/** @jsx jsx */
import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { Container, Row, Col } from 'react-grid-system';
import moment from 'moment';

import { ShiftHopeRate } from 'api/shiftCost';
import Select, { OptionType } from 'components/atoms/Select';
import IconLabelButton from 'components/molecules/IconLabelButton';
import BodyText from 'components/atoms/BodyText';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import DatePicker from 'components/molecules/DatePicker';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import IconTooltip from 'components/molecules/IconTooltip';
import productColor from 'components/styles';
import PrimaryButton from 'components/atoms/Button';
import Panel from 'components/atoms/Panel';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const styles = {
  label: css({
    margin: '0 12px',
    minWidth: '32px',
  }),
  select: css({
    width: '240px',
  }),
  attendSelect: css({
    display: 'flex',
    alignItems: 'center',
  }),
  div: css({
    display: 'flex',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  }),
  textMessage: css({
    color: 'black',
  }),
};

const ShiftCalendarToolbar: React.FC<{
  date: Date;
  setDate: (arg: Date) => void;
  view: 'month' | 'day';
  setView: (arg: 'month' | 'day') => void;
  orgCode: string;
  setOrgCode: (arg: string) => void;
  orgOptions: Array<OptionType>;
  businessMasterOptions: Array<OptionType>;
  businessIds: Array<string>;
  setBusinessIds: (arg: Array<string>) => void;
  shiftConfirmState: { [key: string]: boolean };
  shiftSubmit: () => void;
  cost: number;
  shiftHopeRates: Array<ShiftHopeRate>;
  width: number;
}> = ({
  date,
  setDate,
  view,
  setView,
  orgOptions,
  orgCode,
  setOrgCode,
  businessMasterOptions,
  businessIds,
  setBusinessIds,
  shiftConfirmState,
  shiftSubmit,
  cost,
  shiftHopeRates,
  width,
}) => {
    const functionType = 2;
    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

    const history = useHistory();

    // Check prototype role staff
    const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

    const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

    const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

    useEffect(() => {
      if (checkUserRole && checkUserRole.available === false) {
          history.push('/404')
      }
    }, [checkUserRole]);

    return (
      <div>
        <Row>
          {/* 月・日の切替セレクトボックス */}
          <Col
            offset={{ md: 1, sm: 1 }}
            width={60}
            style={{ marginLeft: 'auto', marginRight: '5px' }}
          >
            <Select
              name="calendarViewSelect"
              value={view}
              setValue={setView as (val: string) => void}
              options={[
                { label: '日', value: 'day' },
                { label: '月', value: 'month' },
              ]}
            />
          </Col>

          {/* 日付選択 */}
          <Col>
            <FlexBox>
              <FlexBoxItem>
                <DatePicker
                  date={date}
                  dateFormat={view === 'month' ? 'yyyy/MM' : 'yyyy/MM/dd'}
                  changeDate={(argDate) => {
                    if (argDate !== null) {
                      setDate(argDate);
                    }
                  }}
                  showMonthYearPicker={view === 'month'}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                {view === 'day' && (
                  <Col>
                    <Row>
                      <Col style={{ marginLeft: '10px' }}>
                        {shiftConfirmState[moment(date).format('YYYY-MM-DD')]
                          ? (
                            <IconTooltip
                              iconType="workSchedule"
                              text="シフト確定済み"
                              color={productColor.primary}
                            />
                          )
                          : null}
                      </Col>
                      <Col style={{ marginLeft: '10px' }}>
                        <IconLabelButton
                          iconType="chevronLeft"
                          onClick={() => setDate(
                            moment(date)
                              .subtract(1, 'day')
                              .toDate(),
                          )}
                          padding="6px 4px"
                          margin="0 0 0 4px"
                        />
                      </Col>
                      <Col style={{ marginLeft: '10px' }}>
                        <IconLabelButton
                          iconType="chevronRight"
                          onClick={() => setDate(
                            moment(date)
                              .add(1, 'day')
                              .toDate(),
                          )}
                          padding="6px 4px"
                          margin="0 0 0 4px"
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
              </FlexBoxItem>
            </FlexBox>
          </Col>
          {/* <Col sm={5}>
          <FlexBox>
            {view === 'month' && (
              <BodyText customStyle={styles.label}>店舗</BodyText>
            )}
            <div css={styles.select}>
              <Select
                name="shiftStoreSelect"
                value={orgCode}
                setValue={setOrgCode}
                options={orgOptions}
              />
            </div>
          </FlexBox>
        </Col> */}

          {/* 今月の人件費率 */}
          <Col
            offset={{ md: 1, sm: 1 }}
            width={250}
            style={{ marginLeft: 'auto', marginRight: '5px' }}
          >
            <Panel padding="0" margin="0" height="24px" width="100%">
              <Container>
                <Row>
                  <Col style={{ padding: '8px' }}>
                    <BodyText>
                      今月の人件費率
                    </BodyText>
                  </Col>
                  <Col css={{ padding: '8px' }}>
                    <BodyText size="lg">
                      {cost ? String(cost) : '-'}
                      %
                    </BodyText>
                  </Col>
                </Row>
              </Container>
            </Panel>
          </Col>
          {/* シフト確定ボタン */}
          <Col
            offset={{ md: 1, sm: 1 }}
            width={150}
            style={{ marginLeft: 'auto' }}
          >
            {checkUserRole && checkUserRole.usabilityGeneralItem1 === 1 && (
              <PrimaryButton
                text="シフト確定"
                onClick={shiftSubmit}
                minWidth="100%"
              />
            )}
          </Col>
        </Row>

        <OrganizationFilteredTree
          functionType={functionType}
          orgCallback={(args: string | Array<string>) => {
            setOrgCode(String(args));
          }}
          initOrgValue={orgCode}
          staffCode={staffCode}
          orgLabel="店舗名"
          targetdayForm={moment(date).startOf('month').toDate()}
          targetdayTo={moment(date).endOf('month').toDate()}
        />
        {/* 業務 */}
        {view === 'day' && (
          <Row style={{ marginTop: '8px' }}>
            <Col>
              <div css={styles.attendSelect}>
                <div>
                  <BodyText customStyle={styles.label}>業務</BodyText>
                </div>
                <Select
                  name="businessAttendList"
                  values={businessIds}
                  setValues={setBusinessIds}
                  options={businessMasterOptions}
                  isMulti={true}
                  placeholder="-"
                  controlStyle={{ minWidth: '400px' }}
                />
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  };

export default ShiftCalendarToolbar;
