/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import productColor, { grayScale } from 'components/styles';
import ClickableLink from 'components/atoms/ClickableLink';
import Icon, { IconType } from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';

interface IconLabelButtonProps {
  onClick: () => void;
  iconType?: IconType;
  iconColor?: string;
  text?: string;
  padding?: string
  margin?: string;
  disabled?: boolean;
  textColor?:string;
  customStyle?: SerializedStyles;
  isIcon?: boolean;
  width?: string;
  className?: string;
}
const styles = {
  button: css`
    button:hover{
      background-color: transparent
    }
  `
}
const IconLabelButton: React.FC<IconLabelButtonProps> = ({
  onClick, iconType, iconColor, text, padding, margin, disabled,textColor, customStyle, isIcon = true, width, className
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div onMouseEnter={(e) => setIsHover(true)} onMouseLeave={(e) => setIsHover(false)} css={css([styles.button, { width: width }])} className={className}>
      <ClickableLink customCss={css(width ? {width: width} : {})} onClick={onClick} padding={padding} margin={margin} disabled={disabled}>
        {iconType && <Icon isHover={isHover} type={iconType} 
        // color={iconColor || productColor.primaryHoverTextLight} 
        color={'#0D4796'} 
        disabled={disabled}/>}
        {text && (
          <BodyText customStyle={css({ 
            marginLeft: isIcon ? '8px' : '0px', 
            // color: disabled ? grayScale.gray60 : isHover ? productColor.primaryHoverText : (textColor || productColor.primaryHoverTextLight) 
            color: disabled ? grayScale.gray60 : isHover ? '#0D4796' : '#0D4796'
          
          }, customStyle)} size="re">{text}</BodyText>
        )}
      </ClickableLink>
    </div>
  )
};

export default IconLabelButton;
