import ApiClient from './ApiClient';

export type AttendErrorPersonal = {
  targetDate: string;
  errorMessage: string;
}

export type AttendErrorOgr = {
  orgName: string;
  orgCode: string;
  errCount: number;
  targetDateFrom: string;
  targetDateTo: string;
}

export const getAttendErrorPersonal = async (): Promise<Array<AttendErrorPersonal>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    staffCode,
  };
  const response = await ApiClient.get(`/v2/attendErrorPersonalInfoOnIcon/${companyCode}`, params, undefined);
  return response.data;
};

export const getAttendErrorOrg = async (): Promise<Array<AttendErrorOgr>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    staffCode,
  };
  const response = await ApiClient.get(`/v2/attendErrorInfoOnIcon/${companyCode}`, params, undefined);
  return response.data;
};

export const downloadAttendanceErrorReportCsv = async (params: any, appendUrl: any, fileName: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsv(`/v1/attendAchievementError/csv/${companyCode}?${appendUrl}`, params, fileName);
};

export default {};
