import { useState, useMemo, useCallback } from 'react';

import EmploymentDomain from 'domain/employment';
import * as api from 'api/employment';
import useToastNotification from 'hooks/useToastNotification';

/**
 * 雇用形態削除処理
 */
export const useEmploymentDelete = (fetchData: () => Promise<void>) => {
  const [delEmployment, setDelEmployment] = useState<EmploymentDomain|null>(null);
  const { successNotification, errorNotification } = useToastNotification();

  // 削除モーダルの開閉状態
  const isOpenDeleteModal = useMemo(() => !!delEmployment, [delEmployment]);

  // 削除モーダルを閉じる
  const closeDeleteModal = useCallback(() => setDelEmployment(null), []);

  // 削除実行
  const onSubmitDeleteEmployment = useCallback(() => {
    if (!delEmployment) {
      return;
    }
    api.deleteEmployment(
      // delEmployment.employmentId, delEmployment.applyStartDateString,
      delEmployment.employmentId, delEmployment.applyStartDate,
    ).then(() => {
      setDelEmployment(null);
      successNotification('削除しました。');
      // 削除後にデータを再取得
      fetchData();
    }).catch((error) => {
      setDelEmployment(null);
      if (error.response && error.response.data && error.response.data.errors) {
        let msgError = '';
        error.response.data.errors.map((item: { defaultMessage: string; }) => {
          msgError += `${item.defaultMessage} \n`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [delEmployment, errorNotification, fetchData, successNotification]);

  return {
    delEmployment,
    setDelEmployment,
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteEmployment,
  };
};

export default {};
