/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { useDownload } from './hook';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import SortIcon from 'components/molecules/SortIcon';
import { grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';

const styles = css({
  fontWeight: 400,
  fontSize: 14,
});

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 1,
});

const DEFAULT_SORT_FIELD = { fieldPath: 'advertisingMediaMstName', sort: 'asc', sortType: 'string' } as FieldSortState

const MediaSalesListTable: React.FC<{
    listMediaSales: any;
    isActiveRow: number;
    isActiveColumn: number;
    handleClickActive: any;
    orgName: any;
    targetPeriodData: any;
    roleScreen: any;
  }> = ({
    listMediaSales,
    isActiveRow,
    isActiveColumn,
    handleClickActive,
    orgName,
    targetPeriodData,
    roleScreen,
  }) => {

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(listMediaSales, DEFAULT_SORT_FIELD);

    const { downloadMediaSalesCsv, downloadMediaSalesExcel } = useDownload();

    const searchResult:any[] = [];

    const targetStoresData = `対象店舗: ${orgName}`;

    const headerInformation = [
      ['媒体', '組数', '客数', '金額']
    ];

    let mergedRegions:any[] = [];
    const codeColumnIndex:any[] = [];
    const data = {
      footer: '',
      formName: '媒体売上帳票',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult,
      totalInformation: [],
    };

    const dataExcel = {
      formName: '媒体売上帳票',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex,
    };

    const handleDownloadMediaSalesCsv = () => {
      downloadMediaSalesCsv(data);
    };

    const handleDownloadMediaSalesExcel = () => {
      dataExcel.headerInformation = [
        ['媒体', '組数', '客数', '金額']
      ];

      return downloadMediaSalesExcel(dataExcel);
    };

    useEffect(() => {
      if (dataSort) {
        // content
        dataSort.map((mediaSales: any) => {
          const searchItem: any[] = [];
          searchItem.push(
            mediaSales.advertisingMediaMstName,
            formatter.format(mediaSales.customers),
            formatter.format(mediaSales.guestCnt),
            formatter.format(mediaSales.advertisingMediaAmount),
          );
          searchResult.push(searchItem);
        });
      }
    }, [dataSort, searchResult]);

    const renderHeaderColumn = useCallback((displayName: string, columnSortField: FieldSortState, rowSpan: number, className: string = "") => (
      <th rowSpan={rowSpan} className={className} onClick={e => sortByFieldPath(columnSortField.fieldPath, columnSortField.sortType)}>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <span style={{ margin: 'auto' }}>{displayName}</span>
          {sortField.fieldPath === columnSortField.fieldPath && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
          {sortField.fieldPath !== columnSortField.fieldPath && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
        </div>
      </th>
      ), [sortField]);

    return (
      <div>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleDownloadMediaSalesCsv}
                iconType="download"
                text=" CSV出力"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleDownloadMediaSalesExcel}
                iconType="download"
                text=" EXCEL出力"
              />
            </FlexBoxItem>
          </FlexBox>
        )}

        <div
          className="table-responsive"
          style={{
            display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', maxHeight: 600,
          }}
        >
          <table id="salesPageForm" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }}>
            <thead>
              <tr>
                { renderHeaderColumn('媒体', { fieldPath: 'advertisingMediaMstName', sort: 'asc', sortType: 'string'}, 1) }
                { renderHeaderColumn('組数', { fieldPath: 'customers', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('客数', { fieldPath: 'guestCnt', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('金額', { fieldPath: 'advertisingMediaAmount', sort: 'asc', sortType: 'number'}, 1) }
              </tr>
            </thead>
              {
                dataSort && dataSort.length > 0 ? dataSort.map((mediaSales: any, index: number) => (
                  <tbody key={index} css={styles}>
                    <tr className={isActiveRow === index ? 'activeClickFrames' : ''}>
                      <td
                        className={`textLeft ${isActiveColumn === 1 ? 'activeClickFrames' : ''}`}
                        onClick={() => { handleClickActive(index, 1); }}
                      >
                        {mediaSales.advertisingMediaMstName}
                      </td>
                      <td
                        className={isActiveColumn === 2 ? 'activeClickFrames' : ''}
                        onClick={() => { handleClickActive(index, 2); }}
                      >
                        {formatter.format(mediaSales.customers)}
                      </td>
                      <td
                        className={isActiveColumn === 3 ? 'activeClickFrames' : ''}
                        onClick={() => { handleClickActive(index, 3); }}
                      >
                        {formatter.format(mediaSales.guestCnt) }
                      </td>
                      <td
                        className={isActiveColumn === 4 ? 'activeClickFrames' : ''}
                        onClick={() => { handleClickActive(index, 4); }}
                      >
                        {formatter.format(mediaSales.advertisingMediaAmount)}
                      </td>
                    </tr>
                  </tbody>
                )) : <tbody css={styles}></tbody>
              }
          </table>
        </div>
      </div>
    );
};

export default MediaSalesListTable;
