import ApiClient from './ApiClient';

export interface Prefecture {
  prefectureCode: string;
  prefectureName: string;
}

export const getPrefectureList = async (): Promise<Array<Prefecture>> => {
  const response = await ApiClient.get('/v1/prefectures/list', {});
  return response.data;
};

export default {
  getPrefectureList,
};
