/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import moment from 'moment';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BlockUI from 'components/molecules/BlockUi';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import IncomeAndExpenditureStoreTable from './incomeAndExpenditureStoreTable';
import useIncomeAndExpenditureStore from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const IncomeAndExpenditureStoreLayout = () => {
  const fromdate = new Date();
  const datefrom = new Date(fromdate.getFullYear(), fromdate.getMonth(), 1); // get first date
  const dateTo = (new Date(fromdate.getFullYear(), fromdate.getMonth() + 1, 0)); // get date
  const [targetDateFrom, setTargetDateFrom] = useState(datefrom);
  const [targetDateTo, setTargetDateTo] = useState(dateTo);

  const st = new Date();
  st.setMonth(0);
  st.setDate(1);
  const ed = new Date(st);
  if (!targetDateFrom) {
    setTargetDateFrom(st);
  }

  if (!targetDateTo) {
    setTargetDateTo(ed);
  }

  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('sessionOrgSelect/incomeAndExpenditureStore') ? sessionStorage.getItem('sessionOrgSelect/incomeAndExpenditureStore') : '');
  const [orgName, setorgName] = useState(sessionStorage.getItem('sessionOrgNameSelected') ? sessionStorage.getItem('sessionOrgNameSelected') : '');

  const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
    undefined,
  );

  const {
    incomeAndExpenditureStore,
    getIsLoading,
    setIsLoading,
  } = useIncomeAndExpenditureStore(
    String(orgCode),
    moment(targetDateFrom).format('YYYY/MM/DD'),
    moment(targetDateTo).format('YYYY/MM/DD'),
    categoryAry,
  );

  const targetPeriodData = `対象期間：${moment(targetDateFrom).format('YYYY/MM')}`;

  const functionType = 3;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <div>
      <FormContents>
        <div>
          <FlexBox>
            <FlexBoxItem width="100px">
              <FormLabel
                label="対象期間"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月"
                label=""
                date={targetDateFrom}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateFrom(date);
                    setTargetDateTo(new Date(date.getFullYear(), date.getMonth() + 1, 0));
                  }
                }}
                showMonthYearPicker={true}
                isFullWidth={true}
              />
            </FlexBoxItem>

          </FlexBox>
        </div>

        <div>
          <OrganizationFilteredTree
            functionType={functionType}
            staffCode={staffCode}
            targetdayForm={targetDateFrom}
            targetdayTo={targetDateTo}
            addAllItem={true}
            initOrgValue={String(orgCode)}
            orgLabel="店舗名"
            orgCallback={(args: any) => {
              setOrgCode(String(args));
            }}
            orgCategoryCallback={
              (args: Array<string>) => {
                setCategoryAr(args);
              }
            }
            orgNameCallback={(arg: any) => {
              setorgName(String(arg));
              sessionStorage.setItem('sessionOrgNameSelected',arg)
            }}

          />
        </div>

        <div>
          <div style={{ marginTop: '30px' }}>
            <div className="row">
              <BlockUI blocking={getIsLoading}>
                <IncomeAndExpenditureStoreTable
                  targetDateFrom={targetDateFrom}
                  // targetDateTo={targetDateTo}
                  incomeAndExpenditureStore={incomeAndExpenditureStore}
                  targetPeriodData={targetPeriodData}
                  orgLabel={orgCode === 'all' ? 'all' : orgCode + ' ' + orgName}
                  setIsLoading={setIsLoading}
                  roleScreen={roleScreen}
                />
              </BlockUI>
            </div>
          </div>
        </div>

      </FormContents>
    </div>
  );
};

export default IncomeAndExpenditureStoreLayout;
