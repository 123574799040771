/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Amplify, { Auth } from 'aws-amplify';
import awsExports from 'aws-exports'; // 設定情報を読み込みます。
import TextForm from 'components/molecules/TextForm';

import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import FormField from 'components/atoms/Form/FormField';
import PasswordForm from 'components/molecules/PasswordForm';
import Button, { PrimaryButton } from 'components/atoms/Button';
import { Link, useHistory, useParams } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';
import {checkEmailExists, staffRegisterTouch} from 'api/signUp'
import { ErrorMessage } from 'utility/messages';

const styles = {
  canselButton: css({
    marginRight: '3em auto',
  }),
  h1: css({
    textAlign: 'left',
    padding: '0 0',
    fontSize: '0.75em',
  }),
  form: css({
    padding: '0 1.5em',
  }),
  standardLogo: css({
    marginLeft: 'auto',
    marginRight: 'auto',
    verticalAlign: 'middle',
    width: '100px',
    height: '100px',
    padding: '8px 12px',
  }),
  formFotter: css({
    padding: '0.2em 0.5em',
    textAlign: 'center',
    fontSize: '.875em',
  }),
};

Amplify.configure(awsExports); // 設定情報をAmplifyに反映させます。

const ActivationSchema = Yup.object().shape({
  email: Yup.string()
    .required('メールアドレスを入力してください'),
  password: Yup.string()
    .required('認証コードを入力してください'),
});

const ActivationInputForm: React.FC = () => {
  const { successNotification, errorNotification } = useToastNotification();
  const { mailAddress } = useParams();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: mailAddress !== undefined ? mailAddress : '',
      password: '',
    },
    // NOTE: setSubmittingを使っていないが非同期処理を実行した場合はformikが自動でisSubmittingの値をtrueからfalseに設定してくれる
    onSubmit: async (values, { setSubmitting }) => {
      const ret = await isEmailExists(values.email);
      if (ret) {
        // メールアドレスがスタッフマスタに存在する場合はCognitoユーザーを認証する
        await confirmSignUp(values.email, values.password);
      }
    },
    validationSchema: ActivationSchema,
  });

  const confirmSignUp = async (email: string, password: string) => {
    try {
      await Auth.confirmSignUp(email, password);
      console.log('認証成功');
      successNotification('ユーザー登録が完了しました');
      await staffRegisterTouch(email);
      history.push('/login');
    } catch (err) {
      console.error(`err.code = ${err.code}`);
      console.error(`err.message = ${err.message}`);
      if (err.code === 'CodeMismatchException') {
        errorNotification('認証コードが間違っています');
      } else {
        errorNotification('ユーザー登録に失敗しました');
      }
    }
  };

  /**
   * スタッフマスタメールアドレス存在チェック
   * @param email
   * @returns true: 存在する / false: 存在しない
  */
  const isEmailExists = async (email: string) => {
    try {
      await checkEmailExists(email);
      return true;
    } catch (err) {
      if(err.response.status === 400) {
        errorNotification(err.response.data.defaultMessage);
      } else if (err.response.status === 503) {
        errorNotification(err.response.data.defaultMessage);
      } else {
        errorNotification(ErrorMessage.Common.INTERNAL_SERVER_ERROR);
      }
      return false;
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <p css={styles.h1}>
        受信したメールアドレスに記載されている認証コードを入力して下さい。
        <br />
        メールが届かない場合、迷惑メールフォルダに無いか確認して下さい。
        <br />
        「e-cometrue.com」のドメインからのメールを受信許可に設定して下さい。
      </p>
      {mailAddress ? '' :<FormField>
        <TextForm
          name="email"
          label="メールアドレス"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
      </FormField>
      }
      <FormField>
        <PasswordForm
          name="password"
          label="認証コード"
          value={formik.values.password}
          onChange={formik.handleChange}
          errorMsg={formik.errors.password}
        />
      </FormField>

      <FormSubmitArea>
        <Button
          text="認証する"
          onClick={formik.handleSubmit}
        />
        <Link to="/login">
          <PrimaryButton
            ghost={true}
            text="戻る"
          />
        </Link>
      </FormSubmitArea>
      <div css={styles.formFotter}>
          <a href="#" id="resend_code" css={styles['formFotter']} onClick={()=>{

            console.log(formik.values && formik.values.email)
            Auth.resendSignUp(formik.values && formik.values.email)
            .then((error: any)=>{
                successNotification(`${formik.values.email} に認証コードを送信しました`);
            })
            .catch((err : any) => {
              console.error(err);
              if (err.code === 'UserNotFoundException') {
                errorNotification('入力されたメールアドレスのユーザーは登録されておりません');
              } else if(err.message === 'User is already confirmed.'){
                errorNotification('このメールは確認済みです')
              }else {
                errorNotification('認証コードの送信に失敗しました');
              }
            });

            }}>認証コードを再送する</a>
        </div>
    </form>
  );
};

export default ActivationInputForm;
