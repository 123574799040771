import React, { useState } from 'react';
import TabControl from 'components/atoms/TabControl';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import { useParams, useHistory } from 'react-router-dom';
import FormContents from 'components/atoms/Form/FormContents';
import AdvertisingPlanPage from '../AdvertisingPlanMaster';
import StoreSettingsPage from '../StoreSettingsPage';

const AdvertisingPage: React.FC = () => {
  const { currentTab } = useParams();
  const [value, setValue] = useState((currentTab === 'advertisingMaster' || currentTab === 'advertisingOrganization') ? currentTab : 'advertisingMaster');
  const history = useHistory();

  return (
    <>
      <SidebarTemplate pageTitle="広告プランマスタ">
        <FormContents>
          <TabControl
            items={[
              { value: 'advertisingMaster', display: '広告プラン' },
              { value: 'advertisingOrganization', display: '店舗設定' },
            ]}
            setValue={(val: string) => {
              sessionStorage.setItem('selectedOrgCode', sessionStorage.getItem('loginUser.orgCode')!);
              setValue(val);
              history.push(`/salesAdvertisingPlan/${val}`);
            }}
            currentValue={`${value}`}
            isFixed={true}
          />
          {value === 'advertisingMaster' ? <AdvertisingPlanPage /> : <StoreSettingsPage />}
        </FormContents>
      </SidebarTemplate>
    </>
  );
};

export default AdvertisingPage;
