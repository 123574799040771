
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import StoreSettingImportForm from '../StoreSettingImportForm';

const StoreSettingImportPage = () => (
  <SidebarTemplate pageTitle="広告プラン店舗設定インポート">
    <StoreSettingImportForm />
  </SidebarTemplate>
);

export default StoreSettingImportPage;
