/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/** @jsx jsx */
import React, {
  useEffect, useReducer, useCallback, useState, useMemo,
} from 'react';
import { createPortal } from 'react-dom';
import { css, jsx } from '@emotion/core';
import ShiftSidebarTemplate from 'components/templates/ShiftSidebarTemplate';
import BlockUI from 'components/molecules/BlockUi';
import FormContents from 'components/atoms/Form/FormContents';
import {
  utilityColor, grayScale, textFontSize, textColor,
} from 'components/styles';
import ContextMenuShift from 'components/molecules/ContextMenu/ContextMenuShift';
import {
  TimelineItemBase,
  ItemContext,
  GetItemsProps,
  GetResizeProps,
  ItemRendererGetResizePropsReturnType,
  TimelineGroupCustom,
} from 'types/react-calendar-timeline';
import Modal from 'components/molecules/Modal';
import { dailyShiftContext, useInitialState } from './contextHook';
import Filters from './Filters';
import { photoPath } from 'hooks/usePhotoPath';
import '../ReactCalendarTimeline/lib/Timeline.scss';
import PhotoFrame from 'components/molecules/PhotoFrame';
import Icon from 'components/atoms/Icon';
import ReactTooltip from 'react-tooltip';
import SelectForm from 'components/molecules/SelectForm';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import moment from 'moment';
import VerticalLabelTextLayout from 'components/atoms/Form/VerticalLabelTextLayout';
import VerticalLabelFormLayoutV2 from 'components/atoms/Form/VerticalLabelFormLayoutV2';
import VerticalLabelSelectFormV2 from 'components/molecules/VerticalLabelSelectFormV2';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import BodyText from 'components/atoms/BodyText';
import useToastNotification from 'hooks/useToastNotification';
import { OptionType } from 'components/atoms/Select';
import { useBusinessAttendChoiceOptions } from 'hooks/useBusinessMasterOptions';
import ModalForm from 'components/molecules/ModalForm';
import { getShift, Shift, postShiftV1 } from 'api/shift';

import { isMacOs } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import ShiftPatternDetailList from './Modals/ShiftPatternDetailList';
import ModalRegisterOtherStaffShift from './Modals/ModalRegisterOtherStaffShift';
import ModalRegisterShift from './Modals/ModalRegisterShift';
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
} from '../ReactCalendarTimeline/index';
import { getDailyAddStaffs, getShiftPatterns } from '../api';
import { convertToNextDay} from 'utility/dateUtil';
import { convertNumberByPrecision } from 'utility/formatUtil';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import { Literal, Values } from 'utility/constants';
import AccordionPanel from 'components/molecules/AccordionPanel';


const DailyShift: React.FC = () => {
  const { errorNotification } = useToastNotification();
  const contextInitialState = useInitialState();
  const {
    remote,
    setRemote,
    isLoading,
    setLoading,
    openConfirmModal,
    setOpenConfirmModal,
    openDeleteModal,
    setOpenDeleteModal,
    openRegisterModal,
    setOpenRegisterModal,
    dailyShift,
    orgCodeModal,
    setOrgCodeModal,
    handleAddStaffDaily,
    businessIds,
    setBusinessIds,
    filters: {
      submitShiftLockout, dateFromStr, selectedOrg, selectedOrgName,
    },
    calendar: {
      items,
      deleteItem,
      groups,
      selectedItem,
      setSelectedItem,
      selectedItemIdArr,
      setSelectedItemIdArr,
      defaultTimeStart,
      defaultTimeEnd,
      sidebarName,
      sidebarType,
      sidebarHeaderWidth,
      sidebarHeaderRightWidth,
      onTimeChange,
      onItemMove,
      onItemSelect,
      onItemClick,
      onCanvasClick,
      onItemResize,
      moveResizeValidator,
      onClickAttendStampList,
      fetchDailyShiftData,
    },
    contextMenu: {
      isPerformanceMenuVisible,
      setPerformanceMenuVisible,
      isItemMenuVisible,
      setItemMenuVisible,
      isTimelineMenuVisible,
      setTimelineMenuVisible,
      contextMenuRef,
      contextMenuX,
      contextMenuY,
      menuTimelineButtons,
      menuPerformanceButtons,
      menuItemButtons,
    },
    shiftPattern: {
      openShiftPatternModal, openShiftPatternStaffModal, shiftPatternOptions, setShiftPatternStaffModal, getWorkdayOptions, setShiftPatternOptions,
    },
  } = contextInitialState;

  const bodyNode = document.body as HTMLBodyElement;

  const functionType = 2;

  // 現在のブラウザのユーザーエージェントを取得
  const userAgent = navigator.userAgent.toLowerCase();
  // タブレット判定（iPad、Android タブレット、Windows タッチデバイス、Kindle、PlayBook、Silk、Puffin の場合はTrue）
  const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
  const isMacOSOrTablet = isMacOs || isTablet;
  const [getCheckScrollBar, setCheckScrollBar] = useState(0);

  useEffect(() => {
    const scrollWidth = document.getElementById('calendar-daily')?.scrollWidth || 0;
    const outerWidth = document.getElementsByClassName('rct-outer')[0]?.scrollWidth || 0;
    if (outerWidth > 0 && scrollWidth > 0) {
      setCheckScrollBar(outerWidth - scrollWidth - 2);
      console.log('scrollWidth', outerWidth, 'outerWidth', scrollWidth);
    }
  }, [dailyShift]);

  const [staffCode, setStaffCode] = useState<string>('');
  const [staffName, setStaffName] = useState<string>('');
  const [shiftDailyStaffOptions, setShiftDailyStaffOptions] = useState<
  Array<OptionType>
>([]);
  const [staffEmploymentId, setStaffEmploymentId] = useState<string>('');

  const defaultShiftDetailList = [
    {
      startTime: moment(dateFromStr).format('YYYY/MM/DD HH:mm:ss'),
      isStartTimeNextDay: false,
      endTime: moment(dateFromStr).format('YYYY/MM/DD HH:mm:ss'),
      isEndTimeNextDay: false,
      businessId: '',
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      shiftDetailId: '',
      isRemote: true,
    },
  ];

  const defaultShift = {
    companyCode: '',
    createUser: sessionStorage.getItem('loginUser.staffName') || '',
    updateUser: sessionStorage.getItem('loginUser.staffName') || '',
    createDate: '',
    updateDate: '',
    shiftId: '',
    orgCode: selectedOrg,
    orgName: selectedOrgName,
    belongOrgCode: '',
    staffCode,
    staffName: staffCode,
    targetDate: moment(dateFromStr).format('YYYY/MM/DD HH:mm:ss'),
    isLegal: false,
    notifiedToStampForgot: false,
    startTime: moment(dateFromStr).format('YYYY/MM/DD HH:mm:ss'),
    endTime: moment(dateFromStr).format('YYYY/MM/DD HH:mm:ss'),
    shiftDetailList: defaultShiftDetailList,
    procType: 0,
    attendType: 0,
    closingHour: 0,
    distinctionHoliday: false,
    shopPlanId: '',
    shopPlan: '',
    shopPlanOrgCode: '',
    shopPlanTargetDate: '',
    shiftPatternOptions,
  };

  useEffect(() => {
    if (openRegisterModal && orgCodeModal !== '') {
      getDailyAddStaffs(
        selectedOrg,
        orgCodeModal,
        moment(dateFromStr).format('YYYY-MM-DD'),
      ).then((dailyAddStaffs: any) => {
        const dailyAddStaffData = dailyAddStaffs.map((staff: any) => ({
          value: staff.staffCode,
          label: staff.staffName,
          employmentId: staff.employmentId,
        }));
        setShiftDailyStaffOptions(dailyAddStaffData);
        setStaffCode(
          dailyAddStaffData !== null && dailyAddStaffData.length > 0
            ? dailyAddStaffData[0].value
            : '',
        );
        setStaffName(
          dailyAddStaffData !== null && dailyAddStaffData.length > 0
            ? dailyAddStaffData[0].label
            : '',
        );
        setStaffEmploymentId(
          dailyAddStaffData !== null && dailyAddStaffData.length > 0
            ? dailyAddStaffData[0].employmentId
            : '',
        );
      }).catch((error: any) => {
        setStaffCode('');
        setStaffName('');
        setStaffEmploymentId('');
      });
    }
  }, [dateFromStr, orgCodeModal, openRegisterModal, selectedOrg]);

  useEffect(() => {
    dispatch({ type: 'STAFF_CODE', payload: staffCode });
    dispatch({ type: 'SHIFTPATTERN_ONCHANGE', payload: '' });
    const staffInfo = shiftDailyStaffOptions.filter((staff) => staff.value === staffCode);
    setStaffName(
      staffInfo !== null && staffInfo.length > 0
        ? staffInfo[0].label
        : '',
    );
    setStaffEmploymentId(
      staffInfo !== null && staffInfo.length > 0
        ? staffInfo[0].employmentId
        : '',
    );
  }, [shiftDailyStaffOptions, staffCode]);

  const memorizedReducer = useCallback((state: Shift, { type, payload }: any): Shift => {
    const shiftDetailList = [...state.shiftDetailList];
    let { staffCode } = state;
    let staffName = state.staffCode;
    let shiftPatternList = state.shiftPatternOptions;
    const nextDay = 1;
    let { orgCode } = state;
    let { orgName } = state;
    let { targetDate } = state;
    let { startTime } = state;
    let { endTime } = state;
    console.log('payload', state, type, payload);
    switch (type) {
      case 'SET_STATE':
        return payload;

      case 'ORG_CODE':
        orgCode = payload;
        return { ...state, orgCode };

      case 'ORG_NAME':
        orgName = payload;
        return { ...state, orgName };

      case 'SHIFT_PATTERN_LIST':
        shiftPatternList = payload;
        console.log('shiftPatternList', shiftPatternList);
        console.log('aaa', shiftPatternList, payload);
        return { ...state, shiftPatternOptions: shiftPatternList };

      case 'START_HOUR_ONCHANGE':
        shiftDetailList[payload.index].startTime = payload.startTime;
        return { ...state, startTime: shiftDetailList[0].startTime, shiftDetailList };

      case 'END_HOUR_ONCHANGE':
        if (shiftDetailList[payload.index + 1]) {
          shiftDetailList[payload.index + 1].startTime = payload.endTime;
        }
        shiftDetailList[payload.index].endTime = payload.endTime;
        return { ...state, endTime: shiftDetailList[shiftDetailList.length - 1].endTime, shiftDetailList };

      case 'START_NEXTDAY_ONCHANGE':
        shiftDetailList[payload.index].isStartTimeNextDay = payload.isStartTimeNextDay;

        shiftDetailList[payload.index].startTime = payload.isStartTimeNextDay
          ? moment(shiftDetailList[payload.index].startTime)
            .add('day', 1)
            .format('YYYY/MM/DD HH:mm:ss')
          : moment(shiftDetailList[payload.index].startTime)
            .subtract('day', 1)
            .format('YYYY/MM/DD HH:mm:ss');

        return { ...state, shiftDetailList };

      case 'END_NEXTDAY_ONCHANGE':
        if (shiftDetailList[payload.index + 1]) {
          shiftDetailList[payload.index + 1].isStartTimeNextDay = payload.isEndTimeNextDay;
          shiftDetailList[payload.index + 1].startTime = convertToNextDay(state.targetDate, payload.isEndTimeNextDay, state.shiftDetailList[payload.index].endTime);
        }
        shiftDetailList[payload.index].isEndTimeNextDay = payload.isEndTimeNextDay;
        shiftDetailList[payload.index].endTime = convertToNextDay(state.targetDate, payload.isEndTimeNextDay, state.shiftDetailList[payload.index].endTime);

        return { ...state, shiftDetailList };

      case 'IS_BREAK_ONCHANGE':
        shiftDetailList[payload.index].businessId = payload.businessId;
        return { ...state, shiftDetailList };

      case 'IS_REMOTE_ONCHANGE':
        shiftDetailList[payload.index].isRemote = payload.isRemote;
        return { ...state, shiftDetailList };

      case 'WORKDAY_TYPE_ONCHANGE':
        switch (payload) {
          case 'legal':
            defaultShiftDetailList[0].isEndTimeNextDay = true;
            defaultShiftDetailList[0].startTime = moment(state.targetDate)
              .add('hour', 0)
              .format('YYYY/MM/DD HH:mm:ss');
            defaultShiftDetailList[0].endTime = moment(state.targetDate)
              .add({ day: 1, hour: 0 })
              .format('YYYY/MM/DD HH:mm:ss');
            return {
              ...state,
              shiftPatternId: '',
              holidayId: 'legal',
              isLegal: true,
              attendType: 1,
              shiftDetailList: defaultShiftDetailList,
            };

          case 'scheduled':
            defaultShiftDetailList[0].isEndTimeNextDay = true;
            defaultShiftDetailList[0].startTime = moment(state.targetDate)
              .add('hour', 0)
              .format('YYYY/MM/DD HH:mm:ss');
            defaultShiftDetailList[0].endTime = moment(state.targetDate)
              .add({ day: 1, hour: 0 })
              .format('YYYY/MM/DD HH:mm:ss');
            return {
              ...state,
              shiftPatternId: '',
              holidayId: 'scheduled',
              isLegal: false,
              attendType: 1,
              shiftDetailList: defaultShiftDetailList,
            };

          default:
            return {
              ...state, shiftPatternId: '', holidayId: '', shiftDetailList: defaultShiftDetailList,
            };
        }

      case 'SHIFTPATTERN_ONCHANGE':
        const shiftPattern = state.shiftPatternOptions && state.shiftPatternOptions.length > 0 && state.shiftPatternOptions.find((pattern: any) => pattern.shiftPatternId === payload);
        console.log('shiftPattern', shiftPattern, payload, state.shiftPatternOptions);
        let newShiftDetailList;
        if (shiftPattern) {
          newShiftDetailList = shiftPattern.attendShiftPatternDetails.map((patternDetail: any) => {
            console.log('patternDetail', patternDetail);
            return {
              startTime: moment(`${
                patternDetail.isStartTimeNextDay
                  ? moment(state.targetDate).add('day', nextDay).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
                  : moment(state.targetDate).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
              } ${patternDetail.startTime}`).format('YYYY/MM/DD HH:mm:ss'),
              isStartTimeNextDay: patternDetail.isStartTimeNextDay,
              endTime: moment(`${
                patternDetail.isEndTimeNextDay
                  ? moment(state.targetDate).add('day', nextDay).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
                  : moment(state.targetDate).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
              } ${patternDetail.endTime}`).format('YYYY/MM/DD HH:mm:ss'),
              isEndTimeNextDay: patternDetail.isEndTimeNextDay,
              businessId: patternDetail.businessId || '',
              createUser: patternDetail.createUser,
              updateUser: patternDetail.updateUser,
              isRemote: patternDetail.isRemote,
            };
          });
        } else {
          defaultShiftDetailList[0].isEndTimeNextDay = false;
          defaultShiftDetailList[0].isStartTimeNextDay = false;
          defaultShiftDetailList[0].startTime = moment(state.targetDate)
            .add('hour', 0)
            .format('YYYY/MM/DD HH:mm:ss');
          defaultShiftDetailList[0].endTime = moment(state.targetDate)
            .add('hour', 0)
            .format('YYYY/MM/DD HH:mm:ss');
          newShiftDetailList = defaultShiftDetailList;
        }
        return { ...state, shiftPatternId: payload, shiftDetailList: newShiftDetailList };

      case 'ADD_SHIFTDETAILLIST':
        const newDefaultShiftDetail = { ...defaultShiftDetailList[0] };
        newDefaultShiftDetail.businessId = '';
        console.log('ADD_SHIFTDETAILLIST', newDefaultShiftDetail);
        newDefaultShiftDetail.startTime = state.shiftDetailList[state.shiftDetailList.length - 1].endTime;
        newDefaultShiftDetail.isStartTimeNextDay = state.shiftDetailList[state.shiftDetailList.length - 1].isEndTimeNextDay;
        newDefaultShiftDetail.isEndTimeNextDay = false;
        newDefaultShiftDetail.endTime = moment(state.targetDate).format('YYYY/MM/DD HH:mm:ss');
        return { ...state, shiftDetailList: [...state.shiftDetailList, newDefaultShiftDetail] };

      case 'DELETE_SHIFTDETAILLIST':
        shiftDetailList.splice(payload, 1);
        return { ...state, shiftDetailList };

      case 'STAFF_CODE':
        staffCode = payload;
        staffName = payload;
        return { ...state, staffCode, staffName };

      case 'TARGET_DATE':
        targetDate = moment(payload).format('YYYY/MM/DD HH:mm:ss');
        startTime = `${moment(payload).format('YYYY/MM/DD')} ${state.startTime.split(' ')[1]}`;
        endTime = `${moment(payload).format('YYYY/MM/DD')} ${state.endTime.split(' ')[1]}`;
        const changeDateShiftDetailList = shiftDetailList.map((detail, index) => ({
          startTime: moment(`${
            detail.isStartTimeNextDay
              ? moment(payload).add('day', nextDay).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
              : moment(payload).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
          } ${detail.startTime.split(' ')[1]}`).format('YYYY/MM/DD HH:mm:ss'),
          isStartTimeNextDay: detail.isStartTimeNextDay,
          endTime: moment(`${
            detail.isEndTimeNextDay
              ? moment(payload).add('day', nextDay).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
              : moment(payload).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
          } ${detail.endTime.split(' ')[1]}`).format('YYYY/MM/DD HH:mm:ss'),
          isEndTimeNextDay: detail.isEndTimeNextDay,
          businessId: detail.businessId || '',
          createUser: detail.createUser,
          updateUser: detail.updateUser,
          isRemote: detail.isRemote,
        }));
        return {
          ...state, targetDate, shiftDetailList: changeDateShiftDetailList, startTime, endTime,
        };

      default:
        return state;
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, dispatch] = useReducer(memorizedReducer, defaultShift);

  console.log('state', state);

  const businessAttendChoiceOptions = useBusinessAttendChoiceOptions(
    staffCode,
    moment(dateFromStr).format('YYYY/MM/DD'),
  );

  const resetShift = () => {
    dispatch({ type: 'SET_STATE', payload: defaultShift });
  };

  const onSubmit = async () => {
    console.log(state, 'posting');
    const newState = formatToSubmitObject(state);
    if (newState.staffCode === '') {
      errorNotification('スタッフをお選びください');
      return false;
    }
    if (newState.orgCode === orgCodeModal) {
      errorNotification('現在の店舗ではスタッフを選べません');
      return false;
    }
    try {
      setLoading(true);
      await postShiftV1(newState);
      await fetchDailyShiftData();
      // reset
      setShiftPatternStaffModal(false);
      setOpenRegisterModal(false);
      resetShift();
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const formatToSubmitObject = (state: Shift) => ({
    cumulativeClosingDate: 0,
    staffCode: state.staffCode,
    staffName: state.staffName,
    attendType: state.attendType,
    orgCode: state.orgCode,
    orgName: state.orgName,
    shiftPatternId: state.shiftPatternId || '',
    shiftDetailList: state.shiftDetailList.map((detail) =>
      // let startTime = detail.isStartTimeNextDay
      //   ? moment(detail.startTime)
      //       .add("day", 1)
      //       .format("YYYY/MM/DD HH:mm:ss")
      //   : detail.startTime;
      // let endTime = detail.isEndTimeNextDay
      //   ? moment(detail.endTime)
      //       .add("day", 1)
      //       .format("YYYY/MM/DD HH:mm:ss")
      //   : detail.endTime;
      ({
        startHourForm: moment(detail.startTime).format('HH'),
        startTimeForm: moment(detail.startTime).format('mm'),
        // startTime: startTime,
        startTime: detail.startTime,
        isStartTimeNextDay: detail.isStartTimeNextDay,
        endHourForm: moment(detail.endTime).format('HH'),
        endTimeForm: moment(detail.endTime).format('mm'),
        // endTime: endTime,
        endTime: detail.endTime,
        isEndTimeNextDay: detail.isEndTimeNextDay,
        isEndTimeNextDayForm: detail.isEndTimeNextDay,
        businessId: detail.businessId || '',
        shiftId: state.shiftId,
        shiftDetailId: detail.shiftDetailId || '',
        createUser: detail.createUser,
        updateUser: detail.updateUser,
        isRemote: remote ? detail.isRemote : false,
      })),
    shiftId: state.shiftId,
    targetDate: moment(state.shiftDetailList[0].startTime)
      .startOf('day')
      .format('YYYY/MM/DD HH:mm:ss'),
    startTime: state.shiftDetailList[0].startTime,
    endTime: state.shiftDetailList[state.shiftDetailList.length - 1].endTime,
    procType: state.procType,
    createUser: state.createUser,
    updateUser: state.updateUser,
    requestNote: state.requestNote !== undefined ? state.requestNote : '',
    deadline: state.deadline !== undefined ? state.deadline : moment(new Date()).format('YYYY/MM/DD'),
  });

  const formatShiftDate = (shift: Shift) => ({
    companyCode: shift.companyCode,
    createUser: shift.createUser,
    updateUser: shift.updateUser,
    createDate: shift.createDate,
    updateDate: shift.updateDate,
    shiftId: shift.shiftId,
    orgCode: shift.orgCode,
    orgName: shift.orgName,
    belongOrgCode: shift.belongOrgCode,
    staffCode: shift.staffCode,
    staffName: shift.staffName,
    targetDate: moment(shift.targetDate).format('YYYY/MM/DD HH:mm:ss'),
    isLegal: shift.isLegal,
    notifiedToStampForgot: shift.notifiedToStampForgot,
    startTime: moment(shift.startTime).format('YYYY/MM/DD HH:mm:ss'),
    endTime: moment(shift.endTime).format('YYYY/MM/DD HH:mm:ss'),
    shiftDetailList: shift.holidayId
      ? [
        {
          startTime: moment(shift.startTime)
            .hour(0)
            .format('YYYY/MM/DD HH:mm:ss'),
          isStartTimeNextDay: false,
          endTime: moment(shift.endTime)
            .hour(0)
            .format('YYYY/MM/DD HH:mm:ss'),
          isEndTimeNextDay: true,
          businessId: '',
          createUser: shift.createUser,
          updateUser: shift.createUser,
          shiftDetailId: '',
          isRemote: true,
        },
      ]
      : shift.shiftDetailList.map((detail) => ({
        startTime: moment(detail.startTime).format('YYYY/MM/DD HH:mm:ss'),
        isStartTimeNextDay: detail.isStartTimeNextDay,
        endTime: moment(detail.endTime).format('YYYY/MM/DD HH:mm:ss'),
        isEndTimeNextDay: detail.isEndTimeNextDay,
        businessId: detail.businessId,
        createUser: detail.createUser,
        updateUser: detail.updateUser,
        shiftDetailId: detail.shiftDetailId || '',
        isRemote: detail.isRemote || false,
      })),
    procType: shift.procType,
    attendType: shift.attendType,
    closingHour: shift.closingHour,
    distinctionHoliday: shift.distinctionHoliday,
    shiftPatternId: shift.shiftPatternId,
    requestNote: shift.requestNote || '',
    holidayId: shift.holidayId || '',
    notifiedBeforeShiftStart: shift.notifiedBeforeShiftStart || '',
  });

  useEffect(() => {
    const fetchShift = async (shiftId: string) => {
      try {
        setLoading(true);

        let shiftData = await getShift(shiftId);
        shiftData = formatShiftDate(shiftData);
        dispatch({ type: 'SET_STATE', payload: shiftData });
      } catch (error) {
        errorNotification(error.response.data.message);
      } finally {
        setLoading(false);
      }
    };

    if (selectedItem) {
      fetchShift(selectedItem.idToString);
    }

    return () => {
      setSelectedItem(null);
      resetShift();
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (staffEmploymentId !== '') {
      getShiftPatterns(staffEmploymentId, selectedOrg).then((shiftPattern: any) => {
        dispatch({ type: 'SHIFT_PATTERN_LIST', payload: shiftPattern });
        setShiftPatternOptions(shiftPattern);
      }).catch((error: any) => {
        dispatch({ type: 'SHIFT_PATTERN_LIST', payload: [] });
        dispatch({ type: 'SHIFTPATTERN_ONCHANGE', payload: '' });
        setShiftPatternOptions([]);
      });
    } else {
      dispatch({ type: 'SHIFT_PATTERN_LIST', payload: [] });
      dispatch({ type: 'SHIFTPATTERN_ONCHANGE', payload: '' });
      setShiftPatternOptions([]);
    }
  }, [selectedOrg, setShiftPatternOptions, staffEmploymentId]);

  const history = useHistory();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === selectedOrg);

  const checkUserRole = staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  const isShowStaffLaberCost = (workingForm?: number | null) => {
    if (workingForm === undefined || workingForm === null) return true;
    if (workingForm === Values.WorkingForm.FullTime.value && checkUserRole?.usabilityGeneralItem2 === 1) return true;
    if (workingForm === Values.WorkingForm.PartTime.value && checkUserRole?.usabilityGeneralItem3 === 1) return true;
    return false;
  }

  return (
    <ShiftSidebarTemplate pageTitle="勤務スケジュール">
      <BlockUI blocking={isLoading}>
        <dailyShiftContext.Provider value={contextInitialState}>
          <FormContents scroll={true}>
            <Filters dispatch={dispatch} businessIds={businessIds} setBusinessIds={setBusinessIds} />
            <AccordionPanel
              text="当日計・累計"
              color={grayScale.gray100}
              callBack={() => {}}
              defaultExpand={true}
            >
              <div style={{ width: '100%'}}>
                <table
                  className="dailyCount"
                  style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    border: '1px solid #CEDAEA'
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan={11}>当日計</th>
                      <th colSpan={7}>累計</th>
                    </tr>
                    <tr>
                      <th rowSpan={2}/>
                      <th rowSpan={2}>売上</th>
                      <th colSpan={3}>正社員</th>
                      <th colSpan={3}>アルバイト他</th>
                      <th colSpan={3}>合計</th>
                      <th rowSpan={2}>売上</th>
                      <th colSpan={2}>正社員</th>
                      <th colSpan={2}>アルバイト他</th>
                      <th colSpan={2}>合計</th>
                    </tr>
                    <tr>
                      <th>勤務時間</th>
                      <th>人件費</th>
                      <th>人件費率</th>
                      <th>勤務時間</th>
                      <th>人件費</th>
                      <th>人件費率</th>
                      <th>勤務時間</th>
                      <th>人件費</th>
                      <th>人件費率</th>
                      <th>人件費</th>
                      <th>人件費率</th>
                      <th>人件費</th>
                      <th>人件費率</th>
                      <th>人件費</th>
                      <th>人件費率</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{textAlign: 'left'}}>予算</td>
                      <td rowSpan={2}>{dailyShift?.salesInfo?.salesBudgetShopDaily || 0}</td>
                      <td>-</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostBudgetShopDailyEmployee || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateBudgetShopDailyEmployee || "0.00%"}</td>
                      <td>-</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostBudgetShopDailyPart || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateBudgetShopDailyPart || "0.00%"}</td>
                      <td>-</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostBudgetShopDaily || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateBudgetShopDaily || "0.00%"}</td>
                      <td rowSpan={2}>{dailyShift?.salesInfo?.salesBudgetShopMonthly || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostBudgetShopMonthlyEmployee || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateBudgetShopMonthlyEmployee || "0.00%"}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostBudgetShopMonthlyPart || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateBudgetShopMonthlyPart || "0.00%"}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostBudgetShopMonthly || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateBudgetShopMonthly || "0.00%"}</td>
                    </tr>
                    <tr>
                      <td style={{textAlign: 'left'}}>シフト</td>
                      <td>{dailyShift?.workTimeInfo?.shiftEmployee || "0:00"}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostShiftShopDailyEmployee || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateShiftShopDailyEmployee || "0.00%"}</td>
                      <td>{dailyShift?.workTimeInfo?.shiftPart || "0:00"}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostShiftShopDailyPart || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateShiftShopDailyPart || "0.00%"}</td>
                      <td>{dailyShift?.workTimeInfo?.shift || "0:00"}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostShiftShopDaily || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateShiftShopDaily || "0.00%"}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostShiftShopMonthlyEmployee || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateShiftShopMonthlyEmployee || "0.00%"}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostShiftShopMonthlyPart || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateShiftShopMonthlyPart || "0.00%"}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostShiftShopMonthly || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateShiftShopMonthly || "0.00%"}</td>
                    </tr>
                    <tr>
                      <td style={{textAlign: 'left'}}>実績</td>
                      <td>{dailyShift?.salesInfo?.netSalesShopDaily || 0}</td>
                      <td>{dailyShift?.workTimeInfo?.achievementEmployee || "0:00"}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostAchievementShopDailyEmployee || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateAchievementShopDailyEmployee || "0.00%"}</td>
                      <td>{dailyShift?.workTimeInfo?.achievementPart || "0:00"}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostAchievementShopDailyPart || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateAchievementShopDailyPart || "0.00%"}</td>
                      <td>{dailyShift?.workTimeInfo?.achievement || "0:00"}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostAchievementShopDaily || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateAchievementShopDaily || "0.00%"}</td>
                      <td>{dailyShift?.salesInfo?.netSalesShopMonthly || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostAchievementShopMonthlyEmployee || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateAchievementShopMonthlyEmployee || "0.00%"}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostAchievementShopMonthlyPart || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateAchievementShopMonthlyPart || "0.00%"}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostAchievementShopMonthly || 0}</td>
                      <td>{dailyShift?.laborCostInfo?.laborCostRateAchievementShopMonthly || "0.00%"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </AccordionPanel>
            {isPerformanceMenuVisible
              && checkUserRole && checkUserRole.editable === 1 && createPortal(
                <div className="contextMenu">
                  <ContextMenuShift
                    buttons={menuPerformanceButtons}
                    pageX={contextMenuX}
                    pageY={contextMenuY}
                    parentRef={contextMenuRef}
                    setIsComponentVisible={setPerformanceMenuVisible}
                  />
                </div>,
                bodyNode,
            )}
            {isItemMenuVisible
              && createPortal(
                <div className="contextMenu">
                  <ContextMenuShift
                    buttons={menuItemButtons}
                    pageX={contextMenuX}
                    pageY={contextMenuY}
                    parentRef={contextMenuRef}
                    setIsComponentVisible={setItemMenuVisible}
                  />
                </div>,
                bodyNode,
              )}
            {isTimelineMenuVisible
              && checkUserRole && checkUserRole.editable === 1 && createPortal(
                <div className="contextMenu">
                  <ContextMenuShift
                    buttons={menuTimelineButtons}
                    pageX={contextMenuX}
                    pageY={contextMenuY}
                    parentRef={contextMenuRef}
                    setIsComponentVisible={setTimelineMenuVisible}
                  />
                </div>,
                bodyNode,
            )}

            {defaultTimeStart && defaultTimeEnd && groups.length > 0 && (
              <Timeline
                style={{
                  width: '100%',
                  maxWidth: `${sidebarHeaderWidth
                    + sidebarHeaderRightWidth
                    + 1100}px`,
                  overflowY: 'scroll',
                  maxHeight: `${70}vh`,
                }}
                lineHeight={48}
                itemHeightRatio={0.5}
                rightSidebarWidth={sidebarHeaderRightWidth}
                sidebarWidth={sidebarHeaderWidth}
                groups={groups}
                items={items}
                defaultTimeStart={defaultTimeStart}
                defaultTimeEnd={defaultTimeEnd}
                // visibleTimeStart={defaultTimeStart.valueOf()}
                // visibleTimeEnd={defaultTimeEnd.valueOf()}
                minZoom={12 * 60 * 60 * 1000}
                maxZoom={12 * 60 * 60 * 1000}
                canChangeGroup={false}
                onTimeChange={onTimeChange}
                onItemMove={onItemMove}
                onItemResize={onItemResize}
                moveResizeValidator={moveResizeValidator}
                onItemDeselect={(e: React.SyntheticEvent) => {
                  setSelectedItem({} as TimelineItemBase<number>); // reset
                }}
                selected={selectedItemIdArr}
                onItemSelect={onItemSelect}
                onItemClick={onItemClick}
                onCanvasClick={onCanvasClick}
                groupRenderer={(groupObj: {
                  group: TimelineGroupCustom;
                  isRightSidebar: any;
                }) => {
                  const { group, isRightSidebar } = groupObj;
                  return isRightSidebar ? (
                    // table cell data of 時間 (h) | 当日計
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {/* Left */}
                      <div
                        className="rct-sidebar-row__block"
                        style={{
                          width: !isMacOSOrTablet ? 'calc(20% - 4px)' : `calc(20% - ${getCheckScrollBar / 5 + 0.5}px)`,
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'center',
                        }}
                      >
                        <div
                          className="rct-sidebar-row__block-child"
                          style={{}}
                        >
                          {group.rightData && group.rightData[0]}
                        </div>
                        <div
                          className="rct-sidebar-row__block-child"
                          style={{ borderTop: `1px solid ${grayScale.gray20}` }}
                        >
                          {group.rightData && group.rightData[1]}
                        </div>
                        <div
                          className="rct-sidebar-row__block-child"
                          style={{ borderTop: `1px solid ${grayScale.gray20}` }}
                        >
                          {group.rightData && group.rightData[2]}
                        </div>
                      </div>
                      {/* Right */}
                      <div
                        className="rct-sidebar-row__block"
                        style={{
                          width: !isMacOSOrTablet ? 'calc(80% - 17px)' : `calc(80% - ${getCheckScrollBar > 0 ? getCheckScrollBar : 3.5}px)`,
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <div style={{ width: '25%', textAlign: 'center' }}>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{
                              borderLeft: `1px solid ${grayScale.gray20}`,
                            }}
                          >
                            -
                          </div>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{
                              borderLeft: `1px solid ${grayScale.gray20}`,
                              borderTop: `1px solid ${grayScale.gray20}`,
                            }}
                          >
                            {(group.dateInfo && group.dateInfo[0]) && isShowStaffLaberCost(group.dateInfo[0].workingForm) ? group.dateInfo[0].dailyLaborCostShift : Literal.MASK}
                          </div>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{
                              borderLeft: `1px solid ${grayScale.gray20}`,
                              borderTop: `1px solid ${grayScale.gray20}`,
                            }}
                          >
                            {(group.dateInfo && group.dateInfo[0]) && isShowStaffLaberCost(group.dateInfo[0].workingForm) ? group.dateInfo[0].dailyLaborCostAchievement : Literal.MASK}
                          </div>
                        </div>
                        <div style={{ width: '25%', textAlign: 'center' }}>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{
                              borderLeft: `1px solid ${grayScale.gray20}`,
                            }}
                          >
                            -
                          </div>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{
                              borderLeft: `1px solid ${grayScale.gray20}`,
                              borderTop: `1px solid ${grayScale.gray20}`,
                            }}
                          >
                            {((group.dateInfo && group.dateInfo[0]) && isShowStaffLaberCost(group.dateInfo[0].workingForm)) ? group.laborCostShiftCumulativePeriod : Literal.MASK}
                          </div>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{
                              borderLeft: `1px solid ${grayScale.gray20}`,
                              borderTop: `1px solid ${grayScale.gray20}`,
                            }}
                          >
                            {((group.dateInfo && group.dateInfo[0]) && isShowStaffLaberCost(group.dateInfo[0].workingForm)) ? group.laborCostAchievementCumulativePeriod : Literal.MASK}
                          </div>
                        </div>
                        <div style={{ width: '25%', textAlign: 'center' }}>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{
                              borderLeft: `1px solid ${grayScale.gray20}`,
                              height: '33.3333%',
                            }}
                          >
                            -
                          </div>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{
                              borderLeft: `1px solid ${grayScale.gray20}`,
                              borderTop: `1px solid ${grayScale.gray20}`,
                              height: '66.6666%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {(group.dateInfo && group.dateInfo[0]) && (isShowStaffLaberCost(group.dateInfo[0].workingForm)) ? convertNumberByPrecision(group.dateInfo[0].dailyHourlySalary) : Literal.MASK}
                          </div>
                        </div>
                        <div style={{ width: '25%', textAlign: 'center' }}>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{
                              borderLeft: `1px solid ${grayScale.gray20}`,
                              height: '33.3333%',
                            }}
                          >
                            -
                          </div>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{
                              borderLeft: `1px solid ${grayScale.gray20}`,
                              borderTop: `1px solid ${grayScale.gray20}`,
                              height: '66.6666%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {(group.dateInfo && group.dateInfo[0]) && (isShowStaffLaberCost(group.dateInfo[0].workingForm)) ? convertNumberByPrecision(group.dateInfo[0].dailyTransportation) : Literal.MASK}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // table cell data of 時間 (h) | 当日計
                    <div
                      className="rct-sidebar-group"
                      style={{ display: 'flex' }}
                    >
                      <div
                        className="rct-sidebar-row__block"
                        style={{
                          width: `${sidebarName}px`,
                          padding: '10px 0 10px 20px',
                          whiteSpace: 'pre-wrap',
                          lineHeight: '22px',
                          alignSelf: 'center',
                          fontSize: textFontSize.sm,
                        }}
                      >
                        {/* Start Table left users (avatar + info) */}
                        <div
                          className="d-flex"
                          style={{ alignItems: 'center' }}
                        >
                          <div
                            style={{
                              borderRadius: '50%',
                              overflow: 'hidden',
                              height: '38px',
                              width: '38px',
                            }}
                          >
                            <PhotoFrame
                              src={photoPath(group)}
                              size="34px"
                              isCircle={true}
                              onErrorIconColor="black"
                            />
                          </div>
                          {/* <IconLabelButton
                            onClick={() => {
                                onClickAttendStampList(group);
                            }}
                            text="詳細"
                            isIcon={false}
                            disabled={group.isSupportStaff ? true : false}
                          /> */}
                        </div>

                        <div
                          css={css`
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                          `}
                        >
                          {group.staffCode}
                        </div>
                        <div
                          css={css`
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                          `}
                        >
                          {group.staffName}
                        </div>
                        <div
                          css={css`
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                          `}
                        >
                          {group.isSupportStaff ? `(${group.orgName})` : null}
                        </div>
                        {/* Start Table right users (avatar + info) */}
                      </div>
                      {/* <div
                        className="rct-sidebar-row__block"
                        style={{ width: `${sidebarSupport}px`, borderLeft: `1px solid ${grayScale.gray20}` }}
                      ></div> */}
                      <div
                        className="rct-sidebar-row__block"
                        style={{
                          width: `${sidebarType}px`,
                          textAlign: 'center',
                          borderLeft: `1px solid ${grayScale.gray20}`,
                          borderRight: `1px solid ${grayScale.gray20}`,
                        }}
                      >
                        {/* Start group of 3 row title 希望 シフト 実績 */}
                        <div
                          className="rct-sidebar-row__block-child"
                          style={{}}
                        >
                          希望
                        </div>
                        <div
                          className="rct-sidebar-row__block-child"
                          style={{ borderTop: `1px solid ${grayScale.gray20}` }}
                        >
                          {group.dateInfo[0].shiftAlertMessage && (
                            <div
                              data-tip={
                                group.dateInfo[0].shiftAlertMessage
                              }
                              data-for={`shiftalert-${group.staffCode}`}
                              css={css({
                                width: 'fit-content',
                                display: 'inline-flex',
                                paddingRight: '3px',
                              })}
                            >
                              <Icon
                                type="warning"
                                color={utilityColor.yellow}
                                verticalAlign="text-bottom"
                                size="15px"
                              />
                              <ReactTooltip
                                html={true}
                                id={`shiftalert-${group.staffCode}`}
                              />
                            </div>
                          )}
                          シフト
                        </div>
                        <div
                          className="rct-sidebar-row__block-child"
                          style={{ borderTop: `1px solid ${grayScale.gray20}` }}
                        >
                          {group.dateInfo[0].achievementErrorMessage && (
                            <div
                              data-tip={
                                group.dateInfo[0].achievementErrorMessage
                              }
                              data-for={`error-${group.staffCode}`}
                              css={css({
                                width: 'fit-content',
                                display: 'inline-flex',
                                paddingRight: '3px'
                              })}
                            >
                              <Icon
                                type="remove"
                                color=""
                                verticalAlign="text-bottom"
                                size="15px"
                              />
                              <ReactTooltip
                                html={true}
                                id={`error-${group.staffCode}`}
                              />
                            </div>
                          )}
                          {group.dateInfo[0].isAchievementConfirm && (
                            <div
                              css={css({
                                width: 'fit-content',
                                display: 'inline-flex',
                                paddingRight: '2px',
                              })}
                            >
                              <Icon
                                type="singlecheckRounded"
                                color="#34a659"
                                verticalAlign="text-bottom"
                                size="15px"
                              />
                            </div>
                          )}
                          {!group.dateInfo[0].isAchievementConfirm &&
                            group.dateInfo[0].isAchievementLock && (
                            <div
                              css={css({
                                width: 'fit-content',
                                display: 'inline-flex',
                                paddingRight: '2px',
                              })}
                            >
                              <Icon
                                type="lock"
                                color="#4b4b4b"
                                verticalAlign="text-bottom"
                                size="15px"
                              />
                            </div>
                          )}
                          {group.dateInfo[0].achievementAlertMessage && (
                            <div
                              data-tip={
                                group.dateInfo[0].achievementAlertMessage
                              }
                              data-for={`alert-${group.staffCode}`}
                              css={css({
                                width: 'fit-content',
                                display: 'inline-flex',
                                paddingRight: '3px',
                              })}
                            >
                              <Icon
                                type="warningFill"
                                color={utilityColor.yellow}
                                verticalAlign="text-bottom"
                                size="15px"
                              />
                              <ReactTooltip
                                html={true}
                                id={`alert-${group.staffCode}`}
                              />
                            </div>
                          )}
                          {group.dateInfo[0].achievementInfoMessage && (
                            <div
                              data-tip={
                                group.dateInfo[0].achievementInfoMessage
                              }
                              data-for={`alert-${group.staffCode}`}
                              css={css({
                                width: 'fit-content',
                                display: 'inline-flex',
                                paddingRight: '3px',
                              })}
                            >
                              <Icon
                                type="warning"
                                color={utilityColor.yellow}
                                verticalAlign="text-bottom"
                                size="15px"
                              />
                              <ReactTooltip
                                html={true}
                                id={`alert-${group.staffCode}`}
                              />
                            </div>
                          )}
                          実績
                          {/* End group of 3 row title 希望 シフト 実績 */}
                        </div>
                      </div>
                    </div>
                  );
                }}
                itemRenderer={(itemRenderer: {
                  item: TimelineItemBase<number>;
                  itemContext: ItemContext;
                  getItemProps: (props: GetItemsProps) => {};
                  getItemBreaktimeProps: (props: GetItemsProps) => {};
                  getResizeProps: (
                    propsOverride?: GetResizeProps
                  ) => ItemRendererGetResizePropsReturnType;
                }) => {
                  const {
                    item,
                    itemContext,
                    getItemProps,
                    getResizeProps,
                    getItemBreaktimeProps,
                  } = itemRenderer;
                  const {
                    left: leftResizeProps,
                    right: rightResizeProps,
                  } = getResizeProps();
                  // Start table data cell right
                  return (
                    <div
                      {...getItemProps({
                        ...item.itemProps,
                        style: {
                          color: 'white',
                          borderStyle: 'solid',
                          borderWidth: 1,
                          borderRadius: 4,
                          borderLeftWidth: itemContext.selected ? 1 : 0,
                          borderRightWidth: itemContext.selected ? 1 : 1,
                          ...item.itemProps?.style,
                        },
                      })}
                    >
                      {itemContext.useResizeHandle ? (
                        <div {...leftResizeProps} />
                      ) : null}

                      <div
                        style={{
                          height: itemContext.dimensions.height,
                          overflow: 'hidden',
                          paddingLeft: 3,
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          position: 'relative',
                        }}
                      >
                        {/* Start time line 10:00 ~ 18:00 */}
                        {item.title}
                        {item.breaks
                          && item.breaks.map((br, i) => (
                            <div
                              key={i}
                              {...getItemBreaktimeProps({
                                style: { background: 'white' },
                                breakId: i,
                              })}
                            />
                          ))}
                        {/* Start time line 10:00 ~ 18:00 */}
                      </div>

                      {itemContext.useResizeHandle ? (
                        <div {...rightResizeProps} />
                      ) : null}
                    </div>
                  );
                  // Start table data cell right
                }}
              >
                <TimelineHeaders
                  className="sticky"
                  calendarHeaderStyle={{ color: grayScale.gray60 }}
                  style={{ position: 'sticky', zIndex: 82, top: 0 }}
                >
                  <SidebarHeader>
                    {({ getRootProps }) => (
                      <div {...getRootProps()} style={{ display: 'flex' }}>
                        <div
                          className="rct-sidebar-row__block"
                          style={{
                            width: `${sidebarName}px`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '14px',
                          }}
                        >
                          氏名
                        </div>
                        {/* <div
                            className="rct-sidebar-row__block"
                            style={{
                              width: `${sidebarSupport}px`,
                              borderLeft: `1px solid ${grayScale.gray20}`,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "14px",
                            }}
                          >
                            休暇
                          </div> */}
                        <div
                          className="rct-sidebar-row__block"
                          style={{
                            width: `${sidebarType}px`,
                            borderLeft: `1px solid ${grayScale.gray20}`,
                            borderRight: `1px solid ${grayScale.gray20}`,
                          }}
                        />
                      </div>
                    )}
                  </SidebarHeader>
                  <SidebarHeader variant="right">
                    {({ getRootProps, data }) => (
                      <div
                        {...getRootProps()}
                        style={{
                          maxWidth: isMacOSOrTablet ? `${sidebarHeaderRightWidth - getCheckScrollBar - 2}px` : `${sidebarHeaderRightWidth - 19}px`,
                          minWidth: isMacOSOrTablet ? `${sidebarHeaderRightWidth - getCheckScrollBar - 2}px` : `${sidebarHeaderRightWidth - 19}px`,
                          width: '100%',
                          textAlign: 'center',
                          borderLeft: '1px solid #d3d3d3',
                          lineHeight: '22px',
                          fontSize: '12px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            // maxWidth: `${sidebarHeaderRightWidth-2}px`,
                            width: '20%',
                            textAlign: 'center',
                            lineHeight: '22px',
                            fontSize: '12px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <div
                            style={{
                              borderBottom: `1px solid ${grayScale.gray20}`,
                              height: '100%',
                            }}
                          >
                            時間 (h)
                          </div>
                          <div style={{ height: '100%' }}>当日計</div>
                        </div>
                        <div
                          style={{
                            // maxWidth: `${sidebarHeaderRightWidth-2}px`,
                            width: '80%',
                            textAlign: 'center',
                            borderLeft: '1px solid #d3d3d3',
                            lineHeight: '22px',
                            fontSize: '12px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <div
                            style={{
                              borderBottom: `1px solid ${grayScale.gray20}`,
                            }}
                          >
                            人件費
                          </div>
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <div
                              style={{
                                width: '25%',
                                textAlign: 'center',
                                borderRight: `1px solid ${grayScale.gray20}`,
                              }}
                            >
                              当日計
                            </div>
                            <div
                              style={{
                                width: '25%',
                                textAlign: 'center',
                                borderRight: `1px solid ${grayScale.gray20}`,
                              }}
                            >
                              累計
                            </div>
                            <div
                              style={{
                                width: '25%',
                                textAlign: 'center',
                                borderRight: `1px solid ${grayScale.gray20}`,
                              }}
                            >
                              時給
                            </div>
                            <div
                              style={{
                                width: '25%',
                                textAlign: 'center',
                              }}
                            >
                              日額交通費
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </SidebarHeader>
                  {/* Render date on header 5 6 7 8 9 10 11 12 13 14 15 16*/}
                  <DateHeader
                    unit="hour"
                    labelFormat="H"
                    height={47}
                    style={{ color: grayScale.gray03 }}
                  />
                </TimelineHeaders>
              </Timeline>
            )}
          </FormContents>

          {/* Confirm shift modal */}
          <Modal
            open={openConfirmModal}
            closeHandler={() => {
              setOpenConfirmModal(false);
            }}
            title="シフトの確定"
            note={
              dailyShift?.isShiftConfirm
                ? 'シフト確定を解除します。よろしいですか？'
                : 'シフトを確定します。よろしいですか？'
            }
            submitText={dailyShift?.isShiftConfirm ? '解除する' : '確定する'}
            onSubmit={submitShiftLockout}
            closeText="キャンセル"
          />

          {/* Confirm delete modal */}
          <Modal
            open={openDeleteModal}
            closeHandler={() => {
              setOpenDeleteModal(false);
            }}
            title="シフトの削除"
            note="シフトを削除します。よろしいですか？"
            submitText="削除する"
            onSubmit={() => {
              deleteItem(selectedItem?.idToString!);
              setOpenDeleteModal(false);
            }}
            closeText="キャンセル"
          />

          <ModalForm
            open={openRegisterModal}
            closeHandler={() => {
              setOpenRegisterModal(false);
            }}
            title="支援スタッフの追加"
            submitText="追 加"
            closeText="閉じる"
            onSubmit={onSubmit}
            width="60%"
            customStyleBody={css({ minHeight: '300px', padding: '12px 20px' })}
          >
            <VerticalLabelTextLayout label="対象日" variable={moment(dateFromStr).format('YYYY/MM/DD')} />
            <OrganizationFilteredTree
              functionType={functionType}
              staffCode=""
              orgLabel="店舗"
              targetdayForm={new Date(dateFromStr)}
              targetdayTo={new Date(dateFromStr)}
              initOrgValue={orgCodeModal}
              orgCallback={(args: string | Array<string>) => {
                setOrgCodeModal(String(args));
              }}
              showFilterCategory={true}
              notSetLoginStaffCode={true}
              customSelectOrg={true}
            />

            <SelectForm
              label="スタッフ"
              name="staffs"
              options={shiftDailyStaffOptions}
              islabelLong={true}
              value={state.staffCode}
              setValue={(type: string) => {
                dispatch({
                  type: 'STAFF_CODE',
                  payload: type,
                });
                setStaffCode(type);
              }}
            />

            <VerticalLabelFormLayoutV2
              label="勤務日種別"
              customCss={css({ color: textColor.main })}
              input={(
                <div css={css({ width: '100%', marginBottom: '20px' })}>
                  <VerticalLabelSelectFormV2
                    label=""
                    placeholder="通常"
                    name="workdayType"
                    value={state.holidayId && state.isLegal ? 'legal' : state.holidayId ? 'scheduled' : 'normal'}
                    setValue={(type: string) => {
                      dispatch({
                        type: 'WORKDAY_TYPE_ONCHANGE',
                        payload: type,
                      });
                    }}
                    options={getWorkdayOptions(state.distinctionHoliday, true)}
                    menuPlacement="top"
                  />
                </div>
              )}
              zIndex={0}
            />

            <VerticalLabelTextLayout label="出勤組織" variable={state?.orgName} />

            <VerticalLabelFormLayout
              label="シフトパターン"
              // customCss={css({ color: textColor.main })}
              input={(
                <div css={css({ width: '100%' })}>
                  <VerticalLabelSelectForm
                    label=""
                    placeholder="通常出勤"
                    name="shiftpattern"
                    value={state.shiftPatternId || ''}
                    setValue={(shiftpatternId) => {
                      dispatch({
                        type: 'SHIFTPATTERN_ONCHANGE',
                        payload: shiftpatternId,
                      });
                    }}
                    options={[{ label: '-', value: '' }].concat(
                      shiftPatternOptions.map((pattern) => ({
                        label: pattern.shiftPatternName,
                        value: pattern.shiftPatternId,
                      })),
                    )}
                    disable={!!state?.holidayId}
                    menuPlacement="top"
                  />
                </div>
              )}
            />

            <BodyText customStyle={css({ display: 'block' })}>シフト時間</BodyText>

            {
            state && (
              <ShiftPatternDetailList
                shiftDetail={state.shiftDetailList}
                isDisable={!!(state.shiftPatternId || state.holidayId)}
                businessAttendChoiceOptions={businessAttendChoiceOptions}
                dispatch={dispatch}
                remote={remote}
              />
            )
            }
          </ModalForm>
          {openShiftPatternModal && <ModalRegisterShift orgName={state?.orgName} />}
          {openShiftPatternStaffModal && <ModalRegisterOtherStaffShift orgName={state?.orgName} />}
        </dailyShiftContext.Provider>
      </BlockUI>
    </ShiftSidebarTemplate>
  );
};

export default DailyShift;
