import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';

import { createOrUpdateExecutive, getExecutiveByExecutiveId } from 'api/executive';
import ExecutiveDomain from 'domain/master/labor/executive';
import useToastNotification from 'hooks/useToastNotification';


type ExecutiveDomainKey = keyof Pick<ExecutiveDomain, 'executiveCode'|'executiveName'>;

export const useExecutiveAddForm = (executiveId?: string) => {
  let isEdit = false;
  if (executiveId) {
    isEdit = true;
  } else {
    isEdit = false;
  }

  const [detailMode, setDetailMode] = useState(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  // modal confirm
  const [toastModalOpen, setToastModalOpen] = useState(false);

  // Modal alert
  const { successNotification, errorNotification } = useToastNotification();
  const onSubmit = async (values: ExecutiveDomain) => {
    if (values.executiveCode === null) {
      errorNotification('役職コードを入力してください。');
      return false;
    }
    if (values.executiveName === null) {
      errorNotification('役職名を入力してください。');
      return false;
    }
    if ((/^ *$/.test(values.executiveCode) || /^ *$/.test(values.executiveName)) && (values.executiveName !== '' && values.executiveCode !== '')) {
      errorNotification('コードに半角、全角スペースは入力できません。');
      return false;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
    } else {
      try {
        const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
        const response = await createOrUpdateExecutive(
          companyCode, false, values.getRawData(), isEdit,
        );
        if (!response.errors) {
          setConfirmModalOpen(false);
          successNotification('更新しました。');
        }
      } catch (error) {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage} \n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    }
    return false;
  };

  const formik = useFormik({
    initialValues: ExecutiveDomain.generateInitial(),
    validationSchema: false,
    onSubmit,
  });

  useEffect(() => {
    if (executiveId) {
      getExecutiveByExecutiveId(executiveId).then((response: any) => {
        if (response) {
          formik.setValues(new ExecutiveDomain(response));
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executiveId]);


  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    setToastModalOpen,
  };
};

export default {
  useExecutiveAddForm,
};
