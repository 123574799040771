/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Row, Col } from 'react-grid-system';

import {productColor, utilityColor} from 'components/styles';
import BodyText from 'components/atoms/BodyText';
import TextInput from 'components/atoms/Form/TextInput';
import FlexBox from 'components/atoms/FlexBox';
import FormLabel from 'components/atoms/Form/FormLabel';

const styles = {
  wrapper: css({
    width: '114px',
  }),
  input: css({
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  }),
  addon: css({
    width: '30px',
    height: '100%',
    textAlign: 'center',
    lineHeight: '36px',
    backgroundColor: productColor.primaryM95,
  }),
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
    minWidth: '100px',
  }),
};

const PercentInput: React.FC<{
  name: string;
  label: string;
  value: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  name, label, value, onChange, disabled = false
}) => (
    <div css={styles.container}>
        <FlexBox>
            <div css={styles.label}>
            <FormLabel
                label={label}
            />
            </div>
            <div css={styles.select}>
            <Row>
                <Col>
                    <TextInput
                    type="number"
                    name={name}
                    label={label}
                    value={value}
                    onChange={onChange}
                    customStyle={styles.input}
                    disable={disabled}
                    />
                </Col>
                <Col>
                    <div css={styles.addon}>
                    <BodyText color={utilityColor.black}>%</BodyText>
                    </div>
                </Col>
            </Row>
            </div>
        </FlexBox>
    </div>
);

export default PercentInput;
