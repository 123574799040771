/* eslint-disable eqeqeq */
/** @jsx jsx */
import React, { useCallback, useEffect, useMemo } from 'react';
import { css, jsx } from '@emotion/core';
import moment from 'moment';
import { Link } from 'react-router-dom';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import SortIcon from 'components/molecules/SortIcon';
import { grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';
import { useDownload } from './hook';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';

const styles = css({
  fontWeight: 400,
  fontSize: 14,
});

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 1,
});

const formatterPercent = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'percent',
});

const DEFAULT_SORT_FIELD = { fieldPath: 'targetDate', sort: 'asc', sortType: 'date' } as FieldSortState

const DailySalesDateTable: React.FC<{
    listDailySalesDate: any;
    stateSymbol: any;
    orgCode:any;
    orgName:any;
    selectedDateFrom:any;
    selectedDateTo:any;
    targetPeriodData:any;
    roleScreen:any;
  }> = ({
    listDailySalesDate,
    stateSymbol,
    orgCode,
    orgName,
    selectedDateFrom,
    selectedDateTo,
    targetPeriodData,
    roleScreen
  }) => {

    const listData = useMemo(() => listDailySalesDate.slice(0, -1), [listDailySalesDate])
    const totalData = useMemo(() => listDailySalesDate.slice(-1), [listDailySalesDate])

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(listData, DEFAULT_SORT_FIELD);

    const classNegative = (number: any) => {
      if (number < 0) {
        return 'colorRed';
      }
      return '';
    };

    const { downloadDaySeparateSalesManagementCosCsv, downloadDaySeparateSalesManagementCosExcel } = useDownload();

    const searchResult:any[] = [];

    const targetStoresData = `対象店舗: ${orgName}`;

    const headerInformation = [
      ['', '', '税抜売上', '', '', '', '', '', '', '', '', '日次収支利益', '', '', '', 'コスト管理', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      ['', '', '当日', '', '', '', '累計', '', '', '', '', '当日', '', '累計', '', 'Fコスト', '', '', '', 'Lコスト', '', '', '', '', '', '概算諸経費', '', '', '', ''],
      ['状態', '日', '実績', '予算', '達成率', '目標差異', '実績', '予算', '達成率', '目標差異', '売上(税込)', '実績', '利益率', '実績', '利益率', 'フード', 'ドリンク', '合計', '売上対比(税抜)', '社員', 'アルバイト', '合計', '売上対比(税抜)', 'FL合計', 'FL売上対比', '合計', '売上対比(税抜)', '組数', '客数', '客単価(税抜)']
    ];

    let mergedRegions:any[] = [];
    const codeColumnIndex:any[] = [];
    const data = {
      footer: '',
      formName: '【日別】売上管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult,
      totalInformation: [],
    };

    const dataExcel = {
      formName: '【日別】売上管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex,
    };

    const handleDownloadDaySeparateSalesManagementCosCsv = () => {
      downloadDaySeparateSalesManagementCosCsv(data);
    };

    const handleDownloadDaySeparateSalesManagementCosExcel = () => {
      if (mergedRegions) {
        mergedRegions = [];
      }
      // merge col row one
      mergedRegions.push([3, 3, 2, 9]);
      mergedRegions.push([3, 3, 11, 14]);
      mergedRegions.push([3, 3, 15, 26]);

      // merge col row two
      mergedRegions.push([4, 4, 2, 5]);
      mergedRegions.push([4, 4, 6, 9]);
      mergedRegions.push([4, 4, 11, 12]);
      mergedRegions.push([4, 4, 13, 14]);
      mergedRegions.push([4, 4, 15, 18]);
      mergedRegions.push([4, 4, 19, 22]);
      mergedRegions.push([4, 4, 25, 26]);

      // merge row
      mergedRegions.push([3, 5, 0, 0]);
      mergedRegions.push([3, 5, 1, 1]);
      mergedRegions.push([3, 5, 10, 10]);
      mergedRegions.push([3, 5, 27, 27]);
      mergedRegions.push([3, 5, 28, 28]);
      mergedRegions.push([3, 5, 29, 29]);
      mergedRegions.push([4, 5, 23, 23]);
      mergedRegions.push([4, 5, 24, 24]);
      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [0];
      dataExcel.headerInformation = [
        ['状態', '日', '税抜売上', '', '', '', '', '', '', '', '売上(税込)', '日次収支利益', '', '', '', 'コスト管理', '', '', '', '', '', '', '', '', '', '', '', '組数', '客数', '客単価(税抜)'],
        ['', '', '当日', '', '', '', '累計', '', '', '', '', '当日', '', '累計', '', 'Fコスト', '', '', '', 'Lコスト', '', '', '', 'FL合計', 'FL売上対比', '概算諸経費', '', '', '', ''],
        ['', '', '実績', '予算', '達成率', '目標差異', '実績', '予算', '達成率', '目標差異', '', '実績', '利益率', '実績', '利益率', 'フード', 'ドリンク', '合計', '売上対比(税抜)', '社員', 'アルバイト', '合計', '売上対比(税抜)', '', '', '合計', '売上対比(税抜)', '', '', '']
      ];

      return downloadDaySeparateSalesManagementCosExcel(dataExcel);
    };

    useEffect(() => {
      if (listDailySalesDate) {
        // content
        [...dataSort, ...totalData] .map((saleDate: any, index: number) => {
          const searchItem: any[] = [];
          searchItem.push(
            (index < listDailySalesDate.length - 1) ? (stateSymbol as any)[saleDate.status] : '',
            (index < listDailySalesDate.length - 1) ? moment(saleDate.targetDate).format('YYYY-MM-DD(ddd)') : '合計',
            formatter.format(saleDate.netSales),
            formatter.format(saleDate.salesBudget),

            formatter.format(saleDate.salesAchievementRate)+'%',
            formatter.format(saleDate.diffNetSales),
            formatter.format(saleDate.cumulativeSales),
            formatter.format(saleDate.cumulativeSalesBudget),
            formatter.format(saleDate.cumulativeSalesAchievementRate)+'%',

            formatter.format(saleDate.diffCumulativeNetSales),
            formatter.format(saleDate.totalSales),
            formatter.format(saleDate.profit),
            formatter.format(saleDate.profitRate)+'%',
            formatter.format(saleDate.cumulativeProfit),

            formatter.format(saleDate.cumulativeProfitRate)+'%',
            formatter.format(saleDate.foodCost),
            formatter.format(saleDate.drinkCost),
            formatter.format(saleDate.totalFoodDrinkCost),
            formatterPercent.format(saleDate.foodAchievementRate),

            formatter.format(saleDate.employeeSalary),
            formatter.format(saleDate.partSalary),
            formatter.format(saleDate.totalLaborCost),
            formatter.format(saleDate.laborAchievementRate)+'%',
            formatter.format(saleDate.totalFoodLaborCost),

            formatter.format(saleDate.foodLaborAchievementRate)+'%',
            formatter.format(saleDate.othreExpenses),
            formatter.format(saleDate.expensesAchievementRate)+'%',
            formatter.format(saleDate.customers),
            formatter.format(saleDate.guestCnt),
            formatter.format(saleDate.customerUnitPrice),

          );
          searchResult.push(searchItem);
        });
      }
    }, [listDailySalesDate, searchResult]);

    const MultiLineText = ({ text }: { text: string }) => {
      const texts = text.split('\n').map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item}
            <br />
          </React.Fragment>
        );
      });
      return <span style={{ margin: 'auto' }}>{texts}</span>;
    };

    const renderHeaderColumn = useCallback((displayName: string, columnSortField: FieldSortState, rowSpan: number, className: string = "") => (
      <th rowSpan={rowSpan} className={className} onClick={e => sortByFieldPath(columnSortField.fieldPath, columnSortField.sortType)}>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <MultiLineText text={displayName}/>
          {sortField.fieldPath === columnSortField.fieldPath && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
          {sortField.fieldPath !== columnSortField.fieldPath && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
        </div>
      </th>
     ), [sortField]);

    return (
      <div>

        {
          roleScreen && roleScreen.downloadFlag === 1 && (
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleDownloadDaySeparateSalesManagementCosCsv}
                iconType="download"
                text=" CSV出力"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleDownloadDaySeparateSalesManagementCosExcel}
                iconType="download"
                text=" EXCEL出力"
              />
            </FlexBoxItem>
          </FlexBox>
        )}

        <div
          className="table-responsive"
          style={{
            display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', maxHeight: 600,
          }}
        >
          <table id="salesPageForm" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }}>
            <thead>
              <tr role="row">
                { renderHeaderColumn('状態', { fieldPath: 'status', sort: 'asc', sortType: 'number'}, 3) }
                { renderHeaderColumn('日', { fieldPath: 'targetDate', sort: 'asc', sortType: 'date'}, 3) }
                <th colSpan={8}>税抜売上</th>
                { renderHeaderColumn('売上(税込)', { fieldPath: 'totalSales', sort: 'asc', sortType: 'number'}, 3) }
                <th colSpan={4}>日次収支利益</th>
                <th colSpan={12}>コスト管理</th>
                { renderHeaderColumn('組数', { fieldPath: 'customers', sort: 'asc', sortType: 'number'}, 3) }
                { renderHeaderColumn('客数', { fieldPath: 'guestCnt', sort: 'asc', sortType: 'number'}, 3) }
                { renderHeaderColumn(`客単価\n(税抜)`, { fieldPath: 'customerUnitPrice', sort: 'asc', sortType: 'number'}, 3) }
              </tr>
              <tr className="subThead">
                <th colSpan={4} className="color_merge">当日</th>
                <th colSpan={4} className="color_merge">累計</th>
                <th colSpan={2} className="color_merge">当日</th>
                <th colSpan={2} className="color_merge">累計</th>
                <th colSpan={4} className="color_merge">Fコスト</th>
                <th colSpan={4} className="color_merge">Lコスト</th>
                { renderHeaderColumn('FL合計', { fieldPath: 'totalFoodLaborCost', sort: 'asc', sortType: 'number'}, 2, "color_merge") }
                { renderHeaderColumn('FL売上対比', { fieldPath: 'foodLaborAchievementRate', sort: 'asc', sortType: 'number'}, 2, "color_merge") }
                <th colSpan={2} className="color_merge">概算諸経費</th>
              </tr>
              <tr className="color_merge_two subThead2">
                {/* 税抜売上 */}
                { renderHeaderColumn('実績', { fieldPath: 'netSales', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('予算', { fieldPath: 'salesBudget', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('達成率', { fieldPath: 'salesAchievementRate', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('目標差異', { fieldPath: 'diffNetSales', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('実績', { fieldPath: 'cumulativeSales', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('予算', { fieldPath: 'cumulativeSalesBudget', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('達成率', { fieldPath: 'cumulativeSalesAchievementRate', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('目標差異', { fieldPath: 'diffCumulativeNetSales', sort: 'asc', sortType: 'number'}, 1) }
                {/* 日次収支利益 */}
                { renderHeaderColumn('実績', { fieldPath: 'profit', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('利益率', { fieldPath: 'profitRate', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('実績', { fieldPath: 'cumulativeProfit', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('利益率', { fieldPath: 'cumulativeProfitRate', sort: 'asc', sortType: 'number'}, 1) }
                {/* Fコスト */}
                { renderHeaderColumn('フード', { fieldPath: 'foodCost', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('ドリンク', { fieldPath: 'drinkCost', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('合計', { fieldPath: 'totalFoodDrinkCost', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('売上対比(税抜)', { fieldPath: 'foodAchievementRate', sort: 'asc', sortType: 'number'}, 1) }
                {/* Lコスト */}
                { renderHeaderColumn('社員', { fieldPath: 'employeeSalary', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('アルバイト', { fieldPath: 'partSalary', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('合計', { fieldPath: 'totalLaborCost', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('売上対比(税抜)', { fieldPath: 'laborAchievementRate', sort: 'asc', sortType: 'number'}, 1) }
                {/* 概算諸経費 */}
                { renderHeaderColumn('合計', { fieldPath: 'othreExpenses', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('売上対比(税抜)', { fieldPath: 'expensesAchievementRate', sort: 'asc', sortType: 'number'}, 1) }
              </tr>
            </thead>
              {
                  dataSort && dataSort.length > 0 ? dataSort.map((saleDate: any, index: number) => (
                    <tbody css={styles} key={index}>
                      <tr>
                        <td className="textCenter">
                        {
                          saleDate.status !== 99
                          ? <Link target={"_blank"} to={{ pathname: `/salesDailyReport/${orgCode}/${moment(saleDate.targetDate).format('YYYY-MM-DD')}` }}>
                            {(stateSymbol as any)[saleDate.status]}
                            </Link>
                          :  (stateSymbol as any)[saleDate.status]
                        }
                        </td>
                        <td>
                          <Link target={"_blank"} to={{ pathname: `/shopSeparateSalesManagementCos/all/${moment(selectedDateFrom).format('YYYY-MM-DD')}/${moment(selectedDateTo).format('YYYY-MM-DD')}` }}>
                            {moment(saleDate.targetDate).format('YYYY-MM-DD(ddd)')}
                          </Link>
                        </td>
                        <td className={classNegative(saleDate.netSales)}>{formatter.format(saleDate.netSales)}</td>
                        <td className={classNegative(saleDate.salesBudget)}>{formatter.format(saleDate.salesBudget)}</td>
                        <td className={classNegative(saleDate.salesAchievementRate)}>{formatter.format(saleDate.salesAchievementRate)+'%'}</td>
                        {/* 達成率 */}
                        <td className={classNegative(saleDate.diffNetSales)}>{formatter.format(saleDate.diffNetSales)}</td>
                        <td className={classNegative(saleDate.cumulativeSales)}>{formatter.format(saleDate.cumulativeSales)}</td>
                        <td className={classNegative(saleDate.cumulativeSalesBudget)}>{formatter.format(saleDate.cumulativeSalesBudget)}</td>
                        {/* 達成率 */}
                        <td className={classNegative(saleDate.cumulativeSalesAchievementRate)}>{formatter.format(saleDate.cumulativeSalesAchievementRate)+'%'}</td>
                        <td className={classNegative(saleDate.diffCumulativeNetSales)}>{formatter.format(saleDate.diffCumulativeNetSales)}</td>
                        <td className={classNegative(saleDate.totalSales)}>{formatter.format(saleDate.totalSales)}</td>
                        <td className={classNegative(saleDate.profit)}>{formatter.format(saleDate.profit)}</td>
                        {/* 利益率 */}
                        <td className={classNegative(saleDate.profitRate)}>{formatter.format(saleDate.profitRate)+'%'}</td>
                        <td className={classNegative(saleDate.cumulativeProfit)}>{formatter.format(saleDate.cumulativeProfit)}</td>
                        {/* 利益率 */}
                        <td className={classNegative(saleDate.cumulativeProfitRate)}>{formatter.format(saleDate.cumulativeProfitRate)+'%'}</td>
                        <td className={classNegative(saleDate.foodCost)}>{formatter.format(saleDate.foodCost)}</td>

                        {/* ドリンク */}
                        <td className={classNegative(saleDate.drinkCost)}>{formatter.format(saleDate.drinkCost)}</td>
                        {/* 合計 */}
                        <td className={classNegative(saleDate.totalFoodDrinkCost)}>{formatter.format(saleDate.totalFoodDrinkCost)}</td>

                        {/* 売上対比(税抜) */}
                        <td className={classNegative(saleDate.foodAchievementRate)}>{formatter.format(saleDate.foodAchievementRate)+'%'}</td>

                        <td className={classNegative(saleDate.employeeSalary)}>{formatter.format(saleDate.employeeSalary)}</td>
                        <td className={classNegative(saleDate.partSalary)}>{formatter.format(saleDate.partSalary)}</td>
                        <td className={classNegative(saleDate.totalLaborCost)}>{formatter.format(saleDate.totalLaborCost)}</td>

                        {/* 売上対比(税込) */}
                        <td className={classNegative(saleDate.laborAchievementRate)}>{formatter.format(saleDate.laborAchievementRate)+'%'}</td>

                        <td className={classNegative(saleDate.totalFoodLaborCost)}>{formatter.format(saleDate.totalFoodLaborCost)}</td>
                        {/* FL売上対比 */}
                        <td className={classNegative(saleDate.foodLaborAchievementRate)}>{formatter.format(saleDate.foodLaborAchievementRate)+'%'}</td>

                        <td className={classNegative(saleDate.othreExpenses)}>{formatter.format(saleDate.othreExpenses)}</td>
                        {/* FL売上対比 */}
                        <td className={classNegative(saleDate.expensesAchievementRate)}>{formatter.format(saleDate.expensesAchievementRate)+'%'}</td>

                        {/* 組数 */}
                        <td className={classNegative(saleDate.customers)}>{formatter.format(saleDate.customers)}</td>
                        <td className={classNegative(saleDate.guestCnt)}>{formatter.format(saleDate.guestCnt)}</td>
                        <td className={classNegative(saleDate.customerUnitPrice)}>{formatter.format(saleDate.customerUnitPrice)}</td>
                      </tr>
                    </tbody>
                  )) : <tbody css={styles}></tbody>
              }
              { listDailySalesDate && listDailySalesDate.length > 0 ? totalData.map((saleDate: any, index: number) => (
                  <tbody css={styles}>
                    <tr className="custom_total">
                    <td className="textCenter" />
                    <td className="textLeft">合計</td>
                    <td className={classNegative(saleDate.netSales)}>{formatter.format(saleDate.netSales)}</td>
                    <td className={classNegative(saleDate.salesBudget)}>{formatter.format(saleDate.salesBudget)}</td>
                    <td className={classNegative(saleDate.salesAchievementRate)}>{formatter.format(saleDate.salesAchievementRate)+'%'}</td>
                    {/* 達成率 */}
                    <td className={classNegative(saleDate.diffNetSales)}>{formatter.format(saleDate.diffNetSales)}</td>
                    <td className={classNegative(saleDate.cumulativeSales)}>{formatter.format(saleDate.cumulativeSales)}</td>
                    <td className={classNegative(saleDate.cumulativeSalesBudget)}>{formatter.format(saleDate.cumulativeSalesBudget)}</td>
                    {/* 達成率 */}
                    <td className={classNegative(saleDate.cumulativeSalesAchievementRate)}>{formatter.format(saleDate.cumulativeSalesAchievementRate)+'%'}</td>
                    <td className={classNegative(saleDate.diffCumulativeNetSales)}>{formatter.format(saleDate.diffCumulativeNetSales)}</td>
                    <td className={classNegative(saleDate.totalSales)}>{formatter.format(saleDate.totalSales)}</td>
                    <td className={classNegative(saleDate.profit)}>{formatter.format(saleDate.profit)}</td>
                    {/* 利益率 */}
                    <td className={classNegative(saleDate.profitRate)}>{formatter.format(saleDate.profitRate)+'%'}</td>
                    <td className={classNegative(saleDate.cumulativeProfit)}>{formatter.format(saleDate.cumulativeProfit)}</td>
                    {/* 利益率 */}
                    <td className={classNegative(saleDate.cumulativeProfitRate)}>{formatter.format(saleDate.cumulativeProfitRate)+'%'}</td>
                    <td className={classNegative(saleDate.foodCost)}>{formatter.format(saleDate.foodCost)}</td>

                    {/* ドリンク */}
                    <td className={classNegative(saleDate.drinkCost)}>{formatter.format(saleDate.drinkCost)}</td>
                    {/* 合計 */}
                    <td className={classNegative(saleDate.totalFoodDrinkCost)}>{formatter.format(saleDate.totalFoodDrinkCost)}</td>

                    {/* 売上対比(税抜) */}
                    <td className={classNegative(saleDate.foodAchievementRate)}>{formatter.format(saleDate.foodAchievementRate)+'%'}</td>

                    <td className={classNegative(saleDate.employeeSalary)}>{formatter.format(saleDate.employeeSalary)}</td>
                    <td className={classNegative(saleDate.partSalary)}>{formatter.format(saleDate.partSalary)}</td>
                    <td className={classNegative(saleDate.totalLaborCost)}>{formatter.format(saleDate.totalLaborCost)}</td>

                    {/* 売上対比(税込) */}
                    <td className={classNegative(saleDate.laborAchievementRate)}>{formatter.format(saleDate.laborAchievementRate)+'%'}</td>

                    <td className={classNegative(saleDate.totalFoodLaborCost)}>{formatter.format(saleDate.totalFoodLaborCost)}</td>
                    {/* FL売上対比 */}
                    <td className={classNegative(saleDate.foodLaborAchievementRate)}>{formatter.format(saleDate.foodLaborAchievementRate)+'%'}</td>

                    <td className={classNegative(saleDate.othreExpenses)}>{formatter.format(saleDate.othreExpenses)}</td>
                    {/* FL売上対比 */}
                    <td className={classNegative(saleDate.expensesAchievementRate)}>{formatter.format(saleDate.expensesAchievementRate)+'%'}</td>

                    {/* 組数 */}
                    <td className={classNegative(saleDate.customers)}>{formatter.format(saleDate.customers)}</td>
                    <td className={classNegative(saleDate.guestCnt)}>{formatter.format(saleDate.guestCnt)}</td>
                    <td className={classNegative(saleDate.customerUnitPrice)}>{formatter.format(saleDate.customerUnitPrice)}</td>
                    </tr>
                  </tbody>
                )) : <tbody css={styles}></tbody>
              }
          </table>
        </div>
      </div>
    );
  };
export default DailySalesDateTable;
