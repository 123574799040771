import moment from 'moment';

import { Manager } from 'api/organization';

export default class ManagerDomain {
  constructor(private rawData: Manager) {
    // do nothing
  }

  get rawDate(): Manager {
    return this.rawData;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  get staffName(): string {
    return this.rawData.staffName || '';
  }

  get startDate(): string {
    return this.rawData.startDate || '';
  }

  get startDateAsDate(): Date {
    return moment(this.rawData.startDate).toDate();
  }

  get endDate(): string|null {
    return this.rawData.endDate || '';
  }

  get endDateAsDate(): Date|null {
    if (!this.rawData.endDate) {
      return null;
    }
    return moment(this.rawData.endDate).toDate();
  }
}
