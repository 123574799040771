import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';
import Icon from 'components/atoms/Icon';
import { iconColor } from 'components/styles';
import { Column } from 'react-table';
import FormContents from 'components/atoms/Form/FormContents';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import DataTable from 'components/organismos/DataTable/DataTable';
import OrganizationCategoryDomain from 'domain/master/general/organizationCategory';
import OrganizationCategoryItemDomain from 'domain/master/general/organizationCategoryItem';
import { deleteCategoryItemById, updateOrganzationCategory, downloadCSV } from 'api/organizationCategory';
import useToastNotification from 'hooks/useToastNotification';
import FormField from 'components/atoms/Form/FormField';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import TextForm from 'components/molecules/TextForm';
import FlexBox from 'components/atoms/FlexBox';
import PrimaryButton from 'components/atoms/Button';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { useOrganizationCategoryItemList } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

type SelectType = 'paid'|'specialHoliday'|'holiday'|'rest';

const SelectType: {[key in SelectType]: SelectType} = {
  paid: 'paid',
  specialHoliday: 'specialHoliday',
  holiday: 'holiday',
  rest: 'rest',
};

const OrganizationCategory: React.FC<{
  initialOrganizationCategoryDomain?: OrganizationCategoryDomain;
  fetchData: () => Promise<void>;
  roleScreen?: any
}> = ({
  initialOrganizationCategoryDomain = OrganizationCategoryDomain.generateInitial(),
  fetchData,
  roleScreen
}) => {
  const {
    organizationCategoryItemList, setOrganizationCategoryItemList,
  } = useOrganizationCategoryItemList(
    initialOrganizationCategoryDomain.organizationCategoryMstHeadId,
  );

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedOrgCatItem, setSelectedOrgCatItem] = useState(
    OrganizationCategoryItemDomain.generateInitial(),
  );
  const [orgCatName, setOrgCatName] = useState(initialOrganizationCategoryDomain.categoryDispName);
  useEffect(() => {
    setOrgCatName(initialOrganizationCategoryDomain.categoryDispName);
  }, [initialOrganizationCategoryDomain.categoryDispName]);

  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((orgCatItem : OrganizationCategoryItemDomain) => {
    setConfirmModalOpen(true);
    setSelectedOrgCatItem(orgCatItem);
  }, []);

  const downloadFileCSV = useCallback(() => {
    downloadCSV(initialOrganizationCategoryDomain.organizationCategoryMstHeadId,
      initialOrganizationCategoryDomain.categoryDispName);
  }, [initialOrganizationCategoryDomain.categoryDispName,
    initialOrganizationCategoryDomain.organizationCategoryMstHeadId]);

  const stateRoleScreen = useSelector((state: Store) => state.auth.roleScreen);
  const userRole = roleScreen ? (stateRoleScreen ? stateRoleScreen : null) : roleScreen;

  const deleteCategoryItem = useCallback(() => {
    deleteCategoryItemById(
      selectedOrgCatItem.organizationCategoryMstDetailId,
    ).then((Response: any) => {
      setOrganizationCategoryItemList(
        organizationCategoryItemList.filter(
          (item) => item.organizationCategoryMstDetailId
          !== selectedOrgCatItem.organizationCategoryMstDetailId,
        ),
      );
      setConfirmModalOpen(false);
      successNotification('削除しました。');
    }).catch((error) => {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.defaultMessage) {
        errorNotification(error.response.data.defaultMessage);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [errorNotification, organizationCategoryItemList,
    selectedOrgCatItem.organizationCategoryMstDetailId,
    setOrganizationCategoryItemList, successNotification]);

  const columns: Array<Column<OrganizationCategoryItemDomain>> = useMemo(() => [
    {
      Header: '項目コード',
      accessor: 'detailCode',
      sortType: 'basic'
    },
    {
      Header: '項目名',
      accessor: 'detailName',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: OrganizationCategoryItemDomain },
        data: Array<OrganizationCategoryItemDomain>
      }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/organizationCategory/edit/${`${cell.row.original.organizationCategoryMstDetailId}`}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            {
              userRole && userRole.editable === 1 && (
              <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                <span
                  style={{ marginLeft: '20px', marginRight: '10px' }}
                  onMouseEnter={() => setIsHoverDelete(true)}
                  onMouseLeave={() => setIsHoverDelete(false)}
                >
                  <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                </span>
              </Link>
              )
            }
          </div>
        );
      },
    },
  ], [openConfirmModal, userRole]);

  return (
    <div>
      <FormContents>
        <FormField>
          <RadioSelectForm
            label=""
            subLabel=""
            items={[
              {
                label: '使用しない',
                value: '0',
              },
              {
                label: '使用する',
                value: '1',
              },
            ]}
            name="inUse"
            value={initialOrganizationCategoryDomain.inUseStr}
            setValue={(e) => {
              const val = e.target.value === '1';
              updateOrganzationCategory(
                val,
                initialOrganizationCategoryDomain.categoryDispName,
                initialOrganizationCategoryDomain.organizationCategoryMstHeadId,
                initialOrganizationCategoryDomain.orgCategoryCode,
                initialOrganizationCategoryDomain.dispSort,
              ).then(() => {
                fetchData();
              });
            }}
            disabled={userRole && userRole.editable === 1 ? false : true}
          />
        </FormField>
        <FormField>
          <TextForm
            name="textForm"
            label="カテゴリ名"
            value={orgCatName}
            onChange={(e) => { setOrgCatName(e.target.value); }}
            onBlur={(e) => {
              if (e.target.value) {
                updateOrganzationCategory(
                  initialOrganizationCategoryDomain.inUse,
                  e.target.value,
                  initialOrganizationCategoryDomain.organizationCategoryMstHeadId,
                  initialOrganizationCategoryDomain.orgCategoryCode,
                  initialOrganizationCategoryDomain.dispSort,
                ).then((organizationCategory) => {
                  setOrgCatName(organizationCategory.categoryDispName);
                  fetchData();
                });
              } else {
                setOrgCatName(initialOrganizationCategoryDomain.categoryDispName);
              }
            }}
            disable={userRole && userRole.editable === 1 ? false : true}
          />
        </FormField>
      </FormContents>
      <div style={{ margin: '8px 16px' }}>
        <FlexBox>
          {
            userRole && userRole.downloadFlag === 1 && (
            <FlexBoxItem>
              <IconLabelButton
                onClick={downloadFileCSV}
                iconType="download"
                text="CSVダウンロード"
              />
            </FlexBoxItem>
            )
          }
          {
            userRole && userRole.importFlag === 1 && (
            <FlexBoxItem>
              <Link to="/organizationCategory/import">
                <PrimaryButton
                  ghost={false}
                  text="インポート"
                />
              </Link>
            </FlexBoxItem>
            )
          }
          {
            userRole && userRole.editable === 1 && (
            <FlexBoxItem grow={1}>
              <div style={{ textAlign: 'right' }}>
                <Link to="/organizationCategory/add">
                  <PrimaryButton
                    ghost={false}
                    text="新規作成"
                  />
                </Link>
              </div>
            </FlexBoxItem>
            )
          }
        </FlexBox>
      </div>
      <div
        css={css`
      table th:nth-child(1) {
        width: 100px;
      }
      table th:nth-child(2) {
        width: 200px;
      }
      table th:nth-child(3) {
        width: 80px;
      }
      `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
          <DataTable
            columns={columns}
            data={organizationCategoryItemList}
            isGlobalFilter={true}
            sortBy={[
              { id: 'detailCode', desc: false }
            ]}
          />
        </div>
      </div>

      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={deleteCategoryItem}
        actionType={ActionType.DELETE}
      />
    </div>
  );
};

export default OrganizationCategory;
