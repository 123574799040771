/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import BodyText from 'components/atoms/BodyText';
import { useShiftDayCalendarContext } from './hooks';

const styles = {
  timeScaleWrapper: css({
    display: 'flex',
    flexWrap: 'nowrap',
  }),
};

const TimeScale: React.FC = () => {
  const { quarterHourPixel, dispStartHour, dispHour } = useShiftDayCalendarContext();
  const range = Array(dispHour + 1).fill(dispStartHour).map((x, y) => x + y);
  return (
    <div css={styles.timeScaleWrapper}>
      {range.map((time) => (
        <React.Fragment key={time}>
          <div
            css={css({ width: quarterHourPixel, flexShrink: 0 })}
          >
            <BodyText
                  // 表示位置を微調整
              customStyle={css({
                position: 'relative',
                right: quarterHourPixel / (String(time).length === 1 ? 4 : 2),
              })}
            >
              {time}
            </BodyText>
          </div>
          {/* 15 */}
          <div
            css={{ width: quarterHourPixel, flexShrink: 0 }}
          />
          {/* 30 */}
          <div
            css={{ width: quarterHourPixel, flexShrink: 0 }}
          />
          {/* 45 */}
          <div
            css={{ width: quarterHourPixel, flexShrink: 0 }}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default TimeScale;
