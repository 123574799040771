/* eslint-disable max-len */
import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import {
  getListHistoryDownloadList,
  getListAttendCSVOutput,
  getAchievementLayout,
  postProcessingHistoryDownload,
  downloadHistoryById,
  deleteHistoryDownloadById,
  cancelHistoryDownloadById,
} from 'api/attendCSVOutput';
import { useFormik } from 'formik';
import moment from 'moment';
import useToastNotification from 'hooks/useToastNotification';
import AttendCSVOutputDomain from 'domain/master/attend/attendCSVOutput';
import { OptionType } from 'components/atoms/Select';
import { getErrorMessageForResponse } from 'utility/errorUtil';

export const useAttendCSVOutput = () => {
  const { successNotification, errorNotification } = useToastNotification();
  const [attendCSVOutput, setAttendCSVOutput] = useState<Array<AttendCSVOutputDomain>>([]);
  const [layoutIdOptions, setLayoutIdOptions] = useState<Array<OptionType>>([]);
  const [isLoading, setLoading] = useState(false);
  const [categoryArr, setCategoryArr] = useState<Array<String>>([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [achivementHistoryId, setAchivementHistoryId] = useState("");
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const pageId = 3;
  const functionType = 2;

  useEffect(() => {
    getListData();
    getListLayoutOption();
  }, []);

  function renderStatusName(status:number) {
    let name = '';
    switch (status) {
      case 0:
        name = '作成中';
      break;
      case 1:
        name = 'ダウンロード';
      break;
      case 2:
        name = '失敗';
      break;

      default:
        break;
    }
    return name;
  }

  const getListData = async () => {
    setLoading(true);
    const params = {
      pageId: pageId,
      loginStaffCode: loginStaffCode
    }
    try {
      const response = await getListHistoryDownloadList(params);
      const newList = response.map((item: any) => {
        return {
          ...item,
          organizationCategoryMstHeadName: Array.from(new Set(item.organizationCategories?.map((x: any) => x?.organizationCategoryMstHeadName))).toString(),
          organizationCategoryMstName: Array.from(new Set(item.organizationCategories?.map((x: any) => x?.organizationCategoryMstName))).toString(),
          statusDownloadName: renderStatusName(item.statusDownload),
        };
      });
      setAttendCSVOutput(newList.map((result: any) => new AttendCSVOutputDomain(result)));
      setLoading(false);
    } catch(error) {
      const message = getErrorMessageForResponse(error);
      errorNotification(message);
    } finally {
      setLoading(false);
    }
  };

  const getListLayoutOption = async () => {
    setLoading(true);
    try {
      const response = await getAchievementLayout(companyCode);
      setLayoutIdOptions(response.map((item: any) => ({
          value: item.layoutId,
          label: item.layoutName,
        }))
      );
      formik.setFieldValue("layoutId", response[0].layoutId);
    } catch(error) {
      const message = getErrorMessageForResponse(error);
      errorNotification(message);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values: any) => {
    // check between date
    const checkTarget = await checkTargetRange(values.targetDateFrom, values.targetDateTo);
    if (!checkTarget) {
      return;
    }
    const submitObj = {
      loginStaffCode: values.loginStaffCode,
      targetDateFrom: moment(values.targetDateFrom).startOf("month").format("YYYY-MM-DD"),
      targetDateTo: moment(values.targetDateTo).endOf("month").format("YYYY-MM-DD"),
      orgCode: values.orgCode,
      employmentId: values.employmentId || "",
      staffCode: values.staffCode === "all" ? "" : values.staffCode,
      layoutId: values.layoutId,
      underFlag: 0,
      createUser: values.loginStaffCode,
      pageId: pageId,
      categoryAry: categoryArr && categoryArr.length > 0 ? String(categoryArr) : '',
      functionType: functionType,
    };
    let layoutName = layoutIdOptions.filter((layout: any) => {
      return layout.value === values.layoutId;
    })[0].label;
    const nameFile = `${layoutName}_${moment(values.targetDateFrom).format('YYYYMM')}_${moment(values.targetDateTo).format('YYYYMM')}_${values.orgCode}`;
      setLoading(true);
      postProcessingHistoryDownload({
        ...submitObj,
        ...{
          targetDate: "",
          viewPeriod: "",
          closingDate: "",
          outputLayoutId: values.layoutId,
          attendFlag: 0,
          flagDownloadAll: 0,
          isUpdate: 0,
          achievementDownloadId: "",
          fileName: nameFile,
        },
      }).then((res: any)=>{
        setLoading(false);
        getListData();
        getListAttendCSVOutput({
          ...submitObj,
          ...{
            achievementDownloadId: res?.attendAchievementDownloadId || "",
            fileName: res?.file.split("/")[2],
            isUpdate: 0,
          },
        }).then((response)=>{
          getListData();
      }).catch((error : any) => {
        setLoading(false);
        const message = getErrorMessageForResponse(error);
        errorNotification(message);
        getListData();
      }).finally(()=>{
        setLoading(false);
      });
      }).catch((error : any) => {
        setLoading(false);
        const message = getErrorMessageForResponse(error);
        errorNotification(message);
      });
  };

  const checkTargetRange = async (strStartDate: Date, strEndDate: Date, isNoMessage?: boolean) => {
    const startDay = new Date(Date.parse(moment(strStartDate, 'YYYY年MM月DD日').format('YYYY/MM/DD')));
    const endDay = new Date(moment(strEndDate, 'YYYY年MM月DD日').format('YYYY/MM/DD'));
    const startDayNextYear = new Date(startDay.getFullYear() + 1, startDay.getMonth(), startDay.getDate());
    if (endDay.getTime() >= startDayNextYear.getTime()) {
      if (!isNoMessage) {
        errorNotification('対象期間の範囲は１年以内で入力してください');
      }
      return false;
    }
    return true;
  };

  const formik = useFormik({
    initialValues: {
      loginStaffCode: loginStaffCode,
      orgCode: sessionStorage.getItem('loginUser.orgCode'),
      targetDateFrom: new Date(),
      targetDateTo: new Date(),
      employmentId: '',
      staffCode: 'all',
      layoutId: ''
    },
    onSubmit,
  });

  const handleCloseConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const handleOpenConfirmModal = useCallback(() => {
    setConfirmModalOpen(true);
  }, []);

  const handleDownloadHistoryById = (achivementHistoryId: string, fileName: string) => {
    setLoading(true);
    const params = {
      achievementHistoryDownloadId: achivementHistoryId,
      pageId: pageId,
      fileName: fileName,
    };
    downloadHistoryById(params, fileName).then(()=>{
    }).catch((error)=>{
      setLoading(false);
      const message = getErrorMessageForResponse(error);
      errorNotification(message);
    }).finally(()=>{
      setLoading(false);
    });
  };

  const handleReloadHistoryDownloadById = (data: any) => {
    const fileName = data.file.split("/")[2];
    const achievementDownloadId = data.attendAchievementDownloadId;
    const dataFrom = data.periodFrom ? data.periodFrom : formik.values.targetDateFrom;
    const dataTo = data.periodTo ? data.periodTo : formik.values.targetDateTo;
    const submitObj = {
      loginStaffCode: loginStaffCode,
      targetDateFrom: moment(dataFrom).startOf("month").format("YYYY-MM-DD"),
      targetDateTo: moment(dataTo).endOf("month").format("YYYY-MM-DD"),
      orgCode: data.orgCode ? data.orgCode : 'all',
      employmentId: "",
      staffCode: "",
      layoutId: data.layoutId,
      underFlag: 0,
      createUser: loginStaffCode,
      pageId: pageId,
      categoryAry: categoryArr && categoryArr.length > 0 ? String(categoryArr) : '',
      functionType: functionType,
    };
      setLoading(true);
      postProcessingHistoryDownload({
        ...submitObj,
        ...{
          targetDate: "",
          viewPeriod: "",
          closingDate: "",
          outputLayoutId: data.layoutId,
          attendFlag: 0,
          flagDownloadAll: 0,
          isUpdate: 1,
          achievementDownloadId: achievementDownloadId,
          fileName: fileName,
        },
      }).then((res: any)=>{
        setLoading(false);
        getListData();
        getListAttendCSVOutput({
          ...submitObj,
          ...{
            achievementDownloadId: achievementDownloadId || "",
            fileName: fileName,
            isUpdate: 1,
          },
        }).then((response)=>{
            getListData();
        }).catch((error : any) => {
          setLoading(false);
          const message = getErrorMessageForResponse(error);
          errorNotification(message);
          getListData();
        }).finally(()=>{
          setLoading(false);
        });
      }).catch((error : any) => {
        setLoading(false);
        const message = getErrorMessageForResponse(error);
        errorNotification(message);
      });
  }

  const handleDeleteHistoryDownloadById = (achivementHistoryId: string) => {
    const params = {
      pageId: pageId,
      achievementHistoryDownloadId: achivementHistoryId,
      loginStaffCode: loginStaffCode
    };
    setLoading(true);
    setConfirmModalOpen(false);
    deleteHistoryDownloadById(params).then((response) => {
      setAttendCSVOutput(response.map((result: any) => new AttendCSVOutputDomain(result)));
        successNotification("削除しました。");
        setLoading(false);
      }).catch(() => {
        setConfirmModalOpen(false);
        setLoading(false);
        errorNotification("データが正常に削除されていません");
      }).finally(()=>{
        setLoading(false);
      });
  };

  const handleCancelHistoryDownloadById = (achivementHistoryId: string) => {
    setLoading(true);
    const params = {
      achievementHistoryDownloadId: achivementHistoryId,
      pageId: pageId,
      loginStaffCode: loginStaffCode,
    };
    cancelHistoryDownloadById(params).then((response)=>{
      setAttendCSVOutput(response.map((result: any) => new AttendCSVOutputDomain(result)));
      setLoading(false);
    }).catch((error)=>{
      setLoading(false);
      const message = getErrorMessageForResponse(error);
      errorNotification(message);
    }).finally(()=>{
      setLoading(false);
    });
  }

  return {
    formik,
    layoutIdOptions,
    attendCSVOutput,
    setAttendCSVOutput,
    isLoading,
    setLoading,
    checkTargetRange,
    categoryArr,
    setCategoryArr,
    achivementHistoryId,
    setAchivementHistoryId,
    confirmModalOpen,
    handleOpenConfirmModal,
    handleCloseConfirmModal,
    handleDownloadHistoryById,
    handleReloadHistoryDownloadById,
    handleCancelHistoryDownloadById,
    handleDeleteHistoryDownloadById,
  };
};

export default useAttendCSVOutput;
