
/**
 * API 勤怠再計算
 */

export interface TransferHolidayStatus {
  staffCode: string,
  staffName: string,
  orgCode: string,
  orgName: string,
  transferDayNumb: number,
}


export default class TransferHolidayStatusDomain {
  constructor(private rawData: TransferHolidayStatus) {
    // do nothing
  }

  static generateInitial(): TransferHolidayStatusDomain {
    return new TransferHolidayStatusDomain({
      staffCode: '',
      staffName: '',
      orgCode: '',
      orgName: '',
      transferDayNumb: 0,
    });
  }

  getRawData(): TransferHolidayStatus {
    return this.rawData;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode:string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  get transferDayNumb():number {
    return this.rawData.transferDayNumb;
  }

  get transferDayNumbStr():string {
    return `${this.rawData.transferDayNumb}日`;
  }

  set transferDayNumb(transferDayNumb:number) {
    this.rawData.transferDayNumb = transferDayNumb;
  }
}
