/* eslint-disable eqeqeq */
/** @jsx jsx */
import React, { useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import moment from 'moment';
import { stateSymbol } from 'components/organismos/master/general/salesPage//SalesManagementList/hookSysboy';
import { Link } from 'react-router-dom';
import { useDownload } from '../hook';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import { grayScale } from 'components/styles';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';

const styles = css({
  fontWeight: 400,
  fontSize: 14,
});
const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 1,
});

const DEFAULT_SORT_FIELD = { fieldPath: 'targetDate', sort: 'asc', sortType: 'date' } as FieldSortState

const SalesFormTable: React.FC<{
    listSalesPageForms: any;
    orgCode:any;
    orgName:any;
    selectedDateFrom: Date;
    selectedDateTo: Date;
    roleScreen: any;
  }> = ({
    listSalesPageForms,
    orgCode,
    orgName,
    selectedDateFrom,
    selectedDateTo,
    roleScreen
  }) => {

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------

    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(listSalesPageForms, DEFAULT_SORT_FIELD);

    const searchResult: any[] = [];

    const { downloadCsv, downloadExcel } = useDownload();

    const headerInformation = [
      ['', '', '', '', '', '', '', '', '', '現金過不足', '', '', '', '小口', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      ['状態', '日/店舗	','売上(税込)', '現金売上', 'CC売上', 'AirPAY', '商品券','商品券釣銭', '掛売', '実過不足', 'レジ過不足','釣銭準備金', '銀行入金額', '食材', '飲材', '雑費', '雑費', 'レジ出金額', '出金差異', 'VOID件数', '強制取消金額', 'オーダー取消金額', '値引・割引金額', '精算日時', '日報申請日時', '日報申請者', '日報承認日時', '日報承認者'],
    ];

    const data = {
      footer: '',
      formName: '【日別】本部確認',
      targetPeriod: '対象期間: '+moment(selectedDateFrom).format('YYYY-MM-DD')+ ' ~ ' +moment(selectedDateTo).format('YYYY-MM-DD'),
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: [],
    };

    let mergedRegions: any[] = [];
    const codeColumnIndex: any[] = [];
    const dataExcel = {
      formName: '【日別】本部確認',
      targetPeriod: '対象期間: '+moment(selectedDateFrom).format('YYYY-MM-DD')+ ' ~ ' +moment(selectedDateTo).format('YYYY-MM-DD'),
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex,
    };

    const handleExportCSV = () => downloadCsv(data);

    const handleExportExcel = () => {
      if (mergedRegions) {
        mergedRegions = [];
      }
      mergedRegions.push([3, 3, 9, 10]);
      mergedRegions.push([3, 3, 13, 18]);
      mergedRegions.push([3, 4, 0, 0]);
      mergedRegions.push([3, 4, 1, 1]);
      mergedRegions.push([3, 4, 2, 2]);
      mergedRegions.push([3, 4, 3, 3]);
      mergedRegions.push([3, 4, 4, 4]);
      mergedRegions.push([3, 4, 5, 5]);
      mergedRegions.push([3, 4, 6, 6]);
      mergedRegions.push([3, 4, 7, 7]);
      mergedRegions.push([3, 4, 8, 8]);
      mergedRegions.push([3, 4, 11, 11]);
      mergedRegions.push([3, 4, 12, 12]);
      mergedRegions.push([3, 4, 19, 19]);
      mergedRegions.push([3, 4, 20, 20]);
      mergedRegions.push([3, 4, 21, 21]);
      mergedRegions.push([3, 4, 22, 22]);
      mergedRegions.push([3, 4, 23, 23]);
      mergedRegions.push([3, 4, 24, 24]);
      mergedRegions.push([3, 4, 25, 25]);
      mergedRegions.push([3, 4, 26, 26]);
      mergedRegions.push([3, 4, 27, 27]);
      mergedRegions.push([3, 4, 28, 28]);

      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [0];
      dataExcel.headerInformation = [
        ['状態', '日/店舗','売上(税込)', '現金売上', 'CC売上', 'AirPAY', '商品券','商品券釣銭', '掛売', '現金過不足', '', '釣銭準備金', '銀行入金額', '小口', '', '', '', '', '', 'VOID件数', '強制取消金額', 'オーダー取消金額', '値引・割引金額', '精算日時', '日報申請日時', '日報申請者', '日報承認日時', '日報承認者'],
        ['', '', '', '', '', '', '', '', '', '実過不足', 'レジ過不足', '', '', '食材', '飲材', '雑費', '合計', 'レジ出金額', '出金差異', '', '', '', '', '', '', '', '', ''],
      ];
      return downloadExcel(dataExcel);
    };

    const renderHeaderColumn = useCallback((displayName: string, columnSortField: FieldSortState, rowSpan: number, className: string = "") => (
      <th rowSpan={rowSpan} className={className} onClick={e => sortByFieldPath(columnSortField.fieldPath, columnSortField.sortType)}>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <span style={{ margin: 'auto' }}>{displayName}</span>
          {sortField.fieldPath === columnSortField.fieldPath && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
          {sortField.fieldPath !== columnSortField.fieldPath && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
        </div>
      </th>
     ), [sortField]);

    return (
      <div>

        {
          roleScreen && roleScreen.downloadFlag === 1 && (
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportCSV}
                iconType="download"
                text=" CSV出力"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportExcel}
                iconType="download"
                text=" EXCEL出力"
              />
            </FlexBoxItem>
          </FlexBox>
        )}

        <div
          className="table-responsive"
          style={{
            display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', marginTop: 20, maxHeight: 600,
          }}
        >
          <table id="salesPageForm" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }}>
            <thead>
              <tr role="row">
                { renderHeaderColumn('状態', { fieldPath: 'status', sort: 'asc', sortType: 'number'}, 2) }
                { renderHeaderColumn('日/店舗', { fieldPath: 'targetDate', sort: 'asc', sortType: 'date'}, 2) }
                { renderHeaderColumn('売上(税込)', { fieldPath: 'totalSales', sort: 'asc', sortType: 'number'}, 2) }
                { renderHeaderColumn('現金売上', { fieldPath: 'cashAmount', sort: 'asc', sortType: 'number'}, 2) }
                { renderHeaderColumn('CC売上', { fieldPath: 'ccAmount', sort: 'asc', sortType: 'number'}, 2) }
                { renderHeaderColumn('AirPAY', { fieldPath: 'airpayAmount', sort: 'asc', sortType: 'number'}, 2) }
                { renderHeaderColumn('商品券', { fieldPath: 'cashVoucherPaymentTotalAmount', sort: 'asc', sortType: 'number'}, 2) }
                { renderHeaderColumn('商品券釣銭', { fieldPath: 'moneyTicketChange', sort: 'asc', sortType: 'number'}, 2) }
                { renderHeaderColumn('掛売', { fieldPath: 'kakeuriAmount', sort: 'asc', sortType: 'number'}, 2) }
                <th colSpan={2}>現金過不足</th>
                { renderHeaderColumn('釣銭準備金', { fieldPath: 'changeReserve', sort: 'asc', sortType: 'number'}, 2) }
                { renderHeaderColumn('銀行入金額', { fieldPath: 'bankDepositAmount', sort: 'asc', sortType: 'number'}, 2) }
                <th colSpan={6}>小口</th>
                { renderHeaderColumn('VOID件数', { fieldPath: 'voidCnt', sort: 'asc', sortType: 'number'}, 2) }
                { renderHeaderColumn('強制取消金額', { fieldPath: 'compulsoryCancelAmount', sort: 'asc', sortType: 'number'}, 2) }
                { renderHeaderColumn('オーダー取消金額', { fieldPath: 'orderMenuCancelAmount', sort: 'asc', sortType: 'number'}, 2) }
                { renderHeaderColumn('値引・割引金額', { fieldPath: 'totalDiscountAmount', sort: 'asc', sortType: 'number'}, 2) }
                { renderHeaderColumn('精算日時', { fieldPath: 'clearingDate', sort: 'asc', sortType: 'date'}, 2) }
                { renderHeaderColumn('日報申請日時', { fieldPath: 'applicationDate', sort: 'asc', sortType: 'date'}, 2) }
                { renderHeaderColumn('日報申請者', { fieldPath: 'applicationUser', sort: 'asc', sortType: 'string'}, 2) }
                { renderHeaderColumn('日報承認日時', { fieldPath: 'approvalDate', sort: 'asc', sortType: 'date'}, 2) }
                { renderHeaderColumn('日報承認者', { fieldPath: 'approvalUser', sort: 'asc', sortType: 'string'}, 2) }
              </tr>
              <tr className="subThead">
                { renderHeaderColumn('実過不足', { fieldPath: 'realExcessAndDeficiency', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('レジ過不足', { fieldPath: 'excessAndDeficiency', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('食材', { fieldPath: 'foodAmount', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('飲材', { fieldPath: 'drinkAmount', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('雑費', { fieldPath: 'otherAmount', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('合計', { fieldPath: 'totalPettyAmount', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('レジ出金額', { fieldPath: 'totalWithdrawal', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('出金差異', { fieldPath: 'pettyWithdrawalDiff', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              </tr>
            </thead>
            {
              dataSort && dataSort.length > 0 ? dataSort.map((salePageForm: any, index:number) => {

                searchResult.push([
                  (stateSymbol as any)[salePageForm.status],
                  salePageForm.targetDate ? moment(salePageForm.targetDate).format('YYYY-MM-DD') : '',
                  formatter.format(salePageForm.totalSales) || '',
                  formatter.format(salePageForm.cashAmount) || '',
                  formatter.format(salePageForm.ccAmount) || '',
                  formatter.format(salePageForm.airpayAmount) || '',
                  formatter.format(salePageForm.cashVoucherPaymentTotalAmount) || '',
                  formatter.format(salePageForm.moneyTicketChange) || '',
                  formatter.format(salePageForm.kakeuriAmount) || '',

                  formatter.format(salePageForm.realExcessAndDeficiency) || '',
                  formatter.format(salePageForm.excessAndDeficiency) || '',
                  formatter.format(salePageForm.changeReserve) || '',
                  formatter.format(salePageForm.bankDepositAmount) || '',

                  formatter.format(salePageForm.foodAmount) || '',
                  formatter.format(salePageForm.drinkAmount) || '',
                  formatter.format(salePageForm.otherAmount) || '',
                  formatter.format(salePageForm.totalPettyAmount) || '',
                  formatter.format(salePageForm.totalWithdrawal) || '',
                  formatter.format(salePageForm.pettyWithdrawalDiff) || '',

                  formatter.format(salePageForm.voidCnt) || '',
                  formatter.format(salePageForm.compulsoryCancelAmount) || '',
                  formatter.format(salePageForm.orderMenuCancelAmount) || '',
                  formatter.format(salePageForm.totalDiscountAmount) || '',
                  salePageForm.clearingDate ? moment(salePageForm.clearingDate).format('YYYY-MM-DD HH:mm') : '',
                  salePageForm.applicationDate ? moment(salePageForm.applicationDate).format('YYYY-MM-DD HH:mm') : '',
                  salePageForm.applicationUser || '',
                  salePageForm.approvalDate ? moment(salePageForm.approvalDate).format('YYYY-MM-DD HH:mm') : '',
                  salePageForm.approvalUser || '',
                ]);
                return (
                  <tbody key={index} css={styles}>
                    <tr>
                      <td className="textCenter">
                        {
                          salePageForm.status !== 99
                          ? <Link target={"_blank"} to={{ pathname: `/salesDailyReport/${orgCode}/${moment(salePageForm.targetDate).format('YYYY-MM-DD')}` }}>
                            {(stateSymbol as any)[salePageForm.status]}
                            </Link>
                          :  (stateSymbol as any)[salePageForm.status]
                        }
                      </td>
                      <td>
                        {
                          moment(salePageForm.targetDate).format('YYYY-MM-DD')
                        }
                      </td>
                      <td>{formatter.format(salePageForm.totalSales)}</td>
                      <td>{ formatter.format(salePageForm.cashAmount) }</td>
                      <td>{ formatter.format(salePageForm.ccAmount) }</td>
                      <td>{ formatter.format(salePageForm.airpayAmount)}</td>
                      <td>{ formatter.format(salePageForm.cashVoucherPaymentTotalAmount) }</td>
                      <td>{ formatter.format(salePageForm.moneyTicketChange) }</td>
                      <td>{ formatter.format(salePageForm.kakeuriAmount)}</td>

                      <td>{ formatter.format(salePageForm.realExcessAndDeficiency) }</td>
                      <td>{ formatter.format(salePageForm.excessAndDeficiency) }</td>

                      <td>{ formatter.format(salePageForm.changeReserve) }</td>
                      <td>{ formatter.format(salePageForm.bankDepositAmount)}</td>

                      <td>{ formatter.format(salePageForm.foodAmount) }</td>
                      <td>{ formatter.format(salePageForm.drinkAmount) }</td>
                      <td>{ formatter.format(salePageForm.otherAmount) }</td>
                      <td>{ formatter.format(salePageForm.totalPettyAmount) }</td>
                      <td>{ formatter.format(salePageForm.totalWithdrawal) }</td>
                      <td>{ formatter.format(salePageForm.pettyWithdrawalDiff) }</td>

                      <td>{ formatter.format(salePageForm.voidCnt) }</td>
                      <td>{ formatter.format(salePageForm.compulsoryCancelAmount) }</td>
                      <td>{ formatter.format(salePageForm.orderMenuCancelAmount) }</td>
                      <td>{ formatter.format(salePageForm.totalDiscountAmount) }</td>
                      <td>{ salePageForm.clearingDate ? moment(salePageForm.clearingDate).format('YYYY-MM-DD HH:mm') : ''}</td>
                      <td>{ salePageForm.applicationDate ? moment(salePageForm.applicationDate).format('YYYY-MM-DD HH:mm') : ''}</td>
                      <td>{ salePageForm.applicationUser }</td>
                      <td>{ salePageForm.approvalDate ? moment(salePageForm.approvalDate).format('YYYY-MM-DD HH:mm') : '' }</td>
                      <td>{ salePageForm.approvalUser }</td>
                    </tr>
                  </tbody>
                );
              }) : <tbody css={styles}></tbody>
            }
          </table>
        </div>
      </div>
    );
  };

export default SalesFormTable;
