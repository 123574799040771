/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import Panel from 'components/atoms/Panel';
import { layer } from 'components/styles';

const styles = {
  wrapper: css({
    position: 'absolute',
    top: '52px',
    right: '4px',
  }),
  body: css({
    boxShadow: layer.layer01,
  }),
};

/**
 * 画面右上のパネル
 */
const AppNotificationPanel: React.FC = ({ children }) => (
  <div css={styles.wrapper}>
    <Panel customStyle={styles.body}>
      {children}
    </Panel>
  </div>
);

export default AppNotificationPanel;
