import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import AdverstingMediaDomain from 'domain/master/sales/adverstingMedia';
import { AddOrUpdateAdvertising,getInfo } from 'api/advertiseMedia';
import useToastNotification from 'hooks/useToastNotification';

type AdverstingMediaDomainKey = keyof Pick<AdverstingMediaDomain, 'advertisingMediaMstCode' | 'advertisingMediaMstName' | 'dispOrder'>;

export const useAdvertisingMediaAddForm = (advertisingMediaMstCode?: string) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const history = useHistory();
  const { successNotification, errorNotification } = useToastNotification();

  const [confirmModalContent, setConfirmModalContent] = useState('');
  const [confirmModalResendOpen, setConfirmModalResendOpen] = useState(false);
  const [submitedBusinessG, setSubmitedBusinessG] = useState({});
  // Model add/edit
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);
  // Model error
  const closeToastModel = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  // Model trung ten khi edit
  const closeConfirmResendModal = useCallback(() => {
    setConfirmModalResendOpen(false);
  }, []);


  // omsubmit
  const onSubmit = async (val: any) => {
    if (val.advertisingMediaMstCode === null) {
      errorNotification('コードを入力してください。');
      return;
    }
    if (val.advertisingMediaMstName === null) {
      errorNotification('名称を入力してください。');
      return;
    }
    if (val.dispOrder === null) {
      errorNotification('並び順を入力してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    let isCreate = 0;
    let submitObj = val.getRawDataForCreate();
    if (advertisingMediaMstCode !== undefined) {
      isCreate = 1;
      submitObj = val.getRawDataForUpdate();
    }

    // isConfirmed
    AddOrUpdateAdvertising(isCreate, 0, submitObj)
      .then(() => {
        setConfirmModalOpen(false);
        successNotification(`${isCreate ? '更新' : '登録'}しました。`);
        history.push('/salesAdvertisingMedia/advertisingMaster');
      })
      .catch((error: any) => {
        if (error.response.status === 406) {
          setConfirmModalOpen(false);
          setConfirmModalResendOpen(true);
          setSubmitedBusinessG(submitObj);

          let msgError = '既に同じ広告媒体名が登録されているますが、よろしいですか？（広告媒体コード：';
          error.response.data.map((item: { id:{advertisingMediaMstCode: string} }) => {
            msgError += `${item.id.advertisingMediaMstCode}, `;
            return msgError;
          });
          msgError = `${msgError.slice(0, -2)})`;
          setConfirmModalContent(msgError);
        } else {
          setConfirmModalOpen(false);
          if (error.response.data != null && error.response.data.errors.count !== 0) {
            const listErr = error.response.data.errors;
            let stringErr = '';
            listErr.map((element: any, index: any) => {
              stringErr += `${element.defaultMessage}<br />`;
              return stringErr;
            });
            errorNotification(stringErr);
          } else {
            errorNotification('サーバー側でエラーが発生しました。');
          }
        }
      });
  };
    // end onsubmit

  // formilk
  const formik = useFormik({
    initialValues: AdverstingMediaDomain.generateInitial(),
    onSubmit,
  });
  // end formilk

  //   useEffect
  useEffect(() => {
    if (advertisingMediaMstCode !== undefined) {
      getInfo(advertisingMediaMstCode).then((item: any) => {
          if (item.id.advertisingMediaMstCode === advertisingMediaMstCode) {
            formik.setFieldValue('advertisingMediaMstCode', item.id.advertisingMediaMstCode);
            formik.setFieldValue('advertisingMediaMstName', item.advertisingMediaMstName);
            formik.setFieldValue('dispOrder', item.dispOrder);
            return true;
          }
          return false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertisingMediaMstCode]);
  // end useEffect

  // ham submitConfirmResendModal
  const submitConfirmResendModal = useCallback(() => {
    let isCreate = 0;
    if (advertisingMediaMstCode !== undefined) {
      isCreate = 1;
    }
    AddOrUpdateAdvertising(isCreate, 1, submitedBusinessG)
      .then(() => {
        successNotification('更新しました。');
        history.goBack();
        setConfirmModalResendOpen(false);
      })
      .catch((error: any) => {
        setConfirmModalResendOpen(false);
        setToastModalOpen(true);
        if (error.response.status === 400) {
          let msgErrorName = '';
          error.response.data.errors.map((item: { defaultMessage: string; }) => {
            msgErrorName += `${item.defaultMessage}<br />`;
            return msgErrorName;
          });
          errorNotification(msgErrorName);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  }, [advertisingMediaMstCode, errorNotification, history, submitedBusinessG, successNotification]);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    history,
    toastModalOpen,
    toastMessage,
    closeToastModel,
    confirmModalContent,
    confirmModalResendOpen,
    closeConfirmResendModal,
    submitConfirmResendModal,
  };
};
export default useAdvertisingMediaAddForm;
