import ApiClient from './ApiClient';

export const downloadCsv = async (queryString: string, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadFile(`/v1/sales/list/standard/advertising/plan/exportFile/csv/${companyCode}?${queryString}`, {}, fileName);
};

export const downloadExcel = async (queryString: string, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadFile(`/v1/sales/list/standard/advertising/plan/exportFile/excel/${companyCode}?${queryString}`, {}, fileName);
};
