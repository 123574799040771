/** @jsx jsx */
import React, { useMemo, useState, useEffect } from 'react';
import DataTable from 'components/organismos/DataTable/DataTable';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import DatePicker from 'components/molecules/DatePicker';
import { css, jsx } from '@emotion/core';
import Checkbox from 'components/molecules/Checkbox';
import Radio from 'components/molecules/Radio';
import Remove from 'components/atoms/Icon/Remove';
import Warning from 'components/atoms/Icon/Warning';
import WarningFill from 'components/atoms/Icon/WarningFill';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import BlockUI from 'components/molecules/BlockUi';
import FormLabel from 'components/atoms/Form/FormLabel';
import DropdownContextMenu from 'components/molecules/Dropdown/DropdownContextMenu';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import AttendErrorDomain from 'domain/master/attend/attendError';
import { useHistory } from 'react-router-dom';
import useAchievementErrorState from '../hook';
import { utilityColor } from 'components/styles';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const styles = {
  table: css`
    table {
      margin-top: 20px;
      // td{
      //   text-align: center;

      //   &:nth-child(2) {
      //     overflow: unset;
      //   }
      // }
    }

    table th:nth-child(1) {
      width: 60px;
    }
    table th:nth-child(2) {
      width: 100px;
    }
    table th:nth-child(3) {
      width: 100px;
    }
    table th:nth-child(4) {
      width: 100px;
    }
    table th:nth-child(5) {
      width: 100px;
    }
    table th:nth-child(6) {
      width: 140px;
    }
    table th:nth-child(7) {
      width: 100px;
    }
    table th:nth-child(8) {
      width: 80px;
    }
    table th:nth-child(9) {
      width: 80px;
    }
    table th:nth-child(10) {
      width: 140px;
    }
    table th:nth-child(11) {
      width: 80px;
    }
    table th:nth-child(12) {
      width: 60px;
    }
    table th:nth-child(13) {
      width: 500px;
    }

    .svg-yellow path:last-child {
      fill: #f2c94c;
    }
  `,
  flexBox: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  textMessage: css({
    color: 'black',
  }),
};

interface AttendErrors {
  orgCodeNoti?: any;
  targetDateFromNoti?: any;
  targetDateToNoti?: any;
  clearToggle?: any;
}

const AttendanceError: React.FC<AttendErrors> = ({
  orgCodeNoti, targetDateFromNoti, targetDateToNoti, clearToggle,
}) => {
  const {
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    orgCode,
    setOrgCode,
    isFilter,
    setIsFilter,
    errorList,
    isLoading,
    setCategoryAr,
    toSearchContinuousWorkAlert,
    setSearchContinuousWorkAlert,
    toSearchOvertimeAlert,
    setSearchOvertimeAlert,
    toSearchHolidayworkAlert,
    setSearchHolidayworkAlert,
    toSearchRecessAlert,
    setSearchRecessAlert,
    toSearchUnder18MidnightWorkAlert,
    setSearchUnder18MidnightWorkAlert,
    toSearchForeignerAlert,
    setSearchForeignerAlert,
    handleExportCSV,
  } = useAchievementErrorState(orgCodeNoti, targetDateFromNoti, targetDateToNoti);

  const functionType = 2;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const history = useHistory();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  const hidden = css({
    display: 'none',
  });

  const display = css({
    display: 'inline-block',
    color: utilityColor.error
  });

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: '種別',
        accessor: 'hasError',
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendErrorDomain };
          data: Array<AttendErrorDomain>;

        }) => (
          <div
            style={{ textAlign: 'center' }}
          >
            <div
              css={cell.row.original.hasError === '1' ? display : hidden}
            >
              <Remove />
            </div>
            <div
              css={cell.row.original.hasError === '0' && cell.row.original.alertFlag ? display : hidden}
              className={`svg-yellow`}
            >
              <WarningFill />
            </div>
            <div
              css={cell.row.original.hasError === '0' && cell.row.original.infoFlag ? display : hidden}
              className={`svg-yellow`}
            >
              <Warning />
            </div>
          </div>
          
        ),
      },
      {
        Header: '修正',
        accessor: '',
        disableSortBy: true,
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendErrorDomain };
          data: Array<AttendErrorDomain>;
        }) => checkUserRole && ((checkUserRole.editable === 1 && cell.row.original.staffCode === staffCode) || (checkUserRole.usabilityGeneralItem1 === 1 && cell.row.original.staffCode !== staffCode)) && (
          <DropdownContextMenu
            text="修正申請 ›"
            modifierApplicationId={cell.row.original.modifierApplicationId}
            orgCode={cell.row.original.orgCode}
            staffCode={cell.row.original.staffCode}
            staffName={cell.row.original.staffName}
            modifierApplicationStatus={null}
            holidayApplicationStatus={cell.row.original.holidayApplicationStatus}
            overworkApplicationStatus={cell.row.original.overtimeApplicationStatus}
            holidayworkApplicationStatus={cell.row.original.holidayworkApplicationStatus}
            transferApplicationStatus={null}
            stampApplicationStatus={cell.row.original.stampApplicationStatus}
            attendanceOrgFix=""
            useOverTimeApplication={cell.row.original.useOverTimeApplication}
            necessaryHolidayWorkApplication={cell.row.original.necessaryHolidayWorkApplication}
            useTransferApplication={cell.row.original.useTransferApplication}
            achievementId={cell.row.original.achievementId}
            targetDate={cell.row.original.targetDate}
            stampVersion={cell.row.original.stampVersion}
            checkStampAddButton={true}
          />
        ),
      },
      {
        Header: '日付',
        accessor: 'targetDate',
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendErrorDomain };
          data: Array<AttendErrorDomain>;
        }) => (
          <div>
            {moment(cell.row.original.targetDate).format('MM/DD(ddd)')}
          </div>
        ),
      },
      {
        Header: '出勤店舗',
        accessor: 'orgName',
      },
      {
        Header: 'スタッフ名',
        accessor: 'staffName',
      },
      {
        Header: '勤務予定',
        accessor: 'shiftTime',
      },
      {
        Header: '休暇',
        accessor: 'holidayWorkFlg',
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendErrorDomain };
          data: Array<AttendErrorDomain>;
        }) => (
          <div>
            {!cell.row.original.holidayWorkFlg ? cell.row.original.holiday || '' : '休日出勤'}
          </div>
        ),
      },
      {
        Header: '出社',
        accessor: 'startTime',
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendErrorDomain };
          data: Array<AttendErrorDomain>;
        }) => (
          <div>
            {cell.row.original.startTime && moment(cell.row.original.startTime).format('HH:mm')}
            {cell.row.original.startTime && moment(cell.row.original.startTime).format('YYYY-MM-DD') > moment(cell.row.original.targetDate).format('YYYY-MM-DD') && ' (翌)' }
          </div>
        ),
      },
      {
        Header: '退社',
        accessor: 'endTime',
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendErrorDomain };
          data: Array<AttendErrorDomain>;
        }) => (
          <div>
            {cell.row.original.endTime && moment(cell.row.original.endTime).format('HH:mm')}
            {cell.row.original.endTime && moment(cell.row.original.endTime).format('YYYY-MM-DD') > moment(cell.row.original.targetDate).format('YYYY-MM-DD') && ' (翌)' }
          </div>
        ),
      },
      {
        Header: '休憩',
        accessor: 'recess',
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendErrorDomain };
          data: Array<AttendErrorDomain>;
        }) => (
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {cell.row.original.recess?.map((recess) => (
              `${recess.startTime} - ${recess.endTime || ''}\n`
            ))}
          </div>
        ),
      },
      {
        Header: '労働時間',
        accessor: 'allWorkTime',
      },
      {
        Header: '残業',
        accessor: 'unapprovedOverTime',
      },
      {
        Header: '内容',
        accessor: 'errorMessage',
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendErrorDomain };
          data: Array<AttendErrorDomain>;
        }) => (
          <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
            {cell.row.original.errorMessage.replace(/<\/br>/gi, '\n')}
          </div>
        ),
      },
    ],
    [checkUserRole, staffCode],
  );

  const [checkClearToggle, setCheckClearToggle] = useState(clearToggle);

  useEffect(() => {
    setCheckClearToggle(clearToggle);
  }, [clearToggle]);

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox customStyle={css({ flexWrap: 'wrap', marginBottom: '12px' })}>
          <FlexBoxItem marginRight="15px" minWidth="60px">
            <FormLabel
              label="対象期間"
            />
          </FlexBoxItem>

          <FlexBoxItem>
            <DatePicker
              dateFormat="yyyy/MM/dd"
              date={targetDateFrom}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDateFrom(date);
                  sessionStorage.setItem('attendError.targetDateFrom', moment(date).format('YYYY-MM-DD'));
                }
              }}
            // isFullWidth={true}
            />
          </FlexBoxItem>

          <FlexBoxItem>
            <span style={{ margin: '0 20px' }}>～</span>
          </FlexBoxItem>

          <FlexBoxItem>
            <DatePicker
              dateFormat="yyyy/MM/dd"
              date={targetDateTo}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDateTo(date);
                  sessionStorage.setItem('attendError.targetDateTo', moment(date).format('YYYY-MM-DD'));
                }
              }}
            // isFullWidth={true}
            />
          </FlexBoxItem>
        </FlexBox>

        <FlexBox customStyle={css({ flexWrap: 'wrap' })}>
          <FlexBoxItem marginRight="15px" minWidth="60px">
            <FormLabel
              label="対象実績"
            />
          </FlexBoxItem>

          {[{ display: 'エラー表示', index: 1 }, { display: 'アラート表示', index: 0 }].map((item) => (
            <FlexBoxItem marginLeft="10px" key={item.index}>
              <Radio
                id={`${item}_${String(item.index)}`}
                name={String(item.index)}
                label={item.display}
                value={String(item.index)}
                checked={isFilter === String(item.index)}
                onChange={() => {
                  sessionStorage.setItem('attendError.isFilter', String(item.index));
                  setIsFilter(String(item.index));
                }}
              />
            </FlexBoxItem>
          ))}
          <span style={{ margin: '0px 0px 0px 5px' }}>(</span>
          <FlexBoxItem customStyle={css({ marginTop: '15px' })}>
            <Checkbox
              id="toSearchOvertimeAlert"
              name="toSearchOvertimeAlert"
              label="残業"
              value={toSearchOvertimeAlert === '1' ? '0' : '1'}
              checked={toSearchOvertimeAlert === '1'}
              readOnly={isFilter === '1'}
              disabled={isFilter === '1'}
              onChange={(e) => {
                setSearchOvertimeAlert(e.target.value);
              }}
            />
          </FlexBoxItem>
          <FlexBoxItem customStyle={css({ marginTop: '15px' })} marginLeft="10px">
            <Checkbox
              id="toSearchHolidayworkAlert"
              name="toSearchHolidayworkAlert"
              label="休日出勤"
              value={toSearchHolidayworkAlert === '1' ? '0' : '1'}
              checked={toSearchHolidayworkAlert === '1'}
              readOnly={isFilter === '1'}
              disabled={isFilter === '1'}
              onChange={(e) => {
                setSearchHolidayworkAlert(e.target.value);
              }}
            />
          </FlexBoxItem>
          <FlexBoxItem customStyle={css({ marginTop: '15px' })} marginLeft="10px">
            <Checkbox
              id="toSearchRecessAlert"
              name="toSearchRecessAlert"
              label="休憩"
              value={toSearchRecessAlert === '1' ? '0' : '1'}
              checked={toSearchRecessAlert === '1'}
              readOnly={isFilter === '1'}
              disabled={isFilter === '1'}
              onChange={(e) => {
                setSearchRecessAlert(e.target.value);
              }}
            />
          </FlexBoxItem>
          <FlexBoxItem customStyle={css({ marginTop: '15px' })} marginLeft="10px">
            <Checkbox
              id="toSearchContinuousWorkAlert"
              name="toSearchContinuousWorkAlert"
              label="連勤"
              value={toSearchContinuousWorkAlert === '1' ? '0' : '1'}
              checked={toSearchContinuousWorkAlert === '1'}
              readOnly={isFilter === '1'}
              disabled={isFilter === '1'}
              onChange={(e) => {
                setSearchContinuousWorkAlert(e.target.value);
              }}
            />
          </FlexBoxItem>
          <FlexBoxItem customStyle={css({ marginTop: '15px' })} marginLeft="10px">
            <Checkbox
              id="toSearchUnder18MidnightWorkAlert"
              name="toSearchUnder18MidnightWorkAlert"
              label="18歳未満"
              value={toSearchUnder18MidnightWorkAlert === '1' ? '0' : '1'}
              checked={toSearchUnder18MidnightWorkAlert === '1'}
              readOnly={isFilter === '1'}
              disabled={isFilter === '1'}
              onChange={(e) => {
                setSearchUnder18MidnightWorkAlert(e.target.value);
              }}
            />
          </FlexBoxItem>
          <FlexBoxItem customStyle={css({ marginTop: '15px' })} marginLeft="10px">
            <Checkbox
              id="toSearchForeignerAlert"
              name="toSearchForeignerAlert"
              label="外国人"
              value={toSearchForeignerAlert === '1' ? '0' : '1'}
              checked={toSearchForeignerAlert === '1'}
              readOnly={isFilter === '1'}
              disabled={isFilter === '1'}
              onChange={(e) => {
                setSearchForeignerAlert(e.target.value);
              }}
            />
          </FlexBoxItem>
          <span style={{ margin: '0px 0px 0px 5px' }}>)</span>

        </FlexBox>
        <FlexBox customStyle={css({ flexWrap: 'wrap' })}>
          <FlexBoxItem grow={5}>
            <OrganizationFilteredTree
              functionType={functionType}
              staffCode={staffCode}
              targetdayForm={targetDateFrom}
              targetdayTo={targetDateTo}
              orgLabel="店舗名"
              initOrgValue={String(orgCode)}
              orgCallback={(args: string | Array<string>) => {
                if (!sessionStorage.getItem('flgHanldeScreenAttendError')) {
                  setOrgCode(String(args));
                  sessionStorage.setItem('attendError.orgCode', String(args));
                }
              }}
              orgCategoryCallback={
                (args: Array<string>) => {
                  setCategoryAr(args);
                  sessionStorage.setItem('attendError.selectCategoryOption', JSON.stringify(args));
                }
              }
              addAllItem={true}
              initCatagory={(args: string) => {
                sessionStorage.setItem('attendError.selectCategory', JSON.stringify(args));
              }}
              styles={{
                label: {
                  toggleSwitch: css({ marginLeft: '0px' }),
                },
                toggle: {
                  width: '13rem',
                },
              }}
              clearToggle={checkClearToggle}
              setClearToggle={setCheckClearToggle}
            />
          </FlexBoxItem>
        </FlexBox>

        {
            roleScreen && roleScreen.downloadFlag === 1 && (
              <FlexBox>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportCSV}
                    iconType="download"
                    text=" CSV出力"
                  />
                </FlexBoxItem>
              </FlexBox>
            )
          }

        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <div css={styles.table}>
            <DataTable
              columns={columns}
              data={errorList.filter((i) => i.hasError === isFilter)}
              minWidth="1686px"
              sortBy={[
                { id: 'startTime', desc: false },
              ]}
            />
          </div>
        </div>
      </FormContents>
    </BlockUI>
  );
};
export default AttendanceError;
