/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';

import { isMacOs } from 'react-device-detect';
import { useDownload } from './hooks';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';
import { grayScale } from 'components/styles';
import useSortTable, { FieldSortState, SortColumn } from 'hooks/useSortTable';
import TimeDaySeparateSalesReportItemDomain, { TimeDaySeparateSalesReport } from 'domain/master/storeManagement/TimeDaySeparateSalesReportItem';

const DEFAULT_SORT_FIELD = { fieldPath: 'targetDay', sort: 'asc' } as FieldSortState

const TimeDaySeparateSales: React.FC<{
  setIsLoading: any,
  salesTimeList: Array<TimeDaySeparateSalesReport>,
  targetTimeList: Array<string>,
  targetPeriodText: string,
  targetStoreText: string,
  targetAggregateText: string,
  targetWeekText: string,
  targetTimeText: string,
  roleScreen: any
}> = ({
  setIsLoading,
  salesTimeList,
  targetTimeList,
  targetPeriodText,
  targetStoreText,
  targetAggregateText,
  targetWeekText,
  targetTimeText,
  roleScreen
}) => {

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート項目 */
    const sortColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '日付', sortField: 'targetDay', sortType: 'string', style: { top: '0', left: '0', zIndex: 99, maxWidth: ' 300px', width: '300px', minWidth: '300px' }, className: "text-center stickyStyle fristColSticky" }
    ], []);

    const sortSubColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '売上', sortField: 'amount',    sortType: 'number', style: { position: 'sticky', top: '34px' }, className: "text-center stickyStyle" },
      { displayName: '数量', sortField: 'item',      sortType: 'number', style: { position: 'sticky', top: '34px' }, className: "text-center stickyStyle" },
      { displayName: '客数', sortField: 'guestCnt',  sortType: 'number', style: { position: 'sticky', top: '34px' }, className: "text-center stickyStyle" },
      { displayName: '組数', sortField: 'customers', sortType: 'number', style: { position: 'sticky', top: '34px' }, className: "text-center stickyStyle" },
    ], []);

    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(salesTimeList, DEFAULT_SORT_FIELD);

    // ------------------------------------------------------------------
    // 出力
    // ------------------------------------------------------------------
    /** CSV・Excel出力実装 */
    const { downloadMonthlyReportCsv, downloadMonthlyReportExcel } = useDownload();
    /** 出力用データ */
    const outputDataList = useMemo(() => (
      dataSort.map((data: TimeDaySeparateSalesReport) => [
        data.dispTargetDay,
        ...data.items.flatMap((item: TimeDaySeparateSalesReportItemDomain) => item.getOutputData())
      ])
    ), [dataSort]);
    /** CSV出力用ヘッダ情報 */
    const headerInformation = useMemo(() => [
      [
        ...sortColumns.map(s => ''),
        ...targetTimeList.flatMap(targetTime => [targetTime, ...Array((sortSubColumns.length - 1)).fill('')]),
      ],
      [
        ...sortColumns.map(s => s.displayName),
        ...targetTimeList.flatMap(h => sortSubColumns.map(s => s.displayName)),
      ],
    ], [targetTimeList]);

    /** Excel出力用ヘッダ情報 */
    const headerInformationExcel = useMemo(() => [
      [
        ...sortColumns.map(s => s.displayName),
        ...targetTimeList.flatMap(targetTime => [targetTime, ...Array((sortSubColumns.length - 1)).fill('')]),
      ],
      [
        ...sortColumns.map(s => ''),
        ...targetTimeList.flatMap(h => sortSubColumns.map(s => s.displayName)),
      ],
    ], [targetTimeList]);

    /**
     * Excel出力用セル結合情報
     * NOTE: [firstRow, lastRow, firstCol, lastCol] の配列で結合するセル範囲を指定する
    */
    const mergedRegions = useMemo(() => {
      const tmpMergedRegions: any[] = [];
      // ヘッダの日付セルを縦方向に結合
      tmpMergedRegions.push([6, 7, 0, 0]);
      // ヘッダの時間を時間毎に横方向に結合
      let firstColIndex = sortColumns.length;
      let lastColIndex = firstColIndex + sortSubColumns.length - 1;
      targetTimeList.map((targetTime: string) => {
        tmpMergedRegions.push([6, 6, firstColIndex, lastColIndex]);
        firstColIndex += sortSubColumns.length;
        lastColIndex += sortSubColumns.length;
      })
      return tmpMergedRegions;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetTimeList]);

    const data = {
      footer: null,
      formName: '時間別日別売上',
      targetPeriod: targetPeriodText,
      targetStores: targetStoreText,
      targetAggregate: targetAggregateText,
      targetDay: targetWeekText,
      targetTime: targetTimeText,
      headerInformation,
      searchResult: outputDataList,
      totalInformation: [],
    };

    const dataExcel = {
      formName: '時間別日別売上',
      targetPeriod: targetPeriodText,
      targetStores: targetStoreText,
      targetAggregate: targetAggregateText,
      targetDay: targetWeekText,
      targetTime: targetTimeText,
      headerInformation: headerInformationExcel,
      searchResult: outputDataList,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex: [],
    };

    const handleExportCSV = () => downloadMonthlyReportCsv(data, setIsLoading);
    const handleExportExcel = () => downloadMonthlyReportExcel(dataExcel, setIsLoading);

    return (
      <div>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportCSV}
                iconType="download"
                text=" CSV出力"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportExcel}
                iconType="download"
                text=" EXCEL出力"
              />
            </FlexBoxItem>
          </FlexBox>
        )}

        <div style={{ margin: '15px' }}>
          <div className="templateTable newDesignTable" style={{ height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: isMacOs ? '35px' : '1px' }}>
            <table className="table table-bordered text-nowrap">
              <thead>
                <tr>
                  { sortColumns.map((col: SortColumn, index: number) => (
                    <th key={index} style={col.style} rowSpan={2} className={col.className} onClick={e => sortByFieldPath(col.sortField, col.sortType)}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ margin: 'auto' }}>{col.displayName}</span>
                        {sortField.fieldPath === col.sortField && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                        {sortField.fieldPath !== col.sortField && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                      </div>
                    </th>
                  ))}
                  {
                    targetTimeList.map((name: any, index: any) => (
                      <th key={index} colSpan={sortSubColumns.length} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>{name}</span></th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    targetTimeList.map((name: any, index: number) => (
                      sortSubColumns.map((col: SortColumn, idx: number) => (
                        <th key={idx} style={col.style} className={col.className}  onClick={e => sortByFieldPath(`items[${index}].${col.sortField}`, col.sortType)}>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ margin: 'auto' }}>{col.displayName}</span>
                            {sortField.fieldPath === `items[${index}].${col.sortField}` && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                            {sortField.fieldPath !== `items[${index}].${col.sortField}` && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                          </div>
                        </th>
                      ))
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  dataSort.length > 0 ? dataSort.map((dispSlitData: TimeDaySeparateSalesReport, index: number) => (
                    <tr key={index}>
                      <td className="text-center stickyStyle fristColSticky" style={{ left: '0', zIndex: 10 }}> <span>{dispSlitData.dispTargetDay}</span> </td>
                      {
                        dispSlitData.items.map((dataItem: TimeDaySeparateSalesReportItemDomain, idx: number) => (
                          <React.Fragment key={idx}>
                            <td className="text-right"><span>{dataItem.amount}</span></td>
                            <td className="text-right"><span>{dataItem.item}</span></td>
                            <td className="text-right"><span>{dataItem.guestCnt}</span></td>
                            <td className="text-right"><span>{dataItem.customers}</span></td>
                          </React.Fragment>
                        ))
                      }
                    </tr>
                  )) : null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

export default TimeDaySeparateSales;
