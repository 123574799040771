/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */
import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import ShiftPatternLayoutList from 'components/organismos/master/attend/ShiftPattern/ShiftPatternLayoutList';

const ShiftPatternPage: React.FC = () => (
  <SidebarTemplate pageTitle="シフトパターンマスタ">
    <ShiftPatternLayoutList />
  </SidebarTemplate>
);

export default ShiftPatternPage;
