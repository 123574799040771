/** @jsx jsx */
import React from 'react';

import { css, jsx } from '@emotion/core';
import Table from 'components/molecules/Table';
import moment from 'moment';
import { ILeaveStatus } from '../../interface';

const header1 = [
  '前年繰越数',
  '当年付与数',
  '消化数',
  '残有給数',
  '次回付与日',
  '次回付与予定日数',
  '次回繰越日数',
  '次回消滅日数',
];
const header2 = [
  '休暇名',
  '付与数',
  '消化数',
  '残日数',
  '有効期間(開始)',
  '有効期間(終了)',
  '次回付与日',
  '次回付与予定日数',
];
const header3 = [
  '休暇名',
  '状態',
  '休日出勤日',
  '代休取得日',
  '消滅日(消滅予定日)',
];
const header4 = ['振替出勤日', '振替休日'];

const styles = {
  table: css`
    table {
      margin-top: 15px;
      th {
        white-space: nowrap;
      }
    }

    .title {
      margin-top: 15px;
      &:not(:first-of-type) {
        margin-top: 45px;
      }
    }

    .vaction-table {
      min-width: 836px;
    }
    .vaction-table th:nth-child(1) {
      width: 100px;
    }
    .vaction-table th:nth-child(2) {
      width: 100px;
    }
    .vaction-table th:nth-child(3) {
      width: 100px;
    }
    .vaction-table th:nth-child(4) {
      width: 100px;
    }
    .vaction-table th:nth-child(5) {
      width: 80px;
    }
    .vaction-table th:nth-child(6) {
      width: 100px;
    }
    .vaction-table th:nth-child(7) {
      width: 100px;
    }
    .vaction-table th:nth-child(8) {
      width: 100px;
    }

    .special-vaction-table {
      min-width: 884px;
    }
    .special-vaction-table th:nth-child(1) {
      width: 180px;
    }
    .special-vaction-table th:nth-child(2) {
      width: 80px;
    }
    .special-vaction-table th:nth-child(3) {
      width: 80px;
    }
    .special-vaction-table th:nth-child(4) {
      width: 80px;
    }
    .special-vaction-table th:nth-child(5) {
      width: 100px;
    }
    .special-vaction-table th:nth-child(6) {
      width: 100px;
    }
    .special-vaction-table th:nth-child(7) {
      width: 80px;
    }
    .special-vaction-table th:nth-child(8) {
      width: 120px;
    }

    .substitute-holiday-table {
      min-width: 580px;
    }
    .substitute-holiday-table th:nth-child(1) {
      width: 100px;
    }
    .substitute-holiday-table th:nth-child(2) {
      width: 100px;
    }
    .substitute-holiday-table th:nth-child(3) {
      width: 100px;
    }
    .substitute-holiday-table th:nth-child(4) {
      width: 100px;
    }
    .substitute-holiday-table th:nth-child(5) {
      width: 140px;
    }

    .transfer-holiday-table {
      min-width: 216px;
    }
    .transfer-holiday-table th:nth-child(1) {
      width: 100px;
    }
    .transfer-holiday-table th:nth-child(2) {
      width: 100px;
    }
  `,
};

const Vacation: React.FC<{ list: ILeaveStatus }> = ({ list }) => {
  const {
    paidHolidayGrantData,
    specialHolidayGrantList,
    substituteHolidayUsingStatusList,
    transferHolidayUsingStatusList,
  } = list;
  return (
    <div css={styles.table}>
      {/* 有給使用状況 */}
      <div className="title">有給使用状況</div>
      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
        <div className="vaction-table">
          <Table>
            <thead>
              <tr>
                {header1.map((header, index) => (
                  <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
                ))}
              </tr>
            </thead>
            <tbody>
              {paidHolidayGrantData && (
              <tr>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {`${paidHolidayGrantData.lastGrantDaysNumSum}日${paidHolidayGrantData.lastGrantTimesNumSum}時間`}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {`${paidHolidayGrantData.grantDaysNumSum}日${paidHolidayGrantData.grantTimesNumSum}時間`}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {`${paidHolidayGrantData.digestedDaysNum}日${paidHolidayGrantData.digestedTimesNum}時間`}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {`${paidHolidayGrantData.remainDaysNum}日${paidHolidayGrantData.remainTimesNum}時間`}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {moment(paidHolidayGrantData.nextGrantDate).format('YYYY/MM/DD')}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {paidHolidayGrantData.nextGrantDays}
                  日
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {`${paidHolidayGrantData.nextCarryOverDaysNum}日${paidHolidayGrantData.nextCarryOverTimesNum}時間`}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {`${paidHolidayGrantData.nextExtinctionDaysNum}日${paidHolidayGrantData.nextExtinctionTimesNum}時間`}
                </Table.Cell>
              </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      {/* 特休使用状況 */}
      <div className="title">特休使用状況</div>
      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
        <div className="special-vaction-table">
          <Table>
            <thead>
              <tr>
                {header2.map((header, index) => (
                  <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
                ))}
              </tr>
            </thead>
            <tbody>
              {specialHolidayGrantList
            && specialHolidayGrantList.map((cell, index) => (
              <tr key={index}>
                <Table.Cell>{cell.holidayName}</Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {cell.grantDaysNumSum}
                  日
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {cell.digestedDaysNum}
                  日
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {cell.remainDaysNum}
                  日
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {moment(cell.effectiveStartDate).format('YYYY/MM/DD')}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {moment(cell.effectiveEndDate).format('YYYY/MM/DD')}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {moment(cell.nextGrantDate).format('YYYY/MM/DD')}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {cell.nextGrantDays}
                  日
                </Table.Cell>
              </tr>
            ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* 代休使用状況 */}
      <div className="title">代休使用状況</div>
      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
        <div className="substitute-holiday-table">
          <Table>
            <thead>
              <tr>
                {header3.map((header, index) => (
                  <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
                ))}
              </tr>
            </thead>
            <tbody>
              {substituteHolidayUsingStatusList
            && substituteHolidayUsingStatusList.map((cell, index) => (
              <tr key={index}>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {cell.holidayName}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {cell.status}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {moment(cell.holidayWorkDate).format('YYYY/MM/DD')}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {
                    cell.substituteHolidayDate
                      ? moment(cell.substituteHolidayDate).format('YYYY/MM/DD')
                      : null
                  }
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {moment(cell.disappearanceDate).format('YYYY/MM/DD')}
                </Table.Cell>
                {/* <Table.Cell customStyle={css({ textAlign: 'center' })}>
                  {cell.grantDaysNum}
                  日
                </Table.Cell> */}
              </tr>
            ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* 振替状況 */}
      <div className="title">振替状況</div>
      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
        <div className="transfer-holiday-table">
          <Table>
            <thead>
              <tr>
                {header4.map((header, index) => (
                  <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
                ))}
              </tr>
            </thead>
            <tbody>
              {transferHolidayUsingStatusList
            && transferHolidayUsingStatusList.map((cell, index) => (
              <tr key={index}>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {moment(cell.transferWorkDate).format('YYYY/MM/DD')}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {moment(cell.transferHolidayDate).format('YYYY/MM/DD')}
                </Table.Cell>
              </tr>
            ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
export default Vacation;
