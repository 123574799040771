import { useState, useEffect } from 'react';
import {
  getSearchYearMonth, getWorkRecords, getRequestResult, getLeaveStatus,
} from 'api/workingStatus';
import moment from 'moment';
import {
  getOperationInfo,
} from 'api/schedule';
import {
  StaffStampList, IApplyResult, Employment,
} from '../../workingStatus/interface';

export type SelectType = 'workRecord' | 'result' | 'vacation';

const useWorkingStatusMobileData = () => {
  const tabTypes: { [key in SelectType]: SelectType } = {
    workRecord: 'workRecord',
    result: 'result',
    vacation: 'vacation',
  };

  const loginStaffCcde = sessionStorage.getItem('loginUser.staffCode') || '';
  const loginStaffName = sessionStorage.getItem('loginUser.staffName') || '';

  const convertedYearMonthStr = sessionStorage.getItem('workingStatus.yearMonth') || new Date();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectType, setSelectType] = useState<SelectType>(tabTypes.workRecord);
  const [getTargetDate, setTargetDate] = useState<Date>(new Date(convertedYearMonthStr));
  const [yearMonth, setYearMonth] = useState<Date>(new Date());
  const [getLoginStaffCcde, setLoginStaffCcde] = useState(loginStaffCcde);
  const [getLoginStaffName, setLoginStaffName] = useState(loginStaffName);
  const [staffStampList, setStaffStampList] = useState<StaffStampList[]>([]);
  const [employment, setEmployment] = useState<Employment>();
  const [applyResult, setApplyResult] = useState<IApplyResult[]>([]);
  const [leaveStatus, setLeaveStatus] = useState<any>();

  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  sessionStorage.setItem('application.dispStaffName', staffName);// used in other pages
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  sessionStorage.setItem('application.staffCode', staffCode);// used in other pages
  const orgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  sessionStorage.setItem('application.orgCode', orgCode);// used in other pages
  const { operationInfo } = useOperationInfo(yearMonth);

  const fetchList = async () => {
    setIsLoading(true);
    try {
      const { yearMonth } = await getSearchYearMonth(staffCode, moment(getTargetDate).format('YYYY/MM/DD'));
      sessionStorage.setItem('workingStatus.yearMonth', yearMonth + '/01');
      setYearMonth(moment(yearMonth + '/01').toDate());


      const { staffStampList, employment } = await getWorkRecords(staffCode, yearMonth);

      setStaffStampList(staffStampList);
      setEmployment(employment);

      const requestResultData = await getRequestResult(staffCode, yearMonth);
      setApplyResult(requestResultData);

      const leaveStatusData = await getLeaveStatus(staffCode, yearMonth);
      setLeaveStatus(leaveStatusData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    setIsLoading,
    fetchList,
    selectType,
    setSelectType,
    getTargetDate,
    setTargetDate,
    getLoginStaffCcde,
    setLoginStaffCcde,
    getLoginStaffName,
    setLoginStaffName,
    staffStampList,
    setStaffStampList,
    employment,
    setEmployment,
    applyResult,
    setApplyResult,
    leaveStatus,
    setLeaveStatus,
    staffCode,
    tabTypes,
    operationInfo,
    yearMonth,
  };
};

/**
 * Get operation infor
 */
const useOperationInfo = (date: Date) => {
  const [operationInfo, setOperationInfo] = useState<any>('');
  useEffect(() => {
    if (!date) {
      return;
    }
    try {
      const fetchOperationInfo = async () => {
        const response = await getOperationInfo(date);
        setOperationInfo(response);
      };
      fetchOperationInfo();
    } catch (error) {

    }
  }, [date]);
  return {
    operationInfo,
  };
};

export default useWorkingStatusMobileData;
