import { useState, useEffect } from 'react';
import { saveSalesExpenses, getDetailSalesExpenses, updateSalesExpenses } from 'api/salesExpensesMST';
import { useParams, useHistory } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';
import moment from 'moment';

export const useSalesExpenseForm = () => {
  const [blocking, setBlocking] = useState(false);
  const [expensesCode, setExpensesCode] = useState<string>('');
  const [expensesName, setExpensesName] = useState<string>('');
  const [dispOrder, setDispOrder] = useState<number>(0);
  const [expensesClassification, setExpensesClassification] = useState<string>('0');
  const [applyStartDate, setApplyStartDate] = useState<Date>(new Date());
  const [applyEndDate, setApplyEndDate] = useState<Date | undefined>(undefined);
  const [save, setSave] = useState<number>(0);
  const { expensesCodeId } = useParams();
  const { successNotification, errorNotification } = useToastNotification();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [undecided, setUndecided] = useState(true);
  const history = useHistory();

  // eslint-disable-next-line no-shadow
  const handleSaveSalesExpenses = async (expensesCode: string, expensesName: string, dispOrder: number, expensesClassification: string, applyStartDate: string, applyEndDate: string) => {
    saveSalesExpenses(expensesCode, expensesName, dispOrder, expensesClassification, applyStartDate, applyEndDate).then((response: any) => {
      let errors = '';
      if (response.status && response.status !== 200) {
        (response.data as Array<any>).map(
          (err) => {
            errors += err.defaultMessage;
          },
        );
        errorNotification(errors);
      } else {
        setExpensesCode('');
        setExpensesName('');
        setDispOrder(0);
        setExpensesClassification('0');
        setApplyStartDate(new Date());
        setApplyEndDate(undefined);
        setUndecided(true);
        successNotification('登録しました。');
        history.push('/salesExpensesMST');
      }
      setBlocking(false);
    }).catch((error: any) => {
      let errors = '';
      if (error.response.data) {
        errors += error.response.data.defaultMessage;
        errorNotification(errors);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setBlocking(false);
    });
  };

  // eslint-disable-next-line no-shadow
  const handleUpdateSalesExpenses = async (expensesCode: string, expensesName: string, dispOrder: number, expensesClassification: string, applyStartDate: string, applyEndDate: string) => {
    updateSalesExpenses(expensesCode, expensesName, dispOrder, expensesClassification, applyStartDate, applyEndDate).then((response: any) => {
      let errors = '';
      if (response.status && response.status !== 200) {
        (response.data as Array<any>).map(
          (err) => {
            errors += err.defaultMessage;
          },
        );
        errorNotification(errors);
      } else {
        successNotification('更新しました。');
      }
      setBlocking(false);
    }).catch((error: any) => {
      let errors = '';
      if (error.response.data) {
        errors += error.response.data.defaultMessage;
        errorNotification(errors);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setBlocking(false);
    });
  };

  useEffect(() => {
    if (save === 1) {
      const startDate = moment(applyStartDate).startOf('month').format('YYYY-MM-DD');
      const endDate = !undecided ? moment(applyEndDate).endOf('month').format('YYYY-MM-DD') : moment('9999-12-31').format('YYYY-MM-DD');
      if (expensesCode.trim() === '') {
        setSave(0);
        setBlocking(false);
        errorNotification('コードを入力してください。');
        return;
      }
      if (expensesName.trim() === '') {
        setSave(0);
        setBlocking(false);
        errorNotification('名称を入力してください。');
        return;
      }

      if (startDate > endDate) {
        setSave(0);
        setBlocking(false);
        errorNotification('適用終了月には適用開始月以降の年月を指定してください');
        return;
      }

      if (expensesCodeId) {
        setBlocking(true);
        handleUpdateSalesExpenses(expensesCode, expensesName, dispOrder, expensesClassification, startDate, endDate);
      } else {
        handleSaveSalesExpenses(expensesCode, expensesName, dispOrder, expensesClassification, startDate, endDate);
      }
      setSave(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save]);

  useEffect(() => {
    if (expensesCodeId) {
      setBlocking(true);
      getDetailSalesExpenses(expensesCodeId).then((response: any) => {
        setExpensesCode(expensesCodeId);
        setExpensesName(response.expensesName);
        setDispOrder(response.dispOrder);
        setExpensesClassification(response.expensesClassification);
        setApplyStartDate(new Date(response.applyStartDate));
        setApplyEndDate(new Date(response.applyEndDate));
        if (moment(response.applyEndDate).format('YYYY-MM-DD') !== '9999-12-31') {
          setUndecided(false);
        }
        setBlocking(false);
      }).catch((error: any) => {
        let errors = '';
        if (error.response && error.response.data.length > 0) {
          errors += error.response.data.defaultMessage;
          errorNotification(errors);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
        setExpensesCode('');
        setBlocking(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  const handleSubmit = () => {
    setBlocking(true);
    setSave(1);
    closeConfirmModal();
  };

  return {
    blocking,
    setBlocking,
    expensesCode,
    setExpensesCode,
    expensesName,
    setExpensesName,
    dispOrder,
    setDispOrder,
    expensesClassification,
    setExpensesClassification,
    applyStartDate,
    setApplyStartDate,
    applyEndDate,
    setApplyEndDate,
    setSave,
    undecided,
    setUndecided,
    expensesCodeId,
    confirmModalOpen,
    closeConfirmModal,
    openConfirmModal,
    handleSubmit,
  };
};

export default {

};
