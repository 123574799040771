/** @jsx jsx */
import React, { useState, useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { css, jsx } from '@emotion/core';
import LableTitle from 'components/atoms/LableTitle';
import PageTitle from 'components/atoms/PageTitle';
import PrimaryButton from 'components/atoms/Button';
import DataTable from 'components/organismos/DataTable/DataTable';
import VacationDomain from 'domain/master/labor/VacationMaster';
import { deleteById } from 'api/vacationMaster';
import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import useToastNotification from 'hooks/useToastNotification';
import { useVacationMaster } from './hooks';

const VacationMasterPage: React.FC<{
  roleScreen?: any
}> = ({ roleScreen }) => {
  const { vacationList, setVacationList } = useVacationMaster();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedVacationMaster, setSelectedVacationMaster] = useState(
    VacationDomain.generateInitial(),
  );
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((vacation : VacationDomain) => {
    setConfirmModalOpen(true);
    setSelectedVacationMaster(vacation);
  }, []);

  const deleteVacationMaster = useCallback(
    () => {
      const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
      deleteById(companyCode, selectedVacationMaster.holidayId).then((response:any) => {
        setVacationList(vacationList.filter(
          (vacation) => vacation.holidayId !== selectedVacationMaster.holidayId,
        ));
        setConfirmModalOpen(false);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = '';
          error.response.data.errors.map((item: { defaultMessage: string; }) => {
            msgError += `${item.defaultMessage}\n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    },
    [errorNotification, selectedVacationMaster.holidayId,
      setVacationList, successNotification, vacationList],
  );
  const columns: Array<Column<VacationDomain>> = useMemo(() => [
    {
      Header: 'コード',
      accessor: 'holidayCode',
      sortType: 'basic'
    },
    {
      Header: '休暇名',
      accessor: 'holidayName',
    },
    {
      Header: '休暇タイプ',
      accessor: 'holidayTypeLabel',
    },
    // {
    //   Header: '色設定',
    //   id: 'holidayBgColor',
    //   Cell: (cell: { row :{original: VacationDomain }}) => (
    //     <div style={{
    //       backgroundColor: cell.row.original.holidayBgColor,
    //       color: cell.row.original.holidayForecolor,
    //       width: '50%',
    //       minHeight: '20px',
    //       borderRadius: '4px',
    //       textAlign: 'center',
    //       paddingTop: '6px',
    //       paddingBottom: '6px',
    //     }}
    //     >
    //       表示イメージ
    //     </div>
    //   ),
    // },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell:{row:{isExpanded: boolean, original:VacationDomain},
        data: Array<VacationDomain>}) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/vacationMaster/edit/${cell.row.original.holidayId}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            {roleScreen && roleScreen.editable === 1 && (
              <React.Fragment>
                <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                  <span
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                    onMouseEnter={() => setIsHoverDelete(true)}
                    onMouseLeave={() => setIsHoverDelete(false)}
                  >
                    <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </Link>
              </React.Fragment>
            )}
          </div>
        );
      },
    },

  ], [openConfirmModal, roleScreen]);

  return (
    <React.Fragment>
      <div>
        <LableTitle title="有給・特別休暇・欠勤などの休暇が設定可能になります。設定した休暇は勤怠でも申請できるようになります。" />
        {
          roleScreen && roleScreen.editable === 1 && (
          <PageTitle
            title=""
            rightContents={(
              <Link to="/vacationMaster/create">
                <PrimaryButton
                  ghost={false}
                  text="新規休暇作成"
                />
              </Link>
            )}
          />
        )}
        <div
          css={css`
            table th:nth-child(1) {
              width: 100px;
            }
            table th:nth-child(2) {
              width: 200px;
            }
            table th:nth-child(3) {
              width: 80px;
            }
            table th:nth-child(4) {
              width: 80px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTable 
            columns={columns} 
            data={vacationList}          
            sortBy={[
              { id: 'holidayCode', desc: false }
            ]}
            />
          </div>
        </div>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={deleteVacationMaster}
          actionType={ActionType.DELETE}
        />

      </div>
    </React.Fragment>
  );
};

export default VacationMasterPage;
