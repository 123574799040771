/* eslint-disable max-len */
import {
  useState, useCallback, useEffect, Dispatch, useMemo,
} from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';
import StaffDomain from 'domain/staff';
import { postSalaryInfo, deleteStaffSalary } from 'api/staff';
import SelfOrganizationDomain from 'domain/master/general/selfOrganization';
import { SalarySupportStaff, SalaryBusinessStaff, SalaryDataStaffDomain } from 'domain/master/general/salaryDataStaff';
import { getErrorMessageForResponse } from 'utility/errorUtil';
import { InfoMessage } from 'utility/messages';

export const useSalaryForm = (
  initialStaffDomain: StaffDomain = StaffDomain.generateInitial(),
  isEditMode: boolean = false,
  setIsLoading: Dispatch<React.SetStateAction<boolean>>,
  menuType: string,
) => {
  const history = useHistory();
  const [openManagerAddModal, setOpenManagerAddModal] = useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [detailMode, setDetailMode] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const [deleteTargetStaffSalary, setDeleteTargetStaffSalary] = useState<SalaryDataStaffDomain|null>(null);

  const onSubmit = async (values: StaffDomain) => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    setConfirmModalOpen(false);
    setIsLoading(true);

    try {
      const response = await postSalaryInfo(isEditMode ? 1 : 0, menuType, values.getStaffInfoPostData(isEditMode));
      const updatedStaff = new StaffDomain(response.staff);
      formik.setFieldValue('salaryList', updatedStaff.salaryList);

      if (!isEditMode) {
        sessionStorage.setItem('selectedOrgCodeStaffMaster', updatedStaff.orgCode);
      }
      setIsLoading(false);
      successNotification(isEditMode ? '更新しました。' : '登録しました。');
      if (!isEditMode && response) {
        history.goBack();
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification(getErrorMessageForResponse(error));
    }
  };
  const formik = useFormik({
    initialValues: initialStaffDomain,
    onSubmit,
  });

  useEffect(() => {
    formik.setValues(initialStaffDomain);
    formik.setFieldValue('selectSalary', initialStaffDomain.selectSalary);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStaffDomain]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const isOpenDeleteModal = useMemo(() => !!deleteTargetStaffSalary, [deleteTargetStaffSalary]);
  const closeDeleteModal = useCallback(() => setDeleteTargetStaffSalary(null), []);

  const onSubmitDeleteStaffSalary = useCallback(async () => {
    if (!deleteTargetStaffSalary) return;
    setIsLoading(true);
    try {
      const salaryList = await deleteStaffSalary(deleteTargetStaffSalary.staffCode, deleteTargetStaffSalary.staffSalaryId);
      initialStaffDomain.salaryList = salaryList;
      formik.setFieldValue('salaryList', salaryList);
      successNotification(InfoMessage.Common.DELETE_COMPLETED);
    } catch(error)  {
        errorNotification(getErrorMessageForResponse(error));
    } finally {
      setDeleteTargetStaffSalary(null);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik, initialStaffDomain, successNotification, errorNotification]);

  const addOrganizationSupport = useCallback((orgList: Array<SelfOrganizationDomain>) => {
    if (!formik.values.selectSalary.salarySupportDataList) {
      return;
    }
    const supportDataList : Array<SalarySupportStaff> = orgList.map(
      (org) => {
        const support = {
          staffSalaryId: '',
          orgCode: org.orgCode,
          orgName: org.orgName,
          addHourlySalary: 0,
          supportTransportation: 0,
        };
        return support;
      },
    );

    formik.setFieldValue('selectSalary.salarySupportDataList', formik.values.selectSalary.salarySupportDataList.concat(supportDataList));
  }, [formik]);

  const deleteOrganizationSupport = useCallback((index: number) => () => {
    if (!formik.values.selectSalary.salarySupportDataList) {
      return;
    }
    formik.setFieldValue('selectSalary.salarySupportDataList', formik.values.selectSalary.salarySupportDataList.filter((manager, idx) => idx !== index));
  }, [formik]);

  const setOrganizationSupport = useCallback(
    (index: number) => (newManager: SalarySupportStaff) => {
      if (!formik.values.selectSalary.salarySupportDataList) {
        return;
      }
      formik.setFieldValue('selectSalary.salarySupportDataList', formik.values.selectSalary.salarySupportDataList.map((manager, idx) => (idx === index ? newManager : manager)));
    }, [formik],
  );


  // Bussiness form
  const addSalaryBusiness = useCallback(() => {
    if (!formik.values.selectSalary.salaryBusinessList) {
      return;
    }
    const salaryBusinessData : SalaryBusinessStaff = {
      id: {
        staffSalaryId: '',
        businessId: '',
      },
      addHourlySalary: 0,
    };
    formik.setFieldValue('selectSalary.salaryBusinessList', formik.values.selectSalary.salaryBusinessList.concat(salaryBusinessData));
  }, [formik]);

  const deleteSalaryBusiness = useCallback((index: number) => () => {
    if (!formik.values.selectSalary.salaryBusinessList) {
      return;
    }
    formik.setFieldValue('selectSalary.salaryBusinessList', formik.values.selectSalary.salaryBusinessList.filter((manager, idx) => idx !== index));
  }, [formik]);


  const setSalaryBusiness = useCallback(
    (index: number) => (newManager: SalaryBusinessStaff) => {
      if (!formik.values.selectSalary.salaryBusinessList) {
        return;
      }
      formik.setFieldValue('selectSalary.salaryBusinessList', formik.values.selectSalary.salaryBusinessList.map((manager, idx) => (idx === index ? newManager : manager)));
    }, [formik],
  );


  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    openManagerAddModal,
    setOpenManagerAddModal,
    addOrganizationSupport,
    deleteOrganizationSupport,
    setOrganizationSupport,
    addSalaryBusiness,
    deleteSalaryBusiness,
    setSalaryBusiness,
    onSubmitDeleteStaffSalary,
    setDeleteTargetStaffSalary,
    isOpenDeleteModal,
    closeDeleteModal,
  };
};

export default {
  useSalaryForm,
};
