import React from 'react';

import { PrimaryButton } from "components/atoms/Button";

const BankForm: React.FC<{
  addCompanyBank: any
  tableBankCompany: any
  addRowCompanyBank: any
}> = ({
  addCompanyBank,
  tableBankCompany,
  addRowCompanyBank
}) => {

  return (
    <React.Fragment>
      {/* 広告掲載店舗名 */}
      
      <div >
        <table style={{ width: '100%' }}>
          <thead>
          </thead>
          <tbody>
            {addCompanyBank && addCompanyBank.length > 0 && addCompanyBank.map((row: any, rowIndex: number) => (
              <tr key={rowIndex}>
                {tableBankCompany.filter((col: any) => col.visible).map((column: any, colIndex: number) => column.content(rowIndex, colIndex))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PrimaryButton text="追加" onClick={addRowCompanyBank} />
    </React.Fragment>
  );
};

export default BankForm;
