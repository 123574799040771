/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/** @jsx jsx */
import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { css, jsx } from '@emotion/core';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import LableTitle from 'components/atoms/LableTitle';
import PageTitle from 'components/atoms/PageTitle';
import PrimaryButton from 'components/atoms/Button';
import ToastModal from 'components/organismos/ToastModal';
import DataTable from 'components/organismos/DataTable/DataTable';
import TimeOutputLayoutDomain from 'domain/master/attend/timeOutputLayout';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import { getOutputLayoutList, deleteTimeOutputLayoutById } from 'api/timeOutputLayout';
import useToastNotification from 'hooks/useToastNotification';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const TimeOutputLayoutList = () => {
  const [
    timeOutputLayoutList, setTimeOutputLayoutList] = useState<Array<TimeOutputLayoutDomain>>([]);

  /**
   * useEffect
   *
   */
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [selectedTimeOutputLayout, setSelectedTimeOutputLayout] = useState(
    TimeOutputLayoutDomain.generateInitial(),
  );
  const { successNotification, errorNotification } = useToastNotification();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  /**
   * useEffect
   *
   */
  useEffect(() => {
    getOutputLayoutList().then((response: Array<any>) => {
      setTimeOutputLayoutList(response.map((result) => new TimeOutputLayoutDomain(result)));
    });
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((timeOutputLayout : TimeOutputLayoutDomain) => {
    setConfirmModalOpen(true);
    setSelectedTimeOutputLayout(timeOutputLayout);
  }, []);

  /**
   * deleteTimeOutputLayoutMaster
   *
   * @param layoutId
   */
  const deleteTimeOutputLayoutMaster = useCallback(
    () => {
      deleteTimeOutputLayoutById(selectedTimeOutputLayout.layoutId).then((response:any) => {
        setTimeOutputLayoutList(
          timeOutputLayoutList.filter(
            (timeOutputLayout) => timeOutputLayout.layoutId !== selectedTimeOutputLayout.layoutId,
          ),
        );
        setConfirmModalOpen(false);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.forEach((element: any) => {
            stringErr += `${element.defaultMessage}<br />`;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      });
    },
    [errorNotification, selectedTimeOutputLayout.layoutId,
      successNotification, timeOutputLayoutList],
  );


  /**
   *
   *
   */
  const columns: Array<Column<TimeOutputLayoutDomain>> = useMemo(() => [
    {
      Header: 'コード',
      accessor: 'layoutCode',
      sortType: 'basic'
    },
    {
      Header: 'レイアウト名',
      accessor: 'layoutName',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell:{row:{isExpanded: boolean, original:TimeOutputLayoutDomain},
        data: Array<TimeOutputLayoutDomain>}) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            {
              roleScreen && roleScreen.editable === 1 && (
                <React.Fragment>
                  <Link to={`/masterTimeOutputLayout/edit/${cell.row.original.layoutId}`}>
                    <span
                      style={{ marginLeft: '20px' }}
                      onMouseEnter={() => setIsHoverEdit(true)}
                      onMouseLeave={() => setIsHoverEdit(false)}
                    >
                      <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
                    </span>
                  </Link>
                  <span
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                    onMouseEnter={() => setIsHoverDelete(true)}
                    onMouseLeave={() => setIsHoverDelete(false)}
                    onClick={() => { openConfirmModal(cell.row.original); }}
                  >
                    <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </React.Fragment>
              )
            }
          </div>
        );
      },
    },

  ], [openConfirmModal, roleScreen]);

  return (
    <React.Fragment>
      <div>
        <LableTitle title="労務規約項目マスタは雇用形態を登録するのに必要な項目です。" />
        <PageTitle
          title=""
          rightContents={
            roleScreen && roleScreen.editable === 1 && (
            <Link to="/masterTimeOutputLayout/add">
              <PrimaryButton
                ghost={false}
                text="新規勤怠出力レイアウト作成"
              />
            </Link>
          )}
        />
        <div
          css={css`
            table th:nth-child(1) {
              width: 100px;
            }
            table th:nth-child(2) {
              width: 200px;
            }
            table th:nth-child(3) {
              width: 80px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTable
              columns={columns}
              data={timeOutputLayoutList}
              sortBy={[
                { id: 'layoutCode', desc: false }
              ]}
            />
          </div>
        </div>
        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={deleteTimeOutputLayoutMaster}
          title="確認メッセージ"
          content="削除します。よろしいですか？"
          submitText="削除"
          items={[]}
        />
        <ToastModal
          open={toastModalOpen}
          closeHandler={() => setToastModalOpen(false)}
          title={toastMessage}
        />
      </div>
    </React.Fragment>
  );
};

export default TimeOutputLayoutList;
