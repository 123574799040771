/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { formatter, formatterPercent } from 'utility/removeComma';
import { css } from '@emotion/core';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';
import { grayScale } from 'components/styles';
import useSortTable, { FieldSortState, SortColumn } from 'hooks/useSortTable';
import { useDownload } from './hooks';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import MonthlyDailyReportDomain from 'domain/master/storeManagement/monthlyDailyReport';
import { Values } from 'utility/constants';
import { formatNumber } from 'utility/formatUtil';

const DEFAULT_SORT_FIELD = { fieldPath: 'orgCode', sort: 'asc' } as FieldSortState

const MonthlyDailyTable: React.FC<{
  setBlocking: any,
  monthlyDailyDetailList: Array<MonthlyDailyReportDomain>,
  monthlyDailyTotal: MonthlyDailyReportDomain,
  taxExclude: string,
  targetPeriodData: string,
  targetStoresData: string,
  roleScreen: any,
}> = ({
  setBlocking,
  monthlyDailyDetailList,
  monthlyDailyTotal,
  taxExclude,
  targetPeriodData,
  targetStoresData,
  roleScreen,
}) => {

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート項目 */
    const sortColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '店舗コード', sortField: 'orgCode',                                                                                 sortType: 'string', style: { top: '0', left: '0', zIndex: 99, maxWidth: '128px', width: '128px', minWidth: '128px' },                      className: "text-center stickyStyle fristColSticky" },
      { displayName: '店舗名',     sortField: 'orgName',                                                                                 sortType: 'string', style: { top: '0', left: '131px', zIndex: 99, maxWidth: ' 200px', width: '200px', minWidth: '200px' },                className: "text-center stickyStyle fristColSticky" },
      { displayName: '合計',       sortField: taxExclude === Values.TaxExclude.Excluded.value ? 'netSales' : 'totalSales',               sortType: 'number', style: { top: '0', left: '334px', zIndex: 99, maxWidth: '89px', width: '89px', minWidth: '89px', cursor: 'pointer' }, className: "text-center stickyStyle fristColSticky" },
      { displayName: '予算',       sortField: 'totalBudget',                                                                             sortType: 'number', style: { top: '0', left: '426px', zIndex: 99, maxWidth: '89px', width: '89px', minWidth: '89px' },                    className: "text-center stickyStyle fristColSticky" },
      { displayName: '達成率',     sortField: taxExclude === Values.TaxExclude.Excluded.value ? 'progressRate' : 'progressRateNetSales', sortType: 'number', style: { top: '0', left: '518px', zIndex: 99, maxWidth: '70px', width: '70px', minWidth: '70px' },                                                                                                  className: "text-center stickyStyle" },
    ], [taxExclude]);

    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(monthlyDailyDetailList, DEFAULT_SORT_FIELD);

    // ------------------------------------------------------------------
    // 出力
    // ------------------------------------------------------------------
    const { dowloadMonthlyDailyCsv,  downloadMonthlyDailyExcel } = useDownload();
    /** 出力用データ */
    const outputDataList = useMemo(() => dataSort.map(d => d.getOutputData(taxExclude)), [dataSort, taxExclude]);
    /** 出力用合計行 */
    const totalInformation = useMemo(() => [
      [
        '合計',
        ...monthlyDailyTotal.getOutputData(taxExclude).splice(1)
      ]
    ], [monthlyDailyTotal, taxExclude]);
    /** 出力用ヘッダ情報 */
    const headerInformation = useMemo(() => [[
      ...sortColumns.map(s => s.displayName),
      ...monthlyDailyTotal.totalSalesList.map(s => s.dayOfWeek),
    ]], [sortColumns, monthlyDailyTotal]);

    const data = {
      footer: '',
      formName: '日別店舗売上',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult: outputDataList,
      totalInformation: totalInformation,
      tax: `集計対象：${taxExclude === Values.TaxExclude.Excluded.value  ? Values.TaxExclude.Excluded.label : Values.TaxExclude.Included.label}`,
    };

    const dataExcel = {
      formName: '日別店舗売上',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult: outputDataList,
      totalInformation: totalInformation,
      mergedRegions: [],
      codeColumnIndex: [],
      tax: `集計対象：${taxExclude === Values.TaxExclude.Excluded.value  ? Values.TaxExclude.Excluded.label : Values.TaxExclude.Included.label}`,
    };

    const handleExportCSV = () => dowloadMonthlyDailyCsv(data, setBlocking);
    const handleExportExcel = () => downloadMonthlyDailyExcel(dataExcel, setBlocking);

    return (
      <div>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
            <FlexBox>
              <FlexBoxItem>
                <IconLabelButton
                  onClick={handleExportCSV}
                  iconType="download"
                  text=" CSV出力"
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <IconLabelButton
                  onClick={handleExportExcel}
                  iconType="download"
                  text=" EXCEL出力"
                />
              </FlexBoxItem>
            </FlexBox>
          )
        }

        <div style={{ margin: '15px' }}>
          <div
            className="templateTable newDesignTable"
            style={{
              height: 'auto',
              minHeight: '10%',
              maxHeight: 'calc(100vh - 300px)',
              paddingBottom: isMacOs ? '35px' : '1px',
            }}
          >
            <table
              className="table table-bordered text-nowrap"
              css={css`
            table th {
             border: "1px solid #000"
            }
            table td:{
              border: "1px solid #000"
            }
          `}
              style={{ tableLayout: 'fixed' }}
            >
              <thead>
                <tr>
                  { sortColumns.map((col: SortColumn, index: number) => (
                    <th key={index} style={col.style} className={col.className} onClick={e => sortByFieldPath(col.sortField, col.sortType)}>
                      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                        <span style={{ margin: 'auto' }}>{col.displayName}</span>
                        <div style={{ position: 'absolute', right: 0, marginTop: "-3px" }}>
                          {sortField.fieldPath === col.sortField && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== col.sortField && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </div>
                    </th>
                  ))}
                  {/* 日付ヘッダ */}
                  {monthlyDailyTotal?.getSalesList(taxExclude)?.map((item: any, index: number) => {
                    const listName = taxExclude ? 'netSalesList' : 'totalSalesList';
                    return (
                      <th
                        key={index}
                        style={{
                          position: 'sticky', top: '0', width: '83px', maxWidth: '83px', minWidth: '83px',
                        }}
                        className="text-center stickyStyle"
                        onClick={e => sortByFieldPath(`${listName}[${index}].value`, 'number')}
                      >
                        <div style={{ display: 'flex', justifyContent: 'center' , position: 'relative' }}>
                          <span style={{ margin: 'auto' }}>{item.dayOfWeek}</span>
                          <div style={{ position: 'absolute', right: 0, marginTop: "-3px" }}>
                          {sortField.fieldPath === `${listName}[${index}].value` && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== `${listName}[${index}].value` && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                          </div>
                        </div>
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {
                  dataSort.map((item: MonthlyDailyReportDomain, index: number) => (
                    <tr key={index}>
                      <td
                        className="text-left stickyStyle fristColSticky"
                        style={{ left: '0', zIndex: 10, padding: '0 4px' }}
                        title={item.orgCode}
                      >
                        <div className="text-ellipsis" style={{ whiteSpace: 'nowrap' }} title={item.orgCode}>{item.orgCode}</div>
                      </td>
                      <td
                        className="text-left stickyStyle "
                        style={{ left: '131px', zIndex: 10, padding: '0 4px' }}
                        title={item.orgName}
                      >
                        <span>
                          <div className="text-ellipsis" style={{ whiteSpace: 'nowrap' }} title={item.orgName}>
                            {item.orgName}
                          </div>
                        </span>
                      </td>
                      <td className="text-right stickyStyle" style={{ left: '334px', zIndex: 10 }}>
                        <span>{item.getSales(taxExclude)}</span>
                      </td>
                      <td className="text-right stickyStyle" style={{ left: '426px', zIndex: 10 }}>
                        <span>{item.totalBudget}</span>
                      </td>
                      <td className="text-right stickyStyle" style={{ left: '518px', zIndex: 10 }}>
                        <span>{item.getProgressRate(taxExclude)}</span>
                      </td>
                      {item.getSalesList(taxExclude).map((x: any, i: any) => (
                        <td key={i} className="text-right"><span>{formatNumber(x.value)}</span></td>
                      ))}
                    </tr>
                  ))
                }
                {/* 合計行 */}
                <tr>
                  <td
                    colSpan={2}
                    style={{ top: '0', left: '0', zIndex: 10 }}
                    className="text-center stickyStyle fristColSticky"
                  >
                    <span>合計</span>
                  </td>
                  <td className="text-right stickyStyle" style={{ left: '334px', zIndex: 10 }}>
                    <span>{monthlyDailyTotal.getSales(taxExclude)}</span>
                  </td>
                  <td className="text-right stickyStyle" style={{ left: '426px', zIndex: 10 }}>
                    <span>{monthlyDailyTotal.totalBudget}</span>
                  </td>
                  <td className="text-right stickyStyle" style={{ left: '518px', zIndex: 10 }}>
                    <span>{monthlyDailyTotal.getProgressRate(taxExclude)}</span>
                  </td>
                  {monthlyDailyTotal.getSalesList(taxExclude).map((x: any, i: any) => (
                    <td key={i} className="text-right"><span>{formatNumber(x.value)}</span></td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

export default MonthlyDailyTable;
