/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import FormContents from 'components/atoms/Form/FormContents';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import FlexBox from 'components/atoms/FlexBox';
import useDaySeparateSalesStoreManagementList from 'components/organismos/master/general/salesPage/daySeparateSalesStoreManagement/hook';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import IconLabelButton from 'components/molecules/IconLabelButton';
import moment from 'moment';
import DatePicker from 'components/molecules/DatePicker';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const DaySeparateSalesStoreManagement: React.FC<{}> = () => {
  const {
    isLoading,
    selectedMonth,
    setSelectedMonth,
    functionType,
    orgCode,
    setOrgCode,
    orgName,
    setorgName,
    categoryAry,
    setCategoryAry,
    daySeparateSalesStoreManagementList,
    handleDownloadCsv,
    handleDownloadExcel
  } = useDaySeparateSalesStoreManagementList();

  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox>
          <FlexBoxItem width="100px">
            <FormLabel label="対象年月" />
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePicker
              dateFormat="yyyy年MM月"
              date={selectedMonth}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setSelectedMonth(date);
                }
              }}
              isFullWidth={false}
              showMonthYearPicker={true}
            />
          </FlexBoxItem>
        </FlexBox>

        <OrganizationFilteredTree
          functionType={functionType}
          orgCallback={(args: string | Array<string>) => {
            setOrgCode(String(args));
          }}
          orgCategoryCallback={(args: Array<string>) => {
            setCategoryAry(args);
          }}
          orgNameCallback={(arg: any) => {
            setorgName(String(arg));
          }}
          initOrgValue={String(orgCode)}
          staffCode={loginStaffCode}
          orgLabel="店舗名"
          addAllItem={true}
          targetdayForm={new Date(moment(selectedMonth).startOf('month').format('YYYY/MM/DD'))}
          targetdayTo={new Date(moment(selectedMonth).endOf('month').format('YYYY/MM/DD'))}
        />

        <FlexBox>
          <FlexBoxItem>
            <IconLabelButton
              onClick={()=>handleDownloadCsv("日別売上管理表.csv")}
              iconType="download"
              text=" CSV出力"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <IconLabelButton
              onClick={()=>handleDownloadExcel("日別売上管理表.xlsx")}
              iconType="download"
              text=" EXCEL出力"
            />
          </FlexBoxItem>
        </FlexBox>

        {/* <DaySeparateSalesStoreManagementTableV2
          listSalesManagement={daySeparateSalesStoreManagementList}
          selectedDate={targetDateFrom}
          orgName={orgName}
          roleScreen={roleScreen}
        /> */}
      </FormContents>
    </BlockUI>
  );
};
export default DaySeparateSalesStoreManagement;
