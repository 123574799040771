import React, {
  useCallback, useReducer, useEffect, useContext, createContext, useState, Dispatch, SetStateAction,
} from 'react';
import useToastNotification from 'hooks/useToastNotification';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  importZenginFileExcel, importKoyuuFileExcel, getBankList, postImportRegist,
} from '../api/import';
import {
  TGeneralImport, TFileImportedResponse, TOptions,
} from '../type/import';
import { fetch, Actions } from '../import/reducer/action';
import { initialState, reducer } from '../import/reducer/reducer';


// declare Types
export type TBankImportInfo = {
  bankImportState: {} & TGeneralImport,
  dispatch: React.Dispatch<Actions>,
  errors: string[] | undefined,
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleSubmit: () => void,
  confirmModalOpen: boolean,
  closeConfirmModal: () => void,
  handleCancelSubmit: () => void,
  confirmOrCancelModal: string,
  setConfirmOrCancelModal: Dispatch<SetStateAction<string>>,
  renderTable: any,
  setRenderTable: Dispatch<SetStateAction<any>>
  uploadFile: File | undefined,
  setUploadFile: Dispatch<SetStateAction<File | undefined>>
  dataTableList: TGeneralImport[],
  setDataTableList: Dispatch<SetStateAction<TGeneralImport[]>>
  handleButtonImportType: (file: File | undefined, type: string) => void,
  fileImportedResponse: TFileImportedResponse,
  setFileImportedResponse: Dispatch<SetStateAction<TFileImportedResponse>>,
  renderTableBlocking: boolean,
  dynamicBankOptions: TOptions[],
  blocking: boolean,
  fetchBankList: () => void,
}
// declare initial state
const BankImportDetails = createContext<TBankImportInfo>({
  bankImportState: initialState,
  dispatch: () => null,
  errors: [],
  handleChange: () => null,
  handleSubmit: () => null,
  confirmModalOpen: false,
  closeConfirmModal: () => null,
  handleCancelSubmit: () => null,
  confirmOrCancelModal: 'confirm',
  setConfirmOrCancelModal: () => null,
  renderTable: false,
  setRenderTable: () => null,
  uploadFile: undefined,
  setUploadFile: () => null,
  dataTableList: [],
  setDataTableList: () => null,
  handleButtonImportType: () => null,
  fileImportedResponse: {
    bankCode: '',
    bankName: '',
    importResults: [],
  },
  setFileImportedResponse: () => null,
  renderTableBlocking: false,
  dynamicBankOptions: [{
    value: '0',
    label: '全銀',
  },
  ],
  blocking: false,
  fetchBankList: () => null,
});

export const useBankImportDetails = () => {
  const context = useContext(BankImportDetails);
  if (!context) {
    throw new Error('useBankImportDetails must be used within a BankDetailProvider');
  }
  return context;
};

export const BankDetailProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [blocking, setBlocking] = useState<boolean>(false);
  const [renderTableBlocking, setRenderTableBlocking] = useState<boolean>(false);
  const [bankImportState, dispatch] = useReducer(reducer, initialState);
  const { successNotification, errorNotification } = useToastNotification();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [errors, setErrors] = useState<Array<string> | undefined>([]);
  const [confirmOrCancelModal, setConfirmOrCancelModal] = useState<string>('confirm');
  const [renderTable, setRenderTable] = useState<any>(false);
  const [uploadFile, setUploadFile] = useState<File | undefined>();
  const [dataTableList, setDataTableList] = useState<TGeneralImport[]>([]);
  const [dynamicBankOptions, setDynamicBankOptions] = useState<TOptions[]>([{
    value: '0',
    label: '全銀',
  },
  ]);
  const [fileImportedResponse, setFileImportedResponse] = useState<TFileImportedResponse>({
    bankCode: '',
    bankName: '',
    importResults: [],
  });

  const extensionFile = (name: string) => name.split('.').pop()?.toLowerCase();

  if (uploadFile) {
    const fileType = extensionFile(uploadFile.name);
    if (fileType !== 'csv') {
      setUploadFile(undefined);
    }
  }
  const { businessStartDate, businessEndDate } = bankImportState;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = {
      ...bankImportState,
      [e.target.name]: e.target.value,
    };
    dispatch(fetch(newValue));
  };
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const fetchBankList = useCallback(async () => {
    try {
      setBlocking(true);
      await getBankList().then((response: any) => {
        // setDynamicBankOptions(response.data);
        if (response && response.companyBankList) {
          const convertedData = response.companyBankList.map(((item: any) => ({
            value: item.bankCode,
            label: item.bankName,
          })));
          setDynamicBankOptions(dynamicBankOptions.concat(convertedData));
        }
      });
      setBlocking(false);
    } catch (err) {
      setBlocking(false);
      errorNotification('サーバー側でエラーが発生しました。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonImportType = useCallback(async (file: File | undefined, type: string) => {
    setErrors(undefined);
    setRenderTableBlocking(true);
    if (type === '0') {
      await importZenginFileExcel(file, businessStartDate, businessEndDate)
        .then((response: any) => {
          setRenderTableBlocking(false);
          if (response.status !== 200) {
            setErrors((response.data.errors as Array<Error>).map((err) => err.message));
          } else {
            // successNotification('インポートが完了しました。');
            setFileImportedResponse(response.data);
            // setUploadFile(undefined);
          }
        }).catch((error: any) => {
          setRenderTableBlocking(false);
          setErrors((error.response.data.errors as Array<Error> || []).map((err) => err.message));
          if (error.response && error.response.data && error.response.data.errors) {
            const listErr = error.response.data.errors;
            let stringErr = '';
            listErr.map((element: any) => {
              stringErr += `${element.defaultMessage} \n`;
              return stringErr;
            });
            setRenderTable(false);
            setUploadFile(undefined);
            errorNotification(stringErr);
          } else if (error.response && error.response.data && Array.isArray(error.response.data)) {
            const normalError = error.response.data;
            let stringNormalError = '';
            normalError.map((element: any) => {
              stringNormalError += `${element.defaultMessage} \n`;
              return stringNormalError;
            });
            setRenderTable(false);
            setUploadFile(undefined);
            errorNotification(stringNormalError);
          } else {
            setRenderTable(false);
            setUploadFile(undefined);
            errorNotification('サーバー側でエラーが発生しました。');
          }
        });
    } else {
      const bankCode = type;
      await importKoyuuFileExcel(file, businessStartDate, businessEndDate, bankCode)
        .then((response: any) => {
          setRenderTableBlocking(false);
          if (response.status !== 200) {
            setErrors((response.data.errors as Array<Error>).map((err) => err.message));
          } else {
            // successNotification('インポートが完了しました。');
            // setUploadFile(undefined);
            setFileImportedResponse(response.data);
          }
        }).catch((error: any) => {
          setRenderTableBlocking(false);
          setErrors((error.response.data.errors as Array<Error> || []).map((err) => err.message));
          if (error.response && error.response.data && error.response.data.errors) {
            const listErr = error.response.data.errors;
            let stringErr = '';
            listErr.map((element: any) => {
              stringErr += `${element.defaultMessage} \n`;
              return stringErr;
            });
            setRenderTable(false);
            setUploadFile(undefined);
            errorNotification(stringErr);
          } else if (error.response && error.response.data && Array.isArray(error.response.data)) {
            const normalError = error.response.data;
            let stringNormalError = '';
            normalError.map((element: any) => {
              stringNormalError += `${element.defaultMessage} \n`;
              return stringNormalError;
            });
            setRenderTable(false);
            setUploadFile(undefined);
            errorNotification(stringNormalError);
          } else {
            setRenderTable(false);
            setUploadFile(undefined);
            errorNotification('サーバー側でエラーが発生しました。');
          }
        });
    }
  }, [businessEndDate, businessStartDate, errorNotification]);
  const handleSubmit = useCallback(() => {
    const createUser = sessionStorage.getItem('loginUser.staffName') || '';
    const importId = fileImportedResponse
      && fileImportedResponse.importResults
      && fileImportedResponse.importResults.length > 0
      && fileImportedResponse.importResults[0]
      && fileImportedResponse.importResults[0].importId;
    const postData = {
      importId,
      createUser,
    };
    if (!confirmModalOpen) {
      // 登録内容確認モーダルが表示されていなければ表示する
      setConfirmModalOpen(true);
      return;
    }
    // エラー表示を初期化
    setErrors(undefined);
    setConfirmModalOpen(false);
    setRenderTable(false);
    setUploadFile(undefined);
    setBlocking(true);
    postImportRegist(postData)
      .then((response: any) => {
        setBlocking(false);
        successNotification('入金登録が完了しました');
      }).catch((error: any) => {
        setBlocking(false);
        console.log(error, 'error');
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage} \n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else if (error.response && error.response.data && Array.isArray(error.response.data)) {
          const normalError = error.response.data;
          let stringNormalError = '';
          normalError.map((element: any) => {
            stringNormalError += `${element.defaultMessage} \n`;
            return stringNormalError;
          });
          errorNotification(stringNormalError);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  }, [confirmModalOpen, errorNotification, fileImportedResponse, successNotification]);
  const handleCancelSubmit = useCallback(() => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    // エラー表示を初期化
    setErrors(undefined);
    setConfirmModalOpen(false);
    successNotification('キャンセルしました');
    setRenderTable(false);
    setUploadFile(undefined);
  }, [confirmModalOpen, successNotification]);

  const value = {
    bankImportState,
    errors,
    dispatch,
    handleChange,
    handleSubmit,
    confirmModalOpen,
    closeConfirmModal,
    handleCancelSubmit,
    confirmOrCancelModal,
    setConfirmOrCancelModal,
    renderTable,
    setRenderTable,
    uploadFile,
    setUploadFile,
    dataTableList,
    setDataTableList,
    handleButtonImportType,
    fileImportedResponse,
    setFileImportedResponse,
    renderTableBlocking,
    dynamicBankOptions,
    blocking,
    fetchBankList,
  };
  return (
    <BankImportDetails.Provider value={value}>
      {children}
    </BankImportDetails.Provider>
  );
};
