import {
  useState, useCallback, useEffect,
} from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { createOrUpdateMediaMaster, getMediaMaster } from 'api/mediaMaster';
import MediaDomain from 'domain/master/sales/media';
import useToastNotification from 'hooks/useToastNotification';

type MediaDomainKey = keyof Pick<MediaDomain, 'paymentMediaMstCode'|'paymentMediaMstName'|'dispOrder'>;

export const useMediaMasterForm = () => {
  const { paymentMediaMstCode } = useParams();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmModalCustomOpen, setConfirmModalCustomOpen] = useState(false);
  const [contentModalCustom, setContentModalCustom] = useState('');
  const { successNotification, errorNotification } = useToastNotification();

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const history = useHistory();

  const onSubmit = async (val: any) => {

    if (val.paymentMediaMstCode === undefined || val.paymentMediaMstCode === null) {
      errorNotification('コードを入力してください。');
      return;
    }
    if (val.paymentMediaMstName === undefined || val.paymentMediaMstName === null) {
      errorNotification('名称を入力してください。');
      return;
    }
    if (val.dispOrder === undefined || val.dispOrder === null) {
      errorNotification('並び順を入力してください。');
      return;
    }
    if (!confirmModalOpen && !confirmModalCustomOpen) {
      setConfirmModalOpen(true);
      return;
    }

    const submitObj = {
      id: {
        companyCode,
        paymentMediaMstCode: val.paymentMediaMstCode,
      },
      paymentMediaMstName: val.paymentMediaMstName,
      dispOrder: val.dispOrder,
      // paymentMediaCategoryCode: val.paymentMediaCategoryCode,
      paymentMediaCategoryCode: '',
      createUser: staffName,
      updateUser: staffName,
    };

    createOrUpdateMediaMaster(!!paymentMediaMstCode, !!isConfirm, submitObj)
      .then((response: any) => {
        successNotification('登録しました。');
        history.push('/salesPaymentMedia/paymentMaster');
      })
      .catch((error: any) => {
        setIsConfirm(false);
        setConfirmModalOpen(false);
        setConfirmModalCustomOpen(false);
        if (error.response.status === 406) {
          let strCode = '';
          const salesPaymentMediaMst = error.response.data;
          for (let i = 0; i < salesPaymentMediaMst.length; i++) {
            const code = salesPaymentMediaMst[i];
            strCode += `${code.id.paymentMediaMstCode}, `;
          }
          strCode = strCode.slice(0, strCode.length - 2);
          setContentModalCustom(`既に同じ支払メディア名が登録されているますが、よろしいですか？（支払メディアコード：${strCode})`);
          setConfirmModalCustomOpen(true);
          setIsConfirm(true);
        } else if (error.response && error.response.data && error.response.data.errors.count !== 0) {
          errorNotification(error.response.data.errors[0].defaultMessage);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  };

  const formik = useFormik({
    initialValues: MediaDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    if (paymentMediaMstCode) {
      getMediaMaster(paymentMediaMstCode).then((response: Array<MediaDomain>) => {
        response.filter((item) => {
          const parseItem = new MediaDomain(item);
          if (parseItem.paymentMediaMstCode === paymentMediaMstCode) {
            formik.setValues(parseItem);
            return true;
          }
          return false;
        });
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMediaMstCode]);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmModalCustom = useCallback(() => {
    setIsConfirm(false);
    setConfirmModalCustomOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    history,
    confirmModalCustomOpen,
    closeConfirmModalCustom,
    contentModalCustom,
  };
};


export default useMediaMasterForm;
