
import { useState, useEffect } from 'react';
import getListMediaMaster from 'api/mediaMaster';
import MediaMasterDomain from 'domain/master/sales/media';

export const useMediaMasterList = (orgCode: string) => {
  // eslint-disable-next-line max-len
  const [mediaMasterList, setMediaMasterList] = useState < Array<MediaMasterDomain>>([]);
  useEffect(() => {
    getListMediaMaster(orgCode).then((response: Array<any>) => {
      setMediaMasterList(response.map(
        (result) => new MediaMasterDomain(result),
      ));
    });
  }, [orgCode, setMediaMasterList]);
  return { mediaMasterList, setMediaMasterList };
};

export default useMediaMasterList;