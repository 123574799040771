/* eslint-disable max-len */
import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FileSelectButton from 'components/molecules/FileSelectButton';
import BodyText from 'components/atoms/BodyText';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { utilityColor } from 'components/styles';
import FormTitle from 'components/atoms/Form/FormTitle';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import BlockUI from 'components/molecules/BlockUi';
import useAttendPayslipImport from './hooks';

const AttendPayslipImportLayout: React.FC = () => {
  const {
    setFormatType, inputValue, optionsFormatType, formik, setUploadFile, uploadFile, onSubmit, errors, confirmModalOpen, closeConfirmModal, handleSubmit, blocking,
  } = useAttendPayslipImport();
  const handleChange = async (e: any) => {
    const val = e.target.value;
    formik.setFieldValue('formatType', val);
    setFormatType(val);
  };
  return (
    <div>
      <BlockUI blocking={blocking}>
        <FormContents>
          <FormTitle
            title="登録するデータ"
          />
          <div>データ種別</div>
          <FormField>

            <RadioSelectForm
              label=""
              items={optionsFormatType}
              name="formatType"
              value={String(formik.values.formatType)}
              setValue={(e) => handleChange(e)}
            />
          </FormField>
          <BodyText color={utilityColor.error} size="md">※CSVファイルの１行目（ヘッダー行）はインポートされません。</BodyText>
          <FormField>
            <FileSelectButton
              text="CSVファイルをドラッグ＆ドロップ  または  クリックしてファイルを選択してください"
              ghost={true}
              setFile={setUploadFile}
              value={inputValue}
              accept=".csv"
            />
          </FormField>

          {uploadFile || errors
            ? (
              <FormField
                displayBlock={true}
              >
                <div>
                  {uploadFile?.name}
                </div>
                {errors && errors.map((error) => (
                  <div key={error}>
                    <BodyText color={utilityColor.error}>
                      {error}
                    </BodyText>
                  </div>
                ))}
              </FormField>
            )
            : null}
          <FormSubmitArea>
            <PrimaryButton
              text="インポート"
              onClick={handleSubmit}
              disabled={!uploadFile}
            />
          </FormSubmitArea>
        </FormContents>

      </BlockUI>

      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        title="確認メッセージ"
        onSubmit={onSubmit}
        content="ファイルをアップロードします。よろしいですか？"
        items={[]}
      />
    </div>

  );
};
export default AttendPayslipImportLayout;
