
/** @jsx jsx */
import { useState, useCallback, useEffect } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import BodyText from 'components/atoms/BodyText';
import { utilityColor } from 'components/styles';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Checkbox from 'components/molecules/Checkbox';
import PrimaryButton, { SecondaryButton } from 'components/atoms/Button';
import FlexBox from 'components/atoms/FlexBox';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import useToastNotification from 'hooks/useToastNotification';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { jsx } from '@emotion/core';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const MasterShopSpecifyLayout = () => {
  const history = useHistory();

  const LOCAL_STORAGE_SHOP_INFO_KEY_NAME: string = 'win-board-localstorage-shop-info';
  const storageEncode = localStorage.getItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME);
  const storage: any = storageEncode ? JSON.parse(storageEncode) : '';
  const [stampByBelongOrg, setstampByBelongOrg] = useState(!!(Number(storage.stampByBelongOrg) === 1));
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const { successNotification, errorNotification } = useToastNotification();
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [confirmModel, setConfirmModel] = useState(false);
  const [confirmClearModel, setConfirmClearModel] = useState(false);
  const [specify, setSpecify] = useState(storage ? storage.specifyCode : sessionStorage.getItem('masterShopSpecify'));
  const [orgCode, setorgCode] = useState(localStorage.getItem('orgCodeSpecify') ? localStorage.getItem('orgCodeSpecify') : sessionStorage.getItem('masterShopSpecifyOrg'));

  const LOCAL_STORAGE_TIME_RECODER: string = 'win-board-localstorage-check-timerecoder';
  const checkTimeRecorder = localStorage.getItem(LOCAL_STORAGE_TIME_RECODER);
  const sessioncheckTimeRecorder: any = checkTimeRecorder ? JSON.parse(checkTimeRecorder) : false;
  const [checkTimeReCoder, setcheckTimeReCoder] = useState(sessioncheckTimeRecorder === true);

  const handleAdd = useCallback(() => {
    setConfirmModel(false);
    if (specify === '') {
      errorNotification('店舗を選択してください。');
    } else {
      // let isStampByBelongOrg = false;
      // orgTreesOptions.filter((option: any) => {
      //   if (specifyCode.includes(option.value)) {
      //     if (String(option.orgCode) === orgCode) {
      //       isStampByBelongOrg = true;
      //     }
      //   }
      // });
      const specifyCode = specify !== null ? specify : sessionStorage.getItem('masterShopSpecify');
      const registeredMap = {
        specifyCode,
        companyCode,
        stampByBelongOrg: stampByBelongOrg ? 1 : 0,
      };
      localStorage.setItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME, JSON.stringify(registeredMap));
      localStorage.setItem('orgCodeSpecify', String(orgCode));

      localStorage.setItem(LOCAL_STORAGE_TIME_RECODER, JSON.stringify(checkTimeReCoder));
      successNotification('登録しました。');
    }
  },
  [checkTimeReCoder, companyCode, errorNotification, orgCode, specify, stampByBelongOrg, successNotification]);
  const handleClear = () => {
    localStorage.removeItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME);
    setConfirmClearModel(false);
    setstampByBelongOrg(false);
    localStorage.removeItem(LOCAL_STORAGE_TIME_RECODER);
    setcheckTimeReCoder(false);
    successNotification('店舗情報をクリアしました。');
    localStorage.removeItem('orgCodeSpecify');
  };

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole])

  return (
    <div>
      <FormContents>

        <OrganizationFilteredTree
          functionType={1}
          orgCallback={(args: string | Array<string>) => {
            setorgCode(String(args));
          }}
          orgSpecifyCallback={
            (args: string) => {
              setSpecify(args);
            }
          }
          initOrgValue={String(orgCode)}
          staffCode={staffCode}
          orgLabel=" "
          addAllItem={false}
        />

        <div style={{ marginLeft: '100px' }}>
          <div>
            <BodyText color={utilityColor.error}>端末に店舗情報を登録します。</BodyText>
          </div>
          <div>
            <BodyText color={utilityColor.error}>店舗情報を登録することで、タイムレコーダーが使用できるようになります。</BodyText>
          </div>
          <div>
            <FormField>
              <FlexBox>
                <FlexBoxItem marginRight="2rem">
                  <Checkbox
                    id="stampByBelongOrg"
                    name="stampByBelongOrg"
                    value={stampByBelongOrg ? 'checked' : 'unchecked'}
                    label="所属店舗で打刻する"
                    checked={stampByBelongOrg}
                    onChange={(e) => {
                      setstampByBelongOrg(!stampByBelongOrg);
                      // orgTreesOptions.filter((option: any) => {
                      //   if (orgCode.includes(option.orgCode) && !stampByBelongOrg) {
                      //     setSpecifyCode(option.value);
                      //   }
                      // });
                    }}
                  />
                </FlexBoxItem>
              </FlexBox>
            </FormField>
            <div>
              <BodyText color={utilityColor.error}>チェックをすると、打刻時の出勤店舗はスタッフの所属店舗になります。</BodyText>
            </div>
            <div>
              <BodyText color={utilityColor.error}>チェックを外すと、打刻時の出勤店舗はこの画面の「組織名」で指定した店舗になります。</BodyText>
            </div>
          </div>
          <FormField>
            <FlexBoxItem>
              <Checkbox
                id="checkTimeReCoder"
                name="checkTimeReCoder"
                value={checkTimeReCoder === true ? 'checked' : 'unchecked'}
                label="打刻履歴を非表示にする"
                checked={checkTimeReCoder}
                onChange={() => {
                  setcheckTimeReCoder(!checkTimeReCoder);
                }}
              />
            </FlexBoxItem>

          </FormField>
        </div>
        {
          checkUserRole && checkUserRole.editable === 1 && (
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <FormSubmitArea>
              <FlexBox>
                <FlexBoxItem marginRight="12px">
                  <SecondaryButton text="クリア" onClick={() => setConfirmClearModel(true)} />
                </FlexBoxItem>
                <FlexBoxItem>
                  <PrimaryButton text="登録" onClick={() => setConfirmModel(true)} />
                </FlexBoxItem>
              </FlexBox>
            </FormSubmitArea>
          </div>
        )}

        <ConfirmActionModal
          open={confirmModel}
          closeHandler={() => setConfirmModel(false)}
          onSubmit={handleAdd}
          actionType={ActionType.CREATE}
        />
        <ConfirmModal
          title="確認メッセージ"
          content="端末に登録されている店舗情報をクリアします。タイムレコーダーが使用できなくなりますが、よろしいですか？"
          submitText="OK"
          open={confirmClearModel}
          closeHandler={() => setConfirmClearModel(false)}
          onSubmit={handleClear}
        />
      </FormContents>
    </div>
  );
};

export default MasterShopSpecifyLayout;
