/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { productColor } from 'components/styles';
import { IconType } from 'components/atoms/Icon';
import IconLink from 'components/molecules/IconLink';
import Separater from 'components/atoms/Separator';
import { SidebarItemType } from '.';
import SidebarHierarchyItem from './SidebarHierarchyItem';
import { HierarchySet } from './HierarchyMode';

const styles = {
  close: css({
    padding: '0 10px',
  }),
};

export interface HierarchyItem {
  text: string;
  to: string;
}

interface BaseSidebarItemProps {
  icon: IconType;
  text: string;
}

export interface SidebarLinkItemProps extends BaseSidebarItemProps {
  href: string;
}

export interface SidebarHierarchyItemProps extends BaseSidebarItemProps {
  hierarchyItems: Array<HierarchyItem>;
}

export interface SidebarSeparatorItemProps {
  separate: boolean;
}

const SidebarItem: React.FC<{
  isOpen: boolean;
  item: SidebarItemType;
  setHierarchy: (hierarchySet?: HierarchySet) => void;
}> = ({
  isOpen, item, setHierarchy,
}) => {
  if ('separate' in item) {
    return <Separater margin="8px 12px" />;
  }

  if ('hierarchyItems' in item) {
    return (
      <SidebarHierarchyItem
        isOpen={isOpen}
        icon={item.icon}
        text={item.text}
        hierarchyItems={item.hierarchyItems}
        setHierarchy={setHierarchy}
      />
    );
  }

  return (
    <div css={isOpen || styles.close} style={{padding:'2px 0px'}}>
      <IconLink href={item.href} iconType={item.icon} text={isOpen ? item.text : ''} color={productColor.primary} />
    </div>
  );
};

export default SidebarItem;
