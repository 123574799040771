import ApiClient from 'api/ApiClient';

export const getDaySeparateSalesStoreManagement = async (params: any, appendUrl: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/list/daySeparateSalesStoreManagement/${companyCode}?${appendUrl}`, params);
  return response.data;
};

export const downloadCsv = async (queryString: string, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadFile(`/v1/sales/list/standard/advertising/daily/exportFile/csv/${companyCode}?${queryString}`, {}, fileName);
};

export const downloadExcel = async (queryString: string, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadFile(`/v1/sales/list/standard/advertising/daily/exportFile/excel/${companyCode}?${queryString}`, {}, fileName);
};

