/* eslint-disable max-len */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-northeast-1',
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: 'ap-northeast-1',
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
};
// console.log(`aws_user_pools_id = ${awsmobile.aws_user_pools_id}`);
// console.log(`document.domain = ${document.domain}`);

export default awsmobile;
