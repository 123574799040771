export interface OrganizationCategoryItem {

  organizationCategoryMstDetailId: string;
  detailCode: string;
  detailName: string;
  isAll: boolean;
  isEdit: boolean;
}

export default class OrganizationCategoryItemDomain {
  constructor(private rawData: OrganizationCategoryItem) {
    // do nothing
  }

  static generateInitial(): OrganizationCategoryItemDomain {
    return new OrganizationCategoryItemDomain({
      organizationCategoryMstDetailId: '',
      detailCode: '',
      detailName: '',
      isAll: false,
      isEdit: false,
    });
  }

  getRawData(): OrganizationCategoryItem {
    return this.rawData;
  }

  get organizationCategoryMstDetailId(): string {
    return this.rawData.organizationCategoryMstDetailId;
  }

  set organizationCategoryMstDetailId(organizationCategoryMstDetailId: string) {
    this.rawData.organizationCategoryMstDetailId = organizationCategoryMstDetailId;
  }

  get detailCode(): string {
    return this.rawData.detailCode;
  }

  set detailCode(detailCode: string) {
    this.rawData.detailCode = detailCode;
  }

  get detailName(): string {
    return this.rawData.detailName;
  }

  set detailName(detailName: string) {
    this.rawData.detailName = detailName;
  }

  set isAll(isAll: boolean) {
    this.rawData.isAll = isAll;
  }

  get isEdit(): boolean {
    return this.rawData.isEdit;
  }
}
