import moment from 'moment';
/**
 * API
 */
export interface WorkingDaysDetail {
    workingDays1 : number | '',
    workingDays2: number | '',
    workingDays3 : number | '',
    workingDays4: number | ''
    workingDays5 : number | '',
    workingDays6: number | '',
    workingDays7 : number | '',
    workingDays8: number | '',
    workingDays9 : number | '',
    workingDays10: number | '',
    workingDays11 : number | '',
    workingDays12: number | '',
}

export interface WorkingDays {
  companyCode: string;
  createUser: string;
  updateUser: string;
  workingDaysId: string;
  workingDaysPatternCode: string;
  workingDaysPatternName: string;
  workingDaysDetails: WorkingDaysDetail;
  targetYearStr: string;
  workingDays: Array<number>;
  orgDaysList: Array<{ id: { orgCode: string, }}>;
  inputOrgList: any;
}

export default class WorkingDaysDomain {
  constructor(private rawData: WorkingDays) {
    // do nothing
  }

  static generateInitial(): WorkingDaysDomain {
    return new WorkingDaysDomain({
      companyCode: '',
      createUser: '',
      updateUser: '',
      workingDaysId: '',
      workingDaysPatternCode: '',
      workingDaysPatternName: '',
      workingDaysDetails: {
        workingDays1: '',
        workingDays2: '',
        workingDays3: '',
        workingDays4: '',
        workingDays5: '',
        workingDays6: '',
        workingDays7: '',
        workingDays8: '',
        workingDays9: '',
        workingDays10: '',
        workingDays11: '',
        workingDays12: '',
      },
      targetYearStr: moment().format('YYYY'),
      workingDays: [],
      orgDaysList: [],
      inputOrgList: null,
    });
  }

  getRawData(): WorkingDays {
    return this.rawData;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = String(companyCode);
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set workingDaysId(workingDaysId: string) {
    this.rawData.workingDaysId = String(workingDaysId);
  }

  get workingDaysId(): string {
    return this.rawData.workingDaysId;
  }

  set workingDaysPatternCode(workingDaysPatternCode: string) {
    this.rawData.workingDaysPatternCode = String(workingDaysPatternCode);
  }

  get workingDaysPatternCode(): string {
    return this.rawData.workingDaysPatternCode;
  }

  set workingDaysPatternName(workingDaysPatternName: string) {
    this.rawData.workingDaysPatternName = String(workingDaysPatternName);
  }

  get workingDaysPatternName(): string {
    return this.rawData.workingDaysPatternName;
  }

  get createUser():string {
    return this.rawData.createUser;
  }

  set createUser(createUser:string) {
    this.rawData.createUser = createUser;
  }


  get updateUser():string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser:string) {
    this.rawData.updateUser = updateUser;
  }

  get workingDaysDetails(): WorkingDaysDetail {
    return this.rawData.workingDaysDetails;
  }

  set workingDaysDetails(workingDaysDetails: WorkingDaysDetail) {
    this.rawData.workingDaysDetails = workingDaysDetails;
  }


  get targetYearStr():string {
    return this.rawData.targetYearStr;
  }

  set targetYearStr(targetYearStr:string) {
    this.rawData.targetYearStr = targetYearStr;
  }

  get workingDays(): Array<number> {
    return this.rawData.workingDays;
  }

  set workingDays(workingDays: Array<number>) {
    this.rawData.workingDays = workingDays;
  }

  get orgDaysList(): Array<{ id: { orgCode: string, }}> {
    return this.rawData.orgDaysList;
  }

  set orgDaysList(orgDaysList: Array<{ id: { orgCode: string, }}>) {
    this.rawData.orgDaysList = orgDaysList;
  }

  get inputOrgList(): any {
    return this.rawData.inputOrgList;
  }

  set inputOrgList(inputOrgList: any) {
    this.rawData.inputOrgList = inputOrgList;
  }
}
