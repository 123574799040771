/** @jsx jsx */
import React, { useState, useEffect, RefObject } from 'react';
import { css, jsx } from '@emotion/core';
import { layer } from 'components/styles';

const styles = {
  box: (left: number, top: number) => css({
    position: 'absolute',
    top,
    left,
    whiteSpace: 'nowrap',
    zIndex: 100,
    cursor: 'pointer',
    boxShadow: layer.layer01,
  }),
};

// Forked DropdownBox - add reponsive to position
const DropdownBox: React.FC<
{isVisible: boolean, parentEle: HTMLElement|null, parentRef:RefObject<any>
}> = ({
  children, isVisible, parentEle, parentRef,
}) => {
  const [boxElement, setBoxElement] = useState<HTMLElement|null>(null);
  const [boxLeft, setBoxLeft] = useState<number>(0);
  const [boxTop, setBoxTop] = useState<number>(0);

  useEffect(() => {
    if (!boxElement) {
      return;
    }

    const clientRect = boxElement && boxElement.getBoundingClientRect();
    const parentRect = parentEle?.getBoundingClientRect();
    if (!parentRect) {
      return;
    }
    const extraSpaceForDesktop = window.screen.availWidth > 1200 ? 100 : 50;
    const remainWidth = window.screen.availWidth - parentRect.right - extraSpaceForDesktop;
    const remainHeight = window.screen.availHeight - parentRect.bottom - extraSpaceForDesktop;

    // 画面外に要素が出る場合に位置を調整する
    if (remainWidth < clientRect.width) {
      setBoxLeft(parentRect.left - clientRect.width);
    } else {
      setBoxLeft(parentRect.right);
    }

    if (remainHeight < clientRect.height) {
      setBoxTop(parentRect.bottom - clientRect.height);
    } else {
      setBoxTop(parentRect.top);
    }
  }, [boxElement, parentEle]);
  return (
    <React.Fragment>
      {isVisible && (
      <div css={styles.box(boxLeft, boxTop)} ref={(ref) => setBoxElement(ref)}>
        <div ref={parentRef}>
          {children}
        </div>
      </div>
      )}
    </React.Fragment>
  );
};

export default DropdownBox;
