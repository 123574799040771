import React from 'react';
import YearPicker from 'components/molecules/YearPicker/YearPicker';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import HourInput from 'components/atoms/Form/HourInput';
import Icon from 'components/atoms/Icon';
import productColor, { textColor, grayScale, textFontSize } from 'components/styles';
import { css } from '@emotion/core';
import { useParams } from 'react-router-dom';
import Button, { PrimaryButton } from 'components/atoms/Button';
import { getWorkingDaysPreviousYear } from 'api/workingDay';
import WorkingDaysDomain from 'domain/master/labor/workingDays';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import useToastNotification from 'hooks/useToastNotification';
import { useWorkingDaysDomainAddForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

// create style form
const styles = {
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  input: css({
    flex: 1,
    width: '100px',
    padding: '7px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: 'none',
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px 0 0 4px',
  }),
  addon: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100px',
    padding: '7px',
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
  }),
  cellTable: css({
    width: '100%',
    border: 'none',
    borderSpacing: '0px 1.5px',
    overflow: 'scroll',
  }),
};

const PrescribedDaysCreateForm: React.FC = () => {
  const { workingDaysId } = useParams();
  const { errorNotification } = useToastNotification();
  const {
    formik, confirmModalOpen, setConfirmModalOpen, closeConfirmModal,
    handleGetWorkingDays, history,
  } = useWorkingDaysDomainAddForm(workingDaysId);
  /**
   *
   *
  */
  const handleChangeYear = async (year: any) => {
    // set year
    formik.setFieldValue('targetYearStr', year);

    // update working detail
    if (workingDaysId) {
      await handleGetWorkingDays(year, workingDaysId);
    }
  };

  /**
   *  handle on click button 対象年の前年の所定労働時間を設定
   */
  const handleSetWorkingDays = async (e: any) => {
    //
    const rawData = formik.values.getRawData();
    // eslint-disable-next-line no-shadow
    const { targetYearStr, workingDaysId } = rawData;
    getWorkingDaysPreviousYear(targetYearStr, workingDaysId).then((response: any) => {
      if (response) {
        const { workingDaysDetails } = response;
        if (workingDaysDetails) {
          formik.setFieldValue('workingDaysDetails', workingDaysDetails);
        } else {
          formik.setFieldValue('workingDaysDetails', WorkingDaysDomain.generateInitial().workingDaysDetails);
        }
      }
    }).catch((error : any) => {
      setConfirmModalOpen(false);
      if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any, index: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    });
  };

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <>
      <p>
        所定労働日数を登録します。コードとパターン名を入力し、対象となる年を選択してから、各月毎の所定労働日数を設定してください。
      </p>

      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormField>
            <TextForm
              name="workingDaysPatternCode"
              label="パターンコード"
              value={String(formik.values.workingDaysPatternCode)}
              onChange={formik.handleChange}
              errorMsg={formik.errors.workingDaysPatternCode}
              required={true}
            />
          </FormField>

          <FormField>
            <TextForm
              name="workingDaysPatternName"
              label="パターン名"
              value={String(formik.values.workingDaysPatternName)}
              onChange={formik.handleChange}
              errorMsg={formik.errors.workingDaysPatternName}
              required={true}
            />
          </FormField>

          <FormTitle
            title="各月所定労働日数設定"
          />

          <p>
            対象となる年を選択して、各月ごとの所定労働日数を設定してください。
          </p>

          <FormField>
            <div style={{ paddingRight: '10px' }}>
              対象年
            </div>

            <div style={{ paddingRight: '10px', width: '100px', marginTop: '-5px' }}>
              <YearPicker
                selectedYear={formik.values.targetYearStr}
                onChange={(year: Date) => handleChangeYear(year)}
              />
            </div>
            { workingDaysId
              ? (
                <div onClick={handleSetWorkingDays} style={{ color: productColor.primary, cursor: 'grab' }}>
                  <Icon type="settings" color={textColor.main} />
                  対象年の前年の所定労働日数を設定
                </div>
              )
              : ''}
          </FormField>

          <div>
            <table css={styles.cellTable} style={{ width: '100%' }}>
              <thead>
                <tr style={{
                  borderTop: '1px solid #CEDAEA', borderBottom: '1px solid #CEDAEA', backgroundColor: '#F2F5F9', height: '40px',
                }}
                >
                  <th style={{ fontWeight: 'inherit', textAlign: 'left' }}>月</th>
                  <th style={{ fontWeight: 'inherit', textAlign: 'left' }}>所定労働日数</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> 1月 </td>
                  <td>
                    <HourInput
                      name="workingDaysDetails.workingDays1"
                      value={formik.values.workingDaysDetails.workingDays1 !== '' && formik.values.workingDaysDetails.workingDays1 >= 0 ? formik.values.workingDaysDetails.workingDays1 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                  </td>
                </tr>
                <tr>
                  <td> 2月 </td>
                  <td>
                    <HourInput
                      name="workingDaysDetails.workingDays2"
                      value={formik.values.workingDaysDetails.workingDays2 !== '' && formik.values.workingDaysDetails.workingDays2 >= 0 ? formik.values.workingDaysDetails.workingDays2 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={29}
                    />
                  </td>
                </tr>
                <tr>
                  <td> 3月 </td>
                  <td>
                    <HourInput
                      name="workingDaysDetails.workingDays3"
                      value={formik.values.workingDaysDetails.workingDays3 !== '' && formik.values.workingDaysDetails.workingDays3 >= 0 ? formik.values.workingDaysDetails.workingDays3 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                  </td>
                </tr>
                <tr>
                  <td> 4月 </td>
                  <td>
                    <HourInput
                      name="workingDaysDetails.workingDays4"
                      value={formik.values.workingDaysDetails.workingDays4 !== '' && formik.values.workingDaysDetails.workingDays4 >= 0 ? formik.values.workingDaysDetails.workingDays4 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td> 5月 </td>
                  <td>
                    <HourInput
                      name="workingDaysDetails.workingDays5"
                      value={formik.values.workingDaysDetails.workingDays5 !== '' && formik.values.workingDaysDetails.workingDays5 >= 0 ? formik.values.workingDaysDetails.workingDays5 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                  </td>
                </tr>
                <tr>
                  <td> 6月 </td>
                  <td>
                    <HourInput
                      name="workingDaysDetails.workingDays6"
                      value={formik.values.workingDaysDetails.workingDays6 !== '' && formik.values.workingDaysDetails.workingDays6 >= 0 ? formik.values.workingDaysDetails.workingDays6 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td> 7月 </td>
                  <td>
                    <HourInput
                      name="workingDaysDetails.workingDays7"
                      value={formik.values.workingDaysDetails.workingDays7 !== '' && formik.values.workingDaysDetails.workingDays7 >= 0 ? formik.values.workingDaysDetails.workingDays7 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                  </td>
                </tr>
                <tr>
                  <td> 8月 </td>
                  <td>
                    <HourInput
                      name="workingDaysDetails.workingDays8"
                      value={formik.values.workingDaysDetails.workingDays8 !== '' && formik.values.workingDaysDetails.workingDays8 >= 0 ? formik.values.workingDaysDetails.workingDays8 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                  </td>
                </tr>
                <tr>
                  <td> 9月 </td>
                  <td>
                    <HourInput
                      name="workingDaysDetails.workingDays9"
                      value={formik.values.workingDaysDetails.workingDays9 !== '' && formik.values.workingDaysDetails.workingDays9 >= 0 ? formik.values.workingDaysDetails.workingDays9 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td> 10月 </td>
                  <td>
                    <HourInput
                      name="workingDaysDetails.workingDays10"
                      value={formik.values.workingDaysDetails.workingDays10 !== '' && formik.values.workingDaysDetails.workingDays10 >= 0 ? formik.values.workingDaysDetails.workingDays10 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                  </td>
                </tr>
                <tr>
                  <td> 11月 </td>
                  <td>
                    <HourInput
                      name="workingDaysDetails.workingDays11"
                      value={formik.values.workingDaysDetails.workingDays11 !== '' && formik.values.workingDaysDetails.workingDays11 >= 0 ? formik.values.workingDaysDetails.workingDays11 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td> 12月 </td>
                  <td>
                    <HourInput
                      name="workingDaysDetails.workingDays12"
                      value={formik.values.workingDaysDetails.workingDays12 !== '' && formik.values.workingDaysDetails.workingDays12 >= 0 ? formik.values.workingDaysDetails.workingDays12 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <FormSubmitArea>
            {
              roleScreen && roleScreen.editable === 1 && (
                <div style={{ marginRight: '12px' }}>
                  <Button
                    text={workingDaysId ? '更新' : '登録'}
                    onClick={formik.handleSubmit}
                  />
                </div>
              )
            }
            {' '}
            <PrimaryButton
              text="戻る"
              onClick={() => {
                history.replace('/agreementMaster');
              }}
              ghost={true}
            />
          </FormSubmitArea>

        </div>
      </form>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={workingDaysId ? ActionType.UPDATE : ActionType.CREATE}
      />
    </>
  );
};

export default PrescribedDaysCreateForm;
