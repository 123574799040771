/** @jsx jsx */
import React from 'react';
import { grayScale } from 'components/styles';
import Accordion from 'components/molecules/Accordion';
import DataTable from 'components/organismos/DataTable/DataTable';
import { css, jsx } from '@emotion/core';
import moment from 'moment';
import RejectedHolidayDomain from '../domain/rejectedHolidayDomain';

const HolidayAccordion: React.FC<{ list: RejectedHolidayDomain[] }> = ({ list }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: '否決日時',
        accessor: 'approvalDate',
      },
      {
        Header: '否決者',
        accessor: 'authorizer',
      },
      {
        Header: '組織名',
        accessor: 'orgName',
      },
      {
        Header: '申請者',
        accessor: 'applicant',
      },
      {
        Header: '申請日時',
        accessor: 'applicationDate',
      },
      {
        Header: '対象者',
        accessor: 'targetPerson',
      },
      {
        Header: '対象日付',
        accessor: 'targetDate',
      },
      {
        Header: '申請種別',
        accessor: 'modifiyStatusName',
      },
      {
        Header: '休暇',
        accessor: 'holidayName',
      },
      {
        Header: '休暇単位',
        accessor: 'holidayDigestiveUnitName',
      },
      {
        Header: '時間休の開始時間',
        accessor: 'holidayStartHm',
        Cell: (cell: { row :{ isExpanded: boolean, original: any }}) => (
          <div style={{ textAlign: 'left' }}>
            {
              (() => {
                if (cell.row.original.holidayDigestiveUnit === 2
                  && cell.row.original.holidayStartHm) {
                  if (moment(cell.row.original.holidayStartHm).format('YYYYMMDD')
                      !== moment(cell.row.original.targetDate).format('YYYYMMDD')) {
                    return `${moment(cell.row.original.holidayStartHm).format('HH:mm')}(翌)`;
                  }
                  return moment(cell.row.original.holidayStartHm).format('HH:mm');
                }
                return '';
              })()
            }
          </div>
        ),
      },
      {
        Header: '取得時間',
        accessor: 'holidayTimes',
        Cell: (cell: { row :{ isExpanded: boolean, original: any }}) => (
          <div style={{ textAlign: 'left' }}>
            {cell.row.original.holidayTimes ? `${cell.row.original.holidayTimes}時間` : ''}
          </div>
        ),
      },
      {
        Header: '否決者コメント',
        accessor: 'rejectReason',
      },
    ],
    [],
  );

  return (
    <div
      css={css`
      table th:nth-child(1) {
        width: 140px;
      }
      table th:nth-child(2) {
        width: 140px;
      }
      table th:nth-child(3) {
        width: 140px;
      }
      table th:nth-child(4) {
        width: 140px;
      }
      table th:nth-child(5) {
        width: 140px;
      }
      table th:nth-child(6) {
        width: 140px;
      }
      table th:nth-child(7) {
        width: 80px;
      }
      table th:nth-child(8) {
        width: 80px;
      }
      table th:nth-child(9) {
        width: 120px;
      }
      table th:nth-child(10) {
        width: 80px;
      }
      table th:nth-child(11) {
        width: 140px;
      }
      table th:nth-child(12) {
        width: 80px;
      }
      table th:nth-child(13) {
        width: 200px;
      }
      `}
    >
      <Accordion
        text={`休暇申請 (${list.length}件）`}
        color={grayScale.gray100}
        callBack={() => {}}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <DataTable columns={columns} data={list} isGlobalFilter={true} minWidth="1620px" sortBy={[{id : 'approvalDate', desc: true}]} />
        </div>
      </Accordion>
    </div>
  );
};

export default HolidayAccordion;
