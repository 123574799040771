/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import Select, { components, OptionTypeBase } from 'react-select';
import { ValueType, MenuPlacement } from 'react-select/src/types';

import {
  grayScale, productColor, textFontSize, textColor, utilityColor,
} from 'components/styles';
import FormLabel from 'components/atoms/Form/FormLabel';
import Icon from 'components/atoms/Icon';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';

export interface OptionType extends OptionTypeBase {
  value: string,
  label: string,
}

const styles = {
  container: css({
    padding: '4px 0',
  }),
  noPaddingContainer: css({
    padding: '0px 0px',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
    fontSize: textFontSize.re,
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: '4px',
  }),
};

const selectStyles = {
  menu: (oldStyles: any) => ({
    ...oldStyles,
    zIndex: 999,
  }),
  control: (oldStyles: any) => ({
    ...oldStyles,
    '& > div': {
      paddingRight: 0,
    },
  }),
  dropdownIndicator: (oldStyles: any) => ({
    ...oldStyles,
    cursor: 'pointer',
  }),
  input: (oldStyles: any) => ({
    ...oldStyles,
  }),
  multiValue: (oldStyles: any) => ({
    ...oldStyles,
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0D4796',
    borderRadius: '12px',
  }),
  multiValueLabel: (oldStyles: any) => ({
    ...oldStyles,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    color: productColor.primary,
  }),
  multiValueRemove: (oldStyles: any) => ({
    ...oldStyles,
    lineHeight: '21px',
    paddingRight: '6px',
    ':hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  }),
  placeholder: (oldStyles: any) => ({
    ...oldStyles,
    fontSize: '14px',
  }),
};

/**
 * 選択式フォーム
 * TODO: セレクトボックスのスタイルがデフォルトのままなので、ちゃんとカスタマイズする
 */
const VerticalLabelSelectForm: React.FC<{
  label?: string;
  name: string;
  value: string;
  options: Array<OptionType>;
  setValue: (val: string) => void;
  description?: string;
  note?: string;
  isMulti?: boolean;
  errorMsg?: string;
  required?: boolean;
  disable?: boolean;
  placeholder?: string;
  menuPlacement?: MenuPlacement;
  customizeSelectPropStyle?: any;
  createMenuPortal?: boolean;
  removePaddingContainer?: boolean;
}> = ({
  label, name, value, options, setValue, description, isMulti, note, errorMsg, required, disable, placeholder, menuPlacement = 'auto', customizeSelectPropStyle = {}, createMenuPortal = false, removePaddingContainer = false,
}) => {
    const getValue = (): ValueType<OptionType> => {
      if (options) {
        return options.find((option) => option.value === value);
      }
      return '' as any;
    };

    const onChange = (option: any) => {
      if (!option) {
        setValue('');
      } else if (Array.isArray(option) && option.length) {
        const array = option.map((opt) => (opt as OptionType).value);
        setValue(
          `${array.toString()},`,
        );
      } else {
        setValue(
          (option as OptionType).value,
        );
      }
    };

    const DropdownIndicator = (props: any) => (
      <components.DropdownIndicator {...props}>
        <Icon type="dropdown" color={grayScale.gray100} />
      </components.DropdownIndicator>
    );

    const MultiValueRemove = (props: any) => (
      <components.MultiValueRemove {...props}>
        <Icon type="close" color={grayScale.gray100} />
      </components.MultiValueRemove>
    );
    const customizedStyle = React.useMemo(() => ({ ...selectStyles, ...customizeSelectPropStyle }), [customizeSelectPropStyle]);
    return (
      <div css={removePaddingContainer ? styles.noPaddingContainer : styles.container}>
        <VerticalLabelFormLayout
          label={(
            (label
              && (
                <FormLabel
                  label={label}
                  required={required}
                />
              )
            )
          )}
          input={(
            <div css={styles.select}>
              <Select
                styles={customizedStyle}
                name={name}
                value={getValue() || null}
                onChange={onChange}
                options={options}
                placeholder={placeholder || '選択してください'}
                isMulti={isMulti}
                isClearable={false}
                components={{ DropdownIndicator, IndicatorSeparator: () => null, MultiValueRemove }}
                isDisabled={disable}
                menuPlacement={menuPlacement}
                menuPortalTarget={createMenuPortal ? document.body : null}
              />
            </div>
          )}
          errorMsg={errorMsg}
        />
        {note && <span css={css(styles.noteText, { color: utilityColor.error })} dangerouslySetInnerHTML={{ __html: note }} />}
      </div>
    );
  };

export default VerticalLabelSelectForm;
