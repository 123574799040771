/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { utilityColor, textColor } from 'components/styles';

const styles = {
  wrapper: css({
    position: 'relative',
  }),
  badge: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: utilityColor.error,
    borderRadius: '9999px',
    height: '16px',
    width: '16px',
    position: 'absolute',
    top: '0',
    right: '0',
    color: textColor.inversed,
    fontSize: '12px',
  }),
};

const Badge: React.FC<{
  showBadge: boolean;
  badgeText?: string;
}> = ({ children, showBadge, badgeText }) => (
  <div css={styles.wrapper}>
    {showBadge && (
      <div css={styles.badge}>
        {badgeText}
      </div>
    )}
    {children}
  </div>
);

export default Badge;
