import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import StaffListTable from 'components/organismos/master/general/staff/StaffListTable';
// import StaffListTable from 'components/organismos/master/general/shopStaff/StaffListTable';
import BlockUI from 'components/molecules/BlockUi';
import { useShopStaffList } from './hooks';

const ShopStaffsPage: React.FC = () => {
  const {
    staffList,
    fetchData,
    executiveId,
    setExecutiveId,
    employmentId,
    setEmploymentId,
    orgCode,
    setOrgCode,
    stateStatus,
    setStateStatus,
    blocking,
    categoryArr,
    setCategoryAr,
    menuType,
    orgStatus,
    setOrgStatus
  } = useShopStaffList();

  return (
    <SidebarTemplate
      pageTitle="スタッフマスタ"
    >
      <BlockUI blocking={blocking}>
        <StaffListTable
          staffList={staffList}
          fetchData={fetchData}
          executiveId={executiveId}
          setExecutiveId={setExecutiveId}
          employmentId={employmentId}
          setEmploymentId={setEmploymentId}
          orgCode={orgCode}
          setOrgCode={setOrgCode}
          stateStatus={stateStatus}
          setStateStatus={setStateStatus}
          categoryArr={categoryArr}
          setCategoryAr={setCategoryAr}
          menuType={'1'}
          orgStatus={orgStatus}
          setOrgStatus={setOrgStatus}
        />
      </BlockUI>
    </SidebarTemplate>
  );
};

export default ShopStaffsPage;
