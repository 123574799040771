import React from 'react';

import { useParams } from 'react-router-dom';
import ShiftPatternInputAddForm from './ShiftPatternInputAddForm';

/**
 * shift pattern registration form
 *
 */
const ShiftPatternAddForm: React.FC = () => {
  const { shiftPatternId, typeButton } = useParams();
  return (
    <ShiftPatternInputAddForm shiftPatternId={shiftPatternId || ''} typeButton={typeButton || ''} />
  );
};

export default ShiftPatternAddForm;
