/**
 * APIのデータ型
 */
export interface SaleBudgetMonth {
  advertisingSaleData: any,
  salesPlanDailyDateData: any,
  saleBudgetListData: any,
  saleBudgetAdvertisingMonthData: any,
  advertisingExpensesData: any,
  saleBudgetMonthData: any,
  busyBias: any,
  normalBias: any,
  quietBias: any,
  holidayBias: any,
  salesPlanDailyData: any,
  expensesDailyData: any,
  expensesMonthlyData: any,
}

export default class SalesBudgetMonthDomain {
  constructor(private rawData: SaleBudgetMonth) {
    // do nothing
  }

  static generateInitial(): SalesBudgetMonthDomain {
    return new SalesBudgetMonthDomain({
      advertisingSaleData: [],
      salesPlanDailyDateData: [],
      saleBudgetListData: [],
      saleBudgetAdvertisingMonthData: [],
      advertisingExpensesData: [],
      saleBudgetMonthData: [],
      busyBias: 0,
      normalBias: 0,
      quietBias: 0,
      holidayBias: 0,
      salesPlanDailyData: [],
      expensesDailyData: [],
      expensesMonthlyData: [],
    });
  }

  getRawData(): SaleBudgetMonth {
    return this.rawData;
  }

  get salesPlanDailyData(): any {
    return this.rawData.salesPlanDailyData;
  }

  set salesPlanDailyData(salesPlanDailyData: any) {
    this.rawData.salesPlanDailyData = salesPlanDailyData;
  }

  get expensesDailyData(): any {
    return this.rawData.expensesDailyData;
  }

  set expensesDailyData(expensesDailyData: any) {
    this.rawData.expensesDailyData = expensesDailyData;
  }

  get expensesMonthlyData(): any {
    return this.rawData.expensesMonthlyData;
  }

  set expensesMonthlyData(expensesMonthlyData: any) {
    this.rawData.expensesMonthlyData = expensesMonthlyData;
  }

  get busyBias(): any {
    return this.rawData.busyBias;
  }

  set busyBias(busyBias: any) {
    this.rawData.busyBias = busyBias;
  }

  get normalBias(): any {
    return this.rawData.normalBias;
  }

  set normalBias(normalBias: any) {
    this.rawData.normalBias = normalBias;
  }

  get quietBias(): any {
    return this.rawData.quietBias;
  }

  set quietBias(quietBias: any) {
    this.rawData.quietBias = quietBias;
  }

  get holidayBias(): any {
    return this.rawData.holidayBias;
  }

  set holidayBias(holidayBias: any) {
    this.rawData.holidayBias = holidayBias;
  }

  get advertisingSaleData(): any {
    return this.rawData.advertisingSaleData;
  }

  set advertisingSaleData(advertisingSaleData: any) {
    this.rawData.advertisingSaleData = advertisingSaleData;
  }

  get salesPlanDailyDateData(): any {
    return this.rawData.salesPlanDailyDateData;
  }

  set salesPlanDailyDateData(salesPlanDailyDateData: any) {
    this.rawData.salesPlanDailyDateData = salesPlanDailyDateData;
  }

  get saleBudgetListData(): any {
    return this.rawData.saleBudgetListData;
  }

  set saleBudgetListData(saleBudgetListData: any) {
    this.rawData.saleBudgetListData = saleBudgetListData;
  }

  get saleBudgetAdvertisingMonthData(): any {
    return this.rawData.saleBudgetAdvertisingMonthData;
  }

  set saleBudgetAdvertisingMonthData(saleBudgetAdvertisingMonthData: any) {
    this.rawData.saleBudgetAdvertisingMonthData = saleBudgetAdvertisingMonthData;
  }

  get advertisingExpensesData(): any {
    return this.rawData.advertisingExpensesData;
  }

  set advertisingExpensesData(advertisingExpensesData: any) {
    this.rawData.advertisingExpensesData = advertisingExpensesData;
  }

  get saleBudgetMonthData(): any {
    return this.rawData.saleBudgetMonthData;
  }

  set saleBudgetMonthData(saleBudgetMonthData: any) {
    this.rawData.saleBudgetMonthData = saleBudgetMonthData;
  }
}
