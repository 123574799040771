import React from 'react';

const MonthDayTable: React.FC<{
  subTotalDay:any,
  holidaySubtotalItem: any,
  countHolidayColspan: any
}> = ({
  subTotalDay,
  holidaySubtotalItem,
  countHolidayColspan,
}) => (
  <div className="table-responsive">
    <table id="monthDayList" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%' }}>
      <thead>
        <tr role="row">
          {
          subTotalDay.distinctionHoliday && <th style={{ width: '9%' }} colSpan={3}>出勤日数</th>
        }
          {
          !subTotalDay.distinctionHoliday && <th style={{ width: '9%' }} colSpan={2}>出勤日数</th>
        }
          <th style={{ width: '9%' }} colSpan={countHolidayColspan(subTotalDay.distinctionHoliday, holidaySubtotalItem)}>休暇日数</th>
        </tr>
        <tr role="row">
          <th style={{ width: '9%' }}>平日</th>
          {
          subTotalDay.distinctionHoliday && <th style={{ width: '9%' }}>法定休日</th>
        }
          {
          subTotalDay.distinctionHoliday && <th style={{ width: '9%' }}>所定休日</th>
        }
          {
          !subTotalDay.distinctionHoliday && <th style={{ width: '9%' }}>休日</th>
        }
          {
          holidaySubtotalItem && holidaySubtotalItem.map((item: any, index: any) => (
            <th key={String(index)} style={{ width: '9%' }}>
              {item.holidayName}
              {subTotalDay.distinctionHoliday && item.holidayType === 0 && item.isLegal ? '（法定）' : ''}
              {subTotalDay.distinctionHoliday && item.holidayType === 0 && !item.isLegal ? '（所定）' : ''}
            </th>
          ))
        }
          <th style={{ width: '9%' }}>遅刻</th>
          <th style={{ width: '9%' }}>早退</th>
        </tr>
      </thead>
      <tbody>
        {
        subTotalDay != null && (
          <tr role="row">
            <td>{subTotalDay.normalWork}</td>
            {
              subTotalDay.distinctionHoliday && <td>{subTotalDay.legalHolidayWork}</td>
            }
            {
              subTotalDay.distinctionHoliday && <td>{subTotalDay.nonLegalHolidayWork}</td>
            }
            {
              !subTotalDay.distinctionHoliday && <td>{subTotalDay.holidayWork}</td>
            }

            {
              subTotalDay.attendHolidaySubtotals && subTotalDay.attendHolidaySubtotals.map((holidaySubtotalDays: any, index: any) => (
                <td key={String(index)}>
                  {holidaySubtotalDays.resultHoliday}
                </td>
              ))
            }
            <td>{subTotalDay.late}</td>
            <td>{subTotalDay.earlier}</td>
          </tr>
        )
      }
      </tbody>
    </table>
  </div>
);
export default MonthDayTable;
