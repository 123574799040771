import React from 'react'
import SidebarTemplate from 'components/templates/SidebarTemplate';
import ApprovalHolidayworkApplication from 'components/organismos/master/attend/ApprovalApplication/ApprovalHolidayworkApplication';

const ApprovalHolidayworkApplicationPage = () => {
  return(
    <SidebarTemplate pageTitle="休日出勤申請 承認・否決">
      <ApprovalHolidayworkApplication/>
    </SidebarTemplate>
  )
}

export default ApprovalHolidayworkApplicationPage