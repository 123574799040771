import React, { Dispatch } from 'react';
import { Link } from 'react-router-dom';
import FormField from 'components/atoms/Form/FormField';
import FormLabel from 'components/atoms/Form/FormLabel';
import TextForm from 'components/molecules/TextForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import DatePickerForm from 'components/molecules/DatePickerForm';
import FlexBox from 'components/atoms/FlexBox';
import FormContents from 'components/atoms/Form/FormContents';
import FormTitle from 'components/atoms/Form/FormTitle';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
// import SelectForm from 'components/molecules/SelectForm';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import StaffDomain, { StaffBusiness } from 'domain/staff';
import { TablItem } from 'components/pages/master/general/staff/StaffAddPage/StaffAddPage';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import BodyText from 'components/atoms/BodyText';
import { css } from '@emotion/core';
import { useStaffAddForm } from './hooks';
import { InfoMessage } from 'utility/messages';

type SelectType = 'staff'|'affiliation'|'permission'|'employment'|'laborCost'|'workPattern';
const SelectType: {[key in SelectType]: SelectType} = {
  staff: 'staff',
  permission: 'permission',
  affiliation: 'affiliation',
  employment: 'employment',
  laborCost: 'laborCost',
  workPattern: 'workPattern',
};

const StaffInfoForm: React.FC<{
  initialStaffDomain: StaffDomain;
  isEditMode?: boolean;
  tabItems: TablItem[];
  setInitialStaffDomain: Dispatch<React.SetStateAction<StaffDomain>>;
  setSelectType: Dispatch<React.SetStateAction<SelectType>>;
  setTabItems: Dispatch<React.SetStateAction<TablItem[]>>;
  setOrgTargetDate: React.Dispatch<React.SetStateAction<Date>>;
  setOrgApplyDate: React.Dispatch<React.SetStateAction<Date>>;
  setEmpTargetDate: React.Dispatch<React.SetStateAction<Date>>;
  trialPeriodOptions: Array<any>;
  businessOptions: Array<any>;
  hqRoleOptions: Array<any>;
  shopRoleOptions: Array<any>;
  personalRoleOptions: Array<any>;
  roleOptions: Array<any>;
  setIsLoading: Dispatch<React.SetStateAction<boolean>>;
  menuType: string;
  roleScreen?: any;
  isEditingLoginUser: boolean;
}> = ({
  initialStaffDomain, isEditMode = false, tabItems,
  setInitialStaffDomain, setSelectType, setTabItems,
  setOrgTargetDate,
  setOrgApplyDate,
  setEmpTargetDate,
  trialPeriodOptions,
  businessOptions,
  hqRoleOptions,
  shopRoleOptions,
  personalRoleOptions,
  roleOptions,
  setIsLoading,
  menuType,
  roleScreen,
  isEditingLoginUser,
}) => {
  const {
    formik,
    confirmModalOpen, closeConfirmModal,
    confirmDeleteShiftModalOpen, closeConfirmDeleteShiftModal,
  } = useStaffAddForm(initialStaffDomain, isEditMode, setIsLoading, menuType, isEditingLoginUser);
  return (

    <>
      <form onSubmit={formik.handleSubmit}>
        <FormContents>
          <FormTitle
            title="基本情報"
          />
          <FormField>
            <TextForm
              name="staffName"
              label="姓・名"
              placeHolder="姓・名"
              value={formik.values.staffName}
              onChange={formik.handleChange}
              required={true}
              errorMsg={formik.errors.staffName}
            />
          </FormField>

          <FormField>
            <TextForm
              name="staffNameKana"
              label="せい・めい"
              placeHolder="せい・めい"
              value={formik.values.staffNameKana || ''}
              onChange={formik.handleChange}
            />
          </FormField>
          {menuType !== '1' && (
            <FormField>
              <TextForm
                name="staffCode"
                label="スタッフコード"
                placeHolder="半角英数字10文字まで"
                value={formik.values.staffCode}
                onChange={formik.handleChange}
                required={true}
                errorMsg={formik.errors.staffCode}
                maxLength={10}
              />
            </FormField>
          )}
          {menuType === '1' && (
          <FormField>
            <FlexBox>
              <FormLabel
                label="スタッフコード"
              />
              <BodyText customStyle={css({
                marginLeft: '20px',
              })}
              >
                {isEditMode ? formik.values.staffCode : '登録時に自動で採番します'}
              </BodyText>
            </FlexBox>
          </FormField>
          )}
          <FormField>
            <FlexBox>

              <FormLabel
                label="状態"
              />
              {isEditMode && (
                <BodyText customStyle={css({
                  marginLeft: '20px',
                })}
                >
                  {formik.values.retirementDate ? '退職' : '在職'}
                </BodyText>
              )}
            </FlexBox>

          </FormField>
          <FormField>
            <div style={{ width: '100%' }}>
              <FlexBox>
                <FlexBoxItem basis="30%">
                  <DatePickerForm
                    dateFormat="yyyy/MM/dd"
                    label="入社日"
                    date={formik.values.hireDate}
                    changeDate={(date: Date) => {
                      if (date === null) {
                        formik.setFieldValue('hireDate', undefined);
                      } else {
                        if (!isEditMode) {
                          formik.values.selectSalary.applyStartDate = date;
                          setOrgTargetDate(date);
                          setOrgApplyDate(date);
                          setEmpTargetDate(date);
                        }
                        formik.setFieldValue('hireDate', date);
                      }
                    }}
                    required={true}
                    errorMsg={formik.errors.hireDate}
                  />
                </FlexBoxItem>
                <FlexBoxItem basis="30%" marginLeft="20px">
                  <VerticalLabelSelectForm
                    label="試用期間"
                    name="trialTerm"
                    value={String(formik.values.trialTerm)}
                    setValue={(val : string) => { formik.setFieldValue('trialTerm', Number(val)); }}
                    options={trialPeriodOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
          </FormField>
          <FormField>
            <FlexBoxItem basis="30%">
              <DatePickerForm
                dateFormat="yyyy/MM/dd"
                label="退職日"
                date={formik.values.retirementDate}
                changeDate={(date: any) => {
                  formik.setFieldValue('retirementDate', date);
                }}
                noDeleteDate={false}
              />
            </FlexBoxItem>
          </FormField>
          <FormField>
            <TextForm
              label="メールアドレス"
              name="mail"
              value={formik.values.mail || ''}
              onChange={formik.handleChange}
              placeHolder="半角英数字"
              toolTip="サインアップにはメールアドレスが必要になります。</br>
              （メールアドレスを削除するとログインできなくなります。</br>
              メールアドレス再設定後は、再度、サインアップから行うことになります）"
            />
          </FormField>

          <FormField>
            <TextForm
              label="郵便番号"
              name="postalCode"
              value={formik.values.postalCode || ''}
              onChange={formik.handleChange}
              placeHolder="半角数字・ハイフン"
            />
          </FormField>

          <FormField>
            <TextForm
              label="住所１"
              name="address1"
              value={formik.values.address1 || ''}
              onChange={formik.handleChange}
              placeHolder=""
            />
          </FormField>

          <FormField>
            <TextForm
              label="住所２"
              name="address2"
              value={formik.values.address2 || ''}
              onChange={formik.handleChange}
              placeHolder=""
            />
          </FormField>

          <FormField>
            <TextForm
              label="電話番号"
              name="phoneNumber"
              value={formik.values.phoneNumber || ''}
              onChange={formik.handleChange}
              placeHolder="半角数字・ハイフン"
            />
          </FormField>

          {/* <FormField displayBlock={true}>
            <VerticalLabelSelectForm
              label="権限"
              name="role"
              value={formik.values.role}
              setValue={(val: string) => { formik.setFieldValue('role', val); }}
              options={roleOptions}
            />
          </FormField> */}

          {/* <>
            <FormLabel label="権限" />
            <FlexBox direction="row" alignItems="flex-start">
              <FlexBoxItem basis="30%" marginLeft="30px">
                <SelectForm
                  label="従業員サイト"
                  name="persionalRole"
                  value={formik.values.personalRole}
                  setValue={(val: string) => { formik.setFieldValue('personalRole', val); }}
                  options={personalRoleOptions}
                />
              </FlexBoxItem>

              <FlexBoxItem basis="30%" marginLeft="30px">
                <SelectForm
                  label="店舗サイト"
                  name="shopRole"
                  value={formik.values.shopRole}
                  setValue={(val: string) => { formik.setFieldValue('shopRole', val); }}
                  options={shopRoleOptions}
                />
              </FlexBoxItem>

              <FlexBoxItem basis="30%" marginLeft="30px">
                <SelectForm
                  label="本部サイト"
                  name="hqRole"
                  value={formik.values.hqRole}
                  setValue={(val: string) => { formik.setFieldValue('hqRole', val); }}
                  options={hqRoleOptions}
                />
              </FlexBoxItem>
            </FlexBox>
          </> */}
        </FormContents>

        <FormContents>
          <FormTitle
            title="労務管理情報"
          />
          <FormField>
            <RadioSelectForm
              label="性別"
              items={[
                {
                  label: '男性',
                  value: '1',
                },
                {
                  label: '女性',
                  value: '2',
                },
              ]}
              name="gender"
              value={String(formik.values.gender)}
              setValue={(e) => { formik.setFieldValue('gender', Number(e.target.value)); }}
            />
          </FormField>

          <FormField>
            <FlexBoxItem basis="30%">
              <DatePickerForm
                dateFormat="yyyy/MM/dd"
                label="生年月日"
                date={formik.values.birthDate}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    formik.setFieldValue('birthDate', date);
                  }
                }}
                noDeleteDate={false}
              />
            </FlexBoxItem>
          </FormField>

          <FormField>
            <RadioSelectForm
              label="国籍"
              items={[
                {
                  label: '日本',
                  value: '1',
                },
                {
                  label: '日本以外',
                  value: '2',
                },
              ]}
              name="nationality"
              value={String(formik.values.nationality)}
              setValue={(e) => {
                formik.setFieldValue('nationality', Number(e.target.value));
              }}
            />
          </FormField>

          {formik.values.nationality === 2 && (
          <FormContents>
            <FormField>
              <TextForm
                label="パスポート番号"
                name="passportNumber"
                value={formik.values.passportNumber || ''}
                onChange={formik.handleChange}
                placeHolder=""
              />
            </FormField>
            <FormField>
              <FlexBoxItem basis="30%">
                <DatePickerForm
                  dateFormat="yyyy/MM/dd"
                  label="パスポート有効期限"
                  date={formik.values.passportExpirationDate}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      formik.setFieldValue('passportExpirationDate', date);
                    }
                  }}
                  noDeleteDate={false}
                />
              </FlexBoxItem>
            </FormField>
            <FormField>
              <TextForm
                label="在留カード番号"
                name="residenceCardNumber"
                value={formik.values.residenceCardNumber || ''}
                onChange={formik.handleChange}
                placeHolder=""
              />
            </FormField>
            <FormField>
              <FlexBoxItem basis="30%">
                <DatePickerForm
                  dateFormat="yyyy/MM/dd"
                  label="在留カード・証明書有効期限"
                  date={formik.values.alienRegistrationCardExpirationDate}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      formik.setFieldValue('alienRegistrationCardExpirationDate', date);
                    }
                  }}
                  noDeleteDate={false}
                />
              </FlexBoxItem>
            </FormField>
            <FormField>
              <FlexBoxItem basis="30%">
                <DatePickerForm
                  dateFormat="yyyy/MM/dd"
                  label="登録証有効期限"
                  date={formik.values.residenceCardExpirationDate}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      formik.setFieldValue('residenceCardExpirationDate', date);
                    }
                  }}
                  noDeleteDate={false}
                />
              </FlexBoxItem>
            </FormField>
            <FormField>
              <RadioSelectForm
                label="労働時間上限チェック(週28時間)"
                items={[
                  {
                    label: 'しない',
                    value: '1',
                  },
                  {
                    label: 'する',
                    value: '2',
                  },
                ]}
                name="statusResidence"
                value={String(formik.values.statusResidence)}
                setValue={(e) => {
                  formik.setFieldValue('statusResidence', Number(e.target.value));
                }}
              />
            </FormField>
          </FormContents>
          )}
        </FormContents>

        <FormContents>
          <FormTitle
            title="打刻情報"
          />
          <FormField>
            <TextForm
              label="打刻ID"
              name="barcodeId"
              value={formik.values.barcodeId || ''}
              onChange={formik.handleChange}
            />
          </FormField>
        </FormContents>

        <FormContents>
          <FormTitle
            title="業務情報"
          />
          <FormField displayBlock={true}>
            <VerticalLabelMutilSelectForm
              label="就業可能業務"
              name="staffBusinessList"
              values={formik.values.staffBusinessList?.map(
                (staffBusiness: StaffBusiness) => businessOptions.find(
                  (item) => staffBusiness.business_id === item.value,
                ),
              ) ?? []}
              setValue={(val: any) => {
                if (val !== '') {
                  formik.setFieldValue('staffBusinessList', val.slice(0, -1).split(',').map(
                    (id: string) => ({ business_id: id }),
                  ));
                } else {
                  formik.setFieldValue('staffBusinessList', []);
                }
              }}
              options={businessOptions}
              note="就業可能な業務が使用できる雇用形態の設定が必要です。"
              isMulti={true}
            />
          </FormField>

        </FormContents>
        <FormSubmitArea>
          {
            roleScreen && roleScreen.editable === 1 && (
              <div style={{ marginRight: '12px' }}>
                <Button
                  text={isEditMode ? 'スタッフ情報の更新' : '所属情報の入力へ'}
                  onClick={() => {
                    if (isEditMode) {
                      formik.handleSubmit();
                    } else if ((menuType === '0' && formik.values.staffCode && formik.values.staffName && formik.values.hireDate)
                              || (menuType === '1' && formik.values.staffName && formik.values.hireDate)) {
                      formik.values.orgApplyDate = formik.values.hireDate;
                      formik.values.empApplyDate = formik.values.hireDate;
                      formik.values.selectSalary.applyStartDate = formik.values.hireDate;

                      if (tabItems.length <= 1) {
                        setInitialStaffDomain(formik.values);
                        setTabItems([
                          {
                            value: SelectType.staff,
                            display: 'スタッフ情報',
                          },
                          {
                            value: SelectType.affiliation,
                            display: '所属設定',
                          }]);
                      }
                      setSelectType(SelectType.affiliation);
                    } else {
                      formik.handleSubmit();
                    }
                  }}
                />
              </div>
            )
          }

          {menuType !== '1' && (
            <Link to="/staffs">
              <Button
                text="スタッフ一覧に戻る"
                ghost={true}
              />
            </Link>
          )}
          {menuType === '1' && (
            <Link to="/staffs/employee">
              <Button
                text="スタッフ一覧に戻る"
                ghost={true}
              />
            </Link>
          )}
        </FormSubmitArea>
      </form>

      {/* 登録内容確認モーダル */}
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
        message={isEditMode ? InfoMessage.StaffMaster.UPDATE_CONFIRM_MESSAGE_STAFF_INFO : ''}
      />

      {/* シフト自動削除確認モーダル */}
      <ConfirmActionModal
        open={confirmDeleteShiftModalOpen}
        closeHandler={closeConfirmDeleteShiftModal}
        onSubmit={formik.handleSubmit}
        actionType={ActionType.UPDATE}
        message={InfoMessage.StaffMaster.UPDATE_DELETE_SHIFT_CONFIRM_MESSAGE_STAFF_INFO}
      />
    </>
  );
};

export default StaffInfoForm;
