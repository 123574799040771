/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Container, Row, Col } from 'react-grid-system';

import productColor from 'components/styles';
import BodyText from 'components/atoms/BodyText';
import TextInput from 'components/atoms/Form/TextInput';

const styles = {
  wrapper: css({
    width: '100%',
  }),
  input: css({
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    textAlign: 'right'
  }),
  addon: css({
    width: '30px',
    height: '100%',
    textAlign: 'center',
    lineHeight: '36px',
    backgroundColor: productColor.primaryM95,
  }),
};

const YenInputBox: React.FC<{
  name: string;
  label?: string;
  value: string;
  disable?: boolean,
  maxLength?: number
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  name, label, value, onChange, disable = false, maxLength
}) => (
  <Container css={styles.wrapper} fluid={true} style={{ marginLeft: '0' }}>
    <Row>
      <Col>
        <TextInput
          name={name}
          label={label}
          value={value}
          onChange={onChange}
          customStyle={styles.input}
          disable={disable}
          maxLength={maxLength}
        />
      </Col>
      <Col style={{ flex: 0 }}>
        <div css={styles.addon}>
          <BodyText color={productColor.primary}>円</BodyText>
        </div>
      </Col>
    </Row>
  </Container>
);

export default YenInputBox;
