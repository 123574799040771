import React from 'react';
import AttendResultForm from 'components/organismos/master/attend/AttendResultForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const AttendResultList = () => (
  <SidebarTemplate pageTitle="勤務データ確認">
    <AttendResultForm />
  </SidebarTemplate>
);

export default AttendResultList;
