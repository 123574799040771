/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
import {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useFormik } from 'formik';
import TimeOutputLayoutDomain from 'domain/master/attend/timeOutputLayout';
import { createOrUpdateTimeOutputLayout, getOutputLayoutById, getOutputLayoutCreate } from 'api/timeOutputLayout';
import { useHistory } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';

/**
 *
 *
 */
interface ItemType {
  id: number;
  outputItemOrder: number;
  output: boolean;
  outputItemId: string;
  outputItemName: string;
  outputItemContent: string;
  isBasicSetting: boolean;
  layoutDetailId: string;
  layoutId: string;
  createUser: string;
  updateUser: string;

}

// declare validation form Working Hours Master
type TimeOutputLayoutDomainKey = keyof Pick<TimeOutputLayoutDomain, 'layoutCode'|'layoutName'>;
//
export const useTimeOutputLayoutDomainAddForm = (layoutId: string) => {
  // history
  const history = useHistory();

  const { successNotification, errorNotification } = useToastNotification();

  //
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  // modal confirm
  const [toastModalOpen, setToastModalOpen] = useState(false);


  // value option
  const [detailList, setDetailList] = useState([]);

  // handle submit form
  const onSubmit = async (values: TimeOutputLayoutDomain) => {
    if (values.layoutCode === null) {
      errorNotification('レイアウトコードを入力してください。');
      return;
    }
    if (values.layoutCode.length > 10) {
      errorNotification('レイアウトコードは10文字以内で入力してください。');
      return;
    }
    if (values.layoutName === null) {
      errorNotification('レイアウト名を入力してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    // TODO companyCodeの取得処理が必要
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginUser = sessionStorage.getItem('loginUser.staffName') || '';
    let convertData = values.getRawData();

    if ((convertData as any).layoutCode.trim() === '' || (convertData as any).layoutName.trim() === '') {
      setConfirmModalOpen(false);
      errorNotification('コードに半角、全角スペースは入力できません。');
      return;
    }

    //
    delete (convertData as any).createDate;
    delete (convertData as any).updateDate;
    delete (convertData as any).companyCode;
    delete (convertData as any).detailList;

    //
    convertData.createUser = loginUser;
    convertData.updateUser = loginUser;

    //
    (convertData as any).layoutCode = (convertData as any).layoutCode.trim();
    (convertData as any).layoutName = (convertData as any).layoutName.trim();

    // set
    convertData.processType = 0;
    if (layoutId && layoutId !== undefined) {
      convertData.processType = 1;
    }

    //
    let checkFlg = false;
    let checkFlgItemName = false;
    let isCheckSpace = false;
    detailList.forEach((item: any, index: number) => {
      const tmpDetail = {
        layoutId: item.layoutId,
        outputItemId: item.outputItemId,
        layoutDetailId: item.layoutDetailId,
        isBasicSetting: item.isBasicSetting,
        outputItemName: item.outputItemName,
        outputItemContent: item.outputItemContent,
        createUser: loginUser,
        updateUser: loginUser,
      };


      if ((item as any).output == true) {
        //
        checkFlg = true;
        if ((item as any).outputItemName.length && (item as any).outputItemName.trim() === '') {
          isCheckSpace = true;
        }
        if ((item as any).outputItemName == null || (item as any).outputItemName.trim() === '') {
          checkFlgItemName = true;
        }

        (tmpDetail as any).output = 'on';
        (tmpDetail as any).outputItemName = (tmpDetail as any).outputItemName.trim();
      }

      //
      if (!layoutId) {
        (tmpDetail as any).layoutId = '';
      }
      // set order
      (tmpDetail as any).outputItemOrder = index;

      convertData = {
        ...convertData,
        [`detailList[${index}]`]: {
          ...tmpDetail,
        },

      };
    });

    if (isCheckSpace) {
      setConfirmModalOpen(false);
      errorNotification('コードに半角、全角スペースは入力できません。');
      return;
    }

    if (!checkFlg) {
      setConfirmModalOpen(false);
      errorNotification('出力項目を選択してください。');
      return;
    }

    if (checkFlgItemName) {
      setConfirmModalOpen(false);
      errorNotification('出力項目名を入力してください。');
      return;
    }

    // check process
    try {
      const response = await createOrUpdateTimeOutputLayout(companyCode, convertData);
      if (!response.errors) {
        successNotification(layoutId && layoutId !== undefined ? '更新しました。' : '登録しました。');
        history.push('/masterTimeOutputLayout');
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.message) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any, index: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  /**
   *
   *
   */
  const formik = useFormik({
    initialValues: TimeOutputLayoutDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  /**
   *
   *
   */
  useEffect(() => {
    getOutputLayouts(layoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutId]);

  /**
   *  handle
   */
  const getOutputLayouts = async (layoutId: any) => {
    if (layoutId && layoutId !== undefined) {
      getOutputLayoutById(layoutId).then((response: any) => {
        if (response && response.companyCode) {
          formik.setValues(new TimeOutputLayoutDomain(response));
          if (response.detailList) {
            setDetailList(response.detailList.map((item: any) => ({
              id: 1,
              isBasicSetting: item.basicSetting,
              ...item,
            })));
          }
          return true;
        }
        return false;
      });
    } else {
      getOutputLayoutCreate().then((response: any) => {
        if (response.detailList) {
          setDetailList(response.detailList.map((item: any) => ({
            id: 1,
            isBasicSetting: item.basicSetting,
            ...item,
          })));
        }
        return true;
      });
    }
  };

  /**
   *
   *
   */
  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  /**
   *
   *
   */
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  //
  const confirmModalItems = useMemo(() => ([
    {
      key: 'レイアウトコード',
      value: formik.values.layoutCode,
    },
    {
      key: 'レイアウト名',
      value: formik.values.layoutName,
    },

  ]), [formik]);

  // 詳細項目入力を表示するかどうか
  const [detailMode, setDetailMode] = useState(false);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    confirmModalItems,
    toastModalOpen,
    closeToastModal,
    setToastModalOpen,
    detailList,
    setDetailList,
    getOutputLayouts,

  };
};

export default {
  useTimeOutputLayoutDomainAddForm,
};
