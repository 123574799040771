import IncomeAndExpenditureScheduleMonthlyLayout from 'components/organismos/master/sales/IncomeAndExpenditureScheduleMonthlyForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';

const incomeAndExpenditureScheduleMonthlyPage = () => (
    <SidebarTemplate pageTitle="【月別】収支明細表">
        <IncomeAndExpenditureScheduleMonthlyLayout/>
    </SidebarTemplate>
);

export default incomeAndExpenditureScheduleMonthlyPage;