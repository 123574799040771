/* eslint-disable jsx-a11y/control-has-associated-label */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import BlockUI from 'components/molecules/BlockUi';
import FormLabel from 'components/atoms/Form/FormLabel';
import { isMobile, isMobileOnly } from 'react-device-detect';
import moment from 'moment';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import Icon from 'components/atoms/Icon';
import { textColor, grayScale } from 'components/styles';
import BottomBar from 'components/organismos/BottomBar';
import DatePickerForm from 'components/molecules/DatePickerForm';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { numberFormater } from 'utility/formatUtil';
import usePeriodSalesForm from './hooks';

const OperationalReportListForm = () => {
  const functionType = 3;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const history = useHistory();

  const {
    setCategoryAry,
    operationalReportList,
    isLoading,
    fetchData,
    setSelectOrgCode,
    selectOrgCode,
    targetDate,
    setTargetDate,
    searchTime,
  } = usePeriodSalesForm();

  function checkTime(targetDate: any) {
    let time = '';
    const date = moment(targetDate).format('YYYY-MM-DD');
    const formatSearchTime = moment(searchTime).format('YYYY-MM-DD');
    if (formatSearchTime === date) {
      time = moment(searchTime).format('YYYY年MM月DD日（ddd） HH時mm分まで');
    }
    return time;
  }

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox alignItems="center">
          <FlexBoxItem basis={isMobile ? '20%' : '10%'}>
            <FormLabel label="対象日" />
          </FlexBoxItem>
          <FlexBoxItem basis="50%">
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label=""
              isFullWidth={true}
              date={new Date(targetDate!)}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDate(date);
                  sessionStorage.setItem('targetDateReportQuick', moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD'));
                }
              }}
            />
          </FlexBoxItem>
          <FlexBoxItem marginLeft="auto" customStyle={css({ marginTop: 0 })}>
            <ClickableIconButton
              additionalCss={css({
                backgroundColor: '#0D4796',
                paddingLeft: '25px',
                paddingRight: '25px',
                borderRadius: '5px',
              })}
              onClick={fetchData}
            >
              <Icon
                type="reload"
                color={textColor.inversed}
                isHover={false}
                size="25"
              />
            </ClickableIconButton>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox>
          <FlexBoxItem width="100%">
            <OrganizationFilteredTree
              functionType={functionType}
              staffCode={staffCode}
              orgLabel="店舗名"
              initOrgValue={String(selectOrgCode)}
              orgCallback={(args: string | Array<string>) => {
                setSelectOrgCode(String(args));
              }}
              orgCategoryCallback={(args: Array<string>) => {
                setCategoryAry(args);
                console.log('args', args);
              }}
              styles={{
                label: {
                  toggleSwitch: css({ marginLeft: '0px' }),
                },
                toggle: {
                  width: '13rem',
                },
              }}
              addAllItem={true}
              targetdayForm={targetDate}
              targetdayTo={targetDate}
            />
          </FlexBoxItem>
        </FlexBox>

        <FlexBox customStyle={css({ marginTop: '12px', marginBottom: '16px' })}>
          <FormLabel label={checkTime(targetDate)} />
        </FlexBox>
        <div style={{ marginTop: '15px', overflow: 'auto' }} className="operationalReportListTable newDesignTable nobackground">
          <table
            className="table table-bordered text-nowrap"
            id="shogunStampHistory"
            cellSpacing="0"
            style={{ whiteSpace: 'nowrap', width: '100%' }}
          >
            <thead>
              <tr>
                <th
                  colSpan={2}
                  style={{
                    top: '0', maxWidth: '100px', width: '80px', minWidth: '80px',
                  }}
                  className="text-center stickyStyle"
                >
                  店舗No
                </th>
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '300px', width: '300px', minWidth: '300px',
                  }}
                  className="text-center stickyStyle"
                >
                  店舗名
                </th>
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '50px', width: '50px', minWidth: '50px',
                  }}
                  className="text-center stickyStyle"
                >
                  男性
                </th>
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '50px', width: '50px', minWidth: '50px',
                  }}
                  className="text-center stickyStyle"
                >
                  女性
                </th>
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '50px', width: '50px', minWidth: '50px',
                  }}
                  className="text-center stickyStyle"
                >
                  計
                </th>
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '25px', width: '25px', minWidth: '25px',
                  }}
                  className="text-center stickyStyle"
                />
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '50px', width: '50px', minWidth: '50px',
                  }}
                  className="text-center stickyStyle"
                >
                  初回
                </th>
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '50px', width: '50px', minWidth: '50px',
                  }}
                  className="text-center stickyStyle"
                >
                  2回目
                </th>
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '50px', width: '50px', minWidth: '50px',
                  }}
                  className="text-center stickyStyle"
                >
                  3回目
                </th>
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '50px', width: '50px', minWidth: '50px',
                  }}
                  className="text-center stickyStyle"
                >
                  常連
                </th>
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '50px', width: '50px', minWidth: '50px',
                  }}
                  className="text-center stickyStyle"
                >
                  計
                </th>
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '25px', width: '25px', minWidth: '25px',
                  }}
                  className="text-center stickyStyle"
                />
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '50px', width: '50px', minWidth: '50px',
                  }}
                  className="text-center stickyStyle"
                >
                  回数
                </th>
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '100px', width: '100px', minWidth: '100px',
                  }}
                  className="text-center stickyStyle"
                >
                  金額
                </th>
                <th
                  rowSpan={2}
                  style={{
                    top: '0', maxWidth: '50px', width: '50px', minWidth: '50px',
                  }}
                  className="text-center stickyStyle"
                >
                  未会計
                </th>
              </tr>
              <tr>
                <th
                  colSpan={1}
                  style={{
                    top: '0', maxWidth: '50px', width: '40px', minWidth: '40px',
                  }}
                  className="text-center stickyStyle"
                >
                  勤怠
                </th>
                <th
                  colSpan={1}
                  style={{
                    top: '0', maxWidth: '50px', width: '40px', minWidth: '40px',
                  }}
                  className="text-center stickyStyle"
                >
                  稼働
                </th>
              </tr>
            </thead>
            <tbody style={{ maxHeight: '100%' }}>
              {operationalReportList?.map((item: any, index: any) => {
                const { orgCode } = item;
                return (
                  <tr style={{ background: 'white' }}>
                    <td
                      colSpan={orgCode === '' ? 2 : 1}
                      className="text-center stickyStyle"
                      style={{
                        top: '0',
                        maxWidth: '40px',
                        width: '40px',
                        minWidth: '40px',
                        cursor: 'pointer',
                        padding: '5px 2px',
                      }}
                      onClick={() => {
                        history.push(`/shogun/stampHistory/${orgCode}/${moment(targetDate!).format('YYYY-MM-DD')}`);
                        sessionStorage.setItem('targetDateReportQuick', moment(targetDate!).format('YYYY-MM-DD'));
                        sessionStorage.setItem('orgCodeReportQuick', String(selectOrgCode));
                      }}
                    >
                      {
                        orgCode ? (
                          <span style={{
                            border: '1px solid #E7EDF4', padding: ' 3px 3px', backgroundColor: '#E7EDF4', color: '#0D4796', borderRadius: '5px',
                          }}
                          >
                            {orgCode}
                          </span>
                        ) : '合計'
                      }
                    </td>
                    {orgCode !== '' && (
                    <td
                      className="text-center stickyStyle"
                      style={{
                        top: '0',
                        maxWidth: '40px',
                        width: '40px',
                        minWidth: '40px',
                        cursor: 'pointer',
                        padding: '5px 2px',
                      }}
                      onClick={() => {
                        history.push(`/shogun/operationalReportQuick/${orgCode}/${moment(targetDate!).format('YYYY-MM-DD')}`);
                        sessionStorage.setItem('targetDateReportQuick', moment(targetDate!).format('YYYY-MM-DD'));
                        sessionStorage.setItem('orgCodeReportQuick', String(selectOrgCode));
                      }}
                    >
                      <span style={{
                        border: '1px solid #E7EDF4', padding: ' 3px 3px', backgroundColor: '#E7EDF4', color: '#0D4796', borderRadius: '5px',
                      }}
                      >
                        {orgCode}
                      </span>
                    </td>
                    )}
                    <td className="text-left" style={{ width: '300px', maxWidth: '300px' }}>
                      <span>
                        <div css={css({ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' })} data-tip={item.orgName} data-for={index}>
                          {item.orgName}
                        </div>
                        <ReactTooltip html={true} id={`row${index}`} />
                      </span>
                    </td>
                    <td className="text-right" style={{ width: '50px' }}>{numberFormater(item.maleCount)}</td>
                    <td className="text-right" style={{ width: '50px' }}>{numberFormater(item.womanCount)}</td>
                    <td className="text-right" style={{ width: '50px' }}>{numberFormater(item.sexTotalCount)}</td>
                    <td style={{ width: '25px', backgroundColor: grayScale.gray20 }} />
                    <td className="text-right" style={{ width: '50px' }}>{numberFormater(item.firstCount)}</td>
                    <td className="text-right" style={{ width: '50px' }}>{numberFormater(item.secondCount)}</td>
                    <td className="text-right" style={{ width: '50px' }}>{numberFormater(item.thirdCount)}</td>
                    <td className="text-right" style={{ width: '50px' }}>{numberFormater(item.regularCount)}</td>
                    <td className="text-right" style={{ width: '50px' }}>{numberFormater(item.visitNumCount)}</td>
                    <td style={{ width: '25px', backgroundColor: grayScale.gray20 }} />
                    <td className="text-right" style={{ width: '50px' }}>{numberFormater(item.accountCount)}</td>
                    <td className="text-right" style={{ width: '100px' }}>{numberFormater(item.priceTotal)}</td>
                    <td className="text-right" style={{ width: '50px' }}>{numberFormater(item.unaccountCount)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </FormContents>
      {isMobileOnly && <BottomBar />}
    </BlockUI>
  );
};
export default OperationalReportListForm;
