import moment from 'moment'; 
import { useState, useEffect, useCallback } from 'react';
import { apiGetShopSeparateSalesManagementCos } from 'api/monthly/shopSeparateSalesManagementCos';
import * as api from 'api/monthlyReport';
import { useParams } from 'react-router-dom';

const useDailySalesStore = () => {
  const functionType = 3;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = useParams<any>();
  const [orgCode, setOrgCode] = useState(params && params.orgCode ? params.orgCode : sessionStorage.getItem('loginUser.orgCode'));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);
  const dateSelectFrom = sessionStorage.getItem('dateSelectSalePageFrom5') || moment().add(-1, 'days').format('YYYY-MM-DD');
  const [selectedDateFrom, setSelectedDateFrom] = useState<Date>(params && params.targetDateFrom ? moment( params.targetDateFrom, 'YYYY-MM-DD').toDate() : new Date(dateSelectFrom));
  const dateSelectTo = sessionStorage.getItem('dateSelectSalePageTo5') || moment().add(-1, 'days').format('YYYY-MM-DD');
  const [selectedDateTo, setSelectedDateTo] = useState<Date>(params && params.targetDateTo ? moment( params.targetDateTo, 'YYYY-MM-DD').toDate() : new Date(dateSelectTo));
  const dummyData: any = [
  ];

  const [listDailySalesStore, setListDailySalesStore] = useState(dummyData);
  
    const fetchData = useCallback(async () => {
      setLoading(true);
      const path = 'SalesManagementCos';
      try {
        const response = await apiGetShopSeparateSalesManagementCos(path, String(orgCode), moment(selectedDateFrom.toString()).format('YYYY/MM/DD'), moment(selectedDateTo.toString()).format('YYYY/MM/DD'), categoryAry);
        setListDailySalesStore(response);
      } catch (error) {
        setListDailySalesStore([]);
      }
      setLoading(false);
    }, [orgCode, selectedDateFrom, selectedDateTo , categoryAry]);
  
    useEffect(() => {
      fetchData();
    }, [fetchData]);

  return {
    isLoading,
    listDailySalesStore,
    functionType,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo
  };
};

export const useDownload = () => {
  const downloadShopSeparateSalesManagementCosCsv = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '【店別】売上管理表.csv');
  };

  const downloadShopSeparateSalesManagementCosExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '【店別】売上管理表.xlsx');
  };

  return {
    downloadShopSeparateSalesManagementCosCsv,
    downloadShopSeparateSalesManagementCosExcel,
  };
};

export default useDailySalesStore;
