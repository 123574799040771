import { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';
import OrganizationDomain from 'domain/organization';
import { getHistory, createOrganizationInfoV4 } from 'api/organization';
import ManagerDomain from 'domain/manager';
import { getCompanyBankList } from 'api/companyBank';

export const useOrganizationAdd = () => {
  const { orgCode } = useParams<{orgCode: string}>();
  const [organizationDomain, setOrganizationDomain] = useState<OrganizationDomain>(
    OrganizationDomain.generateInitial(),
  );
  const [managerList, setManagerList] = useState<Array<ManagerDomain>|null>(null);
  const [seniorManagerList, setSeniorManagerList] = useState<Array<ManagerDomain>|null>(null);
  const [historyList, setHistoryList] = useState<Array<OrganizationDomain>>([]);
  const [blocking, setBlocking] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const [arrCompanyBank, setArrCompanyBank] = useState<any>();

  const historyRedirect = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      if (!orgCode) return;
      const history = await getHistory(orgCode);
      const organization = history[0];
      setOrganizationDomain(new OrganizationDomain(organization));
      if (organization.managerList && organization.managerList.length > 0) {
        setManagerList(organization.managerList.map((manager) => new ManagerDomain(manager)));
      } else {
        setManagerList([]);
      }
      if (organization.seniorManagerList && organization.seniorManagerList.length > 0) {
        setSeniorManagerList(organization.seniorManagerList.map(
          (seniorManager) => new ManagerDomain(seniorManager),
        ));
      } else {
        setSeniorManagerList([]);
      }
      if (history && history.length > 0) {
        setHistoryList(history.map((org) => new OrganizationDomain(org)));
      } else {
        setHistoryList([]);
      }
    };
    fetchData();
  }, [orgCode]);

  const fetchCompanyBank = useCallback(async () => {
    setBlocking(true);
    await getCompanyBankList('', true).then((response: any) => {
      setArrCompanyBank(response);
      setBlocking(false);
    }).catch((error: any) => {
      setArrCompanyBank([]);
      setBlocking(false);
    })
  }, [])

  useEffect(() => {
    fetchCompanyBank()
  }, [fetchCompanyBank])

  const createOrganization = (state: any) => {
    setBlocking(true);
    createOrganizationInfoV4(state).then((response: any) => {
      setBlocking(false);
      historyRedirect.push('/organizations');
      successNotification('登録しました。');
    }).catch((error: any) => {
      setBlocking(false);
      if (error && error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }

  return {
    organizationDomain,
    setOrganizationDomain,
    managerList,
    setManagerList,
    seniorManagerList,
    setSeniorManagerList,
    historyList,
    setHistoryList,
    blocking,
    createOrganization,
    arrCompanyBank
  };
};

export default {};
