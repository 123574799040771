import React, { useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import TabControl from 'components/atoms/TabControl';
import HolidayManagementSpecialHolidayForm from './HolidayManagementSpecialHolidayForm/HolidayManagementSpecialHolidayForm';
import HolidayManagementRestForm from './HolidayManagementRestForm/HolidayManagementRestForm';
import HolidayManagementPaidForm from './HolidayManagementPaidForm/HolidayManagementPaidForm';
import HolidayManagementHolidayForm from './HolidayManagementHolidayForm/HolidayManagementHolidayForm';
import { useHolidayManagementDomainForm } from './hooks';

type SelectType = 'paid'|'specialHoliday'|'holiday'|'rest';

const SelectType: {[key in SelectType]: SelectType} = {
  paid: 'paid',
  specialHoliday: 'specialHoliday',
  holiday: 'holiday',
  rest: 'rest',
};

const HolidayManagementForm: React.FC = () => {
  const {
    arrGrantResult,
    employmentOptions,
    specialHolidayOptions,
  } = useHolidayManagementDomainForm();
  //
  const [selectType, setSelectType] = React.useState<SelectType>(SelectType.paid);
  const [currentAccordionList, setCurrentAccordionList] = useState([]);

  return (
    <FormContents>
      <div>
        <TabControl
          items={[
            {
              value: SelectType.paid,
              display: '有給実績',
            },
            {
              value: SelectType.specialHoliday,
              display: '特休実績',
            },
            {
              value: SelectType.holiday,
              display: '代休実績',
            },
            {
              value: SelectType.rest,
              display: '振休実績',
            },
          ]}
          setValue={setSelectType as (arg: string) => void}
          currentValue={selectType}
          isFixed={true}
        />
      </div>
      <div>
        {/* 有給・管理情報 */}
        {selectType === SelectType.paid
              && (
              <HolidayManagementPaidForm
                arrGrantResult={arrGrantResult}
                employmentOptions={employmentOptions}
                currentAccordionList={currentAccordionList}
                setCurrentAccordionList={setCurrentAccordionList}
              />
              )}
        {/* 特休・管理情報 */}
        {selectType === SelectType.specialHoliday
              && (
              <HolidayManagementSpecialHolidayForm
                arrGrantResult={arrGrantResult}
                employmentOptions={employmentOptions}
                specialHolidayOptions={specialHolidayOptions}
                currentAccordionList={currentAccordionList}
                setCurrentAccordionList={setCurrentAccordionList}
              />
              )}

        {/* 代休・取得状況 */}
        {selectType === SelectType.holiday
              && (
              <HolidayManagementHolidayForm />
              )}

        {/* 振休・取得状況 */}
        {selectType === SelectType.rest
              && (
              <HolidayManagementRestForm />
              )}
      </div>
    </FormContents>
  );
};

export default HolidayManagementForm;
