import React, { SVGProps } from 'react';

const Reload: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 24.000000 24.000000"
    preserveAspectRatio="xMidYMid meet" {...props}>
    <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
    fill="#FFFFFF" stroke="none">
    <path d="M160 202 c0 -4 -15 -6 -34 -4 -27 3 -40 -2 -60 -22 -52 -51 -18 -146
    52 -146 36 0 82 34 82 61 0 26 -13 24 -25 -3 -15 -34 -57 -45 -89 -24 -34 22
    -36 78 -4 100 29 21 59 21 52 1 -5 -11 1 -15 18 -15 32 0 36 5 30 35 -4 23
    -22 36 -22 17z"/>
    </g>
  </svg>
);

export default Reload;
