import ApiClient from './ApiClient';

export const getList = async (companyCode: string, getDetails: boolean):Promise<any> => {
  const params = {
    getDetails,
  };
  const response = await ApiClient.get(`/v1/holiday/patterns/list/${companyCode}`, params);
  return response.data;
};

export const deleteById = async (companyCode: string, paidHolidayPatternId: string)
: Promise<any> => {
  const params = {
    paidHolidayPatternId,
  };
  const reponse = await ApiClient.delete(`/v1/holiday/patterns/${companyCode}`, params);
  return reponse.data;
};

export const getPaidHolidayPattern = async (companyCode: string, paidHolidayPatternId: string)
: Promise<any> => {
  const params = {
    paidHolidayPatternId,
  };
  const reponse = await ApiClient.get(`/v1/holiday/patterns/${companyCode}`, params);
  return reponse.data;
};

//
export const savePaidHolidayPattern = async (companyCode: string, paidHolidayPatternId: any) => {
  // TODO パラメータ確認
  const query = {
  };
  const response = await ApiClient.post(`/v1/holiday/patterns/${companyCode}`, query, paidHolidayPatternId);
  return response.data;
};


export default getList;
