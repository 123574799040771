/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import { useDownload } from './hooks';
import { Link } from 'react-router-dom';
import { isMobileOnly, isMacOs } from 'react-device-detect';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';
import { grayScale } from 'components/styles';
import useSortTable, { FieldSortState, SortColumn } from 'hooks/useSortTable';
import MonthlyDailyBudgetPerformanceManagementReportDomain from 'domain/master/storeManagement/MonthlyDailyBudgetPerformanceManagementReport';
import { MonthlyDailyBudgetPerformanceManagementResult } from 'types/api/sales';

const DEFAULT_SORT_FIELD = { fieldPath: 'targetDate', sort: 'asc' } as FieldSortState

const MonthlySeparateForeCastManagemenTable: React.FC<{
  setIsLoading: any,
  orgCode: any,
  budgetPerfomanceManagementMonthlDailyReport: MonthlyDailyBudgetPerformanceManagementResult,
  targetPeriodData: any,
  orgName: any,
  compareToPreviousYear: any,
  roleScreen: any,
}> = ({
  setIsLoading,
  orgCode,
  budgetPerfomanceManagementMonthlDailyReport,
  targetPeriodData,
  orgName,
  compareToPreviousYear,
  roleScreen,
}) => {

    const {detailList, total} = budgetPerfomanceManagementMonthlDailyReport;

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(detailList, DEFAULT_SORT_FIELD)

    // ------------------------------------------------------------------
    // 出力
    // ------------------------------------------------------------------
    const { downloadBudgetPerfomanceManagementCSV, downloadBudgetPerfomanceManagementExcel } = useDownload();

    const targetStoresData = useMemo(() => `対象店舗：${orgName}`, [orgName]);
    const headerInformation = useMemo(() => [
      ['', '売上', '', '', '', '', '', '', '', '', '', '客数', '', '', '', '', '', '', '', '仕入', '', '', '', '', '', '', '', '人件費', '', '', '', '', '', '', '', '労働時間', '', '', '経費', '', '利益', '', ''],
      ['日付', '純売上', '売上予算', '前年売上', '予実達成率', '前年対比', '累計純売上', '累計売上予算', '累計前年売上', '予実達成率', '前年対比', '客数', '目標客数', '前年客数', '予実達成率', '前年対比', '客単価', '目標客単価', '前年客単価', '仕入', '原価率', '累計仕入', '累計原価率', '累計目標原価率', '累計前年原価率', '予実差率', '前年差率', '人件費', '人件費率', '累計人件費', '累計人件費率', '累計目標人件費率', '累計前年人件費率', '予実差率', '前年差率', '社員', 'アルバイト', '合計', '累計固定費', '累計変動費', '累計利益', '累計目標利益', '累計予実達成率'],
    ], []);

    const searchResult = useMemo(() => (dataSort ?? detailList).map(d => d.getOutputData()), [dataSort]);
    const totalInformation = useMemo(() => [total.getOutputData()], [total]);

    let mergedRegions: any[] = [];
    const codeColumnIndex: any[] = [];
    const data = {
      footer: '',
      formName: '月次日別予実管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      compareToPreviousYear,
      headerInformation,
      searchResult,
      totalInformation,
    };

    const dataExcel = {
      formName: '月次日別予実管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      compareToPreviousYear,
      headerInformation,
      searchResult,
      totalInformation,
      mergedRegions,
      codeColumnIndex,
    };

    const handleExportBudgetPerfomanceManagementCSV = () => { downloadBudgetPerfomanceManagementCSV(data, setIsLoading); };
    const handleExportBudgetPerfomanceManagementExcel = () => {
      if (mergedRegions) {
        mergedRegions = [];
      }
      mergedRegions.push([4, 5, 0, 0]);
      mergedRegions.push([4, 4, 1, 10]);
      mergedRegions.push([4, 4, 11, 18]);
      mergedRegions.push([4, 4, 19, 26]);
      mergedRegions.push([4, 4, 27, 34]);
      mergedRegions.push([4, 4, 35, 37]);
      mergedRegions.push([4, 4, 38, 39]);
      mergedRegions.push([4, 4, 40, 42]);
      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [];
      dataExcel.headerInformation = [
        ['日付', '売上', '', '', '', '', '', '', '', '', '', '客数', '', '', '', '', '', '', '', '仕入', '', '', '', '', '', '', '', '人件費', '', '', '', '', '', '', '', '労働時間', '', '', '経費', '', '利益', '', ''],
        ['', '純売上', '売上予算', '前年売上', '予実達成率', '前年対比', '累計純売上', '累計売上予算', '累計前年売上', '予実達成率', '前年対比', '客数', '目標客数', '前年客数', '予実達成率', '前年対比', '客単価', '目標客単価', '前年客単価', '仕入', '原価率', '累計仕入', '累計原価率', '累計目標原価率', '累計前年原価率', '予実差率', '前年差率', '人件費', '人件費率', '累計人件費', '累計人件費率', '累計目標人件費率', '累計前年人件費率', '予実差率', '前年差率', '社員', 'アルバイト', '合計', '累計固定費', '累計変動費', '累計利益', '累計目標利益', '累計予実達成率'],
      ];
      return downloadBudgetPerfomanceManagementExcel(dataExcel, setIsLoading);
    };

    const sortColumns: Array<SortColumn> = [
      { displayName: '純売上', sortField: 'netSales' },
      { displayName: '売上予算', sortField: 'salesBudget' },
      { displayName: '前年売上', sortField: 'prevSales' },
      { displayName: '予実達成率', sortField: 'perGoalSales' },
      { displayName: '前年対比', sortField: 'perPrevSales' },
      { displayName: '累計純売上', sortField: 'cumulativeSales' },
      { displayName: '累計売上予算', sortField: 'cumulativeSalesBudget', padding: '0 20px 0 0', width: '120px' },
      { displayName: '累計前年売上', sortField: 'prevCumulativeSales', padding: '0 20px 0 0', width: '120px' },
      { displayName: '予実達成率', sortField: 'perGoalCumulativeSales' },
      { displayName: '前年対比', sortField: 'perPrevCumulativeSales' },
      { displayName: '客数', sortField: 'guestCnt' },
      { displayName: '目標客数', sortField: 'guestCntBudget' },
      { displayName: '前年客数', sortField: 'prevGuestCnt' },
      { displayName: '予実達成率', sortField: 'perGoalGuestCnt' },
      { displayName: '前年対比', sortField: 'perPrevGuestCnt' },
      { displayName: '客単価', sortField: 'guestCntPrice' },
      { displayName: '目標客単価', sortField: 'guestCntPriceBudget' },
      { displayName: '前年客単価', sortField: 'prevGuestCntPrice' },
      { displayName: '仕入', sortField: 'purchase' },
      { displayName: '原価率', sortField: 'perPurchase' },
      { displayName: '累計仕入', sortField: 'cumulativePurchase' },
      { displayName: '累計原価率', sortField: 'perCumulativePurchase' },
      { displayName: '累計目標原価率', sortField: 'perCumulativePurchaseBudget', padding: '0 30px 0 0', width: '130px' },
      { displayName: '累計前年原価率', sortField: 'perPrevCumulativePurchase', padding: '0 30px 0 0', width: '130px' },
      { displayName: '予実差率', sortField: 'diffPerCumulativePurchase' },
      { displayName: '前年差率', sortField: 'diffPerPrevCumulativePurchase' },
      { displayName: '人件費', sortField: 'laborCost' },
      { displayName: '人件費率', sortField: 'perLaborCost' },
      { displayName: '累計人件費', sortField: 'cumulativeLaborCost' },
      { displayName: '累計人件費率', sortField: 'perCumulativeLaborCost', padding: '0 20px 0 0', width: '120px' },
      { displayName: '累計目標人件費率', sortField: 'perCumulativeLaborCostBudget', padding: '0 50px 0 0', width: '150px' },
      { displayName: '累計前年人件費率', sortField: 'perPrevCumulativeLaborCost', padding: '0 50px 0 0', width: '150px' },
      { displayName: '予実差率', sortField: 'diffPerCumulativeLaborCost' },
      { displayName: '前年差率', sortField: 'diffPerPrevCumulativeLaborCost' },
      { displayName: '社員', sortField: 'employeeWorkTime' },
      { displayName: 'アルバイト', sortField: 'partWorkTime' },
      { displayName: '合計', sortField: 'totalWorkTime' },
      { displayName: '累計固定費', sortField: 'cumulativeFixCost' },
      { displayName: '累計変動費', sortField: 'cumulativeVariableCost' },
      { displayName: '累計利益', sortField: 'cumulativeProfit' },
      { displayName: '累計目標利益',   sortField: 'cumulativeProfitBudget', padding: '0 20px 0 0', width: '120px' },
      { displayName: '累計予実達成率', sortField: 'perGoalCumulativeProfit', padding: '0 20px 0 0', width: '120px' }
    ];

    const renderReportColumn = (row: MonthlyDailyBudgetPerformanceManagementReportDomain) => (
      <>
        <td className="text-right"><span>{row.netSales}</span></td>
        <td className="text-right"><span>{row.salesBudget}</span></td>
        <td className="text-right"><span>{row.prevSales}</span></td>
        <td className="text-right"><span>{row.perGoalSales}</span></td>
        <td className="text-right"><span>{row.perPrevSales}</span></td>
        <td className="text-right"><span>{row.cumulativeSales}</span></td>
        <td className="text-right"><span>{row.cumulativeSalesBudget}</span></td>
        <td className="text-right"><span>{row.prevCumulativeSales}</span></td>
        <td className="text-right"><span>{row.perGoalCumulativeSales}</span></td>
        <td className="text-right"><span>{row.perPrevCumulativeSales}</span></td>
        <td className="text-right"><span>{row.guestCnt}</span></td>
        <td className="text-right"><span>{row.guestCntBudget}</span></td>
        <td className="text-right"><span>{row.prevGuestCnt}</span></td>
        <td className="text-right"><span>{row.perGoalGuestCnt}</span></td>
        <td className="text-right"><span>{row.perPrevGuestCnt}</span></td>
        <td className="text-right"><span>{row.guestCntPrice}</span></td>
        <td className="text-right"><span>{row.guestCntPriceBudget}</span></td>
        <td className="text-right"><span>{row.prevGuestCntPrice}</span></td>
        <td className="text-right"><span>{row.purchase}</span></td>
        <td className="text-right"><span>{row.perPurchase}</span></td>
        <td className="text-right"><span>{row.cumulativePurchase}</span></td>
        <td className="text-right"><span>{row.perCumulativePurchase}</span></td>
        <td className="text-right"><span>{row.perCumulativePurchaseBudget}</span></td>
        <td className="text-right"><span>{row.perPrevCumulativePurchase}</span></td>
        <td className="text-right"><span>{row.diffPerCumulativePurchase}</span></td>
        <td className="text-right"><span>{row.diffPerPrevCumulativePurchase}</span></td>
        <td className="text-right"><span>{row.laborCost}</span></td>
        <td className="text-right"><span>{row.perLaborCost}</span></td>
        <td className="text-right"><span>{row.cumulativeLaborCost}</span></td>
        <td className="text-right"><span>{row.perCumulativeLaborCost}</span></td>
        <td className="text-right"><span>{row.perCumulativeLaborCostBudget}</span></td>
        <td className="text-right"><span>{row.perPrevCumulativeLaborCost}</span></td>
        <td className="text-right"><span>{row.diffPerCumulativeLaborCost}</span></td>
        <td className="text-right"><span>{row.diffPerPrevCumulativeLaborCost}</span></td>
        <td className="text-right"><span>{row.employeeWorkTimeDisp}</span></td>
        <td className="text-right"><span>{row.partWorkTimeDisp}</span></td>
        <td className="text-right"><span>{row.totalWorkTimeDisp}</span></td>
        <td className="text-right"><span>{row.cumulativeFixCost}</span></td>
        <td className="text-right"><span>{row.cumulativeVariableCost}</span></td>
        <td className="text-right"><span>{row.cumulativeProfit}</span></td>
        <td className="text-right"><span>{row.cumulativeProfitBudget}</span></td>
        <td className="text-right"><span>{row.perGoalCumulativeProfit}</span></td>
      </>
    );

    return (
      <div>
        <FlexBox>
          {
            roleScreen && roleScreen.downloadFlag === 1 && (
              <React.Fragment>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportBudgetPerfomanceManagementCSV}
                    iconType="download"
                    text=" CSV出力"
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportBudgetPerfomanceManagementExcel}
                    iconType="download"
                    text=" EXCEL出力"
                  />
                </FlexBoxItem>
              </React.Fragment>
            )}
        </FlexBox>
        <div style={{ margin: '15px' }}>
          <div className="templateTable newDesignTable" style={{ height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: isMacOs ? '35px' : '1px' }} >
            <table className="table table-bordered text-nowrap">
              <thead>
                {/* frist row */}
                <tr>
                  {/* left side */}
                  <th rowSpan={2} style={{ top: '0', left: '0', zIndex: 99, maxWidth: ' 200px', width: '200px', minWidth: '200px' }} className="text-center stickyStyle fristColSticky" onClick={e => sortByFieldPath('targetDate')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }} >日付</span>
                      {sortField.fieldPath === 'targetDate' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'targetDate' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  {/* right side */}
                  <th colSpan={10} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>売上</span></th>
                  <th colSpan={8} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>客数</span></th>
                  <th colSpan={8} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>仕入</span></th>
                  <th colSpan={8} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>人件費</span></th>
                  <th colSpan={3} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>労働時間</span></th>
                  <th colSpan={2} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>経費</span></th>
                  <th colSpan={3} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>利益</span></th>
                </tr>
                {/* second row */}
                <tr>
                  {/* right side */}
                  {sortColumns.map((item: SortColumn, index: any) => (
                      <th key={index} style={{ top: '34px', padding: item.padding }} className="text-center stickyStyle" onClick={e => sortByFieldPath(item.sortField, item.sortType ?? 'number')}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: item.width }}>
                          <span style={{ margin: 'auto' }}>{item.displayName}</span>
                          {sortField.fieldPath === item.sortField && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== item.sortField && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </th>
                    )
                  )}

                </tr>
              </thead>
              <tbody>
                {
                  dataSort && dataSort.map((row: MonthlyDailyBudgetPerformanceManagementReportDomain, index: number) => {
                    return (
                      <tr key={index}>
                        <td className="text-center stickyStyle fristColSticky" style={{ left: '0', zIndex: 10 }}>
                          <span>
                            <Link to={{ pathname: `/salesDailyReport/${orgCode}/${row.targetDateUrlParam}` }}>
                              {row.targetDateDisp}
                            </Link>
                          </span>
                        </td>
                        {renderReportColumn(row)}
                      </tr>
                    )
                  })
                }
                {/* footer */}
                {
                  total && (
                    <tr key="total" style={{ fontWeight: 'bolder' }}>
                      <td className="text-center stickyStyle fristColSticky" style={{ top: '0', left: '0', zIndex: 99 }}>
                        <div className="text-ellipsis">{total.targetDateDisp}</div>
                      </td>
                      {renderReportColumn(total)}
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

export default MonthlySeparateForeCastManagemenTable;
