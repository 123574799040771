/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import * as api from 'api/monthlyReport';
import MonthlyReportDomain from 'domain/master/storeManagement/monthlyReport';
import { useFormik } from 'formik';
import YearlySalesManagementReportDomain, { YearlySalesManagementReport } from 'domain/master/storeManagement/yearlySalesManagementReport';
import useToastNotification from 'hooks/useToastNotification';

const useYearlyReport = (
  orgCode: string,
  yearlyStartDate: string,
  yearlyEndDate: string,
  categoryAry: any,
) => {
  const [yearlySalesManagementReport, setYearlySalesManagementReport] = useState<Array<YearlySalesManagementReportDomain>>([]);
  const [getIsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const path = 'salesmanagement';
    setIsLoading(true);
    api.getYearlyReport(
      companyCode,
      orgCode,
      yearlyStartDate,
      yearlyEndDate,
      path,
      categoryAry,
    ).then((response: any) => {
      if (isSubscribed) {
        setYearlySalesManagementReport(response.map((r: YearlySalesManagementReport) => new YearlySalesManagementReportDomain(r)));
      }
      setIsLoading(false);
    });

    return () => { isSubscribed = false; };
  }, [categoryAry, orgCode, yearlyEndDate, yearlyStartDate]);

  return { yearlySalesManagementReport, getIsLoading, setIsLoading };
};

export const useGetFiscalYearly = () => {
  const [getValueFiscalYear, setFiscalYear] = useState();
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  useEffect(() => {
    api.getFiscalYear(companyCode).then((response: any) => {
      setFiscalYear(response);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFiscalYear]);
  return { getValueFiscalYear };
};

type ActionType = 'getFetchOption' | 'getFetchOptionforeCast';

const ActionType :{[key in ActionType]: ActionType} = {
  getFetchOption: 'getFetchOption',
  getFetchOptionforeCast: 'getFetchOptionforeCast',
};

export const useGetListMonthlyReport = () => {
  const onSubmit = () => {

  };

  const formik = useFormik({
    initialValues: MonthlyReportDomain.generateInitial(),
    onSubmit,
  });

  const [action, setAction] = useState<ActionType>(ActionType.getFetchOption);
  if (action === ActionType.getFetchOption) { }

  if (action === ActionType.getFetchOptionforeCast) {}
  return {
    action,
    setAction,
    ActionType,
    formik,
  };
};

export const useDownload = () => {
  const { errorNotification } = useToastNotification();

  const downloadMonthlyReportCsv = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportCsv(monthlyReport, '年次売上管理表.csv').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };

  const downloadMonthlyReportExcel = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api.downloadMonthlyReportExcel(monthlyReport, '年次売上管理表.xlsx').then(() => {
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 500) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setIsLoading(false);
    });
  };

  return {
    downloadMonthlyReportCsv,
    downloadMonthlyReportExcel,
  };
};

export default useYearlyReport;
