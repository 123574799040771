/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsx jsx */
import React from 'react';
import { jsx, SerializedStyles } from '@emotion/core';

import Icon, { IconType } from 'components/atoms/Icon';
import ReactTooltip from 'react-tooltip';
import { concat } from 'lodash';

export interface IconTooltipProps {
  iconType: IconType;
  text: string;
  color: string;
  customCss?: SerializedStyles;
  place?: ReactTooltip.Place;
  inlineBlock?: boolean;
}

/**
 * ツールチップのついたアイコン
 */
const IconTooltip: React.FC<IconTooltipProps> = ({
  iconType,
  text,
  color,
  customCss,
  place = 'top',
  inlineBlock = false,
}) => {
  const min = 1;
  const max = 1000;
  const rand = min + Math.random() * (max - min);
  const time = new Date().getTime();
  const number = concat(rand, time).join('');
  return (
    <div
      className="tooltip"
      style={{ display: inlineBlock === true ? 'inline-block' : 'block' }}
    >
      <a data-tip={false} data-for={`tooltip${number}`} css={customCss}>
        <Icon display="flex" type={iconType} color={color} />
      </a>
      <ReactTooltip id={`tooltip${number}`} type="dark" place={place}>
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </ReactTooltip>
    </div>
  );
};

export default IconTooltip;
