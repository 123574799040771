/* eslint-disable max-len */
import ApiClient from './ApiClient';

export const getListTimeRecalculation = async (companyCode: string): Promise<any> => {
  const response = await ApiClient.get(`/v1/attend/latestRecalcute/${companyCode}`, {});
  return response.data;
};

export const getEmploymentOptions = async (targetYmFrom: string, targetYmTo: string):Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/employments/listByMonthRange/${companyCode}?targetYmFrom=${targetYmFrom}&targetYmTo=${targetYmTo}`, {});
  return response.data;
};

export const getStaffOptions = async (params: any, categoryAryStr: any):Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAryStr)) {
    categoryAryStr.forEach((cateArr: any) => {
      appendUrl += `&categoryAry=${cateArr}`;
    });
  }
  const response = await ApiClient.get(`/v2/staffs/list/${companyCode}?${appendUrl}`, params);
  return response.data;
};

export const getRecalculateTime = async (timeRecal: any, categoryAry: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry) && categoryAry.length) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  console.log('appendUrl', appendUrl);
  const queryStringParams = {
    functionType: 2,
    loginStaffCode,
  };
  const reponse = await ApiClient.post(`/v2/attend/recalcuteInfo/${companyCode}`, queryStringParams, timeRecal, appendUrl);
  return reponse.data;
};

// 勤怠再計算前処理
export const preRecalculate = async (timeRecal: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const reponse = await ApiClient.post(`/v1/attend/preRecalcute/${companyCode}`, {}, timeRecal);
  return reponse.data;
};

// 勤怠再計算処理
export const recalculate = async (timeRecal: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const data = {
    recalculationId: timeRecal,
  };
  const reponse = await ApiClient.postMutipartData(`/v1/attend/recalcute/${companyCode}`, {}, data);
  return reponse.data;
};

export default getListTimeRecalculation;
