import React from 'react';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { Link, useParams } from 'react-router-dom';
import ConfirmModal from 'components/organismos/ConfirmModal';
import FormContents from 'components/atoms/Form/FormContents';
import { useJournalMstSelectTree, useClassificationMstTree } from 'components/pages/master/general/accountTitleMST/accountTitleMSTPage/DescriptionMaster/hooks';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import useDescriptMSTDomainForm from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const DescriptionMSTForm: React.FC = () => {
  const { descriptionId } = useParams();
  const { journalMstSelect } = useJournalMstSelectTree();
  const { classificationMstOption } = useClassificationMstTree();
  const {
    formik, confirmModalOpen, closeConfirmModal,
  } = useDescriptMSTDomainForm(String(descriptionId));
  // Set default value
  if (formik.values.journalId === '' && journalMstSelect.length !== 0) {
    formik.setFieldValue('journalId', journalMstSelect[0].value);
  }
  if (formik.values.classificationId === '' && classificationMstOption.length !== 0) {
    formik.setFieldValue('classificationId', classificationMstOption[0].value);
  }

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <div>
      <FormContents>
        <form>
          <FormField>
            <TextForm
              name="descriptionCode"
              label="コード"
              value={formik.values.descriptionCode}
              required={true}
              onChange={formik.handleChange}
            />
          </FormField>
          <FormField>
            <TextForm
              name="descriptionName"
              label="摘要名"
              value={formik.values.descriptionName}
              required={true}
              onChange={formik.handleChange}
            />
          </FormField>
          <FormField displayBlock={true}>
            <VerticalLabelSelectForm
              name="journalId"
              label="仕訳名"
              value={formik.values.journalId}
              setValue={(v) => {
                formik.setFieldValue('journalId', v);
              }}
              options={journalMstSelect}
              required={true}
            />
          </FormField>

          <FormField displayBlock={true}>
            <VerticalLabelSelectForm
              name="classificationId"
              label="分類"
              value={formik.values.classificationId}
              setValue={(v) => {
                formik.setFieldValue('classificationId', v);
              }}
              options={classificationMstOption}
              required={true}
            />
          </FormField>

          <FormSubmitArea>
            {
              roleScreen && roleScreen.editable === 1 && (
                <div style={{ marginRight: '12px' }}>
                  <Button
                    text={descriptionId ? '更新' : '登録'}
                    onClick={formik.handleSubmit}
                  />
                </div>
              )
            }
            <Link to="/accountTitleMST">
              <Button
                text="戻る"
                ghost={true}
              />
            </Link>
          </FormSubmitArea>
        </form>
      </FormContents>

      <ConfirmModal
        title="確認メッセージ"
        content={descriptionId ? '更新します。よろしいですか？' : '登録します。よろしいですか？'}
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.submitForm}
        items={[]}
      />
    </div>
  );
};

export default DescriptionMSTForm;
