/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';
import { Link, useParams } from 'react-router-dom';

const DashboardAnalysticDetail: React.FC<{
    chevronLeft?: boolean,
    chevronRight?: boolean,
    dataGeneral?: any,
    dispType?: number
}> = ({
    chevronLeft = false, chevronRight = false, dataGeneral, dispType
}) => {
        const formatter = new Intl.NumberFormat('ja-JP', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });
        const { option, detailOption, informationDetail } = useParams();

        console.log('check dispType', dispType);

        const handleAddSession = () => {
            if(option === undefined || option === ''){
                sessionStorage.setItem('orgName', dataGeneral.orgName);
            }else if (detailOption === undefined || detailOption === '') {
                sessionStorage.setItem('detailName', dataGeneral.detailName);
            }else if(informationDetail === undefined || informationDetail === ''){
                sessionStorage.setItem('orgName', dataGeneral.orgName);
            }
            
        }

        return (
            <React.Fragment>
                <FlexBox customStyle={css({ padding: '10px 0' })}>
                    <FlexBoxItem basis={chevronLeft ? '40px' : '10px'} customStyle={css({ padding: '0 5px' })}>
                        <Link to={informationDetail !== undefined && informationDetail !== '' && dispType === 2 ? { pathname: `/dashboard` } : informationDetail !== undefined && informationDetail !== '' ? { pathname: `/dashboard/${option}/${detailOption}` } : detailOption !== undefined && detailOption !== '' ? { pathname: `/dashboard/${option}`} : { pathname: `/dashboard` }}>
                            {chevronLeft && <Icon type="chevronLeft" color='#0D4796' />}
                        </Link>
                    </FlexBoxItem>
                    <FlexBoxItem basis='100%'>
                        <FlexBox customStyle={css({ marginBottom: '15px' })}>
                            <FlexBoxItem basis='50%' customStyle={css({ padding: '5px 15px', borderRight: '1px solid #CCCCCC' })}>
                                <FormLabel label="売上" customStyle={css({ color: '#0D4796' })} />
                                <div>
                                    <BodyText
                                        size="xl2"
                                        customStyle={css({
                                            marginTop: '8px', textOverflow: 'hidden', fontSize: '120%',
                                        })}
                                    >
                                        {dataGeneral && dataGeneral.netSales !== undefined ? formatter.format(dataGeneral.netSales) : 0}
                                    </BodyText>
                                    <BodyText
                                        size="lg"
                                        customStyle={css({
                                            marginRight: '4px', marginTop: '12px', textOverflow: 'hidden', fontSize: '80%',
                                        })}
                                    >
                                        円
                                    </BodyText>
                                </div>
                            </FlexBoxItem>
                            <FlexBoxItem basis='50%' customStyle={css({ padding: '5px 15px' })}>
                                <FormLabel label="達成率" customStyle={css({ color: '#0D4796' })} />
                                <div>
                                    <BodyText
                                        size="xl2"
                                        customStyle={css({
                                            marginTop: '8px', textOverflow: 'hidden', fontSize: '120%',
                                        })}
                                    >
                                        {dataGeneral && dataGeneral.netSalesRate !== undefined ? formatter.format(dataGeneral.netSalesRate) : 0}
                                    </BodyText>
                                    <BodyText
                                        size="lg"
                                        customStyle={css({
                                            marginRight: '4px', marginTop: '12px', textOverflow: 'hidden', fontSize: '80%',
                                        })}
                                    >
                                        %
                                    </BodyText>
                                </div>
                            </FlexBoxItem>
                        </FlexBox>

                        <FlexBox>
                            <FlexBoxItem basis='50%' customStyle={css({ padding: '5px 15px', borderRight: '1px solid #CCCCCC' })}>
                                <FormLabel label="主なコスト" customStyle={css({ color: '#0D4796' })} />
                                <div>
                                    <BodyText
                                        size="lg"
                                        customStyle={css({
                                            marginRight: '4px', marginTop: '12px', textOverflow: 'hidden', fontSize: '80%',
                                        })}
                                    >
                                        FLA
                                    </BodyText>
                                    <BodyText
                                        size="xl2"
                                        customStyle={css({
                                            marginTop: '8px', textOverflow: 'hidden', fontSize: '120%',
                                        })}
                                    >
                                        {dataGeneral && dataGeneral.flaRate !== undefined ? formatter.format(dataGeneral.flaRate) : 0}
                                    </BodyText>
                                    <BodyText
                                        size="lg"
                                        customStyle={css({
                                            marginRight: '4px', marginTop: '12px', textOverflow: 'hidden', fontSize: '80%',
                                        })}
                                    >
                                        %
                                    </BodyText>
                                </div>
                            </FlexBoxItem>
                            <FlexBoxItem basis='50%' customStyle={css({ padding: '5px 15px' })}>
                                <FormLabel label="昨年対比" customStyle={css({ color: '#0D4796' })} />
                                <div>
                                    <BodyText
                                        size="xl2"
                                        customStyle={css({
                                            marginTop: '8px', textOverflow: 'hidden', fontSize: '120%',
                                        })}
                                    >
                                        {dataGeneral && dataGeneral.prevNetSalesRate !== undefined ? formatter.format(dataGeneral.prevNetSalesRate) : 0}
                                    </BodyText>
                                    <BodyText
                                        size="lg"
                                        customStyle={css({
                                            marginRight: '4px', marginTop: '12px', textOverflow: 'hidden', fontSize: '80%',
                                        })}
                                    >
                                        %
                                    </BodyText>
                                </div>
                            </FlexBoxItem>
                        </FlexBox>
                        <FlexBox>
                            <FlexBoxItem customStyle={css({ padding: '0px 15px' })}>
                                <BodyText
                                    size="lg"
                                    customStyle={css({
                                        marginRight: '4px', marginTop: '12px', textOverflow: 'hidden', fontSize: '65%',
                                    })}
                                >
                                    F {dataGeneral && dataGeneral.frate !== undefined ? formatter.format(dataGeneral.frate) : 0}%
                                    / L {dataGeneral && dataGeneral.lrate !== undefined ? formatter.format(dataGeneral.lrate) : 0}%
                                    / A {dataGeneral && dataGeneral.arate !== undefined ? formatter.format(dataGeneral.arate) : 0}%
                                </BodyText>
                            </FlexBoxItem>
                        </FlexBox>
                    </FlexBoxItem>
                    <FlexBoxItem basis={chevronRight ? '40px' : '10px'} customStyle={css({ padding: '0 15px' })}>
                        < Link to={ dispType && dispType === 2 ? { pathname: `/dashboard/${dataGeneral && dataGeneral.id}/${dataGeneral && dataGeneral.id}/${(dataGeneral && dataGeneral.orgCode) && dataGeneral.orgCode }` } : detailOption !== undefined && detailOption !== '' ? { pathname: `/dashboard/${option}/${detailOption}/${(dataGeneral && dataGeneral.orgCode) && dataGeneral.orgCode }` } : { pathname: `/dashboard/${option}/${dataGeneral && dataGeneral.organizationCategoryMstDetailId}` }} onClick = {handleAddSession} >
                            {chevronRight && <Icon type="chevronRight" color='#0D4796' />}
                        </Link>
                    </FlexBoxItem>
                </FlexBox>
            </React.Fragment>
        );
    }
export default DashboardAnalysticDetail;
