/** @jsx jsx */
import React, { useState } from 'react';
import { Column } from 'react-table';
import { css, jsx } from '@emotion/core';

import { iconColor } from 'components/styles';
import DataTable from 'components/organismos/DataTable/DataTable';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Icon from 'components/atoms/Icon';
import ClickableLink from 'components/atoms/ClickableLink';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import EmploymentDomain from 'domain/employment';
// import Modal from 'components/molecules/Modal';
// import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import ConfirmModal from 'components/organismos/ConfirmModal';

import ApplyDateFilter from './ApplyDateFilter';
import ButtonNavigation from './ButtonNavigation';
import { useEmploymentDelete } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const EmploymentListTable:React.FC<{
    employmentList: Array<EmploymentDomain>;
    fetchData: () => Promise<void>;
    allEmployment: boolean;
    toggleAllEmployment: () => void;
    applyDate: Date;
    setApplyDate: (arg: Date) => void;
}> = ({
  employmentList, fetchData, allEmployment, toggleAllEmployment, applyDate, setApplyDate,
}) => {
  const {
    // delEmployment,
    setDelEmployment,
    isOpenDeleteModal,
    closeDeleteModal, onSubmitDeleteEmployment,
  } = useEmploymentDelete(fetchData);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const columns: Array<Column<EmploymentDomain>> = React.useMemo(() => [
    {
      Header: '雇用形態コード',
      accessor: 'employmentCode',
      sortType: 'basic'
    },
    {
      Header: '雇用形態名',
      accessor: 'employmentName',
    },
    {
      Header: '適用開始年月',
      accessor: 'dispApplyDateStr',
    },
    {
      Header: () => null,
      accessor: 'employmentId',
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: EmploymentDomain }}) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverCopy, setIsHoverCopy] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);

        return (

          <FlexBox>
            <FlexBoxItem marginLeft="auto">
              <ClickableLink to={`/masterEmploymentForm/edit/2/${cell.row.original.employmentId}/${cell.row.original.applyStartDate}`}>
                <span
                  // style={{ marginLeft: '20px' }}
                  onMouseEnter={() => setIsHoverEdit(true)}
                  onMouseLeave={() => setIsHoverEdit(false)}
                >
                  <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
                </span>
              </ClickableLink>
            </FlexBoxItem>
            {
              roleScreen && roleScreen.editable === 1 && (
                <React.Fragment>
                  <FlexBoxItem>
                    <ClickableLink to={`/masterEmploymentForm/edit/1/${cell.row.original.employmentId}/${cell.row.original.applyStartDate}`}>
                      <span
                        // style={{ marginLeft: '20px' }}
                        onMouseEnter={() => setIsHoverCopy(true)}
                        onMouseLeave={() => setIsHoverCopy(false)}
                      >
                        <Icon type="copy" color={isHoverCopy ? iconColor.grayhover : iconColor.gray} />
                      </span>
                    </ClickableLink>
                  </FlexBoxItem>
                  <FlexBoxItem>
                    <ClickableIconButton
                      onClick={() => setDelEmployment(cell.row.original)}
                    >
                      <span
                        style={{ marginRight: '20px' }}
                        onMouseEnter={() => setIsHoverDelete(true)}
                        onMouseLeave={() => setIsHoverDelete(false)}
                      >
                        <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                      </span>
                    </ClickableIconButton>
                  </FlexBoxItem>
                </React.Fragment>
              )
            }
          </FlexBox>
        );
      },
    },
  ], [setDelEmployment, roleScreen]);

  return (
    <React.Fragment>
      <ApplyDateFilter
        allEmployment={allEmployment}
        toggleAllEmployment={toggleAllEmployment}
        applyDate={applyDate}
        setApplyDate={setApplyDate}
      />

      { roleScreen && roleScreen.editable === 1 && <ButtonNavigation /> }
      <div
        css={css`
            table th:nth-child(1) {
              width: 100px;
            }
            table th:nth-child(2) {
              width: 200px;
            }
            table th:nth-child(3) {
              width: 120px;
            }
            table th:nth-child(4) {
              width: 120px;
            }
          `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
          <DataTable
            columns={columns}
            data={employmentList}
            isGlobalFilter={true}
            useSession={true}
            tableId={1}
            sortBy={[
              { id: 'employmentCode', desc: false }
            ]}
          />
        </div>
      </div>


      <ConfirmModal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        title="確認メッセージ"
        content="削除します。よろしいですか？"
        submitText="削除"
        onSubmit={onSubmitDeleteEmployment}
        items={[]}
      />
    </React.Fragment>
  );
};

export default EmploymentListTable;
