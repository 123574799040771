/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import { apiGetMonthlyBalaceDetailTable, downloadBalanceStatementStoreCsv, downloadBalanceStatementStoreExcel } from 'api/balanceStatementStore';
import {TBalanceStateStore} from './type'
import { getErrorMessageForResponse } from 'utility/errorUtil';
const useBalanceStatementStore = (
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate:string,
  categoryAry: any,
) => {
  const [balanceStatementStore, setBalanceStatementStore] = useState<Array<TBalanceStateStore>>([]);
  const [listHeader, setListHeader] = useState();
  const [getIsLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<String>('');

  useEffect(() => {

    setIsLoading(true);

    // 検索処理を実施
    apiGetMonthlyBalaceDetailTable(orgCode, monthlyStartDate, monthlyEndDate, categoryAry,).then((response) => {

      // 正常の場合、検索結果を設定
      setBalanceStatementStore(response);

    }).catch((error: any) => {

      // エラーの場合、検索結果を空に設定
      setBalanceStatementStore([]);
      setErrorMessage(getErrorMessageForResponse(error));

    }).finally(() => {

      setIsLoading(false);
    });
  }, [orgCode, monthlyStartDate, categoryAry]);

  return {
    balanceStatementStore, setBalanceStatementStore, getIsLoading, listHeader, setIsLoading, errorMessage
  };
};

export default useBalanceStatementStore;
