/** @jsx jsx */
import React from 'react';
import { grayScale } from 'components/styles';
import Accordion from 'components/molecules/Accordion';
import DataTable from 'components/organismos/DataTable/DataTable';
import Modal from 'components/molecules/Modal';
import TabControl from 'components/atoms/TabControl';
import Table from 'components/molecules/Table';
import moment from 'moment';
import PrimaryButton from 'components/atoms/Button';
import { css, jsx } from '@emotion/core';
import { useApplyingTransferForm } from '../ApplyingTab/hook';
import { IchangeLog } from '../interface';
import RejectedStampDomain from '../domain/rejectedStampDomain';

const StampAccordion: React.FC<{ list: RejectedStampDomain[] }> = ({
  list,
}) => {
  const {
    changeLogData,
    setChangeLogData,
    changeLogModalOpen,
    setChangeLogModalOpen,
    selectChangelog,
    setSelectChangelog,
    fetchChangeLog,
  } = useApplyingTransferForm(list);

  const columns = React.useMemo(
    () => [
      {
        Header: '否決日時',
        accessor: 'approvalDate',
      },
      {
        Header: '否決者',
        accessor: 'authorizer',
      },
      {
        Header: '組織名',
        accessor: 'orgName',
      },
      {
        Header: '申請者',
        accessor: 'applicant',
      },
      {
        Header: '申請日時',
        accessor: 'applicationDate',
      },
      {
        Header: '対象者',
        accessor: 'targetPerson',
      },
      {
        Header: () => '対象日付',
        id: '',
        accessor: 'targetDate',
        Cell: (cell: {
          row: { isExpanded: boolean; original: any; index: number };
          data: any;
        }) => (
          <div style={{ textAlign: 'center' }}>
            <div
              css={css`
                button {
                  background-color: #f0ad4e;
                  border-color: #e08e0b;
                  padding: 0.2rem;
                  line-height: 14px;
                }
              `}
            >
              <div>{cell.row.original.targetDate}</div>
              <PrimaryButton
                text="更新履歴"
                onClick={() => {
                  fetchChangeLog(cell.row.original.modifierApplicationId);
                  setChangeLogModalOpen(true);
                  setSelectChangelog('0');
                }}
              />
            </div>
          </div>
        ),
      },
      {
        Header: '申請種別',
        accessor: 'modifiyStatusName',
      },
      {
        Header: '出勤店舗',
        accessor: 'stampOrgName',
      },
      {
        Header: '開始時間', // starttime
        accessor: 'stampListStartTime', // .startTime
        Cell: (cell: { row :{ isExpanded: boolean, original: any }}) => (
          <div style={{ textAlign: 'left', whiteSpace: 'pre' }}>
            {cell.row.original.stampListStartTime}
          </div>
        ),
      },
      {
        Header: '終了時間', // endtime
        accessor: 'stampListEndTime', // .endTime
        Cell: (cell: { row :{ isExpanded: boolean, original: any }}) => (
          <div style={{ textAlign: 'left', whiteSpace: 'pre' }}>
            {cell.row.original.stampListEndTime && cell.row.original.stampListEndTime}
          </div>
        ),
      },
      {
        Header: '業務内容', // business content
        accessor: 'stampListBusinessName', // .businessName
        Cell: (cell: { row :{ isExpanded: boolean, original: any }}) => (
          <div style={{ textAlign: 'left', whiteSpace: 'pre' }}>
            {cell.row.original.stampListBusinessName}
          </div>
        ),
      },
      {
        Header: '否決者コメント',
        accessor: 'rejectReason',
      },
    ],
    [fetchChangeLog, setChangeLogModalOpen, setSelectChangelog],
  );

  return (
    <React.Fragment>
      <div
        css={css`
      table th:nth-child(1) {
        width: 120px;
      }
      table th:nth-child(2) {
        width: 140px;
      }
      table th:nth-child(3) {
        width: 140px;
      }
      table th:nth-child(4) {
        width: 140px;
      }
      table th:nth-child(5) {
        width: 120px;
      }
      table th:nth-child(6) {
        width: 140px;
      }
      table th:nth-child(7) {
        width: 80px;
      }
      table th:nth-child(8) {
        width: 80px;
      }
      table th:nth-child(9) {
        width: 120px;
      }
      table th:nth-child(10) {
        width: 120px;
      }
      table th:nth-child(11) {
        width: 120px;
      }
      table th:nth-child(12) {
        width: 80px;
      }
      table th:nth-child(13) {
        width: 200px;
      }
`}
      >
        <Accordion
          text={`打刻修正申請（${list.length}件）`}
          color={grayScale.gray100}
          callBack={() => {}}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <DataTable columns={columns} data={list} isGlobalFilter={true} minWidth="1664px" sortBy={[{id : 'approvalDate', desc: true}]} />
          </div>
        </Accordion>

        {/* Change log  */}
        <div css={css('>div>div{ max-width: 600px }')}>
          <Modal
            open={changeLogModalOpen}
            closeHandler={() => {
              setChangeLogModalOpen(false);
              setChangeLogData([]);
            }}
            title="更新履歴"
            submitText="閉じる"
            onSubmit={() => {
              setChangeLogModalOpen(false);
              setChangeLogData([]);
            }}
            isShowButtonClose={false}
          >
            <TabControl
              items={changeLogData.map((log: IchangeLog, index: number) => ({
                value: String(index),
                display: `ver${log.version}`,
              }))}
              setValue={setSelectChangelog as (arg: string) => void}
              currentValue={selectChangelog}
              isFixed={true}
            />
            <div>
              {changeLogData.length > 0 && (
              <React.Fragment>
                <div>
                  {`申請種別: ${
                    changeLogData[Number(selectChangelog)].historyTypeName
                  }`}
                </div>
                <div>
                  {
                    changeLogData[Number(selectChangelog)].applicationStaffCode
                      ? `申請者: ${
                        changeLogData[Number(selectChangelog)].applicationStaffCode
                      } ${
                        changeLogData[Number(selectChangelog)].applicationStaffName
                      }`
                      : null
                  }
                </div>
                <div>
                  {
                    changeLogData[Number(selectChangelog)].applicationDate
                      ? `申請日時: ${moment(
                        changeLogData[Number(selectChangelog)].applicationDate,
                      ).format('YYYY/MM/DD HH:mm:ss')}`
                      : null
                  }
                </div>
                <div>
                  {`出勤店舗: ${changeLogData[Number(selectChangelog)].orgCode} ${
                    changeLogData[Number(selectChangelog)].orgName
                  }`}
                </div>

                <Table>
                  <thead>
                    <tr>
                      <Table.HeaderCell customStyle={css({ width: '33%' })}>
                        開始時間
                      </Table.HeaderCell>
                      <Table.HeaderCell customStyle={css({ width: '33%' })}>
                        終了時間
                      </Table.HeaderCell>
                      <Table.HeaderCell customStyle={css({ width: '33%' })}>
                        業務内容
                      </Table.HeaderCell>
                    </tr>
                  </thead>
                  <tbody>
                    {changeLogData[Number(selectChangelog)].stampList.length
                      > 0
                      && changeLogData[Number(selectChangelog)].stampList.map(
                        (stamp, i) => (
                          <tr key={i}>
                            <Table.Cell customStyle={css({ textAlign: 'center' })}>
                              {moment(stamp.startTime).format(
                                'YYYY/MM/DD HH:mm:ss',
                              )}
                            </Table.Cell>
                            <Table.Cell customStyle={css({ textAlign: 'center' })}>
                              {
                                stamp.endTime
                                  ? moment(stamp.endTime).format(
                                    'YYYY/MM/DD HH:mm:ss',
                                  )
                                  : null
                              }
                            </Table.Cell>
                            <Table.Cell>{stamp.businessName}</Table.Cell>
                          </tr>
                        ),
                      )}
                  </tbody>
                </Table>
                <div>
                  {
                    changeLogData[Number(selectChangelog)].applicationReason
                      ? `修正理由: ${
                        changeLogData[Number(selectChangelog)].applicationReason
                      }`
                      : null
                  }
                </div>

                <div>
                  {
                    changeLogData[Number(selectChangelog)].rejectReason
                      ? `否決理由: ${
                        changeLogData[Number(selectChangelog)].rejectReason
                      }`
                      : null
                  }
                </div>

                {changeLogData[Number(selectChangelog)].approvalDate && (
                  <div>
                    {`否決日時: ${moment(
                      changeLogData[Number(selectChangelog)].approvalDate,
                    ).format('YYYY/MM/DD HH:mm:ss')}`}
                  </div>
                )}

                {changeLogData[Number(selectChangelog)].approvalStaffCode && (
                  <div>
                    {`否決者: ${changeLogData[Number(selectChangelog)].approvalStaffCode} ${changeLogData[Number(selectChangelog)].approvalStaffName}`}
                  </div>
                )}
              </React.Fragment>
              )}
            </div>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StampAccordion;
