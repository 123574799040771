/* eslint-disable no-return-await */
/** @jsx jsx */
import { useState, useMemo, useEffect } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import moment from 'moment';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import FormField from 'components/atoms/Form/FormField';
import DatePicker from 'components/molecules/DatePicker';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import SelectForm from 'components/molecules/SelectForm';
import StampHistoryDomain from 'domain/master/atendenceManagement/stampHistory';
import useToastNotification from 'hooks/useToastNotification';
import { getSearchDateClosing } from 'api/stampHistory';
import { useStaffOptions } from 'hooks/useEmploymentOptions';
import ClickableLink from 'components/atoms/ClickableLink';
import BlockUI from 'components/molecules/BlockUi';
import { css, jsx } from '@emotion/core';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import useGpsStampHistory from './hooks';

const GpsStampHistoryLayout = () => {
  const d = new Date();
  const y = d.getFullYear();
  const m = d.getMonth();
  const datefrom = (new Date(y, m, 1));
  const dateTo = (new Date(y, m + 1, 0));

  const targetDateFromstr = moment(datefrom).format('YYYY/MM/DD');
  const targetDateToStr = moment(dateTo).format('YYYY/MM/DD');

  const [targetDateFrom, setTargetDateFrom] = useState(new Date(y, m, 1));
  const [targetDateTo, setTargetDateTo] = useState(new Date(y, m + 1, 0));

  //
  const functionType = 2;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
    undefined,
  );
  const [orgCode, setorgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || '');

  const staffOptions = useStaffOptions(
    orgCode,
    targetDateFrom,
    targetDateTo,
    functionType,
    categoryAry,
  );

  useEffect(() => {
    const currenStaff = staffOptions.find(item => item.value === sessionStorage.getItem('loginUser.staffCode'))
    if (!currenStaff) {
      setStaffCode('all')
    } else {
      setStaffCode(currenStaff.value)
    }
  }, [staffOptions])

  useEffect(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getSearchDateClosing(companyCode).then((data) => {
      if (targetDateFromstr !== data[0]) {
        setTargetDateFrom(new Date(data[0]));
      }
      if (targetDateToStr !== data[1]) {
        setTargetDateTo(new Date(data[1]));
      }
      if (data[0] == null || data[1] == null) {
        setTargetDateFrom(new Date(datefrom));
        setTargetDateTo(new Date(dateTo));
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const { errorNotification } = useToastNotification();


  const [staffCode, setStaffCode] = useState('');

  const { gpsStampHistory, blocking } = useGpsStampHistory(
    orgCode || '',
    staffCode,
    moment(targetDateFrom).format('YYYY年MM月DD日'),
    moment(targetDateTo).format('YYYY年MM月DD日'),
    categoryAry,
  );

  // 対象日の範囲が１年を超えていた場合、エラーとする
  const [stampHistoryNull, setstampHistoryNull] = useState(true);
  useEffect(() => {
    const startDay = new Date(Date.parse(moment(targetDateFrom, 'YYYY年MM月DD日').format('YYYY/MM/DD')));
    const endDay = new Date(moment(targetDateTo, 'YYYY年MM月DD日').format('YYYY/MM/DD'));
    const startDayNextYear = new Date(
      startDay.getFullYear() + 1, startDay.getMonth(), startDay.getDate(),
    );
    if (endDay.getTime() >= startDayNextYear.getTime()) {
      errorNotification('期間の範囲は１年以内で入力してください');
      setstampHistoryNull(false);
    } else {
      setstampHistoryNull(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDateFrom, targetDateTo]);


  const columns: Array<Column<StampHistoryDomain>> = useMemo(() => [
    {
      Header: '店舗コード',
      accessor: 'orgCode',
    },
    {
      Header: '組織名',
      accessor: 'orgName',
    },
    {
      Header: 'スタッフコード',
      accessor: 'staffCode',
    },
    {
      Header: '氏名',
      accessor: 'staffName',
    },
    {
      Header: '打刻種別',
      accessor: 'gpsStampKind',
    },
    {
      Header: '打刻時刻',
      accessor: 'workTimeStr',
    },
    {
      Header: '打刻住所',
      id: 'address',
      accessor: 'address',
      Cell: (cell:{ row:{isExpanded: boolean, original: any }, page:[]}) => {
        const { address } = cell.row.original;
        const { latitude } = cell.row.original;
        const { longitude } = cell.row.original;

        // eslint-disable-next-line no-shadow
        const openMapWindow = (latitude: string, longitude: string) => {
          let strUrl = '';
          strUrl += './map.html';
          strUrl += `?latitude=${latitude}`;
          strUrl += `&longitude=${longitude}`;

          let winPara = 'directories = no,';
          winPara += 'fullscreen = no,';
          winPara += 'location = no,';
          winPara += 'menubar = no,';
          winPara += 'scrollbars = no,';
          winPara += 'status = no,';
          winPara += 'titlebar = no,';
          winPara += 'toolbar = no,';
          winPara += 'resizable = yes,';
          winPara += 'width = 1024,';
          winPara += 'height = 768';

          window.open(strUrl, 'map', winPara);
        };

        return (
          <div>
            <ClickableLink onClick={
              () => openMapWindow(latitude, longitude)
              }
            >
              {address}

            </ClickableLink>
          </div>
        );
      },
    },
  ], []);

  return (
    <BlockUI blocking={blocking}>
      <div>
        <FormContents>
          <FormField>
            <FlexBox>
              <FlexBoxItem width="100px">
                <FormLabel
                  label="期間"
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <DatePicker
                  dateFormat="yyyy年MM月dd日"
                  date={targetDateFrom}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      setTargetDateFrom(date);
                    }
                  }}
                  isFullWidth={true}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <span style={{ margin: '0 30px' }}>～</span>
              </FlexBoxItem>
              <FlexBoxItem>
                <DatePicker
                  dateFormat="yyyy年MM月dd日"
                  date={targetDateTo}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      setTargetDateTo(date);
                    }
                  }}
                  isFullWidth={true}
                />
              </FlexBoxItem>
            </FlexBox>
          </FormField>

          <OrganizationFilteredTree
            functionType={functionType}
            orgCallback={(args: string | Array<string>) => {
              setorgCode(String(args));
            }}
            orgCategoryCallback={
              (args: Array<string>) => {
                setCategoryAr(args);
              }
            }
            targetdayForm={targetDateFrom}
            targetdayTo={targetDateTo}
            initOrgValue={String(orgCode)}
            staffCode={loginStaffCode}
            orgLabel="組織名"
            addAllItem={true}
          />

          <FormField displayBlock={true}>
            <SelectForm
              label="スタッフ"
              name="staffCode"
              value={String(staffCode)}
              setValue={(v) => { setStaffCode(v); }}
              options={staffOptions}
              description=""
            />
          </FormField>

          <div
            css={css`
              table th:nth-child(1) {
                width: 80px;
              }
              table th:nth-child(2) {
                width: 100px;
              }
              table th:nth-child(3) {
                width: 120px;
              }
              table th:nth-child(4) {
                width: 100px;
              }
              table th:nth-child(5) {
                width: 80px;
              }
              table th:nth-child(6) {
                width: 140px;
              }
              table th:nth-child(7) {
                width: 600px;
              }
            `}
          >

            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
              <DataTable
                columns={columns}
                data={stampHistoryNull ? gpsStampHistory : []}
                isGlobalFilter={true}
                minWidth="1276px"
                sortBy={[
                  { id: 'workTimeStr', desc: true }
                ]}
              />
            </div>
          </div>

        </FormContents>
      </div>
    </BlockUI>
  );
};

export default GpsStampHistoryLayout;
