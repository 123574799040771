import moment from 'moment';
import ApiClient from './ApiClient';

export const downloadCSV = async (
  orgCode: string,
  targetMonth: any,
  categoryAry: any,
  fileName: string,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const formatTargetMonth = moment(targetMonth).format('YYYY/MM/DD');
  const functionType = 3;
  const params = {
    functionType,
    loginStaffCode,
    orgCode,
    targetMonth: formatTargetMonth,
  };
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  await ApiClient.downloadCsv(`/v3/pettyCash/outputCsv/${companyCode}?${appendUrl}`, params, getDownloadCsvFileName(`${fileName}_`));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export const getDateSelect = async (dateTarget: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const formatDate = moment(dateTarget).startOf('month').format('YYYY/MM/DD');
  const params = {
    targetMonth: formatDate,
  };
  const response = await ApiClient.get(`/v2/dateSelect/${companyCode}`, params);
  return response.data;
};

export const getDescriptionMST = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/descriptionMst/list/${companyCode}`, {});
  return response.data;
};

export const getHeadPettyCash = async (dateTarget: any, orgCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const formatDate = moment(dateTarget).startOf('month').format('YYYY/MM/DD');
  const params = {
    targetMonth: formatDate,
    orgCode,
  };
  const response = await ApiClient.get(`/v2/pettyCash/head/${companyCode}`, params);
  return response.data;
};

export const getListPettyCash = async (pettyCashId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    pettyCashId,
  };
  const response = await ApiClient.get(`/v2/pettyCash/list/${companyCode}`, params);
  return response.data;
};

export const savePettyCashHead = async (
  pettyCashId: string,
  orgCode: string,
  dateTarget: any,
  beginningBalance: number,
  endingBalance: number,
): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const formatDate = moment(dateTarget).startOf('month').format('YYYY/MM/DD');
  const params = {
    companyCode,
    createUser: staffCode,
    updateUser: staffCode,
    pettyCashId,
    orgCode,
    targetMonth: formatDate,
    beginningBalance,
    endingBalance,
  };
  const response = await ApiClient.post(`/v2/pettyCashHead/save/${companyCode}`, {}, params);
  return response.data;
};

export const savePettyCashDetail = async (
  pettyCashId: string,
  pettyCashDetailId: string,
  orgCode: string,
  targetDate: any,
  descriptionId: string,
  depositFlag: number,
  amount: number,
  payee: string,
  remark: string,
): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const pettyCashDetail = {
    companyCode,
    createUser: staffCode,
    updateUser: staffCode,
    pettyCashId,
    pettyCashDetailId,
    orgCode,
    targetDate,
    descriptionId,
    depositFlag,
    amount,
    payee,
    remark,
  };
  const response = await ApiClient.post(`/v2/pettyCashDetail/save/${companyCode}`, {}, pettyCashDetail);
  return response.data;
};

export const deletePettyCashDetail = async (pettyCashDetailId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    loginStaffCode: staffCode,
    pettyCashDetailId,
  };
  const response = await ApiClient.delete(`/v2/pettyCashDetail/delete/${companyCode}`, params);
  return response.data;
};

export type Error = {
  error: string;
  defaultMessage: string;
}

export default { };
