import React from 'react';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormContents from 'components/atoms/Form/FormContents';

const DetailClosedForm: React.FC<{
  paidCalculationStandard: boolean;
  setPaidCalculationStandard: (paidCalculationStandard: boolean) => void;
  unUsableOfTrialPeriod: boolean;
  setUnUsableOfTrialPeriod: (unUsableOfTrialPeriod: boolean) => void;
}> = ({
  paidCalculationStandard, setPaidCalculationStandard,
  unUsableOfTrialPeriod, setUnUsableOfTrialPeriod,
}) => (
  <FormContents isSubForm={true}>
    <FormTitle
      title="休業詳細設定"
      note=""
    />
    <FormField>
      <RadioSelectForm
        label="有給算出基準：勤務日数への加算"
        subLabel=""
        items={[
          {
            label: '加算しない',
            value: '0',
          },
          {
            label: '加算する',
            value: '1',
          },
        ]}
        name="paidCalculationStandard"
        value={paidCalculationStandard ? '1' : '0'}
        setValue={(e) => {
          setPaidCalculationStandard(e.target.value !== '0');
        }}
      />
    </FormField>

    <FormField>
      <RadioSelectForm
        label="試用期間中の休業使用可否"
        subLabel=""
        items={[
          {
            label: '使用不可',
            value: '1',
          },
          {
            label: '使用可',
            value: '0',
          },
        ]}
        name="unUsableOfTrialPeriod"
        value={unUsableOfTrialPeriod ? '1' : '0'}
        setValue={(e) => { setUnUsableOfTrialPeriod(e.target.value !== '0'); }}
      />
    </FormField>
  </FormContents>
);
export default DetailClosedForm;
