/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Amplify, { Auth } from 'aws-amplify';
import awsExports from 'aws-exports'; // 設定情報を読み込みます。

import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import Button, { PrimaryButton } from 'components/atoms/Button';
import { useHistory, Link } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';
import { handleHelpUrlForScreen } from 'api/login';
import { isMobileOnly } from 'react-device-detect';

const styles = {
  canselButton: css({
    marginRight: '3em auto',
  }),
  h1: css({
    textAlign: 'left',
    padding: '0 0',
    fontSize: '0.75em',
  }),
  form: css({
    padding: '0 1.5em',
  }),
  standardLogo: css({
    marginLeft: 'auto',
    marginRight: 'auto',
    verticalAlign: 'middle',
    width: '100px',
    height: '100px',
    padding: '8px 12px',
  }),
  formFotter: css({
    padding: '0.5em 0.5em',
    textAlign: 'right',
    marginTop: isMobileOnly ? '0px' : '-30px',
  }),
  formHeader: css({
    padding: '0.5em 0.5em',
    textAlign: 'center',
  }),
  'formFotter a': css({
    textDecoration: 'none',
    color: '#0070d2',
    fontSize: '0.75em',
  }),
  'formHeader a': css({
    textDecoration: 'none',
    color: '#0070d2',
  }),
};

Amplify.configure(awsExports); // 設定情報をAmplifyに反映させます。

const ActivationSchema = Yup.object().shape({
  email: Yup.string()
    .email('形式がemailではありません')
    .required('メールアドレスを入力してください'),
});

const ForgotPasswordInputForm: React.FC = () => {
  const { successNotification, errorNotification } = useToastNotification();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      Auth.forgotPassword(formik.values.email)
        .then((error : any) => {
          console.log('認証コード送信成功');
          successNotification(`${formik.values.email} に認証コードを送信しました`);
          history.push(`/forgotPasswordSubmit/${formik.values.email}`);
        })
        .catch((err : any) => {
          console.error(err);
          if (err.code === 'UserNotFoundException') {
            errorNotification('入力されたメールアドレスのユーザーは登録されておりません');
          } else {
            errorNotification('認証コードの送信に失敗しました');
          }
        });
    },
    validationSchema: ActivationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <p css={styles.h1}>
        パスワードをリセットするには、メールアドレスを入力して下さい。
        <br />
        入力されたメールアドレスが正しい場合、パスワードをリセットする認証コードが届きます。
      </p>
      <FormField>
        <TextForm
          name="email"
          label="メールアドレス"
          value={formik.values.email}
          onChange={formik.handleChange}
          errorMsg={formik.errors.email}
        />
      </FormField>

      <FormSubmitArea>
        <Button
          text="次へ"
          onClick={formik.handleSubmit}
        />
        <Link to="/login">
          <PrimaryButton
            ghost={true}
            text="戻る"
          />
        </Link>
      </FormSubmitArea>
      <div css={styles.formFotter}>
        <Link onClick={() => handleHelpUrlForScreen('パスワードリセット')} to="#" css={styles['formFotter a']}>
          ヘルプサイトへ
        </Link>
      </div>
    </form>
  );
};

export default ForgotPasswordInputForm;
