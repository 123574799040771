import {
  useEffect, useCallback, useState, useMemo,
} from 'react';
import { getSalesSuppliersList, deleteSalesSuppliers, downloadCsv } from 'api/salesSuppliersMST';
import useToastNotification from 'hooks/useToastNotification';
import SalesSuppliersMSTDomain from 'domain/master/general/salesSuppliersMST';

export const useSalesSuppliersMSTList = () => {
  const [salesSuppliersMSTList, setSalesSuppliersMSTList] = useState<Array<SalesSuppliersMSTDomain>>([]);
  const [blocking, setBlocking] = useState(false);
  // データの取得
  const fetchData = useCallback(async () => {
    setBlocking(true);
    try {
      const response = await getSalesSuppliersList();
      setSalesSuppliersMSTList(response.map((result: any) => new SalesSuppliersMSTDomain(result)));
    // eslint-disable-next-line no-empty
    } catch (error) {
      setSalesSuppliersMSTList([]);
    }
    setBlocking(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    salesSuppliersMSTList, setSalesSuppliersMSTList, blocking, fetchData,
  };
};

export const useSalesSuppliersDelete = (fetchData: () => Promise<void>) => {
  const [deleteSalesSuppliersTarget, setDeleteSalesSuppliersTarget] = useState<SalesSuppliersMSTDomain|null>(null);
  const { successNotification, errorNotification } = useToastNotification();
  const isOpenDeleteModal = useMemo(() => !!deleteSalesSuppliersTarget, [deleteSalesSuppliersTarget]);
  const closeDeleteModal = useCallback(() => setDeleteSalesSuppliersTarget(null), []);

  const onSubmitDeleteSalesSuppliers = useCallback(() => {
    if (!deleteSalesSuppliersTarget) {
      return;
    }
    deleteSalesSuppliers(deleteSalesSuppliersTarget.transactionType, deleteSalesSuppliersTarget.suppliersCode).then(() => {
      setDeleteSalesSuppliersTarget(null);
      successNotification('削除しました。');
      fetchData();
    }).catch((error) => {
      setDeleteSalesSuppliersTarget(null);
      if (error.response && error.response.data && error.response.data.defaultMessage) {
        errorNotification(error.response.data.defaultMessage);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [deleteSalesSuppliersTarget, errorNotification, fetchData, successNotification]);

  return {
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteSalesSuppliers,
    deleteSalesSuppliersTarget,
    setDeleteSalesSuppliersTarget,
  };
};

export const useDownload = () => {
  const downloadSuppliersCsv = () => {
    downloadCsv('取引先マスタ.csv');
  };

  return {
    downloadSuppliersCsv,
  };
};


export default { useSalesSuppliersMSTList, useSalesSuppliersDelete, useDownload };
