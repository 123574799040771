import React, { SVGProps } from 'react';

const Lightbulb: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M10 2.375a5.625 5.625 0 013.126 10.302L13.125 14c0 .09-.019.174-.053.251.034.076.053.16.053.249v.5c0 .464-.194.882-.506 1.178a2.625 2.625 0 01-5.238 0A1.616 1.616 0 016.875 15v-.5c0-.089.019-.173.052-.25a.623.623 0 01-.052-.25v-1.322A5.625 5.625 0 0110 2.375zm1.225 14.25h-2.45a1.375 1.375 0 002.45 0zm.65-2h-3.75V15c0 .181.129.332.3.367l.075.008h3a.375.375 0 00.375-.375v-.375zM10 3.625a4.375 4.375 0 00-2.187 8.165.625.625 0 01.312.54v1.045h3.75l.001-1.045c0-.223.12-.429.312-.54A4.375 4.375 0 0010 3.625z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Lightbulb;
