import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import OrganizationManagerEditForm from 'components/organismos/organization/OrganizationManagerEditForm';
// import PrimaryButton from 'components/atoms/Button';
// import FlexBox from 'components/atoms/FlexBox';
// import FlexBoxItem from 'components/atoms/FlexBoxItem';

import { useOrganizationManagers } from './hooks';

const OrganizationManagersPage: React.FC = () => {
  const {
    state, setManagerList, setSeniorManagerList,
  } = useOrganizationManagers();
  return (
    <SidebarTemplate
      pageTitle="マネージャー設定"
    >
      <OrganizationManagerEditForm
        managerList={state.managerList}
        seniorManagerList={state.seniorManagerList}
        setManagerList={setManagerList}
        setSeniorManagerList={setSeniorManagerList}
      />

      {/* <FlexBox>
        <FlexBoxItem marginLeft="16px">
          <PrimaryButton
            text="設定を保存"
            onClick={handler.onSubmit(false)}
          />
        </FlexBoxItem>
        <FlexBoxItem marginLeft="8px">
          <PrimaryButton
            text="保存して特賃日を設定"
            onClick={handler.onSubmit(true)}
          />
        </FlexBoxItem>
      </FlexBox> */}
    </SidebarTemplate>
  );
};

export default OrganizationManagersPage;
