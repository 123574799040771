import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import { useParams } from 'react-router-dom';
import BusinessCreateForm from 'components/organismos/master/labor/BusinessCreateForm';

const BusinessCreatePage: React.FC = () => {
  const { businessId } = useParams();

  return (
    <SidebarTemplate pageTitle={businessId ? '業務編集' : '業務作成'}>
      <BusinessCreateForm />
    </SidebarTemplate>
  );
};
export default BusinessCreatePage;
