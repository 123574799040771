import { useEffect, useCallback, useState } from 'react';
import { getStampHistory, getStampHistoryV3 } from 'api/stampHistory';
import StampHistoryDomain from 'domain/master/atendenceManagement/stampHistory';


export const useStampHistory = (orgCode: string, targetDateFrom: string, targetDateTo: string) => {
  const [stampHistory, setstampHistory] = useState<Array<StampHistoryDomain>>([]);
  const [blocking, setBlocking] = useState(false);

  // データの取得
  const fetchData = useCallback(async () => {
    setBlocking(true);
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await getStampHistory(companyCode, orgCode, targetDateFrom, targetDateTo);
    console.log(`response = ${String(response)}`);
    setstampHistory(response.map((result: any) => new StampHistoryDomain(result)));
    setBlocking(false);
  }, [orgCode, targetDateFrom, targetDateTo]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { stampHistory, setstampHistory, blocking };
};

export const useStampHistoryV2 = (orgCode: string, targetDateFrom: string, targetDateTo: string, functionType: number, categoryAry: Array<Array<string>>) => {
  const [stampHistory, setstampHistory] = useState<Array<StampHistoryDomain>>([]);
  const [blocking, setBlocking] = useState(false);

  // データの取得
  const fetchData = useCallback(async () => {
    setBlocking(true);
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    // const response = await getStampHistory(companyCode, orgCode, targetDateFrom, targetDateTo);
    // const response = await getStampHistoryV2(companyCode, orgCode, targetDateFrom, targetDateTo, functionType, categoryAry, staffCode);
    const response = await getStampHistoryV3(companyCode, orgCode, targetDateFrom, targetDateTo, functionType, categoryAry, staffCode);
    setstampHistory(response.map((result: any) => new StampHistoryDomain(result)));
    setBlocking(false);
  }, [categoryAry, functionType, orgCode, targetDateFrom, targetDateTo]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { stampHistory, setstampHistory, blocking };
};
export default { useStampHistory, useStampHistoryV2 };
