/** @jsx jsx */

import React, {
  useRef, useLayoutEffect, useState, useEffect,
} from 'react';
import { css, jsx } from '@emotion/core';
import Link from '../Icon/Link';
import { isMacOs } from 'react-device-detect';

const styles = {
  wrapper: css({
    position: 'relative',
  }),
  externalLink: css({
    position: 'absolute',
    right: isMacOs ? 0 : '16px' ,
    top: '2px',
    transform: 'scale(0.75)',
    cursor: 'pointer',
    svg: {
      path: {
        fill: 'blue !important',
      },
    },
  }),
};
const urlRegex = new RegExp('(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})');

const TextareaAutoResize: React.FC<{
  rows?: number;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  name?: string;
  showExternalLink?: boolean;
}> = ({
  rows = 2, value = '', onChange, name, showExternalLink = false,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const textareaLineHeight = 13;
  const maxRows = 10;
  const minHeight = 33;
  const [showLinks, setShowLinks] = useState(false);
  const linkRef = useRef<string>('');

  useEffect(() => {
    if (!showExternalLink) {
      return;
    }
    if (urlRegex.test(value) && urlRegex.exec(value)) {
      const links = urlRegex.exec(value);
      if (links) {
        let result = links[0];
        if (value?.includes('https') && !result.includes('https')) {
          result = `https://${result}`;
        } else if (value?.includes('http') && !result.includes('http')) {
          result = `http://${result}`;
        }
        if (!result.includes('http')) {
          result = `http://${result}`;
        }
        linkRef.current = result;
        setShowLinks(true)
      } else {
        linkRef.current = '';
        setShowLinks(false)
      }
    } else {
      linkRef.current = '';
      setShowLinks(false)
    }
  }, [showExternalLink, value]);

  useLayoutEffect(() => {
    if (textareaRef.current) {
      const element = textareaRef.current;
      // element.rows = rows; // reset number of rows in textarea
      element.style.height = 'inherit';
      const currentRows = Math.ceil((element.scrollHeight - 20) / textareaLineHeight); // minus 20 padding top + bottom

      if (element.scrollHeight <= minHeight) {
        element.style.height = `${minHeight}px`;
        return;
      }

      // if (currentRows >= maxRows) {
      //   element.rows = maxRows;
      //   element.scrollTop = element.scrollHeight;
      //   return;
      // }

      element.style.height = `${element.scrollHeight}px`;
    }
  }, [value]);

  const openLink = () => {
    window.open(linkRef.current, '_blank');
  };
  return (
    <div
      css={css(styles.wrapper)}
      onFocus={() => {}}
    >
      <textarea
        ref={textareaRef}
        style={{
          boxSizing: 'border-box', width: '100%', marginTop: 0, maxWidth: '100%', paddingRight: '12px', overflowY: 'scroll'
        }}
        onChange={onChange}
        rows={rows}
        value={value}
        name={name}
      />
      {showLinks && linkRef.current && (
        <div aria-hidden="true" css={css(styles.externalLink)} onClick={openLink} title={linkRef.current}>
          <Link />
        </div>
      )}
    </div>
  );
};

export default TextareaAutoResize;
