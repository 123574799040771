import React, { useState, useEffect } from 'react';

// import FormTitle from 'components/atoms/Form/FormTitle';
// import ToggleSwitch from 'components/molecules/ToggleSwitch';
// import BodyText from 'components/atoms/BodyText';
// import YenInputBox from 'components/molecules/YenInputBox';
// import DatePicker from 'components/molecules/DatePicker';
// import Select from 'components/atoms/Select';
// import PrimaryButton from 'components/atoms/Button';

import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
// import OrganizationDomain from 'domain/organization';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
// import ClickableLink from 'components/atoms/ClickableLink';
import Icon from 'components/atoms/Icon';
import { iconColor } from 'components/styles';
import DataTable from 'components/organismos/DataTable/DataTable';
// import SelectForm from 'components/molecules/SelectForm';
import DatePickerForm from 'components/molecules/DatePickerForm';
import { Special } from 'api/organization';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import SelectFormVirtical from 'components/molecules/SelectFormVirtical';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import ClickableLink from 'components/atoms/ClickableLink';
import ClickableIconButton from 'components/atoms/ClickableIconButton';

import moment from 'moment';
import { useSpecialSalary } from './hooks';
import {useSelector} from 'react-redux';
import {Store} from 'modules/store';

const OrganizationSpecialSalaryEditForm: React.FC<{
  isEditMode?: boolean;
  state?: any;
  handleOnChange?: any;
  roleScreen?: any;
  dispatch?: any;
  orgSpecialList?: any;
  handleDeleteSpecialWageSetting?: any,
}> = ({ isEditMode = false, state, handleOnChange, roleScreen, dispatch, orgSpecialList, handleDeleteSpecialWageSetting }) => {
  const {
    // enableSpecialSalary, setEnableSpecialSalary,
    addSalary, setAddSalary,
    applyStartMonth, setApplyStartMonth,
    specialSalaryPatternId, setSpecialSalaryPatternId,
    specialSalaryPatternOptions,
    onSubmit, organizationDomain,
    confirmModalOpen, closeConfirmModal,
    onDeleteSpecialSalary,
    confirmDelModalOpen, closeConfirmDelModal,
    selectedSpecialSalary, setSelectedSpecialSalary
  } = useSpecialSalary(isEditMode);
  const [isHoverDelete, setIsHoverDelete] = useState(false);

  const stateRoleScreen = useSelector((state: Store) => state.auth.roleScreen);
  const userRole = !roleScreen ? (stateRoleScreen ? stateRoleScreen : null) : roleScreen;

  const columns: Array<Column<Special>> = React.useMemo(() => [
    {
      Header: '適用月',
      accessor: 'applyStartMonthStr',
    },
    {
      Header: 'パターンコード',
      accessor: 'specialSalaryPatternCode',
    },
    {
      Header: 'パターン名',
      accessor: 'specialSalaryPatternName',
    },
    {
      Header: '加算額',
      accessor: 'addSalary',
    },
    {
      Header: () => null,
      accessor: 'x',
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row: { isExpanded: boolean, original: Special, index: number } }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        return (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
            {
              userRole && userRole.editable === 1 && (
                <React.Fragment>
                  <FlexBoxItem>
                    <ClickableLink onClick={() => {
                      setApplyStartMonth(moment(cell.row.original.applyStartMonthStr, 'YYYY年MM月').startOf('month').toDate());
                      setSpecialSalaryPatternId(cell.row.original.specialSalaryPatternId);
                      setAddSalary(cell.row.original.addSalary);
                      setSelectedSpecialSalary(cell.row.original)
                    }}
                    >
                      <span
                        style={{ marginLeft: '20px' }}
                        onMouseEnter={() => setIsHoverEdit(true)}
                        onMouseLeave={() => setIsHoverEdit(false)}
                      >
                        <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
                      </span>
                    </ClickableLink>
                  </FlexBoxItem>
                  <FlexBoxItem>
                    <ClickableIconButton
                      onClick={() => handleDeleteSpecialWageSetting(cell.row.index)}
                    >
                      <span
                        onMouseEnter={() => setIsHoverDelete(true)}
                        onMouseLeave={() => setIsHoverDelete(false)}
                      >
                        <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                      </span>
                    </ClickableIconButton>
                  </FlexBoxItem>
                </React.Fragment>
              )}

          </div>
        );
      },
    },
  ], [setAddSalary, setApplyStartMonth, setSpecialSalaryPatternId, userRole, orgSpecialList]);

  useEffect(() => {
    dispatch({ type: 'applyStartMonth', payload: moment(applyStartMonth).startOf('month').toDate() })
    dispatch({ type: 'specialSalaryPatternId', payload: specialSalaryPatternId })
    dispatch({ type: 'addSalary', payload: addSalary })
    dispatch({ type: 'selectedOrgSpecialList', payload: selectedSpecialSalary })

  }, [applyStartMonth, specialSalaryPatternId, addSalary]);
  return (
    <>
      {/* <FormTitle
        title="特賃日設定"
      /> */}

      {orgSpecialList
        && orgSpecialList.length > 0 && (
          <DataTable
            columns={columns}
            data={orgSpecialList}
            sortBy={[
              {id: 'applyStartMonthStr', desc: true}
            ]}
          />
        )}

      {/* <ToggleSwitch
        name="specialSalaryToggleSwitch"
        onLabel="設定する"
        offLabel="設定しない"
        value="on"
        checked={enableSpecialSalary}
        onChange={() => {
          setEnableSpecialSalary(!enableSpecialSalary);
        }}
      /> */}

      {/* {enableSpecialSalary === true && ( */}
      <FormField>
        <div style={{ marginRight: '12px' }}>
          <DatePickerForm
            dateFormat="yyyy/MM"
            label="適用月"
            date={isEditMode ? moment(state?.selectedOrgSpecialList?.id?.applyStartMonth).startOf('month').toDate() : moment(state?.applyStartMonth).startOf('month').toDate()}
            changeDate={(date: Date) => {
              if (date !== null) {
                const cloneOrgSpecialList = { ...state.selectedOrgSpecialList }
                const newOrgSpecialList = {
                  ...cloneOrgSpecialList,
                  applyStartMonthStr: moment(date).startOf('month').format("YYYY年MM月"),
                  id: {
                    ...cloneOrgSpecialList.id,
                    applyStartMonth: moment(date).startOf('month').format("YYYY/MM/DD")
                  }
                }
                handleOnChange(newOrgSpecialList, 'selectedOrgSpecialList', newOrgSpecialList)
                handleOnChange(moment(date).startOf('month').format("YYYY/MM/DD"), 'applyStartMonth', moment(date).startOf('month').format("YYYY/MM/DD"))
              }
            }}
            showMonthYearPicker={true}
          />
        </div>
      </FormField>
      {/* )} */}

      {/* <BodyText>加算額</BodyText> */}

      {/* {enableSpecialSalary === true && ( */}
      {/* <YenInputBox
        name="addSalary"
        label="加算額"
        value={addSalary}
        onChange={(e) => setAddSalary(e.target.value)}
      /> */}
      {/* )} */}

      {/* // <BodyText>適用日</BodyText>
  //       <DatePicker
  //         date={applyStartMonth}
  //         changeDate={(date) => setApplyStartMonth(date)}
  //         showMonthYearPicker={true}
  //         dateFormat="yyyy/MM"
  //       /> */}

      {/* {enableSpecialSalary === true && ( */}
      <FormField>
        <div style={{ width: '100%' }}>
          <SelectFormVirtical
            label="特賃日パターン"
            name="specialSalaryPatternId"
            value={isEditMode ? state?.selectedOrgSpecialList?.specialSalaryPatternId : state?.specialSalaryPatternId}
            setValue={(val) => {
              const cloneOrgSpecialList = { ...state.selectedOrgSpecialList }
              const newOrgSpecialList = {
                ...cloneOrgSpecialList,
                specialSalaryPatternId: val
              }
              handleOnChange(newOrgSpecialList, 'selectedOrgSpecialList', newOrgSpecialList)
              handleOnChange(val, 'specialSalaryPatternId', val)
            }}
            options={specialSalaryPatternOptions}
          />
        </div>
        {/* )} */}
      </FormField>
      <FormField>
        <div style={{ width: '100px' }}>
          <TextForm
            name="addSalary"
            label="加算額"
            groupText="円"
            onChange={(e: any) => {
              const cloneOrgSpecialList = { ...state.selectedOrgSpecialList }
              const newOrgSpecialList = {
                ...cloneOrgSpecialList,
                addSalary: Number(e.target.value)
              }
              handleOnChange(newOrgSpecialList, 'selectedOrgSpecialList', newOrgSpecialList)
              handleOnChange(e.target.value, 'addSalary', e.target.value)
            }}
            value={isEditMode ? String(state?.selectedOrgSpecialList?.addSalary) : String(state?.addSalary)}
            type="number"
            min="0"
            max="99999"
            maxLength={5}
          />
        </div>
      </FormField>
      {/* // <BodyText>特賃日パターン</BodyText>
  //       <Select
  //         name="specialSalaryPatternId"
  //         value={specialSalaryPatternId}
  //         setValue={setSpecialSalaryPatternId}
  //         options={specialSalaryPatternOptions}
  //       /> */}

      {/* <FormSubmitArea>
        {
          userRole !== null && userRole.editable === 1 && (
          <React.Fragment>
            <div style={{ marginRight: '12px' }}>
              <Button
                text={isEditMode ? '更新' : '特賃日情報を登録'}
                onClick={onSubmit}
              />
            </div>
            {
              isEditMode
                ? (
                  <div style={{ marginRight: '12px' }}>
                    <Button
                      text="特賃日情報を削除"
                      onClick={onDeleteSpecialSalary}
                    />
                  </div>
                )
                : null
            }
          </React.Fragment>
        )}
        <Link to="/organizations">
          <Button
            text="戻る"
            ghost={true}
          />
        </Link>
      </FormSubmitArea>
      <ConfirmActionModal
        open={confirmDelModalOpen}
        closeHandler={closeConfirmDelModal}
        onSubmit={onDeleteSpecialSalary}
        actionType={ActionType.DELETE}
      />
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={onSubmit}
        actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
      /> */}
    </>
  );
};

export default OrganizationSpecialSalaryEditForm;
