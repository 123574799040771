import React, { SVGProps } from 'react';

const ChevronUp: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path d="M9.942 8.605l4.558 4.558.884-.884-5.442-5.442L4.5 12.28l.884.884z" fill="#333" />
    </g>
  </svg>
);

export default ChevronUp;
