/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { grayScale, textColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import FlexBox from 'components/atoms/FlexBox';
import BodyText from 'components/atoms/BodyText';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Separater from 'components/atoms/Separator';
import HourInput from 'components/atoms/Form/HourInput';
import { SalarySupportStaff } from 'domain/master/general/salaryDataStaff';
import { useOrganizationSupportEdit } from './hooks';

const OrganizationSupportEdit: React.FC<{
  isShowHourlyWageAddition: boolean,
  support: SalarySupportStaff;
  setSupport: (manager: SalarySupportStaff) => void;
  deleteSupport: () => void;
}> = ({
  isShowHourlyWageAddition, support, deleteSupport, setSupport,
}) => {
  const {
    addHourSalary,
    supportTransportation,
    changeAddHourlySalary,
    changeSupportTransportation,
  } = useOrganizationSupportEdit(support, setSupport);
  return (
    <React.Fragment>
      <FlexBox>
        <FlexBoxItem>
          <Icon type="organization" color={grayScale.gray100} size="30.5px" />
        </FlexBoxItem>

        <FlexBoxItem grow={1} marginLeft="4px">
          <FlexBox>
            <FlexBoxItem>
              <BodyText>
                {support.orgName}
              </BodyText>
            </FlexBoxItem>

            <FlexBoxItem marginLeft="auto">
              <IconLabelButton
                iconType="delete"
                text="削除"
                onClick={deleteSupport}
                padding=""
              />
            </FlexBoxItem>
          </FlexBox>
          <BodyText size="sm" color={textColor.subText01}>
            {support.orgCode}
          </BodyText>
        </FlexBoxItem>
      </FlexBox>

      <FlexBox>
        <FlexBoxItem basis="10%">
          <BodyText>時給加算額</BodyText>
        </FlexBoxItem>
        <FlexBoxItem basis="30%">
          {isShowHourlyWageAddition && (
          <HourInput
            name="addHourlySalary"
            value={addHourSalary}
            label="円"
            onChange={(e) => {
              changeAddHourlySalary(Number(e.target.value));
            }}
            min={0}
            max={99999999}
            maxLength={9}
          />
          )}
        </FlexBoxItem>

        <FlexBoxItem basis="10%">
          <BodyText>支援交通費</BodyText>
        </FlexBoxItem>
        <FlexBoxItem basis="30%">
          <HourInput
            name="supportTransportation"
            value={supportTransportation}
            label="円"
            onChange={(e) => {
              changeSupportTransportation(Number(e.target.value));
            }}
            min={0}
            max={99999999}
            maxLength={9}
          />
        </FlexBoxItem>

      </FlexBox>

      <Separater margin="12px 0" />
    </React.Fragment>
  );
};

export default OrganizationSupportEdit;
