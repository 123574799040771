import React from 'react';

const MonthHourTable: React.FC<{
  employment: any,
  business: any,
  term: any,
  timeDisp: any,
  subTotalTime: any,
}> = ({
  employment,
  business,
  term,
  timeDisp,
  subTotalTime,
}) => (
  <div className="table-responsive">
    <table id="monthHourList" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%' }}>
      <thead>
        <tr>
          <th style={{ width: '7%' }}>&nbsp;</th>
          {/* <!--普通時間 --> */}
          <th style={{ width: '7%' }}>普通</th>
          {/* <!--法定内残業時間（法定内の区別なし） --> */}
          {
        employment.handleLegalInOvertime === 0 && (
          <th className="text-center" style={{ width: '6%' }}>
            普通
            <br />
            残業
          </th>
        )
      }
          {/* <!--法定内残業時間（法定内の区別あり） --> */}
          {
        employment.handleLegalInOvertime === 1 && (
        <th style={{ width: '6%' }}>
          法定内
          <br />
          残業
        </th>
        )
      }
          {
        employment.handleLegalInOvertime === 1 && (
        <th style={{ width: '6%' }}>
          法定外
          <br />
          残業
        </th>
        )
      }
          {/* <!--割増残業時間 --> */}
          {
        employment.useExtraOverTime && (
        <th style={{ width: '6%' }}>
          割増
          <br />
          残業1
        </th>
        )
      }
          {
        employment.useExtraOverTime && employment.extraOverTime2 > 0 && (
        <th style={{ width: '6%' }}>
          割増
          <br />
          残業2
        </th>
        )
      }
          {
        employment.useExtraOverTime && employment.extraOverTime3 > 0 && (
        <th style={{ width: '6%' }}>
          割増
          <br />
          残業3
        </th>
        )
      }
          {/* <!--深夜時間 --> */}
          <th style={{ width: '6%' }}>深夜</th>
          {/* <!--深夜法定内残業時間（法定内の区別なし） --> */}
          {
        employment.handleLegalInOvertime === 0 && (
        <th style={{ width: '6%' }}>
          深夜
          <br />
          残業
        </th>
        )
      }
          {/* <!--深夜法定内残業時間（法定内の区別あり） --> */}
          {
       employment.handleLegalInOvertime === 1 && (
       <th style={{ width: '6%' }}>
         深夜
         <br />
         法定内
         <br />
         残業
       </th>
       )
      }
          {
       employment.handleLegalInOvertime === 1 && (
       <th style={{ width: '6%' }}>
         深夜
         <br />
         法定外
         <br />
         残業
       </th>
       )
      }
          {/* <!--深夜割増残業時間 --> */}
          {
       employment.useExtraOverTime && (
       <th style={{ width: '6%' }}>
         深夜
         <br />
         割増
         <br />
         残業1
       </th>
       )
      }
          {
       employment.useExtraOverTime && employment.extraOverTime2 > 0 && (
       <th style={{ width: '6%' }}>
         深夜
         <br />
         割増
         <br />
         残業2
       </th>
       )
      }
          {
       employment.useExtraOverTime && employment.extraOverTime3 > 0 && (
       <th style={{ width: '6%' }}>
         深夜
         <br />
         割増
         <br />
         残業3
       </th>
       )
      }
          {/* <!--未承認残業時間 --> */}
          {
       employment.useOverTimeApplication !== 0 && (
       <th style={{ width: '6%' }}>
         未承認
         <br />
         残業
       </th>
       )
      }
          <th style={{ width: '6%' }}>休憩</th>
          <th style={{ width: '6%' }}>遅刻</th>
          <th style={{ width: '6%' }}>早退</th>
          {
            employment.calcOverTimeInWeek && (
            <th style={{ width: '6%' }}>
              週所定
              <br />
              超過
            </th>
            )
          }
          <th style={{ width: '6%' }}>みなし時間</th>
          <th style={{ width: '8%' }}>総労働時間</th>
          {
        business && business.map((item: any) => (
          <th style={{ width: '8%' }} key={item.businessCode}>
            { item.businessName }
          </th>
        ))
       }
          {
        term && term.map((item: any) => (
          <th style={{ width: '8%' }} key={item.termId}>
            { item.termName }
          </th>
        ))
      }
        </tr>
      </thead>
      <tbody>
        <tr role="row">
          <td>所定日</td>
          {/* <!--普通時間 --> */}
          <td>{timeDisp(subTotalTime.daytime)}</td>
          {/* <!--法定内残業時間（法定内の区別なし）--> */}
          {
        employment.handleLegalInOvertime === 0 && <td>{timeDisp(subTotalTime.daytimeWeekdayOvertime)}</td>
      }
          {/* <!--法定内残業時間（法定内の区別あり）--> */}
          {
       employment.handleLegalInOvertime === 1 && <td>{timeDisp(subTotalTime.daytimeLegalOvertime)}</td>
      }
          {/* <!--法定外残業時間（法定内の区別あり）--> */}
          {
       employment.handleLegalInOvertime === 1 && <td>{timeDisp(subTotalTime.daytimeWeekdayOvertime)}</td>
      }
          {/* <!--割増残業時間 --> */}
          {
       employment.useExtraOverTime && <td>{timeDisp(subTotalTime.daytimeWeekdayPremiumOvertime1)}</td>
      }
          {
       employment.useExtraOverTime && employment.extraOverTime2 > 0 && <td>{timeDisp(subTotalTime.daytimeWeekdayPremiumOvertime2)}</td>
      }
          {
       employment.useExtraOverTime && employment.extraOverTime3 > 0 && <td>{timeDisp(subTotalTime.daytimeWeekdayPremiumOvertime3)}</td>
      }
          {/* <!--深夜時間 --> */}
          <td>{timeDisp(subTotalTime.midnightWeekdayTotal)}</td>
          {/* <!--深夜法定内残業時間（法定内の区別なし） --> */}
          {
       employment.handleLegalInOvertime === 0 && <td>{timeDisp(subTotalTime.midnightWeekdayOvertime)}</td>
      }
          {/* <!--深夜法定内残業時間（法定内の区別あり） --> */}
          {
       employment.handleLegalInOvertime === 1 && <td>{timeDisp(subTotalTime.midnightWeekdayLegalOvertime)}</td>
      }
          {
       employment.handleLegalInOvertime === 1 && <td>{timeDisp(subTotalTime.midnightWeekdayOvertime)}</td>
      }
          {/* <!--深夜割増残業時間 --> */}
          {
       employment.useExtraOverTime && <td>{timeDisp(subTotalTime.midnightWeekdayPremiumOvertime1)}</td>
      }
          {
       employment.useExtraOverTime && employment.extraOverTime2 > 0 && <td>{timeDisp(subTotalTime.midnightWeekdayPremiumOvertime2)}</td>
      }
          {
       employment.useExtraOverTime && employment.extraOverTime3 > 0 && <td>{timeDisp(subTotalTime.midnightWeekdayPremiumOvertime3)}</td>
      }
          {/* <!--未承認残業時間 --> */}
          {
       employment.useOverTimeApplication !== 0 && <td>{timeDisp(subTotalTime.daytimeUnapprovedOvertime)}</td>
      }
          <td rowSpan={3}>{timeDisp(subTotalTime.recess)}</td>
          <td rowSpan={3}>{timeDisp(subTotalTime.lateTime)}</td>
          <td rowSpan={3}>{timeDisp(subTotalTime.earlierTime)}</td>
          {
            employment.calcOverTimeInWeek && (
              <td rowSpan={3}>{timeDisp(subTotalTime.weeklyOvertime)}</td>
            )
          }
          <td rowSpan={3}>{timeDisp(subTotalTime.deemedTime)}</td>
          <td rowSpan={3}>{timeDisp(subTotalTime.allWorkTime)}</td>
          {
        subTotalTime.businessTimeSubTotal && subTotalTime.businessTimeSubTotal.map((item: any, index: any) => (
          <td rowSpan={3} key={String(index)}>
            {timeDisp(item.subtotalTime)}
          </td>
        ))
       }
          {
        subTotalTime.termTimeSubTotal && subTotalTime.termTimeSubTotal.map((item: any, index: any) => (
          <td rowSpan={3} key={String(index)}>
            {timeDisp(item.subtotalTime)}
          </td>
        ))
      }
        </tr>
        <tr role="row">
          {
       employment.distinctionHoliday && <td>法定休日</td>
      }
          {
       !employment.distinctionHoliday && <td>休日</td>
      }
          {/* <!--普通時間 --> */}
          <td>{timeDisp(subTotalTime.daytimeLegalHoliday)}</td>
          {/* <!--法定内残業時間（法定内の区別あり） --> */}
          {
       employment.handleLegalInOvertime === 1 && <td>-</td>
      }
          {/* <!-- 法定外残業 or 残業(法定休日残業時間を計上する) --> */}
          {
       employment.treatmentStatutoryHolidayOvertime === 0 && <td>{timeDisp(subTotalTime.daytimeLegalHolidayOvertime)}</td>
      }
          {/* <!-- 法定外残業 or 残業(法定休日残業時間を計上しない) --> */}
          {
       employment.treatmentStatutoryHolidayOvertime === 1 && <td>-</td>
      }
          {/* <!--割増残業時間 --> */}
          {
       employment.useExtraOverTime && <td>{timeDisp(subTotalTime.daytimeLegalHolidayPremiumOvertime1)}</td>
      }
          {
       employment.useExtraOverTime && employment.extraOverTime2 > 0 && <td>{timeDisp(subTotalTime.daytimeLegalHolidayPremiumOvertime2)}</td>
      }
          {
       employment.useExtraOverTime && employment.extraOverTime3 > 0 && <td>{timeDisp(subTotalTime.daytimeLegalHolidayPremiumOvertime3)}</td>
      }
          {/* <!--深夜時間 --> */}
          <td>{timeDisp(subTotalTime.midnightLegalHolidayTotal)}</td>
          {/* <!--深夜法定内残業時間（法定内の区別あり） --> */}
          {
       employment.handleLegalInOvertime === 1 && <td>-</td>
      }
          {
       employment.treatmentStatutoryHolidayOvertime === 1 && <td>-</td>
      }
          {
       employment.treatmentStatutoryHolidayOvertime === 0 && <td>{timeDisp(subTotalTime.midnightLegalHolidayOvertime)}</td>
      }
          {/* <!--深夜割増残業時間 --> */}
          {
       employment.useExtraOverTime && <td>{timeDisp(subTotalTime.midnightLegalHolidayPremiumOvertime1)}</td>
      }
          {
       employment.useExtraOverTime && employment.extraOverTime2 > 0 && <td>{timeDisp(subTotalTime.midnightLegalHolidayPremiumOvertime2)}</td>
      }
          {
       employment.useExtraOverTime && employment.extraOverTime3 > 0 && <td>{timeDisp(subTotalTime.midnightLegalHolidayPremiumOvertime3)}</td>
      }
          {/* <!--未承認残業時間 --> */}
          {
       employment.useOverTimeApplication !== 0 && <td>{timeDisp(subTotalTime.leaglHolidayUnapprovedOvertime)}</td>
      }
        </tr>
        {
      employment.distinctionHoliday && (
        <tr role="row">
          <td>所定休日</td>
          {/* <!--普通時間 --> */}
          <td>{timeDisp(subTotalTime.daytimeNotLegalHoliday)}</td>
          {/* <!--法定外残業時間（法定内の区別なし） --> */}
          {
            employment.handleLegalInOvertime === 0 && <td>{timeDisp(subTotalTime.daytimeNotLegalHolidayOvertime)}</td>
          }
          {/* <!--法定内残業時間（法定内の区別あり） --> */}
          {
            employment.handleLegalInOvertime === 1 && <td>{timeDisp(subTotalTime.daytimeNotLegalOvertime)}</td>
          }
          {/* <!--法定外残業時間（法定内の区別あり） --> */}
          {
            employment.handleLegalInOvertime === 1 && <td>{timeDisp(subTotalTime.daytimeNotLegalHolidayOvertime)}</td>
          }
          {/* <!--割増残業時間 --> */}
          {
            employment.useExtraOverTime && <td>{timeDisp(subTotalTime.daytimeNotLegalHolidayPremiumOvertime1)}</td>
          }
          {
            employment.useExtraOverTime && employment.extraOverTime2 > 0 && <td>{timeDisp(subTotalTime.daytimeNotLegalHolidayPremiumOvertime2)}</td>
          }
          {
            employment.useExtraOverTime && employment.extraOverTime3 > 0 && <td>{timeDisp(subTotalTime.daytimeNotLegalHolidayPremiumOvertime3)}</td>
          }
          {/* <!--深夜時間 --> */}
          <td>{timeDisp(subTotalTime.midnightNotLegalHolidayTotal)}</td>
          {/* <!--深夜法定外残業時間（法定内の区別なし） --> */}
          {
            employment.handleLegalInOvertime === 0 && <td>{timeDisp(subTotalTime.midnightNotLegalHolidayOvertime)}</td>
          }
          {/* <!--深夜法定内残業時間（法定内の区別あり） --> */}
          {
            employment.handleLegalInOvertime === 1 && <td>{timeDisp(subTotalTime.midnightNotLegalHolidayLegalOvertime)}</td>
          }
          {/* <!--深夜法定外残業時間（法定内の区別あり） --> */}
          {
            employment.handleLegalInOvertime === 1 && <td>{timeDisp(subTotalTime.midnightNotLegalHolidayOvertime)}</td>
          }
          {/* <!--深夜割増残業時間 --> */}
          {
            employment.useExtraOverTime && <td>{timeDisp(subTotalTime.midnightNotLegalHolidayPremiumOvertime1)}</td>
          }
          {
            employment.useExtraOverTime && employment.extraOverTime2 > 0 && <td>{timeDisp(subTotalTime.midnightNotLegalHolidayPremiumOvertime2)}</td>
          }
          {
            employment.useExtraOverTime && employment.extraOverTime3 > 0 && <td>{timeDisp(subTotalTime.midnightNotLegalHolidayPremiumOvertime3)}</td>
          }
          {/* <!--未承認残業時間 --> */}
          {
            employment.useOverTimeApplication !== 0 && <td>{timeDisp(subTotalTime.notLeaglHolidayUnapprovedOvertime)}</td>
          }
        </tr>
      )
    }
      </tbody>
    </table>
  </div>
);
export default MonthHourTable;
