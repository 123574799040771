import ApiClient from './ApiClient';
import { stringify } from 'querystring';

export type Error = {
  error: string;
  defaultMessage: string;
}

export type RoleMaster = {
  roleId: string,
  roleName: string,
  companyCode: string,
  roleCode: string,
  accessLevel: number,
  accessLevelName: string,
  users?: string,
}

export type AccessLevel = {
  accessLevelMasterId: string,
  companyCode: string,
  accessLevel: number,
  accessLevelName: string,
}



export const getListRoleMaster = async (): Promise<Array<RoleMaster>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/roles/list/${companyCode}?fields=users`, {});
  return response.data;
};


export const downloadRoleMasterCsv = async (data: any, fileName: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = data;
  const queryStringParams = stringify(params);
  await ApiClient.downloadOriginalCsv(`/v1/roles/csv/${companyCode}?fields=users`, queryStringParams, fileName);
};

export const downloadRoleMasterExcel = async (fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
  }
  await ApiClient.downloadExcel(`/v1/role/excel/${companyCode}`, params, fileName)
}

export const deleteRoleMaster = async (roleId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.delete(`/v1/roles/delete/${companyCode}`, { roleId });
  return response.data;
}

export const getRoleMasterDetail = async (permissionCode: string | null, flagCopy: number): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const copy = flagCopy;
  const roleId = permissionCode ? permissionCode : ''
  const params = {
    roleId,
    copy
  }
  const response = await ApiClient.get(`/v1/role/${companyCode}`, params);
  return response.data;
}

export const getAccessLevelList = async (): Promise<Array<AccessLevel>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/access_levels/list/${companyCode}`, {});
  return response.data;
}

export const postRoleMasterList = async (data: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const response = await ApiClient.postJsonData(`/v1/role/${companyCode}`, { staffCode }, data);
  return response.data;
}
export default {};
