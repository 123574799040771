import ApiClient from './ApiClient';

export const getMonthlyDailyReport = async (
  startDay: string,
  endDay: string,
  orgCode:string,
  categoryAry: Array<any>,
): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    staffCode,
    startDay,
    endDay,
  };

  const reponse = await ApiClient.get(`/v3/sales/list/standard/monthly/daySeparateStoreSalesManagement/${companyCode}?${appendUrl}`, params);
  return reponse.data;
};
export default getMonthlyDailyReport;
