import ApiClient from './ApiClient';

export type attendAchievementOutput = {
    staffCode: any;
    orgCode: any;
    targetDate: any;
}

export const getOrganizationsTrees = async (
  staffCode: any,
  functionType:number,
): Promise<Array<attendAchievementOutput>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    staffCode,
    functionType,
  };

  // TODO: V4にバージョンアップ予定
  const response = await ApiClient.get(`/v3/organizations/trees/${companyCode}`, params);
  return response.data;
};

export const exportFilePDF = async (
  orgCode: any,
  targetDate: any,
  functionType: any,
  categoryAry: any,
  staffCode: any,
): Promise<Array<attendAchievementOutput>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDate,
    functionType,
    staffCode,
  };

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any) => {
      appendUrl += `&categoryAry=${cateArr}`;
    });
  }

  const response = await ApiClient.getDownloadFile(`/v2/attendAchievement/output/${companyCode}?staffCode=${staffCode}&functionType=${functionType}&orgCode=${orgCode}&targetDate=${targetDate}${appendUrl}`, {});
  return response.data;
};
