import ApiClient from './ApiClient';

export const getSalesSuppliersList = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/suppliersList/${companyCode}`, { });
  return response.data;
};

export const saveSalesSuppliers = async (
  transactionType: string,
  suppliersCode: string,
  suppliersName: string,
  dispOrder: number,
  applyStartDate: any,
  applyEndDate: any,
) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const createUser = sessionStorage.getItem('loginUser.staffCode');
  const params = {
    transactionType,
    suppliersCode,
    suppliersName,
    dispOrder,
    applyStartDate,
    applyEndDate,
    createUser,
  };
  const response = await ApiClient.postJsonData(`/v1/sales/suppliers/${companyCode}`, {}, params);
  return response.data;
};

export const updateSalesSuppliers = async (
  transactionType: string,
  suppliersCode: string,
  suppliersName: string,
  dispOrder: number,
  applyStartDate: any,
  applyEndDate: any,
) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const createUser = sessionStorage.getItem('loginUser.staffCode');
  const params = {
    transactionType,
    suppliersCode,
    suppliersName,
    dispOrder,
    applyStartDate,
    applyEndDate,
    createUser,
  };
  const response = await ApiClient.putJsonData(`/v1/sales/suppliers/${companyCode}`, {}, params);
  return response.data;
};

export const deleteSalesSuppliers = async (
  transactionType: string,
  suppliersCode: string,
) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    transactionType,
    suppliersCode,
  };
  const response = await ApiClient.delete(`/v1/sales/suppliers/${companyCode}`, params);
  return response.data;
};

export const getDetailSalesSuppliers = async (
  suppliersCode: string,
  transactionType: string,
) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    suppliersCode,
    transactionType,
  };
  const response = await ApiClient.get(`/v1/sales/suppliers/${companyCode}`, {}, params);
  return response.data;
};

export const downloadCsv = async (fileName:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsvPost(`/v1/sales/suppliers/csv/export/${companyCode}`, {}, {}, fileName);
};

export const importFileCSV = async (uploadFile: File): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginUser = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    createUser: loginUser,
    updateUser: loginUser,
  };
  await ApiClient.postFile(`/v1/sales/suppliers/csv/import/${companyCode}`, params, 'uploadFile', uploadFile);
};

export type Error = {
  error: string;
  defaultMessage: string;
}

export default { };
