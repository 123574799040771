import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import { Holiday } from 'domain/holiday';
import { useParams } from 'react-router-dom';
import HolidayAddForm from 'components/organismos/master/labor/HolidayAddForm';

const HolidayCreatePage: React.FC<Holiday> = () => {
  const {
    publicHolidayId,
  } = useParams();
  return (
    <SidebarTemplate pageTitle={publicHolidayId ? '祝日編集' : '祝日作成'}>
      <HolidayAddForm publicHolidayId={publicHolidayId || ''} />
    </SidebarTemplate>
  );
};
export default HolidayCreatePage;
