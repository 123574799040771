/** @jsx jsx */
import React, {
  useState, useLayoutEffect, useRef, useEffect,
} from 'react';
import { css, jsx } from '@emotion/core';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import BlockUI from 'components/molecules/BlockUi';
import FormLabel from 'components/atoms/Form/FormLabel';
import TextTooltip from 'components/atoms/TextTooltip';
import {
  isTablet, isMacOs, isMobile, isMobileOnly,
} from 'react-device-detect';
import moment from 'moment';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import Icon from 'components/atoms/Icon';
import { textColor } from 'components/styles';
import DatePickerForm from 'components/molecules/DatePickerForm';
import BottomBar from 'components/organismos/BottomBar';
import Button from 'components/atoms/Button';
import { useParams, Link } from 'react-router-dom';
import useOperationReportQuick from './hooks';

const OperationalReportQuick: React.FC<{}> = () => {
  const params = useParams<any>();
  const functionType = 2;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const styles = css({
    fontWeight: 400,
    fontSize: 14,
  });

  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const {
    isLoading, operationReport, fetchData, setSelectOrgCode, selectOrgCode,
    targetDateFrom, setTargetDateFrom,
  } = useOperationReportQuick();
  const tableSexRef = useRef<HTMLTableSectionElement>(null);
  const tableVisitRef = useRef<HTMLTableSectionElement>(null);
  const tablePaymentRef = useRef<HTMLTableSectionElement>(null);
  const [checkScrollBarTableSex, setCheckScrollBarTableSex] = useState<number>(0);
  const [checkScrollBarTableVisit, setCheckScrollBarTableVisit] = useState<number>(0);
  const [checkScrollBarTablePayment, setCheckScrollBarTablePayment] = useState<number>(0);
  // const { orgCode, targetDate } = useParams<{ orgCode: string, targetDate: string}>();

  useLayoutEffect(() => {
    const hasScroll = isMacOs || isMobile ? '15px' : '16.8px';
    if (tableSexRef.current) {
      const element = tableSexRef.current;
      if (element.scrollHeight > 256 && checkScrollBarTableSex === 1) {
        element.style.width = `calc(100% + ${hasScroll})`;
      } else {
        element.style.width = '100%';
      }
    }
    if (tableVisitRef.current) {
      const elementVisit = tableVisitRef.current;
      if (elementVisit.scrollHeight > 256 && checkScrollBarTableVisit === 1) {
        elementVisit.style.width = `calc(100% + ${hasScroll})`;
      } else {
        elementVisit.style.width = '100%';
      }
    }
    if (tablePaymentRef.current) {
      const elementPayment = tablePaymentRef.current;
      if (elementPayment.scrollHeight > 256 && checkScrollBarTablePayment === 1) {
        elementPayment.style.width = `calc(100% + ${hasScroll})`;
      } else {
        elementPayment.style.width = '100%';
      }
    }
  }, [
    operationReport, checkScrollBarTableVisit, checkScrollBarTableSex,
    checkScrollBarTablePayment,
  ]);

  useEffect(() => {
    const offsetWidthSexTable = document.getElementById('operationTableVisitReport')?.getElementsByTagName('tbody')[0]?.getBoundingClientRect().width || 0;
    const clientWidthSexTable = document.getElementById('operationTableVisitReport')?.getElementsByTagName('tbody')[0]?.clientWidth || 0;
    if (offsetWidthSexTable === clientWidthSexTable) {
      setCheckScrollBarTableSex(0);
    } else {
      setCheckScrollBarTableSex(1);
    }
    const offsetWidthVisitTable = document.getElementById('operationTableVisitReport')?.getElementsByTagName('tbody')[0]?.getBoundingClientRect().width || 0;
    const clientWidthVisitTable = document.getElementById('operationTableVisitReport')?.getElementsByTagName('tbody')[0]?.clientWidth || 0;
    if (offsetWidthVisitTable === clientWidthVisitTable) {
      setCheckScrollBarTableVisit(0);
    } else {
      setCheckScrollBarTableVisit(1);
    }
    const offsetWidthPaymentTable = document.getElementById('operationTableVisitReport')?.getElementsByTagName('tbody')[0]?.getBoundingClientRect().width || 0;
    const clientWidthPaymentTable = document.getElementById('operationTableVisitReport')?.getElementsByTagName('tbody')[0]?.clientWidth || 0;
    if (offsetWidthPaymentTable === clientWidthPaymentTable) {
      setCheckScrollBarTablePayment(0);
    } else {
      setCheckScrollBarTablePayment(1);
    }
  }, [operationReport]);

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox alignItems="center">
          <FlexBoxItem basis={isMobile ? '20%' : '10%'}>
            <FormLabel
              label="対象日"
            />
          </FlexBoxItem>
          <FlexBoxItem basis="50%">
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label=""
              isFullWidth={true}
              date={targetDateFrom}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDateFrom(date);
                }
              }}
            />
          </FlexBoxItem>
          <FlexBoxItem marginLeft="auto">
            <ClickableIconButton
              additionalCss={css({
                backgroundColor: '#0D4796', paddingLeft: '25px', paddingRight: '25px', borderRadius: '5px',
              })}
              onClick={fetchData}
            >
              <Icon type="reload" color={textColor.inversed} isHover={false} size="25" />
            </ClickableIconButton>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox>
          <FlexBoxItem width="100%">
            <OrganizationFilteredTree
              functionType={functionType}
              staffCode={staffCode}
              orgLabel="組織名"
              initOrgValue={String(selectOrgCode)}
              orgCallback={(args: string | Array<string>) => {
                setSelectOrgCode(String(args));
              }}
              styles={{
                label: {
                  toggleSwitch: css({ marginLeft: '0px' }),
                },
                toggle: {
                  width: '13rem',
                },
              }}
            />
          </FlexBoxItem>
        </FlexBox>
        {operationReport && operationReport.searchTime && operationReport.searchTime !== null && (
          <FlexBox customStyle={css({ marginTop: '15px' })}>
            <FormLabel label={moment(operationReport.searchTime).format('YYYY年MM月DD日（ddd） HH時mm分まで')} />
          </FlexBox>
        )}

        <div style={{ display: isTablet || isMobile ? 'block' : 'flex', overflow: 'auto', paddingBottom: '20px' }}>
          <div style={{ marginTop: '15px' }}>
            <table className="table table-bordered table-condensed operationTable" id="operationTableSexReport" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: isTablet || isMobile ? '100%' : '300px' }}>
              <thead>
                <tr>
                  <th>性別</th>
                  <th>人数</th>
                </tr>
              </thead>
              <tbody css={styles} ref={tableSexRef}>
                {
                  operationReport && operationReport.sex && operationReport.sex.data
                  && operationReport.sex.data.length > 0
                  && operationReport.sex.data.map((item: any, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={index}>
                      <td className="textCenter">{item.name}</td>
                      <td className="textRight">{formatter.format(item.count)}</td>
                    </tr>
                  ))
                }
              </tbody>
              <tfoot>
                <tr>
                  <td className="textCenter">計</td>
                  <td className="textRight">
                    {
                      operationReport && operationReport.sex && operationReport.sex.total
                      && operationReport.sex.total.count
                        ? formatter.format(operationReport.sex.total.count) : 0
                    }
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div style={{ marginTop: '15px', marginLeft: isTablet || isMobile ? 0 : '50px' }}>
            <table className="table table-bordered table-condensed operationTable" id="operationTableVisitReport" cellSpacing="0" style={{ whiteSpace: 'nowrap', textAlign: 'center', width: isTablet || isMobile ? '100%' : '300px' }}>
              <thead>
                <tr>
                  <th>回数</th>
                  <th>人数</th>
                </tr>
              </thead>
              <tbody css={styles} ref={tableVisitRef}>
                {
                  operationReport && operationReport.visit && operationReport.visit.data
                  && operationReport.visit.data.length > 0
                  && operationReport.visit.data.map((item: any, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={index}>
                      <td className="textCenter">{item.name}</td>
                      <td className="textRight">{formatter.format(item.count)}</td>
                    </tr>
                  ))
                }
              </tbody>
              <tfoot>
                <tr>
                  <td className="textCenter">計</td>
                  <td className="textRight">
                    {
                      operationReport && operationReport.visit
                      && operationReport.visit.total
                      && operationReport.visit.total.count
                        ? formatter.format(operationReport.visit.total.count) : 0
                    }
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div style={{ marginTop: '15px', marginLeft: isTablet || isMobile ? 0 : '50px' }}>
            <table className="table table-bordered table-condensed operationTable" id="operationTablePaymentReport" cellSpacing="0" style={{ whiteSpace: 'nowrap', textAlign: 'center', width: isTablet || isMobile ? '100%' : '400px' }}>
              <thead>
                <tr>
                  <th>支払項目</th>
                  <th>件数</th>
                  <th>金額</th>
                </tr>
              </thead>
              <tbody css={styles} ref={tablePaymentRef}>
                {
                  operationReport && operationReport.payment && operationReport.payment.data
                  && operationReport.payment.data.length > 0
                  && operationReport.payment.data.map((item: any, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={index}>
                      <td className="textCenter">{item.name}</td>
                      <td className="textRight">{formatter.format(item.count)}</td>
                      <td className="textRight">{formatter.format(item.amount)}</td>
                    </tr>
                  ))
                }
              </tbody>
              <tfoot>
                <tr>
                  <td className="textCenter">計</td>
                  <td className="textRight">
                    {
                      operationReport && operationReport.payment
                      && operationReport.payment.total
                      && operationReport.payment.total.count
                        ? formatter.format(operationReport.payment.total.count) : 0
                    }
                  </td>
                  <td className="textRight">
                    {
                      operationReport && operationReport.payment && operationReport.payment.total
                      && operationReport.payment.total.amount
                        ? formatter.format(operationReport.payment.total.amount) : 0
                    }
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <div style={{ marginTop: '15px', overflow: 'auto', marginBottom: '30px' }}>
          <table className="table table-bordered table-condensed operationTable" id="operationTableReportNew" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: isTablet || isMobile ? '100%' : '1100px' }}>
            <thead>
              <tr>
                <th>No</th>
                <th>時刻</th>
                <th>性別</th>
                <th>回数</th>
                <th>社員名</th>
                <th>メニュー</th>
                <th>支払</th>
                <th>金額</th>
              </tr>
            </thead>
            <tbody css={styles}>
              {
                operationReport && operationReport.detailList && operationReport.detailList
                && operationReport.detailList.length > 0
                && operationReport.detailList.map((item: any, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={index}>
                    <td className="textRight">{item.no}</td>
                    <td className="textCenter">{item.startTime}</td>
                    <td className="textCenter">{item.sex}</td>
                    <td className="textCenter">{item.visitNum}</td>
                    <td className="textLeft">{item.shopUserName}</td>
                    <td className="textLeft">
                      <TextTooltip
                        id={`menu${index}`}
                        text={item.menuNames}
                      />

                    </td>
                    <td className="textCenter"><TextTooltip id={`payment${index}`} text={item.paymentNames} /></td>
                    <td className="textRight">{formatter.format(item.price || 0)}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        {params.orgCode && params.targetDate
          && (
          <Link to="/shogun/operationalReportList">
            <Button
              text="戻る"
              ghost={true}
            />
          </Link>
          )}
      </FormContents>
      {isMobileOnly && (<BottomBar />)}
    </BlockUI>
  );
};
export default OperationalReportQuick;
