import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getSpecialSalaryPatternList } from 'api/specialSalaryPattern';

/**
 * 特賃日パターンのOptionを取得
 */
export const useSpecialSalaryPatternOptions = () => {
  const [specialSalaryOptions, setSpecialSalaryOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getSpecialSalaryPatternList().then((specialSalaryPetterns) => {
      const specialSalaryPatternList = specialSalaryPetterns.map((specialSalaryPettern) => ({
        value: specialSalaryPettern.specialSalaryPatternId,
        label: `${specialSalaryPettern.specialSalaryPatternCode} ${specialSalaryPettern.specialSalaryPatternName}`,
      }));
      specialSalaryPatternList.unshift({
        value: '',
        label: '-',
      });

      setSpecialSalaryOptions(specialSalaryPatternList);
    });
  }, []);
  return specialSalaryOptions;
};

export default useSpecialSalaryPatternOptions;
