import { CurrentTime } from 'domain/master/attend/currentTime';
import { StampStaff } from 'domain/master/attend/stampStaff';
import ApiClient from './ApiClient';

export const getCurrentTime = async (): Promise<CurrentTime> => {
  // const params: any = [];
  const response = await ApiClient.getNoAuthV2('/v1/attendStamp/currentTime/');
  return response.data;
};

export const getAttendStampStaffItem = async (
  orgCode: string, staffCode: string): Promise<StampStaff> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    staffCode,
  };
  const response = await ApiClient.get(`/v1/attendStamp/staff/${companyCode}`, params);
  return response.data;
};


export const getGpsAddress = async (latlng: string): Promise<any> => {
  const language = 'js';
  const sensor = false;
  const key = 'AIzaSyDBUkvMbAzDEl4KRx4n_bIfcqzy_A2Q3ZE';
  const params = {
    language,
    sensor,
    latlng,
    key,
  };
  const response = await ApiClient.getOrgDomain2('https://maps.googleapis.com/maps/api/geocode/json', params);
  return response.data;
};

export const postGpsStamp = async (params: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
  };

  const reponse = await ApiClient.postMutipartData(`/v1/attendStamp/gpsStamp/${companyCode}`, query, params);
  return reponse.data;
};

export default {};
