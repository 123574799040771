import { HolidayTypeLabel } from 'domain/vacation';

/**
 * API 特賃日パターンマスタ
 */
export interface VacationMaster{
    holidayId: string;
    holidayCode: string;
    holidayName: string;
    holidayType:number;
    holidayBgColor:string;
    holidayForecolor:string;
}
export default class VacationMasterDomain {
  constructor(private rawData: VacationMaster) {
    //
  }

  static generateInitial(): VacationMasterDomain {
    return new VacationMasterDomain({
      holidayId: '',
      holidayCode: '',
      holidayName: '',
      holidayType: 0,
      holidayBgColor: '',
      holidayForecolor: '',
    });
  }

  getRowData(): VacationMaster {
    return this.rawData;
  }

  get holidayId(): string {
    return this.rawData.holidayId;
  }

  set holidayId(holidayId: string) {
    this.rawData.holidayId = holidayId;
  }

  get holidayCode(): string {
    return this.rawData.holidayCode;
  }

  set holidayCode(holidayCode: string) {
    this.rawData.holidayCode = holidayCode;
  }

  get holidayName(): string {
    return this.rawData.holidayName;
  }

  set holidayName(holidayName: string) {
    this.rawData.holidayName = holidayName;
  }

  get holidayType(): number {
    return this.rawData.holidayType;
  }

  set holidayType(holidayType: number) {
    this.rawData.holidayType = holidayType;
  }

  get holidayBgColor(): string {
    return this.rawData.holidayBgColor;
  }

  set holidayBgColor(holidayForecolor: string) {
    this.rawData.holidayForecolor = holidayForecolor;
  }

  get holidayForecolor(): string {
    return this.rawData.holidayForecolor;
  }

  set holidayForecolor(holidayForecolor: string) {
    this.rawData.holidayForecolor = holidayForecolor;
  }

  get holidayTypeLabel(): String {
    return HolidayTypeLabel[this.rawData.holidayType];
  }
}
