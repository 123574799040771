/* eslint-disable no-mixed-operators */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import moment from 'moment';
import React from 'react';
import { isMacOs } from 'react-device-detect';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import useDailySalesManage from './hook';

const styles = {
  link: css`
    a {
     color:#0000EE !important;
     text-decoration: underline;
    }
  `,
};

const DailySalesManagementTable: React.FC<{
    targetDateFrom: Date,
    targetDateTo: Date,
    dailyReport: any,
    getMediaMst: any,
    getCreditMst: any,
    orgCode:any,
    categoryAry: any,
    loadingCallback?: (args: any) => void,
    roleScreen: any
}> = ({
  targetDateFrom,
  targetDateTo,
  dailyReport,
  getMediaMst,
  getCreditMst,
  orgCode,
  categoryAry,
  loadingCallback,
  roleScreen
}) => {
  const { downloadDaySeparateCsv, commentSubjectForBudgetProcess,
    commentSubjectForStoreManagement,
    commentSubjectForOthers } = useDailySalesManage();
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const functionType = 3;
  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0, maximumFractionDigits: 0,
  });
  let bottomRow: any = null;
  let bottomRowMedia: any = null;
  const param = {
    orgCode,
    monthlyStartDate: moment(targetDateFrom).format('YYYY/MM/DD'),
    monthlyEndDate: moment(targetDateTo).format('YYYY/MM/DD'),
    loginStaffCode: staffCode,
    functionType,
    categoryAry: categoryAry.length > 0 ? String(categoryAry) : '',
    commentSubjectForBudgetProcess,
    commentSubjectForStoreManagement,
    commentSubjectForOthers
  };

  const getPaymentMediaAmount = (item: any, fillHeader: any) => {
    const { mediaList } = item;
    const paymentMediaCode = fillHeader.map((itemCode: any) => itemCode[0]);
    const listMediaAmount = paymentMediaCode.map((x: any) => {
      const found = mediaList.find((itemMedia: any) => itemMedia.paymentMediaCode === x);
      if (found) {
        return found.paymentMediaAmount;
      }
      return 0;
    });
    return listMediaAmount;
  };


  return (
    <div>
      <FlexBox>
        <FlexBoxItem>
          {roleScreen && roleScreen.downloadFlag === 1 && (
            <IconLabelButton
              onClick={() => {
                if (loadingCallback) {
                  loadingCallback(true);
                }
                downloadDaySeparateCsv(param).then(() => {
                  if (loadingCallback) {
                    loadingCallback(false);
                  }
                });
              }}
              iconType="download"
              text=" CSV出力"
            />
          )}
        </FlexBoxItem>
      </FlexBox>

      {/* {orgCode && orgCode !== 'all' && ( */}
      <div style={{ margin: '15px' }}>
        <div
          className="templateTable newDesignTable"
          style={{
            height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: isMacOs ? '35px' : '1px',
          }}
        >
          <table className="table table-bordered text-nowrap">
            <thead>
              {/* frist row */}
              <tr>
                <th
                  rowSpan={getMediaMst && getMediaMst.length > 0 ? 4 : 4}
                  colSpan={1}
                  style={{
                    top: '0', left: '0', zIndex: 99, maxWidth: '80px', width: '80px', minWidth: '80px',
                  }}
                  className="text-center stickyStyle fristColSticky"
                >
                  <span>店舗コード</span>

                </th>
                <th
                  rowSpan={getMediaMst && getMediaMst.length > 0 ? 4 : 4}
                  colSpan={1}
                  style={{
                    top: '0', left: '103px', zIndex: 99, maxWidth: '280px', width: '280px', minWidth: '280px',
                  }}
                  className="text-center stickyStyle fristColSticky"
                >
                  <span>店舗名</span>

                </th>
                <th
                  rowSpan={getMediaMst && getMediaMst.length > 0 ? 4 : 4}
                  colSpan={1}
                  style={{
                    top: '0', left: '386px', zIndex: 99, maxWidth: '140px', width: '140px', minWidth: '140px',
                  }}
                  className="text-center stickyStyle fristColSticky"
                >
                  <span>日付</span>

                </th>
                <th colSpan={getMediaMst ? 5 + getMediaMst.length : 5} style={{ top: '0' }} className="text-center stickyStyle">売上</th>
                {/* right */}
                <th colSpan={2} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>値引・割引券</span></th>
                <th colSpan={2} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>VOID</span></th>
                <th colSpan={2} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>会計中止</span></th>
                <th colSpan={2} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>メニュー取消</span></th>
                <th colSpan={4} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>レジ金</span></th>
                <th rowSpan={getMediaMst && getMediaMst.length > 0 ? 4 : 4} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>データ手入力</span></th>
                <th colSpan={3} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>コメント</span></th>
              </tr>
              {/* flexible second row  */}
              <tr>
                {(getMediaMst && getMediaMst.length > 0) && (<th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>純売上</span></th>)}
                {(getMediaMst && getMediaMst.length > 0) && (<th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>消費税</span></th>)}
                {(getMediaMst && getMediaMst.length > 0) && (<th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>総売上</span></th>)}
                {(getMediaMst && getMediaMst.length > 0) && (<th colSpan={getMediaMst.length} style={{ top: '34px' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>支払い種別</span></th>)}
                {(getMediaMst && getMediaMst.length > 0) && (<th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>客数</span></th>)}
                {(getMediaMst && getMediaMst.length > 0) && (<th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>客単価</span></th>)}
              </tr>
              {/* third row */}
              <tr>
                {/* right */}
                { !(getMediaMst && getMediaMst.length > 0) && (
                <React.Fragment>
                  <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle"><span>純売上</span></th>
                  <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle"><span>消費税</span></th>
                  <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle"><span>総売上</span></th>
                </React.Fragment>
                )}
                {
                    getMediaMst && getMediaMst.length > 0
                    && getMediaMst.map((media: any, index: any) => (
                      <th rowSpan={2} key={index + media} className="text-center stickyStyle" style={{ fontSize: '12px', top: '68px' }}>
                        <div
                          style={{
                            overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                          }}
                          data-tip={media[1]}
                        >
                          {media[1]}
                          <ReactTooltip html={true} />
                        </div>
                      </th>
                    ))
                  }
                { !(getMediaMst && getMediaMst.length > 0) && (
                <React.Fragment>
                  <th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>客数</span></th>
                  <th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>客単価</span></th>
                </React.Fragment>
                )}
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>金額</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>枚数</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>金額</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>件数</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>金額</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>件数</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>金額</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>件数</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>釣銭準備金</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>現金在高</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>現金過不足</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>銀行入金</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>{commentSubjectForBudgetProcess}</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>{commentSubjectForStoreManagement}</span></th>
                <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>{commentSubjectForOthers}</span></th>
              </tr>
            </thead>
            <tbody>
              {
                dailyReport && dailyReport.length > 0 ? dailyReport.map((item: any, index: any) => {
                  const listMediaAmount: any = getPaymentMediaAmount(item, getMediaMst);
                  bottomRow = item;
                  bottomRowMedia = getPaymentMediaAmount(item, getMediaMst);

                  if (item.targetDate !== null) {
                    return (
                      <tr key={item.id}>
                        {/* left */}
                        <td style={{ left: '0', zIndex: 95 }} className="text-center stickyStyle fristColSticky"><span>{item.orgCode}</span></td>
                        <td style={{ left: '103px', zIndex: 95 }} className="text-center stickyStyle ">
                          <span>
                            <div
                              style={{
                                overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                              }}
                              data-tip={item.orgName}
                            >
                              {item.orgName}
                              <ReactTooltip html={true} />
                            </div>
                          </span>

                        </td>
                        <td style={{ left: '386px', zIndex: 95 }} className="text-center stickyStyle ">
                          <span css={styles.link}>
                            <Link to={`/shogun/salesDailyReport/${item.orgCode}/${moment(item.targetDate, 'YYYY-MM-DD').format('YYYY-MM-DD')}`}>
                              {moment(item.targetDate, 'YYYY-MM-DD').format('YYYY年MM月DD日（ddd）')}
                            </Link>
                          </span>
                        </td>
                        {/* right */}
                        <td className="text-right"><span>{formatter.format(item.netSales)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.tax)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.totalSales)}</span></td>
                        {
                          item.mediaList && item.mediaList.length > 0 ? (
                            listMediaAmount && listMediaAmount?.map((x: any, i: any) => <td className="text-right" key={i}><span>{formatter.format(x)}</span></td>)
                          )
                            : (
                              getMediaMst && getMediaMst.length > 0
                                  && getMediaMst.map((x: any, i: any) => (
                                    <React.Fragment key={i}>
                                      <td className="text-right">
                                        <span>
                                          0
                                        </span>
                                      </td>
                                    </React.Fragment>
                                  ))
                            )
                        }
                        <td className="text-right"><span>{formatter.format(item.guestCnt)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.perCustomerPrice)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.discountAmount)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.discountCnt)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.voidAmount)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.voidCnt)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.stopAccountAmount)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.stopAccountCnt)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.cancelMenuAmount)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.cancelMenuCnt)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.changeReserve)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.cashStock)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.cashExcessOrDeficiency)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.bankDeposit)}</span></td>
                        <td className="text-center"><span>{(item.editData)}</span></td>
                        <td className="text-left"><span>{item.budgetProgressComment }</span></td>
                        <td className="text-left"><span>{item.storeOperationComment }</span></td>
                        <td className="text-left"><span>{item.otherComment}</span></td>
                      </tr>
                    );
                  }
                }) : null
              }
              {/* footer */}
              <tr style={{ fontWeight: 'bolder' }}>
                <td colSpan={3} className="text-center stickyStyle fristColSticky" style={{ bottom: '0', left: '0', zIndex: 99 }}><span>合計</span></td>
                {bottomRow && (
                  <React.Fragment>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.netSales)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.tax)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.totalSales)}</span></td>
                    {
                          bottomRow.mediaList && bottomRow.mediaList.length > 0 ? (
                            bottomRowMedia && bottomRowMedia?.map((x: any, i: any) => <td className="text-right stickyStyle tickyTotalRight" key={i}><span>{formatter.format(x)}</span></td>)
                          )
                            : (
                              getMediaMst && getMediaMst.length > 0
                                  && getMediaMst.map((x: any, i: any) => (
                                    <React.Fragment key={i}>
                                      <td className="text-right stickyStyle tickyTotalRight">
                                        <span>
                                          0
                                        </span>
                                      </td>
                                    </React.Fragment>
                                  ))
                            )
                    }
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.guestCnt)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.perCustomerPrice)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.discountAmount)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.discountCnt)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.voidAmount)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.voidCnt)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.stopAccountAmount)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.stopAccountCnt)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.cancelMenuAmount)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.cancelMenuCnt)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.changeReserve)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.cashStock)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.cashExcessOrDeficiency)}</span></td>
                    <td className="text-right stickyStyle tickyTotalRight"><span>{formatter.format(bottomRow.bankDeposit)}</span></td>
                    <td className="text-center stickyStyle tickyTotalRight"><span>{(bottomRow.editData)}</span></td>
                    <td className="text-left stickyStyle tickyTotalRight"><span>{bottomRow.budgetProgressComment}</span></td>
                    <td className="text-left stickyStyle tickyTotalRight"><span>{bottomRow.storeOperationComment }</span></td>
                    <td className="text-left stickyStyle tickyTotalRight"><span>{bottomRow.otherComment}</span></td>
                  </React.Fragment>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default DailySalesManagementTable;
