import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getListWorkingHours } from 'api/workingHours';

/**
 * 所定労働時間の選択ボックス用データを取得
 */
export const useWorkingHoursOptions = (): Array<OptionType> => {
  const [workingHoursOptions, setWorkingHoursOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getListWorkingHours().then((workingHours) => {
      setWorkingHoursOptions(workingHours.map((workingHour) => ({
        value: workingHour.workingHoursId,
        label: workingHour.workingHoursPatternName, // TODO 項目確認
      })));
    });
  }, []);
  return workingHoursOptions;
};

export default useWorkingHoursOptions;
