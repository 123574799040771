/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

const FlexBoxItem: React.FC<{
  grow?: number
  shrink?: number
  basis?: string
  marginLeft?: string
  marginRight?: string
  width? : string
  margin?: string
  minWidth?:string
  textAlign?: any
  customStyle?: SerializedStyles;
}> = ({
  children, grow = 0, shrink = 1, basis = 'auto', marginLeft, marginRight, width, margin, minWidth, customStyle, textAlign = ''
}) => (
  <div
    css={css({
      flexGrow: grow,
      flexShrink: shrink,
      flexBasis: basis,
      marginLeft,
      width,
      marginRight,
      margin,
      minWidth,
      textAlign
    }, customStyle)}
  >
    {children}
  </div>
);

export default FlexBoxItem;
