/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';

import HeaderBar from 'components/atoms/HeaderBar';

/**
 * アプリケーション共通のヘッダーバー
 */
const TimeRecorderbar: React.FC<{
  shopName: string
}> = ({ shopName }) => (
  <HeaderBar>
    <div css={css({
      width: '100%',
      textAlign: 'right',
      paddingRight: '20px',
      fontSize: '24px',
    })}
    >
      {shopName}

    </div>
  </HeaderBar>
);

export default TimeRecorderbar;
