import {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useFormik } from 'formik';
import WorkingHoursDomain from 'domain/master/attend/workingHours';
import { post, getWorkingHours } from 'api/workingHours';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import useToastNotification from 'hooks/useToastNotification';

// declare validation form Working Hours Master
type WorkingHoursDomainnKey = keyof Pick<WorkingHoursDomain, 'workingHoursPatternCode'|'workingHoursPatternName'>;

//
export const useWorkingHoursDomainAddForm = (workingHoursId: string) => {
  // history
  const history = useHistory();
  const { successNotification, errorNotification } = useToastNotification();

  //
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  /**
   * create list month
   */
  const arrMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  // modal confirm
  const [toastModalOpen, setToastModalOpen] = useState(false);

  // value input working hours
  const [valueWorkingHours, setValueWorkingHours] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  // value input working minute
  const [valueWorkingMinuteForms, setValueWorkingMinuteForms] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  // handle submit form
  const onSubmit = async (values: WorkingHoursDomain) => {
    if (values.workingHoursPatternCode === null) {
      errorNotification('パターンコードを入力してください。');
      return;
    }
    if (values.workingHoursPatternName === null) {
      errorNotification('パターン名を入力してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    // TODO companyCodeの取得処理が必要
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginUser = sessionStorage.getItem('loginUser.staffName') || '';
    let convertData = values.getRawData();

    //
    let workingHoursDetailId = '';
    if ((convertData as any).workingHoursDetails) {
      //
      workingHoursDetailId = (convertData as any).workingHoursDetails.workingHoursDetailId || '';
    }

    //
    delete (convertData as any).createDate;
    delete (convertData as any).updateDate;
    delete (convertData as any).workingHoursDetails;
    delete (convertData as any).companyCode;

    //
    convertData.companyCode = companyCode;
    convertData.createUser = loginUser;
    convertData.updateUser = loginUser;

    //
    valueWorkingHours.forEach((value: any, index: number) => {
      convertData = {
        ...convertData,
        [`workingHoursForm${index + 1}`]: Number(value),
        [`workingHoursDetails.workingHoursForm${index + 1}`]: Number(value),
      };
    });

    //
    valueWorkingMinuteForms.forEach((value: any, index: number) => {
      convertData = {
        ...convertData,
        [`workingMinuteForm${index + 1}`]: Number(value),
        [`workingHoursDetails.workingMinuteForm${index + 1}`]: Number(value),

      };
    });

    //
    convertData = {
      ...convertData,
      [`workingHoursDetails.${'targetYear'}`]: convertData.targetYear,
      [`workingHoursDetails.${'workingHoursId'}`]: workingHoursId,
      [`workingHoursDetails.${'workingHoursDetailId'}`]: workingHoursDetailId,
    };

    // check process
    try {
      const response = await post(companyCode, convertData);
      if (!response.errors) {
        successNotification('更新しました。');
        history.push('/');
        sessionStorage.setItem('currentTab', '8');
        history.push('/agreementMaster');
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any, index: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: WorkingHoursDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
    // validate: async (values) => {
    //   const errors: {[key: string]: string} = {};
    //   try {
    //     await schema.validate(values, { abortEarly: false });
    //   } catch (error) {
    //     const validationError = error as Yup.ValidationError;
    //     validationError.inner.forEach((innerError) => {
    //       errors[innerError.path] = innerError.message;
    //     });
    //   }
    //   return errors;
    // },
    // TODO classのアクセサ経由でバリデーションができるようにする
  });

  const targetYear = moment().format('YYYY');

  //
  useEffect(() => {
    if (workingHoursId !== undefined) {
      handleGetWorkingHours(targetYear, workingHoursId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetYear, workingHoursId]);


  /**
   * handle get working hours & set formik data
   *
   * @param targetYear
   * @param workingHoursId
  */
  const handleGetWorkingHours = async (targetYear: any, workingHoursId: any) => {
    getWorkingHours(targetYear, workingHoursId).then((response: any) => {
      if (response && response.companyCode) {
        formik.setValues(new WorkingHoursDomain(response));
        formik.setFieldValue('targetYear', targetYear);
        //
        const { workingHoursDetails } = response;
        if (workingHoursDetails) {
          const itemsHour = [...valueWorkingHours];
          const itemsMinute = [...valueWorkingMinuteForms];
          Object.keys(workingHoursDetails).forEach((key_obj) => {
            const value = workingHoursDetails[key_obj];
            if (key_obj.includes('workingHoursForm')) {
              const split_key_obj = key_obj.split('workingHoursForm');
              (itemsHour as any)[parseInt(split_key_obj[1]) - 1] = value;
            } else if (key_obj.includes('workingMinuteForm')) {
              const split_key_obj = key_obj.split('workingMinuteForm');
              (itemsMinute as any)[parseInt(split_key_obj[1]) - 1] = value;
            }
          });
          setValueWorkingHours(itemsHour);
          setValueWorkingMinuteForms(itemsMinute);
        } else {
          setValueWorkingHours([]);
          setValueWorkingMinuteForms([]);
        }
        return true;
      }
      return false;
    });
  };

  //
  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  //
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  //
  const confirmModalItems = useMemo(() => ([
    {
      key: 'パターンコード',
      value: formik.values.workingHoursPatternCode,
    },
    {
      key: 'パターン名',
      value: formik.values.workingHoursPatternName,
    },
    {
      key: '対象年',
      value: formik.values.targetYear,
    },

  ]), [formik]);

  // 詳細項目入力を表示するかどうか
  const [detailMode, setDetailMode] = useState(false);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    confirmModalItems,
    toastModalOpen,
    closeToastModal,
    setToastModalOpen,
    arrMonth,
    valueWorkingHours,
    setValueWorkingHours,
    valueWorkingMinuteForms,
    setValueWorkingMinuteForms,
    handleGetWorkingHours,
  };
};

export default {
  useWorkingHoursDomainAddForm,
};
