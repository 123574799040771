import React from 'react';

import FormContents from 'components/atoms/Form/FormContents';
import WorkingHoursInputAddForm from './WorkingHoursInputAddForm';
import { useParams } from 'react-router-dom';

const WorkingHoursAddForm: React.FC = () => {
  const {
    workingHoursId
  } = useParams();
  return(
  	<FormContents>
	    <WorkingHoursInputAddForm workingHoursId={workingHoursId || ''} />
	  </FormContents>
  );
};

export default WorkingHoursAddForm;
