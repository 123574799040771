/** Error  */
export const ErrorMessage = {

  Common: {

    INTERNAL_SERVER_ERROR: 'サーバー側でエラーが発生しました。',
    INVALID_DATE_RANGE: (titleFrom: string, titleTo: string) => `${titleTo}は${titleFrom}以降を入力してください。`,
    REQUIRED_INPUT: (itemName: string) => `${itemName}を入力してください。`,

  },

  /** 勤怠ロック */
  AttendAchievementLock: {
    TERM_OVER_TARGET_DATE: (month: number) => `対象期間は${month}ヶ月以内で指定してください。`,
    LIMIT_OVER_SELECTED_COUNT: (limit: number) => `勤怠ロックの選択数は${limit.toLocaleString()}件までです。`,
  },

} as const;

/** Warning  */
export const WarningMessage = {

    /** 勤怠ロック */
    AttendAchievementLock: {

      SELECTED_ERROR_COUNT: '勤怠エラー未対応の行が選択されています。<br/>対応が完了するまで勤怠ロックの対象から外れます。',

    },
} as const;

/** Info  */
export const InfoMessage = {

  Common: {
    DELETE_COMPLETED: '削除しました。',
    MAINTENANCE: 'ただいまメンテナンス中のため、しばらくの間ご利用いただけません。',
  },

  StaffMaster: {
    UPDATE_CONFIRM_MESSAGE_STAFF_INFO: `
      登録します。よろしいですか？<br>
      <br>
      ・退職日を設定した場合、所属設定のマネージャー権限の終了は<br>
      　退職日となります。<br>
    `,
    UPDATE_DELETE_SHIFT_CONFIRM_MESSAGE_STAFF_INFO: `
      シフト削除の確認<br>
      <br>
      退職日が設定されているため、退職日以降のシフトは削除されます。<br>
      よろしいですか？<br>
      <font color="red">※ 削除されたシフトを戻すことはできません。</font><br>
    `,
    UPDATE_CONFIRM_MESSAGE_AFFILIATION_INFO: `
      登録します。よろしいですか？<br>
      <br>
      ・<font color="red">所属設定を変更した場合、異動日以降で登録されている</font><br>
      　<font color="red">所属設定はすべて削除されます。</font><br>
      ・退職日を設定した場合、所属設定のマネージャー権限の終了は<br>
      　退職日となります。<br>
    `,
    UPDATE_CONFIRM_MESSAGE_EMPLOYMENT_INFO: `
      登録します。よろしいですか？<br>
      <br>
      ・<font color="red">雇用設定を変更した場合、適用開始月以降で登録されている</font><br>
      　<font color="red">雇用設定はすべて削除されます。</font><br>
      ・変更後の雇用設定は登録済みの勤怠実績に反映されません。<br>
      　反映させたい場合は【勤怠再計算】を行ってください。<br>
    `,
    UPDATE_CONFIRM_MESSAGE_SALARY_INFO: `
      登録します。よろしいですか？<br>
      <br>
      ・<font color="red">人件費設定を変更した場合、給与適用日以降で登録されている</font><br>
      　<font color="red">人件費設定はすべて削除されます。</font><br>
      ・変更後の人件費設定は登録済みの勤怠実績に反映されません。<br>
      　反映させたい場合は【勤怠再計算】を行ってください。<br>
    `,
    DELETE_CONFIRM_MESSAGE_SALARY_INFO: `
      削除します。よろしいですか？<br>
      <br>
      ・削除後の人件費設定は登録済みの勤怠実績に反映されません。<br>
      　反映させたい場合は【勤怠再計算】を行ってください。<br>
    `,
  },

  AttendBusinessMaster: {
    CONFIRM_MESSAGE_DELETE: `
      削除します。よろしいですか？<br>
      <br>
      業務を削除すると以下の設定も削除されます。<br>
      <br>
      【 スタッフマスタ 】<br>
      &nbsp;&nbsp;・就業可能業務<br>
      &nbsp;&nbsp;・業務加算額<br>
      &nbsp;&nbsp;※ 該当スタッフはスタッフマスタのCSVダウンロードで確認することができます。
    `,
  },

  StaffRegistrationStatus: {
    CONFIRM_MESSAGE_DELETE_PHOTO: (staffName: string) => `${staffName}さんの顔写真を削除します。<br>よろしいですか？`,
  },


} as const;
