import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import TimeOutputLayoutList from 'components/organismos/master/attend/TimeOutputLayout/TimeOutputLayoutList';

const TimeOutputLayoutListPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="勤怠出力レイアウトマスタ"
  >
    <TimeOutputLayoutList />
  </SidebarTemplate>
);

export default TimeOutputLayoutListPage;
