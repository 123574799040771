import { useState, useCallback, useEffect } from 'react';

import { getList, getListByYm } from 'api/employment';
import moment from 'moment';
import EmploymentDomain, { Employment } from 'domain/employment';

export const useEmploymentList = () => {
  const constAllEmployment = sessionStorage.getItem('masterEmploymentForm.allEmployment') === 'true';
  const constApplyDate = sessionStorage.getItem('masterEmploymentForm.applyDate') || new Date();
  const [employmentList, setEmploymentList] = useState<Array<EmploymentDomain>>([]);
  const [allEmployment, setAllEmployment] = useState(constAllEmployment);
  const [applyDate, setApplyDate] = useState<Date>(new Date(constApplyDate));
  const [blocking, setBlocking] = useState(false);

  const applyDateStr : string = moment(applyDate).format('YYYY-MM-01');

  // データの取得
  const fetchData = useCallback(async () => {
    setBlocking(true);
    if (allEmployment) {
      const response = await getList();
      setEmploymentList(response.map((result: Employment) => new EmploymentDomain(result)));
    } else {
      const response = await getListByYm(
        applyDateStr,
      );
      setEmploymentList(response.map((result: Employment) => new EmploymentDomain(result)));
    }
    setBlocking(false);
    sessionStorage.setItem('masterEmploymentForm.allEmployment', String(allEmployment));
    sessionStorage.setItem('masterEmploymentForm.applyDate', applyDate.toString());
  }, [allEmployment, applyDate, applyDateStr]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleAllEmployment = useCallback(() => {
    setAllEmployment(!allEmployment);
  }, [allEmployment]);

  return {
    employmentList,
    fetchData,
    allEmployment,
    toggleAllEmployment,
    applyDate,
    setApplyDate,
    blocking,
  };
};

export default {};
