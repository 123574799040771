/* eslint-disable max-len */
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import ScheduleMobilePage from '../../schedule/ScheduleMobilePage';
import ScheduleWebPage from './ScheduleWebPage ';

const ShiftPage: React.FC = () => (
  <>
    {isMobileOnly && (
      <ScheduleMobilePage />
    )}
    {!isMobileOnly && (
      <ScheduleWebPage />
    )}
  </>
);

export default ShiftPage;
