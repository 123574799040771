import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesDailyReportLayout from 'components/organismos/master/storeManagement/salesReport/SalesDailyReportLayout';

const SalesDailyReportPage = () => (
  <SidebarTemplate pageTitle="レポート（日次）">
    <SalesDailyReportLayout />
  </SidebarTemplate>
);
export default SalesDailyReportPage;
