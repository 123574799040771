/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  eqeqeq */

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormContents from 'components/atoms/Form/FormContents';
import FormLabel from 'components/atoms/Form/FormLabel';
import BlockUI from 'components/molecules/BlockUi';
import Checkbox from 'components/molecules/Checkbox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import IconLabelButton from 'components/molecules/IconLabelButton';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import React, { useCallback, useMemo } from 'react';
import { isMacOs } from 'react-device-detect';
import moment from 'moment';
import useBankDepositReport from './hooks';
import { BankDepositMatchingType } from 'api/bankDeposit';
import BankDepositReportDomain from 'domain/master/storeManagement/bankDepositReport'

const styles = {
  text: css({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '50px',
  }),
};

const headerInformationList: {[key in BankDepositMatchingType]: { headerInformation: string[][], tableHeader: string[] } } = {
  0 : {
    headerInformation: [ ['店舗コード', '店舗名', '営業日', '', '銀行', 'POS', '', '差額', '備考'], ['', '', '開始', '終了', '入金額', '現金売上', '現金過不足', '', ''] ],
    tableHeader      : ['開始', '終了', '入金額', '現金売上', '現金過不足'],
  },
  1 : {
    headerInformation: [ ['店舗コード', '店舗名', '営業日', '', '銀行', 'POS', '', '差額', '備考'], ['', '', '開始', '終了', '入金額', '銀行預入金', '現金過不足', '', ''] ],
    tableHeader      : ['開始', '終了', '入金額', '銀行預入金', '現金過不足'],
  },
  2 : {
    headerInformation: [ ['店舗コード', '店舗名', '営業日', '', '銀行', 'POS', '', '差額', '備考'], ['', '', '開始', '終了', '入金額', '紙幣合計', '現金過不足', '', ''] ],
    tableHeader      : ['開始', '終了', '入金額', '紙幣合計', '現金過不足'],
  },
};

const PaymentDetailsLayout: React.FC<{ role?: any }> = ({ role }) => {
  const {
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    orgCode,
    setOrgCode,
    orgName,
    setorgName,
    differenceCheckBox,
    setDifferenceCheckbox,
    isNoRelevantShop,
    setIsNoRelevantShop,
    categoryAry,
    setCategoryAr,
    functionType,
    staffCode,
    bankDepositMatchingType,
    bankDetailData,
    numberFormater,
    checkUserRole,
    loading,
    downloadBankDepositDetailCSV,
    downloadBankDepositDetailExcel,
  } = useBankDepositReport();

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDifferenceCheckbox(e.target.checked);
  };
  const handleNoRelevantShop = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsNoRelevantShop(e.target.checked);
  };
  const searchResult = useMemo(() => {
    const result: any[] = [];
    let orgCodeColumn: React.ReactNode;
    let orgNameColumn: React.ReactNode;
    let startWorkingDate: string;
    let endWorkingDate: string;
    let bankDeposit: string;
    let bankCashSales: string;
    let cashExcess: string;
    let difference: string;
    let prepare: string;
    bankDetailData.map((item: BankDepositReportDomain) => {
      orgCodeColumn = item.orgCode ? item.orgCode : '';
      orgNameColumn = item.orgName ? item.orgName : '';
      startWorkingDate = item.businessStartDate ? moment(item.businessStartDate).format('YYYY-MM-DD') : '';
      endWorkingDate = item.businessEndDate ? moment(item.businessEndDate).format('YYYY-MM-DD') : '';
      bankDeposit = item.depositAmount ? numberFormater(item.depositAmount) : '';
      bankCashSales = item.paymentMediaAmount ? numberFormater(item.paymentMediaAmount) : '';
      cashExcess = item.excessAndDeficiency ? numberFormater(item.excessAndDeficiency) : '';
      difference = item.cashDifference ? numberFormater(item.cashDifference) : '';
      prepare = item.remark ? item.remark : '';
      result.push([
        orgCodeColumn || '',
        orgNameColumn || '',
        startWorkingDate || '',
        endWorkingDate || '',
        bankDeposit || 0,
        bankCashSales || 0,
        cashExcess || 0,
        difference || 0,
        prepare || '',
      ]);
    });
    return result;
  }, [bankDetailData, numberFormater]);

  const headerInformation = useMemo(() => headerInformationList[bankDepositMatchingType].headerInformation, [bankDepositMatchingType]);
  const tblHeaders = useMemo(() => headerInformationList[bankDepositMatchingType].tableHeader, [bankDepositMatchingType]);

  let mergedRegions: any[] = [];
  const codeColumnIndex: any[] = [];
  const data = useMemo(() => ({
    footer: '',
    formName: '入出金明細',
    targetPeriod: `営業日: ${moment(targetDateFrom).format('YYYY-MM-DD')}~${moment(targetDateTo).format('YYYY-MM-DD')}`,
    targetStores: `店舗名：${orgName}`,
    headerInformation,
    searchResult,
    totalInformation: [],
  }), [headerInformation, orgName, searchResult, targetDateFrom, targetDateTo]);

  const dataExcel = useMemo(() => ({
    formName: '入出金明細',
    targetPeriod: `営業日: ${moment(targetDateFrom).format('YYYY-MM-DD')}~${moment(targetDateTo).format('YYYY-MM-DD')}`,
    targetStores: `店舗名：${orgName}`,
    headerInformation,
    searchResult,
    totalInformation: [],
    mergedRegions,
    codeColumnIndex,
  }), [codeColumnIndex,
    headerInformation, mergedRegions, orgName, searchResult, targetDateFrom, targetDateTo]);

  const handleExportBudgetPerfomanceManagementCSV = useCallback(() => {
    downloadBankDepositDetailCSV(data);
  }, [data, downloadBankDepositDetailCSV]);

  const handleExportBudgetPerfomanceManagementExcel = useCallback(() => {
    if (mergedRegions) {
      mergedRegions = [];
    }
    mergedRegions.push([3, 4, 0, 0]);
    mergedRegions.push([3, 4, 1, 1]);
    mergedRegions.push([3, 3, 2, 3]);
    mergedRegions.push([3, 3, 5, 6]);
    mergedRegions.push([3, 4, 7, 7]);
    mergedRegions.push([3, 4, 8, 8]);
    dataExcel.mergedRegions = mergedRegions;
    dataExcel.codeColumnIndex = [0];
    dataExcel.headerInformation = headerInformation;
    return downloadBankDepositDetailExcel(dataExcel);
  }, [mergedRegions, dataExcel]);

  return (
    <React.Fragment>
      <BlockUI blocking={loading}>
        <FormContents>
          <div>
            <FlexBox>
              <FlexBoxItem width="100px">
                <FormLabel
                  label="営業日"
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <DatePickerForm
                  dateFormat="yyyy年MM月dd日"
                  label=""
                  date={targetDateFrom}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      setTargetDateFrom(date);
                    }
                  }}
                  isFullWidth={true}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <span style={{ margin: '0 30px' }}>～</span>
              </FlexBoxItem>
              <FlexBoxItem>
                <DatePickerForm
                  dateFormat="yyyy年MM月dd日"
                  label=""
                  date={targetDateTo}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      setTargetDateTo(date);
                    }
                  }}
                  isFullWidth={true}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <span style={{ margin: '0 15px' }}> </span>
              </FlexBoxItem>
              <FlexBoxItem customStyle={css({ paddingTop: 16.5 })}>
                <Checkbox
                  id="difference"
                  name="difference"
                  label="差額ありのみ"
                  value={String(differenceCheckBox)}
                  checked={differenceCheckBox}
                  onChange={(e) => handleCheckBoxChange(e)}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <span style={{ margin: '0 15px' }}> </span>
              </FlexBoxItem>
              <FlexBoxItem customStyle={css({ paddingTop: 16.5 })}>
                <Checkbox
                  id="noRelevantShop"
                  name="noRelevantShop"
                  label="該当店舗なしのみ"
                  value={String(isNoRelevantShop)}
                  checked={isNoRelevantShop}
                  onChange={(e) => handleNoRelevantShop(e)}
                />
              </FlexBoxItem>

            </FlexBox>
          </div>
          <div>
            <OrganizationFilteredTree
              functionType={functionType}
              staffCode={staffCode}
              targetdayForm={targetDateFrom}
              targetdayTo={targetDateTo}
              addAllItem={true}
              initOrgValue={String(orgCode)}
              orgLabel="店舗名"
              orgCallback={(args: any) => {
                setOrgCode(String(args));
              }}
              orgCategoryCallback={
                (args: Array<string>) => {
                  setCategoryAr(args);
                }
              }
              orgNameCallback={(arg: any) => {
                setorgName(String(arg));
              }}
            />
            {checkUserRole !== null && checkUserRole.downloadFlag === 1 && (
              <FlexBox>
                {/* {
                roleScreen !== null && roleScreen.downloadFlag === 1 && (
                  <React.Fragment> */}
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportBudgetPerfomanceManagementCSV}
                    iconType="download"
                    text=" CSV出力"
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportBudgetPerfomanceManagementExcel}
                    iconType="download"
                    text=" EXCEL出力"
                  />
                </FlexBoxItem>
                {/* </React.Fragment>
                )
              } */}
              </FlexBox>
            )}
          </div>
          {/* render table */}
          <div style={{ margin: '15px' }}>
            <div
              className="templateTable newDesignTable"
              style={{
                height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: isMacOs ? '35px' : '1px',
              }}
            >
              <table className="table table-bordered text-nowrap">
                <thead>
                  <tr>
                    <th
                      rowSpan={2}
                      style={{
                        top: '0', left: '0', zIndex: 99, maxWidth: ' 200px', width: '200px', minWidth: '200px',
                      }}
                      className="text-center stickyStyle fristColSticky"
                    >
                      <span>店舗コード</span>
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        top: '0', left: '203px', zIndex: 99, maxWidth: ' 200px', width: '200px', minWidth: '200px',
                      }}
                      className="text-center stickyStyle "
                    >
                      <span>店舗名 </span>
                    </th>
                    <th colSpan={4} style={{ top: '0' }} className="text-center stickyStyle"><span>営業日</span></th>
                    <th colSpan={2} style={{ top: '0' }} className="text-center stickyStyle"><span>銀行</span></th>
                    <th colSpan={4} style={{ top: '0' }} className="text-center stickyStyle"><span>POS</span></th>
                    <th rowSpan={2} colSpan={4} style={{ top: '0' }} className="text-center stickyStyle"><span>差額</span></th>
                    <th rowSpan={2} colSpan={4} style={{ top: '0' }} className="text-center stickyStyle"><span>備考</span></th>
                  </tr>
                  <tr>
                    {tblHeaders.map((item: any, index: any) => (<th colSpan={2} key={index} style={{ top: '34px' }} className="text-center stickyStyle"><span>{item}</span></th>))}
                  </tr>
                </thead>
                <tbody>
                  {
                    searchResult.map((rows: any[], index: number) => {
                      const orgCodeColumn = rows[0];
                      const orgNameColumn = rows[1];
                      const differenceColumn = rows[rows.length - 2];
                      const prepareColumn = rows[rows.length - 1];
                      return (
                        <tr key={index}>
                          {
                            rows.map((cell: any, idx: number) => {
                              if (idx === 0) {
                                return (
                                  // orgCodeColumn
                                  <td key={idx} className="text-center stickyStyle fristColSticky" style={{ left: '0', zIndex: 10 }}>
                                    <div className="text-ellipsis"><span>{orgCodeColumn}</span></div>
                                  </td>
                                );
                              } if (idx === 1) {
                                // orgNameColumn
                                return (
                                  <td key={idx} className="text-center stickyStyle" style={{ left: '203px', zIndex: 10 }}>
                                    <span>
                                      <div className="text-ellipsis">
                                        {orgNameColumn}
                                      </div>
                                    </span>
                                  </td>
                                );
                              }
                              if (idx < (rows && rows.length - 2)) {
                                return (
                                  <td colSpan={2} key={idx} style={{ textAlign: idx === 2 ? 'center' : idx === 3 ? 'center' : 'right' }}><span>{cell}</span></td>
                                );
                              }
                              return <td colSpan={4} key={idx} style={{ textAlign: idx === 8 ? 'left' : 'right' }} css={styles.text}><span>{cell}</span></td>;
                            })
                          }
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </FormContents>
      </BlockUI>
    </React.Fragment>
  );
};

export default PaymentDetailsLayout;
