import {
  useState, useCallback, useEffect,
} from 'react';
import { useFormik } from 'formik';
import { createOrUpdateHoliday } from 'api/holiday';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import HolidayDomain from 'domain/holiday';
import { getList, getHolidaysOrgList } from 'api/holidayMaster';
import useToastNotification from 'hooks/useToastNotification';

type HolidayDomainKey = keyof Pick<HolidayDomain, 'holidayDate'|'holidayName'>;

export const useHolidayAddForm = (publicHolidayId?: string, holidayDate?:Date) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const history = useHistory();
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const { successNotification, errorNotification } = useToastNotification();
  const [active, setActive] = useState('selection');

  const onSubmit = async (val: any) => {
    if (val.holidayDate === null) {
      errorNotification('日付を入力してください。');
      return;
    }
    if (val.holidayName === '') {
      errorNotification('祝日名を入力してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    try {
      let orgList: string[] = [];
      const postData = val.getRawData();
      const publicDateString : string = moment(val.holidayDate).format('YYYY年MM月DD日');
      postData.holidayDateStr = publicDateString;
      postData.loginUser = sessionStorage.getItem('loginUser.staffName') || '';
      if (publicHolidayId === null || publicHolidayId === '') {
        if (val.isAll) {
          orgList = ['all'];
          postData.orgCodes = orgList;
        }
      }
      const response = await createOrUpdateHoliday(postData);
      if (!response.errors) {
        setConfirmModalOpen(false);
        successNotification(publicHolidayId === null || publicHolidayId === '' ? '登録しました' : '更新しました');
        history.goBack();
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };
  const formik = useFormik({
    initialValues: HolidayDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    if (publicHolidayId !== undefined && publicHolidayId !== '') {
      getList(companyCode, sessionStorage.getItem('selectedYearHolidayMaster') || new Date().getFullYear().toString(), sessionStorage.getItem('selectedOrgCodeHolidayMaster') || 'all').then((response: Array<any>) => {
        response.filter((item) => {
          if (item.publicHolidayId === publicHolidayId) {
            formik.setFieldValue('publicHolidayId', item.publicHolidayId);
            formik.setFieldValue('holidayName', item.publicHolidayName);
            formik.setFieldValue('isAll', item.isAll);
            formik.setFieldValue('isEdit', item.isEdit);
            formik.setFieldValue('holidayDate', new Date(moment(item.publicHolidayDate).format('YYYY/MM/DD')));
            return true;
          }
          return false;
        });
      });

      getHolidaysOrgList(companyCode, publicHolidayId).then((response: Array<any>) => {
        formik.setFieldValue('dispOrg', response[0].orgName);
        formik.setFieldValue('isEdit', true);
        // formik.setFieldValue('org', response[0].orgCode);
        // formik.setFieldValue('organization', [response[0].orgCode]);
      });
    } else {
      formik.setFieldValue('isEdit', false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicHolidayId]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    history,
    active,
    setActive,
  };
};


export default {
  useHolidayAddForm,
};
