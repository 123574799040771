import React from "react";
import ApplyingStampDomain from "../domain/applyingStampDomain";
import ApplyingHolidayDomain from "../domain/applyingHolidayDomain";
import ApplyingOvertimeDomain from "../domain/applyingOvertimeDomain";
import ApplyingHolidayWorkDomain from "../domain/applyingHolidayWorkDomain";
import ApplyingTransferDomain from "../domain/applyingTransferDomain";
import StampAccordion from "./StampAccordion";
import HolidayAccordion from "./HolidayAccordion";
import OvertimeAccordion from "./OvertimeAccordion";
import HolidayWorkAccordion from "./HolidayWorkAccordion";
import TransferAccordion from "./TransferAccordion";

const ApplyingTab: React.FC<{
  stampList: ApplyingStampDomain[];
  holidayList: ApplyingHolidayDomain[];
  overtimeList: ApplyingOvertimeDomain[];
  holidayWorkList: ApplyingHolidayWorkDomain[];
  transferList: ApplyingTransferDomain[];
  
  fetchApprovedStampList: any;
  fetchApprovedHolidayList: any;
  fetchApprovedOvertimeList: any;
  fetchApprovedHolidayWorkList: any;
  fetchApprovedTransferList: any;

  fetchRejectedStampList: any;
  fetchRejectedHolidayList: any;
  fetchRejectedOvertimeList: any;
  fetchRejectedHolidayWorkList: any;
  fetchRejectedTransferList: any;

  setApplyingTransferList: any;
  setApplyingStampList: any;
  setApplyingHolidayList: any;
  setApplyingOvertimeList: any;
  setApplyingHolidayWorkList: any;
}> = ({
  stampList,
  holidayList,
  overtimeList,
  holidayWorkList,
  transferList,
  
  fetchApprovedStampList,
  fetchApprovedHolidayList,
  fetchApprovedOvertimeList,
  fetchApprovedHolidayWorkList,
  fetchApprovedTransferList,
  
  fetchRejectedStampList,
  fetchRejectedHolidayList,
  fetchRejectedOvertimeList,
  fetchRejectedHolidayWorkList,
  fetchRejectedTransferList,

  setApplyingTransferList,
  setApplyingStampList,
  setApplyingHolidayList,
  setApplyingOvertimeList,
  setApplyingHolidayWorkList
}) => {
  return (
    <div>
      <StampAccordion
        list={stampList}
        setApplyingStampList={setApplyingStampList}
        fetchApprovedList={fetchApprovedStampList}
        fetchRejectedList={fetchRejectedStampList}
      />

      <HolidayAccordion
        list={holidayList}
        setApplyingHolidayList={setApplyingHolidayList}
        fetchApprovedList={fetchApprovedHolidayList}
        fetchRejectedList={fetchRejectedHolidayList}
      />

      <OvertimeAccordion
        list={overtimeList}
        setApplyingOvertimeList={setApplyingOvertimeList}
        fetchApprovedList={fetchApprovedOvertimeList}
        fetchRejectedList={fetchRejectedOvertimeList}
      />

      <HolidayWorkAccordion
        list={holidayWorkList}
        setApplyingHolidayWorkList={setApplyingHolidayWorkList}
        fetchApprovedList={fetchApprovedHolidayWorkList}
        fetchRejectedList={fetchRejectedHolidayWorkList}
      />

      <TransferAccordion
        list={transferList}
        setApplyingTransferList={setApplyingTransferList}
        fetchApprovedList={fetchApprovedTransferList}
        fetchRejectedList={fetchRejectedTransferList}
      />
    </div>
  );
};

export default ApplyingTab;
