import { useState, useEffect } from 'react';
import {
  searchDate,
} from 'api/achievementConfirm';

export const useGetSearchDateClosing = () => {
  const [targetMonthProp, setTargetMonth] = useState(new Date('9999-12-31'));
  const [targetDateFromProp, setTargetDateFrom] = useState(new Date('9999-12-31'));
  const [targetDateToProp, setTargetDateTo] = useState(new Date('9999-12-31'));
  useEffect(() => {
    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    searchDate({ staffCode }).then((response: any) => {
      setTargetDateFrom(new Date(response[0]));
      setTargetDateTo(new Date(response[1]));
      setTargetMonth(new Date(response[1]));
    });
  }, []);

  return {
    targetMonthProp,
    targetDateFromProp,
    targetDateToProp,
  };
};

export default {
  useGetSearchDateClosing,
};
