import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './modules/store';
import GlobalStyle from './components/GlobalStyle';
import App from './App';

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.error = () => {};
}

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <App />
  </Provider>,
  document.getElementById('root'),
);
