import React from 'react';
import { useParams } from 'react-router-dom';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button, { PrimaryButton } from 'components/atoms/Button';
import usePostTypeOptions from 'hooks/usePostTypeOptions';
import useDiscountOptions from 'hooks/useDiscountOptions';
import DatePickerForm from 'components/molecules/DatePickerForm';
import Checkbox from 'components/molecules/Checkbox';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FlexBox from 'components/atoms/FlexBox';
import { css } from '@emotion/core';
import PriceWithrowalDomain from 'domain/master/sales/priceWithrowal';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { useAdImportForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const PriceWithdrawlAddForm: React.FC = () => {
  const { orgCode, discountTypeCode, posType, discountTypeMstCode, applicableStartMonthFormat } = useParams();
  console.log('discountTypeCode', discountTypeCode);
  console.log('orgCode1', orgCode);
  const {
    formik, confirmModalOpen,
    closeConfirmModal, history,
  } = useAdImportForm(orgCode, discountTypeCode, posType, discountTypeMstCode, applicableStartMonthFormat );

  const postTypeOptions = usePostTypeOptions();
  const discounTypeOptions = useDiscountOptions();
  // Set default value
  if (formik.values.discountTypeMstCode === '' && discounTypeOptions.length !== 0) {
    formik.setFieldValue('discountTypeMstCode', discounTypeOptions[0].value);
  }
  if (formik.values.posType === '' && postTypeOptions.length !== 0) {
    formik.setFieldValue('posType', postTypeOptions[0].value);
  }

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <FormContents>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormField>
            <div style={{ width: '100%' }}>
              <VerticalLabelSelectForm
                label="値引・割引種別"
                name="discountTypeMstCode"
                value={formik.values.discountTypeMstCode}
                options={discounTypeOptions}
                setValue={(val: string) => { formik.setFieldValue('discountTypeMstCode', val); }}
                errorMsg={formik.errors.discountTypeMstCode}
                required={true}
                disable={!!discountTypeCode}
              />
            </div>
          </FormField>
          <FormField>
            <div style={{ width: '100%' }}>
              <VerticalLabelSelectForm
                label="POS種別"
                name="posType"
                value={String(formik.values.posType)}
                options={postTypeOptions}
                setValue={(val: string) => { formik.setFieldValue('posType', Number(val)); }}
                errorMsg={formik.errors.posType}
                required={true}
                disable={!!discountTypeCode}
              />
            </div>
          </FormField>
          <FormField>
            <TextForm
              label="取込コード"
              name="discountTypeCode"
              value={formik.values.discountTypeCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('discountTypeCode', (e.target.value))}
              errorMsg={formik.errors.discountTypeCode}
              required={true}
              disable={!!discountTypeCode}
            />
          </FormField>

          <FormField>
            <FlexBox
              customStyle={css({ width: '50%' })}
            >
              <FlexBoxItem basis="80%">
                <DatePickerForm
                  dateFormat="yyyy年MM月"
                  label="適用開始月"
                  date={formik.values.applicableStartMonth}
                  changeDate={(date: Date) => {
                    if (date == null) {
                      formik.setFieldValue('applicableStartMonth', undefined);
                    } else {
                      formik.setFieldValue('applicableStartMonth', date);
                    }
                  }}
                  showMonthYearPicker={true}
                  required={true}
                  disable={!!discountTypeCode}
                  isShowDateOnDisable={true}
                />
              </FlexBoxItem>
              <FlexBoxItem basis="20%" marginLeft="20px" />

            </FlexBox>

          </FormField>
          <FormField>
            <FlexBox
              customStyle={css({ width: '50%' })}
            >
              <FlexBoxItem basis="80%">
                <DatePickerForm
                  dateFormat="yyyy年MM月"
                  label="適用終了月"
                  date={formik.values.applicableEndMonthStr === '9999年12月' || formik.values.applicableEndMonthStr === '-' ? undefined : formik.values.applicableEndMonth}
                  changeDate={(date: Date) => {
                    if (date == null) {
                      formik.setFieldValue('applicableEndMonth', new Date('9999-12-31'));
                    } else {
                      formik.setFieldValue('applicableEndMonth', date);
                    }
                  }}

                  showMonthYearPicker={true}
                  disable={formik.values.applicableEndMonthStr === '9999年12月' || formik.values.applicableEndMonthStr === '-'}
                  isShowDateOnDisable={false}
                />
              </FlexBoxItem>
              <FlexBoxItem basis="20%" marginLeft="20px">
                <div style={{ marginTop: '15px' }}>
                  <Checkbox
                    id="unDecided"
                    name="unDecided"
                    label="未定"
                    value={formik.values.applicableEndMonthStr === '9999年12月' || formik.values.applicableEndMonthStr === '-' ? 'checked' : 'unChecked'}
                    checked={formik.values.applicableEndMonthStr === '9999年12月' || formik.values.applicableEndMonthStr === '-'}
                    onChange={(e) => {
                      if (e.target.value === 'checked') {
                        formik.setFieldValue('applicableEndMonth', new Date());
                      } else {
                        formik.setFieldValue('applicableEndMonth', new Date('9999-12-31'));
                      }
                    }}
                  />
                </div>
              </FlexBoxItem>
            </FlexBox>
          </FormField>

        </div>

        <FormSubmitArea>
          {
            roleScreen && roleScreen.editable === 1 && (
              <div style={{ marginRight: '12px' }}>
                <Button
                  text={discountTypeCode ? '更新' : '登録'}
                  onClick={formik.handleSubmit}
                />
              </div>
            )
          }

          <PrimaryButton
            text="戻る"
            onClick={() => {
              history.replace('/discountTypeMst/priceWithdrawal');
            }}
            ghost={true}
          />
        </FormSubmitArea>
      </form>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={discountTypeCode ? ActionType.UPDATE : ActionType.CREATE}
      />
    </FormContents>

  );
};
export default PriceWithdrawlAddForm;
