/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import productColor, { grayScale, textFontSize, iconColor } from 'components/styles';

const styles = {
  wrapper: css({
    display: 'flex',
    maxWidth: '176px',
  }),
  input: css({
    flex: 1,
    width: '32px',
    padding: '7px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    color: grayScale.gray100,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: 'none',
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px 0 0 4px',
  }),
  addon: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '7px',
    fontSize: textFontSize.re,
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
  }),
  grayOut: css({
    background: iconColor.gray,
  }),
  fullWidth: css({
    width: '100%',
    maxWidth: '100%',
  }),
  noLabel: css({
    borderRight: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px',
  }),
};

const handleOnInput = (e:any, maxLength: any) => {
  if (!maxLength || maxLength <= 0 || isNaN(maxLength)) {
    return;
  }
  if (e.target.value.length > maxLength) {
    e.target.value = e.target.value.slice(0, maxLength);
  }
};

const HourInput: React.FC<{
  name: string;
  value?: any;
  label?: string;
  isFullW?: boolean;
  readOnly?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  min?: number;
  max?: number;
  maxLength?:number;
  step?: number;
}> = ({
  name, value = '', label = null, onChange, onBlur, isFullW = false, readOnly = false, min, max, step, maxLength,
}) => (
  <div css={css(styles.wrapper, readOnly ? styles.grayOut : {}, isFullW ? styles.fullWidth : {})}>
    <input
      css={css(
        styles.input,
        readOnly ? styles.grayOut : {},
        label === null ? styles.noLabel : {},
        isFullW ? styles.fullWidth : {},
      )}
      type="number"
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      readOnly={readOnly}
      min={min}
      max={max}
      step={step}
      onInput={(e) => handleOnInput(e, maxLength)}
    />
    {label && (
      <div css={styles.addon}>
        {label}
      </div>
    )}
  </div>
);

export default HourInput;
