import React, { useEffect } from 'react';

const TableBiasSettings: React.FC<{
  formik: any,
  listBiasSettings: any,
  getRows: any,
  handleChangeInputBias: any
}> = ({
  listBiasSettings,
  getRows,
  formik,
  handleChangeInputBias,
}) => {
  useEffect(() => {
    const contentRight = document.getElementById('table_bias_sale_right_1');
    const contentRight2 = document.getElementById('table_bias_sale_right_2');
    const contentRight3 = document.getElementById('table_bias_sale_right_3');
    if (contentRight && contentRight2 && contentRight3) {
      contentRight3.addEventListener('scroll', () => {
        contentRight.scrollLeft = contentRight3.scrollLeft;
        contentRight2.scrollLeft = contentRight3.scrollLeft;
      });
      contentRight2.addEventListener('scroll', () => {
        contentRight.scrollLeft = contentRight2.scrollLeft;
        contentRight3.scrollLeft = contentRight2.scrollLeft;
      });
      contentRight2.addEventListener('touchmove', () => {
        contentRight.scrollLeft = contentRight2.scrollLeft;
      });
    }
  }, []);
  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const handleInputChange = (name: any, value: any) => {
    const { target } = value;
    if (target.value) {
      formik.setFieldValue(name, target.value);
      handleChangeInputBias();
    }
    return target.value;
  };

  return (
    <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
      <div className="bias-settings__top">
        <div className="layout_sale_left">
          <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '301px' }}>
            <tbody>
              <tr>
                <td className="text-center active" style={{ width: '33%' }} rowSpan={2}><small>ステータス</small></td>
                <td className="text-center active" style={{ width: '33%' }} rowSpan={2}>バイアス</td>
                <td className="text-center active" style={{ width: '33%' }} rowSpan={2}>日数</td>

                <td className="text-center active" colSpan={2} style={{ paddingLeft: '0px !important', paddingRight: '0px !important', border: 'none' }}><br /></td>
              </tr>
              <tr>
                <td className="text-center active" style={{ paddingLeft: '0px !important', paddingRight: '0px !important', border: 'none' }}><br /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="layout_sale_right" id="table_bias_sale_right_1" style={{ marginRight: '20px' }}>
          <div className="table-responsive no-padding layout-sale-right">
            <table className="table table-bordered table-striped table-condensed text-nowrap">
              <tbody>
                <tr>
                  <td className="text-center " rowSpan={2}>売上高</td>
                  {/* <td className="text-center " rowSpan={2}>標準原価</td> */}
                  <td className="text-center " rowSpan={2}>F（食材費）</td>
                  <td className="text-center " colSpan={3}>L（人件費）</td>
                  <td className="text-center " rowSpan={2}>A（広告費）</td>
                  <td className="text-center " rowSpan={2}>FLA合計</td>
                  <td className="text-center " rowSpan={2}>店舗利益</td>
                  <td className="text-center " rowSpan={2}>R（家賃）</td>
                  <td className="text-center" colSpan={3}>O（その他経費）</td>
                  <td className="text-center " rowSpan={2}>FLARO合計</td>
                  <td className="text-center " rowSpan={2}>営業利益</td>
                </tr>
                <tr>
                  <td className="text-center ">合計</td>
                  <td className="text-center ">社員</td>
                  <td className="text-center ">アルバイト</td>
                  <td className="text-center ">合計</td>
                  <td className="text-center ">固定費</td>
                  <td className="text-center ">変動費</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="bias-settings__bottom">
        <div className="layout_sale_left">
          <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '298px' }}>
            <tbody>
              <tr>
                <td className="text-center active" style={{ width: ' 33%' }} rowSpan={2}>繁忙日</td>
                <td className="text-right active" style={{ width: '33%' }} rowSpan={2}>
                  <p style={{ display: 'flex', justifyContent: 'center', margin: '0 0' }}>
                    <input
                      type="number"
                      className="form-control input-sm weekdayCnt biasInput"
                      style={{ textAlign: 'right', minWidth: '50px', maxWidth: '70px' }}
                      value={formik.values.busyBias}
                      id="busyBias"
                      onChange={(val) => handleInputChange('busyBias', val)}
                    />
                    <span style={{ position: 'relative', top: '5px' }}> % </span>
                  </p>
                </td>
                <td className="text-center" style={{ width: '33%' }}>
                  <span id="amountOfBusyDay">
                    {getRows.getAmountOfBusyDay || 0}
                    日合計
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ display: 'none', width: '33%' }} />
                <td style={{ display: 'none', width: '33%' }} />
                <td className="text-center active" style={{ width: '33%' }}>1日あたり</td>
              </tr>
              <tr>
                <td className="text-center active" style={{ width: '33%' }} rowSpan={2}>通常日</td>
                <td className="text-center active" style={{ width: '33%' }} rowSpan={2}>
                  {listBiasSettings ? listBiasSettings.normal : 0}
                  %
                </td>
                <td className="text-center active" style={{ width: '33%' }}>
                  <span id="amountOfNormalDay">
                    {getRows.getAmountOfNormalDay}
                    日合計
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ display: 'none', width: '33%' }} />
                <td style={{ display: 'none', width: '33%' }} />
                <td className="text-center active" style={{ width: '33%' }}>1日あたり</td>
              </tr>
              <tr>
                <td className="text-center active" style={{ width: '33%' }} rowSpan={2}>閑散日</td>
                <td className="text-center active" style={{ width: '33%' }} rowSpan={2}>
                  <p style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 0',
                  }}
                  >
                    <input
                      type="number"
                      className="form-control input-sm weekdayCnt biasInput"
                      style={{ textAlign: 'right', minWidth: '50px', maxWidth: '70px' }}
                      value={formik.values.quietBias}
                      id="quietBias"
                      onChange={(val) => handleInputChange('quietBias', val)}
                    />
                    <span style={{ position: 'relative' }}> % </span>
                  </p>
                </td>
                <td className="text-center active" style={{ width: '33%' }}>
                  <span id="amountOfQuietDay">
                    {getRows.getAmountOfQuietDay || 0}
                    日合計
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ display: 'none', width: '33%' }} />
                <td style={{ display: 'none', width: '33%' }} />
                <td className="text-center active" style={{ width: '33%' }}>1日あたり</td>
              </tr>
              <tr>
                <td className="text-center active" style={{ width: '33%' }} rowSpan={2}>定休日</td>
                <td className="text-center active" style={{ width: '33%' }} rowSpan={2}>
                  {listBiasSettings ? listBiasSettings.holiday : 0}
                  %
                </td>
                <td className="text-center active" style={{ width: '33%' }}>
                  <span id="amountOfHolidayDay">
                    {getRows.getAmountOfHolidayDay || 0}
                    日合計
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ display: 'none', width: '33%' }} />
                <td style={{ display: 'none', width: '33%' }} />
                <td className="text-center active" style={{ width: '33%' }}>1日あたり</td>
              </tr>
              <tr>
                <td className="text-center active" colSpan={2} rowSpan={2}>全体</td>
                <td style={{ display: 'none' }} />
                <td className="text-center active">
                  {formik.values.saleBudgetListData.length}
                  日合計
                </td>
              </tr>
              <tr>
                <td style={{ display: 'none' }} />
                <td style={{ display: 'none' }} />
                <td className="text-center active">1日あたり</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="layout_sale_right" id="table_bias_sale_right_2" style={{ marginRight: '20px' }}>
          <table>
            <tbody>
              <tr>
                <td className="text-right ">
                  <span id="amountOfSaleBusyDay">
                    {formatter.format(getRows.getAmountOfSaleBusyDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfStandardCostBusyDay">
                    {formatter.format(getRows.getAmountOfStandardCostBusyDay || 0)}
                  </span>
                </td>
                {/* <td className="text-right ">
                  <span id="amountOfGrossProfitBusyDay">
                    {formatter.format(getRows.getAmountOfGrossProfitBusyDay || 0)}
                  </span>
                </td> */}
                <td className="text-right ">
                  <span id="amountOfEmployeeCostAndPartLaborCostBusyDay">
                    {formatter.format(getRows.getAmountOfEmployeeCostAndPartLaborCostBusyDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfEmployeeCostBusyDay">{formatter.format(getRows.getAmountOfEmployeeCostBusyDay || 0)}</span>
                </td>
                <td className="text-right ">
                  <span id="amountOfPartLaborCostBusyDay">{formatter.format(getRows.getAmountOfPartLaborCostBusyDay || 0)}</span>
                </td>
                <td className="text-right ">
                  <span id="amountOfAdvertisingExpenseBusyDay">
                    {formatter.format(getRows.getAmountOfAdvertisingExpenseBusyDay || 0)}
                  </span>
                </td>
                {/* FLA合計	 */}
                <td className="text-right ">
                  <span id="amountOfAdvertisingExpenseBusyDay">
                    {formatter.format(getRows.getAmountOfFLABusyDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfAdvertisingExpenseBusyDay">
                    {formatter.format(getRows.getAmountOfProfitLossCostBusyDay || 0)}
                  </span>
                </td>
                {/* 店舗利益	 */}
                <td className="text-right ">
                  <span id="amountOfLandRentBusyDay">{formatter.format(getRows.getAmountOfLandRentBusyDay || 0)}</span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherFixedAndVariableCostBusyDay">{formatter.format(getRows.getAmountOfOtherFixedAndVariableCostBusyDay || 0)}</span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherFixedCostBusyDay">{formatter.format(getRows.getAmountOfOtherFixedCostBusyDay || 0)}</span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherVariableCostBusyDay">{formatter.format(getRows.getAmountOfOtherVariableCostBusyDay || 0)}</span>
                </td>
                {/* FLARO合計 */}
                <td className="text-right ">
                  <span id="amountOperatingIncomeBusyDay">{formatter.format(getRows.getAmountOfFLAROBusyDay || 0)}</span>
                </td>
                {/* End FLARO合計 */}
                <td className="text-right ">
                  <span id="amountOperatingIncomeBusyDay">{formatter.format(getRows.getAmountOperatingIncomeBusyDay || 0)}</span>
                </td>
              </tr>
              <tr>
                <td className="text-right "><span id="amountOfSaleEachBusyDay">{formatter.format(getRows.getAmountOfSaleEachBusyDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfStandardCostEachBusyDay">{formatter.format(getRows.getAmountOfStandardCostEachBusyDay || 0)}</span></td>
                {/* <td className="text-right "><span id="amountOfGrossProfitEachBusyDay">{formatter.format(getRows.getAmountOfGrossProfitEachBusyDay || 0)}</span></td> */}
                <td className="text-right "><span id="amountOfEmployeeCostAndPartLaborCostEachBusyDay">{formatter.format(getRows.getAmountOfEmployeeCostAndPartLaborCostEachBusyDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfEmployeeCostEachBusyDay">{formatter.format(getRows.getAmountOfEmployeeCostEachBusyDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfPartLaborCostEachBusyDay">{formatter.format(getRows.getAmountOfPartLaborCostEachBusyDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfAdvertisingExpenseEachBusyDay">{formatter.format(getRows.getAmountOfAdvertisingExpenseEachBusyDay || 0)}</span></td>

                <td className="text-right "><span id="amountOfLandRentEachBusyDay">{formatter.format(getRows.getAmountOfFLAEachBusyDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherFixedAndVariableCostEachBusyDay">{formatter.format(getRows.getAmountOfProfitLossCostEachBusyDay || 0)}</span></td>

                <td className="text-right "><span id="amountOfLandRentEachBusyDay">{formatter.format(getRows.getAmountOfLandRentEachBusyDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherFixedAndVariableCostEachBusyDay">{formatter.format(getRows.getAmountOfOtherFixedAndVariableCostEachBusyDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherFixedCostEachBusyDay">{formatter.format(getRows.getAmountOfOtherFixedCostEachBusyDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherVariableCostEachBusyDay">{formatter.format(getRows.getAmountOfOtherVariableCostEachBusyDay || 0)}</span></td>

                <td className="text-right "><span id="amountOfOtherVariableCostEachBusyDay">{formatter.format(getRows.getAmountOfFLAROEachBusyDay || 0)}</span></td>

                <td className="text-right "><span id="amountOperatingIncomeEachBusyDay">{formatter.format(getRows.getAmountOperatingIncomeEachBusyDay || 0)}</span></td>
              </tr>
              <tr>
                <td className="text-right ">
                  <span id="amountOfSaleNormalDay">
                    {formatter.format(getRows.getAmountOfSaleNormalDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfStandardCostNormalDay">
                    {formatter.format(getRows.getAmountOfStandardCostNormalDay || 0)}
                  </span>
                </td>
                {/* <td className="text-right ">
                  <span id="amountOfGrossProfitNormalDay">
                    {formatter.format(getRows.getAmountOfGrossProfitNormalDay || 0)}
                  </span>
                </td> */}
                <td className="text-right ">
                  <span id="amountOfEmployeeCostAndPartLaborCostNormalDay">{formatter.format(getRows.getAmountOfEmployeeCostAndPartLaborCostNormalDay || 0)}</span>
                </td>
                <td className="text-right ">
                  <span id="amountOfEmployeeCostNormalDay">
                    {formatter.format(getRows.getAmountOfEmployeeCostNormalDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfPartLaborCostNormalDay">
                    {formatter.format(getRows.getAmountOfPartLaborCostNormalDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfAdvertisingExpenseNormalDay">
                    {formatter.format(getRows.getAmountOfAdvertisingExpenseNormalDay || 0)}
                  </span>
                </td>

                <td className="text-right ">
                  <span id="amountOfLandRentNormalDay">
                    {formatter.format(getRows.getAmountOfFLANormalDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherFixedAndVariableCostNormalDay">
                    {formatter.format(getRows.getAmountOfProfitLossCostNormalDay || 0)}
                  </span>
                </td>

                <td className="text-right ">
                  <span id="amountOfLandRentNormalDay">
                    {formatter.format(getRows.getAmountOfLandRentNormalDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherFixedAndVariableCostNormalDay">
                    {formatter.format(getRows.getAmountOfOtherFixedAndVariableCostNormalDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherFixedCostNormalDay">
                    {formatter.format(getRows.getAmountOfOtherFixedCostNormalDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherVariableCostNormalDay">
                    {formatter.format(getRows.getAmountOfOtherVariableCostNormalDay || 0)}
                  </span>
                </td>

                <td className="text-right ">
                  <span id="amountOfOtherVariableCostNormalDay">
                    {formatter.format(getRows.getAmountOfFLARONormalDay || 0)}
                  </span>
                </td>

                <td className="text-right ">
                  <span id="amountOperatingIncomeNormalDay">
                    {formatter.format(getRows.getAmountOperatingIncomeNormalDay || 0)}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="text-right "><span id="amountOfSaleEachNormalDay">{formatter.format(getRows.getAmountOfSaleEachNormalDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfStandardCostEachNormalDay">{formatter.format(getRows.getAmountOfStandardCostEachNormalDay || 0)}</span></td>
                {/* <td className="text-right "><span id="amountOfGrossProfitEachNormalDay">{formatter.format(getRows.getAmountOfGrossProfitEachNormalDay || 0)}</span></td> */}
                <td className="text-right "><span id="amountOfEmployeeCostAndPartLaborCostEachNormalDay">{formatter.format(getRows.getAmountOfEmployeeCostAndPartLaborCostEachNormalDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfEmployeeCostEachNormalDay">{formatter.format(getRows.getAmountOfEmployeeCostEachNormalDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfPartLaborCostEachNormalDay">{formatter.format(getRows.getAmountOfPartLaborCostEachNormalDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfAdvertisingExpenseEachNormalDay">{formatter.format(getRows.getAmountOfAdvertisingExpenseEachNormalDay || 0)}</span></td>

                <td className="text-right "><span id="amountOfLandRentEachNormalDay">{formatter.format(getRows.getAmountOfFLAEachNormalDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherFixedAndVariableCostEachNormalDay">{formatter.format(getRows.getAmountOfProfitLossCostEachNormalDay || 0)}</span></td>

                <td className="text-right "><span id="amountOfLandRentEachNormalDay">{formatter.format(getRows.getAmountOfLandRentEachNormalDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherFixedAndVariableCostEachNormalDay">{formatter.format(getRows.getAmountOfOtherFixedAndVariableCostEachNormalDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherFixedCostEachNormalDay">{formatter.format(getRows.getAmountOfOtherFixedCostEachNormalDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherVariableCostEachNormalDay">{formatter.format(getRows.getAmountOfOtherVariableCostEachNormalDay || 0)}</span></td>

                <td className="text-right "><span id="amountOfOtherVariableCostEachNormalDay">{formatter.format(getRows.getAmountOfFLAROEachNormalDay || 0)}</span></td>

                <td className="text-right "><span id="amountOperatingIncomeEachNormalDay">{formatter.format(getRows.getAmountOperatingIncomeEachNormalDay || 0)}</span></td>
              </tr>
              <tr>
                <td className="text-right ">
                  <span id="amountOfSaleQuietDay">{formatter.format(getRows.getAmountOfSaleQuietDay || 0)}</span>
                </td>
                <td className="text-right ">
                  <span id="amountOfStandardCostQuietDay">
                    {formatter.format(getRows.getAmountOfStandardCostQuietDay || 0)}
                  </span>
                </td>
                {/* <td className="text-right ">
                  <span id="amountOfGrossProfitQuietDay">
                    {formatter.format(getRows.getAmountOfGrossProfitQuietDay || 0)}
                  </span>
                </td> */}
                <td className="text-right ">
                  <span id="amountOfEmployeeCostAndPartLaborCostQuietDay">
                    {formatter.format(getRows.getAmountOfEmployeeCostAndPartLaborCostQuietDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfEmployeeCostQuietDay">
                    {formatter.format(getRows.getAmountOfEmployeeCostQuietDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfPartLaborCostQuietDay">
                    {formatter.format(getRows.getAmountOfPartLaborCostQuietDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfAdvertisingExpenseQuietDay">
                    {formatter.format(getRows.getAmountOfAdvertisingExpenseQuietDay || 0)}
                  </span>
                </td>

                <td className="text-right ">
                  <span id="amountOfLandRentQuietDay">
                    {formatter.format(getRows.getAmountOfFLAQuietDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherFixedAndVariableCostQuietDay">
                    {formatter.format(getRows.getAmountOfProfitLossCostQuietDay || 0)}
                  </span>
                </td>

                <td className="text-right ">
                  <span id="amountOfLandRentQuietDay">
                    {formatter.format(getRows.getAmountOfLandRentQuietDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherFixedAndVariableCostQuietDay">
                    {formatter.format(getRows.getAmountOfOtherFixedAndVariableCostQuietDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherFixedCostQuietDay">
                    {formatter.format(getRows.getAmountOfOtherFixedCostQuietDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherVariableCostQuietDay">
                    {formatter.format(getRows.getAmountOfOtherVariableCostQuietDay || 0)}
                  </span>
                </td>

                <td className="text-right ">
                  <span id="amountOperatingIncomeQuietDay">
                    {formatter.format(getRows.getAmountOfFLAROQuietDay || 0)}
                  </span>
                </td>

                <td className="text-right ">
                  <span id="amountOperatingIncomeQuietDay">
                    {formatter.format(getRows.getAmountOperatingIncomeQuietDay || 0)}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="text-right "><span id="amountOfSaleEachQuietDay">{formatter.format(getRows.getAmountOfSaleEachQuietDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfStandardCostEachQuietDay">{formatter.format(getRows.getAmountOfStandardCostEachQuietDay || 0)}</span></td>
                {/* <td className="text-right "><span id="amountOfGrossProfitEachQuietDay">{formatter.format(getRows.getAmountOfGrossProfitEachQuietDay || 0)}</span></td> */}
                <td className="text-right "><span id="amountOfEmployeeCostAndPartLaborCostEachQuietDay">{formatter.format(getRows.getAmountOfEmployeeCostAndPartLaborCostEachQuietDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfEmployeeCostEachQuietDay">{formatter.format(getRows.getAmountOfEmployeeCostEachQuietDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfPartLaborCostEachQuietDay">{formatter.format(getRows.getAmountOfPartLaborCostEachQuietDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfAdvertisingExpenseEachQuietDay">{formatter.format(getRows.getAmountOfAdvertisingExpenseEachQuietDay || 0)}</span></td>

                <td className="text-right "><span id="amountOfLandRentEachQuietDay">{formatter.format(getRows.getAmountOfFLAEachQuietDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherFixedAndVariableCostEachQuietDay">{formatter.format(getRows.getAmountOfProfitLossCostEachQuietDay || 0)}</span></td>

                <td className="text-right "><span id="amountOfLandRentEachQuietDay">{formatter.format(getRows.getAmountOfLandRentEachQuietDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherFixedAndVariableCostEachQuietDay">{formatter.format(getRows.getAmountOfOtherFixedAndVariableCostEachQuietDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherFixedCostEachQuietDay">{formatter.format(getRows.getAmountOfOtherFixedCostEachQuietDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherVariableCostEachQuietDay">{formatter.format(getRows.getAmountOfOtherVariableCostEachQuietDay || 0)}</span></td>

                <td className="text-right "><span id="amountOperatingIncomeEachQuietDay">{formatter.format(getRows.getAmountOfFLAROEachQuietDay || 0)}</span></td>

                <td className="text-right "><span id="amountOperatingIncomeEachQuietDay">{formatter.format(getRows.getAmountOperatingIncomeEachQuietDay || 0)}</span></td>
              </tr>
              <tr>
                <td className="text-right ">
                  <span id="amountOfSaleHolidayDay">
                    {formatter.format(getRows.getAmountOfSaleHolidayDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfStandardCostHolidayDay">
                    {formatter.format(getRows.getAmountOfStandardCostHolidayDay || 0)}
                  </span>
                </td>
                {/* <td className="text-right ">
                  <span id="amountOfGrossProfitHolidayDay">
                    {formatter.format(getRows.getAmountOfGrossProfitHolidayDay || 0)}
                  </span>
                </td> */}
                <td className="text-right ">
                  <span id="amountOfEmployeeCostAndPartLaborCostHolidayDay">
                    {formatter.format(getRows.getAmountOfEmployeeCostAndPartLaborCostHolidayDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfEmployeeCostHolidayDay">
                    {formatter.format(getRows.getAmountOfEmployeeCostHolidayDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfPartLaborCostHolidayDay">
                    {formatter.format(getRows.getAmountOfPartLaborCostHolidayDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfAdvertisingExpenseHolidayDay">
                    {formatter.format(getRows.getAmountOfAdvertisingExpenseHolidayDay || 0)}
                  </span>
                </td>

                <td className="text-right ">
                  <span id="amountOfLandRentHolidayDay">
                    {formatter.format(getRows.getAmountOfFLAHolidayDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherFixedAndVariableCostHolidayDay">
                    {formatter.format(getRows.getAmountOfProfitLossCostHolidayDay || 0)}
                  </span>
                </td>

                <td className="text-right ">
                  <span id="amountOfLandRentHolidayDay">
                    {formatter.format(getRows.getAmountOfLandRentHolidayDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherFixedAndVariableCostHolidayDay">
                    {formatter.format(getRows.getAmountOfOtherFixedAndVariableCostHolidayDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherFixedCostHolidayDay">
                    {formatter.format(getRows.getAmountOfOtherFixedCostHolidayDay || 0)}
                  </span>
                </td>
                <td className="text-right ">
                  <span id="amountOfOtherVariableCostHolidayDay">
                    {formatter.format(getRows.getAmountOfOtherVariableCostHolidayDay || 0)}
                  </span>
                </td>

                <td className="text-right ">
                  <span id="amountOperatingIncomeHolidayDay">
                    {formatter.format(getRows.getAmountOfFLAROHolidayDay || 0)}
                  </span>
                </td>

                <td className="text-right ">
                  <span id="amountOperatingIncomeHolidayDay">
                    {formatter.format(getRows.getAmountOperatingIncomeHolidayDay || 0)}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="text-right "><span id="amountOfSaleEachHolidayDay">{formatter.format(getRows.getAmountOfSaleEachHolidayDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfStandardCostEachHolidayDay">{formatter.format(getRows.getAmountOfStandardCostEachHolidayDay || 0)}</span></td>
                {/* <td className="text-right "><span id="amountOfGrossProfitEachHolidayDay">{formatter.format(getRows.getAmountOfGrossProfitEachHolidayDay || 0)}</span></td> */}
                <td className="text-right "><span id="amountOfEmployeeCostAndPartLaborCostEachHolidayDay">{formatter.format(getRows.getAmountOfEmployeeCostAndPartLaborCostEachHolidayDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfEmployeeCostEachHolidayDay">{formatter.format(getRows.getAmountOfEmployeeCostEachHolidayDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfPartLaborCostEachHolidayDay">{formatter.format(getRows.getAmountOfPartLaborCostEachHolidayDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfAdvertisingExpenseEachHolidayDay">{formatter.format(getRows.getAmountOfAdvertisingExpenseEachHolidayDay || 0)}</span></td>

                <td className="text-right "><span id="amountOfLandRentEachHolidayDay">{formatter.format(getRows.getAmountOfFLAEachHolidayDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherFixedAndVariableCostEachHolidayDay">{formatter.format(getRows.getAmountOfProfitLossCostEachHolidayDay || 0)}</span></td>

                <td className="text-right "><span id="amountOfLandRentEachHolidayDay">{formatter.format(getRows.getAmountOfLandRentEachHolidayDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherFixedAndVariableCostEachHolidayDay">{formatter.format(getRows.getAmountOfOtherFixedAndVariableCostEachHolidayDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherFixedCostEachHolidayDay">{formatter.format(getRows.getAmountOfOtherFixedCostEachHolidayDay || 0)}</span></td>
                <td className="text-right "><span id="amountOfOtherVariableCostEachHolidayDay">{formatter.format(getRows.getAmountOfOtherVariableCostEachHolidayDay || 0)}</span></td>

                <td className="text-right "><span id="amountOfOtherVariableCostEachHolidayDay">{formatter.format(getRows.getAmountOfFLAROEachHolidayDay || 0)}</span></td>

                <td className="text-right "><span id="amountOperatingIncomeEachHolidayDay">{formatter.format(getRows.getAmountOperatingIncomeEachHolidayDay || 0)}</span></td>
              </tr>
              <tr>
                <td className="text-right "><span id="totalSale">{formatter.format(getRows.getTotalSale || 0)}</span></td>
                <td className="text-right "><span className="sumOfTotalFoodCostAndDrinkCost">{formatter.format(getRows.getSumOfTotalFoodCostAndDrinkCost || 0)}</span></td>
                {/* <td className="text-right "><span className="sumOfListGrossProfit">{formatter.format(getRows.getSumOfListGrossProfit || 0)}</span></td> */}
                <td className="text-right "><span className="sumOfTotalEmployeeLaborCostAndPartLaborCost">{formatter.format(getRows.getSumOfTotalEmployeeLaborCostAndPartLaborCost || 0)}</span></td>
                <td className="text-right "><span id="sumOfEmployeeLaborCostRow">{formatter.format(getRows.getSumOfEmployeeLaborCostRow || 0)}</span></td>
                <td className="text-right "><span id="sumOfPartLaborCostRow">{formatter.format(getRows.getSumOfPartLaborCostRow || 0)}</span></td>
                <td className="text-right "><span className="sumOfAdvertisingExpense">{formatter.format(getRows.getSumOfAdvertisingExpense || 0)}</span></td>

                <td className="text-right "><span id="sumOfLandRentRow">{formatter.format(getRows.getAmountOfFLATotal || 0)}</span></td>
                <td className="text-right "><span className="sumOfTotalOtherVariableCostsAndOtherFixedCosts">{formatter.format(getRows.getAmountOfProfitLossCostTotal || 0)}</span></td>

                <td className="text-right "><span id="sumOfLandRentRow">{formatter.format(getRows.getSumOfLandRentRow || 0)}</span></td>
                <td className="text-right "><span className="sumOfTotalOtherVariableCostsAndOtherFixedCosts">{formatter.format(getRows.getSumOfTotalOtherVariableCostsAndOtherFixedCosts || 0)}</span></td>
                <td className="text-right "><span id="sumOfOtherFixedCostsRow">{formatter.format(getRows.getSumOfOtherFixedCostsRow || 0)}</span></td>
                <td className="text-right "><span id="sumOfOtherVariableCostsRow">{formatter.format(getRows.getSumOfOtherVariableCostsRow || 0)}</span></td>

                <td className="text-right "><span id="sumOfOtherVariableCostsRow">{formatter.format(getRows.getAmountOfFLAROTotal || 0)}</span></td>

                <td className="text-right "><span id="sumOfOperatingIncomeMonth">{formatter.format(getRows.getSumOfOperatingIncomeMonth || 0)}</span></td>
              </tr>
              <tr>
                <td className="text-right "><span id="totalSaleEachDay">{formatter.format(!getRows.getTotalSaleEachDay ? 0 : getRows.getTotalSaleEachDay)}</span></td>
                <td className="text-right "><span id="standardCostEachDay">{formatter.format(getRows.getStandardCostEachDay || 0)}</span></td>
                {/* <td className="text-right "><span id="GrossProfitEachDay">{formatter.format(getRows.getGrossProfitEachDay || 0)}</span></td> */}
                <td className="text-right "><span id="EmployeeAndPartLaborCostEachDay">{formatter.format(getRows.getEmployeeAndPartLaborCostEachDay || 0)}</span></td>
                <td className="text-right "><span id="EmployeeLaborCostEachDay">{formatter.format(getRows.getEmployeeLaborCostEachDay || 0)}</span></td>
                <td className="text-right "><span id="PartLaborCostEachDay">{formatter.format(getRows.getPartLaborCostEachDay || 0)}</span></td>
                <td className="text-right "><span id="AdvertisingExpenseEachDay">{formatter.format(getRows.getAdvertisingExpenseEachDay || 0)}</span></td>

                <td className="text-right "><span id="LandRentEachDay">{formatter.format(getRows.getAmountOfFLAEachTotal || 0)}</span></td>
                <td className="text-right "><span id="OtherFixedAndVariableCostEachDay">{formatter.format(getRows.getAmountOfProfitLossCostEachTotal || 0)}</span></td>

                <td className="text-right "><span id="LandRentEachDay">{formatter.format(getRows.getLandRentEachDay || 0)}</span></td>
                <td className="text-right "><span id="OtherFixedAndVariableCostEachDay">{formatter.format(getRows.getOtherFixedAndVariableCostEachDay || 0)}</span></td>
                <td className="text-right "><span id="OtherFixedCostEachDay">{formatter.format(getRows.getOtherFixedCostEachDay || 0)}</span></td>
                <td className="text-right "><span id="OtherVariableCostEachDay">{formatter.format(getRows.getOtherVariableCostEachDay || 0)}</span></td>

                <td className="text-right "><span id="OtherVariableCostEachDay">{formatter.format(getRows.getAmountOfFLAROEachTotal || 0)}</span></td>

                <td className="text-right "><span id="OperatingIncomeEachDay">{formatter.format(getRows.getOperatingIncomeEachDay || 0)}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="scroll-bottom-bias-settings">
        <div style={{ width: '300px', float: 'left' }} />
        <div
          style={{
            width: 'calc(100% - 300px)',
            float: 'right',
            overflow: 'auto',
          }}
          id="table_bias_sale_right_3"
        >
          <div className="scroll" style={{ width: '1300px', height: '3px' }} />
        </div>
      </div>
    </div>
  );
};
export default TableBiasSettings;
