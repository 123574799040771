import React from 'react';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import {
  textFontSize, utilityColor
} from 'components/styles';
import { OptionType } from 'components/atoms/Select';
import { TRoleMasterList } from '../component/type'
import RadioSelectForm from 'components/molecules/RadioSelectForm';

const AuthorityBasicInformation: React.FC<{
  isEdit?: boolean
  isCopy?: boolean
  //permissionLevelOptions: OptionType[],
  state: TRoleMasterList,
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  dispatch: any
}> = ({
  isEdit,
  //permissionLevelOptions,
  state,
  handleOnChange,
  dispatch
}) => {

    const re = /^[a-zA-Z0-9]*$/;
    const reNumber = /^[0-9]*$/;
    const optionList = [
      {
        value: '0',
        label: '本人の情報のみ表示'
      },
      {
        value: '1',
        label: '閲覧可能店舗の情報を表示'
      }
    ]
    return (
      <>
        <FormField>
          <TextForm
            name="roleCode"
            label="権限コード"
            placeHolder="権限コード"
            value={state?.roleCode}
            onChange={(e) => {
              if (re.test(e.target.value)) {
                handleOnChange(e)
              }
            }}
            disable={isEdit ? true : false}
            required={true}
          />
        </FormField>

        <FormField>
          <TextForm
            name="roleName"
            label="権限名"
            placeHolder="権限名"
            value={state?.roleName}
            onChange={(e) => handleOnChange(e)}
            required={true}
            maxLength={50}
          />
        </FormField>

        <FormField >
          <TextForm
            name="accessLevel"
            label="権限レベル"
            placeHolder="権限レベル"
            value={String(state.accessLevel || '')}
            onChange={(e) => {
              if (reNumber.test(e.target.value)) {
                handleOnChange(e)
              }
            }}
            required={true}
            maxLength={4}
          />
        </FormField>

        <p style={{ fontSize: textFontSize.sm, color: utilityColor.error, marginLeft: '4px', marginTop: 0 }} dangerouslySetInnerHTML={{ __html: '1～9999までの半角数字で入力してください。' }} />
        <p style={{ fontSize: textFontSize.sm, color: utilityColor.error, marginLeft: '4px' }} dangerouslySetInnerHTML={{ __html: '権限の階層を設定します。数字が小さいほど上位の権限レベルとなります。' }} />
        <p style={{ fontSize: textFontSize.sm, color: utilityColor.error, marginLeft: '4px' }} dangerouslySetInnerHTML={{ __html: '現在ログインしているスタッフの権限レベルを超えた設定をすることはできません。' }} />

        <FlexBox>
          <FlexBoxItem>
            <RadioSelectForm
              label='勤怠アラートの表示範囲'
              items={optionList}
              name='notificationType'
              value={String(state?.notificationType)}
              setValue={handleOnChange}
              labelInline={true}
            />
          </FlexBoxItem>
        </FlexBox>

      </>
    );
  };

export default AuthorityBasicInformation;
