/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { textFontSize, textColor } from 'components/styles';

type FontSize = typeof textFontSize;

const BodyText: React.FC<{
  size?: keyof FontSize
  color?: string
  bold?: boolean
  customStyle?: SerializedStyles,
  className?: string,
}> = ({
  children, size = 're', color = textColor.main, bold, customStyle, className,
}) => (
  <span
    css={css({
      fontSize: textFontSize[size],
      color,
      fontFamily: 'inherit',
      fontWeight: bold ? 'bold' : 'normal',
    }, customStyle)}
    className={className}
  >
    {children}
  </span>
);

export default BodyText;
