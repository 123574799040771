/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Amplify, { Auth } from 'aws-amplify';
import awsExports from 'aws-exports'; // 設定情報を読み込みます。

import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import PasswordForm from 'components/molecules/PasswordForm';
import Button, { PrimaryButton } from 'components/atoms/Button';
import { useHistory, Link } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';
import { handleHelpUrlForScreen } from 'api/login';
import { isMobileOnly } from 'react-device-detect';
import {staffRegisterTouch, checkEmailExists} from 'api/signUp'
import { ErrorMessage } from 'utility/messages';

const styles = {
  canselButton: css({
    marginRight: '3em auto',
  }),
  h1: css({
    textAlign: 'left',
    padding: '0 0',
    fontSize: '0.75em',
  }),
  form: css({
    padding: '0 1.5em',
  }),
  standardLogo: css({
    marginLeft: 'auto',
    marginRight: 'auto',
    verticalAlign: 'middle',
    width: '100px',
    height: '100px',
    padding: '8px 12px',
  }),
  formFotter: css({
    padding: '0.5em 0.5em',
    textAlign: 'right',
    marginTop: isMobileOnly ? '0px' : '-30px',
  }),
  formHeader: css({
    padding: '0.5em 0.5em',
    textAlign: 'center',
  }),
  'formFotter a': css({
    textDecoration: 'none',
    color: '#0070d2',
    fontSize: '0.75em',
  }),
  'formHeader a': css({
    textDecoration: 'none',
    color: '#0070d2',
  }),
};

Amplify.configure(awsExports); // 設定情報をAmplifyに反映させます。

const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .required('メールアドレスを入力してください')
    .email('形式がemailではありません。'),
  password: Yup.string()
    .min(6, 'パスワードは6文字以上で入力して下さい')
    .required('パスワードを入力してください'),
  rePassword: Yup.string()
    .required('パスワード(確認)を入力してください')
    .min(6, 'パスワードは6文字以上で入力して下さい')
    .oneOf([Yup.ref('password')], 'パスワードと確認用のパスワードが異なっています'),
});

const SignUpInputForm: React.FC = () => {
  const { successNotification, errorNotification } = useToastNotification();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rePassword: '',
    },
    // NOTE: setSubmittingを使っていないが非同期処理を実行した場合はformikが自動でisSubmittingの値をtrueからfalseに設定してくれる
    onSubmit: async (values, { setSubmitting }) => {
      const ret = await isEmailExists(values.email);
      if (ret) {
        // メールアドレスがスタッフマスタに存在する場合はCognitoユーザーを登録する
        await signUp(values.email, values.password);
      }
    },
    validationSchema: SignUpSchema,
  });

  /**
   * スタッフマスタメールアドレス存在チェック
   * @param email
   * @returns true: 存在する / false: 存在しない
   */
  const isEmailExists = async (email: string) => {
    try {
      await checkEmailExists(email);
      return true;
    } catch (err) {
      if(err.response.status === 400) {
        errorNotification(err.response.data.defaultMessage);
      } else if (err.response.status === 503) {
        errorNotification(err.response.data.defaultMessage);
      } else {
        errorNotification(ErrorMessage.Common.INTERNAL_SERVER_ERROR);
      }
      return false;
    }
  };

  const signUp = async (email: string, password: string) => {
    try {
      await Auth.signUp(email, password);
      console.log(`${email} に認証コードを送信しました`);
      successNotification(`${email} に認証コードを送信しました`);
      await staffRegisterTouch(email);
      history.push(`/activation/${email}`);
    } catch (err) {
      console.error(`err.code = ${err.code}`);
      console.error(`err.message = ${err.message}`);
      if (err.code === 'UsernameExistsException') {
        errorNotification('既にユーザー登録は完了していますので、ユーザ登録する必要はありません');
      } else if (err.message === 'Username should be an email.') {
        errorNotification('正しいメールアドレスを入力して下さい');
      } else if (err.message === "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6") {
        errorNotification('パスワードは6文字以上で入力して下さい');
      } else if (err.code === 'InvalidParameterException') {
        errorNotification('パスワードと確認用のパスワードが異なっています');
      } else {
        errorNotification('ユーザー登録に失敗しました');
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <p css={styles.h1}>
        れすだくを使用するためにユーザー登録の必要があります。
        <br />
        れすだくのスタッフ情報に登録されているメールアドレスと任意のパスワード（6文字以上）を入力して下さい。
      </p>
      <FormField>
        <TextForm
          name="email"
          label="メールアドレス"
          value={formik.values.email}
          onChange={formik.handleChange}
          errorMsg={formik.errors.email}
        />
      </FormField>

      <FormField>
        <PasswordForm
          name="password"
          label="パスワード"
          value={formik.values.password}
          onChange={formik.handleChange}
          errorMsg={formik.errors.password}
        />
      </FormField>

      <FormField>
        <PasswordForm
          name="rePassword"
          label="パスワード(確認)"
          value={formik.values.rePassword}
          onChange={formik.handleChange}
          errorMsg={formik.errors.rePassword}
        />
      </FormField>

      <FormSubmitArea>
        <Button
          text="次へ"
          disabled={formik.isSubmitting}
          onClick={formik.handleSubmit}
        />
        <Link to="/login">
          <PrimaryButton
            ghost={true}
            text="キャンセル"
          />
        </Link>
      </FormSubmitArea>
      <div css={styles.formFotter}>
        <Link onClick={() => handleHelpUrlForScreen('ユーザー登録')} to="#" css={styles['formFotter a']}>
          ヘルプサイトへ
        </Link>
      </div>
    </form>
  );
};

export default SignUpInputForm;
