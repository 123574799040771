import React from 'react';
import DailySalesStore from 'components/organismos/master/general/salesPage/dailySalesStore';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const StoreSalesManagement = () => (
  <SidebarTemplate pageTitle="【店別】売上管理表">
    <DailySalesStore />
  </SidebarTemplate>
);

export default StoreSalesManagement;
