/* eslint-disable no-return-assign */
import { getSalesPlanDaily, SalesSetting,DetailApplicationData } from 'api/salesReport';
import useToastNotification from 'hooks/useToastNotification';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SessionStorageKey } from 'utility/constants';

// data general key name
const generalKey = [
  // ----------------------------------------------------
  // 'amountOfSalesTotal', 売上高
  'rowBlank_01',
  'amountOfSalesAdvertisement',
  'amountOfSalesFreeTotal',
  'amountOfSalesTotal',
  // 'amountOfSalesTotal',
  // 'amountOfSalesAdvertisement',
  // 'amountOfSalesFreeTotal',

  // Food cost F（食材費）
  'rowBlank_02',
  'standardCostFood',
  'standardCostDrink',
  'standardCostTotal',
  'standardCostRate',
  // temporarily no need to use grossProfitTotal,hidden on DOM
  'grossProfitTotal',
  'grossProfitRate',
  // ---------------------------------------
  // L（人件費）
  'rowBlank_03',
  'laborCostsEmployee',
  'laborCostsParttime',
  'laborCostsTotal',
  'laborCostsRate',
  // ---------------------------------------
  // advertisingExpense
  'rowBlank_04',
  'advertisingExpense',
  // ---------------------------------------
  // FLA
  'FLACost',
  'FLARate',
  // ---------------------------------------
  // 店舗損益率
  'ProfitLossCost',
  'ProfitLossRate',
  // ---------------------------------------
  // Rent R（家賃）
  'rowBlank_05',
  'rent',
  'commonServiceFee',
  'rentTotal',
  'rentRate',
  // ---------------------------------------
  // ---------------------------------------
  // Other O（その他経費）
  'rowBlank_06',
  // 'expenses',
  // 'otherPurchase',
  'expenses',
  'otherPurchase',
  'otherExpensesVariableCosts',
  'otherExpensesFixedCost',
  'otherExpensesTotal',
  'otherExpensesRate',
  // ---------------------------------------
  // 【FLARO】合計---------------------------
  'FLAROCost',
  'FLARORate',
  // ---------------------------------------
  // Operating 営業利益----------------------
  'operatingIncomeTotal',
  'operatingIncomeRate',
  // ----------------------------------------------------Modified layout above----------------------------------------------------------------------
  // 仕入額
  'rowBlank_07',
  'purchaseAmountFood',
  'purchaseAmountDrink',
  'purchaseAmountOhter',
  'purchaseAmountTotal',
  'purchaseAmountRateTotal',
  // ----------------------------------------------------------------------------
  // 標準原価
  'rowBlank_08',
  'CostFood',
  'CostDrink',
  'CostTotal',
  'CostRate',
  // ----------------------------------------------------------------------------

  'rowBlank_09',
  'pricePerCustomer',
  'numberOfCustomers',
  'numberOfGroups',
  'averageNumberOfGuestPerGroup',
  'TurnoverRate',
  'porofitMarginAmount',
  'porofitMarginRate',
  'contributionMarginAmount',
  'contributionMarginRate',
  'breakEvenPointSales',
  'breakEvenPointNumberOfGuest',
  'laborCostRateTotal',
  'laborCostRateEmployee',
  'laborCostRatPartTime',
  'totalWorkingHoursTotal',
  'totalWorkingHoursEmployee',
  'totalWorkingHoursPartTime',
  'numberOfEmployeesTotal',
  'numberOfEmployeesEmployee',
  'numberOfEmployeesPartTime',
  'whenPeopleAreHigh',
  'humanTimeProductivity',
  'numberOfPickUps',
  'laborProductivity',
  'laborShare',
  // temporarily no need to use grossProfitTotal,hidden on DOM
  'FLCost',
  'FLRate',
  'FLARCost',
  'FLARRate',
  // ---------------------------------------
];

const attachUrl = process.env.REACT_APP_ATTACH_BASE_URL;

export const useSalesMonthlyReport = () => {
  const params = useParams<{
    orgCode: string,
    targetDate: any
  }>();
  const { errorNotification } = useToastNotification();
  const [targetOrgCode, setTargetOrgCode] = useState<string>(params.orgCode || sessionStorage.getItem('SalesDailyReport.targetOrgCode') || sessionStorage.getItem(SessionStorageKey.LoginUser.ORG_CODE) || '');
  const [targetDay, setTargetDay] = useState<Date>(
    // eslint-disable-next-line no-nested-ternary
    params && params.targetDate ? moment(params.targetDate, 'YYYY-MM-DD').toDate()
      : (
        sessionStorage.getItem('SalesDailyReport.targetDay') ? moment(sessionStorage.getItem('SalesDailyReport.targetDay')!, 'YYYY-MM-DD').toDate() : moment().startOf('day').toDate()
      ),
  );
  //

  const [dataKeyName, setDataKeyName] = useState<Array<any>>([]);
  const [generalData, setGeneralData] = useState<Array<any>>([]);
  const [newDailyData, setNewDailyData] = useState<any>([]);
  const [reportByTimeOfDay, SetReportByTimeOfDay] = useState([]);
  const [cashRegisterReport, SetCashRegisterReport] = useState([]);
  const [grandTotalnData, setGrandTotalnData] = useState([]);
  const [sameDayData, setSameDayData] = useState([]);

  const [advertisingData, setAdvertisingData] = useState([]);
  const [countAdvertising, setCountAdvertising] = useState(0);
  const [expensesData, setExpensesData] = useState<any>([]);
  const [countExpenses, setCountExpenses] = useState<number>(0);

  const [isLoading, setIsLoading] = useState(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [budgetProgressComment, setBudgetProgressComment] = useState('');
  const [storeOperationComment, setStoreOperationComment] = useState('');
  const [otherComment, setOtherComment] = useState('');
  const [paymentMediaAmountManual, setPaymentMediaAmountManual] = useState([]);
  const [attachFileData, setAttachFileData] = useState<any>([]);
  const [discountTypeData, setDiscountTypeData] = useState([]);
  const [setting, setSetting] = useState<SalesSetting | undefined>(undefined);
  const [detailApplicationData, setDetailApplicationData] = useState<DetailApplicationData>({} as DetailApplicationData);

  const [roleOrg, setRoleOrg] = useState<any>([]);
  const [responseRoleOrg, setResponseRoleOrg] = useState<any>([]);

  console.log(generalData, 'check generalData');
  console.log(setting, 'check setting');

  // データの取得
  const fetchData = useCallback(async () => {
    console.log('fetchData start -------------------------------------');
    setIsLoading(true);
    getSalesPlanDaily(
      targetOrgCode,
      new Date(targetDay),
    ).then((data: any) => {
      console.log('fetchData then start -------------------------------------');
      console.log('data' ,data);
      setGeneralData(data);
      setNewDailyData(data);
      setDetailApplicationData(data.detail_application_data);
      if (data.cash_data) {
        setBudgetProgressComment(data.cash_data.comment.budget_progress_comment);
        setStoreOperationComment(data.cash_data.comment.store_operation_comment);
        setOtherComment(data.cash_data.comment.other_comment);
        setDiscountTypeData(data.cash_data.discount_type);
        const tmpAttachFileData:Array<any> = [];
        if (data.cash_data.attachFileData && data.cash_data.attachFileData.length) {
          let tmpFile:any = {};
          data.cash_data.attachFileData.forEach((attachFile: any) => {
            tmpFile = {
              path: `${attachUrl}/${attachFile.path}`,
              file_name: attachFile.file_name,
              idx: attachFile.idx,
            };
            tmpAttachFileData.push(tmpFile);
          });
        }
        setAttachFileData(tmpAttachFileData);
        console.log('fetchData then end -------------------------------------');
      }
      // handle data
      if (data) {
        updateCountDayAndAdvertising(data);
        setSetting(data.setting);
      }
      setIsLoading(false);
      console.log('fetchData end -------------------------------------');
    })
      .catch((exception: any) => {
        setIsLoading(false);
        errorNotification('サーバー側でエラーが発生しました。');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDay, targetOrgCode]);

  //
  const updateCountDayAndAdvertising = useCallback(async (data: any) => {
    dataCalculation(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDay, targetOrgCode]);

  //
  const dataCalculation = useCallback(async (dailyData: any) => {
    let tmpCountAdvertising = 0;
    let tmpCountExpenses = 0;
    let advertisingData: any = [];
    let expensesData: any = [];
    // update count advertising
    if (dailyData.accumulated_data && dailyData.accumulated_data.expenses) {
      // advertisingData
      advertisingData = dailyData.accumulated_data.advertising;
      tmpCountAdvertising = dailyData.accumulated_data.advertising.length;
    }
    if (dailyData.accumulated_data && dailyData.accumulated_data.expenses) {
      // advertisingData
      expensesData = dailyData.accumulated_data.expenses;
      tmpCountExpenses = dailyData.accumulated_data.expenses.length;
    }
    setAdvertisingData(advertisingData);
    setCountAdvertising(tmpCountAdvertising);
    setExpensesData(expensesData);
    setCountExpenses(tmpCountExpenses);
    // レジレポート
    const tmpCashRegisterReport: any = {};
    if (dailyData && dailyData.cash_data) {
      const tmpCheckoutInformation: any = {};
      let tmpCashSales = 0;
      const tmpKeyArr: any = [];
      dailyData.cash_data.payment_media.map((obj_payment_media: any, index: number) => {
        if (obj_payment_media.payment_media_category_code !== null) {
          if (!tmpCheckoutInformation[obj_payment_media.payment_media_category_code]) {
            tmpCheckoutInformation[obj_payment_media.payment_media_category_code] = [];
            tmpCheckoutInformation[obj_payment_media.payment_media_category_code].data = [];
          }
          tmpCheckoutInformation[obj_payment_media.payment_media_category_code].category_name = obj_payment_media.payment_media_category_name;
          tmpCheckoutInformation[obj_payment_media.payment_media_category_code].data.push(obj_payment_media);
          if (tmpKeyArr.indexOf(obj_payment_media.payment_media_category_code) === -1) {
            tmpKeyArr.push(obj_payment_media.payment_media_category_code);
          }
        } else {
          tmpKeyArr.push(index);
          tmpCheckoutInformation[index] = [];
          tmpCheckoutInformation[index].category_name = null;
          tmpCheckoutInformation[index].data = obj_payment_media;
        }

        // calc total cash sales
        if (obj_payment_media.payment_media_mst_code === '000') {
          tmpCashSales += obj_payment_media.payment_media_amount;
        }
        return true;
      });
      tmpCashRegisterReport.checkoutInformation = [];
      tmpCashRegisterReport.countPaymentMedia = dailyData.cash_data.payment_media.length;
      dailyData.cash_data.deposit_withdrawal.cash_sales = tmpCashSales;

      tmpKeyArr.map((val_tmp: any, tmp_key: number) => {
        const obj_tmp = tmpCheckoutInformation[val_tmp];
        if (obj_tmp.data && obj_tmp.data.length) {
          let tmpObj: any = {};
          obj_tmp.data.map((obj_sub_tmp: any, tmp_sub_key: number) => {
            tmpObj = {};
            if (tmp_sub_key === 0) {
              tmpObj.rowspan = obj_tmp.data.length;
              tmpObj.colspan = 1;
              tmpObj.category_name = obj_tmp.category_name;
            } else {
              tmpObj.rowspan = 1;
              tmpObj.colspan = 1;
              tmpObj.category_name = null;
            }

            tmpObj.data = obj_sub_tmp;
            tmpCashRegisterReport.checkoutInformation.push(tmpObj);
            return true;
          });
        } else {
          obj_tmp.rowspan = 1;
          obj_tmp.colspan = 2;
          tmpCashRegisterReport.checkoutInformation.push(obj_tmp);
        }
        return true;
      });
    }
    SetCashRegisterReport(tmpCashRegisterReport);
    setPaymentMediaAmountManual((tmpCashRegisterReport && tmpCashRegisterReport.countPaymentMedia)
      // eslint-disable-next-line no-mixed-operators
      && tmpCashRegisterReport.checkoutInformation.map((info: any, index: number) => ({
        payment_media_amount_manual: info.data.payment_media_amount_manual,
        payment_media_mst_code: info.data.payment_media_mst_code,
      }
        // eslint-disable-next-line no-mixed-operators
      )) || []);

    // 時間帯別レポート
    const tmpreportByTimeOfDay: any = [];
    if (dailyData && dailyData.time_data) {
      dailyData.time_data.map((obj_time_of_day: any, index: number) => {
        tmpreportByTimeOfDay.push([
          obj_time_of_day.target_time, // 時間
          numberFormater(obj_time_of_day.amount), // 売上高
          numberFormater(getRatioLabel(obj_time_of_day.cost, obj_time_of_day.amount)), // 標準原価%
          numberFormater(obj_time_of_day.amount - obj_time_of_day.cost), // 売上総利益
          numberFormater(obj_time_of_day.customers), // 組数
          numberFormater(obj_time_of_day.guest_cnt), // 客数
          numberFormater(getDivision(obj_time_of_day.guest_cnt, dailyData.daily_data.budget.number_of_seat)), // 回転率
          numberFormater(getDivision(obj_time_of_day.amount, obj_time_of_day.guest_cnt, 0)), // 客単価>売上高
          getRatioLabel(obj_time_of_day.cost, obj_time_of_day.amount), // 客単価>標準原価%
          numberFormater(getDivision(obj_time_of_day.amount, obj_time_of_day.guest_cnt, 0) - (getDivision(obj_time_of_day.amount, obj_time_of_day.guest_cnt, 0) * getDivision(obj_time_of_day.cost, obj_time_of_day.amount, 0))), // 客単価>売上総利益
        ]);
        return true;
      });
    }
    SetReportByTimeOfDay(tmpreportByTimeOfDay);

    // set default
    const tmpDataKeyName: any = [];
    const tmpGrandTotalnData: any = [];
    const tmpSameDayData: any = [];
    setDataKeyName([]);

    let tmpTotalWorkingHours = 0;
    let tmpTotalWorkingHoursCumulative = 0;

    //
    // handle
    generalKey.map((value) => {
      // push data 売上高>広告
      if (value === 'amountOfSalesAdvertisement') {
        // tmpDataKeyName.push('amountOfSalesAdvertisementTotal');
        tmpGrandTotalnData.amountOfSalesAdvertisementTotal = [];
        tmpSameDayData.amountOfSalesAdvertisementTotal = [];
        const tmpTotal = {
          achievement: 0,
          budget: 0,
          monthly_budget: 0,
          achievement_same_day: 0,
          budget_same_day: 0,
        };

        if (tmpCountAdvertising) {
          // push data 売上高>広告 for 累計
          dailyData.accumulated_data.advertising.map((obj_adv: any, key_adv: number) => {
            tmpTotal.achievement += obj_adv.advertising_media_amount;
            tmpTotal.budget += obj_adv.budget_advertising_media_amount;
            tmpTotal.monthly_budget += obj_adv.monthly_budget_advertising_media_amount;
            tmpDataKeyName.push(`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`);
            tmpGrandTotalnData[`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`] = [];

            tmpGrandTotalnData[`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`].push(
              obj_adv.advertising_media_amount,
              obj_adv.budget_advertising_media_amount,
              getRatioLabel(obj_adv.advertising_media_amount, obj_adv.budget_advertising_media_amount),
              obj_adv.monthly_budget_advertising_media_amount,
              getRatioLabel(obj_adv.advertising_media_amount, obj_adv.monthly_budget_advertising_media_amount),
            );

            // push data 売上高>広告 for 当日
            tmpSameDayData[`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`] = [];
            tmpTotal.achievement_same_day += dailyData.daily_data.advertising[key_adv].advertising_media_amount;
            tmpTotal.budget_same_day += dailyData.daily_data.advertising[key_adv].budget_advertising_media_amount;
            tmpSameDayData[`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`].push(
              dailyData.daily_data.advertising[key_adv].advertising_media_amount,
              dailyData.daily_data.advertising[key_adv].budget_advertising_media_amount,
              getRatioLabel(dailyData.daily_data.advertising[key_adv].advertising_media_amount, dailyData.daily_data.advertising[key_adv].budget_advertising_media_amount),
            );
            return true;
          });
        }
        // push data 売上高>広告>合計 for 累計
        tmpGrandTotalnData.amountOfSalesAdvertisementTotal.push(
          tmpTotal.achievement,
          tmpTotal.budget,
          getRatioLabel(tmpTotal.achievement, tmpTotal.budget),
          tmpTotal.monthly_budget,
          getRatioLabel(tmpTotal.achievement, tmpTotal.monthly_budget),
        );

        // push data 売上高>広告>合計 for 当日
        tmpSameDayData.amountOfSalesAdvertisementTotal.push(
          tmpTotal.achievement_same_day,
          tmpTotal.budget_same_day,
          getRatioLabel(tmpTotal.achievement_same_day, tmpTotal.budget_same_day),
        );

        // push data 売上高>広告
      } else if (value === 'advertisingExpense') {
        tmpGrandTotalnData.advertisingExpenseTotal = [];
        tmpSameDayData.advertisingExpenseTotal = [];
        const tmpTotal = {
          achievement: 0,
          budget: 0,
          monthly_budget: 0,
          achievement_same_day: 0,
          budget_same_day: 0,
        };

        if (tmpCountAdvertising) {
          // push data 広告宣伝費 for accumulated_data
          dailyData.accumulated_data.advertising.map((obj_adv: any, key_adv: number) => {
            tmpTotal.achievement += obj_adv.advertising_media_expense;
            tmpTotal.budget += obj_adv.budget_advertising_media_expense;
            tmpTotal.monthly_budget += obj_adv.monthly_budget_advertising_media_expense;
            tmpDataKeyName.push(`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`);
            tmpGrandTotalnData[`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`] = [];

            tmpGrandTotalnData[`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`].push(
              obj_adv.advertising_media_expense,
              obj_adv.budget_advertising_media_expense,
              getRatioLabel(obj_adv.advertising_media_expense, obj_adv.budget_advertising_media_expense),
              obj_adv.monthly_budget_advertising_media_expense,
              getRatioLabel(obj_adv.advertising_media_expense, obj_adv.monthly_budget_advertising_media_expense),
            );

            // push data 広告宣伝費 for 当日
            tmpSameDayData[`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`] = [];
            tmpTotal.achievement_same_day += dailyData.daily_data.advertising[key_adv].advertising_media_expense;
            tmpTotal.budget_same_day += dailyData.daily_data.advertising[key_adv].budget_advertising_media_expense;
            tmpSameDayData[`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`].push(
              dailyData.daily_data.advertising[key_adv].advertising_media_expense,
              dailyData.daily_data.advertising[key_adv].budget_advertising_media_expense,
              getRatioLabel(dailyData.daily_data.advertising[key_adv].advertising_media_expense, dailyData.daily_data.advertising[key_adv].budget_advertising_media_expense),
            );
            return true;
          });
        }
        // push data 広告宣伝費>合計 for 累計
        tmpGrandTotalnData.advertisingExpenseTotal.push(
          tmpTotal.achievement,
          tmpTotal.budget,
          getRatioLabel(tmpTotal.achievement, tmpTotal.budget),
          tmpTotal.monthly_budget,
          getRatioLabel(tmpTotal.achievement, tmpTotal.monthly_budget),
        );

        // push data 広告宣伝費>合計 for 当日
        tmpSameDayData.advertisingExpenseTotal.push(
          tmpTotal.achievement_same_day,
          tmpTotal.budget_same_day,
          getRatioLabel(tmpTotal.achievement_same_day, tmpTotal.budget_same_day),
        );
        tmpDataKeyName.push('advertisingExpenseTotal');
        tmpDataKeyName.push('advertisingExpenseRate');
        tmpGrandTotalnData.advertisingExpenseRate = [];
        tmpSameDayData.advertisingExpenseRate = [];

        tmpGrandTotalnData.advertisingExpenseRate.push(
          getRatioLabel(tmpGrandTotalnData.advertisingExpenseTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
          getRatioLabel(tmpGrandTotalnData.advertisingExpenseTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
          '-',
          getRatioLabel(tmpGrandTotalnData.advertisingExpenseTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
          '-',
        );

        tmpSameDayData.advertisingExpenseRate.push(
          getRatioLabel(tmpSameDayData.advertisingExpenseTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
          getRatioLabel(tmpSameDayData.advertisingExpenseTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
          '-',
        );
      } else if (value === 'expenses') {
        // push data 売上高>広告
        // tmpDataKeyName.push('amountOfSalesAdvertisementTotal');
        tmpGrandTotalnData.expensesTotal = [];
        tmpGrandTotalnData.expensesTotalClassification0 = [];
        tmpGrandTotalnData.expensesTotalClassification1 = [];

        tmpSameDayData.expensesTotal = [];
        tmpSameDayData.expensesTotalClassification0 = [];
        tmpSameDayData.expensesTotalClassification1 = [];

        const tmpTotal = {
          achievement: 0,
          budget: 0,
          monthly_budget: 0,
          achievement_same_day: 0,
          budget_same_day: 0,
        };
        const tmpTotalClassification0 = {
          achievement: 0,
          budget: 0,
          monthly_budget: 0,
          achievement_same_day: 0,
          budget_same_day: 0,
        };
        const tmpTotalClassification1 = {
          achievement: 0,
          budget: 0,
          monthly_budget: 0,
          achievement_same_day: 0,
          budget_same_day: 0,
        };
        if (tmpCountExpenses) {
          // push data 売上高>広告 for 累計
          dailyData.accumulated_data.expenses.map((obj_adv: any, key_adv: number) => {
            if (obj_adv.expenses_classification === 0) {
              tmpTotalClassification0.achievement += obj_adv.expenses_amount;
              tmpTotalClassification0.budget += obj_adv.budget_expenses_amount;
              tmpTotalClassification0.monthly_budget += obj_adv.monthly_budget_expenses_amount;
              tmpTotalClassification0.achievement_same_day += dailyData.daily_data.expenses[key_adv].expenses_amount;
              tmpTotalClassification0.budget_same_day += dailyData.daily_data.expenses[key_adv].budget_expenses_amount;
            } else {
              tmpTotalClassification1.achievement += obj_adv.expenses_amount;
              tmpTotalClassification1.budget += obj_adv.budget_expenses_amount;
              tmpTotalClassification1.monthly_budget += obj_adv.monthly_budget_expenses_amount;
              tmpTotalClassification1.achievement_same_day += dailyData.daily_data.expenses[key_adv].expenses_amount;
              tmpTotalClassification1.budget_same_day += dailyData.daily_data.expenses[key_adv].budget_expenses_amount;
            }

            tmpTotal.achievement += obj_adv.expenses_amount;
            tmpTotal.budget += obj_adv.budget_expenses_amount;
            tmpTotal.monthly_budget += obj_adv.monthly_budget_expenses_amount;
            tmpDataKeyName.push(`expenses${obj_adv.expenses_code}`);
            tmpGrandTotalnData[`expenses${obj_adv.expenses_code}`] = [];

            tmpGrandTotalnData[`expenses${obj_adv.expenses_code}`].push(
              obj_adv.expenses_amount,
              obj_adv.budget_expenses_amount,
              getRatioLabel(obj_adv.expenses_amount, obj_adv.budget_expenses_amount),
              obj_adv.monthly_budget_expenses_amount,
              getRatioLabel(obj_adv.expenses_amount, obj_adv.monthly_budget_expenses_amount),
            );

            // push data 売上高>広告 for 当日
            tmpSameDayData[`expenses${obj_adv.expenses_code}`] = [];

            tmpTotal.achievement_same_day += dailyData.daily_data.expenses[key_adv].expenses_amount;
            tmpTotal.budget_same_day += dailyData.daily_data.expenses[key_adv].budget_expenses_amount;

            tmpSameDayData[`expenses${obj_adv.expenses_code}`].push(
              dailyData.daily_data.expenses[key_adv].expenses_amount,
              dailyData.daily_data.expenses[key_adv].budget_expenses_amount,
              getRatioLabel(dailyData.daily_data.expenses[key_adv].expenses_amount, dailyData.daily_data.expenses[key_adv].budget_expenses_amount),
            );
            return true;
          });
        }
        // push data 売上高>広告>合計 for 累計
        tmpGrandTotalnData.expensesTotal.push(
          tmpTotal.achievement,
          tmpTotal.budget,
          getRatioLabel(tmpTotal.achievement, tmpTotal.budget),
          tmpTotal.monthly_budget,
          getRatioLabel(tmpTotal.achievement, tmpTotal.monthly_budget),
        );
        let purchaseOtherCostDay = 0;
        if (dailyData.setting.includePettyCashInPurchase) {
          purchaseOtherCostDay = dailyData.accumulated_data.budget.purchase_other_cost
            + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system
            + dailyData.accumulated_data.budget.petty_cash_other
            + dailyData.accumulated_data.budget.petty_cash_expendables
            + dailyData.accumulated_data.budget.petty_cash_revenue_stamp;
        } else {
          purchaseOtherCostDay = dailyData.accumulated_data.budget.purchase_other_cost
            + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system;
        }
        tmpGrandTotalnData.expensesTotalClassification0.push(
          0 - purchaseOtherCostDay - tmpTotalClassification0.achievement,
          0 - dailyData.accumulated_data.budget.sales_budget_other_purchase - tmpTotalClassification0.budget,
          getRatioLabel(tmpTotalClassification0.achievement - purchaseOtherCostDay, tmpTotalClassification0.budget - dailyData.accumulated_data.budget.sales_budget_other_purchase),
          0 - dailyData.accumulated_data.budget.monthly_sales_budget_other_purchase - tmpTotalClassification0.monthly_budget,
          getRatioLabel(tmpTotalClassification0.achievement - purchaseOtherCostDay, tmpTotalClassification0.monthly_budget - dailyData.accumulated_data.budget.monthly_sales_budget_other_purchase),
        );


        tmpGrandTotalnData.expensesTotalClassification1.push(
          tmpTotalClassification1.achievement,
          tmpTotalClassification1.budget,
          getRatioLabel(tmpTotalClassification1.achievement, tmpTotalClassification1.budget),
          tmpTotalClassification1.monthly_budget,
          getRatioLabel(tmpTotalClassification1.achievement, tmpTotalClassification1.monthly_budget),
        );

        // push data 売上高>広告>合計 for 当日
        tmpSameDayData.expensesTotal.push(
          tmpTotal.achievement_same_day,
          tmpTotal.budget_same_day,
          getRatioLabel(tmpTotal.achievement_same_day, tmpTotal.budget_same_day),
        );

        let purchaseOtherCostDailyData = 0;
        if (dailyData.setting.includePettyCashInPurchase) {
          purchaseOtherCostDailyData = dailyData.daily_data.budget.purchase_other_cost
            + dailyData.daily_data.budget.purchase_other_cost_other_than_system
            + dailyData.daily_data.budget.petty_cash_other
            + dailyData.daily_data.budget.petty_cash_expendables
            + dailyData.daily_data.budget.petty_cash_revenue_stamp;
        } else {
          purchaseOtherCostDailyData = dailyData.daily_data.budget.purchase_other_cost
            + dailyData.daily_data.budget.purchase_other_cost_other_than_system;
        }

        tmpSameDayData.expensesTotalClassification0.push(
          0 - purchaseOtherCostDailyData - tmpTotalClassification0.achievement_same_day,
          0 - dailyData.daily_data.budget.sales_budget_other_purchase - tmpTotalClassification0.budget_same_day,
          getRatioLabel(tmpTotalClassification0.achievement_same_day - purchaseOtherCostDailyData, tmpTotalClassification0.budget_same_day - dailyData.daily_data.budget.sales_budget_other_purchase),
        );


        tmpSameDayData.expensesTotalClassification1.push(
          tmpTotalClassification1.achievement_same_day,
          tmpTotalClassification1.budget_same_day,
          getRatioLabel(tmpTotalClassification1.achievement_same_day, tmpTotalClassification1.budget_same_day),
        );
        // push data 売上高>広告
      } else {
        tmpDataKeyName.push(value);
        tmpGrandTotalnData[value] = [];
        tmpSameDayData[value] = [];
        let tmpMonthlyBudget: any = 0;
        let tmpPerformance: any = 0;
        let tmpBudget: any = 0;
        let tmpTotal = 0;
        let tmpFCostValue = 0;

        switch (value) {
          // 売上高>合計
          case 'amountOfSalesTotal':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.net_sales,
              tmpGrandTotalnData.amountOfSalesAdvertisementTotal[1] + tmpGrandTotalnData.amountOfSalesFreeTotal[1],
              getRatioLabel(dailyData.accumulated_data.budget.net_sales, tmpGrandTotalnData.amountOfSalesAdvertisementTotal[1] + tmpGrandTotalnData.amountOfSalesFreeTotal[1]),
              tmpGrandTotalnData.amountOfSalesAdvertisementTotal[3] + tmpGrandTotalnData.amountOfSalesFreeTotal[3],
              getRatioLabel(dailyData.accumulated_data.budget.net_sales, tmpGrandTotalnData.amountOfSalesAdvertisementTotal[3] + tmpGrandTotalnData.amountOfSalesFreeTotal[3]),
            );
            tmpSameDayData[value].push(
              dailyData.daily_data.budget.net_sales,
              tmpSameDayData.amountOfSalesAdvertisementTotal[1] + tmpSameDayData.amountOfSalesFreeTotal[1],
              getRatioLabel(dailyData.daily_data.budget.net_sales, tmpSameDayData.amountOfSalesAdvertisementTotal[1] + tmpSameDayData.amountOfSalesFreeTotal[1]),
            );
            break;
          // 売上高>フリー>合計
          case 'amountOfSalesFreeTotal':
            tmpPerformance = dailyData.accumulated_data.budget.net_sales - tmpGrandTotalnData.amountOfSalesAdvertisementTotal[0];
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              dailyData.accumulated_data.budget.sales_budget_free,
              getRatioLabel(tmpPerformance, dailyData.accumulated_data.budget.sales_budget_free),
              dailyData.accumulated_data.budget.monthly_sales_budget_free,
              getRatioLabel(tmpPerformance, dailyData.accumulated_data.budget.monthly_sales_budget_free),
            );

            tmpPerformance = dailyData.daily_data.budget.net_sales - tmpSameDayData.amountOfSalesAdvertisementTotal[0];
            tmpSameDayData[value].push(
              tmpPerformance,
              dailyData.daily_data.budget.sales_budget_free,
              getRatioLabel(tmpPerformance, dailyData.daily_data.budget.sales_budget_free),
            );

            break;
          // 標準原価>合計
          case 'standardCostTotal':
            // tmpTotal = dailyData.accumulated_data.budget.food_menu_cost + dailyData.accumulated_data.budget.drink_menu_cost;
            // tmpTotal = tmpGrandTotalnData.standardCostFood[0] + tmpGrandTotalnData.standardCostDrink[0];
            if (dailyData.setting.calculationItemOfFRate === 1) {
              tmpTotal = dailyData.accumulated_data.budget.menu_cost_total;
            } else {
              tmpTotal = tmpGrandTotalnData.standardCostFood[0] + tmpGrandTotalnData.standardCostDrink[0];
            }
            tmpGrandTotalnData[value].push(
              tmpTotal,
              tmpGrandTotalnData.standardCostFood[1] + tmpGrandTotalnData.standardCostDrink[1],
              getRatioLabel(tmpTotal, tmpGrandTotalnData.standardCostFood[1] + tmpGrandTotalnData.standardCostDrink[1]),
              tmpGrandTotalnData.standardCostFood[3] + tmpGrandTotalnData.standardCostDrink[3],
              getRatioLabel(tmpTotal, tmpGrandTotalnData.standardCostFood[3] + tmpGrandTotalnData.standardCostDrink[3]),
            );

            if (dailyData.setting.calculationItemOfFRate === 1) {
              tmpSameDayData[value].push(
                dailyData.daily_data.budget.menu_cost_total,
                dailyData.daily_data.budget.sales_budget_cost_total,
                getRatioLabel((dailyData.daily_data.budget.menu_cost_total), dailyData.daily_data.budget.sales_budget_cost_total),
              );
            } else {
              tmpSameDayData[value].push(
                tmpSameDayData.standardCostFood[0] + tmpSameDayData.standardCostDrink[0],
                dailyData.daily_data.budget.sales_budget_cost_total,
                getRatioLabel(
                  tmpSameDayData.standardCostFood[0] + tmpSameDayData.standardCostDrink[0],
                  dailyData.daily_data.budget.sales_budget_cost_total,
                ),
              );
            }

            // tmpSameDayData[value].push(
            //    (dailyData.daily_data.budget.food_menu_cost + dailyData.daily_data.budget.drink_menu_cost),
            //    dailyData.daily_data.budget.sales_budget_cost_total,
            //    getRatioLabel((dailyData.daily_data.budget.food_menu_cost + dailyData.daily_data.budget.drink_menu_cost), dailyData.daily_data.budget.sales_budget_cost_total),
            // );


            break;
          // 標準原価>フード
          case 'standardCostFood':
            if (dailyData.setting.calculationItemOfFRate === 1) {
              tmpGrandTotalnData[value].push(
                dailyData.accumulated_data.budget.food_menu_cost,
                dailyData.accumulated_data.budget.sales_budget_food_cost,
                getRatioLabel(
                  dailyData.accumulated_data.budget.food_menu_cost,
                  dailyData.accumulated_data.budget.sales_budget_food_cost,
                ),
                dailyData.accumulated_data.budget.monthly_sales_budget_food_cost,
                getRatioLabel(dailyData.accumulated_data.budget.food_menu_cost, dailyData.accumulated_data.budget.monthly_sales_budget_food_cost),
              );
              tmpSameDayData[value].push(
                dailyData.daily_data.budget.food_menu_cost,
                dailyData.daily_data.budget.sales_budget_food_cost,
                getRatioLabel(dailyData.daily_data.budget.food_menu_cost, dailyData.daily_data.budget.sales_budget_food_cost),
              );
            } else if (dailyData.setting.includePettyCashInPurchase) {
              tmpGrandTotalnData[value].push(
                dailyData.accumulated_data.budget.purchase_food_cost
                + dailyData.accumulated_data.budget.purchase_food_cost_other_than_system
                + dailyData.accumulated_data.budget.petty_cash_food,
                dailyData.accumulated_data.budget.sales_budget_food_cost,
                getRatioLabel(
                  dailyData.accumulated_data.budget.purchase_food_cost
                  + dailyData.accumulated_data.budget.purchase_food_cost_other_than_system
                  + dailyData.accumulated_data.budget.petty_cash_food,
                  dailyData.accumulated_data.budget.sales_budget_food_cost,
                ),
                dailyData.accumulated_data.budget.monthly_sales_budget_food_cost,
                getRatioLabel(
                  dailyData.accumulated_data.budget.purchase_food_cost
                  + dailyData.accumulated_data.budget.purchase_food_cost_other_than_system
                  + dailyData.accumulated_data.budget.petty_cash_food,
                  dailyData.accumulated_data.budget.monthly_sales_budget_food_cost,
                ),
              );

              tmpSameDayData[value].push(
                dailyData.daily_data.budget.purchase_food_cost
                + dailyData.daily_data.budget.purchase_food_cost_other_than_system
                + dailyData.daily_data.budget.petty_cash_food,
                dailyData.daily_data.budget.sales_budget_food_cost,
                getRatioLabel(
                  dailyData.daily_data.budget.purchase_food_cost
                  + dailyData.daily_data.budget.purchase_food_cost_other_than_system
                  + dailyData.daily_data.budget.petty_cash_food,
                  dailyData.daily_data.budget.sales_budget_food_cost,
                ),
              );
            } else {
              tmpGrandTotalnData[value].push(
                dailyData.accumulated_data.budget.purchase_food_cost
                + dailyData.accumulated_data.budget.purchase_food_cost_other_than_system,
                dailyData.accumulated_data.budget.sales_budget_food_cost,
                getRatioLabel(
                  dailyData.accumulated_data.budget.purchase_food_cost
                  + dailyData.accumulated_data.budget.purchase_food_cost_other_than_system,
                  dailyData.accumulated_data.budget.sales_budget_food_cost,
                ),
                dailyData.accumulated_data.budget.monthly_sales_budget_food_cost,
                getRatioLabel(
                  dailyData.accumulated_data.budget.purchase_food_cost
                  + dailyData.accumulated_data.budget.purchase_food_cost_other_than_system,
                  dailyData.accumulated_data.budget.monthly_sales_budget_food_cost,
                ),
              );

              tmpSameDayData[value].push(
                dailyData.daily_data.budget.purchase_food_cost
                + dailyData.daily_data.budget.purchase_food_cost_other_than_system,
                dailyData.daily_data.budget.sales_budget_food_cost,
                getRatioLabel(
                  dailyData.daily_data.budget.purchase_food_cost
                  + dailyData.daily_data.budget.purchase_food_cost_other_than_system,
                  dailyData.daily_data.budget.sales_budget_food_cost,
                ),
              );
            }
            break;
          // 標準原価>ドリンク
          case 'standardCostDrink':
            if (dailyData.setting.calculationItemOfFRate === 1) {
              tmpGrandTotalnData[value].push(
                dailyData.accumulated_data.budget.drink_menu_cost,
                dailyData.accumulated_data.budget.sales_budget_drink_cost,
                getRatioLabel(
                  dailyData.accumulated_data.budget.drink_menu_cost,
                  dailyData.accumulated_data.budget.sales_budget_drink_cost,
                ),
                dailyData.accumulated_data.budget.monthly_sales_budget_drink_cost,
                getRatioLabel(dailyData.accumulated_data.budget.drink_menu_cost, dailyData.accumulated_data.budget.monthly_sales_budget_drink_cost),
              );
              tmpSameDayData[value].push(
                dailyData.daily_data.budget.drink_menu_cost,
                dailyData.daily_data.budget.sales_budget_drink_cost,
                getRatioLabel(dailyData.daily_data.budget.drink_menu_cost, dailyData.daily_data.budget.sales_budget_drink_cost),
              );
            } else if (dailyData.setting.includePettyCashInPurchase) {
              tmpGrandTotalnData[value].push(
                dailyData.accumulated_data.budget.purchase_drink_cost
                + dailyData.accumulated_data.budget.purchase_drink_cost_other_than_system
                + dailyData.accumulated_data.budget.petty_cash_drink
                + dailyData.accumulated_data.budget.petty_cash_drink_liquor,
                dailyData.accumulated_data.budget.sales_budget_drink_cost,
                getRatioLabel(
                  dailyData.accumulated_data.budget.purchase_drink_cost
                  + dailyData.accumulated_data.budget.purchase_drink_cost_other_than_system
                  + dailyData.accumulated_data.budget.petty_cash_drink
                  + dailyData.accumulated_data.budget.petty_cash_drink_liquor,
                  dailyData.accumulated_data.budget.sales_budget_drink_cost,
                ),
                dailyData.accumulated_data.budget.monthly_sales_budget_drink_cost,
                getRatioLabel(
                  dailyData.accumulated_data.budget.purchase_drink_cost
                  + dailyData.accumulated_data.budget.purchase_drink_cost_other_than_system
                  + dailyData.accumulated_data.budget.petty_cash_drink
                  + dailyData.accumulated_data.budget.petty_cash_drink_liquor,
                  dailyData.accumulated_data.budget.monthly_sales_budget_drink_cost,
                ),
              );
              tmpSameDayData[value].push(
                dailyData.daily_data.budget.purchase_drink_cost
                + dailyData.daily_data.budget.purchase_drink_cost_other_than_system
                + dailyData.daily_data.budget.petty_cash_drink
                + dailyData.daily_data.budget.petty_cash_drink_liquor,
                dailyData.daily_data.budget.sales_budget_drink_cost,
                getRatioLabel(
                  dailyData.daily_data.budget.purchase_drink_cost
                  + dailyData.daily_data.budget.purchase_drink_cost_other_than_system
                  + dailyData.daily_data.budget.petty_cash_drink
                  + dailyData.daily_data.budget.petty_cash_drink_liquor,
                  dailyData.daily_data.budget.sales_budget_drink_cost,
                ),
              );
            } else {
              tmpGrandTotalnData[value].push(
                dailyData.accumulated_data.budget.purchase_drink_cost
                + dailyData.accumulated_data.budget.purchase_drink_cost_other_than_system,
                dailyData.accumulated_data.budget.sales_budget_drink_cost,
                getRatioLabel(
                  dailyData.accumulated_data.budget.purchase_drink_cost
                  + dailyData.accumulated_data.budget.purchase_drink_cost_other_than_system,
                  dailyData.accumulated_data.budget.sales_budget_drink_cost,
                ),
                dailyData.accumulated_data.budget.monthly_sales_budget_drink_cost,
                getRatioLabel(
                  dailyData.accumulated_data.budget.purchase_drink_cost
                  + dailyData.accumulated_data.budget.purchase_drink_cost_other_than_system,
                  dailyData.accumulated_data.budget.monthly_sales_budget_drink_cost,
                ),
              );
              tmpSameDayData[value].push(
                dailyData.daily_data.budget.purchase_drink_cost
                + dailyData.daily_data.budget.purchase_drink_cost_other_than_system,
                dailyData.daily_data.budget.sales_budget_drink_cost,
                getRatioLabel(
                  dailyData.daily_data.budget.purchase_drink_cost
                  + dailyData.daily_data.budget.purchase_drink_cost_other_than_system,
                  dailyData.daily_data.budget.sales_budget_drink_cost,
                ),
              );
            }

            break;
          // 標準原価>率
          case 'standardCostRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.standardCostTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.standardCostTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.standardCostTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.standardCostTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.standardCostTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
          // 標準原価>合計
          case 'CostTotal':
            // tmpTotal = dailyData.accumulated_data.budget.food_menu_cost + dailyData.accumulated_data.budget.drink_menu_cost;
            // tmpTotal = tmpGrandTotalnData.standardCostFood[0] + tmpGrandTotalnData.standardCostDrink[0];
            tmpTotal = dailyData.accumulated_data.budget.menu_cost_total;
            tmpGrandTotalnData[value].push(
              tmpTotal,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.menu_cost_total,
              '-',
              '-',
            );
            // tmpSameDayData[value].push(
            //    (dailyData.daily_data.budget.food_menu_cost + dailyData.daily_data.budget.drink_menu_cost),
            //    dailyData.daily_data.budget.sales_budget_cost_total,
            //    getRatioLabel((dailyData.daily_data.budget.food_menu_cost + dailyData.daily_data.budget.drink_menu_cost), dailyData.daily_data.budget.sales_budget_cost_total),
            // );


            break;
          // 標準原価>フード
          case 'CostFood':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.food_menu_cost,
              '-',
              '-',
              '-',
              '-',
            );
            tmpSameDayData[value].push(
              dailyData.daily_data.budget.food_menu_cost,
              '-',
              '-',
            );
            break;
          // 標準原価>ドリンク
          case 'CostDrink':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.drink_menu_cost,
              '-',
              '-',
              '-',
              '-',
            );
            tmpSameDayData[value].push(
              dailyData.daily_data.budget.drink_menu_cost,
              '-',
              '-',
            );
            break;
          // 標準原価>率
          case 'CostRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.CostTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.CostTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
              '-',
              '-',
            );
            break;
            // 売上総利益>合計

          case 'grossProfitTotal':
            tmpPerformance = tmpGrandTotalnData.amountOfSalesTotal[0] - tmpGrandTotalnData.standardCostTotal[0];
            tmpBudget = dailyData.accumulated_data.budget.sales_budget_gross_profit;
            tmpMonthlyBudget = dailyData.accumulated_data.budget.monthly_sales_budget_gross_profit;
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(tmpPerformance, tmpMonthlyBudget),
            );

            tmpPerformance = tmpSameDayData.amountOfSalesTotal[0] - tmpSameDayData.standardCostTotal[0];
            tmpBudget = dailyData.daily_data.budget.sales_budget_gross_profit;
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;
          // 売上総利益>率
          case 'grossProfitRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.grossProfitTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.grossProfitTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.grossProfitTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.grossProfitTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.grossProfitTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );

            break;
          // 人件費>合計
          case 'laborCostsTotal':
            tmpPerformance = dailyData.accumulated_data.labor_cost.employee_labor_cost + dailyData.accumulated_data.labor_cost.part_labor_cost;
            tmpBudget = dailyData.accumulated_data.budget.sales_budget_labor_cost_total;
            tmpMonthlyBudget = dailyData.accumulated_data.budget.monthly_sales_budget_labor_cost_total;
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(tmpPerformance, tmpMonthlyBudget),
            );

            tmpPerformance = dailyData.daily_data.labor_cost.employee_labor_cost + dailyData.daily_data.labor_cost.part_labor_cost;
            tmpBudget = dailyData.daily_data.budget.sales_budget_labor_cost_total;
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;
          // 人件費>社員
          case 'laborCostsEmployee':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.labor_cost.employee_labor_cost,
              dailyData.accumulated_data.budget.sales_budget_employee_labor_cost,
              getRatioLabel(dailyData.accumulated_data.labor_cost.employee_labor_cost, dailyData.accumulated_data.budget.sales_budget_employee_labor_cost),
              dailyData.accumulated_data.budget.monthly_sales_budget_employee_labor_cost,
              getRatioLabel(dailyData.accumulated_data.labor_cost.employee_labor_cost, dailyData.accumulated_data.budget.monthly_sales_budget_employee_labor_cost),
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.labor_cost.employee_labor_cost,
              dailyData.daily_data.budget.sales_budget_employee_labor_cost,
              getRatioLabel(dailyData.daily_data.labor_cost.employee_labor_cost, dailyData.daily_data.budget.sales_budget_employee_labor_cost),
            );
            break;
          // 人件費>アルバイト
          case 'laborCostsParttime':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.labor_cost.part_labor_cost,
              dailyData.accumulated_data.budget.sales_budget_part_labor_cost,
              getRatioLabel(dailyData.accumulated_data.labor_cost.part_labor_cost, dailyData.accumulated_data.budget.sales_budget_part_labor_cost),
              dailyData.accumulated_data.budget.monthly_sales_budget_part_labor_cost,
              getRatioLabel(dailyData.accumulated_data.labor_cost.part_labor_cost, dailyData.accumulated_data.budget.monthly_sales_budget_part_labor_cost),
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.labor_cost.part_labor_cost,
              dailyData.daily_data.budget.sales_budget_part_labor_cost,
              getRatioLabel(dailyData.daily_data.labor_cost.part_labor_cost, dailyData.daily_data.budget.sales_budget_part_labor_cost),
            );

            break;
          // 人件費>率
          case 'laborCostsRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.laborCostsTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.laborCostsTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.laborCostsTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.laborCostsTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.laborCostsTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );

            break;

          // 家賃>合計
          case 'rent':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.rent,
              dailyData.accumulated_data.budget.sales_budget_rent,
              getRatioLabel(dailyData.accumulated_data.budget.rent, dailyData.accumulated_data.budget.sales_budget_rent),
              // todo
              dailyData.accumulated_data.budget.monthly_sales_budget_rent,
              getRatioLabel(dailyData.accumulated_data.budget.rent, dailyData.accumulated_data.budget.monthly_sales_budget_rent),
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.rent,
              dailyData.daily_data.budget.sales_budget_rent,
              getRatioLabel(dailyData.daily_data.budget.rent, dailyData.daily_data.budget.sales_budget_rent),
            );

            break;
          case 'commonServiceFee':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.common_service_fee,
              dailyData.accumulated_data.budget.sales_budget_common_service_fee,
              getRatioLabel(dailyData.accumulated_data.budget.common_service_fee, dailyData.accumulated_data.budget.sales_budget_common_service_fee),
              dailyData.accumulated_data.budget.monthly_sales_budget_common_service_fee,
              getRatioLabel(dailyData.accumulated_data.budget.common_service_fee, dailyData.accumulated_data.budget.monthly_sales_budget_common_service_fee),
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.common_service_fee,
              dailyData.daily_data.budget.sales_budget_common_service_fee,
              getRatioLabel(dailyData.daily_data.budget.common_service_fee, dailyData.daily_data.budget.sales_budget_common_service_fee),
            );

            break;
          case 'rentTotal':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.land_rent,
              dailyData.accumulated_data.budget.sales_budget_land_rent,
              getRatioLabel(dailyData.accumulated_data.budget.land_rent, dailyData.accumulated_data.budget.sales_budget_land_rent),
              dailyData.accumulated_data.budget.monthly_sales_budget_land_rent,
              getRatioLabel(dailyData.accumulated_data.budget.land_rent, dailyData.accumulated_data.budget.monthly_sales_budget_land_rent),
            );
            // todo
            tmpSameDayData[value].push(
              dailyData.daily_data.budget.land_rent,
              dailyData.daily_data.budget.sales_budget_land_rent,
              getRatioLabel(dailyData.daily_data.budget.land_rent, dailyData.daily_data.budget.sales_budget_land_rent),
            );

            break;

          // 家賃>率
          case 'rentRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.rentTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.rentTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.rentTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.rentTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.rentTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );

            break;
          // その他経費>合計
          case 'otherExpensesTotal':
            tmpPerformance = dailyData.accumulated_data.budget.sales_budget_other_fixed_cost + dailyData.accumulated_data.budget.sales_budget_other_variable_costs;
            tmpBudget = dailyData.accumulated_data.budget.sales_budget_other_expenses_total;
            tmpMonthlyBudget = dailyData.accumulated_data.budget.monthly_sales_budget_other_fixed_cost + dailyData.accumulated_data.budget.monthly_sales_budget_other_variable_costs;
            tmpGrandTotalnData[value].push(
              tmpGrandTotalnData.otherExpensesVariableCosts[0],
              tmpGrandTotalnData.otherExpensesVariableCosts[1],
              getRatioLabel(tmpGrandTotalnData.otherExpensesVariableCosts[0], tmpGrandTotalnData.otherExpensesVariableCosts[1]),
              tmpGrandTotalnData.otherExpensesVariableCosts[3],
              getRatioLabel(tmpGrandTotalnData.otherExpensesVariableCosts[0], tmpGrandTotalnData.otherExpensesVariableCosts[3]),
            );

            tmpPerformance = dailyData.daily_data.budget.sales_budget_other_fixed_cost + dailyData.daily_data.budget.sales_budget_other_variable_costs;
            tmpBudget = dailyData.daily_data.budget.sales_budget_other_expenses_total;
            tmpSameDayData[value].push(
              tmpSameDayData.otherExpensesVariableCosts[0],
              tmpSameDayData.otherExpensesVariableCosts[1],
              getRatioLabel(tmpSameDayData.otherExpensesVariableCosts[0], tmpSameDayData.otherExpensesVariableCosts[1]),
            );

            break;
          // 仕入（その他）
          case 'otherPurchase':
            if (dailyData.setting.includePettyCashInPurchase) {
              tmpGrandTotalnData[value].push(
                dailyData.accumulated_data.budget.purchase_other_cost
                + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system
                + dailyData.accumulated_data.budget.petty_cash_other
                + dailyData.accumulated_data.budget.petty_cash_expendables
                + dailyData.accumulated_data.budget.petty_cash_revenue_stamp,
                dailyData.accumulated_data.budget.sales_budget_other_purchase,
                getRatioLabel(
                  dailyData.accumulated_data.budget.purchase_other_cost
                  + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system
                  + dailyData.accumulated_data.budget.petty_cash_other
                  + dailyData.accumulated_data.budget.petty_cash_expendables
                  + dailyData.accumulated_data.budget.petty_cash_revenue_stamp,
                  dailyData.accumulated_data.budget.sales_budget_other_purchase,
                ),
                dailyData.accumulated_data.budget.monthly_sales_budget_other_purchase,
                getRatioLabel(
                  dailyData.accumulated_data.budget.purchase_other_cost
                  + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system
                  + dailyData.accumulated_data.budget.petty_cash_other
                  + dailyData.accumulated_data.budget.petty_cash_expendables
                  + dailyData.accumulated_data.budget.petty_cash_revenue_stamp,
                  dailyData.accumulated_data.budget.monthly_sales_budget_other_purchase,
                ),
              );
              tmpSameDayData[value].push(
                dailyData.daily_data.budget.purchase_other_cost
                + dailyData.daily_data.budget.purchase_other_cost_other_than_system
                + dailyData.daily_data.budget.petty_cash_other
                + dailyData.daily_data.budget.petty_cash_expendables
                + dailyData.daily_data.budget.petty_cash_revenue_stamp,
                dailyData.daily_data.budget.sales_budget_other_purchase,
                getRatioLabel(
                  dailyData.daily_data.budget.purchase_other_cost
                  + dailyData.daily_data.budget.purchase_other_cost_other_than_system
                  + dailyData.daily_data.budget.petty_cash_other
                  + dailyData.daily_data.budget.petty_cash_expendables
                  + dailyData.daily_data.budget.petty_cash_revenue_stamp,
                  dailyData.daily_data.budget.sales_budget_other_purchase,
                ),
              );
            } else {
              tmpGrandTotalnData[value].push(
                dailyData.accumulated_data.budget.purchase_other_cost
                + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system,
                dailyData.accumulated_data.budget.sales_budget_other_purchase,
                getRatioLabel(
                  dailyData.accumulated_data.budget.purchase_other_cost
                  + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system,
                  dailyData.accumulated_data.budget.sales_budget_other_purchase,
                ),
                dailyData.accumulated_data.budget.monthly_sales_budget_other_purchase,
                getRatioLabel(
                  dailyData.accumulated_data.budget.purchase_other_cost
                  + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system,
                  dailyData.accumulated_data.budget.monthly_sales_budget_other_purchase,
                ),
              );
              tmpSameDayData[value].push(
                dailyData.daily_data.budget.purchase_other_cost
                + dailyData.daily_data.budget.purchase_other_cost_other_than_system,
                dailyData.daily_data.budget.sales_budget_other_purchase,
                getRatioLabel(
                  dailyData.daily_data.budget.purchase_other_cost
                  + dailyData.daily_data.budget.purchase_other_cost_other_than_system,
                  dailyData.daily_data.budget.sales_budget_other_purchase,
                ),
              );
            }
            break;
          // その他経費>変動費
          // todo
          case 'otherExpensesVariableCosts':
            // tmpGrandTotalnData[value].push(
            //   dailyData.accumulated_data.budget.sales_budget_other_variable_costs,
            //   dailyData.accumulated_data.budget.sales_budget_other_variable_costs,
            //   getRatioLabel(dailyData.accumulated_data.budget.sales_budget_other_variable_costs, dailyData.accumulated_data.budget.sales_budget_other_variable_costs),
            //   dailyData.accumulated_data.budget.monthly_sales_budget_other_variable_costs,
            //   getRatioLabel(dailyData.accumulated_data.budget.sales_budget_other_variable_costs, dailyData.accumulated_data.budget.monthly_sales_budget_other_variable_costs),
            // );

            // tmpSameDayData[value].push(
            //   dailyData.daily_data.budget.sales_budget_other_variable_costs,
            //   dailyData.daily_data.budget.sales_budget_other_variable_costs,
            //   getRatioLabel(dailyData.daily_data.budget.sales_budget_other_variable_costs, dailyData.daily_data.budget.sales_budget_other_variable_costs),
            // );
            tmpGrandTotalnData[value].push(
              tmpGrandTotalnData.expensesTotal[0] + tmpGrandTotalnData.otherPurchase[0],
              tmpGrandTotalnData.expensesTotal[1] + tmpGrandTotalnData.otherPurchase[1],
              getRatioLabel(tmpGrandTotalnData.expensesTotal[2] + tmpGrandTotalnData.otherPurchase[2], dailyData.accumulated_data.budget.sales_budget_other_variable_costs),
              tmpGrandTotalnData.expensesTotal[3] + tmpGrandTotalnData.otherPurchase[3],
              getRatioLabel(tmpGrandTotalnData.expensesTotal[4] + tmpGrandTotalnData.otherPurchase[4], dailyData.accumulated_data.budget.monthly_sales_budget_other_variable_costs),
            );
            tmpSameDayData[value].push(
              tmpSameDayData.expensesTotal[0] + tmpSameDayData.otherPurchase[0],
              tmpSameDayData.expensesTotal[1] + tmpSameDayData.otherPurchase[1],
              getRatioLabel(tmpSameDayData.expensesTotal[2] + tmpSameDayData.otherPurchase[2], dailyData.daily_data.budget.sales_budget_other_variable_costs),
            );


            break;

          // その他経費>固定費
          case 'otherExpensesFixedCost':
            tmpGrandTotalnData[value].push(
              tmpGrandTotalnData.expensesTotal[0],
              tmpGrandTotalnData.expensesTotal[1],
              tmpGrandTotalnData.expensesTotal[2],
              tmpGrandTotalnData.expensesTotal[3],
              tmpGrandTotalnData.expensesTotal[4],
            );

            tmpSameDayData[value].push(
              tmpSameDayData.expensesTotal[0],
              tmpSameDayData.expensesTotal[1],
              tmpSameDayData.expensesTotal[2],
            );

            break;

          // その他経費>率
          case 'otherExpensesRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.otherExpensesTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.otherExpensesTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.otherExpensesTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.otherExpensesTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.otherExpensesTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );

            break;

          // 営業利益>合計
          case 'operatingIncomeTotal':
            tmpPerformance = tmpGrandTotalnData.amountOfSalesTotal[0] - tmpGrandTotalnData.FLAROCost[0];
            tmpBudget = tmpGrandTotalnData.amountOfSalesTotal[1] - tmpGrandTotalnData.FLAROCost[1];
            tmpMonthlyBudget = tmpGrandTotalnData.amountOfSalesTotal[3] - tmpGrandTotalnData.FLAROCost[3];
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(tmpPerformance, tmpMonthlyBudget),
            );

            tmpPerformance = tmpSameDayData.amountOfSalesTotal[0] - tmpSameDayData.FLAROCost[0];
            tmpBudget = tmpSameDayData.amountOfSalesTotal[1] - tmpSameDayData.FLAROCost[1];
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;

          // 営業利益>率
          case 'operatingIncomeRate':
            /*
            tmpMonthlyBudget = getRatioLabel((tmpGrandTotalnData.laborCostsTotal[3] + tmpGrandTotalnData.advertisingExpenseTotal[3] + tmpGrandTotalnData.rentTotal[3] + tmpGrandTotalnData.otherExpensesTotal[3]), tmpGrandTotalnData.grossProfitTotal[3]);
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.operatingIncomeTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.operatingIncomeTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              tmpMonthlyBudget,
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.operatingIncomeTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.operatingIncomeTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            */
            tmpMonthlyBudget = getRatioLabel(
              tmpGrandTotalnData.operatingIncomeTotal[3],
              tmpGrandTotalnData.amountOfSalesTotal[3],
            );
            tmpGrandTotalnData[value].push(
              getRatioLabel(
                tmpGrandTotalnData.operatingIncomeTotal[0],
                tmpGrandTotalnData.amountOfSalesTotal[0],
              ),
              getRatioLabel(
                tmpGrandTotalnData.operatingIncomeTotal[1],
                tmpGrandTotalnData.amountOfSalesTotal[1],
              ),
              '-',
              tmpMonthlyBudget,
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(
                tmpSameDayData.operatingIncomeTotal[0],
                tmpSameDayData.amountOfSalesTotal[0],
              ),
              getRatioLabel(
                tmpSameDayData.operatingIncomeTotal[1],
                tmpSameDayData.amountOfSalesTotal[1],
              ),
              '-',
            );
            break;

          // 仕入額>合計
          case 'purchaseAmountTotal':
            let tmpPurchaseSalesTotalCost = 0;
            if (dailyData.setting.includePettyCashInPurchase) {
              tmpPurchaseSalesTotalCost = dailyData.accumulated_data.budget.purchase_food_cost
                + dailyData.accumulated_data.budget.purchase_food_cost_other_than_system
                + dailyData.accumulated_data.budget.purchase_drink_cost
                + dailyData.accumulated_data.budget.purchase_drink_cost_other_than_system
                + dailyData.accumulated_data.budget.purchase_other_cost
                + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system
                + dailyData.accumulated_data.budget.petty_cash_food
                + dailyData.accumulated_data.budget.petty_cash_drink
                + dailyData.accumulated_data.budget.petty_cash_drink_liquor
                + dailyData.accumulated_data.budget.petty_cash_other
                + dailyData.accumulated_data.budget.petty_cash_expendables
                + dailyData.accumulated_data.budget.petty_cash_revenue_stamp;
            } else {
              tmpPurchaseSalesTotalCost = dailyData.accumulated_data.budget.purchase_food_cost
                + dailyData.accumulated_data.budget.purchase_food_cost_other_than_system
                + dailyData.accumulated_data.budget.purchase_drink_cost
                + dailyData.accumulated_data.budget.purchase_drink_cost_other_than_system
                + dailyData.accumulated_data.budget.purchase_other_cost
                + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system;
            }
            tmpGrandTotalnData[value].push(
              tmpPurchaseSalesTotalCost,
              '-',
              '-',
              '-',
              '-',
            );

            tmpPurchaseSalesTotalCost = 0;
            if (dailyData.setting.includePettyCashInPurchase) {
              tmpPurchaseSalesTotalCost = dailyData.daily_data.budget.purchase_food_cost
                + dailyData.daily_data.budget.purchase_food_cost_other_than_system
                + dailyData.daily_data.budget.purchase_drink_cost
                + dailyData.daily_data.budget.purchase_drink_cost_other_than_system
                + dailyData.daily_data.budget.purchase_other_cost
                + dailyData.daily_data.budget.purchase_other_cost_other_than_system
                + dailyData.daily_data.budget.petty_cash_food
                + dailyData.daily_data.budget.petty_cash_drink
                + dailyData.daily_data.budget.petty_cash_drink_liquor
                + dailyData.daily_data.budget.petty_cash_other
                + dailyData.daily_data.budget.petty_cash_expendables
                + dailyData.daily_data.budget.petty_cash_revenue_stamp;
            } else {
              tmpPurchaseSalesTotalCost = dailyData.daily_data.budget.purchase_food_cost
                + dailyData.daily_data.budget.purchase_food_cost_other_than_system
                + dailyData.daily_data.budget.purchase_drink_cost
                + dailyData.daily_data.budget.purchase_drink_cost_other_than_system
                + dailyData.daily_data.budget.purchase_other_cost
                + dailyData.daily_data.budget.purchase_other_cost_other_than_system;
            }

            tmpSameDayData[value].push(
              tmpPurchaseSalesTotalCost,
              '-',
              '-',
            );

            break;

          case 'purchaseAmountRateTotal':
            let tmpPurchaseSalesTotalCosRate = 0;
            if (dailyData.setting.includePettyCashInPurchase) {
              tmpPurchaseSalesTotalCosRate = dailyData.accumulated_data.budget.purchase_food_cost
                + dailyData.accumulated_data.budget.purchase_food_cost_other_than_system
                + dailyData.accumulated_data.budget.purchase_drink_cost
                + dailyData.accumulated_data.budget.purchase_drink_cost_other_than_system
                + dailyData.accumulated_data.budget.purchase_other_cost
                + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system
                + dailyData.accumulated_data.budget.petty_cash_food
                + dailyData.accumulated_data.budget.petty_cash_drink
                + dailyData.accumulated_data.budget.petty_cash_drink_liquor
                + dailyData.accumulated_data.budget.petty_cash_other
                + dailyData.accumulated_data.budget.petty_cash_expendables
                + dailyData.accumulated_data.budget.petty_cash_revenue_stamp;
            } else {
              tmpPurchaseSalesTotalCosRate = dailyData.accumulated_data.budget.purchase_food_cost
                + dailyData.accumulated_data.budget.purchase_food_cost_other_than_system
                + dailyData.accumulated_data.budget.purchase_drink_cost
                + dailyData.accumulated_data.budget.purchase_drink_cost_other_than_system
                + dailyData.accumulated_data.budget.purchase_other_cost
                + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system;
            }

            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpPurchaseSalesTotalCosRate, tmpGrandTotalnData.amountOfSalesTotal[0]),
              '-',
              '-',
              '-',
              '-',
            );

            tmpPurchaseSalesTotalCosRate = 0;
            if (dailyData.setting.includePettyCashInPurchase) {
              tmpPurchaseSalesTotalCosRate = dailyData.daily_data.budget.purchase_food_cost
                + dailyData.daily_data.budget.purchase_food_cost_other_than_system
                + dailyData.daily_data.budget.purchase_drink_cost
                + dailyData.daily_data.budget.purchase_drink_cost_other_than_system
                + dailyData.daily_data.budget.purchase_other_cost
                + dailyData.daily_data.budget.purchase_other_cost_other_than_system
                + dailyData.daily_data.budget.petty_cash_food
                + dailyData.daily_data.budget.petty_cash_drink
                + dailyData.daily_data.budget.petty_cash_drink_liquor
                + dailyData.daily_data.budget.petty_cash_other
                + dailyData.daily_data.budget.petty_cash_expendables
                + dailyData.daily_data.budget.petty_cash_revenue_stamp;
            } else {
              tmpPurchaseSalesTotalCosRate = dailyData.daily_data.budget.purchase_food_cost
                + dailyData.daily_data.budget.purchase_food_cost_other_than_system
                + dailyData.daily_data.budget.purchase_drink_cost
                + dailyData.daily_data.budget.purchase_drink_cost_other_than_system
                + dailyData.daily_data.budget.purchase_other_cost
                + dailyData.daily_data.budget.purchase_other_cost_other_than_system;
            }

            tmpSameDayData[value].push(
              getRatioLabel(tmpPurchaseSalesTotalCosRate, tmpGrandTotalnData.amountOfSalesTotal[0]),
              '-',
              '-',
            );
            break;

          // 仕入額>フード
          case 'purchaseAmountFood':
            let tmpPurchaseFoodCost = 0;
            if (dailyData.setting.includePettyCashInPurchase) {
              tmpPurchaseFoodCost = dailyData.accumulated_data.budget.purchase_food_cost
                + dailyData.accumulated_data.budget.purchase_food_cost_other_than_system
                + dailyData.accumulated_data.budget.petty_cash_food;
            } else {
              tmpPurchaseFoodCost = dailyData.accumulated_data.budget.purchase_food_cost
                + dailyData.accumulated_data.budget.purchase_food_cost_other_than_system;
            }

            tmpGrandTotalnData[value].push(
              tmpPurchaseFoodCost,
              '-',
              '-',
              '-',
              '-',
            );

            tmpPurchaseFoodCost = 0;
            if (dailyData.setting.includePettyCashInPurchase) {
              tmpPurchaseFoodCost = dailyData.daily_data.budget.purchase_food_cost
                + dailyData.daily_data.budget.purchase_food_cost_other_than_system
                + dailyData.daily_data.budget.petty_cash_food;
            } else {
              tmpPurchaseFoodCost = dailyData.daily_data.budget.purchase_food_cost
                + dailyData.daily_data.budget.purchase_food_cost_other_than_system;
            }

            tmpSameDayData[value].push(
              tmpPurchaseFoodCost,
              '-',
              '-',
            );

            break;

          // 仕入額>ドリンク
          case 'purchaseAmountDrink':
            let tmpPurchaseDrinkCost = 0;
            if (dailyData.setting.includePettyCashInPurchase) {
              tmpPurchaseDrinkCost = dailyData.accumulated_data.budget.purchase_drink_cost
                + dailyData.accumulated_data.budget.purchase_drink_cost_other_than_system
                + dailyData.accumulated_data.budget.petty_cash_drink
                + dailyData.accumulated_data.budget.petty_cash_drink_liquor;
            } else {
              tmpPurchaseDrinkCost = dailyData.accumulated_data.budget.purchase_drink_cost
                + dailyData.accumulated_data.budget.purchase_drink_cost_other_than_system;
            }

            tmpGrandTotalnData[value].push(
              tmpPurchaseDrinkCost,
              '-',
              '-',
              '-',
              '-',
            );

            tmpPurchaseDrinkCost = 0;
            if (dailyData.setting.includePettyCashInPurchase) {
              tmpPurchaseDrinkCost = dailyData.daily_data.budget.purchase_drink_cost
                + dailyData.daily_data.budget.purchase_drink_cost_other_than_system
                + dailyData.daily_data.budget.petty_cash_drink
                + dailyData.daily_data.budget.petty_cash_drink_liquor;
            } else {
              tmpPurchaseDrinkCost = dailyData.daily_data.budget.purchase_drink_cost
                + dailyData.daily_data.budget.purchase_drink_cost_other_than_system;
            }

            tmpSameDayData[value].push(
              tmpPurchaseDrinkCost,
              '-',
              '-',
            );

            break;

          // 仕入額>その他
          case 'purchaseAmountOhter':
            let tmpPurchaseOtherCost = 0;
            if (dailyData.setting.includePettyCashInPurchase) {
              tmpPurchaseOtherCost = dailyData.accumulated_data.budget.purchase_other_cost
                + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system
                + dailyData.accumulated_data.budget.petty_cash_other
                + dailyData.accumulated_data.budget.petty_cash_expendables
                + dailyData.accumulated_data.budget.petty_cash_revenue_stamp;
            } else {
              tmpPurchaseOtherCost = dailyData.accumulated_data.budget.purchase_other_cost
                + dailyData.accumulated_data.budget.purchase_other_cost_other_than_system;
            }
            tmpGrandTotalnData[value].push(
              tmpPurchaseOtherCost,
              '-',
              '-',
              '-',
              '-',
            );

            tmpPurchaseOtherCost = 0;
            if (dailyData.setting.includePettyCashInPurchase) {
              tmpPurchaseOtherCost = dailyData.daily_data.budget.purchase_other_cost
                + dailyData.daily_data.budget.purchase_other_cost_other_than_system
                + dailyData.daily_data.budget.petty_cash_other
                + dailyData.daily_data.budget.petty_cash_expendables
                + dailyData.daily_data.budget.petty_cash_revenue_stamp;
            } else {
              tmpPurchaseOtherCost = dailyData.daily_data.budget.purchase_other_cost
                + dailyData.daily_data.budget.purchase_other_cost_other_than_system;
            }

            tmpSameDayData[value].push(
              tmpPurchaseOtherCost,
              '-',
              '-',
            );

            break;

          // 客単価
          case 'pricePerCustomer':
            tmpPerformance = getDivision(tmpGrandTotalnData.amountOfSalesTotal[0], dailyData.accumulated_data.budget.guest_cnt, 0);
            // tmpBudget = dailyData.accumulated_data.budget.per_customer_price;

            tmpBudget = getDivision(dailyData.accumulated_data.budget.sales_budget_total, dailyData.accumulated_data.budget.sales_budget_guest_cnt, 0);

            tmpMonthlyBudget = dailyData.accumulated_data.budget.monthly_per_customer_price;
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(tmpPerformance, tmpMonthlyBudget),
            );

            tmpPerformance = getDivision(tmpSameDayData.amountOfSalesTotal[0], dailyData.daily_data.budget.guest_cnt, 0);
            tmpBudget = dailyData.daily_data.budget.per_customer_price;
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;

          // 客数
          case 'numberOfCustomers':
            tmpPerformance = dailyData.accumulated_data.budget.guest_cnt;
            tmpBudget = dailyData.accumulated_data.budget.sales_budget_guest_cnt;
            tmpMonthlyBudget = dailyData.accumulated_data.budget.monthly_sales_budget_guest_cnt;
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(tmpPerformance, tmpMonthlyBudget),
            );

            tmpPerformance = dailyData.daily_data.budget.guest_cnt;
            tmpBudget = dailyData.daily_data.budget.sales_budget_guest_cnt;
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;

          // 組数
          case 'numberOfGroups':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.customers,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.customers,
              '-',
              '-',
            );
            break;

          // 組あたり平均人数
          case 'averageNumberOfGuestPerGroup':
            tmpGrandTotalnData[value].push(
              getDivision(tmpGrandTotalnData.numberOfCustomers[0], tmpGrandTotalnData.numberOfGroups[0], 0),
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              getDivision(tmpSameDayData.numberOfCustomers[0], tmpSameDayData.numberOfGroups[0], 0),
              '-',
              '-',
            );

            break;

          // 回転率
          case 'TurnoverRate':
            tmpGrandTotalnData[value].push(
              getDivision(tmpGrandTotalnData.numberOfCustomers[0], dailyData.accumulated_data.budget.number_of_seat, 3),
              getDivision(tmpGrandTotalnData.numberOfCustomers[1], dailyData.accumulated_data.budget.number_of_seat, 3),
              '-',
              getDivision(tmpGrandTotalnData.numberOfCustomers[3], dailyData.accumulated_data.budget.monthly_number_of_seat, 3),
              '-',
            );

            tmpSameDayData[value].push(
              getDivision(tmpSameDayData.numberOfCustomers[0], dailyData.daily_data.budget.number_of_seat, 2),
              getDivision(tmpSameDayData.numberOfCustomers[1], dailyData.daily_data.budget.number_of_seat, 2),
              '-',
            );
            break;

          // 限界利益>額
          case 'porofitMarginAmount':
            tmpPerformance = tmpGrandTotalnData.amountOfSalesTotal[0] - tmpGrandTotalnData.standardCostTotal[0] - tmpGrandTotalnData.laborCostsParttime[0] + tmpGrandTotalnData.expensesTotalClassification0[0];
            tmpBudget = tmpGrandTotalnData.amountOfSalesTotal[1] - tmpGrandTotalnData.standardCostTotal[1] - tmpGrandTotalnData.laborCostsParttime[1] + tmpGrandTotalnData.expensesTotalClassification0[1];
            tmpMonthlyBudget = tmpGrandTotalnData.amountOfSalesTotal[3] - tmpGrandTotalnData.standardCostTotal[3] - tmpGrandTotalnData.laborCostsParttime[3] + tmpGrandTotalnData.expensesTotalClassification0[3];

            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );

            tmpPerformance = tmpSameDayData.amountOfSalesTotal[0] - tmpSameDayData.standardCostTotal[0] - tmpSameDayData.laborCostsParttime[0] + tmpSameDayData.expensesTotalClassification0[0];
            tmpBudget = tmpSameDayData.amountOfSalesTotal[1] - tmpSameDayData.standardCostTotal[1] - tmpSameDayData.laborCostsParttime[1] + tmpSameDayData.expensesTotalClassification0[1];


            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;

          // 限界利益>額
          case 'porofitMarginRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.porofitMarginAmount[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.porofitMarginAmount[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.porofitMarginAmount[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );


            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.porofitMarginAmount[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.porofitMarginAmount[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            break;

          // 貢献利益>率
          case 'contributionMarginAmount':
            tmpPerformance = tmpGrandTotalnData.porofitMarginAmount[0] - tmpGrandTotalnData.laborCostsEmployee[0] - tmpGrandTotalnData.advertisingExpenseTotal[0] - tmpGrandTotalnData.rentTotal[0] - tmpGrandTotalnData.expensesTotalClassification1[0];
            tmpBudget = tmpGrandTotalnData.porofitMarginAmount[1] - tmpGrandTotalnData.laborCostsEmployee[1] - tmpGrandTotalnData.advertisingExpenseTotal[1] - tmpGrandTotalnData.rentTotal[1] - tmpGrandTotalnData.expensesTotalClassification1[1];
            tmpMonthlyBudget = tmpGrandTotalnData.porofitMarginAmount[3] - tmpGrandTotalnData.laborCostsEmployee[3] - tmpGrandTotalnData.advertisingExpenseTotal[3] - tmpGrandTotalnData.rentTotal[3] - tmpGrandTotalnData.expensesTotalClassification1[3];
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );

            tmpPerformance = tmpSameDayData.porofitMarginAmount[0] - tmpSameDayData.laborCostsEmployee[0] - tmpSameDayData.advertisingExpenseTotal[0] - tmpSameDayData.rentTotal[0] - tmpSameDayData.expensesTotalClassification1[0];
            tmpBudget = tmpSameDayData.porofitMarginAmount[1] - tmpSameDayData.laborCostsEmployee[1] - tmpSameDayData.advertisingExpenseTotal[1] - tmpSameDayData.rentTotal[1] - tmpSameDayData.expensesTotalClassification1[1];
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );
            break;

          // 貢献利益>率
          case 'contributionMarginRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.contributionMarginAmount[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.contributionMarginAmount[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.contributionMarginAmount[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.contributionMarginAmount[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.contributionMarginAmount[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            break;

          // 損益分岐点>売上
          case 'breakEvenPointSales':

            tmpPerformance = formatDevision(
              (tmpGrandTotalnData.laborCostsEmployee[0] + tmpGrandTotalnData.advertisingExpenseTotal[0] + tmpGrandTotalnData.rentTotal[0] + tmpGrandTotalnData.expensesTotalClassification1[0]),
              formatDevision(tmpGrandTotalnData.porofitMarginAmount[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
            );
            tmpBudget = formatDevision(
              (tmpGrandTotalnData.laborCostsEmployee[1] + tmpGrandTotalnData.advertisingExpenseTotal[1] + tmpGrandTotalnData.rentTotal[1] + tmpGrandTotalnData.expensesTotalClassification1[1]),
              formatDevision(tmpGrandTotalnData.porofitMarginAmount[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
            );
            tmpMonthlyBudget = formatDevision(
              (tmpGrandTotalnData.laborCostsEmployee[3] + tmpGrandTotalnData.advertisingExpenseTotal[3] + tmpGrandTotalnData.rentTotal[3] + tmpGrandTotalnData.expensesTotalClassification1[3]),
              formatDevision(tmpGrandTotalnData.porofitMarginAmount[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
            );

            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );

            tmpPerformance = formatDevision(
              (tmpSameDayData.laborCostsEmployee[0] + tmpSameDayData.advertisingExpenseTotal[0] + tmpSameDayData.rentTotal[0] + tmpSameDayData.expensesTotalClassification1[0]),
              formatDevision(tmpSameDayData.porofitMarginAmount[0], tmpSameDayData.amountOfSalesTotal[0]),
            );
            tmpBudget = formatDevision(
              (tmpSameDayData.laborCostsEmployee[1] + tmpSameDayData.advertisingExpenseTotal[1] + tmpSameDayData.rentTotal[1] + tmpSameDayData.expensesTotalClassification1[1]),
              formatDevision(tmpSameDayData.porofitMarginAmount[1], tmpSameDayData.amountOfSalesTotal[1]),
            );
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;

          // 損益分岐点>客数
          case 'breakEvenPointNumberOfGuest':
            tmpPerformance = formatDevision(tmpGrandTotalnData.breakEvenPointSales[0], tmpGrandTotalnData.pricePerCustomer[0]);
            tmpBudget = formatDevision(tmpGrandTotalnData.breakEvenPointSales[1], tmpGrandTotalnData.pricePerCustomer[1]);
            tmpMonthlyBudget = formatDevision(tmpGrandTotalnData.breakEvenPointSales[3], tmpGrandTotalnData.pricePerCustomer[3]);
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(tmpPerformance, tmpMonthlyBudget),
            );

            tmpPerformance = formatDevision(tmpSameDayData.breakEvenPointSales[0], tmpSameDayData.pricePerCustomer[0]);
            tmpBudget = formatDevision(tmpSameDayData.breakEvenPointSales[1], tmpSameDayData.pricePerCustomer[1]);
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );
            break;

          // 人件費率>合計
          case 'laborCostRateTotal':
            tmpPerformance = getRatioLabel(tmpGrandTotalnData.laborCostsTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpGrandTotalnData.laborCostsTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]);
            tmpMonthlyBudget = getRatioLabel(tmpGrandTotalnData.laborCostsTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]);
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
              tmpMonthlyBudget,
              '-',
            );

            tmpPerformance = getRatioLabel(tmpSameDayData.laborCostsTotal[0], tmpSameDayData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpSameDayData.laborCostsTotal[1], tmpSameDayData.amountOfSalesTotal[1]);
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
            );
            break;

          // 人件費率>社員
          case 'laborCostRateEmployee':
            tmpPerformance = getRatioLabel(tmpGrandTotalnData.laborCostsEmployee[0], tmpGrandTotalnData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpGrandTotalnData.laborCostsEmployee[1], tmpGrandTotalnData.amountOfSalesTotal[1]);
            tmpMonthlyBudget = getRatioLabel(tmpGrandTotalnData.laborCostsEmployee[3], tmpGrandTotalnData.amountOfSalesTotal[3]);
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
              tmpMonthlyBudget,
              '-',
            );

            tmpPerformance = getRatioLabel(tmpSameDayData.laborCostsEmployee[0], tmpSameDayData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpSameDayData.laborCostsEmployee[1], tmpSameDayData.amountOfSalesTotal[1]);
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
            );
            break;

          // 人件費率>アルバイト
          case 'laborCostRatPartTime':
            tmpPerformance = getRatioLabel(tmpGrandTotalnData.laborCostsParttime[0], tmpGrandTotalnData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpGrandTotalnData.laborCostsParttime[1], tmpGrandTotalnData.amountOfSalesTotal[1]);
            tmpMonthlyBudget = getRatioLabel(tmpGrandTotalnData.laborCostsParttime[3], tmpGrandTotalnData.amountOfSalesTotal[3]);
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
              tmpMonthlyBudget,
              '-',
            );

            tmpPerformance = getRatioLabel(tmpSameDayData.laborCostsParttime[0], tmpSameDayData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpSameDayData.laborCostsParttime[1], tmpSameDayData.amountOfSalesTotal[1]);
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
            );
            break;

          // 総労働時間>合計
          case 'totalWorkingHoursTotal':
            tmpPerformance = dailyData.accumulated_data.labor_cost.total_time_disp;
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              '-',
              '-',
              '-',
              '-',
            );

            tmpTotalWorkingHoursCumulative = dailyData.accumulated_data.labor_cost.employee_total_time + dailyData.accumulated_data.labor_cost.part_total_time;
            if (tmpTotalWorkingHoursCumulative > 0) {
              tmpTotalWorkingHoursCumulative /= 60;
            }

            tmpPerformance = dailyData.daily_data.labor_cost.total_time_disp;
            tmpSameDayData[value].push(
              tmpPerformance,
              '-',
              '-',
            );

            tmpTotalWorkingHours = dailyData.daily_data.labor_cost.employee_total_time + dailyData.daily_data.labor_cost.part_total_time;
            if (tmpTotalWorkingHours > 0) {
              tmpTotalWorkingHours /= 60;
            }

            break;

          // 総労働時間>社員
          case 'totalWorkingHoursEmployee':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.labor_cost.employee_total_time_disp,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.labor_cost.employee_total_time_disp,
              '-',
              '-',
            );
            break;

          // 総労働時間>アルバイト
          case 'totalWorkingHoursPartTime':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.labor_cost.part_total_time_disp,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.labor_cost.part_total_time_disp,
              '-',
              '-',
            );
            break;

          // 勤務人数>合計
          case 'numberOfEmployeesTotal':
            tmpGrandTotalnData[value].push(
              (dailyData.accumulated_data.labor_cost.employee_count + dailyData.accumulated_data.labor_cost.part_count),
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              (dailyData.daily_data.labor_cost.employee_count + dailyData.daily_data.labor_cost.part_count),
              '-',
              '-',
            );
            break;

          // 勤務人数>社員
          case 'numberOfEmployeesEmployee':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.labor_cost.employee_count,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.labor_cost.employee_count,
              '-',
              '-',
            );
            break;

          // 勤務人数>アルバイト
          case 'numberOfEmployeesPartTime':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.labor_cost.part_count,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.labor_cost.part_count,
              '-',
              '-',
            );
            break;

          // 人時売上高
          case 'whenPeopleAreHigh':
            tmpGrandTotalnData[value].push(
              getDivision(tmpGrandTotalnData.amountOfSalesTotal[0], tmpTotalWorkingHoursCumulative, 0),
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              getDivision(tmpSameDayData.amountOfSalesTotal[0], tmpTotalWorkingHours, 0),
              '-',
              '-',
            );
            break;

          // 人時生産性
          case 'humanTimeProductivity':
            tmpGrandTotalnData[value].push(
              getDivision(tmpGrandTotalnData.grossProfitTotal[0], tmpTotalWorkingHoursCumulative, 0),
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              getDivision(tmpSameDayData.grossProfitTotal[0], tmpTotalWorkingHours, 0),
              '-',
              '-',
            );
            break;

          // 人時接客数
          case 'numberOfPickUps':
            tmpGrandTotalnData[value].push(
              getDivision(tmpGrandTotalnData.numberOfCustomers[0], tmpTotalWorkingHoursCumulative, 0),
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              getDivision(tmpSameDayData.numberOfCustomers[0], tmpTotalWorkingHours, 0),
              '-',
              '-',
            );
            break;

          // 労働生産性
          case 'laborProductivity':
            tmpGrandTotalnData[value].push(
              getDivision(tmpGrandTotalnData.grossProfitTotal[0], tmpGrandTotalnData.numberOfEmployeesTotal[0], 0),
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              getDivision(tmpSameDayData.grossProfitTotal[0], tmpSameDayData.numberOfEmployeesTotal[0], 0),
              '-',
              '-',
            );
            break;

          // 労働分配率
          case 'laborShare':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.laborCostsTotal[0], tmpGrandTotalnData.grossProfitTotal[0]),
              getRatioLabel(tmpGrandTotalnData.laborCostsTotal[1], tmpGrandTotalnData.grossProfitTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.laborCostsTotal[3], tmpGrandTotalnData.grossProfitTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.laborCostsTotal[0], tmpSameDayData.grossProfitTotal[0]),
              getRatioLabel(tmpSameDayData.laborCostsTotal[1], tmpSameDayData.grossProfitTotal[1]),
              '-',
            );
            break;

          // FL>Cost
          case 'FLCost':
            tmpFCostValue = 0;
            if (dailyData.setting.calculationItemOfFRate === 0) {
              // 仕入れ
              tmpFCostValue = tmpGrandTotalnData.purchaseAmountFood[0]
                + tmpGrandTotalnData.purchaseAmountDrink[0];
            } else {
              // 標準原価
              tmpFCostValue = tmpGrandTotalnData.standardCostTotal[0];
            }

            tmpPerformance = tmpFCostValue + tmpGrandTotalnData.laborCostsTotal[0];
            tmpBudget = tmpGrandTotalnData.standardCostTotal[1] + tmpGrandTotalnData.laborCostsTotal[1];
            tmpMonthlyBudget = tmpGrandTotalnData.standardCostTotal[3] + tmpGrandTotalnData.laborCostsTotal[3];
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );

            tmpFCostValue = 0;
            if (dailyData.setting.calculationItemOfFRate === 0) {
              tmpFCostValue = tmpSameDayData.purchaseAmountFood[0]
                + tmpSameDayData.purchaseAmountDrink[0];
            } else {
              tmpFCostValue = tmpSameDayData.standardCostTotal[0];
            }

            tmpPerformance = tmpFCostValue + tmpSameDayData.laborCostsTotal[0];
            tmpBudget = tmpSameDayData.standardCostTotal[1] + tmpSameDayData.laborCostsTotal[1];
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );
            break;

          // FL>Rate
          case 'FLRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.FLCost[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.FLCost[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.FLCost[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.FLCost[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.FLCost[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            break;

          // FLA>Cost
          case 'FLACost':
            tmpPerformance = tmpGrandTotalnData.standardCostTotal[0] + tmpGrandTotalnData.laborCostsTotal[0] + tmpGrandTotalnData.advertisingExpenseTotal[0];
            tmpBudget = tmpGrandTotalnData.standardCostTotal[1] + tmpGrandTotalnData.laborCostsTotal[1] + tmpGrandTotalnData.advertisingExpenseTotal[1];
            tmpMonthlyBudget = tmpGrandTotalnData.standardCostTotal[3] + tmpGrandTotalnData.laborCostsTotal[3] + tmpGrandTotalnData.advertisingExpenseTotal[3];
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );


            tmpPerformance = tmpSameDayData.standardCostTotal[0] + tmpSameDayData.laborCostsTotal[0] + tmpSameDayData.advertisingExpenseTotal[0];
            tmpBudget = tmpSameDayData.standardCostTotal[1] + tmpSameDayData.laborCostsTotal[1] + tmpSameDayData.advertisingExpenseTotal[1];
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );
            break;

          // FLA>Rate
          case 'FLARate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.FLACost[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.FLACost[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.FLACost[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.FLACost[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.FLACost[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            break;

          // ProfitLossCost
          case 'ProfitLossCost':
            tmpGrandTotalnData[value].push(
              tmpGrandTotalnData.amountOfSalesTotal[0] - tmpGrandTotalnData.FLACost[0],
              tmpGrandTotalnData.amountOfSalesTotal[1] - tmpGrandTotalnData.FLACost[1],
              getRatioLabel(tmpGrandTotalnData.amountOfSalesTotal[0] - tmpGrandTotalnData.FLACost[0], tmpGrandTotalnData.amountOfSalesTotal[1] - tmpGrandTotalnData.FLACost[1]),
              tmpGrandTotalnData.amountOfSalesTotal[3] - tmpGrandTotalnData.FLACost[3],
              // todo
              getRatioLabel(getRatioLabel(tmpGrandTotalnData.amountOfSalesTotal[0] - tmpGrandTotalnData.FLACost[0], tmpGrandTotalnData.amountOfSalesTotal[1] - tmpGrandTotalnData.FLACost[1]), tmpGrandTotalnData.amountOfSalesTotal[3] - tmpGrandTotalnData.FLACost[3]),
            );
            tmpSameDayData[value].push(
              tmpSameDayData.amountOfSalesTotal[0] - tmpSameDayData.FLACost[0],
              tmpSameDayData.amountOfSalesTotal[1] - tmpSameDayData.FLACost[1],
              getRatioLabel(tmpSameDayData.amountOfSalesTotal[0] - tmpSameDayData.FLACost[0], tmpSameDayData.amountOfSalesTotal[1] - tmpSameDayData.FLACost[1]),
            );
            break;

          // ProfitLossRate
          case 'ProfitLossRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.FLACost[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.FLACost[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.FLACost[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.FLACost[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.FLACost[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            break;


          // FLAR>Cost
          case 'FLARCost':
            tmpFCostValue = 0;
            if (dailyData.setting.calculationItemOfFRate === 0) {
              // 仕入れ
              tmpFCostValue = tmpGrandTotalnData.purchaseAmountFood[0]
                + tmpGrandTotalnData.purchaseAmountDrink[0];
            } else {
              // 標準原価
              tmpFCostValue = tmpGrandTotalnData.standardCostTotal[0];
            }

            tmpPerformance = tmpFCostValue + tmpGrandTotalnData.laborCostsTotal[0] + tmpGrandTotalnData.advertisingExpenseTotal[0] + tmpGrandTotalnData.rentTotal[0];
            tmpBudget = tmpGrandTotalnData.standardCostTotal[1] + tmpGrandTotalnData.laborCostsTotal[1] + tmpGrandTotalnData.advertisingExpenseTotal[1] + tmpGrandTotalnData.rentTotal[1];
            tmpMonthlyBudget = tmpGrandTotalnData.standardCostTotal[3] + tmpGrandTotalnData.laborCostsTotal[3] + tmpGrandTotalnData.advertisingExpenseTotal[3] + tmpGrandTotalnData.rentTotal[3];
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );

            tmpFCostValue = 0;
            if (dailyData.setting.calculationItemOfFRate === 0) {
              tmpFCostValue = tmpSameDayData.purchaseAmountFood[0]
                + tmpSameDayData.purchaseAmountDrink[0];
            } else {
              tmpFCostValue = tmpSameDayData.standardCostTotal[0];
            }

            tmpPerformance = tmpFCostValue + tmpSameDayData.laborCostsTotal[0] + tmpSameDayData.advertisingExpenseTotal[0] + tmpSameDayData.rentTotal[0];
            tmpBudget = tmpSameDayData.standardCostTotal[1] + tmpSameDayData.laborCostsTotal[1] + tmpSameDayData.advertisingExpenseTotal[1] + tmpSameDayData.rentTotal[1];
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );
            break;

          // FLAR>Rate
          case 'FLARRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.FLARCost[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.FLARCost[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.FLARCost[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.FLARCost[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.FLARCost[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            break;

          // FLARO>Cost
          case 'FLAROCost':


            tmpPerformance = tmpGrandTotalnData.FLACost[0] + tmpGrandTotalnData.rentTotal[0] + tmpGrandTotalnData.otherExpensesTotal[0];
            tmpBudget = tmpGrandTotalnData.FLACost[1] + tmpGrandTotalnData.rentTotal[1] + tmpGrandTotalnData.otherExpensesTotal[1];
            tmpMonthlyBudget = tmpGrandTotalnData.FLACost[3] + tmpGrandTotalnData.rentTotal[3] + tmpGrandTotalnData.otherExpensesTotal[3];
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );


            tmpPerformance = tmpSameDayData.FLACost[0] + tmpSameDayData.rentTotal[0] + tmpSameDayData.otherExpensesTotal[0];
            tmpBudget = tmpSameDayData.FLACost[1] + tmpSameDayData.rentTotal[1] + tmpSameDayData.otherExpensesTotal[1];
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );
            break;

          // FLARO>Rate
          case 'FLARORate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.FLAROCost[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.FLAROCost[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.FLAROCost[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.FLAROCost[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.FLAROCost[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            break;

          default:
            break;
        }
      }
      return true;
    });

    // set
    setGrandTotalnData(tmpGrandTotalnData);
    setSameDayData(tmpSameDayData);
    setDataKeyName(tmpDataKeyName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countAdvertising, targetDay, targetOrgCode]);

  useEffect(() => {
    console.log('fetchData targetDay -------------------------------------', targetDay);
    console.log('fetchData targetOrgCode -------------------------------------', targetOrgCode);
    // get data
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDay, targetOrgCode]);

  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatterPercent = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    style: 'percent',
  });

  const getRatioLabel = (numerator: any, denominator: any) => {
    let result = 0;
    if (typeof numerator === 'undefined' || typeof denominator === 'undefined') {
      result = 0;
    } else if (isNaN(numerator) || isNaN(denominator)) {
      result = 0;
    } else if (numerator === 0 || denominator === 0) {
      result = 0;
    } else {
      result = numerator / denominator;
    }
    return formatterPercent.format(result);
  };

  const formatDevision = (numerator: any, denominator: any) => {
    let result = 0;
    if (typeof numerator === 'undefined' || typeof denominator === 'undefined') {
      result = 0;
    } else if (isNaN(numerator) || isNaN(denominator)) {
      result = 0;
    } else if (numerator === 0 || denominator === 0) {
      result = 0;
    } else {
      result = numerator / denominator;
    }
    return Number(result);
  };

  const getDivision = (numerator: any, denominator: any, fixed?: any) => {
    let rsNumber = 0;
    let tmp: Array<any> = [];
    if (String(numerator).indexOf('%') !== -1) {
      tmp = String(numerator).split('%');
      numerator = tmp[0] / 100;
    }
    if (String(denominator).indexOf('%') !== -1) {
      tmp = String(denominator).split('%');
      denominator = tmp[0] / 100;
    }
    if (typeof numerator === 'undefined' || typeof denominator === 'undefined') {
      rsNumber = 0;
    } else if (isNaN(numerator) || isNaN(denominator)) {
      rsNumber = 0;
    } else if (numerator === 0 || denominator === 0) {
      rsNumber = 0;
    } else {
      rsNumber = numerator / denominator;
    }
    if (typeof fixed === 'undefined') {
      tmp = String(rsNumber).split('.');
      fixed = 0;
      if (tmp[1] && tmp[1].length >= 3) {
        fixed = 3;
      } else if (tmp[1]) {
        fixed = tmp[1].length;
      }
    }
    return Number(parseFloat(String(rsNumber)).toFixed(fixed));
  };

  const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num)) {
      return num;
    }
    const tmp = String(num).split('.');
    if (tmp[1]) {
      let fixed = 3;
      if (tmp[1].length < 3) {
        fixed = tmp[1].length;
      }
      return parseFloat(num).toFixed(fixed);
    }
    return formatter.format(num);
  };

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    targetDay,
    setTargetDay,
    targetOrgCode,
    setTargetOrgCode,
    countAdvertising,
    formatter,
    formatterPercent,
    numberFormater,
    generalData,
    setGeneralData,
    dataKeyName,
    grandTotalnData,
    sameDayData,
    reportByTimeOfDay,
    advertisingData,
    expensesData,
    countExpenses,
    isLoading,
    setIsLoading,
    cashRegisterReport,
    confirmModalOpen,
    closeConfirmModal,
    setConfirmModalOpen,
    updateCountDayAndAdvertising,
    budgetProgressComment,
    storeOperationComment,
    otherComment,
    setBudgetProgressComment,
    setStoreOperationComment,
    setOtherComment,
    newDailyData,
    paymentMediaAmountManual,
    setPaymentMediaAmountManual,
    attachFileData,
    setAttachFileData,
    discountTypeData,
    setDiscountTypeData,
    setting,
    detailApplicationData,
    roleOrg,
  };
};

export const eventScroll = (e: { target: any; }) => {
  const { target } = e;
  const layout_sale_right_01 = document.getElementById('layout_sale_right_01');
  const layout_sale_right_02 = document.getElementById('layout_sale_right_02');
  const layout_sale_02_buttom_right_set_scroll = document.getElementById('layout_sale_02_buttom_right_set_scroll');
  if (layout_sale_right_01 && layout_sale_right_02 && layout_sale_02_buttom_right_set_scroll) {
    layout_sale_right_01.scrollLeft = target.scrollLeft;
    layout_sale_right_02.scrollLeft = target.scrollLeft;
    layout_sale_02_buttom_right_set_scroll.scrollLeft = target.scrollLeft;
  }
};


export default useSalesMonthlyReport;
