import { SaleBudgetYearType } from 'components/organismos/master/storeManagement/salesBudgetYear/salesBudgetYearLayout/type';
import ApiClient from './ApiClient';

export const orgSelect = async (functionType:number = 2, dayFrom?: string, dayTo?: string,categoryAry?:any[]): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  // TODO: V4にバージョンアップ予定
  const response = await ApiClient.get(`/v3/organizations/trees/${companyCode}?staffCode=${staffCode}&functionType=${functionType}&targetDayFrom=${dayFrom}&targetDayTo=${dayTo}&categoryAry=${categoryAry}`, {});
  return response.data;
};


export const getSalesBudgetYearly = async ({ orgCode, targetYear, categoryAry }: { orgCode?: string; targetYear?: string; categoryAry: any; }): Promise<SaleBudgetYearType> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }

  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    loginStaffCode,
    functionType: 3,
  };

  const response = await ApiClient.get(`/v3/salesBudgetYearly/${companyCode}?orgCode=${orgCode}&targetYear=${targetYear}${appendUrl}`, params);
  return response.data;
};


export default {
  orgSelect,
};
