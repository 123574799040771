import ApiClient from "./ApiClient";

export const apiGetYearlyBalaceDetailTable = async (
    orgCode: string,
    yearlyStartDate: string,
    yearlyEndDate: string,
    categoryAry: any
): Promise<any> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const params = {
        orgCode,
        yearlyStartDate,
        yearlyEndDate,
        loginStaffCode,
        functionType: 3,
    };

    let appenUrl = '';
    if(Array.isArray(categoryAry)){
        categoryAry.forEach((cateArr: any) => {
            appenUrl += `&categoryAry=${cateArr.join(',')}`;
        });
    }

    const response = await ApiClient.get(`/v1/sales/list/standard/yearly/balancedetailtable/${companyCode}?${appenUrl}`, params);
    return response.data;
};

export const downloadIncomeAndExpenditureScheduleMonthlyCsv = async (incomeAndExpenditureSchedule: any, fileName: any): Promise<void> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    await ApiClient.downloadCsvPost(`/v1/reportExportFile/csv/${companyCode}`, {}, incomeAndExpenditureSchedule, fileName);
};

export const downloadIncomeAndExpenditureScheduleMonthlyExcel = async (incomeAndExpenditureSchedule: any, fileName: any): Promise<void> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    await ApiClient.downloadExcelPost(`/v1/reportExportFile/excel/${companyCode}`, {}, incomeAndExpenditureSchedule, fileName);
};