/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PrimaryButton, { SecondaryButton } from 'components/atoms/Button';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import Icon from 'components/atoms/Icon';
import BlockUI from 'components/molecules/BlockUi';
import Checkbox from 'components/molecules/Checkbox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import IconLabelButton from 'components/molecules/IconLabelButton';
import MultipleCheckboxForm from 'components/molecules/MultipleCheckboxForm';
import { ActionType } from 'components/organismos/ConfirmActionModal';
import ConfirmModal from 'components/organismos/ConfirmModal';
import DataTable from 'components/organismos/DataTable/DataTable';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { iconColor } from 'components/styles';
import AdvertisingPlanDomain from 'domain/advertisingplanorganization';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { useStoreSettingPage } from './hook';

const StoreSettingsPage: React.FC = () => {
  const {
    formik,
    orgCode,
    setOrgCode,
    listChecked,
    setListChecked,
    submitUpdate,
    isLoading,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    endDate,
    setEndDate,
    dateTo,
    confirmModalOpen,
    confirmModalUpdate,
    setConfirmModalUpdate,
    confirmModalDelet,
    setConfirmModalDelete,
    closeConfirmModal,
    functionType,
    staffCode,
    checked,
    setChecked,
    mediaMasterList,
    deleteAdvertisingPlan,
    downloadFileCSV,
    openConfirmModal,
    setCategoryAr,
    checkUserRole,
  } = useStoreSettingPage();

  const columns: Array<Column<AdvertisingPlanDomain>> = useMemo(() => [
    {
      Header: () => (
        <div style={{ lineHeight: 0.5 }}>
          <Checkbox
            id="checkAll"
            name="checkAll"
            label=""
            value=""
            checked={mediaMasterList.length === listChecked.length && listChecked.length > 0}
            onChange={(e) => {
              if (e.target.checked === true) {
                const newArr = [...mediaMasterList].map((data:any) => ({
                  orgCode: data.orgCode,
                  orgName: data.orgName,
                  advertisingPlanMstCode: data.advertisingPlanMstCode,
                  advertisingPlanMstName: data.advertisingPlanMstName,
                  applyStartDate: moment(data.applyStartDay).format('YYYY/MM/DD'),
                }));
                setEndDate(dateTo);
                setListChecked(newArr);
              } else {
                setListChecked([]);
              }
            }}
          />
        </div>
      ),
      accessor: 'checkBox',
      disableSortBy: true,
      Cell: (cell: { row :{original: AdvertisingPlanDomain}}) => {
        const idRow = `${cell.row.original.orgCode}-${cell.row.original.advertisingPlanMstCode}`;
        const formList = {
          orgCode: cell.row.original.orgCode,
          orgName: cell.row.original.orgName,
          advertisingPlanMstCode: cell.row.original.advertisingPlanMstCode,
          advertisingPlanMstName: cell.row.original.advertisingPlanMstName,
          applyStartDate: moment(cell.row.original.applyStartDay).format('YYYY/MM/DD'),
        };
        return (
          <div style={{ lineHeight: 0.5 }}>
            <Checkbox
              id={idRow}
              name={idRow}
              value=""
              checked={!![...listChecked].filter((data:any) => `${data.orgCode}-${data.advertisingPlanMstCode}` === idRow).length}
              onChange={(e) => {
                if (e.target.checked === true) {
                  const newArr = [...listChecked, formList];
                  setEndDate(dateTo);
                  setListChecked(newArr);
                } else {
                  const newArr = [...listChecked].filter((data:any) => `${data.orgCode}-${data.advertisingPlanMstCode}` !== idRow);
                  setListChecked(newArr);
                }
              }}
            />
          </div>
        );
      },
    },
    {
      Header: '組織名',
      accessor: 'orgName',
      sortType: 'basic',
      Cell: (cell: { row :{original: AdvertisingPlanDomain, index:number}}) => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < cell.row.index; i++) {
          if (mediaMasterList[i].orgCode === cell.row.original.orgCode) {
            return ('');
          }
        }
        return (cell.row.original.orgName);
      },
    },
    {
      Header: '広告プランコード',
      accessor: 'advertisingPlanMstCode',
    },
    {
      Header: '広告プラン',
      accessor: 'advertisingPlanMstName',
    },
    {
      Header: '適用開始日',
      accessor: 'applyStartDay',
    },
    {
      Header: '適用終了日',
      accessor: 'applyEndDay',
    },
    {
      Header: () => null,
      id: 'actionButton',
      accessor: '',
      disableSortBy: true,
      Cell: (cell: { row :{
        isExpanded: boolean,
        original: AdvertisingPlanDomain,
        index:number } }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < cell.row.index; i++) {
          if (mediaMasterList[i].orgCode === cell.row.original.orgCode) {
            return (
              <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                {checkUserRole !== null && checkUserRole.editable === 1 && (
                <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                  <span
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                    onMouseEnter={() => setIsHoverDelete(true)}
                    onMouseLeave={() => setIsHoverDelete(false)}
                  >
                    <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </Link>
                )}
              </div>
            );
          }
        }
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/salesAdvertisingPlan/advertisingOrganization/edit/${cell.row.original.orgCode}/${checked}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            {checkUserRole !== null && checkUserRole.editable === 1 && (
              <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                <span
                  style={{ marginLeft: '20px', marginRight: '10px' }}
                  onMouseEnter={() => setIsHoverDelete(true)}
                  onMouseLeave={() => setIsHoverDelete(false)}
                >
                  <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                </span>
              </Link>
            )}
          </div>
        );
      },
    },
  ], [checkUserRole,
    checked,
    dateTo,
    listChecked,
    mediaMasterList,
    openConfirmModal,
    setEndDate,
    setListChecked]);

  const sortBy = React.useMemo(
    () => [
      {
        id: 'orgName',
        desc: false,
      },
    ],
    [],
  );

  return (
    <BlockUI blocking={isLoading}>
      <OrganizationFilteredTree
        functionType={functionType}
        orgCallback={(args: string | Array<string>) => {
          setOrgCode(String(args));
        }}
        orgCategoryCallback={
          (args: Array<string>) => {
            setCategoryAr(args);
          }
        }
        isAlignItemsCenter={true}
        initOrgValue={orgCode}
        staffCode={staffCode}
        orgLabel="店舗名"
        addAllItem={true}
      />

      <FlexBox>
        <FlexBoxItem width="100px">
          <FormLabel
            label="適用開始日"
          />
        </FlexBoxItem>
        <FlexBoxItem>
          <DatePickerForm
            dateFormat="yyyy年MM月dd日"
            label=""
            date={targetDateFrom || null}
            changeDate={(dateStart: any) => {
              if (dateStart !== null) {
                setTargetDateFrom(dateStart);
              }
            }}
            isFullWidth={true}
          />
        </FlexBoxItem>
        <FlexBoxItem>
          <span style={{ margin: '0 20px' }}>~</span>
        </FlexBoxItem>
        <FlexBoxItem>
          <DatePickerForm
            dateFormat="yyyy年MM月dd日"
            label=""
            date={targetDateTo || null}
            changeDate={(dateEnd: any) => {
              if (dateEnd !== null) {
                setTargetDateTo(dateEnd);
              }
            }}
            isFullWidth={true}
          />
        </FlexBoxItem>
        <FlexBoxItem marginLeft="20px">
          <div style={{ marginTop: '1.5rem' }}>
            <MultipleCheckboxForm
              name="stateStatus"
              label=""
              items={[
                {
                  id: 'notSubjectToAudit',
                  label: '適用終了を除く',
                  value: formik.values.notSubjectToAudit,
                },
              ]}
              setValue={(e) => {
                formik.setFieldValue(String(e.target.id), !(e.target.value === 'true'));
                setChecked(!(e.target.value === 'true'));
              }}
            />
          </div>
        </FlexBoxItem>
      </FlexBox>

      <FlexBox>
        {checkUserRole !== null && checkUserRole.downloadFlag === 1 && (
          <FlexBoxItem>
            <IconLabelButton
              onClick={downloadFileCSV}
              iconType="download"
              text="CSVダウンロード"
            />
          </FlexBoxItem>
        )}
        <FlexBoxItem grow={1}>
          <div style={{ textAlign: 'right' }}>
            {checkUserRole !== null && checkUserRole.importFlag === 1 && (
            <Link to="/salesAdvertisingPlan/advertisingOrganization/import" style={{ marginRight: 10 }}>
              <PrimaryButton
                ghost={false}
                text="インポート"
              />
            </Link>
            )}
            {checkUserRole !== null && checkUserRole.editable === 1 && (
              <Link to="/salesAdvertisingPlan/advertisingOrganization/add">
                <PrimaryButton
                  ghost={false}
                  text="新規組織設定"
                />
              </Link>
            )}
          </div>
        </FlexBoxItem>
      </FlexBox>
      <FlexBox
        customStyle={css`
            table th:nth-child(1) {
              width: 100px;
            }           
          `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <DataTable
            columns={columns}
            data={mediaMasterList}
            isGlobalFilter={true}
            sortBy={sortBy}
          />
        </div>
      </FlexBox>
      <div style={{ marginTop: 20 }} />
      <FlexBox>
        <FlexBoxItem width="100px">
          <FormLabel
            label="適用終了日"
          />
        </FlexBoxItem>
        <FlexBoxItem>
          <DatePickerForm
            dateFormat="yyyy年MM月dd日"
            label=""
            date={endDate || null}
            changeDate={(dateEnd: any) => {
              if (dateEnd !== null) {
                setEndDate(dateEnd);
              }
            }}
            disable={listChecked.length === 0}
            isFullWidth={true}
            isShowFullWidthDatePicker={true}
          />
        </FlexBoxItem>
        {checkUserRole !== null && checkUserRole.editable === 1 && (
        <FlexBoxItem marginLeft="20px">
          <PrimaryButton
            ghost={false}
            text="適用終了日一括設定"
            onClick={() => setConfirmModalUpdate(true)}
            disabled={listChecked.length === 0}
          />
        </FlexBoxItem>
        )}
        {checkUserRole !== null && checkUserRole.editable === 1 && (
          <FlexBoxItem grow={1} textAlign="right">
            <SecondaryButton
              text="一括削除"
              onClick={() => setConfirmModalDelete(true)}
              disabled={listChecked.length === 0}
            />
          </FlexBoxItem>
        )}
      </FlexBox>
      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={deleteAdvertisingPlan}
        title="確認メッセージ"
        content="削除します。よろしいですか？"
        submitText="削除"
        items={[]}
      />
      <ConfirmModal
        open={confirmModalUpdate}
        closeHandler={closeConfirmModal}
        onSubmit={() => submitUpdate(ActionType.UPDATE)}
        title="確認メッセージ"
        content="適用終了日を一括更新します。よろしいですか？"
        submitText="更新"
        items={[]}
      />
      <ConfirmModal
        open={confirmModalDelet}
        closeHandler={closeConfirmModal}
        onSubmit={() => submitUpdate(ActionType.DELETE)}
        title="確認メッセージ"
        content="一括削除します。よろしいですか？ 削除すると元に戻すことはできません。"
        submitText="削除"
        items={[]}
      />
    </BlockUI>
  );
};

export default StoreSettingsPage;
