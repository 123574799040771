/** @jsx jsx */
import React, { useState, useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { css, jsx } from '@emotion/core';
import LableTitle from 'components/atoms/LableTitle';
import PageTitle from 'components/atoms/PageTitle';
import PrimaryButton from 'components/atoms/Button';
import DataTable from 'components/organismos/DataTable/DataTable';
import SpecialDateMasterDomain from 'domain/master/labor/specialDateMaster';
import { deleteById } from 'api/specialDateMaster';
import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import useToastNotification from 'hooks/useToastNotification';
import { useSpecialDateMaster } from './hooks';

const SpecialDateMasterPage: React.FC<{
  roleScreen?: any
}> = ({ roleScreen }) => {
  const { specialList, setSpecialList } = useSpecialDateMaster();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const [selectedSpecialMaster, setSelectedSpecialMaster] = useState(
    SpecialDateMasterDomain.generateInitial(),
  );

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((special : SpecialDateMasterDomain) => {
    setConfirmModalOpen(true);
    setSelectedSpecialMaster(special);
  }, []);

  const deleteSpecialMaster = useCallback(
    () => {
      const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
      deleteById(companyCode, selectedSpecialMaster.specialSalaryPatternId).then((response:any) => {
        setSpecialList(
          specialList.filter(
            (special) => special.specialSalaryPatternId
            !== selectedSpecialMaster.specialSalaryPatternId,
          ),
        );
        setConfirmModalOpen(false);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = '';
          error.response.data.errors.map((item: { defaultMessage: string; }) => {
            msgError += `${item.defaultMessage} <br>`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    },
    [errorNotification, selectedSpecialMaster.specialSalaryPatternId,
      setSpecialList, specialList, successNotification],
  );

  const columns: Array<Column<SpecialDateMasterDomain>> = useMemo(() => [
    {
      Header: 'コード',
      accessor: 'specialSalaryPatternCode',
      sortType: 'basic'
    },
    {
      Header: 'パターン名',
      accessor: 'specialSalaryPatternName',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell:{row:{isExpanded: boolean, original:SpecialDateMasterDomain},
        data: Array<SpecialDateMasterDomain>}) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/masterSpecialSalaryPattern/edit/${cell.row.original.specialSalaryPatternId}/0`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            {
              roleScreen && roleScreen.editable === 1 && (
              <React.Fragment>
                <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                  <span
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                    onMouseEnter={() => setIsHoverDelete(true)}
                    onMouseLeave={() => setIsHoverDelete(false)}
                  >
                    <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </Link>
              </React.Fragment>
            )}
          </div>
        );
      },
    },

  ], [openConfirmModal, roleScreen]);

  return (
    <React.Fragment>
      <div>
        <LableTitle title=" 特賃日の曜日・日付に対して、時給を加算して人件費を計算できるようになります。給与ソフト用のCSVに特定の曜日・日付に労働した時間・日数を出力できます。" />
        {
          roleScreen && roleScreen.editable === 1 && (
          <PageTitle
            title=""
            rightContents={(
              <Link to="/masterSpecialSalaryPattern/add">
                <PrimaryButton
                  ghost={false}
                  text="新規特賃日作成"
                />
              </Link>
            )}
          />
        )}
        <div
          css={css`
            table th:nth-child(1) {
              width: 100px;
            }
            table th:nth-child(2) {
              width: 200px;
            }
            table th:nth-child(3) {
              width: 80px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTable 
            columns={columns} 
            data={specialList} 
            sortBy={[
              { id: 'specialSalaryPatternCode', desc: false }
              ]}
            />
          </div>
        </div>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={deleteSpecialMaster}
          actionType={ActionType.DELETE}
        />
      </div>
    </React.Fragment>
  );
};

export default SpecialDateMasterPage;
