import React from 'react';
import Calendar from 'components/molecules/Calendar';
import { ShiftMonthCalendarEvent } from 'components/pages/master/attend/SchedulePage/hooks';
import { ScheduleStatus } from 'api/schedule';
import EventWrapper from './EventWrapper';
import MonthEvent from './MonthEvent';
import MonthDateHeader from './MonthDateHeader';


const ScheduleMonthCalendar: React.FC<{
  events: ShiftMonthCalendarEvent[],
  date: Date;
  datePickerValue: Date;
  setDate: (date: Date) => void;
  setDatePickerValue: (date: Date) => void;
}> = ({
  date, datePickerValue, setDate, setDatePickerValue, events,
}) => {
  // Events that dont have event can't edit & ready to submit
  const configEvents = events.filter((item) => !(item.hopeShift?.isEditable === false && item.hopeShift.fixStatus === ScheduleStatus.UNSUBMITED));

  const newEvents = configEvents.map(
    (item) => ({
      ...item,
      start: item.start,
      // end: item.end,
      // eslint-disable-next-line no-nested-ternary
      end: (item.start && item.end)
        ? (item.start!.getDate() !== item.end!.getDate()
          ? new Date(item.start!.getFullYear(),
                  item.start!.getMonth(),
                  item.start!.getDate(), 23, 30, 0, 0)
          : item.end) : undefined,
      // eslint-disable-next-line no-nested-ternary
      title: item.title,
    }),
  );
  return (
    <>
      <Calendar
        date={datePickerValue}
        setDate={setDatePickerValue}
        events={newEvents}
        views={['month']}
        onDrillDown={(selectDate) => {
          setDate(selectDate);
        }}
        components={{
          month: {
            event: MonthEvent,
            dateHeader: MonthDateHeader,
          },
          toolbar: () => <></>,
          eventWrapper: EventWrapper,
        }}
        messages={{
          showMore: (count: number) => `他${count}件`,
        }}
      />
    </>
  );
};
export default ScheduleMonthCalendar;
