/** @jsx jsx */
/* eslint-disable max-len */
import React, { useMemo } from 'react';
import SelectForm from 'components/molecules/SelectForm';
import AttendCSVOutputDomain from 'domain/master/attend/attendCSVOutput';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import { useEmploymentTypeOptions, useStaffOptions } from 'hooks/useEmploymentOptions';
import FormContents from 'components/atoms/Form/FormContents';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import { css, jsx } from '@emotion/core';
import Button, { SecondaryButton } from 'components/atoms/Button';
import FormField from 'components/atoms/Form/FormField';
import ConfirmModal from 'components/organismos/ConfirmModal';
import FlexBox from 'components/atoms/FlexBox';
import FormLabel from 'components/atoms/Form/FormLabel';
import DatePicker from 'components/molecules/DatePicker';
import { OptionType } from 'components/atoms/Select';
import BlockUI from 'components/molecules/BlockUi';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import moment from 'moment';
import useAttendCSVOutput from './hooks';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { Link } from 'react-router-dom';
import Icon from 'components/atoms/Icon';
import { grayScale, iconColor, productColor } from 'components/styles';
import { PulseLoader } from 'react-spinners';
import TextTooltip from '../../general/salesPage/salesAdvertisingMonthlyReport/component/TextTooltip';
import TagView from './component/TagView';

// create style form
const styles = {
  form: css({
    paddingTop: '15px',
  }),
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
    minWidth: '100px',
    paddingLeft: '20px',
  }),
  digestionDays: css({
    width: '120px',
  }),
  betweenDays: css({
    width: '50px',
    textAlign: 'center',
  }),
  customLink: css({
    textDecoration: 'none',
    'span': {
      color: productColor.primary,
    }
  }),
  customButtonCancel: css({
    fontSize: '12px',
    paddingLeft: '8px',
    paddingRight: '8px',
    lineHeight: '25px',
  }),
  customTable: css({
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    'table': {
      borderSpacing: 0,
      'tr': {
        ':last-child': {
          'td': {
            borderBottom: 0,
          }
        },
        'th': {
          'div': {
            justifyContent: 'center',
          }
        },
        'th, td': {
          margin: 0,
          textAlign: 'center',
          justifyContent: 'center',
          borderBottom: `1px solid ${grayScale.gray10}`,
          borderRight: `1px solid ${grayScale.gray10}`,

          ':last-child': {
            borderRight: 0
          }
        }
      }
    }
  }),
  customTag: css({
    marginLeft: '10px'
  })
};

const AttendCSVOutputLayout: React.FC = () => {
  const {
    formik,
    layoutIdOptions,
    attendCSVOutput,
    setAttendCSVOutput,
    isLoading,
    setLoading,
    checkTargetRange,
    categoryArr,
    setCategoryArr,
    achivementHistoryId,
    setAchivementHistoryId,
    confirmModalOpen,
    handleOpenConfirmModal,
    handleCloseConfirmModal,
    handleDownloadHistoryById,
    handleReloadHistoryDownloadById,
    handleCancelHistoryDownloadById,
    handleDeleteHistoryDownloadById,
  } = useAttendCSVOutput();

  let employmentOptions: Array<OptionType> = [];
  let staffOptions: Array<OptionType> = [];
  const functionType = 2;

  const checkTarget = checkTargetRange(formik.values.targetDateFrom, formik.values.targetDateTo, true);
  if (checkTarget) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    employmentOptions = useEmploymentTypeOptions(formik.values.targetDateFrom, formik.values.targetDateTo);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    staffOptions = useStaffOptions(
      formik.values.orgCode || 'all',
      formik.values.targetDateFrom,
      formik.values.targetDateTo,
      functionType,
      categoryArr,
      formik.values.employmentId
    );
  }

  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  // Set default selected org
  useMemo(() => {
    // setorgCode(sessionStorage.getItem('selectedOrgCode')!);
    formik.setFieldValue('orgCode', '');

    // setEmploymentId(sessionStorage.getItem('selectedStaffCode')!);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: Array<Column<AttendCSVOutputDomain>> = useMemo(() => [
    {
      Header: '出力レイアウト',
      accessor: 'layoutName',
      Cell: (cell: { row: { isExpanded: boolean; original: any } }) => {
        const { layoutName } = cell.row.original;
        return (
          <React.Fragment>
            <TextTooltip
              id={`layoutName${layoutName}`}
              text={layoutName}
            />
          </React.Fragment>
        )
      }
    },
    {
      Header: '対象期間',
      id: 'period',
      accessor: r => r.periodFrom ? `${moment(r.periodFrom).format("YYYY/MM")} 〜 ${moment(r.periodTo).format("YYYY/MM")}` : "-",
      sortType: 'basic'
    },
    {
      Header: '雇用形態',
      accessor: 'employmentName'
    },
    {
      Header: '組織名',
      accessor: 'orgName'
    },
    {
      Header: '組織カテゴリ',
      accessor: 'organizationCategories',
      disableSortBy: true,
      Cell: (cell: { row: { isExpanded: boolean; original: any } }) => {
        const { organizationCategories, attendAchievementDownloadId } = cell.row.original;
        return (
          <React.Fragment>
            {organizationCategories?.length > 0 ?
              <TagView
                customStyle={styles.customTag}
                list={organizationCategories}
                id={`organizationCategories${attendAchievementDownloadId}`}
              /> : <p>-</p>
            }
          </React.Fragment>
        )
      }
    },
    {
      Header: 'スタッフ',
      accessor: 'staffName',
      Cell: (cell: { row: { isExpanded: boolean; original: any } }) => {
        const { staffName } = cell.row.original;
        return (
          <React.Fragment>
            {staffName?.length > 1 ?
              <TextTooltip
              customCss={css({
                ':last-child': {
                  marginTop: '-7px'
                }
              })}
              id={`staffName${staffName.split(" ")[0]}`}
              text={staffName}
              />
          : <p>-</p>}
          </React.Fragment>
        )
      }
    },
    {
      Header: '作成日時',
      accessor: 'createDate',
      Cell: (cell: { row: { isExpanded: boolean; original: any } }) => {
        const createDate = cell.row.original.createDate?.split(" ");
        return (
          <React.Fragment>
            <div style={{ lineHeight: '7px' }}>
              {createDate?.length > 1 ? createDate.map((item:any)=>{
                return (
                  <p>{item}</p>
                )}) : <p>-</p>
              }
            </div>
          </React.Fragment>
        )
      }
    },
    {
      Header: 'ステータス',
      accessor: 'statusDownload',
      Cell: (cell: { row: { isExpanded: boolean, original: any } }) => {
        const { statusDownload, attendAchievementDownloadId, file, rawData } = cell.row.original;
        return (
          <React.Fragment>
            {statusDownload === 0 && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <PulseLoader
                  size={"8px"}
                  color={productColor.primary}
                  loading={true}
                />
                  <span style={{ color: productColor.primary }}>作成中</span>
              </div>
            )}
            {statusDownload === 1 &&
              <Link to="#"
                css={styles.customLink}
                onClick={() => {
                  handleDownloadHistoryById(attendAchievementDownloadId, file);
                }}
              >
                <span>ダウンロード</span>
              </Link>}
            {statusDownload === 2 && (
              <IconLabelButton
                iconType="reload"
                text=" 失敗"
                onClick={() => {handleReloadHistoryDownloadById(rawData)}}
                className="templateIcon"
              />
            )}
          </React.Fragment>
        );
      },
    },
    {
      Header: () => null,
      accessor: '',
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row: { isExpanded: boolean, original: AttendCSVOutputDomain } }) => {
        const { statusDownload, attendAchievementDownloadId } = cell.row.original;
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'center', padding: '10px 0 5px 0' }}>
            {(statusDownload === 1 || statusDownload === 2) && (
              <Link to="#"
                onClick={() => {
                  handleOpenConfirmModal();
                  setAchivementHistoryId(attendAchievementDownloadId);
                }}
              >
                <span style={{ marginLeft: '20px', marginRight: '10px' }}>
                  <Icon type="delete" color={iconColor.gray} />
                </span>
              </Link>
            )}
             {statusDownload === 0 && (
                <SecondaryButton
                  text="キャンセル"
                  onClick={() => {
                    handleCancelHistoryDownloadById(attendAchievementDownloadId)
                  }}
                  customStyle={styles.customButtonCancel}
                />
              )}
          </div>
        );
      },
    },
    {
      Header: '',
      accessor: 'organizationCategoryMstHeadName',
      show: false
    },
    {
      Header: '',
      accessor: 'organizationCategoryMstName',
      show: false
    },
    {
      Header: '',
      accessor: 'statusDownloadName',
      show: false
    }
  ], []);

  return (
    <div>
      <BlockUI blocking={isLoading}>
        <FormContents>
          <form onSubmit={formik.handleSubmit}>
            <FormField displayBlock={true}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel
                    label="対象期間"
                  />
                </div>
                <div css={styles.digestionDays}>
                  <DatePicker
                    dateFormat="yyyy年MM月"
                    date={formik.values.targetDateFrom}
                    changeDate={(date: Date) => {
                      if (date !== null) {
                        formik.setFieldValue('targetDateFrom', date || new Date());
                        checkTargetRange(date || new Date(), formik.values.targetDateTo);
                      }
                    }}
                    showMonthYearPicker={true}
                    isFullWidth={true}
                  />
                </div>
                <div css={styles.betweenDays}>
                  ～
                </div>
                <div css={styles.digestionDays}>
                  <DatePicker
                    dateFormat="yyyy年MM月"
                    date={formik.values.targetDateTo}
                    changeDate={(date: Date) => {
                      if (date !== null) {
                        formik.setFieldValue('targetDateTo', date || new Date());
                        checkTargetRange(formik.values.targetDateFrom, date || new Date());
                      }
                    }}
                    showMonthYearPicker={true}
                    isFullWidth={true}
                  />
                </div>
              </FlexBox>
            </FormField>

            <OrganizationFilteredTree
              functionType={functionType}
              orgCallback={(args: string | Array<string>) => {
                sessionStorage.setItem('selectedOrgCode', String(args));
                formik.setFieldValue('orgCode', String(args));
              }}
              orgCategoryCallback={
                (args: Array<string>) => {
                  setCategoryArr(args);
                }
              }
              targetdayTo={moment(new Date(formik.values.targetDateTo.getFullYear(), formik.values.targetDateTo.getMonth() + 1, 0, 23, 59, 59)).toDate()}
              targetdayForm={moment(new Date(formik.values.targetDateFrom.getFullYear(), formik.values.targetDateFrom.getMonth(), 1)).toDate()}
              initOrgValue={String(formik.values.orgCode)}
              staffCode={staffCode}
              orgLabel="組織名"
              addAllItem={true}
            />

            <FormField displayBlock={true}>
              <SelectForm
                label="雇用形態"
                name="employmentId"
                value={formik.values.employmentId}
                setValue={(v) => {
                  formik.setFieldValue('employmentId', v);
                }}
                options={employmentOptions}
              />
            </FormField>

            <FormField displayBlock={true}>
              <SelectForm
                label="スタッフ"
                name="staffCode"
                value={formik.values.staffCode || ''}
                setValue={(v) => {
                  formik.setFieldValue('staffCode', v);
                }}
                options={staffOptions}
              />
            </FormField>

            <FormField displayBlock={true}>
              <SelectForm
                label="出力レイアウト"
                name=""
                value={formik.values.layoutId || ''}
                setValue={(v) => {
                  formik.setFieldValue('layoutId', v);
                }}
                options={layoutIdOptions}
              />
            </FormField>

            <FormField justifyContent='center'>
              <FormSubmitArea>
                <Button
                  text="出力"
                  onClick={formik.handleSubmit}
                />
              </FormSubmitArea>
            </FormField>
          </form>

          <div
            css={css`
              table th:nth-child(1) {
                width: 120px;
              }
              table th:nth-child(2) {
                width: 135px;
              }
              table th:nth-child(3) {
                width: 110px;
              }
              table th:nth-child(4) {
                width: 210px;
              }
              table th:nth-child(5) {
                width: 230px;
              }
              table th:nth-child(6) {
                width: 150px;
              }
              table th:nth-child(7) {
                width: 120px;
              }
              table th:nth-child(8) {
                width: 90px;
              }
              table th:nth-child(9) {
                width: 70px;
              }
            `}
          >

            <div css={styles.customTable}>
              <DataTable
                columns={columns}
                data={attendCSVOutput}
                isGlobalFilter={true}
                minWidth="972px"
                useSession={true}
                sortBy={[
                  { id: 'createDate', desc: true }
                ]}
              />
            </div>
          </div>
        </FormContents>
      </BlockUI>
       <ConfirmModal
        open={confirmModalOpen}
        closeHandler={handleCloseConfirmModal}
        title="確認メッセージ"
        content="削除します。よろしいですか？"
        submitText="削除"
        onSubmit={() => handleDeleteHistoryDownloadById(achivementHistoryId)}
        items={[]}
      />
    </div>
  );
};

export default AttendCSVOutputLayout;
