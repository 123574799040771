/** @jsx jsx */

import { css, jsx } from '@emotion/core';
import BodyText from 'components/atoms/BodyText';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import Select from 'components/atoms/Select';
import { useOrganizationTrees } from 'hooks/useOrgTreesOptions';
import moment from 'moment';
import React, { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { OrgStatusType, Values } from 'utility/constants';


const OrganizationSelectForm: React.FC<{
  values: Array<string>;
  setValues: (arg: Array<any>) => void;
  functionType: number;
  label: string;
  isVertical?: boolean;
  isMulti?: boolean;
  categoryAry?: Array<any>;
  addAllItem?: boolean;
  getOptionsList?: (args: any) => void,
  orgOrgOptionListCallback?: (args: any) => void,
  placeholder?: string;
  required?: boolean;
  targetDayFrom?: Date;
  targetDayTo?: Date;
  notSetLoginStaffCode?: boolean;
  disabled?:boolean
  orgStatus?: OrgStatusType;
  setOrgStatus?: (arg: OrgStatusType) => void;
  isShowSituation?:boolean
}> = ({
  values = [],
  setValues,
  functionType,
  label,
  isVertical = false,
  isMulti = false,
  categoryAry,
  addAllItem = false,
  getOptionsList,
  orgOrgOptionListCallback,
  placeholder,
  required,
  targetDayFrom,
  targetDayTo,
  notSetLoginStaffCode = false,
  disabled = false,
  orgStatus,
  setOrgStatus,
  isShowSituation,
}) => {
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [org, setOrg] = React.useState<Array<any>>([]);
  const opt = useOrganizationTrees(
    functionType,
    targetDayFrom === undefined ? undefined : moment(targetDayFrom).format('YYYY-MM-DD'),
    targetDayTo === undefined ? undefined : moment(targetDayTo).format('YYYY-MM-DD'),
    notSetLoginStaffCode ? '' : staffCode,
    addAllItem,
    categoryAry,
    orgStatus
  );

  useEffect(() => {
    setOrg(opt);
    if (getOptionsList) {
      getOptionsList(opt);
    }
    if (orgOrgOptionListCallback) {
      orgOrgOptionListCallback(opt);
    }
  }, [getOptionsList, opt, org, orgOrgOptionListCallback, values]);

  const style = {
    selectbox: css({
      width: isMobileOnly ? '100%' : '25rem',
    }),
    label: css({
      paddingBottom: '8px',
    }),
    selectboxOrgStatus: css({
      width: '8rem',
      marginRight: '16px',
    }),
    labelOrgStatus: css({
      width: '4rem',
    }),
  };
  useEffect(() => {
    const url = window.location.href;
    if (url.search('salesAdvertisingPlan/advertisingOrganization') > 0) {
      localStorage.setItem('categoryArray', '[]');
      localStorage.setItem('categoryArray', JSON.stringify(categoryAry));
    }
  }, [categoryAry]);
  return (
    <div>
      {isMobileOnly
        ? (
          org && (
          <div>
            <FlexBox>
              {label && (
              <FlexBoxItem customStyle={style.label} basis={isMobileOnly ? '80px' : 'auto'}>
                <FormLabel label={label} required={required} />
              </FlexBoxItem>
              )}
              <FlexBoxItem customStyle={style.selectbox}>
                <Select
                  name="orgSelect"
                  value={String(values)}
                  values={values}
                  setValues={(arg: Array<string>) => {
                    setValues(arg);
                  }}
                  setValue={(arg: string) => {
                    setValues([arg]);
                  }}
                  options={org}
                  isMulti={isMulti}
                  placeholder={placeholder}
                />
              </FlexBoxItem>
            </FlexBox>
          </div>
          )
        )
        : (
          <div>
            {org && (
            <FlexBox
              direction={!isVertical ? 'row' : 'column'}
              alignItems={!isVertical ? 'center' : 'flex-start'}
              customStyle={css({ width: '100%' })}
            >
              {isShowSituation && setOrgStatus && (
                <React.Fragment>
                  <FlexBoxItem customStyle={style.labelOrgStatus}>
                    <BodyText>状態</BodyText>
                  </FlexBoxItem>
                  <FlexBoxItem customStyle={style.selectboxOrgStatus}>
                    <Select
                      name="orgState"
                      value={orgStatus || Values.OrgStatus.Open.value}
                      setValue={setOrgStatus as (arg: string) => void}
                      options={Object.values(Values.OrgStatus)}
                    />
                  </FlexBoxItem>
                </React.Fragment>
              )}
              {label && (
              <FlexBoxItem minWidth={!isVertical ? '5.2rem' : 'auto'} marginRight="1rem">
                <FormLabel label={label} required={required} />
              </FlexBoxItem>
              )}
              <FlexBoxItem customStyle={style.selectbox}>
                <Select
                  name="orgSelect"
                  value={String(values)}
                  values={values}
                  setValues={(arg: Array<string>) => {
                    setValues(arg);
                  }}
                  setValue={(arg: string) => {
                    setValues([arg]);
                  }}
                  options={org}
                  isMulti={isMulti}
                  placeholder={placeholder}
                  isDisabled={disabled}
                />
              </FlexBoxItem>
            </FlexBox>
            )}
          </div>
        )}

    </div>
  );
};
export default OrganizationSelectForm;
