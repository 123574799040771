import React from 'react';
import TextForm from 'components/molecules/TextForm';

export type GlobalFilterProps = {
  state: any;
  setGlobalFilter: any;
  tableId?: number;
}


const GlobalFilter: React.FC<GlobalFilterProps> = ({ state, setGlobalFilter, tableId }) => (
  <TextForm
    name="globalFilter"
    label=""
    value={state.globalFilter || ''}
    onChange={(e) => {
      setGlobalFilter(e.target.value || undefined);
      sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.globalFilterTxt`, e.target.value);
    }}
  />
);
export default GlobalFilter;
