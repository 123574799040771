import React from 'react';
import moment from 'moment';

const PaidHolidayGranTable: React.FC<{
paidHolidayGrantData: any
}> = ({
  paidHolidayGrantData,
}) => (
  <div className="table-responsive">
    <table id="paidHolidayGrantList" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%' }}>
      <thead>
        <tr role="row">
          <th style={{ width: '12%' }}>前年繰越数</th>
          <th style={{ width: '12%' }}>当年付与数</th>
          <th style={{ width: '12%' }}>消化数</th>
          <th style={{ width: '12%' }}>残有給数</th>
          <th style={{ width: '16%' }}>次回付与日</th>
          <th style={{ width: '12%' }}>次回付与予定日数</th>
          <th style={{ width: '12%' }}>次回繰越日数</th>
          <th style={{ width: '12%' }}>次回消滅日数</th>
        </tr>
      </thead>
      <tbody>
        {
        paidHolidayGrantData != null && (
        <tr role="row">
          <td>
            {paidHolidayGrantData.lastGrantDaysNumSum && paidHolidayGrantData.lastGrantDaysNumSum.toFixed(1)}
            日
            {paidHolidayGrantData.lastGrantTimesNumSum}
            時間
          </td>
          <td>
            {paidHolidayGrantData.grantDaysNumSum && paidHolidayGrantData.grantDaysNumSum.toFixed(1)}
            日
            {paidHolidayGrantData.grantTimesNumSum}
            時間
          </td>
          <td>
            {paidHolidayGrantData.digestedDaysNum && paidHolidayGrantData.digestedDaysNum.toFixed(1)}
            日
            {paidHolidayGrantData.digestedTimesNum}
            時間
          </td>
          <td>
            {paidHolidayGrantData.remainDaysNum && paidHolidayGrantData.remainDaysNum.toFixed(1)}
            日
            {paidHolidayGrantData.remainTimesNum}
            時間
          </td>
          <td>{paidHolidayGrantData.nextGrantDate == null ? '' : moment(paidHolidayGrantData.nextGrantDate).format('YYYY年MM月DD日')}</td>
          <td>
            {paidHolidayGrantData.nextGrantDate == null ? '' : paidHolidayGrantData.nextGrantDays}
            日
          </td>
          <td>
            {paidHolidayGrantData.nextGrantDate == null ? '' : paidHolidayGrantData.nextCarryOverDaysNum.toFixed(1)}
            日
            {paidHolidayGrantData.nextCarryOverTimesNum}
            時間
          </td>
          <td>
            {paidHolidayGrantData.nextGrantDate == null ? '' : paidHolidayGrantData.nextExtinctionDaysNum.toFixed(1)}
            日
            {paidHolidayGrantData.nextExtinctionTimesNum}
            時間
          </td>
        </tr>
        )
      }
      </tbody>
    </table>
  </div>
);
export default PaidHolidayGranTable;
