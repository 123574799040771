import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

export const getDescriptionMstList = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/descriptionMst/list/${companyCode}`, {});
  return response.data;
};

export const deleteDesCriptionMstList = async (descriptionId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    descriptionId,
  };
  const response = await ApiClient.delete(`/v2/descriptionMst/delete/${companyCode}`, params);
  return response.data;
};

export const downloadCSV = async (fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsv(`/v2/descriptionMst/outputCsv/${companyCode}`, {}, getDownloadCsvFileName(`${fileName}_`));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;


export const getjournalMstSelect = async ():Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/journalMst/select/${companyCode}`, {});
  return response.data;
};


export const getClassificationMstSelect = async ():Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/classificationMst/list/${companyCode}`, {});
  return response.data;
};

export const saveDescriptionMSTDetail = async (descriptionId: string, data: any):Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params: any = {
    companyCode,
    descriptionId: '',
    descriptionCode: data.descriptionCode,
    descriptionName: data.descriptionName,
    createUser: staffCode,
    updateUser: staffCode,
    journalId: data.journalId,
    classificationId: data.classificationId,
  };
  if (descriptionId && descriptionId !== 'undefined') {
    params.descriptionId = descriptionId;
  }
  const response = await ApiClient.post(`/v2/descriptionMst/save//${companyCode}`, {}, params);
  return response.data;
};


export const getDescriptionMstDetail = async (descriptionId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    descriptionId,
  };
  const response = await ApiClient.get(`/v2/descriptionMst/${companyCode}`, params);
  return response.data;
};
export type Error = {
  error: string;
  defaultMessage: string;
}

export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v2/descriptionMst/csv/import/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};


export default { };
