/** @jsx jsx */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import { Container, Row, Col } from 'react-grid-system';
import FormContents from 'components/atoms/Form/FormContents';
import BodyText from 'components/atoms/BodyText';
import moment from 'moment';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import BlockUI from 'components/molecules/BlockUi';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import { css, jsx } from '@emotion/core';
import ToastModal from 'components/organismos/ToastModal';
import ConfirmModal from 'components/organismos/ConfirmModal';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import { PrimaryButton } from 'components/atoms/Button';
import productColor, { textFontSize, textColor } from 'components/styles';
import Checkbox from 'components/molecules/Checkbox';
import { isMobileOnly } from 'react-device-detect';
import useHolidayApplicationForm from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const styles = {
  hideByHolidayType1: (holidayType: number) => css({
    display: holidayType === 1 ? '' : 'none',
  }),
  hideByHolidayType4: (holidayType: number) => css({
    display: holidayType === 4 ? '' : 'none',
  }),
  hideByHolidayType5: (holidayType: number) => css({
    display: holidayType === 5 ? '' : 'none',
  }),
  hideByHolidayType6: (holidayType: number) => css({
    display: holidayType === 6 ? '' : 'none',
  }),
  hideWithType2: (holidayType: number, autograntholiday: boolean) => css({
    display: holidayType === 2 && autograntholiday ? '' : 'none',
  }),
  hideWithType2NotAuto: (holidayType: number, autograntholiday: boolean) => css({
    display: holidayType === 2 && !autograntholiday ? '' : 'none',
  }),
  headerTitle: css({
    padding: '4px 16px',
    margin: '10px 0 0 0',
    backgroundColor: productColor.primaryM95,
    fontWeight: 'normal',
    textAlign: 'center',
    fontSize: textFontSize.re,
    borderTop: `1px solid ${productColor.primaryM80}`,
    borderBottom: `1px solid ${productColor.primaryM80}`,
    color: textColor.main,
  }),
};

enum ActionType {
  Add = 0,
  Modify = 1,
  Delete = 2,
}

const HolidayApplicationPage: React.FC = () => {
  const history = useHistory();
  const {
    isLoading,
    formik, targetDate, weekDay, staffName, holidayOptions,
    holidayUnitOptions, hourFormOptions,
    timeFormOptions,
    toastModalOpen,
    toastMessage,
    closeConfirmModal,
    confirmModalOpen,
    setToastModalOpen,
    currentApplid,
    selectedHoliday,
    holidays,
    setSelectedHoliday,
    substituteOptions,
  } = useHolidayApplicationForm();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeHoliday = (holidayId: string) => {
    formik.setFieldValue('holidayId', holidayId);
    const tmpSelectedHoliday: any = holidays.filter((holiday: any) => holiday.value === holidayId);
    if (tmpSelectedHoliday[0]) {
      setSelectedHoliday(tmpSelectedHoliday[0]);
    }
  };
  const handleChangeHolidayUnit = (holidayUnit: string) => {
    formik.setFieldValue('holidayUnit', holidayUnit);
  };

  useEffect(() => {
    if (currentApplid.holidayId !== null) {
      handleChangeHoliday(currentApplid.holidayId);
    } else {
      handleChangeHoliday(selectedHoliday.value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApplid]);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <SidebarTemplate pageTitle="休暇申請">
      <BlockUI blocking={isLoading}>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <Container fluid={true}>
              <FormContents>
                <Row>
                  <Col md={2} xs={12}>
                    <BodyText>対象日</BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <BodyText>
                      {moment(`${targetDate}`, 'YYYY-MM-DD').format(`YYYY年MM月DD日(${weekDay[new Date(`${targetDate}`).getDay()]})`)}
                    </BodyText>
                  </Col>
                </Row>
                <Row>
                  <Col md={2} xs={12}>
                    <BodyText>スタッフ</BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <BodyText>
                      {staffName}
                    </BodyText>
                  </Col>
                </Row>
                {
                !isMobileOnly
                  ? (
                    <React.Fragment>
                      <Row css={css({ textAlign: 'center' }, styles.headerTitle)}>
                        <Col xs={3}>休暇</Col>
                        <Col
                          xs={3}
                          lg={3}
                          xl={3}
                          css={styles.hideByHolidayType1(selectedHoliday.holidayType)}
                        >
                          休暇単位
                        </Col>
                        <Col
                          xs={3}
                          lg={4}
                          xl={3}
                          css={styles.hideByHolidayType1(selectedHoliday.holidayType)}
                        >
                          時間休の開始時間
                        </Col>
                        <Col
                          xs={3}
                          lg={2}
                          xl={3}
                          css={styles.hideByHolidayType1(selectedHoliday.holidayType)}
                        >
                          取得時間
                        </Col>
                        <Col
                          xs={6}
                          css={styles.hideWithType2(
                            selectedHoliday.holidayType, selectedHoliday.autoGrantHoliday,
                          )}
                        >
                          休日出勤日
                        </Col>
                        <Col
                          xs={6}
                          css={styles.hideWithType2NotAuto(
                            selectedHoliday.holidayType, selectedHoliday.autoGrantHoliday,
                          )}
                        />
                        <Col xs={6} css={styles.hideByHolidayType4(selectedHoliday.holidayType)} />
                        <Col xs={6} css={styles.hideByHolidayType5(selectedHoliday.holidayType)} />
                        <Col
                          xs={6}
                          css={styles.hideByHolidayType6(selectedHoliday.holidayType)}
                        >
                          半休となる時間
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={3}>
                          <div css={{
                            display: 'flex', alignContent: 'center', alignItems: 'center', padding: '1vw',
                          }}
                          >
                            <Col>
                              <VerticalLabelSelectForm
                                label=""
                                name="holidayId"
                                value={String(selectedHoliday.value)}
                                setValue={(val: string) => handleChangeHoliday(val)}
                                options={holidayOptions}
                                required={true}
                                errorMsg={formik.errors.holidayId}
                              />
                            </Col>
                          </div>
                        </Col>
                        <Col xs={3} lg={3} xl={3} css={styles.hideByHolidayType1(selectedHoliday.holidayType)}>
                          <div css={{
                            display: 'flex', alignContent: 'center', alignItems: 'center', padding: '1vw',
                          }}
                          >
                            <Col>
                              <VerticalLabelSelectForm
                                label=""
                                name="holidayUnit"
                                value={String(formik.values.holidayUnit)}
                                setValue={(val: string) => handleChangeHolidayUnit(val)}
                                options={holidayUnitOptions}
                              />
                            </Col>
                          </div>
                        </Col>
                        <Col xs={3} lg={4} xl={3} css={styles.hideByHolidayType1(selectedHoliday.holidayType)}>
                          <div css={{
                            display: 'flex', alignContent: 'center', alignItems: 'center', padding: '1vw',
                          }}
                          >

                            <Col>
                              <VerticalLabelSelectForm
                                label=""
                                name="startHour"
                                value={String(formik.values.startHour)}
                                setValue={(val: string) => formik.setFieldValue('startHour', val)}
                                options={hourFormOptions}
                                disable={formik.values.holidayUnit !== 2}
                              />
                            </Col>
                            <div>:</div>
                            <Col>
                              <VerticalLabelSelectForm
                                label=""
                                name="startTime"
                                value={String(formik.values.startTime)}
                                setValue={(val: string) => formik.setFieldValue('startTime', val)}
                                options={timeFormOptions}
                                disable={formik.values.holidayUnit !== 2}
                              />
                            </Col>
                            <Checkbox
                              id="nextDay"
                              name="nextDay"
                              label="翌日"
                              value=""
                              checked={Boolean(formik.values.nextDay)}
                              onChange={formik.handleChange}
                              readOnly={formik.values.holidayUnit !== 2}
                            />
                          </div>
                        </Col>
                        <Col xs={3} lg={2} xl={3} css={styles.hideByHolidayType1(selectedHoliday.holidayType)}>
                          <div css={{
                            display: 'flex', alignContent: 'center', alignItems: 'center', padding: '1vw', fontSize: '14px',
                          }}
                          >
                            <Col>
                              <TextForm
                                name="stampTime"
                                label=""
                                // value={currentApplid.stampTime ? currentApplid.stampTime : ''}
                                value={formik.values.stampTime}
                                onChange={formik.handleChange}
                                disable={formik.values.holidayUnit !== 2}
                              />
                            </Col>
                            <Col>
                              時間
                            </Col>
                          </div>
                        </Col>
                        <Col xs={6} css={styles.hideWithType2(selectedHoliday.holidayType, selectedHoliday.autoGrantHoliday)} style={{ padding: '1vw' }}>
                          <VerticalLabelSelectForm
                            label=""
                            name="substituteId"
                            value={String(formik.values.substituteId)}
                            setValue={(val: string) => formik.setFieldValue('substituteId', val)}
                            options={substituteOptions}
                          />
                        </Col>
                        <Col
                          xs={6}
                          css={styles.hideWithType2NotAuto(
                            selectedHoliday.holidayType, selectedHoliday.autoGrantHoliday,
                          )}
                        />
                        <Col xs={6} css={styles.hideByHolidayType4(selectedHoliday.holidayType)} />
                        <Col xs={6} css={styles.hideByHolidayType5(selectedHoliday.holidayType)} />
                        <Col xs={6} css={styles.hideByHolidayType6(selectedHoliday.holidayType)}>
                          <div css={{
                            display: 'flex', alignContent: 'center', alignItems: 'center', padding: '1vw', textAlign: 'center',
                          }}
                          >
                            <Col>
                              {selectedHoliday.paidHalfHolidayStartTime}
                              -
                              {selectedHoliday.paidHalfHolidayEndTime}
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
                  : (
                    <React.Fragment>
                      <Row>
                        <Col xs={12}>
                          <BodyText>休暇</BodyText>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div css={{
                            display: 'flex', alignContent: 'center', alignItems: 'center', padding: '1vw',
                          }}
                          >
                            <Col>
                              <VerticalLabelSelectForm
                                label=""
                                name="holidayId"
                                value={String(selectedHoliday.value)}
                                setValue={(val: string) => handleChangeHoliday(val)}
                                options={holidayOptions}
                                required={true}
                                errorMsg={formik.errors.holidayId}
                              />
                            </Col>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} css={styles.hideByHolidayType1(selectedHoliday.holidayType)}>
                          <BodyText>休暇単位</BodyText>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} css={styles.hideByHolidayType1(selectedHoliday.holidayType)}>
                          <div css={{
                            display: 'flex', alignContent: 'center', alignItems: 'center', padding: '1vw',
                          }}
                          >
                            <Col>
                              <VerticalLabelSelectForm
                                label=""
                                name="holidayUnit"
                                value={String(formik.values.holidayUnit)}
                                setValue={(val: string) => handleChangeHolidayUnit(val)}
                                options={holidayUnitOptions}
                              />
                            </Col>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} css={styles.hideByHolidayType1(selectedHoliday.holidayType)}>
                          <BodyText>時間休の開始時間</BodyText>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} css={styles.hideByHolidayType1(selectedHoliday.holidayType)}>
                          <div css={{
                            display: 'flex', alignContent: 'center', alignItems: 'center', padding: '1vw',
                          }}
                          >
                            <Col>
                              <VerticalLabelSelectForm
                                label=""
                                name="startHour"
                                value={String(formik.values.startHour)}
                                setValue={(val: string) => formik.setFieldValue('startHour', val)}
                                options={hourFormOptions}
                                disable={formik.values.holidayUnit !== 2}
                              />
                            </Col>
                            <div>:</div>
                            <Col>
                              <VerticalLabelSelectForm
                                label=""
                                name="startTime"
                                value={String(formik.values.startTime)}
                                setValue={(val: string) => formik.setFieldValue('startTime', val)}
                                options={timeFormOptions}
                                disable={formik.values.holidayUnit !== 2}
                              />
                            </Col>
                            <Checkbox
                              id="nextDay"
                              name="nextDay"
                              label="翌日"
                              value=""
                              checked={Boolean(formik.values.nextDay)}
                              onChange={formik.handleChange}
                              readOnly={formik.values.holidayUnit !== 2}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} css={styles.hideByHolidayType1(selectedHoliday.holidayType)}>
                          <BodyText>取得時間</BodyText>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} css={styles.hideByHolidayType1(selectedHoliday.holidayType)}>
                          <div css={{
                            display: 'flex', alignContent: 'center', alignItems: 'center', padding: '1vw', fontSize: '14px',
                          }}
                          >
                            <Col>
                              <TextForm
                                name="stampTime"
                                label=""
                                value={formik.values.stampTime}
                                onChange={formik.handleChange}
                                disable={formik.values.holidayUnit !== 2}
                              />
                            </Col>
                            <Col>
                              時間
                            </Col>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={12}
                          css={styles.hideWithType2(
                            selectedHoliday.holidayType, selectedHoliday.autoGrantHoliday,
                          )}
                        >
                          <BodyText>休日出勤日</BodyText>
                        </Col>
                      </Row>
                      <Row>
                        <Col css={styles.hideWithType2(selectedHoliday.holidayType, selectedHoliday.autoGrantHoliday)} xs={12} style={{ padding: '1vw' }}>
                          <VerticalLabelSelectForm
                            label=""
                            name="substituteId"
                            value={String(formik.values.substituteId)}
                            setValue={(val: string) => formik.setFieldValue('substituteId', val)}
                            options={substituteOptions}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={12}
                          css={styles.hideByHolidayType6(selectedHoliday.holidayType)}
                        >
                          半休となる時間
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} css={styles.hideByHolidayType6(selectedHoliday.holidayType)}>
                          <div css={{
                            display: 'flex', alignContent: 'left', alignItems: 'left', padding: '1vw', textAlign: 'left',
                          }}
                          >
                            <Col>
                              {selectedHoliday.paidHalfHolidayStartTime}
                              -
                              {selectedHoliday.paidHalfHolidayEndTime}
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
                }

                <Row>
                  <Col md={12} xs={12}>
                    <FormField>
                      <TextForm
                        name="applicationReason"
                        label="申請理由"
                        value={String(formik.values.applicationReason)}
                        onChange={formik.handleChange}
                        required={true}
                        errorMsg={formik.errors.applicationReason}
                      />
                    </FormField>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <FormSubmitArea>
                      {
                        (!(currentApplid.modifiyStatus === 1
                           && currentApplid.applicationStatus === 0)
                           && roleScreen && (roleScreen.editable === 1 || roleScreen.length === 0)) && (
                           <span style={{ marginRight: '12px' }}>
                             <PrimaryButton
                               ghost={false}
                               text="申請"
                               onClick={() => {
                                 formik.handleSubmit();
                                 formik.setFieldValue('attendType', ActionType.Add);
                               }}
                             />
                           </span>
                        )
                      }
                      {
                        (currentApplid.achievementId !== null
                          && currentApplid.applicationStatus === 0)
                          && roleScreen && (roleScreen.editable === 1 || roleScreen.length === 0) && (
                          <span style={{ marginRight: '12px' }}>
                            <PrimaryButton
                              ghost={false}
                              text="申請キャンセル"
                              onClick={() => {
                                formik.handleSubmit();
                                formik.setFieldValue('attendType', ActionType.Modify);
                              }}
                            />
                          </span>
                        )
                      }
                      {
                        (currentApplid.deleteApplicationAvailable === true)
                        && roleScreen && (roleScreen.editable === 1 || roleScreen.length === 0) && (
                          <span style={{ marginRight: '12px' }}>
                            <PrimaryButton
                              ghost={false}
                              text="削除申請"
                              onClick={() => {
                                formik.handleSubmit();
                                formik.setFieldValue('attendType', ActionType.Delete);
                              }}
                            />
                          </span>
                        )
                      }
                      <PrimaryButton
                        onClick={() => history.push(sessionStorage.getItem('attendStampList.returnDestination')! || '')}
                        text="戻る"
                        ghost={true}
                      />
                    </FormSubmitArea>
                  </Col>
                </Row>
              </FormContents>
            </Container>
          </form>
        </div>
      </BlockUI>

      <ToastModal
        open={toastModalOpen}
        closeHandler={() => setToastModalOpen(false)}
        title={toastMessage}
      />

      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={() => {
          formik.handleSubmit();
        }}
        title="確認メッセージ"
        // eslint-disable-next-line no-nested-ternary
        content={formik.values.attendType === 0 ? '申請します。よろしいですか？'
          : formik.values.attendType === 1 ? '申請をキャンセルします。よろしいですか？' : '削除申請をします。よろしいですか？'}
        // eslint-disable-next-line no-nested-ternary
        submitText={formik.values.attendType === 0 ? '申請'
          : formik.values.attendType === 1 ? '申請キャンセル' : '削除申請'}
      />
    </SidebarTemplate>
  );
};

export default HolidayApplicationPage;
