/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';
import { grayScale, textFontSize, iconColor } from 'components/styles';

const styles = {
  style: css({
    border: `solid 1px ${grayScale.gray10}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '100%',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    padding: '8px',
  }),
  grayOut: css({
    background: iconColor.gray,
  }),
};

const TextInput: React.FC<{
  name: string;
  label?: string;
  value: string;
  placeHolder?: string;
  type?: string;
  readOnly?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  customStyle?: SerializedStyles;
  disable?: boolean;
  min?: string,
  max?: string,
  maxLength?: number,
  autoComplete?: boolean
}> = ({
  name, type, value, onChange, onBlur, placeHolder, customStyle, disable, readOnly = false,
  min, max, maxLength, autoComplete = true
}) => (
  <input
    css={css(styles.style, readOnly ? styles.grayOut : {}, customStyle)}
    id={name}
    name={name}
    type={type}
    value={value}
    placeholder={placeHolder}
    onChange={onChange}
    onBlur={onBlur}
    readOnly={readOnly}
    disabled={disable}
    min={min}
    max={max}
    maxLength={maxLength}
    autoComplete={autoComplete ? 'on' : 'off'}
  />
);

export default TextInput;
