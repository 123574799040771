
import React from 'react';
import MediaSalesList from 'components/organismos/master/general/salesPage/mediaSalesList/mediaSalesList';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const MediaSales = () => (
  <SidebarTemplate pageTitle="媒体売上帳票">
    <MediaSalesList />
  </SidebarTemplate>
);

export default MediaSales;
