/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import TableSort from 'components/molecules/TableSort';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';
import { grayScale } from 'components/styles';

const style = css({
  position: 'sticky',
  // top: '48px', // Appbarのheight分下げる
  top: '0px',
});

const sort = css({
  display: 'flex',
  alignItems: 'center',
  padding: '0 16px'
});

const hover = css({
  ':hover':{
    cursor: 'pointer',
    backgroundColor: '#F2F5F9'
  }
});
interface TableSortHeaderCellProps {
  onClick: () => void;
  isSorted: boolean;
  isSortedDesc?: boolean;
  disableSortBy?: boolean;
}

const TableSortHeaderCell : React.FC<TableSortHeaderCellProps> = ({
  children, onClick, isSorted, isSortedDesc, disableSortBy
}) => (
  <TableSort.HeaderSortCell
    customStyle={style}
    onClick={onClick}
  >
    <div css={[sort, !disableSortBy && hover]}>
      {children}
      {isSorted
      ? <SortIcon isSortedDesc={isSortedDesc} /> : (!disableSortBy ? <Icon type="sortDefault" color={grayScale.gray100} size="25px" />: '')}
    </div>
  </TableSort.HeaderSortCell>
);

export default TableSortHeaderCell;
