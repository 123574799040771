/** @jsx jsx */
import {useState, useMemo, useEffect, useCallback} from 'react';
import { css, jsx } from '@emotion/core';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import PrimaryButton from 'components/atoms/Button';
import moment from 'moment';
import { Column } from 'react-table';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import DataTable from 'components/organismos/DataTable/DataTable';
import BlockUI from 'components/molecules/BlockUi';
import SalesExpensesMSTDomain from 'domain/master/general/salesExpensesMST';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import ClickableLink from 'components/atoms/ClickableLink';
import Icon from 'components/atoms/Icon';
import Modal from 'components/molecules/Modal';
import { iconColor } from 'components/styles';
import { Link } from 'react-router-dom';
import { useSalesExpensesMSTList, useSalesExpensesDelete } from '../hook';
import IconLabelButton from "components/molecules/IconLabelButton";
import {downloadCSV} from "api/salesExpensesMST";
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const SalesExpensesMSTListManagement = () => {
  const { salesExpensesMSTList, blocking, fetchData } = useSalesExpensesMSTList();
  const {
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteSalesExpense,
    deleteSalesExpenseTarget,
    setDeleteSalesExpenseTarget,
  } = useSalesExpensesDelete(fetchData);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  const columns: Array<Column<SalesExpensesMSTDomain>> = useMemo(
    () => [
      {
        Header: 'コード',
        accessor: 'expensesCode',
      },
      {
        Header: '名称',
        accessor: 'expensesName',
      },
      {
        Header: '経費区分',
        accessor: 'expensesClassification',
        Cell: (cell: { row: { original: any }}) => (
          <div style={{ textAlign: 'left' }}>
            { cell.row.original.expensesClassification === 1 ? '固定費' : '変動費' }
          </div>
        ),
      },
      {
        Header: '並び順',
        accessor: 'dispOrder',
      },
      {
        Header: '適用開始月',
        accessor: 'applyStartDate',
        Cell: (cell: { row :{ original: any }}) => (
          <div style={{ textAlign: 'left' }}>
            {moment(cell.row.original.applyStartDate).format('YYYY年MM月')}
          </div>
        ),
      },
      {
        Header: '適用終了月',
        accessor: 'applyEndDate',
        Cell: (cell: { row :{ original: any }}) => (
          <div style={{ textAlign: 'left' }}>
            {moment(cell.row.original.applyEndDate).format('YYYY-MM-DD') !== '9999-12-31' ? moment(cell.row.original.applyEndDate).format('YYYY年MM月') : null }
          </div>
        ),
      },
      {
        Header: () => null,
        accessor: 'orgCode',
        id: 'actionButton',
        disableSortBy: true,
        Cell: (cell: { row :{ isExpanded: boolean, values: SalesExpensesMSTDomain }}) => {
          const [isHoverEdit, setIsHoverEdit] = useState(false);
          const [isHoverDelete, setIsHoverDelete] = useState(false);

          return (

            <FlexBox>
              <FlexBoxItem marginLeft="auto">
                <ClickableLink to={`/salesExpensesMST/edit/${cell.row.values.expensesCode}`}>
                  <span
                    style={{ marginLeft: '20px' }}
                    onMouseEnter={() => setIsHoverEdit(true)}
                    onMouseLeave={() => setIsHoverEdit(false)}
                  >
                    <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </ClickableLink>
              </FlexBoxItem>
              {
                roleScreen && roleScreen.editable === 1 && (
                <FlexBoxItem>
                  <ClickableIconButton
                    onClick={() => setDeleteSalesExpenseTarget(cell.row.values)}
                  >
                    <span
                      onMouseEnter={() => setIsHoverDelete(true)}
                      onMouseLeave={() => setIsHoverDelete(false)}
                    >
                      <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                    </span>
                  </ClickableIconButton>
                </FlexBoxItem>
                )
              }
            </FlexBox>
          );
        },
      },
    ],
    [setDeleteSalesExpenseTarget, roleScreen],
  );

    const downloadFileCSV = useCallback(() => {
        const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
        downloadCSV(companyCode, 'その他経費マスタ');
    }, []);

    return (
    <BlockUI blocking={blocking}>
      <div>
        <FormContents>
            <FlexBox>
                {
                    roleScreen && roleScreen.downloadFlag === 1 && (
                        <FlexBoxItem>
                            <IconLabelButton
                                onClick={downloadFileCSV}
                                iconType="download"
                                text="CSVダウンロード"
                            />
                        </FlexBoxItem>
                    )
                }
                {
                    roleScreen && roleScreen.importFlag === 1 && (
                        <FlexBoxItem>
                            <Link to="/salesExpensesMST/import">
                                <PrimaryButton
                                    ghost={false}
                                    text="インポート"
                                />
                            </Link>
                        </FlexBoxItem>
                    )
                }
                {
                    roleScreen && roleScreen.editable === 1 && (
                        <FlexBoxItem grow={1}>
                            <div style={{ textAlign: 'right' }}>
                                <Link to="/salesExpensesMST/add">
                                    <PrimaryButton
                                        text="新規作成"
                                    />
                                </Link>
                            </div>
                        </FlexBoxItem>
                    )
                }
            </FlexBox>

          <div
            css={css`
                table {
                  white-space: pre;
                  border-collapse: collapse;
                }
                table th:nth-child(1), table>tbody>tr>td:nth-child(1) {
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 150px;
                }
                table th:nth-child(2), table>tbody>tr>td:nth-child(2)  {
                    width: 250px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 250px;
                }
                table th:nth-child(3), table>tbody>tr>td:nth-child(3)  {
                    width: 100px;
                }
                table th:nth-child(4), table>tbody>tr>td:nth-child(4)  {
                    width: 60px;
                }
                table th:nth-child(5), table>tbody>tr>td:nth-child(5)  {
                    width: 100px;
                }
                table th:nth-child(6), table>tbody>tr>td:nth-child(6)  {
                    width: 100px;
                }
                table th:nth-child(7), table>tbody>tr>td:nth-child(8)  {
                    width: 80px;
                }
                table th:nth-child(8), table>tbody>tr>td:nth-child(9)  {
                    width: 80px;
                }
                `}
          >
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
              <DataTable
                columns={columns}
                data={salesExpensesMSTList}
                isGlobalFilter={true}
                minWidth="1000px"
                sortBy={[
                  { id: 'dispOrder', desc: false },
                ]}
              />
            </div>
          </div>
        </FormContents>
      </div>
      <Modal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        submitText="削除"
        title="確認メッセージ"
        onSubmit={onSubmitDeleteSalesExpense}
      >
        {deleteSalesExpenseTarget?.expensesCode}
        を削除します。よろしいですか？
      </Modal>
    </BlockUI>
  );
};

export default SalesExpensesMSTListManagement;
