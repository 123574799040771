/** @jsx jsx */
import React, { useEffect } from 'react';
import BodyText from 'components/atoms/BodyText';
import { textColor, utilityColor } from 'components/styles';

import Separater from 'components/atoms/Separator';
import Table from 'components/molecules/Table';
import { css, jsx } from '@emotion/core';
import PrimaryButton from 'components/atoms/Button';
import BottomBar from 'components/organismos/BottomBar';
import moment from 'moment';
import DatePicker from 'components/molecules/DatePicker';
import BlockUI from 'components/molecules/BlockUi';
import DropdownContextMenu from 'components/molecules/Dropdown/DropdownContextMenu';
import IconTooltip from 'components/molecules/IconTooltip';
import MobileContainer from '../MobileContainer';
import useWorkingStatusMobileData from './hooks';

const styles = {
  table: css({
    '& > table': { marginTop: '15px' },
  }),
  tableCell: css({
    '&  button': {
      width: '100px',
    },
    overflow: 'visible',

  }),
};

const WorkingStatusMobileLayout: React.FC = () => {
  const {
    isLoading,
    fetchList,
    getTargetDate,
    setTargetDate,
    getLoginStaffCcde,
    getLoginStaffName,
    staffStampList,
    employment,
    operationInfo,
    yearMonth,
  } = useWorkingStatusMobileData();

  useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTargetDate]);

  return (
    <BlockUI blocking={isLoading}>
      <div>
        <MobileContainer>
          <DatePicker
            dateFormat="yyyy/MM"
            date={yearMonth}
            changeDate={(date: Date) => {
              if (date !== null) {
                setTargetDate(date);
              }
            }}
            showMonthYearPicker={true}
            customStyle={css({ width: 'fit-content' })}
          />

          <div>
            {moment(yearMonth).format('M')}
            月の勤務状況
          </div>
          {/*
          <div css={css({ marginTop: '0.5rem' })}>
            給与目安 &nbsp;
            <BodyText size="md">
              ¥
            </BodyText>
            <BodyText size="xl" bold={true}>
              {`${Number(Math.floor(operationInfo.monthTotalCost / 1000) * 1000 || 0).toLocaleString()}`}
            </BodyText>
          </div>
          <div>
            <BodyText color={textColor.subText01}>
              合計給与予定 &nbsp;
              {`¥${Number(Math.floor(operationInfo.totalCost / 1000) * 1000 || 0).toLocaleString()}`}
            </BodyText>
          </div>
          */}
          <div css={css({ marginTop: '0.5rem' })}>
            勤務時間 &nbsp;
            <BodyText size="xl" bold={true}>
              {`${operationInfo.totalWorkTimeHour || 0}`}
            </BodyText>
            <BodyText size="md">
              時間
            </BodyText>
            <BodyText size="xl" bold={true}>
              {`${operationInfo.totalWorkTimeMin || 0}`}
            </BodyText>
            <BodyText size="md">
              分
            </BodyText>
          </div>

          <div>
            <BodyText color={textColor.subText01}>
              合計勤務予定時間 &nbsp;
              {`${operationInfo.monthTotalworkTimeHour || 0}時間${operationInfo.monthTotalworkTimeMin || 0}分`}
            </BodyText>
          </div>

          <Separater margin="1rem 0 2rem" />

          <div>
            {moment(yearMonth).format('M')}
            月の勤務実績
          </div>

          <div css={styles.table}>
            <Table>
              <thead>
                <tr>
                  <Table.HeaderCell customStyle={css({ width: '20px' })} />
                  <Table.HeaderCell customStyle={css({ width: '100px' })}>日付</Table.HeaderCell>
                  <Table.HeaderCell customStyle={css({ width: '120px' })}>時間</Table.HeaderCell>
                  <Table.HeaderCell />
                </tr>
              </thead>
              <tbody>
                {staffStampList && staffStampList.map((cell, index) => (
                  <tr key={index}>
                    <Table.Cell>
                      {(
                        cell.errorMsg) && (
                          <IconTooltip
                            iconType="remove"
                            text={cell.errorMsg || ''}
                            color={utilityColor.white}
                            customCss={css({ margin: '0' })}
                            place="right"
                            inlineBlock={true}
                          />
                      )}
                      {(
                        cell.alertMsg) && (
                          <IconTooltip
                            iconType="warningFill"
                            text={ cell.alertMsg || '' }
                            color={ utilityColor.yellow }
                            customCss={css({ margin: '0' })}
                            place="right"
                            inlineBlock={true}
                          />
                      )}
                      {(
                        cell.infoMsg) && (
                          <IconTooltip
                            iconType="warning"
                            text={ cell.infoMsg || '' }
                            color={ utilityColor.yellow }
                            customCss={css({ margin: '0' })}
                            place="right"
                            inlineBlock={true}
                          />
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(cell.targetDate).format('YYYY/MM/DD')}
                    </Table.Cell>

                    <Table.Cell>
                      <BodyText>
                        {cell.startTime}
                        {' '}
                        {(cell.startTime || cell.endTime) && '~'}
                        {' '}
                        {cell.endTime}
                      </BodyText>
                    </Table.Cell>

                    <Table.Cell customStyle={styles.tableCell}>
                      {
                        cell.employmentId == null ? (
                          null
                        ) : (
                          cell.belongOrgFix === '1' ? (
                            <PrimaryButton disabled={true} text="修正申請 ›" />
                          ) : (
                            <DropdownContextMenu
                              text="修正申請 ›"
                              modifierApplicationId={cell.modifierApplicationId}
                              orgCode={cell.orgCode}
                              staffCode={getLoginStaffCcde}
                              staffName={getLoginStaffName}
                              modifierApplicationStatus={cell.modifierApplicationStatus}
                              holidayApplicationStatus={cell.holidayApplicationStatus}
                              overworkApplicationStatus={cell.overworkApplicationStatus}
                              holidayworkApplicationStatus={cell.holidayworkApplicationStatus}
                              transferApplicationStatus={cell.transferApplicationStatus}
                              attendanceOrgFix={cell.attendanceOrgFix}
                              useOverTimeApplication={employment?.useOverTimeApplication}
                              necessaryHolidayWorkApplication={employment?.necessaryHolidayWorkApplication}
                              useTransferApplication={employment?.useTransferApplication}
                              achievementId={cell.achievementId}
                              targetDate={cell.targetDate}
                              stampVersion={cell.stampVersion}
                            />
                          )
                        )
                      }
                    </Table.Cell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>


        </MobileContainer>
        <BottomBar />
      </div>
    </BlockUI>
  );
};

export default WorkingStatusMobileLayout;
