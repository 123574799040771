import { useState, useEffect, useCallback } from 'react';
import OrganizationCategoryDomain from 'domain/master/general/organizationCategory';
import { getHeaderCategory } from 'api/organizationCategory';

export const useOrganizationCategoryList = () => {
  const [
    organizationCategoryList,
    setOrganizationCategoryList] = useState<Array<OrganizationCategoryDomain>>([]);
  const [orgCat, setOrgCat] = useState<OrganizationCategoryDomain>();
  const [blocking, setBlocking] = useState(false);

  const fetchData = useCallback(async () => {
    setBlocking(true);
    const currentTab = sessionStorage.getItem('currentOrgCatTab') ? sessionStorage.getItem('currentOrgCatTab')! : '1';
    const response = await getHeaderCategory();
    const list = response.map((result: any) => new OrganizationCategoryDomain(result));
    setOrganizationCategoryList(list);
    const selectedTab = parseFloat(currentTab) - 1;
    const selectedOrgCat = list[selectedTab];
    setOrgCat(selectedOrgCat);
    sessionStorage.setItem('selectedOrganizationCategory', selectedOrgCat.categoryDispName);
    sessionStorage.setItem('organizationCategoryMstHeadId', selectedOrgCat.organizationCategoryMstHeadId);
    setBlocking(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    organizationCategoryList,
    setOrganizationCategoryList,
    orgCat,
    setOrgCat,
    fetchData,
    blocking,
  };
};

export default useOrganizationCategoryList;
