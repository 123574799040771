import ApiClient from './ApiClient';

export interface FiscalYear {
  id: {
    companyCode: string;
    fiscalYear: string;
  };
  fiscalStartMonth: string;
  fiscalEndMonth: string;
  createUser: string;
  updateUser: string;
  createDate?: string;
  updateDate?: string;
  isCreate: boolean;
  fiscalYearStr: string;
  fiscalStartMonthStr: string;
  fiscalEndMonthStr: string;
}

export const getList = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const response = await ApiClient.get(`/v1/fiscalYear/${companyCode}`, {}, undefined);
  return response.data;
};

export const getFiscalYear = async (fiscalYear: string) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const response = await ApiClient.get(`/v1/fiscalYear/date/${companyCode}?fiscalYear=${fiscalYear}`, {});
  return response.data;
};

export const deleteById = async (fiscalYear: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const reponse = await ApiClient.delete(`/v1/fiscalYear/${companyCode}/${fiscalYear}`, undefined);
  return reponse;
};

export const postFiscalYear = async (dataPost: any) => {
  const response = await post(dataPost, {});
  return response.data;
};

export const post = async (params: any, query: {[key: string]: string|number}) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const response = await ApiClient.post(`/v1/fiscalYear/${companyCode}`, query, params);
  return response;
};
