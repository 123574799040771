
import { useState, useEffect } from 'react';
import getListPaymentMediaOrganization from 'api/paymentMediaOrganization';
import PaymentMediaOrganizationDomain from 'domain/master/sales/paymentMediaOrganization';

export const usePaymentMediaOrganizationList = (orgCode: string) => {
  // eslint-disable-next-line max-len
  const [paymentMediaOrganizationList, setPaymentMediaOrganizationList] = useState < Array<PaymentMediaOrganizationDomain>>([]);
  useEffect(() => {
    getListPaymentMediaOrganization(orgCode).then((response: Array<any>) => {
      setPaymentMediaOrganizationList(response.map(
        (result) => new PaymentMediaOrganizationDomain(result),
      ));
    });
  }, [orgCode, setPaymentMediaOrganizationList]);
  return { paymentMediaOrganizationList, setPaymentMediaOrganizationList };
};


export default usePaymentMediaOrganizationList;
