import React, { CSSProperties } from 'react';
import ReactSelect, { OptionTypeBase } from 'react-select';
import { ValueType, MenuPlacement } from 'react-select/src/types';

import productColor, {
  textFontSize,
  textColor,
  grayScale,
} from 'components/styles';

export interface OptionType extends OptionTypeBase {
  value: string;
  label: string;
  isHide?: boolean
}

const Select: React.FC<{
  name: string;
  options: Array<OptionType>;

  /**
   * 単数選択の場合の値
   * (isMulti=falseの場合は必須)
   */
  value?: string;
  setValue?: (val: string) => void;

  /**
   * 複数選択の場合の値
   * (isMulti=trueの場合は必須)
   */
  values?: Array<string>;
  setValues?: (vals: Array<string>) => void;
  isMulti?: boolean;

  /**
   * 読み取り専用
   */
  readOnly?: boolean;

  /**
   * 無効設定
   */
  isDisabled?: boolean;

  /**
   * placeHolderを指定する場合
   * デフォルトは'選択してください'
   */
  placeholder?: string;

  /**
   * optionがない場合の表示メッセージ
   * デフォルトは'選択できる項目はありません'
   */
  noOptionMessage?: string;

  /**
   * 幅
   */
  width?: string;

  /**
   * cssのカスタマイズ
   */
  controlStyle?: CSSProperties;

  /**
   * メニューの表示位置
   * デフォルトは'auto'
   */
  menuPlacement?: MenuPlacement;
}> = ({
  name,
  options,
  value,
  setValue,
  values,
  setValues,
  isMulti,
  readOnly,
  isDisabled,
  placeholder = '選択してください',
  noOptionMessage = '選択できる項目はありません',
  width,
  controlStyle,
  menuPlacement = 'auto',
}) => {
  /**
   * 単数選択
   */
  const getValue = (): ValueType<OptionType> => {
    if (!options) {
      return '' as any;
    }
    if (value === undefined) {
      return '' as any;
    }
    const opt = options.find((option) => option.value === value);
    if (opt === undefined) {
      return '' as any;
    }
    return opt;
  };
  const onChange = React.useCallback(
    (option) => {
      if (!setValue) {
        return;
      }
      setValue((option as OptionType).value);
    },
    [setValue],
  );

  /**
   * 複数選択
   */
  const getValues = (): ValueType<OptionType> => {
    if (!options) {
      return [];
    }
    return options.filter((option) => values?.find((val) => option.value === val));
  };
  const onMultiChange = React.useCallback(
    (changeOptions) => {
      if (!setValues) {
        return;
      }
      if (changeOptions === null || changeOptions === undefined) {
        setValues([]);
      } else {
        setValues(
          (changeOptions as Array<OptionType>).map((option) => option.value),
        );
      }
    },
    [setValues],
  );

  return (
    <ReactSelect
      name={name}
      value={isMulti ? getValues() : getValue()}
      onChange={isMulti ? onMultiChange : onChange}
      options={options}
      placeholder={placeholder}
      isMulti={isMulti}
      readOnly={readOnly}
      isDisabled={isDisabled}
      noOptionsMessage={() => noOptionMessage}
      menuPlacement={menuPlacement}
      styles={{
        control: (provided) => ({
          ...provided,
          ...controlStyle,
          borderColor: grayScale.gray10,
          ':hover': {
            borderColor: grayScale.gray20,
          },
        }),
        container: (provided) => ({
          ...provided,
          width,
        }),
        option: (provided, state) => ({
          ...provided,
          fontSize: textFontSize.re,
          backgroundColor: (() => {
            if (state.isSelected) return productColor.primary;
            if (state.isFocused) return productColor.primaryM80;
            return '';
          })(),
          color: state.isSelected ? textColor.inversed : textColor.main,
        }),
        singleValue: (provided) => ({
          ...provided,
          fontSize: textFontSize.re,
        }),
        valueContainer: (provided) => ({
          ...provided,
          minHeight: '36px',
        }),
        indicatorSeparator: () => ({
          width: 0,
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 5,
        }),
      }}
    />
  );
};

export default Select;
