import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';

import AccountTitleSubMSTImportForm from 'components/organismos/master/general/accountTitleSubMST/ImportSubForm/AccountTitleSubMSTImportForm';

const AccountTitleSubMSTImportPage: React.FC = () => (
  <SidebarTemplate pageTitle="補助科目インポート">
    <AccountTitleSubMSTImportForm />
  </SidebarTemplate>
);


export default AccountTitleSubMSTImportPage;
