/* eslint-disable max-len */
import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

export const downloadCSV = async (
  fileName: string,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsv(`/v3/discountType/outputCsv/${companyCode}`, {}, getDownloadCsvFileName(`${fileName}_`));
};

const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v3/discountType/csv/import/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};


export const getDisCountTypeList = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v3/discountType/list/${companyCode}`, {});
  return response.data;
};


export const getAccountTitleMSTDetail = async (
  accountTitleId: string): Promise<any> => {
  const params = {
    accountTitleId,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/accountTitleMst/${companyCode}`, params);
  return response.data;
};

export const createOrUpdateDiscountType = async (isCreate: boolean, isConfirm: boolean, mediaMaster: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isCreate: isCreate ? 1 : 0,
    isConfirmed: isConfirm ? 1 : 0,
  };
  const response = await ApiClient.post(`/v3/discountType/save/${companyCode}`, params, mediaMaster);
  return response.data;
};


export const getDiscountMaster = async (discountTypeMstCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    discountTypeMstCode,
  };
  const response = await ApiClient.get(`/v3/discountType/list/${companyCode}`, params);
  return response.data;
};

export const deleteDisCountTypeById = async (
  discountTypeMstCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    discountTypeMstCode,
  };

  const response = await ApiClient.delete(`/v3/discountType/delete/${companyCode}`, params);
  return response.data;
};


export type Error = {
  error: string;
  defaultMessage: string;
}

export default { };
