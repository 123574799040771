/**
 * API 祝日マスタ
 */

export interface Executive {
  companyCode: string;
  executiveCode: string;
  executiveName: string;
  executiveId: string;
  isEdit:boolean;
  data: any,
  createUser: string,
  updateUser: string,
  deleted:boolean
}

export default class ExecutiveDomain {
  constructor(private rawData: Executive) {
    // do nothing
  }

  static generateInitial(): ExecutiveDomain {
    return new ExecutiveDomain({
      executiveCode: '',
      executiveName: '',
      companyCode: '',
      executiveId: '',
      isEdit: false,
      data: [],
      createUser: '',
      updateUser: '',
      deleted: false,
    });
  }

  getRawData(): Executive {
    return this.rawData;
  }

  get executiveCode(): string {
    return this.rawData.executiveCode;
  }

  set executiveCode(executiveCode: string) {
    this.rawData.executiveCode = executiveCode;
  }

  get executiveName(): string {
    return this.rawData.executiveName;
  }

  set executiveName(executiveName: string) {
    this.rawData.executiveName = executiveName;
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = companyCode;
  }

  get executiveId(): string {
    return this.rawData.executiveId;
  }

  set executiveId(executiveId: string) {
    this.rawData.executiveId = executiveId;
  }

  get createUser():string {
    return this.rawData.createUser;
  }

  set createUser(createUser:string) {
    this.rawData.createUser = createUser;
  }


  get updateUser():string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser:string) {
    this.rawData.updateUser = updateUser;
  }

  get isEdit(): boolean {
    return this.rawData.isEdit;
  }
}
