import { AxiosResponse } from 'axios';
import moment from 'moment';
import { OrgStatusType } from 'utility/constants';
// import OrganizationDomain from 'domain/organization';
import ApiClient from './ApiClient';

export type Special = {
  applyStartMonthStr: string;
  addSalary: number;
  specialSalaryPatternId: string;
  specialSalaryPatternCode?: string;
  specialSalaryPatternName?: string;
}

export type EditSpecial = {
  applyStartMonthStr: string;
  specialSalaryPatternId: string;
  addSalary: number;
}

export interface Organization {
  orgCode: string;
  applyStartDate: string;
  orgName: string;
  orgNameKana: string;
  category: number;
  managerSetting: any;
  closeFlag: boolean;
  areaId: string;
  prefectureCode: string;
  employmentId: string;
  workingDaysId: string;
  businessCategoryId?: string;
  posCode: string;
  infomartLinkCode: string;
  numberOfSeat: number;
  floorSpace: number;
  managerList?: Array<Manager>;
  seniorManagerList?: Array<Manager>;
  orgSpecialList?: Array<Special> | null;
  editManagerList?: Array<EditManager>;
  editSeniorManagerList?: Array<EditManager>;
  editSpecial?: Special;
  organizationCategoryMstDetilId?:Array<string>;
  orgCategoryList?:Array<any>;
}

export interface OrganizationBasic {
  companyCode?: string;
  orgCode: string;
  applyStartDate: string;
  orgName: string;
  orgNameKana: string;
  category: number;
  closeFlag: boolean;
  areaId?: string;
  prefectureCode?: string;
  employmentId?: string;
  workingDaysId?: string;
  businessCategoryId?: string;
  posCode?: string;
  infomartLinkCode?: string;
  numberOfSeat?: number;
  floorSpace?: number;
  createUser: string;
  updateUser: string;
  organizationCategoryMstDetilId?:Array<string>;
}

export interface OrganizationManagerBasic {
  companyCode?: string;
  orgCode: string;
  applyStartDate: string;
  orgName: string;
  orgNameKana: string;
  category: number;
  closeFlag: boolean;
  areaId?: string;
  prefectureCode?: string;
  employmentId?: string;
  workingDaysId?: string;
  businessCategoryId?: string;
  posCode?: string;
  infomartLinkCode?: string;
  numberOfSeat?: number;
  floorSpace?: number;
  createUser: string;
  updateUser: string;
  editManagerList?: Array<EditManager>;
  editSeniorManagerList?: Array<EditManager>;
}

export interface OrganizationSalaryBasic {
  companyCode?: string;
  orgCode: string;
  applyStartDate: string;
  orgName: string;
  orgNameKana: string;
  category: number;
  closeFlag: boolean;
  areaId?: string;
  prefectureCode?: string;
  employmentId?: string;
  workingDaysId?: string;
  businessCategoryId?: string;
  posCode?: string;
  infomartLinkCode?: string;
  numberOfSeat?: number;
  floorSpace?: number;
  createUser: string;
  updateUser: string;
  editSpecial?: EditSpecial;
}

export type Manager = {
  orgCode: string;
  managerId: number;
  staffCode: string;
  staffName?: string;
  managerType: number;
  startDate: string;
  endDate?: string;
  hireDate?: string;
  retirementDate?: string;
  createUser?: string;
}

/**
 * マネージャー情報更新時にPOSTするデータ形式
 */
export type EditManager = {
  orgCode: string;
  staffCode: string;
  managerType: string;
  startDateStr: string;
  endDateStr?: string;
  hireDateStr?: string;
  retirementDateStr?: string;
}

export type CsvImportHistory = {
  // createDate: string
  // updateDate: string
  historyId: string
  formatType: number
  importFileName: string
  result: number
  errDetail: string
  errDetailDisp: string
}

export type Error = {
  error: string;
  defaultMessage: string;
}

export const getList = async (
  noManager: boolean,
  orgStatus: number,
  targetDate: Date,
  categoryAry: any,
  unsetTargetDate?: Date,

): Promise<Array<Organization>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    noManager,
    orgStatus,
    targetDate: moment(targetDate).format('YYYY-MM-DD'),
    unsetTargetDate: unsetTargetDate ? moment(unsetTargetDate).format('YYYY-MM-DD') : undefined,
    functionType: 1,
    loginStaffCode,
  };
  const response = await ApiClient.get(`/v2/organizations/list/${companyCode}?${appendUrl}`, params, undefined);
  return response.data;
};

export const postOrganization = async (organization: OrganizationBasic, isEditMode: boolean):
  Promise<Array<Organization>> => {
  const query = {
    inputType: isEditMode ? 1 : 0,
    tabIndex: 0,
  };
  const response = await postBasic(organization, query);
  return response.data;
};

export const postManagers = async (organization: OrganizationManagerBasic) => {
  const query = {
    inputType: 1,
    tabIndex: 1,
  };
  const response = await postManagerBasic(organization, query);
  return response.data;
};

export const postSpecialSalary = async (organization: OrganizationSalaryBasic) => {
  const query = {
    inputType: 1,
    tabIndex: 2,
  };
  const response = await postSalaryBasic(organization, query);
  return response.data;
};

const postBasic = async (
  organization: OrganizationBasic, query: { [key: string]: string | number },
) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // const response = await ApiClient.post(`/v1/organizations/${companyCode}`, query, organization);
  const response = await ApiClient.post(`/v2/organizations/${companyCode}`, query, organization);
  return response;
};

const postManagerBasic = async (
  organization: OrganizationManagerBasic, query: { [key: string]: string | number },
) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v1/organizations/${companyCode}`, query, organization);
  return response;
};

const postSalaryBasic = async (
  organization: OrganizationSalaryBasic, query: { [key: string]: string | number },
) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v1/organizations/${companyCode}`, query, organization);
  return response;
};

/**
 * CSVファイルインポート
 */
export const postCsvImport = async (
  uploadFile: File, formatType: string): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const query = {
    formatType,
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v3/organizations/csv/import/${companyCode}`, query, 'uploadFile', uploadFile);
  return response;
};

export const downloadOrganizationCsv = async (
  noManager: boolean,
  orgStatus: number,
  targetDate: Date,
  categoryAry: any,
  unsetTargetDate?: Date,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    noManager,
    orgStatus,
    targetDate: moment(targetDate).format('YYYY-MM-DD'),
    unsetTargetDate: unsetTargetDate ? moment(unsetTargetDate).format('YYYY-MM-DD') : undefined,
    functionType: 1,
    loginStaffCode,
  };

  await ApiClient.downloadCsv(`/v3/organizations/csv/${companyCode}?${appendUrl}`, params, getDownloadCsvFileName('組織情報_'));
};

export const downloadManagerCsv = async (
  noManager: boolean,
  orgStatus: number,
  targetDate: Date,
  categoryAry: any,
  unsetTargetDate?: Date,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    noManager,
    orgStatus,
    targetDate: moment(targetDate).format('YYYY-MM-DD'),
    unsetTargetDate: unsetTargetDate ? moment(unsetTargetDate).format('YYYY-MM-DD') : undefined,
    functionType: 1,
    loginStaffCode,
  };
  await ApiClient.downloadCsv(`/v2/organizations/manager/csv/${companyCode}?${appendUrl}`, params, getDownloadCsvFileName('組織マネージャー権限_'));
};

export const downloadSpecialSalaryCsv = async (
  noManager: boolean,
  orgStatus: number,
  targetDate: Date,
  categoryAry: any,
  unsetTargetDate?: Date,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    noManager,
    orgStatus,
    targetDate: moment(targetDate).format('YYYY-MM-DD'),
    unsetTargetDate: unsetTargetDate ? moment(unsetTargetDate).format('YYYY-MM-DD') : undefined,
    functionType: 1,
    loginStaffCode,
  };
  await ApiClient.downloadCsv(`/v2/organizations/specialSalary/csv/${companyCode}?${appendUrl}`, params, getDownloadCsvFileName('特賃日割当情報_'));
};

export const getHistory = async (orgCode: string): Promise<Array<Organization>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // const response = await ApiClient.get(`/v1/organizations/history/${companyCode}`, {}, { orgCode });
  const response = await ApiClient.get(`/v3/organizations/history/${companyCode}`, {}, { orgCode });
  return response.data;
};

export const getTrees = async (functionType:number= 2,dayFrom?: string, dayTo?: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  // const siteType = 'HQ';
  // TODO: V4にバージョンアップ予定
  const response = await ApiClient.get(`/v3/organizations/trees/${companyCode}?staffCode=${staffCode}&functionType=${functionType}&targetDayFrom=${dayFrom}&targetDayTo=${dayTo}`, {});
  return response.data;
};
export const getTreesOrgCode = async (functionType:number = 2,targetDayFrom?: string,
  targetDayTo?: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  // const siteType = siteTypeString || 'HQ';
  const query = {
    // siteType,
    staffCode,
    functionType,
    targetDayFrom,
    targetDayTo,
  };
  // TODO: V4にバージョンアップ予定
  const response = await ApiClient.get(`/v3/organizations/trees/${companyCode}?`, query);
  return response.data;
};

export const getTreesAllOrgCode = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  // const siteType = 'HQ';
  const query = {
    // siteType,
    staffCode,
    functionType:2,
    addAllItem: true,
  };
  // TODO: V4にバージョンアップ予定
  const response = await ApiClient.get(`/v3/organizations/trees/${companyCode}?`, query);
  return response.data;
};

export const getTreesByCategory = async (functionType:number,category?: Array<any>): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  // const siteType = 'HQ';
  // TODO: V4にバージョンアップ予定
  const response = await ApiClient.get(`/v3/organizations/trees/${companyCode}?staffCode=${staffCode}&functionType=${functionType}&categoryAry=${category}`, {});
  return response.data;
};

export const getTreesByCategoryAll = async (functionType:number, dayFrom?: string, dayTo?: string,
  category?: any[]): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  // const siteType = 'HQ';
  // TODO: V4にバージョンアップ予定
  const response = await ApiClient.get(`/v3/organizations/trees/${companyCode}?staffCode=${staffCode}&functionType=${functionType}&targetDayFrom=${dayFrom}&targetDayTo=${dayTo}&categoryAry=${category}&addAllItem=${true}`, {});
  return response.data;
};

export const getSelManager = async (orgCode: string): Promise<Array<Manager>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    orgCode,
  };
  const response = await ApiClient.get(`/v2/organizations/selManager/${companyCode}`, {}, query);
  return response.data;
};

export const getOrgManged = async (): Promise<Array<any>> => {
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const targetDate = moment(new Date()).format('YYYY-MM-DD');
  const query = {
    staffCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/organizations/managed/${companyCode}`, {}, query);
  return response.data;
};

export const getSelOrganizeStaff = async (categoryAry: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    loginStaffCode,
    functionType: 1,
  };
  const response = await ApiClient.get(`/v2/organizations/selOrganize/${companyCode}?orgCodes=${appendUrl}`, params);
  return response.data;
};


export const getSelOrganize = async (

): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/organizations/selOrganize/${companyCode}?orgCodes=`, {});

  return response.data;
};

export const deleteOrganization = async (orgCode: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // await ApiClient.delete(`/v1/organizations/delete/${companyCode}`, { orgCode, tabIndex: 0 });
  await ApiClient.delete(`/v2/organizations/delete/${companyCode}`, { orgCode, tabIndex: 0 });
};

export const deleteOrganizationSpecialSalary = async (orgCode: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // await ApiClient.delete(`/v1/organizations/delete/${companyCode}`, { orgCode, tabIndex: 2 });
  await ApiClient.delete(`/v2/organizations/delete/${companyCode}`, { orgCode, tabIndex: 2 });
};

const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export const getOrganizationInfo = async (orgCode: string, targetDate: string):
  Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // const response = await ApiClient.get(`/v1/organizations/${companyCode}`, { orgCode, targetDate });
  const response = await ApiClient.get(`/v2/organizations/${companyCode}`, { orgCode, targetDate });

  return response.data;
};

export const getOrganizationTrees = async (functionType: number, targetDayFrom?: string,
  targetDayTo?: string, staffCode?: string, addAllItem?: boolean, categoryParrentAry?: Array<any>, orgStatus?: OrgStatusType): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  let params: object;
  params = { functionType };
  if (targetDayFrom) {
    params = { ...params, targetDayFrom };
  }
  if (targetDayTo) {
    params = { ...params, targetDayTo };
  }
  if (staffCode) {
    params = { ...params, staffCode };
  }

  params = { ...params, addAllItem };

  if(orgStatus) {
    params = { ...params, orgStatus };
  }

  let url = Object.entries(params)
    .map(
      ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
    )
    .join('&');

  if (Array.isArray(categoryParrentAry)) {
    categoryParrentAry.forEach((cateArr: any, index: number) => {
      url += `&categoryAry=${cateArr.join(',')}`;
    });
  }

  const response = await ApiClient.get(`/v4/organizations/trees/${companyCode}?${url}`, {});
  return response.data;
};

export const getOrganizationInfoV4 = async (orgCode: string, targetDate: string, responseGroup: string):
  Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDate,
    responseGroup
  }
  const response = await ApiClient.get(`/v4/organizations/${companyCode}`, params);

  return response.data;
};

export const createOrganizationInfoV4 = async (organization: any) : Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const createUser = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser
  }
  const response = await ApiClient.postJsonData(`/v4/organizations/${companyCode}`, params, organization);
  return response.data;
};

export const updateOrganizationInfoV4 = async (organization: any, originalApplyStartDate?: any) : Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const createUser = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser,
    updateUser: createUser,
    originalApplyStartDate
  }
  const response = await ApiClient.patchJsonData(`/v4/organizations/${companyCode}`, params, organization);
  return response.data;
};

export default {};
