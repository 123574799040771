import React from 'react';

import useSidebar from 'hooks/useSidebar';
import Sidebar from 'components/organismos/Sidebar';
import Appbar from 'components/organismos/Appbar';
import useLoginUser from 'hooks/useLoginUser';
import TabBar, { TablItem } from 'components/TabBar';
import {useHistory,useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
  
const ShiftSidebarTemplate: React.FC<{
  pageTitle?: string;
}> = ({ children, pageTitle }) => {
  const {
    isSidebarOpen, toggleSidebar, sidebarItems, hierarchy, setHierarchy,
  } = useSidebar();
  const params = useParams<any>();
  // Shift
  const paramsUrl = params.orgName !== undefined && params.orgCode !== undefined && params.targetDate !== undefined ? '/'+params.orgName+'/'+params.orgCode+'/'+params.targetDate : ''; 

  const shiftTabbar: Array<TablItem> = [
    { value: 'dailyShift'+paramsUrl, display: '日次' },
    { value: 'monthlyShift'+paramsUrl, display: '月次' },
  ];
  const { staffName } = useLoginUser();
  const location = useLocation();
  const history = useHistory();
  let group = location.pathname.split('/')[1];

  // set page title
  // 組織カテゴリについてはカテゴリ名称に従い画面タイトルが変わるので固定処理とする
  let pathname = window.location.pathname;
  if (pathname === "/organizationCategory/import") {
    sessionStorage.setItem('PageTitleCurrent', "組織カテゴリ項目インポート");
  } else if (pathname.indexOf("/organizationCategory/edit") === 0) {
    sessionStorage.setItem('PageTitleCurrent', "組織カテゴリ項目編集");
  } else if (pathname === "/organizationCategory/add") {
    sessionStorage.setItem('PageTitleCurrent', "組織カテゴリ項目作成");
  } else {
    sessionStorage.setItem('PageTitleCurrent', String(pageTitle));
  }
  
  return (
    <Sidebar
      isOpen={isSidebarOpen}
      items={sidebarItems}
      hierarchy={hierarchy}
      setHierarchy={setHierarchy}
    >
      <Appbar
        toggleSidebar={toggleSidebar}
        title={pageTitle}
        staffName={staffName}
      />
      <TabBar
            items={shiftTabbar}
            setValue={(val: string) => {
                history.push(`/${val}`);
            }}
            currentValue={group}
            isFixed={true}
          />
      {children}
    </Sidebar>
  );
};

export default ShiftSidebarTemplate;
