import React, { useEffect, useState } from 'react';

import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { Link, useParams } from 'react-router-dom';
import ConfirmModal from 'components/organismos/ConfirmModal';
import FormContents from 'components/atoms/Form/FormContents';
import { useOrganizationCategoryDomainForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

type SelectType = 'paid'|'specialHoliday'|'holiday'|'rest';

const SelectType: {[key in SelectType]: SelectType} = {
  paid: 'paid',
  specialHoliday: 'specialHoliday',
  holiday: 'holiday',
  rest: 'rest',
};

const OrganizationCategoryForm: React.FC = () => {
  const { organizationCategoryMstDetailId } = useParams();
  const {
    formik, confirmModalOpen, closeConfirmModal,
  } = useOrganizationCategoryDomainForm(organizationCategoryMstDetailId);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  return (
    <div>
      <FormContents>
        <form>
          <FormField>
            <TextForm
              name="detailCode"
              label="項目コード"
              value={formik.values.detailCode}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.detailCode}
            />
          </FormField>
          <FormField>
            <TextForm
              name="detailName"
              label="項目名"
              value={formik.values.detailName}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.detailName}
            />
          </FormField>
          <FormSubmitArea>
            {
              roleScreen && roleScreen.editable === 1 && (
                <div style={{ marginRight: '12px' }}>
                  <Button
                    text={organizationCategoryMstDetailId ? '更新' : '登録'}
                    onClick={formik.handleSubmit}
                  />
                </div>
              )
            }
            <Link to="/organizationCategory">
              <Button
                text="戻る"
                ghost={true}
              />
            </Link>
          </FormSubmitArea>
        </form>
      </FormContents>

      <ConfirmModal
        title="確認メッセージ"
        content={organizationCategoryMstDetailId ? '更新します。よろしいですか？' : '登録します。よろしいですか？'}
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.submitForm}
        items={[]}
      />
    </div>
  );
};

export default OrganizationCategoryForm;
