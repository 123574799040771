import { useState, useEffect } from 'react';
import TaxRateCaptureSettingDomain from 'domain/master/sales/taxRateCaptureSetting';
import getListTaxRate from 'api/taxRateCaptureSetting';

export const useTaxRateList = (orgCode: string) => {
  const [taxRateList, setTaxRateList] = useState < Array<TaxRateCaptureSettingDomain>>([]);

  useEffect(() => {
    getListTaxRate(orgCode).then((response: Array<any>) => {
      const list = response.map((result) => new TaxRateCaptureSettingDomain(result));
      setTaxRateList(list);
    });
  }, [orgCode]);
  return { taxRateList, setTaxRateList };
};


export default useTaxRateList;
