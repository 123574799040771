/**
 * APIのデータ型
 */
export interface TimeOutputLayout {
  companyCode: string;
  createUser: string;
  updateUser: string;
  layoutId: string;
  layoutCode: string;
  layoutName: string;
  processType: number;
}

export default class TimeOutputLayoutDomain {
  constructor(private rawData: TimeOutputLayout) {
    // do nothing
  }

  static generateInitial(): TimeOutputLayoutDomain {
    return new TimeOutputLayoutDomain({
      companyCode: '',
      createUser: '',
      updateUser: '',
      layoutId: '',
      layoutCode: '',
      layoutName: '',
      processType: 1,
    });
  }

  getRawData(): TimeOutputLayout {
    return this.rawData;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = String(companyCode);
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set layoutId(layoutId: string) {
    this.rawData.layoutId = String(layoutId);
  }

  get layoutId(): string {
    return this.rawData.layoutId;
  }

  set layoutCode(layoutCode: string) {
    this.rawData.layoutCode = String(layoutCode);
  }

  get layoutCode(): string {
    return this.rawData.layoutCode;
  }

  set layoutName(layoutName: string) {
    this.rawData.layoutName = String(layoutName);
  }

  get layoutName(): string {
    return this.rawData.layoutName;
  }

  get createUser():string {
    return this.rawData.createUser;
  }

  set createUser(createUser:string) {
    this.rawData.createUser = createUser;
  }


  get updateUser():string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser:string) {
    this.rawData.updateUser = updateUser;
  }

  get processType():number {
    return this.rawData.processType;
  }

  set processType(processType:number) {
    this.rawData.processType = processType;
  }
}
