/** @jsx jsx */
import React, { useState, useCallback, useEffect } from 'react';
import { css, jsx } from '@emotion/core';

import { utilityColor, textColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import ClickableButton from 'components/atoms/ClickableIconButton';
import HeaderBar from 'components/atoms/HeaderBar';
import Heading from 'components/atoms/Heading';
import BodyText from 'components/atoms/BodyText';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import PrimaryLogo from 'components/atoms/Logo/Primary';
import PrimaryButton from 'components/atoms/Button';
import Badge from 'components/atoms/Badge';
import { isMobileOnly } from 'react-device-detect';
import PhotoFrame from 'components/molecules/PhotoFrame';
import { Link, useHistory } from 'react-router-dom';
import {
  AttendErrorOgr, getAttendErrorOrg, AttendErrorPersonal, getAttendErrorPersonal,
} from 'api/attendError';
import useToastNotification from 'hooks/useToastNotification';
import Modal from 'components/molecules/Modal';
import usePhotoPath from 'hooks/usePhotoPath';
import AttendErrorOrg from './AttendErrorOrg';
import AttendErrorPersonalPannel from './AttendErrorPersonal';
import {
  useStaffSetting,
} from './hooks';
import StaffSettingPanel from './StaffSettingPanel';


const styles = {
  sidebarButton: css({
    margin: '8px 12px',
    backgroundColor: `${utilityColor.white}`,
    borderRadius: '50%',
  }),
  logo: css({
    width: '32px',
    height: '32px',
    padding: '2px 2px',
  }),
  textMessage: css({
    color: 'black',
  }),
};

/**
 * アプリケーション共通のヘッダーバー
 */
const Appbar: React.FC<{
  toggleSidebar: () => void;
  title?: string;
  staffName: string;
}> = ({ toggleSidebar, title, staffName }) => {
  // const { openAttendAlert, setOpenAttendAlert, attendAlertList } = useAttendAlert();
  const [openAttendError, setOpenAttendError] = useState(false);
  const [attendErrorOrgList, setAttendErrorOrgList] = useState<Array<AttendErrorOgr>>([]);
  const [attendErrorPersonalList,
    setAttendErrorPersonalList] = useState<Array<AttendErrorPersonal>>([]);
  const {
    openStaffSetting, setOpenStaffSetting, logoutAction, openModalLogout,
  } = useStaffSetting();

  const loginUserNotificationType = sessionStorage.getItem('loginUser.notificationType') || '0';
  const { errorNotification } = useToastNotification();
  const useGpsStamp = sessionStorage.getItem('loginUser.useGpsStamp');
  const { myPhotoPath } = usePhotoPath();
  const history = useHistory();

  const [roleAvailable, setRoleAvailable] = useState<boolean>(false);
  const [roleOperationalReportList, setRoleOperationalReportList] = useState<boolean>(false);
  const [rolePeriodSales, setRolePeriodSales] = useState<boolean>(false);

  const [roleShogunStampHistory, setRoleShogunStampHistory] = useState<boolean>(false);

  const getAttendError = useCallback(async () => {
    try {
      if (loginUserNotificationType === '0') {
        await getAttendErrorPersonal().then((response: any) => {
          setAttendErrorPersonalList(response);
          setOpenAttendError(!openAttendError);
        });
      } else {
        await getAttendErrorOrg().then((response: any) => {
          setAttendErrorOrgList(response);
          setOpenAttendError(!openAttendError);
        });
      }
    } catch (error) {
      errorNotification('サーバー側でエラーが発生しました。');
    }
  }, [errorNotification, loginUserNotificationType, openAttendError]);

  useEffect(() => {
    setTimeout(() => {
      setRoleAvailable(Boolean(sessionStorage.getItem('role.operationalReportQuick')) || false);
      setRoleOperationalReportList(Boolean(sessionStorage.getItem('role.operationalReportList')) || false);
      setRolePeriodSales(Boolean(sessionStorage.getItem('role.periodSales')) || false);
      setRoleShogunStampHistory(Boolean(sessionStorage.getItem('role.shogunStampHistory')) || false);
    }, 1500);
  }, []);

  const handleBackLogin = () => {
    history.push('/login');
  };

  return (
    <HeaderBar>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {
        !isMobileOnly
          ? (
            <ClickableButton additionalCss={styles.sidebarButton} onClick={toggleSidebar}>
              <Icon type="hamburger" color={utilityColor.black} />
            </ClickableButton>
          )
          : <PrimaryLogo css={styles.logo} />
      }
          <Heading level={isMobileOnly ? 6 : 3}>{title}</Heading>

          {
        useGpsStamp === 'true'
          ? (
            <FlexBoxItem marginLeft="auto">
              <Link to="/timeRecorderGps">
                <Icon
                  type="clock"
                  size="24px"
                  color={!isMobileOnly ? utilityColor.white : utilityColor.mobileIcon}
                />
              </Link>
            </FlexBoxItem>
          )
          : null
      }

          {
        !isMobileOnly
          ? (
            <FlexBoxItem marginLeft={useGpsStamp === 'true' ? '8px' : 'auto'}>
              <ClickableButton onClick={
                () => (openAttendError ? setOpenAttendError(false) : getAttendError())
                }
              >
                <Badge showBadge={false}>
                  <Icon
                    type="notification"
                    size="24px"
                    color={!isMobileOnly ? utilityColor.white : utilityColor.mobileIcon}
                  />
                </Badge>
              </ClickableButton>
            </FlexBoxItem>
          )
          : null
      }

          <FlexBoxItem marginLeft={useGpsStamp === 'true' || !isMobileOnly ? '8px' : 'auto'}>
            <ClickableButton onClick={() => setOpenStaffSetting(!openStaffSetting)}>
              <FlexBox>
                <PhotoFrame src={myPhotoPath} size="24px" isCircle={true} onErrorIconColor="white" />
                <FlexBoxItem marginLeft="8px">
                  <BodyText
                    color={!isMobileOnly ? textColor.inversed : textColor.mobileTitle}
                  >
                    {staffName}

                  </BodyText>
                </FlexBoxItem>
              </FlexBox>
            </ClickableButton>
          </FlexBoxItem>

          {/* {openAttendAlert && <AttendAlert attendAlertList={attendAlertList} />} */}
          {openAttendError
          && loginUserNotificationType
          && loginUserNotificationType === '1' && (
          <AttendErrorOrg
            attendErrorList={attendErrorOrgList}
            setOpenAttendError={setOpenAttendError}
          />
          )}
          {openAttendError
          && loginUserNotificationType
          && loginUserNotificationType === '0' && (
          <AttendErrorPersonalPannel
            attendErrorList={attendErrorPersonalList}
            setOpenAttendError={setOpenAttendError}
          />
          )}
          {openStaffSetting && <StaffSettingPanel staffName={staffName} logoutAction={logoutAction} />}
        </div>
        <FlexBox>
          {isMobileOnly && roleAvailable && (
          <FlexBox customStyle={css({ padding: '10px' })}>
            <FlexBoxItem>
              <PrimaryButton text="速報" onClick={() => { history.push('/shogun/operationalReportQuick'); }} />
            </FlexBoxItem>
          </FlexBox>
          )}

          {isMobileOnly && rolePeriodSales && (
          <FlexBox customStyle={css({ padding: '8px' })}>
            <FlexBoxItem>
              <PrimaryButton text="期間売上" onClick={() => { history.push('/shogun/periodSales'); }} />
            </FlexBoxItem>
          </FlexBox>
          )}

          {isMobileOnly && roleShogunStampHistory && (
          <FlexBox customStyle={css({ padding: '8px' })}>
            <FlexBoxItem>
              <PrimaryButton text="出退勤" onClick={() => { history.push('/shogun/stampHistory'); }} />
            </FlexBoxItem>
          </FlexBox>
          )}
          {isMobileOnly && roleOperationalReportList && (
            <FlexBox customStyle={css({ padding: '8px' })}>
              <FlexBoxItem>
                <PrimaryButton text="稼働一覧" onClick={() => { history.push('/shogun/operationalReportList'); }} />
              </FlexBoxItem>
            </FlexBox>
          )}
        </FlexBox>

        <Modal
          open={openModalLogout}
          closeHandler={handleBackLogin}
          title=""
          submitText="OK"
          onSubmit={handleBackLogin}
          isShowButtonClose={false}
        >
          <p css={styles.textMessage}>セッションが切断されました。ログイン画面に戻ります。</p>
        </Modal>
      </div>
    </HeaderBar>
  );
};

export default Appbar;
