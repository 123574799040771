import {
  useEffect, useState, useCallback,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
// import { toast } from 'react-toastify';
import moment from 'moment';

import { getHistory, postSpecialSalary, deleteOrganizationSpecialSalary } from 'api/organization';
import useSpecialSalaryPatternOptions from 'hooks/useSpecialSalaryPatternOptions';
import OrganizationDomain from 'domain/organization';
import useToastNotification from 'hooks/useToastNotification';

export const useSpecialSalary = (
  isEditMode: boolean = false,
) => {
  const history = useHistory();
  const { orgCode } = useParams<{orgCode: string}>();
  const [enableSpecialSalary, setEnableSpecialSalary] = useState(false);
  // 組織情報
  const [organizationDomain, setOrganizationDomain] = useState<OrganizationDomain|null>(null);
  // 加算額
  const [addSalary, setAddSalary] = useState<number>(0);
  // 適用月
  const [applyStartMonth, setApplyStartMonth] = useState<Date>(new Date());
  // 特賃日パターンID
  const [specialSalaryPatternId, setSpecialSalaryPatternId] = useState<string>('');
  // 特賃日パターンのオプション一覧
  const specialSalaryPatternOptions = useSpecialSalaryPatternOptions();
  // 登録内容確認モーダルの開閉状態
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmDelModalOpen, setConfirmDelModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  // selected specialSalaryRow
  const [selectedSpecialSalary,setSelectedSpecialSalary] = useState<any>({})
  // 初期データ取得
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const historyList = await getHistory(orgCode);
  //     const organization = historyList[0];

  //     // POST用に組織情報を保存しておく
  //     setOrganizationDomain(new OrganizationDomain(organization));

  //     if (organization.orgSpecialList && organization.orgSpecialList.length !== 0) {
  //       const special = organization.orgSpecialList[0];
  //       setEnableSpecialSalary(true);
  //       setAddSalary(special.addSalary);
  //       setSpecialSalaryPatternId(special.specialSalaryPatternId);
  //       setApplyStartMonth(moment(special.applyStartMonthStr, 'YYYY年MM月').toDate());
  //     }
  //   };
  //   fetchData();
  // }, [orgCode]);

  const onDeleteSpecialSalary = useCallback(() => {
    if (!confirmDelModalOpen) {
      // 登録内容確認モーダルが表示されていなければ表示する
      setConfirmDelModalOpen(true);
      return;
    }

    // const response = await postOrganization(values.getRawData());
    deleteOrganizationSpecialSalary(orgCode)
      .then((response : any) => {
        if (organizationDomain) {
          organizationDomain.clearOrgSpecialList();
        }

        setEnableSpecialSalary(true);
        setAddSalary(0);
        setSpecialSalaryPatternId('');
        setApplyStartMonth(moment(new Date(), 'YYYY年MM月').toDate());

        setConfirmDelModalOpen(false);

        successNotification('削除しました');
      }).catch((error) => {
        setConfirmDelModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage} \n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  }, [confirmDelModalOpen, errorNotification, orgCode, organizationDomain, successNotification]);

  // 保存
  const onSubmit = useCallback(() => {
    if (!confirmModalOpen) {
      // 登録内容確認モーダルが表示されていなければ表示する
      setConfirmModalOpen(true);
      return;
    }

    // 設定処理
    if (organizationDomain == null) {
      errorNotification('組織情報がありません');
      return;
    }
    const postData = organizationDomain.getSpecialSalaryPostData({
      addSalary: Number(addSalary),
      applyStartMonthStr: moment(applyStartMonth).format('YYYY年MM月'),
      specialSalaryPatternId,
    });

    // const response = await postOrganization(values.getRawData());
    postSpecialSalary(postData)
      .then((response : any) => {
        const organization = response[0];
        // POST用に組織情報を保存しておく
        setOrganizationDomain(new OrganizationDomain(organization));

        if (organization.orgSpecialList && organization.orgSpecialList.length !== 0) {
          const special = organization.orgSpecialList[0];
          setEnableSpecialSalary(true);
          setAddSalary(special.addSalary);
          setSpecialSalaryPatternId(special.specialSalaryPatternId);
          setApplyStartMonth(moment(special.applyStartMonthStr, 'YYYY年MM月').toDate());
        }

        setConfirmModalOpen(false);

        successNotification('更新しました');
        // 新規登録の場合は組織一覧ページへ遷移
        if (!isEditMode) {
          history.push('/organizations/');
        }
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = '';
          error.response.data.errors.map((item: { defaultMessage: string; }) => {
            msgError += `${item.defaultMessage} <br />`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  }, [addSalary, applyStartMonth, confirmModalOpen, errorNotification, history, isEditMode,
    organizationDomain, specialSalaryPatternId, successNotification]);

  // 登録内容確認モーダル用データ
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmDelModal = useCallback(() => {
    setConfirmDelModalOpen(false);
  }, []);

  return {
    enableSpecialSalary,
    setEnableSpecialSalary,
    addSalary,
    setAddSalary,
    applyStartMonth,
    setApplyStartMonth,
    specialSalaryPatternId,
    setSpecialSalaryPatternId,
    specialSalaryPatternOptions,
    onSubmit,
    organizationDomain,
    confirmModalOpen,
    closeConfirmModal,
    onDeleteSpecialSalary,
    confirmDelModalOpen,
    closeConfirmDelModal,
    selectedSpecialSalary,
    setSelectedSpecialSalary
  };
};

export default {};
