/** @jsx jsx */
import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { css, jsx } from '@emotion/core';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import PrimaryButton from 'components/atoms/Button';
import DatePicker from 'components/molecules/DatePicker';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import BlockUI from 'components/molecules/BlockUi';
import Select from 'components/atoms/Select';
import TextInput from 'components/atoms/Form/TextInput';
import FormatInput from 'components/atoms/Form/FormatInput';
import { Row, Col } from 'react-grid-system';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormLabel from 'components/atoms/Form/FormLabel';
import { iconColor } from 'components/styles';
import { Link, useHistory } from 'react-router-dom';
import Icon from 'components/atoms/Icon';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { downloadCSV } from 'api/pettyCash';
import { isSafari, isIPad13 } from 'react-device-detect';
import { usePettyCash } from './hook';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const PettyCashManagement : React.FC<{}> = () => {
  const {
    selectedMonth,
    setSelectedMonth,
    optionDateSelected,
    optionDescriptionMST,
    getSupportDestinationOrg,
    setSupportDestinationOrg,
    pettyCashList,
    totalPettyCashList,
    endBalance,
    beginBalance,
    setBeginBalance,
    displayBalance,
    setDisplayBalance,
    dateSelected,
    setDateSelected,
    selectDescriptionMST,
    setSelectDescriptionMST,
    checkCustom,
    setCheckCustom,
    money,
    setMoney,
    paymentDestination,
    setPaymentDestination,
    remark,
    setRemark,
    setRegisterData,
    setDeleteDetail,
    pettyCashDetail,
    setPettyCashDetail,
    blocking,
    setBlocking,
    setUpdatePettyHead
  } = usePettyCash();

  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const [isHoverEdit, setIsHoverEdit] = useState(false);
  const [isHoverDelete, setIsHoverDelete] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmPettyCashHead, setConfirmPettyCashHead] = useState(false);
  const [confirmPettyCashDetail, setConfirmPettyCashDetail] = useState(false);
  const [itemDetail, setItemDetail] = useState('');
  const [buttonType, setButtonType] = useState(0);
  const [categoryAry, setCategoryAry] = useState<Array<string>>([]);
  const [toggleContent, setToggleContent] = useState<boolean>(getSupportDestinationOrg !== 'all');

  const history = useHistory();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === getSupportDestinationOrg);

  const checkUserRole = getSupportDestinationOrg !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole])

  const re = /^[0-9\,\-\b]+$/;

  const downloadFileCSV = () => {
    downloadCSV(getSupportDestinationOrg, selectedMonth, categoryAry, '小口現金仕訳');
  };

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
    setItemDetail('');
  }, []);

  const openConfirmModal = useCallback(() => {
    setConfirmModalOpen(true);
  }, []);

  const closeConfirmPettyCashHead = useCallback(() => {
    setConfirmPettyCashHead(false);
  }, []);

  const openConfirmPettyCashHead = useCallback(() => {
    setConfirmPettyCashHead(true);
  }, []);

  const closeConfirmPettyCashDetail = useCallback(() => {
    setConfirmPettyCashDetail(false);
  }, []);

  const openConfirmPettyCashDetail = useCallback(() => {
    setConfirmPettyCashDetail(true);
  }, []);

  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num)) {
      return num;
    }
    const tmp = String(num).split('.');
    if (tmp[1]) {
      let fixed = 3;
      if (tmp[1].length < 3) {
        fixed = tmp[1].length;
      }
      return parseFloat(num).toFixed(fixed);
    }
    return formatter.format(num);
  };

  const removeComma = (number: any) => {
    const tmp = String(number).replace(/[\,]/g, '');
    if (!isNaN(Number(tmp))) {
      return Number(tmp);
    }
    return Number(0);
  };

  const styleTable = css({
    width: '100%',
    whiteSpace: 'nowrap',
  });

  useEffect(() => {
    if (getSupportDestinationOrg !== 'all') {
      setToggleContent(true);
    } else {
      setToggleContent(false);
    }
  }, [getSupportDestinationOrg]);

  return (
    <div>
      <BlockUI blocking={blocking}>
        <FormContents>
          <FlexBox>
            <DatePicker
              dateFormat="yyyy年MM月"
              date={selectedMonth}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setSelectedMonth(date);
                  sessionStorage.setItem('dateSelectPettyCase', `${date}`);
                }
              }}
              isFullWidth={false}
              showMonthYearPicker={true}
            />
            {
              checkUserRole && checkUserRole.downloadFlag === 1 && (
              <FlexBoxItem marginLeft="auto">
                <PrimaryButton
                  onClick={downloadFileCSV}
                  text="仕訳データ出力"
                />
              </FlexBoxItem>
            )}
          </FlexBox>

          <OrganizationFilteredTree
            functionType={3}
            staffCode={staffCode}
            orgLabel="店舗名"
            initOrgValue={String(getSupportDestinationOrg)}
            orgCallback={(args: string | Array<string>) => {
              setSupportDestinationOrg(String(args));
            }}
            orgCategoryCallback={(args: Array<string>) => {
              setCategoryAry(args);
            }}
            targetdayForm={moment(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1)).toDate()}
            targetdayTo={moment(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0)).toDate()}
            addAllItem={true}
          />
        </FormContents>

        <FormContents isToggle={toggleContent}>
          <Row style={{ margin: '10px 0' }}>
            <Col xl={8} lg={12} md={12}>
              <FlexBox>
                <FlexBoxItem width="8%" marginRight="6px">
                  <FormLabel label="日付" />
                </FlexBoxItem>
                <FlexBoxItem width="22%">
                  <Select
                    name="dateSelected"
                    value={dateSelected}
                    setValue={(val: string) => {
                      setDateSelected(val);
                    }}
                    options={optionDateSelected}
                    width="100%"
                  />
                </FlexBoxItem>
                <FlexBoxItem marginLeft="10%" width="15%">
                  <FormLabel label="科目名" />
                </FlexBoxItem>
                <FlexBoxItem width="50%">
                  <Select
                    name="descriptionMST"
                    value={selectDescriptionMST}
                    setValue={(val: string) => {
                      setSelectDescriptionMST(val);
                    }}
                    options={optionDescriptionMST}
                    width="100%"
                  />
                </FlexBoxItem>
              </FlexBox>
            </Col>
          </Row>
          <Row style={{ margin: '10px 0' }}>
            <Col xl={8} lg={12} md={12}>
              <FlexBox>
                <FlexBoxItem width="8%">
                  <FormLabel label="金額" />
                </FlexBoxItem>
                <FlexBoxItem width="50%">
                  <FlexBox>
                    <FlexBoxItem width={isSafari ? '175px' : '130px'} customStyle={css({ marginBottom: '8px' })}>
                      <RadioSelectForm
                        name="debitTaxType"
                        items={[
                          {
                            label: '入金',
                            value: '1',
                          },
                          {
                            label: '出金',
                            value: '0',
                          },
                        ]}
                        value={String(checkCustom)}
                        setValue={(e) => {
                          setCheckCustom(Number(e.target.value));
                        }}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem width="60%">
                      <FlexBox>
                        <FlexBoxItem width="100%">
                          <TextInput
                            type="text"
                            name="money"
                            value={String(numberFormater(money))}
                            onChange={(e) => {
                              // number = 10 && comma = 3
                              if ((e.target.value == '' || re.test(e.target.value)) && e.target.value.length <= 10) {
                                setMoney(removeComma(e.target.value));
                              }
                            }}
                            customStyle={css({ textAlign: 'right' })}
                          />
                        </FlexBoxItem>
                        <FlexBoxItem>
                          <FormLabel label="円" customStyle={css({ paddingLeft: '5px' })} />
                        </FlexBoxItem>
                      </FlexBox>
                    </FlexBoxItem>
                  </FlexBox>
                </FlexBoxItem>
                <FlexBoxItem width="45%">
                  <FlexBox>
                    <FlexBoxItem marginLeft="10%" width="30%" marginRight={isIPad13 ? '2px' : ''}>
                      <FormLabel label="支払先" />
                    </FlexBoxItem>
                    <FlexBoxItem width="100%">
                      <TextInput
                        name="paymentDestination"
                        value={paymentDestination}
                        onChange={(e) => {
                          setPaymentDestination(e.target.value);
                        }}
                      />
                    </FlexBoxItem>
                  </FlexBox>
                </FlexBoxItem>
              </FlexBox>
            </Col>

          </Row>
          <Row style={{ margin: '10px 0' }}>
            <Col xl={8} lg={12} md={12}>
              <FlexBox>
                <FlexBoxItem width="8%" marginRight="2px">
                  <FormLabel label="備考" />
                </FlexBoxItem>
                <FlexBoxItem width="92%">
                  <TextInput
                    name="remark"
                    value={remark}
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                  />
                </FlexBoxItem>
              </FlexBox>
            </Col>
          </Row>
          {
            checkUserRole && checkUserRole.editable === 1 && (
            <FlexBoxItem marginLeft="auto">
              <PrimaryButton
                onClick={() => {
                  openConfirmPettyCashDetail();
                  pettyCashDetail && pettyCashDetail !== 'undefined' ? setButtonType(1) : setButtonType(0);
                }}
                text={pettyCashDetail && pettyCashDetail !== 'undefined' ? '更新' : '登録'}
                minWidth="100px"
              />
            </FlexBoxItem>
          )}
        </FormContents>

        <FormContents isToggle={toggleContent}>
          <FlexBox>
            <span>期首残高</span>
            { displayBalance === 0 ? (
              <span>
                <span style={{ margin: '0 20px' }}>
                  {beginBalance && numberFormater(beginBalance)}
                  円
                </span>
                {
                  checkUserRole && checkUserRole.editable === 1 && (
                    <PrimaryButton
                      onClick={() => {
                        setDisplayBalance(1);
                      }}
                      text="期首残高編集"
                      minWidth="100px"
                    />
                )}
              </span>
            ) : (
              <span>
                <span style={{ margin: '0 2px 0 10px' }}>
                  <FormatInput
                    type="text"
                    name="beginBalance"
                    value={String(numberFormater(beginBalance))}
                    onChange={(e) => {
                      if (e.target.value == '' || re.test(e.target.value)) {
                        setBeginBalance(removeComma(e.target.value));
                      }
                    }}
                    customStyle={css({ width: '120px', fontSize: '14px' })}
                  />
                </span>
                <span style={{ marginRight: '10px' }}>円</span>
                {
                  checkUserRole && checkUserRole.editable === 1 && (
                  <PrimaryButton
                    onClick={() => {
                      openConfirmPettyCashHead();
                    }}
                    text="更新"
                    minWidth="80px"
                  />
                )}
              </span>
            )}
            <span style={{ marginLeft: '30px' }}>期末残高</span>
            <span style={{ margin: '0 20px' }}>
              {endBalance && numberFormater(endBalance)}
              円
            </span>
          </FlexBox>
          <FlexBox customStyle={css({ margin: '20px 0' })}>
            <FlexBoxItem customStyle={css({ width: '100%' })}>
              <div
                className="table-responsive"
                css={css`
                            table{
                              border-spacing: 0
                          }
                          table th{
                              text-align: left
                          }
                          table th, table td{
                              padding: 5px;
                              font-size: 12px
                          }
                          table th:nth-child(1), table td:nth-child(1){
                                  width: 5%;
                              }
                              table th:nth-child(2), table td:nth-child(2) {
                                  width: 5%;
                              }
                              table th:nth-child(3), table td:nth-child(3) {
                                  width: 25%;
                                  word-break: break-all;
                                  white-space: pre-line;
                              }
                              table th:nth-child(4), table td:nth-child(4) {
                                  width: 5%;
                              }
                              table th:nth-child(5), table td:nth-child(5) {
                                  width: 10%;
                              }
                              table th:nth-child(6), table td:nth-child(6) {
                                  width: 10%;
                              }
                              table th:nth-child(7), table td:nth-child(7) {
                                  width: 35%;
                                  word-break: break-all;
                                  white-space: pre-line;
                              }
                              table th:nth-child(5), table td:nth-child(7) {
                                width: 5%;
                            }
                              table tr.totalRow{
                                  background-color: #f2f2f2;
                              }
                              table tbody:last-child::after{
                                  content: ' ';
                                  background: blue;
                                  height: 2px;
                              }
                            table th:nth-child(4), table th:nth-child(5), table th:nth-child(6){
                                text-align: center
                            }
                            table td:nth-child(4), table td:nth-child(5), table td:nth-child(6){
                                text-align: right
                            }
                        `}
              >
                <table
                  className="table table-bordered table-striped table-condensed"
                  css={css(styleTable)}
                >
                  <thead>
                    <tr>
                      <th>日付</th>
                      <th>科目</th>
                      <th>支払先</th>
                      <th>入金</th>
                      <th>出金</th>
                      <th>残高</th>
                      <th>備考</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      pettyCashList && pettyCashList.map((data: any, index: number) => (
                        <tr key={index} className={data && data.dataType == 1 ? 'totalRow' : ''}>
                          <td>{data && data.dayStr}</td>
                          <td>{data && data.descriptionName}</td>
                          <td>{data && data.payee}</td>
                          <td>{data && data.depositFlag === 1 ? `${numberFormater(data.deposit)}円` : data.dataType === 1 ? `${numberFormater(data.deposit)}円` : '-'}</td>
                          <td>{data && data.depositFlag === 0 ? `${numberFormater(data.payment)}円` : data.dataType === 1 ? `${numberFormater(data.payment)}円` : '-'}</td>
                          <td>{(data && data.balance !== '') && (`${numberFormater(data.balance)}円`)}</td>
                          <td>{data && data.remark}</td>
                          <td>
                            { checkUserRole && checkUserRole.editable === 1 && (data && data.dataType === 0) && (
                            <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                              <Link
                                to="#"
                                onClick={() => {
                                  setDateSelected(String(moment(data.targetDate).format('YYYY/MM/DD')));
                                  setSelectDescriptionMST(data.descriptionId);
                                  setCheckCustom(data.depositFlag);
                                  setMoney(data.depositFlag === 0 ? data.payment : data.deposit);
                                  setPaymentDestination(data.payee);
                                  setRemark(data.remark);
                                  setPettyCashDetail(data.pettyCashDetailId);
                                }}
                              >
                                <span
                                  style={{ marginLeft: isIPad13 ? '5px' : '20px' }}
                                  onMouseEnter={() => setIsHoverEdit(true)}
                                  onMouseLeave={() => setIsHoverEdit(false)}
                                >
                                  <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
                                </span>
                              </Link>
                              <Link to="#" onClick={() => { openConfirmModal(); setItemDetail(data.pettyCashDetailId); }}>
                                <span
                                  style={{ marginLeft: '20px', marginRight: '10px' }}
                                  onMouseEnter={() => setIsHoverDelete(true)}
                                  onMouseLeave={() => setIsHoverDelete(false)}
                                >
                                  <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                                </span>
                              </Link>
                            </div>
                            ) }
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                  <tbody style={{ backgroundColor: '#0D4796' }}>
                    <tr>
                      <td style={{ padding: '2px' }}> </td>
                      <td style={{ padding: '2px' }}> </td>
                      <td style={{ padding: '2px' }}> </td>
                      <td style={{ padding: '2px' }}> </td>
                      <td style={{ padding: '2px' }}> </td>
                      <td style={{ padding: '2px' }}> </td>
                      <td style={{ padding: '2px' }}> </td>
                      <td style={{ padding: '2px' }}> </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>{totalPettyCashList != null && totalPettyCashList.length > 0 && totalPettyCashList[0].dayStr !== 'undefined' ? totalPettyCashList[0].dayStr : ''}</td>
                      <td />
                      <td />
                      <td>{totalPettyCashList != null && totalPettyCashList.length > 0 && totalPettyCashList[0].deposit !== 'undefined' ? `${numberFormater(totalPettyCashList[0].deposit)}円` : ''}</td>
                      <td>{totalPettyCashList != null && totalPettyCashList.length > 0 && totalPettyCashList[0].payment !== 'undefined' ? `${numberFormater(totalPettyCashList[0].payment)}円` : ''}</td>
                      <td>{totalPettyCashList != null && totalPettyCashList.length > 0 && totalPettyCashList[0].balance !== 'undefined' ? `${numberFormater(totalPettyCashList[0].balance)}円` : ''}</td>
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                </table>
              </div>
            </FlexBoxItem>
          </FlexBox>
        </FormContents>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={() => {
            setDeleteDetail(itemDetail);
            setItemDetail('');
            closeConfirmModal();
            setBlocking(true);
          }}
          actionType={ActionType.DELETE}
        />


        <ConfirmActionModal
          open={confirmPettyCashHead}
          closeHandler={closeConfirmPettyCashHead}
          onSubmit={() => {
            setDisplayBalance(0);
            setUpdatePettyHead(1);
            setBlocking(true);
            closeConfirmPettyCashHead();
          }}
          actionType={ActionType.UPDATE}
        />

        <ConfirmActionModal
          open={confirmPettyCashDetail}
          closeHandler={closeConfirmPettyCashDetail}
          onSubmit={() => {
            setRegisterData(1);
            setBlocking(true);
            closeConfirmPettyCashDetail();
          }}
          actionType={buttonType === 1 ? ActionType.UPDATE : ActionType.CREATE}
        />
      </BlockUI>
    </div>
  );
};

export default PettyCashManagement;
