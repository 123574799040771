import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import PayMeCooperationFrom from 'components/organismos/master/atendanceManagement/PayMeCooperationFrom';

const PayMeCooperationPage = () => (
  <SidebarTemplate pageTitle="Payme連携">
    <PayMeCooperationFrom />
  </SidebarTemplate>
);

export default PayMeCooperationPage;
