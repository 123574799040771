import moment from "moment";
import { useState } from "react";

import {
  getApprovedTransferList,
  getApprovedStampList,
  getApprovedHolidayList,
  getApprovedOverTimeList,
  getApprovedHolidayWorkList,
  getRejectedStampList,
  getRejectedHolidayList,
  getRejectedOverTimeList,
  getRejectedHolidayWorkList,
  getRejectedTransferList,
  getApplyingStampList,
  getApplyingHolidayList,
  getApplyingHolidayWorkList,
  getApplyingOverTimeList,
  getApplyingTransferList
} from "./api";
import ApprovedTransferDomain from "./domain/approvedTransferDomain";
import ApprovedStampDomain from "./domain/approvedStampDomain";
import ApprovedHolidayDomain from "./domain/approvedHolidayDomain";
import ApprovedOvertimeDomain from "./domain/approvedOvertimeDomain";
import ApprovedHolidayWorkDomain from "./domain/approvedHolidayWorkDomain";
import RejectedStampDomain from "./domain/rejectedStampDomain";
import RejectedHolidayDomain from "./domain/rejectedHolidayDomain";
import RejectedOvertimeDomain from "./domain/rejectedOvertimeDomain";
import RejectedHolidayWorkDomain from "./domain/rejectedHolidayWorkDomain";
import RejectedTransferDomain from "./domain/rejectedTransferDomain";
import ApplyingStampDomain from "./domain/applyingStampDomain";
import ApplyingHolidayDomain from "./domain/applyingHolidayDomain";
import ApplyingOvertimeDomain from "./domain/applyingOvertimeDomain";
import ApplyingHolidayWorkDomain from "./domain/applyingHolidayWorkDomain";
import ApplyingTransferDomain from "./domain/applyingTransferDomain";
import {
  IStamp,
  IHoliday,
  IOvertime,
  IHolidayWork,
  ITransfer
} from "./interface";
import { searchDate } from "api/achievementConfirm";
import { useParams } from 'react-router-dom';

export type AttendApplicationApprovalParameter = {
  targetDate: string,
}

export const useAttendApplicationApproval = () => {
  const params = useParams<AttendApplicationApprovalParameter>();
  // Range Time
  const startOfMonth = params.targetDate
                        ? moment(params.targetDate).startOf("month").format("YYYY-MM-DD")
                        : moment().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = params.targetDate
                      ? moment(params.targetDate).endOf("month").format("YYYY-MM-DD")
                      : moment().endOf("month").format("YYYY-MM-DD");

  const [targetDateFrom, setTargetDateFrom] = useState<Date>(new Date(startOfMonth));
  const [targetDateTo, setTargetDateTo] = useState<Date>(new Date(endOfMonth));
  const [initialDateFrom, setInitialDateFrom] = useState<Date>(new Date(startOfMonth));
  const [initialDateTo, setInitialDateTo] = useState<Date>(new Date(endOfMonth));
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";

  //Applying tab
  const [applyingStampList, setApplyingStampList] = useState<ApplyingStampDomain[]>([]);
  const [applyingHolidayList, setApplyingHolidayList] = useState<ApplyingHolidayDomain[]>([]);
  const [applyingOvertimeList, setApplyingOvertimeList] = useState<ApplyingOvertimeDomain[]>([]);
  const [applyingHolidayWorkList, setApplyingHolidayWorkList] = useState<ApplyingHolidayWorkDomain[]>([]);
  const [applyingTransferList, setApplyingTransferList] = useState<ApplyingTransferDomain[]>([]);

  //Approved tab
  const [approvedStampList, setApprovedStampList] = useState<ApprovedStampDomain[]>([]);
  const [approvedHolidayList, setApprovedHolidayList] = useState<ApprovedHolidayDomain[]>([]);
  const [approvedOvertimeList, setApprovedOvertimeList] = useState<ApprovedOvertimeDomain[]>([]);
  const [approvedHolidayWorkList, setApprovedHolidayWorkList] = useState<ApprovedHolidayWorkDomain[]>([]);
  const [approvedTransferList, setApprovedTransferList] = useState<ApprovedTransferDomain[]>([]);

  //Rejected tab
  const [rejectedStampList, setRejectedStampList] = useState<RejectedStampDomain[]>([]);
  const [rejectedHolidayList, setRejectedHolidayList] = useState<RejectedHolidayDomain[]>([]);
  const [rejectedOvertimeList, setRejectedOvertimeList] = useState<RejectedOvertimeDomain[]>([]);
  const [rejectedHolidayWorkList, setRejectedHolidayWorkList] = useState<RejectedHolidayWorkDomain[]>([]);
  const [rejectedTransferList, setRejectedTransferList] = useState<RejectedTransferDomain[]>([]);

  const fetchData = async () => {
    setIsLoading(true);

    let userPaymentDay = []; //empty on subsequent change
    if (isFirstRender) {
      userPaymentDay = await searchDate({ staffCode });
      setIsFirstRender(false);
      setInitialDateFrom(moment(userPaymentDay[0]).toDate());
      setInitialDateTo(moment(userPaymentDay[1]).toDate());
      // fix month closingDate
      const startOfMonthclosingDate =  moment(userPaymentDay[1]).toDate().getDate()
      const startOfMonthCurent = (new Date()).getDate();
      if (startOfMonthCurent > startOfMonthclosingDate){
        const endOfMonthNew = moment().endOf("month").add(1).format("YYYY-MM-DD");;
        setTargetDateTo(new Date(endOfMonthNew));
      }
    }

    Promise.all([
      fetchApplyingList(userPaymentDay[0], userPaymentDay[1]),
      fetchApprovedList(userPaymentDay[0], userPaymentDay[1]),
      fetchRejectedList(userPaymentDay[0], userPaymentDay[1])
    ]).then(() => {
      setIsLoading(false);
    });
  };

  const fetchSearchDate = async () => {
    let response = await searchDate({ staffCode });
    return response;
  };

  const fetchApplyingList = (
    initialDateFrom: string | null = null,
    initialDateTo: string | null = null
  ) => {
    const params = {
      staffCode,
      targetDateFrom: moment(targetDateFrom).format("YYYY-MM-DD"),
      targetDateTo: moment(targetDateTo).format("YYYY-MM-DD")
    };
    Promise.all([
      getApplyingStampList(params),
      getApplyingHolidayList(params),
      getApplyingOverTimeList(params),
      getApplyingHolidayWorkList(params),
      getApplyingTransferList(params)
    ]).then(result => {
      let [
        stampList,
        holidayList,
        overtimeList,
        holidayWorkList,
        transferList
      ] = result;

      setApplyingStampList(stampList.map((data: IStamp) => new ApplyingStampDomain(data)));
      setApplyingHolidayList(holidayList.map((data: IHoliday) => new ApplyingHolidayDomain(data)));
      setApplyingOvertimeList(overtimeList.map((data: IOvertime) => new ApplyingOvertimeDomain(data)));
      setApplyingHolidayWorkList(holidayWorkList.map((data: IHolidayWork) => new ApplyingHolidayWorkDomain(data)));
      setApplyingTransferList(transferList.map((data: ITransfer) => new ApplyingTransferDomain(data)));
    });
  };

  const fetchApprovedList = async (
    initialDateFrom: any = null,
    initialDateTo: any = null
  ) => {
    const params = {
      staffCode,
      targetDateFrom: moment(targetDateFrom).format("YYYY-MM-DD"),
      targetDateTo: moment(targetDateTo).format("YYYY-MM-DD")
    };

    await fetchApprovedStampList(params);
    await fetchApprovedHolidayList(params);
    await fetchApprovedOvertimeList(params);
    await fetchApprovedHolidayWorkList(params);
    await fetchApprovedTransferList(params);
  };

  const fetchApprovedStampList = async (params: any) => {
    if(!params){
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format("YYYY-MM-DD"),
        targetDateTo: moment(initialDateTo).format("YYYY-MM-DD"),
      };
    }
    let stampList = await getApprovedStampList(params);
    const newStampItem =  stampList.map((data: IStamp) => new ApprovedStampDomain(data))
    setApprovedStampList(newStampItem);
  };
  const fetchApprovedHolidayList = async (params: any) => {
    if(!params){
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format("YYYY-MM-DD"),
        targetDateTo: moment(initialDateTo).format("YYYY-MM-DD"),
      };
    }
    let holidayList = await getApprovedHolidayList(params);
    const newHolidayList = holidayList.map((data: IHoliday) => new ApprovedHolidayDomain(data))
    setApprovedHolidayList(newHolidayList);
  };
  const fetchApprovedOvertimeList = async (params: any) => {
    if(!params){
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format("YYYY-MM-DD"),
        targetDateTo: moment(initialDateTo).format("YYYY-MM-DD"),
      };
    }
    let overtimeList = await getApprovedOverTimeList(params);
    const newOverTimeList = overtimeList.map((data: IOvertime) => new ApprovedOvertimeDomain(data))
    setApprovedOvertimeList(newOverTimeList);
  };
  const fetchApprovedHolidayWorkList = async (params: any) => {
    if(!params){
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format("YYYY-MM-DD"),
        targetDateTo: moment(initialDateTo).format("YYYY-MM-DD"),
      };
    }
    let holidayWorkList = await getApprovedHolidayWorkList(params);
    const newHolidayWorkList = holidayWorkList.map((data: IHolidayWork) => new ApprovedHolidayWorkDomain(data))
    setApprovedHolidayWorkList(newHolidayWorkList);
  };
  const fetchApprovedTransferList = async (params: any) => {
    if(!params){
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format("YYYY-MM-DD"),
        targetDateTo: moment(initialDateTo).format("YYYY-MM-DD"),
      };
    }
    let transferList = await getApprovedTransferList(params);
    const newTransferList = transferList.map((data: ITransfer) => new ApprovedTransferDomain(data))
    setApprovedTransferList(newTransferList);
  };

  const fetchRejectedList = async (
    initialDateFrom: any = null,
    initialDateTo: any = null
  ) => {
    const params = {
      staffCode,
      targetDateFrom: moment(targetDateFrom).format("YYYY-MM-DD"),
      targetDateTo: moment(targetDateTo).format("YYYY-MM-DD")
    };

    await fetchRejectedStampList(params);
    await fetchRejectedHolidayList(params);
    await fetchRejectedOvertimeList(params);
    await fetchRejectedHolidayWorkList(params);
    await fetchRejectedTransferList(params);
  };

  const fetchRejectedStampList = async (params: any) => {
    if(!params){
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format("YYYY-MM-DD"),
        targetDateTo: moment(initialDateTo).format("YYYY-MM-DD"),
      };
    }
    let stampList = await getRejectedStampList(params);
    setRejectedStampList(stampList.map((data: IStamp) => new RejectedStampDomain(data)));
  };
  const fetchRejectedHolidayList = async (params: any) => {
    if(!params){
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format("YYYY-MM-DD"),
        targetDateTo: moment(initialDateTo).format("YYYY-MM-DD"),
      };
    }
    let holidayList = await getRejectedHolidayList(params);
    setRejectedHolidayList(holidayList.map((data: IHoliday) => new RejectedHolidayDomain(data)));
  };
  const fetchRejectedOvertimeList = async (params: any) => {
    if(!params){
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format("YYYY-MM-DD"),
        targetDateTo: moment(initialDateTo).format("YYYY-MM-DD"),
      };
    }
    let overtimeList = await getRejectedOverTimeList(params);
    setRejectedOvertimeList(overtimeList.map((data: IOvertime) => new RejectedOvertimeDomain(data)));
  };
  const fetchRejectedHolidayWorkList = async (params: any) => {
    if(!params){
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format("YYYY-MM-DD"),
        targetDateTo: moment(initialDateTo).format("YYYY-MM-DD"),
      };
    }
    let holidayWorkList = await getRejectedHolidayWorkList(params);
    setRejectedHolidayWorkList(holidayWorkList.map((data: IHolidayWork) => new RejectedHolidayWorkDomain(data)));
  };
  const fetchRejectedTransferList = async (params: any) => {
    if(!params){
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format("YYYY-MM-DD"),
        targetDateTo: moment(initialDateTo).format("YYYY-MM-DD"),
      };
    }
    let transferList = await getRejectedTransferList(params);
    setRejectedTransferList(transferList.map((data: ITransfer) => new RejectedTransferDomain(data)));
  };

  return {
    isLoading,
    setIsLoading,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    initialDateFrom,
    setInitialDateFrom,
    initialDateTo,
    setInitialDateTo,

    applyingStampList,
    applyingHolidayList,
    applyingOvertimeList,
    applyingHolidayWorkList,
    applyingTransferList,
    setApplyingStampList,
    setApplyingHolidayList,
    setApplyingOvertimeList,
    setApplyingHolidayWorkList,
    setApplyingTransferList,

    approvedStampList,
    approvedHolidayList,
    approvedOvertimeList,
    approvedHolidayWorkList,
    approvedTransferList,

    rejectedStampList,
    rejectedHolidayList,
    rejectedOvertimeList,
    rejectedHolidayWorkList,
    rejectedTransferList,

    fetchData,
    fetchSearchDate,
    fetchApplyingList,

    fetchApprovedList,
    fetchApprovedStampList,
    fetchApprovedHolidayList,
    fetchApprovedOvertimeList,
    fetchApprovedHolidayWorkList,
    fetchApprovedTransferList,

    fetchRejectedList,
    fetchRejectedStampList,
    fetchRejectedHolidayList,
    fetchRejectedOvertimeList,
    fetchRejectedHolidayWorkList,
    fetchRejectedTransferList
  };
};

export default useAttendApplicationApproval;
