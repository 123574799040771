import React from 'react';
import { css } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BodyText from 'components/atoms/BodyText';
import MultipleCheckboxForm from 'components/molecules/MultipleCheckboxForm';

const StateStatusFilter: React.FC<{
  stateStatus: Array<number>;
  setStateStatus: (stateStatus: Array<number>) => void;
}> = ({ stateStatus, setStateStatus }) => (
  <FlexBox
    customStyle={css({
      padding: '8px',
    })}
  >
    <FlexBoxItem basis="10%">
      <BodyText> 状態 </BodyText>
    </FlexBoxItem>
    <FlexBoxItem basis="90%">
      <MultipleCheckboxForm
        name="stateStatus"
        label=""
        items={[
          {
            id: 'onJob',
            label: '在職',
            value: stateStatus.includes(1),
          },
          {
            id: 'retirement',
            label: '退職',
            value: stateStatus.includes(2),
          },
          {
            id: 'expectedRetirement',
            label: '退職予定',
            value: stateStatus.includes(3),
          },
          {
            id: 'beforeJoining',
            label: '入社前',
            value: stateStatus.includes(4),
          },

        ]}
        setValue={(e) => {
          let stateStatusValue = 0;
          if (e.target.id === 'onJob') {
            stateStatusValue = 1;
          } else if (e.target.id === 'retirement') {
            stateStatusValue = 2;
          } else if (e.target.id === 'expectedRetirement') {
            stateStatusValue = 3;
          } else if (e.target.id === 'beforeJoining') {
            stateStatusValue = 4;
          }
          if (e.target.value === 'true') {
            setStateStatus(stateStatus.filter((item) => item !== stateStatusValue));
          } else {
            setStateStatus([...stateStatus, stateStatusValue]);
          }
        }}
      />
    </FlexBoxItem>
  </FlexBox>
);

export default StateStatusFilter;
