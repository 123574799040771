export type DashboardCard = {
  id: string
  cardValue: number
  cardThreshold: number
  calculationItemOfFRate: number
  includePettyCashInPurchase: boolean
  rank?: number
  totalNumber?: number
}

export class DashboardCardDomain {
  constructor(private rawData: DashboardCard) {
    // do nothing
  }

  static generateInitial(): DashboardCardDomain {
    return new DashboardCardDomain({
      id: '',
      cardValue: 0,
      cardThreshold: 0,
      rank: 0,
      totalNumber: 0,
      calculationItemOfFRate: 0,
      includePettyCashInPurchase: true,
    });
  }

  get shiftStartTime(): string {
    return this.rawData.id;
  }

  set id(id: string) {
    this.rawData.id = id;
  }

  get cardValue(): number {
    return this.rawData.cardValue;
  }

  get cardValueStr(): string {
    return `¥${this.rawData.cardValue.toLocaleString()}`;
  }

  set cardValue(cardValue: number) {
    this.rawData.cardValue = cardValue;
  }

  get cardThreshold(): number {
    return this.rawData.cardThreshold;
  }

  get cardThresholdStr(): string {
    return `(${this.rawData.cardThreshold * 100}%)`;
  }

  set cardThreshold(cardThreshold: number) {
    this.rawData.cardThreshold = cardThreshold;
  }

  get rankStrOld(): string {
    return `${this.rawData.rank || '--'}位 / ${this.rawData.totalNumber || '--'}店舗`;
  }

  get rankStr(): string {
    return this.rawData.rank ? `${formatter.format(this.rawData.rank)}` : '--';
  }

  get totalNumberStr(): string {
    return this.rawData.totalNumber ? `${formatter.format(this.rawData.totalNumber)}` : '--';
  }
  
  get calculationItemOfFRate(): number {
    return this.rawData.calculationItemOfFRate;
  }

  set calculationItemOfFRate(calculationItemOfFRate: number) {
    this.rawData.calculationItemOfFRate = calculationItemOfFRate;
  }

  get includePettyCashInPurchase(): boolean {
    return this.rawData.includePettyCashInPurchase;
  }

  set includePettyCashInPurchase(includePettyCashInPurchase: boolean) {
    this.rawData.includePettyCashInPurchase = includePettyCashInPurchase;
  }

}

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
