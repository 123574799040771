
import { useState, useEffect } from 'react';
import { getListAttendBusiness } from 'api/businessMaster';
import BusinessDomain from 'domain/master/labor/business';

export const useBusinessList = () => {
  const [businessList, setBusinessList] = useState<Array<BusinessDomain>>([]);
  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getListAttendBusiness(companyCode).then((response: Array<any>) => {
      if (isSubscribed) {
        setBusinessList(response.map((result) => new BusinessDomain(result)));
      }
    });
    return () => { isSubscribed = false; };
  }, []);
  return { businessList, setBusinessList };
};


export default useBusinessList;
