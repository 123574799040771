/* eslint-disable max-len */
/* eslint-disable camelcase */
import { UserRegistrationStatusType, Values } from 'utility/constants';

export type StaffRegistrationStatus = {
  companyCode: string;
  staffCode: string;
  staffName: string;
  orgCode?: string;
  orgName?: string;
  executiveId?: string,
  executiveCode?: string,
  executiveName?: string,
  mail?: string;
  userRegistrationStatus: UserRegistrationStatusType,
  userRegistrationStatusName: string,
  nameplate?: string,
}

export default class StaffRegistrationStatusDomain {
  private rawData: StaffRegistrationStatus

  constructor(rawData: StaffRegistrationStatus) {
    this.rawData = rawData;
  }

  static generateInitial(): StaffRegistrationStatusDomain {
    return new StaffRegistrationStatusDomain({
      companyCode:'',
      staffCode: '',
      staffName: '',
      orgCode: '',
      orgName: '',
      executiveId: '',
      executiveCode: '',
      executiveName: '',
      mail: '',
      userRegistrationStatus: Values.UserRegistrationStatus.Unregistered.value,
      userRegistrationStatusName: Values.UserRegistrationStatus.Unregistered.label,
      nameplate: '',
    });
  }

  getRawData(): StaffRegistrationStatus {
    return this.rawData;
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = companyCode;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName(): string {
    return this.rawData.staffName;
  }

  set staffName(staffName: string) {
    this.rawData.staffName = staffName;
  }

  get orgCode(): string | undefined {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string | undefined) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string | undefined {
    return this.rawData.orgName;
  }

  set orgName(orgName: string | undefined) {
    this.rawData.orgName = orgName;
  }

  get executiveId(): string | undefined {
    return this.rawData.executiveId;
  }

  set executiveId(executiveId: string | undefined) {
    this.rawData.executiveId = executiveId;
  }

  get executiveCode(): string | undefined {
    return this.rawData.executiveCode;
  }

  set executiveCode(executiveCode: string | undefined) {
    this.rawData.executiveCode = executiveCode;
  }

  get executiveName(): string | undefined {
    return this.rawData.executiveName;
  }

  set executiveName(executiveName: string | undefined) {
    this.rawData.executiveName = executiveName;
  }

  get mail(): string | undefined {
    return this.rawData.mail
  }

  set mail(mail: string | undefined) {
    this.rawData.mail = mail;
  }

  get userRegistrationStatus(): UserRegistrationStatusType {
    return this.rawData.userRegistrationStatus
  }

  set userRegistrationStatus(userRegistrationStatus: UserRegistrationStatusType) {
    this.rawData.userRegistrationStatus = userRegistrationStatus;
  }

  get userRegistrationStatusName(): string {
    return this.rawData.userRegistrationStatusName
  }

  set userRegistrationStatusName(userRegistrationStatusName: string) {
    this.rawData.userRegistrationStatusName = userRegistrationStatusName;
  }

  get nameplate(): string | undefined {
    return this.rawData.nameplate
  }

  set nameplate(nameplate: string | undefined) {
    this.rawData.nameplate = nameplate;
  }
}
