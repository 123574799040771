import React, { SVGProps } from 'react';

const Rocket: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M6.846 13.154a.625.625 0 010 .884l-1.768 1.768a.625.625 0 11-.884-.884l1.768-1.768a.625.625 0 01.884 0zm1.06 1.768a.625.625 0 010 .884l-.707.707a.625.625 0 11-.884-.884l.708-.707a.625.625 0 01.883 0zm-2.828-2.828a.625.625 0 010 .883l-.707.708a.625.625 0 11-.884-.884l.707-.707a.625.625 0 01.884 0zm12.286-9.458c.236 4.007-.59 6.953-2.475 8.839a20.41 20.41 0 01-.445.432l.465.863a.625.625 0 01-.036.652l-3.182 4.596a.625.625 0 01-1.065-.06l-1.811-3.365-3.41-3.409-3.363-1.81a.625.625 0 01-.06-1.065l4.596-3.182a.625.625 0 01.652-.036l.863.465c.14-.148.284-.297.432-.445 1.886-1.886 4.832-2.71 8.839-2.475zM7.247 6.52l-.272-.147-3.443 2.384 1.791.965C5.738 8.655 6.38 7.588 7.247 6.52zm3.03 8.157l.966 1.79 2.384-3.442-.147-.272c-1.068.867-2.135 1.509-3.202 1.924zM10.21 9.79l-2.617 1.813.805.804 1.812-2.617zm1.608-.126l-2.383 3.443.246.458.197-.074c.983-.39 1.983-1.008 2.998-1.86l-1.058-1.967zm-1.481-1.481L8.37 7.124c-.853 1.016-1.471 2.016-1.861 3l-.074.194.458.248 3.443-2.383zm5.817-4.336c-3.085-.015-5.315.719-6.744 2.148l-.173.175 2.59 1.396c.14.075.236.191.287.322.13.05.246.146.321.285l1.395 2.592.176-.174c1.43-1.43 2.163-3.659 2.148-6.744z"
        fill="#333"
      />
      <path
        d="M9.409 5.995c1.43-1.43 3.659-2.163 6.744-2.148.015 3.085-.719 5.315-2.148 6.744-1.505 1.505-2.979 2.501-4.416 3.008L6.4 10.411C6.908 8.974 7.904 7.5 9.409 5.995z"
        fill="#FFF"
      />
      <path
        d="M13.121 6.879a2 2 0 11-2.828 2.828A2 2 0 0113.12 6.88zm-.884.884a.75.75 0 10-1.06 1.06.75.75 0 001.06-1.06z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Rocket;
