import React from 'react';
// import ShiftSidebarTemplate from 'components/templates/SidebarTemplate';
import ShiftSidebarTemplate from "components/templates/ShiftSidebarTemplate";

import MonthlyShiftImport from './MonthlyShiftImport';


const MonthlyShiftImportLayoutPage: React.FC = () => (
  <ShiftSidebarTemplate
    pageTitle="シフトインポート"
  >
    <MonthlyShiftImport />
  </ShiftSidebarTemplate>
);

export default MonthlyShiftImportLayoutPage;
