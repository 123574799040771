// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';

export interface SalesSetting {
    // add new type data
    companyCode: string;
    calculationItemOfFRate: string;
    includePettyCashInPurchase: string;
    useReportApproval: string;
    reportApprovalStartDate: string;
    manageRevenueStamp: string;
    manageRevenueStampStartDate: string;
    commentSubjectSetting?: boolean;
    commentSubjectForBudgetProcess?: string;
    commentSubjectForStoreManagement?: string;
    commentSubjectForOthers?: string;
  }

export default class SalesSettingDomain {
  constructor(private rawData: SalesSetting) {
    this.rawData.commentSubjectSetting = !!this.rawData.commentSubjectSetting;
    this.rawData.commentSubjectForBudgetProcess = (!!this.rawData.commentSubjectSetting && 
      this.rawData.commentSubjectForBudgetProcess != '' && this.rawData.commentSubjectForBudgetProcess != null ) 
      ?  this.rawData.commentSubjectForBudgetProcess :  '予算進捗について';
    this.rawData.commentSubjectForStoreManagement = (!!this.rawData.commentSubjectSetting && 
      this.rawData.commentSubjectForStoreManagement != '' && this.rawData.commentSubjectForStoreManagement != null)
       ? this.rawData.commentSubjectForStoreManagement : '店舗経営について';
    this.rawData.commentSubjectForOthers = (!!this.rawData.commentSubjectSetting &&
      this.rawData.commentSubjectForOthers != '' && this.rawData.commentSubjectForOthers != null)
      ? this.rawData.commentSubjectForOthers : 'その他';
  }

  static generateInitial(): SalesSettingDomain {
    return new SalesSettingDomain({
      // add new initial data
      companyCode: '',
      calculationItemOfFRate: '0',
      includePettyCashInPurchase: 'false',
      useReportApproval: 'false',
      reportApprovalStartDate: moment(new Date()).format('YYYY-MM-DD'),
      manageRevenueStamp: 'false',
      manageRevenueStampStartDate: moment(new Date()).format('YYYY-MM-DD'),
      commentSubjectSetting: false,
      commentSubjectForBudgetProcess: '',
      commentSubjectForStoreManagement: '',
      commentSubjectForOthers: '',
    });
  }

  getRawData(): SalesSetting {
    return this.rawData;
  }

  getRawDataWithoutNullData(): SalesSetting {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    return {
      // add new value
      companyCode,
      calculationItemOfFRate: this.rawData.calculationItemOfFRate,
      includePettyCashInPurchase: this.rawData.includePettyCashInPurchase,
      useReportApproval: this.rawData.useReportApproval,
      reportApprovalStartDate: this.rawData.reportApprovalStartDate,
      manageRevenueStamp: this.rawData.manageRevenueStamp,
      manageRevenueStampStartDate: this.rawData.manageRevenueStampStartDate,
      commentSubjectSetting: this.rawData.commentSubjectSetting,
      commentSubjectForBudgetProcess:(!!this.rawData.commentSubjectSetting && 
        this.rawData.commentSubjectForBudgetProcess != '' && this.rawData.commentSubjectForBudgetProcess != null ) 
        ?  this.rawData.commentSubjectForBudgetProcess :  '予算進捗について',
      commentSubjectForStoreManagement: (!!this.rawData.commentSubjectSetting && 
        this.rawData.commentSubjectForStoreManagement != '' && this.rawData.commentSubjectForStoreManagement != null) 
         ? this.rawData.commentSubjectForStoreManagement : '店舗経営について',
      commentSubjectForOthers: (!!this.rawData.commentSubjectSetting &&
        this.rawData.commentSubjectForOthers != '' && this.rawData.commentSubjectForOthers != null) 
        ? this.rawData.commentSubjectForOthers : 'その他'
    };
  }


  get calculationItemOfFRate(): string {
    return this.rawData.calculationItemOfFRate;
  }

  set calculationItemOfFRate(calculationItemOfFRate: string) {
    this.rawData.calculationItemOfFRate = calculationItemOfFRate;
  }


  get includePettyCashInPurchase(): string {
    return this.rawData.includePettyCashInPurchase;
  }

  set includePettyCashInPurchase(includePettyCashInPurchase: string) {
    this.rawData.includePettyCashInPurchase = includePettyCashInPurchase;
  }

  get useReportApproval(): string {
    return this.rawData.useReportApproval;
  }

  set useReportApproval(useReportApproval: string) {
    this.rawData.useReportApproval = useReportApproval;
  }

  get reportApprovalStartDate(): string {
    return this.rawData.reportApprovalStartDate;
  }

  set reportApprovalStartDate(reportApprovalStartDate: string) {
    this.rawData.reportApprovalStartDate = reportApprovalStartDate;
  }

  // declare get set function for new data
  get manageRevenueStamp(): string {
    return this.rawData.manageRevenueStamp;
  }

  set manageRevenueStamp(manageRevenueStamp: string) {
    this.rawData.manageRevenueStamp = manageRevenueStamp;
  }

  get manageRevenueStampStartDate(): string {
    return this.rawData.manageRevenueStampStartDate;
  }

  set manageRevenueStampStartDate(manageRevenueStampStartDate: string) {
    this.rawData.manageRevenueStampStartDate = manageRevenueStampStartDate;
  }

  get commentSubjectSetting(): boolean {
    return this.rawData.commentSubjectSetting || false;
  }

  set commentSubjectSetting(commentSubjectSetting: boolean) {
    this.rawData.commentSubjectSetting = commentSubjectSetting;
  }

  get commentSubjectForBudgetProcess(): string {
    return this.rawData.commentSubjectForBudgetProcess || '';
  }

  set commentSubjectForBudgetProcess(commentSubjectForBudgetProcess: string) {
    this.rawData.commentSubjectForBudgetProcess = commentSubjectForBudgetProcess;
  }

  get commentSubjectForStoreManagement(): string {
    return this.rawData.commentSubjectForStoreManagement || '';
  }

  set commentSubjectForStoreManagement(commentSubjectForStoreManagement: string) {
    this.rawData.commentSubjectForStoreManagement = commentSubjectForStoreManagement;
  }

  get commentSubjectForOthers(): string {
    return this.rawData.commentSubjectForOthers || '';
  }

  set commentSubjectForOthers(commentSubjectForOthers: string) {
    this.rawData.commentSubjectForOthers = commentSubjectForOthers;
  }
}
