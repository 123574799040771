import { useState, useEffect } from 'react';
import {
  getInboundStatement,
  getAdvertisingStatement,
  getOtherDetails,
  getSalesCategory,
  downloadReportCsv,
  downloadReportExcel,
  downloadCsvSalesCategory,
  downloadExcelSalesCategory,
  downloadCsvInboundStatement,
  downloadExcelInboundStatement,
  downloadCsvAdvertisingStatement,
  downloadExcelAdvertisingStatement,
  downloadCsvOtherDetails,
  downloadExcelOtherDetails
} from 'api/salesAdvertisingMonthlyReport';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';

const useSalesAdvertisingMonthlyReportList = () => {
  const { errorNotification } = useToastNotification();
  const params = useParams<any>();
  const functionType = 3;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || "all");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);
  const monthSelect = params.targetDate || new Date();
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date(monthSelect));

  type ActionType = 'getTab1' | 'getTab2' | 'getTab3' | 'getTab4';
  const [orgName, setorgName] = useState(sessionStorage.getItem('loginUser.orgName') ? sessionStorage.getItem('loginUser.orgName') : '');

  const ActionType :{[key in ActionType]: ActionType} = {
    getTab1: 'getTab1',
    getTab2: 'getTab2',
    getTab3: 'getTab3',
    getTab4: 'getTab4'
  };
  const [action, setAction] = useState<ActionType>(ActionType.getTab1);
  const [listSalesManagement, setListSalesManagement] = useState<any>([]);
  const [listSalesManagement2, setListSalesManagement2] = useState<any>([]);
  const [listSalesManagement3, setListSalesManagement3] = useState<any>([]);
  const [listSalesManagement4, setListSalesManagement4] = useState<any>([]);

  useEffect(() => {
    let appendUrl = '';
    if (Array.isArray(categoryAry)) {
      categoryAry.forEach((cateArr: any, index: number) => {
        appendUrl += `&categoryAry=${cateArr.join(',')}`;
      });
    }

    let params = {
      orgCode: orgCode,
      monthlyStartDate: moment(selectedMonth).startOf('month').format('YYYY/MM/DD'),
      monthlyEndDate: moment(selectedMonth).endOf('month').format('YYYY/MM/DD'),
      loginStaffCode: loginStaffCode,
      functionType: 3
    }

    async function asyncCall1() {
      const result = await getInboundStatement(params, appendUrl);
      setListSalesManagement(result);
    }
    async function asyncCall2() {
      const result = await getAdvertisingStatement(params, appendUrl);
      setListSalesManagement2(result);
    }
    async function asyncCall3() {
      const result = await getOtherDetails(params, appendUrl);
      setListSalesManagement3(result);
    }
    async function asyncCall4() {
      const result = await getSalesCategory(params, appendUrl);
      setListSalesManagement4(result);
    }

    async function fetchData() {
      switch (action) {
        case 'getTab1':
          asyncCall1()
          break;
        case 'getTab2':
          asyncCall2()
          break;
        case 'getTab3':
          asyncCall3()
          break;
        case 'getTab4':
          asyncCall4()
          break;

        default:
          asyncCall1();
        break;
      }
    }
    fetchData();

  }, [orgCode, selectedMonth, categoryAry, action]);

  const handleError = (error: any) => {
    if (error.response && error.response.data && error.response.data.errors) {
      let msgError = '';
      error.response.data.errors.map((item: { defaultMessage: string; }) => {
        msgError += `${item.defaultMessage} \n`;
        return msgError;
      });
      errorNotification(msgError);
    } else {
      errorNotification('サーバー側でエラーが発生しました。');
    }
  }

  // InboundStatement
  const handleDownloadCsvInboundStatement = (queryString: string, fileName: string) => {
    downloadCsvInboundStatement(queryString, fileName).then(()=>{
      setIsLoading(false);
    }).catch((error)=>{
      setIsLoading(false);
      handleError(error);
    })
  }

  const handleDownloadExcelInboundStatement = (queryString: string, fileName: string) => {
    downloadExcelInboundStatement(queryString, fileName).then(()=>{
      setIsLoading(false);
    }).catch((error)=>{
      setIsLoading(false);
      handleError(error);
    })
  }

  // AdvertisingStatement
  const handleDownloadCsvAdvertisingStatement = (queryString: string, fileName: string) => {
    downloadCsvAdvertisingStatement(queryString, fileName).then(()=>{
      setIsLoading(false);
    }).catch((error)=>{
      setIsLoading(false);
      handleError(error);
    })
  }

  const handleDownloadExcelAdvertisingStatement = (queryString: string, fileName: string) => {
    downloadExcelAdvertisingStatement(queryString, fileName).then(()=>{
      setIsLoading(false);
    }).catch((error)=>{
      setIsLoading(false);
      handleError(error);
    })
  }

  // OtherDetails
  const handleDownloadCsvOtherDetails = (queryString: string, fileName: string) => {
    downloadCsvOtherDetails(queryString, fileName).then(()=>{
      setIsLoading(false);
    }).catch((error)=>{
      setIsLoading(false);
      handleError(error);
    })
  }

  const handleDownloadExcelOtherDetails = (queryString: string, fileName: string) => {
    downloadExcelOtherDetails(queryString, fileName).then(()=>{
      setIsLoading(false);
    }).catch((error)=>{
      setIsLoading(false);
      handleError(error);
    })
  }

  // SalesCategory
  const handleDownloadCsvSalesCategory = (queryString: string, fileName: string) => {
    downloadCsvSalesCategory(queryString, fileName).then(()=>{
      setIsLoading(false);
    }).catch((error)=>{
      setIsLoading(false);
      handleError(error);
    })
  }

  const handleDownloadExcelSalesCategory = (queryString: string, fileName: string) => {
    downloadExcelSalesCategory(queryString, fileName).then(()=>{
      setIsLoading(false);
    }).catch((error)=>{
      setIsLoading(false);
      handleError(error);
    })
  }

  const handleExportCSV = () => {
    setIsLoading(true);
    let params = {
      orgCode: orgCode,
      monthlyStartDate: moment(selectedMonth).startOf('month').format('YYYY/MM/DD'),
      monthlyEndDate: moment(selectedMonth).endOf('month').format('YYYY/MM/DD'),
      loginStaffCode: loginStaffCode,
      functionType: "3"
    }
    const queryParameters = new URLSearchParams(params);
    const queryParameter = new URLSearchParams(categoryAry.map(s=>['categoryAry',s]));
    const queryString = categoryAry?.length > 0 ? `${queryParameters.toString()}&${queryParameter.toString()}` : queryParameters.toString();
    switch (action) {
      case 'getTab1':
        handleDownloadCsvInboundStatement(queryString, "売上管理月報（インバウンド明細).csv");
        break;
      case 'getTab2':
        handleDownloadCsvAdvertisingStatement(queryString, "売上管理月報（広告明細).csv")
        break;
      case 'getTab3':
        handleDownloadCsvOtherDetails(queryString, "売上管理月報（その他明細).csv");
        break;
      case 'getTab4':
        handleDownloadCsvSalesCategory(queryString, "売上管理月報（売上区分）.csv");
        break;

      default:
      break;
    }
  }

  const handleExportExcel = () => {
    setIsLoading(true);
    let params = {
      orgCode: orgCode,
      monthlyStartDate: moment(selectedMonth).startOf('month').format('YYYY/MM/DD'),
      monthlyEndDate: moment(selectedMonth).endOf('month').format('YYYY/MM/DD'),
      loginStaffCode: loginStaffCode,
      functionType: "3"
    }
    const queryParameters = new URLSearchParams(params);
    const queryParameter = new URLSearchParams(categoryAry.map(s=>['categoryAry',s]));
    const queryString = categoryAry?.length > 0 ? `${queryParameters.toString()}&${queryParameter.toString()}` : queryParameters.toString();
    switch (action) {
      case 'getTab1':
        handleDownloadExcelInboundStatement(queryString, "売上管理月報（インバウンド明細).xlsx");
        break;
      case 'getTab2':
        handleDownloadExcelAdvertisingStatement(queryString, "売上管理月報（広告明細).xlsx")
        break;
      case 'getTab3':
        handleDownloadExcelOtherDetails(queryString, "売上管理月報（その他明細).xlsx");
        break;
      case 'getTab4':
        handleDownloadExcelSalesCategory(queryString, "売上管理月報（売上区分）.xlsx");
        break;

      default:
      break;
    }
  }

  return {
    isLoading,
    listSalesManagement,
    listSalesManagement2,
    listSalesManagement3,
    listSalesManagement4,
    selectedMonth,
    setSelectedMonth,
    functionType,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    action,
    setAction,
    ActionType,
    orgName,
    setorgName,
    handleExportCSV,
    handleExportExcel
  };
}
export const useDownload = () => {
  const downloadCsv = (data: any, fileName: any) => {
   downloadReportCsv(data, `${fileName}.csv`);
  };
  const downloadExcel = (data: any, fileName: any) => {
    downloadReportExcel(data, `${fileName}.xlsx`);
  };
  return {
    downloadCsv,
    downloadExcel
  };
};

export default useSalesAdvertisingMonthlyReportList;
