import { useState, useCallback, useEffect } from 'react';
import { getSpecificStaff, postStaffPhoto } from 'api/staff';

import useToastNotification from 'hooks/useToastNotification';
import { useStaffsForOrgCodeOptions } from 'hooks/useStaffsOptions';
import { SessionStorageKey } from 'utility/constants';
import { photoPathByStaffCode } from 'hooks/usePhotoPath';

export const useMasterShopFaceRegistrationForm = () => {
  const [disableButtonCap, setDisableButtonCap] = useState(false);
  const companyCode = sessionStorage.getItem(SessionStorageKey.LoginUser.COMPANY_CODE) || '';
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [staff, setStaff] = useState<any>();
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem(SessionStorageKey.LoginUser.ORG_CODE) || '');
  const [staffCode, setStaffCode] = useState(sessionStorage.getItem(SessionStorageKey.LoginUser.STAFF_CODE) || '');
  const [orgTreesOptions, setOrgTreesOptions] = useState<any[]>([]);
  const staffsForOrgCodeOptions = useStaffsForOrgCodeOptions(orgCode);

  const { successNotification, errorNotification } = useToastNotification();

  const handleSubmit = async (
    files?: any,
  ) => {
    setIsLoading(true);
    if (!staff) {
      setIsLoading(false);
      errorNotification('スタッフを選択してください。');

      return;
    }
    // if (!confirmModalOpen) {
    //   setConfirmModalOpen(true);
    //   return;
    // }
    // setConfirmModalOpen(false);
    let convertData: any = {};
    convertData = {
      'id.staffCode': staffCode,
      personalRole: staff.personalRole || '',
      shopRole: staff.shopRole || '',
      hqRole: staff.hqRole || '',
      createUser: sessionStorage.getItem(SessionStorageKey.LoginUser.STAFF_NAME) || '',
      updateUser: sessionStorage.getItem(SessionStorageKey.LoginUser.STAFF_NAME) || '',
    };

    if (files) {
      convertData = {
        ...convertData,
        nameplate: '1.jpg',
        photo1: files,
      };
    }

    //
    try {
      const response = await postStaffPhoto(convertData);
      setIsLoading(false);
      if (!response.errors) {
        // ログインユーザー自身の顔写真を登録した場合はログイン情報の顔写真ファイルパスを更新
        if (staffCode === sessionStorage.getItem(SessionStorageKey.LoginUser.STAFF_CODE)) {
          const photoPath = photoPathByStaffCode(staffCode, '1.jpg');
          sessionStorage.setItem(SessionStorageKey.LoginUser.PHOTO_PATH, photoPath);
        }
        successNotification('登録しました。');
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        const err = error.response.data.message.replace(/<br>/gi, '\n');
        errorNotification(err);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  useEffect(() => {
    if (staffCode) {
      getSpecificStaff(staffCode).then((response: any) => {
        setStaff(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffCode]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    errorNotification,
    handleSubmit,
    companyCode,
    staff,
    orgCode,
    setOrgCode,
    orgTreesOptions,
    staffCode,
    setStaffCode,
    staffsForOrgCodeOptions,
    confirmModalOpen,
    closeConfirmModal,
    isLoading,
    setIsLoading,
    disableButtonCap,
    setDisableButtonCap,
  };
};


export default useMasterShopFaceRegistrationForm;
