
import React from 'react';
import DiscoutTypeImportForm from 'components/organismos/master/sales/DiscountTypeForm/DiscoutTypeImportForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const DiscountTypeImportPage = () => (
  <SidebarTemplate pageTitle="値引・割引種別インポート">
    <DiscoutTypeImportForm />
  </SidebarTemplate>
);

export default DiscountTypeImportPage;
