/** @jsx jsx */
import LableTitle from 'components/atoms/LableTitle';
import PaidGrantPatternDomain from 'domain/master/labor/paidGrantPattern';
import React, { useMemo, useState, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import PageTitle from 'components/atoms/PageTitle';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import { deleteById } from 'api/paidGrantPattern';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import useToastNotification from 'hooks/useToastNotification';
import usePaidGrantList from './hooks';

const PaidGrantPatternPage: React.FC<{
  roleScreen?: any
}> = ({ roleScreen }) => {
  const { paintGrant, setpaintGrant } = usePaidGrantList();

  const [confirmModel, setConfirmModel] = useState(false);
  const [selectedPaidGrant, setselectedPaidGrant] = useState(
    PaidGrantPatternDomain.generateInitial(),
  );
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModel = useCallback(
    () => {
      setConfirmModel(false);
    },
    [],
  );
  const openConfirmModal = useCallback(
    (a:PaidGrantPatternDomain) => {
      setConfirmModel(true);
      setselectedPaidGrant(a);
    },
    [],
  );

  const deletePaidGrantpattern = useCallback(
    () => {
      const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
      deleteById(companyCode, selectedPaidGrant.paidHolidayPatternId).then((Response: any) => {
        setpaintGrant(paintGrant.filter((a) => a.paidHolidayPatternId
                                              !== selectedPaidGrant.paidHolidayPatternId));
        setConfirmModel(false);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModel(false);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = '';
          error.response.data.errors.map((item: { defaultMessage: string; }) => {
            msgError += `${item.defaultMessage} \n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    },
    [errorNotification, paintGrant, selectedPaidGrant.paidHolidayPatternId,
      setpaintGrant, successNotification],
  );
  const columns: Array<Column<PaidGrantPatternDomain>> = useMemo(() => [
    {
      Header: 'コード',
      accessor: 'paidHolidayPatternCode',
      sortType: 'basic'
    },
    {
      Header: '有給パターン名',
      accessor: 'paidHolidayPatternName',
    },
    {
      Header: '付与開始勤務月数',
      accessor: 'grantStartMonthsFull',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: PaidGrantPatternDomain },
        data: Array<PaidGrantPatternDomain>
      }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/masterPaidHolidayPattern/edit/${cell.row.original.paidHolidayPatternId}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            {roleScreen && roleScreen.editable === 1 && (
              <React.Fragment>
                <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                  <span
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                    onMouseEnter={() => setIsHoverDelete(true)}
                    onMouseLeave={() => setIsHoverDelete(false)}
                  >
                    <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </Link>
              </React.Fragment>
            )}
          </div>
        );
      },
    },
  ], [openConfirmModal, roleScreen]);

  return (
    <React.Fragment>
      <div>
        <LableTitle title="自動有給付与機能を使用する際に有給を付与する条件として利用できるようになります。" />
        {
          roleScreen && roleScreen.editable === 1 && (
            <PageTitle
              title=""
              rightContents={(
                <Link to="/masterPaidHolidayPattern/add">
                  <PrimaryButton ghost={false} text="新規有給パターン作成" />
                </Link>
            )}
        />
          )
        }
        <div
          css={css`
            table th:nth-child(1) {
              width: 100px;
            }
            table th:nth-child(2) {
              width: 200px;
            }
            table th:nth-child(3) {
              width: 120px;
            }
            table th:nth-child(4) {
              width: 80px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTable 
            columns={columns} 
            data={paintGrant} 
            sortBy={[
              { id: 'paidHolidayPatternCode', desc: false }
            ]}
            />
          </div>
        </div>
        <ConfirmActionModal
          open={confirmModel}
          closeHandler={closeConfirmModel}
          onSubmit={deletePaidGrantpattern}
          actionType={ActionType.DELETE}
        />
      </div>
    </React.Fragment>
  );
};

export default PaidGrantPatternPage;
