import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import OrganizationCsvUploadForm from 'components/organismos/organization/OrganizationCsvUploadForm';

const OrganizationCsvUploadPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="組織インポート"
  >
    <OrganizationCsvUploadForm />
  </SidebarTemplate>
);

export default OrganizationCsvUploadPage;
