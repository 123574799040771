/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { EventProps } from 'react-big-calendar';

const style = css({
  textAlign: 'center',
  fontSize: '12px',
});

const MonthEvent: React.FC = (props) => {
  const { title } = props as EventProps;
  return (
    <div css={style}>
      {title}
    </div>
  );
};

export default MonthEvent;
