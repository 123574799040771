import React, { SVGProps } from 'react';

const Delete: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M13 2.875c.345 0 .625.28.625.625v1.375H16c.314 0 .574.231.618.533l.007.092c0 .345-.28.625-.625.625h-.625V16.5c0 .345-.28.625-.625.625h-9.5a.625.625 0 01-.625-.625V6.125H4a.625.625 0 01-.618-.533L3.375 5.5c0-.345.28-.625.625-.625h2.375V3.5c0-.345.28-.625.625-.625h6zm1.125 3.25h-8.25v9.75h8.25v-9.75zM10 7.375c.345 0 .625.28.625.625v6a.625.625 0 11-1.25 0V8c0-.345.28-.625.625-.625zm-2.25 1c.345 0 .625.28.625.625v4a.625.625 0 11-1.25 0V9c0-.345.28-.625.625-.625zm4.5 0c.345 0 .625.28.625.625v4a.625.625 0 11-1.25 0V9c0-.345.28-.625.625-.625zm.125-4.25h-4.75v.75h4.75v-.75z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Delete;
