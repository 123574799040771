import React from 'react';
import PersonalSettingForm from 'components/organismos/personalSetting/PersonalSettingForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const PersonalSetting = () => (
  <SidebarTemplate pageTitle="マイページ">
    <PersonalSettingForm />
  </SidebarTemplate>
);

export default PersonalSetting;
