/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Checkbox from 'components/molecules/Checkbox';
import BodyText from 'components/atoms/BodyText';
import DatePicker from 'components/molecules/DatePicker';

const ManagerFilter: React.FC<{
  noManager: boolean;
  toggleNoManager: () => void;
  unsetTargetDate: Date;
  setUnsetTargetDate: (date: Date) => void;
}> = ({
  noManager, toggleNoManager, unsetTargetDate, setUnsetTargetDate,
}) => (
  <FlexBox
    customStyle={css({
      padding: '24px 8px 8px 8px',
    })}
  >
    <FlexBoxItem>
      <BodyText>マネージャー設定</BodyText>
    </FlexBoxItem>
    <FlexBoxItem marginLeft="24px" customStyle={css({ paddingTop: '16px' })}>
      <Checkbox
        id="noManager"
        name="noManager"
        value="noManager"
        label="未設定期間あり"
        checked={noManager}
        onChange={toggleNoManager}
      />
    </FlexBoxItem>
    <FlexBoxItem marginLeft="20px">
      <DatePicker
        date={unsetTargetDate}
        changeDate={(date: Date) => {
          if (date !== null) {
            setUnsetTargetDate(date);
          }
        }}
        disabled={!noManager}
      />
    </FlexBoxItem>
  </FlexBox>
);

export default ManagerFilter;
