import SidebarTemplate from 'components/templates/SidebarTemplate';
import DaySeparateSalesStoreManagement from 'components/organismos/master/general/salesPage/daySeparateSalesStoreManagement';
import React from 'react';

const DaySeparateSalesStoreManagementPage = () => (
  <SidebarTemplate pageTitle="日別売上管理表">
    <DaySeparateSalesStoreManagement />
  </SidebarTemplate>
);
export default DaySeparateSalesStoreManagementPage;
