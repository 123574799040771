/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { textFontSize } from 'components/styles';

const styles = {
  title: css({
    padding: '4px 0',
    margin: '0',
    fontSize: textFontSize.re,
  }),
};

const FormSubTitle: React.FC<{
  title: string;
}> = ({ title }) => (
  <h5 css={styles.title}>
    {title}
  </h5>
);

export default FormSubTitle;
