import AttendStampForm from 'components/organismos/master/attend/AttentStampForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';

const AttendStampList = () => {
  const attendFlag = sessionStorage.getItem('attendStampList.attendFlag') || '0';
  return (
    <SidebarTemplate pageTitle={attendFlag === '0' ? '打刻データ確認' : '打刻データ確認'}>
      <AttendStampForm />
    </SidebarTemplate>
  );
};

export default AttendStampList;
