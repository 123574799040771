export const types = {
  SET_IS_AUTH: 'auth/SET_IS_AUTH' as const,
  SET_ROLE_SCREEN: 'auth/SET_ROLE_SCREEN' as const,
};

export const setIsAuth = (payload: boolean) => ({
  type: types.SET_IS_AUTH,
  payload,
});

export const setRoleScreen = (payload: Object) => ({
  type: types.SET_ROLE_SCREEN,
  payload,
});

type Actions = (
    | ReturnType<typeof setIsAuth>
    | ReturnType<typeof setRoleScreen>
    );

export default Actions;
