/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import BlockUi from 'react-block-ui';
import PulseLoader from 'react-spinners/PulseLoader';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';

interface BlockUiProps {
  blocking: boolean;
}

const BlockUI: React.FC<BlockUiProps> = ({
  children, blocking,
}) => (
  <BlockUi
    tag="div"
    blocking={blocking}
    // message={message}
    loader={(
      <PulseLoader
        color="#3145D1"
        loading={blocking}
      />
)}
  >
    {children}
  </BlockUi>
);

export default BlockUI;
