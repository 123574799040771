import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { Container, Row, Col } from 'react-grid-system';
import { css } from '@emotion/core';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import BodyText from 'components/atoms/BodyText';
import TabControl from 'components/atoms/TabControl';
import BlockUI from 'components/molecules/BlockUi';
import {
  useGetClosingList, useGetEmployments,
  eventScroll, useGetTimeList, useGetPaidHoliday,
} from './hooks';
import ToastModal from '../../../ToastModal';
import DatePickerForm from '../../../../molecules/DatePickerForm';
import TimeTable from './TimeTable';
import DayTable from './DayTable';
import PaidHolidayStatus from './PaidHolidayStatus';


type SelectTab = 'tab_time_table'|'tab_day_table'|'tab_paid_table';
const SelectTab: {[key in SelectTab]: SelectTab} = {
  tab_time_table: 'tab_time_table',
  tab_day_table: 'tab_day_table',
  tab_paid_table: 'tab_paid_table',
};

const AttendMonthlyList: React.FC = () => {
  const history = useHistory();
  const [toastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [selectTab, setSelectTab] = React.useState<SelectTab>(SelectTab.tab_time_table);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem('headquartersFinal.monthlyList.searchTargetDateTo')
    || !sessionStorage.getItem('headquartersFinal.monthlyList.searchTargetDateTo')
    || !sessionStorage.getItem('headquartersFinal.monthlyList.selectOrgCode')) {
      history.push('/dashboard');
    }
  }, [history]);

  const [targetDateFrom, setTargetDateFrom] = useState(new Date(sessionStorage.getItem('headquartersFinal.monthlyList.searchTargetDateFrom')!));
  const [targetDateTo, setTargetDateTo] = useState(new Date(sessionStorage.getItem('headquartersFinal.monthlyList.searchTargetDateTo')!));
  const [selectedClosingDate, setSelectedClosingDate] = useState(sessionStorage.getItem('headquartersFinal.monthlyList.selClosingDate') || '-1');
  const [selectedEmploymentId, setSelectedEmploymentId] = useState(sessionStorage.getItem('headquartersFinal.monthlyList.searchEmploymentId') || '');
  const [attendFlag] = useState(sessionStorage.getItem('headquartersFinal.monthlyList.attendFlag') || '0');
  const [selectOrgCode] = useState(sessionStorage.getItem('headquartersFinal.monthlyList.selectOrgCode') || '');
  const [selectOrgName] = useState(sessionStorage.getItem('headquartersFinal.monthlyList.selectOrgName') || '');

  const { closingDateList } = useGetClosingList(targetDateFrom);
  const { employmentList } = useGetEmployments(targetDateFrom, targetDateTo, selectedClosingDate);
  const { getPaidHolidayStatusList } = useGetPaidHoliday(selectOrgCode, selectedEmploymentId, false, selectedClosingDate);

  const {
    term,
    business,
    subTotalTimeList,
    subTotalDayList,
    holidaySubtotalItem,
  } = useGetTimeList(setLoading, targetDateFrom, targetDateTo,
    selectedClosingDate, attendFlag, selectOrgCode, selectedEmploymentId);
  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <Row>
          <Col md={2}>
            <div style={{ marginTop: '10px' }}>
              <BodyText>対象期間</BodyText>
            </div>
          </Col>
          <Col md={10}>
            <FlexBox>
              <FlexBoxItem>
                <DatePickerForm
                  dateFormat="yyyy年MM月dd日"
                  label=""
                  isFullWidth={true}
                  date={targetDateFrom}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      setTargetDateFrom(date);
                    }
                  }}
                />
              </FlexBoxItem>
              <FlexBoxItem><span style={{ margin: '0 30px' }}>～</span></FlexBoxItem>
              <FlexBoxItem>
                <DatePickerForm
                  dateFormat="yyyy年MM月dd日"
                  label=""
                  isFullWidth={true}
                  date={targetDateTo}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      setTargetDateTo(date);
                    }
                  }}
                />
              </FlexBoxItem>
            </FlexBox>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: 'flex', alignItems: 'center' }} md={2}>
            <BodyText>組織名</BodyText>
          </Col>
          <Col md={10}>
            <BodyText>{selectOrgName}</BodyText>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: 'flex', alignItems: 'center' }} md={2}>
            <BodyText>締日・雇用形態</BodyText>
          </Col>
          <Col md={10}>
            <Row>
              <Col md={2}>
                <FlexBox>
                  <FlexBoxItem grow={0.5}>
                    <VerticalLabelSelectForm
                      label=""
                      name="closingDate"
                      value={selectedClosingDate}
                      setValue={(v) => {
                        setSelectedEmploymentId('');
                        setSelectedClosingDate(v);
                        sessionStorage.setItem('headquartersFinal.monthlyList.selClosingDate', v);
                      }}

                      options={[...[{ value: '-1', label: '-' }], ...closingDateList]}
                    />
                  </FlexBoxItem>
                  <span>&nbsp;日</span>
                </FlexBox>
              </Col>
              <Col md={4}>
                <VerticalLabelSelectForm
                  label=""
                  name="attendEmployment"
                  value={selectedEmploymentId}
                  setValue={(v) => setSelectedEmploymentId(v)}
                  options={[...[{ value: '', label: '-' }], ...employmentList]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ marginTop: '30px' }}>
          <TabControl
            items={[
              {
                value: SelectTab.tab_time_table,
                display: '集計時間データ',
              },
              {
                value: SelectTab.tab_day_table,
                display: '集計日数データ',
              },
              {
                value: SelectTab.tab_paid_table,
                display: '有給使用状況(現時点)',
              },
            ]}
            setValue={setSelectTab as (arg: string) => void}
            currentValue={selectTab}
            isFixed={false}
            largeWidth={true}
          />
          {selectTab === SelectTab.tab_time_table
              && (
                <div style={{ marginTop: '20px' }}>
                  <TimeTable
                    eventScroll={eventScroll}
                    subTotalTimeList={subTotalTimeList}
                    business={business || []}
                    term={term}
                    targetDateFrom={targetDateFrom}
                    targetDateTo={targetDateTo}
                    selectedEmploymentId={selectedEmploymentId}
                    selectOrgCode={selectOrgCode}
                    selectedClosingDate={selectedClosingDate}
                    attendFlag={attendFlag}
                  />
                </div>
              )}
          {selectTab === SelectTab.tab_day_table
              && (
                <div style={{ marginTop: '20px' }}>
                  <DayTable
                    eventScroll={eventScroll}
                    holidaySubtotalItem={holidaySubtotalItem}
                    subTotalDayList={subTotalDayList}
                  />
                </div>
              )}
          {selectTab === SelectTab.tab_paid_table
              && (
                <div style={{ marginTop: '20px' }}>
                  <PaidHolidayStatus getPaidHolidayStatusList={getPaidHolidayStatusList} />
                </div>
              )}
        </div>
        <Container>
          <Row>
            <FlexBox alignItems="center" customStyle={css({ width: '100%', justifyContent: 'center', marginTop: '20px' })}>
              <FlexBoxItem>
                <PrimaryButton
                  onClick={() => history.push('/attendHeadquartersFinal')}
                  ghost={false}
                  text="戻る"
                />
              </FlexBoxItem>
            </FlexBox>
          </Row>
        </Container>
        <ToastModal
          open={toastModalOpen}
          closeHandler={() => setToastModalOpen(false)}
          title={toastMessage}
        />
      </FormContents>
    </BlockUI>
  );
};

export default AttendMonthlyList;
