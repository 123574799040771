import ApiClient from './ApiClient';

export const getStampHistory = async (companyCode: string, orgCode: string,
  targetDateFrom: string, targetDateTo: string): Promise<any> => {
  const params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
  };
  const response = await ApiClient.get(`/v1/attendStamp/historyForRange/${companyCode}`, params);
  return response.data;
};

export const getGpsStampHistory = async (companyCode: string, orgCode: string, staffCode: string,
  targetDateFrom: string, targetDateTo: string, categoryAry: any): Promise<any> => {
  const params = {
    orgCode: (orgCode === 'all') ? 'all' : orgCode,
    staffCode,
    targetDateFrom,
    targetDateTo,
    functionType: 2,
    loginStaffCode: sessionStorage.getItem('loginUser.staffCode') || '',
  };
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const response = await ApiClient.get(`/v2/attendStamp/gpsHistory/${companyCode}?${appendUrl}`, params);
  return response.data;
};


export const getSearchDateClosing = async (companyCode: string): Promise<any> => {
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const response = await ApiClient.get(`/v1/employments/searchDate/${companyCode}?staffCode=${staffCode}&closeDateFlg=false&targetDate=`, {});

  return response.data;
};

export const getStampHistoryV3 = async (companyCode: string, orgCode: string,
  targetDateFrom: string, targetDateTo: string, functionType: number, categoryAry: Array<Array<string>>, loginStaffCode: string): Promise<any> => {
  const params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
    functionType,
    loginStaffCode,
  };
  let url = Object.entries(params)
    .map(
      ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
    )
    .join('&');
  if (categoryAry && categoryAry.length) {
    categoryAry.forEach((cateAry) => {
      url += `&categoryAry=${cateAry.join(',')}`;
    });
  }
  const response = await ApiClient.get(`/v3/attendStamp/historyForRange/${companyCode}?&${url}`, {});
  return response.data;
};

export default getStampHistory;
