/** @jsx jsx */
import React, { ReactNode } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { grayScale } from 'components/styles';

const styles = {
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '36px',
    border: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px',
  }),
};

/**
 * input add-on
 */
const AddOnInput: React.FC<{
  input: ReactNode;
  addOn: ReactNode;
  appended?: boolean;
  customStyle?: SerializedStyles;
}> = ({
  input, addOn, appended = true, customStyle,
}) => (
  <div css={css(styles.wrapper, customStyle)}>
    {!appended && addOn}
    {input}
    {appended && addOn}
  </div>
);

export default AddOnInput;
