import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';
import { apiGetMediaAnalysis } from 'api/monthly/mediaAnalysis';
import * as api from 'api/monthlyReport';

const useMediaAnalysisBillList = () => {
  const functionType = 1;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode'));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);
  const dateSelectFrom = moment().startOf('month').format('YYYY-MM-DD');
  const [selectedDateFrom, setSelectedDateFrom] = useState<Date>(new Date(dateSelectFrom));
  const dateSelectTo = moment().format('DD') !== '01' ? moment().add(-1, 'days').format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD');
  const [selectedDateTo, setSelectedDateTo] = useState<Date>(new Date(dateSelectTo));
  const dummyData: any = [
  ];

  const [listMediaAnalysisBill, setListMediaAnalysisBill] = useState(dummyData);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const path = 'mediaAnalysis';
    try {
      const response = await apiGetMediaAnalysis(path, String(orgCode), moment(selectedDateFrom.toString()).format('YYYY/MM/DD'), moment(selectedDateTo.toString()).format('YYYY/MM/DD'), categoryAry);

      // handle data before display
      if (response && response[0] && response[1] && response[1][0].slitListMonthlyMediaAnalysisDataList) {
        response[0].forEach((mediaAnalysis: any) => {
          let tmpsLitListMonthlyMediaAnalysisDataList: any[] = [];
          response[1][0].slitListMonthlyMediaAnalysisDataList.forEach((slitListMonthlyMediaAnalysisData: any) => {
            let tmp = {
              advertisingMediaAmount: 0,
              customerUnitPrice: 0,
              customers: 0,
              dispOrder: 0,
              guestCnt: 0,
            };
            if (mediaAnalysis.slitListMonthlyMediaAnalysisDataList?.length !== 0) {
              mediaAnalysis.slitListMonthlyMediaAnalysisDataList.forEach((mediaAnalysisData: any) => { 

                if (mediaAnalysisData.advertisingMediaMstCode === slitListMonthlyMediaAnalysisData.advertisingMediaMstCode) {
                  tmp = mediaAnalysisData;  
                }
              });
            }
            tmpsLitListMonthlyMediaAnalysisDataList.push(tmp);

          });

          mediaAnalysis.slitListMonthlyMediaAnalysisDataList = tmpsLitListMonthlyMediaAnalysisDataList;

        });

      }

      setListMediaAnalysisBill(response);
    } catch (error) {
      setListMediaAnalysisBill([]);
    }
    setLoading(false);
  }, [orgCode, selectedDateFrom, selectedDateTo, categoryAry]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    isLoading,
    listMediaAnalysisBill,
    functionType,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo
  };
};

export const useDownload = () => {
  const downloadMediaAnalysisCsv = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '媒体分析.csv');
  };

  const downloadMediaAnalysiExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '媒体分析.xlsx');
  };

  return {
    downloadMediaAnalysisCsv,
    downloadMediaAnalysiExcel,
  };
};

export default useMediaAnalysisBillList;
