/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { textColor } from 'components/styles';

const styles = {
  pageTitle: css({
    padding: '16px',
    // display: 'flex',
    // alignItems: 'center',
  }),
  title: css({
    padding: '0',
    margin: '0',
    color: textColor.main,
    fontsize: '14px',
    lineheight: '21px',
  }),

};

const LableTitle: React.FC<{
  title: string;
}> = ({
  title,
}) => (
  <section css={styles.pageTitle}>
    <div css={styles.title}>
      {title}
    </div>
  </section>
);

export default LableTitle;
