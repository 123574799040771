import ApiClient from "./ApiClient";

export const apiGetMonthlyBalaceDetailTable = async (
    orgCode: string,
    monthlyStartDate: string,
    monthlyEndDate: string,
    categoryAry: any
): Promise<any> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const params = {
        orgCode,
        monthlyStartDate,
        monthlyEndDate,
        loginStaffCode,
        functionType: 3,
    };

    let appenUrl = '';
    if(Array.isArray(categoryAry)){
        categoryAry.forEach((cateArr: any) => {
            appenUrl += `&categoryAry=${cateArr.join(',')}`;
        });
    }

    const response = await ApiClient.get(`/v1/sales/list/standard/monthly/balancedetailtable/${companyCode}?${appenUrl}`, params);
    return response.data;
};

export const downloadBalanceStatementStoreCsv = async (balanceStatementStore: any, fileName: any): Promise<void> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    await ApiClient.downloadCsvPost(`/v1/reportExportFile/csv/${companyCode}`, {}, balanceStatementStore, fileName);
};

export const downloadBalanceStatementStoreExcel = async (orgCode: string, monthlyStartDate: string, monthlyEndDate: string, paramCategoryAry: any, fileName: any): Promise<void> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

    let categoryAry = '';
    if(Array.isArray(paramCategoryAry)){
        paramCategoryAry.forEach((cateArr: any) => {
            categoryAry += `${cateArr.join(',')}`;
        });
    }

    const params = {
        categoryAry,
        orgCode,
        monthlyStartDate,
        monthlyEndDate,
        loginStaffCode,
        functionType: 3,
    };

    await ApiClient.downloadExcelPost(`/v1/sales/list/standard/monthly/balancedetailtable/excel/${companyCode}`, params, {}, fileName);
};