import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import useToastNotification from 'hooks/useToastNotification';
import { getAccountTitleMSTDetail, saveAccountTitleMSTDetail } from 'api/subjectTitleMST';
import AccountTitleSubMSTItemDomain from 'domain/master/general/accountTitleSubMSTItem';
import { useHistory } from 'react-router-dom';


export const useAccountTitleSubMSTDomainForm = (accountTitleId: string, accountTitleSubId?: string) => {
  const [detailMode, setDetailMode] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const history = useHistory();

  // modal confirm
  const [toastModalOpen, setToastModalOpen] = useState(false);

  // Modal alert
  const { successNotification, errorNotification } = useToastNotification();
  const onSubmit = async (values: AccountTitleSubMSTItemDomain) => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
    } else {
      try {
        const response = await saveAccountTitleMSTDetail(String(accountTitleId),
          String(accountTitleSubId), values.getRawData());
        if (!response.errors) {
          setConfirmModalOpen(false);
          let successMes = '登録しました。';
          if (accountTitleSubId && accountTitleSubId !== 'undefined') {
            successMes = '更新しました。';
          }
          successNotification(successMes);
          history.push('/accountTitleMst');
        }
      } catch (error) {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.length) {
          const listErr = error.response.data;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage} \n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    }
    return false;
  };

  const formik = useFormik({
    initialValues: AccountTitleSubMSTItemDomain.generateInitial(),
    validationSchema: false,
    onSubmit,
  });

  useEffect(() => {
    if (accountTitleSubId && accountTitleSubId !== 'undefined') {
      getAccountTitleMSTDetail(accountTitleSubId).then((response: any) => {
        if (response) {
          formik.setValues(new AccountTitleSubMSTItemDomain(response));
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountTitleSubId]);


  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    setToastModalOpen,
  };
};

export default {
  useAccountTitleSubMSTDomainForm,
};
