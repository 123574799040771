/** @jsx jsx */
/* eslint-disable max-len */
import React, { Dispatch, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button, { PrimaryButton } from 'components/atoms/Button';
import DatePickerForm from 'components/molecules/DatePickerForm';
import FormContents from 'components/atoms/Form/FormContents';
import FormTitle from 'components/atoms/Form/FormTitle';
import StaffDomain from 'domain/staff';
import { TablItem } from 'components/pages/master/general/staff/StaffAddPage/StaffAddPage';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import Icon from 'components/atoms/Icon';
import productColor, { iconColor, utilityColor } from 'components/styles';
import { SalaryDataStaffDomain } from 'domain/master/general/salaryDataStaff';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import HourInput from 'components/atoms/Form/HourInput';
import BodyText from 'components/atoms/BodyText';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import ClickableLink from 'components/atoms/ClickableLink';
import { useSalaryForm } from './hooks';
import OrganizationAddModal from '../OrganizationAddModal';
import OrganizationSupportEdit from '../OrganizationAddModal/OrganizationSupportEdit';
import SalaryBusinessForm from './SalaryBusinessForm';
import { InfoMessage } from 'utility/messages';
import { css, jsx } from '@emotion/core';
import IconButton from 'components/molecules/IconButton';

type SelectType = 'staff'|'affiliation'|'permission'|'employment'|'laborCost'|'workPattern';
const SelectType: {[key in SelectType]: SelectType} = {
  staff: 'staff',
  affiliation: 'affiliation',
  permission: 'permission',
  employment: 'employment',
  laborCost: 'laborCost',
  workPattern: 'workPattern',
};

const SalaryInfoForm: React.FC<{
  initialStaffDomain?: StaffDomain;
  isEditMode?: boolean;
  tabItems: TablItem[];
  setInitialStaffDomain: Dispatch<React.SetStateAction<StaffDomain>>;
  setSelectType: Dispatch<React.SetStateAction<SelectType>>;
  setTabItems: Dispatch<React.SetStateAction<TablItem[]>>;
  businessOptions: Array<any>;
  setIsLoading: Dispatch<React.SetStateAction<boolean>>;
  menuType: string;
  roleScreen?: any;
}> = ({
  initialStaffDomain, isEditMode = false, tabItems,
  setInitialStaffDomain, setSelectType, setTabItems,
  businessOptions,
  setIsLoading,
  menuType,
  roleScreen
}) => {
  const {
    formik,
    confirmModalOpen, closeConfirmModal,
    openManagerAddModal,
    setOpenManagerAddModal,
    addOrganizationSupport,
    deleteOrganizationSupport,
    setOrganizationSupport,
    addSalaryBusiness,
    deleteSalaryBusiness,
    setSalaryBusiness,
    onSubmitDeleteStaffSalary,
    setDeleteTargetStaffSalary,
    isOpenDeleteModal,
    closeDeleteModal,
  } = useSalaryForm(
    initialStaffDomain,
    isEditMode,
    setIsLoading,
    menuType,
  );


  const columns: Array<Column<SalaryDataStaffDomain>> = React.useMemo(() => [
    {
      Header: '給与適用日',
      accessor: 'applyStartDateStr',
    },
    {
      Header: '給与体系',
      accessor: 'salaryPaymentStr',
    },
    {
      Header: '給与',
      accessor: 'dispSalaryStr',
    },
    {
      Header: '日額交通費',
      accessor: 'dailyTransportation',
    },
    {
      Header: '月額交通費',
      accessor: 'monthlyTransportation',
    },
    {
      Header: () => null,
      accessor: 'x',
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: SalaryDataStaffDomain }}) => {
        return (
          <IconButton
            iconType="edit"
            onClick={() => formik.setFieldValue('selectSalary', new SalaryDataStaffDomain({ ...cell.row.original.getRawData() }))}
            iconColor={iconColor.gray}
            iconHoverColor={iconColor.gray}
          />
        );
      },
    },
    {
      Header: () => null,
      accessor: 'delete',
      id: 'deleteButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: SalaryDataStaffDomain, index: number }}) => {
        return (<React.Fragment>
            {(cell.row.index === 0 && roleScreen && roleScreen.editable === 1) && (
              <IconButton
                iconType="delete"
                onClick={() => setDeleteTargetStaffSalary(cell.row.original)}
                iconColor={iconColor.red}
                iconHoverColor={utilityColor.white}
                linkHoverColor={productColor.deleteActionHover}
              />
            )}
          </React.Fragment>);
      },
    },

  ], [formik, roleScreen]);

  const overTimeHoursFormat = Math.floor(formik.values.selectSalary.fixedOvertimeHours / 60)

  const overTimeMinutesFormat = formik.values.selectSalary.fixedOvertimeHours % 60;

  const [overTimeMinutes, setOverTimeMinutes] = useState(overTimeMinutesFormat || 0);

  const [overTimeHours, setOverTimeHours] = useState(overTimeHoursFormat || 0);

  useEffect (() => {
    var hours = Number(overTimeHours) * 60;
    var minutes = Number(hours) + Number(overTimeMinutes)
    formik.setFieldValue('selectSalary.fixedOvertimeHours', minutes);
  }, [overTimeMinutes, overTimeHours])

  useEffect(() => {
    const overTimeHoursFormat = Math.floor(formik.values.selectSalary.fixedOvertimeHours / 60)
    const overTimeMinutesFormat = formik.values.selectSalary.fixedOvertimeHours % 60;
    setOverTimeHours(overTimeHoursFormat || 0)
    setOverTimeMinutes(overTimeMinutesFormat || 0)
  }, [formik.values.selectSalary.applyStartDate])

  console.log('overTimeHours', formik.values.selectSalary.fixedOvertimeHours);

  console.log('renderSALARY', formik.values.selectSalary.applyStartDate);
  return (
    <React.Fragment>
      {formik.values.salaryList
      && formik.values.salaryList.length !== 0 && (
        <div css={css`
            table th:nth-child(6) {
              width: 42px;
            }
            table th:nth-child(7) {
              width: 42px;
            }
          `}
        >
          <DataTable
            columns={columns}
            data={formik.values.salaryDomainList}
            sortBy={[
              {id: 'applyStartDateStr', desc: true}
            ]}
          />
        </div>
      )}

      <form onSubmit={formik.handleSubmit}>
        <FormContents>
          <FormField>
            <DatePickerForm
              dateFormat="yyyy/MM/dd"
              label="給与適用日"
              date={formik.values.selectSalary.applyStartDate}
              changeDate={(date: Date) => {
                if (date !== null) {
                  formik.setFieldValue('selectSalary.applyStartDate', date);
                }
              }}
              required={true}
              disable={!isEditMode}
              isShowDateOnDisable={true}
            />
          </FormField>

          <FormField>
            <RadioSelectForm
              label="給与体系"
              required={true}
              items={[
                {
                  label: '設定なし',
                  value: '0',
                },
                {
                  label: '時給',
                  value: '1',
                },
                {
                  label: '月給',
                  value: '2',
                },
                {
                  label: '日給',
                  value: '3',
                },
              ]}
              name="selectSalary.salaryPayment"
              value={String(formik.values.selectSalary.salaryPayment)}
              setValue={(e) => formik.setFieldValue('selectSalary.salaryPayment', Number(e.target.value))}
            />
          </FormField>
          {formik.values.selectSalary.salaryPayment === 1 && (
          <FormContents>
            <FlexBox>
              <FlexBoxItem basis="130px">
                <BodyText> 時給 </BodyText>
              </FlexBoxItem>
              <FlexBoxItem basis="200px">
                <HourInput
                  name="selectSalary.hourlySalary"
                  value={formik.values.selectSalary.hourlySalary || 0}
                  label="円"
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  isFullW={false}
                  min={0}
                  max={99999999}
                  maxLength={9}
                />
              </FlexBoxItem>
            </FlexBox>
          </FormContents>
          )}

          {formik.values.selectSalary.salaryPayment === 2 && (
          <FormContents>
            <FlexBox>
              <FlexBoxItem basis="130px">
                <BodyText> 月給 </BodyText>
              </FlexBoxItem>
              <FlexBoxItem basis="200px">
                <HourInput
                  name="selectSalary.monthlySalary"
                  value={formik.values.selectSalary.monthlySalary || 0}
                  label="円"
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  min={0}
                  max={99999999}
                  maxLength={9}
                />
              </FlexBoxItem>
            </FlexBox>

            <FlexBox>
              <FlexBoxItem basis="130px">
                <BodyText> 基礎賃金 </BodyText>
              </FlexBoxItem>

              <FlexBoxItem basis="200px">
                <HourInput
                  name="selectSalary.monthlyHourlySalary"
                  value={formik.values.selectSalary.monthlyHourlySalary || 0}
                  label="円／時間"
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  min={0}
                  max={99999999}
                  maxLength={9}
                />
              </FlexBoxItem>
            </FlexBox>

            <FlexBox>
              <FlexBoxItem basis="130px">
                <BodyText> 固定残業時間 </BodyText>
              </FlexBoxItem>

              <FlexBoxItem basis="200px">
                <HourInput
                  name="overTimeHours"
                  value={overTimeHours || 0}
                  label="時間"
                  onChange={(e: any) => {
                    if (e.target.value <= 999999999 && e.target.value >= 0 && e.target.value.length <= 9) {
                      setOverTimeHours(e.target.value)
                    }
                  }}
                  min={0}
                  max={999999999}
                  maxLength={9}
                />
              </FlexBoxItem>
              <FlexBoxItem basis="200px">
                <HourInput
                  name="overTimeMinutes"
                  value={overTimeMinutes || 0}
                  label="分"
                  onChange={(e: any) => {
                    if (e.target.value <= 59 && e.target.value >= 0 && e.target.value.length <= 2) {
                      setOverTimeMinutes(e.target.value)
                    }
                  }}
                  min={0}
                  max={59}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <BodyText color={utilityColor.error} size="sm">※勤怠確定のCSV出力項目になります。人件費の計算には使用されません。</BodyText>
              </FlexBoxItem>
            </FlexBox>
          </FormContents>
          )}

          {formik.values.selectSalary.salaryPayment === 3 && (
          <FormContents>
            <FlexBox>
              <FlexBoxItem basis="130px">
                <BodyText> 日給 </BodyText>
              </FlexBoxItem>
              <FlexBoxItem basis="200px">
                <HourInput
                  name="selectSalary.dailySalary"
                  value={formik.values.selectSalary.dailySalary || 0}
                  label="円"
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  min={0}
                  max={99999999}
                  maxLength={9}
                />
              </FlexBoxItem>
            </FlexBox>

            <FlexBox>
              <FlexBoxItem basis="130px">
                <BodyText> 基礎賃金 </BodyText>
              </FlexBoxItem>

              <FlexBoxItem basis="200px">
                <HourInput
                  name="selectSalary.dailyHourlySalary"
                  value={formik.values.selectSalary.dailyHourlySalary || 0}
                  label="円／時間"
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  min={0}
                  max={99999999}
                  maxLength={9}
                />
              </FlexBoxItem>
            </FlexBox>
          </FormContents>
          )}

          <FormField>
            <RadioSelectForm
              label="交通費区分"
              required={true}
              items={[
                {
                  label: '設定なし',
                  value: '0',
                },
                {
                  label: '日額',
                  value: '1',
                },
                {
                  label: '月額',
                  value: '2',
                },
              ]}
              name="selectSalary.transportationFlag"
              value={String(formik.values.selectSalary.transportationFlag)}
              setValue={(e) => formik.setFieldValue('selectSalary.transportationFlag', Number(e.target.value))}
            />
          </FormField>

          {formik.values.selectSalary.transportationFlag === 1 && (
          <FormContents>
            <FlexBox>
              <FlexBoxItem basis="130px">
                <BodyText> 交通費 </BodyText>
              </FlexBoxItem>
              <FlexBoxItem basis="200px">
                <HourInput
                  name="selectSalary.dailyTransportation"
                  value={formik.values.selectSalary.dailyTransportation || 0}
                  label="円"
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  min={0}
                  max={99999999}
                  maxLength={9}
                />
              </FlexBoxItem>
            </FlexBox>

            <FlexBox>
              <FlexBoxItem basis="130px">
                <BodyText> 交通費上限額 </BodyText>
              </FlexBoxItem>

              <FlexBoxItem basis="200px">
                <HourInput
                  name="selectSalary.limitTransportation"
                  value={formik.values.selectSalary.limitTransportation || 0}
                  label="円"
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  min={0}
                  max={99999999}
                  maxLength={9}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <BodyText color={utilityColor.error} size="sm">※上限を設定しない場合は「0」を入力してください。</BodyText>
              </FlexBoxItem>
            </FlexBox>
          </FormContents>
          )}

          {formik.values.selectSalary.transportationFlag === 2 && (
          <FormContents>
            <FlexBox>
              <FlexBoxItem basis="130px">
                <BodyText> 交通費 </BodyText>
              </FlexBoxItem>
              <FlexBoxItem basis="200px">
                <HourInput
                  name="selectSalary.monthlyTransportation"
                  value={formik.values.selectSalary.monthlyTransportation || 0}
                  label="円"
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  min={0}
                  max={99999999}
                  maxLength={9}
                />
              </FlexBoxItem>
            </FlexBox>

            <FlexBox>
              <FlexBoxItem basis="130px">
                <BodyText> 交通費上限額 </BodyText>
              </FlexBoxItem>

              <FlexBoxItem basis="200px">
                <HourInput
                  name="selectSalary.limitTransportation"
                  value={formik.values.selectSalary.limitTransportation || 0}
                  label="円"
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  min={0}
                  max={99999999}
                  maxLength={9}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <BodyText color={utilityColor.error} size="sm">※上限を設定しない場合は「0」を入力してください。</BodyText>
              </FlexBoxItem>

            </FlexBox>
          </FormContents>
          )}

        </FormContents>


        <FormContents>
          <FormTitle title="支援組織の設定" />
          {formik.values.selectSalary.salarySupportDataList
            ? formik.values.selectSalary.salarySupportDataList.map((support, index) => (
              <OrganizationSupportEdit
                key={index.toString()}
                isShowHourlyWageAddition={formik.values.selectSalary.salaryPayment === 1}
                support={support}
                deleteSupport={deleteOrganizationSupport(index)}
                setSupport={setOrganizationSupport(index)}
              />
            )) : <BodyText>Loading...</BodyText>}

          <div>
            <PrimaryButton
              text="支援組織追加"
              onClick={() => { setOpenManagerAddModal(true); }}
              fullwidth={true}
            />
          </div>
          <FormField>
            <OrganizationAddModal
              open={openManagerAddModal}
              openHandler={setOpenManagerAddModal}
              addOrganization={addOrganizationSupport}
            />
          </FormField>

        </FormContents>

        <FormContents>
          {formik.values.selectSalary.salaryPayment === 1 && formik.values.selectSalary.salaryBusinessList
            ? formik.values.selectSalary.salaryBusinessList.map((manager, index) => (
              <SalaryBusinessForm
                businessOptions={businessOptions}
                key={index.toString()}
                salaryBusiness={manager}
                setSalaryBusiness={setSalaryBusiness(index)}
                deleteSalaryBusiness={deleteSalaryBusiness(index)}
              />
            )) : ''}

          {formik.values.selectSalary.salaryPayment === 1 && (
          <PrimaryButton
            text="業務加算額追加"
            onClick={addSalaryBusiness}
            fullwidth={true}
          />
          )}
        </FormContents>


        <FormSubmitArea>
          {roleScreen && roleScreen.editable === 1 && (
            <div style={{ marginRight: '12px' }}>
              <Button
                text={isEditMode ? '人件費情報を登録' : '登録'}
                onClick={formik.handleSubmit}
              />
            </div>
          )}
          {roleScreen && roleScreen.editable === 1 && !isEditMode && (
            <div style={{ marginRight: '12px' }}>
              <Button
                text="シフトパターン設定の入力へ"
                onClick={() => {
                  if (formik.values.orgApplyDate && formik.values.orgCode) {
                    if (tabItems.length <= 5) {
                      setInitialStaffDomain(formik.values);
                      setTabItems([
                        {
                          value: SelectType.staff,
                          display: 'スタッフ情報',
                        },
                        {
                          value: SelectType.affiliation,
                          display: '所属設定',
                        },
                        {
                          value: SelectType.permission,
                          display: '権限設定',
                        },
                        {
                          value: SelectType.employment,
                          display: '雇用設定',
                        },
                        {
                          value: SelectType.laborCost,
                          display: '人件費設定',
                        },
                        {
                          value: SelectType.workPattern,
                          display: 'シフトパターン設定',
                        }]);
                    }
                    setSelectType(SelectType.workPattern);
                  } else {
                    formik.validateForm();
                  }
                }}
              />
            </div>
          )}

          {menuType !== '1' && (
            <Link to="/staffs">
              <Button
                text="スタッフ一覧に戻る"
                ghost={true}
              />
            </Link>
          )}
          {menuType === '1' && (
            <Link to="/staffs/employee">
              <Button
                text="スタッフ一覧に戻る"
                ghost={true}
              />
            </Link>
          )}
        </FormSubmitArea>
      </form>

      {/* 登録内容確認モーダル */}
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
        message={isEditMode ? InfoMessage.StaffMaster.UPDATE_CONFIRM_MESSAGE_SALARY_INFO : ''}
      />

      <ConfirmActionModal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        onSubmit={onSubmitDeleteStaffSalary}
        actionType={ActionType.DELETE}
        message={InfoMessage.StaffMaster.DELETE_CONFIRM_MESSAGE_SALARY_INFO}
      />
    </React.Fragment>
  );
};

export default SalaryInfoForm;
