import React from 'react';
import Modal from 'components/molecules/Modal';
import PanelDescription from 'components/atoms/PanelDescription';
import { number } from 'yup';

export enum ActionType {
  CREATE = 0,
  UPDATE = 1,
  DELETE = 2,
  IMPORT = 3,
}
/**
 * Confirm action for add, delete, update
 */
const ConfirmActionModal: React.FC<{
  open: boolean
  closeHandler: () => void
  onSubmit: () => void
  actionType: ActionType
  message?: string
}> = ({
  open, closeHandler, onSubmit, actionType, message
}) => {
  let content = '';
  let submitText = '';
  const title = '確認メッセージ';
  if (actionType === ActionType.CREATE) {
    content = message || '登録します。よろしいですか？';
    submitText = '登録';
  } else if (actionType === ActionType.DELETE) {
    content =  message || '削除します。よろしいですか？';
    submitText = '削除';
  } else if (actionType === ActionType.UPDATE) {
    content =  message || '更新します。よろしいですか？';
    submitText = '更新';
  } else if (actionType === ActionType.IMPORT) {
    content =  message || 'ファイルをアップロードします。よろしいですか？';
    submitText = 'OK';
  }
  return (
    <Modal
      open={open}
      closeHandler={closeHandler}
      title={title}
      submitText={submitText}
      onSubmit={onSubmit}
    >
      <PanelDescription>
        {content}
      </PanelDescription>
    </Modal>
  );
};

export default ConfirmActionModal;
