import { AxiosResponse } from 'axios';
import ApiClient from 'api/ApiClient';

export const registerDeposit = async (params: any): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.postJsonData(`/v1/bank/deposit/${companyCode}`, {}, params);
  return response;
};

export const deleteDeposit = async (params: any): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.deleteJsonData(`/v1/bank/deposit/${companyCode}`, {}, params);
  return response;
};
