/* eslint-disable max-len */

export interface Vacation {
  createUser: string,
  updateUser: string,
  holidayId: string,
  holidayCode: string,
  holidayName: string,
  holidayType: number;
  holidayBgColor: string;
  holidayForecolor: string;
  paidCalculationStandard: boolean;
  salaryCalculation: number;
  unitPricePeriod: number;
  unitPriceTransportation: boolean;
  unitPriceRounding: number;
  halfHoliday: boolean;
  halfHolidayDeemedWorkingHours: number;
  timeHoliday: boolean;
  timeHolidayMaxHour: number;
  timeHolidayHourOfDay: number;
  conditionGrantSubstituteHoliday: number;
  conditionGrantSubstituteHolidayHours: number;
  conditionGrantSubstituteHolidayMinutes: number;
  validityPeriod: number;
  deemedWorkingHours: number;
  deemedWorkingMinutes: number;
  useEmploymentDeemedWorkingHours: boolean;
  // isWorkTime: number;
  unUsableOfTrialPeriod: boolean;
  autoGrantHoliday: boolean;
  autoGrantDateMonth: number;
  autoGrantDateSpecialHolidayDay: number;
  autoGrantDateDay: number;
  autoGrantHolidayType: number;
  autoGrantDay: number | '';
  autoGrantWorkingStartMonths1: number | '';
  autoGrantWorkingEndMonths1: number | '';
  autoGrantWorkingDay1: number | '';
  autoGrantWorkingStartMonths2: number | '';
  autoGrantWorkingEndMonths2: number | '';
  autoGrantWorkingDay2: number | '';
  autoGrantWorkingStartMonths3: number | '';
  autoGrantWorkingEndMonths3: number | '';
  autoGrantWorkingDay3: number | '';
  includingTrialPeriod: boolean;
  autoValidityType: number;
  autoValidityPeriod: number;
  enteringGrantHoliday: boolean;
  enteringGrantDay1: number | '';
  enteringGrantDay2: number | '';
  enteringGrantDay3: number | '';
  enteringValidityType: number;
  enteringValidityPeriod: number;
  paidHalfHolidayStartTime: string ;
  paidHalfHolidayEndTime: string ;
  isPaidHalfHolidayStartTimeNextDay: boolean;
  isPaidHalfHolidayEndTimeNextDay: boolean;
  noticeStaffs: Array<any>;
  grantStaffNum: number;
  grantDate: Date | null;
  // from cydas
  validityPeriodStartMonth: number;
  validityPeriodStartDay: number;
  validityPeriodEndMonth: number;
  validityPeriodEndDay: number;
    // from cydas
}

export enum HolidayType {
  PUBLIC_HOLIDAY = 0,
  PAID_1_DAY = 1,
  PAID_HALF_DAY = 6,
  HOLIDAY = 2,
  TEXIU = 4,
  ABSENCE = 5,
  CLOSED = 7,
}

export enum HolidayTypeLabel {
  '公休' = 0,
  '有給（１日）' = 1,
  '有給（半日）' = 6,
  '代休' = 2,
  '特休' = 4,
  '欠勤' = 5,
  '休業' = 7,
}

export default class VacationDomain {
  constructor(private rawData: Vacation) {
    // do nothing
  }

  static generateInitial(): VacationDomain {
    return new VacationDomain({
      createUser: '',
      updateUser: '',
      holidayId: '',
      holidayCode: '',
      holidayName: '',
      holidayType: 0,
      holidayBgColor: '#ffffff',
      holidayForecolor: '#000000',
      paidCalculationStandard: false,
      salaryCalculation: 0,
      unitPricePeriod: 0,
      unitPriceTransportation: false,
      unitPriceRounding: 0,
      halfHoliday: false,
      halfHolidayDeemedWorkingHours: 0,
      timeHoliday: false,
      timeHolidayMaxHour: 0,
      timeHolidayHourOfDay: 0,
      conditionGrantSubstituteHoliday: 0,
      conditionGrantSubstituteHolidayHours: 0,
      conditionGrantSubstituteHolidayMinutes: 0,
      validityPeriod: 0,
      deemedWorkingHours: 0,
      deemedWorkingMinutes: 0,
      useEmploymentDeemedWorkingHours: false,
      // isWorkTime: 1,
      unUsableOfTrialPeriod: false,
      autoGrantHoliday: false,
      autoGrantDateMonth: 1,
      autoGrantDateSpecialHolidayDay: 0,
      autoGrantDateDay: 1,
      autoGrantHolidayType: 0,
      autoGrantDay: '',
      autoGrantWorkingStartMonths1: '',
      autoGrantWorkingEndMonths1: '',
      autoGrantWorkingDay1: '',
      autoGrantWorkingStartMonths2: '',
      autoGrantWorkingEndMonths2: '',
      autoGrantWorkingDay2: '',
      autoGrantWorkingStartMonths3: '',
      autoGrantWorkingEndMonths3: '',
      autoGrantWorkingDay3: '',
      includingTrialPeriod: true,
      autoValidityType: 0,
      autoValidityPeriod: 0,
      enteringGrantHoliday: false,

      enteringGrantDay1: '',
      enteringGrantDay2: '',
      enteringGrantDay3: '',
      enteringValidityType: 0,
      enteringValidityPeriod: 0,
      paidHalfHolidayStartTime: '00:00',
      paidHalfHolidayEndTime: '00:00',
      isPaidHalfHolidayStartTimeNextDay: false,
      isPaidHalfHolidayEndTimeNextDay: false,
      noticeStaffs: [],
      grantStaffNum: 0,
      grantDate: null,
      validityPeriodStartMonth: 1,
      validityPeriodStartDay: 1,
      validityPeriodEndMonth: 12,
      validityPeriodEndDay: 31,
    });
  }

  getRawData(): Vacation {
    return this.rawData;
  }

  getRawDataWithoutNullData(): Vacation {
    return {
      createUser: this.rawData.createUser,
      updateUser: this.rawData.updateUser,
      holidayId: this.rawData.holidayId,
      holidayCode: this.rawData.holidayCode,
      holidayName: this.rawData.holidayName,
      holidayType: this.rawData.holidayType,
      holidayBgColor: this.rawData.holidayBgColor,
      holidayForecolor: this.rawData.holidayForecolor,
      paidCalculationStandard: this.rawData.paidCalculationStandard ? this.rawData.paidCalculationStandard : false,
      salaryCalculation: this.rawData.salaryCalculation ? this.rawData.salaryCalculation : 0,
      unitPricePeriod: this.rawData.unitPricePeriod ? this.rawData.unitPricePeriod : 0,
      unitPriceTransportation: this.rawData.unitPriceTransportation ? this.rawData.unitPriceTransportation : false,
      unitPriceRounding: this.rawData.unitPriceRounding ? this.rawData.unitPriceRounding : 0,
      halfHoliday: this.rawData.halfHoliday ? this.rawData.halfHoliday : false,
      halfHolidayDeemedWorkingHours: this.rawData.halfHolidayDeemedWorkingHours ? this.rawData.halfHolidayDeemedWorkingHours : 0,
      timeHoliday: this.rawData.timeHoliday ? this.rawData.timeHoliday : false,
      timeHolidayMaxHour: this.rawData.timeHolidayMaxHour ? this.rawData.timeHolidayMaxHour : 0,
      timeHolidayHourOfDay: this.rawData.timeHolidayHourOfDay ? this.rawData.timeHolidayHourOfDay : 0,
      conditionGrantSubstituteHoliday: this.rawData.conditionGrantSubstituteHoliday ? this.rawData.conditionGrantSubstituteHoliday : 0,
      conditionGrantSubstituteHolidayHours: this.rawData.conditionGrantSubstituteHolidayHours ? this.rawData.conditionGrantSubstituteHolidayHours : 0,
      conditionGrantSubstituteHolidayMinutes: this.rawData.conditionGrantSubstituteHolidayMinutes ? this.rawData.conditionGrantSubstituteHolidayMinutes : 0,
      validityPeriod: this.rawData.validityPeriod ? this.rawData.validityPeriod : 0,
      deemedWorkingHours: this.rawData.deemedWorkingHours ? this.rawData.deemedWorkingHours : 0,
      deemedWorkingMinutes: this.rawData.deemedWorkingMinutes ? this.rawData.deemedWorkingMinutes : 0,
      useEmploymentDeemedWorkingHours: this.rawData.useEmploymentDeemedWorkingHours ? this.rawData.useEmploymentDeemedWorkingHours : false,
      // isWorkTime: this.rawData.isWorkTime ? this.rawData.isWorkTime : 0,
      unUsableOfTrialPeriod: this.rawData.unUsableOfTrialPeriod ? this.rawData.unUsableOfTrialPeriod : false,
      autoGrantHoliday: this.rawData.autoGrantHoliday ? this.rawData.autoGrantHoliday : false,
      autoGrantDateMonth: this.rawData.autoGrantDateMonth ? this.rawData.autoGrantDateMonth : 1,
      autoGrantDateSpecialHolidayDay: this.rawData.autoGrantDateSpecialHolidayDay ? this.rawData.autoGrantDateSpecialHolidayDay : 0,
      autoGrantDateDay: this.rawData.autoGrantDateDay ? this.rawData.autoGrantDateDay : 1,
      autoGrantHolidayType: this.rawData.autoGrantHolidayType ? this.rawData.autoGrantHolidayType : 0,
      autoGrantDay: this.rawData.autoGrantDay != null ? this.rawData.autoGrantDay : '',
      autoGrantWorkingStartMonths1: this.rawData.autoGrantWorkingStartMonths1 != null ? this.rawData.autoGrantWorkingStartMonths1 : '',
      autoGrantWorkingEndMonths1: this.rawData.autoGrantWorkingEndMonths1 != null ? this.rawData.autoGrantWorkingEndMonths1 : '',
      autoGrantWorkingDay1: this.rawData.autoGrantWorkingDay1 != null ? this.rawData.autoGrantWorkingDay1 : '',
      autoGrantWorkingStartMonths2: this.rawData.autoGrantWorkingStartMonths2 != null ? this.rawData.autoGrantWorkingStartMonths2 : '',
      autoGrantWorkingEndMonths2: this.rawData.autoGrantWorkingEndMonths2 != null ? this.rawData.autoGrantWorkingEndMonths2 : '',
      autoGrantWorkingDay2: this.rawData.autoGrantWorkingDay2 != null ? this.rawData.autoGrantWorkingDay2 : '',
      autoGrantWorkingStartMonths3: this.rawData.autoGrantWorkingStartMonths3 != null ? this.rawData.autoGrantWorkingStartMonths3 : '',
      autoGrantWorkingEndMonths3: this.rawData.autoGrantWorkingEndMonths3 != null ? this.rawData.autoGrantWorkingEndMonths3 : '',
      autoGrantWorkingDay3: this.rawData.autoGrantWorkingDay3 != null ? this.rawData.autoGrantWorkingDay3 : '',
      includingTrialPeriod: this.rawData.includingTrialPeriod ? this.rawData.includingTrialPeriod : false,
      autoValidityType: this.rawData.autoValidityType ? this.rawData.autoValidityType : 0,
      autoValidityPeriod: this.rawData.autoValidityPeriod ? this.rawData.autoValidityPeriod : 0,
      enteringGrantHoliday: this.rawData.enteringGrantHoliday ? this.rawData.enteringGrantHoliday : false,
      enteringGrantDay1: this.rawData.enteringGrantDay1 != null ? this.rawData.enteringGrantDay1 : '',
      enteringGrantDay2: this.rawData.enteringGrantDay2 != null ? this.rawData.enteringGrantDay2 : '',
      enteringGrantDay3: this.rawData.enteringGrantDay3 != null ? this.rawData.enteringGrantDay3 : '',
      enteringValidityType: this.rawData.enteringValidityType ? this.rawData.enteringValidityType : 0,
      enteringValidityPeriod: this.rawData.enteringValidityPeriod ? this.rawData.enteringValidityType : 0,
      paidHalfHolidayStartTime: this.rawData.paidHalfHolidayStartTime ? this.rawData.paidHalfHolidayStartTime : '00:00',
      paidHalfHolidayEndTime: this.rawData.paidHalfHolidayEndTime ? this.rawData.paidHalfHolidayEndTime : '00:00',
      isPaidHalfHolidayStartTimeNextDay: this.rawData.isPaidHalfHolidayStartTimeNextDay ? this.rawData.isPaidHalfHolidayStartTimeNextDay : false,
      isPaidHalfHolidayEndTimeNextDay: this.rawData.isPaidHalfHolidayEndTimeNextDay ? this.rawData.isPaidHalfHolidayEndTimeNextDay : false,
      noticeStaffs: this.rawData.noticeStaffs ? this.rawData.noticeStaffs : [],
      grantStaffNum: this.rawData.grantStaffNum ? this.rawData.grantStaffNum : 0,
      grantDate: this.rawData.grantDate ? this.rawData.grantDate : null,
      validityPeriodStartMonth: this.rawData.validityPeriodStartMonth ? this.rawData.validityPeriodStartMonth : 0,
      validityPeriodStartDay: this.rawData.validityPeriodStartDay ? this.rawData.validityPeriodStartDay : 0,
      validityPeriodEndMonth: this.rawData.validityPeriodEndMonth ? this.rawData.validityPeriodEndMonth : 0,
      validityPeriodEndDay: this.rawData.validityPeriodEndDay ? this.rawData.validityPeriodEndDay : 0,
    };
  }

  getRawDataCreationTexiu(): Vacation {
    return {
      createUser: this.rawData.createUser,
      updateUser: this.rawData.updateUser,
      holidayId: this.rawData.holidayId,
      holidayCode: this.rawData.holidayCode,
      holidayName: this.rawData.holidayName,
      holidayType: this.rawData.holidayType,
      holidayBgColor: this.rawData.holidayBgColor,
      holidayForecolor: this.rawData.holidayForecolor,
      paidCalculationStandard: this.rawData.paidCalculationStandard,
      salaryCalculation: this.rawData.salaryCalculation,
      unitPricePeriod: this.rawData.unitPricePeriod,
      unitPriceTransportation: this.rawData.unitPriceTransportation,
      unitPriceRounding: this.rawData.unitPriceRounding,
      halfHoliday: this.rawData.halfHoliday,
      halfHolidayDeemedWorkingHours: this.rawData.halfHolidayDeemedWorkingHours,
      timeHoliday: this.rawData.timeHoliday,
      timeHolidayMaxHour: this.rawData.timeHolidayMaxHour,
      timeHolidayHourOfDay: this.rawData.timeHolidayHourOfDay,
      conditionGrantSubstituteHoliday: this.rawData.conditionGrantSubstituteHoliday,
      conditionGrantSubstituteHolidayHours: this.rawData.conditionGrantSubstituteHolidayHours,
      conditionGrantSubstituteHolidayMinutes: this.rawData.conditionGrantSubstituteHolidayMinutes,
      validityPeriod: this.rawData.validityPeriod,
      deemedWorkingHours: this.rawData.deemedWorkingHours,
      deemedWorkingMinutes: this.rawData.deemedWorkingMinutes,
      useEmploymentDeemedWorkingHours: this.rawData.useEmploymentDeemedWorkingHours,
      // isWorkTime: this.rawData.isWorkTime,
      unUsableOfTrialPeriod: this.rawData.unUsableOfTrialPeriod,
      autoGrantHoliday: this.rawData.autoGrantHoliday,
      autoGrantDateMonth: this.rawData.autoGrantDateMonth ? this.rawData.autoGrantDateMonth : 1,
      autoGrantDateSpecialHolidayDay: this.rawData.autoGrantDateSpecialHolidayDay,
      autoGrantDateDay: this.rawData.autoGrantDateDay ? this.rawData.autoGrantDateDay : 1,
      autoGrantHolidayType: this.rawData.autoGrantHolidayType,
      autoGrantDay: this.rawData.autoGrantDay !== '' ? this.rawData.autoGrantDay : 0,
      autoGrantWorkingStartMonths1: this.rawData.autoGrantWorkingStartMonths1,
      autoGrantWorkingEndMonths1: this.rawData.autoGrantWorkingEndMonths1,
      autoGrantWorkingDay1: this.rawData.autoGrantWorkingDay1,
      autoGrantWorkingStartMonths2: this.rawData.autoGrantWorkingStartMonths2,
      autoGrantWorkingEndMonths2: this.rawData.autoGrantWorkingEndMonths2,
      autoGrantWorkingDay2: this.rawData.autoGrantWorkingDay2,
      autoGrantWorkingStartMonths3: this.rawData.autoGrantWorkingStartMonths3,
      autoGrantWorkingEndMonths3: this.rawData.autoGrantWorkingEndMonths3,
      autoGrantWorkingDay3: this.rawData.autoGrantWorkingDay3,
      includingTrialPeriod: this.rawData.includingTrialPeriod,
      autoValidityType: this.rawData.autoValidityType,
      autoValidityPeriod: this.rawData.autoValidityPeriod,
      enteringGrantHoliday: this.rawData.enteringGrantHoliday,
      enteringGrantDay1: this.rawData.enteringGrantDay1 !== '' ? this.rawData.enteringGrantDay1 : 0,
      enteringGrantDay2: this.rawData.enteringGrantDay2 !== '' ? this.rawData.enteringGrantDay2 : 0,
      enteringGrantDay3: this.rawData.enteringGrantDay3 !== '' ? this.rawData.enteringGrantDay3 : 0,
      enteringValidityType: this.rawData.enteringValidityType,
      enteringValidityPeriod: this.rawData.enteringValidityPeriod,
      paidHalfHolidayStartTime: this.rawData.paidHalfHolidayStartTime,
      paidHalfHolidayEndTime: this.rawData.paidHalfHolidayEndTime,
      isPaidHalfHolidayStartTimeNextDay: this.rawData.isPaidHalfHolidayStartTimeNextDay,
      isPaidHalfHolidayEndTimeNextDay: this.rawData.isPaidHalfHolidayEndTimeNextDay,
      noticeStaffs: this.rawData.noticeStaffs,
      grantStaffNum: this.rawData.grantStaffNum,
      grantDate: this.rawData.grantDate,
      validityPeriodStartMonth: this.rawData.validityPeriodStartMonth ? this.rawData.validityPeriodStartMonth : 0,
      validityPeriodStartDay: this.rawData.validityPeriodStartDay ? this.rawData.validityPeriodStartDay : 0,
      validityPeriodEndMonth: this.rawData.validityPeriodEndMonth ? this.rawData.validityPeriodEndMonth : 0,
      validityPeriodEndDay: this.rawData.validityPeriodEndDay ? this.rawData.validityPeriodEndDay : 0,
    };
  }

  getRawDataCreation(): Vacation {
    return {
      createUser: this.rawData.createUser,
      updateUser: this.rawData.updateUser,
      holidayId: this.rawData.holidayId,
      holidayCode: this.rawData.holidayCode,
      holidayName: this.rawData.holidayName,
      holidayType: this.rawData.holidayType,
      holidayBgColor: this.rawData.holidayBgColor,
      holidayForecolor: this.rawData.holidayForecolor,
      paidCalculationStandard: this.rawData.paidCalculationStandard,
      salaryCalculation: this.rawData.salaryCalculation,
      unitPricePeriod: this.rawData.unitPricePeriod,
      unitPriceTransportation: this.rawData.unitPriceTransportation,
      unitPriceRounding: this.rawData.unitPriceRounding,
      halfHoliday: this.rawData.halfHoliday,
      halfHolidayDeemedWorkingHours: this.rawData.halfHolidayDeemedWorkingHours,
      timeHoliday: this.rawData.timeHoliday,
      timeHolidayMaxHour: this.rawData.timeHolidayMaxHour,
      timeHolidayHourOfDay: this.rawData.timeHolidayHourOfDay,
      conditionGrantSubstituteHoliday: this.rawData.conditionGrantSubstituteHoliday,
      conditionGrantSubstituteHolidayHours: this.rawData.conditionGrantSubstituteHolidayHours,
      conditionGrantSubstituteHolidayMinutes: this.rawData.conditionGrantSubstituteHolidayMinutes,
      validityPeriod: this.rawData.validityPeriod,
      deemedWorkingHours: this.rawData.deemedWorkingHours,
      deemedWorkingMinutes: this.rawData.deemedWorkingMinutes,
      useEmploymentDeemedWorkingHours: this.rawData.useEmploymentDeemedWorkingHours,
      // isWorkTime: this.rawData.isWorkTime,
      unUsableOfTrialPeriod: this.rawData.unUsableOfTrialPeriod,
      autoGrantHoliday: this.rawData.autoGrantHoliday,
      autoGrantDateMonth: this.rawData.autoGrantDateMonth ? this.rawData.autoGrantDateMonth : 1,
      autoGrantDateSpecialHolidayDay: this.rawData.autoGrantDateSpecialHolidayDay,
      autoGrantDateDay: this.rawData.autoGrantDateDay ? this.rawData.autoGrantDateDay : 1,
      autoGrantHolidayType: this.rawData.autoGrantHolidayType,
      autoGrantDay: this.rawData.autoGrantDay !== '' ? this.rawData.autoGrantDay : 0,
      autoGrantWorkingStartMonths1: this.rawData.autoGrantWorkingStartMonths1 !== '' ? this.rawData.autoGrantWorkingStartMonths1 : 0,
      autoGrantWorkingEndMonths1: this.rawData.autoGrantWorkingEndMonths1 !== '' ? this.rawData.autoGrantWorkingEndMonths1 : 0,
      autoGrantWorkingDay1: this.rawData.autoGrantWorkingDay1 !== '' ? this.rawData.autoGrantWorkingDay1 : 0,
      autoGrantWorkingStartMonths2: this.rawData.autoGrantWorkingStartMonths2 !== '' ? this.rawData.autoGrantWorkingStartMonths2 : 0,
      autoGrantWorkingEndMonths2: this.rawData.autoGrantWorkingEndMonths2 !== '' ? this.rawData.autoGrantWorkingEndMonths2 : 0,
      autoGrantWorkingDay2: this.rawData.autoGrantWorkingDay2 !== '' ? this.rawData.autoGrantWorkingDay2 : 0,
      autoGrantWorkingStartMonths3: this.rawData.autoGrantWorkingStartMonths3 !== '' ? this.rawData.autoGrantWorkingStartMonths3 : 0,
      autoGrantWorkingEndMonths3: this.rawData.autoGrantWorkingEndMonths3 !== '' ? this.rawData.autoGrantWorkingEndMonths3 : 0,
      autoGrantWorkingDay3: this.rawData.autoGrantWorkingDay3 !== '' ? this.rawData.autoGrantWorkingDay3 : 0,
      includingTrialPeriod: this.rawData.includingTrialPeriod,
      autoValidityType: this.rawData.autoValidityType,
      autoValidityPeriod: this.rawData.autoValidityPeriod,
      enteringGrantHoliday: this.rawData.enteringGrantHoliday,
      enteringGrantDay1: this.rawData.enteringGrantDay1 !== '' ? this.rawData.enteringGrantDay1 : 0,
      enteringGrantDay2: this.rawData.enteringGrantDay2 !== '' ? this.rawData.enteringGrantDay2 : 0,
      enteringGrantDay3: this.rawData.enteringGrantDay3 !== '' ? this.rawData.enteringGrantDay3 : 0,
      enteringValidityType: this.rawData.enteringValidityType,
      enteringValidityPeriod: this.rawData.enteringValidityPeriod,
      paidHalfHolidayStartTime: this.rawData.paidHalfHolidayStartTime,
      paidHalfHolidayEndTime: this.rawData.paidHalfHolidayEndTime,
      isPaidHalfHolidayStartTimeNextDay: this.rawData.isPaidHalfHolidayStartTimeNextDay,
      isPaidHalfHolidayEndTimeNextDay: this.rawData.isPaidHalfHolidayEndTimeNextDay,
      noticeStaffs: this.rawData.noticeStaffs,
      grantStaffNum: this.rawData.grantStaffNum,
      grantDate: this.rawData.grantDate,
      validityPeriodStartMonth: this.rawData.validityPeriodStartMonth ? this.rawData.validityPeriodStartMonth : 0,
      validityPeriodStartDay: this.rawData.validityPeriodStartDay ? this.rawData.validityPeriodStartDay : 0,
      validityPeriodEndMonth: this.rawData.validityPeriodEndMonth ? this.rawData.validityPeriodEndMonth : 0,
      validityPeriodEndDay: this.rawData.validityPeriodEndDay ? this.rawData.validityPeriodEndDay : 0,
    };
  }

  get holidayTypeLabel(): String {
    return HolidayTypeLabel[this.rawData.holidayType];
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  get holidayId(): string {
    return this.rawData.holidayId;
  }

  set holidayId(holidayId: string) {
    this.rawData.holidayId = holidayId;
  }

  get holidayCode(): string {
    return this.rawData.holidayCode;
  }

  set holidayCode(holidayCode: string) {
    this.rawData.holidayCode = holidayCode;
  }

  get holidayName(): string {
    return this.rawData.holidayName;
  }

  set holidayName(holidayName: string) {
    this.rawData.holidayName = holidayName;
  }

  get holidayType(): number {
    return this.rawData.holidayType;
  }

  set holidayType(holidayType: number) {
    this.rawData.holidayType = holidayType;
  }

  get holidayBgColor(): string {
    return this.rawData.holidayBgColor;
  }

  set holidayBgColor(holidayBgColor: string) {
    this.rawData.holidayBgColor = holidayBgColor;
  }

  get holidayForecolor(): string {
    return this.rawData.holidayForecolor;
  }

  set holidayForecolor(holidayForecolor: string) {
    this.rawData.holidayForecolor = holidayForecolor;
  }

  get paidCalculationStandard(): boolean {
    return this.rawData.paidCalculationStandard;
  }

  set paidCalculationStandard(paidCalculationStandard: boolean) {
    this.rawData.paidCalculationStandard = paidCalculationStandard;
  }

  get salaryCalculation(): number {
    return this.rawData.salaryCalculation;
  }

  set salaryCalculation(salaryCalculation: number) {
    this.rawData.salaryCalculation = salaryCalculation;
  }

  get unitPricePeriod(): number {
    return this.rawData.unitPricePeriod;
  }

  set unitPricePeriod(unitPricePeriod: number) {
    this.rawData.unitPricePeriod = unitPricePeriod;
  }

  get unitPriceTransportation(): boolean {
    return this.rawData.unitPriceTransportation;
  }

  set unitPriceTransportation(unitPriceTransportation: boolean) {
    this.rawData.unitPriceTransportation = unitPriceTransportation;
  }

  get unitPriceRounding(): number {
    return this.rawData.unitPriceRounding;
  }

  set unitPriceRounding(unitPriceRounding: number) {
    this.rawData.unitPriceRounding = unitPriceRounding;
  }

  get halfHoliday(): boolean {
    return this.rawData.halfHoliday;
  }

  set halfHoliday(halfHoliday: boolean) {
    this.rawData.halfHoliday = halfHoliday;
  }

  get halfHolidayDeemedWorkingHours(): number {
    return this.rawData.halfHolidayDeemedWorkingHours;
  }

  set halfHolidayDeemedWorkingHours(halfHolidayDeemedWorkingHours: number) {
    this.rawData.halfHolidayDeemedWorkingHours = halfHolidayDeemedWorkingHours;
  }

  get conditionGrantSubstituteHoliday(): number {
    return this.rawData.conditionGrantSubstituteHoliday;
  }

  set conditionGrantSubstituteHoliday(conditionGrantSubstituteHoliday: number) {
    this.rawData.conditionGrantSubstituteHoliday = conditionGrantSubstituteHoliday;
  }

  get conditionGrantSubstituteHolidayHours(): number {
    return this.rawData.conditionGrantSubstituteHolidayHours;
  }

  set conditionGrantSubstituteHolidayHours(conditionGrantSubstituteHolidayHours: number) {
    this.rawData.conditionGrantSubstituteHolidayHours = conditionGrantSubstituteHolidayHours;
  }

  get conditionGrantSubstituteHolidayMinutes(): number {
    return this.rawData.conditionGrantSubstituteHolidayMinutes;
  }

  set conditionGrantSubstituteHolidayMinutes(conditionGrantSubstituteHolidayMinutes: number) {
    this.rawData.conditionGrantSubstituteHolidayMinutes = conditionGrantSubstituteHolidayMinutes;
  }

  get validityPeriod(): number {
    return this.rawData.validityPeriod;
  }

  set validityPeriod(validityPeriod: number) {
    this.rawData.validityPeriod = validityPeriod;
  }

  get deemedWorkingHours(): number {
    return this.rawData.deemedWorkingHours;
  }

  set deemedWorkingHours(deemedWorkingHours: number) {
    this.rawData.deemedWorkingHours = deemedWorkingHours;
  }
  // from cydas
  get validityPeriodStartMonth(): number {
    return this.rawData.validityPeriodStartMonth;
  }

  set validityPeriodStartMonth(validityPeriodStartMonth: number) {
    this.rawData.validityPeriodStartMonth = validityPeriodStartMonth;
  }

  get validityPeriodStartDay(): number {
    return this.rawData.validityPeriodStartDay;
  }

  set validityPeriodStartDay(validityPeriodStartDay: number) {
    this.rawData.validityPeriodStartDay = validityPeriodStartDay;
  }

  get validityPeriodEndMonth(): number {
    return this.rawData.validityPeriodEndMonth;
  }

  set validityPeriodEndMonth(validityPeriodEndMonth: number) {
    this.rawData.validityPeriodEndMonth = validityPeriodEndMonth;
  }

  get validityPeriodEndDay(): number {
    return this.rawData.validityPeriodEndDay;
  }

  set validityPeriodEndDay(validityPeriodEndDay: number) {
    this.rawData.validityPeriodEndDay = validityPeriodEndDay;
  }
  //  cydas
  get deemedWorkingMinutes(): number {
    return this.rawData.deemedWorkingMinutes;
  }

  set deemedWorkingMinutes(deemedWorkingMinutes: number) {
    this.rawData.deemedWorkingMinutes = deemedWorkingMinutes;
  }

  get useEmploymentDeemedWorkingHours(): boolean {
    return this.rawData.useEmploymentDeemedWorkingHours;
  }

  set useEmploymentDeemedWorkingHours(useEmploymentDeemedWorkingHours: boolean) {
    this.rawData.useEmploymentDeemedWorkingHours = useEmploymentDeemedWorkingHours;
  }

  // get isWorkTime(): number {
  //   return this.rawData.isWorkTime;
  // }

  // set isWorkTime(isWorkTime: number) {
  //   this.rawData.isWorkTime = isWorkTime;
  // }

  get unUsableOfTrialPeriod(): boolean {
    return this.rawData.unUsableOfTrialPeriod;
  }

  set unUsableOfTrialPeriod(unUsableOfTrialPeriod: boolean) {
    this.rawData.unUsableOfTrialPeriod = unUsableOfTrialPeriod;
  }

  get autoGrantHoliday(): boolean {
    return this.rawData.autoGrantHoliday;
  }

  set autoGrantHoliday(autoGrantHoliday: boolean) {
    this.rawData.autoGrantHoliday = autoGrantHoliday;
  }

  get autoGrantDateMonth(): number {
    return this.rawData.autoGrantDateMonth;
  }

  set autoGrantDateMonth(autoGrantDateMonth: number) {
    this.rawData.autoGrantDateMonth = autoGrantDateMonth;
  }

  get autoGrantDateSpecialHolidayDay(): number {
    return this.rawData.autoGrantDateSpecialHolidayDay;
  }

  set autoGrantDateSpecialHolidayDay(autoGrantDateSpecialHolidayDay: number) {
    this.rawData.autoGrantDateSpecialHolidayDay = autoGrantDateSpecialHolidayDay;
  }

  get autoGrantDateDay(): number {
    return this.rawData.autoGrantDateDay;
  }

  set autoGrantDateDay(autoGrantDateDay: number) {
    this.rawData.autoGrantDateDay = autoGrantDateDay;
  }

  get autoGrantHolidayType(): number {
    return this.rawData.autoGrantHolidayType;
  }

  set autoGrantHolidayType(autoGrantHolidayType: number) {
    this.rawData.autoGrantHolidayType = autoGrantHolidayType;
  }

  get autoGrantDay(): number | '' {
    return this.rawData.autoGrantDay;
  }

  set autoGrantDay(autoGrantDay: number | '') {
    this.rawData.autoGrantDay = autoGrantDay;
  }

  get includingTrialPeriod(): boolean {
    return this.rawData.includingTrialPeriod;
  }

  set includingTrialPeriod(includingTrialPeriod: boolean) {
    this.rawData.includingTrialPeriod = includingTrialPeriod;
  }

  get autoValidityType(): number {
    return this.rawData.autoValidityType;
  }

  set autoValidityType(autoValidityType: number) {
    this.rawData.autoValidityType = autoValidityType;
  }

  get autoValidityPeriod(): number {
    return this.rawData.autoValidityPeriod;
  }

  set autoValidityPeriod(autoValidityPeriod: number) {
    this.rawData.autoValidityPeriod = autoValidityPeriod;
  }

  get enteringGrantHoliday(): boolean {
    return this.rawData.enteringGrantHoliday;
  }

  set enteringGrantHoliday(enteringGrantHoliday: boolean) {
    this.rawData.enteringGrantHoliday = enteringGrantHoliday;
  }

  get timeHoliday(): boolean {
    return this.rawData.timeHoliday;
  }

  set timeHoliday(timeHoliday: boolean) {
    this.rawData.timeHoliday = timeHoliday;
  }

  get timeHolidayMaxHour(): number {
    return this.rawData.timeHolidayMaxHour;
  }

  set timeHolidayMaxHour(timeHolidayMaxHour: number) {
    this.rawData.timeHolidayMaxHour = timeHolidayMaxHour;
  }

  get timeHolidayHourOfDay(): number {
    return this.rawData.timeHolidayHourOfDay;
  }

  set timeHolidayHourOfDay(timeHolidayHourOfDay: number) {
    this.rawData.timeHolidayHourOfDay = timeHolidayHourOfDay;
  }

  get autoGrantWorkingStartMonths1(): number | '' {
    return this.rawData.autoGrantWorkingStartMonths1;
  }

  set autoGrantWorkingStartMonths1(autoGrantWorkingStartMonths1: number | '') {
    this.rawData.autoGrantWorkingStartMonths1 = autoGrantWorkingStartMonths1;
  }

  get autoGrantWorkingEndMonths1(): number | '' {
    return this.rawData.autoGrantWorkingEndMonths1;
  }

  set autoGrantWorkingEndMonths1(autoGrantWorkingEndMonths1: number | '') {
    this.rawData.autoGrantWorkingEndMonths1 = autoGrantWorkingEndMonths1;
  }

  get autoGrantWorkingDay1(): number | '' {
    return this.rawData.autoGrantWorkingDay1;
  }

  set autoGrantWorkingDay1(autoGrantWorkingDay1: number | '') {
    this.rawData.autoGrantWorkingDay1 = autoGrantWorkingDay1;
  }

  get autoGrantWorkingStartMonths2(): number | '' {
    return this.rawData.autoGrantWorkingStartMonths2;
  }

  set autoGrantWorkingStartMonths2(autoGrantWorkingStartMonths2: number | '') {
    this.rawData.autoGrantWorkingStartMonths2 = autoGrantWorkingStartMonths2;
  }

  get autoGrantWorkingEndMonths2(): number | '' {
    return this.rawData.autoGrantWorkingEndMonths2;
  }

  set autoGrantWorkingEndMonths2(autoGrantWorkingEndMonths2: number | '') {
    this.rawData.autoGrantWorkingEndMonths2 = autoGrantWorkingEndMonths2;
  }

  get autoGrantWorkingDay2(): number | '' {
    return this.rawData.autoGrantWorkingDay2;
  }

  set autoGrantWorkingDay2(autoGrantWorkingDay2: number | '') {
    this.rawData.autoGrantWorkingDay2 = autoGrantWorkingDay2;
  }

  get autoGrantWorkingStartMonths3(): number | '' {
    return this.rawData.autoGrantWorkingStartMonths3;
  }

  set autoGrantWorkingStartMonths3(autoGrantWorkingStartMonths3: number | '') {
    this.rawData.autoGrantWorkingStartMonths3 = autoGrantWorkingStartMonths3;
  }

  get autoGrantWorkingEndMonths3(): number | '' {
    return this.rawData.autoGrantWorkingEndMonths3;
  }

  set autoGrantWorkingEndMonths3(autoGrantWorkingEndMonths3: number | '') {
    this.rawData.autoGrantWorkingEndMonths3 = autoGrantWorkingEndMonths3;
  }

  get autoGrantWorkingDay3(): number | '' {
    return this.rawData.autoGrantWorkingDay3;
  }

  set autoGrantWorkingDay3(autoGrantWorkingDay3: number | '') {
    this.rawData.autoGrantWorkingDay3 = autoGrantWorkingDay3;
  }

  get enteringGrantDay1(): number | '' {
    return this.rawData.enteringGrantDay1;
  }

  set enteringGrantDay1(enteringGrantDay1: number | '') {
    this.rawData.enteringGrantDay1 = enteringGrantDay1;
  }

  get enteringGrantDay2(): number | '' {
    return this.rawData.enteringGrantDay2;
  }

  set enteringGrantDay2(enteringGrantDay2: number | '') {
    this.rawData.enteringGrantDay2 = enteringGrantDay2;
  }

  get enteringGrantDay3(): number | '' {
    return this.rawData.enteringGrantDay3;
  }

  set enteringGrantDay3(enteringGrantDay3: number | '') {
    this.rawData.enteringGrantDay3 = enteringGrantDay3;
  }

  get enteringValidityType(): number {
    return this.rawData.enteringValidityType;
  }

  set enteringValidityType(enteringValidityType: number) {
    this.rawData.enteringValidityType = enteringValidityType;
  }

  get enteringValidityPeriod(): number {
    return this.rawData.enteringValidityPeriod;
  }

  set enteringValidityPeriod(enteringValidityPeriod: number) {
    this.rawData.enteringValidityPeriod = enteringValidityPeriod;
  }

  get paidHalfHolidayStartTime(): string {
    return this.rawData.paidHalfHolidayStartTime;
  }

  set paidHalfHolidayStartTime(paidHalfHolidayStartTime: string) {
    this.rawData.paidHalfHolidayStartTime = paidHalfHolidayStartTime;
  }

  get paidHalfHolidayEndTime(): string {
    return this.rawData.paidHalfHolidayEndTime;
  }

  set paidHalfHolidayEndTime(paidHalfHolidayEndTime: string) {
    this.rawData.paidHalfHolidayEndTime = paidHalfHolidayEndTime;
  }


  get isPaidHalfHolidayEndTimeNextDay(): boolean {
    return this.rawData.isPaidHalfHolidayEndTimeNextDay;
  }

  set isPaidHalfHolidayEndTimeNextDay(isPaidHalfHolidayEndTimeNextDay: boolean) {
    this.rawData.isPaidHalfHolidayEndTimeNextDay = isPaidHalfHolidayEndTimeNextDay;
  }

  get isPaidHalfHolidayStartTimeNextDay(): boolean {
    return this.rawData.isPaidHalfHolidayStartTimeNextDay;
  }

  set isPaidHalfHolidayStartTimeNextDay(isPaidHalfHolidayStartTimeNextDay: boolean) {
    this.rawData.isPaidHalfHolidayStartTimeNextDay = isPaidHalfHolidayStartTimeNextDay;
  }

  get noticeStaffs(): Array<string> {
    return this.rawData.noticeStaffs;
  }

  set noticeStaffs(noticeStaffs: Array<string>) {
    this.rawData.noticeStaffs = noticeStaffs;
  }
  // grantStaffNum: 0,
  // grantDate: undefined,
}
