import React, { SVGProps } from 'react';

const Employ: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M10 1.5a1.5 1.5 0 011.364.875H16.5c.345 0 .625.28.625.625v15c0 .345-.28.625-.625.625h-13A.625.625 0 012.875 18V3c0-.345.28-.625.625-.625h5.136A1.5 1.5 0 0110 1.5zm5.875 2.125h-4.511a1.5 1.5 0 01-2.728 0H4.125v13.75h11.75V3.625zM13 14.875a.625.625 0 01.092 1.243l-.092.007H7a.625.625 0 01-.092-1.243L7 14.875h6zm-3-9.75a2.875 2.875 0 012.016 4.924c1.039.522 1.711 1.533 2.09 3.05a.625.625 0 01-.606.776h-7a.625.625 0 01-.606-.777c.379-1.516 1.051-2.527 2.09-3.05A2.875 2.875 0 0110 5.125zm0 5.75c-1.375 0-2.166.51-2.651 1.75h5.302c-.485-1.24-1.276-1.75-2.651-1.75zm0-4.5a1.625 1.625 0 100 3.25 1.625 1.625 0 000-3.25z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Employ;
