/** @jsx jsx */
import { useState, useMemo } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import { useOrgTreesOptionsAll } from 'hooks/useOrgTreesOptions';
import moment from 'moment';
import PaymeCoordinationDomain from 'domain/master/atendenceManagement/paymeCoordination';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import { useEmploymentListDateRange } from 'hooks/useEmploymentOptions';
import { PrimaryButton } from 'components/atoms/Button';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import { css, jsx } from '@emotion/core';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { downloadCSVV2, usePaymeCoordinationV2 } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const PayMeCooperationFrom = () => {
  // const today = new Date();
  const d = new Date();
  d.setDate(d.getDate() - 1);
  const [targetDateFrom, setTargetDateFrom] = useState(d);
  const [targetDateTo, setTargetDateTo] = useState(d);

  const employmentOptions = useEmploymentListDateRange(
    targetDateFrom,
    targetDateTo,
  );

  const [employmentId, setEmploymentId] = useState(
    sessionStorage.getItem('selectedEmployment')
      ? sessionStorage.getItem('selectedEmployment')
      : '',
  );
  const [employmentIdAfter, setemploymentIdAfter] = useState(
    sessionStorage.getItem('selectedEmploymentAfter')
      ? sessionStorage.getItem('selectedEmploymentAfter')
      : '',
  );

  const orgTreesOptions = useOrgTreesOptionsAll(
    moment(targetDateFrom).format('YYYY-MM-DD'),
    moment(targetDateTo).format('YYYY-MM-DD'),
  );
  const [orgCode, setorgCode] = useState(
    sessionStorage.getItem('loginUser.orgCode') || '',
  );

  let setlabelOrgCode = '';
  orgTreesOptions.filter((item: any) => {
    if (item.value === orgCode) {
      setlabelOrgCode = item.label;
    }
    return setlabelOrgCode;
  });

  const functionType = 2;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [categoryAry, setCategoryAry] = useState<Array<Array<string>>>([]);

  const { paymeCoordination } = usePaymeCoordinationV2(
    orgCode || '',
    moment(targetDateFrom).format('YYYY-MM-DD'),
    moment(targetDateTo).format('YYYY-MM-DD'),
    employmentId?.slice(0, -1) || '',
    functionType,
    categoryAry,
    staffCode,
  );

  // employment delete combobox selected
  const selectedEmploymentIds: Array<string> = employmentId
    ? employmentId.split(',')
    : [];
  let newSelectedEmploymentId: string = '';
  selectedEmploymentIds.map((item: string) => {
    if (employmentOptions.map((option) => option.value).includes(item)) {
      newSelectedEmploymentId += `${item},`;
      return newSelectedEmploymentId;
    }
    return true;
  });
  if (employmentId !== newSelectedEmploymentId) {
    setEmploymentId(newSelectedEmploymentId);
  }

  // employment after empployment null and select targetDateFrom,targetDateTo
  const employmentIdAfterstr: Array<string> = employmentIdAfter
    ? employmentIdAfter.split(',')
    : [];
  let newSelectedEmploymentId2: string = '';
  employmentIdAfterstr.map((item: string) => {
    if (employmentOptions.map((option) => option.value).includes(item)) {
      newSelectedEmploymentId2 += `${item},`;
    }
  });
  if (employmentId !== newSelectedEmploymentId2) {
    setEmploymentId(newSelectedEmploymentId2);
  }

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const columns: Array<Column<PaymeCoordinationDomain>> = useMemo(
    () => [
      {
        Header: '社員番号',
        accessor: 'employeeNumber',
        sortType: 'basic'
      },
      {
        Header: '勤務日',
        accessor: 'workingDay',
      },
      {
        Header: '出勤時刻',
        accessor: 'attendance',
      },
      {
        Header: '退勤時刻',
        accessor: 'departureTime',
      },
      {
        Header: '実労働時間',
        accessor: 'actualWorkingHours',
      },
    ],
    [],
  );

  return (
    <div>
      <FormContents>
        <FlexBox>
          <DatePickerForm
            dateFormat="yyyy年MM月dd日"
            label="対象期間"
            date={targetDateFrom}
            changeDate={(date: Date) => {
              if (date !== null) {
                setTargetDateFrom(date);
              }
            }}
            isFullWidth={true}
          />
          <span style={{ margin: '0 30px', marginTop: '24px' }}>～</span>
          <DatePickerForm
            dateFormat="yyyy年MM月dd日"
            label="対象期間"
            date={targetDateTo}
            changeDate={(date: Date) => {
              if (date !== null) {
                setTargetDateTo(date);
              }
            }}
            isFullWidth={true}
          />
        </FlexBox>
        <OrganizationFilteredTree
          functionType={functionType}
          staffCode={staffCode}
          targetdayForm={moment(targetDateFrom).toDate()}
          targetdayTo={moment(targetDateTo).toDate()}
          orgLabel="対象組織"
          initOrgValue={String(orgCode)}
          orgCallback={(args: string | Array<string>) => {
            setorgCode(String(args));
          }}
          orgCategoryCallback={(args: Array<Array<string>>) => {
            setCategoryAry(args);
          }}
          addAllItem={true}
          styles={{
            label: {
              toggleSwitch: css({ marginLeft: '0px' }),
            },
            toggle: {
              width: '13rem',
            },
          }}
        />

        <VerticalLabelMutilSelectForm
          label="雇用形態"
          name="employmentId"
          values={employmentOptions.filter((option) => {
            if (employmentId?.split(',').includes(option.value)) {
              return option;
            }
          })}
          setValue={(val: any) => {
            setEmploymentId(val.slice(0, -1));
            setemploymentIdAfter(val);
          }}
          options={employmentOptions}
          description=""
          isMulti={true}
        />

        <FormField>
          <FormSubmitArea>
            {roleScreen?.downloadFlag === 1 && (
            <PrimaryButton
              text="CSV出力 "
              onClick={() => {
                downloadCSVV2(
                  moment(targetDateFrom).format('YYYY-MM-DD'),
                  moment(targetDateTo).format('YYYY-MM-DD'),
                  String(orgCode),
                  String(employmentId),
                  setlabelOrgCode,
                  functionType,
                  categoryAry,
                  staffCode,
                );
              }}
            />
            )}
          </FormSubmitArea>
        </FormField>

        <div
          css={css`
            table th:nth-child(1) {
              width: 100px;
            }
            table th:nth-child(2) {
              width: 100px;
            }
            table th:nth-child(3) {
              width: 100px;
            }
            table th:nth-child(4) {
              width: 100px;
            }
            table th:nth-child(5) {
              width: 100px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <DataTable
              columns={columns}
              data={paymeCoordination}
              isGlobalFilter={true}
              minWidth="540px"
              sortBy={[
                { id: 'employeeNumber', desc: false }
              ]}
            />
          </div>
        </div>
      </FormContents>
    </div>
  );
};

export default PayMeCooperationFrom;
