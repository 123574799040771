import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import PaymentImportCreateForm from 'components/organismos/master/sales/PaymentImportCreateForm';
import { useParams } from 'react-router-dom';

const PaymentImportCreatePage: React.FC = () => {
  const { importCode } = useParams();
  return (
    <SidebarTemplate pageTitle={importCode ? '支払取込編集' : '支払取込設定'}>
      <PaymentImportCreateForm />
    </SidebarTemplate>
  );
};
export default PaymentImportCreatePage;
