/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Column } from 'react-table';

import { ReactSortable } from 'react-sortablejs';
import TableSort from 'components/molecules/TableSort/TableSort';
import { useDataTableSort } from './hooks';

import TableSortHeaderCell from './TableSortHeaderCell';
interface SortBy {
  id: string;
  desc: boolean;
}
interface DataTableProps {
  columns: Array<Column<any>>;
  data: Array<any>;
  setPageList?: any;
  isBorder?: boolean;
  useSetPageList?: boolean;
  sortBy?: Array<SortBy>;
}


const DataTableSort: React.FC<DataTableProps> = ({
  columns, data, setPageList, isBorder, useSetPageList, sortBy
}) => {
  const { headerGroups, prepareRow, page } = useDataTableSort(columns, data, sortBy);

  const [dataList, setDataList] = useState<Array<any>>([]);
  useEffect(() => {

    setDataList(page);
  }, [page]);

  const callBack = () => {
    if (useSetPageList) {
      setPageList(dataList);
    }

  };

  return (
    <>
      <div style={{ height: isBorder ? 'auto' : 'calc(100vh - 260px)', overflow: 'auto', maxHeight: 'calc(100vh - 260px)' }} className={`${isBorder ? 'templateTable useBordered' : ''}`}>
        <TableSort>
          <thead style={{}} className={`${isBorder ? 'useSticky' : ''}`}>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.getHeaderGroupProps().key}>
                {headerGroup.headers.map((column) => {
                  const headerProps = column.getHeaderProps(column.getSortByToggleProps());
                  return (
                    <TableSortHeaderCell
                      key={headerProps.key}
                      onClick={(headerProps as any).onClick}
                      isSorted={column.isSorted}
                      isSortedDesc={column.isSortedDesc}
                      disableSortBy={column.disableSortBy}
                    >
                      <span style={{
                      }}
                      >
                        {column.render('Header')}

                      </span>
                    </TableSortHeaderCell>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody style={{}}>
            <ReactSortable list={dataList} setList={(data: any) => useSetPageList ? {} : setDataList(data)} onEnd={callBack} style={{ display: 'contents' }}>
              {dataList.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    key={row.getRowProps().key}
                    style={{
                      backgroundColor: '#ffffff',
                    }}
                  >
                    {row.cells.map((cell: any) => (
                      <TableSort.SortCell key={cell.getCellProps().key}>
                        {cell.render('Cell')}
                      </TableSort.SortCell>
                    ))}
                  </tr>
                );
              })}
            </ReactSortable>
          </tbody>
        </TableSort>

      </div>
      {page.length === 0 ? <div style={{ backgroundColor: '#ffffff', textAlign: 'center', padding: ' 12px 0' }}>データはありません。</div> : ''}

    </>
  );
};

export default DataTableSort;
