/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import moment from 'moment';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { useDownload } from './hooks';

const IncomeAndExpenditureScheduleMonthlyTable: React.FC<{
    year: number,
    incomeAndExpenditureScheduleMonthly: any | null,
    orgCode: string | null,
    orgName: string | null,
    roleScreen?: any
}> = ({
    year,
    incomeAndExpenditureScheduleMonthly,
    orgCode,
    orgName,
    roleScreen
}) => {
        const { downloadCsv, downloadExcel } = useDownload();

        const formatter = new Intl.NumberFormat('ja-JP', {
            minimumFractionDigits: 0, maximumFractionDigits: 2,
        });

        // 組織名
        const listHeader = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return moment(item.targetMonth).format('YYYY') !== '9999' ? moment(item.targetMonth).format('YYYY年MM月') : `${year}年 合計`;
        });

        const new_list_header = listHeader.map((item: any, index: number) => {
            return [item, '']
        });

        const listHeaderResult = []

        for (var i = 0; i < new_list_header.length; i++) {
            listHeaderResult.push(...new_list_header[i])
        }

        listHeaderResult.unshift(orgCode + ' ' + orgName, '', '');

        const headerInformation = [
            listHeaderResult
        ];

        // data for CSV, Excel
        const totalInformation: any[] = [];
        const searchResult: any[] = [];
        const targetStoresData = `対象店舗：${orgCode + ' ' + orgName}`;
        const mergedRegions: any = [];
        const codeColumnIndex: any = [];

        useEffect(() => {
            let employeeLaborCost: any = [];
            let partSalary: any = [];
            let partAdjustCost: any = [];
            let totalLaborCost: any = [];
            let retireLaborCost: any = [];
            let pettyFoodTotal: any = [];
            let foodDetailNameCsv: any = []
            let foodTotal: any = [];
            let pettyDrinkTotal: any = [];
            let drinkDetailNameCsv: any = []
            let drinkTotal: any = [];
            let termStart: any = [];
            let termEnd: any = [];
            let totalPurchase: any = [];
            let communicationDetailNameCsv: any = []
            let totalCommunicationCost: any = [];
            let advertisingDetailNameCsv: any = [];
            let totalAdvertisingCost: any = [];
            let recruitmentDetailNameCsv: any = [];
            let totalRecruitmentCost: any = [];
            let insectRepellent: any = [];
            let garbageDisposalFee: any = [];
            let designFee: any = [];
            let expensesDetailNameCsv: any = [];
            let totalExpenses: any = [];
            let electricBill: any = [];
            let waterFee: any = [];
            let gasFee: any = [];
            let totalUtilityCosts: any = [];
            let landRent: any = [];
            let commonServiceFee: any = [];
            let rentOther: any = [];
            let totalLandRent: any = [];
            let totalCcAmount: any = [];
            let ccFee: any = [];
            let depreciationDetail: any = [];
            let storeEquipmentRent: any = [];
            let leasePayment: any = [];
            let leaseDebt: any = [];
            let depreciation: any = [];
            let bonusDepreciation: any = [];
            let repairCosts: any = [];
            let commonFee: any = [];
            let totalExpenditure: any = [];
            let netSales: any = [];
            let otherSalesDetailNameCsv: any = [];
            let otherNetSales: any = [];
            let totalNetSales: any = [];
            let ordinaryIncome: any = [];
            let salesBudgetTotal: any = [];
            let totalFlCost: any = [];
            let totalFlaCost: any = [];
            let totalFlarCost: any = [];
            let totalFlaroCost: any = []

            let getFoodDetailNameDataCsv: any = []

            const foodDetailAmountCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.foodDetailAmount !== '';
            }).map((item: any, index: number) => {
                return item.foodDetailAmount.split(',');
            }) : [];
            const foodDetailAmountRateCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.foodDetailAmountRate !== '';
            }).map((item: any, index: number) => {
                return item.foodDetailAmountRate.split(',');
            }) : [];
            const getFoodDetailNameCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.foodDetailName !== '';
            }).length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.foodDetailName !== '';
            }).map((item: any, index: number) => {
                return item.foodDetailName.split(',');
            }) : [[]] : [[]];
            console.log(getFoodDetailNameCsv, 'getFoodDetailNameCsv')
            getFoodDetailNameDataCsv.push(foodDetailAmountCsv, foodDetailAmountRateCsv);

            let getDrinkDetailNameDataCsv: any = []

            const drinkDetailAmountCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.drinkDetailAmount !== '';
            }).map((item: any, index: number) => {
                return item.drinkDetailAmount.split(',');
            }) : [];
            const drinkDetailAmountRateCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.drinkDetailAmountRate !== '';
            }).map((item: any, index: number) => {
                return item.drinkDetailAmountRate.split(',');
            }) : [];
            const getDrinkDetailNameCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.drinkDetailName !== '';
            }).length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.drinkDetailName !== '';
            }).map((item: any, index: number) => {
                return item.drinkDetailName.split(',');
            }) : [[]] : [[]];

            getDrinkDetailNameDataCsv.push(drinkDetailAmountCsv, drinkDetailAmountRateCsv);

            let getCommunicationDetailNameDataCsv: any = []

            const communicationDetailAmountCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.communicationDetailAmount !== '';
            }).map((item: any, index: number) => {
                return item.communicationDetailAmount.split(',');
            }) : [];
            const communicationDetailAmountRateCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.communicationDetailAmountRate !== '';
            }).map((item: any, index: number) => {
                return item.communicationDetailAmountRate.split(',');
            }) : [];
            const getCommunicationDetailNameCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.communicationDetailName !== '';
            }).length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.communicationDetailName !== '';
            }).map((item: any, index: number) => {
                return item.communicationDetailName.split(',');
            }) : [[]] : [[]];

            getCommunicationDetailNameDataCsv.push(communicationDetailAmountCsv, communicationDetailAmountRateCsv);

            let getAdvertisingDetailNameDataCsv: any = []

            const advertisingDetailAmountCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.advertisingDetailAmount !== '';
            }).map((item: any, index: number) => {
                return item.advertisingDetailAmount.split(',');
            }) : [];
            const advertisingDetailAmountRateCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.advertisingDetailAmountRate !== '';
            }).map((item: any, index: number) => {
                return item.advertisingDetailAmountRate.split(',');
            }) : [];
            const getAdvertisingDetailNameCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.advertisingDetailName !== '';
            }).length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.advertisingDetailName !== '';
            }).map((item: any, index: number) => {
                return item.advertisingDetailName.split(',');
            }) : [[]] : [[]];

            getAdvertisingDetailNameDataCsv.push(advertisingDetailAmountCsv, advertisingDetailAmountRateCsv);

            let getRecruitmentDetailNameDataCsv: any = []

            const recruitmentDetailAmountCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.recruitmentDetailAmount !== '';
            }).map((item: any, index: number) => {
                return item.recruitmentDetailAmount.split(',');
            }) : [];
            const recruitmentDetailAmountRateCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.recruitmentDetailAmountRate !== '';
            }).map((item: any, index: number) => {
                return item.recruitmentDetailAmountRate.split(',');
            }) : [];
            const getRecruitmentDetailNameCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.recruitmentDetailName !== '';
            }).length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.recruitmentDetailName !== '';
            }).map((item: any, index: number) => {
                return item.recruitmentDetailName.split(',');
            }) : [[]] : [[]];

            getRecruitmentDetailNameDataCsv.push(recruitmentDetailAmountCsv, recruitmentDetailAmountRateCsv);

            let getExpensesDetailNameDataCsv: any = []

            const expensesDetailAmountCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.expensesDetailAmount !== '';
            }).map((item: any, index: number) => {
                return item.expensesDetailAmount.split(',');
            }) : [];
            const expensesDetailAmountRateCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.expensesDetailAmountRate !== '';
            }).map((item: any, index: number) => {
                return item.expensesDetailAmountRate.split(',');
            }) : [];
            const getExpensesDetailNameCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.expensesDetailName !== '';
            }).length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.expensesDetailName !== '';
            }).map((item: any, index: number) => {
                return item.expensesDetailName.split(',');
            }) : [[]] : [[]];

            getExpensesDetailNameDataCsv.push(expensesDetailAmountCsv, expensesDetailAmountRateCsv);

            const otherSalesDetailAmountCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.otherSalesDetailAmount !== '';
            }).map((item: any, index: number) => {
                return item.otherSalesDetailAmount.split(',');
            }) : [];
            const getOtherSalesDetailNameCsv = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.otherSalesDetailName !== '';
            }).length > 0 ? incomeAndExpenditureScheduleMonthly.filter((item: any) => {
                return item.otherSalesDetailName !== '';
            }).map((item: any, index: number) => {
                return item.otherSalesDetailName.split(',');
            }) : [[]] : [[]];

            employeeLaborCost.push('人件費', '', '社員');
            partSalary.push('', '', 'アルバイト');
            partAdjustCost.push('', '', 'アルバイト調整額');
            totalLaborCost.push('', '', '人件費合計');
            retireLaborCost.push('人件費(退職者)', '', '');
            pettyFoodTotal.push('仕入れ', '食材', '【食材小口】');

            getFoodDetailNameCsv && getFoodDetailNameCsv[0] && getFoodDetailNameCsv[0].map((item: any, index: number) => {
                const arrayCsv = listHeader && listHeader.map((item1: any, indexHeader: number) => {
                    return [formatter.format(getFoodDetailNameDataCsv[0][indexHeader][index]), formatter.format(getFoodDetailNameDataCsv[1][indexHeader][index]) + '%'];
                });
                foodDetailNameCsv.push(['', '', item, ...arrayCsv.flat()]);
            });

            foodTotal.push('', '食材合計', '');
            pettyDrinkTotal.push('', '飲材', '【飲材小口】');

            getDrinkDetailNameCsv && getDrinkDetailNameCsv[0] && getDrinkDetailNameCsv[0].map((item: any, index: number) => {
                const arrayCsv = listHeader && listHeader.map((item1: any, indexHeader: number) => {
                    return [formatter.format(getDrinkDetailNameDataCsv[0][indexHeader][index]), formatter.format(getDrinkDetailNameDataCsv[1][indexHeader][index]) + '%']
                })
                drinkDetailNameCsv.push(['', '', item, ...arrayCsv.flat()]);
            });

            drinkTotal.push('', '飲材合計', '');
            termStart.push('', '前月棚卸額', '');
            termEnd.push('', '当月棚卸額', '');
            totalPurchase.push('', '仕入原材料合計', '');

            getCommunicationDetailNameCsv && getCommunicationDetailNameCsv[0].length > 0 ? getCommunicationDetailNameCsv[0].map((item: any, index: number) => {
                const arrayCsv = listHeader && listHeader.map((item1: any, indexHeader: number) => {
                    return [formatter.format(getCommunicationDetailNameDataCsv[0][indexHeader][index]), formatter.format(getCommunicationDetailNameDataCsv[1][indexHeader][index]) + '%']
                })
                index === 0 ? communicationDetailNameCsv.push(['通信費', item, '', ...arrayCsv.flat()]) : communicationDetailNameCsv.push(['', item, '', ...arrayCsv.flat()]);
                index === getCommunicationDetailNameCsv[0].length - 1 && totalCommunicationCost.push('', '通信費合計', '');
            }) : totalCommunicationCost.push('通信費', '通信費合計', '');

            getAdvertisingDetailNameCsv && getAdvertisingDetailNameCsv[0].length > 0 ? getAdvertisingDetailNameCsv[0].map((item: any, index: number) => {
                const arrayCsv = listHeader && listHeader.map((item1: any, indexHeader: number) => {
                    return [formatter.format(getAdvertisingDetailNameDataCsv[0][indexHeader][index]), formatter.format(getAdvertisingDetailNameDataCsv[1][indexHeader][index]) + '%'];
                });
                index === 0 ? advertisingDetailNameCsv.push(['宣伝費', item, '', ...arrayCsv.flat()]) : advertisingDetailNameCsv.push(['', item, '', ...arrayCsv.flat()]);
                index === getAdvertisingDetailNameCsv[0].length - 1 && totalAdvertisingCost.push('', '宣伝費合計', '');
            }) : totalAdvertisingCost.push('宣伝費', '宣伝費合計', '');

            getRecruitmentDetailNameCsv && getRecruitmentDetailNameCsv[0].length > 0 ? getRecruitmentDetailNameCsv[0].map((item: any, index: number) => {
                const arrayCsv = listHeader && listHeader.map((item1: any, indexHeader: number) => {
                    return [formatter.format(getRecruitmentDetailNameDataCsv[0][indexHeader][index]), formatter.format(getRecruitmentDetailNameDataCsv[1][indexHeader][index]) + '%'];
                });
                index === 0 ? recruitmentDetailNameCsv.push(['募集費', item, '', ...arrayCsv.flat()]) : recruitmentDetailNameCsv.push(['', item, '', ...arrayCsv.flat()]);
                index === getRecruitmentDetailNameCsv[0].length - 1 && totalRecruitmentCost.push('', '募集費合計', '');
            }) : totalRecruitmentCost.push('募集費', '募集費合計', '');
            console.log('vvv', totalRecruitmentCost)
            insectRepellent.push('諸経費', '防虫費', '');
            garbageDisposalFee.push('', 'ゴミ処理費', '');
            designFee.push('', 'デザイン制作費', '');

            getExpensesDetailNameCsv && getExpensesDetailNameCsv[0] && getExpensesDetailNameCsv[0].map((item: any, index: number) => {
                const arrayCsv = listHeader && listHeader.map((item1: any, indexHeader: number) => {
                    return [formatter.format(getExpensesDetailNameDataCsv[0][indexHeader][index]), formatter.format(getExpensesDetailNameDataCsv[1][indexHeader][index]) + '%'];
                });
                expensesDetailNameCsv.push(['', item, '', ...arrayCsv.flat()]);
            });

            totalExpenses.push('', '諸経費合計', '');
            electricBill.push('光熱費合計', '電気', '');
            waterFee.push('', '水道', '');
            gasFee.push('', 'ガス', '');
            totalUtilityCosts.push('', '光熱費合計', '');
            landRent.push('家賃', '家賃', '');
            commonServiceFee.push('', '共益費', '');
            rentOther.push('', 'その他', '');
            totalLandRent.push('', '家賃合計', '');
            totalCcAmount.push('決済手数料', '', '決済売上(税抜)');
            ccFee.push('', '', '手数料');
            depreciationDetail.push('減価償却費', '減価償却費', '');
            storeEquipmentRent.push('', '店舗設備賃料', '');
            leasePayment.push('', 'リース費', '');
            leaseDebt.push('', 'リース債務', '');
            depreciation.push('', '減価償却費', '');
            bonusDepreciation.push('賞与償却費', '', '');
            repairCosts.push('修繕費', '', '');
            commonFee.push('共通費', '', '');
            totalExpenditure.push('支出総計', '', '');
            netSales.push('売上(税抜)', 'POS売上', '');

            getOtherSalesDetailNameCsv && getOtherSalesDetailNameCsv[0] && getOtherSalesDetailNameCsv[0].map((item: any, index: number) => {
                const arrayCsv = listHeader && listHeader.map((item1: any, indexHeader: number) => {
                    return [formatter.format(otherSalesDetailAmountCsv[indexHeader][index]), ''];
                });
                index === 0 ? otherSalesDetailNameCsv.push(['', 'その他売上', item, ...arrayCsv.flat()]) : otherSalesDetailNameCsv.push(['', '', item, ...arrayCsv.flat()]);
            });

            otherNetSales.push('', 'その他売上合計', '');
            totalNetSales.push('', '売上合計', '');
            ordinaryIncome.push('営業利益/率', '', '');
            salesBudgetTotal.push('予算/達成率', '', '');
            totalFlCost.push('FLコスト/率', '', '');
            totalFlaCost.push('FLAコスト/率', '', '');
            totalFlarCost.push('FLARコスト/率', '', '');
            totalFlaroCost.push('FLAROコスト/率', '', '');

            mergedRegions.push([4, 7, 0, 1]);
            mergedRegions.push([8, 8, 0, 2]);
            mergedRegions.push([9, 15 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length, 0, 0]);

            getFoodDetailNameCsv && getFoodDetailNameCsv[0].length > 0
                && mergedRegions.push([9, 9 + getFoodDetailNameCsv[0].length, 1, 1]);

            mergedRegions.push([10 + getFoodDetailNameCsv[0].length, 10 + getFoodDetailNameCsv[0].length, 1, 2]);

            getFoodDetailNameCsv && getFoodDetailNameCsv[0].length > 0
                && getDrinkDetailNameCsv && getDrinkDetailNameCsv[0].length > 0
                && mergedRegions.push([11 + getFoodDetailNameCsv[0].length, 11 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length, 1, 1]);

            mergedRegions.push([12 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length, 12 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([13 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length, 13 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([14 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length, 14 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([15 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length, 15 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length, 1, 2]);

            getFoodDetailNameCsv && getFoodDetailNameCsv[0].length > 0
                && getDrinkDetailNameCsv && getDrinkDetailNameCsv[0].length > 0
                && getCommunicationDetailNameCsv && getCommunicationDetailNameCsv[0].length > 0
                && mergedRegions.push([16 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length, 16 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length, 0, 0]);

            getCommunicationDetailNameCsv && getCommunicationDetailNameCsv[0] && getCommunicationDetailNameCsv[0].map((item: any, index: number) => {
                mergedRegions.push([16 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + index, 16 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + index, 1, 2]);
            });

            mergedRegions.push([16 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length, 16 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length, 1, 2]);

            getFoodDetailNameCsv && getFoodDetailNameCsv[0].length > 0
                && getDrinkDetailNameCsv && getDrinkDetailNameCsv[0].length > 0
                && getCommunicationDetailNameCsv && getCommunicationDetailNameCsv[0].length > 0
                && getAdvertisingDetailNameCsv && getAdvertisingDetailNameCsv[0].length > 0
                && mergedRegions.push([17 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length, 17 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length, 0, 0]);

            getAdvertisingDetailNameCsv && getAdvertisingDetailNameCsv[0] && getAdvertisingDetailNameCsv[0].map((item: any, index: number) => {
                mergedRegions.push([17 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + index, 17 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + index, 1, 2]);
            });

            mergedRegions.push([17 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length, 17 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length, 1, 2]);

            getFoodDetailNameCsv && getFoodDetailNameCsv[0].length > 0
                && getDrinkDetailNameCsv && getDrinkDetailNameCsv[0].length > 0
                && getCommunicationDetailNameCsv && getCommunicationDetailNameCsv[0].length > 0
                && getAdvertisingDetailNameCsv && getAdvertisingDetailNameCsv[0].length > 0
                && getRecruitmentDetailNameCsv && getRecruitmentDetailNameCsv[0].length > 0
                && mergedRegions.push([18 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length, 18 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length, 0, 0]);

            getRecruitmentDetailNameCsv && getRecruitmentDetailNameCsv[0] && getRecruitmentDetailNameCsv[0].map((item: any, index: number) => {
                mergedRegions.push([18 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + index, 18 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + index, 1, 2]);
            });

            mergedRegions.push([18 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length, 18 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length, 1, 2]);

            mergedRegions.push([19 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length, 22 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 0, 0]);
            mergedRegions.push([19 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length, 19 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([20 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length, 20 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([21 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length, 21 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length, 1, 2]);

            getExpensesDetailNameCsv && getExpensesDetailNameCsv[0] && getExpensesDetailNameCsv[0].map((item: any, index: number) => {
                mergedRegions.push([22 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + index, 22 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + index, 1, 2]);
            });

            mergedRegions.push([22 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 22 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);

            mergedRegions.push([23 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 26 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 0, 0]);

            mergedRegions.push([23 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 23 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([24 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 24 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([25 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 25 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([26 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 26 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);

            mergedRegions.push([27 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 30 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 0, 0]);

            mergedRegions.push([27 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 27 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([28 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 28 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([29 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 29 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([30 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 30 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);

            mergedRegions.push([31 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 32 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 0, 0]);

            // mergedRegions.push([31 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 31 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);
            // mergedRegions.push([32 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 32 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);

            mergedRegions.push([33 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 37 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 0, 0]);

            mergedRegions.push([33 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 33 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([34 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 34 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([35 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 35 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([36 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 36 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([37 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 37 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);

            mergedRegions.push([38 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 38 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 0, 2]);
            mergedRegions.push([39 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 39 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 0, 2]);
            mergedRegions.push([40 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 40 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 0, 2]);
            mergedRegions.push([41 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 41 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 0, 2]);

            mergedRegions.push([42 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 44 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 0, 0]);

            mergedRegions.push([42 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 42 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 1, 2]);

            const lengthFoodDetailCsv = getFoodDetailNameCsv ? getFoodDetailNameCsv[0].length : 0;
            const lengthDrinkDetailNameCsv = getDrinkDetailNameCsv ? getDrinkDetailNameCsv[0].length : 0;
            const lengthCommunicationDetailNameCsv = getCommunicationDetailNameCsv ? getCommunicationDetailNameCsv[0].length : 0;
            const lengthAdvertisingDetailNameCsv = getAdvertisingDetailNameCsv ? getAdvertisingDetailNameCsv[0].length : 0;
            const lengthRecruitmentDetailNameCsv = getRecruitmentDetailNameCsv ? getRecruitmentDetailNameCsv[0].length : 0;
            const lengthExpensesDetailNameCsv = getExpensesDetailNameCsv ? getExpensesDetailNameCsv[0].length : 0;
            const lengthOtherSalesDetailNameCsv = getOtherSalesDetailNameCsv ? getOtherSalesDetailNameCsv[0].length : 0;

            lengthOtherSalesDetailNameCsv > 1 && mergedRegions.push([43 + lengthFoodDetailCsv + lengthDrinkDetailNameCsv + lengthCommunicationDetailNameCsv + lengthAdvertisingDetailNameCsv + lengthRecruitmentDetailNameCsv + lengthExpensesDetailNameCsv, 42 + lengthFoodDetailCsv + lengthDrinkDetailNameCsv + lengthCommunicationDetailNameCsv + lengthAdvertisingDetailNameCsv + lengthRecruitmentDetailNameCsv + lengthExpensesDetailNameCsv + lengthOtherSalesDetailNameCsv, 1, 1]);

            mergedRegions.push([43 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 43 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 1, 2]);
            mergedRegions.push([44 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 44 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 1, 2]);

            mergedRegions.push([45 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 45 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 0, 2]);
            mergedRegions.push([46 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 46 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 0, 2]);
            mergedRegions.push([47 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 47 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 0, 2]);
            mergedRegions.push([48 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 48 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 0, 2]);
            mergedRegions.push([49 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 49 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 0, 2]);
            mergedRegions.push([50 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 50 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 0, 2]);

            if (incomeAndExpenditureScheduleMonthly) {
                incomeAndExpenditureScheduleMonthly.map((item: any, index: any) => {
                    index === 0 ? mergedRegions.push([3, 3, 0, 2]) && mergedRegions.push([3, 3, 3, 4]) : mergedRegions.push([3, 3, (index + 1) * 2 + 1, (index + 1) * 2 + 2]);

                    mergedRegions.push([42 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, 42 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length, (index + 1) * 2 + 1, (index + 1) * 2 + 2]);

                    getOtherSalesDetailNameCsv && getOtherSalesDetailNameCsv[0] && getOtherSalesDetailNameCsv[0].map((item: any, indexOrder: number) => {
                        mergedRegions.push([43 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + indexOrder, 43 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + indexOrder, (index + 1) * 2 + 1, (index + 1) * 2 + 2]);
                    });

                    mergedRegions.push([43 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 43 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, (index + 1) * 2 + 1, (index + 1) * 2 + 2]);
                    mergedRegions.push([44 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, 44 + getFoodDetailNameCsv[0].length + getDrinkDetailNameCsv[0].length + getCommunicationDetailNameCsv[0].length + getAdvertisingDetailNameCsv[0].length + getRecruitmentDetailNameCsv[0].length + getExpensesDetailNameCsv[0].length + getOtherSalesDetailNameCsv[0].length, (index + 1) * 2 + 1, (index + 1) * 2 + 2]);

                    employeeLaborCost.push(formatter.format(item.employeeLaborCost), (item.employeeLaborCostRate) + '%');
                    partSalary.push(formatter.format(item.partSalary), (item.partSalaryRate) + '%');
                    partAdjustCost.push(formatter.format(item.partAdjustCost), (item.partAdjustCostRate) + '%');
                    totalLaborCost.push(formatter.format(item.totalLaborCost), (item.totalLaborCostRate) + '%');
                    retireLaborCost.push(formatter.format(item.retireLaborCost), (item.retireLaborCostRate) + '%');
                    pettyFoodTotal.push(formatter.format(item.pettyFoodTotal), (item.pettyFoodTotalRate) + '%');
                    foodTotal.push(formatter.format(item.foodTotal), (item.foodTotalRate) + '%');
                    pettyDrinkTotal.push(formatter.format(item.pettyDrinkTotal), (item.pettyDrinkTotalRate) + '%')
                    drinkTotal.push(formatter.format(item.drinkTotal), (item.drinkTotalRate) + '%');
                    termStart.push(formatter.format(item.termStart), (item.termStartRate) + '%');
                    termEnd.push(formatter.format(item.termEnd), (item.termEndRate) + '%');
                    totalPurchase.push(formatter.format(item.totalPurchase), (item.totalPurchaseRate) + '%');
                    totalCommunicationCost.push(formatter.format(item.totalCommunicationCost), (item.totalCommunicationCostRate) + '%');
                    totalAdvertisingCost.push(formatter.format(item.totalAdvertisingCost), (item.totalAdvertisingCostRate) + '%');
                    totalRecruitmentCost.push(formatter.format(item.totalRecruitmentCost), (item.totalRecruitmentCostRate) + '%');
                    insectRepellent.push(formatter.format(item.insectRepellent), (item.insectRepellentRate) + '%');
                    garbageDisposalFee.push(formatter.format(item.garbageDisposalFee), (item.garbageDisposalFeeRate) + '%');
                    designFee.push(formatter.format(item.designFee), (item.designFeeRate) + '%');
                    totalExpenses.push(formatter.format(item.totalExpenses), (item.totalExpensesRate) + '%');
                    electricBill.push(formatter.format(item.electricBill), (item.electricBillRate) + '%');
                    waterFee.push(formatter.format(item.waterFee), (item.waterFeeRate) + '%');
                    gasFee.push(formatter.format(item.gasFee), (item.gasFeeRate) + '%');
                    totalUtilityCosts.push(formatter.format(item.totalUtilityCosts), (item.totalUtilityCostsRate) + '%');
                    landRent.push(formatter.format(item.landRent), (item.landRentRate) + '%');
                    commonServiceFee.push(formatter.format(item.commonServiceFee), (item.commonServiceFeeRate) + '%');
                    rentOther.push(formatter.format(item.rentOther), (item.rentOtherRate) + '%');
                    totalLandRent.push(formatter.format(item.totalLandRent), (item.totalLandRentRate) + '%');
                    totalCcAmount.push(formatter.format(item.totalCcAmount), (item.totalCcAmountRate) + '%');
                    ccFee.push(formatter.format(item.ccFee), (item.ccFeeRate) + '%');
                    depreciationDetail.push(formatter.format(item.depreciationDetail), (item.depreciationDetailRate) + '%');
                    storeEquipmentRent.push(formatter.format(item.storeEquipmentRent), (item.storeEquipmentRentRate) + '%');
                    leasePayment.push(formatter.format(item.leasePayment), (item.leasePaymentRate) + '%');
                    leaseDebt.push(formatter.format(item.leaseDebt), (item.leaseDebtRate) + '%');
                    depreciation.push(formatter.format(item.depreciation), (item.depreciationRate) + '%');
                    bonusDepreciation.push(formatter.format(item.bonusDepreciation), (item.bonusDepreciationRate) + '%');
                    repairCosts.push(formatter.format(item.repairCosts), (item.repairCostsRate) + '%');
                    commonFee.push(formatter.format(item.commonFee), (item.commonFeeRate) + '%');
                    totalExpenditure.push(formatter.format(item.totalExpenditure), (item.totalExpenditureRate) + '%');
                    netSales.push(formatter.format(item.netSales), '');
                    otherNetSales.push(formatter.format(item.otherNetSales), '');
                    totalNetSales.push(formatter.format(item.totalNetSales), '');
                    ordinaryIncome.push(formatter.format(item.ordinaryIncome), (item.profitRate) + '%');
                    salesBudgetTotal.push(formatter.format(item.salesBudgetTotal), (item.salesAchievementRate) + '%');
                    totalFlCost.push(formatter.format(item.totalFlCost), (item.totalFlRate) + '%');
                    totalFlaCost.push(formatter.format(item.totalFlaCost), (item.totalFlaRate) + '%')
                    totalFlarCost.push(formatter.format(item.totalFlarCost), (item.totalFlarRate) + '%')
                    totalFlaroCost.push(formatter.format(item.totalFlaroCost), (item.totalFlaroRate) + '%')
                });

                searchResult.push(employeeLaborCost, partSalary, partAdjustCost, totalLaborCost, retireLaborCost, pettyFoodTotal, ...foodDetailNameCsv, foodTotal, pettyDrinkTotal, ...drinkDetailNameCsv, drinkTotal, termStart, termEnd, totalPurchase, ...communicationDetailNameCsv, totalCommunicationCost, ...advertisingDetailNameCsv, totalAdvertisingCost, ...recruitmentDetailNameCsv, totalRecruitmentCost, insectRepellent, garbageDisposalFee, designFee, ...expensesDetailNameCsv, totalExpenses,
                    electricBill, waterFee, gasFee, totalUtilityCosts, landRent, commonServiceFee, rentOther, totalLandRent, totalCcAmount, ccFee, depreciationDetail, storeEquipmentRent, leasePayment, leaseDebt, depreciation, bonusDepreciation, repairCosts, commonFee, totalExpenditure, netSales, ...otherSalesDetailNameCsv, otherNetSales, totalNetSales, ordinaryIncome, salesBudgetTotal, totalFlCost, totalFlaCost, totalFlarCost, totalFlaroCost);
                console.log(searchResult, 'searchResult')
            }
        }, [searchResult]);

        // 人件費＞社員
        const listEmployeeLaborCost = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                employeeLaborCost: item.employeeLaborCost,
                employeeLaborCostRate: item.employeeLaborCostRate
            }
        });

        // 人件費＞アルバイト
        const listPartSalary = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                partSalary: item.partSalary,
                partSalaryRate: item.partSalaryRate
            }
        });

        // 人件費＞アルバイト調整額
        const listPartAdjustCost = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                partAdjustCost: item.partAdjustCost,
                partAdjustCostRate: item.partAdjustCostRate
            }
        });

        // 人件費＞人件費合計率
        const listTotalLaborCost = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalLaborCost: item.totalLaborCost,
                totalLaborCostRate: item.totalLaborCostRate
            }
        });

        // 人件費(退職者)
        const listRetireLaborCost = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                retireLaborCost: item.retireLaborCost,
                retireLaborCostRate: item.retireLaborCostRate
            }
        });

        // 仕入れ - 食材 - 【食材小口】
        const listPettyFoodTotal = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                pettyFoodTotal: item.pettyFoodTotal,
                pettyFoodTotalRate: item.pettyFoodTotalRate
            }
        });

        // 仕入れ > 食材合計
        const listFoodTotal = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                foodTotal: item.foodTotal,
                foodTotalRate: item.foodTotalRate
            }
        });

        // 仕入れ > 飲材 > 【飲材小口】
        const listPettyDrinkTotal = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                pettyDrinkTotal: item.pettyDrinkTotal,
                pettyDrinkTotalRate: item.pettyDrinkTotalRate
            }
        });

        // 仕入れ > 飲材合計
        const listDrinkTotal = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                drinkTotal: item.drinkTotal,
                drinkTotalRate: item.drinkTotalRate
            }
        });

        // 仕入れ > 前月棚卸額
        const listTermStart = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                termStart: item.termStart,
                termStartRate: item.termStartRate
            }
        });

        // 仕入れ > 当月棚卸額
        const listTermEnd = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                termEnd: item.termEnd,
                termEndRate: item.termEndRate
            }
        });

        // 仕入れ > 仕入原材料合計
        const listTotalPurchase = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalPurchase: item.totalPurchase,
                totalPurchaseRate: item.totalPurchaseRate
            }
        });

        // 通信費 > 通信費合計
        const listTotalCommunicationCost = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalCommunicationCost: item.totalCommunicationCost,
                totalCommunicationCostRate: item.totalCommunicationCostRate
            }
        });

        // 宣伝費 > 宣伝費合計
        const listTotalAdvertisingCost = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalAdvertisingCost: item.totalAdvertisingCost,
                totalAdvertisingCostRate: item.totalAdvertisingCostRate
            }
        });

        // 募集費 > 募集費合計
        const listTotalRecruitmentCost = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalRecruitmentCost: item.totalRecruitmentCost,
                totalRecruitmentCostRate: item.totalRecruitmentCostRate
            }
        });

        //   諸経費 > 防虫費
        const listInsectRepellent = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                insectRepellent: item.insectRepellent,
                insectRepellentRate: item.insectRepellentRate
            }
        });

        // 諸経費 > ゴミ処理費
        const listGarbageDisposalFee = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                garbageDisposalFee: item.garbageDisposalFee,
                garbageDisposalFeeRate: item.garbageDisposalFeeRate
            }
        });

        // 諸経費 > デザイン制作費
        const listDesignFee = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                designFee: item.designFee,
                designFeeRate: item.designFeeRate
            }
        });

        // 諸経費 > 諸経費合計
        const listTotalExpenses = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalExpenses: item.totalExpenses,
                totalExpensesRate: item.totalExpensesRate
            }
        });

        // 光熱費合計 > 電気 
        const listElectricBill = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                electricBill: item.electricBill,
                electricBillRate: item.electricBillRate
            }
        });


        // 光熱費合計 > 水道
        const listWaterFee = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                waterFee: item.waterFee,
                waterFeeRate: item.waterFeeRate
            }
        });

        // 光熱費合計 > ガス
        const listGasFee = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                gasFee: item.gasFee,
                gasFeeRate: item.gasFeeRate
            }
        });

        // 光熱費合計 > 光熱費合計
        const listTotalUtilityCosts = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalUtilityCosts: item.totalUtilityCosts,
                totalUtilityCostsRate: item.totalUtilityCostsRate
            }
        });

        // 家賃 > 家賃
        const listLandRent = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                landRent: item.landRent,
                landRentRate: item.landRentRate
            }
        });

        // 家賃 > 共益費
        const listCommonServiceFee = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                commonServiceFee: item.commonServiceFee,
                commonServiceFeeRate: item.commonServiceFeeRate
            }
        });

        // 家賃 > その他
        const listRentOther = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                rentOther: item.rentOther,
                rentOtherRate: item.rentOtherRate
            }
        });

        // 家賃 > 家賃合計
        const listTotalLandRent = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalLandRent: item.totalLandRent,
                totalLandRentRate: item.totalLandRentRate
            }
        });

        // 決済手数料 > 決済売上(税抜)
        const listTotalCcAmount = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalCcAmount: item.totalCcAmount,
                totalCcAmountRate: item.totalCcAmountRate
            }
        });

        // 決済手数料 > 手数料
        const listCcFee = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                ccFee: item.ccFee,
                ccFeeRate: item.ccFeeRate
            }
        });

        // 減価償却費 > 減価償却費
        const listDepreciationDetail = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                depreciationDetail: item.depreciationDetail,
                depreciationDetailRate: item.depreciationDetailRate
            }
        });

        // 減価償却費 > 店舗設備賃料
        const listStoreEquipmentRent = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                storeEquipmentRent: item.storeEquipmentRent,
                storeEquipmentRentRate: item.storeEquipmentRentRate
            }
        });

        // 減価償却費 > リース費
        const listLeasePayment = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                leasePayment: item.leasePayment,
                leasePaymentRate: item.leasePaymentRate
            }
        });

        // 減価償却費 > リース債務
        const listLeaseDebt = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                leaseDebt: item.leaseDebt,
                leaseDebtRate: item.leaseDebtRate
            }
        });

        // 減価償却費 > 減価償却費
        const listDepreciation = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                depreciation: item.depreciation,
                depreciationRate: item.depreciationRate
            }
        });

        // 賞与償却費
        const listBonusDepreciation = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                bonusDepreciation: item.bonusDepreciation,
                bonusDepreciationRate: item.bonusDepreciationRate
            }
        });

        // 修繕費
        const listRepairCosts = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                repairCosts: item.repairCosts,
                repairCostsRate: item.repairCostsRate
            }
        });

        // 共通費
        const listCommonFee = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                commonFee: item.commonFee,
                commonFeeRate: item.commonFeeRate
            }
        });

        // 支出総計
        const listTotalExpenditure = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalExpenditure: item.totalExpenditure,
                totalExpenditureRate: item.totalExpenditureRate
            }
        });

        // 売上(税抜) > POS売上
        const listNetSales = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.netSales;
        });

        // 売上(税抜) > その他売上合計
        const listOtherNetSales = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.otherNetSales;
        });

        // 売上(税抜) > 売上合計
        const listTotalNetSales = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.totalNetSales;
        });

        // 営業利益/率
        const listOrdinaryIncome = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                ordinaryIncome: item.ordinaryIncome,
                profitRate: item.profitRate
            }
        });

        // 予算/達成率
        const listSalesBudgetTotal = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                salesBudgetTotal: item.salesBudgetTotal,
                salesAchievementRate: item.salesAchievementRate
            }
        });

        // FLコスト/率
        const listTotalFlCost = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalFlCost: item.totalFlCost,
                totalFlRate: item.totalFlRate
            }
        });

        // FLAコスト/率
        const listTotalFlaCost = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalFlaCost: item.totalFlaCost,
                totalFlaRate: item.totalFlaRate
            }
        });

        // FLARコスト/率
        const listTotalFlarCost = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalFlarCost: item.totalFlarCost,
                totalFlarRate: item.totalFlarRate
            }
        });

        // FLAROコスト/率
        const listTotalFlaroCost = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return {
                totalFlaroCost: item.totalFlaroCost,
                totalFlaroRate: item.totalFlaroRate
            }
        });

        // 仕入れ > 食材 > data
        const foodDetailNameData: any = [];
        const foodDetailName = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.filter((item: any) => {
            return item.foodDetailName !== '';
        }).map((item: any, index: number) => {
            return item.foodDetailName.split(',');
        });

        const foodDetailAmount = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.foodDetailAmount.split(',');
        });

        const foodDetailAmountRate = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.foodDetailAmountRate.split(',');
        });

        foodDetailNameData.push(foodDetailAmount, foodDetailAmountRate);

        const drinkDetailNameData: any = [];
        const drinkDetailName = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.filter((item: any) => {
            return item.drinkDetailName !== '';
        }).map((item: any, index: number) => {
            return item.drinkDetailName.split(',');
        });

        const drinkDetailAmount = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.drinkDetailAmount.split(',');
        });

        const drinkDetailAmountRate = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.drinkDetailAmountRate.split(',');
        });

        drinkDetailNameData.push(drinkDetailAmount, drinkDetailAmountRate);

        // 通信費 > data
        const communicationDetailNameData: any = [];
        const communicationDetailName = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.filter((item: any) => {
            return item.communicationDetailName !== '';
        }).map((item: any, index: number) => {
            return item.communicationDetailName.split(',');
        });

        const communicationDetailAmount = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.communicationDetailAmount.split(',');
        });

        const communicationDetailAmountRate = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.communicationDetailAmountRate.split(',');
        });

        communicationDetailNameData.push(communicationDetailAmount, communicationDetailAmountRate);

        // 宣伝費 > data
        const advertisingDetailNameData: any = [];
        const advertisingDetailName = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.filter((item: any) => {
            return item.advertisingDetailName !== '';
        }).map((item: any, index: number) => {
            return item.advertisingDetailName.split(',');
        });

        const advertisingDetailAmount = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.advertisingDetailAmount.split(',');
        });

        const advertisingDetailAmountRate = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.advertisingDetailAmountRate.split(',');
        });

        advertisingDetailNameData.push(advertisingDetailAmount, advertisingDetailAmountRate);

        // 募集費 > data
        const recruitmentDetailNameData: any = [];
        const recruitmentDetailName = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.filter((item: any) => {
            return item.recruitmentDetailName !== '';
        }).map((item: any, index: number) => {
            return item.recruitmentDetailName.split(',');
        });

        const recruitmentDetailAmount = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.recruitmentDetailAmount.split(',');
        });

        const recruitmentDetailAmountRate = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.recruitmentDetailAmountRate.split(',');
        });

        recruitmentDetailNameData.push(recruitmentDetailAmount, recruitmentDetailAmountRate);

        // 諸経費 > data
        const expensesDetailNameData: any = [];
        const expensesDetailName = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.filter((item: any) => {
            return item.expensesDetailName !== '';
        }).map((item: any, index: number) => {
            return item.expensesDetailName.split(',');
        });

        const expensesDetailAmount = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.expensesDetailAmount.split(',');
        });

        const expensesDetailAmountRate = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.expensesDetailAmountRate.split(',');
        });

        expensesDetailNameData.push(expensesDetailAmount, expensesDetailAmountRate);

        // 売上(税抜) > その他売上 > data
        const otherSalesDetailName = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.filter((item: any) => {
            return item.otherSalesDetailName !== '';
        }).map((item: any, index: number) => {
            return item.otherSalesDetailName.split(',');
        });

        const otherSalesDetailAmount = incomeAndExpenditureScheduleMonthly && incomeAndExpenditureScheduleMonthly.map((item: any, index: number) => {
            return item.otherSalesDetailAmount.split(',');
        });

        const data = {
            footer: '',
            formName: '【月別】収支明細表',
            targetPeriod: year,
            targetStores: targetStoresData,
            headerInformation,
            searchResult,
            totalInformation: [],
        };

        const dataExcel = {
            footer: '',
            formName: '【月別】収支明細表',
            targetPeriod: year,
            targetStores: targetStoresData,
            headerInformation,
            searchResult,
            totalInformation: [],
            mergedRegions,
            codeColumnIndex
        };

        const handleExportCSV = () => downloadCsv(data);
        const handleExportExcel = () => downloadExcel(dataExcel);

        return (
            <div>
                {
                    roleScreen && roleScreen.downloadFlag === 1 && (
                        <FlexBox>
                            <FlexBoxItem>
                                <IconLabelButton
                                    onClick={handleExportCSV}
                                    iconType="download"
                                    text=" CSV出力"
                                />
                            </FlexBoxItem>
                            <FlexBoxItem>
                                <IconLabelButton
                                    onClick={handleExportExcel}
                                    iconType="download"
                                    text=" EXCEL出力"
                                />
                            </FlexBoxItem>
                        </FlexBox>
                    )
                }

                <div style={{ margin: '15px' }}>
                    <div className="templateTable newDesignTable EllipsisHeader" style={{ height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: '1px' }}>
                        <table className="table table-bordered text-nowrap titleColumn" style={{ width: 'auto' }}>
                            <thead>
                                <tr>
                                    <th colSpan={3} className="text-center stickyStyle fristColSticky" style={{ top: '0px', left: '0px', zIndex: 15, maxWidth: '200px', width: '200px', minWidth: '200px' }}>{orgCode + ' ' + orgName}</th>
                                    {
                                        listHeader.map((item: string[]) => {
                                            return <th colSpan={2} className="text-center stickyStyle" style={{ position: 'sticky', top: '0px' }}><span>{item}</span></th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowSpan={4} colSpan={2} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>人件費</td>
                                    <td className="stickyStyle fontWeightTitle bgWhite" style={{ left: '214px', zIndex: 10 }}>社員</td>
                                    {
                                        listEmployeeLaborCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.employeeLaborCost && item.employeeLaborCost < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.employeeLaborCost)}</td>
                                                <td style={{ color: item.employeeLaborCostRate && item.employeeLaborCostRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.employeeLaborCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td className="stickyStyle fontWeightTitle bgWhite" style={{ left: '214px', zIndex: 10 }} >アルバイト</td>
                                    {
                                        listPartSalary.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.partSalary && item.partSalary < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.partSalary)}</td>
                                                <td style={{ color: item.partSalaryRate && item.partSalaryRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.partSalaryRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }




                                </tr>
                                <tr>
                                    <td className="stickyStyle fontWeightTitle bgWhite" style={{ left: '214px', zIndex: 10 }}>アルバイト調整額</td>
                                    {
                                        listPartAdjustCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.partAdjustCost && item.partAdjustCost < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.partAdjustCost)}</td>
                                                <td style={{ color: item.partAdjustCostRate && item.partAdjustCostRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.partAdjustCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }



                                </tr>
                                <tr>
                                    <td className="stickyStyle fontWeightTitle bgWhite" style={{ left: '214px', zIndex: 10 }}>人件費合計</td>
                                    {
                                        listTotalLaborCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalLaborCost && item.totalLaborCost < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalLaborCost)}</td>
                                                <td style={{ color: item.totalLaborCostRate && item.totalLaborCostRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalLaborCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }



                                </tr>
                                <tr>
                                    <td colSpan={3} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }} >人件費(退職者)</td>
                                    {
                                        listRetireLaborCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.retireLaborCost && item.retireLaborCost < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.retireLaborCost)}</td>
                                                <td style={{ color: item.retireLaborCostRate && item.retireLaborCostRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.retireLaborCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td rowSpan={(foodDetailName[0] ? foodDetailName[0].length : 0) + (drinkDetailName[0] ? drinkDetailName[0].length : 0) + 7} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>仕入れ</td>
                                    <td rowSpan={foodDetailName && foodDetailName.length > 0 ? foodDetailName[0].length + 1 : 1} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>食材</td>
                                    <td className="stickyStyle fontWeightTitle bgWhite" style={{ left: '214px', zIndex: 10 }}>【食材小口】</td>

                                    {
                                        listPettyFoodTotal.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.pettyFoodTotal && item.pettyFoodTotal < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.pettyFoodTotal)}</td>
                                                <td style={{ color: item.pettyFoodTotalRate && item.pettyFoodTotalRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.pettyFoodTotalRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                {
                                    foodDetailName && foodDetailName.length > 0 && foodDetailName[0].map((item: any, index: number) => {
                                        return <React.Fragment>
                                            <tr>
                                                <td className="stickyStyle fontWeightTitle bgWhite" style={{ left: '214px', zIndex: 10 }}>{item}</td>

                                                {
                                                    listHeader && listHeader.map((itemHeader: any, indexHeader: number) => {
                                                        return <React.Fragment>
                                                            <td style={{ color: foodDetailNameData[0][indexHeader][index] && foodDetailNameData[0][indexHeader][index] < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(foodDetailNameData[0][indexHeader][index])}</td>
                                                            <td style={{ color: foodDetailNameData[1][indexHeader][index] && foodDetailNameData[1][indexHeader][index] < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(foodDetailNameData[1][indexHeader][index])}%</td>
                                                        </React.Fragment>
                                                    })
                                                }
                                            </tr>
                                        </React.Fragment>
                                    })
                                }
                                <tr>
                                    <td colSpan={2} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }} >食材合計</td>

                                    {
                                        listFoodTotal.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.foodTotal && item.foodTotal < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.foodTotal)}</td>
                                                <td style={{ color: item.foodTotalRate && item.foodTotalRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.foodTotalRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td rowSpan={drinkDetailName && drinkDetailName.length > 0 ? drinkDetailName[0].length + 1 : 1} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>飲材</td>
                                    <td className="stickyStyle fontWeightTitle bgWhite" style={{ left: '214px', zIndex: 10 }}>【飲材小口】</td>

                                    {
                                        listPettyDrinkTotal.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.pettyDrinkTotal && item.pettyDrinkTotal < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.pettyDrinkTotal)}</td>
                                                <td style={{ color: item.pettyDrinkTotalRate && item.pettyDrinkTotalRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.pettyDrinkTotalRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>

                                {
                                    drinkDetailName && drinkDetailName.length > 0 && drinkDetailName[0].map((item: any, index: number) => {
                                        return <React.Fragment>
                                            <tr>
                                                <td className="stickyStyle fontWeightTitle bgWhite" style={{ left: '214px', zIndex: 10 }}>{item}</td>
                                                {
                                                    listHeader && listHeader.map((itemHeader: any, indexHeader: number) => {
                                                        return <React.Fragment>
                                                            <td style={{ color: drinkDetailNameData[0][indexHeader][index] && drinkDetailNameData[0][indexHeader][index] < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(drinkDetailNameData[0][indexHeader][index])}</td>
                                                            <td style={{ color: drinkDetailNameData[1][indexHeader][index] && drinkDetailNameData[1][indexHeader][index] < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(drinkDetailNameData[1][indexHeader][index])}%</td>
                                                        </React.Fragment>
                                                    })
                                                }
                                            </tr>
                                        </React.Fragment>
                                    })
                                }

                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }} >飲材合計</td>

                                    {
                                        listDrinkTotal.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.drinkTotal && item.drinkTotal < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.drinkTotal)}</td>
                                                <td style={{ color: item.drinkTotalRate && item.drinkTotalRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.drinkTotalRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }} >前月棚卸額</td>

                                    {
                                        listTermStart.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.termStart && item.termStart < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.termStart)}</td>
                                                <td style={{ color: item.termStartRate && item.termStartRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.termStartRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }} >当月棚卸額</td>

                                    {
                                        listTermEnd.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.termEnd && item.termEnd < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.termEnd)}</td>
                                                <td style={{ color: item.termEndRate && item.termEndRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.termEndRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }} >仕入原材料合計</td>

                                    {
                                        listTotalPurchase.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalPurchase && item.totalPurchase < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalPurchase)}</td>
                                                <td style={{ color: item.totalPurchaseRate && item.totalPurchaseRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalPurchaseRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>

                                {
                                    communicationDetailName && communicationDetailName.length > 0 && communicationDetailName[0].map((item: any, index: number) => {
                                        return <React.Fragment>
                                            <tr>
                                                {
                                                    index === 0 && <td rowSpan={communicationDetailName && communicationDetailName.length > 0 ? communicationDetailName[0].length + 1 : 1} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>通信費</td>
                                                }
                                                <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>{item}</td>
                                                {
                                                    listHeader && listHeader.map((itemHeader: any, indexHeader: number) => {
                                                        return <React.Fragment>
                                                            <td style={{ color: communicationDetailNameData[0][indexHeader][index] && communicationDetailNameData[0][indexHeader][index] < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(communicationDetailNameData[0][indexHeader][index])}</td>
                                                            <td style={{ color: communicationDetailNameData[1][indexHeader][index] && communicationDetailNameData[1][indexHeader][index] < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(communicationDetailNameData[1][indexHeader][index])}%</td>
                                                        </React.Fragment>
                                                    })
                                                }
                                            </tr>
                                        </React.Fragment>
                                    })
                                }

                                <tr>
                                    {
                                        communicationDetailName.length <= 0 && <td className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>通信費</td>
                                    }
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>通信費合計</td>
                                    {/* <td className="stickyStyle fontWeightTitle" style={{ left: '214px', zIndex: 10, background: 'white' }}></td> */}

                                    {
                                        listTotalCommunicationCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalCommunicationCost && item.totalCommunicationCost < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalCommunicationCost)}</td>
                                                <td style={{ color: item.totalCommunicationCostRate && item.totalCommunicationCostRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalCommunicationCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>

                                {
                                    advertisingDetailName && advertisingDetailName.length > 0 && advertisingDetailName[0].map((item: any, index: number) => {
                                        return <React.Fragment>
                                            <tr>
                                                {
                                                    index === 0 && <td rowSpan={advertisingDetailName && advertisingDetailName.length > 0 ? advertisingDetailName[0].length + 1 : 1} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>宣伝費</td>
                                                }
                                                <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>{item}</td>
                                                {
                                                    listHeader && listHeader.map((itemHeader: any, indexHeader: number) => {
                                                        return <React.Fragment>
                                                            <td style={{ color: advertisingDetailNameData[0][indexHeader][index] && advertisingDetailNameData[0][indexHeader][index] < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(advertisingDetailNameData[0][indexHeader][index])}</td>
                                                            <td style={{ color: advertisingDetailNameData[1][indexHeader][index] && advertisingDetailNameData[1][indexHeader][index] < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(advertisingDetailNameData[1][indexHeader][index])}%</td>
                                                        </React.Fragment>
                                                    })
                                                }
                                            </tr>
                                        </React.Fragment>
                                    })
                                }

                                <tr>
                                    {
                                        advertisingDetailName.length <= 0 && <td className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>宣伝費</td>
                                    }
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>宣伝費合計</td>

                                    {
                                        listTotalAdvertisingCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalAdvertisingCost && item.totalAdvertisingCost < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalAdvertisingCost)}</td>
                                                <td style={{ color: item.totalAdvertisingCostRate && item.totalAdvertisingCostRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalAdvertisingCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>

                                {
                                    recruitmentDetailName && recruitmentDetailName.length > 0 && recruitmentDetailName[0].map((item: any, index: number) => {
                                        return <React.Fragment>
                                            <tr>
                                                {
                                                    index === 0 && <td rowSpan={recruitmentDetailName && recruitmentDetailName.length > 0 ? recruitmentDetailName[0].length + 1 : 1} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>募集費</td>
                                                }
                                                <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>{item}</td>
                                                {
                                                    listHeader && listHeader.map((itemHeader: any, indexHeader: number) => {
                                                        return <React.Fragment>
                                                            <td style={{ color: recruitmentDetailNameData[0][indexHeader][index] && recruitmentDetailNameData[0][indexHeader][index] < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(recruitmentDetailNameData[0][indexHeader][index])}</td>
                                                            <td style={{ color: recruitmentDetailNameData[1][indexHeader][index] && recruitmentDetailNameData[1][indexHeader][index] < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(recruitmentDetailNameData[1][indexHeader][index])}%</td>
                                                        </React.Fragment>
                                                    })
                                                }
                                            </tr>
                                        </React.Fragment>
                                    })
                                }

                                <tr>
                                    {
                                        recruitmentDetailName.length <= 0 && <td className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>募集費</td>
                                    }
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>募集費合計</td>
                                    {/* <td className="stickyStyle fontWeightTitle" style={{ left: '214px', zIndex: 10, background: 'white' }}></td> */}

                                    {
                                        listTotalRecruitmentCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalRecruitmentCost && item.totalRecruitmentCost < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalRecruitmentCost)}</td>
                                                <td style={{ color: item.totalRecruitmentCostRate && item.totalRecruitmentCostRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalRecruitmentCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td rowSpan={expensesDetailName && expensesDetailName.length > 0 ? expensesDetailName[0].length + 4 : 4} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>諸経費</td>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10, background: 'white' }}>防虫費</td>

                                    {
                                        listInsectRepellent.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.insectRepellent && item.insectRepellent < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.insectRepellent)}</td>
                                                <td style={{ color: item.insectRepellentRate && item.insectRepellentRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.insectRepellentRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10, background: 'white' }}>ゴミ処理費</td>

                                    {
                                        listGarbageDisposalFee.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.garbageDisposalFee && item.garbageDisposalFee < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.garbageDisposalFee)}</td>
                                                <td style={{ color: item.garbageDisposalFeeRate && item.garbageDisposalFeeRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.garbageDisposalFeeRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>デザイン制作費</td>

                                    {
                                        listDesignFee.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.designFee && item.designFee < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.designFee)}</td>
                                                <td style={{ color: item.designFeeRate && item.designFeeRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.designFeeRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>

                                {
                                    expensesDetailName && expensesDetailName.length > 0 && expensesDetailName[0].map((item: any, index: number) => {
                                        return <React.Fragment>
                                            <tr>
                                                <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>{item}</td>
                                                {
                                                    listHeader && listHeader.map((itemHeader: any, indexHeader: number) => {
                                                        return <React.Fragment>
                                                            <td style={{ color: expensesDetailNameData[0][indexHeader][index] && expensesDetailNameData[0][indexHeader][index] < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(expensesDetailNameData[0][indexHeader][index])}</td>
                                                            <td style={{ color: expensesDetailNameData[1][indexHeader][index] && expensesDetailNameData[1][indexHeader][index] < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(expensesDetailNameData[1][indexHeader][index])}%</td>
                                                        </React.Fragment>
                                                    })
                                                }
                                            </tr>
                                        </React.Fragment>
                                    })
                                }

                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>諸経費合計</td>
                                    {/* <td className="stickyStyle fontWeightTitle" style={{ left: '214px', zIndex: 10, background: 'white' }}></td> */}

                                    {
                                        listTotalExpenses.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalExpenses && item.totalExpenses < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalExpenses)}</td>
                                                <td style={{ color: item.totalExpensesRate && item.totalExpensesRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalExpensesRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td rowSpan={4} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>光熱費合計</td>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>電気</td>

                                    {
                                        listElectricBill.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.electricBill && item.electricBill < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.electricBill)}</td>
                                                <td style={{ color: item.electricBillRate && item.electricBillRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.electricBillRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>水道</td>

                                    {
                                        listWaterFee.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.waterFee && item.waterFee < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.waterFee)}</td>
                                                <td style={{ color: item.waterFeeRate && item.waterFeeRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.waterFeeRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>ガス</td>

                                    {
                                        listGasFee.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.gasFee && item.gasFee < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.gasFee)}</td>
                                                <td style={{ color: item.gasFeeRate && item.gasFeeRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.gasFeeRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>光熱費合計</td>
                                    {/* <td className="stickyStyle fontWeightTitle" style={{ left: '214px', zIndex: 10, background: 'white' }}></td> */}

                                    {
                                        listTotalUtilityCosts.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalUtilityCosts && item.totalUtilityCosts < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalUtilityCosts)}</td>
                                                <td style={{ color: item.totalUtilityCostsRate && item.totalUtilityCostsRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalUtilityCostsRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td rowSpan={4} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>家賃</td>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>家賃</td>

                                    {
                                        listLandRent.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.landRent && item.landRent < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.landRent)}</td>
                                                <td style={{ color: item.landRentRate && item.landRentRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.landRentRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10, background: 'white' }}>共益費</td>

                                    {
                                        listCommonServiceFee.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.commonServiceFee && item.commonServiceFee < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.commonServiceFee)}</td>
                                                <td style={{ color: item.commonServiceFeeRate && item.commonServiceFeeRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.commonServiceFeeRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>その他</td>

                                    {
                                        listRentOther.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.rentOther && item.rentOther < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.rentOther)}</td>
                                                <td style={{ color: item.rentOtherRate && item.rentOtherRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.rentOtherRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>家賃合計</td>

                                    {
                                        listTotalLandRent.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalLandRent && item.totalLandRent < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalLandRent)}</td>
                                                <td style={{ color: item.totalLandRentRate && item.totalLandRentRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalLandRentRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td rowSpan={2} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>決済手数料</td>
                                    <td className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}></td>
                                    <td className="stickyStyle fontWeightTitle bgWhite" style={{ left: '214px', zIndex: 10 }}>決済売上(税抜)</td>

                                    {
                                        listTotalCcAmount.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalCcAmount && item.totalCcAmount < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalCcAmount)}</td>
                                                <td style={{ color: item.totalCcAmountRate && item.totalCcAmountRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalCcAmountRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}></td>
                                    <td className="stickyStyle fontWeightTitle bgWhite" style={{ left: '214px', zIndex: 10 }}>手数料</td>

                                    {
                                        listCcFee.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.ccFee && item.ccFee < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.ccFee)}</td>
                                                <td style={{ color: item.ccFeeRate && item.ccFeeRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.ccFeeRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td rowSpan={5} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>減価償却費</td>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>減価償却費</td>

                                    {
                                        listDepreciationDetail.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.depreciationDetail && item.depreciationDetail < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.depreciationDetail)}</td>
                                                <td style={{ color: item.depreciationDetailRate && item.depreciationDetailRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.depreciationDetailRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>店舗設備賃料</td>

                                    {
                                        listStoreEquipmentRent.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.storeEquipmentRent && item.storeEquipmentRent < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.storeEquipmentRent)}</td>
                                                <td style={{ color: item.storeEquipmentRentRate && item.storeEquipmentRentRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.storeEquipmentRentRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>リース費</td>

                                    {
                                        listLeasePayment.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.leasePayment && item.leasePayment < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.leasePayment)}</td>
                                                <td style={{ color: item.leasePaymentRate && item.leasePaymentRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.leasePaymentRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>リース債務</td>

                                    {
                                        listLeaseDebt.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.leaseDebt && item.leaseDebt < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.leaseDebt)}</td>
                                                <td style={{ color: item.leaseDebtRate && item.leaseDebtRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.leaseDebtRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>減価償却費</td>

                                    {
                                        listDepreciation.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.depreciation && item.depreciation < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.depreciation)}</td>
                                                <td style={{ color: item.depreciationRate && item.depreciationRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.depreciationRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={3} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>賞与償却費</td>

                                    {
                                        listBonusDepreciation.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.bonusDepreciation && item.bonusDepreciation < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.bonusDepreciation)}</td>
                                                <td style={{ color: item.bonusDepreciationRate && item.bonusDepreciationRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.bonusDepreciationRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={3} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>修繕費</td>

                                    {
                                        listRepairCosts.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.repairCosts && item.repairCosts < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.repairCosts)}</td>
                                                <td style={{ color: item.repairCostsRate && item.repairCostsRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.repairCostsRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={3} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>共通費</td>

                                    {
                                        listCommonFee.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.commonFee && item.commonFee < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.commonFee)}</td>
                                                <td style={{ color: item.commonFeeRate && item.commonFeeRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.commonFeeRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={3} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>支出総計</td>

                                    {
                                        listTotalExpenditure.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalExpenditure && item.totalExpenditure < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalExpenditure)}</td>
                                                <td style={{ color: item.totalExpenditureRate && item.totalExpenditureRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalExpenditureRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td rowSpan={otherSalesDetailName && otherSalesDetailName[0] && otherSalesDetailName[0].length > 0 ? otherSalesDetailName[0].length + 3 : 3} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>売上(税抜)</td>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10, background: 'white' }}>POS売上</td>

                                    {
                                        listNetSales.map((item: any) => {
                                            return <React.Fragment>
                                                <td colSpan={2} style={{ color: item && item < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'center' }}>{formatter.format(item)}</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>

                                {
                                    otherSalesDetailName && otherSalesDetailName.length > 0 && otherSalesDetailName[0].map((item: any, index: number) => {
                                        return <React.Fragment>
                                            <tr>
                                                {index === 0 && (
                                                    <td rowSpan={otherSalesDetailName && otherSalesDetailName[0] && otherSalesDetailName[0].length > 0 ? otherSalesDetailName[0].length : 1} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>その他売上</td>
                                                )}
                                                <td className="stickyStyle fontWeightTitle bgWhite" style={{ left: '214px', zIndex: 10 }}>{item}</td>
                                                {
                                                    listHeader && listHeader.map((itemHeader: any, indexHeader: number) => {
                                                        return <React.Fragment>
                                                            <td colSpan={2} style={{ color: otherSalesDetailAmount[indexHeader][index] && otherSalesDetailAmount[indexHeader][index] < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'center' }}>{formatter.format(otherSalesDetailAmount[indexHeader][index])}</td>
                                                        </React.Fragment>
                                                    })
                                                }
                                            </tr>
                                        </React.Fragment>
                                    })
                                }

                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>その他売上合計</td>

                                    {
                                        listOtherNetSales.map((item: any) => {
                                            return <React.Fragment>
                                                <td colSpan={2} style={{ color: item && item < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'center' }}>{formatter.format(item)}</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={2} className="stickyStyle fontWeightTitle bgWhite" style={{ left: '107px', zIndex: 10 }}>売上合計</td>

                                    {
                                        listTotalNetSales.map((item: any) => {
                                            return <React.Fragment>
                                                <td colSpan={2} style={{ color: item && item < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'center' }}>{formatter.format(item)}</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={3} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>営業利益/率</td>

                                    {
                                        listOrdinaryIncome.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.ordinaryIncome && item.ordinaryIncome < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.ordinaryIncome)}</td>
                                                <td style={{ color: item.profitRate && item.profitRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.profitRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={3} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>予算/達成率</td>

                                    {
                                        listSalesBudgetTotal.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.salesBudgetTotal && item.salesBudgetTotal < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.salesBudgetTotal)}</td>
                                                <td style={{ color: item.salesAchievementRate && item.salesAchievementRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.salesAchievementRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={3} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>FLコスト/率</td>

                                    {
                                        listTotalFlCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalFlCost && item.totalFlCost < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalFlCost)}</td>
                                                <td style={{ color: item.totalFlRate && item.totalFlRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalFlRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={3} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>FLAコスト/率</td>

                                    {
                                        listTotalFlaCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalFlaCost && item.totalFlaCost < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalFlaCost)}</td>
                                                <td style={{ color: item.totalFlaRate && item.totalFlaRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalFlaRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={3} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>FLARコスト/率</td>

                                    {
                                        listTotalFlarCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalFlarCost && item.totalFlarCost < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalFlarCost)}</td>
                                                <td style={{ color: item.totalFlarRate && item.totalFlarRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalFlarRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                                <tr>
                                    <td colSpan={3} className="stickyStyle fristColSticky fontWeightTitle bgWhite" style={{ left: '0px', zIndex: 10 }}>FLAROコスト/率</td>

                                    {
                                        listTotalFlaroCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalFlaroCost && item.totalFlaroCost < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalFlaroCost)}</td>
                                                <td style={{ color: item.totalFlaroRate && item.totalFlaroRate < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalFlaroRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };

export default IncomeAndExpenditureScheduleMonthlyTable;
