export interface SalesSuppliersMST {
    id: {
      companyCode: string,
      transactionType: string,
      suppliersCode: string,
    },
    suppliersName: string,
    dispOrder: number,
    applyStartDate: Date,
    applyEndDate: Date,
    createDate: Date,
    createUser: string,
    updateDate: Date,
    updateUser: string,
  }

export default class SalesSuppliersMSTDomain {
  constructor(private rawData: SalesSuppliersMST) {
    // do nothing
  }

  static generateInitial(): SalesSuppliersMSTDomain {
    return new SalesSuppliersMSTDomain({
      id: {
        companyCode: '',
        transactionType: '',
        suppliersCode: '',
      },
      suppliersName: '',
      dispOrder: 0,
      applyStartDate: new Date(),
      applyEndDate: new Date(),
      createDate: new Date(),
      createUser: '',
      updateDate: new Date(),
      updateUser: '',
    });
  }

  getRawData(): SalesSuppliersMST {
    return this.rawData;
  }

  get companyCode(): string {
    return this.rawData.id.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.id.companyCode = companyCode;
  }

  get transactionType(): string {
    return this.rawData.id.transactionType;
  }

  set transactionType(transactionType: string) {
    this.rawData.id.transactionType = transactionType;
  }

  get suppliersCode(): string {
    return this.rawData.id.suppliersCode;
  }

  set suppliersCode(suppliersCode: string) {
    this.rawData.id.suppliersCode = suppliersCode;
  }

  get suppliersName(): string {
    return this.rawData.suppliersName;
  }

  set suppliersName(suppliersName: string) {
    this.rawData.suppliersName = suppliersName;
  }

  get dispOrder(): number {
    return this.rawData.dispOrder;
  }

  set dispOrder(dispOrder: number) {
    this.rawData.dispOrder = dispOrder;
  }

  get applyStartDate(): Date {
    return this.rawData.applyStartDate;
  }

  set applyStartDate(applyStartDate: Date) {
    this.rawData.applyStartDate = applyStartDate;
  }

  get applyEndDate(): Date {
    return this.rawData.applyEndDate;
  }

  set applyEndDate(applyEndDate: Date) {
    this.rawData.applyEndDate = applyEndDate;
  }

  get createDate(): Date {
    return this.rawData.createDate;
  }

  set createDate(createDate: Date) {
    this.rawData.createDate = createDate;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateDate(): Date {
    return this.rawData.updateDate;
  }

  set updateDate(updateDate: Date) {
    this.rawData.updateDate = updateDate;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }
}
