/** @jsx jsx */
import React, { useState, useMemo } from 'react';

import FormLabel from 'components/atoms/Form/FormLabel';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import { css, jsx } from '@emotion/core';
import { grayScale, textFontSize } from 'components/styles';
import { ChromePicker } from 'react-color';

const styles = {
  wrapper: css({
    display: 'flex',
    maxWidth: '96px',
  }),
  wrapperFullWidth: css({
    display: 'flex',
    width: '100%',
  }),
  input: css({
    flex: 1,
    width: '32px',
    padding: '7px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    color: grayScale.gray100,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: 'none',
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px 0 0 4px',
  }),
};

const ColorForm: React.FC<{
  name: string;
  label: string;
  color: string;
  isFullW?: boolean;
  required?: boolean;
  onChange: (event: any) => void;
  errorMsg?: string;
}> = ({
  name, label, onChange, errorMsg, required, isFullW = false, color,
}) => {
  const [pickerVisible, setPickerVisible] = useState(false);
  const [colorPicker, setColorPicker] = useState(color);
  useMemo(() => {
    setColorPicker(color);
  }, [color]);
  return (
    <div>
      <VerticalLabelFormLayout
        label={(
          <FormLabel
            label={label}
            required={required}
          />
      )}
        input={(
          <div css={css(isFullW ? styles.wrapperFullWidth : styles.wrapper)}>
            <input
              css={css(styles.input, isFullW ? {
                width: '100%',
                backgroundColor: colorPicker,
              } : { backgroundColor: colorPicker })}
              value={colorPicker}
              name={name}
              onFocus={() => { setPickerVisible(true); }}
              onBlur={(e) => {
                onChange(e);
                setPickerVisible(false);
              }}
            />
          </div>
      )}
        errorMsg={errorMsg}
      />
      { pickerVisible === true ? (
        <ChromePicker
          color={colorPicker}
          onChange={(e) => {
            setColorPicker(e.hex);
          }}
        />
      ) : ''}
    </div>
  );
};

export default ColorForm;
