/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import ForgotPasswordForm from 'components/organismos/login/ForgotPasswordForm';
import PrimaryLogo from 'components/atoms/Logo/Primary';

const styles = {
  formWrapper: css({
    background: '#F2F5F9',
    margin: '3em auto',
    padding: '0 1em',
    maxWidth: '480px',
  }),
  h1: css({
    textAlign: 'center',
    padding: '0 0',
  }),
  h2: css({
    textAlign: 'center',
    padding: '0 0',
  }),
  form: css({
    padding: '0 1.5em',
  }),
  standardLogo: css({
    marginLeft: 'auto',
    marginRight: 'auto',
    verticalAlign: 'middle',
    width: '100px',
    height: '100px',
    padding: '8px 12px',
  }),
};

const ForgotPasswordPage: React.FC = () => (
  <div css={styles.formWrapper}>
    <div css={styles.h1}>
      <PrimaryLogo css={styles.standardLogo} />
    </div>
    <h2 css={styles.h2}>パスワードリセット</h2>
    <ForgotPasswordForm />
  </div>
);

export default ForgotPasswordPage;
