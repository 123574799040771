import React from 'react';

import FormContents from 'components/atoms/Form/FormContents';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { Link, useParams } from 'react-router-dom';
import ConfirmModal from 'components/organismos/ConfirmModal';
import EmploymentInputAddForm from './EmploymentInputAddForm';
import EmploymentInputHintDisplay from './EmploymentInputHintDisplay';
import { useEmploymentAddForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';


const EmploymentAddForm: React.FC = () => {
  const { isEdit, employmentId, applyStartDate } = useParams();
  const {
    formik, confirmModalOpen, closeConfirmModal, termMst,
  } = useEmploymentAddForm(Number(isEdit), employmentId, applyStartDate);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <div style={{ marginLeft: '20px' }}>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ float: 'left', overflow: 'auto' }}>
          <div style={{ minWidth: '1024px' }}>
            <FormContents>
              <p>雇用形態マスタは勤怠を管理するために必要な項目です。</p>
              <EmploymentInputAddForm formik={formik} termMst={termMst} />
              <FormSubmitArea>
                {
                  roleScreen && roleScreen.editable === 1 && (
                    <div style={{ marginRight: '12px' }}>
                      <Button
                        text={employmentId && isEdit === '2' ? '雇用形態を更新' : '雇用形態を登録'}
                        onClick={formik.handleSubmit}
                      />
                    </div>
                  )
                }
                <Link to="/masterEmploymentForm">
                  <Button
                    text="戻る"
                    ghost={true}
                  />
                </Link>

                <ConfirmModal
                  title="確認メッセージ"
                  open={confirmModalOpen}
                  closeHandler={closeConfirmModal}
                  onSubmit={formik.handleSubmit}
                  content={`${employmentId && isEdit === '2' ? '更新' : '登録'}します。よろしいですか？`}
                  submitText={employmentId && isEdit === '2' ? '更新' : '登録'}
                />

              </FormSubmitArea>
            </FormContents>
          </div>
        </div>
        <div style={{ right: '0', position: 'sticky', top: '0' }}>
          <EmploymentInputHintDisplay formik={formik} />
        </div>
        <div style={{ clear: 'both' }}> </div>
      </form>
    </div>
  );
};

export default EmploymentAddForm;
