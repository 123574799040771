import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import PercentRegistration from 'components/organismos/master/atendanceManagement/PercentRegistration';

const PercentRegistrationPage = () => (
  <SidebarTemplate pageTitle="歩合登録">
    <PercentRegistration />
  </SidebarTemplate>
);

export default PercentRegistrationPage;
