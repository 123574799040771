import React, { SVGProps } from 'react';

const Lock: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M13.95 7.23h-.282V5.168a4.167 4.167 0 10-8.336 0V7.23h-.281c-1.008 0-1.824.82-1.824 1.829v5.113C3.227 15.18 4.043 16 5.05 16h8.898c1.008 0 1.824-.82 1.824-1.828V9.059A1.827 1.827 0 0013.95 7.23zM7.136 5.168c0-.656.261-1.238.691-1.672A2.346 2.346 0 019.5 2.805c.656 0 1.242.261 1.672.691.43.434.691 1.016.691 1.672V7.23H7.137zm2.89 7.016l.29 1.863H8.683l.289-1.863a1.3 1.3 0 111.054 0zm0 0"
        fill="#333"
      />
    </g>
  </svg>
);

export default Lock;
