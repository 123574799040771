import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import AdvertisingMediaAddForm from 'components/organismos/master/sales/AdvertisingMediaAddForm';
import { useParams } from 'react-router-dom';

const AdvertisingMediaAddPage = () => {
  const { advertisingMediaMstCode } = useParams<{ advertisingMediaMstCode: string}>();
  return (
    <SidebarTemplate pageTitle={advertisingMediaMstCode ? '広告媒体編集' : '広告媒体作成'}>
      <AdvertisingMediaAddForm />
    </SidebarTemplate>
  );
};

export default AdvertisingMediaAddPage;
