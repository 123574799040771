import moment from 'moment';
/**
 * API 祝日マスタ
 */

export default class SalesMenuTypeDomain {
  constructor(private rawData: Array<any>) {
    // do nothing
  }

  static generateInitial(): SalesMenuTypeDomain {
    return new SalesMenuTypeDomain(['', '', moment(new Date()).format('YYYY/MM/DD'), moment(new Date('9999/12/31')).format('YYYY/MM/DD')]);
  }

  getRawData(): Array<any> {
    return this.rawData;
  }

  get listFood(): string {
    return this.rawData[0] ? this.rawData[0] : '';
  }

  set listFood(listFood:string) {
    this.rawData[0] = listFood;
  }

  get listDrink(): string {
    return this.rawData[1] ? this.rawData[1] : '';
  }

  set listDrink(listDrink:string) {
    this.rawData[1] = listDrink;
  }

  get applyStartDate(): string {
    return this.rawData[2];
  }

  set applyStartDate(date:string) {
    this.rawData[2] = date;
  }

  get applyStartDateStr(): string {
    return moment(new Date(this.rawData[2])).format('YYYY年MM月');
  }

  get applyEndDate(): string {
    return this.rawData[3];
  }

  set applyEndDate(date:string) {
    this.rawData[3] = date;
  }

  get applyEndDateStr(): string {
    return this.rawData[3] === '9999-12-31' || this.rawData[3] === '9999/12/31' ? '' : moment(new Date(this.rawData[3])).format('YYYY年MM月');
  }
}
