import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
// import { getPrefectureList } from 'api/prefecture';
import { getList } from 'api/paidGrantPattern';

/**
 * 有給付与パターンの選択ボックス用データを取得
 */
export const usePaidHolidayPatternOptions = (): Array<OptionType> => {
  const [paidHolidayPatternOptions, setPaidHolidayPatternOption] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const getDetails = false;
    getList(companyCode, getDetails).then((paidHolidayPatterns) => {
      setPaidHolidayPatternOption(paidHolidayPatterns.map((paidHolidayPattern: { paidHolidayPatternId: any; paidHolidayPatternName: any; }) => ({
        value: paidHolidayPattern.paidHolidayPatternId,
        label: paidHolidayPattern.paidHolidayPatternName,
      })));
    });
  }, []);
  return paidHolidayPatternOptions;
};

export default usePaidHolidayPatternOptions;
