/** @jsx jsx */
import React, { useMemo, useState } from 'react';
import TabControl from 'components/atoms/TabControl';
import LableTitle from 'components/atoms/LableTitle';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import { css, jsx } from '@emotion/core';
import HolidayMasterPage from './HolidayMasterPage';
import SpecialDateMasterPage from './SpecialDateMasterPage';
import BusinessMasterPage from './BusinessMasterPage';
import VacationMasterPage from './VacationMasterPage';
import TimeZonePage from './TimeZonePage';
import PrescribedHoursPage from './PrescribedHoursPage';
import PaidGrantPatternPage from './PaidGrantPatternPage';
import TimeSettingPage from './TimeSettingsPage';
import PrescribedDaysPage from './PrescribedDaysPage';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const AgreementMasterPage: React.FC = () => {
  
  const currentTab = sessionStorage.getItem('currentTab') ? sessionStorage.getItem('currentTab')! : '1';
  const [value, setValue] = useState(currentTab);
  const [toastMessage, setToastMessage] = useState('');

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const result = useMemo(() => {
    sessionStorage.setItem('currentTab', value);

    switch (value) {
      case '1': {
        setToastMessage('労務規約項目マスタは雇用形態を登録するのに必要な項目です。');
        return <HolidayMasterPage roleScreen={roleScreen} />;
      }
      case '2': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <SpecialDateMasterPage roleScreen={roleScreen} />;
      }
      case '3': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <VacationMasterPage roleScreen={roleScreen} />;
      }
      case '4': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <PaidGrantPatternPage roleScreen={roleScreen} />;
      }
      case '5': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <BusinessMasterPage roleScreen={roleScreen} />;
      }
      case '6': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <TimeZonePage roleScreen={roleScreen} />;
      }
      case '7': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <PrescribedDaysPage roleScreen={roleScreen} />;
      }
      case '8': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <PrescribedHoursPage roleScreen={roleScreen} />;
      }
      default:
        return <TimeSettingPage roleScreen={roleScreen} />;
    }
  }, [value, roleScreen]);

  const style = css({
    paddingLeft: '16px',
    paddingRight: '16px',
    overflowX: 'auto',
  });

  return (
    <SidebarTemplate pageTitle="労務規約項目マスタ">
      <LableTitle title={toastMessage} />
      <div css={style}>
        <TabControl
          items={[
            { value: '1', display: '祝日' },
            { value: '2', display: '特賃日' },
            { value: '3', display: '休暇' },
            { value: '4', display: '有給' },
            { value: '5', display: '業務' },
            { value: '6', display: '時間帯' },
            { value: '7', display: '所定日数' },
            { value: '8', display: '所定時間' },
            { value: '9', display: '勤怠共有' },
          ]}
          setValue={(val: string) => {
            if (val === '1') {
              sessionStorage.setItem('selectedOrgCodeHolidayMaster', 'all');
              sessionStorage.setItem('selectedYearHolidayMaster', new Date().getFullYear().toString());
            }
            setValue(val);
          }}
          currentValue={value}
          isFixed={true}
        />
      </div>

      <div>
        {result}
      </div>
    </SidebarTemplate>

  );
};

export default AgreementMasterPage;
