import React from 'react';
import TimeSettingForm from 'components/organismos/master/labor/TimeSettingsForm';

const TimeSettingPage: React.FC<{
  roleScreen?: any
}> = ({ roleScreen }) => (
  <TimeSettingForm roleScreen={roleScreen} />
);

export default TimeSettingPage;
