
import moment from 'moment';
/**
 * API 勤怠再計算
 */

export interface SubstituteHolidayStatus {
    staffCode: string,
    staffName: string,
    orgCode: string,
    orgName: string,
    grantDaysNum: number,
    digestedDaysNum: number,
    extinctionDaysNum: number,
    substituteDigestedtHistoryList: [],
    fromDate: Date,
    toDate: Date,
}


export default class SubstituteHolidayStatusDomain {
  constructor(private rawData: SubstituteHolidayStatus) {
    // do nothing
  }

  static generateInitial(): SubstituteHolidayStatusDomain {
    return new SubstituteHolidayStatusDomain({
      staffCode: '',
      staffName: '',
      orgCode: '',
      orgName: '',
      grantDaysNum: 0,
      digestedDaysNum: 0,
      extinctionDaysNum: 0,
      substituteDigestedtHistoryList: [],
      fromDate: new Date(),
      toDate: new Date(),
    });
  }

  getRawData(): SubstituteHolidayStatus {
    return this.rawData;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode:string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  // get
  get grantDaysNum():number {
    return this.rawData.grantDaysNum;
  }

  get grantDaysNumStr():string {
    return `${this.rawData.grantDaysNum}日`;
  }

  get digestedDaysNum():number {
    return this.rawData.digestedDaysNum;
  }

  get digestedDaysNumStr():string {
    return `${this.rawData.digestedDaysNum}日`;
  }

  get extinctionDaysNum():number {
    return this.rawData.extinctionDaysNum;
  }

  get extinctionDaysNumStr():string {
    return `${this.rawData.extinctionDaysNum}日`;
  }

  get fromDate(): string {
    return moment(moment(this.rawData.fromDate).toDate()).format('YYYY年MM月DD日');
  }

  get toDate(): string {
    return moment(moment(this.rawData.toDate).toDate()).format('YYYY年MM月DD日');
  }

  // custom data use DataTable
}
