/** @jsx jsx */
import React, {
  useCallback, useMemo } from 'react';
import { Column, Row as tableRow } from 'react-table';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import SecondaryButton from 'components/atoms/Button/SecondaryButton';
import TextTooltip from 'components/atoms/TextTooltip';
import DataTable from 'components/organismos/DataTable/DataTable';
import AttendAchievementLockDomain from 'domain/master/attend/attendAchievementLockDomain';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import moment from 'moment';
import BodyText from 'components/atoms/BodyText';
import BlockUI from 'components/molecules/BlockUi';
import useToastNotification from 'hooks/useToastNotification';
import { selectedRow, useAttendAchievementLock } from './hooks';
import DatePickerForm from 'components/molecules/DatePickerForm';
import OrganizationFilteredTree from "components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree";
import Checkbox from 'components/molecules/Checkbox';
import Radio from 'components/molecules/Radio';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { SessionStorageKey } from 'utility/constants';
import { ErrorMessage, WarningMessage } from 'utility/messages';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const styles = {
  table: css`
    table tbody tr {
      height: 30px;
    }
    table th:nth-child(1) {
      width: 30px;
    }
    table th:nth-child(2) {
      width: 120px;
    }
    table th:nth-child(3) {
      width: 110px;
    }
    table th:nth-child(4) {
      width: 400px;
    }
    table th:nth-child(5) {
      width: 100px;
    }
    table th:nth-child(6) {
      text-align: center;
      width: 100px;
    }
    table th:nth-child(7) {
      text-align: center;
      width: 110px;
    }
    table th:nth-child(8) {
      text-align: center;
      text-align: center;
      width: 130px;
    }
    table th:nth-child(9) {
      text-align: center;
      width: 100px;
    }
    table td:nth-child(6) {
      text-align: center;
      padding-right: 22px;
    }
    table td:nth-child(7) {
      text-align: center;
      padding-right: 32px;
    }
    table td:nth-child(8) {
      text-align: center;
      padding-right: 22px;
    }
    table td:nth-child(9) {
      text-align: center;
      padding-right: 32px;
    }
  `,
};

const AttendAchievementLockForm = () => {
    const loginStaffCode = sessionStorage.getItem(SessionStorageKey.LoginUser.STAFF_CODE) || '';
    const { errorNotification } = useToastNotification();
    const {
      isLoading, setLoading,
      targetDateFrom, setTargetDateFrom,
      targetDateTo, setTargetDateTo,
      categoryArr, setCategoryArr,
      orgCode, setOrgCode,
      lockStatus, setLockStatus,
      isError, setIsError,
      isAlertOvertime, setIsAlertOvertime,
      isAlertHolidaywork, setIsAlertHolidaywork,
      isDisapproval, setIsDisapproval,
      isLockable, setIsLockable,
      availableDailyShift, setAvailableDailyShift,
      availableAttendApplication, setAvailableAttendApplication,
      attendAchievementLockList, setAttendAchievementLockList,
      selectedRows, setSelectedRows,
      confirmModalOpen, setConfirmModalOpen,
      closeConfirmModal,
      lockAttendAchievement,
      unlockAttendAchievement,
    } = useAttendAchievementLock();

    // Check prototype role staff
    const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

    const getPageRows = (flatRows: Array<tableRow<AttendAchievementLockDomain>>, pageIndex: number, pageSize: number): selectedRow[] => {
      return flatRows.slice(pageIndex * pageSize, pageSize * (pageIndex + 1))
                      .map(r => ({
                          rowIndex   : parseInt(r.id),
                          targetDate : r.original.targetDate,
                          orgCode    : r.original.orgCode,
                          lockStatus : r.original.lockStatus,
                          lockable: r.original.errorCount === 0 && r.original.disapprovalCount === 0,
                        } as selectedRow));
    };

    const columns: Array<Column<AttendAchievementLockDomain>> = useMemo(() => [
      {
        Header: (cell: {
          flatRows:Array<tableRow<AttendAchievementLockDomain>>;
          state:any;
        }) => (
          <div style={{ textAlign: 'center', maxHeight: '20px' }}>
            <Checkbox
              id="checkbox_all"
              name="checkbox_all"
              value=""
              checked={(() => {
                const pageRows = getPageRows(cell.flatRows, cell.state.pageIndex, cell.state.pageSize);
                return pageRows.length > 0 ? pageRows.every(r => selectedRows.some(s => s.rowIndex === r.rowIndex)) : false
              })()}
              onChange={e => {
                if (!e.target.checked) {
                  setSelectedRows(selectedRows.filter(s => !getPageRows(cell.flatRows, cell.state.pageIndex, cell.state.pageSize).some(r => r.rowIndex === s.rowIndex)));
                } else {
                  setSelectedRows([
                    ...selectedRows.filter(s => !getPageRows(cell.flatRows, cell.state.pageIndex, cell.state.pageSize).some(r => r.rowIndex === s.rowIndex)),
                    ...getPageRows(cell.flatRows, cell.state.pageIndex, cell.state.pageSize)
                  ]);
                }
              }}
            />
          </div>
        ),
        disableSortBy: true,
        accessor: 'checkbox',
        id: '',
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendAchievementLockDomain; index: number };
          data: any;
        }) => (
          <div style={{ textAlign: 'center', maxHeight: '20px' }}>
            <Checkbox
              id={`checkbox_${cell.row.index}`}
              name="name"
              label=""
              value={`${cell.row.index}`}
              checked={selectedRows.some(r => r.rowIndex === cell.row.index)}
              onChange={e => {
                if (!e.target.checked) {
                  setSelectedRows(selectedRows.filter(s => s.rowIndex !== cell.row.index));
                } else {
                  setSelectedRows([
                    ...selectedRows,
                    {
                      rowIndex   : cell.row.index,
                      targetDate : cell.row.original.targetDate,
                      orgCode    : cell.row.original.orgCode,
                      lockStatus : cell.row.original.lockStatus,
                      lockable: cell.row.original.errorCount === 0 && cell.row.original.disapprovalCount === 0,
                    } as selectedRow
                  ]);
                }
              }}
            />
          </div>
        ),
      },
      {
        Header: '日付',
        id: 'targetDate',
        accessor: r => r.targetDate ? `${moment(r.targetDate).format("YYYY/MM/DD (ddd)")}` : "",
        sortType: 'basic'
      },
      {
        Header: '組織コード',
        accessor: 'orgCode',
        sortType: 'basic'
      },
      {
        Header: '組織名',
        accessor: 'orgName',
        sortType: 'basic',
        Cell: (cell: { row :{ isExpanded: boolean, original: AttendAchievementLockDomain; index: number }}) => (
          <TextTooltip id={`orgName_${cell.row.index}`} text={cell.row.original.orgName} />
        ),
      },
      {
        Header: 'ロック状態',
        accessor: 'lockStatus',
        sortType: 'basic',
        Cell: (cell: { row: { isExpanded: boolean, original: AttendAchievementLockDomain } }) => (
          <strong style={cell.row.original.lockStatus === '1' ? {color: 'blue'} : {color: 'red'}}>
            {cell.row.original.lockStatus === '1' ? 'ロック中' : '未ロック'}
          </strong>
        ),
      },
      {
        Header: '勤怠エラー',
        accessor: 'errorCount',
        sortType: 'basic',
        Cell: (cell: { row: { isExpanded: boolean, original: AttendAchievementLockDomain } }) => (
          <React.Fragment>
            { availableDailyShift ? (
                <Link to={{
                  pathname: `/dailyShift/${cell.row.original.orgName?.replace('/', '%2F')}/${cell.row.original.orgCode}/${moment(cell.row.original.targetDate).format('YYYY-MM-DD')}/`}}
                  target='_blank'
                >
                  {`${cell.row.original.errorCount.toLocaleString()} 件`}
                </Link>
              ) : (
                <span>
                  {`${cell.row.original.errorCount.toLocaleString()} 件`}
                </span>
              )
            }
          </React.Fragment>
        )
      },
      {
        Header: () => (<div>残業未申請<br />アラート</div>),
        accessor: 'alertOvertimeApplicationCount',
        sortType: 'basic',
        Cell: (cell: { row: { isExpanded: boolean, original: AttendAchievementLockDomain } }) => (
          <React.Fragment>
            { availableDailyShift ? (
                <Link to={{
                  pathname: `/dailyShift/${cell.row.original.orgName?.replace('/', '%2F')}/${cell.row.original.orgCode}/${moment(cell.row.original.targetDate).format('YYYY-MM-DD')}/`}}
                  target='_blank'
                >
                  {`${cell.row.original.alertOvertimeApplicationCount.toLocaleString()} 件`}
                </Link>
              ) : (
                <span>
                  {`${cell.row.original.alertOvertimeApplicationCount.toLocaleString()} 件`}
                </span>
              )
            }
          </React.Fragment>
        ),
      },
      {
        Header: () => (<div>休日出勤未申請<br />アラート</div>),
        accessor: 'alertHolidayworkApplicationCount',
        sortType: 'basic',
        Cell: (cell: { row: { isExpanded: boolean, original: AttendAchievementLockDomain } }) => (
          <React.Fragment>
            { availableDailyShift ? (
                <Link to={{
                  pathname: `/dailyShift/${cell.row.original.orgName?.replace('/', '%2F')}/${cell.row.original.orgCode}/${moment(cell.row.original.targetDate).format('YYYY-MM-DD')}/`}}
                  target='_blank'
                >
                  {`${cell.row.original.alertHolidayworkApplicationCount.toLocaleString()} 件`}
                </Link>
              ) : (
                <span>
                  {`${cell.row.original.alertHolidayworkApplicationCount.toLocaleString()} 件`}
                </span>
              )
            }
          </React.Fragment>
        ),
      },
      {
        Header: '承認待ち',
        accessor: 'disapprovalCount',
        sortType: 'basic',
        Cell: (cell: { row: { isExpanded: boolean, original: AttendAchievementLockDomain } }) => (
          <React.Fragment>
            { availableAttendApplication && cell.row.original.disapprovalCount > 0 && cell.row.original.managerFlag
              ? (
                <Link to={{
                  pathname: `/attendApplication/${moment(cell.row.original.targetDate).format('YYYY-MM-DD')}/`}}
                  target='_blank'
                >
                  {`${cell.row.original.disapprovalCount.toLocaleString()} 件`}
                </Link>
              ) : (
                <span>
                  {`${cell.row.original.disapprovalCount.toLocaleString()} 件`}
                </span>
              )
            }
          </React.Fragment>
        ),
      },
      {
        Header: '',
        id: 'blank',
        disableSortBy: true,
      },
    ], [attendAchievementLockList, selectedRows]);

    const handleLock = useCallback(async (opneModal:boolean) => {
      if(opneModal) {
        if(selectedRows.length > 1000) {
          errorNotification(ErrorMessage.AttendAchievementLock.LIMIT_OVER_SELECTED_COUNT(1000));
          return;
        }
        if(selectedRows.some(s => !s.lockable)) {
          setConfirmModalOpen(true);
          return;
        }
      }
      await lockAttendAchievement();
    }, [selectedRows, confirmModalOpen]);

    const handleUnlock = useCallback(async () => {
      await unlockAttendAchievement();
    }, [selectedRows]);

    const tableId = 1;
    return (
      <BlockUI blocking={isLoading}>
        <FormContents>
          <Row>
            <Col md={2}>
              <div style={{ marginTop: '16px' }}>
                <BodyText>対象期間</BodyText>
              </div>
            </Col>
            <Col md={10}>
              <FlexBox>
                <FlexBoxItem>
                  <DatePickerForm
                    dateFormat="yyyy年MM月dd日"
                    label=""
                    isFullWidth={true}
                    date={targetDateFrom}
                    changeDate={(date: Date) => {
                      if (date !== null) {
                        setTargetDateFrom(date);
                        sessionStorage.setItem(SessionStorageKey.Common.DataTable.INIT_PAGE_INDEX(tableId, window.location.pathname), String(0));
                      }
                    }}
                  />
                </FlexBoxItem>
                <FlexBoxItem><span style={{ margin: '0 30px' }}>～</span></FlexBoxItem>
                <FlexBoxItem>
                  <DatePickerForm
                    dateFormat="yyyy年MM月dd日"
                    label=""
                    isFullWidth={true}
                    date={targetDateTo}
                    changeDate={(date: Date) => {
                      if (date !== null) {
                        setTargetDateTo(date);
                        sessionStorage.setItem(SessionStorageKey.Common.DataTable.INIT_PAGE_INDEX(tableId, window.location.pathname), String(0));
                      }
                    }}
                  />
                </FlexBoxItem>
              </FlexBox>
            </Col>
         </Row>
         <Row>
            <Col md={2}>
              <div style={{ marginTop: '12px' }}>
                <BodyText>ロック状態</BodyText>
              </div>
            </Col>
            <Col md={10}>
              <FlexBox customStyle={css({ flexWrap: 'wrap', marginTop: '12px' })}>
                {[{ display: '未ロック', index: 0 }, { display: 'ロック中', index: 1 }, { display: '全て', index: -1 }].map((item) => (
                  <FlexBoxItem marginRight="10px" key={item.index}>
                    <Radio
                      id={`lock_status_${String(item.index)}`}
                      name={String(item.index)}
                      label={item.display}
                      value={String(item.index)}
                      checked={lockStatus === String(item.index)}
                      onChange={() => {
                        setLockStatus(String(item.index));
                        sessionStorage.setItem(SessionStorageKey.Common.DataTable.INIT_PAGE_INDEX(tableId, window.location.pathname), String(0));
                      }}
                    />
                  </FlexBoxItem>
                ))}
              </FlexBox>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <div style={{ marginTop: '12px' }}>
                <BodyText>勤怠実績</BodyText>
              </div>
            </Col>
            <Col md={10}>
              <FlexBox customStyle={css({ flexWrap: 'wrap' })}>
                <FlexBoxItem customStyle={css({ marginTop: '15px' })}>
                  <Checkbox
                    id="isError"
                    name="isError"
                    label="勤怠エラー"
                    value={String(isError)}
                    checked={isError}
                    onChange={(e) => {
                      setIsError(e.target.checked);
                      sessionStorage.setItem(SessionStorageKey.Common.DataTable.INIT_PAGE_INDEX(tableId, window.location.pathname), String(0));
                    }}
                  />
                </FlexBoxItem>
                <FlexBoxItem customStyle={css({ marginTop: '15px', marginLeft: '8px' })}>
                  <Checkbox
                    id="isAlertOvertime"
                    name="isAlertOvertime"
                    label="残業未申請"
                    value={String(isAlertOvertime)}
                    checked={isAlertOvertime}
                    onChange={(e) => {
                      setIsAlertOvertime(e.target.checked);
                      sessionStorage.setItem(SessionStorageKey.Common.DataTable.INIT_PAGE_INDEX(tableId, window.location.pathname), String(0));
                    }}
                  />
                </FlexBoxItem>
                <FlexBoxItem customStyle={css({ marginTop: '15px', marginLeft: '8px' })}>
                  <Checkbox
                    id="isAlertHolidaywork"
                    name="isAlertHolidaywork"
                    label="休日出勤未申請"
                    value={String(isAlertHolidaywork)}
                    checked={isAlertHolidaywork}
                    onChange={(e) => {
                      setIsAlertHolidaywork(e.target.checked);
                      sessionStorage.setItem(SessionStorageKey.Common.DataTable.INIT_PAGE_INDEX(tableId, window.location.pathname), String(0));
                    }}
                  />
                </FlexBoxItem>
                <FlexBoxItem customStyle={css({ marginTop: '15px', marginLeft: '8px' })}>
                  <Checkbox
                    id="isDisapproval"
                    name="isDisapproval"
                    label="承認待ち"
                    value={String(isDisapproval)}
                    checked={isDisapproval}
                    onChange={(e) => {
                      setIsDisapproval(e.target.checked);
                      sessionStorage.setItem(SessionStorageKey.Common.DataTable.INIT_PAGE_INDEX(tableId, window.location.pathname), String(0));
                    }}
                  />
                </FlexBoxItem>
                <FlexBoxItem customStyle={css({ marginTop: '15px', marginLeft: '8px' })}>
                  <Checkbox
                    id="isLockable"
                    name="isLockable"
                    label="ロック可能"
                    value={String(isLockable)}
                    checked={isLockable}
                    onChange={(e) => {
                      setIsLockable(e.target.checked);
                      sessionStorage.setItem(SessionStorageKey.Common.DataTable.INIT_PAGE_INDEX(tableId, window.location.pathname), String(0));
                    }}
                  />
                </FlexBoxItem>
              </FlexBox>
            </Col>
          </Row>
          <Row>
            <Col md={7}>
              <OrganizationFilteredTree
                functionType={3}
                staffCode={loginStaffCode}
                orgLabel="組織名"
                initOrgValue={String(orgCode)}
                orgCallback={(args: string | Array<string>) => {
                  setOrgCode(String(args));
                }}
                orgCategoryCallback={(args: Array<string>) => {
                  setCategoryArr(args);
                }}
                targetdayForm={moment(
                  new Date(targetDateFrom),
                ).toDate()}
                targetdayTo={moment(new Date(targetDateTo)).toDate()}
                addAllItem={true}
                styles={{
                  label: {
                    toggleSwitch: css({ marginLeft: '0px' }),
                  },
                  toggle: {
                    width: '13rem',
                  },
                }}
              />
            </Col>
          </Row>
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', marginBottom: '32px' }}>
            <div css={styles.table}>
              <DataTable
                columns={columns}
                data={attendAchievementLockList}
                minWidth="1080px"
                useSession={true}
                tableId={1}
                pageSize={100}
                sortBy={[
                  { id: 'targetDate', desc: false }
                ]}
              />
            </div>
          </div>
          {roleScreen && roleScreen.editable === 1 && attendAchievementLockList.length > 0 && (
            <Container>
              <Row style={{ paddingBottom: '32px' }}>
                <FlexBox alignItems="center" customStyle={css({ width: '100%', justifyContent: 'center' })}>
                  <FlexBoxItem>
                    <PrimaryButton
                      disabled={selectedRows.length === 0 }
                      onClick={async () => await handleLock(true)}
                      ghost={false}
                      text="ロック"
                    />
                  </FlexBoxItem>
                  <FlexBoxItem marginLeft="16px">
                    <SecondaryButton
                      disabled={selectedRows.length === 0 }
                      onClick={handleUnlock}
                      ghost={false}
                      text="ロック解除"
                    />
                  </FlexBoxItem>
                </FlexBox>
              </Row>
            </Container>
          )}
        </FormContents>
        <ConfirmModal
          title="勤怠エラーあり"
          content={WarningMessage.AttendAchievementLock.SELECTED_ERROR_COUNT}
          submitText={'ロック'}
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={async () => await handleLock(false)}
          items={[]}
        />
      </BlockUI>
    );
  };

export default AttendAchievementLockForm;
