/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import BodyText from 'components/atoms/BodyText';
import PrimaryButton from 'components/atoms/Button';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormContents from 'components/atoms/Form/FormContents';
import BlockUI from 'components/molecules/BlockUi';
import DatePickerForm from 'components/molecules/DatePickerForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import React, {
  ReactElement,
} from 'react';
import moment from 'moment';
import useBankExport from './hook';

const styles = {
  label: css({
    marginLeft: '4px',
    fontSize: '16px',
  }),
  selectionWrapper: css({
    minHeight: 75,
    height: 75,
    marginTop: 15,
    width: '100%',
  }),
  selectionWrapperItem: css({
    height: '100%',
    width: '100%',
  }),
  selectionItemTitle: css({
    height: 25,
  }),
  selectionItemContent: css({
    paddingTop: 5,
    '& .css-5hicrt': {
      marginRight: '3rem',
    },
  }),
  marginRight: css({
    marginRight: 15,
  }),
  marginLeft: css({
    marginLeft: 15,
  }),
};

const BankDepositExportLayout: React.FC = (): ReactElement => {
  const {
    isLoading,
    optionList,
    targetDateFrom,
    setTarGetDateFrom,
    targetDateTo,
    setTarGetDateTo,
    valueCheck,
    handleChangeCheckBox,
    handleDownloadCsv,
  } = useBankExport();
  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <BodyText customStyle={styles.label}>銀行入出金明細の仕訳データを出力します。</BodyText>
        <FlexBox customStyle={styles.selectionWrapper}>
          <FlexBoxItem customStyle={styles.selectionWrapperItem}>
            <FlexBox direction="column" alignItems="flex-start" customStyle={styles.selectionWrapperItem}>
              <FlexBoxItem textAlign="left" customStyle={styles.selectionItemTitle}>
                <BodyText customStyle={styles.label}>入金日</BodyText>
              </FlexBoxItem>
              <FlexBoxItem>
                <FlexBox>
                  <FlexBoxItem customStyle={styles.marginRight}>
                    <DatePickerForm
                      label=""
                      date={moment(new Date(targetDateFrom)).toDate()}
                      dateFormat="yyyy年MM月dd日"
                      changeDate={(date: Date) => {
                        if (date !== null) {
                          setTarGetDateFrom(moment(date).format('YYYY/MM/DD'));
                        }
                      }}
                      isFullWidth={true}
                    />
                  </FlexBoxItem>
                  <FlexBoxItem>
                    ～
                  </FlexBoxItem>
                  <FlexBoxItem customStyle={styles.marginLeft}>
                    <DatePickerForm
                      label=""
                      date={moment(new Date(targetDateTo)).toDate()}
                      dateFormat="yyyy年MM月dd日"
                      changeDate={(date: Date) => {
                        if (date !== null) {
                          setTarGetDateTo(moment(date).format('YYYY/MM/DD'));
                        }
                      }}
                      isFullWidth={true}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </FlexBoxItem>
            </FlexBox>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.selectionWrapper}>
          <FlexBoxItem customStyle={styles.selectionWrapperItem}>
            <FlexBox direction="column" alignItems="flex-start" customStyle={styles.selectionWrapperItem}>
              <FlexBoxItem textAlign="left" customStyle={styles.selectionItemTitle}>
                <BodyText customStyle={styles.label}>銀行</BodyText>
              </FlexBoxItem>
              <FlexBoxItem customStyle={styles.selectionItemContent}>
                <RadioSelectForm
                  label=""
                  items={optionList}
                  name="bankName"
                  value={valueCheck}
                  setValue={(e) => {
                    handleChangeCheckBox(e.target.value);
                  }}
                  labelInline={false}
                />
              </FlexBoxItem>
            </FlexBox>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.selectionWrapper}>
          <FlexBoxItem>
            <PrimaryButton
              text="仕訳データ出力"
              onClick={handleDownloadCsv}
            />
          </FlexBoxItem>
        </FlexBox>
      </FormContents>
    </BlockUI>
  );
};
export default BankDepositExportLayout;
