import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import TermAddForm from 'components/organismos/master/attend/TermAddForm';
import { useParams } from 'react-router-dom';

const TermMasterAddPage: React.FC = () => {
  const {
    termId,
  } = useParams();

  return (
    <SidebarTemplate
      pageTitle={termId ? '時間帯編集' : '時間帯作成'}
    >
      <TermAddForm />
    </SidebarTemplate>
  );
};

export default TermMasterAddPage;
