/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

const styles = {
  base: css({
    fontWeight: 'bold',
    fontFamily: 'inherit',
    margin: '0',
  }),
  h1: css({
    fontSize: '24px',
  }),
  h2: css({
    fontSize: '20px',
  }),
  h3: css({
    fontSize: '18px',
  }),
  h4: css({
    fontSize: '16px',
  }),
  h5: css({
    fontSize: '14px',
  }),
  h6: css({
    fontSize: '12px',
  }),
};

const Heading: React.FC<{
  level?: number
  customStyle?: SerializedStyles;
}> = ({ children, level = 2, customStyle }) => {
  const Tag = `h${level}` as 'h1';
  const mainStyle = css(styles.base, styles[Tag]);
  return (
    <Tag css={css(mainStyle, customStyle)}>
      {children}
    </Tag>
  );
};

export default Heading;
