import * as React from "react"
import { SVGProps } from "react"

const Link = (props: SVGProps<SVGSVGElement>) => (
    <svg width={20} height={20} {...props}>
        <path
            style={{
                stroke: "none",
                fillRule: "nonzero",
                fill: "#000",
                fillOpacity: 1,
            }}
            d="M8.594 12.426c-.258 0-.524-.102-.719-.297a4.786 4.786 0 0 1 0-6.766l3.762-3.765A4.754 4.754 0 0 1 15.02.199c1.277 0 2.476.496 3.378 1.403a4.781 4.781 0 0 1 0 6.761l-1.718 1.723a1.026 1.026 0 0 1-1.442 0 1.023 1.023 0 0 1 0-1.445l1.719-1.72a2.749 2.749 0 0 0 0-3.882 2.74 2.74 0 0 0-3.879 0L9.313 6.805a2.749 2.749 0 0 0 0 3.883 1.018 1.018 0 0 1-.72 1.738Zm0 0"
        />
        <path
            style={{
                stroke: "none",
                fillRule: "nonzero",
                fill: "#000",
                fillOpacity: 1,
            }}
            d="M4.98 19.8a4.733 4.733 0 0 1-3.378-1.402 4.781 4.781 0 0 1 0-6.761L3.32 9.914a1.026 1.026 0 0 1 1.442 0 1.023 1.023 0 0 1 0 1.445l-1.719 1.72a2.749 2.749 0 0 0 0 3.882 2.74 2.74 0 0 0 3.879 0l3.765-3.766a2.749 2.749 0 0 0 0-3.883 1.018 1.018 0 1 1 1.442-1.44 4.793 4.793 0 0 1 0 6.765l-3.766 3.765a4.754 4.754 0 0 1-3.383 1.399Zm0 0"
        />
    </svg>
)

export default Link;
