import SaleSetting from 'domain/master/general/salesSetting'
import ApiClient from './ApiClient';
import moment from 'moment';

// TimeSetting
export const UpdateTimeSetting = async (companyCode: string,
  SaleSettingObj: SaleSetting) => {
    const saleObj = SaleSettingObj
  // set data for Post api
  const query = {
    useReportApproval: saleObj.useReportApproval,
    calculationItemOfFRate: saleObj.calculationItemOfFRate,
    includePettyCashInPurchase: saleObj.includePettyCashInPurchase,
    reportApprovalStartDate: saleObj.reportApprovalStartDate != null ? moment(String(saleObj.reportApprovalStartDate)).format('YYYY-MM-DD') : '',
    createUser: sessionStorage.getItem('loginUser.staffCode') || '',
    //add new values
    manageRevenueStamp: saleObj.manageRevenueStamp,
    manageRevenueStampStartDate: saleObj.manageRevenueStampStartDate != null ? moment(String(saleObj.manageRevenueStampStartDate)).format('YYYY-MM-DD') : '',

  };
  const params = SaleSettingObj;
  const response = await ApiClient.post(`/v1/setting/reports/${companyCode}`, query, params);
  return response.data;
};
export const getTimeSetting = async (companyCode: string): Promise<any> => {
  const params = {};

  const response = await ApiClient.get(`/v1/setting/reports/${companyCode}`, params);
  return response.data;
};

export default {
  UpdateTimeSetting,
  getTimeSetting
};
