import moment from 'moment';
/**
 * APIのデータ型
 */
export interface AttendHelpList {
  orgName: string,
  belongOrgName: string,
  staffName: string,
  subtotalTime: number,
  subtotalDays: string,
  totalCost: number,
  subtotalTimeDisp: string,
  totalCostDisp: string,
  key: {
    belongOrgCode: string,
    orgCode: string,
    staffCode: string
  },
  staffSubtotal: number,
  attendanceDaysSubtotal: number,
  allWorkTimeSubtotalDisp: string,
  targetDateFrom: Date,
  targetDateTo: Date,
}

export default class AttendHelpListDomain {
  constructor(private rawData: AttendHelpList) {
    // do nothing
  }

  static generateInitial(): AttendHelpListDomain {
    return new AttendHelpListDomain({
      orgName: '',
      belongOrgName: '',
      staffName: '',
      subtotalTime: 0,
      subtotalDays: '',
      totalCost: 0,
      subtotalTimeDisp: '',
      totalCostDisp: '',
      key: {
        belongOrgCode: '',
        orgCode: '',
        staffCode: '',
      },
      staffSubtotal: 0,
      attendanceDaysSubtotal: 0,
      allWorkTimeSubtotalDisp: '',
      targetDateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      targetDateTo: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    });
  }

  getRawData(): AttendHelpList {
    return this.rawData;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get belongOrgName(): string {
    return this.rawData.belongOrgName;
  }

  set belongOrgName(belongOrgName: string) {
    this.rawData.belongOrgName = belongOrgName;
  }

  get staffName(): string {
    return this.rawData.staffName;
  }

  set staffName(staffName: string) {
    this.rawData.staffName = staffName;
  }

  get subtotalTime(): number {
    return this.rawData.subtotalTime;
  }

  set subtotalTime(subtotalTime: number) {
    this.rawData.subtotalTime = subtotalTime;
  }

  get totalCost(): number {
    return this.rawData.totalCost;
  }

  set totalCost(totalCost: number) {
    this.rawData.totalCost = totalCost;
  }

  get subtotalTimeDisp(): string {
    return this.rawData.subtotalTimeDisp;
  }

  set subtotalDays(subtotalDays: string) {
    this.rawData.subtotalTimeDisp = subtotalDays;
  }

  get subtotalDays(): string {
    return `${this.rawData.subtotalDays}日`;
  }

  get numberSubtotalDays(): string {
    return this.rawData.subtotalDays;
  }

  set subtotalTimeDisp(subtotalTimeDisp: string) {
    this.rawData.subtotalTimeDisp = subtotalTimeDisp;
  }

  get totalCostDisp(): string {
    return `${this.rawData.totalCostDisp}円`;
  }

  set totalCostDisp(totalCostDisp: string) {
    this.rawData.totalCostDisp = totalCostDisp;
  }

  get belongOrgCode(): string {
    return this.rawData.key.belongOrgCode;
  }

  get orgCode(): string {
    return this.rawData.key.orgCode;
  }

  get staffCode(): string {
    return this.rawData.key.staffCode;
  }

  get key(): {} {
    return this.rawData.key;
  }

  get staffSubtotal(): string {
    return `${this.rawData.staffSubtotal}人`;
  }

  get attendanceDaysSubtotal(): string {
    return `${this.rawData.attendanceDaysSubtotal}日`;
  }

  get allWorkTimeSubtotalDisp(): string {
    return this.rawData.allWorkTimeSubtotalDisp;
  }

  set setTargetDateFrom(targetDateFrom: any) {
    this.rawData.targetDateFrom = targetDateFrom;
  }

  get targetDateFrom(): any {
    return moment(this.rawData.targetDateFrom).toDate();
  }

  get targetDateTo(): Date {
    return moment(this.rawData.targetDateTo).toDate();
  }

  set setTargetDateTo(targetDateTo: Date) {
    this.rawData.targetDateTo = targetDateTo;
  }
}
