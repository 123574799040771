import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import PrimaryButton from 'components/atoms/Button';
import HourInput from 'components/atoms/Form/HourInput';
import productColor, {
  gridCol, grayScale, textFontSize,
} from 'components/styles';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { css } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import { usePaidHolidayPatternDomainAddForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';


// create style form
const styles = {
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  input: css({
    flex: 1,
    width: '100px',
    padding: '7px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: 'none',
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px 0 0 4px',
  }),
  addon: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100px',
    padding: '7px',
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
  }),
  cellTable: css({
    width: '100%',
    border: 'none',
    borderSpacing: '0px 1.5px',
    overflow: 'scroll',
  }),
};

const PaidHolidayPatternInputAddForm: React.FC<{paidHolidayPatternId: string}> = ({
  paidHolidayPatternId,
}) => {
  const {
    formik, confirmModalOpen, closeConfirmModal, optionsStartMonths, arrStartMonth, valuePatternDetails, setValuePatternDetails,
  } = usePaidHolidayPatternDomainAddForm(paidHolidayPatternId);

  // history
  const history = useHistory();

  /**
   *  handle on change input pattern details
   */
  const handleChangePatternDetails = async (e: any) => {
    const checkName = e.target.name.split('_');
    if (checkName[1]) {
      const items = [...valuePatternDetails];
      (items as any)[checkName[1]][checkName[0]] = e.target.value;
      setValuePatternDetails(items);
    }
  };

  /**
   *  handle back
   */
  const handleBack = async () => {
    sessionStorage.setItem('currentTab', '4');
    history.push('/agreementMaster');
  };

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <>
      <p>
        有給パターンを登録します。コードと有給パターン名を入力し、有給付与を開始する勤務月数を選択してください。
      </p>

      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormField>
            <TextForm
              name="paidHolidayPatternCode"
              label="パターンコード"
              value={formik.values.paidHolidayPatternCode}
              onChange={formik.handleChange}
              errorMsg={formik.errors.paidHolidayPatternCode}
              required={true}
            />
          </FormField>

          <FormField>
            <TextForm
              name="paidHolidayPatternName"
              label="パターン名"
              value={formik.values.paidHolidayPatternName}
              onChange={formik.handleChange}
              errorMsg={formik.errors.paidHolidayPatternName}
              required={true}
            />
          </FormField>

          <FormTitle
            title="有給付与パターン"
          />

          <p>
            付与開始月数を選択して、所定労働日数と継続勤務年数に従って付与する有給数を設定してください。
          </p>

          <FormField>
            <div style={{ width: gridCol.grid12 }}>
              <div style={{ width: '350px' }}>
                <div style={{ width: gridCol.grid05, float: 'left' }}>
                  付与開始勤務月数
                </div>
                <div style={{ width: gridCol.grid07, float: 'right', marginTop: '-20px' }}>
                  <FormField>
                    <RadioSelectForm
                      label=""
                      items={optionsStartMonths}
                      name="grantStartMonths"
                      value={String(formik.values.grantStartMonths)}
                      setValue={formik.handleChange}
                    />
                  </FormField>
                </div>
              </div>
            </div>
          </FormField>

          <div>
            <table css={styles.cellTable} style={{ width: '100%' }}>
              <thead>
                <tr style={{
                  borderTop: '1px solid #CEDAEA', borderBottom: '1px solid #CEDAEA', backgroundColor: '#F2F5F9', height: '40px',
                }}
                >
                  <th style={{ fontWeight: 'inherit', textAlign: 'left' }}>所定労働日数(週/年)</th>
                  {arrStartMonth.map((item) => (
                    <th style={{ fontWeight: 'inherit', textAlign: 'left' }}>{Number(formik.values.grantStartMonths) === 0 ? item[0] : item[6]}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {valuePatternDetails.map((itemPatternDetail: any, key_patternDetail: any) => (
                  <tr style={{ backgroundColor: '#ffffff' }}>
                    <td>
                      {itemPatternDetail.id.weeklyWorkingDays}
                      日 /
                      {' '}
                      {itemPatternDetail.yearlyWorkingDaysFrom}
                      日〜
                      {itemPatternDetail.yearlyWorkingDaysTo === 0 ? '' : `${itemPatternDetail.yearlyWorkingDaysTo}日`}
                    </td>
                    {arrStartMonth.map((itemStartMonth, key_startMonth) => (
                      <td>
                        <HourInput
                          name={`continuedServiceYears${key_startMonth}_${key_patternDetail}`}
                          value={Number(itemPatternDetail[`continuedServiceYears${key_startMonth}`])}
                          label="日"
                          onChange={handleChangePatternDetails}
                          min={0}
                          max={99}

                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <FormSubmitArea>
            {
              roleScreen && roleScreen.editable === 1 && (
                <div style={{ marginRight: '12px' }}>
                  <Button
                    text="有給パターンを登録"
                    onClick={formik.handleSubmit}
                  />
                </div>
              )
            }
            <PrimaryButton
              text="戻る"
              onClick={() => handleBack()}
              ghost={true}
            />
          </FormSubmitArea>

        </div>

        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          title="確認メッセージ"
          content={`${paidHolidayPatternId ? '更新' : '登録'}します。よろしいですか？`}
          submitText={paidHolidayPatternId ? '更新' : '登録'}
        />

      </form>
    </>
  );
};

export default PaidHolidayPatternInputAddForm;
