export interface OrganizationCategory {

  orgCategoryCode: string,
  organizationCategoryMstHeadId: string,
  categoryDispName: string,
  inUse: boolean,
  dispSort: number
}

export default class OrganizationCategoryDomain {
  constructor(private rawData: OrganizationCategory) {
    // do nothing
  }

  static generateInitial(): OrganizationCategoryDomain {
    return new OrganizationCategoryDomain({
      orgCategoryCode: '',
      organizationCategoryMstHeadId: '',
      categoryDispName: '',
      inUse: false,
      dispSort: 0,
    });
  }

  get orgCategoryCode(): string {
    return this.rawData.orgCategoryCode;
  }

  set orgCategoryCode(orgCategoryCode: string) {
    this.rawData.orgCategoryCode = orgCategoryCode;
  }

  get organizationCategoryMstHeadId(): string {
    return this.rawData.organizationCategoryMstHeadId;
  }

  set organizationCategoryMstHeadId(organizationCategoryMstHeadId: string) {
    this.rawData.organizationCategoryMstHeadId = organizationCategoryMstHeadId;
  }

  get categoryDispName(): string {
    return this.rawData.categoryDispName;
  }

  set categoryDispName(categoryDispName: string) {
    this.rawData.categoryDispName = categoryDispName;
  }

  get inUse(): boolean {
    return this.rawData.inUse;
  }

  get inUseStr(): string {
    return this.rawData.inUse ? '1' : '0';
  }

  set inUse(inUse: boolean) {
    this.rawData.inUse = inUse;
  }

  get dispSort(): number {
    return this.rawData.dispSort;
  }

  set dispSort(dispSort: number) {
    this.rawData.dispSort = dispSort;
  }
}
