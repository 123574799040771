/** @jsx jsx */
import React from 'react';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import Checkbox from 'components/molecules/Checkbox';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import { css, jsx } from '@emotion/core';
import IconLabelButton from 'components/molecules/IconLabelButton';
import {
  Row, Container, Col, useScreenClass, Hidden,
} from 'react-grid-system';
import { productColor, textFontSize, textColor } from 'components/styles';
import { isMobileOnly } from 'react-device-detect';
import BodyText from 'components/atoms/BodyText';
import { MenuPlacement } from 'react-select/src/types';
import { useShiftPatternDetailDomainForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const tableStyle = css({
  padding: '4px 16px',
  margin: '0',
  backgroundColor: productColor.primaryM95,
  fontWeight: 'normal',
  textAlign: 'left',
  fontSize: textFontSize.re,
  borderTop: `1px solid ${productColor.primaryM80}`,
  borderBottom: `1px solid ${productColor.primaryM80}`,
  color: textColor.main,
});

const styles = {
  buttonDelete: css({}),
  grayoutButton: css({
    opacity: '0.5',
    cursor: 'not-allowed',
  }),
  isStartTimeNextDay: css({
    marginRight: '20px',
  }),
};

const ShiftPatternDetails: React.FC<{
  shiftPatternDetailsList: any;
  attendBusinessOptions: any;
  listTimes: any;
  isCreate?: boolean;
  isDisableAll?: boolean;
  setShiftPatternDetailsList: (dataList: any) => void;
  menuPlacement?:MenuPlacement;
  orgCode?:string
}> = ({
  shiftPatternDetailsList,
  setShiftPatternDetailsList,
  listTimes,
  attendBusinessOptions,
  isCreate = true,
  isDisableAll = false,
  menuPlacement = 'bottom',
  orgCode,
}) => {
  const {
    timeFormOptions,
    hourFormOptions,
    detailList,
    setDetailList,
    convertDataOuput,
  } = useShiftPatternDetailDomainForm(
    listTimes,
    shiftPatternDetailsList,
    setShiftPatternDetailsList,
    attendBusinessOptions,
    isCreate,
  );

  const handleAddShiftPatternDetails = () => {
    if (isDisableAll) {
      return;
    }
    let defaultBusinessId = '';
    if (attendBusinessOptions.length) {
      defaultBusinessId = attendBusinessOptions[0].value;
    }

    const lastIndex = detailList.length - 1;
    const tmpPatternDetails = [...detailList];

    tmpPatternDetails.push({
      shiftPatternDetailId: '',
      shiftPatternId: detailList[lastIndex].shiftPatternId,
      businessId: defaultBusinessId,
      startHourForm: detailList[lastIndex].endHourForm,
      startTimeForm: detailList[lastIndex].endTimeForm,
      isStartTimeNextDay: detailList[lastIndex].isEndTimeNextDay,
      endHourForm: '00',
      endTimeForm: '00',
      isEndTimeNextDay: false,
      createUser: '',
      updateUser: '',
    });

    setDetailList(tmpPatternDetails);
    convertDataOuput(tmpPatternDetails);
  };

  /**
     * 終了時間を変更（次行があれば開始時間に反映）
     *
     */
  const handleChangeStartEndTime = (
    indexShiftPattern: number,
    nameField: string,
    value: any,
  ) => {
    if (isDisableAll) {
      return;
    }

    const items = [...detailList];
    items.filter((item: any, index: number) => {
      if (index === indexShiftPattern) {
        item[nameField] = value;
        return true;
      }
      return false;
    });
    const arrOnChange = ['endHourForm', 'endTimeForm', 'isEndTimeNextDay'];
    if (arrOnChange.indexOf(nameField) !== -1) {
      // 次行があれば開始時間に反映
      if (items.length - 1 > indexShiftPattern) {
        items[indexShiftPattern + 1].startHourForm = items[indexShiftPattern].endHourForm;
        items[indexShiftPattern + 1].startTimeForm = items[indexShiftPattern].endTimeForm;
        items[indexShiftPattern + 1].isStartTimeNextDay = items[indexShiftPattern].isEndTimeNextDay;
      }
    }
    setDetailList(items);
    convertDataOuput(items);
  };

  /**
     * handleDeleteShift
     *
     */
  const handleDeleteShift = () => {
    if (isDisableAll) {
      return;
    }
    const tmpList = [...detailList];
    tmpList.splice(tmpList.length - 1, 1);
    setDetailList(tmpList);
    convertDataOuput(tmpList);
  };

  /**
     * handleChangeCheckboxNextDay
     *
     */
  const handleChangeCheckboxNextDay = (
    indexShiftPattern: number,
    nameField: string,
    isChange?: boolean,
  ) => {
    if (isDisableAll || isChange) return;
    const items = [...detailList];
    items.filter((item: any, index: number) => {
      if (index === indexShiftPattern) {
        if (item[nameField] === true) {
          item[nameField] = false;
        } else {
          item[nameField] = true;
        }
        return true;
      }
      return false;
    });
    if (
      ['endHourForm', 'endTimeForm', 'isEndTimeNextDay'].indexOf(nameField)
        !== -1
    ) {
      // 次行があれば開始時間に反映
      if (items.length - 1 > indexShiftPattern) {
        items[indexShiftPattern + 1].startHourForm = items[indexShiftPattern].endHourForm;
        items[indexShiftPattern + 1].startTimeForm = items[indexShiftPattern].endTimeForm;
        items[indexShiftPattern + 1].isStartTimeNextDay = items[indexShiftPattern].isEndTimeNextDay;
      }
    }
    setDetailList(items);
    convertDataOuput(items);
  };

  /**
     * 時間を変更（リストボックスの値を変更）
     *
     */
  const onHandleHiddenButton = (indexShiftPattern: number) => {
    if (
      detailList.length === 1
        || detailList.length !== indexShiftPattern + 1
    ) {
      return true;
    }
    return false;
  };

  const screenClass = useScreenClass();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  return (
    <Container fluid={true}>
      <div css={isDisableAll ? styles.grayoutButton : {}}>
        {
          !isMobileOnly ? (
            <React.Fragment>
              <Hidden xs={true} sm={true}>
                <Row css={tableStyle}>
                  <Col xs={3} md={2} xl={3} />
                  <Col xs={3} md={4} xl={3}>開始時間</Col>
                  <Col xs={3} md={4} xl={3}>終了時間</Col>
                  <Col xs={3} md={2} xl={3}>業務内容</Col>
                </Row>
              </Hidden>
              <Hidden xl={true} md={true} lg={true}>
                <Row css={tableStyle}>
                  <Col xs={3} md={4} xl={3}>開始時間</Col>
                  <Col xs={3} md={4} xl={3}>終了時間</Col>
                  <Col xs={3} md={2} xl={3}>業務内容</Col>
                  <Col xs={3} md={2} xl={3} />
                </Row>
              </Hidden>

              {detailList.map((shiftPattern: any, keyShiftPattern: number) => (
                <Row
                  key={keyShiftPattern.toString()}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Hidden xs={true} md={true} sm={true}>
                    <Col style={{ fontSize: '14px' }} xs={2} md={2} xl={2} lg={1}>
                      時間
                      {keyShiftPattern + 1}
                    </Col>
                  </Hidden>
                  <Col xs={3} md={4} xl={3} lg={4}>
                    <Row
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <Col xs={12} md={4}>
                        <VerticalLabelSelectForm
                          label=""
                          name={`startHour${keyShiftPattern}`}
                          value={String(shiftPattern.startHourForm)}
                          setValue={(val: string) => handleChangeStartEndTime(
                            keyShiftPattern,
                            'startHourForm',
                            val,
                          )}
                          options={hourFormOptions}
                          disable={keyShiftPattern !== 0 || isDisableAll}
                          menuPlacement={menuPlacement}
                        />
                      </Col>
                      <Col xs={12} md={1} style={{ textAlign: 'center' }}>:</Col>
                      <Col xs={12} md={4}>
                        <VerticalLabelSelectForm
                          name={`startTime${keyShiftPattern}`}
                          value={String(shiftPattern.startTimeForm)}
                          setValue={(val: string) => handleChangeStartEndTime(
                            keyShiftPattern,
                            'startTimeForm',
                            val,
                          )}
                          options={timeFormOptions}
                          disable={keyShiftPattern !== 0 || isDisableAll}
                          placeholder="分"
                          menuPlacement={menuPlacement}
                        />
                      </Col>
                      <Col xs={12} md={3}>
                        <Checkbox
                          id={`isStartTimeNextDay${keyShiftPattern}`}
                          name={`isStartTimeNextDay${keyShiftPattern}`}
                          label="翌日"
                          value="true"
                          checked={Boolean(shiftPattern.isStartTimeNextDay)}
                          onChange={() => handleChangeCheckboxNextDay(
                            keyShiftPattern,
                            'isStartTimeNextDay',
                            keyShiftPattern !== 0,
                          )}
                          readOnly={keyShiftPattern !== 0 || isDisableAll}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3} md={4} xl={3} lg={4}>
                    <Row
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <Col xs={12} md={4}>
                        <VerticalLabelSelectForm
                          name={`endHour${keyShiftPattern}`}
                          value={String(shiftPattern.endHourForm)}
                          setValue={(val: string) => handleChangeStartEndTime(
                            keyShiftPattern,
                            'endHourForm',
                            val,
                          )}
                          options={hourFormOptions}
                          disable={isDisableAll}
                          menuPlacement={menuPlacement}
                        />
                      </Col>
                      <Col xs={12} md={1} style={{ textAlign: 'center' }}>
                        :
                      </Col>
                      <Col xs={12} md={4}>
                        <VerticalLabelSelectForm
                          name={`endTime${keyShiftPattern}`}
                          value={String(shiftPattern.endTimeForm)}
                          setValue={(val: string) => handleChangeStartEndTime(
                            keyShiftPattern,
                            'endTimeForm',
                            val,
                          )}
                          options={timeFormOptions}
                          disable={isDisableAll}
                          placeholder="分"
                          menuPlacement={menuPlacement}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        style={{ display: 'flex', textAlignLast: 'center' }}
                      >
                        <Checkbox
                          id={`isEndTimeNextDay${keyShiftPattern}`}
                          name={`isEndTimeNextDay${keyShiftPattern}`}
                          label="翌日"
                          value="true"
                          checked={Boolean(shiftPattern.isEndTimeNextDay)}
                          onChange={() => handleChangeCheckboxNextDay(
                            keyShiftPattern,
                            'isEndTimeNextDay',
                          )}
                          readOnly={isDisableAll}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3} md={2} xl={2} lg={2} style={{ paddingBottom: ['lg', 'xl'].includes(screenClass) ? '' : ['md'].includes(screenClass) ? '' : '8%' }}>
                    <VerticalLabelSelectForm
                      name={`businessId${keyShiftPattern}`}
                      value={String(shiftPattern.businessId)}
                      setValue={(val: string) => handleChangeStartEndTime(keyShiftPattern, 'businessId', val)}
                      options={attendBusinessOptions}
                      disable={isDisableAll}
                      placeholder="業務"
                      menuPlacement={menuPlacement}
                    />
                  </Col>
                  <Col xs={3} md={2} xl={2} lg={1} style={{ paddingBottom: ['lg', 'xl'].includes(screenClass) ? '' : ['md'].includes(screenClass) ? '' : '8%' }}>
                    <div
                      css={css(
                        styles.buttonDelete,
                        onHandleHiddenButton(keyShiftPattern)
                          ? styles.grayoutButton
                          : {},
                      )}
                    >
                      <IconLabelButton
                        iconType="delete"
                        text="削除"
                        onClick={() => (onHandleHiddenButton(keyShiftPattern)
                          ? () => { }
                          : handleDeleteShift())}
                        padding=""
                        disabled={isDisableAll}
                        isIcon={false}
                      />
                    </div>
                  </Col>
                </Row>
              ))}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {detailList.map((shiftPattern: any, keyShiftPattern: number) => (
                <React.Fragment>
                  <Row>
                    <Col xs={12}>
                      <BodyText>
                        シフト時間
                        {keyShiftPattern + 1}
                      </BodyText>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <BodyText>開始時間</BodyText>
                    </Col>
                    <Col xs={12}>
                      <Row
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                        }}
                      >
                        <Col xs={4}>
                          <VerticalLabelSelectForm
                            label=""
                            name={`startHour${keyShiftPattern}`}
                            value={String(shiftPattern.startHourForm)}
                            setValue={(val: string) => handleChangeStartEndTime(
                              keyShiftPattern,
                              'startHourForm',
                              val,
                            )}
                            options={hourFormOptions}
                            disable={keyShiftPattern !== 0 || isDisableAll}
                          />
                        </Col>
                        <Col xs={1} style={{ textAlign: 'center' }}>:</Col>
                        <Col xs={4}>
                          <VerticalLabelSelectForm
                            name={`startTime${keyShiftPattern}`}
                            value={String(shiftPattern.startTimeForm)}
                            setValue={(val: string) => handleChangeStartEndTime(
                              keyShiftPattern,
                              'startTimeForm',
                              val,
                            )}
                            options={timeFormOptions}
                            disable={keyShiftPattern !== 0 || isDisableAll}
                            placeholder="分"
                          />
                        </Col>
                        <Col xs={3}>
                          <Checkbox
                            id={`isStartTimeNextDay${keyShiftPattern}`}
                            name={`isStartTimeNextDay${keyShiftPattern}`}
                            label="翌日"
                            value="true"
                            checked={Boolean(shiftPattern.isStartTimeNextDay)}
                            onChange={() => handleChangeCheckboxNextDay(
                              keyShiftPattern,
                              'isStartTimeNextDay',
                              keyShiftPattern !== 0,
                            )}
                            readOnly={keyShiftPattern !== 0 || isDisableAll}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <BodyText>終了時間</BodyText>
                    </Col>
                    <Col xs={12}>
                      <Row
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                        }}
                      >
                        <Col xs={4}>
                          <VerticalLabelSelectForm
                            name={`endHour${keyShiftPattern}`}
                            value={String(shiftPattern.endHourForm)}
                            setValue={(val: string) => handleChangeStartEndTime(
                              keyShiftPattern,
                              'endHourForm',
                              val,
                            )}
                            options={hourFormOptions}
                            disable={isDisableAll}
                          />
                        </Col>
                        <Col xs={1} style={{ textAlign: 'center' }}>
                          :
                        </Col>
                        <Col xs={4}>
                          <VerticalLabelSelectForm
                            name={`endTime${keyShiftPattern}`}
                            value={String(shiftPattern.endTimeForm)}
                            setValue={(val: string) => handleChangeStartEndTime(
                              keyShiftPattern,
                              'endTimeForm',
                              val,
                            )}
                            options={timeFormOptions}
                            disable={isDisableAll}
                            placeholder="分"
                          />
                        </Col>
                        <Col xs={3}>
                          <Checkbox
                            id={`isEndTimeNextDay${keyShiftPattern}`}
                            name={`isEndTimeNextDay${keyShiftPattern}`}
                            label="翌日"
                            value="true"
                            checked={Boolean(shiftPattern.isEndTimeNextDay)}
                            onChange={() => handleChangeCheckboxNextDay(
                              keyShiftPattern,
                              'isEndTimeNextDay',
                            )}
                            readOnly={isDisableAll}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <BodyText>業務内容</BodyText>
                    </Col>
                    <Col xs={12}>
                      <VerticalLabelSelectForm
                        name={`businessId${keyShiftPattern}`}
                        value={String(shiftPattern.businessId)}
                        setValue={(val: string) => handleChangeStartEndTime(keyShiftPattern, 'businessId', val)}
                        options={attendBusinessOptions}
                        disable={isDisableAll}
                        placeholder="業務"
                      />
                    </Col>
                  </Row>
                  <Row
                    key={keyShiftPattern.toString()}
                    style={{
                      alignItems: 'left',
                      justifyContent: 'left',
                    }}
                  >
                    <Col xs={12}>
                      <div
                        css={css(
                          styles.buttonDelete,
                          onHandleHiddenButton(keyShiftPattern)
                            ? styles.grayoutButton
                            : {},
                        )}
                      >
                        <IconLabelButton
                          iconType="delete"
                          text="削除"
                          onClick={() => (onHandleHiddenButton(keyShiftPattern)
                            ? () => { }
                            : handleDeleteShift())}
                          padding=""
                          disabled={isDisableAll}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              ))}
            </React.Fragment>
          )
        }
      </div>
      <Row>
        <Col xs={12} md={6}>
          <FormSubmitArea>
            <PrimaryButton
              ghost={false}
              text="行追加"
              onClick={handleAddShiftPatternDetails}
              disabled={isDisableAll}
            />
          </FormSubmitArea>
        </Col>
      </Row>
    </Container>
  );
};

export default ShiftPatternDetails;
