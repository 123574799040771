
import { useState, useEffect } from 'react';

import { getList } from 'api/paidGrantPattern';
import PaidGrantPatternDomain from 'domain/master/labor/paidGrantPattern';

export const usePaidGrantList = () => {
  const [paintGrant, setpaintGrant] = useState<Array<PaidGrantPatternDomain>>([]);
  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const getDetails = false;
    getList(companyCode, getDetails).then((response: Array<any>) => {
      if (isSubscribed) {
        setpaintGrant(response.map((result) => new PaidGrantPatternDomain(result)));
      }
    });
    return () => { isSubscribed = false; };
  }, []);
  return { paintGrant, setpaintGrant };
};


export default usePaidGrantList;
