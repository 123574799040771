import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import AttendCSVOutputLayout from 'components/organismos/master/attend/AttendCSVOutputLayout';

const AttendCSVOutputPage = () => (
  <SidebarTemplate pageTitle="CSV出力">
    <AttendCSVOutputLayout />
  </SidebarTemplate>
);

export default AttendCSVOutputPage;
