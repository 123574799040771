/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import Select, { components, OptionTypeBase } from 'react-select';
import { ValueType } from 'react-select/src/types';

import {
  grayScale, productColor, textFontSize, textColor, utilityColor,
} from 'components/styles';
import FormLabel from 'components/atoms/Form/FormLabel';
import Icon from 'components/atoms/Icon';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import FlexBox from 'components/atoms/FlexBox';
import FormDescription from 'components/atoms/Form/FormDescription';

export interface OptionType extends OptionTypeBase {
  value: string,
  label: string,
}

const styles = {
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: '4px',
  }),
};

const selectStyles = {
  control: (oldStyles: any) => ({
    ...oldStyles,
    '& > div': {
      paddingRight: 0,
    },
  }),
  dropdownIndicator: (oldStyles: any) => ({
    ...oldStyles,
    cursor: 'pointer',
  }),
  input: (oldStyles: any) => ({
    ...oldStyles,
  }),
  multiValue: (oldStyles: any) => ({
    ...oldStyles,
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0D4796',
    borderRadius: '12px',
  }),
  multiValueLabel: (oldStyles: any) => ({
    ...oldStyles,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    color: productColor.primary,
  }),
  multiValueRemove: (oldStyles: any) => ({
    ...oldStyles,
    lineHeight: '21px',
    paddingRight: '6px',
    ':hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  }),
  placeholder: (oldStyles: any) => ({
    ...oldStyles,
    fontSize: '14px',
  }),
  menu: (oldStyles: any) => ({
    ...oldStyles,
    zIndex: 999,
  }),
};


const hideMenuStyles = {
  dropdownIndicator: (oldStyles: any) => ({
    ...oldStyles,
    display: 'none',
  }),
  menu: (oldStyles: any) => ({
    ...oldStyles,
    zIndex: 999,
  }),
};

/**
 * 選択式フォーム
 * TODO: セレクトボックスのスタイルがデフォルトのままなので、ちゃんとカスタマイズする
 */
const VerticalLabelMutilSelectForm: React.FC<{
  label: string;
  name: string;
  values: Array<OptionType> | undefined;
  options: Array<OptionType>;
  setValue: (val: string) => void;
  description?: string;
  note?: string;
  isMulti?: boolean;
  menuIsOpen?: boolean;
  hideIndicator?: boolean;
  hidePlaceholder?: boolean;
  placeholder?:string;
  disable? : boolean;
}> = ({
  label, name, values, options, setValue, description, isMulti, note, menuIsOpen, hideIndicator, hidePlaceholder, placeholder, disable = false,
}) => {
  const getValue = (): ValueType<OptionType> | undefined => values;


  const onChange = (option: any) => {
    if (!option) {
      setValue('');
    } else if (Array.isArray(option) && option.length) {
      const array = option.map((opt) => (opt as OptionType).value);
      setValue(
        `${array.toString()},`,
      );
    } else {
      setValue(
        (option as OptionType).value,
      );
    }
  };

  const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <Icon type="dropdown" color={grayScale.gray100} />
    </components.DropdownIndicator>
  );

  const MultiValueRemove = (props: any) => (
    <components.MultiValueRemove {...props}>
      <Icon type="close" color={grayScale.gray100} />
    </components.MultiValueRemove>
  );

  return (
    <div css={styles.container}>
      <VerticalLabelFormLayout
        label={(
          <FormLabel
            label={label}
          />
          )}
        input={(
          <div css={styles.container}>
            <FlexBox>
              <div css={styles.select}>
                <Select
                  styles={hideIndicator ? { ...selectStyles, ...hideMenuStyles } : selectStyles}
                  name={name}
                  value={getValue()}
                  onChange={onChange}
                  options={options}
                  placeholder={hidePlaceholder ? '' : (placeholder || '選択してください')}
                  isMulti={isMulti}
                  isClearable={false}
                  components={{ DropdownIndicator, IndicatorSeparator: () => null, MultiValueRemove }}
                  menuIsOpen={menuIsOpen}
                  isDisabled={disable}
                />
              </div>
            </FlexBox>
            <FlexBox>
              <div css={styles.select}>
                {description
            && <FormDescription description={description} />}
              </div>
            </FlexBox>
          </div>
          )}
      />
      {note && <span css={css(styles.noteText, { color: utilityColor.error })} dangerouslySetInnerHTML={{ __html: note }} />}
    </div>
  );
};

export default VerticalLabelMutilSelectForm;
