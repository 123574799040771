import { useEffect, useState, useCallback } from 'react';
import { getOperationReportQuick } from 'api/operationReportQuick';
import { useParams } from 'react-router-dom';

const useOperationReportQuick = () => {
  const params = useParams<any>();
  const [operationReport, setOperationReport] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const userOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [selectOrgCode, setSelectOrgCode] = useState(params.orgCode ? params.orgCode : userOrgCode);
  const currentDate = new Date();
  const [targetDateFrom, setTargetDateFrom] = useState<any>(params.targetDate ? new Date(params.targetDate) : currentDate);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getOperationReportQuick(selectOrgCode, targetDateFrom).then((response: any) => {
      setOperationReport(response);
    }).catch((error: any) => {
      setOperationReport([]);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [selectOrgCode, targetDateFrom]);
  useEffect(() => {
    fetchData();
  }, [fetchData, selectOrgCode, targetDateFrom]);

  return {
    operationReport,
    isLoading,
    setIsLoading,
    fetchData,
    selectOrgCode,
    setSelectOrgCode,
    targetDateFrom,
    setTargetDateFrom,
  };
};

export default useOperationReportQuick;
