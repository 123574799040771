import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import SpecialSalaryPatternInputForm from 'components/organismos/master/labor/SpecialSalaryPatternInputForm';
import SpecialSalaryPatternInputSettingForm from 'components/organismos/master/labor/SpecialSalaryPatternInputSettingForm';
import { useParams } from 'react-router-dom';
import FormContents from 'components/atoms/Form/FormContents';
import TabControl from 'components/atoms/TabControl';

type SelectTab = 'edit'|'setting';
const SelectTab: {[key in SelectTab]: SelectTab} = {
  edit: 'edit',
  setting: 'setting',
};

const SpecialSalaryPatternEditPage: React.FC = () => {
  const {
    specialSalaryPatternId,
    tabIndex,
  } = useParams();
  const [selectTab, setSelectTab] = React.useState<SelectTab>(
    tabIndex === '1' ? SelectTab.setting : SelectTab.edit,
  );
  return (
    <SidebarTemplate pageTitle={specialSalaryPatternId ? '特賃日編集' : '特賃日作成'}>
      <FormContents>
        <TabControl
          items={[
            {
              value: SelectTab.edit,
              display: '特賃日情報',
            },
            {
              value: SelectTab.setting,
              display: '特賃日割当設定',
            },
          ]}
          setValue={setSelectTab as (arg: string) => void}
          currentValue={selectTab}
          isFixed={true}
        />
        {selectTab === SelectTab.edit
              && (
                <SpecialSalaryPatternInputForm />
              )}
        {selectTab === SelectTab.setting
              && (
                <SpecialSalaryPatternInputSettingForm />
              )}

      </FormContents>
    </SidebarTemplate>
  );
};

export default SpecialSalaryPatternEditPage;
