import moment from 'moment';
interface OrganizationCategoriesList {
  attendAchievementDownloadId: string,
  attendAchievementDownloadOrganizationCategoryId: string,
  companyCode: string,
  createDate: string,
  createUser: string,
  organizationCategoryMstDetailId: string,
  organizationCategoryMstHeadId: string,
  organizationCategoryMstHeadName: string,
  organizationCategoryMstName: string
}
interface AttendCSVOutput {
  attendAchievementDownloadId: string,
  type: string,
  layoutId: string,
  layoutName: string,
  periodFrom: string,
  periodTo: string,
  closingDate: string,
  employmentName: string,
  createDate: string,
  createUser: string,
  statusDownload: number,
  file: string,
  orgCode: string,
  orgName: string,
  staffName: string,
  targetYmd: string,
  viewPeriod: number,
  attendFlag: number,
  organizationCategories: Array<OrganizationCategoriesList>,
  organizationCategoryMstHeadName: string,
  organizationCategoryMstName: string,
  statusDownloadName: string,
}

export default class AttendCSVOutputDomain {
  constructor(private rawData: AttendCSVOutput) {
    //
  }

  static generateInitial(): AttendCSVOutputDomain {
    return new AttendCSVOutputDomain({
      attendAchievementDownloadId: '',
      type: '',
      layoutId: '',
      layoutName: '',
      periodFrom: '',
      periodTo: '',
      closingDate: '',
      employmentName: '',
      createDate: '',
      createUser: '',
      statusDownload: 0,
      file: '',
      orgCode: '',
      orgName: '',
      staffName: '',
      targetYmd: '',
      viewPeriod: 0,
      attendFlag: 1,
      organizationCategories: [],
      organizationCategoryMstHeadName: '',
      organizationCategoryMstName: '',
      statusDownloadName: '',
    });
  }

  getRawData(): AttendCSVOutput {
    return this.rawData;
  }

  get attendAchievementDownloadId(): string {
    return this.rawData.attendAchievementDownloadId;
  }

  set attendAchievementDownloadId(attendAchievementDownloadId: string) {
    this.rawData.attendAchievementDownloadId = attendAchievementDownloadId;
  }

  get targetYmd(): string {
    return this.rawData.targetYmd;
  }

  set targetYmd(targetYmd: string) {
    this.rawData.targetYmd = targetYmd;
  }

  
  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }


  get viewPeriod(): number {
    return this.rawData.viewPeriod;
  }

  set viewPeriod(viewPeriod: number) {
    this.rawData.viewPeriod = viewPeriod;
  }
  
  get attendFlag(): number {
    return this.rawData.attendFlag;
  }

  set attendFlag(attendFlag: number) {
    this.rawData.attendFlag = attendFlag;
  }

  get type(): string {
    return this.rawData.type;
  }

  set type(type: string) {
    this.rawData.type = type;
  }

  get layoutId(): string {
    return this.rawData.layoutId;
  }

  set layoutId(layoutId: string) {
    this.rawData.layoutId = layoutId;
  }

  get staffName(): string {
    return this.rawData.staffName;
  }

  set staffName(staffName: string) {
    this.rawData.staffName = staffName;
  }

  get layoutName(): string {
    return this.rawData.layoutName || "-";
  }

  set layoutName(layoutName: string) {
    this.rawData.layoutName = layoutName;
  }

  get periodFrom(): string {
    return this.rawData.periodFrom;
  }

  set periodFrom(periodFrom: string) {
    this.rawData.periodFrom = periodFrom;
  }

  get periodTo(): string {
    return this.rawData.periodTo;
  }

  set periodTo(periodTo: string) {
    this.rawData.periodTo = periodTo;
  }

  get closingDate(): string {
    return this.rawData.closingDate;
  }

  set closingDate(closingDate: string) {
    this.rawData.closingDate = closingDate;
  }

  get employmentName(): string {
    return this.rawData.employmentName || "-";
  }

  set employmentName(employmentName: string) {
    this.rawData.employmentName = employmentName;
  }

  get createDate(): string {
    return moment(this.rawData.createDate).format("YYYY年MM月DD日 HH:mm:ss");
  }

  set createDate(createDate: string) {
    this.rawData.createDate = createDate;
  }

  get statusDownload(): number {
    return this.rawData.statusDownload;
  }

  set statusDownload(statusDownload: number) {
    this.rawData.statusDownload = statusDownload;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get file(): string {
    return this.rawData.file.substring(this.rawData.file.lastIndexOf("/") + 1, this.rawData.file.length);
  }

  set file(file: string) {
    this.rawData.file = file;
  }

  get orgName(): string {
    return this.rawData.orgName || "全ての組織";
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get orgFullName(): string {
    if(!this.rawData.orgCode) {
      return '-';
    }
    return this.rawData.orgCode + " " + this.rawData.orgName;
  }

  get organizationCategories(): Array<any> {
    return this.rawData.organizationCategories;
  }

  set organizationCategories(organizationCategories: Array<any>) {
    this.rawData.organizationCategories = organizationCategories;
  }

  get organizationCategoryMstHeadName(): string {
    return this.rawData.organizationCategoryMstHeadName;
  }

  set organizationCategoryMstHeadName(organizationCategoryMstHeadName: string) {
    this.rawData.organizationCategoryMstHeadName = organizationCategoryMstHeadName;
  }

  get organizationCategoryMstName(): string {
    return this.rawData.organizationCategoryMstName;
  }

  set organizationCategoryMstName(organizationCategoryMstName: string) {
    this.rawData.organizationCategoryMstName = organizationCategoryMstName;
  }

  get statusDownloadName(): string {
    return this.rawData.statusDownloadName;
  }

  set statusDownloadName(statusDownloadName: string) {
    this.rawData.statusDownloadName = statusDownloadName;
  }
  
}