import React, { SVGProps } from 'react';

const Download: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M4.125 12v3.375h11.75V12h1.25v4.625H2.875V12h1.25zm6.5-9v8.261l2.925-2.924.884.884-4.442 4.442L5.55 9.22l.884-.884 2.941 2.942V3h1.25z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Download;
