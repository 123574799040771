import React from 'react';

import ConfirmModal from 'components/organismos/ConfirmModal';
import FormContents from 'components/atoms/Form/FormContents';
import ListVerticalSeparator from 'components/molecules/ListVerticalSeparator';
import PrimaryButton from 'components/atoms/Button';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import useOrgTreesOptions from 'hooks/useOrgTreesOptions';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import FormField from 'components/atoms/Form/FormField';
import { useHolidayAddForm } from './hooks';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const HolidayImportForm: React.FC = () => {
  const targetYear = sessionStorage.getItem('selectedYearHolidayMaster') || moment().format('YYYY');
  const selectedStartDate = `${targetYear}-01-01`;
  const selectedEndDate = `${targetYear}-12-31`;
  const orgTreesOptions = useOrgTreesOptions(selectedStartDate, selectedEndDate);
  const {
    formik, holidayList, confirmModalOpen, closeConfirmModal,
  } = useHolidayAddForm();

  // history
  const history = useHistory();

  /**
   *  handle back
   */
  const handleBack = async () => {
    sessionStorage.setItem('currentTab', '1');
    history.push('/agreementMaster');
  };

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <>
      <FormContents>
        <p>
          表示されている祝日を登録します。祝日を割り当てる組織を選択してください。
        </p>

        <FormField>
          <RadioSelectForm
            label="組織選択"
            name="isAll"
            items={[
              {
                label: '全ての組織に設定',
                value: 'all',
              },
              {
                label: '組織を選択して設定',
                value: 'selection',
              },
            ]}
            value={String(formik.values.isAll ? 'all' : 'selection')}
            setValue={(e) => formik.setFieldValue('isAll', e.target.value === 'all')}
            readOnly={true}
          />
        </FormField>

        <div style={String(formik.values.isAll) === 'false' ? {} : { display: 'none' }}>
          <VerticalLabelMutilSelectForm
            label=""
            name="orgCodes"
            values={formik.values.orgCodes.map(
              (orgCode: string) => orgTreesOptions.find((item) => orgCode === item.value),
            )}
            setValue={(val: any) => {
              formik.setFieldValue('orgCodes', val.slice(0, -1).split(','));
            }}
            options={orgTreesOptions}
            isMulti={true}
          />
        </div>

        {/* <RadioSelectForm
          label=""
          name="option"
          items={[
            {
              label: '全ての組織',
              value: 'all',
            },
            {
              label: '組織を選択して設定',
              value: 'selection',
            },
          ]}
          value={String(formik.values.option)}
          setValue={formik.handleChange}
        /> */}
        {/* { formik.values.option === 'selection' ? (
          <div style={{ width: '200px' }}>
            <VerticalLabelSelectForm
              label=""
              name="organization"
              value=""
              setValue={(val: string) => formik.setFieldValue('organization', val)}
              options={orgTreesOptions}
              isMulti={true}
            />
          </div>
        ) : ''}
 */}
        <div style={{ marginTop: '20px' }}>
          <ListVerticalSeparator
            title="取り込み祝日リスト"
            items={holidayList}
          />
        </div>
        <FormSubmitArea>
          { roleScreen && roleScreen.editable === 1 && (
            <div style={{ marginRight: '12px' }}>
              <PrimaryButton
                text="祝日を登録"
                onClick={formik.handleSubmit}
                minWidth="122px"
                disabled={!(holidayList && holidayList.length)}
              />
            </div>
          )}

          <PrimaryButton
            text="戻る"
            onClick={() => handleBack()}
            ghost={true}
          />
        </FormSubmitArea>

      </FormContents>

      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        title="確認メッセージ"
        content="登録します。よろしいですか？"
        submitText="登録"
      />
    </>
  );
};

export default HolidayImportForm;
