import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import BankRegistLayout from './BankRegistLayout';
import { BankDetailProvider } from '../context/BankRegistDetails';

const BankMasterPage = () => (
  <SidebarTemplate pageTitle="銀行入金登録">
    <BankDetailProvider>
      <BankRegistLayout />
    </BankDetailProvider>
  </SidebarTemplate>
);

export default BankMasterPage;
