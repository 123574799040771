import { useCallback, useEffect, useState } from "react"
// @ts-ignore
import _get from 'lodash.get';

export type FieldSortState = {
    fieldPath: string,
    sort: SortState,
    sortType?: SortTypeType,
}

/**
 * ソート項目
 */
export type SortColumn = {
    /** 画面に表示する項目名 */
    displayName: string,
    /** フィールド名（ドメインクラスやオブジェクトのプロパティ名を指定） */
    sortField: string,
    /** ソートタイプ （未指定はstringでのソート） */
    sortType?: SortTypeType,
    /** 項目幅（項目別で幅を調整したい場合に指定） */
    width?: string,
    /** 項目の余白（項目別で余白を調整したい場合に指定） */
    padding?: string,
    /** 項目のクラスを指定 */
    className?: string,
    /** 項目のスタイルを指定 */
    style?: React.CSSProperties,
    /** 項目のrowSpanを指定 */
    rowSpan?: number,
    /** 項目のcolSpanを指定 */
    colSpan?: number,
}

type HookStates<T> = {
    sortField: FieldSortState,
    sortedDataSource: T[]
}

type FieldValueResolver = <T>(item: T) => any

type HookActions<T> = {
    sortByFieldPath: (fieldPath: string, sortType?: SortTypeType) => void
}

type SortState = 'asc' | 'desc'
type SortTypeType = 'minute' | 'number' | 'date' | 'string'

const useSortTable = <T = any>(dataSource: T[], defaultSortField: FieldSortState): [HookStates<T>, HookActions<T>] => {
    const [sortField, setSortField] = useState<FieldSortState>(defaultSortField)
    const [sortedDataSource, setSortedDataSource] = useState<T[]>(dataSource || [])

    const transformToMin = (e: string) => {
        if(e.includes(".")){
            const part = e.split('.')
            return parseInt(part[0]) * 60 + parseInt(part[1])
        }
        if(e.includes(":")){
            const part = e.split(':')
            return parseInt(part[0]) * 60 + parseInt(part[1])
        }
        return 0
    }

    const transformToNumber = (e: string | number) => {
        if (!e) return 0;
        if (typeof(e) === 'number') return e;
        const number = e.replace(/[\,\%]/g, '')
        return Number(number)
    }

    /**
     *
     * @param fieldPath something like orgCode or discountAmountArr[1].discountAmount
     */
    const sortByFieldPath = useCallback((fieldPath: string, sortType?: SortTypeType) => {
        console.log(`${fieldPath},${sortField.fieldPath === fieldPath && sortField.sort === 'asc' ? 'desc' : 'asc'},${sortType}`)
        if (sortField.fieldPath === fieldPath) {
            setSortField({
                fieldPath,
                sort: sortField.sort === 'asc' ? 'desc' : 'asc',
                sortType,
            })
        } else {
            setSortField({
                fieldPath,
                sort: 'asc',
                sortType,
            })
        }
    }, [sortField])

    const sort = useCallback((list: T[], sortField: FieldSortState): T[] => {
        let data = list
        const result = data &&  data.sort((a:any , b:any ) => {
            if (sortField.sortType === 'date') {
                if (sortField.sort === 'asc') {
                    return  new Date(_get(a, sortField.fieldPath)) > new Date(_get(b, sortField.fieldPath))? 1 : -1
                }

                return new Date(_get(b, sortField.fieldPath)) > new Date(_get(a, sortField.fieldPath))? 1 : -1
            }
            if (sortField.sortType === 'minute') {
                if (sortField.sort === 'asc') {
                    return transformToMin(_get(a, sortField.fieldPath)) - transformToMin(_get(b, sortField.fieldPath))
                }

                return transformToMin(_get(b, sortField.fieldPath)) - transformToMin(_get(a, sortField.fieldPath))
            }
            if (sortField.sortType === 'number') {
                if (sortField.sort === 'asc') {
                    return transformToNumber(_get(a, sortField.fieldPath)) - transformToNumber(_get(b, sortField.fieldPath))
                }

                return transformToNumber(_get(b, sortField.fieldPath)) - transformToNumber(_get(a, sortField.fieldPath))
            }
            if (sortField.sort === 'asc') {
                return (_get(a, sortField.fieldPath) || '') > (_get(b, sortField.fieldPath) || '') ? 1 : -1
            }

            return( _get(b, sortField.fieldPath) || '') > (_get(a, sortField.fieldPath) || '') ? 1 : -1
        });
        return result
    }, [])

    useEffect(() => {
        setSortedDataSource(sort(sortedDataSource, sortField).concat([]))
    }, [sortField, sort])

    useEffect(() => {
        if (dataSource !== sortedDataSource) {
            setSortedDataSource(sort(dataSource, sortField))
        }
    }, [dataSource, sort])

    return [{ sortField, sortedDataSource }, { sortByFieldPath }]
}

export default useSortTable;
