import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import AttendPayslipImportLayout from 'components/organismos/master/atendanceManagement/AttendPayslipImportLayout';

const AttendPayslipImport = () => (
  <SidebarTemplate pageTitle="給与明細インポート">
    <AttendPayslipImportLayout />
  </SidebarTemplate>
);

export default AttendPayslipImport;
