import { useState, useEffect } from 'react';

import VacationMasterDomain from 'domain/master/labor/VacationMaster';

import getList from 'api/vacationMaster';

export const useVacationMaster = () => {
  const [vacationList, setVacationList] = useState<Array<VacationMasterDomain>>([]);
  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getList(companyCode).then((response: Array<any>) => {
      if (isSubscribed) {
        setVacationList(response.map((result) => new VacationMasterDomain(result)));
      }
    });
    return () => { isSubscribed = false; };
  }, []);
  return { vacationList, setVacationList };
};

export default useVacationMaster;
