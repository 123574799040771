/** @jsx jsx */
import React, { Dispatch,SetStateAction } from 'react';
import { jsx } from '@emotion/core';
import WrappedTable from './WrappedTable/main'


interface AccordionTableProps {
  isExpanded: boolean
  setStateAccordion: Dispatch<SetStateAction<boolean>>;
  backgroundColor?: string;
  hideBackgroundColor?: boolean
  children: JSX.Element[]
}

const AccordionTableWrapper: React.FC<AccordionTableProps> = ({
  children,
  isExpanded = false,
  setStateAccordion,
  backgroundColor, 
  hideBackgroundColor = false
}) : JSX.Element => {
  return (
    <React.Fragment>
      <WrappedTable
        children={children}
        isExpanded={isExpanded}
        setStateAccordion={setStateAccordion}
        backgroundColor={backgroundColor}
        hideBackgroundColor={hideBackgroundColor}
      />
    </React.Fragment>
  );
};

export default AccordionTableWrapper;
