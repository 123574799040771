/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import Select, { components, OptionTypeBase } from 'react-select';
import { ValueType } from 'react-select/src/types';
import FlexBox from 'components/atoms/FlexBox';

import {
  grayScale, productColor, textFontSize, textColor, utilityColor,
} from 'components/styles';
import FormLabel from 'components/atoms/Form/FormLabel';
import Icon from 'components/atoms/Icon';
import DateTimeSelectFormLayout from 'components/atoms/Form/DateTimeSelectFormLayout';
import Separater from 'components/atoms/Separator';
import Button from '../atoms/Button';

export interface OptionType extends OptionTypeBase {
  value: string,
  label: string,
}

const styles = {
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: '4px',
  }),
  selectDateStyles: css({
    width: '70px',
  }),
  wrapSameDays: css({
    display: 'flex',
    padding: '10px 0',
  }),
};

const selectStyles = {
  control: (oldStyles: any) => ({
    ...oldStyles,
    '& > div': {
      paddingLeft: 0,
      direction: 'ltr',
    },
  }),
  dropdownIndicator: (oldStyles: any) => ({
    ...oldStyles,
    cursor: 'pointer',
  }),
  input: (oldStyles: any) => ({
    ...oldStyles,
  }),
  multiValue: (oldStyles: any) => ({
    ...oldStyles,
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0D4796',
    borderRadius: '12px',
  }),
  multiValueLabel: (oldStyles: any) => ({
    ...oldStyles,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    color: productColor.primary,
  }),
  multiValueRemove: (oldStyles: any) => ({
    ...oldStyles,
    lineHeight: '21px',
    paddingRight: '6px',
    ':hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  }),
  placeholder: (oldStyles: any) => ({
    ...oldStyles,
    fontSize: '14px',
  }),
};

const MonthDateSelectForm: React.FC<{
  label: string;
  name: string;
  dateFormat: string;
  labelFormat: string;
  values: Array<OptionType>;
  setValue: (val: any) => void;
  description?: string;
  note?: string;
  isMulti?: boolean;
}> = ({
  label, name, dateFormat, labelFormat, values, setValue, description, isMulti, note,
}) => {
  const getValue = (): ValueType<OptionType> => values;

  const onChange = (option: any) => {
    if (option) {
      setValue(option);
    } else {
      setValue([]);
    }
  };

  const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <Icon type="calendar" color={grayScale.gray100} />
    </components.DropdownIndicator>
  );

  const MultiValueRemove = (props: any) => (
    <components.MultiValueRemove {...props}>
      <Icon type="close" color={grayScale.gray100} />
    </components.MultiValueRemove>
  );

  const [month, setMonth] = React.useState({ value: String(1), label: String(1) });
  const [date, setDate] = React.useState({ value: String(1), label: String(1) });
  const [days, setDays] = React.useState(31);

  const months = [];
  for (let i = 1; i <= 12; i++) {
    months.push({ value: String(i), label: String(i) });
  }

  const dates = [];
  for (let i = 1; i <= days; i++) {
    dates.push({ value: String(i), label: String(i) });
  }

  const handleAddSameDays = () => {
    const data = {
      value: `${month.value}/${date.value}`,
      label: `${month.value}月${date.value}日`,
    };

    if (!values.find((i: any) => i.value === data.value)) {
      setValue([...values, data]);
    }
  };

  const handleChangeMonth = (e: any) => {
    setDate({ value: String(1), label: String(1) });
    setDays(new Date((new Date()).getFullYear(), e.value, 0).getDate());
    setMonth({ value: e.value, label: e.value });
  };

  return (
    <div css={styles.container}>
      <DateTimeSelectFormLayout
        label={(
          <FormLabel
            label={label}
          />
        )}
        input={(
          <div>
            <div css={styles.wrapSameDays}>
              <FlexBox>
                <Select
                  css={styles.selectDateStyles}
                  value={month}
                  onChange={handleChangeMonth}
                  options={months}
                  components={{ IndicatorSeparator: () => null }}
                />
                <Separater margin="0 5px" />
                <div css={styles.wrapSameDays}>
                  月
                </div>
                <Separater margin="0 5px" />
                <Select
                  css={styles.selectDateStyles}
                  value={date}
                  onChange={(e: any) => { setDate({ value: e.value, label: e.value }); }}
                  options={dates}
                  components={{ IndicatorSeparator: () => null }}
                />
                <Separater margin="0 5px" />
                <div>
                  日
                </div>
                <Separater margin="0 5px 0 10px" />
                <Button
                  text="追加する"
                  ghost={true}
                  onClick={handleAddSameDays}
                />
              </FlexBox>
            </div>
            <div css={styles.select}>
              <Select
                styles={selectStyles}
                name={name}
                value={getValue()}
                onChange={onChange}
                placeholder=""
                isMulti={isMulti}
                isClearable={false}
                menuIsOpen={false}
                isRtl={true}
                components={{ DropdownIndicator, IndicatorSeparator: () => null, MultiValueRemove }}
              />
            </div>
          </div>
        )}
      />
      {note && <span css={css(styles.noteText, { color: utilityColor.error })} dangerouslySetInnerHTML={{ __html: note }} />}
    </div>
  );
};

export default MonthDateSelectForm;
