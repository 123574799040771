/** @jsx jsx */
import React, { useCallback } from 'react';
import { css, jsx } from '@emotion/core';

import productColor, { textFontSize } from 'components/styles';


// interface AccordionTableProps {
//   text: string;
//   color: string;
//   defaultExpand?: boolean;
//   accordionTableValue?: string;
//   callBack: () => void;
// }

const TableFirstRow: React.FC<any> = ({
  rowData,
  handleExpand,
  backgroundColor,
  hideBackgroundColor,
  bodyRowLength
}) => {
  return (
    <React.Fragment>
      {
        rowData.map((r:any) => (React.cloneElement(r, { onClick: handleExpand,
          style:{
            backgroundColor: hideBackgroundColor ? '': backgroundColor ? backgroundColor : 'white',
            cursor: bodyRowLength > 0 ? 'pointer' : ''
        } })))
      }
    </React.Fragment>
  );
};

export default TableFirstRow;
