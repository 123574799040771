import React from 'react';
import DailySalesDate from 'components/organismos/master/general/salesPage/dailySalesDate';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const DailySalesManagementPage = () => (
  <SidebarTemplate pageTitle="【日別】売上管理表">
    <DailySalesDate />
  </SidebarTemplate>
);

export default DailySalesManagementPage;
