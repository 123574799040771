export interface AccountTitleMSTItem {
  accountTitleId: string;
    accountTitleCode: string;
    accountTitleName: string;
    isAll: boolean;
    isEdit: boolean;
  }
  
  export default class AccountTitleMSTItemDomain {
    constructor(private rawData: AccountTitleMSTItem) {
      // do nothing
    }
  
    static generateInitial(): AccountTitleMSTItemDomain {
      return new AccountTitleMSTItemDomain({
        accountTitleId: '',
        accountTitleCode: '',
        accountTitleName: '',
        isAll: false,
        isEdit: false,
      });
    }
  
    getRawData(): AccountTitleMSTItem {
      return this.rawData;
    }
  
    get accountTitleId(): string {
      return this.rawData.accountTitleId;
    }
  
    set accountTitleId(accountTitleId: string) {
      this.rawData.accountTitleId = accountTitleId;
    }
  
    get accountTitleCode(): string {
      return this.rawData.accountTitleCode;
    }
  
    set accountTitleCode(accountTitleCode: string) {
      this.rawData.accountTitleCode = accountTitleCode;
    }
  
    get accountTitleName(): string {
      return this.rawData.accountTitleName;
    }
  
    set accountTitleName(accountTitleName: string) {
      this.rawData.accountTitleName = accountTitleName;
    }
  
    set isAll(isAll: boolean) {
      this.rawData.isAll = isAll;
    }
  
    get isEdit(): boolean {
      return this.rawData.isEdit;
    }
  }
  