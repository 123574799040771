/** @jsx jsx */
import React, { useMemo, useState, useCallback } from 'react';
import { Column } from 'react-table';
import { Link, useHistory } from 'react-router-dom';
import { css, jsx } from '@emotion/core';
import PrimaryButton from 'components/atoms/Button';
import PageTitle from 'components/atoms/PageTitle';
import DataTable from 'components/organismos/DataTable/DataTable';
import HolidayMasterDomain from 'domain/master/labor/holidayMaster';
import { deleteById } from 'api/holidayMaster';
import LableTitle from 'components/atoms/LableTitle';
import { iconColor, gridCol } from 'components/styles';
import Icon from 'components/atoms/Icon';
import { useOrgTreesOptionsAll } from 'hooks/useOrgTreesOptions';
import SelectForm from 'components/molecules/SelectForm';
import FlexBox from 'components/atoms/FlexBox';
import YearPicker from 'components/molecules/YearPicker/YearPicker';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import useToastNotification from 'hooks/useToastNotification';
import { useHolidayMasterList } from './hooks';

const HolidayMasterPage: React.FC<{
  roleScreen?: any
}> = ({ roleScreen }) => {
  const { successNotification, errorNotification } = useToastNotification();

  const [selectedYear, setSelectedYear] = useState(Number(sessionStorage.getItem('selectedYearHolidayMaster')));
  const [orgCode, setorgCode] = useState(sessionStorage.getItem('selectedOrgCodeHolidayMaster'));

  const selectedStartDate = `${selectedYear}-01-01`;
  const selectedEndDate = `${selectedYear}-12-31`;
  const orgTreesOptions = useOrgTreesOptionsAll(selectedStartDate, selectedEndDate);

  const { holidayList, setHolidayList } = useHolidayMasterList(
    String(orgCode), String(selectedYear),
  );

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedHolidayMaster, setSelectedHolidayMaster] = useState(
    HolidayMasterDomain.generateInitial(),
  );

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((holiday : HolidayMasterDomain) => {
    setConfirmModalOpen(true);
    setSelectedHolidayMaster(holiday);
  }, []);
  

  const deleteUpdateHoliday = useCallback(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

    deleteById(
      companyCode, selectedHolidayMaster.publicHolidayDate,
      selectedHolidayMaster.publicHolidayName,
      (String(orgCode)),
    ).then((Response: any) => {
      setHolidayList(
        holidayList.filter(
          (holiday) => holiday.publicHolidayId !== selectedHolidayMaster.publicHolidayId,
        ),
      );
      // fix row is hidden alfter delete
      const old = orgCode;
      setorgCode('all');
      setorgCode(old);
      setConfirmModalOpen(false);
      successNotification('削除しました。');
    }).catch((error) => {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        let msgError = '';
        error.response.data.errors.map((item: { defaultMessage: string; }) => {
          msgError += `${item.defaultMessage} \n`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [errorNotification, holidayList, orgCode, selectedHolidayMaster.publicHolidayDate,
    selectedHolidayMaster.publicHolidayId, selectedHolidayMaster.publicHolidayName,
    setHolidayList, successNotification]);

  const columns: Array<Column<HolidayMasterDomain>> = useMemo(() => [
    {
      Header: '日付',
      accessor: 'publicHolidayDateStr',
    },
    {
      Header: '祝日名',
      accessor: 'publicHolidayName',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: HolidayMasterDomain },
        data: Array<HolidayMasterDomain>
      }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/holidayMaster/edit/${`${cell.row.original.publicHolidayId}/${cell.row.original.publicHolidayDate}/${cell.row.original.publicHolidayName}/${orgCode}/false`}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            {
              roleScreen && roleScreen.editable === 1 && (
              <React.Fragment>
                <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                  <span
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                    onMouseEnter={() => setIsHoverDelete(true)}
                    onMouseLeave={() => setIsHoverDelete(false)}
                  >
                    <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </Link>
              </React.Fragment>
            )}
          </div>
        );
      },
    },
  ], [openConfirmModal, orgCode, roleScreen]);

  return (

    <div>
      <LableTitle title="設定された祝日は勤怠機能で固定の休日として利用できます。" />
      
      <PageTitle
        title=""
        rightContents={(
          <div>
            {
              roleScreen && roleScreen.editable === 1 && (
              <Link to="/holidayMaster/create">
                <span style={{ marginRight: '12px' }}>
                  <PrimaryButton
                    ghost={false}
                    text="新規祝日作成"
                  />
                </span>
              </Link>
            )}
            {
              roleScreen && roleScreen.importFlag === 1 && (
              <Link to="/holidayMaster/import">
                <PrimaryButton
                  ghost={false}
                  text="日本の祝日取り込み"
                />
              </Link>
            )}
          </div>
      )}
      />  

      <div style={{ marginRight: '12px', padding: '16px' }}>
        <FlexBox>
          <div style={{ marginRight: '12px', width: gridCol.grid03 }}>
            <FlexBox>
              <span style={{ flexBasis: '100px' }}>対象年：</span>
              <YearPicker
                selectedYear={selectedYear}
                onChange={(year: number) => {
                  sessionStorage.setItem('selectedYearHolidayMaster', String(year));
                  setSelectedYear(year);
                }}
              />
            </FlexBox>

          </div>
          <div style={{ width: gridCol.grid01 }} />
          <div style={{ width: gridCol.grid03 }}>
            <SelectForm
              label="組織名："
              name="orgCode"
              value={String(orgCode)}
              setValue={(v) => {
                sessionStorage.setItem('selectedOrgCodeHolidayMaster', v);
                setorgCode(v);
              }}
              options={orgTreesOptions}
              description=""
            />
          </div>
        </FlexBox>

      </div>
      <div
        css={css`
      table th:nth-child(1) {
        width: 100px;
      }
      table th:nth-child(2) {
        width: 200px;
      }
      table th:nth-child(3) {
        width: 80px;
      }
      `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
          <DataTable 
          columns={columns} 
          data={holidayList} 
          sortBy={[
            { id: 'publicHolidayDateStr', desc: false }
          ]}
          />
        </div>
      </div>

      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={deleteUpdateHoliday}
        actionType={ActionType.DELETE}
      />

    </div>
  );
};

export default HolidayMasterPage;
