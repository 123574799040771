import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import ExecutiveAddForm from 'components/organismos/Executive/ExecutiveAddForm';
import { useParams } from 'react-router-dom';

const ExecutiveFormAdd: React.FC = () => {
  const { executiveId } = useParams();
  return (
    <SidebarTemplate pageTitle={executiveId ? '役職編集' : '役職作成'}>
      <ExecutiveAddForm />
    </SidebarTemplate>
  );
};

export default ExecutiveFormAdd;
