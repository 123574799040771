import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';

import SalaryCsvSettingDomain from 'domain/salaryCsvSetting';
import { getPayslipCsvFormat, savePayslipCsvFormat } from 'api/salaryCsvSetting';
import useToastNotification from 'hooks/useToastNotification';

interface ItemSetting {
  formatDetailId: string;
  itemName: string;
  csvPosition: any;
  itemIndex: any;
  itemCategory: any;
  formatId: string;
  companyCode: string;
  createUser: string;
  updateUser: string;
}
const defaultList : Array<ItemSetting> = [];

export const useSalaryCsvSettingAddForm = (
) => {
  // Modal alert
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  // value option
  const [detailList, setDetailList] = useState(defaultList);

  /**
   *  getFormatDetailId
   *
   */
  const getItemIndex = (itemSetting: ItemSetting) => {
    if (itemSetting) {
      return itemSetting.itemIndex;
    }
    return '';
  };


  /**
   *  getFormatDetailId
   *
   */
  const getFormatDetailId = (itemSetting: ItemSetting) => {
    if (itemSetting) {
      return itemSetting.formatDetailId;
    }
    return '';
  };

  /**
   *  getItemName
   *
   */
  const getItemName = (itemSetting: ItemSetting) => {
    if (itemSetting) {
      return itemSetting.itemName;
    }
    return '';
  };

  /**
   *  getCsvPosition
   *
   */
  const getCsvPosition = (itemSetting: ItemSetting) => {
    if (itemSetting) {
      return itemSetting.csvPosition;
    }
    return 0;
  };


  const optionsFormatType = [
    {
      label: '給与',
      value: '0',
    },
    {
      label: '賞与',
      value: '1',
    },
  ];

  // 登録内容確認モーダルの開閉状態
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';

  const onSubmit = async (values: SalaryCsvSettingDomain) => {
    if (!confirmModalOpen) {
      // 登録内容確認モーダルが表示されていなければ表示する
      setConfirmModalOpen(true);
      return;
    }

    //
    let convertData = values.getRawData();
    console.log(convertData);

    //
    delete (convertData as any).createDate;
    delete (convertData as any).updateDate;
    delete (convertData as any).detailList;

    //
    let isCheckSpace = false;
    detailList.forEach((item: any, index: number) => {
      // eslint-disable-next-line no-param-reassign
      delete (item as any).createDate;
      // eslint-disable-next-line no-param-reassign
      delete (item as any).updateDate;
      if ((item as any).itemName.length && (item as any).itemName.trim() === '') {
        isCheckSpace = true;
      }
      (item as any).itemName = (item as any).itemName.trim();

      convertData = {
        ...convertData,
        [`detailList[${index}]`]: {
          ...item,
        },
      };
    });

    if (isCheckSpace) {
      setConfirmModalOpen(false);
      errorNotification('コードに半角、全角スペースは入力できません。');
      return;
    }

    // check process
    try {
      const response = await savePayslipCsvFormat(companyCode, convertData);
      setConfirmModalOpen(false);
      if (!response.errors) {
        successNotification('更新しました。');
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };
  const formik = useFormik({
    initialValues: SalaryCsvSettingDomain.generateInitial(),
    onSubmit,
    validateOnChange: false,
  });

  /**
   *
   *
   */
  useEffect(() => {
    setPayslipCsvFormat(formik.values.formatType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
   *  funct call api & handle data
   *
   */
  const setPayslipCsvFormat = async (formatType: any) => {
    if (formatType !== undefined) {
      getPayslipCsvFormat(formatType).then((response: any) => {
        if (response && response.detailList) {
          formik.setValues(new SalaryCsvSettingDomain(response));
          if (response.detailList) {
            setDetailList(response.detailList);
          }
          return true;
        }

        response.formatType = formatType;
        response.companyCode = companyCode;
        response.formatId = '';
        response.createUser = staffName;
        response.updateUser = staffName;
        formik.setValues(new SalaryCsvSettingDomain(response));
        const detailList: Array<ItemSetting> = [];
        let csvPosition: any = null;
        let itemName = '';
        for (let i = 0; i < 76; i++) {
          let itemCategory = 0;
          if (i >= 6 && i < 26) {
            itemCategory = 1;
          } else if (i >= 26 && i < 46) {
            itemCategory = 2;
          } else if (i >= 46 && i < 66) {
            itemCategory = 3;
          } else if (i >= 66 && i < 76) {
            itemCategory = 4;
          }
          if (i < 4) {
            csvPosition = i + 1;
          } else {
            csvPosition = null;
          }
          //
          if (i === 2) {
            itemName = '支給対象年月';
          } else if (i === 3) {
            itemName = '支給年月日';
          } else {
            itemName = '';
          }
          detailList.push(
            {
              formatDetailId: '',
              itemName,
              csvPosition,
              itemIndex: i,
              itemCategory,
              formatId: '',
              companyCode,
              createUser: staffName,
              updateUser: staffName,
            },
          );
        }
        setDetailList(detailList);
        return false;
      });
    }
  };

  /**
   *
   *
   */
  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);


  // 登録内容確認モーダル用データ
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    optionsFormatType,
    detailList,
    setDetailList,
    getFormatDetailId,
    getCsvPosition,
    getItemName,
    getItemIndex,
    setPayslipCsvFormat,
    closeToastModal,
    toastModalOpen,
    setToastModalOpen,
  };
};

export default {
  useSalaryCsvSettingAddForm,
};
