/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable  eqeqeq */
/** @jsx jsx */
import React, { useEffect, useReducer, useCallback } from "react";
import { css, jsx } from "@emotion/core";
import BodyText from "components/atoms/BodyText";
import VerticalLabelSelectFormV2 from "components/molecules/VerticalLabelSelectFormV2";
import ModalForm from "components/molecules/ModalForm";
import VerticalLabelTextLayout from "components/atoms/Form/VerticalLabelTextLayout";
import VerticalLabelFormLayoutV2 from "components/atoms/Form/VerticalLabelFormLayoutV2";
import ShiftPatternDetailList from "./ShiftPatternDetailList";
import { useMonthlyShiftContext } from "../contextHook";
import { textColor } from "components/styles";
import { getShift, Shift, postShiftV1 } from "api/shift";
import { useBusinessAttendChoiceOptions } from "hooks/useBusinessMasterOptions";
import moment from "moment";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import TextareaAutoResize from "components/atoms/input/TextareaAutoResize";
import DatePickerForm from 'components/molecules/DatePickerForm';
import useToastNotification from "hooks/useToastNotification";

const ModalRegisterOtherStaffShift: React.FC<{
  orgName?: string
}> = ({
  orgName
}) => {
  const {
    remote,
    setLoading,
    filters: { selectedOrg, viewPeriod },
    shiftPattern: { openShiftPatternStaffModal, setShiftPatternStaffModal, shiftPatternOptions, getWorkdayOptions },
    monthlyCalendar: { selectedItem, setSelectedItem, selectedCell, reFetchMonthlyShifts },
  } = useMonthlyShiftContext();
  const { errorNotification } = useToastNotification();

  const defaultShiftDetailList = [
    {
      startTime: selectedCell?.targetDate,
      isStartTimeNextDay: false,
      endTime: selectedCell?.targetDate,
      isEndTimeNextDay: false,
      businessId: "",
      createUser: sessionStorage.getItem("loginUser.staffName") || "",
      updateUser: sessionStorage.getItem("loginUser.staffName") || "",
      shiftDetailId: "",
      isRemote: true,
    },
  ];
  const defaultShift = {
    companyCode: "",
    createUser: sessionStorage.getItem("loginUser.staffName") || "",
    updateUser: sessionStorage.getItem("loginUser.staffName") || "",
    createDate: "",
    updateDate: "",
    shiftId: "",
    orgCode: selectedOrg,
    orgName: orgName,
    belongOrgCode: "",
    staffCode: selectedCell?.staffCode,
    staffName: selectedCell?.staffName,
    targetDate: selectedCell?.targetDate,
    isLegal: false,
    notifiedToStampForgot: false,
    startTime: selectedCell?.targetDate,
    endTime: selectedCell?.targetDate,
    shiftDetailList: defaultShiftDetailList,
    procType: 0,
    attendType: 0,
    closingHour: 0,
    distinctionHoliday: false,
    shopPlanId: "",
    shopPlan: "",
    shopPlanOrgCode: "",
    shopPlanTargetDate: "",
  };

  const memorizedReducer = useCallback((state: Shift, { type, payload }: any): Shift => {
    console.log('shiftDetailList', state.shiftDetailList);
    let shiftDetailList = [...state.shiftDetailList];
    switch (type) {
      case "SET_STATE":
        return payload;

      case "START_HOUR_ONCHANGE":
        shiftDetailList[payload.index].startTime = payload.startTime;
        return { ...state, startTime: shiftDetailList[0].startTime, shiftDetailList };

      case "END_HOUR_ONCHANGE":
        if (shiftDetailList[payload.index + 1]) {
          shiftDetailList[payload.index + 1].startTime = payload.endTime;
        }
        shiftDetailList[payload.index].endTime = payload.endTime;
        return { ...state, endTime: shiftDetailList[shiftDetailList.length - 1].endTime, shiftDetailList };

      case "START_NEXTDAY_ONCHANGE":
        shiftDetailList[payload.index].isStartTimeNextDay = payload.isStartTimeNextDay;

        shiftDetailList[payload.index].startTime = payload.isStartTimeNextDay
          ? moment(shiftDetailList[payload.index].startTime)
              .add("day", 1)
              .format("YYYY/MM/DD HH:mm:ss")
          : moment(shiftDetailList[payload.index].startTime)
              .subtract("day", 1)
              .format("YYYY/MM/DD HH:mm:ss");

        return { ...state, shiftDetailList };

      case "END_NEXTDAY_ONCHANGE":
        if (shiftDetailList[payload.index + 1]) {
          shiftDetailList[payload.index + 1].isStartTimeNextDay = payload.isEndTimeNextDay;
        }
        shiftDetailList[payload.index].isEndTimeNextDay = payload.isEndTimeNextDay;

        shiftDetailList[payload.index].endTime = payload.isEndTimeNextDay
          ? moment(shiftDetailList[payload.index].endTime)
              .add("day", 1)
              .format("YYYY/MM/DD HH:mm:ss")
          : moment(shiftDetailList[payload.index].endTime)
              .subtract("day", 1)
              .format("YYYY/MM/DD HH:mm:ss");

        return { ...state, shiftDetailList };

      case "IS_BREAK_ONCHANGE":
        shiftDetailList[payload.index].businessId = payload.businessId;
        return { ...state, shiftDetailList };

      case "IS_REMOTE_ONCHANGE":
        shiftDetailList[payload.index].isRemote = payload.isRemote;
        return { ...state, shiftDetailList };

      case "WORKDAY_TYPE_ONCHANGE":
        switch (payload) {
          case "legal":
            defaultShiftDetailList[0].isEndTimeNextDay = false;
            defaultShiftDetailList[0].startTime = moment(state.targetDate)
              .add("hour", 0)
              .format("YYYY/MM/DD HH:mm:ss");
            defaultShiftDetailList[0].endTime = moment(state.targetDate)
              .add({ day: 1, hour: 0 })
              .format("YYYY/MM/DD HH:mm:ss");
            return {
              ...state,
              shiftPatternId: "",
              holidayId: "legal",
              isLegal: true,
              attendType: 1,
              shiftDetailList: defaultShiftDetailList,
            };

          case "scheduled":
            defaultShiftDetailList[0].isEndTimeNextDay = true;
            defaultShiftDetailList[0].startTime = moment(state.targetDate)
              .add("hour", 0)
              .format("YYYY/MM/DD HH:mm:ss");
            defaultShiftDetailList[0].endTime = moment(state.targetDate)
              .add({ day: 1, hour: 0 })
              .format("YYYY/MM/DD HH:mm:ss");
            return {
              ...state,
              shiftPatternId: "",
              holidayId: "scheduled",
              isLegal: false,
              attendType: 1,
              shiftDetailList: defaultShiftDetailList,
            };

          default:
            return { ...state, shiftPatternId: "", holidayId: "", shiftDetailList: defaultShiftDetailList };
        }

      case "SHIFTPATTERN_ONCHANGE":
        let shiftPattern = shiftPatternOptions.find((pattern) => pattern.shiftPatternId === payload);

        let newShiftDetailList;
        if (shiftPattern) {
          newShiftDetailList = shiftPattern.attendShiftPatternDetails.map((patternDetail) => {
            return {
              startTime: moment(`${state.targetDate.split(" ")[0]} ${patternDetail.startTime}`).format("YYYY/MM/DD HH:mm:ss"),
              isStartTimeNextDay: patternDetail.isStartTimeNextDay,
              endTime: moment(`${state.targetDate.split(" ")[0]} ${patternDetail.endTime}`).format("YYYY/MM/DD HH:mm:ss"),
              isEndTimeNextDay: patternDetail.isEndTimeNextDay,
              businessId: patternDetail.businessId || "",
              createUser: patternDetail.createUser,
              updateUser: patternDetail.updateUser,
              isRemote: patternDetail.isRemote,
            };
          });
        } else {
          newShiftDetailList = defaultShiftDetailList;
        }
        return { ...state, shiftPatternId: payload, shiftDetailList: newShiftDetailList };

      case "ADD_SHIFTDETAILLIST":
        let newDefaultShiftDetail = { ...defaultShiftDetailList[0] };
        newDefaultShiftDetail.businessId = '';
        console.log('ADD_SHIFTDETAILLIST', newDefaultShiftDetail);
        newDefaultShiftDetail.startTime = state.shiftDetailList[state.shiftDetailList.length - 1].endTime;
        newDefaultShiftDetail.isStartTimeNextDay = state.shiftDetailList[state.shiftDetailList.length - 1].isEndTimeNextDay;
        let prevEndDate = moment(state.shiftDetailList[state.shiftDetailList.length - 1].endTime);
        newDefaultShiftDetail.endTime = !state.shiftDetailList[state.shiftDetailList.length]?.endTime ? 
                        prevEndDate.hour(Number(0)).format("YYYY/MM/DD HH:mm:ss") : state.shiftDetailList[state.shiftDetailList.length]?.endTime;
        return { ...state, shiftDetailList: [...state.shiftDetailList, newDefaultShiftDetail] };

      case "DELETE_SHIFTDETAILLIST":
        shiftDetailList.splice(payload, 1);
        return { ...state, shiftDetailList };
      
      case "ORG_NAME":
        let orgName = payload;
        return { ...state, orgName }

      default:
        return state;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const businessAttendChoiceOptions = useBusinessAttendChoiceOptions(
    selectedItem?.staffCode || selectedCell?.staffCode,
    moment(selectedItem?.targetDate).format("YYYY/MM/DD")
  );
  const [state, dispatch] = useReducer(memorizedReducer, defaultShift);

  const resetShift = () => {
    dispatch({ type: "SET_STATE", payload: defaultShift });
  };

  useEffect(() => {
    dispatch({ type: "ORG_NAME", payload: orgName });
  }, [orgName])

  const onSubmit = async () => {
    let newState = formatToSubmitObject(state);
    try {
      setLoading(true);
      await postShiftV1(newState);
      await reFetchMonthlyShifts();
      //reset
      setShiftPatternStaffModal(false);
      resetShift();
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const formatToSubmitObject = (state: Shift) => {
    return {
      viewPeriod: viewPeriod,
      shopPlanId: "",
      shopPlan: "",
      shopPlanOrgCode: "",
      shopPlanTargetDate: "",
      targetDate: state.targetDate,
      staffCode: state.staffCode,
      staffName: state.staffName,
      attendType: state.attendType,
      orgCode: state.orgCode,
      orgName: state.orgName,
      shiftPatternId: state.shiftPatternId || "",
      shiftDetailList: state.shiftDetailList.map((detail) => {
        return {
          startHourForm: moment(detail.startTime).format("HH"),
          startTimeForm: moment(detail.startTime).format("mm"),
          startTime: detail.startTime,
          isStartTimeNextDay: detail.isStartTimeNextDay,
          endHourForm: moment(detail.endTime).format("HH"),
          endTimeForm: moment(detail.endTime).format("mm"),
          endTime: detail.endTime,
          isEndTimeNextDay: detail.isEndTimeNextDay,
          businessId: detail.businessId || "",
          shiftId: state.shiftId,
          shiftDetailId: detail.shiftDetailId || "",
          createUser: detail.createUser,
          updateUser: detail.updateUser,
          isRemote: remote ? detail.isRemote: false,
        };
      }),
      shiftId: state.shiftId,
      startTime: state.shiftDetailList[0].startTime,
      endTime: state.shiftDetailList[state.shiftDetailList.length - 1].endTime,
      procType: state.procType,
      createUser: state.createUser,
      updateUser: state.updateUser,
      requestNote: state.requestNote !== undefined ? state.requestNote : '',
      deadline: state.deadline !== undefined ? state.deadline : moment(new Date()).format('YYYY/MM/DD'),
    };
  };

  const formatShiftDate = (shift: Shift) => {
    return {
      companyCode: shift.companyCode,
      createUser: shift.createUser,
      updateUser: shift.updateUser,
      createDate: shift.createDate,
      updateDate: shift.updateDate,
      shiftId: shift.shiftId,
      orgCode: shift.orgCode,
      orgName: shift.orgName,
      belongOrgCode: shift.belongOrgCode,
      staffCode: shift.staffCode,
      staffName: shift.staffName,
      targetDate: moment(shift.targetDate).format("YYYY/MM/DD HH:mm:ss"),
      isLegal: shift.isLegal,
      notifiedToStampForgot: shift.notifiedToStampForgot,
      startTime: moment(shift.startTime).format("YYYY/MM/DD HH:mm:ss"),
      endTime: moment(shift.endTime).format("YYYY/MM/DD HH:mm:ss"),
      shiftDetailList: shift.holidayId
        ? [{
            startTime: moment(shift.startTime)
              .hour(0)
              .format("YYYY/MM/DD HH:mm:ss"),
            isStartTimeNextDay: false,
            endTime: moment(shift.endTime).hour(5).format("YYYY/MM/DD HH:mm:ss"),
            isEndTimeNextDay: true,
            businessId: "",
            createUser: shift.createUser,
            updateUser: shift.createUser,
            shiftDetailId: "",
          }]
        : shift.shiftDetailList.map((detail) => ({
            startTime: moment(detail.startTime).format("YYYY/MM/DD HH:mm:ss"),
            isStartTimeNextDay: detail.isStartTimeNextDay,
            endTime: moment(detail.endTime).format("YYYY/MM/DD HH:mm:ss"),
            isEndTimeNextDay: detail.isEndTimeNextDay,
            businessId: detail.businessId,
            createUser: detail.createUser,
            updateUser: detail.updateUser,
            shiftDetailId: detail.shiftDetailId || "",
            isRemote: detail.isRemote ? true : false
          })),
      procType: shift.procType,
      attendType: shift.attendType,
      closingHour: shift.closingHour,
      distinctionHoliday: shift.distinctionHoliday,
      shiftPatternId: shift.shiftPatternId,
      requestNote: shift.requestNote || "",
      holidayId: shift.holidayId || "",
      notifiedBeforeShiftStart: shift.notifiedBeforeShiftStart || "",
    };
  };

  // const fetchShift = async () => {
  //   setLoading(true);
  //   let shiftData = await getShift(selectedItem.id);
  //   shiftData = formatShiftDate(shiftData);
  //   dispatch({ type: "SET_STATE", payload: shiftData });
  //   setLoading(false);
  // };

  useEffect(() => {
    const fetchShift = async () => {
      setLoading(true);

      let shiftData = await getShift(selectedItem.id);
      shiftData = formatShiftDate(shiftData);
      dispatch({ type: "SET_STATE", payload: shiftData });

      setLoading(false);
    };

    if (selectedItem) {
      fetchShift();
    }

    return () => {
      setSelectedItem(null);
      resetShift();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalForm
      open={openShiftPatternStaffModal}
      closeHandler={() => {
        setShiftPatternStaffModal(false);
      }}
      title="シフト追加"
      submitText={selectedItem ? "更新" : "登録"}
      onSubmit={onSubmit}
      closeText="キャンセル"
      width="800px"
      contentWidth="80%"
    >
      <VerticalLabelTextLayout label={`対象日`} variable={moment(state.targetDate || selectedCell.targetDate).format("YYYY/MM/DD")} />
      <VerticalLabelTextLayout label={`スタッフコード`} variable={state?.staffCode} />
      <VerticalLabelTextLayout label={`氏名`} variable={state?.staffName} />

      <VerticalLabelFormLayoutV2
        label="勤務日種別"
        customCss={css({ color: textColor.main })}
        input={
          <div css={css({ width: "100%", marginBottom: "20px" })}>
            <VerticalLabelSelectFormV2
              label=""
              placeholder="通常"
              name="workdayType"
              value={state.holidayId && state.isLegal ? "legal" : state.holidayId ? "scheduled" : "normal"}
              setValue={(type: string) => {
                dispatch({
                  type: "WORKDAY_TYPE_ONCHANGE",
                  payload: type,
                });
              }}
              options={getWorkdayOptions(state.distinctionHoliday, true)}
              menuPlacement="top"
            />
          </div>
        }
        zIndex={0}
      />

      <VerticalLabelTextLayout label={`出勤組織`} variable={state?.orgName} />

      <VerticalLabelFormLayout
        label="シフトパターン"
        // customCss={css({ color: textColor.main })}
        input={
          <div css={css({ width: "100%" })}>
            <VerticalLabelSelectForm
              label=""
              placeholder="通常出勤"
              name="shiftpattern"
              value={state.shiftPatternId || ""}
              setValue={(shiftpatternId) => {
                dispatch({
                  type: "SHIFTPATTERN_ONCHANGE",
                  payload: shiftpatternId,
                });
              }}
              options={[{ label: "-", value: "" }].concat(
                shiftPatternOptions.map((pattern) => ({
                  label: pattern.shiftPatternName,
                  value: pattern.shiftPatternId,
                }))
              )}
              disable={state?.holidayId ? true : false}
              menuPlacement="top"
            />
          </div>
        }
      />

      <BodyText customStyle={css({ display: "block" })}>シフト時間</BodyText>

      {
      state && (
        <ShiftPatternDetailList
          shiftDetail={state.shiftDetailList}
          isDisable={state.shiftPatternId || state.holidayId ? true : false}
          businessAttendChoiceOptions={businessAttendChoiceOptions}
          dispatch={dispatch}
          remote={remote}
        />
      )
      }
    </ModalForm>
  );
};

export default ModalRegisterOtherStaffShift;
