import { ScheduleStatus } from 'api/schedule';

export type HopeShift = {
  createUser: string,
  updateUser: string,
  hopeShiftId: string | null,
  orgCode: string,
  orgName: string,
  staffCode: string,
  targetDate: string,
  hopeShiftPatternType: number,
  shiftPatternId: string,
  startTime: Date | null,
  endTime: Date | null,
  note: string,
  fixStatus: number,
  isEditable: boolean,
  holidayName?: string,
  employmentId?: string,
  businessId?: string
}

export class HopeShiftDomain {
  constructor(private rawData: HopeShift) {
    // do nothing
  }

  static generateInitial(): HopeShiftDomain {
    return new HopeShiftDomain({
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      hopeShiftId: null,
      orgCode: sessionStorage.getItem('loginUser.orgCode') || '',
      orgName: sessionStorage.getItem('loginUser.orgName') || '',
      staffCode: sessionStorage.getItem('loginUser.staffCode') || '',
      targetDate: '',
      hopeShiftPatternType: 0,
      shiftPatternId: '',
      startTime: new Date(),
      endTime: new Date(),
      note: '',
      fixStatus: ScheduleStatus.NO_REGISTER,
      isEditable: true,
    });
  }

  public getRawData() {
    return this.rawData;
  }

  get isEditable(): boolean {
    return this.rawData.isEditable;
  }

  set isEditable(isEditable: boolean) {
    this.rawData.isEditable = isEditable;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  get hopeShiftId(): string | null{
    return this.rawData.hopeShiftId;
  }

  set hopeShiftId(hopeShiftId: string | null) {
    this.rawData.hopeShiftId = hopeShiftId;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }


  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get holidayName(): string| undefined {
    return this.rawData.holidayName;
  }

  set holidayName(holidayName: string| undefined) {
    this.rawData.holidayName = holidayName;
  }

  get employmentId(): string| undefined {
    return this.rawData.employmentId;
  }

  set employmentId(employmentId: string| undefined) {
    this.rawData.employmentId = employmentId;
  }

  get businessId(): string| undefined {
    return this.rawData.businessId;
  }

  set businessId(businessId: string| undefined) {
    this.rawData.businessId = businessId;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get shiftPatternId(): string {
    return this.rawData.shiftPatternId;
  }

  set shiftPatternId(shiftPatternId: string) {
    this.rawData.shiftPatternId = shiftPatternId;
  }

  get note(): string {
    return this.rawData.note;
  }

  set note(note: string) {
    this.rawData.note = note;
  }

  get targetDate(): string {
    return this.rawData.targetDate;
  }

  set targetDate(targetDate: string) {
    this.rawData.targetDate = targetDate;
  }

  get startTime(): Date | null{
    return this.rawData.startTime;
  }

  set startTime(startTime: Date | null) {
    this.rawData.startTime = startTime;
  }

  get endTime(): Date | null{
    return this.rawData.endTime;
  }

  set endTime(endTime: Date | null) {
    this.rawData.endTime = endTime;
  }

  get hopeShiftPatternType(): number {
    return this.rawData.hopeShiftPatternType;
  }

  set hopeShiftPatternType(hopeShiftPatternType: number) {
    this.rawData.hopeShiftPatternType = hopeShiftPatternType;
  }

  get fixStatus(): number {
    return this.rawData.fixStatus;
  }

  set fixStatus(fixStatus: number) {
    this.rawData.fixStatus = fixStatus;
  }
}
