/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsx jsx */
import React from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import ReactTooltip from "react-tooltip";

export interface TooltipProps {
  id: string;
  text: string;
  customCss?: SerializedStyles;
  place?: ReactTooltip.Place;
  width?: string;
}

/**
 * ツールチップのついたアイコン
 */
const TextTooltip: React.FC<TooltipProps> = ({
  id,
  text,
  customCss,
  place = "top",
  width = 'calc(100% - 10%)'
}) => {
  return (
    <div className="text-tooltip" css={customCss}>
      <div
        data-tip={false}
        data-for={id}
        css={css({
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          width: width,
          overflow: "hidden"
        })}>
        {text}
      </div>
      <ReactTooltip id={id} type="dark" place={place}>
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </ReactTooltip>
    </div>
  );
};

export default TextTooltip;
