import { TCompanyBankList } from '../../type/regist';
import actionTypes from './actionType';
import { Actions } from './action';

// const bankData: TCompanyBank[] = [];

export const initialState: TCompanyBankList = {
  companyBankListHashMap: {
    0: {
      bankCode: '',
      bankIndex: 0,
      allDateChangeStatus: true,
      bankData: [],
    },
  },
};
export const reducer = (state: TCompanyBankList, action: Actions) => {
  const { type, response } = action;
  const cloneData: TCompanyBankList = JSON.parse(JSON.stringify({ ...response }));
  switch (type) {
    // Fetch data from API action
    case actionTypes.FETCH:
      // cloneData.generalData = {
      //   // checkExist: response.checkExist,
      //   // listAdvertising: response.listAdvertising,
      //   // listAdvertisingApplyPeriod: response.listAdvertisingApplyPeriod,
      //   // listAdvertisingExpense: response.listAdvertisingExpense,
      //   // listExpenses: response.listExpenses,
      //   // salesBudgetMonthly: response.salesBudgetMonthly
      // };
      return cloneData;
    default:
      throw new Error();
  }
};
