import moment from 'moment';

export type DashboardShift = {
  staffCode: string
  staffName: string
  orgCode: string
  belongOrgCode: string
  belongOrgName: string
  shiftStartTime: Date
  shiftEndTime: Date
  startTime: Date
  endTime: Date
  staffStatus: number
}
export enum StaffStatusLabel {
  '左記以外' = 0,
  '未出勤' = 1,
  '出勤予定' = 2,
  '出勤中' = 3,
  '退勤済' = 4,
}

export class DashboardShiftDomain {
  constructor(private rawData: DashboardShift) {
    // do nothing
  }

  get shiftStartTime(): Date {
    return this.rawData.shiftStartTime;
  }

  set shiftStartTime(shiftStartTime: Date) {
    this.rawData.shiftStartTime = shiftStartTime;
  }

  get shiftEndTime(): Date {
    return this.rawData.shiftEndTime;
  }

  set shiftEndTime(shiftEndTime: Date) {
    this.rawData.shiftEndTime = shiftEndTime;
  }

  get shiftStartTimeToEndTimeStr(): string {
    if (this.rawData.shiftStartTime && this.rawData.shiftEndTime) {
      return `${moment(this.rawData.shiftStartTime).format('HH:mm')}~${moment(this.rawData.shiftEndTime).format('HH:mm')}`;
    } if (this.rawData.shiftStartTime && !this.rawData.shiftEndTime) {
      return `${moment(this.rawData.shiftStartTime).format('HH:mm')}~`;
    }
    return '';
  }

  get startTime(): Date {
    return this.rawData.startTime;
  }

  set startTime(startTime: Date) {
    this.rawData.startTime = startTime;
  }

  get endTime(): Date {
    return this.rawData.endTime;
  }

  set endTime(endTime: Date) {
    this.rawData.endTime = endTime;
  }

  get startTimeToEndTimeStr(): string {
    return `${moment(this.rawData.startTime).format('HH:mm')}~${moment(this.rawData.endTime).format('HH:mm')}`;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName(): string {
    return this.rawData.staffName;
  }

  set staffName(staffName: string) {
    this.rawData.staffName = staffName;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get belongOrgCode(): string {
    return this.rawData.belongOrgCode;
  }

  set belongOrgCode(belongOrgCode: string) {
    this.rawData.belongOrgCode = belongOrgCode;
  }

  get belongOrgName(): string {
    return this.rawData.belongOrgName;
  }

  set belongOrgName(belongOrgName: string) {
    this.rawData.belongOrgName = belongOrgName;
  }

  get staffStatusStr(): string {
    return StaffStatusLabel[this.rawData.staffStatus];
  }

  get staffStatus(): number {
    return this.rawData.staffStatus;
  }

  set staffStatus(staffStatus: number) {
    this.rawData.staffStatus = staffStatus;
  }

  get note(): string {
    if (this.orgCode !== this.belongOrgCode && this.belongOrgCode) {
      return `${this.belongOrgName}店からのヘルプ`;
    }
    return '';
  }
}
