import React from 'react';

import Calendar from 'components/molecules/Calendar';
import MonthEvent from 'components/organismos/ShiftMonthCalendar/MonthEvent';
import moment from 'moment';
import { grayScale } from 'components/styles';
import MonthDateHeader from './MonthDateHeader';
import MonthEventWrapper from './MonthEventWrapper';

import {
  shiftMonthCalendarContext,
  useMonthCalendarEvents,
  useInitialShiftMonthCalendarContext,
} from './hooks';

const ShiftMonthCalendar: React.FC<{
  date: Date;
  setDate: (date: Date) => void;
  setView: (view: 'month' | 'day') => void;
  orgCode: string;
  shiftConfirmState: { [key: string]: boolean };
}> = ({
  date, setDate, setView, orgCode, shiftConfirmState,
}) => {
    const context = useInitialShiftMonthCalendarContext();
    const { events, setEvents } = context;
    useMonthCalendarEvents(date, orgCode, shiftConfirmState, setEvents);
    let isDefault = true;
    if (orgCode === '' && events !== undefined && events.length > 0) {
      isDefault = true;
      events.forEach((evt) => {
        if (evt.title !== '0名') {
          isDefault = false;
        }
      });
      if (
        !moment(date)
          .startOf('month')
          .isSame(events[0].start)
        && isDefault === true
      ) {
        isDefault = false;
      }
    } else if (orgCode === '' && (events === undefined || events.length === 0)) {
      isDefault = false;
    }
    if (!isDefault) {
      const startDate = moment(date).startOf('month');
      const endDate = moment(date).endOf('month');
      const tempList = [];
      while (!startDate.isAfter(endDate)) {
        tempList.push({
          allDay: true,
          title: '0名',
          isAlert: false,
          isShiftConfirm: false,
          color:
            moment().diff(moment(startDate), 'day') > 0 ? grayScale.gray20 : '',
          start: moment(startDate).toDate(),
          end: moment(startDate).toDate(),
        });
        startDate.add(1, 'day');
      }
      setEvents(tempList);
    }
    return (
      <shiftMonthCalendarContext.Provider value={context}>
        <Calendar
          date={date}
          setDate={setDate}
          events={events}
          views={['month']}
          onDrillDown={(selectDate) => {
            setDate(selectDate);
            setView('day');
          }}
          components={{
            month: {
              event: MonthEvent,
              // Propsをそのまま渡すため
              // eslint-disable-next-line react/jsx-props-no-spreading
              dateHeader: MonthDateHeader,
            },
            // Toolbarは独自で作成するため潰す
            toolbar: () => <></>,
            eventWrapper: MonthEventWrapper,
          }}
        />
      </shiftMonthCalendarContext.Provider>
    );
  };

export default ShiftMonthCalendar;
