import { useFormik } from 'formik';
import { useState, useCallback } from 'react';
import { importFileCSV, Error } from 'api/salesSuppliersMST';
import useToastNotification from 'hooks/useToastNotification';

export const useSalesSuppliersImport = () => {
  const [uploadFile, setUploadFile] = useState<File>();
  const [errors, setErrors] = useState<Array<string>>();
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [inputValue, setValueInput] = useState('');
  const { successNotification, errorNotification } = useToastNotification();
  const [blocking, setBlocking] = useState(false);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const extensionFile = (name: string) => {
    return name.split('.').pop()?.toLowerCase();
  };

  if (uploadFile) {
    const fileType = extensionFile(uploadFile.name);
    if (fileType !== 'csv') {
      errorNotification('CSVファイルを選択してください。');
      setUploadFile(undefined);
      setValueInput('');
    }
  }

  const onSubmit = useCallback(() => {
    setConfirmModalOpen(false);
    if (!uploadFile) {
      return;
    }
    setErrors(undefined);
    setBlocking(true);

    importFileCSV(uploadFile)
      .then((response: any) => {
        setBlocking(false);
        successNotification('インポートが完了しました。');
      }).catch((error: any) => {
        setBlocking(false);
        if (error.response && error.response.data && error.response.data.errors) {
          setErrors((error.response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile]);

  const formik = useFormik({
    initialValues: {
      formatType: 0,
    },
    onSubmit,
  });


  const handleSubmit = useCallback(() => {
    setConfirmModalOpen(true);
  }, []);

  return {
    formik,
    setUploadFile,
    uploadFile,
    onSubmit,
    errors,
    toastModalOpen,
    setToastModalOpen,
    confirmModalOpen,
    closeConfirmModal,
    inputValue,
    handleSubmit,
    blocking,
  };
};
export default useSalesSuppliersImport;
