import React from 'react';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import HourInput from 'components/atoms/Form/HourInput';
import FormLabel from 'components/atoms/Form/FormLabel';
import TimeInput from 'components/atoms/Form/TimeInput';
import Checkbox from 'components/molecules/Checkbox';
import BodyText from 'components/atoms/BodyText';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import { css } from '@emotion/core';

const DetailPaidHalfDayForm: React.FC<{
  paidCalculationStandard: boolean;
  setPaidCalculationStandard: (paidCalculationStandard: boolean) => void;
  // isWorkTime: number;
  // setIsWorkTime: (isWorkTime: number) => void;
  unUsableOfTrialPeriod: boolean;
  setUnUsableOfTrialPeriod: (unUsableOfTrialPeriod: boolean) => void;
  deemedWorkingHours: number,
  setDeemedWorkingHours: (deemedWorkingHours: number) => void
  deemedWorkingMinutes: number,
  setDeemedWorkingMinutes: (deemedWorkingMinutes: number) => void
  paidHalfHolidayStartTime: string | null,
  setPaidHalfHolidayStartTime: (paidHalfHolidayStartTime: string) => void
  paidHalfHolidayEndTime: string | null,
  setPaidHalfHolidayEndTime: (paidHalfHolidayEndTime: string) => void
  isPaidHalfHolidayStartTimeNextDay: boolean,
  setIsPaidHalfHolidayStartTimeNextDay: (isPaidHalfHolidayStartTimeNextDay: boolean) => void
  isPaidHalfHolidayEndTimeNextDay: boolean,
  setIsPaidHalfHolidayEndTimeNextDay: (isPaidHalfHolidayEndTimeNextDay: boolean) => void
}> = ({
  paidCalculationStandard, setPaidCalculationStandard,
  // isWorkTime, setIsWorkTime,
  unUsableOfTrialPeriod, setUnUsableOfTrialPeriod,
  deemedWorkingHours, setDeemedWorkingHours,
  deemedWorkingMinutes, setDeemedWorkingMinutes,
  paidHalfHolidayStartTime, setPaidHalfHolidayStartTime,
  paidHalfHolidayEndTime, setPaidHalfHolidayEndTime,
  isPaidHalfHolidayStartTimeNextDay, setIsPaidHalfHolidayStartTimeNextDay,
  isPaidHalfHolidayEndTimeNextDay, setIsPaidHalfHolidayEndTimeNextDay,
}) => (
  <FormContents isSubForm={true}>
    <FormTitle
      title="有給詳細設定"
      note=""
    />
    {/* <FormField>
      <RadioSelectForm
        label="有給算出基準：勤務日数への加算"
        subLabel=""
        items={[
          {
            label: '加算しない',
            value: '0',
          },
          {
            label: '加算する',
            value: '1',
          },
        ]}
        name="xx"
        value={paidCalculationStandard ? '1' : '0'}
        setValue={(e) => {
          setPaidCalculationStandard(e.target.value !== '0');
        }}
      />
    </FormField> */}

    <FormContents isSubForm={true}>
      <FormLabel label="有給時みなし勤務時間" />
      <FormField>
        <FlexBox>
          <HourInput
            name="hour"
            value={deemedWorkingHours}
            label="時間"
            onChange={(e) => {
              setDeemedWorkingHours(Number(e.target.value));
            }}
            min={0}
            max={23}
          />
          <HourInput
            name="minute"
            value={deemedWorkingMinutes}
            label="分"
            onChange={(e) => {
              setDeemedWorkingMinutes(Number(e.target.value));
            }}
            min={0}
            max={59}
          />
        </FlexBox>
      </FormField>

    </FormContents>

    <FormField>
      <RadioSelectForm
        label="試用期間中の有給使用可否"
        subLabel=""
        items={[
          {
            label: '使用不可',
            value: '1',
          },
          {
            label: '使用可',
            value: '0',
          },
        ]}
        name="unUsableOfTrialPeriod"
        value={unUsableOfTrialPeriod ? '1' : '0'}
        setValue={(e) => { setUnUsableOfTrialPeriod(e.target.value !== '0'); }}
      />
    </FormField>

    <FormField>
      <VerticalLabelFormLayout
        label={(
          <FormLabel
            label="半休とする時間"
          />
                )}
        input={(
          <FlexBox>
            <TimeInput
              name="startTime"
              value={paidHalfHolidayStartTime || '00:00'}
              onChange={() => {}}
              setValue={(val: string) => setPaidHalfHolidayStartTime(val)}
            />
            <Checkbox
              id="isPaidHalfHolidayStartTimeNextDay"
              name="isPaidHalfHolidayStartTimeNextDay"
              label="翌日"
              value={String(isPaidHalfHolidayStartTimeNextDay)}
              checked={isPaidHalfHolidayStartTimeNextDay}
              onChange={(e) => {
                setIsPaidHalfHolidayStartTimeNextDay(e.target.value === 'false');
              }}
            />
            <BodyText customStyle={css({ marginRight: '10px', marginLeft: '10px' })}>〜</BodyText>

            <TimeInput
              name="endTime"
              value={paidHalfHolidayEndTime || '00:00'}
              onChange={() => {}}
              setValue={(val: string) => setPaidHalfHolidayEndTime(val)}
            />
            <Checkbox
              id="isPaidHalfHolidayEndTimeNextDay"
              name="isPaidHalfHolidayEndTimeNextDay"
              label="翌日"
              value={String(isPaidHalfHolidayEndTimeNextDay)}
              checked={isPaidHalfHolidayEndTimeNextDay}
              onChange={(e) => {
                setIsPaidHalfHolidayEndTimeNextDay(e.target.value === 'false');
              }}
            />
          </FlexBox>
                )}
      />
    </FormField>

  </FormContents>
);

export default DetailPaidHalfDayForm;
