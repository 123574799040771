import moment from 'moment';
import { useState, useEffect } from 'react';
import { getOrganizationCategoryOptions, getSearchOrganizationCategory } from 'api/organizationCategory';
import { OptionType } from 'components/atoms/Select';
import { OrgStatusType, Values } from 'utility/constants';
import { MaxDate, MinDate } from 'utility/dateUtil';

/**
 * Get advertising media options
 */
export const useOrganizationCategoryOptions = (
  functionType: number,
  isMultiple: boolean,
  staffCode?: string,
  isOptional?: boolean,
): Array<any> => {
  const [organizationCategorysOptions, setOrganizationCategorysOptions] = useState<Array<any>>([]);
  useEffect(() => {
    const getOptionFunction = getOrganizationCategoryOptions(functionType, staffCode);
    getOptionFunction.then((organizationCategorys) => {
      const list = [] as any;
      organizationCategorys.map((organizationCategory: any) => {
        const options = [] as any;
        if (isOptional === undefined || isOptional === true) {
          options.push({
            value: '',
            label: '-',
          });
        }
        organizationCategory.categoryLt.forEach((category: any) => {
          options.push({
            value: category.organizationCategoryMstDetailId,
            label: category.detailName,
          });
        });
        list.push({
          groupId: organizationCategory.organizationCategoryMstHeadId,
          group: organizationCategory.categoryDispName,
          options,
        });
      });
      setOrganizationCategorysOptions(list);
    });
  }, [functionType, isMultiple, isOptional, staffCode]);
  return organizationCategorysOptions;
};

export const useSearchCategoryOption = (functionType: number, categoryAry: Array<Array<string>>, staffCode?: string, targetDayFrom?: string, targetDayTo?: string, categoryAddAllItem?: boolean, orgStatus?: OrgStatusType) => {
  const [categoryOptionsList, setCategoryOptionsList] = useState<Array<any>>([]);
  useEffect(() => {

    let dateFrom = targetDayFrom || undefined;
    let dateTo = targetDayTo || undefined;

    if(orgStatus === Values.OrgStatus.Closed.value) {
      dateFrom = MinDate;
      dateTo = moment().format('YYYY-MM-DD');
    }

    if(orgStatus === Values.OrgStatus.Scheduled.value) {
      dateFrom = moment().format('YYYY-MM-DD');
      dateTo = MaxDate;
    }

    const getOptionFunction = getSearchOrganizationCategory(functionType, categoryAry, staffCode, dateFrom, dateTo, categoryAddAllItem || false);
    getOptionFunction.then((data) => {
      const options = data.map((option: any) => {
        const optionList: Array<OptionType> = option.categoryLt.map((category: any) => ({
          value: category.organizationCategoryMstDetailId,
          label: `${category.detailName}`,
        }));
        return {
          groupId: option.organizationCategoryMstHeadId,
          group: option.categoryDispName,
          options: optionList,
        };
      });
      setCategoryOptionsList(options);
    });
  }, [categoryAddAllItem, categoryAry, functionType, staffCode, targetDayFrom, targetDayTo, orgStatus]);
  return categoryOptionsList;
};
export default { useOrganizationCategoryOptions, useSearchCategoryOption };
