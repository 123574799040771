import { DashboardShift } from 'domain/master/general/dashboardShift';
import moment from 'moment';
import { DashboardCard } from 'domain/master/general/dashboardCard';
import ApiClient from './ApiClient';

export type GraphMonthlyResponse = {
  graph: {
    yaxisScaleCurrency: {
      minimum: number;
      maximum: number;
      tickAmount: number;
    };
    yaxisScaleRatio: {
      minimum: number;
      maximum: number;
      tickAmount: number;
    };
  };
  data: {
    budget: number;
    date: number;
    foodCostRate: number;
    laborCostRate: number;
    netSales: number;
    operatingIncome: number;
  }[];
};

export type GraphYearlyResponse = {
  fiscalYear: number;
  graph: {
    yaxisScaleCurrency: {
      minimum: number;
      maximum: number;
      tickAmount: number;
    };
    yaxisScaleRatio: {
      minimum: number;
      maximum: number;
      tickAmount: number;
    };
  };
  data: {
    budget: number;
    month: number;
    foodCostRate: number;
    laborCostRate: number;
    netSales: number;
    operatingIncome: number;
  }[];
};

export const getDashboardShiftList = async (
  orgCode: string,
): Promise<Array<DashboardShift>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
  };
  const response = await ApiClient.get(
    `/v2/dashboard/shiftOnTheDay/${companyCode}`,
    params,
  );
  return response.data;
};

export const getSalesAmountOnTheMonth = async (
  orgCode: string,
  targetDate: Date,
  categoryAry: any,
): Promise<DashboardCard> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');
  const params = {
    orgCode,
    staffCode,
    targetDay: targetDateStr,
    functionType: 4,
  };

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }

  const response = await ApiClient.get(
    `/v3/dashboard/card/salesAmountOnTheMonth/${companyCode}?${appendUrl}`,
    params,
  );
  return response.data;
};

export const getSalesAmountOnTheDay = async (
  orgCode: string,
  targetDate: Date,
  categoryAry: any,
): Promise<DashboardCard> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }

  const params = {
    orgCode,
    staffCode,
    targetDay: targetDateStr,
    functionType: 4,
  };
  const response = await ApiClient.get(
    `/v3/dashboard/card/salesAmountOnTheDay/${companyCode}?${appendUrl}`,
    params,
  );
  return response.data;
};

export const getNumberOfCustomersUpToBreakEvenPoint = async (
  orgCode: string,
  targetDate: Date,
  categoryAry: any,
): Promise<DashboardCard> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    staffCode,
    targetDay: targetDateStr,
    functionType: 4,
  };
  const response = await ApiClient.get(
    `/v3/dashboard/card/numberOfCustomersUpToBreakEvenPoint/${companyCode}?${appendUrl}`,
    params,
  );
  return response.data;
};

export const getNumberOfCustomersToReachBudgetMonth = async (
  orgCode: string,
  targetDate: Date,
  categoryAry: any,
): Promise<DashboardCard> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    staffCode,
    targetDay: targetDateStr,
    functionType: 4,
  };
  const response = await ApiClient.get(
    `/v3/dashboard/card/numberOfCustomersToReachBudgetMonth/${companyCode}?${appendUrl}`,
    params,
  );
  return response.data;
};

export const getMonthlyBudgetProgressRate = async (
  orgCode: string,
  targetDate: Date,
  categoryAry: any,
): Promise<DashboardCard> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    staffCode,
    targetDay: targetDateStr,
    functionType: 4,
  };
  const response = await ApiClient.get(
    `/v3/dashboard/card/monthlyBudgetProgressRate/${companyCode}?${appendUrl}`,
    params,
  );
  return response.data;
};

// DIFF
export const getMonthlyBudgetProgressRank = async (
  orgCode: string,
  targetDate: Date,
  categoryAry: any,
): Promise<DashboardCard> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    staffCode,
    targetDay: targetDateStr,
    functionType: 4,
  };
  const response = await ApiClient.get(
    `/v3/dashboard/card/monthlyBudgetProgressRank/${companyCode}?${appendUrl}`,
    params,
  );
  return response.data;
};

export const getFLRate = async (
  orgCode: string,
  targetDate: Date,
  categoryAry: any,
): Promise<DashboardCard> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    staffCode,
    targetDay: targetDateStr,
    functionType: 4,
  };
  const response = await ApiClient.get(
    `/v3/dashboard/card/flRate/${companyCode}?${appendUrl}`,
    params,
  );
  return response.data;
};

export const getVoidRate = async (
  orgCode: string,
  targetDate: Date,
  categoryAry: any,
): Promise<DashboardCard> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    staffCode,
    targetDay: targetDateStr,
    functionType: 4,
  };
  const response = await ApiClient.get(
    `/v3/dashboard/card/voidRate/${companyCode}?${appendUrl}`,
    params,
  );
  return response.data;
};

export const getfRate = async (
  orgCode: string,
  targetDate: Date,
  categoryAry: any,
): Promise<DashboardCard> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    staffCode,
    targetDay: targetDateStr,
    functionType: 4,
  };
  const response = await ApiClient.get(
    `/v4/dashboard/card/fRate/${companyCode}?${appendUrl}`,
    params,
  );
  return response.data;
};

export const getlRate = async (
  orgCode: string,
  targetDate: Date,
  categoryAry: any,
): Promise<DashboardCard> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    staffCode,
    targetDay: targetDateStr,
    functionType: 4,
  };
  const response = await ApiClient.get(
    `/v3/dashboard/card/lRate/${companyCode}?${appendUrl}`,
    params,
  );
  return response.data;
};
export const getpRate = async (
  orgCode: string,
  targetDate: Date,
  categoryAry: any,
): Promise<DashboardCard> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    staffCode,
    targetDay: targetDateStr,
    functionType: 4,
  };
  const response = await ApiClient.get(
    `/v3/dashboard/card/pRate/${companyCode}?${appendUrl}`,
    params,
  );
  return response.data;
};
export const getDashboardRequestTable = async (
  orgCode: string,
  categoryAry: any,
): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode');
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    functionType: 4,
    orgCode,
    staffCode,
  };
  const response = await ApiClient.get(
    `/v1/dashboard/reportStatus/${companyCode}?${appendUrl}`,
    params,
  );
  return response.data;
};
export const getSPDashboardFirstView = async (params: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(
    `/v2/sp/dashboard/firstView/${companyCode}`,
    params,
  );
  return response.data;
};

export const getSPDashboardCategoryView = async (params: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(
    `/v2/sp/dashboard/categoryView/${companyCode}`,
    params,
  );
  return response.data;
};

export const getSPDashboardCategoryDetailView = async (
  params: any,
): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(
    `/v2/sp/dashboard/categoryDetailView/${companyCode}`,
    params,
  );
  return response.data;
};

export const getSPDashboardOrgDetailView = async (
  params: any,
): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(
    `/v2/sp/dashboard/orgDetailView/${companyCode}`,
    params,
  );
  return response.data;
};

export const getGraphMonthly = async (
  orgCode: string,
  targetDate: Date,
  categoryAry: any,
): Promise<GraphMonthlyResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');
  let appendUrl = '';

  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    companyCode,
    orgCode,
    loginStaffCode: staffCode,
    targetDay: targetDateStr,
    functionType: 4,
    categoryArr: appendUrl,
  };

  const response = await ApiClient.get(
    `/v2/dashboard/graph/sales/monthly/${companyCode}`,
    params,
  );

  return response.data;
};

export const getGraphYearly = async (
  orgCode: string,
  targetDate: Date,
  categoryAry: any,
): Promise<GraphYearlyResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');
  let appendUrl = '';

  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    companyCode,
    orgCode,
    loginStaffCode: staffCode,
    targetDay: targetDateStr,
    functionType: 4,
    categoryArr: appendUrl,
  };

  const response = await ApiClient.get(
    `/v2/dashboard/graph/sales/yearly/${companyCode}`,
    params,
  );

  return response.data;
};

export default getDashboardShiftList;
