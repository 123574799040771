
import moment from 'moment'; 
import { useState, useEffect, useCallback } from 'react';
import { apiGetDaySeparateSalesManagementCos } from 'api/monthly/daySeparateSalesManagementCos';
import * as api from 'api/monthlyReport';
import { useParams } from 'react-router-dom';

const useDailySalesDate = () => {
  const functionType = 3;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = useParams<any>();

  const [orgCode, setOrgCode] = useState(params && params.orgCode ? params.orgCode : sessionStorage.getItem('loginUser.orgCode'));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);
  const dateSelectFrom = moment().startOf('month').format('YYYY-MM-DD');
  const [selectedDateFrom, setSelectedDateFrom] = useState<Date>(params && params.targetDateFrom ? moment( params.targetDateFrom, 'YYYY-MM-DD').toDate() : new Date(dateSelectFrom));
  const dateSelectTo =  moment().format('DD') !== '01' ? moment().add(-1, 'days').format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD');
  const [selectedDateTo, setSelectedDateTo] = useState<Date>(params && params.targetDateTo ? moment( params.targetDateTo, 'YYYY-MM-DD').toDate() : new Date(dateSelectTo));

  const dummyData: any = [
  ];

  const [listDailySalesDate, setListDailySalesDate] = useState(dummyData);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const path = 'daySeparateSalesManagementCos';
    try {
      const response = await apiGetDaySeparateSalesManagementCos(path, String(orgCode), moment(selectedDateFrom.toString()).format('YYYY/MM/DD'), moment(selectedDateTo.toString()).format('YYYY/MM/DD'), categoryAry);
      setListDailySalesDate(response);
    } catch (error) {
      setListDailySalesDate([]);
    }
    setLoading(false);
  }, [orgCode, selectedDateFrom, selectedDateTo, categoryAry]); 

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    isLoading,
    listDailySalesDate,
    functionType,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo
  };
};

export const useDownload = () => {
  const downloadDaySeparateSalesManagementCosCsv = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '【日別】売上管理表.csv');
  };

  const downloadDaySeparateSalesManagementCosExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '【日別】売上管理表.xlsx');
  };

  return {
    downloadDaySeparateSalesManagementCosCsv,
    downloadDaySeparateSalesManagementCosExcel,
  };
};

export default useDailySalesDate;
