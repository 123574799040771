import React from 'react'
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AttendApplicationApproval from 'components/organismos/master/attend/AttendApplicationApproval';

const AttendApplicationApprovalPage = () => {
  return(
    <SidebarTemplate pageTitle="勤怠承認">
      <AttendApplicationApproval/>
    </SidebarTemplate>
  )
}

export default AttendApplicationApprovalPage