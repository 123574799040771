import { useState, useEffect } from 'react';
import { getList } from 'api/executive';

export const useExecutiveOptions = (): Array<any> => {
  const [executiveOptions, setExecutiveOptions] = useState<Array<any>>([]);
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  useEffect(() => {
    getList(companyCode).then((response: Array<any>) => {
      const executiveO = response.map((item) => ({
        value: item.executiveId,
        label: `${item.executiveCode} ${item.executiveName}`,
      }));
      executiveO.unshift({
        value: '',
        label: '-',
      });
      setExecutiveOptions(executiveO);
    });
  }, [companyCode]);
  return executiveOptions;
};

export default useExecutiveOptions;
