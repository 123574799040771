/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

function Year({ year, selectedYear, choiseYear }) {
  const selectedYearClass = year === selectedYear ? 'selected-year' : '';

  function clickHandler(event) {
    choiseYear(year);
  }

  return (
    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div className="year-wrapper" year={year}>
      <a onClick={(e) => clickHandler(e)} className={`year ${selectedYearClass}`}>
        {year}
      </a>
    </div>
  );
}

export default Year;
