/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import BodyText from 'components/atoms/BodyText';
import { utilityColor } from 'components/styles';
import { Link } from 'react-router-dom';
import Icon, { IconType } from 'components/atoms/Icon';
import IconStories from 'stories/2-Icon.stories';

const BottomBar = () => {
  const barStyle = {
    wrap: css({
      backgroundColor: utilityColor.white,
      padding: '0 0.5rem',
      position: 'relative',
      marginBottom: '50px', // action bar
    }),
    fixedBottomBar: css({
      position: 'fixed',
      bottom: 0,
      width: '100%',
      height: '45px',
      background: '#0D4796',
      margin: '0 -0.5rem',
      zIndex: 100,
    }),
    item: css({
      display: 'flex',
      flexDirection: 'column',
      textDecoration: 'none',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    fixedBottomBarButtons: css`
      height: 100%;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        &:first-of-type{
          border-right: 1px solid #fff;
        }
        :nth-of-type(2){
          border-right: 1px solid #fff;
        }
        :nth-of-type(3){
          border-right: 1px solid #fff;
        }
      },
    `,
  };
  const mobileDashboard = sessionStorage.getItem('mobileDashboard');
  const mobileSchedule = sessionStorage.getItem('mobileSchedule');
  const mobileWorkAchievement = sessionStorage.getItem('mobileWorkAchievement');

  return (
    <div css={barStyle.wrap}>
      <div css={barStyle.fixedBottomBar}>
        <FlexBox customStyle={barStyle.fixedBottomBarButtons}>
          {
            mobileDashboard === '1' && (
              <div>
                <Link css={barStyle.item} to="/dashboard">
                  <Icon type="analize" color="#fff"/>
                  <BodyText color="#fff" size="xs">ダッシュボード</BodyText>
                </Link>
              </div>
            )
          }
          {
            mobileWorkAchievement === '1' && (
              <div>
                <Link css={barStyle.item} to="/workAchievement">
                  <Icon type="workSchedule" color="#fff"/>
                  <BodyText color="#fff" size="xs">勤務状況</BodyText>
                </Link>
              </div>
            )
          }
          {
            mobileSchedule === '1' && (
              <div>
                <Link css={barStyle.item} to="/schedule">
                  <Icon type="calendar" color="#fff"/>
                  <BodyText color="#fff" size="xs">マイシフト</BodyText>
                </Link>
              </div>
            )
          }
          <div>
            <Link css={barStyle.item} to="/personalSetting">
              <Icon type="employ" color="#fff"/>
              <BodyText color="#fff" size="xs">マイページ</BodyText>
            </Link>
          </div>
        </FlexBox>
      </div>
    </div>
  );
};
export default BottomBar;
