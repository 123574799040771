import moment from 'moment';

/**
 * API PriceWithrowalDomain
 */
export default class PriceWithrowalDomain {
  constructor(private rawData: Array<any>) {
    // do nothing
  }

  static generateInitial(): PriceWithrowalDomain {
    return new PriceWithrowalDomain(['', '', '', '', '', '', new Date(), new Date('9999-12-31')]);
  }

  getRawData(): Array<any> {
    return this.rawData;
  }

  get companyCode(): string {
    return this.rawData[0];
  }

  set companyCode(companyCode: string) {
    this.rawData[0] = companyCode;
  }

  get discountTypeMstCode(): string {
    return this.rawData[1];
  }

  set discountTypeMstCode(discountTypeMstCode:string) {
    this.rawData[1] = discountTypeMstCode;
  }

  get discountTypeMstName(): string {
    return this.rawData[2];
  }

  set discountTypeMstName(discountTypeMstName: string) {
    this.rawData[2] = discountTypeMstName;
  }

  get posType(): string {
    return this.rawData[3];
  }

  set posType(posType:string) {
    this.rawData[3] = posType;
  }

  get posName(): string {
    return this.rawData[4];
  }

  set posName(posName:string) {
    this.rawData[4] = posName;
  }

  get discountTypeCode(): string {
    return this.rawData[5];
  }

  set discountTypeCode(discountTypeCode:string) {
    this.rawData[5] = discountTypeCode;
  }

  get applicableStartMonth(): Date {
    return moment(this.rawData[6]).toDate();
  }

  set applicableStartMonth(applicableStartMonth: Date) {
    this.rawData[6] = applicableStartMonth;
  }

  get applicableStartMonthStr(): string {
    return moment(this.rawData[6]).format('YYYY年MM月');
  }

  get applicableEndMonth(): Date {
    if (this.rawData[7] === '9999-12-31') {
      return new Date('9999-12-31');
    }
    return moment(this.rawData[7]).toDate();
  }

  get applicableEndMonthStr(): string {
    if (this.rawData[7] === '9999-12-31') {
      return '-';
    }
    return moment(this.rawData[7]).format('YYYY年MM月');
  }

  set applicableEndMonth(applicableEndMonth: Date) {
    this.rawData[7] = applicableEndMonth;
  }
  
  get applicableStartMonthFormat(): string {
    return moment(this.rawData[6]).format('YYYY-MM');
  }
}
