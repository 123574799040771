/** @jsx jsx */
import React, {
  ReactElement, useState, useEffect,
} from 'react';
import { css, jsx } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import FormContents from 'components/atoms/Form/FormContents';
import BodyText from 'components/atoms/BodyText';
// import useWorkingStatusData, { SelectType } from './hook';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import DatePickerForm from 'components/molecules/DatePickerForm';
import moment from 'moment';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { Row, Col } from 'react-grid-system';
import PrimaryButton, { SecondaryButton } from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import Checkbox from 'components/molecules/Checkbox';
import OtherBankLayout from './components/otherBank/OtherBankLayout';
import SpecificBankLayout from './components/specificBank/SpecificBankLayout';
import { useBankDetailProvider } from '../context/BankRegistDetails';
import { TCompanyBank } from '../type/regist';


const styles = {
  tableWrapper: css({
    marginTop: 9,
    overflow: 'auto',
    maxHeight:'calc(100vh - 280px)',
    height:'calc(100vh - 280px)',
  
  }),
  marginTitle: css({
    marginRight: 15,
  }),
  flexCenterRadio: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: '-8px',
  }),
  radioSpace: css({
    marginLeft: 0,
    margin: "16px 0px"
  }),
  headerWrapper: css({
    width: '100%',
    height: '100%',
    overflow: 'auto',
  }),
  headerSpecificWrapperContent: css({
    width: 1325,
  }),
  headerOtherWrapperContent: css({
    width:"100%",
  }),
  CheckboxPadding: css({
    paddingTop: 12.5,
  }),
};
const BankRegistLayout: React.FC = (): ReactElement => {
  const {
    depositDate,
    handleChangeDynamicDate,
    stateReducer,
    bankList,
    handleAddRow,
    currentBank,
    setCurrentBank,
    isLoading,
    orgTreeBank,
    otherBankOrgTree,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    confirmModalDelete,
    setConfirmModalDelete,
    closeConfirmModalDelete,
    handleOnRegister,
    handleOnDelete,
    roleScreen,
    handleChangeTrap,
    // stateReducer.companyBankListHashMap,
    dateOption,
    handleChangeDateOption,
    handleDynamicApplyDate,
    handleChangeAllDateStatus,
  } = useBankDetailProvider();

  const [currentBankName, setCurrentBankName] = useState('');
  const [list, setList] = useState<TCompanyBank[]>([]);
  const handleChangeScreenOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentBank(e.target.value);
    bankList.forEach((item: any) => {
      if (item.value === e.target.value) {
        setCurrentBankName(item.label);
      }
    });
  };

  useEffect(() => {
    if (currentBank !== '' && stateReducer.companyBankListHashMap[currentBank]) {
      const newList = stateReducer.companyBankListHashMap[currentBank].bankData;
      setList(newList);
    }
  }, [currentBank, list, stateReducer.companyBankListHashMap]);
  return (
    <BlockUI blocking={isLoading}>
      <FormContents  >
        <FlexBox customStyle={styles.headerWrapper}>
          <FlexBoxItem customStyle={styles.headerOtherWrapperContent}>
            <FlexBox >
              <FlexBoxItem customStyle={styles.marginTitle}><BodyText>入金日</BodyText></FlexBoxItem>
              <FlexBoxItem customStyle={styles.marginTitle}>
                <DatePickerForm
                  label=""
                  date={moment(depositDate).toDate()}
                  dateFormat="yyyy年MM月dd日"
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      const timeStamp = moment(date).valueOf();
                      handleChangeDynamicDate(timeStamp, 'default');
                    }
                  }}
                  isFullWidth={true}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <Row>
                  <Col>
                    <IconLabelButton
                      iconType="chevronLeft"
                      onClick={() => handleChangeDynamicDate(depositDate, 'desc')}
                      padding="6px 4px"
                      margin="0 0 0 4px"
                    />

                  </Col>
                  <Col>
                    <IconLabelButton
                      iconType="chevronRight"
                      onClick={() => handleChangeDynamicDate(depositDate, 'inc')}
                      padding="6px 4px"
                      margin="0 0 0 4px"
                    />

                  </Col>
                </Row>
              </FlexBoxItem>
              {
                roleScreen && roleScreen.editable === 1 && currentBank === '0' && (
                  <FlexBoxItem textAlign="right" marginLeft='26px'>
                    <PrimaryButton
                      onClick={() => handleAddRow()}
                      text="新規追加"
                    />
                  </FlexBoxItem>
                )
              }
              <FlexBoxItem marginLeft="26px" grow={2} textAlign="right">
                <FlexBox>
                  <FlexBoxItem customStyle={styles.marginTitle}><BodyText>営業日一括変更</BodyText></FlexBoxItem>
                  <FlexBoxItem customStyle={styles.marginTitle}>
                    <DatePickerForm
                      label=""
                      date={moment(dateOption.applyDate).toDate()}
                      dateFormat="yyyy年MM月dd日"
                      changeDate={(date: Date) => {
                        if (date !== null) {
                          const timeStamp = moment(date).valueOf();
                          handleChangeDateOption(date);
                        }
                      }}
                      isFullWidth={true}
                    />
                  </FlexBoxItem>
                  <FlexBoxItem customStyle={styles.marginTitle}>
                    <FlexBox customStyle={{ ...styles.CheckboxPadding }}>
                      <FlexBoxItem>
                        <Checkbox
                          id="startDateStatus"
                          name="startDateStatus"
                          label="開始日"
                          value={String(dateOption.startDateStatus)}
                          checked={Boolean(dateOption.startDateStatus)}
                          onChange={handleChangeDateOption}
                        />
                      </FlexBoxItem>
                      <FlexBoxItem>
                        <Checkbox
                          id="endDateStatus"
                          name="endDateStatus"
                          label="終了日"
                          value={String(dateOption.endDateStatus)}
                          checked={Boolean(dateOption.endDateStatus)}
                          onChange={handleChangeDateOption}
                        />
                      </FlexBoxItem>

                    </FlexBox>
                  </FlexBoxItem>
                  <FlexBoxItem>
                    <PrimaryButton
                      onClick={() => handleDynamicApplyDate(currentBank)}
                      text="変更"
                    />
                  </FlexBoxItem>
                </FlexBox>
              </FlexBoxItem>

             
            </FlexBox>
            <FlexBoxItem customStyle={styles.radioSpace}>
                <FlexBox>
                  <FlexBoxItem customStyle={styles.marginTitle}>
                    <BodyText>銀行</BodyText>
                  </FlexBoxItem>
                  <FlexBoxItem customStyle={styles.flexCenterRadio}>
                    <RadioSelectForm
                      items={bankList}
                      name="screenOptions"
                      value={String(currentBank)}
                      setValue={handleChangeScreenOption}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </FlexBoxItem>
            <FlexBox alignItems="flex-start" customStyle={styles.tableWrapper}>
              <FlexBoxItem grow={1}>
                {
                  stateReducer.companyBankListHashMap['0'] && currentBank === '0' && (
                    <OtherBankLayout
                      currentBank={currentBank}
                      allDateChangeStatus={stateReducer.companyBankListHashMap['0'].allDateChangeStatus}
                      handleChangeAllDateStatus={handleChangeAllDateStatus}
                      renderList={stateReducer.companyBankListHashMap['0'].bankData}
                      orgTreeBank={orgTreeBank}
                      otherBankOrgTree={otherBankOrgTree}
                      handleChangeTrap={handleChangeTrap}
                    />
                  )
                }
                {
                  stateReducer.companyBankListHashMap[currentBank] && currentBank !== '0' && (
                    <SpecificBankLayout
                      currentBank={currentBank}
                      allDateChangeStatus={stateReducer.companyBankListHashMap[currentBank].allDateChangeStatus}
                      handleChangeAllDateStatus={handleChangeAllDateStatus}
                      renderList={stateReducer.companyBankListHashMap[currentBank].bankData}
                      orgTreeBank={orgTreeBank}
                      handleChangeTrap={handleChangeTrap}
                    />
                  )
                }
              </FlexBoxItem>
            </FlexBox>
          </FlexBoxItem>
        </FlexBox>
      </FormContents>

      {roleScreen && roleScreen.editable === 1 && list.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 15px' }}>
          <SecondaryButton
            onClick={() => { setConfirmModalDelete(true); }}
            text="全削除"
            minWidth="100px"
          />
          <PrimaryButton
            onClick={() => { setConfirmModalOpen(true); }}
            text="登録"
            minWidth="100px"
          />
        </div>
      )}
      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        title="確認メッセージ"
        content="登録します。よろしいですか？"
        submitText="登録"
        onSubmit={() => handleOnRegister(list)}
        items={[]}
      />
      <ConfirmModal
        open={confirmModalDelete}
        closeHandler={closeConfirmModalDelete}
        title="確認メッセージ"
        content={`${moment(depositDate).format('YYYY年MM月DD日')} ${(currentBankName || bankList[0]?.label)} の入金データを全削除します。よろしいですか？`}
        submitText="全削除"
        onSubmit={() => handleOnDelete()}
        items={[]}
      />
    </BlockUI>
  );
};
export default BankRegistLayout;
