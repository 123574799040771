import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import WorkingStatusLayout from './workingStatusLayout';
import WorkingStatusMobileLayout from '../../schedule/WorkingStatusMobilePage/WorkingStatusMobileLayout';


const WorkingStatusPage = () => (
  <SidebarTemplate pageTitle="勤務状況">
    {isMobileOnly ? <WorkingStatusMobileLayout />
      : <WorkingStatusLayout />}
  </SidebarTemplate>
);

export default WorkingStatusPage;
