import {
  useState, useCallback, useEffect,
} from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import SpecialSalaryPatternDomain from 'domain/master/labor/specialSalaryPattern';
import { postSpecialSalaryPatternSetting, getSpecialSalaryPattern } from 'api/specialSalaryPattern';
import { getSelOrganize } from 'api/organization';
import useOrganizeOptions from 'hooks/useOrgTreesOptions';
import useToastNotification from 'hooks/useToastNotification';

type SpecialSalaryPatternDomainKey = keyof Pick<SpecialSalaryPatternDomain, 'applyStartMonthStr'>;


export const useSpecialSalaryPatternSettingForm = () => {
  const { specialSalaryPatternId } = useParams();
  const organizeOptions = useOrganizeOptions();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [active, setActive] = useState('selection');
  const { successNotification, errorNotification } = useToastNotification();
  const history = useHistory();

  const onSubmit = async (values: SpecialSalaryPatternDomain) => {
    if (values.applyStartMonthStr === null) {
      errorNotification('適用開始月を入力してください');
      return;
    }
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    try {
      const postData = values.getRawDataSetting();
      postData.applyStartMonthStr = moment(formik.values.applyStartMonthStr).format('YYYY年MM月');
      postData.sameDays = postData.sameDays.map((item: any) => item.value).toString();
      postData.fixedDays = postData.fixedDays.map((item: any) => item.value).toString();
      postData.createUser = sessionStorage.getItem('loginUser.staffName') || '';
      postData.updateUser = sessionStorage.getItem('loginUser.staffName') || '';

      if (active === 'selection') {
        postData.patOrgCodes = postData.patOrgCodes.toString();
      } else {
        postData.patOrgCodes = organizeOptions.map((item: any) => item.value).toString();
      }

      const response = await postSpecialSalaryPatternSetting(postData);
      setConfirmModalOpen(false);
      if (!response.errors) {
        successNotification('更新しました。');
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any, index: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: SpecialSalaryPatternDomain.generateInitial(),
    onSubmit,

  });

  useEffect(() => {
    let totalOrg = 0;
    if (specialSalaryPatternId) {
      getSpecialSalaryPattern(specialSalaryPatternId).then((response: any) => {
        response.sameDays = [];
        response.fixedDays = [];
        response.patOrgCodes = [];
        response.addSalary = '';

        response.sameDayList.forEach((item: any) => {
          response.sameDays.push({
            value: `${item.sameMonth}/${item.sameDay}`,
            label: `${item.sameMonth}月${item.sameDay}日`,
          });
        });

        response.fixedList.forEach((item: any) => {
          response.fixedDays.push({
            value: moment(item.fixedDate).format('YYYY/MM/DD'),
            label: moment(item.fixedDate).format('YYYY年MM月DD日'),
          });
        });

        response.patOrgList.forEach((item: any) => {
          // response.applyStartMonthStr = new Date(item.id.applyStartMonth);
          // response.addSalary = item.addSalary;
          response.patOrgCodes.push(item.id.orgCode);
        });
        totalOrg = response.patOrgList.length;

        formik.setValues(new SpecialSalaryPatternDomain(response));
      });
      getSelOrganize().then((items) => {
        if (items.length === totalOrg) {
          setActive('all');
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialSalaryPatternId]);

  // 登録内容確認モーダル用データ
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const handleBack = async () => {
    sessionStorage.setItem('currentTab', '2');
    history.push('/agreementMaster');
  };

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    active,
    setActive,
    handleBack,
  };
};

export default {
  useSpecialSalaryPatternSettingForm,
};
