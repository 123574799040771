import React, { SVGProps } from 'react';

const Chart: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M15.5 5.375c.483 0 .875.392.875.875v9.5a.882.882 0 01-.009.126l.634-.001a.625.625 0 110 1.25H3a.625.625 0 110-1.25l.634.001a.882.882 0 01-.009-.126v-3.5c0-.483.392-.875.875-.875h2c.483 0 .875.392.875.875v3.5a.882.882 0 01-.009.126h.768a.882.882 0 01-.009-.126v-6.5c0-.483.392-.875.875-.875h2c.483 0 .875.392.875.875v6.5a.882.882 0 01-.009.126h.768a.882.882 0 01-.009-.126v-9.5c0-.483.392-.875.875-.875h2zm-9.375 7.25h-1.25V14h1.25v-1.375zm4.5-3h-1.25V13h1.25V9.625zm4.5-3h-1.25V10h1.25V6.625z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Chart;
