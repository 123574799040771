import TimeListDaTaOutputReportForm from 'components/organismos/master/sales/TimeListDaTaOutputReportForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';

const TimeListDataOutput = () => (
  <SidebarTemplate pageTitle="タイムリストデータ出力">
    <TimeListDaTaOutputReportForm />
  </SidebarTemplate>
);

export default TimeListDataOutput;
