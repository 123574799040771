import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesExpensesMSTForm from 'components/organismos/master/general/salesExpensesMST/salesExpensesMSTForm';

const SalesExpensesMSTAddPage: React.FC = () => (
  <SidebarTemplate pageTitle="その他経費作成">
    <SalesExpensesMSTForm />
  </SidebarTemplate>
);

export default SalesExpensesMSTAddPage;
