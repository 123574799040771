/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import SelectForm from 'components/molecules/SelectForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import HourInput from 'components/atoms/Form/HourInput';
import Checkbox from 'components/molecules/Checkbox';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBox from 'components/atoms/FlexBox';
import BlockUIForLoading from 'components/molecules/BlockUIForLoading';
import { Link, useHistory } from 'react-router-dom';

import { grayScale, productColor } from 'components/styles';
import DataTable from 'components/organismos/DataTable/DataTable';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import { css, jsx } from '@emotion/core';
import Accordion from 'components/molecules/Accordion';
import PaidHolidayStatusDomain from 'domain/master/holidayManagement/paidHolidayStatus';
import { Column } from 'react-table';
import Modal from 'components/molecules/Modal';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import {
  useManagementInformationDomainForm,
  useDownloadHolidayManagement,
} from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
// create style form
const styles = {
  form: css({
    paddingTop: '15px',
  }),
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
    minWidth: '100px',
  }),
  digestionDays: css({
    width: '150px',
  }),
  betweenDays: css({
    width: '50px',
    textAlign: 'center',
  }),
  detail: css({
    backgroundColor: productColor.primary,
    borderRadius: '5px',
    textAlign: 'center',
    marginLeft: '5px',
  }),
  detailButton: css({
    padding: '4px',
    color: 'white',
  }),
  textMessage: css({
    color: 'black',
  }),
};

export interface DataDetail {
  staffCode: string;
  staffName: string;
}

const ManagementInformationForm: React.FC<{
  employmentOptions: any;
}> = ({ employmentOptions }) => {
  const {
    paidHolidayStatusList,
    orgCode,
    setOrgCode,
    employmentId,
    setOrgEmploymentId,
    extinctionDaysStart,
    setExtinctionDaysStart,
    extinctionDaysEnd,
    setExtinctionDaysEnd,
    targetGrantTenDays,
    setTargetGrantTenDays,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    callPaidHolidayStatusDetail,
    callSaveUseItemForPaidHolidayStatus,
  } = useManagementInformationDomainForm();

  const { downloadPaidHolidayStatus } = useDownloadHolidayManagement(
    orgCode,
    extinctionDaysStart,
    extinctionDaysEnd,
    employmentId,
    targetGrantTenDays,
  );

  //
  const [dataDetail, setDataDetail] = useState<DataDetail>({
    staffCode: '',
    staffName: '',
  });

  // handlePaidHolidayStatusDetail
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePaidHolidayStatusDetail = async (data: any) => {
    setDataDetail(data);
    callPaidHolidayStatusDetail(data.staffCode);
    setModalOpen(true);
  };

  // Check prototype role staff
  const history = useHistory();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  const columns: Array<Column<PaidHolidayStatusDomain>> = React.useMemo(
    () => [
      {
        Header: () => 'スタッフコード',
        id: 'staffCode',
        accessor: 'staffCode',
        sortType: 'basic',
        Cell: (cell: {
          row: { isExpanded: boolean; original: PaidHolidayStatusDomain };
          data: Array<PaidHolidayStatusDomain>;
        }) => (
          <FlexBox>
            {cell.row.original.staffCode}
            <div css={css(styles.detail)}>
              {checkUserRole && checkUserRole.editable === 1 && (
              <ClickableIconButton
                onClick={() => handlePaidHolidayStatusDetail(cell.row.original)}
                additionalCss={styles.detailButton}
              >
                編集
              </ClickableIconButton>
              )}
            </div>
          </FlexBox>
        ),
      },
      {
        Header: 'スタッフ名',
        accessor: 'staffName',
      },
      {
        Header: '付与日',
        accessor: 'effectiveStartDateToStr',
      },
      {
        Header: '前年繰越数',
        accessor: 'carriedOverLastYear',
      },
      {
        Header: '当年付与数',
        accessor: 'numberGrantedThisYear',
      },
      {
        Header: '消化数',
        accessor: 'digestionNumber',
      },
      {
        Header: '残有給数',
        accessor: 'remainingPaid',
      },
      {
        Header: '次回付与日',
        accessor: 'nextGrantDate',
      },
      {
        Header: '次回予定付与日数',
        accessor: 'nextGrantDaysToStr',
      },
      {
        Header: '次回繰越日数',
        accessor: 'nextRolloverDays',
      },
      {
        Header: '次回消滅日数',
        accessor: 'nextExpirationDate',
      },
    ],
    [checkUserRole, handlePaidHolidayStatusDetail],
  );

  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const accessLevel = sessionStorage.getItem('loginUser.accessLevel');
  const managementOrg = 1;
  return (
    <BlockUIForLoading tag="div" blocking={false}>
      <Accordion
        text="管理情報"
        color={grayScale.gray100}
        callBack={() => {}}
        defaultExpand={!!((sessionStorage.getItem('attendHolidayManagement.accordion.paid.management') === String(true)))}
        accordionValue="paid.management"
      >
        <div>
          <OrganizationFilteredTree
            functionType={2}
            staffCode={staffCode}
            orgLabel="組織名"
            initOrgValue={String(orgCode)}
            orgCallback={(args: string | Array<string>) => {
              setOrgCode(String(args));
            }}
            addAllItem={false}
            styles={{
              label: {
                toggleSwitch: css({ marginLeft: '0px' }),
              },
              toggle: {
                width: '13rem',
              },
            }}
            orgFilter={managementOrg}
          />

          <SelectForm
            label="雇用形態"
            name="employmentId001"
            value={String(employmentId)}
            setValue={(val: string) => {
              setOrgEmploymentId(val);
              sessionStorage.setItem('management.employmentId', String(val));
            }}
            options={employmentOptions}
          />

          <div css={styles.container}>
            <FlexBox>
              <div css={styles.label}>
                <FormLabel label="消化日数" />
              </div>
              <div css={styles.digestionDays}>
                <HourInput
                  name="extinctionDaysStart"
                  value={
                    extinctionDaysStart === ''
                      ? ''
                      : Number(extinctionDaysStart)
                  }
                  onChange={(e) => {
                    setExtinctionDaysStart(e.target.value);
                    sessionStorage.setItem('management.extinctionDaysStart', String(e.target.value));
                  }}
                  label="日"
                  min={0}
                  max={99}
                />
              </div>
              <div css={styles.betweenDays}>～</div>
              <div css={styles.digestionDays}>
                <HourInput
                  name="extinctionDaysEnd"
                  value={
                    extinctionDaysEnd === '' ? '' : Number(extinctionDaysEnd)
                  }
                  onChange={(e) => {
                    setExtinctionDaysEnd(e.target.value);
                    sessionStorage.setItem('management.extinctionDaysEnd', String(e.target.value));
                  }}
                  label="日"
                  min={0}
                  max={99}
                />
              </div>
            </FlexBox>
          </div>

          <div css={styles.container}>
            <FlexBox>
              <div css={styles.label}>
                <FormLabel label=" " />
              </div>
              <div css={styles.select}>
                <Checkbox
                  id="targetGrantTenDays"
                  name="targetGrantTenDays"
                  value=""
                  label="当年付与数10日以上のスタッフを対象とする"
                  checked={targetGrantTenDays}
                  onChange={() => {
                    setTargetGrantTenDays(!targetGrantTenDays);
                    sessionStorage.setItem('management.targetGrantTenDays', String(!targetGrantTenDays));
                  }}
                />
              </div>
            </FlexBox>
          </div>
          <div
            css={css`
              table th:nth-child(1) {
                width: 140px;
              }
              table th:nth-child(2) {
                width: 100px;
              }
              table th:nth-child(3) {
                width: 140px;
              }
              table th:nth-child(4) {
                width: 100px;
              }
              table th:nth-child(5) {
                width: 100px;
              }
              table th:nth-child(6) {
                width: 100px;
              }
              table th:nth-child(7) {
                width: 100px;
              }
              table th:nth-child(8) {
                width: 140px;
              }
              table th:nth-child(9) {
                width: 140px;
              }
              table th:nth-child(10) {
                width: 100px;
              }
              table th:nth-child(11) {
                width: 100px;
              }
            `}
          >
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
              <DataTable
                columns={columns}
                data={paidHolidayStatusList}
                isGlobalFilter={true}
                minWidth="1348px"
                sortBy={[
                  { id: 'staffCode', desc: false }
                ]}
              />
            </div>
          </div>
          <FormSubmitArea>
            <div style={{ marginRight: '12px' }}>
              {checkUserRole && checkUserRole.importFlag === 1 && (
              <Link to="/attendHolidayManagementImport/Paid">
                <Button text="インポート" />
              </Link>
              )}
            </div>
            <div style={{ marginRight: '12px' }}>
              {checkUserRole && checkUserRole.downloadFlag === 1 && (
              <Button
                text="ダウンロード "
                onClick={() => downloadPaidHolidayStatus()}
              />
              )}
            </div>
            {Number(accessLevel) === 1 && checkUserRole && checkUserRole.downloadFlag === 1 && (
             <Button
               text="全店舗ダウンロード "
               onClick={() => downloadPaidHolidayStatus(true)}
             />
            )}

          </FormSubmitArea>

          {/* Form Detail */}
          <Modal
            open={modalOpen}
            closeHandler={closeModal}
            title="有給編集"
            submitText="登録"
            closeText="閉じる"
            onSubmit={callSaveUseItemForPaidHolidayStatus}
          >
            {detailItems && (
              <div style={{ width: '500px' }}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td className="active">スタッフコード：</td>
                      <td>{dataDetail.staffCode}</td>
                    </tr>
                    <tr>
                      <td className="active">スタッフ名：</td>
                      <td>{dataDetail.staffName}</td>
                    </tr>
                    <tr>
                      <td className="active" />
                      <td />
                    </tr>

                    {detailItems.useLastFirstGrantFlg && (
                      <tr>
                        <td className="active">初回繰越数：</td>
                        <td>
                          <FlexBox>
                            <div style={{ width: '120px' }}>
                              <HourInput
                                name="useItemsLastFirstGrantDaysNum"
                                value={Number(
                                  detailItems.lastFirstGrantDaysNum,
                                )}
                                onChange={(e) => setDetailItems({
                                  ...detailItems,
                                  lastFirstGrantDaysNum: Number(
                                    e.target.value,
                                  ),
                                })}
                                label="日"
                                min={0}
                                step={0.5}
                              />
                            </div>
                            <div style={{ width: '120px' }}>
                              <HourInput
                                name="useItemsLastFirstGrantTimesNum"
                                value={Number(
                                  detailItems.lastFirstGrantTimesNum,
                                )}
                                onChange={(e) => setDetailItems({
                                  ...detailItems,
                                  lastFirstGrantTimesNum: Number(
                                    e.target.value,
                                  ),
                                })}
                                label="時間"
                                min={0}
                                step={0.5}
                              />
                            </div>
                          </FlexBox>
                        </td>
                      </tr>
                    )}

                    {detailItems.useFirstGrantFlg && (
                      <tr>
                        <td className="active">初回付与数：</td>
                        <td>
                          <FlexBox>
                            <div style={{ width: '120px' }}>
                              <HourInput
                                name="useItemsFirstGrantDaysNum"
                                value={Number(detailItems.firstGrantDaysNum)}
                                onChange={(e) => setDetailItems({
                                  ...detailItems,
                                  firstGrantDaysNum: Number(e.target.value),
                                })}
                                label="日"
                                min={0}
                                step={0.5}
                              />
                            </div>
                            <div style={{ width: '120px' }}>
                              <HourInput
                                name="useItemsFirstGrantTimesNum"
                                value={Number(detailItems.firstGrantTimesNum)}
                                onChange={(e) => setDetailItems({
                                  ...detailItems,
                                  firstGrantTimesNum: Number(e.target.value),
                                })}
                                label="時間"
                                min={0}
                                step={0.5}
                              />
                            </div>
                          </FlexBox>
                        </td>
                      </tr>
                    )}

                    {detailItems.useLastGrantFlg && (
                      <tr>
                        <td className="active">前年繰越数：</td>
                        <td>
                          <FlexBox>
                            <div style={{ width: '120px' }}>
                              <HourInput
                                name="useItemsLastGrantDaysNum"
                                value={Number(detailItems.lastGrantDaysNum)}
                                onChange={(e) => setDetailItems({
                                  ...detailItems,
                                  lastGrantDaysNum: Number(e.target.value),
                                })}
                                label="日"
                                min={0}
                                step={0.5}
                              />
                            </div>
                            <div style={{ width: '120px' }}>
                              <HourInput
                                name="useItemsLastGrantTimesNum"
                                value={Number(detailItems.lastGrantTimesNum)}
                                onChange={(e) => setDetailItems({
                                  ...detailItems,
                                  lastGrantTimesNum: Number(e.target.value),
                                })}
                                label="時間"
                                min={0}
                              />
                            </div>
                          </FlexBox>
                        </td>
                      </tr>
                    )}

                    {detailItems.useCurrentGrantFlg && (
                      <tr>
                        <td className="active">当年付与数：</td>
                        <td>
                          <FlexBox>
                            <div style={{ width: '120px' }}>
                              <HourInput
                                name="useItemsGrantDaysNum"
                                value={Number(detailItems.grantDaysNum)}
                                onChange={(e) => setDetailItems({
                                  ...detailItems,
                                  grantDaysNum: Number(e.target.value),
                                })}
                                label="日"
                                min={0}
                                step={0.5}
                              />
                            </div>
                            <div style={{ width: '120px' }}>
                              <HourInput
                                name="useItemsGrantTimesNum"
                                value={Number(detailItems.grantTimesNum)}
                                onChange={(e) => setDetailItems({
                                  ...detailItems,
                                  grantTimesNum: Number(e.target.value),
                                })}
                                label="時間"
                                min={0}
                              />
                            </div>
                          </FlexBox>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </Modal>
        </div>
      </Accordion>
    </BlockUIForLoading>
  );
};
export default ManagementInformationForm;
