import ApiClient from 'api/ApiClient';

export const getIncomeExpenditure = async (
  orgCode: string,
  yearlyStartDate: string,
  yearlyEndDate: string,
  categoryAry: any,
)
  : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    yearlyStartDate,
    yearlyEndDate,
    loginStaffCode,
    functionType: 3,
  };

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }

  const response = await ApiClient.get(`/v1/sales/list/standard/yearly/balancetable/${companyCode}?${appendUrl}`, params);
  return response.data;
};

export const downloadIncomeExpenditureCsv = async (data: any, fileName:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsvPost(`/v1/reportExportFile/csv/${companyCode}`, {}, data, fileName);
};

export const downloadIncomeExpenditureExcel = async (monthlyReport: any, fileName: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadExcelPost(`/v1/reportExportFile/excel/${companyCode}`, {}, monthlyReport, fileName);
};

