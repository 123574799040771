import React from 'react';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import PrimaryButton from 'components/atoms/Button';
import Dropdown from 'components/molecules/Dropdown';
import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';
import productColor, { grayScale } from 'components/styles';
import Select from 'components/atoms/Select';

const ButtonNavigation: React.FC<{
  downloadOrganization: () => void;
  downloadManagerCsv: () => void;
  downloadSpecialSalaryCsv: () => void;
  downloadCompanyBankCsv:() => void;
  arrCompanyBank?: any;
  roleScreen?: any;
  order?: string;
  sort?: string;
  setOrder: (arg: '0'|'1') => void;
  setSort: (arg: '0'|'1') => void;
}> = ({
  downloadOrganization, downloadManagerCsv, downloadSpecialSalaryCsv, downloadCompanyBankCsv, roleScreen, arrCompanyBank,
  order, sort, setOrder, setSort
}) => (
  <FlexBox customStyle={css({ paddingRight: '12px', marginTop: '16px' })}>
    {
      roleScreen && roleScreen.downloadFlag === 1 && (
      <React.Fragment>
        <FlexBoxItem>
          <Dropdown
            items={arrCompanyBank && arrCompanyBank.companyBankList && arrCompanyBank.companyBankList.length > 0 ? [
              {
                value: '0',
                label: '組織情報のダウンロード',
                onClick: downloadOrganization,
              },
              {
                value: '1',
                label: 'マネージャー権限のダウンロード',
                onClick: downloadManagerCsv,
              },
              {
                value: '2',
                label: '特賃日割当情報のダウンロード',
                onClick: downloadSpecialSalaryCsv,
              },
              // {
              //   value: '3',
              //   label: '銀行情報のダウンロード',
              //   onClick: downloadCompanyBankCsv,
              // },
            ] :
            [
              {
                value: '0',
                label: '組織情報のダウンロード',
                onClick: downloadOrganization,
              },
              {
                value: '1',
                label: 'マネージャー権限のダウンロード',
                onClick: downloadManagerCsv,
              },
              {
                value: '2',
                label: '特賃日割当情報のダウンロード',
                onClick: downloadSpecialSalaryCsv,
              },
            ]}
          >
            <Icon type="download" color={grayScale.gray100} />
            <BodyText
              color={productColor.primary}
              customStyle={css({
                marginLeft: '4px',
              })}
            >
              CSVダウンロード
            </BodyText>
          </Dropdown>
        </FlexBoxItem>

        <FlexBoxItem marginLeft="24px" basis="150px">
          <Select
            name="orgState"
            value={String(sort)}
            setValue={setSort as (arg: string) => void}
            options={[
              {
                label: '組織コード',
                value: '0',
              },
              {
                label: '並び順',
                value: '1',
              },
            ]}
          />
        </FlexBoxItem>

        <FlexBoxItem marginLeft="24px" basis="150px">
          <Select
            name="orgState"
            value={String(order)}
            setValue={setOrder as (arg: string) => void}
            options={[
              {
                label: '昇順',
                value: '0',
              },
              {
                label: '降順',
                value: '1',
              },
            ]}
          />
        </FlexBoxItem>
      </React.Fragment>
      )
    }

    <FlexBoxItem marginLeft="auto">
      { roleScreen && roleScreen.importFlag === 1 && (
      <Link to="/organizations/csv">
        <PrimaryButton
          text="インポート"
        />
      </Link>
      )}
      { roleScreen && roleScreen.editable === 1 && (
        <span style={{ marginLeft: 10 }}>
          <Link to="/organizations/add">
            <PrimaryButton
              text="新規組織登録"
            />
          </Link>
        </span>
      )}
    </FlexBoxItem>
  </FlexBox>
);

export default ButtonNavigation;
