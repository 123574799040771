import {
  useState, useCallback, useEffect,
} from 'react';

import {
  getDigestionItemsForSpecialHolidayStatus,
  downloadSpecialHolidayStatusCsv,
  getSpecialHolidayStatusDetail,
  saveUseItemForSpecialHoliday,
} from 'api/holidayManagement';
import SpecialHolidayStatusDomain from 'domain/master/holidayManagement/specialHolidayStatus';
import useToastNotification from 'hooks/useToastNotification';

export interface DetailItems {
  specialHolidayGrantId: string,
  holidayId: string,
  holidayName: string,
  staffCode: string,
  staffName: string,
  enteringGrantDay1: number,
  enteringGrantDay2: number,
  enteringGrantDay3: number,
  grantDaysNum: number,
  useEnteringGrantFlg1: boolean,
  useEnteringGrantFlg2: boolean,
  useEnteringGrantFlg3: boolean,
  useGrantFlg: boolean,
}

export const useManagementInformationDomainForm = () => {
  const sessionStorageOrgCode = String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}4`)) !== 'null' ? (String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}4`))) : (sessionStorage.getItem('loginUser.orgCode') || '');
  const [
    paidHolidayStatusList, SetPaidHolidayStatusList,
  ] = useState<Array<SpecialHolidayStatusDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useState(sessionStorageOrgCode);
  const sessionManagementEmploymentId = sessionStorage.getItem('specialHoliday.managementInformation.employmentId') || '';
  const [employmentId, setOrgEmploymentId] = useState(sessionManagementEmploymentId);
  const sessionManagementSpecialManageHolidaySelect = sessionStorage.getItem('specialHoliday.managementInformation.specialManageHolidaySelect') || '';
  const [
    specialManageEmpSelect, setSpecialManageEmpSelect,
  ] = useState(sessionManagementSpecialManageHolidaySelect);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailItems, setDetailItems] = useState<DetailItems>({
    specialHolidayGrantId: '',
    holidayId: '',
    holidayName: '',
    staffCode: '',
    staffName: '',
    enteringGrantDay1: 0,
    enteringGrantDay2: 0,
    enteringGrantDay3: 0,
    grantDaysNum: 0,
    useEnteringGrantFlg1: false,
    useEnteringGrantFlg2: false,
    useEnteringGrantFlg3: false,
    useGrantFlg: false,
  });
  const { successNotification, errorNotification } = useToastNotification();


  // データの取得
  const fetchData = useCallback(async () => {
    const response = await getDigestionItemsForSpecialHolidayStatus(
      orgCode !== 'all' ? orgCode : '',
      employmentId,
      specialManageEmpSelect,
    );
    SetPaidHolidayStatusList(response.map((result) => new SpecialHolidayStatusDomain(result)));
  }, [employmentId, orgCode, specialManageEmpSelect]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, employmentId, specialManageEmpSelect]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  //
  const callPaidHolidayStatusDetail = useCallback(async (
    staffCode: string,
    staffName: string,
    holidayId: string,
    holidayName: string,
    specialHolidayGrantId: string,
  ) => {
    try {
      await getSpecialHolidayStatusDetail(
        staffCode,
        staffName,
        holidayId,
        holidayName,
        specialHolidayGrantId,
      ).then((response: any) => {
        setDetailItems(response);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [errorNotification]);

  //
  const callSaveUseItemForPaidHolidayStatus = useCallback(async () => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const tmpDataPost = detailItems;
    (tmpDataPost as any).saveUser = tmpDataPost.staffCode;
    if (!(tmpDataPost as any).specialHolidayGrantId) {
      (tmpDataPost as any).specialHolidayGrantId = '';
    }
    try {
      await saveUseItemForSpecialHoliday(companyCode, tmpDataPost).then((response: any) => {
        setNoManager(!noManager);
        successNotification('更新しました。');
        setModalOpen(false);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [detailItems, errorNotification, noManager, successNotification]);

  return {
    paidHolidayStatusList,
    toggleNoManager,
    orgCode,
    setOrgCode,
    employmentId,
    setOrgEmploymentId,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    callPaidHolidayStatusDetail,
    callSaveUseItemForPaidHolidayStatus,
    specialManageEmpSelect,
    setSpecialManageEmpSelect,
  };
};

export const useDownloadHolidayManagement = (
  orgCode: string,
  employmentId: string,
  specialManageEmpSelect: string,
) => {
  const downloadPaidHolidayStatus = useCallback((isDownloadAll?: boolean) => {
    downloadSpecialHolidayStatusCsv(
      isDownloadAll ? '' : orgCode,
      employmentId,
      specialManageEmpSelect,
    );
  }, [employmentId, specialManageEmpSelect, orgCode]);

  return {
    downloadPaidHolidayStatus,
  };
};

export default {
  useManagementInformationDomainForm,
};
