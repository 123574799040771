import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';

import HolidayManagementImportForm from 'components/organismos/master/attend/HolidayManagementImportForm';
import { useParams } from 'react-router-dom';

const HolidayManagementImportPage: React.FC = () => {
  const { formatType } = useParams();
  return (
    <SidebarTemplate
      pageTitle={formatType === 'specialHoliday' ? '特休インポート' : '有給インポート'}
    >
      <HolidayManagementImportForm />
    </SidebarTemplate>
  );
};

export default HolidayManagementImportPage;
