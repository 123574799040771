/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useCallback } from 'react';
import { importFileCSV, Error } from 'api/executive';
import useToastNotification from 'hooks/useToastNotification';

export const useExecutiveCsvForm = () => {
  const [uploadFile, setUploadFile] = useState<File>();
  const [errors, setErrors] = useState<Array<string>>();
  const [toastMessage, setToastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [inputValue, setValueInput] = useState('');
  const { successNotification, errorNotification } = useToastNotification();
  const [blocking, setBlocking] = useState(false);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const extensionFile = (name: string) => {
    return name.split('.').pop()?.toLowerCase();
  };

  if (uploadFile) {
    const fileType = extensionFile(uploadFile.name);
    if (fileType !== 'csv') {
      setToastModalOpen(true);
      setToastMessage('CSVファイルを選択してください。');
      setUploadFile(undefined);
      setValueInput('');
    }
  }

  const onSubmit = useCallback(() => {
    setConfirmModalOpen(false);
    if (!uploadFile) {
      return;
    }
    setErrors(undefined);
    setBlocking(true);

    importFileCSV(uploadFile)
      .then((response: any) => {
        setBlocking(false);
        if (response.status && response.status !== 200) {
          setErrors((response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        } else {
          successNotification('インポートが完了しました。');
        }
      }).catch((error: any) => {
        setBlocking(false);
        setErrors((error.response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        if (error.response && error.response.data && error.response.data.errors) {
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  }, [errorNotification, successNotification, uploadFile]);

  const handleSubmit = useCallback(() => {
    setConfirmModalOpen(true);
  }, []);

  return {
    uploadFile,
    setUploadFile,
    onSubmit,
    errors,
    toastMessage,
    toastModalOpen,
    setToastModalOpen,
    setToastMessage,
    confirmModalOpen,
    closeConfirmModal,
    handleSubmit,
    inputValue,
    blocking,
  };
};

export default useExecutiveCsvForm;
