/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import StaffDomain, { StaffAccess } from 'domain/staff';
import { getSpecificStaff } from 'api/staff';
import useRolesOptions from 'hooks/useRoleOptions';
import useBusinessMasterOptions from 'hooks/useBusinessMasterOptions';
import useExecutiveOptions from 'hooks/useExecutiveOptions';
import { OptionType } from 'components/atoms/Select';
import moment from 'moment';
import { getOrganizationTrees } from 'api/organization';
import { getEmploymentSelectListYm } from 'api/employment';
import { fetchBreakUUID } from 'api/shiftPattern';
import ShiftPatternDomain from 'domain/master/attend/shiftPattern';
import { SessionStorageKey, Values } from 'utility/constants';

export const useStaffEdit = () => {
  const loginStaffCode = sessionStorage.getItem(SessionStorageKey.LoginUser.STAFF_CODE) || '';
  const { staffCode, menuType } = useParams();
  const [staffDomain, setStaffDomain] = useState<StaffDomain>(StaffDomain.generateInitial());
  const [orgTreeOptions, setOrgTreeOptions] = useState<Array<OptionType>>([]);
  const [employmentOptions, setEmploymentOptions] = useState<Array<OptionType>>([]);
  const [orgTargetDate, setOrgTargetDate] = useState<Date>(new Date());
  const [empTargetDate, setEmpTargetDate] = useState<Date>(new Date());
  const [breakBusiness, setBreakBusiness] = useState<ShiftPatternDomain>(ShiftPatternDomain.generateInitial());
  const [orgApplyDate, setOrgApplyDate] = useState<Date>(new Date());
  const [orgDate, setOrgDate] = useState<Date | undefined>(undefined);


  const [isLoading, setIsLoading] = useState(!!staffCode);
  // const [isLoading, setIsLoading] = useState(true);

  /** ログインユーザー編集フラグ */
  const [isEditingLoginUser, setIsEditingLoginUser] = useState(false);

  const {
    hqRoleOptions, shopRoleOptions, personalRoleOptions, roleOptions,
  } = useRolesOptions();
  const businessOptions = useBusinessMasterOptions();
  const executiveOptions = useExecutiveOptions();
  const trialPeriodOptions : Array<any> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => ({
    value: item.toString(),
    label: item === 0 ? 'なし' : `${item}ヶ月`,
  }));

  useEffect(() => {
    if (staffCode) {
      const fetchData = async () => {
        const staffResult = await getSpecificStaff(staffCode);
        const staffDomainResult = new StaffDomain(staffResult);
        staffDomainResult.orgAccessRegistList = staffDomainResult.orgAccessList !== null
          ? staffDomainResult.orgAccessList.map((item:StaffAccess) => ({
              orgCode : item.orgCode,
              roleCode: item.roleCode
            }))
          : [];
        setStaffDomain(staffDomainResult);
        setIsLoading(false);
        setOrgTargetDate(staffDomainResult.hireDate!);
        setOrgDate(staffDomainResult.orgApplyDate!);
        setOrgApplyDate(staffDomainResult.orgApplyDate!);
        setEmpTargetDate(staffDomainResult.empApplyDate || staffDomainResult.hireDate!);
        setIsEditingLoginUser(staffCode === loginStaffCode);
        console.log(`staffCode:${staffCode} loginStaffCode:${loginStaffCode} isEditingLoginUser:${staffCode === loginStaffCode}`)
      };
      fetchData();
    }
  }, [staffCode]);

  useEffect(() => {
    fetchBreakUUID().then((response: ShiftPatternDomain) => {
      sessionStorage.setItem('breakTimeBusinessId', response.businessId);
      setBreakBusiness(response);
    });
  }, []);

  useEffect(() => {
    // edit isset orgDate || create not isset staffCode
    let dateFrom = moment(orgApplyDate).format('YYYY-MM-DD');
    let dateTo = moment(orgApplyDate).format('YYYY-MM-DD');
    if (orgDate || !staffCode) {
      if (!staffCode) {
        dateFrom = moment(orgTargetDate).format('YYYY-MM-DD');
        dateTo = moment(orgTargetDate).format('YYYY-MM-DD');
      }
      getOrganizationTrees(Values.FunctionType.Master.value, String(dateFrom), String(dateTo), loginStaffCode, false, []).then((trees) => {
        const treesOrg = trees.map((tree) => ({
          value: tree.orgCode,
          label: `${tree.orgCode} ${tree.orgName}`,
        }));
        treesOrg.unshift({
          value: '',
          label: '-',
        });

        if (!treesOrg.map((item) => item.value).includes(staffDomain.orgCode) && staffDomain.orgCode) {
          const newStaff = staffDomain.getRawData();
          newStaff.orgCode = treesOrg.length > 1 ? treesOrg[1].value : '';
          setStaffDomain(new StaffDomain(newStaff));
        }
        setOrgTreeOptions(treesOrg);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgApplyDate]);


  useEffect(() => {
    getEmploymentSelectListYm(empTargetDate).then((employments) => {
      const employmentO = employments.map((employment) => ({
        value: employment.employmentId,
        label: `${employment.employmentCode} ${employment.employmentName}`,
      }));
      employmentO.unshift({
        value: '',
        label: '-',
      });
      if (!employmentO.map((item) => item.value).includes(staffDomain.employmentId)) {
        const newStaff = staffDomain.getRawData();
        newStaff.employmentId = '';
        setStaffDomain(new StaffDomain(newStaff));
      }
      setEmploymentOptions(employmentO);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empTargetDate]);

  return {
    isLoading,
    setIsLoading,
    staffDomain,
    setStaffDomain,
    hqRoleOptions,
    shopRoleOptions,
    personalRoleOptions,
    roleOptions,
    businessOptions,
    trialPeriodOptions,
    employmentOptions,
    executiveOptions,
    orgTreeOptions,
    setOrgTargetDate,
    orgApplyDate,
    setOrgApplyDate,
    setEmpTargetDate,
    breakBusiness,
    menuType,
    isEditingLoginUser,
  };
};

export default useStaffEdit;
