/** @jsx jsx */
import React, { useState, useEffect }  from 'react';
import { jsx } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import FormContents from 'components/atoms/Form/FormContents';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import DatePicker from 'components/molecules/DatePicker';
import FlexBox from 'components/atoms/FlexBox';
import dailySalesStore from 'components/organismos/master/general/salesPage/dailySalesStore/hook';
import DailySalesStoreTable from 'components/organismos/master/general/salesPage/dailySalesStore/DailySalesStoreTable';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const DailySalesStore: React.FC<{}> = () => {
  const {
    isLoading,
    listDailySalesStore,
    functionType,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo
  } = dailySalesStore();

  const arListSymbol = [
    {
      value: -1,
      text: '未申請',
      symbol: 'x',
    },
    {
      value: 0,
      text: '申請中',
      symbol: '△',
    },
    {
      value: 1,
      text: '差戻し中',
      symbol: '▼',
    },
    {
      value: 2,
      text: '承認済み',
      symbol: '〇',
    },
    {
      value: 3,
      text: '休日',
      symbol: '−',
    },
    {
      value: 99,
      text: '空白で表示',
      symbol: '',
    },
  ];

  const stateSymbol = {};
  arListSymbol.forEach((item) => {
    (stateSymbol as any)[item.value] = item.symbol;
  });

  const [orgName, setorgName] = useState(sessionStorage.getItem('loginUser.orgName') ? sessionStorage.getItem('loginUser.orgName') : '');
  const targetPeriodData = `対象期間：${moment(selectedDateFrom).format('YYYY/MM/DD')}~${moment(selectedDateTo).format('YYYY/MM/DD')}`;

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox>
          <FlexBoxItem>
            <DatePicker
              dateFormat="yyyy年MM月dd日"
              date={selectedDateFrom}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setSelectedDateFrom(date);
                  sessionStorage.setItem('dateSelectSalePageFrom5', `${date}`);
                }
              }}
              isFullWidth={true}
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <span style={{ margin: '0 30px' }}>～</span>
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePicker
              dateFormat="yyyy年MM月dd日"
              date={selectedDateTo}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setSelectedDateTo(date);
                  sessionStorage.setItem('dateSelectSalePageTo5', `${date}`);
                }
              }}
              isFullWidth={true}
            />
          </FlexBoxItem>
        </FlexBox>
        <div style={{ marginLeft: '8px' }}>
          <OrganizationFilteredTree
            functionType={functionType}
            orgCallback={(args: string | Array<string>) => {
              setOrgCode(String(args));
            }}
            orgCategoryCallback={
                (args: Array<string>) => {
                  setCategoryAry(args);
                }
              }
            initOrgValue={String(orgCode)}
            staffCode={loginStaffCode}
            orgLabel="組織名"
            addAllItem={true}
            orgNameCallback={(arg: any) => {
              setorgName(String(arg));
            }}
          />
        </div>

        <DailySalesStoreTable
          listDailySalesStore={listDailySalesStore}
          stateSymbol={stateSymbol}
          orgCode={orgCode}
          orgName={orgName}
          selectedDateFrom={selectedDateFrom}
          selectedDateTo={selectedDateTo}
          targetPeriodData={targetPeriodData}
          roleScreen={roleScreen}
        />
      </FormContents>
    </BlockUI>
  );
};
export default DailySalesStore;
