import React, { useEffect, useState, useMemo, useCallback } from "react";

import { PrimaryButton } from "components/atoms/Button";
import { css } from "@emotion/core";
import ApiClient from "api/ApiClient";
import moment from "moment";
import {
  APISalesReportPointedOutType,
  SaleReportPointOutColumnType,
  TableColumnsType,
  APIReportStatusType,
} from "domain/salesReport";
import { OptionType } from "components/atoms/Select";
import useToastNotification from "hooks/useToastNotification";
import TextareaAutoResize from "components/atoms/input/TextareaAutoResize";
import DatePicker from "components/molecules/DatePicker";
import CheckboxOn from 'components/atoms/Icon/CheckboxOn';
import CheckboxOff from 'components/atoms/Icon/CheckboxOff';
import TextLink from 'components/atoms/TextLink';

const ReportInstruction: React.FC<{
  canInstructUpdate: boolean;
  canInstructAdd: boolean;
  targetDay: string;
  targetOrgCode: string;
  roleScreen?: any,
}> = ({ canInstructUpdate, canInstructAdd, targetDay, targetOrgCode, roleScreen }) => {
  const { errorNotification, successNotification } = useToastNotification();
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const site = sessionStorage.getItem("loginUser.site") || "1";
  const createUser = sessionStorage.getItem("loginUser.staffName") || "";

  const initState = {
    pointedOutStatus: "",
    correspondenceStatus: "",
    correspondenceDay: "",
    overview: "",
    contents: "",
    hqSharedMatters: "",
    createDate: moment().format("YYYY-MM-DD HH:mm"),
    confirmedDay: "",
    createUser: "",
    // status: false,
    notSubjectToAudit: false,
    updateUser: "",
  };

  const [salesReportPointedOut, setSalesReportPointedOut] = useState<SaleReportPointOutColumnType[]>([initState]);
  const [saleReportStatus0, setSaleReportStatus0] = useState<OptionType[]>([{ label: "-", value: "", isHide: false }]);
  const [saleReportStatus1, setSaleReportStatus1] = useState<OptionType[]>([{ label: "-", value: "", isHide: false }]);
  const [isSubmittedTextArea, setIsSubmittedTextArea] = useState<Boolean>(false)

  console.log('salesReportPointedOut', salesReportPointedOut);
  const tableColumns: TableColumnsType[] = [
    {
      name: "状態", // saleReportStatus
      visible: true,
      width: "80px",
      content: (rowIndex: number, colIndex: number) => (
        <td key={colIndex}>
          <select
            style={{ padding: "5px", height: "30px", width: "100%" }}
            key={colIndex}
            onChange={(e) => {
              let option = e.target;
              let newSalesReportPointedOut = [...salesReportPointedOut];
              newSalesReportPointedOut[rowIndex].pointedOutStatus = option.value;

              setSalesReportPointedOut(newSalesReportPointedOut);
            }}
            value={salesReportPointedOut[rowIndex].pointedOutStatus}
            disabled={roleScreen === null || roleScreen.usabilityGeneralItem1 === 0 ? true : false}
          >
            {saleReportStatus0.map((option, i) => {
                if(roleScreen !== null && roleScreen.usabilityGeneralItem1 === 1) {
                  return (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                    )
                } else if(roleScreen !== null && roleScreen.usabilityGeneralItem1 === 0 && option.isHide === false) {
                  return (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                    )
                }              
              }
            )}
          </select>
        </td>
      ),
    },
    {
      name: "対応状況", // saleReportStatusKbn
      visible: true,
      width: "80px",
      content: (rowIndex, colIndex) => (
        <td key={colIndex}>
          <select
            id={String(colIndex)}
            key={colIndex}
            style={{ padding: "5px", height: "30px", width: "100%" }}
            onChange={(e) => {
              let option = e.target;
              let newSalesReportPointedOut = [...salesReportPointedOut];
              newSalesReportPointedOut[rowIndex].correspondenceStatus = option.value;

              setSalesReportPointedOut(newSalesReportPointedOut);
            }}
            value={salesReportPointedOut[rowIndex].correspondenceStatus}
          >
            {saleReportStatus1.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
              )
            )}
          </select>
        </td>
      ),
    },
    {
      name: "対応日", // date
      visible: true,
      width: "10%",
      content: (rowIndex, colIndex) => (
        <td key={colIndex}>
          <DatePicker
            dateFormat="yyyy年MM月dd日"
            date={
              // > -25200000 stands for Displaying dates which are larger than 1970-01-01
              moment(salesReportPointedOut[rowIndex].correspondenceDay).valueOf() > -25200000 ? salesReportPointedOut[rowIndex].correspondenceDay
                ? moment(salesReportPointedOut[rowIndex].correspondenceDay).toDate()
                : undefined : undefined
            }
            changeDate={(e: Date) => {
              if (e) {
                let value = moment(e).format("YYYY-MM-DD");
                let newSalesReportPointedOut = [...salesReportPointedOut];
                newSalesReportPointedOut[rowIndex].correspondenceDay = value;
                setSalesReportPointedOut(newSalesReportPointedOut);
              }
            }}
            isFullWidth={true}
            customStyle={css({ background: "white", border: '1px solid black', width: 'auto' })}
            hideIcon={true}
          />
        </td>
      ),
    },
    {
      name: "指摘概要", // overview
      visible: true,
      width: "12%",
      content: (rowIndex, colIndex) => (
        <td key={colIndex} style={{ wordBreak: "break-all" }}>
          {roleScreen !== null && roleScreen.usabilityGeneralItem1 === 1 ? (
            <TextareaAutoResize
              rows={1}
              value={salesReportPointedOut[rowIndex].overview}
              onChange={(e) => {
                let value = e.target.value;
                let newSalesReportPointedOut = [...salesReportPointedOut];
                salesReportPointedOut[rowIndex].overview = value;

                setSalesReportPointedOut(newSalesReportPointedOut);
              }}
              showExternalLink={true}
            />
          ) : (
            <TextLink
              rows={1}
              value={salesReportPointedOut[rowIndex].overview}
              showExternalLink={true}
            />
          )}
        </td>
      ),
    },
    {
      name: "指摘内容", // content
      visible: true,
      width: "12%",
      content: (rowIndex, colIndex) => (
        <td key={colIndex} style={{ wordBreak: "break-all" }}>
          { roleScreen !== null && roleScreen.usabilityGeneralItem1 === 1 ? (
            <TextareaAutoResize
              rows={1}
              value={salesReportPointedOut[rowIndex].contents}
              onChange={(e) => {
                let value = e.target.value;
                let newSalesReportPointedOut = [...salesReportPointedOut];
                salesReportPointedOut[rowIndex].contents = value;

                setSalesReportPointedOut(newSalesReportPointedOut);
              }}
              showExternalLink={true}
            />
          ) : (
            <TextLink
              rows={1}
              value={salesReportPointedOut[rowIndex].contents}
              showExternalLink={true}
            />
          )}
        </td>
      ),
    },
    {
      name: "本部連絡欄", // hqSharedMatters
      visible: roleScreen !== null && roleScreen.usabilityGeneralItem1 === 1 ? true : false,
      width: "12%",
      content: (rowIndex, colIndex) => (
        <td key={colIndex} style={{ wordBreak: "break-all" }}>
          {roleScreen !== null && roleScreen.usabilityGeneralItem1 === 1 ? (
            <TextareaAutoResize
              rows={1}
              value={salesReportPointedOut[rowIndex].hqSharedMatters}
              onChange={(e) => {
                let value = e.target.value;
                let newSalesReportPointedOut = [...salesReportPointedOut];
                salesReportPointedOut[rowIndex].hqSharedMatters = value;

                setSalesReportPointedOut(newSalesReportPointedOut);
              }}
              showExternalLink={true}
            />
          ) : (
            <TextLink
              rows={1}
              value={salesReportPointedOut[rowIndex].hqSharedMatters}
              showExternalLink={true}
            />
          )}
        </td>
      ),
    },
    {
      name: "指摘日", // createDate
      visible: true,
      content: (rowIndex, colIndex) => <td key={colIndex} style={{ textAlign: 'center' }}>{salesReportPointedOut[rowIndex].createDate}</td>,
    },
    {
      name: "確認日", // confirmedDay
      visible: true,
      width: "10%",
      content: (rowIndex, colIndex) => (
        <td key={colIndex}>
          {roleScreen !== null && roleScreen.usabilityGeneralItem1 === 1 ? (
            <DatePicker
              dateFormat="yyyy年MM月dd日"
              date={
                // > -25200000 stands for Displaying dates which are larger than 1970-01-01
                salesReportPointedOut[rowIndex].confirmedDay !== '' && moment(salesReportPointedOut[rowIndex].confirmedDay).valueOf() > -25200000 ? salesReportPointedOut[rowIndex].confirmedDay
                  ? moment(salesReportPointedOut[rowIndex].confirmedDay).toDate()
                  : undefined : undefined
              }
              changeDate={(e: Date) => {
                if (e) {
                  let value = moment(e).format("YYYY-MM-DD");
                  let newSalesReportPointedOut = [...salesReportPointedOut];
                  newSalesReportPointedOut[rowIndex].confirmedDay = value;
                  setSalesReportPointedOut(newSalesReportPointedOut);
                }
              }}
              isFullWidth={true}
              customStyle={css({ background: "white", border: '1px solid black', width: 'auto' })}
              disabled={roleScreen === null || roleScreen.usabilityGeneralItem1 === 0 ? true : false}
              hideIcon={true}
            />
          ) : (
              // > -25200000 stands for Displaying dates which are larger than 1970-01-01
              salesReportPointedOut[rowIndex].confirmedDay !== null && moment(salesReportPointedOut[rowIndex].confirmedDay).valueOf() > -25200000 ? salesReportPointedOut[rowIndex].confirmedDay : ''
            )}
        </td>
      ),
    },
    // {
    //   name: "登録者", // createUser
    //   visible: true,
    //   content: (rowIndex, colIndex) => <td key={colIndex} style={{ width: '50px', padding: '0 2.5px' }}>{salesReportPointedOut[rowIndex].createUser}</td>,
    // },
    {
      name: "最終更新者", // lastUpdateB
      visible: true,
      width: "10%",
      content: (rowIndex, colIndex) => <td key={colIndex} style={{ width: '50px', padding: '0 2.5px', textAlign: 'center' }}>{salesReportPointedOut[rowIndex].updateUser}</td>,
    },
    {
      name: "監査対象外", // checkbox
      visible: true,
      width: "5%",
      content: (rowIndex, colIndex) => {
        let status = salesReportPointedOut[rowIndex].notSubjectToAudit;
        let opacityValue = (roleScreen !== null && roleScreen.usabilityGeneralItem1 === 1) ? 1 : 0.45;
        return (
          <td key={colIndex} style={{ textAlign: 'center', opacity: opacityValue }} >
            {status ? <CheckboxOn onClick={() => handleToggleStatusCheckboxRow(rowIndex)} /> : <CheckboxOff onClick={() => handleToggleStatusCheckboxRow(rowIndex)} />}
          </td>
        );
      },
    },
    // {
    //   name: "監査対象外", // checkbox
    //   visible: true,
    //   width: "5%",
    //   content: (rowIndex, colIndex) => { 
    //     let status = salesReportPointedOut[rowIndex].notSubjectToAudit; 
    //     return (
    //       <td key={colIndex} style={{ textAlign: 'center' }} > 
    //       {status?
    //         <CheckboxOn  onClick={()=>handleToggleStatusCheckboxRow(rowIndex)} />:
    //         <CheckboxOff onClick={()=>handleToggleStatusCheckboxRow(rowIndex)}   />}
    //       </td>
    //     );
    //   },
    // },
    {
      name: "更新", // updateBtn
      visible: roleScreen !== null && roleScreen.editable === 1 ? true : false,
      width: "5%",
      content: (rowIndex, colIndex) => {        
        return (
          <td key={colIndex} style={{ textAlign: 'center' }} >
            <PrimaryButton text="更新" 
              onClick={() => submit(rowIndex)} 
              disabled={roleScreen === null || roleScreen.editable === 0 || (roleScreen.editable === 1 && roleScreen.usabilityGeneralItem1 === 0 && flagCountSalesPointedOut === 0) ? true : false}
            />
          </td>
        );
      },
    },
  ];
  const handleToggleStatusCheckboxRow = useCallback((rowIndex: number) => {
    // let index = salesReportPointedOut.findIndex((item)=>item.idx === idx); 
    if (roleScreen !== null && roleScreen.usabilityGeneralItem1 === 1) {
      let tmpsalesReportPointedOut = [...salesReportPointedOut];
      tmpsalesReportPointedOut[rowIndex].notSubjectToAudit = !tmpsalesReportPointedOut[rowIndex].notSubjectToAudit;
      setSalesReportPointedOut(tmpsalesReportPointedOut);
    }

  }, [salesReportPointedOut]);

  const addRow = () => {
    let newRows = [...salesReportPointedOut, initState];
    setSalesReportPointedOut(newRows);
  };

  const submit = async (rowIndex: number) => {
    let rowData = { ...salesReportPointedOut[rowIndex] };
    //set default POST values
    rowData.pointedOutStatus = rowData.pointedOutStatus || saleReportStatus0[0].value;
    rowData.correspondenceStatus = rowData.correspondenceStatus || saleReportStatus0[0].value;
    rowData.correspondenceDay = rowData.correspondenceDay !== '' && rowData.correspondenceDay !== null && new Date(rowData.correspondenceDay) instanceof Date && !isNaN(new Date(rowData.correspondenceDay).valueOf())
      ? moment(rowData.correspondenceDay).format("YYYY-MM-DD") : '';
    rowData.confirmedDay = rowData.confirmedDay !== '' && rowData.confirmedDay !== null && new Date(rowData.confirmedDay) instanceof Date && !isNaN(new Date(rowData.confirmedDay).valueOf())
      ? moment(rowData.confirmedDay).format("YYYY-MM-DD") : '';
    rowData.createUser = rowData.createUser || createUser;
    rowData.updateUser = rowData.updateUser || createUser;
    rowData.hqSharedMatters = rowData.hqSharedMatters || ' ';
    //check empty value
    let doNotCheck: string[] = [];
    if (roleScreen !== null && roleScreen.editable === 1 && roleScreen.usabilityGeneralItem1 === 0) {
      doNotCheck = ["overview", "contents", "hqSharedMatters", "confirmedDay"];
    }
    for (const rowKey in rowData) {
      if (Object.prototype.hasOwnProperty.call(rowData, rowKey)) {
        const value = rowData[rowKey as keyof SaleReportPointOutColumnType];
        if (value === "" && !doNotCheck.includes(rowKey) && rowKey !== 'confirmedDay' && rowKey !== 'correspondenceDay') {
          if (rowKey === 'overview') {
            errorNotification("指摘概要を入力してください");
          } else if (rowKey === 'contents') {
            errorNotification("指摘内容を入力してください");
          }
          // errorNotification("を入力してください。");
          return;
        }
      }
    }
    try {
      let data;
      if (rowData.idx) {
        data = await apiPutSaleReportPointOut(rowData);
      } else {
        data = await apiPostSaleReportPointOut(rowData);
      }

      //set idx data back to row
      let cloneRows = [...salesReportPointedOut];
      cloneRows[rowIndex] = convertData([data])[0];
      setSalesReportPointedOut(cloneRows);

      successNotification("更新しました。");
    } catch (error) {
      errorNotification(error.response.data.error);
    }
  };

  const apiPostSaleReportPointOut = async (
    object: SaleReportPointOutColumnType
  ): Promise<APISalesReportPointedOutType> => {
    let params = {
      loginStaffCode: loginStaffCode,
      orgCode: targetOrgCode,
      targetDay: moment(targetDay).format("YYYY-MM-DD"),
      pointedOutStatus: object.pointedOutStatus,
      correspondenceStatus: object.correspondenceStatus,
      correspondenceDay: object.correspondenceDay,
      overview: object.overview,
      contents: object.contents,
      hqSharedMatters: object.hqSharedMatters,
      confirmedDay: object.confirmedDay,
      notSubjectToAudit: object.notSubjectToAudit,
      createUser: object.createUser,
    };
    
    let { data } = await ApiClient.postJsonData(`/v2/report/pointedOut/${companyCode}`, {}, params);
    return data;
  };

  const apiPutSaleReportPointOut = async (
    object: SaleReportPointOutColumnType
  ): Promise<APISalesReportPointedOutType> => {
    let params = {
      loginStaffCode: loginStaffCode,
      orgCode: targetOrgCode,
      targetDay: moment(targetDay).format("YYYY-MM-DD"),
      idx: object.idx,
      pointedOutStatus: object.pointedOutStatus,
      correspondenceStatus: object.correspondenceStatus,
      correspondenceDay: object.correspondenceDay,
      overview: object.overview,
      contents: object.contents,
      hqSharedMatters: object.hqSharedMatters,
      confirmedDay: object.confirmedDay,
      notSubjectToAudit: object.notSubjectToAudit,
      createUser: createUser,
    };

    let { data } = await ApiClient.putJsonData(`/v2/report/pointedOut/${companyCode}`, {}, params);
    return data;
  };

  const apiGetSaleReportPointOut = async (): Promise<APISalesReportPointedOutType[]> => {
    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    let query = {
      orgCode: targetOrgCode,
      targetDay: moment(targetDay).format("YYYY-MM-DD"),
      staffCode,
      roleFlag: roleScreen?.usabilityGeneralItem1 === 1 ? '1' : '0',
    };
    
    let { data } = await ApiClient.get(`/v2/report/pointedOut/${companyCode}`, {}, query);
    return data;
  };

  const apiGetReportStatus0 = async (): Promise<APIReportStatusType[]> => {
    let { data } = await ApiClient.get(`/v1/report/status0/${companyCode}`, {}, {});
    return data;
  };
  const apiGetReportStatus1 = async (): Promise<APIReportStatusType[]> => {
    let { data } = await ApiClient.get(`/v1/report/status1/${companyCode}`, {}, {});
    return data;
  };

  const convertData = (inputData: APISalesReportPointedOutType[]): SaleReportPointOutColumnType[] =>
    inputData.map((row) => ({
      pointedOutStatus: row.pointedOutStatus,
      correspondenceStatus: row.correspondenceStatus,
      correspondenceDay: moment(row.correspondenceDay).format("YYYY-MM-DD"),
      overview: row.overview,
      contents: row.contents,
      hqSharedMatters: row.hqSharedMatters,
      createDate: moment(row.createDate).format("YYYY-MM-DD HH:mm"),
      confirmedDay: moment(row.confirmedDay).format("YYYY-MM-DD"),
      createUser: row.createUser,
      updateUser: row.updateUser,
      status: row.status,
      notSubjectToAudit: row.notSubjectToAudit,
      idx: row.salesReportPointedOutPK.idx,
    }));

  const [flagCountSalesPointedOut, setFlagCountSalesPointedOut] = useState(0);

  useEffect(() => {
    async function getSaleReportPointOut() {
      try {
        let data = await apiGetSaleReportPointOut();
        if (data.length) {
          setSalesReportPointedOut(convertData(data));
          setFlagCountSalesPointedOut(data.length);
        } else {
          setSalesReportPointedOut([initState]);
          setFlagCountSalesPointedOut(0)
        }
      } catch (error) {
        errorNotification("Error");
      }
    }

    getSaleReportPointOut();
  }, [targetDay, targetOrgCode]);

  // set 2 dropdowns
  useEffect(() => {
    async function getReportStatus0() {
      let [status0, status1] = await Promise.all([apiGetReportStatus0(), apiGetReportStatus1()]);
      let data0: OptionType[] = status0.map((status) => ({ value: status.statusId, label: status.statusName, isHide:status.isHideShop }));
      let data1: OptionType[] = status1.map((status) => ({ value: status.statusId, label: status.statusName, isHide:status.isHideShop }));      
      
      setSaleReportStatus0(data0);
      setSaleReportStatus1(data1);
    }
    getReportStatus0();
  }, []);

  return (
    <div className="panel panel-default table-responsive" style={{ marginTop: "20px", marginBottom: "20px" }}>
      <table className="table table-bordered table-striped table-condensed text-nowrap">
        <thead>
          <tr className="width_row_contents">
            {tableColumns
              .filter((col) => col.visible)
              .map((column, i) => {
                return (
                  <th key={i} className="text-center" style={{ width: column.width || "auto" }}>
                    {column.name}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {salesReportPointedOut.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {tableColumns.filter((col) => col.visible).map((column, colIndex) => column.content(rowIndex, colIndex))}
            </tr>
          ))}
        </tbody>
      </table>

      { roleScreen !== null && roleScreen.usabilityGeneralItem1 === 1 && <PrimaryButton text="追加" onClick={addRow}/> }
    </div>
  );
};

export default ReportInstruction;
