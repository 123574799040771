/* eslint-disable max-len */

/** @jsx jsx */
import { useMemo, useState, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import { iconColor } from 'components/styles';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import useToastNotification from 'hooks/useToastNotification';

import Icon from 'components/atoms/Icon';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import PrimaryButton from 'components/atoms/Button';
import DataTable from 'components/organismos/DataTable/DataTable';
import { downloadCSV, deletePriceWithdrawal } from 'api/priceWidthdrawal';
import PriceWithrowalDomain from 'domain/master/sales/priceWithrowal';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import SelectForm from 'components/molecules/SelectForm';
import ConfirmModal from 'components/organismos/ConfirmModal';
import ToastModal from 'components/organismos/ToastModal';
import { useOrgTreesByCategoryOptions, usePriceWidthdrawalList } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const PriceWithdrawalPage = () => {
  const {
    orgCode,
    setorgCode,
    orgTreesOptions,
  } = useOrgTreesByCategoryOptions();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalResendOpen, setConfirmModalResendOpen] = useState(false);
  const [confirmModalContent, setConfirmModalContent] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [toastMessage] = useState('');
  const { priceWidthdrawalItemList, setpriceWidthdrawalItemList } = usePriceWidthdrawalList(orgCode);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const { successNotification, errorNotification } = useToastNotification();
  // Set default selected org
  useMemo(() => {
    setorgCode(sessionStorage.getItem('selectedOrgCode')!);
  }, [setorgCode]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const [selectedObj, setSelectedObj] = useState(PriceWithrowalDomain.generateInitial());

  const downloadFileCSV = useCallback(() => {
    downloadCSV('値引・割引取込情報', orgCode);
  }, [orgCode]);


  const closeConfirmResendModal = useCallback(() => {
    setConfirmModalResendOpen(false);
  }, []);

  const openConfirmModal = useCallback((advertising : PriceWithrowalDomain) => {
    setConfirmModalOpen(true);
    setSelectedObj(advertising);
  }, []);

  const deleteUpdateHoliday = useCallback(() => {
    const staffName = sessionStorage.getItem('loginUser.staffName') || '';
    const submitObj = {
      id: {
        companyCode: selectedObj.companyCode,
        orgCode,
        posType: selectedObj.posType.toString(),
        discountTypeCode: selectedObj.discountTypeCode,
      },
      applyStartDayStr: selectedObj.applicableStartMonthStr,
      applyEndDayStr: selectedObj.applicableEndMonthStr,
      createUser: staffName,
      updateUser: staffName,
    };
    deletePriceWithdrawal(confirmModalResendOpen ? 1 : 0, submitObj).then((Response: any) => {
      setpriceWidthdrawalItemList(priceWidthdrawalItemList.filter(
        (priceWidthdrawal) => priceWidthdrawal.discountTypeCode !== selectedObj.discountTypeCode
        || priceWidthdrawal.posType !== selectedObj.posType || priceWidthdrawal.discountTypeCode !== selectedObj.discountTypeCode,
      ));
      setConfirmModalOpen(false);
      setConfirmModalResendOpen(false);
      successNotification('削除しました。');
    }).catch((error) => {
      setConfirmModalOpen(false);
      if (error.response.status === 406) {
        const errorString = '既にPOSデータが取り込まれています。削除するとレポートや帳票で売上の金額が合わなくなる可能性がありますが、よろしいですか？';
        setConfirmModalResendOpen(true);
        setConfirmModalContent(errorString);
      } else {
        if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
          let errorString : string = '';
          error.response.data.errors.forEach((errorStr: { defaultMessage: any; }) => { errorString = `${errorString}${errorStr.defaultMessage}</br>`; });
          errorNotification(errorString);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
        setToastModalOpen(true);
      }
    });
  }, [selectedObj.companyCode, selectedObj.posType, selectedObj.discountTypeCode, selectedObj.applicableStartMonthStr, selectedObj.applicableEndMonthStr, orgCode, confirmModalResendOpen, setpriceWidthdrawalItemList, priceWidthdrawalItemList, successNotification, errorNotification]);

  const columns: Array<Column<PriceWithrowalDomain>> = useMemo(() => [
    {
      Header: '値引・割引種別',
      accessor: 'discountTypeMstName',
      sortType: 'basic'
    },
    {
      Header: 'POS',
      accessor: 'posName',
    },
    {
      Header: '取込コード',
      accessor: 'discountTypeCode',
    },
    {
      Header: '適用開始月',
      accessor: 'applicableStartMonthStr',
    },
    {
      Header: '適用終了月',
      accessor: 'applicableEndMonthStr',
    },
    {
      Header: () => null,
      accessor: 'discountTypeMstCode',
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: PriceWithrowalDomain },
        data: Array<PriceWithrowalDomain>
        }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/discountTypeMst/priceWithdrawal/edit/${cell.row.original.discountTypeCode}/${cell.row.original.posType}/${cell.row.original.discountTypeMstCode}/${cell.row.original.applicableStartMonthFormat}/${orgCode}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            {
              roleScreen && roleScreen.editable === 1 && (
                <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                  <span
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                    onMouseEnter={() => setIsHoverDelete(true)}
                    onMouseLeave={() => setIsHoverDelete(false)}
                  >
                    <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </Link>
              )
            }
          </div>
        );
      },
    },
  ], [openConfirmModal, orgCode, roleScreen]);
  sessionStorage.getItem('accountTitleIdFirst');
  return (
    <div>
      <div style={{ marginTop: '12px' }}>
        <FlexBox>
          {
            roleScreen && roleScreen.downloadFlag === 1 && (
              <FlexBoxItem>
                <IconLabelButton
                  onClick={downloadFileCSV}
                  iconType="download"
                  text="CSVダウンロード"
                />
              </FlexBoxItem>
            )
          }
          {
            roleScreen && roleScreen.importFlag === 1 && (
              <FlexBoxItem>
                <Link to="/discountTypeMst/priceWithdrawal/import/">
                  <PrimaryButton
                    ghost={false}
                    text="インポート"
                  />
                </Link>
              </FlexBoxItem>
            )
          }
          {
            roleScreen && roleScreen.editable === 1 && (
              <FlexBoxItem grow={1}>
                <div style={{ textAlign: 'right' }}>
                  <Link to={`/discountTypeMst/priceWithdrawal/add/${orgCode}`}>
                    <PrimaryButton
                      ghost={false}
                      text="新規登録"
                    />
                  </Link>
                </div>
              </FlexBoxItem>
            )
          }
        </FlexBox>
        <div style={{ marginTop: '16px', marginLeft: '16px' }}>
          <SelectForm
            label="組織名："
            name="orgCode"
            value={orgCode}
            setValue={(v) => {
              sessionStorage.setItem('selectedOrgCode', v);
              setorgCode(v);
            }}
            options={orgTreesOptions}
            description=""
          />

        </div>
      </div>
      <div
        css={css`
        table th:nth-child(1) {
          width: 200px;
        }
        table th:nth-child(2) {
          width: 150px;
        }
        table th:nth-child(3) {
          width: 100px;
        }
        table th:nth-child(4) {
          width: 100px;
        }
        table th:nth-child(5) {
          width: 100px;
        }
        table th:nth-child(6) {
          width: 80px;
        }
        `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
          <DataTable
            columns={columns}
            data={priceWidthdrawalItemList}
            isGlobalFilter={true}
            sortBy={[
              { id: 'discountTypeMstName', desc: false }
            ]}
          />
        </div>
      </div>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={deleteUpdateHoliday}
        actionType={ActionType.DELETE}
      />
      <ConfirmModal
        open={confirmModalResendOpen}
        closeHandler={closeConfirmResendModal}
        onSubmit={deleteUpdateHoliday}
        title="確認"
        content={confirmModalContent}
        submitText="削除"
        items={[]}
      />
      <ToastModal
        open={toastModalOpen}
        closeHandler={() => setToastModalOpen(false)}
        title={toastMessage}
      />
    </div>

  );
};

export default PriceWithdrawalPage;
