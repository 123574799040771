import ApiClient from 'api/ApiClient';
import { IAttendAchievementError } from './interface';

export const getAttendAchievementError = async (
  orgCode: string,
  categoryAryStr: any,
  targetDateFrom: string,
  targetDateTo: string,
  isOnlyError: number,
  toSearchContinuousWorkAlert: string,
  toSearchOvertimeAlert: string,
  toSearchHolidayworkAlert: string,
  toSearchRecessAlert: string,
  toSearchUnder18MidnightWorkAlert: string,
  toSearchForeignerAlert: string,

): Promise<IAttendAchievementError[]> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const functionType = 2;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAryStr)) {
    categoryAryStr.forEach((cateArr: any) => {
      appendUrl += `&categoryAry=${cateArr}`;
    });
  }

  if ([toSearchContinuousWorkAlert,
    toSearchOvertimeAlert,
    toSearchHolidayworkAlert,
    toSearchRecessAlert,
    toSearchUnder18MidnightWorkAlert,
    toSearchForeignerAlert].every(i => i === "0")) {
    toSearchContinuousWorkAlert = "1"
    toSearchOvertimeAlert = "1"
    toSearchHolidayworkAlert = "1"
    toSearchRecessAlert = "1"
    toSearchUnder18MidnightWorkAlert = "1"
    toSearchForeignerAlert = "1"
  }

  const { data } = await ApiClient.get(`/v2/attendAchievementError/${companyCode}?functionType=${functionType}&orgCode=${orgCode}&targetDateFrom=${targetDateFrom}&targetDateTo=${targetDateTo}&isOnlyError=${isOnlyError}&toSearchContinuousWorkAlert=${toSearchContinuousWorkAlert}&toSearchOvertimeAlert=${toSearchOvertimeAlert}&toSearchHolidayworkAlert=${toSearchHolidayworkAlert}&toSearchRecessAlert=${toSearchRecessAlert}&toSearchUnder18MidnightWorkAlert=${toSearchUnder18MidnightWorkAlert}&toSearchForeignerAlert=${toSearchForeignerAlert}&loginStaffCode=${loginStaffCode}${appendUrl}`, {});

  return data;
};
