import {
  deleteAdverPlan,
  deleteAdvertising,
  downloadCSVOrganization,
  getListAdvertising,
  getListAdvertisingPlanOrganization,
  updateEndDateAdvertising,
} from 'api/advertising';
import AdvertisingPlanDomain from 'domain/advertisingplanorganization';
import { useFormik } from 'formik';
import useToastNotification from 'hooks/useToastNotification';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

interface IUpdateField {
  orgCode: string,
  orgName: string,
  advertisingPlanMstCode: string,
  advertisingPlanMstName: string,
  applyStartDate: string
}

export const useStoreSettingPage = () => {
  const [mediaMasterList, setMediaMasterList] = useState < Array<AdvertisingPlanDomain>>([]);
  const [orgCode, setOrgCode] = useState('all' || '');
  const fromdate = new Date();
  const dateTo = (new Date(fromdate.getFullYear(), fromdate.getMonth(), fromdate.getDate()));
  const [targetDateFrom, setTargetDateFrom] = useState(null);
  const [targetDateTo, setTargetDateTo] = useState(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listChecked, setListChecked] = useState<Array<IUpdateField>>([]);
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalUpdate, setConfirmModalUpdate] = useState(false);
  const [confirmModalDelet, setConfirmModalDelete] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const functionType = 1;
  const [checked, setChecked] = useState(true);
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [confirmModalResendOpen] = useState(false);
  const [selectedObj, setSelectedObj] = useState(AdvertisingPlanDomain.generateInitial());
  const [categoryArray, setCategoryAr] = useState<Array<any> | null>(
    null,
  );
  const initValue = {
    notSubjectToAudit: true,
  };

  const getListAdvertisingPlan = useCallback(() => {
    if (orgCode) {
      getListAdvertisingPlanOrganization(`${orgCode}`, checked, targetDateFrom, targetDateTo).then((response: Array<any>) => {
        if (checked === false) {
          response.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            if (item.applyEndDay.substring(0, 4) === '9999') item.applyEndDay = '－';
          });
          setMediaMasterList(response.map(
            (result) => new AdvertisingPlanDomain(result),
          ));
        } else {
          response.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            if (item.applyEndDay.substring(0, 4) === '9999') item.applyEndDay = '－';
          });
          setMediaMasterList(response.filter((item) => item.applyEndDay >= moment(new Date()).format('YYYY-MM-DD')).map(
            (result) => new AdvertisingPlanDomain(result),
          ));
        }
        setIsLoading(false);
      });
    }
  }, [checked, orgCode, targetDateFrom, targetDateTo]);

  useEffect(() => {
    getListAdvertisingPlan();
    getListAdvertising().then((response: Array<any>) => {
      const listAdverName = _.map(response,
        ({ advertisingPlanMstName, advertisingPlanMstCode }) => ({
          label: advertisingPlanMstName,
          value: advertisingPlanMstCode,
        }));
      localStorage.setItem('OptionAdvertising', JSON.stringify(listAdverName));
    });
  }, [getListAdvertisingPlan, categoryArray]);

  useEffect(() => {
    if (listChecked.length === 0) {
      setEndDate(null);
    }
  }, [listChecked.length]);

  const deleteAdvertisingPlan = useCallback(() => {
    const submitObj = {
      orgCode: selectedObj.orgCode,
      applyStartDate: selectedObj.applyStartDay,
      advertisingPlanMstCode: selectedObj.advertisingPlanMstCode,
    };
    deleteAdverPlan(confirmModalResendOpen ? '1' : submitObj).then((Response: any) => {
      setMediaMasterList(mediaMasterList.filter(
        (mediaMaster) => mediaMaster.advertisingPlanMstCode !== selectedObj.advertisingPlanMstCode,
      ));
      setConfirmModalOpen(false);
      successNotification('削除しました。');
    }).catch((error) => {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else if ((error.response && error.response.data && error.response.data.message)) {
        const stringErr = error.response.data.message;
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    });
  }, [confirmModalResendOpen,
    errorNotification,
    mediaMasterList,
    selectedObj.advertisingPlanMstCode,
    selectedObj.applyStartDay,
    selectedObj.orgCode,
    successNotification]);

  // Update data Advertising
  const submitUpdate = useCallback((type:number) => {
    const FormSubmit = {
      advertisingPlanOrganizationList: listChecked,
      applyEndDate: endDate !== null ? moment(endDate).format('YYYY/MM/DD') : '',
      createUser: staffName,
      updateUser: staffName,
    };
    if (type === 1) {
      updateEndDateAdvertising(FormSubmit).then(() => {
        successNotification('更新しました。');
        setConfirmModalUpdate(false);
        getListAdvertisingPlan();
        setListChecked([]);
        setEndDate(null);
      }).catch((error: any) => {
        setConfirmModalUpdate(false);
        if (error.response && error.response.data) {
          if (error.response.data.defaultMessage !== null) {
            errorNotification(`${error.response.data.defaultMessage}`);
          } else {
            errorNotification(`${error.response.data.errors[0].defaultMessage}`);
          }
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    } else {
      deleteAdvertising(FormSubmit).then(() => {
        successNotification('更新しました。');
        setConfirmModalDelete(false);
        getListAdvertisingPlan();
        setListChecked([]);
      }).catch((error: any) => {
        setConfirmModalDelete(false);
        if (error.response && error.response.data) {
          if (error.response.data.defaultMessage !== null) {
            errorNotification(`${error.response.data.defaultMessage}`);
          } else {
            errorNotification(`${error.response.data.errors[0].defaultMessage}`);
          }
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    }
  }, [endDate,
    errorNotification,
    getListAdvertisingPlan,
    listChecked,
    staffName,
    successNotification]);

  // Download AdvertisingOrganization
  const downloadFileCSV = useCallback(() => {
    const queryForm = {
      orgCode,
      categoryArray,
      applyStartDateFrom: targetDateFrom,
      applyStartDateTo: targetDateTo,
      functionType,
      checked,
    };
    downloadCSVOrganization('広告プラン店舗設定', queryForm);
  }, [categoryArray, checked, orgCode, targetDateFrom, targetDateTo]);

  const openConfirmModal = useCallback((val : AdvertisingPlanDomain) => {
    setConfirmModalOpen(true);
    setSelectedObj(val);
  }, [setConfirmModalOpen, setSelectedObj]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
    setConfirmModalUpdate(false);
    setConfirmModalDelete(false);
  }, [setConfirmModalOpen]);

  const onSubmit = async (val: any) => {
    console.log('this Get value form from formik');
  };

  const formik = useFormik({
    initialValues: initValue,
    onSubmit,
  });

  const history = useHistory();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true
  && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  return {
    formik,
    listChecked,
    orgCode,
    setOrgCode,
    setListChecked,
    submitUpdate,
    isLoading,
    setIsLoading,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    endDate,
    setEndDate,
    dateTo,
    confirmModalOpen,
    setConfirmModalOpen,
    confirmModalUpdate,
    setConfirmModalUpdate,
    confirmModalDelet,
    setConfirmModalDelete,
    closeConfirmModal,
    functionType,
    staffCode,
    successNotification,
    errorNotification,
    checked,
    setChecked,
    mediaMasterList,
    setMediaMasterList,
    deleteAdvertisingPlan,
    setSelectedObj,
    downloadFileCSV,
    openConfirmModal,
    setCategoryAr,
    checkUserRole,
  };
};

export default useStoreSettingPage;
