import ApiClient from './ApiClient';

export interface SalaryCsvSetting {
  companyCode: string;
  createUser: string;
  updateUser: string;
  formatId: string;
  formatType: number;
}

/**
 * getPayslipCsvFormat
 *
 * @param formatType
 *
 */
export const getPayslipCsvFormat = async (formatType: number): Promise<Array<SalaryCsvSetting>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    formatType,
  };
  const response = await ApiClient.get(`/v1/payslip/csv/format/${companyCode}`, query);
  return response.data;
};

export const savePayslipCsvFormat = async (companyCode: string, dataPayslipCsvFormat: any) => {
  // TODO パラメータ確認
  const query = {
  };
  const response = await ApiClient.post(`/v1/payslip/csv/format/${companyCode}`, query, dataPayslipCsvFormat);
  return response.data;
};

export default {
  getPayslipCsvFormat,
};
