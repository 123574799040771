import React, { useState, useEffect } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FileSelectButton from 'components/molecules/FileSelectButton';
import FormTitle from 'components/atoms/Form/FormTitle';
import BodyText from 'components/atoms/BodyText';
import { utilityColor } from 'components/styles';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import BlockUI from 'components/molecules/BlockUi';
import useSaleBudgetImport from './hooks';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const SalesBudgetYearImportLayout = () => {
  if (!sessionStorage.getItem('dateSelect')) {
    sessionStorage.setItem('dateSelect', `${new Date().getFullYear()}`);
  }

  const historyRedirect = useHistory();

  const dateSelect = sessionStorage.getItem('dateSelect') || '';
  const destinationOrg = sessionStorage.getItem('destination_org') || '';

  const [selectedYear] = useState(Number(dateSelect));

  const {
    inputValue, setUploadFile, uploadFile, onSubmit, errors, confirmModalOpen, closeConfirmModal, handleSubmit, blocking,
  } = useSaleBudgetImport();

  const handleClick = () => {
    // eslint-disable-next-line no-restricted-globals
    history.back();
    sessionStorage.setItem('isBack', 'true');
  };

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  useEffect(() => {
    if (roleScreen && roleScreen.available === false) {
      historyRedirect.push('/404')
    }
  }, [roleScreen])

  return (
    <div>
      <BlockUI blocking={blocking}>
        <FormContents>
          <FormTitle
            title="登録するデータ"
          />
          <p>
            会計年度：
            <strong>
              {selectedYear}
              {'年 '}
            </strong>
          </p>
          <p>
            組織名：
            <strong>
              {destinationOrg}
            </strong>
          </p>
          <FormField>
            <FileSelectButton
              text="Excelファイルをドラッグ＆ドロップ  または  クリックしてファイルを選択してください"
              ghost={true}
              setFile={setUploadFile}
              value={inputValue}
              accept=".xlsx,.xls"
            />
          </FormField>
          {uploadFile || errors
            ? (
              <FormField
                displayBlock={true}
              >
                <div>
                  {uploadFile?.name}
                </div>
                {errors && errors.map((error) => (
                  <div key={error}>
                    <BodyText color={utilityColor.error}>
                      {error}
                    </BodyText>
                  </div>
                ))}
              </FormField>
            )
            : null}
          <FormSubmitArea>
            { 
              roleScreen && roleScreen.importFlag === 1 && (
              <PrimaryButton
                text="インポート"
                onClick={handleSubmit}
                disabled={!uploadFile}
              />
            )}

            <PrimaryButton
              text="戻る"
              ghost={true}
              onClick={handleClick}
            />
          </FormSubmitArea>
        </FormContents>

      </BlockUI>

      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        title="確認メッセージ"
        onSubmit={onSubmit}
        content="ファイルをアップロードします。よろしいですか？"
        items={[]}
      />
    </div>
  );
};
export default SalesBudgetYearImportLayout;
