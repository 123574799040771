import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { css } from '@emotion/core';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import FormContents from 'components/atoms/Form/FormContents';
import DatePickerForm from 'components/molecules/DatePickerForm';
import Icon from 'components/atoms/Icon';
import useToastNotification from 'hooks/useToastNotification';
import TextForm from 'components/molecules/TextForm';
import FormField from 'components/atoms/Form/FormField';
import OrganizationInputForm from 'components/organismos/organization/OrganizationInputForm';
import StoreBasicInformationForm from 'components/organismos/organization/OrganizationInputForm/StoreBasicInformationForm';
import BankForm from 'components/organismos/organization/OrganizationInputForm/BankForm';
import LicenseForm from 'components/organismos/organization/OrganizationInputForm/LicenseForm';
import TerminalEquipmentSafeForm from 'components/organismos/organization/OrganizationInputForm/TerminalEquipmentSafeForm';
import BuildingManagementForm from 'components/organismos/organization/OrganizationInputForm/BuildingManagementForm';
import FacilityForm from 'components/organismos/organization/OrganizationInputForm/FacilityForm';
import InsuranceBankOtherForm from 'components/organismos/organization/OrganizationInputForm/InsuranceBankOtherForm';
import OrganizationManagerEditForm from 'components/organismos/organization/OrganizationManagerEditForm';
import OrganizationSpecialSalaryEditForm from 'components/organismos/organization/OrganizationSpecialSalaryEditForm';

import BlockUI from 'components/molecules/BlockUi';
import { useOrganizationEdit } from './hooks';
import ScrollMenuComponent from 'components/organismos/MenuScrollVerical';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import SelectFormVirtical from 'components/molecules/SelectFormVirtical';
import Checkbox from 'components/molecules/Checkbox';
import useCompanyBankOptions from 'hooks/useCompanyBankOptions';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

export type TableColumnsType = {
  name: string;
  width?: string;
  visible: boolean;
  content: (rowIndex: number, colIndex: number) => JSX.Element;
};

const OrganizationEditPage: React.FC = () => {
  const {
    organizationDomain,
    setOrganizationDomain,
    managerList,
    setManagerList,
    seniorManagerList,
    setSeniorManagerList,
    historyList,
    setHistoryList,
    blocking,
    orgCode,
    organizationDetail,
    fetchData,
    setBlocking,
    updateOrganization,
    handleDeleteSpecialWageSetting,
    arrCompanyBank,
    arrInputChange,
    setArrInputChange,
  } = useOrganizationEdit();

  const companyBankOptions = useCompanyBankOptions();

  const [openDetailForm] = useState<boolean>(true);

  const defaultNameList = [
    {
      name: ''
    },
  ];

  const defaultCompanyBank = [
    {
      organizationBankId: '',
      applyStartDate: moment().format('YYYY/MM/DD'),
      applyEndDate: '',
      lineNo: 0,
      bankCode: companyBankOptions && companyBankOptions.length > 0 ? companyBankOptions[0].value : '',
      otherBankName: '',
      registrationNo: ''
    },
  ];

  const defaultReducer: any = {
    orgCode: organizationDetail?.organizations.orgCode || '',
    orgName: organizationDetail?.organizations.orgName || '',
    orgNameKana: organizationDetail?.organizations.orgNameKana || '',
    organizationCategoryMstDetilId: organizationDetail?.organizations.organizationCategoryMstDetilId || [],
    closeFlag: organizationDetail?.organizations.closeFlag === true ? 1 : 0,
    applyStartDate: moment(organizationDetail?.organizations.applyStartDate || new Date()).format("YYYY/MM/DD"),
    applyEndDate: moment(organizationDetail?.organizations.applyEndDate || new Date()).format("YYYY/MM/DD"),
    employmentId: organizationDetail?.organizations.employmentId || '',
    workingDaysId: organizationDetail?.organizations.workingDaysId || '',
    posCode: organizationDetail?.organizations.posCode || '',
    infomartLinkCode: organizationDetail?.organizations.infomartLinkCode || '',
    dispOrder: organizationDetail?.organizations.dispOrder || 0,
    managerList: organizationDetail?.managers.managerList || [],
    seniorManagerList: organizationDetail?.managers.seniorManagerList || [],
    selectedOrgSpecialList: organizationDetail?.orgSpecialList.length > 0 ? organizationDetail?.orgSpecialList[0] : {
      applyStartMonthStr: moment(new Date()).startOf('month').format("YYYY年MM月"),
      id: {
        applyStartMonth: moment(new Date()).startOf('month').format("YYYY/MM/DD")
      }
    },
    applyStartMonth: moment(organizationDetail?.orgSpecialList.length > 0 ? organizationDetail?.orgSpecialList[0].id.applyStartMonth : new Date()).startOf('month').format("YYYY/MM/DD"),
    specialSalaryPatternId: organizationDetail?.orgSpecialList.length > 0 ? organizationDetail?.orgSpecialList[0].specialSalaryPatternId : '',
    addSalary: organizationDetail?.orgSpecialList.length > 0 ? organizationDetail?.orgSpecialList[0].addSalary : 0,
    zipCode: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.zipCode !== null ? organizationDetail?.basicInformation.zipCode : '',
    prefectureCode: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.prefectureCode !== null ? organizationDetail?.basicInformation.prefectureCode : '',
    address: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.address !== null ? organizationDetail?.basicInformation.address : '',
    tel1: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.tel1 !== null ? organizationDetail?.basicInformation.tel1 : '',
    tel2: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.tel2 !== null ? organizationDetail?.basicInformation.tel2 : '',
    tel3: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.tel3 !== null ? organizationDetail?.basicInformation.tel3 : '',
    fax1: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.fax1 !== null ? organizationDetail?.basicInformation.fax1 : '',
    fax2: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.fax2 !== null ? organizationDetail?.basicInformation.fax2 : '',
    fax3: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.fax3 !== null ? organizationDetail?.basicInformation.fax3 : '',
    operatingCompany: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.operatingCompany !== null ? organizationDetail?.basicInformation.operatingCompany : '',
    operatingCompanyRepresentative: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.operatingCompanyRepresentative !== null ? organizationDetail?.basicInformation.operatingCompanyRepresentative : '',
    operatingCompanyRepresentativeTel: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.operatingCompanyRepresentativeTel !== null ? organizationDetail?.basicInformation.operatingCompanyRepresentativeTel : '',
    entryMethod: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.entryMethod !== null ? organizationDetail?.basicInformation.entryMethod : '',
    squareMeters: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.squareMeters !== null ? parseFloat(organizationDetail?.basicInformation.squareMeters).toFixed(2) : '',
    sharedSpaceSquareMeters: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.sharedSpaceSquareMeters !== null ? parseFloat(organizationDetail?.basicInformation.sharedSpaceSquareMeters).toFixed(2) : '',
    floorSpace: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.floorSpace !== null ? parseFloat(organizationDetail?.basicInformation.floorSpace).toFixed(2) : '',
    numberOfSeat: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.numberOfSeat !== null ? organizationDetail?.basicInformation.numberOfSeat : 0,
    shopManager: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.shopManager !== null ? organizationDetail?.basicInformation.shopManager : '',
    shopManagerTel: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.shopManagerTel !== null ? organizationDetail?.basicInformation.shopManagerTel : '',
    posContact: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.posContact !== null ? organizationDetail?.basicInformation.posContact : '',
    posContactTel: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.posContactTel !== null ? organizationDetail?.basicInformation.posContactTel : '',
    headChef: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.headChef !== null ? organizationDetail?.basicInformation.headChef : '',
    businessHoursMon: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.businessHoursMon !== null ? organizationDetail?.basicInformation.businessHoursMon : '',
    businessHoursTue: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.businessHoursTue !== null ? organizationDetail?.basicInformation.businessHoursTue : '',
    businessHoursWed: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.businessHoursWed !== null ? organizationDetail?.basicInformation.businessHoursWed : '',
    businessHoursThu: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.businessHoursThu !== null ? organizationDetail?.basicInformation.businessHoursThu : '',
    businessHoursFri: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.businessHoursFri !== null ? organizationDetail?.basicInformation.businessHoursFri : '',
    businessHoursSat: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.businessHoursSat !== null ? organizationDetail?.basicInformation.businessHoursSat : '',
    businessHoursSun: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.businessHoursSun !== null ? organizationDetail?.basicInformation.businessHoursSun : '',
    businessHoursHoliday: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.businessHoursHoliday !== null ? organizationDetail?.basicInformation.businessHoursHoliday : '',
    businessHoursLunch: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.businessHoursLunch !== null ? organizationDetail?.basicInformation.businessHoursLunch : '',
    closingDay: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.closingDay !== null ? organizationDetail?.basicInformation.closingDay : '',
    nameList: organizationDetail?.basicInformation !== null && organizationDetail?.basicInformation.nameList !== undefined && organizationDetail?.basicInformation.nameList.length > 0 ? organizationDetail?.basicInformation.nameList : [],
    businessLicenseStartDay: organizationDetail?.permit !== null && organizationDetail?.permit.businessLicenseStartDay !== null ? moment(organizationDetail?.permit.businessLicenseStartDay).format("YYYY/MM/DD") : '',
    businessLicenseEndDay: organizationDetail?.permit !== null && organizationDetail?.permit.businessLicenseEndDay !== null ? moment(organizationDetail?.permit.businessLicenseEndDay).format("YYYY/MM/DD") : '',
    foodHygieneOfficer: organizationDetail?.permit !== null && organizationDetail?.permit.foodHygieneOfficer !== null ? organizationDetail?.permit.foodHygieneOfficer : '',
    healthCenter: organizationDetail?.permit !== null && organizationDetail?.permit.healthCenter !== null ? organizationDetail?.permit.healthCenter : '',
    foodHygieneOfficerTel: organizationDetail?.permit !== null && organizationDetail?.permit.foodHygieneOfficerTel !== null ? organizationDetail?.permit.foodHygieneOfficerTel : '',
    healthCenterTel: organizationDetail?.permit !== null && organizationDetail?.permit.healthCenterTel !== null ? organizationDetail?.permit.healthCenterTel : '',
    tradeName: organizationDetail?.permit !== null && organizationDetail?.permit.tradeName !== null ? organizationDetail?.permit.tradeName : '',
    businessLicenseOperatingCompanyRepresentative: organizationDetail?.permit !== null && organizationDetail?.permit.businessLicenseOperatingCompanyRepresentative !== null ? organizationDetail?.permit.businessLicenseOperatingCompanyRepresentative : '',
    firePreventionManagementResponsible: organizationDetail?.permit !== null && organizationDetail?.permit.firePreventionManagementResponsible !== null ? organizationDetail?.permit.firePreventionManagementResponsible : '',
    fireStation: organizationDetail?.permit !== null && organizationDetail?.permit.fireStation !== null ? organizationDetail?.permit.fireStation : '',
    firePreventionManagementResponsibleTel: organizationDetail?.permit !== null && organizationDetail?.permit.firePreventionManagementResponsibleTel !== null ? organizationDetail?.permit.firePreventionManagementResponsibleTel : '',
    fireStationTel: organizationDetail?.permit !== null && organizationDetail?.permit.fireStationTel !== null ? organizationDetail?.permit.fireStationTel : '',
    firePreventionObjectInspector: organizationDetail?.permit !== null && organizationDetail?.permit.firePreventionObjectInspector !== null ? organizationDetail?.permit.firePreventionObjectInspector : '',
    buildingManagementNote: organizationDetail?.permit !== null && organizationDetail?.permit.buildingManagementNote !== null ? organizationDetail?.permit.buildingManagementNote : '',
    firePreventionObjectInspectionDate: organizationDetail?.permit !== null && organizationDetail?.permit.firePreventionObjectInspectionDate !== null ? moment(organizationDetail?.permit.firePreventionObjectInspectionDate).format("YYYY/MM/DD") : '',
    fireInspectionDate: organizationDetail?.permit !== null && organizationDetail?.permit.fireInspectionDate !== null ? moment(organizationDetail?.permit.fireInspectionDate).format("YYYY/MM/DD") : '',
    lateNightAlcoholOfferNotificationType: organizationDetail?.permit !== null && organizationDetail?.permit.lateNightAlcoholOfferNotificationType !== null ? organizationDetail?.permit.lateNightAlcoholOfferNotificationType : '',
    catNo: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.catNo !== null ? organizationDetail?.terminalEquipmentSafe.catNo : '',
    creditTerminalNo: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.creditTerminalNo !== null ? organizationDetail?.terminalEquipmentSafe.creditTerminalNo : '',
    providerId: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.providerId !== null ? organizationDetail?.terminalEquipmentSafe.providerId : '',
    providerPassword: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.providerPassword !== null ? organizationDetail?.terminalEquipmentSafe.providerPassword : '',
    wifiId: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.wifiId !== null ? organizationDetail?.terminalEquipmentSafe.wifiId : '',
    wifiPassword: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.wifiPassword !== null ? organizationDetail?.terminalEquipmentSafe.wifiPassword : '',
    securityCameraQuantity: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.securityCameraQuantity !== null ? organizationDetail?.terminalEquipmentSafe.securityCameraQuantity : '',
    securityCameraMaker: organizationDetail?.terminalEquipmentSafe !== null ? organizationDetail?.terminalEquipmentSafe.securityCameraMaker : '',
    securityCameraModelNo: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.securityCameraModelNo !== null ? organizationDetail?.terminalEquipmentSafe.securityCameraModelNo : '',
    posQuantity: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.posQuantity !== null ? organizationDetail?.terminalEquipmentSafe.posQuantity : '',
    posMaker: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.posMaker !== null ? organizationDetail?.terminalEquipmentSafe.posMaker : '',
    posModelNo: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.posModelNo !== null ? organizationDetail?.terminalEquipmentSafe.posModelNo : '',
    posCustomerNo: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.posCustomerNo !== null ? organizationDetail?.terminalEquipmentSafe.posCustomerNo : '',
    posRemark: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.posRemark !== null ? organizationDetail?.terminalEquipmentSafe.posRemark : '',
    handyQuantity: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.handyQuantity !== null ? organizationDetail?.terminalEquipmentSafe.handyQuantity : '',
    handyConservative: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.handyConservative !== null ? organizationDetail?.terminalEquipmentSafe.handyConservative : '',
    handyMaker: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.handyMaker !== null ? organizationDetail?.terminalEquipmentSafe.handyMaker : '',
    handyConservativePeriod: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.handyConservativePeriod !== null ? organizationDetail?.terminalEquipmentSafe.handyConservativePeriod : '',
    handyModelNo: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.handyModelNo !== null ? organizationDetail?.terminalEquipmentSafe.handyModelNo : '',
    cashboxManager1: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.cashboxManager1 !== null ? organizationDetail?.terminalEquipmentSafe.cashboxManager1 : '',
    cashboxPassword1: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.cashboxPassword1 !== null ? organizationDetail?.terminalEquipmentSafe.cashboxPassword1 : '',
    cashboxManager2: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.cashboxManager2 !== null ? organizationDetail?.terminalEquipmentSafe.cashboxManager2 : '',
    cashboxPassword2: organizationDetail?.terminalEquipmentSafe !== null && organizationDetail?.terminalEquipmentSafe.cashboxPassword2 !== null ? organizationDetail?.terminalEquipmentSafe.cashboxPassword2 : '',
    buildingOwner: organizationDetail?.buildingManagement !== null && organizationDetail?.buildingManagement.buildingOwner !== null ? organizationDetail?.buildingManagement.buildingOwner : '',
    buildingOwnerAddress: organizationDetail?.buildingManagement !== null && organizationDetail?.buildingManagement.buildingOwnerAddress !== null ? organizationDetail?.buildingManagement.buildingOwnerAddress : '',
    buildingOwnerTel: organizationDetail?.buildingManagement !== null && organizationDetail?.buildingManagement.buildingOwnerTel !== null ? organizationDetail?.buildingManagement.buildingOwnerTel : '',
    buildingManagementCompany: organizationDetail?.buildingManagement !== null && organizationDetail?.buildingManagement.buildingManagementCompany !== null ? organizationDetail?.buildingManagement.buildingManagementCompany : '',
    buildingManagementCompanyZipCode: organizationDetail?.buildingManagement !== null && organizationDetail?.buildingManagement.buildingManagementCompanyZipCode !== null ? organizationDetail?.buildingManagement.buildingManagementCompanyZipCode : '',
    buildingManagementCompanyAddress: organizationDetail?.buildingManagement !== null && organizationDetail?.buildingManagement.buildingManagementCompanyAddress !== null ? organizationDetail?.buildingManagement.buildingManagementCompanyAddress : '',
    buildingManagementCompanyTel: organizationDetail?.buildingManagement !== null && organizationDetail?.buildingManagement.buildingManagementCompanyTel !== null ? organizationDetail?.buildingManagement.buildingManagementCompanyTel : '',
    buildingManagementCompanyFax: organizationDetail?.buildingManagement !== null && organizationDetail?.buildingManagement.buildingManagementCompanyFax !== null ? organizationDetail?.buildingManagement.buildingManagementCompanyFax : '',
    buildingManagementCompanyContact: organizationDetail?.buildingManagement !== null && organizationDetail?.buildingManagement.buildingManagementCompanyContact !== null ? organizationDetail?.buildingManagement.buildingManagementCompanyContact : '',
    buildingManagementCompanyContactTel: organizationDetail?.buildingManagement !== null && organizationDetail?.buildingManagement.buildingManagementCompanyContactTel !== null ? organizationDetail?.buildingManagement.buildingManagementCompanyContactTel : '',
    leaseContractStartDate: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractStartDate !== null ? moment(organizationDetail?.facility.leaseContractStartDate).format("YYYY/MM/DD") : '',
    leaseContractEndDate: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractEndDate !== null ? moment(organizationDetail?.facility.leaseContractEndDate).format("YYYY/MM/DD") : '',
    leaseContractRent: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractRent !== null ? organizationDetail?.facility.leaseContractRent : '',
    leaseContractYears: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractYears !== null ? organizationDetail?.facility.leaseContractYears : '',
    leaseContractCommonServiceFee: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractCommonServiceFee !== null ? organizationDetail?.facility.leaseContractCommonServiceFee : '',
    leaseContractDeposit: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractDeposit !== null ? organizationDetail?.facility.leaseContractDeposit : '',
    leaseContractOtherPurchaseContent: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractOtherPurchaseContent !== null ? organizationDetail?.facility.leaseContractOtherPurchaseContent : '',
    leaseContractNote: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractNote !== null ? organizationDetail?.facility.leaseContractNote : '',
    leaseContractOtherPurchase: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractOtherPurchase !== null ? organizationDetail?.facility.leaseContractOtherPurchase : '',
    leaseContractReviewDate: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractReviewDate !== null ? moment(organizationDetail?.facility.leaseContractReviewDate).format("YYYY/MM/DD") : '',
    leaseContractStories: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractStories !== null ? organizationDetail?.facility.leaseContractStories : '',
    leaseRenewedContractType: organizationDetail?.facility !== null ? organizationDetail?.facility.leaseRenewedContractType : '',
    leaseRenewalFee: organizationDetail?.facility !== null && organizationDetail?.facility.leaseRenewalFee !== null ? organizationDetail?.facility.leaseRenewalFee : '',
    leaseRenewedContractRemark: organizationDetail?.facility !== null && organizationDetail?.facility.leaseRenewedContractRemark !== null ? organizationDetail?.facility.leaseRenewedContractRemark : '',
    leaseContractExpirationNotice: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractExpirationNotice !== null ? organizationDetail?.facility.leaseContractExpirationNotice : '',
    leaseContractExpirationAmortization: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractExpirationAmortization !== null ? organizationDetail?.facility.leaseContractExpirationAmortization : '',
    leaseContractExpirationReturnRemark: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractExpirationReturnRemark !== null ? organizationDetail?.facility.leaseContractExpirationReturnRemark : '',
    leaseContractCancelNotice: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractCancelNotice !== null ? organizationDetail?.facility.leaseContractCancelNotice : '',
    leaseContractCancelFee: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractCancelFee !== null ? organizationDetail?.facility.leaseContractCancelFee : '',
    leaseContractCancelRemark: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractCancelRemark !== null ? organizationDetail?.facility.leaseContractCancelRemark : '',
    leaseContractRestoreType: organizationDetail?.facility !== null && organizationDetail?.facility.leaseContractRestoreType !== null ? organizationDetail?.facility.leaseContractRestoreType : '',
    insuranceCompany: organizationDetail?.insuranceBankOther !== null && organizationDetail?.insuranceBankOther.insuranceCompany !== null ? organizationDetail?.insuranceBankOther.insuranceCompany : '',
    insuranceCompanyContact: organizationDetail?.insuranceBankOther !== null && organizationDetail?.insuranceBankOther.insuranceCompanyContact !== null ? organizationDetail?.insuranceBankOther.insuranceCompanyContact : '',
    insuranceCompanyContactTel: organizationDetail?.insuranceBankOther !== null && organizationDetail?.insuranceBankOther.insuranceCompanyContactTel !== null ? organizationDetail?.insuranceBankOther.insuranceCompanyContactTel : '',
    insuranceContractStartDate: organizationDetail?.insuranceBankOther !== null && organizationDetail?.insuranceBankOther.insuranceContractStartDate !== null ? moment(organizationDetail?.insuranceBankOther.insuranceContractStartDate).format("YYYY/MM/DD") : '',
    insuranceContractEndDate: organizationDetail?.insuranceBankOther !== null && organizationDetail?.insuranceBankOther.insuranceContractEndDate !== null ? moment(organizationDetail?.insuranceBankOther.insuranceContractEndDate).format("YYYY/MM/DD") : '',
    insuranceContractNo: organizationDetail?.insuranceBankOther !== null ? organizationDetail?.insuranceBankOther.insuranceContractNo : '',
    memo: organizationDetail?.insuranceBankOther !== null ? organizationDetail?.insuranceBankOther.memo : '',
    companyBankList: organizationDetail?.orgBankList && organizationDetail?.orgBankList !== null ? organizationDetail?.orgBankList : []
  };

  const organizations = ['orgCode', 'orgName', 'orgNameKana', 'organizationCategoryMstDetilId', 'closeFlag', 'applyStartDate', 'applyEndDate', 'workingDaysId', 'employmentId', 'posCode', 'infomartLinkCode', 'dispOrder'];
  const managers = ['managerList', 'seniorManagerList'];
  const orgSpecialList = ['applyStartMonth', 'specialSalaryPatternId', 'addSalary'];
  const basicInformation = [
    'zipCode', 'prefectureCode', 'address', 'tel1', 'tel2', 'tel3', 'fax1', 'fax2', 'fax3',
    'operatingCompany', 'operatingCompanyRepresentative', 'operatingCompanyRepresentativeTel', 'entryMethod', 'squareMeters', 'sharedSpaceSquareMeters',
    'floorSpace', 'numberOfSeat', 'shopManager', 'shopManagerTel', 'posContact', 'posContactTel', 'headChef',
    'businessHoursMon', 'businessHoursTue', 'businessHoursWed', 'businessHoursThu', 'businessHoursFri', 'businessHoursSat', 'businessHoursSun',
    'businessHoursHoliday', 'businessHoursLunch', 'closingDay', 'nameList'
  ]
  const permit = [
    'businessLicenseStartDay', 'businessLicenseEndDay', 'foodHygieneOfficer', 'healthCenter', 'foodHygieneOfficerTel', 'healthCenterTel',
    'tradeName', 'businessLicenseOperatingCompanyRepresentative', 'firePreventionManagementResponsible', 'fireStation', 'firePreventionManagementResponsibleTel', 'fireStationTel',
    'firePreventionObjectInspector', 'buildingManagementNote', 'firePreventionObjectInspectionDate', 'fireInspectionDate', 'lateNightAlcoholOfferNotificationType'
  ]
  const terminalEquipmentSafe = [
    'catNo', 'creditTerminalNo', 'providerId', 'providerPassword', 'wifiId', 'wifiPassword', 'securityCameraQuantity', 'securityCameraMaker', 'securityCameraModelNo',
    'posQuantity', 'posMaker', 'posModelNo', 'posCustomerNo', 'posRemark', 'handyQuantity', 'handyConservative', 'handyMaker', 'handyConservativePeriod', 'handyModelNo',
    'cashboxManager1', 'cashboxPassword1', 'cashboxManager2', 'cashboxPassword2'
  ]
  const buildingManagement = [
    'buildingOwner', 'buildingOwnerAddress', 'buildingOwnerTel', 'buildingManagementCompany', 'buildingManagementCompanyZipCode', 'buildingManagementCompanyAddress',
    'buildingManagementCompanyTel', 'buildingManagementCompanyFax', 'buildingManagementCompanyContact', 'buildingManagementCompanyContactTel'
  ]
  const facility = [
    'leaseContractStartDate', 'leaseContractEndDate', 'leaseContractRent', 'leaseContractYears', 'leaseContractCommonServiceFee', 'leaseContractDeposit', 'leaseContractOtherPurchaseContent',
    'leaseContractNote', 'leaseContractOtherPurchase', 'leaseContractReviewDate', 'leaseContractStories', 'leaseRenewedContractType', 'leaseRenewalFee', 'leaseRenewedContractRemark', 'leaseContractExpirationNotice',
    'leaseContractExpirationAmortization', 'leaseContractExpirationReturnRemark', 'leaseContractCancelNotice', 'leaseContractCancelFee', 'leaseContractCancelRemark', 'leaseContractRestoreType'
  ]
  const insuranceBankOther = [
    'insuranceCompany', 'insuranceCompanyContact', 'insuranceCompanyContactTel', 'insuranceContractStartDate', 'insuranceContractEndDate', 'insuranceContractNo',
    'mainBankName1', 'registrationNumber1', 'mainBankName2', 'registrationNumber2', 'otherBankName1', 'registrationNumber3', 'otherBankName2', 'registrationNumber4', 'memo'
  ]

  const orgBankList = ['companyBankList']

  function renderListAddName(number: number){
    const arr = [];
    for (var i = 1; i <= number; i++) {
      arr.push({
        name: ''
      });
    }
  return arr;
}

  const memorizedReducer = useCallback((state: any, { type, payload }: any) => {

    let nameList = [...state.nameList];

    let companyBankList = [...state.companyBankList];

    switch (type) {
      case 'DEFAULT':
        return payload;

      case 'MANAGER_LIST':
        return { ...state, managerList: payload }

      case 'SENIOR_MANAGER_LIST':
        return { ...state, seniorManagerList: payload }

      case "ADD_NAME":
        let newDefaultName = { ...defaultNameList[0] };
        newDefaultName.name = '';
        return { ...state, nameList: [...state.nameList, newDefaultName] };

      case "DELETE_NAME":
        nameList.splice(payload.index, 1);
        return { ...state, nameList };

      case "CHANGE_LIST_NAME":
        let arr = renderListAddName(10);
        nameList[payload.index] = {
          name: payload.name
        };
        nameList.map((item: any, index: number) => {
          if (item !== null) {
            return arr[index] = item;
          }
        });
        return { ...state, nameList: arr };

      case "ADD_COMPANY_BANK":
        let newDefaultCompanyBank = { ...defaultCompanyBank[0] };
        return { ...state, companyBankList: [...state.companyBankList, newDefaultCompanyBank] };

      case "DELETE_COMPANY_BANK":
        companyBankList.splice(payload.index, 1);
        return { ...state, companyBankList };

      case "CHANGE_COMPANY_BANK":
        if (payload.name === 'bankCode' && payload.value !== '0') {
          companyBankList[payload.index]['otherBankName'] = '';
        }
        companyBankList[payload.index][payload.name] = payload.value;
        return { ...state, companyBankList };

      default:
        const result = { ...state }
        if (result.hasOwnProperty(type)) {
          result[type] = payload;
        }
        return result;
    }
  }, [defaultReducer, defaultCompanyBank]);

  const [state, dispatch] = useReducer(memorizedReducer, defaultReducer);

  useEffect(() => {
    dispatch({ type: 'DEFAULT', payload: defaultReducer })
    // setTimeout(() => {
    // setBlocking(false)
    // }, 8000);
  }, [organizationDetail])

  const handleOnChange = (e: any, targetName?: string, targetPayload?: any) => {
    const type = targetName !== undefined ? targetName : e.target.name;
    const payload = targetPayload !== undefined ? targetPayload : e.target.value;
    let inputCheck: any = arrInputChange;
    if (payload !== defaultReducer[type] && inputCheck.includes(type) === false) {
      inputCheck.push(type);
    }
    if (
      payload === defaultReducer[type]
      && inputCheck.includes(type) === true
      && organizations.includes(type) === false
      && managers.includes(type) === false
      && orgSpecialList.includes(type) === false
    ) {
      var index = inputCheck.indexOf(type)
      if (index !== -1) {
        inputCheck.splice(index, 1);
      }
    }
    setArrInputChange(inputCheck);
    dispatch({ type, payload });
  }

  const handleOnBlur = (e: any) => {
    const type = e.target.name;
    const payload = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '';
    dispatch({ type, payload });
  }

  const filterObjectUpdate = (state: any) => {
    const keyState: any = Object.keys(state);
    const newState: any = {
      organizations: {},
      managers: {},
      orgSpecialList: [],
      basicInformation: {},
      permit: {},
      terminalEquipmentSafe: {},
      buildingManagement: {},
      facility: {},
      insuranceBankOther: {},
      orgBankList: [],
    };
    let objOrgSpecialList: any = {
      id: {
        companyCode: "",
        orgCode: "",
        applyStartMonth: ""
      },
      specialSalaryPatternId: "",
      addSalary: 0,
      orgName: "",
      specialSalaryPatternCode: "",
      specialSalaryPatternName: "",
      applyStartMonth: moment(new Date()).format('YYYY/MM/DD'),
    }
    const numberTypeFormat = [
      'closeFlag', 'addSalary', 'dispOrder', 'numberOfSeat',
      'securityCameraQuantity', 'posQuantity', 'handyQuantity', 'handyConservative',
      'leaseContractRent', 'leaseContractCommonServiceFee', 'leaseContractOtherPurchase', 'leaseRenewedContractType', 'leaseRenewalFee',
      'leaseContractExpirationAmortization', 'leaseContractCancelFee', 'leaseContractRestoreType'
    ]
    const doubleTypeFormat = ['squareMeters', 'sharedSpaceSquareMeters', 'floorSpace'];
    const itemArray = ['nameList']
    console.log('arrInputChange', arrInputChange);
    const arrObjPost: any = [];
    keyState.map((item: never) => {
      if (moment(state['applyStartDate']).format('YYYY/MM/DD') !== moment(defaultReducer['applyStartDate']).format('YYYY/MM/DD')
        ? (state[item] !== '' && state[item] !== null && itemArray.includes(item) === false) || (itemArray.includes(item) === true && state[item].length > 0)
        : arrInputChange.includes(item) === true) {
        if (state[item] !== '') {
          if (numberTypeFormat.includes(item) === true) {
            state[item] = Number(state[item]);
          }
          if (doubleTypeFormat.includes(item) === true) {
            state[item] = parseFloat(state[item]).toFixed(2);
          }
        }
        if (organizations.includes(item) === true) {
          newState['organizations'][item] = state[item];
        } else if (managers.includes(item) === true) {
          newState['managers'][item] = state[item];
        } else if (basicInformation.includes(item) === true) {
          //newState['basicInformation'][item] = state[item];
          arrObjPost.push({ basicInformation });
        } else if (permit.includes(item) === true) {
          //newState['permit'][item] = state[item];
          arrObjPost.push({ permit });
        } else if (terminalEquipmentSafe.includes(item) === true) {
          //newState['terminalEquipmentSafe'][item] = state[item];
          arrObjPost.push({ terminalEquipmentSafe });
        } else if (buildingManagement.includes(item) === true) {
          //newState['buildingManagement'][item] = state[item];
          arrObjPost.push({ buildingManagement });
        } else if (facility.includes(item) === true) {
          //newState['facility'][item] = state[item];
          arrObjPost.push({ facility });
        } else if (insuranceBankOther.includes(item) === true) {
          //newState['insuranceBankOther'][item] = state[item];
          arrObjPost.push({ insuranceBankOther });
        } else if (orgBankList.includes(item) === true) {
          newState['orgBankList'] = state[item]
        }
      }
    });

    if (arrObjPost && arrObjPost.length > 0) {
      arrObjPost.map((objPost: any) => {
        Object.keys(objPost).map((elementObj: any) => {
          eval(elementObj).map((element: any) => {
            newState[elementObj][element] = state[element] !== '' ? state[element] : null;
          })
        })
      })
    }
    // if (organizationDetail && organizationDetail.orgSpecialList) {
    //   newState['orgSpecialList'].shift(objOrgSpecialList, ...organizationDetail.orgSpecialList);
    // }
    //track
    objOrgSpecialList = { ...state.selectedOrgSpecialList }
    newState['orgSpecialList'].push(objOrgSpecialList, ...organizationDetail.orgSpecialList);

    const keyNewState: any = Object.keys(newState);
    keyNewState.map((item: never) => {
      if ((Object.keys(newState[item]).length === 0 && item !== 'orgBankList') || (item === 'orgBankList' && arrInputChange.includes('companyBankList') === false)) {
        delete newState[item];
      }
    });

    let msgErrors = '';

    if (newState['orgBankList'] && newState['orgBankList'].length > 0) {
      newState['orgBankList'].map((item: any, index: number) => {
        if (newState['orgBankList'][index].bankCode === '0' && newState['orgBankList'][index].otherBankName === '') {
          msgErrors += `銀行${index + 1}の銀行名を入力してください<br>`;
        }
        newState['orgBankList'][index].lineNo = index + 1
        delete newState['orgBankList'][index].companyCode
        delete newState['orgBankList'][index].orgCode
        delete newState['orgBankList'][index].createUser
        delete newState['orgBankList'][index].createDate
        delete newState['orgBankList'][index].updateUser
        delete newState['orgBankList'][index].updateDate
      })
    }

    if (msgErrors !== '') {
      errorNotification(msgErrors);
      return false;
    }

    if (newState['managers'] && newState['managers']['managerList'].length > 0) {
      newState['managers']['managerList'].map((manager: any, index: number) => {
        newState['managers']['managerList'][index].managerType = 0
      })
    }

    if (newState['managers'] && newState['managers']['seniorManagerList'].length > 0) {
      newState['managers']['seniorManagerList'].map((manager: any, index: number) => {
        newState['managers']['seniorManagerList'][index].managerType = 1
      })
    }

    return newState;
  }

  const handleButtonSubmit = () => {
    const newState = filterObjectUpdate(state);
    console.log('newState', newState);
    if (newState !== false) {
      updateOrganization(newState, newState.organizations.applyStartDate);
    }
  }

  const initStateName = {
    name: "",
  };

  const [addName, setAddName] = useState<any>([]);

  const [addCompanyBank, setAddCompanyBank] = useState<any>([]);

  useEffect(() => {
    setAddName(state?.nameList)
  }, [state?.nameList])

  useEffect(() => {
    setAddCompanyBank(state?.companyBankList)
  }, [state?.companyBankList])

  const [elementCalculate, setElementCalculate] = useState<number>(1);

  const [memoReCalculate, setMemoReCalCulate] = useState<number>(0);

  const { errorNotification } = useToastNotification();

  const deleteCompanyBank = (rowIndex: number) => {
    try {
      const newRows = [...addCompanyBank];
      newRows.splice(rowIndex, 1);
      setAddCompanyBank(newRows);
      dispatch({
        type: "DELETE_COMPANY_BANK",
        payload: {
          index: rowIndex
        }
      });
      let inputCheck: any = arrInputChange;
      if (inputCheck.includes('companyBankList') === false) {
        inputCheck.push('companyBankList');
      }
      setArrInputChange(inputCheck);
      setElementCalculate(elementCalculate - 1);
    } catch (error) {
      errorNotification('サーバー側でエラーが発生しました。');
    }
  };

  const handleChangeListName = (e: any, rowIndex: number) => {
    dispatch({
      type: "CHANGE_LIST_NAME",
      payload: {
        index: rowIndex,
        name: e.target.value,
      },
    });
    let inputCheck: any = arrInputChange;
    if (inputCheck.includes('nameList') === false) {
      inputCheck.push('nameList');
    }
    setArrInputChange(inputCheck);
  }

  const handleChangeCompanyBank = (value: any, rowIndex: number, name: string) => {
    dispatch({
      type: "CHANGE_COMPANY_BANK",
      payload: {
        index: rowIndex,
        value,
        name
      },
    });
    let inputCheck: any = arrInputChange;
    if (inputCheck.includes('companyBankList') === false) {
      inputCheck.push('companyBankList');
    }
    setArrInputChange(inputCheck);
  }

  const tableColumnsName: TableColumnsType[] = [
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
          <FormField displayBlock={true}>
            <TextForm
              name="name"
              label={`名称${rowIndex + 1}`}
              value={String(state.nameList[rowIndex]?.name || '')}
              onChange={(e) => { handleChangeListName(e, rowIndex) }}
            />
          </FormField>
      ),
    }
  ];

  const reNumberic = (value: string) => {
    const re = /^[0-9\b\n]*$/;
    return re.test(value);
  }

  const tableBankCompany: TableColumnsType[] = [
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td style={{ width: '5%', paddingTop: 25 }} key={colIndex}>
          <FormField>
            <span style={{ fontSize: '14px' }}>銀行 {rowIndex + 1}</span>
          </FormField>
        </td>
      ),
    },
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td style={{ width: '0%' }} key={colIndex}>
          <FormField>
            {/* <span style={{ width: 50, paddingTop: 7, paddingRight: 5, fontSize: '14px' }}>銀行</span> */}
            <SelectFormVirtical
              label="銀行"
              name="bankCode"
              value={String(state?.companyBankList[rowIndex]?.bankCode || '')}
              setValue={(val) => { handleChangeCompanyBank(val, rowIndex, 'bankCode') }}
              options={companyBankOptions}
              paddingTop={5}
              paddingBottom={3}
              smallSelect={true}
            />
          </FormField>
        </td>
      ),
    },
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td style={{ width: '20%' }} key={colIndex}>
          <FormField>
            {/* <span style={{ width: 50, paddingTop: 7, paddingRight: 5, fontSize: '14px' }}>銀行名</span> */}
            <TextForm
              name="otherBankName"
              label='銀行名'
              value={state?.companyBankList[rowIndex]?.otherBankName}
              disable={String(state?.companyBankList[rowIndex]?.bankCode) !== '0' ? true : false}
              onChange={(e) => { handleChangeCompanyBank(e.target.value, rowIndex, 'otherBankName') }}
              customStyleLabel={css({ paddingTop: '12px' })}
            />
          </FormField>
        </td>
      ),
    },
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td style={{ width: '20%' }} key={colIndex}>
          <FormField>
            {/* <span style={{ width: 70, paddingTop: 7, paddingRight: 5, fontSize: '14px' }}>登録番号</span> */}
            <TextForm
              name="registrationNo"
              label='登録番号'
              value={state?.companyBankList[rowIndex]?.registrationNo}
              onChange={(e) => {
                if (reNumberic(e.target.value)) {
                  handleChangeCompanyBank(e.target.value, rowIndex, 'registrationNo')
                }
              }}
              maxLength={16}
              customStyleLabel={css({ paddingTop: '12px' })}
            />
          </FormField>
        </td>
      ),
    },
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td style={{ width: '20%' }} key={colIndex}>
          <FormField isPadding={true}>
            <span style={{ width: 70, paddingTop: 12, fontSize: '14px' }}>期間</span>
          </FormField>
          <FormField isPadding={true}>
            <DatePickerForm
              label=""
              date={moment(state?.companyBankList[rowIndex]?.applyStartDate).toDate() || ''}
              dateFormat="yyyy年MM月dd日"
              changeDate={(date: Date) => {
                if (date !== null) {
                  const newdate = date ? moment(date).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD');
                  handleChangeCompanyBank(newdate, rowIndex, 'applyStartDate')
                }
              }}
              isFullWidth={true}
              removeMargin={true}
            />
            <DatePickerForm
              label=""
              date={moment(state?.companyBankList[rowIndex]?.applyEndDate).toDate() || ''}
              dateFormat="yyyy年MM月dd日"
              changeDate={(date: Date) => {
                if (date !== null) {
                  const newdate = date ? moment(date).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD');
                  handleChangeCompanyBank(newdate, rowIndex, 'applyEndDate')
                }
              }}
              disable={
                moment(state?.companyBankList[rowIndex]?.applyEndDate).format('YYYY') === '9999' ||
                  state?.companyBankList[rowIndex]?.applyEndDate === '' ? true : false
              }
              isFullWidth={true}
              removeMargin={true}
            />
          </FormField>
        </td>
      ),
    },
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td style={{ width: '5%', paddingTop: 43 }} key={colIndex}>
          <FormField>
            <Checkbox
              id={`decided${rowIndex}`}
              name="decided"
              label="未定"
              value=""
              checked={
                moment(state?.companyBankList[rowIndex]?.applyEndDate).format('YYYY') === '9999' ||
                  state?.companyBankList[rowIndex]?.applyEndDate === '' ? true : false
              }
              onChange={(e) => {
                let checked = '';
                if (moment(state?.companyBankList[rowIndex]?.applyEndDate).format('YYYY') === '9999' ||
                  state?.companyBankList[rowIndex]?.applyEndDate === '') {
                  checked = moment().format('YYYY/MM/DD')
                }
                handleChangeCompanyBank(checked, rowIndex, 'applyEndDate')
              }}
            />
          </FormField>
        </td>
      ),
    },
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td key={colIndex} className="text-center" style={{ width: '2%', color: 'red', fontSize: 14, cursor: 'pointer', paddingTop: '30px' }} onClick={() => {
          deleteCompanyBank(rowIndex)
        }}>
          <Icon type="close" color={'red'} hoverColor={'red'} />
        </td>
      )
      ,
    },
  ];

  const addRow = () => {
    const newRows = [...addName, initStateName];
    setAddName(newRows);
    dispatch({
      type: "ADD_NAME",
    });
    let inputCheck: any = arrInputChange;
    if (JSON.stringify(newRows) !== JSON.stringify(defaultReducer['nameList']) && inputCheck.includes('nameList') === false) {
      inputCheck.push('nameList');
    }
    if (JSON.stringify(newRows) === JSON.stringify(defaultReducer['nameList']) && inputCheck.includes('nameList') === true) {
      var index = inputCheck.indexOf('nameList')
      if (index !== -1) {
        inputCheck.splice(index, 1);
      }
    }
    setArrInputChange(inputCheck);
    setElementCalculate(elementCalculate + 1);
  }

  const addRowCompanyBank = () => {
    const newRows = [...addCompanyBank, defaultCompanyBank[0]];
    setAddCompanyBank(newRows);
    dispatch({
      type: "ADD_COMPANY_BANK",
    });
    let inputCheck: any = arrInputChange;
    if (JSON.stringify(newRows) !== JSON.stringify(defaultReducer['companyBankList']) && inputCheck.includes('companyBankList') === false) {
      inputCheck.push('companyBankList');
    }
    if (JSON.stringify(newRows) === JSON.stringify(defaultReducer['companyBankList']) && inputCheck.includes('companyBankList') === true) {
      var index = inputCheck.indexOf('companyBankList')
      if (index !== -1) {
        inputCheck.splice(index, 1);
      }
    }
    setArrInputChange(inputCheck);
    setElementCalculate(elementCalculate + 1);
  }

  const loadDetailFormList = ['組織情報', 'マネージャー設定', '特賃日設定', '店舗基本情報', '許認可', '端末・機器・金庫', 'ビル管理', '施設', '保険・その他']

  const loadDetailFormCompanyBankList = ['組織情報', 'マネージャー設定', '特賃日設定', '店舗基本情報', '許認可', '端末・機器・金庫', 'ビル管理', '施設', '銀行', '保険・その他']

  const [list, setList] = useState<any>(loadDetailFormList);

  useEffect(() => {
    if (arrCompanyBank && arrCompanyBank.companyBankList && arrCompanyBank.companyBankList.length > 0) {
      setList(loadDetailFormCompanyBankList);
    } else {
      setList(loadDetailFormList);
    }
  }, [arrCompanyBank])

  useEffect(() => {
    setMemoReCalCulate(memoReCalculate + 1)
  }, [elementCalculate, managerList]);

  const history = useHistory();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole])

  return (
    <SidebarTemplate pageTitle="組織編集">
      <BlockUI blocking={blocking}>
        {/* <TabControl
            items={[
              {
                value: SelectType.basic,
                display: '組織情報',
              },
              {
                value: SelectType.manager,
                display: 'マネージャー設定',
              },
              {
                value: SelectType.specialSalary,
                display: '特賃日設定',
              },
            ]}
            setValue={setSelectType as (arg: string) => void}
            currentValue={selectType}
            isFixed={true}
          /> */}

        {/* {selectType === SelectType.basic && (
            <> */}

        {/* <OrganizationInputForm
                initialOrganizationDomain={organizationDomain || undefined}
                setInitialOrganizationDomain={setOrganizationDomain}
                historyList={historyList}
                setHistoryList={setHistoryList}
                isEditMode={true}
                roleScreen={checkUserRole}
              />
            </>
          )}
          {selectType === SelectType.manager && (
            <OrganizationManagerEditForm
              managerList={managerList}
              seniorManagerList={seniorManagerList}
              setManagerList={setManagerList}
              setSeniorManagerList={setSeniorManagerList}
              isEditMode={true}
            />
          )}
          {selectType === SelectType.specialSalary && (
            <OrganizationSpecialSalaryEditForm isEditMode={true} />
          )} */}
        {/* <ScrollMenuComponent items={list} /> */}
        <FormContents>
          <ScrollMenuComponent
            items={list}
            buttonSubmit={true}
            renderElementByAddOrRemove={memoReCalculate}
            openDetailForm={openDetailForm}
            roleScreen={checkUserRole}
            handleButtonSubmit={handleButtonSubmit}
            isEditMode={true}
          >
            {
              <OrganizationInputForm
                initialOrganizationDomain={organizationDomain || undefined}
                setInitialOrganizationDomain={setOrganizationDomain}
                historyList={historyList}
                setHistoryList={setHistoryList}
                state={state}
                handleOnChange={handleOnChange}
                fetchData={fetchData}
                isEditMode={true}
              />
            }
            {
              <OrganizationManagerEditForm
                managerList={managerList}
                seniorManagerList={seniorManagerList}
                setManagerList={setManagerList}
                setSeniorManagerList={setSeniorManagerList}
                isEditMode={false}
                state={state}
                dispatch={dispatch}
              />
            }
            {
              <OrganizationSpecialSalaryEditForm
                isEditMode={false}
                state={state}
                handleOnChange={handleOnChange}
                roleScreen={checkUserRole}
                dispatch={dispatch}
                orgSpecialList={organizationDetail?.orgSpecialList}
                handleDeleteSpecialWageSetting={handleDeleteSpecialWageSetting}
              />
            }
            {
              <StoreBasicInformationForm
                state={state}
                handleOnBlur={handleOnBlur}
                handleOnChange={handleOnChange}
                addName={renderListAddName(10)}
                tableColumnsName={tableColumnsName}
                addRow={addRow}
              />
            }
            {
              <LicenseForm
                state={state}
                handleOnChange={handleOnChange}
              />
            }
            {
              <TerminalEquipmentSafeForm
                state={state}
                handleOnChange={handleOnChange}
              />
            }
            {
              <BuildingManagementForm
                state={state}
                handleOnChange={handleOnChange}
              />
            }
            {
              <FacilityForm
                state={state}
                handleOnChange={handleOnChange}
              />
            }
            {
              arrCompanyBank && arrCompanyBank.companyBankList && arrCompanyBank.companyBankList.length > 0 &&
              <BankForm
                addCompanyBank={addCompanyBank}
                tableBankCompany={tableBankCompany}
                addRowCompanyBank={addRowCompanyBank}
              />
            }
            {
              <InsuranceBankOtherForm
                state={state}
                handleOnChange={handleOnChange}
              />
            }
          </ScrollMenuComponent>
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default OrganizationEditPage;
