import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import ScheduleLayout from './ScheduleLayout';

/**
 * FOR MOBILE
 */
const ScheduleMobilePage: React.FC = () => (
  <SidebarTemplate pageTitle="マイシフト">
    <ScheduleLayout />
  </SidebarTemplate>
);

export default ScheduleMobilePage;
