import { useState, useEffect } from 'react';
import WorkingDaysDomain from 'domain/master/labor/workingDays';
import { getWorkingDayList, deleteWorkingDayById } from 'api/workingDay';

export const UsePrescribedWorkingDaysList = () => {
  const [workingDayList, setWorkingDayList] = useState<Array<WorkingDaysDomain>>([]);
  useEffect(() => {
    let isSubscribed = true;
    getWorkingDayList().then((response: Array<any>) => {
      if (isSubscribed) {
        setWorkingDayList(response.map((result) => new WorkingDaysDomain(result)));
      }
    });
    return () => { isSubscribed = false; };
  }, []);
  return { workingDayList, setWorkingDayList };
};

export const UseDeleteWorkingDaysById = (id: string) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  deleteWorkingDayById(id).then(() => {
    setIsSuccess(true);
    setErrorMessage('');
  }).catch((error) => {
    setIsSuccess(false);
    setErrorMessage(error.reponse.data.error[0].defaultMessage);
  });

  return { isSuccess, errorMessage };
};

export default UsePrescribedWorkingDaysList;
