/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { Row, Col } from 'react-grid-system';
import FlexBox from 'components/atoms/FlexBox';
import FormPadding from 'components/atoms/Form/FormPadding';
import BodyText from 'components/atoms/BodyText';
import PrimaryButton, { SecondaryButton } from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import moment from 'moment';
import Checkbox from 'components/molecules/Checkbox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import { OptionType } from 'components/atoms/Select';
import { getList } from 'api/businessMaster';
import { AttendBusiness } from 'domain/employment';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import SelectForm from 'components/molecules/SelectForm';
import { useHistory, useParams } from 'react-router-dom';
import { useDailyShiftContext } from './contextHook';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

export const useBusinessMasterOptions = (): Array<OptionType> => {
  const [businessOptions, setBusinessOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getList().then((businesses) => {
      setBusinessOptions(businesses.map((business) => ({
        value: business.businessId,
        label: `${business.businessCode} ${business.businessName}`,
      })));
    });
  }, []);
  return businessOptions;
};


const Filters: React.FC <{
  dispatch?: any,
  businessIds?: any,
  setBusinessIds?: any,
}> = ({
  dispatch,
  businessIds,
  setBusinessIds,
}) => {
  const {
    setOpenConfirmModal,
    dailyShift,
    loadEmploymentDone,
    setOpenRegisterModal,
    filters: {
      orgs,
      dateFromStr,
      setDateFromStr,
      isAttendExists,
      setAttendExists,
      selectedOrg,
      setSelectedOrg,
      employmentOptions,
      setEmploymentOptions,
      downloadPdf,
      checkall,
      setCheckall,
      selectedCumulativeClosingDate,
      setSelectedCumulativeClosingDate,
    },
  } = useDailyShiftContext();
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
    undefined,
  );

  const businessOptions = useBusinessMasterOptions();
  const params = useParams<any>();

  useEffect(() => {
    if (employmentOptions !== null && employmentOptions !== [] && loadEmploymentDone) {
      let sessionData = '';
      const list = employmentOptions.reduce((x: any, y: any) => (y.checked === true ? [...x, y.value] : x), []);
      if (list.length === employmentOptions.length) {
        sessionData = 'all';
        setCheckall(true);
      } else {
        sessionData = list.join();
        setCheckall(false);
      }
      sessionStorage.setItem(`0/sessionData${window.location.pathname}.employmentOptions`, sessionData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employmentOptions, loadEmploymentDone]);
  const cumulativePeriod = dailyShift && dailyShift.cumulativePeriod ? dailyShift.cumulativePeriod : '';

  const history = useHistory();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === selectedOrg);

  const checkUserRole = staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404 ')
    }
  }, [checkUserRole]);

  return (
    <FormPadding css={{ position: 'relative', zIndex: 10 }} isSubForm={window.screen.width < 800}>
      <Row>
        <Col md={2} lg={1}>
          <div css={css({ marginTop: '18px' })}>
            <BodyText>期間</BodyText>
          </div>
        </Col>
        <Col md={10} lg={11}>
          <FlexBox>
            <Col md={3} xl={2}>
              <DatePickerForm
                label=""
                date={moment(dateFromStr).toDate()}
                dateFormat="yyyy年MM月dd日"
                changeDate={(date: Date) => {
                  if (date !== null) {
                    const newdate = date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
                    setDateFromStr(newdate);
                    sessionStorage.setItem('attendStampList.dailyShift.targetDateFrom', newdate);
                    dispatch({
                      type: 'TARGET_DATE',
                      payload: newdate,
                    });
                  }
                }}
                isFullWidth={true}
              />
            </Col>
            <Col md={9}>
              <div css={css({
                display: 'flex', alignItems: 'baseline', marginLeft: '20px', marginTop: '-8px',
              })}
              >
                <Checkbox
                  id="1"
                  name="isAttendExists"
                  label="シフト・実績有る人のみ表示"
                  value="1"
                  checked={isAttendExists === '1'}
                  onChange={() => {
                    sessionStorage.setItem('attendStampList.attendFlag', isAttendExists === '1' ? '0' : '1');
                    setAttendExists(isAttendExists === '1' ? '0' : '1');
                  }}
                />
              </div>
            </Col>
          </FlexBox>
        </Col>
      </Row>

      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', flexWrap: 'wrap',
      }}
      >
        <div className="daily_shift_filtered_org" style={{ width: '8.33333%' }}>
          <div css={css({ marginTop: '24px' })}>
            <BodyText>組織</BodyText>
          </div>
        </div>
        <div className="daily_shift_filtered_org" style={{ width: '720px' }}>
          <OrganizationFilteredTree
            functionType={2}
            targetdayForm={moment(dateFromStr).toDate()}
            targetdayTo={moment(dateFromStr).toDate()}
            orgCallback={(args: string | Array<string>) => {
              sessionStorage.setItem('attendStampList.dailyShift.orgCode', String(args));
              setSelectedOrg(String(args));
              dispatch({
                type: 'ORG_CODE',
                payload: String(args),
              });
            }}
            initOrgValue={params.orgCode || sessionStorage.getItem('attendStampList.dailyShift.orgCode') || selectedOrg}
            staffCode={loginStaffCode}
            orgLabel="出勤店舗"
            addAllItem={false}
            orgCategoryCallback={
                          (args: Array<string>) => {
                            setCategoryAr(args);
                          }
                        }
            orgNameCallback={(args: string) => {
              sessionStorage.setItem('dailyShift.orgName', String(args));
              dispatch({
                type: 'ORG_NAME',
                payload: String(args),
              });
            }}
          />
        </div>
        <div>
          <div css={css({
            marginTop: '12px', display: 'flex', flexDirection: 'row', alignItems: 'center',
          })}
          >
            <BodyText>累計起算日：</BodyText>
            <SelectForm
              label=""
              name="orgCode"
              value={selectedCumulativeClosingDate}
              options={[
                {
                  label: '1',
                  value: '1',
                },
                {
                  label: '6',
                  value: '6',
                },
                {
                  label: '11',
                  value: '11',
                },
                {
                  label: '16',
                  value: '16',
                },
                {
                  label: '21',
                  value: '21',
                },
                {
                  label: '26',
                  value: '26',
                },
              ]}
              setValue={(closingDay) => {
                setSelectedCumulativeClosingDate(closingDay);
                sessionStorage.setItem('attendStampList.dailyShift.cumulativeClosingDate', closingDay);
              }}
              description=""
            />
            <div style={{ marginLeft: 10, fontSize: 14, color: '#a94442' }}>{`(累計期間：${cumulativePeriod})`}</div>
          </div>
        </div>
      </div>

      {/* <Row>
        <Col lg={1} md={2}>
          <div css={css({ marginTop: "8px" })}>
            <BodyText>雇用形態</BodyText>
          </div>
        </Col>
        <Col lg={11} md={10} css={css({ display: "flex", flexWrap: "wrap" })}>
          <div css={css({ padding: "8px 10px 5px 0" })}>
            <Checkbox
              id={"all"}
              name={"checkall"}
              label="すべて"
              value={"1"}
              checked={checkall}
              onChange={(event) => {
                let newEmploymentOptions = employmentOptions.map((option) => {
                  option.checked = !checkall;
                  return option;
                });
                setEmploymentOptions(newEmploymentOptions);
                setCheckall(!checkall);
              }}
            />
          </div>
          {employmentOptions.map((option, key) => {
            return (
              <div key={key} css={css({ padding: "8px 10px 5px 0" })}>
                <Checkbox
                  id={option.id}
                  name={option.label}
                  label={option.label}
                  value={option.value}
                  checked={option.checked}
                  onChange={(event) => {
                    let newEmploymentOptions = [...employmentOptions];
                    newEmploymentOptions[key].checked = event.target.checked;
                    setEmploymentOptions(newEmploymentOptions);
                  }}
                />
              </div>
            );
          })}
        </Col>
      </Row> */}
      <Row>
        <Col lg={1} md={2}>
          <div css={css({ marginTop: '15px' })}>
            <BodyText>業務</BodyText>
          </div>
        </Col>
        <Col lg={5}>
          <VerticalLabelMutilSelectForm
            label=""
            name="businesses"
            values={businessIds.map(
              (business: AttendBusiness) => businessOptions.find(
                (item) => business.businessId === item.value,
              ),
            )}
            setValue={(val: any) => {
              if (val !== '') {
                setBusinessIds(val.slice(0, -1).split(',').map(
                  (id: string) => ({ businessId: id }),
                ));
              } else {
                setBusinessIds([]);
              }
            }}
            options={businessOptions}
            isMulti={true}
          />
        </Col>
      </Row>
      <Row>
        <FlexBox customStyle={css({ margin: "1rem 0", width: "100%", justifyContent: "flex-start" })}>
          <span style={{ marginRight: '10px' }}>
            {checkUserRole && checkUserRole.editable === 1 && (
            <PrimaryButton
              text="支援スタッフの追加"
              onClick={() => {
                setOpenRegisterModal(true);
              }}
            />
            )}

          </span>
          {dailyShift?.isShiftConfirm && checkUserRole && checkUserRole.usabilityGeneralItem1 === 1 ? (
            <SecondaryButton
              text="シフト確定解除"
              onClick={() => {
                setOpenConfirmModal(true);
              }}
            />
          ) : checkUserRole && checkUserRole.usabilityGeneralItem1 === 1 && (
            <PrimaryButton
              text="シフト確定"
              disabled={dailyShift.isAchievementConfirm || dailyShift.isShiftConfirm}
              onClick={() => {
                setOpenConfirmModal(true);
              }}
            />
          )}
          {checkUserRole && checkUserRole.downloadFlag === 1 && (
          <ClickableIconButton onClick={downloadPdf} additionalCss={css({ margin: '0 0 0 10px', cursor: 'pointer' })}>
            <span className="print">
              <Icon type="printer" color="" size="24px" />
            </span>
          </ClickableIconButton>
          )}
        </FlexBox>
      </Row>
    </FormPadding>
  );
};

export default Filters;
