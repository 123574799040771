import { useState, useEffect, useCallback } from 'react';
import OvertimeApplicationDomain from 'domain/master/attend/attendOvertimeApplication';
import { useHistory } from 'react-router-dom';
// import { useAllOrgTrees } from 'hooks/useOrgTreesOptions';
import { getOvertime, postOvertime } from 'api/attendOvertimeApplication';
import { useFormik } from 'formik';
import useToastNotification from 'hooks/useToastNotification';

type OvertimeApplicationKey = keyof Pick<OvertimeApplicationDomain, 'applicationReason'>;

export const OvertimeApplicationForm = () => {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [targetDate] = useState(sessionStorage.getItem('application.targetDate') || '');
  const [staffName] = useState(sessionStorage.getItem('application.dispStaffName') || '');
  const [selectStaffCode] = useState(sessionStorage.getItem('application.staffCode') || '');
  const [dispStaffName] = useState(sessionStorage.getItem('application.dispStaffName') || '');
  const [selectOrgCode, setSelectOrgCode] = useState(sessionStorage.getItem('application.orgCode') || '');
  // const orgTreesOptions = useAllOrgTrees();
  const [toastMessage, setToastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmApplyModalOpen, setConfirmApplyModalOpen] = useState(false);
  const [isGetOvertionSuccees, setIsGetOvertionSuccees] = useState(true);
  const { errorNotification } = useToastNotification();

  const onSubmit = async (values: OvertimeApplicationDomain) => {
    console.log(values);

    if (values.applicationReason === null) {
      errorNotification('申請理由を入力してください。');
      return;
    }

    if (!confirmApplyModalOpen) {
      setConfirmApplyModalOpen(true);
      return;
    }

    const {
      modifierApplicationId, version, orgName, buttonType, applicationReason, staffCode, orgCode,
      applicationOvertimeStr, targetDateStr,
    } = values.getRawData();

    const dataPost = {
      modifierApplicationId: modifierApplicationId || '',
      version: version || '',
      orgCode,
      orgName,
      staffCode,
      buttonType,
      targetDateStr,
      applicationOvertimeStr,
      updateUserCode: sessionStorage.getItem('loginUser.staffCode') || '',
      applicationReason,
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
    };

    try {
      setLoading(true);
      setConfirmApplyModalOpen(false);
      const response = await postOvertime(dataPost);
      if (!response.errors) {
        history.push(sessionStorage.getItem('attendStampList.returnDestination')! || '');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setConfirmApplyModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: OvertimeApplicationDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  useEffect(() => {
    setLoading(true);
    getOvertime({
      orgCode: selectOrgCode,
      targetDate,
      staffCode: selectStaffCode,
    }).then((data: any) => {
      console.log(data);
      formik.setValues(new OvertimeApplicationDomain(data));
      setLoading(false);
    }).catch(() => {
      setIsGetOvertionSuccees(false);
      setLoading(false);
      // setToastMessage('サーバー側でエラーが発生しました。');
      // setToastModalOpen(true);
      errorNotification('サーバー側でエラーが発生しました。');
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgCode, selectStaffCode, staffName, targetDate]);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmApplyModal = useCallback(() => {
    setConfirmApplyModalOpen(false);
  }, []);

  return {
    formik,
    // orgTreesOptions,
    selectOrgCode,
    setSelectOrgCode,
    staffName,
    targetDate,
    toastMessage,
    setToastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    setConfirmApplyModalOpen,
    closeToastModal,
    closeConfirmApplyModal,
    isLoading,
    dispStaffName,
    isGetOvertionSuccees,
  };
};

export default OvertimeApplicationForm;
