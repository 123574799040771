import React, { ReactNode } from 'react';
import { Container, Row, Col } from 'react-grid-system';

const ThreeColumnLayout: React.FC<{
  children: {
    firstColumn:ReactNode,
    secondColumn:ReactNode,
    threeColumn:ReactNode,

  }
}> = ({ children }) => (
  <Container fluid={true}>
    <Row style={{ border: '1px solid #222d32' }}>
      <Col md={4} xs={12}>
        {children.firstColumn}
      </Col>
      <Col style={{ borderLeft: '1px solid #222d32' }} md={4} xs={12}>
        {children.secondColumn}
      </Col>
      <Col style={{ borderLeft: '1px solid #222d32' }} md={4} xs={12}>
        {children.threeColumn}
      </Col>
    </Row>
  </Container>
);

export default ThreeColumnLayout;
