import React from 'react';
import { useParams } from 'react-router-dom';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import JournalMasterForm from 'components/organismos/master/general/accountTitleMST/JournalMaster/JournalMasterForm'
import { useAccountTitleMSTItemList } from '../accountTitleMSTPage/AccountMaster/hooks';

const JournalMasterAddPage: React.FC = () => {
  const {
    accountTitleMSTItemList
  } = useAccountTitleMSTItemList();
  const { journalId } = useParams();
  return (
    <SidebarTemplate pageTitle={journalId ? '仕訳編集' : '仕訳登録'}>
        <JournalMasterForm 
          accountTitleList={accountTitleMSTItemList}
        />
    </SidebarTemplate>
  );
};


export default JournalMasterAddPage;
