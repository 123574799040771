/* eslint-disable eqeqeq */
/** @jsx jsx */
import React, { useCallback, useEffect, useMemo } from 'react';
import { css, jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { useDownload } from './hook';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';
import { grayScale } from 'components/styles';

const styles = css({
  fontWeight: 400,
  fontSize: 14,
});

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 1,
});

const formatterPercent = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'percent',
});

const DEFAULT_SORT_FIELD = { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number' } as FieldSortState

const DailySalesStoreTable: React.FC<{
    listDailySalesStore: any;
    stateSymbol: any;
    orgCode:any;
    orgName:any;
    selectedDateFrom:any;
    selectedDateTo:any;
    targetPeriodData:any;
    roleScreen:any;
  }> = ({
    listDailySalesStore,
    stateSymbol,
    orgCode,
    orgName,
    selectedDateFrom,
    selectedDateTo,
    targetPeriodData,
    roleScreen,
  }) => {

    const listData = useMemo(() => listDailySalesStore.slice(0, -1), [listDailySalesStore])
    const totalData = useMemo(() => listDailySalesStore.slice(-1), [listDailySalesStore])

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(listData, DEFAULT_SORT_FIELD);

    const { downloadShopSeparateSalesManagementCosCsv, downloadShopSeparateSalesManagementCosExcel } = useDownload();

    const searchResult:any[] = [];

    const targetStoresData = `対象店舗: ${orgName}`;

    const headerInformation = [
      ['', '', '', '', '税抜売上', '', '', '', '', '', '', '', '', '日次収支利益', '', '', '', 'コスト管理', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '当日', '', '', '', '累計', '', '', '', '', '当日', '', '累計', '', 'Fコスト', '', '', '', 'Lコスト', '', '', '', '', '', '概算諸経費', '', '', '', ''],
      ['状態', '家賃', '店舗', '並び順', '実績', '予算', '達成率', '目標差異', '実績', '予算', '達成率', '目標差異', '売上(税込)', '実績', '利益率', '実績', '利益率', 'フード', 'ドリンク', '合計', '売上対比(税抜)', '社員', 'アルバイト', '合計', '売上対比(税抜)', '合計', '売上対比(税抜)', '合計', '売上対比', '組数', '客数', '客単価(税抜)']
    ];

    let mergedRegions:any[] = [];
    const codeColumnIndex:any[] = [];
    const data = {
      footer: '',
      formName: '【店別】売上管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult,
      totalInformation: [],
    };

    const dataExcel = {
      formName: '【店別】売上管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex,
    };

    const handleDownloadShopSeparateSalesManagementCosCsv = () => {
      downloadShopSeparateSalesManagementCosCsv(data);
    };

    const handleDownloadShopSeparateSalesManagementCosExcel = () => {
      if (mergedRegions) {
        mergedRegions = [];
      }
      // merge col row one
      mergedRegions.push([3, 3, 4, 11]);
      mergedRegions.push([3, 3, 13, 16]);
      mergedRegions.push([3, 3, 17, 28]);

      // merge col row two
      mergedRegions.push([4, 4, 4, 7]);
      mergedRegions.push([4, 4, 8, 11]);
      mergedRegions.push([4, 4, 13, 14]);
      mergedRegions.push([4, 4, 15, 16]);
      mergedRegions.push([4, 4, 17, 20]);
      mergedRegions.push([4, 4, 21, 24]);
      mergedRegions.push([4, 4, 27, 28]);

      // merge row
      mergedRegions.push([3, 5, 0, 0]);
      mergedRegions.push([3, 5, 1, 1]);
      mergedRegions.push([3, 5, 2, 2]);
      mergedRegions.push([3, 5, 3, 3]);
      mergedRegions.push([3, 5, 12, 12]);
      mergedRegions.push([4, 5, 25, 25]);
      mergedRegions.push([4, 5, 26, 26]);
      mergedRegions.push([3, 5, 29, 29]);
      mergedRegions.push([3, 5, 30, 30]);
      mergedRegions.push([3, 5, 31, 31]);
      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [0];
      dataExcel.headerInformation = [
        ['状態', '家賃', '店舗', '並び順', '税抜売上', '', '', '', '', '', '', '', '売上(税込)', '日次収支利益', '', '', '', 'コスト管理', '', '', '', '', '', '', '', '', '', '', '', '組数', '客数', '客単価(税抜)'],
        ['', '', '', '', '当日', '', '', '', '累計', '', '', '', '', '当日', '', '累計', '', 'Fコスト', '', '', '', 'Lコスト', '', '', '', '合計', '売上対比(税抜)', '概算諸経費', '', '', '', ''],
        ['', '', '', '', '実績', '予算', '達成率', '目標差異', '実績', '予算', '達成率', '目標差異', '', '実績', '利益率', '実績', '利益率', 'フード', 'ドリンク', '合計', '売上対比(税抜)', '社員', 'アルバイト', '合計', '売上対比(税抜)', '', '', '合計', '売上対比', '', '', '']
      ];

      return downloadShopSeparateSalesManagementCosExcel(dataExcel);
    };

    useEffect(() => {
      if (listDailySalesStore) {
        // content
        [...dataSort, ...totalData].map((saleDate: any, index: number) => {
          const searchItem: any[] = [];
          searchItem.push(

            (index < listDailySalesStore.length - 1) ? (stateSymbol as any)[saleDate.status] : '',
            saleDate.landRent,
            (index < listDailySalesStore.length - 1) ? saleDate.orgName : '合計',
            saleDate.dispOrder,
            formatter.format(saleDate.netSales),
            formatter.format(saleDate.salesBudget),

            formatter.format(saleDate.salesAchievementRate)+'%',
            formatter.format(saleDate.diffNetSales),
            formatter.format(saleDate.cumulativeSales),
            formatter.format(saleDate.cumulativeSalesBudget),
            formatter.format(saleDate.cumulativeSalesAchievementRate)+'%',

            formatter.format(saleDate.diffCumulativeNetSales),
            formatter.format(saleDate.totalSales),
            formatter.format(saleDate.profit),
            formatter.format(saleDate.profitRate)+'%',
            formatter.format(saleDate.cumulativeProfit),

            formatter.format(saleDate.cumulativeProfitRate)+'%',
            formatter.format(saleDate.foodCost),
            formatter.format(saleDate.drinkCost),
            formatter.format(saleDate.totalFoodDrinkCost),
            formatter.format(saleDate.foodAchievementRate)+'%',

            formatter.format(saleDate.employeeSalary),
            formatter.format(saleDate.partSalary),
            formatter.format(saleDate.totalLaborCost),
            formatter.format(saleDate.laborAchievementRate)+'%',
            formatter.format(saleDate.totalFoodLaborCost),

            formatter.format(saleDate.foodLaborAchievementRate)+'%',
            formatter.format(saleDate.othreExpenses),
            formatter.format(saleDate.expensesAchievementRate)+'%',
            formatter.format(saleDate.customers),
            formatter.format(saleDate.guestCnt),
            formatter.format(saleDate.customerUnitPrice),

          );
          searchResult.push(searchItem);
        });
      }
    }, [listDailySalesStore, searchResult]);


    const classNegative = (number: any) => {
      if (number < 0) {
        return 'colorRed';
      }
      return '';
    };

    const MultiLineText = ({ text }: { text: string }) => {
      const texts = text.split('\n').map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item}
            <br />
          </React.Fragment>
        );
      });
      return <span style={{ margin: 'auto' }}>{texts}</span>;
    };

    const renderHeaderColumn = useCallback((displayName: string, columnSortField: FieldSortState, rowSpan: number, className: string = "") => (
      <th rowSpan={rowSpan} className={className} onClick={e => sortByFieldPath(columnSortField.fieldPath, columnSortField.sortType)}>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <MultiLineText text={displayName}/>
          {sortField.fieldPath === columnSortField.fieldPath && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
          {sortField.fieldPath !== columnSortField.fieldPath && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
        </div>
      </th>
     ), [sortField]);

    return (
      <div>

        {
          roleScreen && roleScreen.downloadFlag === 1 && (
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleDownloadShopSeparateSalesManagementCosCsv}
                iconType="download"
                text=" CSV出力"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleDownloadShopSeparateSalesManagementCosExcel}
                iconType="download"
                text=" EXCEL出力"
              />
            </FlexBoxItem>
          </FlexBox>
        )}

        <div
          className="table-responsive"
          style={{
            display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', maxHeight: 600,
          }}
        >
          <table id="salesPageForm" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }}>
            <thead>
              <tr role="row">
                { renderHeaderColumn('状態', { fieldPath: 'status', sort: 'asc', sortType: 'number'}, 3) }
                { renderHeaderColumn('家賃', { fieldPath: 'landRent', sort: 'asc', sortType: 'number'}, 3) }
                { renderHeaderColumn('店舗', { fieldPath: 'orgName', sort: 'asc', sortType: 'string'}, 3) }
                { renderHeaderColumn('並び順', { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number'}, 3) }
                <th colSpan={8}>税抜売上</th>
                { renderHeaderColumn('売上(税込)', { fieldPath: 'totalSales', sort: 'asc', sortType: 'number'}, 3) }
                <th colSpan={4}>日次収支利益</th>
                <th colSpan={12}>コスト管理</th>
                { renderHeaderColumn('組数', { fieldPath: 'customers', sort: 'asc', sortType: 'number'}, 3) }
                { renderHeaderColumn('客数', { fieldPath: 'guestCnt', sort: 'asc', sortType: 'number'}, 3) }
                { renderHeaderColumn(`客単価\n(税抜)`, { fieldPath: 'customerUnitPrice', sort: 'asc', sortType: 'number'}, 3) }
              </tr>
              <tr className="color_merge_one subThead">
                <th colSpan={4}>当日</th>
                <th colSpan={4}>累計</th>
                <th colSpan={2}>当日</th>
                <th colSpan={2}>累計</th>
                <th colSpan={4}>Fコスト</th>
                <th colSpan={4}>Lコスト</th>
                { renderHeaderColumn('合計', { fieldPath: 'totalFoodLaborCost', sort: 'asc', sortType: 'number'}, 2, "color_merge") }
                { renderHeaderColumn('売上対比(税抜)', { fieldPath: 'foodLaborAchievementRate', sort: 'asc', sortType: 'number'}, 2, "color_merge") }
                <th colSpan={2}>概算諸経費</th>
              </tr>
              <tr className="color_merge_two subThead2">
                {/* 税抜売上 */}
                { renderHeaderColumn('実績', { fieldPath: 'netSales', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('予算', { fieldPath: 'salesBudget', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('達成率', { fieldPath: 'salesAchievementRate', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('目標差異', { fieldPath: 'diffNetSales', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('実績', { fieldPath: 'cumulativeSales', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('予算', { fieldPath: 'cumulativeSalesBudget', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('達成率', { fieldPath: 'cumulativeSalesAchievementRate', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('目標差異', { fieldPath: 'diffCumulativeNetSales', sort: 'asc', sortType: 'number'}, 1) }
                {/* 日次収支利益 */}
                { renderHeaderColumn('実績', { fieldPath: 'profit', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('利益率', { fieldPath: 'profitRate', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('実績', { fieldPath: 'cumulativeProfit', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('利益率', { fieldPath: 'cumulativeProfitRate', sort: 'asc', sortType: 'number'}, 1) }
                {/* Fコスト */}
                { renderHeaderColumn('フード', { fieldPath: 'foodCost', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('ドリンク', { fieldPath: 'drinkCost', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('合計', { fieldPath: 'totalFoodDrinkCost', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('売上対比(税抜)', { fieldPath: 'foodAchievementRate', sort: 'asc', sortType: 'number'}, 1) }
                {/* Lコスト */}
                { renderHeaderColumn('社員', { fieldPath: 'employeeSalary', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('アルバイト', { fieldPath: 'partSalary', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('合計', { fieldPath: 'totalLaborCost', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('売上対比(税抜)', { fieldPath: 'laborAchievementRate', sort: 'asc', sortType: 'number'}, 1) }
                {/* 概算諸経費 */}
                { renderHeaderColumn('合計', { fieldPath: 'othreExpenses', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('売上対比(税抜)', { fieldPath: 'expensesAchievementRate', sort: 'asc', sortType: 'number'}, 1) }
              </tr>
            </thead>
              {
                  listDailySalesStore && listDailySalesStore.length > 0 ? [...dataSort, ...totalData].map((saleDate: any, index: number) => (
                    <tbody key={index} css={styles}>
                      <tr className={(index == listDailySalesStore.length - 1) ? 'custom_total' : ''}>
                        <td className="textCenter">{(index < listDailySalesStore.length - 1) ?
                        saleDate.status !== 99
                        ? <Link target={"_blank"} to={{ pathname: `/salesDailyReport/${saleDate.orgCode}/${moment(selectedDateTo).format('YYYY-MM-DD')}` }}>
                            {(stateSymbol as any)[saleDate.status]}
                          </Link>
                        :  (stateSymbol as any)[saleDate.status]
                        : ''}</td>
                        <td className={classNegative(saleDate.landRent)}>{formatter.format(saleDate.landRent)}</td>
                        <td className="textLeft">{(index < listDailySalesStore.length - 1)
                        ?
                        <Link target={"_blank"} to={{ pathname: `/daySeparateSalesManagementCos/${saleDate.orgCode}/${moment(selectedDateFrom).format('YYYY-MM-DD')}/${moment(selectedDateTo).format('YYYY-MM-DD')}` }}>
                        {saleDate.orgName}
                        </Link>
                        : '合計'}</td>
                        <td>{saleDate.dispOrder}</td>
                        <td className={classNegative(saleDate.netSales)}>{formatter.format(saleDate.netSales)}</td>
                        <td className={classNegative(saleDate.salesBudget)}>{formatter.format(saleDate.salesBudget)}</td>
                        <td className={classNegative(saleDate.salesAchievementRate)}>{formatter.format(saleDate.salesAchievementRate)+'%'}</td>
                        <td className={classNegative(saleDate.diffNetSales)}>{formatter.format(saleDate.diffNetSales)}</td>

                        <td className={classNegative(saleDate.cumulativeSales)}>{formatter.format(saleDate.cumulativeSales)}</td>
                        <td className={classNegative(saleDate.cumulativeSalesBudget)}>{formatter.format(saleDate.cumulativeSalesBudget)}</td>
                        <td className={classNegative(saleDate.cumulativeSalesAchievementRate)}>{formatter.format(saleDate.cumulativeSalesAchievementRate)+'%'}</td>

                        <td className={classNegative(saleDate.diffCumulativeNetSales)}>{formatter.format(saleDate.diffCumulativeNetSales)}</td>
                        {/* 売上(税込) */}
                        <td className={classNegative(saleDate.totalSales)}>{formatter.format(saleDate.totalSales)}</td>

                        <td className={classNegative(saleDate.profit)}>{formatter.format(saleDate.profit)}</td>
                        <td className={classNegative(saleDate.profitRate)}>{formatter.format(saleDate.profitRate)+'%'}</td>
                        <td className={classNegative(saleDate.cumulativeProfit)}>{formatter.format(saleDate.cumulativeProfit)}</td>
                        <td className={classNegative(saleDate.cumulativeProfitRate)}>{formatter.format(saleDate.cumulativeProfitRate)+'%'}</td>

                        <td className={classNegative(saleDate.foodCost)}>{formatter.format(saleDate.foodCost)}</td>
                        <td className={classNegative(saleDate.drinkCost)}>{formatter.format(saleDate.drinkCost)}</td>

                        <td className={classNegative(saleDate.totalFoodDrinkCost)}>{formatter.format(saleDate.totalFoodDrinkCost)}</td>
                        <td className={classNegative(saleDate.foodAchievementRate)}>{formatter.format(saleDate.foodAchievementRate)+'%'}</td>


                        <td className={classNegative(saleDate.employeeSalary)}>{formatter.format(saleDate.employeeSalary)}</td>
                        <td className={classNegative(saleDate.partSalary)}>{formatter.format(saleDate.partSalary)}</td>
                        <td className={classNegative(saleDate.totalLaborCost)}>{formatter.format(saleDate.totalLaborCost)}</td>
                        <td className={classNegative(saleDate.laborAchievementRate)}>{formatter.format(saleDate.laborAchievementRate)+'%'}</td>

                        <td className={classNegative(saleDate.totalFoodLaborCost)}>{formatter.format(saleDate.totalFoodLaborCost)}</td>
                        <td className={classNegative(saleDate.foodLaborAchievementRate)}>{formatter.format(saleDate.foodLaborAchievementRate)+'%'}</td>
                        <td className={classNegative(saleDate.othreExpenses)}>{formatter.format(saleDate.othreExpenses)}</td>

                        <td className={classNegative(saleDate.expensesAchievementRate)}>{formatter.format(saleDate.expensesAchievementRate)+'%'}</td>
                        <td className={classNegative(saleDate.customers)}>{formatter.format(saleDate.customers)}</td>
                        <td className={classNegative(saleDate.guestCnt)}>{formatter.format(saleDate.guestCnt)}</td>
                        <td className={classNegative(saleDate.customerUnitPrice)}>{formatter.format(saleDate.customerUnitPrice)}</td>
                      </tr>
                    </tbody>
                  )) : <tbody css={styles}></tbody>
              }
          </table>
        </div>
      </div>
    );
  };
export default DailySalesStoreTable;
