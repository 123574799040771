import { useState, useCallback, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import { getListStaff } from 'api/staff';
import StaffDomain from 'domain/staff';
import ToastNotification from 'components/molecules/ToastNotification';
import { OrgStatusType, SessionStorageKey, Values } from 'utility/constants';

export const useStaffList = () => {
  const [staffList, setStaffList] = useState<Array<StaffDomain>>([]);
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('selectedOrgCodeStaffMaster') || sessionStorage.getItem('loginUser.orgCode')!);
  const [executiveId, setExecutiveId] = useState(sessionStorage.getItem('selectedExecutiveIdStaffMaster') || '');
  const [employmentId, setEmploymentId] = useState(sessionStorage.getItem('selectedEmploymentIdStaffMaster') || '');
  const [stateStatus, setStateStatus] = useState(sessionStorage.getItem('selectedStateStatusStaffMaster')?.split(',').map((item) => Number(item)) || [1, 3, 4]);
  const [blocking, setBlocking] = useState(false);
  const [categoryArr, setCategoryAr] = useState<Array<any>>([]);
  const [menuType, setMenuType] = useState(0) || 0;
  const [orgStatus, setOrgStatus] = useState<OrgStatusType>((sessionStorage.getItem(SessionStorageKey.UserSetting.StaffMaster.SEARCH_ORG_STATUS) || Values.OrgStatus.Open.value) as OrgStatusType);

  // Get list staff
  const fetchData = useCallback(async () => {
    setBlocking(true);
    if (stateStatus.length === 0) {
      setBlocking(false);
      ToastNotification.error('状態を選択してください。');
      return;
    }

    const response = await getListStaff(
      orgCode,
      executiveId,
      employmentId,
      stateStatus,
      categoryArr,
      orgStatus,
    );

    setStaffList(response.map((result) => new StaffDomain(result)));
    setBlocking(false);
  }, [categoryArr, employmentId, executiveId, orgCode, stateStatus, orgStatus]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);



  return {
    staffList,
    fetchData,
    executiveId,
    setExecutiveId,
    employmentId,
    setEmploymentId,
    orgCode,
    setOrgCode,
    stateStatus,
    setStateStatus,
    blocking,
    categoryArr,
    setCategoryAr,
    menuType,
    orgStatus,
    setOrgStatus
  };
};

export default useStaffList;
