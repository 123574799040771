/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import productColor from 'components/styles';
import AppNotificationPanel from 'components/molecules/AppNotificationPanel';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import PrimaryButton from 'components/atoms/Button';
import BodyText from 'components/atoms/BodyText';
import ClickableLink from 'components/atoms/ClickableLink';
import PhotoFrame from 'components/molecules/PhotoFrame';
import usePhotoPath from 'hooks/usePhotoPath';

const styles = {
  background: css({
    padding: '20px',
    backgroundColor: productColor.primaryM90,
  }),
  avatar: css({
    width: '48px',
  }),
};

const StaffSettingPanel: React.FC<{
  staffName: string;
  logoutAction: () => void;
}> = ({ staffName, logoutAction }) => {
  const { myPhotoPath } = usePhotoPath();
  return (
    <AppNotificationPanel>
      <div css={styles.background}>
        <FlexBox direction="column">
          <FlexBoxItem>
            <PhotoFrame src={myPhotoPath} size="90px" isCircle={true} onErrorIconColor="black" />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>{staffName}</BodyText>
          </FlexBoxItem>
        </FlexBox>
      </div>

      <FlexBox customStyle={css({ padding: '12px' })}>
        <FlexBoxItem>
          <ClickableLink
            to="/personalSetting" // TODO リンク先は仮
            padding="0"
          >
            <PrimaryButton text="マイページ" />
          </ClickableLink>
        </FlexBoxItem>
        <FlexBoxItem marginLeft="auto">
          <PrimaryButton
            text="ログアウト"
            ghost={true}
            onClick={logoutAction}
          />
        </FlexBoxItem>
      </FlexBox>
    </AppNotificationPanel>
  );
};

export default StaffSettingPanel;
