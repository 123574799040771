/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
// import useScrollbarAutoWidth from 'hooks/useScrollbarAutoWidth';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDownload, eventScroll } from './hooks';
import { isMacOs } from 'react-device-detect';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';
import { grayScale } from 'components/styles';
import useSortTable, { FieldSortState, SortColumn } from 'hooks/useSortTable';
import MonthlySalesManagementReportDomain from 'domain/master/storeManagement/monthlySalesManagementReport';

const DEFAULT_SORT_FIELD = { fieldPath: 'orgCode', sort: 'asc' } as FieldSortState

const MonthlySalesManagementTable: React.FC<{
  targetDateFrom: any,
  targetDateTo: any,
  monthlyReport: Array<MonthlySalesManagementReportDomain>,
  targetPeriodData: any,
  orgLabel: any,
  setIsLoading: any,
  roleScreen?: any,
}> = ({
  targetDateFrom,
  targetDateTo,
  monthlyReport,
  targetPeriodData,
  orgLabel,
  setIsLoading,
  roleScreen,
}) => {
    const target_date_from = `${moment(targetDateFrom).format('YYYY-MM-DD')}`;
    const target_date_to = `${moment(targetDateTo).format('YYYY-MM-DD')}`;

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソートヘッダ項目 */
    const sortColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '店舗コード',  sortField: 'orgCode', sortType: 'string', rowSpan: 2, style: { top: '0', left: '0', zIndex: 99, maxWidth: ' 200px', width: '200px', minWidth: '200px' },     className:"text-center stickyStyle fristColSticky" },
      { displayName: '店舗名',      sortField: 'orgName', sortType: 'string', rowSpan: 2, style: { top: '0', left: '203px', zIndex: 99, maxWidth: ' 200px', width: '200px', minWidth: '200px' }, className:"text-center stickyStyle" },
    ], []);

    /** ソートしないヘッダ項目 */
    const unsortColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '売上',        sortField: '',  colSpan: 8 },
      { displayName: '値引・割引券', sortField: '', colSpan: 2 },
      { displayName: 'VOID',        sortField: '', colSpan: 2},
      { displayName: '会計中止',     sortField: '', colSpan: 2 },
      { displayName: 'メニュー取消', sortField: '', colSpan: 2 },
      { displayName: 'レジ金',      sortField: '', colSpan: 4 },
    ], []);

    /** サブヘッダ項目 */
    const sortSubColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '純売上', sortField: 'netSales', sortType: 'number' },
      { displayName: '消費税', sortField: 'tax', sortType: 'number' },
      { displayName: '総売上', sortField: 'totalSales', sortType: 'number' },
      { displayName: '金券販売', sortField: 'cashVoucherSalesTotalAmount', sortType: 'number' },
      { displayName: '客数', sortField: 'guestCnt', sortType: 'number' },
      { displayName: '客単価', sortField: 'perCustomerPrice', sortType: 'number' },
      { displayName: '組数', sortField: 'customers', sortType: 'number' },
      { displayName: '組単価', sortField: 'perGroupPrice', sortType: 'number' },
      { displayName: '金額', sortField: 'totalDiscountAmount', sortType: 'number' },
      { displayName: '枚数', sortField: 'totalDiscountCnt', sortType: 'number' },
      { displayName: '金額', sortField: 'voidAmount', sortType: 'number' },
      { displayName: '件数', sortField: 'voidCnt', sortType: 'number' },
      { displayName: '金額', sortField: 'stopAccountAmount', sortType: 'number' },
      { displayName: '件数', sortField: 'stopAccountCnt', sortType: 'number' },
      { displayName: '金額', sortField: 'posMenuCancelAmount', sortType: 'number' },
      { displayName: '件数', sortField: 'posMenuCancelCnt', sortType: 'number' },
      { displayName: '釣銭準備金', sortField: 'changeReserve', sortType: 'number' },
      { displayName: '現金在高', sortField: 'cashStock', sortType: 'number' },
      { displayName: '現金過不足', sortField: 'excessAndDeficiency', sortType: 'number' },
      { displayName: '銀行入金', sortField: 'bankDepositAmount', sortType: 'number' }
    ], []);

    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(monthlyReport, DEFAULT_SORT_FIELD)

    // ------------------------------------------------------------------
    // 出力
    // ------------------------------------------------------------------
    const { downloadMonthlyReportCsv, downloadMonthlyReportExcel } = useDownload();

    /** 出力用データ */
    const outputDataList = useMemo(() => dataSort.map(m => m.getOutputData()), [dataSort]);

    const targetStoresData = `対象店舗：${orgLabel}`;

    /** CSV出力用ヘッダ情報 */
    const headerInformation = [
      [
        ...sortColumns.map(s => ''),
        ...unsortColumns.flatMap(s => [s.displayName, ...Array((s.colSpan! - 1)).fill('')]),
      ],
      [
        ...sortColumns.map(s => s.displayName),
        ...sortSubColumns.map(s => s.displayName)
      ],
    ];

    /** Excel出力用ヘッダ情報 */
    const headerInformationExcel = [
      [
        ...sortColumns.map(s => s.displayName),
        ...unsortColumns.flatMap(s => [s.displayName, ...Array((s.colSpan! - 1)).fill('')]),
      ],
      [
        ...sortColumns.map(s => ''),
        ...sortSubColumns.map(s => s.displayName)
      ],
    ];

    /**
     * Excel出力用セル結合情報
     * NOTE: [firstRow, lastRow, firstCol, lastCol] の配列で結合するセル範囲を指定する
    */
    const mergedRegions = useMemo(() => {
      const tmpMergedRegions: any[] = [];
      // ヘッダの店舗セルを縦方向に結合
      tmpMergedRegions.push([3, 4, 0, 0]);
      // ヘッダの店舗名を縦方向に結合
      tmpMergedRegions.push([3, 4, 1, 1]);
      // ソートしないヘッダ項目をColSpan分横方向に結合
      let firstColIndex = 2;
      let lastColIndex = 0;
      unsortColumns.map((item: SortColumn) => {
        lastColIndex = firstColIndex + item.colSpan! - 1;
        tmpMergedRegions.push([3, 3, firstColIndex, lastColIndex]);
        firstColIndex = lastColIndex + 1;
      })
      return tmpMergedRegions;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const codeColumnIndex: any[] = [];
    const data = {
      footer: '',
      formName: '月次売上管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation: headerInformation,
      searchResult: outputDataList,
      totalInformation: [],
    };

    const dataExcel = {
      formName: '月次売上管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation: headerInformationExcel,
      searchResult: outputDataList,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex: [0],
    };

    const handleExportCSV = () => downloadMonthlyReportCsv(data, setIsLoading);
    const handleExportExcel = () => downloadMonthlyReportExcel(dataExcel, setIsLoading);

    return (
      <div>
        <FlexBox>
          {
            roleScreen && roleScreen.downloadFlag === 1 && (
              <React.Fragment>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportCSV}
                    iconType="download"
                    text=" CSV出力"
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportExcel}
                    iconType="download"
                    text=" EXCEL出力"
                  />
                </FlexBoxItem>
              </React.Fragment>
            )}
        </FlexBox>
        <div style={{ margin: '15px' }}>
          <div className="templateTable newDesignTable" style={{ height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: isMacOs ? '35px' : '1px' }} >
            <table className="table table-bordered text-nowrap">
              <thead>
                <tr>
                  {sortColumns.map((item: SortColumn, index: any) => (
                    <th key={index} rowSpan={item.rowSpan} style={item.style} className={item.className} onClick={e => sortByFieldPath(item.sortField, item.sortType)}>
                      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }} >
                        <span style={{ margin: 'auto' }} >{item.displayName}</span>
                        <div style={{ position: 'absolute', right: 0, marginTop: "-2px" }}>
                          {sortField.fieldPath === item.sortField && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== item.sortField && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </div>
                    </th>
                  ))}
                  {unsortColumns.map((item: SortColumn, index: any) => (
                    <th key={index} colSpan={item.colSpan} style={{ top: '0' }} className="text-center stickyStyle" ><span style={{ width: '100%' }}>{item.displayName}</span></th>
                  ))}
                </tr>
                <tr>
                  {sortSubColumns.map((item: SortColumn, index: any) => (
                    <th key={index} style={{ position: 'sticky', top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath(item.sortField, item.sortType)}  >
                      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                        <span style={{ margin: 'auto' }}>{item.displayName}</span>
                        <div style={{ position: 'absolute', right: 0, marginTop: "-2px" }}>
                          {sortField.fieldPath === item.sortField && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== item.sortField && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  dataSort.map((item: MonthlySalesManagementReportDomain, index: number) => (
                    <tr key={index}>
                      <td className="text-center stickyStyle fristColSticky" style={{ left: '0', zIndex: 10 }}>
                        <div className="text-ellipsis"><span>{item.orgCode}</span></div>
                      </td>
                      <td className="text-center stickyStyle " style={{ left: '203px', zIndex: 10 }}><span>
                        <div className="text-ellipsis">
                          <Link to={{ pathname: `/monthlyDailyList/${item.orgCode}/${target_date_from}/${target_date_to}/SaleManagementTable` }} >
                            {item.orgName}
                          </Link>
                        </div>
                      </span></td>
                      <td className="text-right"><span>{item.netSales}</span></td>
                      <td className="text-right"><span>{item.tax}</span></td>
                      <td className="text-right"><span>{item.totalSales}</span></td>
                      <td className="text-right"><span>{item.cashVoucherSalesTotalAmount}</span></td>
                      <td className="text-right"><span>{item.guestCnt}</span></td>
                      <td className="text-right"><span>{item.perCustomerPrice}</span></td>
                      <td className="text-right"><span>{item.customers}</span></td>
                      <td className="text-right"><span>{item.perGroupPrice}</span></td>
                      <td className="text-right"><span>{item.totalDiscountAmount}</span></td>
                      <td className="text-right"><span>{item.totalDiscountCnt}</span></td>
                      <td className="text-right"><span>{item.voidAmount}</span></td>
                      <td className="text-right"><span>{item.voidCnt}</span></td>
                      <td className="text-right"><span>{item.stopAccountAmount}</span></td>
                      <td className="text-right"><span>{item.stopAccountCnt}</span></td>
                      <td className="text-right"><span>{item.posMenuCancelAmount}</span></td>
                      <td className="text-right"><span>{item.posMenuCancelCnt}</span></td>
                      <td className="text-right"><span>{item.changeReserve}</span></td>
                      <td className="text-right"><span>{item.cashStock}</span></td>
                      <td className="text-right"><span>{item.excessAndDeficiency}</span></td>
                      <td className="text-right"><span>{item.bankDepositAmount}</span></td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

export default MonthlySalesManagementTable;
