/* eslint-disable @typescript-eslint/no-unused-vars */
import { downloadCSV, getCompanyBankList } from 'api/companyBank';
import useToastNotification from 'hooks/useToastNotification';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

const IOptionList = [
  {
    value: 'all',
    label: '全て',
  },
];
export const useBankExport = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [targetDateFrom, setTarGetDateFrom] = useState<string>(moment(new Date()).format('YYYY/MM/DD'));
  const [targetDateTo, setTarGetDateTo] = useState<string>(moment(new Date()).format('YYYY/MM/DD'));
  const [valueCheck, setValueCheck] = useState<string>('all');
  const [optionList, setOptionList] = useState<any>(IOptionList);
  const { errorNotification } = useToastNotification();
  const handleChangeCheckBox = (value:string) => {
    setValueCheck(value);
  };

  useEffect(() => {
    const param = {
      hasOther: true,
    };
    getCompanyBankList(param).then((response: any) => {
      setIsLoading(false);
      if (response) {
        const newOptionList = response.companyBankList.map((companyBank: any) => ({
          value: companyBank.bankCode,
          label: companyBank.bankName,
        }));
        const newArrOption = _.unionBy(IOptionList, newOptionList, 'label');
        setOptionList(newArrOption);
      }
    });
  }, []);

  const handleDownloadCsv = useCallback(() => {
    const param = {
      bankCode: valueCheck,
      depositStartDate: targetDateFrom,
      depositEndDate: targetDateTo,
    };
    downloadCSV('入出金仕訳データ', param).catch((error) => {
      if (error && error.response && error.response.data) {
        errorNotification(error.response.data.defaultMessage);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [errorNotification, targetDateFrom, targetDateTo, valueCheck]);

  return {
    isLoading,
    optionList,
    targetDateFrom,
    setTarGetDateFrom,
    targetDateTo,
    setTarGetDateTo,
    valueCheck,
    handleChangeCheckBox,
    handleDownloadCsv,
  };
};

export default useBankExport;
