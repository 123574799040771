/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/core';
import moment from 'moment';
import { Link } from 'react-router-dom';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import SortIcon from 'components/molecules/SortIcon';
import { grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';
import useHeadquarterPointedOutList, { useDownload } from 'components/organismos/master/general/salesPage/headquarterPointedOutList/hook';

const styles = css({
  fontWeight: 400,
  fontSize: 14,
});

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 1,
});

const DEFAULT_SORT_FIELD = { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number' } as FieldSortState

const HeadquarterPointedOutListTable: React.FC<{
    listHeadquarterPointedOut: any;
    orgName: any;
    selectedMonth: any;
    roleScreen: any;
  }> = ({
    listHeadquarterPointedOut,
    orgName,
    selectedMonth,
    roleScreen,
  }) => {
    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(listHeadquarterPointedOut, DEFAULT_SORT_FIELD);

    const searchResult: any[] = [];
    useEffect(() => {
      dataSort.map((item: any, index: any) => {
        searchResult.push([
          item.targetDay || '',
          item.orgName || '',
          item.dispOrder || '',
          item.pointedOutStatus || '',
          item.overview || '',
          item.contents || '',
          item.hqSharedMatters || '',
          moment(item.createDate).format('YYYY-MM-DD HH:mm'),
          item.confirmedDay,
        ]);
        return true;
      });
    }, [listHeadquarterPointedOut, searchResult]);

    const { downloadCsv, downloadExcel } = useDownload();

    const headerInformation = [
      ['日付', '店舗名', '並び順', '状態', '指摘概要', '指摘内容', '本部連絡欄', '指摘日', '確認日'],
    ];

    const data = {
      footer: '',
      formName: '本部用指摘内容確認帳票',
      targetPeriod: `対象期間: ${moment(selectedMonth).format('YYYY-MM')}`,
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: [],
    };

    let mergedRegions: any[] = [];
    const codeColumnIndex: any[] = [];

    const dataExcel = {
      formName: '本部用指摘内容確認帳票',
      targetPeriod: `対象期間: ${moment(selectedMonth).format('YYYY-MM')}`,
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex,
    };

    const handleExportCSV = () => downloadCsv(data);

    const handleExportExcel = () => {
      if (mergedRegions) {
        mergedRegions = [];
      }
      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [0];
      dataExcel.headerInformation = [
        ['日付', '店舗名', '並び順', '状態', '指摘概要', '指摘内容', '本部連絡欄', '指摘日', '確認日'],
      ];
      return downloadExcel(dataExcel);
    };

  const renderHeaderColumn = useCallback((displayName: string, columnSortField: FieldSortState, rowSpan: number, className: string = "") => (
    <th rowSpan={rowSpan} className={className} onClick={e => sortByFieldPath(columnSortField.fieldPath, columnSortField.sortType)}>
      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
        <span style={{ margin: 'auto' }}>{displayName}</span>
        {sortField.fieldPath === columnSortField.fieldPath && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
        {sortField.fieldPath !== columnSortField.fieldPath && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
      </div>
    </th>
    ), [sortField]);

  return (
    <div>
      {
        roleScreen && roleScreen.downloadFlag === 1 && (
        <FlexBox>
          <FlexBoxItem>
            <IconLabelButton
              onClick={handleExportCSV}
              iconType="download"
              text=" CSV出力"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <IconLabelButton
              onClick={handleExportExcel}
              iconType="download"
              text=" EXCEL出力"
            />
          </FlexBoxItem>
        </FlexBox>
      )}
      <div
        className="table-responsive"
        style={{
          display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', marginTop: 20, maxHeight: 600,
        }}
      >
        <table id="headquarterPointedOut" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }}>
          <thead>
            <tr>
              { renderHeaderColumn('日付', { fieldPath: 'targetDay', sort: 'asc', sortType: 'date'}, 1) }
              { renderHeaderColumn('店舗名', { fieldPath: 'orgName', sort: 'asc', sortType: 'string'}, 1) }
              { renderHeaderColumn('並び順', { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number'}, 1) }
              { renderHeaderColumn('状態', { fieldPath: 'pointedOutStatusDispOrder', sort: 'asc', sortType: 'number'}, 1) }
              { renderHeaderColumn('指摘概要', { fieldPath: 'overview', sort: 'asc', sortType: 'string'}, 1) }
              { renderHeaderColumn('指摘内容', { fieldPath: 'contents', sort: 'asc', sortType: 'string'}, 1) }
              { renderHeaderColumn('本部連絡欄', { fieldPath: 'hqSharedMatters', sort: 'asc', sortType: 'string'}, 1) }
              { renderHeaderColumn('指摘日', { fieldPath: 'createDate', sort: 'asc', sortType: 'date'}, 1) }
              { renderHeaderColumn('確認日', { fieldPath: 'confirmedDay', sort: 'asc', sortType: 'date'}, 1) }
            </tr>
          </thead>
            {
                  dataSort && dataSort.length > 0 ? dataSort.map((headquarterPointedOut: any, index: number) => (
                  <tbody key={index} css={styles}>
                    <tr>
                      <td>
                      {
                          <Link target={"_blank"} to={{ pathname: `/salesDailyReport/${headquarterPointedOut.orgCode}/${moment(headquarterPointedOut.targetDay).format('YYYY-MM-DD')}` }}>
                            {headquarterPointedOut.targetDay}
                          </Link>
                        }
                      </td>
                      <td className="textLeft">
                        {headquarterPointedOut.orgName}
                      </td>
                      <td>
                        {headquarterPointedOut.dispOrder}
                      </td>
                      <td className="textLeft">
                        {headquarterPointedOut.pointedOutStatus}
                      </td>
                      <td className="textLeft">
                        {headquarterPointedOut.overview}
                      </td>
                      <td className="textLeft">
                        {headquarterPointedOut.contents}
                      </td>
                      <td className="textLeft">
                        {headquarterPointedOut.hqSharedMatters}
                      </td>
                      <td>
                        {moment(headquarterPointedOut.createDate).format('YYYY-MM-DD HH:mm')}
                      </td>
                      <td>
                      {moment(headquarterPointedOut.confirmedDay).valueOf() > -25200000 || moment.isMoment(headquarterPointedOut.confirmedDay) ? headquarterPointedOut.confirmedDay : null}
                      </td>
                    </tr>
                  </tbody>
                  )) : <tbody css={styles}></tbody>
              }
        </table>
      </div>
    </div>
  );
};
export default HeadquarterPointedOutListTable;
