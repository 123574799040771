/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import moment from 'moment';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormContents from 'components/atoms/Form/FormContents';
import FormLabel from 'components/atoms/Form/FormLabel';
import BlockUI from 'components/molecules/BlockUi';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import IncomeAndExpenditureScheduleMonthlyTable from './incomeAndExpenditureScheduleMonthlyTable';
import useIncomeAndExpenditureScheduleMonthly from './hooks';
import YearPicker from 'components/molecules/YearPicker/YearPicker';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const IncomeAndExpenditureScheduleMonthlyLayout = () => {

    const [orgCode, setOrgCode] = useState(sessionStorage.getItem('sessionOrgSelect/incomeAndExpenditureScheduleMonthly') ? sessionStorage.getItem('sessionOrgSelect/incomeAndExpenditureScheduleMonthly') : sessionStorage.getItem("loginUser.orgCode"));
    const [orgName, setOrgName] = useState(sessionStorage.getItem('sessionOrgNameSelected') ? sessionStorage.getItem('sessionOrgNameSelected') : sessionStorage.getItem("loginUser.orgName"));
    const [categoryAry, setCategoryAry] = useState<Array<any> | undefined>(undefined);

    const year = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState<number>(year);

    const { incomeAndExpenditureScheduleMonthly, getIsLoading } = useIncomeAndExpenditureScheduleMonthly(
        String(orgCode),
        moment(new Date(selectedYear, 0)).startOf('year').format('YYYY/MM/DD'),
        moment(new Date(selectedYear, 0)).endOf('year').format('YYYY/MM/DD'),
        categoryAry,
    );

    const functionType = 3;
    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

    const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

    return (
        <div>
            <FormContents>
                <div className="IncomeAndExpenditureSchedule">
                    <FlexBox>
                        <FlexBoxItem width="100px">
                            <FormLabel
                                label="対象期間"
                            />
                        </FlexBoxItem>
                        <FlexBoxItem>
                            <YearPicker
                                selectedYear={selectedYear}
                                onChange={(year: number) => {
                                    setSelectedYear(year);
                                }}
                                setAutoLeft={false}
                            />
                        </FlexBoxItem>

                    </FlexBox>
                </div>

                <div>
                    <OrganizationFilteredTree
                        functionType={functionType}
                        staffCode={staffCode}
                        addAllItem={false}
                        initOrgValue={String(orgCode)}
                        orgLabel="店舗名"
                        orgCallback={(args: any) => {
                            setOrgCode(String(args));
                        }}
                        orgCategoryCallback={
                            (args: Array<string>) => {
                                setCategoryAry(args);
                            }
                        }
                        orgNameCallback={(arg: any) => {
                            setOrgName(String(arg));
                            sessionStorage.setItem('sessionOrgNameSelected', arg);
                        }}

                    />
                </div>

                <div>
                    <div style={{ marginTop: '30px' }}>
                        <div className="row">
                            <BlockUI blocking={getIsLoading}>
                                <IncomeAndExpenditureScheduleMonthlyTable
                                    year={selectedYear}
                                    incomeAndExpenditureScheduleMonthly={incomeAndExpenditureScheduleMonthly}
                                    orgCode={orgCode}
                                    orgName={orgName}
                                    roleScreen={roleScreen}
                                />
                            </BlockUI>
                        </div>
                    </div>
                </div>

            </FormContents>
        </div>
    );
};

export default IncomeAndExpenditureScheduleMonthlyLayout;
