import ApiClient from 'api/ApiClient';

export const getInboundStatement = async (params: any, appendUrl: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/inboundDetail/${companyCode}?${appendUrl}`, params);
  return response.data;
};

export const getAdvertisingStatement = async (params: any, appendUrl: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/advertisingDetail/${companyCode}?${appendUrl}`, params);
  return response.data;
};

export const getOtherDetails = async (params: any, appendUrl: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/otherDetail/${companyCode}?${appendUrl}`, params);
  return response.data;
};

export const getSalesCategory = async (params: any, appendUrl: any) : Promise<any> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await ApiClient.get(`/v1/sales/list/standard/monthly/salesCategory/${companyCode}?${appendUrl}`, params);
    return response.data;
};

export const downloadReportCsv = async (monthlyReport: any, fileName: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsvPost(`/v1/reportExportFile/csv/${companyCode}`, {}, monthlyReport, fileName);
};

export const downloadReportExcel = async (monthlyReport: any, fileName: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadExcelPost(`/v1/reportExportFile/excel/${companyCode}`, {}, monthlyReport, fileName);
};

// InboundStatement
export const downloadCsvInboundStatement = async (queryString: string, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadFile(`/v1/sales/list/standard/monthly/inboundDetail/exportFile/csv/${companyCode}?${queryString}`, {}, fileName);
};

export const downloadExcelInboundStatement = async (queryString: string, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadFile(`/v1/sales/list/standard/monthly/inboundDetail/exportFile/excel/${companyCode}?${queryString}`, {}, fileName);
};

// AdvertisingStatement
export const downloadCsvAdvertisingStatement = async (queryString: string, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadFile(`/v1/sales/list/standard/monthly/advertisingDetail/exportFile/csv/${companyCode}?${queryString}`, {}, fileName);
};

export const downloadExcelAdvertisingStatement = async (queryString: string, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadFile(`/v1/sales/list/standard/monthly/advertisingDetail/exportFile/excel/${companyCode}?${queryString}`, {}, fileName);
};

// OtherDetails
export const downloadCsvOtherDetails = async (queryString: string, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadFile(`/v1/sales/list/standard/monthly/otherDetail/exportFile/csv/${companyCode}?${queryString}`, {}, fileName);
};

export const downloadExcelOtherDetails = async (queryString: string, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadFile(`/v1/sales/list/standard/monthly/otherDetail/exportFile/excel/${companyCode}?${queryString}`, {}, fileName);
};

// SalesCategory
export const downloadCsvSalesCategory = async (queryString: string, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadFile(`/v1/sales/list/standard/monthly/salesCategory/exportFile/csv/${companyCode}?${queryString}`, {}, fileName);
};

export const downloadExcelSalesCategory = async (queryString: string, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadFile(`/v1/sales/list/standard/monthly/salesCategory/exportFile/excel/${companyCode}?${queryString}`, {}, fileName);
};

