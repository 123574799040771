import { IWorkingStatus, IYearMonth, IApplyResult, ILeaveStatus } from './../components/pages/master/workingStatus/interface';
import ApiClient from "./ApiClient";

export const getSearchYearMonth = async (staffCode: string, targetDate: string): Promise<IYearMonth> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    staffCode,
    targetDate,
  };
  const { data } = await ApiClient.get(`/v1/employments/searchYearMonth/${companyCode}`, query, {});
  return data;
};

export const getWorkRecords = async (staffCode: string, targetYm: string): Promise<IWorkingStatus> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    staffCode,
    targetYm,
  };
  const { data } = await ApiClient.get(`/v2/stamplist/list/staff/${companyCode}`, query);
  return data;
};

export const getRequestResult = async (staffCode: string, targetYm: string): Promise<IApplyResult[]> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {staffCode,targetYm};
  const { data } = await ApiClient.get(`/v2/attendModifier/personal/applist/${companyCode}`, query);
  return data;
};

export const getLeaveStatus = async (staffCode: string, targetYm: string): Promise<ILeaveStatus> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {staffCode,targetYm};
  const { data } = await ApiClient.get(`/v2/stamplist/holiday/staff/${companyCode}`, query);
  return data;
};