import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import PettyCashManagement from 'components/pages/master/storeManagement/pettyCash/pettyCashManagement/index'

const PettyCashPage = () => (
  <SidebarTemplate pageTitle="小口現金管理">
    <PettyCashManagement />
  </SidebarTemplate>
);
export default PettyCashPage;
