/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import moment from 'moment';
import {
  Calendar as ReactCalendar, momentLocalizer, Event, View, Components, Messages,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { grayScale } from 'components/styles';
import MonthHeader from './MonthHeader';
import DateHeader from './DateHeader';
import EventWrapper from './EventWrapper';

const localizer = momentLocalizer(moment);

const createStyle = (height: string) => css({
  height,

  /*
   * react-big-calendarのcssをカスタマイズ
   */
  // ボーダーを無効化
  '.rbc-month-view, .rbc-month-row, .rbc-header, .rbc-day-bg': {
    border: 'none',
  },

  // 背景色を無効化
  '.rbc-today, .rbc-off-range-bg': {
    backgroundColor: 'transparent',
  },

  // 曜日ヘッダー
  '.rbc-month-header': {
    padding: '12px 0',
    borderBottom: `1px solid ${grayScale.gray10}`,
  },

  // 選択中のイベント
  '.rbc-event.rbc-selected': {
    backgroundColor: 'transparent',
  },
  '.rbc-show-more': {
    fontSize: '12px',
    fontWeight: 'normal',
    color: '#333333',
    textAlign: 'right',
    textDecoration: 'none',
    paddingRight: '10px',
  },
  // '.rbc-event-content': {
  //   whiteSpace: 'pre-wrap',
  // },
});

const Calendar: React.FC<{
  date: Date;
  setDate: (date: Date) => void;
  events: Array<Event>;
  views: Array<View>;
  onDrillDown?: (date: Date, view: View) => void;
  height?: string;
  components?: Components;
  messages?: Messages;
}> & {
  DateHeader: typeof DateHeader;
} = ({
  date, setDate, events, views, onDrillDown, height = '600px', components, messages,
}) => (
  <div css={createStyle(height)}>
    <ReactCalendar
      // Settings
      localizer={localizer}
      startAccessor="start"
      endAccessor="end"

      defaultView={views[0]}

      // 日付移動の制御
      date={date}
      onNavigate={(newDate: Date) => {
        setDate(newDate);
      }}

      // 日付バーをクリックした際に表示するview
      drilldownView="day"
      onDrillDown={onDrillDown}

        // イベントデータ
      events={events}

        // 表示可能なview
      views={views}

      // 表示するviewの制御
      // onView={(selectView) => {
      //   setView(selectView);
      // }}
      // view={view}

      // イベントをクリックした際の動作
      // TODO イベントクリックで週表示にしたいが、エラーが発生してしまうため別途方法を考える
      // onSelectEvent={(event: Event) => {
      //   setDate(event.start || new Date());
      //   setView('day');
      // }}

      // コンポーネントの上書き
      components={{
        month: {
          header: components?.month?.header || MonthHeader,
          event: components?.month?.event,
          dateHeader: components?.month?.dateHeader || DateHeader,
        },
        toolbar: components?.toolbar,
        eventWrapper: components?.eventWrapper || EventWrapper,
        // event: EventCell,
      }}
      messages={messages}
      length={1}
    />

  </div>
);

Calendar.DateHeader = DateHeader;

export default Calendar;
