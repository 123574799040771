/**
 * APIのデータ型
 */
export type CurrentTime = {
  dateString: string;
  timeString: string;
  serverDateTime: Date;
}

export class CurrentTimeDomain {
  constructor(private rawData: CurrentTime) {
    // do nothing
  }

  static generateInitial(): CurrentTimeDomain {
    return new CurrentTimeDomain({
      dateString: '',
      timeString: '',
      serverDateTime: new Date(),
    });
  }

  getRawData(): CurrentTime {
    return this.rawData;
  }

  get dateString(): string {
    return this.rawData.dateString;
  }

  set dateString(dateString: string) {
    this.rawData.dateString = dateString;
  }

  get timeString(): string {
    return this.rawData.timeString;
  }

  set timeString(timeString: string) {
    this.rawData.timeString = timeString;
  }

  get serverDateTime(): Date {
    return this.rawData.serverDateTime;
  }

  set serverDateTime(serverDateTime: Date) {
    this.rawData.serverDateTime = serverDateTime;
  }
}
