import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import CommentListManagement from 'components/pages/master/storeManagement/commentList/commentListManagement/index';

const CommentListPage = () => (
  <SidebarTemplate pageTitle="日次コメント帳票">
    <CommentListManagement />
  </SidebarTemplate>
);
export default CommentListPage;
