/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import PrimaryButton from 'components/atoms/Button';

const styles = {
  wrapper: css({
    padding: '15px 20px',
    display: 'flex',
    alignItems: 'center',
  }),
  button: css({
    marginLeft: 'auto',
  }),
  buttonCancel: css({
    marginRight: 'auto',
  }),
};


/**
 * モーダルのフッター
 *  - Submitボタンを持つ
 */
const Footer: React.FC<{
  submitText: string
  closeText: string
  closeHandler: () => void
  onSubmit: () => void
  isShowButtonClose: boolean
  optionalButtonText?: string;
  optionalButtonSubmit?: () => void;
  isDisabledAll?: boolean;
}> = ({
  submitText, onSubmit, closeHandler, closeText, isShowButtonClose, optionalButtonText, optionalButtonSubmit, isDisabledAll = false,
}) => (
  <div css={styles.wrapper}>
    {optionalButtonText && (
      <div>
        <PrimaryButton
          text={optionalButtonText}
          onClick={optionalButtonSubmit}
          ghost={true}
          disabled={isDisabledAll}
        />
      </div>
    )}
    <div css={styles.buttonCancel}>
      {isShowButtonClose
      && (
      <PrimaryButton
        text={closeText}
        onClick={closeHandler}
        ghost={true}
        disabled={isDisabledAll}
      />
      )}

    </div>
    {submitText && (
      <div css={styles.button}>
        <PrimaryButton
          text={submitText}
          onClick={onSubmit}
          disabled={isDisabledAll}
        />
      </div>
    )}

  </div>
);

export default Footer;
