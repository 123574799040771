export interface AccountTitleSubMSTItem {
    companyCode: string;
    createUser: string,
    createDate: string,
    updateUser: string,
    updateDate: string,
    accountTitleSubId: string;
    accountTitleId: string;
    accountTitleSubCode: string;
    accountTitleSubName: string;

  }

export default class AccountTitleSubMSTItemDomain {
  constructor(private rawData: AccountTitleSubMSTItem) {
    // do nothing
  }

  static generateInitial(): AccountTitleSubMSTItemDomain {
    return new AccountTitleSubMSTItemDomain({
      companyCode: '',
      createUser: '',
      createDate: '',
      updateUser: '',
      updateDate: '',
      accountTitleSubId: '',
      accountTitleId: '',
      accountTitleSubCode: '',
      accountTitleSubName: '',
    });
  }

  getRawData(): AccountTitleSubMSTItem {
    return this.rawData;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  get accountTitleId(): string {
    return this.rawData.accountTitleId;
  }

  set accountTitleId(accountTitleId: string) {
    this.rawData.accountTitleId = accountTitleId;
  }

  get accountTitleSubId(): string {
    return this.rawData.accountTitleSubId;
  }

  set accountTitleSubId(accountTitleSubId: string) {
    this.rawData.accountTitleSubId = accountTitleSubId;
  }


  get accountTitleSubCode(): string {
    return this.rawData.accountTitleSubCode;
  }

  set accountTitleSubCode(accountTitleSubCode: string) {
    this.rawData.accountTitleSubCode = accountTitleSubCode;
  }

  get accountTitleSubName(): string {
    return this.rawData.accountTitleSubName;
  }

  set accountTitleSubName(accountTitleSubName: string) {
    this.rawData.accountTitleSubName = accountTitleSubName;
  }
}
