/**
 * APIのデータ型
 */

export interface SpecialSalaryPattern {
  specialSalaryPatternId: string;
  specialSalaryPatternCode: string;
  specialSalaryPatternName: string;
  applyStartMonthStr: any;
  addSalary: string;
  sameDays: any;
  fixedDays: any;
  patOrgCodes: any;
  holidayFlag: boolean;
  saturdayFlag: boolean;
  sundayFlag: boolean;
  mondayFlag: boolean;
  tuesdayFlag: boolean;
  wednesdayFlag: boolean;
  thursdayFlag: boolean;
  fridayFlag: boolean;
  createUser: string;
  updateUser: string;
}

export default class SpecialSalaryPatternDomain {
  constructor(private rawData: SpecialSalaryPattern) {
    // Todo
  }

  static generateInitial(): SpecialSalaryPatternDomain {
    return new SpecialSalaryPatternDomain({
      specialSalaryPatternId: '',
      specialSalaryPatternCode: '',
      specialSalaryPatternName: '',
      applyStartMonthStr: '',
      addSalary: '',
      sameDays: [],
      fixedDays: [],
      holidayFlag: false,
      saturdayFlag: false,
      sundayFlag: false,
      mondayFlag: false,
      tuesdayFlag: false,
      wednesdayFlag: false,
      thursdayFlag: false,
      fridayFlag: false,
      patOrgCodes: [],
      createUser: '',
      updateUser: '',
    });
  }

  getRawData(): SpecialSalaryPattern {
    return this.rawData;
  }

  getRawDataPost(): SpecialSalaryPattern {
    return {
      specialSalaryPatternId: this.rawData.specialSalaryPatternId,
      specialSalaryPatternCode: this.rawData.specialSalaryPatternCode,
      specialSalaryPatternName: this.rawData.specialSalaryPatternName,
      applyStartMonthStr: this.rawData.applyStartMonthStr,
      addSalary: this.rawData.addSalary,
      sameDays: this.rawData.sameDays,
      fixedDays: this.rawData.fixedDays,
      patOrgCodes: this.rawData.patOrgCodes,
      holidayFlag: this.rawData.holidayFlag,
      saturdayFlag: this.rawData.saturdayFlag,
      sundayFlag: this.rawData.sundayFlag,
      mondayFlag: this.rawData.mondayFlag,
      tuesdayFlag: this.rawData.tuesdayFlag,
      wednesdayFlag: this.rawData.wednesdayFlag,
      thursdayFlag: this.rawData.thursdayFlag,
      fridayFlag: this.rawData.fridayFlag,
      createUser: this.rawData.createUser,
      updateUser: this.rawData.updateUser,
    };
  }

  getRawDataSetting(): SpecialSalaryPattern {
    return {
      specialSalaryPatternId: this.rawData.specialSalaryPatternId,
      specialSalaryPatternCode: this.rawData.specialSalaryPatternCode,
      specialSalaryPatternName: this.rawData.specialSalaryPatternName,
      applyStartMonthStr: this.rawData.applyStartMonthStr,
      addSalary: this.rawData.addSalary,
      sameDays: this.rawData.sameDays,
      fixedDays: this.rawData.fixedDays,
      patOrgCodes: this.rawData.patOrgCodes,
      holidayFlag: this.rawData.holidayFlag,
      saturdayFlag: this.rawData.saturdayFlag,
      sundayFlag: this.rawData.sundayFlag,
      mondayFlag: this.rawData.mondayFlag,
      tuesdayFlag: this.rawData.tuesdayFlag,
      wednesdayFlag: this.rawData.wednesdayFlag,
      thursdayFlag: this.rawData.thursdayFlag,
      fridayFlag: this.rawData.fridayFlag,
      createUser: this.rawData.createUser,
      updateUser: this.rawData.updateUser,
    };
  }

  get specialSalaryPatternCode(): string {
    return this.rawData.specialSalaryPatternCode;
  }

  set specialSalaryPatternCode(specialSalaryPatternCode: string) {
    this.rawData.specialSalaryPatternCode = specialSalaryPatternCode;
  }

  get specialSalaryPatternName(): string {
    return this.rawData.specialSalaryPatternName;
  }

  set specialSalaryPatternName(specialSalaryPatternName: string) {
    this.rawData.specialSalaryPatternName = specialSalaryPatternName;
  }

  get applyStartMonthStr(): any {
    return this.rawData.applyStartMonthStr;
  }

  set applyStartMonthStr(date: any) {
    this.rawData.applyStartMonthStr = date;
  }

  get addSalary(): string {
    return this.rawData.addSalary;
  }

  set addSalary(addSalary: string) {
    this.rawData.addSalary = addSalary;
  }

  get holidayFlag(): boolean {
    return this.rawData.holidayFlag;
  }

  set holidayFlag(holidayFlag: boolean) {
    this.rawData.holidayFlag = holidayFlag;
  }

  get saturdayFlag(): boolean {
    return this.rawData.saturdayFlag;
  }

  set saturdayFlag(saturdayFlag: boolean) {
    this.rawData.saturdayFlag = saturdayFlag;
  }

  get sundayFlag(): boolean {
    return this.rawData.sundayFlag;
  }

  set sundayFlag(sundayFlag: boolean) {
    this.rawData.sundayFlag = sundayFlag;
  }

  get mondayFlag(): boolean {
    return this.rawData.mondayFlag;
  }

  set mondayFlag(mondayFlag: boolean) {
    this.rawData.mondayFlag = mondayFlag;
  }

  get tuesdayFlag(): boolean {
    return this.rawData.tuesdayFlag;
  }

  set tuesdayFlag(tuesdayFlag: boolean) {
    this.rawData.tuesdayFlag = tuesdayFlag;
  }

  get wednesdayFlag(): boolean {
    return this.rawData.wednesdayFlag;
  }

  set wednesdayFlag(wednesdayFlag: boolean) {
    this.rawData.wednesdayFlag = wednesdayFlag;
  }

  get thursdayFlag(): boolean {
    return this.rawData.thursdayFlag;
  }

  set thursdayFlag(thursdayFlag: boolean) {
    this.rawData.thursdayFlag = thursdayFlag;
  }

  get fridayFlag(): boolean {
    return this.rawData.fridayFlag;
  }

  set fridayFlag(fridayFlag: boolean) {
    this.rawData.fridayFlag = fridayFlag;
  }

  get sameDays(): any {
    return this.rawData.sameDays;
  }

  set sameDays(sameDays) {
    this.rawData.sameDays = sameDays;
  }

  get fixedDays(): any {
    return this.rawData.fixedDays;
  }

  set fixedDays(fixedDays) {
    this.rawData.fixedDays = fixedDays;
  }

  get patOrgCodes(): any {
    return this.rawData.patOrgCodes;
  }

  set patOrgCodes(patOrgCodes: any) {
    this.rawData.patOrgCodes = patOrgCodes;
  }
}
