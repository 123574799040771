import React, { SVGProps } from 'react';

const Settings: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M11.5 2.375c.345 0 .625.28.625.625v1.79l.056.024 1.266-1.266a.625.625 0 01.884 0l2.121 2.121a.625.625 0 010 .884L15.188 7.82l.023.055H17c.345 0 .625.28.625.625v3c0 .345-.28.625-.625.625h-1.79a5.591 5.591 0 01-.024.056l1.266 1.266a.625.625 0 010 .884l-2.121 2.121a.625.625 0 01-.884 0l-1.267-1.265a5.591 5.591 0 01-.054.022L12.125 17c0 .345-.28.625-.625.625h-3A.625.625 0 017.875 17v-1.79a5.593 5.593 0 01-.056-.024l-1.266 1.266a.625.625 0 01-.884 0l-2.121-2.121a.625.625 0 010-.884l1.265-1.267a5.592 5.592 0 01-.022-.054L3 12.125a.625.625 0 01-.625-.625v-3c0-.345.28-.625.625-.625h1.79l.023-.055-1.266-1.267a.625.625 0 010-.884L5.67 3.548a.625.625 0 01.884 0l1.266 1.265c.02-.007.038-.015.056-.023V3c0-.345.28-.625.625-.625h3zm-.625 1.25h-1.75v1.604a.625.625 0 01-.437.596 4.344 4.344 0 00-.71.294.625.625 0 01-.732-.111L6.111 4.873 4.873 6.111l1.135 1.135c.193.193.238.49.111.731a4.344 4.344 0 00-.294.71.625.625 0 01-.596.438H3.625v1.75l1.605.001c.273 0 .514.178.596.438.076.245.175.482.293.708a.625.625 0 01-.111.732l-1.135 1.135 1.238 1.238 1.134-1.135a.625.625 0 01.732-.112c.227.12.465.218.71.295.261.082.439.323.439.596v1.604h1.75V14.77c0-.272.177-.514.438-.596.244-.076.481-.175.708-.293a.625.625 0 01.731.111l1.136 1.135 1.238-1.238-1.135-1.134a.625.625 0 01-.112-.732c.12-.227.218-.465.295-.71a.625.625 0 01.596-.438h1.604v-1.75h-1.604a.625.625 0 01-.596-.438 4.344 4.344 0 00-.294-.71.625.625 0 01.111-.73l1.135-1.136-1.238-1.238-1.134 1.135a.625.625 0 01-.732.112 4.344 4.344 0 00-.71-.295.625.625 0 01-.438-.596V3.625zM10 7.375a2.625 2.625 0 110 5.25 2.625 2.625 0 010-5.25zm0 1.25a1.375 1.375 0 100 2.75 1.375 1.375 0 000-2.75z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Settings;
