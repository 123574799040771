/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { layer } from 'components/styles';

const style = css({
  margin: '8px 16px',
  padding: '20px 24px',
  backgroundColor: '#FFFFFF',
  boxShadow: layer.layer02,
});

const subStyle = css({
  margin: '5px 10px',
  padding: '5px 10px',
  backgroundColor: '#FFFFFF',
  boxShadow: layer.layer02,
});

const fullWidthStyle = css({
  padding: '20px 24px',
  backgroundColor: '#FFFFFF',
  boxShadow: layer.layer02,
});

const withoutTopStyle = css({
  padding: '0px 0px 20px 0px',
  backgroundColor: '#FFFFFF',
  boxShadow: layer.layer02,
});

const widthoutTopAndBottom = css({
  margin: '8px 16px',
  padding: '0px 24px 8px 24px',
  backgroundColor: '#FFFFFF',
  boxShadow: layer.layer02,
});

/**
 * フォームのコンテンツ
 */
const FormContents: React.FC<{isSubForm?: boolean, isToggle?: boolean, isFullWidthForm?: boolean, isWithoutTop?: boolean, scroll?: boolean, isWidthoutTopBottom?:boolean }> = ({
  children,
  isSubForm = false,
  isToggle = true,
  isFullWidthForm = false,
  isWithoutTop = false,
  isWidthoutTopBottom = false,
  scroll = false,
}) => (
  <div css={isFullWidthForm ? fullWidthStyle : isWithoutTop ? withoutTopStyle : isSubForm ? subStyle : isWidthoutTopBottom ? widthoutTopAndBottom : style} style={{ display: isToggle ? 'block' : 'none', overflowX: scroll ? 'scroll' : 'unset' }}>
    {children}
  </div>
);

export default FormContents;
