import React, { SVGProps } from 'react';

const Calendar: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M7.625 3v.875h4.75V3h1.25v.875H16.5c.345 0 .625.28.625.625v12c0 .345-.28.625-.625.625h-13a.625.625 0 01-.625-.625v-12c0-.345.28-.625.625-.625h2.875V3h1.25zm8.25 5.375H4.125v7.5h11.75v-7.5zm-9 4.125a.875.875 0 110 1.75.875.875 0 010-1.75zm3 0a.875.875 0 110 1.75.875.875 0 010-1.75zm3.25 0a.875.875 0 110 1.75.875.875 0 010-1.75zm-6.25-2.75a.875.875 0 110 1.75.875.875 0 010-1.75zm3 0a.875.875 0 110 1.75.875.875 0 010-1.75zm3.25 0a.875.875 0 110 1.75.875.875 0 010-1.75zm-6.75-4.625h-2.25v2h11.75v-2h-2.25V6h-1.25v-.875h-4.75V6h-1.25v-.875z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Calendar;
