
/**
 * API 所定労働時間マスタ
 */
export interface PrescribedHours{
    workingHoursId: string;
    workingHoursPatternCode: string;
    workingHoursPatternName: string;
}
export default class PrescribedHoursDomain {
  constructor(private rawData: PrescribedHours) {
    //
  }

  static generateInitial(): PrescribedHoursDomain {
    return new PrescribedHoursDomain({
      workingHoursId: '',
      workingHoursPatternCode: '',
      workingHoursPatternName: '',
    });
  }

  getRowData(): PrescribedHours {
    return this.rawData;
  }

  get workingHoursId(): string {
    return this.rawData.workingHoursId;
  }

  set workingHoursId(workingHoursId: string) {
    this.rawData.workingHoursId = workingHoursId;
  }

  get workingHoursPatternCode(): string {
    return this.rawData.workingHoursPatternCode;
  }

  set workingHoursPatternCode(workingHoursPatternCode: string) {
    this.rawData.workingHoursPatternCode = workingHoursPatternCode;
  }

  get workingHoursPatternName(): string {
    return this.rawData.workingHoursPatternName;
  }

  set workingHoursPatternName(workingHoursPatternName: string) {
    this.rawData.workingHoursPatternName = workingHoursPatternName;
  }
}
