import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { UpdateTimeSetting, getTimeSetting } from 'api/salesSetting';
// import { UpdateTimeSetting, getTimeSetting } from 'api/timeSetting';

import SalesSettingDomain from 'domain/master/general/salesSetting';
import useToastNotification from 'hooks/useToastNotification';

const useSalesSetting = () => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const { successNotification, errorNotification } = useToastNotification();
  const onSubmit = async (val: any) => {
    const submitTimeSetting = val.getRawDataWithoutNullData();
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
    }

    UpdateTimeSetting(companyCode, submitTimeSetting)
      .then((response: any) => {
        formik.setFieldValue('commentSubjectForBudgetProcess', response.commentSubjectForBudgetProcess)
        formik.setFieldValue('commentSubjectForStoreManagement', response.commentSubjectForStoreManagement)
        formik.setFieldValue('commentSubjectForOthers', response.commentSubjectForOthers)
        setConfirmModalOpen(false);
        successNotification('更新しました');
      })
      .catch((error: any) => {
        setConfirmModalOpen(false);
        if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
          let msgError = '';
          error.response.data.errors.map((iteam: { defaultMessage: string; }) => {
            msgError += `${iteam.defaultMessage} \n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification('Something wrong. please try again');
        }
      });
  };


  const formik = useFormik({
    initialValues: SalesSettingDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    getTimeSetting(companyCode).then((response: any) => {
      setLoading(false);
      formik.setValues(new SalesSettingDomain(response));
    }).catch((e) => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode]);


  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    setConfirmModalOpen,
    isLoading,
    setLoading,
  };
};

export default useSalesSetting;
