/**
 * API 勤怠再計算
 */

export interface ApprovalHolidayApplication {
  modifierApplicationId: string;
  orgCode: string;
  orgName: string;
  staffCode: string;
  staffName: string;
  targetDate: Date;
  transferHolidayDate: Date;
  applicationDate: Date;
  applicationStaffCode: string;
  applicationStaffName: string;
  applicationStatus: number;
  modifiyStatus: number;
  applicationReason: string;
  rejectReason: any;
  version: number;
  approvalStaffCode: string;
  approvalStaffName: string;
  approvalDate: any;
  workTimeData: any;
  achievementId: string;
  holidayName: string;
  applicationType: number;
  holidayId: string;
  isLegal: boolean;
  holidayDigestiveUnit: number;
  holidayStartHm: Date;
  holidayEndHm: Date;
  holidayTimes: number;
}

export default class ApprovalHolidayApplicationDomain {
  constructor(private rawData: ApprovalHolidayApplication) {
    // do nothing
  }

  static generateInitial(): ApprovalHolidayApplicationDomain {
    return new ApprovalHolidayApplicationDomain({
      modifierApplicationId: '',
      orgCode: '',
      orgName: '',
      staffCode: '',
      staffName: '',
      targetDate: new Date(),
      transferHolidayDate: new Date(),
      applicationDate: new Date(),
      applicationStaffCode: '',
      applicationStaffName: '',
      applicationStatus: 0,
      modifiyStatus: 0,
      applicationReason: '',
      rejectReason: '',
      version: 0,
      approvalStaffCode: '',
      approvalStaffName: '',
      approvalDate: '',
      workTimeData: {},
      achievementId: '',
      holidayName: '',
      applicationType: 0,
      holidayId: '',
      isLegal: false,
      holidayDigestiveUnit: 0,
      holidayStartHm: new Date(),
      holidayEndHm: new Date(),
      holidayTimes: 0,
    });
  }

  getRawData(): ApprovalHolidayApplication {
    return this.rawData;
  }

  get modifierApplicationId(): string {
    return this.rawData.modifierApplicationId;
  }

  set modifierApplicationId(modifierApplicationId: string) {
    this.rawData.modifierApplicationId = modifierApplicationId;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get applicationStaffCode(): string {
    return this.rawData.applicationStaffCode;
  }

  set applicationStaffCode(applicationStaffCode: string) {
    this.rawData.applicationStaffCode = applicationStaffCode;
  }

  get applicationDate(): Date {
    return this.rawData.applicationDate;
  }

  set applicationDate(applicationDate: Date) {
    this.rawData.applicationDate = applicationDate;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName(): string {
    return this.rawData.staffName;
  }

  set staffName(staffName: string) {
    this.rawData.staffName = staffName;
  }

  get targetDate(): Date {
    return this.rawData.targetDate;
  }

  set targetDate(targetDate: Date) {
    this.rawData.targetDate = targetDate;
  }

  get modifiyStatus(): number {
    return this.rawData.modifiyStatus;
  }

  set modifiyStatus(modifiyStatus: number) {
    this.rawData.modifiyStatus = modifiyStatus;
  }

  get applicationReason(): string {
    return this.rawData.applicationReason;
  }

  set applicationReason(applicationReason: string) {
    this.rawData.applicationReason = applicationReason;
  }

  get applicationStatus(): number {
    return this.rawData.applicationStatus;
  }

  set applicationStatus(applicationStatus: number) {
    this.rawData.applicationStatus = applicationStatus;
  }

  get approvalStaffCode(): string {
    return this.rawData.approvalStaffCode;
  }

  set approvalStaffCode(approvalStaffCode: string) {
    this.rawData.approvalStaffCode = approvalStaffCode;
  }

  get approvalStaffName(): string {
    return this.rawData.approvalStaffName;
  }

  set approvalStaffName(approvalStaffName: string) {
    this.rawData.approvalStaffName = approvalStaffName;
  }

  get rejectReason(): string {
    return this.rawData.rejectReason;
  }

  set rejectReason(rejectReason: string) {
    this.rawData.rejectReason = rejectReason;
  }

  get workTimeData(): Object {
    return this.rawData.workTimeData;
  }

  set workTimeData(workTimeData: Object) {
    this.rawData.workTimeData = workTimeData;
  }

  get version(): number {
    return this.rawData.version;
  }

  set version(version: number) {
    this.rawData.version = version;
  }

  get applicationStaffName(): string {
    return this.rawData.applicationStaffName;
  }

  set applicationStaffName(applicationStaffName: string) {
    this.rawData.applicationStaffName = applicationStaffName;
  }

  get approvalDate(): string {
    return this.rawData.approvalDate;
  }

  set approvalDate(approvalDate: string) {
    this.rawData.approvalDate = approvalDate;
  }

  get transferHolidayDate(): Date {
    return this.rawData.transferHolidayDate;
  }

  set transferHolidayDate(transferHolidayDate: Date) {
    this.rawData.transferHolidayDate = transferHolidayDate;
  }

  get achievementId(): string {
    return this.rawData.achievementId;
  }

  set achievementId(achievementId: string) {
    this.rawData.achievementId = achievementId;
  }

  get holidayName(): string {
    return this.rawData.holidayName;
  }

  set holidayName(holidayName: string) {
    this.rawData.holidayName = holidayName;
  }

  get applicationType(): number {
    return this.rawData.applicationType;
  }

  set applicationType(applicationType: number) {
    this.rawData.applicationType = applicationType;
  }

  get holidayId(): string {
    return this.rawData.holidayId;
  }

  set holidayId(holidayId: string) {
    this.rawData.holidayId = holidayId;
  }

  get isLegal(): boolean {
    return this.rawData.isLegal;
  }

  set isLegal(isLegal: boolean) {
    this.rawData.isLegal = isLegal;
  }

  get holidayDigestiveUnit(): number {
    return this.rawData.holidayDigestiveUnit;
  }

  set holidayDigestiveUnit(holidayDigestiveUnit: number) {
    this.rawData.holidayDigestiveUnit = holidayDigestiveUnit;
  }

  get holidayStartHm(): Date {
    return this.rawData.holidayStartHm;
  }

  set holidayStartHm(holidayStartHm: Date) {
    this.rawData.holidayStartHm = holidayStartHm;
  }

  get holidayEndHm(): Date {
    return this.rawData.holidayEndHm;
  }

  set holidayEndHm(holidayEndHm: Date) {
    this.rawData.holidayEndHm = holidayEndHm;
  }

  get holidayTimes(): number {
    return this.rawData.holidayTimes;
  }

  set holidayTimes(holidayTimes: number) {
    this.rawData.holidayTimes = holidayTimes;
  }
  // get

  // custom data use DataTable
}
