import React, { useMemo, useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import DatePickerForm from 'components/molecules/DatePickerForm';
import FormLabel from 'components/atoms/Form/FormLabel';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import BlockUI from 'components/molecules/BlockUi';
import NumberIndicationAnalysisTable from './NumberIndicationAnalysisTable';
import useNumberIndicationAnalysis from './hooks';
import DataTableSort from 'components/organismos/DataTableSort/DataTableSort';
import { Column } from 'react-table';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const functionType = 3;
const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
const NumberIndicationAnalysisLayout = () => {
    const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') ? sessionStorage.getItem('loginUser.orgCode') : '');
    const [orgName, setorgName] = useState(sessionStorage.getItem('loginUser.orgName') ? sessionStorage.getItem('loginUser.orgName') : '');
    const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
        undefined,
    ); 
    
    const { targetDateFrom, setTargetDateFrom, targetDateTo, setTargetDateTo, numberIndicationAnalysisData, getIsLoading, setNumberIndicationAnalysisData, targetPeriodData } = useNumberIndicationAnalysis(String(orgCode), categoryAry);
    const targetStoresData = `組織: ${orgCode} ${orgName}`;

    const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

    return (
        <div>
            <FormContents>
                <FlexBox>
                    <FlexBoxItem>
                        <DatePickerForm
                            dateFormat="yyyy年MM月"
                            label=""
                            date={new Date(targetDateFrom)}
                            changeDate={(date: Date) => {
                                if (date !== null) {
                                    setTargetDateFrom(String(date));
                                }
                            }}
                            isFullWidth={true}
                            showMonthYearPicker={true}

                        />
                    </FlexBoxItem>
                    <FlexBoxItem>
                        <span style={{ margin: '0 30px' }}>～</span>
                    </FlexBoxItem>
                    <FlexBoxItem>
                        <DatePickerForm
                            dateFormat="yyyy年MM月"
                            label=""
                            date={new Date(targetDateTo)}
                            changeDate={(date: Date) => {
                                if (date !== null) {
                                    setTargetDateTo(String(date));
                                }
                            }}
                            isFullWidth={true}
                            showMonthYearPicker={true}
                        />
                    </FlexBoxItem>
                </FlexBox>
                <OrganizationFilteredTree
                    orgCallback={(args: string) => { 
                        if (typeof (args) === 'string') {
                            setOrgCode(args);
                        } else {
                            setOrgCode(args[0]);
                        }
                    }}
                    functionType={functionType}
                    staffCode={staffCode}
                    addAllItem={true}
                    orgLabel="組織"
                    orgCategoryCallback={
                        (args: Array<string>) => {
                            setCategoryAr(args);
                        }
                    }
                    initOrgValue={String(orgCode)}
                    orgNameCallback={(arg: any) => {
                        setorgName(String(arg));
                        console.log(arg);

                    }}
                    isAlignItemsCenter={true}
                />

                <div>
                    <BlockUI blocking={getIsLoading}>
                        <NumberIndicationAnalysisTable
                            numberIndicationAnalysisData={numberIndicationAnalysisData}
                            setPageList={setNumberIndicationAnalysisData}
                            targetPeriodData={targetPeriodData}
                            targetStoresData={targetStoresData}
                            roleScreen={roleScreen}
                        />
                    </BlockUI>
                </div>
            </FormContents>
        </div>
    );
}

export default NumberIndicationAnalysisLayout;
