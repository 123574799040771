import React, { ReactNode } from 'react';
import { Container, Row, Col } from 'react-grid-system';

const TwoColumnLayout: React.FC<{
  children: {
    firstColumn:ReactNode,
    secondColumn:ReactNode
  }
}> = ({ children }) => (
  <Container fluid={true}>
    <Row style={{ overflowX: 'scroll', flexWrap: 'nowrap' }}>
      <div style={{
        minWidth: 800,
        width: '100%',
        display: 'flex',
      }}
      >
        <Col md={6} xs={12}>
          {children.firstColumn}
        </Col>
        <Col md={6} xs={12}>
          {children.secondColumn}
        </Col>
      </div>
    </Row>
  </Container>
);

export default TwoColumnLayout;
