import moment from 'moment';

export type OrganizationDataStaff = {
  id: {
    companyCode: string,
    staffCode: string,
    applyStartDate: Date,
  },
  applyEndDate: Date,
  orgCode: string,
  orgName: string,
  executiveCode: string,
  executiveName: string,
  executiveId: string,
}

export class OrganizationDataStaffDomain {
  constructor(private rawData: OrganizationDataStaff) {
    // do nothing
  }

  get applyStartDate(): Date {
    return this.rawData.id.applyStartDate;
  }

  get applyStartDateStr(): string {
    return moment(this.rawData.id.applyStartDate).format('YYYY年MM月DD日');
  }

  set applyStartDate(applyStartDate: Date) {
    this.rawData.id.applyStartDate = applyStartDate;
  }

  get applyEndDate(): Date {
    return this.rawData.applyEndDate;
  }

  get applyEndDateStr(): string {
    return moment(this.rawData.applyEndDate).format('YYYY年MM月DD日');
  }

  set applyEndDate(applyEndDate: Date) {
    this.rawData.applyEndDate = applyEndDate;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get executiveCode(): string {
    return this.rawData.executiveCode;
  }

  set executiveCode(executiveCode: string) {
    this.rawData.executiveCode = executiveCode;
  }

  get executiveName(): string {
    return this.rawData.executiveName;
  }

  set executiveName(executiveName: string) {
    this.rawData.executiveName = executiveName;
  }

  get executiveId(): string {
    return this.rawData.executiveName;
  }

  set executiveId(executiveId: string) {
    this.rawData.executiveId = executiveId;
  }
}
