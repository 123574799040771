import {
  useState, useCallback, useEffect,
} from 'react';
import { useFormik } from 'formik';
// import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import WorkingDaysDomain from 'domain/master/labor/workingDays';
import { createOrUpdateWorkingDays, getWorkingDaysById } from 'api/workingDay';
import useOrgTreesOptions from 'hooks/useOrgTreesOptions';
import useToastNotification from 'hooks/useToastNotification';

type WorkingDaysDomainnKey = keyof Pick<WorkingDaysDomain, 'workingDaysPatternCode'|'workingDaysPatternName'>;

//
export const useWorkingDaysDomainAddForm = (workingDaysId?: string) => {
  // history
  const history = useHistory();

  // modal confirm
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  // Modal alert
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const orgTreesOptions = useOrgTreesOptions();
  const { successNotification, errorNotification } = useToastNotification();

  // handle submit form
  const onSubmit = async (values: WorkingDaysDomain) => {
    if (values.workingDaysPatternCode === null) {
      errorNotification('パターンコードを入力してください。');
      return;
    }
    if (values.workingDaysPatternName === null) {
      errorNotification('パターン名を入力してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginUser = sessionStorage.getItem('loginUser.staffName') || '';
    const convertData = values.getRawData();

    delete (convertData as any).createDate;
    delete (convertData as any).updateDate;
    delete (convertData as any).workingDays;
    delete (convertData as any).workingDaysDetails.id;
    delete (convertData as any).inputOrgList;

    convertData.companyCode = companyCode;
    convertData.createUser = loginUser;
    convertData.updateUser = loginUser;

    delete (convertData as any).orgDaysList;
    // check process
    try {
      const response = await createOrUpdateWorkingDays(companyCode, convertData, 0);
      setConfirmModalOpen(false);
      if (!response.errors) {
        if (workingDaysId) {
          successNotification('更新しました。');
        } else {
          successNotification('登録しました。');
          history.push(`/masterWorkingDays/edit/${response.workingDaysId}/0`);
        }
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any, index: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: WorkingDaysDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  const targetYear = moment().format('YYYY');

  //
  useEffect(() => {
    if (workingDaysId !== undefined) {
      handleGetWorkingDays(targetYear, workingDaysId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetYear, workingDaysId]);


  /**
   * handle get working days & set formik data
   *
   * @param targetYear
   * @param workingDaysId
  */
  const handleGetWorkingDays = async (targetYearStr: any, id: any) => {
    getWorkingDaysById(targetYearStr, id).then((response: any) => {
      if (response) {
        const { workingDaysDetails } = response;
        if (workingDaysDetails != null) {
          formik.setValues(new WorkingDaysDomain(response));
          formik.setFieldValue('targetYearStr', targetYearStr);
        } else {
          const initalWorkingDay : WorkingDaysDomain = response;
          initalWorkingDay.workingDaysDetails = WorkingDaysDomain.generateInitial()
            .workingDaysDetails;
          formik.setValues(new WorkingDaysDomain(initalWorkingDay));
          formik.setFieldValue('targetYearStr', targetYearStr);
        }
      } else {
        // setValueWorkingHours([]);
      }
      return true;
    });
  };

  //
  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  //
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  // 詳細項目入力を表示するかどうか
  const [detailMode, setDetailMode] = useState(false);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    setToastModalOpen,
    handleGetWorkingDays,
    orgTreesOptions,
    history,
  };
};

export default {
  useWorkingDaysDomainAddForm,
};
