/** @jsx jsx */
import React from 'react';
import {
  css, jsx,
} from '@emotion/core';

import Modal from 'components/molecules/Modal';
import Table from 'components/molecules/Table';
import Checkbox from 'components/molecules/Checkbox';

import ManagerDomain from 'domain/manager';
// import OrganizationFilter from './OrganizationFilter';

import { useStaffList } from './hooks';
import OrganizationFilteredTree from '../OrganizationFilteredTree/OrganizationFilteredTree';

const OrganizationManagerAddModal: React.FC<{
    open: boolean;
    openHandler: (arg: boolean) => void;
    addManagerList: (arg: Array<ManagerDomain>) => void
}> = ({
  open, openHandler, addManagerList,
}) => {
  const {
    staffList, checkedStaffCodeList, checkHandler, onSubmit, orgMgrCode, setOrgMgrCode, setCheckedStaffList, setCheckedStaffCodeList,
  } = useStaffList(addManagerList, openHandler);
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';


  let checkWidthSM = false;
  const mq = window.matchMedia('(max-width: 1024px)');
  if (mq.matches) {
    checkWidthSM = true;
  } else {
    checkWidthSM = false;
  }

  const styles = {
    model: css({
      width: checkWidthSM ? '70%' : '50%',
    }),
  };

  const handleCloseModal = () => {
    setCheckedStaffList([]);
    setCheckedStaffCodeList([]);
    openHandler(false);
  }

  return (

    <Modal
      open={open}
      closeHandler={handleCloseModal}
      title="スタッフ選択"
      submitText="スタッフを追加"
      onSubmit={onSubmit}
      customStyle={styles.model}
    >
      <OrganizationFilteredTree
        functionType={1}
        staffCode={staffCode}
        orgLabel="組織名"
        initOrgValue={String(orgMgrCode)}
        orgCallback={(args: string | Array<string>) => {
          setOrgMgrCode(String(args));
        }}
        addAllItem={false}
        styles={{
          label: {
            toggleSwitch: css({ marginLeft: '0px' }),
          },
          toggle: {
            width: '13rem',
          },
        }}
      />


      <div css={css({ minHeight: '300px' })}>
        <Table>
          <thead>
            <tr>
              <Table.HeaderCell customStyle={css({ width: '10%' })} />
              <Table.HeaderCell customStyle={css({ width: '30%' })}>
                スタッフコード
              </Table.HeaderCell>
              <Table.HeaderCell customStyle={css({ width: '60%' })}>
                スタッフ名
              </Table.HeaderCell>
            </tr>
          </thead>
          <tbody>
            {staffList && staffList.length > 0 && (
              staffList.map((staff, index) => (
                <tr key={index.toString()}>
                  <Table.Cell>
                    <Checkbox
                      id={staff.staffCode}
                      name={staff.staffCode}
                      value={staff.staffCode}
                      checked={checkedStaffCodeList.includes(staff.staffCode)}
                      onChange={() => checkHandler(staff.staffCode)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {staff.staffCode}
                  </Table.Cell>
                  <Table.Cell>
                    {staff.staffName}
                  </Table.Cell>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    </Modal>
  );
};

export default OrganizationManagerAddModal;
