import { useState, useEffect, useCallback } from 'react';
import { OptionType } from 'components/atoms/Select';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useFormik } from 'formik';
import AttendHolidayApplicationDomain from 'domain/master/attend/holidayApplication';
import {
  getHolidays, getCurrentHolidayApplication, getEmployment, getRoundTimeList, createHolidayApplicaiton, getAttendSetting, getAttendHolidayList, getSubstituteList,
} from 'api/attendHolidayApplicaiton';
import useToastNotification from 'hooks/useToastNotification';

const weekDay = ['日', '月', '火', '水', '木', '金', '土'];
const publicHoliday = 0;
const substituteHoliday = 3;

const listHours = [
  '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
  '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
  '20', '21', '22', '23',
];

const listTimes = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];

type HolidayApplicaitonKey = keyof Pick<AttendHolidayApplicationDomain, 'applicationReason' >


export const useHolidayApplicationForm = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [orgCode] = useState(sessionStorage.getItem('application.orgCode') || '');
  const [targetDate] = useState(sessionStorage.getItem('application.targetDate') || '');
  const [staffName] = useState(sessionStorage.getItem('application.dispStaffName') || '');
  const [staffCode] = useState(sessionStorage.getItem('application.staffCode') || '');
  const [holidayOptions, setHolidayOptions] = useState<Array<OptionType>>([]);
  const [holidayUnitOptions, setHolidayUnitOptions] = useState<Array<OptionType>>([]);
  const [hourFormOptions, setHourFormOptions] = useState<Array<OptionType>>([]);
  const [timeFormOptions, setTimeFormOptions] = useState<Array<OptionType>>([]);
  const [toastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [holidays, setHolidays] = useState([]);


  let ssAchievementId: any = '';
  if (sessionStorage.getItem('application.achievementId') && sessionStorage.getItem('application.achievementId') !== 'null') {
    ssAchievementId = sessionStorage.getItem('application.achievementId');
  }
  const [achievementId] = useState(ssAchievementId || '');
  const [employment, setEmployment] = useState(Object());
  const targetYmStr = moment(`${targetDate}`, 'YYYY-MM-DD').format('YYYY年MM月');
  const targetDateFrom = '';
  const targetDateTo = '';

  const [attendSetting, setAttendSetting] = useState(Object());
  const [currentApplid, setCurrentApplid] = useState(Object());
  const [attendHolidayList, setAttendHolidayList] = useState(Object());
  const [substituteOptions, setSubstituteOptions] = useState<Array<OptionType>>([]);
  const [holidayDigestiveUnit, setHolidayDigestiveUnit] = useState('0');
  const [selectedTimeHoliday, setSelectedTimeHoliday] = useState(false);
  const [roundTimeList, setRoundTimeList] = useState(listTimes);
  const [employmentId, setEmploymentId] = useState('');
  const [applyStartDate, setApplyStartDate] = useState('');
  const [updateUser] = useState(sessionStorage.getItem('loginUser.staffCode') || '');
  const [selectedHoliday, setSelectedHoliday] = useState(Object());
  const [startTime, setStartTime] = useState('');

  const { successNotification, errorNotification } = useToastNotification();

  useEffect(() => {
    setHourFormOptions(listHours.map((Hours) => ({
      value: Hours,
      label: Hours,
    })));

    fetchData();

    setTimeFormOptions(roundTimeList.map((Time: any) => ({
      value: Time,
      label: Time,
    })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    // Loading pin
    setLoading(true);

    // getHolidays
    await handelGetHolidays();

    // getEmployment
    const infoEmployment = await handelGetEmployment();

    // getAttendSetting
    await handelGetAttendSetting();

    // getCurrentHolidayApplication
    await handleGetCurrentHolidayApplication();

    // getAttendHolidayList
    await handleGetAttendHolidayList();

    // getSubstituteList
    await handelGetSubstituteList();

    // getRoundTimeList
    await handleGetRoundTimeList(infoEmployment);

    // getHolidayUnitList
    await handelGetHolidayUnitList(infoEmployment);
  };


  const onSubmit = async (values: AttendHolidayApplicationDomain) => {
    if (values.applicationReason === null) {
      errorNotification('申請理由を入力してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    formik.setFieldValue('targetDate', targetDate);
    formik.setFieldValue('holidayId', selectedHoliday.value);
    const postData = values.getRawData();
    if (postData.holidayUnit === 2) {
      postData.stampStartTime = `${postData.startHour}:${postData.startTime}`;
    }

    // submit form
    try {
      setLoading(true);
      setConfirmModalOpen(false);
      const response = await createHolidayApplicaiton(postData);
      if (!response.errors) {
        history.push(sessionStorage.getItem('attendStampList.returnDestination')! || '');
      }
      successNotification('申請しました。');
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error: any) => {
    setLoading(false);
    setConfirmModalOpen(false);
    if (error.response && error.response.data && error.response.data.errors) {
      let msgError = '';
      error.response.data.errors.map((item: { defaultMessage: string; }) => {
        msgError += `${item.defaultMessage} <br />`;
        return msgError;
      });
      errorNotification(msgError);
    } else {
      errorNotification('サーバー側でエラーが発生しました。');
      throw error;
    }
  };

  const formik = useFormik({
    initialValues: AttendHolidayApplicationDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  const handelGetHolidays = async () => {
    const params = {
      staffCode,
      targetDateStr: targetDate,
    };
    const response = await getHolidays(params);
    if (response.length) {
      const holidayList:any = response.filter(
        (item: any) => item.holidayType !== publicHoliday && item.holidayType !== substituteHoliday,
      );
      let firstHoliday:any = {};
      if(holidayList[0]){
        firstHoliday = {
          value: !holidayList[0].holidayId ? '' : holidayList[0]?.holidayId,
          label: !holidayList[0].holidayName ? '' : holidayList[0]?.holidayName,
          holidayType: !holidayList[0].holidayType ? '' : holidayList[0]?.holidayType,
          autoGrantHoliday: !holidayList[0].autoGrantHoliday ? '' : holidayList[0]?.autoGrantHoliday,
          paidHalfHolidayStartTime: !holidayList[0].paidHalfHolidayStartTime ? '' : holidayList[0]?.paidHalfHolidayStartTime,
          paidHalfHolidayEndTime: !holidayList[0].paidHalfHolidayEndTime ? '' : holidayList[0]?.paidHalfHolidayEndTime,
        }
      }
      if (firstHoliday.value) {
        formik.setFieldValue('holidayId', firstHoliday.value);
      }

      setSelectedHoliday(firstHoliday);
      // eslint-disable-next-line no-shadow
      const holidays = holidayList.map((holiday: any) => ({
        value: (holiday.holidayId) === null ? '' : holiday.holidayId,
        label: holiday.holidayName === null ? '' : holiday.holidayName,
      }));
      setHolidayOptions(holidays);
      const tmpHoliday = holidayList.map((holiday: any) => ({
        value: (holiday.holidayId) === null ? '' : holiday.holidayId,
        label: holiday.holidayName === null ? '' : holiday.holidayName,
        holidayType: holiday.holidayType === null ? '' : holiday.holidayType,
        autoGrantHoliday: holiday.autoGrantHoliday === null ? '' : holiday.autoGrantHoliday,
        paidHalfHolidayStartTime: holiday.paidHalfHolidayStartTime === null ? '' : holiday.paidHalfHolidayStartTime,
        paidHalfHolidayEndTime: holiday.paidHalfHolidayEndTime === null ? '' : holiday.paidHalfHolidayEndTime,
      }));
      setHolidays(tmpHoliday);
    }
  };

  const handelGetEmployment = async () => {
    try {
      const employmentParams = {
        orgCode,
        staffCode,
        targetYmStr,
        targetDateFrom,
        targetDateTo,
      };
      const response: any = await getEmployment(employmentParams);
      if (response && response.id.employmentId) {
        setEmployment(response);
        setEmploymentId(response.id.employmentId);
        setApplyStartDate(response.id.applyStartDate);
      }
      return response;
    } catch (error) {
      setLoading(false);
      return {};
    }
  };

  const handelGetAttendSetting = async () => {
    try {
      const response = await getAttendSetting();
      if (response) {
        setAttendSetting(response);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleGetCurrentHolidayApplication = async () => {
    try {
      const params = {
        staffCode,
        achievementId,
        targetDate,
      };
      const response:any = await getCurrentHolidayApplication(params);
      if (response) {
        setCurrentApplid(response);
        const selectedHolidayUnit = response.holidayDigestiveUnit;
        if (selectedHolidayUnit === 2) {
          setSelectedTimeHoliday(true);
        }
        setHolidayDigestiveUnit(selectedHolidayUnit);

        const tmpAchievementId = response.achievementId !== null ? response.achievementId : '';
        const tmpModifierApplicationId = response.modifierApplicationId !== null ? response.modifierApplicationId : '';
        const tmpVersion = response.version !== null ? response.version : '';
        const tmpApplicationReason = response.applicationReason !== null ? response.applicationReason : '';

        const tmpStartHour = response.holidayStartHm !== null ? moment(response.holidayStartHm).format('HH') : '00';
        const tmpStartTime = response.holidayStartHm !== null ? moment(response.holidayStartHm).format('mm') : '00';

        const tmpNextDay = response.holidayStartHm === null
              || targetDate !== moment(new Date(response.holidayStartHm), 'YYYY-MM-DD').locale('ja').format('YYYY-MM-DD') ? response.holidayTimes : false;
        const tmpHolidayTimes = response.holidayTimes !== null ? response.holidayTimes : '';

        formik.setFieldValue('staffCode', staffCode);
        formik.setFieldValue('orgCode', orgCode);
        formik.setFieldValue('targetDate', targetDate);
        formik.setFieldValue('achievementId', tmpAchievementId);
        formik.setFieldValue('modifierApplicationId', tmpModifierApplicationId);
        formik.setFieldValue('modifiyStatus', response.modifiyStatus);
        formik.setFieldValue('version', tmpVersion);
        formik.setFieldValue('holidayId', response.holidayId);
        formik.setFieldValue('holidayUnit', response.holidayDigestiveUnit);
        formik.setFieldValue('updateUser', updateUser);
        formik.setFieldValue('applicationReason', tmpApplicationReason);


        formik.setFieldValue('startHour', tmpStartHour);
        formik.setFieldValue('startTime', tmpStartTime);
        formik.setFieldValue('nextDay', tmpNextDay);
        formik.setFieldValue('stampTime', tmpHolidayTimes);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleGetAttendHolidayList = async () => {
    try {
      const params = {
        achievementId,
        staffCode,
        targetDate,
      };
      const response = await getAttendHolidayList(params);
      if (response) {
        setAttendHolidayList(response);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handelGetSubstituteList = async () => {
    try {
      const params = {
        staffCode,
        targetDate,
      };

      const response:any = await getSubstituteList(params);
      if (response) {
        // setSubstituteOptions
        setSubstituteOptions(response.map((substitute: any) => (
          {
            value: `${substitute.holidaySubstituteGrantHistoryId}`,
            label: `${substituteDispFormatter(substitute.holidayWorkDate, substitute.effectiveEndDate)}`,
          }
        )));

        let tmpSelectedSubstitute = '';
        const result = response.filter(
          (substitute: any) => substitute.substituteHolidayDate,
        ).map((substitute: any) => substitute.holidaySubstituteGrantHistoryId);
        if (result.length <= 0) {
          tmpSelectedSubstitute = response[0].holidaySubstituteGrantHistoryId;
        } else {
          // eslint-disable-next-line prefer-destructuring
          tmpSelectedSubstitute = result[0];
        }
        formik.setFieldValue('substituteId', tmpSelectedSubstitute);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const substituteDispFormatter = (holidayWorkDate: string, effectiveEndDate: string) => {
    const holidayWorkDateStr = moment(holidayWorkDate, 'YYYY-MM-DD').format('YYYY年MM月DD日');
    const effectiveEndDateStr = moment(effectiveEndDate, 'YYYY-MM-DD').format('YYYY年MM月DD日');
    return `${holidayWorkDateStr}(有効期限：${effectiveEndDateStr})`;
  };

  const handleGetRoundTimeList = async (employmentInfo: any) => {
    try {
      const params = {
        employmentId: `${employmentInfo.id.employmentId}`,
        targetDateStr: `${employmentInfo.id.applyStartDate}`,
        staffCode: '',
      };
      const response:any = await getRoundTimeList(params);
      // Hiding pin
      setLoading(false);
      if (response) {
        setRoundTimeList(response.roundTimeList);
        if (currentApplid.holidayStartHm) {
          const tmpStartTime = moment(new Date(currentApplid.holidayStartHm), 'YYYY-MM-DD HH:mm').locale('ja').format('mm');
          setStartTime(tmpStartTime);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handelGetHolidayUnitList = (employmentInfo: any) => {
    const holidayUnit = [
      {
        value: '0',
        label: '全休',
      },
    ];

    const holidayUnit2 = [
      {
        value: '0',
        label: '全休',
      },
      {
        value: '2',
        label: '時間休',
      },
    ];

    if (employmentInfo !== null && employmentInfo.timeHoliday) {
      setHolidayUnitOptions(holidayUnit2);
    } else {
      setHolidayUnitOptions(holidayUnit);
    }
  };

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    isLoading,
    formik,
    targetDate,
    staffName,
    weekDay,
    holidayOptions,
    holidayUnitOptions,
    hourFormOptions,
    timeFormOptions,
    toastModalOpen,
    toastMessage,
    closeConfirmModal,
    confirmModalOpen,
    setToastModalOpen,
    holidayDigestiveUnit,
    employment,
    attendSetting,
    currentApplid,
    attendHolidayList,
    selectedTimeHoliday,
    startTime,
    roundTimeList,
    employmentId,
    applyStartDate,
    selectedHoliday,
    holidays,
    setSelectedHoliday,
    onSubmit,
    substituteOptions,
  };
};

export default useHolidayApplicationForm;
