import moment from 'moment';
import ApiClient from './ApiClient';

export interface PosType {
    posNo: number;
    posName: string;
    dispOrder: number;
  }
export const getListMenuType = async (orgCode: string):Promise<any> => {
  const params = {
    orgCode,
    applyStartDate: moment(new Date()).format('YYYY/MM/DD'),
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/menuType/${companyCode}`, params);
  return response.data;
};

export const deleteByMenuType = async (data: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v1/sales/master/menuType/delete/${companyCode}`, {}, {
    salesMenuTypeMsts: data,
  });
  return response.data;
};


export const createOrUpdateMenuType = async (isCreate: number,
  unDecided: boolean, dataPost: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isCreate,
    unDecided,
  };

  const response = await ApiClient.post(`/v1/sales/master/menuType/${companyCode}`, params, {
    salesMenuTypeMsts: dataPost,
  });
  return response.data;
};

export default getListMenuType;
