import React, {
  Dispatch, SetStateAction
} from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import Checkbox from 'components/molecules/Checkbox';
import SalesMenuTypeDomain from 'domain/master/sales/salesMenuType';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import DatePickerForm from 'components/molecules/DatePickerForm';
import moment from 'moment';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FlexBox from 'components/atoms/FlexBox';
import TextForm from 'components/molecules/TextForm';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import { useSalesMenuTypeForm } from './hooks';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const SalesMenuTypeAddForm: React.FC <{
   menuTypeList: SalesMenuTypeDomain[],
   setMenuTypeList: Dispatch<SetStateAction<SalesMenuTypeDomain[]>>,
   isCreate : boolean,
   setIsCreate: any,
   menuTypeObj: SalesMenuTypeDomain;
   setMenuTypeObj: Dispatch<SetStateAction<SalesMenuTypeDomain>>,
   foodCode: string,
   setFoodCode: any,
   drinkCode: string,
   setDrinkCode: any,
   unDecided: boolean,
   setUnDecided: any,
  }> = ({
    menuTypeList, setMenuTypeList, isCreate, menuTypeObj, setIsCreate,
    setMenuTypeObj, foodCode, setFoodCode, drinkCode, setDrinkCode, unDecided, setUnDecided,
  }) => {
    const {
      formik, confirmModalOpen, closeConfirmModal,
      deleteMenuType, confirmModalDeleteOpen,
      closeConfirmModalDelete, openConfirmModalDelete,
    } = useSalesMenuTypeForm(menuTypeList, setMenuTypeList,
      isCreate, menuTypeObj, setMenuTypeObj, setIsCreate, unDecided, setUnDecided);

      const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

    return (
      <FormContents>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <FlexBox>
              <FlexBoxItem>
                <FormField>
                  <TextForm
                    name="foodCode"
                    label="フードの大部門コード"
                    value={foodCode}
                    onChange={(e) => {
                      setFoodCode(e.target.value);
                    }}
                  />
                </FormField>
              </FlexBoxItem>
              <FlexBoxItem marginLeft="20px">
                <div style={{ marginTop: '20px' }}>
                  <Button
                    onClick={() => {
                      formik.setFieldValue('listFood', Array.from(new Set([...formik.values.listFood.split(','), foodCode])).filter((x) => x).toString());
                      setFoodCode('');
                    }}
                    text="追加"
                    ghost={true}
                  />
                </div>
              </FlexBoxItem>
              <FlexBoxItem marginLeft="20px" basis="60%">
                <div style={{ marginTop: '20px' }}>
                  <VerticalLabelMutilSelectForm
                    label=""
                    name="selectFood"
                    values={formik.values.listFood ? formik.values.listFood.split(',').map((i: any) => ({ value: i, label: i })) : []}
                    setValue={(val: string) => {
                      formik.setFieldValue('listFood', val.slice(0, -1));
                    }}
                    options={[]}
                    isMulti={true}
                    menuIsOpen={false}
                    hideIndicator={true}
                    hidePlaceholder={true}
                  />
                </div>
              </FlexBoxItem>
            </FlexBox>

            <FlexBox>
              <FlexBoxItem>
                <FormField>
                  <TextForm
                    name="drinkCode"
                    label="ドリンクの大部門コード"
                    value={drinkCode}
                    onChange={(e) => {
                      setDrinkCode(e.target.value);
                    }}
                  />
                </FormField>
              </FlexBoxItem>
              <FlexBoxItem marginLeft="20px">
                <div style={{ marginTop: '20px' }}>
                  <Button
                    onClick={() => {
                      formik.setFieldValue('listDrink', Array.from(new Set([...formik.values.listDrink.split(','), drinkCode])).filter((x) => x).toString());
                      setDrinkCode('');
                    }}
                    text="追加"
                    ghost={true}
                  />
                </div>
              </FlexBoxItem>
              <FlexBoxItem marginLeft="20px" basis="60%">
                <div style={{ marginTop: '20px' }}>
                  <VerticalLabelMutilSelectForm
                    label=""
                    name="selectDrink"
                    values={formik.values.listDrink ? formik.values.listDrink.split(',').map((i: any) => ({ value: i, label: i })) : []}
                    setValue={(val: string) => {
                      formik.setFieldValue('listDrink', val.slice(0, -1));
                    }}
                    options={[]}
                    isMulti={true}
                    menuIsOpen={false}
                    hideIndicator={true}
                    hidePlaceholder={true}
                  />
                </div>
              </FlexBoxItem>
            </FlexBox>

            <FlexBox>
              <FlexBoxItem>
                <FormField>
                  <DatePickerForm
                    readOnly={!isCreate}
                    dateFormat="yyyy年MM月"
                    label="適用開始月"
                    showMonthYearPicker={true}
                    isFullWidth={true}
                    date={moment(new Date(formik.values.applyStartDate)).toDate()}
                    changeDate={(date: Date) => {
                      if (date !== null) {
                        formik.setFieldValue('applyStartDate', moment(new Date(date)).format('YYYY/MM/DD'));
                      }
                    }}
                  />
                  <div>
                    <span style={{ color: 'red', marginTop: '4px' }}>{formik.errors.applyStartDate}</span>
                  </div>
                </FormField>
              </FlexBoxItem>
              <FlexBoxItem marginLeft="20px">
                <FormField>
                  <DatePickerForm
                    dateFormat="yyyy年MM月"
                    label="適用終了月"
                    showMonthYearPicker={true}
                    isFullWidth={true}
                    date={formik.values.applyEndDate === '9999/12/31' || formik.values.applyEndDate === '9999-12-31' ? undefined : moment(new Date(formik.values.applyEndDate)).toDate()}
                    changeDate={(date: Date) => {
                      if (date == null) {
                        formik.setFieldValue('applyEndDate', moment(new Date('9999/12/31')).format('YYYY/MM/DD'));
                      } else {
                        formik.setFieldValue('applyEndDate', moment(new Date(date)).format('YYYY/MM/DD'));
                      }
                    }}
                    disable={unDecided && (formik.values.applyEndDate === '9999/12/31' || formik.values.applyEndDate === '9999-12-31')}
                    isShowDateOnDisable={false}
                  />
                </FormField>
              </FlexBoxItem>
              <FlexBoxItem marginLeft="20px">
                <div style={{ marginTop: '15px' }}>
                  <FormField>
                    <Checkbox
                      id="unDecided"
                      name="unDecided"
                      label="未定"
                      value={unDecided ? 'checked' : 'unChecked'}
                      checked={unDecided}
                      onChange={(e) => {
                        if (e.target.value === 'checked') {
                          formik.setFieldValue('applyEndDate', moment(new Date()).format('YYYY/MM/DD'));
                          setUnDecided(false);
                        } else {
                          formik.setFieldValue('applyEndDate', moment(new Date('9999/12/31')).format('YYYY/MM/DD'));
                          setUnDecided(true);
                        }
                      }}
                    />
                  </FormField>
                </div>
              </FlexBoxItem>
            </FlexBox>
          </div>

          <FormSubmitArea>
            {
              roleScreen && roleScreen.editable === 1 && (
                <React.Fragment>
                  <div style={{ marginRight: '12px' }}>
                    <Button
                      text={isCreate ? '登録' : '更新'}
                      onClick={formik.handleSubmit}
                    />
                  </div>
                  <div style={{ marginRight: '12px' }}>
                    <Button
                      text="削除"
                      onClick={openConfirmModalDelete}
                      disabled={isCreate}
                    />
                  </div>
                </React.Fragment>
              )
            }
            <Button
              text="クリア"
              onClick={() => {
                setIsCreate(true);
                setFoodCode('');
                setDrinkCode('');
                setUnDecided(true);
                setMenuTypeObj(SalesMenuTypeDomain.generateInitial());
              }}
            />

          </FormSubmitArea>
        </form>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          actionType={isCreate ? ActionType.CREATE : ActionType.UPDATE}
        />
        <ConfirmModal
          open={confirmModalDeleteOpen}
          closeHandler={closeConfirmModalDelete}
          onSubmit={deleteMenuType}
          title="確認メッセージ"
          content="レポートやリストにおいて、標準原価のフード・ドリンクの内訳が表示されなくなりますが、よろしいですか？"
          submitText="削除"
          items={[]}
        />
      </FormContents>
    );
  };
export default SalesMenuTypeAddForm;
