import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import JournalMasterImportForm from 'components/organismos/master/general/accountTitleMST/JournalMaster/ImportForm/JournalMasterForm';

const AccountTitleMSTAddPage: React.FC = () => {

  return (
    <SidebarTemplate pageTitle='仕訳インポート'>
        <JournalMasterImportForm />
    </SidebarTemplate>
  );
};


export default AccountTitleMSTAddPage;
