import moment from 'moment';
/**
 * API 勤怠再計算
 */

export interface PaidHolidayGrantHistory {
    paidHolidayGrantHistoryId: string,
    companyCode: string,
    orgCode: string,
    orgName: string,
    staffCode: string,
    staffName: string,
    grantType: number,
    grantTypeStr: string,
    grantDate: string,
    grantResult: boolean,
    grantResultStr: string,
    grantErrorMessage: string,
    grantDaysNum: number,
    grantTimesNum: number,
    lastCarryOverDaysNum: number,
    lastCarryOverTimesNum: number,
    lastDigestedDaysNum: number,
    lastDigestedTimesNum: number,
    lastExtinctionDaysNum: number,
    lastExtinctionTimesNum: number,
}


export default class PaidHolidayGrantHistoryDomain {
  constructor(private rawData: PaidHolidayGrantHistory) {
    // do nothing
  }

  static generateInitial(): PaidHolidayGrantHistoryDomain {
    return new PaidHolidayGrantHistoryDomain({
      paidHolidayGrantHistoryId: '',
      companyCode: '',
      orgCode: '',
      orgName: '',
      staffCode: '',
      staffName: '',
      grantType: 0,
      grantTypeStr: '',
      grantDate: '',
      grantResult: false,
      grantResultStr: '',
      grantErrorMessage: '',
      grantDaysNum: 0,
      grantTimesNum: 0,
      lastCarryOverDaysNum: 0,
      lastCarryOverTimesNum: 0,
      lastDigestedDaysNum: 0,
      lastDigestedTimesNum: 0,
      lastExtinctionDaysNum: 0,
      lastExtinctionTimesNum: 0,

    });
  }

  getRawData(): PaidHolidayGrantHistory {
    return this.rawData;
  }

  get paidHolidayGrantHistoryId(): string {
    return this.rawData.paidHolidayGrantHistoryId;
  }

  set paidHolidayGrantHistoryId(paidHolidayGrantHistoryId: string) {
    this.rawData.paidHolidayGrantHistoryId = paidHolidayGrantHistoryId;
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = companyCode;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  // get
  get grantType(): number {
    return this.rawData.grantType;
  }

  get grantTypeStr(): string {
    return this.rawData.grantTypeStr;
  }

  get grantDate(): string {
    return this.rawData.grantDate;
  }

  get grantDateToStr(): string {
    return moment(moment(this.rawData.grantDate).toDate()).format('YYYY年MM月DD日');
  }

  get grantResult(): boolean {
    return this.rawData.grantResult;
  }

  get grantResultStr(): string {
    return this.rawData.grantResultStr;
  }

  get grantErrorMessage(): string {
    return this.rawData.grantErrorMessage;
  }

  get grantDaysNum(): number {
    return this.rawData.grantDaysNum;
  }

  get grantDaysNumStr(): string {
    return `${holidayTime(this.rawData.grantDaysNum)}日`;
  }

  get grantTimesNum(): number {
    return this.rawData.grantTimesNum;
  }

  get lastCarryOverDaysNum(): number {
    return this.rawData.lastCarryOverDaysNum;
  }

  get lastCarryOverDaysNumStr(): string {
    return `${holidayTime(this.rawData.lastCarryOverDaysNum)}日`;
  }

  get lastCarryOverTimesNum(): number {
    return this.rawData.lastCarryOverTimesNum;
  }

  get lastCarryOverTimesNumStr(): string {
    return `${holidayTime(this.rawData.lastCarryOverTimesNum)}日`;
  }

  get lastDigestedDaysNum(): number {
    return this.rawData.lastDigestedDaysNum;
  }

  get lastDigestedDaysNumStr(): string {
    return `${holidayTime(this.rawData.lastDigestedDaysNum)}日`;
  }

  get lastDigestedTimesNum(): number {
    return this.rawData.lastDigestedTimesNum;
  }

  get lastExtinctionDaysNum(): number {
    return this.rawData.lastExtinctionDaysNum;
  }

  get lastExtinctionDaysNumStr(): string {
    return `${holidayTime(this.rawData.lastExtinctionDaysNum)}日`;
  }


  get lastExtinctionTimesNum(): number {
    return this.rawData.lastExtinctionTimesNum;
  }

  // custom data use DataTable
}

const holidayTime = (time: any) => {
  if (!time) {
    return '0.0';
  }
  return (time).toFixed(1);
};
