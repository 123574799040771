import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import StaffRegistrationListTable from 'components/organismos/master/general/staff/StaffRegistrationListTable';
import BlockUI from 'components/molecules/BlockUi';
import { useStaffRegistrationStatus } from './hooks';

const StaffRegistrationStatusPage: React.FC = () => {
    const {
        staffRegisterList,
        orgCode,
        setOrgCode,
        userRegistrationStatus,
        setUserRegistrationStatus,
        faceRegistrationStatus,
        setFaceRegistrationStatus,
        employmentRegistrationStatus,
        setEmploymentRegistrationStatus,
        blocking,
        categoryArr,
        setCategoryAr,
        orgStatus,
        setOrgStatus,
        downloadCsv,
        deletePhoto,
        selectedDeleteRow,
        setSelectedDeleteRow,
        openConfirmModal,
        setOpenConfirmModal,
    } = useStaffRegistrationStatus();

    return (
        <SidebarTemplate
            pageTitle="ユーザー登録状況確認"
        >
            <BlockUI blocking={blocking}>
                <StaffRegistrationListTable
                    staffRegisterList={staffRegisterList}
                    orgCode={orgCode}
                    setOrgCode={setOrgCode}
                    userRegistrationStatus={userRegistrationStatus}
                    setUserRegistrationStatus={setUserRegistrationStatus}
                    faceRegistrationStatus={faceRegistrationStatus}
                    setFaceRegistrationStatus={setFaceRegistrationStatus}
                    employmentRegistrationStatus={employmentRegistrationStatus}
                    setEmploymentRegistrationStatus={setEmploymentRegistrationStatus}
                    categoryArr={categoryArr}
                    setCategoryAr={setCategoryAr}
                    orgStatus={orgStatus}
                    setOrgStatus={setOrgStatus}
                    downloadCsv={downloadCsv}
                    deletePhoto={deletePhoto}
                    selectedDeleteRow={selectedDeleteRow}
                    setSelectedDeleteRow={setSelectedDeleteRow}
                    openConfirmModal={openConfirmModal}
                    setOpenConfirmModal={setOpenConfirmModal}
                />
            </BlockUI>
        </SidebarTemplate>
    );
};

export default StaffRegistrationStatusPage;
