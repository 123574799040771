import React from 'react';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import HourInput from 'components/atoms/Form/HourInput';
import FormLabel from 'components/atoms/Form/FormLabel';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import BodyText from 'components/atoms/BodyText';

const DetailHolidayForm: React.FC<{
  autoGrantHoliday: boolean;
  setAutoGrantHoliday: (autoGrantHoliday: boolean) => void;
  conditionGrantSubstituteHolidayHours: number;
  setConditionGrantSubstituteHolidayHours: (conditionGrantSubstituteHolidayHours: number) => void;
  conditionGrantSubstituteHolidayMinutes: number,
  setConditionGrantSubstituteHolidayMinutes:(conditionGrantSubstituteHolidayMinutes: number) => void
  validityPeriod: number;
  setValidityPeriod: (validityPeriod: number) => void;
}> = ({
  autoGrantHoliday, setAutoGrantHoliday,
  conditionGrantSubstituteHolidayHours, setConditionGrantSubstituteHolidayHours,
  conditionGrantSubstituteHolidayMinutes, setConditionGrantSubstituteHolidayMinutes,
  validityPeriod, setValidityPeriod,
}) => (
  <FormContents isSubForm={true}>
    <FormTitle
      title="代休詳細設定"
      note=""
    />
    <FormField>
      <RadioSelectForm
        label="代休の自動付与"
        subLabel=""
        items={[
          {
            label: '付与しない',
            value: '0',
          },
          {
            label: '付与する',
            value: '1',
          },
        ]}
        name="xx"
        value={autoGrantHoliday ? '1' : '0'}
        setValue={(e) => {
          setAutoGrantHoliday(e.target.value !== '0');
        }}
      />
    </FormField>
    { autoGrantHoliday === true && (
    <FormContents isSubForm={true}>

      <FormField>
        <VerticalLabelFormLayout
          label={(
            <FormLabel
              label="代休の付与条件"
            />
                )}
          input={(
            <FlexBox>
              <HourInput
                name="hour"
                value={conditionGrantSubstituteHolidayHours}
                label="時間"
                onChange={(e) => {
                  setConditionGrantSubstituteHolidayHours(Number(e.target.value));
                }}
                min={0}
                max={23}
              />
              <HourInput
                name="minute"
                value={conditionGrantSubstituteHolidayMinutes}
                label="分"
                onChange={(e) => {
                  setConditionGrantSubstituteHolidayMinutes(Number(e.target.value));
                }}
                min={0}
                max={59}
              />
              <BodyText>以上の休日出勤で１日付与</BodyText>
            </FlexBox>
                )}
        />
      </FormField>

      <FormField>
        <VerticalLabelFormLayout
          label={(
            <FormLabel
              label="付与された代休の有効期間"
            />
                )}
          input={(
            <FlexBox>
              <HourInput
                name="hour"
                value={validityPeriod}
                label="ヶ月"
                onChange={(e) => {
                  setValidityPeriod(Number(e.target.value));
                }}
                min={1}
              />
            </FlexBox>
                )}
        />
      </FormField>

    </FormContents>
    )}
  </FormContents>
);

export default DetailHolidayForm;
