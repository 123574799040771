
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import PriceWithdrawlAddForm from 'components/organismos/master/sales/PriceWithdrawlForm/PriceWithdrawlAddForm';
import { useParams } from 'react-router-dom';

const PriceWithrawlAddPage = () => {
  const { discountTypeCode } = useParams();
  return (
    <SidebarTemplate pageTitle={discountTypeCode ? '値引・割引取込編集' : '値引・割引取込設定'}>
      <PriceWithdrawlAddForm />
    </SidebarTemplate>
  );
};

export default PriceWithrawlAddPage;
