/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import PrimaryLogo from 'components/atoms/Logo/Primary';

const style = css({
  width: '32px',
  height: '32px',
  padding: '8px 12px',
});

const SidebarMustHead: React.FC = () => (
  <div>
    <PrimaryLogo css={style} />
  </div>
);

export default SidebarMustHead;
