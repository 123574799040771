/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

const style = css({
  padding: '12px 12px',
  margin: '0',
  // maxHeight: 'calc(100vh - 500px)',
  maxHeight: 'calc(100vh - 30vh)',
  overflow: 'auto',
});

const Body: React.FC<{
  customStyleBody?: SerializedStyles;
}> = ({ customStyleBody, children }) => (
  <div css={[style, customStyleBody]}>
    {children}
  </div>
);

export default Body;
