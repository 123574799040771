import { useEffect, useState, useCallback } from 'react';
import { getErrorMessageForResponse } from 'utility/errorUtil';
import getOperationalReportList from 'api/operationalReportList';
import useToastNotification from 'hooks/useToastNotification';

const usePeriodSalesForm = () => {
  const { errorNotification } = useToastNotification();
  const [operationalReportList, setOperationalReportList] = useState<Array<any>>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectOrgCode, setSelectOrgCode] = useState(sessionStorage.getItem('orgCodeReportQuick') ? sessionStorage.getItem('orgCodeReportQuick') : sessionStorage.getItem('orgCode') || 'all');
  const categorySession = JSON.parse(String(sessionStorage.getItem(`sessionOrganizationFileterTree${window.location.pathname}.Toggle`)));
  const [categoryAry, setCategoryAry] = useState<Array<string>>(categorySession);
  const [searchTime, setSearchTime] = useState(new Date());
  const target = sessionStorage.getItem('targetDateReportQuick');


  const [targetDate, setTargetDate] = useState(target ? new Date(target) : new Date());
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getOperationalReportList(selectOrgCode, new Date(targetDate!), categoryAry);
      setOperationalReportList(response.list);
      setSearchTime(response.searchTime);
    } catch (error) {
      errorNotification(getErrorMessageForResponse(error));
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryAry, selectOrgCode, targetDate]);


  useEffect(() => {
    fetchData();
  }, [fetchData, selectOrgCode, targetDate]);

  return {
    categoryAry,
    setCategoryAry,
    operationalReportList,
    isLoading,
    fetchData,
    setIsLoading,
    selectOrgCode,
    setSelectOrgCode,
    targetDate,
    setTargetDate,
    searchTime,
    setSearchTime,
  };
};

export default usePeriodSalesForm;
