
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { getCurrentTime } from 'api/attendStamp';
import { CurrentTimeDomain } from 'domain/master/attend/currentTime';

export const useAttendTimeRecorder = () => {
  const [textId, setTextId] = useState('');
  const [currentDay, setCurrentDay] = useState(moment(new Date()).format('YYYY/M/D dddd'));
  const [timeNow, setTimeNow] = useState(moment(new Date()).format('HH:mm:ss'));
  const [currentTime, setCurrentTime] = useState(CurrentTimeDomain.generateInitial());
  const startTime = useRef(Date.now());

  useEffect(() => {
    const timer = setTimeout(() => {
      // console.log(`currentTime.serverDateTime.getSeconds() = ${currentTime.serverDateTime.getSeconds()}`);
      // console.log(`startTime.current = ${startTime.current}`);
      // console.log(`Date.now() = ${Date.now()}`);
      if (!currentTime || currentTime.dateString === '' || currentTime.serverDateTime.getSeconds() === 0
          || Date.now() > (startTime.current + (60 * 1000))) {
        // console.log('getCurrentTime 開始');
        getCurrentTime()
          .then((response: any) => {
            // console.log('サーバー時間取得');
            // console.log(`response[0] = ${response[0]}`);
            // console.log(`response[1] = ${response[1]}`);
            // console.log(`response[2] = ${response[2]}`);
            setCurrentDay(moment(new Date(response[2])).format('YYYY/M/D dddd'));
            setTimeNow(moment(new Date(response[2])).format('HH:mm:ss'));
            const resultCurrentTime: CurrentTimeDomain = CurrentTimeDomain.generateInitial();
            resultCurrentTime.dateString = String(response[0]);
            resultCurrentTime.timeString = String(response[1]);
            resultCurrentTime.serverDateTime = new Date(response[2]);
            setCurrentTime(resultCurrentTime);
            startTime.current = Date.now();
          })
          .catch((error: any) => {
            console.log('サーバー時間取得エラー');
            console.log(error);
            currentTime.serverDateTime.setSeconds(
              currentTime.serverDateTime.getSeconds() + 1,
            );
            setCurrentDay(moment(currentTime.serverDateTime).format('YYYY/M/D dddd'));
            setTimeNow(moment(currentTime.serverDateTime).format('HH:mm:ss'));
          });
      } else {
        // currentTime.serverDateTime.setSeconds(
        //   currentTime.serverDateTime.getSeconds() + 1,
        // );
        currentTime.serverDateTime.setMilliseconds(
          currentTime.serverDateTime.getMilliseconds() + Date.now() - startTime.current,
        );
        startTime.current = Date.now();
        setCurrentDay(moment(currentTime.serverDateTime).format('YYYY/M/D dddd'));
        setTimeNow(moment(currentTime.serverDateTime).format('HH:mm:ss'));
      }
    }, 1000);
    return () => clearTimeout(timer);
  });

  return {
    textId,
    setTextId,
    currentDay,
    timeNow,
  };
};

export default useAttendTimeRecorder;
