import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import DashboardForm from 'components/organismos/master/general/dashboard';

const DashboardPage: React.FC = () => (
  <SidebarTemplate pageTitle="ダッシュボード">
    <DashboardForm />
  </SidebarTemplate>
);

export default DashboardPage;
