import {
  useState, useCallback, useEffect,
} from 'react';

import {
  getDigestionItemsForSubstituteHolidayStatus,
  downloadSubstituteHolidayCsv,
  getSubstituteDigestedtHistoryDetail,
} from 'api/holidayManagement';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import SubstituteHolidayStatusDomain from 'domain/master/holidayManagement/substituteHolidayStatus';
import useToastNotification from 'hooks/useToastNotification';

export interface DetailItems {
  staffCode: string,
  staffName: string,
  orgCode: string,
  orgName: string,
  grantDaysNum: string,
  digestedDaysNum: string,
  extinctionDaysNum: string,
  substituteDigestedtHistoryList: [],
  fromDate: Date,
  toDate: Date,
}


export const useHolidayManagementHolidayDomainForm = () => {
  const sessionSearchDate = sessionStorage.getItem('holiday.searchDate') || new Date().toString();
  const loadDateYearAgo = new Date(sessionSearchDate);
  loadDateYearAgo.setDate(loadDateYearAgo.getDate() + 1);
  loadDateYearAgo.setFullYear(loadDateYearAgo.getFullYear() - 1);

  const sessionStorageOrgCode = String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}7`)) !== 'null' ? (String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}7`))) : (sessionStorage.getItem('loginUser.orgCode') || '');
  const [
    substituteHolidayStatusList, setSubstituteHolidayStatusList,
  ] = useState<Array<SubstituteHolidayStatusDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useState(sessionStorageOrgCode);
  const [searchDate, setSearchDate] = useState<Date>(new Date(sessionSearchDate));
  const [dateYearAgo, setDateYearAgo] = useState<Date>(loadDateYearAgo);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailItems, setDetailItems] = useState<DetailItems>({
    staffCode: '',
    staffName: '',
    orgCode: '',
    orgName: '',
    grantDaysNum: '',
    digestedDaysNum: '',
    extinctionDaysNum: '',
    substituteDigestedtHistoryList: [],
    fromDate: new Date(),
    toDate: new Date(),
  });
  const { errorNotification } = useToastNotification();


  // データの取得
  const fetchData = useCallback(async () => {
    const response = await getDigestionItemsForSubstituteHolidayStatus(
      orgCode !== 'all' ? orgCode : '',
      String(moment(moment(searchDate).toDate()).format('YYYY/MM/DD')),
    );
    setSubstituteHolidayStatusList(
      response.map((result) => new SubstituteHolidayStatusDomain(result)),
    );
  }, [orgCode, searchDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, searchDate]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  //
  const callGetSubstituteDigestedtHistoryDetail = useCallback(async (staffCode: string) => {
    try {
      await getSubstituteDigestedtHistoryDetail(
        staffCode,
        String(moment(moment(searchDate).toDate()).format('YYYY/MM/DD')),
      ).then((response: any) => {
        setDetailItems(response);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [errorNotification, searchDate]);

  return {
    substituteHolidayStatusList,
    toggleNoManager,
    orgCode,
    setOrgCode,
    searchDate,
    setSearchDate,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    callGetSubstituteDigestedtHistoryDetail,
    dateYearAgo,
    setDateYearAgo,
  };
};


export const useDownloadHolidayManagementHoliday = (
  orgCode: string,
  targetDate: Date,
) => {
  const downloadHolidayManagementHoliday = useCallback((isDownloadAll?: boolean) => {
    downloadSubstituteHolidayCsv(
      isDownloadAll ? '' : orgCode,
      targetDate,
    );
  }, [orgCode, targetDate]);

  return {
    downloadHolidayManagementHoliday,
  };
};

export default {
  useHolidayManagementHolidayDomainForm,
};
