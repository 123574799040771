/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import DatePicker from './DatePicker';

const styles = {
  startDatePicker: css({
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  }),
  endDatePicker: css({
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderLeft: 'none',
  }),
};

const DateRangePicker: React.FC<{
  startDate: Date;
  setStartDate: (date: Date) => void;
  endDate?: Date;
  setEndDate: (date: Date) => void;
  marginLeft?: string;
  endDateDisabled?: boolean;
  positionFixed?: boolean;
}> = ({
  startDate, setStartDate,
  endDate, setEndDate,
  marginLeft,
  endDateDisabled = false,
  positionFixed = false,
}) => (
  <FlexBox>
    <FlexBoxItem marginLeft={marginLeft}>
      <DatePicker
        date={startDate}
        changeDate={(date: Date) => {
          if (date !== null) {
            setStartDate(date);
          }
        }}
        selectStart={true}
        startDate={startDate}
        endDate={endDate}
        customStyle={styles.startDatePicker}
        positionFixed={positionFixed}
      />
    </FlexBoxItem>
    <FlexBoxItem>
      <DatePicker
        date={endDate}
        changeDate={(date: Date) => {
          if (date !== null) {
            setEndDate(date);
          }
        }}
        selectEnd={true}
        startDate={startDate}
        endDate={endDate}
        customStyle={styles.endDatePicker}
        disabled={endDateDisabled}
        positionFixed={positionFixed}
      />
    </FlexBoxItem>
  </FlexBox>
);

export default DateRangePicker;
