import { useState, useCallback, useMemo, useEffect } from 'react';
import { getCompanyBankList } from 'api/companyBank';
import { postCsvImport, Error } from 'api/organization';
import useToastNotification from 'hooks/useToastNotification';

export const useOrganizationCsvForm = () => {
  // アップロードタイプ
  const [uploadType, setUploadType] = useState('0');
  // 登録内容確認モーダルの開閉状態
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const [blocking, setBlocking] = useState(false);
  const [arrCompanyBank, setArrCompanyBank] = useState<any>();
  const uploadTypeItems: Array<{ label: string, value: string }> = useMemo(() => (
    arrCompanyBank && arrCompanyBank.companyBankList && arrCompanyBank.companyBankList.length > 0 ? [
    {
      label: '組織情報',
      value: '0',
    },
    {
      label: 'マネージャ権限',
      value: '1',
    },
    {
      label: '特賃日割当情報',
      value: '2',
    },
    // {
    //   label: '銀行情報',
    //   value: '3',
    // },
  ] : 
  [
    {
      label: '組織情報',
      value: '0',
    },
    {
      label: 'マネージャ権限',
      value: '1',
    },
    {
      label: '特賃日割当情報',
      value: '2',
    },
  ]), [arrCompanyBank]);

  // アップロードファイル
  const [uploadFile, setUploadFile] = useState<File>();

  // アップロードエラー
  const [errors, setErrors] = useState<Array<string>>();

  // アップロード実行処理
  const onSubmit = useCallback(() => {
    if (!uploadFile) {
      return;
    }

    if (!confirmModalOpen) {
      // 登録内容確認モーダルが表示されていなければ表示する
      setConfirmModalOpen(true);
      return;
    }

    // エラー表示を初期化
    setErrors(undefined);

    setConfirmModalOpen(false);
    setBlocking(true);
    postCsvImport(uploadFile, uploadType)
      .then((response: any) => {
        setBlocking(false);
        if (response.status !== 200) {
          setErrors((response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        } else {
          successNotification('インポートが完了しました。');
        }
      }).catch((error: any) => {
        setBlocking(false);
        setErrors((error.response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        if (error.response && error.response.data && error.response.data.errors) {
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  }, [uploadFile, confirmModalOpen, uploadType, errorNotification, successNotification]);

  // 登録内容確認モーダル用データ
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const fetchCompanyBank = useCallback(async () => {
    setBlocking(true);
    await getCompanyBankList('', true).then((response: any) => {
      setArrCompanyBank(response);
      setBlocking(false);
    }).catch((error: any) => {
      setArrCompanyBank([]);
      setBlocking(false);
    })
  }, [])

  useEffect(() => {
    fetchCompanyBank()
  }, [fetchCompanyBank])

  return {
    uploadType,
    setUploadType,
    uploadTypeItems,
    uploadFile,
    setUploadFile,
    onSubmit,
    errors,
    blocking,
    confirmModalOpen,
    closeConfirmModal,
  };
};

export default {};
