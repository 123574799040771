/** @jsx jsx */
import React, { Dispatch, SetStateAction } from 'react';
import { jsx } from '@emotion/core';
import TableFirstRow from './TableFirstRow'
import TableBodyRow from './TableBodyRow'
import TableLastRow from './TableLastRow'
interface WrappedTable {
  children:  JSX.Element[],
  isExpanded: boolean,
  setStateAccordion: Dispatch<SetStateAction<boolean>>,
  backgroundColor?: string,
  hideBackgroundColor?: boolean
}
const WrappedTable: React.FC<WrappedTable> = ({
  children,
  isExpanded,
  setStateAccordion,
  backgroundColor,
  hideBackgroundColor
}) => {
  const handleExpand = (): void => { setStateAccordion(!isExpanded) }
  const firstRow =  children.slice(0, 1)
  const bodyRow = children.slice(1, children.length - 1)
  const lastRow = children.slice(children.length - 1, children.length)

  return <React.Fragment>
    <TableFirstRow
     rowData={firstRow}
     handleExpand={handleExpand}
      backgroundColor={backgroundColor}
      hideBackgroundColor={hideBackgroundColor}
      bodyRowLength={bodyRow.length}
    />
    <TableBodyRow
      rowData={bodyRow}
      isExpanded={isExpanded}
    />
    <TableLastRow
      rowData={lastRow}
      backgroundColor={backgroundColor}
    />
  </React.Fragment>
};

export default WrappedTable;
