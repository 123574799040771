/** @jsx jsx */
import React from 'react';
import { grayScale } from 'components/styles';
import Accordion from 'components/molecules/Accordion';
import DataTable from 'components/organismos/DataTable/DataTable';
import { css, jsx } from '@emotion/core';
import ApprovedHolidayWorkDomain from '../domain/approvedHolidayWorkDomain';

const HolidayWorkAccordion: React.FC<{ list: ApprovedHolidayWorkDomain[] }> = ({
  list,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: '承認日時',
        accessor: 'approvalDate',
        Cell: (cell: { row :{ isExpanded: boolean, original: any }}) => (
          <div style={{ textAlign: 'center' }}>
            {cell.row.original.approvalDate}
          </div>
        ),
      },
      {
        Header: '承認者',
        accessor: 'authorizer',
      },
      {
        Header: '組織名',
        accessor: 'orgName',
      },
      {
        Header: '申請者',
        accessor: 'applicant',
      },
      {
        Header: '申請日時',
        accessor: 'applicationDate',
      },
      {
        Header: '対象者',
        accessor: 'targetPerson',
      },
      {
        Header: '対象日付',
        accessor: 'targetDate',
      },
      {
        Header: '申請種別',
        accessor: 'modifiyStatusName',
      },
      {
        Header: '休日種別',
        accessor: 'legalName',
      },
      {
        Header: '申請理由',
        accessor: 'applicationReason',
      },
    ],
    [],
  );

  return (
    <div
      css={css`
      table th:nth-child(1) {
        width: 140px;
      }
      table th:nth-child(2) {
        width: 140px;
      }
      table th:nth-child(3) {
        width: 140px;
      }
      table th:nth-child(4) {
        width: 140px;
      }
      table th:nth-child(5) {
        width: 140px;
      }
      table th:nth-child(6) {
        width: 140px;
      }
      table th:nth-child(7) {
        width: 80px;
      }
      table th:nth-child(8) {
        width: 80px;
      }
      table th:nth-child(9) {
        width: 80px;
      }
      table th:nth-child(10) {
        width: 200px;
      }
      `}
    >
      <Accordion
        text={`休日出勤申請 (${list.length}件）`}
        color={grayScale.gray100}
        callBack={() => {}}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <DataTable columns={columns} data={list} isGlobalFilter={true} minWidth="1400px" sortBy={[{id : 'approvalDate', desc: true}]} />
        </div>
      </Accordion>
    </div>
  );
};

export default HolidayWorkAccordion;
