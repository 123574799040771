import React, { useState, useEffect, useReducer, useCallback } from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import FormContents from 'components/atoms/Form/FormContents';
import DatePickerForm from 'components/molecules/DatePickerForm';
import Icon from 'components/atoms/Icon';
import useToastNotification from 'hooks/useToastNotification';
import TextForm from 'components/molecules/TextForm';
import FormField from 'components/atoms/Form/FormField';
import OrganizationInputForm from 'components/organismos/organization/OrganizationInputForm';
import StoreBasicInformationForm from 'components/organismos/organization/OrganizationInputForm/StoreBasicInformationForm';
import LicenseForm from 'components/organismos/organization/OrganizationInputForm/LicenseForm';
import BankForm from 'components/organismos/organization/OrganizationInputForm/BankForm';
import TerminalEquipmentSafeForm from 'components/organismos/organization/OrganizationInputForm/TerminalEquipmentSafeForm';
import BuildingManagementForm from 'components/organismos/organization/OrganizationInputForm/BuildingManagementForm';
import FacilityForm from 'components/organismos/organization/OrganizationInputForm/FacilityForm';
import InsuranceBankOtherForm from 'components/organismos/organization/OrganizationInputForm/InsuranceBankOtherForm';
import OrganizationManagerEditForm from 'components/organismos/organization/OrganizationManagerEditForm';
import OrganizationSpecialSalaryEditForm from 'components/organismos/organization/OrganizationSpecialSalaryEditForm';
import OrganizationDomain from 'domain/organization';
import ScrollMenuComponent from 'components/organismos/MenuScrollVerical';
import { useOrganizationAdd } from './hooks';
import moment from 'moment';
import BlockUI from 'components/molecules/BlockUi';
import SelectFormVirtical from 'components/molecules/SelectFormVirtical';
import Checkbox from 'components/molecules/Checkbox';
import useCompanyBankOptions from 'hooks/useCompanyBankOptions';
import { css } from '@emotion/core';
import {useSelector} from 'react-redux';
import {Store} from 'modules/store';

export type TableColumnsType = {
  name: string;
  width?: string;
  visible: boolean;
  content: (rowIndex: number, colIndex: number) => JSX.Element;
};

const OrganizationAddPage: React.FC = () => {
  const {
    organizationDomain,
    setOrganizationDomain,
    managerList,
    setManagerList,
    seniorManagerList,
    setSeniorManagerList,
    historyList,
    setHistoryList,
    blocking,
    createOrganization,
    arrCompanyBank
  } = useOrganizationAdd();

  const companyBankOptions = useCompanyBankOptions();

  const [openDetailForm, setOpenDetailForm] = useState<boolean>(false);

  const defaultNameList = [
    {
      name: ''
    },
  ];

  const defaultCompanyBank = [
    {
      organizationBankId: '',
      applyStartDate: moment().format('YYYY/MM/DD'),
      applyEndDate: '',
      lineNo: 0,
      bankCode: companyBankOptions && companyBankOptions.length > 0 ? companyBankOptions[0].value : '',
      otherBankName: '',
      registrationNo: ''
    },
  ];

  const defaultReducer: any = {
    orgCode: '',
    orgName: '',
    orgNameKana: '',
    dispOrder: 0,
    organizationCategoryMstDetilId: [],
    closeFlag: 0,
    applyStartDate: moment(new Date()).startOf('month').format("YYYY/MM/DD"),
    applyEndDate: '',
    employmentId: '',
    workingDaysId: '',
    posCode: '',
    infomartLinkCode: '',
    managerList: [],
    seniorManagerList: [],
    applyStartMonth: moment(new Date()).startOf('month').format("YYYY/MM/DD"),
    specialSalaryPatternId: '',
    addSalary: 0,
    zipCode: '',
    prefectureCode: '',
    address: '',
    tel1: '',
    tel2: '',
    tel3: '',
    fax1: '',
    fax2: '',
    fax3: '',
    operatingCompany: '',
    operatingCompanyRepresentative: '',
    operatingCompanyRepresentativeTel: '',
    entryMethod: '',
    squareMeters: '',
    sharedSpaceSquareMeters: '',
    floorSpace: '',
    numberOfSeat: 0,
    shopManager: '',
    shopManagerTel: '',
    posContact: '',
    posContactTel: '',
    headChef: '',
    businessHoursMon: '',
    businessHoursTue: '',
    businessHoursWed: '',
    businessHoursThu: '',
    businessHoursFri: '',
    businessHoursSat: '',
    businessHoursSun: '',
    businessHoursHoliday: '',
    businessHoursLunch: '',
    closingDay: '',
    nameList: [],
    businessLicenseStartDay: '',
    businessLicenseEndDay: '',
    foodHygieneOfficer: '',
    healthCenter: '',
    foodHygieneOfficerTel: '',
    healthCenterTel: '',
    tradeName: '',
    businessLicenseOperatingCompanyRepresentative: '',
    firePreventionManagementResponsible: '',
    fireStation: '',
    firePreventionManagementResponsibleTel: '',
    fireStationTel: '',
    firePreventionObjectInspector: '',
    buildingManagementNote: '',
    firePreventionObjectInspectionDate: '',
    fireInspectionDate: '',
    lateNightAlcoholOfferNotificationType: '',
    catNo: '',
    creditTerminalNo: '',
    providerId: '',
    providerPassword: '',
    wifiId: '',
    wifiPassword: '',
    securityCameraQuantity: '',
    securityCameraMaker: '',
    securityCameraModelNo: '',
    posQuantity: '',
    posMaker: '',
    posModelNo: '',
    posCustomerNo: '',
    posRemark: '',
    handyQuantity: '',
    handyConservative: '',
    handyMaker: '',
    handyConservativePeriod: '',
    handyModelNo: '',
    cashboxManager1: '',
    cashboxPassword1: '',
    cashboxManager2: '',
    cashboxPassword2: '',
    buildingOwner: '',
    buildingOwnerAddress: '',
    buildingOwnerTel: '',
    buildingManagementCompany: '',
    buildingManagementCompanyZipCode: '',
    buildingManagementCompanyAddress: '',
    buildingManagementCompanyTel: '',
    buildingManagementCompanyFax: '',
    buildingManagementCompanyContact: '',
    buildingManagementCompanyContactTel: '',
    leaseContractStartDate: '',
    leaseContractEndDate: '',
    leaseContractRent: '',
    leaseContractYears: '',
    leaseContractCommonServiceFee: '',
    leaseContractDeposit: '',
    leaseContractOtherPurchaseContent: '',
    leaseContractNote: '',
    leaseContractOtherPurchase: '',
    leaseContractReviewDate: '',
    leaseContractStories: '',
    leaseRenewedContractType: '',
    leaseRenewalFee: '',
    leaseRenewedContractRemark: '',
    leaseContractExpirationNotice: '',
    leaseContractExpirationAmortization: '',
    leaseContractExpirationReturnRemark: '',
    leaseContractCancelNotice: '',
    leaseContractCancelFee: '',
    leaseContractCancelRemark: '',
    leaseContractRestoreType: '',
    insuranceCompany: '',
    insuranceCompanyContact: '',
    insuranceCompanyContactTel: '',
    insuranceContractStartDate: '',
    insuranceContractEndDate: '',
    insuranceContractNo: '',
    memo: '',
    companyBankList: []
  };

  const organizations = ['orgCode', 'orgName', 'orgNameKana', 'dispOrder', 'organizationCategoryMstDetilId', 'closeFlag', 'applyStartDate', 'applyEndDate', 'workingDaysId', 'employmentId', 'posCode', 'infomartLinkCode'];
  const managers = ['managerList', 'seniorManagerList'];
  const orgSpecialList = ['applyStartMonth', 'specialSalaryPatternId', 'addSalary'];
  const basicInformation = [
    'zipCode', 'prefectureCode', 'address', 'tel1', 'tel2', 'tel3', 'fax1', 'fax2', 'fax3',
    'operatingCompany', 'operatingCompanyRepresentative', 'operatingCompanyRepresentativeTel', 'entryMethod', 'squareMeters', 'sharedSpaceSquareMeters',
    'floorSpace', 'numberOfSeat', 'shopManager', 'shopManagerTel', 'posContact', 'posContactTel', 'headChef',
    'businessHoursMon', 'businessHoursTue', 'businessHoursWed', 'businessHoursThu', 'businessHoursFri', 'businessHoursSat', 'businessHoursSun',
    'businessHoursHoliday', 'businessHoursLunch', 'closingDay', 'nameList'
  ]
  const permit = [
    'businessLicenseStartDay', 'businessLicenseEndDay', 'foodHygieneOfficer', 'healthCenter', 'foodHygieneOfficerTel', 'healthCenterTel',
    'tradeName', 'businessLicenseOperatingCompanyRepresentative', 'firePreventionManagementResponsible', 'fireStation', 'firePreventionManagementResponsibleTel', 'fireStationTel',
    'firePreventionObjectInspector', 'buildingManagementNote', 'firePreventionObjectInspectionDate', 'fireInspectionDate', 'lateNightAlcoholOfferNotificationType'
  ]
  const terminalEquipmentSafe = [
    'catNo', 'creditTerminalNo', 'providerId', 'providerPassword', 'wifiId', 'wifiPassword', 'securityCameraQuantity', 'securityCameraMaker', 'securityCameraModelNo',
    'posQuantity', 'posMaker', 'posModelNo', 'posCustomerNo', 'posRemark', 'handyQuantity', 'handyConservative', 'handyMaker', 'handyConservativePeriod', 'handyModelNo',
    'cashboxManager1', 'cashboxPassword1', 'cashboxManager2', 'cashboxPassword2'
  ]
  const buildingManagement = [
    'buildingOwner', 'buildingOwnerAddress', 'buildingOwnerTel', 'buildingManagementCompany', 'buildingManagementCompanyZipCode', 'buildingManagementCompanyAddress',
    'buildingManagementCompanyTel', 'buildingManagementCompanyFax', 'buildingManagementCompanyContact', 'buildingManagementCompanyContactTel'
  ]
  const facility = [
    'leaseContractStartDate', 'leaseContractEndDate', 'leaseContractRent', 'leaseContractYears', 'leaseContractCommonServiceFee', 'leaseContractDeposit', 'leaseContractOtherPurchaseContent',
    'leaseContractNote', 'leaseContractOtherPurchase', 'leaseContractReviewDate', 'leaseContractStories', 'leaseRenewedContractType', 'leaseRenewalFee', 'leaseRenewedContractRemark', 'leaseContractExpirationNotice',
    'leaseContractExpirationAmortization', 'leaseContractExpirationReturnRemark', 'leaseContractCancelNotice', 'leaseContractCancelFee', 'leaseContractCancelRemark', 'leaseContractRestoreType'
  ]
  const insuranceBankOther = [
    'insuranceCompany', 'insuranceCompanyContact', 'insuranceCompanyContactTel', 'insuranceContractStartDate', 'insuranceContractEndDate', 'insuranceContractNo',
    'mainBankName1', 'registrationNumber1', 'mainBankName2', 'registrationNumber2', 'otherBankName1', 'registrationNumber3', 'otherBankName2', 'registrationNumber4', 'memo'
  ]
  const orgBankList = ['companyBankList']

  const [arrInputChange, setArrInputChange] = useState([
    'orgCode', 'orgName', 'orgNameKana', 'dispOrder', 'organizationCategoryMstDetilId', 'closeFlag', 'applyStartDate', 'applyEndDate', 'employmentId', 'workingDaysId', 'posCode', 'infomartLinkCode',
    'managerList', 'seniorManagerList',
    'applyStartMonth', 'specialSalaryPatternId', 'addSalary'
  ]);

  function renderListAddName(number: number){
    const arr = [];
    for (var i = 1; i <= number; i++) {
      arr.push({
        name: ''
      });
    }
  return arr;
}

  const memorizedReducer = useCallback((state: any, { type, payload }: any) => {

    let nameList = [...state.nameList];

    let companyBankList = [...state.companyBankList];

    switch (type) {
      case 'DEFAULT':
        return payload;

      case 'MANAGER_LIST':
        return { ...state, managerList: payload }

      case 'SENIOR_MANAGER_LIST':
        return { ...state, seniorManagerList: payload }

      case "ADD_NAME":
        let newDefaultName = { ...defaultNameList[0] };
        newDefaultName.name = '';
        return { ...state, nameList: [...state.nameList, newDefaultName] };

      case "DELETE_NAME":
        nameList.splice(payload.index, 1);
        return { ...state, nameList };

      case "CHANGE_LIST_NAME":
      let arr = renderListAddName(10);
      nameList[payload.index] = {
        name: payload.name
      };
      nameList.map((item: any, index: number) => {
        if (item !== null) {
          return arr[index] = item;
        }
      });
        return { ...state, nameList: arr };

      case "ADD_COMPANY_BANK":
        let newDefaultCompanyBank = { ...defaultCompanyBank[0] };
        return { ...state, companyBankList: [...state.companyBankList, newDefaultCompanyBank] };

      case "DELETE_COMPANY_BANK":
        companyBankList.splice(payload.index, 1);
        return { ...state, companyBankList };

      case "CHANGE_COMPANY_BANK":
        if (payload.name === 'bankCode' && payload.value !== '0') {
          companyBankList[payload.index]['otherBankName'] = '';
        }
        companyBankList[payload.index][payload.name] = payload.value;
        return { ...state, companyBankList };

      default:
        const result = { ...state }
        if (result.hasOwnProperty(type)) {
          result[type] = payload;
        }
        return result;
    }
  }, [defaultCompanyBank]);

  const [state, dispatch] = useReducer(memorizedReducer, defaultReducer);

  useEffect(() => {
    dispatch({ type: 'DEFAULT', payload: defaultReducer })
  }, [])

  const handleOnChange = (e: any, targetName?: string, targetPayload?: any) => {
    const type = targetName !== undefined ? targetName : e.target.name;
    const payload = targetPayload !== undefined ? targetPayload : e.target.value;
    let inputCheck: any = arrInputChange;
    if (payload !== defaultReducer[type] && inputCheck.includes(type) === false) {
      inputCheck.push(type);
    }
    if (
      payload === defaultReducer[type]
      && inputCheck.includes(type) === true
      && organizations.includes(type) === false
      && managers.includes(type) === false
      && orgSpecialList.includes(type) === false
    ) {
      var index = inputCheck.indexOf(type)
      if (index !== -1) {
        inputCheck.splice(index, 1);
      }
    }
    setArrInputChange(inputCheck);
    dispatch({ type, payload });
  }

  const handleOnBlur = (e: any) => {
    const type = e.target.name;
    const payload = Number(e.target.value);
    dispatch({ type, payload });
  }

  const filterObjectUpdate = (state: any) => {
    const keyState: any = Object.keys(state);
    const newState: any = {
      organizations: {},
      managers: {},
      orgSpecialList: [],
      basicInformation: {},
      permit: {},
      terminalEquipmentSafe: {},
      buildingManagement: {},
      facility: {},
      insuranceBankOther: {},
      orgBankList: [],
    };
    const objOrgSpecialList: any = {
      addSalary: 0,
      applyStartMonth: moment(new Date()).startOf('month').format('YYYY/MM/DD'),
      specialSalaryPatternId: ""
    }
    const numberTypeFormat = [
      'closeFlag', 'squareMeters', 'addSalary', 'dispOrder', 'sharedSpaceSquareMeters', 'floorSpace', 'numberOfSeat',
      'securityCameraQuantity', 'posQuantity', 'handyQuantity', 'handyConservative',
      'leaseContractRent', 'leaseContractCommonServiceFee', 'leaseContractOtherPurchase', 'leaseRenewedContractType', 'leaseRenewalFee',
      'leaseContractExpirationAmortization', 'leaseContractCancelFee', 'leaseContractRestoreType'
    ]

    keyState.map((item: never) => {
      if (arrInputChange.includes(item) === true) {
        if (state[item] !== '' && numberTypeFormat.includes(item) === true) {
          state[item] = Number(state[item]);
        }
        if (organizations.includes(item) === true) {
          newState['organizations'][item] = state[item];
        } else if (managers.includes(item) === true) {
          newState['managers'][item] = state[item];
        } else if (orgSpecialList.includes(item) === true) {
          objOrgSpecialList[item] = state[item];
        } else if (basicInformation.includes(item) === true) {
          newState['basicInformation'][item] = state[item];
        } else if (permit.includes(item) === true) {
          newState['permit'][item] = state[item];
        } else if (terminalEquipmentSafe.includes(item) === true) {
          newState['terminalEquipmentSafe'][item] = state[item];
        } else if (buildingManagement.includes(item) === true) {
          newState['buildingManagement'][item] = state[item];
        } else if (facility.includes(item) === true) {
          newState['facility'][item] = state[item];
        } else if (insuranceBankOther.includes(item) === true) {
          newState['insuranceBankOther'][item] = state[item];
        } else if (orgBankList.includes(item) === true) {
          newState['orgBankList'] = state[item]
        }
      }
    });

    newState['orgSpecialList'].push(objOrgSpecialList);

    const keyNewState: any = Object.keys(newState);
    keyNewState.map((item: never) => {
      if (Object.keys(newState[item]).length === 0) {
        delete newState[item];
      }
    });

    let msgErrors = '';

    if (newState['orgBankList'] && newState['orgBankList'].length > 0) {
      newState['orgBankList'].map((item: any, index: number) => {
        if (newState['orgBankList'][index].bankCode === '0' && newState['orgBankList'][index].otherBankName === '') {
          msgErrors += `銀行${index + 1}の銀行名を入力してください<br>`;
        }
        newState['orgBankList'][index].lineNo = index + 1
      })
    }

    if (msgErrors !== '') {
      errorNotification(msgErrors);
      return false;
    }

    if (newState['managers'] && newState['managers']['managerList'].length > 0) {
      newState['managers']['managerList'].map((manager: any, index: number) => {
        newState['managers']['managerList'][index].managerType = 0
      })
    }

    if (newState['managers'] && newState['managers']['seniorManagerList'].length > 0) {
      newState['managers']['seniorManagerList'].map((manager: any, index: number) => {
        newState['managers']['seniorManagerList'][index].managerType = 1
      })
    }

    return newState;
  }

  const handleButtonSubmit = () => {
    const newState = filterObjectUpdate(state);
    if (newState !== false) {
      createOrganization(newState);
    }
  }

  const initStateName = {
    name: "",
  };

  const [addName, setAddName] = useState<any>([]);

  const [addCompanyBank, setAddCompanyBank] = useState<any>([]);

  const [elementCalculate, setElementCalculate] = useState<number>(1);

  const [memoReCalculate, setMemoReCalCulate] = useState<number>(0);

  const { errorNotification } = useToastNotification();

  const deleteCompanyBank = (rowIndex: number) => {
    try {
      const newRows = [...addCompanyBank];
      newRows.splice(rowIndex, 1);
      setAddCompanyBank(newRows);
      dispatch({
        type: "DELETE_COMPANY_BANK",
        payload: {
          index: rowIndex
        }
      });
      setElementCalculate(elementCalculate - 1);
    } catch (error) {
      errorNotification('サーバー側でエラーが発生しました。');
    }
  };

  const handleChangeListName = (e: any, rowIndex: number) => {
    dispatch({
      type: "CHANGE_LIST_NAME",
      payload: {
        index: rowIndex,
        name: e.target.value,
      },
    });
    let inputCheck: any = arrInputChange;
    if (inputCheck.includes('nameList') === false) {
      inputCheck.push('nameList');
    }
    setArrInputChange(inputCheck);
  }

  const handleChangeCompanyBank = (value: any, rowIndex: number, name: string) => {
    dispatch({
      type: "CHANGE_COMPANY_BANK",
      payload: {
        index: rowIndex,
        value,
        name
      },
    });
    let inputCheck: any = arrInputChange;
    if (JSON.stringify(state.companyBankList) !== JSON.stringify(defaultReducer['companyBankList']) && inputCheck.includes('companyBankList') === false) {
      inputCheck.push('companyBankList');
    }
    if (JSON.stringify(state.companyBankList) === JSON.stringify(defaultReducer['companyBankList']) && inputCheck.includes('companyBankList') === true) {
      var index = inputCheck.indexOf('companyBankList')
      if (index !== -1) {
        inputCheck.splice(index, 1);
      }
    }
    setArrInputChange(inputCheck);
  }

  const tableColumnsName: TableColumnsType[] = [
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
          <FormField displayBlock={true} key={colIndex}>
            <TextForm
              name="name"
              label={`名称${rowIndex + 1}`}
              value={state.nameList[rowIndex]?.name}
              onChange={(e) => { handleChangeListName(e, rowIndex) }}
            />
          </FormField>
      ),
    }
  ];

  const reNumberic = (value: string) => {
    const re = /^[0-9\b\n]*$/;
    return re.test(value);
  }

  const tableBankCompany: TableColumnsType[] = [
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td style={{ width: '5%', paddingTop: 25 }} key={colIndex}>
          <FormField>
            <span style={{ fontSize: '14px' }}>銀行 {rowIndex + 1}</span>
          </FormField>
        </td>
      ),
    },
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td style={{ width: '0%' }} key={colIndex}>
          <FormField>
            {/* <span style={{ width: 50, paddingTop: 7, paddingRight: 5, fontSize: '14px' }}>銀行</span> */}
            <SelectFormVirtical
              label="銀行"
              name="bankCode"
              value={String(state.companyBankList[rowIndex].bankCode)}
              setValue={(val) => { handleChangeCompanyBank(val, rowIndex, 'bankCode') }}
              options={companyBankOptions}
              paddingTop={5}
              paddingBottom={3}
              smallSelect={true}
            />
          </FormField>
        </td>
      ),
    },
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td style={{ width: '20%' }} key={colIndex}>
          <FormField>
            {/* <span style={{ width: 50, paddingTop: 7, paddingRight: 5, fontSize: '14px' }}>銀行名</span> */}
            <TextForm
              name="otherBankName"
              label='銀行名'
              value={state.companyBankList[rowIndex].otherBankName}
              disable={String(state.companyBankList[rowIndex].bankCode) !== '0' ? true : false}
              onChange={(e) => { handleChangeCompanyBank(e.target.value, rowIndex, 'otherBankName') }}
              customStyleLabel={css({ paddingTop: '12px' })}
            />
          </FormField>
        </td>
      ),
    },
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td style={{ width: '20%' }} key={colIndex}>
          <FormField>
            {/* <span style={{ width: 70, paddingTop: 7, paddingRight: 5, fontSize: '14px' }}>登録番号</span> */}
            <TextForm
              name="registrationNo"
              label='登録番号'
              value={state.companyBankList[rowIndex].registrationNo}
              onChange={(e) => {
                if (reNumberic(e.target.value)) {
                  handleChangeCompanyBank(e.target.value, rowIndex, 'registrationNo')
                }
              }}
              maxLength={16}
              customStyleLabel={css({ paddingTop: '12px' })}
            />
          </FormField>
        </td>
      ),
    },
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td style={{ width: '20%' }} key={colIndex}>
          <FormField isPadding={true}>
            <span style={{ width: 70, paddingTop: 12, fontSize: '14px' }}>期間</span>
          </FormField>
          <FormField isPadding={true}>
            <DatePickerForm
              label=""
              date={moment(state.companyBankList[rowIndex].applyStartDate).toDate() || ''}
              dateFormat="yyyy年MM月dd日"
              changeDate={(date: Date) => {
                if (date !== null) {
                  const newdate = date ? moment(date).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD');
                  handleChangeCompanyBank(newdate, rowIndex, 'applyStartDate')
                }
              }}
              isFullWidth={true}
              removeMargin={true}
            />
            <DatePickerForm
              label=""
              date={moment(state.companyBankList[rowIndex].applyEndDate).toDate() || ''}
              dateFormat="yyyy年MM月dd日"
              changeDate={(date: Date) => {
                if (date !== null) {
                  const newdate = date ? moment(date).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD');
                  handleChangeCompanyBank(newdate, rowIndex, 'applyEndDate')
                }
              }}
              disable={
                moment(state.companyBankList[rowIndex].applyEndDate).format('YYYY/MM/DD') === '9999/12/31' ||
                state.companyBankList[rowIndex].applyEndDate === '' ? true : false
              }
              isFullWidth={true}
              removeMargin={true}
            />
          </FormField>
        </td>
      ),
    },
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td style={{ width: '5%', paddingTop: 43 }} key={colIndex}>
          <FormField>
            <Checkbox
              id={`decided${rowIndex}`}
              name="decided"
              label="未定"
              value=""
              checked={
                moment(state.companyBankList[rowIndex].applyEndDate).format('YYYY/MM/DD') === '9999/12/31' ||
                state.companyBankList[rowIndex].applyEndDate === '' ? true : false
              }
              onChange={(e) => {
                let checked = '';
                if (moment(state.companyBankList[rowIndex].applyEndDate).format('YYYY/MM/DD') === '9999/12/31' ||
                state.companyBankList[rowIndex].applyEndDate === '') {
                  checked = moment().format('YYYY/MM/DD')
                }
                handleChangeCompanyBank(checked, rowIndex, 'applyEndDate')
              }}
            />
          </FormField>
        </td>
      ),
    },
    {
      name: '',
      visible: true,
      width: '100%',
      content: (rowIndex: number, colIndex: number) => (
        <td key={colIndex} className="text-center" style={{ width: '2%', color: 'red', fontSize: 14, cursor: 'pointer', paddingTop: '30px' }} onClick={() => {
          deleteCompanyBank(rowIndex)
        }}>
          <Icon type="close" color={'red'} hoverColor={'red'} />
        </td>
      )
      ,
    },
  ];

  const addRow = () => {
    const newRows = [...addName, initStateName];
    setAddName(newRows);
    dispatch({
      type: "ADD_NAME",
    });
    let inputCheck: any = arrInputChange;
    if (JSON.stringify(newRows) !== JSON.stringify(defaultReducer['nameList']) && inputCheck.includes('nameList') === false) {
      inputCheck.push('nameList');
    }
    if (JSON.stringify(newRows) === JSON.stringify(defaultReducer['nameList']) && inputCheck.includes('nameList') === true) {
      var index = inputCheck.indexOf('nameList')
      if (index !== -1) {
        inputCheck.splice(index, 1);
      }
    }
    setArrInputChange(inputCheck);
    setElementCalculate(elementCalculate + 1);
  }

  const addRowCompanyBank = () => {
    const newRows = [...addCompanyBank, defaultCompanyBank[0]];
    setAddCompanyBank(newRows);
    dispatch({
      type: "ADD_COMPANY_BANK",
    });
    let inputCheck: any = arrInputChange;
    if (JSON.stringify(newRows) !== JSON.stringify(defaultReducer['companyBankList']) && inputCheck.includes('companyBankList') === false) {
      inputCheck.push('companyBankList');
    }
    if (JSON.stringify(newRows) === JSON.stringify(defaultReducer['companyBankList']) && inputCheck.includes('companyBankList') === true) {
      var index = inputCheck.indexOf('companyBankList')
      if (index !== -1) {
        inputCheck.splice(index, 1);
      }
    }
    setArrInputChange(inputCheck);
    setElementCalculate(elementCalculate + 1);
  }

  const defaultList = ['組織情報', 'マネージャー設定', '特賃日設定'];

  const loadDetailFormList = ['組織情報', 'マネージャー設定', '特賃日設定', '店舗基本情報', '許認可', '端末・機器・金庫', 'ビル管理', '施設', '保険・その他']

  const loadDetailFormCompanyBankList = ['組織情報', 'マネージャー設定', '特賃日設定', '店舗基本情報', '許認可', '端末・機器・金庫', 'ビル管理', '施設', '銀行', '保険・その他']

  const [list, setList] = useState<any>(defaultList);

  useEffect(() => {
    if (openDetailForm) {
      if (arrCompanyBank && arrCompanyBank.companyBankList && arrCompanyBank.companyBankList.length > 0) {
        setList(loadDetailFormCompanyBankList);
      } else {
        setList(loadDetailFormList);
      }
    }
  }, [openDetailForm, arrCompanyBank])

  useEffect(() => {
    setMemoReCalCulate(memoReCalculate + 1)
  }, [elementCalculate, managerList]);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  return (
    <SidebarTemplate pageTitle="組織作成">
      <BlockUI blocking={blocking}>
        <FormContents>
          <ScrollMenuComponent
            items={list}
            buttonSubmit={true}
            renderElementByAddOrRemove={memoReCalculate}
            setOpenDetailForm={setOpenDetailForm}
            openDetailForm={openDetailForm}
            indexHiddenContent={2}
            roleScreen={roleScreen}
            handleButtonSubmit={handleButtonSubmit}
          >
            {
              <OrganizationInputForm
                initialOrganizationDomain={organizationDomain || undefined}
                setInitialOrganizationDomain={setOrganizationDomain}
                historyList={historyList}
                setHistoryList={setHistoryList}
                state={state}
                handleOnChange={handleOnChange}
              />
            }
            {
              <OrganizationManagerEditForm
                managerList={managerList}
                seniorManagerList={seniorManagerList}
                setManagerList={setManagerList}
                setSeniorManagerList={setSeniorManagerList}
                isEditMode={false}
                state={state}
                dispatch={dispatch}
              />
            }
            {
              <OrganizationSpecialSalaryEditForm
                isEditMode={false}
                state={state}
                handleOnChange={handleOnChange}
                roleScreen={roleScreen}
                dispatch={dispatch}
              />
            }
            {
              <StoreBasicInformationForm
                state={state}
                handleOnChange={handleOnChange}
                handleOnBlur={handleOnBlur}
                addName={renderListAddName(10)}
                tableColumnsName={tableColumnsName}
                addRow={addRow}
              />
            }
            {
              <LicenseForm
                state={state}
                handleOnChange={handleOnChange}
              />
            }
            {
              <TerminalEquipmentSafeForm
                state={state}
                handleOnChange={handleOnChange}
              />
            }
            {
              <BuildingManagementForm
                state={state}
                handleOnChange={handleOnChange}
              />
            }
            {
              <FacilityForm
                state={state}
                handleOnChange={handleOnChange}
              />
            }
            {
              arrCompanyBank && arrCompanyBank.companyBankList && arrCompanyBank.companyBankList.length > 0 &&
              <BankForm
                addCompanyBank={addCompanyBank}
                tableBankCompany={tableBankCompany}
                addRowCompanyBank={addRowCompanyBank}
              />
            }
            {
              <InsuranceBankOtherForm
                state={state}
                handleOnChange={handleOnChange}
              />
            }
          </ScrollMenuComponent>
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default OrganizationAddPage;
