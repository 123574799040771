export interface DescriptionMaster{
    descriptionId: string,
    descriptionCode: string,
    descriptionName: string,
    journalCode: string,
    journalName: string,
    classificationCode: string,
    classificationName: string,
    journalId: string,
    classificationId:string,
}

export default class DescriptionMasterDomain {
  constructor(private rawData:DescriptionMaster) {
    // do nothing
  }

  static generateInitial(): DescriptionMasterDomain {
    return new DescriptionMasterDomain({
      descriptionId: '',
      descriptionCode: '',
      descriptionName: '',
      journalCode: '',
      journalName: '',
      classificationCode: '',
      classificationName: '',
      journalId: '',
      classificationId: '',
    });
  }

  getRawData(): DescriptionMaster {
    return this.rawData;
  }

  get descriptionId(): string {
    return this.rawData.descriptionId;
  }

  set descriptionId(descriptionId: string) {
    this.rawData.descriptionId = descriptionId;
  }

  get descriptionCode(): string {
    return this.rawData.descriptionCode;
  }

  set descriptionCode(descriptionCode: string) {
    this.rawData.descriptionCode = descriptionCode;
  }

  get descriptionName(): string {
    return this.rawData.descriptionName;
  }

  set descriptionName(descriptionName: string) {
    this.rawData.descriptionName = descriptionName;
  }

  get journalCode(): string {
    return this.rawData.journalCode;
  }

  set journalCode(journalCode: string) {
    this.rawData.journalCode = journalCode;
  }

  get journalName(): string {
    return this.rawData.journalName;
  }

  set journalName(journalName: string) {
    this.rawData.journalName = journalName;
  }

  get classificationCode(): string {
    return this.rawData.classificationCode;
  }

  set classificationCode(classificationCode: string) {
    this.rawData.classificationCode = classificationCode;
  }

  get classificationName(): string {
    return this.rawData.classificationName;
  }

  set classificationName(classificationName: string) {
    this.rawData.classificationName = classificationName;
  }

  get journalId(): string {
    return this.rawData.journalId;
  }

  set journalId(journalId: string) {
    this.rawData.journalId = journalId;
  }

  get classificationId(): string {
    return this.rawData.classificationId;
  }

  set classificationId(classificationId: string) {
    this.rawData.classificationId = classificationId;
  }
}
