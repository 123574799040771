/** @jsx jsx */
import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import DatePicker from 'components/molecules/DatePicker';
import TabControl from 'components/atoms/TabControl';
import FormContents from 'components/atoms/Form/FormContents';
import moment from 'moment';
import BlockUI from 'components/molecules/BlockUi';
import useToastNotification from 'hooks/useToastNotification';
import ApplyingTab from './ApplyingTab';
import ApprovedTab from './ApprovedTab';
import RejectedTab from './RejectedTab';

import useAttendApplicationApproval from './hooks';

type SelectType = 'applying' | 'approved' | 'rejected';

const SelectType: { [key in SelectType]: SelectType } = {
  applying: 'applying',
  approved: 'approved',
  rejected: 'rejected',
};

const AttendApplicationApproval: React.FC = () => {
  const [selectType, setSelectType] = React.useState<SelectType>(
    SelectType.applying,
  );

  const {
    isLoading,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    initialDateFrom,
    setInitialDateFrom,
    initialDateTo,
    setInitialDateTo,

    applyingStampList,
    applyingHolidayList,
    applyingOvertimeList,
    applyingHolidayWorkList,
    applyingTransferList,
    approvedStampList,
    approvedHolidayList,
    approvedOvertimeList,
    approvedHolidayWorkList,
    approvedTransferList,
    rejectedStampList,
    rejectedHolidayList,
    rejectedOvertimeList,
    rejectedHolidayWorkList,
    rejectedTransferList,

    fetchData,

    fetchApprovedStampList,
    fetchApprovedHolidayList,
    fetchApprovedOvertimeList,
    fetchApprovedHolidayWorkList,
    fetchApprovedTransferList,

    fetchRejectedStampList,
    fetchRejectedHolidayList,
    fetchRejectedOvertimeList,
    fetchRejectedHolidayWorkList,
    fetchRejectedTransferList,

    setApplyingStampList,
    setApplyingHolidayList,
    setApplyingOvertimeList,
    setApplyingHolidayWorkList,
    setApplyingTransferList,
  } = useAttendApplicationApproval();
  const { errorNotification } = useToastNotification();

  useEffect(() => {
    if (moment(targetDateTo).diff(targetDateFrom, 'year') >= 1) {
      errorNotification('対象期間の範囲は１年以内で入力してください');
      return;
    }

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDateFrom]);

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox customStyle={css({ marginBottom: '30px' })}>
          <FlexBoxItem marginRight="15px">
            <FormLabel label="年月 " />
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePicker
              dateFormat="yyyy年MM月"
              date={targetDateFrom}
              changeDate={(date: Date) => {
                if (date !== null) {
                  const selectedMonth = date.getMonth()+1;
                  const selectedYear = date.getFullYear();
                  const monthFrom = moment(date).startOf('month').toDate();
                  const monthTo = moment(date).endOf('month').toDate();
                  setTargetDateFrom(monthFrom);
                  setTargetDateTo(monthTo);
                  setInitialDateFrom(monthFrom);
                  setInitialDateTo(monthTo);
                }
              }}
              isFullWidth={true}
              showMonthYearPicker={true}
            />
          </FlexBoxItem>
        </FlexBox>
        <TabControl
          items={[
            {
              value: SelectType.applying,
              display: `要承認 (${applyingStampList.length
                + applyingHolidayList.length
                + applyingOvertimeList.length
                + applyingHolidayWorkList.length
                + applyingTransferList.length}件)`,
            },
            {
              value: SelectType.approved,
              display: `承認済 (${approvedStampList.length
                + approvedHolidayList.length
                + approvedOvertimeList.length
                + approvedHolidayWorkList.length
                + approvedTransferList.length}件)`,
            },
            {
              value: SelectType.rejected,
              display: `否決済 (${rejectedStampList.length
                + rejectedHolidayList.length
                + rejectedOvertimeList.length
                + rejectedHolidayWorkList.length
                + rejectedTransferList.length}件)`,
            },
          ]}
          setValue={setSelectType as (arg: string) => void}
          currentValue={selectType}
          isFixed={true}
        />
        <div>
          {/* 要承認 */}
          {selectType === SelectType.applying && (
            <ApplyingTab
              stampList={applyingStampList}
              holidayList={applyingHolidayList}
              overtimeList={applyingOvertimeList}
              holidayWorkList={applyingHolidayWorkList}
              transferList={applyingTransferList}
              // fetch approved API
              fetchApprovedStampList={fetchApprovedStampList}
              fetchApprovedHolidayList={fetchApprovedHolidayList}
              fetchApprovedOvertimeList={fetchApprovedOvertimeList}
              fetchApprovedHolidayWorkList={fetchApprovedHolidayWorkList}
              fetchApprovedTransferList={fetchApprovedTransferList}
              // fecth rejected API
              fetchRejectedStampList={fetchRejectedStampList}
              fetchRejectedHolidayList={fetchRejectedHolidayList}
              fetchRejectedOvertimeList={fetchRejectedOvertimeList}
              fetchRejectedHolidayWorkList={fetchRejectedHolidayWorkList}
              fetchRejectedTransferList={fetchRejectedTransferList}
              // set list
              setApplyingTransferList={setApplyingTransferList}
              setApplyingStampList={setApplyingStampList}
              setApplyingHolidayList={setApplyingHolidayList}
              setApplyingOvertimeList={setApplyingOvertimeList}
              setApplyingHolidayWorkList={setApplyingHolidayWorkList}
            />
          )}

          {/* 承認済 */}
          {selectType === SelectType.approved && (
            <ApprovedTab
              stampList={approvedStampList}
              holidayList={approvedHolidayList}
              overtimeList={approvedOvertimeList}
              holidayWorkList={approvedHolidayWorkList}
              transferList={approvedTransferList}
            />
          )}

          {/* 否決済 */}
          {selectType === SelectType.rejected && (
            <RejectedTab
              stampList={rejectedStampList}
              holidayList={rejectedHolidayList}
              overtimeList={rejectedOvertimeList}
              holidayWorkList={rejectedHolidayWorkList}
              transferList={rejectedTransferList}
            />
          )}
        </div>
      </FormContents>
    </BlockUI>
  );
};

export default AttendApplicationApproval;
