import { useState } from 'react';
import {
  getSearchYearMonth, getWorkRecords, getRequestResult, getLeaveStatus,
} from 'api/workingStatus';
import moment from 'moment';
import {
  StaffStampList, IApplyResult, Employment,
} from '../interface';

export type SelectType = 'workRecord' | 'result' | 'vacation';

const useWorkingStatusData = () => {
  const tabTypes: { [key in SelectType]: SelectType } = {
    workRecord: 'workRecord',
    result: 'result',
    vacation: 'vacation',
  };

  const convertedYearMonthStr = sessionStorage.getItem('workingStatus.yearMonth') || new Date();
  const loginStaffCcde = sessionStorage.getItem('loginUser.staffCode') || '';
  const loginStaffName = sessionStorage.getItem('loginUser.staffName') || '';

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectType, setSelectType] = useState<SelectType>(tabTypes.workRecord);
  const [getTargetDate, setTargetDate] = useState<Date>(new Date(convertedYearMonthStr));
  const [yearMonth, setYearMonth] = useState<Date>(new Date());
  const [getLoginStaffCcde, setLoginStaffCcde] = useState(loginStaffCcde);
  const [getLoginStaffName, setLoginStaffName] = useState(loginStaffName);
  const [staffStampList, setStaffStampList] = useState<StaffStampList[]>([]);
  const [employment, setEmployment] = useState<Employment>();
  const [applyResult, setApplyResult] = useState<IApplyResult[]>([]);
  const [leaveStatus, setLeaveStatus] = useState<any>();

  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const fetchList = async () => {
    setIsLoading(true);

    const { yearMonth } = await getSearchYearMonth(staffCode, moment(getTargetDate).format('YYYY/MM/DD'));
    sessionStorage.setItem('workingStatus.yearMonth', `${yearMonth}/01`);
    setYearMonth(moment(`${yearMonth}/01`).toDate());

    const { staffStampList, employment } = await getWorkRecords(staffCode, yearMonth);
    setStaffStampList(staffStampList);
    setEmployment(employment);

    const requestResultData = await getRequestResult(staffCode, yearMonth);
    setApplyResult(requestResultData);

    const leaveStatusData = await getLeaveStatus(staffCode, yearMonth);
    setLeaveStatus(leaveStatusData);

    setIsLoading(false);
  };

  return {
    isLoading,
    setIsLoading,
    fetchList,
    selectType,
    setSelectType,
    getTargetDate,
    setTargetDate,
    getLoginStaffCcde,
    setLoginStaffCcde,
    getLoginStaffName,
    setLoginStaffName,
    staffStampList,
    setStaffStampList,
    employment,
    setEmployment,
    applyResult,
    setApplyResult,
    leaveStatus,
    setLeaveStatus,
    staffCode,
    tabTypes,
    yearMonth,
  };
};

export default useWorkingStatusData;
