import { ApexOptions } from 'apexcharts';
import CustomCard from 'components/organismos/Charts/CustomCard';
import React, { useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { isMobileOnly, isTablet } from 'react-device-detect';

type YAxis =
  | {
      minimum: number;
      maximum: number;
      tickAmount: number;
    }
  | undefined;

type Props = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  labels: string[];
  targetDate: Date;
  fiscalYear: number | undefined;
  yaxisScaleCurrency: YAxis;
};

const YearlyChart: React.FC<Props> = ({
  series,
  labels,
  targetDate,
  fiscalYear,
  yaxisScaleCurrency,
}) => {
  const title = `${fiscalYear ?? ''}年度売上実績`;
  const month = targetDate.getMonth() + 1; // JavaScriptの月は0から始まるため、+1をします。

  const options: ApexOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none', // ホバー時のフィルターを無効化
        },
      },
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: Number.MIN_SAFE_INTEGER, // 実質的に無制限に近い負の値
              to: 0,
              color: '#F15B46',
            },
          ],
        },
      },
    },
    stroke: {
      width: [0, 0, 3],
    },
    title: {
      text: title,
      style: { fontSize: '16px', fontWeight: 'bold' },
    },
    labels,
    legend: {
      show: false,
    },
    xaxis: {
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
        maxHeight: 25,
      },
    },
    yaxis: [
      {
        showAlways: true,
        axisBorder: {
          show: true,
        },
        labels: {
          formatter(val) {
            return val.toLocaleString();
          },
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
          },
          maxWidth: 70,
        },
        title: {
          text: '（千円）',
          rotate: -0,
          offsetX: 45, // 水平方向にタイトルを移動します。正の値は右、負の値は左へ移動。
          offsetY: -230, // 垂直方向にタイトルを移動します。正の値は下、負の値は上へ移動。
          style: {
            color: '#000',
            fontSize: '12px',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-title',
          },
        },
        min: yaxisScaleCurrency?.minimum ?? -10000,
        max: yaxisScaleCurrency?.maximum ?? 100000,
        tickAmount: yaxisScaleCurrency?.tickAmount ?? 10,
        forceNiceScale: true,
      },
    ],
    tooltip: {
      custom: () => '',
      x: {
        show: true,
        formatter(val, index) {
          if (labels[index.dataPointIndex]) {
            setXAxisData(labels[index.dataPointIndex].toString());
            return `${labels[index.dataPointIndex].toString()}`;
          }
          return val.toString();
        },
      },
      y: [
        {
          formatter(y) {
            if (typeof y !== 'undefined') {
              setTooltipData((prev) => {
                const prevData = prev;

                if (prevData.length > 0) {
                  prevData[0].value = `${y.toLocaleString()}`;
                }
                return [...prev];
              });
              return y.toLocaleString();
            }
            return y;
          },
        },
        {
          formatter(y) {
            if (typeof y !== 'undefined') {
              setTooltipData((prev) => {
                const prevData = prev;

                if (prevData.length > 0) {
                  prevData[1].value = `${y.toLocaleString()}`;
                }
                return [...prev];
              });
              return y.toLocaleString();
            }
            return y;
          },
        },
        {
          formatter(y) {
            if (typeof y !== 'undefined') {
              setTooltipData((prev) => {
                const prevData = prev;

                if (prevData.length > 0) {
                  prevData[2].value = `${y.toLocaleString()}`;
                }
                return [...prev];
              });
              return y.toLocaleString();
            }
            return y;
          },
        },
      ],
    },
  };

  const chartRef = useRef<ReactApexChart | null>(null);
  const [tooltipData, setTooltipData] = useState<
    { name: string; color: string; value: string; check: boolean }[]
  >([]);
  const [xAxisData, setXAxisData] = useState<string>('');

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = (chartRef.current as any).chart;
      const globalData = chartInstance.w.globals;

      setXAxisData(month.toString());

      setTooltipData(
        globalData.initialSeries.map((s: any, index: number) => {
          let initialData = s.data.find(
            (_item: any, i: number) => labels[i] === month.toString(),
          );

          if (initialData !== undefined) {
            initialData = `${initialData.toLocaleString()}`;
          }

          return {
            name: s.name,
            color: globalData.colors[index],
            value: initialData || '-',
            check: true,
          };
        }),
      );
    }
  }, [labels, month, targetDate]);

  const handleLegendClick = (index: number) => {
    if (chartRef.current) {
      const chartInstance = (chartRef.current as any).chart;
      chartInstance.toggleSeries(chartInstance.w.globals.seriesNames[index]);
      setTooltipData((prev) => {
        const prevData = prev;

        if (prevData.length > 0) {
          prevData[index].check = !prevData[index].check;
        }
        return [...prev];
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isMobileOnly || isTablet ? 'column' : 'row',
        paddingRight: isMobileOnly || isTablet ? '0px' : '8px',
      }}
    >
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <ReactApexChart
          ref={chartRef}
          options={options}
          series={series}
          height={507}
        />
      </div>
      <div
        style={{
          width: isMobileOnly || isTablet ? '100%' : '148px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px 8px 4px 8px',
          }}
        >
          <span
            style={{
              color: 'black',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '200%',
            }}
          >
            {`${xAxisData}月`}
          </span>
        </div>
        <div
          style={{
            width: isMobileOnly || isTablet ? '100%' : '148px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: isMobileOnly || isTablet ? 'row' : 'column',
            gap: isMobileOnly || isTablet ? '4px' : '8px',
          }}
        >
          {tooltipData.map((s, index) => (
            <button
              key={s.name ?? ''}
              style={{
                display: 'inline-block',
                marginLeft: '4px',
                paddingTop: '8px',
                cursor: 'pointer',
                border: `1px solid ${s.color}`,
                borderRadius: '5px',
              }}
              onClick={() => handleLegendClick(index)}
              type="button"
            >
              <CustomCard
                title={s.name}
                color={s.color}
                value={s.value}
                check={s.check}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default YearlyChart;
