/** @jsx jsx */
import React, { Dispatch, SetStateAction } from 'react';
import { css, jsx } from '@emotion/core';

import { AttendErrorOgr } from 'api/attendError';
import { textColor, utilityColor, grayScale } from 'components/styles';
import AppNotificationPanel from 'components/molecules/AppNotificationPanel';
import Heading from 'components/atoms/Heading';
import Table from 'components/molecules/Table';
import { Link } from 'react-router-dom';
import Separater from 'components/atoms/Separator';
import Icon from 'components/atoms/Icon';
import FormContents from 'components/atoms/Form/FormContents';

const styles = {
  link: css({
    minWidth: '140px',
    margin: '6px 8px',
    textDecoration: 'none',
  }),
  width: css({
    width: '50vw',
  }),
};

const removeSession = () => {
  sessionStorage.removeItem('attendError.isFilter');
  sessionStorage.removeItem('attendError.selectCategory');
  sessionStorage.removeItem('attendError.selectCategoryOption');
  sessionStorage.removeItem('sessionOrganizationFileterTree/attendError.Toggle');
  sessionStorage.removeItem('sessionOrganizationFileterTree/attendError.Selected');
};

const AttendErrorOrg: React.FC<{
  attendErrorList: Array<AttendErrorOgr>;
  setOpenAttendError: Dispatch<SetStateAction<boolean>>;
}> = ({ attendErrorList, setOpenAttendError }) => (
  <AppNotificationPanel>
    <Heading customStyle={css({ color: textColor.main, padding: '12px' })}>
      勤怠アラート
    </Heading>
    <Separater margin="5px" />
    <div style={{ maxHeight: 'calc(100vh - 30vh)', overflowY: 'auto' }}>
      <Table customStyle={styles.width}>
        <tbody>
          {
            attendErrorList.length > 0 ? attendErrorList.map((dataElement, index) => (
              <tr key={String(index)}>
                <Table.Cell>
                  <FormContents isSubForm={true}>
                    <div>
                      <Icon type="warning" color={grayScale.gray100} />
                      <Link
                        onClick={() => {
                          setOpenAttendError(false);
                          removeSession();
                          sessionStorage.setItem('flgHanldeScreenAttendError', String(1));
                          sessionStorage.setItem('attendError.orgCode', dataElement.orgCode);
                          sessionStorage.setItem('attendErrorIcon.targetDateFrom', dataElement.targetDateFrom);
                          sessionStorage.setItem('attendErrorIcon.targetDateTo', dataElement.targetDateTo);
                          sessionStorage.setItem('clearToggle', String(1));
                        }}
                        to="/attendError"
                        css={styles.link}
                      >
                        <span>{dataElement.orgName}</span>
                      </Link>
                    </div>
                    <div>
                      <span css={{ color: utilityColor.black }}>
                        エラーが発生している勤怠が
                        {dataElement.errCount}
                        件あります。
                      </span>
                    </div>
                  </FormContents>
                </Table.Cell>
              </tr>
            ))
              : (
                <tr>
                  <Table.Cell>
                    <FormContents isSubForm={true}>
                      <span css={{ color: utilityColor.black }}>アラートはありません</span>
                    </FormContents>
                  </Table.Cell>
                </tr>
              )
          }
        </tbody>
      </Table>
    </div>
  </AppNotificationPanel>
);

export default AttendErrorOrg;
