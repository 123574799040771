/* eslint-disable max-len */
import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import {
  timeDisplayFormat, timeDisplayFormatLabel, roundMethodOfThirdDecimalPlace,
  roundMethodOfThirdDecimalPlaceLabel, midnightBreakdownDisplay, midnightBreakdownDisplayLabel,
  useFaceStampLabel, useFaceStamp, useBarcodeStampLabel, useBarcodeStamp, costRoundingFlagLabel,
  costRoundingFlag, monthlyProratedFlagLabel, monthlyProratedFlag, monthlyTransportationProratedFlagLabel,
  monthlyTransportationProratedFlag, supportTransportationFlagLabel, supportTransportationFlag,
  hopeShiftLimitFlagLabel, hopeShiftLimitFlag,
  //  mwsDisplayFlagLabel,mwsDisplayFlag,
  mwsDisplayStartDowLabel, mwsDisplayStartDow, shiftCreateFlag, shiftCreateFlagLabel, displayActualStampingFlag, displayActualStampingFlagLabel,
  autoExtraHourlySalaryFlag, autoExtraHourlySalaryFlagLabel,
}
  from 'domain/master/labor/timeSetting';
import Button from 'components/atoms/Button';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import { gridCol, utilityColor } from 'components/styles';
import HourInput from 'components/atoms/Form/HourInput';
import FlexBox from 'components/atoms/FlexBox';
import FormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import FormLabel from 'components/atoms/Form/FormLabel';
import SelectForm from 'components/molecules/SelectForm';
import BodyText from 'components/atoms/BodyText';
import ClickableLink from 'components/atoms/ClickableLink';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import BlockUI from 'components/molecules/BlockUi';
import { useSettingUpdateForm } from './hooks';
import { Values } from 'utility/constants';

const TimeSettingForm: React.FC<{
  roleScreen?: any
}> = ({ roleScreen }) => {
  const {
    formik, confirmModalOpen, closeConfirmModal, isLoading,
  } = useSettingUpdateForm();

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <FormContents>
              <FormTitle title="システム共通設定" />
              {/* timeDisplayFormat */}
              <FormField>
                <RadioSelectForm
                  label="時間表示形式"
                  subLabel=""
                  items={[
                    {
                      label: timeDisplayFormatLabel[timeDisplayFormat.time10],
                      value: '0',
                    },
                    {
                      label: timeDisplayFormatLabel[timeDisplayFormat.time60],
                      value: '1',
                    },
                  ]}
                  name="timeDisplayFormat"
                  value={String(formik.values.timeDisplayFormat)}
                  setValue={(e) => {
                    formik.setFieldValue('timeDisplayFormat', e.target.value);
                  }}
                />
              </FormField>
              {String(formik.values.timeDisplayFormat) === timeDisplayFormat.time10.toString() && (
                <FormContents>
                  <RadioSelectForm
                    label="小数点第3位の処理設定"
                    items={Object.values(Values.roundMethodOfThirdDecimalPlace)}
                    name="roundMethodOfThirdDecimalPlace"
                    value={String(formik.values.roundMethodOfThirdDecimalPlace)}
                    setValue={(e) => {
                      formik.setFieldValue('roundMethodOfThirdDecimalPlace', e.target.value);
                    }}
                  />
                </FormContents>
              )}

              {/* midnightBreakdownDisplay */}
              <FormField>
                <RadioSelectForm
                  label="深夜時間の内訳表示"
                  items={[
                    {
                      label: midnightBreakdownDisplayLabel[midnightBreakdownDisplay.midnight0],
                      value: 'false',
                    },
                    {
                      label: midnightBreakdownDisplayLabel[midnightBreakdownDisplay.midnight1],
                      value: 'true',
                    },
                  ]}
                  name="midnightBreakdownDisplay"
                  value={String(formik.values.midnightBreakdownDisplay)}
                  setValue={(e) => {
                    formik.setFieldValue('midnightBreakdownDisplay', e.target.value);
                  }}
                />
              </FormField>

              {/* longestWorkingHours */}
              <FormField>
                <div style={{ width: gridCol.grid06 }}>
                  <FormLayout
                    label={(
                      <FormLabel
                        label="1日の連続最長勤務時間"
                      />
                    )}
                    input={(
                      <FlexBox>
                        <HourInput
                          name="longestWorkingHours"
                          value={formik.values.longestWorkingHours ? formik.values.longestWorkingHours : 0}
                          label="時間"
                          onChange={formik.handleChange}
                          min={12}
                          max={48}
                        />
                        <BodyText color={utilityColor.error} size="sm">※この時間以上を経過して打刻すると出勤打刻となります。</BodyText>
                      </FlexBox>
                    )}
                  />
                </div>
              </FormField>

              {/* useFaceStamp  */}
              <FormField>
                <RadioSelectForm
                  label="顔認証打刻機能"
                  items={[
                    {
                      label: useFaceStampLabel[useFaceStamp.useFaceStamp0],
                      value: '0',
                    },
                    {
                      label: useFaceStampLabel[useFaceStamp.useFaceStamp1],
                      value: '1',
                    },
                  ]}
                  name="useFaceStamp"
                  value={String(formik.values.useFaceStamp)}
                  setValue={(e) => {
                    formik.setFieldValue('useFaceStamp', e.target.value);
                  }}
                />
              </FormField>

              {/* useBarcodeStamp*/}
              <FormField>
                <RadioSelectForm
                  label="ID打刻機能"
                  items={[
                    {
                      label: useBarcodeStampLabel[useBarcodeStamp.useBarcodeStamp0],
                      value: '0',
                    },
                    {
                      label: useBarcodeStampLabel[useBarcodeStamp.useBarcodeStamp1],
                      value: '1',
                    },
                  ]}
                  name="useBarcodeStamp"
                  value={String(formik.values.useBarcodeStamp)}
                  setValue={(e) => {
                    formik.setFieldValue('useBarcodeStamp', e.target.value);
                  }}
                />
              </FormField>

              {/* useBarcodeStamp*/}
              <FormField>
                <RadioSelectForm
                  label="人件費端数処理設定 "
                  items={[
                    {
                      label: costRoundingFlagLabel[costRoundingFlag.costRoundingFlag1],
                      value: '1',
                    },
                    {
                      label: costRoundingFlagLabel[costRoundingFlag.costRoundingFlag0],
                      value: '0',
                    },
                    {
                      label: costRoundingFlagLabel[costRoundingFlag.costRoundingFlag4],
                      value: '4',
                    },
                  ]}
                  name="costRoundingFlag"
                  value={String(formik.values.costRoundingFlag)}
                  setValue={(e) => {
                    formik.setFieldValue('costRoundingFlag', e.target.value);
                  }}
                  note="※変更すると、登録時に当月分の人件費を再計算します。"
                />
              </FormField>

              {/* monthlyProratedFlag*/}
              <FormField>
                <RadioSelectForm
                  label="月給者人件費計上設定 "
                  items={[
                    {
                      label: monthlyProratedFlagLabel[monthlyProratedFlag.ProratedFlag0],
                      value: '0',
                    },
                    {
                      label: monthlyProratedFlagLabel[monthlyProratedFlag.ProratedFlag1],
                      value: '1',
                    },
                    {
                      label: monthlyProratedFlagLabel[monthlyProratedFlag.ProratedFlag2],
                      value: '2',
                    },
                  ]}
                  name="monthlyProratedFlag"
                  value={String(formik.values.monthlyProratedFlag)}
                  setValue={(e) => {
                    formik.setFieldValue('monthlyProratedFlag', e.target.value);
                  }}
                  note="※変更すると、登録時に当月分の人件費を再計算します。"
                />
              </FormField>

              {/* autoExtraHourlySalaryFlag */}
              <FormField>
                <RadioSelectForm
                  label="月給・日給者基礎賃金自動算出設定 "
                  items={[
                    {
                      label: autoExtraHourlySalaryFlagLabel[autoExtraHourlySalaryFlag.ProratedFlag1],
                      value: '1',
                    },
                    {
                      label: autoExtraHourlySalaryFlagLabel[autoExtraHourlySalaryFlag.ProratedFlag0],
                      value: '0',
                    },
                  ]}
                  name="autoExtraHourlySalaryFlag"
                  value={String(formik.values.autoExtraHourlySalaryFlag)}
                  setValue={(e) => {
                    formik.setFieldValue('autoExtraHourlySalaryFlag', e.target.value);
                  }}
                  note="※変更すると、登録時に当月分の人件費を再計算します。"
                />
              </FormField>

              {/* monthlyTransportationProratedFlag*/}
              <FormField>
                <RadioSelectForm
                  label="月額交通費計上設定"
                  items={[
                    {
                      label: monthlyTransportationProratedFlagLabel[monthlyTransportationProratedFlag.TransportationProratedFlag0],
                      value: '0',
                    },
                    {
                      label: monthlyTransportationProratedFlagLabel[monthlyTransportationProratedFlag.TransportationProratedFlag1],
                      value: '1',
                    },
                    {
                      label: monthlyTransportationProratedFlagLabel[monthlyTransportationProratedFlag.TransportationProratedFlag2],
                      value: '2',
                    },
                  ]}
                  name="monthlyTransportationProratedFlag"
                  value={String(formik.values.monthlyTransportationProratedFlag)}
                  setValue={(e) => {
                    formik.setFieldValue('monthlyTransportationProratedFlag', e.target.value);
                  }}
                  note="※変更すると、登録時に当月分の人件費を再計算します。"
                />
              </FormField>

              {/* supportTransportationFlag*/}
              <FormField>
                <RadioSelectForm
                  label="支援交通費計上設定: "
                  items={[
                    {
                      label: supportTransportationFlagLabel[supportTransportationFlag.supportTransportationFlag0],
                      value: '0',
                    },
                    {
                      label: supportTransportationFlagLabel[supportTransportationFlag.supportTransportationFlag1],
                      value: '1',
                    },
                  ]}
                  name="supportTransportationFlag"
                  value={String(formik.values.supportTransportationFlag)}
                  setValue={(e) => {
                    formik.setFieldValue('supportTransportationFlag', e.target.value);
                  }}
                  note=" ※変更すると、登録時に当月分の人件費を再計算します。"
                />
              </FormField>
            </FormContents>

            <FormContents>


              <FlexBox>
                <FormTitle title="システムデフォルト設定" />
                <BodyText bold={true}>
                  <FlexBox>
                    <span>（※</span>
                    <ClickableLink to="/masterOrganizeSetting" padding="0 0">
                      <span>シフト設定</span>
                    </ClickableLink>
                    <span>で店舗個別に設定されている場合は店舗の設定を優先します） </span>


                  </FlexBox>
                </BodyText>
              </FlexBox>


              {/* dispStartHour and dispHour */}
              <FormField>
                <FormLayout
                  label={(
                    <FormLabel
                      label="シフト表示時間"
                    />
                  )}
                  input={(
                    <FlexBox>
                      <div style={{ float: 'left', marginRight: '12px' }}>
                        <HourInput
                          name="dispStartHour"
                          value={formik.values.dispStartHour ? formik.values.dispStartHour : 0}
                          label="時から"
                          onChange={formik.handleChange}
                          min={0}
                          max={23}
                        />
                      </div>
                      <SelectForm
                        label=""
                        name="orgCode"
                        value={String(formik.values.dispHour)}
                        options={[
                          {
                            label: '12',
                            value: '12',
                          },
                          {
                            label: '18',
                            value: '18',
                          },
                          {
                            label: '24',
                            value: '24',
                          },
                        ]}
                        setValue={(hour) => {
                          formik.setFieldValue('dispHour', hour);
                        }}
                        description=""
                      />
                      <div style={{ marginLeft: '12px' }}>
                        <BodyText> 時間 </BodyText>
                      </div>
                    </FlexBox>
                  )}
                />
              </FormField>

              {/* mwsDisplayFlag */}
              {/* <FormField>
              <RadioSelectForm
                label="月次シフト表示設定"
                items={[
                  {
                    label: mwsDisplayFlagLabel[mwsDisplayFlag.mwsDisplayFlag0],
                    value: '0',
                  },
                  {
                    label: mwsDisplayFlagLabel[mwsDisplayFlag.mwsDisplayFlag1],
                    value: '1',
                  },
                ]}
                name="mwsDisplayFlag"
                value={String(formik.values.mwsDisplayFlag)}
                setValue={(e) => {
                  formik.setFieldValue('mwsDisplayFlag', e.target.value);
                }}
              />
            </FormField>
            {String(formik.values.mwsDisplayFlag) === mwsDisplayFlag.mwsDisplayFlag0.toString() && (
            <FormContents>
              <FlexBox>
                <SelectForm
                  label=""
                  name="mwsDisplayStartDow"
                  value={String(formik.values.mwsDisplayStartDow)}
                  options={[
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow0],
                      value: '0',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow1],
                      value: '1',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow2],
                      value: '2',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow3],
                      value: '3',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow4],
                      value: '4',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow5],
                      value: '5',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow6],
                      value: '6',
                    },
                  ]}
                  setValue={(m) => { formik.setFieldValue('mwsDisplayStartDow', m); }}
                />
                <div style={{ marginLeft: '12px' }}>
                  <BodyText>曜日～1週間表示</BodyText>
                </div>
              </FlexBox>
            </FormContents>
            )}
            {String(formik.values.mwsDisplayFlag) === mwsDisplayFlag.mwsDisplayFlag1.toString() && (
            <FormContents>
              <FlexBox>
                <HourInput
                  name="mwsDisplayStartDay1"
                  value={formik.values.mwsDisplayStartDay1?formik.values.mwsDisplayStartDay1:0}
                  label="日～1ヶ月表示"
                  onChange={formik.handleChange}
                  min={0}
                  max={31}
                />
                <BodyText color={utilityColor.error} size="sm">※「0」を入力すると月末という扱いになります。</BodyText>
              </FlexBox>
            </FormContents>
            )} */}

              {/* hopeShiftLimitFlag*/}
              <FormField>
                <RadioSelectForm
                  label="希望シフト提出期限: "
                  items={[
                    {
                      label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag0],
                      value: '0',
                    },
                    {
                      label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag1],
                      value: '1',
                    },
                    {
                      label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag2],
                      value: '2',
                    },
                    {
                      label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag3],
                      value: '3',
                    },
                  ]}
                  name="hopeShiftLimitFlag"
                  value={String(formik.values.hopeShiftLimitFlag)}
                  setValue={(e) => {
                    formik.setFieldValue('hopeShiftLimitFlag', e.target.value);
                  }}
                />
              </FormField>

              {/* - hopeShiftLimitFixedDay1 */}
              {String(formik.values.hopeShiftLimitFlag) === hopeShiftLimitFlag.hopeShiftLimitFlag1.toString() && (
                <FormContents>
                  <FlexBox>
                    <div style={{ marginRight: '12px' }}>
                      <BodyText> 前月 </BodyText>
                    </div>
                    <HourInput
                      name="hopeShiftLimitFixedDay1"
                      value={formik.values.hopeShiftLimitFixedDay1 ? formik.values.hopeShiftLimitFixedDay1 : 0}
                      label="日まで"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                    <BodyText color={utilityColor.error} size="sm">※「0」を入力すると月末という扱いになります。</BodyText>
                  </FlexBox>
                </FormContents>
              )}

              {/* hopeShiftLimitFixedDay1 - hopeShiftLimitFixedDay2*/}
              {String(formik.values.hopeShiftLimitFlag) === hopeShiftLimitFlag.hopeShiftLimitFlag2.toString() && (
                <FormContents>
                  <div>
                    <FlexBox>
                      <div style={{ marginRight: '35px' }}>
                        <BodyText> 1日～15日分シフト </BodyText>
                      </div>
                      <div style={{ marginRight: '12px' }}>
                        <BodyText> 前月 </BodyText>
                      </div>
                      <HourInput
                        name="hopeShiftLimitFixedDay1"
                        value={formik.values.hopeShiftLimitFixedDay1 ? formik.values.hopeShiftLimitFixedDay1 : 0}
                        label="日まで"
                        onChange={formik.handleChange}
                        min={0}
                        max={31}
                      />
                    </FlexBox>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <FlexBox>
                      <div style={{ marginRight: '30px' }}>
                        <BodyText> 16日～末日分シフト </BodyText>
                      </div>
                      <div style={{ marginRight: '12px' }}>
                        <BodyText> 当月 </BodyText>
                      </div>
                      <HourInput
                        name="hopeShiftLimitFixedDay2"
                        value={formik.values.hopeShiftLimitFixedDay2 ? formik.values.hopeShiftLimitFixedDay2 : 0}
                        label="日まで"
                        onChange={formik.handleChange}
                        min={0}
                        max={31}
                      />
                    </FlexBox>
                  </div>

                  <BodyText color={utilityColor.error} size="sm">※「0」を入力すると月末という扱いになります。</BodyText>
                </FormContents>
              )}

              {/* hopeShiftWeekStartDow - hopeShiftWeekOffset - hopeShiftWeekLimitDow*/}
              {String(formik.values.hopeShiftLimitFlag) === hopeShiftLimitFlag.hopeShiftLimitFlag3.toString() && (
                <FormContents>
                  <FlexBox>
                    <div style={{ marginRight: '12px' }}>
                      <BodyText>対象シフト</BodyText>
                    </div>
                    <SelectForm
                      label=""
                      name="hopeShiftWeekStartDow"
                      value={String(formik.values.hopeShiftWeekStartDow)}
                      options={[
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow0],
                          value: '0',
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow1],
                          value: '1',
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow2],
                          value: '2',
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow3],
                          value: '3',
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow4],
                          value: '4',
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow5],
                          value: '5',
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow6],
                          value: '6',
                        },
                      ]}
                      setValue={(m) => { formik.setFieldValue('hopeShiftWeekStartDow', m); }}
                    />
                    <div style={{ marginLeft: '12px' }}>
                      <BodyText>曜日～1週間のシフト</BodyText>
                    </div>
                  </FlexBox>

                  <FlexBox>

                    <HourInput
                      name="hopeShiftWeekOffset"
                      value={formik.values.hopeShiftWeekOffset ? formik.values.hopeShiftWeekOffset : 0}
                      label="週前の"
                      onChange={formik.handleChange}
                      min={0}
                      max={5}
                    />
                    <div style={{ marginLeft: '12px' }}>
                      <SelectForm
                        label=""
                        name="hopeShiftWeekLimitDow"
                        value={String(formik.values.hopeShiftWeekLimitDow)}
                        options={[
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow0],
                            value: '0',
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow1],
                            value: '1',
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow2],
                            value: '2',
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow3],
                            value: '3',
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow4],
                            value: '4',
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow5],
                            value: '5',
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow6],
                            value: '6',
                          },
                        ]}
                        setValue={(m) => { formik.setFieldValue('hopeShiftWeekLimitDow', m); }}
                      />
                    </div>
                    <div style={{ marginLeft: '12px' }}>
                      <BodyText>曜日まで</BodyText>
                    </div>
                  </FlexBox>
                  <BodyText color={utilityColor.error} size="sm">※週の開始日は日曜とします。「0」週間前とすると、同じ週という扱いになります。</BodyText>

                </FormContents>
              )}

              {/* hopeShiftLimitFlag0  */}
              {String(formik.values.hopeShiftLimitFlag) !== hopeShiftLimitFlag.hopeShiftLimitFlag0.toString() && (
                <FormField>
                  <RadioSelectForm
                    label="契約シフト自動作成"
                    items={[
                      {
                        label: shiftCreateFlagLabel[shiftCreateFlag.shiftCreateFlag0],
                        value: '0',
                      },
                      {
                        label: shiftCreateFlagLabel[shiftCreateFlag.shiftCreateFlag1],
                        value: '1',
                      },
                    ]}
                    name="shiftCreateFlag"
                    value={String(formik.values.shiftCreateFlag)}
                    setValue={(e) => {
                      formik.setFieldValue('shiftCreateFlag', e.target.value);
                    }}
                    note="※希望シフト提出期限後に提出がないスタッフのシフトをシフトパターン設定から自動作成します。"
                  />
                </FormField>
              )}
              {/* displayActualStampingFlagLabel */}

              <FormField>
                <RadioSelectForm
                  label="打刻データ確認画面に実打刻時間を表示"
                  items={[
                    {
                      label: displayActualStampingFlagLabel[displayActualStampingFlag.displayActualStampingFlag1],
                      value: '1',
                    },
                    {
                      label: displayActualStampingFlagLabel[displayActualStampingFlag.displayActualStampingFlag0],
                      value: '0',
                    },
                  ]}
                  name="stampingDataConfirm"
                  value={formik.values.displayActualStamping !== null ? String(formik.values.displayActualStamping) : '0'}
                  setValue={(e) => {
                    formik.setFieldValue('displayActualStamping', e.target.value);
                  }}
                />
              </FormField>

              <FormField>
                <RadioSelectForm
                  label="勤怠確定でCSVに出力されるスタッフ"
                  items={[
                    {
                      label: '全てのスタッフ',
                      value: '0',
                    },
                    {
                      label: '実績があるスタッフのみ',
                      value: '1',
                    },
                  ]}
                  name="outputStaffFlag"
                  value={String(formik.values.outputStaffFlag)}
                  setValue={(e) => {
                    formik.setFieldValue('outputStaffFlag', e.target.value);
                  }}
                />
              </FormField>

            </FormContents>
            {
              roleScreen && roleScreen.editable === 1 && (
                <div style={{ marginLeft: '16px' }}>
                  <FormSubmitArea>
                    <Button
                      text="登録"
                      onClick={formik.handleSubmit}
                    />
                    {/* <PrimaryButton text="戻る" ghost={true} /> */}
                  </FormSubmitArea>
                </div>
              )}
          </div>


        </form>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          actionType={ActionType.CREATE}
        />

      </FormContents>
    </BlockUI>
  );
};

export default TimeSettingForm;
