import ApiClient from './ApiClient';

export const getList = async (companyCode: string):Promise<any> => {
  const params = {};
  const response = await ApiClient.get(`/v1/specialSalaryPattern/list/${companyCode}`, params);
  return response.data;
};

export const deleteById = async (companyCode: string, specialSalaryPatternId: string): Promise<any> => {
  const params = {
    specialSalaryPatternId,
  };
  const response = await ApiClient.delete(`/v1/specialSalaryPattern/${companyCode}`, params);
  return response.data;
};

export default getList;
