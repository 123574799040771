/* eslint-disable max-len */
export enum DayOfWeekLabel {
  '祝日' = 7,
  '土曜日' = 6,
  '日曜日' = 0,
  '月曜日' = 1,
  '火曜日' = 2,
  '水曜日' = 3,
  '木曜日' = 4,
  '金曜日' = 5,
}

export enum DayOfWeek {
  HOLIDAY = 7,
  SAT = 6,
  SUN = 0,
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
}

export type AttendShiftPatternDetails = {
  createUser: string,
  updateUser: string,
  shiftPatternId: string,
  shiftPatternDetailId: string,
  startTime: string,
  endTime: string,
  isStartTimeNextDay: boolean,
  isEndTimeNextDay: boolean,
  businessId: string,
}

export type WeekShiftPatternDataStaff = {
  companyCode: string,
  createUser: string,
  updateUser: string,
  orgCode: string,
  patternIdStr: string,
  shiftPatternId: string,
  shiftPatternCode: string,
  shiftPatternName: string,
  shortName: string,
  staffWeekShiftPatternId: string,
  daysOfWeek: Array<number>,
  attendType: number,
  attendShiftPatternDetails: Array<AttendShiftPatternDetails>,
}

export class WeekShiftPatternDataStaffDomain {
  private selectedDay: number[] = [] ;

  constructor(private rawData: WeekShiftPatternDataStaff) {
    // do nothing
  }

  static generateInitial(): WeekShiftPatternDataStaffDomain {
    return new WeekShiftPatternDataStaffDomain({
      companyCode: sessionStorage.getItem('loginUser.companyCode') || '',
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      orgCode: '',
      patternIdStr: '',
      shiftPatternId: '',
      shiftPatternCode: '',
      shiftPatternName: '',
      shortName: '',
      staffWeekShiftPatternId: '',
      daysOfWeek: [],
      attendType: 0,
      attendShiftPatternDetails: [{
        createUser: '',
        updateUser: '',
        shiftPatternId: '',
        shiftPatternDetailId: '',
        startTime: '00:00:00',
        endTime: '00:00:00',
        isStartTimeNextDay: false,
        isEndTimeNextDay: false,
        businessId: '',
      }],
    });
  }

  getRawData(): WeekShiftPatternDataStaff {
    return this.rawData;
  }

  get selectedDayOfWeeks() : number[] {
    return this.selectedDay;
  }

  set selectedDayOfWeeks(a: number[]) {
    this.selectedDay = a;
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = companyCode;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get patternIdStr(): string {
    return this.rawData.patternIdStr;
  }

  set patternIdStr(patternIdStr: string) {
    this.rawData.patternIdStr = patternIdStr;
  }

  get shiftPatternId(): string {
    return this.rawData.shiftPatternId;
  }

  set shiftPatternId(shiftPatternId: string) {
    this.rawData.shiftPatternId = shiftPatternId;
  }

  get shiftPatternCode(): string {
    return this.rawData.shiftPatternCode;
  }

  set shiftPatternCode(shiftPatternCode: string) {
    this.rawData.shiftPatternCode = shiftPatternCode;
  }

  get shiftPatternName(): string {
    return this.rawData.shiftPatternName;
  }

  get shiftPatternNameWithAll(): string {
    if (this.rawData.patternIdStr && this.rawData.orgCode === 'all') {
      return `${this.rawData.shiftPatternName}(共通)`;
    } else {
      return this.rawData.shiftPatternName;
    }
  }

  set shiftPatternName(shiftPatternName: string) {
    this.rawData.shiftPatternName = shiftPatternName;
  }

  get shortName(): string {
    return this.rawData.shortName;
  }

  set shortName(shortName: string) {
    this.rawData.shortName = shortName;
  }

  get staffWeekShiftPatternId(): string {
    return this.rawData.staffWeekShiftPatternId;
  }

  set staffWeekShiftPatternId(staffWeekShiftPatternId: string) {
    this.rawData.staffWeekShiftPatternId = staffWeekShiftPatternId;
  }

  get dayOfWeek(): number {
    return this.rawData.daysOfWeek[0];
  }

  get dayOfWeekStr(): string {
    return DayOfWeekLabel[this.dayOfWeek];
  }

  set daysOfWeek(dayOfWeek: number) {
    this.rawData.daysOfWeek = [dayOfWeek];
  }

  get attendType(): number {
    return this.rawData.attendType;
  }

  set attendType(attendType: number) {
    this.rawData.attendType = attendType;
  }
  get attendShiftPatternDetails(): Array<AttendShiftPatternDetails> {
    return this.rawData.attendShiftPatternDetails;
  }

  set attendShiftPatternDetails(attendShiftPatternDetails: Array<AttendShiftPatternDetails>) {
    this.rawData.attendShiftPatternDetails = attendShiftPatternDetails;
  }


  get startTimeStr(): string {
    const shiftDetailStart : AttendShiftPatternDetails = this.attendShiftPatternDetails[0];
    if (shiftDetailStart.isStartTimeNextDay) {
      return `${shiftDetailStart.startTime.substr(0, 5)}(翌)`;
    }
    return shiftDetailStart.startTime.substr(0, 5);
  }

  get endTimeStr(): string {
    const shiftDetailEnd : AttendShiftPatternDetails = this.attendShiftPatternDetails[this.attendShiftPatternDetails.length - 1];
    if (shiftDetailEnd.isEndTimeNextDay) {
      return `${shiftDetailEnd.endTime.substr(0, 5)}(翌)`;
    }
    return shiftDetailEnd.endTime.substr(0, 5);
  }

  get breakTimeStr(): string {
    const breakShiftDetails : Array<AttendShiftPatternDetails> = this.attendShiftPatternDetails.filter((item) => item.businessId === sessionStorage.getItem('breakTimeBusinessId'));

    let breakTimeStr = '';
    breakShiftDetails.map((breakTime) => {
      let startTimeStr = breakTime.startTime.substr(0, 5);
      let endTimeStr = breakTime.endTime.substr(0, 5);

      if (breakTime.isStartTimeNextDay) {
        startTimeStr = `${startTimeStr}(翌)`;
      }
      if (breakTime.isEndTimeNextDay) {
        endTimeStr = `${endTimeStr}(翌)`;
      }

      breakTimeStr += `${startTimeStr} ～ ${endTimeStr} \n`;
      return breakTimeStr;
    });

    return breakTimeStr;
  }
}
