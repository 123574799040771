import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FlexBox from 'components/atoms/FlexBox';
import { gridCol } from 'components/styles';
import HourInput from 'components/atoms/Form/HourInput';

const EmploymentWorkSystemAndBreakSettings: React.FC<{
  formik: any
}> = ({
  formik,
}) => (
  <>
    <FormTitle
      title="勤務時間の丸め設定"
    />
    <div>
      <FormField>
        <RadioSelectForm
          label="出社/休憩終了打刻時間の秒数の丸め処理"
          name="roundMethodStartStamp"
          items={[
            {
              label: '切り捨てる',
              value: '0',
            },
            {
              label: '切り上げる',
              value: '1',
            },
          ]}
          value={String(formik.values.roundMethodStartStamp)}
          setValue={formik.handleChange}
        />
      </FormField>
      
      <FormField>
        <RadioSelectForm
          label="退社/休憩開始打刻時間の秒数の丸め処理"
          name="roundMethodEndStamp"
          items={[
            {
              label: '切り捨てる',
              value: '0',
            },
            {
              label: '切り上げる',
              value: '1',
            },
          ]}
          value={String(formik.values.roundMethodEndStamp)}
          setValue={formik.handleChange}
        />
      </FormField>

      <FormField>
        <div style={{ width: gridCol.grid06 }}>
          <VerticalLabelSelectForm
            label="出社/退社打刻の丸め単位"
            name="roundUnitStartStamp"
            value={String(formik.values.roundUnitStartStamp)}
            setValue={(val: string) => formik.setFieldValue('roundUnitStartStamp', val)}
            options={[
              {
                label: '01分',
                value: '1',
              },
              {
                label: '05分',
                value: '5',
              },
              {
                label: '10分',
                value: '10',
              },
              {
                label: '15分',
                value: '15',
              },
              {
                label: '30分',
                value: '30',
              },
            ]}
          />
        </div>
      </FormField>
      <div style={String(formik.values.useAutoRecess) === '0' || String(formik.values.useAutoRecess) === '1' ? {} : { display: 'none' }}>
        <FormField>
          <RadioSelectForm
            label="休憩の丸め処理"
            name="roundTimingRecess"
            items={[
              {
                label: '休憩打刻を丸める',
                value: '0',
              },
              {
                label: '休憩毎集計時間を丸める',
                value: '1',
              },
              {
                label: '実績毎集計時間を丸める',
                value: '2',
              },
            ]}
            value={String(formik.values.roundTimingRecess)}
            setValue={formik.handleChange}
          />
        </FormField>
        <FormField>
          <div style={{ width: gridCol.grid06, paddingLeft: '0px' }}>
            <VerticalLabelSelectForm
              label="休憩の丸め単位"
              name="roundUnitRecessStamp"
              value={String(formik.values.roundUnitRecessStamp)}
              setValue={(val: string) => formik.setFieldValue('roundUnitRecessStamp', val)}
              options={[
                {
                  label: '01分',
                  value: '1',
                },
                {
                  label: '05分',
                  value: '5',
                },
                {
                  label: '10分',
                  value: '10',
                },
                {
                  label: '15分',
                  value: '15',
                },
                {
                  label: '30分',
                  value: '30',
                },
              ]}
            />
          </div>
        </FormField>
      </div>


      <FormField>
        <RadioSelectForm
          label="出社時間をシフトの開始時間に丸める"
          name="roundShiftStartTime"
          items={[
            {
              label: '丸めない',
              value: '0',
            },
            {
              label: '丸める',
              value: '1',
            },
          ]}
          value={String(formik.values.roundShiftStartTime)}
          setValue={formik.handleChange}
          note={(
            <>
              ※「丸める」を選択するとシフトの開始時間に出社時間を丸めます。
              <br />
              シフトの開始時間より出社時刻が早い場合のみ適用されます。
            </>
              )}
        />
      </FormField>


      <div style={{ display: 'inline-flex' }}>
        <FormField>
          <RadioSelectForm
            label="退社時間をシフトの終了時間に丸める"
            name="roundShiftEndTime"
            items={[
              {
                label: '丸めない',
                value: '0',
              },
              {
                label: '丸める',
                value: '1',
              },
              {
                label: 'シフト終了時間から指定時間内は丸める',
                value: '2',
              },
            ]}
            value={String(formik.values.roundShiftEndTime)}
            setValue={formik.handleChange}
            note={(
              <>
                ※「丸める」を選択するとシフトの終了時間に退社時間を丸めます。
                <br />
                シフトの終了時間より退社時刻が遅い場合のみ適用されます。
              </>
                )}
          />
        </FormField>
        <div style={String(formik.values.roundShiftEndTime) === '2' ? {} : { display: 'none' }} />
        <FlexBox>
          <div style={{ width: '120px' }}>
            <HourInput
              name="roundEndElapsedTime"
              value={formik.values.roundEndElapsedTime}
              label="分"
              min={0}
              max={9999}
              onChange={formik.handleChange}
            />
          </div>
          <div style={{ marginLeft: '10px' }}>
            分未満
          </div>
        </FlexBox>
      </div>

    </div>
  </>
);

export default EmploymentWorkSystemAndBreakSettings;
