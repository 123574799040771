import ApiClient from './ApiClient';

export const getAttendIncentiveSalaryHeader = async (): Promise<any> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await ApiClient.get(`/v1/attend/incentiveSalaryMst/${companyCode}`, {});
    return response.data;
};

export const getAttendIncentiveSalaryDataList = async (orgCode: string, targetMonth: any, functionType: number, categoryAry: any): Promise<any> => {
    const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const params = {
        orgCode,
        targetMonth,
        functionType,
        loginStaffCode,
    };
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    let appendUrl = '';
    if (Array.isArray(categoryAry)) {
        categoryAry.forEach((cateArr: any, index: number) => {
        appendUrl += `&categoryAry=${cateArr.join(',')}`;
        });
    }
    const response = await ApiClient.get(`/v1/attend/incentiveSalary/${companyCode}?${appendUrl}`, params);
    return response.data;
};

export const createAttendIncentive = async (targetMonth: any, incentiveSalaryDataList: any) : Promise<Array<any>> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const params = {
      loginStaffCode,
      targetMonth
    }
    const response = await ApiClient.postMutipartData(`/v1/attend/incentiveSalary/registList/${companyCode}`, params, incentiveSalaryDataList);
    return response.data;
};

export default { };
