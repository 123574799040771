
import React from 'react';
import SalesPageForm from 'components/organismos/master/general/salesPage';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const SalesPage = () => (
  <SidebarTemplate pageTitle="【日別】本部確認帳票">
    <SalesPageForm />
  </SidebarTemplate>
);

export default SalesPage;
