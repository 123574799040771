import { useState, useEffect } from 'react';
import moment from 'moment';
import useToastNotification from 'hooks/useToastNotification';
import { downloadAttendanceErrorReportCsv } from 'api/attendError';
import { IAttendAchievementError } from './interface';
import { getAttendAchievementError } from './api';

const useAchievementErrorState = (orgCodeNoti: any, targetDateFromNoti: any, targetDateToNoti: any) => {
  const { errorNotification } = useToastNotification();
  const loginUserOrgCode = orgCodeNoti !== null && orgCodeNoti !== 'undefined' ? orgCodeNoti : sessionStorage.getItem('attendError.orgCode') || sessionStorage.getItem('loginUser.orgCode') || '';
  const [categoryAry, setCategoryAr] = useState(JSON.parse(String(sessionStorage.getItem('attendError.selectCategory'))));

  const isFilterStr = sessionStorage.getItem('attendError.isFilter') || 'true';
  const now = new Date();
  // get first date of month
  const startMonth = (new Date(now.getFullYear(), now.getMonth(), 1));
  // get last date of month
  const endMonth = (new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59));

  // get session when click icon screen AttenErrorOrg
  const dateFromStr = targetDateFromNoti !== null && targetDateFromNoti !== 'undefined' ? targetDateFromNoti : sessionStorage.getItem('attendErrorIcon.targetDateFrom');
  const dateToStr = targetDateToNoti !== null && targetDateToNoti !== 'undefined' ? targetDateToNoti : sessionStorage.getItem('attendErrorIcon.targetDateTo');

  let dateFrom = dateFromStr ? moment(dateFromStr).toDate() : startMonth;
  let dateTo = dateToStr ? moment(dateToStr).toDate() : endMonth;

  // get session save value when back
  let targetDateFromStr = sessionStorage.getItem('attendError.targetDateFrom');
  let targetDateToStr = sessionStorage.getItem('attendError.targetDateTo');

  if (targetDateFromStr) {
    dateFrom = moment(targetDateFromStr).toDate();
  }
  if (targetDateToStr) {
    dateTo = moment(targetDateToStr).toDate();
  }

  const [targetDateFrom, setTargetDateFrom] = useState<Date>(dateFrom);
  const [targetDateTo, setTargetDateTo] = useState<Date>(dateTo);
  const [orgCode, setOrgCode] = useState<string>(loginUserOrgCode);
  const [isFilter, setIsFilter] = useState<string>('1');
  const [errorList, setErrorList] = useState<IAttendAchievementError[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toSearchContinuousWorkAlert, setSearchContinuousWorkAlert] = useState<string>('1');
  const [toSearchOvertimeAlert, setSearchOvertimeAlert] = useState<string>('1');
  const [toSearchHolidayworkAlert, setSearchHolidayworkAlert] = useState<string>('1');
  const [toSearchRecessAlert, setSearchRecessAlert] = useState<string>('1');
  const [toSearchUnder18MidnightWorkAlert, setSearchUnder18MidnightWorkAlert] = useState<string>('1');
  const [toSearchForeignerAlert, setSearchForeignerAlert] = useState<string>('1');

  const fetchData = async () => {
    setIsLoading(true);

    if (!targetDateFromStr || !targetDateToStr) {
      targetDateFromStr = moment(targetDateFrom).format('YYYY-MM-DD');
      targetDateToStr = moment(targetDateTo).format('YYYY-MM-DD');
      sessionStorage.setItem('attendErrorIcon.targetDateFrom', targetDateFromStr);
      sessionStorage.setItem('attendErrorIcon.targetDateTo', targetDateToStr);
    }
    setErrorList([]);

    const achievementErrors = await getAttendAchievementError(
      orgCode,
      categoryAry,
      targetDateFromStr,
      targetDateToStr,
      +isFilter,
      toSearchContinuousWorkAlert,
      toSearchOvertimeAlert,
      toSearchHolidayworkAlert,
      toSearchRecessAlert,
      toSearchUnder18MidnightWorkAlert,
      toSearchForeignerAlert,
    );
    setErrorList(achievementErrors);
    setIsLoading(false);
  };

  const dowloadAttendanceErrorCsv = async (attendanceErrorReport: any) => {
    setIsLoading(true);
    try {
      let appendUrl = '';
      if (Array.isArray(categoryAry)) {
        categoryAry.forEach((cateArr: any) => {
          appendUrl += `&categoryAry=${cateArr.join(',')}`;
        });
      }
      const isOnlyError = isFilter;
      const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
      const functionType = 2;
      const params = {
        orgCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
        isOnlyError,
        toSearchContinuousWorkAlert,
        toSearchOvertimeAlert,
        toSearchHolidayworkAlert,
        toSearchRecessAlert,
        toSearchUnder18MidnightWorkAlert,
        toSearchForeignerAlert,
        loginStaffCode,
        functionType,
      };
      const fileName = `勤怠エラーリスト_${moment().format('YYYYMMDDHHmmss')}.csv`;
      await downloadAttendanceErrorReportCsv(params, appendUrl, fileName);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        errorNotification('出力対象となる勤務エラー情報が存在しません。');
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (orgCodeNoti !== null && orgCodeNoti !== 'undefined') {
      setOrgCode(orgCodeNoti);
    }
    if (targetDateFromNoti !== null && targetDateFromNoti !== 'undefined') {
      setTargetDateFrom(moment(targetDateFromNoti).toDate());
    }
    if (targetDateToNoti !== null && targetDateToNoti !== 'undefined') {
      setTargetDateTo(moment(targetDateToNoti).toDate());
    }
    setIsFilter(sessionStorage.getItem('attendError.isFilter') !== null ? sessionStorage.getItem('attendError.isFilter') as string : '1');
  }, [orgCodeNoti, targetDateFromNoti, targetDateToNoti]);

  useEffect(() => {
    if (moment(targetDateTo).diff(targetDateFrom, 'year') >= 1) {
      errorNotification('対象期間の範囲は１年以内で入力してください');
      return;
    }

    if (orgCode) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgCode, targetDateFrom, targetDateTo, isFilter, categoryAry, toSearchContinuousWorkAlert, toSearchOvertimeAlert, toSearchHolidayworkAlert, toSearchRecessAlert, toSearchUnder18MidnightWorkAlert, toSearchForeignerAlert]);

  const data = [
    orgCode,
    categoryAry,
    targetDateFromStr,
    targetDateToStr,
    isFilter,
    toSearchContinuousWorkAlert,
    toSearchOvertimeAlert,
    toSearchHolidayworkAlert,
    toSearchRecessAlert,
    toSearchUnder18MidnightWorkAlert,
    toSearchForeignerAlert,
  ];

  const handleExportCSV = () => dowloadAttendanceErrorCsv(data);

  return {
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    orgCode,
    setOrgCode,
    isFilter,
    setIsFilter,
    errorList,
    setErrorList,
    isLoading,
    setIsLoading,
    fetchData,
    categoryAry,
    setCategoryAr,
    toSearchContinuousWorkAlert,
    setSearchContinuousWorkAlert,
    toSearchOvertimeAlert,
    setSearchOvertimeAlert,
    toSearchHolidayworkAlert,
    setSearchHolidayworkAlert,
    toSearchRecessAlert,
    setSearchRecessAlert,
    toSearchUnder18MidnightWorkAlert,
    setSearchUnder18MidnightWorkAlert,
    toSearchForeignerAlert,
    setSearchForeignerAlert,
    handleExportCSV,
    dowloadAttendanceErrorCsv,

  };
};

export default useAchievementErrorState;
