/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { productColor } from 'components/styles';
import FlexBox from 'components/atoms/FlexBox';
import BodyText from 'components/atoms/BodyText';
import Toggle from 'components/atoms/Toggle';

const styles = {
  label: css({
    alignItems: 'center',
    display: 'inline-flex',
    cursor: 'pointer',
    color: productColor.primary,
    marginLeft: '40px',
  }),
  labelMobile: css({
    alignItems: 'center',
    display: 'inline-flex',
    cursor: 'pointer',
    color: productColor.primary,
    marginLeft: '10px',
  }),
  offLabelText: css({
    marginRight: '4px',
  }),
  onLabelText: css({
    marginLeft: '4px',
  }),
};

const ToggleSwitch: React.FC<{
  name: string;
  onLabel: string;
  offLabel: string;
  value: string;
  checked: boolean;
  isMobile?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  name, onLabel, offLabel, value, checked, isMobile = false, onChange,
}) => (
  <FlexBox>
    <label
      css={isMobile ? styles.labelMobile : styles.label}
      htmlFor={name}
    >
      <BodyText customStyle={styles.offLabelText}>
        {offLabel}
      </BodyText>
      <Toggle
        on={checked}
        name={name}
        value={value}
        onChange={onChange}
      />
      <BodyText customStyle={styles.onLabelText}>
        {onLabel}
      </BodyText>
    </label>
  </FlexBox>
);

export default ToggleSwitch;
