import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesSuppliersMSTListManagement from 'components/pages/master/general/salesSuppliersMST/salesSuppliersMSTList/salesSuppliersMSTListManagement';

const CommentListPage = () => (
  <SidebarTemplate pageTitle="取引先マスタ">
    <SalesSuppliersMSTListManagement />
  </SidebarTemplate>
);
export default CommentListPage;
