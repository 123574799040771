
import { useEmploymentOptions } from 'hooks/useEmploymentOptions';
import { useSpecialHolidayOptions } from 'hooks/useSpecialHolidayOptions';


export const useHolidayManagementDomainForm = () => {
  /**
   * arrGrantResult
   *
   */
  const arrGrantResult = [
    {
      label: '全て',
      value: '0',
    },
    {
      label: '成功',
      value: '1',
    },
    {
      label: '失敗',
      value: '2',
    },
  ];

  /**
   * orgTreesOptions
   *
   */


  /**
   * employmentOptions
   *
   */
  const employmentOptions = useEmploymentOptions();

  /**
   * specialHolidayOptions
   *
   */
  const specialHolidayOptions = useSpecialHolidayOptions();


  return {
    arrGrantResult,
    employmentOptions,
    specialHolidayOptions,
  };
};

export const useDownloadHolidayManagement = () =>
// const downloadOrganization = useCallback(() => {
//   api.downloadPaidHolidayDigestionCsv(
//     noManager,
//     Number(orgStatus),
//     new Date(),
//     noManager ? unsetTargetDate : undefined,
//   );
// }, [noManager, orgStatus, unsetTargetDate]);

  ({
    // downloadOrganization,
  });
export default {
  useHolidayManagementDomainForm,
};
