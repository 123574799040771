/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { layer } from 'components/styles';
import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';
import productColor, { textFontSize, grayScale } from 'components/styles';

const styles = {
  box: (height: number) => css({
    position: 'absolute',
    top: `${height}px`,
    left: '100%',
    whiteSpace: 'nowrap',
    zIndex: 100,
    cursor: 'pointer',
    boxShadow: layer.layer06,
  }),
  subMenu: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 14px',
    backgroundColor: '#ffff',
    fontSize: textFontSize.re,
    ':hover': {
      backgroundColor: productColor.primaryM95,
    },
  })
};

//Forked DropdownBox - add reponsive to position
const SubContextMenuShift: React.FC<{
  text: string;
}> = ({ children, text }) => {
  const [subMenu, setSubMenu] = useState<HTMLElement | null>(null);
  const [subMenuHeight, setSubMenuHeight] = useState<number>(0);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const onMouseEnter = () => {
    setShowMenu(true)
  }
  const onMouseLeave = () => {
    setShowMenu(false)
  }

  useEffect(() => {
    if (!subMenu) {
      return;
    }

    let parentElement = subMenu.parentElement;

    if (!parentElement) {
      return;
    }

    let parentRect = parentElement.getBoundingClientRect();
    let extraSpaceForDesktop = window.screen.width > 1200 ? 50 : 10;
    let remainHeight = window.screen.availHeight - parentRect.top - extraSpaceForDesktop;

    if (remainHeight < subMenu.clientHeight) {
      setSubMenuHeight(-subMenu.clientHeight + parentRect.height);
    } else {
      setSubMenuHeight(0);
    }
  }, [subMenu])


  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div css={styles.subMenu} >
        <BodyText color={grayScale.gray100}>{text}</BodyText>
        <Icon type="chevronRight" color={grayScale.gray60}></Icon>
      </div>
      {showMenu && (
        <div ref={(ref) => { setSubMenu(ref) }} css={styles.box(subMenuHeight)}>
          {children}
        </div>
      )}
    </div>
  );
};

export default SubContextMenuShift;
