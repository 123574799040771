/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import BodyText from 'components/atoms/BodyText';

const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

const DashboardInformationDetail: React.FC<{
    detailReport?: any,
    period?:boolean
}> = ({ detailReport, period }) => {
    return (
    <React.Fragment>
       
                <FlexBox customStyle={css({ background: '#F5F6F7' })}>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px' })}>
                        <FormLabel label={period ? '売上' : '当月の売上'} />
                    </FlexBoxItem>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px', textAlign: 'right' })}>
                        <BodyText
                            size="xl2"
                            customStyle={css({
                                marginTop: '8px', textOverflow: 'hidden', fontSize: '120%'
                            })}
                        >
                            { detailReport && detailReport.netSales && formatter.format(detailReport.netSales)} 円
                        </BodyText>
                    </FlexBoxItem>
                </FlexBox>

                <FlexBox customStyle={css({ background: '#ffffff' })}>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px' })}>
                        <FormLabel label={period ? '客数' : '当月の客数'} />
                    </FlexBoxItem>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px', textAlign: 'right' })}>
                        <BodyText
                            size="xl2"
                            customStyle={css({
                                marginTop: '8px', textOverflow: 'hidden', fontSize: '120%'
                            })}
                        >
                            {detailReport && detailReport.customers && formatter.format(detailReport.customers)} 組 { detailReport && detailReport.guestCnt && formatter.format(detailReport.guestCnt) } 人
                        </BodyText>
                    </FlexBoxItem>
                </FlexBox>

                <FlexBox customStyle={css({ background: '#F5F6F7' })}>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px' })}>
                        <FormLabel label='客単価' />
                    </FlexBoxItem>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px', textAlign: 'right' })}>
                        <BodyText
                            size="xl2"
                            customStyle={css({
                                marginTop: '8px', textOverflow: 'hidden', fontSize: '120%'
                            })}
                        >
                            { detailReport && detailReport.guestCntRate && formatter.format(detailReport.guestCntRate)} 円
                        </BodyText>
                    </FlexBoxItem>
                </FlexBox>

                <FlexBox customStyle={css({ background: '#ffffff' })}>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px' })}>
                        <FormLabel label='組単価' />
                    </FlexBoxItem>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px', textAlign: 'right' })}>
                        <BodyText
                            size="xl2"
                            customStyle={css({
                                marginTop: '8px', textOverflow: 'hidden', fontSize: '120%'
                            })}
                        >
                            { detailReport && detailReport.customersRate && formatter.format(detailReport.customersRate)} 円 
                        </BodyText>
                    </FlexBoxItem>
                </FlexBox>

                <FlexBox customStyle={css({ background: '#F5F6F7' })}>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px' })}>
                        <FormLabel label='売上目標達成率' />
                    </FlexBoxItem>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px', textAlign: 'right' })}>
                        <BodyText
                            size="xl2"
                            customStyle={css({
                                marginTop: '8px', textOverflow: 'hidden', fontSize: '120%'
                            })}
                        >
                            { detailReport && detailReport.netSalesRate && formatter.format(detailReport.netSalesRate)} %
                        </BodyText>
                    </FlexBoxItem>
                </FlexBox>

                <FlexBox customStyle={css({ background: '#ffffff' })}>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px' })}>
                        <FormLabel label='売上前年対比' />
                    </FlexBoxItem>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px', textAlign: 'right' })}>
                        <BodyText
                            size="xl2"
                            customStyle={css({
                                marginTop: '8px', textOverflow: 'hidden', fontSize: '120%'
                            })}
                        >
                            { detailReport && detailReport.prevNetSalesRate && formatter.format(detailReport.prevNetSalesRate)} %
                        </BodyText>
                    </FlexBoxItem>
                </FlexBox>

                <FlexBox customStyle={css({ background: '#F5F6F7' })}>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px' })}>
                        <FormLabel label='客数前年対比' />
                    </FlexBoxItem>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px', textAlign: 'right' })}>
                        <BodyText
                            size="xl2"
                            customStyle={css({
                                marginTop: '8px', textOverflow: 'hidden', fontSize: '120%'
                            })}
                        >
                            { detailReport && detailReport.prevGuestCntRate && formatter.format(detailReport.prevGuestCntRate)} %
                        </BodyText>
                    </FlexBoxItem>
                </FlexBox>

                <FlexBox customStyle={css({ background: '#ffffff' })}>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px' })}>
                        <FormLabel label='FLA率' />
                    </FlexBoxItem>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px', textAlign: 'right' })}>
                        <BodyText
                            size="xl2"
                            customStyle={css({
                                marginTop: '8px', textOverflow: 'hidden', fontSize: '120%'
                            })}
                        >
                            { detailReport && detailReport.flaRate && formatter.format(detailReport.flaRate)} %
                        </BodyText>
                    </FlexBoxItem>
                </FlexBox>

                <FlexBox customStyle={css({ background: '#F5F6F7' })}>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px' })}>
                        <FormLabel label='F率' />
                    </FlexBoxItem>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px', textAlign: 'right' })}>
                        <BodyText
                            size="xl2"
                            customStyle={css({
                                marginTop: '8px', textOverflow: 'hidden', fontSize: '120%'
                            })}
                        >
                            { detailReport && detailReport.frate && formatter.format(detailReport.frate)} %
                        </BodyText>
                    </FlexBoxItem>
                </FlexBox>

                <FlexBox customStyle={css({ background: '#ffffff' })}>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px' })}>
                        <FormLabel label='L率' />
                    </FlexBoxItem>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px', textAlign: 'right' })}>
                        <BodyText
                            size="xl2"
                            customStyle={css({
                                marginTop: '8px', textOverflow: 'hidden', fontSize: '120%'
                            })}
                        >
                            { detailReport && detailReport.lrate && formatter.format(detailReport.lrate)} %
                        </BodyText>
                    </FlexBoxItem>
                </FlexBox>

                <FlexBox customStyle={css({ background: '#F5F6F7' })}>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px' })}>
                        <FormLabel label='A率' />
                    </FlexBoxItem>
                    <FlexBoxItem basis='50%' customStyle={css({ padding: '10px 24px', textAlign: 'right' })}>
                        <BodyText
                            size="xl2"
                            customStyle={css({
                                marginTop: '8px', textOverflow: 'hidden', fontSize: '120%'
                            })}
                        >
                            { detailReport && detailReport.arate && formatter.format(detailReport.arate)} %
                        </BodyText>
                    </FlexBoxItem>
                </FlexBox>
      
    </React.Fragment>
)};

export default DashboardInformationDetail;
