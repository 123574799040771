/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';
import ReactTooltip from 'react-tooltip';

export interface TooltipProps {
  id: string;
  text: string;
  customCss?: SerializedStyles;
  place?: ReactTooltip.Place;
}

const styles = {
  wrapper: css({
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
    display: 'block',
  }),
};

/**
 * ツールチップのついたアイコン
 */
const TextTooltip: React.FC<TooltipProps> = ({
  id,
  text,
  customCss,
  place = 'top',
}) => (
  <div className="text-tooltip" css={customCss}>
    <p
      data-tip={false}
      data-for={id}
      css={css({
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginTop: 0,
        marginBottom: 0,
      })}
    >
      {text}
    </p>
    <ReactTooltip id={id} type="dark" place={place} multiline={true} css={styles.wrapper}>
      <span style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: text }} />
    </ReactTooltip>
  </div>
);

export default TextTooltip;
