import { createStore, combineReducers, applyMiddleware, compose } from 'redux';

import sample from './sample';
import { SampleState } from './sample/reducer';
import ui, { UiState } from './ui';
import auth, { AuthState } from './auth';

export interface Store {
    sample: SampleState;
    ui: UiState;
    auth: AuthState;
}

// NOTE: Redux DevTools Extension を使用したい場合はコメントを外す（本番環境では使えないようにコメントアウトしている）
// interface ExtendedWindow extends Window {
//     __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
// }

// declare var window: ExtendedWindow;

// const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export default createStore(combineReducers<Store>({ sample, ui, auth }), composeEnhancers(applyMiddleware()));

export default createStore(combineReducers<Store>({ sample, ui, auth }));
