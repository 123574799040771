
import React, { useState } from 'react';
import IconTooltip from 'components/molecules/IconTooltip';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import AccordionTableWrapper from 'components/molecules/AccordionTableWrapper/index';
import { isMacOs } from 'react-device-detect';
import { eventScroll } from './hooks';
import TextTooltip from 'components/organismos/master/general/salesPage/salesAdvertisingMonthlyReport/component/TextTooltip';

const SalesReportTable: React.FC<{
  orgCode: any,
  orgTreesOptions: any,
  generalData: any,
  dataKeyName: any,
  dayPlanData: any,
  monthlyPlanData: any,
  countAdvertising: any,
  advertisingData: any,
  countExpenses: any,
  expensesData: any,
  setting: any,
}> = ({
  orgCode,
  orgTreesOptions,
  // generalData is used for rendering table header 現状
  generalData,
  // ---------------------------------------------------
  // dataKeyName stands for data row of right table from first row to last row
  dataKeyName,
  // ---------------------------------------------------
  // dayPlanData stands for data from day 1 to day 30,31 (not the first three columns)
  dayPlanData,
  // --------------------------------------------------
  // monthlyPlanData stands for the first 3 columns
  monthlyPlanData,
  // --------------------------------------------------
  // count advertising array length
  countAdvertising,
  // --------------------------------------------------
  // advertisingData stands for data dynamic advertising data inside 売上高	& A（広告費）
  advertisingData,
  // expensesData stands for data dynamic advertising data inside O（その他経費)
  countExpenses,
  expensesData,
  // --------------------------------------------------
  setting,
}) => {
  const history = useHistory();

  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num)) {
      return num;
    }
    const tmp = String(num).split('.');
    if (tmp[1]) {
      let fixed = 3;
      if (tmp[1].length < 3) {
        fixed = tmp[1].length;
      }
      return formatter.format(Number(parseFloat(num).toFixed(fixed)));
    }
    return formatter.format(num);
  };

  const handleRedirectDailyReport = (targetDay: any) => {
    if (!orgCode || orgCode === 'all') return;
    sessionStorage.setItem('SalesDailyReport.targetDay', targetDay);
    sessionStorage.setItem('SalesDailyReport.targetOrgCode', orgCode);
    let orgCodeName = '';
    orgTreesOptions.filter((item: any) => {
      if (item.value === orgCode) {
        orgCodeName = item.label;
        return true;
      }
      return false;
    });
    sessionStorage.setItem('SalesDailyReport.targetOrgName', orgCodeName);
    history.push('/salesDailyReport');
  };
    // console.log(generalData, 'check first generalData')
    // console.log(dataKeyName, 'check first dataKeyName')
    // console.log(dayPlanData, 'check first dayPlanData')
    // console.log('check first !-----------------------------------------------------------------------------------!')
    // console.log(monthlyPlanData, 'check second monthlyPlanData')
    // console.log(countAdvertising, 'check second countAdvertising')
    // console.log(advertisingData, 'check second advertisingData')
    // console.log('check second!-----------------------------------------------------------------------------------!')
  const slice_data_key_name = (arr: any) => {
    const result = [];
    result.push(arr.slice(arr.indexOf('rowBlank_01'), arr.indexOf('rowBlank_02')));
    result.push(arr.slice(arr.indexOf('rowBlank_02'), arr.indexOf('standardCostRate')));
    result.push(arr.slice(arr.indexOf('standardCostRate'), arr.indexOf('grossProfitTotal')));// new
    result.push(arr.slice(arr.indexOf('grossProfitTotal'), arr.indexOf('rowBlank_03')));
    result.push(arr.slice(arr.indexOf('rowBlank_03'), arr.indexOf('laborCostsRate'))); // new
    result.push(arr.slice(arr.indexOf('laborCostsRate'), arr.indexOf('rowBlank_04')));
    result.push(arr.slice(arr.indexOf('rowBlank_04'), arr.indexOf('advertisingExpenseRate')));
    result.push(arr.slice(arr.indexOf('advertisingExpenseRate'), arr.indexOf('FLACost')));
    result.push(arr.slice(arr.indexOf('FLACost'), arr.indexOf('ProfitLossCost')));
    result.push(arr.slice(arr.indexOf('ProfitLossCost'), arr.indexOf('rowBlank_05')));
    result.push(arr.slice(arr.indexOf('rowBlank_05'), arr.indexOf('rentRate')));
    result.push(arr.slice(arr.indexOf('rentRate'), arr.indexOf('rowBlank_06')));
    result.push(arr.slice(arr.indexOf('rowBlank_06'), arr.indexOf('otherExpensesRate')));
    result.push(arr.slice(arr.indexOf('otherExpensesRate'), arr.indexOf('FLAROCost')));
    result.push(arr.slice(arr.indexOf('FLAROCost'), arr.indexOf('operatingIncomeTotal')));
    result.push(arr.slice(arr.indexOf('operatingIncomeTotal'), arr.indexOf('rowBlank_07')));
    result.push(arr.slice(arr.indexOf('rowBlank_07'), arr.indexOf('rowBlank_08')));
    result.push(arr.slice(arr.indexOf('rowBlank_08'), arr.indexOf('rowBlank_09')));
    result.push(arr.slice(arr.indexOf('rowBlank_09'), arr.indexOf('FLCost')));
    result.push(arr.slice(arr.indexOf('FLCost'), arr.indexOf('FLARRate') + 1));
    return result;
  };
  const slicedDataKeyName = slice_data_key_name(dataKeyName);
  // Accordion for 売上高
  const [isAdvertisingSaleDataExpanded, setIsAdvertisingSaleDataExpand] = useState(false);
  // Accordion for F（食材費）
  const [isIngredientCostExpanded, setIsIngredientCostExpand] = useState(false);
  // Accordion for L（人件費）
  const [isLaborCostExpanded, setIsLaborCostExpand] = useState(false);
  // Accordion for A（広告費）
  const [isAdvertisingCostExpanded, setIsAdvertisingCostExpand] = useState(false);
  // Accordion for R（家賃）
  const [isRentExpanded, setIsRentExpand] = useState(false);
  // Accordion for O（その他経費）
  const [isOtherExpensesExpanded, setIsOtherExpensesExpand] = useState(false);
  const renderTableRowGrayBackground = (keyName: string) => (keyName === 'pricePerCustomer'
        || keyName === 'numberOfGroups'
        || keyName === 'laborCostsTotal'
        || keyName === 'numberOfCustomersUpToBreakEvenSalesData'
        || keyName === 'TurnoverRate'
        || keyName === 'porofitMarginRate'
        || keyName === 'contributionMarginRate'
        || keyName === 'breakEvenPointNumberOfGuest'
        || keyName === 'laborCostRateEmployee'
        || keyName === 'totalWorkingHoursTotal'
        || keyName === 'totalWorkingHoursPartTime'
        || keyName === 'numberOfEmployeesEmployee'
        || keyName === 'whenPeopleAreHigh'
        || keyName === 'numberOfPickUps'
        || keyName === 'laborShare'
        || keyName === 'purchaseAmountDrink'
        || keyName === 'purchaseAmountTotal'
        || keyName === 'CostDrink'
        || keyName === 'CostRate'
    ? '#f2f2f2' : '');

  const renderFooterColor = (keyName: string) => {
    let result = '';
    switch (keyName) {
      case 'amountOfSalesTotal':
        result = '#fce5cd';
        break;
      case 'standardCostTotal':
      case 'laborCostsTotal':
      case 'advertisingExpenseTotal':
      case 'FLACost':
      case 'rentTotal':
      case 'otherExpensesTotal':
        result = '#cfe2f3';
        break;
      case 'operatingIncomeTotal':
      case 'ProfitLossCost':
        result = '#fff2cc';
        break;
      case 'FLAROCost':
        result = '#c9daf8';
        break;
      default:
        result = '';
        break;
    }
    return result;
  };
  const renderBorderOnMac = (keyName: string) => {
    let result = '';
    switch (keyName) {
      case 'rowBlank_08':
      case 'CostFood':
      case 'CostDrink':
      case 'CostTotal':
      case 'CostRate':
      case 'rowBlank_09':
      case 'pricePerCustomer':
      case 'numberOfCustomers':
      case 'numberOfGroups':
      case 'averageNumberOfGuestPerGroup':
      case 'TurnoverRate':
      case 'porofitMarginAmount':
      case 'porofitMarginRate':
      case 'contributionMarginAmount':
      case 'contributionMarginRate':
      case 'breakEvenPointSales':
      case 'breakEvenPointNumberOfGuest':
      case 'laborCostRateTotal':
      case 'laborCostRateEmployee':
      case 'laborCostRatPartTime':
      case 'totalWorkingHoursTotal':
      case 'totalWorkingHoursEmployee':
      case 'totalWorkingHoursPartTime':
      case 'numberOfEmployeesTotal':
      case 'numberOfEmployeesEmployee':
      case 'numberOfEmployeesPartTime':
      case 'whenPeopleAreHigh':
      case 'humanTimeProductivity':
      case 'numberOfPickUps':
      case 'laborProductivity':
      case 'laborShare':
        result = '1px solid black';
        break;
      default:
        result = '';
        break;
    }
    return result;
  };
  return (
    <div id="formSalesReport" className='salesMonthlyReport'>
      {/* ヘッダー部 */}
      <div className="row tr_white_background" id="layoutSale_01">
        <div className="col-md-12">
          <form className="form-horizontal">
            <div className="row">
              {/* ------------------------------------- 左上 ------------------------------------- */}
              <div className="col-xs-3 col-md-3 no-padding layout_sale_left">
                <div className="box-body no-padding">
                  <div className="table-responsive no-padding tr_white_background flex_center">
                    <table className="table table-bordered table-striped table-condensed text-nowrap table1">
                      <tbody>
                        <tr>
                          <td className="text-center" colSpan={6}>ステータス</td>
                        </tr>
                        <tr>
                          <td className="text-center height_row_plus" colSpan={5}>項目</td>
                          <td className="text-center height_row_plus" colSpan={1} style={{ width: '20%' }}>固変</td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-bordered table-striped table-condensed text-nowrap table2" style={{ width: '239px' }}>
                      <tbody>
                        <tr>
                          <td className="text-center" colSpan={3}>現状</td>
                        </tr>
                        <tr>
                          <td style={{ background: '#FFF' }} className="text-center height_row_plus" rowSpan={2}>実績</td>
                          <td style={{ background: '#FFF' }} className="text-center height_row_plus" rowSpan={2}>予算</td>
                          <td style={{ background: '#FFF', width: '57px' }} className="text-center height_row_plus" rowSpan={2}>進捗率</td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* ------------------------------------- 右上 ------------------------------------- */}
              <div className="col-xs-9 col-md-9 no-padding overflow-hidden layout_sale_right" id="layout_sale_right_01" style={{width: 'calc(100% - 491px)'}} onScroll={eventScroll}>
                <div className="layout_sale_right_content">
                  <div className="col-xs-12 col-md-12 no-padding">
                    <div className="box-body no-padding">
                      <div className="table-responsive no-padding layout-sale-right">
                        <table className="table table-bordered table-striped table-condensed text-nowrap">
                          <tbody>
                            <tr>
                              {generalData && generalData.days
                                  && generalData.days.map((objDay: any, key: number) => (
                                    <td className="text-center" key={key}>
                                      <div style={{ width: '83px'}}>{objDay.budget.status}</div>
                                    </td>
                                  ))}
                            </tr>
                            <tr>
                              {generalData.days
                                  && generalData.days.map((objDay: any, key: number) => (
                                    <td
                                      key={key}
                                      onClick={() => handleRedirectDailyReport(
                                        moment(new Date(objDay.day), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                      )}
                                      onKeyUp={() => { }}
                                      className={`text-center ${objDay.budget.target_weekday === 0 || objDay.budget.target_weekday === 7 ? 'sunday' : objDay.budget.target_weekday === 6 ? 'saturday' : ''}`}
                                      style={(orgCode && orgCode !== 'all') ? { textDecoration: 'underline', cursor: 'pointer', backgroundColor: '#FFF' } : { backgroundColor: '#FFF' }}
                                    >
                                      <div style={{ width: '83px'}}>{moment(new Date(objDay.day), 'YYYY-MM-DD').format('D')}</div>
                                    </td>
                                  ))}

                            </tr>
                            <tr>
                              {generalData.days
                                  && generalData.days.map((objDay: any) => (
                                    <td className={`text-center ${objDay.budget.target_weekday === 0 || objDay.budget.target_weekday === 7 ? 'sunday' : objDay.budget.target_weekday === 6 ? 'saturday' : ''}`}>
                                      { objDay.budget.weekday}
                                    </td>
                                  ))}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>

            <div className="row" id="layoutSale_02_buttom">
              {/* ------------------------------------- 左下 ------------------------------------- */}
              <div className="col-xs-3 col-md-3 no-padding layout_sale_left ">
                <div className="box-body no-padding">
                  <div className="table-responsive no-padding flex_center">
                    <div className="table1">
                      <table className="table1 table table-bordered table-striped table-condensed text-nowrap">
                        <tbody>
                          {/* Start 売上高 */}
                          <AccordionTableWrapper
                            isExpanded={isAdvertisingSaleDataExpanded}
                            setStateAccordion={setIsAdvertisingSaleDataExpand}
                          >
                            <tr>
                              <td className="text-left p-left lightOrange" colSpan={5}>売上高</td>
                              <td className="text-center twenty_percent_width" colSpan={1} />
                            </tr>
                            {(countAdvertising && advertisingData)
                              ? advertisingData.map((advertising: any) => (
                                <tr>
                                  <td
                                    style={{
                                      minWidth: '25px', width: '1.5%', border: '0px solid black', borderLeft: '1px solid black', backgroundColor: '#fce5cd',
                                    }}
                                    colSpan={1}
                                  />
                                  <td className="text-right p-right" colSpan={4}>
                                    <TextTooltip id={`売上高_${advertising.advertising_media_mst_code}`} text={advertising.advertising_media_mst_name} width='calc(100% - 1%)'/>
                                  </td>
                                  <td className="text-center" colSpan={1} style={{ width: '20%' }} />
                                </tr>
                              ))
                              : ''}
                            <tr>
                              <td className="empty_column lightOrange" colSpan={1} />
                              <td className="text-right p-right" colSpan={4}>
                                フリー
                              </td>
                              <td className="text-center" colSpan={1} style={{ width: '20%' }} />
                            </tr>

                            <tr>
                              <td className="text-center lightOrange" colSpan={5}>【売上高】計</td>
                              <td className="text-center lightOrange" colSpan={1} />
                            </tr>
                          </AccordionTableWrapper>
                          {/* End 売上高 */}
                          {/*  */}
                          {/* Start F（食材費） */}
                          <AccordionTableWrapper
                            isExpanded={isIngredientCostExpanded}
                            setStateAccordion={setIsIngredientCostExpand}
                          >
                            <tr>
                              <td className="text-left active lightBlue p-left" colSpan={5}>F（食材費）</td>
                              <td colSpan={1} className="column_change text-center" style={{ backgroundColor: '#FFF' }} />
                            </tr>
                            <tr>
                              <td colSpan={1} className="empty_column lightBlue" />
                              <td className="text-right active p-right" colSpan={4}>食材費</td>
                              <td colSpan={1} className="column_change text-center">変</td>
                            </tr>
                            <tr>
                              <td colSpan={1} className="empty_column lightBlue" />
                              <td className="text-right active p-right" colSpan={4}>
                                <label className="control-label">ドリンク費</label>
                              </td>
                              <td colSpan={1} className="column_change text-center">変</td>
                            </tr>
                            <tr>
                              <td className="text-center active lightBlue" colSpan={5}>
                                <label className="control-label">【F】計	</label>
                              </td>
                              <td colSpan={1} className="column_change lightBlue" />
                            </tr>
                          </AccordionTableWrapper>
                          <tr>
                            <td className="text-right active p-right" colSpan={5}>
                              <label className="control-label">F率</label>
                            </td>
                            <td colSpan={1} className="column_change text-center" />
                          </tr>
                          {/* End F（食材費） */}
                          {/*  */}
                          {/* <tr>
                            <td className="text-center" rowSpan={2}>売上総利益</td>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{justifyContent:'flex-end'}}>
                                合計&nbsp;
                              <IconTooltip iconType="help" text="売上高（合計） - 標準原価（合計）" color="#000" />
                              </div>


                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{justifyContent:'flex-end'}}>
                                率&nbsp;
                              <IconTooltip iconType="help" text="売上総利益（合計） / 売上高（合計）の％表示" color="#000" />
                              </div>

                            </td>
                          </tr> */}
                          {/* Start L（人件費）				 */}
                          <AccordionTableWrapper
                            isExpanded={isLaborCostExpanded}
                            setStateAccordion={setIsLaborCostExpand}
                          >
                            <tr>
                              <td className="text-left active lightBlue p-left" colSpan={5}>
                                <label className="control-label">L（人件費）</label>
                              </td>
                              <td colSpan={1} className="column_change text-center" />

                              {/* <td className="text-right active width-110">
                        <span className="sumOfTotalEmployeeLaborCostAndPartLaborCost">
                          {formatter.format(numberFormater(getRows.getSumOfTotalEmployeeLaborCostAndPartLaborCost) || 0)}
                        </span>
                      </td> */}
                            </tr>

                            <tr>
                              <td colSpan={1} className="empty_column lightBlue" />
                              <td className="text-right active p-right" colSpan={4}>
                                <label className="control-label">社員人件費</label>
                              </td>
                              <td colSpan={1} className="column_change text-center">固</td>
                            </tr>

                            <tr>
                              <td colSpan={1} className="empty_column lightBlue" />
                              <td className="text-right active p-right" colSpan={4}>
                                <label className="control-label">アルバイト人件費</label>
                              </td>
                              <td colSpan={1} className="column_change text-center">変</td>
                            </tr>

                            <tr>
                              <td className="text-center active lightBlue" colSpan={5}>
                                <label className="control-label">【L】計</label>
                              </td>
                              <td colSpan={1} className="column_change lightBlue" />
                            </tr>
                          </AccordionTableWrapper>
                          <tr>
                            <td className="text-right active p-right" colSpan={5}>
                              <label className="control-label">L率</label>
                            </td>
                            <td colSpan={1} className="column_change text-center" />
                          </tr>
                          {/* End L（人件費）				 */}
                          {/* Start A（広告費） */}
                          <AccordionTableWrapper
                            isExpanded={isAdvertisingCostExpanded}
                            setStateAccordion={setIsAdvertisingCostExpand}
                          >
                            <tr>
                              <td className="text-left active lightBlue p-left" colSpan={5}>A（広告費）</td>
                              <td colSpan={1} className="column_change text-center" />
                              {/* <td className="text-right active width-110">
                        <span className="sumOfAdvertisingExpense">
                          {formatter.format(numberFormater(getRows.getSumOfAdvertisingExpense) || 0)}
                        </span>
                      </td> */}
                            </tr>
                            {(countAdvertising && advertisingData)
                              ? advertisingData.map((advertising: any) => (
                                <tr>
                                  <td colSpan={1} className="empty_column lightBlue" />
                                  <td className="text-right active p-right" colSpan={4}>
                                    <TextTooltip id={`A（広告費）_${advertising.advertising_media_mst_code}`} text={advertising.advertising_media_mst_name} width='calc(100% - 1%)'/>
                                  </td>
                                  <td colSpan={1} className="text-center">固</td>
                                </tr>
                              ))
                              : ''}
                            <tr>
                              <td colSpan={5} className="text-center active lightBlue">
                                <label className="control-label">【A】計</label>
                              </td>
                              <td colSpan={1} className="column_change lightBlue" />
                            </tr>
                          </AccordionTableWrapper>
                          <tr>
                            <td colSpan={5} className="text-right active p-right">
                              <label className="control-label">A率</label>
                            </td>
                            <td colSpan={1} className="column_change " />
                          </tr>
                          {/* End A（広告費） */}
                          {/*  */}
                          {/* Start FLA合計				*/}
                          <tr>
                            <td colSpan={5} className="lightBlue p-left">FLA合計</td>
                            <td colSpan={1} className="column_change lightBlue" />
                          </tr>
                          <tr>
                            {/* <td colSpan={1} className="empty_column"></td>  */}
                            <td colSpan={5} className="text-right p-right">FLA率</td>
                            <td colSpan={1} className="column_change text-center" />
                          </tr>
                          {/* End FLA合計				*/}
                          {/* Start 店舗利益				 */}
                          <tr>
                            <td colSpan={5} className="lightYellow p-left">店舗利益</td>
                            <td colSpan={1} className="column_change lightYellow" />
                          </tr>
                          <tr>
                            <td className="empty_column" colSpan={1} style={{ backgroundColor: '#fff2cc' }} />
                            <td colSpan={4} className="text-right p-right">店舗利益率</td>
                            <td colSpan={1} className="column_change text-center" />
                          </tr>
                          {/* End 店舗利益				 */}
                          {/* Start R（家賃） */}
                          <AccordionTableWrapper
                            isExpanded={isRentExpanded}
                            setStateAccordion={setIsRentExpand}
                          >
                            <tr>
                              <td className="text-left active p-left height_row_contents" colSpan={5} style={{ backgroundColor: '#cfe2f3' }}>
                                R（家賃）
                              </td>
                              <td colSpan={1} className="column_change text-center" />
                            </tr>
                            <tr>
                              <td colSpan={1} className="empty_column lightBlue" />
                              <td colSpan={4} className="text-right p-right">家賃</td>
                              <td colSpan={1} className="column_change text-center">固</td>
                            </tr>
                            <tr>
                              <td colSpan={1} className="empty_column lightBlue" />
                              <td colSpan={4} className="text-right p-right">共益費</td>
                              <td colSpan={1} className="column_change text-center">固</td>
                            </tr>
                            <tr>
                              <td className="text-center active lightBlue" colSpan={5}>
                                【R】計
                              </td>
                              <td colSpan={1} className="column_change lightBlue" />
                            </tr>
                          </AccordionTableWrapper>
                          <tr>
                            <td className="text-right active p-right" colSpan={5}>
                              R率
                            </td>
                            <td colSpan={1} className="column_change text-center" />
                          </tr>
                          {/* End R（家賃） */}
                          {/* Start O（その他経費）				 */}
                          <AccordionTableWrapper
                            isExpanded={isOtherExpensesExpanded}
                            setStateAccordion={setIsOtherExpensesExpand}
                          >
                            <tr>
                              <td className="text-left active lightBlue p-left" colSpan={5}>
                                <label className="control-label">O（その他経費）</label>
                              </td>
                              <td colSpan={1} className="column_change text-center" />
                            </tr>
                            {(countExpenses && expensesData)
                              ? expensesData.map((expenses: any) => (
                                <tr>
                                  <td
                                    style={{
                                      minWidth: '25px', width: '1.5%', border: '0px solid black', borderLeft: '1px solid black', backgroundColor: '#cfe2f3',
                                    }}
                                    colSpan={1}
                                  />
                                  <td className="text-right p-right" colSpan={4}>
                                    <TextTooltip id={`O（その他経費）_${expenses.expenses_code}`} text={expenses.expenses_name} width='calc(100% - 1%)'/>
                                  </td>
                                  <td className="text-center" colSpan={1} style={{ width: '20%' }}>
                                    {
                                        expenses.expenses_classification === 0 ? '変' : '固'
                                      }
                                  </td>
                                </tr>
                              ))
                              : ''}
                            <tr>
                              <td className="empty_column lightBlue" colSpan={1} />
                              <td className="text-right p-right" colSpan={4}>
                                仕入（その他）
                              </td>
                              <td className="text-center" colSpan={1} style={{ width: '20%' }}>
                                変
                              </td>
                            </tr>

                            <tr>
                              <td className="text-center active lightBlue" colSpan={5}>
                                <label className="control-label">【O】計</label>
                              </td>
                              <td colSpan={1} className="column_change lightBlue" />
                            </tr>
                          </AccordionTableWrapper>
                          <tr>
                            <td className="text-right active p-right" colSpan={5}>
                              <label className="control-label">O率</label>
                            </td>
                            <td colSpan={1} className="column_change text-center" />
                          </tr>
                          {/* End O（その他経費）				 */}
                          {/* Start 【FLARO】合計				 */}
                          <tr>
                            <td className="text-left active lightPurple p-left" colSpan={5}>【FLARO】合計</td>
                            <td colSpan={1} className="column_change lightPurple" />
                          </tr>
                          <tr>
                            <td className="text-right active lightPurple p-right" colSpan={5}>FLARO率</td>
                            <td colSpan={1} className="column_change" />
                          </tr>
                          {/* End 【FLARO】合計				 */}
                          {/* Start 営業利益	 */}

                          <tr>
                            <td className="text-left active lightYellow p-left" colSpan={5}>
                              <label className="control-label">営業利益</label>
                            </td>
                            <td colSpan={1} className="column_change lightYellow" />
                          </tr>
                          <tr>
                            <td className="lightYellow" colSpan={1} />
                            <td className="text-right active p-right" colSpan={4}>
                              営業利益率
                            </td>
                            <td colSpan={1} className="column_change text-center" />
                          </tr>
                          {/* End 営業利益	 */}
                        </tbody>
                      </table>
                      {/* ------------------------------------------------------------Second Table------------------------------------------------------------------------- */}
                      <table className={`table1 table table-bordered table-striped table-condensed text-nowrap ${setting.calculationItemOfFRate === 1 ? 'f_rate_table2' : 'f_rate_table1'}`}>
                        <tbody>
                          {/* --------------------------------------Table Below----------------------------------- */}
                          {setting.calculationItemOfFRate === 1 ? (
                            <>

                              <tr>
                                <td style={{ borderTop: 0 }} className="text-center no-border split_sale_repport" colSpan={6}>&nbsp;</td>
                              </tr>
                              <tr className="split_sale_repport">
                                <td className="text-center " rowSpan={5}>仕入額</td>
                                <td className="text-right p-right" colSpan={4}>フード</td>
                              </tr>

                              <tr className="light_gray_background">
                                <td className="text-right p-right" colSpan={4}>ドリンク</td>
                              </tr>
                              <tr className="split_sale_repport">
                                <td className="text-right p-right" colSpan={4}>その他</td>
                              </tr>
                              <tr className="light_gray_background">
                                <td className="text-right p-right" colSpan={4}>合計</td>
                              </tr>
                              <tr className="split_sale_repport">
                                <td className="text-right p-right" colSpan={4}>率</td>
                              </tr>
                              {/*  */}
                              <tr>
                                <td className="text-center no-border split_sale_repport" colSpan={3}>&nbsp;</td>
                              </tr>
                              {/*  */}

                            </>
                          ) : (
                            <>
                              {/*  */}
                              <tr>
                                <td style={{ borderTop: 0 }} className="text-center no-border split_sale_repport" colSpan={3}>&nbsp;</td>
                              </tr>
                              {/*  */}
                              <tr>
                                <td className="text-center" rowSpan={4}>標準原価</td>
                                <td className="text-right p-right" colSpan={4}>フード</td>
                              </tr>

                              <tr>
                                <td className="text-right p-right" colSpan={4}>ドリンク</td>
                              </tr>
                              <tr>
                                <td className="text-right p-right" colSpan={4}>合計</td>
                              </tr>
                              <tr>
                                <td className="text-right p-right" colSpan={4}>率</td>
                              </tr>

                              {/*  */}
                              <tr>
                                <td className="text-center no-border split_sale_repport" colSpan={3}>&nbsp;</td>
                              </tr>
                              {/*  */}
                            </>
                          )}

                          <tr>
                            <td className="text-right p-right" colSpan={3}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                客単価&nbsp;
                                <IconTooltip iconType="help" text="売上高合計 / 客数" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-right p-right" colSpan={3}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                客数&nbsp;
                                <IconTooltip iconType="help" text="POSの客数" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={3}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                組数&nbsp;
                                <IconTooltip iconType="help" text="POSの会計数" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={3}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                組あたり平均人数
                                <IconTooltip iconType="help" text="客数 / 組数" color="#000" />
                              </div>
                            </td>
                          </tr>
                          {/* <tr>
                            <td className="text-right p-right" colSpan={3}>
                              <label className="control-label" style={{ fontSize: '12px' }}>
                                損益分岐点売上高までの客数
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-right p-right" colSpan={3}>
                              <label className="control-label" style={{ fontSize: '12px' }}>
                                月次予算売上高までの客数
                              </label>
                            </td>
                          </tr> */}
                          <tr>
                            <td className="text-center" colSpan={3}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                回転率&nbsp;
                                <IconTooltip iconType="help" text="客数 / 座席数" color="#000" />
                              </div>
                            </td>
                          </tr>
                          {/*  */}
                          <tr>
                            <td className="text-center" rowSpan={2}>限界利益</td>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                額&nbsp;
                                <IconTooltip iconType="help" text="売上高合計 - 変動費" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                率&nbsp;
                                <IconTooltip iconType="help" text="限界利益 / 売上高合計の％表示" color="#000" />
                              </div>
                            </td>
                          </tr>
                          {/*  */}
                          <tr>
                            <td className="text-center" rowSpan={2}>貢献利益</td>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                額&nbsp;
                                <IconTooltip iconType="help" text="限界利益 - 固定費" color="#000" />
                              </div>

                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                率&nbsp;
                                <IconTooltip iconType="help" text="貢献利益 / 売上高合計の％表示" color="#000" />
                              </div>

                            </td>
                          </tr>
                          {/*  */}
                          <tr>
                            <td className="text-center" rowSpan={2}>
                              損益分岐点
                            </td>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                売上&nbsp;
                                <IconTooltip iconType="help" text="固定費 / 限界利益率" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                客数&nbsp;
                                <IconTooltip iconType="help" text="損益分岐点売上 / 客単価" color="#000" />
                              </div>
                            </td>
                          </tr>
                          {/*  */}
                          <tr>
                            <td className="text-center" rowSpan={3}>人件費率</td>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                合計&nbsp;
                                <IconTooltip iconType="help" text="人件費合計 / 売上高合計の％表示" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                社員&nbsp;
                                <IconTooltip iconType="help" text="社員人件費 / 売上高合計の％表示" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                アルバイト&nbsp;
                                <IconTooltip iconType="help" text="アルバイト人件費 / 売上高合計の％表示" color="#000" />
                              </div>
                            </td>
                          </tr>
                          {/*  */}
                          <tr>
                            <td className="text-center split_sale_repport" rowSpan={3}>総労働時間</td>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                合計&nbsp;
                                <IconTooltip iconType="help" text="社員総労働時間 ＋ アルバイト総労働時間" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                社員&nbsp;
                                <IconTooltip iconType="help" text="勤怠システムから取得" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                アルバイト&nbsp;
                                <IconTooltip iconType="help" text="勤怠システムから取得" color="#000" />
                              </div>
                            </td>
                          </tr>
                          {/*  */}
                          <tr>
                            <td className="text-center" rowSpan={3}>勤務人数</td>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                合計&nbsp;
                                <IconTooltip iconType="help" text="社員勤務人数 ＋ アルバイト勤務人数" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                社員&nbsp;
                                <IconTooltip iconType="help" text="勤怠システムから取得" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={2}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                アルバイト&nbsp;
                                <IconTooltip iconType="help" text="勤怠システムから取得" color="#000" />
                              </div>
                            </td>
                          </tr>
                          {/*  */}
                          <tr>
                            <td className="text-center" colSpan={3}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                人時売上高&nbsp;
                                <IconTooltip iconType="help" text="売上高合計 / 総労働時間合計" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={3}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                人時生産性&nbsp;
                                <IconTooltip iconType="help" text="（売上高合計 - 食材費合計）/ 総労働時間合計" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={3}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                人時接客数&nbsp;
                                <IconTooltip iconType="help" text="客数 / 総労働時間合計" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={3}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                労働生産性&nbsp;
                                <IconTooltip iconType="help" text="（売上高合計 - 食材費合計）/ 勤怠人数合計" color="#000" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={3}>
                              <div className="icon-tooltip" style={{ justifyContent: 'flex-end' }}>
                                労働分配率&nbsp;
                                <IconTooltip iconType="help" text="人件費合計 /（売上高合計 - 食材費合計）の%表示" color="#000" />
                              </div>

                            </td>
                          </tr>
                          {/*  */}
                          {/* --------------------------------------Table Below----------------------------------- */}
                        </tbody>
                      </table>
                    </div>
                    <div className="table2">
                      <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '239px' }}>
                        {/* render data */}
                        <tbody className="wrap-color">
                          {
                              slicedDataKeyName.map((dataKeyName: any, index: any) => {
                                if (index === 0 || index === 1 || index === 4 || index === 6 || index === 10 || index === 12) {
                                  return (
                                    <AccordionTableWrapper
                                      isExpanded={index === 0 ? isAdvertisingSaleDataExpanded
                                        : index === 1 ? isIngredientCostExpanded
                                          : index === 4 ? isLaborCostExpanded
                                            : index === 6 ? isAdvertisingCostExpanded
                                              : index === 10 ? isRentExpanded
                                                : index === 12 ? isOtherExpensesExpanded : true}
                                      setStateAccordion={index === 0 ? setIsAdvertisingSaleDataExpand
                                        : index === 1 ? setIsIngredientCostExpand
                                          : index === 4 ? setIsLaborCostExpand
                                            : index === 6 ? setIsAdvertisingCostExpand
                                              : index === 10 ? setIsRentExpand
                                                : index === 12 ? setIsOtherExpensesExpand : setIsOtherExpensesExpand}
                                    >
                                      {/* can not refactor this collection of tr into smaller component,
                                              because AccordionTableWrapper can only receive multiple elements not single element */}
                                      {
                                      dataKeyName.map((key_name: any, index: any) => {
                                        const render_footerColor = renderFooterColor(key_name);
                                        if (key_name !== 'grossProfitTotal'
                                          && key_name !== 'grossProfitRate'
                                          && key_name !== 'FLCost'
                                          && key_name !== 'FLRate'
                                          && key_name !== 'FLARCost'
                                          && key_name !== 'FLARRate'
                                          && key_name !== 'otherExpensesVariableCosts'
                                          && key_name !== 'otherExpensesFixedCost'
                                        ) {
                                          return (
                                            <tr style={{ background: 'white !important'}}>
                                              {(!monthlyPlanData[key_name] || (monthlyPlanData[key_name] && monthlyPlanData[key_name].length === 0))
                                              && <td style={{ backgroundColor: render_footerColor }} className="text-center no-border split_sale_repport" colSpan={3}>&nbsp;</td>}

                                              {(monthlyPlanData[key_name] && monthlyPlanData[key_name].length > 0)
                                              && <td style={{ backgroundColor: render_footerColor }} className={(monthlyPlanData[key_name][0] === '-') ? 'text-center' : 'text-right'}>
                                                    <div style={{
                                                      whiteSpace: "nowrap",
                                                      textOverflow: "ellipsis",
                                                      width: '89px',
                                                      overflow: "hidden"
                                                    }}>
                                                      {numberFormater(monthlyPlanData[key_name][0])}
                                                    </div>
                                                  </td>}

                                              {(monthlyPlanData[key_name] && monthlyPlanData[key_name].length > 0)
                                              && <td style={{ backgroundColor: render_footerColor }} className={(monthlyPlanData[key_name][1] === '-') ? 'text-center' : 'text-right'}>
                                                    <div style={{
                                                      whiteSpace: "nowrap",
                                                      textOverflow: "ellipsis",
                                                      width: '89px',
                                                      overflow: "hidden"
                                                    }}>
                                                      {numberFormater(monthlyPlanData[key_name][1])}
                                                    </div>
                                                </td>}
                                              {(monthlyPlanData[key_name] && monthlyPlanData[key_name].length > 0)
                                              && <td style={{ backgroundColor: render_footerColor, width: '54px' }} className={(monthlyPlanData[key_name][2] === '-') ? 'text-center' : 'text-right'}>
                                                  <div style={{
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    width: '53px',
                                                    overflow: "hidden"
                                                  }}>
                                                    {numberFormater(monthlyPlanData[key_name][2])}
                                                  </div>
                                                </td>}
                                            </tr>
                                          );
                                        }
                                        { /* can not refactor this collection of tr into smaller component,
                                              because AccordionTableWrapper can only receive multiple elements not single element */ }
                                      })
                                    }
                                    </AccordionTableWrapper>
                                  );
                                }


                                return dataKeyName.map((key_name: any, index: any) => {
                                  const render_tr_gray_background = renderTableRowGrayBackground(key_name);
                                  const render_footerColor = renderFooterColor(key_name);
                                  if (key_name !== 'grossProfitTotal'
                                      && key_name !== 'grossProfitRate'
                                      && key_name !== 'FLCost'
                                      && key_name !== 'FLRate'
                                      && key_name !== 'FLARCost'
                                      && key_name !== 'FLARRate'
                                      && key_name !== 'otherExpensesVariableCosts'
                                      && key_name !== 'otherExpensesFixedCost'
                                  ) {
                                    if (setting.calculationItemOfFRate !== 1
                                      ? key_name !== 'rowBlank_07'
                                        && key_name !== 'purchaseAmountFood'
                                        && key_name !== 'purchaseAmountDrink'
                                        && key_name !== 'purchaseAmountOhter'
                                        && key_name !== 'purchaseAmountTotal'
                                        && key_name !== 'purchaseAmountRateTotal'
                                      : setting.calculationItemOfFRate !== 0
                                        ? key_name !== 'rowBlank_08'
                                          && key_name !== 'CostFood'
                                          && key_name !== 'CostDrink'
                                          && key_name !== 'CostTotal'
                                          && key_name !== 'CostRate'
                                        : '') {
                                      return (
                                        <tr style={{ background: render_tr_gray_background, border: isMacOs ? renderBorderOnMac(key_name) : '' }}>
                                          {(!monthlyPlanData[key_name] || (monthlyPlanData[key_name] && monthlyPlanData[key_name].length === 0))
                                            && <td style={{ backgroundColor: render_footerColor }} className="text-center no-border split_sale_repport" colSpan={3}>&nbsp;</td>}

                                          {(monthlyPlanData[key_name] && monthlyPlanData[key_name].length > 0)
                                            && <td style={{ backgroundColor: render_footerColor }} className={(monthlyPlanData[key_name][0] === '-') ? 'text-center' : 'text-right'}>
                                                <div style={{
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                  width: '89px',
                                                  overflow: "hidden"
                                                }}>
                                                  {numberFormater(monthlyPlanData[key_name][0])}
                                                </div>
                                              </td>}

                                          {(monthlyPlanData[key_name] && monthlyPlanData[key_name].length > 0)
                                            && <td style={{ backgroundColor: render_footerColor }} className={(monthlyPlanData[key_name][1] === '-') ? 'text-center' : 'text-right'}>
                                                <div style={{
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                  width: '89px',
                                                  overflow: "hidden"
                                                }}>
                                                  {numberFormater(monthlyPlanData[key_name][1])}
                                                </div>
                                              </td>}
                                          {(monthlyPlanData[key_name] && monthlyPlanData[key_name].length > 0)
                                            && <td style={{ backgroundColor: render_footerColor, width: '54px' }} className={(monthlyPlanData[key_name][2] === '-') ? 'text-center' : 'text-right'}>
                                                <div style={{
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                  width: '53px',
                                                  overflow: "hidden"
                                                }}>
                                                  {numberFormater(monthlyPlanData[key_name][2])}
                                                </div>
                                              </td>}
                                        </tr>
                                      );
                                    }
                                  }
                                });
                              })
                            }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* ------------------------------------------- 右下 ------------------------------------------- */}
              <div className="no-padding layout_sale_right" id="layout_sale_right_02" onScroll={eventScroll}>
                <div className="layout_sale_right_content">
                  <div className="no-padding">
                    <div className="box-body no-padding">
                      <div className="table-responsive no-padding">
                        <table className="table table-bordered table-striped table-condensed text-nowrap">
                          {/* render data */}
                          <tbody className="wrap-color">
                            {
                                slicedDataKeyName.map((dataKeyName: any, index: any) => {
                                  if (index === 0 || index === 1 || index === 4 || index === 6 || index === 10 || index === 12) {
                                    return (
                                      <AccordionTableWrapper
                                        isExpanded={index === 0 ? isAdvertisingSaleDataExpanded
                                          : index === 1 ? isIngredientCostExpanded
                                            : index === 4 ? isLaborCostExpanded
                                              : index === 6 ? isAdvertisingCostExpanded
                                                : index === 10 ? isRentExpanded
                                                  : index === 12 ? isOtherExpensesExpanded : true}
                                        setStateAccordion={index === 0 ? setIsAdvertisingSaleDataExpand
                                          : index === 1 ? setIsIngredientCostExpand
                                            : index === 4 ? setIsLaborCostExpand
                                              : index === 6 ? setIsAdvertisingCostExpand
                                                : index === 10 ? setIsRentExpand
                                                  : index === 12 ? setIsOtherExpensesExpand : setIsOtherExpensesExpand}
                                      >
                                        {/* can not refactor this collection of tr into smaller component,
                                              because AccordionTableWrapper can only receive multiple elements not single element */}
                                        {
                                        dataKeyName.map((key_name: any, index: any) => {
                                          const render_footerColor = renderFooterColor(key_name);

                                          if (key_name !== 'grossProfitTotal'
                                            && key_name !== 'grossProfitRate'
                                            && key_name !== 'FLCost'
                                            && key_name !== 'FLRate'
                                            && key_name !== 'FLARCost'
                                            && key_name !== 'FLARRate'
                                            && key_name !== 'otherExpensesVariableCosts'
                                            && key_name !== 'otherExpensesFixedCost'
                                          ) {
                                            return (
                                              <tr style={{ background: 'white !important' }}>
                                                {(!dayPlanData[key_name] || (dayPlanData[key_name] && dayPlanData[key_name].length === 0))
                                                && <td style={{ backgroundColor: render_footerColor }} className="text-center no-border split_sale_repport" colSpan={31}>&nbsp;</td>}

                                                {(dayPlanData[key_name] && dayPlanData[key_name].length > 0)
                                                && (
                                                  dayPlanData[key_name].map((val: any) => (
                                                    <td style={{ backgroundColor: render_footerColor }} className="text-right">
                                                      <div style={{
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis",
                                                        width: '83px',
                                                        overflow: "hidden"
                                                      }}>
                                                        {numberFormater(val)}
                                                      </div>
                                                    </td>
                                                  ))
                                                )}
                                              </tr>
                                            );
                                          }
                                          { /* can not refactor this collection of tr into smaller component,
                                              because AccordionTableWrapper can only receive multiple elements not single element */ }
                                        })
                                      }
                                      </AccordionTableWrapper>
                                    );
                                  }


                                  return dataKeyName.map((key_name: any, index: any) => {
                                    const render_tr_gray_background = renderTableRowGrayBackground(key_name);
                                    const render_footerColor = renderFooterColor(key_name);
                                    if (key_name !== 'grossProfitTotal'
                                        && key_name !== 'grossProfitRate'
                                        && key_name !== 'FLCost'
                                        && key_name !== 'FLRate'
                                        && key_name !== 'FLARCost'
                                        && key_name !== 'FLARRate'
                                        && key_name !== 'otherExpensesVariableCosts'
                                        && key_name !== 'otherExpensesFixedCost'
                                    ) {
                                      if (setting.calculationItemOfFRate !== 1
                                        ? key_name !== 'rowBlank_07'
                                          && key_name !== 'purchaseAmountFood'
                                          && key_name !== 'purchaseAmountDrink'
                                          && key_name !== 'purchaseAmountOhter'
                                          && key_name !== 'purchaseAmountTotal'
                                          && key_name !== 'purchaseAmountRateTotal'
                                        : setting.calculationItemOfFRate !== 0
                                          ? key_name !== 'rowBlank_08'
                                            && key_name !== 'CostFood'
                                            && key_name !== 'CostDrink'
                                            && key_name !== 'CostTotal'
                                            && key_name !== 'CostRate'
                                          : '') {
                                        return (
                                          <tr style={{ background: render_tr_gray_background }}>

                                            {(!dayPlanData[key_name] || (dayPlanData[key_name] && dayPlanData[key_name].length === 0))
                                              && <td style={{ backgroundColor: render_footerColor }} className="text-center no-border split_sale_repport" colSpan={31}>&nbsp;</td>}

                                            {(dayPlanData[key_name] && dayPlanData[key_name].length > 0)
                                              && (
                                                dayPlanData[key_name].map((val: any) => (
                                                  <td style={{ backgroundColor: render_footerColor }} className="text-right">
                                                    <div style={{
                                                      whiteSpace: "nowrap",
                                                      textOverflow: "ellipsis",
                                                      width: '83px',
                                                      overflow: "hidden"
                                                    }}>
                                                      {numberFormater(val)}
                                                    </div>
                                                  </td>
                                                ))
                                              )}
                                          </tr>
                                        );
                                      }
                                    }
                                  });
                                })
                              }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
            </div>

            <div className="row" style={{ position: 'relative' }}>
              <div className="no-padding layout_sale_left" />
              <div style={{ float: 'right' }} className="no-padding layout_sale_right" id="layout_sale_02_buttom_right_set_scroll" onScroll={eventScroll}>
                <div id="layout_sale_02_buttom_right_content_set_scroll" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default SalesReportTable;
