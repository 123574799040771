import React from 'react';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import HourInput from 'components/atoms/Form/HourInput';
import BodyText from 'components/atoms/BodyText';
import FormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import FormLabel from 'components/atoms/Form/FormLabel';

const DetailPaid1DayForm: React.FC<{
  paidCalculationStandard: boolean;
  setPaidCalculationStandard: (paidCalculationStandard: boolean) => void;
  salaryCalculation: number;
  setSalaryCalculation: (salaryCalculation: number) => void;
  unitPricePeriod: number;
  setUnitPricePeriod: (unitPricePeriod: number) => void;
  unitPriceTransportation: boolean;
  setUnitPriceTransportation: (unitPriceTransportation: boolean) => void;
  unitPriceRounding: number;
  setUnitPriceRounding: (unitPriceRounding: number) => void;
  // isWorkTime: number;
  // setIsWorkTime: (isWorkTime: number) => void;
  unUsableOfTrialPeriod: boolean;
  setUnUsableOfTrialPeriod: (unUsableOfTrialPeriod: boolean) => void;
  useEmploymentDeemedWorkingHours: boolean;
  setUseEmploymentDeemedWorkingHours: (useEmploymentDeemedWorkingHours: boolean) => void;
  deemedWorkingHours: number,
  setDeemedWorkingHours: (deemedWorkingHours: number) => void
  deemedWorkingMinutes: number,
  setDeemedWorkingMinutes: (deemedWorkingMinutes: number) => void
}> = ({
  salaryCalculation, setSalaryCalculation,
  unitPricePeriod, setUnitPricePeriod,
  unitPriceTransportation, setUnitPriceTransportation,
  unitPriceRounding, setUnitPriceRounding,
  // isWorkTime, setIsWorkTime,
  unUsableOfTrialPeriod, setUnUsableOfTrialPeriod,
  useEmploymentDeemedWorkingHours, setUseEmploymentDeemedWorkingHours,
  deemedWorkingHours, setDeemedWorkingHours,
  deemedWorkingMinutes, setDeemedWorkingMinutes,
}) => (
  <FormContents isSubForm={true}>
    <FormTitle
      title="有給詳細設定"
      note=""
    />
    {/* <FormField>
      <RadioSelectForm
        label="有給算出基準：勤務日数への加算"
        subLabel=""
        items={[
          {
            label: '加算しない',
            value: '0',
          },
          {
            label: '加算する',
            value: '1',
          },
        ]}
        name="xx"
        value={paidCalculationStandard ? '1' : '0'}
        setValue={(e) => {
          setPaidCalculationStandard(e.target.value !== '0');
        }}
      />
    </FormField> */}

    <FormField>
      <RadioSelectForm
        label="賃金計算方法"
        items={[
          {
            label: '時間',
            value: '0',
          },
          {
            label: '金額',
            value: '1',
          },
        ]}
        name="salaryCalculation"
        value={String(salaryCalculation)}
        setValue={(e) => {
          setSalaryCalculation(Number(e.target.value));
        }}
      />
    </FormField>

    { salaryCalculation === 0 && (
      <FormContents isSubForm={true}>
        <FormField>
          <RadioSelectForm
            label="有給時みなし勤務時間"
            subLabel=""
            items={[
              {
                label: '設定する',
                value: '0',
              },
              {
                label: '雇用形態に設定されている日の契約労働時間をみなし勤務時間とする',
                value: '1',
              },
            ]}
            name="yy"
            value={useEmploymentDeemedWorkingHours ? '1' : '0'}
            setValue={(e) => { setUseEmploymentDeemedWorkingHours(e.target.value !== '0'); }}
          />
        </FormField>
        <FormField>
          <FlexBox>
            <HourInput
              name="hour"
              value={deemedWorkingHours}
              label="時間"
              onChange={(e) => {
                setDeemedWorkingHours(Number(e.target.value));
              }}
              min={0}
              max={23}
            />
            <HourInput
              name="minute"
              value={deemedWorkingMinutes}
              label="分"
              onChange={(e) => {
                setDeemedWorkingMinutes(Number(e.target.value));
              }}
              min={0}
              max={59}
            />
          </FlexBox>
        </FormField>

      </FormContents>
    )}

    { salaryCalculation === 1 && (
      <FormContents isSubForm={true}>

        <FormField>
          <FormLayout
            label={(
              <FormLabel
                label="対象期間"
              />
                )}
            input={(
              <FlexBox>
                <div style={{ marginRight: '12px' }}>
                  <BodyText> 過去 </BodyText>
                </div>
                <HourInput
                  name="unitPricePeriod"
                  value={unitPricePeriod}
                  label="ヶ月の平均"
                  onChange={(e) => {
                    setUnitPricePeriod(Number(e.target.value));
                  }}
                  min={1}
                  max={12}
                />
              </FlexBox>
                )}
          />
        </FormField>

        <FormField>
          <RadioSelectForm
            label="金額計算時の賃金に交通費を"
            subLabel=""
            items={[
              {
                label: '含まない',
                value: '0',
              },
              {
                label: '含む',
                value: '1',
              },
            ]}
            name="zz"
            value={unitPriceTransportation ? '1' : '0'}
            setValue={(e) => { setUnitPriceTransportation(e.target.value === '1'); }}
          />
        </FormField>

        <FormField>
          <RadioSelectForm
            label="単価の端数処理（銭） "
            items={[
              {
                label: '切り捨てる',
                value: '0',
              },
              {
                label: '切り上げる',
                value: '1',
              },
              {
                label: '四捨五入',
                value: '4',
              },
            ]}
            name="unitPriceRounding"
            value={String(unitPriceRounding)}
            setValue={(e) => {
              setUnitPriceRounding(Number(e.target.value));
            }}
          />
        </FormField>


      </FormContents>
    )}

    <FormField>
      <RadioSelectForm
        label="試用期間中の有給使用可否"
        subLabel=""
        items={[
          {
            label: '使用不可',
            value: '1',
          },
          {
            label: '使用可',
            value: '0',
          },
        ]}
        name="unUsableOfTrialPeriod"
        value={unUsableOfTrialPeriod ? '1' : '0'}
        setValue={(e) => { setUnUsableOfTrialPeriod(e.target.value !== '0'); }}
      />
    </FormField>

  </FormContents>
);
export default DetailPaid1DayForm;
