import moment from 'moment';
import ApiClient from './ApiClient';

export const getOperationalReportList = async (selectOrgCode: any, targetDate: Date, categoryAry: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode: selectOrgCode,
    loginStaffCode: sessionStorage.getItem('loginUser.staffCode') || '',
    functionType: 3,
    targetDate: moment(targetDate).format('YYYY-MM-DD'),
  };

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const response = await ApiClient.get(`/v1/jsp/sj/operational_report_list/${companyCode}?${appendUrl}`, params);
  return response.data;
};
export default getOperationalReportList;
