import { useState, useEffect, useCallback } from 'react';

import { AttendAlert } from 'api/attendAlert';
import {
  getAttendErrorOrg, getAttendErrorPersonal, AttendErrorOgr, AttendErrorPersonal,
} from 'api/attendError';
import { useHistory } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import awsExports from 'aws-exports'; // 設定情報を読み込みます。
import { uiSidebarActions } from 'modules/ui/sidebar';
import { useDispatch } from 'react-redux';

Amplify.configure(awsExports); // 設定情報をAmplifyに反映させます。

// export const useAttendAlert = () => {
//   const [openAttendAlert, setOpenAttendAlert] = useState(false);
//   const [attendAlertList, setAttendAlertList] = useState<Array<AttendAlert>>([]);

//   useEffect(() => {
//     const fetch = async () => {
//       const response = await getAttendAlerts();
//       setAttendAlertList(response);
//     };
//     fetch();
//   }, []);

//   return {
//     openAttendAlert,
//     setOpenAttendAlert,
//     attendAlertList,
//   };
// };

export const useAttendErrorOrg = () => {
  const [attendErrorOrgList, setAttendErrorOrgList] = useState<Array<AttendErrorOgr>>([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getAttendErrorOrg();
      setAttendErrorOrgList(response);
    };
    fetch();
  }, []);

  return {
    attendErrorOrgList,
  };
};

export const useAttendErrorPersonal = () => {
  const [attendErrorPersonalList,
    setAttendErrorPersonalList] = useState<Array<AttendErrorPersonal>>([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getAttendErrorPersonal();
      setAttendErrorPersonalList(response);
    };
    fetch();
  }, []);

  return {
    attendErrorPersonalList,
  };
};

export const useStaffSetting = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [openStaffSetting, setOpenStaffSetting] = useState(false);
  const [openModalLogout, setOpenModalLogout] = useState(false);

  /**
   * sessionStorageをクリアしてログインページへ飛ばす
   */
  const logoutAction = useCallback(() => {
    dispatch(uiSidebarActions.setHierarchy(undefined));
    sessionStorage.clear();
    localStorage.removeItem('logged');
    localStorage.removeItem('topPageSP');
    localStorage.removeItem('topPagePC');
    Auth.signOut();
    history.push('/login');
  }, [dispatch, history]);

  const checkLogOut = () => {
    if (!localStorage.getItem('logged')) {
      setOpenModalLogout(true);
    }
  };

  // NOTE: storageイベントでWebStorageの変更を検知する
  window.addEventListener('storage', checkLogOut);

  return {
    openStaffSetting,
    setOpenStaffSetting,
    logoutAction,
    openModalLogout,
  };
};

/*
export const usePhotoPath = () => {
  const domainUrl = process.env.REACT_APP_PHOTO_BASE_URL;

  const [photoPath, setPhotoPath] = useState(
    `${domainUrl
    }/${sessionStorage.getItem('loginUser.companyCode')
    }/${sessionStorage.getItem('loginUser.staffCode')
    }/1.jpg?${now()}`,
  );
  return photoPath;
};
*/

export default {};
