import { useState, useEffect } from 'react';

import { getSelOrganize } from 'api/organization';

export const useOrganizeOptions = (): Array<any> => {
  const [organizeOptions, setOrganizeOptions] = useState<Array<any>>([]);
  useEffect(() => {
    getSelOrganize().then((items) => {
      setOrganizeOptions(items.map((item) => ({
        value: item.orgCode,
        label: `${item.orgCode} ${item.orgName}`,
      })));
    });
  }, []);
  return organizeOptions;
};

export default useOrganizeOptions;
