/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { Container, Row, Col } from 'react-grid-system';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
// import BodyText from 'components/atoms/BodyText';
import BlockUI from 'components/molecules/BlockUi';
import moment from 'moment';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import FormField from 'components/atoms/Form/FormField';
import FormLabel from 'components/atoms/Form/FormLabel';
import { isMobileOnly } from 'react-device-detect';
import Modal from 'components/molecules/Modal';
import {
  useGetEmployment,
  useGetStampSubTotal,
  useGetHolidayUsingStatus,
  useGetStaffs,
  eventScroll,
  useGetStampList,
  useGetAttendSetting
} from './hooks';
import ToastModal from 'components/organismos/ToastModal';
import DatePickerForm from 'components/molecules/DatePickerForm';
import MonthHourTable from './MonthHourTable';
import MonthDayTable from './MonthDayTable';
import PaidHolidayGranTable from './PaidHolidayGranTable';
import SpecialHolidayGrantTable from './SpecialHolidayGrantTable';
import SubstituteHolidayTable from './SubstituteHolidayTable';
import TransferHolidayTable from './TransferHolidayTable';
import AttendStampTable from './AttendStampTable';
import AttendStampTableNoData from './AttendStampTableNoData';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const AttendStampForm: React.FC = () => {
  const history = useHistory();
  const [toastMessage, setToastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);

  const currentDate = new Date();
  const firstDayStr = moment(currentDate).format('YYYY-MM-01');
  const lastDayStr = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
  ).format('YYYY-MM-DD');

  const [targetDateFrom, setTargetDateFrom] = useState(
    sessionStorage.getItem('attendStampList.targetDateFrom')
      ? new Date(sessionStorage.getItem('attendStampList.targetDateFrom')!)
      : new Date(firstDayStr),
  );
  const [targetDateTo, setTargetDateTo] = useState(
    sessionStorage.getItem('attendStampList.targetDateTo')
      ? new Date(sessionStorage.getItem('attendStampList.targetDateTo')!)
      : new Date(lastDayStr),
  );
  const [oldOrgCode, setOldSelectOrgCode] = useState(
    sessionStorage.getItem('attendStampList.orgCode')!
      || sessionStorage.getItem('loginUser.orgCode')!,
  );
  const [selectOrgCode, setSelectOrgCode] = useState(
    sessionStorage.getItem('attendStampList.orgCode')!
      || sessionStorage.getItem('loginUser.orgCode')!,
  );
  const [staffCode, setStaffCode] = useState(
    sessionStorage.getItem('attendStampList.staffCode')!
      || sessionStorage.getItem('loginUser.staffCode')!,
  );
  const [tmpStaffCode, setTmpStaffCode] = useState(
    sessionStorage.getItem('attendStampList.staffCode')!
      || sessionStorage.getItem('loginUser.staffCode')!,
  );
  const [selectedEmploymentId] = useState(
    sessionStorage.getItem('attendStampList.employmentId') || '',
  );
  const [attendFlag] = useState(
    sessionStorage.getItem('attendStampList.attendFlag') || '0',
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshStampList, setIsRefreshStampList] = useState(false);
  const [isRefreshStampSubTotal, setIsRefreshStampSubTotal] = useState(false);
  const [isRefreshHolidayUsingStatus, setIsRefreshHolidayUsingStatus] = useState(false);

  const { staffList, setStaffList } = useGetStaffs(
    targetDateFrom,
    targetDateTo,
    selectOrgCode,
    selectedEmploymentId,
    attendFlag,
  );
  const { employment } = useGetEmployment(
    targetDateFrom,
    targetDateTo,
    staffCode,
    selectOrgCode,
  );
  const functionType = 2;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [categoryArr, setCategoryArr] = useState<Array<String>>([]);

  const {
    term,
    business,
    subTotalTime,
    subTotalDay,
    holidaySubtotalItem,
    setSubTotalTime,
    setSubTotalDay,
    setHolidaySubtotalItem,
    setAttendHolidays,
  } = useGetStampSubTotal(
    targetDateFrom,
    targetDateTo,
    staffCode,
    selectOrgCode,
    attendFlag,
    isRefreshStampSubTotal,
    setIsRefreshStampSubTotal
  );
  const {
    paidHolidayGrantData,
    specialHolidayGrantList,
    substituteHolidayUsingStatusList,
    transferHolidayUsingStatusList,
  } = useGetHolidayUsingStatus(
    targetDateFrom,
    targetDateTo,
    staffCode,
    isRefreshHolidayUsingStatus,
    setIsRefreshHolidayUsingStatus
  );
  const {
    stampItems, stampBusiness, stampTerm, openDeleteModal, setOpenDeleteModal, deletePublicHoliday, getCustomDropdownMenuButtons
  } = useGetStampList(
    targetDateFrom,
    targetDateTo,
    staffCode,
    attendFlag,
    selectOrgCode,
    setToastMessage,
    setToastModalOpen,
    setSubTotalTime,
    setSubTotalDay,
    setHolidaySubtotalItem,
    setAttendHolidays,
    setStaffList,
    setIsLoading,
    isRefreshStampList,
    setIsRefreshStampList,
    setIsRefreshStampSubTotal,
    setIsRefreshHolidayUsingStatus
  );

  const { displayActualStampingFlag } = useGetAttendSetting();

  function timeDisp(time: any) {
    if (time === 0 || !time) {
      return '-';
    }
    return time;
  }

  function countHolidayColspan(
    distinctionHoliday: any,
    attendHolidayList: any,
  ) {
    // 法定休日 + 所定休日 or 休日
    let result = distinctionHoliday ? 2 : 1;
    // 業務数
    result += attendHolidayList ? attendHolidayList.length : 0;
    // 遅刻 + 早退
    result += 2;
    return result;
  }

  const styles = {
    label: css({
      flexBasis: isMobileOnly ? '-120px' : '100px',
      marginLeft: isMobileOnly ? '-320px' : '',
    }),
    flexbox: css({
      marginLeft: isMobileOnly ? '70px' : '0px',
    }),
    textMessage: css({
      color: 'black',
    }),
  };

  // Check prototype role staff
  const  roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === selectOrgCode);

  const checkUserRole = selectOrgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox
          direction={isMobileOnly ? 'column' : 'row'}
          alignItems="center"
          customStyle={styles.flexbox}
        >
          <div css={styles.label}>
            <FormLabel label="期間" />
          </div>
          <FlexBoxItem>
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label=""
              isFullWidth={true}
              date={targetDateFrom}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDateFrom(date);
                }
              }}
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <span style={{ margin: '0 30px' }}>～</span>
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label=""
              isFullWidth={true}
              date={targetDateTo}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDateTo(date);
                }
              }}
            />
          </FlexBoxItem>
        </FlexBox>

        <div className="wrap-attend-stamp-list">
          <OrganizationFilteredTree
            functionType={functionType}
            staffCode={loginStaffCode}
            targetdayForm={targetDateFrom}
            targetdayTo={targetDateTo}
            orgLabel="組織名"
            initOrgValue={String(selectOrgCode)}
            orgCallback={(args: string | Array<string>) => {
              setStaffCode(oldOrgCode === String(args) ? tmpStaffCode : '');
              setOldSelectOrgCode(selectOrgCode);
              setSelectOrgCode(String(args));
            }}
            orgCategoryCallback={(args: any) => {
              setCategoryArr(args);
            }}
            initCatagory={(args: string) => {}}
          />
          <FormField>
            <FlexBoxItem width="100px">
              <FormLabel label="スタッフ" />
            </FlexBoxItem>
            <FlexBoxItem basis="45%">
              <VerticalLabelSelectForm
                label=""
                name="staffCode"
                value={staffCode}
                setValue={(v) => {
                  setStaffCode(v);
                  setTmpStaffCode(v);
                }}
                options={staffList}
                placeholder="スタッフを選択してください"
              />
            </FlexBoxItem>
          </FormField>

          <Row>&nbsp;</Row>
          <Row>
            <Col xs={12}>
              <div className="panel panel-default">
                <div className="panel-heading">日別データ</div>
                <div className="panel-body">
                  <Row>
                    <Col xs={12}>
                      {stampItems && stampItems.length && staffCode !== '' ? (
                        <AttendStampTable
                          eventScroll={eventScroll}
                          business={stampBusiness || []}
                          term={stampTerm}
                          targetDateFrom={targetDateFrom}
                          targetDateTo={targetDateTo}
                          selectOrgCode={selectOrgCode}
                          attendFlag={attendFlag}
                          stampItems={stampItems}
                          emp={employment}
                          staffCode={staffCode}
                          staffList={staffList}
                          role={checkUserRole}
                          displayActualStampingFlag={displayActualStampingFlag}
                          getCustomDropdownMenuButtons={getCustomDropdownMenuButtons}
                        />
                      ) : (
                        <AttendStampTableNoData
                          eventScroll={eventScroll}
                          business={stampBusiness || []}
                          term={stampTerm || []}
                          targetDateFrom={targetDateFrom}
                          targetDateTo={targetDateTo}
                          selectOrgCode={selectOrgCode}
                          attendFlag={attendFlag}
                          stampItems={stampItems}
                          emp={employment}
                          staffCode={staffCode}
                          staffList={staffList}
                          role={checkUserRole}
                          displayActualStampingFlag={displayActualStampingFlag}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="panel panel-default">
                <div className="panel-heading">集計時間データ</div>
                <div className="panel-body">
                  <Row>
                    <Col xs={12}>
                      <MonthHourTable
                        employment={employment}
                        business={business || []}
                        term={term || []}
                        timeDisp={timeDisp}
                        subTotalTime={subTotalTime}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="panel panel-default">
                <div className="panel-heading">集計日数データ</div>
                <div className="panel-body">
                  <Row>
                    <Col xs={12}>
                      <MonthDayTable
                        subTotalDay={subTotalDay}
                        holidaySubtotalItem={holidaySubtotalItem}
                        countHolidayColspan={countHolidayColspan}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          {employment.autoPaidManageFunc && attendFlag === '0' ? (
            <Row>
              <Col xs={12}>
                <div className="panel panel-default">
                  <div className="panel-heading">有給使用状況(現時点)</div>
                  <div className="panel-body">
                    <Row>
                      <Col xs={12}>
                        <PaidHolidayGranTable
                          paidHolidayGrantData={paidHolidayGrantData}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            ''
          )}
          {specialHolidayGrantList.length > 0 && attendFlag === '0' && (
            <Row>
              <Col xs={12}>
                <div className="panel panel-default">
                  <div className="panel-heading">特休使用状況</div>
                  <div className="panel-body">
                    <Row>
                      <Col xs={12}>
                        <SpecialHolidayGrantTable
                          specialHolidayGrantList={specialHolidayGrantList}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          {substituteHolidayUsingStatusList.length > 0 && attendFlag === '0' && (
            <Row>
              <Col xs={12}>
                <div className="panel panel-default">
                  <div className="panel-heading">代休使用状況</div>
                  <div className="panel-body">
                    <Row>
                      <Col xs={12}>
                        <SubstituteHolidayTable
                          substituteHolidayUsingStatusList={
                            substituteHolidayUsingStatusList
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          {employment.useTransferApplication
          && transferHolidayUsingStatusList.length > 0
          && attendFlag === '0' ? (
            <Row>
              <Col xs={12}>
                <div className="panel panel-default">
                  <div className="panel-heading">振替状況</div>
                  <div className="panel-body">
                    <Row>
                      <Col xs={12}>
                        <TransferHolidayTable
                          transferHolidayUsingStatusList={
                            transferHolidayUsingStatusList
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            ) : (
              ''
            )}
          <Container>
            <Row>
              <FlexBox
                alignItems="center"
                customStyle={css({
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '20px',
                })}
              >
                <FlexBoxItem>
                  <PrimaryButton
                    onClick={() => history.push(
                        sessionStorage.getItem(
                          'headquartersFinal.monthlyList.returnDestination',
                        )! || sessionStorage.getItem('attendStampList.returnDestination')!,
                    )}
                    ghost={false}
                    text="戻る"
                  />
                </FlexBoxItem>
              </FlexBox>
            </Row>
          </Container>
          <ToastModal
            open={toastModalOpen}
            closeHandler={() => setToastModalOpen(false)}
            title={toastMessage}
          />
        </div>
        {/* Confirm delete modal */}
        <Modal
          open={openDeleteModal}
          closeHandler={() => {
            setOpenDeleteModal(false);
          }}
          title="公休削除"
          note="公休を削除します。よろしいですか？"
          submitText="削除する"
          onSubmit={() => {
            deletePublicHoliday();
            setOpenDeleteModal(false);
          }}
          closeText="キャンセル"
        />
      </FormContents>
    </BlockUI>
  );
};

export default AttendStampForm;
