import React from 'react';
import moment from 'moment';

const TransferHolidayTable: React.FC<{
    transferHolidayUsingStatusList: any
}> = ({
  transferHolidayUsingStatusList,
}) => (
  <div className="table-responsive">
    <table id="transferHolidayList" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%' }}>
      <thead>
        <tr role="row">
          <th style={{ width: '9%' }}>振替出勤日</th>
          <th style={{ width: '9%' }}>振替休日</th>
        </tr>
      </thead>
      <tbody>
        {
        transferHolidayUsingStatusList && transferHolidayUsingStatusList.map((transfer: any, index: any) => (
          <tr role="row" key={String(index)}>
            <td>{transfer.transferWorkDate === '' ? '' : moment(transfer.transferWorkDate).format('YYYY年MM月DD日')}</td>
            <td>{transfer.transferHolidayDate === '' ? '' : moment(transfer.transferHolidayDate).format('YYYY年MM月DD日')}</td>
          </tr>
        ))
      }
      </tbody>
    </table>
  </div>
);
export default TransferHolidayTable;
