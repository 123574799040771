/* eslint-disable react/destructuring-assignment */

/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import FormContents from 'components/atoms/Form/FormContents';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import DatePickerForm from 'components/molecules/DatePickerForm';
import Icon from 'components/atoms/Icon';

import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import BlockUI from 'components/molecules/BlockUi';
import BodyText from 'components/atoms/BodyText';
import IconLabelButton from 'components/molecules/IconLabelButton';

import moment from 'moment';
import productColor, { grayScale } from 'components/styles';

import SalesTableLayout from './salesTableLayout';

import useSalesAdvertsingPlanReport, { salesAdvertsingPlanReportContext } from './hook';

const SalesAdvertisingPlanReportLayout = () => {
  const context = useSalesAdvertsingPlanReport();
  const functionType = 3;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  return (
    <salesAdvertsingPlanReportContext.Provider value={context}>
      <FormContents>
        <div>
          <FlexBox>
            <FlexBoxItem width="100px">
              <FormLabel
                label="対象期間"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月"
                label=""
                date={context.targetDateFrom}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    context.setTargetDateFrom(date);
                    context.setTargetDateTo(moment(date).clone().endOf('month').toDate());
                  }
                }}
                showMonthYearPicker={true}
                isFullWidth={true}
              />
            </FlexBoxItem>
          </FlexBox>
        </div>

        <div>
          <OrganizationFilteredTree
            functionType={functionType}
            staffCode={staffCode}
            targetdayForm={context.targetDateFrom}
            targetdayTo={context.targetDateTo}
            addAllItem={true}
            initOrgValue={String(context.orgCode)}
            orgLabel="店舗名"
            orgCallback={(args: any) => {
              context.setOrgCode(String(args));
            }}
            orgCategoryCallback={
              (args: Array<string>) => {
                context.setCategoryAry(args);
              }
            }
            orgNameCallback={(arg: any) => {
              context.setorgName(String(arg));
              sessionStorage.setItem('sessionOrgNameSelected', arg);
            }}

          />
        </div>

        <div>
          <div style={{ marginTop: '30px' }}>
            <div className="row">
              <BlockUI blocking={context.isLoading}>
                <FlexBox>
                  <FlexBoxItem>
                    <IconLabelButton
                      onClick={() => context.handleOnDownloadCsv("広告プラン別売上.csv")}
                      iconType="download"
                      text=" CSV出力"
                    />
                  </FlexBoxItem>
                  <FlexBoxItem>
                    <IconLabelButton
                      onClick={() => context.handleOnDownloadExcel("広告プラン別売上.xlsx")}
                      iconType="download"
                      text=" EXCEL出力"
                    />
                  </FlexBoxItem>
                </FlexBox>

                <SalesTableLayout />

              </BlockUI>
            </div>
          </div>
        </div>
      </FormContents>
    </salesAdvertsingPlanReportContext.Provider>
  );
};

export default SalesAdvertisingPlanReportLayout;
