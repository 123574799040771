import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

export const getList = async ():Promise<any> => {
  const params = {};
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/advertisingMediaMaster/${companyCode}`, params);
  return response.data;
};

export const getInfo = async (advertisingMediaMstCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    advertisingMediaMstCode,
  };
  const reponse = await ApiClient.get(`/v1/advertisingMediaMaster/${companyCode}`, params);
  return reponse.data;
}

export const deleteById = async (advertisingMediaMstCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    advertisingMediaMstCode,
  };
  const reponse = await ApiClient.delete(`/v1/advertisingMedia/delete/${companyCode}`, params);
  return reponse.data;
};

export const AddOrUpdateAdvertising = async (isCreate: number, isConfirmed: number,
  advertising: any) : Promise<any> => {
  const prams = {
    isCreate,
    isConfirmed,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const reponse = await ApiClient.post(`/v1/advertisingMedia/${companyCode}`, prams, advertising);
  return reponse.data;
};

export const downloadCSV = async (
  companyCode: string,
): Promise<void> => {
  const params = {};
  await ApiClient.downloadCsv(`/v2/advertisingMedia/csv/${companyCode}`, params, getDownloadCsvFileName('広告媒体情報_'));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

/**
 * CSVファイルインポート
 */
 export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v2/advertisingMedia/csv/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export type Error = {
  error: string;
  defaultMessage: string;
}

export default getList;
