import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import EmploymentListTable from 'components/organismos/employment/EmploymentListTable/EmploymentListTable';

import BlockUI from 'components/molecules/BlockUi';
import { useEmploymentList } from './hooks';

const EmploymentsPage: React.FC = () => {
  const {
    employmentList,
    fetchData,
    allEmployment,
    toggleAllEmployment,
    applyDate,
    // applyDateStr,
    setApplyDate,
    blocking,
  } = useEmploymentList();

  return (
    <SidebarTemplate
      pageTitle="雇用形態マスタ"
    >
      <BlockUI blocking={blocking}> 
        <EmploymentListTable
          employmentList={employmentList}
          fetchData={fetchData}
          allEmployment={allEmployment}
          toggleAllEmployment={toggleAllEmployment}
          applyDate={applyDate}
          setApplyDate={setApplyDate}
        />
      </BlockUI>
    </SidebarTemplate>
  );
};

export default EmploymentsPage;
