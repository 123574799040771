/** @jsx jsx */
import React from 'react';
import Table from 'components/molecules/Table';
import DatePickerForm from 'components/molecules/DatePickerForm';
import Checkbox from 'components/molecules/Checkbox';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import TextInput from 'components/atoms/Form/TextInput';
import moment from 'moment';
import { css, jsx } from '@emotion/core';
import { OptionType } from 'components/atoms/Select';
import { TCompanyBank } from '../../../type/regist';
import styles from './styles';

const SpecificBankLayout: React.FC<{
  renderList: TCompanyBank[],
  orgTreeBank: OptionType[],
  handleChangeTrap: {
    set(obj: TCompanyBank, prop: string, value: any): boolean;
  };
  allDateChangeStatus: boolean;
  handleChangeAllDateStatus: any;
  currentBank: string
}> = ({
  renderList,
  orgTreeBank,
  handleChangeTrap,
  allDateChangeStatus,
  handleChangeAllDateStatus,
  currentBank,
}) => {
    const formatter = new Intl.NumberFormat('ja-JP', {
      minimumFractionDigits: 0, maximumFractionDigits: 2,
    });

    const numberFormater = (num: any) => {
      if (typeof num === 'undefined' || isNaN(num)) {
        return num;
      }
      const tmp = String(num).split('.');
      if (tmp[1]) {
        let fixed = 3;
        if (tmp[1].length < 3) {
          fixed = tmp[1].length;
        }
        return formatter.format(Number(parseFloat(num).toFixed(fixed)));
      }
      return formatter.format(num);
    };

    return (
      <Table customStyle={styles.table}>
        <thead>
          <tr>
            <Table.HeaderCell customStyle={styles.recordNo}>ﾚｺｰﾄﾞNo</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.store}>店舗</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.cardNo}>登録番号</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.notLinked}>非連動</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.depositAmount}>入金額</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.withdrawalAmount}>出金額</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.businessDayStart}>営業日（開始）</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.businessDayEnd}>営業日（終了）</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.dateChangeStatus}>
              <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
              }}
              >
                <div>一括</div>
                <div style={{ height: 19 }}>
                  <Checkbox
                    id={'allDate'.concat(String(currentBank || ''))}
                    name="allDateChangeStatus"
                    value={String(allDateChangeStatus)}
                    checked={allDateChangeStatus}
                    onChange={(e) => {
                      handleChangeAllDateStatus(e, currentBank);
                    }}
                  />
                </div>
              </div>
            </Table.HeaderCell>
            {' '}
            <Table.HeaderCell customStyle={styles.remarks}>備考</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.reasonForChange}>変更理由</Table.HeaderCell>
          </tr>
        </thead>
        <tbody>
          {
            renderList && renderList.map((obj: TCompanyBank, index: number) => {
              const item = new Proxy(obj, handleChangeTrap);
              return (
                <tr
                  key={String(index)}
                  css={css({
                    '& :is(td:nth-of-type(1), td:nth-of-type(2), td:nth-of-type(3), td:nth-of-type(4))': {
                      zIndex: renderList.length - index, overflow: 'unset',
                    },
                  })}
                >
                  <Table.Cell customStyle={styles.recordNoStickyBody}>{item.recordNo}</Table.Cell>
                  <Table.Cell customStyle={styles.storeStickyBody}>
                    {
                      item.depositWithdrawalType !== '2' && (
                        <VerticalLabelSelectForm
                          name="orgCode"
                          value={item.orgCode}
                          setValue={(val) => { item.orgCode = val; }}
                          options={orgTreeBank}
                          disable={!item.changeReason}
                          customizeSelectPropStyle={
                            {
                              menuPortal: (oldStyles: any) => ({ ...oldStyles, zIndex: 9999 }),
                            }
                          }
                          createMenuPortal={true}
                          removePaddingContainer={true}
                        />
                      )
                    }
                  </Table.Cell>
                  <Table.Cell customStyle={styles.cardNoStickyBody}>
                    {item.registrationNo}
                  </Table.Cell>
                  <Table.Cell customStyle={styles.notLinkedStickyBody}>
                    <Checkbox
                      id={'isStartTimeNextDay'.concat(String(index))} // Unique ID
                      name="isStartTimeNextDay"
                      // value={String(formik.values.isStartTimeNextDay)}
                      value=""
                      // checked={Boolean(formik.values.isStartTimeNextDay)}
                      checked={item.accountingNonLinkFlg}
                      // onChange={formik.handleChange}
                      onChange={(e) => { item.accountingNonLinkFlg = e.target.checked; }}
                    />
                  </Table.Cell>
                  <Table.Cell customStyle={styles.depositAmountNo}>
                    {numberFormater(item.depositAmount)}
                  </Table.Cell>
                  <Table.Cell customStyle={styles.withdrawalAmountNo}>
                    {numberFormater(item.withdrawalAmount)}
                  </Table.Cell>
                  <Table.Cell customStyle={css({ '&>div>div': { marginTop: '0px' } })}>
                    <DatePickerForm
                      label=""
                      date={moment(item.businessStartDate).toDate()}
                      dateFormat="yyyy年MM月dd日"
                      changeDate={(date: Date) => {
                        if (date !== null) {
                          item.businessStartDate = moment(date).format('YYYY/MM/DD');
                        }
                      }}
                      isFullWidth={true}
                    />
                  </Table.Cell>
                  <Table.Cell customStyle={css({ '&>div>div': { marginTop: '0px' } })}>
                    <DatePickerForm
                      label=""
                      date={moment(item.businessEndDate).toDate()}
                      dateFormat="yyyy年MM月dd日"
                      changeDate={(date: Date) => {
                        if (date !== null) {
                          item.businessEndDate = moment(date).format('YYYY/MM/DD');
                        }
                      }}
                      isFullWidth={true}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <div style={{
                      height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 7,
                    }}
                    >
                      <Checkbox
                        id={'dateChangeStatus-id'.concat(String(index))}
                        name="dateChangeStatus"
                        value={String(item.dateChangeStatus)}
                        checked={item.dateChangeStatus || false}
                        onChange={(e) => {
                          item.dateChangeStatus = e.target.checked;
                        }}
                      />
                    </div>

                  </Table.Cell>
                  <Table.Cell customStyle={styles.remarksBodyTextLeft}>
                    <TextInput
                      name="remark"
                      value={item.remark}
                      onChange={(e) => { item.remark = e.target.value; }}
                    />
                  </Table.Cell>
                  <Table.Cell customStyle={styles.reasonForChangeNoSticky}>
                    <TextInput
                      name="changeReason"
                      value={item.changeReason || ''}
                      onChange={(e) => { item.changeReason = e.target.value; }}
                    />
                  </Table.Cell>
                </tr>
              );
            })
          }
        </tbody>
      </Table>
    );
  };
export default SpecificBankLayout;
