
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AdvertisingMediaImportForm from 'components/organismos/master/sales/AdvertisingMediaImportForm';

const AdvertisingMediaImportPage = () => (
  <SidebarTemplate pageTitle="広告媒体インポート">
    <AdvertisingMediaImportForm />
  </SidebarTemplate>
);

export default AdvertisingMediaImportPage;
