import ApiClient from 'api/ApiClient';

export const apiGetSlitMonthlyBalanceTable = async (
    orgCode: string,
    monthlyStartDate: string,
    monthlyEndDate: string,
    categoryAry: any,
)
    : Promise<any> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const params = {
        orgCode,
        monthlyStartDate,
        monthlyEndDate,
        loginStaffCode,
        functionType: 3,
    };

    let appendUrl = '';
    if (Array.isArray(categoryAry)) {
        categoryAry.forEach((cateArr: any) => {
            appendUrl += `&categoryAry=${cateArr.join(',')}`;
        });
    }

    const response = await ApiClient.get(`/v1/sales/list/standard/monthly/balancetable/${companyCode}?${appendUrl}`, params);
    return response.data;
};

export const downloadIncomeAndExpenditureStoreCsv = async (incomeAndExpenditureStore: any, fileName: any): Promise<void> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    await ApiClient.downloadCsvPost(`/v1/reportExportFile/csv/${companyCode}`, {}, incomeAndExpenditureStore, fileName);
};

export const downloadIncomeAndExpenditureStoreExcel = async (incomeAndExpenditureStore: any, fileName: any): Promise<void> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    await ApiClient.downloadExcelPost(`/v1/reportExportFile/excel/${companyCode}`, {}, incomeAndExpenditureStore, fileName);
};