import React, { useEffect, useState } from 'react';

import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { Link, useParams } from 'react-router-dom';
import ConfirmModal from 'components/organismos/ConfirmModal';
import FormContents from 'components/atoms/Form/FormContents';
import { useExecutiveAddForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const ExecutiveAddForm: React.FC = () => {
  const { executiveId } = useParams();
  const {
    formik, confirmModalOpen, closeConfirmModal,
  } = useExecutiveAddForm(executiveId);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  return (
    <div>
      <FormContents>
        <p>役職マスタを登録します。役職コードと役職名を入力してください。</p>
        <form>
          <FormField>
            <TextForm
              name="executiveCode"
              label="役職コード"
              value={formik.values.executiveCode}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.executiveCode}
            />
          </FormField>
          <FormField>
            <TextForm
              name="executiveName"
              label="役職名"
              value={formik.values.executiveName}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.executiveName}
            />
          </FormField>
          <FormSubmitArea>
            {
              roleScreen && roleScreen.editable === 1 && (
                <div style={{ marginRight: '12px' }}>
                  <Button
                    text={executiveId ? '更新' : '役職マスタを登録'}
                    onClick={formik.handleSubmit}
                  />
                </div>
              )
            }
            <Link to="/executive">
              <Button
                text="戻る"
                ghost={true}
              />
            </Link>
          </FormSubmitArea>
        </form>
      </FormContents>

      <ConfirmModal
        title="確認メッセージ"
        content={executiveId ? '更新します。よろしいですか？' : '登録します。よろしいですか？'}
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.submitForm}
        items={[]}
      />
    </div>
  );
};

export default ExecutiveAddForm;
