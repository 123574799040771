import moment from 'moment';

import {
  Organization, EditSpecial, Manager, Special, EditManager,
  OrganizationBasic, OrganizationManagerBasic, OrganizationSalaryBasic,
} from 'api/organization';

export enum OrganizationCategory {
  COMPANY = 0,
  DEPARTMENT = 1,
  STORE = 2,
}
export enum OrganizationCategoryLabel {
  '会社' = 0,
  '部署' = 1,
  '店舗' = 2,
}

export type StoreState = 'open' | 'close';

export default class OrganizationDomain {
  private rawData: Organization

  constructor(rawData: Organization) {
    // 日付のフォーマットを変換しておく
    this.rawData = {
      ...rawData,
      applyStartDate: convertDateToStringYYYYMMDD(rawData.applyStartDate),
      // applyEndDate: rawData.applyEndDate && convertDateToStringYYYYMMDD(rawData.applyEndDate),
      // createDate: rawData.createDate && convertDateToStringYYYYMMDD(rawData.createDate),
    };
  }

  static generateInitial(): OrganizationDomain {
    return new OrganizationDomain({
      orgCode: '',
      applyStartDate: moment().format('YYYY/MM/DD'),
      orgName: '',
      orgNameKana: '',
      category: 2,
      managerSetting: undefined,
      orgSpecialList: undefined,
      closeFlag: false,
      areaId: '',
      prefectureCode: '',
      employmentId: '',
      workingDaysId: '',
      businessCategoryId: '',
      posCode: '',
      infomartLinkCode: '',
      numberOfSeat: 0,
      floorSpace: 0,

    });
  }

  getRawData(): Organization {
    return this.rawData;
  }

  getBasicPostData(): OrganizationBasic {
    return {
      orgCode: this.rawData.orgCode,
      applyStartDate: this.rawData.applyStartDate,
      orgName: this.rawData.orgName,
      orgNameKana: this.rawData.orgNameKana,
      category: this.rawData.category,
      closeFlag: this.rawData.closeFlag,
      areaId: this.rawData.areaId,
      prefectureCode: this.rawData.prefectureCode,
      employmentId: this.rawData.employmentId,
      workingDaysId: this.rawData.workingDaysId,
      businessCategoryId: this.rawData.businessCategoryId,
      posCode: this.rawData.posCode,
      infomartLinkCode: this.rawData.infomartLinkCode,
      numberOfSeat: this.rawData.numberOfSeat,
      floorSpace: this.rawData.floorSpace,
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      organizationCategoryMstDetilId: this.rawData.organizationCategoryMstDetilId,

    };
  }

  getManagersPostData(
    managerList: Array<Manager>, seniorManagerList: Array<Manager>,
  ): OrganizationManagerBasic { // TODo
    // const postRawData = this.getBasicPostData();
    return {
      // ...postRawData,
      orgCode: this.rawData.orgCode,
      applyStartDate: this.rawData.applyStartDate,
      orgName: this.rawData.orgName,
      orgNameKana: this.rawData.orgNameKana,
      category: this.rawData.category,
      closeFlag: this.rawData.closeFlag,
      areaId: this.rawData.areaId,
      prefectureCode: this.rawData.prefectureCode,
      employmentId: this.rawData.employmentId,
      workingDaysId: this.rawData.workingDaysId,
      businessCategoryId: this.rawData.businessCategoryId,
      posCode: this.rawData.posCode,
      infomartLinkCode: this.rawData.infomartLinkCode,
      numberOfSeat: this.rawData.numberOfSeat,
      floorSpace: this.rawData.floorSpace,
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      ...OrganizationDomain.convertToEditManager(this.orgCode, managerList, seniorManagerList),
    };
  }

  getSpecialSalaryPostData(
    specialSalary: EditSpecial,
  ): OrganizationSalaryBasic {
    return {
      orgCode: this.rawData.orgCode,
      applyStartDate: this.rawData.applyStartDate,
      orgName: this.rawData.orgName,
      orgNameKana: this.rawData.orgNameKana,
      category: this.rawData.category,
      closeFlag: this.rawData.closeFlag,
      areaId: this.rawData.areaId,
      prefectureCode: this.rawData.prefectureCode,
      employmentId: this.rawData.employmentId,
      workingDaysId: this.rawData.workingDaysId,
      businessCategoryId: this.rawData.businessCategoryId,
      posCode: this.rawData.posCode,
      infomartLinkCode: this.rawData.infomartLinkCode,
      numberOfSeat: this.rawData.numberOfSeat,
      floorSpace: this.rawData.floorSpace,
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      editSpecial: specialSalary,
    };
  }

  clearOrgSpecialList() {
    this.rawData.orgSpecialList = [];
  }

  private getPostData(): Organization {
    // nullを除去する
    const postRawData: Organization = JSON.parse(
      JSON.stringify(this.rawData, (key, value) => (value === null ? '' : value)),
    );

    const editManagerAndSeniorManager = OrganizationDomain.convertToEditManager(
      this.orgCode, this.rawData.managerList || [], this.rawData.seniorManagerList || [],
    );
    return {
      ...postRawData,
      // 送らない項目
      // managerList: undefined,
      // seniorManagerList: undefined,
      // orgSpecialList: undefined,
      // updateDate: undefined,
      // 変換して送る項目
      ...editManagerAndSeniorManager,
      editSpecial: OrganizationDomain.convertToEditSpecial(this.rawData.orgSpecialList),
    };
  }

  static convertToEditManager(
    orgCode: string, managerList: Array<Manager>, seniorManagerList: Array<Manager>,
  ): { editManagerList: Array<EditManager>, editSeniorManagerList: Array<EditManager> } {
    return {
      editManagerList: managerList.map((manager): EditManager => ({
        orgCode,
        staffCode: manager.staffCode,
        managerType: '0',
        hireDateStr: manager.hireDate ? moment(manager.hireDate).format('YYYY/MM/DD') : '',
        retirementDateStr: manager.retirementDate ? moment(manager.retirementDate).format('YYYY/MM/DD') : '',
        startDateStr: moment(manager.startDate).format('YYYY/MM/DD'),
        endDateStr: manager.endDate ? moment(manager.endDate).format('YYYY/MM/DD') : '',
      })),
      editSeniorManagerList: seniorManagerList.map((manager): EditManager => ({
        orgCode,
        staffCode: manager.staffCode,
        managerType: '1',
        hireDateStr: manager.hireDate ? moment(manager.hireDate).format('YYYY/MM/DD') : '',
        retirementDateStr: manager.retirementDate ? moment(manager.retirementDate).format('YYYY/MM/DD') : '',
        startDateStr: moment(manager.startDate).format('YYYY/MM/DD'),
        endDateStr: manager.endDate ? moment(manager.endDate).format('YYYY/MM/DD') : '',
      })),
    };
  }

  static convertToEditSpecial(orgSpecialList?: Array<Special> | null): Special | undefined {
    const orgSpecial = orgSpecialList?.[0];
    return orgSpecial && {
      applyStartMonthStr: orgSpecial.applyStartMonthStr,
      addSalary: orgSpecial.addSalary,
      specialSalaryPatternId: orgSpecial.specialSalaryPatternId,
    };
  }

  get organizationCategoryMstDetilId(): Array<string> | undefined {
    return this.rawData.organizationCategoryMstDetilId;
  }

  set organizationCategoryMstDetilId(organizationCategoryMstDetilId: Array<string> | undefined) {
    this.rawData.organizationCategoryMstDetilId = organizationCategoryMstDetilId;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get orgNameKana(): string {
    return this.rawData.orgNameKana;
  }

  set orgNameKana(orgNameKana: string) {
    this.rawData.orgNameKana = orgNameKana;
  }

  get category(): number {
    return this.rawData.category;
  }

  get categoryLabel(): string {
    return OrganizationCategoryLabel[this.rawData.category];
  }

  set category(category: number) {
    this.rawData.category = Number(category);
  }

  get managerSetting(): any {
    return this.rawData.managerSetting;
  }

  get orgSpecialList(): any {
    return this.rawData.orgSpecialList;
  }

  get orgCategoryList(): any {
    return this.rawData.orgCategoryList;
  }

  get applyStartDate(): Date {
    return moment(this.rawData.applyStartDate).toDate();
  }

  get applyStartDateText(): string {
    return this.rawData.applyStartDate;
  }

  set applyStartDate(date: Date) {
    this.rawData.applyStartDate = moment(date).format('YYYY/MM/DD');
  }

  // get applyEndDate(): Date {
  //   return moment(this.rawData.applyEndDate).toDate();
  // }

  // set applyEndDate(date: Date) {
  //   this.rawData.applyEndDate = moment(date).format('YYYY/MM/DD');
  // }

  // get dispStartHour(): number {
  //   return this.rawData.dispStartHour;
  // }

  // set dispStartHour(dispStartHour: number) {
  //   this.rawData.dispStartHour = dispStartHour;
  // }

  // get dispHour(): number {
  //   return this.rawData.dispHour;
  // }

  // set dispHour(dispHour: number) {
  //   this.rawData.dispHour = dispHour;
  // }

  get closeFlag(): StoreState {
    return this.rawData.closeFlag ? 'close' : 'open';
  }

  set closeFlag(closeFlag: StoreState) {
    this.rawData.closeFlag = closeFlag === 'close';
  }

  get closeFlagLabel(): string {
    return this.rawData.closeFlag ? '閉店' : '開店';
  }

  get areaId(): string {
    return this.rawData.areaId || '';
  }

  set areaId(areaId: string) {
    this.rawData.areaId = areaId;
  }

  get prefectureCode(): string {
    return this.rawData.prefectureCode || '';
  }

  set prefectureCode(prefectureCode: string) {
    this.rawData.prefectureCode = prefectureCode;
  }

  get employmentId(): string {
    return this.rawData.employmentId || '';
  }

  set employmentId(employmentId: string) {
    this.rawData.employmentId = employmentId;
  }

  get workingDaysId(): string {
    return this.rawData.workingDaysId || '';
  }

  set workingDaysId(workingDaysId: string) {
    this.rawData.workingDaysId = workingDaysId;
  }

  get businessCategoryId(): string {
    return this.rawData.businessCategoryId || '';
  }

  set businessCategoryId(businessCategoryId: string) {
    this.rawData.businessCategoryId = businessCategoryId;
  }

  get posCode(): string {
    return this.rawData.posCode || '';
  }

  set posCode(posCode: string) {
    this.rawData.posCode = posCode;
  }

  get infomartLinkCode(): string {
    return this.rawData.infomartLinkCode || '';
  }

  set infomartLinkCode(infomartLinkCode: string) {
    this.rawData.infomartLinkCode = infomartLinkCode;
  }

  get numberOfSeat(): string {
    return this.rawData.numberOfSeat ? String(this.rawData.numberOfSeat) : '';
  }

  set numberOfSeat(numberOfSeat: string) {
    this.rawData.numberOfSeat = Number(numberOfSeat);
  }

  get floorSpace(): string {
    return this.rawData.floorSpace ? String(this.rawData.floorSpace) : '';
  }

  set floorSpace(floorSpace: string) {
    this.rawData.floorSpace = Number(floorSpace);
  }
}

const convertDateToStringYYYYMMDD = (dateStr?: string) => {
  if (!dateStr) {
    return '';
  }
  return moment(dateStr).format('YYYY/MM/DD');
};
