/* eslint-disable no-nested-ternary */
import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import FormLabel from 'components/atoms/Form/FormLabel';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import MultipleCheckboxForm from 'components/molecules/MultipleCheckboxForm';
import HourInput from 'components/atoms/Form/HourInput';
import FlexBox from 'components/atoms/FlexBox';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import useWorkingHoursOptions from 'hooks/useWorkingHoursOptions';

const EmploymentOvertimeSettings: React.FC<{
  formik: any
}> = ({
  formik,
}) => {
  const workingHoursOptions = useWorkingHoursOptions();
  const extraOverTimeItems = [];
  const [isNumber] = React.useState(3);
  for (let i = 1; i <= isNumber;) {
    extraOverTimeItems.push(i);
    i += 1;
  }

  return (
    <>
      <FormTitle
        title="残業設定"
      />
      <div>
        <FormField>
          <RadioSelectForm
            label="法定内残業時間の扱い"
            name="handleLegalInOvertime"
            items={[
              {
                label: '全て法定外残業として計上する',
                value: '0',
              },
              {
                label: '法定内残業と法定外残業で区別して計上する',
                value: '1',
              },
            ]}
            value={String(formik.values.handleLegalInOvertime)}
            setValue={formik.handleChange}
            note="※日の法定時間は8時間、週の法定時間は40時間となります。"
          />
        </FormField>

        <div style={String(formik.values.workSystem) === '1' ? {} : { display: 'none' }}>
          <FormField>
            <MultipleCheckboxForm
              name="overtimeCalculationSetting"
              label="残業計算設定"
              items={[
                {
                  id: 'calcOverTimeInMonth',
                  label: '月単位で計算',
                  value: formik.values.calcOverTimeInMonth,
                },
                {
                  id: 'calcOverTimeInWeek',
                  label: '週単位で計算',
                  value: formik.values.calcOverTimeInWeek,
                },
                {
                  id: 'calcOverTimeInDay',
                  label: '日単位で計算',
                  value: formik.values.calcOverTimeInDay,
                },
              ]}
              setValue={(e) => formik.setFieldValue(String(e.target.id), !(e.target.value === 'true'))}
            />
          </FormField>

          <div style={String(formik.values.calcOverTimeInMonth) === 'true' ? {} : { display: 'none' }}>
            <VerticalLabelSelectForm
              label="月の所定労働時間設定"
              name="workingHoursId"
              value={String(formik.values.workingHoursId)}
              setValue={(val: string) => formik.setFieldValue('workingHoursId', val)}
              options={workingHoursOptions}
            />
          </div>

          <div style={String(formik.values.calcOverTimeInWeek) === 'true' ? {} : { display: 'none' }}>
            <VerticalLabelFormLayout
              label={(
                <div>
                  <div style={{ float: 'left' }}>
                    <FormLabel
                      label="週の所定労働時間設定"
                    />
                  </div>
                </div>
              )}
              input={(
                <VerticalLabelSelectForm
                  label=""
                  name="letShiftPredeterminedWorkTimeOfWeek"
                  value={String(formik.values.letShiftPredeterminedWorkTimeOfWeek)}
                  setValue={(val: string) => formik.setFieldValue('letShiftPredeterminedWorkTimeOfWeek', val)}
                  options={[
                    { label: '週の所定労働時間を指定する', value: '0' },
                    { label: '1週間分のシフト時間を週の所定労働時間とする', value: '1' },
                    { label: '週の所定労働時間を限度としてシフト時間を所定労働時間とする', value: '2' },
                  ]}
                />
              )}
            />

            <div style={String(formik.values.letShiftPredeterminedWorkTimeOfWeek) !== '1' ? {} : { display: 'none' }}>
              <FormField>
                <FlexBox>
                  <HourInput
                    name="prescribedWorkHourOfWeek"
                    value={String(formik.values.prescribedWorkHourOfWeek)}
                    label="時間"
                    onChange={formik.handleChange}
                    min={0}
                    max={168}
                  />
                </FlexBox>
              </FormField>
            </div>
          </div>
        </div>

        <div style={String(formik.values.workSystem) === '1' ? {} : { display: 'none' }}>
          <div style={String(formik.values.calcOverTimeInDay) === 'true' ? {} : { display: 'none' }}>
            <FormField displayBlock={true}>
              <VerticalLabelFormLayout
                label={(
                  <div>
                    <div style={{ float: 'left' }}>
                      <FormLabel
                        label="日の所定労働時間設定"
                      />
                    </div>
                  </div>
              )}
                input={(
                  <VerticalLabelSelectForm
                    label=""
                    name="letShiftPredeterminedWorkTimeOfDay"
                    value={String(formik.values.letShiftPredeterminedWorkTimeOfDay)}
                    setValue={(val: string) => formik.setFieldValue('letShiftPredeterminedWorkTimeOfDay', val)}
                    options={[
                      { label: '日の所定労働時間を指定する', value: '0' },
                      { label: 'シフト時間を日の所定労働時間とする', value: '1' },
                      { label: '日の所定労働時間を限度としてシフト時間を所定時間とする', value: '2' },
                    ]}
                  />
              )}
              />
            </FormField>

            <div style={String(formik.values.letShiftPredeterminedWorkTimeOfDay) !== '1' ? {} : { display: 'none' }}>
              <FormField>
                <FlexBox>
                  <div style={{ width: '120px' }}>
                    <HourInput
                      name="prescribedWorkHourOfDay"
                      value={String(formik.values.prescribedWorkHourOfDay)}
                      label="分"
                      onChange={formik.handleChange}
                      min={0}
                      max={1440}
                    />
                  </div>
                </FlexBox>
              </FormField>
            </div>
          </div>
        </div>

        <FormField>
          <RadioSelectForm
            label="残業申請機能"
            name="useOverTimeApplication"
            items={[
              {
                label: '使用しない',
                value: '0',
              },
              {
                label: '使用する',
                value: '1',
              },
              {
                label: 'シフト内の残業時間は認める',
                value: '2',
              },
            ]}
            value={String(formik.values.useOverTimeApplication)}
            setValue={formik.handleChange}
          />
        </FormField>

        <FormField>
          <RadioSelectForm
            label="割増残業機能"
            name="useExtraOverTime"
            items={[
              {
                label: '使用しない',
                value: 'false',
              },
              {
                label: '使用する',
                value: 'true',
              },
            ]}
            value={String(formik.values.useExtraOverTime)}
            setValue={formik.handleChange}
          />
        </FormField>
        <div style={String(formik.values.useExtraOverTime) === 'true' ? {} : { display: 'none' }}>
          {extraOverTimeItems.map((value, key) => (
            <VerticalLabelFormLayout
              label={(
                <FormLabel
                  label={`割増残業${String(value)}`}
                />
                )}
              input={(
                <FlexBox>
                  <FormLabel
                    label="残業時間が"
                  />
                  <HourInput
                    name={`extraOverTime${String(value)}`}
                    value={
                      value === 1
                        ? formik.values.extraOverTime1
                        : value === 2
                          ? formik.values.extraOverTime2
                          : formik.values.extraOverTime3
                    }
                    label="時間"
                    min={0}
                    max={743}
                    onChange={formik.handleChange}
                  />
                  <FormLabel
                    label="を超える場合に計上する"
                  />
                </FlexBox>
              )}
            />
          ))}

        </div>
      </div>
    </>
  );
};

export default EmploymentOvertimeSettings;
