import React from 'react';
import { useParams } from 'react-router-dom';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button, { PrimaryButton } from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { useBusinessAddForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const BusinessCreateForm: React.FC = () => {
  const { businessId } = useParams();
  const {
    formik, confirmModalOpen, confirmModalResendOpen, closeConfirmModal,
    closeConfirmResendModal, submitConfirmResendModal, confirmModalContent,
    history,
  } = useBusinessAddForm(businessId);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <FormContents>
      <p>
        業務を登録します。コードと業務名を入力してください。
      </p>

      <form onSubmit={formik.handleSubmit}>
        <div>

          <FormField>
            <TextForm
              name="businessCode"
              label="業務コード"
              required={true}
              value={formik.values.businessCode}
              onChange={formik.handleChange}
              errorMsg={formik.errors.businessCode}
            />
          </FormField>
          <FormField>
            <TextForm
              name="businessName"
              label="業務名"
              required={true}
              value={formik.values.businessName}
              onChange={formik.handleChange}
              errorMsg={formik.errors.businessName}
            />
          </FormField>
        </div>

        <FormSubmitArea>
          {
            roleScreen && roleScreen.editable === 1 && (
              <div style={{ marginRight: '12px' }}>
                <Button
                  text="業務を登録"
                  onClick={formik.handleSubmit}
                />
              </div>
            )
          }
          <PrimaryButton
            text="戻る"
            onClick={() => {
              history.goBack();
            }}
            ghost={true}
          />
        </FormSubmitArea>
      </form>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={businessId ? ActionType.UPDATE : ActionType.CREATE}
      />
      <ConfirmModal
        title="確認"
        content={confirmModalContent}
        open={confirmModalResendOpen}
        closeHandler={closeConfirmResendModal}
        onSubmit={submitConfirmResendModal}
        items={[]}
      />
    </FormContents>
  );
};
export default BusinessCreateForm;
