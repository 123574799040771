import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FlexBox from 'components/atoms/FlexBox';
import FormLabel from 'components/atoms/Form/FormLabel';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import { OptionType } from 'components/atoms/Select';
import FormTitle from 'components/atoms/Form/FormTitle';
import { isMobileOnly } from 'react-device-detect';

const OrganizationCategorySelect: React.FC<{
  optionsList: Array<any>;
  values: Array<any>;
  setValues: (args: any) => void;
  label: string | undefined;
  required?: boolean | undefined;
  isVertical?: boolean;
  isMulti?: boolean;
  isSplitted?: boolean;
  disabled?: boolean
}> = ({
  optionsList,
  values,
  setValues,
  label,
  required = false,
  isVertical = true,
  isMulti = false,
  isSplitted = false,
  disabled = false
}) => {
    const [orgCategory, setOrgCategory] = React.useState<Array<any>>([]);
    useEffect(() => {
      setOrgCategory(optionsList);
    }, [optionsList]);
    let renderList: Array<any> = [];

    return (
      <>
        {label && <FormTitle title={label} />}
        {orgCategory && orgCategory.length > 0 && (
          <FlexBox direction="column">
            {orgCategory.map((category, index) => {
              if (index % 2 === 0) renderList = [];
              const render = (
                <FlexBox
                  direction={!isVertical ? 'row' : 'column'}
                  alignItems={!isVertical ? 'center' : 'flex-start'}
                  key={`${category.groupId}${`-${index}`}`}
                  customStyle={css({ width: '100%', padding: '0 0 0 1rem' })}
                >
                  <FlexBoxItem width={!isVertical ? '5rem' : 'auto'}>
                    <FormLabel label={category.group} />
                  </FlexBoxItem>
                  <FlexBoxItem width="100%">
                    <VerticalLabelMutilSelectForm
                      // eslint-disable-next-line no-nested-ternary
                      placeholder={isSplitted
                        ? (
                          category.options && category.options.length
                            ? 'すべて'
                            : 'なし'
                        )
                        : undefined}
                      label=""
                      name="orgCategory"
                      values={category.options.filter((option: OptionType) => {
                        if (values === null) return false;
                        if (values.includes(option.value)) {
                          return true;
                        }
                        return false;
                      })}
                      setValue={(val: string) => {
                        const removeItems: Array<string> = [];
                        category.options.forEach((option: OptionType) => {
                          if (values.includes(option.value)) {
                            removeItems.push(option.value);
                          }
                        });
                        const tempList = values.filter((item: string) => {
                          if (removeItems.includes(item) || item === '') {
                            return false;
                          }
                          return true;
                        });
                        const valList = val.split(',');
                        if (
                          valList.length > 0
                          && valList[valList.length - 1] === ''
                        ) {
                          valList.splice(valList.length - 1, 1);
                        }
                        tempList.push.apply(tempList, valList);
                        setValues(tempList);
                      }}
                      options={category.options}
                      isMulti={isMulti}
                      disable={disabled}
                    />
                  </FlexBoxItem>
                </FlexBox>
              );
              renderList.push(render);
              if (
                isSplitted
                && index % 2 === 0
                && index === orgCategory.length - 1
              ) {
                renderList.push(
                  <FlexBox
                    direction={!isVertical ? 'row' : 'column'}
                    alignItems={!isVertical ? 'center' : 'flex-start'}
                    key={`${category.groupId}-empty`}
                    customStyle={css({ width: '100%', padding: '0 0 0 1rem' })}
                  />,
                );
              }
              const wrapper = (
                <FlexBox
                  direction={isMobileOnly ? 'column' : 'row'}
                  customStyle={
                    isSplitted
                      ? css({ width: '100%', padding: '0 1rem 0 1rem' })
                      : css({ width: '100%' })
                  }
                  key={`${category.groupId}-container`}
                >
                  {renderList}
                </FlexBox>
              );
              if (
                isSplitted
                && (index % 2 === 1 || index === orgCategory.length - 1)
              ) {
                // console.log('RETURN', renderList.length, renderList);
                return wrapper;
              }
              if (!isSplitted) {
                return render;
              }
              return true;
            })}
          </FlexBox>
        )}
      </>
    );
  };
export default OrganizationCategorySelect;
//
