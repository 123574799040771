
export interface Advertising {
  advertisingPlanMstName: string,
  dispOrder: string,
  advertisingPlanMstCode: string,
}


export default class AdvertisingDomain {
  constructor(private rawData: any) {
    this.rawData = {
      ...rawData,
      advertisingPlanMstName: rawData.advertisingPlanMstName,
      advertisingPlanMstCode: rawData.advertisingPlanMstCode,
      dispOrder: rawData.dispOrder,
    };
  }

  static generateInitial(): AdvertisingDomain {
    return new AdvertisingDomain({
      advertisingPlanMstName: '',
      dispOrder: '',
      advertisingPlanMstCode: '',
    });
  }

  getRawData(): Advertising {
    return this.rawData;
  }

  getRawDataPost(): any {
    return {
      advertisingPlanMstName: this.rawData.advertisingPlanMstName,
      dispOrder: this.rawData.dispOrder,
      advertisingPlanMstCode: this.rawData.advertisingPlanMstCode,
      isCreate: true,
    };
  }

  get advertisingPlanMstName(): string {
    return this.rawData.advertisingPlanMstName;
  }

  set advertisingPlanMstName(advertisingPlanMstName: string) {
    this.rawData.advertisingPlanMstName = advertisingPlanMstName;
  }

  get dispOrder(): string {
    return this.rawData.dispOrder;
  }

  set dispOrder(dispOrder: string) {
    this.rawData.dispOrder = dispOrder;
  }

  get advertisingPlanMstCode(): string {
    return this.rawData.advertisingPlanMstCode !== 'undefined' ? this.rawData.advertisingPlanMstCode : '';
  }

  set advertisingPlanMstCode(advertisingPlanMstCode: string) {
    this.rawData.advertisingPlanMstCode = advertisingPlanMstCode;
  }
}
