/** @jsx jsx */
import React from 'react';

import FormLabel from 'components/atoms/Form/FormLabel';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import YearPicker from 'components/molecules/YearPicker/YearPicker';
import { css, jsx } from '@emotion/core';
import productColor, { grayScale, gridCol, textFontSize } from 'components/styles';

const styles = {
  wrapper: css({
    width: gridCol.grid02,
    display: 'flex',
  }),
  input: css({
    flex: 1,
    width: '32px',
    padding: '7px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: 'none',
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px 0 0 4px',
  }),
  addon: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '7px',
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
  }),
};

const YearPickerForm: React.FC<{
  name: string;
  label: string;
  note?: string;
  type?: string;
  selectedYear: number
  placeHolder?: string;
  groupText?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMsg?: string;
}> = ({
  name, label, selectedYear, type,disabled, onChange, errorMsg, note, placeHolder, groupText, required,
}) => (
  <VerticalLabelFormLayout
    label={(
      <FormLabel
        label={label}
        required={required}
      />
    )}
    input={(
      <div css={styles.wrapper}>
        <YearPicker
          customStyle={{ width: '100%' }}
          selectedYear={selectedYear}
          onChange={onChange}
          disabled={disabled}
        />
        { groupText && (
        <div css={styles.addon}>
          {' '}
          {groupText}
        </div>
        )}
      </div>
    )}
    errorMsg={errorMsg}
  />
);

export default YearPickerForm;
