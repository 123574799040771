import DaySeparateReportLayout from 'components/organismos/master/storeManagement/shogun/DaySeparateReportLayout/DaySeparateReportLayout';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';

const DaySeparateReportPage = () => (
  <SidebarTemplate pageTitle="売上日報一覧">
    <DaySeparateReportLayout />
  </SidebarTemplate>
);
export default DaySeparateReportPage;
