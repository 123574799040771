/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import ApiClient from 'api/ApiClient';
import { OptionType } from 'components/atoms/Select';
import {
  APIReportStatusType,
} from 'domain/salesReport';
import { css, jsx } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import FormContents from 'components/atoms/Form/FormContents';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import DatePicker from 'components/molecules/DatePicker';
import FlexBox from 'components/atoms/FlexBox';
import moment from 'moment';
import SelectForm from 'components/molecules/SelectForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import useHeadquarterPointedOutList, { useDownload } from 'components/organismos/master/general/salesPage/headquarterPointedOutList/hook';
import HeadquarterPointedOutListTable from 'components/organismos/master/general/salesPage/headquarterPointedOutList/headquarterPointedOutListTable';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const HeadquarterPointedOutList: React.FC<{}> = () => {
  const {
    isLoading,
    listHeadquarterPointedOut,
    functionType,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    selectedMonth,
    setSelectedMonth,
    getStatus,
    setStatus
  } = useHeadquarterPointedOutList();

  const [orgName, setorgName] = useState(sessionStorage.getItem('loginUser.orgName') ? sessionStorage.getItem('loginUser.orgName') : '');
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const [saleReportStatus0, setSaleReportStatus0] = useState<OptionType[]>([{ label: '全て', value: 'all' }]);
  const apiGetReportStatus0 = async (): Promise<APIReportStatusType[]> => {
    const { data } = await ApiClient.get(`/v1/report/status0/${companyCode}`, {}, {});
    return data;
  };

  useEffect(() => {
    async function getReportStatus0() {
      const [status0] = await Promise.all([apiGetReportStatus0()]);
      const data0: OptionType[] = status0.map((status) => ({ value: status.statusId, label: status.statusName }));
      data0.push({ label: '全て', value: 'all' });
      setStatus(data0 && data0[0].value)
      setSaleReportStatus0(data0);
    }
    getReportStatus0();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox>
          <DatePicker
            dateFormat="yyyy年MM月"
            date={selectedMonth}
            changeDate={(date: Date) => {
              if (date !== null) {
                setSelectedMonth(date);
                sessionStorage.setItem('dateSelectSalePage12', `${date}`);
              }
            }}
            isFullWidth={false}
            showMonthYearPicker={true}
          />
        </FlexBox>
        <div style={{ marginLeft: '8px' }}>
          <OrganizationFilteredTree
            functionType={functionType}
            orgCallback={(args: string | Array<string>) => {
              setOrgCode(String(args));
            }}
            orgCategoryCallback={
              (args: Array<string>) => {
                setCategoryAry(args);
              }
            }
            orgNameCallback={(arg: any) => {
              setorgName(String(arg));
            }}
            initOrgValue={String(orgCode)}
            staffCode={loginStaffCode}
            orgLabel="組織名"
            addAllItem={true}
          />
        </div>
        <FlexBox>
          <SelectForm
            label="状態"
            name="status"
            value={String(getStatus)}
            setValue={(v: string) => {
              setStatus(v);
            }}
            options={saleReportStatus0}
            islabelLong={true}
          />
        </FlexBox>
        <HeadquarterPointedOutListTable
          listHeadquarterPointedOut={listHeadquarterPointedOut}
          orgName={orgName}
          selectedMonth={selectedMonth}
          roleScreen={roleScreen}
        />
      </FormContents>
    </BlockUI>
  );
};
export default HeadquarterPointedOutList;
