/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import Select, { components, OptionTypeBase } from 'react-select';
import { ValueType } from 'react-select/src/types';
import moment from 'moment';

import {
  grayScale, productColor, textFontSize, textColor, utilityColor,
} from 'components/styles';
import FormLabel from 'components/atoms/Form/FormLabel';
import Icon from 'components/atoms/Icon';
import DateTimeSelectFormLayout from 'components/atoms/Form/DateTimeSelectFormLayout';
import ReactDatePicker from 'react-datepicker';
import './styles.css';

export interface OptionType extends OptionTypeBase {
  value: string,
  label: string,
}

const styles = {
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: '4px',
  }),
  wrapDatePicker: css({
    height: '0px',
    overflow: 'hidden',
  }),
};

const selectStyles = {
  control: (oldStyles: any) => ({
    ...oldStyles,
    '& > div': {
      paddingLeft: 0,
      direction: 'ltr',
    },
  }),
  dropdownIndicator: (oldStyles: any) => ({
    ...oldStyles,
    cursor: 'pointer',
  }),
  input: (oldStyles: any) => ({
    ...oldStyles,
  }),
  multiValue: (oldStyles: any) => ({
    ...oldStyles,
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0D4796',
    borderRadius: '12px',
  }),
  multiValueLabel: (oldStyles: any) => ({
    ...oldStyles,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    color: productColor.primary,
  }),
  multiValueRemove: (oldStyles: any) => ({
    ...oldStyles,
    lineHeight: '21px',
    paddingRight: '6px',
    ':hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  }),
  placeholder: (oldStyles: any) => ({
    ...oldStyles,
    fontSize: '14px',
  }),
};

const DateTimeSelectForm: React.FC<{
  label: string;
  name: string;
  dateFormat: string;
  labelFormat: string;
  valueFormat: string;
  values: Array<OptionType>;
  setValue: (val: any) => void;
  description?: string;
  note?: string;
  isMulti?: boolean;
}> = ({
  label, name, dateFormat, valueFormat, labelFormat, values, setValue, description, isMulti, note,
}) => {
  const getValue = (): ValueType<OptionType> => values;

  const [isOpenDatePicker, setIsOpenDatePicker] = React.useState(false);
  const onFocus = () => {
    setIsOpenDatePicker(true);
  };
  const closeDatePicker = () => {
    setIsOpenDatePicker(!isOpenDatePicker);
  };

  const handleChangeDatePicker = (date: Date) => {
    const tmp = {
      value: moment(date, valueFormat).format(valueFormat),
      label: moment(date, labelFormat).format(labelFormat),
    };
    if (!values.find((i: any) => i.value === tmp.value)) {
      setValue([...values, tmp]);
    }

    setIsOpenDatePicker(false);
  };

  const onChange = (option: any) => {
    if (option) {
      setValue(option);
    } else {
      setValue([]);
    }
  };

  const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <Icon type="calendar" color={grayScale.gray100} />
    </components.DropdownIndicator>
  );

  const MultiValueRemove = (props: any) => (
    <components.MultiValueRemove {...props}>
      <Icon type="close" color={grayScale.gray100} />
    </components.MultiValueRemove>
  );

  return (
    <div css={styles.container}>
      <DateTimeSelectFormLayout
        label={(
          <FormLabel
            label={label}
          />
        )}
        input={(
          <div css={styles.select}>
            <div css={styles.wrapDatePicker}>
              <ReactDatePicker
                showYearDropdown={true}
                showMonthDropdown={true}
                locale="ja"
                dateFormat={dateFormat}
                selected={new Date()}
                onChange={handleChangeDatePicker}
                open={isOpenDatePicker}
                onClickOutside={closeDatePicker}
              />
            </div>
            <Select
              onFocus={onFocus}
              styles={selectStyles}
              name={name}
              value={getValue()}
              onChange={onChange}
              placeholder="選択してください"
              isMulti={isMulti}
              isClearable={false}
              menuIsOpen={false}
              isRtl={true}
              components={{ DropdownIndicator, IndicatorSeparator: () => null, MultiValueRemove }}
            />
          </div>
        )}
      />
      {note && <span css={css(styles.noteText, { color: utilityColor.error })} dangerouslySetInnerHTML={{ __html: note }} />}
    </div>
  );
};

export default DateTimeSelectForm;
