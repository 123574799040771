/* eslint-disable max-len */
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import Panel from 'components/atoms/Panel';
import ScheduleCalendarToolbar from 'components/organismos/master/attend/Schedule/ScheduleCalendarToolbar';
import ScheduleMonthCalendar from 'components/organismos/master/attend/Schedule/ScheduleMonthCalendar';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FlexBox from 'components/atoms/FlexBox';
import FormContents from 'components/atoms/Form/FormContents';
import BodyText from 'components/atoms/BodyText';
import Select from 'components/atoms/Select';
import PrimaryButton from 'components/atoms/Button';
import { css } from '@emotion/core';
import { grayScale } from 'components/styles';
import ScheduleWeekCalendar from 'components/organismos/master/attend/Schedule/ScheduleWeekCalendar/ScheduleWeekCalendar';
import moment from 'moment';
import BlockUI from 'components/molecules/BlockUi';
import { HopeShiftDomain } from 'domain/master/general/hopeShift';
import { AttendShiftPatternDetails } from 'domain/master/general/weekShiftPatternDataStaff';
import { ScheduleStatus } from 'api/schedule';
import TimePicker from 'rc-time-picker';
import TextForm from 'components/molecules/TextForm';
import { useSchedulePage } from './hooks';

const ScheduleWebPage : React.FC = () => {
  const {
    state, setter,
  } = useSchedulePage();

  const valueSelectType = state.selectedHopeShift?.shiftPatternId && state.shiftPatternOptions.map((item) => item.value).includes(state.selectedHopeShift?.shiftPatternId) ? state.selectedHopeShift?.shiftPatternId
    : (state.flgCanWork ? 'CANWORK' : (state.selectedHopeShift?.hopeShiftPatternType === 1 || state.selectedHopeShift?.fixStatus === ScheduleStatus.DAY_OFF ? 'CANTWORK' : (state.selectedHopeShift?.hopeShiftPatternType === 2 ? 'CANTWORK' : (state.selectedHopeShift?.hopeShiftPatternType === 1 ? 'CANWORK' : (state.selectedHopeShift?.startTime ? 'CANWORK' : undefined)))));
  return (
    <SidebarTemplate
      pageTitle="マイシフト"
    >
      <BlockUI blocking={state.isLoading}>
        <FlexBox customStyle={css({ overflowX: 'scroll', flexWrap: 'nowrap' })}>
          <div style={{
            minWidth: 800,
            width: '100%',
            display: 'flex',
            boxSizing: 'border-box',
          }}
          >
            <FlexBoxItem basis="75%">
              <Panel margin="8px 0px 8px 16px" padding="8px 20px">
                <ScheduleCalendarToolbar
                  date={state.datePickerValue}
                  setDatePickerValue={setter.setDatePickerValue}
                  setDate={setter.setDate}
                  view={state.view}
                  setView={setter.setView}
                />

                {/* 月表示 */}
                {state.view === 'month' && (
                <ScheduleMonthCalendar
                  events={state.events}
                  date={state.date}
                  datePickerValue={state.datePickerValue}
                  setDate={setter.setDate}
                  setDatePickerValue={setter.setDatePickerValue}
                />
                )}

                {/* 日表示 */}
                {state.view === 'week' && (
                  <ScheduleWeekCalendar
                    events={state.events}
                    date={state.date}
                    datePickerValue={state.datePickerValue}
                    setDate={setter.setDate}
                    setDatePickerValue={setter.setDatePickerValue}
                  />
                )}
              </Panel>
            </FlexBoxItem>

            <FlexBoxItem basis="25%">
              <FlexBox direction="column" alignItems="flex-start" customStyle={css({ height: '670px' })}>
                <FlexBoxItem width="100%">
                  <FormContents>
                    <BodyText size="re"> 希望シフト変更 </BodyText>
                    <br />
                    <BodyText size="md" customStyle={css({ textAlign: 'left' })}>
                      {moment(state.date).format('YYYY年MM月DD日')}
                    </BodyText>

                    <Select
                      isMulti={false}
                      placeholder="-"
                      name="a"
                    // eslint-disable-next-line no-nested-ternary
                      value={valueSelectType}
                      setValue={(val) => {
                        setter.setFlgCanWork(false);
                        if (val === '') {
                        // const editedHopeShift = new HopeShiftDomain({
                        //   ...state.selectedHopeShift!.getRawData(),
                        //   shiftPatternId: '',
                        //   startTime: null,
                        //   endTime: null,
                        //   fixStatus: ScheduleStatus.UNSUBMITED,
                        //   hopeShiftPatternType: 0,
                        // });
                        // setter.setSelectedHopeShift(editedHopeShift);
                        } else if (val === 'CANWORK') {
                          const editedHopeShift = new HopeShiftDomain({
                            ...state.selectedHopeShift!.getRawData(),
                            shiftPatternId: '',
                            startTime: state.startTimeGlobal.toDate(),
                            endTime: state.endTimeGlobal.toDate(),
                            fixStatus: ScheduleStatus.UNSUBMITED,
                            hopeShiftPatternType: 1,
                            note: state.selectedHopeShift ? state.selectedHopeShift.note : '',
                          });
                          setter.setFlgCanWork(true);
                          setter.setSelectedHopeShift(editedHopeShift);
                        } else if (val === 'CANTWORK') {
                          const editedHopeShift = new HopeShiftDomain({
                            ...state.selectedHopeShift!.getRawData(),
                            shiftPatternId: '',
                            startTime: null,
                            endTime: null,
                            fixStatus: ScheduleStatus.UNSUBMITED,
                            hopeShiftPatternType: 2,
                            note: '',
                          });
                          setter.setSelectedHopeShift(editedHopeShift);
                        } else {
                          const selectedShiftPattern = state.shiftPatternList.filter((item) => item.shiftPatternId === val)[0];

                          // Get end time
                          const shiftDetailEnd : AttendShiftPatternDetails = selectedShiftPattern.attendShiftPatternDetails[selectedShiftPattern.attendShiftPatternDetails.length - 1];
                          const [hours, minutes, seconds] = shiftDetailEnd.endTime.split(':');
                          const endTime = new Date(state.selectedHopeShift!.targetDate);
                          if (shiftDetailEnd.isEndTimeNextDay) {
                            endTime.setDate(endTime.getDate() + 1);
                          }
                          endTime.setHours(Number(hours));
                          endTime.setMinutes(Number(minutes));
                          endTime.setSeconds(Number(seconds));
                          // Get start time
                          const shiftDetailStart : AttendShiftPatternDetails = selectedShiftPattern.attendShiftPatternDetails[0];
                          const [h, m, s] = shiftDetailStart.startTime.split(':');
                          const startTime = new Date(state.selectedHopeShift!.targetDate);
                          if (shiftDetailStart.isStartTimeNextDay) {
                            startTime.setDate(endTime.getDate() + 1);
                          }
                          startTime.setHours(Number(h));
                          startTime.setMinutes(Number(m));
                          startTime.setSeconds(Number(s));


                          const editedHopeShift = new HopeShiftDomain({
                            ...state.selectedHopeShift!.getRawData(),
                            shiftPatternId: selectedShiftPattern.shiftPatternId,
                            startTime,
                            endTime,
                            fixStatus: ScheduleStatus.UNSUBMITED,
                            hopeShiftPatternType: 3,
                            note: '',
                          });
                          setter.setSelectedHopeShift(editedHopeShift);
                        }
                      }}
                      options={state.shiftPatternOptions}
                      controlStyle={state.selectedHopeShift?.isEditable ? {} : {
                        opacity: '0.5',
                        pointerEvents: 'none',
                      }}
                    />

                    <div style={{ width: '100%', display: 'inline-block', marginTop: '5px' }}>

                      <div style={{ width: '80px', float: 'left' }}>
                        <TimePicker
                          name="midnightStartTime"
                          format="HH:mm"
                          value={state.startTimeGlobal}
                          showSecond={false}
                          onChange={(momentVal) => {
                            const tmpVal = momentVal || moment(state.date).hour(state.timeSettings.startHour).minutes(0);
                            setter.setStartTimeGlobal(tmpVal);
                            const editedHopeShift = new HopeShiftDomain({
                              ...state.selectedHopeShift!.getRawData(),
                              shiftPatternId: '',
                              startTime: tmpVal.toDate(),
                              endTime: state.endTimeGlobal.toDate(),
                              fixStatus: ScheduleStatus.UNSUBMITED,
                              hopeShiftPatternType: 1,
                              businessId: state.businessId,
                              note: state.selectedHopeShift ? state.selectedHopeShift.note : '',
                            });
                            setter.setSelectedHopeShift(editedHopeShift);
                          }}
                          disabled={!state.selectedHopeShift?.isEditable || valueSelectType !== 'CANWORK'}
                        />
                      </div>
                      <div style={{
                        width: '20px', textAlign: 'center', float: 'left',
                      }}
                      >
                        〜
                      </div>
                      <div style={{ width: '80px', float: 'left' }}>
                        <TimePicker
                          name="midnightEndTime"
                          format="HH:mm"
                          value={state.endTimeGlobal}
                          showSecond={false}
                          onChange={(momentVal) => {
                            const tmpVal = momentVal || moment(state.date).hour(state.timeSettings.endHour).minutes(0);
                            setter.setEndTimeGlobal(tmpVal);
                            const editedHopeShift = new HopeShiftDomain({
                              ...state.selectedHopeShift!.getRawData(),
                              shiftPatternId: '',
                              startTime: state.startTimeGlobal.toDate(),
                              endTime: tmpVal.toDate(),
                              fixStatus: ScheduleStatus.UNSUBMITED,
                              hopeShiftPatternType: 1,
                              businessId: state.businessId,
                              note: state.selectedHopeShift ? state.selectedHopeShift.note : '',
                            });
                            setter.setSelectedHopeShift(editedHopeShift);
                          }}
                          disabled={!state.selectedHopeShift?.isEditable || valueSelectType !== 'CANWORK'}
                        />
                      </div>
                    </div>

                    {
                    state.businessOptions.length > 1
                      ? (
                        <Select
                          isMulti={false}
                          placeholder="-"
                          name="businessId"
                          value={state.businessId}
                          setValue={(businessId) => {
                            setter.setBusinessId(businessId);
                            const editedHopeShift = new HopeShiftDomain({
                              ...state.selectedHopeShift!.getRawData(),
                              shiftPatternId: '',
                              startTime: state.startTimeGlobal.toDate(),
                              endTime: state.endTimeGlobal.toDate(),
                              fixStatus: ScheduleStatus.UNSUBMITED,
                              hopeShiftPatternType: 1,
                              businessId,
                              note: state.selectedHopeShift ? state.selectedHopeShift.note : '',
                            });
                            setter.setSelectedHopeShift(editedHopeShift);
                          }}
                          options={
                            state.businessOptions.filter(
                              (item: any) => {
                                if (valueSelectType === 'CANWORK' && item.label === '休憩') {
                                  return false;
                                }
                                return true;
                              },
                            )
                          }
                          controlStyle={(state.selectedHopeShift?.isEditable && valueSelectType === 'CANWORK') ? {} : {
                            opacity: '0.5',
                            pointerEvents: 'none',
                          }}
                        />
                      )
                      : ''
                  }
                    {/* <BodyText size="re" customStyle={css({ textAlign: 'left' })}>
                    コメント
                  </BodyText> */}
                    <TextForm
                      name="comment"
                      label="コメント"
                      value={state.note}
                      onChange={(e) => {
                        setter.setNote(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (state.selectedHopeShift?.note !== e.target.value) {
                          setter.setNote(e.target.value);
                          const editedHopeShift = new HopeShiftDomain({
                            ...state.selectedHopeShift!.getRawData(),
                            shiftPatternId: state.selectedHopeShift ? state.selectedHopeShift.shiftPatternId : '',
                            startTime: state.startTimeGlobal.toDate(),
                            endTime: state.endTimeGlobal.toDate(),
                            fixStatus: ScheduleStatus.UNSUBMITED,
                            businessId: state.businessId,
                            note: e.target.value,
                          });
                          setter.setSelectedHopeShift(editedHopeShift);
                        }
                      }}
                      disable={!state.selectedHopeShift?.isEditable}
                    />

                  </FormContents>

                </FlexBoxItem>
                <FlexBoxItem width="100%">
                  <div style={{ margin: '8px 16px' }}>
                    <PrimaryButton text="提出" fullwidth={true} onClick={setter.onSubmitHopeShifts} />
                  </div>
                </FlexBoxItem>
                <FlexBoxItem width="100%">
                  <FormContents>
                    <BodyText size="re">
                      {`${state.datePickerValue.getMonth() + 1}月の勤務状況`}
                    </BodyText>
                    <br />
                    {/*
                  <BodyText size="md" customStyle={css({ width: '80px', display: 'inline-block' })}>給与目安</BodyText>
                  <BodyText size="md">¥</BodyText>
                  <BodyText size="xl2" bold={true}>
                    {`${Number(Math.floor(state.operationInfo.monthTotalCost / 1000) * 1000 || 0).toLocaleString()}`}
                  </BodyText>
                  <br />
                  <BodyText size="re" color={grayScale.gray40} customStyle={css({ width: '100px', display: 'inline-block' })}>合計給与予定 </BodyText>
                  <BodyText size="re" color={grayScale.gray40}>
                    {`¥${Number(Math.floor(state.operationInfo.totalCost / 1000) * 1000 || 0).toLocaleString()}`}
                  </BodyText>
                  <br />
                  */}
                    <BodyText size="md" customStyle={css({ width: '120px', display: 'inline-block' })}>合計勤務予定</BodyText>
                    <BodyText size="xl2" bold={true}>
                      {state.operationInfo.monthTotalworkTimeHour || 0}
                    </BodyText>
                    <BodyText size="md">時間</BodyText>
                    <BodyText size="xl2" bold={true}>
                      {state.operationInfo.monthTotalworkTimeMin || 0}
                    </BodyText>
                    <BodyText size="md">分</BodyText>
                    <br />
                    <BodyText size="re" color={grayScale.gray40} customStyle={css({ width: '120px', display: 'inline-block' })}>勤務時間</BodyText>
                    <BodyText size="re" color={grayScale.gray40}>
                      {`${state.operationInfo.totalWorkTimeHour || 0}時間${state.operationInfo.totalWorkTimeMin || 0}分`}
                    </BodyText>
                    <br />
                    <BodyText size="re" color={grayScale.gray40} customStyle={css({ width: '120px', display: 'inline-block' })}>（残業時間</BodyText>
                    <BodyText size="re" color={grayScale.gray40}>{`${state.operationInfo.overTimeHour || 0}`}</BodyText>
                    <BodyText size="re" color={grayScale.gray40}>時間</BodyText>
                    <BodyText size="re" color={grayScale.gray40}>{state.operationInfo.overTimeMin || 0}</BodyText>
                    <BodyText size="re" color={grayScale.gray40}>分）</BodyText>
                  </FormContents>
                </FlexBoxItem>
                <FlexBoxItem width="100%">
                  <FormContents>
                    {/*
                  <BodyText size="re"> 年間支給額の目安 </BodyText>
                  <br />
                  <BodyText size="md" customStyle={css({ width: '70px', display: 'inline-block' })}>給与</BodyText>
                  <BodyText size="md">¥</BodyText>
                  <BodyText size="xl2" bold={true}>
                    {`${Number(Math.floor(state.yearlyAmountPaidInfo.yearTotalCost / 1000) * 1000 || 0).toLocaleString()}`}
                  </BodyText>
                  <br />
                  <BodyText size="re" color={grayScale.gray40} customStyle={css({ width: '80px', display: 'inline-block' })}>昨年実績</BodyText>
                  <BodyText size="re" color={grayScale.gray40}>
                    {`¥${Number(Math.floor(state.yearlyAmountPaidInfo.preYearTotalCost / 1000) * 1000 || 0).toLocaleString()}`}
                  </BodyText>
                  <br />
                  */}
                    <BodyText size="md" customStyle={css({ width: '120px', display: 'inline-block' })}>残り有給日数</BodyText>
                    <BodyText size="xl2" bold={true}>
                      {`${Number(state.paidHolidayInfo.remainDaysNum || 0).toLocaleString()}`}
                    </BodyText>
                    <BodyText size="md">日</BodyText>
                  </FormContents>
                </FlexBoxItem>
              </FlexBox>
            </FlexBoxItem>
          </div>
        </FlexBox>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default ScheduleWebPage;
