/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { string } from 'yup';

// create style form
const styles = {
  disabled: css({
    opacity: '0.5',
  }),
  padding: css({
    padding: 0,
  }),
};

/**
 * フォームフィールドのレイアウト
 */
const FormField: React.FC<{
  displayBlock?: boolean;
  disabled?: boolean;
  alignItems?:string;
  justifyContent?:string;
  isPadding?:boolean
}> = ({
  children, displayBlock = false, disabled = false, isPadding = false, alignItems = 'unset', justifyContent = 'unset'
}) => (
  <div
    css={css({
      padding: '10px 0',
      display: displayBlock ? 'block' : 'flex',
      alignItems,
      justifyContent,
    }, disabled ? styles.disabled : {}, isPadding ? styles.padding : {})}
  >
    {children}
  </div>
);

export default FormField;
