/** @jsx jsx */
import React, { useCallback } from 'react';
import { css, jsx } from '@emotion/core';
// interface AccordionTableProps {
//   text: string;
//   color: string;
//   defaultExpand?: boolean;
//   accordionTableValue?: string;
//   callBack: () => void;
// }

const TableLastRow: React.FC<any> = ({
  rowData,
  backgroundColor
}) => {
  return (
    <React.Fragment>
      {
        rowData.map((r:any) => (React.cloneElement(r, { style:{backgroundColor: backgroundColor ? backgroundColor : 'white'} })))
      }
    </React.Fragment>
  );
};

export default TableLastRow;
