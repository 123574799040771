/* eslint-disable max-len */
import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getEmploymentSelectList, getSelectListDateRange, getNecessaryData } from 'api/employment';
import { getEmploymentOptions, getStaffOptions } from 'api/timeRecalculation';
import moment from 'moment';

/**
 * 雇用形態の選択ボックス用データを取得
 */
export const useEmploymentOptions = (): Array<OptionType> => {
  const [employmentOptions, setEmploymentOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getEmploymentSelectList().then((employments) => {
      const employmentO = employments.map((employment) => ({
        value: employment.employmentId,
        label: `${employment.employmentCode} ${employment.employmentName}`,
      }));
      employmentO.unshift({
        value: '',
        label: '-',
      });

      setEmploymentOptions(employmentO);
    });
  }, []);
  return employmentOptions;
};

// use Screen 勤怠再計算 - Time Recalculation
export const useEmploymentTypeOptions = (targetDateFrom : Date, targetDateTo: Date): Array<OptionType> => {
  const [employmentOptions, setEmploymentOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    if (targetDateFrom === null || targetDateTo === null) return;
    const dateFrom = moment(new Date(targetDateFrom.getFullYear(), targetDateFrom.getMonth(), 1)).format('YYYY-MM-DD');
    const dateTo = moment(new Date(targetDateTo.getFullYear(), targetDateTo.getMonth() + 1, 0, 23, 59, 59)).format('YYYY-MM-DD');
    getEmploymentOptions(dateFrom, dateTo).then((trees) => {
      const employment = trees.map((tree) => ({
        value: tree.id.employmentId,
        label: `${tree.employmentCode} ${tree.employmentName}`,
      }));
      employment.unshift({
        value: '',
        label: '-',
      });
      setEmploymentOptions(employment);
    });
  }, [targetDateFrom, targetDateTo]);
  return employmentOptions;
};

// use screen 勤怠再計算 - Time Recalculation
export const useStaffOptions = (
  orgCode: string,
  targetDateFrom : Date,
  targetDateTo: Date,
  functionType: number,
  categoryAryStr: any,
  employmentId?: string,
  targetMonthType?: number,
  stateStatus?: number[],
): Array<OptionType> => {
  const [staffOption, setstaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    if (targetDateFrom === null || targetDateTo === null) return;
    const dateFrom = moment(new Date(targetDateFrom.getFullYear(), targetDateFrom.getMonth(), 1)).format('YYYY-MM-DD');
    const dateTo = moment(new Date(targetDateTo.getFullYear(), targetDateTo.getMonth() + 1, 0, 23, 59, 59)).format('YYYY-MM-DD');

    const params = {
      orgCode: orgCode,
      targetDayFrom: dateFrom,
      targetDayTo: dateTo,
      stateStatus: stateStatus ?? 1,
      targetMonthType: targetMonthType ?? 1,
      functionType: functionType,
      employmentId: employmentId
    }

    getStaffOptions(params, categoryAryStr).then((trees) => {
      const staffOptions = trees.map((tree) => ({
        value: tree.staffCode,
        label: `${tree.staffCode} ${tree.staffName}`,
      }));
      staffOptions.unshift({
        value: 'all',
        label: 'all 全スタッフ ',
      });
      setstaffOptions(staffOptions);
    });
  }, [categoryAryStr, functionType, orgCode, targetDateFrom, targetDateTo, employmentId]);
  return staffOption;
};

export const useEmploymentListDateRange = (targetDateFrom : Date, targetDateTo: Date): Array<OptionType> => {
  const [staffOption, setstaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const dateFrom = moment(new Date(targetDateFrom)).format('YYYY-MM-DD');
    const dateTo = moment(new Date(targetDateTo)).format('YYYY-MM-DD');
    getSelectListDateRange(dateFrom, dateTo).then((trees) => {
      const employment = trees.map((tree: { employmentId: any; employmentCode: any; employmentName: any; }) => ({
        value: tree.employmentId,
        label: `${tree.employmentCode} ${tree.employmentName}`,
      }));
      setstaffOptions(employment);
    });
  }, [targetDateFrom, targetDateTo]);
  return staffOption;
};

/**
 *
 */
export const useEmploymentLatestOptions = (): any => {
  const [employmentOptions, setEmploymentOptions] = useState<Array<OptionType>>([]);
  const [attendEmploymentList, setAttendEmploymentList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      await getNecessaryData().then((employments) => {
        setAttendEmploymentList(employments);
        const employmentOption = employments.map((employment: any) => ({
          value: employment.id.employmentId,
          label: `${employment.employmentCode} ${employment.employmentName}`,
        }));
        setEmploymentOptions(employmentOption);
      });
    };
    fetchData();
  }, []);
  return {
    employmentOptions,
    attendEmploymentList,
  };
};

export default useEmploymentOptions;
