/** @jsx jsx */
import React, { useCallback } from 'react';
import { css, jsx } from '@emotion/core';

// interface AccordionTableProps {
//   text: string;
//   color: string;
//   defaultExpand?: boolean;
//   accordionTableValue?: string;
//   callBack: () => void;
// }

const TableBodyRow: React.FC<any> = ({
  rowData,
  isExpanded
}) => {
  return (
    <React.Fragment>
      {
        isExpanded ? rowData : []
      }
    </React.Fragment>
  );
};

export default TableBodyRow;
