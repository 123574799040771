import React, { SVGProps } from 'react';

const Close: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.5 23C6.70101 23 2 18.299 2 12.5C2 6.70101 6.70101 2 12.5 2C18.299 2 23 6.70101 23 12.5C23 18.299 18.299 23 12.5 23ZM12.5 11.7929L15.1464 9.14645C15.3417 8.95118 15.6583 8.95118 15.8536 9.14645C16.0488 9.34171 16.0488 9.65829 15.8536 9.85355L13.2071 12.5L15.8536 15.1464C16.0488 15.3417 16.0488 15.6583 15.8536 15.8536C15.6583 16.0488 15.3417 16.0488 15.1464 15.8536L12.5 13.2071L9.85355 15.8536C9.65829 16.0488 9.34171 16.0488 9.14645 15.8536C8.95118 15.6583 8.95118 15.3417 9.14645 15.1464L11.7929 12.5L9.14645 9.85355C8.95118 9.65829 8.95118 9.34171 9.14645 9.14645C9.34171 8.95118 9.65829 8.95118 9.85355 9.14645L12.5 11.7929ZM22 12.5C22 17.7467 17.7467 22 12.5 22C7.25329 22 3 17.7467 3 12.5C3 7.25329 7.25329 3 12.5 3C17.7467 3 22 7.25329 22 12.5Z" fill="#999999" />
  </svg>

);

export default Close;
