import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import SelectFormVirtical from 'components/molecules/SelectFormVirtical';
import FormField from 'components/atoms/Form/FormField';
import DatePickerForm from 'components/molecules/DatePickerForm';
import TextForm from 'components/molecules/TextForm';
import moment from 'moment';
import TextareaAutoResize from "components/atoms/input/TextareaAutoResize";

const InsuranceBankOtherForm: React.FC<{
  state: any,
  handleOnChange: any,
}> = ({
  state,
  handleOnChange
}) => {

  const reAlphaNumbericSymbols = (value: string) => {
    const re =  /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\b\n ]*$/;
    return re.test(value);
  }

  const reNumberic = (value: string) => {
    const re = /^[0-9\b\n]*$/;
    return re.test(value);
  }

  return (
    <React.Fragment>

      {/* 火災保険 */}
      <FormTitle title="火災保険" />

      <FormField>
        <TextForm
          name="insuranceCompany"
          label="店舗保険会社"
          value={state.insuranceCompany}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="insuranceCompanyContact"
            label="担当者"
            value={state.insuranceCompanyContact}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="insuranceCompanyContactTel"
            label="担当者連絡先"
            value={state.insuranceCompanyContactTel}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField>
        <DatePickerForm 
          dateFormat="yyyy/MM/dd"
          label="契約開始日"
          date={state.insuranceContractStartDate !== '' ? moment(state.insuranceContractStartDate).toDate() : undefined}
          changeDate={(date: Date) => {
            if (date !== null) {
              handleOnChange(moment(date).format("YYYY/MM/DD"), 'insuranceContractStartDate', moment(date).format("YYYY/MM/DD"))
            }
          }}
        />

        <DatePickerForm
          dateFormat="yyyy/MM/dd"
          label="契約終了日"
          date={state.insuranceContractEndDate !== '' ? moment(state.insuranceContractEndDate).toDate() : undefined}
          changeDate={(date: Date) => {
            if (date !== null) {
              handleOnChange(moment(date).format("YYYY/MM/DD"), 'insuranceContractEndDate', moment(date).format("YYYY/MM/DD"))
            }
          }}
        />
      </FormField>

      <FormField>
        <TextForm
          name="insuranceContractNo"
          label="保険番号"
          value={state.insuranceContractNo}
          onChange={handleOnChange}
        />
      </FormField>

      {/* 銀行 */}
      {/* <FormTitle title="銀行" />

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="mainBankName1"
            label="主要銀行名①"
            value={state.mainBankName1}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="registrationNumber1"
            label="登録番号①"
            value={state.registrationNumber1}
            onChange={(e) => {
              if (reNumberic(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="mainBankName2"
            label="主要銀行名②"
            value={state.mainBankName2}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="registrationNumber2"
            label="登録番号②"
            value={state.registrationNumber2}
            onChange={(e) => {
              if (reNumberic(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="otherBankName1"
            label="その他銀行名①"
            value={state.otherBankName1}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="registrationNumber3"
            label="登録番号③"
            value={state.registrationNumber3}
            onChange={(e) => {
              if (reNumberic(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="otherBankName2"
            label="その他銀行名②"
            value={state.otherBankName2}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="registrationNumber4"
            label="登録番号④"
            value={state.registrationNumber4}
            onChange={(e) => {
              if (reNumberic(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField> */}

      {/* その他 */}
      <FormTitle title="その他" />

      <FormField displayBlock={true}>
        <div style={{ fontSize: '14px' }}>メモ</div>
        <TextareaAutoResize
          name="memo"
          value={state.memo}
          onChange={handleOnChange}
          rows={5}
        />
      </FormField>

    </React.Fragment>
  );
};

export default InsuranceBankOtherForm;
