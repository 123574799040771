import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getSpecialHolidays } from 'api/vacation';

/**
 * 特賃日パターンのOptionを取得
 */
export const useSpecialHolidayOptions = () => {
  const [specialHolidayOptions, setSpecialHolidayOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const fetchData = async () => {
      const specialHolidaysList = await getSpecialHolidays();
      const specialHolidaysOption = specialHolidaysList.map((specialHoliday: any) => ({
        value: specialHoliday.holidayId || '',
        label: `${specialHoliday.holidayCode} ${specialHoliday.holidayName}`,
      }));
      specialHolidaysOption.unshift({
        value: '',
        label: '-',
      });
      setSpecialHolidayOptions(specialHolidaysOption);
    };
    fetchData();
  }, []);
  return specialHolidayOptions;
};

export default useSpecialHolidayOptions;
