/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBox from 'components/atoms/FlexBox';
import productColor, {
  grayScale,
} from 'components/styles';
import DataTable from 'components/organismos/DataTable/DataTable';
import { css, jsx } from '@emotion/core';
import Accordion from 'components/molecules/Accordion';
import PaidHolidayDigestedtHistoryDomain from 'domain/master/holidayManagement/paidHolidayDigestedtHistory';
import { Column } from 'react-table';
import DatePicker from 'components/molecules/DatePicker';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import Modal from 'components/molecules/Modal';
import moment from 'moment';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { useHistory } from 'react-router-dom';
import { useAcquisitionStatusDomainForm, useDownloadHolidayManagementAcquisitionStatus } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

// create style form
const styles = {
  form: css({
    paddingTop: '15px',
  }),
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
    minWidth: '100px',
  }),
  detail: css({
    backgroundColor: productColor.primary,
    borderRadius: '5px',
    textAlign: 'center',
    marginLeft: '5px',
  }),
  detailButton: css({
    padding: '4px',
    color: 'white',
  }),
  selectDay: css({
    minWidth: '100px',
    width: '150px',
  }),
  textMessage: css({
    color: 'black',
  }),
};

export interface DataDetail {
  staffCode: string,
  staffName: string,
  transferDayNumb: string
}

const AcquisitionStatusForm: React.FC = () => {
  const {
    paidHolidayDigestedtHistoryList,
    callGetAcquisitionStatusDetail,
    orgCode,
    setOrgCode,
    searchDate,
    setSearchDate,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    dateYearAgo,
    setDateYearAgo,
  } = useAcquisitionStatusDomainForm();

  const {
    downloadHolidayManagementAcquisitionStatus,
  } = useDownloadHolidayManagementAcquisitionStatus(
    orgCode,
    searchDate,
  );

  //
  const [dataDetail, setDataDetail] = useState<DataDetail>({
    staffCode: '',
    staffName: '',
    transferDayNumb: '',
  });

  // handlePaidHolidayStatusDetail
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTransferHolidayStatusDetail = async (data: any) => {
    setDataDetail(data);
    callGetAcquisitionStatusDetail(data.staffCode);
    setModalOpen(true);
  };

  //
  const columns: Array<Column<PaidHolidayDigestedtHistoryDomain>> = React.useMemo(() => [
    {
      Header: () => 'スタッフコード',
      id: 'staffCode',
      accessor: 'staffCode',
      sortType: 'basic',
      Cell: (cell: { row :{ isExpanded: boolean, original: PaidHolidayDigestedtHistoryDomain },
        data: Array<PaidHolidayDigestedtHistoryDomain>
      }) => (
        <FlexBox>
          {cell.row.original.staffCode}
          <div css={css(styles.detail)}>
            <ClickableIconButton
              onClick={() => handleTransferHolidayStatusDetail(cell.row.original)}
              additionalCss={styles.detailButton}
            >
              詳細
            </ClickableIconButton>
          </div>
        </FlexBox>
      ),
    },
    {
      Header: 'スタッフ名',
      accessor: 'staffName',
    },
    {
      Header: '合計',
      accessor: 'digestion',
    },
  ], [handleTransferHolidayStatusDetail]);

  //

  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const accessLevel = sessionStorage.getItem('loginUser.accessLevel');
  const acquisitionOrg = 3;

  const history = useHistory();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  return (
    <Accordion
      text="取得状況"
      color={grayScale.gray100}
      callBack={() => {}}
      defaultExpand={!!((sessionStorage.getItem('attendHolidayManagement.accordion.paid.acquisition') === String(true)))}
      accordionValue="paid.acquisition"
    >
      <div>
        <div css={styles.container}>
          <FlexBox>
            <div css={styles.label}>
              <FormLabel
                label="対象期間"
              />
            </div>
            <div css={styles.selectDay}>
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                date={searchDate}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setSearchDate(date);
                    const minusYear = new Date(date);
                    minusYear.setDate(minusYear.getDate() + 1);
                    minusYear.setFullYear(minusYear.getFullYear() - 1);
                    setDateYearAgo(new Date(minusYear));
                  }
                  sessionStorage.setItem('acquisitionStatus.searchDate', String(date));
                }}
                isFullWidth={true}
              />

            </div>
            <div style={{ paddingLeft: '5px' }}>
              から過去1年間
            </div>
          </FlexBox>
        </div>

        <OrganizationFilteredTree
          functionType={2}
          staffCode={staffCode}
          orgLabel="組織名"
          initOrgValue={String(orgCode)}
          orgCallback={(args: string | Array<string>) => {
            setOrgCode(String(args));
          }}
          targetdayForm={dateYearAgo}
          targetdayTo={searchDate}
          addAllItem={false}
          styles={{
            label: {
              toggleSwitch: css({ marginLeft: '0px' }),
            },
            toggle: {
              width: '13rem',
            },
          }}
          orgFilter={acquisitionOrg}
        />
        <div
          css={css`
              table th:nth-child(1) {
                width: 200px;
              }
              table th:nth-child(2) {
                width: 200px;
              }
              table th:nth-child(3) {
                width: 160px;
              }
            `}
        >

          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <DataTable
              columns={columns}
              data={paidHolidayDigestedtHistoryList}
              isGlobalFilter={true}
              sortBy={[
                { id: 'staffCode', desc: false }
              ]}
            />
          </div>
        </div>

        <FormSubmitArea>
          <div style={{ marginRight: '12px' }}>
            {checkUserRole && checkUserRole.downloadFlag === 1 && (
            <Button
              text="ダウンロード  "
              onClick={() => downloadHolidayManagementAcquisitionStatus()}
            />
            )}
          </div>
          {Number(accessLevel) === 1 && checkUserRole && checkUserRole.downloadFlag === 1 && (
          <Button
            text="全店舗ダウンロード "
            onClick={() => downloadHolidayManagementAcquisitionStatus(true)}
          />
          )}

        </FormSubmitArea>

        {/* Form Detail */}
        <Modal
          open={modalOpen}
          closeHandler={closeModal}
          title="取得状況詳細"
          submitText="閉じる"
          onSubmit={closeModal}
          isShowButtonClose={false}
        >
          <div style={{ width: '500px' }}>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td className="active">スタッフコード：</td>
                  <td>
                    {dataDetail.staffCode}
                  </td>
                </tr>
                <tr>
                  <td className="active">スタッフ名：</td>
                  <td>
                    {dataDetail.staffName}
                  </td>
                </tr>
                <tr>
                  <td className="active">期間：</td>
                  <td>
                    {`${moment(detailItems.fromDate).format('YYYY年MM月DD日')}～${moment(detailItems.toDate).format('YYYY年MM月DD日')}`}
                  </td>
                </tr>
              </tbody>
            </table>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>日付</th>
                  <th>消化日数</th>
                </tr>
              </thead>
              <tbody>
                {detailItems.paidHolidayDigestionDetailList
                && detailItems.paidHolidayDigestionDetailList.map((item: any) => (
                  <tr>
                    <td>
                      {moment(item.digestedDate).format('YYYY年MM月DD日')}
                    </td>
                    <td>
                      {item.digestedNum}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      </div>
    </Accordion>
  );
};
export default AcquisitionStatusForm;
