import moment from 'moment';
import ApiClient from './ApiClient';

export interface Employment2 {
  employmentId: string;
  applyStartDate: Date;
  employmentCode: string;
  employmentName: string;
}

export const getEmploymentSelectListYm = async (targetDate: Date): Promise<Array<Employment2>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const targetYmStr = moment(targetDate).format('YYYY年MM月');
  const response = await ApiClient.get(`/v1/employments/selectListYm/${companyCode}`, { targetYmStr });
  return response.data;
};

export const getEmploymentSelectList = async (): Promise<Array<Employment2>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/employments/selectList/${companyCode}`, {});
  return response.data;
};

/**
 * 雇用形態一覧を取得する
 *
 */
export const getNecessaryData = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const reponse = await ApiClient.get(`/v1/employments/latestList/${companyCode}`, {});
  return reponse.data;
};

/**
 * getRoundTimeList
 *
 */
export const getRoundTimeList = async (employmentId: string,
  targetDateStr: string, staffCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    employmentId,
    targetDateStr,
    staffCode,
  };
  const reponse = await ApiClient.get(`/v1/employments/roundTime/${companyCode}`, query);
  return reponse.data;
};

export const getListByYm = async (targetYmStr: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    targetDate: targetYmStr,
  };
  const response = await ApiClient.get(`/v2/employments/listByYm/${companyCode}`, params);

  return response.data;
};

export const getList = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
  };
  const response = await ApiClient.get(`/v1/employments/list/${companyCode}`, params);
  return response.data;
};

export const getEmployment = async (employmentId:string, applyStartDate: string) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  let dateFormats = new Date();
  let applyDate = moment().startOf('month').format('YYYY-MM-DD');
  if (applyStartDate) {
    dateFormats = new Date(applyStartDate);
    applyDate = moment(dateFormats.toString()).format('YYYY-MM-DD');
  }
  const params = {
    employmentId,
    applyDate,
  };

  const response = await ApiClient.get(`/v1/employments/details/${companyCode}`, params);

  return response.data;
};


export const createOrUpdateEmployment = async (postData: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {};
  const response = await ApiClient.post(`/v2/employments/regist/${companyCode}`, query, postData);
  return response.data;
};

export const deleteEmployment = async (employmentId: string, applyDate: Date): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  let dateFormats = new Date();
  let paramApplyDate = moment().startOf('month').format('YYYY-MM-DD');
  if (applyDate) {
    dateFormats = new Date(applyDate);
    paramApplyDate = moment(dateFormats.toString()).format('YYYY-MM-DD');
  }
  const params = {
    employmentId,
    applyDate: paramApplyDate,
  };

  const response = await ApiClient.delete(`/v2/employments/details/${companyCode}`, params);
  return response.data;
};

export const getSelectListDateRange = async (
  targetDateFrom: string, targetDateTo: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    targetDateFrom,
    targetDateTo,
  };
  const reponse = await ApiClient.get(`/v1/employments/selectListDateRange/${companyCode}`, query);
  return reponse.data;
};

export const reponseGetHopeShift = async (staffCode: string, targetDate: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    staffCode,
    targetDate
  };
  const reponse = await ApiClient.get(`/v1/hopeShift/${companyCode}`, query);
  return reponse.data;
};

export const getRoundTimeListStamp = async (employmentId: string,
  targetDateStr: string, staffCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    employmentId,
    targetDateStr,
    staffCode,
  };
  const reponse = await ApiClient.get(`/v1/employments/roundTime/stamp/${companyCode}`, query);
  return reponse.data;
};


export default {
  getList,
  getListByYm,
  getEmploymentSelectList,
};
