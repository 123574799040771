import React from 'react';

import { textColor } from 'components/styles';
import FormTitle from 'components/atoms/Form/FormTitle';
import SelectForm from 'components/molecules/SelectForm';
import useEmploymentOptions from 'hooks/useEmploymentOptions';
import useWorkingDayOptions from 'hooks/useWorkingDayOptions';
import FormSubTitle from 'components/atoms/Form/FormSubTitle';
import SideLabelTextForm from 'components/molecules/SideLabelTextForm';
// import FormField from 'components/atoms/Form/FormField';
// import TextForm from 'components/molecules/TextForm';

/**
 * 店舗詳細項目
 */
const StoreDetailForm: React.FC<{
  prefectureCode: string;
  setPrefectureCode: (prefectureCode: string) => void;
  areaId: string;
  setAreaId: (areaId: string) => void;
  businessCategoryId: string;
  setBusinessCategoryId: (businessCategoryId: string) => void;
  employmentId: string;
  setEmploymentId: (employmentId: string) => void;
  workingDaysId: string;
  setWorkingDaysId: (workingDaysId: string) => void;
  posCode: string;
  setPosCode: (posCode: string) => void;
  infomartLinkCode: string;
  setInfomartLinkCode: (posCode: string) => void;
  numberOfSeat: string;
  setNumberOfSeat: (numberOfSeat: string) => void;
  floorSpace: string;
  setFloorSpace: (floorSpace: string) => void;
  state?: any,
  handleOnChange?: any
}> = ({
  prefectureCode, setPrefectureCode,
  areaId, setAreaId,
  businessCategoryId, setBusinessCategoryId,
  employmentId, setEmploymentId,
  workingDaysId, setWorkingDaysId,
  posCode, setPosCode,
  infomartLinkCode, setInfomartLinkCode,
  numberOfSeat, setNumberOfSeat,
  floorSpace, setFloorSpace,
  state, handleOnChange
}) => {
  const employmentOptions = useEmploymentOptions();
  const workingDayOptions = useWorkingDayOptions();
  return (
    <>
      <FormTitle
        title="詳細項目"
        note="＊任意"
        noteColor={textColor.subText01}
      />

      <p>
        詳細な日次決算情報を閲覧するのに必要な情報です。
        <br />
        {/* TODO 赤くする */}
        各管理項目は登録されていないと、設定することができません。
      </p>

      <FormSubTitle
        title="管理項目"
      />

      {/* <SelectForm
        label="所在地"
        name="prefectureCode"
        value={prefectureCode}
        setValue={setPrefectureCode}
        options={prefectureOptions}
      /> */}
      {/* <SelectForm
        label="エリア"
        name="areaId"
        value={areaId}
        setValue={setAreaId}
        options={areaOptions}
        description="帳票のグループ項目として使用します。"
      />
      <SelectForm
        label="業態"
        name="businessCategoryId"
        value={businessCategoryId}
        setValue={setBusinessCategoryId}
        options={businessCategoryOptions}
        description="帳票のグループ項目として使用します。"
      /> */}
      <SelectForm
        label="雇用形態"
        name="employmentId"
        value={state?.employmentId}
        setValue={(val) => { handleOnChange (val, 'employmentId', val)}}
        options={employmentOptions}
        description="スタッフ登録時のデフォルトとなる雇用形態を選択します"
      />
      <SelectForm
        label="所定労働日数"
        name="workingDaysId"
        value={state?.workingDaysId}
        setValue={(val) => { handleOnChange (val, 'workingDaysId', val)}}
        options={workingDayOptions}
        description="月給者の人件費を按分する日数として使用します。"
      />

      {/* <FormSubTitle
        title="店舗項目"
      /> */}
      {/* POSメーカー */}

      {/* POSコード */}
      <SideLabelTextForm
        label="POSコード"
        name="posCode"
        value={state?.posCode}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e)}
        description="連携するPOSのコードを入力します。"
      />

      {/* インフォマート店舗コード */}
      <SideLabelTextForm
        label="インフォマート店舗コード"
        name="infomartLinkCode"
        value={state?.infomartLinkCode}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e)}
        description="連携するインフォマートの店舗コードを入力します。"
      />

      {/* 席数 */}
      {/* <SideLabelTextForm
        label="席数"
        name="numberOfSeat"
        value={numberOfSeat}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNumberOfSeat(e.target.value)}
        description="帳票やレポートの計算値として使用します。"
      /> */}

      {/* 坪数 */}
      {/* <SideLabelTextForm
        label="坪数"
        name="floorSpace"
        value={floorSpace}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFloorSpace(e.target.value)}
        description="帳票やレポートの計算値として使用します。"
      /> */}
    </>
  );
};

export default StoreDetailForm;
