/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import FormatInput from 'components/atoms/Form/FormatInput';

/**
 * アイコンつきのリンク
 */
const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const numberFormater = (num: any) => {
  if (typeof num === 'undefined' || isNaN(num)) {
    return num;
  }
  const tmp = String(num).split('.');
  if (tmp[1]) {
    let fixed = 3;
    if (tmp[1].length < 3) {
      fixed = tmp[1].length;
    }
    return parseFloat(num).toFixed(fixed);
  }
  return formatter.format(num);
};
const removeComma = (number: any) => {
  const tmp = String(number).replace(/[\,]/g, '');
  if (!isNaN(Number(tmp))) {
    return Number(tmp);
  }
  return Number(0);
};
const re = /^[0-9\,\-\b]+$/;

const border_bottom = {
  borderBottom: '1px solid black'
}

const DiscountTableItem: React.FC<{
  discountTitle: string;
  dailyCashRegisterEatIn: number;
  eatInManual: number;
  takeOutManual: any;
  index: number;
  updateDiscountTypeDataFieldChanged: any;
  updateCntDiscountTypeDataFieldChanged: any;
  discount_cnt: number,
  discount_cnt_manual:any
}> = ({
  discountTitle,
  dailyCashRegisterEatIn,
  eatInManual,
  takeOutManual,
  index,
  updateDiscountTypeDataFieldChanged,
  updateCntDiscountTypeDataFieldChanged,
  discount_cnt,
  discount_cnt_manual
}) => {
    return (
      <React.Fragment>
        <tr className="DiscountInformationTd">
          <td className="text-center" rowSpan={3}>{discountTitle}
          </td>
        </tr>
        <tr>
          <td className="text-center DiscountInformationItem" colSpan={2} style={{ ...border_bottom }}>{'枚数'}</td>

          <td className="text-right ">{numberFormater(discount_cnt)}</td>

          <td className="text-right">
            <FormatInput
              type="text"
              name="eat_in"
              value={numberFormater(discount_cnt_manual)}
              onChange={(e) => {
                if (e.target.value == '' || re.test(e.target.value)) {
                  updateCntDiscountTypeDataFieldChanged(index, e);
                }

              }}
            />
          </td>

          <td className="text-right">{numberFormater(removeComma(discount_cnt_manual) + removeComma(discount_cnt))}</td>
        </tr>
        <tr>
          <td className="text-center DiscountInformationItem" colSpan={2} style={{ ...border_bottom }}>{'金額'}</td>
          <td className="text-right ">{numberFormater(dailyCashRegisterEatIn)}</td>
          <td className="text-right">
            <FormatInput
              type="text"
              name="eat_in"
              value={numberFormater(eatInManual)}
              onChange={(e) => {
                if (e.target.value == '' || re.test(e.target.value)) {
                  updateDiscountTypeDataFieldChanged(index, e);
                }

              }}
           
            />
          </td>
          <td className="text-right">{numberFormater(removeComma(eatInManual) + removeComma(dailyCashRegisterEatIn))}</td>
        </tr>
        <tr>
          
        </tr>
      </React.Fragment>
    );
  }
export default DiscountTableItem;

