import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import { useParams } from 'react-router-dom';
import VacationCreateForm from 'components/organismos/master/labor/VacationCreateForm';

const VacationCreatePage: React.FC = () => {
  const { vacationId } = useParams();
  return (
    <SidebarTemplate pageTitle={vacationId ? '休暇編集' : '休暇作成'}>
      <VacationCreateForm />
    </SidebarTemplate>
  );
};
export default VacationCreatePage;
