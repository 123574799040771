import React, { SVGProps } from 'react';

const IndicatorWarning: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M10 4a6.5 6.5 0 110 13 6.5 6.5 0 010-13zm0 1.25a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zm0 7.375c.345 0 .625.28.625.625v.25a.625.625 0 11-1.25 0v-.25c0-.345.28-.625.625-.625zm0-5.75c.345 0 .625.28.625.625v4a.625.625 0 11-1.25 0v-4c0-.345.28-.625.625-.625z"
        fill="#333"
      />
    </g>
  </svg>
);

export default IndicatorWarning;
