/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import Pagination, { PaginationProps } from './Pagination';

const styles = {
  controllbar: css({
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    // whiteSpace: 'nowrap',
    // overflowX: 'auto',
  }),
  pagination: css({
    marginLeft: 'auto',
  }),
};
interface ControllbarProps {
  numberOfElements: number 
  pageNumber: number
  pageSize: number
  totalElements: number
  totalPages: number
  handlePaginationPrevious: any
  handlePaginationNext: any
  canNextPage:boolean
  canPreviousPage:boolean
  handleGoToPage: any
  handleSetPageSize:any
}

const Controllbar: React.FC<ControllbarProps> = ({
  numberOfElements,
  pageNumber,
  pageSize,
  totalElements,
  totalPages,
  handlePaginationPrevious,
  handlePaginationNext,
  canNextPage,
  canPreviousPage,
  handleGoToPage,
  handleSetPageSize
}) => {
  return (
  <div css={styles.controllbar}>
    <div css={styles.pagination}>
      <Pagination
        total={totalElements}
        pageSize={pageSize}
        pageIndex={pageNumber-1}
        pageCount={totalPages}
        nextPage={handlePaginationNext}
        canNextPage={canNextPage}
        previousPage={handlePaginationPrevious}
        canPreviousPage={canPreviousPage}
        setPageSize={handleSetPageSize}
        gotoPage={handleGoToPage}
        // tableId={tableId}
      />
    </div>
  </div>
)};

export default Controllbar;
