import moment from 'moment';
import { formatNumber, formatPercent } from 'utility/formatUtil';

export interface MonthlyDailyBudgetPerformanceManagementReport {
    /** 対象日 */
    targetDate: Date;
    /** 純売上 */
    netSales: number;
    /** 売上予算 */
    salesBudget: number;
    /** 前年売上 */
    prevSales: number;
    /** 予実達成率 */
    perGoalSales: number;
    /** 前年対比 */
    perPrevSales: number;
    /** 累計純売上 */
    cumulativeSales: number;
    /** 累計売上予算 */
    cumulativeSalesBudget: number;
    /** 累計前年売上 */
    prevCumulativeSales: number;
    /** 累計予実達成率 */
    perGoalCumulativeSales: number;
    /** 累計前年対比 */
    perPrevCumulativeSales: number;
    /** 客数 */
    guestCnt: number;
    /** 目標客数 */
    guestCntBudget: number;
    /** 前年客数 */
    prevGuestCnt: number;
    /** 客数予実達成率 */
    perGoalGuestCnt: number;
    /** 客数前年対比 */
    perPrevGuestCnt: number;
    /** 客単価 */
    guestCntPrice: number;
    /** 目標客単価 */
    guestCntPriceBudget: number;
    /** 前年客単価 */
    prevGuestCntPrice: number;
    /** 仕入 */
    purchase: number;
    /** 原価率 */
    perPurchase: number;
    /** 累計仕入 */
    cumulativePurchase: number;
    /** 前年累計仕入 */
    prevCumulativePurchase: number;
    /** 累計仕入予算 */
    cumulativePurchaseBudget: number;
    /** 累計原価率 */
    perCumulativePurchase: number;
    /** 累計仕入目標原価率 */
    perCumulativePurchaseBudget: number;
    /** 累計仕入前年原価率 */
    perPrevCumulativePurchase: number;
    /** 累計仕入予実差率 */
    diffPerCumulativePurchase: number;
    /** 累計仕入前年差率 */
    diffPerPrevCumulativePurchase: number;
    /** 人件費 */
    laborCost: number;
    /** 人件費率 */
    perLaborCost: number;
    /** 累計人件費 */
    cumulativeLaborCost: number;
    /** 前年累計人件費 */
    prevCumulativeLaborCost: number;
    /** 累計人件費予算 */
    cumulativeLaborCostBudget: number;
    /** 累計人件費率 */
    perCumulativeLaborCost: number;
    /** 累計目標人件費率 */
    perCumulativeLaborCostBudget: number;
    /** 累計前年人件費率 */
    perPrevCumulativeLaborCost: number;
    /** 累計人件費予実差率 */
    diffPerCumulativeLaborCost: number;
    /** 累計人件費前年差率 */
    diffPerPrevCumulativeLaborCost: number;
    /** 固定費 */
    fixCost: number;
    /** 累計固定費 */
    cumulativeFixCost: number;
    /** 変動費 */
    variableCost: number;
    /** 累計変動費 */
    cumulativeVariableCost: number;
    /** 表示用社員労働時間 */
    employeeWorkTimeDisp: string;
    /** 表示用パート労働時間 */
    partWorkTimeDisp: string;
    /** 表示用合計労働時間 */
    totalWorkTimeDisp: string;
    /** 社員労働時間 */
    employeeWorkTime: number;
    /** パート労働時間 */
    partWorkTime: number;
    /** 合計労働時間 */
    totalWorkTime: number;
    /** 累計利益 */
    cumulativeProfit: number;
    /** 累計目標利益 */
    cumulativeProfitBudget: number;
    /** 累計利益予実達成率 */
    perGoalCumulativeProfit: number;
  }

export default class MonthlyDailyBudgetPerformanceManagementReportDomain {
  constructor(private rawData: MonthlyDailyBudgetPerformanceManagementReport) {
    // NOTE：パラメータープロパティで `rawData` の宣言と初期化を省略
  }

  static generateInitial(): MonthlyDailyBudgetPerformanceManagementReportDomain {
    return new MonthlyDailyBudgetPerformanceManagementReportDomain({
      targetDate: new Date(),
      netSales: 0,
      salesBudget: 0,
      prevSales: 0,
      perGoalSales: 0,
      perPrevSales: 0,
      cumulativeSales: 0,
      cumulativeSalesBudget: 0,
      perGoalCumulativeSales: 0,
      prevCumulativeSales: 0,
      perPrevCumulativeSales: 0,
      guestCnt: 0,
      guestCntBudget: 0,
      prevGuestCnt: 0,
      perGoalGuestCnt: 0,
      perPrevGuestCnt: 0,
      guestCntPrice: 0,
      guestCntPriceBudget: 0,
      prevGuestCntPrice: 0,
      purchase: 0,
      perPurchase: 0,
      cumulativePurchase: 0,
      prevCumulativePurchase: 0,
      cumulativePurchaseBudget: 0,
      perCumulativePurchase: 0,
      perCumulativePurchaseBudget: 0,
      perPrevCumulativePurchase: 0,
      diffPerCumulativePurchase: 0,
      diffPerPrevCumulativePurchase: 0,
      laborCost: 0,
      perLaborCost: 0,
      cumulativeLaborCost: 0,
      prevCumulativeLaborCost: 0,
      cumulativeLaborCostBudget: 0,
      perCumulativeLaborCost: 0,
      perCumulativeLaborCostBudget: 0,
      perPrevCumulativeLaborCost: 0,
      diffPerCumulativeLaborCost: 0,
      diffPerPrevCumulativeLaborCost: 0,
      fixCost: 0,
      cumulativeFixCost: 0,
      variableCost: 0,
      cumulativeVariableCost: 0,
      employeeWorkTimeDisp: '0',
      partWorkTimeDisp: '0',
      totalWorkTimeDisp: '0',
      employeeWorkTime: 0,
      partWorkTime: 0,
      totalWorkTime: 0,
      cumulativeProfit: 0,
      cumulativeProfitBudget: 0,
      perGoalCumulativeProfit: 0,
    });
  }

  getRawData(): MonthlyDailyBudgetPerformanceManagementReport {
    return this.rawData;
  }

  getOutputData() : Array<string> {
    return [
      this.targetDateDisp,
      this.netSales,
      this.salesBudget,
      this.prevSales,
      this.perGoalSales,
      this.perPrevSales,
      this.cumulativeSales,
      this.cumulativeSalesBudget,
      this.prevCumulativeSales,
      this.perGoalCumulativeSales,
      this.perPrevCumulativeSales,
      this.guestCnt,
      this.guestCntBudget,
      this.prevGuestCnt,
      this.perGoalGuestCnt,
      this.perPrevGuestCnt,
      this.guestCntPrice,
      this.guestCntPriceBudget,
      this.prevGuestCntPrice,
      this.purchase,
      this.perPurchase,
      this.cumulativePurchase,
      this.perCumulativePurchase,
      this.perCumulativePurchaseBudget,
      this.perPrevCumulativePurchase,
      this.diffPerCumulativePurchase,
      this.diffPerPrevCumulativePurchase,
      this.laborCost,
      this.perLaborCost,
      this.cumulativeLaborCost,
      this.perCumulativeLaborCost,
      this.perCumulativeLaborCostBudget,
      this.perPrevCumulativeLaborCost,
      this.diffPerCumulativeLaborCost,
      this.diffPerPrevCumulativeLaborCost,
      this.employeeWorkTimeDisp,
      this.partWorkTimeDisp,
      this.totalWorkTimeDisp,
      this.cumulativeFixCost,
      this.cumulativeVariableCost,
      this.cumulativeProfit,
      this.cumulativeProfitBudget,
      this.perGoalCumulativeProfit,
    ]
  }

  get targetDate() : Date {
    return this.rawData.targetDate;
  }

  get targetDateDisp() : string {
    if(!this.rawData.targetDate) return '合計';
    return moment(this.rawData.targetDate, 'YYYY-MM-DD').format('YYYY年MM月DD日（ddd）');
  }

  get targetDateUrlParam() : string {
    return moment(this.rawData.targetDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }

  get netSales() : string {
    return formatNumber(this.rawData.netSales);
  }

  get salesBudget() : string {
    return formatNumber(this.rawData.salesBudget);
  }

  get prevSales() : string {
    return formatNumber(this.rawData.prevSales);
  }

  get perGoalSales() : string {
    return formatPercent(this.rawData.perGoalSales);
  }

  get perPrevSales() : string {
    return formatPercent(this.rawData.perPrevSales);
  }

  get cumulativeSales() : string {
    return formatNumber(this.rawData.cumulativeSales);
  }

  get cumulativeSalesBudget() : string {
    return formatNumber(this.rawData.cumulativeSalesBudget);
  }

  get perGoalCumulativeSales() : string {
    return formatPercent(this.rawData.perGoalCumulativeSales);
  }

  get prevCumulativeSales() : string {
    return formatNumber(this.rawData.prevCumulativeSales);
  }

  get perPrevCumulativeSales() : string {
    return formatPercent(this.rawData.perPrevCumulativeSales);
  }

  get guestCnt() : string {
    return formatNumber(this.rawData.guestCnt);
  }

  get guestCntBudget() : string {
    return formatNumber(this.rawData.guestCntBudget);
  }

  get prevGuestCnt() : string {
    return formatNumber(this.rawData.prevGuestCnt);
  }

  get perGoalGuestCnt() : string {
    return formatPercent(this.rawData.perGoalGuestCnt);
  }

  get perPrevGuestCnt() : string {
    return formatPercent(this.rawData.perPrevGuestCnt);
  }

  get guestCntPrice() : string {
    return formatNumber(this.rawData.guestCntPrice);
  }

  get guestCntPriceBudget() : string {
    return formatNumber(this.rawData.guestCntPriceBudget);
  }

  get prevGuestCntPrice() : string {
    return formatNumber(this.rawData.prevGuestCntPrice);
  }

  get purchase() : string {
    return formatNumber(this.rawData.purchase);
  }

  get perPurchase() : string {
    return formatPercent(this.rawData.perPurchase);
  }

  get cumulativePurchase() : string {
    return formatNumber(this.rawData.cumulativePurchase);
  }

  get prevCumulativePurchase() : string {
    return formatNumber(this.rawData.prevCumulativePurchase);
  }

  get cumulativePurchaseBudget() : string {
    return formatNumber(this.rawData.cumulativePurchaseBudget);
  }

  get perCumulativePurchase() : string {
    return formatPercent(this.rawData.perCumulativePurchase);
  }

  get perCumulativePurchaseBudget() : string {
    return formatPercent(this.rawData.perCumulativePurchaseBudget);
  }

  get perPrevCumulativePurchase() : string {
    return formatPercent(this.rawData.perPrevCumulativePurchase);
  }

  get diffPerCumulativePurchase() : string {
    return formatPercent(this.rawData.diffPerCumulativePurchase);
  }

  get diffPerPrevCumulativePurchase() : string {
    return formatPercent(this.rawData.diffPerPrevCumulativePurchase);
  }

  get laborCost() : string {
    return formatNumber(this.rawData.laborCost);
  }

  get perLaborCost() : string {
    return formatPercent(this.rawData.perLaborCost);
  }

  get cumulativeLaborCost() : string {
    return formatNumber(this.rawData.cumulativeLaborCost);
  }

  get prevCumulativeLaborCost() : string {
    return formatNumber(this.rawData.prevCumulativeLaborCost);
  }

  get cumulativeLaborCostBudget() : string {
    return formatNumber(this.rawData.cumulativeLaborCostBudget);
  }

  get perCumulativeLaborCost() : string {
    return formatPercent(this.rawData.perCumulativeLaborCost);
  }

  get perCumulativeLaborCostBudget() : string {
    return formatPercent(this.rawData.perCumulativeLaborCostBudget);
  }

  get perPrevCumulativeLaborCost() : string {
    return formatPercent(this.rawData.perPrevCumulativeLaborCost);
  }

  get diffPerCumulativeLaborCost() : string {
    return formatPercent(this.rawData.diffPerCumulativeLaborCost);
  }

  get diffPerPrevCumulativeLaborCost() : string {
    return formatPercent(this.rawData.diffPerPrevCumulativeLaborCost);
  }

  get fixCost() : string {
    return formatNumber(this.rawData.fixCost);
  }

  get cumulativeFixCost() : string {
    return formatNumber(this.rawData.cumulativeFixCost);
  }

  get variableCost() : string {
    return formatNumber(this.rawData.variableCost);
  }

  get cumulativeVariableCost() : string {
    return formatNumber(this.rawData.cumulativeVariableCost);
  }

  get employeeWorkTimeDisp() : string {
    return this.rawData.employeeWorkTimeDisp;
  }

  get partWorkTimeDisp() : string {
    return this.rawData.partWorkTimeDisp;
  }

  get totalWorkTimeDisp() : string {
    return this.rawData.totalWorkTimeDisp;
  }

  get employeeWorkTime() : number {
    return this.rawData.employeeWorkTime;
  }

  get partWorkTime() : number {
    return this.rawData.partWorkTime;
  }

  get totalWorkTime() : number {
    return this.rawData.totalWorkTime;
  }

  get cumulativeProfit() : string {
    return formatNumber(this.rawData.cumulativeProfit);
  }

  get cumulativeProfitBudget() : string {
    return formatNumber(this.rawData.cumulativeProfitBudget);
  }

  get perGoalCumulativeProfit() : string {
    return formatPercent(this.rawData.perGoalCumulativeProfit);
  }

}
