import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import ShogunStampHistoryForm from 'components/organismos/master/sales/ShogunStampHistoryForm';

const ShogunStampHistoryPage = () => (
  <SidebarTemplate pageTitle='出退勤状況'>
    <ShogunStampHistoryForm />
  </SidebarTemplate>
);

export default ShogunStampHistoryPage;
