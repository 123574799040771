/** @jsx jsx */
import React, { ReactNode, ComponentProps } from 'react';
import { css, jsx } from '@emotion/core';

import { textFontSize, textColor, utilityColor } from 'components/styles';
import FormLabel from 'components/atoms/Form/FormLabel';
import RadioSelector from './RadioSelector';

const styles = {
  subLabel: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: '4px',
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: utilityColor.error,
    marginLeft: '4px',
  }),
};

const RadioSelectForm: React.FC<{
  label?: string;
  subLabel?: string;
  note?: string|ReactNode;
  required?: boolean;
  readOnly?: boolean;
  noTitle?: boolean;
  selectorInline?: boolean;
  labelInline?: boolean;
  disabled?: boolean;
  labelInlineWidth?:number;
} & ComponentProps<typeof RadioSelector>> = ({
  label, subLabel, items, name, value, setValue, note, required, readOnly = false, noTitle = false, selectorInline = false, labelInline = false, disabled = false, labelInlineWidth= 0,
}) => (
  <div>
    {label && !labelInline
    && (
    <FormLabel
      label={label}
      subText={subLabel}
      required={required}
    />
    )}
    <RadioSelector
      items={items}
      name={name}
      value={value}
      setValue={setValue}
      readOnly={readOnly}
      noTitle={noTitle}
      selectorInline={selectorInline}
      disabled={disabled}
      labelInline={labelInline}
      label={label}
      labelInlineWidth={labelInlineWidth}
    />
    {note && <span css={styles.noteText}>{note}</span>}
  </div>
);

export default RadioSelectForm;
