import React from 'react';

import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { Link, useParams } from 'react-router-dom';
import ConfirmModal from 'components/organismos/ConfirmModal';
import FormContents from 'components/atoms/Form/FormContents';
import { useAccountTitleMSTDomainForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const AccountTitleMSTForm: React.FC = () => {
  const { accountTitleId } = useParams();
  const {
    formik, confirmModalOpen, closeConfirmModal,
  } = useAccountTitleMSTDomainForm(accountTitleId);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <div>
      <FormContents>
        <p><strong>勘定科目マスタを設定します。コードと名称を入力してください。</strong></p>
        <form>
          <FormField>
            <TextForm
              name="accountTitleCode"
              label="勘定科目コード"
              value={formik.values.accountTitleCode}
              required={true}
              onChange={formik.handleChange}
            />
          </FormField>
          <FormField>
            <TextForm
              name="accountTitleName"
              label="勘定科目名"
              value={formik.values.accountTitleName}
              required={true}
              onChange={formik.handleChange}
            />
          </FormField>
          <FormSubmitArea>
            {
              roleScreen && roleScreen.editable === 1 && (
                <div style={{ marginRight: '12px' }}>
                  <Button
                    text={accountTitleId ? '更新' : '登録'}
                    onClick={formik.handleSubmit}
                  />
                </div>
              )
            }
            <Link to="/accountTitleMST">
              <Button
                text="戻る"
                ghost={true}
              />
            </Link>
          </FormSubmitArea>
        </form>
      </FormContents>

      <ConfirmModal
        title="確認メッセージ"
        content={accountTitleId ? '更新します。よろしいですか？' : '登録します。よろしいですか？'}
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.submitForm}
        items={[]}
      />
    </div>
  );
};

export default AccountTitleMSTForm;
