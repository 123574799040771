import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getCompanyBankList } from 'api/companyBank';

/**
 * 所在地の選択ボックス用データを取得
 */
export const useCompanyBankOptions = (): Array<OptionType> => {
  const [companyBankOptions, setCompanyBankOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const param = {
      hasOther: true,
    };
    getCompanyBankList(param).then((response: any) => {
      response && setCompanyBankOptions(response.companyBankList.map((companyBank: any) => ({
        value: companyBank.bankCode,
        label: companyBank.bankName,
      })));
    })
  }, []);
  return companyBankOptions;
};

export default useCompanyBankOptions;
