import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import TimeRecorderGpsForm from 'components/organismos/AttendTimeRecorder/TimeRecorderGpsForm/TimeRecorderGpsForm';

const TimeRecorderGPSPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="GPS打刻"
  >
    <TimeRecorderGpsForm />
  </SidebarTemplate>
);

export default TimeRecorderGPSPage;
