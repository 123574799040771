import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getOutputBaseItems } from 'api/timeOutputLayout';

/**
 * output base items
 *
 *
 */
export const useOutputBaseItems = (baseItemType: number): Array<OptionType> => {
  const [outputBaseItemsOptions, setOutputBaseItemsOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getOutputBaseItems(baseItemType).then((items) => {
      setOutputBaseItemsOptions(items.map((item: any) => ({
        value: item.baseItemCode,
        label: `${item.baseItemName}`,
      })));
    });
  }, [baseItemType]);
  return outputBaseItemsOptions;
};

export default useOutputBaseItems;
