/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
/* eslint-disable no-return-assign */
import { getSalesBudgetYearly } from 'api/storeManagement';
import { useEffect, useState, useCallback,useReducer } from 'react';
import { OptionType } from 'components/atoms/Select';
import { getSalesExpensesList } from 'api/salesExpensesMST';
import { useFormik } from 'formik';
import StoreManagementDomain from 'domain/master/storeManagement/salesBudgetYear';
import moment from 'moment';
import {
  updateSalesBudgetYear,
  getFiscalYearFromDate,
  getFiscalYearFromDateToYear,
} from 'api/salesBudgetYear';
import useToastNotification from 'hooks/useToastNotification';
import {initialState,reducer} from './reducer'
import {fetch,get,input_change, enable_month_link} from './actions'
import {SaleBudgetYearType, SalesBudgetMonthly, SalesExpensesListType} from './type';
import {formatter,numberFormaterInput,removeComma} from './functions'

type ErrorType = {
  defaultMessage:string
}

type StartEndTime = {
  start : Date,
  end: Date
}

export const useSalesBudgetYear = (year: number, orgCode: string) => {
  const [yearFormState,dispatch] = useReducer(reducer,initialState)
  const handleChange = (e: any ) =>{
    e.preventDefault();
    const { target } = e;
    // let caret = target.selectionStart;
    // const element = target;
    // if (numberFormaterInput(removeComma(target.value)).split('').length - target.value.length === 1) {
    //   caret = caret + 1
    // } else if (numberFormaterInput(removeComma(target.value)).split('').length - target.value.length === -1) {
    //   caret = caret - 1
    //  if(caret === -1){
    //    caret = 0
    //  }else if(target.value.indexOf('-') !== -1 && caret === 0){
    //    caret = 1
    //  }
    // }
    // window.requestAnimationFrame(() => {
    //   element.selectionStart = caret
    //   element.selectionEnd = caret
    // });
    dispatch(input_change(e.target));

  }
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const [categoryAry, setCategoryAry] = useState<Array<string>>([]);
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);
  const [getIsLoading, setIsLoading] = useState(true);
  const [getSalesExpensesMSTList, setSalesExpensesMSTList] = useState<any>([]);

  // values: StoreManagementDomain
  const {
    salesBudgetTotal,
    costTotal,
    listOperatingIncomeCost,
    salesBudgetFree,
    foodCost,
    drinkCost,
    laborCostTotal,
    employeeLaborCost,
    partLaborCost,
    landRentTotalCost,
    listTotalPerCustomerPrice,
    otherPurchase,
    commonServiceFeeCost,
    rentCost,
    listGuestCntTotal,
    listAdvertising,
    listAdvertisingExpense,
    listExpenses,
    salesBudgetAdvertising,
    grossProfitTotal,
    listAdvertisingExpenseTotal,
    listClassification0,
    listClassification1,
    listExpensesTotal
  } = yearFormState.monthlyListData

  const onSubmit = async () => {
    setConfirmModalOpen(true);
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
    } else {
      setConfirmModalOpen(false);
      setIsLoading(true);
      let listData = '';
      const createUser = sessionStorage.getItem('loginUser.staffName') || '';
      const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
      const orgCode = sessionStorage.getItem('orgCode_DestinationOrg') || '';
      if (yearFormState.generalData.salesBudgetMonthly) {
        yearFormState.generalData.salesBudgetMonthly.map((element: SalesBudgetMonthly, i: number) => {
          const salesBudgetAdvertisingPost =  !isNaN(salesBudgetAdvertising[i]) ? Number(salesBudgetAdvertising[i]) : 0
          const salesBudgetFreePost =  !isNaN(salesBudgetFree[i]) ? Number(salesBudgetFree[i]) : 0
          const salesBudgetTotalPost = !isNaN(salesBudgetTotal[i]) ? Number(salesBudgetTotal[i]) : 0
          const costTotalPost = !isNaN(costTotal[i]) ? Number(costTotal[i]) : 0
          const foodCostPost =  !isNaN(foodCost[i]) ? Number(foodCost[i]) : 0
          const drinkCostPost =  !isNaN(drinkCost[i]) ? Number(drinkCost[i]) : 0
          const employeeLaborCostPost =  !isNaN(employeeLaborCost[i]) ? Number(employeeLaborCost[i]) : 0
          const partLaborCostPost =  !isNaN(partLaborCost[i]) ? Number(partLaborCost[i]) : 0
          const laborCostTotalPost = !isNaN(laborCostTotal[i]) ? Number(laborCostTotal[i]) : 0
          const advertisingMediaExpensePost =  !isNaN(listAdvertisingExpenseTotal[i]) ? Number(listAdvertisingExpenseTotal[i]) : 0
          const landRentPost =  !isNaN(landRentTotalCost[i]) ? Number(landRentTotalCost[i]) : 0
          const otherExpensesTotalPost =  !isNaN(listExpensesTotal[i]) ? Number(listExpensesTotal[i]) : 0
          const otherVariableCostsPost =  !isNaN(listClassification0[i]) ? Number(listClassification0[i]) : 0
          const otherFixedCostPost =  !isNaN(listClassification1[i]) ? Number(listClassification1[i]) : 0
          const grossProfitPost =  !isNaN(grossProfitTotal[i]) ? Number(grossProfitTotal[i]) : 0
          const rentPost =   !isNaN(rentCost[i]) ? Number(rentCost[i]) : 0
          const commonServiceFeePost =  !isNaN(commonServiceFeeCost[i]) ? Number(commonServiceFeeCost[i]) : 0
          const otherPurchasePost =  !isNaN(otherPurchase[i]) ? Number(otherPurchase[i]) : 0
          const perCustomerPricePost =  !isNaN(listTotalPerCustomerPrice[i]) ? Number(listTotalPerCustomerPrice[i]) : 0
          const operatingIncomePost = !isNaN(listOperatingIncomeCost[i]) ? Number(listOperatingIncomeCost[i]) : 0
          const guestCntPost = !isNaN(listGuestCntTotal[i]) ? Number(listGuestCntTotal[i]) : 0
          // const operatingIncomeItem = listOperatingIncomeCost[i];
          listData += `&listSalesBudgetMonthly[${i}].createUser=${createUser}`;
          listData += `&listSalesBudgetMonthly[${i}].updateUser=${createUser}`;
          listData += `&listSalesBudgetMonthly[${i}].id.companyCode=${companyCode}`;
          listData += `&listSalesBudgetMonthly[${i}].id.orgCode=${orgCode}`;
          listData += `&listSalesBudgetMonthly[${i}].id.targetMonth=${element.id.targetMonth}`;
          listData += `&listSalesBudgetMonthly[${i}].salesBudgetTotal=${salesBudgetTotalPost}`;
          listData += `&listSalesBudgetMonthly[${i}].salesBudgetAdvertising=${salesBudgetAdvertisingPost}`;
          listData += `&listSalesBudgetMonthly[${i}].salesBudgetFree=${salesBudgetFreePost}`;
          listData += `&listSalesBudgetMonthly[${i}].costTotal=${costTotalPost}`;
          listData += `&listSalesBudgetMonthly[${i}].foodCost=${foodCostPost}`;
          listData += `&listSalesBudgetMonthly[${i}].drinkCost=${drinkCostPost}`;
          listData += `&listSalesBudgetMonthly[${i}].grossProfit=${grossProfitPost}`;
          listData += `&listSalesBudgetMonthly[${i}].laborCostTotal=${laborCostTotalPost}`;
          listData += `&listSalesBudgetMonthly[${i}].employeeLaborCost=${employeeLaborCostPost}`;
          listData += `&listSalesBudgetMonthly[${i}].partLaborCost=${partLaborCostPost}`;
          listData += `&listSalesBudgetMonthly[${i}].advertisingMediaExpense=${advertisingMediaExpensePost}`;
          listData += `&listSalesBudgetMonthly[${i}].landRent=${landRentPost}`;
          listData += `&listSalesBudgetMonthly[${i}].otherExpensesTotal=${otherExpensesTotalPost}`;
          listData += `&listSalesBudgetMonthly[${i}].otherVariableCosts=${otherVariableCostsPost}`; // total 変 rows + other purchase
          listData += `&listSalesBudgetMonthly[${i}].otherFixedCost=${otherFixedCostPost}`;     // total 固 rows
          listData += `&listSalesBudgetMonthly[${i}].operatingIncome=${operatingIncomePost}`;
          listData += `&listSalesBudgetMonthly[${i}].perCustomerPrice=${perCustomerPricePost}`;
          listData += `&listSalesBudgetMonthly[${i}].otherPurchase=${otherPurchasePost}`;
          listData += `&listSalesBudgetMonthly[${i}].commonServiceFee=${commonServiceFeePost}`;
          listData += `&listSalesBudgetMonthly[${i}].rent=${rentPost}`;
          listData += `&listSalesBudgetMonthly[${i}].guestCnt=${guestCntPost}`;
          yearFormState.monthlyListDataInfo.listAdvertising.length > 0 && listAdvertising.length > 0 && listAdvertising.map((item: Array<Array<number | null | string>>, j: number) => {
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].id.advertisingMediaMstCode=${yearFormState.monthlyListDataInfo.listAdvertising[j][0]}`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].id.companyCode=${companyCode}`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].id.orgCode=${orgCode}`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].id.targetMonth=${element.id.targetMonth}`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].createUser=${createUser}`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].updateUser=${createUser}`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].advertisingMediaAmount=${
              !isNaN(listAdvertising[j][i]) ? Number(listAdvertising[j][i]) : 0
            }`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].advertisingMediaExpense=${
              !isNaN(listAdvertisingExpense[j][i]) ? Number(listAdvertisingExpense[j][i]) : 0
            }`;
          });
          yearFormState.monthlyListDataInfo.listExpenses.length > 0 && listExpenses.length > 0 && listExpenses.map((item: Array<Array<number | null | string>>, j: number) => {
            listData += `&listMonthlyExpenses[${j}][${i}].id.companyCode=${companyCode}`;
            listData += `&listMonthlyExpenses[${j}][${i}].id.orgCode=${orgCode}`;
            listData += `&listMonthlyExpenses[${j}][${i}].id.targetMonth=${element.id.targetMonth}`;
            listData += `&listMonthlyExpenses[${j}][${i}].id.expensesCode=${yearFormState.monthlyListDataInfo.listExpenses[j][0]}`;
            listData += `&listMonthlyExpenses[${j}][${i}].createUser=${createUser}`;
            listData += `&listMonthlyExpenses[${j}][${i}].updateUser=${createUser}`;
            listData += `&listMonthlyExpenses[${j}][${i}].expensesAmount=${!isNaN(listExpenses[j][i]) ? Number(listExpenses[j][i]) : 0}`;
          });
        });
      }

      const response = await updateSalesBudgetYear(companyCode, listData);
      console.log(response,'check error')
      setIsLoading(false);
      if (!response.errors) {
        dispatch(enable_month_link(yearFormState));
        successNotification('登録しました。');
      } else if (response && response.data && response.data.errors) {
        const listErr = response.data.errors;
        let stringErr = '';
        listErr.forEach((element:ErrorType) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      return false;
    }
  };
  const fetchListExpense = async () => {
    getSalesExpensesList().then((response: SalesExpensesListType) => {
      setSalesExpensesMSTList(response);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getSalesBudgetYearly(
      { orgCode: orgCode || sessionStorage.getItem('orgCode') || 'all', targetYear: `${year}/01/01`, categoryAry },
    ).then((response:SaleBudgetYearType) => {
      dispatch(fetch(response))
      dispatch(get(yearFormState))
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, orgCode, categoryAry]);

  useEffect(() => {
    fetchListExpense();
  }, []);
  return {
    handleChange,
    yearFormState,
    getIsLoading,
    setIsLoading,
    confirmModalOpen,
    closeConfirmModal,
    categoryAry,
    setCategoryAry,
    onSubmit,
    getSalesExpensesMSTList
  };
};

export const useGetFiscalYear2 = (dateSelect: number) => {
  const Start = sessionStorage.getItem('fiscalStartEnd.Start') || new Date();
  const End = sessionStorage.getItem('fiscalStartEnd.End') || new Date();


  const [fiscalStartEnd, setfiscalStartEnd] = useState<StartEndTime>({
    start: moment(Start).toDate(),
    end: moment(End).toDate(),
  });
  useEffect(() => {
    getFiscalYearFromDateToYear(dateSelect).then((response) => {
      const from = response.fiscalStartMonth;
      const to = response.fiscalEndMonth;
      const dateObject = { start: from, end: to };
      setfiscalStartEnd(dateObject);
    });
  }, [dateSelect]);
  return { fiscalStartEnd };
};


export const downloadOriginalFile = async (
  response: any,
  downloadFileName: string,
) => {
  console.log(response,'check download')
  const file = new Blob([response], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(file, downloadFileName);
};
// eslint-disable-next-line camelcase
export const setFileName = (
  orgCode: string,
  dateSelect: string | null | number,
  procDateStr: string | null | number,
) => {
  const fileName = `${
    orgCode === 'all'
      ? '全店舗'
      : orgCode
        .trim()
        .replace(/\\/g, '￥')
        .replace(/\//g, '／')
        .replace(/:/g, '：')
        .replace(/\*/g, '＊')
        .replace(/\?/g, '？')
        .replace(/\/"/g, '”')
        .replace(/</g, '＜')
        .replace(/>/g, '＞')
        .replace(/\|/g, '｜')
  }_年間月次予算${dateSelect}_${procDateStr}${
    orgCode === 'all' ? '.zip' : '.xlsx'
  }`;
  return fileName;
};
export const eventScroll = (e:{ target: any }) => {
  const { target } = e;
  const contentRight = document.getElementById('contentRight');
  const contentRight2 = document.getElementById('contentRight2');
  const headRight = document.getElementById('headRight');
  const scrollBottom = document.getElementById('scrollBottom');
  if(e.target.type !=='text'){
    if (contentRight) {
      contentRight.scrollLeft = target.scrollLeft;
    }
    if (contentRight2) {
      contentRight2.scrollLeft = target.scrollLeft;
    }
    if (headRight) {
      headRight.scrollLeft = target.scrollLeft;
    }

    if (scrollBottom) {
      scrollBottom.scrollLeft = target.scrollLeft;
    }
  }

};

export default useSalesBudgetYear;
