import { useState, useEffect } from 'react';
import { OptionType } from 'components/atoms/Select';
import { getPaymentMediaOrganizationList } from 'api/paymentMediaOrganization';

/**
 * Get payment media options
 */
export const usePaymentMediaOptions = (): Array<OptionType> => {
  const [paymentMediaOptions, setPaymentMediaOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getPaymentMediaOrganizationList().then((paymentMedias) => {
      setPaymentMediaOptions(paymentMedias.map((paymentMedia) => ({
        value: paymentMedia[1],
        label: paymentMedia[2],
      })));
    });
  }, []);
  return paymentMediaOptions;
};

export default usePaymentMediaOptions;
