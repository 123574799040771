import { useState, useEffect, useCallback } from 'react';

import { getAttendAchievementLockList, modifyAttendAchievementLock, ModifyInfo, AttendAchievementLock } from 'api/attendAchievementLock';
import AttendAchievementLockDomain from 'domain/master/attend/attendAchievementLockDomain';
import moment from 'moment';
import useToastNotification from 'hooks/useToastNotification';
import { getErrorMessageForResponse } from 'utility/errorUtil';
import { SessionStorageKey } from 'utility/constants';
import { ErrorMessage } from 'utility/messages';

export type selectedRow = {
  rowIndex  : number,
  targetDate: Date,
  orgCode   : string,
  lockStatus: string,
  lockable  : boolean
}

export const useAttendAchievementLock = () => {
  const functionType = 2;

  const sessionTargetDateFrom = sessionStorage.getItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_TARGET_DATE_FROM);
  const sessionTargetDateTo = sessionStorage.getItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_TARGET_DATE_TO);
  const sessionCategoryArr = JSON.parse(String(sessionStorage.getItem(SessionStorageKey.Common.ORG_FILTER_TREE_TOGGLE(window.location.pathname))));
  const sessionOrgCode = sessionStorage.getItem(SessionStorageKey.Common.SELECT_ORG_CODE('attendAchievementLock')) || sessionStorage.getItem(SessionStorageKey.LoginUser.ORG_CODE);
  const sessionLockStatus = sessionStorage.getItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_LOCK_STATUS);
  const sessionIsError = sessionStorage.getItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_IS_ERROR);
  const sessionIsAlertOvertime = sessionStorage.getItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_IS_ALERT_OVERTIME);
  const sessionIsAlertHolidaywork = sessionStorage.getItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_IS_ALERT_HOLIDAYWORK);
  const sessionIsDisapproval = sessionStorage.getItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_IS_DISAPPROVAL);
  const sessionIsLockable = sessionStorage.getItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_IS_LOCKABLE);

  const [isLoading, setLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [targetDateFrom, setTargetDateFrom] = useState(sessionTargetDateFrom ? new Date(sessionTargetDateFrom) : moment().add(-1, 'days').toDate());
  const [targetDateTo, setTargetDateTo] = useState(sessionTargetDateTo ? new Date(sessionTargetDateTo) : new Date());
  const [categoryArr, setCategoryArr] = useState<Array<String>>(sessionCategoryArr || []);
  const [orgCode, setOrgCode] = useState(sessionOrgCode || 'all');
  const [lockStatus, setLockStatus] = useState(sessionLockStatus || '0');
  const [isError, setIsError] = useState<boolean>(sessionIsError?.toLowerCase() === 'true' || false);
  const [isAlertOvertime, setIsAlertOvertime] = useState<boolean>(sessionIsAlertOvertime?.toLowerCase() === 'true' || false);
  const [isAlertHolidaywork, setIsAlertHolidaywork] = useState<boolean>(sessionIsAlertHolidaywork?.toLowerCase() === 'true' || false);
  const [isDisapproval, setIsDisapproval] = useState<boolean>(sessionIsDisapproval?.toLowerCase() === 'true' || false);
  const [isLockable, setIsLockable] = useState<boolean>(sessionIsLockable?.toLowerCase() === 'true' || false);

  const [availableDailyShift, setAvailableDailyShift] = useState(false);
  const [availableAttendApplication, setAvailableAttendApplication] = useState(false);

  const [attendAchievementLockList, setAttendAchievementLockList] = useState<Array<AttendAchievementLockDomain>>([]);
  const [selectedRows, setSelectedRows] = useState<selectedRow[]>([]);

  const { successNotification, errorNotification } = useToastNotification();

  const fetchAttendAchievementLockList = useCallback(async () => {
    if (!isLoading) {
      setLoading(true);
    }
    try {
      const response = await getAttendAchievementLockList(
                                targetDateFrom, targetDateTo, functionType, orgCode, categoryArr, lockStatus,
                                isError, isAlertOvertime, isAlertHolidaywork, isDisapproval, isLockable);

      setAttendAchievementLockList(response.lockList.map(
        (result: AttendAchievementLock) => new AttendAchievementLockDomain(result)),
      );
      setAvailableDailyShift(response.availableDailyShift);
      setAvailableAttendApplication(response.availableAttendApplication);
      setSelectedRows([]);
      sessionStorage.setItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_TARGET_DATE_FROM, moment(targetDateFrom).format('YYYY-MM-DD'));
      sessionStorage.setItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_TARGET_DATE_TO, moment(targetDateTo).format('YYYY-MM-DD'));
      sessionStorage.setItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_LOCK_STATUS, lockStatus);
      sessionStorage.setItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_IS_ERROR, String(isError));
      sessionStorage.setItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_IS_ALERT_OVERTIME, String(isAlertOvertime));
      sessionStorage.setItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_IS_ALERT_HOLIDAYWORK, String(isAlertHolidaywork));
      sessionStorage.setItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_IS_DISAPPROVAL, String(isDisapproval));
      sessionStorage.setItem(SessionStorageKey.Attend.AttendAchievementLock.SEARCH_IS_LOCKABLE, String(isLockable));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else if (error.response && error.response.data && Array.isArray(error.response.data)) {
        const normalError = error.response.data;
        let stringNormalError = '';
        normalError.map((element: any) => {
          stringNormalError += `${element.defaultMessage} \n`;
          return stringNormalError;
        });
        errorNotification(stringNormalError);
      } else {
        errorNotification(ErrorMessage.Common.INTERNAL_SERVER_ERROR);
      }
    }
  }, [targetDateFrom, targetDateTo, orgCode, categoryArr, lockStatus, isError, isAlertOvertime, isAlertHolidaywork, isDisapproval, isLockable]);

  useEffect(() => {
    const from = moment(targetDateFrom);
    const to = moment(targetDateTo);
    if(from.isAfter(to)) {
      return;
    }
    if(to.diff(from, 'days') > 30) {
      errorNotification(ErrorMessage.AttendAchievementLock.TERM_OVER_TARGET_DATE(1));
      return;
    }
    setLoading(true);
    fetchAttendAchievementLockList()
  }, [targetDateFrom, targetDateTo, orgCode, categoryArr, lockStatus, isError, isAlertOvertime, isAlertHolidaywork, isDisapproval, isLockable]);

  const lockAttendAchievement = useCallback(async () => {
    setConfirmModalOpen(false);
    const lockList = selectedRows
                          .filter(r => r.lockable && r.lockStatus === '0')
                          .map(r => ({ targetDate: moment(r.targetDate).format('YYYY-MM-DD'), orgCode: r.orgCode } as ModifyInfo));
    try {
      setLoading(true);
      await modifyAttendAchievementLock(lockList, 'lock');
      const response = await getAttendAchievementLockList(
        targetDateFrom, targetDateTo, functionType, orgCode, categoryArr, lockStatus,
        isError, isAlertOvertime, isAlertHolidaywork, isDisapproval, isLockable);
      setAttendAchievementLockList(
        response.lockList.map((result: any) => new AttendAchievementLockDomain(result)),
      );
      setAvailableDailyShift(response.availableDailyShift);
      setAvailableAttendApplication(response.availableAttendApplication);
      setSelectedRows([]);
    } catch(error) {
      const message = getErrorMessageForResponse(error);
      errorNotification(message);
    } finally {
      setLoading(false);
    }
  }, [selectedRows, attendAchievementLockList])

  const unlockAttendAchievement = useCallback(async () => {
    const lockList = selectedRows
                          .filter(r => r.lockStatus === '1')
                          .map(r => ({ targetDate: moment(r.targetDate).format('YYYY-MM-DD'), orgCode: r.orgCode } as ModifyInfo));
    try {
      setLoading(true);
      await modifyAttendAchievementLock(lockList, 'unlock');
      const response = await getAttendAchievementLockList(
        targetDateFrom, targetDateTo, functionType, orgCode, categoryArr, lockStatus,
        isError, isAlertOvertime, isAlertHolidaywork, isDisapproval, isLockable);
      setAttendAchievementLockList(
        response.lockList.map((result: any) => new AttendAchievementLockDomain(result)),
      );
      setAvailableDailyShift(response.availableDailyShift);
      setAvailableAttendApplication(response.availableAttendApplication);
      setSelectedRows([]);
    } catch(error) {
      const message = getErrorMessageForResponse(error);
      errorNotification(message);
    } finally {
      setLoading(false);
    }
  }, [selectedRows, attendAchievementLockList])

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    isLoading,
    setLoading,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    categoryArr,
    setCategoryArr,
    orgCode,
    setOrgCode,
    lockStatus,
    setLockStatus,
    isError,
    setIsError,
    isAlertOvertime,
    setIsAlertOvertime,
    isAlertHolidaywork,
    setIsAlertHolidaywork,
    isDisapproval,
    setIsDisapproval,
    isLockable,
    setIsLockable,
    availableDailyShift,
    setAvailableDailyShift,
    availableAttendApplication,
    setAvailableAttendApplication,
    attendAchievementLockList,
    setAttendAchievementLockList,
    selectedRows,
    setSelectedRows,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    lockAttendAchievement,
    unlockAttendAchievement,
  };
};

export default {
  useAttendAchievementLock,
};
