import { useState, useCallback, useEffect } from 'react';
import { ManagerDataStaffDomain } from 'domain/master/general/managerDataStaff';
import moment from 'moment';

export const useManagerEdit = (manager: ManagerDataStaffDomain, setManager: (manager: ManagerDataStaffDomain) => void) => {
  const [startDate, setStartDate] = useState<Date>(manager.startDate || new Date());
  const [endDate, setEndDate] = useState<Date|null>(manager.endDate || null);
  const [isEndDateUndecided, setEndDateUndecided] = useState<boolean>(!manager.endDateStr);
  const [type, setType] = useState<number>(manager.managerType);

  useEffect(() => {
    // NOTE: マネージャー更新APIで適用終了日が自動調整された場合 未定チェックボックス(isEndDateUndecided)が適用終了日と連動していないため、適用終了日の変更時に更新する
    setEndDateUndecided(!manager.endDateStr);
  }, [manager.endDateStr])

  const changeStartDate = useCallback((date: Date) => {
    setStartDate(date);
    setManager(new ManagerDataStaffDomain({
      ...manager.getRawData(),
      startDateStr: moment(date).format('YYYY年MM月DD日'),
    }));
  }, [manager, setManager]);

  const changeEndDate = useCallback((date: Date|null) => {
    setEndDate(date);
    setManager(new ManagerDataStaffDomain({
      ...manager.getRawData(),
      endDateStr: date ? moment(date).format('YYYY年MM月DD日') : null,
    }));
  }, [manager, setManager]);

  const changeType = useCallback((typeManager: number) => {
    setType(typeManager);
    setManager(new ManagerDataStaffDomain({
      ...manager.getRawData(),
      managerType: typeManager,
    }));
  }, [manager, setManager]);

  const toggleEndDateUndecided = useCallback(() => {
    if (!isEndDateUndecided) {
      // 未定にしたら、endDateも空にする
      changeEndDate(null);
    }
    setEndDateUndecided(!isEndDateUndecided);
  }, [changeEndDate, isEndDateUndecided]);

  return {
    isEndDateUndecided,
    toggleEndDateUndecided,
    startDate,
    endDate,
    type,
    changeStartDate,
    changeEndDate,
    changeType,
  };
};

export default {};
