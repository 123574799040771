import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AdvertisingMasterImportForm
    from 'components/organismos/master/sales/AdvertisingMasterImportForm';

const AdvertisingMasterImportPage: React.FC = () => (
    <SidebarTemplate pageTitle="広告取込インポート">
        <AdvertisingMasterImportForm />
    </SidebarTemplate>
);

export default AdvertisingMasterImportPage
