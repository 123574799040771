import ApiClient from './ApiClient';

export const createOrUpdateVacation = async (companyCode: string, isAccepted: boolean,
  vacationObj: any) => {
  const query = {
    isAccepted,
  };

  const params = vacationObj;

  const response = await ApiClient.post(`/v3/holidays/attends/${companyCode}`, query, params);
  return response.data;
};


export const getAttendVacation = async (companyCode: string, vacationId: string): Promise<any> => {
  const params = { holidayId: vacationId };

  const response = await ApiClient.get(`/v1/holidays/attends/${companyCode}`, params);
  return response.data;
};

export const getSpecialHolidays = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/holidays/attends/list/auto/special/${companyCode}`, {});
  return response.data;
};

export default {
  createOrUpdateVacation,
};
