import { useState, useCallback } from 'react';
import ManagerDomain from 'domain/manager';
import moment from 'moment';
import { isDeepStrictEqual } from 'util';

export const useManagerEdit = (manager: any, setManager: (manager: any) => void) => {
  const [startDate, setStartDate] = useState<Date>(manager.startDateAsDate || new Date());
  const [endDate, setEndDate] = useState<Date|null>(manager.startDateAsDate || null);
  const [isEndDateUndecided, setEndDateUndecided] = useState<boolean>(!manager.endDate);

  const changeStartDate = useCallback((date: Date) => {
    setStartDate(date);
    setManager({
      ...manager,
      startDate: moment(date).format('YYYY/MM/DD'),
    });
  }, [manager, setManager]);

  const changeEndDate = useCallback((date: Date|null) => {
    setEndDate(date);
    setManager({
      ...manager,
      endDate: date ? moment(date).format('YYYY/MM/DD') : undefined,
    });
  }, [manager, setManager]);

  const toggleEndDateUndecided = useCallback(() => {
    if (!isDeepStrictEqual) {
      // 未定にしたら、endDateも空にする
      changeEndDate(null);
    }
    setEndDateUndecided(!isEndDateUndecided);
  }, [changeEndDate, isEndDateUndecided]);

  return {
    isEndDateUndecided,
    toggleEndDateUndecided,
    startDate,
    endDate,
    changeStartDate,
    changeEndDate,
  };
};

export default {};
