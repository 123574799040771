import Actions, { types } from './actions';

export interface SampleState {
  name: string,
}

const initialState: SampleState = {
  name: 'Cliek Me',
};

const reducer = (state = initialState, action: Actions): SampleState => {
  const newState = { ...state };
  switch (action.type) {
    case types.SET_NAME:
      newState.name = action.payload;
      return newState;


    // case types.FETCH:
    //   newState.name = action.payload;
    //   return newState;


    default:
      return state;
  }
};

export default reducer;

