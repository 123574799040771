/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FlexBox from 'components/atoms/FlexBox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import moment from 'moment';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import useToastNotification from 'hooks/useToastNotification';
import BlockUI from 'components/molecules/BlockUi';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import useMonthlyReport, { useGetListMonthlyReport } from './hooks';
import MonthlySeparateForeCastManagemenTable from './MonthlySeparateForeCastManagemenTable';
import MonthlySeparateForeCastManagemenTable_new from './MonthlySeparateForeCastManagemenTable_new';

import MonthlyDaySeparateSalesManagement from './MonthlyDaySeparateSalesManagement';
import MonthlyDaySeparateSalesManagement_new from './MonthlyDaySeparateSalesManagement_new';
import { useParams } from 'react-router-dom';
import MonthlySeparateDiscountSumaryTable from './MonthlySeparateDiscountSumaryTable';
import MonthlySeparateDiscountSumaryTable_new from './MonthlySeparateDiscountSumaryTable_new';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import { getOrgNameFromOrgOptionLabel } from 'utility/formatUtil';

const SeparateBillLayout = () => {
  const params = useParams<any>();
  const fromdate = new Date();
  const datefrom = params && params.targetDateFrom ? new Date(params.targetDateFrom) : new Date(fromdate.getFullYear(), fromdate.getMonth(), 1); // get first date
	const dateTo = params && params.targetDateTo ? new Date(params.targetDateTo) : (new Date(fromdate.getFullYear(), fromdate.getMonth() + 1, 0, 23, 59, 59)); // get last date
  const [targetDateFrom, setTargetDateFrom] = useState(datefrom);
  const [targetDateTo, setTargetDateTo] = useState(dateTo);
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [orgTreesOptions, setOrgTreesOptions] = useState<[]>([]);
  const st = new Date();
  st.setMonth(0);
  st.setDate(1);
  const ed = new Date(st);
  if (!targetDateFrom) {
    setTargetDateFrom(st);
  }

  if (!targetDateTo) {
    setTargetDateTo(ed);
  }
  const [isShowContentAction, setIsShowContentAction] = useState<boolean>(false);

  const [getOrgName, setOrgName] = useState<string>('');
  const [orgCode, setOrgCode] = useState(params.orgCode || (sessionStorage.getItem('loginUser.orgCode') ? sessionStorage.getItem('loginUser.orgCode') : ''));
  useEffect(() => {
    if (orgCode) {
      orgTreesOptions.map((data: any) => {
        if (data.value === orgCode) {
          return setOrgName(getOrgNameFromOrgOptionLabel(data.label));
        }
        return true;
      });
    }
  }, [orgCode, orgTreesOptions]);

  const [getMonthlyComparePrevYear, setMonthlyComparePrevYear] = useState<number>(0);

  const {
    getMediaMst, salesMangementReport, budgetPerfomanceManagementMonthlDailyReport, discountSumary, getIsLoading, setIsLoading, listHeader
  } = useMonthlyReport(String(orgCode), moment(targetDateFrom).format('YYYY/MM/DD'), moment(targetDateTo).format('YYYY/MM/DD'), getMonthlyComparePrevYear);
  const targetPeriodData = `対象期間：${moment(targetDateFrom).format('YYYY/MM/DD')}~${moment(targetDateTo).format('YYYY/MM/DD')}`;
  const {
    action,
    setAction,
    ActionType,
  } = useGetListMonthlyReport();

  const { errorNotification } = useToastNotification();

  const getFetchOption = () => {
    if (!orgCode || orgTreesOptions.length === 0) {
      errorNotification('組織名を選択してください。');
      setIsShowContentAction(false);
      return false;
    }
    setAction(ActionType.getFetchOption);
    setIsShowContentAction(true);
  };

  const getFetchOptionforeCast = () => {
    if (!orgCode || orgTreesOptions.length === 0) {
      errorNotification('組織名を選択してください。');
      setIsShowContentAction(false);
      return false;
    }
    setAction(ActionType.getFetchOptionforeCast);
    setIsShowContentAction(true);
  };
  const getFetchOptionDiscountSumary = () => {
    if (!orgCode || orgTreesOptions.length === 0) {
      errorNotification('組織名を選択してください。');
      setIsShowContentAction(false);
      return false;
    }
    setAction(ActionType.getFetchOptionDiscountSumary);
    setIsShowContentAction(true);
  };

   useEffect(()=>{
    if(!getIsLoading && params.isActiveTable === 'SaleManagementTable'){
      setIsShowContentAction(true);
      setAction(ActionType.getFetchOption);
    }else if(!getIsLoading && params.isActiveTable === 'ForecastManagementTable'){
      setIsShowContentAction(true);
      setAction(ActionType.getFetchOptionforeCast);
    }else if(!getIsLoading && params.isActiveTable === 'DiscountSumaryManagementTable'){
      setIsShowContentAction(true);
      setAction(ActionType.getFetchOptionDiscountSumary);
    }
  }, [ActionType.getFetchOption, ActionType.getFetchOptionforeCast, getIsLoading, params.isActiveTable, setAction,params,isShowContentAction])

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <div>
      <FormContents>
        <div>
          <FlexBox>
            <FlexBoxItem width="100px">
              <FormLabel
                label="対象期間"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月dd日"
                label=""
                date={targetDateFrom}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateFrom(date);
                  }
                }}
                isFullWidth={true}
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <span style={{ margin: '0 30px' }}>～</span>
            </FlexBoxItem>
            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月dd日"
                label=""
                date={targetDateTo}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateTo(date);
                  }
                }}
                isFullWidth={true}
              />
            </FlexBoxItem>
          </FlexBox>
        </div>
        <OrganizationFilteredTree
          functionType={3}
          orgCallback={(args: string | Array<string>) => {
            setOrgCode(String(args));
          }}
          initOrgValue={String(orgCode)}
          staffCode={staffCode}
          orgLabel="組織名"
          addAllItem={false}
          targetdayForm={targetDateFrom}
          targetdayTo={targetDateTo}
          orgOrgOptionListCallback={setOrgTreesOptions}
        />
        <FlexBox>
          <FlexBoxItem width="105px">
            <FormLabel label="前年比較対象" />
          </FlexBoxItem>
          <FlexBoxItem>
            <RadioSelectForm
              label=""
              name="monthlyComparePrevYear"
              items={[
                { label: '同曜日', value: '0' },
                { label: '同日', value: '1' },
              ]}
              value={String(getMonthlyComparePrevYear)}
              setValue={(e) => setMonthlyComparePrevYear(Number(e.target.value))}
            />
          </FlexBoxItem>
        </FlexBox>
        <FormField>
          <FormSubmitArea>
            <div style={{ marginRight: '12px' }}>
              <PrimaryButton
                text="売上管理表"
                onClick={getFetchOption}
                ghost={!(isShowContentAction && action === ActionType.getFetchOption)}
              />
            </div>
            {roleScreen && roleScreen.usabilityGeneralItem1 === 1 && (
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton
                  text="予実管理表"
                  onClick={getFetchOptionforeCast}
                  ghost={!(isShowContentAction && action === ActionType.getFetchOptionforeCast)}
                  />
              </div>
            )}
            <PrimaryButton
              text="値引・割引集計表"
              onClick={getFetchOptionDiscountSumary}
              ghost={!(isShowContentAction && action === ActionType.getFetchOptionDiscountSumary)}
              />
          </FormSubmitArea>
        </FormField>

        <div>
          {isShowContentAction && action === ActionType.getFetchOption
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <MonthlyDaySeparateSalesManagement_new
                   setIsLoading={setIsLoading}
                   orgCode={orgCode}
                   salesMangementReport={salesMangementReport}
                   targetPeriodData={targetPeriodData}
                   getMediaMst={getMediaMst}
                   compareToPreviousYear={(getMonthlyComparePrevYear)}
                   orgName={getOrgName}
                   roleScreen={roleScreen}
                 />
               </BlockUI>
             </div>
           </div>
           )}

          {isShowContentAction && action === ActionType.getFetchOptionforeCast
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <MonthlySeparateForeCastManagemenTable_new
                   setIsLoading={setIsLoading}
                   orgCode={orgCode}
                   budgetPerfomanceManagementMonthlDailyReport={budgetPerfomanceManagementMonthlDailyReport}
                   targetPeriodData={targetPeriodData}
                   compareToPreviousYear={(getMonthlyComparePrevYear)}
                   orgName={getOrgName}
                   roleScreen={roleScreen}
                 />
               </BlockUI>

             </div>
           </div>
           )}


          {isShowContentAction && action === ActionType.getFetchOptionDiscountSumary
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <MonthlySeparateDiscountSumaryTable_new
                   setIsLoading={setIsLoading}
                   orgCode={orgCode}
                   listHeader={listHeader}
                   discountSumary={discountSumary}
                   getMediaMst={getMediaMst}
                   targetPeriodData={targetPeriodData}
                   compareToPreviousYear={(getMonthlyComparePrevYear)}
                   orgName={getOrgName}
                   targetDateFrom={targetDateFrom}
                   targetDateTo={targetDateTo}
                   roleScreen={roleScreen}
                 />
               </BlockUI>
             </div>
           </div>
           )}
        </div>

      </FormContents>
    </div>
  );
};

export default SeparateBillLayout;
