/** @jsx jsx */

import React from "react";
import PrimaryButton from "components/atoms/Button";
import {css, jsx} from "@emotion/core";
import {Auth} from "aws-amplify";
import {useHistory} from "react-router-dom";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FlexBox from "components/atoms/FlexBox";
import { useDispatch } from 'react-redux';
import { uiSidebarActions } from 'modules/ui/sidebar';

const styles = {
    wrapper: css({
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        top: "50%",
        minWidth: "350px",
        width: "100%",
        position: "absolute",
        left: "0%",
        height: "20rem",
        marginTop: "-16rem"
    }),
    title: css({
        textAlign: "center",
        color: "rgb(0, 123, 195)",
        fontSize: "40px",
        marginBottom: "0px",
        marginTop: "10px",
        lineHeight: "50px",
        fontWeight: "bold",
        fontFamily: "inherit"
    }),
    description: css({
        textAlign: "center",
        color: "rgb(140, 140, 140)",
        fontSize: "15px",
        lineHeight: "24px",
        fontFamily: "inherit"
    }),
};

const MaintenancePage: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const logoutAction = () => {
        dispatch(uiSidebarActions.setHierarchy(undefined));
        sessionStorage.clear();
        localStorage.removeItem('logged');
        localStorage.removeItem('topPageSP');
        localStorage.removeItem('topPagePC');
        Auth.signOut();
        history.push('/login');
    };

    return (
        <div css={styles.wrapper}>
            <FlexBox alignItems="center" direction="column">
                <FlexBoxItem textAlign="center">
                    <FlexBoxItem>
                        <p css={styles.title}>ただいま、メンテナンス中です</p>
                    </FlexBoxItem>
                    <FlexBoxItem customStyle={css({marginTop: "15px", marginBottom: "20px"})}>
                        <p css={styles.description}>ご利用の皆様にはご不便をおかけし、申し訳ございません。<br/> メンテナンス終了までしばらくお待ちください。</p>
                    </FlexBoxItem>
                    <FlexBoxItem customStyle={css({marginTop: "15px", marginBottom: "20px", paddingTop: "10px"})}>
                        <PrimaryButton
                            text="ログアウト"
                            ghost={true}
                            onClick={logoutAction}
                            minWidth="120px"
                        />
                    </FlexBoxItem>
                </FlexBoxItem>
            </FlexBox>
        </div>
    )
}

export default MaintenancePage;
