import React from 'react';
import { useParams } from 'react-router-dom';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button, { PrimaryButton } from 'components/atoms/Button';
import { HolidayType, HolidayTypeLabel } from 'domain/vacation';
import ConfirmModal from 'components/organismos/ConfirmModal';
import useStaffsOptions from 'hooks/useStaffsOptions';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { useVacationAddForm } from './hooks';
import DetailPaid1DayForm from './DetailPaid1DayForm';
import DetailPaidHalfDayForm from './DetailPaidHalfDayForm';
import DetailHolidayForm from './DetailHolidayForm';
import DetailTexiuForm from './DetailTexiuForm';
import DetailClosedForm from './DetailClosedForm';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const VacationCreateForm: React.FC = () => {
  const { vacationId } = useParams<{ vacationId: string }>();
  const {
    formik, confirmModalOpen, confirmModalResendOpen, closeConfirmModal,
    closeConfirmResendModal, submitConfirmResendModal, confirmModalContent, history,
  } = useVacationAddForm(vacationId);
  const staffList = useStaffsOptions();

  if (formik.values.deemedWorkingMinutes === undefined
    && formik.values.deemedWorkingHours !== undefined) {
    const wrongDeemedWorkingHours = formik.values.deemedWorkingHours;
    formik.setFieldValue('deemedWorkingHours', Math.trunc(wrongDeemedWorkingHours / 60));
    formik.setFieldValue('deemedWorkingMinutes', wrongDeemedWorkingHours % 60);
  }

  if (formik.values.conditionGrantSubstituteHoliday !== undefined
    && formik.values.conditionGrantSubstituteHolidayHours === undefined
    && formik.values.conditionGrantSubstituteHolidayMinutes === undefined) {
    const { conditionGrantSubstituteHoliday } = formik.values;
    formik.setFieldValue('conditionGrantSubstituteHolidayHours', Math.trunc(conditionGrantSubstituteHoliday / 60));
    formik.setFieldValue('conditionGrantSubstituteHolidayMinutes', conditionGrantSubstituteHoliday % 60);
  }

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <FormContents>
      <p>
        休暇を登録します。コードと休暇名を入力して、休暇タイプを選択してください。
      </p>

      <form onSubmit={formik.handleSubmit}>
        <div>

          <FormField>
            <TextForm
              name="holidayCode"
              label="休暇コード"
              value={formik.values.holidayCode}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.holidayCode}
            />
          </FormField>
          <FormField>
            <TextForm
              name="holidayName"
              label="休暇名"
              value={formik.values.holidayName}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.holidayName}
            />
          </FormField>

          <FormField>
            <RadioSelectForm
              label="休暇タイプ"
              subLabel=""
              items={[
                {
                  label: HolidayTypeLabel[HolidayType.PUBLIC_HOLIDAY],
                  value: HolidayType.PUBLIC_HOLIDAY.toString(),
                },
                {
                  label: HolidayTypeLabel[HolidayType.PAID_1_DAY],
                  value: HolidayType.PAID_1_DAY.toString(),
                },
                {
                  label: HolidayTypeLabel[HolidayType.PAID_HALF_DAY],
                  value: HolidayType.PAID_HALF_DAY.toString(),
                },
                {
                  label: HolidayTypeLabel[HolidayType.HOLIDAY],
                  value: HolidayType.HOLIDAY.toString(),
                },
                {
                  label: HolidayTypeLabel[HolidayType.TEXIU],
                  value: HolidayType.TEXIU.toString(),
                },
                {
                  label: HolidayTypeLabel[HolidayType.ABSENCE],
                  value: HolidayType.ABSENCE.toString(),
                },
                {
                  label: HolidayTypeLabel[HolidayType.CLOSED],
                  value: HolidayType.CLOSED.toString(),
                },
              ]}
              name="holidayType"
              value={String(formik.values.holidayType)}
              setValue={(e) => {
                formik.handleChange(e);
              }}
            />
          </FormField>

          {String(formik.values.holidayType) === HolidayType.PAID_1_DAY.toString() && (
            <DetailPaid1DayForm
              paidCalculationStandard={formik.values.paidCalculationStandard}
              setPaidCalculationStandard={(val: boolean) => {
                formik.setFieldValue('paidCalculationStandard', val);
              }}
              salaryCalculation={formik.values.salaryCalculation}
              setSalaryCalculation={(val: number) => {
                formik.setFieldValue('salaryCalculation', val);
              }}
              unitPricePeriod={formik.values.unitPricePeriod}
              setUnitPricePeriod={(val: number) => {
                formik.setFieldValue('unitPricePeriod', val);
              }}
              unitPriceTransportation={formik.values.unitPriceTransportation}
              setUnitPriceTransportation={(val: boolean) => {
                formik.setFieldValue('unitPriceTransportation', val);
              }}
              unitPriceRounding={formik.values.unitPriceRounding}
              setUnitPriceRounding={(val: number) => {
                formik.setFieldValue('unitPriceRounding', val);
              }}
              // isWorkTime={formik.values.isWorkTime}
              // setIsWorkTime={(val: number) => {
              //   formik.setFieldValue('isWorkTime', val);
              // }}
              unUsableOfTrialPeriod={formik.values.unUsableOfTrialPeriod}
              setUnUsableOfTrialPeriod={(val: boolean) => {
                formik.setFieldValue('unUsableOfTrialPeriod', val);
              }}
              useEmploymentDeemedWorkingHours={formik.values.useEmploymentDeemedWorkingHours}
              setUseEmploymentDeemedWorkingHours={(val: boolean) => {
                formik.setFieldValue('useEmploymentDeemedWorkingHours', val);
              }}
              deemedWorkingHours={formik.values.deemedWorkingHours}
              setDeemedWorkingHours={(val: number) => {
                formik.setFieldValue('deemedWorkingHours', val);
              }}
              deemedWorkingMinutes={formik.values.deemedWorkingMinutes}
              setDeemedWorkingMinutes={(val: number) => {
                formik.setFieldValue('deemedWorkingMinutes', val);
              }}
            />
          )}

          {String(formik.values.holidayType) === HolidayType.PAID_HALF_DAY.toString() && (
            <DetailPaidHalfDayForm
              paidCalculationStandard={formik.values.paidCalculationStandard}
              setPaidCalculationStandard={(val: boolean) => {
                formik.setFieldValue('paidCalculationStandard', val);
              }}
              // isWorkTime={formik.values.isWorkTime}
              // setIsWorkTime={(val: number) => {
              //   formik.setFieldValue('isWorkTime', val);
              // }}
              unUsableOfTrialPeriod={formik.values.unUsableOfTrialPeriod}
              setUnUsableOfTrialPeriod={(val: boolean) => {
                formik.setFieldValue('unUsableOfTrialPeriod', val);
              }}
              deemedWorkingHours={formik.values.deemedWorkingHours}
              setDeemedWorkingHours={(val: number) => {
                formik.setFieldValue('deemedWorkingHours', val);
              }}
              deemedWorkingMinutes={formik.values.deemedWorkingMinutes}
              setDeemedWorkingMinutes={(val: number) => {
                formik.setFieldValue('deemedWorkingMinutes', val);
              }}
              paidHalfHolidayStartTime={formik.values.paidHalfHolidayStartTime}
              setPaidHalfHolidayStartTime={(val: string) => {
                formik.setFieldValue('paidHalfHolidayStartTime', val);
              }}
              paidHalfHolidayEndTime={formik.values.paidHalfHolidayEndTime}
              setPaidHalfHolidayEndTime={(val: string) => {
                formik.setFieldValue('paidHalfHolidayEndTime', val);
              }}
              isPaidHalfHolidayStartTimeNextDay={formik.values.isPaidHalfHolidayStartTimeNextDay}
              setIsPaidHalfHolidayStartTimeNextDay={(val: boolean) => {
                formik.setFieldValue('isPaidHalfHolidayStartTimeNextDay', val);
              }}
              isPaidHalfHolidayEndTimeNextDay={formik.values.isPaidHalfHolidayEndTimeNextDay}
              setIsPaidHalfHolidayEndTimeNextDay={(val: boolean) => {
                formik.setFieldValue('isPaidHalfHolidayEndTimeNextDay', val);
              }}

            />
          )}


          {String(formik.values.holidayType) === HolidayType.HOLIDAY.toString() && (
            <DetailHolidayForm
              autoGrantHoliday={formik.values.autoGrantHoliday}
              setAutoGrantHoliday={(val: boolean) => {
                formik.setFieldValue('autoGrantHoliday', val);
              }}
              conditionGrantSubstituteHolidayHours={
                formik.values.conditionGrantSubstituteHolidayHours
              }
              setConditionGrantSubstituteHolidayHours={(val: number) => {
                formik.setFieldValue('conditionGrantSubstituteHolidayHours', val);
              }}
              conditionGrantSubstituteHolidayMinutes={
                formik.values.conditionGrantSubstituteHolidayMinutes
              }
              setConditionGrantSubstituteHolidayMinutes={(val: number) => {
                formik.setFieldValue('conditionGrantSubstituteHolidayMinutes', val);
              }}
              validityPeriod={formik.values.validityPeriod}
              setValidityPeriod={(val: number) => {
                formik.setFieldValue('validityPeriod', val);
              }}
            />
          )}


          {String(formik.values.holidayType) === HolidayType.TEXIU.toString() && (
            <DetailTexiuForm
              paidCalculationStandard={formik.values.paidCalculationStandard}
              setPaidCalculationStandard={(val: boolean) => {
                formik.setFieldValue('paidCalculationStandard', val);
              }}
              // isWorkTime={formik.values.isWorkTime}
              // setIsWorkTime={(val: number) => {
              //   formik.setFieldValue('isWorkTime', val);
              // }}
              unUsableOfTrialPeriod={formik.values.unUsableOfTrialPeriod}
              setUnUsableOfTrialPeriod={(val: boolean) => {
                formik.setFieldValue('unUsableOfTrialPeriod', val);
              }}
              useEmploymentDeemedWorkingHours={formik.values.useEmploymentDeemedWorkingHours}
              setUseEmploymentDeemedWorkingHours={(val: boolean) => {
                formik.setFieldValue('useEmploymentDeemedWorkingHours', val);
              }}
              deemedWorkingHours={formik.values.deemedWorkingHours}
              setDeemedWorkingHours={(val: number) => {
                formik.setFieldValue('deemedWorkingHours', val);
              }}
              deemedWorkingMinutes={formik.values.deemedWorkingMinutes}
              setDeemedWorkingMinutes={(val: number) => {
                formik.setFieldValue('deemedWorkingMinutes', val);
              }}
              autoGrantHoliday={formik.values.autoGrantHoliday}
              setAutoGrantHoliday={(val: boolean) => {
                formik.setFieldValue('autoGrantHoliday', val);
              }}
              autoGrantDateMonth={formik.values.autoGrantDateMonth}
              setAutoGrantDateMonth={(val: number) => {
                formik.setFieldValue('autoGrantDateMonth', val);
              }}
              autoGrantDateDay={formik.values.autoGrantDateDay}
              setAutoGrantDateDay={(val: number) => {
                formik.setFieldValue('autoGrantDateDay', val);
              }}
              autoGrantHolidayType={formik.values.autoGrantHolidayType}
              setAutoGrantHolidayType={(val: number) => {
                formik.setFieldValue('autoGrantHolidayType', val);
              }}

              autoGrantDay={formik.values.autoGrantDay}
              setAutoGrantDay={(val: number | '') => {
                formik.setFieldValue('autoGrantDay', val);
              }}
              autoGrantWorkingStartMonths1={formik.values.autoGrantWorkingStartMonths1}
              setAutoGrantWorkingStartMonths1={(val: number | '') => {
                formik.setFieldValue('autoGrantWorkingStartMonths1', val);
              }}
              autoGrantWorkingEndMonths1={formik.values.autoGrantWorkingEndMonths1}
              setAutoGrantWorkingEndMonths1={(val: number | '') => {
                formik.setFieldValue('autoGrantWorkingEndMonths1', val);
              }}
              autoGrantWorkingDay1={formik.values.autoGrantWorkingDay1}
              setAutoGrantWorkingDay1={(val: number | '') => {
                formik.setFieldValue('autoGrantWorkingDay1', val);
              }}
              autoGrantWorkingStartMonths2={formik.values.autoGrantWorkingStartMonths2}
              setAutoGrantWorkingStartMonths2={(val: number | '') => {
                formik.setFieldValue('autoGrantWorkingStartMonths2', val);
              }}
              autoGrantWorkingEndMonths2={formik.values.autoGrantWorkingEndMonths2}
              setAutoGrantWorkingEndMonths2={(val: number | '') => {
                formik.setFieldValue('autoGrantWorkingEndMonths2', val);
              }}
              autoGrantWorkingDay2={formik.values.autoGrantWorkingDay2}
              setAutoGrantWorkingDay2={(val: number | '') => {
                formik.setFieldValue('autoGrantWorkingDay2', val);
              }}
              autoGrantWorkingStartMonths3={formik.values.autoGrantWorkingStartMonths3}
              setAutoGrantWorkingStartMonths3={(val: number | '') => {
                formik.setFieldValue('autoGrantWorkingStartMonths3', val);
              }}
              autoGrantWorkingEndMonths3={formik.values.autoGrantWorkingEndMonths3}
              setAutoGrantWorkingEndMonths3={(val: number | '') => {
                formik.setFieldValue('autoGrantWorkingEndMonths3', val);
              }}
              autoGrantWorkingDay3={formik.values.autoGrantWorkingDay3}
              setAutoGrantWorkingDay3={(val: number | '') => {
                formik.setFieldValue('autoGrantWorkingDay3', val);
              }}

              enteringGrantHoliday={formik.values.enteringGrantHoliday}
              setEnteringGrantHoliday={(val: boolean) => {
                formik.setFieldValue('enteringGrantHoliday', val);
              }}
              enteringGrantDay1={formik.values.enteringGrantDay1}
              setEnteringGrantDay1={(val: number | '') => {
                formik.setFieldValue('enteringGrantDay1', val);
              }}
              enteringGrantDay2={formik.values.enteringGrantDay2}
              setEnteringGrantDay2={(val: number | '') => {
                formik.setFieldValue('enteringGrantDay2', val);
              }}
              enteringGrantDay3={formik.values.enteringGrantDay3}
              setEnteringGrantDay3={(val: number | '') => {
                formik.setFieldValue('enteringGrantDay3', val);
              }}
              includingTrialPeriod={formik.values.includingTrialPeriod}
              setIncludingTrialPeriod={(val: boolean) => {
                formik.setFieldValue('includingTrialPeriod', val);
              }}
              noticeStaffs={formik.values.noticeStaffs}
              setNoticeStaffs={(val: Array<any>) => {
                formik.setFieldValue('noticeStaffs', val);
              }}

              listStaff={staffList}
              validityPeriodStartMonth={formik.values.validityPeriodStartMonth}
              setValidityPeriodStartMonth={(val: number | '') => {
                formik.setFieldValue('validityPeriodStartMonth', val);
              }}

              validityPeriodStartDay={formik.values.validityPeriodStartDay}
              setValidityPeriodStartDay={(val: number | '') => {
                formik.setFieldValue('validityPeriodStartDay', val);
              }}

              validityPeriodEndMonth={formik.values.validityPeriodEndMonth}
              setValidityPeriodEndMonth={(val: number | '') => {
                formik.setFieldValue('validityPeriodEndMonth', val);
              }}

              validityPeriodEndDay={formik.values.validityPeriodEndDay}
              setValidityPeriodEndDay={(val: number | '') => {
                formik.setFieldValue('validityPeriodEndDay', val);
              }}
            />
          )}

          {String(formik.values.holidayType) === HolidayType.CLOSED.toString() && (
            <DetailClosedForm
              paidCalculationStandard={formik.values.paidCalculationStandard}
              setPaidCalculationStandard={(val: boolean) => {
                formik.setFieldValue('paidCalculationStandard', val);
              }}
              unUsableOfTrialPeriod={formik.values.unUsableOfTrialPeriod}
              setUnUsableOfTrialPeriod={(val: boolean) => {
                formik.setFieldValue('unUsableOfTrialPeriod', val);
              }}
            />
          )}
        </div>


        <FormSubmitArea>
          {
            roleScreen && roleScreen.editable === 1 && (
              <div style={{ marginRight: '12px' }}>
                <Button
                  text="休暇を登録"
                  onClick={formik.handleSubmit}
                />
              </div>
            )
          }
          <PrimaryButton
            text="戻る"
            onClick={() => {
              history.goBack();
            }}
            ghost={true}
          />
        </FormSubmitArea>
      </form>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={vacationId ? ActionType.UPDATE : ActionType.CREATE}
      />

      <ConfirmModal
        title="確認"
        content={confirmModalContent}
        open={confirmModalResendOpen}
        closeHandler={closeConfirmResendModal}
        onSubmit={submitConfirmResendModal}
        items={[]}
      />
    </FormContents>
  );
};
export default VacationCreateForm;
