import {
  useState, useCallback, useEffect,
} from 'react';
import { useFormik } from 'formik';
// import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import SpecialSalaryPatternDomain from 'domain/master/labor/specialSalaryPattern';
import { postSpecialSalaryPattern, getSpecialSalaryPattern } from 'api/specialSalaryPattern';
import useToastNotification from 'hooks/useToastNotification';

type SpecialSalaryPatternDomainKey = keyof Pick<SpecialSalaryPatternDomain, 'specialSalaryPatternCode'|'specialSalaryPatternName'>;

export const useSpecialSalaryPatternAddForm = () => {
  const { specialSalaryPatternId } = useParams();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalContent, setConfirmModalContent] = useState('登録します。よろしいですか？');
  const [confirmModalSubmitText, setConfirmModalSubmitText] = useState('登録');

  const [toastModalOpen, setToastModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const history = useHistory();

  const onSubmit = async (values: SpecialSalaryPatternDomain) => {
    if (values.specialSalaryPatternCode === null) {
      errorNotification('パターンコードを入力してください');
      return;
    }
    if (values.specialSalaryPatternName === null) {
      errorNotification('パターン名を入力してください');
      return;
    }

    if (specialSalaryPatternId) {
      setConfirmModalContent('更新します。よろしいですか？');
      setConfirmModalSubmitText('更新');
    }
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    try {
      const postData = values.getRawDataPost();
      postData.sameDays = postData.sameDays.map((item: any) => item.value).toString();
      postData.fixedDays = postData.fixedDays.map((item: any) => item.value).toString();
      postData.createUser = sessionStorage.getItem('loginUser.staffName') || '';
      postData.updateUser = sessionStorage.getItem('loginUser.staffName') || '';

      const response = await postSpecialSalaryPattern(postData);
      setConfirmModalOpen(false);
      if (!response.errors) {
        if (specialSalaryPatternId) {
          successNotification('更新しました。');
        } else {
          successNotification('登録しました。');
          history.push(`/masterSpecialSalaryPattern/edit/${response.specialSalaryPatternId}/0`);
        }
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: SpecialSalaryPatternDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    if (specialSalaryPatternId) {
      getSpecialSalaryPattern(specialSalaryPatternId).then((response: any) => {
        response.sameDays = [];
        response.fixedDays = [];

        response.sameDayList.forEach((item: any) => {
          response.sameDays.push({
            value: `${item.sameMonth}/${item.sameDay}`,
            label: `${item.sameMonth}月${item.sameDay}日`,
          });
        });

        response.fixedList.forEach((item: any) => {
          response.fixedDays.push({
            value: moment(item.fixedDate).format('YYYY/MM/DD'),
            label: moment(item.fixedDate).format('YYYY年MM月DD日'),
          });
        });

        formik.setValues(new SpecialSalaryPatternDomain(response));
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialSalaryPatternId]);

  const handleBack = async () => {
    sessionStorage.setItem('currentTab', '2');
    history.push('/agreementMaster');
  };

  // 登録内容確認モーダル用データ
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    handleBack,
    confirmModalContent,
    confirmModalSubmitText,
  };
};

export default {
  useSpecialSalaryPatternAddForm,
};
