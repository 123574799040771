import React, { SVGProps } from 'react';

const Calc: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M16 1.875c.345 0 .625.28.625.625v15c0 .345-.28.625-.625.625H4a.625.625 0 01-.625-.625v-15c0-.345.28-.625.625-.625h12zm-.625 1.25H4.625v13.75h10.75V3.125zM7.5 13.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1A.5.5 0 016 15v-1a.5.5 0 01.5-.5h1zm3 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1A.5.5 0 019 15v-1a.5.5 0 01.5-.5h1zm3 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1zm-6-3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1A.5.5 0 016 12v-1a.5.5 0 01.5-.5h1zm3 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1A.5.5 0 019 12v-1a.5.5 0 01.5-.5h1zm3 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1zm-6-3A.5.5 0 018 8v1a.5.5 0 01-.5.5h-1A.5.5 0 016 9V8a.5.5 0 01.5-.5h1zm3 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1A.5.5 0 019 9V8a.5.5 0 01.5-.5h1zm3 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1A.5.5 0 0112 9V8a.5.5 0 01.5-.5h1zm0-3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-7A.5.5 0 016 6V5a.5.5 0 01.5-.5h7z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Calc;
