import React, { useEffect, useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import TextForm from 'components/molecules/TextForm';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import { useHistory } from 'react-router-dom';
import ConfirmModal from 'components/organismos/ConfirmModal';
import ShiftPatternDetails from 'components/organismos/master/labor/shiftPattern/ShiftPatternDetails';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import useShiftPatternAddForm from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const ShiftPatternAddPage: React.FC<{
  shiftPatternId: string
  typeButton: string
}> = ({
  shiftPatternId,
  typeButton,
}) => {
  const {
    formik,
    // orgTreesOptions,
    employmentOptions,
    employmentIdSelect,
    confirmModalOpen,
    closeConfirmModal,
    shiftPatternDetailsList,
    setShiftPatternDetailsList,
    listTimes,
    attendBusinessOptions,
    handleGetBusinessDataByEmploymentId,
    handleGetRoundTimeList,
    attendEmploymentList,
    staffCode,
    setSelectedOrgCode
  } = useShiftPatternAddForm(shiftPatternId, typeButton);

  // history
  const history = useHistory();

  /**
   *  useEffect
   *
   */
  useEffect(() => {
    //
  }, [employmentIdSelect, formik]);

  /**
   *  handle back
   *
   */
  const handleBack = async () => {
    history.push('/masterShiftPattern');
  };


  /**
   *  handle back
   *
   */
  const handleChangeEmploymentId = (employmentId: any) => {
    formik.setFieldValue('employmentId', employmentId);
    handleGetBusinessDataByEmploymentId(employmentId, true);
    let targetDateStr = '';
    attendEmploymentList.filter((item: any) => {
      if (employmentId === item.id.employmentId) {
        targetDateStr = item.id.applyStartDate;
        return true;
      }
      return false;
    });
    handleGetRoundTimeList(employmentId, targetDateStr, '', true);
  };
  const functionType = 1;

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (

    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormContents>
          {/* <VerticalLabelSelectForm
            label="適用店舗"
            name="orgCode"
            value={String(formik.values.orgCode)}
            setValue={(val: string) => formik.setFieldValue('orgCode', val)}
            options={orgTreesOptions}
            required={true}
          /> */}

          <OrganizationFilteredTree
            functionType={functionType}
            orgCallback={(args: string | Array<string>) => {
              setSelectedOrgCode(String(args));
              formik.setFieldValue('orgCode', args);
            }}
            initOrgValue={String(formik.values.orgCode)}
            staffCode={staffCode}
            orgLabel="適用店舗"
            addAllItem={true}
            required={true}
          />


          <VerticalLabelSelectForm
            label="雇用形態"
            name="employmentId"
            value={String(formik.values.employmentId)}
            setValue={(val: string) => handleChangeEmploymentId(val)}
            options={employmentOptions}
            required={true}
          />
          <FormField>
            <TextForm
              name="shiftPatternCode"
              label="シフトパターンコード"
              value={formik.values.shiftPatternCode}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.shiftPatternCode}
            />
          </FormField>
          <FormField>
            <TextForm
              name="shiftPatternName"
              label="シフトパターン名"
              value={formik.values.shiftPatternName}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.shiftPatternName}
            />
          </FormField>
          <FormField>
            <TextForm
              name="shortName"
              label="短縮名"
              value={formik.values.shortName}
              required={true}
              onChange={formik.handleChange}
              appendLabel="（※5文字まで）"
              errorMsg={formik.errors.shortName}
            />
          </FormField>

          <ShiftPatternDetails
            shiftPatternDetailsList={shiftPatternDetailsList}
            setShiftPatternDetailsList={setShiftPatternDetailsList}
            attendBusinessOptions={attendBusinessOptions}
            listTimes={listTimes}
            isCreate={Boolean((shiftPatternId || typeButton === 'copy'))}
          />

          <FormSubmitArea>
            {
              roleScreen && roleScreen.editable === 1 && (
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton
                  ghost={false}
                  text={shiftPatternId ? '更新' : '登録'}
                  onClick={formik.handleSubmit}
                />
              </div>
            )}
            <PrimaryButton
              ghost={true}
              text="戻る"
              onClick={() => handleBack()}
            />
          </FormSubmitArea>
        </FormContents>

        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          title="確認メッセージ"
          content={`${shiftPatternId ? '更新' : '登録'}します。よろしいですか？`}
          submitText={shiftPatternId ? '更新' : '登録'}
        />
      </form>
    </div>
  );
};

export default ShiftPatternAddPage;
