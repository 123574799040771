import {
  useState, useCallback, useEffect, useMemo,
} from 'react';

import { useFormik } from 'formik';
import TermsDomain from 'domain/master/attend/terms';
import { post, getList } from 'api/termsMaster';
import { useHistory } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';

//
type TermsDomainKey = keyof Pick<TermsDomain, 'termName'>;

export const useTermsDomainAddForm = (termId: string) => {
  //
  const history = useHistory();
  const { successNotification, errorNotification } = useToastNotification();

  //
  const optionsTargetType = [
    {
      label: '平日・休日',
      value: '0',
    },
    {
      label: '平日のみ',
      value: '1',
    },
    {
      label: '休日のみ',
      value: '2',
    },
  ];

  //
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  //
  const [listTerm, setListTerm] = useState([]);

  //
  const [toastModalOpen, setToastModalOpen] = useState(false);

  // TODO companyCodeの取得処理が必要
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const onSubmit = async (values: TermsDomain) => {
    if (values.termName === null) {
      errorNotification('名称を入力してください');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    setConfirmModalOpen(false);
    setIsLoading(true);

    const dataPost = values.getRawData();

    //
    let convertData = {
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
    };


    listTerm.forEach((item: any, index: number) => {
      //
      const tmpDataTerm = {
        termOrder: item.termOrder,
        termId: item.termId,
        startTime: item.startTime,
        endTime: item.endTime,
        targetType: item.targetType,
        termName: item.termName,
      };

      if (item.termId === termId) {
        tmpDataTerm.targetType = dataPost.targetType;
        tmpDataTerm.startTime = dataPost.startTime;
        tmpDataTerm.endTime = dataPost.endTime;
        tmpDataTerm.termName = dataPost.termName;
      }

      convertData = {
        ...convertData,
        [`terms[${index}]`]: {
          ...tmpDataTerm,
        },
      };
    });

    if (!termId) {
      //
      const tmpDataTermCreate = {
        termOrder: listTerm.length,
        termId: '',
        startTime: dataPost.startTime,
        endTime: dataPost.endTime,
        targetType: dataPost.targetType,
        termName: dataPost.termName,
      };
      const index = 0;
      convertData = {
        ...convertData,
        [`terms[${index}]`]: {
          ...tmpDataTermCreate,
        },
      };
    }

    try {
      const response = await post(companyCode, convertData);
      setIsLoading(false);
      if (!response.errors) {
        sessionStorage.setItem('currentTab', '6');
        successNotification('登録しました。');
        history.push('/agreementMaster');
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };


  //
  const formik = useFormik({
    initialValues: TermsDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  //
  useEffect(() => {
    if (termId !== undefined) {
      getList().then((response: any) => {
        response.filter((items: any) => {
          if (items.termId === termId) {
            formik.setValues(new TermsDomain(items));
            return true;
          }
          return false;
        });
        setListTerm(response);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termId]);

  //
  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const confirmModalItems = useMemo(() => ([
    {
      key: '時間帯名',
      value: formik.values.termName,
    },
    {
      key: '対象となる日付区分',
      value: optionsTargetType[formik.values.targetType].label,
    },
    {
      key: '時間帯設定',
      value: `${formik.values.startTime ? formik.values.startTime : '--:--'} ~ ${formik.values.endTime ? formik.values.endTime : '--:--'}`,
    },

  ]), [formik.values.endTime, formik.values.startTime, formik.values.targetType, formik.values.termName, optionsTargetType]);

  // 詳細項目入力を表示するかどうか
  const [detailMode, setDetailMode] = useState(false);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    confirmModalItems,
    toastModalOpen,
    closeToastModal,
    optionsTargetType,
    isLoading,
  };
};

export default {
  useTermsDomainAddForm,
};
