/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import ClickableLink from 'components/atoms/ClickableLink';

import { useShiftDayCalendarContext } from './hooks';

const ShiftAddButton: React.FC = () => {
  const { showShiftAddPanelAction } = useShiftDayCalendarContext();
  return (
    <React.Fragment>
      <ClickableLink
        onClick={(e) => {
          showShiftAddPanelAction(12, 0);
        }}
        customCss={css({ textAlign: 'center' })}
      >
        新しくシフトを追加する
      </ClickableLink>
    </React.Fragment>
  );
};

export default ShiftAddButton;
