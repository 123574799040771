import ApiClient from './ApiClient';

export const getList = async (companyCode: string):Promise<any> => {
  const params = {};
  const response = await ApiClient.get(`/v1/holidays/attends/list/${companyCode}`, params);
  return response.data;
};

export const deleteById = async (companyCode: string, holidayId: string): Promise<any> => {
  const params = {
    holidayId,
  };
  const reponse = await ApiClient.delete(`/v1/holidays/attends/${companyCode}`, params);
  return reponse.data;
};

export default getList;
