import React from 'react';

import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import DatePickerForm from 'components/molecules/DatePickerForm';
import useOrganizeOptions from 'hooks/useOrganizeOptions';
import { useSpecialSalaryPatternSettingForm } from './hooks';
import { gridCol } from '../../../../styles';
import VerticalLabelMutilSelectForm from '../../../../molecules/VerticalLabelMutilSelectForm';

const SpecialSalaryPatternInputSettingForm: React.FC = () => {
  const {
    formik, confirmModalOpen, closeConfirmModal, handleBack,
  } = useSpecialSalaryPatternSettingForm();
  const organizeOptions = useOrganizeOptions();

  return (
    <>
      <p>
        特賃日を割り当てる組織を選択します。
      </p>

      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormField>
            <div style={{ width: gridCol.grid02 }}>
              <DatePickerForm
                dateFormat="yyyy年MM月"
                label="適用月"
                date={formik.values.applyStartMonthStr}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    formik.setFieldValue('applyStartMonthStr', date);
                  }
                }}
                showMonthYearPicker={true}
                errorMsg={formik.errors.applyStartMonthStr !== undefined ? '日付は必須です' : ''}
                required={true}
              />
            </div>
          </FormField>

          <FormField>
            <div style={{ width: gridCol.grid02 }}>
              <TextForm
                name="addSalary"
                label="加算額"
                groupText="円"
                onChange={formik.handleChange}
                value={formik.values.addSalary}
                type="number"
                min="0"
                max="99999999"
              />
            </div>
          </FormField>

          <VerticalLabelMutilSelectForm
            label="組織選択"
            name="patOrgCodes"
            values={formik.values.patOrgCodes.map(
              (orgCode: string) => organizeOptions.find((item) => orgCode === item.value),
            )}
            setValue={(val: any) => {
              formik.setFieldValue('patOrgCodes', val.slice(0, -1).split(','));
            }}
            options={organizeOptions}
            isMulti={true}
            note="選択組織すべてに同じ設定をおこないます。個別設定は、組織マスタから行うことができます。"
          />
          <FormSubmitArea>
            <div style={{ marginRight: '12px' }}>
              <Button
                text="組織を割当"
                onClick={formik.handleSubmit}
              />
            </div>
            <Button
              text="戻る"
              onClick={handleBack}
              ghost={true}
            />
          </FormSubmitArea>
        </div>

        <ConfirmModal
          title="確認メッセージ"
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          content="更新します。よろしいですか？"
          submitText="更新"
        />
      </form>
    </>
  );
};

export default SpecialSalaryPatternInputSettingForm;
