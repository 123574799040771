import moment from 'moment';
import ApiClient from './ApiClient';
import {AxiosResponse} from "axios";

export const getSalesExpensesList = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/expensesList/${companyCode}`, {});
  return response.data;
};

export const saveSalesExpenses = async (
  expensesCode: string,
  expensesName: string,
  dispOrder: number,
  expensesClassification: string,
  applyStartDate: any,
  applyEndDate: any,
) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const createUser = sessionStorage.getItem('loginUser.staffCode');
  const params = {
    expensesCode,
    expensesName,
    dispOrder,
    expensesClassification,
    applyStartDate,
    applyEndDate,
    createUser,
  };
  const response = await ApiClient.postJsonData(`/v1/sales/expenses/${companyCode}`, {}, params);
  return response.data;
};

export const updateSalesExpenses = async (
  expensesCode: string,
  expensesName: string,
  dispOrder: number,
  expensesClassification: string,
  applyStartDate: any,
  applyEndDate: any,
) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const createUser = sessionStorage.getItem('loginUser.staffCode');
  const params = {
    expensesCode,
    expensesName,
    dispOrder,
    expensesClassification,
    applyStartDate,
    applyEndDate,
    createUser,
  };
  const response = await ApiClient.putJsonData(`/v1/sales/expenses/${companyCode}`, {}, params);
  return response.data;
};

export const deleteSalesExpenses = async (
  expensesCode: string,
) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    expensesCode,
  };
  const response = await ApiClient.delete(`/v1/sales/expenses/${companyCode}`, params);
  return response.data;
};

export const getDetailSalesExpenses = async (
  expensesCode: string,
) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    expensesCode,
  };
  const response = await ApiClient.get(`/v1/sales/expenses/${companyCode}`, {}, params);
  return response.data;
};

export const downloadCommentCsv = async (data: any, fileName:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsvPost(`/v1/sales/list/standard/daily/comment/csv/${companyCode}`, {}, data, getDownloadCsvFileName(`${fileName}_`));
};

export const downloadCSV = async (companyCode: string, fileName: any): Promise<void> => {
  const params = {};
  await ApiClient.downloadCsv(`/v2/sales/master/expenses/csv/${companyCode}`, params, getDownloadCsvFileName(`${fileName}_`));
};

const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export const downloadCommentExcel = async (data: any, fileName:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadExcelPost(`/v1/sales/list/standard/daily/comment/excel/${companyCode}`, {}, data, getDownloadExcelFileName(`${fileName}_`));
};

const getDownloadExcelFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.xlsx`;

/**
 * CSVファイルインポート
 */
export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v2/sales/master/expenses/csv/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export type Error = {
  error: string;
  defaultMessage: string;
}

export default { };
