/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */
/** @jsx jsx */
import React, { useMemo, useState, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';

import PrimaryButton from 'components/atoms/Button';
import PageTitle from 'components/atoms/PageTitle';
import DataTable from 'components/organismos/DataTable/DataTable';
import Icon from 'components/atoms/Icon';
import { iconColor } from 'components/styles';
import FlexBox from 'components/atoms/FlexBox';

import ShiftPatternDomain from 'domain/master/attend/shiftPattern';
import moment from 'moment';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { deleteById } from 'api/shiftPattern';
import ToastModal from 'components/organismos/ToastModal';
import useToastNotification from 'hooks/useToastNotification';

import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Select from 'components/atoms/Select';
import BodyText from 'components/atoms/BodyText';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import ClickableLink from 'components/atoms/ClickableLink';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import {
  useShiftPatternList, useWorkTypeAll, findMinimumHour, findMaximunHour, useBreakList, findBreakShift
} from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const ShiftPatternLayoutList: React.FC = () => {
  const { shiftBreakList } = useBreakList();
  const orgCodeSlected = sessionStorage.getItem('shiftPatternOrgCode') ? sessionStorage.getItem('shiftPatternOrgCode') : (sessionStorage.getItem('loginUser.orgCode') || 'all');
  const employmentIdSelected = sessionStorage.getItem('employmentIdSelected') ? sessionStorage.getItem('employmentIdSelected') : String('all');
  const [orgCode, setorgCode] = useState(orgCodeSlected);
  const [employmentId, setEmploymentId] = useState(employmentIdSelected);
  sessionStorage.setItem('shiftPatternOrgCode', String(orgCode));
  sessionStorage.setItem('employmentIdSelected', String(employmentId));
  const {
    shiftPatternList, setShiftPatternList, setCategoryArr, staffCode, addAllItem
  } = useShiftPatternList(String(orgCode), String(employmentId));

  const { shiftPatternWorkType, getName } = useWorkTypeAll();
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const [toastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [valueShiftPatternId, setValue] = useState('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const openConfirmModal = useCallback((item : ShiftPatternDomain) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setValue(item.shiftPatternId);
    setConfirmModalOpen(true);
  }, []);

  const deleteShiftPatternbyID = useCallback(() => {
    deleteById(valueShiftPatternId).then((response: any) => {
      setShiftPatternList(shiftPatternList.filter((shiftPattern) => shiftPattern.shiftPatternId !== valueShiftPatternId));
      setConfirmModalOpen(false);
      successNotification('削除しました。');
    }).catch((error) => {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        let msgError = '';
        error.response.data.errors.map((item: { defaultMessage: string; }) => {
          msgError += `${item.defaultMessage} </br>`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [errorNotification, setShiftPatternList, shiftPatternList, successNotification, valueShiftPatternId]);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const columns: Array<Column<ShiftPatternDomain>> = useMemo(() => [
    {
      Header: '店舗',
      accessor: 'orgName',
    },
    {
      Header: '雇用形態',
      accessor: 'employmentId',
      Cell: (cell: { row :{ isExpanded: boolean, original: ShiftPatternDomain },
        data: Array<ShiftPatternDomain> }) => {
        let getNameResponse = '';
        // eslint-disable-next-line array-callback-return
        getName.filter((response) => {
          if (response.value === cell.row.original.employmentId) {
            getNameResponse = response.label;
          }
        });
        return getNameResponse;
      },
    },
    {
      Header: 'パターンコード',
      accessor: 'shiftPatternCode',
      sortType: 'basic'
    },
    {
      Header: 'パターン名',
      accessor: 'shiftPatternName',
    },
    {
      Header: '出社時間',
      accessor: 'endTime',
      Cell: (cell: { row :{ isExpanded: boolean, original: ShiftPatternDomain },
        data: Array<ShiftPatternDomain> }) => {
        const text = findMinimumHour(cell.row.original.attendShiftPatternDetails)[1] ? '(翌)' : '';
        const time = `${moment(findMinimumHour(cell.row.original.attendShiftPatternDetails)[0], 'HH:mm:ss').format('HH:mm')}`;
        return `${time} ${text}`;
      },
    },
    {
      Header: '退社時間',
      accessor: 'startTime',
      Cell: (cell: { row :{ isExpanded: boolean, original: ShiftPatternDomain },
        data: Array<ShiftPatternDomain> }) => {
        const text = findMaximunHour(cell.row.original.attendShiftPatternDetails)[1] ? '(翌)' : '';
        const time = `${moment(findMaximunHour(cell.row.original.attendShiftPatternDetails)[0], 'HH:mm:ss').format('HH:mm')}`;
        return `${time} ${text}`;
      },
    },
    {
      Header: '休憩時間',
      accessor: 'free',
      Cell: (cell: { row :{ isExpanded: boolean, original: ShiftPatternDomain },
        data: Array<ShiftPatternDomain> }) => {
        let breakTimeText = '';
        if (cell.row.original.attendShiftPatternDetails) {
          const listBreak = findBreakShift(cell.row.original.attendShiftPatternDetails, shiftBreakList);
          if (listBreak.length) {
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < listBreak.length; index++) {
              const {
                isStartTimeNextDay, startTime, endTime, isEndTimeNextDay,
              } = listBreak[index];
              const valStartTime = moment(startTime, 'HH:mm:ss').format('HH:mm');
              const valIsStartTimeNextDay = isStartTimeNextDay ? ' (翌)' : '';
              const valEndTime = moment(endTime, 'HH:mm:ss').format('HH:mm');
              const valIsEndTimeNextDay = isEndTimeNextDay ? ' (翌)' : '';

              if (index === 0) {
                breakTimeText = `${valStartTime + valIsStartTimeNextDay} ～ ${valEndTime}${valIsEndTimeNextDay}`;
              }

              if (index !== 0) {
                breakTimeText += `<br> ${valStartTime + valIsStartTimeNextDay} ～ ${valEndTime}${valIsEndTimeNextDay}`;
              }
            }
          }
        }
        return (
          <div dangerouslySetInnerHTML={{ __html: breakTimeText }} />
        );
      },
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: ShiftPatternDomain },
      data: Array<ShiftPatternDomain> }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverCopy, setIsHoverCopy] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <FlexBox>
            <FlexBoxItem marginLeft="auto">
              <ClickableLink to={`/masterShiftPattern/edit/${cell.row.original.shiftPatternId}`}>
                <span
                  onMouseEnter={() => setIsHoverEdit(true)}
                  onMouseLeave={() => setIsHoverEdit(false)}
                >
                  <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
                </span>
              </ClickableLink>
            </FlexBoxItem>
            {
              roleScreen && roleScreen.editable === 1 && (
              <React.Fragment>
                <FlexBoxItem>
                  <ClickableLink to={`/masterShiftPattern/copy/${cell.row.original.shiftPatternId}`}>
                    <span
                      onMouseEnter={() => setIsHoverCopy(true)}
                      onMouseLeave={() => setIsHoverCopy(false)}
                    >
                      <Icon type="copy" color={isHoverCopy ? iconColor.grayhover : iconColor.gray} />
                    </span>
                  </ClickableLink>
                </FlexBoxItem>
                <FlexBoxItem>
                  <ClickableIconButton
                    onClick={() => { openConfirmModal(cell.row.original); }}
                  >
                    <span
                      style={{ marginRight: '20px' }}
                      onMouseEnter={() => setIsHoverDelete(true)}
                      onMouseLeave={() => setIsHoverDelete(false)}
                    >
                      <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                    </span>
                  </ClickableIconButton>
                </FlexBoxItem>
              </React.Fragment>
            )}
          </FlexBox>

        );
      },
    },
  ], [getName, openConfirmModal, shiftBreakList, roleScreen]);

  return (
    <React.Fragment>
      <div>
        {
          roleScreen && roleScreen.editable === 1 && (
          <PageTitle
            title=""
            rightContents={(
              <Link to="/masterShiftPattern/add">
                <PrimaryButton
                  ghost={false}
                  text="新規シフトパターンマスタ作成"
                />
              </Link>
          )}
          />
        )}
        
        <div style={{ marginLeft: '8px' }}>
          <OrganizationFilteredTree
            functionType={1}
            orgCallback={(args: string | Array<string>) => {
              setorgCode(String(args));
            }}
            initOrgValue={String(orgCode)}
            staffCode={staffCode}
            orgLabel="組織"
            addAllItem={addAllItem}
            orgCategoryCallback={
            (args: Array<string>) => {
              setCategoryArr(args);
              sessionStorage.setItem('attendResult.selectCategoryOption', JSON.stringify(args));
            }
            }
            initCatagory={(args: string) => {
              sessionStorage.setItem('attendResult.selectCategory', JSON.stringify(args));
            }}
          />
        </div>


        <FlexBox
          customStyle={css({
            padding: '8px',
          })}
        >
          <FlexBoxItem basis="100px">
            <BodyText>
              雇用形態
            </BodyText>
          </FlexBoxItem>
          <FlexBoxItem basis="400px">
            <Select
              name="employmentId"
              value={String(employmentId || 'all')}
              setValue={setEmploymentId}
              options={shiftPatternWorkType}
            />
          </FlexBoxItem>
        </FlexBox>

        <div
          css={css`
            table th:nth-child(1) {
              width: 100px;
            }
            table th:nth-child(2) {
              width: 200px;
            }
            table th:nth-child(3) {
              width: 100px;
            }
            table th:nth-child(4) {
              width: 150px;
            }
            table th:nth-child(5) {
              width: 100px;
            }
            table th:nth-child(6) {
              width: 100px;
            }
            table th:nth-child(7) {
              width: 150px;
            }
            table th:nth-child(8) {
              width: 120px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTable
              columns={columns}
              data={shiftPatternList}
              minWidth="1084px"
              sortBy={[
                { id: 'shiftPatternCode', desc: false }
              ]}
            />
          </div>
        </div>

        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          title="確認メッセージ"
          content="削除します。よろしいですか？"
          submitText="削除"
          onSubmit={deleteShiftPatternbyID}
          items={[]}
        />
        <ToastModal
          open={toastModalOpen}
          closeHandler={() => setToastModalOpen(false)}
          title={toastMessage}
        />
      </div>
    </React.Fragment>
  );
};

export default ShiftPatternLayoutList;
