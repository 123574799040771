import moment from 'moment';
import ApiClient from './ApiClient';

export const getCompanyBankList = async (param: any, hasParam?: boolean): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = !hasParam ? {
    importFlg: true,
    hasOther: param.hasOther,
  } : {};
  const response = await ApiClient.get(`/v1/companyBank/list/${companyCode}`, params);
  return response.data;
};

export const downloadCSV = async (fileName: string, param:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsv(`/v1/bank/deposit/export/${companyCode}`, param, getDownloadCsvFileName(`${fileName}`, param.depositStartDate, param.depositEndDate));
};
const getDownloadCsvFileName = (prefix: string, startDate:string, EnDate:string) => `${prefix}_${moment(startDate).format('YYYYMMDD')}_${moment(EnDate).format('YYYYMMDD')}.csv`;

export default {
  getCompanyBankList,
  downloadCSV,
};
