type ActionTypes = {
    FETCH: string
    GET: string,
    INPUT_CHANGE:string,
    ENABLE_MONTH_LINK:string,
}
export const actionTypes: ActionTypes = {
    FETCH: 'FETCH',
    GET:'GET',
    INPUT_CHANGE:'INPUT_CHANGE',
    ENABLE_MONTH_LINK:'ENABLE_MONTH_LINK'
} as const;
