import moment from 'moment';
/**
 * API 勤怠再計算
 */

export interface SpecialHolidayDigestion {
  cnt: number,
  orgCode: string,
  orgName: string,
  staffCode: string,
  staffName: string,
  holidayCode: string,
  holidayName: string,
  total: number,
  specialHolidayDigestionDetailList: [],
  fromDate: Date,
  toDate: Date,
}


export default class SpecialHolidayDigestionDomain {
  constructor(private rawData: SpecialHolidayDigestion) {
    // do nothing
  }

  static generateInitial(): SpecialHolidayDigestionDomain {
    return new SpecialHolidayDigestionDomain({
      cnt: 0,
      orgCode: '',
      orgName: '',
      staffCode: '',
      staffName: '',
      holidayCode: '',
      holidayName: '',
      total: 0,
      specialHolidayDigestionDetailList: [],
      fromDate: new Date(),
      toDate: new Date(),

    });
  }

  getRawData(): SpecialHolidayDigestion {
    return this.rawData;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode:string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  // get

  get holidayCode():string {
    return this.rawData.holidayCode;
  }

  get holidayName():string {
    return this.rawData.holidayName;
  }

  get total():number {
    return this.rawData.total;
  }

  get totalStr():string {
    return `${this.rawData.total}日`;
  }

  get fromDate():string {
    return moment(moment(this.rawData.fromDate).toDate()).format('YYYY年MM月DD日');
  }

  get toDate():string {
    return moment(moment(this.rawData.toDate).toDate()).format('YYYY年MM月DD日');
  }


  // custom data use DataTable
}
