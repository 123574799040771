import React from 'react';

import { useParams } from 'react-router-dom';
import FormContents from 'components/atoms/Form/FormContents';
import PaidHolidayPatternInputAddForm from './PaidHolidayPatternInputAddForm';

const PaidHolidayPatternAddForm: React.FC = () => {
  const {
    paidHolidayPatternId,
  } = useParams();

  return (
    <FormContents>
      <PaidHolidayPatternInputAddForm paidHolidayPatternId={paidHolidayPatternId || ''} />
    </FormContents>
  );
};

export default PaidHolidayPatternAddForm;
