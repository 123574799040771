/** @jsx jsx */
import React, {
  Dispatch,
  SetStateAction,
} from 'react';
import { css, jsx } from '@emotion/core';
import moment from 'moment';
import { AttendErrorPersonal } from 'api/attendError';
import PrimaryButton from 'components/atoms/Button';
import { textColor, utilityColor, grayScale } from 'components/styles';
import AppNotificationPanel from 'components/molecules/AppNotificationPanel';
import Heading from 'components/atoms/Heading';
import Table from 'components/molecules/Table';
import { Link } from 'react-router-dom';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Separater from 'components/atoms/Separator';
import Icon from 'components/atoms/Icon';
import FormContents from 'components/atoms/Form/FormContents';

const styles = {
  width: css({
    width: '50vw',
  }),
  messageStyle: css({
    whiteSpace: 'pre-wrap',
    color: utilityColor.black
  })
};

const AttendErrorPersonalPannel: React.FC<{
  attendErrorList: Array<AttendErrorPersonal>;
  setOpenAttendError: Dispatch<SetStateAction<boolean>>;
}> = ({ attendErrorList, setOpenAttendError }) => {
  const loginUserNotificationType = sessionStorage.getItem('loginUser.notificationType') || '0'

  return(
  <AppNotificationPanel>
    <Heading customStyle={css({ color: textColor.main, padding: '12px' })}>
      勤怠アラート
    </Heading>
    <Separater margin="5px"></Separater>
    <div style={{maxHeight: 'calc(100vh - 30vh)', overflowY: 'auto'}}>
      <Table customStyle={styles.width}>
        <tbody>
          {
            attendErrorList.length > 0 ? attendErrorList.map((dataElement, index) => (
              <tr key={String(index)}>
                <Table.Cell>
                  <FormContents isSubForm={true}>
                    <div>
                    <Icon type="remove" color={utilityColor.white} />
                      <span css={{ color: utilityColor.black }}>{moment(new Date(dataElement.targetDate)).format('YYYY年MM月DD日')}</span>
                    </div>
                    <div>
                      <span css={styles.messageStyle}>
                      {dataElement.errorMessage.replace('</br>', '\n')}
                      </span>
                    </div>
                  </FormContents>
                </Table.Cell>
              </tr>
            ))
            :　<tr>
                <Table.Cell>
                  <FormContents isSubForm={true}>
                    <span css={{ color: utilityColor.black }}>{'アラートはありません'}</span>
                    </FormContents>
                </Table.Cell>
              </tr>
          }
        </tbody>
      </Table>
  </div>
    {
      loginUserNotificationType === '0' && (
      <FlexBox customStyle={css({ padding: '12px', alignItems: 'center' })}>
        <span>&nbsp;</span>
        <FlexBoxItem marginLeft="auto">
          <Link to="/workAchievement">
            <PrimaryButton
              text="勤務状況へ >"
              ghost={false}
              onClick={() => setOpenAttendError(false)}
            />
          </Link>
        </FlexBoxItem>
      </FlexBox>
    )}
  </AppNotificationPanel>
);
}
export default AttendErrorPersonalPannel;
