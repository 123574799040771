import moment from 'moment';
import ApiClient from './ApiClient';

export const getListDailyReport = async (param:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/daySeparateReport/${companyCode}`, param);
  return response.data;
};

export const getListMedia = async (param:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    orgCode: param.orgCode,
  };
  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/daySeparateReport/paymentmedianame/${companyCode}`, query);
  return response.data;
};

export const getListCredit = async (param:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    orgCode: param.orgCode,
  };
  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/daySeparateReport/creditname/${companyCode}`, query);
  return response.data;
};

export const downloadCsv = async (param:any, fileName: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadExcelPost(`/v1/sales/list/standard/monthly/daySeparateReport/exportFile/csv/${companyCode}`, param, {}, getDownloadCsvFileName(fileName));
};

export const downloadExcel = async (param:any, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadExcelPost(`/v1/sales/list/standard/monthly/daySeparateReport/exportFile/excel/${companyCode}`, param, {}, getDownloadExcelFileName(fileName));
};

const getDownloadCsvFileName = (prefix: string) => `${prefix}.csv`;
const getDownloadExcelFileName = (prefix: string) => `${prefix}.xlsx`;

export default { getListDailyReport };
