/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { css, jsx } from '@emotion/core';
import { iconColor } from 'components/styles';
import DataTable from 'components/organismos/DataTable/DataTable';
import Icon from 'components/atoms/Icon';
import TextTooltip from 'components/atoms/TextTooltip';
import StaffDomain from 'domain/staff';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import ClickableLink from 'components/atoms/ClickableLink';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import ButtonNavigation from './ButtonNavigation';
import { useDownload, useStaffDelete } from './hooks';
import EmploymentFilter from './EmploymentFilter';
import ExecutiveFilter from './ExecutiveFilter';
import StateStatusFilter from './StateStatusFilter';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import { OrgStatusType, SessionStorageKey, Values } from 'utility/constants';

const StaffListTable:React.FC<{
    staffList: Array<StaffDomain>;
    fetchData: () => Promise<void>;
    executiveId: string;
    setExecutiveId: (arg: string) => void;
    employmentId: string;
    setEmploymentId: (arg: string) => void;
    orgCode: string;
    setOrgCode : (arg: string) => void;
    stateStatus: Array<number>;
    setStateStatus : (arg: Array<number>) => void;
    categoryArr: Array<string>;
    setCategoryAr: (arg: Array<string>) => void;
    menuType: string;
    orgStatus: OrgStatusType;
    setOrgStatus : (arg: OrgStatusType) => void;
}> = ({
  staffList, fetchData,
  executiveId, setExecutiveId,
  employmentId, setEmploymentId,
  orgCode, setOrgCode,
  stateStatus, setStateStatus, categoryArr, setCategoryAr,
  menuType, orgStatus, setOrgStatus
}) => {
  //
  const functionType = Values.FunctionType.Master.value;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const {
    downloadStaffCsv, downloadStaffManagerCsv, downloadStaffSalaryCsv,
  } = useDownload(orgCode, executiveId, employmentId, stateStatus, categoryArr, orgStatus);

  const {
    setDeleteTargetStaff,
    isOpenDeleteModal,
    closeDeleteModal, onSubmitDeleteStaff,
  } = useStaffDelete(fetchData);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  const sortBy = React.useMemo(
    () => [
      {
        id: 'staffCode',
        desc: true,
      },
    ],
    [],
  );


  const columns: Array<Column<StaffDomain>> = React.useMemo(() => [
    {
      Header: '組織コード',
      accessor: 'orgCode',
    },
    {
      Header: '組織名',
      accessor: 'orgName',
      Cell: (cell: { row :{ isExpanded: boolean, values: StaffDomain }}) => {
        return (
          <TextTooltip id={`orgName_${cell.row.values.staffCode}`} text={cell.row.values.orgName} />
        )
      },
    },
    {
      Header: 'スタッフコード',
      accessor: 'staffCode',
      sortType: 'basic'
    },
    {
      Header: 'スタッフ名',
      accessor: 'staffName',
      Cell: (cell: { row :{ isExpanded: boolean, values: StaffDomain }}) => {
        return (
          <Link to={{ pathname: menuType !== '1' ? `/staffs/${cell.row.values.staffCode}/${menuType}` : `/staffs/employee/${cell.row.values.staffCode}/${menuType}` }}>
            <TextTooltip id={`staffName_${cell.row.values.staffCode}`} text={cell.row.values.staffName} />
          </Link>
        )
      },
    },
    {
      Header: 'スタッフ名カナ',
      accessor: 'staffNameKana',
      Cell: (cell: { row :{ isExpanded: boolean, values: StaffDomain }}) => {
        return (
          <TextTooltip id={`staffNameKana_${cell.row.values.staffCode}`} text={cell.row.values.staffNameKana} />
        )
      },
    },
    {
      Header: 'メールアドレス',
      accessor: 'mail',
      Cell: (cell: { row :{ isExpanded: boolean, values: StaffDomain }}) => {
        return (
          <TextTooltip id={`mail_${cell.row.values.staffCode}`} text={cell.row.values.mail} />
        )
      },
    },
    {
      Header: '役職',
      accessor: 'executiveName',
      Cell: (cell: { row :{ isExpanded: boolean, values: StaffDomain }}) => {
        return (
          <TextTooltip id={`executiveName_${cell.row.values.staffCode}`} text={cell.row.values.executiveName} />
        )
      },
    },
    {
      Header: '権限',
      accessor: 'roleName',
      Cell: (cell: { row :{ isExpanded: boolean, values: StaffDomain }}) => {
        return (
          <TextTooltip id={`roleName_${cell.row.values.staffCode}`} text={cell.row.values.roleName} />
        )
      },
    },
    {
      Header: '雇用形態',
      accessor: 'employmentName',
      Cell: (cell: { row :{ isExpanded: boolean, values: StaffDomain }}) => {
        return (
          <TextTooltip id={`employmentName_${cell.row.values.staffCode}`} text={cell.row.values.employmentName} />
        )
      },
    },
    {
      Header: '状態',
      accessor: 'stateStatusDisp',
    },
    {
      Header: () => null,
      accessor: 'orgCode',
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, values: StaffDomain }}) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);

        return (

          <FlexBox>
            <FlexBoxItem marginLeft="auto">
              <ClickableLink to={menuType !== '1' ? `/staffs/${cell.row.values.staffCode}/${menuType}` : `/staffs/employee/${cell.row.values.staffCode}/${menuType}`}>
                <span
                  style={{ marginLeft: '20px' }}
                  onMouseEnter={() => setIsHoverEdit(true)}
                  onMouseLeave={() => setIsHoverEdit(false)}
                >
                  <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
                </span>
              </ClickableLink>
            </FlexBoxItem>
            {
              roleScreen && roleScreen.editable === 1 && (
                <FlexBoxItem>
                  <ClickableIconButton
                    onClick={() => setDeleteTargetStaff(cell.row.values)}
                  >
                    <span
                      onMouseEnter={() => setIsHoverDelete(true)}
                      onMouseLeave={() => setIsHoverDelete(false)}
                    >
                      <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                    </span>
                  </ClickableIconButton>
                </FlexBoxItem>
              )
            }
          </FlexBox>
        );
      },
    },
  ], [setDeleteTargetStaff, roleScreen]);

  return (
    <React.Fragment>
      <div style={{ marginLeft: '8px' }}>
        <OrganizationFilteredTree
          functionType={functionType}
          orgCallback={(args: string | Array<string>) => {
            setOrgCode(String(args));
            sessionStorage.setItem('selectedOrgCodeStaffMaster', String(args));
          }}
          orgCategoryCallback={
              (args: Array<string>) => {
                setCategoryAr(args);
              }
            }
          initOrgValue={String(orgCode)}
          staffCode={loginStaffCode}
          orgLabel="組織名"
          addAllItem={true}
          orgFilter={parseInt(orgStatus)}
          showSelectOrgStatus={true}
          orgStatus={orgStatus}
          orgStatusCallback={(args: OrgStatusType) => {
            setOrgStatus(args);
            setOrgCode('all');
            // NOTE: 組織カテゴリでの絞り込みを行うために選択中の組織カテゴリを再設定 ※ 再設定を行わないとカテゴリでの絞り込みが行われない
            setCategoryAr(JSON.parse(String(sessionStorage.getItem(SessionStorageKey.Common.ORG_FILTER_TREE_TOGGLE(window.location.pathname, parseInt(args))))));
            sessionStorage.setItem(SessionStorageKey.UserSetting.StaffMaster.SEARCH_ORG_STATUS, args);
            sessionStorage.setItem('selectedOrgCodeStaffMaster', 'all');
          }}
          styles={{
            toggle: {
              width: '8rem'
            },
            selectOrgStatus: {
              width: '13rem'
            }
          }}
        />
      </div>

      <ExecutiveFilter
        executiveId={executiveId}
        setExecutiveId={(arg : string) => {
          sessionStorage.setItem('selectedExecutiveIdStaffMaster', arg);
          setExecutiveId(arg);
        }}
      />

      <EmploymentFilter
        employmentId={employmentId}
        setEmploymentId={(arg : string) => {
          sessionStorage.setItem('selectedEmploymentIdStaffMaster', arg);
          setEmploymentId(arg);
        }}
      />

      <StateStatusFilter
        stateStatus={stateStatus}
        setStateStatus={(arg : Array<number>) => {
          sessionStorage.setItem('selectedStateStatusStaffMaster', arg.toString());
          setStateStatus(arg);
        }}
      />

      <ButtonNavigation
        downloadStaffCsv={downloadStaffCsv}
        downloadStaffSalaryCsv={downloadStaffSalaryCsv}
        downloadStaffManagerCsv={downloadStaffManagerCsv}
        menuType={menuType}
        roleScreen={roleScreen}
      />
      <div
        css={css`
      table th:nth-child(1) {
        width: 80px;
      }
      table th:nth-child(2) {
        width: 150px;
      }
      table th:nth-child(3) {
        width: 90px;
      }
      table th:nth-child(4) {
        width: 120px;
      }
      table th:nth-child(5) {
        width: 120px;
      }
      table th:nth-child(6) {
        width: 140px;
      }
      table th:nth-child(7) {
        width: 80px;
      }
      table th:nth-child(8) {
        width: 120px;
      }
      table th:nth-child(9) {
        width: 100px;
      }
      table th:nth-child(10) {
        width: 50px;
      }
      table th:nth-child(11) {
        width: 80px;
      }
      `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
          <DataTable columns={columns} data={staffList} isGlobalFilter={true} minWidth="1202px" sortBy={sortBy} useSession={true}/>
        </div>
      </div>

      <ConfirmActionModal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        onSubmit={onSubmitDeleteStaff}
        actionType={ActionType.DELETE}
      />
    </React.Fragment>
  );
};

export default StaffListTable;
