/* eslint-disable max-len */
import {
  useState, useCallback, useEffect, Dispatch, SetStateAction,
} from 'react';
import { useFormik } from 'formik';
import { createOrUpdateTaxRate, deleteByTaxRate } from 'api/taxRateCaptureSetting';
import TaxRateCaptureSettingDomain from 'domain/master/sales/taxRateCaptureSetting';
import useToastNotification from 'hooks/useToastNotification';

export const useTaxRateForm = (
  taxRateList: TaxRateCaptureSettingDomain[],
  setTaxRateList: Dispatch<SetStateAction<TaxRateCaptureSettingDomain[]>>,
  isCreate: boolean,
  setIsCreate: Dispatch<SetStateAction<boolean>>,
  taxRateObj: TaxRateCaptureSettingDomain,
  setTaxRateObj: Dispatch<SetStateAction<TaxRateCaptureSettingDomain>>,
) => {
  const [confirmModalDeleteOpen, setConfirmModalDeleteOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const staffName = sessionStorage.getItem('loginUser.staffName') || '';

  const onSubmit = async (val: any) => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    const submitObj = {
      id: {
        companyCode: sessionStorage.getItem('loginUser.companyCode') || '',
        orgCode: isCreate ? sessionStorage.getItem('selectedOrgCode') : taxRateObj.orgCode,
        posType: val.posName,
      },
      taxType1: val.taxType1,
      taxType2: val.taxType2,
      taxType3: val.taxType3,
      taxType4: val.taxType4,
      taxType5: val.taxType5,
      createUser: staffName,
      updateUser: staffName,

    };

    createOrUpdateTaxRate(isCreate ? 1 : 0, submitObj)
      .then(() => {
        if (isCreate) {
          setTaxRateList(taxRateList.concat(taxRateObj));
          successNotification('登録しました。');
        } else {
          setTaxRateList(taxRateList.map(
            (item) => ((item.orgCode === taxRateObj.orgCode && item.posName === taxRateObj.posName) ? taxRateObj : item),
          ));
          successNotification('更新しました。');
        }
        setTaxRateObj(TaxRateCaptureSettingDomain.generateInitial());
        setIsCreate(true);
        setConfirmModalOpen(false);
      })
      .catch((error: any) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.forEach((element: any) => {
            stringErr += `${element.defaultMessage}<br />`;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  };

  const formik = useFormik({
    initialValues: taxRateObj,
    onSubmit,
    validateOnChange: false,

  });

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmModalDelete = useCallback(() => {
    setConfirmModalDeleteOpen(false);
  }, []);

  useEffect(() => {
    formik.setValues(taxRateObj);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxRateObj]);

  const deleteTaxRate = useCallback(() => {
    if (!confirmModalDeleteOpen) {
      setConfirmModalDeleteOpen(true);
      return;
    }

    const submitObj = {
      id: {
        companyCode: sessionStorage.getItem('loginUser.companyCode') || '',
        orgCode: taxRateObj.orgCode,
        posType: taxRateObj.posName,
      },
      taxType1: taxRateObj.taxType1,
      taxType2: taxRateObj.taxType2,
      taxType3: taxRateObj.taxType3,
      taxType4: taxRateObj.taxType4,
      taxType5: taxRateObj.taxType5,
      createUser: staffName,
      updateUser: staffName,

    };
    deleteByTaxRate(submitObj).then((Response: any) => {
      setTaxRateList(taxRateList.filter(
        (taxRate) => taxRate.orgCode !== taxRateObj.orgCode
        || taxRate.posName !== taxRateObj.posName || taxRate.companyCode !== taxRateObj.companyCode,
      ));
      setTaxRateObj(TaxRateCaptureSettingDomain.generateInitial());
      setIsCreate(true);
      setConfirmModalDeleteOpen(false);
      successNotification('削除しました。');
    }).catch((error : any) => {
      setConfirmModalDeleteOpen(false);

      if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
        let errorString : string = '';

        error.response.data.errors.forEach((errorStr: { defaultMessage: any; }) => { errorString = `${errorString}${errorStr.defaultMessage}</br>`; });

        errorNotification(errorString);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [confirmModalDeleteOpen, errorNotification, setIsCreate, setTaxRateList, setTaxRateObj, staffName, successNotification, taxRateList, taxRateObj.companyCode, taxRateObj.orgCode, taxRateObj.posName, taxRateObj.taxType1, taxRateObj.taxType2, taxRateObj.taxType3, taxRateObj.taxType4, taxRateObj.taxType5]);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    confirmModalDeleteOpen,
    closeConfirmModalDelete,
    toastModalOpen,
    closeToastModal,
    deleteTaxRate,
  };
};


export default useTaxRateForm;
