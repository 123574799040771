import ApiClient from './ApiClient';

export const getAttendNoticeSetting = async () => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const response = await ApiClient.get(`/v1/attendNoticeSetting/${companyCode}/${staffCode}`, {}, {});
  return response.data;
};

export const postAttendNoticeSetting = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v1/attendNoticeSetting/${companyCode}`, {}, params);
  return response.data;
};

export const postPhoto = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.postMutipartData(`/v1/staffs/photos/${companyCode}`, { dataType: 1 }, params);
  return response.data;
};

export const getBonusPayslipPdf = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const response = await ApiClient.getDownloadFile(`/v1/payslip/print/${companyCode}/${staffCode}`, params);
  return response.data;
};

export const getBonusPayslipPdfError = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const response = await ApiClient.get(`/v1/payslip/print/${companyCode}/${staffCode}`, {}, params);
  return response.data;
};

export const downloadOriginalPDF = async (response: any, downloadFileName: string) => {
  const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
  const file = new Blob([bom, response], {
    type: 'application/pdf',
  });
  saveAs(file, downloadFileName);
};

export const postStaffPersoalSetting = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v2/staffs/personalSetting/${companyCode}`, {}, params);
  return response.data;
};

export default {
  getAttendNoticeSetting,
  postAttendNoticeSetting,
  downloadOriginalPDF,
  getBonusPayslipPdfError,
  postStaffPersoalSetting,
};
