import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import OrganizationListTable from 'components/organismos/organization/OrganizationListTable/OrganizationListTable';

import BlockUI from 'components/molecules/BlockUi';
import { useOrganizationList } from './hooks';

const OrganizationsPage: React.FC = () => {
  const {
    organizationList,
    fetchData,
    noManager,
    toggleNoManager,
    unsetTargetDate,
    setUnsetTargetDate,
    orgStatus,
    setOrgStatus,
    blocking,
    categoryArr,
    setCategoryArr,
    arrCompanyBank
  } = useOrganizationList();

  return (
    <SidebarTemplate
      pageTitle="組織マスタ"
    >
      <BlockUI blocking={blocking}>
        <OrganizationListTable
          organizationList={organizationList}
          fetchData={fetchData}
          noManager={noManager}
          toggleNoManager={toggleNoManager}
          unsetTargetDate={unsetTargetDate}
          setUnsetTargetDate={setUnsetTargetDate}
          orgStatus={orgStatus}
          setOrgStatus={setOrgStatus}
          categoryArr={categoryArr}
          setCategoryArr={setCategoryArr}
          arrCompanyBank={arrCompanyBank}
        />
      </BlockUI>
    </SidebarTemplate>
  );
};

export default OrganizationsPage;
