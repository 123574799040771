
import {
  useState, useCallback, useEffect,
} from 'react';
import ShiftPatternDomain from 'domain/master/attend/shiftPattern';
import { useFormik } from 'formik';
import { getBusinessDataByEmploymentId } from 'api/businessMaster';
import { getRoundTimeList } from 'api/employment';
import useToastNotification from 'hooks/useToastNotification';
import { useOrgTreesOptionsAll } from 'hooks/useOrgTreesOptions';
import { OptionType } from 'components/atoms/Select';
import { createOrUpdateShiftPattern, getShiftPatternById } from 'api/shiftPattern';
import { useHistory } from 'react-router-dom';
import { ShiftPatternDetailInput } from 'components/organismos/master/labor/shiftPattern/ShiftPatternDetails/hooks';
import { useEmploymentLatestOptions } from 'hooks/useEmploymentOptions';
import moment from 'moment';

type ShiftPatternDomainKey = keyof Pick<ShiftPatternDomain, 'shiftPatternCode'|'shiftPatternName'|'shortName'>;

export const useShiftPatternAddForm = (shiftPatternId: string, typeButton: string) => {
  //
  const sessionShiftPatternOrgCode = sessionStorage.getItem('shiftPatternOrgCode') || '';
  const sessionEmploymentIdSelected = (sessionStorage.getItem('employmentIdSelected') && (sessionStorage.getItem('employmentIdSelected') !== 'NaN')) ? String(sessionStorage.getItem('employmentIdSelected')) : String('all');
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  // history
  const history = useHistory();
  //
  const { employmentOptions, attendEmploymentList } = useEmploymentLatestOptions();
  const [employmentIdSelect, setEmploymentIdSelect] = useState(sessionEmploymentIdSelected);

  // 登録内容確認モーダルの開閉状態
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  //
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [handleGetInfoShiftPattern, setHandleGetInfoShiftPattern] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { successNotification, errorNotification } = useToastNotification();

  //
  const [shiftPatternDetailsList, setShiftPatternDetailsList] = useState<Array<ShiftPatternDetailInput>>([]);
  //
  const [attendBusinessOptions, setAttendBusinessOptions] = useState<Array<OptionType>>([]);
  //
  const [listTimes, setListTimes] = useState([]);
  //
  // const orgTreesOptions = useOrgTreesOptionsAll();
  const [selectedOrgCode, setSelectedOrgCode] = useState(sessionShiftPatternOrgCode);

  const defaultTime: any = [
    '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
    '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
    '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
    '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
    '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
    '50', '51', '52', '53', '54', '55', '56', '57', '58', '59',
  ];

  //
  const onSubmit = async (values: ShiftPatternDomain) => {
    if (values.shiftPatternCode === null) {
      errorNotification('シフトパターンコードを入力してください。');
      return;
    }

    if (values.shiftPatternName === null) {
      errorNotification('シフトパターン名を入力してください。');
      return;
    }

    if (values.shortName === null) {
      errorNotification('短縮名を入力してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    // TODO companyCodeの取得処理が必要
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginUser = sessionStorage.getItem('loginUser.staffName') || '';
    //
    let convertData = values.getRawData();

    //
    delete (convertData as any).createDate;
    delete (convertData as any).updateDate;
    delete (convertData as any).companyCode;
    delete (convertData as any).attendShiftPatternDetails;

    //
    (convertData as any).shiftPatternCode = (convertData as any).shiftPatternCode.trim();
    (convertData as any).shiftPatternName = (convertData as any).shiftPatternName.trim();
    (convertData as any).shortName = (convertData as any).shortName.trim();

    //
    convertData = {
      ...convertData,
      updateUser: loginUser,
      createUser: loginUser,
    };

    shiftPatternDetailsList.forEach((item: any, index: number) => {
      delete (item as any).companyCode;
      delete (item as any).createDate;
      delete (item as any).updateDate;

      if (typeButton === 'copy') {
        (item as any).shiftPatternDetailId = '';
        (item as any).shiftPatternId = '';
      }

      const tmpDetail = {
        ...item,
        startTime: moment(item.startTime, 'HH:mm:ss').format('HH:mm'),
        endTime: moment(item.endTime, 'HH:mm:ss').format('HH:mm'),
        createUser: loginUser,
        updateUser: loginUser,
      };

      convertData = {
        ...convertData,
        [`attendShiftPatternDetails[${index}]`]: {
          ...tmpDetail,
        },
      };
    });

    // submit form
    try {
      const response = await createOrUpdateShiftPattern(companyCode, convertData);
      if (!response.errors) {
        history.push('/masterShiftPattern');
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };

  const formik = useFormik({
    initialValues: ShiftPatternDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  useEffect(() => {
    if (employmentOptions && employmentOptions.length) {
      // get info
      if (shiftPatternId && shiftPatternId !== undefined) {
        if (!handleGetInfoShiftPattern) {
          getShiftPatternDetail(shiftPatternId);
        }
      } else {
        let defaultEmploymentId = '';
        if (employmentOptions[0]) {
          defaultEmploymentId = employmentOptions[0].value;
        }
        setEmploymentIdSelect(defaultEmploymentId);
      }

      if (employmentIdSelect && employmentIdSelect !== 'all') {
        let targetDateStr = '';
        attendEmploymentList.filter((item: any) => {
          if (employmentIdSelect === item.id.employmentId) {
            targetDateStr = item.id.applyStartDate;
            return true;
          }
          return false;
        });
        handleGetRoundTimeList(employmentIdSelect, targetDateStr, '');
        //
        handleGetBusinessDataByEmploymentId(employmentIdSelect);
        formik.setFieldValue('orgCode', selectedOrgCode);
        formik.setFieldValue('employmentId', employmentIdSelect);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employmentIdSelect, employmentOptions]);

  /**
   *  funct call api & handle data
   *
   */
  const handleGetBusinessDataByEmploymentId = async (employmentId: any, isUpdate?: boolean) => {
    if (employmentId !== undefined) {
      await getBusinessDataByEmploymentId(employmentId).then((response: any) => {
        if (response && response.length) {
          let tmpAttendBusiness = response.map((business: any) => ({
            value: business.businessId,
            label: business.businessName,
          }));
          if (response.length <= 1) {
            tmpAttendBusiness = [
              {
                value: ' ',
                label: '',
              },
              ...tmpAttendBusiness,
            ];
          }
          setAttendBusinessOptions(tmpAttendBusiness);
        }
      });
    }
  };

  /**
   *  funct call api & handle data
   *
   */
  const getShiftPatternDetail = async (shiftPatternId: any) => {
    if (shiftPatternId && shiftPatternId !== undefined) {
      await getShiftPatternById(shiftPatternId).then((response: any) => {
        if (response && response.companyCode) {
          setEmploymentIdSelect(response.employmentId);
          // if typeButton is copy
          if (typeButton === 'copy') {
            response.shiftPatternId = null;
          }
          formik.setValues(new ShiftPatternDomain(response));
          const { attendShiftPatternDetails } = response;
          //
          if (attendShiftPatternDetails) {
            setShiftPatternDetailsList(attendShiftPatternDetails.map((shiftPatternDetail: ShiftPatternDetailInput) => (shiftPatternDetail)));
          } else {
            setShiftPatternDetailsList([]);
          }
          setHandleGetInfoShiftPattern(true);
          return true;
        }
        return false;
      });
    }
  };

  /**
   *  funct call api & handle data
   *
   */
  const handleGetRoundTimeList = async (employmentId: string, targetDateStr: string, staffCodestr: string, isUpdate?: boolean) => {
    try {
      await getRoundTimeList(employmentId, targetDateStr, '').then((response: any) => {
        if (response && response.roundTimeList) {
          setListTimes(response.roundTimeList);
        } else {
          setListTimes(defaultTime);
        }
      });
    } catch (e) {
      setListTimes(defaultTime);
    }
  };

  //
  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  //
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    // orgTreesOptions,
    employmentOptions,
    shiftPatternDetailsList,
    setShiftPatternDetailsList,
    attendBusinessOptions,
    setAttendBusinessOptions,
    employmentIdSelect,
    setEmploymentIdSelect,
    selectedOrgCode,
    setSelectedOrgCode,
    listTimes,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    handleGetBusinessDataByEmploymentId,
    handleGetRoundTimeList,
    attendEmploymentList,
    staffCode
  };
};

export default useShiftPatternAddForm;
