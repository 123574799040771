import moment from 'moment';

export interface SelfOrganization {
  orgCode: string;
  orgName: string;
  category: number;
  employmentId: string;
  startDate: string;
  endDate?: string;
}

export type StoreState = 'open' | 'close';

export default class SelfOrganizationDomain {
  private rawData: SelfOrganization

  constructor(rawData: SelfOrganization) {
    // 日付のフォーマットを変換しておく
    this.rawData = {
      ...rawData,
    };
  }

  static generateInitial(): SelfOrganizationDomain {
    return new SelfOrganizationDomain({
      orgCode: '',
      orgName: '',
      category: 0,
      employmentId: '',
      startDate: '',
      endDate: '',
    });
  }

  getRawData(): SelfOrganization {
    return this.rawData;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get category(): number {
    return this.rawData.category;
  }

  set category(category: number) {
    this.rawData.category = Number(category);
  }

  get employmentId(): string {
    return this.rawData.employmentId || '';
  }

  set employmentId(employmentId: string) {
    this.rawData.employmentId = employmentId;
  }

  get startDate(): string {
    return this.rawData.startDate || '';
  }

  get startDateAsDate(): Date {
    return moment(this.rawData.startDate).toDate();
  }

  get endDate(): string|null {
    return this.rawData.endDate || '';
  }

  get endDateAsDate(): Date|null {
    if (!this.rawData.endDate) {
      return null;
    }
    return moment(this.rawData.endDate).toDate();
  }
}
