import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';
import * as api from 'api/monthlyReport';
import { apiGetMediaSales } from 'api/monthly/mediaSales';

const useMediaSalesList = () => {
  const functionType = 3;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode'));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);
  
  const dateSelectFrom = moment().startOf('month').format('YYYY-MM-DD');
  const [selectedDateFrom, setSelectedDateFrom] = useState<Date>(new Date(dateSelectFrom));
  const dateSelectTo =  moment().format('DD') !== '01' ? moment().add(-1, 'days').format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD');
  const [selectedDateTo, setSelectedDateTo] = useState<Date>(new Date(dateSelectTo));

  const dummyData: any = [
  ];

  const [listMediaSales, setListMediaSales] = useState(dummyData);
  const fetchData = useCallback(async () => {
    setLoading(true);
    const path = 'mediaSales';
    try {
      const response = await apiGetMediaSales(path, String(orgCode), moment(selectedDateFrom.toString()).format('YYYY/MM/DD'), moment(selectedDateTo.toString()).format('YYYY/MM/DD'), categoryAry);
      setListMediaSales(response);
    } catch (error) {
      setListMediaSales([]);
    }
    setLoading(false);
  }, [orgCode, selectedDateFrom, selectedDateTo, categoryAry]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
 
  return {
    isLoading,
    listMediaSales,
    functionType,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo
  };
};

export const useDownload = () => {
  const downloadMediaSalesCsv = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '媒体売上.csv');
  };

  const downloadMediaSalesExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '媒体売上.xlsx');
  };

  return {
    downloadMediaSalesCsv,
    downloadMediaSalesExcel,
  };
};

export default useMediaSalesList;
