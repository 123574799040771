import BalanceStatementStoreLayout from 'components/organismos/master/sales/BalanceStatementStoreForm/balanceStatementStoreLayout';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';

const balanceStatementStorePage = () => (
    <SidebarTemplate pageTitle="【店別】収支明細表">
        <BalanceStatementStoreLayout />
    </SidebarTemplate>
);

export default balanceStatementStorePage;