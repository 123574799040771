/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React, {
  useCallback, useReducer, useContext, createContext, useState, useEffect,
} from 'react';
import useToastNotification from 'hooks/useToastNotification';
// import { postCsvImport, Error } from 'api/organization';
import moment from 'moment';
import { OptionType } from 'components/atoms/Select';
import { getCompanyBankList } from 'api/companyBank';
import { date } from 'yup';
import { fetch } from '../regist/reducer/action';
import { initialState, reducer } from '../regist/reducer/reducer';
import { getOrgTreeBank } from '../api/organizations';
import { getBankList, getList } from '../api/regist';
import {
  TCompanyBankList, TBankList, TCompanyBank, TBankTabs, TOtherCompanyBank,
} from '../type/regist';
import { deleteDeposit, registerDeposit } from '../api/deposit';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

export interface IOtherBankOrgTree {
  [key: string]: OptionType[]
}

interface TDateOption {
  applyDate: string,
  startDateStatus: boolean,
  endDateStatus: boolean,
}
interface IBankRegistContext {
  stateReducer: TCompanyBankList;
  bankList: OptionType[];
  dispatch: React.Dispatch<{
    type: string;
    response: TCompanyBankList;
  }>;
  orgTreeBank: OptionType[];
  otherBankOrgTree: IOtherBankOrgTree;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  depositDate: number;
  currentBank: string;
  setCurrentBank: React.Dispatch<React.SetStateAction<string>>;
  confirmModalOpen: boolean;
  setConfirmModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeConfirmModal: any;
  confirmModalDelete: boolean;
  setConfirmModalDelete: React.Dispatch<React.SetStateAction<boolean>>;
  closeConfirmModalDelete: any;
  handleAddRow: () => void;
  handleChangeDynamicDate: (date: number, setType: string) => void;
  handleOnRegister: (depositList: any) => void;
  handleOnDelete: () => void;
  roleScreen: any;
  handleChangeTrap: {
    set(obj: TCompanyBank, prop: string, value: any): boolean;
  };
  dateOption: TDateOption,
  setDateOption: React.Dispatch<React.SetStateAction<TDateOption>>,
  handleChangeDateOption: (input: React.ChangeEvent<HTMLInputElement> | Date) => void,

  handleDynamicApplyDate: (bankCode: string) => void,
  handleChangeAllDateStatus: (input: React.ChangeEvent<HTMLInputElement>, currentBank: string) => void
}

export const BankRegistProviderContext = createContext<IBankRegistContext>({
  stateReducer: initialState,
  bankList: [],
  dispatch: () => null,
  orgTreeBank: [],
  otherBankOrgTree: {},
  isLoading: true,
  setIsLoading: () => null,
  depositDate: moment(new Date()).valueOf(),
  currentBank: '',
  setCurrentBank: () => null,
  confirmModalOpen: false,
  setConfirmModalOpen: () => null,
  closeConfirmModal: () => null,
  confirmModalDelete: false,
  setConfirmModalDelete: () => null,
  closeConfirmModalDelete: () => null,
  handleAddRow: () => null,
  handleChangeDynamicDate: () => null,
  handleOnRegister: () => null,
  handleOnDelete: () => null,
  roleScreen: null,
  handleChangeTrap: {
    set: () => true || false,
  },
  dateOption: {
    applyDate: moment(new Date()).format('YYYY-MM-DD'),
    startDateStatus: true,
    endDateStatus: true,
  },
  setDateOption: () => null,
  handleChangeDateOption: (input: React.ChangeEvent<HTMLInputElement> | Date) => null,
  handleDynamicApplyDate: (bankCode: string) => null,
  handleChangeAllDateStatus: () => null,
});

export const useBankDetailProvider = () => {
  const context = useContext(BankRegistProviderContext);
  if (!context) {
    throw new Error('useBankDetailProvider must be used within a BankDetailProvider');
  }
  return context;
};

export const BankDetailProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [stateReducer, dispatch] = useReducer(reducer, initialState);
  const [bankList, setBankList] = useState<OptionType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [depositDate, setDepositDate] = useState<number>(moment(new Date()).valueOf());
  const [orgTreeBank, setOrgTreeBank] = useState<OptionType[]>([]);
  const [currentBank, setCurrentBank] = useState<string>('');
  const [otherBankOrgTree, setOtherBankOrgTree] = useState<IOtherBankOrgTree>({});
  const createUser = sessionStorage.getItem('loginUser.staffName') || '';
  // Auth section
  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  // Caching
  const [cacheOtherBankList, setCacheOtherBankList] = useState<IOtherBankOrgTree>({});
  const [dateOption, setDateOption] = useState<TDateOption>({
    applyDate: moment(new Date()).format('YYYY-MM-DD'),
    startDateStatus: true,
    endDateStatus: true,
  });
  const handleChangeDateOption = (input: React.ChangeEvent<HTMLInputElement> | Date) => {
    if (input instanceof Date) {
      const newResult = { ...dateOption, applyDate: moment(input).format('YYYY-MM-DD') };
      setDateOption(newResult);
    } else {
      const newResult = { ...dateOption, [input.target.name]: input.target.checked };
      setDateOption(newResult);
    }
  };
  const handleChangeAllDateStatus = (input: React.ChangeEvent<HTMLInputElement>, currentBank: string) => {
    const allBanks = JSON.parse(JSON.stringify(stateReducer.companyBankListHashMap));
    for (const item in allBanks) {
      if (allBanks[item].bankData.length > 0 && allBanks[item].bankCode === currentBank) {
        const newBankData: TCompanyBank[] = allBanks[item].bankData.map((item: TCompanyBank) => ({
          ...item,
          dateChangeStatus: input.target.checked,
        }));
        allBanks[item].allDateChangeStatus = input.target.checked;
        allBanks[item].bankData = ([] as TCompanyBank[]).concat(newBankData);
      }
    }
    const newStateReducer: TCompanyBankList = {
      companyBankListHashMap: allBanks,
    };
    dispatch(fetch(newStateReducer));
  };
  const handleChangeTrap = {
    set(obj: TCompanyBank, prop: string, value: any) {
      const bank = obj;
      switch (typeof value) {
        case 'string':
          if (prop === 'businessStartDate') {
            if (moment(value).valueOf() > moment(bank.businessEndDate).valueOf()) {
              bank.businessStartDate = value;
              bank.businessEndDate = value;
            } else {
              bank.businessStartDate = value;
            }
          }
          if (prop === 'businessEndDate') {
            if (moment(value).valueOf() < moment(bank.businessStartDate).valueOf()) {
              bank.businessStartDate = value;
              bank.businessEndDate = value;
            } else {
              bank.businessEndDate = value;
            }
          }
          if (prop === 'remark') bank.remark = value.trim();
          if (prop === 'changeReason') bank.changeReason = value.trim();

          if (prop === 'organizationBankId') bank.organizationBankId = value;

          if (prop === 'orgCode') {
            bank.orgCode = value;
            fetchOtherBankList(value, bank);
          }
          break;
        case 'number':
          if (prop === 'depositAmount') bank.depositAmount = value;
          if (prop === 'withdrawalAmount') bank.withdrawalAmount = value;
          break;
        case 'boolean':
          if (prop === 'accountingNonLinkFlg') bank.accountingNonLinkFlg = value;
          if (prop === 'deleteFlg') bank.deleteFlg = value;
          if (prop === 'dateChangeStatus') bank.dateChangeStatus = value;
          break;
        default:
          break;
      }

      // Indicate success
      dispatch(fetch(stateReducer));
      return true;
    },
  };

  const handleAddRow = () => {
    const OtherBank = stateReducer.companyBankListHashMap['0'];

    const newRowValue: TOtherCompanyBank = {
      deleteFlg: false,
      bankDepositWithdrawalId: '',
      companyCode: '',
      recordNo: OtherBank.bankData.length + 1,
      bankCode: '0',
      depositDate: moment(depositDate).format('YYYY/MM/DD'),
      orgCode: '',
      registrationNo: '0',
      organizationBankId: '',
      accountingNonLinkFlg: false,
      depositWithdrawalType: '',
      depositAmount: 0,
      withdrawalAmount: 0,
      businessStartDate: moment(new Date()).format('YYYY/MM/DD'),
      businessEndDate: moment(new Date()).format('YYYY/MM/DD'),
      dateChangeStatus: true,
      remark: '',
      usabilityGeneralItem1: '',
      usabilityGeneralItem2: '',
      usabilityGeneralItem3: '',
      usabilityGeneralItem4: '',
      usabilityGeneralItem5: '',
    };
    OtherBank.bankData = [...OtherBank.bankData].concat(newRowValue);
    setOtherBankOrgTree({ ...otherBankOrgTree, [OtherBank.bankData.length]: [] });
    dispatch(fetch(stateReducer));
  };
  const handleDynamicApplyDate = (bankCode: string) => {
    const allBanks = JSON.parse(JSON.stringify(stateReducer.companyBankListHashMap));
    for (const item in allBanks) {
      if (allBanks[item].bankData.length > 0 && allBanks[item].bankCode === bankCode) {
        const newBankData: TCompanyBank[] = allBanks[item].bankData.map((item: TCompanyBank) => {
          if (item.dateChangeStatus === true) {
            return {
              ...item,
              businessStartDate: dateOption.startDateStatus ? moment(dateOption.applyDate).format('YYYY/MM/DD') : dateOption.endDateStatus ? moment(dateOption.applyDate).valueOf() < moment(item.businessStartDate).valueOf() ? moment(dateOption.applyDate).format('YYYY/MM/DD') : moment(item.businessStartDate).format('YYYY/MM/DD') : moment(item.businessStartDate).format('YYYY/MM/DD'),
              businessEndDate: dateOption.endDateStatus ? moment(dateOption.applyDate).format('YYYY/MM/DD') : dateOption.startDateStatus ? moment(dateOption.applyDate).valueOf() > moment(item.businessEndDate).valueOf() ? moment(dateOption.applyDate).format('YYYY/MM/DD') : moment(item.businessEndDate).format('YYYY/MM/DD') : moment(item.businessEndDate).format('YYYY/MM/DD'),
            };
          }
          return item;
        });
        allBanks[item].bankData = ([] as TCompanyBank[]).concat(newBankData);
      }
    }
    const newStateReducer: TCompanyBankList = {
      companyBankListHashMap: allBanks,
    };
    dispatch(fetch(newStateReducer));
  };
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);
  const [confirmModalDelete, setConfirmModalDelete] = useState(false);
  const closeConfirmModalDelete = useCallback(() => {
    setConfirmModalDelete(false);
  }, []);

  const handleOnRegister = async (depositList: TCompanyBank[]) => {
    const postData: any = [];
    depositList.forEach((item) => {
      postData.push({
        bankDepositWithdrawalId: item.bankDepositWithdrawalId,
        recordNo: item.recordNo,
        deleteFlg: item.deleteFlg || false,
        orgCode: item.orgCode,
        registrationNo: item.registrationNo,
        accountingNonLinkFlg: item.accountingNonLinkFlg,
        organizationBankId: item.organizationBankId,
        depositWithdrawalType: item.depositWithdrawalType,
        depositAmount: item.depositAmount,
        withdrawalAmount: item.withdrawalAmount,
        businessStartDate: moment(item.businessStartDate).format('YYYY/MM/DD'),
        businessEndDate: moment(item.businessEndDate).format('YYYY/MM/DD'),
        remark: item.remark,
        changeReason: currentBank === '0' ? null : item.changeReason || '',
      });
    });
    const params = {
      depositDate: moment(depositDate).format('YYYY/MM/DD'),
      bankCode: currentBank,
      depositList: postData,
      createUser,
    };
    setConfirmModalOpen(true);
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
    } else {
      setConfirmModalOpen(false);
      setIsLoading(true);
      await registerDeposit(params).then((response) => {
        successNotification('登録しました。');
        fetchData(cacheOtherBankList);
        setIsLoading(false);
      }).catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage} \n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else if (error.response && error.response.data && Array.isArray(error.response.data)) {
          const normalError = error.response.data;
          let stringNormalError = '';
          normalError.map((element: any) => {
            stringNormalError += `${element.defaultMessage} \n`;
            return stringNormalError;
          });
          errorNotification(stringNormalError);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    }
  };

  const handleOnDelete = async () => {
    const params = {
      depositDate: moment(depositDate).format('YYYY/MM/DD'),
      bankCode: currentBank,
    };
    setConfirmModalDelete(true);
    if (!confirmModalDelete) {
      setConfirmModalDelete(true);
    } else {
      setConfirmModalDelete(false);
      setIsLoading(true);
      try {
        const response = await deleteDeposit(params);
        const bank = stateReducer.companyBankListHashMap[currentBank];
        if (!bank) return;
        const newList = { ...stateReducer.companyBankListHashMap };
        newList[currentBank].bankData = response.data.companyBankList;
        // dispatch(fetch(stateReducer));
        successNotification('削除しました。');
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        let msgError = '';
        if (error.response && error.response.data && error.response.data.errors) {
          error.response.data.errors.map((item: { defaultMessage: string; }) => {
            msgError += `${item.defaultMessage} </br>`;
            return msgError;
          });
        } else {
          msgError = 'サーバー側でエラーが発生しました。';
        }
        errorNotification(msgError);
      }
    }
  };
  const handleFetchDataFail = useCallback((error: any) => {
    if (error.response && error.response.data && error.response.data.errors) {
      const listErr = error.response.data.errors;
      let stringErr = '';
      listErr.map((element: any) => {
        stringErr += `${element.defaultMessage} \n`;
        return stringErr;
      });
      errorNotification(stringErr);
    } else if (error.response && error.response.data && Array.isArray(error.response.data)) {
      const normalError = error.response.data;
      let stringNormalError = '';
      normalError.map((element: any) => {
        stringNormalError += `${element.defaultMessage} \n`;
        return stringNormalError;
      });
      errorNotification(stringNormalError);
    } else {
      errorNotification('サーバー側でエラーが発生しました。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchManyOtherBankList = useCallback(async (cacheOtherBankList: IOtherBankOrgTree, otherBank: TBankTabs) => {
    const depositDateFormat = moment(depositDate).format('YYYY/MM/DD');
    let countBankFetch = 0;
    let countBankNoFetch = 0;

    const fetchListHashMap = otherBank.bankData.reduce((obj: any, item, currentIndex: number) => {
      const key = `${depositDateFormat}-${item.orgCode}`;
      // If no orgCode => no fetch
      if (item.orgCode === '') return obj;

      countBankFetch = !cacheOtherBankList[key] ? countBankFetch + 1 : countBankFetch;
      countBankNoFetch = cacheOtherBankList[key] ? countBankNoFetch + 1 : countBankNoFetch;

      // If have same orgCode
      if (obj[item.orgCode] && obj[item.orgCode].banks.length > 0) {
        obj[item.orgCode].banks.push(item.organizationBankId);
        return obj;
      }

      // Fetch with new orgCode
      return {
        ...obj,
        [item.orgCode]: {
          banks: [item.organizationBankId],
          depositDate: depositDateFormat,
          orgCode: item.orgCode,
          bankCode: item.bankCode,
          organizationBankId: item.organizationBankId,
          hasFetch: !!cacheOtherBankList[key],
          OrganizationBankList: cacheOtherBankList[key] || [],
          key,
        },
      };
    }, {});

    const promiseList: Array<{
      banks: Array<string>
      depositDate: string
      orgCode: string
      bankCode: string
      organizationBankId: string
      hasFetch: boolean
      OrganizationBankList: Array<any>
      key: string
    }> = Object.values(fetchListHashMap);

    const newOtherBankList: any = {};
    const newOtherBankOrgTree: any = {};
    let countFetch = 0;
    let countNoFetch = 0;
    promiseList.forEach((val) => {
      if (val.hasFetch) {
        val.banks.forEach((bankId) => {
          countNoFetch += 1;
          const orgBank = val.OrganizationBankList;
          const bank = otherBank.bankData.filter((item) => item.organizationBankId === bankId);
          bank.forEach((bankItem) => {
            if (orgBank.length > 0) {
              bankItem.organizationBankId = bankItem.organizationBankId || orgBank[0].value;
            } else bankItem.organizationBankId = '';
            newOtherBankOrgTree[bankItem.recordNo] = orgBank;
          });
        });

        if (countNoFetch === countBankNoFetch) {
          setOtherBankOrgTree({ ...newOtherBankOrgTree });
          dispatch(fetch(stateReducer));
        }
      } else {
        getBankList(val.depositDate, val.orgCode, val.bankCode)
          .then((result: any) => {
            if (result?.data?.OrganizationBankList) {
              const orgBank: OptionType[] = result.data.OrganizationBankList.map((val: any) => ({
                value: val.organizationBankId,
                label: val.bankName,
              }));
              val.banks.forEach((bankId) => {
                countFetch += 1;
                const bank = otherBank.bankData.filter((item) => item.organizationBankId === bankId);
                bank.forEach((bankItem) => {
                  if (orgBank.length > 0) {
                    bankItem.organizationBankId = bankItem.organizationBankId || orgBank[0].value;
                  } else bankItem.organizationBankId = '';
                  newOtherBankOrgTree[bankItem.recordNo] = orgBank;
                });
              });
              newOtherBankList[val.key] = orgBank;
            }
          })
          .catch((error) => {
            handleFetchDataFail(error);
          })
          .finally(() => {
            // Last promise finish
            if (countFetch === countBankFetch) {
              setOtherBankOrgTree({ ...newOtherBankOrgTree });
              setCacheOtherBankList({ ...cacheOtherBankList, ...newOtherBankList });
              dispatch(fetch(stateReducer));
            }
          });
      }
    });
  }, [depositDate, handleFetchDataFail, stateReducer]);

  const fetchData = useCallback(async (cacheOtherBankList: IOtherBankOrgTree) => {
    if (bankList.length <= 0) return;

    setIsLoading(true);
    const depositDateFormat = moment(depositDate).format('YYYY/MM/DD');
    const companyBankListHashMap: any = {};

    const fetchList = bankList.map((val) => ({
      promise: getList(depositDateFormat, val.value),
      bankCode: val.value,
    }));

    fetchList.forEach((val, index: number) => {
      val.promise
        .then((result: any) => {
          const companyBank = {
            bankData: result.data.companyBankList.map((item: any) => ({
              ...item,
              dateChangeStatus: true,
            })),
            bankCode: val.bankCode,
            bankIndex: index,
            allDateChangeStatus: true,
          };
          companyBankListHashMap[val.bankCode] = companyBank;
        })
        .catch((error) => {
          const companyBank = {
            bankData: [],
            bankCode: val.bankCode,
            bankIndex: index,
          };
          companyBankListHashMap[val.bankCode] = companyBank;
          handleFetchDataFail(error);
        })
        .finally(() => {
          if (Object.keys(companyBankListHashMap).length === bankList.length) {
            setIsLoading(false);
            stateReducer.companyBankListHashMap = companyBankListHashMap;
            if (companyBankListHashMap['0']?.bankData.length > 0) {
              fetchManyOtherBankList(cacheOtherBankList, companyBankListHashMap['0']);
              return;
            }
            dispatch(fetch(stateReducer));
          }
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositDate, bankList, handleFetchDataFail]);

  const fetchBankList = useCallback(async () => {
    try {
      const param = {
        hasOther: true,
      };
      const res = await getCompanyBankList(param);
      if (res?.companyBankList) {
        const bankList: OptionType[] = res.companyBankList.map((val: TBankList) => (
          {
            value: val.bankCode,
            label: val.bankName,
          }
        ));

        setBankList(bankList);
        setCurrentBank(bankList[0].value);
      }
    } catch (error) {
      handleFetchDataFail(error);
    }
  }, [handleFetchDataFail]);

  const fetchOtherBankList = useCallback(async (orgCode: string, bank: TCompanyBank) => {
    const { bankCode } = bank;
    const depositDateFormat = moment(depositDate).format('YYYY/MM/DD');
    const key = `${depositDateFormat}-${orgCode}`;
    // Check cache
    const orgBank = cacheOtherBankList[key];
    if (orgBank) {
      setOtherBankOrgTree({ ...otherBankOrgTree, [bank.recordNo]: orgBank });
      if (orgBank.length > 0) bank.organizationBankId = orgBank[0].value;
      else bank.organizationBankId = '';
      dispatch(fetch(stateReducer));
      return;
    }

    try {
      const res = await getBankList(depositDateFormat, orgCode, bankCode);
      if (res?.data?.OrganizationBankList) {
        const orgBank: OptionType[] = res.data.OrganizationBankList.map((val: any) => ({
          value: val.organizationBankId,
          label: val.bankName,
        }));

        setOtherBankOrgTree({ ...otherBankOrgTree, [bank.recordNo]: orgBank });
        if (orgBank.length > 0) bank.organizationBankId = orgBank[0].value;
        else bank.organizationBankId = '';
        setCacheOtherBankList({ ...cacheOtherBankList, [key]: orgBank });
        dispatch(fetch(stateReducer));
      }
    } catch (error) {
      handleFetchDataFail(error);
    }
  }, [cacheOtherBankList, depositDate, handleFetchDataFail, otherBankOrgTree, stateReducer]);

  const fetchOrgTreeBank = useCallback(async () => {
    const targetDayFrom = moment(depositDate).format('YYYY-MM-DD');
    const targetDayTo = moment(depositDate).format('YYYY-MM-DD');

    try {
      const res = await getOrgTreeBank(targetDayFrom, targetDayTo);
      if (res) {
        const orgTree: OptionType[] = res.map((val: any) => ({
          value: val.orgCode,
          label: `${val.orgCode} ${val.orgName}`,
        }));
        setOrgTreeBank(orgTree);
      }
    } catch (error) {
      handleFetchDataFail(error);
    }
  }, [depositDate, handleFetchDataFail]);


  useEffect(() => {
    fetchBankList();
  }, [fetchBankList]);

  useEffect(() => {
    fetchData(cacheOtherBankList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  useEffect(() => {
    fetchOrgTreeBank();
  }, [depositDate, fetchOrgTreeBank]);

  const handleChangeDynamicDate = (date: number, setType: string) => {
    // 86.400.000 ms for a day
    if (setType === 'inc') {
      setDepositDate(date + 86400000);
    } else if (setType === 'desc') {
      setDepositDate(date - 86400000);
    } else {
      setDepositDate(date);
    }
  };
  const value = {
    stateReducer,
    bankList,
    setBankList,
    dispatch,
    isLoading,
    setIsLoading,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    confirmModalDelete,
    setConfirmModalDelete,
    closeConfirmModalDelete,
    handleAddRow,

    depositDate,
    orgTreeBank,
    currentBank,
    setCurrentBank,

    handleChangeDynamicDate,
    handleChangeTrap,
    handleOnRegister,
    handleOnDelete,

    otherBankOrgTree,
    setOtherBankOrgTree,

    roleScreen,

    dateOption,
    setDateOption,
    handleChangeDateOption,
    handleDynamicApplyDate,

    handleChangeAllDateStatus,
  };
  return (
    <BankRegistProviderContext.Provider value={value}>
      {children}
    </BankRegistProviderContext.Provider>
  );
};
