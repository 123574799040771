/* eslint-disable jsx-a11y/control-has-associated-label */
/** @jsx jsx */
import { useMemo } from 'react';
import { css, jsx } from '@emotion/core';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import BlockUI from 'components/molecules/BlockUi';
import FormLabel from 'components/atoms/Form/FormLabel';
import { isMacOs, isMobile, isMobileOnly } from 'react-device-detect';
import moment from 'moment';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import Icon from 'components/atoms/Icon';
import { textColor } from 'components/styles';
import BottomBar from 'components/organismos/BottomBar';
import DatePicker from 'components/molecules/DatePicker';
import BodyText from 'components/atoms/BodyText';
import usePeriodSalesForm from './hooks';
import { numberFormater } from 'utility/formatUtil';

const PeriodSalesForm = () => {
  const functionType = 3;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const {
    setCategoryAry,
    operationReport,
    isLoading,
    fetchData,
    setSelectOrgCode,
    selectOrgCode,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    searchTime,
  } = usePeriodSalesForm();

  const tblRows = useMemo(() => ['日付', '男性', '女性', '計', '', '初回', '2回目', '3回目', '常連', '計', '', '回数', '金額'], []);

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox alignItems="center">

          <FlexBox customStyle={css({ flexWrap: 'wrap' })}>
            <FlexBoxItem width={isMobile ? '65px' : '100px'}>
              {isMobile ? (
                <FormLabel
                  label="開始日"
                />
              ) : (
                <FormLabel
                  label="対象期間"
                />
              )}

            </FlexBoxItem>

            <FlexBoxItem marginRight="6px">
              <DatePicker
                dateFormat="yyyy/MM/dd"
                date={targetDateFrom}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateFrom(date);
                  }
                }}
              />
            </FlexBoxItem>
            <FlexBox>
              {
              isMobile ? (
                <FlexBoxItem width={isMobile ? '65px' : '100px'}>
                  <BodyText>終了日</BodyText>
                </FlexBoxItem>
              ) : (
                <FlexBoxItem marginLeft="-2px">
                  <span style={{ margin: '0 20px' }}>～</span>
                </FlexBoxItem>
              )
            }


              <FlexBoxItem customStyle={css({ marginTop: isMobile ? '10px' : '' })}>
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  date={targetDateTo}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      setTargetDateTo(date);
                    }
                  }}
                />
              </FlexBoxItem>
            </FlexBox>


          </FlexBox>
          <FlexBoxItem marginLeft="auto" customStyle={css({ marginTop: isMobileOnly ? '-40px' : 0 })}>
            <ClickableIconButton
              additionalCss={css({
                backgroundColor: '#0D4796', paddingLeft: '25px', paddingRight: '25px', borderRadius: '5px',
              })}
              onClick={fetchData}
            >
              <Icon type="reload" color={textColor.inversed} isHover={false} size="25" />
            </ClickableIconButton>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox>
          <FlexBoxItem width="100%">
            <OrganizationFilteredTree
              functionType={functionType}
              staffCode={staffCode}
              orgLabel="組織名"
              initOrgValue={String(selectOrgCode)}
              orgCallback={(args: string | Array<string>) => {
                setSelectOrgCode(String(args));
              }}
              orgCategoryCallback={(args: Array<string>) => {
                setCategoryAry(args);
              }}
              styles={{
                label: {
                  toggleSwitch: css({ marginLeft: '0px' }),
                },
                toggle: {
                  width: '13rem',
                },
              }}
            />
          </FlexBoxItem>
        </FlexBox>

        <FlexBox customStyle={css({ marginTop: '12px', marginBottom: '16px' })}>
          <FormLabel label={moment(searchTime).format('YYYY年MM月DD日（ddd） HH時mm分まで')} />
        </FlexBox>

        <div
          className="periodSalesTable newDesignTable nobackground"
          style={{
            height: 'auto', minHeight: '10%', padding: isMacOs ? '35px' : '0px',
          }}
        >
          <table className="table table-bordered text-nowrap" id="periodSales">
            <thead>
              <tr>
                {tblRows.map((item: any, index: any) => (
                  <th
                    style={index === 0 ? {
                      top: 0, left: 0, zIndex: 99,
                    } : { zIndex: 95, top: 0 }}
                    className={index === 0 ? 'stickyStyle bgGray fristColSticky' : ' text-center bgGray'}
                  >
                    {item}
                  </th>
                ))}

              </tr>
            </thead>
            <tbody>
              {
                operationReport?.map((item: any) => (
                  <tr>
                    <td
                      className={item.targetDay !== '' ? 'stickyStyle fristColSticky' : 'text-center stickyStyle fristColSticky'}
                      style={{ top: '0', left: '0', zIndex: 95 }}
                    >
                      {item.targetDay !== '' ? item.targetDay : '合計'}
                    </td>
                    <td className="text-right pr-10">{numberFormater(item.maleCount)}</td>
                    <td className="text-right pr-10">{numberFormater(item.womanCount)}</td>
                    <td className="text-right pr-10">{numberFormater(item.sexTotalCount)}</td>
                    <td className="text-right pr-10 bgGray" />
                    <td className="text-right pr-10">{numberFormater(item.firstCount)}</td>
                    <td className="text-right pr-10">{numberFormater(item.secondCount)}</td>
                    <td className="text-right pr-10">{numberFormater(item.thirdCount)}</td>
                    <td className="text-right pr-10">{numberFormater(item.regularCount)}</td>
                    <td className="text-right pr-10">{numberFormater(item.visitNumCount)}</td>
                    <td className="text-right pr-10 bgGray" />
                    <td className="text-right pr-10">{numberFormater(item.unaccountCount)}</td>
                    <td className="text-right pr-10">{numberFormater(item.priceTotal)}</td>

                  </tr>
                ))
              }

            </tbody>
          </table>
        </div>
      </FormContents>
      {isMobileOnly && (<BottomBar />)}
    </BlockUI>
  );
};
export default PeriodSalesForm;
