import { FiscalYear } from 'api/fiscalYear';
import moment from 'moment';

export default class FiscalYearDomain {
  private rawData: FiscalYear

  constructor(rawData: FiscalYear) {
    this.rawData = {
      ...rawData,
      id: {
        companyCode: rawData.id.companyCode,
        fiscalYear: rawData.id.fiscalYear,
      },
      fiscalYearStr: `${rawData.id.fiscalYear}年`,
      fiscalStartMonth: convertDateToStringYYYYMMDD(rawData.fiscalStartMonth),
      fiscalEndMonth: convertDateToStringYYYYMMDD(rawData.fiscalEndMonth),
      fiscalStartMonthStr: moment(rawData.fiscalStartMonth).format('YYYY年MM月'),
      fiscalEndMonthStr: moment(rawData.fiscalEndMonth).format('YYYY年MM月'),
    };
    console.log(`fiscalStartMonth = ${this.rawData.fiscalStartMonth}`);
  }

  static generateInitial(): FiscalYearDomain {
    return new FiscalYearDomain({
      id: {
        companyCode: '',
        fiscalYear: moment().format('YYYY'),
      },
      fiscalStartMonth: moment().format('YYYY/MM/DD'),
      fiscalEndMonth: moment().format('YYYY/MM/DD'),
      createUser: '',
      updateUser: '',
      createDate: '',
      updateDate: '',
      isCreate: false,
      fiscalYearStr: '',
      fiscalStartMonthStr: '',
      fiscalEndMonthStr: '',
    });
  }

  getRawData(): FiscalYear {
    return this.rawData;
  }

  getRawDataPost(): any {
    return {
      fiscalYearStr: this.rawData.fiscalYearStr,
      fiscalStartMonthStr: this.rawData.fiscalStartMonthStr,
      fiscalEndMonthStr: this.rawData.fiscalEndMonthStr,
      isCreate: true,
      createUser: this.rawData.createUser,
      updateUser: this.rawData.updateUser,
    };
  }

  get companyCode(): string {
    return this.rawData.id.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.id.companyCode = companyCode;
  }

  get fiscalYear(): string {
    return `${this.rawData.id.fiscalYear}`;
  }

  set fiscalYear(fiscalYear: string) {
    this.rawData.id.fiscalYear = fiscalYear;
    this.rawData.fiscalYearStr = `${fiscalYear}年`;
  }

  get fiscalYearStr(): string {
    return `${this.rawData.fiscalYearStr}`;
  }

  get fiscalStartMonth(): Date {
    return new Date(this.rawData.fiscalStartMonth);
  }

  set fiscalStartMonth(date: Date) {
    console.log(`date = ${date}`);
    this.rawData.fiscalStartMonth = date ? moment(date).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD');
    this.rawData.fiscalStartMonthStr = date ? moment(date).format('YYYY年MM月') : moment().format('YYYY年MM月');
    console.log(`this.rawData.fiscalStartMonth = ${this.rawData.fiscalStartMonth}`);
  }

  get fiscalEndMonth(): Date {
    return new Date(this.rawData.fiscalEndMonth);
  }

  set fiscalEndMonth(date: Date) {
    this.rawData.fiscalEndMonth = date ? moment(date).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD');
    this.rawData.fiscalEndMonthStr = date ? moment(date).format('YYYY年MM月') : moment().format('YYYY年MM月');
  }

  get fiscalStartMonthStr(): string {
    return this.rawData.fiscalStartMonthStr;
  }

  get fiscalEndMonthStr(): string {
    return this.rawData.fiscalEndMonthStr;
  }
}
const convertDateToStringYYYYMMDD = (dateStr?: string) => {
  if (!dateStr) {
    return '';
  }
  return moment(dateStr).format('YYYY/MM/DD');
};
