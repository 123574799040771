/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { productColor, textColor, utilityColor } from 'components/styles';
import { isMobileOnly } from 'react-device-detect';

const style = {
  normal: css({
    backgroundColor: productColor.primary,
    color: textColor.inversed,
    height: '48px',
    position: 'sticky',
    display: 'flex',
    alignItems: 'center',
    top: '0',
    zIndex: 9999,
  }),
  mobile: css({
    backgroundColor: utilityColor.white,
    color: textColor.mobileTitle,
    height: 'auto',
    position: 'sticky',
    alignItems: 'center',
    top: '0',
    zIndex: 6,
    boxShadow: '0px 1px 5px rgba(72, 80, 87, 0.2)',
  }),
};

const HeaderBar: React.FC = ({ children }) => (
  <div className="hidePrint"  css={!isMobileOnly ? style.normal : style.mobile} >
    {children}
  </div>
);

export default HeaderBar;
