import { stringify } from 'querystring';
import ApiClient from './ApiClient';

export const getPaymeCoordination = async (companyCode: string, orgCode: string,
  targetDateFrom: string, targetDateTo: string, employmentIds: string): Promise<any> => {
  const params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
    employmentIds,
  };

  const response = await ApiClient.get(`/v1/paymeCoordination/getAchievementForPayme/${companyCode}`, params);
  return response.data;
};
export const getPaymeCoordinationV2 = async (companyCode: string, orgCode: string,
  targetDateFrom: string, targetDateTo: string, employmentIds: string,
  functionType: number,
  categoryAry: Array<Array<string>>,
  loginStaffCode: string): Promise<any> => {
  const params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
    employmentIds,
    functionType,
    loginStaffCode,
  };
  let url = Object.entries(params)
    .map(
      ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
    )
    .join('&');
  if (categoryAry && categoryAry.length) {
    categoryAry.forEach((cateAry) => {
      url += `&categoryAry=${cateAry.join(',')}`;
    });
  }
  const response = await ApiClient.get(`/v2/paymeCoordination/getAchievementForPayme/${companyCode}?${url}`, {});
  return response.data;
};


export const getCsvOutputPaymeCoordination = async (companyCode: string, orgCode: string,
  targetDateFrom: string, targetDateTo: string, employmentIds: string, orgCodeName: string): Promise<any> => {
  const params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
    employmentIds,
  };
  const queryStringParams = stringify(params);
  const strTargetDateFrom = targetDateFrom.replace('-', '').replace('-', '');
  const strTargetDateTo = targetDateTo.replace('-', '').replace('-', '');
  await ApiClient.downloadOriginalCsv(`/v1/paymeCoordination/csvOutput/${companyCode}`, queryStringParams, getDownloadCsvFileName(`Paymer連携データ( ${orgCodeName})_${strTargetDateFrom}-${strTargetDateTo}`));
};
export const getCsvOutputPaymeCoordinationV2 = async (companyCode: string, orgCode: string,
  targetDateFrom: string, targetDateTo: string, employmentIds: string, orgCodeName: string, functionType: number,
  categoryAry: Array<Array<string>>,
  loginStaffCode: string): Promise<any> => {
  const params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
    employmentIds,
    functionType,
    loginStaffCode,
  };
  let url = Object.entries(params)
    .map(
      ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
    )
    .join('&');
  if (categoryAry && categoryAry.length) {
    categoryAry.forEach((cateAry) => {
      url += `&categoryAry=${cateAry.join(',')}`;
    });
  }
  // const queryStringParams = stringify(params);
  const strTargetDateFrom = targetDateFrom.replace('-', '').replace('-', '');
  const strTargetDateTo = targetDateTo.replace('-', '').replace('-', '');
  await ApiClient.downloadOriginalCsv(`/v2/paymeCoordination/csvOutput/${companyCode}?${url}`, undefined, getDownloadCsvFileName(`Paymer連携データ( ${orgCodeName})_${strTargetDateFrom}-${strTargetDateTo}`));
};

const getDownloadCsvFileName = (prefix: string) => `${prefix}.csv`;

export default getPaymeCoordination;
