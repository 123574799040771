/**
 * API Payme連携
 */
export default class PaymeCoordinationDomain {
  constructor(private rawData: Array<any>) {
    // do nothing
  }

  static generateInitial(): PaymeCoordinationDomain {
    return new PaymeCoordinationDomain(['', '', '', '', '']);
  }

  getRawData(): Array<any> {
    return this.rawData;
  }

  get employeeNumber(): string {
    return this.rawData[0];
  }

  set employeeNumber(employeeNumber: string) {
    this.rawData[0] = employeeNumber;
  }

  get workingDay(): string {
    return this.rawData[1];
  }

  set workingDay(workingDay:string) {
    this.rawData[1] = workingDay;
  }

  get attendance(): string {
    return this.rawData[2];
  }

  set attendance(attendance: string) {
    this.rawData[2] = attendance;
  }

  get departureTime(): string {
    return this.rawData[3];
  }

  set departureTime(departureTime:string) {
    this.rawData[3] = departureTime;
  }

  get actualWorkingHours(): string {
    return this.rawData[4];
  }

  set actualWorkingHours(actualWorkingHours:string) {
    this.rawData[4] = actualWorkingHours;
  }
}
