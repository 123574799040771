import React from 'react';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import PrimaryButton from 'components/atoms/Button';
import Dropdown from 'components/molecules/Dropdown';
import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';
import productColor, { grayScale } from 'components/styles';

const ButtonNavigation: React.FC<{
  downloadStaffCsv: () => void;
  downloadStaffSalaryCsv: () => void;
  downloadStaffManagerCsv: () => void;
  menuType: string;
  roleScreen?: any
}> = ({ downloadStaffCsv, downloadStaffSalaryCsv, downloadStaffManagerCsv, menuType, roleScreen }) => (
  <FlexBox>

    {roleScreen && roleScreen.downloadFlag === 1 && menuType !== '1' && (
    <FlexBoxItem>
      <Dropdown
        items={[
          {
            value: '0',
            label: 'スタッフ情報のダウンロード',
            onClick: downloadStaffCsv,
          },
          {
            value: '1',
            label: '人件費情報のダウンロード',
            onClick: downloadStaffSalaryCsv,
          },
          {
            value: '2',
            label: 'マネージャー権限のダウンロード',
            onClick: downloadStaffManagerCsv,
          },
        ]}
      >
        <Icon type="download" color={grayScale.gray100} />
        <BodyText
          color={productColor.primary}
          customStyle={css({
            marginLeft: '4px',
          })}
        >
          CSVダウンロード
        </BodyText>
      </Dropdown>
    </FlexBoxItem>
    )}

    <FlexBoxItem marginLeft="auto">
      { roleScreen && roleScreen.importFlag === 1 && menuType !== '1' && (
        <Link to="/staffs/csv">
          <span style={{ marginRight: '12px' }}>
            <PrimaryButton
              text="インポート"
            />
          </span>
        </Link>
      )}
      { roleScreen && roleScreen.editable === 1 && (
        <Link to={menuType !== '1' ? `/staffs/add/${menuType}` : `/staffs/employee/add/${menuType}`}>
          <PrimaryButton
            text="新規スタッフ登録"
          />
        </Link>
      )}
    </FlexBoxItem>

  </FlexBox>
);

export default ButtonNavigation;
