import { useState, useEffect } from 'react';
import {
  GraphMonthlyResponse,
  GraphYearlyResponse,
  getGraphMonthly,
  getGraphYearly,
} from 'api/dashboard';
import { isMobileOnly } from 'react-device-detect';

export const useDashboardGraph = ({
  targetDate,
  orgCode,
  categoryAry,
}: {
  targetDate: Date;
  orgCode: string;
  categoryAry: string[];
}) => {
  const [graphMonthly, setGraphMonthly] = useState<GraphMonthlyResponse | null>(
    null,
  );
  const [graphYearly, setGraphYearly] = useState<GraphYearlyResponse | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  useEffect(() => {
    setIsLoading(true);

    if (!isMobileOnly) {
      Promise.all([
        getGraphMonthly(orgCode, targetDate, categoryAry),
        getGraphYearly(orgCode, targetDate, categoryAry),
      ]).then((response) => {
        setGraphMonthly(response[0]);
        setGraphYearly(response[1]);

        setIsLoading(false);
      });
    }
  }, [staffCode, orgCode, targetDate, categoryAry]);

  return {
    graphMonthly,
    graphYearly,
    isLoading,
  };
};

export default useDashboardGraph;
