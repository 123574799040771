import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import { isMobile } from 'react-device-detect';
import PeriodSalesForm from 'components/organismos/master/sales/PeriodSalesForm';


const PeriodSalesPage = () => (
  <SidebarTemplate pageTitle={isMobile ? '期間売上' : '期間売上'}>
    <PeriodSalesForm />
  </SidebarTemplate>
);

export default PeriodSalesPage;
