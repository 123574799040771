/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

export interface ButtonProps {
  /**
   * ボタンテキスト
   */
  text: string;
  /**
   * ボタンクリック時に実行する関数
   */
  onClick?: () => void;
  /**
   * ボタンの非活性化
   */
  disabled?: boolean;
  /**
   * ゴーストボタンかどうか
   */
  ghost?: boolean;
  fullwidth?:boolean;
  /**
   * ボタンの最小幅
   */
  minWidth?: string;

  customStyle?: SerializedStyles,
}

interface ButtonColor {
  /**
   * ベースの色
   */
  baseColor: string;
  /**
   * テキストの色
   */
  textColor: string;
  /**
   * ボーダーの色(指定がなければベースの色と同じになる)
   */
  borderColor?: string;
  /**
   * ゴーストボタンの色
   */
  ghostColor: string;
  /**
   * ゴーストボタンのテキストの色
   */
  ghostTextColor: string;
  /**
   * :hover時のボタンの色
   */
  hoverColor: string;
  /**
   * :hover時のボーダーの色(指定がなければ:hover時のボタンの色と同じになる)
   */
  hoverBorderColor?: string;
  /**
   * :focus時のボタンの色
   */
  focusColor: string;
}

interface BaseButtonProps extends ButtonProps {
  color: ButtonColor;
}

const baseButtonStyle = css({
  fontSize: '14px',
  lineHeight: '32px',
  paddingLeft: '12px',
  paddingRight: '12px',
  borderRadius: '5px', // TODO Sketchで指定が見つかっていないためちゃんと確認する
});
const disableStyle = css({
  backgroundColor: '#D6D6D6',
  border: '1px solid #EAEAEA',
  color: '#ADADAD',
});

const fullwidthStyle = css({
  width: '100%',
});

const minWidthStyle = (len: string) => css({
  minWidth: len,
});

const ghostStyle = (color: ButtonColor) => css({
  backgroundColor: 'transparent',
  border: `1px solid ${color.ghostColor}`,
  color: color.ghostTextColor,
});
const additionalStyle = (color: ButtonColor) => {
  const {
    baseColor, textColor, borderColor,
  } = color;
  return css({
    backgroundColor: baseColor,
    border: `1px solid ${borderColor || baseColor}`,
    color: textColor,
  });
};

const hoverStyle = (color: ButtonColor) => css({
  ':hover': {
    backgroundColor: color.hoverColor,
    border: `1px solid ${color.hoverBorderColor || color.hoverColor}`,
    color: color.textColor,
  },
  ':focus': {
    backgroundColor: color.focusColor,
    border: `1px solid ${color.focusColor}`,
    color: color.textColor,
  },
});

/**
 * ボタンのベースとなるコンポーネント
 *
 * @see https://sketch.cloud/s/2b3Pe/a/ljLorx
 */
const BaseButton: React.FC<BaseButtonProps> = ({
  text,
  onClick,
  disabled,
  ghost = false,
  color,
  fullwidth,
  minWidth,
  customStyle,
}) => {
  const buttonCss = React.useMemo(() => {
    let buttonStyle = css(baseButtonStyle, ghost ? ghostStyle(color) : additionalStyle(color));

    if (fullwidth) {
      buttonStyle = css(buttonStyle, hoverStyle(color), fullwidthStyle);
    }

    if (minWidth) {
      buttonStyle = css(buttonStyle, hoverStyle(color), minWidthStyle(minWidth));
    }

    if (disabled) {
      return css(buttonStyle, disableStyle);
    }

    return css(buttonStyle, hoverStyle(color));
  }, [color, disabled, fullwidth, ghost, minWidth]);
  return (
    <button
      css={[buttonCss, customStyle]}
      type="button"
      onClick={() => (onClick ? onClick() : () => {})}
      disabled={disabled}

    >
      {text}
    </button>
  );
};

export default BaseButton;
