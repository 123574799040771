/** @jsx jsx */
import React, { ReactNode } from 'react';
import { css, jsx } from '@emotion/core';
import { EventWrapperProps } from 'react-big-calendar';

import {
  productColor, textColor, textFontSize, utilityColor,
} from 'components/styles';
import { ShiftMonthCalendarEvent } from 'components/pages/master/attend/SchedulePage/hooks';

const createStyle = (color: string = productColor.primaryM95) => css({
  fontSize: textFontSize.re,
  width: '100%',
  '& > *': {
    backgroundColor: color || productColor.primaryM80,
    color: textColor.main,
    textAlign: 'center',
    borderRadius: '4px',
    border: color === utilityColor.white ? '1px solid #0D4796' : 'none',
  },
});

/**
 * イベントラッパー
 * - eventにcolorが指定されている場合に設定する
 */
const WeekEventWrapper: React.FC<EventWrapperProps<ShiftMonthCalendarEvent>> = (props) => {
  const {
    children, event,
  } = props as EventWrapperProps & {
    children: ReactNode;
    event: ShiftMonthCalendarEvent
  };
  return (
    <div css={createStyle(event.color)}>
      {children}
    </div>
  );
};

export default WeekEventWrapper;
