import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import TextareaAutoResize from "components/atoms/input/TextareaAutoResize";

const BuildingManagementForm: React.FC<{
  state: any,
  handleOnChange: any,
}> = ({
  state,
  handleOnChange
}) => {

  const reAlphaNumbericSymbols = (value: string) => {
    const re =  /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\b\n ]*$/;
    return re.test(value);
  }

  return (
    <React.Fragment>
      
      {/* オーナー */}
      <FormTitle title="オーナー" />

      <FormField>
        <TextForm
          name="buildingOwner"
          label="オーナー名"
          value={state.buildingOwner}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <TextForm
          name="buildingOwnerAddress"
          label="オーナー住所"
          value={state.buildingOwnerAddress}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <TextForm
          name="buildingOwnerTel"
          label="オーナー連絡先"
          value={state.buildingOwnerTel}
          onChange={(e) => {
            if (reAlphaNumbericSymbols(e.target.value)){
              handleOnChange(e)
            }
          }}
        />
      </FormField>

      {/* 管理会社 */}
      <FormTitle title="管理会社" />

      <FormField displayBlock={true}>
        <div style={{ fontSize: '14px' }}>ビル管理会社名</div>
        <TextareaAutoResize
          name="buildingManagementCompany"
          value={state.buildingManagementCompany}
          onChange={handleOnChange}
          rows={5}
        />
      </FormField>

      <FormField>
        <TextForm
          name="buildingManagementCompanyZipCode"
          label="ビル管理会社郵便番号"
          value={state.buildingManagementCompanyZipCode}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField displayBlock={true}>
        <div style={{ fontSize: '14px' }}>ビル管理会社住所</div>
        <TextareaAutoResize
          name="buildingManagementCompanyAddress"
          value={state.buildingManagementCompanyAddress}
          onChange={handleOnChange}
          rows={5}
        />
      </FormField>

      <FormField displayBlock={true}>
        <div style={{ fontSize: '14px' }}>ビル管理会社TEL</div>
        <TextareaAutoResize
          name="buildingManagementCompanyTel"
          value={state.buildingManagementCompanyTel}
          onChange={handleOnChange}
          rows={5}
        />
      </FormField>

      <FormField displayBlock={true}>
        <div style={{ fontSize: '14px' }}>ビル管理会社FAX</div>
        <TextareaAutoResize
          name="buildingManagementCompanyFax"
          value={state.buildingManagementCompanyFax}
          onChange={handleOnChange}
          rows={5}
        />
      </FormField>

      <FormField displayBlock={true}>
        <div style={{ fontSize: '14px' }}>ビル管理担当者名</div>
        <TextareaAutoResize
          name="buildingManagementCompanyContact"
          value={state.buildingManagementCompanyContact}
          onChange={handleOnChange}
          rows={5}
        />
      </FormField>

      <FormField displayBlock={true}>
        <div style={{ fontSize: '14px' }}>ビル管理担当者TEL</div>
        <TextareaAutoResize
          name="buildingManagementCompanyContactTel"
          value={state.buildingManagementCompanyContactTel}
          onChange={handleOnChange}
          rows={5}
        />
      </FormField>

    </React.Fragment>
  );
};

export default BuildingManagementForm;
