import _ from 'lodash';
import moment from 'moment';
import { Values } from 'utility/constants';
import { formatNumber } from 'utility/formatUtil';

interface ITotalSalesList {
  date: Date,
  value: number,
  dayOfWeek: string,
}
export interface MonthlyDailyReport {
  orgCode: string;
  orgName: string;
  totalSales: number;
  netSales: number;
  totalBudget: number;
  progressRate: string;
  progressRateNetSales: string;
  totalSalesList: Array<ITotalSalesList>;
  netSalesList: Array<ITotalSalesList>;
  dayOfWeek: string;
}

export default class MonthlyDailyReportDomain {
  constructor(private rawData: MonthlyDailyReport) {
    // do nothing
  }

  static generateInitial(): MonthlyDailyReportDomain {
    return new MonthlyDailyReportDomain({
      orgCode: '',
      orgName: '',
      totalSales: 0,
      netSales: 0,
      totalBudget: 0,
      progressRate: "0.00%",
      progressRateNetSales: "0.00%",
      totalSalesList: [],
      netSalesList: [],
      dayOfWeek: '',
    });
  }

  getRawData(): MonthlyDailyReport {
    return this.rawData;
  }

  /** 出力データ（CSV出力など） */
  getOutputData(taxExclude: string): Array<any> {
    return [
      this.orgCode || '',
      this.orgName || '',
      this.getSales(taxExclude),
      this.totalBudget,
      this.getProgressRate(taxExclude),
      ...this.getSalesList(taxExclude).flatMap(s => formatNumber(s.value))
    ];
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  get totalSales(): number {
    return this.rawData.totalSales;
  }

  get netSales(): number {
    return this.rawData.netSales;
  }

  get totalBudget(): string {
    return formatNumber(this.rawData.totalBudget);
  }

  get progressRate(): string {
    return this.rawData.progressRate;
  }

  get progressRateNetSales(): string {
    return this.rawData.progressRateNetSales;
  }

  get totalSalesList(): Array<ITotalSalesList> {
    return this.rawData.totalSalesList;
  }

  get netSalesList(): Array<ITotalSalesList> {
    return this.rawData.netSalesList;
  }

  get dayOfWeek(): string {
    return this.rawData.dayOfWeek;
  }

  getDayOfWeekList(): Array<string> {
    const ArrayDate = this.rawData.totalSalesList.map((item) => item.dayOfWeek);
    return ArrayDate;
  }

  getDateList(): Array<string> {
    const ArrayDate = this.rawData.totalSalesList.map((item) => moment(item.date).format('MM/DD'));
    return ArrayDate;
  }

  getValueList(): Array<string> {
    const strValue = this.rawData.totalSalesList.map((item) => formatNumber(item.value));
    return strValue;
  }

  getNetValueList(): Array<string> {
    return this.rawData.netSalesList.map((item) => formatNumber(item.value));
  }

  getSales(taxExclude: string): string {
    const sales = taxExclude === Values.TaxExclude.Excluded.value ? this.netSales : this.totalSales;
    return formatNumber(sales) || '0';
  }

  getProgressRate(taxExclude: string): string {
    const rate = taxExclude === Values.TaxExclude.Excluded.value ? this.progressRateNetSales : this.progressRate;
    return rate || '0.00%';
  }

  getSalesList(taxExclude: string): Array<ITotalSalesList> {
    const list = taxExclude === Values.TaxExclude.Excluded.value ? this.netSalesList : this.totalSalesList;
    return list || [];
  }

}
