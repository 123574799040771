
import React from 'react';
import SalesManagementList from 'components/organismos/master/general/salesPage/SalesManagementList/SalesManagementList';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const SalesMangement = () => (
  <SidebarTemplate pageTitle="営業管理帳票">
    <SalesManagementList />
  </SidebarTemplate>
);

export default SalesMangement;
