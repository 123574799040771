
/**
 * API 祝日マスタ
 */

export default class TaxRateCaptureSettingDomain {
  constructor(private rawData: Array<any>) {
    // do nothing
  }

  static generateInitial(): TaxRateCaptureSettingDomain {
    return new TaxRateCaptureSettingDomain([sessionStorage.getItem('loginUser.companyCode'), sessionStorage.getItem('selectedOrgCode'), '', '', '', '', '', '', '', '', '', '', '']);
  }

  getRawData(): Array<any> {
    return this.rawData;
  }

  get companyCode(): string {
    return this.rawData[0];
  }

  set companyCode(companyCode: string) {
    this.rawData[0] = companyCode;
  }

  get orgCode(): string {
    return this.rawData[1];
  }

  set orgCode(orgCode:string) {
    this.rawData[1] = orgCode;
  }

  get posName(): string {
    return this.rawData[2];
  }

  set posName(posName: string) {
    this.rawData[2] = posName;
  }

  //
  get taxType1Str(): string {
    return this.rawData[3];
  }

  set taxType1Str(taxType1:string) {
    this.rawData[3] = taxType1;
  }

  get taxType2Str(): string {
    return this.rawData[4];
  }

  set taxType2Str(taxType2:string) {
    this.rawData[4] = taxType2;
  }

  get taxType3Str(): string {
    return this.rawData[5];
  }

  set taxType3Str(taxType3:string) {
    this.rawData[5] = taxType3;
  }

  get taxType4Str(): string {
    return this.rawData[6];
  }

  set taxType4Str(taxType4:string) {
    this.rawData[6] = taxType4;
  }

  get taxType5Str(): string {
    return this.rawData[7];
  }

  set taxType5Str(taxType5:string) {
    this.rawData[7] = taxType5;
  }

  //
  get taxType1(): Number {
    return this.rawData[9];
  }

  set taxType1(taxType1:Number) {
    this.rawData[9] = taxType1;
  }

  get taxType2(): Number {
    return this.rawData[10];
  }

  set taxType2(taxType2:Number) {
    this.rawData[10] = taxType2;
  }

  get taxType3(): Number {
    return this.rawData[11];
  }

  set taxType3(taxType3:Number) {
    this.rawData[11] = taxType3;
  }

  get taxType4(): Number {
    return this.rawData[12];
  }

  set taxType4(taxType4:Number) {
    this.rawData[12] = taxType4;
  }

  get taxType5(): Number {
    return this.rawData[13];
  }

  set taxType5(taxType5:Number) {
    this.rawData[13] = taxType5;
  }


  //
}
