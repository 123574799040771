import DailySalesManageLayout from 'components/organismos/master/storeManagement/report/DailySalesManagement';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';

const DailySalesManagementPage = () => (
  <SidebarTemplate pageTitle="売上管理日報">
    <DailySalesManageLayout />
  </SidebarTemplate>
);
export default DailySalesManagementPage;
