import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { getSelManager } from 'api/organization';
import ManagerDomain from 'domain/manager';

export const useStaffList = (
  addStaff: (arg: Array<any>) => void,
  openHandler: (arg: boolean) => void,
) => {
  const { orgCode } = useParams<{orgCode: string}>();
  const [staffList, setStaffList] = useState<Array<any>>([]);
  const [checkedStaffList, setCheckedStaffList] = useState<Array<any>>([]);
  const [checkedStaffCodeList, setCheckedStaffCodeList] = useState<Array<string>>([]);
  const [orgMgrCode, setOrgMgrCode] = useState(orgCode);

  useEffect(() => {
    const fetchStaffList = async () => {
      if(!orgMgrCode) return;
      const fetchedStaffList = await getSelManager(orgMgrCode);
      setStaffList(fetchedStaffList.map((fetchStaff) => fetchStaff));
    };
    fetchStaffList();
  }, [orgMgrCode, setStaffList]);

  /**
   * チェックボックスのハンドラー
   */
  const checkHandler = useCallback((staffCode: string) => {
    const isChecked = checkedStaffCodeList.includes(staffCode);
    console.log(isChecked);
    if (isChecked) {
      // チェック済みならリストからはずす
      setCheckedStaffCodeList(checkedStaffCodeList.filter(
        (checkedStaffCode) => checkedStaffCode !== staffCode,
      ));
      setCheckedStaffList(checkedStaffList.filter(
        (staff) => staff.staffCode !== staffCode,
      ));
      return;
    }
    const staffs = staffList.filter((staff) => staff.staffCode === staffCode);
    if (staffs) {
      setCheckedStaffList(checkedStaffList.concat(staffs));
    }

    setCheckedStaffCodeList(checkedStaffCodeList.concat(staffCode));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffList, checkedStaffCodeList]);

  /**
   * 追加実行処理
   */
  const onSubmit = useCallback(() => {
    if (!staffList) {
      return;
    }

    addStaff(checkedStaffList.filter((staff) => checkedStaffCodeList.includes(staff.staffCode)));

    // チェック済みリストからはずす
    setCheckedStaffCodeList([]);
    setCheckedStaffList([]);

    // モーダルを閉じる
    openHandler(false);
  }, [addStaff, checkedStaffCodeList, checkedStaffList, openHandler, staffList]);

  return {
    staffList,
    checkedStaffCodeList,
    setCheckedStaffList,
    setCheckedStaffCodeList,
    checkHandler,
    onSubmit,
    orgMgrCode,
    setOrgMgrCode,
  };
};

export default {};
