import { useEffect, useState, useCallback } from 'react';
import { getStampHistory } from 'api/shogunStampHistory';
import moment from 'moment';
import { getErrorMessageForResponse } from 'utility/errorUtil';
import { useParams } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';

const useStampHistoryForm = () => {
  const { errorNotification } = useToastNotification();

  const params = useParams<any>();
  const [stampHistory, setStampHistory] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const userOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';

  const [selectOrgCode, setSelectOrgCode] = useState(params.orgCode ? params.orgCode : userOrgCode);

  const currentDate = new Date();
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);
  const [searchTime, setSearchTime] = useState(new Date());
  const [targetDate, setTargetDate] = useState<any>(params.targetDate ? new Date(params.targetDate) : currentDate);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const apiParams = {
      orgCode: selectOrgCode,
      targetDate: moment(targetDate).format('YYYY-MM-DD'),
    };
    try {
      const response = await getStampHistory(apiParams);
      setStampHistory(response);
      setSearchTime(response.search);
    } catch (error) {
      errorNotification(getErrorMessageForResponse(error));
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgCode, targetDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData, selectOrgCode, targetDate]);

  return {
    categoryAry,
    setCategoryAry,
    stampHistory,
    isLoading,
    setIsLoading,
    fetchData,
    selectOrgCode,
    setSelectOrgCode,
    targetDate,
    setTargetDate,
    searchTime,
    setSearchTime,
  };
};

export default useStampHistoryForm;
