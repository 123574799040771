import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import useToastNotification from 'hooks/useToastNotification';
import { getJournalMasterDetail, saveJournalMasterDetail } from 'api/journalMaster';
import JournalMasterItemDomain from 'domain/master/general/journalMasterItem';
import { useHistory } from 'react-router-dom';


export const useJournalMasterDomainForm = (journalId?: string) => {
  const [detailMode, setDetailMode] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [getDebitTaxType, setDebitTaxType] = useState(0);
  const [getCreditTaxType, setCreditTaxType] = useState(0);
  const [loadDebitAccountTitleId, setLoadDebitAccountTitleId] = useState('');
  const [loadCreditAccountTitleId, setCreditAccountTitleId] = useState('');
  const [tmpDebitAccountTitleSubId, setTmpDebitAccountTitleSubId] = useState('');
  const [tmpCreditAccountTitleSubId, setTmpCreditAccountTitleSubId] = useState('');
  const history = useHistory();

  // modal confirm
  const [toastModalOpen, setToastModalOpen] = useState(false);

  // Modal alert
  const { successNotification, errorNotification } = useToastNotification();
  const onSubmit = async (values: JournalMasterItemDomain) => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
    } else {
      try {
        const response = await saveJournalMasterDetail(
          String(journalId), values.getRawData(),
        );
        if (!response.errors) {
          setConfirmModalOpen(false);
          let successMes = '登録しました。';
          if (journalId && journalId !== 'undefined') {
            successMes = '更新しました。';
          }
          successNotification(successMes);
          history.push('/accountTitleMst');
        }
      } catch (error) {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.length) {
          const listErr = error.response.data;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage} \n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    }
    return false;
  };

  const formik = useFormik({
    initialValues: JournalMasterItemDomain.generateInitial(),
    validationSchema: false,
    onSubmit,
  });

  useEffect(() => {
    if (journalId) {
        getJournalMasterDetail(journalId).then((response: any) => {
        if (response) {
          formik.setValues(new JournalMasterItemDomain(response));
          setDebitTaxType(Number(response.debitTaxType));
          setCreditTaxType(Number(response.creditTaxType));
          setLoadDebitAccountTitleId(response.debitAccountTitleId);
          setCreditAccountTitleId(response.creditAccountTitleId);
          setTmpDebitAccountTitleSubId(response.debitAccountTitleSubId);
          setTmpCreditAccountTitleSubId(response.creditAccountTitleSubId);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalId]);


  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    setToastModalOpen,
    getDebitTaxType, 
    setDebitTaxType,
    getCreditTaxType, 
    setCreditTaxType,
    loadDebitAccountTitleId, 
    setLoadDebitAccountTitleId,
    loadCreditAccountTitleId, 
    setCreditAccountTitleId,
    tmpDebitAccountTitleSubId, 
    setTmpDebitAccountTitleSubId,
    tmpCreditAccountTitleSubId, 
    setTmpCreditAccountTitleSubId
  };
};

export default {
  useJournalMasterDomainForm,
};
