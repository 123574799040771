/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */

/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';

import SalesTable from './salesTable';
import { useSalesAdvertsingPlanReportContext } from './hook';

const SalesTableLayout = () => {
  const { stores, targetDateFrom } = useSalesAdvertsingPlanReportContext();

  return (
    <React.Fragment>
      {stores && stores.map((store: any, index: number) => (
        <React.Fragment key={index}>
          <FlexBox customStyle={css({ marginTop: '30px', fontWeight: 700, fontSize: '16px' })}>
            <FlexBoxItem customStyle={css({ marginRight: '16px' })}>{`${targetDateFrom.getFullYear()}年${targetDateFrom.getMonth() + 1}月`}</FlexBoxItem>
            <FlexBoxItem>{store.storeName}</FlexBoxItem>
          </FlexBox>
          <div css={css({ width: '100%' })}>
            {store.storeRef.map((item: any, index: number) => (
              <SalesTable key={`table-${index}`} caption={item.storeRefNameRef} stores={item.storeRefData} />
            ))}
          </div>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default SalesTableLayout;
