import ApiClient from './ApiClient';
import moment from 'moment';
import { AxiosResponse } from 'axios';

export interface PosType {
  posNo: number;
  posName: string;
  dispOrder: number;
}

export const getListAdvertisingMediaOrganization = async (orgCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = { orgCode };

  const response = await ApiClient.get(`/v1/sales/master/advertisingMediaOrganization/${companyCode}`, params);
  return response.data;
};

export const deleteAdvertisingMediaOrganization = async (isConfirmed: number,
  advertisingMediaOrganization: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isConfirmed,
  };

  const response = await ApiClient.post(`/v1/sales/master/advertisingMediaOrganization/delete/${companyCode}`, params, advertisingMediaOrganization);
  return response.data;
};

export const createOrUpdateAdvertisingMediaOrganization = async (isCreate: boolean,
  advertisingMediaOrganization: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isCreate: isCreate ? 1 : 0,
  };
  const response = await ApiClient.post(`/v1/sales/master/advertisingMediaOrganization/${companyCode}`, params, advertisingMediaOrganization);
  return response.data;
};

export const getAdvertisingMediaOrganizationList = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/advertisingMediaOrganizationMstToSelect/${companyCode}`, {});
  return response.data;
};

export const getPostTypeList = async (): Promise<Array<PosType>> => {
  const response = await ApiClient.get('/v1/posType', {});
  return response.data;
};

export const downloadCSV = async (
    fileName: string,
    companyCode: string,
    orgCode: string
): Promise<void> => {
  const params = {
    orgCode,
  };
  await ApiClient.downloadCsv(`/v1/sales/master/advertisingMediaOrganization/csv/${companyCode}`, params, getDownloadCsvFileName(fileName));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

/**
 * CSVファイルインポート
 */
export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    formatType: 0,
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v1/sales/master/advertisingMediaOrganization/csv/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export type Error = {
  error: string;
  defaultMessage: string;
}

export default getListAdvertisingMediaOrganization;
