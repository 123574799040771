import React, { SVGProps } from 'react';

const Telephone: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M16.71 7.497a33.183 33.183 0 01-4.147 5.066 33.183 33.183 0 01-5.066 4.147 2.625 2.625 0 01-3.313-.328l-.636-.637a3.125 3.125 0 010-4.42l.884-.883a1.875 1.875 0 012.651 0c.369.368.952.41 1.368.096.344-.26.713-.586 1.107-.98s.72-.763.98-1.107c.313-.416.272-1-.096-1.368a1.875 1.875 0 010-2.651l.884-.884a3.125 3.125 0 014.42 0l.636.636a2.625 2.625 0 01.328 3.313zm-1.04-.694a1.375 1.375 0 00-.172-1.735l-.637-.636a1.875 1.875 0 00-2.651 0l-.884.883a.625.625 0 000 .884c.809.81.898 2.09.21 3.004-.296.393-.66.806-1.094 1.239-.433.433-.846.798-1.239 1.094a2.295 2.295 0 01-3.004-.21.625.625 0 00-.884 0l-.883.884a1.875 1.875 0 000 2.651l.636.637c.464.464 1.19.535 1.735.172a31.934 31.934 0 004.876-3.99 31.934 31.934 0 003.99-4.877z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Telephone;
