import { useState, useCallback } from 'react';

import ManagerDomain from 'domain/manager';
import { getHistory, postManagers } from 'api/organization';
import { useParams, useHistory } from 'react-router-dom';
import OrganizationDomain from 'domain/organization';
import useToastNotification from 'hooks/useToastNotification';

export const useManagerListHandler = (
  managerList: Array<any>|null,
  setManagerList: (arg: Array<any>) => void,
  seniorManagerList: Array<any>|null,
  setSeniorManagerList: (arg: Array<any>) => void,
  isEditMode: boolean = false,
) => {
  const history = useHistory();
  const { orgCode } = useParams<{orgCode: string}>();
  // 登録内容確認モーダルの開閉状態
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  // 削除処理
  const deleteManager = useCallback((index: number) => () => {
    if (!managerList) {
      return;
    }
    setManagerList(managerList.filter((manager, idx) => idx !== index));
  }, [managerList, setManagerList]);
  const deleteSeniorManager = useCallback((index: number) => () => {
    if (!seniorManagerList) {
      return;
    }
    setSeniorManagerList(
      seniorManagerList.filter((seniorManager, idx) => idx !== index),
    );
  }, [seniorManagerList, setSeniorManagerList]);

  // 更新処理
  const setManager = useCallback((index: number) => (newManager: any) => {
    if (!managerList) {
      managerList = [];
    }
    console.log(index, newManager);
    setManagerList(managerList.map((manager, idx) => (idx === index ? newManager : manager)));
  }, [managerList, setManagerList]);
  const setSeniorManager = useCallback((index: number) => (newManager: any) => {
    if (!seniorManagerList) {
      seniorManagerList = [];
    }
    setSeniorManagerList(
      seniorManagerList.map((manager, idx) => (idx === index ? newManager : manager)),
    );
  }, [seniorManagerList, setSeniorManagerList]);

  // スタッフを追加
  const addManager = useCallback((staffList: Array<any>) => {
    if (!managerList) {
      managerList = [];
    }
    setManagerList(managerList.concat(staffList));
  }, [managerList, setManagerList]);
  const addSeniorManager = useCallback((staffList: Array<any>) => {
    if (!seniorManagerList) {
      seniorManagerList = [];
    }
    setSeniorManagerList(seniorManagerList.concat(staffList));
  }, [seniorManagerList, setSeniorManagerList]);

  // 保存
  const onSubmit = useCallback(() => {
    if (!confirmModalOpen) {
      // 登録内容確認モーダルが表示されていなければ表示する
      setConfirmModalOpen(true);
      return;
    }

    getHistory(orgCode).then((response : any) => {
      const organization = new OrganizationDomain(response[0]);
      if (!organization || !managerList || !seniorManagerList) {
        errorNotification('対象の組織情報が見つかりませんでした。');
        return;
      }

      const postData = organization.getManagersPostData(
        managerList.map((manager) => manager.rawDate),
        seniorManagerList.map((seniorManager) => seniorManager.rawDate),
      );

      postManagers(postData).then((responseMng : any) => {
        const orgMgr = new OrganizationDomain(responseMng[0]);
        setConfirmModalOpen(false);
        successNotification('更新しました。');
        // 新規登録の場合は特賃日登録ページへ遷移
        if (!isEditMode && response[0]) {
          history.push(`/organizations/${orgMgr.orgCode}/specialsalary`);
        }
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage} \n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    }).catch((error) => {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [confirmModalOpen, errorNotification, history, isEditMode,
    managerList, orgCode, seniorManagerList, successNotification]);

  // 登録内容確認モーダル用データ
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    deleteManager,
    deleteSeniorManager,
    setManager,
    setSeniorManager,
    addManager,
    addSeniorManager,
    onSubmit,
    confirmModalOpen,
    closeConfirmModal,
  };
};

export const useStaffAddModal = () => {
  const [openManagerAddModal, setOpenManagerAddModal] = useState<boolean>(false);
  const [openSeniorManagerAddModal, setOpenSeniorManagerAddModal] = useState<boolean>(false);
  return {
    openManagerAddModal,
    openSeniorManagerAddModal,
    setOpenManagerAddModal,
    setOpenSeniorManagerAddModal,
  };
};

export default {};
