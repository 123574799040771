import React, { SVGProps } from 'react';

const Dropdown: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path d="M9.646 12.646L4.854 7.854A.5.5 0 015.207 7h9.586a.5.5 0 01.353.854l-4.792 4.792a.5.5 0 01-.708 0z" fill="#333" />
    </g>
  </svg>
);

export default Dropdown;
