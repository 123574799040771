import React, { useEffect, useState } from 'react';

import { utilityColor } from 'components/styles';
import FormContents from 'components/atoms/Form/FormContents';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import RadioSelector from 'components/molecules/RadioSelector';
import FileSelectButton from 'components/molecules/FileSelectButton';
import BodyText from 'components/atoms/BodyText';

import { useHistory } from 'react-router-dom';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import BlockUI from 'components/molecules/BlockUi';
import { useStaffCsvForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

export type UploadType = '0' | '1' | '2';

const StaffCsvUploadForm: React.FC = () => {
  const {
    confirmModalOpen, closeConfirmModal, uploadType, setUploadType,
    uploadTypeItems, uploadFile, setUploadFile, onSubmit, errors, blocking,
  } = useStaffCsvForm();
  const history = useHistory();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  return (
    <BlockUI blocking={blocking}>
      <FormContents>
        <FormTitle
          title="登録するデータ"
        />

        <p>
          スタッフ情報、人件費情報、マネージャー権限のうちどれかを選択して、正しい形式のファイルをアップロードしてください。
        </p>

        <FormField>
          <RadioSelector
            name="uploadType"
            items={uploadTypeItems}
            value={uploadType}
            setValue={(e) => setUploadType(e.target.value as UploadType)}
          />
        </FormField>

        <BodyText color={utilityColor.error} size="md">※CSVファイルの１行目（ヘッダー行）はインポートされません。</BodyText>
        <FormField>
          <FileSelectButton
            text="CSVファイルをドラッグ＆ドロップ または クリックしてファイルを選択してください"
            ghost={true}
            setFile={setUploadFile}
            accept=".csv"
          />
        </FormField>

        {uploadFile || errors
          ? (
            <FormField
              displayBlock={true}
            >
              <div>
                {uploadFile?.name}
              </div>
              {errors && errors.map((error) => (
                <div key={error}>
                  <BodyText color={utilityColor.error}>
                    {error}
                  </BodyText>
                </div>
              ))}
            </FormField>
          )
          : null}

        <FormSubmitArea>
          {
            roleScreen && roleScreen.importFlag === 1 && (
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton
                  text="インポート"
                  onClick={onSubmit}
                  disabled={!uploadFile}
                />
              </div>
            )
          }

          <PrimaryButton
            text="戻る"
            onClick={() => {
              history.goBack();
            }}
            ghost={true}
          />

        </FormSubmitArea>

        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={onSubmit}
          actionType={ActionType.IMPORT}
        />

      </FormContents>

    </BlockUI>

  );
};

export default StaffCsvUploadForm;
