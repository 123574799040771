import React, { useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import DatePicker from 'components/molecules/DatePicker';
import useMonthlySubmissionConfirmation from './hooks';
import FormLabel from 'components/atoms/Form/FormLabel';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import MonthlySubmissionConfirmationTable from './MonthlySubmissionConfirmationTable';
import BlockUI from 'components/molecules/BlockUi';
import moment from 'moment';

const functionType = 3;
const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
const MonthlySubmissionConfirmationLayout = () => {
    const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') ? sessionStorage.getItem('loginUser.orgCode') : '');
    const [orgName, setorgName] = useState(sessionStorage.getItem('loginUser.orgName') ? sessionStorage.getItem('loginUser.orgName') : '');
    const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
        undefined,
    );
    // sessionStorage.getItem('loginUser.orgName');
    const { selectedMonth, setSelectedMonth, monthlyReportSubmissionConfirmationData, getIsLoading } = useMonthlySubmissionConfirmation(String(orgCode), categoryAry);
    
    return (
        <div className="printA4">
            <FormContents>
                <div className="headerContentPrint">
                    <FlexBox>
                        <FlexBoxItem >
                            <FormLabel
                                label="月次書類提出確認表"
                            />
                        </FlexBoxItem>
                    </FlexBox>
                    <FlexBox>
                        <FlexBoxItem width="50px">
                            <FormLabel
                                label="組織:"
                            />
                        </FlexBoxItem>
                        <FlexBoxItem marginRight="5px">
                            <FormLabel
                                label={`${orgCode}`}
                            />
                        </FlexBoxItem>
                        <FlexBoxItem>
                            <FormLabel
                                label={`${orgName}`}
                            />
                        </FlexBoxItem>
                    </FlexBox>
                    <FlexBox>
                        <FlexBoxItem width="50px">
                            <FormLabel
                                label="日付:"
                            />
                        </FlexBoxItem>
                        <FlexBoxItem>
                            <FormLabel
                                label={`${moment(selectedMonth, 'YYYY-MM').format('YYYY年MM月')}`}
                            />
                        </FlexBoxItem>
                    </FlexBox>
                </div>
                <div className="groupToolSearch"> 
                    <FlexBox>
                        <DatePicker
                            dateFormat="yyyy年MM月"
                            date={selectedMonth}
                            changeDate={(date: Date) => {
                                if (date !== null) {
                                    sessionStorage.setItem('dateSelectMonthlySubmissionConfirmation', `${date}`);
                                    setSelectedMonth(date);

                                }
                            }}
                            isFullWidth={false}
                            showMonthYearPicker={true}
                        />
                    </FlexBox>
                    <div style={{ marginLeft: '8px',alignItems:'center' }}>
                        <OrganizationFilteredTree
                            orgCallback={(args: string) => {
                                console.log(args);
                                if (typeof (args) === 'string') {
                                    setOrgCode(args);
                                } else {
                                    setOrgCode(args[0]);
                                }
                            }}
                            functionType={functionType}
                            staffCode={staffCode}
                            addAllItem={false}
                            orgLabel="組織"
                            orgCategoryCallback={
                                (args: Array<string>) => {
                                    setCategoryAr(args);
                                }
                            }
                            initOrgValue={String(orgCode)}
                            orgNameCallback={(arg: any) => {
                                setorgName(String(arg));
                                console.log(arg);

                            }}
                            isAlignItemsCenter={true}
                        />
                    </div>
                </div>
                <div>
                    <BlockUI blocking={getIsLoading}>
                        <MonthlySubmissionConfirmationTable
                            selectedMonth={selectedMonth}
                            monthlyReportSubmissionConfirmationData={monthlyReportSubmissionConfirmationData}
                        />
                    </BlockUI>
                </div>
            </FormContents>
        </div>
    );
}

export default MonthlySubmissionConfirmationLayout