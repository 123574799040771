/** @jsx jsx */
import React from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import {
  jsx, Global, css,
} from '@emotion/core';
import Loader from 'react-loaders';

import 'loaders.css/loaders.min.css';
import { grayScale } from 'components/styles';

interface BlockUiProps {
  tag: string;
  blocking: boolean;
}

const customizeLoading = css(`
  .customizeLoading {
    left: calc(50% - 7px)
  }
  .customizeLoading>div {
    background-color: ${grayScale.gray30}
  }
}
`);

const BlockUIForLoading: React.FC<BlockUiProps> = ({
  children, tag, blocking,
}) => (
  <React.Fragment>
    <Global
      styles={customizeLoading}
    />
    <BlockUi
      tag={tag}
      blocking={blocking}
      loader={<Loader active={true} type="ball-spin-fade-loader" innerClassName="customizeLoading" />}
    >
      {children}
    </BlockUi>
  </React.Fragment>
);

export default BlockUIForLoading;
