export interface CommentList {
    id: {
        companyCode: string,
        orgCode: string,
        targetMonth: Date,
    },
    orgName: string,
    stateString: string,
    budgetProgressComment: string,
    storeOperationComment: string,
    otherComment: string,
    updateDate: Date,
    updateUser: string,
  }

export default class CommentListDomain {
  constructor(private rawData: CommentList) {
    // do nothing
  }

  static generateInitial(): CommentListDomain {
    return new CommentListDomain({
      id: {
        companyCode: '',
        orgCode: '',
        targetMonth: new Date(),
      },
      orgName: '',
      stateString: '',
      budgetProgressComment: '',
      storeOperationComment: '',
      otherComment: '',
      updateDate: new Date(),
      updateUser: '',
    });
  }

  getRawData(): CommentList {
    return this.rawData;
  }

  get companyCode(): string {
    return this.rawData.id.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.id.companyCode = companyCode;
  }

  get orgCode(): string {
    return this.rawData.id.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.id.orgCode = orgCode;
  }

  get targetMonth(): Date {
    return this.rawData.id.targetMonth;
  }

  set targetMonth(targetMonth: Date) {
    this.rawData.id.targetMonth = targetMonth;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get stateString(): string {
    return this.rawData.stateString;
  }

  set stateString(stateString: string) {
    this.rawData.stateString = stateString;
  }

  get budgetProgressComment(): string {
    return this.rawData.budgetProgressComment;
  }

  set budgetProgressComment(budgetProgressComment: string) {
    this.rawData.budgetProgressComment = budgetProgressComment;
  }

  get storeOperationComment(): string {
    return this.rawData.storeOperationComment;
  }

  set storeOperationComment(storeOperationComment: string) {
    this.rawData.storeOperationComment = storeOperationComment;
  }

  get otherComment(): string {
    return this.rawData.otherComment;
  }

  set otherComment(otherComment: string) {
    this.rawData.otherComment = otherComment;
  }

  get updateDate(): Date {
    return this.rawData.updateDate;
  }

  set updateDate(updateDate: Date) {
    this.rawData.updateDate = updateDate;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }
}
