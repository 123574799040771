import React from 'react';
import HeadquartersFinalForm from 'components/organismos/master/attend/HeadquartersFinalForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import moment from 'moment';
import { useGetSearchDateClosing } from './hooks';

const HeadquartersFinalPage = () => {
  const {
    targetMonthProp,
    targetDateFromProp,
    targetDateToProp,
  } = useGetSearchDateClosing();

  return (
    <SidebarTemplate pageTitle="勤怠確定">
      {
        (moment(targetMonthProp).format('YYYY-MM-DD') !== '9999-12-31'
        && moment(targetDateFromProp).format('YYYY-MM-DD') !== '9999-12-31'
        && moment(targetDateToProp).format('YYYY-MM-DD') !== '9999-12-31') && (
          <HeadquartersFinalForm
            targetMonthProp={moment(targetMonthProp).subtract(1, 'months').toDate()}
            targetDateFromProp={moment(targetDateFromProp).subtract(1, 'months').toDate()}
            targetDateToProp={moment(targetDateToProp).subtract(1, 'months').toDate()}
          />
        )
      }
    </SidebarTemplate>
  );
};

export default HeadquartersFinalPage;
