/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { textFontSize, textColor } from 'components/styles';

const style = css({
  padding: '12px 16px',
  margin: '0',
  fontWeight: 'normal',
  textAlign: 'left',
  fontSize: textFontSize.re,
  color: textColor.main,
});

const SortCell : React.FC<{
  customStyle?: SerializedStyles;
}> = ({ children, customStyle }) => (
  <td css={css(style, customStyle)}>
    {children}
  </td>
);

export default SortCell;
