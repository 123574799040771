import React from 'react';
import { css } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BodyText from 'components/atoms/BodyText';
import Select from 'components/atoms/Select';
import useExecutiveOptions from 'hooks/useExecutiveOptions';

const ExecutiveFilter: React.FC<{
  executiveId: string;
  setExecutiveId: (orgCode: string) => void;
}> = ({ executiveId, setExecutiveId }) => {
  const executiveOptions = useExecutiveOptions();

  return (
    <FlexBox
      customStyle={css({
        padding: '8px',
      })}
    >
      <FlexBoxItem basis="100px">
        <BodyText> 役職 </BodyText>
      </FlexBoxItem>
      <FlexBoxItem basis="400px">
        <Select
          name="orgState"
          value={executiveId}
          setValue={(arg: string) => { setExecutiveId(arg); }}
          options={executiveOptions}
        />
      </FlexBoxItem>
    </FlexBox>
  );
};

export default ExecutiveFilter;
