import React, { SVGProps } from 'react';

const Search: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M8.5 4.5a4.5 4.5 0 013.593 7.21l2.849 2.848a.625.625 0 11-.884.884l-2.848-2.85A4.5 4.5 0 118.5 4.5zm0 1.25a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Search;
