
import { useState, useEffect } from 'react';
import AdvertisingMediaOrganizationDomain from 'domain/master/sales/advertisingMediaOrganization';
import getListAdvertisingMediaOrganization from 'api/advertisingMediaOrganization';

export const useAdvertisingMediaOrganizationList = (orgCode: string) => {
  // eslint-disable-next-line max-len
  const [advertisingMediaOrganizationList, setAdvertisingMediaOrganizationList] = useState < Array<AdvertisingMediaOrganizationDomain>>([]);
  useEffect(() => {
    getListAdvertisingMediaOrganization(orgCode).then((response: Array<any>) => {
      setAdvertisingMediaOrganizationList(response.map(
        (result) => new AdvertisingMediaOrganizationDomain(result),
      ));
    });
  }, [orgCode, setAdvertisingMediaOrganizationList]);
  return { advertisingMediaOrganizationList, setAdvertisingMediaOrganizationList };
};


export default useAdvertisingMediaOrganizationList;
