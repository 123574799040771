/* eslint-disable max-len */
import moment from 'moment';
import {
  useState, useCallback, useEffect, useMemo, Dispatch
} from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';
import StaffDomain,{Staff} from 'domain/staff';
import { postPermission, getRolesMasterList, getStaffCodeV2 } from 'api/staff';
import { getOrganizationTrees } from 'api/organization';
import { OptionType } from 'components/atoms/Select';
import { useOrganizationCategoryOptions } from 'hooks/useOrganizationCategoryOptions';
import { TRoleMaster,TPermissions } from './type'
import { MaxDate } from 'utility/dateUtil';
import { SessionStorageKey } from 'utility/constants';
export const usePermissionForm = (
  initialStaffDomain: StaffDomain = StaffDomain.generateInitial(),
  isEditMode: boolean = false,
  setIsLoading: Dispatch<React.SetStateAction<boolean>>,
  menuType: string,
  isEditingLoginUser: boolean,
) => {
  const [staffCodeList,setStaffCodeList] = useState<any>([])
  //const [loading,setLoading] = useState<boolean>(false)
  const history = useHistory();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [detailMode, setDetailMode] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const organizationCategoryOptions = useOrganizationCategoryOptions(1, false, staffCode);
  const [roleMasterList, setRoleMasterList] = useState<TRoleMaster[]>([])
  const [orgAccessRegistList, setOrgAccessRegistList] = useState<TPermissions[]>([])
  const mappedOrgAccessList = useMemo(()=>{
    return initialStaffDomain.orgAccessList !== null ? initialStaffDomain.orgAccessList.map(((item:any)=>{
      return {
        orgCode: item.orgCode,
        roleCode:item.roleCode
      }
    })) : []
  },[staffCodeList,roleMasterList,organizationCategoryOptions])
  const fetchStaffCodeV2 = useCallback(async ()=>{
    try {
      //setLoading(true);
      const response = await getStaffCodeV2();
      //setLoading(false);
      setStaffCodeList(response)
    } catch (error) {
      //setLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}\n`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  },[])

  const fetchRoleMasterList = useCallback(async () => {
    try {
      // setLoading(true);
      const response = await getRolesMasterList();
      // setLoading(false);
      setRoleMasterList(response)
    } catch (error) {
      // setLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}\n`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, []);

  const onSubmit = async (values: StaffDomain) => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    setConfirmModalOpen(false);
    setIsLoading(true);

    try {
      const dataPost = values.getStaffInfoPostData(isEditMode);
      const response = await postPermission(isEditMode ? 1 : 0, menuType, dataPost);
      const updatedStaff = new StaffDomain(response.staff);
      if (!isEditMode) {
        sessionStorage.setItem('selectedOrgCodeStaffMaster', updatedStaff.orgCode);
      } else {
        // ログインユーザー自身の編集中の場合
        if (isEditingLoginUser) {
          // ログインユーザー自身の閲覧範囲を更新した場合は、ログイン情報の閲覧範囲を更新する
          if (String(updatedStaff.accessLevel) !== sessionStorage.getItem(SessionStorageKey.LoginUser.ACCESS_LEVEL)) {
            sessionStorage.setItem(SessionStorageKey.LoginUser.ACCESS_LEVEL, String(updatedStaff.accessLevel));
          }
        }
      }

      setIsLoading(false);
      successNotification(isEditMode ? '更新しました。' : '登録しました。');

      if (!isEditMode && response) {
        history.goBack();
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.map((item: { defaultMessage: string; }) => errorNotification(item.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };
  const formik = useFormik({
    initialValues: initialStaffDomain,
    onSubmit,
    validateOnChange: false,
  });

  const useOrgTreesOptionsAll = (functionType: number, category: Array<any>): Array<OptionType> => {
    const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
    useEffect(() => {
      getOrganizationTrees(functionType, moment(new Date()).format('YYYY-MM-DD'), MaxDate, staffCode, false, category).then((trees) => {
        if (!trees) {
          console.error('店舗一覧が取得できません');
          return;
        }
        const orgs = trees.map((tree) => ({
          value: tree.orgCode,
          label: `${tree.orgCode} ${tree.orgName}`,
        }));
        if (orgs && orgs[0].value === 'all') { orgs.shift(); }
        setOrgTreesOptionsList(orgs);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return orgTreesOptions;
  };
  //  api orgnaization
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  useEffect(() => {
    setOrgAccessRegistList(mappedOrgAccessList)
    fetchStaffCodeV2()
    fetchRoleMasterList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return {
    //loading,
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    useOrgTreesOptionsAll,
    organizationCategoryOptions,
    roleMasterList,
    setRoleMasterList,
    staffCodeList,
    orgAccessRegistList,
    setOrgAccessRegistList
  };
};

export default {
  usePermissionForm,
};
