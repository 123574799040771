import { useState, useCallback, useEffect } from 'react';
import { getIncomeExpenditure, downloadIncomeExpenditureCsv, downloadIncomeExpenditureExcel } from 'api/incomeExpenditure';
import moment from 'moment';
import { reponseGetHopeShift } from 'api/employment';

const useIncomeExpenditure = () => {
  const functionType = 3;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode'));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);
  const year = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState<number>(year);
  const [listIncomeExpenditure, setListIncomeExpenditure] = useState([]);

  const fetchData = useCallback(async () => {
    let arrData: any = [];
    setLoading(true);
    try {
      const response = await getIncomeExpenditure(
        String(orgCode),
        moment(new Date(selectedYear, 0)).startOf('year').format('YYYY/MM/DD'),
        moment(new Date(selectedYear, 0)).endOf('year').format('YYYY/MM/DD'),
        categoryAry,
      );
      setListIncomeExpenditure(response);
      response.map((item: any, index: number) => {
        arrData[index] = item.employeeLaborCost;
      });
      console.log('arrData', arrData);
    } catch (error) {
        setListIncomeExpenditure([]);
    }
    setLoading(false);
  }, [orgCode, selectedYear, categoryAry]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    isLoading,
    listIncomeExpenditure,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    selectedYear,
    setSelectedYear,
    functionType
  };
};

export const useDownload = () => {
  const downloadCsv = (data: any) => {
    downloadIncomeExpenditureCsv(data, '【月別】収支表帳票.csv');
  };

  const downloadExcel = (data: any) => {
    downloadIncomeExpenditureExcel(data, '【月別】収支表帳票.xlsx');
  };
  return {
    downloadCsv,
    downloadExcel
  };
};

export default useIncomeExpenditure;
