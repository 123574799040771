import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import YearlySalesManagementLayout from 'components/organismos/master/storeManagement/report/YearlySalesManagement/YearlySalesManagementLayout';
import useGetFiscalYearly from './hooks';

const YearlySalesManagementPage = () => {
  const { getValueFiscalYear } = useGetFiscalYearly();

  return (
    <SidebarTemplate pageTitle="年次帳票">
      {
        getValueFiscalYear ? <YearlySalesManagementLayout fiscalYear={getValueFiscalYear} /> : null
      }

    </SidebarTemplate>
  );
};
export default YearlySalesManagementPage;
