/* eslint-disable eqeqeq */
/** @jsx jsx */
import React, { useRef, useCallback, useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import {
  Container, Row, Col,
} from 'react-grid-system';
import BodyText from 'components/atoms/BodyText';
import { PrimaryButton } from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import BottomBar from 'components/organismos/BottomBar';
import FormField from 'components/atoms/Form/FormField';
import BlockUI from 'components/molecules/BlockUi';
import FlexBox from 'components/atoms/FlexBox';
import SelectForm from 'components/molecules/SelectForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import DatePicker from 'components/molecules/DatePicker';
import RadioSelector from 'components/molecules/RadioSelector';
import TextForm from 'components/molecules/TextForm';
import Modal from 'components/molecules/Modal';
import Webcam from 'react-webcam';
import EXIF from 'exif-js';
import { isMobileOnly } from 'react-device-detect';
import { css, jsx } from '@emotion/core';
import { productColor } from 'components/styles';
import ToastModal from '../../ToastModal';
import { PersonalSettingForm } from './hooks';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { uiSidebarActions } from 'modules/ui/sidebar';

const videoConstraints = {
  width: 255,
  height: 168,
  facingMode: 'user',
};

const styles = {
  textMessage: css({
    color: 'black',
  }),
};

const PersonalSetting: React.FC = () => {
  const {
    formik,
    toastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    closeConfirmApplyModal,
    isLoading,
    handlePrintPdf,
    handlePostPhoto,
    setSubmitType,
    openModalLogout,
  } = PersonalSettingForm();
  const webcamRef = useRef(Object());
  const canvasRef = useRef(Object());
  const fileInputRef = useRef(Object());
  const imgRef = useRef(Object());
  const useSalaryPayslip = sessionStorage.getItem('loginUser.useSalaryPayslip');

   // logout
  const history = useHistory();
  const dispatch = useDispatch();
  const logoutAction = () => {
    dispatch(uiSidebarActions.setHierarchy(undefined));
    sessionStorage.clear();
    localStorage.removeItem('logged');
    localStorage.removeItem('topPageSP');
    localStorage.removeItem('topPagePC');
    Auth.signOut();
    history.push('/login');
  };

  const capture = useCallback(() => {
    if (webcamRef.current) {
      // Reset file upload
      fileInputRef.current.value = '';

      const camera = webcamRef.current.video;
      const canvas = canvasRef.current;

      const ctx = canvas.getContext('2d');
      const w = camera.offsetWidth;
      const h = camera.offsetHeight;
      canvas.setAttribute('width', w);
      canvas.setAttribute('height', h);
      // 左右反転
      ctx.transform(-1, 0, 0, 1, w, 0);
      // カメラから キャンバスに静止画を描く
      ctx.drawImage(camera, 0, 0, w, h);

      canvas.toBlob((blob: any) => {
        console.log('capture');
        handlePostPhoto(blob);
      }, 'image/jpeg');
    }
  }, [handlePostPhoto]);

  const uploadFile = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const changefile = useCallback((e) => {
    const file = fileInputRef.current.files[0];
    const reader = new FileReader();
    const img = imgRef.current;
    img.crossOrigin = 'anonymous';

    if (file && !file.type.match(/image.*/)) {
      return false;
    }

    reader.onloadend = function () {
      img.onload = function () { // 画像が読み込めた時の処理
        EXIF.getData(img, () => {
          const canvas = canvasRef.current;
          const ctx = canvas.getContext('2d');

          const size = img.width;
          const w = size;
          const h = img.height * (size / img.width);
          console.log(w, h);

          ctx.clearRect(0, 0, canvas.width, canvas.height);

          let rotate = 0;
          console.log(EXIF.pretty(img));

          // 回転方向の読み取り
          if (EXIF.pretty(img)) {
            if (EXIF.getAllTags(img).Orientation == 6) {
              rotate = 90;
            } else if (EXIF.getAllTags(img).Orientation == 3) {
              rotate = 180;
            } else if (EXIF.getAllTags(img).Orientation == 8) {
              rotate = 270;
            }
          }

          // Canvasのサイズを指定
          if (rotate == 90 || rotate == 270) {
            canvas.width = h;
            canvas.height = w;
          } else {
            canvas.width = w;
            canvas.height = h;
          }

          // 画像の回転
          if (rotate && rotate > 0) {
            ctx.rotate(rotate * Math.PI / 180);
            if (rotate == 90) { ctx.translate(0, -h); } else if (rotate == 180) { ctx.translate(-w, -h); } else if (rotate == 270) { ctx.translate(-w, 0); }
          }

          ctx.drawImage(img, 0, 0, w, h);

          canvas.toBlob((blob: any) => {
            console.log('changefile');
            handlePostPhoto(blob);
          }, 'image/jpeg');
        });
      };

      img.src = String(reader.result);
    };

    if (file != undefined) {
      reader.readAsDataURL(file);
    }
  }, [handlePostPhoto]);

  const textfix = css({
    color: productColor.secondary,
    padding: '5px 0',
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: 400,
  });
  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <form onSubmit={formik.handleSubmit}>
          <Container fluid={true}>
            <Row>
              <Col md={2} xs={12}>
                <BodyText>スタッフ名</BodyText>
              </Col>
              <Col md={6} xs={12}>
                <BodyText>
                  {formik.values.staffName}
                </BodyText>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col md={2} xs={12}>
                <BodyText>所属組織</BodyText>
              </Col>
              <Col md={6} xs={12}>
                <BodyText>
                  {formik.values.orgName}
                </BodyText>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col md={2} xs={12}>
                <BodyText>スタッフコード</BodyText>
              </Col>
              <Col md={6} xs={12}>
                <BodyText>
                  {formik.values.staffCode}
                </BodyText>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }} align={'center'}>
              <Col md={2} xs={12}>
                <BodyText>登録メールアドレス</BodyText>
              </Col>
              <Col md={6} xs={12}>
                <TextForm
                  label=""
                  name="mail"
                  value={formik.values.mail || ''}
                  onChange={formik.handleChange}
                  placeHolder="半角で入力してください"
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col md={2} xs={12}>
              </Col>
              <Col md={6} xs={12}>
                <PrimaryButton
                  minWidth="60px"
                  ghost={false}
                  text="メールアドレス更新"
                  onClick={() => {
                    setSubmitType('staff');
                    formik.handleSubmit();
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col lg={2} md={12} xs={12}>
                <BodyText>通知設定</BodyText>
              </Col>
              <Col lg={10} md={12} xs={12}>
                <Row>
                  <Col lg={4} md={12}>
                    <FormField displayBlock={true}>
                      <SelectForm
                        label="シフト通知"
                        name="notifyBeforeShiftStart"
                        value={String(formik.values.attendNoticeSetting.notifyBeforeShiftStart ? '1' : '0')}
                        setValue={(v: string) => {
                          formik.setFieldValue('attendNoticeSetting.notifyBeforeShiftStart', v === '1');
                        }}
                        options={[{ value: '0', label: '通知しない' }, { value: '1', label: '通知する' }]}
                        description=""
                      />
                    </FormField>
                  </Col>
                  <Col lg={1} md={0} />
                  {
                    formik.values.attendNoticeSetting.notifyBeforeShiftStart && (
                    <Col lg={4} md={12}>
                      <FormField displayBlock={true}>
                        <SelectForm
                          islabelLong={true}
                          label="通知時間：シフト開始の"
                          name="notifyToStampForgot"
                          value={String(formik.values.attendNoticeSetting.minutesBeforeShiftStart) === '0' ? '30' : String(formik.values.attendNoticeSetting.minutesBeforeShiftStart)}
                          setValue={(v) => {
                            formik.setFieldValue('attendNoticeSetting.minutesBeforeShiftStart', v);
                            console.log(formik.values.attendNoticeSetting);
                          }}
                          options={[{ value: '30', label: '30分前' }, { value: '60', label: '60分前' }, { value: '90', label: '90分前' }]}
                          description=""
                        />
                      </FormField>
                    </Col>
                    )
                  }
                </Row>
                <Row>
                  <Col lg={4} md={12} xs={12}>
                    <FormField displayBlock={true}>
                      <SelectForm
                        label="打刻忘れ"
                        name="notifyToStampForgot"
                        value={formik.values.attendNoticeSetting.notifyToStampForgot ? '1' : '0'}
                        setValue={(v: string) => {
                          formik.setFieldValue('attendNoticeSetting.notifyToStampForgot', v === '1');
                          console.log(formik.values.attendNoticeSetting);
                        }}
                        options={[{ value: '0', label: '通知しない' }, { value: '1', label: '通知する' }]}
                        description=""
                      />
                    </FormField>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <PrimaryButton
                      minWidth="60px"
                      ghost={false}
                      text="通知設定更新"
                      onClick={() => {
                        setSubmitType('notice');
                        formik.handleSubmit();
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {
              useSalaryPayslip === 'true'
                ? (
                  <Row style={{ marginTop: '20px' }}>
                    <Col
                      md={2}
                      xs={12}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <BodyText>
                        給与明細ダウンロード
                      </BodyText>
                    </Col>
                    <Col lg={4} md={12} xs={12}>
                      <FormField>
                        <FlexBox>
                          <FlexBoxItem>
                            <DatePicker
                              dateFormat="yyyy年MM月"
                              date={formik.values.paymentTarget}
                              showMonthYearPicker={true}
                              changeDate={(date: Date) => {
                                if (date !== null) {
                                  formik.setFieldValue('paymentTarget', date);
                                }
                              }}
                              isFullWidth={true}
                            />
                          </FlexBoxItem>
                          <FlexBoxItem marginLeft="15px">
                            <div style={{ marginTop: '-8px', minWidth: '130px' }}>
                              <RadioSelector
                                name="uploadType"
                                items={[
                                  {
                                    value: '0',
                                    label: '給与',
                                  },
                                  {
                                    value: '1',
                                    label: '賞与',
                                  },
                                ]}
                                value={formik.values.formatType}
                                setValue={(e) => formik.setFieldValue('formatType', e.target.value)}
                              />
                            </div>
                          </FlexBoxItem>
                        </FlexBox>
                      </FormField>
                    </Col>
                    <Col lg={2} md={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                      <PrimaryButton
                        minWidth="150px"
                        ghost={false}
                        text="ダウンロード"
                        onClick={() => { handlePrintPdf(); }}
                      />
                    </Col>
                  </Row>
                )
                : null
            }
            <Row style={{ marginTop: '30px' }}>
              <Col style={{ marginBottom: '10px' }} lg={2} md={4} xs={12}>
                <BodyText>
                  顔写真登録
                </BodyText>
              </Col>
              <Col lg={10} md={12} xs={12}>
                <Row>
                  <Col lg={6} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                      <span css={textfix}>帽子、マスクなどは外して、顔全体を撮影してください。</span>
                      <Webcam
                        screenshotQuality={1}
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                        }}
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={640}
                        height={480}
                        videoConstraints={videoConstraints}
                        mirrored={true}
                      />
                      <canvas style={{ display: 'none' }} ref={canvasRef} className="canvas" />
                    </div>

                  </Col>
                  <Col lg={1} md={0} />
                  <Col lg={3} md={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <Row style={{ width: '100%' }}>
                      {
                        isMobileOnly ? (
                          <FlexBox customStyle={css({ width: '100%', justifyContent: 'space-between', marginTop: '10px' })}>
                            <FlexBoxItem>
                              <PrimaryButton
                                ghost={false}
                                text="カメラで撮影する"
                                onClick={() => {
                                  capture();
                                }}
                              />
                            </FlexBoxItem>
                            <FlexBoxItem>
                              <PrimaryButton
                                ghost={true}
                                text="ファイルを選択"
                                onClick={() => {
                                  uploadFile();
                                }}
                              />
                            </FlexBoxItem>
                          </FlexBox>
                        ) : (
                          <React.Fragment>
                            <Col style={{ margin: '0 0 10px 0' }} lg={12} md={6} xs={6}>
                              <PrimaryButton
                                minWidth="150px"
                                ghost={false}
                                text="カメラで撮影する"
                                onClick={() => {
                                  capture();
                                }}
                              />
                            </Col>
                            <Col lg={12} md={6} xs={6}>
                              <PrimaryButton
                                minWidth="150px"
                                ghost={true}
                                text="ファイルを選択"
                                onClick={() => {
                                  uploadFile();
                                }}
                              />
                            </Col>
                          </React.Fragment>
                        )
                      }
                      <input style={{ display: 'none' }} ref={fileInputRef} type="file" onChange={(e) => changefile(e)} accept=".jpg,.png,image/jpeg,image/png" />
                      <img style={{ display: 'none' }} ref={imgRef} src="" />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </form>

        <ToastModal
          open={toastModalOpen}
          closeHandler={() => setToastModalOpen(false)}
          title={toastMessage}
        />

        <ConfirmModal
          open={confirmApplyModalOpen}
          closeHandler={closeConfirmApplyModal}
          onSubmit={formik.handleSubmit}
          title="確認メッセージ"
          content="更新します。よろしいですか？"
          submitText="更新"
        />
      </FormContents>

      <Modal
        open={openModalLogout}
        closeHandler={logoutAction}
        title=""
        submitText="OK"
        onSubmit={logoutAction}
        isShowButtonClose={false}
      >
        <p css={styles.textMessage}>スタッフ情報が変更されました。ログイン画面に戻ります。</p>
      </Modal>

      {isMobileOnly && <BottomBar />}
    </BlockUI>
  );
};

export default PersonalSetting;
