/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

const style = css({
  display: 'flex',
  paddingTop: '16px',
  '*': {
    marginRight: '0px',
  },
});

const FormSubmitArea: React.FC = ({ children }) => (
  <div css={style}>
    {children}
  </div>
);

export default FormSubmitArea;
