import { useState, useEffect } from 'react';
import { OptionType } from 'components/atoms/Select';
import { getAdvertisingMediaOrganizationList } from 'api/advertisingMediaOrganization';

/**
 * Get advertising media options
 */
export const useAdvertisingMediaOptions = (): Array<OptionType> => {
  const [advertisingMediaOptions, setAdvertisingMediaOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getAdvertisingMediaOrganizationList().then((advertisingMedias) => {
      setAdvertisingMediaOptions(advertisingMedias.map((advertisingMedia) => ({
        value: advertisingMedia[0],
        label: advertisingMedia[1],
      })));
    });
  }, []);
  return advertisingMediaOptions;
};

export default useAdvertisingMediaOptions;
