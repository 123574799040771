/** @jsx jsx */
import React,{useState} from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';
import { grayScale, textFontSize, iconColor } from 'components/styles';

const styles = {
  style: css({
    border: `solid 1px ${grayScale.gray50}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '90%',
    fontSize: textFontSize.sm,
    fontFamily: 'inherit',
    padding: '4px',
    textAlign: 'right',
    margin: '7px'
  }),
  grayOut: css({
    background: iconColor.gray,
  }),
};

const FormatInput: React.FC<{
  name: string;
  label?: string;
  value: string;
  placeHolder?: string;
  type?: string;
  readOnly?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  customStyle?: SerializedStyles;
  disable?: boolean;
  min?: string,
  max?: string,
}> = ({
  name, type, value, onChange, onBlur, placeHolder, customStyle, disable, readOnly = false,
  min, max
}) =>{ 
  // const [addMinusOnMaxLength,setAddMinusOnMaxLength] = useState(13)
  // const handleMinusKeyPress = (e:any) => {
  //   if (e.key === '-') {
  //     // max 10 numbers + 3 comma + 1 minus for negative number
  //     setAddMinusOnMaxLength(14)  
  //   }else{
  //     // max 10 numbers + 3 comma
  //     setAddMinusOnMaxLength(13)
  //   }
  // }
  return (
  <input
    css={css(styles.style, readOnly ? styles.grayOut : {}, customStyle)}
    id={name}
    name={name}
    type={type}
    value={value}
    placeholder={placeHolder}
    onChange={onChange}
    onBlur={onBlur}
    readOnly={readOnly}
    disabled={disable}
    min={min}
    max={max}
    // maxLength={addMinusOnMaxLength}
    // onKeyPress={handleMinusKeyPress}
  />
  )
}

export default FormatInput;
