import React from 'react';
import TaxRateCaptureSettingForm from 'components/organismos/master/sales/TaxRateCaptureSettingForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const TaxRateCaptureSetting = () => (
  <SidebarTemplate
    pageTitle="税率マスタ"
  >
    <TaxRateCaptureSettingForm />
  </SidebarTemplate>
);
export default TaxRateCaptureSetting;
