import React from 'react';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button, { PrimaryButton } from 'components/atoms/Button';
import DatePickerForm from 'components/molecules/DatePickerForm';
import FormContents from 'components/atoms/Form/FormContents';
import useOrgTreesOptions from 'hooks/useOrgTreesOptions';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import moment from 'moment';
import { useHolidayAddForm } from './hooks';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const HolidayAddForm: React.FC<{publicHolidayId?: string}> = ({
  publicHolidayId,
}) => {
  const {
    formik, confirmModalOpen, closeConfirmModal,
    history,
  } = useHolidayAddForm(publicHolidayId);
  const startAndEndDate = moment(formik.values.holidayDate).format('YYYY-MM-DD');
  const orgTreesOptions = useOrgTreesOptions(startAndEndDate, startAndEndDate);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <FormContents>
      <p>
        祝日を登録します。日付と名前を入力して、割り当てる組織を選択してください。
      </p>

      <form onSubmit={formik.handleSubmit}>
        <FormField>
          <DatePickerForm
            dateFormat="yyyy/MM/dd"
            label="日付"
            date={formik.values.holidayDate}
            isFullWidth={true}
            changeDate={(date: Date) => {
              if (date !== null) {
                formik.setFieldValue('holidayDate', date);
              }
            }}
            required={true}
          />
        </FormField>

        <FormField>
          <TextForm
            name="holidayName"
            label="祝日名"
            value={formik.values.holidayName}
            onChange={formik.handleChange}
            required={true}
          />
        </FormField>
        <div style={publicHolidayId !== '' ? { pointerEvents: 'none' } : {}} />

        <div style={String(formik.values.isEdit) === 'true' ? {} : { display: 'none' }}>
          <p>
            組織：
            {formik.values.dispOrg}
          </p>
        </div>
        <div style={String(formik.values.isEdit) === 'false' ? {} : { display: 'none' }}>
          <FormField>
            <RadioSelectForm
              label="組織選択"
              name="isAll"
              items={[
                {
                  label: '全ての組織に設定',
                  value: 'all',
                },
                {
                  label: '組織を選択して設定',
                  value: 'selection',
                },
              ]}
              value={String(formik.values.isAll ? 'all' : 'selection')}
              setValue={(e) => formik.setFieldValue('isAll', e.target.value === 'all')}
              readOnly={true}
            />
          </FormField>
        </div>

        <div style={String(formik.values.isEdit) === 'false' && String(formik.values.isAll) === 'false' ? {} : { display: 'none' }}>
          <VerticalLabelMutilSelectForm
            label=""
            name="orgCodes"
            values={formik.values.orgCodes.map(
              (orgCode: string) => orgTreesOptions.find((item) => orgCode === item.value),
            )}
            setValue={(val: any) => {
              formik.setFieldValue('orgCodes', val.slice(0, -1).split(','));
            }}
            options={orgTreesOptions}
            isMulti={true}
            disable={formik.values.publicHolidayId !== ''}
          />
        </div>

        <FormSubmitArea>
          {
            roleScreen && roleScreen.editable === 1 && (
              <div style={{ marginRight: '12px' }}>
                <Button
                  text={publicHolidayId ? '更新' : '登録'}
                  onClick={formik.handleSubmit}
                />
              </div>
            )
          }
          <PrimaryButton
            text="戻る"
            onClick={() => {
              history.goBack();
            }}
            ghost={true}
          />
        </FormSubmitArea>

      </form>

      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={publicHolidayId ? ActionType.UPDATE : ActionType.CREATE}
      />
    </FormContents>
  );
};

export default HolidayAddForm;
