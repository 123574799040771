import React, { useEffect } from 'react';
import { Column } from 'react-table';

import Table from 'components/molecules/Table';
import { css } from '@emotion/core';
import { useDataTable } from './hooks';
import TableHeaderCell from './TableHeaderCell';
import Controllbar from './Controllbar';

interface SortBy {
  id: string;
  desc: boolean;
}

interface DataTableProps {
  columns: Array<Column<any>>;
  data: Array<any>;
  isGlobalFilter?: any;
  minWidth?: string;
  sortBy?: Array<SortBy>;
  useSession?: boolean;
  tableId?: number;
  pageSize?: number;
  emptyValueMess?: string
}


const DataTable: React.FC<DataTableProps> = ({
  columns, data, isGlobalFilter, minWidth = isGlobalFilter ? '620px' : '488px', sortBy, useSession = false, tableId = 0, pageSize,
  emptyValueMess = 'データはありません。',
}) => {
  let globalFilterTxt = '';
  let initPageSize = pageSize || 20;
  let initPageIndex = 0;

  if (useSession) {
    globalFilterTxt = sessionStorage.getItem(`sessionDataTable${tableId}${window.location.pathname}.globalFilterTxt`) || '';
    initPageSize = Number(sessionStorage.getItem(`sessionDataTable${tableId}${window.location.pathname}.initPageSize`)) || initPageSize;
    initPageIndex = Number(sessionStorage.getItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`)) || 0;
  }

  const {
    headerGroups,
    prepareRow,
    pagination,
    gotoPage,
    page,
    filter,
    flatRows,
  } = useDataTable(columns,
    data,
    isGlobalFilter,
    initPageSize,
    initPageIndex,
    sortBy,
    globalFilterTxt);
  const previous = sessionStorage.getItem('path') || '';
  useEffect(() => {
    if (window.location.pathname.split('/')[1] !== (previous ? previous.split('/')[1] : '')) {
      for (const key in sessionStorage) {
        if (sessionStorage.hasOwnProperty(key)) {
          if (key.search('sessionDataTable') !== -1) {
            sessionStorage.removeItem(key);
          }
        }
      }
    }
    if (tableId !== 0) {
      sessionStorage.setItem('path', window.location.pathname);
    }
    window.onbeforeunload = () => {
      for (const key in sessionStorage) {
        if (sessionStorage.hasOwnProperty(key)) {
          if (key.search('sessionDataTable') !== -1) {
            sessionStorage.removeItem(key);
          }
        }
      }
    };
  }, [previous, tableId]);

  return (
    <>
      <div>
        <div style={{ whiteSpace: 'nowrap' }}>
          {/* <div style={{ marginTop: '46px', whiteSpace: 'nowrap' }}> */}
          <Controllbar
            total={flatRows.length}
            isGlobalFilter={isGlobalFilter}
            filter={filter}
            pagination={pagination}
            tableId={tableId}
            gotoPage={gotoPage}
          />
          {/* <Table customStyle={{ marginTop: '46px', whiteSpace: 'nowrap' }}> */}
          {/* <Table customStyle={{ minWidth: '488px' }}> */}
          <Table customStyle={css({ minWidth, whiteSpace: 'nowrap' })}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.getHeaderGroupProps().key}>
                  {headerGroup.headers.map((column) => {
                    const headerProps = column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <TableHeaderCell
                        key={headerProps.key}
                        onClick={(headerProps as any).onClick}
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                        disableSortBy={column.disableSortBy}
                      >
                        {column.render('Header')}
                      </TableHeaderCell>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {page.map(
                (row) => {
                  prepareRow(row);
                  return (
                    // rows with errorMessage property will change the row color becomes red
                    <tr key={row.getRowProps().key} style={{ backgroundColor: row?.original && row.original?.errDetail ? '#FFB8B8' : '#ffffff' }}>
                      {row.cells.map((cell) => (
                        <Table.Cell key={cell.getCellProps().key}>
                          {cell.render('Cell')}
                        </Table.Cell>
                      ))}
                    </tr>
                  );
                },
              )}
            </tbody>
          </Table>
        </div>
        {/* </div> */}
      </div>

      {page.length === 0 ? (
        <div style={{
          backgroundColor: '#ffffff', textAlign: 'center', padding: ' 12px 0', fontSize: '14px',
        }}
        >
          {emptyValueMess}
        </div>
      ) : ''}
    </>
  );
};

export default DataTable;
