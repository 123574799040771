import _ from "lodash";
import { formatNumber, formatPercent } from "utility/formatUtil";

export interface ItemReport {
  /** ランク */
  itemRank: string,
  /** 順位 */
  itemOrder: number,
  /** メニューコード */
  menuCode: string,
  /** 商品名 */
  menuName: string,
  /** 単価 */
  unitPrice: number,
  /** 原価 */
  cost: number;
  /** 数量 */
  totalItem: number,
  /** 金額 */
  totalPrice: number,
  /** 構成比 */
  compositionRatio: number,
  /** 累計比 */
  cumulativeRatio: number,
  /** 合計原価 */
  totalCost: number,
  /** 利益 */
  profit: number,
  /** 大部門コード */
  largeDivisionCode: string,
  /** 大部門名称 */
  largeDivisionName: string,
  /** 小部門コード */
  smallDivisionCode: string,
  /** 小部門名称 */
  smallDivisionName: string,
}

export default class ItemReportDomain {
  _itemRank: string;
  _itemOrder: string;
  _menuCode: string;
  _menuName: string;
  _unitPrice: string;
  _cost: string;
  _totalItem: string;
  _totalPrice: string;
  _compositionRatio: string;
  _cumulativeRatio: string;
  _totalCost: string;
  _profit: string;
  _largeDivisionCode: string;
  _largeDivisionName: string;
  _smallDivisionCode: string;
  _smallDivisionName: string;

  constructor(private rawData: ItemReport) {
    this._itemRank = rawData.itemRank;
    this._itemOrder = formatNumber(rawData.itemOrder);
    this._menuCode = rawData.menuCode;
    this._menuName = rawData.menuName;
    this._unitPrice = formatNumber(rawData.unitPrice);
    this._cost = formatNumber(rawData.cost);
    this._totalItem = formatNumber(rawData.totalItem);
    this._totalPrice = formatNumber(rawData.totalPrice);
    this._compositionRatio = formatPercent(rawData.compositionRatio);
    this._cumulativeRatio = formatPercent(rawData.cumulativeRatio);
    this._totalCost = formatNumber(rawData.totalCost);
    this._profit = formatNumber(rawData.profit);
    this._largeDivisionCode = rawData.largeDivisionCode;
    this._largeDivisionName = rawData.largeDivisionName;
    this._smallDivisionCode = rawData.smallDivisionCode;
    this._smallDivisionName = rawData.smallDivisionName;
  }

  static generateInitial(): ItemReportDomain {
    return new ItemReportDomain({
      itemRank: '',
      itemOrder: 0,
      menuCode: '',
      menuName: '',
      unitPrice: 0,
      cost: 0,
      totalItem: 0,
      totalPrice: 0,
      compositionRatio: 0,
      cumulativeRatio: 0,
      totalCost: 0,
      profit: 0,
      largeDivisionCode: '',
      largeDivisionName: '',
      smallDivisionCode: '',
      smallDivisionName: '',
    });
  }

  getRawData(): ItemReport {
    return this.rawData;
  }

  /** 表示データ（画面非表示のプロパティを除外） */
  getDispData(): Partial<this>{
    return _.omit(this, ['rawData', '_largeDivisionCode', '_smallDivisionCode']);
  }

  /** 出力データ（CSV出力など） */
  getOutputData(): Array<Partial<this>> {
    return Object.values(this.getDispData());
  }

  get itemRank(): string {
    return this._itemRank;
  }

  get itemOrder(): string {
    return this._itemOrder;
  }

  get menuCode(): string {
    return this._menuCode;
  }

  get menuName(): string {
    return this._menuName;
  }

  get unitPrice(): string {
    return this._unitPrice;
  }

  get cost(): string {
    return this._cost;
  }

  get totalItem(): string {
    return this._totalItem;
  }

  get totalPrice(): string {
    return this._totalPrice;
  }

  get compositionRatio(): string {
    return this._compositionRatio;
  }

  get cumulativeRatio(): string {
    return this._cumulativeRatio;
  }

  get totalCost(): string {
    return this._totalCost;
  }

  get profit(): string {
    return this._profit;
  }

  get largeDivisionCode(): string {
    return this._largeDivisionCode;
  }

  get largeDivisionName(): string {
    return this._largeDivisionName;
  }

  get smallDivisionCode(): string {
    return this._smallDivisionCode;
  }

  get smallDivisionName(): string {
    return this._smallDivisionName;
  }

}
