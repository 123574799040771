import ApiClient from './ApiClient';

export const getList = async (companyCode: string, year: string, orgCode:string): Promise<any> => {
  const params = {
    year,
    orgCode,
  };

  const response = await ApiClient.get(`/v1/public/holidays/filter/${companyCode}`, params);
  return response.data;
};

export const deleteById = async (companyCode: string, date: Date, name: string, orgCode: string):
 Promise<any> => {
  const params = {
    date,
    name,
    orgCode,
  };

  const response = await ApiClient.delete(`/v1/public/holidays/${companyCode}`, params);
  return response.data;
};

export const getHolidaysOrgList = async (companyCode: string, publicHolidayId: string):
Promise<any> => {
  const params = { publicHolidayId };

  const response = await ApiClient.get(`/v1/public/holidays/orgList/${companyCode}`, params);
  return response.data;
};

export default getList;
