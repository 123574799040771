import React from 'react';
import { css } from '@emotion/core';
import FormContents from 'components/atoms/Form/FormContents';
import PrimaryButton from 'components/atoms/Button';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import BlockUI from 'components/molecules/BlockUi';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import DatePicker from 'components/molecules/DatePicker';
import FlexBox from 'components/atoms/FlexBox';
import FormLabel from 'components/atoms/Form/FormLabel';
import useTimeListReport from './hooks';

const TimeListDaTaOutputReportLayout: React.FC = () => {
  const {
    setCategoryAry,
    blocking,
    setBlocking,
    targetDateFrom,
    setTargetDateFrom,
    staffCode,
    orgCode,
    setorgCode,
    targetDateTo,
    setTargetDateTo,
    functionType,
    downloadListDataOutput,
  } = useTimeListReport();

  return (
    <div>
      <BlockUI blocking={blocking}>
        <FormContents>
          <FlexBox customStyle={css({ flexWrap: 'wrap' })}>
            <FlexBoxItem width="100px">
              <FormLabel
                label="対象期間"
              />
            </FlexBoxItem>

            <FlexBoxItem>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                date={targetDateFrom}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateFrom(date);
                  }
                }}
              />
            </FlexBoxItem>

            <FlexBoxItem>
              <span style={{ margin: '0 20px' }}>～</span>
            </FlexBoxItem>

            <FlexBoxItem>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                date={targetDateTo}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateTo(date);
                  }
                }}
              />
            </FlexBoxItem>
          </FlexBox>

          <OrganizationFilteredTree
            functionType={functionType}
            staffCode={staffCode}
            orgLabel="店舗名"
            addAllItem={true}
            initOrgValue={String(orgCode)}
            orgCallback={(args: string | Array<string>) => {
              setorgCode(String(args));
            }}
            orgCategoryCallback={(args: Array<string>) => {
              setCategoryAry(args);
            }}
            targetdayForm={targetDateFrom}
            targetdayTo={targetDateTo}
            styles={{
              label: {
                toggleSwitch: css({ marginLeft: '0px' }),
              },
              toggle: {
                width: '19rem',
                marginLeft: '0.4rem',
              },
            }}
          />

          <div style={{ marginTop: '15px' }}>
            <PrimaryButton
              ghost={false}
              text="ファイル出力"
              onClick={() => {
                setBlocking(true);
                downloadListDataOutput();
              }}
            />
          </div>
        </FormContents>
      </BlockUI>
    </div>
  );
};

export default TimeListDaTaOutputReportLayout;
