import {
  useState, useCallback, useEffect, Dispatch, SetStateAction,
} from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import { createOrUpdateMenuType, deleteByMenuType } from 'api/menuType';
import SalesMenuTypeDomain from 'domain/master/sales/salesMenuType';
import useToastNotification from 'hooks/useToastNotification';

export const useSalesMenuTypeForm = (
  menuTypeList: SalesMenuTypeDomain[],
  setMenuTypeList: Dispatch<SetStateAction<SalesMenuTypeDomain[]>>,
  isCreate: boolean,
  menuTypeObj: SalesMenuTypeDomain,
  setMenuTypeObj: Dispatch<SetStateAction<SalesMenuTypeDomain>>,
  setIsCreate: any,
  unDecided: boolean,
  setUnDecided: any,
) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalDeleteOpen, setConfirmModalDeleteOpen] = useState(false);
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const { successNotification, errorNotification } = useToastNotification();

  const onSubmit = async (values: any) => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    if (!unDecided && (values.applyEndDate === '9999/12/31' || values.applyEndDate === '9999-12-31')) {
      setConfirmModalOpen(false);
      errorNotification('適用終了月を指定するか、または、未定をチェックしてください。');
      return;
    }

    const dataPost: Array<any> = [];

    if (values.listFood) {
      values.listFood.split(',').forEach((menu: string) => {
        dataPost.push({
          id: {
            companyCode: sessionStorage.getItem('loginUser.companyCode') || '',
            orgCode: sessionStorage.getItem('selectedOrgCode') || '',
            applyStartDate: moment(new Date(values.applyStartDate)).format('YYYY/MM/DD'),
            largeDivisionCode: menu,
          },
          applyEndDate: moment(new Date(values.applyEndDate)).format('YYYY/MM/DD'),
          menuType: 0,
          createUser: staffName,
          updateUser: staffName,
        });
      });
    }

    if (values.listDrink) {
      values.listDrink.split(',').forEach((menu: string) => {
        dataPost.push({
          id: {
            companyCode: sessionStorage.getItem('loginUser.companyCode') || '',
            orgCode: sessionStorage.getItem('selectedOrgCode') || '',
            applyStartDate: moment(new Date(values.applyStartDate)).format('YYYY/MM/DD'),
            largeDivisionCode: menu,
          },
          applyEndDate: moment(new Date(values.applyEndDate)).format('YYYY/MM/DD'),
          menuType: 1,
          createUser: staffName,
          updateUser: staffName,
        });
      });
    }

    createOrUpdateMenuType(isCreate ? 1 : 0, unDecided, dataPost)
      .then((response: any) => {
        setConfirmModalOpen(false);
        if (isCreate) {
          setMenuTypeList(menuTypeList.concat(menuTypeObj));
          successNotification('登録しました。');
        } else {
          setMenuTypeList(menuTypeList.map(
            (item) => ((item.applyStartDate === menuTypeObj.applyStartDate) ? menuTypeObj : item),
          ));
          successNotification('更新しました。');
        }
        setMenuTypeObj(SalesMenuTypeDomain.generateInitial());
        setIsCreate(true);
        setUnDecided(true);
      })
      .catch((error: any) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.forEach((element: any) => {
            stringErr += `${element.defaultMessage}<br />`;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  };

  const formik = useFormik({
    initialValues: menuTypeObj,
    onSubmit,
    validateOnChange: false,
  });

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmModalDelete = useCallback(() => {
    setConfirmModalDeleteOpen(false);
  }, []);

  const openConfirmModalDelete = useCallback(() => {
    setConfirmModalDeleteOpen(true);
  }, []);


  useEffect(() => {
    formik.setValues(menuTypeObj);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuTypeObj]);

  const deleteMenuType = useCallback(() => {
    const dataPost: Array<any> = [];

    if (menuTypeObj.listFood) {
      menuTypeObj.listFood.split(',').forEach((menu: string) => {
        dataPost.push({
          id: {
            companyCode: sessionStorage.getItem('loginUser.companyCode') || '',
            orgCode: sessionStorage.getItem('selectedOrgCode') || '',
            applyStartDate: moment(new Date(menuTypeObj.applyStartDate)).format('YYYY/MM/DD'),
            largeDivisionCode: menu,
          },
          applyEndDate: moment(new Date(menuTypeObj.applyEndDate)).format('YYYY/MM/DD'),
          menuType: 0,
          createUser: staffName,
          updateUser: staffName,
        });
      });
    }

    if (menuTypeObj.listDrink) {
      menuTypeObj.listDrink.split(',').forEach((menu: string) => {
        dataPost.push({
          id: {
            companyCode: sessionStorage.getItem('loginUser.companyCode') || '',
            orgCode: sessionStorage.getItem('selectedOrgCode') || '',
            applyStartDate: moment(new Date(menuTypeObj.applyStartDate)).format('YYYY/MM/DD'),
            largeDivisionCode: menu,
          },
          applyEndDate: moment(new Date(menuTypeObj.applyEndDate)).format('YYYY/MM/DD'),
          menuType: 1,
          createUser: staffName,
          updateUser: staffName,
        });
      });
    }

    deleteByMenuType(dataPost).then((Response: any) => {
      setMenuTypeList(menuTypeList.filter(
        (item) => item.applyStartDate !== menuTypeObj.applyStartDate,
      ));
      setMenuTypeObj(SalesMenuTypeDomain.generateInitial());
      setIsCreate(true);
      setUnDecided(true);
      setConfirmModalDeleteOpen(false);
      successNotification('削除しました。');
    }).catch((error : any) => {
      setConfirmModalDeleteOpen(false);

      if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [errorNotification, menuTypeList, menuTypeObj.applyEndDate, menuTypeObj.applyStartDate, menuTypeObj.listDrink, menuTypeObj.listFood, setIsCreate, setMenuTypeList, setMenuTypeObj, setUnDecided, staffName, successNotification]);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    isCreate,
    setIsCreate,
    deleteMenuType,
    setUnDecided,
    confirmModalDeleteOpen,
    closeConfirmModalDelete,
    setConfirmModalDeleteOpen,
    openConfirmModalDelete,
    unDecided,
  };
};


export default useSalesMenuTypeForm;
