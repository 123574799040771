/** @jsx jsx */
import React, {
  ReactElement, useMemo, useState, useEffect,
} from 'react';
import { css, jsx } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import FormContents from 'components/atoms/Form/FormContents';
import BodyText from 'components/atoms/BodyText';
// import useWorkingStatusData, { SelectType } from './hook';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import DatePickerForm from 'components/molecules/DatePickerForm';
import moment from 'moment';
import FileSelectButton from 'components/molecules/FileSelectButton';
import FormField from 'components/atoms/Form/FormField';
import { utilityColor } from 'components/styles';
import PrimaryButton, { SecondaryButton } from 'components/atoms/Button';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import ConfirmModal from 'components/organismos/ConfirmModal';
import ReactTooltip from 'react-tooltip';
import { TGeneralImport, TImportResults, TFileImportedResponse } from '../type/import';
import { fetch } from './reducer/action';
import { useBankImportDetails } from '../context/BankImportDetails';
import mockImportData from '../mockImportData.json';

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const numberFormater = (num: any) => {
  if (typeof num === 'undefined' || isNaN(num)) {
    return num;
  }
  const tmp = String(num).split('.');
  if (tmp[1]) {
    let fixed = 3;
    if (tmp[1].length < 3) {
      fixed = tmp[1].length;
    }
    return formatter.format(Number(parseFloat(num).toFixed(fixed)));
  }
  return formatter.format(num);
};
const styles = {
  label: css({
    marginLeft: '4px',
    fontSize: '16px',
  }),
  selectionWrapper: css({
    minHeight: 75,
    height: 75,
    marginTop: 15,
  }),
  selectionWrapperItem: css({
    height: '100%',
    width: 375,
  }),
  selectionItemTitle: css({
    height: 25,
  }),
  selectionItemContent: css({
    paddingTop: 5,
  }),
  marginRight: css({
    marginRight: 15,
  }),
  marginRightButton: css({
    marginRight: 25,
  }),
  marginLeft: css({
    marginLeft: 15,
  }),
  flexSpaceBetween: css({
    justifyContent: 'space-between',
  }),
  importTitle: css({
    marginBottom: 4,
  }),
  flexImportTitle: css({
    marginTop: 10,
  }),
  importBankName: css({
    marginBottom: '7.5px',
  }),
};
const BankDepositImportLayout: React.FC = (): ReactElement => {
  const {
    blocking,
    bankImportState,
    dispatch,
    errors,
    handleChange,
    handleSubmit,
    confirmModalOpen,
    closeConfirmModal,
    handleCancelSubmit,
    confirmOrCancelModal,
    setConfirmOrCancelModal,
    renderTable,
    setRenderTable,
    uploadFile,
    setUploadFile,
    dataTableList,
    setDataTableList,
    handleButtonImportType,
    fileImportedResponse,
    renderTableBlocking,
    dynamicBankOptions,
    fetchBankList,
  } = useBankImportDetails();
  // const [uploadFile, setUploadFile] = useState<File>();
  const [caseOption, setCaseOption] = useState<string>('1');
  const { importResults } = fileImportedResponse;
  const handleChangeCaseOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCaseOption(e.target.value);
  };
  const optionCaseList = [
    {
      value: '0',
      label: '全件',
    },
    {
      value: '1',
      label: 'エラーのみ',
    },
  ];
  const columns: Array<Column<TImportResults>> = useMemo(
    () => [
      {
        Header: '行',
        accessor: 'lineNo',
      },
      {
        Header: '区分',
        accessor: 'dataType',
      },
      {
        Header: '入金日',
        accessor: 'depositDate',
      },
      {
        Header: '入金処理日',
        accessor: 'depositProcessDate',
      },
      {
        Header: '入出金',
        accessor: 'depositWithdrawalType',
        Cell: (cell: { row: { isExpanded: boolean; original: TImportResults } }) => {
          const { depositWithdrawalType } = cell.row.original;
          return (
            <div>
              {
                (depositWithdrawalType === '1' && '入金') || (depositWithdrawalType === '2' && '出金')
              }
            </div>
          );
        },
      },
      {
        Header: '金額',
        accessor: 'transactionAmount',
        Cell: (cell: { row: { isExpanded: boolean; original: TImportResults } }) => {
          const { transactionAmount } = cell.row.original;
          return (
            <div>
              {
                numberFormater(transactionAmount)
              }
            </div>
          );
        },
      },
      {
        Header: '登録番号',
        accessor: 'registrationNo',
      },
      {
        Header: '組織名',
        accessor: 'orgName',
      },
      {
        Header: 'エラー内容',
        accessor: 'errDetail',
        Cell: (cell: { row: { isExpanded: boolean; original: TImportResults } }) => {
          const { errDetail } = cell.row.original;
          return (
            <div>
              <div>
                {(errDetail === null || errDetail === '') && (
                  <div>{errDetail}</div>
                )}
              </div>
              <div>
                {errDetail !== null && errDetail !== '' && (
                  <div
                    style={{
                      color: '#FF0000', fontSize: 14, maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis',
                    }}
                    data-tip={errDetail}
                  >
                    {errDetail}
                    <ReactTooltip html={true} />
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );
  const hasErrorFlg = useMemo(() => {
    const result = importResults.every((item: TImportResults) => item.errFlg === false);
    return result;
  }, [importResults]);
  const conditionDataTableValue = useMemo(() => {
    if (importResults) {
      if (caseOption === '0') {
        return importResults;
      }
      return importResults.filter((item: TImportResults) => item.errDetail !== '');
    }
    return [];
  }, [caseOption, importResults]);
  useEffect(() => {
    fetchBankList();
  }, [fetchBankList]);
  useEffect(() => {
    setDataTableList(uploadFile ? mockImportData : []);
  }, [setDataTableList, uploadFile]);
  const cssValue = css`
  table tbody tr {
    height:35px;
  }
  table th:nth-of-type(1) {
    width: 15px !important;
    text-align:center;
  }
  table td:nth-of-type(1) {
    text-align:right;
  }
  table th:nth-of-type(2) {
    width: 25px !important;
    text-align:center;
  }
  table td:nth-of-type(2) {
    text-align:center;
  }
  table th:nth-of-type(3) {
    width: 60px !important;
    text-align:center;
  }
  table td:nth-of-type(3) {
    text-align:center;
  }
  table th:nth-of-type(4) {
    width: 60px !important;
    text-align:center;
  }
  table td:nth-of-type(4) {
    text-align:center;
  }
  table th:nth-of-type(5) {
    width: 35px !important;
    text-align:center;
  }
  table td:nth-of-type(5) {
    text-align:center;
  }
  table th:nth-of-type(6) {
    width: 75px !important;
    text-align:center;
  }
  table td:nth-of-type(6) {
    text-align:right;
  }
  table th:nth-of-type(7) {
    width: 75px !important;
    text-align:center;
  }
  table td:nth-of-type(7) {
    text-align:center;
  }
  table th:nth-of-type(8) {
    width: 125px !important;
    text-align:left;
  }
  table td:nth-of-type(8) {
    text-align:left;
  }
  table th:nth-of-type(9) {
    width: 175px !important;
    text-align:left;
  }
  table td:nth-of-type(9) {
    text-align:left;
  }
  `;
  return (
    <BlockUI blocking={blocking}>
      <FormContents isFullWidthForm={true}>
        <BodyText customStyle={styles.label}>入出金明細ファイルを取リ込み、銀行入金データを登録します。</BodyText>
        <FlexBox customStyle={styles.selectionWrapper}>
          <FlexBoxItem customStyle={styles.selectionWrapperItem}>
            <FlexBox direction="column" alignItems="flex-start" customStyle={styles.selectionWrapperItem}>
              <FlexBoxItem textAlign="left" customStyle={styles.selectionItemTitle}>
                <BodyText customStyle={styles.label}>営業日</BodyText>
              </FlexBoxItem>
              <FlexBoxItem>
                <FlexBox>
                  <FlexBoxItem customStyle={styles.marginRight}>
                    <DatePickerForm
                      label=""
                      date={moment(new Date(bankImportState.businessStartDate)).toDate()}
                      dateFormat="yyyy年MM月dd日"
                      changeDate={(date: Date) => {
                        if (date !== null) {
                          const newStartDate = { ...bankImportState };
                          if (moment(date).valueOf() > moment(newStartDate.businessEndDate).valueOf()) {
                            newStartDate.businessEndDate = moment(date).format('YYYY/MM/DD');
                            newStartDate.businessStartDate = moment(date).format('YYYY/MM/DD');
                          } else {
                            newStartDate.businessStartDate = moment(date).format('YYYY/MM/DD');
                          }
                          dispatch(fetch(newStartDate));
                        }
                      }}
                      isFullWidth={true}
                      disable={renderTable}
                      isShowDateOnDisable={true}
                    />
                  </FlexBoxItem>
                  <FlexBoxItem>
                    ～
                  </FlexBoxItem>
                  <FlexBoxItem customStyle={styles.marginLeft}>
                    <DatePickerForm
                      label=""
                      date={moment(new Date(bankImportState.businessEndDate)).toDate()}
                      dateFormat="yyyy年MM月dd日"
                      changeDate={(date: Date) => {
                        if (date !== null) {
                          const newEndDate = { ...bankImportState };
                          if (moment(date).valueOf() < moment(newEndDate.businessStartDate).valueOf()) {
                            newEndDate.businessEndDate = moment(date).format('YYYY/MM/DD');
                            newEndDate.businessStartDate = moment(date).format('YYYY/MM/DD');
                          } else {
                            newEndDate.businessEndDate = moment(date).format('YYYY/MM/DD');
                          }
                          dispatch(fetch(newEndDate));
                        }
                      }}
                      isFullWidth={true}
                      disable={renderTable}
                      isShowDateOnDisable={true}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </FlexBoxItem>
            </FlexBox>
          </FlexBoxItem>
          <FlexBoxItem customStyle={styles.selectionWrapperItem}>
            <FlexBox direction="column" alignItems="flex-start" customStyle={styles.selectionWrapperItem}>
              <FlexBoxItem textAlign="left" customStyle={styles.selectionItemTitle}>
                <BodyText customStyle={styles.label}>ファイル形式</BodyText>
              </FlexBoxItem>
              <FlexBoxItem customStyle={styles.selectionItemContent}>
                <RadioSelectForm
                  label=""
                  items={dynamicBankOptions}
                  name="bankOption"
                  value={String(bankImportState.bankOption)}
                  setValue={handleChange}
                  labelInline={false}
                  disabled={renderTable}
                />
              </FlexBoxItem>
            </FlexBox>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox direction="column" alignItems="flex-start">
          {
            !renderTable && (
              <FlexBoxItem>
                <BodyText customStyle={styles.importTitle}>入出金明細ファイル</BodyText>
              </FlexBoxItem>
            )
          }
          <FlexBoxItem>
            {
              !renderTable && (
                <FormField>
                  <FileSelectButton
                    text="CSVファイルをドラッグ&ドロップ または クリックしてファイルを選択してください"
                    ghost={true}
                    setFile={setUploadFile}
                    accept=".csv"
                  />
                </FormField>
              )
            }
            {uploadFile || errors
              ? (
                <BlockUI blocking={renderTableBlocking}>
                  <FlexBox direction="column" alignItems="flex-start">
                    <FlexBoxItem customStyle={styles.importBankName}><BodyText>{uploadFile && `ファイル名：${uploadFile?.name}`}</BodyText></FlexBoxItem>
                    {
                      renderTable && (
                        <FlexBoxItem>
                          <BodyText>
                            銀行:
                            {' '}
                            {fileImportedResponse.bankName}
                          </BodyText>
                        </FlexBoxItem>
                      )
                    }
                    {errors && errors.map((error) => (
                      <FlexBoxItem>
                        <div key={error}>
                          <BodyText color={utilityColor.error}>
                            {error}
                          </BodyText>
                        </div>
                      </FlexBoxItem>
                    ))}
                  </FlexBox>
                </BlockUI>
              )
              : null}
          </FlexBoxItem>
        </FlexBox>

        {
          !renderTable && (
            <FlexBox customStyle={styles.flexImportTitle}>
              <FlexBoxItem>
                <PrimaryButton
                  text="インポート"
                  // onClick={() => setRenderTable(!renderTable)}
                  onClick={() => {
                    setRenderTable(!renderTable);
                    handleButtonImportType(uploadFile, bankImportState.bankOption);
                  }}
                  disabled={!uploadFile}
                />
              </FlexBoxItem>
            </FlexBox>
          )
        }
        {
          renderTable && (
            <FlexBox customStyle={{ ...styles.flexSpaceBetween }}>
              <FlexBoxItem>
                <FlexBox>
                  <FlexBoxItem>
                    <RadioSelectForm
                      label="インポート結果"
                      items={optionCaseList}
                      name="caseOption"
                      value={String(caseOption)}
                      setValue={handleChangeCaseOption}
                      labelInline={true}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </FlexBoxItem>

              <FlexBoxItem>
                <FlexBox>
                  <FlexBoxItem customStyle={styles.marginRightButton}>
                    <div className="bankCustomizedButton">
                      <PrimaryButton
                        text="登録"
                        onClick={() => {
                          setConfirmOrCancelModal('confirm');
                          handleSubmit();
                        }}
                        disabled={!hasErrorFlg}
                      />
                    </div>
                  </FlexBoxItem>
                  <FlexBoxItem>
                    <SecondaryButton
                      text="キャンセル"
                      onClick={() => {
                        setConfirmOrCancelModal('cancel');
                        handleCancelSubmit();
                      }}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </FlexBoxItem>
            </FlexBox>
          )
        }
        {
          renderTable && (
            <BlockUI blocking={renderTableBlocking}>
              {
                !renderTableBlocking && (
                  <div
                    css={cssValue}
                  >
                    <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', backgroundColor: '#fff' }}>
                      <DataTable
                        columns={columns}
                        data={conditionDataTableValue || []}
                        isGlobalFilter={true}
                        pageSize={100}
                        emptyValueMess="エラーはありません。"
                        sortBy={[
                          {id: 'lineNo', desc: false}
                        ]}
                      />
                    </div>
                  </div>
                )
              }
            </BlockUI>
          )
        }
      </FormContents>
      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        title="確認メッセージ"
        onSubmit={() => (confirmOrCancelModal === 'confirm' ? handleSubmit() : handleCancelSubmit())}
        content={confirmOrCancelModal === 'confirm'
          ? 'インポート結果を登録します。よろしいですか？'
          : 'インポート結果の登録をキャンセルします。よろしいですか？'}
        submitText={confirmOrCancelModal === 'confirm' ? '保存' : 'はい'}
        closeText={confirmOrCancelModal === 'confirm' ? 'キャンセル' : 'いいえ'}
        items={[]}
      />
    </BlockUI>
  );
};
export default BankDepositImportLayout;
