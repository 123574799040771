import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';

import DescriptiionMSTImportForm from 'components/organismos/master/general/descriptionMSTForm/descriptiionMSTImportForm/DescriptiionMSTImportForm';

const DecriptionMSTImportPage: React.FC = () => (
  <SidebarTemplate pageTitle="摘要インポート">
    <DescriptiionMSTImportForm />
  </SidebarTemplate>
);


export default DecriptionMSTImportPage;
