import {
  useState, useCallback, useEffect,
} from 'react';

import {
  getDigestionItemsForPaidHolidayStatus,
  downloadPaidHolidayStatusCsv,
  getPaidHolidayStatusDetail,
  saveUseItemForPaidHolidayStatus,
} from 'api/holidayManagement';
import PaidHolidayStatusDomain from 'domain/master/holidayManagement/paidHolidayStatus';
import useToastNotification from 'hooks/useToastNotification';

export interface DetailItems {
  paidHolidayGrantId: string,
  staffCode: string,
  lastFirstGrantDaysNum: number,
  lastFirstGrantTimesNum: number,
  lastGrantDaysNum: number,
  lastGrantTimesNum: number,
  firstGrantDaysNum: number,
  firstGrantTimesNum: number,
  grantDaysNum: number,
  grantTimesNum: number,
  useLastFirstGrantFlg: boolean,
  useFirstGrantFlg: boolean,
  useCurrentGrantFlg: boolean,
  useLastGrantFlg: boolean,
}

export const useManagementInformationDomainForm = () => {
  const sessionStorageOrgCode = String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}1`)) !== 'null' ? (String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}1`))) : (sessionStorage.getItem('loginUser.orgCode') || '');
  const [
    paidHolidayStatusList, SetPaidHolidayStatusList,
  ] = useState<Array<PaidHolidayStatusDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useState(sessionStorageOrgCode);
  const managementEmploymentId = sessionStorage.getItem('management.employmentId') || '';
  const [employmentId, setOrgEmploymentId] = useState(managementEmploymentId);
  const sessionManagementExtinctionDaysStart = sessionStorage.getItem('management.extinctionDaysStart') || '';
  const [
    extinctionDaysStart, setExtinctionDaysStart,
  ] = useState(sessionManagementExtinctionDaysStart);
  const sessionManagementExtinctionDaysEnd = sessionStorage.getItem('management.extinctionDaysEnd') || '';
  const [extinctionDaysEnd, setExtinctionDaysEnd] = useState(sessionManagementExtinctionDaysEnd);
  const sessionManagementTargetGrantTenDays = sessionStorage.getItem('management.targetGrantTenDays') || '';
  const [targetGrantTenDays, setTargetGrantTenDays] = useState(sessionManagementTargetGrantTenDays === 'true');
  const [modalOpen, setModalOpen] = useState(false);
  const [detailItems, setDetailItems] = useState<DetailItems>({
    paidHolidayGrantId: '',
    staffCode: '',
    lastFirstGrantDaysNum: 0,
    lastFirstGrantTimesNum: 0,
    lastGrantDaysNum: 4,
    lastGrantTimesNum: 3,
    firstGrantDaysNum: 0,
    firstGrantTimesNum: 0,
    grantDaysNum: 0,
    grantTimesNum: 0,
    useLastFirstGrantFlg: false,
    useFirstGrantFlg: false,
    useCurrentGrantFlg: false,
    useLastGrantFlg: false,
  });
  const { successNotification, errorNotification } = useToastNotification();


  // データの取得
  const fetchData = useCallback(async () => {
    const response = await getDigestionItemsForPaidHolidayStatus(
      orgCode !== 'all' ? orgCode : '',
      extinctionDaysStart,
      extinctionDaysEnd,
      employmentId,
      targetGrantTenDays,
    );
    SetPaidHolidayStatusList(response.map((result) => new PaidHolidayStatusDomain(result)));
  }, [employmentId, extinctionDaysEnd, extinctionDaysStart, orgCode, targetGrantTenDays]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, employmentId, targetGrantTenDays]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  //
  const callPaidHolidayStatusDetail = useCallback(async (staffCode: string) => {
    try {
      await getPaidHolidayStatusDetail(staffCode).then((response: any) => {
        setDetailItems(response);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [errorNotification]);

  //
  const callSaveUseItemForPaidHolidayStatus = useCallback(async () => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const tmpDataPost = detailItems;
    (tmpDataPost as any).saveUser = tmpDataPost.staffCode;
    if (!(tmpDataPost as any).paidHolidayGrantId) {
      (tmpDataPost as any).paidHolidayGrantId = '';
    }
    try {
      await saveUseItemForPaidHolidayStatus(companyCode, tmpDataPost).then((response: any) => {
        setNoManager(!noManager);
        successNotification('更新しました。');
        setModalOpen(false);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [detailItems, errorNotification, noManager, successNotification]);

  return {
    paidHolidayStatusList,
    toggleNoManager,
    orgCode,
    setOrgCode,
    employmentId,
    setOrgEmploymentId,
    extinctionDaysStart,
    setExtinctionDaysStart,
    extinctionDaysEnd,
    setExtinctionDaysEnd,
    targetGrantTenDays,
    setTargetGrantTenDays,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    callPaidHolidayStatusDetail,
    callSaveUseItemForPaidHolidayStatus,
  };
};

export const useDownloadHolidayManagement = (
  orgCode: string,
  extinctionDaysStart: string,
  extinctionDaysEnd: string,
  employmentId: string,
  targetGrantTenDays: boolean,
) => {
  const downloadPaidHolidayStatus = useCallback((isDownloadAll?: boolean) => {
    downloadPaidHolidayStatusCsv(
      isDownloadAll ? '' : orgCode,
      extinctionDaysStart,
      extinctionDaysEnd,
      employmentId,
      targetGrantTenDays,
    );
  }, [employmentId, extinctionDaysEnd, extinctionDaysStart, orgCode, targetGrantTenDays]);

  return {
    downloadPaidHolidayStatus,
  };
};

export default {
  useManagementInformationDomainForm,
};
