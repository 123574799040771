/** @jsx jsx */
import { jsx } from '@emotion/core';
import BodyText from 'components/atoms/BodyText';
import Select from 'components/atoms/Select';
import IconTooltip from 'components/molecules/IconTooltip';
import Modal from 'components/molecules/Modal';
import ShiftPatternDetails from 'components/organismos/master/labor/shiftPattern/ShiftPatternDetails';
import { useBusinessAttendChoiceOptions } from 'hooks/useBusinessMasterOptions';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { useShiftDayCalendarContext, useShiftEditPanel } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';


const ShiftEditPanel: React.FC<{ orgCode?: string }> = ({ orgCode }) => {
  const {
    date, shiftEditPanelState, hideShiftEditPanelAction,
  } = useShiftDayCalendarContext();
  const {
    state, setter, actions, options, listTimes, hopeShift,
  } = useShiftEditPanel(shiftEditPanelState.shiftId);

  // 業務内容のオプションリスト
  const businessMasterOptions = useBusinessAttendChoiceOptions(state.staffCode, moment(date).format('YYYY/MM/DD'));

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  return (
    <React.Fragment>
      <Modal
        open={true}
        title="シフト編集"
        closeHandler={hideShiftEditPanelAction}
        submitText={checkUserRole?.editable === 1 ? '編集' : ''}
        onSubmit={shiftEditPanelState.checkBelongOrg === '1' ? () => {} : actions.editShiftAction}
        isShowButtonClose={false}
        optionalButtonText={checkUserRole?.editable === 1 ? '削除' : ''}
        optionalButtonSubmit={shiftEditPanelState.checkBelongOrg === '1' ? () => {} : () => setter.setDeleteModalShowFlag(true)}
        width="800px"
        isDisabledAll={shiftEditPanelState.checkBelongOrg === '1'}
      >
        <Row align="center">
          <Col md={12}>
            <BodyText size="sm">
              対象日
            </BodyText>
          </Col>
          <Col md={12}>
            <BodyText size="md">
              {state.targetDate}
            </BodyText>
          </Col>
        </Row>

        <Row align="center" css={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              スタッフコード
            </BodyText>
          </Col>
          <Col md={12}>
            <BodyText size="md">
              {state.staffCode}
            </BodyText>
          </Col>
        </Row>

        <Row align="center" css={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              対象者
            </BodyText>
          </Col>
          <Col md={12}>
            <BodyText size="md">
              {state.staffName}
            </BodyText>
          </Col>
        </Row>

        <Row align="center" style={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText>
              希望シフト
            </BodyText>
          </Col>
          <Col md={12}>
            <BodyText size="md">
              { hopeShift ? hopeShift.hopeShiftPatternType === 2 ? (
                <div>
                  <span style={{ color: '#E95562' }}>出勤できません</span>
                  { hopeShift.note != null && hopeShift.note !== '' ? <IconTooltip iconType="comment" text={hopeShift.note} color="blue" inlineBlock={true} /> : '' }
                </div>
              ) : hopeShift.hopeShiftPatternType === 1 || hopeShift.hopeShiftPatternType === 3 ? (
                <div>
                  <span>{`${moment(hopeShift.startTime).format('HH:mm')} - ${moment(hopeShift.endTime).format('HH:mm')}`}</span>
                  { hopeShift.note != null && hopeShift.note !== '' ? <IconTooltip iconType="comment" text={hopeShift.note} color="blue" inlineBlock={true} /> : '' }
                </div>
              ) : '未提出' : '未提出'}
            </BodyText>
          </Col>
        </Row>

        <Row align="center" css={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              勤務日種別
            </BodyText>
          </Col>
          <Col md={12}>
            <Select
              name="shiftEditAttendType"
              value={state.attendType}
              setValue={setter.setAttendType}
              options={options.attendTypeOptions}
              isDisabled={shiftEditPanelState.checkBelongOrg === '1'}
            />
          </Col>
        </Row>

        <Row align="center" css={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              出勤店舗
            </BodyText>
          </Col>
          <Col md={12}>
            {state.orgName}
          </Col>
        </Row>

        <Row align="center" style={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              シフトパターン
            </BodyText>
          </Col>
          <Col md={12}>
            <Select
              name="shiftEditBusinessMasterOptions"
              value={state.shiftPatternId}
              setValue={(val) => setter.setShiftPatternId(val)}
              options={options.shiftPatternOptions}
              isDisabled={shiftEditPanelState.checkBelongOrg === '1' || state.attendType !== '0'/* 勤務日種別が0:通常の場合のみ */}
            />
          </Col>
        </Row>

        {/* シフト時間 */}
        <Row align="center" style={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              シフト時間
            </BodyText>
          </Col>
          <Col md={12}>
            <ShiftPatternDetails
              shiftPatternDetailsList={state.shiftDetailList}
              setShiftPatternDetailsList={setter.setShiftDetailList}
              attendBusinessOptions={businessMasterOptions}
              listTimes={listTimes}
              isDisableAll={shiftEditPanelState.checkBelongOrg === '1' || !!state.shiftPatternId || state.isPublicHoliday}
              menuPlacement="top"
              orgCode={orgCode}
            />
          </Col>
        </Row>
      </Modal>

      {/* 削除モーダル */}
      <Modal
        open={state.showDeleteConfirm}
        title="シフト削除"
        closeHandler={() => setter.setDeleteModalShowFlag(false)}
        submitText={checkUserRole?.editable === 1 ? '削除' : ''}
        onSubmit={actions.deleteShiftAction}
        closeText="キャンセル"
        width="800px"
      >
        削除してよろしいですか
      </Modal>
    </React.Fragment>
  );
};

export default ShiftEditPanel;
