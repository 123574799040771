/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Container, Row, Col } from 'react-grid-system';

import productColor, { grayScale, utilityColor } from 'components/styles';
import BodyText from 'components/atoms/BodyText';
import BlockUI from 'components/molecules/BlockUi';
import IconTooltip from 'components/molecules/IconTooltip';
import ShiftRow from './ShiftRow';
import TimeScale from './TimeScale';
import ShiftAddButton from './ShiftAddButton';
import ShiftAddPanel from './ShiftAddPanel';

import { useInitialShiftDay, shiftDayCalendarContext } from './hooks';
import ShiftEditPanel from './ShiftEditPanel';

const styles = {
  wrapper: css({
    marginTop: '40px',
    overflowX: 'scroll',
  }),
};

const ShiftDayCalendar: React.FC<{
  orgCode: string;
  date: Date;
  dispStartHour: number;
  dispHour: number;
  businessIds: Array<string>;
}> = ({
  orgCode, date, dispStartHour, dispHour, businessIds,
}) => {
  const context = useInitialShiftDay(orgCode, date, dispStartHour, dispHour, businessIds);
  const {
    loadingStaffCodes, staffDailyShifts, shiftAddPanelState, shiftEditPanelState,
  } = context;
  return (
    <shiftDayCalendarContext.Provider value={context}>
      <div css={styles.wrapper}>
        <Container fluid={true}>
          <Row>
            <Col md={2} />
            <Col md={10}>
              <TimeScale />
            </Col>
          </Row>

          {staffDailyShifts.map((shift) => (
            <BlockUI blocking={loadingStaffCodes.includes(shift.staffCode)}>
              <Row
                key={shift.staffName}
                css={{
                  padding: '6px 0',
                }}
              >
                <Col
                  md={2}
                  title={shift.staffName}
                  css={{
                    display: 'flex',
                    borderBottom: `1px solid ${grayScale.gray10}`,
                  }}
                >
                  <div
                    css={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    <BodyText>
                      {shift.staffName}
                    </BodyText>
                  </div>
                  {shift.hopeShift && shift.hopeShift.note && shift.hopeShift.note.length > 0 && (
                    <div
                      css={{ marginLeft: '10px' }}
                    >
                      <IconTooltip
                        iconType="comment"
                        text={shift.hopeShift.note || ''}
                        color={productColor.primary}
                        customCss={css({ margin: '0' })}
                      />
                    </div>
                  )}
                  {shift.isAlert && (
                    <div
                      css={{ marginLeft: '10px' }}
                    >
                      <IconTooltip
                        iconType="warning"
                        text={shift.alertMessage || ''}
                        color={utilityColor.yellow}
                        customCss={css({ margin: '0' })}
                      />
                    </div>
                  )}
                </Col>
                <Col md={10}>
                  <ShiftRow
                    staffDailyShift={shift}
                    orgCode={orgCode}
                  />
                </Col>
              </Row>
            </BlockUI>
          ))}

          <Row>
            <Col md={1} />
            <Col md={11} css={{ textAlign: 'center' }}>
              <ShiftAddButton />
            </Col>
          </Row>
        </Container>
      </div>

      {/* シフト追加パネル */}
      {shiftAddPanelState.isShow && (
        <ShiftAddPanel />
      )}
      {/* シフト編集パネル */}
      {shiftEditPanelState.isShow && (
        <ShiftEditPanel orgCode={orgCode} />
      )}
    </shiftDayCalendarContext.Provider>
  );
};

export default ShiftDayCalendar;
