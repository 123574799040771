/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

const FlexBox: React.FC<{
  alignItems?: 'center'|'flex-start'|'flex-end'; // 適宜増やす
  direction?: 'row'|'column'
  customStyle?: SerializedStyles
}> = ({
  children, customStyle, alignItems = 'center', direction = 'row',
}) => (
  <div css={css({
    display: 'flex',
    alignItems,
    flexDirection: direction,
  }, customStyle)}
  >
    {children}
  </div>
);

export default FlexBox;
