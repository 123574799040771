import React from 'react';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import PrimaryButton from 'components/atoms/Button';

const ButtonNavigation: React.FC<{
}> = () => (
  <FlexBox customStyle={css({ paddingRight: '12px', alignItems: 'right' })}>
    <FlexBoxItem marginLeft="auto">
      <Link to="/masterEmploymentForm/add">
        <PrimaryButton
          text="新規雇用形態作成"
        />
      </Link>
    </FlexBoxItem>
  </FlexBox>
);

export default ButtonNavigation;
