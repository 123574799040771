import React, { useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import moment from 'moment';

import Modal from 'components/molecules/Modal';
import BodyText from 'components/atoms/BodyText';
import Select from 'components/atoms/Select';
import ShiftPatternDetails from 'components/organismos/master/labor/shiftPattern/ShiftPatternDetails';

import { useBusinessAttendChoiceOptions } from 'hooks/useBusinessMasterOptions';
import { useOrganizationTrees } from 'hooks/useOrgTreesOptions';
import IconTooltip from 'components/molecules/IconTooltip';
import { useHistory } from 'react-router-dom';
import { useShiftDayCalendarContext, useShiftAddPanel } from './hooks';
import OrganizationFilteredTree from '../organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const ShiftAddPanel: React.FC = () => {
  const {
    orgCode, date, shiftAddPanelState, hideShiftAddPanelAction,
  } = useShiftDayCalendarContext();
  const {
    state, setter, actions, options, listTimes, hopeShift,
  } = useShiftAddPanel();

  // 店舗一覧
  // const orgOptions = useAllOrgTrees();
  const functionType = 2;
  const orgOptions = useOrganizationTrees(
    functionType,
    undefined,
    undefined,
    shiftAddPanelState.staff?.staffCode || state.staffCode,
    false,
  );
  // 業務オプション一覧
  const businessMasterOptions = useBusinessAttendChoiceOptions(
    shiftAddPanelState.staff?.staffCode || state.staffCode, moment(date).format('YYYY/MM/DD'),
  );

  useEffect(() => {
    if (shiftAddPanelState.staff && shiftAddPanelState.staff !== undefined) {
      let fetchAttendType = 0;
      if (shiftAddPanelState.staff.shiftDailyList[0].holidayId) {
        fetchAttendType = shiftAddPanelState.staff.shiftDailyList[0].isLegal ? 1 : 2;
      }
      setter.setAttendType(String(fetchAttendType));
    }
  }, [setter, shiftAddPanelState.staff]);

  const history = useHistory();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === state.targetOrgCode);

  const checkUserRole = state.targetOrgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  return (
    <Modal
      open={true}
      title="シフト追加"
      closeHandler={hideShiftAddPanelAction}
      submitText={checkUserRole && checkUserRole.editable === 1 ? '実行' : ''}
      onSubmit={actions.addShiftAction}
      isShowButtonClose={false}
      width="800px"
      isDisabledAll={actions.loadingAddShift}
    >
      <Row align="center">
        <Col md={12}>
          <BodyText>
            対象日
          </BodyText>
        </Col>
        <Col md={12}>
          <BodyText size="md">
            {moment(shiftAddPanelState.defaultStartDate).format('YYYY/MM/DD')}
          </BodyText>
        </Col>
      </Row>

      <Row align="center" style={{ marginTop: '8px' }}>
        {/* <Col md={12}>
          <BodyText>
            店舗
          </BodyText>
        </Col> */}
        <Col md={12}>
          <BodyText size="md">
            {shiftAddPanelState.staff?.staffName ? (
              <BodyText>
                {orgOptions.find((orgOption) => orgOption.value === orgCode)?.label}
              </BodyText>
            ) : (
              <OrganizationFilteredTree
                functionType={functionType}
                staffCode=""
                orgLabel="店舗"
                targetdayForm={date}
                targetdayTo={date}
                initOrgValue={state.targetOrgCode}
                orgCallback={(args: string | Array<string>) => {
                  setter.setTargetOrgCode(String(args));
                }}
                showFilterCategory={true}
                notSetLoginStaffCode={true}
              />
            )}
          </BodyText>
        </Col>
      </Row>

      <Row align="center" style={{ marginTop: '8px' }}>
        <Col md={12}>
          <BodyText>
            対象者
          </BodyText>
        </Col>
        <Col md={12}>
          <BodyText size="md">
            {shiftAddPanelState.staff?.staffName || (
              <Select
                name="staffOptions"
                value={state.staffCode}
                setValue={setter.setStaffCode}
                options={options.shiftDailyStaffOptions}
              />
            )}
          </BodyText>
        </Col>
      </Row>

      <Row align="center" style={{ marginTop: '8px' }}>
        <Col md={12}>
          <BodyText>
            希望シフト
          </BodyText>
        </Col>
        <Col md={12}>
          <BodyText size="md">
            {
              hopeShift
                ? hopeShift.hopeShiftPatternType === 2 ? (
                  <div>
                    <span style={{ color: '#E95562' }}>出勤できません</span>
                    {' '}
                    {hopeShift.note !== '' ? <IconTooltip iconType="comment" text={hopeShift.note} color="blue" inlineBlock={true} /> : ''}
                  </div>
                ) : hopeShift.hopeShiftPatternType === 1 || hopeShift.hopeShiftPatternType === 3
                  ? (
                    <div>
                      <span>{`${moment(hopeShift.startTime).format('HH:mm')} - ${moment(hopeShift.endTime).format('HH:mm')}`}</span>
                      {' '}
                      {hopeShift.note !== '' ? <IconTooltip iconType="comment" text={hopeShift.note} color="blue" inlineBlock={true} /> : ''}
                    </div>
                  )
                  : '未提出' : '未提出'
            }
          </BodyText>
        </Col>
      </Row>

      {shiftAddPanelState.staff?.staffName ? null : (
        <Row align="center" css={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              勤務日種別
            </BodyText>
          </Col>
          <Col md={12}>
            <Select
              name="shiftEditAttendType"
              value={state.attendType}
              setValue={setter.setAttendType}
              options={options.attendTypeOptions}
            />
          </Col>
        </Row>
      )}

      <Row align="center" style={{ marginTop: '8px' }}>
        <Col md={12}>
          <BodyText>
            シフトパターン
          </BodyText>
        </Col>
        <Col md={12}>
          <Select
            name="shiftAddBusinessMasterOptions"
            value={state.shiftPatternId}
            setValue={(val) => setter.setShiftPatternId(val)}
            options={options.shiftPatternOptions}
            isDisabled={state.attendType !== '0'/* 勤務日種別が0:通常の場合のみ */}
          />
        </Col>
      </Row>

      <Row align="center" style={{ marginTop: '8px' }}>
        <Col md={12}>
          <BodyText size="sm">
            シフト時間
          </BodyText>
        </Col>
        <Col md={12}>
          <ShiftPatternDetails
            shiftPatternDetailsList={state.shiftDetailsList}
            setShiftPatternDetailsList={setter.setShiftDetailsList}
            attendBusinessOptions={businessMasterOptions}
            listTimes={listTimes}
            isDisableAll={!!state.shiftPatternId || state.isPublicHoliday}
            menuPlacement="top"
            orgCode={orgCode}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ShiftAddPanel;
