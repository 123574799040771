export interface SalesExpensesMST {
    id: {
      companyCode: string,
      expensesCode: string,
    },
    expensesName: string,
    dispOrder: number,
    expensesClassification: string,
    applyStartDate: Date,
    applyEndDate: Date,
    createDate: Date,
    createUser: string,
    updateDate: Date,
    updateUser: string,
  }

export default class SalesExpensesMSTDomain {
  constructor(private rawData: SalesExpensesMST) {
    // do nothing
  }

  static generateInitial(): SalesExpensesMSTDomain {
    return new SalesExpensesMSTDomain({
      id: {
        companyCode: '',
        expensesCode: '',
      },
      expensesName: '',
      dispOrder: 0,
      expensesClassification: '',
      applyStartDate: new Date(),
      applyEndDate: new Date(),
      createDate: new Date(),
      createUser: '',
      updateDate: new Date(),
      updateUser: '',
    });
  }

  getRawData(): SalesExpensesMST {
    return this.rawData;
  }

  get companyCode(): string {
    return this.rawData.id.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.id.companyCode = companyCode;
  }

  get expensesCode(): string {
    return this.rawData.id.expensesCode;
  }

  set expensesCode(expensesCode: string) {
    this.rawData.id.expensesCode = expensesCode;
  }

  get expensesName(): string {
    return this.rawData.expensesName;
  }

  set expensesName(expensesName: string) {
    this.rawData.expensesName = expensesName;
  }

  get dispOrder(): number {
    return this.rawData.dispOrder;
  }

  set dispOrder(dispOrder: number) {
    this.rawData.dispOrder = dispOrder;
  }

  get expensesClassification(): string {
    return this.rawData.expensesClassification;
  }

  set expensesClassification(expensesClassification: string) {
    this.rawData.expensesClassification = expensesClassification;
  }

  get applyStartDate(): Date {
    return this.rawData.applyStartDate;
  }

  set applyStartDate(applyStartDate: Date) {
    this.rawData.applyStartDate = applyStartDate;
  }

  get applyEndDate(): Date {
    return this.rawData.applyEndDate;
  }

  set applyEndDate(applyEndDate: Date) {
    this.rawData.applyEndDate = applyEndDate;
  }

  get createDate(): Date {
    return this.rawData.createDate;
  }

  set createDate(createDate: Date) {
    this.rawData.createDate = createDate;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateDate(): Date {
    return this.rawData.updateDate;
  }

  set updateDate(updateDate: Date) {
    this.rawData.updateDate = updateDate;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }
}
