import React, { SVGProps } from 'react';

const Money: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M10 4a6.5 6.5 0 110 13 6.5 6.5 0 010-13zm0 1.25a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zm2.347 1.98c.287.191.365.58.173.867l-1.186 1.778h.166c.314 0 .574.231.618.533l.007.092c0 .345-.28.625-.625.625h-.887c.008.04.012.082.012.125v.375h.875c.314 0 .574.231.618.533l.007.092c0 .345-.28.625-.625.625h-.875v.875a.625.625 0 01-.533.618l-.092.007a.625.625 0 01-.625-.625v-.875H8.5a.625.625 0 01-.618-.533l-.007-.092c0-.345.28-.625.625-.625h.875v-.375c0-.043.004-.085.012-.125H8.5a.625.625 0 110-1.25h.165L7.48 8.097a.625.625 0 01.093-.804l.08-.063a.625.625 0 01.867.173L10 9.622l1.48-2.219a.625.625 0 01.867-.173z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Money;
