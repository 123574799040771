import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import SmartHRSyncReportManagement from 'components/pages/master/storeManagement/smartHRSyncReport/smartHRSyncReportManagement/index';

const SmartHRSyncReportPage = () => (
  <SidebarTemplate pageTitle="SmartHR 連携情報">
    <SmartHRSyncReportManagement />
  </SidebarTemplate>
);
export default SmartHRSyncReportPage;

