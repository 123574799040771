/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';

import { productColor, grayScale, utilityColor } from 'components/styles';

const Toggle: React.FC<{
  on: boolean;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  on, name, value, onChange,
}) => (
  <label
    htmlFor={name}
    css={{
      display: 'flex',
      borderRadius: '9999px',
      height: '24px',
      width: '48px',
      backgroundColor: on ? productColor.primary : grayScale.gray40,
      justifyContent: on ? 'flex-end' : 'flex-start',
    }}
  >
    <input
      css={{ display: 'none' }}
      type="checkbox"
      id={name}
      name={name}
      value={value}
      checked={on}
      onChange={onChange}
    />
    <div
      css={{
        borderRadius: '9999px',
        boxSizing: 'border-box',
        width: '24px',
        backgroundColor: utilityColor.white,
        border: `4px solid ${on ? productColor.primary : grayScale.gray40}`,
      }}
    />
  </label>
);

export default Toggle;
