import React, { SVGProps } from 'react';

const CheckboxOn: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <g transform="translate(4 4)">
        <rect fill="#333" width={12} height={12} rx={2} />
        <path
          d="M3.467 5.585a.625.625 0 10-.934.83l2 2.25c.244.275.67.28.922.013l4-4.25a.625.625 0 00-.91-.856L5.013 7.324l-1.546-1.74z"
          fill="#FFF"
        />
      </g>
    </g>
  </svg>
);

export default CheckboxOn;
