import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import OperationalReportListForm from 'components/organismos/master/sales/OperationalReportListForm';
 
 const OperationalReportListPage = () => (
   <SidebarTemplate pageTitle='稼働状況一覧'>
     <OperationalReportListForm />
   </SidebarTemplate>
 );
 
 export default OperationalReportListPage;