/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { grayScale, utilityColor } from 'components/styles';
import CircleIconButton from 'components/molecules/CircleIconButton';
import Pagenation, { PaginationProps } from './Pagination';
import GlobalFilter, { GlobalFilterProps } from './GlobalFilter';

const styles = {
  controllbar: css({
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    // whiteSpace: 'nowrap',
    // overflowX: 'auto',
  }),
  actionButton: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  pagenation: css({
    marginLeft: 'auto',
  }),
};

interface ControllbarProps {
  total?:number;
  pagination: PaginationProps;
  filter: GlobalFilterProps;
  isGlobalFilter: boolean;
  tableId: number;
  gotoPage: any;
}

const Controllbar: React.FC<ControllbarProps> = ({
  pagination, filter, isGlobalFilter, tableId, gotoPage,
}) => {

  if (pagination.pageIndex + 1 > pagination.pageCount) {
    gotoPage(0)
  }

  return (
    <div css={styles.controllbar}>
      {isGlobalFilter ? (
        <div css={{ minWidth: '140px' }}>
          <div css={styles.actionButton}>
            <CircleIconButton
              iconType="search"
              iconColor={grayScale.gray100}
              borderColor={utilityColor.transparent}
              onClick={() => console.log('検索')}
            />
            <GlobalFilter
              state={filter.state}
              setGlobalFilter={filter.setGlobalFilter}
              tableId={tableId}
            />
          </div>
        </div>
      ) : ''}
      <div css={styles.pagenation}>
        <Pagenation
          total={pagination.total}
          pageSize={pagination.pageSize}
          pageIndex={pagination.pageIndex}
          pageCount={pagination.pageCount}
          nextPage={pagination.nextPage}
          canNextPage={pagination.canNextPage}
          previousPage={pagination.previousPage}
          canPreviousPage={pagination.canPreviousPage}
          setPageSize={pagination.setPageSize}
          gotoPage={pagination.gotoPage}
          tableId={tableId}
        />
      </div>
    </div>
  )
}


export default Controllbar;
