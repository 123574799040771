import moment from 'moment';
import ApiClient from './ApiClient';


const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export const downloadCSVTimList = async (params: any, appendUrl: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsv(`/v1/jsp/sj/timelist/csv/${companyCode}?${appendUrl}`, params, getDownloadCsvFileName('タイムリストデータ出力_'));
};

export default downloadCSVTimList;
