import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

export const downloadCSV = async (
  fileName: string,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsv(`/v2/journalMst/outputCsv/${companyCode}`, {}, getDownloadCsvFileName(`${fileName}_`));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v2/journalMst/csv/import/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export const getJourneyMasterList = async (): Promise<any> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await ApiClient.get(`/v2/journalMst/list/${companyCode}`, {});
    return response.data;
};

export const getJournalMasterDetail = async (
    journalId: string): Promise<any> => {
    const params = {
      journalId
    };
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await ApiClient.get(`/v2/journalMst/${companyCode}`, params);
    return response.data;
};

export const saveJournalMasterDetail = async (
    journalId: string, data: any) => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const params: any = {
      companyCode,
      createUser: staffCode,
      updateUser: staffCode,
      journalId: '',
      journalCode: data.journalCode,
      journalName: data.journalName,
      debitAccountTitleId: data.debitAccountTitleId,
      debitAccountTitleSubId: data.debitAccountTitleSubId,
      debitTaxType: data.debitTaxType,
      debitTaxRate: data.debitTaxRate,
      creditAccountTitleId: data.creditAccountTitleId,
      creditAccountTitleSubId: data.creditAccountTitleSubId,
      creditTaxType: data.creditTaxType,
      creditTaxRate: data.creditTaxRate
    };
    if (journalId && journalId !== 'undefined') {
      params.journalId = journalId;
    }
    const response = await ApiClient.post(`/v2/journalMst/save/${companyCode}`, {}, params);
    return response.data;
  };

export const deleteJournalMaster = async (journalId: string): Promise<any> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const params = {
      journalId,
    };

    const response = await ApiClient.delete(`/v2/journalMst/delete/${companyCode}`, params);
    return response.data;
};

export type Error = {
  error: string;
  defaultMessage: string;
}

export default { };
