import { useState, useEffect, useCallback } from 'react';

import {
  searchDate, getListClosingDate, getListEmployment, getListOutputLayout, getAchievementConfirmV3
} from 'api/achievementConfirm';
import AttendHeadQuartersFinalDomain from 'domain/master/attend/attendHeadquartersFinal';
import moment from 'moment';
import { AnyRecord } from 'dns';
import useToastNotification from 'hooks/useToastNotification';

export const useAchievementConfirmList = (
  viewPeriod: string,
  setAllConfirm: any,
  setAllCsvOutput: any,
  targetMonth: Date,
  targetDateFrom: Date,
  targetDateTo: Date,
  selectedEmploymentId: string,
  selectedClosingDate: string,
  setToastMessage: any,
  setToastModalOpen: any,
  categoryArr: any,
  orgCode: string
) => {
  const [achievementConfirmList, setAchievementConfirmList] = useState<Array<AttendHeadQuartersFinalDomain>>([]);
  const [isLoading, setLoading] = useState(false);
  const [achievementConfirmV3, setAchievementConfirmV3] = useState<any>([])
  const functionType = 2
  const { successNotification, errorNotification } = useToastNotification();
  const [errors, setErrors] = useState<Array<string> | undefined>([]);

  const fetchAchievementConfirmV3 = useCallback(async (targetDateFrom, targetDateTo, viewPeriod, employmentId, closingDate, orgCode, functionType, categoryArr) => {
    setLoading(true);
    await getAchievementConfirmV3(targetDateFrom, targetDateTo, viewPeriod, employmentId, closingDate, orgCode, functionType, categoryArr).then((response: any) => {
      const tmpTargetDateFrom = new Date(targetDateFrom);
      const tmpTargetDateTo = new Date(targetDateTo);
      const startDayNextYear = new Date(tmpTargetDateFrom.getFullYear() + 1, tmpTargetDateFrom.getMonth(), tmpTargetDateFrom.getDate());
      if (tmpTargetDateTo.getTime() >= startDayNextYear.getTime()) {
        setToastMessage('対象期間の範囲は１年以内で入力してください');
        setToastModalOpen(true);

        setAchievementConfirmList([]);
        setAllConfirm(false);
        setAllCsvOutput(false);
      } else {
        setAchievementConfirmList(
          response.confirmList.map((result: any) => new AttendHeadQuartersFinalDomain(result)),
        );
        setAllConfirm(response.allConfirm);
        setAllCsvOutput(response.allCsvOutput);
      }

      setLoading(false);

      sessionStorage.removeItem('headquartersFinal.searchTargetDateFrom');
      sessionStorage.removeItem('headquartersFinal.searchTargetDateTo');
      sessionStorage.removeItem('headquartersFinal.searchTargetMonth');
      sessionStorage.removeItem('headquartersFinal.searchEmploymentId');
      sessionStorage.removeItem('headquartersFinal.searchOutputLayout');
      sessionStorage.removeItem('headquartersFinal.viewPeriod');
      sessionStorage.removeItem('headquartersFinal.selClosingDate');
      sessionStorage.removeItem('headquartersFinal.selectOrgCode');
      sessionStorage.removeItem('headquartersFinal.selectOrgName');

      sessionStorage.removeItem('headquartersFinal.monthlyList.searchTargetDateFrom');
      sessionStorage.removeItem('headquartersFinal.monthlyList.searchTargetDateTo');
      sessionStorage.removeItem('headquartersFinal.monthlyList.selClosingDate');
      sessionStorage.removeItem('headquartersFinal.monthlyList.searchEmploymentId');
      sessionStorage.removeItem('headquartersFinal.monthlyList.selectOrgCode');
      sessionStorage.removeItem('headquartersFinal.monthlyList.selectOrgName');
      sessionStorage.removeItem('headquartersFinal.monthlyList.attendFlag');

    }).catch((error: any) => {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else if (error.response && error.response.data && Array.isArray(error.response.data)) {
        const normalError = error.response.data;
        let stringNormalError = '';
        normalError.map((element: any) => {
          stringNormalError += `${element.defaultMessage} \n`;
          return stringNormalError;
        });
        errorNotification(stringNormalError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    })
  }, [])

  useEffect(() => {
    let targetDateFromStr = '';
    let targetDateToStr = '';
    if (viewPeriod === 'monthly') {
      targetDateFromStr = moment(new Date(targetMonth)).format('YYYY-MM-01');
      targetDateToStr = moment(new Date(targetMonth)).format('YYYY-MM-01');
    } else {
      targetDateFromStr = moment(new Date(targetDateFrom)).format('YYYY-MM-DD');
      targetDateToStr = moment(new Date(targetDateTo)).format('YYYY-MM-DD');
    }
    setLoading(true);
    fetchAchievementConfirmV3(targetDateFromStr, targetDateToStr, viewPeriod, selectedEmploymentId, selectedClosingDate, orgCode, functionType, categoryArr)
  }, [orgCode, functionType, categoryArr, selectedClosingDate, selectedEmploymentId, setAllConfirm, setAllCsvOutput, setToastMessage, setToastModalOpen, targetDateFrom, targetDateTo, targetMonth, viewPeriod]);
  return {
    achievementConfirmList,
    setAchievementConfirmList,
    isLoading,
    setLoading,
    achievementConfirmV3
  };
};

export const useGetSearchDateClosing = (
  setTargetDateFrom: any, setTargetDateTo: any, setTargetMonth: any,
) => {
  useEffect(() => {
    if (sessionStorage.getItem('headquartersFinal.searchTargetDateTo')) {
      return;
    }
    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    searchDate({ staffCode }).then((response: any) => {
      setTargetDateFrom(new Date(response[0]));
      setTargetDateTo(new Date(response[1]));
      setTargetMonth(new Date(response[1]));
    });
  }, [setTargetDateFrom, setTargetDateTo, setTargetMonth]);
};

export const useGetClosingList = (viewPeriod: string, targetMonth: Date, targetDateTo: Date) => {
  const [closingDateList, setClosingDateList] = useState([]);
  useEffect(() => {
    let targetDate = '';
    if (viewPeriod === 'monthly') {
      targetDate = moment(new Date(targetMonth)).format('YYYY-MM-01');
    } else {
      targetDate = moment(new Date(targetDateTo)).format('YYYY-MM-DD');
    }

    getListClosingDate({ targetDate }).then((response: any) => {
      setClosingDateList(response.map((item: any) => ({
        value: String(item.closingDate),
        label: item.closingName,
      })));
    });
  }, [targetDateTo, targetMonth, viewPeriod]);
  return {
    closingDateList,
    setClosingDateList,
  };
};

export const useGetEmployments = (
  viewPeriod: string, targetMonth: Date, targetDateFrom: Date, targetDateTo: Date, selClosingDate: string,
) => {
  const [employmentList, setEmploymentList] = useState([]);
  useEffect(() => {
    let targetDateFromStr = '';
    let targetDateToStr = '';
    let selClosingDateStr = '';
    if (viewPeriod === 'monthly') {
      targetDateFromStr = moment(new Date(targetMonth)).format('YYYY-MM-01');
      targetDateToStr = moment(new Date(targetMonth)).format('YYYY-MM-01');
    } else {
      targetDateFromStr = moment(new Date(targetDateFrom)).format('YYYY-MM-DD');
      targetDateToStr = moment(new Date(targetDateTo)).format('YYYY-MM-DD');
    }
    setEmploymentList([]);
    if (selClosingDate !== '-1') {
      selClosingDateStr = selClosingDate;
      const params = {
        targetDateFrom: targetDateFromStr,
        targetDateTo: targetDateToStr,
        closingDate: selClosingDateStr,
      };

      getListEmployment(params).then((response: any) => {
        if (response.length) {
          setEmploymentList(response.length && response.map((item: any) => ({
            value: String(item.employmentId),
            label: `${item.employmentCode} ${item.employmentName}`,
          })));
        }
      });
    }
  }, [selClosingDate, targetDateFrom, targetDateTo, targetMonth, viewPeriod]);
  return {
    employmentList,
    setEmploymentList,
  };
};

export const useGetOutputLayouts = (setOutputLayoutId: any) => {
  const [outputLayoutList, setOutputLayoutList] = useState([]);
  useEffect(() => {
    getListOutputLayout().then((response: any) => {
      setOutputLayoutList([]);
      if (response.length) {
        setOutputLayoutList(response.map((item: any) => ({
          value: String(item.layoutId),
          label: `${item.layoutCode} ${item.layoutName}`,
        })));
        setOutputLayoutId(sessionStorage.getItem('headquartersFinal.searchOutputLayout') || response[0].layoutId);
      }
    });
  }, [setOutputLayoutId]);
  return {
    outputLayoutList,
    setOutputLayoutList,
  };
};

export default {
  useAchievementConfirmList,
  useGetSearchDateClosing,
  useGetEmployments,
};
