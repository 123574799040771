/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { createOrUpdateAdvertisingOrganization, getListAdvertisingPlanOrganization } from 'api/advertising';
import useToastNotification from 'hooks/useToastNotification';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

export interface StoreDetail {
    advertisingPlanCode: string,
    applicationStartDate: any,
    applicationEndDate: any,
    optionAdvertisingName:any,
    undecided: boolean,
    isShow: boolean,
    isDisable: boolean,
    isDelete: boolean,
    hideTrash: boolean
}

export const useCreateSettingForm = () => {
  const { editStore, checkParam } = useParams();
  const toggle = sessionStorage.getItem('toggle');
  const fromdate = new Date();
  const dateTo = (new Date(fromdate.getFullYear(), fromdate.getMonth(), fromdate.getDate()));
  const [targetDateFrom, setTargetDateFrom] = useState(null);
  const [targetDateTo, setTargetDateTo] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [hideOrg, setHideOrg] = useState<boolean>(false);
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || '');
  const { successNotification, errorNotification } = useToastNotification();
  const [categoryArray, setCategoryAr] = useState<Array<any> | []>(
    [],
  );
  const history = useHistory();
  const functionType = 1;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const initOptionAdName = localStorage.getItem('OptionAdvertising') || '';
  const optionAdName = JSON.parse(initOptionAdName);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [detailList, setDetailList] = useState<Array<StoreDetail>>([{
    advertisingPlanCode: '',
    applicationStartDate: targetDateFrom,
    applicationEndDate: targetDateTo,
    optionAdvertisingName: optionAdName,
    undecided: true,
    isShow: true,
    isDisable: true,
    isDelete: false,
    hideTrash: false,
  }]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);


  // Clone object on click
  const handleAddStoreDetails = useCallback(() => {
    const tmpPatternDetails = [...detailList];
    tmpPatternDetails.push({
      advertisingPlanCode: '',
      applicationStartDate: targetDateFrom,
      applicationEndDate: targetDateTo,
      optionAdvertisingName: optionAdName,
      undecided: true,
      isShow: false,
      isDisable: true,
      isDelete: false,
      hideTrash: false,
    });
    tmpPatternDetails.filter((item: any, index: number) => {
      if (index === 0) {
        item.isShow = false;
      }
    });
    setDetailList(tmpPatternDetails);
  }, [detailList, optionAdName, targetDateFrom, targetDateTo]);

  const handleDateForm = useCallback((
    indexPattern:number,
    namevalue:string,
    date:Date,
  ) => {
    const tmpList = [...detailList];
    // eslint-disable-next-line array-callback-return
    tmpList.filter((item: any, index: number) => {
      if (index === indexPattern) {
        item[namevalue] = date;
      }
    });
    setDetailList(tmpList);
  }, [detailList]);

  // Delete object on click
  // eslint-disable-next-line consistent-return
  const handleDeleteShift = useCallback((indexPattern:number, isDelete:string) => {
    const tmpList = [...detailList];
    if (editStore) {
      // eslint-disable-next-line consistent-return
      tmpList.filter((item: any, index: number) => {
        if (index === indexPattern) {
          item[isDelete] = true && !item[isDelete];
        }
      });
      setDetailList(tmpList);
      return true;
    }
  }, [detailList, editStore]);

  const handleDeleteShiftTrash = useCallback((indexPattern:number) => {
    const tmpList = [...detailList];
    tmpList.splice(indexPattern, 1);
    if (tmpList.length === 1) {
      tmpList.filter((item: any, index: number) => {
        if (index === 0) {
          item.isShow = true;
        }
      });
    }
    setDetailList(tmpList);
  }, [detailList]);

  // Checkbox action
  const handleChangeCheckboxNextDay = useCallback((
    indexPattern: number,
    nameCheck: string,
    isDisable: string,
  ) => {
    const tmpList = [...detailList];
    tmpList.filter((item: any, index: number) => {
      if (index === indexPattern) {
        if (item[nameCheck] === true) {
          // eslint-disable-next-line no-param-reassign
          item[nameCheck] = false;
          // eslint-disable-next-line no-param-reassign
          item[isDisable] = false;
        } else {
          // eslint-disable-next-line no-param-reassign
          item[nameCheck] = true;
          // eslint-disable-next-line no-param-reassign
          item[isDisable] = true;
        }
        return true;
      }
      return false;
    });
    setDetailList(tmpList);
  }, [detailList]);

  // Get Value on selectBox
  const handleAdvertising = useCallback((data:string, indexPattern: number, nameCheck: string) => {
    const tmpList = [...detailList];
    tmpList.filter((item: any, index: number) => {
      if (index === indexPattern) {
        // eslint-disable-next-line no-param-reassign
        item[nameCheck] = data;
        return true;
      }
      return false;
    });
    setDetailList(tmpList);
  }, [detailList]);

  useEffect(() => {
    if (editStore) {
      setHideOrg(true);
      setOrgCode(editStore);
      getListAdvertisingPlanOrganization(`${editStore}`, checkParam, targetDateFrom, targetDateTo).then((dataList: Array<any>) => {
        const tmpPatternDetails = dataList.map((value:any, key:number) => ({
          advertisingPlanCode: value.advertisingPlanMstCode,
          applicationStartDate: new Date(value.applyStartDay),
          applicationEndDate: value.applyEndDay.substring(0, 4) !== '9999' ? new Date(value.applyEndDay) : new Date(dateTo),
          optionAdvertisingName: optionAdName,
          undecided: handleActionCheck(value.applyEndDay),
          isDisable: handleActionCheck(value.applyEndDay),
          isShow: true,
          isDelete: false,
          hideTrash: true,
        }));
        setDetailList(tmpPatternDetails);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStore]);

  const handleActionCheck = useCallback((dataDate:any) => {
    const flag = dataDate.split('-');
    if (flag[0] >= 9999) {
      return true;
    }
    return false;
  }, []);

  // Create or update api
  const handleSubmitForm = useCallback(() => {
    const advertisingPlanOrganizationList = _.map(detailList,
      ({
        advertisingPlanCode, applicationStartDate, applicationEndDate, undecided, isDelete,
      // eslint-disable-next-line no-nested-ternary
      }) => (undecided !== true && applicationStartDate !== null && applicationEndDate !== null ? {
        advertisingPlanMstCode: advertisingPlanCode,
        applyStartDate: moment(applicationStartDate).format('YYYY/MM/DD'),
        applyEndDate: moment(applicationEndDate).format('YYYY/MM/DD'),
        isDelete,
      } : applicationStartDate !== null ? {
        advertisingPlanMstCode: advertisingPlanCode,
        applyStartDate: applicationStartDate ? moment(applicationStartDate).format('YYYY/MM/DD') : '',
        isDelete,
      } : {
        advertisingPlanMstCode: advertisingPlanCode,
        isDelete,
      }
      ));
    const FormSubmit = {
      orgCode,
      categoryAry: categoryArray && categoryArray.length > 0 ? String(categoryArray) : null,
      advertisingPlanOrganizationList,
      createUser: staffName,
      updateUser: staffName,
      loginStaffCode: staffCode,
      functionType,
    };
    createOrUpdateAdvertisingOrganization(FormSubmit).then(() => {
      successNotification('更新しました。');
      history.push('/salesAdvertisingPlan/advertisingOrganization');
    }).catch((error: any) => {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors.count !== 0) {
        errorNotification(`${error.response.data.errors[0].defaultMessage}`);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [categoryArray,
    detailList,
    errorNotification,
    history,
    orgCode,
    staffCode,
    staffName,
    successNotification]);

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true
    && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  return {
    detailList,
    setDetailList,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    handleDateForm,
    optionAdName,
    handleAddStoreDetails,
    handleDeleteShift,
    handleChangeCheckboxNextDay,
    handleAdvertising,
    handleDeleteShiftTrash,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    hideOrg,
    orgCode,
    setOrgCode,
    setCategoryAr,
    functionType,
    staffCode,
    isLoading,
    toggle,
    handleSubmitForm,
    checkUserRole,
  };
};

export default useCreateSettingForm;
