export const types = {
  SET_NAME: 'sample/SET_NAME' as const,
  FETCH: 'sample/FETCH' as const,
};

export const setName = (payload: string) => ({
  type: types.SET_NAME,
  payload,
});
export const fetch = (payload: number) => ({
  type: types.FETCH,
  payload,
});

type Actions = (
    | ReturnType<typeof setName>
    | ReturnType<typeof fetch>
);
export default Actions;
