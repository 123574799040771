import { useState, useEffect } from 'react';
import { apiGetHeadQuarters ,apiGetHeadQuarters2} from 'api/monthly/headquarters';
import moment from 'moment';
import {downloadMonthlyReportCsv,downloadMonthlyReportExcel} from 'api/monthlyReport';

const useSalesManagementList = () => {
  const functionType = 1;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode'));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);
  const monthSelect =  new Date();
  const  targetdayForm= moment().add(-1, 'days').format('YYYY-MM-DD');

  const [selectedDateFrom, setSelectedDateFrom] = useState<Date>(new Date(targetdayForm));
  const dateSelectTo =  moment().add(-1, 'days').format('YYYY-MM-DD');
  const [selectedDateTo, setSelectedDateTo] = useState<Date>(new Date(dateSelectTo));

  type ActionType = 'getTab1' | 'getTab2' | 'getTab3'| 'getTab4' | 'getTab5' | 'getTab6' | 'getTab7';
  const [orgName, setorgName] = useState(sessionStorage.getItem('loginUser.orgName') ? sessionStorage.getItem('loginUser.orgName') : '');

  const ActionType :{[key in ActionType]: ActionType} = {
    getTab1: 'getTab1',
    getTab2: 'getTab2',
    getTab3: 'getTab3',
    getTab4: 'getTab4',
    getTab5: 'getTab5',
    getTab6: 'getTab6',
    getTab7: 'getTab7',
  };
  const [action, setAction] = useState<ActionType>(ActionType.getTab1);
  const [listSalesManagement, setListSalesManagement] = useState([]);
  const [listSalesManagement2, setListSalesManagement2] = useState([]);
  const [listSalesManagement3, setListSalesManagement3] = useState([]);
  const [listSalesManagement4, setListSalesManagement4] = useState([]);
  const [listSalesManagement5, setListSalesManagement5] = useState([]);
  const [listSalesManagement6, setListSalesManagement6] = useState([]);
  const [listSalesManagement7, setListSalesManagement7] = useState([]);

  const [getMonthlyComparePrevYear, setMonthlyComparePrevYear] = useState<number>(0);

  useEffect(() => {
    const path = 'businessManagement/Sales';
    const path2 = 'businessManagement/prevSales';
    const path3 = 'businessManagement/incomplete';
    const path4 = 'businessManagement/FL';
    const path5 = 'businessManagement/FL/detail';
    const path6 = 'businessManagement/audit';
    const path7 = 'businessManagement/comment';

    async function asyncCall1() {
      const result = await apiGetHeadQuarters(path, String(orgCode), moment(selectedDateFrom.toString()).format('YYYY/MM/DD'), moment(selectedDateTo.toString()).format('YYYY/MM/DD'), categoryAry);
      setListSalesManagement(result);
    }
    async function asyncCall2() {
      const result = await apiGetHeadQuarters2(path2, String(orgCode), moment(selectedDateFrom.toString()).format('YYYY/MM/DD'), moment(selectedDateTo.toString()).format('YYYY/MM/DD'),getMonthlyComparePrevYear, categoryAry);
      setListSalesManagement2(result);
    }
    async function asyncCall3() {
      const result = await apiGetHeadQuarters(path3, String(orgCode), moment(selectedDateFrom.toString()).format('YYYY/MM/DD'), moment(selectedDateTo.toString()).format('YYYY/MM/DD'), categoryAry);
      setListSalesManagement3(result);
    }
    async function asyncCall4() {
      const result = await apiGetHeadQuarters(path4, String(orgCode), moment(selectedDateFrom.toString()).format('YYYY/MM/DD'), moment(selectedDateTo.toString()).format('YYYY/MM/DD'), categoryAry);
      setListSalesManagement4(result);
    }
    async function asyncCall5() {
      const result = await apiGetHeadQuarters(path5, String(orgCode), moment(selectedDateFrom.toString()).format('YYYY/MM/DD'), moment(selectedDateTo.toString()).format('YYYY/MM/DD'), categoryAry);
      setListSalesManagement5(result);
    }
    async function asyncCall6() {
      const result = await apiGetHeadQuarters(path6, String(orgCode), moment(selectedDateFrom.toString()).format('YYYY/MM/DD'), moment(selectedDateTo.toString()).format('YYYY/MM/DD'), categoryAry);
      setListSalesManagement6(result);
    }
    async function asyncCall7() {
      const result = await apiGetHeadQuarters(path7, String(orgCode), moment(selectedDateFrom.toString()).format('YYYY/MM/DD'), moment(selectedDateTo.toString()).format('YYYY/MM/DD'), categoryAry);
      setListSalesManagement7(result);
    }

    async function fetchData() {
      try {
        setLoading(true);
        switch (action) {
          case 'getTab1':
            await asyncCall1()
            break;
          case 'getTab2':
            await asyncCall2()
            break;
          case 'getTab3':
            await asyncCall3()
            break;
          case 'getTab4':
            await asyncCall4()
            break;
          case 'getTab5':
            await asyncCall5();
            break;
          case 'getTab6':
            await asyncCall6();
            break;
          case 'getTab7':
            await asyncCall7();
            break;
          default:
            await asyncCall1();
          break;
        }
        setLoading(false);
      } catch (error) {
        switch (action) {
          case 'getTab1':
            setListSalesManagement([])
            break;
          case 'getTab2':
            setListSalesManagement2([])
            break;
          case 'getTab3':
            setListSalesManagement3([])
            break;
          case 'getTab4':
            setListSalesManagement4([])
            break;
          case 'getTab5':
            setListSalesManagement5([])
            break;
          case 'getTab6':
            setListSalesManagement6([])
            break;
          case 'getTab7':
            setListSalesManagement7([])
            break;
          default:
            setListSalesManagement([])
          break;
        }
        setLoading(false)
      }
    }
    fetchData();

  }, [orgCode,selectedDateFrom, selectedDateTo, categoryAry, action, getMonthlyComparePrevYear]);

  return {
    isLoading,
    listSalesManagement,
    listSalesManagement2,
    listSalesManagement3,
    listSalesManagement4,
    listSalesManagement5,
    listSalesManagement6,
    listSalesManagement7,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo,
    functionType,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    action,
    setAction,
    ActionType,
    getMonthlyComparePrevYear,
    setMonthlyComparePrevYear,
    orgName,
    setorgName
  };
}
export const useDownload = () => {
  const downloadCsv1 = (data: any) => {
   downloadMonthlyReportCsv(data, '営業管理【売上管理】.csv');
  };
  const downloadExcel1 = (data: any) => {
    downloadMonthlyReportExcel(data, '営業管理【売上管理】.xlsx');
  };
  const downloadCsv2 = (data: any) => {
    downloadMonthlyReportCsv(data, '営業管理【前年対比】.csv');
   };
   const downloadExcel2 = (data: any) => {
     downloadMonthlyReportExcel(data, '営業管理【前年対比】.xlsx');
   };
   const downloadCsv3 = (data: any) => {
    downloadMonthlyReportCsv(data, '営業管理【不備一覧】.csv');
   };
   const downloadExcel3 = (data: any) => {
     downloadMonthlyReportExcel(data, '営業管理【不備一覧】.xlsx');
   };
   const downloadCsv4 = (data: any) => {
    downloadMonthlyReportCsv(data, '営業管理【FL】.csv');
   };
   const downloadExcel4 = (data: any) => {
     downloadMonthlyReportExcel(data, '営業管理【FL】.xlsx');
   };
   const downloadCsv5 = (data: any) => {
    downloadMonthlyReportCsv(data, '営業管理【FL詳細】.csv');
   };
   const downloadExcel5 = (data: any) => {
     downloadMonthlyReportExcel(data, '営業管理【FL詳細】.xlsx');
   };
   const downloadCsv6 = (data: any) => {
    downloadMonthlyReportCsv(data, ' 営業管理【監査項目】.csv');
   };
   const downloadExcel6 = (data: any) => {
     downloadMonthlyReportExcel(data, '営業管理【監査項目】.xlsx');
   };
   const downloadCsv7 = (data: any) => {
    downloadMonthlyReportCsv(data, ' 営業管理【コメント】.csv');
   };
   const downloadExcel7 = (data: any) => {
     downloadMonthlyReportExcel(data, '営業管理【コメント】.xlsx');
   };

  return {
    downloadCsv1,
    downloadExcel1,
    downloadCsv2,
    downloadExcel2,
    downloadCsv3,
    downloadExcel3,
    downloadCsv4,
    downloadExcel4,
    downloadCsv5,
    downloadExcel5,
    downloadCsv6,
    downloadExcel6,
    downloadCsv7,
    downloadExcel7,
  };
};

export default useSalesManagementList;
