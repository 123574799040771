/** @jsx jsx */
import React, { Fragment } from "react";
import { css, jsx } from "@emotion/core";
import { textFontSize, productColor } from "components/styles";
import ReactTooltip from "react-tooltip";

const style = (disable: boolean, bgColor: string | null, textColor: string | null) =>
  css({
    textAlign: "center",
    fontSize: textFontSize.sm,
    height: "24px",
    lineHeight: "24px",
    borderRadius: "4px",
    backgroundColor: bgColor || productColor.editAction,
    color: textColor || "white",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    padding: "0 5px",
    cursor: disable ? "normal" : "pointer",
  });
type ItemType = {
  id?: string;
  title: string;
  disable: boolean;
  bgColor?: string | null;
  textColor?: string | null;
  onClick?: (event: React.MouseEvent) => void;
};

const Item: React.FC<ItemType> = ({ id = '', title, disable = false, bgColor = "", textColor = "", onClick }) => {
  if (disable) {
    onClick = () => {};
  }
  return (
    <Fragment>
      {
        title && (
          <section className="calendar-item" onClick={onClick} css={style(disable, bgColor, textColor)} data-tip={title} data-for={`${title}-${id}`}>
            {title}
          </section>
        )
      }
      
      <ReactTooltip html={true} id={`${title}-${id}`} />
    </Fragment>
  );
};

export default Item;
