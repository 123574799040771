import React, { useState, useCallback, useReducer, useEffect } from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import FormContents from 'components/atoms/Form/FormContents';
import TabControl from 'components/atoms/TabControl';
import BlockUI from 'components/molecules/BlockUi';
import AuthorityBasicInformation from 'components/organismos/master/authority/AuthorityBasicInformation';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import RoleMasterTabRadioComponent from 'components/organismos/master/authority/component/RoleMasterTabRadioComponent';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { useAuthorityMasterOptions, useAuthorityMasterEdit } from '../../hooks';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { TRoleMasterList, CategoryMenuList, MenuDetail } from './type'
import { postRoleMasterList } from 'api/roleMaster';
import useToastNotification from 'hooks/useToastNotification';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

export interface TablItem {
  value: string;
  display?: string;
}
type ErrorType = {
  defaultMessage: string
}
const AuthorityMasterDetailPage: React.FC = () => {
  const { permissionCode } = useParams<{ permissionCode: string }>();
  const location = useLocation();
  const [selectType, setSelectType] = useState<string>('basic');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const history = useHistory();
  // const {
  //   permissionLevelOptions
  // } = useAuthorityMasterOptions();

  const {
    detail, tabItems, blocking, setBlocking
  } = useAuthorityMasterEdit();

  const memorizedReducer = useCallback((state: TRoleMasterList, { type, payload }) => {
    const { name, value, tabName, childrenId } = payload
    const result = JSON.parse(JSON.stringify(state))
    switch (type) {
      case 'BASIC_ON_CHANGE':
        const new_basic_result = {
          ...result,
          [name]: value
        }
        return new_basic_result
      case 'ON_CHANGE':
        const new_menu_detail_data = result.categoryMenuList.map((item: CategoryMenuList) => {
          if (item.categoryMenuId === tabName) {
            return {
              ...item,
              menuDetailData: item.menuDetailData.map((childrenItem: MenuDetail) => {
                if (childrenItem.menuId === childrenId) {
                  return {
                    ...childrenItem,
                    [name]: Number(value)
                  }
                } else {
                  return childrenItem
                }
              }),
            }
          } else {
            return item
          }
        })
        const new_result = {
          ...result,
          categoryMenuList: new_menu_detail_data
        }
        return new_result
      case 'DEFAULT':
        return value
      default:
        if (result.hasOwnProperty(name)) {
          if (name !== 'roleCode' && name !== 'roleName' && name !== 'accessLevel') {
            result[name] = Number(value);
          } else {
            result[name] = value;
          }
        }
        return result;
    }
  }, []);

  const isCopy = location.pathname.indexOf('copy') !== -1 ? true : false;

  const isEdit = location.pathname.indexOf('copy') === -1 && permissionCode ? true : false;

  const defaultRoleMaster = {
    roleId: detail?.roleId || '',
    roleCode: !isCopy ? (detail?.roleCode || '') : '',
    roleName: !isCopy ? (detail?.roleName || '') : '',
    accessLevel: String(detail?.accessLevel || '1'),
    companyCode: detail?.companyCode || '',
    notificationType: detail?.notificationType,
    categoryMenuList: detail?.categoryMenuList || [],
  }

  const [state, dispatch] = useReducer(memorizedReducer, defaultRoleMaster);

  useEffect(() => {
    dispatch({ type: 'DEFAULT', payload: { value: defaultRoleMaster } })
  }, [detail])
  const handleBasicOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch({ type: 'BASIC_ON_CHANGE', payload: { name, value } });
  }
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, children_id: string) => {
    const name = e.target.name;
    const value = e.target.value;
    const tabName = selectType
    const childrenId = children_id
    dispatch({ type: 'ON_CHANGE', payload: { name, value, tabName, childrenId } });
  }
  const handlePost = async (data: TRoleMasterList) => {
    setBlocking(true);
    setConfirmModalOpen(false)
    const post = { ...data }
    const additionalData = {
      site: 0,
      allOrganizationAccess: true,
      budgetManagement: true,
      paidControl: true,
      administrator: true,
      allStaffAccess: true,
      retired: true,
      accountingRole: true,
      attendanceRole: true,
      modifyRegireport: true,
      displayLaborCost: true,
    }

    const postData = {
      rolesPk: {
        roleId: post?.roleId || '',
        companyCode: post.companyCode
      },
      ...additionalData,
      roleName: post.roleName,
      accessLevel: post.accessLevel ? Number(post.accessLevel) : 1,
      roleCode: post.roleCode,
      notificationType: Number(post.notificationType),
      categories: post.categoryMenuList.map((item: CategoryMenuList) => {
        return {
          categoryMenuId: item.categoryMenuId,
          functions: item.menuDetailData.map((item1: any) => {
            delete item1['isDefault']
            return {
              ...item1,
              available: Number(item1.available) === 1 ? true : false,
              downloadFlag: Number(item1.downloadFlag),
              editable: Number(item1.editable),
              importFlag: Number(item1.importFlag),
              usabilityGeneralItem1: Number(item1.usabilityGeneralItem1),
              usabilityGeneralItem2: Number(item1.usabilityGeneralItem2),
              usabilityGeneralItem3: Number(item1.usabilityGeneralItem3),
              usabilityGeneralItem4: Number(item1.usabilityGeneralItem4),
              usabilityGeneralItem5: Number(item1.usabilityGeneralItem5),
              usabilityGeneralItem6: Number(item1.usabilityGeneralItem6),
              usabilityGeneralItem7: Number(item1.usabilityGeneralItem7),
              usabilityGeneralItem8: Number(item1.usabilityGeneralItem8),
              usabilityGeneralItem9: Number(item1.usabilityGeneralItem9),
              usabilityGeneralItem10: Number(item1.usabilityGeneralItem10),
              roleDetailId: item1.roleDetailId === null ? null : item1.roleDetailId,
              roleId: post?.roleId || ''
            }
          })
        }
      })
    }
    // console.log(postData, 'check post')
    try {
      const response = await postRoleMasterList(postData);
      setConfirmModalOpen(false)
      if (!response.errors) {
        successNotification('更新しました。');
        setBlocking(false);
      }
      history.push('/roleMaster');
    } catch (error) {
      setConfirmModalOpen(false)
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: ErrorType) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        setBlocking(false);
        errorNotification(stringErr);
      } else {
        setBlocking(false);
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  }

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  return (
    <SidebarTemplate pageTitle={location.pathname.indexOf('copy') === -1 ? (permissionCode ? '権限編集' : '権限作成') : '権限作成'}>
      <BlockUI blocking={blocking}>
        <FormContents>
          <div style={{ overflowX: 'auto' }}>
            {tabItems && <TabControl
              items={tabItems}
              setValue={setSelectType as (arg: string) => void}
              currentValue={selectType}
              isFixed={false}
            />}
          </div>

          {/* Basic Information Tab */}
          {
            selectType === 'basic' && (
              <AuthorityBasicInformation
                isEdit={location.pathname.indexOf('copy') === -1 ? (permissionCode ? true : false) : false}
                isCopy={isCopy ? true : false}
                // permissionLevelOptions={permissionLevelOptions}
                state={state}
                handleOnChange={handleBasicOnChange}
                dispatch={dispatch}
              />
            )
          }

          {/* Render Tab */}
          {
            selectType !== 'basic' && <RoleMasterTabRadioComponent
              state={state}
              handleOnChange={handleOnChange}
              categoryMenuId={selectType}
            />
          }

          {
            permissionCode !== '00000' && <FormSubmitArea>
              {
                roleScreen && roleScreen.editable === 1 && (
                  <div style={{ marginRight: '12px' }}>
                    <Button
                      text={isEdit ? '更新' : '登録'}
                      onClick={() => { setConfirmModalOpen(true) }}
                    />
                  </div>
                )
              }
              <Link to="/roleMaster">
                <Button
                  text="権限一覧に戻る"
                  ghost={true}
                />
              </Link>
            </FormSubmitArea>
          }

          {/* Modal Submit */}
          <ConfirmActionModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            onSubmit={() => handlePost(state)}
            actionType={isEdit ? ActionType.UPDATE : ActionType.CREATE}
          />
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default AuthorityMasterDetailPage;
