/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { productColor } from 'components/styles';

const styles = {
  wrapper: css({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    color: productColor.primary,

  }),
  label: css({
    flex: 1,
    height: '24px',
    width: '100%',
    padding: '4px',
    textAlign: 'center',
    fontSize: '13px',
    borderBottom: `2px solid ${productColor.primaryM90}`,
  }),
  selected: css({
    borderBottom: `2px solid ${productColor.primary}`,
  }),
  notSelected: css({
    cursor: 'pointer',
    ':hover': {
      backgroundColor: productColor.primaryM95,
      borderBottom: `2px solid ${productColor.primary}`,
    },
  }),
  radio: css({
    display: 'none',
  }),
};

interface TablItem {
  value: string;
  display?: string;
  disabled?: boolean;
}

const TabControl: React.FC<{
  items: Array<TablItem>;
  setValue: (value: string) => void;
  currentValue: string;
  isFixed?: boolean;
  largeWidth?: boolean;
}> = ({
  items, setValue, currentValue, isFixed = false, largeWidth = false,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  const lengthNotDisable = items.filter((x) => !x.disabled).length;
  return (
    <div css={isFixed ? css(styles.wrapper, { width: 130 * lengthNotDisable }) : (largeWidth ? css(styles.wrapper, { width: 135 * lengthNotDisable }) : styles.wrapper)}>
      {items.map((item) => (
        <React.Fragment key={item.value}>
          <label
            htmlFor={item.value}
            // eslint-disable-next-line no-nested-ternary
            css={css(styles.label, item.disabled === true ? styles.radio
              : (currentValue === item.value ? styles.selected : styles.notSelected))}
          >
            <input
              css={styles.radio}
              type="radio"
              id={item.value}
              value={item.value}
              checked={item.value === currentValue}
              onChange={handleChange}
              disabled={!!(item.disabled)}
            />
            {item.display || item.value}
          </label>
        </React.Fragment>
      ))}
    </div>
  );
};

export default TabControl;
