/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { Column } from 'react-table';
import { css, jsx } from '@emotion/core';

// import OrganizationDomain from 'domain/organization';
import OrganizationDomain from 'domain/organizationListTable';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import DataTable from 'components/organismos/DataTable/DataTable';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Icon from 'components/atoms/Icon';
import ClickableLink from 'components/atoms/ClickableLink';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import Modal from 'components/molecules/Modal';
import { iconColor } from 'components/styles';
import ManagerFilter from './ManagerFilter';
import ButtonNavigation from './ButtonNavigation';

import { useOrganizationDelete, useDownload } from './hooks';
import {useSelector} from 'react-redux';
import {Store} from 'modules/store';
import { OrgStatusType, SessionStorageKey, Values } from 'utility/constants';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import { MaxDate } from 'utility/dateUtil';

const OrganizationListTable: React.FC<{
  organizationList: Array<OrganizationDomain>;
  fetchData: () => Promise<void>;
  noManager: boolean;
  toggleNoManager: () => void;
  unsetTargetDate: Date;
  setUnsetTargetDate: (arg: Date) => void;
  orgStatus: OrgStatusType;
  setOrgStatus: (arg: OrgStatusType) => void;
  categoryArr: Array<string>;
  setCategoryArr: (arg: Array<string>) => void;
  arrCompanyBank?: any;
}> = ({
  organizationList, fetchData, noManager, toggleNoManager,
  unsetTargetDate, setUnsetTargetDate, orgStatus,
  setOrgStatus, categoryArr, setCategoryArr, arrCompanyBank
}) => {
    //

    const [sort, setSort] = useState<string>('0');
    const [order, setOrder] = useState<string>('0');

    const functionType = 1;
    const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    console.log(organizationList, 'eeeeee');
    const {
      deleteTargetOrganization, setDeleteTargetOrganization,
      isOpenDeleteModal,
      closeDeleteModal, onSubmitDeleteOrganization,
    } = useOrganizationDelete(fetchData);
    const {
      downloadOrganization, downloadManagerCsv, downloadSpecialSalaryCsv, downloadCompanyBankCsv
    } = useDownload(noManager, orgStatus, categoryArr, unsetTargetDate, sort, order);

    const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

    const today = new Date();

    const columns: Array<Column<OrganizationDomain>> = React.useMemo(() => [
      {
        Header: '組織コード',
        accessor: 'orgCode',
        sortType: 'basic'
      },
      {
        Header: '組織名',
        accessor: 'orgName',
      },
      // {
      //   Header: '組織カテゴリ',
      //   accessor: 'categoryLabel',
      // },
      {
        Header: () => null,
        accessor: 'orgCode',
        id: 'actionButton',
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean, values: OrganizationDomain } }) => {
          const [isHoverEdit, setIsHoverEdit] = useState(false);
          const [isHoverDelete, setIsHoverDelete] = useState(false);
          const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === cell.row.values.orgCode);
          const roleUser = staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;
          return (
            <FlexBox>
              <FlexBoxItem marginLeft="auto">
                <ClickableLink to={`/organizations/${cell.row.values.orgCode}`}>
                  <span
                    style={{ marginLeft: '20px' }}
                    onMouseEnter={() => setIsHoverEdit(true)}
                    onMouseLeave={() => setIsHoverEdit(false)}
                  >
                    <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </ClickableLink>
              </FlexBoxItem>
              {
                roleScreen && roleUser.editable === 1 && (
                  <React.Fragment>
                    <FlexBoxItem>
                      <ClickableIconButton
                        onClick={() => setDeleteTargetOrganization(cell.row.values)}
                      >
                        <span
                          onMouseEnter={() => setIsHoverDelete(true)}
                          onMouseLeave={() => setIsHoverDelete(false)}
                        >
                          <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                        </span>
                      </ClickableIconButton>
                    </FlexBoxItem>
                  </React.Fragment>
                )
              }
            </FlexBox>
          );
        },
      },
    ], [setDeleteTargetOrganization, roleScreen]);
    return (
      <React.Fragment>
        <ManagerFilter
          noManager={noManager}
          toggleNoManager={toggleNoManager}
          unsetTargetDate={unsetTargetDate}
          setUnsetTargetDate={setUnsetTargetDate}
        />
        <div style={{ marginLeft: '8px' }}>
          <OrganizationFilteredTree
            functionType={functionType}
            orgCategoryCallback={
              (args: Array<string>) => {
                setCategoryArr(args);
              }
            }
            targetdayForm={today}
            targetdayTo={today}
            staffCode={loginStaffCode}
            orgLabel="組織名"
            orgFilter={parseInt(orgStatus)}
            showSelectOrg={false}
            addAllItem={true}
            orgStatus={orgStatus}
          />

        </div>

        <div style={{ marginLeft: '8px' }}>
          <RadioSelectForm
              label="状態"
              labelInline={true}
              labelInlineWidth={100}
              items={Object.values(Values.OrgStatus)}
              name="orgStatus"
              value={orgStatus}
              setValue={(e) => {
                setOrgStatus(e.target.value as OrgStatusType);
                // NOTE: 組織カテゴリでの絞り込みを行うために選択中の組織カテゴリを再設定 ※ 再設定を行わないとカテゴリでの絞り込みが行われない
                setCategoryArr(JSON.parse(String(sessionStorage.getItem(SessionStorageKey.Common.ORG_FILTER_TREE_TOGGLE(window.location.pathname, parseInt(e.target.value))))));;
              }}
            />
        </div>

        <ButtonNavigation
          downloadOrganization={downloadOrganization}
          downloadManagerCsv={downloadManagerCsv}
          downloadSpecialSalaryCsv={downloadSpecialSalaryCsv}
          downloadCompanyBankCsv={downloadCompanyBankCsv}
          arrCompanyBank={arrCompanyBank}
          roleScreen={roleScreen}
          sort={sort}
          setSort={setSort}
          order={order}
          setOrder={setOrder}
        />
        <div
          css={css`
      table th:nth-child(1) {
        width: 200px;
      }
      table th:nth-child(2) {
        width: 200px;
      }
      table th:nth-child(3) {
        width: 100px;
      }
      table th:nth-child(4) {
        width: 100px;
      }
      `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTable
              columns={columns}
              data={organizationList}
              isGlobalFilter={true}
              minWidth="632px"
              useSession={true}
              tableId={1}
              sortBy={[
                { id: 'orgCode', desc: false }
              ]}
            />
          </div>
        </div>

        <Modal
          open={isOpenDeleteModal}
          closeHandler={closeDeleteModal}
          submitText="削除"
          title="削除"
          onSubmit={onSubmitDeleteOrganization}
        >
          {deleteTargetOrganization?.orgName}
          を削除します。よろしいですか？
        </Modal>
      </React.Fragment>
    );
  };

export default OrganizationListTable;
