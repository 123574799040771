/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import moment from 'moment';
import { isMacOs } from 'react-device-detect';
import { useDownload } from './hooks';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';
import { grayScale } from 'components/styles';
import useSortTable, { FieldSortState, SortColumn } from 'hooks/useSortTable';
import YearlySalesManagementReportDomain, { YearlySalesManagementReportData } from 'domain/master/storeManagement/yearlySalesManagementReport';
import { formatNumber, formatPercent } from 'utility/formatUtil';

const DEFAULT_SORT_FIELD = { fieldPath: 'orgCode', sort: 'asc' } as FieldSortState
const YearlySalesManagementTable: React.FC<{
  setIsLoading: any,
  orgName: any,
  yearlySalesManagementReport: Array<YearlySalesManagementReportDomain>,
  targetPeriodText: string,
  targetPeriodList: Array<string>,
  yearlyStartDate: any,
  yearlyEndDate: any
  roleScreen?: any,
}> = ({
  setIsLoading,
  orgName,
  yearlySalesManagementReport,
  targetPeriodText,
  targetPeriodList,
  yearlyStartDate,
  yearlyEndDate,
  roleScreen,
}) => {
    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソートヘッダ項目 */
    const sortColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '店舗コード',  sortField: 'orgCode', sortType: 'string', rowSpan: 2, style: { top: '0', left: '0', zIndex: 99 },     className:"text-center stickyStyle fristColSticky" },
      { displayName: '店舗名',      sortField: 'orgName', sortType: 'string', rowSpan: 2, style: { top: '0', left: '103px', zIndex: 99, maxWidth: ' 300px', width: '300px', minWidth: '300px' }, className:"text-center stickyStyle" },
    ], []);

    /** ソートサブヘッダ項目 */
    const sortSubColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '純売上',    sortField: 'netSales',              sortType: 'number' },
      { displayName: '売上予算',   sortField: 'salesBudget',          sortType: 'number' },
      { displayName: '予実達成率', sortField: 'perSalesBudget',       sortType: 'number' },
      { displayName: '前年売上',   sortField: 'prevSales',            sortType: 'number' },
      { displayName: '前年対比',   sortField: 'perPrevSales',         sortType: 'number' },
      { displayName: '客数',      sortField: 'guestCnt',              sortType: 'number' },
      { displayName: '前年客数',  sortField: 'guestCntPreviousYear',  sortType: 'number' },
      { displayName: '前年対比',  sortField: 'perPrevGuestCnt',       sortType: 'number' },
      { displayName: '組数',      sortField: 'customers',             sortType: 'number' },
      { displayName: '前年組数',  sortField: 'customersPreviousYear', sortType: 'number' },
      { displayName: '前年対比',  sortField: 'perPrevCustomers',      sortType: 'number' },
    ], []);

    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(yearlySalesManagementReport, DEFAULT_SORT_FIELD);

    // ------------------------------------------------------------------
    // 出力
    // ------------------------------------------------------------------
    /** CSV・Excel出力実装 */
    const { downloadMonthlyReportCsv, downloadMonthlyReportExcel } = useDownload();

    /** 出力用データ */
    const outputDataList = useMemo(() => dataSort.map(d => d.getOutputData()), [dataSort]);

    /** CSV出力用ヘッダ情報 */
    const headerInformation = [
      [
        ...sortColumns.map(s => ''),
        ...targetPeriodList.flatMap(targetPeriod => [targetPeriod, ...Array((sortSubColumns.length - 1)).fill('')]),
      ],
      [
        ...sortColumns.map(s => s.displayName),
        ...targetPeriodList.flatMap(h => sortSubColumns.map(s => s.displayName)),
      ],
    ];

    /** Excel出力用ヘッダ情報 */
    const headerInformationExcel = [
      [
        ...sortColumns.map(s => s.displayName),
        ...targetPeriodList.flatMap(targetPeriod => [targetPeriod, ...Array((sortSubColumns.length - 1)).fill('')]),
      ],
      [
        ...sortColumns.map(s => ''),
        ...targetPeriodList.flatMap(h => sortSubColumns.map(s => s.displayName)),
      ],
    ];

    /**
     * Excel出力用セル結合情報
     * NOTE: [firstRow, lastRow, firstCol, lastCol] の配列で結合するセル範囲を指定する
    */
    const mergedRegions = useMemo(() => {
      const tmpMergedRegions: any[] = [];
      // ヘッダの店舗セルを縦方向に結合
      tmpMergedRegions.push([4, 5, 0, 0]);
      // ヘッダの店舗名を縦方向に結合
      tmpMergedRegions.push([4, 5, 1, 1]);
      // ヘッダの期間を月毎に横方向に結合
      let firstColIndex = 2;
      let lastColIndex = firstColIndex + sortSubColumns.length - 1;
      targetPeriodList.map((targetPeriod: string) => {
        tmpMergedRegions.push([4, 4, firstColIndex, lastColIndex]);
        firstColIndex += sortSubColumns.length;
        lastColIndex += sortSubColumns.length;
      })
      return tmpMergedRegions;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetPeriodList]);

    const targetStoresData = `対象店舗：${orgName}`;

    const data = {
      footer: '',
      formName: '年次売上管理表',
      targetPeriod: targetPeriodText,
      targetStores: targetStoresData,
      headerInformation,
      searchResult: outputDataList,
      totalInformation: [],
    };

    const dataExcel = {
      formName: '年次売上管理表',
      targetPeriod: targetPeriodText,
      targetStores: targetStoresData,
      compareToPreviousYear: false,
      headerInformation: headerInformationExcel,
      searchResult: outputDataList,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex: [0],
    };

    const handleExportCSV = () => downloadMonthlyReportCsv(data, setIsLoading);
    const handleExportExcel = () => downloadMonthlyReportExcel(dataExcel, setIsLoading);

    return (
      <div>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
            <FlexBox>
              <FlexBoxItem>
                <IconLabelButton
                  onClick={handleExportCSV}
                  iconType="download"
                  text=" CSV出力"
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <IconLabelButton
                  onClick={handleExportExcel}
                  iconType="download"
                  text=" EXCEL出力"
                />
              </FlexBoxItem>
            </FlexBox>
          )}
        <div style={{ margin: '15px' }}>
          <div className="templateTable newDesignTable" style={{ height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: isMacOs ? '35px' : '1px' }} >
            <table className="table table-bordered text-nowrap" id="wrapTblRight">
              <thead>
                <tr>
                  {sortColumns.map((item: SortColumn, index: any) => (
                    <th key={index} rowSpan={item.rowSpan} style={item.style} className={item.className} onClick={e => sortByFieldPath(item.sortField, item.sortType)}>
                      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }} >
                        <span style={{ margin: 'auto' }} >{item.displayName}</span>
                        <div style={{ position: 'absolute', right: 0, marginTop: "-2px" }}>
                          {sortField.fieldPath === item.sortField && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== item.sortField && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </div>
                    </th>
                  ))}
                  {
                    targetPeriodList.map((targetPeriod: string, index: any) => (
                      <th key={index} colSpan={11} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>{targetPeriod}</span></th>
                    ))
                  }
                </tr>
                <tr>
                  { targetPeriodList.map((targetPeriod: string, index: number) => (
                    <React.Fragment key={index}>
                      { sortSubColumns.map((column: SortColumn, columnIndex: number ) => (
                        <th key={columnIndex} style={{ position: 'sticky', top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath(`items[${index}].${column.sortField}`, column.sortType)}>
                          <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }} >
                            <span style={{ margin: 'auto' }}>{column.displayName}</span>
                            <div style={{ position: 'absolute', right: 0, marginTop: "-3px" }}>
                              {sortField.fieldPath === `items[${index}].${column.sortField}` && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                              {sortField.fieldPath !== `items[${index}].${column.sortField}` && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                            </div>
                          </div>
                        </th>
                      ))}
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                { dataSort
                    ? dataSort.map((dispSlitData: YearlySalesManagementReportDomain, index: any) => (
                      <tr key={index}>
                        <td className="text-center stickyStyle fristColSticky" style={{ left: '0', zIndex: 10 }}>
                          <span>
                            <div className="text-ellipsis">{dispSlitData.orgCode}</div>
                          </span>
                        </td>
                        <td className="text-center stickyStyle" style={{ left: '103px', zIndex: 10 }}>
                          <span>
                            <div className="text-ellipsis">{dispSlitData.orgName}</div>
                          </span>
                        </td>
                        {
                          dispSlitData?.items?.map((dataChild: YearlySalesManagementReportData, idx: any) => (
                            <React.Fragment key={idx}>
                              <td className="text-right"><span>{formatNumber(dataChild.netSales)}</span></td>
                              <td className="text-right"><span>{formatNumber(dataChild.salesBudget)}</span></td>
                              <td className="text-right"><span>{formatPercent(dataChild.perSalesBudget)}</span></td>
                              <td className="text-right"><span>{formatNumber(dataChild.netSalesPreviousYear)}</span></td>
                              <td className="text-right"><span>{formatPercent(dataChild.perPrevSales)}</span></td>
                              <td className="text-right"><span>{formatNumber(dataChild.guestCnt)}</span></td>
                              <td className="text-right"><span>{formatNumber(dataChild.guestCntPreviousYear)}</span></td>
                              <td className="text-right"><span>{formatPercent(dataChild.perPrevGuestCnt)}</span></td>
                              <td className="text-right"><span>{formatNumber(dataChild.customers)}</span></td>
                              <td className="text-right"><span>{formatNumber(dataChild.customersPreviousYear)}</span></td>
                              <td className="text-right"><span>{formatPercent(dataChild.perPrevCustomers)}</span></td>
                            </React.Fragment>
                          ))
                        }
                      </tr>
                    ))
                    : null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

export default YearlySalesManagementTable;
