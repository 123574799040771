import { useState, useEffect } from 'react';
import { getList } from 'api/holidayMaster';
import HolidayMasterDomain from 'domain/master/labor/holidayMaster';

export const useHolidayMasterList = (orgCode: string, year: string) => {
  const [holidayList, setHolidayList] = useState<Array<HolidayMasterDomain>>([]);

  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getList(companyCode, year, orgCode).then((response: Array<any>) => {
      if (isSubscribed) {
        setHolidayList(response.map((result) => new HolidayMasterDomain(result)));
      }
    });
    return () => { isSubscribed = false; };
  }, [orgCode, year]);

  return { holidayList, setHolidayList };
};

export default useHolidayMasterList;
