/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import BodyText from 'components/atoms/BodyText';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Separater from 'components/atoms/Separator';
import HourInput from 'components/atoms/Form/HourInput';
import SelectForm from 'components/molecules/SelectForm';
import { SalaryBusinessStaff } from 'domain/master/general/salaryDataStaff';
import { OptionType } from 'components/atoms/Select';

const SalaryBusinessForm: React.FC<{
  businessOptions: Array<OptionType>;
  salaryBusiness: SalaryBusinessStaff;
  setSalaryBusiness: (salaryBusiness: SalaryBusinessStaff) => void;
  deleteSalaryBusiness: () => void;
}> = ({
  businessOptions, salaryBusiness, deleteSalaryBusiness, setSalaryBusiness,
}) => (
  <React.Fragment>
    <FlexBox>
      <FlexBoxItem basis="30%">
        <SelectForm
          label="業務"
          name="businessId"
          value={salaryBusiness.id.businessId}
          setValue={(v) => {
            setSalaryBusiness({
              ...salaryBusiness,
              id: {
                staffSalaryId: salaryBusiness.id.staffSalaryId,
                businessId: v,
              },
            });
          }}
          options={businessOptions}
        />
      </FlexBoxItem>

      <FlexBoxItem basis="10%" marginLeft="10%">
        <BodyText>時給加算額</BodyText>
      </FlexBoxItem>
      <FlexBoxItem basis="30%">
        <HourInput
          name="selectSalary.limitTransportation"
          value={salaryBusiness.addHourlySalary}
          label="円"
          onChange={(e) => {
            setSalaryBusiness({
              ...salaryBusiness,
              addHourlySalary: Number(e.target.value),
            });
          }}
          min={0}
          max={99999999}
          maxLength={9}
        />
      </FlexBoxItem>
      <FlexBoxItem marginLeft="auto">
        <IconLabelButton
          iconType="delete"
          text="削除"
          onClick={deleteSalaryBusiness}
          padding=""
        />
      </FlexBoxItem>

    </FlexBox>

    <Separater margin="12px 0" />
  </React.Fragment>
);

export default SalaryBusinessForm;
