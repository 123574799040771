/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { Dispatch, useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { PrimaryButton } from 'components/atoms/Button';

import FormContents from 'components/atoms/Form/FormContents';
import FormTitle from 'components/atoms/Form/FormTitle';
import StaffDomain from 'domain/staff';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import { TablItem } from 'components/pages/master/general/staff/StaffAddPage/StaffAddPage';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import OrganizationCategorySelect from 'components/organismos/organization/OrganizationCategory/OrganizationCategorySelect';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import { usePermissionForm } from './hooks';
import { TableColumnsType, TPermissions } from './type'
import useToastNotification from 'hooks/useToastNotification';
import BodyText from 'components/atoms/BodyText';
import Icon from 'components/atoms/Icon';
import { TRoleMaster } from './type'
import { InfoMessage } from 'utility/messages';

type SelectType = 'staff' | 'affiliation' | 'permission' | 'employment' | 'laborCost' | 'workPattern';
const SelectType: { [key in SelectType]: SelectType } = {
  staff: 'staff',
  affiliation: 'affiliation',
  permission: 'permission',
  employment: 'employment',
  laborCost: 'laborCost',
  workPattern: 'workPattern',
};

type TOptionType = {
  value: string,
  label: string
}
type TRoleCodeOptions = {
  roleCode: string
  value: string,
  label: string
}
const PermissionForm: React.FC<{
  initialStaffDomain?: StaffDomain;
  isEditMode?: boolean;
  tabItems: TablItem[];
  setInitialStaffDomain: Dispatch<React.SetStateAction<StaffDomain>>;
  setSelectType: Dispatch<React.SetStateAction<SelectType>>;
  setTabItems: Dispatch<React.SetStateAction<TablItem[]>>;
  setEmpTargetDate?: React.Dispatch<React.SetStateAction<Date>>;
  employmentOptions: Array<any>;
  organizationOptions: Array<any>;
  roleOptions: Array<any>;
  setIsLoading: Dispatch<React.SetStateAction<boolean>>;
  menuType: string;
  roleScreen?: any;
  isEditingLoginUser: boolean;
}> = ({
  initialStaffDomain, isEditMode = false, tabItems,
  setInitialStaffDomain, setSelectType, setTabItems,
  setEmpTargetDate,
  employmentOptions, roleOptions, organizationOptions,
  setIsLoading,
  menuType,
  roleScreen,
  isEditingLoginUser,
}) => {
    const {
      //loading,
      roleMasterList,
      staffCodeList,
      formik,
      confirmModalOpen, closeConfirmModal,
      useOrgTreesOptionsAll,
      organizationCategoryOptions,
      orgAccessRegistList,
      setOrgAccessRegistList
    } = usePermissionForm(
      initialStaffDomain,
      isEditMode,
      setIsLoading,
      menuType,
      isEditingLoginUser,
    );
    const orgTreesOptions = useOrgTreesOptionsAll(2, []);
    const roleOptionsFollowingRoleCode = useMemo(() => {
      return staffCodeList && roleOptions && roleOptions.filter((item) => item.roleCode === staffCodeList.roleCode).map((item) => {
        return {
          value: item.value,
          label: item.label
        }
      })
    }, [staffCodeList, roleOptions])
    const [optionType2, setOptionType2] = useState<TOptionType[]>([])
    const { errorNotification } = useToastNotification();

    const [initStateAuthority, setInitStateAuthority] = useState<TPermissions[]>([
      {
        orgCode: '',
        roleCode: ''
      }
    ]);

    const deleteNotClinkToSystem = (rowIndex: number) => {
      if (menuType === '0') {
        try {
          const newRows = [...orgAccessRegistList];
          newRows.splice(rowIndex, 1);
          setOrgAccessRegistList(newRows);
          formik.setFieldValue('orgAccessRegistList', newRows);

        } catch (error) {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      }
    };
    const tableColumnsConcurrentStores: TableColumnsType[] = [
      {
        name: '',
        visible: true,
        width: '190px',
        content: (rowIndex: number, colIndex: number) => (
          // <FormField displayBlock={true}>
          <td style={{ minWidth: 190, width: 220 }} key={`label-col1-${colIndex}`}>
            <FormField displayBlock={true}>
              <VerticalLabelSelectForm
                name="accessLevel"
                value={orgAccessRegistList[rowIndex].orgCode}
                setValue={(val) => {
                  // const option = e.target;
                  const newNotClinkToSystem = [...orgAccessRegistList];
                  newNotClinkToSystem[rowIndex] = {
                    ...newNotClinkToSystem[rowIndex],
                    orgCode: newNotClinkToSystem.find(item => item.orgCode === val) !== undefined ?
                      (newNotClinkToSystem.find(item => item.orgCode === val)?.orgCode === orgAccessRegistList[rowIndex].orgCode
                        ? orgAccessRegistList[rowIndex].orgCode : '')
                      : val,
                  };
                  setOrgAccessRegistList(newNotClinkToSystem);
                  formik.setFieldValue('orgAccessRegistList', newNotClinkToSystem);
                }}
                options={orgTreesOptions}
                disable={menuType === '1' ? true : false}
                required={true}
              />
            </FormField>
          </td>
        ),
      },
      {
        name: '機能権限',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <React.Fragment>
            <td style={{ minWidth: 60, width: 100, fontSize: 13 }} className="text-right" key={`label-cols2-${colIndex}`}>機能権限</td>
            <td style={{ minWidth: 180, width: 210 }} key={`value-cols2-${colIndex}`}>
              <FormField displayBlock={true}>
                <VerticalLabelSelectForm
                  name="accessLevel"
                  value={orgAccessRegistList[rowIndex].roleCode}
                  setValue={(val) => {
                    const newNotClinkToSystem = [...orgAccessRegistList,];
                    newNotClinkToSystem[rowIndex] = {
                      ...newNotClinkToSystem[rowIndex],
                      roleCode: val,
                    };
                    setOrgAccessRegistList(newNotClinkToSystem);
                    formik.setFieldValue('orgAccessRegistList', newNotClinkToSystem);
                  }}
                  options={optionType2}
                  disable={menuType === '1' ? true : false}
                  required={true}
                />
              </FormField>
            </td>
          </React.Fragment>
        ),
      },
      {
        name: '',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={`icon-col3-${colIndex}`} className="text-center" style={{ width: '50px', color: 'red', fontSize: 14, cursor: 'not-allowed' }} onClick={() => {
            deleteNotClinkToSystem(rowIndex)
          }}>
            <Icon type="close" color={menuType === '1' ? 'gray' : 'red'} hoverColor={menuType === '1' ? 'gray' : 'red'} />
          </td>
        )
        ,
      },
    ];
    const addRow = () => {
      if (initStateAuthority === undefined) {
        errorNotification('取引先が登録されていません。取引先マスタを登録してから操作をやり直してください。');
      } else {
        const newRowsAuthority = [...orgAccessRegistList, ...initStateAuthority];
        setOrgAccessRegistList(newRowsAuthority);
      }
    };

    //  api orgnaization

    const accessLevel = sessionStorage.getItem('loginUser.accessLevel');

    let optionsReadingRange = [
      {
        label: '所属組織',
        value: '0',
      },
      {
        label: '全組織',
        value: '1',
      },
      {
        label: '所属組織とカテゴリ',
        value: '2',
      },
    ];

    if (Number(accessLevel) === 0) {
      optionsReadingRange = [
        {
          label: '所属組織',
          value: '0',
        },
      ];
    } else if (Number(accessLevel) === 2) {
      optionsReadingRange = [
        {
          label: '所属組織',
          value: '0',
        },
        {
          label: '所属組織とカテゴリ',
          value: '2',
        },
      ];
    }

    useEffect(() => {
      setOptionType2(roleMasterList.map((item: TRoleMaster, index: number) => {
        if (roleMasterList.length > 0 && index === roleMasterList.length - 1 && formik.values.roleCode === '') {
          formik.setFieldValue('roleCode', item.roleCode);
        }
        return {
          value: item.roleCode,
          label: item.roleName
        }
      })
      )
    }, [roleMasterList]);
    return (
      <>
        <form onSubmit={formik.handleSubmit}>
          <FormContents>
            <FormTitle
              title="権限設定"
            />

            <FormField displayBlock={true}>
              <VerticalLabelSelectForm
                label="閲覧範囲"
                name="accessLevel"
                value={String(formik.values.accessLevel)}
                setValue={(val) => {
                  formik.setFieldValue('accessLevel', val);
                }}
                options={optionsReadingRange}
                disable={menuType === '1' ? true : false}
              />
            </FormField>

            {String(formik.values.accessLevel) === String(2) && (
              <div style={{ marginLeft: '10px' }}>
                <OrganizationCategorySelect
                  label=""
                  values={formik.values.categoryAccessSetAry}
                  optionsList={organizationCategoryOptions}
                  setValues={(arg) => {
                    formik.setFieldValue(
                      'categoryAccessSetAry',
                      arg,
                    );
                    formik.setFieldValue('orgAccessRegistList', orgAccessRegistList);

                  }}
                  isMulti={true}
                  disabled={menuType === '1' ? true : false}
                />
                <div style={{ marginLeft: '8px' }}>
                  {/* <VerticalLabelMutilSelectForm
                    label="兼務店舗"
                    name="orgAccessSetAry"
                    values={
                      orgTreesOptions.filter((option) => {
                        if (formik.values.orgAccessSetAry && formik.values.orgAccessSetAry.length
                          && formik.values.orgAccessSetAry.includes(option.value)) {
                          return option;
                        }
                      })
                    }
                    setValue={(val: any) => {
                      const arr = val.slice(0, -1).split(',');
                      formik.setFieldValue('orgAccessSetAry', arr);
                    }}
                    options={orgTreesOptions}
                    isMulti={true}
                  /> */}

                  {/* Start here */}
                  <FormField displayBlock={true}>
                    <BodyText color={'#000'}>兼務店舗</BodyText>
                    <div >
                      <table style={{ width: '40%' }}>
                        <thead>
                        </thead>
                        <tbody>
                          {orgAccessRegistList && orgAccessRegistList.length > 0 && orgAccessRegistList.map((row: any, rowIndex: number) => (
                            <tr key={rowIndex}>
                              {tableColumnsConcurrentStores.filter((col) => col.visible).map((column, colIndex) => column.content(rowIndex, colIndex))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div style={{ paddingTop: '12px' }}>
                        <PrimaryButton
                          disabled={menuType === '1' ? true : false}
                          text="兼務店舗を追加"
                          onClick={() => {
                            addRow();
                          }}
                        />
                      </div>
                    </div>
                  </FormField>
                  {/* Start here */}

                </div>
              </div>
            )}

            <FormField displayBlock={true}>
              <VerticalLabelSelectForm
                label="機能権限"
                name="role"
                value={formik.values.roleCode}
                setValue={(val: string) => { formik.setFieldValue('roleCode', val); }}
                options={optionType2}
              />
            </FormField>

          </FormContents>

          <FormSubmitArea>
            {roleScreen && roleScreen.editable === 1 && (
              <div style={{ marginRight: '12px' }}>
                <Button
                  text={isEditMode ? '権限設定を登録' : '登録'}
                  onClick={formik.handleSubmit}
                />
              </div>
            )}
            {roleScreen && roleScreen.editable === 1 && !isEditMode && (
              <div style={{ marginRight: '12px' }}>
                <Button
                  text="雇用情報の入力へ"
                  onClick={() => {
                    if (formik.values.empApplyDate) {
                      // Set salary apply day
                      formik.values.selectSalary.applyStartDate = formik.values.hireDate;

                      if (tabItems.length <= 3) {
                        setInitialStaffDomain(formik.values);

                        setTabItems([
                          {
                            value: SelectType.staff,
                            display: 'スタッフ情報',
                          },
                          {
                            value: SelectType.affiliation,
                            display: '所属情報',
                          },
                          {
                            value: SelectType.permission,
                            display: '権限設定',
                          },
                          {
                            value: SelectType.employment,
                            display: '雇用情報',
                          },
                        ]);
                      }
                      setSelectType(SelectType.employment);
                    } else {
                      formik.validateForm();
                    }
                  }}
                />
              </div>
            )}

            {menuType !== '1' && (
              <Link to="/staffs">
                <Button
                  text="スタッフ一覧に戻る"
                  ghost={true}
                />
              </Link>
            )}
            {menuType === '1' && (
              <Link to="/staffs/employee">
                <Button
                  text="スタッフ一覧に戻る"
                  ghost={true}
                />
              </Link>
            )}
          </FormSubmitArea>
        </form>

        {/* 登録内容確認モーダル */}
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
        />
      </>
    );
  };

export default PermissionForm;
