
import React from 'react';
import SalesSettingForm from 'components/organismos/master/general/salesSetting';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const SalesSetting = () => (
  <SidebarTemplate pageTitle="損益管理共有マスタ">
    <SalesSettingForm />
  </SidebarTemplate>
);

export default SalesSetting;
