
import React from 'react';
import IncomeExpenditureList from 'components/organismos/master/general/salesPage/incomeExpenditure/incomeExpenditureList';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const incomeExpenditurePage = () => (
  <SidebarTemplate pageTitle="【月別】収支表帳票">
    <IncomeExpenditureList /> 
  </SidebarTemplate>
);

export default incomeExpenditurePage;
