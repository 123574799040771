
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import PaymentImportForm from 'components/organismos/master/sales/PaymentImportForm/PaymentImportForm';


const MediaMasterImportPage = () => (
  <SidebarTemplate pageTitle="支払取込一覧インポート">
    <PaymentImportForm />
  </SidebarTemplate>
);

export default MediaMasterImportPage;
