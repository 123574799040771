/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import Table from 'components/molecules/Table';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';
import { grayScale } from 'components/styles';

const style = css({
  position: 'sticky',
  top: '48px', // Appbarのheight分下げる
});

const sort = css({
  display: 'flex',
  alignItems: 'center',
  padding: '0 4px'
});

const hover = css({
  ':hover':{
    cursor: 'pointer',
    backgroundColor: '#F2F5F9'
  }
})

interface TableHeaderCellProps {
  onClick: () => void;
  isSorted: boolean;
  isSortedDesc?: boolean;
  disableSortBy?: boolean;
}

const TableHeaderCell : React.FC<TableHeaderCellProps> = ({
  children, onClick, isSorted, isSortedDesc, disableSortBy
}) => (
  <Table.HeaderCell
    customStyle={style}
    onClick={onClick}
  >
    <div css={[sort, !disableSortBy && hover]}>
      {children}
      {isSorted
      ? <SortIcon isSortedDesc={isSortedDesc} /> : (!disableSortBy ? <Icon type="sortDefault" color={grayScale.gray100} size="25px" />: '')}
    </div>
  </Table.HeaderCell>
);

export default TableHeaderCell;
