import React, { SVGProps } from 'react';

const WarningFill: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M17.297 15.426L11.312 5.059a1.517 1.517 0 00-2.624 0L2.702 15.426a1.522 1.522 0 000 1.515c.27.47.77.758 1.309.758h11.976c.54 0 1.04-.289 1.309-.758.27-.468.27-1.046 0-1.515zM10 15.992a.938.938 0 110-1.875.938.938 0 010 1.875zm.578-3a.577.577 0 11-1.156 0l-.36-4.238a.938.938 0 011.876 0zm0 0"
        fill="#333"
      />
    </g>
  </svg>
);

export default WarningFill;
