import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesExpensesMSTListManagement from 'components/pages/master/general/salesExpenesesMST/salesExpensesMSTList/salesExpensesMSTListManagement';

const CommentListPage = () => (
  <SidebarTemplate pageTitle="その他経費マスタ">
    <SalesExpensesMSTListManagement />
  </SidebarTemplate>
);
export default CommentListPage;
