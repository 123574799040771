import {
  useState, useCallback, useEffect,
} from 'react';

import {
  getDigestionItemsForTransferHolidayStatus,
  downloadTransferHolidayCsv,
  getTransferHolidayStatusDetail,
} from 'api/holidayManagement';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import TransferHolidayStatusDomain from 'domain/master/holidayManagement/transferHolidayStatus';
import useToastNotification from 'hooks/useToastNotification';

export interface DetailItems {
  staffCode: string,
  staffName: string,
  orgCode: any,
  orgName: any,
  transferDayNumb: any,
  attendTransfer: [],
  targetDateFrom: Date,
  targetDateTo: Date,
}

export const useHolidayManagementRestForm = () => {
  const sessionSearchDate = sessionStorage.getItem('restHoliday.searchDate') || new Date().toString();
  const loadDateYearAgo = new Date(sessionSearchDate);
  loadDateYearAgo.setDate(loadDateYearAgo.getDate() + 1);
  loadDateYearAgo.setFullYear(loadDateYearAgo.getFullYear() - 1);

  const sessionStorageOrgCode = String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}8`)) !== 'null' ? (String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}8`))) : (sessionStorage.getItem('loginUser.orgCode') || '');
  const [
    transferHolidayStatusList, setTransferHolidayStatusList,
  ] = useState<Array<TransferHolidayStatusDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useState(sessionStorageOrgCode);
  const [searchDate, setSearchDate] = useState<Date>(new Date(sessionSearchDate));
  const [dateYearAgo, setDateYearAgo] = useState<Date>(loadDateYearAgo);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailItems, setDetailItems] = useState<DetailItems>({
    staffCode: '',
    staffName: '',
    orgCode: '',
    orgName: '',
    transferDayNumb: '',
    attendTransfer: [],
    targetDateFrom: new Date(),
    targetDateTo: new Date(),
  });
  const { errorNotification } = useToastNotification();


  // データの取得
  const fetchData = useCallback(async () => {
    const response = await getDigestionItemsForTransferHolidayStatus(
      orgCode !== 'all' ? orgCode : '',
      String(moment(moment(searchDate).toDate()).format('YYYY/MM/DD')),
    );
    setTransferHolidayStatusList(response.map((result) => new TransferHolidayStatusDomain(result)));
  }, [orgCode, searchDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, searchDate]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  //
  const callGetTransferHolidayStatusDetail = useCallback(async (staffCode: string) => {
    try {
      await getTransferHolidayStatusDetail(
        staffCode,
        String(moment(moment(searchDate).toDate()).format('YYYY/MM/DD')),
      ).then((response: any) => {
        setDetailItems(response);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [errorNotification, searchDate]);

  return {
    transferHolidayStatusList,
    callGetTransferHolidayStatusDetail,
    toggleNoManager,
    orgCode,
    setOrgCode,
    searchDate,
    setSearchDate,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    dateYearAgo,
    setDateYearAgo,
  };
};

export const useDownloadHolidayManagementRest = (
  orgCode: string,
  targetDate: Date,
) => {
  const downloadHolidayManagementRest = useCallback((isDownloadAll?: boolean) => {
    downloadTransferHolidayCsv(
      isDownloadAll ? '' : orgCode,
      String(moment(moment(targetDate).toDate()).format('YYYY/MM/DD')),
    );
  }, [orgCode, targetDate]);

  return {
    downloadHolidayManagementRest,
  };
};

export default {
  useHolidayManagementRestForm,
};
