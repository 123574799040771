import React, { SVGProps } from 'react';

const Edit: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M17 15.875v1.25H3v-1.25h14zM14.243 2.407l2.475 2.475a.625.625 0 010 .884l-8.486 8.486a.625.625 0 01-.364.178l-2.829.353a.625.625 0 01-.697-.697l.353-2.829a.625.625 0 01.178-.364l8.486-8.486a.625.625 0 01.884 0zM13.8 3.733l-7.892 7.892-.227 1.818 1.818-.227 7.892-7.892-1.591-1.59z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Edit;
