import { useState, useEffect } from 'react';
import useToastNotification from 'hooks/useToastNotification';
import { getRoleMasterDetail, getAccessLevelList } from 'api/roleMaster';
import { OptionType } from 'components/atoms/Select';
import { useParams, useLocation } from 'react-router-dom';
import { TRoleMasterList, CategoryMenuList } from './authorityMaster/authorityMasterDetail/type'
type TAccessLevel = {
  accessLevel: number,
  accessLevelMasterId: string,
  accessLevelName: string,
  companyCode: string,
}
type TTabItems = {
  value: string,
  display: string
}
export const useAuthorityMasterOptions = () => {
  const [permissionLevelOptions, setPermissionLevelOptions] = useState<Array<OptionType>>([]);
  const { errorNotification } = useToastNotification();
  useEffect(() => {
    try {
      getAccessLevelList().then((response: TAccessLevel[]) => {
        setPermissionLevelOptions(response.map((accessLevel: TAccessLevel) => {
          return {
            value: String(accessLevel.accessLevel),
            label: accessLevel.accessLevelName,
          }
        }))
      });
    }
    catch (error) {
      errorNotification('サーバー側でエラーが発生しました。');
    }
  }, [])

  return {
    permissionLevelOptions
  }
}

export const useAuthorityMasterEdit = () => {
  const [blocking, setBlocking] = useState(false);
  const [categoryParentMenu, setCategoryParentMenu] = useState<any>();
  const [tabItems, setTabItems] = useState<TTabItems[]>([]);
  const [detail, setDetail] = useState<TRoleMasterList>();
  const { permissionCode } = useParams<{ permissionCode: string }>();
  const { errorNotification } = useToastNotification();
  const location = useLocation()
  const [copyState, setCopyState] = useState<number>(location.pathname.indexOf('copy') > -1 ? 1 : 0)
  let listParentCategory: any = { basic: 'basic' }
  let defaultTab: TTabItems[] = [{ value: 'basic', display: '基本情報' }]

  useEffect(() => {
    setBlocking(true);
    try {
      getRoleMasterDetail(permissionCode || null, copyState).then((response: TRoleMasterList) => {
        setDetail(response);
        response && response.categoryMenuList && response.categoryMenuList.map((category: CategoryMenuList) => {
          listParentCategory[category.categoryMenuId] = category.menuDetailData
          defaultTab.push({ value: category.categoryMenuId, display: category.categoryMenuName })
        }
        );
        setTabItems(defaultTab);
        setCategoryParentMenu(listParentCategory);
        setBlocking(false)
      });
    }
    catch (error) {
      setBlocking(false)
      errorNotification('サーバー側でエラーが発生しました。');
    }
  }, [permissionCode])
  return {
    detail, tabItems, categoryParentMenu, blocking, setBlocking
  }
}

export default {};
