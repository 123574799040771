
import { useParams } from 'react-router-dom';
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import DiscountTypeAddForm from 'components/organismos/master/sales/DiscountTypeForm/DiscountTypeAddForm';

const DiscountTypeAddPage = () => {
  const { discountTypeMstCode } = useParams();
  return (
    <SidebarTemplate pageTitle={discountTypeMstCode ? '値引・割引種別編集' : '値引・割引種別作成'}>
      <DiscountTypeAddForm />
    </SidebarTemplate>
  );
};

export default DiscountTypeAddPage;
