import React from 'react';

type Props = {
  title: string;
  color: string;
  value: string;
  check: boolean;
};

const CustomCard: React.FC<Props> = ({ title, color, value, check }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '8px 0px',
      gap: '8px',
      minWidth: '122px',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            marginRight: '4px',
            backgroundColor: color,
          }}
        />
        <span
          style={{
            color: 'black',
            fontWeight: 'bold',
            fontSize: '12px',
          }}
        >
          {title}
        </span>
      </div>
      <input type="checkbox" checked={check} />
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          flex: 1,
          fontWeight: 'bold',
          fontSize: '170%',
          width: '100%',
          textAlign: 'end',
          marginRight: '2px',
        }}
      >
        {value}
      </div>
      <div
        style={{
          fontWeight: 'bold',
          fontSize: '100%',
          textAlign: 'end',
        }}
      >
        千円
      </div>
    </div>
  </div>
);

export default CustomCard;
