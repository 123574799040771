/** @jsx jsx */
import React, { useMemo, useState, useCallback } from 'react';
import { Column } from 'react-table';
import { css, jsx } from '@emotion/core';
import AdverstingMediaDomain from 'domain/master/sales/adverstingMedia';
import PageTitle from 'components/atoms/PageTitle';
import PrimaryButton from 'components/atoms/Button';
import { Link } from 'react-router-dom';
import DataTable from 'components/organismos/DataTable/DataTable';
import Icon from 'components/atoms/Icon';
import { iconColor } from 'components/styles';
import { deleteById, downloadCSV } from 'api/advertiseMedia';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import useToastNotification from 'hooks/useToastNotification';
import useAdvertise from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';

const AdvertisingMediaMasterPage = () => {
  const { advertise, setAdvertise } = useAdvertise();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  // eslint-disable-next-line max-len
  const [selectedAdvertise, setSelectedAdvertise] = useState(AdverstingMediaDomain.generateInitial());
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((item : AdverstingMediaDomain) => {
    setConfirmModalOpen(true);
    setSelectedAdvertise(item);
  }, []);

  const downloadFileCSV = useCallback(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    downloadCSV(companyCode);
  }, []);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const deleteAdvertise = useCallback(
    () => {
      deleteById(selectedAdvertise.advertisingMediaMstCode).then((response:any) => {
        setAdvertise(advertise.filter((item) => item.advertisingMediaMstCode
                                                  !== selectedAdvertise.advertisingMediaMstCode));
        setConfirmModalOpen(false);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response.data.message != null && error.response.data.message.length !== 0) {
          errorNotification(error.response.data.message);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    },
    [advertise, errorNotification, selectedAdvertise.advertisingMediaMstCode,
      setAdvertise, successNotification],
  );

  const columns: Array<Column<AdverstingMediaDomain>> = useMemo(() => [
    {
      Header: 'コード',
      accessor: 'advertisingMediaMstCode',
    },
    {
      Header: '名称',
      accessor: 'advertisingMediaMstName',
    },
    {
      Header: '並び順',
      accessor: 'dispOrder',
    },
    {
      Header: () => null,
      accessor: 'advertisingMediaMstCode',
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: AdverstingMediaDomain },
            data: Array<AdverstingMediaDomain>
          }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/salesAdvertisingMedia/advertisingMaster/edit/${cell.row.original.advertisingMediaMstCode}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>

            {
              roleScreen && roleScreen.editable === 1 && (
                <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                  <span
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                    onMouseEnter={() => setIsHoverDelete(true)}
                    onMouseLeave={() => setIsHoverDelete(false)}
                  >
                    <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </Link>
              )
            }
          </div>
        );
      },
    },
  ], [openConfirmModal, roleScreen]);

  const sortBy = React.useMemo(
    () => [
      {
        id: 'dispOrder',
        desc: false,
      },
    ],
    [],
  );
  return (
    <React.Fragment>
      <div>
        <PageTitle
          title=""
          rightContents={
            roleScreen && roleScreen.editable === 1 && (
              <Link to="/salesAdvertisingMedia/advertisingMaster/add">
                <PrimaryButton
                  ghost={false}
                  text="新規広告媒体作成"
                />
              </Link>
          )}
        >
          <FlexBox>
            {
              roleScreen && roleScreen.downloadFlag === 1 && (
              <FlexBoxItem>
                <IconLabelButton
                  onClick={downloadFileCSV}
                  iconType="download"
                  text="CSVダウンロード"
                />
              </FlexBoxItem>
              )
            }
            {
              roleScreen && roleScreen.importFlag === 1 && (
              <FlexBoxItem>
                <Link to="/salesAdvertisingMedia/advertisingMaster/import">
                  <PrimaryButton
                    ghost={false}
                    text="インポート"
                  />
                </Link>
              </FlexBoxItem>
              )
            }
          </FlexBox>
        </PageTitle>

        <div
          css={css`
            table th:nth-child(1) {
              width: 150px;
            }
            table th:nth-child(2) {
              width: 300px;
            }
            table th:nth-child(3) {
              width: 150px;
            }
            table th:nth-child(4) {
              width: 80px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTable
              columns={columns}
              data={advertise}
              isGlobalFilter={true}
              sortBy={sortBy}
            />
          </div>
        </div>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={deleteAdvertise}
          actionType={ActionType.DELETE}
        />

      </div>
    </React.Fragment>
  );
};


export default AdvertisingMediaMasterPage;
