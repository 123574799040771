import { useState, useEffect } from 'react';
import { OptionType } from 'components/atoms/Select';
import { getDisCountTypeList } from 'api/disCountType';

/**
 * Get discount options
 */
export const useDiscountOptions = (): Array<OptionType> => {
  const [discounTypeOptions, setdiscounTypeOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getDisCountTypeList().then((discounts) => {
      setdiscounTypeOptions(discounts.map((discount: any[]) => ({
        value: discount[1],
        label: discount[2],
      })));
    });
  }, []);
  return discounTypeOptions;
};

export default useDiscountOptions;
