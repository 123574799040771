import React from 'react';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import PrimaryButton from 'components/atoms/Button';
import IconLabelButton from 'components/molecules/IconLabelButton';

const ButtonNavigation: React.FC<{
  downloadExcel: () => void;
  roleScreen?: any;
}> = ({ downloadExcel, roleScreen }) => (
  <FlexBox customStyle={css({ marginTop: '10px', padding: '0 15px' })}>

    {
      roleScreen && roleScreen.downloadFlag === 1 && (
      <FlexBox>
        <FlexBoxItem>
          <IconLabelButton
            onClick={downloadExcel}
            iconType="download"
            text="EXCELダウンロード"
          />
        </FlexBoxItem>
      </FlexBox>
      )
    }

    {
      roleScreen && roleScreen.editable === 1 && (
      <FlexBoxItem marginLeft="auto">
        <Link to="/roleMaster/add">
          <PrimaryButton
            text="新規権限作成"
          />
        </Link>
      </FlexBoxItem>
      )
    }

  </FlexBox>
);

export default ButtonNavigation;
