/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React, { useState } from 'react';
import { grayScale } from 'components/styles';
import Accordion from 'components/molecules/Accordion';
import DataTable from 'components/organismos/DataTable/DataTable';
import PrimaryButton, { SecondaryButton } from 'components/atoms/Button';
import Checkbox from 'components/molecules/Checkbox';
import TextForm from 'components/molecules/TextForm';
import FlexBox from 'components/atoms/FlexBox';
import { css, jsx } from '@emotion/core';
import ConfirmModal from 'components/organismos/ConfirmModal';
import ToastModal from 'components/organismos/ToastModal';
import useToastNotification from 'hooks/useToastNotification';
import BlockUI from 'components/molecules/BlockUi';
import { useApplyingTransferForm } from './hook';
import ApplyingOvertimeDomain from '../domain/applyingOvertimeDomain';
import { postBulkApproveOvertime, postApproveOvertime } from '../api';

const { errorNotification, successNotification } = useToastNotification();

const OvertimeAccordion: React.FC<{
  list: ApplyingOvertimeDomain[];
  setApplyingOvertimeList: any;
  fetchApprovedList: any;
  fetchRejectedList: any;
}> = ({
  list,
  setApplyingOvertimeList,
  fetchApprovedList,
  fetchRejectedList,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    toastMessage,
    setToastMessage,

    toastModalOpen,
    setToastModalOpen,

    confirmApplyModalOpen,
    setConfirmApplyModalOpen,

    closeConfirmApplyModal,

    extractSingleApproveData,
    extractBulkApproveData,
    extractSingleRejectData,

    rejectReasonOnchange,

    buttonType,
    setButtonType,
    selectedIndex,
    setSelectedIndex,
  } = useApplyingTransferForm(list);

  const handleSubmit = async () => {
    const remainingList = list.filter(
      (item, position) => !selectedIndex.includes(position),
    ); // remove checked requests
    const checkedList = list.filter(
      (item, position) => selectedIndex.includes(position),
    ); // checked requests

    try {
      if (buttonType === 1) {
        rejectRequest(checkedList, remainingList);
      } else {
        approveRequest(checkedList, remainingList);
      }
    } catch (error) {
      setToastMessage(error.message);
    }
    setConfirmApplyModalOpen(false);
    setSelectedIndex([]);
  };

  const approveRequest = async (checkedList: any[], remainingList: any[]) => {
    setIsLoading(true);
    if (checkedList.length > 1) {
      await postBulkApproveOvertime(extractBulkApproveData(checkedList)).then(() => {
        setIsLoading(false);
        successNotification('承認しました。');
        setApplyingOvertimeList(remainingList); // set new list
        fetchApprovedList(); // refetch API
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = '';
          error.response.data.errors.map((item: { defaultMessage: string; }) => {
            msgError += `${item.defaultMessage} </br>`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
        setIsLoading(false);
      });
    } else {
      await postApproveOvertime(extractSingleApproveData(checkedList)).then(() => {
        setIsLoading(false);
        successNotification('承認しました。');
        setApplyingOvertimeList(remainingList); // set new list
        fetchApprovedList(); // refetch API
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = '';
          error.response.data.errors.map((item: { defaultMessage: string; }) => {
            msgError += `${item.defaultMessage} </br>`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
        setIsLoading(false);
      });
    }
  };

  const rejectRequest = async (checkedList: any[], remainingList: any[]) => {
    setIsLoading(true);
    if (!checkedList[0].rejectReason) {
      setIsLoading(false);
      errorNotification('否決コメントを入力してください。');
      return;
    }

    await postApproveOvertime(extractSingleRejectData(checkedList)).then(() => {
      setIsLoading(false);
      successNotification('否決しました。');
      setApplyingOvertimeList(remainingList); // set new list
      fetchRejectedList(); // refetch API
    }).catch((error) => {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        let msgError = '';
        error.response.data.errors.map((item: { defaultMessage: string; }) => {
          msgError += `${item.defaultMessage} </br>`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (cell: {
          flatRows: any;
          state:any;
        }) => (
          <Checkbox
            id="checkbox_overtime_all"
            name="checkbox_overtime_all"
            value=""
            checked={(cell.state.pageSize === selectedIndex.length || cell.state.pageSize >= selectedIndex.length) && selectedIndex.length !== 0}
            onChange={() => {
              if (selectedIndex.length > 0) {
                setSelectedIndex([]);
              } else {
                const allIndex = cell.flatRows
                  .slice(cell.state.pageIndex * cell.state.pageSize, cell.state.pageSize * (cell.state.pageIndex + 1))
                  .map((i: { id: any }) => parseInt(i.id))
                setSelectedIndex(allIndex);
              }
            }}
          />
        ),
        disableSortBy: true,
        id: '',
        accessor: 'checkbox',
        Cell: (cell: {
          row: { isExpanded: boolean; original: any; index: number };
          data: any;
        }) => (
          <div style={{ textAlign: 'center' }}>
            <Checkbox
              id={`checkbox_overtime_${cell.row.index}`}
              name="name"
              label=""
              value={`${cell.row.index}`}
              checked={selectedIndex.includes(cell.row.index)}
              onChange={() => {
                if (selectedIndex.includes(cell.row.index)) {
                  setSelectedIndex(
                    selectedIndex.filter(
                      (selected) => selected !== cell.row.index,
                    ),
                  );
                } else {
                  setSelectedIndex([...selectedIndex, cell.row.index]);
                }
              }}
            />
          </div>
        ),
      },
      {
        Header: '決裁',
        id: '',
        accessor: 'decision',
        Cell: (cell: {
          row: { isExpanded: boolean; original: any; index: number };
          data: any;
        }) => (
          <div>
            <FlexBox customStyle={css({ justifyContent: 'space-evenly' })}>
              <PrimaryButton
                text="承認"
                onClick={() => {
                  setButtonType(0);
                  setSelectedIndex([cell.row.index]);
                  setConfirmApplyModalOpen(true);
                }}
              />
              <SecondaryButton
                text="否決"
                onClick={() => {
                  setButtonType(1);
                  setSelectedIndex([cell.row.index]);
                  setConfirmApplyModalOpen(true);
                }}
              />
            </FlexBox>
            <TextForm
              name="rejectReason"
              label=""
              value={cell.data[cell.row.index].rejectReason}
              placeHolder="否決コメントを入力"
              errorMsg=""
              onChange={(e) => rejectReasonOnchange(cell.row.index, e)}
            />
          </div>
        ),
      },
      {
        Header: '組織名',
        accessor: 'orgName',
      },
      {
        Header: '申請者',
        accessor: 'applicant',
      },
      {
        Header: '申請日時',
        accessor: 'applicationDate',
      },
      {
        Header: '対象者',
        accessor: 'targetPerson',
      },
      {
        Header: '対象日付',
        accessor: 'targetDate',
      },
      {
        Header: '申請種別',
        accessor: 'modifiyStatusName',
      },
      {
        Header: '残業時間',
        accessor: 'unapprovedOvertime',
      },
      {
        Header: '申請残業時間',
        accessor: 'applicationOvertime',
      },
      {
        Header: '申請理由',
        accessor: 'applicationReason',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, selectedIndex],
  );

  return (
    <div css={css`
    table th:nth-child(1) {
      width: 20px;
    }
    table th:nth-child(2) {
      width: 180px;
    }
    table th:nth-child(3) {
      width: 140px;
    }
    table th:nth-child(4) {
      width: 140px;
    }
    table th:nth-child(5) {
      width: 140px;
    }
    table th:nth-child(6) {
      width: 140px;
    }
    table th:nth-child(7) {
      width: 80px;
    }
    table th:nth-child(8) {
      width: 80px;
    }
    table th:nth-child(9) {
      width: 80px;
    }
    table th:nth-child(10) {
      width: 100px;
    }
    table th:nth-child(11) {
      width: 200px;
    }
  `}
    >
      <Accordion
        text={`残業申請 (${list.length}件）`}
        color={grayScale.gray100}
        callBack={() => {}}
      >
        <PrimaryButton
          text="一括承認"
          onClick={() => {
            setButtonType(2);
            setConfirmApplyModalOpen(true);
          }}
          disabled={selectedIndex.length === 0}
        />
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <BlockUI blocking={isLoading}>
            <DataTable columns={columns} data={list} isGlobalFilter={true} minWidth="1384px" sortBy={[{ id: 'applicationDate', desc: false }]} />
          </BlockUI>
        </div>
      </Accordion>
      <ToastModal
        open={toastModalOpen}
        closeHandler={() => setToastModalOpen(false)}
        title={toastMessage}
      />

      <ConfirmModal
        open={confirmApplyModalOpen}
        closeHandler={closeConfirmApplyModal}
        onSubmit={handleSubmit}
        title="確認メッセージ"
        // eslint-disable-next-line no-nested-ternary
        content={
          buttonType === 0
            ? '承認します。よろしいですか？'
            : buttonType === 1
              ? '否決します。よろしいですか？'
              : '一括承認します。よろしいですか？'
        }
        // eslint-disable-next-line no-nested-ternary
        submitText={buttonType !== 1 ? '承認' : '否決'}
      />
    </div>
  );
};

export default OvertimeAccordion;
