/**
 * 数値を指定した形式にフォーマットします。
 *
 * @param {number} number - フォーマット対象の数値。
 * @param {number} [minimumFractionDigits=0] - フォーマットする数値の小数部分の最小桁数。省略時は 0 が使用されます。
 * @param {number} [maximumFractionDigits=0] - フォーマットする数値の小数部分の最大桁数。省略時は 0 が使用されます。
 * @param {Intl.NumberFormatOptions} [options={}] - その他のフォーマットオプション。
 * @param {string} [locale='ja-JP'] - フォーマットに使用するロケール。省略時は 'ja-JP' が使用されます。
 * @returns {string} - フォーマットされた数値。
 * @throws {Error} フォーマットに使用するロケールがサポートされていない場合にスローされます。
 */
export const formatNumber = (number: number, minimumFractionDigits: number = 0, maximumFractionDigits: number = 0, options: Intl.NumberFormatOptions = {}, locale: string = 'ja-JP'): string => {
  const formatter = new Intl.NumberFormat(locale, {
    ...options,
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  });
  return formatter.format(number);
}

/**
 * 数値をパーセント形式にフォーマットします。
 *
 * @param {number} number - フォーマット対象の数値。
 * @param {number} [minimumFractionDigits=0] - フォーマットする数値の小数部分の最小桁数。省略時は 0 が使用されます。
 * @param {number} [maximumFractionDigits=0] - フォーマットする数値の小数部分の最大桁数。省略時は 0 が使用されます。
 * @param {Intl.NumberFormatOptions} [options={}] - その他のフォーマットオプション。
 * @param {string} [locale='ja-JP'] - フォーマットに使用するロケール。省略時は 'ja-JP' が使用されます。
 * @returns {string} - フォーマットされた数値。
 * @throws {Error} フォーマットに使用するロケールがサポートされていない場合にスローされます。
 */
export const formatPercent = (number: number, minimumFractionDigits: number = 2, maximumFractionDigits: number = 2, options: Intl.NumberFormatOptions = {}, locale: string = 'ja-JP'): string => {
  const formatter = new Intl.NumberFormat(locale, {
    ...options,
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
    style: 'percent',
  });
  return formatter.format(number);
}

/**
 * @deprecated numberFormaterと一緒に削除する
 */
const formatter = new Intl.NumberFormat("ja-JP", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

/**
 * @deprecated formatNumberを使用してください
 */
export const numberFormater = (num: any) => {
  if (typeof num === "undefined" || isNaN(num)) {
    return num;
  }
  const tmp = String(num).split(".");
  if (tmp[1]) {
    let fixed = 3;
    if (tmp[1].length < 3) {
      fixed = tmp[1].length;
    }
    return parseFloat(num).toFixed(fixed);
  }
  return formatter.format(num);
};

export const convertNumberByPrecision = (num:any, defaultValue = '-', precision = 3, delimiter = ',') => {
  if (!num) {
    return defaultValue ?? num;
  }
  function reverse(s: string) {
    return s.split('').reverse().join('');
  }
  let numStr = String(num);
  if (num.length <= precision || numStr.includes(delimiter)) {
    return numStr;
  }
  let res = '';
  numStr = reverse(numStr);
  while (numStr.length >= precision) {
    res += `${delimiter}${numStr.substr(0, precision)}`;
    numStr = numStr.substr(precision, numStr.length);
  }
  if (numStr.length > 0) {
    res += `${delimiter}${numStr}`;
  }
  res = res.substr(1, res.length);
  return reverse(res);
};

/**
 * 組織セレクトボックスのラベルから組織名を取得する
 * @param orgOptionLabel 組織セレクトボックスのラベル（組織コード + 半角スペース + 組織名）
 * @returns 組織名
 */
export const getOrgNameFromOrgOptionLabel = (orgOptionLabel: string): string => {
  // 組織セレクトボックスのラベルより組織コードを除外
  const [, ...orgName] = orgOptionLabel.split(' ');
  // 組織名に半角スペースが含まれる場合は分割されるので半角スペースで結合
  return orgName.join(' ');
};

/**
 * 曜日チェックボックスを選択曜日を文字列に変換
 * @param formik
 * @returns 選択曜日の文字列
 */
export const getTargetWeekText = (formik: any): string => {
  let targetDay = '';
  if (formik.values.timePub === true) {
    targetDay += ' 祝';
  }
  if (formik.values.timeSat === true) {
    targetDay += ' 土';
  }
  if (formik.values.timeSun === true) {
    targetDay += ' 日';
  }
  if (formik.values.timeMon === true) {
    targetDay += ' 月';
  }
  if (formik.values.timeTue === true) {
    targetDay += ' 火';
  }
  if (formik.values.timeWed === true) {
    targetDay += ' 水';
  }
  if (formik.values.timeTur === true) {
    targetDay += ' 木';
  }
  if (formik.values.timeFri === true) {
    targetDay += ' 金';
  }
  return targetDay;
};

/**
 * 組織セレクトボックスから選択組織の組織名を取得
 * @param orgTreesOptions 組織セレクトボックス
 * @param orgCode 選択組織の組織コード
 * @returns 選択組織の組織名
 */
export const getOrgNameFromOrgTreesOptions = (orgTreesOptions: any, orgCode: string | null): string => {
  if (!orgCode) return '';
  const org = orgTreesOptions.find((data: any) => data.value === orgCode);
  if (!org) return '';
  return getOrgNameFromOrgOptionLabel(org.label);
};
