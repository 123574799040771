import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment';
import { ResDataType } from './hooks';
import { isMobileOnly, isMacOs } from 'react-device-detect';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import SortIcon from 'components/molecules/SortIcon';
import { grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';

const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num)) {
      return num;
    }
    const tmp = String(num).split('.');
    if (tmp[1]) {
      let fixed = 3;
      if (tmp[1].length < 3) {
        fixed = tmp[1].length;
      }
      return parseFloat(num).toFixed(fixed);
    }
    return formatter.format(num);
  };
  console.log(numberFormater(1000000),'check number')

  const DEFAULT_SORT_FIELD = { fieldPath: 'targetDate', sort: 'asc', sortType: 'date' } as FieldSortState

const MonthlySubmissionConfirmationTable: React.FC<{
    selectedMonth: Date,
    monthlyReportSubmissionConfirmationData: Array<ResDataType>
}> = ({ selectedMonth, monthlyReportSubmissionConfirmationData }) => {
    // let totalDateItem: (ResDataType | undefined);
    const [totalDateItem, setTotalDateItem] = useState<(ResDataType | undefined)>();
    const [getCheckScrollBar, setCheckScrollBar] = useState(0);
    const listData = useMemo(() => {
        let lstTempl: Array<ResDataType> = [];
        monthlyReportSubmissionConfirmationData.map((item: ResDataType, index: number) => {
            const { status, targetDate } = item;
            if ((new Date(targetDate)).getFullYear() !== 9999) {
                lstTempl.push(item);
            } else {
                console.log((new Date(targetDate)).getFullYear());
                setTotalDateItem(item);
            }
        });
        return lstTempl;
    }, [monthlyReportSubmissionConfirmationData]);

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(listData, DEFAULT_SORT_FIELD);

    const eventScroll = useCallback((e: { target: any }) => {
        const { target } = e;
        const wrapTblRight = document.getElementById('wrapTblRight');
        const wrapTblHeadRight = document.getElementById('wrapTblHeadRight');
        const wrapTblBottomRight = document.getElementById('wrapTblBottomRight');

        if (wrapTblRight) {
            wrapTblRight.scrollLeft = target.scrollLeft;
        }
        if (wrapTblHeadRight) {
            wrapTblHeadRight.scrollLeft = target.scrollLeft;
        }
        if (wrapTblBottomRight) {
            wrapTblBottomRight.scrollLeft = target.scrollLeft;
        }
    }, []);
    const returnStatusShape = useCallback((status: number) => {
        switch (status) {
            case -1: return 'x' //未申請
            case 0: return '△' //申請中
            case 1: return '▼' //差戻し中
            case 2: return '〇' //承認済み
            case 3: return '−' //休日
            default: return '' // status: 99
        }
    }, []);
    const rightHeaderList = useMemo(() => [
        {displayName: 'AirPAY', sortField: 'airpayAmount'},
        {displayName: '商品券', sortField: 'cashVoucherPaymentTotalAmount'},
        {displayName: '掛売', sortField: 'kakeuriAmount'},
        {displayName: '小口出金差異', sortField: 'pettyWithdrawalDiff'},
        {displayName: 'VOID件数', sortField: 'voidCnt'},
    ], []);

    useEffect(() => {
        const offsetWidth = document.getElementById('parentRowContent')?.offsetWidth || 0;
        const clientWidth = document.getElementById('parentRowContent')?.clientWidth || 0;
        if (offsetWidth - clientWidth === 0) {
            setCheckScrollBar(1);
        } else {
            setCheckScrollBar(0);
        }
        console.log('offsetWidth', offsetWidth, 'clientWidthA', clientWidth);
    }, [listData]);

    const renderHeaderColumn = useCallback((displayName: string, columnSortField: FieldSortState, rowSpan: number, className: string = "", style: React.CSSProperties = {}) => (
        <th rowSpan={rowSpan} className={className} style={style} onClick={e => sortByFieldPath(columnSortField.fieldPath, columnSortField.sortType)}>
          <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
            <span style={{ margin: 'auto' }}>{displayName}</span>
            {sortField.fieldPath === columnSortField.fieldPath && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
            {sortField.fieldPath !== columnSortField.fieldPath && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
          </div>
        </th>
        ), [sortField]);


    return (
        <div style={{ marginTop: 30 }}>
            <div className="templateTable">
                <div className="tableShow" id="tableShow">
                    <div className="topRow">
                        <div className="col_left">
                            <table className="table table-bordered table-striped table-condensed text-nowrap" >
                                <tbody>
                                    <tr>
                                        { renderHeaderColumn('状態', { fieldPath: 'status', sort: 'asc', sortType: 'number'}, 1, "text-center", { minWidth: '75px', width: '20%' }) }
                                        { renderHeaderColumn('日付', { fieldPath: 'targetDate', sort: 'asc', sortType: 'date'}, 1, "text-center") }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col_right  overflow-hidden" id="wrapTblHeadRight"
                        // event scroll here
                        >
                            <div>
                                <div className="col_right_content Col_right-MarginPrint" style={{ width: 'auto' }} >
                                    <table className="table table-bordered table-striped table-condensed text-nowrap">
                                        <tbody>
                                            <tr>
                                                {rightHeaderList.map((headerRightContent: any, index: number) => (
                                                    <React.Fragment key={index}>
                                                        { renderHeaderColumn(`${headerRightContent.displayName}`, { fieldPath: `${headerRightContent.sortField}`, sort: 'asc', sortType: 'number'}, 1, "text-center nonBorderBottomPrint") }
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottomRow" id="parentRowContent">
                        <div className="col_left">
                            <table className="table table-bordered table-striped table-condensed text-nowrap">
                                <tbody>
                                    {dataSort.map((item: any, index: number) => {
                                        const { status, targetDate } = item;
                                        return (
                                            <tr key={index}>
                                                <td className="text-center col_leftTd" style={{ minWidth: '75px', width: '20%' }}>
                                                    {returnStatusShape(status)}
                                                </td>
                                                <td className="text-center col_rightTd dayColView" >
                                                    {moment(targetDate, 'YYYY-MM-DD').format('YYYY年MM月DD日（ddd）')}
                                                </td>
                                                <td className="text-center col_rightTd dayColPrint">
                                                    {moment(targetDate, 'YYYY-MM-DD').format('YYYY-MM-DD')}

                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="col_right" id="wrapTblRight" onScroll={eventScroll} style={{width: 'calc(100% - 350px)'}}>
                            <div>
                                <div className="col_right_content" style={{ width: 'auto', marginRight: isMobileOnly ? '16px' : (isMacOs && getCheckScrollBar === 1 ? '16px' : 0) }} >
                                    <table className="table table-bordered table-striped table-condensed text-nowrap">
                                        <tbody>
                                            {dataSort.map((item: any, index: number) => {
                                                const { cashVoucherPaymentTotalAmount, kakeuriAmount, airpayAmount, pettyWithdrawalDiff, voidCnt } = item;
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-right nonBorderLeftPrint">
                                                            {numberFormater(airpayAmount)}
                                                        </td>
                                                        <td className="text-right" >
                                                            {numberFormater(cashVoucherPaymentTotalAmount)}
                                                        </td>
                                                        <td className="text-right">
                                                            {numberFormater(kakeuriAmount)}
                                                        </td>
                                                        <td className="text-right">
                                                            {numberFormater(pettyWithdrawalDiff)}
                                                        </td>
                                                        <td className="text-right">
                                                            {numberFormater(voidCnt)}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="topRow footerRow" style={{ borderTop: '5px solid #d2d6de' }} >
                        <div className="col_left">
                            <table className="table table-bordered table-striped table-condensed text-nowrap">
                                <tbody>
                                    <tr>
                                        <td colSpan={2} className="text-center" style={{ textAlign: 'center' }}>合計</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col_right " id="wrapTblBottomRight" onScroll={eventScroll}>
                            <div>
                                <div className="col_right_content" style={{ width: 'auto' }}>
                                    <table className="table table-bordered table-striped table-condensed text-nowrap">
                                        <tbody>
                                            {totalDateItem &&
                                                <tr>
                                                    <td className="text-right nonBorderTopPrint">
                                                        {totalDateItem.airpayAmount ? numberFormater(totalDateItem.airpayAmount) : 0}
                                                    </td>
                                                    <td className="text-right nonBorderTopPrint">
                                                        {totalDateItem.cashVoucherPaymentTotalAmount ? numberFormater(totalDateItem.cashVoucherPaymentTotalAmount) : 0}
                                                    </td>
                                                    <td className="text-right nonBorderTopPrint">
                                                        {totalDateItem.kakeuriAmount ? numberFormater(totalDateItem.kakeuriAmount) : 0}
                                                    </td>
                                                    <td className="text-right nonBorderTopPrint">
                                                        {totalDateItem.pettyWithdrawalDiff ? numberFormater(totalDateItem.pettyWithdrawalDiff) : 0}
                                                    </td>
                                                    <td className="text-right nonBorderTopPrint">
                                                        {totalDateItem.voidCnt ? numberFormater(totalDateItem.voidCnt) : 0}
                                                    </td>
                                                </tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tablePrint">
                    <table className="table table-bordered table-striped table-condensed text-nowrap" >
                        <tbody>
                            <tr>
                                <th style={{ width: '30px', minWidth: '30px', maxWidth: '30px', textAlign: 'center' }} >
                                    <span>状態</span>
                                </th>
                                {[{displayName: '日付'}, ...rightHeaderList].map((item: any, index: number) => (
                                    <th key={index} style={{ textAlign: 'center' }}>
                                        <span>{item.displayName}</span>
                                    </th>
                                ))
                                }
                            </tr>
                            {dataSort.map((item: any, index: number) => {
                                const { status, targetDate, airpayAmount, cashVoucherPaymentTotalAmount, kakeuriAmount, pettyWithdrawalDiff, voidCnt } = item;
                                return (
                                    <tr key={index}>
                                        <td style={{ width: '30px', minWidth: '30px', maxWidth: '30px', textAlign: 'center' }}>
                                            {returnStatusShape(status)}
                                        </td>
                                        <td className="col_rightTd">
                                            <Link to="">
                                                {moment(targetDate, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                            </Link>
                                        </td>
                                        <td>
                                            {numberFormater(airpayAmount)}
                                        </td>
                                        <td >
                                            {numberFormater(cashVoucherPaymentTotalAmount)}
                                        </td>
                                        <td>
                                            {numberFormater(kakeuriAmount)}
                                        </td>
                                        <td>
                                            {numberFormater(pettyWithdrawalDiff)}
                                        </td>
                                        <td>
                                            {numberFormater(voidCnt)}
                                        </td>
                                    </tr>
                                )
                            })}
                            {totalDateItem &&
                                <tr>
                                    <td style={{ width: '30px', minWidth: '30px', maxWidth: '30px', textAlign: 'center' }}></td>
                                    <td className="text-center"  >合計</td>
                                    <td >
                                        {totalDateItem.airpayAmount ? numberFormater(totalDateItem.airpayAmount) : 0}
                                    </td>
                                    <td >
                                        {totalDateItem.cashVoucherPaymentTotalAmount ? numberFormater(totalDateItem.cashVoucherPaymentTotalAmount) : 0}
                                    </td>
                                    <td >
                                        {totalDateItem.kakeuriAmount ? numberFormater(totalDateItem.kakeuriAmount) : 0}
                                    </td>
                                    <td >
                                        {totalDateItem.pettyWithdrawalDiff ? numberFormater(totalDateItem.pettyWithdrawalDiff) : 0}
                                    </td>
                                    <td >
                                        {totalDateItem.voidCnt ? numberFormater(totalDateItem.voidCnt) : 0}
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}

export default MonthlySubmissionConfirmationTable;
