/* eslint-disable func-names */
/** @jsx jsx */
import React, {
  useRef, useEffect, useState
} from 'react';
import { css, jsx } from '@emotion/core';
import PrimaryButton from 'components/atoms/Button';
// import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import BlockUI from 'components/molecules/BlockUi';
import FileSelectButton from 'components/molecules/FileSelectButton';
import SelectForm from 'components/molecules/SelectForm';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
// import ConfirmModal from 'components/organismos/ConfirmModal';
import { productColor } from 'components/styles';
// import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Webcam from 'react-webcam';
import { useMasterShopFaceRegistrationForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';


let checkWidthIPad = false;
const mq = window.matchMedia('(max-width: 1024px)');
if (mq.matches) {
  checkWidthIPad = true;
} else {
  checkWidthIPad = false;
}

const styles = {
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: checkWidthIPad ? '10px' : '100px',
  }),
  contentLeft: css({
    flexGrow: 4,
    minWidth: checkWidthIPad ? '10px' : '100px',
  }),
  canvas: css({
    width: '260px',
    border: '1px solid #000',
  }),
  video: {
    border: '1px solid #000',
  },
  textfix: css({
    color: productColor.secondary,
    padding: '5px 5px',
    textAlign: 'center',
    fontWeight: 400,
  }),
};

const videoConstraints = {
  width: checkWidthIPad ? 480 : 640,
  height: checkWidthIPad ? 320 : 480,
  facingMode: 'user',
  border: '1px solid #000',
};

const MasterShopFaceRegistrationForm: React.FC = () => {
  // アップロードファイル
  const canvasRef1 = useRef<HTMLCanvasElement>(null);
  const webcamRef = useRef(null);
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const {
    staff,
    orgCode,
    setOrgCode,
    orgTreesOptions,
    staffCode,
    setStaffCode,
    staffsForOrgCodeOptions,
    handleSubmit,
    isLoading,
    errorNotification,
    disableButtonCap,
    setDisableButtonCap,
  } = useMasterShopFaceRegistrationForm();

  // eslint-disable-next-line no-shadow
  const capture = (webcamRef: any) => {
    setDisableButtonCap(true);

    //  img 64
    // const base64string = webcamRef.current.getScreenshot();
    if (!canvasRef1.current) {
      setDisableButtonCap(false);
      return;
    }

    // Reset file upload

    const camera = webcamRef.current.video;
    const canvas = canvasRef1.current;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      setDisableButtonCap(false);
      return;
    }
    const w = camera.offsetWidth;
    const h = camera.offsetHeight;
    canvas.setAttribute('width', w);
    canvas.setAttribute('height', h);
    // 左右反転
    ctx.transform(-1, 0, 0, 1, w, 0);
    // カメラから キャンバスに静止画を描く
    ctx.drawImage(camera, 0, 0, w, h);

    canvas.toBlob((blob: any) => {
      handleSubmit(blob);
      setDisableButtonCap(false);
    }, 'image/jpeg');

    // const canvas: HTMLCanvasElement = canvasRef1.current;
    // const ctx = canvas.getContext('2d');

    // if (!ctx) {
    //   setDisableButtonCap(false);
    //   return;
    // }
    // const img = new Image();
    // img.crossOrigin = '';

    // // eslint-disable-next-line no-loop-func
    // img.onload = function () {
    //   const w = img.width;
    //   const h = img.height;
    //   ctx.clearRect(0, 0, canvas.width, canvas.height);
    //   canvas.width = w;
    //   canvas.height = h;
    //   ctx.drawImage(img, 0, 0, w, h);
    //   canvas.toBlob((blob: any) => {
    //     handleSubmit(blob);
    //     setDisableButtonCap(false);
    //   }, 'image/jpeg');
    // };
    // img.onerror = function () {
    //   setDisableButtonCap(false);
    // };
    // img.src = base64string;
  };

  const onUserMediaError = (err: any) => {
    if (err) {
      errorNotification('カメラへのアクセスに失敗しました。');
      setDisableButtonCap(true);
    }
  };

  // const photosClear = (isConfirm?: boolean) => {
  //   if (isConfirm) {
  //     if (!confirmModalClearOpen) {
  //       setConfirmModalClearOpen(true);
  //       return;
  //     }
  //     setConfirmModalClearOpen(false);
  //   }
  //   if (!canvasRef1.current) {
  //     return;
  //   }
  //   const canvas: HTMLCanvasElement = canvasRef1.current;
  //   setFiles(null);
  //   // setNameplate('');
  //   const ctx = canvas.getContext('2d');
  //   if (ctx) {
  //     ctx.clearRect(0, 0, canvas.width, canvas.height);
  //     canvas.width = 260;
  //     canvas.height = 130;
  //   }
  // };

  // const drawImages = async () => {
  //   await drawImage(1);
  // };

  // const drawImage = async (index: number):Promise<void> => {
  //   const s3Url = 'https://staging.photo.win-board.net/';
  //   if (!canvasRef1.current) {
  //     return;
  //   }
  //   const canvas: HTMLCanvasElement = canvasRef1.current;
  //   //
  //   // eslint-disable-next-line prefer-const
  //   if (!canvasRef1.current) {
  //     return;
  //   }

  //   const ctx = canvas.getContext('2d');
  //   if (ctx) {
  //     const img = new Image();
  //     img.crossOrigin = '';

  //     // eslint-disable-next-line no-loop-func
  //     img.onload = function () {
  //       const w = img.width;
  //       const h = img.height;
  //       ctx.clearRect(0, 0, canvas.width, canvas.height);
  //       canvas.width = w;
  //       canvas.height = h;
  //       ctx.drawImage(img, 0, 0, w, h);
  //       canvas.toBlob((blob: any) => {
  //         setFiles(blob);
  //       }, 'image/jpeg');
  //     };
  //     img.src = `${s3Url
  //         + companyCode
  //     }/${staffCode}/${index}.jpg?${Date.now()}`;

  //     //
  //   }
  // };

  const changefile = (index: number, file: any) => {
    if (!file.type.match(/image.*/)) {
      return false;
    }
    handleSubmit(file);
    return true;
  };

  useEffect(() => {
    if (staff) {
      // photosClear();
      // drawImages();
      // setNameplate(staff.nameplate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staff]);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  const functionType = 1;
  return (
    <div>
      <BlockUI blocking={isLoading}>
        <FormContents>
          <OrganizationFilteredTree
            functionType={functionType}
            orgCallback={(args: string | Array<string>) => {
              setOrgCode(String(args));
            }}
            initOrgValue={orgCode}
            staffCode={loginStaffCode}
            orgLabel="所属組職"
          />
          <FormField displayBlock={true}>
            <SelectForm
              label="スタッフ名"
              name="staffCode"
              value={staffCode}
              setValue={setStaffCode}
              options={staffsForOrgCodeOptions}
            />
          </FormField>
          <div css={styles.container}>
            <FlexBox>
              <div css={styles.label} />
              <div css={styles.contentLeft}>
                <FlexBoxItem basis="100%">
                  <div css={styles.textfix}>帽子、マスクなどは外して、顔全体を撮影してください。</div>
                  <FlexBoxItem margin="auto" width="650px">
                    <Webcam
                      style={styles.video}
                      audio={false}
                      height={checkWidthIPad ? 320 : 480}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      width={checkWidthIPad ? 480 : 640}
                      videoConstraints={videoConstraints}
                      onUserMediaError={onUserMediaError}
                      mirrored={true}
                    />

                  </FlexBoxItem>

                  {
                    roleScreen && roleScreen.editable === 1 && (
                      <FlexBoxItem margin="0 auto" width={checkWidthIPad ? '480px' : '650px'}>
                        <FlexBoxItem margin="auto" width="200px">
                          <canvas ref={canvasRef1} id="canvas1" css={styles.canvas} style={{ display: 'none' }} />
                          <PrimaryButton
                            text="カメラで撮影する"
                            onClick={() => capture(webcamRef)}
                            disabled={disableButtonCap}
                            fullwidth={true}
                          />
                          <FormField>
                            <FileSelectButton
                              text="ファイルを選択"
                              setFile={(file) => changefile(1, file)}
                              value=""
                              ghost={true}
                              accept=".jpg,.png,image/jpeg,image/png"
                              fullWidth={true}
                            />
                          </FormField>
                        </FlexBoxItem>
                      </FlexBoxItem>
                    )
                  }

                </FlexBoxItem>
              </div>
            </FlexBox>
          </div>

          {/* <ConfirmModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            onSubmit={() => handleSubmit(canvasRef1)}
            title="確認メッセージ"
            content="更新します。よろしいですか？"
            submitText="更新"
          /> */}

        </FormContents>
      </BlockUI>
    </div>

  );
};

export default MasterShopFaceRegistrationForm;
