import React, { useEffect } from 'react';

import BodyText from 'components/atoms/BodyText';
import { useHistory } from 'react-router-dom';

const SessionExpireError: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.replace('/login');
    }, 5000);
  });
  return (
    <div>
      <BodyText>
        セッションの有効期限が切れています
      </BodyText>
      <br />
      <BodyText>
        ５秒後にログイン画面へリダイレクトします
      </BodyText>
    </div>
  );
};

export default SessionExpireError;
