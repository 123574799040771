import { useEffect, useState } from 'react';
import AttendHelpListDomain from 'domain/master/attend/attendHelpList';
import attendHelpList, {
  subTotalList, searchDate, attendHelpListV2, subTotalListV2, downloadSupportListCsv,
} from 'api/attendHelpList';
import { useFormik } from 'formik';
import { getTrees } from 'api/organization';
import { OptionType } from 'components/atoms/Select';
import moment from 'moment';
import useToastNotification from 'hooks/useToastNotification';
import {getRoleByPath} from 'api/login'
import {useLocation} from 'react-router-dom'
let errors = 0;
type TFunctionRoles = {
        companyCode: string,
        orgCode: string,
        roleCode: number,
        menuId: string,
        available: boolean,
        editable: number,
        importFlag: number,
        downloadFlag: number,
        usabilityGeneralItem1: number,
        usabilityGeneralItem2: number,
        usabilityGeneralItem3: number,
        usabilityGeneralItem4: number,
        usabilityGeneralItem5: number,
        usabilityGeneralItem6: number,
        usabilityGeneralItem7: number,
        usabilityGeneralItem8: number,
        usabilityGeneralItem9: number,
        usabilityGeneralItem10: number,
        notificationType: number
}
type TSubTotalList = {
  staffSubtotal: number,
  attendanceDaysSubtotal: number,
  allWorkTimeSubtotalDisp: string,
  totalCostDisp: string
}
export const useAttendHelpList = () => {
  const [getSearchDate, setSearchDate] = useState([]);
  const [functionRoles,setFunctionRoles] = useState<TFunctionRoles[]>([])
  const path = useLocation()
  
  const onSubmit = () => {
  };

  const { errorNotification } = useToastNotification();

  const formik = useFormik({
    initialValues: AttendHelpListDomain.generateInitial(),
    onSubmit,
    validateOnChange: true,
    validate: async (values) => {
      const targetDateFrom = moment(new Date(values.targetDateFrom)).format('YYYY-MM-DD');
      const targetDateTo = moment(new Date(values.targetDateTo)).format('YYYY-MM-DD');
      const dateFrom = moment([new Date(targetDateFrom).getFullYear(), new Date(targetDateFrom).getMonth(), new Date(targetDateFrom).getDate()]);
      const dateTo = moment([new Date(targetDateTo).getFullYear(), new Date(targetDateTo).getMonth(), new Date(targetDateTo).getDate()]);
      if (dateTo.diff(dateFrom, 'days') > 365) {
        errorNotification('対象期間の範囲は１年以内で入力してください');
        errors = 1;
        return false;
      }
      errors = 0;
    },
  });

  // useEffect(() => {
  //   searchDate(false, '')
  //     .then((response: any) => {
  //       setSearchDate(response);
  //       if (response) {
  //         formik.setFieldValue('setTargetDateFrom', new Date(new Date(response[0]).getFullYear(), new Date(response[0]).getMonth(), new Date(response[0]).getDate()));
  //         formik.setFieldValue('setTargetDateTo', new Date(new Date(response[1]).getFullYear(), new Date(response[1]).getMonth(), new Date(response[1]).getDate()));
  //       }
  //     });
  // }, []);
  const fetch = async() =>{
    const response = await getRoleByPath(path.pathname)
    setFunctionRoles(response)
  }
  useEffect(()=>{
    fetch()
  },[])
  return {
    formik,
    getSearchDate,
    functionRoles
  };
};

export const useGetAttendHelpList = (
  targetDateFrom: any,
  targetDateTo: any,
  supportSourceOrg: string,
  supportDestinationOrg: string,
) => {
  const [getAttendHelpList, setAttendHelpList] = useState<Array<AttendHelpListDomain>>([]);
  useEffect(() => {
    if (!errors && supportDestinationOrg !== '' && supportSourceOrg !== '') {
      attendHelpList(targetDateFrom, targetDateTo, supportSourceOrg, supportDestinationOrg).then((response: Array<any>) => {
        setAttendHelpList(response.map((result) => new AttendHelpListDomain(result)));
      });
    }
  }, [supportDestinationOrg, supportSourceOrg, targetDateFrom, targetDateTo]);
  return { getAttendHelpList, setAttendHelpList };
};
export const useGetAttendHelpListV2 = (
  targetDateFrom: any,
  targetDateTo: any,
  supportSourceOrg: any,
  supportDestinationOrg: any,
  functionType:number,
  categoryAry:Array<Array<string>>,
  destinationCategoryAry:Array<Array<string>>,
  loginStaffCode:string,
) => {
  const [getAttendHelpList, setAttendHelpList] = useState<Array<AttendHelpListDomain>>([]);
  useEffect(() => {
    if (!errors && supportDestinationOrg !== '' && supportSourceOrg !== '') {
      attendHelpListV2(targetDateFrom, targetDateTo, supportSourceOrg, supportDestinationOrg, functionType, categoryAry, destinationCategoryAry, loginStaffCode).then((response: Array<any>) => {
        console.log('GET');
        setAttendHelpList(response.map((result) => new AttendHelpListDomain(result)));
      });
    }
  }, [categoryAry, destinationCategoryAry, functionType, loginStaffCode, supportDestinationOrg, supportSourceOrg, targetDateFrom, targetDateTo]);
  return { getAttendHelpList, setAttendHelpList };
};

export const useGetSubTotalList = (
  targetDateFrom: any,
  targetDateTo: any,
  supportSourceOrg: string,
  supportDestinationOrg: string,
) => {
  const [getSubTotalList, setSubTotalList] = useState([]);
  useEffect(() => {
    if (!errors && supportDestinationOrg !== '' && supportSourceOrg !== '') {
      subTotalList(targetDateFrom, targetDateTo, supportSourceOrg, supportDestinationOrg)
        .then((response: any) => {
          setSubTotalList(response);
        });
    }
  }, [supportDestinationOrg, supportSourceOrg, targetDateFrom, targetDateTo]);
  return { getSubTotalList, setSubTotalList };
};
export const useGetSubTotalListV2 = (
  targetDateFrom: any,
  targetDateTo: any,
  supportSourceOrg: string,
  supportDestinationOrg: string,
  functionType:number,
  categoryAry:Array<Array<string>>,
  destinationCategoryAry:Array<Array<string>>,
  loginStaffCode:string,
) => {
  const [getSubTotalList, setSubTotalList] = useState<TSubTotalList>({
    staffSubtotal: 0,
    attendanceDaysSubtotal: 0,
    allWorkTimeSubtotalDisp: '0',
    totalCostDisp: '0'
  });
  useEffect(() => {
    if (!errors && supportDestinationOrg !== '' && supportSourceOrg !== '') {
      subTotalListV2(targetDateFrom, targetDateTo, supportSourceOrg, supportDestinationOrg, functionType, categoryAry, destinationCategoryAry, loginStaffCode)
        .then((response: any) => {
          setSubTotalList(response);
        });
    }
  }, [categoryAry, destinationCategoryAry, functionType, loginStaffCode, supportDestinationOrg, supportSourceOrg, targetDateFrom, targetDateTo]);
  return { getSubTotalList, setSubTotalList };
};

export const useSearchDate = (closeDateFlg: any, targetDate?: any) => {
  const [getSearchDate, setSearchDate] = useState([]);
  useEffect(() => {
    searchDate(closeDateFlg, targetDate || '')
      .then((response: any) => {
        if (response !== undefined) {
          setSearchDate(response);
        }
      });
  }, [closeDateFlg, targetDate]);
  return { getSearchDate };
};

export const useGetTrees = (targetDateFrom: any, targetDateTo: any) => {
  const [getOrgTree, setOrgTree] = useState<Array<OptionType>>([]);

  useEffect(() => {
    if (!errors) {
      getTrees(targetDateFrom, targetDateTo).then((response) => {
        const orgs = response.map((res) => ({
          value: res.orgCode,
          label: `${res.orgCode} ${res.orgName}`,
        }));
        orgs.unshift({
          value: '',
          label: 'all 全店舗',
        });
        setOrgTree(orgs);
      });
    }
  }, [targetDateFrom, targetDateTo]);
  return {
    getOrgTree,
  };
};

export const useDownload = () => {
  const handleDownloadSupportListCsv = (data: any) => {
    const fileName = `他店舗支援_${moment().format('YYYYMMDDHHmmss')}.csv`;
    downloadSupportListCsv(data, fileName);
  };

  return {
    handleDownloadSupportListCsv,
  };
};

export default useAttendHelpList;
