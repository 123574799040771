/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';
import { backgroundColor } from 'components/styles';

const style = css({
  backgroundColor: backgroundColor.main,
});

const MainContents: React.FC<{
  additionalCss?: SerializedStyles
}> = ({ children, additionalCss }) => (
  <main className="MainContentPrint" css={additionalCss ? css(style, additionalCss) : style}>
    {children}
  </main>
);

export default MainContents;
