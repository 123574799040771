import { useState, useCallback, useMemo } from 'react';
import { postStaffCsvImport, Error } from 'api/staff';
import useToastNotification from 'hooks/useToastNotification';
import { UploadType } from './StaffCsvUploadForm';
import { Auth } from 'aws-amplify';
import LoginUserDomain from 'domain/loginUser';
import { SessionStorageKey } from 'utility/constants';
import useLoginUser from 'hooks/useLoginUser';


export const useStaffCsvForm = () => {
  // アップロードタイプ
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [uploadType, setUploadType] = useState<UploadType>('0');
  const { successNotification, errorNotification } = useToastNotification();
  const [blocking, setBlocking] = useState(false);
  const { storeLoginUserToSessionStorage } = useLoginUser();

  const uploadTypeItems: Array<{ label: string, value: UploadType }> = useMemo(() => ([
    {
      label: 'スタッフ情報',
      value: '0',
    },
    {
      label: '人件費情報',
      value: '1',
    },
    {
      label: 'マネージャー権限',
      value: '2',
    },
  ]), []);

  // アップロードファイル
  const [uploadFile, setUploadFile] = useState<File>();

  // アップロードエラー
  const [errors, setErrors] = useState<Array<string>>();

  const extensionFile = (name: string) => name.split('.').pop();

  if (uploadFile) {
    const fileType = extensionFile(uploadFile.name);
    if (fileType !== 'csv' && fileType !== 'xls' && fileType !== 'xlsx') {
      // errorNotification('CSVファイルを選択してください。');
      setErrors(['CSVファイルを選択してください。']);
      setUploadFile(undefined);
    }
  }

  // アップロード実行処理
  const onSubmit = useCallback(() => {
    setConfirmModalOpen(false);

    if (!uploadFile) {
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    // エラー表示を初期化
    setErrors(undefined);
    setBlocking(true);
    postStaffCsvImport(uploadType, uploadFile).then(async (response) => {
      setBlocking(false);
      if (response.status && response.status !== 200) {
        let errorString = '';
        (response.data.errors as Array<Error>).map((err) => {
          errorString += `${err.defaultMessage} \n`;
          return errorString;
        });
        // setErrors((response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        errorNotification(errorString);
      } else {
        if (response.data.loginUser) {
          const loginUser = new LoginUserDomain(response.data.loginUser);
          if(loginUser.mail !== sessionStorage.getItem(SessionStorageKey.LoginUser.MAIL)) {
            // メールアドレスが変更されている場合はCognitoの認証ユーザー情報を再取得
            // NOTE: `bypassCache: true`でキャッシュに保存されているユーザー情報を無視し、最新のユーザー情報を取り直す
            await Auth.currentAuthenticatedUser({ bypassCache: true });
          }
          storeLoginUserToSessionStorage(loginUser);
        }
        successNotification('インポートが完了しました。');
      }
    }).catch((error) => {
      setBlocking(false);
      setUploadFile(undefined);
      if (error && error.response && error.response.data.errors && error.response.data.errors.length > 0 && error.response.status !== 200) {
        let errorString = '';
        (error.response.data.errors as Array<Error>).map((err) => {
          errorString += `${err.defaultMessage} \n`;
          return errorString;
        });
        setErrors((error.response.data.errors as Array<Error>).map((err) => err.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [confirmModalOpen, errorNotification, successNotification, uploadFile, uploadType]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    confirmModalOpen,
    closeConfirmModal,
    uploadType,
    setUploadType,
    uploadTypeItems,
    uploadFile,
    setUploadFile,
    onSubmit,
    errors,
    blocking,
  };
};

export default {};
