import React, { useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { Row, Col } from 'react-grid-system';
import BodyText from 'components/atoms/BodyText';
import TabControl from 'components/atoms/TabControl';
import BlockUI from 'components/molecules/BlockUi';
import moment from 'moment';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { css } from '@emotion/core';
import PaidHolidayStatus from 'components/organismos/master/attend/AttendMonthlyForm/PaidHolidayStatus';
import { eventScroll, useGetTimeList, useGetPaidHoliday } from './hooks';
import ToastModal from '../../../ToastModal';
import DatePickerForm from '../../../../molecules/DatePickerForm';
import TimeTable from './TimeTable';
import DayTable from './DayTable';

type SelectTab = 'tab_time_table' | 'tab_day_table'|'tab_paid_table';
const SelectTab: { [key in SelectTab]: SelectTab } = {
  tab_time_table: 'tab_time_table',
  tab_day_table: 'tab_day_table',
  tab_paid_table: 'tab_paid_table',
};

const AttendResultForm: React.FC = () => {
  const [toastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [selectTab, setSelectTab] = React.useState<SelectTab>(
    SelectTab.tab_time_table,
  );
  const [isLoading, setLoading] = useState(false);

  const currentDate = new Date();
  const firstDayStr = moment(currentDate).format('YYYY-MM-01');
  const lastDayStr = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
  ).format('YYYY-MM-DD');
  const [targetDateFrom, setTargetDateFrom] = useState(
    sessionStorage.getItem('attendResult.searchTargetDateFrom')
      ? new Date(sessionStorage.getItem('attendResult.searchTargetDateFrom')!)
      : new Date(firstDayStr),
  );
  const [targetDateTo, setTargetDateTo] = useState(
    sessionStorage.getItem('attendResult.searchTargetDateTo')
      ? new Date(sessionStorage.getItem('attendResult.searchTargetDateTo')!)
      : new Date(lastDayStr),
  );

  const [selectedClosingDate] = useState(
    sessionStorage.getItem('headquartersFinal.monthlyList.selClosingDate')
      || '-1',
  );
  const [selectedEmploymentId] = useState(
    sessionStorage.getItem(
      'headquartersFinal.monthlyList.searchEmploymentId',
    ) || '',
  );
  const [attendFlag] = useState(
    '0',
    // sessionStorage.getItem('headquartersFinal.monthlyList.attendFlag') || '0',
  );
  const [selectOrgCode, setSelectOrgCode] = useState(
    sessionStorage.getItem('attendResult.selectOrgCode')
      ? sessionStorage.getItem('attendResult.selectOrgCode')
      : sessionStorage.getItem('loginUser.orgCode'),
  );
  const [categoryArr, setCategoryArr] = useState<Array<String>>([]);

  console.log(
    'headquartersFinal.monthlyList.selectOrgCode',
    sessionStorage.getItem('headquartersFinal.monthlyList.selectOrgCode'),
  );

  const {
    term,
    business,
    subTotalTimeList,
    subTotalDayList,
    holidaySubtotalItem,
  } = useGetTimeList(
    setLoading,
    targetDateFrom,
    targetDateTo,
    selectedClosingDate,
    attendFlag,
    selectOrgCode,
    selectedEmploymentId,
  );
  const functionType = 2;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const { getPaidHolidayStatusList } = useGetPaidHoliday(selectOrgCode || '', false);

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <Row>
          <Col md={2}>
            <div style={{ marginTop: '10px' }}>
              <BodyText>対象期間</BodyText>
            </div>
          </Col>
          <Col md={10}>
            <FlexBox>
              <FlexBoxItem>
                <DatePickerForm
                  dateFormat="yyyy年MM月dd日"
                  label=""
                  isFullWidth={true}
                  date={targetDateFrom}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      setTargetDateFrom(date);
                      sessionStorage.setItem(
                        'attendResult.searchTargetDateFrom',
                        moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                      );
                    }
                  }}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <span style={{ margin: '0 30px' }}>～</span>
              </FlexBoxItem>
              <FlexBoxItem>
                <DatePickerForm
                  dateFormat="yyyy年MM月dd日"
                  label=""
                  isFullWidth={true}
                  date={targetDateTo}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      setTargetDateTo(date);
                      sessionStorage.setItem(
                        'attendResult.searchTargetDateTo',
                        moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                      );
                    }
                  }}
                />
              </FlexBoxItem>
            </FlexBox>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <OrganizationFilteredTree
              functionType={functionType}
              staffCode={staffCode}
              targetdayForm={targetDateFrom}
              targetdayTo={targetDateTo}
              orgLabel="組織名"
              initOrgValue={String(selectOrgCode)}
              orgCallback={(args: string | Array<string>) => {
                setSelectOrgCode(String(args));
                sessionStorage.setItem(
                  'attendResult.selectOrgCode',
                  String(args),
                );
              }}
              initCatagory={(args: string) => {
                sessionStorage.setItem('attendResult.selectCategory', JSON.stringify(args));
              }}

              orgCategoryCallback={(args: any) => {
                setCategoryArr(args);
                sessionStorage.setItem('attendResult.selectCategoryOption', JSON.stringify(args));
              }}

              styles={{
                label: {
                  toggleSwitch: css({ marginLeft: '0px' }),
                },
                toggle: {
                  width: '13rem',
                },
              }}
            />
          </Col>
        </Row>
        <div style={{ marginTop: '30px' }}>
          <TabControl
            items={[
              {
                value: SelectTab.tab_time_table,
                display: '集計時間データ',
              },
              {
                value: SelectTab.tab_day_table,
                display: '集計日数データ',
              },
              {
                value: SelectTab.tab_paid_table,
                display: '有給使用状況(現時点)',
              },
            ]}
            setValue={setSelectTab as (arg: string) => void}
            currentValue={selectTab}
            isFixed={false}
            largeWidth={true}
          />
          {selectTab === SelectTab.tab_time_table && (
            <div style={{ marginTop: '20px' }}>
              <TimeTable
                eventScroll={eventScroll}
                subTotalTimeList={subTotalTimeList}
                business={business || []}
                term={term}
                targetDateFrom={targetDateFrom}
                targetDateTo={targetDateTo}
                selectedEmploymentId={selectedEmploymentId}
                selectOrgCode={String(selectOrgCode)}
                selectedClosingDate={selectedClosingDate}
                attendFlag={attendFlag}
              />
            </div>
          )}
          {selectTab === SelectTab.tab_day_table && (
            <div style={{ marginTop: '20px' }}>
              <DayTable
                eventScroll={eventScroll}
                holidaySubtotalItem={holidaySubtotalItem}
                subTotalDayList={subTotalDayList}
              />
            </div>
          )}
          {selectTab === SelectTab.tab_paid_table
            && (
              <div style={{ marginTop: '20px' }}>
                <PaidHolidayStatus getPaidHolidayStatusList={getPaidHolidayStatusList} />
              </div>
            )}
        </div>
        <ToastModal
          open={toastModalOpen}
          closeHandler={() => setToastModalOpen(false)}
          title={toastMessage}
        />
      </FormContents>
    </BlockUI>
  );
};

export default AttendResultForm;
