import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import React, { useEffect, useState } from 'react';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import FlexBox from 'components/atoms/FlexBox';
import FormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import FormLabel from 'components/atoms/Form/FormLabel';
import SelectForm from 'components/molecules/SelectForm';
import Button from 'components/atoms/Button';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import FormTitle from 'components/atoms/Form/FormTitle';
import BlockUI from 'components/molecules/BlockUi';
import DatePickerForm from 'components/molecules/DatePickerForm';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import BodyText from 'components/atoms/BodyText';
import { utilityColor } from 'components/styles';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import useSalesSetting from './hook';
import TextInput from '../../../../atoms/Form/TextInput';


const SalesSettingForm = () => {
  const {
    formik, confirmModalOpen, closeConfirmModal, setConfirmModalOpen, isLoading,
  } = useSalesSetting();
  const [isUseReportApproval, setIsUseReportApproval] = useState(formik.values.useReportApproval);
  const [isManageRevenueStamp, setIsManageRevenueStamp] = useState(
    formik.values.manageRevenueStamp,
  );
  useEffect(() => {
    setIsUseReportApproval(formik.values.useReportApproval);
  }, [isUseReportApproval, formik]);
  useEffect(() => {
    setIsManageRevenueStamp(formik.values.manageRevenueStamp);
  }, [isManageRevenueStamp, formik]);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <FormContents>
              <FormTitle title="F率算出設定" />
              <BodyText color={utilityColor.error} size="sm">ダッシュボード、レポートのF率を、仕入れ金額、標準原価、どちらで算出するかを設定します。</BodyText>
              <FormField>
                <FormLayout
                  label={(
                    <FormLabel
                      label="F率算出項目"
                    />
                  )}
                  input={(
                    <FlexBox>
                      <SelectForm
                        label=""
                        name="calculationItemOfFRate"
                        value={String(formik.values.calculationItemOfFRate)}
                        options={[
                          {
                            label: '仕入れ',
                            value: '0',
                          },
                          {
                            label: '標準原価',
                            value: '1',
                          },
                        ]}
                        setValue={(cal: string) => {
                          formik.setFieldValue('calculationItemOfFRate', cal);
                        }}
                      />
                    </FlexBox>
                  )}
                />
              </FormField>

            </FormContents>

            <FormContents>
              <FormTitle title="仕入れ金額" />
              <BodyText color={utilityColor.error} size="sm">ダッシュボード、帳票、レポートに表示する仕入れ金額に小口現金を含めるかを設定します。</BodyText>
              <FormField>
                <RadioSelectForm
                  label="小口現金"
                  subLabel=""
                  name="includePettyCashInFRate"
                  items={[
                    {
                      label: '含める',
                      value: 'true',
                    },
                    {
                      label: '含めない',
                      value: 'false',
                    },
                  ]}
                  value={String(formik.values.includePettyCashInPurchase)}
                  // setValue={(e) => {
                  //   formik.setFieldValue('includePettyCashInFRate', String(e.target.value));
                  // }}
                  setValue={(e) => {
                    formik.setFieldValue('includePettyCashInPurchase', e.target.value === 'true');
                  }}

                />
              </FormField>

            </FormContents>

            {/* Form content 3 */}
            <FormContents>
              <FormTitle title="レポート設定" />
              <BodyText color={utilityColor.error} size="sm">レポートの承認機能を使用するかをに表示する仕入れ金額に小口現金を含めるかを設定します。</BodyText>
              <FormField>
                <RadioSelectForm
                  label="レポート承認機能"
                  name="useReportApproval"
                  items={[
                    {
                      label: '使用する',
                      value: 'true',
                    },
                    {
                      label: '使用しない',
                      value: 'false',
                    },
                  ]}
                  value={String(formik.values.useReportApproval)}
                  setValue={(e) => {
                    setIsUseReportApproval(e.target.value);
                    formik.setFieldValue('useReportApproval', e.target.value === 'true');
                    formik.setFieldValue('reportApprovalStartDate', moment(formik.values.reportApprovalStartDate !== null ? moment(
                      formik.values.reportApprovalStartDate.toString(),
                    ).toDate() : new Date()).format('YYYY-MM-DD'));
                  }}
                />
              </FormField>

              <BodyText>使用開始日</BodyText>
              <FormField>
                <DatePickerForm
                  dateFormat="yyyy年MM月dd日"
                  label=""
                  date={formik.values.reportApprovalStartDate !== null ? moment(
                    formik.values.reportApprovalStartDate.toString(),
                  ).toDate() : new Date()}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      formik.setFieldValue('reportApprovalStartDate', moment(date).format('YYYY-MM-DD'));
                    }
                  }}
                  isFullWidth={true}
                  disable={formik.values.useReportApproval === 'false' ? true : Boolean(isUseReportApproval) !== true}
                  isShowDateOnDisable={true}
                />
              </FormField>
              {/* add new form field in UI3  */}
              <BodyText color={utilityColor.error} size="sm">レポートで収入印紙の枚数管理を行うかを設定します。</BodyText>

              <FormField>
                <RadioSelectForm
                  label="収入印紙の枚数管理"
                  subLabel=""
                  name="manageRevenueStamp"
                  items={[
                    {
                      label: '使用する',
                      value: 'true',
                    },
                    {
                      label: '使用しない',
                      value: 'false',
                    },
                  ]}
                  // value={String(formik.values.useReportApproval)}
                  // setValue={(e) => {
                  //   setIsUseReportApproval(e.target.value)
                  //   formik.setFieldValue('useReportApproval', e.target.value === 'true');
                  //   formik.setFieldValue('reportApprovalStartDate', moment(moment(
                  //   formik.values.reportApprovalStartDate.toString(),
                  // ).toDate()).format('YYYY-MM-DD'));
                  // }}
                  value={String(formik.values.manageRevenueStamp)}
                  // setValue={(e) => {
                  //   formik.setFieldValue('includePettyCashInFRate', String(e.target.value));
                  // }}
                  setValue={(e) => {
                    setIsManageRevenueStamp(e.target.value);
                    formik.setFieldValue('manageRevenueStamp', e.target.value === 'true');
                    formik.setFieldValue('manageRevenueStampStartDate', moment(formik.values.manageRevenueStampStartDate !== null ? formik.values.manageRevenueStampStartDate : new Date()).format('YYYY-MM-DD'));
                  }}
                />
              </FormField>

              <BodyText>使用開始日</BodyText>
              <FormField>
                <DatePickerForm
                  dateFormat="yyyy年MM月dd日"
                  label=""
                  date={formik.values.manageRevenueStampStartDate !== null ? moment(
                    formik.values.manageRevenueStampStartDate.toString(),
                  ).toDate() : new Date()}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      formik.setFieldValue('manageRevenueStampStartDate', moment(date).format('YYYY-MM-DD'));
                    }
                  }}
                  isFullWidth={true}
                  disable={formik.values.manageRevenueStamp === 'false' ? true : Boolean(isManageRevenueStamp) !== true}
                  isShowDateOnDisable={true}
                />
              </FormField>

              <BodyText>コメント欄のタイトル設定</BodyText>
              <FormField>
                <RadioSelectForm
                  items={[
                    {
                      label: 'しない',
                      value: 'false',
                    },
                    {
                      label: 'する',
                      value: 'true',
                    },
                  ]}
                  name="commentSubjectSetting"
                  value={String(formik.values.commentSubjectSetting)}
                  setValue={(e) => formik.setFieldValue('commentSubjectSetting', e.target.value === 'true')}
                />
              </FormField>
              {
                formik.values.commentSubjectSetting && (
                  <>
                    <FormField>
                      <TextInput
                        name="commentSubjectForBudgetProcess"
                        value={
                          formik.values.commentSubjectForBudgetProcess
                        }
                        onChange={
                          (e) => formik.setFieldValue('commentSubjectForBudgetProcess', e.target.value)
                        }
                      />
                    </FormField>
                    <FormField>
                      <TextInput
                        name="commentSubjectForStoreManagement"
                        value={
                          formik.values.commentSubjectForStoreManagement
                        }
                        onChange={
                          (e) => formik.setFieldValue('commentSubjectForStoreManagement', e.target.value)
                        }
                      />
                    </FormField>
                    <FormField>
                      <TextInput
                        name="commentSubjectForOthers"
                        value={
                          formik.values.commentSubjectForOthers
                        }
                        onChange={
                          (e) => formik.setFieldValue('commentSubjectForOthers', e.target.value)
                        }
                      />
                    </FormField>
                  </>
                )
              }
            </FormContents>
            {/* ---------------------------------------------------------------- */}
            {
              roleScreen && roleScreen.editable === 1 && (
                <div style={{ marginLeft: '16px' }}>
                  <FormSubmitArea>
                    <Button
                      text="登録"
                      onClick={() => setConfirmModalOpen(true)}
                    />
                  </FormSubmitArea>
                </div>
              )
            }
          </div>
        </form>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          actionType={ActionType.CREATE}
        />
      </FormContents>
    </BlockUI>
  );
};

export default SalesSettingForm;
