import ApiClient from './ApiClient';

export interface TimeOutputLayout {
  companyCode: string;
  layoutId: string;
  layoutCode: string;
  layoutName: string;
  processType: string;
}

/**
 * get list time output layout
 *
 *
 */
export const getOutputLayoutList = async (): Promise<Array<TimeOutputLayout>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/outputlayouts/${companyCode}`, {});
  return response.data;
};


/**
 * getOutputLayout
 *
 * @param layoutId
 */
export const getOutputLayoutById = async (layoutId: string): Promise<Array<TimeOutputLayout>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/outputlayouts/${companyCode}/${layoutId}`, {});
  return response.data;
};


/**
 * createOrUpdateTimeOutputLayout
 *
 * @param companyCode
 * @param dataTimeOutputLayout
 */
export const createOrUpdateTimeOutputLayout = async (companyCode: string, dataTimeOutputLayout: any) => {
  // TODO パラメータ確認
  const query = {
  };
  const response = await ApiClient.post(`/v2/outputlayouts/${companyCode}`, query, dataTimeOutputLayout);
  return response.data;
};

/**
 * delete
 *
 */
export const deleteTimeOutputLayoutById = async (layoutId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const reponse = await ApiClient.delete(`/v1/outputlayouts/${companyCode}/${layoutId}`, {});
  return reponse.data;
};

/**
 * get
 *
 */
export const getOutputBaseItems = async (baseItemType: number): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    baseItemType,
  };
  const reponse = await ApiClient.get(`/v1/outputlayouts/baseitem/${companyCode}`, query);
  return reponse.data;
};

/**
 * getOutputLayoutCreate
 *
 *
 */
export const getOutputLayoutCreate = async (): Promise<Array<TimeOutputLayout>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/outputlayouts/detail/${companyCode}`, {});
  return response.data;
};


/**
 * reflectData
 *
 *
 */
export const reflectData = async (companyCode: string, data: any) => {
  // TODO パラメータ確認
  const query = {
  };
  const response = await ApiClient.post(`/v1/outputlayouts/outputitem/${companyCode}`, query, data);
  return response.data;
};


export default {
  getOutputLayoutList,
};
