import React, { SVGProps } from 'react';

const SortDown: React.FC<SVGProps<SVGSVGElement>> = props => (
	<svg version="1.1" id="contents" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 800 800" xmlSpace="preserve" {...props}>
  		<style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t.st0{fill:#B3B3B3;}\n" }} />
  		<polygon className="st0" points="389.52,166.06 285.4,346.41 493.65,346.41 " />
  		<polygon points="389.52,597.4 493.65,417.05 285.4,417.05 " />
	</svg>
);

export default SortDown;
