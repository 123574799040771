/** @jsx jsx */
import React, { useEffect, useState } from 'react';

import SelectForm from 'components/molecules/SelectForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import HourInput from 'components/atoms/Form/HourInput';
import FlexBox from 'components/atoms/FlexBox';
import { Link, useHistory } from 'react-router-dom';

import {
  grayScale, productColor,
} from 'components/styles';
import DataTable from 'components/organismos/DataTable/DataTable';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import { css, jsx } from '@emotion/core';
import Accordion from 'components/molecules/Accordion';
import SpecialHolidayStatusDomain from 'domain/master/holidayManagement/specialHolidayStatus';
import { Column } from 'react-table';
import Modal from 'components/molecules/Modal';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { useManagementInformationDomainForm, useDownloadHolidayManagement } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
// create style form
const styles = {
  form: css({
    paddingTop: '15px',
  }),
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
    minWidth: '100px',
  }),
  digestionDays: css({
    width: '150px',
  }),
  betweenDays: css({
    width: '50px',
    textAlign: 'center',
  }),
  detail: css({
    backgroundColor: productColor.primary,
    borderRadius: '5px',
    textAlign: 'center',
    marginLeft: '5px',
  }),
  detailButton: css({
    padding: '4px',
    color: 'white',
  }),
  textMessage: css({
    color: 'black',
  }),
};

export interface DataDetail {
  staffCode: string,
  staffName: string,
  holidayName: string
}

const ManagementInformationForm: React.FC<
  {
    employmentOptions: any,
    specialHolidayOptions: any,
    currentAccordionList: any,
    setCurrentAccordionList: any
  }
> = (
  {
    employmentOptions,
    specialHolidayOptions,
    currentAccordionList,
    setCurrentAccordionList,
  },
  ) => {
    const {
      paidHolidayStatusList,
      orgCode,
      setOrgCode,
      employmentId,
      setOrgEmploymentId,
      modalOpen,
      setModalOpen,
      closeModal,
      detailItems,
      setDetailItems,
      callPaidHolidayStatusDetail,
      callSaveUseItemForPaidHolidayStatus,
      specialManageEmpSelect,
      setSpecialManageEmpSelect,
    } = useManagementInformationDomainForm();

    const {
      downloadPaidHolidayStatus,
    } = useDownloadHolidayManagement(
      orgCode,
      employmentId,
      specialManageEmpSelect,
    );


    //
    const [dataDetail, setDataDetail] = useState<DataDetail>({
      staffCode: '',
      staffName: '',
      holidayName: '',
    });

    // handlePaidHolidayStatusDetail
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlePaidHolidayStatusDetail = async (data: any) => {
      setDataDetail(data);
      callPaidHolidayStatusDetail(
        data.staffCode,
        data.staffName,
        data.holidayId,
        data.holidayName,
        data.specialHolidayGrantId ? data.specialHolidayGrantId : '',
      );
      setModalOpen(true);
    };

      const history = useHistory();

      // Check prototype role staff
      const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

      const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

      const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

      useEffect(() => {
        if (checkUserRole && checkUserRole.available === false) {
          history.push('/404')
        }
      }, [checkUserRole]);

      const columns: Array<Column<SpecialHolidayStatusDomain>> = React.useMemo(() => [
        {
          Header: () => 'スタッフコード',
          id: 'staffCode',
          accessor: 'staffCode',
          sortType: 'basic',
          Cell: (cell: { row :{ isExpanded: boolean, original: SpecialHolidayStatusDomain },
        data: Array<SpecialHolidayStatusDomain>
      }) => (
        <FlexBox>
          {cell.row.original.staffCode}
          <div css={css(styles.detail)}>
            {checkUserRole && checkUserRole.editable === 1 && (
            <ClickableIconButton
              onClick={() => handlePaidHolidayStatusDetail(cell.row.original)}
              additionalCss={styles.detailButton}
            >
              編集
            </ClickableIconButton>
            )}
          </div>
        </FlexBox>
          ),
        },
        {
          Header: 'スタッフ名',
          accessor: 'staffName',
        },
        {
          Header: '休暇コード',
          accessor: 'holidayCode',
        },
        {
          Header: '休暇名',
          accessor: 'holidayName',
        },
        {
          Header: '付与数',
          accessor: 'grantDaysNumSumStr',
        },
        {
          Header: '消化数',
          accessor: 'digestedDaysNumStr',
        },
        {
          Header: '残日数',
          accessor: 'remainDaysNumStr',
        },
        {
          Header: '有効期間(開始)',
          accessor: 'useStartDate',
        },
        {
          Header: '有効期間(終了)',
          accessor: 'useEndDate',
        },
        {
          Header: '次回付与日',
          accessor: 'nextGrantDate',
        },
        {
          Header: '次回予定付与日数',
          accessor: 'nextGrantDaysStr',
        },

      ], [checkUserRole, handlePaidHolidayStatusDetail]);

    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const accessLevel = sessionStorage.getItem('loginUser.accessLevel');
    const managementOrg = 4;
    return (
      <Accordion
        text="管理情報"
        color={grayScale.gray100}
        callBack={() => { }}
        defaultExpand={!!((sessionStorage.getItem('attendHolidayManagement.accordion.specialHoliday.management') === String(true)))}
        accordionValue="specialHoliday.management"
      >
        <div>
          <OrganizationFilteredTree
            functionType={2}
            staffCode={staffCode}
            orgLabel="組織名"
            initOrgValue={String(orgCode)}
            orgCallback={(args: string | Array<string>) => {
              setOrgCode(String(args));
            }}
            addAllItem={false}
            styles={{
              label: {
                toggleSwitch: css({ marginLeft: '0px' }),
              },
              toggle: {
                width: '13rem',
              },
            }}
            orgFilter={managementOrg}
          />

          <SelectForm
            label="雇用形態"
            name="employmentId001"
            value={String(employmentId)}
            setValue={(val: string) => {
              setOrgEmploymentId(val);
              sessionStorage.setItem('specialHoliday.managementInformation.employmentId', String(val));
            }}
            options={employmentOptions}
          />

          <SelectForm
            label="休暇"
            name="specialManageHolidaySelect"
            value={String(specialManageEmpSelect)}
            setValue={(val: string) => {
              setSpecialManageEmpSelect(val);
              sessionStorage.setItem('specialHoliday.managementInformation.specialManageHolidaySelect', String(val));
            }}
            options={specialHolidayOptions}
          />
          <div
            css={css`
              table th:nth-child(1) {
                width: 140px;
              }
              table th:nth-child(2) {
                width: 100px;
              }
              table th:nth-child(3) {
                width: 100px;
              }
              table th:nth-child(4) {
                width: 140px;
              }
              table th:nth-child(5) {
                width: 80px;
              }
              table th:nth-child(6) {
                width: 80px;
              }
              table th:nth-child(7) {
                width: 80px;
              }
              table th:nth-child(8) {
                width: 140px;
              }
              table th:nth-child(9) {
                width: 140px;
              }
              table th:nth-child(10) {
                width: 140px;
              }
              table th:nth-child(11) {
                width: 140px;
              }
            `}
          >

            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
              <DataTable
                columns={columns}
                data={paidHolidayStatusList}
                isGlobalFilter={true}
                minWidth="1368px"
                sortBy={[
                  { id: 'staffCode', desc: false }
                ]}
              />
            </div>
            <FormSubmitArea>
              <div style={{ marginRight: '12px' }}>
                {checkUserRole && checkUserRole.importFlag === 1 && (
                <Link to="/attendHolidayManagementImport/specialHoliday">
                  <Button
                    text="インポート"
                  />
                </Link>
                )}
              </div>

              <div style={{ marginRight: '12px' }}>
                {checkUserRole && checkUserRole.downloadFlag === 1 && (
                <Button
                  text="ダウンロード"
                  onClick={() => downloadPaidHolidayStatus()}
                />
                )}
              </div>
              {Number(accessLevel) === 1 && checkUserRole && checkUserRole.downloadFlag === 1 && (
              <Button
                text="全店舗ダウンロード "
                onClick={() => downloadPaidHolidayStatus(true)}
              />
            )}

          </FormSubmitArea>
          </div>
          {/* Form Detail */}
          <Modal
            open={modalOpen}
            closeHandler={closeModal}
            title="特休編集"
            submitText="登録"
            closeText="閉じる"
            onSubmit={callSaveUseItemForPaidHolidayStatus}
          >
            {detailItems
              && (
                <div style={{ width: '500px' }}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td className="active">スタッフコード：</td>
                        <td>{dataDetail.staffCode}</td>
                      </tr>
                      <tr>
                        <td className="active">スタッフ名：</td>
                        <td>{dataDetail.staffName}</td>
                      </tr>
                      <tr>
                        <td className="active">休暇名：</td>
                        <td>{dataDetail.holidayName}</td>
                      </tr>
                      <tr>
                        <td className="active" />
                        <td />
                      </tr>

                      {detailItems.useEnteringGrantFlg1
                        && (
                          <tr>
                            <td className="active">入社時の付与数:</td>
                            <td>
                              <FlexBox>
                                <div style={{ width: '120px' }}>
                                  <HourInput
                                    name="enteringGrantDay1"
                                    value={Number(detailItems.enteringGrantDay1)}
                                    onChange={(e) => setDetailItems(
                                      {
                                        ...detailItems,
                                        enteringGrantDay1: Number(e.target.value),
                                      },
                                    )}
                                    label="日"
                                    min={0}
                                    max={99}
                                  />
                                </div>
                              </FlexBox>
                            </td>
                          </tr>
                        )}

                      {detailItems.useEnteringGrantFlg2
                        && (
                          <tr>
                            <td className="active">入社3ヶ月後の付与数：</td>
                            <td>
                              <FlexBox>
                                <div style={{ width: '120px' }}>
                                  <HourInput
                                    name="enteringGrantDay2"
                                    value={Number(detailItems.enteringGrantDay2)}
                                    onChange={(e) => setDetailItems(
                                      {
                                        ...detailItems,
                                        enteringGrantDay2: Number(e.target.value),
                                      },
                                    )}
                                    label="日"
                                    min={0}
                                    max={99}
                                  />
                                </div>
                              </FlexBox>

                            </td>
                          </tr>
                        )}

                      {detailItems.useEnteringGrantFlg3
                        && (
                          <tr>
                            <td className="active">入社6ヶ月後の付与数：</td>
                            <td>
                              <FlexBox>
                                <div style={{ width: '120px' }}>
                                  <HourInput
                                    name="enteringGrantDay3"
                                    value={Number(detailItems.enteringGrantDay3)}
                                    onChange={(e) => setDetailItems(
                                      {
                                        ...detailItems,
                                        enteringGrantDay3: Number(e.target.value),
                                      },
                                    )}
                                    label="日"
                                    min={0}
                                    max={99}
                                  />
                                </div>
                              </FlexBox>
                            </td>
                          </tr>
                        )}

                      {detailItems.useGrantFlg
                        && (
                          <tr>
                            <td className="active">付与数：</td>
                            <td>
                              <FlexBox>
                                <div style={{ width: '120px' }}>
                                  <HourInput
                                    name="grantDaysNum"
                                    value={Number(detailItems.grantDaysNum)}
                                    onChange={(e) => setDetailItems(
                                      {
                                        ...detailItems,
                                        grantDaysNum: Number(e.target.value),
                                      },
                                    )}
                                    label="日"
                                    min={0}
                                    max={99}
                                  />
                                </div>
                              </FlexBox>
                            </td>
                          </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    )}
            </Modal>
      </div>
    </Accordion>
  );
};
export default ManagementInformationForm;
