export interface ShiftPatternDetailInput {
  createUser: string,
  updateUser: string,
  shiftPatternDetailId: string,
  shiftPatternId: string,
  businessId: string,
  startTime: string,
  isStartTimeNextDay: boolean,
  endTime: string,
  isEndTimeNextDay: boolean,
}

export interface StampItem {
  startHourForm : string,
  startTimeForm: string,
  startTime: string,
  startTimeOrg: string,
  isStartTimeNextDay: boolean,
  endHourForm: string,
  endTimeForm: string,
  endTime: string,
  endTimeOrg: string,
  isEndTimeNextDay: boolean,
  businessId: string,
  stampId: string,
}

export interface StampModification {
  achievementId: string,
  modifierApplicationId: string,
  staffCode: string,
  orgCode: string,
  orgName: string,
  targetDate: string,
  targetDateStr: string,
  version: string,
  buttonType: number,
  loginUserCode: string,
  stampList: Array<StampItem>,
  applicationReason: string,
  createUser: string,
  updateUser: string,
  modifiyStatus: any,
  applicationStatus: any,
  deleteApplicationAvailable: boolean,
  disableAutoRecess: number,
  displayAutoBreak: boolean
}

export default class AttendStampModificationDomain {
  constructor(private rawData: StampModification) {
    // do nothing
  }

  static generateInitial(): AttendStampModificationDomain {
    return new AttendStampModificationDomain({
      achievementId: '',
      modifierApplicationId: '',
      staffCode: '',
      orgCode: '',
      orgName: '',
      targetDate: '',
      targetDateStr: '',
      version: '',
      buttonType: 0,
      loginUserCode: '',
      stampList: [],
      applicationReason: '',
      createUser: '',
      updateUser: '',
      modifiyStatus: null,
      applicationStatus: null,
      deleteApplicationAvailable: false,
      disableAutoRecess: 0,
      displayAutoBreak: false
    });
  }

  getRawData(): StampModification {
    return this.rawData;
  }

  get modifierApplicationId(): string {
    return this.rawData.modifierApplicationId;
  }

  set modifierApplicationId(modifierApplicationId: string) {
    this.rawData.modifierApplicationId = modifierApplicationId;
  }

  get achievementId(): string {
    return this.rawData.achievementId;
  }

  set achievementId(achievementId: string) {
    this.rawData.achievementId = achievementId;
  }

  get version(): string {
    return this.rawData.version;
  }

  set version(version: string) {
    this.rawData.version = version;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get targetDateStr(): string {
    return this.rawData.targetDateStr;
  }

  set targetDateStr(targetDateStr: string) {
    this.rawData.targetDateStr = targetDateStr;
  }

  get targetDate(): string {
    return this.rawData.targetDate;
  }

  set targetDate(targetDate: string) {
    this.rawData.targetDate = targetDate;
  }

  get stampList(): Array<StampItem> {
    return this.rawData.stampList;
  }

  set stampList(stampList: Array<StampItem>) {
    this.rawData.stampList = stampList;
  }

  get applicationReason(): string {
    return this.rawData.applicationReason;
  }

  set applicationReason(applicationReason: string) {
    this.rawData.applicationReason = applicationReason;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  get buttonType(): number {
    return this.rawData.buttonType;
  }

  set buttonType(buttonType: number) {
    this.rawData.buttonType = buttonType;
  }

  get loginUserCode(): string {
    return this.rawData.loginUserCode;
  }

  set loginUserCode(loginUserCode: string) {
    this.rawData.loginUserCode = loginUserCode;
  }

  get modifiyStatus(): any {
    return this.rawData.modifiyStatus;
  }

  set modifiyStatus(modifiyStatus: any) {
    this.rawData.modifiyStatus = modifiyStatus;
  }

  get applicationStatus(): any {
    return this.rawData.applicationStatus;
  }

  set applicationStatus(applicationStatus: any) {
    this.rawData.applicationStatus = applicationStatus;
  }

  get deleteApplicationAvailable(): boolean {
    return this.rawData.deleteApplicationAvailable;
  }

  set deleteApplicationAvailable(deleteApplicationAvailable: boolean) {
    this.rawData.deleteApplicationAvailable = deleteApplicationAvailable;
  }

  get disableAutoRecess(): number {
    return this.rawData.disableAutoRecess;
  }

  set disableAutoRecess(disableAutoRecess: number) {
    this.rawData.disableAutoRecess = disableAutoRecess;
  }

  get displayAutoBreak(): boolean {
    return this.rawData.displayAutoBreak;
  }

  set displayAutoBreak(displayAutoBreak: boolean) {
    this.rawData.displayAutoBreak = displayAutoBreak;
  }

}
