import { HopeShift } from 'domain/master/general/hopeShift';
import ApiClient from './ApiClient';

type MonthlyShift = {
  [day: string]: { // YYYY-MM-DD形式の日付
    isAlert: boolean;
    number: number;
    isShiftConfirm: boolean;
  }
}

type DailyDrawingInfo = {
  dispStartHour: number;
  dispHour: number;
}

export type ShiftDaily = {
  shiftId: string;
  orgCode: string;
  orgName?: string;
  belongOrgCode?: string;
  staffCode: string;
  targetDate?: string;
  holidayId?: unknown;
  holidayCode?: unknown;
  holidayName?: string;
  holidayType?: unknown;
  isLegal?: boolean;
  startTime: string;
  endTime: string;
  shiftPatternId?: string;
  shiftPatternCode?: string;
  shiftPatternName?: string;
  shortName?: string;
  shiftSupportRequestId?: unknown;
  status?: unknown;
  companyCode?: string;
  distinctionHoliday?: boolean,
  workSystem: number;
  shiftAlertMessage?: unknown;
  shiftIdToString?: string;
  holidayIdToString?: string;
  shiftPatternIdToString?: string;
  shiftSupportRequestIdToString?: string;
  attendType?: string;
}

export type Shift = {
  companyCode: string;
  createUser: string;
  createDate: string;
  updateUser: string;
  updateDate: string;
  shiftId: string;
  orgCode: string;
  belongOrgCode: string;
  staffCode: string;
  targetDate: string;
  holidayId?: string;
  isLegal: boolean;
  shiftPatternId?: string;
  notifiedBeforeShiftStart?: string;
  notifiedToStampForgot: boolean
  startTime: string;
  endTime: string;
  shiftDetailList: Array<ShiftDetail>;
  requestNote?: string;
  deadline?: string;
  procType: number;
  attendType: number;
  orgName?: string;
  staffName?: string;
  closingHour: number;
  distinctionHoliday: boolean;
  shiftPatternOptions?: any
}

export type StaffDailyShift = {
  staffCode: string;
  staffName: string;
  belongOrgCode: string;
  belongOrgName?: string;
  isSupportStaff?: boolean;
  employmentId: string;
  workingForm?: number;
  isAlert: boolean;
  alertMessage?: string;
  employmentIdToString?: string;
  shiftDailyList: Array<ShiftDaily>;
  workSystem: number;
  hopeShift: HopeShift;
}

export type ShiftDailyStaff = {
  staffCode: string;
  staffName: string;
  belongOrgCode: string;
  employmentId: string;
  closingHour: number;
  distinctionHoliday: boolean;
}

export type ShiftDetail = {
  startTime: string;
  isStartTimeNextDay: boolean;
  endTime: string;
  isEndTimeNextDay: boolean;
  businessId?: string;
  createDate?: string;
  createUser?: string;
  updateDate?: string;
  updateUser?: string;
  shiftDetailId?: string;
  isRemote?: boolean;
}

export type ShiftPostData = {
  orgCode: string;
  staffCode: string;
  targetDate: string;
  procType: number;
  attendType: number;
  shiftId?: string;
  shiftPatternId?: string;
  startTime?: string;
  endTime?: string;
  createUser?: string;
  updateUser?: string;
  shiftDetailList: Array<ShiftDetail>;
}

export type ShiftMovePostData = {
  orgCode: string;
  staffCode: string;
  targetDate: string;
  shiftId: string;
  startTime?: string;
  endTime?: string;
  updateUser?: string;
}

export type ShiftDeleteQueryParameter = {
  orgCode: string;
  staffCode: string;
  targetDay: string;
  shiftId: string;
  updateUser?: string;
}

export const getMonthry = async (
  orgCode: string,
  dayFrom: string,
  dayTo: string,
): Promise<MonthlyShift> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    dayFrom,
    dayTo,
  };
  const response = await ApiClient.get(`/v1/shift/monthly/${companyCode}`, params, undefined);
  return response.data;
};

export const getDailyDrawingInfo = async (orgCode: string): Promise<DailyDrawingInfo> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
  };
  const response = await ApiClient.get(`/v1/shift/daily/drawingInfo/${companyCode}`, params, undefined);
  return response.data;
};

export const getDaily = async (
  orgCode: string, targetDay: string, businesses: string,
): Promise<Array<StaffDailyShift>> => {
  const params = {
    orgCode,
    targetDay,
    businesses,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/shift/daily/${companyCode}`, params, undefined);
  return response.data;
};

export const getDailyAddStaffs = async (
  orgCode: string,
  targetOrgCode: string,
  targetDay: string,
): Promise<Array<ShiftDailyStaff>> => {
  const params = {
    orgCode,
    targetOrgCode,
    targetDay,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/shift/daily/add/staffs/${companyCode}`, params, undefined);
  return response.data;
};

export const getShift = async (shiftId: string): Promise<Shift> => {
  const params = {
    shiftId,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/shift/${companyCode}`, params, undefined);
  return response.data;
};

export const postShift = async (
  shiftPostData: ShiftPostData,
): Promise<Array<StaffDailyShift>> => {
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const postData: ShiftPostData = {
    ...shiftPostData,
    createUser: staffName,
    updateUser: staffName,
    shiftDetailList: shiftPostData.shiftDetailList.map((shiftDetail) => ({
      ...shiftDetail,
      createUser: staffName,
      updateUser: staffName,
    })),
  };
  const response = await ApiClient.post(`/v2/shift/${companyCode}`, {}, postData);
  return response.data;
};

export const postShiftMove = async (
  moveType: number, shiftMovePostData: ShiftMovePostData,
): Promise<Array<StaffDailyShift>> => {
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const postData: ShiftMovePostData = {
    ...shiftMovePostData,
    updateUser: staffName,
  };
  const response = await ApiClient.post(`/v2/shift/move/${companyCode}`, { moveType }, postData);
  return response.data;
};

export const deleteShift = async (
  shiftDeleteQueryParameter: ShiftDeleteQueryParameter,
) => {
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const query: ShiftDeleteQueryParameter = {
    ...shiftDeleteQueryParameter,
    updateUser: staffName,
  };

  const response = await ApiClient.delete(`/v2/shift/${companyCode}`, query);
  return response.data;
};

//MonthlyLockOutV1
export type ConfirmType = {
  [key: string]: boolean;
  isConfirmedAllDays: boolean;
};
export interface IShiftConfirmLockOut {
  shiftConfirm: ConfirmType;
  achievementConfirm: ConfirmType;
}
export const confirmMonthlyLockOutV1 = async (orgCode: string, targetDateFrom: string, targetDateTo: string): Promise<IShiftConfirmLockOut> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
  };

  const response = await ApiClient.get(`/v1/mws/confirm/${companyCode}`, params, {});
  return response.data;
};



//Context Hook MonthlyShift Api
export const deleteShiftV1 = async (shiftId: string, updateUser: string): Promise<{ shiftId: string }> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    shiftId,
    updateUser,
  };

  const response = await ApiClient.delete(`/v1/shift/${companyCode}`, params);
  return response.data;
};
export const registerHoliday = async (
  orgCode: string,
  staffCode: string,
  targetDate: string,
  isLegal: boolean,
  createUser: string,
  updateUser: string
): Promise<any> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    orgCode,
    staffCode,
    targetDate,
    isLegal,
    createUser,
    updateUser,
  };

  const response = await ApiClient.post(`/v1/shift/Holiday/${companyCode}`, params, {});
  return response.data;
};

export const copyFromPreviousDay = async (orgCode: string, staffCode: string, targetDate: string, createUser: string): Promise<any> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    orgCode,
    staffCode,
    targetDate,
    createUser,
  };

  const response = await ApiClient.post(`/v1/shift/copy/${companyCode}`, params, {});
  return response.data;
};


// Daily Shift

export const postShiftV1 = async (shiftPostData: ShiftPostData): Promise<Array<StaffDailyShift>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  const response = await ApiClient.post(`/v2/shift/${companyCode}`, {}, shiftPostData);
  return response.data;
};
export default {};
