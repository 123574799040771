import { HierarchySet } from 'components/organismos/Sidebar/HierarchyMode';

export const types = {
  SET_IS_OPEN: 'ui/sidebar/SET_IS_OPEN' as const,
  SET_HIERARCHY: 'ui/sidebar/SET_HIERARCHY' as const,
};

export const setIsOpen = (payload: boolean) => ({
  type: types.SET_IS_OPEN,
  payload,
});

export const setHierarchy = (payload?: HierarchySet) => ({
  type: types.SET_HIERARCHY,
  payload,
});

type Actions = (
  | ReturnType<typeof setIsOpen>
  | ReturnType<typeof setHierarchy>
);

export default Actions;
