import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

export const getHeaderCategory = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/organizationCategoryMstHead/list/${companyCode}`, {});
  return response.data;
};

export const getCategoryItemList = async (
  organizationCategoryMstHeadId: string): Promise<any> => {
  if (!organizationCategoryMstHeadId) {
    return [];
  }
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    organizationCategoryMstHeadId,
  };
  const response = await ApiClient.get(`/v2/organizationCategoryMstDetail/list/${companyCode}`, params);
  return response.data;
};

export const deleteCategoryItemById = async (
  organizationCategoryMstDetailId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    organizationCategoryMstDetailId,
  };

  const response = await ApiClient.delete(`/v2/organizationCategoryMstDetail/delete/${companyCode}`, params);
  return response.data;
};

export const updateOrganzationCategory = async (inUse: boolean, categoryDispName: string,
  organizationCategoryMstHeadId: string, orgCategoryCode: string, dispSort: number) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    organizationCategoryMstHeadId,
    orgCategoryCode,
    dispSort,
    inUse,
    categoryDispName,
    createUser: staffName,
    updateUser: staffName,
  };

  const response = await ApiClient.post(`/v2/organizationCategoryMst/${companyCode}`, {}, params);
  return response.data;
};

export const downloadCSV = async (
  organizationCategoryMstHeadId: string,
  fileName: string,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = { organizationCategoryMstHeadId };
  await ApiClient.downloadCsv(`/v2/organizationCategoryMstDetail/outputCsv/${companyCode}`, params, getDownloadCsvFileName(`${fileName}_`));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const organizationCategoryMstHeadId = sessionStorage.getItem('organizationCategoryMstHeadId') || '';
  const params = {
    formatType: 0,
    createUser: staffName,
    updateUser: staffName,
    organizationCategoryMstHeadId,
  };
  const response = await ApiClient.postFile(`/v2/organizationCategoryMstDetail/csv/import/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export type Error = {
  error: string;
  defaultMessage: string;
}

export const getOrganizationCategoryOptions = async (
  functionType: number,
  staffCode?: string,

): Promise<any> => {
  let params;
  if (staffCode === undefined) {
    params = {
      functionType,
    };
  } else {
    params = {
      staffCode,
      functionType,
    };
  }

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/organizationCategory/trees/${companyCode}`, params);
  return response.data;
};

export const getOrganizationCategoryItem = async (
  organizationCategoryMstDetailId: string): Promise<any> => {
  const params = {
    organizationCategoryMstDetailId,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/organizationCategoryMstDetail/${companyCode}`, params);
  return response.data;
};

export const createOrUpdateOrganizationCategoryItem = async (
  organizationMstDetailId: string, data: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const organizationCategoryMstHeadId = sessionStorage.getItem('organizationCategoryMstHeadId') || '';
  const params: any = {
    companyCode,
    organizationCategoryMstHeadId,
    detailCode: data.detailCode,
    detailName: data.detailName,
    createUser: staffName,
    updateUser: staffName,
  };
  if (organizationMstDetailId && organizationMstDetailId !== 'undefined') {
    params.organizationCategoryMstDetailId = organizationMstDetailId;
  }
  const response = await ApiClient.post(`/v2/organizationCategoryMstDetail/${companyCode}`, {}, params);
  return response.data;
};


export const getSearchOrganizationCategory = async (
  functionType: number,
  categoryAry:Array<Array<string>>,
  staffCode?: string,
  targetDayFrom?:string,
  targetDayTo?: string,
  addAllItem?:boolean,
): Promise<any> => {
  let params:object;
  params = { functionType };
  if (targetDayFrom) {
    params = { ...params, targetDayFrom };
  }
  if (targetDayTo) {
    params = { ...params, targetDayTo };
  }
  if (staffCode) {
    params = { ...params, staffCode };
  }
  if (addAllItem !== undefined) {
    params = { ...params, addAllItem };
  }
  let url = Object.entries(params)
    .map(
      ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
    )
    .join('&');
  if (categoryAry && categoryAry.length) {
    categoryAry.forEach((cateAry) => {
      url += `&categoryAry=${cateAry.join(',')}`;
    });
  }
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/organizationCategory/search/${companyCode}?&${url}`, {});
  return response.data;
};

export default { getOrganizationCategoryOptions, getSearchOrganizationCategory };
