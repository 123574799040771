import ApiClient from "api/ApiClient";
import { AxiosResponse } from 'axios';

export const getOrgTreeBank = async (targetDayFrom: string, targetDayTo: string) : Promise<Array<AxiosResponse>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    functionType: 3,
    targetDayFrom,
    targetDayTo,
    addAllItem: false,
    staffCode,
  };
  // TODO: V4にバージョンアップ予定
  const response = await ApiClient.get(`/v3/organizations/trees/${companyCode}`, params);
  return response.data;
};

export default {};
