/* eslint-disable react/no-array-index-key */
import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { gridCol } from "components/styles";
import IconTooltip from "components/molecules/IconTooltip";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { isMacOs } from "react-device-detect";
import { eventScroll } from "./hooks";
import { SalesBudgetMonthly } from "./type";
import {formatNumberFormatterMinus,formatPercentMinus} from './functions'
const Input: React.FC<{
  id: string;
  value: string;
  handleChange: any;
  disabled: boolean;
}> = ({ id, value, handleChange, disabled = false }) => {
  // const [addMinusOnMaxLength,setAddMinusOnMaxLength] = useState(13)
  // const handleMinusKeyPress = (e:any) => {
  //   if (e.key === '-') {
  //     // max 10 numbers + 3 comma + 1 minus for negative number
  //     setAddMinusOnMaxLength(14)
  //   }else{
  //     // max 10 numbers + 3 comma
  //     setAddMinusOnMaxLength(13)
  //   }
  // };
  return (
    <input
      // type="tel"
      className="form-control input-sm"
      id={id}
      style={{ textAlign: "right", width: "110px", height: "26px" }}
      value={value}
      // tabIndex={(listAdvertising.length * 2 + 9) * (index - 3) + numberElement + 1}
      disabled={disabled}
      onChange={e => handleChange(e)}
      // maxLength={addMinusOnMaxLength}
      // onKeyPress={handleMinusKeyPress}
    />
  );
};
const BudgetYearTable: React.FC<{
  getInputDisable: boolean;
  getSalesExpensesMSTList: any;
  yearFormState: any;
  handleChange: any;
}> = ({
  getInputDisable,
  getSalesExpensesMSTList,
  yearFormState,
  handleChange
}) => {
  const refChangeColumnWidth = useRef(Object());
  const [offsetWidthChangeColumn, setOffsetWidthChangeColumn] = useState(0);
  const refSecondTableTitleWidth = useRef(Object());
  const [
    offsetWidthSecondTableTitle,
    setOffsetWidthSecondTableTitle
  ] = useState(0);
  const [checkScrollBar, setCheckScrollBar] = useState(0);
  const salesBudgetYearLength = yearFormState.generalData.salesBudgetMonthly
    ? yearFormState.generalData.salesBudgetMonthly.length
    : 0;
  console.log(yearFormState, "check form state");
  // console.log(getSalesExpensesMSTList,'fetch 111')
  useEffect(() => {
    const change_column_width = refChangeColumnWidth.current
      ? refChangeColumnWidth.current.offsetWidth
      : 0;
    setOffsetWidthChangeColumn(change_column_width);
  }, [refChangeColumnWidth.current.offsetWidth]);

  useEffect(() => {
    const offsetWidth =
      document.getElementById("wrapTblContent")?.offsetWidth || 0;
    const clientWidth =
      document.getElementById("wrapTblContent")?.offsetWidth || 0;
    if (offsetWidth - clientWidth === 0) {
      setCheckScrollBar(1);
    } else {
      setCheckScrollBar(0);
    }
  }, []);

  const history = useHistory();

  const re = /^[0-9\,\-\b]+$/;

  const formatter = new Intl.NumberFormat("ja-JP", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  const numberFormaterInput = (num: any) => {
    if (typeof num === "undefined" || isNaN(num)) {
      return num;
    }
    const tmp = String(num).split(".");
    if (tmp[1]) {
      let fixed = 3;
      if (tmp[1].length < 3) {
        fixed = tmp[1].length;
      }
      return parseFloat(num).toFixed(fixed);
    }
    return formatter.format(num);
  };

  const handleClickMonth = (e: any) => {
    sessionStorage.setItem("month_selected", e);
    return history.push("/salesBudgetMonth");
  };

  const orgCode = sessionStorage.getItem("orgCode_DestinationOrg");
  let count_dynamic_list_index = 0;
  const {
    listAdvertising,
    listAdvertisingApplyPeriod,
    listAdvertisingExpense,
    listExpenses,
    salesBudgetMonthly
  } = yearFormState.generalData;
  const listAdvertisingDisabled: boolean[] = [];
  const listExpensesDisabled: boolean[] = [];
  const listAdvertisingExpenseDisabled: boolean[] = [];
  const listOthersExpenseDisabled: boolean[] = [];
  salesBudgetMonthly.map(
    (element: Array<Array<number | null | string>>, i: number) => {
      listAdvertising.map(
        (item: Array<Array<number | null | string>>, j: number) => {
          if (listAdvertisingApplyPeriod) {
            for (let k = 0; k < listAdvertisingApplyPeriod.length; k += 1) {
              if (listAdvertising[j][0] === listAdvertisingApplyPeriod[k][0]) {
                if (
                  moment(salesBudgetMonthly[i].id.targetMonth).isBetween(
                    listAdvertisingApplyPeriod[k][1],
                    listAdvertisingApplyPeriod[k][2]
                  )
                ) {
                  listAdvertisingDisabled[listAdvertising[j][0] + i] = false;
                  listExpensesDisabled[listAdvertising[j][0] + i] = false;
                  listAdvertisingExpenseDisabled[
                    listAdvertising[j][0] + i
                  ] = false;
                } else {
                  listAdvertisingDisabled[listAdvertising[j][0] + i] = true;
                  listExpensesDisabled[listAdvertising[j][0] + i] = true;
                  listAdvertisingExpenseDisabled[
                    listAdvertising[j][0] + i
                  ] = true;
                }
                break;
              }
            }
          }
        }
      );
      listExpenses.map(
        (item: Array<Array<number | null | string>>, j: number) => {
          if (getSalesExpensesMSTList) {
            for (let k = 0; k < getSalesExpensesMSTList.length; k += 1) {
              if (
                listExpenses[j][0] ===
                getSalesExpensesMSTList[k].id.expensesCode
              ) {
                if (
                  moment(salesBudgetMonthly[i].id.targetMonth).isBetween(
                    getSalesExpensesMSTList[k].applyStartDate,
                    getSalesExpensesMSTList[k].applyEndDate,
                    getSalesExpensesMSTList[k].applyStartDate,
                    "[]"
                  )
                ) {
                  listOthersExpenseDisabled[listExpenses[j][0] + i] = false;
                } else {
                  listOthersExpenseDisabled[listExpenses[j][0] + i] = true;
                }
                break;
              }
            }
          }
        }
      );
    }
  );

  const isRetina = window.devicePixelRatio > 1;

  return (
    <div className="wrap-budget">
      <div className="headTop" style={{ display: "flex", marginRight: !isMacOs && checkScrollBar ? '-5px' : '0' }}>
        <div style={{ width: "249px", borderRight: "5px solid #d2d6de" }}>
          <table
            className="table table-bordered table-striped table-condensed text-nowrap"
            style={{ width: gridCol.grid12 }}
          >
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <td className="text-center">項目</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="headRight"
          style={
            // isRetina ? {width: 'calc(100% - 249px)'} : { width: 'calc(100% - 247px)' }
            { width: isMacOs ? (checkScrollBar ? 'calc(100% - 252px)' : 'calc(100% - 269px)') : 'calc(100% - 276px)' }
          }
          onScroll={eventScroll}
        >
          <div
            style={{
              width: `${(salesBudgetYearLength + 3) * 125}px`,
              marginRight: "15px"
            }}
          >
            <table
              className="table table-bordered table-striped table-condensed text-nowrap"
              style={{
                width: `${(salesBudgetYearLength + 3) * 125}px`
                // width: '1680px'
              }}
            >
              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <th
                    className="text-center width_row_contents_changes small_width"
                    ref={refChangeColumnWidth}
                  >
                    固変
                  </th>
                  <th className="text-center width_row_contents">合計 </th>
                  {yearFormState.generalData.salesBudgetMonthly.map(
                    (item: SalesBudgetMonthly, e: number) => (
                      <th
                        key={e}
                        className="text-center width_row_contents"
                        onClick={() =>
                          item.existsBudget && orgCode && orgCode !== 'all'
                            ? handleClickMonth(item.id.targetMonth)
                            : ""
                        }
                        style={
                          item.existsBudget && orgCode && orgCode !== 'all'
                            ? {
                                color: "blue",
                                textDecoration: "underline",
                                textDecorationColor: "blue",
                                cursor: "pointer"
                              }
                            : {}
                        }
                      >
                        {moment(item.id.targetMonth, "YYYY/MM/DD").format("M月")}
                      </th>
                    )
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="table-content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: gridCol.grid12
          }}
        >
          {/* First Table */}
          <div style={{ display: "inline-flex", width: gridCol.grid12 }} id="wrapTblContent">
            <div
              className="contentLeft"
              style={{
                width: "249px",
                minWidth: "249px",
                borderRight: "5px solid rgb(210, 214, 222)"
              }}
            >
              <table
                className="table table-bordered table-striped  table-condensed text-nowrap"
                style={{ width: gridCol.grid12 }}
              >
                <tbody>
                  <tr>
                    <td
                      className="text-left active p-left"
                      colSpan={4}
                      style={{
                        backgroundColor: "#fce5cd"
                      }}
                    >
                      売上高
                    </td>
                  </tr>
                  {yearFormState.monthlyListDataInfo.listAdvertising.map(
                    (item: Array<number | null | string>, e: number) => (
                      <tr key={e}>
                        <td
                          style={{
                            minWidth: "25px",
                            width: "7.5%",
                            border: "0px solid black",
                            borderLeft: "1px solid black",
                            backgroundColor: "#fce5cd"
                          }}
                        />
                        <td
                          className="text-right active height_row_contents p-right"
                          colSpan={4}
                        >
                          {item[1]}
                        </td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td
                      style={{
                        minWidth: "7.5%",
                        width: "7.5%",
                        border: "0px solid black",
                        borderLeft: "1px solid black",
                        backgroundColor: "#fce5cd"
                      }}
                    />
                    <td
                      className="text-right active height_row_contents p-right"
                      colSpan={4}
                    >
                      フリー
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="text-center active height_row_contents"
                      colSpan={4}
                      style={{
                        backgroundColor: "#fce5cd"
                      }}
                    >
                      【売上高】計
                    </td>
                  </tr>
                  {/* Start F（食材費）	 */}
                  <tr>
                    <td
                      className="text-left active p-left"
                      style={{
                        backgroundColor: "#cfe2f3"
                      }}
                      colSpan={4}
                    >
                      F（食材費）
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={1}
                      style={{
                        backgroundColor: "#cfe2f3",
                        border: "0px solid black",
                        borderLeft: "1px solid black"
                      }}
                    />
                    <td
                      className="text-right active height_row_contents p-right"
                      colSpan={1}
                    >
                      フード
                    </td>
                  </tr>

                  <tr>
                    <td
                      colSpan={1}
                      style={{
                        backgroundColor: "#cfe2f3",
                        border: "0px solid black",
                        borderLeft: "1px solid black"
                      }}
                    />
                    <td
                      className="text-right active height_row_contents p-right"
                      colSpan={4}
                    >
                      ドリンク
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="text-center active"
                      style={{
                        backgroundColor: "#cfe2f3"
                      }}
                      colSpan={4}
                    >
                      【F】計
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="text-right active p-right">
                      F率
                    </td>
                  </tr>
                  {/* Start 人件費	 */}
                  <tr>
                    <td
                      className="text-left active p-left"
                      colSpan={4}
                      style={{ backgroundColor: "#cfe2f3" }}
                    >
                      L（人件費）
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={1}
                      style={{
                        backgroundColor: "#cfe2f3",
                        border: "0px solid black",
                        borderLeft: "1px solid black"
                      }}
                    />
                    <td
                      className="text-right active height_row_contents p-right"
                      colSpan={1}
                    >
                      社員人件費
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={1}
                      style={{
                        backgroundColor: "#cfe2f3",
                        border: "0px solid black",
                        borderLeft: "1px solid black"
                      }}
                    />
                    <td
                      className="text-right active height_row_contents p-right"
                      colSpan={1}
                    >
                      アルバイト人件費
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="text-center active"
                      colSpan={4}
                      style={{ backgroundColor: "#cfe2f3" }}
                    >
                      【L】計
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right active p-right" colSpan={4}>
                      L率
                    </td>
                  </tr>
                  {/* End 人件費	 */}
                  {/* Start 広告宣伝費	 */}
                  <tr>
                    <td
                      className="text-left active p-left"
                      colSpan={4}
                      style={{ backgroundColor: "#cfe2f3" }}
                    >
                      A（広告費）
                    </td>
                  </tr>
                  {yearFormState.monthlyListDataInfo.listAdvertisingExpense.map(
                    (item: Array<number | null | string>, e: number) => (
                      <tr key={e}>
                        <td
                          colSpan={1}
                          style={{
                            backgroundColor: "#cfe2f3",
                            border: "0px solid black",
                            borderLeft: "1px solid black"
                          }}
                        />
                        <td
                          className="text-right active height_row_contents p-right"
                          colSpan={1}
                        >
                          {item[1]}
                        </td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td
                      className="text-center active"
                      colSpan={4}
                      style={{ backgroundColor: "#cfe2f3" }}
                    >
                      【A】計
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right active p-right" colSpan={4}>
                      A率
                    </td>
                  </tr>
                  {/* End 広告宣伝費	 */}
                  {/* Start FLA合計				*/}
                  <tr>
                    <td colSpan={4} className="text-left p-left lightBlue">
                      FLA合計
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="text-right p-right">
                      FLA率{" "}
                    </td>
                  </tr>
                  {/* End FLA合計				*/}
                  {/* Start 店舗利益				 */}
                  <tr>
                    <td colSpan={4} className="text-left p-left lightYellow">
                      店舗損益
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ backgroundColor: "#fff2cc" }} />
                    <td colSpan={1} className="text-right p-right">
                      店舗損益率
                    </td>
                  </tr>
                  {/* End 店舗利益				 */}
                  {/* Start R（家賃） */}
                  <tr>
                    <td
                      className="text-left active p-left height_row_contents"
                      colSpan={4}
                      style={{ backgroundColor: "#cfe2f3" }}
                    >
                      R（家賃）
                    </td>
                  </tr>
                  {["家賃", "共益費"].map((item, index) => (
                    <tr>
                      <td
                        colSpan={1}
                        style={{
                          backgroundColor: "#cfe2f3",
                          border: "0px solid black",
                          borderLeft: "1px solid black"
                        }}
                      />
                      <td colSpan={1} className="text-right p-right">
                        {item}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      className="text-center active"
                      colSpan={4}
                      style={{ backgroundColor: "#cfe2f3" }}
                    >
                      【R】計
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right active p-right" colSpan={4}>
                      R率
                    </td>
                  </tr>
                  {/* End R（家賃） */}
                  {/* Start O（その他経費）				 */}
                  <tr>
                    <td
                      className="text-center active"
                      colSpan={4}
                      style={{ backgroundColor: "#cfe2f3" }}
                    >
                      O（その他経費）
                    </td>
                  </tr>
                  {yearFormState.monthlyListDataInfo.listExpenses.map(
                    (item: Array<number | null | string>, index: number) => (
                      <tr key={index}>
                        <td
                          className="lightBlue"
                          style={{
                            minWidth: "7.5%",
                            width: "7.5%",
                            border: "0px solid black",
                            borderLeft: "1px solid black"
                          }}
                        />
                        <td
                          className="text-right active height_row_contents p-right"
                          colSpan={4}
                        >
                          {item[1]}
                        </td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td
                      colSpan={1}
                      style={{
                        backgroundColor: "#cfe2f3",
                        border: "0px solid black",
                        borderLeft: "1px solid black"
                      }}
                    />
                    <td
                      className="text-right p-right active height_row_contents"
                      colSpan={1}
                    >
                      仕入（その他）
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="text-center active height_row_contents"
                      colSpan={4}
                      style={{ backgroundColor: "#cfe2f3" }}
                    >
                      【O】計
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="text-right active height_row_contents p-right"
                      colSpan={4}
                    >
                      O率
                    </td>
                  </tr>
                  {/* End O（その他経費）				 */}
                  {/* Start 【FLARO】合計				 */}
                  <tr>
                    <td
                      className="text-left active p-left lightPurple"
                      colSpan={4}
                    >
                      【FLARO】合計
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="text-right active lightPurple p-right"
                      colSpan={4}
                    >
                      FLARO率
                    </td>
                  </tr>
                  {/* End 【FLARO】合計				 */}
                  {/* Start 営業利益	 */}
                  <tr>
                    <td
                      className="text-left active p-left lightYellow"
                      colSpan={4}
                    >
                      営業利益
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} className="lightYellow" />
                    <td className="text-right active p-right" colSpan={1}>
                      営業利益率
                    </td>
                  </tr>

                  {/* End 営業利益	 */}
                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        border: 0,
                        borderRight: "15px solid #FFF"
                        // backgroundColor:"rgb(210, 214, 222)"
                      }}
                    />
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              id="contentRight"
              className="tabLeft"
              style={{ width: "calc(100% - 251px)", overflowX: "auto" }}
              onScroll={eventScroll}
            >
              <div
                style={{
                  width: `${(salesBudgetYearLength + 3) * 125}px`,
                  marginRight: "15px"
                }}
              >
                <table
                  className="table table-bordered table-striped table-condensed text-nowrap"
                  style={{ width: `${(salesBudgetYearLength + 3) * 125}px` }}
                  // onScroll={eventScroll}
                >
                  <tbody>
                    {/* Start 売上高	 */}
                    <tr>
                      <td className="text-right width_row_contents_changes" />
                      <td className="text-right width_row_contents">
                        <span id="salesBudgetTotal" />
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.salesBudgetTotalRow &&
                        yearFormState.monthlyListData.salesBudgetTotalRow[2] &&
                        yearFormState.monthlyListData.salesBudgetTotalRow[2].map(
                          (
                            item: Array<Array<number | null> | number | string>,
                            index: number
                          ) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span id={`salesBudgetTotal${index}`} />
                              </td>
                            )
                        )}
                    </tr>
                    {yearFormState &&
                      yearFormState.monthlyListData &&
                      yearFormState.monthlyListData.listAdvertisingRow &&
                      yearFormState.monthlyListData.listAdvertisingRow.map(
                        (element: any, numberElement: number) => {
                          count_dynamic_list_index++;
                          return (
                            <tr key={numberElement}>
                              <td className="text-center width_row_contents_changes small_width">
                                {element[0]
                                  ? element[0] === 0
                                    ? "変"
                                    : "固"
                                  : ""}
                              </td>
                              <td className="text-right width_row_contents">
                                <span
                                  id={`Advertising${element[numberElement]}`}
                                >
                                  {formatter.format(element[1])}
                                </span>
                              </td>
                              {// eslint-disable-next-line array-callback-return
                              element[2] &&
                                element[2].map(
                                  (item: string[], index: number) => {
                                    return (
                                      item !== null && (
                                        <td
                                          className="text-right width_row_contents"
                                          key={index}
                                        >
                                          <Input
                                            id={`listAdvertising${numberElement}${count_dynamic_list_index}${index}`}
                                            value={
                                              String(
                                                numberFormaterInput(item)
                                              ) || "0"
                                            }
                                            handleChange={handleChange}
                                            disabled={
                                              !!(
                                                listAdvertisingDisabled[
                                                  yearFormState.monthlyListDataInfo.listAdvertising[numberElement][0] + index
                                                ] === true || getInputDisable
                                              )
                                            }
                                          />
                                        </td>
                                      )
                                    );
                                  }
                                )}
                            </tr>
                          );
                        }
                      )}
                    <tr>
                      <td />
                      <td className="text-right width_row_contents">
                        <span id="salesBudgetFree">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.salesBudgetFreeRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .salesBudgetFreeRow[1] || 0
                            )
                            }
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.salesBudgetFreeRow &&
                        yearFormState.monthlyListData.salesBudgetFreeRow[2] &&
                        yearFormState.monthlyListData.salesBudgetFreeRow[2].map(
                          (
                            item: Array<number[] | number | string>,
                            index: number
                          ) => {
                            return (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <Input
                                  id={`${index}`}
                                  value={
                                    String(numberFormaterInput(item)) || "0"
                                  }
                                  handleChange={handleChange}
                                  disabled={getInputDisable}
                                />
                              </td>
                            );
                          }
                        )}
                    </tr>
                    <tr>
                      <td
                        className="text-right width_row_contents_changes"
                        style={{ backgroundColor: "#fce5cd" }}
                      />
                      <td
                        className="text-right width_row_contents"
                        style={{ backgroundColor: "#fce5cd" }}
                      >
                        <span id="salesBudgetTotal">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.salesBudgetTotalRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .salesBudgetTotalRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.salesBudgetTotalRow &&
                        yearFormState.monthlyListData.salesBudgetTotalRow[2] &&
                        yearFormState.monthlyListData.salesBudgetTotalRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                                style={{ backgroundColor: "#fce5cd" }}
                              >
                                <span id={`salesBudgetTotal${index}`}>
                                  {formatter.format(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    {/* End 売上高	 */}
                    {/* Start 標準原価	 */}
                    <tr>
                      <td className="text-right width_row_contents_changes" />
                      <td className="text-right width_row_contents" />
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.foodCostRow &&
                        yearFormState.monthlyListData.foodCostRow[2] &&
                        yearFormState.monthlyListData.foodCostRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            />
                          )
                        )}
                    </tr>
                    <tr>
                      <td className="text-center width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.foodCostRow &&
                          yearFormState.monthlyListData.foodCostRow[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="foodCost">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.foodCostRow &&
                            formatter.format(
                              yearFormState.monthlyListData.foodCostRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.foodCostRow &&
                        yearFormState.monthlyListData.foodCostRow[2] &&
                        yearFormState.monthlyListData.foodCostRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <Input
                                id={`foodCost${index}`}
                                value={String(numberFormaterInput(item)) || "0"}
                                handleChange={handleChange}
                                disabled={getInputDisable}
                              />
                            </td>
                          )
                        )}
                    </tr>

                    <tr>
                      <td className="text-center width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.drinkCostRow &&
                          yearFormState.monthlyListData.drinkCostRow[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="drinkCost">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.drinkCostRow &&
                            formatter.format(
                              yearFormState.monthlyListData.drinkCostRow[1]|| 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.drinkCostRow &&
                        yearFormState.monthlyListData.drinkCostRow[2] &&
                        yearFormState.monthlyListData.drinkCostRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <Input
                                id={`drinkCost${index}`}
                                value={String(numberFormaterInput(item)) || "0"}
                                handleChange={handleChange}
                                disabled={getInputDisable}
                              />
                            </td>
                          )
                        )}
                    </tr>
                    <tr>
                      <td
                        className="text-right width_row_contents_changes"
                        style={{ backgroundColor: "#cfe2f3" }}
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.costTotalRow &&
                          yearFormState.monthlyListData.costTotalRow[0]}
                      </td>
                      <td
                        className="text-right width_row_contents"
                        style={{ backgroundColor: "#cfe2f3" }}
                      >
                        <span id="costTotal">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.costTotalRow &&
                            formatter.format(
                              yearFormState.monthlyListData.costTotalRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.costTotalRow &&
                        yearFormState.monthlyListData.costTotalRow[2] &&
                        yearFormState.monthlyListData.costTotalRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                              style={{ backgroundColor: "#cfe2f3" }}
                            >
                              <span id={`costTotal${index}`}>
                                {formatter.format(item)}
                              </span>
                            </td>
                          )
                        )}
                    </tr>

                    <tr>
                      <td className="text-right width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.costTotalRowRate &&
                          yearFormState.monthlyListData.costTotalRowRate[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="costTotal">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.costTotalRowRate &&
                            formatPercentMinus(yearFormState.monthlyListData.costTotalRowRate[1])}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.costTotalRowRate &&
                        yearFormState.monthlyListData.costTotalRowRate[2] &&
                        yearFormState.monthlyListData.costTotalRowRate[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <span id={`costTotal${index}`}>{formatPercentMinus(item)}</span>
                            </td>
                          )
                        )}
                    </tr>

                    {/* End 標準原価	 */}
                    {/* Start L（人件費）	 */}
                    <tr>
                      <td className="text-right width_row_contents_changes" />
                      <td className="text-right width_row_contents" />
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.employeeLaborCostRow &&
                        yearFormState.monthlyListData.employeeLaborCostRow[2] &&
                        yearFormState.monthlyListData.employeeLaborCostRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            />
                          )
                        )}
                    </tr>
                    <tr>
                      <td className="text-center width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.employeeLaborCostRow &&
                          yearFormState.monthlyListData.employeeLaborCostRow[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="employeeLaborCost">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .employeeLaborCostRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .employeeLaborCostRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.employeeLaborCostRow &&
                        yearFormState.monthlyListData.employeeLaborCostRow[2] &&
                        yearFormState.monthlyListData.employeeLaborCostRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <Input
                                id={`employeeLaborCost${index}`}
                                value={String(numberFormaterInput(item)) || "0"}
                                handleChange={handleChange}
                                disabled={getInputDisable}
                              />
                            </td>
                          )
                        )}
                    </tr>

                    <tr>
                      <td className="text-center width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.partLaborCostRow &&
                          yearFormState.monthlyListData.partLaborCostRow[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="partLaborCost">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.partLaborCostRow &&
                            formatter.format(
                              yearFormState.monthlyListData.partLaborCostRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.partLaborCostRow &&
                        yearFormState.monthlyListData.partLaborCostRow[2] &&
                        yearFormState.monthlyListData.partLaborCostRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <Input
                                id={`partLaborCost${index}`}
                                value={String(numberFormaterInput(item)) || "0"}
                                handleChange={handleChange}
                                disabled={getInputDisable}
                              />
                            </td>
                          )
                        )}
                    </tr>
                    <tr>
                      <td
                        className="text-right width_row_contents_changes"
                        style={{ backgroundColor: "#cfe2f3" }}
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.laborCostTotalRow &&
                          yearFormState.monthlyListData.laborCostTotalRow[0]}
                      </td>
                      <td
                        className="text-right width_row_contents"
                        style={{ backgroundColor: "#cfe2f3" }}
                      >
                        <span id="laborCostTotal">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.laborCostTotalRow &&
                            formatter.format(
                              yearFormState.monthlyListData.laborCostTotalRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.laborCostTotalRow &&
                        yearFormState.monthlyListData.laborCostTotalRow[2] &&
                        yearFormState.monthlyListData.laborCostTotalRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                              style={{ backgroundColor: "#cfe2f3" }}
                            >
                              <span id={`laborCostTotal${index}`}>
                                {formatter.format(item)}
                              </span>
                            </td>
                          )
                        )}
                    </tr>

                    <tr>
                      <td className="text-right width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.laborCostTotalRowRate &&
                          yearFormState.monthlyListData
                            .laborCostTotalRowRate[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="laborCostTotal">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .laborCostTotalRowRate &&
                              formatPercentMinus(yearFormState.monthlyListData
                              .laborCostTotalRowRate[1])}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.laborCostTotalRowRate &&
                        yearFormState.monthlyListData
                          .laborCostTotalRowRate[2] &&
                        yearFormState.monthlyListData.laborCostTotalRowRate[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <span id={`laborCostTotal${index}`}>{formatPercentMinus(item)}</span>
                            </td>
                          )
                        )}
                    </tr>

                    {/* End L（人件費）	 */}
                    {/* Start A（広告費）	 */}
                    <tr>
                      <td className="text-right width_row_contents_changes" />
                      <td className="text-right width_row_contents">
                        <span id="salesBudgetTotal" />
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listAdvertisingExpenseTotalRow &&
                        yearFormState.monthlyListData
                          .listAdvertisingExpenseTotalRow[2] &&
                        yearFormState.monthlyListData.listAdvertisingExpenseTotalRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span id={`salesBudgetTotal${index}`} />
                              </td>
                            )
                        )}
                    </tr>
                    {yearFormState &&
                      yearFormState.monthlyListData &&
                      yearFormState.monthlyListData.listAdvertisingExpenseRow &&
                      yearFormState.monthlyListData.listAdvertisingExpenseRow.map(
                        (element: any, numberElement: number) => {
                          count_dynamic_list_index++;
                          return (
                            <tr key={numberElement}>
                              <td className="text-center width_row_contents_changes small_width">
                                {element[0]}
                              </td>
                              <td className="text-right width_row_contents">
                                <span
                                  id={`Advertising${element[numberElement]}`}
                                >
                                  {formatter.format(element[1])}
                                </span>
                              </td>
                              {// eslint-disable-next-line array-callback-return
                              element[2] &&
                                element[2].map((item: any, index: number) => {
                                  return (
                                    item !== null && (
                                      <td
                                        className="text-right width_row_contents"
                                        key={index}
                                      >
                                        <Input
                                          id={`listAdvertisingExpense${numberElement}${count_dynamic_list_index}${index}`}
                                          value={
                                            String(numberFormaterInput(item)) ||
                                            "0"
                                          }
                                          handleChange={handleChange}
                                          disabled={
                                            !!(
                                              listAdvertisingExpenseDisabled[
                                                yearFormState.monthlyListDataInfo.listAdvertisingExpense[numberElement][0] + index
                                              ] === true || getInputDisable
                                            )
                                          }
                                        />
                                      </td>
                                    )
                                  );
                                })}
                            </tr>
                          );
                        }
                      )}
                    <tr>
                      <td
                        className="text-right width_row_contents_changes"
                        style={{ backgroundColor: "#cfe2f3" }}
                      />
                      <td
                        className="text-right width_row_contents"
                        style={{ backgroundColor: "#cfe2f3" }}
                      >
                        <span id="listAdvertisingExpenseTotal">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listAdvertisingExpenseTotalRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .listAdvertisingExpenseTotalRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listAdvertisingExpenseTotalRow &&
                        yearFormState.monthlyListData
                          .listAdvertisingExpenseTotalRow[2] &&
                        yearFormState.monthlyListData.listAdvertisingExpenseTotalRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                                style={{ backgroundColor: "#cfe2f3" }}
                              >
                                <span
                                  id={`listAdvertisingExpenseTotal${index}`}
                                >
                                  {formatter.format(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    <tr>
                      <td className="text-right width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listAdvertisingExpenseTotalRowRate &&
                          yearFormState.monthlyListData
                            .listAdvertisingExpenseTotalRowRate[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="costTotal">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listAdvertisingExpenseTotalRowRate &&
                              formatPercentMinus(yearFormState.monthlyListData
                              .listAdvertisingExpenseTotalRowRate[1])}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listAdvertisingExpenseTotalRowRate &&
                        yearFormState.monthlyListData
                          .listAdvertisingExpenseTotalRowRate[2] &&
                        yearFormState.monthlyListData.listAdvertisingExpenseTotalRowRate[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span id={`costTotal${index}`}>{formatPercentMinus(item)}</span>
                              </td>
                            )
                        )}
                    </tr>
                    {/* End A（広告費） */}
                    {/* Start FLA合計 */}
                    <tr>
                      <td
                        className="text-right width_row_contents_changes"
                        style={{ backgroundColor: "#cfe2f3" }}
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.listFLACostRow &&
                          yearFormState.monthlyListData.listFLACostRow[0]}
                      </td>
                      <td
                        className="text-right width_row_contents"
                        style={{ backgroundColor: "#cfe2f3" }}
                      >
                        <span id="advertisingMediaExpense">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.listFLACostRow &&
                            formatter.format(
                              yearFormState.monthlyListData.listFLACostRow[1] ||
                                0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.listFLACostRow &&
                        yearFormState.monthlyListData.listFLACostRow[2] &&
                        yearFormState.monthlyListData.listFLACostRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                                style={{ backgroundColor: "#cfe2f3" }}
                              >
                                <span id={`costFLA${index}`}>
                                  {formatter.format(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    <tr>
                      <td className="text-right width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.listFLACostRowRate &&
                          yearFormState.monthlyListData.listFLACostRowRate[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="advertisingMediaExpense">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.listFLACostRowRate &&
                            formatPercentMinus(yearFormState.monthlyListData.listFLACostRowRate[1])}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.listFLACostRowRate &&
                        yearFormState.monthlyListData.listFLACostRowRate[2] &&
                        yearFormState.monthlyListData.listFLACostRowRate[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span id={`rateFLA${index}`}>{formatPercentMinus(item)}</span>
                              </td>
                            )
                        )}
                    </tr>
                    {/* End FLA合計 */}

                    {/* Start 店舗利益*/}
                    <tr>
                      <td
                        className="text-right width_row_contents_changes"
                        style={{ backgroundColor: "#fff2cc" }}
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.listProfitLossCostRow &&
                          yearFormState.monthlyListData
                            .listProfitLossCostRow[0]}
                      </td>
                      <td
                        className="text-right width_row_contents"
                        style={{ backgroundColor: "#fff2cc" }}
                      >
                        <span id="advertisingMediaExpense">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listProfitLossCostRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .listProfitLossCostRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.listProfitLossCostRow &&
                        yearFormState.monthlyListData
                          .listProfitLossCostRow[2] &&
                        yearFormState.monthlyListData.listProfitLossCostRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                                style={{ backgroundColor: "#fff2cc" }}
                              >
                                <span id={`advertisingMediaExpense${index}`}>
                                  {formatter.format(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    <tr>
                      <td className="text-right width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listProfitLossCostRowRate &&
                          yearFormState.monthlyListData
                            .listProfitLossCostRowRate[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="advertisingMediaExpense">
                          {/* Rate 店舗損益率 */}
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listProfitLossCostRowRate &&
                              formatPercentMinus(yearFormState.monthlyListData
                              .listProfitLossCostRowRate[1])}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listProfitLossCostRowRate &&
                        yearFormState.monthlyListData
                          .listProfitLossCostRowRate[2] &&
                        yearFormState.monthlyListData.listProfitLossCostRowRate[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span id={`advertisingMediaExpense${index}`}>
                                  {/* Rate 店舗損益率 */}
                                  {formatPercentMinus(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    {/* End 店舗利益 */}

                    {/* Start R（家賃） */}
                    <tr>
                      <td className="text-right width_row_contents_changes" />
                      <td className="text-right width_row_contents" />
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.rentCostRow &&
                        yearFormState.monthlyListData.rentCostRow[2] &&
                        yearFormState.monthlyListData.rentCostRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            />
                          )
                        )}
                    </tr>

                    <tr>
                      <td className="text-center width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.rentCostRow &&
                          yearFormState.monthlyListData.rentCostRow[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="rentCost">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.rentCostRow &&
                            formatter.format(
                              yearFormState.monthlyListData.rentCostRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.rentCostRow &&
                        yearFormState.monthlyListData.rentCostRow[2] &&
                        yearFormState.monthlyListData.rentCostRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <Input
                                id={`rentCost${index}`}
                                value={String(numberFormaterInput(item)) || "0"}
                                handleChange={handleChange}
                                disabled={getInputDisable}
                              />
                            </td>
                          )
                        )}
                    </tr>

                    <tr>
                      <td className="text-center width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .commonServiceFeeCostRow &&
                          yearFormState.monthlyListData
                            .commonServiceFeeCostRow[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="commonServiceFeeCost">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .commonServiceFeeCostRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .commonServiceFeeCostRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.commonServiceFeeCostRow &&
                        yearFormState.monthlyListData
                          .commonServiceFeeCostRow[2] &&
                        yearFormState.monthlyListData.commonServiceFeeCostRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <Input
                                id={`commonServiceFeeCost${index}`}
                                value={String(numberFormaterInput(item)) || "0"}
                                handleChange={handleChange}
                                disabled={getInputDisable}
                              />
                            </td>
                          )
                        )}
                    </tr>

                    <tr>
                      <td
                        className="text-right width_row_contents_changes"
                        style={{ backgroundColor: "#cfe2f3" }}
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.landRentTotalCostRow &&
                          yearFormState.monthlyListData.landRentTotalCostRow[0]}
                      </td>
                      <td
                        className="text-right width_row_contents"
                        style={{ backgroundColor: "#cfe2f3" }}
                      >
                        <span id="landRentTotalCost">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .landRentTotalCostRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .landRentTotalCostRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.landRentTotalCostRow &&
                        yearFormState.monthlyListData.landRentTotalCostRow[2] &&
                        yearFormState.monthlyListData.landRentTotalCostRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                              style={{ backgroundColor: "#cfe2f3" }}
                            >
                              <span id={`landRentTotal${index}`}>
                                {formatter.format(item)}
                              </span>
                            </td>
                          )
                        )}
                    </tr>

                    <tr>
                      <td className="text-right width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.landRentTotalRowRate &&
                          yearFormState.monthlyListData.landRentTotalRowRate[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="landRentTotal">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .landRentTotalRowRate &&
                              formatPercentMinus(yearFormState.monthlyListData
                              .landRentTotalRowRate[1])}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.landRentTotalRowRate &&
                        yearFormState.monthlyListData.landRentTotalRowRate[2] &&
                        yearFormState.monthlyListData.landRentTotalRowRate[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <span id={`landRentTotal${index}`}>{formatPercentMinus(item)}</span>
                            </td>
                          )
                        )}
                    </tr>
                    {/* End R（家賃）		 */}
                    {/* Start O（その他経費）	 */}
                    <tr>
                      <td className="text-right width_row_contents_changes" />
                      <td className="text-right width_row_contents">
                        <span id="listExpenses" />
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.listExpensesTotalRow &&
                        yearFormState.monthlyListData.listExpensesTotalRow[2] &&
                        yearFormState.monthlyListData.listExpensesTotalRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span id={`listExpenses${index}`} />
                              </td>
                            )
                        )}
                    </tr>
                    {yearFormState &&
                      yearFormState.monthlyListData &&
                      yearFormState.monthlyListData.listExpensesRow &&
                      yearFormState.monthlyListData.listExpensesRow.map(
                        (element: any, numberElement: number) => {
                          count_dynamic_list_index++;
                          return (
                            <tr key={numberElement}>
                              <td className="text-center width_row_contents_changes small_width">
                                {element[0]}
                              </td>
                              <td className="text-right width_row_contents">
                                <span id={`expenses${element[numberElement]}`}>
                                  {formatter.format(element[1])}
                                </span>
                              </td>
                              {// eslint-disable-next-line array-callback-return
                              element[2] &&
                                element[2].map((item: any, index: number) => {
                                  return (
                                    item !== null && (
                                      <td
                                        className="text-right width_row_contents"
                                        key={index}
                                      >
                                        <Input
                                          id={`listExpenses${numberElement}${count_dynamic_list_index}${index}`}
                                          value={
                                            String(numberFormaterInput(item)) ||
                                            "0"
                                          }
                                          handleChange={handleChange}
                                          disabled={
                                            !!(
                                              listOthersExpenseDisabled[
                                                yearFormState.monthlyListDataInfo.listExpenses[numberElement][0] + index
                                              ] === true || getInputDisable
                                            )
                                          }
                                        />
                                      </td>
                                    )
                                  );
                                })}
                            </tr>
                          );
                        }
                      )}
                    <tr>
                      <td className="text-center width_row_contents_changes small_width">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.otherPurchaseRow &&
                          yearFormState.monthlyListData.otherPurchaseRow[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="otherPurchase">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.otherPurchaseRow &&
                            formatter.format(
                              yearFormState.monthlyListData.otherPurchaseRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.otherPurchaseRow &&
                        yearFormState.monthlyListData.otherPurchaseRow[2] &&
                        yearFormState.monthlyListData.otherPurchaseRow[2].map(
                          (item: any, index: number) => {
                            return (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <Input
                                  id={`otherPurchase${index}`}
                                  value={
                                    String(numberFormaterInput(item)) || "0"
                                  }
                                  handleChange={handleChange}
                                  disabled={getInputDisable}
                                />
                              </td>
                            );
                          }
                        )}
                    </tr>
                    <tr>
                      <td
                        className="text-right width_row_contents_changes"
                        style={{ backgroundColor: "#cfe2f3" }}
                      />
                      <td
                        className="text-right width_row_contents"
                        style={{ backgroundColor: "#cfe2f3" }}
                      >
                        <span id="listExpensesTotal">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listExpensesTotalRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .listExpensesTotalRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.listExpensesTotalRow &&
                        yearFormState.monthlyListData.listExpensesTotalRow[2] &&
                        yearFormState.monthlyListData.listExpensesTotalRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                                style={{ backgroundColor: "#cfe2f3" }}
                              >
                                <span id={`listExpensesTotal${index}`}>
                                  {formatter.format(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    <tr>
                      <td className="text-right width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listExpensesTotalRowRate &&
                          yearFormState.monthlyListData
                            .listExpensesTotalRowRate[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="costTotal">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listExpensesTotalRowRate &&
                              formatPercentMinus(yearFormState.monthlyListData
                              .listExpensesTotalRowRate[1])}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listExpensesTotalRowRate &&
                        yearFormState.monthlyListData
                          .listExpensesTotalRowRate[2] &&
                        yearFormState.monthlyListData.listExpensesTotalRowRate[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span id={`costTotal${index}`}>{formatPercentMinus(item)}</span>
                              </td>
                            )
                        )}
                    </tr>
                    {/* End O（その他経費） */}
                    {/* Start FLARO合計 */}
                    <tr>
                      <td
                        className="text-right width_row_contents_changes"
                        style={{ backgroundColor: "#c9daf8" }}
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.listFLAROCostRow &&
                          yearFormState.monthlyListData.listFLAROCostRow[0]}
                      </td>
                      <td
                        className="text-right width_row_contents"
                        style={{ backgroundColor: "#c9daf8" }}
                      >
                        <span id="advertisingMediaExpense">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.listFLAROCostRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .listFLAROCostRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.listFLAROCostRow &&
                        yearFormState.monthlyListData.listFLAROCostRow[2] &&
                        yearFormState.monthlyListData.listFLAROCostRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                                style={{ backgroundColor: "#c9daf8" }}
                              >
                                <span id={`costFLARO${index}`}>
                                  {formatter.format(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    <tr>
                      <td className="text-right width_row_contents_changes"
                      style={{ backgroundColor: "#c9daf8" }}
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.listFLAROCostRowRate &&
                          yearFormState.monthlyListData.listFLAROCostRowRate[0]}
                      </td>
                      <td className="text-right width_row_contents"
                      style={{ backgroundColor: "#c9daf8" }}>
                        <span id="advertisingMediaExpense">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listFLAROCostRowRate &&
                              formatPercentMinus(yearFormState.monthlyListData
                              .listFLAROCostRowRate[1])}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.listFLAROCostRowRate &&
                        yearFormState.monthlyListData.listFLAROCostRowRate[2] &&
                        yearFormState.monthlyListData.listFLAROCostRowRate[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                                style={{ backgroundColor: "#c9daf8" }}
                              >
                                <span id={`rateFLARO${index}`}>{formatPercentMinus(item)}</span>
                              </td>
                            )
                        )}
                    </tr>
                    {/* End FLA合計 */}

                    {/* Start 店舗利益*/}
                    <tr>
                      <td
                        className="text-right width_row_contents_changes"
                        style={{ backgroundColor: "#fff2cc" }}
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listOperatingIncomeCostRow &&
                          yearFormState.monthlyListData
                            .listOperatingIncomeCostRow[0]}
                      </td>
                      <td
                        className="text-right width_row_contents"
                        style={{ backgroundColor: "#fff2cc" }}
                      >
                        <span id="advertisingMediaExpense">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listOperatingIncomeCostRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .listOperatingIncomeCostRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listOperatingIncomeCostRow &&
                        yearFormState.monthlyListData
                          .listOperatingIncomeCostRow[2] &&
                        yearFormState.monthlyListData.listOperatingIncomeCostRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                                style={{ backgroundColor: "#fff2cc" }}
                              >
                                <span id={`advertisingMediaExpense${index}`}>
                                  {formatter.format(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    <tr>
                      <td className="text-right width_row_contents_changes">
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listOperatingIncomeCostRowRate &&
                          yearFormState.monthlyListData
                            .listOperatingIncomeCostRowRate[0]}
                      </td>
                      <td className="text-right width_row_contents">
                        <span id="advertisingMediaExpense">
                          {/* Rate 店舗損益率 */}
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listOperatingIncomeCostRowRate &&
                              formatPercentMinus(yearFormState.monthlyListData
                              .listOperatingIncomeCostRowRate[1])}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listOperatingIncomeCostRowRate &&
                        yearFormState.monthlyListData
                          .listOperatingIncomeCostRowRate[2] &&
                        yearFormState.monthlyListData.listOperatingIncomeCostRowRate[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span id={`advertisingMediaExpense${index}`}>
                                  {/* Rate 店舗損益率 */}
                                  {formatPercentMinus(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    {/* End 店舗利益 */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* First Table */}
          {/* Second Table */}
          <div style={{ display: "inline-flex", width: gridCol.grid12 }}>
            <div
              className="tabLeft"
              style={{ borderRight: "5px solid rgb(210, 214, 222)" }}
            >
              <div>
                <table
                  className="table table-bordered table-striped  table-condensed text-nowrap"
                  style={{ width: `${249}px` }}
                  id="salesBudgetYear-Left2"
                >
                  <tbody>
                    {/* <tr>
                    <td
                      className="text-center no-border split_sale_repport"
                      colSpan={4}
                      style={{border:'0px solid transparent !important'}}
                    >
                    </td>
                  </tr> */}
                    <tr>
                      <td
                        className="text-right active height_row_contents"
                        colSpan={3}
                        style={{ paddingRight: 10 }}
                      >
                        客単価
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right active" colSpan={4}>
                        <div className="icon-tooltip-right">
                          客数&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="売上高合計 / 客単価"
                            color="#000"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right active" colSpan={4}>
                        <div className="icon-tooltip-right">
                          回転率&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="客数 / 座席数"
                            color="#000"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" rowSpan={2}>
                        限界利益
                      </td>
                      <td className="text-right active" colSpan={4}>
                        <div className="icon-tooltip-right">
                          額&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="売上高合計 - 変動費"
                            color="#000"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right active" colSpan={4}>
                        <div className="icon-tooltip-right">
                          率&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="限界利益 / 売上高合計の％表示"
                            color="#000"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" rowSpan={2}>
                        貢献利益
                      </td>
                      <td className="text-right active" colSpan={4}>
                        <div className="icon-tooltip-right">
                          額&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="限界利益 - 固定費"
                            color="#000"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right active" colSpan={4}>
                        <div className="icon-tooltip-right">
                          率&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="貢献利益 / 売上高合計の％表示"
                            color="#000"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" rowSpan={2}>
                        損益分岐点
                      </td>
                      <td className="text-right active" colSpan={4}>
                        <div className="icon-tooltip-right">
                          売上&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="固定費 / 限界利益率"
                            color="#000"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right active" colSpan={4}>
                        <div className="icon-tooltip-right">
                          客数&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="損益分岐点売上 / 客単価"
                            color="#000"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" rowSpan={3}>
                        人件費率
                      </td>
                      <td className="text-right active" colSpan={4}>
                        <div className="icon-tooltip-right">
                          合計&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="人件費（合計）/ 売上高合計の％表示"
                            color="#000"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right active" colSpan={4}>
                        <div className="icon-tooltip-right">
                          社員&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="社員人件費 / 売上高合計の％表示"
                            color="#000"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right active" colSpan={4}>
                        <div className="icon-tooltip-right">
                          アルバイト&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="アルバイト人件費 / 売上高合計の％表示"
                            color="#000"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right active" colSpan={4}>
                        <div className="icon-tooltip-right">
                          労働分配率&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="人件費合計 /（売上高合計 - 食材費合計）の％表示"
                            color="#000"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              id="contentRight2"
              className="tabLeftBottom hide_scrollbar_onscroll"
              style={{
                width: `calc(100% + ${249 + offsetWidthChangeColumn}px`,
                overflowX: "auto"
              }}
              onScroll={eventScroll}
            >
              <div
                style={{
                  width: `${(salesBudgetYearLength + 3) * 125}px`,
                  marginRight: "15px",
                  display: "flex"
                }}
              >
                {/* Table bottom Right */}
                <table
                  className="table table-bordered table-striped table-condensed text-nowrap"
                  style={{
                    width: `${(salesBudgetYearLength + 3) * 125 - 3}px`
                    // width: `${1700-25}px`
                  }}
                >
                  <tbody>
                    {/* No accordion below */}
                    {/* totalPerCustomerPrice 客単価 */}
                    <tr>
                      <td
                        colSpan={1}
                        className="width_row_contents_changes small_width"
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listTotalPerCustomerPriceRow &&
                          yearFormState.monthlyListData
                            .listTotalPerCustomerPriceRow[0]}
                      </td>
                      <td className="text-right width_row_contents_bottom">
                        <span id="perCustomerPrice">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listTotalPerCustomerPriceRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .listTotalPerCustomerPriceRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listTotalPerCustomerPriceRow &&
                        yearFormState.monthlyListData
                          .listTotalPerCustomerPriceRow[2] &&
                        yearFormState.monthlyListData.listTotalPerCustomerPriceRow[2].map(
                          (item: any, index: number) => {
                            return (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <Input
                                  id={`listTotalPerCustomerPrice${index}`}
                                  value={
                                    String(
                                      numberFormaterInput(!isNaN(item) ? Math.round(item) : item)
                                    ) || "0"
                                  }
                                  handleChange={handleChange}
                                  disabled={getInputDisable}
                                />
                              </td>
                            );
                          }
                        )}
                    </tr>
                    {/* getGuestCntTotal客数  */}
                    <tr>
                      <td
                        colSpan={1}
                        className="width_row_contents_changes small_width"
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.listGuestCntTotalRow &&
                          yearFormState.monthlyListData.listGuestCntTotalRow[0]}
                      </td>
                      <td className="text-right width_row_contents_bottom">
                        <span id="perCustomerPrice">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listGuestCntTotalRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .listGuestCntTotalRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.listGuestCntTotalRow &&
                        yearFormState.monthlyListData.listGuestCntTotalRow[2] &&
                        yearFormState.monthlyListData.listGuestCntTotalRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <span id={`listGuestCntTotalRow${index}`}>
                                {formatter.format(item)}
                              </span>
                            </td>
                          )
                        )}
                    </tr>
                    {/* getTurnoverRateTotalItems 回転率 */}
                    <tr>
                      <td
                        colSpan={1}
                        className="width_row_contents_changes small_width"
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listTurnoverRateTotalRow &&
                          yearFormState.monthlyListData
                            .listTurnoverRateTotalRow[0]}
                      </td>
                      <td className="text-right width_row_contents_bottom">
                        <span id="perCustomerPrice">
                          {(yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listTurnoverRateTotalRow &&
                            yearFormState.monthlyListData
                              .listTurnoverRateTotalRow[1]) ||
                            0}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listTurnoverRateTotalRow &&
                        yearFormState.monthlyListData
                          .listTurnoverRateTotalRow[2] &&
                        yearFormState.monthlyListData.listTurnoverRateTotalRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <span id={`listTurnoverRateTotalRow${index}`}>
                                {item}
                              </span>
                            </td>
                          )
                        )}
                    </tr>
                    {/* getTotalAllEvenMarginalProfit 限界利益	 額  */}
                    <tr>
                      <td
                        colSpan={1}
                        className="width_row_contents_changes small_width"
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listEvenMarginalProfitTotalRow &&
                          yearFormState.monthlyListData
                            .listEvenMarginalProfitTotalRow[0]}
                      </td>
                      <td className="text-right width_row_contents_bottom">
                        <span id="perCustomerPrice">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listEvenMarginalProfitTotalRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .listEvenMarginalProfitTotalRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listEvenMarginalProfitTotalRow &&
                        yearFormState.monthlyListData
                          .listEvenMarginalProfitTotalRow[2] &&
                        yearFormState.monthlyListData.listEvenMarginalProfitTotalRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span
                                  id={`listEvenMarginalProfitTotalRow${index}`}
                                >
                                  {formatter.format(item || 0)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    {/* marginalProfitRate 限界利益	率  */}
                    <tr>
                      <td
                        colSpan={1}
                        className="width_row_contents_changes small_width"
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listMarginalprofitRateRow &&
                          yearFormState.monthlyListData
                            .listMarginalprofitRateRow[0]}
                      </td>
                      <td className="text-right width_row_contents_bottom">
                        <span id="perCustomerPrice">
                          {(yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listMarginalprofitRateRow &&
                              formatPercentMinus(yearFormState.monthlyListData
                              .listMarginalprofitRateRow[1]) ||
                            0)}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listMarginalprofitRateRow &&
                        yearFormState.monthlyListData
                          .listMarginalprofitRateRow[2] &&
                        yearFormState.monthlyListData.listMarginalprofitRateRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span id={`listMarginalprofitRateRow${index}`}>
                                  {formatPercentMinus(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    {/* contributionMarginTotal 貢献利益	額 */}
                    <tr>
                      <td
                        colSpan={1}
                        className="width_row_contents_changes small_width"
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listContributionMarginTotalRow &&
                          yearFormState.monthlyListData
                            .listContributionMarginTotalRow[0]}
                      </td>
                      <td className="text-right width_row_contents_bottom">
                        <span id="perCustomerPrice">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listContributionMarginTotalRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .listContributionMarginTotalRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listContributionMarginTotalRow &&
                        yearFormState.monthlyListData
                          .listContributionMarginTotalRow[2] &&
                        yearFormState.monthlyListData.listContributionMarginTotalRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span
                                  id={`listContributionMarginTotalRow${index}`}
                                >
                                  {formatter.format(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    {/* contributionMarginTotalRate 貢献利益	率  */}
                    <tr>
                      <td
                        colSpan={1}
                        className="width_row_contents_changes small_width"
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listContributionMarginTotalRateRow &&
                          yearFormState.monthlyListData
                            .listContributionMarginTotalRateRow[0]}
                      </td>
                      <td className="text-right width_row_contents_bottom">
                        <span id="perCustomerPrice">
                          {(yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listContributionMarginTotalRateRow &&
                              formatPercentMinus(yearFormState.monthlyListData
                              .listContributionMarginTotalRateRow[1]) ||
                            0)}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listContributionMarginTotalRateRow &&
                        yearFormState.monthlyListData
                          .listContributionMarginTotalRateRow[2] &&
                        yearFormState.monthlyListData.listContributionMarginTotalRateRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span
                                  id={`listContributionMarginTotalRateRow${index}`}
                                >
                                  {formatPercentMinus(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    {/* 損益分岐点	売上 bePointSales */}
                    <tr>
                      <td
                        colSpan={1}
                        className="width_row_contents_changes small_width"
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.listBePointSalesRow &&
                          yearFormState.monthlyListData.listBePointSalesRow[0]}
                      </td>
                      <td className="text-right width_row_contents_bottom">
                        <span id="perCustomerPrice">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.listBePointSalesRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .listBePointSalesRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.listBePointSalesRow &&
                        yearFormState.monthlyListData.listBePointSalesRow[2] &&
                        yearFormState.monthlyListData.listBePointSalesRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span id={`listBePointSalesRow${index}`}>
                                  {formatter.format(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    {/* 損益分岐点	客数 bePointGuestCnt */}
                    <tr>
                      <td
                        colSpan={1}
                        className="width_row_contents_changes small_width"
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listBePointGuestCntRow &&
                          yearFormState.monthlyListData
                            .listBePointGuestCntRow[0]}
                      </td>
                      <td className="text-right width_row_contents_bottom">
                        <span id="perCustomerPrice">
                          {yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listBePointGuestCntRow &&
                            formatter.format(
                              yearFormState.monthlyListData
                                .listBePointGuestCntRow[1] || 0
                            )}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.listBePointGuestCntRow &&
                        yearFormState.monthlyListData
                          .listBePointGuestCntRow[2] &&
                        yearFormState.monthlyListData.listBePointGuestCntRow[2].map(
                          (item: any, index: number) =>
                            item !== null && (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <span id={`listBePointGuestCntRow${index}`}>
                                  {formatter.format(item)}
                                </span>
                              </td>
                            )
                        )}
                    </tr>
                    {/* 人件費率 getTotalPersonRateItems	合計  */}
                    <tr>
                      <td
                        colSpan={1}
                        className="width_row_contents_changes small_width"
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listPersonRateTotalRow &&
                          yearFormState.monthlyListData
                            .listPersonRateTotalRow[0]}
                      </td>
                      <td className="text-right width_row_contents_bottom">
                        <span id="perCustomerPrice">
                          {(yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listPersonRateTotalRow &&
                              formatPercentMinus(yearFormState.monthlyListData
                              .listPersonRateTotalRow[1]) ||
                            0)}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.listPersonRateTotalRow &&
                        yearFormState.monthlyListData
                          .listPersonRateTotalRow[2] &&
                        yearFormState.monthlyListData.listPersonRateTotalRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <span id={`listPersonRateTotalRow${index}`}>
                                {formatPercentMinus(item)}
                              </span>
                            </td>
                          )
                        )}
                    </tr>
                    {/* 人件費率 getEmployeePersonRate 社員   */}
                    <tr>
                      <td
                        colSpan={1}
                        className="width_row_contents_changes small_width"
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData
                            .listEmployeePersonRateRow &&
                          yearFormState.monthlyListData
                            .listEmployeePersonRateRow[0]}
                      </td>
                      <td className="text-right width_row_contents_bottom">
                        <span id="perCustomerPrice">
                          {(yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listEmployeePersonRateRow &&
                              formatPercentMinus(yearFormState.monthlyListData
                              .listEmployeePersonRateRow[1]) ||
                            0)}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData
                          .listEmployeePersonRateRow &&
                        yearFormState.monthlyListData
                          .listEmployeePersonRateRow[2] &&
                        yearFormState.monthlyListData.listEmployeePersonRateRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <span id={`listEmployeePersonRateRow${index}`}>
                                {formatPercentMinus(item)}
                              </span>
                            </td>
                          )
                        )}
                    </tr>
                    {/* 人件費率 getPartPersonRate アルバイト  */}
                    <tr>
                      <td
                        colSpan={1}
                        className="width_row_contents_changes small_width"
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.listPartPersonRateRow &&
                          yearFormState.monthlyListData
                            .listPartPersonRateRow[0]}
                      </td>
                      <td className="text-right width_row_contents_bottom">
                        <span id="perCustomerPrice">
                          {(yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData
                              .listPartPersonRateRow &&
                              formatPercentMinus(yearFormState.monthlyListData
                              .listPartPersonRateRow[1]) ||
                            0)}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.listPartPersonRateRow &&
                        yearFormState.monthlyListData
                          .listPartPersonRateRow[2] &&
                        yearFormState.monthlyListData.listPartPersonRateRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <span id={`listPartPersonRateRow${index}`}>
                                {formatPercentMinus(item)}
                              </span>
                            </td>
                          )
                        )}
                    </tr>
                    {/* laborShare 労働分配率  */}
                    <tr>
                      <td
                        colSpan={1}
                        className="width_row_contents_changes small_width"
                      >
                        {yearFormState &&
                          yearFormState.monthlyListData &&
                          yearFormState.monthlyListData.listLaborShareRow &&
                          yearFormState.monthlyListData.listLaborShareRow[0]}
                      </td>
                      <td className="text-right width_row_contents_bottom">
                        <span id="perCustomerPrice">
                          {(yearFormState &&
                            yearFormState.monthlyListData &&
                            yearFormState.monthlyListData.listLaborShareRow &&
                            formatPercentMinus(yearFormState.monthlyListData
                              .listLaborShareRow[1]) ||
                            0)}
                        </span>
                      </td>
                      {yearFormState &&
                        yearFormState.monthlyListData &&
                        yearFormState.monthlyListData.listLaborShareRow &&
                        yearFormState.monthlyListData.listLaborShareRow[2] &&
                        yearFormState.monthlyListData.listLaborShareRow[2].map(
                          (item: any, index: number) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <span id={`listLaborShareRow${index}`}>
                                {formatPercentMinus(item)}
                              </span>
                            </td>
                          )
                        )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Second Table */}
        </div>
      </div>

      <div className="scroll-bottom" onScroll={eventScroll}>
        <div style={{ width: "250px", float: "left" }} />
        <div
          style={{
            width: "calc(100% - 260px)",
            float: "right",
            height: "24px",
            overflow: "auto"
          }}
          id="scrollBottom"
        >
          <div
            className="scroll"
            style={{ width: `${(salesBudgetYearLength + 3) * 150}px` }}
          />
        </div>
      </div>
    </div>
  );
};
export default BudgetYearTable;
