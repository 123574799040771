/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Amplify, { Auth } from 'aws-amplify';
import awsExports from 'aws-exports'; // 設定情報を読み込みます。

import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import PasswordForm from 'components/molecules/PasswordForm';
import Button, { PrimaryButton } from 'components/atoms/Button';
import { useHistory, Link, useParams } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';
import { handleHelpUrlForScreen } from 'api/login';
import { isMobileOnly } from 'react-device-detect';

const styles = {
  canselButton: css({
    marginRight: '3em auto',
  }),
  h1: css({
    textAlign: 'left',
    padding: '0 0',
    fontSize: '0.75em',
  }),
  form: css({
    padding: '0 1.5em',
  }),
  standardLogo: css({
    marginLeft: 'auto',
    marginRight: 'auto',
    verticalAlign: 'middle',
    width: '100px',
    height: '100px',
    padding: '8px 12px',
  }),
  formFotter: css({
    padding: '0.5em 0.5em',
    textAlign: 'right',
    marginTop: isMobileOnly ? '0px' : '-30px',
  }),
  formHeader: css({
    padding: '0.5em 0.5em',
    textAlign: 'center',
  }),
  'formFotter a': css({
    textDecoration: 'none',
    color: '#0070d2',
    fontSize: '0.75em',
  }),
  'formHeader a': css({
    textDecoration: 'none',
    color: '#0070d2',
  }),
};

Amplify.configure(awsExports); // 設定情報をAmplifyに反映させます。

const ForgotPasswordSubmitSchema = Yup.object().shape({
  email: Yup.string()
    .required('メールアドレスを入力してください'),
  activationCode: Yup.string()
    .required('認証コードを入力してください'),
  password: Yup.string()
    .min(6, 'パスワードは6文字以上で入力して下さい')
    .required('パスワードを入力してください'),
  rePassword: Yup.string()
    .required('パスワード(確認)を入力してください')
    .min(6, 'パスワードは6文字以上で入力して下さい')
    .oneOf([Yup.ref('password')], 'パスワードと確認用のパスワードが異なっています'),
});

const ForgotPasswordSubmitInputForm: React.FC = () => {
  const { successNotification, errorNotification } = useToastNotification();
  const { mailAddress } = useParams();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: mailAddress !== undefined ? mailAddress : '',
      activationCode: '',
      password: '',
      rePassword: '',
    },
    onSubmit: (values) => {
      Auth.forgotPasswordSubmit(formik.values.email,
        formik.values.activationCode, formik.values.password)
        .then((data : any) => {
          console.log('パスワードを再設定しました');
          successNotification('パスワードの再設定が完了しました');
          history.push('/login');
        })
        .catch((exception : any) => {
          console.error(exception);
          if (exception.code === 'CodeMismatchException') {
            errorNotification('認証コードが間違っています');
          } else if (exception.code === 'ExpiredCodeException') {
            errorNotification('認証コードの有効期限が切れていますので、再度パスワードの再設定をおこなってください');
          } else {
            errorNotification('パスワードの再設定に失敗しました');
          }
        });
    },
    validationSchema: ForgotPasswordSubmitSchema,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <p css={styles.h1}>
        受信したメールアドレスに記載されている認証コードと新しいパスワードを入力して下さい。
        <br />
        メールが届かない場合、迷惑メールフォルダに無いか確認して下さい。
        <br />
        「e-cometrue.com」のドメインからのメールを受信許可に設定して下さい。
      </p>
      <FormField>
        <TextForm
          name="activationCode"
          label="認証コード"
          value={formik.values.activationCode}
          onChange={formik.handleChange}
          errorMsg={formik.errors.activationCode}
        />
      </FormField>

      <FormField>
        <PasswordForm
          name="password"
          label="パスワード"
          value={formik.values.password}
          onChange={formik.handleChange}
          errorMsg={formik.errors.password}
        />
      </FormField>

      <FormField>
        <PasswordForm
          name="rePassword"
          label="パスワード(確認)"
          value={formik.values.rePassword}
          onChange={formik.handleChange}
          errorMsg={formik.errors.rePassword}
        />
      </FormField>

      <FormSubmitArea>
        <Button
          text="パスワードを変更"
          onClick={formik.handleSubmit}
        />
        <Link to="/login">
          <PrimaryButton
            ghost={true}
            text="キャンセル"
          />
        </Link>
      </FormSubmitArea>
      <div css={styles.formFotter}>
        <Link onClick={() => handleHelpUrlForScreen('パスワード変更')} to="#" css={styles['formFotter a']}>
          ヘルプサイトへ
        </Link>
      </div>
    </form>
  );
};

export default ForgotPasswordSubmitInputForm;
