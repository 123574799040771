/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { layer } from 'components/styles';
import IconTooltip from 'components/molecules/IconTooltip';
import ReactTooltip from 'react-tooltip';
import FormLabel from 'components/atoms/Form/FormLabel';

/**
 * フォームのコンテンツ
 */
const CardContents: React.FC<{
  customStyle?: SerializedStyles;
  className?: string;
  inlineBlock?: boolean;
  text: string;
  tooltipText: string;
  place?: ReactTooltip.Place;
}> = ({
  children,
  customStyle,
  className,
  text,
  tooltipText,
  inlineBlock,
  place,
}) => (
  <div
    css={css(
      {
        marginLeft: '16px',
        marginBottom: '8px',
        backgroundColor: '#FFFFFF',
        boxShadow: layer.layer02,
        borderLeft: '12px solid rgb(13, 71, 150)',
        fontSize: '120%',
        display: 'flex',
        alignItems: 'center',
      },
      customStyle,
    )}
    className={className}
  >
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        minHeight: '40px',
        flexDirection: 'row',
        width: '100%',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <FormLabel label={text} />
        <IconTooltip
          iconType="help"
          text={tooltipText}
          color="#000"
          customCss={customStyle ? { ...customStyle } : undefined}
          inlineBlock={inlineBlock}
          place={place}
        />
      </div>
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
          gap: '4px',
        }}
      >
        {children}
      </div>
    </div>
  </div>
);

export default CardContents;
