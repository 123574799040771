/** @jsx jsx */
import PrescribedHours from 'domain/master/labor/prescribedHours';
import { Column } from 'react-table';
import React, { useMemo, useCallback, useState } from 'react';
import { css, jsx } from '@emotion/core';
import LableTitle from 'components/atoms/LableTitle';
import PageTitle from 'components/atoms/PageTitle';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import DataTable from 'components/organismos/DataTable/DataTable';
import { deleteById } from 'api/prescribedHours';

import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import useToastNotification from 'hooks/useToastNotification';
import usePrescribedHoursList from './hooks';

const TimeZonePage: React.FC<{
  roleScreen?: any
}> = ({ roleScreen }) => {
  const { prescribedList, setPrescribedList } = usePrescribedHoursList();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedPrescribed, setSelectedPrescribed] = useState(PrescribedHours.generateInitial());
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((prescribed : PrescribedHours) => {
    setConfirmModalOpen(true);
    setSelectedPrescribed(prescribed);
  }, []);

  const deletePrescribed = useCallback(
    () => {
      const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
      deleteById(companyCode, selectedPrescribed.workingHoursId).then((response:any) => {
        setPrescribedList(prescribedList.filter((prescribed) => prescribed.workingHoursId
                                                      !== selectedPrescribed.workingHoursId));
        setConfirmModalOpen(false);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.map((element: any, index: any) => {
            stringErr += `${element.defaultMessage}\n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    },
    [errorNotification, prescribedList, selectedPrescribed.workingHoursId,
      setPrescribedList, successNotification],
  );
  const columns: Array<Column<PrescribedHours>> = useMemo(() => [
    {
      Header: 'コード',
      accessor: 'workingHoursPatternCode',
      sortType: 'basic'
    },
    {
      Header: 'パターン名',
      accessor: 'workingHoursPatternName',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: PrescribedHours },
            data: Array<PrescribedHours>
          }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/masterWorkingHours/edit/${cell.row.original.workingHoursId}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            {
              roleScreen && roleScreen.editable === 1 && (
              <React.Fragment>
                <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                  <span
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                    onMouseEnter={() => setIsHoverDelete(true)}
                    onMouseLeave={() => setIsHoverDelete(false)}
                  >
                    <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </Link>
              </React.Fragment>
            )}
          </div>
        );
      },
    },
  ], [openConfirmModal, roleScreen]);
  return (
    <React.Fragment>
      <div>
        <LableTitle title="月単位で残業時間を計算する場合に使用する各月の所定労働時間を登録します。" />
        {
          roleScreen && roleScreen.editable === 1 && (
          <PageTitle
            title=""
            rightContents={(
              <Link to="/masterWorkingHours/add">
                <span style={{ marginRight: '12px' }}>
                  <PrimaryButton
                    ghost={false}
                    text="新規所定労働時間作成"
                  />
                </span>
              </Link>
            )}
          />
        )}
        <div
          css={css`
            table th:nth-child(1) {
              width: 100px;
            }
            table th:nth-child(2) {
              width: 200px;
            }
            table th:nth-child(3) {
              width: 120px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTable
              columns={columns}
              data={prescribedList}
              sortBy={[
                { id: 'workingHoursPatternCode', desc: false }
              ]}
            />
          </div>
        </div>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={deletePrescribed}
          actionType={ActionType.DELETE}
        />
      </div>
    </React.Fragment>
  );
};

export default TimeZonePage;
