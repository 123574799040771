
/**
 * APIのデータ型
 */
export interface Terms {
  companyCode: string;
  termId: string;
  termName: string;
  targetType: number;
  startTime: string;
  endTime: string;
  termOrder: number;

}

export default class TermsDomain {
  constructor(private rawData: Terms) {
    // do nothing
  }

  static generateInitial(): TermsDomain {
    return new TermsDomain({
      companyCode: '',
      termId: '',
      termName: '',
      targetType: 0,
      termOrder: 0,
      startTime: '',
      endTime: '',
    });
  }

  getRawData(): Terms {
    return this.rawData;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = String(companyCode);
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set termId(termId: string) {
    this.rawData.termId = String(termId);
  }

  get termId(): string {
    return this.rawData.termId;
  }

  set termName(termName: string) {
    this.rawData.termName = String(termName);
  }

  get termName(): string {
    return this.rawData.termName;
  }

  set targetType(targetType: number) {
    this.rawData.targetType = Number(targetType);
  }

  get targetType(): number {
    return this.rawData.targetType;
  }

  set termOrder(termOrder: number) {
    this.rawData.termOrder = Number(termOrder);
  }

  get termOrder(): number {
    return this.rawData.termOrder;
  }

  set startTime(startTime: string) {
    this.rawData.startTime = startTime;
  }

  get startTime(): string {
    return this.rawData.startTime;
  }

  set endTime(endTime: string) {
    this.rawData.endTime = endTime;
  }

  get endTime(): string {
    return this.rawData.endTime;
  }
}
