import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AdvertisingImportCreateForm from 'components/organismos/master/sales/AdvertisingImportCreateForm';
import { useParams } from 'react-router-dom';

const AdvertisingImportCreatePage: React.FC = () => {
  const { importCode } = useParams();
  return (
    <SidebarTemplate pageTitle={importCode ? '広告取込編集' : '広告取込設定'}>
      <AdvertisingImportCreateForm />

    </SidebarTemplate>
  );
};

export default AdvertisingImportCreatePage;
