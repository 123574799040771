import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import { isMobile } from 'react-device-detect';
import OperationalReportQuick from 'components/organismos/master/sales/OperationalReportQuick';

const OperationalReportQuickPage = () => (
  <SidebarTemplate pageTitle={isMobile ? '稼働状況速報' : '店舗別稼働状況（速報）'}>
    <OperationalReportQuick />
  </SidebarTemplate>
);

export default OperationalReportQuickPage;
