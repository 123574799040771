/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  eqeqeq */

/** @jsx jsx */
import React, {
  Fragment, useEffect, useState, useRef, useMemo,
} from 'react';
import { css, jsx } from '@emotion/core';
import { useMonthlyShiftContext, useInitialState } from './contextHook';
import Table from 'components/molecules/Table';
import { utilityColor, grayScale } from 'components/styles';
import Item from 'components/molecules/CalendarTimeline/Item';
import Icon from 'components/atoms/Icon';
import ContextMenuShift from 'components/molecules/ContextMenu/ContextMenuShift';
import { createPortal } from 'react-dom';
import ReactTooltip from 'react-tooltip';
import { Row, Col } from 'react-grid-system';
import { getShiftPatterns, fetchMonthlyShift } from '../api';
import IconLabelButton from 'components/molecules/IconLabelButton';
import moment from 'moment';
import './style.scss';
import ChevronDown from 'components/atoms/Icon/ChevronDown';
import BodyText from 'components/atoms/BodyText';
import { photoPath } from 'hooks/usePhotoPath';
import PhotoFrame from 'components/molecules/PhotoFrame';
import { isIPad13 } from 'react-device-detect';
import BlockUI from 'components/molecules/BlockUi';
import Dropdown from 'components/molecules/Dropdown';
import { useParams } from 'react-router-dom';
import Controllbar from '../PaginationBar/Controllbar';
import { Literal, Values } from 'utility/constants';
import AccordionPanel from 'components/molecules/AccordionPanel';
import AccordionTableWrapper from 'components/molecules/AccordionTableWrapper';

const TableMonthlyShift: React.FC<{ role?: any }> = ({ role }) => {
  const {
    isLoading,
    setLoading,
    isMonthlyTableLoading,
    setRemote,
    history,
    filters: {
      selectedOrg,
      dateFromStr,
      isAttendExists,
      dateToStr,
      employmentOptions,
      pageTotal,
      pageIndex,
      setPageIndex,
    },

    monthlyCalendar: {
      monthlyShifts,
      staffs,
      setStaffs,
      pages,
      setPages,
      shopPlan,
      setSelectedItem,
      setSelectedCell,
      shiftConfirm,
      onClickAttendStampList,
      setDailyShiftConfirmModal,
      setSelectedDay,
      achievementConfirm,
    },
    contextMenu: {
      isPerformanceMenuVisible,
      setPerformanceMenuVisible,
      isItemMenuVisible,
      setItemMenuVisible,
      isTimelineMenuVisible,
      setTimelineMenuVisible,
      contextMenuRef,
      contextMenuX,
      setContextMenuX,
      contextMenuY,
      setContextMenuY,
      getMenuItemButtons,
      getOtherOrgStaffMenuItemButtons,
      getMenuPerformanceButtons,
      getMenuTimelineButtons,
      menuTimelineButtons,
      getOtherOrgStaffMenuTimelineButtons,
      setMenuTimelineButtons,
      menuPerformanceButtons,
      setMenuPerformanceButtons,
      menuItemButtons,
      setMenuItemButtons,
    },
    shiftPattern: { setShiftPatternOptions },
  } = useMonthlyShiftContext();
  const params = useParams<any>();
  const [pageSize, setPageSize] = useState(pages.pageSize || 50);
  const bodyNode = document.body as HTMLBodyElement;
  // Cacl width auto
  const calculateScrollBarWidth = () => {
    const inner = document.createElement('p');
    inner.style.width = '100%';
    inner.style.height = '200px';

    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'hidden';
    outer.appendChild(inner);

    document.body.appendChild(outer);
    const w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    let w2 = inner.offsetWidth;
    if (w1 == w2) w2 = outer.clientWidth;
    document.body.removeChild(outer);
    return w1 - w2;
  };

  // toggle table header
  const [headerWidth, setHeaderWidth] = useState<number>(0);
  const mainTable = useRef<any>(null);
  useEffect(() => {
    const main = document.querySelector('main');
    const tableHeader = document.getElementById('table-header');
    if (main && tableHeader && mainTable) {
      window.addEventListener('resize', (event) => {
        const isWidth = calculateScrollBarWidth();
        setHeaderWidth(
          Number(mainTable.current && mainTable.current.offsetWidth)
          - Number(isWidth) || 960,
        );
      });
      main.onscroll = function () {
        if (
          mainTable.current
          && main
          && main?.scrollTop > mainTable.current.offsetTop - 22
        ) {
          tableHeader!.style.visibility = 'visible';
          tableHeader!.style.display = 'block';
        } else {
          tableHeader!.style.visibility = 'hidden';
          tableHeader!.style.display = 'none';
        }
      };
      mainTable.current.ontouchmove = function () {
        tableHeader!.scrollLeft = mainTable.current.scrollLeft || 0;
      };
      mainTable.current.onscroll = function () {
        tableHeader!.scrollLeft = mainTable.current.scrollLeft || 0;
      };
    }

    const isWidth = calculateScrollBarWidth();
    setHeaderWidth(
      Number(mainTable.current.offsetWidth) - Number(isWidth) || 960,
    );
  }, []);

  useEffect(() => {
    setPageIndex(
      Number(sessionStorage.getItem('monthly_shift_current_page_index'))
      || pages.pageNumber,
    );
    setPageSize(pages.pageSize);
  }, [pages, pageIndex, selectedOrg, setPageIndex]);

  const canNextPage = pages.totalPages - pageIndex > 1;
  const canPreviousPage = pageIndex > 0;

  const [isLoadMore, setIsLoadMore] = useState(false);

  const handlePaginationPrevious = () => {
    const employmentIds = employmentOptions.reduce(
      (prev, current) => (current.checked ? `${current.id},${prev}` : prev),
      '1',
    );
    setLoading(true);
    fetchMonthlyShift(
      dateFromStr,
      dateToStr,
      selectedOrg,
      isAttendExists,
      employmentIds,
      '',
      pageIndex - 1,
      pages.pageSize,
    )
      .then((response) => {
        sessionStorage.setItem(
          'monthly_shift_current_page_index',
          `${pageIndex - 1}`,
        );
        setStaffs([...response.staffInfo]);
        setPages(response.page);
        setPageIndex(pageIndex - 1);
      })
      .finally(() => {
        setIsLoadMore(false);
        setLoading(false);
      });
  };

  const handlePaginationNext = () => {
    const employmentIds = employmentOptions.reduce(
      (prev, current) => (current.checked ? `${current.id},${prev}` : prev),
      '1',
    );
    setLoading(true);
    fetchMonthlyShift(
      dateFromStr,
      dateToStr,
      selectedOrg,
      isAttendExists,
      employmentIds,
      '',
      pageIndex + 1,
      pages.pageSize,
    )
      .then((response) => {
        sessionStorage.setItem(
          'monthly_shift_current_page_index',
          `${pageIndex + 1}`,
        );
        setStaffs([...response.staffInfo]);
        setPages(response.page);
        setPageIndex(pageIndex + 1);
      })
      .finally(() => {
        setLoading(false);
        setIsLoadMore(false);
      });
  };

  const handleGoToPage = (page_index: 0) => {
    const employmentIds = employmentOptions.reduce(
      (prev, current) => (current.checked ? `${current.id},${prev}` : prev),
      '1',
    );
    setLoading(true);
    fetchMonthlyShift(
      dateFromStr,
      dateToStr,
      selectedOrg,
      isAttendExists,
      employmentIds,
      '',
      page_index,
      pages.pageSize,
    )
      .then((response) => {
        sessionStorage.setItem(
          'monthly_shift_current_page_index',
          `${page_index}`,
        );
        setStaffs([...response.staffInfo]);
        setPages(response.page);
        setPageIndex(page_index);
      })
      .finally(() => {
        setIsLoadMore(false);
        setLoading(false);
      });
  };

  const handleSetPageSize = (page_size: any) => {
    const employmentIds = employmentOptions.reduce(
      (prev, current) => (current.checked ? `${current.id},${prev}` : prev),
      '1',
    );
    setLoading(true);
    fetchMonthlyShift(
      dateFromStr,
      dateToStr,
      selectedOrg,
      isAttendExists,
      employmentIds,
      '',
      0,
      page_size,
    )
      .then((response) => {
        setPageIndex(0);
        setPageSize(page_size);
        setStaffs([...response.staffInfo]);
        setPages(response.page);
        sessionStorage.setItem(
          'monthly_shift_current_page_size',
          `${page_size}`,
        );
        sessionStorage.removeItem('monthly_shift_current_page_index');
      })
      .finally(() => {
        setIsLoadMore(false);
        setLoading(false);
      });
  };

  const [isScroll, setScroll] = useState<boolean>(true);

  const handleScroll = () => {
    setScroll(false);
  };
  const formatterPercent = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    style: 'percent',
  });

  const historyDailyShift = params.orgName !== undefined && params.orgCode !== undefined && params.targetDate !== undefined
    ? `/dailyShift/${params.orgName}/${params.orgCode}/${params.targetDate}` : '/dailyShift';

  // Accordion for 人件費
  const [isExpandedLaborCost, setIsExpandedLaborCost] = useState(false);

  return (
    <React.Fragment>
      <BlockUI blocking={false}>
        {/* Table header */}
        <div
          id="table-header"
          onScroll={() => handleScroll()}
          style={{
            maxWidth: `${headerWidth}px`,
            position: 'sticky',
            top: '48px',
            zIndex: 82,
            overflowX: 'scroll',
            visibility: 'hidden',
            height: '150px',
            display: 'none',
          }}
        >
          <div id="table-inner">
            <Table
              className="table__monthly-shift"
              css={css({ td: { textAlign: 'center', lineHeight: '11px' } })}
              customStyle={css({
                marginBottom: '-1px',
                borderBottom: '1px solid #d3d3d3',
              })}
            >
              <colgroup>
                <col style={{ width: '160px' }} />
                <col style={{ width: '65px' }} />
                {shopPlan.map((number, i) => (
                  <col key={i} style={{ width: '100px' }} />
                ))}
                <col style={{ width: '100px' }} />
                <col style={{ width: '50px' }} />
                <col style={{ width: '50px' }} />
              </colgroup>
              <thead>
                <tr style={{ lineHeight: 0 }}>
                  <Table.ShiftHeaderCell
                    rowSpan={2}
                    className="freeze-col freeze-col__header border-r-none"
                  />
                  <Table.ShiftHeaderCell
                    rowSpan={2}
                    className="freeze-col-2 border-l-none"
                  />
                  {monthlyShifts?.targetDateList
                    && monthlyShifts.targetDateList.map((date, i) => {
                      const formattedDate = moment(date).format('YYYY-MM-DD');
                      return (
                        <Table.ShiftHeaderCell key={i} rowSpan={2}>
                          <Dropdown
                            items={[
                              {
                                value: 'workSchedule',
                                label: 'シフトの確定',
                                disabled:
                                  shiftConfirm[formattedDate]
                                  || achievementConfirm[formattedDate],
                                onClick: () => {
                                  setDailyShiftConfirmModal(true);
                                },
                              },
                              {
                                value: 'chevronRight',
                                label: '日次シフトへ移動',
                                disabled: false,
                                onClick: () => {
                                  sessionStorage.setItem(
                                    'attendStampList.dailyShift.targetDateFrom',
                                    moment(date).format('YYYY/MM/DD'),
                                  );
                                  sessionStorage.setItem(
                                    'attendStampList.dailyShift.orgCode',
                                    selectedOrg,
                                  );
                                  history.push(historyDailyShift);
                                },
                              },
                            ]}
                            setValue={() => {
                              setSelectedDay(moment(date).format('YYYY/MM/DD'));
                            }}
                            // isIconButton={false}
                            isDropdownDisplayed={false}
                            isTextCenter={true}
                            isScroll={isScroll}
                            setScroll={setScroll}
                          >
                            <span
                              css={css({
                                cursor: 'pointer',
                                '& > svg': {
                                  width: '10px',
                                  height: '16px',
                                  verticalAlign: 'sub',
                                  '&>path': { fill: grayScale.gray01 },
                                },
                              })}
                            >
                              {moment(date).format('M/D ddd')}
                              {' '}
                              <ChevronDown />
                            </span>
                          </Dropdown>
                        </Table.ShiftHeaderCell>
                      );
                    })}
                  <Table.ShiftHeaderCell rowSpan={2}>
                    時間数合計
                  </Table.ShiftHeaderCell>
                  <Table.ShiftHeaderCell colSpan={2}>
                    公休数
                  </Table.ShiftHeaderCell>
                </tr>
                <tr style={{ lineHeight: 0 }}>
                  {/* <Table.ShiftHeaderCell className="freeze-col-2 border-t-none border-l-none"></Table.ShiftHeaderCell> */}
                  <Table.ShiftHeaderCell
                    customStyle={css({ padding: '14px 0' })}
                  >
                    法定
                  </Table.ShiftHeaderCell>
                  <Table.ShiftHeaderCell
                    customStyle={css({ padding: '14px 0' })}
                  >
                    所定
                  </Table.ShiftHeaderCell>
                </tr>
              </thead>
            </Table>
          </div>
        </div>
        {/* /.Table header */}

        <AccordionPanel
              text="期間計"
              color={grayScale.gray100}
              callBack={() => {}}
              defaultExpand={true}
            >
          <div
            className="monthly_shift_earning_wrapper"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <table className="monthly_shift_earning_left">
              <thead>
                <tr>
                  <th colSpan={2}>売上</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    前年実績
                  </td>
                  <td>
                    {monthlyShifts?.summaryInfo?.salesAchievementTotalPreviousYear || 0}
                  </td>
                </tr>
                <tr>
                  <td>
                    予算
                  </td>
                  <td>
                    {monthlyShifts?.summaryInfo?.salesPlanTotal || 0}
                  </td>
                </tr>
                <tr>
                  <td>
                    実績
                  </td>
                  <td>
                    {monthlyShifts?.summaryInfo?.salesAchievementTotal || 0}
                  </td>
                </tr>
                <tr>
                  <td>
                    予実比
                  </td>
                  <td>
                    {monthlyShifts?.summaryInfo?.salesPlanAchievementRate || "0.00%"}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="monthly_shift_earning_right">
              <thead>
                <tr>
                  <th />
                  <th colSpan={3}>正社員</th>
                  <th colSpan={3}>アルバイト他</th>
                  <th colSpan={3}>合計</th>
                </tr>
                <tr>
                  <th />
                  <th>勤務時間(h)</th>
                  <th>人件費</th>
                  <th>人件費率</th>
                  <th>勤務時間(h)</th>
                  <th>人件費</th>
                  <th>人件費率</th>
                  <th>勤務時間(h)</th>
                  <th>人件費</th>
                  <th>人件費率</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                  <td>予算 </td>
                  <td>-</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostBudgetShopMonthlyEmployee || 0}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostRateBudgetShopMonthlyEmployee || "0.00%"}</td>
                  <td>-</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostBudgetShopMonthlyPart || 0}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostRateBudgetShopMonthlyPart || "0.00%"}</td>
                  <td>-</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostBudgetShopMonthly || 0}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostRateBudgetShopMonthly || "0.00%"}</td>
                </tr>
                <tr>
                  <td>シフト </td>
                  <td>{monthlyShifts?.summaryInfo?.workTimeShiftShopMonthlyEmployee || "0:00"}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostShiftShopMonthlyEmployee || 0}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostRateShiftShopMonthlyEmployee || "0.00%"}</td>
                  <td>{monthlyShifts?.summaryInfo?.workTimeShiftShopMonthlyPart || "0:00"}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostShiftShopMonthlyPart || 0}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostRateShiftShopMonthlyPart || "0.00%"}</td>
                  <td>{monthlyShifts?.summaryInfo?.workTimeShiftShopMonthly || "0:00"}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostShiftShopMonthly || 0}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostRateShiftShopMonthly || "0.00%"}</td>
                </tr>
                <tr>
                  <td>実績</td>
                  <td>{monthlyShifts?.summaryInfo?.workTimeAchievementShopMonthlyEmployee || "0:00"}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostAchievementShopMonthlyEmployee || 0}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostRateAchievementShopMonthlyEmployee || "0.00%"}</td>
                  <td>{monthlyShifts?.summaryInfo?.workTimeAchievementShopMonthlyPart || "0:00"}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostAchievementShopMonthlyPart || 0}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostRateAchievementShopMonthlyPart || "0.00%"}</td>
                  <td>{monthlyShifts?.summaryInfo?.workTimeAchievementShopMonthly || "0:00"}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostAchievementShopMonthly || 0}</td>
                  <td>{monthlyShifts?.summaryInfo?.laborCostRateAchievementShopMonthly || "0.00%"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </AccordionPanel>
        <Controllbar
                numberOfElements={pages.numberOfElements || 0}
                pageNumber={pageIndex + 1 || 1}
                pageSize={pageSize || 50}
                totalElements={pages.totalElements}
                totalPages={pages.totalPages || 1}
                handlePaginationPrevious={handlePaginationPrevious}
                handlePaginationNext={handlePaginationNext}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                handleGoToPage={handleGoToPage}
                handleSetPageSize={handleSetPageSize}
              />
        <div
          onScroll={() => handleScroll()}
          id="main-table"
          ref={mainTable}
          style={{
            width: '100%',
            overflowX: 'auto',
            maxHeight: isIPad13 ? 'auto' : '70vh',
          }}
        >
          {isPerformanceMenuVisible
            && createPortal(
              <div className="contextMenu">
                <ContextMenuShift
                  buttons={menuPerformanceButtons}
                  pageX={contextMenuX}
                  pageY={contextMenuY}
                  parentRef={contextMenuRef}
                  setIsComponentVisible={setPerformanceMenuVisible}
                />
              </div>,
              bodyNode,
            )}
          {isItemMenuVisible
            && createPortal(
              <div className="contextMenu">
                <ContextMenuShift
                  buttons={menuItemButtons}
                  pageX={contextMenuX}
                  pageY={contextMenuY}
                  parentRef={contextMenuRef}
                  setIsComponentVisible={setItemMenuVisible}
                />
              </div>,
              bodyNode,
            )}
          {isTimelineMenuVisible
            && createPortal(
              <div className="contextMenu">
                <ContextMenuShift
                  buttons={menuTimelineButtons}
                  pageX={contextMenuX}
                  pageY={contextMenuY}
                  parentRef={contextMenuRef}
                  setIsComponentVisible={setTimelineMenuVisible}
                />
              </div>,
              bodyNode,
            )}
          <Table
            className="table__monthly-shift"
            css={css({ td: { textAlign: 'center', lineHeight: '11px' } })}
          >
            <colgroup>
              <col style={{ width: '20px' }} />
              <col style={{ width: '75px' }} />
              <col style={{ width: '65px' }} />
              <col style={{ width: '65px' }} />
              {shopPlan.map((number, i) => (
                <col key={i} style={{ width: '100px' }} />
              ))}
              <col style={{ width: '100px' }} />
              <col style={{ width: '50px' }} />
              <col style={{ width: '50px' }} />
            </colgroup>

            <thead>
              <tr style={{ lineHeight: 0 }}>
                <Table.ShiftHeaderCell
                  rowSpan={2}
                  colSpan={3}
                  className="freeze-col freeze-col__header border-r-none"
                  style={{
                    position: 'sticky', top: 0,
                  }}
                />
                <Table.ShiftHeaderCell
                  rowSpan={2}
                  className="border-l-none"
                  style={{
                    position: 'sticky', top: 0, zIndex: 3, left: '95px',
                  }}
                />
                {monthlyShifts?.targetDateList
                  && monthlyShifts.targetDateList.map((date, i) => {
                    const formattedDate = moment(date).format('YYYY-MM-DD');
                    return (
                      <Table.ShiftHeaderCell
                        key={i}
                        rowSpan={2}
                        style={{
                          position: 'sticky', top: 0, zIndex: 3
                        }}
                      >
                        <Dropdown
                          items={[
                            {
                              value: 'workSchedule',
                              label: 'シフトの確定',
                              disabled:
                                shiftConfirm[formattedDate]
                                || achievementConfirm[formattedDate],
                              onClick: () => {
                                setDailyShiftConfirmModal(true);
                              },
                            },
                            {
                              value: 'chevronRight',
                              label: '日次シフトへ移動',
                              disabled: false,
                              onClick: () => {
                                sessionStorage.setItem(
                                  'attendStampList.dailyShift.targetDateFrom',
                                  moment(date).format('YYYY/MM/DD'),
                                );
                                sessionStorage.setItem(
                                  'attendStampList.dailyShift.orgCode',
                                  selectedOrg,
                                );
                                history.push(historyDailyShift);
                              },
                            },
                          ]}
                          setValue={() => {
                            setSelectedDay(moment(date).format('YYYY/MM/DD'));
                          }}
                          // isIconButton={false}
                          isDropdownDisplayed={false}
                          isTextCenter={true}
                          isScroll={isScroll}
                          setScroll={setScroll}
                          fixedDropDown={true}
                        >
                          <span
                            css={css({
                              cursor: 'pointer',
                              '& > svg': {
                                width: '10px',
                                height: '16px',
                                verticalAlign: 'sub',
                                '&>path': { fill: grayScale.gray01 },
                              },
                            })}
                          >
                            {moment(date).format('M/D ddd')}
                            {' '}
                            <ChevronDown />
                          </span>
                        </Dropdown>
                      </Table.ShiftHeaderCell>
                    );
                  })}
                <Table.ShiftHeaderCell
                  rowSpan={2}
                  style={{
                    position: 'sticky', top: 0,
                  }}
                >
                  時間数合計
                </Table.ShiftHeaderCell>
                <Table.ShiftHeaderCell
                  colSpan={2}
                  style={{
                    position: 'sticky', top: 0,
                  }}
                >
                  公休数
                </Table.ShiftHeaderCell>
              </tr>
              <tr style={{ lineHeight: 0 }}>
                {/* <Table.ShiftHeaderCell className="freeze-col-2 border-t-none border-l-none border-t-none"></Table.ShiftHeaderCell> */}
                <Table.ShiftHeaderCell
                  customStyle={css({ padding: '14px 0' })}
                  style={{
                    position: 'sticky', top: '30px',
                  }}
                >
                  法定
                </Table.ShiftHeaderCell>
                <Table.ShiftHeaderCell
                  customStyle={css({ padding: '14px 0' })}
                  style={{
                    position: 'sticky', top: '30px',
                  }}
                >
                  所定
                </Table.ShiftHeaderCell>
              </tr>
            </thead>
            <tbody>
              {staffs.map((staff, indexStaff) => (
                <Fragment key={indexStaff}>
                  {/* RowId1 */}
                  <tr>
                    <Table.ShiftCell
                      rowSpan={3}
                      colSpan={3}
                      style={{
                        textAlign: 'left',
                        padding: '10px 0 10px 20px',
                        lineHeight: '18px',
                        fontSize: '12px',
                        position: 'sticky',
                        left: 0,
                        backgroundColor: '#ffffff',
                      }}
                    >
                      <div className="d-flex" style={{ alignItems: 'center' }}>
                        <div
                          style={{
                            borderRadius: '50%',
                            overflow: 'hidden',
                            height: '38px',
                            width: '38px',
                          }}
                        >
                          <PhotoFrame
                            src={photoPath(staff)}
                            size="34px"
                            isCircle={true}
                            onErrorIconColor="black"
                          />
                        </div>
                        {/* <IconLabelButton
                          onClick={() => onClickAttendStampList(staff)}
                          text="詳細"
                          isIcon={false}
                          disabled={staff.isSupportStaff ? true : false}
                        /> */}
                      </div>
                      <div
                        css={css`
                          display: -webkit-box;
                          -webkit-line-clamp: 3;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                        `}
                      >
                        {staff.staffCode}
                      </div>
                      <div
                        css={css`
                          display: -webkit-box;
                          -webkit-line-clamp: 3;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                        `}
                      >
                        {staff.staffName}
                      </div>
                      <div
                        css={css`
                          display: -webkit-box;
                          -webkit-line-clamp: 3;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                        `}
                      >
                        {staff.isSupportStaff ? `(${staff.orgName})` : null}
                      </div>
                    </Table.ShiftCell>
                    <Table.ShiftCell>
                      希望
                    </Table.ShiftCell>

                    {staff.dateInfo.map((dateinfo, i) => {
                      let hopeTitle = '';
                      if (dateinfo.hope) {
                        switch (dateinfo.hope.hopeShiftPatternType) {
                          case 1:
                            hopeTitle = 'o';
                            break;

                          case 2:
                            hopeTitle = '×';
                            break;

                          case 4:
                            hopeTitle = dateinfo.hope.holidayName || '';
                            break;

                          case 5:
                            hopeTitle = dateinfo.hope.holidayName || '';
                            break;

                          default:
                            hopeTitle = dateinfo.hope.shiftPatternName || '-';
                            break;
                        }
                      }
                      return (
                        <Table.ShiftCell key={i} style={{ padding: '2px 5px' }}>
                          {hopeTitle && (
                            <div
                              data-tip={hopeTitle}
                              data-for={`row1-${i}-${hopeTitle}`}
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontSize: '12px',
                                height: '14px',
                              }}
                            >
                              {hopeTitle}
                              <ReactTooltip
                                html={true}
                                id={`row1-${i}-${hopeTitle}`}
                              />
                            </div>
                          )}
                        </Table.ShiftCell>
                      );
                    })}

                    <Table.ShiftCell>-</Table.ShiftCell>
                    <Table.ShiftCell>-</Table.ShiftCell>
                    <Table.ShiftCell>-</Table.ShiftCell>
                  </tr>

                  {/* RowId2 */}
                  <tr>
                    <Table.ShiftCell>
                      シフト
                    </Table.ShiftCell>
                    {staff.dateInfo.map((dateinfo, i) => {
                      const formattedDate = moment(dateinfo.targetDate).format(
                        'YYYY-MM-DD',
                      );
                      return (
                        <Table.ShiftCell
                          key={i}
                          style={{ padding: '8px 5px' }}
                          className={
                            shiftConfirm[formattedDate]
                              || achievementConfirm[formattedDate]
                              || dateinfo.workSystem === 0
                              || dateinfo.workSystem === 2
                              || dateinfo.workSystem === 3
                              ? ''
                              : 'cursor-pointer on-hover'
                          }
                          onClick={async (event: React.MouseEvent) => {
                            if (role !== null && role.editable === 1) {
                              setRemote(dateinfo.useRemote);
                              if (
                                shiftConfirm[formattedDate]
                                || achievementConfirm[formattedDate]
                                || dateinfo.workSystem === 0
                                || dateinfo.workSystem === 2
                                || dateinfo.workSystem === 3
                              ) {
                                return;
                              }
                              if (
                                dateinfo.isAchievementConfirm
                                || dateinfo.shiftList.length == 0
                              ) {
                                setContextMenuX(event.pageX);
                                setContextMenuY(event.pageY);

                                // fetch Shiftpattern
                                try {
                                  var shiftPatternData = await getShiftPatterns(
                                    staff.employmentId,
                                    selectedOrg,
                                  );
                                } catch (error) {
                                  shiftPatternData = [];
                                }

                                setShiftPatternOptions(shiftPatternData);

                                const timelineButtons = staff.orgCode === selectedOrg
                                  ? getMenuTimelineButtons(
                                    staff.staffCode,
                                    staff.staffName,
                                    dateinfo.targetDate,
                                    shiftPatternData,
                                    dateinfo.distinctionHoliday,
                                    dateinfo.closingHour,
                                  )
                                  : getOtherOrgStaffMenuTimelineButtons(
                                    staff.staffCode,
                                    dateinfo.targetDate,
                                    shiftPatternData,
                                  );

                                setMenuTimelineButtons(timelineButtons);
                                setSelectedCell({
                                  staffCode: staff.staffCode,
                                  staffName: staff.staffName,
                                  targetDate: moment(dateinfo.targetDate).format(
                                    'YYYY/MM/DD HH:mm:ss',
                                  ),
                                  distinctionHoliday: dateinfo.distinctionHoliday,
                                });
                                setTimelineMenuVisible(true);
                              }
                            }
                          }}
                        >
                          <div className="cell__itemWrapper">
                            {dateinfo.shiftList
                              && dateinfo.shiftList.map((shift, i) => {
                                let title = '';
                                if (shift.holidayName) {
                                  const holidayType = shift.isLegal
                                    ? ' (法定)'
                                    : ' (所定)';
                                  title = shift.distinctionHoliday
                                    ? shift.holidayName + holidayType
                                    : shift.holidayName;
                                } else if (shift.shiftPatternId) {
                                  title = `${shift.shiftPatternName}(${shift.shiftTimePdfDisp})`;
                                } else {
                                  title = shift.shiftTimePdfDisp;
                                }

                                // 他店舗に出勤する所属スタッフは支援先の店舗名を付加
                                if (shift.orgCode !== selectedOrg) {
                                  title += ` (${shift.orgName})`;
                                }

                                return (
                                  <Item
                                    key={i}
                                    id={shift.id}
                                    title={title}
                                    disable={
                                      shiftConfirm[formattedDate]
                                      || achievementConfirm[formattedDate]
                                      || dateinfo.workSystem === 0
                                      || dateinfo.workSystem === 2
                                      || dateinfo.workSystem === 3
                                    }
                                    bgColor={shift.holidayBgcolor}
                                    textColor={shift.holidayForecolor}
                                    onClick={async (event) => {
                                      setContextMenuX(event.pageX);
                                      setContextMenuY(event.pageY);

                                      // fetch Shiftpattern
                                      try {
                                        var shiftPatternData = await getShiftPatterns(
                                          staff.employmentId,
                                          selectedOrg,
                                        );
                                      } catch (error) {
                                        shiftPatternData = [];
                                      }

                                      setShiftPatternOptions(shiftPatternData);
                                      const itemButtons = staff.orgCode === selectedOrg
                                        ? getMenuItemButtons(
                                          staff.staffCode,
                                          staff.staffName,
                                          dateinfo.targetDate,
                                          shiftPatternData,
                                          dateinfo.distinctionHoliday,
                                          dateinfo.closingHour,
                                        )
                                        : getOtherOrgStaffMenuItemButtons(
                                          staff.staffCode,
                                          dateinfo.targetDate,
                                          shiftPatternData,
                                        );
                                      setMenuItemButtons(itemButtons);

                                      setSelectedCell({
                                        staffCode: staff.staffCode,
                                        staffName: staff.staffName,
                                        targetDate: moment(
                                          dateinfo.targetDate,
                                        ).format('YYYY/MM/DD HH:mm:ss'),
                                        distinctionHoliday: dateinfo.distinctionHoliday,
                                      });
                                      setSelectedItem(shift);
                                      setItemMenuVisible(true);
                                    }}
                                  />
                                );
                              })}
                          </div>
                          {dateinfo.shiftAlertMessage && (
                            <div className="cell__itemWrapper"
                              css={css({
                                display: 'inline-block',
                              })}
                            >
                              <div
                                data-tip={
                                  dateinfo.shiftAlertMessage || ''
                                }
                                data-for={`shiftalert-${indexStaff}-${dateinfo.targetDate}`}
                                css={css({
                                  width: 'fit-content',
                                  display: 'inline-flex',
                                })}
                              >
                                <Icon
                                  type="warning"
                                  color={utilityColor.yellow}
                                  verticalAlign="text-bottom"
                                />
                                <ReactTooltip
                                  html={true}
                                  id={`shiftalert-${indexStaff}-${dateinfo.targetDate}`}
                                />
                              </div>
                            </div>
                          )}

                        </Table.ShiftCell>
                      );
                    })}

                    <Table.ShiftCell>{staff.shiftWorkTime}</Table.ShiftCell>
                    <Table.ShiftCell>
                      {role !== null && role.usabilityGeneralItem2 === 1 ? staff.shiftLegalHolidayCount : '-'}
                    </Table.ShiftCell>
                    <Table.ShiftCell>
                      {role !== null && role.usabilityGeneralItem2 === 1 ? staff.shiftPrescribedHolidayCount : '-'}
                    </Table.ShiftCell>
                  </tr>
                  {/* RowId3 */}
                  <tr>
                    <Table.ShiftCell>
                      実績
                    </Table.ShiftCell>
                    {monthlyShifts?.workTimeInfo?.daily.length > 0
                      && staff.dateInfo.map((dateinfo, i) => (
                        <Table.ShiftCell
                          key={i}
                          style={{ padding: '2px 5px' }}
                          className={
                            !dateinfo.isAchievementConfirm
                              ? 'cursor-pointer on-hover'
                              : ''
                          }
                          onClick={(event: React.MouseEvent) => {
                            if (!dateinfo.isAchievementConfirm && role !== null && role.editable === 1) {
                              const performanceButtons = getMenuPerformanceButtons(
                                dateinfo.achievementList[0]
                                  ?.modifierApplicationId || null, // modifierApplicationId : any
                                staff.orgCode,
                                staff.staffCode,
                                staff.staffName,
                                null, // modifierApplicationStatus: number | null
                                dateinfo.achievementList[0]
                                  ?.holidayApplicationStatus, // holidayApplicationStatus: number | null,
                                null, // overworkApplicationStatus: number | null,
                                null, // holidayworkApplicationStatus: number | null,
                                null, // transferApplicationStatus: number | null,
                                '', // attendanceOrgFix: string,
                                dateinfo.useOverTimeApplication, // useOverTimeApplication: any,
                                dateinfo.necessaryHolidayWorkApplication, // necessaryHolidayWorkApplication: any,
                                dateinfo.useTransferApplication, // useTransferApplication: any,
                                dateinfo.achievementList[0]?.idToString || null, // achievementId: any,
                                dateinfo.targetDate,
                                dateinfo.achievementList[0]?.stampVersion
                                || null,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                dateinfo.achievementList[0]?.holidayId,
                                dateinfo.achievementList[0]?.isHolidayWork,
                              );
                              setMenuPerformanceButtons(performanceButtons);
                              setContextMenuX(event.pageX);
                              setContextMenuY(event.pageY);
                              setPerformanceMenuVisible(true);
                            }
                          }}
                        >
                          <div className="cell__itemWrapper">
                            {dateinfo.achievementList
                              && dateinfo.achievementList
                                .map((achievement, i) => {
                                  let title = '';
                                  if (achievement.holidayName) {
                                    let holidayTypeName = achievement.transferType !== 0
                                      ? ' (振替休日) '
                                      : '';

                                    if (achievement.holidayType === 1) {
                                      switch (
                                      achievement.holidayDigestiveUnit
                                      ) {
                                        case 0:
                                          holidayTypeName = ' (全)';
                                          break;

                                        case 2:
                                          holidayTypeName = ' (時間)';
                                          break;

                                        default:
                                          break;
                                      }
                                    }

                                    title = achievement.isHolidayWork
                                      ? '休日出勤'
                                      : achievement.holidayName
                                      + holidayTypeName;
                                  } else if (
                                    achievement.workTimeDisp !== '0'
                                  ) {
                                    title = achievement.workTimeDisp || '';
                                  }
                                  return [achievement, title];
                                })
                                .reduce((unique: any, item: any) => {
                                  if (
                                    unique.find((u: any) => u[1] === item[1])
                                  ) {
                                    return unique;
                                  }
                                  return [...unique, item];
                                }, [])
                                .map((item: any) => (
                                  <Fragment key={i}>
                                    <div
                                      data-tip={item[1]}
                                      data-for={`row3-${indexStaff}-${item[0].targetDate}-${item[1]}`}
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        height: '14px',
                                      }}
                                    >
                                      {item[1]}
                                      <ReactTooltip
                                        html={true}
                                        id={`row3-${indexStaff}-${item[0].targetDate}-${item[1]}`}
                                      />
                                    </div>
                                  </Fragment>
                                ))}
                          </div>
                          {dateinfo.isAchievementConfirm && (
                            <Icon
                              type="singlecheckRounded"
                              color="#34a659"
                              size="18px"
                            />
                          )}
                          {!dateinfo.isAchievementConfirm &&
                            dateinfo.isAchievementLock && (
                            <Icon
                              type="lock"
                              color="#4b4b4b"
                              size="18px"
                            />
                          )}
                          {dateinfo.achievementErrorMessage && (
                            <div className="cell__itemWrapper"
                              css={css({
                                display: 'inline-block',
                              })}
                            >
                              <div
                                data-tip={
                                  dateinfo.achievementErrorMessage || ''
                                }
                                data-for={`error-${indexStaff}-${dateinfo.targetDate}`}
                                css={css({
                                  width: 'fit-content',
                                  display: 'inline-flex',
                                })}
                              >
                                <Icon
                                  type="remove"
                                  color=""
                                  verticalAlign="text-bottom"
                                />
                                <ReactTooltip
                                  html={true}
                                  id={`error-${indexStaff}-${dateinfo.targetDate}`}
                                />
                              </div>
                            </div>
                          )}
                          {dateinfo.achievementAlertMessage && (
                            <div className="cell__itemWrapper"
                              css={css({
                                display: 'inline-block',
                              })}
                            >
                              <div
                                data-tip={
                                  dateinfo.achievementAlertMessage || ''
                                }
                                data-for={`alert-${indexStaff}-${dateinfo.targetDate}`}
                                css={css({
                                  width: 'fit-content',
                                  display: 'inline-flex',
                                })}
                              >
                                <Icon
                                  type="warningFill"
                                  color={utilityColor.yellow}
                                  verticalAlign="text-bottom"
                                />
                                <ReactTooltip
                                  html={true}
                                  id={`alert-${indexStaff}-${dateinfo.targetDate}`}
                                />
                              </div>
                            </div>
                          )}
                          {dateinfo.achievementInfoMessage && (
                            <div className="cell__itemWrapper"
                              css={css({
                                display: 'inline-block',
                              })}
                            >
                              <div
                                data-tip={
                                  dateinfo.achievementInfoMessage || ''
                                }
                                data-for={`alert-${indexStaff}-${dateinfo.targetDate}`}
                                css={css({
                                  width: 'fit-content',
                                  display: 'inline-flex',
                                })}
                              >
                                <Icon
                                  type="warning"
                                  color={utilityColor.yellow}
                                  verticalAlign="text-bottom"
                                />
                                <ReactTooltip
                                  html={true}
                                  id={`alert-${indexStaff}-${dateinfo.targetDate}`}
                                />
                              </div>
                            </div>
                          )}
                          {/* <Col md={dateinfo.achievementErrorMessage || dateinfo.achievementAlertMessage ? 3 : 0}>
                            {dateinfo.achievementErrorMessage || (dateinfo.achievementErrorMessage && dateinfo.achievementAlertMessage) ? (
                              <div
                                data-tip={dateinfo.achievementErrorMessage || ""}
                                data-for={`error-${indexStaff}-${dateinfo.targetDate}`}
                                css={css({
                                  width: "fit-content",
                                  display: "inline-flex",
                                })}
                              >
                                <Icon type="remove" color="" verticalAlign="text-bottom" />
                                <ReactTooltip html={true} id={`error-${indexStaff}-${dateinfo.targetDate}`} />
                              </div>
                            ) : dateinfo.achievementAlertMessage ? (
                              <div
                                data-tip={dateinfo.achievementAlertMessage || ""}
                                data-for={`alert-${indexStaff}-${dateinfo.targetDate}`}
                                css={css({
                                  width: "fit-content",
                                  display: "inline-flex",
                                })}
                              >
                                <Icon type="warning" color={utilityColor.yellow} verticalAlign="text-bottom" />
                                <ReactTooltip html={true} id={`alert-${indexStaff}-${dateinfo.targetDate}`} />
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col md={dateinfo.achievementErrorMessage || dateinfo.achievementAlertMessage ? 9 : 12}>
                            {dateinfo.achievementList &&
                              dateinfo.achievementList.map((achievement, i) => {
                                let title = "";
                                if (achievement.holidayName) {
                                  let holidayType = achievement.transferType !== 0 ? " (振替休日)" : "";
                                  title = achievement.holidayName + holidayType;
                                } else {
                                  title = achievement.workTimeDisp || "";
                                }
                                return (
                                  <Fragment key={i}>
                                    <div
                                      data-tip={title}
                                      data-for={`row3-${indexStaff}-${achievement.targetDate}-${title}`}
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {title}
                                      <ReactTooltip html={true} id={`row3-${indexStaff}-${achievement.targetDate}-${title}`} />
                                    </div>
                                  </Fragment>
                                );
                              })}
                          </Col> */}
                        </Table.ShiftCell>
                      ))}
                    <Table.ShiftCell>
                      {staff.achievementWorkTime}
                    </Table.ShiftCell>
                    <Table.ShiftCell>
                      {role !== null && role.usabilityGeneralItem2 === 1 ? staff.achievementLegalHolidayCount : '-'}
                    </Table.ShiftCell>
                    <Table.ShiftCell>
                      {role !== null && role.usabilityGeneralItem2 === 1 ? staff.achievementPrescribedHolidayCount : '-'}
                    </Table.ShiftCell>
                  </tr>
                </Fragment>
              ))}
            </tbody>
            {/* SUM BOTTOM */}
            <AccordionTableWrapper
                      isExpanded={isExpandedLaborCost}
                      setStateAccordion={setIsExpandedLaborCost}
                    >
              <tbody>
                <tr>
                  <Table.ShiftCell className="freeze-col freeze-col__row" colSpan={3}
                    style={{ lineHeight: 'normal', textAlign: 'left', borderRight: 'none', borderBottom: 'none'}}
                  >
                    <span>人件費</span>
                    {
                      isExpandedLaborCost
                        ? <Icon type="chevronDown" color={grayScale.gray100} />
                        : <Icon type="chevronRight" color={grayScale.gray100} />
                    }
                  </Table.ShiftCell>
                  <Table.ShiftCell className="freeze-col-2" style={{borderLeft: 'none', borderBottom: 'none'}} />
                  <Table.ShiftCell colSpan={(monthlyShifts?.targetDateList?.length ?? 0) + 3} style={{borderLeft: 'none'}} />
                </tr>
              </tbody>
              <tbody>
                {/* 時間数合計 (h) */}
                <tr>
                  <Table.ShiftCell className="freeze-col freeze-col__row" rowSpan={3} style={{borderTop: 'none', borderBottom: 'none'}} />
                  <Table.ShiftCell className="freeze-col freeze-col__row freeze-col__row--expand" rowSpan={3}>
                    正社員
                  </Table.ShiftCell>
                  <Table.ShiftCell
                    className="freeze-col-2"
                    style={{ lineHeight: '18px' }}
                  >
                    時間数 (h)
                  </Table.ShiftCell>
                  <Table.ShiftCell
                    rowSpan={3}
                    style={{ lineHeight: '18px' }}
                  >
                    実績<br/>(予定)
                  </Table.ShiftCell>

                  {monthlyShifts?.workTimeInfo?.daily.map((item, i) => (
                    <Table.ShiftCell
                      key={i}
                      style={{ lineHeight: '18px', textAlign: 'right' }}
                    >
                      <span>{`${item.workTimeAchievementShopDailyEmployee || "0:00"} (${item.workTimeShiftShopDailyEmployee || "0:00"})`}</span>
                    </Table.ShiftCell>
                  ))}

                  <Table.ShiftCell
                    style={{
                      padding: '14px 5px',
                      lineHeight: '18px',
                      textAlign: 'right',
                    }}
                  >
                    { monthlyShifts.workTimeInfo && (
                      <Fragment>
                        <span>
                          {`${monthlyShifts?.workTimeInfo?.workTimeAchievementShopMonthlyEmployee || "0:00"} (${monthlyShifts?.workTimeInfo?.workTimeShiftShopMonthlyEmployee || "0:00"})`}
                        </span>
                      </Fragment>
                    )}
                  </Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                </tr>
                {/* 人件費合計	 */}
                <tr>
                  <Table.ShiftCell className="freeze-col-2">
                    人件費
                  </Table.ShiftCell>
                  {/* <Table.ShiftCell className="freeze-col-2" /> */}

                  {monthlyShifts?.laborCostInfo?.daily.map((item, i) => (
                    <Table.ShiftCell
                      key={i}
                      style={{ lineHeight: '18px', textAlign: 'right' }}
                    >
                      <span>
                        {`${item.laborCostAchievementShopDailyEmployee || 0} (${item.laborCostShiftShopDailyEmployee || 0})`}
                      </span>
                    </Table.ShiftCell>
                  ))}

                  <Table.ShiftCell
                    style={{ padding: '14px 5px', lineHeight: '18px' }}
                  >
                    -
                  </Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                </tr>
                {/* 人件費率	 */}
                <tr>
                  <Table.ShiftCell className="freeze-col-2">
                    人件費率
                  </Table.ShiftCell>
                  {/* <Table.ShiftCell className="freeze-col-2" /> */}

                  {monthlyShifts?.laborCostInfo?.daily.map(
                      (item: any, i: number) => (
                        <Table.ShiftCell
                          key={i}
                          style={{ lineHeight: '18px', textAlign: 'right' }}
                        >
                          <span>
                            {`${item.laborCostRateAchievementShopDailyEmployee || "0.00%"} (${item.laborCostRateShiftShopDailyEmployee || "0.00%"})`}
                          </span>
                        </Table.ShiftCell>
                      )
                    )}

                  <Table.ShiftCell
                    style={{ padding: '14px 5px', lineHeight: '18px' }}
                  >
                    -
                  </Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                </tr>
              </tbody>

              <tbody>
                {/* 時間数合計 (h) */}
                <tr>
                  <Table.ShiftCell className="freeze-col freeze-col__row " rowSpan={3} style={{borderTop: 'none', borderBottom: 'none'}} />
                  <Table.ShiftCell className="freeze-col freeze-col__row freeze-col__row--expand" rowSpan={3} style={{fontSize: "12px"}}>
                    アルバイト他
                  </Table.ShiftCell>
                  <Table.ShiftCell
                    className="freeze-col-2"
                    style={{ lineHeight: '18px' }}
                  >
                    時間数 (h)
                  </Table.ShiftCell>
                  <Table.ShiftCell
                    rowSpan={3}
                    style={{ lineHeight: '18px' }}
                  >
                    実績<br/>(予定)
                  </Table.ShiftCell>

                  {monthlyShifts?.workTimeInfo?.daily.map((item, i) => (
                    <Table.ShiftCell
                      key={i}
                      style={{ lineHeight: '18px', textAlign: 'right' }}
                    >
                      <span>{`${item.workTimeAchievementShopDailyPart || "0:00"} (${item.workTimeShiftShopDailyPart || "0:00"})`}</span>
                    </Table.ShiftCell>
                  ))}

                  <Table.ShiftCell
                    style={{
                      padding: '14px 5px',
                      lineHeight: '18px',
                      textAlign: 'right',
                    }}
                  >
                    { monthlyShifts.workTimeInfo && (
                      <Fragment>
                        <span>
                          {`${monthlyShifts.workTimeInfo?.workTimeAchievementShopMonthlyPart || "0:00"} (${monthlyShifts.workTimeInfo?.workTimeShiftShopMonthlyPart || "0:00"})`}
                        </span>
                      </Fragment>
                    )}
                  </Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                </tr>
                {/* 人件費合計	 */}
                <tr>
                  <Table.ShiftCell className="freeze-col-2">
                    人件費
                  </Table.ShiftCell>
                  {/* <Table.ShiftCell className="freeze-col-2" /> */}

                  {monthlyShifts?.laborCostInfo?.daily.map((item, i) => (
                    <Table.ShiftCell
                      key={i}
                      style={{ lineHeight: '18px', textAlign: 'right' }}
                    >
                      <span>
                        {`${item.laborCostAchievementShopDailyPart || 0} (${item.laborCostShiftShopDailyPart || 0})`}
                      </span>
                    </Table.ShiftCell>
                  ))}

                  <Table.ShiftCell
                    style={{ padding: '14px 5px', lineHeight: '18px' }}
                  >
                    -
                  </Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                </tr>
                {/* 人件費率	 */}
                <tr>
                  <Table.ShiftCell className="freeze-col-2">
                    人件費率
                  </Table.ShiftCell>
                  {/* <Table.ShiftCell className="freeze-col-2" /> */}

                  {monthlyShifts?.laborCostInfo?.daily.map(
                      (item: any, i: number) => (
                        <Table.ShiftCell
                          key={i}
                          style={{ lineHeight: '18px', textAlign: 'right' }}
                        >
                          <span>
                            {`${item.laborCostRateAchievementShopDailyPart || "0.00%"} (${item.laborCostRateShiftShopDailyPart || "0.00%"})`}
                          </span>
                        </Table.ShiftCell>
                      )
                    )}

                  <Table.ShiftCell
                    style={{ padding: '14px 5px', lineHeight: '18px' }}
                  >
                    -
                  </Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                </tr>
              </tbody>

              <tbody>
                {/* 時間数合計 (h) */}
                <tr>
                  <Table.ShiftCell className="freeze-col freeze-col__row" rowSpan={3} style={{borderTop: 'none'}} />
                  <Table.ShiftCell className="freeze-col freeze-col__row freeze-col__row--expand" rowSpan={3}>
                    合計
                  </Table.ShiftCell>
                  <Table.ShiftCell
                    className="freeze-col-2"
                    style={{ lineHeight: '18px' }}
                  >
                    時間数 (h)
                  </Table.ShiftCell>
                  <Table.ShiftCell
                    rowSpan={3}
                    style={{ lineHeight: '18px' }}
                  >
                    実績<br/>(予定)
                  </Table.ShiftCell>

                  {monthlyShifts?.workTimeInfo?.daily.map((item, i) => (
                    <Table.ShiftCell
                      key={i}
                      style={{ lineHeight: '18px', textAlign: 'right' }}
                    >
                      <span>{`${item.workTimeAchievementShopDaily || "0:00"} (${item.workTimeShiftShopDaily || "0:00"})`}</span>
                    </Table.ShiftCell>
                  ))}

                  <Table.ShiftCell
                    style={{
                      padding: '14px 5px',
                      lineHeight: '18px',
                      textAlign: 'right',
                    }}
                  >
                    { monthlyShifts.workTimeInfo && (
                      <Fragment>
                        <span>
                          {`${monthlyShifts?.workTimeInfo?.workTimeAchievementShopMonthly || "0:00"} (${monthlyShifts.workTimeInfo?.workTimeShiftShopMonthly || "0:00"})`}
                        </span>
                      </Fragment>
                    )}
                  </Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                </tr>
                {/* 人件費合計	 */}
                <tr>
                  <Table.ShiftCell className="freeze-col-2">
                    人件費
                  </Table.ShiftCell>
                  {/* <Table.ShiftCell className="freeze-col-2" /> */}

                  {monthlyShifts?.laborCostInfo?.daily.map((item, i) => (
                    <Table.ShiftCell
                      key={i}
                      style={{ lineHeight: '18px', textAlign: 'right' }}
                    >
                      <span>
                        {`${item.laborCostAchievementShopDaily || 0} (${item.laborCostShiftShopDaily || 0})`}
                    </span>
                    </Table.ShiftCell>
                  ))}

                  <Table.ShiftCell
                    style={{ padding: '14px 5px', lineHeight: '18px' }}
                  >
                    -
                  </Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                </tr>
                {/* 人件費率	 */}
                <tr>
                  <Table.ShiftCell className="freeze-col-2">
                    人件費率
                  </Table.ShiftCell>
                  {/* <Table.ShiftCell className="freeze-col-2" /> */}

                  {monthlyShifts?.laborCostInfo?.daily.map(
                      (item: any, i: number) => (
                        <Table.ShiftCell
                          key={i}
                          style={{ lineHeight: '18px', textAlign: 'right' }}
                        >
                          <span>
                            {`${item.laborCostRateAchievementShopDaily || "0.00%"} (${item.laborCostRateShiftShopDaily || "0.00%"})`}
                        </span>
                        </Table.ShiftCell>
                      )
                    )}

                  <Table.ShiftCell
                    style={{ padding: '14px 5px', lineHeight: '18px' }}
                  >
                    -
                  </Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                  <Table.ShiftCell>-</Table.ShiftCell>
                </tr>
              </tbody>
            </AccordionTableWrapper>

            <tbody>
              {/* 前年 */}
              <tr>
                <Table.ShiftCell className="freeze-col freeze-col__row" rowSpan={4} colSpan={3}>
                  売上
                </Table.ShiftCell>
                <Table.ShiftCell className="freeze-col-2">前年</Table.ShiftCell>
                {monthlyShifts?.salesInfo?.salesPrevAchievementDailyList.map(
                    (netSales: string, i: number) => (
                      <Table.ShiftCell
                        key={i}
                        style={{ lineHeight: '18px', textAlign: 'right' }}
                      >
                        {netSales || 0}
                      </Table.ShiftCell>
                    ),
                  )}

                <Table.ShiftCell
                  style={{ padding: '14px 5px', lineHeight: '18px' }}
                >
                  -
                </Table.ShiftCell>
                <Table.ShiftCell>-</Table.ShiftCell>
                <Table.ShiftCell>-</Table.ShiftCell>
              </tr>
              {/* 予算  */}
              <tr>
                <Table.ShiftCell className="freeze-col-2">
                  予算
                  {' '}
                </Table.ShiftCell>

                {monthlyShifts?.salesInfo?.salesBudgetDailyList.map((budgetSales: string, i: number) => (
                    <Table.ShiftCell
                      key={i}
                      style={{ lineHeight: '18px', textAlign: 'right' }}
                    >
                      {budgetSales || 0}
                    </Table.ShiftCell>
                  ))}

                <Table.ShiftCell
                  style={{ padding: '14px 5px', lineHeight: '18px' }}
                >
                  -
                </Table.ShiftCell>
                <Table.ShiftCell>-</Table.ShiftCell>
                <Table.ShiftCell>-</Table.ShiftCell>
              </tr>
              {/* 実績	 */}
              <tr>
                <Table.ShiftCell className="freeze-col-2">実績</Table.ShiftCell>
                {monthlyShifts?.salesInfo?.salesAchievementDailyList.map(
                    (netSales: string, i: number) => (
                      <Table.ShiftCell
                        key={i}
                        style={{ lineHeight: '18px', textAlign: 'right' }}
                      >
                        {netSales || 0}
                      </Table.ShiftCell>
                    ),
                  )}

                <Table.ShiftCell
                  style={{ padding: '14px 5px', lineHeight: '18px' }}
                >
                  -
                </Table.ShiftCell>
                <Table.ShiftCell>-</Table.ShiftCell>
                <Table.ShiftCell>-</Table.ShiftCell>
              </tr>
              {/* 予実比 */}
              <tr>
                <Table.ShiftCell className="freeze-col-2">
                  予実比
                </Table.ShiftCell>
                {monthlyShifts?.salesInfo?.salesBudgetRateShopDailyList.map(
                    (rate: string, i: number) => (
                      <Table.ShiftCell
                        key={i}
                        style={{ lineHeight: '18px', textAlign: 'right' }}
                      >
                        {rate || '0.00%'}
                      </Table.ShiftCell>
                    ),
                  )}

                <Table.ShiftCell
                  style={{ padding: '14px 5px', lineHeight: '18px' }}
                >
                  -
                </Table.ShiftCell>
                <Table.ShiftCell>-</Table.ShiftCell>
                <Table.ShiftCell>-</Table.ShiftCell>
              </tr>
            </tbody>

            {/* ./SUM BOTTOM */}
          </Table>
        </div>
      </BlockUI>
    </React.Fragment>
  );
};

export default TableMonthlyShift;
