/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';
import { getList } from 'api/executive';
import ExecutiveDomain from 'domain/master/labor/executive';

export const useExecutiveList = () => {
  const [executiveList, setExecutiveList] = useState<Array<ExecutiveDomain>>([]);
  useEffect(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getList(companyCode).then((response: Array<any>) => {
      setExecutiveList(response.map((result) => new ExecutiveDomain(result)));
    });
  }, []);
  return { executiveList, setExecutiveList };
};


export default useExecutiveList;
