import { css } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormContents from 'components/atoms/Form/FormContents';
import FormLabel from 'components/atoms/Form/FormLabel';
import BlockUI from 'components/molecules/BlockUi';
import DatePicker from 'components/molecules/DatePicker';
import IconLabelButton from 'components/molecules/IconLabelButton';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import React from 'react';
import moment from 'moment';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import useMonthlyDailyReport from './hooks';
import MonthlyDailyTable from './MonthlyDailyTable';
import { Values } from 'utility/constants';
import { getOrgNameFromOrgOptionLabel } from 'utility/formatUtil';

const MonthlyDailyStoreLayout = () => {
  const {
    setCategoryAry,
    blocking,
    setBlocking,
    targetDate,
    setTargetDate,
    staffCode,
    orgCode,
    setorgCode,
    orgName,
    functionType,
    monthlyDailyDetailList,
    monthlyDailyTotal,
    roleScreen,
    setOrgTreesOptions,
    taxExclude,
    setTaxExclude,
    toggle,
    setToggle,
    categoryOption,
    setCategoryOption,
  } = useMonthlyDailyReport();

  const targetPeriodData = `対象期間：${moment(targetDate).format('YYYY/MM')}`;
  const targetStoresData = `対象店舗：${getOrgNameFromOrgOptionLabel(orgName)}`;

  return (
    <>
      <BlockUI blocking={blocking}>
        <FormContents>
          <FlexBox>
            <FlexBoxItem width="100px">
              <FormLabel
                label="対象期間"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <DatePicker
                dateFormat="yyyy年MM月"
                date={targetDate}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDate(date);
                  }
                }}
                isFullWidth={false}
                showMonthYearPicker={true}
              />
            </FlexBoxItem>
          </FlexBox>

          <OrganizationFilteredTree
            functionType={functionType}
            staffCode={staffCode}
            orgLabel="店舗名"
            addAllItem={true}
            initOrgValue={String(orgCode)}
            orgCallback={(args: string | Array<string>) => {
              setorgCode(String(args));
            }}
            orgCategoryCallback={(args: Array<string>) => {
              setCategoryAry(args);
            }}
            initCatagory={(args: Array<string>) => {
              setCategoryOption(args);
            }}
            orgOrgOptionListCallback={setOrgTreesOptions}
            targetdayForm={new Date(moment(targetDate).startOf('month').format('YYYY/MM/DD'))}
            targetdayTo={new Date(moment(targetDate).endOf('month').format('YYYY/MM/DD'))}
            styles={{
              label: {
                toggleSwitch: css({ marginLeft: '0px' }),
              },
              toggle: {
                width: '19rem',
                marginLeft: '0.4rem',
              },
            }}
            selectCheck={setToggle}
            defaultToggleValue={toggle}
            defaultCategoryValues={categoryOption}
          />

          <FlexBoxItem customStyle={css({ marginTop: toggle ? '10px' : '-20px', marginBottom: '8px' })}>
            <RadioSelectForm
              name="taxExclude"
              label="集計対象"
              labelInline={true}
              labelInlineWidth={100}
              items={Object.values(Values.TaxExclude)}
              value={String(taxExclude)}
              setValue={(e) => {
                setTaxExclude(Number(e.target.value));
              }}
            />
          </FlexBoxItem>

          <MonthlyDailyTable
            setBlocking={setBlocking}
            monthlyDailyDetailList={monthlyDailyDetailList}
            monthlyDailyTotal={monthlyDailyTotal}
            taxExclude={String(taxExclude)}
            targetPeriodData={targetPeriodData}
            targetStoresData={targetStoresData}
            roleScreen={roleScreen}
          />

        </FormContents>
      </BlockUI>
    </>
  );
};

export default MonthlyDailyStoreLayout;
