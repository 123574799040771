/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useCallback } from 'react';
import useToastNotification from 'hooks/useToastNotification';
import { Error } from '../../type';
import { importFileExcel } from '../../api';

export const useMonthlyShiftImport = () => {
  const [uploadFile, setUploadFile] = useState<File>();
  const [errors, setErrors] = useState<Array<string>>();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [inputValue, setValueInput] = useState('');
  const { successNotification, errorNotification } = useToastNotification();
  const [blocking, setBlocking] = useState(false);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const extensionFile = (name: string) => name.split('.').pop();

  if (uploadFile) {
    const fileType = extensionFile(uploadFile.name);
    if (fileType !== 'csv') {
      errorNotification('Excelファイルを選択してください。');
      setUploadFile(undefined);
      setValueInput('');
    }
  }

  const onSubmit = useCallback(() => {
    if (!uploadFile) {
      return;
    }
    setErrors(undefined);
    setBlocking(true);
    setConfirmModalOpen(false);
    importFileExcel(uploadFile)
      .then((response: any) => {
        setBlocking(false);
        if (response.status !== 200) {
          console.log(response, response.data, response.data.errors);
          setErrors((response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        } else {
          successNotification('インポートが完了しました。');
          setUploadFile(undefined);
          setValueInput('');
        }
      }).catch((error: any) => {
        setBlocking(false);
        console.log(error.response, '???');
        setErrors((error.response.data.errors as Array<Error> || []).map((err) => err.defaultMessage));
        if (error.response && error.response.data && error.response.data.errors) {
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  }, [errorNotification, successNotification, uploadFile]);

  const handleSubmit = useCallback(() => {
    setConfirmModalOpen(true);
  }, []);

  return {
    uploadFile,
    setUploadFile,
    onSubmit,
    errors,
    confirmModalOpen,
    closeConfirmModal,
    handleSubmit,
    inputValue,
    blocking,
  };
};

export default useMonthlyShiftImport;
