/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/** @jsx jsx */
import React, {
  useEffect, useReducer, useCallback, useState,
} from 'react';
import { css, jsx } from '@emotion/core';
import ShiftSidebarTemplate from 'components/templates/ShiftSidebarTemplate';
import BlockUI from 'components/molecules/BlockUi';
import FormContents from 'components/atoms/Form/FormContents';
import Modal from 'components/molecules/Modal';
import SelectForm from 'components/molecules/SelectForm';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import VerticalLabelTextLayout from 'components/atoms/Form/VerticalLabelTextLayout';
import VerticalLabelFormLayoutV2 from 'components/atoms/Form/VerticalLabelFormLayoutV2';
import VerticalLabelSelectFormV2 from 'components/molecules/VerticalLabelSelectFormV2';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import DatePickerForm from 'components/molecules/DatePickerForm';
import BodyText from 'components/atoms/BodyText';
import { getShift, Shift, postShiftV1 } from 'api/shift';
import { useBusinessAttendChoiceOptions } from 'hooks/useBusinessMasterOptions';
import { textColor } from 'components/styles';
import useToastNotification from 'hooks/useToastNotification';
import { OptionType } from 'components/atoms/Select';
import moment from 'moment';
import ModalForm from 'components/molecules/ModalForm';
import { useHistory } from 'react-router-dom';
import { getDailyAddStaffs, getShiftPatterns } from '../api';
import ShiftPatternDetailList from './Modals/ShiftPatternDetailList';
import ModalRegisterShift from './Modals/ModalRegisterShift';
import Filters from './Filters';
import ModalRegisterOtherStaffShift from './Modals/ModalRegisterOtherStaffShift';
import TableMonthlyShift from './TableMonthlyShift';
import { monthlyShiftContext, useInitialState } from './contextHook';
import { convertToNextDay } from 'utility/dateUtil';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const MonthlyShift: React.FC = () => {
  const functionType = 2;
  const contextInitialState = useInitialState();
  const {
    remote,
    isLoading,
    openConfirmModal,
    setOpenConfirmModal,
    openDeleteModal,
    setOpenDeleteModal,
    openRegisterModal,
    setOpenRegisterModal,
    orgCodeModal,
    setOrgCodeModal,
    setLoading,
    filters: {
      dateToStr, dateFromStr, selectedOrg, viewPeriod, selectedOrgName,
    },
    monthlyCalendar: {
      deleteItem,
      selectedItem,
      shiftConfirm,
      submitShiftLockout,
      openDailyShiftConfirmModal,
      setDailyShiftConfirmModal,
      selectedDay,
      submitDayShiftLockout,
      setSelectedItem,
      reFetchMonthlyShifts,
    },
    shiftPattern: {
      openShiftPatternModal, openShiftPatternStaffModal, setShiftPatternStaffModal, shiftPatternOptions, getWorkdayOptions, setShiftPatternOptions,
    },
  } = contextInitialState;

  const { errorNotification } = useToastNotification();

  const memorizedReducer = useCallback((state: Shift, { type, payload }: any): Shift => {
    const shiftDetailList = [...state.shiftDetailList];
    let { targetDate } = state;
    let { staffCode } = state;
    let staffName = state.staffCode;
    let shiftPatternList = state.shiftPatternOptions;
    const nextDay = 1;
    let { orgCode } = state;
    let { orgName } = state;
    switch (type) {
      case 'SET_STATE':
        return payload;

      case 'ORG_CODE':
        orgCode = payload;
        return { ...state, orgCode };

      case 'ORG_NAME':
        orgName = payload;
        return { ...state, orgName };

      case 'SHIFT_PATTERN_LIST':
        shiftPatternList = payload;
        return { ...state, shiftPatternOptions: shiftPatternList };

      case 'START_HOUR_ONCHANGE':
        shiftDetailList[payload.index].startTime = payload.startTime;
        console.log('START_HOUR_ONCHANGE', { ...state, startTime: shiftDetailList[0].startTime, shiftDetailList });
        return { ...state, startTime: shiftDetailList[0].startTime, shiftDetailList };

      case 'END_HOUR_ONCHANGE':
        if (shiftDetailList[payload.index + 1]) {
          shiftDetailList[payload.index + 1].startTime = payload.endTime;
        }
        shiftDetailList[payload.index].endTime = payload.endTime;
        console.log('END_HOUR_ONCHANGE', { ...state, endTime: shiftDetailList[shiftDetailList.length - 1].endTime, shiftDetailList });
        return { ...state, endTime: shiftDetailList[shiftDetailList.length - 1].endTime, shiftDetailList };

      case 'START_NEXTDAY_ONCHANGE':
        shiftDetailList[payload.index].isStartTimeNextDay = payload.isStartTimeNextDay;

        shiftDetailList[payload.index].startTime = payload.isStartTimeNextDay
          ? moment(shiftDetailList[payload.index].startTime)
            .add('day', 1)
            .format('YYYY/MM/DD HH:mm:ss')
          : moment(shiftDetailList[payload.index].startTime)
            .subtract('day', 1)
            .format('YYYY/MM/DD HH:mm:ss');

        return { ...state, shiftDetailList };

      case 'END_NEXTDAY_ONCHANGE':
        if (shiftDetailList[payload.index + 1]) {
          shiftDetailList[payload.index + 1].isStartTimeNextDay = payload.isEndTimeNextDay;
          shiftDetailList[payload.index + 1].startTime =  convertToNextDay(state.targetDate, payload.isEndTimeNextDay, state.shiftDetailList[payload.index].endTime);

        }
        shiftDetailList[payload.index].isEndTimeNextDay = payload.isEndTimeNextDay;
        shiftDetailList[payload.index].endTime = convertToNextDay(state.targetDate, payload.isEndTimeNextDay, state.shiftDetailList[payload.index].endTime);

        return { ...state, shiftDetailList };

      case 'IS_BREAK_ONCHANGE':
        shiftDetailList[payload.index].businessId = payload.businessId;
        return { ...state, shiftDetailList };

      case 'IS_REMOTE_ONCHANGE':
        shiftDetailList[payload.index].isRemote = payload.isRemote;
        return { ...state, shiftDetailList };

      case 'WORKDAY_TYPE_ONCHANGE':
        switch (payload) {
          case 'legal':
            defaultShiftDetailList[0].isEndTimeNextDay = false;
            defaultShiftDetailList[0].startTime = moment(state.targetDate)
              .add('hour', 0)
              .format('YYYY/MM/DD HH:mm:ss');
            defaultShiftDetailList[0].endTime = moment(state.targetDate)
              .add({ day: 1, hour: 0 })
              .format('YYYY/MM/DD HH:mm:ss');
            return {
              ...state,
              shiftPatternId: '',
              holidayId: 'legal',
              isLegal: true,
              attendType: 1,
              shiftDetailList: defaultShiftDetailList,
            };

          case 'scheduled':
            defaultShiftDetailList[0].isEndTimeNextDay = true;
            defaultShiftDetailList[0].startTime = moment(state.targetDate)
              .add('hour', 0)
              .format('YYYY/MM/DD HH:mm:ss');
            defaultShiftDetailList[0].endTime = moment(state.targetDate)
              .add({ day: 1, hour: 0 })
              .format('YYYY/MM/DD HH:mm:ss');
            return {
              ...state,
              shiftPatternId: '',
              holidayId: 'scheduled',
              isLegal: false,
              attendType: 1,
              shiftDetailList: defaultShiftDetailList,
            };

          default:
            return {
              ...state, shiftPatternId: '', holidayId: '', shiftDetailList: defaultShiftDetailList,
            };
        }

      case 'SHIFTPATTERN_ONCHANGE':
        const shiftPattern = state.shiftPatternOptions && state.shiftPatternOptions.length > 0 && state.shiftPatternOptions.find((pattern: any) => pattern.shiftPatternId === payload);
        console.log('shiftPattern', shiftPattern, payload, state.shiftPatternOptions);
        let newShiftDetailList;
        if (shiftPattern) {
          newShiftDetailList = shiftPattern.attendShiftPatternDetails.map((patternDetail: any) => {
            console.log('patternDetail', patternDetail);
            return {
              startTime: moment(`${
                patternDetail.isStartTimeNextDay
                  ? moment(state.targetDate).add('day', nextDay).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
                  : moment(state.targetDate).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
              } ${patternDetail.startTime}`).format('YYYY/MM/DD HH:mm:ss'),
              isStartTimeNextDay: patternDetail.isStartTimeNextDay,
              endTime: moment(`${
                patternDetail.isEndTimeNextDay
                  ? moment(state.targetDate).add('day', nextDay).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
                  : moment(state.targetDate).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
              } ${patternDetail.endTime}`).format('YYYY/MM/DD HH:mm:ss'),
              isEndTimeNextDay: patternDetail.isEndTimeNextDay,
              businessId: patternDetail.businessId || '',
              createUser: patternDetail.createUser,
              updateUser: patternDetail.updateUser,
              isRemote: patternDetail.isRemote,
            };
          });
        } else {
          defaultShiftDetailList[0].isEndTimeNextDay = false;
          defaultShiftDetailList[0].isStartTimeNextDay = false;
          defaultShiftDetailList[0].startTime = moment(state.targetDate)
            .add('hour', 0)
            .format('YYYY/MM/DD HH:mm:ss');
          defaultShiftDetailList[0].endTime = moment(state.targetDate)
            .add('hour', 0)
            .format('YYYY/MM/DD HH:mm:ss');
          newShiftDetailList = defaultShiftDetailList;
        }
        return { ...state, shiftPatternId: payload, shiftDetailList: newShiftDetailList };

      case 'ADD_SHIFTDETAILLIST':
        const newDefaultShiftDetail = { ...defaultShiftDetailList[0] };
        newDefaultShiftDetail.businessId = '';
        console.log('ADD_SHIFTDETAILLIST', newDefaultShiftDetail);
        newDefaultShiftDetail.startTime = state.shiftDetailList[state.shiftDetailList.length - 1].endTime;
        newDefaultShiftDetail.isStartTimeNextDay = state.shiftDetailList[state.shiftDetailList.length - 1].isEndTimeNextDay;
        newDefaultShiftDetail.isEndTimeNextDay = false;
        newDefaultShiftDetail.endTime = moment(state.targetDate).format("YYYY/MM/DD HH:mm:ss")
        return { ...state, shiftDetailList: [...state.shiftDetailList, newDefaultShiftDetail] };

      case 'DELETE_SHIFTDETAILLIST':
        shiftDetailList.splice(payload, 1);
        return { ...state, shiftDetailList };

      case 'TARGET_DATE':
        targetDate = moment(payload).format('YYYY/MM/DD HH:mm:ss');
        const changeDateShiftDetailList = shiftDetailList.map((detail, index) => ({
          startTime: moment(`${
            detail.isStartTimeNextDay
              ? moment(payload).add('day', nextDay).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
              : moment(payload).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
          } ${detail.startTime.split(' ')[1]}`).format('YYYY/MM/DD HH:mm:ss'),
          isStartTimeNextDay: detail.isStartTimeNextDay,
          endTime: moment(`${
            detail.isEndTimeNextDay
              ? moment(payload).add('day', nextDay).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
              : moment(payload).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
          } ${detail.endTime.split(' ')[1]}`).format('YYYY/MM/DD HH:mm:ss'),
          isEndTimeNextDay: detail.isEndTimeNextDay,
          businessId: detail.businessId || '',
          createUser: detail.createUser,
          updateUser: detail.updateUser,
          isRemote: detail.isRemote,
        }));
        return { ...state, targetDate, shiftDetailList: changeDateShiftDetailList };

      case 'STAFF_CODE':
        staffCode = payload;
        staffName = payload;
        return { ...state, staffCode, staffName };

      default:
        return state;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [staffCode, setStaffCode] = useState<string>('');
  const [staffName, setStaffName] = useState<string>('');
  const [staffEmploymentId, setStaffEmploymentId] = useState<string>('');

  const defaultShiftDetailList = [
    {
      startTime: moment(dateFromStr).set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      }).format('YYYY/MM/DD HH:mm:ss'),
      isStartTimeNextDay: false,
      endTime: moment(dateFromStr).set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      }).format('YYYY/MM/DD HH:mm:ss'),
      isEndTimeNextDay: false,
      businessId: '',
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      shiftDetailId: '',
      isRemote: true,
    },
  ];
  const defaultShift = {
    companyCode: '',
    createUser: sessionStorage.getItem('loginUser.staffName') || '',
    updateUser: sessionStorage.getItem('loginUser.staffName') || '',
    createDate: '',
    updateDate: '',
    shiftId: '',
    orgCode: selectedOrg,
    orgName: selectedOrgName,
    belongOrgCode: '',
    staffCode,
    staffName,
    targetDate: moment(dateFromStr).set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    }).format('YYYY/MM/DD HH:mm:ss'),
    isLegal: false,
    notifiedToStampForgot: false,
    startTime: moment(dateFromStr).set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    }).format('YYYY/MM/DD HH:mm:ss'),
    endTime: moment(dateFromStr).set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    }).format('YYYY/MM/DD HH:mm:ss'),
    shiftDetailList: defaultShiftDetailList,
    procType: 0,
    attendType: 0,
    closingHour: 0,
    distinctionHoliday: false,
    shopPlanId: '',
    shopPlan: '',
    shopPlanOrgCode: '',
    shopPlanTargetDate: '',
    shiftPatternOptions,
  };

  const [state, dispatch] = useReducer(memorizedReducer, defaultShift);

  console.log(state, 'state');
  const [shiftMonthlyStaffOptions, setShiftMonthlyStaffOptions] = useState<
    Array<OptionType>
  >([]);

  useEffect(() => {
    if (openRegisterModal && orgCodeModal !== '') {
      getDailyAddStaffs(
        selectedOrg,
        orgCodeModal,
        moment(dateFromStr).format('YYYY-MM-DD'),
      ).then((monthlyAddStaffs: any) => {
        const monthlyAddStaffData = monthlyAddStaffs.map((staff: any) => ({
          value: staff.staffCode,
          label: staff.staffName,
          employmentId: staff.employmentId,
        }));
        setShiftMonthlyStaffOptions(monthlyAddStaffData);
        setStaffCode(
          monthlyAddStaffData !== null && monthlyAddStaffData.length > 0
            ? monthlyAddStaffData[0].value
            : '',
        );
        setStaffName(
          monthlyAddStaffData !== null && monthlyAddStaffData.length > 0
            ? monthlyAddStaffData[0].label
            : '',
        );
        setStaffEmploymentId(
          monthlyAddStaffData !== null && monthlyAddStaffData.length > 0
            ? monthlyAddStaffData[0].employmentId
            : '',
        );
      }).catch((error: any) => {
        setStaffCode('');
        setStaffName('');
        setStaffEmploymentId('');
      });
    }
  }, [dateFromStr, orgCodeModal, state.targetDate, openRegisterModal, selectedOrg]);

  useEffect(() => {
    dispatch({ type: 'STAFF_CODE', payload: staffCode });
    dispatch({ type: 'SHIFTPATTERN_ONCHANGE', payload: '' });
    const staffInfo = shiftMonthlyStaffOptions.filter((staff) => staff.value === staffCode);
    console.log('staffInfo', staffInfo);
    setStaffName(
      staffInfo !== null && staffInfo.length > 0
        ? staffInfo[0].label
        : '',
    );
    setStaffEmploymentId(
      staffInfo !== null && staffInfo.length > 0
        ? staffInfo[0].employmentId
        : '',
    );
  }, [shiftMonthlyStaffOptions, staffCode]);

  const businessAttendChoiceOptions = useBusinessAttendChoiceOptions(
    staffCode,
    moment(state.targetDate).format('YYYY/MM/DD'),
  );

  const resetShift = () => {
    dispatch({ type: 'SET_STATE', payload: defaultShift });
  };

  const onSubmit = async () => {
    const newState = formatToSubmitObject(state);
    if (newState.staffCode === '') {
      errorNotification('スタッフをお選びください');
      return false;
    }
    if (newState.orgCode === orgCodeModal) {
      errorNotification('現在の店舗ではスタッフを選べません');
      return false;
    }
    try {
      setLoading(true);
      await postShiftV1(newState);
      await reFetchMonthlyShifts();
      // reset
      setShiftPatternStaffModal(false);
      setOpenRegisterModal(false);
      resetShift();
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const formatToSubmitObject = (state: Shift) => ({
    viewPeriod,
    shopPlanId: '',
    shopPlan: '',
    shopPlanOrgCode: '',
    shopPlanTargetDate: '',
    targetDate: state.targetDate,
    staffCode: state.staffCode,
    staffName: state.staffName,
    attendType: state.attendType,
    orgCode: state.orgCode,
    orgName: state.orgName,
    shiftPatternId: state.shiftPatternId || '',
    shiftDetailList: state.shiftDetailList.map((detail) => ({
      startHourForm: moment(detail.startTime).format('HH'),
      startTimeForm: moment(detail.startTime).format('mm'),
      startTime: detail.startTime,
      isStartTimeNextDay: detail.isStartTimeNextDay,
      endHourForm: moment(detail.endTime).format('HH'),
      endTimeForm: moment(detail.endTime).format('mm'),
      endTime: detail.endTime,
      isEndTimeNextDay: detail.isEndTimeNextDay,
      businessId: detail.businessId || '',
      shiftId: state.shiftId,
      shiftDetailId: detail.shiftDetailId || '',
      createUser: detail.createUser,
      updateUser: detail.updateUser,
      isRemote: remote ? detail.isRemote : false,
    })),
    shiftId: state.shiftId,
    startTime: state.shiftDetailList[0].startTime,
    endTime: state.shiftDetailList[state.shiftDetailList.length - 1].endTime,
    procType: state.procType,
    createUser: state.createUser,
    updateUser: state.updateUser,
    requestNote: state.requestNote !== undefined ? state.requestNote : '',
    deadline: state.deadline !== undefined ? state.deadline : moment(new Date()).format('YYYY/MM/DD'),
  });

  const formatShiftDate = (shift: Shift) => ({
    companyCode: shift.companyCode,
    createUser: shift.createUser,
    updateUser: shift.updateUser,
    createDate: shift.createDate,
    updateDate: shift.updateDate,
    shiftId: shift.shiftId,
    orgCode: shift.orgCode,
    orgName: shift.orgName,
    belongOrgCode: shift.belongOrgCode,
    staffCode: shift.staffCode,
    staffName: shift.staffName,
    targetDate: moment(shift.targetDate).format('YYYY/MM/DD HH:mm:ss'),
    isLegal: shift.isLegal,
    notifiedToStampForgot: shift.notifiedToStampForgot,
    startTime: moment(shift.startTime).format('YYYY/MM/DD HH:mm:ss'),
    endTime: moment(shift.endTime).format('YYYY/MM/DD HH:mm:ss'),
    shiftDetailList: shift.holidayId
      ? [{
        startTime: moment(shift.startTime)
          .hour(0)
          .format('YYYY/MM/DD HH:mm:ss'),
        isStartTimeNextDay: false,
        endTime: moment(shift.endTime).hour(0).format('YYYY/MM/DD HH:mm:ss'),
        isEndTimeNextDay: true,
        businessId: '',
        createUser: shift.createUser,
        updateUser: shift.createUser,
        shiftDetailId: '',
      }]
      : shift.shiftDetailList.map((detail) => ({
        startTime: moment(detail.startTime).format('YYYY/MM/DD HH:mm:ss'),
        isStartTimeNextDay: detail.isStartTimeNextDay,
        endTime: moment(detail.endTime).format('YYYY/MM/DD HH:mm:ss'),
        isEndTimeNextDay: detail.isEndTimeNextDay,
        businessId: detail.businessId,
        createUser: detail.createUser,
        updateUser: detail.updateUser,
        shiftDetailId: detail.shiftDetailId || '',
        isRemote: !!detail.isRemote,
      })),
    procType: shift.procType,
    attendType: shift.attendType,
    closingHour: shift.closingHour,
    distinctionHoliday: shift.distinctionHoliday,
    shiftPatternId: shift.shiftPatternId,
    requestNote: shift.requestNote || '',
    holidayId: shift.holidayId || '',
    notifiedBeforeShiftStart: shift.notifiedBeforeShiftStart || '',
  });

  // const fetchShift = async () => {
  //   setLoading(true);
  //   let shiftData = await getShift(selectedItem.id);
  //   shiftData = formatShiftDate(shiftData);
  //   dispatch({ type: "SET_STATE", payload: shiftData });
  //   setLoading(false);
  // };

  useEffect(() => {
    const fetchShift = async () => {
      setLoading(true);

      let shiftData = await getShift(selectedItem.id);
      shiftData = formatShiftDate(shiftData);
      dispatch({ type: 'SET_STATE', payload: shiftData });

      setLoading(false);
    };

    if (selectedItem) {
      fetchShift();
    }

    return () => {
      setSelectedItem(null);
      resetShift();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (staffEmploymentId !== '') {
      getShiftPatterns(staffEmploymentId, selectedOrg).then((shiftPattern: any) => {
        dispatch({ type: 'SHIFT_PATTERN_LIST', payload: shiftPattern });
        setShiftPatternOptions(shiftPattern);
      }).catch((error: any) => {
        dispatch({ type: 'SHIFT_PATTERN_LIST', payload: [] });
        dispatch({ type: 'SHIFTPATTERN_ONCHANGE', payload: '' });
        setShiftPatternOptions([]);
      });
    } else {
      dispatch({ type: 'SHIFT_PATTERN_LIST', payload: [] });
      dispatch({ type: 'SHIFTPATTERN_ONCHANGE', payload: '' });
      setShiftPatternOptions([]);
    }
  }, [selectedOrg, setShiftPatternOptions, staffEmploymentId]);

  const history = useHistory();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === selectedOrg);

  const checkUserRole = selectedOrg !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  return (
    <ShiftSidebarTemplate pageTitle="勤務スケジュール">
      <BlockUI blocking={isLoading}>
        <monthlyShiftContext.Provider value={contextInitialState}>
          {dateToStr && (
            <FormContents>
              <Filters dispatch={dispatch} />

              <TableMonthlyShift role={checkUserRole} />
            </FormContents>
          )}

          {/* Confirm shift modal */}
          <Modal
            open={openConfirmModal}
            closeHandler={() => {
              setOpenConfirmModal(false);
            }}
            title="シフトの確定"
            note={shiftConfirm.isConfirmedAllDays ? 'シフト確定を解除します。よろしいですか？' : 'シフトを確定します。よろしいですか？'}
            submitText={shiftConfirm.isConfirmedAllDays ? '解除する' : '確定する'}
            onSubmit={submitShiftLockout}
            closeText="キャンセル"
          />

          {/* Confirm dailyShift modal */}
          <Modal
            open={openDailyShiftConfirmModal}
            closeHandler={() => {
              setDailyShiftConfirmModal(false);
            }}
            title="シフトの確定"
            note={`${moment(selectedDay).format('M月D日')}のシフトを確定します。よろしいですか？`}
            submitText="確定する"
            onSubmit={submitDayShiftLockout}
            closeText="キャンセル"
          />

          {/* Confirm delete modal */}
          <Modal
            open={openDeleteModal}
            closeHandler={() => {
              setOpenDeleteModal(false);
            }}
            title="シフトの削除"
            note="シフトを削除します。よろしいですか？"
            submitText="削除する"
            onSubmit={() => {
              deleteItem(selectedItem.id);
              setOpenDeleteModal(false);
            }}
            closeText="キャンセル"
          />

          <ModalForm
            open={openRegisterModal}
            closeHandler={() => {
              setOpenRegisterModal(false);
            }}
            title="支援スタッフの追加"
            submitText="追 加"
            closeText="閉じる"
            onSubmit={onSubmit}
            width="60%"
            customStyleBody={css({ minHeight: '300px', padding: '30px 20px' })}
          >

            <BodyText>期間</BodyText>
            <DatePickerForm
              label=""
              date={moment(state.targetDate).toDate()}
              dateFormat="yyyy年MM月dd日"
              changeDate={(type: Date) => {
                if (type !== null) {
                  dispatch({
                    type: 'TARGET_DATE',
                    payload: type,
                  });
                }
              }}
              isFullWidth={true}
            />

            <OrganizationFilteredTree
              functionType={functionType}
              staffCode=""
              orgLabel="店舗"
              targetdayForm={new Date(dateFromStr)}
              targetdayTo={new Date(dateToStr)}
              initOrgValue={orgCodeModal}
              orgCallback={(args:string|Array<string>) => {
                setOrgCodeModal(String(args));
              }}
              showFilterCategory={true}
              notSetLoginStaffCode={true}
              customSelectOrg={true}
            />

            <SelectForm
              label="スタッフ"
              name="staffs"
              options={shiftMonthlyStaffOptions}
              islabelLong={true}
              value={state.staffCode}
              setValue={(type: string) => {
                dispatch({
                  type: 'STAFF_CODE',
                  payload: type,
                });
                setStaffCode(type);
              }}
            />

            <VerticalLabelFormLayoutV2
              label="勤務日種別"
              customCss={css({ color: textColor.main })}
              input={(
                <div css={css({ width: '100%', marginBottom: '20px' })}>
                  <VerticalLabelSelectFormV2
                    label=""
                    placeholder="通常"
                    name="workdayType"
                    value={state.holidayId && state.isLegal ? 'legal' : state.holidayId ? 'scheduled' : 'normal'}
                    setValue={(type: string) => {
                      dispatch({
                        type: 'WORKDAY_TYPE_ONCHANGE',
                        payload: type,
                      });
                    }}
                    options={getWorkdayOptions(state.distinctionHoliday, true)}
                    menuPlacement="top"
                  />
                </div>
              )}
              zIndex={0}
            />

            <VerticalLabelTextLayout label="出勤組織" variable={state?.orgName} />

            <VerticalLabelFormLayout
              label="シフトパターン"
              // customCss={css({ color: textColor.main })}
              input={(
                <div css={css({ width: '100%' })}>
                  <VerticalLabelSelectForm
                    label=""
                    placeholder="通常出勤"
                    name="shiftpattern"
                    value={state.shiftPatternId || ''}
                    setValue={(shiftpatternId) => {
                      dispatch({
                        type: 'SHIFTPATTERN_ONCHANGE',
                        payload: shiftpatternId,
                      });
                    }}
                    options={[{ label: '-', value: '' }].concat(
                      shiftPatternOptions.map((pattern) => ({
                        label: pattern.shiftPatternName,
                        value: pattern.shiftPatternId,
                      })),
                    )}
                    disable={!!state?.holidayId}
                    menuPlacement="top"
                  />
                </div>
              )}
            />

            <BodyText customStyle={css({ display: 'block' })}>シフト時間</BodyText>

            {
            state && (
              <ShiftPatternDetailList
                shiftDetail={state.shiftDetailList}
                isDisable={!!(state.shiftPatternId || state.holidayId)}
                businessAttendChoiceOptions={businessAttendChoiceOptions}
                dispatch={dispatch}
                remote={remote}
              />
            )
            }
          </ModalForm>
          {/* ShiftPattern */}
          {openShiftPatternModal && <ModalRegisterShift orgName={state?.orgName} />}
          {openShiftPatternStaffModal && <ModalRegisterOtherStaffShift orgName={state?.orgName} />}
        </monthlyShiftContext.Provider>
      </BlockUI>
    </ShiftSidebarTemplate>
  );
};

export default MonthlyShift;
