import { useState, useEffect } from 'react';
import { getCategoryItemList } from 'api/organizationCategory';
import OrganizationCategoryItemDomain from 'domain/master/general/organizationCategoryItem';

export const useOrganizationCategoryItemList = (organizationCategoryMstHeadId: string) => {
  const [
    organizationCategoryItemList,
    setOrganizationCategoryItemList] = useState<Array<OrganizationCategoryItemDomain>>([]);

  useEffect(() => {
    getCategoryItemList(organizationCategoryMstHeadId).then((response: Array<any>) => {
      setOrganizationCategoryItemList(
        response.map((result) => new OrganizationCategoryItemDomain(result)),
      );
    });
  }, [organizationCategoryMstHeadId]);

  return { organizationCategoryItemList, setOrganizationCategoryItemList };
};

export default useOrganizationCategoryItemList;
