import React, { SVGProps } from 'react';

const Warning: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M10.812 4.094c.247.142.453.348.596.595l5.768 9.999a1.625 1.625 0 01-1.407 2.437H4.23a1.625 1.625 0 01-1.407-2.437l5.768-9.999a1.625 1.625 0 012.22-.595zm-1.137 1.22l-5.768 9.999a.375.375 0 00.324.562H15.77a.375.375 0 00.324-.562l-5.768-10a.375.375 0 00-.65 0zM10 13.375c.345 0 .625.28.625.625v.25a.625.625 0 11-1.25 0V14c0-.345.28-.625.625-.625zm0-5.75c.345 0 .625.28.625.625v4a.625.625 0 11-1.25 0v-4c0-.345.28-.625.625-.625z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Warning;
