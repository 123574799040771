import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import TimeRecalculationForm from 'components/organismos/master/atendanceManagement/TimeRecalculationForm';

const TimeRecalculationPage = () => (
  <SidebarTemplate pageTitle="勤怠再計算">
    <TimeRecalculationForm />
  </SidebarTemplate>
);

export default TimeRecalculationPage;
