import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

// Get list Advertising
export const getListAdvertising = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/advertisingPlanMaster/list/${companyCode}`, {});
  return response.data;
};

// Get Detail Advertising
export const getAdvertising = async (advertisingPlanMstCode: string) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = { advertisingPlanMstCode };
  const response = await ApiClient.get(`/v1/sales/master/advertisingPlanMaster/${companyCode}`, params);
  return response.data;
};

// Create or Update Advertising
export const createOrUpdateAdvertising = async (
  isCreate: boolean,
  isConfirm: boolean,
  body: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isCreate: isCreate ? 0 : 1,
    isConfirmed: true,
  };
  const response = await ApiClient.postJsonData(`/v1/sales/master/advertisingPlanMaster/${companyCode}`, params, body);
  return response.data;
};

// Delete Advertising
export const deleteAdver = async (advertisingPlanMstCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    advertisingPlanMstCode,
  };
  const reponse = await ApiClient.delete(`/v1/sales/master/advertisingPlanMaster/${companyCode}`, params);
  return reponse.data;
};

// Download Advertising
export const downloadCSV = async (fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsv(`/v1/sales/master/advertisingPlanMaster/csv/${companyCode}`, {}, getDownloadCsvFileName(`${fileName}_`));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

// Import Advertising
export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v1/sales/master/advertisingPlanMaster/csv/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

// Get Advertising Plan Organization
export const getListAdvertisingPlanOrganization = async (
  orgCode: string,
  isExceptApplyEnd:any, applyStartFrom:any, applyStartTo:any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    applyStartDateFrom: applyStartFrom ? moment(applyStartFrom).format('YYYY-MM-DD') : '',
    applyStartDateTo: applyStartTo ? moment(applyStartTo).format('YYYY-MM-DD') : '',
    isExceptApplyEnd,
    loginStaffCode: staffCode,
    functionType: 1,
  };
  if (localStorage.getItem('categoryArray') === '[]') {
    const response = await ApiClient.get(`/v1/sales/master/advertisingPlanOrganization/list/${companyCode}?${'categoryAry='}`, params);
    return response.data;
  }
  const categoryAry = JSON.parse(`[${localStorage.getItem('categoryArray')}]`);

  let appenUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any) => {
      appenUrl += `&categoryAry=${cateArr}`;
    });
  }
  const response = await ApiClient.get(`/v1/sales/master/advertisingPlanOrganization/list/${companyCode}?${appenUrl}`, params);
  return response.data;
};

// Create or update adv plan setting
export const createOrUpdateAdvertisingOrganization = async (body: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.postMutipartData(`/v1/sales/master/advertisingPlanOrganization/${companyCode}`, {}, body);
  return response.data;
};
export type Error = {
  error: string;
  defaultMessage: string;
}

// Delete Advertising Plan Organization
export const deleteAdverPlan = async (formSubmit: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode: formSubmit.orgCode,
    applyStartDate: formSubmit.applyStartDate,
    advertisingPlanMstCode: formSubmit.advertisingPlanMstCode,
  };
  const reponse = await ApiClient.delete(`/v1/sales/master/advertisingPlanOrganization/${companyCode}`, params);
  return reponse.data;
};

// Download AdvertisingOrganization
export const downloadCSVOrganization = async (fileName: string, queryForm: any): Promise<void> => {
  console.log('debug', queryForm.applyStartDateFrom);
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const startDate = moment(queryForm.applyStartDateFrom).format('YYYY-MM-DD');
  const endDate = moment(queryForm.applyStartDateTo).format('YYYY-MM-DD');
  const arrayCategory = queryForm.categoryArray;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode: queryForm.orgCode,
    categoryAry: arrayCategory && arrayCategory.length > 0 ? String(arrayCategory) : '',
    applyStartDateFrom: queryForm.applyStartDateFrom !== null ? startDate : '',
    applyStartDateTo: queryForm.applyStartDateTo !== null ? endDate : '',
    isExceptApplyEnd: queryForm.checked,
    loginStaffCode: staffCode,
    functionType: 1,
  };
  await ApiClient.downloadCsv(`/v1/sales/master/advertisingPlanOrganization/csv/${companyCode}`, params, getDownloadCsvFileName(`${fileName}_`));
};

// Import AdvertisingOrganization
export const importFileCSVOrganization = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v1/sales/master/advertisingPlanOrganization/csv/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

// Update EndDate AdvertisingOrganization
export const updateEndDateAdvertising = async (FormSubmit:any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.postMutipartData(`/v1/sales/master/advertisingPlanOrganization/applyDate/bulkUpdate/${companyCode}`, {}, FormSubmit);
  return response.data;
};

// Delete AdvertisingOrganization
export const deleteAdvertising = async (FormSubmit:any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const submit = {
    advertisingPlanOrganizationList: FormSubmit.advertisingPlanOrganizationList,
  };
  const response = await ApiClient.deleteMutipartData(`/v1/sales/master/advertisingPlanOrganization/bulkDelete/${companyCode}`, {}, submit);
  return response.data;
};

export default { getListAdvertising, getListAdvertisingPlanOrganization };
