/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import useScrollbarAutoWidth from 'hooks/useScrollbarAutoWidth';
import { useDownload } from './hooks';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { isMacOs } from 'react-device-detect';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';
import { grayScale } from 'components/styles';
import useSortTable, { FieldSortState, SortColumn } from 'hooks/useSortTable';
import MonthlyBudgetPerformanceManagementReportDomain from 'domain/master/storeManagement/MonthlyBudgetPerformanceManagementReport';

const DEFAULT_SORT_FIELD = { fieldPath: 'orgCode', sort: 'asc' } as FieldSortState

const MonthlyForeCastManagemenTable: React.FC<{
  setIsLoading: any,
  targetDateFrom: any,
  targetDateTo: any,
  budgetPerfomanceManagementMonthlyReport: Array<MonthlyBudgetPerformanceManagementReportDomain>,
  targetPeriodData: any,
  orgLabel: any,
  roleScreen?: any,
}> = ({
  setIsLoading,
  targetDateFrom,
  targetDateTo,
  budgetPerfomanceManagementMonthlyReport,
  targetPeriodData,
  orgLabel,
  roleScreen,
}) => {
    let target_date_from = useMemo(() => {
      return `${moment(targetDateFrom).format('YYYY-MM-DD')}`;
    }, []);
    let target_date_to = useMemo(() => {
      return `${moment(targetDateTo).format('YYYY-MM-DD')}`;
    }, []);

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソートヘッダ項目 */
    const sortColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '店舗コード',  sortField: 'orgCode', sortType: 'string', rowSpan: 2, style: { top: '0', left: '0', zIndex: 99, maxWidth: ' 200px', width: '200px', minWidth: '200px' },     className:"text-center stickyStyle fristColSticky" },
      { displayName: '店舗名',      sortField: 'orgName', sortType: 'string', rowSpan: 2, style: { top: '0', left: '203px', zIndex: 99, maxWidth: ' 200px', width: '200px', minWidth: '200px' }, className:"text-center stickyStyle" },
    ], []);

    /** ソートしないヘッダ項目 */
    const unsortColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '売上',    sortField: '',  colSpan: 6 },
      { displayName: '客数',    sortField: '',  colSpan: 6 },
      { displayName: '仕入',    sortField: '',  colSpan: 5 },
      { displayName: '人件費',  sortField: '',  colSpan: 5 },
      { displayName: '労働時間', sortField: '', colSpan: 3 },
      { displayName: '経費',    sortField: '',  colSpan: 2 },
      { displayName: '利益',    sortField: '',  colSpan: 3 },
    ], []);

    /** サブヘッダ項目 */
    const sortSubColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '純売上', sortField: 'cumulativeSales', sortType: 'number', },
      { displayName: '売上予算', sortField: 'cumulativeSalesBudget', sortType: 'number', },
      { displayName: '予実差額', sortField: 'forecastDifference', sortType: 'number', },
      { displayName: '予実達成率', sortField: 'perGoalCumulativeSales', sortType: 'number', },
      { displayName: '前年売上', sortField: 'prevCumulativeSales', sortType: 'number', },
      { displayName: '前年対比', sortField: 'perPrevCumulativeSales', sortType: 'number', },
      { displayName: '客数', sortField: 'guestCnt', sortType: 'number', },
      { displayName: '目標客数', sortField: 'guestCntBudget', sortType: 'number', },
      { displayName: '予実達成率', sortField: 'perGoalGuestCntBudget', sortType: 'number', },
      { displayName: '客単価', sortField: 'perGuestCnt', sortType: 'number', },
      { displayName: '目標客単価', sortField: 'perGuestCntBudget', sortType: 'number', },
      { displayName: '客単価差額', sortField: 'diffPerGuestCnt', sortType: 'number', },
      { displayName: '仕入', sortField: 'cumulativePurchase', sortType: 'number', },
      { displayName: '仕入予算', sortField: 'cumulativePurchaseBudget', sortType: 'number', },
      { displayName: '原価率', sortField: 'perCumulativePurchase', sortType: 'number', },
      { displayName: '目標原価率', sortField: 'perCumulativePurchaseBudget', sortType: 'number', },
      { displayName: '予実差率', sortField: 'diffPerCumulativePurchase', sortType: 'number', },
      { displayName: '人件費', sortField: 'cumulativeLaborCost', sortType: 'number', },
      { displayName: '人件費予算', sortField: 'cumulativeLaborCostBudget', sortType: 'number', },
      { displayName: '人件費率', sortField: 'perCumulativeLaborCost', sortType: 'number', },
      { displayName: '目標人件費率', sortField: 'perCumulativeLaborCostBudget', sortType: 'number', },
      { displayName: '予実差率', sortField: 'diffPerCumulativeLaborCost', sortType: 'number', },
      { displayName: '社員', sortField: 'employeeWorkTimeDisp', sortType: 'minute', },
      { displayName: 'アルバイト', sortField: 'partWorkTimeDisp', sortType: 'minute', },
      { displayName: '合計', sortField: 'totalWorkTimeDisp', sortType: 'minute', },
      { displayName: '固定費', sortField: 'cumulativeFixCost', sortType: 'number', },
      { displayName: '変動費', sortField: 'cumulativeVariableCost', sortType: 'number', },
      { displayName: '利益', sortField: 'cumulativeProfit', sortType: 'number', },
      { displayName: '目標利益', sortField: 'cumulativeProfitBudget', sortType: 'number', },
      { displayName: '予実達成率', sortField: 'perGoalCumulativeProfit', sortType: 'number', },
    ], []);

    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(budgetPerfomanceManagementMonthlyReport, DEFAULT_SORT_FIELD)

    // ------------------------------------------------------------------
    // 出力
    // ------------------------------------------------------------------
    const {
      downloadBudgetPerfomanceManagementCSV,
      downloadBudgetPerfomanceManagementExcel,
    } = useDownload();

    /** 出力用データ */
    const outputDataList = useMemo(() => dataSort.map(m => m.getOutputData()), [dataSort]);

    const targetStoresData = `対象店舗: ${orgLabel}`;

    /** CSV出力用ヘッダ情報 */
    const headerInformation = [
      [
        ...sortColumns.map(s => ''),
        ...unsortColumns.flatMap(s => [s.displayName, ...Array((s.colSpan! - 1)).fill('')]),
      ],
      [
        ...sortColumns.map(s => s.displayName),
        ...sortSubColumns.map(s => s.displayName)
      ],
    ];

    /** Excel出力用ヘッダ情報 */
    const headerInformationExcel = [
      [
        ...sortColumns.map(s => s.displayName),
        ...unsortColumns.flatMap(s => [s.displayName, ...Array((s.colSpan! - 1)).fill('')]),
      ],
      [
        ...sortColumns.map(s => ''),
        ...sortSubColumns.map(s => s.displayName)
      ],
    ];

    /**
     * Excel出力用セル結合情報
     * NOTE: [firstRow, lastRow, firstCol, lastCol] の配列で結合するセル範囲を指定する
    */
    const mergedRegions = useMemo(() => {
      const tmpMergedRegions: any[] = [];
      // ヘッダの店舗セルを縦方向に結合
      tmpMergedRegions.push([3, 4, 0, 0]);
      // ヘッダの店舗名を縦方向に結合
      tmpMergedRegions.push([3, 4, 1, 1]);
      // ソートしないヘッダ項目をColSpan分横方向に結合
      let firstColIndex = 2;
      let lastColIndex = 0;
      unsortColumns.map((item: SortColumn) => {
        lastColIndex = firstColIndex + item.colSpan! - 1;
        tmpMergedRegions.push([3, 3, firstColIndex, lastColIndex]);
        firstColIndex = lastColIndex + 1;
      })
      return tmpMergedRegions;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const data = {
      footer: '',
      formName: '月次予実管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult: outputDataList,
      totalInformation: [],
    };

    const dataExcel = {
      formName: '月次予実管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation: headerInformationExcel,
      searchResult: outputDataList,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex: [0],
    };

    const handleExportCSV = () => downloadBudgetPerfomanceManagementCSV(data, setIsLoading);
    const handleExportExcel = () => downloadBudgetPerfomanceManagementExcel(dataExcel, setIsLoading);

    return (
      <div  >
        <FlexBox>
          {
            roleScreen && roleScreen.downloadFlag === 1 && (
              <React.Fragment>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportCSV}
                    iconType="download"
                    text=" CSV出力"
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportExcel}
                    iconType="download"
                    text=" EXCEL出力"
                  />
                </FlexBoxItem>
              </React.Fragment>
            )}
        </FlexBox>

        <div style={{ margin: '15px' }}>
          <div className="templateTable newDesignTable" style={{ height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: isMacOs ? '35px' : '1px' }} >
            <table className="table table-bordered text-nowrap">
              <thead>
                <tr>
                {sortColumns.map((item: SortColumn, index: any) => (
                    <th key={index} rowSpan={item.rowSpan} style={item.style} className={item.className} onClick={e => sortByFieldPath(item.sortField, item.sortType)}>
                      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }} >
                        <span style={{ margin: 'auto' }} >{item.displayName}</span>
                        <div style={{ position: 'absolute', right: 0, marginTop: "-2px" }}>
                          {sortField.fieldPath === item.sortField && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== item.sortField && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </div>
                    </th>
                  ))}
                  {unsortColumns.map((item: SortColumn, index: any) => (
                    <th key={index} colSpan={item.colSpan} style={{ top: '0' }} className="text-center stickyStyle" ><span>{item.displayName}</span></th>
                  ))}
                </tr>
                <tr>
                  {sortSubColumns.map((item: SortColumn, index: any) => (
                    <th key={index} style={{ position: 'sticky', top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath(item.sortField, item.sortType)}>
                      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                        <span style={{ margin: 'auto' }}>{item.displayName}</span>
                        <div style={{ position: 'absolute', right: 0, marginTop: "-2px" }}>
                          {sortField.fieldPath === item.sortField && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== item.sortField && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  dataSort.map((item: MonthlyBudgetPerformanceManagementReportDomain, index: number) => {
                    return (<tr key={index} >
                      <td className="text-center stickyStyle fristColSticky" style={{ left: '0', zIndex: 10 }}>
                        <div className="text-ellipsis"><span>{item.orgCode}</span></div>
                      </td>
                      <td className="text-center stickyStyle " style={{ left: '203px', zIndex: 10 }}><span>
                        <div className="text-ellipsis">
                          <Link to={{ pathname: `/monthlyDailyList/${item.orgCode}/${target_date_from}/${target_date_to}/SaleManagementTable` }} >
                            {item.orgName}
                          </Link>
                        </div>
                      </span></td>
                      <td className="text-right"><span>{item.cumulativeSales}</span></td>
                      <td className="text-right"><span>{item.cumulativeSalesBudget}</span></td>
                      <td className="text-right"><span>{item.diffCumulativeSales}</span></td>
                      <td className="text-right"><span>{item.perGoalCumulativeSales}</span></td>
                      <td className="text-right"><span>{item.prevCumulativeSales}</span></td>
                      <td className="text-right"><span>{item.perPrevCumulativeSales}</span></td>
                      <td className="text-right"><span>{item.guestCnt}</span></td>
                      <td className="text-right"><span>{item.guestCntBudget}</span></td>
                      <td className="text-right"><span>{item.perGoalGuestCntBudget}</span></td>
                      <td className="text-right"><span>{item.perGuestCnt}</span></td>
                      <td className="text-right"><span>{item.perGuestCntBudget}</span></td>
                      <td className="text-right"><span>{item.diffPerGuestCnt}</span></td>
                      <td className="text-right"><span>{item.cumulativePurchase}</span></td>
                      <td className="text-right"><span>{item.cumulativePurchaseBudget}</span></td>
                      <td className="text-right"><span>{item.perCumulativePurchase}</span></td>
                      <td className="text-right"><span>{item.perCumulativePurchaseBudget}</span></td>
                      <td className="text-right"><span>{item.diffPerCumulativePurchase}</span></td>
                      <td className="text-right"><span>{item.cumulativeLaborCost}</span></td>
                      <td className="text-right"><span>{item.cumulativeLaborCostBudget}</span></td>
                      <td className="text-right"><span>{item.perCumulativeLaborCost}</span></td>
                      <td className="text-right"><span>{item.perCumulativeLaborCostBudget}</span></td>
                      <td className="text-right"><span>{item.diffPerCumulativeLaborCost}</span></td>
                      <td className="text-right"><span>{item.employeeWorkTimeDisp}</span></td>
                      <td className="text-right"><span>{item.partWorkTimeDisp}</span></td>
                      <td className="text-right"><span>{item.totalWorkTimeDisp}</span></td>
                      <td className="text-right"><span>{item.cumulativeFixCost}</span></td>
                      <td className="text-right"><span>{item.cumulativeVariableCost}</span></td>
                      <td className="text-right"><span>{item.cumulativeProfit}</span></td>
                      <td className="text-right"><span>{item.cumulativeProfitBudget}</span></td>
                      <td className="text-right"><span>{item.perGoalCumulativeProfit}</span></td>
                    </tr>)
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

export default MonthlyForeCastManagemenTable;
