import {
  useState, useCallback, useEffect,
} from 'react';

import {
  getGrantItemsForSpecialHolidayGrantHistory,
  downloadSpecialHolidayGrantHistoryCsv,
} from 'api/holidayManagement';
import SpecialHolidayGrantHistoryDomain from 'domain/master/holidayManagement/specialHolidayGrantHistory';

export const useSpecialHolidayGrantHistoryDomainForm = () => {
  const sessionStorageOrgCode = String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}5`)) !== 'null' ? (String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}5`))) : (sessionStorage.getItem('loginUser.orgCode') || '');
  const [paidHolidayGrantHistoryList, setPaidHolidayGrantHistoryList] = useState<
  Array<SpecialHolidayGrantHistoryDomain>
  >([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useState(sessionStorageOrgCode);
  const yearCurrent = new Date().getFullYear().toString();
  const sessionGrandHistoryTargetDateFrom = sessionStorage.getItem('specialHoliday.grandHistory.targetDateFrom') || `${yearCurrent}/01/01`;
  const [
    targetDateFrom, setTargetDateFrom,
  ] = useState<Date>(new Date(sessionGrandHistoryTargetDateFrom));
  const sessionGrandHistoryTargetDateTo = sessionStorage.getItem('specialHoliday.grandHistory.targetDateTo') || `${yearCurrent}/12/31`;
  const [targetDateTo, setTargetDateTo] = useState<Date>(new Date(sessionGrandHistoryTargetDateTo));
  const [modalOpen, setModalOpen] = useState(false);

  // データの取得
  const fetchData = useCallback(async () => {
    const response = await getGrantItemsForSpecialHolidayGrantHistory(
      orgCode !== 'all' ? orgCode : '',
      targetDateFrom,
      targetDateTo,
    );
    setPaidHolidayGrantHistoryList(
      response.map((result) => new SpecialHolidayGrantHistoryDomain(result)),
    );
  }, [orgCode, targetDateFrom, targetDateTo]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, targetDateFrom, targetDateTo]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return {
    paidHolidayGrantHistoryList,
    toggleNoManager,
    orgCode,
    setOrgCode,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    modalOpen,
    setModalOpen,
    closeModal,
  };
};

export const useDownloadHolidayManagementSpecialHolidayGrantHistory = (
  orgCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
) => {
  const downloadHolidayManagementSpecialHolidayGrantHistory = useCallback((
    isDownloadAll?: boolean,
  ) => {
    downloadSpecialHolidayGrantHistoryCsv(
      isDownloadAll ? '' : orgCode,
      targetDateFrom,
      targetDateTo,
    );
  }, [orgCode, targetDateFrom, targetDateTo]);

  return {
    downloadHolidayManagementSpecialHolidayGrantHistory,
  };
};

export default {
  useSpecialHolidayGrantHistoryDomainForm,
};
