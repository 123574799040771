import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { UpdateTimeSetting, getTimeSetting } from 'api/timeSetting';
import TimeSettingDomain from 'domain/master/labor/timeSetting';
import useToastNotification from 'hooks/useToastNotification';

export const useSettingUpdateForm = () => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const { successNotification, errorNotification } = useToastNotification();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (val: any) => {
    const submitTimeSetting = val.getRawDataWithoutNullData();
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    setConfirmModalOpen(false);
    setIsLoading(true);
    try {
      const response = await UpdateTimeSetting(companyCode, submitTimeSetting);
      if (!response.errors) {
        setIsLoading(false);
        successNotification('更新しました');
      }
    } catch (error) {
      setConfirmModalOpen(false);
      setIsLoading(false);
      if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
        let msgError = '';
        error.response.data.errors.map((iteam: { defaultMessage: string; }) => {
          msgError += `${iteam.defaultMessage} \n`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification('Something wrong. please try again');
        throw error;
      }
    }
  };


  const formik = useFormik({
    initialValues: TimeSettingDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    getTimeSetting(companyCode).then((response: any) => {
      formik.setValues(new TimeSettingDomain(response));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode]);


  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    isLoading,
  };
};


export default useSettingUpdateForm;
