/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */
import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import ExecutiveIndexLayoutPage from 'components/organismos/Executive/ExecutiveIndexPage/ExecutiveIndexLayoutPage';

const ExecutiveIndexPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="役職マスタ"
  >
    <ExecutiveIndexLayoutPage />
  </SidebarTemplate>
);

export default ExecutiveIndexPage;
