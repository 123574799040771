/** @jsx jsx */
import React, { useState, useEffect, RefObject } from 'react';
import { css, jsx } from '@emotion/core';
import { layer } from 'components/styles';
import SubContextMenuShift from './SubContextMenuShift';
import ContextMenuItem from '../ContextMenuItem';
import { ContextMenuType } from '../type';

const styles = {
  box: (left: number, top: number) => css({
    position: 'absolute',
    top,
    left,
    whiteSpace: 'nowrap',
    zIndex: 100,
    cursor: 'pointer',
    boxShadow: layer.layer06,
    minWidth: '160px',
  }),
};

//Forked DropdownBox - add reponsive to position
const ContextMenuShift: React.FC<{
  pageX: number,
  pageY: number,
  parentRef: RefObject<any>,
  buttons: ContextMenuType[],
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>,
}> = ({ buttons, pageX, pageY, parentRef, setIsComponentVisible }) => {
  const [boxElement, setBoxElement] = useState<HTMLElement | null>(null);
  const [boxLeft, setBoxLeft] = useState<number>(0);
  const [boxTop, setBoxTop] = useState<number>(0);

  useEffect(() => {
    if (!boxElement) {
      return;
    }

    const clientRect = boxElement && boxElement.getBoundingClientRect();
    const extraSpaceForDesktop = window.screen.availWidth > 1200 ? 100 : 50;
    const remainWidthRight = window.innerWidth - pageX - extraSpaceForDesktop;
    const remainHeightBottom = window.innerHeight - pageY - extraSpaceForDesktop;
    
    if (remainWidthRight < clientRect.width) {
      setBoxLeft(pageX - clientRect.width);
    } else {
      setBoxLeft(pageX);
    }

    if (remainHeightBottom < clientRect.height) {
      setBoxTop(pageY - clientRect.height /* + window.scrollY */);
    } else {
      setBoxTop(pageY /* + window.scrollY */)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxElement]);
  return (
    <React.Fragment>
      <div css={styles.box(boxLeft, boxTop)} ref={(ref) => setBoxElement(ref)}>
        <div ref={parentRef}>
          {buttons
            .filter(btn => !btn.isHidden)
            .map((btn, index) => (
              <div key={index} style={{ position: 'relative' }}>
                {btn.subMenu
                  ?
                  <SubContextMenuShift text={btn.label}>
                    {btn.subMenu.map((subItem) => (
                      <ContextMenuItem key={subItem.label}
                        label={subItem.label}
                        disabled={!subItem.isSelectable}
                        icon={btn.icon}
                        onClick={() => {
                          if (subItem.onClick) {
                            subItem.onClick();
                          }
                          setIsComponentVisible(false);
                        }}
                      />
                    ))}
                  </SubContextMenuShift>
                  :
                  <ContextMenuItem
                    label={btn.label}
                    disabled={!btn.isSelectable}
                    icon={btn.icon}
                    onClick={() => {
                      if (btn.onClick) {
                        btn.onClick();
                      }
                      setIsComponentVisible(false);
                    }}
                  />
                }
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContextMenuShift;
