import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import ActivationInputForm from './ActivationInputForm';

const ActivationForm: React.FC = () => (
  <FormContents>
    <ActivationInputForm />
  </FormContents>
);

export default ActivationForm;
