/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import moment from 'moment';
import { downloadIncomeAndExpenditureStoreCsv, downloadIncomeAndExpenditureStoreExcel } from 'api/incomeAndExpenditureStore';

const IncomeAndExpenditureStoreTable: React.FC<{
    targetDateFrom: any,
    incomeAndExpenditureStore: any,
    targetPeriodData: any,
    orgLabel: any,
    setIsLoading: any,
    roleScreen?: any
}> = ({
    targetDateFrom,
    incomeAndExpenditureStore,
    targetPeriodData,
    orgLabel,
    setIsLoading,
    roleScreen
}) => {

        const target_date_from = `${moment(targetDateFrom).format(`YYYY年MM月`)}`;

        const formatter = new Intl.NumberFormat('ja-JP', {
            minimumFractionDigits: 0, maximumFractionDigits: 2,
        });

        const formatterPercent = new Intl.NumberFormat('ja-JP', {
            minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'percent',
        });

        // data for CSV, Excel
        const totalInformation: any[] = [];
        const searchResult: any[] = [];

        const targetStoresData = `対象店舗：${orgLabel}`;

        const mergedRegions: any = [];

        const codeColumnIndex: any = [];

        useEffect(() => {
            let employeeLaborCost: any = [];
            let partSalary: any = [];
            let partAdjustCost: any = [];
            let totalLaborCost: any = [];
            let retireLaborCost: any = [];
            let foodTotal: any = [];
            let drinkTotal: any = [];
            let termStart: any = [];
            let termEnd: any = [];
            let totalPurchase: any = [];
            let totalCommunicationCost: any = [];
            let totalAdvertisingCost: any = [];
            let totalRecruitmentCost: any = [];
            let totalExpenses: any = [];
            let totalUtilityCosts: any = [];
            let totalLandRent: any = [];
            let totalCcAmount: any = [];
            let ccFee: any = [];
            let depreciation: any = [];
            let bonusDepreciation: any = [];
            let repairCosts: any = [];
            let commonFee: any = [];
            let totalExpenditure: any = [];
            let netSales: any = [];
            let otherNetSales: any = [];
            let totalNetSales: any = [];
            let ordinaryIncome: any = [];
            let salesBudgetTotal: any = [];
            let totalFlCost: any = [];
            let totalFlaCost: any = [];
            let totalFlarCost: any = [];
            let totalFlaroCost: any = [];

            employeeLaborCost.push('人件費', '社員');
            partSalary.push('', 'アルバイト');
            partAdjustCost.push('', 'アルバイト調整額');
            totalLaborCost.push('', '人件費合計');
            retireLaborCost.push('人件費(退職者)', '');
            foodTotal.push('仕入', '食材合計');
            drinkTotal.push('', '飲材合計');
            termStart.push('', '前月棚卸額');
            termEnd.push('', '当月棚卸額');
            totalPurchase.push('', '仕入原材料合計');
            totalCommunicationCost.push('通信費合計', '');
            totalAdvertisingCost.push('宣伝費合計', '');
            totalRecruitmentCost.push('募集費合計', '');
            totalExpenses.push('諸経費合計', '');
            totalUtilityCosts.push('光熱費合計', '');
            totalLandRent.push('家賃合計', '');
            totalCcAmount.push('決済手数料', '決済売上(税抜)');
            ccFee.push('', '手数料');
            depreciation.push('減価償却費', '');
            bonusDepreciation.push('賞与償却費', '');
            repairCosts.push('修繕費', '');
            commonFee.push('共通費', '');
            totalExpenditure.push('支出総計', '');
            netSales.push('売上(税抜)', 'POS売上');
            otherNetSales.push('', 'その他売上');
            totalNetSales.push('', '売上合計');
            ordinaryIncome.push('営業利益/率', '');
            salesBudgetTotal.push('予算/達成率', '');
            totalFlCost.push('FLコスト/率', '');
            totalFlaCost.push('FLAコスト/率','');
            totalFlarCost.push('FLARコスト/率','');
            totalFlaroCost.push('FLAROコスト/率','');

            mergedRegions.push([4, 7, 0, 0]);
            mergedRegions.push([8, 8, 0, 1]);
            mergedRegions.push([9, 13, 0, 0]);
            mergedRegions.push([14, 14, 0, 1]);
            mergedRegions.push([15, 15, 0, 1]);
            mergedRegions.push([16, 16, 0, 1]);
            mergedRegions.push([17, 17, 0, 1]);
            mergedRegions.push([18, 18, 0, 1]);
            mergedRegions.push([19, 19, 0, 1]);
            mergedRegions.push([20, 21, 0, 0]);
            mergedRegions.push([22, 22, 0, 1]);
            mergedRegions.push([23, 23, 0, 1]);
            mergedRegions.push([24, 24, 0, 1]);
            mergedRegions.push([25, 25, 0, 1]);
            mergedRegions.push([26, 26, 0, 1]);
            mergedRegions.push([27, 29, 0, 0]);
            mergedRegions.push([30, 30, 0, 1]);
            mergedRegions.push([31, 31, 0, 1]);
            mergedRegions.push([32, 32, 0, 1]);
            mergedRegions.push([33, 33, 0, 1]);
            mergedRegions.push([34, 34, 0, 1]);
            mergedRegions.push([35, 35, 0, 1]);

            if (incomeAndExpenditureStore) {
                incomeAndExpenditureStore.map((item: any, index: any) => {
                    index === 0 ? mergedRegions.push([3, 3, 0, 1]) && mergedRegions.push([3, 3, 2, 3]) : mergedRegions.push([3, 3, (index + 1) * 2, (index + 1) * 2 + 1]);

                    mergedRegions.push([27, 27, (index + 1) * 2, (index + 1) * 2 + 1]);
                    mergedRegions.push([28, 28, (index + 1) * 2, (index + 1) * 2 + 1]);
                    mergedRegions.push([29, 29, (index + 1) * 2, (index + 1) * 2 + 1]);

                    employeeLaborCost.push(formatter.format(item.employeeLaborCost), (item.employeeLaborCostRate) + '%');
                    partSalary.push(formatter.format(item.partSalary), (item.partSalaryRate) + '%');
                    partAdjustCost.push(formatter.format(item.partAdjustCost), (item.partAdjustCostRate) + '%');
                    totalLaborCost.push(formatter.format(item.totalLaborCost), (item.totalLaborCostRate) + '%');
                    retireLaborCost.push(formatter.format(item.retireLaborCost), (item.retireLaborCostRate) + '%');
                    foodTotal.push(formatter.format(item.foodTotal), (item.foodTotalRate) + '%');
                    drinkTotal.push(formatter.format(item.drinkTotal), (item.drinkTotalRate) + '%');
                    termStart.push(formatter.format(item.termStart), (item.termStartRate) + '%');
                    termEnd.push(formatter.format(item.termEnd), (item.termEndRate) + '%');
                    totalPurchase.push(formatter.format(item.totalPurchase), (item.totalPurchaseRate) + '%');
                    totalCommunicationCost.push(formatter.format(item.totalCommunicationCost), (item.totalCommunicationCostRate) + '%');
                    totalAdvertisingCost.push(formatter.format(item.totalAdvertisingCost), (item.totalAdvertisingCostRate) + '%');
                    totalRecruitmentCost.push(formatter.format(item.totalRecruitmentCost), (item.totalRecruitmentCostRate) + '%');
                    totalExpenses.push(formatter.format(item.totalExpenses), (item.totalExpensesRate) + '%');
                    totalUtilityCosts.push(formatter.format(item.totalUtilityCosts), (item.totalUtilityCostsRate) + '%');
                    totalLandRent.push(formatter.format(item.totalLandRent), (item.totalLandRentRate) + '%');
                    totalCcAmount.push(formatter.format(item.totalCcAmount), (item.totalCcAmountRate) + '%');
                    ccFee.push(formatter.format(item.ccFee), (item.ccFeeRate) + '%');
                    depreciation.push(formatter.format(item.depreciation), (item.depreciationRate) + '%');
                    bonusDepreciation.push(formatter.format(item.bonusDepreciation), (item.bonusDepreciationRate) + '%');
                    repairCosts.push(formatter.format(item.repairCosts), (item.repairCostsRate) + '%');
                    commonFee.push(formatter.format(item.commonFee), (item.commonFeeRate) + '%');
                    totalExpenditure.push(formatter.format(item.totalExpenditure), (item.totalExpenditureRate) + '%');
                    netSales.push(formatter.format(item.netSales), '');
                    otherNetSales.push(formatter.format(item.otherNetSales), '');
                    totalNetSales.push(formatter.format(item.totalNetSales), '');
                    ordinaryIncome.push(formatter.format(item.ordinaryIncome), (item.profitRate) + '%');
                    salesBudgetTotal.push(formatter.format(item.salesBudgetTotal), (item.salesAchievementRate) + '%');
                    totalFlCost.push(formatter.format(item.totalFlCost), (item.totalFlRate) + '%');
                    totalFlaCost.push(formatter.format(item.totalFlaCost), (item.totalFlaRate) + '%')
                    totalFlarCost.push(formatter.format(item.totalFlarCost), (item.totalFlarRate) + '%')
                    totalFlaroCost.push(formatter.format(item.totalFlaroCost), (item.totalFlaroRate) + '%')
                });

                searchResult.push(employeeLaborCost, partSalary, partAdjustCost, totalLaborCost, retireLaborCost, foodTotal, drinkTotal, termStart, termEnd, totalPurchase, totalCommunicationCost, totalAdvertisingCost, totalRecruitmentCost, totalExpenses,
                    totalUtilityCosts, totalLandRent, totalCcAmount, ccFee, depreciation, bonusDepreciation, repairCosts, commonFee, totalExpenditure, netSales, otherNetSales, totalNetSales, ordinaryIncome, salesBudgetTotal, totalFlCost, totalFlaCost, totalFlarCost, totalFlaroCost);

            }
        }, [searchResult]);

        const listHeader = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return item.org_name
        });

        const new_list_header = listHeader.map((item: any, index: number) => {
            return [item, '']
        });

        const listHeaderResult = []

        for (var i = 0; i < new_list_header.length; i++) {
            listHeaderResult.push(...new_list_header[i])
        }

        listHeaderResult.unshift(target_date_from, '');

        const headerInformation = [
            listHeaderResult
        ];

        // 人件費＞社員
        const listEmployeeLaborCost = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                employeeLaborCost: item.employeeLaborCost,
                employeeLaborCostRate: item.employeeLaborCostRate
            }
        });

        // 人件費＞アルバイト
        const listPartSalary = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                partSalary: item.partSalary,
                partSalaryRate: item.partSalaryRate
            }
        });

        // 人件費＞アルバイト調整額
        const listPartAdjustCost = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                partAdjustCost: item.partAdjustCost,
                partAdjustCostRate: item.partAdjustCostRate
            }
        });

        // 人件費＞人件費合計率
        const listTotalLaborCost = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalLaborCost: item.totalLaborCost,
                totalLaborCostRate: item.totalLaborCostRate
            }
        });

        // 人件費(退職者)
        const listRetireLaborCost = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                retireLaborCost: item.retireLaborCost,
                retireLaborCostRate: item.retireLaborCostRate
            }
        });

        // 食材合計
        const listFoodTotal = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                foodTotal: item.foodTotal,
                foodTotalRate: item.foodTotalRate
            }
        });

        // 飲材合計
        const listDrinkTotal = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                drinkTotal: item.drinkTotal,
                drinkTotalRate: item.drinkTotalRate
            }
        });

        // 前月棚卸額
        const listTermStart = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                termStart: item.termStart,
                termStartRate: item.termStartRate
            }
        });

        // 当月棚卸額
        const listTermEnd = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                termEnd: item.termEnd,
                termEndRate: item.termEndRate
            }
        });

        // 仕入原材料合計
        const listTotalPurchase = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalPurchase: item.totalPurchase,
                totalPurchaseRate: item.totalPurchaseRate
            }
        });

        // 通信費合計
        const listTotalCommunicationCost = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalCommunicationCost: item.totalCommunicationCost,
                totalCommunicationCostRate: item.totalCommunicationCostRate
            }
        });

        // 宣伝費合計
        const listTotalAdvertisingCost = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalAdvertisingCost: item.totalAdvertisingCost,
                totalAdvertisingCostRate: item.totalAdvertisingCostRate
            }
        });

        // 募集費合計
        const listTotalRecruitmentCost = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalRecruitmentCost: item.totalRecruitmentCost,
                totalRecruitmentCostRate: item.totalRecruitmentCostRate
            }
        });

        // 諸経費合計
        const listTotalExpenses = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalExpenses: item.totalExpenses,
                totalExpensesRate: item.totalExpensesRate
            }
        });

        // 光熱費合計
        const listTotalUtilityCosts = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalUtilityCosts: item.totalUtilityCosts,
                totalUtilityCostsRate: item.totalUtilityCostsRate
            }
        });

        //家賃合計
        const listTotalLandRent = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalLandRent: item.totalLandRent,
                totalLandRentRate: item.totalLandRentRate
            }
        });

        // 決済売上(税抜)
        const listTotalCcAmount = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalCcAmount: item.totalCcAmount,
                totalCcAmountRate: item.totalCcAmountRate
            }
        });

        // 手数料
        const listCcFee = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                ccFee: item.ccFee,
                ccFeeRate: item.ccFeeRate
            }
        });

        // 減価償却費
        const listDepreciation = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                depreciation: item.depreciation,
                depreciationRate: item.depreciationRate
            }
        });

        // 賞与償却費
        const listBonusDepreciation = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                bonusDepreciation: item.bonusDepreciation,
                bonusDepreciationRate: item.bonusDepreciationRate
            }
        });

        // 修繕費
        const listRepairCosts = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                repairCosts: item.repairCosts,
                repairCostsRate: item.repairCostsRate
            }
        });

        // 共通費
        const listCommonFee = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                commonFee: item.commonFee,
                commonFeeRate: item.commonFeeRate
            }
        });

        // 支出総計
        const listTotalExpenditure = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalExpenditure: item.totalExpenditure,
                totalExpenditureRate: item.totalExpenditureRate
            }
        });

        // POS売上
        const listNetSales = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return item.netSales;
        });

        console.log('listNetSales', listNetSales);

        //その他売上
        const listOtherNetSales = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return item.otherNetSales;
        });

        //売上合計
        const listTotalNetSales = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return item.totalNetSales;
        });

        // 予算/達成率
        const listSalesBudgetTotal = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                salesBudgetTotal: item.salesBudgetTotal,
                salesAchievementRate: item.salesAchievementRate
            }
        });

        // FLコスト/率
        const listTotalFlCost = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalFlCost: item.totalFlCost,
                totalFlRate: item.totalFlRate
            }
        });

        //FLAコスト/率
        const listTotalFlaCost = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalFlaCost: item.totalFlaCost,
                totalFlaRate: item.totalFlaRate
            }
        });

        //FLARコスト/率
        const listTotalFlarCost = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalFlarCost: item.totalFlarCost,
                totalFlarRate: item.totalFlarRate
            }
        });

        //FLAROコスト/率
        const listTotalFlaroCost = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                totalFlaroCost: item.totalFlaroCost,
                totalFlaroRate: item.totalFlaroRate
            }
        });

        // 営業利益/率
        const listOrdinaryIncome = incomeAndExpenditureStore && incomeAndExpenditureStore.map((item: any, index: number) => {
            return {
                ordinaryIncome: item.ordinaryIncome,
                profitRate: item.profitRate
            }
        });

        const data = {
            footer: '',
            formName: '【店別】収支表帳票',
            targetPeriod: targetPeriodData,
            targetStores: targetStoresData,
            headerInformation,
            searchResult,
            totalInformation: [],
        };

        const dataExcel = {
            footer: '',
            formName: '【店別】収支表帳票',
            targetPeriod: targetPeriodData,
            targetStores: targetStoresData,
            headerInformation,
            searchResult,
            totalInformation: [],
            mergedRegions,
            codeColumnIndex
        };

        const handleExportCSV = () => { 
            setIsLoading(true); 
            downloadIncomeAndExpenditureStoreCsv(data, '【店別】収支表帳票.csv').finally(() => {
                setIsLoading(false);
            });
        };
        const handleExportExcel = () => { 
            setIsLoading(true);
            downloadIncomeAndExpenditureStoreExcel(dataExcel, '【店別】収支表帳票.xlsx').finally(() => {
                setIsLoading(false);
            });
         };

        return (
            <div>
                {
                    roleScreen && roleScreen.downloadFlag === 1 && (
                    <FlexBox>
                        <FlexBoxItem>
                            <IconLabelButton
                                onClick={handleExportCSV}
                                iconType="download"
                                text=" CSV出力"
                            />
                        </FlexBoxItem>
                        <FlexBoxItem>
                            <IconLabelButton
                                onClick={handleExportExcel}
                                iconType="download"
                                text=" EXCEL出力"
                            />
                        </FlexBoxItem>
                    </FlexBox>
                )}

                <div style={{ margin: '15px' }}>
                    <div className="templateTable newDesignTable" style={{ height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: '1px' }}>
                        <table className="table table-bordered text-nowrap titleColumn" style={{ width: "auto" }}>
                            <thead>
                                <tr>
                                    <th colSpan={2} className="text-center stickyStyle fristColSticky" style={{ top: '0px', left: '0px', zIndex: 15, maxWidth: '200px', width: '200px', minWidth: '200px' }}>{target_date_from}</th>
                                    {
                                        listHeader.map((item: string[]) => {
                                            return <th colSpan={2} className="text-center stickyStyle" style={{ position: 'sticky', top: '0px' }}>{item}</th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowSpan={4} className="stickyStyle backroundTitle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }}>人件費</td>
                                    <td className="stickyStyle fontWeightTitle" style={{ left: '107px', zIndex: 10, background: 'white' }}>社員</td>
                                    {
                                        listEmployeeLaborCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.employeeLaborCost && item.employeeLaborCost < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.employeeLaborCost)}</td>
                                                <td style={{ color: item.employeeLaborCostRate && item.employeeLaborCostRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.employeeLaborCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fontWeightTitle" style={{ left: '107px', zIndex: 10 }} >アルバイト</td>
                                    {
                                        listPartSalary.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.partSalary && item.partSalary < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.partSalary)}</td>
                                                <td style={{ color: item.partSalaryRate && item.partSalaryRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.partSalaryRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fontWeightTitle" style={{ left: '107px', zIndex: 10 }}>アルバイト調整額</td>
                                    {
                                        listPartAdjustCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.partAdjustCost && item.partAdjustCost < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.partAdjustCost)}</td>
                                                <td style={{ color: item.partAdjustCostRate && item.partAdjustCostRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.partAdjustCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fontWeightTitle" style={{ left: '107px', zIndex: 10 }}>人件費合計</td>
                                    {
                                        listTotalLaborCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalLaborCost && item.totalLaborCost < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalLaborCost)}</td>
                                                <td style={{ color: item.totalLaborCostRate && item.totalLaborCostRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalLaborCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>人件費(退職者)</td>
                                    {
                                        listRetireLaborCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.retireLaborCost && item.retireLaborCost < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.retireLaborCost)}</td>
                                                <td style={{ color: item.retireLaborCostRate && item.retireLaborCostRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.retireLaborCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>


                                <tr>
                                    <td rowSpan={5} className="stickyStyle backroundTitle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }}>仕入</td>
                                    <td className="stickyStyle backroundTitle fontWeightTitle" style={{ left: '107px', zIndex: 10, background: 'white' }}>食材合計</td>
                                    {
                                        listFoodTotal.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.foodTotal && item.foodTotal < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.foodTotal)}</td>
                                                <td style={{ color: item.foodTotalRate && item.foodTotalRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.foodTotalRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fontWeightTitle" style={{ left: '107px', zIndex: 10 }}>飲材合計</td>
                                    {
                                        listDrinkTotal.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.drinkTotal && item.drinkTotal < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.drinkTotal)}</td>
                                                <td style={{ color: item.drinkTotalRate && item.drinkTotalRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.drinkTotalRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fontWeightTitle" style={{ left: '107px', zIndex: 10 }}>前月棚卸額</td>
                                    {
                                        listTermStart.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.termStart && item.termStart < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.termStart)}</td>
                                                <td style={{ color: item.termStartRate && item.termStartRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.termStartRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fontWeightTitle" style={{ left: '107px', zIndex: 10 }}>当月棚卸額</td>
                                    {
                                        listTermEnd.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.termEnd && item.termEnd < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.termEnd)}</td>
                                                <td style={{ color: item.termEndRate && item.termEndRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.termEndRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fontWeightTitle" style={{ left: '107px', zIndex: 10 }}>仕入原材料合計</td>
                                    {
                                        listTotalPurchase.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalPurchase && item.totalPurchase < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalPurchase)}</td>
                                                <td style={{ color: item.totalPurchaseRate && item.totalPurchaseRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalPurchaseRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>通信費合計</td>
                                    {
                                        listTotalCommunicationCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalCommunicationCost && item.totalCommunicationCost < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalCommunicationCost)}</td>
                                                <td style={{ color: item.totalCommunicationCostRate && item.totalCommunicationCostRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalCommunicationCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>宣伝費合計</td>
                                    {
                                        listTotalAdvertisingCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalAdvertisingCost && item.totalAdvertisingCost < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalAdvertisingCost)}</td>
                                                <td style={{ color: item.totalAdvertisingCostRate && item.totalAdvertisingCostRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalAdvertisingCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>募集費合計</td>
                                    {
                                        listTotalRecruitmentCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalRecruitmentCost && item.totalRecruitmentCost < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalRecruitmentCost)}</td>
                                                <td style={{ color: item.totalRecruitmentCostRate && item.totalRecruitmentCostRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalRecruitmentCostRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>諸経費合計</td>
                                    {
                                        listTotalExpenses.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalExpenses && item.totalExpenses < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalExpenses)}</td>
                                                <td style={{ color: item.totalExpensesRate && item.totalExpensesRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalExpensesRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>光熱費合計</td>
                                    {
                                        listTotalUtilityCosts.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalUtilityCosts && item.totalUtilityCosts < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalUtilityCosts)}</td>
                                                <td style={{ color: item.totalUtilityCostsRate && item.totalUtilityCostsRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalUtilityCostsRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>家賃合計</td>
                                    {
                                        listTotalLandRent.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalLandRent && item.totalLandRent < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalLandRent)}</td>
                                                <td style={{ color: item.totalLandRentRate && item.totalLandRentRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalLandRentRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td rowSpan={2} className="stickyStyle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }}>決済手数料</td>
                                    <td className="stickyStyle fontWeightTitle" style={{ left: '107px', zIndex: 10, background: 'white' }}>決済売上(税抜)</td>
                                    {
                                        listTotalCcAmount.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalCcAmount && item.totalCcAmount < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalCcAmount)}</td>
                                                <td style={{ color: item.totalCcAmountRate && item.totalCcAmountRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalCcAmountRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fontWeightTitle" style={{ left: '107px', zIndex: 10 }}>手数料</td>
                                    {
                                        listCcFee.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.ccFee && item.ccFee < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.ccFee)}</td>
                                                <td style={{ color: item.ccFeeRate && item.ccFeeRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.ccFeeRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>減価償却費</td>
                                    {
                                        listDepreciation.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.depreciation && item.depreciation < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.depreciation)}</td>
                                                <td style={{ color: item.depreciationRate && item.depreciationRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.depreciationRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>賞与償却費</td>
                                    {
                                        listBonusDepreciation.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.bonusDepreciation && item.bonusDepreciation < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.bonusDepreciation)}</td>
                                                <td style={{ color: item.bonusDepreciationRate && item.bonusDepreciationRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.bonusDepreciationRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>修繕費</td>
                                    {
                                        listRepairCosts.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.repairCosts && item.repairCosts < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.repairCosts)}</td>
                                                <td style={{ color: item.repairCostsRate && item.repairCostsRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.repairCostsRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>共通費</td>
                                    {
                                        listCommonFee.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.commonFee && item.commonFee < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.commonFee)}</td>
                                                <td style={{ color: item.commonFeeRate && item.commonFeeRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.commonFeeRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>支出総計</td>
                                    {
                                        listTotalExpenditure.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalExpenditure && item.totalExpenditure < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalExpenditure)}</td>
                                                <td style={{ color: item.totalExpenditureRate && item.totalExpenditureRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalExpenditureRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td rowSpan={3} className="stickyStyle backroundTitle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }}>売上(税抜)</td>
                                    <td className="stickyStyle backroundTitle fontWeightTitle" style={{ left: '107px', zIndex: 10, background: 'white' }}>POS売上</td>
                                    {
                                        listNetSales.map((item: any) => {
                                            return <td colSpan = {2} style={{ color: item && item < 0 ? 'red' : 'black', textAlign: 'center' }}>{formatter.format(item)}</td>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fontWeightTitle" style={{ left: '107px', zIndex: 10 }}>その他売上</td>
                                    {
                                        listOtherNetSales.map((item: any) => {
                                            return <td colSpan = {2} style={{ color: item && item < 0 ? 'red' : 'black', textAlign: 'center' }}>{formatter.format(item)}</td>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fontWeightTitle" style={{ left: '107px', zIndex: 10 }}>売上合計</td>
                                    {
                                        listTotalNetSales.map((item: any) => {
                                            return <td colSpan = {2} style={{ color: item && item < 0 ? 'red' : 'black', textAlign: 'center' }}>{formatter.format(item)}</td>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>営業利益/率</td>
                                    {
                                        listOrdinaryIncome.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.ordinaryIncome && item.ordinaryIncome < 0 ? 'red' : 'black', paddingRight: '5px' , textAlign: 'right' }}>{formatter.format(item.ordinaryIncome)}</td>
                                                <td style={{ color: item.profitRate && item.profitRate < 0 ? 'red' : 'black', paddingRight: '5px' , textAlign: 'right' }}>{formatter.format(item.profitRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>予算/達成率</td>
                                    {
                                        listSalesBudgetTotal.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.salesBudgetTotal && item.salesBudgetTotal < 0 ? 'red' : 'black', paddingRight: '5px' , textAlign: 'right' }}>{formatter.format(item.salesBudgetTotal)}</td>
                                                <td style={{ color: item.salesAchievementRate && item.salesAchievementRate < 0 ? 'red' : 'black', paddingRight: '5px' , textAlign: 'right' }}>{formatter.format(item.salesAchievementRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>FLコスト/率</td>
                                    {
                                        listTotalFlCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalFlCost && item.totalFlCost < 0 ? 'red' : 'black', paddingRight: '5px' , textAlign: 'right' }}>{formatter.format(item.totalFlCost)}</td>
                                                <td style={{ color: item.totalFlRate && item.totalFlRate < 0 ? 'red' : 'black', paddingRight: '5px' , textAlign: 'right' }}>{formatter.format(item.totalFlRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>FLAコスト/率</td>
                                    {
                                        listTotalFlaCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalFlaCost && item.totalFlaCost < 0 ? 'red' : 'black', paddingRight: '5px', textAlign: 'right' }}>{formatter.format(item.totalFlaCost)}</td>
                                                <td style={{ color: item.totalFlaRate && item.totalFlaRate < 0 ? 'red' : 'black', paddingRight: '5px' , textAlign: 'right' }}>{formatter.format(item.totalFlaRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>FLARコスト/率</td>
                                    {
                                        listTotalFlarCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalFlarCost && item.totalFlarCost < 0 ? 'red' : 'black', paddingRight: '5px' , textAlign: 'right' }}>{formatter.format(item.totalFlarCost)}</td>
                                                <td style={{ color: item.totalFlarRate && item.totalFlarRate < 0 ? 'red' : 'black', paddingRight: '5px' ,textAlign: 'right' }}>{formatter.format(item.totalFlarRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td className="stickyStyle backroundTitle fristColSticky fontWeightTitle" style={{ left: '0px', zIndex: 10 }} colSpan={2}>FLAROコスト/率</td>
                                    {
                                        listTotalFlaroCost.map((item: any) => {
                                            return <React.Fragment>
                                                <td style={{ color: item.totalFlaroCost && item.totalFlaroCost < 0 ? 'red' : 'black', paddingRight: '5px' , textAlign: 'right' }}>{formatter.format(item.totalFlaroCost)}</td>
                                                <td style={{ color: item.totalFlaroRate && item.totalFlaroRate < 0 ? 'red' : 'black', paddingRight: '5px' , textAlign: 'right' }}>{formatter.format(item.totalFlaroRate)}%</td>
                                            </React.Fragment>
                                        })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };

export default IncomeAndExpenditureStoreTable;
