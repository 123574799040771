import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import getMonthlyDailyReport from 'api/monthlyDailyReport';
import MonthlyDailyReportDomain, { MonthlyDailyReport } from 'domain/master/storeManagement/monthlyDailyReport';

import { downloadMonthlyReportCsv, downloadMonthlyReportExcel } from 'api/monthlyReport';
import { getErrorMessageForResponse } from 'utility/errorUtil';
import useToastNotification from 'hooks/useToastNotification';

import { LocalStorageKey, SessionStorageKey } from 'utility/constants';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

export const useMonthlyDailyReport = () => {
  const { errorNotification } = useToastNotification();
  const [monthlyDailyDetailList, setMonthlyDailyDetailList] = useState<Array<MonthlyDailyReportDomain>>([]);
  const [monthlyDailyTotal, setMonthlyDailyTotal] = useState<MonthlyDailyReportDomain>(MonthlyDailyReportDomain.generateInitial());
  const [orgCode, setorgCode] = useState(() => {
    if (sessionStorage.getItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_ORG_CODE)) {
        return String(sessionStorage.getItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_ORG_CODE));
    }
    return sessionStorage.getItem('loginUser.orgCode') || '';
  });
  const [staffCode] = useState(sessionStorage.getItem('loginUser.staffCode') || '');
  const functionType = 3;
  const [categoryAry, setCategoryAry] = useState<Array<any>>(() => {
    if (sessionStorage.getItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_CATEGORY_ARRAY)) {
      return JSON.parse(String(sessionStorage.getItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_CATEGORY_ARRAY)));
    }
    return [];
  });

  const [categoryOption, setCategoryOption] = useState<Array<any>>(() => {
    if (sessionStorage.getItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_CATEGORY_OPTION)) {
      return JSON.parse(String(sessionStorage.getItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_CATEGORY_OPTION)));
    }
    return [];
  })
  const [blocking, setBlocking] = useState(false);
  const [targetDate, setTargetDate] = useState(() => {
    if (sessionStorage.getItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_TARGET_DATE)) {
      return new Date(String(sessionStorage.getItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_TARGET_DATE)));
    }
    return new Date();
  });
  const [orgName, setOrgName] = useState<string>('');
  const [orgTreesOptions, setOrgTreesOptions] = useState<[]>([]);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const [taxExclude, setTaxExclude] = useState(() => {
    if (localStorage.getItem(LocalStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_TAX_EXCLUDE)) {
      return Number(localStorage.getItem(LocalStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_TAX_EXCLUDE));
    }
    return 1;
  });

  const [toggle, setToggle] = useState(() => {
    sessionStorage.removeItem('toggle');
    sessionStorage.removeItem(`sessionOrganizationFileterTree${window.location.pathname}.Toggle`);
    if (sessionStorage.getItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_CATEGORY)) {
      return sessionStorage.getItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_CATEGORY) === 'true';
    }
    return false;
  });

  useEffect(() => {
    if (orgCode) {
      orgTreesOptions.map((data: any) => {
        if (data.value === orgCode) {
          return setOrgName(data.label);
        }
        return true;
      });
    }
  }, [orgCode, orgTreesOptions]);


  const fetchData = useCallback(async () => {
    setBlocking(true);
    try {
      const response = await getMonthlyDailyReport(
        moment(targetDate).startOf('month').format('YYYY/MM/DD'),
        moment(targetDate).endOf('month').format('YYYY/MM/DD'),
        orgCode,
        categoryAry,
      );
      setMonthlyDailyDetailList(response.detailList.map((d: MonthlyDailyReport) => new MonthlyDailyReportDomain(d)));
      setMonthlyDailyTotal(new MonthlyDailyReportDomain(response.total));
      sessionStorage.setItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_TARGET_DATE, moment(targetDate).format('YYYY-MM-DD'));
      sessionStorage.setItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_ORG_CODE, orgCode);
      sessionStorage.setItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_CATEGORY_OPTION, JSON.stringify(categoryOption));
      sessionStorage.setItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_CATEGORY_ARRAY, JSON.stringify(categoryAry));
    } catch(err) {
      const mess = getErrorMessageForResponse(err);
      errorNotification(mess);
    } finally {
      setBlocking(false);
    }
  },
  [categoryAry, orgCode, targetDate]);

  useEffect(() => {
    localStorage.setItem(LocalStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_TAX_EXCLUDE, String(taxExclude));
  }, [taxExclude]);

  useEffect(() => {
    if (!toggle) {
      sessionStorage.removeItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_CATEGORY_OPTION);
    }
    sessionStorage.setItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_CATEGORY, String(toggle));
  }, [toggle]);

  useEffect(() => {
    fetchData();
  }, [fetchData, categoryAry]);

  return {
    categoryAry,
    setCategoryAry,
    blocking,
    setBlocking,
    targetDate,
    setTargetDate,
    staffCode,
    orgCode,
    setorgCode,
    orgName,
    functionType,
    monthlyDailyDetailList,
    monthlyDailyTotal,
    roleScreen,
    setOrgTreesOptions,
    taxExclude,
    setTaxExclude,
    toggle,
    setToggle,
    categoryOption,
    setCategoryOption,
  };
};

export const useDownload = () => {
  const { errorNotification } = useToastNotification();

  const dowloadMonthlyDailyCsv = async (monthlyReport: any, setBlocking: Dispatch<SetStateAction<boolean>>) => {
    setBlocking(true);
    try {
      await downloadMonthlyReportCsv(monthlyReport, '日別店舗売上.csv');
    } catch (err) {
      errorNotification(getErrorMessageForResponse(err));
    } finally {
      setBlocking(false);
    }
  };

  const downloadMonthlyDailyExcel = async (monthlyReport: any, setBlocking: Dispatch<SetStateAction<boolean>>) => {
    setBlocking(true);
    try {
      await downloadMonthlyReportExcel(monthlyReport, '日別店舗売上.xlsx')
    } catch (err) {
      errorNotification(getErrorMessageForResponse(err));
    } finally {
      setBlocking(false);
    }
  };

  return {
    dowloadMonthlyDailyCsv,
    downloadMonthlyDailyExcel,
  };
};


export default useMonthlyDailyReport;
