/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { productColor } from 'components/styles';

const style = css({
  borderBottom: `1px solid ${productColor.primaryM90}`,
});

const Separater: React.FC<{
  margin?: string
}> = ({ margin }) => (
  <div css={css(style, { margin })} />
);

export default Separater;
