/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import useScrollbarAutoWidth from 'hooks/useScrollbarAutoWidth';
import { Link } from 'react-router-dom';
import { useDownload } from './hooks';
import moment from 'moment';
import { isMacOs } from 'react-device-detect';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';
import { grayScale } from 'components/styles';
import useSortTable, { FieldSortState, SortColumn } from 'hooks/useSortTable';
import MonthlyDiscountSummaryReportDomain, {MonthlyDiscountSummaryReportDetail, MonthlyDiscountSummaryReportDiscount} from 'domain/master/storeManagement/monthlyDiscountSummaryReport';
import { formatNumber } from 'utility/formatUtil';

const DEFAULT_SORT_FIELD = { fieldPath: 'orgCode', sort: 'asc' } as FieldSortState

const MonthlyDiscountSumaryTable: React.FC<{
  setIsLoading: any,
  targetDateFrom: any,
  targetDateTo: any,
  discountMonthlyReport: MonthlyDiscountSummaryReportDomain,
  targetPeriodData: any,
  orgLabel: any,
  roleScreen?: any,
}> = ({
  setIsLoading,
  targetDateFrom,
  targetDateTo,
  discountMonthlyReport,
  targetPeriodData,
  orgLabel,
  roleScreen,
}) => {
    let target_date_from = useMemo(() => `${moment(targetDateFrom).format('YYYY-MM-DD')}`, [targetDateFrom]);
    let target_date_to = useMemo(() => `${moment(targetDateTo).format('YYYY-MM-DD')}`, [targetDateTo]);

    const { headerList, detailList } = discountMonthlyReport;

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソートヘッダ項目 */
    const sortColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '店舗コード',  sortField: 'orgCode', sortType: 'string', rowSpan: 2, style: { top: '0', left: '0', zIndex: 99, maxWidth: ' 200px', width: '200px', minWidth: '200px' },     className:"text-center stickyStyle fristColSticky" },
      { displayName: '店舗名',      sortField: 'orgName', sortType: 'string', rowSpan: 2, style: { top: '0', left: '203px', zIndex: 99, maxWidth: ' 200px', width: '200px', minWidth: '200px' }, className:"text-center stickyStyle" },
    ], []);

    /** サブヘッダ項目 */
    const sortSubColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '金額', sortField: 'discountAmount', sortType: 'number', },
      { displayName: '枚数', sortField: 'discountCnt', sortType: 'number', },
    ], []);

    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(detailList, DEFAULT_SORT_FIELD)

    // ------------------------------------------------------------------
    // 出力
    // ------------------------------------------------------------------
    const { downloadDiscountReportCsv, downloadDiscountReportExcel } = useDownload();

    /** 出力用データ */
    const outputDataList = useMemo(() => MonthlyDiscountSummaryReportDomain.getOutputDetailData(dataSort), [dataSort]);

    const targetStoresData = `対象店舗：${orgLabel}`;

    /** CSV出力用ヘッダ情報 */
    const headerInformation = [
      [
        ...sortColumns.map(s => ''),
        ...headerList.flatMap(h => [h.discountTypeMstName, ...Array((sortSubColumns.length - 1)).fill('')]),
      ],
      [
        ...sortColumns.map(s => s.displayName),
        ...headerList.flatMap(h => sortSubColumns.map(s => s.displayName)),
      ],
    ];

    /** Excel出力用ヘッダ情報 */
    const headerInformationExcel = [
      [
        ...sortColumns.map(s => s.displayName),
        ...headerList.flatMap(h => [h.discountTypeMstName, ...Array((sortSubColumns.length - 1)).fill('')]),
      ],
      [
        ...sortColumns.map(s => ''),
        ...headerList.flatMap(h => sortSubColumns.map(s => s.displayName)),
      ],
    ];

    /**
     * Excel出力用セル結合情報
     * NOTE: [firstRow, lastRow, firstCol, lastCol] の配列で結合するセル範囲を指定する
    */
    const mergedRegions = useMemo(() => {
      const tmpMergedRegions: any[] = [];
      // ヘッダの店舗セルを縦方向に結合
      tmpMergedRegions.push([3, 4, 0, 0]);
      // ヘッダの店舗名を縦方向に結合
      tmpMergedRegions.push([3, 4, 1, 1]);
      // ヘッダの値引項目名をサブヘッダ項目分横方向に結合
      let firstColIndex = 2;
      let lastColIndex = firstColIndex + sortSubColumns.length - 1;
      headerList.map((h: any) => {
        tmpMergedRegions.push([3, 3, firstColIndex, lastColIndex]);
        firstColIndex += sortSubColumns.length;
        lastColIndex += sortSubColumns.length;
      })
      return tmpMergedRegions;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const data = {
      footer: '',
      formName: '月次売上値引・割引集計表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult: outputDataList,
      totalInformation: [],
    };

    const dataExcel = {
      formName: '月次売上値引・割引集計表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation: headerInformationExcel,
      searchResult: outputDataList,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex: [0],
    };

    const handleExportCSV = () => downloadDiscountReportCsv(data, setIsLoading);
    const handleExportExcel = () => downloadDiscountReportExcel(dataExcel, setIsLoading);

    return (
      <div>
        <FlexBox>
          {
            roleScreen && roleScreen.downloadFlag === 1 && (
              <React.Fragment>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportCSV}
                    iconType="download"
                    text=" CSV出力"
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportExcel}
                    iconType="download"
                    text=" EXCEL出力"
                  />
                </FlexBoxItem>
              </React.Fragment>
            )}
        </FlexBox>
        <div style={{ margin: '15px' }}>
          <div className="templateTable newDesignTable" style={{ height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: isMacOs ? '35px' : '1px' }} >
            <table className="table table-bordered text-nowrap">
              <thead>
                <tr>
                  {sortColumns.map((item: SortColumn, index: any) => (
                    <th key={index} rowSpan={item.rowSpan} style={item.style} className={item.className} onClick={e => sortByFieldPath(item.sortField, item.sortType)}>
                      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }} >
                        <span style={{ margin: 'auto' }} >{item.displayName}</span>
                        <div style={{ position: 'absolute', right: 0, marginTop: "-2px" }}>
                          {sortField.fieldPath === item.sortField && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== item.sortField && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </div>
                    </th>
                  ))}
                  {
                    headerList.length > 0
                      ? headerList.map((item: MonthlyDiscountSummaryReportDiscount, idx: number) => (
                        <th key={idx} colSpan={sortSubColumns.length} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}  >{item.discountTypeMstName}</span></th>
                      ))
                      : (<th className="text-center stickyStyle" style={{ width: '100%', top: '0' }}><span>-</span></th>)
                  }
                </tr>
                {
                  headerList.length > 0
                    ? (
                      <tr>
                        {
                          headerList.map((item: MonthlyDiscountSummaryReportDiscount, index: any) => (
                            <React.Fragment key={index}>
                              {sortSubColumns.map((item: SortColumn, index2: number) => (
                                <th key={index2} style={{ top: '34px' }} className="text-center stickyStyle" >
                                  <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }} onClick={e => sortByFieldPath(`discountList[${index}].${item.sortField}`, item.sortType)}>
                                    <span style={{ margin: 'auto' }} >{item.displayName}</span>
                                    <div style={{ position: 'absolute', right: 0, marginTop: "-2px" }}>
                                      {sortField.fieldPath === `discountList[${index}].${item.sortField}` && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                                      {sortField.fieldPath !== `discountList[${index}].${item.sortField}` && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                                    </div>
                                  </div>
                                </th>
                              ))}
                            </React.Fragment>
                          ))
                        }
                      </tr>
                    )
                    : (<tr><th style={{ top: '34px' }} className="text-center stickyStyle"><span>-</span></th></tr>)
                }
              </thead>
              <tbody>
                {
                  dataSort && dataSort.map((item: MonthlyDiscountSummaryReportDetail) => (
                    <tr key={item.orgCode}>
                      <td className="text-center stickyStyle fristColSticky" style={{ left: '0', zIndex: 10 }}>
                        <span><div className="text-ellipsis">{item.orgCode}</div></span>
                      </td>
                      <td className="text-center stickyStyle" style={{ left: '203px', zIndex: 10 }}><span>
                        <div className="text-ellipsis">
                          <Link to={{ pathname: `/monthlyDailyList/${item.orgCode}/${target_date_from}/${target_date_to}/DiscountSumaryManagementTable` }}>
                            {item.orgName}
                          </Link>
                        </div>
                      </span></td>
                      {
                        item.discountList?.length > 0
                          ? item.discountList.map((itemDiscount: MonthlyDiscountSummaryReportDiscount, idx: number) => (
                              <React.Fragment key={idx}>
                                <td className="text-right" >{formatNumber(itemDiscount?.discountAmount ?? 0)}</td>
                                <td className="text-right" >{formatNumber(itemDiscount?.discountCnt ?? 0)}</td>
                              </React.Fragment>
                            ))
                          : <td className="text-center"><span>-</span></td>
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>

      </div >
    );
  };
export default MonthlyDiscountSumaryTable;
