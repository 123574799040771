import { actionTypes } from "./actionTypes";
import { Actions } from './actions';
import {
  removeComma,
  numberFormater,
  // getRatioLabel,
  formatterPercent,
  getRatioLabel,
  getDivision,
  sumTotalOfArray,
  formatNumberMinus
} from "./functions";
import { SaleBudgetYearType, SalesBudgetMonthly} from "./type";

export const initialState = {
  generalData: {
    listAdvertising: [],
    listAdvertisingApplyPeriod: [],
    listAdvertisingExpense: [],
    listExpenses: [],
    salesBudgetMonthly: []
  },
  monthlyListDataInfo: {
    // staff info of months in 売上高
    listAdvertising: [],
    // staff info of months in A（広告費）
    listAdvertisingExpense: [],
    // staff info of months in O（その他経費）
    listExpenses: []
  },
  monthlyListData: {
    // months in 売上高
    listAdvertising: [],
    salesBudgetAdvertising: [],
    salesBudgetFree: [],
    salesBudgetTotal: [],
    listAdvertisingRow: [],
    salesBudgetFreeRow: [],
    salesBudgetTotalRow: [],
    // months in F（食材費）
    foodCost: [],
    drinkCost: [],
    costTotal: [],
    costTotalRate: [],
    foodCostRow: [],
    drinkCostRow: [],
    costTotalRow: [],
    costTotalRowRate: [],
    //grossProfitTotal hidden on DOM
    grossProfitTotal: [],
    // months in L（人件費）
    employeeLaborCost: [],
    partLaborCost: [],
    laborCostTotal: [],
    laborCostTotalRate: [],
    employeeLaborCostRow: [],
    partLaborCostRow: [],
    laborCostTotalRow: [],
    laborCostTotalRowRate: [],
    // months in A（広告費）
    listAdvertisingExpense: [],
    listAdvertisingExpenseTotal: [],
    listAdvertisingExpenseTotalRate: [],
    listAdvertisingExpenseRow: [],
    listAdvertisingExpenseTotalRow: [],
    listAdvertisingExpenseTotalRowRate: [],
    // months in FLA合計
    listFLACost: [],
    listFLACostRate: [],
    listFLACostRow: [],
    listFLACostRowRate: [],
    // months in 店舗損益 ProfitLoss
    listProfitLossCost: [],
    listProfitLossCostRate: [],
    listProfitLossCostRow: [],
    listProfitLossCostRowRate: [],
    // months in R（家賃）
    rentCost: [],
    commonServiceFeeCost: [],
    landRentTotalCost: [],
    landRentTotalRate: [],
    rentCostRow: [],
    commonServiceFeeCostRow: [],
    landRentTotalCostRow: [],
    landRentTotalRowRate: [],
    // months in O（その他経費)
    ////classification 0 = 変 , 1 = 固
    listClassification0: [],
    listClassification1: [],
    ////
    listExpenses: [],
    otherPurchase: [],
    listExpensesTotal: [],
    listExpensesTotalRate: [],
    listExpensesRow: [],
    otherPurchaseRow: [],
    listExpensesTotalRow: [],
    listExpensesTotalRowRate: [],
    // months in 【FLARO】合計
    listFLAROCost: [],
    listFLAROCostRate: [],
    listFLAROCostRow: [],
    listFLAROCostRowRate: [],
    // months in Operating Income 営業利益
    listOperatingIncomeCost: [],
    listOperatingIncomeCostRate: [],
    listOperatingIncomeCostRow: [],
    listOperatingIncomeCostRowRate: [],

    //-----------------------------------------------SecondTable---------------------------------------------------
    // totalPerCustomerPrice 客単価
    listTotalPerCustomerPrice: [],
    listTotalPerCustomerPriceRow: [],
    // getGuestCntTotal客数
    listGuestCntTotal: [],
    listGuestCntTotalRow: [],
    // getNumberOfSeat - for calculation
    listNumberOfSeat: [],
    // getTurnoverRateTotalItems 回転率
    listTurnoverRateTotal: [],
    listTurnoverRateTotalRow: [],
    // getOtherVariableCosts
    // listOtherVariableCosts: [],
    // getTotalAllEvenMarginalProfit 限界利益	 額
    listEvenMarginalProfitTotal: [],
    listEvenMarginalProfitTotalRow: [],
    // marginalProfitRate 限界利益	率
    listMarginalprofitRate: [],
    listMarginalprofitRateRow: [],
    // contributionMarginTotal 貢献利益	額
    listContributionMarginTotal: [],
    listContributionMarginTotalRow: [],
    // contributionMarginTotalRate 貢献利益	率
    listContributionMarginTotalRate: [],
    listContributionMarginTotalRateRow: [],
    // 損益分岐点	売上 bePointSales
    listBePointSales: [],
    listBePointSalesRow: [],
    // 損益分岐点	客数 bePointGuestCnt
    listBePointGuestCnt: [],
    listBePointGuestCntRow: [],
    // 人件費率 getTotalPersonRateItems	合計
    listPersonRateTotal: [],
    listPersonRateTotalRow: [],
    // 人件費率 getEmployeePersonRate 社員
    listEmployeePersonRate: [],
    listEmployeePersonRateRow: [],
    // 人件費率 getPartPersonRate アルバイト
    listPartPersonRate: [],
    listPartPersonRateRow: [],
    // laborShare 労働分配率
    listLaborShare: [],
    listLaborShareRow: []
  }
};
// yearFormStateType Actions
export const reducer = (state: SaleBudgetYearType, action:Actions) => {
  const { type, response } = action ;
  const orgCode = sessionStorage.getItem("orgCode_DestinationOrg");
  //JSON.parse & JSON.stringify to deep clone an Object
  const cloneData = JSON.parse(JSON.stringify({ ...state }));
  let count_dynamic_list_index = 0;
  switch (type) {
    // Fetch data from API action
    case actionTypes.FETCH:
      cloneData.generalData = {
        listAdvertising: response.listAdvertising,
        listAdvertisingApplyPeriod: response.listAdvertisingApplyPeriod,
        listAdvertisingExpense: response.listAdvertisingExpense,
        listExpenses: response.listExpenses,
        salesBudgetMonthly: response.salesBudgetMonthly
      };
      cloneData.monthlyListDataInfo = {
        //Elements start from index = 3 to the end of the array in 売上高
        listAdvertising: response.listAdvertising.map((item:Array<Array<number | null | string>>) => {
          return item && item.slice(0, 3);
        }),

        //Elements start from index = 3 to the end of the array in（広告費）
        listAdvertisingExpense: response.listAdvertisingExpense.map(
          (item:Array<Array<number | null | string>>) => {
            return item && item.slice(0, 3);
          }
        ),
        //Elements start from index = 3 to the end of the array in（その他経費）
        listExpenses: response.listExpenses.map((item:Array<Array<number | null | string>>) => {
          return item && item.slice(0, 3);
        })
      };
      cloneData.monthlyListData = {
        //Elements start from index = 3 to the end of the array in 売上高
        listAdvertising: response.listAdvertising.map((item:Array<Array<number | null | string>>) => {
          return item && item.slice(3, item.length);
        }),
        salesBudgetFree: response.salesBudgetMonthly.map((item:SalesBudgetMonthly) => {
          return item && item.salesBudgetFree;
        }),
        // F（食材費）
        foodCost: response.salesBudgetMonthly.map((item:SalesBudgetMonthly) => {
          return item && item.foodCost;
        }),
        drinkCost: response.salesBudgetMonthly.map((item:SalesBudgetMonthly) => {
          return item && item.drinkCost;
        }),
        costTotal: response.salesBudgetMonthly.map((item:SalesBudgetMonthly) => {
          return item && item.costTotal;
        }),
        // L（人件費）
        employeeLaborCost: response.salesBudgetMonthly.map((item:SalesBudgetMonthly) => {
          return item && item.employeeLaborCost;
        }),
        partLaborCost: response.salesBudgetMonthly.map((item:SalesBudgetMonthly) => {
          return item && item.partLaborCost;
        }),
        laborCostTotal: response.salesBudgetMonthly.map((item:SalesBudgetMonthly) => {
          return item && item.laborCostTotal;
        }),

        //Elements start from index = 3 to the end of the array in A（広告費）
        listAdvertisingExpense: response.listAdvertisingExpense.map(
          (item:Array<Array<number | null | string>>) => {
            return item && item.slice(3, item.length);
          }
        ),

        // R（家賃）
        rentCost: response.salesBudgetMonthly.map((item:SalesBudgetMonthly) => {
          return item && item.rent;
        }),
        commonServiceFeeCost: response.salesBudgetMonthly.map((item:SalesBudgetMonthly) => {
          return item && item.commonServiceFee;
        }),
        landRentTotalCost: response.salesBudgetMonthly.map((item:SalesBudgetMonthly) => {
          return item && item.landRent;
        }),

        //Elements start from index = 3 to the end of the array in（その他経費）
        listExpenses: response.listExpenses.map((item:Array<Array<number | null | string>>) => {
          return item && item.slice(3, item.length);
        }),
        otherPurchase: response.salesBudgetMonthly.map((item:SalesBudgetMonthly) => {
          return item && item.otherPurchase;
        }),

        //客単価 perCustomerPrice
        listTotalPerCustomerPrice: response.salesBudgetMonthly.map(
          (item:SalesBudgetMonthly) => {
            return item && item.perCustomerPrice;
          }
        ),
        //客数 guestCnt
        listGuestCntTotal: response.salesBudgetMonthly.map((item:SalesBudgetMonthly) => {
          return item && item.guestCnt;
        }),
        //numberOfSeat
        listNumberOfSeat: response.salesBudgetMonthly.map((item:SalesBudgetMonthly) => {
          return item && item.numberOfSeat;
        })
        //otherVariableCosts
        // listOtherVariableCosts: response.salesBudgetMonthly.map((item) => {
        //   return item && item.otherVariableCosts;
        // })
      };
      return cloneData;
    // Get handled data after fetching action
    case actionTypes.GET:
      // declare variables
      // Start 売上高 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // calculate & map data
      const listAdvertisingTotal = state.monthlyListData.listAdvertising.map(
        (item) => {
          return item !== null ? item && Number(sumTotalOfArray(item)) : null;
        }
      );
      const listSalesBudgetFree = state.monthlyListData.salesBudgetFree;
      // calculate total value in a row
      const salesBudgetFreeTotal = Number(sumTotalOfArray(
        state.monthlyListData.salesBudgetFree
      ));
      const salesBudgetTotal =
        Number(sumTotalOfArray(listAdvertisingTotal)) + Number(salesBudgetFreeTotal);
      // render array
      const listAdvertisingArray = state.monthlyListData.listAdvertising.map(
        (item, index) => {
          return [
            "",
            listAdvertisingTotal[index],
            state.monthlyListData.listAdvertising[index]
          ];
        }
      );
      // const listAdvertisingTotalArray =
      //   state.monthlyListData.listAdvertising &&
      //   state.monthlyListData.listAdvertising.length > 0 &&
      //   state.monthlyListData.listAdvertising[0]
      //     .map((item, index) => {
      //       return state.monthlyListData.listAdvertising.reduce(
      //         (accumulator, currentValue[]) => {
      //           return accumulator + currentValue[index];
      //         },
      //         0
      //       );
      //     })
      //     .map((item, index) => item + listSalesBudgetFree[index]);
      const salesBudgetAdvertisingArray = state.monthlyListData.listAdvertising.reduce(
          (accumulator, currentValue) =>
            currentValue.map((item, i) =>
              item !== null ? (accumulator[i] || 0) + item : null
            ),
          []
        );

      const listAdvertisingTotalArray =
        state.monthlyListData.listAdvertising &&
        state.monthlyListData.listAdvertising.length > 0
          ? state.monthlyListData.listAdvertising
              .reduce(
                (accumulator, currentValue) =>
                  currentValue.map((item, i) =>
                    item !== null ? (accumulator[i] || 0) + item : null
                  ),
                []
              )
              .map((item, index) =>
                item !== null ? item + listSalesBudgetFree[index] : null
              )
          : listSalesBudgetFree;
      const salesBudgetFreeArray = [
        "",
        salesBudgetFreeTotal,
        listSalesBudgetFree
      ];
      const salesBudgetTotalArray = [
        "",
        salesBudgetTotal,
        listAdvertisingTotalArray
      ];
      // End 売上高 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // Start F（食材費）-----------------------------------------------------------------------------------------------------------------------------------------------------------------
      const listFoodCost = state.monthlyListData.foodCost;
      const foodCostTotal = sumTotalOfArray(listFoodCost);
      const listDrinkCost = state.monthlyListData.drinkCost;
      const drinkCostTotal = sumTotalOfArray(listDrinkCost);

      const listCostTotal =
        listFoodCost &&
        listFoodCost &&
        listFoodCost.map((item, index) => {
          return item + listDrinkCost[index];
        });
      const costTotalFoodAndDrink = Number(foodCostTotal) + Number(drinkCostTotal)

      const listCostTotalRate =
        listCostTotal &&
        listCostTotal.map((item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(
                getRatioLabel(
                  listCostTotal[index],
                  listAdvertisingTotalArray[index]
                )
              )
            ) || 0
          );
        });
      const costTotalFoodAndDrinkRate = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(costTotalFoodAndDrink, salesBudgetTotal))
        ) || 0
      );

      const foodCostArray = ["変", foodCostTotal, listFoodCost];
      const drinkCostArray = ["変", drinkCostTotal, listDrinkCost];
      const costTotalArray = ["", costTotalFoodAndDrink, listCostTotal];
      const costTotalRateArray = [
        "",
        costTotalFoodAndDrinkRate,
        listCostTotalRate
      ];
      // End F（食材費）-----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // grossProfitTotal getRatioLabel(tmpMonthlyPlanData.standardCostTotal[0], tmpMonthlyPlanData.amountOfSalesTotal[0])
      const listGrossProfitTotal = listCostTotal.map(
        (item, index) => {
          return Number(listAdvertisingTotalArray[index]) - item;
        }
      );
      const grossProfitTotalValue = salesBudgetTotal - costTotalFoodAndDrink;

      // Start L（人件費）-----------------------------------------------------------------------------------------------------------------------------------------------------------------
      const employeeLaborCost = state.monthlyListData.employeeLaborCost;
      const employeeLaborCostTotal = sumTotalOfArray(employeeLaborCost);

      const partLaborCost = state.monthlyListData.partLaborCost;
      const partLaborCostTotal = sumTotalOfArray(partLaborCost);

      const laborCostTotal =
        employeeLaborCost &&
        partLaborCost &&
        employeeLaborCost.map((item, index) => {
          return employeeLaborCost[index] + partLaborCost[index];
        });
      const laborCostTotalEmployeeAndPartLabor =
        Number(employeeLaborCostTotal) + Number(partLaborCostTotal);

      const laborCostTotalRate =
        laborCostTotal &&
        laborCostTotal.map((item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(
                getRatioLabel(
                  laborCostTotal[index],
                  listAdvertisingTotalArray[index]
                )
              )
            ) || 0
          );
        });
      const laborCostTotalEmployeeAndPartLaborRate = formatterPercent.format(
        numberFormater(
          Number(
            getRatioLabel(laborCostTotalEmployeeAndPartLabor, salesBudgetTotal)
          )
        ) || 0
      );

      const employeeLaborCostArray = [
        "固",
        employeeLaborCostTotal,
        employeeLaborCost
      ];
      const partLaborCostArray = ["変", partLaborCostTotal, partLaborCost];
      const laborCostTotalArray = [
        "",
        laborCostTotalEmployeeAndPartLabor,
        laborCostTotal
      ];
      const laborCostTotalRateArray = [
        "",
        laborCostTotalEmployeeAndPartLaborRate,
        laborCostTotalRate
      ];
      // End L（人件費）-----------------------------------------------------------------------------------------------------------------------------------------------------------------

      // Start A 広告費 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
      // calculate & map data
      const listAdvertisingExpenseTotal = state.monthlyListData.listAdvertisingExpense.map(
        (item) => {
          return item !== null ? item && sumTotalOfArray(item) : null;
        }
      );
      const advertisingExpenseTotal = sumTotalOfArray(
        listAdvertisingExpenseTotal
      );

      const listAdvertisingExpenseTotalArray =
        state.monthlyListData.listAdvertisingExpense.length > 0
          ? state.monthlyListData.listAdvertisingExpense.reduce(
              (accumulator, currentValue) =>
                currentValue.map((item, i) =>
                  item !== null ? (accumulator[i] || 0) + item : null
                ),
              []
            )
          : Array(state.generalData.salesBudgetMonthly.length).fill(0);

      const listAdvertisingExpenseTotalRate =
        listAdvertisingExpenseTotalArray &&
        listAdvertisingExpenseTotalArray.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(getRatioLabel(item, listAdvertisingTotalArray[index]))
                ) || 0
              )
            : null;
        });
      const advertisingExpenseTotalRate = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(advertisingExpenseTotal, salesBudgetTotal))
        ) || 0
      );

      const listAdvertisingExpenseArray = state.monthlyListData.listAdvertising.map(
        (item, index) => {
          return [
            "固",
            listAdvertisingExpenseTotal[index],
            state.monthlyListData.listAdvertisingExpense[index]
          ];
        }
      );
      const advertisingExpenseTotalArray = [
        "",
        advertisingExpenseTotal,
        listAdvertisingExpenseTotalArray
      ];
      const advertisingExpnseTotalRateArray = [
        "",
        advertisingExpenseTotalRate,
        listAdvertisingExpenseTotalRate
      ];
      // End 広告費 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // Start FLA合計 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
      const listFLACostTotal =
        listCostTotal &&
        laborCostTotal &&
        listAdvertisingExpenseTotalArray &&
        listAdvertisingExpenseTotalArray.map((item, index) => {
          return item !== null
            ? item + listCostTotal[index] + laborCostTotal[index]
            : null;
        });
      const FLACostTotal =
        Number(costTotalFoodAndDrink) +
        Number(laborCostTotalEmployeeAndPartLabor) +
        Number(advertisingExpenseTotal);

      const listFLACostTotalRate =
        listFLACostTotal &&
        listFLACostTotal.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(getRatioLabel(item, listAdvertisingTotalArray[index]))
                ) || 0
              )
            : null;
        });
      const FLACostTotalRate = formatterPercent.format(
        numberFormater(Number(getRatioLabel(FLACostTotal, salesBudgetTotal))) ||
          0
      );

      const listFLACostTotalArray = ["", FLACostTotal, listFLACostTotal];
      const listFLACostTotalRateArray = [
        "",
        FLACostTotalRate,
        listFLACostTotalRate
      ];
      // End FLA合計 -----------------------------------------------------------------------------------------------------------------------------------------------------------------

      // Start Profit Loss Cost 店舗損益 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
      const listProfitLossCostTotal =
        listFLACostTotal &&
        listFLACostTotal.map((item, index) => {
          return item !== null ? Number(listAdvertisingTotalArray[index]) - item : null;
        });
      const profitLossCostTotal = sumTotalOfArray(listProfitLossCostTotal);

      const listProfitLossCostTotalRate =
        listProfitLossCostTotal &&
        listProfitLossCostTotal.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(getRatioLabel(item, listAdvertisingTotalArray[index]))
                ) || 0
              )
            : null;
        });
      const profitLossCostTotalRate = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(profitLossCostTotal, salesBudgetTotal))
        ) || 0
      );

      const listProfitLossCostTotalArray = [
        "",
        profitLossCostTotal,
        listProfitLossCostTotal
      ];
      const listProfitLossCostTotalRateArray = [
        "",
        profitLossCostTotalRate,
        listProfitLossCostTotalRate
      ];

      // End Profit Loss Cost 店舗損益 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // Start R（家賃）-----------------------------------------------------------------------------------------------------------------------------------------------------------------
      const listRentCost = state.monthlyListData.rentCost;
      const rentCostTotal = sumTotalOfArray(listRentCost);

      const listCommonServiceFeeCost =
        state.monthlyListData.commonServiceFeeCost;
      const commonServiceFeeCostTotal = sumTotalOfArray(
        listCommonServiceFeeCost
      );

      const listLandRentCostTotal =
        listRentCost &&
        listCommonServiceFeeCost &&
        listRentCost.map((item, index) => {
          return item + listCommonServiceFeeCost[index];
        });
      const landRentCostTotal = Number(rentCostTotal) + Number(commonServiceFeeCostTotal);

      const listLandRentCostTotalRate =
        listLandRentCostTotal &&
        listLandRentCostTotal.map((item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(getRatioLabel(item, listAdvertisingTotalArray[index]))
            ) || 0
          );
        });
      const landRentCostTotalRate = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(landRentCostTotal, salesBudgetTotal))
        ) || 0
      );

      const rentCostArray = ["固", rentCostTotal, listRentCost];
      const commonServiceFeeArray = [
        "固",
        commonServiceFeeCostTotal,
        listCommonServiceFeeCost
      ];
      const landRentCostArray = ["", landRentCostTotal, listLandRentCostTotal];
      const landRentRateArray = [
        "",
        landRentCostTotalRate,
        listLandRentCostTotalRate
      ];
      // End R（家賃）-----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // Start O（その他経費） ----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // calculate & map data
      const listExpensesTotal = state.monthlyListData.listExpenses.map(
        (item) => {
          return item !== null ? item && sumTotalOfArray(item) : null;
        }
      );

      const listOtherPurchase = state.monthlyListData.otherPurchase;
      const otherPurchaseTotal = sumTotalOfArray(listOtherPurchase);

      const expensesTotal =
        Number(sumTotalOfArray(listExpensesTotal)) + Number(otherPurchaseTotal);

      const listExpensesTotalArray =
        state.monthlyListData.listExpenses.length > 0
          ? state.monthlyListData.listExpenses
              .reduce(
                (accumulator, currentValue) =>
                  currentValue.map((item, i) =>
                    item !== null ? (accumulator[i] || 0) + item : null
                  ),
                []
              )
              .map((item, index) =>
                item !== null ? item + listOtherPurchase[index] : null
              )
          : listOtherPurchase;

      const listExpensesTotalRate =
        listExpensesTotalArray &&
        listExpensesTotalArray.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(getRatioLabel(item, listAdvertisingTotalArray[index]))
                ) || 0
              )
            : null;
        });

      const expensesTotalRate = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(expensesTotal, salesBudgetTotal))
        ) || 0
      );

      const listExpensesArray = state.monthlyListData.listExpenses.map(
        (item, index) => {
          return [
            state.monthlyListDataInfo.listExpenses[index][2] === 1
              ? "固"
              : "変",
            listExpensesTotal[index],
            state.monthlyListData.listExpenses[index]
          ];
        }
      );
      ////classification 0 = 変 , 1 = 固
      const expensesTotalClassification0:any =
        state.monthlyListData.listExpenses &&
        state.monthlyListData.listExpenses.length > 0 ?
        state.monthlyListData.listExpenses[0]
          .map((item, index) => {
            return state.monthlyListData.listExpenses.reduce(
              (
                accumulator:any,
                currentValue:any,
                currentIndex:number
              ) => {
                if (
                  state.monthlyListDataInfo.listExpenses[currentIndex] &&
                  state.monthlyListDataInfo.listExpenses[currentIndex][2] === 0
                ) {
                  return currentValue[index] !== null ? Number(accumulator) + Number(currentValue[index]) : null;
                } else {
                  return accumulator !== null ? Number(accumulator): 0;
                }
              },
              0
            );
          })
          .map(
            (item, index) =>
            item !== null ? item + state.monthlyListData.otherPurchase[index]:null
          ) : state.monthlyListData.otherPurchase
      const expensesTotalClassification0Value = Number(sumTotalOfArray(
        expensesTotalClassification0
      ));
      const expensesTotalClassification1:any =
        state.monthlyListData.listExpenses &&
        state.monthlyListData.listExpenses.length > 0 ?
        state.monthlyListData.listExpenses[0].map(
          (item, index) => {
            return state.monthlyListData.listExpenses.reduce(
              (
                accumulator:any,
                currentValue:any,
                currentIndex:number
              ) => {
                if (
                  state.monthlyListDataInfo.listExpenses[currentIndex] &&
                  state.monthlyListDataInfo.listExpenses[currentIndex][2] === 1
                ) {
                  return currentValue[index] !== null ? Number(accumulator) + Number(currentValue[index]) : null;
                } else {
                  return accumulator !== null ? Number(accumulator) : 0;
                }
              },
              0
            );
          }
        ) : Array(state.generalData.salesBudgetMonthly.length).fill(0)
      const expensesTotalClassification1Value = sumTotalOfArray(
        expensesTotalClassification1
      );
      ////
      const otherPurchaseArray = ["変", otherPurchaseTotal, listOtherPurchase];
      const expensesTotalArray = ["", expensesTotal, listExpensesTotalArray];
      const expensesTotalRateArray = [
        "",
        expensesTotalRate,
        listExpensesTotalRate
      ];

      // End O（その他経費） -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // Start FLARO合計 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
      const listFLAROCostTotal:number[] =
        listFLACostTotal &&
        listLandRentCostTotal &&
        listExpensesTotalArray &&
        listFLACostTotal.map((item, index) => {
          return item !== null
            ? item +
                listLandRentCostTotal[index] +
                listExpensesTotalArray[index]
            : null;
        });
      const FLAROCostTotal = FLACostTotal + landRentCostTotal + expensesTotal;

      const listFLAROCostTotalRate =
        listFLAROCostTotal &&
        listFLAROCostTotal.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(getRatioLabel(item, listAdvertisingTotalArray[index]))
                ) || 0
              )
            : null;
        });
      const FLAROCostTotalRate = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(FLAROCostTotal, salesBudgetTotal))
        ) || 0
      );

      const listFLAROCostTotalArray = ["", FLAROCostTotal, listFLAROCostTotal];
      const listFLAROCostTotalRateArray = [
        "",
        FLAROCostTotalRate,
        listFLAROCostTotalRate
      ];
      // End FLARO合計 -----------------------------------------------------------------------------------------------------------------------------------------------------------------

      // Start Profit Loss Cost 店舗損益 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
      const listOperatingIncomeCostTotal =
        listFLAROCostTotal &&
        listFLAROCostTotal.map((item, index) => {
          return item !== null ? Number(listAdvertisingTotalArray[index]) - item : null;
        });
      const operatingIncomeCostTotal = sumTotalOfArray(
        listOperatingIncomeCostTotal
      );

      const listOperatingIncomeCostTotalRate =
        listOperatingIncomeCostTotal &&
        listOperatingIncomeCostTotal.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(getRatioLabel(item, listAdvertisingTotalArray[index]))
                ) || 0
              )
            : null;
        });
      const operatingIncomeCostTotalRate = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(operatingIncomeCostTotal, salesBudgetTotal))
        ) || 0
      );

      const listOperatingIncomeCostTotalArray = [
        "",
        operatingIncomeCostTotal,
        listOperatingIncomeCostTotal
      ];
      const listOperatingIncomeCostTotalRateArray = [
        "",
        operatingIncomeCostTotalRate,
        listOperatingIncomeCostTotalRate
      ];

      // End Profit Loss Cost 店舗損益 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // totalPerCustomerPrice 客単価
      const listPerCustomerPriceTotal =
        orgCode !== "all"
          ? state.monthlyListData.listTotalPerCustomerPrice
          : state.monthlyListData.listGuestCntTotal &&
            state.monthlyListData.listGuestCntTotal.map(
              (item, index) => {
                return Number(
                  getRatioLabel(listAdvertisingTotalArray[index], item)
                );
              }
            );
      const perCustomerPriceTotal_GuestCnt =
        orgCode !== "all"
          ? state.monthlyListData.listTotalPerCustomerPrice.map(
              (item, index) => {
                return Number(
                  getRatioLabel(listAdvertisingTotalArray[index], item)
                );
              }
            )
          : listPerCustomerPriceTotal &&
            listPerCustomerPriceTotal.map((item, index) => {
              return Number(
                getRatioLabel(listAdvertisingTotalArray[index], item)
              );
            });

      const perCustomerPriceTotal_GuestCnt_Total = sumTotalOfArray(
        perCustomerPriceTotal_GuestCnt
      );

      const perCustomerPriceTotal = Number(
        getRatioLabel(salesBudgetTotal, perCustomerPriceTotal_GuestCnt_Total)
      );

      const listTotalPerCustomerPriceArray = [
        "",
        perCustomerPriceTotal,
        listPerCustomerPriceTotal
      ];
      // getGuestCntTotal客数
      const listGuestCntTotal =
        listPerCustomerPriceTotal &&
        listPerCustomerPriceTotal.map((item, index) => {
          return Number(getRatioLabel(listAdvertisingTotalArray[index], item))
          ;
        });
      const guestCntTotal = Number(getRatioLabel(salesBudgetTotal, perCustomerPriceTotal));

      const listGuestCntTotalArray = ["", guestCntTotal, listGuestCntTotal];

      // getTurnoverRateTotalItems 回転率
      const listTurnoverRateTotal =
        orgCode !== "all"
          ? listGuestCntTotal.map((item, index) => {
              return numberFormater(
                Number(
                  getRatioLabel(
                    item,
                    state.monthlyListData.listNumberOfSeat[index]
                  )
                )
              ).toFixed(2);
            })
          : state.monthlyListData.listGuestCntTotal.map(
              (item, index) => {
                return numberFormater(
                  Number(
                    getRatioLabel(
                      item,
                      state.monthlyListData.listNumberOfSeat[index]
                    )
                  )
                ).toFixed(2);
              }
            );
      const turnoverRateTotal = "-";
      const listTurnoverRateTotalArray = [
        "",
        turnoverRateTotal,
        listTurnoverRateTotal
      ];
      // getTotalAllEvenMarginalProfit 限界利益	 額
      const listEvenMarginalProfitTotal =
        listAdvertisingTotalArray &&
        listAdvertisingTotalArray.map((item, index) => {
          return item !== null
            ? item -
                listCostTotal[index] -
                partLaborCost[index] -
                expensesTotalClassification0[index]
            : null;
        });
      const evenMarginalProfitTotal = Number(salesBudgetTotal) - Number(partLaborCostTotal) - Number(costTotalFoodAndDrink) - expensesTotalClassification0Value
      const listEvenMarginalProfitTotalArray = [
        "",
        evenMarginalProfitTotal,
        listEvenMarginalProfitTotal
      ];
      // marginalProfitRate 限界利益	率
      const listMarginalProfitRateTotal =
        listEvenMarginalProfitTotal &&
        listEvenMarginalProfitTotal.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(getRatioLabel(item, listAdvertisingTotalArray[index]))
                ) || 0
              )
            : null;
        });
      const marginalProfitRateTotal = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(evenMarginalProfitTotal, salesBudgetTotal))
        ) || 0
      );
      const listMarginalProfitRateArray = [
        "",
        marginalProfitRateTotal,
        listMarginalProfitRateTotal
      ];
      // contributionMarginTotal 貢献利益	額
      const listContributionMarginTotal =
        listEvenMarginalProfitTotal &&
        listEvenMarginalProfitTotal.map((item, index) => {
          return item !== null
            ? item -
                employeeLaborCost[index] -
                listAdvertisingExpenseTotalArray[index] -
                listLandRentCostTotal[index] -
                expensesTotalClassification1[index]
            : null;
        });
      const contributionMarginTotal = sumTotalOfArray(
        listContributionMarginTotal
      );
      const listContributionMarginTotalArray = [
        "",
        contributionMarginTotal,
        listContributionMarginTotal
      ];
      // contributionMarginTotalRate 貢献利益	率
      const listContributionMarginTotalRate =
        listContributionMarginTotal &&
        listContributionMarginTotal.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(getRatioLabel(item, listAdvertisingTotalArray[index]))
                ) || 0
              )
            : null;
        });
      const contributionMarginTotalRate = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(contributionMarginTotal, salesBudgetTotal))
        ) || 0
      );
      const listContributionMarginTotalRateArray = [
        "",
        contributionMarginTotalRate,
        listContributionMarginTotalRate
      ];
      // 損益分岐点	売上 bePointSales
      //employeeLaborCost, listAdvertisingExpenseTotalArray,listLandRentCostTotal,expensesTotalClassification1
      //listEvenMarginalProfitTotal,listAdvertisingTotalArray
      const listBePointSalesTotal =
        listEvenMarginalProfitTotal &&
        listEvenMarginalProfitTotal.map((item, index) => {
          return item !== null
            ? getRatioLabel(
                  employeeLaborCost[index] +
                    listAdvertisingExpenseTotalArray[index] +
                    listLandRentCostTotal[index] +
                    expensesTotalClassification1[index],
                  getRatioLabel(item, listAdvertisingTotalArray[index])
                )

            : null;
        });
      const bePointSalesTotal = getRatioLabel(
          Number(employeeLaborCostTotal) +
            Number(advertisingExpenseTotal) +
            Number(landRentCostTotal) +
            Number(expensesTotalClassification1Value),
          getRatioLabel(evenMarginalProfitTotal, salesBudgetTotal)

      );
      const listBePointSalesArray = [
        "",
        bePointSalesTotal,
        listBePointSalesTotal
      ];
      // 損益分岐点	客数 bePointGuestCnt
      const listBePointGuestCntTotal =
        listBePointSalesTotal &&
        listBePointSalesTotal.map((item, index) => {
          return item !== null
            ? formatNumberMinus(getRatioLabel(item, listPerCustomerPriceTotal[index]))
            : null;
        });
      const bePointGuestCntTotal = formatNumberMinus(
        getRatioLabel(bePointSalesTotal, perCustomerPriceTotal)
      );
      const listBePointGuestCntArray = [
        "",
        bePointGuestCntTotal,
        listBePointGuestCntTotal
      ];
      // 人件費率 getTotalPersonRateItems	合計 laborCostTotalEmployeeAndPartLabor
      const listPersonRateTotal = laborCostTotal.map(
        (item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(getRatioLabel(item, listAdvertisingTotalArray[index]))
            ) || 0
          );
        }
      );
      const personRateTotal = formatterPercent.format(
        numberFormater(
          Number(
            getRatioLabel(laborCostTotalEmployeeAndPartLabor, salesBudgetTotal)
          )
        ) || 0
      );
      const listPersonRateTotalArray = [
        "",
        personRateTotal,
        listPersonRateTotal
      ];
      // 人件費率 getEmployeePersonRate 社員
      const listEmployeePersonRateTotal = employeeLaborCost.map(
        (item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(getRatioLabel(item, listAdvertisingTotalArray[index]))
            ) || 0
          );
        }
      );
      const employeePersonRateTotal = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(employeeLaborCostTotal, salesBudgetTotal))
        ) || 0
      );
      const listEmployeePersonRateArray = [
        "",
        employeePersonRateTotal,
        listEmployeePersonRateTotal
      ];
      // 人件費率 getPartPersonRate アルバイト
      const listPartPersonRateTotal = partLaborCost.map(
        (item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(getRatioLabel(item, listAdvertisingTotalArray[index]))
            ) || 0
          );
        }
      );
      const partPersonRateTotal = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(partLaborCostTotal, salesBudgetTotal))
        ) || 0
      );
      const listPartPersonRateArray = [
        "",
        partPersonRateTotal,
        listPartPersonRateTotal
      ];
      // laborShare 労働分配率
      const listLaborShareTotal = laborCostTotal.map(
        (item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(getRatioLabel(item, listGrossProfitTotal[index]))
            )
          );
        }
      );
      const laborShareTotal = formatterPercent.format(
        numberFormater(
          Number(
            getRatioLabel(
              laborCostTotalEmployeeAndPartLabor,
              grossProfitTotalValue
            )
          )
        )
      );

      const listLaborShareArray = ["", laborShareTotal, listLaborShareTotal];

      //-----------------------------------------------------------------------------------------------------------
      // Assign data to state
      cloneData.monthlyListData = {
        ...cloneData.monthlyListData,
        // rows for 売上高
        listAdvertisingRow: listAdvertisingArray,
        salesBudgetAdvertising: salesBudgetAdvertisingArray,
        salesBudgetTotal: listAdvertisingTotalArray,
        salesBudgetFreeRow: salesBudgetFreeArray,
        salesBudgetTotalRow: salesBudgetTotalArray,
        // rows for F（食材費）
        foodCostRow: foodCostArray,
        drinkCostRow: drinkCostArray,
        costTotalRow: costTotalArray,
        costTotalRowRate: costTotalRateArray,
        // grossProfitTotal
        grossProfitTotal: listGrossProfitTotal,
        // rows for L（人件費）
        employeeLaborCostRow: employeeLaborCostArray,
        partLaborCostRow: partLaborCostArray,
        laborCostTotalRow: laborCostTotalArray,
        laborCostTotalRowRate: laborCostTotalRateArray,
        // rows for A（広告費）
        listAdvertisingExpenseTotal: listAdvertisingExpenseTotalArray,
        listAdvertisingExpenseRow: listAdvertisingExpenseArray,
        listAdvertisingExpenseTotalRow: advertisingExpenseTotalArray,
        listAdvertisingExpenseTotalRowRate: advertisingExpnseTotalRateArray,
        // rows for FLA合計
        listFLACostRow: listFLACostTotalArray,
        listFLACostRowRate: listFLACostTotalRateArray,
        // rows for Profit Loss Cost 店舗損益
        listProfitLossCostRow: listProfitLossCostTotalArray,
        listProfitLossCostRowRate: listProfitLossCostTotalRateArray,
        // rows for R（家賃）
        rentCostRow: rentCostArray,
        commonServiceFeeCostRow: commonServiceFeeArray,
        landRentTotalCostRow: landRentCostArray,
        landRentTotalRowRate: landRentRateArray,
        // rows for O（その他経費）
        //
        listClassification0: expensesTotalClassification0,
        listClassification1: expensesTotalClassification1,
        //
        listExpensesTotal: listExpensesTotalArray,
        listExpensesRow: listExpensesArray,
        otherPurchaseRow: otherPurchaseArray,
        listExpensesTotalRow: expensesTotalArray,
        listExpensesTotalRowRate: expensesTotalRateArray,
        // rows for 【FLARO】合計
        listFLAROCostRow: listFLAROCostTotalArray,
        listFLAROCostRowRate: listFLAROCostTotalRateArray,
        // rows for Operating Income 営業利益
        listOperatingIncomeCost: listOperatingIncomeCostTotal,
        listOperatingIncomeCostRow: listOperatingIncomeCostTotalArray,
        listOperatingIncomeCostRowRate: listOperatingIncomeCostTotalRateArray,

        //-----------------------------------------------SecondTable---------------------------------------------------
        // totalPerCustomerPrice 客単価
        listTotalPerCustomerPriceRow: listTotalPerCustomerPriceArray,
        // getGuestCntTotal客数
        listGuestCntTotalRow: listGuestCntTotalArray,
        // getTurnoverRateTotalItems 回転率
        listTurnoverRateTotalRow: listTurnoverRateTotalArray,
        // getTotalAllEvenMarginalProfit 限界利益	 額
        listEvenMarginalProfitTotalRow: listEvenMarginalProfitTotalArray,
        // marginalProfitRate 限界利益	率
        listMarginalprofitRateRow: listMarginalProfitRateArray,
        // contributionMarginTotal 貢献利益	額
        listContributionMarginTotalRow: listContributionMarginTotalArray,
        // contributionMarginTotalRate 貢献利益	率
        listContributionMarginTotalRateRow: listContributionMarginTotalRateArray,
        // 損益分岐点	売上 bePointSales
        listBePointSalesRow: listBePointSalesArray,
        // 損益分岐点	客数 bePointGuestCnt
        listBePointGuestCntRow: listBePointGuestCntArray,
        // 人件費率 getTotalPersonRateItems	合計
        listPersonRateTotalRow: listPersonRateTotalArray,
        // 人件費率 getEmployeePersonRate 社員
        listEmployeePersonRateRow: listEmployeePersonRateArray,
        // 人件費率 getPartPersonRate アルバイト
        listPartPersonRateRow: listPartPersonRateArray,
        // laborShare 労働分配率
        listLaborShareRow: listLaborShareArray
      };

      return cloneData;
    case actionTypes.INPUT_CHANGE:
      // handleChange applied here !
      // Start 売上高 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // ----------------------------------------------------------------------- List Advertising -----------------------------------------------------------------------------
      const listAdvertisingInput =
        state.monthlyListData &&
        state.monthlyListData.listAdvertising &&
        state.monthlyListData.listAdvertising.map(
          (item1, index1) => {
            count_dynamic_list_index++;
            return (
              item1 &&
              item1.map((item2, index2) => {
                if (
                  response.id ===
                  `listAdvertising${index1}${count_dynamic_list_index}${index2}`
                ) {
                  return removeComma(response.value);
                } else {
                  return item2 !== null ? removeComma(item2) : null;
                }
              })
            );
          }
        );
      const listAdvertisingTotalRow = listAdvertisingInput.map((item) => {
        return item && sumTotalOfArray(item);
      });

      const listAdvertisingArrayRow = cloneData.monthlyListData.listAdvertising.map(
        (item:Array<Array<number | null | string> | string | number | null>, index: number) => {
          return [
            "",
            listAdvertisingTotalRow[index],
            listAdvertisingInput[index]
          ];
        }
      );
      // ----------------------------------------------------------------------- SalesBudgetFree -----------------------------------------------------------------------------

      const salesBudgetFreeInput =
        state.monthlyListData &&
        state.monthlyListData.salesBudgetFree &&
        state.monthlyListData.salesBudgetFree.map(
          (item, index) => {
            if (response.id === `${index}`) {
              return removeComma(response.value);
            } else {
              return item;
            }
          }
        );
      const salesBudgetFreeRowTotal = sumTotalOfArray(salesBudgetFreeInput);

      const salesBudgetFreeRow = [
        "",
        salesBudgetFreeRowTotal,
        salesBudgetFreeInput
      ];
      // ----------------------------------------------------------------------- SalesBudgetTotal -----------------------------------------------------------------------------
      const salesBudgetAdvertisingInput =
        listAdvertisingInput && listAdvertisingInput.length > 0
          ? listAdvertisingInput.reduce(
              (accumulator, currentValue) =>
                currentValue.map((item, i) =>
                  item !== null ? (accumulator[i] || 0) + (!isNaN(item) ? Number(item) : 0) : null
                ),
              []
            )
          : Array(state.generalData.salesBudgetMonthly.length).fill(0);

      const salesBudgetTotalInput =
        Number(sumTotalOfArray(listAdvertisingTotalRow)) + Number(salesBudgetFreeRowTotal);

      const listAdvertisingTotalInputArray =
        listAdvertisingInput.length > 0
          ? listAdvertisingInput
              .reduce(
                (accumulator, currentValue) =>
                  currentValue.map((item, i) =>
                    item !== null ? (!isNaN(accumulator[i]) ? Number(accumulator[i]) : 0 || 0) + (!isNaN(item) ? Number(item) : 0) : null
                  ),
                []
              )
              .map((item, index) =>
                item !== null ?  (!isNaN(item) ? Number(item) : 0) + (!isNaN(salesBudgetFreeInput[index]) ? Number(salesBudgetFreeInput[index]) : 0) : null
              )
          : salesBudgetFreeInput.map(item => !isNaN(item) ? Number(item) : 0);

      const salesBudgetTotalRow = [
        "",
        salesBudgetTotalInput,
        listAdvertisingTotalInputArray
      ];

      // End 売上高 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // Start F（食材費） -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // ----------------------------------------------------------------------- Food Cost -----------------------------------------------------------------------------
      const foodCostInput =
        state.monthlyListData &&
        state.monthlyListData.foodCost &&
        state.monthlyListData.foodCost.map((item, index) => {
          if (response.id === `foodCost${index}`) {
            return removeComma(response.value);
          } else {
            return item;
          }
        });
      const foodCostRowTotal = sumTotalOfArray(foodCostInput);

      const foodCostRow = ["変", foodCostRowTotal, foodCostInput];
      // ----------------------------------------------------------------------- Drink Cost -----------------------------------------------------------------------------
      const drinkCostInput =
        state.monthlyListData &&
        state.monthlyListData.drinkCost &&
        state.monthlyListData.drinkCost.map((item, index) => {
          if (response.id === `drinkCost${index}`) {
            return removeComma(response.value);
          } else {
            return item;
          }
        });
      const drinkCostRowTotal = sumTotalOfArray(drinkCostInput);
      const drinkCostRow = ["変", drinkCostRowTotal, drinkCostInput];

      // ----------------------------------------------------------------------- Cost Total -----------------------------------------------------------------------------
      const listCostTotalFoodAndDrinkInput = foodCostInput.map(
        (item, index) => {
          return (!isNaN(item) ? Number(item) : 0) + (!isNaN(drinkCostInput[index]) ? Number(drinkCostInput[index]) : 0);
        }
      );
      const costTotalFoodAndDrinkInput = Number(foodCostRowTotal) + Number(drinkCostRowTotal);
      const listCostTotalFoodAndDrinkInputRow = [
        "",
        costTotalFoodAndDrinkInput,
        listCostTotalFoodAndDrinkInput
      ];

      // ----------------------------------------------------------------------- Cost Total Rate-----------------------------------------------------------------------------
      const listCostTotalFoodAndDrinkRateInput = listCostTotalFoodAndDrinkInput.map(
        (item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(getRatioLabel(item, listAdvertisingTotalInputArray[index]))
            ) || 0
          );
        }
      );
      const costTotalFoodAndDrinkRateInput = formatterPercent.format(
        numberFormater(
          Number(
            getRatioLabel(costTotalFoodAndDrinkInput, salesBudgetTotalInput)
          )
        ) || 0
      );
      const listCostTotalFoodAndDrinkRateInputRow = [
        "",
        costTotalFoodAndDrinkRateInput,
        listCostTotalFoodAndDrinkRateInput
      ];
      // Start L（人件費) -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // ----------------------------------------------------------------------- Employee Labor Cost -----------------------------------------------------------------------------
      const employeeLaborCostInput =
        state.monthlyListData &&
        state.monthlyListData.employeeLaborCost &&
        state.monthlyListData.employeeLaborCost.map(
          (item, index) => {
            if (response.id === `employeeLaborCost${index}`) {
              return removeComma(response.value);
            } else {
              return item;
            }
          }
        );
      const employeeLaborCostInputRowTotal = sumTotalOfArray(
        employeeLaborCostInput
      );

      const employeeLaborCostInputRow = [
        "固",
        employeeLaborCostInputRowTotal,
        employeeLaborCostInput
      ];

      //
      // grossProfitTotal getRatioLabel(tmpMonthlyPlanData.standardCostTotal[0], tmpMonthlyPlanData.amountOfSalesTotal[0])
      const listGrossProfitTotalInput = listCostTotalFoodAndDrinkInput.map(
        (item, index) => {
          return Number(listAdvertisingTotalInputArray[index]) - item;
        }
      );
      const grossProfitTotalInputValue =
        salesBudgetTotalInput - costTotalFoodAndDrinkInput;
      // ----------------------------------------------------------------------- Part Labor Cost -----------------------------------------------------------------------------
      const partLaborCostInput =
        state.monthlyListData &&
        state.monthlyListData.partLaborCost &&
        state.monthlyListData.partLaborCost.map((item, index) => {
          if (response.id === `partLaborCost${index}`) {
            return removeComma(response.value);
          } else {
            return item;
          }
        });
      const partLaborCostRowTotal = sumTotalOfArray(partLaborCostInput);
      const partLaborCostRow = [
        "変",
        partLaborCostRowTotal,
        partLaborCostInput
      ];

      // ----------------------------------------------------------------------- Labor Cost Total -----------------------------------------------------------------------------
      const listLaborCostTotalInput = employeeLaborCostInput.map(
        (item, index) => {
          return (!isNaN(item) ? Number(item) : 0) + (!isNaN(partLaborCostInput[index]) ? Number(partLaborCostInput[index]) : 0)
        }
      );
      const laborCostTotalInput =
        Number(employeeLaborCostInputRowTotal) + Number(partLaborCostRowTotal);
      const listLaborCostTotalInputRow = [
        "",
        laborCostTotalInput,
        listLaborCostTotalInput
      ];

      // ----------------------------------------------------------------------- Labor Cost Total Rate-----------------------------------------------------------------------------
      const listLaborCostTotalRateInput = listLaborCostTotalInput.map(
        (item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(getRatioLabel(item, listAdvertisingTotalInputArray[index]))
            ) || 0
          );
        }
      );
      const laborCostTotalRateInput = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(laborCostTotalInput, salesBudgetTotalInput))
        ) || 0
      );
      const listLaborCostTotalInputRateRow = [
        "",
        laborCostTotalRateInput,
        listLaborCostTotalRateInput
      ];
      // Start A（広告費)-----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // ----------------------------------------------------------------------- List Advertising Expense -----------------------------------------------------------------------------
      const listAdvertisingExpenseInput =
        state.monthlyListData &&
        state.monthlyListData.listAdvertisingExpense &&
        state.monthlyListData.listAdvertisingExpense.map(
          (item1, index1) => {
            count_dynamic_list_index++;
            return (
              item1 &&
              item1.map((item2, index2) => {
                if (
                  response.id ===
                  `listAdvertisingExpense${index1}${count_dynamic_list_index}${index2}`
                ) {
                  return removeComma(response.value);
                } else {
                  return item2 !== null ? removeComma(item2) : null;
                }
              })
            );
          }
        );

      const listAdvertisingExpenseTotalValue = listAdvertisingExpenseInput.map(
        (item) => {
          return item && sumTotalOfArray(item);
        }
      );

      const listAdvertisingExpenseArrayRow = cloneData.monthlyListData.listAdvertisingExpense.map(
        (item:Array<Array<number | null>>, index:number) => {
          return [
            "固",
            listAdvertisingExpenseTotalValue[index],
            listAdvertisingExpenseInput[index]
          ];
        }
      );
      // ----------------------------------------------------------------------- List Advertising Expense Total -----------------------------------------------------------------------------

      const listAdvertisingExpenseInputTotal =
        listAdvertisingExpenseInput && listAdvertisingExpenseInput.length > 0
          ? listAdvertisingExpenseInput.reduce(
              (accumulator, currentValue) =>
                currentValue.map((item, i) =>
                  item !== null ? ((!isNaN(accumulator[i]) ? Number(accumulator[i]) : 0) || 0) + (!isNaN(item) ? Number(item) : 0) : null
                ),
              []
            )
          : Array(state.generalData.salesBudgetMonthly.length).fill(0);
      const advertisingExpenseTotalInputValue = sumTotalOfArray(
        listAdvertisingExpenseTotalValue
      );
      const listAdvertisingExpenseInputTotalRow = [
        "",
        advertisingExpenseTotalInputValue,
        listAdvertisingExpenseInputTotal
      ];

      // ----------------------------------------------------------------------- List Advertising Expense Total Rate -----------------------------------------------------------------------------
      const listAdvertisingExpenseTotalRateInput =
        listAdvertisingExpenseInputTotal &&
        listAdvertisingExpenseInputTotal.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(
                    getRatioLabel(item, listAdvertisingTotalInputArray[index])
                  )
                ) || 0
              )
            : null;
        });
      const advertisingExpenseInputTotalRate = formatterPercent.format(
        numberFormater(
          Number(
            getRatioLabel(
              advertisingExpenseTotalInputValue,
              salesBudgetTotalInput
            )
          )
        ) || 0
      );
      const listAdvertisingExpenseInputTotalRateRow = [
        "",
        advertisingExpenseInputTotalRate,
        listAdvertisingExpenseTotalRateInput
      ];

      // End A（広告費) -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // Start FLA合計 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
      const listFLACostTotalInput =
        listCostTotalFoodAndDrinkInput &&
        listLaborCostTotalInput &&
        listAdvertisingExpenseInputTotal &&
        listAdvertisingExpenseInputTotal.map((item, index) => {
          return item !== null
            ? item +
                listCostTotalFoodAndDrinkInput[index] +
                listLaborCostTotalInput[index]
            : null;
        });
      const FLACostTotalInput =
        costTotalFoodAndDrinkInput +
        laborCostTotalInput +
        Number(advertisingExpenseTotalInputValue);

      const listFLACostTotalRateInput =
        listFLACostTotalInput &&
        listFLACostTotalInput.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(
                    getRatioLabel(item, listAdvertisingTotalInputArray[index])
                  )
                ) || 0
              )
            : null;
        });
      const FLACostTotalRateInput = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(FLACostTotalInput, salesBudgetTotalInput))
        ) || 0
      );

      const listFLACostTotalInputArray = [
        "",
        FLACostTotalInput,
        listFLACostTotalInput
      ];
      const listFLACostTotalRateInputArray = [
        "",
        FLACostTotalRateInput,
        listFLACostTotalRateInput
      ];
      // End FLA合計 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // Start 店舗損益 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
      const listProfitLossCostTotalInput =
        listFLACostTotalInput &&
        listFLACostTotalInput.map((item, index) => {
          return item !== null
            ? Number(listAdvertisingTotalInputArray[index]) - item
            : null;
        });
      const profitLossCostTotalInput =
        salesBudgetTotalInput - FLACostTotalInput;

      const listProfitLossCostTotalRateInput =
        listProfitLossCostTotalInput &&
        listProfitLossCostTotalInput.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(
                    getRatioLabel(item, listAdvertisingTotalInputArray[index])
                  )
                ) || 0
              )
            : null;
        });
      const profitLossCostTotalRateInput = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(profitLossCostTotalInput, salesBudgetTotalInput))
        ) || 0
      );

      const listProfitLossCostTotalInputArray = [
        "",
        profitLossCostTotalInput,
        listProfitLossCostTotalInput
      ];
      const listProfitLossCostTotalRateInputArray = [
        "",
        profitLossCostTotalRateInput,
        listProfitLossCostTotalRateInput
      ];
      // End 店舗損益 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // Start R（家賃）-----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // ----------------------------------------------------------------------- Rent Cost -----------------------------------------------------------------------------
      const rentCostInput =
        state.monthlyListData &&
        state.monthlyListData.rentCost &&
        state.monthlyListData.rentCost.map((item, index) => {
          if (response.id === `rentCost${index}`) {
            return removeComma(response.value);
          } else {
            return item;
          }
        });
      const rentCostInputRowTotal = sumTotalOfArray(rentCostInput);

      const rentCostInputRow = ["固", rentCostInputRowTotal, rentCostInput];
      // ----------------------------------------------------------------------- Common Service Fee Cost -----------------------------------------------------------------------------
      const commonServiceFeeCostInput =
        state.monthlyListData &&
        state.monthlyListData.commonServiceFeeCost &&
        state.monthlyListData.commonServiceFeeCost.map(
          (item, index) => {
            if (response.id === `commonServiceFeeCost${index}`) {
              return removeComma(response.value);
            } else {
              return item;
            }
          }
        );
      const commonServiceFeeCostRowTotal = sumTotalOfArray(
        commonServiceFeeCostInput
      );
      const commonServiceFeeCostRow = [
        "固",
        commonServiceFeeCostRowTotal,
        commonServiceFeeCostInput
      ];

      // ----------------------------------------------------------------------- Land Rent Total -----------------------------------------------------------------------------
      const listLandRentTotalInput = rentCostInput.map(
        (item, index) => {
          return  (!isNaN(item) ? Number(item) : 0) + (!isNaN(commonServiceFeeCostInput[index]) ? Number(commonServiceFeeCostInput[index]) : 0)
        }
      );
      const landRentTotalInput =
        Number(rentCostInputRowTotal) + Number(commonServiceFeeCostRowTotal);
      const listLandRentTotalInputRow = [
        "",
        landRentTotalInput,
        listLandRentTotalInput
      ];

      // ----------------------------------------------------------------------- Land Rent Total Rate-----------------------------------------------------------------------------
      const listLandRentTotalRateInput = listLandRentTotalInput.map(
        (item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(getRatioLabel(item, listAdvertisingTotalInputArray[index]))
            ) || 0
          );
        }
      );
      const landRentTotalRateInput = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(landRentTotalInput, salesBudgetTotalInput))
        ) || 0
      );
      const listLandRentTotalInputRateRow = [
        "",
        landRentTotalRateInput,
        listLandRentTotalRateInput
      ];
      // Start O（その他経費） -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // ----------------------------------------------------------------------- List Expenses -----------------------------------------------------------------------------
      const listExpensesInput =
        state.monthlyListData &&
        state.monthlyListData.listExpenses &&
        state.monthlyListData.listExpenses.map((item1, index1) => {
          count_dynamic_list_index++;
          return (
            item1 &&
            item1.map((item2, index2) => {
              if (
                response.id ===
                `listExpenses${index1}${count_dynamic_list_index}${index2}`
              ) {
                return removeComma(response.value);
              } else {
                return item2 !== null ? removeComma(item2) : null;
              }
            })
          );
        });

      const listExpensesTotalRow = listExpensesInput.map((item) => {
        return item && sumTotalOfArray(item);
      });

      const listExpensesArrayRow = cloneData.monthlyListData.listExpenses.map(
        (item:Array<Array<number | null>>, index:number) => {
          return [
            state.monthlyListDataInfo.listExpenses[index][2] === 1
              ? "固"
              : "変",
            listExpensesTotalRow[index],
            listExpensesInput[index]
          ];
        }
      );
      // ----------------------------------------------------------------------- OtherPurchase -----------------------------------------------------------------------------

      const otherPurchaseInput =
        state.monthlyListData &&
        state.monthlyListData.otherPurchase &&
        state.monthlyListData.otherPurchase.map((item, index) => {
          if (response.id === `otherPurchase${index}`) {
            return removeComma(response.value);
          } else {
            return item;
          }
        });
      const otherPurchaseRowTotal = sumTotalOfArray(otherPurchaseInput);

      const otherPurchaseRow = [
        "変",
        otherPurchaseRowTotal,
        otherPurchaseInput
      ];
      // ----------------------------------------------------------------------- List Expenses Total -----------------------------------------------------------------------------
      const listExpensesTotalInput =
        Number(sumTotalOfArray(listExpensesTotalRow)) + Number(otherPurchaseRowTotal);

      const listExpensesTotalInputArray =
        listExpensesInput && listExpensesInput.length > 0
          ? listExpensesInput
              .reduce(
                (accumulator, currentValue) =>
                  currentValue.map((item, i) =>
                    item !== null ? ((!isNaN(accumulator[i]) ? Number(accumulator[i]) : 0) || 0) + (!isNaN(item) ? Number(item) : 0) : null
                  ),
                []
              )
              .map((item, index) =>
                item !== null ? (!isNaN(item) ? Number(item) : 0) + (!isNaN(otherPurchaseInput[index]) ? Number(otherPurchaseInput[index]) : 0) : null
              )
          : otherPurchaseInput.map(item=>!isNaN(item) ? Number(item) : 0);

      const listExpensesTotalInputRow = [
        "",
        listExpensesTotalInput,
        listExpensesTotalInputArray
      ];
      // ----------------------------------------------------------------------- List Expenses Total Rate-----------------------------------------------------------------------------
      const listExpensesTotalInputRateArray = listExpensesTotalInputArray.map(
        (item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(
                    getRatioLabel(item, listAdvertisingTotalInputArray[index])
                  )
                ) || 0
              )
            : null;
        }
      );
      const listExpensesTotalInputRate = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(listExpensesTotalInput, salesBudgetTotalInput))
        ) || 0
      );
      const listExpensesRateInputRow = [
        "",
        listExpensesTotalInputRate,
        listExpensesTotalInputRateArray
      ];
      // End  -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      ////classification 0 = 変 , 1 = 固
      const expensesTotalClassification0Input:any =
      listExpensesInput.length > 0 ?
      listExpensesInput[0]
          .map((item, index) => {
            return listExpensesInput.reduce(
              (
                accumulator:any,
                currentValue:any,
                currentIndex:number
              ) => {
                if (
                  state.monthlyListDataInfo.listExpenses[currentIndex] &&
                  state.monthlyListDataInfo.listExpenses[currentIndex][2] === 0
                ) {
                  return currentValue[index] !== null ? accumulator + (!isNaN(currentValue[index]) ? Number(currentValue[index]) : 0) : null;
                } else {
                  return accumulator !== null ? Number(accumulator) : 0;
                }
              },
              0
            );
          })
          .map(
            (item, index) =>
            item !== null ? (!isNaN(item) ? Number(item) : 0) + (!isNaN(otherPurchaseInput[index]) ? otherPurchaseInput[index] : 0) : Number(null)
          ) : otherPurchaseInput.map(item=>!isNaN(item) ? Number(item) : 0)
      const expensesTotalClassification0ValueInput:number = Number(sumTotalOfArray(
        expensesTotalClassification0Input
      ));

      const expensesTotalClassification1Input:any =
      listExpensesInput.length > 0 ?
      listExpensesInput[0].map(
          (item, index) => {
            return listExpensesInput.reduce(
              (
                accumulator:any,
                currentValue:any,
                currentIndex:number
              ) => {
                if (
                  state.monthlyListDataInfo.listExpenses[currentIndex] &&
                  state.monthlyListDataInfo.listExpenses[currentIndex][2] === 1
                ) {
                  return currentValue[index] !== null ? accumulator + (!isNaN(currentValue[index]) ? Number(currentValue[index]) : 0) : null;
                } else {
                  return accumulator !== null ? Number(accumulator) : 0;
                }
              },
              0
            );
          }
        ) : Array(state.generalData.salesBudgetMonthly.length).fill(0)
      const expensesTotalClassification1InputValue = Number(sumTotalOfArray(
        expensesTotalClassification1Input
      ));

      // Start FLARO合計 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
      const listFLAROCostTotalInput =
        listFLACostTotalInput &&
        listLandRentTotalInput &&
        listExpensesTotalInputArray &&
        listFLACostTotalInput.map((item, index) => {
          return item !== null
            ? item +
                listLandRentTotalInput[index] +
                listExpensesTotalInputArray[index]
            : null;
        });
      const FLAROCostTotalInput =
        FLACostTotalInput + landRentTotalInput + listExpensesTotalInput;

      const listFLAROCostTotalRateInput =
        listFLAROCostTotalInput &&
        listFLAROCostTotalInput.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(
                    getRatioLabel(item, listAdvertisingTotalInputArray[index])
                  )
                ) || 0
              )
            : null;
        });
      const FLAROCostTotalRateInput = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(FLAROCostTotalInput, salesBudgetTotalInput))
        ) || 0
      );

      const listFLAROCostTotalInputArray = [
        "",
        FLAROCostTotalInput,
        listFLAROCostTotalInput
      ];
      const listFLAROCostTotalRateInputArray = [
        "",
        FLAROCostTotalRateInput,
        listFLAROCostTotalRateInput
      ];
      // End FLARO合計 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // Start 営業利益 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
      const listOperatingIncomeCostTotalInput =
        listFLAROCostTotalInput &&
        listFLAROCostTotalInput.map((item, index) => {
          return item !== null
            ? Number(listAdvertisingTotalInputArray[index]) - item
            : null;
        });
      const operatingIncomeCostTotalInput =
        salesBudgetTotalInput - FLAROCostTotalInput;

      const listOperatingIncomeCostTotalRateInput =
        listOperatingIncomeCostTotalInput &&
        listOperatingIncomeCostTotalInput.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(
                    getRatioLabel(item, listAdvertisingTotalInputArray[index])
                  )
                ) || 0
              )
            : null;
        });
      const operatingIncomeCostTotalRateInput = formatterPercent.format(
        numberFormater(
          Number(
            getRatioLabel(operatingIncomeCostTotalInput, salesBudgetTotalInput)
          )
        ) || 0
      );

      const listOperatingIncomeCostTotalInputArray = [
        "",
        operatingIncomeCostTotalInput,
        listOperatingIncomeCostTotalInput
      ];
      const listOperatingIncomeCostTotalRateInputArray = [
        "",
        operatingIncomeCostTotalRateInput,
        listOperatingIncomeCostTotalRateInput
      ];
      // End 営業利益 -----------------------------------------------------------------------------------------------------------------------------------------------------------------

      // Start 客単価 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
      const initListTotalPerCustomerPrice =
        state.monthlyListData.listTotalPerCustomerPrice;
      const listPerCustomerPriceTotalInput = initListTotalPerCustomerPrice.map(
        (item, index) => {
          if (response.id === `listTotalPerCustomerPrice${index}`) {
            return removeComma(response.value);
          } else {
            return item;
          }
        }
      );

      const perCustomerPriceTotal_GuestCnt_Input = initListTotalPerCustomerPrice.map(
        (item, index) => {
          return Number(
            getRatioLabel(listAdvertisingTotalInputArray[index], item)
          );
        }
      );

      const perCustomerPriceTotal_GuestCnt_Total_Input = sumTotalOfArray(
        perCustomerPriceTotal_GuestCnt_Input
      );

      const perCustomerPriceTotalInput = sumTotalOfArray(
        listPerCustomerPriceTotalInput
      );

      const listTotalPerCustomerPriceInputArray = [
        "",
        perCustomerPriceTotalInput,
        listPerCustomerPriceTotalInput
      ];
      // End 客単価 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      //客数
      const listGuestCntTotalInput =
        listPerCustomerPriceTotalInput &&
        listPerCustomerPriceTotalInput.map((item, index) => {
          return !isNaN(item) && Number(item) !== 0 ? Number(getRatioLabel(listAdvertisingTotalInputArray[index], item)) : 0
          ;
        });
      const guestCntTotalInput = sumTotalOfArray(
        listGuestCntTotalInput
      );
      const listGuestCntTotalInputArray = [
        "",
        guestCntTotalInput,
        listGuestCntTotalInput
      ];
      // End 客数  -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      //getTurnoverRateTotalItems 回転率
      const listTurnoverRateTotalInput =
        orgCode !== "all"
          ? listGuestCntTotalInput.map((item, index) => {
              return numberFormater(
                Number(
                  getRatioLabel(
                    item,
                    state.monthlyListData.listNumberOfSeat[index]
                  )
                )
              ).toFixed(2);
            })
          : state.monthlyListData.listGuestCntTotal.map(
              (item, index) => {
                return numberFormater(
                  Number(
                    getRatioLabel(
                      item,
                      state.monthlyListData.listNumberOfSeat[index]
                    )
                  )
                ).toFixed(2);
              }
            );
      const turnoverRateTotalInput = "-";
      const listTurnoverRateTotalInputArray = [
        "",
        turnoverRateTotalInput,
        listTurnoverRateTotalInput
      ];
      // End getTurnoverRateTotalItems 回転率  -----------------------------------------------------------------------------------------------------------------------------------------------------------------
      // getTotalAllEvenMarginalProfit 限界利益	 額
      const listEvenMarginalProfitTotalInput =
        listAdvertisingTotalInputArray &&
        listAdvertisingTotalInputArray.map((item, index) => {
          return item !== null
            ? item -
                (!isNaN(listCostTotalFoodAndDrinkInput[index]) ? Number(listCostTotalFoodAndDrinkInput[index]) : 0) -
                (!isNaN(partLaborCostInput[index]) ? Number(partLaborCostInput[index]) : 0) -
                (!isNaN(expensesTotalClassification0Input[index]) ? Number(expensesTotalClassification0Input[index]) : 0)
            : null;
        });
      const evenMarginalProfitTotalInput = sumTotalOfArray(
        listEvenMarginalProfitTotalInput
      );
      const listEvenMarginalProfitTotalInputArray = [
        "",
        evenMarginalProfitTotalInput,
        listEvenMarginalProfitTotalInput
      ];
      // marginalProfitRate 限界利益	率
      const listMarginalProfitRateTotalInput =
        listEvenMarginalProfitTotalInput &&
        listEvenMarginalProfitTotalInput.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(
                    getRatioLabel(item, listAdvertisingTotalInputArray[index])
                  )
                ) || 0
              )
            : null;
        });
      const marginalProfitRateTotalInput = formatterPercent.format(
        numberFormater(
          Number(
            getRatioLabel(evenMarginalProfitTotalInput, salesBudgetTotalInput)
          )
        ) || 0
      );
      const listMarginalProfitRateInputArray = [
        "",
        marginalProfitRateTotalInput,
        listMarginalProfitRateTotalInput
      ];
      // contributionMarginTotal 貢献利益	額
      const listContributionMarginTotalInput =
        listEvenMarginalProfitTotalInput &&
        listEvenMarginalProfitTotalInput.map((item, index) => {
          return item !== null
            ? item -
                (!isNaN(employeeLaborCostInput[index]) ? Number(employeeLaborCostInput[index]) : 0 ) -
                (!isNaN(listAdvertisingExpenseInputTotal[index]) ? Number(listAdvertisingExpenseInputTotal[index]) : 0 ) -
                (!isNaN(listLandRentTotalInput[index]) ? Number(listLandRentTotalInput[index]) : 0 ) -
                (!isNaN(expensesTotalClassification1Input[index]) ? Number(expensesTotalClassification1Input[index]) : 0 )
            : null;
        });
      const contributionMarginTotalInput = sumTotalOfArray(
        listContributionMarginTotalInput
      );
      const listContributionMarginTotalInputArray = [
        "",
        contributionMarginTotalInput,
        listContributionMarginTotalInput
      ];
      // contributionMarginTotalRate 貢献利益	率
      const listContributionMarginTotalInputRate =
        listContributionMarginTotalInput &&
        listContributionMarginTotalInput.map((item, index) => {
          return item !== null
            ? formatterPercent.format(
                numberFormater(
                  Number(
                    getRatioLabel(item, listAdvertisingTotalInputArray[index])
                  )
                ) || 0
              )
            : null;
        });
      const contributionMarginTotalInputRate = formatterPercent.format(
        numberFormater(
          Number(
            getRatioLabel(contributionMarginTotalInput, salesBudgetTotalInput)
          )
        ) || 0
      );
      const listContributionMarginTotalInputRateArray = [
        "",
        contributionMarginTotalInputRate,
        listContributionMarginTotalInputRate
      ];
      // 損益分岐点	売上 bePointSales
      const listBePointSalesTotalInput =
        listEvenMarginalProfitTotalInput &&
        listEvenMarginalProfitTotalInput.map((item, index) => {
          return item !== null
            ? getRatioLabel(
                    (!isNaN(employeeLaborCostInput[index]) ? Number(employeeLaborCostInput[index]) : 0) +
                    (!isNaN(listAdvertisingExpenseInputTotal[index]) ? Number(listAdvertisingExpenseInputTotal[index]) : 0)+
                    (!isNaN(listLandRentTotalInput[index]) ? Number(listLandRentTotalInput[index]) : 0) +
                    (!isNaN(expensesTotalClassification1Input[index]) ? Number(expensesTotalClassification1Input[index]) : 0),
                  getRatioLabel(!isNaN(item) ? Number(item) : 0, listAdvertisingTotalInputArray[index])
                )

            : null;
        });
      const bePointSalesTotalInput = formatNumberMinus(
        getRatioLabel(
          Number(employeeLaborCostInputRowTotal) +
            Number(advertisingExpenseTotalInputValue) +
            landRentTotalInput +
            expensesTotalClassification1InputValue,
          getRatioLabel(evenMarginalProfitTotalInput, salesBudgetTotalInput)
        )
      );
      const listBePointSalesInputArray = [
        "",
        bePointSalesTotalInput,
        listBePointSalesTotalInput
      ];
      // 損益分岐点	客数 bePointGuestCnt
      const listBePointGuestCntTotalInput =
        listBePointSalesTotalInput &&
        listBePointSalesTotalInput.map((item, index) => {
          return item !== null
            ? formatNumberMinus(getRatioLabel((!isNaN(item) ? Number(item) : 0), (!isNaN(listPerCustomerPriceTotalInput[index]) ? Number(listPerCustomerPriceTotalInput[index]) : 0)))
            : null;
        });
      const bePointGuestCntTotalInput = formatNumberMinus(
        getRatioLabel(bePointSalesTotalInput, perCustomerPriceTotalInput)
      );
      const listBePointGuestCntInputArray = [
        "",
        bePointGuestCntTotalInput,
        listBePointGuestCntTotalInput
      ];
      // 人件費率 getTotalPersonRateItems	合計 laborCostTotalEmployeeAndPartLabor
      const listPersonRateTotalInput = listLaborCostTotalInput.map(
        (item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(getRatioLabel(!isNaN(item) ? Number(item) : 0, listAdvertisingTotalInputArray[index]))
            ) || 0
          );
        }
      );
      const personRateTotalInput = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(laborCostTotalInput, salesBudgetTotalInput))
        ) || 0
      );
      const listPersonRateTotalInputArray = [
        "",
        personRateTotalInput,
        listPersonRateTotalInput
      ];
      // 人件費率 getEmployeePersonRate 社員
      const listEmployeePersonRateTotalInput = employeeLaborCostInput.map(
        (item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(getRatioLabel(!isNaN(item) ? Number(item) : 0, listAdvertisingTotalInputArray[index]))
            ) || 0
          );
        }
      );
      const employeePersonRateTotalInput = formatterPercent.format(
        numberFormater(
          Number(
            getRatioLabel(employeeLaborCostInputRowTotal, salesBudgetTotalInput)
          )
        ) || 0
      );
      const listEmployeePersonRateInputArray = [
        "",
        employeePersonRateTotalInput,
        listEmployeePersonRateTotalInput
      ];
      // 人件費率 getPartPersonRate アルバイト
      const listPartPersonRateTotalInput = partLaborCostInput.map(
        (item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(getRatioLabel(!isNaN(item) ? Number(item) : 0, listAdvertisingTotalInputArray[index]))
            ) || 0
          );
        }
      );
      const partPersonRateTotalInput = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(partLaborCostRowTotal, salesBudgetTotalInput))
        ) || 0
      );
      const listPartPersonRateInputArray = [
        "",
        partPersonRateTotalInput,
        listPartPersonRateTotalInput
      ];
      // laborShare 労働分配率
      const listLaborShareTotalInput = listLaborCostTotalInput.map(
        (item, index) => {
          return formatterPercent.format(
            numberFormater(
              Number(getRatioLabel(!isNaN(item) ? Number(item) : 0, listGrossProfitTotalInput[index]))
            )
          );
        }
      );
      const laborShareTotalInput = formatterPercent.format(
        numberFormater(
          Number(getRatioLabel(laborCostTotalInput, grossProfitTotalInputValue))
        )
      );

      const listLaborShareInputArray = [
        "",
        laborShareTotalInput,
        listLaborShareTotalInput
      ];

      //-----------------------------------------------------------------------------------------------------------
      cloneData.monthlyListData = {
        ...cloneData.monthlyListData,
        // 売上高
        listAdvertising: [...listAdvertisingInput],
        salesBudgetAdvertising: [...salesBudgetAdvertisingInput],
        salesBudgetFree: [...salesBudgetFreeInput],
        salesBudgetTotal: [...listAdvertisingTotalInputArray],
        listAdvertisingRow: [...listAdvertisingArrayRow],
        salesBudgetFreeRow: [...salesBudgetFreeRow],
        salesBudgetTotalRow: [...salesBudgetTotalRow],
        // F（食材費）
        foodCost: [...foodCostInput],
        foodCostRow: [...foodCostRow],
        drinkCost: [...drinkCostInput],
        drinkCostRow: [...drinkCostRow],
        costTotal: [...listCostTotalFoodAndDrinkInput],
        costTotalRow: [...listCostTotalFoodAndDrinkInputRow],
        costTotalRate: [...listCostTotalFoodAndDrinkRateInput],
        costTotalRowRate: [...listCostTotalFoodAndDrinkRateInputRow],
        // grossProfitTotal
        grossProfitTotal: [...listGrossProfitTotalInput],
        // months in L（人件費）
        employeeLaborCost: [...employeeLaborCostInput],
        employeeLaborCostRow: [...employeeLaborCostInputRow],
        partLaborCost: [...partLaborCostInput],
        partLaborCostRow: [...partLaborCostRow],
        laborCostTotal: [...listLaborCostTotalInput],
        laborCostTotalRow: [...listLaborCostTotalInputRow],
        laborCostTotalRate: [...listLaborCostTotalRateInput],
        laborCostTotalRowRate: [...listLaborCostTotalInputRateRow],
        // A（広告費）
        listAdvertisingExpense: [...listAdvertisingExpenseInput],
        listAdvertisingExpenseTotal: [...listAdvertisingExpenseInputTotal],
        listAdvertisingExpenseTotalRate: [
          ...listAdvertisingExpenseTotalRateInput
        ],
        listAdvertisingExpenseRow: [...listAdvertisingExpenseArrayRow],
        listAdvertisingExpenseTotalRow: [
          ...listAdvertisingExpenseInputTotalRow
        ],
        listAdvertisingExpenseTotalRowRate: [
          ...listAdvertisingExpenseInputTotalRateRow
        ],
        // FLA合計
        listFLACost: [...listFLACostTotalInput],
        listFLACostRate: [...listFLACostTotalRateInput],
        listFLACostRow: [...listFLACostTotalInputArray],
        listFLACostRowRate: [...listFLACostTotalRateInputArray],
        // 店舗損益 ProfitLoss
        listProfitLossCost: [...listProfitLossCostTotalInput],
        listProfitLossCostRate: [...listProfitLossCostTotalRateInput],
        listProfitLossCostRow: [...listProfitLossCostTotalInputArray],
        listProfitLossCostRowRate: [...listProfitLossCostTotalRateInputArray],
        // months in R（家賃）
        rentCost: [...rentCostInput],
        rentCostRow: [...rentCostInputRow],
        commonServiceFeeCost: [...commonServiceFeeCostInput],
        commonServiceFeeCostRow: [...commonServiceFeeCostRow],
        landRentTotalCost: [...listLandRentTotalInput],
        landRentTotalCostRow: [...listLandRentTotalInputRow],
        landRentTotalRate: [...listLandRentTotalRateInput],
        landRentTotalRowRate: [...listLandRentTotalInputRateRow],
        // in O（その他経費)
        ////classification 0 = 変 , 1 = 固
        listClassification0: [...expensesTotalClassification0Input],
        listClassification1: [...expensesTotalClassification1Input],
        ////
        listExpenses: [...listExpensesInput],
        listExpensesRow: [...listExpensesArrayRow],
        otherPurchase: [...otherPurchaseInput],
        otherPurchaseRow: [...otherPurchaseRow],
        listExpensesTotal: [...listExpensesTotalInputArray],
        listExpensesTotalRow: [...listExpensesTotalInputRow],
        listExpensesTotalRate: [...listExpensesTotalInputRateArray],
        listExpensesTotalRowRate: [...listExpensesRateInputRow],
        // FLARO合計
        listFLAROCost: [...listFLAROCostTotalInput],
        listFLAROCostRate: [...listFLAROCostTotalRateInput],
        listFLAROCostRow: [...listFLAROCostTotalInputArray],
        listFLAROCostRowRate: [...listFLAROCostTotalRateInputArray],
        // 営業利益 Operating Income
        listOperatingIncomeCost: [...listOperatingIncomeCostTotalInput],
        listOperatingIncomeCostRate: [...listOperatingIncomeCostTotalRateInput],
        listOperatingIncomeCostRow: [...listOperatingIncomeCostTotalInputArray],
        listOperatingIncomeCostRowRate: [
          ...listOperatingIncomeCostTotalRateInputArray
        ],
        //-----------------------------------------------SecondTable---------------------------------------------------
        // totalPerCustomerPrice 客単価
        listTotalPerCustomerPrice: [...listPerCustomerPriceTotalInput],
        listTotalPerCustomerPriceRow: [...listTotalPerCustomerPriceInputArray],
        // // getGuestCntTotal客数
        listGuestCntTotal: [...listGuestCntTotalInput],
        listGuestCntTotalRow: [...listGuestCntTotalInputArray],
        // // getNumberOfSeat - for calculation
        // listNumberOfSeat: [],
        // getTurnoverRateTotalItems 回転率
        listTurnoverRateTotal: [...listTurnoverRateTotalInput],
        listTurnoverRateTotalRow: [...listTurnoverRateTotalInputArray],
        // // getOtherVariableCosts
        // listOtherVariableCosts: [],
        // getTotalAllEvenMarginalProfit 限界利益	 額
        listEvenMarginalProfitTotal: [...listEvenMarginalProfitTotalInput],
        listEvenMarginalProfitTotalRow: [
          ...listEvenMarginalProfitTotalInputArray
        ],
        // marginalProfitRate 限界利益	率
        listMarginalprofitRate: [...listMarginalProfitRateTotalInput],
        listMarginalprofitRateRow: [...listMarginalProfitRateInputArray],
        // contributionMarginTotal 貢献利益	額
        listContributionMarginTotal: [...listContributionMarginTotalInput],
        listContributionMarginTotalRow: [
          ...listContributionMarginTotalInputArray
        ],
        // contributionMarginTotalRate 貢献利益	率
        listContributionMarginTotalRate: [
          ...listContributionMarginTotalInputRate
        ],
        listContributionMarginTotalRateRow: [
          ...listContributionMarginTotalInputRateArray
        ],
        // 損益分岐点	売上 bePointSales
        listBePointSales: [...listBePointSalesTotalInput],
        listBePointSalesRow: [...listBePointSalesInputArray],
        // 損益分岐点	客数 bePointGuestCnt
        listBePointGuestCnt: [...listBePointGuestCntTotalInput],
        listBePointGuestCntRow: [...listBePointGuestCntInputArray],
        // 人件費率 getTotalPersonRateItems	合計
        listPersonRateTotal: [...listPersonRateTotalInput],
        listPersonRateTotalRow: [...listPersonRateTotalInputArray],
        // 人件費率 getEmployeePersonRate 社員
        listEmployeePersonRate: [...listEmployeePersonRateTotalInput],
        listEmployeePersonRateRow: [...listEmployeePersonRateInputArray],
        // 人件費率 getPartPersonRate アルバイト
        listPartPersonRate: [...listPartPersonRateTotalInput],
        listPartPersonRateRow: [...listPartPersonRateInputArray],
        // laborShare 労働分配率
        listLaborShare: [...listLaborShareTotalInput],
        listLaborShareRow: [...listLaborShareInputArray]
      };
      return cloneData;
    case actionTypes.ENABLE_MONTH_LINK:
      cloneData.generalData = {
        ...cloneData.generalData,
        salesBudgetMonthly: cloneData.generalData.salesBudgetMonthly.map((sbm: SalesBudgetMonthly) => ({
          ...sbm,
          existsBudget: true
        }))
      };
      return cloneData;
    default:
      throw new Error();
  }
};
