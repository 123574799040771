import { useEffect, useState } from 'react';
import { StaffInfo } from "components/pages/master/shift/type";
import { SessionStorageKey } from 'utility/constants';

const usePhotoPath = () => {
  const [myPhotoPath, setMyPhotoPath] = useState('');

  useEffect(() => {
    setMyPhotoPath(
      sessionStorage.getItem(SessionStorageKey.LoginUser.PHOTO_PATH) || ''
    );
  }, []);

  return {
    myPhotoPath,
  };
};

export const photoPath = (staff: StaffInfo) => {
  const domainUrl = process.env.REACT_APP_PHOTO_BASE_URL || "";

  return `${domainUrl}/${sessionStorage.getItem(SessionStorageKey.LoginUser.COMPANY_CODE)}/${staff.staffCode}/${staff.nameplate || "1.jpg"}?${new Date().getTime()}`;

};

export const photoPathByStaffCode = (staffCode: string, nameplate?: string, companyCode?: string ) => {
  const domainUrl = process.env.REACT_APP_PHOTO_BASE_URL || "";

  return `${domainUrl}/${companyCode || sessionStorage.getItem(SessionStorageKey.LoginUser.COMPANY_CODE)}/${staffCode}/${nameplate || "1.jpg"}?${new Date().getTime()}`;

};

export default usePhotoPath;
