/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

const style = (color: string, borderColor: string) => css({
  margin: '0',
  width: '32px',
  height: '32px',
  padding: '5.5px', // (32 - 20(Icon)) / 2 - 0.5(border)
  border: `0.5px solid ${borderColor}`,
  borderRadius: '50%',
  backgroundColor: color,
  cursor: 'pointer',
  outline: 'none',
  fontFamily: 'inherit',
});

interface CircleButtonProps {
  onClick: () => void;
  color: string;
  borderColor: string;
}

const CircleButton: React.FC<CircleButtonProps> = ({
  children, onClick, color, borderColor,
}) => (
  <button type="button" css={style(color, borderColor)} onClick={onClick}>
    {children}
  </button>
);

export default CircleButton;
