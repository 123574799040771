/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import FormContents from 'components/atoms/Form/FormContents';
import { Container, Row, Col } from 'react-grid-system';
// import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import moment from 'moment';
import BodyText from 'components/atoms/BodyText';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import { PrimaryButton } from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import ShiftPatternDetails from 'components/organismos/master/labor/shiftPattern/ShiftPatternDetails';
import TextForm from 'components/molecules/TextForm';
import FormField from 'components/atoms/Form/FormField';
import BlockUI from 'components/molecules/BlockUi';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { isMobileOnly } from 'react-device-detect';
import ToastModal from 'components/organismos/ToastModal';
import { useStampModificationAddForm } from './hooks';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';


enum ActionType {
  Add = 0,
  Modify = 1,
  Delete = 2,
}

const StampModificationPage: React.FC = () => {
  const history = useHistory();
  const {
    formik,
    // orgTreesOptions,
    staffName,
    attendBusinessOptions,
    targetDate,
    toastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmModalOpen,
    closeConfirmModal,
    stampList,
    setStampList,
    listTimes,
    isLoading,
    targetDateFrom,
    targetDateTo,
    orgCode,
    setOrgCode,
  } = useStampModificationAddForm();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <SidebarTemplate pageTitle={formik.values.achievementId ? '打刻修正申請' : '打刻追加申請'}>
      <BlockUI blocking={isLoading}>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <Container fluid={true}>
              <FormContents>
                <Row>
                  <Col md={2} xs={12}>
                    <BodyText>対象日</BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <BodyText>
                      {moment(`${targetDate}`).format('YYYY年MM月DD日(ddd)')}
                    </BodyText>
                  </Col>
                </Row>
                <Row>
                  <Col md={2} xs={12}>
                    <BodyText>スタッフ</BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <BodyText>
                      {staffName}
                    </BodyText>
                  </Col>
                </Row>
                <Row>
                  {
                      // (formik.values.achievementId === '') && (
                      //   <Col md={12} xs={12}>
                      //     <VerticalLabelSelectForm
                      //       label="出勤店舗"
                      //       name="orgCode"
                      //       value={String(formik.values.orgCode)}
                      //       setValue={(val: string) => formik.setFieldValue('orgCode', val)}
                      //       options={orgTreesOptions}
                      //       required={true}
                      //     />
                      //   </Col>
                      // )

                      (formik.values.achievementId === '') && (
                        <Col md={12} xs={12}>
                          <OrganizationFilteredTree
                            functionType={2}
                            targetdayForm={moment(targetDate).toDate()}
                            targetdayTo={moment(targetDate).toDate()}
                            orgCallback={(args: string | Array<string>) => {
                              setOrgCode(String(args));
                              formik.setFieldValue('orgCode', String(args));
                            }}
                            orgNameCallback={(args: string | Array<string>) => {
                              formik.setFieldValue('orgName', String(args));
                            }}
                            initOrgValue={String(formik.values.orgCode)}
                            staffCode=""
                            notSetLoginStaffCode={true}
                            orgLabel="出勤店舗"
                            addAllItem={false}
                            isNotShowCatagoryMobie={true}
                          />
                        </Col>
                      )


                    }

                  {
                      (formik.values.achievementId !== '') && (
                        <Col xs={12}>
                          <Row>
                            <Col md={2} xs={12}>
                              <BodyText>出勤店舗</BodyText>
                            </Col>
                            <Col md={6} xs={12}>
                              <BodyText>
                                {formik.values.orgName}
                              </BodyText>
                            </Col>
                          </Row>
                          <Row>&nbsp;</Row>
                        </Col>
                      )
                    }
                </Row>
                { formik.values.displayAutoBreak  && (
                  <Row>
                    <Col style={{ display: 'flex', alignItems: 'center' }} md={2}>
                      <BodyText>自動休憩</BodyText>
                    </Col>
                    <Col md={10}>
                      <Row>
                        <Col md={4} xs={12}>
                          <VerticalLabelSelectForm
                            label=""
                            name="disableAutoRecess"
                            value={String(formik.values.disableAutoRecess || 0)}
                            setValue={(val: string) => {
                              formik.setFieldValue('disableAutoRecess', val)
                            }}
                            options={[
                              {
                                label: "有効",
                                value: "0",
                              },
                              {
                                label: "無効",
                                value: "1",
                              }
                            ]}
                            disable={formik.values.applicationStatus === 0}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={12} xs={12}>
                    <ShiftPatternDetails
                      shiftPatternDetailsList={stampList}
                      setShiftPatternDetailsList={setStampList}
                      attendBusinessOptions={attendBusinessOptions}
                      listTimes={listTimes}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xs={12}>
                    <FormField>
                      <TextForm
                        name="applicationReason"
                        label="申請理由"
                        value={String(formik.values.applicationReason)}
                        required={true}
                        onChange={formik.handleChange}
                        errorMsg={formik.errors.applicationReason}
                      />
                    </FormField>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <FormSubmitArea>
                      {
                        (!(formik.values.modifiyStatus === 1
                           && formik.values.applicationStatus === 0)
                           && roleScreen && (roleScreen.editable === 1 || roleScreen.length === 0)) && (
                           <div style={{ marginRight: '12px' }}>
                             <PrimaryButton
                               ghost={false}
                               text="申請"
                               onClick={() => {
                                 formik.handleSubmit();
                                 formik.setFieldValue('buttonType', ActionType.Add);
                               }}
                             />
                           </div>
                        )
                      }
                      {
                        ((formik.values.applicationStatus === 0) 
                        && roleScreen && (roleScreen.editable === 1 || roleScreen.length === 0)) && (
                          <div style={{ marginRight: '12px' }}>
                            <PrimaryButton
                              ghost={false}
                              text="申請キャンセル"
                              onClick={() => {
                                formik.handleSubmit();
                                formik.setFieldValue('buttonType', ActionType.Modify);
                              }}
                            />
                          </div>
                        )
                      }

                      {
                        (formik.values.deleteApplicationAvailable && roleScreen && (roleScreen.editable === 1 || roleScreen.length === 0)) && (
                          <div style={{ marginRight: '12px' }}>
                            <PrimaryButton
                              ghost={false}
                              text="削除申請"
                              onClick={() => {
                                formik.handleSubmit();
                                formik.setFieldValue('buttonType', ActionType.Delete);
                              }}
                            />
                          </div>
                        )
                      }
                      <PrimaryButton
                        onClick={() => history.push(sessionStorage.getItem('attendStampList.returnDestination')! || '')}
                        text="戻る"
                        ghost={true}
                      />
                    </FormSubmitArea>
                  </Col>
                </Row>
              </FormContents>
            </Container>
          </form>
        </div>

        <ToastModal
          open={toastModalOpen}
          closeHandler={() => setToastModalOpen(false)}
          title={toastMessage}
        />

        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={() => {
            formik.handleSubmit();
          }}
          title="確認メッセージ"
          // eslint-disable-next-line no-nested-ternary
          content={formik.values.buttonType === 0 ? '申請します。よろしいですか？'
            : formik.values.buttonType === 1 ? '申請をキャンセルします。よろしいですか？' : '削除申請をします。よろしいですか？'}
          // eslint-disable-next-line no-nested-ternary
          submitText={formik.values.buttonType === 0 ? '申請'
            : formik.values.buttonType === 1 ? '申請キャンセル' : '削除申請'}
        />

      </BlockUI>
    </SidebarTemplate>
  );
};

export default StampModificationPage;
