/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import IconTooltip from 'components/molecules/IconTooltip';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import AccordionTableWrapper from 'components/molecules/AccordionTableWrapper/index';

const TableSalesPlanDaily: React.FC<{
  formik: any
  getRows: any,
  handleChangeSelect: any,
  listBiasSettings: any,
  arrayIndexHoliday: any
}> = ({
  getRows,
  formik,
  handleChangeSelect,
  listBiasSettings,
  arrayIndexHoliday,
}) => {
  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatterPercent = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'percent',
  });

  const eventScroll = (e: { target: any }) => {
    const { target } = e;
    const contentRight = document.getElementById('daily_layout_sale_right');
    const contentRight2 = document.getElementById('daily_layout_sale_right_2');
    const contentRight3 = document.getElementById('layout_sale_right_3');
    const contentRight4 = document.getElementById('layout_sale_right_4');
    const contentRight5 = document.getElementById('layout_sale_right_5');
    const contentRightTableBottom = document.getElementById('layout_sale_right_table_bottom');

    if (contentRight) {
      contentRight.scrollLeft = target.scrollLeft;
    }
    if (contentRight2) {
      contentRight2.scrollLeft = target.scrollLeft;
    }

    if (contentRight3) {
      contentRight3.scrollLeft = target.scrollLeft;
    }

    if (contentRight4) {
      contentRight4.scrollLeft = target.scrollLeft;
    }

    if (contentRight5) {
      contentRight5.scrollLeft = target.scrollLeft;
    }
    if (contentRightTableBottom) {
      contentRightTableBottom.scrollLeft = target.scrollLeft;
    }
  };

  const dateSelectType = (e: any) => {
    const { target } = e;
    formik.setFieldValue(`saleBudgetListData[${target.id}][20]`, Number(target.value));
    handleChangeSelect();
  };
  const isRetina = window.devicePixelRatio > 1;

  const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num) || !isFinite(num)) {
      return 0;
    }
    return num;
  };

  let checkWidthIPad = false;
  const mq = window.matchMedia('(max-width: 1024px)');
  if (mq.matches) {
    checkWidthIPad = true;
  } else {
    checkWidthIPad = false;
  }
  // Accordion for 売上高
  const [isAdvertisingSaleDataExpanded, setIsAdvertisingSaleDataExpand] = useState(false);
  // Accordion for F（食材費）
  const [isIngredientCostExpanded, setIsIngredientCostExpand] = useState(false);
  // Accordion for L（人件費）
  const [isLaborCostExpanded, setIsLaborCostExpand] = useState(false);
  // Accordion for A（広告費）
  const [isAdvertisingCostExpanded, setIsAdvertisingCostExpand] = useState(false);
  // Accordion for R（家賃）
  const [isRentExpanded, setIsRentExpand] = useState(false);
  // Accordion for O（その他経費）
  const [isOtherExpensesExpanded, setIsOtherExpensesExpand] = useState(false);

  let totalExpenseOther = 0;

  return (
    <div style={{ paddingBottom: '40px' }}>
      {/* ステータス table header */}
      <div className="sales_plan_daily-settings__top">
        <div className="layout_sale_left" style={{ width: checkWidthIPad ? '340px' : '345px',minWidth: checkWidthIPad ? '340px' : '345px', height: '100%', fontSize:'14px' }}>
          <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '100%', height: '100%' }}>
            <tbody>
              <tr>
                <td className="text-center active" style={{ height: '45px' }} colSpan={7}>ステータス</td>
              </tr>
              <tr>
                <td className="text-center active" style={{ height: checkWidthIPad ? '60px' : '56px' }} colSpan={5}>項目</td>
                <td className="text-center active column_change" style={{ height: checkWidthIPad ? '60px' : '56px' }} colSpan={1}>固変</td>
                <td className="text-center active width-110" rowSpan={1} style={{ width: '89px' }}>合計</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="layout_sale_right" style={!isRetina ? { overflow: 'auto', width: checkWidthIPad ? 'calc(100% - 337px)' : 'calc(100% - 342px)' } : { overflow: 'auto', width: checkWidthIPad ? 'calc(100% - 337px)' : 'calc(100% - 342px)' }}>
          <div className="table-responsive no-padding layout-sale-right" id="daily_layout_sale_right" style={!isRetina ? { maxWidth: '100%', overflow: 'hidden', marginRight: '47px' } : { maxWidth: '100%', overflow: 'hidden', marginRight: '30px' }}>
            <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '3200px' , fontSize:'14px'}}>
              <thead>
                <tr>
                  {
                      formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                        <th key={index} className="text-center ">
                          <select
                            className="form-control input-ms date-select-type"
                            id={index}
                            key={index}
                            onChange={dateSelectType}
                          >
                            <option value="0" selected={saleBudgetData[20] === 0}> 繁忙 </option>
                            <option value="1" selected={saleBudgetData[20] === 1}> 通常 </option>
                            <option value="2" selected={saleBudgetData[20] === 2}> 閑散 </option>
                            <option value="3" selected={saleBudgetData[20] === 3}> 定休 </option>
                          </select>
                        </th>
                      ))
                    }
                </tr>
                <tr>
                  {
                      formik.values.salesPlanDailyDateData.map((date: any, index: any) => (
                        <th key={index} className={date.weekday === '土' ? 'saturday text-center active ' : date.weekday === '日' || date.weekday === '祝' ? 'text-center active red-day ' : 'text-center active '}>
                          {moment(date.day, 'YYYY-MM-DD').format('DD')}
                        </th>
                      ))
                    }
                </tr>
                <tr>
                  {
                      formik.values.salesPlanDailyDateData.map((date: any, index: any) => (
                        <th key={index} className={date.weekday === '土' ? 'saturday text-center active ' : date.weekday === '日' || date.weekday === '祝' ? 'text-center active red-day ' : 'text-center active '}>
                          {date.weekday}
                        </th>
                      ))
                    }
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div />
      {/* ステータス table header */}

      {/* Table Body */}
      <div
        className="sales_plan_daily-settings__bottom"
        style={{
          display: 'flex', flexDirection: 'column', maxHeight: '600px', overflow: 'auto', fontSize:'14px'
        }}
      >
        {/* First Table */}
        <div style={{ display: 'inline-flex', width: '100%', marginBottom: '37px' }}>
          {/* Table Left */}
          <div className="no-padding layout_sale_left" id="daily_layout_sale_left" style={{ width: '353px',minWidth:'345px' }}>
            <div className="box-body no-padding">
              <div className="table-responsive no-padding">
                <table className="table table-bordered table-striped table-condensed text-nowrap">
                  <tbody>
                    {/* Table Top  */}
                    {/* Start 売上高	 */}
                    <AccordionTableWrapper
                      isExpanded={isAdvertisingSaleDataExpanded}
                      setStateAccordion={setIsAdvertisingSaleDataExpand}
                    >
                      <tr>
                        <td className="text-left active lightOrange p-left" colSpan={4}>売上高</td>
                        <td colSpan={1} className="column_change text-center" />
                        <td colSpan={1} className="text-right active width-110 ">
                          <span id="sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow" />
                        </td>
                        {/* <td className="text-right active width-110 ">
                        <span id="sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow">{formatter.format(numberFormater(getRows.getSumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow) || 0)}</span>
                      </td> */}
                      </tr>
                      {/* <tr>
                      <td colSpan={1} className="empty_column"></td>
                      <td className="text-center active" colSpan={3}>広告</td>
                      <td colSpan={1}></td>
                      <td colSpan={1}></td>
                    </tr> */}
                      {
                        formik.values.advertisingSaleData ? formik.values.advertisingSaleData.map((data: any, index: any) => {
                          if (data[1] !== 'total') {
                            return (
                              <tr key={index}>
                                <td colSpan={1} className="empty_column lightOrange" />
                                <td className="text-right active p-right" colSpan={3}>{data[1]}</td>
                                <td colSpan={1} />
                                <td colSpan={1} className="text-right active width-110">
                                  <span id={`sumOfAdvertisingSale${data[0]}`}>{formik.values.saleBudgetAdvertisingMonthData.length > 0 && formik.values.saleBudgetAdvertisingMonthData[index] ? formatter.format(numberFormater(formik.values.saleBudgetAdvertisingMonthData[index][3])) : 0}</span>
                                </td>
                              </tr>
                            );
                          }
                        })
                          : ''
                    }
                      <tr>
                        <td colSpan={1} className="empty_column lightOrange" />
                        <td className="text-right active p-right" colSpan={3}>
                          <label className="control-label">フリー</label>
                        </td>
                        <td colSpan={1} />
                        <td colSpan={1}>
                          <span>
                            {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][3])) : 0}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center active lightOrange" colSpan={4}>
                          <label className="control-label">合計</label>
                        </td>
                        <td className="lightOrange" colSpan={1} />
                        <td className="text-right active width-110 lightOrange" colSpan={1}>
                          <span id="saleBudgetMonthData03">
                            {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][1])) : 0}
                          </span>
                        </td>
                      </tr>
                    </AccordionTableWrapper>
                    {/* End 売上高	 */}
                    {/* Start F（食材費） */}
                    <AccordionTableWrapper
                      isExpanded={isIngredientCostExpanded}
                      setStateAccordion={setIsIngredientCostExpand}
                    >
                      <tr>
                        <td className="text-left active lightBlue p-left" colSpan={4}>F（食材費）</td>
                        <td colSpan={1} className="column_change text-center" />
                        <td colSpan={1} className="text-right active width-110 ">
                          <span id="sumOfTotalFoodCostAndDrinkCost" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} className="empty_column lightBlue" />
                        <td className="text-right active p-right" colSpan={3}>食材費</td>
                        <td colSpan={1} className="column_change text-center">変</td>
                        <td colSpan={1} className="text-right active width-110">
                          {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][5])) : 0}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} className="empty_column lightBlue" />
                        <td className="text-right active p-right" colSpan={3}>
                          <label className="control-label">ドリンク費</label>
                        </td>
                        <td colSpan={1} className="column_change text-center">変</td>
                        <td colSpan={1} className="text-right active width-110">
                          {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][6])) : 0}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center active lightBlue" colSpan={4}>
                          <label className="control-label">【F】計	</label>
                        </td>
                        <td colSpan={1} className="column_change lightBlue" />
                        <td className="text-right active width-110 lightBlue" colSpan={1}>
                          <span id="saleBudgetMonthData03">
                            {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][5] + formik.values.saleBudgetMonthData[0][6])) : 0}
                          </span>
                        </td>
                      </tr>
                    </AccordionTableWrapper>
                    <tr>
                      <td className="text-right active p-right" colSpan={4}>
                        <label className="control-label">F率</label>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110" colSpan={1}>
                        <span id="saleBudgetMonthData03">
                          {formatterPercent.format(numberFormater(formik.values.saleBudgetMonthData.length > 0 && formik.values.saleBudgetMonthData[0][1] > 0 ? (formik.values.saleBudgetMonthData[0][5] + formik.values.saleBudgetMonthData[0][6]) / formik.values.saleBudgetMonthData[0][1] : 0))}
                        </span>
                      </td>
                    </tr>
                    {/* End F（食材費） */}
                    {/* Start Gross Profit 売上総利 temporarily no need to use */}
                    {/* <tr>
                      <td className="text-center active" rowSpan={2}>
                        <label className="control-label">売上総利益</label>
                      </td>
                      <td className="text-center active" colSpan={2}>
                        <span>
                          合計&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="売上高（合計）- 標準原価（合計）"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td className="text-right active width-110">
                        <span className="sumOfListGrossProfit">
                          {formatter.format(numberFormater(getRows.getSumOfListGrossProfit)) || 0}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" colSpan={2}>
                        <span>
                          率&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="売上総利益 / 売上高（合計）の％表示"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td className="text-right active width-110">
                        <span id="rateOfSumGrossProfitAndSumAmountOfSale">
                          {formatterPercent.format(numberFormater(getRows.getRateOfSumGrossProfitAndSumAmountOfSale) || 0)}
                        </span>
                      </td>
                    </tr> */}
                    {/* End Gross Profit 売上総利 temporarily no need to use */}
                    {/* Start L（人件費）				 */}
                    <AccordionTableWrapper
                      isExpanded={isLaborCostExpanded}
                      setStateAccordion={setIsLaborCostExpand}
                    >
                      <tr>
                        <td className="text-left active lightBlue p-left" colSpan={4}>
                          <label className="control-label">L（人件費）</label>
                        </td>
                        <td colSpan={1} className="column_change text-center" />
                        <td colSpan={1} className="text-right active width-110 ">
                          <span id="sumOfTotalFoodCostAndDrinkCost" />
                        </td>
                        {/* <td className="text-right active width-110">
                        <span className="sumOfTotalEmployeeLaborCostAndPartLaborCost">
                          {formatter.format(numberFormater(getRows.getSumOfTotalEmployeeLaborCostAndPartLaborCost) || 0)}
                        </span>
                      </td> */}
                      </tr>
                      <tr>
                        <td colSpan={1} className="empty_column lightBlue" />
                        <td className="text-right active p-right" colSpan={3}>
                          <label className="control-label">社員人件費</label>
                        </td>
                        <td colSpan={1} className="column_change text-center">固</td>
                        <td colSpan={1} className="text-right active width-110">
                          <span id="saleBudgetMonthData09">
                            {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][9])) : 0}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={1} className="empty_column lightBlue" />
                        <td className="text-right active p-right" colSpan={3}>
                          <label className="control-label">アルバイト人件費</label>
                        </td>
                        <td colSpan={1} className="column_change text-center">変</td>
                        <td colSpan={1} className="text-right active width-110">
                          <span id="saleBudgetMonthData10">
                            {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][10])) : 0}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td className="text-center active lightBlue" colSpan={4}>
                          <label className="control-label">【L】計</label>
                        </td>
                        <td colSpan={1} className="column_change lightBlue" />
                        <td className="text-right active width-110 lightBlue">
                          <span id="saleBudgetMonthData10">
                            {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][8])) : 0}
                          </span>
                        </td>
                      </tr>
                    </AccordionTableWrapper>

                    <tr>
                      <td className="text-right active" colSpan={4}>
                        <label className="control-label">L率</label>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span id="saleBudgetMonthData10">
                          {formatterPercent.format(numberFormater(formik.values.saleBudgetMonthData.length > 0 && formik.values.saleBudgetMonthData[0][1] > 0 ? formik.values.saleBudgetMonthData[0][8] / formik.values.saleBudgetMonthData[0][1] : 0))}
                        </span>
                      </td>
                    </tr>
                    {/* End L（人件費）				 */}
                    {/* Start A（広告費） */}
                    <AccordionTableWrapper
                      isExpanded={isAdvertisingCostExpanded}
                      setStateAccordion={setIsAdvertisingCostExpand}
                    >
                      <tr>
                        <td className="text-left active lightBlue p-left" colSpan={4}>A（広告費）</td>
                        <td colSpan={1} className="column_change text-center" />
                        <td colSpan={1} className="text-right active width-110 ">
                          <span id="sumOfAdvertisingExpense" />
                        </td>
                        {/* <td className="text-right active width-110">
                        <span className="sumOfAdvertisingExpense">
                          {formatter.format(numberFormater(getRows.getSumOfAdvertisingExpense) || 0)}
                        </span>
                      </td> */}
                      </tr>
                      {
                        formik.values.advertisingExpensesData ? formik.values.advertisingExpensesData.map((data: any, index: any) => {
                          if (data[1] !== 'total') {
                            return (
                              <tr key={index}>
                                <td colSpan={1} className="empty_column lightBlue" />
                                <td className="text-right active p-right" colSpan={3}>{data[1]}</td>
                                <td colSpan={1} className="text-center">固</td>
                                <td colSpan={1} className="text-right active width-110">
                                  <span id={`sumOfAdvertisingExpense${data[0]}`}>{formik.values.saleBudgetAdvertisingMonthData.length > 0 && formik.values.saleBudgetAdvertisingMonthData[index] ? formatter.format(numberFormater(formik.values.saleBudgetAdvertisingMonthData[index][4])) : 0}</span>
                                </td>
                              </tr>
                            );
                          }
                        })
                          : ''
                      }
                      <tr>
                        <td colSpan={4} className="text-center active lightBlue">
                          <label className="control-label">【A】計</label>
                        </td>
                        <td colSpan={1} className="column_change lightBlue" />
                        <td className="text-right active width-110 lightBlue">
                          <span id="saleBudgetMonthData12">
                            {formatter.format(numberFormater(getRows.getSumOfAdvertisingExpense) || 0)}
                          </span>
                        </td>
                      </tr>
                    </AccordionTableWrapper>
                    <tr>
                      <td colSpan={4} className="text-right active p-right">
                        <label className="control-label">A率</label>
                      </td>
                      <td colSpan={1} className="column_change " />
                      <td colSpan={1} className="text-right active width-110 ">
                        <span id="saleBudgetMonthData12">
                          {formatterPercent.format(numberFormater(formik.values.saleBudgetMonthData.length > 0 && formik.values.saleBudgetMonthData[0][1] > 0 ? getRows.getSumOfAdvertisingExpense / formik.values.saleBudgetMonthData[0][1] : 0))}
                        </span>
                      </td>
                    </tr>
                    {/* End A（広告費） */}
                    {/* Start FLA合計				*/}
                    <tr>
                      <td colSpan={4} className="lightBlue p-left">FLA合計</td>
                      <td colSpan={1} className="column_change lightBlue" />
                      <td colSpan={1} className="text-right active width-110 lightBlue">
                        <span id="sumOfAdvertisingExpense">{formatter.format(numberFormater(getRows.getSumOfFLACost) || 0)}</span>
                      </td>
                    </tr>
                    <tr>
                      {/* <td colSpan={1} className="empty_column"></td>  */}
                      <td colSpan={4} className="p-left">FLA率	</td>
                      <td colSpan={1} className="column_change text-center" />
                      <td colSpan={1} className="text-right active width-110 ">
                        <span id="sumOfAdvertisingExpense">{formatterPercent.format(numberFormater(getRows.getSumOfFLACostRate) || 0)}</span>
                      </td>
                    </tr>
                    {/* End FLA合計				*/}
                    {/* Start 店舗利益				 */}
                    <tr>
                      <td colSpan={4} className="lightYellow p-left">店舗損益率</td>
                      <td colSpan={1} className="column_change lightYellow" />
                      <td colSpan={1} className="text-right active width-110 lightYellow">
                        <span id="sumOfAdvertisingExpense">{formatter.format((formik.values.saleBudgetMonthData.length > 0 ? formik.values.saleBudgetMonthData[0][1] : 0) - getRows.getSumOfFLACost)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="empty_column" colSpan={1} style={{ backgroundColor: '#fff2cc' }} />
                      <td colSpan={3} className="text-right p-right">店舗損益率</td>
                      <td colSpan={1} className="column_change text-center" />
                      <td colSpan={1} className="text-right active width-110 ">
                        <span id="sumOfAdvertisingExpense">
                          {
                            formatterPercent.format(
                              numberFormater(formik.values.saleBudgetMonthData.length > 0 && formik.values.saleBudgetMonthData[0][1] > 0
                                ? ((formik.values.saleBudgetMonthData.length > 0 ? formik.values.saleBudgetMonthData[0][1] : 0) - getRows.getSumOfFLACost) / formik.values.saleBudgetMonthData[0][1] : 0),
                            )
                          }

                        </span>
                      </td>
                    </tr>
                    {/* End 店舗利益				 */}
                    {/* Start R（家賃） */}
                    <AccordionTableWrapper
                      isExpanded={isRentExpanded}
                      setStateAccordion={setIsRentExpand}
                    >
                      <tr>
                        <td className="text-left active p-left height_row_contents" colSpan={4} style={{ backgroundColor: '#cfe2f3' }}>
                          R（家賃）
                        </td>
                        <td colSpan={1} className="column_change text-center" />
                        <td colSpan={1} className="text-right active width-110 ">
                          <span id="sumOfAdvertisingExpense" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} className="empty_column lightBlue" />
                        <td colSpan={3} className="text-right p-right">家賃</td>
                        <td colSpan={1} className="column_change text-center">固</td>
                        <td colSpan={1} className="text-right active width-110 ">
                          <span id="sumOfAdvertisingExpense">
                            { formatter.format(formik.values.saleBudgetMonthData.length > 0 ? formik.values.saleBudgetMonthData[0][19] : 0) }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1} className="empty_column lightBlue" />
                        <td colSpan={3} className="text-right p-right">共益費</td>
                        <td colSpan={1} className="column_change text-center">固</td>
                        <td colSpan={1} className="text-right active width-110 ">
                          <span id="sumOfAdvertisingExpense">
                            { formatter.format(formik.values.saleBudgetMonthData.length > 0 ? formik.values.saleBudgetMonthData[0][20] : 0) }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center active lightBlue" colSpan={4}>
                          【R】計
                        </td>
                        <td colSpan={1} className="column_change lightBlue" />
                        <td colSpan={1} className="text-right active width-110 lightBlue">
                          <span id="sumOfAdvertisingExpense">
                            { formatter.format(formik.values.saleBudgetMonthData.length > 0 ? formik.values.saleBudgetMonthData[0][19] + formik.values.saleBudgetMonthData[0][20] : 0) }
                          </span>
                        </td>
                      </tr>
                    </AccordionTableWrapper>
                    <tr>
                      <td className="text-right active p-right" colSpan={4}>
                        R率
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td colSpan={1} className="text-right active width-110 ">
                        <span id="sumOfAdvertisingExpense">
                          {
                          formatterPercent.format(
                            numberFormater(formik.values.saleBudgetMonthData.length > 0 && formik.values.saleBudgetMonthData[0][1] > 0 ? (formik.values.saleBudgetMonthData[0][19] + formik.values.saleBudgetMonthData[0][20]) / formik.values.saleBudgetMonthData[0][1] : 0),
                          )
                        }
                        </span>
                      </td>
                    </tr>

                    {/* End R（家賃） */}
                    {/* Start O（その他経費）				 */}
                    <AccordionTableWrapper
                      isExpanded={isOtherExpensesExpanded}
                      setStateAccordion={setIsOtherExpensesExpand}
                    >
                      <tr>
                        <td className="text-left active lightBlue p-left" colSpan={4}>
                          <label className="control-label">O（その他経費）</label>
                        </td>
                        <td colSpan={1} className="column_change text-center" />
                        <td colSpan={1} className="text-right active width-110">
                          <span className="sumOfTotalOtherVariableCostsAndOtherFixedCosts" />
                        </td>
                      </tr>

                      {
                        formik.values.expensesDailyData.map((item:any, index: number) => {
                          let expenseHorizontal = 0;
                          formik.values.saleBudgetListData.map((saleBudgetListData: any, element: number) => {
                            expenseHorizontal += item[element + 4];
                          });
                          totalExpenseOther += expenseHorizontal;
                          return (
                            <tr>
                              <td colSpan={1} className="empty_column lightBlue" />
                              <td className="text-right active p-right" colSpan={3}>
                                <label className="control-label">{item[1]}</label>
                              </td>
                              <td colSpan={1} className="column_change text-center">{ item[2] === 0 ? '変' : '固' }</td>
                              <td className="text-right active width-110">
                                <span>
                                  {formatter.format(numberFormater(expenseHorizontal))}
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      }

                      <tr>
                        <td colSpan={1} className="empty_column lightBlue" />
                        <td className="text-right active p-right" colSpan={3}>
                          <label className="control-label">仕入（その他）</label>
                        </td>
                        <td colSpan={1} className="column_change text-center">変</td>
                        <td className="text-right active width-110">
                          <span id="saleBudgetMonthData14">
                            {
                              formatter.format(getRows.getPurchasingOther)
                            }
                          </span>
                        </td>
                      </tr>

                      {/* <tr>
                        <td colSpan={1} className="empty_column lightBlue" />
                        <td className="text-right active p-right" colSpan={3}>
                          <label className="control-label">固定費</label>
                        </td>
                        <td colSpan={1} className="column_change text-center">固</td>
                        <td className="text-right active width-110">
                          <span id="saleBudgetMonthData15">
                            {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][15])) : 0}
                          </span>
                        </td>
                      </tr> */}

                      <tr>
                        <td className="text-center active lightBlue" colSpan={4}>
                          <label className="control-label">【O】計</label>
                        </td>
                        <td colSpan={1} className="column_change lightBlue" />
                        <td colSpan={1} className="text-right active width-110 lightBlue">
                          <span className="sumOfTotalOtherVariableCostsAndOtherFixedCosts">
                            {formatter.format(numberFormater(getRows.getSumOfOtherTotalCost) || 0)}
                          </span>
                        </td>
                      </tr>
                    </AccordionTableWrapper>
                    <tr>
                      <td className="text-right active p-right" colSpan={4}>
                        <label className="control-label">O率</label>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td colSpan={1} className="text-right active width-110">
                        <span className="sumOfTotalOtherVariableCostsAndOtherFixedCosts">
                        {formatterPercent.format(numberFormater(getRows.getSumOfOtherTotalCostRate))}
                        </span>
                      </td>
                    </tr>
                    {/* End O（その他経費）				 */}
                    {/* Start 【FLARO】合計				 */}
                    <tr>
                      <td className="text-left active lightPurple p-left" colSpan={4}>【FLARO】合計</td>
                      <td colSpan={1} className="column_change lightPurple" />
                      <td colSpan={1} className="text-right active width-110 lightPurple">
                        <span className="sumOfTotalOtherVariableCostsAndOtherFixedCosts">
                          { formatter.format(getRows.getSumOfFLAROCost || 0) }
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right active lightPurple p-right" colSpan={4}>FLARO率</td>
                      <td colSpan={1} className="column_change lightPurple" />
                      <td colSpan={1} className="text-right active width-110 lightPurple">
                        <span className="sumOfTotalOtherVariableCostsAndOtherFixedCosts">
                          {formatterPercent.format(numberFormater(formik.values.saleBudgetMonthData.length > 0 && formik.values.saleBudgetMonthData[0][1] > 0 ? getRows.getSumOfFLAROCost / formik.values.saleBudgetMonthData[0][1] : 0))}
                        </span>
                      </td>
                    </tr>
                    {/* End 【FLARO】合計				 */}
                    {/* Start 営業利益	 */}

                    <tr>
                      <td className="text-left active lightYellow p-left" colSpan={4}>
                        <label className="control-label">営業利益</label>
                      </td>
                      <td colSpan={1} className="column_change lightYellow" />
                      <td colSpan={1} className="text-right active width-110 lightYellow">
                        <span className="totalOfOperatingIncome">
                          {formatter.format(numberFormater(getRows.getTotalOfOperatingIncome) || 0)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="lightYellow" colSpan={1} />
                      <td className="text-right active p-right" colSpan={3}>
                        営業利益率
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td colSpan={1} className="text-right active width-110">
                        <span id="rateOfOperationIncome">{formatterPercent.format(numberFormater(getRows.getRateOfOperationIncome))}</span>
                      </td>
                    </tr>
                    {/* End 営業利益	 */}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* Table Left */}

          {/* Table Right */}
          <div
            className="layout_sale_right_content1"
            id="daily_layout_sale_right_2"
            style={{
              width: 'calc(100% - 354px)', overflow: 'auto', borderLeft: '5px solid #d2d6de', height: '100%', marginRight: '30px',
            }}
            onScroll={eventScroll}
          >
            <div className="no-padding">
              <div className="box-body no-padding">
                <div className="table-responsive no-padding" style={{ maxWidth: '100%' }}>
                  <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '3200px' }}>
                    <tbody>
                      {/* Table Top */}
                      {/* Start 売上高 */}
                      <AccordionTableWrapper
                        isExpanded={isAdvertisingSaleDataExpanded}
                        setStateAccordion={setIsAdvertisingSaleDataExpand}
                      >
                          <tr>
                            {
                              getRows.getListSumOfAdvertisingSaleAndSaleBudgetFree ? getRows.getListSumOfAdvertisingSaleAndSaleBudgetFree.map((sumOfAdvertisingSaleAndSaleBudgetFree: any, index: any) => {
                              return <td key={index} className="text-right ">
                                <span id={`sumOfAdvertisingSaleAndSaleBudgetFree${index}`} />
                              </td>}) : ''
                            }
                          </tr>
                          {
                            // render total of this collection

                           formik.values.advertisingSaleData.map((data: any, index: any) => {
                              if (data[1] !== 'total') {
                                return <tr>
                                  {
                                    data && data.slice(3,data.length).map((dayItem:any,index:any)=>{
                                      if(dayItem !== null){
                                        return <td key={index} className="text-right ">
                                        <span id={`AdvertisingEachDayListItem${index}`}> {formatter.format(numberFormater(dayItem)) || 0}</span>
                                        </td>
                                      }
                                    })
                                  }
                                </tr>
                              }
                            })
                             
                          }
                          <tr>
                          {
                           formik.values.saleBudgetListData.map((data: any, index: any) => {
                                return <td key={index} className="text-right ">
                                       <span id={`salesBudgetFreeItem${index}`}> {formatter.format(numberFormater(data[3])) || 0}</span>
                                      </td>
                            })
                          }
                          </tr>
                        {/* <tr>
                         
                          {
                            getRows.getListSumOfAdvertisingEachDay ? getRows.getListSumOfAdvertisingEachDay.map((sumOfAdvertisingEachDay: any, index: any) => (
                              <td key={index} className="text-right ">
                                {sumOfAdvertisingEachDay !== undefined ? <span id={`sumOfAdvertisingEachDay${index}`}>{formatter.format(numberFormater(sumOfAdvertisingEachDay))}123</span> : ''}
                              </td>
                            )) : null
                          }
                        </tr> */}
                        {/* {
                        formik.values.advertisingExpensesData.map((data: any, number: any) => {
                          if (data[1] !== 'total') {
                            return (
                              <tr key={number}>
                                {data.map((dayData: any, index: any) => {
                                  if (index !== 0 && index !== 1 && index !== 2 && dayData !== undefined && dayData !== null) {
                                    return (
                                      <td className="text-right " key={index}>
                                        <span id={`advertisingSale${data[0] + [index - 3]}`}>{formatter.format(numberFormater(formik.values.advertisingExpensesData[number][index]) || 0)}</span>
                                      </td>
                                    );
                                  }
                                })}
                              </tr>
                            );
                          }
                        })} */}
                        {/* <tr>
                        {
                            formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                              <td key={index} className="text-right ">
                                <span id={`salesBudgetFree${index}`}>{formatter.format(numberFormater(saleBudgetData[3])) || 0}</span>
                              </td>
                            )) : null
                          }
                      </tr> */}
                        <tr>
                          {getRows.getListSumOfAdvertisingSaleAndSaleBudgetFree ? getRows.getListSumOfAdvertisingSaleAndSaleBudgetFree.map((sumOfAdvertisingSaleAndSaleBudgetFree: any, index: any) => (
                            <td key={index} className="text-right  lightOrange">
                              <span id={`sumOfAdvertisingSaleAndSaleBudgetFree${index}`}>{formatter.format(numberFormater(sumOfAdvertisingSaleAndSaleBudgetFree)) || 0}</span>
                            </td>
                          )) : null}
                        </tr>
                      </AccordionTableWrapper>
                      {/* End 売上高 */}
                      {/* Start F（食材費） */}
                      <AccordionTableWrapper
                        isExpanded={isIngredientCostExpanded}
                        setStateAccordion={setIsIngredientCostExpand}
                      >
                        <tr>
                          {
                            getRows.getListSumOfFoodCostAndDrinkCost ? getRows.getListSumOfFoodCostAndDrinkCost.map((sumOfFoodCostAndDrinkCost: any, index: any) => (
                              <td key={index} className="text-right ">
                                <span id={`sumOfFoodCostAndDrinkCost${index}`} />
                              </td>
                            )) : null
                          }
                        </tr>
                        {/* food cost */}
                        <tr>
                          {
                            formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                              <td key={index} className="text-right  p-right">
                                <span id={`foodCost${index}`}>{formatter.format(numberFormater(saleBudgetData[5])) || 0}</span>
                              </td>
                            )) : null
                          }
                        </tr>
                        {/* drink cost */}
                        <tr>
                          {
                            formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                              <td key={index} className="text-right  p-right">
                                <span id={`drinkCost${index}`}>{formatter.format(numberFormater(saleBudgetData[6])) || 0}</span>
                              </td>
                            )) : null
                          }
                        </tr>
                        <tr>
                          {
                            getRows.getListSumOfFoodCostAndDrinkCost ? getRows.getListSumOfFoodCostAndDrinkCost.map((sumOfFoodCostAndDrinkCost: any, index: any) => (
                              <td key={index} className="text-right  lightBlue">
                                <span id={`sumOfFoodCostAndDrinkCost${index}`}>{formatter.format(numberFormater(sumOfFoodCostAndDrinkCost)) || 0}</span>
                              </td>
                            )) : null
                          }
                        </tr>
                      </AccordionTableWrapper>
                      <tr>
                        {
                            getRows.getListSumOfFoodCostAndDrinkCost ? getRows.getListSumOfFoodCostAndDrinkCost.map((sumOfFoodCostAndDrinkCost: any, index: any) => (
                              <td key={index} className="text-right ">
                                <span id={`sumOfFoodCostAndDrinkCost${index}`}>{formatterPercent.format(numberFormater(sumOfFoodCostAndDrinkCost/getRows.getListSumOfAdvertisingSaleAndSaleBudgetFree[index]) || 0)}</span>
                              </td>
                            )) : null
                          }
                      </tr>
                      {/* End F（食材費） */}
                      {/* Temporarily no need to use gross profit */}
                      {/* <tr>
                        {
                            getRows.getListGrossProfit ? getRows.getListGrossProfit.map((data: any, index: any) => (
                              <td key={index} className="text-right ">
                                <span id={`grossProfit${index}`}>{formatter.format(numberFormater(data)) || 0}</span>
                              </td>
                            )) : null
                          }
                      </tr>
                      <tr>
                        {
                            getRows.getListRateOfGrossProfit ? getRows.getListRateOfGrossProfit.map((rateOfGrossProfit: any, index: any) => (
                              <td key={index} className="text-right ">
                                <span id={`rateOfGrossProfit${index}`}>{formatterPercent.format(numberFormater(rateOfGrossProfit) || 0)}123</span>
                              </td>
                            )) : null
                          }
                      </tr> */}
                      {/* Temporarily no need to use gross profit */}

                      {/* Start L（人件費*/}
                      <AccordionTableWrapper
                        isExpanded={isLaborCostExpanded}
                        setStateAccordion={setIsLaborCostExpand}
                      >
                        <tr>
                          {
                            getRows.getListSumOfEmployeeLaborCostAndPartLaborCost ? getRows.getListSumOfEmployeeLaborCostAndPartLaborCost.map((sumOfEmployeeLaborCostAndPartLaborCost: any, index: any) => (
                              <td key={index} className="text-right ">
                                <span id={`sumOfEmployeeLaborCostAndPartLaborCost${index}`} />
                              </td>
                            )) : null
                          }
                        </tr>
                        <tr>
                          {
                            formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                              <td key={index} className="text-right ">
                                <span id={`employeeLaborCost${index}`}>{formatter.format(numberFormater(saleBudgetData[9])) || 0}</span>
                              </td>
                            )) : null
                          }
                        </tr>
                        <tr>
                          {
                            formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                              <td key={index} className="text-right ">
                                <span id={`partLaborCost${index}`}>
                                  {formatter.format(numberFormater(saleBudgetData[10])) || 0}
                                  
                                </span>
                              </td>
                            )) : null
                          }
                        </tr>
                        <tr>
                          {
                            getRows.getListSumOfEmployeeLaborCostAndPartLaborCost ? getRows.getListSumOfEmployeeLaborCostAndPartLaborCost.map((sumOfEmployeeLaborCostAndPartLaborCost: any, index: any) => (
                              <td key={index} className="text-right  lightBlue">
                                <span id={`sumOfEmployeeLaborCostAndPartLaborCost${index}`}>{formatter.format(numberFormater(sumOfEmployeeLaborCostAndPartLaborCost) || 0)}</span>
                              </td>
                            )) : null
                          }
                        </tr>
                      </AccordionTableWrapper>
                      <tr>
                        {
                            getRows.getListSumOfEmployeeLaborCostAndPartLaborCost ? getRows.getListSumOfEmployeeLaborCostAndPartLaborCost.map((sumOfEmployeeLaborCostAndPartLaborCost: any, index: any) => (
                              <td key={index} className="text-right ">
                                <span id={`sumOfEmployeeLaborCostAndPartLaborCost${index}`}>{formatterPercent.format(numberFormater(sumOfEmployeeLaborCostAndPartLaborCost/getRows.getListSumOfAdvertisingSaleAndSaleBudgetFree[index]) || 0)}</span>
                              </td>
                            )) : null
                          }
                      </tr>
                      {/* End L（人件費）				 */}
                      {/* Start A（広告費）				 */}
                      <AccordionTableWrapper
                        isExpanded={isAdvertisingCostExpanded}
                        setStateAccordion={setIsAdvertisingCostExpand}
                      >
                        <tr>
                          {
                            getRows.getListSumOfAdvertisingExpenseEachDay ? getRows.getListSumOfAdvertisingExpenseEachDay.map((sumOfAdvertisingExpenseEach: any, index: any) => {
                              if (sumOfAdvertisingExpenseEach !== null && sumOfAdvertisingExpenseEach !== undefined) {
                                return (
                                  <td key={index} className="text-right ">
                                    <span id={`sumOfAdvertisingExpenseEach${index}`} />
                                  </td>
                                );
                              }
                            }) : null
                          }
                        </tr>
                        {formik.values.advertisingExpensesData.map((data: any, number: any) => {
                          if (data[1] !== 'total') {
                            return (
                              <tr key={number}>
                                {data.map((dayData: any, index: any) => {
                                  if (index !== 0 && index !== 1 && index !== 2 && dayData !== undefined && dayData !== null) {
                                    return (
                                      <td key={index} className="text-right ">
                                        <span id={`advertisingExpense${data[1][index]}`}>{formatter.format(numberFormater(dayData) || 0)}</span>
                                      </td>
                                    );
                                  }
                                })}
                              </tr>
                            );
                          }
                        })}
                        <tr>
                          {
                            getRows.getListSumOfAdvertisingExpenseEachDay ? getRows.getListSumOfAdvertisingExpenseEachDay.map((sumOfAdvertisingExpenseEach: any, index: any) => {
                              if (sumOfAdvertisingExpenseEach !== null && sumOfAdvertisingExpenseEach !== undefined) {
                                return (
                                  <td key={index} className="text-right  lightBlue">
                                    <span id={`sumOfAdvertisingExpenseEach${index}`}>{formatter.format(numberFormater(sumOfAdvertisingExpenseEach) || 0)}</span>
                                  </td>
                                );
                              }
                            }) : null
                          }
                        </tr>
                      </AccordionTableWrapper>
                      <tr>
                        {
                            getRows.getListSumOfAdvertisingExpenseEachDay ? getRows.getListSumOfAdvertisingExpenseEachDay.map((sumOfAdvertisingExpenseEach: any, index: any) => {
                              if (sumOfAdvertisingExpenseEach !== null && sumOfAdvertisingExpenseEach !== undefined) {
                                return (
                                  <td key={index} className="text-right ">
                                    <span id={`sumOfAdvertisingExpenseEach${index}`}>{formatterPercent.format(numberFormater(sumOfAdvertisingExpenseEach/getRows.getListSumOfAdvertisingSaleAndSaleBudgetFree[index]) || 0)}</span>
                                  </td>
                                );
                              }
                            }) : null
                          }
                      </tr>
                      {/* End A（広告費）				 */}
                      {/* Start FLA合計				 */}
                      <tr>
                        {
                          getRows.getListOfFLACost ? getRows.getListOfFLACost.map((fLACost: any, index: any) => (
                            <td key={index} className="text-right  lightBlue">
                              <span id={`fLACost${index}`}>{formatter.format(numberFormater(fLACost) || 0)}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListOfFLACostRate ? getRows.getListOfFLACostRate.map((fLACostRate: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`fLACostRate${index}`}>{formatterPercent.format(numberFormater(fLACostRate) || 0)}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      {/* End FLA合計				 */}
                      {/* Start 店舗利益*/}
                      <tr>
                        {
                          getRows.getListOfProfitLossCost ? getRows.getListOfProfitLossCost.map((PLCost: any, index: any) => (
                            <td key={index} className="text-right  lightYellow">
                              <span id={`fLACost${index}`}>{formatter.format(numberFormater(PLCost))}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListOfProfitLossCostRate ? getRows.getListOfProfitLossCostRate.map((PLCostRate: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`fLACostRate${index}`}>{formatterPercent.format(numberFormater(PLCostRate))}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      {/* End 店舗利益 */}
                      {/* Start R（家賃）	 */}
                      {/* todo */}
                      <AccordionTableWrapper
                        isExpanded={isRentExpanded}
                        setStateAccordion={setIsRentExpand}
                      >
                        <tr>
                          {
                          getRows.getListOfFLACostRate ? getRows.getListOfFLACostRate.map((fLACostRate: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`fLACostRate${index}`} />
                            </td>
                          )) : null
                        }
                        </tr>
                        <tr>
                          {
                            formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                              <td key={index} className="text-right ">
                                <span id={`landRent${index}`}>{formatter.format(numberFormater(saleBudgetData[21])) || 0}</span>
                              </td>
                            )) : null
                          }
                        </tr>
                        <tr>
                          {
                            formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                              <td key={index} className="text-right ">
                                <span id={`landRent${index}`}>{formatter.format(numberFormater(saleBudgetData[22])) || 0}</span>
                              </td>
                            )) : null
                          }
                        </tr>
                        <tr>
                          {
                            formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                              <td key={index} className="text-right  lightBlue">
                                <span id={`landRent${index}`}>{formatter.format(numberFormater(saleBudgetData[12])) || 0}</span>
                              </td>
                            )) : null
                          }
                        </tr>
                      </AccordionTableWrapper>
                      <tr>
                        {
                          formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`fLACostRate${index}`}>
                                {
                                  formatterPercent.format(
                                    numberFormater(saleBudgetData && saleBudgetData[12] > 0 ? saleBudgetData[12] / saleBudgetData[1] : 0),
                                  )
                                }
                              </span>
                            </td>
                          )) : null
                        }
                      </tr>
                      {/* End R（家賃）	 */}
                      {/* Start O（その他経費） */}
                      <AccordionTableWrapper
                        isExpanded={isOtherExpensesExpanded}
                        setStateAccordion={setIsOtherExpensesExpand}
                      >
                        <tr>
                          {
                            getRows.getListSumOfOtherVariableCostsAndOtherFixedCosts ? getRows.getListSumOfOtherVariableCostsAndOtherFixedCosts.map((sumOfOtherVariableCostsAndOtherFixedCosts: any, index: any) => (
                              <td key={index} className="text-right ">
                                <span id={`sumOfOtherVariableCostsAndOtherFixedCosts${index}`} />
                              </td>
                            )) : null
                          }
                        </tr>

                        {
                          formik.values.expensesDailyData.map((expensesDailyData:any, element: number) => (
                            <tr>
                              {
                                formik.values.saleBudgetListData.map((saleBudgetData:any, index: number) => (
                                  <td key={index} className="text-right ">
                                    <span id={`expensesDailyData${index + 4}`}>{formatter.format(numberFormater(expensesDailyData[index + 4])) || 0}</span>
                                  </td>
                                ))
                              }
                            </tr>
                          ))
                        }

                        <tr>
                          {
                             getRows.getListOfPurchasingOther.map((otherPurchase:any, index: number) => (
                              <td>
                                <span>{formatter.format(otherPurchase)}</span>
                              </td>
                            ))
                          }
                        </tr>

                        <tr>
                          {
                            getRows.getListSumOfOtherTotalCost.map((otherCost:any, index: number) => {
                              return (
                                <td key={index} className="text-right  lightBlue">
                                  <span id={`expenseVerticle${index}`}>{formatter.format(numberFormater(otherCost))}</span>
                                </td>
                              );
                            })
                          }
                        </tr>
                      </AccordionTableWrapper>
                      <tr>
                      {
                            getRows.getListSumOfOtherTotalCostRate.map((otherCostRate:any, index: number) => {
                              return (
                                <td key={index} className="text-right ">
                                  <span id={`expenseVerticle${index}`}>{formatterPercent.format(numberFormater(otherCostRate) || 0)}</span>
                                </td>
                              );
                            })
                          }
                      </tr>
                      {/* End O（その他経費） */}
                      <tr>
                        {
                          getRows.getListOfFLAROCost ? getRows.getListOfFLAROCost.map((fLAROCost: any, index: any) => (
                            <td key={index} className="text-right  lightPurple">
                              <span id={`fLAROCost${index}`}>{formatter.format(numberFormater(fLAROCost) )}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListOfFLAROCostRate ? getRows.getListOfFLAROCostRate.map((fLAROCostRate: any, index: any) => (
                            <td key={index} className="text-right  lightPurple">
                              <span id={`fLAROCostRate${index}`}>{formatterPercent.format(numberFormater(fLAROCostRate))}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      {/* Start 営業利益	 */}
                      <tr>
                        {
                            getRows.getListOfOperatingIncome ? getRows.getListOfOperatingIncome.map((operatingIncome: any, index: any) => (
                              <td key={index} className="text-right  lightYellow">
                                <span id={`operatingIncome${index}`}>{formatter.format(numberFormater(operatingIncome)) || 0}</span>
                              </td>
                            )) : null
                          }
                      </tr>
                      <tr>
                        {
                            getRows.getListOfRateOperatingIncome ? getRows.getListOfRateOperatingIncome.map((rateOperatingIncome: any, index: any) => (
                              <td key={index} className="text-right ">
                                <span id={`rateOperatingIncome${index}`}>{formatterPercent.format(numberFormater(rateOperatingIncome) || 0)}</span>
                              </td>
                            )) : null
                          }
                      </tr>
                      {/* End 営業利益 */}
                      {/* Table Top */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* Table Right */}
        </div>
        {/* First Table */}
        {/* Second Table */}
        <div style={{ display: 'inline-flex', width: '100%' }}>
          {/* Table Left */}
          <div className="no-padding layout_sale_left" id="daily_layout_sale_left" style={{ width: '345px',minWidth:'345px', borderRight: '5px solid rgb(210, 214, 222)' }}>
            <div className="box-body no-padding">
              <div className="table-responsive no-padding">
                <table className="table table-bordered table-striped table-condensed text-nowrap"  id="salesBudgetMonth-BottomLeft">
                  <tbody>
                    {/* Table Bottom */}
                    <tr>
                      <td className="text-right active p-right" colSpan={3}>
                        <label className="control-label">客単価</label>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span className="perCustomerPrice">{formatter.format(numberFormater(getRows.getPerCustomerPrice) || 0)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right active p-right" colSpan={3}>
                        <span style={{ justifyContent: 'flex-end' }}>
                          客数&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="売上高合計 / 客単価"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span id="sumOfNumberOfCustomers">{formatter.format(numberFormater(getRows.getSumOfNumberOfCustomers) || 0)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right active p-right" colSpan={3}>
                        <span style={{ justifyContent: 'flex-end' }}>
                          回転率&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="客数 / 座席数"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span id="sumOfTurnoverRate">{getRows.getSumOfTurnoverRate.toFixed(2) || 0.00}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" rowSpan={2}>
                        <label className="control-label">限界利益</label>
                      </td>
                      <td className="text-right active p-right" colSpan={2}>
                        <span style={{ justifyContent: 'flex-end' }}>
                          額&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="売上高合計 - 変動費"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span id="sumOfMarginalProfit">{formatter.format(numberFormater(getRows.getSumOfMarginalProfit) || 0)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right active p-right" colSpan={2}>
                        <span style={{ justifyContent: 'flex-end' }}>
                          率&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="限界利益 / 売上高合計の％表示"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span id="sumOfRateMarginalProfit">{formatterPercent.format(numberFormater(getRows.getSumOfRateMarginalProfit) || 0)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" rowSpan={2}>
                        <label className="control-label">貢献利益</label>
                      </td>
                      <td className="text-center active" colSpan={2}>
                        <span style={{ justifyContent: 'flex-end' }}>
                          額&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="限界利益 - 固定費"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span id="sumOfContributionMargin">{formatter.format(numberFormater(getRows.getSumOfContributionMargin) || 0)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" colSpan={2}>
                        <span style={{ justifyContent: 'flex-end' }}>
                          率&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="貢献利益 / 売上高合計の％表示"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span id="sumOfRateContributionMargin">{formatterPercent.format(numberFormater(getRows.getSumOfRateContributionMargin) || 0)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" rowSpan={2}>
                        <label className="control-label">損益分岐点</label>
                      </td>
                      <td className="text-center active" colSpan={2}>
                        <span style={{ justifyContent: 'flex-end' }}>
                          売上&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="固定費 / 限界利益率"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span id="sumOfBreakEventPointSale">{formatter.format(numberFormater(getRows.getSumOfBreakEventPointSale) || 0)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" colSpan={2}>
                        <span style={{ justifyContent: 'flex-end' }}>
                          客数&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="損益分岐点売上 / 客単価"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span id="sumOfBreakEventPointNumber">{formatter.format(numberFormater(getRows.getSumOfBreakEventPointNumber) || 0)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" rowSpan={3}>
                        <label className="control-label">人件費率</label>
                      </td>
                      <td className="text-center active" colSpan={2}>
                        <span style={{ justifyContent: 'flex-end' }}>
                          合計&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="人件費合計 / 売上高合計の％表示"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span id="sumOfSumOfEmployeeAndPartCostRatio">{formatterPercent.format(numberFormater(getRows.getSumOfSumOfEmployeeAndPartCostRatio) || 0)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" colSpan={2}>
                        <span style={{ justifyContent: 'flex-end' }}>
                          社員&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="社員人件費 / 売上高合計の％表示"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span id="sumOfEmployeeCostRatio">{formatterPercent.format(numberFormater(getRows.getSumOfEmployeeCostRatio) || 0)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" colSpan={2}>
                        <span style={{ justifyContent: 'flex-end' }}>
                          アルバイト&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="アルバイト人件費 / 売上高合計の％表示"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span id="sumOfPartCostRatio">{formatterPercent.format(numberFormater(getRows.getSumOfPartCostRatio) || 0)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center active" colSpan={3}>
                        <span style={{ justifyContent: 'flex-end' }}>
                          労働分配率&nbsp;
                          <IconTooltip
                            iconType="help"
                            text="人件費合計 /（売上高合計 - 食材費合計）の％表示"
                            color="#000"
                          />
                        </span>
                      </td>
                      <td colSpan={1} className="column_change text-center" />
                      <td className="text-right active width-110">
                        <span id="sumOfLaborShare">{formatterPercent.format(numberFormater(getRows.getSumOfLaborShare) || 0)}</span>
                      </td>
                    </tr>

                  </tbody>
                  {/* Table Bottom */}
                </table>
              </div>
            </div>
          </div>
          {/* Table Left */}

          {/* Table Right */}
          <div
            className="layout_sale_right_content1 hide_scrollbar_onscroll"
            id="layout_sale_right_table_bottom"
            style={{
              width: 'calc(100% - 354px)', overflowX: 'auto', height: '100%', marginRight: '30px',
            }}
            onScroll={eventScroll}
          >
            <div className="no-padding">
              <div className="box-body no-padding">
                <div className="table-responsive no-padding" style={{ maxWidth: '100%' }}>
                  <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '3200px' }} id="salesBudgetMonth-BottomRight">
                    <tbody>
                      {/* Table Bottom */}
                      <tr>
                        {
                          formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`perCustomerPrice${index}`}>{formatter.format(numberFormater(getRows.getPerCustomerPrice)) || 0}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListNumberOfCustomers ? getRows.getListNumberOfCustomers.map((numbersOfCustomers: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`numbersOfCustomers${index}`}>{formatter.format(numberFormater(numbersOfCustomers)) || 0}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListOfTurnoverRate ? getRows.getListOfTurnoverRate.map((turnoverRate: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`turnoverRate${index}`}>{turnoverRate.toFixed(2) || 0.00}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListOfMarginalProfit ? getRows.getListOfMarginalProfit.map((marginalProfit: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`marginalProfit${index}`}>{formatter.format(numberFormater(marginalProfit)) || 0}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListRateOfMarginalProfit ? getRows.getListRateOfMarginalProfit.map((rateOfMarginalProfit: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`rateOfMarginalProfit${index}`}>{formatterPercent.format(numberFormater(rateOfMarginalProfit) || 0)}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListOfContributionMargin ? getRows.getListOfContributionMargin.map((contributionMargin: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`contributionMargin${index}`}>{formatter.format(numberFormater(contributionMargin) || 0)}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListOfRateContributionMargin ? getRows.getListOfRateContributionMargin.map((rateContributionMargin: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`rateContributionMargin${index}`}>{formatterPercent.format(numberFormater(rateContributionMargin) || 0)}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListOfBreakEventPointSale ? getRows.getListOfBreakEventPointSale.map((breakEventPointSale: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`breakEventPointSale${index}`}>{formatter.format(numberFormater(breakEventPointSale) || 0)}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListOfBreakEventPointNumber ? getRows.getListOfBreakEventPointNumber.map((breakEventPointNumber: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`breakEventPointNumber${index}`}>{formatter.format(numberFormater(breakEventPointNumber) || 0)}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListSumOfEmployeeAndPartCostRatio ? getRows.getListSumOfEmployeeAndPartCostRatio.map((sumOfEmployeeAndPartCostRatio: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`sumOfEmployeeAndPartCostRatio${index}`}>{formatterPercent.format(numberFormater(sumOfEmployeeAndPartCostRatio) || 0)}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListOfEmployeeCostRatio ? getRows.getListOfEmployeeCostRatio.map((employeeCostRatio: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`employeeCostRatio${index}`}>{formatterPercent.format(numberFormater(employeeCostRatio) || 0)}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListOfPartCostRatio ? getRows.getListOfPartCostRatio.map((partCostRatio: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`partCostRatio${index}`}>{formatterPercent.format(numberFormater(partCostRatio) || 0)}</span>
                            </td>
                          )) : null
                        }
                      </tr>
                      <tr>
                        {
                          getRows.getListOfLaborShare ? getRows.getListOfLaborShare.map((laborShare: any, index: any) => (
                            <td key={index} className="text-right ">
                              <span id={`laborShare${index}`}>{formatterPercent.format(numberFormater(laborShare) || 0)}</span>
                            </td>
                          )) : null
                        }
                      </tr>

                      {/* Table Bottom  */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* Table Right */}
        </div>
        {/* Second Table */}
      </div>
      {/* Table Body */}

      {/* Bottom scroll */}
      <div className="scroll-bottom-daily-settings" id="layout_sale_right_3" style={{ marginTop: '4px' }} onScroll={eventScroll}>
        <div style={{ width: '350px', float: 'left' }} />
        <div
          style={{
            width: 'calc(100% - 350px)',
            float: 'right',
            height: '15px',
            overflow: 'auto',
          }}
          id="layout_sale_right_4"
        >
          <div className="scroll" id="layout_sale_right_5" style={{ width: '4500px' }} />
        </div>
      </div>
      {/* Bottom scroll */}
    </div>
  );
};
export default TableSalesPlanDaily;
