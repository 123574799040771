/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import React, { useCallback, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { stateSymbol } from 'components/organismos/master/general/salesPage//SalesManagementList/hookSysboy';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { useDownload } from 'components/organismos/master/general/salesPage/SalesManagementList/hook';
import moment from 'moment';
import { Link } from 'react-router-dom';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import SortIcon from 'components/molecules/SortIcon';
import { grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';

const styles = css({
  fontWeight: 400,
  fontSize: 14,
});

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 1,
});

const formatterPercent = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'percent',
});

const DEFAULT_SORT_FIELD = { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number' } as FieldSortState

const SalesManagementFLListTable: React.FC<{
    listSalesManagement4: any;
    isActiveRow: number;
    isActiveColumn: number;
    handleClickActive: any;
    selectedDateFrom: any;
    selectedDateTo: any;
    orgName: any;
    roleScreen?: any;
  }> = ({
    listSalesManagement4,
    isActiveRow,
    isActiveColumn,
    handleClickActive,
    selectedDateFrom,
    selectedDateTo,
    orgName,
    roleScreen
  }) => {

    const listData = useMemo(() => listSalesManagement4.slice(0, -1), [listSalesManagement4])
    const totalData = useMemo(() => listSalesManagement4.slice(-1), [listSalesManagement4])

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(listData, DEFAULT_SORT_FIELD);

    const searchResult: any[] = [];
    const totalInformation: any[] = [];
    const { downloadCsv4, downloadExcel4 } = useDownload();

    const headerInformation = [
      ['', '', '売上','','','FL', 'L', '','','','','','F',''],
      ['店舗', '並び順', '売上(税抜)', '累計売上', '累計達成率','累計FL(%)', '累計人件費率', '日次PA人件費', '累計PA人件費率','社員労働時間','PA労働時間','人時生産性','累計F(%)','累計D(%)'],
    ];

    const data = {
      footer: '',
      formName: '営業管理【FL】',
      targetPeriod: `対象期間：${moment(selectedDateFrom).format('YYYY-MM-DD')}~${moment(selectedDateTo).format('YYYY-MM-DD')}`,
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: totalInformation,
    };

    let mergedRegions: any[] = [];
    const codeColumnIndex: any[] = [];
    const dataExcel = {
      formName: '営業管理【FL】',
      targetPeriod: `対象期間: ${moment(selectedDateFrom).format('YYYY-MM-DD')}~${moment(selectedDateTo).format('YYYY-MM-DD')}`,
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: totalInformation,
      mergedRegions,
      codeColumnIndex,
    };

    const handleExportCSV = () => downloadCsv4(data);

    const handleExportExcel = () => {
      if (mergedRegions) {
        mergedRegions = [];
      }
      mergedRegions.push([3, 3, 2, 4]);
      mergedRegions.push([3, 3, 6, 8]);
      mergedRegions.push([3, 3, 9, 11]);
      mergedRegions.push([3, 3, 12, 13]);

      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [0];
      dataExcel.headerInformation = headerInformation;
      return downloadExcel4(dataExcel);
    };

    const renderHeaderColumn = useCallback((displayName: string, columnSortField: FieldSortState, rowSpan: number, className: string = "") => (
      <th rowSpan={rowSpan} className={className} onClick={e => sortByFieldPath(columnSortField.fieldPath, columnSortField.sortType)}>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <span style={{ margin: 'auto' }}>{displayName}</span>
          {sortField.fieldPath === columnSortField.fieldPath && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
          {sortField.fieldPath !== columnSortField.fieldPath && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
        </div>
      </th>
     ), [sortField]);

    return (
      <div>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportCSV}
                iconType="download"
                text=" CSV出力"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportExcel}
                iconType="download"
                text=" EXCEL出力"
              />
            </FlexBoxItem>
          </FlexBox>
        )}
        <div
        className="table-responsive"
        style={{
          display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', marginTop: 20, maxHeight: 600,
        }}
      >
        <table id="salesPageForm" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }}>
          <thead>
            <tr role="row">
              <th colSpan={2}/>
              <th colSpan={3}>売上</th>
              <th>FL</th>
              <th colSpan={3}>L</th>
              <th colSpan={3} />
              <th colSpan={2}>F</th>
            </tr>
            <tr className="subThead">
              { renderHeaderColumn('店舗', { fieldPath: 'orgName', sort: 'asc', sortType: 'string'}, 1) }
              { renderHeaderColumn('並び順', { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number'}, 1) }
              { renderHeaderColumn('売上(税抜)', { fieldPath: 'netSales', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('累計売上', { fieldPath: 'cumulativeSales', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('累計達成率', { fieldPath: 'cumulativeSalesAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('累計FL(%)', { fieldPath: 'cumulativeFoodLaborAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('累計人件費率', { fieldPath: 'cumulativeLaborAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('日次PA人件費', { fieldPath: 'partSalary', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('累計PA人件費率', { fieldPath: 'partLaborAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('社員労働時間', { fieldPath: 'employeeWorkTimeDisp', sort: 'asc', sortType: 'minute'}, 1, "color_merge") }
              { renderHeaderColumn('PA労働時間', { fieldPath: 'partWorkTimeDisp', sort: 'asc', sortType: 'minute'}, 1, "color_merge") }
              { renderHeaderColumn('人時生産性', { fieldPath: 'manHourProductivity', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('累計F(%)', { fieldPath: 'cumulativeFoodAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('累計D(%)', { fieldPath: 'cumulativeDrinkAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
            </tr>
          </thead>
            {
                  listSalesManagement4 && listSalesManagement4.length > 0 ? dataSort.map((saleManagementFL: any, index: number) => {
                    {searchResult.push([
                      (stateSymbol as any)[saleManagementFL.status] + saleManagementFL.orgName,
                      saleManagementFL.dispOrder,
                      formatter.format(saleManagementFL.netSales),
                      formatter.format(saleManagementFL.cumulativeSales),
                      formatter.format(saleManagementFL.cumulativeSalesAchievementRate)+'%',
                      formatter.format(saleManagementFL.cumulativeFoodLaborAchievementRate)+'%',
                      formatter.format(saleManagementFL.cumulativeLaborAchievementRate)+'%',
                      formatter.format(saleManagementFL.partSalary),
                      formatter.format(saleManagementFL.partLaborAchievementRate)+'%',
                      saleManagementFL.employeeWorkTimeDisp,
                      saleManagementFL.partWorkTimeDisp,
                      formatter.format(saleManagementFL.manHourProductivity),
                      formatter.format(saleManagementFL.cumulativeFoodAchievementRate)+'%',
                      formatter.format(saleManagementFL.cumulativeDrinkAchievementRate)+'%',
                    ])
                    }
                    return (
                    <tbody key={index} css={styles}>
                      <tr className={isActiveRow === index ? 'activeClickFrames' : ''}>
                        <td
                          className={`textLeft ${isActiveColumn === 1 ? 'activeClickFrames' : ''}`}
                          onClick={() => { handleClickActive(index, 1); }}
                        >
                           {
                                saleManagementFL.status !== 99
                                ? <Link target={"_blank"} to={{ pathname: `/salesDailyReport/${saleManagementFL.orgCode}/${moment(selectedDateTo).format('YYYY-MM-DD')}` }}>
                                  {(stateSymbol as any)[saleManagementFL.status]}
                                  </Link>
                                :  (stateSymbol as any)[saleManagementFL.status]
                           }
                          {saleManagementFL.orgName}
                        </td>
                        <td
                          className={isActiveColumn === 2 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 2); }}
                        >
                          {saleManagementFL.dispOrder}
                        </td>
                        <td
                          className={isActiveColumn === 3 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 3); }}
                        >
                          {formatter.format(saleManagementFL.netSales)}
                        </td>
                        <td
                          className={isActiveColumn === 4 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 4); }}
                        >
                          {formatter.format(saleManagementFL.cumulativeSales)}
                        </td>
                        <td
                          className={isActiveColumn === 5 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 5); }}
                        >
                          {formatter.format(saleManagementFL.cumulativeSalesAchievementRate)+'%'}
                        </td>
                        <td
                          className={isActiveColumn === 6 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 6); }}
                        >
                          {formatter.format(saleManagementFL.cumulativeFoodLaborAchievementRate)+'%'}
                        </td>
                        <td
                          className={isActiveColumn === 7 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 7); }}
                        >
                          {formatter.format(saleManagementFL.cumulativeLaborAchievementRate)+'%'}
                        </td>
                        <td
                          className={isActiveColumn === 8 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 8); }}
                        >
                          {formatter.format(saleManagementFL.partSalary)}
                        </td>
                        <td
                          className={isActiveColumn === 9 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 9); }}
                        >
                          {formatter.format(saleManagementFL.partLaborAchievementRate)+'%'}
                        </td>
                        <td
                          className={isActiveColumn === 10 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 10); }}
                        >
                          {saleManagementFL.employeeWorkTimeDisp}
                        </td>
                        <td
                          className={isActiveColumn === 11 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 11); }}
                        >
                          {saleManagementFL.partWorkTimeDisp}
                        </td>
                        <td
                          className={isActiveColumn === 12 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 12); }}
                        >
                          {formatter.format(saleManagementFL.manHourProductivity)}
                        </td>
                        <td
                          className={isActiveColumn === 13 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 13); }}
                        >
                          {formatter.format(saleManagementFL.cumulativeFoodAchievementRate)+'%'}
                        </td>
                        <td
                          className={isActiveColumn === 14 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 14); }}
                        >
                          {formatter.format(saleManagementFL.cumulativeDrinkAchievementRate)+'%'}
                        </td>
                      </tr>
                    </tbody>
                    )
                  } ) : <tbody css={styles}></tbody>
              }
          <tfoot>
            { listSalesManagement4.length > 0 && totalData.map((item: any, index: any) => {
              {totalInformation.push([
                '合計',
               '',
               formatter.format(item.netSales),
               formatter.format(item.cumulativeSales),
               formatter.format(item.cumulativeSalesAchievementRate)+'%',
               formatter.format(item.cumulativeFoodLaborAchievementRate)+'%',
               formatter.format(item.cumulativeLaborAchievementRate)+'%',
               formatter.format(item.partSalary),
               formatter.format(item.partLaborAchievementRate)+'%',
               item.employeeWorkTimeDisp,
               item.partWorkTimeDisp,
               formatter.format(item.manHourProductivity),
               formatter.format(item.cumulativeFoodAchievementRate)+'%',
               formatter.format(item.cumulativeDrinkAchievementRate)+'%',
              ])}
             return (
                <tr key={index} className="custom_total">
                  <td className="textLeft">合計</td>
                  <td></td>
                  <td>{formatter.format(item.netSales)}</td>
                  <td>{formatter.format(item.cumulativeSales)}</td>
                  <td>{formatter.format(item.cumulativeSalesAchievementRate)+'%'}</td>
                  <td>{formatter.format(item.cumulativeFoodLaborAchievementRate)+'%'}</td>
                  <td>{formatter.format(item.cumulativeLaborAchievementRate)+'%'}</td>
                  <td>{formatter.format(item.partSalary)}</td>
                  <td>{formatter.format(item.partLaborAchievementRate)+'%'}</td>
                  <td>{item.employeeWorkTimeDisp}</td>
                  <td>{item.partWorkTimeDisp}</td>
                  <td>{formatter.format(item.manHourProductivity)}</td>
                  <td>{formatter.format(item.cumulativeFoodAchievementRate)+'%'}</td>
                  <td>{formatter.format(item.cumulativeDrinkAchievementRate)+'%'}</td>
                </tr>
              )
            })}
          </tfoot>
        </table>
      </div>
      </div>
    );
  }
export default SalesManagementFLListTable;
