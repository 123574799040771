/** @jsx jsx */
import React from "react";
import FullScreenOverlay from "./FullScreenOverlay";
import Panel from "components/atoms/Panel";
import Header from "./Header";
// import Footer from "./Footer";
import Footer from 'components/molecules/Modal/Footer';
import Body from 'components/molecules/Modal/Body';
import BodyText from "components/atoms/BodyText";
import { css, jsx, SerializedStyles } from "@emotion/core";

const ModalForm: React.FC<{
  open: boolean;
  closeHandler: () => void;
  title: string;
  submitText: string;
  closeText?: string;
  onSubmit: () => void;
  width?: string;
  typeSubmit?: string;
  note?: string;
  contentWidth?: string;
  isShowButtonClose?: boolean;
  customStyleBody?: SerializedStyles;
}> = ({
  children,
  open,
  closeHandler,
  title,
  submitText,
  onSubmit,
  closeText = "キャンセル",
  note = "",
  width,
  isShowButtonClose = true,
  // typeSubmit = "confirm",
  contentWidth = "100%",
  customStyleBody,
}) => (
  <FullScreenOverlay open={open}>
    <Panel width={width ? width : "486px"}>
      <Header title={title} />
      <Body customStyleBody={customStyleBody}>
        <div css={css({ width: "100%", textAlign: "center" })}>
          <BodyText>{note}</BodyText>
        </div>
        {children}
      </Body>
      <div style={{margin:'auto', width:"80%"}}>
      <Footer 
      submitText={submitText} 
      closeText={closeText} 
      onSubmit={onSubmit} 
      closeHandler={closeHandler}
      isShowButtonClose={isShowButtonClose}
      />
      </div>
    </Panel>
  </FullScreenOverlay>
);

export default ModalForm;
