
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useState, useMemo, useCallback } from 'react';
import DescriptionMasterDomain from 'domain/master/general/descriptionMaster';
import { Column } from 'react-table';
import { iconColor } from 'components/styles';
import DataTable from 'components/organismos/DataTable/DataTable';
import { downloadCSV, deleteDesCriptionMstList } from 'api/descriptionMaster';
import PrimaryButton from 'components/atoms/Button';
import useToastNotification from 'hooks/useToastNotification';
import { Link } from 'react-router-dom';
import Icon from 'components/atoms/Icon';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import FlexBoxItem from 'components/atoms/FlexBoxItem';

import FlexBox from 'components/atoms/FlexBox';
import IconLabelButton from 'components/molecules/IconLabelButton';
import useDescriptionMasterItemList from './hooks';
import { useSelector } from "react-redux";
import { Store } from 'modules/store';

const DescriptionMaster = () => {
  const { descriptMasterItemList, setDescriptMasterItemList } = useDescriptionMasterItemList();
  const [selectedDescriptItem, setselectedDescriptItem] = useState(
    DescriptionMasterDomain.generateInitial(),
  );

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const downloadFileCSV = useCallback(() => {
    downloadCSV('摘要マスタ');
  }, []);

  // Model
  const openConfirmModal = useCallback((descriptionTitleMSTItem : DescriptionMasterDomain) => {
    setConfirmModalOpen(true);
    setselectedDescriptItem(descriptionTitleMSTItem);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);
  // end Model

  const deleteDescriptionMaster = useCallback(() => {
    deleteDesCriptionMstList(selectedDescriptItem.descriptionId)
      .then(() => {
        setDescriptMasterItemList(
          descriptMasterItemList.filter(
            (item) => item.descriptionId
              !== selectedDescriptItem.descriptionId,
          ),
        );
        setConfirmModalOpen(false);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.defaultMessage) {
          errorNotification(error.response.data.defaultMessage);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  }, [descriptMasterItemList, errorNotification,
    selectedDescriptItem.descriptionId, setDescriptMasterItemList, successNotification]);

  const columns: Array<Column<DescriptionMasterDomain>> = useMemo(() => [
    {
      Header: '摘要コード',
      accessor: 'descriptionCode',
      sortType: 'basic'
    },
    {
      Header: '摘要名',
      accessor: 'descriptionName',
    },
    {
      Header: '仕訳名',
      accessor: 'journalName',
    },
    {
      Header: '分類',
      accessor: 'classificationName',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: DescriptionMasterDomain },
        data: Array<DescriptionMasterDomain>
        }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/descriptionMst/edit/${`${cell.row.original.descriptionId}`}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            {
              roleScreen && roleScreen.editable === 1 && (
                <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                  <span
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                    onMouseEnter={() => setIsHoverDelete(true)}
                    onMouseLeave={() => setIsHoverDelete(false)}
                  >
                    <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </Link>
              )
            }
          </div>
        );
      },
    },
  ], [openConfirmModal, roleScreen]);
  return (
    <div>
      <div style={{ margin: '8px 16px' }}>
        <p>小口現金管理の入力項目である、仕訳設定済みの科目を登録します。</p>

        <FlexBox>
          {
            roleScreen && roleScreen.downloadFlag === 1 && (
            <FlexBoxItem>
              <IconLabelButton
                onClick={downloadFileCSV}
                iconType="download"
                text="CSVダウンロード"
              />
            </FlexBoxItem>
            )
          }
          {
            roleScreen && roleScreen.importFlag === 1 && (
            <FlexBoxItem>
              <Link to="/descriptionMst/import">
                <PrimaryButton
                  ghost={false}
                  text="インポート"
                />
              </Link>
            </FlexBoxItem>
            )
          }
          {
            roleScreen && roleScreen.editable === 1 && (
            <FlexBoxItem grow={1}>
              <div style={{ textAlign: 'right' }}>
                <Link to="/descriptionMst/add">
                  <PrimaryButton
                    ghost={false}
                    text="摘要作成"
                  />
                </Link>
              </div>
            </FlexBoxItem>
            )
          }
        </FlexBox>
      </div>
      <div
        css={css`
          table th:nth-child(1), table td:nth-child(1){
              width: 20%;
          }
          table th:nth-child(2), table td:nth-child(2) {
              width: 25%;
          }
          table th:nth-child(3), table td:nth-child(3) {
              width: 25%;
          }
          table th:nth-child(4), table td:nth-child(4) {
              width: 20%;
          }
          table th:nth-child(5), table td:nth-child(5) {
              width: 10%;
          }
          `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
          <DataTable
            columns={columns}
            data={descriptMasterItemList}
            isGlobalFilter={true}
            sortBy={[
              { id: 'descriptionCode', desc: false }
            ]}
          />
        </div>
      </div>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={deleteDescriptionMaster}
        actionType={ActionType.DELETE}
      />
    </div>
  );
};

export default DescriptionMaster;
