
import React from 'react';
import MediaAnalysisBillList from 'components/organismos/master/general/salesPage/mediaAnalysisBillList/mediaAnalysisBillList';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const MediaAnalysisBill = () => (
  <SidebarTemplate pageTitle="媒体分析帳票">
    <MediaAnalysisBillList />
  </SidebarTemplate>
);

export default MediaAnalysisBill;
