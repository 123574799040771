import moment from 'moment';

export type OverViews = {
    overview: string,
    statusName: string,
}

export type DashboardReportStatus = {
  id: {
    companyCode: string,
    orgCode: string,
    targetDay: string,
  },
  status?: number|undefined,
  orgName: string,
  overviews: Array<OverViews>
}

export class DashboardReportStatusDomain {
  constructor(private rawData: DashboardReportStatus) {
    // do nothing
  }

  getRawData(): DashboardReportStatus {
    return this.rawData;
  }

  get companyCode (): string {
    return this.rawData.id.companyCode;
  }

  set companyCode (companyCode: string) {
    this.rawData.id.companyCode = companyCode;
  }

  get orgCode (): string {
    return this.rawData.id.orgCode;
  }

  set orgCode (orgCode: string) {
    this.rawData.id.orgCode = orgCode;
  }

  get targetDay (): string {
    return this.rawData.id.targetDay;
  }

  set targetDay (targetDay: string) {
    this.rawData.id.targetDay = targetDay;
  }
  
  get status (): number|undefined {
    return this.rawData.status;
  }

  set status (status: number|undefined) {
    this.rawData.status = status;
  }

  get orgName (): string {
    return this.rawData.orgName;
  }

  set orgName (orgName: string) {
    this.rawData.orgName = orgName;
  }

  get overviews (): Array<OverViews> {
    return this.rawData.overviews;
  }

  set overviews (overviews: Array<OverViews>) {
    this.rawData.overviews = overviews;
  }
}
