
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import DescriptionMSTForm from 'components/organismos/master/general/descriptionMSTForm/DescriptionMSTForm.tsx';
import { useParams } from 'react-router-dom';

const DescriptionMSTAddPage: React.FC = () => {
  const { descriptionId } = useParams();
  return (
    <SidebarTemplate pageTitle={descriptionId ? '摘要編集' : '摘要登録'}>
      <DescriptionMSTForm />
    </SidebarTemplate>
  );
};


export default DescriptionMSTAddPage;
