import { useState, useMemo, useCallback } from 'react';

// import OrganizationDomain from 'domain/organization';
import OrganizationDomain from 'domain/organizationListTable';
import * as api from 'api/organizationListTable';
import useToastNotification from 'hooks/useToastNotification';

/**
 * 組織削除処理
 */
export const useOrganizationDelete = (fetchData: () => Promise<void>) => {
  const [deleteTargetOrganization, setDeleteTargetOrganization] = useState<OrganizationDomain|null>(null);
  const { successNotification, errorNotification } = useToastNotification();

  // 削除モーダルの開閉状態
  const isOpenDeleteModal = useMemo(() => !!deleteTargetOrganization, [deleteTargetOrganization]);

  // 削除モーダルを閉じる
  const closeDeleteModal = useCallback(() => setDeleteTargetOrganization(null), []);

  // 削除実行
  const onSubmitDeleteOrganization = useCallback(() => {
    if (!deleteTargetOrganization) {
      return;
    }
    api.deleteOrganization(deleteTargetOrganization.orgCode).then(() => {
      console.log(deleteTargetOrganization.orgCode, 'orgCode');
      setDeleteTargetOrganization(null);
      successNotification('削除しました。');
      // 削除後にデータを再取得
      fetchData();
    }).catch((error) => {
      setDeleteTargetOrganization(null);
      if (error.response && error.response.data && error.response.data.errors) {
        let msgError = '';
        error.response.data.errors.map((item: { defaultMessage: string; }) => {
          msgError += `${item.defaultMessage} \n`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [deleteTargetOrganization, errorNotification, fetchData, successNotification]);
  console.log(deleteTargetOrganization, 'deleteTargetOrganization');

  return {
    deleteTargetOrganization,
    setDeleteTargetOrganization,
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteOrganization,
  };
};

export const useDownload = (
  noManager: boolean,
  orgStatus: string,
  categoryArr: any,
  unsetTargetDate: Date,
  sort: string,
  order: string,
) => {
  const downloadOrganization = useCallback(() => {
    api.downloadOrganizationCsv(
      noManager,
      Number(orgStatus),
      new Date(),
      categoryArr,
      sort,
      order,
      noManager ? unsetTargetDate : undefined,
    );
  }, [categoryArr, noManager, orgStatus, unsetTargetDate, sort, order]);
  const downloadManagerCsv = useCallback(() => {
    api.downloadManagerCsv(
      noManager,
      Number(orgStatus),
      new Date(),
      categoryArr,
      sort,
      order,
      noManager ? unsetTargetDate : undefined,
    );
  }, [categoryArr, noManager, orgStatus, unsetTargetDate, sort, order]);

  const downloadSpecialSalaryCsv = useCallback(() => {
    api.downloadSpecialSalaryCsv(
      noManager,
      Number(orgStatus),
      new Date(),
      categoryArr,
      sort,
      order,
      noManager ? unsetTargetDate : undefined,
    );
  }, [categoryArr, noManager, orgStatus, unsetTargetDate, sort, order]);

  const downloadCompanyBankCsv = useCallback(() => {
    api.downloadCompanyBankCsv(
      noManager,
      Number(orgStatus),
      new Date(),
      categoryArr,
      noManager ? unsetTargetDate : undefined,
    );
  }, [categoryArr, noManager, orgStatus, unsetTargetDate]);
  return {
    downloadOrganization,
    downloadManagerCsv,
    downloadSpecialSalaryCsv,
    downloadCompanyBankCsv,
  };
};

export default {};
