/* eslint-disable max-len */
import { useState, useCallback, useEffect } from 'react';
import { SalarySupportStaff } from 'domain/master/general/salaryDataStaff';

export const useOrganizationSupportEdit = (support: SalarySupportStaff,
  setSupport: (support: SalarySupportStaff) => void) => {
  const [addHourSalary, setAddHourSalary] = useState<number>(0);

  const [supportTransportation, setSupportTransportation] = useState<number>(0);

  useEffect(() => {
    setAddHourSalary(support.addHourlySalary);
    setSupportTransportation(support.supportTransportation);
  }, [support]);

  const changeAddHourlySalary = useCallback((a: number) => {
    setAddHourSalary(a);
    setSupport({
      ...support,
      addHourlySalary: a,
    });
  }, [setSupport, support]);

  const changeSupportTransportation = useCallback((a: number) => {
    setSupportTransportation(a);
    setSupport({
      ...support,
      supportTransportation: a,
    });
  }, [setSupport, support]);


  return {
    addHourSalary,
    supportTransportation,
    changeAddHourlySalary,
    changeSupportTransportation,
  };
};

export default {};
