import Button, { PrimaryButton } from 'components/atoms/Button';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import TextForm from 'components/molecules/TextForm';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import ConfirmModal from 'components/organismos/ConfirmModal';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAdvertisingCreateForm from './hook';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const AdvertisingCreateForm: React.FC = () => {
  const { advertisingPlanMstCode } = useParams();
  const {
    formik,
    confirmModalOpen,
    contentModalCustom,
    closeConfirmModal,
    history,
    confirmModalCustomOpen,
    closeConfirmModalCustom,
  } = useAdvertisingCreateForm();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  useEffect(() => {
    if (roleScreen && roleScreen.available === false) {
        history.push('/404')
    }
  }, [roleScreen]);

  return (
    <FormContents>
      <p>{advertisingPlanMstCode ? '広告プランを編集します。コード、名称、並び順を入力してください。' : '広告プランを登録します。コード、名称、並び順を入力してください。'}</p>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormField>
            <TextForm
              label="コード"
              name="advertisingPlanMstCode"
              maxLength={6}
              value={formik.values.advertisingPlanMstCode}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value))) {
                  formik.handleChange(e);
                }
              }}
              errorMsg={formik.errors.advertisingPlanMstCode}
              required={true}
              disable={!!advertisingPlanMstCode}
            />
          </FormField>
          <FormField>
            <TextForm
              maxLength={50}
              label="名称"
              name="advertisingPlanMstName"
              value={formik.values.advertisingPlanMstName}
              onChange={formik.handleChange}
              errorMsg={formik.errors.advertisingPlanMstName}
              required={true}
            />
          </FormField>

          <FormField>
            <TextForm
              label="並び順"
              name="dispOrder"
              maxLength={6}
              value={formik.values.dispOrder}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value))) {
                  formik.handleChange(e);
                }
              }}
              errorMsg={formik.errors.dispOrder}
              required={true}
            />
          </FormField>
        </div>

        <FormSubmitArea>
          { roleScreen && roleScreen.editable === 1 && (
          <div style={{ marginRight: '12px' }}>
            <Button
              text={advertisingPlanMstCode ? '広告プランを更新' : '広告プランを登録'}
              onClick={formik.handleSubmit}
            />
          </div>
          )}
          <PrimaryButton
            text="戻る"
            onClick={() => {
              history.replace('/salesAdvertisingPlan/advertisingMaster');
            }}
            ghost={true}
          />
        </FormSubmitArea>
      </form>

      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={advertisingPlanMstCode ? ActionType.UPDATE : ActionType.CREATE}
      />
      <ConfirmModal
        title="確認メッセージ"
        open={confirmModalCustomOpen}
        closeHandler={closeConfirmModalCustom}
        submitText={advertisingPlanMstCode ? '更新' : '登録'}
        content={contentModalCustom}
        onSubmit={formik.handleSubmit}
      />
    </FormContents>
  );
};
export default AdvertisingCreateForm;
