import moment from 'moment';
import { HopeShiftDomain } from 'domain/master/general/hopeShift';
import ApiClient from './ApiClient';

export enum ScheduleStatus {
  NO_REGISTER = 0,
  ACTUAL = 1,
  APPROVED = 2,
  NOT_APPROVED = 3,
  DAY_OFF = 5,
  UNSUBMITED = 9,
}

export enum ScheduleStatusColor {
  '#ffffff' = 0,
  '#E7E7E7' = 1,
  '#9FBDD4' = 2,
  '#D6EAE3' = 3,
  '#FEB8B9' = 5,
  '#FFFFFF' = 9,
}

type MonthlyShift = {
  targetDate: string,
  dayDow: number,
  isEditable: number,
  dayStatus: number,
  fixStatus: number,
  orgCode: string,
  employmentId: string,
  staffCode: string,
  shiftPatternId: string,
  shiftPatternCode: string,
  shiftPatternName: string,
  shortName: string,
  startTime: Date,
  endTime: Date,
  holidayCode: string,
  holidayName: string,
}

export type ScheduleOperationInfo = {
  companyCode: string,
  staffCode: string,
  targetMonth: string,
  openDate: Date,
  closeDate: Date,
  calcOverTimeInWeek: Boolean,
  /* totalCost: number,*/
  transportation: number,
  /* monthTotalCost: number,*/
  monthTransportation: number,
  totalWorkTime: number,
  monthTotalworkTime: number,
  overTime: number,
  totalWorkTimeHour: number,
  totalWorkTimeMin: number,
  monthTotalworkTimeHour: number,
  monthTotalworkTimeMin: number,
  overTimeHour: number,
  overTimeMin: number,
  weeklyOvertimeHour: number,
  weeklyOvertimeMin: number
}

export type ShiftDaily = {
  shiftId: string;
  orgCode: string;
  orgName?: string;
  belongOrgCode?: string;
  staffCode: string;
  targetDate?: string;
  holidayId?: unknown;
  holidayCode?: unknown;
  holidayName?: unknown;
  holidayType?: unknown;
  isLegal?: boolean;
  startTime: string;
  endTime: string;
  shiftPatternId?: string;
  shiftPatternCode?: string;
  shiftPatternName?: string;
  shortName?: string;
  shiftSupportRequestId?: unknown;
  status?: unknown;
  companyCode?: string;
  distinctionHoliday?: boolean,
  workSystem?: number;
  shiftAlertMessage?: unknown;
  shiftIdToString?: string;
  holidayIdToString?: string;
  shiftPatternIdToString?: string;
  shiftSupportRequestIdToString?: string;
}

export type StaffDailyShift = {
  staffCode: string;
  staffName: string;
  belongOrgCode?: string;
  belongOrgName?: string;
  isSupportStaff?: boolean;
  employmentId?: string;
  workingForm?: number;
  isAlert: boolean;
  alertMessage?: string;
  employmentIdToString?: string;
  shiftDailyList: Array<ShiftDaily>;
}

export type ShiftDailyStaff = {
  staffCode: string;
  staffName: string;
  belongOrgCode: string;
}

export type ShiftDetail = {
  startTime: string;
  isStartTimeNextDay: boolean;
  endTime: string;
  isEndTimeNextDay: boolean;
  businessId?: string;
  createUser?: string;
  updateUser?: string;
}

export type ShiftPostData = {
  orgCode: string;
  staffCode: string;
  targetDate: string;
  procType: number;
  attendType: number;
  shiftId?: string;
  shiftPatternId?: string;
  startTime?: string;
  endTime?: string;
  createUser?: string;
  updateUser?: string;
  shiftDetailList: Array<ShiftDetail>;
}

export const getDrawingInfo = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const orgCode = sessionStorage.getItem('loginUser.orgCode') || '';

  const params = {
    orgCode,
  };
  const response = await ApiClient.get(`/v1/shift/daily/drawingInfo/${companyCode}`, params, undefined);
  return response.data;
};

export const getStaffWorkList = async (
  targetDate: Date,
  viewPeriod: number,
): Promise<Array<MonthlyShift>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY/MM/DD');

  const params = {
    staffCode,
    targetDate: targetDateStr,
    viewPeriod,
  };
  const response = await ApiClient.get(`/v2/personal/getStaffWorkList/${companyCode}`, params, undefined);
  return response.data;
};

export const getOperationInfo = async (targetDate: Date): Promise<ScheduleOperationInfo> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY/MM/DD');

  const params = {
    staffCode,
    targetDate: targetDateStr,
  };
  const response = await ApiClient.get(`/v2/personal/getOperationInfo/${companyCode}`, params, undefined);
  return response.data;
};

/*
  export const getYearlyAmountPaid = async (targetDate: Date): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY/MM/DD');

  const params = {
    staffCode,
    targetDate: targetDateStr,
  };
  const response = await ApiClient.get(`/v2/personal/getYearlyAmountPaid/${companyCode}`, params, undefined);
  return response.data;
};
*/

export const getPaidHoliday = async (targetDate: Date): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY/MM/DD');

  const params = {
    staffCode,
    targetDate: targetDateStr,
  };
  const response = await ApiClient.get(`/v2/personal/getPaidHoliday/${companyCode}`, params, undefined);
  return response.data;
};


export const postHopeShifts = async (
  targetDate: Date,
  viewPeriod: number,
  hopeShiftsPostData: HopeShiftDomain[],
): Promise<Array<MonthlyShift>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY/MM/DD');

  const queryParam = {
    staffCode,
    targetDate: targetDateStr,
    viewPeriod,
  };

  const postData: any = {
    hopeShiftList: hopeShiftsPostData.map(
      (item) => {
        const startTime = item.startTime === null ? '' : moment(item.startTime).format('YYYY-MM-DDTHH:mm:ss');
        let endTime = item.endTime === null ? '' : moment(item.endTime).format('YYYY-MM-DDTHH:mm:ss');
        if (item.endTime && item.hopeShiftPatternType === 1) {
          if (endTime <= startTime) {
            endTime = moment(item.endTime).add(1, 'd').format('YYYY-MM-DDTHH:mm:ss');
          } else {
            const tmpEndTime = moment(item.targetDate).hour(moment(item.endTime).get('hour')).minutes(moment(item.endTime).get('minute')).format('YYYY-MM-DDTHH:mm:ss');
            if (tmpEndTime > startTime) {
              endTime = tmpEndTime;
            }
          }
        }

        const x = {
          ...item.getRawData(),
          startTime,
          endTime,
        };

        delete (x as any).isEditable;
        return x;
      },
    ).filter((item) => item.fixStatus === 9),

  };

  const response = await ApiClient.post(`/v2/personal/createHopeShifts/${companyCode}`, queryParam, postData);

  return response.data;
};

export default {};
