import React, { useState } from 'react';
import TabControl from 'components/atoms/TabControl';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import { useParams, useHistory } from 'react-router-dom';
import FormContents from 'components/atoms/Form/FormContents';
import MediaMasterPage from './MediaMasterPage';
import PaymentImportMasterPage from './PaymentImportMasterPage';

const PaymentTypePage: React.FC = () => {
  const { currentTab } = useParams<{ currentTab: any }>();
  const [value, setValue] = useState((currentTab === 'paymentMaster' || currentTab === 'paymentSetting') ? currentTab : 'mediaMaster');
  const history = useHistory();

  return (
    <>
      <SidebarTemplate pageTitle="支払種別マスタ">
        <FormContents>
          <TabControl
            items={[
              { value: 'paymentMaster', display: '支払種別' },
              { value: 'paymentSetting', display: '支払取込一覧' },
            ]}
            setValue={(val: string) => {
              sessionStorage.setItem('selectedOrgCode', sessionStorage.getItem('loginUser.orgCode')!);
              setValue(val);
              history.push(`/salesPaymentMedia/${val}`);
            }}
            currentValue={value}
            isFixed={true}
          />
          {value === 'paymentMaster'
            ? <MediaMasterPage />
            : <PaymentImportMasterPage />}
        </FormContents>
      </SidebarTemplate>
    </>
  );
};

export default PaymentTypePage;
