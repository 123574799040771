/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from '@emotion/core';

import productColor, { utilityColor, iconColor as styleIconColor } from 'components/styles';
import ClickableLink from 'components/atoms/ClickableLink';
import Icon, { IconType } from 'components/atoms/Icon';

interface IconButtonProps {
  iconType: IconType;
  onClick: () => void;
  iconColor?: string;
  iconHoverColor?: string;
  linkColor?:string;
  linkHoverColor?:string;
  disabled?: boolean;
  to?: string;
  target?: string;
}

/**
 * アイコンボタン（一覧の編集アイコンや削除アイコンで使用）
 */
const IconButton: React.FC<IconButtonProps> = ({
  iconType, onClick, iconColor = utilityColor.white, iconHoverColor = styleIconColor.gray, linkColor = productColor.primary, linkHoverColor = productColor.primaryM95, disabled = false, to, target
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <ClickableLink
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
      color={linkColor}
      hoverColor={linkHoverColor}
      disabled={disabled}
      to={to}
      target={target}
    >
      <Icon
        type={iconType}
        color={isHover ? iconHoverColor : iconColor}
        hoverColor={isHover ? iconHoverColor : iconColor}
        disabled={disabled}
      />
    </ClickableLink>
  )
};

export default IconButton;
