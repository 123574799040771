import ApiClient from 'api/ApiClient';

export interface HeadQuarters {
    companyCode: string;
    orgCode: string;
    targetDate: Date;
    status : number;
    totalSales : number;
    cashAmount : number;
    ccAmount : number;
    cashVoucherPaymentTotalAmount : number;
    moneyTicketChange : number;
    kakeuriAmount : number;
    realExcessAndDeficiency : number;
    excessAndDeficiency : number;
    changeReserve : number;
    foodAmount : number;
    drinkAmount : number;
    otherAmount : number;
    totalPettyAmount: number;
    totalWithdrawal : number;
    pettyWithdrawalDiff : number;
    voidCnt : number;
    compulsoryCancelAmount : number;
    orderMenuCancelAmount : number;
    totalDiscountAmount : number;
    clearingDate : Date;
    applicationDate : Date;
    applicationUser : string;
    approvalDate : Date;
    approvalUser : string;
  }

export const apiGetHeadQuarters = async (
  path: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  categoryAry: any,
)
  : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    loginStaffCode,
    functionType: 3,
  };

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }

  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/${path}/${companyCode}?${appendUrl}`, params);
  return response.data;
};

export const apiGetHeadQuarters2 = async (
  path: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  monthlyComparePrevYear: any,
  categoryAry: any,
)
  : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    monthlyComparePrevYear,
    loginStaffCode,
    functionType: 3,
  };

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }

  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/${path}/${companyCode}?${appendUrl}`, params);
  return response.data;
};

