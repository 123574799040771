
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AdvertisingImportForm from '../AdvertisingImportForm';

const AdvertisingImportPage = () => (
  <SidebarTemplate pageTitle="広告プランインポート">
    <AdvertisingImportForm />
  </SidebarTemplate>
);

export default AdvertisingImportPage;
