/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FlexBox from 'components/atoms/FlexBox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import moment from 'moment';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import MultipleCheckboxForm from 'components/molecules/MultipleCheckboxForm';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import useToastNotification from 'hooks/useToastNotification';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import BlockUI from 'components/molecules/BlockUi';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import useItemsReport from './hooks';
import ItemSalesOrderTable from './ItemSalesOrderTable_new';
import ItemCntOrderTable from './ItemCntOrderTable_new';
import ItemCategorySalesOrderTable from './ItemCategorySalesOrderTable_new';
import ItemCategoryCntOrderTable from './ItemCategoryCntOrderTable_new';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import { getOrgNameFromOrgOptionLabel } from 'utility/formatUtil';

const FormByProductLayout = () => {
  const datefrom = new Date(moment().subtract(1, 'days').toString());
  const dateTo = new Date(moment().toString());
  const [targetDateFrom, setTargetDateFrom] = useState(datefrom);
  const [targetDateTo, setTargetDateTo] = useState(dateTo);
  const [orgTreesOptions, setOrgTreesOptions] = useState([]);

  //
  const functionType = 3;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
    undefined,
  );


  const st = new Date();
  st.setMonth(0);
  st.setDate(1);
  const ed = new Date(st);
  if (!targetDateFrom) {
    setTargetDateFrom(st);
  }

  if (!targetDateTo) {
    setTargetDateTo(ed);
  }

  const [showTabs, setShowTabs] = useState(0);

  const [orgCode, setOrgCode] = useState('all');
  const [orgName, setOrgName] = useState('全ての組織');
  const [getItemStartTime, setItemStartTime] = useState('05');
  const [getItemEndTime, setItemEndTime] = useState('04');
  const { itemReport, itemCategoryReport, formik, getIsLoading, setIsLoading } = useItemsReport(
    String(orgCode), moment(targetDateFrom).format('YYYY-MM-DD'),
    getItemStartTime, moment(targetDateTo).format('YYYY-MM-DD'),
    getItemEndTime,
    showTabs,
    categoryAry,
  );
  const targetPeriodData = `対象期間：${moment(targetDateFrom).format('YYYY年MM月DD日')}~${moment(targetDateTo).format('YYYY年MM月DD日')}`;

  const itemStartTimeList:any[] = [];
  for (let i = 0; i < 24; i += 1) {
    itemStartTimeList.push({
      label: String(i < 10 ? `0${i}` : i),
      value: String(i < 10 ? `0${i}` : i),
    });
  }
  const { errorNotification } = useToastNotification();

  const expandTabs = (status:number) => {
    if (!orgCode || orgTreesOptions.length === 0) {
      errorNotification('組織名を選択してください。');
      return false;
    }
    setShowTabs(status);
  };

  const setTargetOrgName = useCallback((argOrgCode: string) => {
    if (argOrgCode) {
      const org: any = orgTreesOptions.find((o: any) => o.value === argOrgCode);
      setOrgName(org?.label ? getOrgNameFromOrgOptionLabel(org.label) : '');
    }
  }, [orgTreesOptions]);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <div>
      <FormContents>
        <FlexBox>
          <FlexBoxItem width="100px">
            <FormLabel
              label="対象期間"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label=""
              date={targetDateFrom}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDateFrom(date);
                }
              }}
              isFullWidth={true}
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <span style={{ margin: '0 30px' }}>～</span>
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label=""
              date={targetDateTo}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDateTo(date);
                }
              }}
              isFullWidth={true}
            />
          </FlexBoxItem>
        </FlexBox>

        <OrganizationFilteredTree
          functionType={functionType}
          orgCallback={(args: string | Array<string>) => {
            setOrgCode(String(args));
            setTargetOrgName(String(args));
          }}
          orgCategoryCallback={
              (args: Array<string>) => {
                setCategoryAr(args);
              }
            }
          initOrgValue={String(orgCode)}
          staffCode={loginStaffCode}
          orgLabel="組織名"
          addAllItem={true}
          targetdayForm={targetDateFrom}
          targetdayTo={targetDateTo}
          orgOrgOptionListCallback={setOrgTreesOptions}
        />

        <FlexBox>
          <FlexBoxItem width="105px">
            <FormLabel label="対象曜日" />
          </FlexBoxItem>
          <FlexBoxItem width="100%">
            <MultipleCheckboxForm
              name="stateStatus"
              label=""
              items={[
                {
                  id: 'itemPub',
                  label: '祝日',
                  value: formik.values.itemPub,
                },
                {
                  id: 'itemSat',
                  label: '土曜日',
                  value: formik.values.itemSat,
                },
                {
                  id: 'itemSun',
                  label: '日曜日',
                  value: formik.values.itemSun,
                },
                {
                  id: 'itemMon',
                  label: '月曜日',
                  value: formik.values.itemMon,
                },
                {
                  id: 'itemTue',
                  label: '火曜日',
                  value: formik.values.itemTue,
                },
                {
                  id: 'itemWed',
                  label: '水曜日',
                  value: formik.values.itemWed,
                },
                {
                  id: 'itemTur',
                  label: '木曜日',
                  value: formik.values.itemTur,
                },
                {
                  id: 'itemFri',
                  label: '金曜日',
                  value: formik.values.itemFri,
                },
              ]}
              setValue={(e) => formik.setFieldValue(String(e.target.id), !(e.target.value === 'true'))}
            />
          </FlexBoxItem>
        </FlexBox>

        <div style={{
          display: 'flex', alignItems: 'center', marginTop: '15px', fontSize: '14px',
        }}
        >
          <span style={{ marginRight: '45px' }}>対象時間</span>
          <span style={{ width: '100px' }}>
            <VerticalLabelSelectForm
              label=""
              name="itemStartTime"
              value={String(getItemStartTime)}
              setValue={
                (val) => setItemStartTime(val)
              }
              options={itemStartTimeList}
            />
          </span>
          <span style={{ marginLeft: '5px' }}>時</span>
          <span style={{ margin: '0 10px' }}>～</span>
          <span style={{ width: '100px' }}>
            <VerticalLabelSelectForm
              label=""
              name="itemEndTime"
              value={String(getItemEndTime)}
              setValue={(val) => setItemEndTime(val)}
              options={itemStartTimeList}
            />
          </span>
          <span style={{ marginLeft: '5px' }}>時</span>
        </div>

        <FormField>
          <FormSubmitArea>
            <div style={{ display: 'flex', marginTop: '0px' }}>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton
                  text="ABC売上順"
                  onClick={() => expandTabs(1)}
                  ghost={!(showTabs === 1)}
                />
              </div>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton
                  text="ABC数量順"
                  onClick={() => expandTabs(2)}
                  ghost={!(showTabs === 2)}
                />
              </div>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton
                  text="部門別ABC売上順"
                  onClick={() => expandTabs(3)}
                  ghost={!(showTabs === 3)}
                />
              </div>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton
                  text="部門別ABC数量順"
                  onClick={() => expandTabs(4)}
                  ghost={!(showTabs === 4)}
                />
              </div>
            </div>
          </FormSubmitArea>
        </FormField>

        <div>
          {showTabs === 1
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                  <ItemSalesOrderTable
                   setIsLoading={setIsLoading}
                   itemReport={itemReport}
                   targetPeriodData={targetPeriodData}
                   itemStartTime={getItemStartTime}
                   itemEndTime={getItemEndTime}
                   formik={formik}
                   orgCode={orgCode}
                   orgName={orgName}
                   orgTreesOptions={orgTreesOptions}
                   roleScreen={roleScreen}
                 />
               </BlockUI>
             </div>
           </div>
           )}
          {showTabs === 2
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <ItemCntOrderTable
                   setIsLoading={setIsLoading}
                   itemReport={itemReport}
                   targetPeriodData={targetPeriodData}
                   itemStartTime={getItemStartTime}
                   itemEndTime={getItemEndTime}
                   formik={formik}
                   orgCode={orgCode}
                   orgName={orgName}
                   orgTreesOptions={orgTreesOptions}
                   roleScreen={roleScreen}
                 />
               </BlockUI>
             </div>
           </div>
           )}
          {showTabs === 3
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <ItemCategorySalesOrderTable
                   setIsLoading={setIsLoading}
                   itemCategoryReport={itemCategoryReport}
                   targetPeriodData={targetPeriodData}
                   itemStartTime={getItemStartTime}
                   itemEndTime={getItemEndTime}
                   formik={formik}
                   orgCode={orgCode}
                   orgName={orgName}
                   orgTreesOptions={orgTreesOptions}
                   roleScreen={roleScreen}
                 />
               </BlockUI>
             </div>
           </div>
           )}
          {showTabs === 4
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <ItemCategoryCntOrderTable
                   setIsLoading={setIsLoading}
                   itemCategoryReport={itemCategoryReport}
                   targetPeriodData={targetPeriodData}
                   itemStartTime={getItemStartTime}
                   itemEndTime={getItemEndTime}
                   formik={formik}
                   orgCode={orgCode}
                   orgName={orgName}
                   orgTreesOptions={orgTreesOptions}
                   roleScreen={roleScreen}
                 />
               </BlockUI>
             </div>
           </div>
           )}
        </div>
      </FormContents>
    </div>
  );
};

export default FormByProductLayout;
