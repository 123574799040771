import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

export const downloadCSV = async (
  fileName: string,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsv(`/v2/accountTitleSubMst/outputCsv/${companyCode}`, {}, getDownloadCsvFileName(`${fileName}_`));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v2/accountTitleSubMst/csv/import/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export const getAccountTitleMSTList = async (accountTitleId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = { accountTitleId };
  const response = await ApiClient.get(`/v2/accountTitleSubMst/list/${companyCode}`, params);
  return response.data;
};

export const getAccountTitleMSTDetail = async (
  accountTitleSubId: string): Promise<any> => {
  const params = {
    accountTitleSubId,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/accountTitleSubMst/${companyCode}`, params);
  return response.data;
};

export const saveAccountTitleMSTDetail = async (accountTitleId: string,
  accountTitleSubId: string, data: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params: any = {
    companyCode,
    accountTitleId,
    accountTitleSubId: '',
    accountTitleSubCode: data.accountTitleSubCode,
    accountTitleSubName: data.accountTitleSubName,
    createUser: staffCode,
    updateUser: staffCode,
  };
  if (accountTitleSubId && accountTitleSubId !== 'undefined') {
    params.accountTitleSubId = accountTitleSubId;
  }
  const response = await ApiClient.post(`/v2/accountTitleSubMst/save/${companyCode}`, {}, params);
  return response.data;
};

export const deleteAccountTitleMST = async (
  accountTitleSubId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    accountTitleSubId,
  };

  const response = await ApiClient.delete(`/v2/accountTitleSubMst/delete/${companyCode}`, params);
  return response.data;
};

export type Error = {
  error: string;
  defaultMessage: string;
}


export const getAccountTreeOption = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {};
  const response = await ApiClient.get(`/v2/accountTitleMst/list/${companyCode}`, params);
  return response.data;
};
export default { };
