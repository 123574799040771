import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import SelectFormVirtical from 'components/molecules/SelectFormVirtical';
import FormField from 'components/atoms/Form/FormField';
import DatePickerForm from 'components/molecules/DatePickerForm';
import TextForm from 'components/molecules/TextForm';
import moment from 'moment';

const LicenseForm: React.FC<{
  state: any,
  handleOnChange: any,
}> = ({
  state,
  handleOnChange
}) => {

    const reAlphaNumbericSymbols = (value: string) => {
      const re = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\b\n ]*$/;
      return re.test(value);
    }

    return (
      <React.Fragment>
        {/* 保健所 */}

        <FormTitle title="保健所" />

        <FormField>
          <DatePickerForm
            dateFormat="yyyy/MM/dd"
            label="営業許可書有効開始日"
            date={state.businessLicenseStartDay !== '' ? moment(state.businessLicenseStartDay).toDate() : undefined}
            changeDate={(date: Date) => {
              if (date !== null) {
                handleOnChange(moment(date).format("YYYY/MM/DD"), 'businessLicenseStartDay', moment(date).format("YYYY/MM/DD"))
              }
            }}
          />

          <DatePickerForm
            dateFormat="yyyy/MM/dd"
            label="営業許可証有効期限日"
            date={state.businessLicenseEndDay !== '' ? moment(state.businessLicenseEndDay).toDate() : undefined}
            changeDate={(date: Date) => {
              if (date !== null) {
                handleOnChange(moment(date).format("YYYY/MM/DD"), 'businessLicenseEndDay', moment(date).format("YYYY/MM/DD"))
              }
            }}
          />
        </FormField>

        <FormField>
          <TextForm
            name="foodHygieneOfficer"
            label="食品衛生責任者"
            value={state.foodHygieneOfficer}
            onChange={handleOnChange}
          />
        </FormField>

        <FormField>
          <TextForm
            name="foodHygieneOfficerTel"
            label="食品衛生責任者連絡先"
            value={state.foodHygieneOfficerTel}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)) {
                handleOnChange(e)
              }
            }}
          />
        </FormField>

        <FormField>
          <TextForm
            name="healthCenter"
            label="管轄保健所名"
            value={state.healthCenter}
            onChange={handleOnChange}
          />
        </FormField>

        <FormField>
          <TextForm
            name="healthCenterTel"
            label="管轄保健所連絡先"
            value={state.healthCenterTel}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)) {
                handleOnChange(e)
              }
            }}
          />
        </FormField>

        <FormField>
          <TextForm
            name="tradeName"
            label="店舗名（営業所名称、屋号）"
            value={state.tradeName}
            onChange={handleOnChange}
          />
        </FormField>

        <FormField>
          <TextForm
            name="businessLicenseOperatingCompanyRepresentative"
            label="営業許可証運営会社名"
            value={state.businessLicenseOperatingCompanyRepresentative}
            onChange={handleOnChange}
          />
        </FormField>

        {/* 消防署 */}

        <FormTitle title="消防署" />

        <FormField>
          <TextForm
            name="firePreventionManagementResponsible"
            label="防火管理責任者"
            value={state.firePreventionManagementResponsible}
            onChange={handleOnChange}
          />
        </FormField>

        <FormField>
          <TextForm
            name="firePreventionManagementResponsibleTel"
            label="防火責任者連絡先"
            value={state.firePreventionManagementResponsibleTel}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)) {
                handleOnChange(e)
              }
            }}
          />
        </FormField>

        <FormField>
          <TextForm
            name="fireStation"
            label="管轄消防署名"
            value={state.fireStation}
            onChange={handleOnChange}
          />
        </FormField>

        <FormField>
          <TextForm
            name="fireStationTel"
            label="管轄消防署連絡先"
            value={state.fireStationTel}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)) {
                handleOnChange(e)
              }
            }}
          />
        </FormField>

        <FormField>
          <TextForm
            name="firePreventionObjectInspector"
            label="防火対象物点検業者"
            value={state.firePreventionObjectInspector}
            onChange={handleOnChange}
          />
        </FormField>

        <FormField>
          <TextForm
            name="buildingManagementNote"
            label="特記事項（ビル管）"
            value={state.buildingManagementNote}
            onChange={handleOnChange}
          />
        </FormField>

        <FormField>
          <DatePickerForm
            dateFormat="yyyy/MM/dd"
            label="防火対象物点検日"
            date={state.firePreventionObjectInspectionDate !== '' ? moment(state.firePreventionObjectInspectionDate).toDate() : undefined}
            changeDate={(date: Date) => {
              if (date !== null) {
                handleOnChange(moment(date).format("YYYY/MM/DD"), 'firePreventionObjectInspectionDate', moment(date).format("YYYY/MM/DD"))
              }
            }}
          />

          <DatePickerForm
            dateFormat="yyyy/MM/dd"
            label="消防点検日"
            date={state.fireInspectionDate !== '' ? moment(state.fireInspectionDate).toDate() : undefined}
            changeDate={(date: Date) => {
              if (date !== null) {
                handleOnChange(moment(date).format("YYYY/MM/DD"), 'fireInspectionDate', moment(date).format("YYYY/MM/DD"))
              }
            }}
          />
        </FormField>

        {/* 警察署 */}

        <FormTitle title="警察署" />

        <FormField displayBlock={true}>
          <SelectFormVirtical
            label="深夜酒類提供飲食店営業開始届出"
            fullWidthLabel={true}
            name="lateNightAlcoholOfferNotificationType"
            value={String(state.lateNightAlcoholOfferNotificationType)}
            setValue={(val) => { handleOnChange(val, 'lateNightAlcoholOfferNotificationType', val) }}
            options={[
              { label: '無', value: '0' },
              { label: '有', value: '1' },
            ]}
          />
        </FormField>
      </React.Fragment>
    );
  };

export default LicenseForm;
