/** @jsx jsx */
import React, { Dispatch, useState } from 'react';
import { Link } from 'react-router-dom';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import DatePickerForm from 'components/molecules/DatePickerForm';
import FormContents from 'components/atoms/Form/FormContents';
import StaffDomain from 'domain/staff';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import { TablItem } from 'components/pages/master/general/staff/StaffAddPage/StaffAddPage';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import { EmploymentDataStaffDomain } from 'domain/master/general/employmentDataStaff';
import { useEmploymentForm } from './hooks';
import { InfoMessage } from 'utility/messages';
import { css, jsx } from '@emotion/core';
import ClickableLink from 'components/atoms/ClickableLink';
import productColor, { iconColor, utilityColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import IconButton from 'components/molecules/IconButton';

type SelectType = 'staff'|'affiliation'|'permission'|'employment'|'laborCost'|'workPattern';
const SelectType: {[key in SelectType]: SelectType} = {
  staff: 'staff',
  affiliation: 'affiliation',
  permission: 'permission',
  employment: 'employment',
  laborCost: 'laborCost',
  workPattern: 'workPattern',
};

const EmploymentInfoForm: React.FC<{
  initialStaffDomain?: StaffDomain;
  isEditMode?: boolean;
  tabItems: TablItem[];
  setInitialStaffDomain: Dispatch<React.SetStateAction<StaffDomain>>;
  setSelectType: Dispatch<React.SetStateAction<SelectType>>;
  setTabItems: Dispatch<React.SetStateAction<TablItem[]>>;
  setEmpTargetDate?: React.Dispatch<React.SetStateAction<Date>>;
  employmentOptions: Array<any>;
  setIsLoading: Dispatch<React.SetStateAction<boolean>>;
  menuType: string;
  roleScreen?: any;
}> = ({
  initialStaffDomain, isEditMode = false, tabItems,
  setInitialStaffDomain, setSelectType, setTabItems,
  setEmpTargetDate,
  employmentOptions,
  setIsLoading,
  menuType,
  roleScreen,
}) => {
  const {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    onSubmitDeleteStaffEmployment,
    setDeleteTargetStaffEmployment,
    isOpenDeleteModal,
    closeDeleteModal,
    } = useEmploymentForm(
    initialStaffDomain,
    isEditMode,
    setIsLoading,
    menuType,
  );

  const columns: Array<Column<EmploymentDataStaffDomain>> = React.useMemo(() => [
    {
      Header: '適用開始月',
      accessor: 'applyStartDateStr',
    },
    {
      Header: '雇用形態コード',
      accessor: 'employmentCode',
    },
    {
      Header: '雇用形態名',
      accessor: 'employmentName',
    },
    {
      Header: () => null,
      accessor: 'delete',
      id: 'deleteButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: EmploymentDataStaffDomain, index: number }}) => {
        const lastIndex = formik.values.employmentDataList?.length - 1 ?? 0;
        return (<React.Fragment>
            {(cell.row.index === lastIndex && roleScreen && roleScreen.editable === 1) && (
              <IconButton
                iconType="delete"
                onClick={() => setDeleteTargetStaffEmployment(cell.row.original)}
                iconColor={iconColor.red}
                iconHoverColor={utilityColor.white}
                linkHoverColor={productColor.deleteActionHover}
              />
            )}
          </React.Fragment>);
      },
    },

  ], [formik, roleScreen]);
  return (
    <React.Fragment>
      {formik.values.employmentDataList
      && formik.values.employmentDataList.length !== 0 && (
        <div css={css`
            table th:nth-child(4) {
              width: 42px;
            }
          `}
        >
          <DataTable
            columns={columns}
            data={formik.values.employmentDataDomainList}
            sortBy={[
              {id: 'applyStartDateStr', desc: true}
            ]}
          />
        </div>
      )}

      <form onSubmit={formik.handleSubmit}>
        <FormContents>
          <FormField>
            <DatePickerForm
              dateFormat={isEditMode ? 'yyyy/MM' : 'yyyy/MM/dd'}
              label="適用開始月"
              date={formik.values.empApplyDate}
              changeDate={(date: Date) => {
                if (date !== null) {
                  if (setEmpTargetDate) {
                    if (!date) {
                      setEmpTargetDate(new Date());
                    } else { setEmpTargetDate(date); }
                  }
                  formik.setFieldValue('empApplyDate', date);
                }
              }}
              required={true}
              disable={!isEditMode}
              isShowDateOnDisable={true}
              showMonthYearPicker={true}
            />
          </FormField>

          <FormField displayBlock={true}>
            <VerticalLabelSelectForm
              label="雇用形態"
              name="employmentId"
              value={formik.values.employmentId}
              setValue={(val: string) => { formik.setFieldValue('employmentId', val); }}
              options={employmentOptions}
              note={isEditMode ? '雇用形態を変更すると、シフトパターン設定が削除され、再登録が必要となります。' : ''}
              errorMsg={formik.errors.employmentId}
              required={true}
            />
          </FormField>
        </FormContents>

        <FormSubmitArea>
          { roleScreen && roleScreen.editable === 1 && (
            <div style={{ marginRight: '12px' }}>
              <Button
                text={isEditMode ? '雇用情報を登録' : '登録'}
                onClick={formik.handleSubmit}
              />
            </div>
          )}
          { roleScreen && roleScreen.editable === 1 && !isEditMode && (
            <div style={{ marginRight: '12px' }}>
              <Button
                text="人件費情報の入力へ"
                onClick={() => {
                  if (formik.values.empApplyDate && formik.values.employmentId) {
                    // Set salary apply day
                    formik.values.selectSalary.applyStartDate = formik.values.hireDate;

                    if (tabItems.length <= 4) {
                      setInitialStaffDomain(formik.values);

                      setTabItems([
                        {
                          value: SelectType.staff,
                          display: 'スタッフ情報',
                        },
                        {
                          value: SelectType.affiliation,
                          display: '所属設定',
                        },
                        {
                          value: SelectType.permission,
                          display: '権限設定',
                        },
                        {
                          value: SelectType.employment,
                          display: '雇用設定',
                        },
                        {
                          value: SelectType.laborCost,
                          display: '人件費設定',
                        }]);
                    }
                    setSelectType(SelectType.laborCost);
                  } else {
                    formik.validateForm();
                  }
                }}
              />
            </div>
          )}

          {menuType !== '1' && (
            <Link to="/staffs">
              <Button
                text="スタッフ一覧に戻る"
                ghost={true}
              />
            </Link>
          )}
          {menuType === '1' && (
            <Link to="/staffs/employee">
              <Button
                text="スタッフ一覧に戻る"
                ghost={true}
              />
            </Link>
          )}
        </FormSubmitArea>
      </form>

      {/* 登録内容確認モーダル */}
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
        message={isEditMode ? InfoMessage.StaffMaster.UPDATE_CONFIRM_MESSAGE_EMPLOYMENT_INFO : ''}
      />

      <ConfirmActionModal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        onSubmit={onSubmitDeleteStaffEmployment}
        actionType={ActionType.DELETE}
      />

    </React.Fragment>
  );
};

export default EmploymentInfoForm;
