/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import Add from 'components/atoms/Icon/Add';
import Analize from 'components/atoms/Icon/Analize';
import Arrow from 'components/atoms/Icon/Arrow';
import ArrowLeft from 'components/atoms/Icon/ArrowLeft';
import ArrowUp from 'components/atoms/Icon/ArrowUp';
import ArrowDown from 'components/atoms/Icon/ArrowDown';
import Auth from 'components/atoms/Icon/Auth';
import Avatar from 'components/atoms/Icon/Avatar';
import Calc from 'components/atoms/Icon/Calc';
import Calendar from 'components/atoms/Icon/Calendar';
import Chart from 'components/atoms/Icon/Chart';
import CheckboxAll from 'components/atoms/Icon/CheckboxAll';
import CheckboxOff from 'components/atoms/Icon/CheckboxOff';
import CheckboxOn from 'components/atoms/Icon/CheckboxOn';
import ChevronDown from 'components/atoms/Icon/ChevronDown';
import ChevronLeft from 'components/atoms/Icon/ChevronLeft';
import ChevronRight from 'components/atoms/Icon/ChevronRight';
import ChevronUp from 'components/atoms/Icon/ChevronUp';
import Clock from 'components/atoms/Icon/Clock';
import Close from 'components/atoms/Icon/Close';
import Company from 'components/atoms/Icon/Company';
import Dashboard from 'components/atoms/Icon/Dashboard';
import Delete from 'components/atoms/Icon/Delete';
import Download from 'components/atoms/Icon/Download';
import Dragdrop from 'components/atoms/Icon/Dragdrop';
import Dropdown from 'components/atoms/Icon/Dropdown';
import Edit from 'components/atoms/Icon/Edit';
import Employ from 'components/atoms/Icon/Employ';
import Filter from 'components/atoms/Icon/Filter';
import Hamburger from 'components/atoms/Icon/Hamburger';
import Help from 'components/atoms/Icon/Help';
import Home from 'components/atoms/Icon/Home';
import IndicatorFill from 'components/atoms/Icon/IndicatorFill';
import IndicatorWarning from 'components/atoms/Icon/IndicatorWarning';
import Kebab from 'components/atoms/Icon/Kebab';
import Lightbulb from 'components/atoms/Icon/Lightbulb';
import Lock from 'components/atoms/Icon/Lock';
import Meatball from 'components/atoms/Icon/Meatball';
import Money from 'components/atoms/Icon/Money';
import Notification from 'components/atoms/Icon/Notification';
import Organization from 'components/atoms/Icon/Organization';
import RadioOff from 'components/atoms/Icon/RadioOff';
import RadioOn from 'components/atoms/Icon/RadioOn';
import Ranking from 'components/atoms/Icon/Ranking';
import Rate from 'components/atoms/Icon/Rate';
import Remove from 'components/atoms/Icon/Remove';
import Report from 'components/atoms/Icon/Report';
import Rocket from 'components/atoms/Icon/Rocket';
import Search from 'components/atoms/Icon/Search';
import Settings from 'components/atoms/Icon/Settings';
import SingleCheck from 'components/atoms/Icon/SingleCheck';
import SinglecheckRounded from 'components/atoms/Icon/SinglecheckRounded';
import Sort from 'components/atoms/Icon/Sort';
import Telephone from 'components/atoms/Icon/Telephone';
import Upload from 'components/atoms/Icon/Upload';
import Warning from 'components/atoms/Icon/Warning';
import WarningFill from 'components/atoms/Icon/WarningFill';
import WorkScheudle from 'components/atoms/Icon/WorkScheudle';
import Switch from 'components/atoms/Icon/Switch';
import SwitchOff from 'components/atoms/Icon/SwitchOff';
import Copy from 'components/atoms/Icon/Copy';
import Comment from 'components/atoms/Icon/Comment';
import Date from 'components/atoms/Icon/Date';
import Printer from 'components/atoms/Icon/Printer';
import CloseCircle from 'components/atoms/Icon/CloseCircle';
import productColor, { grayScale, iconColor } from 'components/styles';
import AddCircle from './Icon/AddCirlcle';
import SortDefault from './Icon/SortDefault';
import SortUp from './Icon/SortUp';
import SortDown from './Icon/SortDown';
import Reload from './Icon/Reload';

const icons = {
  add: Add,
  analize: Analize,
  arrow: Arrow,
  arrowLeft: ArrowLeft,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  auth: Auth,
  avatar: Avatar,
  calc: Calc,
  calendar: Calendar,
  chart: Chart,
  checkboxAll: CheckboxAll,
  checkboxOff: CheckboxOff,
  checkboxOn: CheckboxOn,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  clock: Clock,
  close: Close,
  company: Company,
  copy: Copy,
  dashboard: Dashboard,
  delete: Delete,
  download: Download,
  dragdrop: Dragdrop,
  dropdown: Dropdown,
  edit: Edit,
  employ: Employ,
  filter: Filter,
  hamburger: Hamburger,
  help: Help,
  home: Home,
  indicatorFill: IndicatorFill,
  indicatorWarning: IndicatorWarning,
  kebab: Kebab,
  lightbulb: Lightbulb,
  lock: Lock,
  meatball: Meatball,
  money: Money,
  notification: Notification,
  organization: Organization,
  radioOff: RadioOff,
  radioOn: RadioOn,
  ranking: Ranking,
  rate: Rate,
  remove: Remove,
  report: Report,
  rocket: Rocket,
  search: Search,
  settings: Settings,
  singleCheck: SingleCheck,
  singlecheckRounded: SinglecheckRounded,
  sort: Sort,
  telephone: Telephone,
  upload: Upload,
  warning: Warning,
  warningFill: WarningFill,
  workSchedule: WorkScheudle,
  switch: Switch,
  switchOff: SwitchOff,
  comment: Comment,
  date: Date,
  printer: Printer,
  closeCircle: CloseCircle,
  addCircle: AddCircle,
  sortDefault: SortDefault,
  sortUp: SortUp,
  sortDown: SortDown,
  reload: Reload,
};

type Icons = typeof icons;
export type IconType = keyof Icons;
interface IconProps {
  type: IconType;
  color: string;
  verticalAlign?: string;
  size?: string;
  margin?: string;
  display?: string;
  isHover?: boolean;
  disabled?: boolean;
  hoverColor?: string;
}

// Figmaから作成したアイコンだとsvgの形式が違うため、分岐処理を入れる
const figmaIcons: Array<IconType> = ['notification'];

const createStyle = (
  type: IconType,
  color: string,
  verticalAlign: string,
  size: string,
  margin: string,
  display: string,
  disabled: boolean,
  hoverColor = iconColor.grayhover,
) =>
  css({
    width: size,
    margin,
    display,
    '& > svg': {
      verticalAlign,
      width: size,
      height: size,
      fill: color,
      '&:hover > g > path:nth-of-type(2)': {
        fill: hoverColor,
      },
      '& > g > path:nth-of-type(2)': {
        fill: figmaIcons.includes(type) ? undefined : color,
      },

      '& > path': {
        fill: figmaIcons.includes(type) ? color : undefined,
      },
    },
    ':hover': {
      cursor: disabled ? 'not-allowed' : 'pointer',
    },
  });

const Icon: React.FC<IconProps> = ({
  type,
  color,
  verticalAlign = 'bottom',
  size = '20px',
  margin = '0',
  display = 'unset',
  isHover = false,
  disabled = false,
  hoverColor,
}) => {
  const RenderIcon = icons[type];
  return (
    <span
      css={createStyle(
        type,
        disabled
          ? grayScale.gray60
          : isHover
          ? productColor.primaryHoverText
          : color,
        verticalAlign,
        size,
        margin,
        display,
        disabled,
        hoverColor,
      )}
    >
      <RenderIcon />
    </span>
  );
};

export default Icon;
