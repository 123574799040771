import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import SelectFormVirtical from 'components/molecules/SelectFormVirtical';
import FormField from 'components/atoms/Form/FormField';
import DatePickerForm from 'components/molecules/DatePickerForm';
import TextForm from 'components/molecules/TextForm';
import moment from 'moment';

const TerminalEquipmentSafeForm: React.FC<{
  state: any,
  handleOnChange: any,
}> = ({
  state,
  handleOnChange
}) => {

  const reAlphaNumbericSymbols = (value: string) => {
    const re =  /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\b\n ]*$/;
    return re.test(value);
  }

  const reNumberic = (value: string) => {
    const re = /^[0-9\b\n]*$/;
    return re.test(value);
  }

  const reAlphaCharacters = (value: string) => {
    const re = /^[a-zA-Z\b\n ]*$/;
    return re.test(value);
  }

  return (
    <React.Fragment>
      {/* 通信端末 */}

      <FormTitle title="通信端末" />

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="catNo"
            label="CAT番号"
            value={state.catNo}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="creditTerminalNo"
            label="クレジットの端末番号"
            value={state.creditTerminalNo}
            onChange={handleOnChange}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="providerId"
            label="プロバイダＩＤ"
            value={state.providerId}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="providerPassword"
            label="プロバイダパスワード"
            value={state.providerPassword}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="wifiId"
            label="WifiのID"
            value={state.wifiId}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="wifiPassword"
            label="WifiのPASS"
            value={state.wifiPassword}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      {/* 機器 */}

      <FormTitle title="機器" />

      <FormField>
        <TextForm
          name="securityCameraQuantity"
          label="防犯カメラ個数"
          value={state.securityCameraQuantity}
          maxLength={4}
          onChange={(e) => {
            if (reNumberic(e.target.value)){
              handleOnChange(e)
            }
          }}
        />
      </FormField>

      <FormField>
        <TextForm
          name="securityCameraMaker"
          label="防犯カメラメーカー"
          value={state.securityCameraMaker}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <TextForm
          name="securityCameraModelNo"
          label="防犯カメラ型番"
          value={state.securityCameraModelNo}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <TextForm
          name="posQuantity"
          label="ＰＯＳ個数"
          value={state.posQuantity}
          maxLength={4}
          onChange={(e) => {
            if (reNumberic(e.target.value)){
              handleOnChange(e)
            }
          }}
        />
      </FormField>

      <FormField>
        <TextForm
          name="posMaker"
          label="ＰＯＳのメーカー"
          value={state.posMaker}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <TextForm
          name="posModelNo"
          label="ＰＯＳ型番"
          value={state.posModelNo}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <TextForm
          name="posCustomerNo"
          label="ＰＯＳお客様番号"
          value={state.posCustomerNo}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <TextForm
          name="posRemark"
          label="ＰＯＳ備考"
          value={state.posRemark}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="handyQuantity"
            label="ハンディの個数"
            value={state.handyQuantity}
            maxLength={4}
            onChange={(e) => {
              if (reNumberic(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
        
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <SelectFormVirtical
            label="ハンディ保守の有無"
            name="handyConservative"
            value={String(state.handyConservative)}
            setValue={(e) => { handleOnChange (e, 'handyConservative', e)}}
            options={[
              { label: '無', value: '0' },
              { label: '有', value: '1' },
            ]}
          />
        </div>
      </FormField>

      <FormField>
        <TextForm
          name="handyMaker"
          label="ハンディのメーカー"
          value={state.handyMaker}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <TextForm
          name="handyConservativePeriod"
          label="ハンディ保守期限"
          value={state.handyConservativePeriod}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <TextForm
          name="handyModelNo"
          label="ハンディの型番"
          value={state.handyModelNo}
          onChange={handleOnChange}
        />
      </FormField>

      {/* 警察署 */}
      
      <FormTitle title="金庫" />

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="cashboxManager1"
            label="金庫管理者1"
            value={state.cashboxManager1}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="cashboxPassword1"
            label="パスワード1"
            value={state.cashboxPassword1}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="cashboxManager2"
            label="金庫管理者2"
            value={state.cashboxManager2}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="cashboxPassword2"
            label="パスワード2"
            value={state.cashboxPassword2}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>
    </React.Fragment>
  );
};

export default TerminalEquipmentSafeForm;
