
import React from 'react';
import SalesPageForm from 'components/organismos/master/general/salesPage/SalesPageStore';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const SalesPage = () => (
  <SidebarTemplate pageTitle="【店別】本部確認帳票">
    <SalesPageForm />
  </SidebarTemplate>
);

export default SalesPage;
