import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  getDateSelect,
  getDescriptionMST,
  getHeadPettyCash,
  getListPettyCash,
  savePettyCashHead,
  savePettyCashDetail,
  deletePettyCashDetail,
} from 'api/pettyCash';
import useToastNotification from 'hooks/useToastNotification';
import { useParams } from 'react-router-dom';

export const usePettyCash = () => {
  const params = useParams<any>();
  const { successNotification, errorNotification } = useToastNotification();
  const [blocking, setBlocking] = useState(false);
  const monthSelect = params.targetDate || new Date();
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date(monthSelect));
  const [optionDateSelected, setOptionDateSelected] = useState();
  const [optionDescriptionMST, setOptionDescriptionMST] = useState();
  const [getSupportDestinationOrg, setSupportDestinationOrg] = useState(
    params.orgCode || sessionStorage.getItem('loginUser.orgCode') || '',
  );
  const [pettyCashHead, setPettyCashHead] = useState();
  const [pettyCashList, setPettyCashList] = useState();
  const [totalPettyCash, setTotalPettyCash] = useState();
  const [totalPettyCashList, setTotalPettyCashList] = useState();
  const [pettyCashId, setPettyCashId] = useState('');
  const [displayBalance, setDisplayBalance] = useState(0);
  const [beginBalance, setBeginBalance] = useState(0);
  const [endBalance, setEndBalance] = useState(0);

  const [dateSelected, setDateSelected] = useState('');
  const [selectDescriptionMST, setSelectDescriptionMST] = useState('');
  const [checkCustom, setCheckCustom] = useState(0);
  const [money, setMoney] = useState(0);
  const [paymentDestination, setPaymentDestination] = useState('');
  const [remark, setRemark] = useState('');
  const [pettyCashDetail, setPettyCashDetail] = useState('');
  const [deleteDetail, setDeleteDetail] = useState('');

  const [registerData, setRegisterData] = useState(0);
  const [updatePettyHead, setUpdatePettyHead] = useState(0);

  const fetchDateSelected = async () => {
    getDateSelect(selectedMonth).then((response: any) => {
      setBlocking(false);
      let errors = '';
      if (response.status && response.status !== 200) {
        (response.data as Array<any>).map(
          (err) => {
            errors += err.defaultMessage;
          },
        );
        errorNotification(errors);
      } else {
        setDateSelected(moment(response[0].keyDate).format('YYYY/MM/DD'));
        response.map((item: any) => {
          if (moment(new Date()).format('YYYY/MM/DD') === moment(item.keyDate).format('YYYY/MM/DD')) {
            setDateSelected(moment(new Date()).format('YYYY/MM/DD'));
          }
        });
        setOptionDateSelected(response.map((item: any) => (
          {
            value: moment(item.keyDate).format('YYYY/MM/DD'),
            label: item.valueDate,
          }
        )));
      }
    }).catch((error: any) => {
      setBlocking(false);
      let errors = '';
      if (error.response.data) {
        (error.response.data as Array<any>).map(
          (err) => {
            errors += err.defaultMessage;
          },
        );
        errorNotification(errors);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  };

  const fetchPettyCashHead = async (selectedMonth: any, getSupportDestinationOrg: string) => {
    const responsePettyCashHead = await getHeadPettyCash(selectedMonth, getSupportDestinationOrg);
    if (responsePettyCashHead) {
      const responsePettyCashList = await getListPettyCash(responsePettyCashHead.pettyCashId);
      setPettyCashId(responsePettyCashHead.pettyCashId);
      if (responsePettyCashList.length > 0) {
        setPettyCashList(responsePettyCashList.filter(
          (item: any) => item.dataType === 0 || item.dataType === 1,
        ));
        setTotalPettyCashList(responsePettyCashList.filter(
          (item: any) => item.dataType === 2,
        ));
      } else {
        setPettyCashList([]);
        setTotalPettyCashList([]);
      }
      setBeginBalance(responsePettyCashHead.beginningBalance);
      setEndBalance(responsePettyCashHead.endingBalance);
    }
    setPettyCashHead(responsePettyCashHead);
  };

  const updatePettyCashHead = async (pettyCashId: string, orgCode: string, dateTarget: any, beginningBalance: number, endingBalance: number) => {
    savePettyCashHead(pettyCashId, orgCode, dateTarget, beginningBalance, endingBalance).then((response: any) => {
      setBlocking(false);
      let errors = '';
      if (response.status && response.status !== 200) {
        (response.data as Array<any>).map(
          (err) => {
            errors += err.defaultMessage;
          },
        );
        errorNotification(errors);
      } else {
        setBeginBalance(response.beginningBalance);
        setEndBalance(response.endingBalance);
        getListPettyCash(pettyCashId).then((response: any) => {
          if (response.length > 0) {
            setPettyCashList(response.filter(
              (item: any) => item.dataType === 0 || item.dataType === 1,
            ));
            setTotalPettyCashList(response.filter(
              (item: any) => item.dataType === 2,
            ));
          } else {
            setPettyCashList([]);
            setTotalPettyCashList([]);
          }
        });
        successNotification('更新しました。');
      }
    }).catch((error: any) => {
      setBlocking(false);
      let errors = '';
      if (error.response.data) {
        (error.response.data as Array<any>).map(
          (err) => {
            errors += err.defaultMessage;
          },
        );
        errorNotification(errors);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  };

  const savePettyCashDetailPost = async (pettyCashId: string,
    pettyCashDetailId: string,
    orgCode: string,
    dateTarget: any,
    descriptionId: string,
    depositFlag: number,
    amount: number,
    payee: string,
    remark: string) => {
    savePettyCashDetail(pettyCashId,
      pettyCashDetailId,
      orgCode,
      dateTarget,
      descriptionId,
      depositFlag,
      amount,
      payee,
      remark).then((response: any) => {
      setBlocking(false);
      let errors = '';
      if (response.status && response.status !== 200) {
        (response.data as Array<any>).map(
          (err) => {
            errors += err.defaultMessage;
          },
        );
        errorNotification(errors);
      } else {
        successNotification(pettyCashDetailId && pettyCashDetailId !== 'undefined' ? '更新しました。' : '登録しました。');
        fetchPettyCashHead(selectedMonth, getSupportDestinationOrg);
        setSelectDescriptionMST('');
        setCheckCustom(0);
        setMoney(0);
        setPaymentDestination('');
        setRemark('');
        setPettyCashDetail('');
      }
    }).catch((error: any) => {
      setBlocking(false);
      let errors = '';
      if (error.response.data) {
        (error.response.data as Array<any>).map(
          (err) => {
            errors += err.defaultMessage;
          },
        );
        errorNotification(errors);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  };

  useEffect(() => {
    fetchDateSelected();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  useEffect(() => {
    fetchPettyCashHead(selectedMonth, getSupportDestinationOrg);
  }, [selectedMonth, getSupportDestinationOrg]);

  useEffect(() => {
    if (updatePettyHead === 1) {
      updatePettyCashHead(pettyCashId, getSupportDestinationOrg, selectedMonth, beginBalance, endBalance);
      setUpdatePettyHead(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePettyHead]);

  useEffect(() => {
    if (registerData === 1) {
      if (dateSelected && dateSelected !== 'undefined') {
        savePettyCashDetailPost(pettyCashId, pettyCashDetail, getSupportDestinationOrg, dateSelected, selectDescriptionMST, checkCustom, money, paymentDestination, remark);
      } else {
        errorNotification('日付を指定してください。');
        setBlocking(false);
      }
      setRegisterData(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerData]);

  useEffect(() => {
    if (deleteDetail !== '') {
      deletePettyCashDetail(deleteDetail).then((response: any) => {
        setBlocking(false);
        let errors = '';
        if (response.status && response.status !== 200) {
          (response.data as Array<any>).map(
            (err) => {
              errors += err.defaultMessage;
            },
          );
          errorNotification(errors);
        } else {
          fetchPettyCashHead(selectedMonth, getSupportDestinationOrg);
          successNotification('削除しました。');
        }
      }).catch((error: any) => {
        setBlocking(false);
        let errors = '';
        if (error.response.data) {
          (error.response.data as Array<any>).map(
            (err) => {
              errors += err.defaultMessage;
            },
          );
          errorNotification(errors);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
      setDeleteDetail('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDetail]);

  useEffect(() => {
    const fetchDescriptionMST = async () => {
      const reponseDescriptionMSTList = await getDescriptionMST();
      reponseDescriptionMSTList.unshift({
        descriptionId: '',
        descriptionCode: '-',
        descriptionName: '',
      });
      setOptionDescriptionMST(reponseDescriptionMSTList.map((item: any) => ({
        value: item.descriptionId,
        label: `${item.descriptionCode} ${item.descriptionName}`,
      })));
    };
    fetchDescriptionMST();
  }, []);

  return {
    selectedMonth,
    setSelectedMonth,
    optionDateSelected,
    optionDescriptionMST,
    getSupportDestinationOrg,
    setSupportDestinationOrg,
    pettyCashHead,
    pettyCashList,
    totalPettyCash,
    totalPettyCashList,
    pettyCashId,
    endBalance,
    beginBalance,
    setBeginBalance,
    displayBalance,
    setDisplayBalance,
    dateSelected,
    setDateSelected,
    selectDescriptionMST,
    setSelectDescriptionMST,
    checkCustom,
    setCheckCustom,
    money,
    setMoney,
    paymentDestination,
    setPaymentDestination,
    remark,
    setRemark,
    setRegisterData,
    setDeleteDetail,
    pettyCashDetail,
    setPettyCashDetail,
    blocking,
    setBlocking,
    setUpdatePettyHead,
  };
};

export default {};
