import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
// import { getPrefectureList } from 'api/prefecture';
import { getList } from 'api/vacationMaster';

/**
 * 休暇の選択ボックス用データを取得
 */
export const useHolidayOptions = (): Array<OptionType> => {
  const [holidayOptions, setHolidayOption] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getList(companyCode).then((holidays) => {
      setHolidayOption(holidays.map((holiday: { holidayId: any; holidayName: any; }) => ({
        value: holiday.holidayId,
        label: holiday.holidayName,
      })));
    });
  }, []);
  return holidayOptions;
};

export default useHolidayOptions;
