import React, { useState, useEffect } from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import FormContents from 'components/atoms/Form/FormContents';
import TabControl from 'components/atoms/TabControl';
import StaffInfoForm from 'components/organismos/master/general/staff/StaffInfoForm';
import AffiliationInfoForm from 'components/organismos/master/general/staff/AffiliationInfoForm';
// import StaffDomain from 'domain/staff';
import EmploymentInfoForm from 'components/organismos/master/general/staff/EmploymentInfoForm';
import PermissionForm from 'components/organismos/master/general/staff/PermissionForm';
import SalaryInfoForm from 'components/organismos/master/general/staff/SalaryInfoForm';
import WorkPatternInfoForm from 'components/organismos/master/general/staff/WorkPatternInfoForm';
import BlockUI from 'components/molecules/BlockUi';
import useStaffEdit from '../StaffEditPage/hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

export interface TablItem {
  value: string;
  display?: string;
}
export type SelectType = 'staff'|'affiliation'|'permission'|'employment'|'laborCost'|'workPattern';
const SelectType: {[key in SelectType]: SelectType} = {
  staff: 'staff',
  affiliation: 'affiliation',
  permission: 'permission',
  employment: 'employment',
  laborCost: 'laborCost',
  workPattern: 'workPattern',
};

const StaffAddPage: React.FC = () => {
  const [selectType, setSelectType] = React.useState<SelectType>(SelectType.staff);
  const [tabItems, setTabItems] = useState <Array<TablItem>>([{ value: SelectType.staff, display: 'スタッフ情報' }]);
  const [isEditMode, setIsEditMode] = useState <boolean>(false);

  const {
    isLoading, setIsLoading,
    staffDomain, setStaffDomain,
    trialPeriodOptions, businessOptions,
    hqRoleOptions, shopRoleOptions, personalRoleOptions,
    executiveOptions, employmentOptions,
    orgTreeOptions,
    setOrgTargetDate,
    setOrgApplyDate,
    setEmpTargetDate,
    breakBusiness,
    roleOptions,
    menuType,
    isEditingLoginUser,
  } = useStaffEdit();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  return (
    <SidebarTemplate pageTitle="スタッフ作成">
      <BlockUI blocking={isLoading}>
        <FormContents>
          <div style={{ overflowX: 'auto' }}>
            <TabControl
              items={tabItems}
              setValue={setSelectType as (arg: string) => void}
              currentValue={selectType}
              isFixed={true}
            />
          </div>

          {selectType === SelectType.staff
              && (
                <>
                  <StaffInfoForm
                    initialStaffDomain={staffDomain}
                    setInitialStaffDomain={setStaffDomain}
                    isEditMode={isEditMode}
                    tabItems={tabItems}
                    setTabItems={setTabItems}
                    setSelectType={setSelectType}
                    setOrgTargetDate={setOrgTargetDate}
                    setOrgApplyDate={setOrgApplyDate}
                    setEmpTargetDate={setEmpTargetDate}
                    trialPeriodOptions={trialPeriodOptions}
                    businessOptions={businessOptions}
                    hqRoleOptions={hqRoleOptions}
                    shopRoleOptions={shopRoleOptions}
                    personalRoleOptions={personalRoleOptions}
                    roleOptions={roleOptions}
                    setIsLoading={setIsLoading}
                    menuType={menuType || '0'}
                    roleScreen={roleScreen}
                    isEditingLoginUser={isEditingLoginUser}
                  />
                </>
              )}
          {selectType === SelectType.affiliation
              && (
                <AffiliationInfoForm
                  initialStaffDomain={staffDomain}
                  setInitialStaffDomain={setStaffDomain}
                  isEditMode={isEditMode}
                  tabItems={tabItems}
                  setTabItems={setTabItems}
                  setOrgTargetDate={setOrgTargetDate}
                  setSelectType={setSelectType}
                  setOrgApplyDate={setOrgApplyDate}
                  organizationOptions={orgTreeOptions}
                  executiveOptions={executiveOptions}
                  setIsLoading={setIsLoading}
                  menuType={menuType || '0'}
                  roleScreen={roleScreen}
                  isEditingLoginUser={isEditingLoginUser}
                  />
              )}
          {selectType === SelectType.permission
              && (
                <PermissionForm
                  initialStaffDomain={staffDomain}
                  setInitialStaffDomain={setStaffDomain}
                  isEditMode={isEditMode}
                  tabItems={tabItems}
                  setTabItems={setTabItems}
                  setSelectType={setSelectType}
                  organizationOptions={orgTreeOptions}
                  employmentOptions={employmentOptions}
                  roleOptions={roleOptions}
                  setIsLoading={setIsLoading}
                  menuType={menuType || '0'}
                  roleScreen={roleScreen}
                  isEditingLoginUser={isEditingLoginUser}
                  />
              )}
          {selectType === SelectType.employment
              && (
                <EmploymentInfoForm
                  initialStaffDomain={staffDomain}
                  setInitialStaffDomain={setStaffDomain}
                  isEditMode={isEditMode}
                  tabItems={tabItems}
                  setTabItems={setTabItems}
                  setSelectType={setSelectType}
                  employmentOptions={employmentOptions}
                  setIsLoading={setIsLoading}
                  menuType={menuType || '0'}
                  roleScreen={roleScreen}
                />
              )}
          {selectType === SelectType.laborCost
              && (
                <SalaryInfoForm
                  initialStaffDomain={staffDomain}
                  setInitialStaffDomain={setStaffDomain}
                  isEditMode={isEditMode}
                  tabItems={tabItems}
                  setTabItems={setTabItems}
                  setSelectType={setSelectType}
                  businessOptions={businessOptions}
                  setIsLoading={setIsLoading}
                  menuType={menuType || '0'}
                  roleScreen={roleScreen}
                />
              )}
          {selectType === SelectType.workPattern
              && (
                <WorkPatternInfoForm
                  setInitialStaffDomain={setStaffDomain}
                  initialStaffDomain={staffDomain}
                  isEditMode={isEditMode}
                  setIsEditMode={setIsEditMode}
                  breakBusiness={breakBusiness}
                  setIsLoading={setIsLoading}
                  menuType={menuType || '0'}
                  roleScreen={roleScreen}
                />
              )}
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default StaffAddPage;
