/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { EventWrapperProps, Event } from 'react-big-calendar';
import EventWrapper from 'components/molecules/Calendar/EventWrapper';
import productColor from 'components/styles';

const style = css({
  '.rbc-event-allday': {
    outline: 'none',
    cursor: 'default',
  },
});

/**
 * 月表示時のイベントラッパー
 */
const MonthEventWrapper: React.FC<EventWrapperProps<Event>> = (props) => {
  const { event } = props;
  const { color } = event as Event & {color: string};
  return (
    <EventWrapper
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      customCss={css(style, {
        backgroundColor: color || productColor.primaryM80,
      })}
    />
  );
};

export default MonthEventWrapper;
