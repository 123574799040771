import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import OrganizationCategoryForm from 'components/organismos/master/general/organizationCategory/OrganizationCategoryForm';
import { useParams } from 'react-router-dom';

const OrganizationCategoryAddPage: React.FC = () => {
  const {
    organizationCategoryMstDetailId,
  } = useParams();

  const pageTitle = sessionStorage.getItem('selectedOrganizationCategory') ?? '';
  return (
    <SidebarTemplate pageTitle={`${pageTitle}${organizationCategoryMstDetailId ? '編集' : '登録'}`}>
      <OrganizationCategoryForm />
    </SidebarTemplate>
  );
};


export default OrganizationCategoryAddPage;
