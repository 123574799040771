import moment from 'moment';

export type SalaryBusinessStaff = {
  id: {
    staffSalaryId: string,
    businessId: string,
  },
  addHourlySalary: number,
}

export type SalarySupportStaff = {
  staffSalaryId: string,
  orgCode: string,
  orgName: string,
  addHourlySalary: number,
  supportTransportation: number,
}

export type SalaryDataStaff = {
  companyCode: string,
  createUser: string,
  updateUser: string,
  staffSalaryId: string,
  staffCode: string,
  applyStartDate?: Date,
  applyEndDate: Date,
  salaryPayment: number,
  dailyTransportation: number,
  monthlyTransportation: number,
  hourlySalary: number
  monthlySalary: number,
  monthlyHourlySalary: number,
  dailySalary: number,
  dailyHourlySalary: number,
  fixedOvertimeHours: number,
  hourlyTrainingFlag: number,
  trainingTermDivision: number,
  trainingTermHour: number,
  trainingTermDay: number,
  trainingHourlyDivision: number,
  trainingHourlyRate: number,
  trainingHourlyDifference: number,
  recalculateFlag: number,
  limitTransportation: number,
  transportationFlag: number,
  salaryBusinessList: Array<SalaryBusinessStaff>,
  // salarySupportList: Array<SalarySupportStaff>,
  salarySupportDataList: Array<SalarySupportStaff>,
  salaryPaymentStr: string,
  dispSalaryStr: string,
  // beforeApplyStartDateStr: string,
}

export class SalaryDataStaffDomain {
  constructor(private rawData: SalaryDataStaff) {
    // do nothing
  }

  static generateInitial(): SalaryDataStaffDomain {
    return new SalaryDataStaffDomain({
      companyCode: sessionStorage.getItem('loginUser.companyCode') || '',
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      staffSalaryId: '',
      staffCode: '',
      applyStartDate: undefined,
      applyEndDate: new Date(),
      salaryPayment: 0,
      dailyTransportation: 0,
      monthlyTransportation: 0,
      hourlySalary: 0,
      monthlySalary: 0,
      monthlyHourlySalary: 0,
      dailySalary: 0,
      dailyHourlySalary: 0,
      fixedOvertimeHours: 0,
      hourlyTrainingFlag: 0,
      trainingTermDivision: 0,
      trainingTermHour: 0,
      trainingTermDay: 0,
      trainingHourlyDivision: 0,
      trainingHourlyRate: 0,
      trainingHourlyDifference: 0,
      recalculateFlag: 0,
      limitTransportation: 0,
      transportationFlag: 0,
      salaryBusinessList: [],
      // salarySupportList: [],
      salarySupportDataList: [],
      salaryPaymentStr: '',
      dispSalaryStr: '',
    });
  }

  getRawData(): SalaryDataStaff {
    return this.rawData;
  }

  get applyStartDateStr(): string {
    return moment(this.rawData.applyStartDate).format('YYYY年MM月DD日');
  }

  get beforeApplyStartDateStr(): string {
    return moment(this.rawData.applyStartDate).format('YYYY年MM月DD日');
  }

  get applyStartDate(): Date | undefined {
    if (this.rawData.applyStartDate) {
      return moment(this.rawData.applyStartDate).toDate();
    }
    return undefined;
  }

  set applyStartDate(applyStartDate: Date | undefined) {
    this.rawData.applyStartDate = applyStartDate;
  }


  get applyEndDate(): Date {
    return this.rawData.applyEndDate;
  }

  get applyEndDateStr(): string {
    return moment(this.rawData.applyEndDate).format('YYYY年MM月DD日');
  }

  set applyEndDate(applyEndDate: Date) {
    this.rawData.applyEndDate = applyEndDate;
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = companyCode;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  get staffSalaryId(): string {
    return this.rawData.staffSalaryId;
  }

  set staffSalaryId(staffSalaryId: string) {
    this.rawData.staffSalaryId = staffSalaryId;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get salaryPayment(): number {
    return this.rawData.salaryPayment;
  }

  set salaryPayment(salaryPayment: number) {
    this.rawData.salaryPayment = salaryPayment;
  }

  get salaryPaymentStr(): string {
    return this.rawData.salaryPaymentStr;
  }

  set salaryPaymentStr(salaryPaymentStr: string) {
    this.rawData.salaryPaymentStr = salaryPaymentStr;
  }

  get dailyTransportation(): number {
    return this.rawData.dailyTransportation;
  }

  set dailyTransportation(dailyTransportation: number) {
    this.rawData.dailyTransportation = dailyTransportation;
  }

  get monthlyTransportation(): number {
    return this.rawData.monthlyTransportation;
  }

  set monthlyTransportation(monthlyTransportation: number) {
    this.rawData.monthlyTransportation = monthlyTransportation;
  }

  get hourlySalary(): number {
    return this.rawData.hourlySalary;
  }

  set hourlySalary(hourlySalary: number) {
    this.rawData.hourlySalary = hourlySalary;
  }

  get monthlySalary(): number {
    return this.rawData.monthlySalary;
  }

  set monthlySalary(monthlySalary: number) {
    this.rawData.monthlySalary = monthlySalary;
  }

  get monthlyHourlySalary(): number {
    return this.rawData.monthlyHourlySalary;
  }

  set monthlyHourlySalary(monthlyHourlySalary: number) {
    this.rawData.monthlyHourlySalary = monthlyHourlySalary;
  }

  get dailySalary(): number {
    return this.rawData.dailySalary;
  }

  set dailySalary(dailySalary: number) {
    this.rawData.dailySalary = dailySalary;
  }

  get dailyHourlySalary(): number {
    return this.rawData.dailyHourlySalary;
  }

  set dailyHourlySalary(dailyHourlySalary: number) {
    this.rawData.dailyHourlySalary = dailyHourlySalary;
  }

  get fixedOvertimeHours(): number {
    return this.rawData.fixedOvertimeHours;
  }

  set fixedOvertimeHours(fixedOvertimeHours: number) {
    this.rawData.fixedOvertimeHours = fixedOvertimeHours;
  }

  get hourlyTrainingFlag(): number {
    return this.rawData.hourlyTrainingFlag;
  }

  set hourlyTrainingFlag(hourlyTrainingFlag: number) {
    this.rawData.hourlyTrainingFlag = hourlyTrainingFlag;
  }

  get trainingTermDivision(): number {
    return this.rawData.trainingTermDivision;
  }

  set trainingTermDivision(trainingTermDivision: number) {
    this.rawData.trainingTermDivision = trainingTermDivision;
  }

  get trainingTermHour(): number {
    return this.rawData.trainingTermHour;
  }

  set trainingTermHour(trainingTermHour: number) {
    this.rawData.trainingTermHour = trainingTermHour;
  }

  get trainingTermDay(): number {
    return this.rawData.trainingTermDay;
  }

  set trainingTermDay(trainingTermDay: number) {
    this.rawData.trainingTermDay = trainingTermDay;
  }

  get trainingHourlyDivision(): number {
    return this.rawData.trainingHourlyDivision;
  }

  set trainingHourlyDivision(trainingHourlyDivision: number) {
    this.rawData.trainingHourlyDivision = trainingHourlyDivision;
  }

  get trainingHourlyRate(): number {
    return this.rawData.trainingHourlyRate;
  }

  set trainingHourlyRate(trainingHourlyRate: number) {
    this.rawData.trainingHourlyRate = trainingHourlyRate;
  }

  get trainingHourlyDifference(): number {
    return this.rawData.trainingHourlyDifference;
  }

  set trainingHourlyDifference(trainingHourlyDifference: number) {
    this.rawData.trainingHourlyDifference = trainingHourlyDifference;
  }

  get recalculateFlag(): number {
    return this.rawData.recalculateFlag;
  }

  set recalculateFlag(recalculateFlag: number) {
    this.rawData.recalculateFlag = recalculateFlag;
  }

  get limitTransportation(): number {
    return this.rawData.limitTransportation;
  }

  set limitTransportation(limitTransportation: number) {
    this.rawData.limitTransportation = limitTransportation;
  }

  get transportationFlag(): number {
    return this.rawData.transportationFlag;
  }

  set transportationFlag(transportationFlag: number) {
    this.rawData.transportationFlag = transportationFlag;
  }

  get dispSalaryStr(): string {
    return this.rawData.dispSalaryStr;
  }

  set dispSalaryStr(dispSalaryStr: string) {
    this.rawData.dispSalaryStr = dispSalaryStr;
  }

  get salaryBusinessList(): Array<SalaryBusinessStaff> {
    return this.rawData.salaryBusinessList;
  }

  set salaryBusinessList(salaryBusinessList: Array<SalaryBusinessStaff>) {
    this.rawData.salaryBusinessList = salaryBusinessList;
  }

  get salarySupportDataList(): Array<SalarySupportStaff> {
    return this.rawData.salarySupportDataList;
  }

  set salarySupportDataList(salarySupportDataList: Array<SalarySupportStaff>) {
    this.rawData.salarySupportDataList = salarySupportDataList;
  }

  // salarySupportList: Array<any>,
}
