/** @jsx jsx */
import React, {useMemo, useState, useCallback} from 'react';
import { css, jsx } from '@emotion/core';
import { iconColor } from 'components/styles';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import useToastNotification from 'hooks/useToastNotification';

import Icon from 'components/atoms/Icon';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import PrimaryButton from 'components/atoms/Button';
import DataTable from 'components/organismos/DataTable/DataTable';
import { downloadCSV, deleteJournalMaster } from 'api/journalMaster';
import JournalMasterItemDomain from 'domain/master/general/journalMasterItem';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { useJournalMasterItemList } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const JournalMaster: React.FC<{
        setJournalData: any,
    }> = ({
        setJournalData
    }) => {
    const {
        journalMasterItemList, setJournalMasterItemList
      } = useJournalMasterItemList();
      
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const { successNotification, errorNotification } = useToastNotification();

    const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

    const closeConfirmModal = useCallback(() => {
        setConfirmModalOpen(false);
      }, []);
    const [selectedJournalMasterItem, setSelectedJournalMasterItem] = useState(
        JournalMasterItemDomain.generateInitial(),
      );

    const downloadFileCSV = useCallback(() => {
        downloadCSV('仕訳情報');
      }, []);

    const openConfirmModal = useCallback((journalMasterItem : JournalMasterItemDomain) => {
        setConfirmModalOpen(true);
        setSelectedJournalMasterItem(journalMasterItem);
    }, []);

    const deleteJournalMasterItem = useCallback(() => {
        deleteJournalMaster(selectedJournalMasterItem.journalId).then((response: any) => {
            if(response.length > 0){
                sessionStorage.setItem('checkJournalData', '1');
            }else{
                setJournalData(0);
                sessionStorage.setItem('checkJournalData', '0');
            }
            setJournalMasterItemList(
                journalMasterItemList.filter(
              (item: any) => item.journalId
              !== selectedJournalMasterItem.journalId,
            ),
          );
          setConfirmModalOpen(false);
          successNotification('削除しました。');
        }).catch((error: any) => {
          setConfirmModalOpen(false);
          if (error.response && error.response.data && error.response.data.defaultMessage) {
            errorNotification(error.response.data.defaultMessage);
          } else {
            errorNotification('サーバー側でエラーが発生しました。');
          }
        });
      }, [errorNotification, journalMasterItemList,
        selectedJournalMasterItem.journalId,
        setJournalMasterItemList, successNotification]);

    const columns: Array<Column<JournalMasterItemDomain>> = useMemo(() => [
    {
        Header: '仕訳コード',
        accessor: 'journalCode',
        sortType: 'basic'
    },
    {
        Header: '仕訳名',
        accessor: 'journalName',
    },
    {
        Header: '借方勘定科目',
        accessor: 'debitAccountTitleName',
    },
    {
        Header: '借方補助科目',
        accessor: 'debitAccountTitleSubName',
    },
    {
        Header: '貸方勘定科目',
        accessor: 'creditAccountTitleName',
    },
    {
        Header: '貸方補助科目',
        accessor: 'creditAccountTitleSubName',
    },
    {
        Header: () => null,
        id: 'actionButton',
        disableSortBy: true,
        Cell: (cell: { row :{ isExpanded: boolean, original: JournalMasterItemDomain },
        data: Array<JournalMasterItemDomain>
        }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
            <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/journalMaster/edit/${`${cell.row.original.journalId}`}`}>
                <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
                >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
                </span>
            </Link>
            {
                roleScreen && roleScreen.editable === 1 && (
                    <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                        <span
                        style={{ marginLeft: '20px', marginRight: '10px' }}
                        onMouseEnter={() => setIsHoverDelete(true)}
                        onMouseLeave={() => setIsHoverDelete(false)}
                        >
                        <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                        </span>
                    </Link>
                )
            }
            </div>
        );
        },
    },
    ], [openConfirmModal, roleScreen]);

    return (
        <div>
            <div style={{ margin: '8px 16px' }}>
                <p>小口現金管理で登録されたデータの仕訳を設定します。</p>
                <FlexBox>
                {
                    roleScreen && roleScreen.downloadFlag === 1 && (
                    <FlexBoxItem>
                        <IconLabelButton
                        onClick={downloadFileCSV}
                        iconType="download"
                        text="CSVダウンロード"
                        />
                    </FlexBoxItem>
                    )
                }
                {
                    roleScreen && roleScreen.importFlag === 1 && (
                    <FlexBoxItem>
                        <Link to="/journalMaster/import">
                        <PrimaryButton
                            ghost={false}
                            text="インポート"
                        />
                        </Link>
                    </FlexBoxItem>
                    )
                }
                {
                    roleScreen && roleScreen.editable === 1 && (
                    <FlexBoxItem grow={1}>
                        <div style={{ textAlign: 'right' }}>
                        <Link to="/journalMaster/add">
                            <PrimaryButton
                            ghost={false}
                            text="仕訳作成"
                            />
                        </Link>
                        </div>
                    </FlexBoxItem>
                    )
                }
                </FlexBox>
            </div>
            <div
                css={css`
            table th:nth-child(1), table td:nth-child(1){
                width: 15%;
            }
            table th:nth-child(2), table td:nth-child(2) {
                width: 15%;
            }
            table th:nth-child(3), table td:nth-child(3) {
                width: 15%;
            }
            table th:nth-child(4), table td:nth-child(4) {
                width: 15%;
            }
            table th:nth-child(5), table td:nth-child(5) {
                width: 15%;
            }
            table th:nth-child(6), table td:nth-child(6) {
                width: 15%;
            }
            table th:nth-child(7), table td:nth-child(7) {
                width: 10%;
            }
            `}
            >
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
                <DataTable
                    columns={columns}
                    data={journalMasterItemList}
                    isGlobalFilter={true}
                    sortBy={[
                        { id: 'journalCode', desc: false }
                      ]}
                />
                </div>
            </div>
            <ConfirmActionModal
                open={confirmModalOpen}
                closeHandler={closeConfirmModal}
                onSubmit={deleteJournalMasterItem}
                actionType={ActionType.DELETE}
            />
        </div>
    );
}

export default JournalMaster
