import { useState, useEffect } from 'react';

import { getList } from 'api/fiscalYear';
import FiscalYearDomain from 'domain/master/sales/fiscalYear';

export const useFiscalYearList = () => {
  const [fiscalYearList, setFiscalYearList] = useState<Array<FiscalYearDomain>>([]);
  useEffect(() => {
    getList().then((response) => {
      setFiscalYearList(response.map((result) => new FiscalYearDomain(result)));
    });
  }, []);
  return { fiscalYearList, setFiscalYearList };
};

export default {
  useFiscalYearList,
};
