/** @jsx jsx */
import React from 'react';

import { css, jsx } from '@emotion/core';
import ManagementInformationForm from './ManagementInformationForm/ManagementInformationForm';
import AcquisitionStatusForm from './AcquisitionStatusForm/AcquisitionStatusForm';
import GrantHistoryForm from './GrantHistoryForm/GrantHistoryForm';

// create style form
const styles = {
  form: css({
    paddingTop: '15px',
  }),
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
    minWidth: '100px',
  }),
};

const HolidayManagementPaidForm: React.FC<
{
  arrGrantResult: any,
  employmentOptions: any,
  currentAccordionList: any,
  setCurrentAccordionList: any
}
> = ({
  arrGrantResult, employmentOptions,
}) =>
  //
  (
    <div css={css(styles.form)}>
      <ManagementInformationForm
        employmentOptions={employmentOptions}
      />

      <GrantHistoryForm
        arrGrantResult={arrGrantResult}
      />

      <AcquisitionStatusForm />
    </div>
  );
export default HolidayManagementPaidForm;
