import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import IncomeAndExpenditureStoreLayout from 'components/organismos/master/sales/SalesForm/incomeAndExpenditureStoreLayout';

const incomeAndExpenditureStorePage = () => (
  <SidebarTemplate pageTitle="【店別】収支表帳票">
    <IncomeAndExpenditureStoreLayout />
  </SidebarTemplate>
);

export default incomeAndExpenditureStorePage;