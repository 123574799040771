import {
  useState, useCallback, useEffect,
} from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import PriceWithrowalDomain from 'domain/master/sales/priceWithrowal';
import { getPriceWidthdrawalList, createOrUpdateAdvertisingMediaOrganization } from 'api/priceWidthdrawal';
import useToastNotification from 'hooks/useToastNotification';
import moment from 'moment';

type PriceWithrowalDomainKey = keyof Pick<PriceWithrowalDomain, 'discountTypeMstCode'|'posType'|'discountTypeCode'>;

export const useAdImportForm = (orgCode?: string, discountTypeCode?: string, posType?: string, discountTypeMstCode?: string, applicableStartMonthFormat?: string) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const history = useHistory();

  const onSubmit = async (val: any) => {
    if (val.discountTypeMstCode === undefined || val.discountTypeMstCode === null) {
      errorNotification('広告媒体を入力してください。');
      return;
    }
    if (val.posType === undefined || val.posType === null) {
      errorNotification('POS種別を入力してください。');
      return;
    }
    if (val.discountTypeCode === undefined || val.discountTypeCode === null) {
      errorNotification('取込コードを入力してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    if (val.applicableEndMonthStr === '-') {
      setConfirmModalOpen(false);
      errorNotification('適用終了月を指定するか、または、未定をチェックしてください。');
      return;
    }
    const submitObj = {
      discountTypeMstCode: val.discountTypeMstCode,
      id: {
        companyCode,
        orgCode,
        posType: String(val.posType),
        discountTypeCode: val.discountTypeCode,
      },
      applyStartDayStr: val.applicableStartMonthStr,
      applyEndDayStr: '',
      dispOrder: 1,
      createUser: staffName,
      updateUser: staffName,
    };
    if (val.applicableEndMonthStr !== '-' && val.applicableEndMonthStr !== '9999年12月') {
      submitObj.applyEndDayStr = val.applicableEndMonthStr;
    }

    createOrUpdateAdvertisingMediaOrganization(!discountTypeCode, submitObj)
      .then((response: any) => {
        successNotification('登録しました。');
        history.push('/discountTypeMst/priceWithdrawal');
        console.log('RESPONSE ', response);
      })
      .catch((error: any) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors.count !== 0) {
          errorNotification(error.response.data.errors[0].defaultMessage);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  };

  const formik = useFormik({
    initialValues: PriceWithrowalDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    // console.log('orgCode', orgCode);
    // console.log('posType', posType);
    // console.log('discountTypeCode', discountTypeCode);
    if (orgCode !== undefined && posType !== undefined && discountTypeCode !== undefined) {
      getPriceWidthdrawalList(orgCode).then((response: Array<any>) => {
        console.log('response', response);
        response.filter((item) => {
          console.log('item', item);
          const parseItem = new PriceWithrowalDomain(item);
          if (Number(parseItem.posType) === Number(posType)
                && parseItem.discountTypeCode === discountTypeCode
                && parseItem.discountTypeMstCode === discountTypeMstCode
                && parseItem.applicableStartMonthFormat === applicableStartMonthFormat) {
            formik.setValues(parseItem);
            return true;
          }
          return false;
        });
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgCode, posType, discountTypeCode]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    history,
  };
};

export default useAdImportForm;
