/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { textColor, textFontSize } from 'components/styles';

const style = css({
  color: textColor.subText01,
  fontSize: textFontSize.sm,
  padding: '4px 0',
});

const FormDescription: React.FC<{
  description: string
}> = ({ description }) => (
  <div css={style}>{description}</div>
);

export default FormDescription;
