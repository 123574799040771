import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import TimeListLayout from 'components/organismos/master/storeManagement/report/TimeList/TimeListLayout';

const TimeListPage = () => (
  <SidebarTemplate pageTitle="時間別帳票">
    <TimeListLayout />
  </SidebarTemplate>
);
export default TimeListPage;
