/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import HeaderSortCell from './HeaderSortCell';
import SortCell from './SortCell';

const style = css({
  whiteSpace: 'pre-wrap',
  width: '100%',
  border: 'none',
  borderSpacing: '0px 1.5px',
});

const TableSort: React.FC & {
    HeaderSortCell: typeof HeaderSortCell;
    SortCell: typeof SortCell;
} = ({ children }) => (
  <table css={style}>
    {children}
  </table>
);

TableSort.HeaderSortCell = HeaderSortCell;
TableSort.SortCell = SortCell;

export default TableSort;
