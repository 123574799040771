/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { productColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import ClickableLink from 'components/atoms/ClickableLink';
import useSidebar from 'hooks/useSidebar';
import BodyText from 'components/atoms/BodyText';
import { SidebarHierarchyItemProps } from './SidebarItem';
import { HierarchySet } from './HierarchyMode';

const styles = {
  close: css({
    padding: '0 0px',
  }),
  chevron: css({
    float: 'right',
  }),
  label: css({
    marginLeft: '4px',
  }),
};

interface SidebarHierarchyItemPropsExt extends SidebarHierarchyItemProps {
  isOpen: boolean;
  setHierarchy: (hierarchySet?: HierarchySet) => void;
}

const SidebarHierarchyItem: React.FC<SidebarHierarchyItemPropsExt> = ({
  icon, text, hierarchyItems, isOpen, setHierarchy,
}) => {
  const { openSidebar } = useSidebar();
  const onClickHandler = React.useCallback(() => {
    setHierarchy({ parentIcon: icon, parentText: text, items: hierarchyItems });
    openSidebar();
  }, [hierarchyItems, icon, openSidebar, setHierarchy, text]);

  return (
    <div css={isOpen || styles.close}>
      <ClickableLink onClick={() => onClickHandler()}>
        <Icon type={icon} color={productColor.primary} />
        {isOpen && (
          <React.Fragment>
            <BodyText customStyle={styles.label}>{text}</BodyText>
            <span css={styles.chevron}>
              <Icon type="chevronRight" color={productColor.primary} />
            </span>
          </React.Fragment>
        )}
      </ClickableLink>
    </div>
  );
};

export default SidebarHierarchyItem;
