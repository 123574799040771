import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesExpensesMSTForm from 'components/organismos/master/general/salesExpensesMST/salesExpensesMSTForm';

const SalesExpensesMSTEditPage: React.FC = () => (
  <SidebarTemplate pageTitle="その他経費編集">
    <SalesExpensesMSTForm />
  </SidebarTemplate>
);

export default SalesExpensesMSTEditPage;
