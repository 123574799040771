/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FlexBox from 'components/atoms/FlexBox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import moment from 'moment';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BlockUI from 'components/molecules/BlockUi';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import MonthlyForeCastManagemenTable_new from './MonthlyForeCastManagemenTable_new';
import MonthlySalesManagementTable from './MonthlySalesManagementTable_new';
import useMonthlyReport, { useGetListMonthlyReport } from './hooks';
import MonthlyDiscountSumaryTable_new from './MonthlyDiscountSumaryTable_new';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const MonthlyReportLayout = () => {
  const fromdate = new Date();
  const datefrom = new Date(fromdate.getFullYear(), fromdate.getMonth(), 1); // get first date
  const dateTo = (new Date(fromdate.getFullYear(), fromdate.getMonth(), fromdate.getDate())); // get date
  const [targetDateFrom, setTargetDateFrom] = useState(datefrom);
  const [targetDateTo, setTargetDateTo] = useState(dateTo);

  const st = new Date();
  st.setMonth(0);
  st.setDate(1);
  const ed = new Date(st);
  if (!targetDateFrom) {
    setTargetDateFrom(st);
  }

  if (!targetDateTo) {
    setTargetDateTo(ed);
  }


  const [isShowContentAction, setIsShowContentAction] = useState(false);


  const [orgCode, setOrgCode] = useState('all');
  const [orgName, setorgName] = useState('全ての組織');
  const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
    undefined,
  );
  const {
    monthlyReport, budgetPerfomanceManagementMonthlyReport, getIsLoading, setIsLoading, discountMonthlyReport
  } = useMonthlyReport(
    String(orgCode),
    moment(targetDateFrom).format('YYYY/MM/DD'),
    moment(targetDateTo).format('YYYY/MM/DD'),
    categoryAry,
  );
  const targetPeriodData = `対象期間：${moment(targetDateFrom).format('YYYY/MM/DD')}~${moment(targetDateTo).format('YYYY/MM/DD')}`;

  const {
    action,
    setAction,
    ActionType,
  } = useGetListMonthlyReport();


  const getFetchOption = () => {
    setAction(ActionType.getFetchOption);
    setIsShowContentAction(true);
  };

  const getFetchOptionforeCast = () => {
    setAction(ActionType.getFetchOptionforeCast);
    setIsShowContentAction(true);
  };


  const getFetchOptionDiscountSumary = () => {
    setAction(ActionType.getFetchOptionDiscountSumary);
    setIsShowContentAction(true);
  };

  const functionType = 3;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <div>
      <FormContents>
        <div>
          <FlexBox>
            <FlexBoxItem width="100px">
              <FormLabel
                label="対象期間"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月dd日"
                label=""
                date={targetDateFrom}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateFrom(date);
                  }
                }}
                isFullWidth={true}
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <span style={{ margin: '0 30px' }}>～</span>
            </FlexBoxItem>
            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月dd日"
                label=""
                date={targetDateTo}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateTo(date);
                  }
                }}
                isFullWidth={true}
              />
            </FlexBoxItem>
          </FlexBox>
        </div>

        <div>
          <OrganizationFilteredTree
            functionType={functionType}
            staffCode={staffCode}
            targetdayForm={targetDateFrom}
            targetdayTo={targetDateTo}
            addAllItem={true}
            initOrgValue={String(orgCode)}
            orgLabel="店舗名"
            orgCallback={(args: any) => {
              setOrgCode(String(args));
            }}
            orgCategoryCallback={
              (args: Array<string>) => {
                setCategoryAr(args);
              }
            }
            orgNameCallback={(arg: any) => {
              setorgName(String(arg));
            }}

          />


        </div>
        <FormField>
          <FormSubmitArea>
            <div style={{ display: 'flex', marginTop: '-15px' }}>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton
                  text="売上管理表 "
                  ghost={!(isShowContentAction && action === ActionType.getFetchOption)}
                  onClick={getFetchOption}
                />
              </div>
              {roleScreen && roleScreen.usabilityGeneralItem1 === 1 && (
                <div style={{ marginRight: '12px' }}>
                  <PrimaryButton
                    text="予実管理表"
                    ghost={!(isShowContentAction && action === ActionType.getFetchOptionforeCast)}
                    onClick={getFetchOptionforeCast}
                  />
                </div>
              )}
              <PrimaryButton
                text="値引・割引集計表"
                ghost={!(isShowContentAction && action === ActionType.getFetchOptionDiscountSumary)}
                onClick={getFetchOptionDiscountSumary}
              />
            </div>
          </FormSubmitArea>
        </FormField>

        <div>
          {isShowContentAction && action === ActionType.getFetchOption
            && (
              <div style={{ marginTop: '30px' }}>
                <div className="row">
                  <BlockUI blocking={getIsLoading}>
                    <MonthlySalesManagementTable
                      targetDateFrom={targetDateFrom}
                      targetDateTo={targetDateTo}
                      monthlyReport={monthlyReport}
                      targetPeriodData={targetPeriodData}
                      orgLabel={orgName}
                      setIsLoading={setIsLoading}
                      roleScreen={roleScreen}
                    />
                  </BlockUI>
                </div>
              </div>
            )}

          {isShowContentAction && action === ActionType.getFetchOptionforeCast
            && (
              <div style={{ marginTop: '30px' }}>
                <div className="row">
                  <BlockUI blocking={getIsLoading}>
                    <MonthlyForeCastManagemenTable_new
                      setIsLoading={setIsLoading}
                      targetDateFrom={targetDateFrom}
                      targetDateTo={targetDateTo}
                      budgetPerfomanceManagementMonthlyReport={budgetPerfomanceManagementMonthlyReport}
                      targetPeriodData={targetPeriodData}
                      orgLabel={orgName}
                      roleScreen={roleScreen}
                    />
                  </BlockUI>
                </div>
              </div>
            )}

          {isShowContentAction && action === ActionType.getFetchOptionDiscountSumary
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <MonthlyDiscountSumaryTable_new
                  setIsLoading={setIsLoading}
                  targetDateFrom={targetDateFrom}
                  targetDateTo={targetDateTo}
                  discountMonthlyReport={discountMonthlyReport}
                  targetPeriodData={targetPeriodData}
                  orgLabel={orgName}
                  roleScreen={roleScreen}
                 />
               </BlockUI>
             </div>
           </div>
           )}
        </div>

      </FormContents>
    </div>
  );
};

export default MonthlyReportLayout;
