/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import FormContents from "components/atoms/Form/FormContents";
import OrganizationFilteredTree from "components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree";
import DatePicker from "components/molecules/DatePicker";
import FlexBox from "components/atoms/FlexBox";
import useSalesAdvertisingMonthlyReportList from "components/organismos/master/general/salesPage/salesAdvertisingMonthlyReport/hook";
import FormField from "components/atoms/Form/FormField";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import PrimaryButton from "components/atoms/Button";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FormLabel from "components/atoms/Form/FormLabel";
import InboundStatementListTable from "./table/InboundStatementListTable";
import AdvertisingStatementListTable from "./table/AdvertisingStatementListTable";
import OtherDetailsListTable from "./table/OtherDetailsListTable";
import SalesCatalogListTable from "./table/SalesCatalogListTable";
import IconLabelButton from "components/molecules/IconLabelButton";
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const SalesAdvertisingMonthlyReport: React.FC<{}> = () => {
  const {
    isLoading,
    listSalesManagement,
    listSalesManagement2,
    listSalesManagement3,
    listSalesManagement4,
    selectedMonth,
    setSelectedMonth,
    functionType,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    action,
    setAction,
    ActionType,
    orgName,
    setorgName,
    handleExportCSV,
    handleExportExcel
  } = useSalesAdvertisingMonthlyReportList();

  const getTab1 = () => {
    setAction(ActionType.getTab1);
  };

  const getTab2 = () => {
    setAction(ActionType.getTab2);
  };

  const getTab3 = () => {
    setAction(ActionType.getTab3);
  };

  const getTab4 = () => {
    setAction(ActionType.getTab4);
  };

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox>
          <FlexBoxItem width="100px">
            <FormLabel label="対象年月" />
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePicker
              dateFormat="yyyy年MM月"
              date={selectedMonth}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setSelectedMonth(date);
                  sessionStorage.setItem("dateSelectPettyCase", `${date}`);
                }
              }}
              isFullWidth={false}
              showMonthYearPicker={true}
            />
          </FlexBoxItem>
        </FlexBox>
        <div>
          <OrganizationFilteredTree
            functionType={functionType}
            orgCallback={(args: string | Array<string>) => {
              setOrgCode(String(args));
            }}
            orgCategoryCallback={(args: Array<string>) => {
              setCategoryAry(args);
            }}
            orgNameCallback={(arg: any) => {
              setorgName(String(arg));
            }}
            initOrgValue={String(orgCode)}
            staffCode={loginStaffCode}
            orgLabel="店舗名"
            addAllItem={true}
            targetdayForm={new Date(moment(selectedMonth).startOf('month').format('YYYY/MM/DD'))}
            targetdayTo={new Date(moment(selectedMonth).endOf('month').format('YYYY/MM/DD'))}
          />
        </div>

        <FormField>
          <FormSubmitArea>
            <div style={{ display: "flex", marginTop: "-15px" }}>
            <div style={{ marginRight: "12px" }}>
                <PrimaryButton text="インバウンド明細" onClick={getTab1} />
              </div>
              <div style={{ marginRight: "12px" }}>
                <PrimaryButton text="広告明細" onClick={getTab2} />
              </div>
              <div style={{ marginRight: "12px" }}>
                <PrimaryButton text="その他明細" onClick={getTab3} />
              </div>
              <div style={{ marginRight: "12px" }}>
                <PrimaryButton text="売上区分" onClick={getTab4} />
              </div>
            </div>
          </FormSubmitArea>
        </FormField>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportCSV}
                iconType="download"
                text=" CSV出力"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportExcel}
                iconType="download"
                text=" EXCEL出力"
              />
            </FlexBoxItem>
          </FlexBox>
        )}
        {action === ActionType.getTab1 && orgCode !== "all" && (
          <InboundStatementListTable
            listSalesManagement={listSalesManagement}
            orgCode={orgCode}
            orgName={orgName}
          />
        )}
        {action === ActionType.getTab2 && orgCode !== "all" && (
          <AdvertisingStatementListTable
            listSalesManagement={listSalesManagement2}
            orgCode={orgCode}
            orgName={orgName}
          />
        )}
        {action === ActionType.getTab3 && orgCode !== "all" && (
          <OtherDetailsListTable
            listSalesManagement={listSalesManagement3}
            orgCode={orgCode}
            orgName={orgName}
          />
        )}
        {action === ActionType.getTab4 && orgCode !== "all" && (
          <SalesCatalogListTable
            listSalesManagement={listSalesManagement4}
            orgCode={orgCode}
            orgName={orgName}
          />
        )}
      </FormContents>
    </BlockUI>
  );
};
export default SalesAdvertisingMonthlyReport;
