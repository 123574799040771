/* eslint-disable max-len */
import {
  useState, useCallback, useEffect, useMemo, Dispatch,
} from 'react';
import { useFormik } from 'formik';
import useToastNotification from 'hooks/useToastNotification';
import StaffDomain, { Staff } from 'domain/staff';
import { OptionType } from 'components/atoms/Select';
import * as api from 'api/staff';
import { WeekShiftPatternDataStaffDomain, WeekShiftPatternDataStaff, AttendShiftPatternDetails } from 'domain/master/general/weekShiftPatternDataStaff';
import moment from 'moment';

export const useAffiliationForm = (
  initialStaffDomain: StaffDomain = StaffDomain.generateInitial(),
  isEditMode: boolean = false,
  setIsLoading: Dispatch<React.SetStateAction<boolean>>,
  menuType: string,
  setIsEditMode?: Dispatch<React.SetStateAction<boolean>>,
) => {
  const [businessOptions, setBusinessOptions] = useState<Array<OptionType>>([]);
  const [shiftPatternOptions, setShiftPatternOptions] = useState<Array<OptionType>>([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [detailMode, setDetailMode] = useState(false);
  const [registeredDayOfWeeks, setRegisteredDayOfWeeks] = useState(initialStaffDomain.weekShiftPatternDomainList.map((item) => item.dayOfWeek));
  const [selectedDayOfWeeks, setSelectedDayOfWeeks] = useState<Array<number>>(initialStaffDomain.selectWeekShiftPattern.selectedDayOfWeeks);
  const { successNotification, errorNotification } = useToastNotification();
  // DELETE WORK PATTERN
  const [deleteTargetWeekShift, setDeleteTargetWeekShift] = useState<WeekShiftPatternDataStaffDomain|null>(null);
  const isOpenDeleteModal = useMemo(() => !!deleteTargetWeekShift, [deleteTargetWeekShift]);
  const closeDeleteModal = useCallback(() => setDeleteTargetWeekShift(null), []);

  const onSubmit = async (values: any) => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    setConfirmModalOpen(false);
    setIsLoading(true);

    try {
      const response = await api.postWorkPatternInfo(isEditMode ? 1 : 0, menuType, values.getStaffInfoPostData(isEditMode));
      const updatedStaff = new StaffDomain(response.staff);

      // Reset select week shift
      formik.setFieldValue('selectWeekShiftPattern', WeekShiftPatternDataStaffDomain.generateInitial());

      // Reset week shift pattern list
      const updatedWeekShiftPatternList : Array<WeekShiftPatternDataStaff> = [];
      updatedStaff.weekShiftPatternList.forEach((weekShift) => {
        const newWeekShift = weekShift;

        const updatedDetails : Array<AttendShiftPatternDetails> = [];
        newWeekShift.attendShiftPatternDetails.forEach((detail) => {
          const newDetail = detail;
          newDetail.startTime = newDetail.startTime.length === 8 ? newDetail.startTime : moment(newDetail.startTime).format('HH:mm');
          newDetail.endTime = newDetail.endTime.length === 8 ? newDetail.endTime : moment(newDetail.endTime).format('HH:mm');
          updatedDetails.push(newDetail);
        });

        updatedWeekShiftPatternList.push(newWeekShift);
      });
      formik.setFieldValue('weekShiftPatternList', updatedWeekShiftPatternList);

      // Reset registered day of weeks
      setRegisteredDayOfWeeks(updatedStaff.weekShiftPatternList.map((item) => item.daysOfWeek[0]));

      // Reset select day of weeks
      setSelectedDayOfWeeks([]);

      // Reset shift pattern options
      let patternList : OptionType[] = [];
      api.getShiftPatternStaff(formik.values.orgCode, formik.values.employmentId, formik.values.staffCode, isEditMode).then((response2: Staff) => {
        if (response2.shiftPatternList && response2.shiftPatternList.length > 0) {
          formik.setFieldValue('shiftPatternList', response2.shiftPatternList);
          patternList = response2.shiftPatternList.map((shiftPattern) => ({
            value: shiftPattern.patternIdStr,
            label: shiftPattern.orgCode === 'all' ? `${shiftPattern.shiftPatternName}(共通)` : shiftPattern.shiftPatternName,
          }));
        }
        patternList.unshift({
          value: '',
          label: '未選択',
        });
        setShiftPatternOptions(patternList);
      });

      setIsLoading(false);
      successNotification(isEditMode ? '更新しました。' : '登録しました。');

      if (!isEditMode) {
        sessionStorage.setItem('selectedOrgCodeStaffMaster', updatedStaff.orgCode);
        setIsEditMode!(true);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.map((item: { defaultMessage: string; }) => errorNotification(item.defaultMessage.replace('<br/>', '\n')));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };
  const formik = useFormik({
    initialValues: initialStaffDomain,
    onSubmit,
  });

  useEffect(() => {
    formik.setValues(initialStaffDomain);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let patternList : OptionType[] = [];
    let businessList : OptionType[] = [];

    api.getShiftPatternStaff(formik.values.orgCode, formik.values.employmentId, formik.values.staffCode, isEditMode).then((response: Staff) => {
      formik.setFieldValue('roundTimeList', response.roundTimeList);

      if (response.empBusinessList && response.empBusinessList.length > 0) {
        formik.setFieldValue('empBusinessList', response.empBusinessList);
        businessList = response.empBusinessList.map((business) => ({
          value: business.businessId || '',
          label: business.businessName || '',
        }));
      }
      setBusinessOptions(businessList);

      if (response.shiftPatternList && response.shiftPatternList.length > 0) {
        formik.setFieldValue('shiftPatternList', response.shiftPatternList);
        patternList = response.shiftPatternList.map((shiftPattern) => ({
          value: shiftPattern.patternIdStr,
          label: shiftPattern.orgCode === 'all' ? `${shiftPattern.shiftPatternName}(共通)` : shiftPattern.shiftPatternName,
        }));
      }
      patternList.unshift({
        value: '',
        label: '未選択',
      });
      setShiftPatternOptions(patternList);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.employmentId, formik.values.orgCode, isEditMode]);

  const onSubmitDeleteStaff = useCallback(() => {
    if (!deleteTargetWeekShift) {
      return;
    }
    api.deleteWeekShiftPatternStaff(deleteTargetWeekShift.staffWeekShiftPatternId).then((response : Array<WeekShiftPatternDataStaff>) => {
      setDeleteTargetWeekShift(null);

      formik.setFieldValue('weekShiftPatternList', response);
      formik.setFieldValue('selectWeekShiftPattern', WeekShiftPatternDataStaffDomain.generateInitial());
      setRegisteredDayOfWeeks(response.map((item) => item.daysOfWeek[0]));
      setSelectedDayOfWeeks([]);
      successNotification('削除しました。');
    }).catch((error) => {
      setDeleteTargetWeekShift(null);
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.map((item: { defaultMessage: string; }) => errorNotification(item.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [deleteTargetWeekShift, errorNotification, formik, successNotification]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    shiftPatternOptions,
    businessOptions,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    selectedDayOfWeeks,
    setSelectedDayOfWeeks,
    registeredDayOfWeeks,

    deleteTargetWeekShift,
    setDeleteTargetWeekShift,
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteStaff,
  };
};

export default {
  useAffiliationForm,
};
