/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { PrimaryButton } from 'components/atoms/Button';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormContents from 'components/atoms/Form/FormContents';
import FormLabel from 'components/atoms/Form/FormLabel';
import TabControl from 'components/atoms/TabControl';
import DatePickerForm from 'components/molecules/DatePickerForm';
import ConfirmModal from 'components/organismos/ConfirmModal';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { gridCol, utilityColor } from 'components/styles';
import { useEffect, useState } from 'react';
import BlockUI from 'components/molecules/BlockUi';
import { useHistory } from 'react-router-dom';
import SalesUncategorizedTable from '../SalesUncategorizedTable';
import useStaffEdit from './hook';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const styles = {
  table: css({
    width: '100%',
    borderSpacing: 0,
    borderLeft: '1px solid #222d32',
    borderBottom: '1px solid #222d32',
  }),
  border: css({
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12,
    borderRight: '1px solid #222d32',
    borderTop: '1px solid #222d32',
    padding: '5px 10px',
  }),
  wrapper: css({
    backgroundColor: 'white',
    border: `2px solid ${utilityColor.black}`,
  }),
  textMessage: css({
    color: 'black',
  }),
};

type SelectTab = 'tab_advertising_table' | 'tab_other_table';
const SelectTab: { [key in SelectTab]: SelectTab } = {
  tab_advertising_table: 'tab_advertising_table',
  tab_other_table: 'tab_other_table',
};

const SalesSlipForm = () => {
  const [selectTab, setSelectTab] = useState<SelectTab>(
    SelectTab.tab_advertising_table,
  );
  const {
    orgApplyDate,
    setOrgApplyDate,
    functionType,
    organization,
    setOrganization,
    confirmSale,
    setConfirmSale,
    staffCode,
    salesBreak,
    categoryArray,
    setCategoryAr,
    loginStaffCode,
    closeConfirmSaleDetail,
    isLoading,
    handleUpdateSalesSlip,
    salesSlip,
    setSalesSlip,
    initState,
    numberFormater,
    isDisable,
    setIsDisable,
    organizationMedia
  } = useStaffEdit(selectTab);

  const history = useHistory();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true
    && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === organization);

  const checkUserRole = organization !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  return (
    <div>
      <FormContents>
        <FlexBox>
          <FlexBoxItem width="100px">
            <FormLabel
              label="対象日"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label=""
              date={orgApplyDate}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setOrgApplyDate(date);
                }
              }}
              isFullWidth={true}
            />
          </FlexBoxItem>
        </FlexBox>
        <FlexBox>
          <FlexBoxItem width={gridCol.grid06}>
            <OrganizationFilteredTree
              functionType={functionType}
              orgCallback={(args: string | Array<string>) => {
                setOrganization(String(args));
              }}
              orgCategoryCallback={(args: Array<string>) => {
                setCategoryAr(args);
              }}
              initOrgValue={organization}
              staffCode={staffCode}
              addAllItem={false}
              orgLabel="組織名"
              isAlignItemsCenter={true}
            />
          </FlexBoxItem>
        </FlexBox>
      </FormContents>
      <FormContents>
        <FlexBox customStyle={css({ marginBottom: 15 })}>
          <FlexBoxItem>
            <TabControl
              items={[
                {
                  value: SelectTab.tab_advertising_table,
                  display: '広告',
                },
                {
                  value: SelectTab.tab_other_table,
                  display: 'その他',
                },
              ]}
              setValue={setSelectTab as (arg: string) => void}
              currentValue={selectTab}
              isFixed={false}
              largeWidth={true}
            />
          </FlexBoxItem>
        </FlexBox>
        <BlockUI blocking={isLoading}>
          <FlexBox>
            <FlexBoxItem grow={1}>
              <table css={styles.table}>
                <tbody>
                  <tr>
                    <td css={styles.border} style={{ background: 'linear-gradient(to bottom, #fdfbfb, #f5f5f5)' }}>
                      <FormLabel
                        customStyle={css({
                          fontSize: 12,
                        })}
                        label="総売上"
                      />
                    </td>
                    <td css={styles.border}>
                      {`${numberFormater(salesBreak.totalSales)} 円`}
                    </td>
                    <td css={styles.border} style={{ background: 'linear-gradient(to bottom, #fdfbfb, #f5f5f5)' }}>
                      <FormLabel
                        customStyle={css({
                          fontSize: 12,
                        })}
                        label="広告売上"
                      />
                    </td>
                    <td css={styles.border}>
                      {`${numberFormater(salesBreak.advertisingSales)} 円`}
                    </td>
                    <td css={styles.border} style={{ background: 'linear-gradient(to bottom, #fdfbfb, #f5f5f5)' }}>
                      <FormLabel
                        customStyle={css({
                          fontSize: 12,
                        })}
                        label="その他売上"
                      />
                    </td>
                    <td css={styles.border}>
                      {`${numberFormater(salesBreak.otherSales)} 円`}
                    </td>
                    <td css={styles.border} style={{ background: 'linear-gradient(to bottom, #fdfbfb, #f5f5f5)' }}>
                      <FormLabel
                        customStyle={css({
                          fontSize: 12,
                        })}
                        label="差額"
                      />
                    </td>
                    <td css={styles.border}>
                      {`${numberFormater(salesBreak.balanceDue)} 円`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </FlexBoxItem>
            <FlexBoxItem marginLeft="10px">
              {checkUserRole && checkUserRole.editable === 1
                && (
                  <PrimaryButton
                    text="登録"
                    onClick={() => setConfirmSale(true)}
                    ghost={false}
                    disabled={isDisable}
                  />
                )}
            </FlexBoxItem>
          </FlexBox>
          <div
            className="panel panel-default"
            style={{ marginTop: '20px' }}
          >
            {selectTab === SelectTab.tab_advertising_table
              && (
                <SalesUncategorizedTable
                  organization={organization}
                  organizationMedia={organizationMedia}
                  orgApplyDate={orgApplyDate}
                  functionType={functionType}
                  categoryArray={categoryArray}
                  loginStaffCode={loginStaffCode}
                  selectTab="tab_advertising_table"
                  salesSlip={salesSlip}
                  salesSlipCallback={(args: any) => {
                    setSalesSlip(args);
                  }}
                  isDisableCallback={(args: any) => {
                    setIsDisable(args);
                  }}
                  initState={initState}
                />
              )}
            {selectTab === SelectTab.tab_other_table
              && (
                <SalesUncategorizedTable
                  organization={organization}
                  organizationMedia={organizationMedia}
                  orgApplyDate={orgApplyDate}
                  functionType={functionType}
                  categoryArray={categoryArray}
                  loginStaffCode={loginStaffCode}
                  selectTab="tab_other_table"
                  salesSlip={salesSlip}
                  salesSlipCallback={(args: any) => {
                    setSalesSlip(args);
                  }}
                  isDisableCallback={(args: any) => {
                    setIsDisable(args);
                  }}
                  initState={initState}
                />
              )}
          </div>
        </BlockUI>
      </FormContents>
      <ConfirmModal
        open={confirmSale}
        closeHandler={closeConfirmSaleDetail}
        title="確認メッセージ"
        content="登録します。よろしいですか？"
        submitText="登録"
        onSubmit={() => handleUpdateSalesSlip()}
        items={[]}
      />
    </div>
  );
};
export default SalesSlipForm;
