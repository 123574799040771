import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import PaymentDetailsLayout from './PaymentDetailsLayout';

const PaymentDetailsPage = () => (
  <SidebarTemplate pageTitle="入出金明細">
    <PaymentDetailsLayout />
  </SidebarTemplate>
);
export default PaymentDetailsPage;
