import { useState, useCallback, useEffect } from 'react';
import { getHqPointedOut, downloadPointedOutCsv, downloadPointedOutExcel } from 'api/monthly/hqPointedOut';
import useToastNotification from 'hooks/useToastNotification';
import moment from 'moment';

const useHeadquarterPointedOutList = () => {
  const functionType = 3;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode'));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);
  const monthSelect = sessionStorage.getItem('dateSelectSalePage12') || new Date();
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date(monthSelect));
  const [listHeadquarterPointedOut, setListHeadquarterPointedOut] = useState([]);
  const [getStatus, setStatus] = useState<string>('all');

  let dateTo = '';
  dateTo = moment(selectedMonth).endOf('month').format('YYYY/MM/DD');

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getHqPointedOut(
        String(orgCode),
        moment(selectedMonth).startOf('month').format('YYYY/MM/DD'),
        dateTo,
        getStatus,
        categoryAry,
      );
      console.log('response', response);
      setListHeadquarterPointedOut(response);
    } catch (error) {
      setListHeadquarterPointedOut([]);
    }
    setLoading(false);
  }, [orgCode, selectedMonth, dateTo, getStatus, categoryAry]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    isLoading,
    listHeadquarterPointedOut,
    functionType,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    selectedMonth,
    setSelectedMonth,
    getStatus,
    setStatus
  };
};

export const useDownload = () => {
  const { errorNotification } = useToastNotification();
  const downloadCsv = (data: any) => {
    downloadPointedOutCsv(data, '本部用指摘内容確認.csv');
  };

  const downloadExcel = (data: any) => {
    downloadPointedOutExcel(data, '本部用指摘内容確認.xlsx');
  };

  return {
    downloadCsv,
    downloadExcel,
  };
};

export default useHeadquarterPointedOutList;
