/**
 * APIのデータ型
 */
export interface Holiday {
  publicHolidayId: string;
  holidayName: string;
  holidayDate: Date;
  holidayDateStr: string;
  isAll: boolean,
  isEdit: boolean,
  organization: Array<any>,
  orgCodes: any;
  dispOrg:string;
}

export default class HolidayDomain {
  org: any;

  constructor(private rawData: Holiday) {
    // do nothing
  }

  static generateInitial(): HolidayDomain {
    return new HolidayDomain({
      publicHolidayId: '',
      holidayName: '',
      holidayDate: new Date(),
      holidayDateStr: '',
      isAll: true,
      isEdit: true,
      organization: [],
      orgCodes: [],
      dispOrg: '',
    });
  }

  getRawData(): Holiday {
    return this.rawData;
  }

  get publicHolidayId(): string {
    return this.rawData.publicHolidayId;
  }

  set publicHolidayId(publicHolidayId: string) {
    this.rawData.publicHolidayId = publicHolidayId;
  }

  get holidayDate(): Date {
    return this.rawData.holidayDate;
  }

  set holidayDate(holidayDate: Date) {
    this.rawData.holidayDate = holidayDate;
  }

  get holidayDateStr(): string {
    return this.rawData.holidayDateStr;
  }

  set holidayDateStr(holidayDateStr: string) {
    this.rawData.holidayDateStr = holidayDateStr;
  }

  get holidayName(): string {
    return this.rawData.holidayName;
  }

  set holidayName(holidayName: string) {
    this.rawData.holidayName = holidayName;
  }

  get isAll(): boolean {
    return this.rawData.isAll;
  }

  set isAll(isAll: boolean) {
    this.rawData.isAll = isAll;
  }

  get isEdit(): boolean {
    return this.rawData.isEdit;
  }

  set isEdit(isEdit: boolean) {
    this.rawData.isEdit = isEdit;
  }

  get organization(): Array<any> {
    return this.rawData.organization;
  }

  set organization(organization: Array<any>) {
    this.rawData.organization = organization;
  }

  get orgCodes(): any {
    return this.rawData.orgCodes;
  }

  set orgCodes(orgCodes: any) {
    this.rawData.orgCodes = orgCodes;
  }

  set dispOrg(dispOrg: string) {
    this.rawData.dispOrg = dispOrg;
  }

  get dispOrg(): string {
    return this.rawData.dispOrg;
  }
}
