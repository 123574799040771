import ApiClient from './ApiClient';

export type attendHelpList = {
    targetDateFrom: any;
    targetDateTo: any;
    supportSourceOrg: any;
    supportDestinationOrg:any;
    closeDateFlg:any;
    targetDate:any;
}

export const attendHelpList = async (
  targetDateFrom: any,
  targetDateTo: any,
  supportSourceOrg: any,
  supportDestinationOrg: any,
): Promise<Array<attendHelpList>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    targetDateFrom,
    targetDateTo,
    supportSourceOrg,
    supportDestinationOrg,
  };
  const response = await ApiClient.get(`/v1/support/list/${companyCode}`, params);
  return response.data;
};
export const attendHelpListV2 = async (
  targetDateFrom: any,
  targetDateTo: any,
  supportSourceOrg: any,
  supportDestinationOrg: any,
  functionType:number,
  categoryAry:Array<Array<string>>,
  destinationCategoryAry:Array<Array<string>>,
  loginStaffCode:string,
): Promise<Array<attendHelpList>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    targetDateFrom,
    targetDateTo,
    supportSourceOrg,
    supportDestinationOrg,
    functionType,
    loginStaffCode,
  };

  let url = Object.entries(params)
    .map(
      ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
    )
    .join('&');
  if (categoryAry && categoryAry.length) {
    categoryAry.forEach((cateAry) => {
      url += `&categoryAry=${cateAry.join(',')}`;
    });
  }
  if (destinationCategoryAry && destinationCategoryAry.length) {
    destinationCategoryAry.forEach((cateAry) => {
      url += `&destinationCategoryAry=${cateAry.join(',')}`;
    });
  }
  const response = await ApiClient.get(`/v2/support/list/${companyCode}?${url}`, {});
  return response.data;
};

export const searchDate = async (
  closeDateFlg: any,
  targetDate: any,
): Promise<Array<attendHelpList>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    staffCode,
    closeDateFlg,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/employments/searchDate/${companyCode}`, params);
  return response.data;
};

export const downloadSupportListCsv = async (data: any, fileName:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsvPost(`/v1/supportListExportFile/csv/${companyCode}`, {}, data, fileName);
};

export const subTotalList = async (
  targetDateFrom: any,
  targetDateTo: any,
  supportSourceOrg: any,
  supportDestinationOrg: any,
): Promise<Array<attendHelpList>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    targetDateFrom,
    targetDateTo,
    supportSourceOrg,
    supportDestinationOrg,
  };
  const response = await ApiClient.get(`/v1/support/subtotal/${companyCode}`, params);
  return response.data;
};

export const subTotalListV2 = async (
  targetDateFrom: any,
  targetDateTo: any,
  supportSourceOrg: any,
  supportDestinationOrg: any,
  functionType:number,
  categoryAry:Array<Array<string>>,
  destinationCategoryAry:Array<Array<string>>,
  loginStaffCode:string,
): Promise<Array<attendHelpList>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    targetDateFrom,
    targetDateTo,
    supportSourceOrg,
    supportDestinationOrg,
    functionType,
    loginStaffCode,
  };

  let url = Object.entries(params)
    .map(
      ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
    )
    .join('&');
  if (categoryAry && categoryAry.length) {
    categoryAry.forEach((cateAry) => {
      url += `&categoryAry=${cateAry.join(',')}`;
    });
  }
  if (destinationCategoryAry && destinationCategoryAry.length) {
    destinationCategoryAry.forEach((cateAry) => {
      url += `&categoryAry=${cateAry.join(',')}`;
    });
  }
  const response = await ApiClient.get(`/v2/support/subtotal/${companyCode}?${url}`, {});
  return response.data;
};

export default attendHelpList;
