import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SidebarItemType } from 'components/organismos/Sidebar';
import { Store } from 'modules/store';
import { uiSidebarActions } from 'modules/ui/sidebar';
import { HierarchySet } from 'components/organismos/Sidebar/HierarchyMode';

const useSidebar = () => {
  // selector
  const isSidebarOpen = useSelector((state:Store) => state.ui.sidebar.isOpen);
  const hierarchy = useSelector((state: Store) => state.ui.sidebar.hierarchy);

  // dispatch action
  const dispatch = useDispatch();
  const openSidebar = useCallback(() => {
    dispatch(uiSidebarActions.setIsOpen(true));
  }, [dispatch]);
  const toggleSidebar = useCallback(() => {
    dispatch(uiSidebarActions.setIsOpen(!isSidebarOpen));
    dispatch(uiSidebarActions.setHierarchy(undefined));
  }, [dispatch, isSidebarOpen]);
  const setHierarchy = useCallback((hierarchySet?: HierarchySet) => {
    dispatch(uiSidebarActions.setHierarchy(hierarchySet));
  }, [dispatch]);

  let tmpMenu: Array<SidebarItemType> = [];

  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const menuItems = JSON.parse(String(sessionStorage.getItem('menuItems')));
    if (menuItems.length) {
      tmpMenu = menuItems;
    }
  } catch (e) {
    console.log(e);
  }
  const sidebarItems: Array<SidebarItemType> = useMemo(() => tmpMenu, [tmpMenu]);

  return {
    isSidebarOpen,
    hierarchy,
    openSidebar,
    toggleSidebar,
    sidebarItems,
    setHierarchy,
  };
};

export default useSidebar;
