/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
  bankDepositDetailReport, downloadBankDepositDetailReportCsv, downloadBankDepositDetailReportExcel,
  BankDepositMatchingType, BankDepositReportData
} from 'api/bankDeposit';
import BankDepositReportDomain from 'domain/master/storeManagement/bankDepositReport';
import useToastNotification from 'hooks/useToastNotification';
import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const useBankDepositReport = () => {
  const { errorNotification } = useToastNotification();
  const [orgCode, setOrgCode] = useState('all');
  const [orgName, setorgName] = useState('全ての組織');
  const fromdate = new Date();
  const datefrom = new Date(fromdate.getFullYear(), fromdate.getMonth(), 1); // get first date
  const dateTo = (new Date(fromdate.getFullYear(), fromdate.getMonth(), fromdate.getDate())); // get date
  const [targetDateFrom, setTargetDateFrom] = useState(datefrom);
  const [targetDateTo, setTargetDateTo] = useState(dateTo);
  const [differenceCheckBox, setDifferenceCheckbox] = useState<boolean>(false);
  const [isNoRelevantShop, setIsNoRelevantShop] = useState<boolean>(false);
  const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
    undefined,
  );
  const functionType = 3;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [bankDepositMatchingType, setBankDepositMatchingType] = useState<BankDepositMatchingType>(0);
  const [bankDetailData, setBankDetailData] = useState<Array<BankDepositReportDomain>>([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

    // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  const handleError = useCallback((error: any) => {
    if (error.response && error.response.data && error.response.data.errors) {
      let msgError = '';
      error.response.data.errors.map((item: { defaultMessage: string; }) => {
        msgError += `${item.defaultMessage} \n`;
        return msgError;
      });
      errorNotification(msgError);
    } else {
      errorNotification('サーバー側でエラーが発生しました。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  useEffect(() => {
    const param = {
      orgCode,
      businessStartDate: moment(targetDateFrom).format('YYYY/MM/DD'),
      businessEndDate: moment(targetDateTo).format('YYYY/MM/DD'),
      loginStaffCode: staffCode,
      isDifference: differenceCheckBox,
      isNoRelevantShop,
      functionType,
      categoryAry: categoryAry ? String(categoryAry) : '',
    };
    setLoading(true);
    if (new Date(targetDateFrom) > new Date(targetDateTo)) {
      setLoading(false);
      setBankDetailData([])
    } else {
      bankDepositDetailReport(param).then((response: BankDepositReportData) => {
        setBankDepositMatchingType(response.bankDepositMatchingType);
        setBankDetailData(response.reportList);
        setLoading(false);
      }).catch((err) => {
        handleError(err);
        setBankDetailData([])
        setLoading(false);
      }).finally(() => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryAry, differenceCheckBox, isNoRelevantShop, orgCode, staffCode, targetDateFrom, targetDateTo]);

  const downloadBankDepositDetailCSV = (BankDepositDetailReport: any) => {
    setLoading(true);
    downloadBankDepositDetailReportCsv(BankDepositDetailReport, '入出金明細.csv').then(() => {
      setLoading(false);
    }).catch((err) => {
      handleError(err);
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
  };
  const downloadBankDepositDetailExcel = (BankDepositDetailReport: any) => {
    setLoading(true);
    downloadBankDepositDetailReportExcel(BankDepositDetailReport, '入出金明細.xlsx').then(() => {
      setLoading(false);
    }).catch((err) => {
      handleError(err);
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
  };

  const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num)) {
      return num;
    }
    const tmp = String(num).split('.');
    if (tmp[1]) {
      let fixed = 3;
      if (tmp[1].length < 3) {
        fixed = tmp[1].length;
      }
      return parseFloat(num).toFixed(fixed);
    }
    return formatter.format(num);
  };

  return {
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    orgCode,
    setOrgCode,
    orgName,
    setorgName,
    differenceCheckBox,
    setDifferenceCheckbox,
    isNoRelevantShop,
    setIsNoRelevantShop,
    categoryAry,
    setCategoryAr,
    functionType,
    staffCode,
    bankDepositMatchingType,
    bankDetailData,
    numberFormater,
    checkUserRole,
    loading,
    setLoading,
    downloadBankDepositDetailCSV,
    downloadBankDepositDetailExcel,
  };
};

export default useBankDepositReport;
