import React, { useState, Dispatch } from 'react';
import { Link } from 'react-router-dom';

import OrganizationDomain from 'domain/organization';

import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import ClickableLink from 'components/atoms/ClickableLink';
import ConfirmActionModal, {
  ActionType,
} from 'components/organismos/ConfirmActionModal';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Icon from 'components/atoms/Icon';
import { iconColor } from 'components/styles';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import { useOrganizationCategoryOptions } from 'hooks/useOrganizationCategoryOptions';
import DatePickerForm from 'components/molecules/DatePickerForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import OrganizationCategorySelect from '../OrganizationCategory/OrganizationCategorySelect';
import StoreDetailForm from './StoreDetailForm';
import { useOrganizationAddForm } from './hooks';
import moment from 'moment';

const OrganizationInputForm: React.FC<{
  initialOrganizationDomain?: OrganizationDomain;
  setInitialOrganizationDomain: Dispatch<
    React.SetStateAction<OrganizationDomain>
  >;
  historyList: Array<OrganizationDomain>;
  isEditMode?: boolean;
  setHistoryList: (arg: Array<OrganizationDomain>) => void;
  state?: any,
  handleOnChange?: any
  fetchData?: any
}> = ({
  initialOrganizationDomain,
  setInitialOrganizationDomain,
  historyList,
  isEditMode = false,
  setHistoryList,
  state,
  handleOnChange,
  fetchData
}) => {
  const {
    formik,
    detailMode,
    confirmModalOpen,
    closeConfirmModal,
  } = useOrganizationAddForm(
    initialOrganizationDomain,
    isEditMode,
    historyList,
    setHistoryList,
  );
  // console.log(`initialOrganizationDomain = ${initialOrganizationDomain?.orgCode}`);

  const columns: Array<Column<OrganizationDomain>> = React.useMemo(
    () => [
      {
        Header: '適用日',
        accessor: 'applyStartDateText',
      },
      {
        Header: '組織コード',
        accessor: 'orgCode',
      },
      {
        Header: '組織名',
        accessor: 'orgName',
      },
      {
        Header: '状態',
        accessor: 'closeFlagLabel',
      },
      {
        Header: () => null,
        accessor: 'x',
        id: 'actionButton',
        disableSortBy: true,
        Cell: (cell: {
          row: { isExpanded: boolean; original: OrganizationDomain };
        }) => {
          const [isHoverEdit, setIsHoverEdit] = useState(false);
          return (
            <FlexBox>
              <FlexBoxItem marginLeft="auto">
                <ClickableLink
                  onClick={() => fetchData(cell.row.original.applyStartDate)}
                >
                  <span
                    style={{ marginLeft: '20px' }}
                    onMouseEnter={() => setIsHoverEdit(true)}
                    onMouseLeave={() => setIsHoverEdit(false)}
                  >
                    <Icon
                      type="edit"
                      color={isHoverEdit ? iconColor.grayhover : iconColor.gray}
                    />
                  </span>
                </ClickableLink>
              </FlexBoxItem>
            </FlexBox>
          );
        },
      },
    ],
    [formik],
  );
  const functionType = 1;
  const optionsCategory = useOrganizationCategoryOptions(functionType, false);
  const [options, setOptions] = React.useState<Array<any>>([]);
  const [categorySelect, setCategorySelect] = React.useState<Array<string>>([]);

  React.useEffect(() => {
    setOptions(optionsCategory);
    const select = state?.organizationCategoryMstDetilId;
    console.log('state', select)
      const categoryList = select
      const categoryListTemp: Array<string> = [];
      if (categoryList) {
        categoryList.forEach((category: any) => {
          categoryListTemp.push(category);
        });
      }
      //formik.setFieldValue('organizationCategoryMstDetilId', categoryListTemp);
      setCategorySelect(categoryListTemp);

    //setCategorySelect(select)
  }, [optionsCategory, state?.organizationCategoryMstDetilId]);

  return (
    <>
      <DataTable
        columns={columns}
        data={historyList}
        isGlobalFilter={false}
        sortBy={[
          {id: 'applyStartDateText', desc: true}
        ]}
      />

      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormField>
            <TextForm
              name="orgCode"
              label="組織コード"
              value={state?.orgCode}
              onChange={handleOnChange}
              disable={isEditMode}
              maxLength={10}
            />
          </FormField>

          <FormField>
            <TextForm
              name="orgName"
              label="組織名"
              value={state?.orgName}
              onChange={handleOnChange}
            />
          </FormField>

          <FormField>
            <TextForm
              name="orgNameKana"
              label="組織名（ふりがな）"
              value={state?.orgNameKana}
              onChange={handleOnChange}
            />
          </FormField>

          <FormField>
            <TextForm
              name="dispOrder"
              label="並び順"
              value={state?.dispOrder}
              onChange={handleOnChange}
            />
          </FormField>

          <OrganizationCategorySelect
            optionsList={options}
            label="組織カテゴリ"
            values={categorySelect}
            setValues={(arg) => {
              handleOnChange(arg, 'organizationCategoryMstDetilId', arg)
            }}
          />
          {/* <FormField>
              <RadioSelectForm
                label="組織カテゴリ"
                subLabel="店舗損益管理の予算編成、レポート、帳票は、店舗に設定されている組織が対象となります。"
                items={[
                  {
                    label: OrganizationCategoryLabel[2],
                    value: '2',
                  },
                  {
                    label: OrganizationCategoryLabel[1],
                    value: '1',
                  },
                  {
                    label: OrganizationCategoryLabel[0],
                    value: '0',
                  },
                ]}
                name="category"
                value={String(formik.values.category)}
                setValue={formik.handleChange}
              />
            </FormField> */}

          <FormField>
            <div style={{ marginRight: '12px' }}>
              <DatePickerForm
                dateFormat="yyyy/MM/dd"
                label="適用日"
                date={moment(state?.applyStartDate).toDate()}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    handleOnChange(moment(date).format("YYYY/MM/DD"), 'applyStartDate', moment(date).format("YYYY/MM/DD"))
                  }
                }}
              />
            </div>

            {/* <div style={{ marginRight: '12px' }}>
              <VerticalLabelFormLayout
                label={<FormLabel label="表示営業時間" />}
                input={(
                  <FlexBox>
                    <HourInput
                      name="dispStartHour"
                      value={formik.values.dispStartHour}
                      label="時"
                      onChange={formik.handleChange}
                    />
                    <div>から</div>
                    <HourInput
                      name="dispHour"
                      value={formik.values.dispHour}
                      label="時間"
                      onChange={formik.handleChange}
                    />
                  </FlexBox>
                )}
              />
            </div> */}
            <RadioSelectForm
              label="店舗状態"
              name="closeFlag"
              items={[
                {
                  label: '開店',
                  value: '0',
                },
                {
                  label: '閉店',
                  value: '1',
                },
              ]}
              value={String(state?.closeFlag)}
              setValue={handleOnChange}
            />
          </FormField>
        </div>

        {/* 詳細項目 */}
        {/* {String(formik.values.category) === '0' ? '' : (
            <ClickableLink
              onClick={() => setDetailMode(!detailMode)}
              padding="6px 0"
            >
              詳細項目も一緒に登録
            </ClickableLink>
          )} */}

        {/* 店舗詳細項目 */}
        {/* {detailMode && formik.values.category === OrganizationCategory.STORE && ( */}
        {detailMode && (
          <StoreDetailForm
            prefectureCode={formik.values.prefectureCode}
            setPrefectureCode={(val: string) => formik.setFieldValue('prefectureCode', val)}
            areaId={formik.values.areaId}
            setAreaId={(val: string) => formik.setFieldValue('areaId', val)}
            businessCategoryId={formik.values.businessCategoryId}
            setBusinessCategoryId={(val: string) => formik.setFieldValue('businessCategoryId', val)}
            employmentId={formik.values.employmentId}
            setEmploymentId={(val: string) => formik.setFieldValue('employmentId', val)}
            workingDaysId={formik.values.workingDaysId}
            setWorkingDaysId={(val: string) => formik.setFieldValue('workingDaysId', val)}
            posCode={formik.values.posCode}
            setPosCode={(val: string) => formik.setFieldValue('posCode', val)}
            infomartLinkCode={formik.values.infomartLinkCode}
            setInfomartLinkCode={(val: string) => formik.setFieldValue('infomartLinkCode', val)}
            numberOfSeat={formik.values.numberOfSeat}
            setNumberOfSeat={(val: string) => formik.setFieldValue('numberOfSeat', val)}
            floorSpace={formik.values.floorSpace}
            setFloorSpace={(val: string) => formik.setFieldValue('floorSpace', val)}
            state={state}
            handleOnChange={handleOnChange}
          />
        )}

        {/* 部署詳細項目 */}
        {/* {detailMode && formik.values.category === OrganizationCategory.DEPARTMENT && ( */}
        {/* detailMode && (
          <DepartmentDetailForm
            prefectureCode={formik.values.prefectureCode}
            setPrefectureCode={(val: string) => formik.setFieldValue('prefectureCode', val)}
            employmentId={formik.values.employmentId}
            setEmploymentId={(val: string) => formik.setFieldValue('employmentId', val)}
            workingDaysId={formik.values.workingDaysId}
            setWorkingDaysId={(val: string) => formik.setFieldValue('workingDaysId', val)}
          />
        )

        <FormSubmitArea>
          {
            roleScreen !== null && roleScreen.editable === 1 && (
              <div style={{ marginRight: '12px' }}>
                <Button
                  text={isEditMode ? '更新' : '登録してマネージャー設定へ'}
                  onClick={formik.handleSubmit}
                />
              </div>
            )
          }
          <Link to="/organizations">
            <Button text="戻る" ghost={true} />
          </Link>
        </FormSubmitArea> */}
      </form>

      {/* 登録内容確認モーダル */}
      {/* <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        items={confirmModalItems}
      />
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
      />*/}
    </>
  );
};

export default OrganizationInputForm;
