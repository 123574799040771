import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

export const getList = async (companyCode: string): Promise<any> => {
  const params = {};

  const response = await ApiClient.get(`/v1/executive/list/${companyCode}`, params);
  return response.data;
};

export const getExecutiveByExecutiveId = async (executiveId: string): Promise<any> => {
  const params = {
    executiveId,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/executive/${companyCode}`, params);
  return response.data;
};

export const createOrUpdateExecutive = async (companyCode: string, isAccepted: boolean,
  data: any, isEdit: boolean) => {
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
    executiveCode: data.executiveCode.trim(),
    executiveName: data.executiveName.trim(),
    executiveId: data.executiveId,
  };
  const query = {
    isAccepted,
  };
  const response = await ApiClient.post(`/v1/executive/${companyCode}`, query, params);
  return response.data;
};

export const deleteById = async (executiveId: string): Promise<any> => {
  const params = {
    executiveId,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.delete(`/v1/executive/${companyCode}`, params);
  return response.data;
};

export const downloadCSV = async (
  companyCode: string,
): Promise<void> => {
  const params = {};
  await ApiClient.downloadCsv(`/v1/executive/outputCsv/${companyCode}`, params, getDownloadCsvFileName('役職情報_'));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;


/**
 * CSVファイルインポート
 */
export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    formatType: 0,
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v1/executive/csv/import/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export type Error = {
  error: string;
  defaultMessage: string;
}
