/**
 * APIのデータ型
 */
interface Revision {
  revision: string;
}

export default class RevisionDomain {
  constructor(private rawData: Revision) {
    // do nothing
  }

  getRawData(): Revision {
    return this.rawData;
  }

  get revision(): string {
    return this.rawData.revision;
  }
}
