import {
  useState, useCallback, useEffect,
} from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import EmploymentDomain, { Employment } from 'domain/employment';
import { createOrUpdateEmployment, getEmployment } from 'api/employment';
import useToastNotification from 'hooks/useToastNotification';
import moment from 'moment';
import getList from 'api/termsMaster';
import { Terms } from 'domain/master/attend/terms';

type EmploymentDomainKey = keyof Pick<EmploymentDomain, 'employmentCode'|'employmentName'>;

export const useEmploymentAddForm = (
  isEdit?:number, employmentId?: string, applyStartDate?: string,
) => {
  const history = useHistory();

  // 登録内容確認モーダルの開閉状態
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  // Modal alert
  const { successNotification, errorNotification } = useToastNotification();

  const [blocking, setBlocking] = useState(false);

  const [editEmployment, setEditEmployment] = useState(EmploymentDomain.generateInitial(isEdit));

  const [termMst, setTermMst] = useState<Array<Terms>>([]);

  const onSubmit = async (values: EmploymentDomain) => {
    if (formik.values.employmentCode === '') {
      errorNotification('雇用形態コードを入力してください。');
      return;
    }
    if (formik.values.employmentName === '') {
      errorNotification('雇用形態名を入力してください。');
      return;
    }
    if ((/^ *$/.test(values.employmentCode) || /^ *$/.test(values.employmentName)) && (values.employmentName !== '' && values.employmentCode !== '')) {
      errorNotification('コードに半角、全角スペースは入力できません。');
      return;
    }

    if (!confirmModalOpen) {
      // 登録内容確認モーダルが表示されていなければ表示する
      setConfirmModalOpen(true);
      return;
    }

    try {
      const postData = values.getRawData();
      postData.employmentId = postData.id.employmentId;
      postData.createUser = sessionStorage.getItem('loginUser.staffName') || '';
      postData.updateUser = sessionStorage.getItem('loginUser.staffName') || '';
      postData.businessesIds = postData.businesses.map((business) => business.businessId);
      postData.attendHolidayIds = postData.holidays.map((holiday) => holiday.holidayId);
      postData.staffCodes = postData.noticeStaffs.map((noticeStaff) => noticeStaff.staffCode);

      if (String(postData.workSystem) === '1') {
        postData.startTime = '00:00';
        postData.isStartTimeNextDay = false;
        postData.endTime = '00:00';
        postData.isEndTimeNextDay = false;
      }

      if (String(postData.useAutoRecess) !== '1') {
        postData.autoRecessWorkConditionTime1 = 0;
        postData.autoRecessWorkConditionTime2 = 0;
        postData.autoRecessWorkConditionTime3 = 0;
        postData.autoRecessGrantTime1 = 0;
        postData.autoRecessGrantTime2 = 0;
        postData.autoRecessGrantTime3 = 0;
      }

      if (String(postData.useAutoRecess) !== '2') {
        postData.recessStartTime1 = '00:00';
        postData.recessEndTime1 = '00:00';
        postData.isRecessStartTimeNextDay1 = false;
        postData.isRecessEndTimeNextDay1 = false;
        postData.recessStartTime2 = '00:00';
        postData.recessEndTime2 = '00:00';
        postData.isRecessStartTimeNextDay2 = false;
        postData.isRecessEndTimeNextDay2 = false;
        postData.recessStartTime3 = '00:00';
        postData.recessEndTime3 = '00:00';
        postData.isRecessStartTimeNextDay3 = false;
        postData.isRecessEndTimeNextDay3 = false;
      } else {
        if (!postData.recessStartTime1) {
          postData.recessStartTime1 = '00:00';
        }
        if (!postData.recessEndTime1) {
          postData.recessEndTime1 = '00:00';
        }
        if (!postData.isRecessStartTimeNextDay1) {
          postData.isRecessStartTimeNextDay1 = false;
        }
        if (!postData.isRecessEndTimeNextDay1) {
          postData.isRecessEndTimeNextDay1 = false;
        }
        if (!postData.recessStartTime2) {
          postData.recessStartTime2 = '00:00';
        }
        if (!postData.recessEndTime2) {
          postData.recessEndTime2 = '00:00';
        }
        if (!postData.isRecessStartTimeNextDay2) {
          postData.isRecessStartTimeNextDay2 = false;
        }
        if (!postData.isRecessEndTimeNextDay2) {
          postData.isRecessEndTimeNextDay2 = false;
        }
        if (!postData.recessStartTime3) {
          postData.recessStartTime3 = '00:00';
        }
        if (!postData.recessEndTime3) {
          postData.recessEndTime3 = '00:00';
        }
        if (!postData.isRecessStartTimeNextDay3) {
          postData.isRecessStartTimeNextDay3 = false;
        }
        if (!postData.isRecessEndTimeNextDay3) {
          postData.isRecessEndTimeNextDay3 = false;
        }
      }

      if (String(postData.workSystem) === '0') {
        postData.calcOverTimeInMonth = false;
        postData.calcOverTimeInWeek = false;
        postData.calcOverTimeInDay = false;
        delete (postData as any).workingHoursId;
      }
      // if (String(postData.workSystem) === '1') {
      //   postData.calcLegalOverTime = 0;
      // }

      if (postData.calcOverTimeInMonth !== true) {
        delete (postData as any).workingHoursId;
      }
      if (postData.calcOverTimeInWeek !== true) {
        postData.letShiftPredeterminedWorkTimeOfWeek = 0;
        postData.prescribedWorkHourOfWeek = 0;
      }
      if (String(postData.letShiftPredeterminedWorkTimeOfWeek) !== '0'
          && String(postData.letShiftPredeterminedWorkTimeOfWeek) !== '2') {
        postData.prescribedWorkHourOfWeek = 0;
      }

      // if (String(postData.workSystem) === '0' && postData.calcLegalOverTime !== 1) {
      //   postData.carryOverMonthMethod = 0;
      // }
      // if (String(postData.workSystem) === '1' && postData.calcOverTimeInWeek !== true) {
      //   postData.carryOverMonthMethod = 0;
      // }
      // if (String(postData.carryOverMonthMethod) === '0') {
      //   postData.carryOverMonthRounding = 4;
      // }

      if (postData.calcOverTimeInDay !== true) {
        postData.letShiftPredeterminedWorkTimeOfDay = 0;
        postData.prescribedWorkHourOfDay = 0;
      }
      if (String(postData.letShiftPredeterminedWorkTimeOfDay) !== '0'
          && String(postData.letShiftPredeterminedWorkTimeOfDay) !== '2') {
        postData.prescribedWorkHourOfDay = 0;
      }

      if (postData.useExtraOverTime !== true) {
        postData.extraOverTime1 = 0;
        postData.extraOverTime2 = 0;
        postData.extraOverTime3 = 0;
      }
      // if (String(postData.workSystem) === '1') {
      //   postData.calcLegalOverTime = 0;
      // }

      if (postData.calcOverTimeInMonth !== true) {
        delete (postData as any).workingHoursId;
      }
      if (postData.calcOverTimeInWeek !== true) {
        postData.letShiftPredeterminedWorkTimeOfWeek = 0;
        postData.prescribedWorkHourOfWeek = 0;
      }
      if (String(postData.letShiftPredeterminedWorkTimeOfWeek) !== '0'
          && String(postData.letShiftPredeterminedWorkTimeOfWeek) !== '2') {
        postData.prescribedWorkHourOfWeek = 0;
      }

      // if (String(postData.workSystem) === '0' && postData.calcLegalOverTime !== 1) {
      //   postData.carryOverMonthMethod = 0;
      // }
      // if (String(postData.workSystem) === '1' && postData.calcOverTimeInWeek !== true) {
      //   postData.carryOverMonthMethod = 0;
      // }
      // if (String(postData.carryOverMonthMethod) === '0') {
      //   postData.carryOverMonthRounding = 4;
      // }

      if (postData.calcOverTimeInDay !== true) {
        postData.letShiftPredeterminedWorkTimeOfDay = 0;
        postData.prescribedWorkHourOfDay = 0;
      }
      if (String(postData.letShiftPredeterminedWorkTimeOfDay) !== '0'
          && String(postData.letShiftPredeterminedWorkTimeOfDay) !== '2') {
        postData.prescribedWorkHourOfDay = 0;
      }

      if (postData.useExtraOverTime !== true) {
        postData.extraOverTime1 = 0;
        postData.extraOverTime2 = 0;
        postData.extraOverTime3 = 0;
      }

      delete (postData as any).createDate;
      delete (postData as any).updateDate;

      postData.businesses.forEach((item: any, index: number) => {
        // eslint-disable-next-line no-param-reassign
        delete (item as any).createDate;
        // eslint-disable-next-line no-param-reassign
        delete (item as any).updateDate;
      });
      postData.holidays.forEach((item: any, index: number) => {
        // eslint-disable-next-line no-param-reassign
        delete (item as any).createDate;
        // eslint-disable-next-line no-param-reassign
        delete (item as any).updateDate;
      });
      postData.noticeStaffs.forEach((item: any, index: number) => {
        // eslint-disable-next-line no-param-reassign
        delete (item as any).createDate;
        // eslint-disable-next-line no-param-reassign
        delete (item as any).updateDate;
      });

      postData.terms = postData.terms.filter((e) => e.id.termId !== '');

      postData.terms.forEach((item2: any, index: number) => {
        // eslint-disable-next-line no-param-reassign
        item2.id.applyStartDate = moment(item2.id.applyStartDate).format('YYYY/MM/DD');
      });

      const response = await createOrUpdateEmployment(postData);
      if (!response.errors) {
        setConfirmModalOpen(false);
        successNotification(isEdit ? '更新しました' : '登録しました');
        history.goBack();
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };

  const formik = useFormik({
    initialValues: EmploymentDomain.generateInitial(isEdit),
    onSubmit,
  });

  useEffect(() => {
    setBlocking(true);
    if (employmentId !== undefined && applyStartDate !== undefined) {
      getEmployment(employmentId, applyStartDate).then((response: Employment) => {
        if (response) {
          const employmentDomain = new EmploymentDomain(response);
          formik.setValues(employmentDomain);
          setEditEmployment(employmentDomain);
          // console.log(`formik.values.employmentCode = ${formik.values.employmentCode}`);
          // console.log(`response.terms[0].id.termId = ${response.terms[0].id.termId}`);
          // console.log(`formik.values.terms[0].id.termId = ${formik.values.terms[0].id.termId}`);
          if (isEdit === undefined || isEdit < 2) {
            formik.setFieldValue('employmentId', '');
            formik.setFieldValue('applyDate', new Date());
            formik.setFieldValue('employmentCode', '');
            formik.setFieldValue('employmentName', '');
          }
          getList().then((termList: Array<Terms>) => {
            setTermMst(termList);
          });
        }
      });
    }
    setBlocking(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employmentId]);

  // 登録内容確認モーダル用データ
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);
  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    blocking,
    editEmployment,
    termMst,
  };
};

export default {
  useEmploymentAddForm,
};
