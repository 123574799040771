import SidebarTemplate from 'components/templates/SidebarTemplate';
import MonthlyReportLayout from 'components/organismos/master/storeManagement/report/monthlyReport/MonthlyReportLayout';
import React from 'react';

const MonthlyReportPage = () => (
  <SidebarTemplate pageTitle="月次帳票">
    <MonthlyReportLayout />
  </SidebarTemplate>
);
export default MonthlyReportPage;
