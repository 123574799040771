/* eslint-disable react-hooks/rules-of-hooks */
import FormContents from 'components/atoms/Form/FormContents';
import TabControl from 'components/atoms/TabControl';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DiscountTypePage from './DiscountTypePage/DiscountTypePage';
import PriceWithdrawalPage from './PriceWithdrawalPage/PriceWithdrawalPage';

const discountTypeMst = () => {
  const { currentTab } = useParams();
  const [value, setValue] = useState((currentTab === 'discountType' || currentTab === 'priceWithdrawal') ? currentTab : 'discountType');

  const history = useHistory();
  return (
    <SidebarTemplate pageTitle="値引・割引種別マスタ">
      <FormContents>
        <TabControl
          items={[
            { value: 'discountType', display: '値引・割引種別' },
            { value: 'priceWithdrawal', display: '値引・割引取込一覧' },
          ]}
          setValue={(val: string) => {
            sessionStorage.setItem('selectedOrgCode', sessionStorage.getItem('loginUser.orgCode')!);
            setValue(val);
            history.push(`/discountTypeMst/${val}`);
          }}
          currentValue={value}
          isFixed={true}
        />
        {value === 'discountType' ? <DiscountTypePage linktab={value} /> : <PriceWithdrawalPage />}

      </FormContents>

    </SidebarTemplate>
  );
};

export default discountTypeMst;
