import { useState, useCallback, useEffect } from 'react';
import { getListStaffRegistrationStatus, downloadCsvStaffRegistratoinStatus, deletePhoto as deletePhotoApi } from 'api/staffRegistration';
import { OrgStatusType, UserRegistrationStatusType, SessionStorageKey, Values } from 'utility/constants';
import StaffRegisterDomain from 'domain/master/general/staffRegistrationStatus';
import useToastNotification from 'hooks/useToastNotification';
import { ErrorMessage, InfoMessage } from 'utility/messages';
import StaffRegistrationStatusDomain from 'domain/master/general/staffRegistrationStatus';

export const useStaffRegistrationStatus = () => {
    const [blocking, setBlocking] = useState(false);
    const [staffRegisterList, setStaffRegisterList] = useState<Array<StaffRegisterDomain>>([]);
    const [orgCode, setOrgCode] = useState(sessionStorage.getItem(SessionStorageKey.Common.SELECT_ORG_CODE('StaffRegistrationStatus')) || sessionStorage.getItem(SessionStorageKey.LoginUser.ORG_CODE)!);
    const [categoryArr, setCategoryAr] = useState<Array<any>>([]);
    const [orgStatus, setOrgStatus] = useState<OrgStatusType>((sessionStorage.getItem(SessionStorageKey.UserSetting.StaffRegistrationStatus.SEARCH_ORG_STATUS) || Values.OrgStatus.Open.value) as OrgStatusType);
    const [userRegistrationStatus, setUserRegistrationStatus] = useState<UserRegistrationStatusType>((sessionStorage.getItem(SessionStorageKey.UserSetting.StaffRegistrationStatus.SEARCH_USER_REAGISTRATION_STATUS) || Values.UserRegistrationStatus.Unregistered.value) as UserRegistrationStatusType);
    const [faceRegistrationStatus, setFaceRegistrationStatus] = useState(sessionStorage.getItem(SessionStorageKey.UserSetting.StaffRegistrationStatus.SEARCH_FACE_REAGISTRATION_STATUS) || '-1');
    const [employmentRegistrationStatus, setEmploymentRegistrationStatus] = useState(sessionStorage.getItem(SessionStorageKey.UserSetting.StaffRegistrationStatus.SEARCH_EMPLOYMENT_REAGISTRATION_STATUS) || '1');
    const [selectedDeleteRow, setSelectedDeleteRow] = useState<StaffRegistrationStatusDomain | null>(null);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const { successNotification, errorNotification } = useToastNotification();

    // Get list staff
    const fetchData = useCallback(async () => {
        try {
            setBlocking(true);
            const response = await getListStaffRegistrationStatus(
                orgCode,
                categoryArr,
                orgStatus,
                userRegistrationStatus,
                faceRegistrationStatus,
                employmentRegistrationStatus,
            );
            setStaffRegisterList(response.map((result) => new StaffRegisterDomain(result)));
        } catch(e) {
            errorNotification(ErrorMessage.Common.INTERNAL_SERVER_ERROR);
        } finally {
            setBlocking(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRegistrationStatus, faceRegistrationStatus, employmentRegistrationStatus, orgCode, categoryArr, orgStatus]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const downloadCsv = useCallback(async () => {
        try {
            setBlocking(true);
            await downloadCsvStaffRegistratoinStatus(
            orgCode,
            categoryArr,
            orgStatus,
            userRegistrationStatus,
            faceRegistrationStatus,
            employmentRegistrationStatus,
            );
        } catch(e) {
            errorNotification(ErrorMessage.Common.INTERNAL_SERVER_ERROR);
        } finally {
            setBlocking(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRegistrationStatus, faceRegistrationStatus, employmentRegistrationStatus, orgCode, categoryArr, orgStatus]);

    const deletePhoto = useCallback(async () => {
        setOpenConfirmModal(false);
        if(!selectedDeleteRow || !selectedDeleteRow.staffCode) {
            setSelectedDeleteRow(null);
            return;
        }

        try {
            setBlocking(true);
            await deletePhotoApi(selectedDeleteRow.staffCode);
            // 一覧に表示されている顔写真をクリア
            selectedDeleteRow.nameplate = undefined;
            setSelectedDeleteRow(selectedDeleteRow);
            successNotification(InfoMessage.Common.DELETE_COMPLETED);
        } catch(e) {
            errorNotification(ErrorMessage.Common.INTERNAL_SERVER_ERROR);
            setSelectedDeleteRow(null);
        } finally {
           setBlocking(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDeleteRow]);

    return {
        staffRegisterList,
        fetchData,
        orgCode,
        setOrgCode,
        userRegistrationStatus,
        setUserRegistrationStatus,
        faceRegistrationStatus,
        setFaceRegistrationStatus,
        employmentRegistrationStatus,
        setEmploymentRegistrationStatus,
        blocking,
        categoryArr,
        setCategoryAr,
        orgStatus,
        setOrgStatus,
        downloadCsv,
        deletePhoto,
        selectedDeleteRow,
        setSelectedDeleteRow,
        openConfirmModal,
        setOpenConfirmModal,
    };
};

export default useStaffRegistrationStatus;
