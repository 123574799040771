import React, { useState } from 'react';

import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { Link, useParams } from 'react-router-dom';
import ConfirmModal from 'components/organismos/ConfirmModal';
import FormContents from 'components/atoms/Form/FormContents';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import PercentInput from 'components/molecules/PercentInput';
import { useSubjectMSTItemList } from 'components/pages/master/general/accountTitleMST/accountTitleMSTPage/SubjectMaster/hooks';
import { useCreditAccountTitleSubItemList } from 'components/pages/master/general/accountTitleMST/accountTitleMSTPage/JournalMaster/hooks';
import { useJournalMasterDomainForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const JournalMasterForm: React.FC<{
    accountTitleList: any,
  }> = ({
    accountTitleList,
  }) => {
    const { journalId } = useParams();
    const {
      formik, confirmModalOpen, closeConfirmModal,
      getDebitTaxType,
      setDebitTaxType,
      getCreditTaxType,
      setCreditTaxType,
      loadDebitAccountTitleId,
      loadCreditAccountTitleId,
      tmpDebitAccountTitleSubId,
      setTmpDebitAccountTitleSubId,
      tmpCreditAccountTitleSubId,
      setTmpCreditAccountTitleSubId,
    } = useJournalMasterDomainForm(journalId);
    const [debitAccountTitleId, setDebitAccountTitleId] = useState(loadDebitAccountTitleId);
    const { accountTitleSubMSTItemList } = useSubjectMSTItemList(String(formik.values.debitAccountTitleId ? formik.values.debitAccountTitleId : debitAccountTitleId));

    const [creditAccountTitleId, setCreditAccountTitleId] = useState(loadCreditAccountTitleId);
    const { creditAccountTitleSubItemList } = useCreditAccountTitleSubItemList(String(formik.values.creditAccountTitleId ? formik.values.creditAccountTitleId : creditAccountTitleId));

    const optionAccountTitleMST = accountTitleList.map((tree: any) => ({
      value: tree.accountTitleId,
      label: `${tree.accountTitleCode} ${tree.accountTitleName}`,
    }));

    const optionDebitAccountTitleSub = accountTitleSubMSTItemList.map((tree: any) => ({
      value: tree.accountTitleSubId,
      label: `${tree.accountTitleSubCode} ${tree.accountTitleSubName}`,
    }));

    const optionCreditAccountTitleSub = creditAccountTitleSubItemList.map((tree: any) => ({
      value: tree.accountTitleSubId,
      label: `${tree.accountTitleSubCode} ${tree.accountTitleSubName}`,
    }));

    optionDebitAccountTitleSub.unshift({
      value: '',
      label: '-',
    });

    optionCreditAccountTitleSub.unshift({
      value: '',
      label: '-',
    });

    const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

    return (
      <div>
        <FormContents>
          <form>
            <FormField>
              <TextForm
                name="journalCode"
                label="仕訳コード"
                value={formik.values.journalCode}
                required={true}
                onChange={formik.handleChange}
              />
            </FormField>
            <FormField>
              <TextForm
                name="journalName"
                label="仕訳名"
                value={formik.values.journalName}
                required={true}
                onChange={formik.handleChange}
              />
            </FormField>

            <FlexBoxItem>
              <VerticalLabelSelectForm
                label="借方勘定科目"
                name="debitAccountTitleId"
                value={String(formik.values.debitAccountTitleId)}
                setValue={
                  (val: string) => {
                    formik.setFieldValue('debitAccountTitleId', val);
                    setDebitAccountTitleId(val);
                    formik.setFieldValue('debitAccountTitleSubId', '');
                    setTmpDebitAccountTitleSubId('');
                  }
                }
                options={optionAccountTitleMST}
                required={true}
              />
            </FlexBoxItem>

            <FlexBoxItem>
              <VerticalLabelSelectForm
                label="借方補助科目"
                name="debitAccountTitleSubId"
                value={tmpDebitAccountTitleSubId !== null ? String(tmpDebitAccountTitleSubId) : String(optionDebitAccountTitleSub[0].value)}
                setValue={(val: string) => {
                  formik.setFieldValue('debitAccountTitleSubId', val);
                  setTmpDebitAccountTitleSubId(val);
                }}
                options={optionDebitAccountTitleSub}
              />
            </FlexBoxItem>

            <FormField>
              <RadioSelectForm
                label="借方消費税"
                name="debitTaxType"
                items={[
                  {
                    label: '外税',
                    value: '1',
                  },
                  {
                    label: '内税',
                    value: '2',
                  },
                  {
                    label: '対象外',
                    value: '0',
                  },
                ]}
                value={String(formik.values.debitTaxType)}
                setValue={(e) => {
                  formik.setFieldValue('debitTaxType', String(e.target.value));
                  if (e.target.value === '0') {
                    formik.setFieldValue('debitTaxRate', '');
                  }
                  setDebitTaxType(Number(e.target.value));
                }}
                selectorInline={true}
              />
            </FormField>

            <FormField>
              <PercentInput
                label="借方消費税率"
                name="debitTaxRate"
                value={formik.values.debitTaxRate !== null ? String(formik.values.debitTaxRate) : ''}
                onChange={formik.handleChange}
                disabled={getDebitTaxType === 0}
              />
            </FormField>

            <FlexBoxItem>
              <VerticalLabelSelectForm
                label="貸方勘定科目"
                name="creditAccountTitleId"
                value={String(formik.values.creditAccountTitleId)}
                setValue={(val: string) => {
                  formik.setFieldValue('creditAccountTitleId', val);
                  setCreditAccountTitleId(val);
                  formik.setFieldValue('creditAccountTitleSubId', '');
                  setTmpCreditAccountTitleSubId('');
                }}
                options={optionAccountTitleMST}
                required={true}
              />
            </FlexBoxItem>

            <FlexBoxItem>
              <VerticalLabelSelectForm
                label="貸方補助科目"
                name="creditAccountTitleSubId"
                value={tmpCreditAccountTitleSubId !== null ? String(tmpCreditAccountTitleSubId) : String(optionCreditAccountTitleSub[0].value)}
                setValue={(val: string) => {
                  formik.setFieldValue('creditAccountTitleSubId', val);
                  setTmpCreditAccountTitleSubId(val);
                }}
                options={optionCreditAccountTitleSub}
              />
            </FlexBoxItem>

            <FormField>
              <RadioSelectForm
                label="貸方消費税"
                name="creditTaxType"
                items={[
                  {
                    label: '外税',
                    value: '1',
                  },
                  {
                    label: '内税',
                    value: '2',
                  },
                  {
                    label: '対象外',
                    value: '0',
                  },
                ]}
                value={String(formik.values.creditTaxType)}
                setValue={(e) => {
                  formik.setFieldValue('creditTaxType', String(e.target.value));
                  if (e.target.value === '0') {
                    formik.setFieldValue('creditTaxRate', '');
                  }
                  setCreditTaxType(Number(e.target.value));
                }}
                selectorInline={true}
              />
            </FormField>

            <FormField>
              <PercentInput
                label="貸方消費税率"
                name="creditTaxRate"
                value={formik.values.creditTaxRate !== null ? String(formik.values.creditTaxRate) : ''}
                onChange={formik.handleChange}
                disabled={getCreditTaxType === 0}
              />
            </FormField>

            <FormSubmitArea>
              {
                roleScreen && roleScreen.editable === 1 && (
                  <div style={{ marginRight: '12px' }}>
                    <Button
                      text={journalId ? '更新' : '登録'}
                      onClick={formik.handleSubmit}
                    />
                  </div>
                )
              }
              <Link to="/accountTitleMST">
                <Button
                  text="戻る"
                  ghost={true}
                />
              </Link>
            </FormSubmitArea>
          </form>
        </FormContents>

        <ConfirmModal
          title="確認メッセージ"
          content={journalId ? '更新します。よろしいですか？' : '登録します。よろしいですか？'}
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.submitForm}
          items={[]}
        />
      </div>
    );
  };

export default JournalMasterForm;
