/** @jsx jsx */
import React,{useState} from 'react';
import { css, jsx } from '@emotion/core';

/**
 * アイコンつきのリンク
 */



const EmptyDiscountItem: React.FC<{
 
}> = ({
 
}) => {

return (
      <tr>
        <td className="text-center" colSpan={2}>-</td>
        <td className="text-center ">-</td>
        <td className="text-center">-</td>
        <td className="text-center">-</td>
        <td className="text-center">-</td>

      </tr>
    );
}
export default EmptyDiscountItem;
