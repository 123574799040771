/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import { Rnd, RndResizeCallback, RndDragCallback } from 'react-rnd';
import moment from 'moment';

import { ShiftDaily } from 'api/shift';

import { productColor, textColor } from 'components/styles';
import BodyText from 'components/atoms/BodyText';

import { useShiftDayCalendarContext, useShiftBarChangeAction } from './hooks';

const displayDateFormat = 'HH:mm';

const styles = {
  shitBar: css({
    backgroundColor: productColor.primary,
    borderRadius: '4px',
  }),
};

const ShiftBar: React.FC<{
  shiftDaily: ShiftDaily;
  employmentId: string;
  /** @deprecated */
  belongOrgCode?: string;
  canShiftChange: boolean;
  orgCode?: string;
  belongOrgName?: string;
}> = ({
  shiftDaily, employmentId, canShiftChange, orgCode, belongOrgName,
}) => {
  const {
    date, quarterHourPixel, dispStartHour, dispHour, showShiftEditPanelAction,
  } = useShiftDayCalendarContext();
  const shiftChangeAction = useShiftBarChangeAction();
  const [x, setX] = React.useState(0);

  const canMove = React.useMemo(() => {
    // 公休の場合はシフト操作はできない
    if (shiftDaily.holidayId) {
      return false;
    }
    return canShiftChange;
  }, [canShiftChange, shiftDaily.holidayId]);

  /**
   * 座標から時間を取得
   */
  const positionToTime = React.useCallback((position: number) => {
    const decimalHour = position / (quarterHourPixel * 4) + dispStartHour;
    const hour = Math.floor(decimalHour);
    const minutes = (decimalHour - hour) * 60;
    return {
      hour,
      minutes,
    };
  }, [dispStartHour, quarterHourPixel]);

  /**
   * シフトの開始位置を取得
   */
  const getShiftStartPixel = (shiftStart: string) => {
    const shiftStartDate = moment(shiftStart);
    const startDate = moment(date).hour(dispStartHour).minutes(0).second(0);
    const diffMinutes = shiftStartDate.diff(startDate, 'minutes');

    // 開始時間より前だったら0にする
    return Math.round(diffMinutes < 0 ? 0 : diffMinutes / 15) * quarterHourPixel;
  };

  /**
   * シフトバーの長さを取得
   */
  const getShiftTimePixel = (shiftStart: string, shiftEnd: string) => {
    const shiftStartDate = moment(shiftStart);
    const shiftEndDate = moment(shiftEnd);
    const shiftTimeMinutes = shiftEndDate.diff(shiftStartDate, 'minutes');
    return Math.round(shiftTimeMinutes / 15) * quarterHourPixel;
  };

  /**
   * リサイズ完了
   */
  const onResizeStop: RndResizeCallback = React.useCallback(
    (e, dir, elementRef, delta, position) => {
      const start = positionToTime(position.x);
      const end = positionToTime(parseInt(elementRef.style.width, 10) + position.x);
      shiftChangeAction(
        dir === 'left' ? 1 : 2, // 1:開始時間の移動 2:終了時間の移動
        shiftDaily.staffCode,
        shiftDaily.shiftId,
        moment(date).hour(start.hour).minutes(start.minutes).format('YYYY/MM/DD HH:mm'),
        moment(date).hour(end.hour).minutes(end.minutes).format('YYYY/MM/DD HH:mm'),
      );
    }, [date, positionToTime, shiftChangeAction, shiftDaily.shiftId, shiftDaily.staffCode],
  );

  /**
   * ドラッグ完了
   */
  const onDragStop: RndDragCallback = React.useCallback((e, data) => {
    if (data.deltaX === 0) {
      // クリック時はなにもしない
      return;
    }
    const start = positionToTime(data.lastX);
    const end = positionToTime(parseInt(data.node.style.width, 10) + data.lastX);
    shiftChangeAction(
      0, // 0:全体の移動
      shiftDaily.staffCode,
      shiftDaily.shiftId,
      moment(date).hour(start.hour).minutes(start.minutes).format('YYYY/MM/DD HH:mm'),
      moment(date).hour(end.hour).minutes(end.minutes).format('YYYY/MM/DD HH:mm'),
    );
  }, [date, positionToTime, shiftChangeAction, shiftDaily.shiftId, shiftDaily.staffCode]);

  const [checkBelongOrg, setCheckBelongOrg] = useState('0');

  return (
    <Rnd
      size={{ width: getShiftTimePixel(shiftDaily.startTime, shiftDaily.endTime), height: '100%' }}
      position={{ x: getShiftStartPixel(shiftDaily.startTime), y: -32 }}
      key={shiftDaily.startTime}
      css={styles.shitBar}
      default={{
        x: getShiftStartPixel(shiftDaily.startTime),
        y: 0,
        width: getShiftTimePixel(shiftDaily.startTime, shiftDaily.endTime),
        height: '100%',
      }}
      dragAxis="x"
      enableResizing={{
        right: (shiftDaily.belongOrgCode !== shiftDaily.orgCode && shiftDaily.belongOrgCode === orgCode) || shiftDaily.orgCode !== orgCode ? !canMove : canMove,
        left: (shiftDaily.belongOrgCode !== shiftDaily.orgCode && shiftDaily.belongOrgCode === orgCode) || shiftDaily.orgCode !== orgCode ? !canMove : canMove,
        top: false,
        bottom: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      disableDragging={(shiftDaily.belongOrgCode !== shiftDaily.orgCode && shiftDaily.belongOrgCode === orgCode) || shiftDaily.orgCode !== orgCode ? canMove : !canMove}
      bounds="parent"
      resizeGrid={[quarterHourPixel, 0]}
      dragGrid={[quarterHourPixel, 0]}
      minWidth={quarterHourPixel}
      maxWidth={quarterHourPixel * 4 * (dispHour + 1)}
      maxHeight={24}
      onResizeStop={(shiftDaily.belongOrgCode !== shiftDaily.orgCode && shiftDaily.belongOrgCode === orgCode) || shiftDaily.orgCode !== orgCode ? () => {} : onResizeStop}
      onDragStop={onDragStop}
    >
      <button
        type="button"
        css={{
          width: '100%',
          height: '100%',
          padding: '0',
          margin: '0',
          boxSizing: 'border-box',
          textAlign: 'left',
          backgroundColor: 'transparent',
          border: 'none',
          borderRadius: '4px',
          display: 'table-cell',
          verticalAlign: 'middle',
          outline: 'none',
        }}
        onMouseDown={(e) => {
          if ((shiftDaily.belongOrgCode !== shiftDaily.orgCode && shiftDaily.belongOrgCode === orgCode) || shiftDaily.orgCode !== orgCode) {
            setCheckBelongOrg('1');
          } else {
            setCheckBelongOrg('0');
          }
          setX(e.pageX);
        }}
        onMouseUp={(e) => {
          if (e.pageX !== x) {
            return;
          }
          if (!canShiftChange) {
            return;
          }
          if ((shiftDaily.belongOrgCode !== shiftDaily.orgCode && shiftDaily.belongOrgCode === orgCode) || shiftDaily.orgCode !== orgCode) {
            setCheckBelongOrg('1');
          } else {
            setCheckBelongOrg('0');
          }
          showShiftEditPanelAction(shiftDaily.shiftId, employmentId, checkBelongOrg);
        }}
      >
        <BodyText
          color={textColor.inversed}
          customStyle={css({ verticalAlign: '100%', marginLeft: '4px' })}
        >

          {shiftDaily.holidayName ? (
            <BodyText size="sm" color={textColor.inversed}>
              {shiftDaily.holidayName}
            </BodyText>
          ) : (
            <React.Fragment>
              {moment(shiftDaily.startTime).format(displayDateFormat)}
              {' - '}
              {moment(shiftDaily.endTime).format(displayDateFormat)}
              {shiftDaily.belongOrgCode !== shiftDaily.orgCode
                ? (shiftDaily.orgCode !== orgCode ? ` (${shiftDaily.orgName})` : '') : (shiftDaily.orgCode !== orgCode ? ` (${shiftDaily.orgName})` : '')}
            </React.Fragment>
          )}
        </BodyText>
      </button>
    </Rnd>
  );
};

export default ShiftBar;
