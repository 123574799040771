import ApiClient from './ApiClient';
import BankDepositReportDomain from 'domain/master/storeManagement/bankDepositReport'

export interface bankDeposit {
  orgCode: string;
  businessStartDate: string;
  businessEndDate: string;
  isDifference:boolean;
  loginStaffCode:string;
  functionType: number;
  categoryAry: any;
}

// 銀行入金突合種別(0:現金売上 1: 銀行預入金 2:紙幣合計)
export type BankDepositMatchingType = 0 | 1 | 2;

export interface BankDepositReportData {
	reportList: BankDepositReportDomain[];
	bankDepositMatchingType: BankDepositMatchingType;
}

export const bankDepositDetailReport = async (param:bankDeposit): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/bank/deposit/detailReport/${companyCode}`, param);
  return response.data;
};

export const downloadBankDepositDetailReportCsv = async (BankDepositDetailReport: any,
  fileName: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsvPost(`/v1/reportExportFile/csv/${companyCode}`, {}, BankDepositDetailReport, fileName);
};

export const downloadBankDepositDetailReportExcel = async (BankDepositDetailReport: any,
  fileName: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadExcelPost(`/v1/reportExportFile/excel/${companyCode}`, {}, BankDepositDetailReport, fileName);
};

export default {};
