import React from 'react';
import {
  BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';
import { setConfiguration } from 'react-grid-system';
import moment from 'moment';
// import { withAuthenticator } from 'aws-amplify-react'; // ログイン画面のモジュールの読み込み
import Amplify from 'aws-amplify'; // amplifyのモジュールの読み込み
import awsExports from 'aws-exports'; // 設定情報を読み込みます。
import ToastContainerWrapper from 'components/molecules/ToastNotification';
import PrivateRoute from './PrivateRoute';

// 共通(common)

// ログイン(login)
import LoginPage from 'components/pages/login/LoginPage';
import SignUpPage from 'components/pages/login/SignUpPage';
import ActivationPage from 'components/pages/login/ActivationPage';
import ForgotPasswordPage from 'components/pages/login/ForgotPasswordPage';
import ForgotPasswordSubmitPage from 'components/pages/login/ForgotPasswordSubmitPage';
// マイページ
import PersonalSetting from 'components/pages/personalSetting/PersonalSetting';
// タイムレコーダー
import TimeRecorderPage from 'components/pages/master/attend/TimeRecorder/TimeRecorderPage';
// GPS打刻
import TimeRecorderGPSPage from 'components/pages/attend/timeRecorderGPS';
// ダッシュボード
import DashboardPage from 'components/pages/master/general/dashboard/DashboardPage';
// セッション有効期限切れ
import SessionExpireError from 'components/pages/error/SessionExpireError';
// Not Found (404)
import NotFound from 'components/pages/NotFound';
// メンテナンス中
import Maintenance from 'components/pages/Maintenance';



// 損益管理(sales)

// 予算編成
import salesBudgetYear from 'components/pages/master/storeManagement/salesBudgetYear';
import salesBudgetYearImport from 'components/pages/master/storeManagement/salesBudgetYearImport';
// 予算編成（月間）
import SalesBudgetMonth from 'components/pages/master/storeManagement/salesBudgetMonth/salesBudgetMonth';
// レポート
import SalesMonthlyReportPage from 'components/pages/master/storeManagement/salesReport/SalesMonthlyReportPage';
// レポート（日次）
import SalesDailyReportPage from 'components/pages/master/storeManagement/salesReport/SalesDailyReportPage';
// 小口現金管理
import PettyCashPage from './components/pages/master/storeManagement/pettyCash';
// 月次帳票
import MonthlyReportPage from 'components/pages/master/storeManagement/report/MonthlyReportPage';
// 月次日別帳票
import SeparateBillPage from 'components/pages/master/storeManagement/report/ SeparateBillReport/ SeparateBillPage';
// 年次帳票
import YearlySalesManagementPage from 'components/pages/master/storeManagement/report/YearlySalesManagementPage';
// 商品別帳票
import FormByProduct from 'components/pages/master/storeManagement/report/FormByProduct';
// 時間別帳票
import TimeListPage from 'components/pages/master/storeManagement/report/TimeList';
// 日次コメント帳票
import CommentListPage from './components/pages/master/storeManagement/commentList';

// 【日別】本部確認帳票
import SalesPage from './components/pages/master/general/salesPage';
// 【店別】本部確認帳票
import SalesPageStore from './components/pages/master/general/salesPage/salesPageStore';
// 【日別】売上管理表
import dailySalesManagement from './components/pages/master/general/salesPage/dailySalesManagement';
// 【店別】売上管理表
import storeSalesManagement from './components/pages/master/general/salesPage/storeSalesManagement';
// 営業管理帳票
import salesManagement from './components/pages/master/general/salesPage/salesMangement';
// 媒体分析帳票
import mediaAnalysisBill from './components/pages/master/general/salesPage/mediaAnalysisBill';
// 媒体売上帳票
import mediaSales from './components/pages/master/general/salesPage/mediaSales';
// 本部用指摘内容確認帳票
import headquarterPointedOut from './components/pages/master/general/salesPage/headquarterPointedOut';
// 月次書類提出確認表
import MonthlySubmissionConfirmationPage from 'components/pages/master/storeManagement/report/MonthlySubmissionConfirmationPage';
// 指摘件数分析帳票
import NumberIndicationAnalysisPage from 'components/pages/master/storeManagement/report/NumberIndicationAnalysisPage';
// 【店別】収支表帳票
import incomeAndExpenditureStorePage from 'components/pages/master/storeManagement/salesForm/index';
// 【月別】収支表帳票
import incomeExpenditurePage from './components/pages/master/general/salesPage/incomeExpenditure';
// 【店別】収支明細表
import balanceStatementStorePage from 'components/pages/master/sales/balanceStatementStore/index';
// 【月別】収支明細表
import IncomeAndExpenditureScheduleMonthlyPage from 'components/pages/master/sales/incomeAndExpenditureScheduleMonthly/index';

// レシート別売上登録
import SalesSlipPage from './components/pages/master/sales/salesSlip/SalesSlipPage';
// 銀行入金インポート
import BankDepositImportPage from 'components/pages/master/bank/import/index';
// 銀行入金登録
import BankRegistPage from 'components/pages/master/bank/regist/index';
// 入出金明細
import PaymentDetailsPage from 'components/pages/master/storeManagement/payment/paymentDetails/index';
// 入出金仕訳データ出力
import BankDepositExportPage from './components/pages/master/bank/export';
// 売上管理日報
import DailySalesManagementPage from './components/pages/master/storeManagement/report/DailySalesManagement';
// 広告売上管理月報
import SalesAdvertisingMonthlyReportPage from 'components/pages/master/general/salesPage/salesAdvertisingMonthlyReport';
// 広告プラン別売上
import SalesAdvertisingPlanReport from './components/pages/master/sales/salesAdvertisingPlanReport';
// 日別店舗売上
import MonthlyDailyStore from 'components/pages/master/storeManagement/report/MonthlyDailyStore';

// 日別売上管理表
import DaySeparateSalesStoreManagementPage from 'components/pages/master/general/salesPage/daySeparateSalesStoreManagement';


// 勤怠管理(attend)

// マイシフト
import SchedulePage from 'components/pages/master/attend/SchedulePage';
// 勤務状況
import WorkingStatusPage from 'components/pages/master/workingStatus/WorkingStatusPage';
// シフト編成
import ShiftPage from 'components/pages/master/ShiftPage';
// 勤務スケジュール（日次）
import DailyShift from 'components/pages/master/shift/daily';
// 勤務スケジュール（月次）
import MonthlyShift from 'components/pages/master/shift/monthly';
import MonthlyShiftImportLayoutPage from 'components/pages/master/shift/monthly/MonthlyShiftImportForm/MonthlyShiftImportLayoutPage';
// 勤務データ確認
import AttendResult from 'components/pages/master/attend/AttendResult';
// 勤務データ確認（勤怠確定用）
import AttendMonthlyList from 'components/pages/master/attend/AttendMonthlyList';
// 打刻データ確認
import AttendStampList from 'components/pages/master/attend/AttendStampList';
// 勤怠エラーリスト
import AttendanceErrorPage from 'components/pages/master/attendanceRecord/AttendanceErrorPage';
// 勤怠承認
import AttendApplicationApprovalPage from 'components/pages/master/attend/AttendApplicationApproval';
// 勤怠ロック
import AttendAchievementLockPage from 'components/pages/master/attend/AttendAchievementLock';
// 勤怠確定
import HeadquartersFinalPage from 'components/pages/master/attend/HeadquartersFinalPage';
// 給与明細インポート
import AttendPayslipImport from 'components/pages/master/atendanceManagement/AttendPayslipImport';
// 勤怠実績印刷
import AttendAchievementOutput from 'components/pages/master/attend/AttendAchievementOutput';
// 休暇実績
import HolidayManagementPage from 'components/pages/master/attend/HolidayManagementPage/HolidayManagementPage';
import HolidayManagementImportPage from 'components/pages/master/attend/HolidayManagementPage/HolidayManagementImportPage';
// 他店舗支援
import AttendHelpListPage from 'components/pages/master/attend/AttendHelpListPage';
// 打刻実績
import StampHistoryPage from 'components/pages/master/atendanceManagement/StampHistoryPage';
// GPS打刻実績
import GpsStampHistoryPage from 'components/pages/attend/GpsStampHistoryPage';
// 勤怠再計算
import TimeRecalculationPage from 'components/pages/master/atendanceManagement/TimeRecalculationPage';
// CSV出力
import AttendCSVOutputPage from 'components/pages/master/attend/AttendCSVOutputPage';
// payme連携
import PayMeCooperationPage from 'components/pages/master/atendanceManagement/PayMeCooperationPage';

// 打刻修正申請・打刻追加申請
import StampModificationPage from 'components/pages/master/attend/StampModificationPage';
// 休暇申請
import HolidayApplicationPage from 'components/pages/master/attend/HolidayApplicationPage';
// 残業申請
import OvertimeApplicationPage from 'components/pages/master/attend/OvertimeApplicationPage';
// 休日出勤申請
import HolidayWorkApplicationPage from 'components/pages/master/attend/HolidayWorkApplicationPage';
// 振替申請
import TransferApplicationPage from 'components/pages/master/attend/TransferApplicationPage';

// 打刻修正申請 承認・否決
import ApprovalModificationApplicationPage from 'components/pages/master/attend/ApprovalApplicationPage/ApprovalModificationApplicationPage';
// 休暇申請 承認・否決
import ApprovalHolidayApplicationPage from 'components/pages/master/attend/ApprovalApplicationPage/ApprovalHolidayApplicationPage';
// 残業申請 承認・否決
import ApprovalOvertimeApplicationPage from 'components/pages/master/attend/ApprovalApplicationPage/ApprovalOvertimeApplicationPage';
// 休日出勤申請 承認・否決
import ApprovalHolidayworkApplicationPage from 'components/pages/master/attend/ApprovalApplicationPage/ApprovalHolidayworkApplicationPage';
// 振替申請 承認・否決
import ApprovalTransferApplicationPage from 'components/pages/master/attend/ApprovalApplicationPage/ApprovalTransferApplicationPage';

// 歩合登録
import PercentRegistration from './components/pages/master/atendanceManagement/PercentRegistrationPage';
// SmartHR 連携情報
import SmartHRSyncReportPage from 'components/pages/master/storeManagement/smartHRSyncReport';

// 勤務先人件費一覧
import AttendAchievementListPage from 'components/pages/master/attend/attendAchievementList';


// ユーザー設定(user_settings)

// 組織マスタ
import OrganizationsPage from 'components/pages/master/general/organization/OrganizationsPage';
import OrganizationAddPage from 'components/pages/master/general/organization/OrganizationAddPage';
import OrganizationEditPage from 'components/pages/master/general/organization/OrganizationEditPage';
import OrganizationManagersPage from 'components/pages/master/general/organization/OrganizationManagersPage';
import OrganizationSpecialSalaryPage from 'components/pages/master/general/organization/OrganizationSpecialSalaryPage';
import OrganizationCsvUploadPage from 'components/pages/master/general/organization/OrganizationCsvUploadPage/OrganizationCsvUploadPage';
// スタッフマスタ
import StaffsPage from 'components/pages/master/general/staff/StaffsPage';
import StaffEditPage from 'components/pages/master/general/staff/StaffEditPage';
import StaffCsvUploadPage from 'components/pages/master/general/staff/StaffCsvUploadPage/StaffCsvUploadPage';
import StaffAddPage from 'components/pages/master/general/staff/StaffAddPage';
// スタッフマスタ（店舗用）
import ShopStaffsPage from 'components/pages/master/general/shopStaff/ShopStaffsPage';
// import ShopStaffEditPage from 'components/pages/master/general/shopStaff/ShopStaffEditPage';
// import ShopStaffAddPage from 'components/pages/master/general/shopStaff/ShopStaffAddPage';
// ユーザー登録状況
import StaffRegistrationStatusPage from 'components/pages/master/general/staff/StaffRegistrationStatusPage'
// 顔写真設定
import MasterShopFaceRegistrationPage from 'components/pages/master/laborAgreement/MasterShopFaceRegistrationPage';
// 組織カテゴリマスタ
import OrganizationCategoryPage from './components/pages/master/general/organizationCategory/OrganizationCategoryPage';
import OrganizationCategoryAddPage from './components/pages/master/general/organizationCategory/OrganizationCategoryAddPage';
import OrganizationCategoryImportPage from 'components/pages/master/general/organizationCategory/OrganizationCategoryImportPage';
// 役職マスタ
import ExecutiveIndexPage from 'components/pages/master/laborAgreement/Executive/ExecutiveIndexPage';
import ExecutiveFormAddPage from 'components/pages/master/laborAgreement/Executive/ExecutiveFormAddPage';
import ExecutiveImportPage from 'components/pages/master/laborAgreement/Executive/ExecutiveFormImportPage';
// 権限マスタ
import authorityMasterPage from './components/pages/master/authority/authorityMasterPage';
import authorityMasterDetail from './components/pages/master/authority/authorityMaster/authorityMasterDetail';


// 損益管理設定(sales_settings)

// 支払種別マスタ
import PaymentTypePage from 'components/pages/master/sales/paymentTypeMaster';
import MediaMasterCreatePage from 'components/pages/master/sales/paymentTypeMaster/MediaMasterCreatePage';
import MediaMasterImportPage from 'components/pages/master/sales/paymentTypeMaster/MediaMasterImportPage';
import PaymentImportPage from 'components/pages/master/sales/paymentTypeMaster/PaymentImportPage';
import PaymentImportCreatePage from 'components/pages/master/sales/paymentTypeMaster/PaymentImportCreatePage';
// 値引・割引種別マスタ
import DiscountTypeImportPage from 'components/pages/master/sales/discountTypeMaster/DiscountTypeImportPage';
import DiscountTypeAddPage from 'components/pages/master/sales/discountTypeMaster/DiscountTypeAddPage';
import discountTypeMst from 'components/pages/master/sales/discountTypeMaster';
import PriceWidthrawlImportPage from 'components/pages/master/sales/discountTypeMaster/PriceWidthrawlImportPage';
import PriceWithrawlAddPage from './components/pages/master/sales/discountTypeMaster/PriceWithrawlAddPage';
// 税率マスタ
import TaxRateCaptureSetting from 'components/pages/master/sales/taxRateCaptureSetting';
// 広告媒体マスタ
import AdvertisingMasterPage from 'components/pages/master/sales/advertisingMaster';
import AdvertisingMediaAddPage from 'components/pages/master/sales/advertisingMaster/AdvertisingMediaAddPage';
import AdvertisingMediaImportPage from 'components/pages/master/sales/advertisingMaster/AdvertisingMediaImportPage';
import AdvertisingImportCreatePage from 'components/pages/master/sales/advertisingMaster/AdvertisingImportCreatePage';
import AdvertisingMasterImportPage from './components/pages/master/sales/advertisingMaster/AdvertisingMasterImportPage';
// 会計年度マスタ
import FiscalYearsPage from 'components/pages/master/sales/fiscalYearMaster/FiscalYearsPage';
import FiscalYearAddPage from 'components/pages/master/sales/fiscalYearMaster/FiscalYearAddPage';
// 商品カテゴリマスタ
import SalesMenuType from 'components/pages/master/sales/salesMenuType';
// 小口現金マスタ
import AccountTitleMSTPage from './components/pages/master/general/accountTitleMST/accountTitleMSTPage/AccountTitleMSTPage';
// 小口現金 - 勘定科目マスタ
import AccountTitleMSTAddPage from './components/pages/master/general/accountTitleMST/accountTitleMSTAddPage/AccountTitleMSTAddPage';
import AccountTitleMSTImportPage from './components/pages/master/general/accountTitleMST/accountTitleMSTImportPage/AccountTitleMSTImportPage';
// 小口現金 - 補助科目マスタ
import AccountTitleSubMSTAddPage from './components/pages/master/general/accountTitleMST/accountTitleSubMSTAddPage/AccountTitleSubMSTAddPage';
import AccountTitleSubMSTImportPage from './components/pages/master/general/accountTitleMST/accountTitleSubMSTImportPage/AccountTitleSubMSTImportPage';
// 小口現金 - 仕訳マスタ
import JournalMasterAddPage from './components/pages/master/general/accountTitleMST/accountTitleMSTAddPage/JournalMasterAddPage';
import journeyMasterImportPage from './components/pages/master/general/accountTitleMST/accountTitleMSTImportPage/JournalMasterImportPage';
// 小口現金 - 摘要マスタ
import DescriptionMSTAddPage from './components/pages/master/general/accountTitleMST/accountTitleSubMSTAddPage/DescriptionMSTAddPage';
import DescriptionMSTImportPage from './components/pages/master/general/accountTitleMST/accountTitleSubMSTImportPage/DescriptionMSTImportPage';
// 損益管理共有マスタ
import SalesSetting from './components/pages/master/general/salesSetting';
// その他経費マスタ
import SalesExpensesMSTAddPage from './components/pages/master/general/salesExpenesesMST/salesExpensesMSTAdd';
import SalesExpensesMSTEditPage from './components/pages/master/general/salesExpenesesMST/salesExpensesMSTEdit';
import SalesExpensesMSTListPage from './components/pages/master/general/salesExpenesesMST/salesExpensesMSTList';
import SalesExpensesMSTImportLayoutPage from "./components/pages/master/general/salesExpenesesMST/salesExpensesMSTImport";
// 取引先マスタ
import SalesSuppliersMSTImportPage from './components/pages/master/general/salesSuppliersMST/salesSuppliersMSTImport';
import SalesSuppliersMSTAddPage from './components/pages/master/general/salesSuppliersMST/salesSuppliersMSTAdd';
import SalesSuppliersMSTEditPage from './components/pages/master/general/salesSuppliersMST/salesSuppliersMSTEdit';
import SalesSuppliersMSTListPage from './components/pages/master/general/salesSuppliersMST/salesSuppliersMSTList';

// 広告プランマスタ
import AdvertisingPage from 'components/pages/master/advertising/AdvertisingPage';
import AdvertisingCreatePage from './components/pages/master/advertising/AdvertisingCreate';
import AdvertisingImportPage from './components/pages/master/advertising/AdvertisingImport';
import CreateStoreSettingsPage from './components/pages/master/advertising/CreateStoreSettings';
import StoreSettingImportPage from './components/pages/master/advertising/StoreSettingImport';


// 勤怠管理設定(attend_settings)

// シフト設定
import MasterOrganizeSettingPage from 'components/pages/master/laborAgreement/MasterOrganizeSettingPage';
// 端末設定
import MasterShopSpecifyPage from 'components/pages/master/laborAgreement/MasterShopSpecifyPage';
// シフトパターンマスタ
import ShiftPatternPage from './components/pages/master/attend/ShiftPatternPage/ShiftPatternIndexPage';
import ShiftPatternAddPage from 'components/pages/master/attend/ShiftPatternPage/ShiftPatternAddPage/ShiftPatternAddPage';
// 労務規約項目マスタ
import AgreementMasterPage from 'components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage';
// 労務規約項目マスタ - 祝日
import HolidayCreatePage from 'components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/HolidayCreatePage';
import HolidayFormAddPage from 'components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/HolidayImportPage';
// 労務規約項目マスタ - 特賃日
import SpecialSalaryPatternAddPage from './components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/SpecialSalaryPatternAddPage';
import SpecialSalaryPatternEditPage from './components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/SpecialSalaryPatternEditPage';
// 労務規約項目マスタ - 休暇
import VacationCreatePage from 'components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/VacationCreatePage';
// 労務規約項目マスタ - 業務
import BusinessMasterPage from 'components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/BusinessMasterPage';
import BusinessCreatePage from 'components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/BusinessCreatePage';
// 労務規約項目マスタ - 時間帯
import TermMasterAddPage from 'components/pages/master/attend/term/TermMasterAddPage';
// 労務規約項目マスタ - 有給
import PaidHolidayPatternAddPage from 'components/pages/master/attend/PaidHolidayPattern/PaidHolidayPatternAddPage';
// 労務規約項目マスタ - 所定日数
import WorkingDaysCreatePage from 'components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/PrescribedDaysCreatePage/WorkingDaysCreatePage';
import WorkingDaysEditPage from 'components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/PrescribedDaysEditPage/WorkingDaysEditPage';
// 労務規約項目マスタ - 所定時間
import WorkingHoursAddPage from 'components/pages/master/attend/working_hours/WorkingHoursAddPage';
// 雇用形態マスタ
import EmploymentFormAddPage from 'components/pages/master/laborAgreement/employment/EmploymentFormAddPage';
import EmploymentFormPage from 'components/pages/master/laborAgreement/employment/EmploymentFormPage';
// 給与取込レイアウト設定
import SalarryCsvSettingFormPage from 'components/pages/master/laborAgreement/SalaryCsvSetting/SalarryCsvSettingFormPage';
// 勤怠出力レイアウト設定
import TimeOutputLayoutAddPage from 'components/pages/master/attend/TimeOutputLayout/TimeOutputLayoutAddPage';
import TimeOutputLayoutListPage from 'components/pages/master/attend/TimeOutputLayout/TimeOutputLayoutListPage';


// 専用機能(extension)

// 速報（将軍ジャパン専用）
import OperationalReportQuickPage from './components/pages/master/sales/operationalReportQuick';
// 売上日報一覧（将軍ジャパン専用）
import DaySeparateReportPage from 'components/pages/master/storeManagement/report/ShogunPage/DaySeparateReportPage';
// 売上日報（将軍ジャパン専用）
import ShogunSalesDailyReportPage from 'components/pages/master/storeManagement/report/ShogunPage/SalesDailyReportPage';
// 期間売上（将軍ジャパン専用）
import PeriodSalesPage from './components/pages/master/sales/periodSales';
// 出退勤状況（将軍ジャパン専用）
import ShogunStampHistoryPage from 'components/pages/master/sales/shogunStampHistory';
// 稼働状況一覧（将軍ジャパン専用）
import OperationalReportListPage from 'components/pages/master/sales/operationalReportList';
// タイムリストデータ出力（将軍ジャパン専用）
import TimeListDataOutput from './components/pages/master/sales/TimeListDataOutput';

// 廃止(abolition)

//// 業態マスタ
// import BusinessCategoryAddPage from 'components/pages/master/sales/businessCategory/BusinessCategoryAddPage';
// import BusinessCategoryPage from 'components/pages/master/sales/businessCategory/BusinessCategoryPage';
// import BusinessCategoryImportPage from 'components/pages/master/sales/businessCategory/BusinessCategoryImportPage';
//// エリアマスタ
// import AreasPage from 'components/pages/master/sales/area/AreasPage';
// import AreasAddPage from 'components/pages/master/sales/area/AreasAddPage';
// import AreasImportPage from 'components/pages/master/sales/area/AreasImportPage';


// 設定情報をAmplifyに反映させます。
Amplify.configure(awsExports);

// react-grid-systemの設定
setConfiguration({
  gutterWidth: 0,
});

// momentの日本語設定
moment.locale('ja', {
  months: ['１月', '2月', '3月', '4月', '5月', '６月', '７月', '８月', '９月', '１０月', '１１月', '１２月'],
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
});

const App: React.FC = () => {
  const ComponentInMobie = <PrivateRoute path="/:option?/:detailOption?/:informationDetail?" component={DashboardPage} />;

  sessionStorage.removeItem('mobileDashboard');
  sessionStorage.removeItem('mobileSchedule');
  sessionStorage.removeItem('mobileWorkAchievement');

  return (
    <>
      <Router>
        <Switch>
          <Route exact={true} path="/login">
            <LoginPage />
          </Route>
          <Route exact={true} path="/login/:approvalMode/:applicationId">
            <LoginPage />
          </Route>
          <Route exact={true} path="/signup">
            <SignUpPage />
          </Route>
          <Route exact={true} path="/activation/:mailAddress">
            <ActivationPage />
          </Route>
          <Route exact={true} path="/activation">
            <ActivationPage />
          </Route>
          <Route exact={true} path="/forgotPassword">
            <ForgotPasswordPage />
          </Route>
          <Route exact={true} path="/forgotPasswordSubmit/:mailAddress">
            <ForgotPasswordSubmitPage />
          </Route>
          <Route exact={true} path="/timeRecorder">
            <TimeRecorderPage />
          </Route>
          <Route exact={true} path="/maintenance">
            <Maintenance />
          </Route>
          <Route path="/expire">
            <SessionExpireError />
          </Route>
          <Route exact={true} path="/404">
            <NotFound />
          </Route>

          <PrivateRoute path="/accountTitleSubMst/add" component={AccountTitleSubMSTAddPage} />
          <PrivateRoute path="/accountTitleSubMst/edit/:accountTitleSubId" component={AccountTitleSubMSTAddPage} />
          <PrivateRoute path="/accountTitleSubMst/import" component={AccountTitleSubMSTImportPage} />
          <PrivateRoute path="/accountTitleMst/add" component={AccountTitleMSTAddPage} />
          <PrivateRoute path="/accountTitleMst/edit/:accountTitleId" component={AccountTitleMSTAddPage} />
          <PrivateRoute path="/accountTitleMst/import" component={AccountTitleMSTImportPage} />
          <PrivateRoute path="/accountTitleMst" component={AccountTitleMSTPage} />
          <PrivateRoute path="/agreementMaster" component={AgreementMasterPage} />
          <PrivateRoute path="/approvalHolidayworkApplication/:applicationId" component={ApprovalHolidayworkApplicationPage} />
          <PrivateRoute path="/approvalHolidayApplication/:applicationId" component={ApprovalHolidayApplicationPage} />
          <PrivateRoute path="/approvalModificationApplication/:applicationId" component={ApprovalModificationApplicationPage} />
          <PrivateRoute path="/approvalOvertimeApplication/:applicationId" component={ApprovalOvertimeApplicationPage} />
          <PrivateRoute path="/approvalTransferApplication/:applicationId" component={ApprovalTransferApplicationPage} />
          <PrivateRoute path="/attendAchievementOutput" component={AttendAchievementOutput} />
          <PrivateRoute path="/attendAchivevementList" component={AttendAchievementListPage} />
          <PrivateRoute path="/attendAchievementLock" component={AttendAchievementLockPage} />
          <PrivateRoute path="/attendApplication/:targetDate?" component={AttendApplicationApprovalPage} />
          <PrivateRoute path="/attendCSVOutput" component={AttendCSVOutputPage} />
          <PrivateRoute path="/attendError" component={AttendanceErrorPage} />
          <PrivateRoute path="/attendHeadquartersFinal" component={HeadquartersFinalPage} />
          <PrivateRoute path="/attendHelpList" component={AttendHelpListPage} />
          <PrivateRoute path="/attendHolidayManagementImport/:formatType" component={HolidayManagementImportPage} />
          <PrivateRoute path="/attendHolidayManagement" component={HolidayManagementPage} />
          <PrivateRoute path="/attendHolidayworkApplication" component={HolidayWorkApplicationPage} />
          <PrivateRoute path="/attendHolidayApplication" component={HolidayApplicationPage} />
          <PrivateRoute path="/attendIncentiveSalary" component={PercentRegistration} />
          <PrivateRoute path="/attendMonthlyList" component={AttendMonthlyList} />
          <PrivateRoute path="/attendOvertimeApplication" component={OvertimeApplicationPage} />
          <PrivateRoute path="/AttendPayslipImport" component={AttendPayslipImport} />
          <PrivateRoute path="/attendResult" component={AttendResult} />
          <PrivateRoute path="/attendStampModificationApplication" component={StampModificationPage} />
          <PrivateRoute path="/attendStampList" component={AttendStampList} />
          <PrivateRoute path="/attendTransferApplication" component={TransferApplicationPage} />

          <PrivateRoute path="/balanceStatementStore" component={balanceStatementStorePage} />
          <PrivateRoute path="/bankDepositImport" component={BankDepositImportPage} />
          <PrivateRoute path="/bankDepositRegist" component={BankRegistPage} />
          <PrivateRoute path="/bankDepositExport" component={BankDepositExportPage} />
          <PrivateRoute path="/bankDepositDetailReport" component={PaymentDetailsPage} />
          <PrivateRoute path="/businessMaster/edit/:businessId" component={BusinessCreatePage} />
          <PrivateRoute path="/businessMaster/create" component={BusinessCreatePage} />
          <PrivateRoute path="/businessMaster" component={BusinessMasterPage} />
          <PrivateRoute path="/businessManagement" component={salesManagement} />

          <PrivateRoute path="/commentList" component={CommentListPage} />

          <PrivateRoute path="/dailyShift/attendStampModificationApplication" component={StampModificationPage} />
          <PrivateRoute path="/dailyShift/attendHolidayApplication" component={HolidayApplicationPage} />
          <PrivateRoute path="/dailyShift/attendOvertimeApplication" component={OvertimeApplicationPage} />
          <PrivateRoute path="/dailyShift/attendHolidayworkApplication" component={HolidayWorkApplicationPage} />
          <PrivateRoute path="/dailyShift/attendTransferApplication" component={TransferApplicationPage} />
          <PrivateRoute path="/dailyShift/:orgName?/:orgCode?/:targetDate?" component={DailyShift} />
          <PrivateRoute path="/dashboard/:option?/:detailOption?/:informationDetail?" component={DashboardPage} />
          <PrivateRoute path="/daySeparateHqConfirm" component={SalesPage} />
          <PrivateRoute path="/daySeparateSalesManagementCos/:orgCode?/:targetDateFrom?/:targetDateTo?" component={dailySalesManagement} />
          <PrivateRoute path="/daySeparateSalesStoreManagement" component={DaySeparateSalesStoreManagementPage} />
          <PrivateRoute path="/daySeparateReport" component={DailySalesManagementPage} />
          <PrivateRoute path="/descriptionMst/add" component={DescriptionMSTAddPage} />
          <PrivateRoute path="/descriptionMst/edit/:descriptionId" component={DescriptionMSTAddPage} />
          <PrivateRoute path="/descriptionMst/import" component={DescriptionMSTImportPage} />
          <PrivateRoute path="/discountTypeMst/discountType/import" component={DiscountTypeImportPage} />
          <PrivateRoute path="/discountTypeMst/discountType/edit/:discountTypeMstCode" component={DiscountTypeAddPage} />
          <PrivateRoute path="/discountTypeMst/discountType/add" component={DiscountTypeAddPage} />
          <PrivateRoute path="/discountTypeMst/priceWithdrawal/import" component={PriceWidthrawlImportPage} />
          <PrivateRoute path="/discountTypeMst/priceWithdrawal/edit/:discountTypeCode/:posType/:discountTypeMstCode/:applicableStartMonthFormat/:orgCode" component={PriceWithrawlAddPage} />
          <PrivateRoute path="/discountTypeMst/priceWithdrawal/add/:orgCode" component={PriceWithrawlAddPage} />
          <PrivateRoute path="/discountTypeMst/:currentTab" component={discountTypeMst} />
          <PrivateRoute path="/discountTypeMst" component={discountTypeMst} />

          <PrivateRoute path="/executive/add" component={ExecutiveFormAddPage} />
          <PrivateRoute path="/executive/import" component={ExecutiveImportPage} />
          <PrivateRoute path="/executive/edit/:companyCode/:executiveId" component={ExecutiveFormAddPage} />
          <PrivateRoute path="/executive" component={ExecutiveIndexPage} />

          <PrivateRoute path="/GpsStampHistory" component={GpsStampHistoryPage} />

          <PrivateRoute path="/holidayMaster/edit/:publicHolidayId" component={HolidayCreatePage} />
          <PrivateRoute path="/holidayMaster/create" component={HolidayCreatePage} />
          <PrivateRoute path="/holidayMaster/import" component={HolidayFormAddPage} />

          <PrivateRoute path="/incomeExpenditure" component={incomeExpenditurePage} />
          <PrivateRoute path="/incomeAndExpenditureScheduleMonthly" component={IncomeAndExpenditureScheduleMonthlyPage} />
          <PrivateRoute path="/incomeAndExpenditureStore" component={incomeAndExpenditureStorePage} />
          <PrivateRoute path="/itemList" component={FormByProduct} />

          <PrivateRoute path="/journalMaster/add" component={JournalMasterAddPage} />
          <PrivateRoute path="/journalMaster/edit/:journalId" component={JournalMasterAddPage} />
          <PrivateRoute path="/journalMaster/import" component={journeyMasterImportPage} />

          <PrivateRoute path="/masterEmploymentForm/add" component={EmploymentFormAddPage} />
          <PrivateRoute path="/masterEmploymentForm/edit/:isEdit/:employmentId/:applyStartDate" component={EmploymentFormAddPage} />
          <PrivateRoute path="/masterEmploymentForm" component={EmploymentFormPage} />
          <PrivateRoute path="/masterFiscalYear/add" component={FiscalYearAddPage} />
          <PrivateRoute path="/masterFiscalYear/edit/:fiscalYear" component={FiscalYearAddPage} />
          <PrivateRoute path="/masterFiscalYear" component={FiscalYearsPage} />
          <PrivateRoute path="/masterOrganizeSetting" component={MasterOrganizeSettingPage} />
          <PrivateRoute path="/masterPaidHolidayPattern/add" component={PaidHolidayPatternAddPage} />
          <PrivateRoute path="/masterPaidHolidayPattern/edit/:paidHolidayPatternId" component={PaidHolidayPatternAddPage} />
          <PrivateRoute path="/masterShiftPattern/add" component={ShiftPatternAddPage} />
          <PrivateRoute path="/masterShiftPattern/:typeButton/:shiftPatternId" component={ShiftPatternAddPage} />
          <PrivateRoute path="/masterShiftPattern" component={ShiftPatternPage} />
          <PrivateRoute path="/masterShopSpecify" component={MasterShopSpecifyPage} />
          <PrivateRoute path="/masterShopFaceRegistration" component={MasterShopFaceRegistrationPage} />
          <PrivateRoute path="/masterSpecialSalaryPattern/add" component={SpecialSalaryPatternAddPage} />
          <PrivateRoute path="/masterSpecialSalaryPattern/edit/:specialSalaryPatternId/:tabIndex" component={SpecialSalaryPatternEditPage} />
          <PrivateRoute path="/masterTimeOutputLayout/add" component={TimeOutputLayoutAddPage} />
          <PrivateRoute path="/masterTimeOutputLayout/edit/:layoutId" component={TimeOutputLayoutAddPage} />
          <PrivateRoute path="/masterTimeOutputLayout" component={TimeOutputLayoutListPage} />
          <PrivateRoute path="/masterTerm/add" component={TermMasterAddPage} />
          <PrivateRoute path="/masterTerm/edit/:termId" component={TermMasterAddPage} />
          <PrivateRoute path="/masterSalaryCsvSetting" component={SalarryCsvSettingFormPage} />
          <PrivateRoute path="/masterWorkingDays/add" component={WorkingDaysCreatePage} />
          <PrivateRoute path="/masterWorkingDays/edit/:workingDaysId/:tabIndex" component={WorkingDaysEditPage} />
          <PrivateRoute path="/masterWorkingHours/add" component={WorkingHoursAddPage} />
          <PrivateRoute path="/masterWorkingHours/edit/:workingHoursId" component={WorkingHoursAddPage} />
          <PrivateRoute path="/mediaAnalysis" component={mediaAnalysisBill} />
          <PrivateRoute path="/mediaSales" component={mediaSales} />
          <PrivateRoute path="/monthlyDayStoreSalesManagement" component={MonthlyDailyStore} />
          <PrivateRoute path="/monthlyDailyList/:orgCode?/:targetDateFrom?/:targetDateTo?/:isActiveTable?" component={SeparateBillPage} />
          <PrivateRoute path="/monthlyReport" component={MonthlyReportPage} />
          <PrivateRoute path="/monthlyShift/attendStampModificationApplication" component={StampModificationPage} />
          <PrivateRoute path="/monthlyShift/attendHolidayApplication" component={HolidayApplicationPage} />
          <PrivateRoute path="/monthlyShift/attendOvertimeApplication" component={OvertimeApplicationPage} />
          <PrivateRoute path="/monthlyShift/attendHolidayworkApplication" component={HolidayWorkApplicationPage} />
          <PrivateRoute path="/monthlyShift/attendTransferApplication" component={TransferApplicationPage} />
          <PrivateRoute path="/monthlyShift/import" component={MonthlyShiftImportLayoutPage} />
          <PrivateRoute path="/monthlyShift/:orgName?/:orgCode?/:targetDate?" component={MonthlyShift} />
          <PrivateRoute path="/monthlySubmissionConfirmation" component={MonthlySubmissionConfirmationPage} />

          <PrivateRoute path="/numberIndicationAnalysis" component={NumberIndicationAnalysisPage} />

          <PrivateRoute path="/organizationCategory/add" component={OrganizationCategoryAddPage} />
          <PrivateRoute path="/organizationCategory/edit/:organizationCategoryMstDetailId" component={OrganizationCategoryAddPage} />
          <PrivateRoute path="/organizationCategory/import" component={OrganizationCategoryImportPage} />
          <PrivateRoute path="/organizationCategory" component={OrganizationCategoryPage} />
          <PrivateRoute path="/organizations/add" component={OrganizationAddPage} />
          <PrivateRoute path="/organizations/csv" component={OrganizationCsvUploadPage} />
          <PrivateRoute path="/organizations/:orgCode/specialsalary" component={OrganizationSpecialSalaryPage} />
          <PrivateRoute path="/organizations/:orgCode/managers" component={OrganizationManagersPage} />
          <PrivateRoute path="/organizations/:orgCode" component={OrganizationEditPage} />
          <PrivateRoute path="/organizations" component={OrganizationsPage} />

          <PrivateRoute path="/payMeCooperation" component={PayMeCooperationPage} />
          <PrivateRoute path="/personalSetting" component={PersonalSetting} />
          <PrivateRoute path="/pettyCash/:orgCode?/:targetDate?" component={PettyCashPage} />
          <PrivateRoute path="/pointedOutHqConfirm" component={headquarterPointedOut} />

          <PrivateRoute path="/roleMaster/add" component={authorityMasterDetail} />
          <PrivateRoute path="/roleMaster/edit/:permissionCode" component={authorityMasterDetail} />
          <PrivateRoute path="/roleMaster/copy/:permissionCode" component={authorityMasterDetail} />
          <PrivateRoute path="/roleMaster" component={authorityMasterPage} />

          <PrivateRoute path="/salesAdvertisingMedia/advertisingMaster/edit/:advertisingMediaMstCode" component={AdvertisingMediaAddPage} />
          <PrivateRoute path="/salesAdvertisingMedia/advertisingMaster/add" component={AdvertisingMediaAddPage} />
          <PrivateRoute path="/salesAdvertisingMedia/advertisingMaster/import" component={AdvertisingMediaImportPage} />
          <PrivateRoute path="/salesAdvertisingMedia/advertisingSetting/edit/:importCode/:postType/:advertisingMediaCode/:applicableStartMonthFormat/:orgCode" component={AdvertisingImportCreatePage} />
          <PrivateRoute path="/salesAdvertisingMedia/advertisingSetting/add/:orgCode" component={AdvertisingImportCreatePage} />
          <PrivateRoute path="/salesAdvertisingMedia/advertisingSetting/import" component={AdvertisingMasterImportPage} />
          <PrivateRoute path="/salesAdvertisingMedia/:currentTab" component={AdvertisingMasterPage} />
          <PrivateRoute path="/salesAdvertisingMonthlyReport" component={SalesAdvertisingMonthlyReportPage} />
          <PrivateRoute path="/salesAdvertisingPlanReport" component={SalesAdvertisingPlanReport} />
          <PrivateRoute path="/salesAdvertisingPlan/advertisingOrganization/add" component={CreateStoreSettingsPage} />
          <PrivateRoute path="/salesAdvertisingPlan/advertisingOrganization/edit/:editStore/:checkParam" component={CreateStoreSettingsPage} />
          <PrivateRoute path="/salesAdvertisingPlan/advertisingOrganization/import" component={StoreSettingImportPage} />
          <PrivateRoute path="/salesAdvertisingPlan/advertisingMaster/edit/:advertisingPlanMstCode" component={AdvertisingCreatePage} />
          <PrivateRoute path="/salesAdvertisingPlan/advertisingMaster/add" component={AdvertisingCreatePage} />
          <PrivateRoute path="/salesAdvertisingPlan/advertisingMaster/import" component={AdvertisingImportPage} />
          <PrivateRoute path="/salesAdvertisingPlan/:currentTab" component={AdvertisingPage} />
          <PrivateRoute path="/salesBudgetMonth" component={SalesBudgetMonth} />
          <PrivateRoute path="/salesBudgetYearImport" component={salesBudgetYearImport} />
          <PrivateRoute path="/salesBudgetYear" component={salesBudgetYear} />
          <PrivateRoute path="/salesDailyReport/:orgCode?/:targetDate?" component={SalesDailyReportPage} />
          <PrivateRoute path="/salesExpensesMST/add" component={SalesExpensesMSTAddPage} />
          <PrivateRoute path="/salesExpensesMST/import" component={SalesExpensesMSTImportLayoutPage} />
          <PrivateRoute path="/salesExpensesMST/edit/:expensesCodeId" component={SalesExpensesMSTEditPage} />
          <PrivateRoute path="/salesExpensesMST" component={SalesExpensesMSTListPage} />
          <PrivateRoute path="/salesMenuType" component={SalesMenuType} />
          <PrivateRoute path="/salesMonthlyReport" component={SalesMonthlyReportPage} />
          <PrivateRoute path="/salesPaymentMedia/paymentMaster/edit/:paymentMediaMstCode" component={MediaMasterCreatePage} />
          <PrivateRoute path="/salesPaymentMedia/paymentMaster/add" component={MediaMasterCreatePage} />
          <PrivateRoute path="/salesPaymentMedia/paymentMaster/import" component={MediaMasterImportPage} />
          <PrivateRoute path="/salesPaymentMedia/paymentSetting/edit/:importCode/:postType/:paymentMediaCode/:applicableStartMonthFormat/:orgCode" component={PaymentImportCreatePage} />
          <PrivateRoute path="/salesPaymentMedia/paymentSetting/add/:orgCode" component={PaymentImportCreatePage} />
          <PrivateRoute path="/salesPaymentMedia/paymentSetting/import" component={PaymentImportPage} />
          <PrivateRoute path="/salesPaymentMedia/:currentTab" component={PaymentTypePage} />
          <PrivateRoute path="/salesSetting" component={SalesSetting} />
          <PrivateRoute path="/salesSlip" component={SalesSlipPage} />
          <PrivateRoute path="/salesSuppliersMST/import" component={SalesSuppliersMSTImportPage} />
          <PrivateRoute path="/salesSuppliersMST/add" component={SalesSuppliersMSTAddPage} />
          <PrivateRoute path="/salesSuppliersMST/edit/:suppliersCodeId/:transaction" component={SalesSuppliersMSTEditPage} />
          <PrivateRoute path="/salesSuppliersMST" component={SalesSuppliersMSTListPage} />
          <PrivateRoute path="/schedule" component={SchedulePage} />
          <PrivateRoute path="/shift" component={ShiftPage} />
          <PrivateRoute path="/shogun/daySeparateReport" component={DaySeparateReportPage} />
          <PrivateRoute path="/shogun/salesDailyReport/:orgCode?/:targetDate?" component={ShogunSalesDailyReportPage} />
          <PrivateRoute path="/shogun/periodSales" component={PeriodSalesPage} />
          <PrivateRoute path="/shogun/operationalReportQuick/:orgCode?/:targetDate?" component={OperationalReportQuickPage} />
          <PrivateRoute path="/shogun/stampHistory/:orgCode?/:targetDate?" component={ShogunStampHistoryPage} />
          <PrivateRoute path="/shogun/operationalReportList" component={OperationalReportListPage} />
          <PrivateRoute path="/shogun/timeListDataOutput" component={TimeListDataOutput} />
          <PrivateRoute path="/shopSeparateHqConfirm" component={SalesPageStore} />
          <PrivateRoute path="/shopSeparateSalesManagementCos/:orgCode?/:targetDateFrom?/:targetDateTo?" component={storeSalesManagement} />
          <PrivateRoute path="/smartHRSyncReport" component={SmartHRSyncReportPage} />
          <PrivateRoute path="/staffs/staffRegistrationStatus" component={StaffRegistrationStatusPage} />
          <PrivateRoute path="/staffs/employee/add/:menuType" component={StaffAddPage} />
          <PrivateRoute path="/staffs/employee/:staffCode/:menuType" component={StaffEditPage} />
          <PrivateRoute path="/staffs/employee" component={ShopStaffsPage} />
          <PrivateRoute path="/staffs/add/:menuType" component={StaffAddPage} />
          <PrivateRoute path="/staffs/csv" component={StaffCsvUploadPage} />
          <PrivateRoute path="/staffs/:staffCode/:menuType" component={StaffEditPage} />
          <PrivateRoute path="/staffs" component={StaffsPage} />
          <PrivateRoute path="/stampHistory" component={StampHistoryPage} />

          <PrivateRoute path="/taxRateCaptureSetting" component={TaxRateCaptureSetting} />
          <PrivateRoute path="/timeRecorderGps" component={TimeRecorderGPSPage} />
          <PrivateRoute path="/timeRecalculation" component={TimeRecalculationPage} />
          <PrivateRoute path="/timeList" component={TimeListPage} />

          <PrivateRoute path="/vacationMaster/edit/:vacationId" component={VacationCreatePage} />
          <PrivateRoute path="/vacationMaster/create" component={VacationCreatePage} />

          <PrivateRoute path="/workAchievement" component={WorkingStatusPage} />

          <PrivateRoute path="/yearlyList" component={YearlySalesManagementPage} />

          <PrivateRoute path="/404" component={NotFound} />

          {/* 廃止(abolition) */}
          {/* <PrivateRoute path="/areas/add" component={AreasAddPage} /> */}
          {/* <PrivateRoute path="/areas/import" component={AreasImportPage} /> */}
          {/* <PrivateRoute path="/areas/edit/:companyCode/:areaId" component={AreasAddPage} /> */}
          {/* <PrivateRoute path="/areas" component={AreasPage} /> */}
          {/* <PrivateRoute path="/businessCategories/import" component={BusinessCategoryImportPage} /> */}
          {/* <PrivateRoute path="/businessCategories/edit/:companyCode/:businessCategoryId" component={BusinessCategoryAddPage} /> */}
          {/* <PrivateRoute path="/businessCategories/add" component={BusinessCategoryAddPage} /> */}
          {/* <PrivateRoute path="/businessCategories" component={BusinessCategoryPage} /> */}

          {/* ダッシュボード（SP） */}
          {/* {
              !isMobileOnly
                ? site === '1'
                  ? <PrivateRoute path="/" component={SchedulePage} />
                  : <PrivateRoute path="/" component={DashboardPage} />
                : <PrivateRoute path="/" component={WorkingStatusPage} />
            } */}
          {ComponentInMobie}
        </Switch>
      </Router>
      <ToastContainerWrapper />
    </>
  );
};

// export default withAuthenticator(App);
export default App;
