import React from 'react';

import FormContents from 'components/atoms/Form/FormContents';
import { useParams } from 'react-router-dom';
import TimeOutputLayoutInputAddForm from './TimeOutputLayoutInputAddForm';

const TimeOutputLayoutAddForm: React.FC = () => {
  const { layoutId } = useParams();
  return (
    <FormContents>
      <TimeOutputLayoutInputAddForm layoutId={layoutId || ''} />
    </FormContents>
  );
};

export default TimeOutputLayoutAddForm;
