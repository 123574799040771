import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesSuppliersMSTForm from 'components/organismos/master/general/salesSuppliersMST/salesSuppliersMSTForm';

const SalesExpensesMSTAddPage: React.FC = () => (
  <SidebarTemplate pageTitle="取引先作成">
    <SalesSuppliersMSTForm />
  </SidebarTemplate>
);

export default SalesExpensesMSTAddPage;
