import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesDailyReportLayout from 'components/organismos/master/storeManagement/shogun/SalesDailyReportLayout';

const SalesDailyReportPage = () => (
  <SidebarTemplate pageTitle="売上日報">
    <SalesDailyReportLayout />
  </SidebarTemplate>
);
export default SalesDailyReportPage;
