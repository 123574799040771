import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import OrganizationDomain from 'domain/organization';
import { getHistory, getOrganizationInfoV4, updateOrganizationInfoV4 } from 'api/organization';
import ManagerDomain from 'domain/manager';
import useToastNotification from 'hooks/useToastNotification';
import moment from 'moment';
import { getCompanyBankList } from 'api/companyBank';

export const useOrganizationEdit = () => {
  const defineInputChange = [
    'orgCode', 'orgName', 'orgNameKana', 'organizationCategoryMstDetilId', 'closeFlag', 'applyStartDate', 'applyEndDate', 'employmentId', 'workingDaysId', 'posCode', 'infomartLinkCode', 'dispOrder',
    'managerList', 'seniorManagerList',
    'applyStartMonth', 'specialSalaryPatternId', 'addSalary',
    'zipCode', 'prefectureCode', 'address', 'tel1', 'tel2', 'tel3', 'fax1', 'fax2', 'fax3',
    'operatingCompany', 'operatingCompanyRepresentative', 'operatingCompanyRepresentativeTel', 'entryMethod', 'squareMeters', 'sharedSpaceSquareMeters',
    'floorSpace', 'numberOfSeat', 'shopManager', 'shopManagerTel', 'posContact', 'posContactTel', 'headChef',
    'businessHoursMon', 'businessHoursTue', 'businessHoursWed', 'businessHoursThu', 'businessHoursFri', 'businessHoursSat', 'businessHoursSun',
    'businessHoursHoliday', 'businessHoursLunch', 'closingDay', 'nameList',
  ];
  const [arrInputChange, setArrInputChange] = useState(defineInputChange);
  const { orgCode } = useParams<{orgCode: string}>();
  const [organizationDomain, setOrganizationDomain] = useState<OrganizationDomain>(
    OrganizationDomain.generateInitial(),
  );
  const [managerList, setManagerList] = useState<Array<ManagerDomain>|null>(null);
  const [seniorManagerList, setSeniorManagerList] = useState<Array<ManagerDomain>|null>(null);
  const [historyList, setHistoryList] = useState<Array<OrganizationDomain>>([]);
  const [blocking, setBlocking] = useState(false);
  const [organizationDetail, setOrganizationDetail] = useState<any>();
  const { successNotification, errorNotification } = useToastNotification();
  const [arrCompanyBank, setArrCompanyBank] = useState<any>();

  const fetchData = useCallback(async (startDate?: any) => {
    setBlocking(true);
    const history = await getHistory(orgCode);
    // setOrganizationDomain(new OrganizationDomain(organization));
    // if (organization.managerList && organization.managerList.length > 0) {
    //   setManagerList(organization.managerList.map((manager) => new ManagerDomain(manager)));
    // } else {
    //   setManagerList([]);
    // }
    // if (organization.seniorManagerList && organization.seniorManagerList.length > 0) {
    //   setSeniorManagerList(organization.seniorManagerList.map(
    //     (seniorManager) => new ManagerDomain(seniorManager),
    //   ));
    // } else {
    //   setSeniorManagerList([]);
    // }

    if (history && history.length > 0) {
      const organization = history[0];
      console.log('organization', organization);
      const applyStartDate = startDate ? startDate : organization.applyStartDate;
      try {
        await getOrganizationInfoV4(orgCode, moment(applyStartDate).format('YYYY-MM-DD'), 'large').then((response: any) => {
          setOrganizationDetail(response);
          setBlocking(false)
        });
      }
      catch (error) {
        errorNotification('サーバー側でエラーが発生しました。');
        setBlocking(false);
      }
      setHistoryList(history.map((org) => new OrganizationDomain(org)));
    } else {
      setHistoryList([]);
    }
  }, [orgCode]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchCompanyBank = useCallback(async () => {
    await getCompanyBankList('', true).then((response: any) => {
      setArrCompanyBank(response);
    }).catch((error: any) => {
      setArrCompanyBank([]);
    })
  }, [])

  useEffect(() => {
    fetchCompanyBank()
  }, [fetchCompanyBank])

  const updateOrganization = (state: any, originalApplyStartDate?: any) => {
    setBlocking(true);
    updateOrganizationInfoV4(state, moment(originalApplyStartDate).format('YYYY-MM-DD')).then((response: any) => {
      setBlocking(false);
      fetchData(originalApplyStartDate ? moment(originalApplyStartDate).format('YYYY/MM/DD') : state.organization.applyStartDate);
      setArrInputChange(defineInputChange);
      successNotification('更新しました。');
    }).catch((error: any) => {
      setBlocking(false);
      if (error && error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }
  const handleDeleteSpecialWageSetting = (rowIndex:number) => {
    // const
    const cloneOrgSpecialList = [...organizationDetail.orgSpecialList]
    const newOrgSpecialList = cloneOrgSpecialList.filter((item:any,index:number)=> index !== rowIndex)
    const newOrganizationDetail = {
      ...organizationDetail,
      orgSpecialList: newOrgSpecialList
    }
    setOrganizationDetail(newOrganizationDetail)
  }
  return {
    organizationDomain,
    setOrganizationDomain,
    managerList,
    setManagerList,
    seniorManagerList,
    setSeniorManagerList,
    historyList,
    setHistoryList,
    blocking,
    orgCode,
    organizationDetail,
    fetchData,
    setBlocking,
    updateOrganization,
    handleDeleteSpecialWageSetting,
    arrCompanyBank,
    arrInputChange,
    setArrInputChange,
  };
};

export default {};
