
/**
 * API 勤怠再計算
 */

export interface ApprovalModificationApplication {
  modifierApplicationId: string,
  companyCode: string,
  achievementId: string,
  applicationDate: string,
  orgCode: string,
  staffCode: string,
  applicationStaffCode: string,
  approvalStaffCode: string,
  applicationType: number,
  applicationStatus: number,
  modifiyStatus: number,
  applicationReason: string
  rejectReason: any
  version: number,
  approvalDate: Date,
  holidayId: any,
  isLegal: boolean,
  holidayDigestiveUnit: any,
  holidayStartHm: any,
  holidayEndHm: any,
  holidayTimes: any,
  orgName: string,
  staffName: string,
  approvalStaffName: string,
  applicationStaffName: string,
  targetDate: string,
  belongOrgName: string,
  stampList: Array<any>,
}


export default class ApprovalModificationApplicationDomain {
  constructor(private rawData: ApprovalModificationApplication) {
  // do nothing
  }

  static generateInitial(): ApprovalModificationApplicationDomain {
    return new ApprovalModificationApplicationDomain({
      modifierApplicationId: '',
      companyCode: '',
      achievementId: '',
      applicationDate: '',
      orgCode: '',
      staffCode: '',
      applicationStaffCode: '',
      approvalStaffCode: '',
      applicationType: 0,
      applicationStatus: 0,
      modifiyStatus: 0,
      applicationReason: '',
      rejectReason: '',
      version: 0,
      approvalDate: new Date(),
      holidayId: '',
      isLegal: false,
      holidayDigestiveUnit: '',
      holidayStartHm: '',
      holidayEndHm: '',
      holidayTimes: '',
      orgName: '',
      staffName: '',
      approvalStaffName: '',
      applicationStaffName: '',
      targetDate: '',
      belongOrgName: '',
      stampList: [],
    });
  }

  getRawData(): ApprovalModificationApplication {
    return this.rawData;
  }

  get modifierApplicationId():string {
    return this.rawData.modifierApplicationId;
  }

  set modifierApplicationId(modifierApplicationId:string) {
    this.rawData.modifierApplicationId = modifierApplicationId;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode:string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get applicationStaffName():string {
    return this.rawData.applicationStaffName;
  }

  set applicationStaffName(applicationStaffName:string) {
    this.rawData.applicationStaffName = applicationStaffName;
  }

  get applicationStaffCode():string {
    return this.rawData.applicationStaffCode;
  }

  set applicationStaffCode(applicationStaffCode:string) {
    this.rawData.applicationStaffCode = applicationStaffCode;
  }

  get applicationDate():string {
    return this.rawData.applicationDate;
  }

  set applicationDate(applicationDate:string) {
    this.rawData.applicationDate = applicationDate;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  get targetDate():string {
    return this.rawData.targetDate;
  }

  set targetDate(targetDate:string) {
    this.rawData.targetDate = targetDate;
  }

  get modifiyStatus():number {
    return this.rawData.modifiyStatus;
  }

  set modifiyStatus(modifiyStatus:number) {
    this.rawData.modifiyStatus = modifiyStatus;
  }

  get stampList():Array<any> {
    return this.rawData.stampList;
  }

  set stampList(stampList:Array<any>) {
    this.rawData.stampList = stampList;
  }

  get applicationReason():string {
    return this.rawData.applicationReason;
  }

  set applicationReason(applicationReason:string) {
    this.rawData.applicationReason = applicationReason;
  }

  get applicationStatus():number {
    return this.rawData.applicationStatus;
  }

  set applicationStatus(applicationStatus:number) {
    this.rawData.applicationStatus = applicationStatus;
  }

  get approvalStaffCode():string {
    return this.rawData.approvalStaffCode;
  }

  set approvalStaffCode(approvalStaffCode:string) {
    this.rawData.approvalStaffCode = approvalStaffCode;
  }

  get approvalStaffName():string {
    return this.rawData.approvalStaffName;
  }

  set approvalStaffName(approvalStaffName:string) {
    this.rawData.approvalStaffName = approvalStaffName;
  }

  get rejectReason():string {
    return this.rawData.rejectReason;
  }

  set rejectReason(rejectReason:string) {
    this.rawData.rejectReason = rejectReason;
  }

  get companyCode():string {
    return this.rawData.companyCode;
  }

  set companyCode(companyCode:string) {
    this.rawData.companyCode = companyCode;
  }

  get achievementId():string {
    return this.rawData.achievementId;
  }

  set achievementId(achievementId:string) {
    this.rawData.achievementId = achievementId;
  }

  get applicationType():number {
    return this.rawData.applicationType;
  }

  set applicationType(applicationType:number) {
    this.rawData.applicationType = applicationType;
  }

  get version():number {
    return this.rawData.version;
  }

  set version(version:number) {
    this.rawData.version = version;
  }

  get approvalDate():Date {
    return this.rawData.approvalDate;
  }

  set approvalDate(approvalDate:Date) {
    this.rawData.approvalDate = approvalDate;
  }

  get holidayId():string {
    return this.rawData.holidayId;
  }

  set holidayId(holidayId:string) {
    this.rawData.holidayId = holidayId;
  }

  get isLegal():boolean {
    return this.rawData.holidayId;
  }

  set isLegal(isLegal:boolean) {
    this.rawData.isLegal = isLegal;
  }

  get holidayDigestiveUnit():string {
    return this.rawData.holidayDigestiveUnit;
  }

  set holidayDigestiveUnit(holidayDigestiveUnit:string) {
    this.rawData.holidayDigestiveUnit = holidayDigestiveUnit;
  }


  get holidayStartHm():string {
    return this.rawData.holidayStartHm;
  }

  set holidayStartHm(holidayStartHm:string) {
    this.rawData.holidayStartHm = holidayStartHm;
  }

  get holidayEndHm():string {
    return this.rawData.holidayEndHm;
  }

  set holidayEndHm(holidayEndHm:string) {
    this.rawData.holidayEndHm = holidayEndHm;
  }

  get holidayTimes():string {
    return this.rawData.holidayTimes;
  }

  set holidayTimes(holidayTimes:string) {
    this.rawData.holidayTimes = holidayTimes;
  }

  get belongOrgName():string {
    return this.rawData.belongOrgName;
  }

  set belongOrgName(belongOrgName:string) {
    this.rawData.belongOrgName = belongOrgName;
  }

  // get

// custom data use DataTable
}
