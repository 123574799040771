/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import {
  updateCommentForSale,
  updateCashierReportManual,
  updateImageDetailReport,
  deleteImageDetailReport,
  DetailApplicationData,
} from 'api/salesReport';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FlexBox from 'components/atoms/FlexBox';
import PrimaryButton from 'components/atoms/Button';
import { css } from '@emotion/core';
import useToastNotification from 'hooks/useToastNotification';
import FormLabel from 'components/atoms/Form/FormLabel';
import FormatInput from '../FormatInput';
import ConfirmModal from 'components/organismos/ConfirmModal';
import FormField from 'components/atoms/Form/FormField';
import FileSelectButton from 'components/molecules/FileSelectButton';
import ClickableLink from 'components/atoms/ClickableLink';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import moment from 'moment';
import DiscountTableItem from 'components/molecules/CashRegisterDiscountItem';
import { Link } from 'react-router-dom';
import useSalesMonthlyReport from './hooks';
import EmptyDiscountItem from './EmptyDiscountItem';
import ReportInstruction from './ReportInstruction';
import useApplicationReport from './hookApplicationReport';
import ExpenseInformation from './ExpenseInformation';
import { Literal, Values } from "utility/constants";

const formatNumberMinus = (number: string) => {
  if (number === '-0') {
    return number.replace(/[/-]/g, '');
  }
  return number;
}

export const handleRemoveKeepCursorPosition = (e:any) =>{
  // const { target } = e;
  // let caret = target.selectionStart;
  // const element = target;
  // if (numberFormaterInput(removeComma(target.value)).split('').length - target.value.length === 1) {
  //   caret = caret + 1
  // } else if (numberFormaterInput(removeComma(target.value)).split('').length - target.value.length === -1) {
  //   caret = caret - 1
  //  if(caret === -1){
  //    caret = 0
  //  }else if(target.value.indexOf('-') !== -1 && caret === 0){
  //    caret = 1
  //  }
  // }
  // window.requestAnimationFrame(() => {
  //   element.selectionStart = caret;
  //   element.selectionEnd = caret;
  // });
}
const modalImage = css`
  div:not(:first-child) div button {
    display: none;
  }
`;

const tableImage = css`
  margin: 0 auto;
  display: flex;
`;

const domainUrl = process.env.REACT_APP_ATTACH_BASE_URL;

const CashRegisterReport: React.FC<{
  dailyData: any;
  cashRegisterReport: any;
  setIsLoading: any;
  orgName: any;
  targetOrgCode: any;
  targetDay: any;
  setGeneralData: any;
  updateCountDayAndAdvertising: any;
  budgetProgressComment: string;
  storeOperationComment: string;
  otherComment: string;
  setBudgetProgressComment: any;
  setStoreOperationComment: any;
  setOtherComment: any;
  paymentMediaAmountManual: any;
  setPaymentMediaAmountManual: any;
  attachFileData: any;
  setAttachFileData: any;
  discountTypeData: any;
  setDiscountTypeData: any;
  getStatus: (status: number | null) => void
  sameDayData: any,
  setting: any,
  detailApplicationData :DetailApplicationData,
  selectType: string,
  roleScreen?: any
}> = ({
  dailyData,
  cashRegisterReport,
  setIsLoading,
  targetOrgCode,
  targetDay,
  setGeneralData,
  updateCountDayAndAdvertising,
  budgetProgressComment,
  storeOperationComment,
  otherComment,
  setBudgetProgressComment,
  setStoreOperationComment,
  setOtherComment,
  paymentMediaAmountManual,
  setPaymentMediaAmountManual,
  attachFileData,
  setAttachFileData,
  discountTypeData,
  setDiscountTypeData,
  getStatus,
  sameDayData,
  setting,
  detailApplicationData,
  orgName,
  roleScreen,
}) => {
  console.log('CashRegisterReport start -------------------------------');
  console.log('discountTypeData', discountTypeData);

    const {
      canManualInput,
      canComment,
      canAttach,
      canInstructUpdate,
      canInstructAdd,
      RequestButton,
      ApproveButton,
      RejectButton,
      WithdrawButton,
      ApproveButtonConfirm,
      isTableVisibility,
      isTurnOff,
      isDetailApplicationDisplay,
      status
    } = useApplicationReport(targetDay, targetOrgCode, getStatus);
    const { successNotification, errorNotification } = useToastNotification();
    const [buttonType, setButtonType] = useState(0);
    //
    const [openModal, setOpenModal] = useState(false);
    const [imgModal, setImgModal] = useState<any>();

    // Update Daily Report

    // Table 1 売上情報
    const [eatInManual, setEatInManual] = useState(
      dailyData?.cash_data?.manual_input.eat_in_manual || 0,
    );
    const [takeOutManual, setTakeOutManual] = useState(
      dailyData?.cash_data?.manual_input.takeout_manual || 0,
    );
    const [eatInTakeOutManual, setEatInTakeOutManual] = useState(
      (((takeOutManual) ? takeOutManual : 0 )
      +(((eatInManual) ? eatInManual :0))) || 0,
    );
    const [discountTypeDataPost, setDiscountTypeDataPost] = useState(
      discountTypeData.map((item: any) => ({
        discountCode: item.discount_type_mst_code,
        discountCnt: item.discount_cnt_manual,
        discountAmount: item.discount_amount_manual,
      })),
    );
    const [takeOutTaxManual, setTakeOutTaxManual] = useState(
      dailyData?.cash_data?.manual_input.takeout_tax_manual || 0,
    );
    const [eatInTaxManual, setEatInTaxManual] = useState(
      dailyData?.cash_data?.manual_input.eat_in_tax_manual || 0,
    );
    const [totalTakeOutEatInTax, setTotalTakeOutEatInTax] = useState(
      (((takeOutTaxManual) ? takeOutTaxManual : 0)
      +
      ((eatInTaxManual) ? eatInTaxManual : 0))
      || 0,
    );

    const [cashVoucherSalesTotalAmountManual, setCashVoucherSalesTotalAmountManual] = useState(
      dailyData?.cash_data?.manual_input.cash_voucher_sales_total_amount_manual || 0,
    );

    const [
      totalDiscountAmountManualReduced,
      setTotalDiscountAmountManualReduced,
    ] = useState(0);
    const [totalDiscountAmountReduced, setTotalDiscountAmountReduced] = useState(
      0,
    );

    const [guestCNTManual, setGuestCNTManual] = useState(
      dailyData?.cash_data?.manual_input.guest_cnt_manual || 0,
    );

    const [customerManual, setCustomerManual] = useState(
      dailyData?.cash_data?.manual_input.customers_manual || 0,
    );

    // Table 2 入出金・ドロア情報
    const [totalPaymentManual, setTotalPaymentManual] = useState(
      dailyData?.cash_data?.manual_input.total_payment_manual || 0,
    );
    const [totalWithDrawalManual, setTotalWithDrawalManual] = useState(
      dailyData?.cash_data?.manual_input.total_withdrawal_manual || 0,
    );

    const [changeReserveManual, setChangeReserveManual] = useState(
      dailyData?.cash_data?.manual_input.change_reserve_manual || 0,
    );
    const [cashStockManual, setCashStockManual] = useState(
      dailyData?.cash_data?.manual_input.cash_stock_manual || 0,
    );
    const [
      cashVoucherPaymentTotalAmountManual,
      setCashVoucherPaymentTotalAmountManual,
    ] = useState(
      dailyData?.cash_data?.manual_input.cash_voucher_payment_total_amount_manual
      || 0,
    );
    const [excessAndDeficiencyManual, setExcessAndDeficiencyManual] = useState(
      dailyData?.cash_data?.manual_input.excess_and_deficiency_manual || 0,
    );
    const [nextDayChangeManual, setNextDayChangeManual] = useState(
      dailyData?.cash_data?.manual_input.next_day_change_manual || 0,
    );
    const [bankDepositAmountManual, setBankDepositAmountManual] = useState(
      dailyData?.cash_data?.manual_input.bank_deposit_amount_manual || 0,
    );
    const [bankDepositAmountPOS, setBankDepositAmountPOS] = useState(
      setting && setting.calculationBankDeposit && dailyData?.cash_data?.deposit_withdrawal ? 0 : dailyData?.cash_data?.deposit_withdrawal.bank_deposit_amount,
    )

    // Table 3 精算情報
    const payment_code_specific = dailyData?.cash_data
      && dailyData?.cash_data?.payment_media
      && dailyData?.cash_data?.payment_media[0]
      && dailyData?.cash_data?.payment_media[0].payment_media_mst_code === '000'
      && dailyData?.cash_data?.payment_media[0].payment_media_amount_manual ? dailyData?.cash_data?.payment_media[0].payment_media_amount_manual:0;
    const [mstCodeSpecific, setMSTCodeSpecific] = useState(
      ((payment_code_specific) ? payment_code_specific : 0) || 0,
    );
    const [allPaymentMediaAmount, setAllPaymentMediaAmount] = useState(0);
    const [allPaymentMediaAmountManual, setAllPaymentMediaAmountManual] = useState(0);
    const [paymentMediaNameMaxColSpan, setPaymentMediaNameMaxColSpan] = useState(2);
    const getPaymentMediaNameMaxColSpan = () => cashRegisterReport?.checkoutInformation?.reduce((p: number, c: any) =>
        Math.max( p, !Number.isNaN(c.colspan) ? Number(c.colspan) + 1 : 2)
      , 2);
    const sumAmountPaymentMedia = () => cashRegisterReport?.checkoutInformation?.reduce((p: number, c: any) =>
        removeComma(p) + removeComma(c.data.payment_media_amount)
      , 0);
    const sumAmountPaymentMediaManual = () => paymentMediaAmountManual.reduce((p: number, c: any) =>
        removeComma(p) + removeComma(c.payment_media_amount_manual)
      , 0);

    // Table 4 経費情報
    const [foodCost, setFoodCost] = useState(
      dailyData?.cash_data?.manual_input.food_cost_manual || 0,
    );
    const [drinkCostManual, setDrinkCostManual] = useState(
      dailyData?.cash_data?.manual_input.drink_cost_manual || 0,
    );
    const [otherCostManual, setOtherCostManual] = useState(
      dailyData?.cash_data?.manual_input.other_cost_manual || 0,
    );

    // Table 5 アラート情報
    const [receiptCNTManual, setReceiptCNTManual] = useState(
      dailyData?.cash_data?.manual_input.receipt_cnt_manual || 0,
    );
    const [receiptAmountManual, setReceiptAmountManual] = useState(
      dailyData?.cash_data?.manual_input.receipt_amount_manual || 0,
    );
    const [voidCNTManual, setVoidCNTManual] = useState(
      dailyData?.cash_data?.manual_input.void_cnt_manual || 0,
    );
    const [voidAmountManual, setVoidAmountManual] = useState(
      dailyData?.cash_data?.manual_input.void_amount_manual || 0,
    );

    // Table 6 人件費情報
    const [employeeSalaryManual, setEmployeeSalaryManual] = useState(
      dailyData?.cash_data?.manual_input.employee_salary_manual || 0,
    );
    const [partSalaryManual, setPartSalaryManual] = useState(
      dailyData?.cash_data?.manual_input.part_salary_manual || 0,
    );

    const re = /^[0-9\,\-\b]+$/;

    const formatter = new Intl.NumberFormat('ja-JP', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    const numberFormater = (num: any) => {
      if (typeof num === 'undefined' || isNaN(num)) {
        return num;
      }
      const tmp = String(num).split('.');
      if (tmp[1]) {
        let fixed = 3;
        if (tmp[1].length < 3) {
          fixed = tmp[1].length;
        }
        return parseFloat(num).toFixed(fixed);
      }
      return formatter.format(num);
    };

    const removeComma = (number: any) => {
      const tmp = String(number).replace(/[\,]/g, '');
      if ((Number(tmp))) {
        return Number(tmp);
      }
      return number === '-' ? number : 0;
    };

    const updateFieldChanged = (index: number, e: any) => {
      const newArr = [...paymentMediaAmountManual];
      newArr[index].payment_media_amount_manual = numberFormater(
        removeComma(e.target.value),
      );
      setPaymentMediaAmountManual(newArr);
    };
    const updateDiscountTypeDataFieldChanged = (index: number, e: any) => {
      const newArr = [...discountTypeData];
      newArr[index].discount_amount_manual = removeComma(e.target.value);
      setDiscountTypeData(newArr);
    };
    const updateCntDiscountTypeDataFieldChanged = (index: number, e: any) => {
      const newArr = [...discountTypeData];
      newArr[index].discount_cnt_manual = removeComma(e.target.value);
      setDiscountTypeData(newArr);
    };
    const [imageOPen, setimageOpen] = useState(false);

    const getExtention = (fileName: String) => {
      const i = fileName.lastIndexOf('.');
      if (i === -1) return false;
      return fileName.slice(i);
    };

    const imageURL = imgModal;
    // ----page-- many image
    let StringPhoto = '';
    let descript = '';
    if (attachFileData.length) {
      attachFileData.map((element: any) => {
        if (element && element.path) {
          let extensionFile = getExtention(element.file_name).toString().toLowerCase();
          if (extensionFile !== '.pdf') {
            StringPhoto += `${element.path},`;
            descript += `${element.file_name},`;
            return StringPhoto;
          }
        }
      });
    }

    const images = StringPhoto.slice(0, -1).split(',');
    const Arraydescript = descript.slice(0, -1).split(',');

    // Reset the initial value when closing the model
    const [photoIndex, setphotoIndex] = useState(images.indexOf(imageURL));

    const [purchaseCostOtherThanSystemDataFood, setPurchaseCostOtherThanSystemDataFood] = useState(dailyData?.cash_data?.purchase_cost_other_than_system_food.map((item: any) => (
      { suppliers_code: item.suppliers_code, purchase_amount: item.purchase_amount }
    )) || []);

    const [purchaseCostOtherThanSystemDataDrink, setPurchaseCostOtherThanSystemDataDrink] = useState(dailyData?.cash_data?.purchase_cost_other_than_system_drink.map((item: any) => (
      { suppliers_code: item.suppliers_code, purchase_amount: item.purchase_amount, drink_classification: item.drink_classification }
    )) || []);

    const [purchaseCostOtherThanSystemDataOther, setPurchaseCostOtherThanSystemDataOther] = useState(dailyData?.cash_data?.purchase_cost_other_than_system_other.map((item: any) => (
      { suppliers_code: item.suppliers_code, purchase_amount: item.purchase_amount }
    )) || []);


    const [remainingBefor, setRemainingBefor] = useState(dailyData?.cash_data?.manual_input.revenue_stamp_remaining_befor || 0);
    const [purchaseNumber, setPurchaseNumber] = useState(dailyData?.cash_data?.manual_input.revenue_stamp_purchase || 0);
    const [stampAdjustment, setStampAdjustment] = useState(dailyData?.cash_data?.manual_input.revenue_stamp_adjustment || 0);
    const [stampUse, setStampUse] = useState(dailyData?.cash_data?.manual_input.revenue_stamp_use || 0);
    const [remaining, setRemaining] = useState(dailyData?.cash_data?.manual_input.revenue_stamp_remaining || 0);


    const [foodSystem, setFoodSystem] = useState(dailyData?.cash_data?.cash_register.food_cost || 0);
    const [drinkSystem, setDrinkSystem] = useState(dailyData?.cash_data?.cash_register.drink_cost || 0);
    const [otherSystem, setOtherSystem] = useState(dailyData?.cash_data?.cash_register.other_cost || 0);

    const [foodTotalManual, setFoodTotalManual] = useState(dailyData?.cash_data?.cash_register.food_cost + dailyData?.cash_data?.manual_input.food_cost_manual || 0);
    const [drinkTotalManual, setDrinkTotalManual] = useState(dailyData?.cash_data?.cash_register.drink_cost + dailyData?.cash_data?.manual_input.drink_cost_manual || 0);
    const [otherTotalManual, setOtherTotalManual] = useState(dailyData?.cash_data?.cash_register.other_cost + dailyData?.cash_data?.manual_input.other_cost_manual || 0);

    const formatDate = (date : Date) : string => {
      return moment(date).format("YYYY-MM-DD HH:mm:ss")
    }

    const handleOpenFile = async (pathFile: string): Promise<any> => {
      if (pathFile) {
          window.open(pathFile, '_blank');
      }
      return true;
    };

    useEffect(() => {
      setphotoIndex(images.indexOf(imageURL));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageOPen]);

    useEffect(() => {
      cashRegisterReport
        && cashRegisterReport.countPaymentMedia
        && cashRegisterReport.checkoutInformation
        && cashRegisterReport.checkoutInformation.map((info: any, index: number) => {
          if (info.data.payment_media_mst_code === '000') {
            setMSTCodeSpecific(info.data.payment_media_amount_manual);
          } else {
            setMSTCodeSpecific(0);
          }
        });

      setEatInManual(dailyData?.cash_data?.manual_input.eat_in_manual);
      setTakeOutManual(dailyData?.cash_data?.manual_input.takeout_manual);
      setTakeOutTaxManual(dailyData?.cash_data?.manual_input.takeout_tax_manual);
      setEatInTaxManual(dailyData?.cash_data?.manual_input.eat_in_tax_manual);
      setCashVoucherSalesTotalAmountManual(dailyData?.cash_data?.manual_input.cash_voucher_sales_total_amount_manual);
      setGuestCNTManual(dailyData?.cash_data?.manual_input.guest_cnt_manual);
      setCustomerManual(dailyData?.cash_data?.manual_input.customers_manual);
      setTotalPaymentManual(
        dailyData?.cash_data?.manual_input.total_payment_manual,
      );
      setTotalWithDrawalManual(
        dailyData?.cash_data?.manual_input.total_withdrawal_manual,
      );
      setChangeReserveManual(
        dailyData?.cash_data?.manual_input.change_reserve_manual,
      );
      setCashStockManual(dailyData?.cash_data?.manual_input.cash_stock_manual);
      setCashVoucherPaymentTotalAmountManual(
        dailyData?.cash_data?.manual_input.cash_voucher_payment_total_amount_manual,
      );
      setExcessAndDeficiencyManual(
        dailyData?.cash_data?.manual_input.excess_and_deficiency_manual,
      );
      setNextDayChangeManual(
        dailyData?.cash_data?.manual_input.next_day_change_manual,
      );
      setBankDepositAmountManual(
        dailyData?.cash_data?.manual_input.bank_deposit_amount_manual,
      );
      setPaymentMediaAmountManual(
        (cashRegisterReport
          && cashRegisterReport?.countPaymentMedia
          // eslint-disable-next-line no-mixed-operators
          && cashRegisterReport?.checkoutInformation?.map(
            (info: any, index: number) => ({
              payment_media_amount_manual: info.data.payment_media_amount_manual,
              payment_media_mst_code: info.data.payment_media_mst_code,
            }),
            // eslint-disable-next-line no-mixed-operators
          ))
        || [],
      );
      setFoodCost(dailyData?.cash_data?.manual_input.food_cost_manual);
      setDrinkCostManual(dailyData?.cash_data?.manual_input.drink_cost_manual);
      setOtherCostManual(dailyData?.cash_data?.manual_input.other_cost_manual);
      setReceiptCNTManual(dailyData?.cash_data?.manual_input.receipt_cnt_manual);
      setReceiptAmountManual(
        dailyData?.cash_data?.manual_input.receipt_amount_manual,
      );
      setVoidCNTManual(dailyData?.cash_data?.manual_input.void_cnt_manual);
      setVoidAmountManual(dailyData?.cash_data?.manual_input.void_amount_manual);
      setPartSalaryManual(dailyData?.cash_data?.manual_input.part_salary_manual);
      setEmployeeSalaryManual(
        dailyData?.cash_data?.manual_input.employee_salary_manual,
      );
      setMSTCodeSpecific(payment_code_specific);

      setFoodSystem(dailyData?.cash_data?.cash_register.food_cost);
      setRemaining(dailyData?.cash_data?.manual_input.revenue_stamp_remaining);
      setRemainingBefor(dailyData?.cash_data?.manual_input.revenue_stamp_remaining_befor);
      setPurchaseNumber(dailyData?.cash_data?.manual_input.revenue_stamp_purchase);
      setStampAdjustment(dailyData?.cash_data?.manual_input.revenue_stamp_adjustment);
      setStampUse(dailyData?.cash_data?.manual_input.revenue_stamp_use);
      setRemaining(dailyData?.cash_data?.manual_input.revenue_stamp_remaining_befor + dailyData?.cash_data?.manual_input.revenue_stamp_purchase
        + dailyData?.cash_data?.manual_input.revenue_stamp_adjustment - dailyData?.cash_data?.manual_input.revenue_stamp_use);
      setFoodTotalManual(dailyData?.cash_data?.cash_register.food_cost + dailyData?.cash_data?.manual_input.food_cost_manual);
      setDrinkTotalManual(dailyData?.cash_data?.cash_register.drink_cost + dailyData?.cash_data?.manual_input.drink_cost_manual);
      setDrinkSystem(dailyData?.cash_data?.cash_register.drink_cost);
      setOtherSystem(dailyData?.cash_data?.cash_register.other_cost);
      setOtherTotalManual(dailyData?.cash_data?.cash_register.other_cost + dailyData?.cash_data?.manual_input.other_cost_manual);

      setPurchaseCostOtherThanSystemDataFood(dailyData?.cash_data?.purchase_cost_other_than_system_food.map((item: any) => (
        { suppliers_code: item.suppliers_code, purchase_amount: item.purchase_amount }
      )) || []);
      setPurchaseCostOtherThanSystemDataDrink(dailyData?.cash_data?.purchase_cost_other_than_system_drink.map((item: any) => (
        { suppliers_code: item.suppliers_code, purchase_amount: item.purchase_amount, drink_classification: item.drink_classification }
      )) || []);
      setPurchaseCostOtherThanSystemDataOther(dailyData?.cash_data?.purchase_cost_other_than_system_other.map((item: any) => (
        { suppliers_code: item.suppliers_code, purchase_amount: item.purchase_amount }
      )) || []);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dailyData]);

    useEffect(() => {
      setPaymentMediaNameMaxColSpan(getPaymentMediaNameMaxColSpan());
      setAllPaymentMediaAmount(sumAmountPaymentMedia());
      setAllPaymentMediaAmountManual(sumAmountPaymentMediaManual());
    }, [cashRegisterReport]);

    useEffect(() => {
      setAllPaymentMediaAmountManual(sumAmountPaymentMediaManual());
    }, [paymentMediaAmountManual]);

    useEffect(() => {
      setEatInTakeOutManual(
        ((takeOutManual) ? takeOutManual : 0)
      + ((eatInManual) ? eatInManual : 0)
      );
    }, [takeOutManual, eatInManual]);

    useEffect(() => {
      setDiscountTypeDataPost(
        discountTypeData.map((item: any) => ({
          discountCode: item.discount_type_mst_code,
          discountCnt: item.discount_cnt_manual,
          discountAmount: item.discount_amount_manual,
        })),
      );
      setTotalDiscountAmountManualReduced(
        discountTypeData && discountTypeData.length > 0
          ? discountTypeData.reduce(
            (initialState: any, value: any) => initialState + ((value.discount_amount_manual) ? Number(value.discount_amount_manual) : 0),
            0,
          )
          : dailyData?.cash_data?.manual_input.total_discount_amount_manual,
      );
      setTotalDiscountAmountReduced(
        discountTypeData && discountTypeData.length > 0
          ? discountTypeData.reduce(
            (initialState: any, value: any) => initialState + ((value.discount_amount) ? Number(value.discount_amount) : 0),
            0,
          )
          : dailyData?.cash_data?.cash_register?.total_discount_amount,
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discountTypeData]);

    useEffect(() => {
      setTotalTakeOutEatInTax(
        ((takeOutTaxManual) ? takeOutTaxManual : 0)
      + ((eatInTaxManual) ? eatInTaxManual: 0)
      );
    }, [takeOutTaxManual, eatInTaxManual]);

    const {
      confirmModalOpen,
      closeConfirmModal,
      setConfirmModalOpen,
    } = useSalesMonthlyReport();

    const handleUpdateCommentForSale = () => {
      setConfirmModalOpen(false);
      setIsLoading(true);

      updateCommentForSale(
        targetOrgCode,
        new Date(targetDay),
        budgetProgressComment,
        storeOperationComment,
        otherComment,
      )
        .then((data: any) => {
          setIsLoading(false);
          successNotification('更新しました。');
        })
        .catch((exception: any) => {
          setIsLoading(false);
          errorNotification('サーバー側でエラーが発生しました。');
        });
    };

    const handleUpdateCashierReportManual = () => {
      setConfirmModalOpen(false);
      setIsLoading(true);

      if (paymentMediaAmountManual) {
        const formatPaymentMedia = [...paymentMediaAmountManual];
        formatPaymentMedia.map((info: any, index: number) => {
          info.payment_media_amount_manual = removeComma(
            info.payment_media_amount_manual,
          );
        });
        setPaymentMediaAmountManual(formatPaymentMedia);
      } else {
        setPaymentMediaAmountManual([]);
      }

      if (purchaseCostOtherThanSystemDataFood && purchaseCostOtherThanSystemDataFood.length > 0) {
        const formatPurchaseCost = [...purchaseCostOtherThanSystemDataFood];
        formatPurchaseCost.map((info: any, index: number) => {
          info.purchase_amount = removeComma(
            info.purchase_amount,
          );
        });
        setPurchaseCostOtherThanSystemDataFood(formatPurchaseCost);
      } else {
        setPurchaseCostOtherThanSystemDataFood([]);
      }

      if (purchaseCostOtherThanSystemDataDrink && purchaseCostOtherThanSystemDataDrink.length > 0) {
        const formatDrinkCost = [...purchaseCostOtherThanSystemDataDrink];
        formatDrinkCost.map((info: any, index: number) => {
          info.purchase_amount = removeComma(
            info.purchase_amount,
          );
        });
        setPurchaseCostOtherThanSystemDataDrink(formatDrinkCost);
      } else {
        setPurchaseCostOtherThanSystemDataDrink([]);
      }

      if (purchaseCostOtherThanSystemDataOther && purchaseCostOtherThanSystemDataOther.length > 0) {
        const formatOthers = [...purchaseCostOtherThanSystemDataOther];
        formatOthers.map((info: any, index: number) => {
          info.purchase_amount = removeComma(
            info.purchase_amount,
          );
        });
        setPurchaseCostOtherThanSystemDataOther(formatOthers);
      } else {
        setPurchaseCostOtherThanSystemDataOther([]);
      }
      const postData = {
        net_sales_manual: removeComma((eatInTakeOutManual) ? eatInTakeOutManual : 0),
        eat_in_manual: removeComma((eatInManual) ? eatInManual : 0),
        takeout_manual: removeComma((takeOutManual) ? takeOutManual : 0),
        tax_manual: removeComma((totalTakeOutEatInTax) ? totalTakeOutEatInTax : 0),
        eat_in_tax_manual: removeComma((eatInTaxManual) ? eatInTaxManual : 0),
        takeout_tax_manual: removeComma((takeOutTaxManual) ? takeOutTaxManual : 0),
        total_sales_manual:
          removeComma((totalTakeOutEatInTax) ? totalTakeOutEatInTax : 0) + removeComma((eatInTakeOutManual) ? eatInTakeOutManual : 0),
        total_discount_amount_manual: removeComma(
          (totalDiscountAmountManualReduced) ? totalDiscountAmountManualReduced : 0 ,
        ),
        cash_voucher_sales_total_amount_manual: removeComma((cashVoucherSalesTotalAmountManual) ? cashVoucherSalesTotalAmountManual : 0),
        guest_cnt_manual: removeComma((guestCNTManual) ? guestCNTManual : 0),
        customers_manual: removeComma((customerManual) ? customerManual : 0),
        total_payment_manual: removeComma((totalPaymentManual) ? totalPaymentManual : 0),
        total_withdrawal_manual: removeComma((totalWithDrawalManual) ? totalWithDrawalManual : 0),
        change_reserve_manual: removeComma((changeReserveManual) ? changeReserveManual : 0),
        cash_stock_manual: removeComma((cashStockManual) ? cashStockManual : 0),
        cash_voucher_payment_total_amount_manual: removeComma(
          (cashVoucherPaymentTotalAmountManual) ? cashVoucherPaymentTotalAmountManual : 0 ,
        ),
        excess_and_deficiency_manual: removeComma((excessAndDeficiencyManual) ? excessAndDeficiencyManual : 0),
        next_day_change_manual: removeComma((nextDayChangeManual) ? nextDayChangeManual : 0),
        bank_deposit_amount_manual: removeComma((bankDepositAmountManual) ? bankDepositAmountManual : 0),
        sales_total_cost_manual:
          removeComma((foodCost) ? foodCost : 0)
          + removeComma((drinkCostManual) ? drinkCostManual : 0)
          + removeComma((otherCostManual) ? otherCostManual : 0),
        food_cost_manual: removeComma((foodCost) ? foodCost : 0),
        drink_cost_manual: removeComma((drinkCostManual) ? drinkCostManual : 0),
        other_cost_manual: removeComma((otherCostManual) ? otherCostManual : 0),
        receipt_cnt_manual: removeComma((receiptCNTManual) ? receiptCNTManual : 0),
        receipt_amount_manual: removeComma((receiptAmountManual) ? receiptAmountManual : 0),
        void_cnt_manual: removeComma((voidCNTManual) ? voidCNTManual : 0),
        void_amount_manual: removeComma((voidAmountManual) ? voidAmountManual : 0),
        paymentMediaManualInputData: paymentMediaAmountManual.map((item:any)=>{
          return {
            payment_media_amount_manual: removeComma((item.payment_media_amount_manual) ? item.payment_media_amount_manual : 0),
            payment_media_mst_code: item.payment_media_mst_code
          }
        }),
        employee_salary_manual: removeComma((employeeSalaryManual) ? employeeSalaryManual : 0),
        part_salary_manual: removeComma((partSalaryManual) ? partSalaryManual : 0),
        discountTypeManualInputData: discountTypeDataPost.map((item:any)=>{
          return {
            discountCode: item.discountCode,
            discountCnt: removeComma((item.discountCnt) ? item.discountCnt : 0),
            discountAmount: removeComma((item.discountAmount) ? item.discountAmount : 0),
          }
        }),
        revenue_stamp_purchase: removeComma((purchaseNumber) ? purchaseNumber : 0),
        revenue_stamp_adjustment: removeComma((stampAdjustment) ? stampAdjustment : 0),
        revenue_stamp_use: removeComma((stampUse) ? stampUse : 0),
        revenue_stamp_remaining: removeComma((remaining) ? remaining : 0),
        purchaseCostOtherThanSystemDataFood: purchaseCostOtherThanSystemDataFood.map((item:any)=>{
          return { suppliers_code: item.suppliers_code,
            purchase_amount: (item.purchase_amount) ? Number(formatNumberMinus(String(item.purchase_amount))) : 0
          }
        }),
        purchaseCostOtherThanSystemDataDrink:purchaseCostOtherThanSystemDataDrink.map((item:any)=>{
          return { suppliers_code: item.suppliers_code,
            drink_classification: item.drink_classification,
            purchase_amount: (item.purchase_amount) ? Number(formatNumberMinus(String(item.purchase_amount))) : 0
          }
        }),
        purchaseCostOtherThanSystemDataOther: purchaseCostOtherThanSystemDataOther.map((item:any)=>{
          return { suppliers_code: item.suppliers_code,
            purchase_amount: (item.purchase_amount) ? Number(formatNumberMinus(String(item.purchase_amount))) : 0
          }
        }),
      };
      console.log(postData,'check posting')

      updateCashierReportManual(targetOrgCode, new Date(targetDay), postData)
        .then((data: any) => {
          setIsLoading(false);
          successNotification('更新しました。');
          setGeneralData(data);
          setRemainingBefor(data.cash_data?.manual_input.revenue_stamp_remaining_befor);
          if (data) {
            updateCountDayAndAdvertising(data);
          }
          // window.location.reload();
        })
        .catch((exception: any) => {
          setIsLoading(false);
          errorNotification('サーバー側でエラーが発生しました。');
        });
    };

    const handleAllSubmit = () => {
      setConfirmModalOpen(false);
      setIsLoading(true);

      if (paymentMediaAmountManual) {
        const formatPaymentMedia = [...paymentMediaAmountManual];
        formatPaymentMedia.map((info: any, index: number) => {
          info.payment_media_amount_manual = removeComma(
            info.payment_media_amount_manual,
          );
        });
        setPaymentMediaAmountManual(formatPaymentMedia);
      } else {
        setPaymentMediaAmountManual([]);
      }

      if (purchaseCostOtherThanSystemDataFood && purchaseCostOtherThanSystemDataFood.length > 0) {
        const formatPurchaseCost = [...purchaseCostOtherThanSystemDataFood];
        formatPurchaseCost.map((info: any, index: number) => {
          info.purchase_amount = removeComma(
            info.purchase_amount,
          );
        });
        setPurchaseCostOtherThanSystemDataFood(formatPurchaseCost);
      } else {
        setPurchaseCostOtherThanSystemDataFood([]);
      }

      if (purchaseCostOtherThanSystemDataDrink && purchaseCostOtherThanSystemDataDrink.length > 0) {
        const formatDrinkCost = [...purchaseCostOtherThanSystemDataDrink];
        formatDrinkCost.map((info: any, index: number) => {
          info.purchase_amount = removeComma(
            info.purchase_amount,
          );
        });
        setPurchaseCostOtherThanSystemDataDrink(formatDrinkCost);
      } else {
        setPurchaseCostOtherThanSystemDataDrink([]);
      }

      if (purchaseCostOtherThanSystemDataOther && purchaseCostOtherThanSystemDataOther.length > 0) {
        const formatOthers = [...purchaseCostOtherThanSystemDataOther];
        formatOthers.map((info: any, index: number) => {
          info.purchase_amount = removeComma(
            info.purchase_amount,
          );
        });
        setPurchaseCostOtherThanSystemDataOther(formatOthers);
      } else {
        setPurchaseCostOtherThanSystemDataOther([]);
      }

      const postData = {
        net_sales_manual: removeComma(eatInTakeOutManual),
        eat_in_manual: removeComma(eatInManual),
        takeout_manual: removeComma(takeOutManual),
        tax_manual: removeComma(totalTakeOutEatInTax),
        eat_in_tax_manual: removeComma(eatInTaxManual),
        takeout_tax_manual: removeComma(takeOutTaxManual),
        total_sales_manual:
            removeComma(totalTakeOutEatInTax) + removeComma(eatInTakeOutManual),
        total_discount_amount_manual: removeComma(
          totalDiscountAmountManualReduced,
        ),
        cash_voucher_sales_total_amount_manual: removeComma(cashVoucherSalesTotalAmountManual),
        guest_cnt_manual: removeComma(guestCNTManual),
        customers_manual: removeComma(customerManual),
        total_payment_manual: removeComma(totalPaymentManual),
        total_withdrawal_manual: removeComma(totalWithDrawalManual),
        change_reserve_manual: removeComma(changeReserveManual),
        cash_stock_manual: removeComma(cashStockManual),
        cash_voucher_payment_total_amount_manual: removeComma(
          cashVoucherPaymentTotalAmountManual,
        ),
        excess_and_deficiency_manual: removeComma(excessAndDeficiencyManual),
        next_day_change_manual: removeComma(nextDayChangeManual),
        bank_deposit_amount_manual: removeComma(bankDepositAmountManual),
        sales_total_cost_manual:
            removeComma(foodCost)
            + removeComma(drinkCostManual)
            + removeComma(otherCostManual),
        food_cost_manual: removeComma(foodCost),
        drink_cost_manual: removeComma(drinkCostManual),
        other_cost_manual: removeComma(otherCostManual),
        receipt_cnt_manual: removeComma(receiptCNTManual),
        receipt_amount_manual: removeComma(receiptAmountManual),
        void_cnt_manual: removeComma(voidCNTManual),
        void_amount_manual: removeComma(voidAmountManual),
        paymentMediaManualInputData: paymentMediaAmountManual,
        employee_salary_manual: removeComma(employeeSalaryManual),
        part_salary_manual: removeComma(partSalaryManual),
        discountTypeManualInputData: discountTypeDataPost,
        revenue_stamp_purchase: removeComma(purchaseNumber),
        revenue_stamp_adjustment: removeComma(stampAdjustment),
        revenue_stamp_use: removeComma(stampUse),
        revenue_stamp_remaining: removeComma(remaining),
        purchaseCostOtherThanSystemDataFood,
        purchaseCostOtherThanSystemDataDrink,
        purchaseCostOtherThanSystemDataOther,
      };

      try {
        updateCashierReportManual(targetOrgCode, new Date(targetDay), postData)
          .then((data: any) => {
            setIsLoading(false);
            setGeneralData(data);
            setRemainingBefor(data.cash_data?.manual_input.revenue_stamp_remaining_befor);
            if (data) {
              updateCountDayAndAdvertising(data);
            }
            try {
              updateCommentForSale(
                targetOrgCode,
                new Date(targetDay),
                budgetProgressComment,
                storeOperationComment,
                otherComment,
              )
                .then((data: any) => {
                  setIsLoading(false);
                });
            } catch (error) {
              errorNotification('サーバー側でエラーが発生しました。');
            }
          });
      } catch (error) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }

    const handleSubmit = async (idx: Number, files?: any) => {
      setIsLoading(true);
      if (!targetOrgCode) {
        setIsLoading(false);
        return;
      }

      let convertData: any = {};
      convertData = {
        orgCode: targetOrgCode,
        targetDay: moment(targetDay).format('YYYY-MM-DD'),
        idx,
        createUser: sessionStorage.getItem('loginUser.staffName') || '',
        updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      };

      if (files) {
        convertData = {
          ...convertData,
          photo: files,
        };
      }

      //
      try {
        const response = await updateImageDetailReport(convertData);
        setIsLoading(false);
        if (!response.errors) {
          const tmpAttachFileData = [...attachFileData];
          const tmpFile = {
            path: `${domainUrl}/${response.path}`,
            file_name: response.fileName,
            idx: idx,
          };
          (tmpAttachFileData as any)[idx as any] = tmpFile;
          setAttachFileData(tmpAttachFileData);

          successNotification('登録しました。');
        }
      } catch (error) {
        setIsLoading(false);
        if (
          error.response
          && error.response.data
          && error.response.data.message
        ) {
          const err = error.response.data.message.replace(/<br>/gi, '\n');
          errorNotification(err);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    };

    const handleDeleteFile = async (idx: number) => {
      setIsLoading(true);
      if (!targetOrgCode) {
        setIsLoading(false);
        return;
      }

      let convertData: any = {};
      convertData = {
        orgCode: targetOrgCode,
        targetDay: moment(targetDay).format('YYYY-MM-DD'),
        idx,
      };

      //
      try {

        const response = await deleteImageDetailReport(convertData);
        setIsLoading(false);
        if (!response.errors) {
          const tmpAttachFileData = [...attachFileData];
          const tmpFile = {
            path: '',
            file_name: '',
          };
          // (tmpAttachFileData as any)[idx as any] = tmpFile;
          console.log('tmpAttachFileData', tmpAttachFileData);
          let index = tmpAttachFileData.findIndex(item => item?.idx === idx);
          tmpAttachFileData?.splice(index, 1);

          setAttachFileData(tmpAttachFileData);
          successNotification('削除しました。');
        }
      } catch (error) {
        setIsLoading(false);
        if (
          error.response
          && error.response.data
          && error.response.data.message
        ) {
          const err = error.response.data.message.replace(/<br>/gi, '\n');
          errorNotification(err);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    };

    const changefile = (idx: number, file: any) => {
      if (!file || !file.type.match(/image\/.*|application\/pdf/)) {
        return false;
      }
      handleSubmit(idx, file);
      return true;
    };

    const deleteFile = (idx: number) => {
      if (!targetOrgCode) {
        return false;
      }
      handleDeleteFile(idx);
      return true;
    };

    let listFileAttact = useMemo(() => {

      let listData = attachFileData.map((attactFile: { path: string, file_name: string, idx: number }, index: number) => {
        if (typeof (attactFile?.path) !== "undefined" && attactFile?.path !== "") {
          return { ...attactFile }
        } else {
          return undefined;
        }
      }).filter((item: any) => typeof (item) !== "undefined");
      return listData;
    }, [attachFileData]);

    let indexNextFileAttach = useMemo(() => {
      console.log('listFileAttact', listFileAttact);
      return listFileAttact.length > 0 ? listFileAttact[listFileAttact.length - 1]?.idx + 1 : 1;
    }, [listFileAttact]);

    const renderFileAttach = useCallback(() => {
      return listFileAttact.map((attachFile: { path: string, file_name: string, idx: number }, index: number) => {
        console.log('idx', attachFile, index);
        return (
        <FormField key={index} alignItems='center'>
          <FormLabel
            label={`添付${index + 1}`}
            customStyle={css({ marginRight: '15px' })}
          />
          <>
            <div
              style={{ minWidth: '200px', paddingRight: '10px' }}
            >
              <ClickableLink
                to=""
                target="_blank"
                customCss={css({padding:0})}
                onClick={() => handleOpenFile(attachFile.path)}
              >
                {attachFile.file_name}
              </ClickableLink>
            </div>
            {roleScreen && roleScreen.importFlag === 1 && (
                <PrimaryButton
                text="ファイルを削除"
                onClick={() => deleteFile(attachFile.idx)}
                disabled={isTurnOff}
                />
              )}
          </>
        </FormField>)
      })
    }, [listFileAttact, isTurnOff]);
    useEffect(() => {
      const bank_deposit_amount_pos_bank_deposit = dailyData && dailyData?.cash_data && dailyData?.cash_data?.deposit_withdrawal
      ? (dailyData?.cash_data?.deposit_withdrawal.cash_sales || 0) +  removeComma(mstCodeSpecific) - dailyData?.cash_data?.deposit_withdrawal.total_withdrawal - totalWithDrawalManual
      : 0
      setBankDepositAmountPOS(setting && setting.calculationBankDeposit && dailyData?.cash_data?.deposit_withdrawal ? bank_deposit_amount_pos_bank_deposit : dailyData?.cash_data?.deposit_withdrawal.bank_deposit_amount )
    }, [dailyData,mstCodeSpecific,totalWithDrawalManual,dailyData?.cash_data?.deposit_withdrawal.cash_sales]);

    const isShowLaberCost = (workingForm?: number | null) => {
      // 労働形態が未設定（合計行）は表示
      if (workingForm === undefined || workingForm === null) return true;
      if (workingForm === Values.WorkingForm.FullTime.value && roleScreen?.usabilityGeneralItem3 === 1) return true;
      if (workingForm === Values.WorkingForm.PartTime.value && roleScreen?.usabilityGeneralItem4 === 1) return true;
      return false;
    }

    return (
      <div className="box-body" id="SalesDailyReport">
        <div className="row" id="layoutSale_02">
          <div className="col-md-12">
            <div id="resize-detect-container" className="tab-content">
              {/* レジレポート */}
              <div className="tab-pane" id="search_tab_2">
                <div className="col-xs-12">
                  <FlexBox customStyle={css({ alignItems: 'initial' })}>
                    <FlexBoxItem basis="49%">
                      <FlexBoxItem>
                        <div className="table-responsive">
                          <table className="table table-bordered table-striped table-condensed text-nowrap">
                            <thead>
                              <tr>
                                <th colSpan={3} className="text-center">
                                  項目
                              </th>
                                <th className="text-center">POS</th>
                                <th className="text-center">手入力</th>
                                <th className="text-center">合計</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center" rowSpan={14}>
                                  売上情報
                              </td>
                                <td className="text-center" rowSpan={3}>
                                  税抜
                              </td>
                                <td className="text-center">合計</td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.cash_register
                                        .net_sales,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  {numberFormater((eatInTakeOutManual) ? Number(eatInTakeOutManual) : 0)}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    ((eatInTakeOutManual) ? Number(eatInTakeOutManual) : 0)
                                    + Number(
                                      dailyData?.cash_data?.cash_register
                                        .net_sales,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">イートイン</td>
                                <td className="text-right ">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.cash_register.eat_in,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="eat_in"
                                    value={
                                      dailyData?.cash_data
                                        ? String(numberFormater(eatInManual))
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setEatInManual(
                                          removeComma(e.target.value),
                                        );
                                        setEatInTakeOutManual(
                                          ((removeComma(e.target.value)) ?  removeComma(e.target.value) : 0)
                                          + removeComma((takeOutManual) ? takeOutManual : 0),
                                        );
                                      }
                                    }}
                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((eatInManual) ? eatInManual : 0)
                                    + removeComma(
                                      dailyData?.cash_data?.cash_register.eat_in,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">テイクアウト</td>
                                <td className="text-right ">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.cash_register.takeout,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="takeout_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(numberFormater(takeOutManual))
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setTakeOutManual(
                                          removeComma(e.target.value),
                                        );
                                        setEatInTakeOutManual(
                                          ((removeComma(e.target.value)) ? removeComma(e.target.value) : 0)
                                          + removeComma((eatInManual) ? eatInManual : 0),
                                        );
                                      }
                                    }}

                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((takeOutManual) ? takeOutManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.cash_register.takeout,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center" rowSpan={3}>
                                  消費税
                              </td>
                                <td className="text-center">合計</td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.cash_register.tax,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((totalTakeOutEatInTax) ? totalTakeOutEatInTax : 0  ),
                                  )}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((totalTakeOutEatInTax) ? totalTakeOutEatInTax : 0  )
                                    + removeComma(
                                      dailyData?.cash_data?.cash_register.tax,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">イートイン</td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.cash_register
                                        .eat_in_tax,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="eat_in_tax_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(numberFormater(eatInTaxManual))
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setEatInTaxManual(
                                          removeComma(e.target.value),
                                        );
                                        setTotalTakeOutEatInTax(
                                          ((removeComma(e.target.value)) ? removeComma(e.target.value) : 0 )
                                          + removeComma((takeOutTaxManual) ? takeOutTaxManual : 0 ),
                                        );
                                      }
                                    }}

                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((eatInTaxManual) ? eatInTaxManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.cash_register
                                        .eat_in_tax,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">テイクアウト</td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.cash_register
                                        .takeout_tax,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="takeout_tax_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(numberFormater(takeOutTaxManual))
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setTakeOutTaxManual(
                                          removeComma(e.target.value),
                                        );
                                        setTotalTakeOutEatInTax(
                                          ((removeComma(e.target.value)) ? removeComma(e.target.value) : 0 )
                                          + removeComma((eatInTaxManual) ? eatInTaxManual : 0 ),
                                        );
                                      }
                                    }}

                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((takeOutTaxManual) ? takeOutTaxManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.cash_register
                                        .takeout_tax,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center" rowSpan={3}>
                                  税込
                              </td>
                                <td className="text-center">合計</td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.cash_register
                                        .total_sales,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((totalTakeOutEatInTax) ? totalTakeOutEatInTax : 0)
                                    + removeComma((eatInTakeOutManual) ? eatInTakeOutManual : 0),
                                  )}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((totalTakeOutEatInTax) ? totalTakeOutEatInTax : 0)
                                    + removeComma((eatInTakeOutManual) ? eatInTakeOutManual : 0)
                                    + removeComma(
                                      dailyData?.cash_data?.cash_register
                                        .total_sales,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">イートイン</td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.cash_register
                                        .eat_in_total_sales,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((eatInManual) ? eatInManual : 0)
                                    + removeComma((eatInTaxManual) ? eatInTaxManual : 0),
                                  )}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((eatInManual) ? eatInManual : 0)
                                    + removeComma((eatInTaxManual) ? eatInTaxManual : 0)
                                    + removeComma(
                                      dailyData?.cash_data?.cash_register
                                        .eat_in_total_sales,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">テイクアウト</td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.cash_register
                                        .takeout_total_sales,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((takeOutManual) ? takeOutManual : 0)
                                    + removeComma((takeOutTaxManual) ? takeOutTaxManual : 0),
                                  )}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((takeOutManual) ? takeOutManual : 0)
                                    + removeComma((takeOutTaxManual) ? takeOutTaxManual : 0)
                                    + removeComma(
                                      dailyData?.cash_data?.cash_register
                                        .takeout_total_sales,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center" colSpan={2}>
                                    金券販売
                                </td>
                                <td className="text-right">
                                  {dailyData?.cash_data? numberFormater(dailyData?.cash_data?.cash_register.cash_voucher_sales_total_amount,): ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="cashVoucherSalesTotalAmountMaunual"
                                    value={
                                      dailyData?.cash_data? String(numberFormater(cashVoucherSalesTotalAmountManual)): ''
                                    }
                                    onChange={
                                      (e) => {
                                        handleRemoveKeepCursorPosition(e)
                                        if (e.target.value == ''|| re.test(e.target.value)) {
                                          setCashVoucherSalesTotalAmountManual(removeComma(e.target.value));
                                        }
                                      }
                                    }
                                  />
                                </td>
                                <td className="text-right">
                                  {
                                    numberFormater(
                                      removeComma((cashVoucherSalesTotalAmountManual) ? cashVoucherSalesTotalAmountManual : 0 )
                                      + removeComma(dailyData?.cash_data?.cash_register.cash_voucher_sales_total_amount))
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center" colSpan={2}>
                                  値引・割引額
                              </td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater((totalDiscountAmountReduced) ? totalDiscountAmountReduced : 0)
                                    : 0}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="totalDiscountAmountManual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(
                                          numberFormater(
                                            totalDiscountAmountManualReduced,
                                          ),
                                        )
                                        : '0'
                                    }
                                    disable={
                                      !!(discountTypeData
                                        && discountTypeData.length > 0)
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setTotalDiscountAmountManualReduced(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}

                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma(
                                      (totalDiscountAmountManualReduced) ? totalDiscountAmountManualReduced : 0,
                                    ) + removeComma((totalDiscountAmountReduced) ? totalDiscountAmountReduced : 0),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center" colSpan={2}>
                                  客数
                              </td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.cash_register
                                        .guest_cnt,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="guestCNTmaunual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(numberFormater(guestCNTManual))
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setGuestCNTManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}


                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((guestCNTManual) ? guestCNTManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.cash_register
                                        .guest_cnt,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center" colSpan={2}>
                                  組数
                              </td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.cash_register
                                        .customers,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="customerManual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(numberFormater(customerManual))
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setCustomerManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}


                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((customerManual) ? customerManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.cash_register
                                        .customers,
                                    ),
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </FlexBoxItem>
                    </FlexBoxItem>
                    {/* track this */}
                    <FlexBoxItem basis="2%" />
                    <FlexBoxItem basis="49%">
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped table-condensed text-nowrap">
                          <thead>
                            <tr>
                              <th colSpan={3} className="text-center">
                                項目
                            </th>
                              <th className="text-center">POS</th>
                              <th className="text-center">手入力</th>
                              <th className="text-center">合計</th>
                            </tr>
                          </thead>

                          {cashRegisterReport
                            && cashRegisterReport?.countPaymentMedia ? (
                              <tbody>
                                {cashRegisterReport?.checkoutInformation?.map(
                                  (info: any, index: number) => (
                                    <tr key={index}>
                                      {index === 0 ? (
                                        <td
                                          className="text-center"
                                          rowSpan={
                                            cashRegisterReport?.countPaymentMedia
                                          }
                                        >
                                          精算情報
                                        </td>
                                      ) : (
                                          ''
                                        )}

                                      {!info.category_name && (
                                        <td
                                          className="text-center"
                                          colSpan={info.colspan}
                                        >
                                          {info.data.payment_media_mst_name}
                                        </td>
                                      )}

                                      {!info.category_name && (
                                        <td className="text-right">
                                          {numberFormater(
                                            info.data.payment_media_amount,
                                          )}
                                        </td>
                                      )}

                                      {!info.category_name && (
                                        <td className="text-right">
                                          <FormatInput
                                            type="text"
                                            name="payment_media_amount_manual[]"
                                            value={
                                              paymentMediaAmountManual.length > 0
                                                && typeof paymentMediaAmountManual[
                                                index
                                                ] !== 'undefined'
                                                && typeof paymentMediaAmountManual[index]
                                                  .payment_media_amount_manual
                                                !== 'undefined'
                                                ? numberFormater(
                                                  paymentMediaAmountManual[index]
                                                    .payment_media_amount_manual,
                                                )
                                                : 0
                                            }
                                            onChange={(e) => {
                                              handleRemoveKeepCursorPosition(e)
                                              if (
                                                e.target.value == ''
                                                || re.test(e.target.value)
                                              ) {
                                                updateFieldChanged(index, e);
                                              }
                                              if (
                                                info.data.payment_media_mst_code
                                                === '000'
                                              ) {
                                                setMSTCodeSpecific(
                                                  numberFormater(
                                                    removeComma(e.target.value),
                                                  ),
                                                );
                                              }
                                            }}

                                          />
                                        </td>
                                      )}

                                      {!info.category_name && (
                                        <td className="text-right">
                                          {numberFormater(
                                            removeComma(
                                              paymentMediaAmountManual.length > 0
                                                && typeof paymentMediaAmountManual[
                                                index
                                                ] !== 'undefined'
                                                && typeof paymentMediaAmountManual[
                                                  index
                                                ].payment_media_amount_manual
                                                !== 'undefined'
                                                ? (paymentMediaAmountManual[index].payment_media_amount_manual) ? paymentMediaAmountManual[index].payment_media_amount_manual : 0
                                                : 0,
                                            )
                                            + removeComma(
                                              info.data.payment_media_amount,
                                            ),
                                          )}
                                        </td>
                                      )}

                                      {info.category_name && (
                                        <td
                                          className="text-center"
                                          rowSpan={info.rowspan}
                                        >
                                          {info.category_name}
                                        </td>
                                      )}

                                      {info.category_name && (
                                        <td className="text-center ">
                                          {info.data.payment_media_mst_name}
                                        </td>
                                      )}

                                      {info.category_name && (
                                        <td className="text-right">
                                          {numberFormater(
                                            info.data.payment_media_amount,
                                          )}
                                        </td>
                                      )}

                                      {info.category_name && (
                                        <td className="text-right">
                                          <FormatInput
                                            type="text"
                                            name="payment_media_amount_manual[]"
                                            value={numberFormater(
                                              paymentMediaAmountManual[index]
                                                .payment_media_amount_manual,
                                            )}
                                            onChange={(e) => {
                                              handleRemoveKeepCursorPosition(e)
                                              if (
                                                e.target.value == ''
                                                || re.test(e.target.value)
                                              ) {
                                                updateFieldChanged(index, e);
                                              }
                                              if (
                                                info.data.payment_media_mst_code
                                                === '000'
                                              ) {
                                                setMSTCodeSpecific(
                                                  numberFormater(
                                                    removeComma(e.target.value),
                                                  ),
                                                );
                                              }
                                            }}


                                          />
                                        </td>
                                      )}

                                      {info.category_name && (
                                        <td className="text-right">
                                          {numberFormater(
                                            removeComma(
                                              paymentMediaAmountManual[index]
                                                .payment_media_amount_manual,
                                            )
                                            + removeComma(
                                              info.data.payment_media_amount,
                                            ),
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  ),
                                )}
                                <tr key={cashRegisterReport?.countPaymentMedia}>
                                <td
                                  className="text-center"
                                  colSpan={paymentMediaNameMaxColSpan}
                                  >
                                   合計
                                 </td>
                                 <td className="text-right">
                                    {numberFormater(
                                      removeComma(allPaymentMediaAmount)
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {numberFormater(
                                      removeComma(allPaymentMediaAmountManual)
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {numberFormater(
                                      removeComma(allPaymentMediaAmount) + removeComma(allPaymentMediaAmountManual)
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <></>
                            )}

                          {cashRegisterReport
                            && !cashRegisterReport?.countPaymentMedia && (
                              <tbody>
                                <tr>
                                  <td className="text-center">精算情報</td>
                                  <td className="text-left" colSpan={2}>
                                    -
                                  </td>
                                  <td className="text-center" />
                                  <td className="text-right" />
                                  <td className="text-right" />
                                </tr>
                              </tbody>
                            )}
                        </table>
                      </div>
                    </FlexBoxItem>
                  </FlexBox>

                  <FlexBoxItem basis="2%" />
                  <FlexBoxItem basis="49%">
                    <FlexBox
                      customStyle={css({
                        alignItems: 'initial',
                        marginTop: '15px',
                      })}
                    >
                      <FlexBoxItem basis="49%">
                        <div className="table-responsive">
                          <table className="table table-bordered table-striped table-condensed text-nowrap">
                            <thead>
                              <tr>
                                <th colSpan={2} className="text-center">
                                  項目
                              </th>
                                <th className="text-center">POS</th>
                                <th className="text-center">手入力</th>
                                <th className="text-center">合計</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center" rowSpan={2}>
                                  入出金
                              </td>
                                <td className="text-center">入金</td>
                                <td className="text-right ">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .total_payment,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="total_payment_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(
                                          numberFormater(totalPaymentManual),
                                        )
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setTotalPaymentManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}

                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((totalPaymentManual) ? totalPaymentManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .total_payment,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">出金</td>
                                <td className="text-right ">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .total_withdrawal,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="total_withdrawal_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(
                                          numberFormater(totalWithDrawalManual),
                                        )
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setTotalWithDrawalManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}


                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((totalWithDrawalManual) ? totalWithDrawalManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .total_withdrawal,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center" rowSpan={9}>
                                  ドロア情報
                              </td>
                                <td className="text-center">釣銭</td>
                                <td className="text-right ">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .change_reserve,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="change_reserve_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(
                                          numberFormater(changeReserveManual),
                                        )
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setChangeReserveManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}


                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((changeReserveManual) ? changeReserveManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .change_reserve,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">現金売上</td>
                                <td className="text-right ">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .cash_sales,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  {numberFormater(removeComma((mstCodeSpecific) ? mstCodeSpecific : 0 ))}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((mstCodeSpecific) ? mstCodeSpecific : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .cash_sales,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">現金残高</td>
                                <td className="text-right ">
                                  {dailyData?.cash_data
                                    ? numberFormater(dailyData?.cash_data?.deposit_withdrawal.change_reserve
                                      + dailyData?.cash_data?.deposit_withdrawal.cash_sales
                                      + dailyData?.cash_data?.deposit_withdrawal.total_payment
                                      - dailyData?.cash_data?.deposit_withdrawal.total_withdrawal)
                                    : ''}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((changeReserveManual) ? changeReserveManual : 0 )
                                    + removeComma((mstCodeSpecific) ? mstCodeSpecific : 0 )
                                    + removeComma((totalPaymentManual) ? totalPaymentManual : 0 )
                                    - removeComma((totalWithDrawalManual) ? totalWithDrawalManual : 0 ),
                                  )}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((changeReserveManual) ? changeReserveManual : 0 ) + removeComma(dailyData?.cash_data?.deposit_withdrawal.change_reserve)
                                    + removeComma((mstCodeSpecific) ? mstCodeSpecific : 0 ) + removeComma(dailyData?.cash_data?.deposit_withdrawal.cash_sales)
                                    + removeComma((totalPaymentManual) ? totalPaymentManual : 0 ) + removeComma(dailyData?.cash_data?.deposit_withdrawal.total_payment)
                                    - (removeComma((totalWithDrawalManual) ? totalWithDrawalManual : 0 ) + removeComma(dailyData?.cash_data?.deposit_withdrawal.total_withdrawal)),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">実現金残高</td>
                                <td className="text-right ">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .cash_stock,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="cash_stock_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(numberFormater(cashStockManual))
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setCashStockManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}


                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((cashStockManual) ? cashStockManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .cash_stock,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">金券</td>
                                <td className="text-right ">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .cash_voucher_payment_total_amount,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="cash_voucher_payment_total_amount_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(
                                          numberFormater(
                                            cashVoucherPaymentTotalAmountManual,
                                          ),
                                        )
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setCashVoucherPaymentTotalAmountManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}


                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma(
                                      (cashVoucherPaymentTotalAmountManual) ? cashVoucherPaymentTotalAmountManual : 0 ,
                                    )
                                    + removeComma(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .cash_voucher_payment_total_amount,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">過不足</td>
                                <td className="text-right ">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .excess_and_deficiency,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="excess_and_deficiency_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(
                                          numberFormater(
                                            excessAndDeficiencyManual,
                                          ),
                                        )
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setExcessAndDeficiencyManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}


                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma( (excessAndDeficiencyManual) ? excessAndDeficiencyManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .excess_and_deficiency,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">翌営業日釣銭</td>
                                <td className="text-right ">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .next_day_change,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="next_day_change_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(
                                          numberFormater(nextDayChangeManual),
                                        )
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setNextDayChangeManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}


                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((nextDayChangeManual) ? nextDayChangeManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .next_day_change,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">釣銭増減</td>
                                <td className="text-right ">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .next_day_change
                                      - dailyData?.cash_data?.deposit_withdrawal
                                        .change_reserve,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((nextDayChangeManual) ? nextDayChangeManual : 0 )
                                    - removeComma((changeReserveManual) ? changeReserveManual : 0 ),
                                  )}
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((nextDayChangeManual) ? nextDayChangeManual : 0 )
                                    - removeComma((changeReserveManual) ? changeReserveManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.deposit_withdrawal
                                        .next_day_change
                                      - dailyData?.cash_data?.deposit_withdrawal
                                        .change_reserve,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">銀行預入金</td>
                                <td className="text-right ">
                                  {dailyData?.cash_data
                                    ? numberFormater((bankDepositAmountPOS) ? bankDepositAmountPOS : 0 )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="bank_deposit_amount_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(
                                          numberFormater(
                                            bankDepositAmountManual,
                                          ),
                                        )
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setBankDepositAmountManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}


                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((bankDepositAmountManual) ? bankDepositAmountManual : 0 )
                                    + removeComma((bankDepositAmountPOS) ? bankDepositAmountPOS : 0 ),
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </FlexBoxItem>
                      <FlexBoxItem basis="2%" />
                      <FlexBoxItem basis="49%">
                        {/* <div
                        className="table-responsive"
                        style={{ marginBottom: '25px' }}
                      >
                        <table className="table table-bordered table-striped table-condensed text-nowrap">
                          <thead>
                            <tr>
                              <th colSpan={3} className="text-center">
                                項目
                              </th>
                              <th className="text-center">
                                {
                                    setting.includePettyCashInPurchase
                                      ? 'システム＋小口現金'
                                      : 'システム'
                                  }
                              </th>
                              <th className="text-center">手入力</th>
                              <th className="text-center">合計</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center" rowSpan={4}>仕入情報</td>
                              <td className="text-center">合計</td>
                              <td className="text-center">金額</td>
                              <td className="text-right">
                                { dailyData?.cash_data
                                  ? numberFormater(
                                    setting.includePettyCashInPurchase
                                      ? removeComma(dailyData?.cash_data?.cash_register.food_cost)
                                            + removeComma(dailyData?.cash_data?.cash_register.drink_cost)
                                            + removeComma(dailyData?.cash_data?.cash_register.other_cost)
                                            + removeComma(dailyData?.daily_data.budget.petty_cash_food)
                                            + removeComma(dailyData?.daily_data.budget.petty_cash_drink)
                                            + removeComma(dailyData?.daily_data.budget.petty_cash_expendables)
                                            + removeComma(dailyData?.daily_data.budget.petty_cash_other)
                                      : removeComma(dailyData?.cash_data?.cash_register.food_cost)
                                            + removeComma(dailyData?.cash_data?.cash_register.drink_cost)
                                            + removeComma(dailyData?.cash_data?.cash_register.other_cost),
                                  )
                                  : ''}
                              </td>
                              <td className="text-right">{ numberFormater(removeComma(foodCost) + removeComma(drinkCostManual) + removeComma(otherCostManual)) }</td>
                              <td className="text-right">
                                {
                                    numberFormater(
                                      setting.includePettyCashInPurchase
                                        ? removeComma(foodCost)
                                          + removeComma(drinkCostManual)
                                          + removeComma(otherCostManual)
                                          + removeComma(dailyData?.cash_data?.cash_register.food_cost)
                                          + removeComma(dailyData?.cash_data?.cash_register.drink_cost)
                                          + removeComma(dailyData?.cash_data?.cash_register.other_cost)
                                          + removeComma(dailyData?.daily_data.budget.petty_cash_food)
                                          + removeComma(dailyData?.daily_data.budget.petty_cash_drink)
                                          + removeComma(dailyData?.daily_data.budget.petty_cash_expendables)
                                          + removeComma(dailyData?.daily_data.budget.petty_cash_other)
                                        : removeComma(foodCost)
                                          + removeComma(drinkCostManual)
                                          + removeComma(otherCostManual)
                                          + removeComma(dailyData?.cash_data?.cash_register.food_cost)
                                          + removeComma(dailyData?.cash_data?.cash_register.drink_cost)
                                          + removeComma(dailyData?.cash_data?.cash_register.other_cost),
                                    )
                                  }
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center">フード</td>
                              <td className="text-center">金額</td>
                              <td className="text-right">
                                {
                                    dailyData?.cash_data
                                      ? setting.includePettyCashInPurchase
                                        ? numberFormater(dailyData?.cash_data?.cash_register.food_cost + dailyData?.daily_data.budget.petty_cash_food)
                                        : numberFormater(dailyData?.cash_data?.cash_register.food_cost)
                                      : ''
                                  }
                              </td>
                              <td className="text-right">
                                <FormatInput
                                  type="text"
                                  name="food_cost"
                                  value={dailyData?.cash_data ? String(numberFormater(foodCost)) : ''}
                                  onChange={(e) => {
                                    if (e.target.value == '' || re.test(e.target.value)) {
                                      setFoodCost(removeComma(e.target.value));
                                    }
                                  }}
                                />
                              </td>
                              <td className="text-right">
                                {
                                    numberFormater(
                                      removeComma(foodCost)
                                      + removeComma(
                                        setting.includePettyCashInPurchase
                                          ? dailyData?.cash_data?.cash_register.food_cost + dailyData?.daily_data.budget.petty_cash_food
                                          : dailyData?.cash_data?.cash_register.food_cost,
                                      ),
                                    )
                                  }
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center">ドリンク</td>
                              <td className="text-center">金額</td>
                              <td className="text-right">
                                {
                                    dailyData?.cash_data
                                      ? setting.includePettyCashInPurchase
                                        ? numberFormater(dailyData?.cash_data?.cash_register.drink_cost + dailyData?.daily_data.budget.petty_cash_drink)
                                        : numberFormater(dailyData?.cash_data?.cash_register.drink_cost)
                                      : ''
                                  }
                              </td>
                              <td className="text-right">
                                <FormatInput
                                  type="text"
                                  name="drink_cost"
                                  value={dailyData?.cash_data ? String(numberFormater(drinkCostManual)) : ''}
                                  onChange={(e) => {
                                    if (e.target.value == '' || re.test(e.target.value)) {
                                      setDrinkCostManual(removeComma(e.target.value));
                                    }
                                  }}
                                />
                              </td>
                              <td className="text-right">
                                {
                                    numberFormater(
                                      removeComma(drinkCostManual)
                                      + removeComma(
                                        setting.includePettyCashInPurchase
                                          ? dailyData?.cash_data?.cash_register.drink_cost + dailyData?.daily_data.budget.petty_cash_drink
                                          : dailyData?.cash_data?.cash_register.drink_cost,
                                      ),
                                    )
                                  }
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center">その他</td>
                              <td className="text-center">金額</td>
                              <td className="text-right">
                                {
                                    dailyData?.cash_data
                                      ? setting.includePettyCashInPurchase
                                        ? numberFormater(
                                          dailyData?.cash_data?.cash_register.other_cost
                                            + dailyData?.daily_data.budget.petty_cash_expendables
                                            + dailyData?.daily_data.budget.petty_cash_other,
                                        )
                                        : numberFormater(dailyData?.cash_data?.cash_register.other_cost)
                                      : ''
                                  }
                              </td>
                              <td className="text-right">
                                <FormatInput
                                  type="text"
                                  name="other_cost"
                                  value={dailyData?.cash_data ? String(numberFormater(otherCostManual)) : ''}
                                  onChange={(e) => {
                                    if (e.target.value == '' || re.test(e.target.value)) {
                                      setOtherCostManual(removeComma(e.target.value));
                                    }
                                  }}
                                />
                              </td>
                              <td className="text-right">
                                {
                                    numberFormater(
                                      removeComma(otherCostManual)
                                      + removeComma(
                                        setting.includePettyCashInPurchase
                                          ? dailyData?.cash_data?.cash_register.other_cost
                                            + dailyData?.daily_data.budget.petty_cash_expendables
                                            + dailyData?.daily_data.budget.petty_cash_other
                                          : dailyData?.cash_data?.cash_register.other_cost,
                                      ),
                                    )
                                  }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div> */}

                        <div className="table-responsive" style={{ marginBottom: '20px' }}>
                          <table className="table table-bordered table-striped table-condensed text-nowrap">
                            {/* Discount daily sale */}
                            <thead>
                              <tr>
                                <th colSpan={4} className="text-center">
                                  項目
                              </th>
                                <th className="text-center">POS</th>
                                <th className="text-center">手入力</th>
                                <th className="text-center">合計</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td
                                  className="text-center"
                                  rowSpan={
                                    discountTypeData
                                    && discountTypeData.length * 4
                                  }
                                >
                                  値引・割引情報
                              </td>
                              </tr>

                              {discountTypeData && discountTypeData.length > 0 ? (
                                discountTypeData.map(
                                  (item: any, index: number) => (
                                    <DiscountTableItem
                                      index={index}
                                      discountTitle={item.discount_type_mst_name}
                                      dailyCashRegisterEatIn={
                                        item.discount_amount
                                      }
                                      eatInManual={item.discount_amount_manual}
                                      takeOutManual={(takeOutManual) ? takeOutManual : 0 }
                                      discount_cnt={item.discount_cnt}
                                      discount_cnt_manual={
                                        item.discount_cnt_manual
                                      }
                                      updateDiscountTypeDataFieldChanged={
                                        updateDiscountTypeDataFieldChanged
                                      }
                                      updateCntDiscountTypeDataFieldChanged={
                                        updateCntDiscountTypeDataFieldChanged
                                      }
                                    />
                                  ),
                                )
                              ) : (
                                  <EmptyDiscountItem />
                                )}
                            </tbody>
                          </table>
                        </div>

                        <div className="table-responsive">
                          <table className="table table-bordered table-striped table-condensed text-nowrap">
                            <thead>
                              <tr>
                                <th colSpan={3} className="text-center">
                                  項目
                              </th>
                                <th className="text-center">POS</th>
                                <th className="text-center">手入力</th>
                                <th className="text-center">合計</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center" rowSpan={4}>
                                  アラート情報
                              </td>
                                <td className="text-center" rowSpan={2}>
                                  領収書
                              </td>
                                <td className="text-center">回数</td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.alert.receipt_cnt,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="receipt_cnt_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(numberFormater(receiptCNTManual))
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setReceiptCNTManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}


                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((receiptCNTManual) ? receiptCNTManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.alert.receipt_cnt,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">金額</td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.alert.receipt_amount,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="receipt_amount_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(
                                          numberFormater(receiptAmountManual),
                                        )
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setReceiptAmountManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}


                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((receiptAmountManual) ? receiptAmountManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.alert.receipt_amount,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center" rowSpan={2}>
                                  VOID
                              </td>
                                <td className="text-center">回数</td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.alert.void_cnt,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="void_cnt_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(numberFormater(voidCNTManual))
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setVoidCNTManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}


                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((voidCNTManual) ? voidCNTManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.alert.void_cnt,
                                    ),
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">金額</td>
                                <td className="text-right">
                                  {dailyData?.cash_data
                                    ? numberFormater(
                                      dailyData?.cash_data?.alert.void_amount,
                                    )
                                    : ''}
                                </td>
                                <td className="text-right">
                                  <FormatInput
                                    type="text"
                                    name="void_amount_manual"
                                    value={
                                      dailyData?.cash_data
                                        ? String(numberFormater(voidAmountManual))
                                        : ''
                                    }
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (
                                        e.target.value == ''
                                        || re.test(e.target.value)
                                      ) {
                                        setVoidAmountManual(
                                          removeComma(e.target.value),
                                        );
                                      }
                                    }}


                                  />
                                </td>
                                <td className="text-right">
                                  {numberFormater(
                                    removeComma((voidAmountManual) ? voidAmountManual : 0 )
                                    + removeComma(
                                      dailyData?.cash_data?.alert.void_amount,
                                    ),
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </FlexBoxItem>
                    </FlexBox>
                    <ExpenseInformation
                      dailyData={dailyData}
                      setting={setting}
                      purchaseCostOtherThanSystemDataFood={purchaseCostOtherThanSystemDataFood}
                      setPurchaseCostOtherThanSystemDataFood={setPurchaseCostOtherThanSystemDataFood}
                      purchaseCostOtherThanSystemDataDrink={purchaseCostOtherThanSystemDataDrink}
                      setPurchaseCostOtherThanSystemDataDrink={setPurchaseCostOtherThanSystemDataDrink}
                      purchaseCostOtherThanSystemDataOther={purchaseCostOtherThanSystemDataOther}
                      setPurchaseCostOtherThanSystemDataOther={setPurchaseCostOtherThanSystemDataOther}
                      foodSystem={foodSystem}
                      foodCost={foodCost}
                      setFoodCost={setFoodCost}
                      foodTotalManual={foodTotalManual}
                      setFoodTotalManual={setFoodTotalManual}
                      drinkSystem={drinkSystem}
                      drinkCostManual={drinkCostManual}
                      drinkTotalManual={drinkTotalManual}
                      setDrinkTotalManual={setDrinkTotalManual}
                      setDrinkCostManual={setDrinkCostManual}
                      otherSystem={otherSystem}
                      otherCostManual={otherCostManual}
                      otherTotalManual={otherTotalManual}
                      setOtherTotalManual={setOtherTotalManual}
                      setOtherCostManual={setOtherCostManual}
                      remainingBefor={remainingBefor}
                      setRemainingBefor={setRemainingBefor}
                      purchaseNumber={purchaseNumber}
                      setPurchaseNumber={setPurchaseNumber}
                      stampAdjustment={stampAdjustment}
                      setStampAdjustment={setStampAdjustment}
                      stampUse={stampUse}
                      setStampUse={setStampUse}
                      remaining={remaining}
                      setRemaining={setRemaining}
                      targetDay={targetDay}
                      targetOrgCode={targetOrgCode}
                    />

                    {/* 人件費 */}
                    {roleScreen && (roleScreen.usabilityGeneralItem3 === 0 || roleScreen.usabilityGeneralItem3 === 1) && (
                      <>
                        {/* 人件費  */}
                        <FlexBox customStyle={css({ marginTop: '15px' })}>
                          <FlexBoxItem basis="70%">
                            <div>
                              <Link to={{ pathname: `/dailyShift/${orgName?.replace('/', '%2F')}/${targetOrgCode}/${moment(targetDay).format('YYYY-MM-DD')}/`}} target='_blank'>
                                勤務スケジュール
                              </Link>
                            </div>
                            <div className="table-responsive">
                              <table className="table table-bordered table-striped table-condensed text-nowrap">
                                <thead>
                                  <tr>
                                    <th colSpan={2} className="text-center">
                                      項目
                                  </th>
                                    <th className="text-center">予算</th>
                                    <th className="text-center">シフト</th>
                                    <th className="text-center">実績</th>
                                    <th className="text-center">手入力</th>
                                    <th className="text-center">合計</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="text-center" rowSpan={2}>
                                      人件費
                                  </td>
                                    <td className="text-center">社員</td>
                                   {
                                     isShowLaberCost(Values.WorkingForm.FullTime.value) ? (
                                       <>
                                         <td className="text-right ">
                                            {dailyData?.cash_data
                                              ? numberFormater(
                                                dailyData?.cash_data?.labor_cost_data
                                                  .budget_cost_employee,
                                              )
                                              : ''}
                                         </td>
                                         <td className="text-right ">
                                            {dailyData?.cash_data
                                              ? numberFormater(
                                                dailyData?.cash_data?.labor_cost_data
                                                  .shift_cost_employee,
                                              )
                                              : ''}
                                         </td>
                                         <td className="text-right ">
                                            {dailyData?.cash_data
                                              ? numberFormater(
                                                dailyData?.cash_data?.labor_cost_data
                                                  .achievement_cost_employee,
                                              )
                                              : ''}
                                         </td>
                                         <td className="text-right">
                                            <FormatInput
                                              type="text"
                                              name="employee_salary_manual"
                                              value={
                                                dailyData?.cash_data
                                                  ? String(
                                                    numberFormater(employeeSalaryManual),
                                                  )
                                                  : ''
                                              }
                                              onChange={(e) => {
                                                handleRemoveKeepCursorPosition(e)
                                                if (
                                                  e.target.value == ''
                                                  || re.test(e.target.value)
                                                ) {
                                                  setEmployeeSalaryManual(
                                                    removeComma(e.target.value),
                                                  );
                                                }
                                              }}
                                            />
                                         </td>
                                         <td className="text-right">
                                            {numberFormater(
                                              removeComma((employeeSalaryManual) ? employeeSalaryManual : 0 )
                                              + removeComma(
                                                dailyData?.cash_data?.labor_cost_data
                                                  .achievement_cost_employee,
                                              ),
                                            )}
                                         </td>
                                       </>
                                      ) : (
                                        <>
                                          <td className="text-right">{Literal.MASK}</td>
                                          <td className="text-right">{Literal.MASK}</td>
                                          <td className="text-right">{Literal.MASK}</td>
                                          <td className="text-right">{Literal.MASK}</td>
                                          <td className="text-right">{Literal.MASK}</td>
                                        </>
                                      )
                                   }
                                  </tr>
                                  <tr>
                                    <td className="text-center">アルバイト</td>
                                    {
                                      isShowLaberCost(Values.WorkingForm.PartTime.value) ? (
                                        <>
                                          <td className="text-right ">
                                            {dailyData?.cash_data
                                              ? numberFormater(
                                                dailyData?.cash_data?.labor_cost_data
                                                  .budget_cost_part,
                                              )
                                              : ''}
                                          </td>
                                          <td className="text-right ">
                                            {dailyData?.cash_data
                                              ? numberFormater(
                                                dailyData?.cash_data?.labor_cost_data
                                                  .shift_cost_part,
                                              )
                                              : ''}
                                          </td>
                                          <td className="text-right ">
                                            {dailyData?.cash_data
                                              ? numberFormater(
                                                dailyData?.cash_data?.labor_cost_data
                                                  .achievement_cost_part,
                                              )
                                              : ''}
                                          </td>
                                          <td className="text-right">
                                            <FormatInput
                                              type="text"
                                              name="part_salary_manual"
                                              value={
                                                dailyData?.cash_data
                                                  ? String(numberFormater(partSalaryManual))
                                                  : ''
                                              }
                                              onChange={(e) => {
                                                handleRemoveKeepCursorPosition(e)
                                                if (
                                                  e.target.value == ''
                                                  || re.test(e.target.value)
                                                ) {
                                                  setPartSalaryManual(
                                                    removeComma(e.target.value),
                                                  );
                                                }
                                              }}
                                            />
                                          </td>
                                          <td className="text-right">
                                            {numberFormater(
                                              removeComma((partSalaryManual) ? partSalaryManual : 0 )
                                              + removeComma(
                                                dailyData?.cash_data?.labor_cost_data
                                                  .achievement_cost_part,
                                              ),
                                            )}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td className="text-right">{Literal.MASK}</td>
                                          <td className="text-right">{Literal.MASK}</td>
                                          <td className="text-right">{Literal.MASK}</td>
                                          <td className="text-right">{Literal.MASK}</td>
                                          <td className="text-right">{Literal.MASK}</td>
                                        </>
                                      )
                                    }
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </FlexBoxItem>
                        </FlexBox>
                        {/* スタッフ別人件費 */}
                        <FlexBox customStyle={css({ marginTop: '15px' })}>
                          <FlexBoxItem basis="100%">
                            <div className="table-responsive">
                              <table className="table table-bordered table-striped table-condensed text-nowrap">
                                <thead>
                                  <tr>
                                    <th rowSpan={2} className="text-center">
                                      項目
                                  </th>
                                    <th rowSpan={2} className="text-center">
                                      スタッフコード
                                  </th>
                                    <th rowSpan={2} className="text-center">
                                      氏名
                                  </th>
                                    <th colSpan={2} className="text-center">
                                      シフト
                                  </th>
                                    <th colSpan={5} className="text-center">
                                      実績
                                  </th>
                                  </tr>
                                  <tr>
                                    <th className="text-center">人件費</th>
                                    <th className="text-center">総労働時間</th>
                                    <th className="text-center">人件費</th>
                                    <th className="text-center">総労働時間</th>
                                    <th className="text-center">残業時間</th>
                                    <th className="text-center">深夜時間</th>
                                    <th className="text-center">休憩</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {dailyData?.cash_data
                                    && dailyData?.cash_data?.labor_cost_data
                                      .labor_cost_staff_data
                                    && dailyData?.cash_data?.labor_cost_data.labor_cost_staff_data.map(
                                      (item: any, index: any) => (
                                        <tr>
                                          {index === 0 ? (
                                            <td
                                              className="text-center"
                                              rowSpan={
                                                dailyData?.cash_data?.labor_cost_data
                                                  .labor_cost_staff_data.length
                                              }
                                            >
                                              スタッフ別人件費
                                            </td>
                                          ) : (
                                            ''
                                          )}
                                          {item.staff_code != null
                                            && item.staff_code !== '' ? (
                                              <td className="text-center">
                                                {item.staff_code}
                                              </td>
                                            ) : (
                                              <td colSpan={2} className="text-center">
                                                合計
                                              </td>
                                            )}
                                          {item.staff_code != null
                                            && item.staff_code !== '' ? (
                                              <td className="text-center">
                                                {item.staff_name}
                                              </td>
                                            ) : (
                                              ''
                                            )}
                                          <td className="text-right">
                                            {isShowLaberCost(item.working_form)
                                              ? item.shift_work_cost !== null && item.shift_work_cost !== null
                                                ? numberFormater(
                                                  removeComma(item.shift_work_cost)
                                                  + removeComma(item.shift_transportation_cost),
                                                )
                                                : '0'
                                              : Literal.MASK}
                                          </td>
                                          <td className="text-right">
                                            {item.shift_total_work_time_disp}
                                          </td>
                                          <td className="text-right">
                                            {isShowLaberCost(item.working_form)
                                              ? item.shift_work_cost !== null && item.shift_work_cost !== null
                                                ? numberFormater(
                                                  removeComma(item.achievement_work_cost,)
                                                  + removeComma(item.achievement_transportation_cost),
                                                )
                                                : '0'
                                              : Literal.MASK}
                                          </td>
                                          <td className="text-right">
                                            {item.total_work_time_disp}
                                          </td>
                                          <td className="text-right">
                                            {item.overtime_disp}
                                          </td>
                                          <td className="text-right">
                                            {item.midnight_time_disp}
                                          </td>
                                          <td className="text-right">
                                            {item.recess_time_disp}
                                          </td>
                                        </tr>
                                      ),
                                    )}

                                  {dailyData?.cash_data
                                    && !dailyData?.cash_data?.labor_cost_data
                                      .labor_cost_staff_data.length && (
                                      <tr>
                                        <td className="text-center">
                                          スタッフ別人件費
                                        </td>
                                        <td className="text-left">-</td>
                                        <td className="text-left">-</td>
                                        <td className="text-right" />
                                        <td className="text-right" />
                                        <td className="text-right" />
                                        <td className="text-right" />
                                        <td className="text-right" />
                                        <td className="text-right" />
                                        <td className="text-right" />
                                      </tr>
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </FlexBoxItem>
                        </FlexBox>
                      </>
                    )}

                    {
                      roleScreen && roleScreen.editable === 1 && (
                      <FormSubmitArea>
                        <Button
                          text="更新"
                          disabled={isTurnOff}
                          onClick={() => {
                            setButtonType(0);
                            setConfirmModalOpen(true);
                          }}
                        />
                      </FormSubmitArea>
                    )}
                  </FlexBoxItem>

                  <div
                    className="panel panel-default"
                    style={{ marginTop: '20px' }}
                  >
                    <div className="panel-heading">
                      <FormLabel label="コメント" />
                    </div>
                    <div className="panel-body">
                      <div className="col-md-12">
                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea
                              className="form-control input-sm"
                              rows={5}
                              name="budget_progress_comment"
                              id="budgetProgressComment"
                              placeholder={setting.commentSubjectForBudgetProcess || "予算進捗について" } 
                              // defaultValue={budgetProgressComment}
                              value={budgetProgressComment}
                              onChange={(event) => setBudgetProgressComment(event.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <textarea
                              className="form-control input-sm"
                              rows={5}
                              name="store_operation_comment"
                              id="storeOperationComment"
                              placeholder={setting.commentSubjectForStoreManagement || "店舗運営について" } 
                              // defaultValue={storeOperationComment}
                              value={storeOperationComment}
                              onChange={(event) => setStoreOperationComment(event.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <textarea
                              className="form-control input-sm"
                              rows={5}
                              name="other_comment"
                              id="otherComment"
                              placeholder={setting.commentSubjectForOthers || "その他" }
                              // defaultValue={otherComment}
                              value={otherComment}
                              onChange={(event) => setOtherComment(event.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      {
                        roleScreen && roleScreen.usabilityGeneralItem2 === 1 && (
                        <div className="form-group col-xs-12 col-sm-12">
                          <div className="text-center">
                            <FormSubmitArea>
                              <Button
                                text="コメントの更新 "
                                onClick={() => {
                                  setButtonType(1);
                                  setConfirmModalOpen(true);
                                }}
                                disabled={isTurnOff}
                              />
                            </FormSubmitArea>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className="panel panel-default"
                    style={{ marginTop: '20px' }}
                  >
                    {isDetailApplicationDisplay && (
                      <div className="panel panel-default table-responsive" style={{ marginTop: "20px", marginBottom: "20px" }}>
                      <table className="table table-bordered table-striped table-condensed text-nowrap">
                        <thead>
                          <tr className="width_row_contents">
                            {["申請者","申請日時","更新者","更新時間","承認者","更新時間"].map((column, i) => {
                                return (
                                  <th key={i} className="text-center" style={{width: "auto"}}>
                                    {column}
                                  </th>
                                );
                              })}
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td style={{ textAlign: 'center' }}>{detailApplicationData && detailApplicationData.applicant !== null ? detailApplicationData.applicant : ''}</td>
                              <td style={{ textAlign: 'center' }}>{detailApplicationData && detailApplicationData.application_time !== null ? formatDate(detailApplicationData.application_time) : ''}</td>
                              <td style={{ textAlign: 'center' }}>{detailApplicationData && detailApplicationData.changer !== null ? detailApplicationData.changer : ''}</td>
                              <td style={{ textAlign: 'center' }}>{detailApplicationData && detailApplicationData.update_time !== null ? formatDate(detailApplicationData.update_time) : '' }</td>
                              <td style={{ textAlign: 'center' }}>{detailApplicationData && detailApplicationData.authorizer !== null ? detailApplicationData.authorizer : ''}</td>
                              <td style={{ textAlign: 'center' }}>{detailApplicationData && detailApplicationData.approval_time !== null ? formatDate(detailApplicationData.approval_time) : '' }</td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                    )}
                  </div>

                  <div
                    className="panel panel-default"
                    style={{ marginTop: '20px' }}
                  >
                    {/* TESTFILE */}
                    <div className="panel-heading">
                      <FormLabel
                        label="添付ファイル"
                        customStyle={css({ marginBottom: '15px' })}
                      />
                      {renderFileAttach()}
                      {
                        roleScreen && roleScreen.importFlag === 1 && (
                        <FormField>
                          {/* <FormLabel
                            label={`添付${listFileAttact.length +1}`}
                            customStyle={css({ marginRight: '15px' })}
                          /> */}
                          <FileSelectButton
                            text="ファイルを選択"
                            setFile={(file) => changefile(indexNextFileAttach, file)}
                            value=""
                            ghost={true}
                            accept=".jpg,.png,image/jpeg,image/png,.pdf"
                            fullWidth={false}
                            disabled={isTurnOff}
                          />
                        </FormField>
                      )}

                      {/* <FormField>
                        <FormLabel
                          label="添付１"
                          customStyle={css({ marginRight: '15px' })}
                        />
                        {attachFileData
                          && attachFileData[0]
                          && attachFileData[0].path ? (
                            <>
                              <div
                                style={{ minWidth: '200px', paddingRight: '10px' }}
                              >
                                <ClickableLink
                                  to=""
                                  target="_blank"
                                  onClick={() => handleOpenFile(attachFileData[0].path)}
                                >
                                  {attachFileData[0].file_name}
                                </ClickableLink>
                              </div>
                              <PrimaryButton
                                text="ファイルを削除"
                                onClick={() => deleteFile(0)}
                                disabled={!canAttach()}
                              />
                            </>
                          ) : (
                            <FileSelectButton
                              text="ファイルを選択"
                              setFile={(file) => changefile(0, file)}
                              value=""
                              ghost={true}
                              accept=".jpg,.png,image/jpeg,image/png,.pdf"
                              fullWidth={false}
                              disabled={!canAttach()}
                            />
                          )}
                      </FormField> */}
                      {/* <FormField>
                      <FormLabel
                        label="添付２"
                        customStyle={css({ marginRight: '15px' })}
                      />
                      {attachFileData
                          && attachFileData[1]
                          && attachFileData[1].path ? (
                            <>
                            <div
                                style={{ minWidth: '200px', paddingRight: '10px' }}
                              >
                                <ClickableLink
                                to=""
                                target="_blank"
                                onClick={() => handleOpenFile(attachFileData[1].path)}
                              >
                                {attachFileData[1].file_name}
                              </ClickableLink>
                              </div>
                            <PrimaryButton
                                text="ファイルを削除"
                                onClick={() => deleteFile(1)}
                                disabled={!canAttach()}
                              />
                          </>
                        ) : (
                          <FileSelectButton
                            text="ファイルを選択"
                            setFile={(file) => changefile(1, file)}
                            value=""
                            ghost={true}
                            accept=".jpg,.png,image/jpeg,image/png,.pdf"
                            fullWidth={false}
                            disabled={!canAttach()}
                          />
                        )}
                    </FormField> */}
                      {/* <FormField>
                        <FormLabel
                          label="添付３"
                          customStyle={css({ marginRight: '15px' })}
                        />
                        {attachFileData
                          && attachFileData[2]
                          && attachFileData[2].path ? (
                            <>
                              <div
                                style={{ minWidth: '200px', paddingRight: '10px' }}
                              >
                                <ClickableLink
                                  to=""
                                  target="_blank"
                                  onClick={() => handleOpenFile(attachFileData[2].path)}
                                >
                                  {attachFileData[2].file_name}
                                </ClickableLink>
                              </div>
                              <PrimaryButton
                                text="ファイルを削除"
                                onClick={() => deleteFile(2)}
                                disabled={!canAttach()}
                              />
                            </>
                          ) : (
                            <FileSelectButton
                              text="ファイルを選択"
                              setFile={(file) => changefile(2, file)}
                              value=""
                              ghost={true}
                              accept=".jpg,.png,image/jpeg,image/png,.pdf"
                              fullWidth={false}
                              disabled={!canAttach()}
                            />
                          )}
                      </FormField> */}
                    </div>
                  </div>

                  <div
                    className="panel panel-default"
                    style={{ marginTop: '20px' }}
                  >
                    {isTableVisibility && (
                      <ReportInstruction
                        canInstructUpdate={canInstructUpdate()}
                        canInstructAdd={canInstructAdd()}
                        targetDay={targetDay}
                        targetOrgCode={targetOrgCode}
                        roleScreen={roleScreen}
                      />
                    )}
                  </div>
                  <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
                  }}
                  >
                    <div style={{ marginTop: '-16px' }}>
                      <FormSubmitArea>
                        <Link to="/salesMonthlyReport">
                          <Button
                            text="戻る"
                            ghost={true}
                          />
                        </Link>
                      </FormSubmitArea>
                    </div>
                    <div
                      style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end',
                      }}
                      className="text-right"
                    >
                      { roleScreen && roleScreen.editable === 1 && <RequestButton /> }
                      { roleScreen && roleScreen.usabilityGeneralItem1 === 1 && <ApproveButton /> }
                      { roleScreen && roleScreen.usabilityGeneralItem1 === 1 && <RejectButton /> }
                      { roleScreen && roleScreen.usabilityGeneralItem1 === 1 && <WithdrawButton /> }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: openModal ? '' : 'none' }}>
          {imageURL !== null && (
            <div style={{ textAlign: 'center' }}>
              {imageOPen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => setimageOpen(false)}
                  onMovePrevRequest={() => setphotoIndex(
                    (photoIndex + images.length - 1) % images.length,
                  )}
                  onMoveNextRequest={() => setphotoIndex((photoIndex + 1) % images.length)}
                  imageCaption={Arraydescript[photoIndex]}
                  imageTitle="添付画像"
                  animationDuration={500}
                />
              )}
            </div>
          )}
        </div>
      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        title="確認メッセージ"
        content="更新します。よろしいですか？"
        submitText="更新"
        onSubmit={() => {
          buttonType === 1
            ? handleUpdateCommentForSale()
            : handleUpdateCashierReportManual();
        }}
        items={[]}
      />

      <ApproveButtonConfirm
          handleAllSubmit={handleAllSubmit}
          isDisabled={(status === 1 || status === null) && roleScreen && roleScreen.editable === 1 ? true : false}
      />
    </div>
  );
};
export default CashRegisterReport;
