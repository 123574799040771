/* eslint-disable max-len */
/** @jsx jsx */
import React, { useState, useEffect, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import ThreeColumnLayout from 'components/atoms/layout/ThreeColumnLayout';
import { backgroundColor, iconColor } from 'components/styles';
import { Row, Col } from 'react-grid-system';
import { OptionType } from 'components/atoms/Select';
import { PrimaryButton } from 'components/atoms/Button';
import { Link, useHistory } from 'react-router-dom';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import useToastNotification from 'hooks/useToastNotification';
import ApiClient from 'api/ApiClient';
import FormatInput from '../FormatInput';
import moment from 'moment';
import {handleRemoveKeepCursorPosition} from './CashRegisterReport'
import {
  FoodNotClinkToSystem,
  DrinkNotClinkToSystem,
  OtherNotClinkToSystem,
  TableColumnsType,
} from 'domain/salesReport';
import Icon from 'components/atoms/Icon';


const styles = {
  contaner: css({
    marginTop: '25px',
    border: '1px solid #222d32',
  }),
  wrapperTitle: css({
    backgroundColor: backgroundColor.whiteSmoke,
    padding: '6px 0px',
    width: '100%',
  }),
  wrapper: css({
    borderTop: '1px solid #222d32',
  }),
  titleColumn: css({
    backgroundColor: backgroundColor.whiteSmoke,
    borderBottom: '1px solid #222d32',
    padding: '5px',
    textAlign: 'center',
  }),
  titleBody: css({
    padding: '30px',
  }),
};

const table = css`
      table tbody tr td{
        text-align: right;
        padding: 0 5px;
      }
      table thead tr{
        background-color: #EFEFEF;
      }
      table tbody tr td:first-child{
        text-align: right;
      }
      table tbody tr td span{
        margin-right: 5px;
      }
      table tfoot tr td{
        padding:0 5px;
      }

      table tfoot tr td:nth-child(1){
        background-color: #EFEFEF;
        text-align: center;
      }
      table tfoot tr td:last-child{
        text-align: right;
      }
      table tfoot tr td:nth-child(2) span{
        text-align: right;
      }
`;

const table2 = css`
      padding-top: 30px;
      table tbody tr td{
        text-align: left;
        padding: 0 5px;
      }
      table thead tr th{
        background-color: #EFEFEF;
      }
      table tbody tr td:last-child{
        text-align: right;
      }
      table tbody tr td span{
        margin-right: 5px;
      }
      table th:nth-child(1) {
      }
      table th:last-child {
        padding-right: 5px;
      }
`;

const table3 = css`
      table tfoot tr td{
        text-align: right;
        padding: 0 5px;
      },
      table tfoot tr td span{
        margin-right: 5px;
      },
`;

const table4 = css`
      table tbody tr td:first-child{
        text-align: center;
      },
      table tbody tr td:first-child{
        text-align: center;
      },
      table tbody th{
        text-align: center;
      },
      table tbody tr td span{
        margin-right: 5px;
      }
      table tbody tr td:last-child{
        text-align: right;
        padding-right: 5px;
      },
`;


const ExpenseInformation: React.FC<{
  dailyData: any,
  setting: any;
  purchaseCostOtherThanSystemDataFood: any,
  setPurchaseCostOtherThanSystemDataFood: any,
  purchaseCostOtherThanSystemDataDrink: any,
  setPurchaseCostOtherThanSystemDataDrink: any,
  purchaseCostOtherThanSystemDataOther: any,
  setPurchaseCostOtherThanSystemDataOther: any,
  foodSystem: any,
  foodCost: any,
  setFoodCost: any,
  foodTotalManual: any,
  setFoodTotalManual: any,
  drinkCostManual: any,
  setDrinkCostManual: any,
  drinkSystem: any,
  drinkTotalManual: any,
  setDrinkTotalManual: any,
  otherSystem: any,
  otherCostManual: any,
  otherTotalManual: any,
  setOtherTotalManual: any,
  setOtherCostManual: any,
  remainingBefor: any,
  setRemainingBefor: any,
  purchaseNumber: any,
  setPurchaseNumber: any,
  stampAdjustment: any,
  setStampAdjustment: any,
  stampUse: any,
  setStampUse: any,
  setRemaining: any,
  remaining: any,
  targetDay: any,
  targetOrgCode: any,
}> = ({
  dailyData,
  setting,
  purchaseCostOtherThanSystemDataFood,
  setPurchaseCostOtherThanSystemDataFood,
  purchaseCostOtherThanSystemDataDrink,
  setPurchaseCostOtherThanSystemDataDrink,
  purchaseCostOtherThanSystemDataOther,
  setPurchaseCostOtherThanSystemDataOther,
  foodSystem,
  foodCost,
  setFoodCost,
  foodTotalManual,
  setFoodTotalManual,
  drinkCostManual,
  setDrinkCostManual,
  drinkSystem,
  drinkTotalManual,
  setDrinkTotalManual,
  otherSystem,
  otherCostManual,
  otherTotalManual,
  setOtherTotalManual,
  setOtherCostManual,
  remainingBefor,
  setRemainingBefor,
  purchaseNumber,
  setPurchaseNumber,
  stampAdjustment,
  setStampAdjustment,
  stampUse,
  setStampUse,
  remaining,
  setRemaining,
  targetDay,
  targetOrgCode,
}) => {
  const { errorNotification } = useToastNotification();
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const re = /^[0-9\,\-\b]+$/;
  const removeComma = (number: any) => {
    const tmp = String(number).replace(/[\,]/g, '');
    if (!isNaN(Number(tmp))) {
      return Number(tmp);
    }
    return number === '-' ? number : 0;
  };
  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num)) {
      return num;
    }
    const tmp = String(num).split('.');
    if (tmp[1]) {
      let fixed = 3;
      if (tmp[1].length < 3) {
        fixed = tmp[1].length;
      }
      return parseFloat(num).toFixed(fixed);
    }
    return formatter.format(num);
  };
  const [initStateFood, setInitStateFood] = useState();
  const [initStateDrink, setInitStateDrink] = useState();
  const [initStateOther, setInitStateOther] = useState();
  const [foodNotClinkToSystem, setFoodNotClinkToSystem] = useState<FoodNotClinkToSystem[]>([]);
  const [drinkNotClinkToSystem, setDrinkNotClinkToSystem] = useState<DrinkNotClinkToSystem[]>([]);
  const [otherNotClinkToSystem, setOtherNotClinkToSystem] = useState<OtherNotClinkToSystem[]>([]);

  /* ---------------------------------- food --------------------------------- */
  const [foodSuppliers, setFoodSuppliers] = useState<OptionType[]>([dailyData?.cash_data?.purchase_cost_other_than_system_food]);
  const [foodAmount, setFoodAmount] = useState<any>(dailyData?.cash_data?.purchase_cost_other_than_system_food.map((item: any) => item.purchase_amount) || []);
  const [totalFoodAmount, setTotalFoodAmount] = useState(dailyData?.cash_data?.purchase_cost_other_than_system_food && dailyData?.cash_data?.purchase_cost_other_than_system_food.length > 0
    ? dailyData?.cash_data?.purchase_cost_other_than_system_food.reduce(
      (accumulator: any, curentvalue: any) => accumulator + Number(curentvalue.purchase_amount),
      0,
    ) : 0);

  /* ---------------------------------- drink --------------------------------- */
  const [drinkSuppliers, setDrinkSuppliers] = useState<OptionType[]>([]);
  const drinkClassification = [{ label: 'ドリンク', value: '0' }, { label: '酒類', value: '1' }];
  const [drinkAmount, setDrinkAmount] = useState<any>(dailyData?.cash_data?.purchase_cost_other_than_system_drink.map((item: any) => item.purchase_amount) || []);
  const [totalDrinkAmount, setTotalDrinkAmount] = useState(dailyData?.cash_data?.purchase_cost_other_than_system_drink && dailyData?.cash_data?.purchase_cost_other_than_system_drink.length > 0
    ? dailyData?.cash_data?.purchase_cost_other_than_system_drink.reduce(
      (accumulator: any, curentvalue: any) => accumulator + Number(curentvalue.purchase_amount),
      0,
    ) : 0);

  /* --------------------------------- orther --------------------------------- */
  const [otherSuppliers, setOtherSuppliers] = useState<OptionType[]>([{ label: 'others 1', value: '0' }, { label: 'others 2', value: '1' }]);

  const [otherAmount, setOtherAmount] = useState<any>(dailyData?.cash_data?.purchase_cost_other_than_system_other.map((item: any) => item.purchase_amount) || []);
  const [totalOthersAmount, setTotalOthersAmount] = useState(dailyData?.cash_data?.purchase_cost_other_than_system_other && dailyData?.cash_data?.purchase_cost_other_than_system_other.length > 0
    ? dailyData?.cash_data?.purchase_cost_other_than_system_other.reduce(
      (accumulator: any, curentvalue: any) => accumulator + Number(curentvalue.purchase_amount),
      0,
    ) : 0);


  /* ----------------------------------Table 小口現金 ---------------------------------- */
  const [foodSumAmountSmallCase, setFoodSumAmountSmallCase] = useState<number>(dailyData?.cash_data?.petty_cash_food && dailyData?.cash_data?.petty_cash_food.length > 0
    ? dailyData?.cash_data?.petty_cash_food.reduce(
      (accumulator: any, curentvalue: any) => accumulator + Number(curentvalue.amount),
      0,
    ) : 0);

  const [drinkSumAmountSmallCase, setDrinkSumAmountSmallCase] = useState<number>(dailyData?.cash_data?.petty_cash_drink && dailyData?.cash_data?.petty_cash_drink.length > 0
    ? dailyData?.cash_data?.petty_cash_drink.reduce(
      (accumulator: any, curentvalue: any) => accumulator + Number(curentvalue.amount),
      0,
    ) : 0);

  const [otherSumAmountSmallCase, setOtherSumAmountSmallCase] = useState<number>(dailyData?.cash_data?.petty_cash_other && dailyData?.cash_data?.petty_cash_other.length > 0
    ? dailyData?.cash_data?.petty_cash_other.reduce(
      (accumulator: any, curentvalue: any) => accumulator + Number(curentvalue.amount),
      0,
    ) : 0);
  const [pettyCashSum,setPettyCashSum] = useState<number>(foodSumAmountSmallCase + drinkSumAmountSmallCase + otherSumAmountSmallCase)

  /* ----------------------------------- End table 小口現金 ---------------------------------- */
  const [stateDelete, setstateDelete] = useState(0);
  const [typeDelete, setTypeDelete] = useState('');
  let flag = 0;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);


  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((item: number) => {
    setConfirmModalOpen(true);
    setstateDelete(item);
  }, []);

  const history = useHistory();

  useEffect(() => {
    const petty_cash_food = dailyData?.cash_data?.petty_cash_food && dailyData?.cash_data?.petty_cash_food.length > 0
    ? dailyData?.cash_data?.petty_cash_food.reduce(
      (accumulator: any, curentvalue: any) => accumulator + Number(curentvalue.amount),
      0,
    ) : 0
    const petty_cash_drink = dailyData?.cash_data?.petty_cash_drink && dailyData?.cash_data?.petty_cash_drink.length > 0
    ? dailyData?.cash_data?.petty_cash_drink.reduce(
      (accumulator: any, curentvalue: any) => accumulator + Number(curentvalue.amount),
      0,
    ) : 0
    const petty_cash_other = dailyData?.cash_data?.petty_cash_other && dailyData?.cash_data?.petty_cash_other.length > 0
    ? dailyData?.cash_data?.petty_cash_other.reduce(
      (accumulator: any, curentvalue: any) => accumulator + Number(curentvalue.amount),
      0,
    ) : 0
    setFoodSumAmountSmallCase(petty_cash_food);
    setDrinkSumAmountSmallCase(petty_cash_drink);
    setOtherSumAmountSmallCase(petty_cash_other);
    setPettyCashSum(petty_cash_food + petty_cash_drink+petty_cash_other)
    setFoodAmount(dailyData?.cash_data?.purchase_cost_other_than_system_food.map((item: any) => item.purchase_amount) || []);
    setDrinkAmount(dailyData?.cash_data?.purchase_cost_other_than_system_drink.map((item: any) => item.purchase_amount) || []);
    setOtherAmount(dailyData?.cash_data?.purchase_cost_other_than_system_other.map((item: any) => item.purchase_amount) || []);

    setTotalFoodAmount(dailyData?.cash_data?.purchase_cost_other_than_system_food && dailyData?.cash_data?.purchase_cost_other_than_system_food.length > 0
      ? dailyData?.cash_data?.purchase_cost_other_than_system_food.reduce(
        (accumulator: any, curentvalue: any) => accumulator + Number(curentvalue.purchase_amount),
        0,
      ) : 0);

    setTotalDrinkAmount(dailyData?.cash_data?.purchase_cost_other_than_system_drink && dailyData?.cash_data?.purchase_cost_other_than_system_drink.length > 0
      ? dailyData?.cash_data?.purchase_cost_other_than_system_drink.reduce(
        (accumulator: any, curentvalue: any) => accumulator + Number(curentvalue.purchase_amount),
        0,
      ) : 0);

    setTotalOthersAmount(dailyData?.cash_data?.purchase_cost_other_than_system_other && dailyData?.cash_data?.purchase_cost_other_than_system_other.length > 0
      ? dailyData?.cash_data?.purchase_cost_other_than_system_other.reduce(
        (accumulator: any, curentvalue: any) => accumulator + Number(curentvalue.purchase_amount),
        0,
      ) : 0);
  }, [dailyData]);

  // ドリンク - food
  const tableColumnsFood: TableColumnsType[] = [
    {
      name: '取引先',
      visible: true,
      width: '160px',
      content: (rowIndex: number, colIndex: number) => (
        <td key={colIndex} style={{ width: '160px' }}>
          <select
            style={{ padding: '5px', height: '30px', width: '100%' }}
            key={colIndex}
            onChange={(e) => {
              const option = e.target;
              const newNotClinkToSystemt = [...purchaseCostOtherThanSystemDataFood];
              newNotClinkToSystemt[rowIndex] = {
                ...newNotClinkToSystemt[rowIndex],
                suppliers_code: option.value,
              };
              setFoodNotClinkToSystem(newNotClinkToSystemt);
              setPurchaseCostOtherThanSystemDataFood(newNotClinkToSystemt);
            }}
            value={purchaseCostOtherThanSystemDataFood[rowIndex].suppliers_code}
          >
            {foodSuppliers.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </td>
      ),
    },
    {
      name: '金額',
      visible: true,
      content: (rowIndex: number, colIndex: number) => (
        <td className="text-right" key={colIndex}>
          <span>
            <FormatInput
              type="tel"
              name="foodManualInput"
              value={String(numberFormater(removeComma(purchaseCostOtherThanSystemDataFood[rowIndex].purchase_amount)))}
              onChange={(e) => {
                handleRemoveKeepCursorPosition(e)
                if (e.target.value == '' || re.test(e.target.value)) {
                  const option = e.target;
                  const newFoodAmount = [...foodAmount];
                  const newNotClinkToSystemt = [...purchaseCostOtherThanSystemDataFood];
                  newNotClinkToSystemt[rowIndex] = {
                    ...newNotClinkToSystemt[rowIndex],
                    purchase_amount: option.value,
                  };
                  newFoodAmount[rowIndex] = numberFormater(removeComma((option.value)));
                  setTotalFoodAmount(newFoodAmount.reduce((prev, foodAmountValue) => {
                    const prevFormat = prev !== undefined ? Number(String(prev).replace(/,/g, '')) : 0;
                    const foodAmountValueFormat = foodAmountValue !== undefined ? Number(String(foodAmountValue).replace(/,/g, '')) : 0;
                    return (Number(!isNaN(prevFormat) ? prevFormat : 0) + Number(!isNaN(foodAmountValueFormat) ? foodAmountValueFormat : 0 ));
                  },0));
                  setFoodNotClinkToSystem(newNotClinkToSystemt);
                  setPurchaseCostOtherThanSystemDataFood(newNotClinkToSystemt);
                  setFoodAmount(newFoodAmount);
                }
              }}
            />
          </span>
        </td>
      ),
    },
    {
      name: '削除',
      visible: true,
      content: (rowIndex: number, colIndex: number) => (
        <td key={colIndex} className="text-center" style={{ width: '10px' }}>
          <Link
            to="#"
            onClick={() => {
              setTypeDelete('food');
              openConfirmModal(rowIndex);
            }}
          >
            <span
              style={{ marginLeft: '7px', marginRight: '7px' }}
            >
              <Icon type="delete" color={iconColor.gray} hoverColor={iconColor.grayhover} />
            </span>
          </Link>
        </td>

      )
      ,
    },
  ];

  // ドリンク - drink
  const tableColumnsDrink: TableColumnsType[] = [
    {
      name: '取引先',
      visible: true,
      width: '150px',
      content: (rowIndex: number, colIndex: number) => (
        <td key={colIndex}>
          <select
            style={{ padding: '5px', height: '30px', width: '100%' }}
            key={colIndex}
            onChange={(e) => {
              const option = e.target;
              const newDrinkNotClinkToSystem = [...purchaseCostOtherThanSystemDataDrink];
              newDrinkNotClinkToSystem[rowIndex] = {
                ...newDrinkNotClinkToSystem[rowIndex],
                suppliers_code: option.value,
              };
              setDrinkNotClinkToSystem(newDrinkNotClinkToSystem);
              setPurchaseCostOtherThanSystemDataDrink(newDrinkNotClinkToSystem);
            }}
            value={purchaseCostOtherThanSystemDataDrink[rowIndex].suppliers_code}
          >
            {drinkSuppliers.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </td>
      ),
    },
    {
      name: '分類',
      visible: true,
      content: (rowIndex: number, colIndex: number) => (
        <td key={colIndex} style={{ width: '87px' }}>
          <select
            style={{ padding: '5px', height: '30px', width: '100%' }}
            key={colIndex}
            onChange={(e) => {
              const option = e.target;
              const newDrinkNotClinkToSystem = [...purchaseCostOtherThanSystemDataDrink];
              newDrinkNotClinkToSystem[rowIndex] = {
                ...newDrinkNotClinkToSystem[rowIndex],
                drink_classification: option.value,
              };
              setDrinkNotClinkToSystem(newDrinkNotClinkToSystem);
              setPurchaseCostOtherThanSystemDataDrink(newDrinkNotClinkToSystem);
            }}
            value={purchaseCostOtherThanSystemDataDrink[rowIndex].drink_classification}
          >
            {drinkClassification.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </td>
      ),
    },
    {
      name: '金額',
      visible: true,
      content: (rowIndex: number, colIndex: number) => (
        <td className="text-right" key={colIndex}>
          <span>
            <FormatInput
              type="tel"
              name="drinkAmount"
              value={String(numberFormater(removeComma(purchaseCostOtherThanSystemDataDrink[rowIndex].purchase_amount)))}
              onChange={(e) => {
                handleRemoveKeepCursorPosition(e)
                if (e.target.value == '' || re.test(e.target.value)) {
                  const option = e.target;
                  const newDrinkAmount = [...drinkAmount];
                  const newDrinkNotClinkToSystemt = [...purchaseCostOtherThanSystemDataDrink];
                  newDrinkNotClinkToSystemt[rowIndex] = {
                    ...newDrinkNotClinkToSystemt[rowIndex],
                    purchase_amount: option.value,
                  };
                  newDrinkAmount[rowIndex] = numberFormater(removeComma((option.value)));
                  setTotalDrinkAmount(newDrinkAmount.reduce((prev, drinkAmountValue) => {
                    const prevFormat = prev !== undefined ? Number(String(prev).replace(/,/g, '')) : 0;
                    const drinkAmountValueFormat = drinkAmountValue !== undefined ? Number(String(drinkAmountValue).replace(/,/g, '')) : 0;
                    return (Number(!isNaN(prevFormat) ? prevFormat : 0) + Number(!isNaN(drinkAmountValueFormat) ? drinkAmountValueFormat : 0 ));
                  },0));
                  setDrinkNotClinkToSystem(newDrinkNotClinkToSystemt);
                  setPurchaseCostOtherThanSystemDataDrink(newDrinkNotClinkToSystemt);
                  setDrinkAmount(newDrinkAmount);
                }
              }}

            />
          </span>
        </td>
      ),
    },
    {
      name: '削除',
      visible: true,
      content: (rowIndex: number, colIndex: number) => (
        <td key={colIndex} className="text-center" style={{ width: '10px' }}>
          <Link
            to="#"
            onClick={() => {
              setTypeDelete('drink');
              openConfirmModal(rowIndex);
            }}
          >
            <span
              style={{ marginLeft: '7px', marginRight: '7px' }}
            >
              <Icon type="delete" color={iconColor.gray} hoverColor={iconColor.grayhover} />
            </span>
          </Link>
        </td>

      )
      ,
    },
  ];

  // ドリンク - others
  const tableColumnsOthers: TableColumnsType[] = [
    {
      name: '取引先',
      visible: true,
      width: '160px',
      content: (rowIndex: number, colIndex: number) => (
        <td key={colIndex}>
          <select
            style={{ padding: '5px', height: '30px', width: '100%' }}
            key={colIndex}
            onChange={(e) => {
              const option = e.target;
              const newOtherNotClinkToSystem = [...purchaseCostOtherThanSystemDataOther];
              newOtherNotClinkToSystem[rowIndex] = {
                ...newOtherNotClinkToSystem[rowIndex],
                suppliers_code: option.value,
              };
              setOtherNotClinkToSystem(newOtherNotClinkToSystem);
              setPurchaseCostOtherThanSystemDataOther(newOtherNotClinkToSystem);
            }}
            value={purchaseCostOtherThanSystemDataOther[rowIndex].suppliers_code}
          >
            {otherSuppliers.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </td>
      ),
    },
    {
      name: '金額',
      visible: true,
      content: (rowIndex: number, colIndex: number) => (
        <td className="text-right" key={colIndex}>
          <span>
            <FormatInput
              type="tel"
              name="otherAmount"
              value={String(numberFormater(removeComma(purchaseCostOtherThanSystemDataOther[rowIndex].purchase_amount)))}
              onChange={(e) => {
                handleRemoveKeepCursorPosition(e)
                if (e.target.value == '' || re.test(e.target.value)) {
                  const option = e.target;
                  const newOtherAmount = [...otherAmount];
                  const newOtherNotClinkToSystemt = [...purchaseCostOtherThanSystemDataOther];
                  newOtherNotClinkToSystemt[rowIndex] = {
                    ...newOtherNotClinkToSystemt[rowIndex],
                    purchase_amount: option.value,
                  };
                  newOtherAmount[rowIndex] = numberFormater(removeComma((option.value)));
                  setTotalOthersAmount(newOtherAmount.reduce((prev, otherAmountValue) => {
                    const prevFormat = prev !== undefined ? Number(String(prev).replace(/,/g, '')) : 0;
                    const othersAmountValueFormat = otherAmountValue !== undefined ? Number(String(otherAmountValue).replace(/,/g, '')) : 0;
                    return (Number(!isNaN(prevFormat) ? prevFormat : 0) + Number(!isNaN(othersAmountValueFormat) ? othersAmountValueFormat : 0 ));
                  },0));
                  setOtherNotClinkToSystem(newOtherNotClinkToSystemt);
                  setPurchaseCostOtherThanSystemDataOther(newOtherNotClinkToSystemt);
                  setOtherAmount(newOtherAmount);
                }
              }}

            />
          </span>
        </td>
      ),
    },
    {
      name: '削除',
      visible: true,
      content: (rowIndex: number, colIndex: number) => (
        <td key={colIndex} className="text-center" style={{ width: '10px' }}>
          <Link
            to="#"
            onClick={() => {
              setTypeDelete('other');
              openConfirmModal(rowIndex);
            }}
          >
            <span
              style={{ marginLeft: '7px', marginRight: '7px' }}
            >
              <Icon type="delete" color={iconColor.gray} hoverColor={iconColor.grayhover} />
            </span>
          </Link>
        </td>

      )
      ,
    },
  ];

  const addRow = () => {
    if (flag === 0) {
      if (initStateFood === undefined) {
        errorNotification('取引先が登録されていません。取引先マスタを登録してから操作をやり直してください。');
      } else {
        const newRowsFood = [...purchaseCostOtherThanSystemDataFood, initStateFood];
        console.log('newRowsFood', newRowsFood);
        setFoodNotClinkToSystem(newRowsFood);
        setPurchaseCostOtherThanSystemDataFood(newRowsFood);
      }
    } else if (flag === 1) {
      if (initStateDrink === undefined) {
        errorNotification('取引先が登録されていません。取引先マスタを登録してから操作をやり直してください。');
      } else {
        const newRowsDrink = [...purchaseCostOtherThanSystemDataDrink, initStateDrink];
        setDrinkNotClinkToSystem(newRowsDrink);
        setPurchaseCostOtherThanSystemDataDrink(newRowsDrink);
      }
    } else if (initStateOther === undefined) {
      errorNotification('取引先が登録されていません。取引先マスタを登録してから操作をやり直してください。');
    } else {
      const newRowsOther = [...purchaseCostOtherThanSystemDataOther, initStateOther];
      setOtherNotClinkToSystem(newRowsOther);
      setPurchaseCostOtherThanSystemDataOther(newRowsOther);
    }
  };

  const deleteNotClinkToSystem = (rowIndex: number) => {
    try {
      console.log('rowIndex', rowIndex);
      if (typeDelete === 'food') {
        const newRows = [...purchaseCostOtherThanSystemDataFood];
        newRows.splice(rowIndex, 1);
        setFoodNotClinkToSystem(newRows);
        const newRowsInput = [...foodAmount];
        newRowsInput.splice(rowIndex, 1);
        newRowsInput.length > 0 ? setTotalFoodAmount(newRowsInput.reduce((prev, foodAmountValue) => {
          const prevFormat = prev !== undefined ? String(prev).replace(/,/g, '') : '0';
          const foodAmountValueFormat = foodAmountValue !== undefined ? String(foodAmountValue).replace(/,/g, '') : '0';
          return (parseInt(prevFormat) + parseInt(foodAmountValueFormat));
        })) : setTotalFoodAmount(0);
        setFoodAmount(newRowsInput);
        setPurchaseCostOtherThanSystemDataFood(newRows);
      } else if (typeDelete === 'drink') {
        const newRows = [...purchaseCostOtherThanSystemDataDrink];
        newRows.splice(rowIndex, 1);
        setDrinkNotClinkToSystem(newRows);
        const newRowsInput = [...drinkAmount];
        newRowsInput.splice(rowIndex, 1);
        newRowsInput.length > 0 ? setTotalDrinkAmount(newRowsInput.reduce((prev, drinkAmountValue) => {
          const prevFormat = prev !== undefined ? String(prev).replace(/,/g, '') : '0';
          const drinkAmountValueFormat = drinkAmountValue !== undefined ? String(drinkAmountValue).replace(/,/g, '') : '0';
          return (parseInt(prevFormat) + parseInt(drinkAmountValueFormat));
        })) : setTotalDrinkAmount(0);
        setDrinkAmount(newRowsInput);
        setPurchaseCostOtherThanSystemDataDrink(newRows);
      } else {
        const newRows = [...purchaseCostOtherThanSystemDataOther];
        newRows.splice(rowIndex, 1);
        setOtherNotClinkToSystem(newRows);
        const newRowsInput = [...otherAmount];
        newRowsInput.splice(rowIndex, 1);
        newRowsInput.length > 0 ? setTotalOthersAmount(newRowsInput.reduce((prev, othersAmountValue) => {
          const prevFormat = prev !== undefined ? String(prev).replace(/,/g, '') : '0';
          const othersAmountValueFormat = othersAmountValue !== undefined ? String(othersAmountValue).replace(/,/g, '') : '0';
          return (parseInt(prevFormat) + parseInt(othersAmountValueFormat));
        })) : setTotalOthersAmount(0);
        setOtherAmount(newRowsInput);
        setPurchaseCostOtherThanSystemDataOther(newRows);
      }
    } catch (error) {
      errorNotification('サーバー側でエラーが発生しました。');
    }
    setConfirmModalOpen(false);
  };

  const apiGetSupplier = async (targetDate: any): Promise<any> => {
    const targetDay = moment(targetDate).format('YYYY-MM-DD');
    const { data } = await ApiClient.get(`/v1/sales/suppliersListByTargetDay/${companyCode}`, { targetDay }, {});
    return data;
  };

  // set dropdowns
  useEffect(() => {
    async function getReportStatus0() {
      const getSuppliers = await apiGetSupplier(targetDay);
      const dataFoods: OptionType[] = getSuppliers.filter((response: any) => response.id.transactionType === 0).map((status: any) => ({ value: status.id.suppliersCode, label: status.suppliersName }));
      const dataDrinks: OptionType[] = getSuppliers.filter((response: any) => response.id.transactionType === 1).map((status: any) => ({ value: status.id.suppliersCode, label: status.suppliersName }));
      const dataOthers: OptionType[] = getSuppliers.filter((response: any) => response.id.transactionType === 2).map((status: any) => ({ value: status.id.suppliersCode, label: status.suppliersName }));
      console.log('dataFoods', dataFoods);
      setDrinkSuppliers(dataDrinks);
      setFoodSuppliers(dataFoods);
      setOtherSuppliers(dataOthers);
      // if (getSuppliers && getSuppliers.length > 0) {
      //   setInitStateFood({
      //     suppliers_code: dataFoods[0].value,
      //     purchase_amount: '0',
      //   });
      //   setInitStateDrink({
      //     suppliers_code: dataDrinks[0].value,
      //     drink_classification: '0',
      //     purchase_amount: '0',
      //   });
      //   setInitStateOther({
      //     suppliers_code: dataOthers[0].value,
      //     purchase_amount: '0',
      //   });
      // }
      if (dataFoods.length) {
        setInitStateFood({
          suppliers_code: dataFoods[0].value,
          purchase_amount: '0',
        });
      }
      if (dataDrinks.length) {
        setInitStateDrink({
          suppliers_code: dataDrinks[0].value,
          drink_classification: '0',
          purchase_amount: '0',
        });
      }
      if (dataOthers.length) {
        setInitStateOther({
          suppliers_code: dataOthers[0].value,
          purchase_amount: '0',
        });
      }
    }
    getReportStatus0();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDay]);

  return (
    <div>
      <div css={styles.contaner}>
        <div css={styles.wrapperTitle}><span style={{ padding: '0 6px' }}>経費情報</span></div>
        <div css={styles.wrapper}>
          <div style={{ padding: '30px' }}>
            <div>システム連携</div>
            <ThreeColumnLayout>
              {{
                firstColumn: (
                  <div>
                    <div css={styles.titleColumn}>フード</div>
                    <div css={styles.titleBody}>
                      <div css={table}>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>システム</th>
                              <th>手入力</th>
                              <th>合計</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><span>{numberFormater(foodSystem)}</span></td>
                              <td>
                                <span>
                                  <FormatInput
                                    type="tel"
                                    name="foodCost"
                                    value={String(numberFormater(foodCost))}
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (e.target.value == '' || re.test(e.target.value)) {
                                        setFoodCost(removeComma(e.target.value));
                                        setFoodTotalManual((!isNaN(removeComma(e.target.value)) ? removeComma(e.target.value) : 0) + removeComma(foodSystem));
                                      }
                                    }}


                                  />
                                </span>
                              </td>
                              <td><span>{numberFormater(foodTotalManual)}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div css={table2}>
                        <div>取引先</div>
                        <div>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>取引先名</th>
                                <th>金額</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dailyData?.cash_data?.purchase_cost_food
                              && dailyData?.cash_data?.purchase_cost_food.map((x: any) => (
                                <tr>
                                  <td><span>{numberFormater(x.suppliers_name)}</span></td>
                                  <td><span>{numberFormater(x.amount)}</span></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>

                ),
                secondColumn: (
                  <div>
                    <div css={styles.titleColumn}>ドリンク</div>
                    <div css={styles.titleBody}>
                      <div css={table}>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>システム</th>
                              <th>手入力</th>
                              <th>合計</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><span>{numberFormater(drinkSystem)}</span></td>
                              <td>
                                <span>
                                  <FormatInput
                                    type="tel"
                                    name="drinkCostManual"
                                    value={String(numberFormater(drinkCostManual))}
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (e.target.value == '' || re.test(e.target.value)) {
                                        setDrinkCostManual(removeComma(e.target.value));
                                        setDrinkTotalManual((!isNaN(removeComma(e.target.value)) ? removeComma(e.target.value) : 0) + removeComma(drinkSystem));
                                      }
                                    }}

                                  />
                                </span>
                              </td>
                              <td><span>{numberFormater(drinkTotalManual)}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div css={table2}>
                        <div>取引先</div>
                        <div>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>取引先名</th>
                                <th>金額</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dailyData?.cash_data?.purchase_cost_drink
                              && dailyData?.cash_data?.purchase_cost_drink.map((x: any) => (
                                <tr>
                                  <td><span>{numberFormater(x.suppliers_name)}</span></td>
                                  <td><span>{numberFormater(x.amount)}</span></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>
                ),
                threeColumn: (
                  <div>
                    <div css={styles.titleColumn}>消耗品関係・その他</div>
                    <div css={styles.titleBody}>
                      <div css={table}>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>システム</th>
                              <th>手入力</th>
                              <th>合計</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><span>{numberFormater(otherSystem)}</span></td>
                              <td>
                                <span>
                                  <FormatInput
                                    type="tel"
                                    name="otherCostManual"
                                    value={String(numberFormater(otherCostManual))}
                                    onChange={(e) => {
                                      handleRemoveKeepCursorPosition(e)
                                      if (e.target.value == '' || re.test(e.target.value)) {
                                        setOtherCostManual(removeComma(e.target.value));
                                        setOtherTotalManual((!isNaN(removeComma(e.target.value)) ? removeComma(e.target.value) : 0) + removeComma(otherSystem));
                                      }
                                    }}


                                  />
                                </span>
                              </td>
                              <td><span>{numberFormater(otherTotalManual)}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div css={table2}>
                        <div>取引先</div>
                        <div>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>取引先名</th>
                                <th>金額</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dailyData?.cash_data?.purchase_cost_other
                              && dailyData?.cash_data?.purchase_cost_other.map((x: any) => (
                                <tr>
                                  <td><span>{numberFormater(x.suppliers_name)}</span></td>
                                  <td><span>{numberFormater(x.amount)}</span></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              }}
            </ThreeColumnLayout>
          </div>


          <div style={{ padding: '0 30px 30px 30px' }}>
            <div>システム連携外</div>
            <ThreeColumnLayout>
              {{
                firstColumn: (
                  <div className="table">
                    <div css={styles.titleColumn}>フード</div>
                    <div css={styles.titleBody}>
                      <div css={table}>
                        <table className="table table-bordered">
                          <tfoot>
                            <tr>
                              <td>合計</td>
                              <td><span style={{ textAlign: 'right', paddingRight: '5px' }}>{numberFormater(totalFoodAmount)}</span></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>

                      <div className="panel panel-default table-responsive" style={{ paddingTop: '30px' }}>
                        <table className="table table-bordered table-striped table-condensed text-nowrap">
                          <thead>
                            <tr className="width_row_contents">
                              {tableColumnsFood.filter((col) => col.visible).map((column, i) => (
                                <th key={i} className="text-center" style={{ width: column.width || 'auto' }}>
                                  {column.name}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {purchaseCostOtherThanSystemDataFood && purchaseCostOtherThanSystemDataFood.length > 0 && purchaseCostOtherThanSystemDataFood.map((row: any, rowIndex: number) => (
                              <tr key={rowIndex}>
                                {tableColumnsFood.filter((col) => col.visible).map((column, colIndex) => column.content(rowIndex, colIndex))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div style={{ paddingTop: '12px' }}>
                          <PrimaryButton
                            text="追加"
                            onClick={() => {
                              flag = 0;
                              addRow();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                ),
                secondColumn: (
                  <div>
                    <div css={styles.titleColumn}>ドリンク</div>
                    <div css={styles.titleBody}>
                      <div css={table}>
                        <table className="table table-bordered">
                          <tfoot>
                            <tr>
                              <td>合計</td>
                              <td><span style={{ textAlign: 'right', paddingRight: '5px' }}>{numberFormater(totalDrinkAmount)}</span></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>

                      <div className="panel panel-default table-responsive" style={{ paddingTop: '30px' }}>
                        <table className="table table-bordered table-striped table-condensed text-nowrap">
                          <thead>
                            <tr className="width_row_contents">
                              {tableColumnsDrink.filter((col) => col.visible).map((column, i) => (
                                <th key={i} className="text-center" style={{ width: column.width || 'auto' }}>
                                  {column.name}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {purchaseCostOtherThanSystemDataDrink && purchaseCostOtherThanSystemDataDrink.length > 0 && purchaseCostOtherThanSystemDataDrink.map((row: any, rowIndex: number) => (
                              <tr key={rowIndex}>
                                {tableColumnsDrink.filter((col) => col.visible).map((column, colIndex) => column.content(rowIndex, colIndex))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div style={{ paddingTop: '12px' }}>
                          <PrimaryButton
                            text="追加"
                            onClick={() => {
                              flag = 1;
                              addRow();
                            }}
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                ),
                threeColumn: (
                  <div>
                    <div css={styles.titleColumn}>消耗品関係・その他</div>
                    <div css={styles.titleBody}>
                      <div css={table}>
                        <table className="table table-bordered">
                          <tfoot>
                            <tr>
                              <td>合計</td>
                              <td><span style={{ textAlign: 'right', paddingRight: '5px' }}>{numberFormater(totalOthersAmount)}</span></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>

                      <div className="panel panel-default table-responsive" style={{ paddingTop: '30px' }}>
                        <table className="table table-bordered table-striped table-condensed text-nowrap">
                          <thead>
                            <tr className="width_row_contents">
                              {tableColumnsOthers.filter((col) => col.visible).map((column, i) => (
                                <th key={i} className="text-center" style={{ width: column.width || 'auto' }}>
                                  {column.name}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {purchaseCostOtherThanSystemDataOther && purchaseCostOtherThanSystemDataOther.length > 0 && purchaseCostOtherThanSystemDataOther.map((row: any, rowIndex: number) => (
                              <tr key={rowIndex}>
                                {tableColumnsOthers.filter((col) => col.visible).map((column, colIndex) => column.content(rowIndex, colIndex))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div style={{ paddingTop: '12px' }}>
                          <PrimaryButton
                            text="追加"
                            onClick={() => {
                              flag = 2;
                              addRow();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              }}
            </ThreeColumnLayout>
          </div>

          { setting && setting.includePettyCashInPurchase ? (
            <div style={{ padding: '0 30px' }}>
              <div><Link to={{ pathname: `/pettyCash/${targetOrgCode}/${moment(targetDay).format('YYYY-MM-DD')}/`}} target='_blank'>小口現金</Link></div>
              <ThreeColumnLayout>
                {{
                  firstColumn: (
                    <div className="table">
                      <div css={styles.titleColumn}>フード</div>
                      <div css={styles.titleBody}>
                        <div css={table}>
                          <table className="table table-bordered">
                            <tfoot>
                              <tr>
                                <td>合計</td>
                                <td>
                                  <span style={{ textAlign: 'right', paddingRight: '5px' }}>
                                    {numberFormater(foodSumAmountSmallCase)}
                                  </span>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>

                        <div css={table2}>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>支払先</th>
                                <th>金額</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dailyData?.cash_data?.petty_cash_food
                                && dailyData?.cash_data?.petty_cash_food.map((x: any) => (
                                  <tr>
                                    <td><span>{x.payee}</span></td>
                                    <td><span>{numberFormater(x.amount)}</span></td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>

                  ),
                  secondColumn: (
                    <div>
                      <div css={styles.titleColumn}>ドリンク</div>
                      <div css={styles.titleBody}>
                        <div css={table}>
                          <table className="table table-bordered">
                            <tfoot>
                              <tr>
                                <td>合計</td>
                                <td><span style={{ textAlign: 'right', paddingRight: '5px' }}>{numberFormater(drinkSumAmountSmallCase)}</span></td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>

                        <div css={table2}>
                          <div>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>支払先</th>
                                  <th>分類</th>
                                  <th>金額</th>

                                </tr>
                              </thead>
                              <tbody>
                                {dailyData?.cash_data?.petty_cash_drink
                                && dailyData?.cash_data?.petty_cash_drink.map((x: any) => (
                                  <tr>
                                    <td><span>{x.payee}</span></td>
                                    <td><span>{x.classification_code === "2" ? 'ドリンク' : '酒類'}</span></td>
                                    <td><span>{numberFormater(x.amount)}</span></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                      </div>
                    </div>
                  ),
                  threeColumn: (
                    <div>
                      <div css={styles.titleColumn}>消耗品関係・その他</div>
                      <div css={styles.titleBody}>
                        <div css={table}>
                          <table className="table table-bordered">
                            <tfoot>
                              <tr>
                                <td>合計</td>
                                <td><span style={{ textAlign: 'right', paddingRight: '5px' }}>{numberFormater(otherSumAmountSmallCase)}</span></td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>

                        <div css={table2}>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>支払先</th>
                                <th>分類</th>
                                <th>金額</th>

                              </tr>
                            </thead>
                            <tbody>
                              {dailyData?.cash_data?.petty_cash_other
                                && dailyData?.cash_data?.petty_cash_other.map((x: any) => (
                                  <tr>
                                    <td><span>{x.payee}</span></td>
                                    <td><span>{x.classification_name}</span></td>
                                    <td><span>{numberFormater(x.amount)}</span></td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ),
                }}
              </ThreeColumnLayout>
              {/* Total of the 3 columns */}
              <div style={{width:'calc(100%-1px)',height:30,border:'1px solid black',borderTop:0,padding:'15px 30px'}}>
                  <div style={{display:'flex',flexDirection:'row',width:'32%',height:25,border:'1px solid black',minWidth:'280px'}}>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'flex-start',borderRight:'1px solid black',padding:'0px 5px',width:'35%',minWidth:'70px',backgroundColor:'#EFEFEF'}}>
                      小口合計
                    </div>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',padding:'0px 5px',width:'80%',minWidth:'150px'}}>
                      {numberFormater(pettyCashSum)}
                      </div>
                  </div>
              </div>
            </div>
          ) : '' }

          <div style={{ padding: '30px' }}>
            <Row>
              <Col md={4} xs={12}>
                <div css={table3}>
                  <table className="table table-bordered">
                    <tfoot>
                      <tr>
                        <td>フード合計</td>
                        <td><span>{numberFormater(removeComma(foodTotalManual) + removeComma(totalFoodAmount) + removeComma(setting && setting.includePettyCashInPurchase ? foodSumAmountSmallCase : 0))}</span></td>
                      </tr>
                      <tr>
                        <td>ドリンク合計</td>
                        <td><span>{numberFormater(removeComma(drinkTotalManual) + removeComma(totalDrinkAmount) + removeComma(setting && setting.includePettyCashInPurchase ? drinkSumAmountSmallCase : 0)) }</span></td>
                      </tr>
                      <tr>
                        <td>その他合計</td>
                        <td><span>{numberFormater(removeComma(otherTotalManual) + removeComma(totalOthersAmount) + removeComma(setting && setting.includePettyCashInPurchase ? otherSumAmountSmallCase : 0)) }</span></td>
                      </tr>
                      <tr>
                        <td>合計</td>
                        <td>
                          <span>
                            {
                          numberFormater(
                            removeComma(foodTotalManual) + removeComma(totalFoodAmount) + removeComma(drinkTotalManual)
                            + removeComma(totalDrinkAmount) + removeComma(otherTotalManual) + removeComma(totalOthersAmount)
                            + removeComma(setting && setting.includePettyCashInPurchase ? foodSumAmountSmallCase : 0)
                            + removeComma(setting && setting.includePettyCashInPurchase ? drinkSumAmountSmallCase : 0)
                            + removeComma(setting && setting.includePettyCashInPurchase ? otherSumAmountSmallCase : 0),
                          )
                        }
                          </span>

                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>

              </Col>
            </Row>
          </div>
        </div>
      </div>

      { setting && setting.manageRevenueStamp && moment(setting.manageRevenueStampStartDate).format('YYYY-MM-DD') <= moment(targetDay).format('YYYY-MM-DD') ? (
        <div style={{ padding: '30px 0' }}>
          <Row>
            <Col md={4} xs={12}>
              <div css={table4}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th colSpan={2}>項目</th>
                      <th>枚数</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: backgroundColor.whiteSmoke }} rowSpan={5}>収入印紙</td>
                      <td>前日枚数</td>
                      <td><span>{numberFormater(removeComma(remainingBefor))}</span></td>
                    </tr>
                    <tr>
                      <td>購入数</td>
                      <td>
                        <FormatInput
                          type="tel"
                          name="purchaseNumber"
                          value={String(numberFormater(purchaseNumber))}
                          onChange={(e) => {
                            handleRemoveKeepCursorPosition(e)
                            if (e.target.value == '' || re.test(e.target.value)) {
                              setPurchaseNumber(removeComma(e.target.value));
                              setRemaining(removeComma(!isNaN(remainingBefor) ? remainingBefor : 0 ) + (!isNaN(removeComma(e.target.value)) ? removeComma(e.target.value) : 0 ) + removeComma(!isNaN(stampAdjustment) ? stampAdjustment : 0 ) - removeComma(!isNaN(stampUse) ? stampUse : 0));
                            }
                          }}

                        />
                      </td>
                    </tr>
                    <tr>
                      <td>調整数</td>
                      <td>
                        <FormatInput
                          type="tel"
                          name="stampAdjustment"
                          value={String(numberFormater(stampAdjustment))}
                          onChange={(e) => {
                            handleRemoveKeepCursorPosition(e)
                            if (e.target.value == '' || re.test(e.target.value)) {
                              setStampAdjustment(removeComma(e.target.value));
                              setRemaining(removeComma(!isNaN(remainingBefor) ? remainingBefor : 0 ) + removeComma(!isNaN(purchaseNumber) ? purchaseNumber : 0 ) + (!isNaN(removeComma(e.target.value)) ? removeComma(e.target.value) : 0  ) - removeComma(!isNaN(stampUse) ? stampUse : 0 ));
                            }
                          }}

                        />
                      </td>
                    </tr>
                    <tr>
                      <td>使用数</td>
                      <td>
                        <FormatInput
                          type="tel"
                          name="stampUse"
                          value={String(numberFormater(stampUse))}
                          onChange={(e) => {
                            handleRemoveKeepCursorPosition(e)
                            if (e.target.value == '' || re.test(e.target.value)) {
                              setStampUse(removeComma(e.target.value));
                              setRemaining(removeComma(!isNaN(remainingBefor) ? remainingBefor : 0 ) + removeComma(!isNaN(purchaseNumber) ? purchaseNumber : 0 ) + removeComma(!isNaN(stampAdjustment) ? stampAdjustment : 0 ) - (!isNaN(removeComma(e.target.value)) ? removeComma(e.target.value) : 0));
                            }
                          }}

                        />
                      </td>
                    </tr>
                    <tr>
                      <td>残枚数</td>
                      <td><span>{numberFormater(removeComma(!isNaN(remaining) ? remaining : 0 ))}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </Col>
          </Row>

        </div>
      ) : '' }

      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={() => deleteNotClinkToSystem(stateDelete)}
        actionType={ActionType.DELETE}
      />
    </div>


  );
};
export default ExpenseInformation;
