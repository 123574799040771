import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import GpsStampHistoryLayout from 'components/organismos/master/atendanceManagement/GpsStampHistoryLayout';

const GpsStampHistoryPage = () => (
  <SidebarTemplate pageTitle="GPS打刻実績">
    <GpsStampHistoryLayout />
  </SidebarTemplate>
);

export default GpsStampHistoryPage;
