
import React, { useState, useEffect } from 'react';
import { useSearchCategoryOption } from 'hooks/useOrganizationCategoryOptions';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import { OptionType } from 'components/atoms/Select';
import { OrgStatusType, SessionStorageKey, Values } from 'utility/constants';

export const useOrganizationFilteredTree = (
  functionType: number,
  staffCode: string,
  initValues: string | Array<string> | undefined,
  isMulti: boolean,
  addAllItem: boolean,
  key: any,
  targetdayFrom?: Date,
  targetdayTo?: Date,
  callback?: (args: any) => void,
  orgCategoryCallback?: (args: any) => void,
  orgFilter?: number,
  orgStatus?: OrgStatusType,
) => {
  const orgSesssionInit = JSON.parse(String(sessionStorage.getItem('attendResult.selectCategoryOption'))) || [];
  const [blocking, setBlocking] = useState(false);

  let categorySessionAry = JSON.parse(String(sessionStorage.getItem(`sessionOrganizationFileterTree${window.location.pathname}${orgFilter !== undefined ? orgFilter : ''}.Toggle`)));
  const previous = sessionStorage.getItem('pathOrganizationFileterTree') || '';

  let categoryAttendAry = JSON.parse(String(sessionStorage.getItem('attendResult.selectCategoryOption')));

  if (window.location.pathname.split('/')[1] === 'attendError') {
    categoryAttendAry = JSON.parse(String(sessionStorage.getItem('attendError.selectCategoryOption')));
  }

  if (['attendStampList'].indexOf(String(previous).split('/')[1]) !== -1) {
    categorySessionAry = categoryAttendAry;
  }

  if (window.location.pathname.split('/')[1] === 'monthlyDayStoreSalesManagement') {
    categorySessionAry = JSON.parse(String(sessionStorage.getItem(SessionStorageKey.Sales.MonthlyDayStoreSalesManagement.SEARCH_CATEGORY_ARRAY)));
  }

  const [categoryAry, setCategoryAry] = useState<Array<any>>(categorySessionAry || orgSesssionInit);

  const optionsCategory = useSearchCategoryOption(
    functionType,
    categoryAry,
    staffCode,
    targetdayFrom !== undefined ? moment(targetdayFrom).format('YYYY-MM-DD') : undefined,
    targetdayTo !== undefined ? moment(targetdayTo).format('YYYY-MM-DD') : undefined,
    addAllItem,
    orgStatus,
  );
  const [options, setOptions] = useState<Array<any>>([]);
  const [categorySelect, setCategorySelect] = React.useState<Array<string>>([]);
  // console.log('---------------------------------------------------------');
  // console.log('initValues', initValues);
  const [orgCode, setOrgCode] = useState<Array<string>>([]);
  const [specify, setSpecify] = useState<Array<string>>([]);
  const orgSelectTag = String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}${orgFilter !== undefined ? orgFilter : ''}`)) !== 'null' ? [String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}${orgFilter !== undefined ? orgFilter : ''}`))] : [String(sessionStorage.getItem('loginUser.orgCode'))];
  const [orgSelectFilter, setOrgSelectFilter] = useState<Array<string>>(orgSelectTag);
  // console.log('initOrgCode', orgCode);

  const [orgSelect, setOrgSelect] = useState<Array<string>>(orgCode);

  const [orgOptionList, setOrgOptionList] = useState<Array<any>>([]);
  const [firstLoad, isFirstLoad] = useState<boolean>(true);


  useEffect(() => {
    let init: Array<string> = [];
    // console.log('initValues', initValues);
    if (typeof (initValues) === 'string') {
      init = [initValues];
    } else if (Array.isArray(initValues)) {
      init = initValues;
    }
    setOrgCode(init);
    if (window.location.pathname === '/masterShopSpecify') {
      sessionStorage.setItem('masterShopSpecifyOrg', String(init));
    }
  }, [initValues]);

  useEffect(() => {
    //
    const tempList: Array<any> = [];
    const categoryTempList: Array<any> = [];
    optionsCategory.forEach((item: any) => {
      const catyTempList: Array<any> = [];
      item.options.filter((option: OptionType) => {
        if (categorySelect.includes(option.value)) {
          catyTempList.push(option.value);
          categoryTempList.push(option.value);
          return true;
        }
        return false;
      });
      if (catyTempList.length) {
        tempList.push(catyTempList);
      }
    });
    if (categoryTempList.length < categorySelect.length) {
      setCategoryAry(tempList);
      setCategorySelect(categoryTempList);
      if (orgCategoryCallback) {
        orgCategoryCallback(tempList);
      }
      return;
    }
    setOptions(optionsCategory);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsCategory]);

  useEffect(() => {
    setOrgSelect(orgCode);
    setOrgSelectFilter(orgCode);
  }, [callback, orgCode]);

  useEffect(() => {
    if (orgOptionList && orgOptionList.length > 0) {
      const sortedOrgList = orgOptionList.filter(
        (orgOption) => orgCode.includes(orgOption.value),
      );
      if (window.location.pathname === '/masterShopSpecify') {
        sessionStorage.setItem('masterShopSpecify', String(orgOptionList[0].specifyCodeValue));
      }
      if (sortedOrgList.length <= 0 || String(orgCode) === '') {
        if (!isMulti) {
          const spcifyCodeValue = orgOptionList[0].specifyCodeValue;
          setSpecify(spcifyCodeValue);
          setOrgCode([orgOptionList[0].value]);
          if (callback) {
            callback([orgOptionList[0].value]);
          }
        }
        isFirstLoad(false);
      } else if (sortedOrgList.length <= 0 && String(orgCode) !== '') {
        if (!isMulti) {
          setOrgCode(['']);
          if (callback) {
            callback(['']);
          }
        }
        isFirstLoad(false);
      }
    } else if (String(orgCode) !== '' && !firstLoad) {
      if (!isMulti) {
        setOrgCode(['']);
        if (callback) {
          callback(['']);
        }
      }
    }
  }, [callback, firstLoad, isMulti, orgCode, orgOptionList]);
  return {
    state: {
      options,
      orgSelect,
      categoryAry,
      categorySelect,
      orgOptionList,
      orgCode,
      specify,
      blocking,
    },
    setter: {
      setOptions,
      setOrgSelect,
      setCategoryAry,
      setCategorySelect,
      setOrgOptionList,
      setOrgCode,
      setSpecify,
      setBlocking,
    },
    orgSelectFilter,
    setOrgSelectFilter,
  };
};
export default {};
