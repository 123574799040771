import { useState, useCallback } from 'react';

import { useAllOrgTrees } from 'hooks/useOrgTreesOptions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ApplyingTransferDomain from '../domain/applyingTransferDomain';
import { getApplyingStampListHistory } from '../api';
import { IchangeLog } from '../interface';

type ApplyingTransferKey = keyof Pick<
  ApplyingTransferDomain,
  'applicationReason'
>;

const ApplyingTransferValidationSchema: {
  [key in ApplyingTransferKey]: any;
} = {
  applicationReason: Yup.string()
    .trim()
    .required('申請理由を入力してください。'),
};

const schema = Yup.object({ ...ApplyingTransferValidationSchema });

// eslint-disable-next-line import/prefer-default-export
export const useApplyingTransferForm = (list: any) => {
  const [isLoading, setLoading] = useState(false);
  const [targetDate] = useState(
    sessionStorage.getItem('application.targetDate') || '',
  );
  const [staffName] = useState(
    sessionStorage.getItem('application.dispStaffName') || '',
  );
  const [dispStaffName] = useState(
    sessionStorage.getItem('application.dispStaffName') || '',
  );
  const [selectOrgCode, setSelectOrgCode] = useState(
    sessionStorage.getItem('application.orgCode') || '',
  );
  const orgTreesOptions = useAllOrgTrees();
  const [toastMessage, setToastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmApplyModalOpen, setConfirmApplyModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number[]>([]);
  const [buttonType, setButtonType] = useState<number>(0);

  const [changeLogModalOpen, setChangeLogModalOpen] = useState<boolean>(false);
  const [changeLogData, setChangeLogData] = useState<IchangeLog[]>([]);
  const [selectChangelog, setSelectChangelog] = useState<string>('0');

  const onSubmit = async (values: []) => {};

  const formik = useFormik({
    initialValues: list,
    enableReinitialize: true,
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
    validate: async (values) => {
      const errors: { [key: string]: string } = {};
      try {
        await schema.validate(values, { abortEarly: false });
      } catch (error) {
        const validationError = error as Yup.ValidationError;
        validationError.inner.forEach((innerError) => {
          console.log('innerError.message', innerError.message);
          errors[innerError.path] = innerError.message;
        });
      }
      return errors;
    },
  });

  const rejectReasonOnchange = (index: number, event: any) => {
    const rejectReason = event.target.value || '';
    formik.setFieldValue(`${index}.rejectReason`, rejectReason);
  };

  const extractBulkApproveData = (data: any[]) => {
    const approvalDataList = data.map((item: any) => (
      {
        staffCode: sessionStorage.getItem('loginUser.staffCode') || '',
        buttonType: 0,
        modifierApplicationId: item.modifierApplicationId,
        appStaffName: '',
        targetDate: item.targetDate,
        belongOrgName: item.orgName,
        orgName: item.orgName,
      }
    ));
    return {
      approvalDataList,
      applicationTransferList_length: approvalDataList.length * 5,
    };
  };

  const extractBulkHolidayApproveData = (data: any[]) => {
    const approvalDataList = data.map((item: any) => (
      {
        // approvalStaffCode: sessionStorage.getItem('loginUser.staffCode') || '',
        staffCode: sessionStorage.getItem('loginUser.staffCode') || "",
        buttonType: 0,
        modifierApplicationId: item.modifierApplicationId,
        appStaffName: '',
        targetDate: item.targetDate,
        belongOrgName: item.orgName,
        orgName: item.orgName,
      }
    ));
    return {
      approvalDataList,
      applicationTransferList_length: approvalDataList.length * 5,
    };
  };

  const extractSingleApproveData = (data: any[]) => ({
    modifierApplicationId: data[0].modifierApplicationId,
    staffCode: sessionStorage.getItem('loginUser.staffCode') || '',
    approvalStaffCode: data[0].approvalStaffCode || '',
    achievementId: data[0].achievementId || '',
    orgName: data[0].orgName,
    belongOrgName: data[0].orgName,
    buttonType: 0,
    applicationStatus: 1,
    rejectReason: data[0].rejectReason,
  });

  const extractSingleHolidayApproveData = (data: any[]) => ({
    modifierApplicationId: data[0].modifierApplicationId,
    approvalStaffCode: sessionStorage.getItem('loginUser.staffCode') || '',
    // approvalStaffCode: data[0].approvalStaffCode || "",
    achievementId: data[0].achievementId || '',
    orgName: data[0].orgName,
    belongOrgName: data[0].orgName,
    buttonType: 0,
    applicationStatus: 1,
    rejectReason: data[0].rejectReason,
  });

  const extractSingleRejectData = (data: any[]) => ({
    modifierApplicationId: data[0].modifierApplicationId,
    staffCode: sessionStorage.getItem('loginUser.staffCode') || '',
    approvalStaffCode: data[0].approvalStaffCode || '',
    achievementId: data[0].achievementId || '',
    orgName: data[0].orgName,
    belongOrgName: data[0].orgName,
    applicationStatus: 2,
    rejectReason: data[0].rejectReason,
    buttonType: 1,
  });

  const extractSingleHolidayRejectData = (data: any[]) => ({
    modifierApplicationId: data[0].modifierApplicationId,
    approvalStaffCode: sessionStorage.getItem('loginUser.staffCode') || '',
    // approvalStaffCode: data[0].approvalStaffCode || "",
    achievementId: data[0].achievementId || '',
    orgName: data[0].orgName,
    belongOrgName: data[0].orgName,
    applicationStatus: 2,
    rejectReason: data[0].rejectReason,
    buttonType: 1,
  });

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmApplyModal = useCallback(() => {
    setConfirmApplyModalOpen(false);
  }, []);

  const fetchChangeLog = async (applicationId:string) => {
    const history = await getApplyingStampListHistory({ applicationId });
    setChangeLogData(history);
  };

  return {
    formik,
    orgTreesOptions,
    selectOrgCode,
    setSelectOrgCode,
    staffName,
    targetDate,
    toastMessage,
    setToastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    setConfirmApplyModalOpen,
    closeToastModal,
    closeConfirmApplyModal,
    isLoading,
    setLoading,
    dispStaffName,

    extractSingleApproveData,
    extractSingleHolidayApproveData,
    extractBulkApproveData,
    extractBulkHolidayApproveData,
    extractSingleRejectData,
    extractSingleHolidayRejectData,

    rejectReasonOnchange,

    buttonType,
    setButtonType,
    selectedIndex,
    setSelectedIndex,

    changeLogModalOpen,
    setChangeLogModalOpen,
    changeLogData,
    setChangeLogData,
    selectChangelog,
    setSelectChangelog,
    fetchChangeLog,
  };
};

// export default useApplyingTransferForm;
