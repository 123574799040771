import React from 'react';

import FullScreenOverlay from 'components/atoms/FullScreenOverlay';
import Panel from 'components/atoms/Panel';
import Header from 'components/molecules/Modal/Header';
import Footer from 'components/molecules/Modal/Footer';
import Body from 'components/molecules/Modal/Body';
import BodyText from 'components/atoms/BodyText';
import { css, jsx ,SerializedStyles } from '@emotion/core';

/**
 * モーダル
 */
const Modal: React.FC<{
  open: boolean
  closeHandler: () => void
  title: string
  submitText: string
  closeText?: string
  onSubmit: () => void
  isShowButtonClose?: boolean
  width?: string;
  optionalButtonText?: string;
  optionalButtonSubmit?: () => void;
  isDisabledAll?: boolean;
  note?:string;
  customStyle?: SerializedStyles;
  customStyleBody?: SerializedStyles;
}> = ({
  children, open, closeHandler, title, submitText, onSubmit, closeText = 'キャンセル', isShowButtonClose = true, isDisabledAll = false,
  width, optionalButtonText, optionalButtonSubmit, customStyle,note='', customStyleBody,
}) => (
  <FullScreenOverlay open={open}>
    <Panel
      width={width}
      customStyle={customStyle}
    >
      <Header
        title={title}
        closeHandler={closeHandler}
      />
      <Body customStyleBody={customStyleBody}>
        <div css={css({ width: '100%', textAlign: 'center' })}>
          <BodyText>{note}</BodyText>
        </div>
        {children}
      </Body>
      <Footer
        submitText={submitText}
        closeText={closeText}
        onSubmit={onSubmit}
        closeHandler={closeHandler}
        isShowButtonClose={isShowButtonClose}
        optionalButtonText={optionalButtonText}
        optionalButtonSubmit={optionalButtonSubmit}
        isDisabledAll={isDisabledAll}
      />
    </Panel>
  </FullScreenOverlay>
);

export default Modal;
