
import React from 'react';
import PriceWithdrawlImportForm from 'components/organismos/master/sales/PriceWithdrawlForm/PriceWithdrawlImportForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const PriceWidthrawlImportPagePage = () => (
  <SidebarTemplate pageTitle="値引・割引取込インポート">
    <PriceWithdrawlImportForm />
  </SidebarTemplate>
);

export default PriceWidthrawlImportPagePage;
