/** @jsx jsx */
import React, { useEffect } from 'react';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBox from 'components/atoms/FlexBox';
import {
  grayScale,
} from 'components/styles';
import DataTable from 'components/organismos/DataTable/DataTable';
import { css, jsx } from '@emotion/core';
import Accordion from 'components/molecules/Accordion';
import PaidHolidayGrantHistoryDomain from 'domain/master/holidayManagement/paidHolidayGrantHistory';
import { Column } from 'react-table';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import DatePicker from 'components/molecules/DatePicker';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { useHistory } from 'react-router-dom';
import { useGrantHistoryDomainForm, useDownloadHolidayManagementGrantHistory } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

// create style form
const styles = {
  form: css({
    paddingTop: '15px',
  }),
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
    minWidth: '100px',
  }),
  digestionDays: css({
    width: '200px',
  }),
  betweenDays: css({
    width: '50px',
    textAlign: 'center',
  }),
  textMessage: css({
    color: 'black',
  }),
};

const GrantHistoryForm: React.FC<{arrGrantResult: any}> = ({
  arrGrantResult,
}) => {
  const {
    paidHolidayGrantHistoryList,
    orgCode,
    setOrgCode,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    paidGrantResult,
    setPaidGrantResult,
  } = useGrantHistoryDomainForm();

  const {
    downloadHolidayManagementGrantHistory,
  } = useDownloadHolidayManagementGrantHistory(
    orgCode,
    targetDateFrom,
    targetDateTo,
    paidGrantResult,
  );
  //
  const columns: Array<Column<PaidHolidayGrantHistoryDomain>> = React.useMemo(() => [
    {
      Header: 'スタッフコード',
      accessor: 'staffCode',
      sortType: 'basic'
    },
    {
      Header: 'スタッフ名',
      accessor: 'staffName',
    },
    {
      Header: '付与日',
      accessor: 'grantDateToStr',
    },
    {
      Header: '付与結果',
      accessor: 'grantResultStr',
    },
    {
      Header: '付与種別',
      accessor: 'grantTypeStr',
    },
    {
      Header: '繰越日数',
      accessor: 'lastCarryOverDaysNumStr',
    },
    {
      Header: '付与日数',
      accessor: 'grantDaysNumStr',
    },
    {
      Header: '前年消化日数',
      accessor: 'lastDigestedDaysNumStr',
    },
    {
      Header: '消滅日数',
      accessor: 'lastExtinctionDaysNumStr',
    },
    {
      Header: 'エラー内容',
      accessor: 'grantErrorMessage',
    },
  ], []);
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const accessLevel = sessionStorage.getItem('loginUser.accessLevel');
  const grandHistoryOrg = 2;

  const history = useHistory();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  return (
    <Accordion
      text="付与履歴"
      color={grayScale.gray100}
      callBack={() => {}}
      defaultExpand={!!((sessionStorage.getItem('attendHolidayManagement.accordion.paid.grandhistory') === String(true)))}
      accordionValue="paid.grandhistory"
    >
      <div>
        <div css={styles.container}>
          <FlexBox>
            <div css={styles.label}>
              <FormLabel
                label="付与日"
              />
            </div>
            <div css={styles.digestionDays}>
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                date={targetDateFrom}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateFrom(date);
                    sessionStorage.setItem('grantHistory.targetDateFrom', String(date));
                  }
                  sessionStorage.setItem('grantHistory.targetDateFrom', String(date));
                }}
                isFullWidth={true}
              />
            </div>
            <div css={styles.betweenDays}>
              ～
            </div>
            <div css={styles.digestionDays}>
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                date={targetDateTo}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateTo(date);
                    sessionStorage.setItem('grantHistory.targetDateTo', String(date));
                  }
                  sessionStorage.setItem('grantHistory.targetDateTo', String(date));
                }}
                isFullWidth={true}
              />
            </div>
          </FlexBox>
        </div>


        <OrganizationFilteredTree
          functionType={2}
          staffCode={staffCode}
          orgLabel="組織名"
          initOrgValue={String(orgCode)}
          orgCallback={(args: string | Array<string>) => {
            setOrgCode(String(args));
          }}
          targetdayForm={targetDateFrom}
          targetdayTo={targetDateTo}
          addAllItem={false}
          styles={{
            label: {
              toggleSwitch: css({ marginLeft: '0px' }),
            },
            toggle: {
              width: '13rem',
            },
          }}
          orgFilter={grandHistoryOrg}
        />
        <div css={styles.container}>
          <FlexBox>
            <div css={styles.label}>
              <FormLabel
                label="付与結果"
              />
            </div>
            <div css={styles.select}>
              <RadioSelectForm
                label=""
                items={arrGrantResult}
                name="targetType"
                value={String(paidGrantResult)}
                setValue={(e) => {
                  setPaidGrantResult(e.target.value);
                  sessionStorage.setItem('grantHistory.paidGrantResult', String(e.target.value));
                }}
              />
            </div>

          </FlexBox>
        </div>
        <div
          css={css`
              table th:nth-child(1) {
                width: 140px;
              }
              table th:nth-child(2) {
                width: 100px;
              }
              table th:nth-child(3) {
                width: 140px;
              }
              table th:nth-child(4) {
                width: 100px;
              }
              table th:nth-child(5) {
                width: 100px;
              }
              table th:nth-child(6) {
                width: 100px;
              }
              table th:nth-child(7) {
                width: 100px;
              }
              table th:nth-child(8) {
                width: 100px;
              }
              table th:nth-child(9) {
                width: 100px;
              }
              table th:nth-child(10) {
                width: 200px;
              }
            `}
        >

          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <DataTable
              columns={columns}
              data={paidHolidayGrantHistoryList}
              isGlobalFilter={true}
              minWidth="1268px"
              sortBy={[
                { id: 'staffCode', desc: false }
              ]}
            />
          </div>
        </div>
        <FormSubmitArea>
          <div style={{ marginRight: '12px' }}>
            {checkUserRole && checkUserRole.downloadFlag === 1 && (
            <Button
              text="ダウンロード"
              onClick={() => downloadHolidayManagementGrantHistory()}
            />
            )}

          </div>
          {Number(accessLevel) === 1 && checkUserRole && checkUserRole.downloadFlag === 1 && (
            <Button
              text="全店舗ダウンロード "
              onClick={() => downloadHolidayManagementGrantHistory(true)}
            />
          )}

        </FormSubmitArea>
      </div>
    </Accordion>
  );
};
export default GrantHistoryForm;
