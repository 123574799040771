/** @jsx jsx */
/* eslint-disable max-len */
import React, { Dispatch, useState } from 'react';
import { Link } from 'react-router-dom';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button, { PrimaryButton } from 'components/atoms/Button';
import DatePickerForm from 'components/molecules/DatePickerForm';
import FormContents from 'components/atoms/Form/FormContents';
import FormTitle from 'components/atoms/Form/FormTitle';
import BodyText from 'components/atoms/BodyText';
import StaffDomain from 'domain/staff';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import { TablItem } from 'components/pages/master/general/staff/StaffAddPage/StaffAddPage';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import { OrganizationDataStaffDomain } from 'domain/master/general/organizationDataStaff';
import productColor, { iconColor, utilityColor } from 'components/styles';
import { getOrganizationInfo } from 'api/organization';
import moment from 'moment';
import OrganizationAddModal from '../OrganizationAddModal';
import { useAffiliationForm } from './hooks';
import OrganizationManagerEdit from '../OrganizationAddModal/OrganizationManagerEdit';
import { InfoMessage } from 'utility/messages';
import ClickableLink from 'components/atoms/ClickableLink';
import Icon from 'components/atoms/Icon';
import { css, jsx } from '@emotion/core';
import IconButton from 'components/molecules/IconButton';

type SelectType = 'staff'|'affiliation'|'permission'|'employment'|'laborCost'|'workPattern';
const SelectType: {[key in SelectType]: SelectType} = {
  staff: 'staff',
  affiliation: 'affiliation',
  permission: 'permission',
  employment: 'employment',
  laborCost: 'laborCost',
  workPattern: 'workPattern',
};

const AffiliationInfoForm: React.FC<{
  initialStaffDomain?: StaffDomain;
  isEditMode?: boolean;
  tabItems: TablItem[];
  setInitialStaffDomain: Dispatch<React.SetStateAction<StaffDomain>>;
  setSelectType: Dispatch<React.SetStateAction<SelectType>>;
  setTabItems: Dispatch<React.SetStateAction<TablItem[]>>;
  setOrgTargetDate?: React.Dispatch<React.SetStateAction<Date>>;
  setOrgApplyDate?: React.Dispatch<React.SetStateAction<Date>>;
  organizationOptions: Array<any>;
  executiveOptions: Array<any>;
  setIsLoading: Dispatch<React.SetStateAction<boolean>>;
  menuType: string;
  roleScreen?: any;
  isEditingLoginUser: boolean;
}> = ({
  initialStaffDomain, isEditMode = false, tabItems,
  setInitialStaffDomain, setSelectType, setTabItems,
  setOrgTargetDate,
  setOrgApplyDate,
  organizationOptions, executiveOptions,
  setIsLoading,
  menuType,
  roleScreen,
  isEditingLoginUser,
}) => {
  const {
    formik,
    confirmModalOpen, closeConfirmModal,
    openManagerAddModal,
    setOpenManagerAddModal,
    addManager,
    deleteOrganizationManager,
    setOrganizationManager,
    // getSelectedOrganizationInfo,
    onSubmitDeleteStaffOrganization,
    setDeleteTargetStaffOrganization,
    isOpenDeleteModal,
    closeDeleteModal,
  } = useAffiliationForm(
    initialStaffDomain,
    isEditMode,
    setIsLoading,
    menuType,
    isEditingLoginUser,
  );


  const columns: Array<Column<OrganizationDataStaffDomain>> = React.useMemo(() => [
    {
      Header: '異動日',
      accessor: 'applyStartDateStr',
    },
    {
      Header: '組織コード',
      accessor: 'orgCode',
    },
    {
      Header: '組織名',
      accessor: 'orgName',
    },
    {
      Header: '役職コード',
      accessor: 'executiveCode',
    },

    {
      Header: '役職名',
      accessor: 'executiveName',
    },
    {
      Header: () => null,
      accessor: 'delete',
      id: 'deleteButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: OrganizationDataStaffDomain, index: number }}) => {
        const lastIndex = formik.values.organizationDataList?.length - 1 ?? 0;
        return (<React.Fragment>
            {(formik.values.organizationDataList && formik.values.organizationDataList.length > 1
              && cell.row.index === lastIndex && roleScreen && roleScreen.editable === 1) && (
                <IconButton
                  iconType="delete"
                  onClick={() => setDeleteTargetStaffOrganization(cell.row.original)}
                  iconColor={iconColor.red}
                  iconHoverColor={utilityColor.white}
                  linkHoverColor={productColor.deleteActionHover}
                />
            )}
          </React.Fragment>);
      },
    },

  ], [formik, roleScreen]);

  return (
    <React.Fragment>
      {formik.values.organizationDataList
      && formik.values.organizationDataList.length !== 0 && (
        <div css={css`
            table th:nth-child(6) {
              width: 42px;
            }
          `}
        >
          <DataTable
            columns={columns}
            data={formik.values.organizationDataDomainList}
            sortBy={[
              {id: 'applyStartDateStr', desc: true}
            ]}
          />
        </div>
      )}

      <form onSubmit={formik.handleSubmit}>
        <FormContents>
          <FormField>
            <DatePickerForm
              dateFormat="yyyy/MM/dd"
              label="異動日"
              date={formik.values.orgApplyDate}
              changeDate={(date: Date) => {
                if (date !== null) {
                  if (setOrgTargetDate) {
                    setOrgTargetDate(date);
                  }
                  formik.setFieldValue('orgApplyDate', date);
                  if (setOrgApplyDate) {
                    setOrgApplyDate(date);
                  }
                }
              }}
              required={true}
              errorMsg={formik.errors.orgApplyDate}
              disable={!isEditMode}
              isShowDateOnDisable={true}
            />
          </FormField>

          <FormField displayBlock={true}>
            <VerticalLabelSelectForm
              label="組織"
              name="organization"
              value={formik.values.orgCode}
              setValue={(val: string) => {
                formik.values.orgCode = val;

                formik.setFieldValue('orgCode', val);
                setInitialStaffDomain(formik.values);
              }}
              options={organizationOptions}
              note={isEditMode ? ' 組織を変更すると、シフトパターン設定が削除され、再登録が必要となります。' : ''}
              errorMsg={formik.errors.orgCode}
              required={true}
            />
          </FormField>

          <FormField displayBlock={true}>
            <VerticalLabelSelectForm
              label="役職"
              name="executiveId"
              value={formik.values.executiveId}
              setValue={(val: string) => { formik.setFieldValue('executiveId', val); }}
              options={executiveOptions}
            />
          </FormField>
        </FormContents>

        {menuType !== '1' && (
        <FormContents>
          <FormTitle
            title="マネージャー権限を設定"
          />
          <BodyText color={utilityColor.error}>店舗勤怠責任者の場合、マネージャー権限を設定してください。</BodyText>
          {formik.values.managerDomainList ? formik.values.managerDomainList.map((manager, index) => (
            <OrganizationManagerEdit
              key={index.toString()}
              manager={manager}
              deleteManager={deleteOrganizationManager(index)}
              setManager={setOrganizationManager(index)}
            />
          )) : <BodyText>Loading...</BodyText>}

          <div>
            <PrimaryButton
              text="マネージャー権限追加"
              onClick={() => {
                setOpenManagerAddModal(true);
                // setCategoryAr([]);
              }}
              fullwidth={false}
            />
          </div>
          <FormField>
            <OrganizationAddModal
              open={openManagerAddModal}
              openHandler={setOpenManagerAddModal}
              addOrganization={addManager}
            />
          </FormField>

        </FormContents>
        )}

        <FormSubmitArea>
          <div style={{ marginRight: '12px' }}>
            { roleScreen && roleScreen.editable === 1 && isEditMode && (
            <Button
              text="所属情報を登録"
              onClick={formik.handleSubmit}
            />
            )}
          </div>
          {roleScreen && roleScreen.editable === 1 && !isEditMode && (
            <div style={{ marginRight: '12px' }}>
              <Button
                text="権限設定の入力へ"
                onClick={() => {
                  if (formik.values.orgApplyDate && formik.values.orgCode) {
                    formik.values.empApplyDate = formik.values.hireDate;
                    getOrganizationInfo(formik.values.orgCode, moment(formik.values.hireDate).format('YYYY-MM-DD')).then((response: any) => {
                      if (response.employmentId) {
                        formik.setFieldValue('employmentId', response.employmentId);
                      } else {
                        formik.setFieldValue('employmentId', '');
                      }

                      if (tabItems.length <= 2) {
                        setInitialStaffDomain(formik.values);
                        setTabItems([
                          {
                            value: SelectType.staff,
                            display: 'スタッフ情報',
                          },
                          {
                            value: SelectType.affiliation,
                            display: '所属設定',
                          },
                          {
                            value: SelectType.permission,
                            display: '権限設定',
                          }]);
                      }
                      setSelectType(SelectType.permission);
                    });
                  } else {
                    formik.validateForm();
                  }
                }}
              />
            </div>
          )}

          {menuType !== '1' && (
            <Link to="/staffs">
              <Button
                text="スタッフ一覧に戻る"
                ghost={true}
              />
            </Link>
          )}
          {menuType === '1' && (
            <Link to="/staffs/employee">
              <Button
                text="スタッフ一覧に戻る"
                ghost={true}
              />
            </Link>
          )}
        </FormSubmitArea>
      </form>

      {/* 登録内容確認モーダル */}
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
        message={isEditMode ? InfoMessage.StaffMaster.UPDATE_CONFIRM_MESSAGE_AFFILIATION_INFO : ''}
      />

      <ConfirmActionModal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        onSubmit={onSubmitDeleteStaffOrganization}
        actionType={ActionType.DELETE}
      />

    </React.Fragment>
  );
};

export default AffiliationInfoForm;
