/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import Icon from 'components/atoms/Icon';
import { Link } from 'react-router-dom';


const DashboardAnalysticList: React.FC<{ dataList?: any }> = ({ dataList }) => (
    <React.Fragment>

        {
            dataList && dataList.categoryList.length > 0 ? dataList.categoryList.map((item: any, index: number) => {
                return (

                    <FlexBox customStyle={(index === dataList.categoryList.length - 1) ? css({ borderTop: '1px solid #DDDDDD', borderBottom: '1px solid #DDDDDD' }) : css({ borderTop: '1px solid #DDDDDD' })}>
                        <FlexBoxItem basis='100%' customStyle={css({ padding: '10px 24px' })}>
                            <FormLabel label={item.categoryDispName} />
                        </FlexBoxItem>
                        <Link to={{ pathname: `/dashboard/${item.organizationCategoryMstHeadId}` }} onClick={() => { sessionStorage.setItem('categoryDispName', item.categoryDispName) }}>
                            <FlexBoxItem basis='40px' customStyle={css({ padding: '10px 15px' })}>
                                <Icon type="chevronRight" color='#0D4796' />
                            </FlexBoxItem>
                        </Link>
                    </FlexBox>

                );
            }) : null
        }

    </React.Fragment>
);

export default DashboardAnalysticList;
