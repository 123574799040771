import { useState, useMemo, useCallback, useEffect } from 'react';

import { getListRoleMaster, deleteRoleMaster, downloadRoleMasterExcel } from 'api/roleMaster';
import AuthorityDomain from './type';
import useToastNotification from 'hooks/useToastNotification';

export const useAuthorityMasterList = () => {
  const [blocking, setBlocking] = useState(false);
  const [authorityMasterList, setAuthorityMasterList] = useState<Array<AuthorityDomain>>([]);
  
  const fetchData = useCallback(async () => {
    setBlocking(true);
    await getListRoleMaster().then((response: any) => {
      setAuthorityMasterList(response);
    });

    setBlocking(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    authorityMasterList, blocking, fetchData
  }
}

export const useDelete = (fetchData: any) => {
  const [deleteTarget, setDeleteTarget] = useState<any>(null);
  const { successNotification, errorNotification } = useToastNotification();

  const isOpenDeleteModal = useMemo(() => !!deleteTarget, [deleteTarget]);

  const closeDeleteModal = useCallback(() => setDeleteTarget(null), []);

  const onSubmitDelete = useCallback(() => {
    if (!deleteTarget) {
      return;
    }
    deleteRoleMaster(deleteTarget.roleId).then(() => {
      setDeleteTarget(null);
      successNotification('削除しました。');
      // 削除後にデータを再取得
      fetchData();
    }).catch((error) => {
      setDeleteTarget(null);
      if (error.response && error.response.data && error.response.data.defaultMessage) {
        let msgError = error.response.data.defaultMessage;
        errorNotification(msgError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [deleteTarget, errorNotification, successNotification]);

  return {
    deleteTarget,
    setDeleteTarget,
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDelete,
  };
};


export const useDownload = () => {
  const downloadExcel = () => {
    downloadRoleMasterExcel('権限マスタ.xlsx')
  }

  return {
    downloadExcel
  };
};

export default {};
