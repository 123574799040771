import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';

import { OptionType } from 'components/atoms/Select';
import moment from 'moment';

const listHours = [
  '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
  '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
  '20', '21', '22', '23',
];


export interface ShiftPatternDetailInput {
  createUser: string,
  updateUser: string,
  shiftPatternDetailId: string,
  shiftPatternId: string,
  businessId: string,
  startTime: string,
  isStartTimeNextDay: boolean,
  endTime: string,
  isEndTimeNextDay: boolean,
  stampId?: string,
}

export interface ShiftPatternDetail {
    shiftPatternDetailId: string,
    shiftPatternId: string,
    businessId: string,
    startHourForm: string,
    startTimeForm: string,
    isStartTimeNextDay: boolean,
    endHourForm: string,
    endTimeForm: string,
    isEndTimeNextDay: boolean,
    createUser: string,
    updateUser: string,
}

export const useShiftPatternDetailDomainForm = (
  listTimes: any,
  shiftPatternDetailsList: any,
  setShiftPatternDetailsList: Dispatch<SetStateAction<Array<ShiftPatternDetailInput>>>,
  attendBusinessOptions: any,
  isCreate: boolean,
) => {
  const [hourFormOptions, setHourFormOptions] = useState<Array<OptionType>>([]);
  const [timeFormOptions, setTimeFormOptions] = useState<Array<OptionType>>([]);
  const [detailList, setDetailList] = useState<Array<ShiftPatternDetail>>([{
    shiftPatternDetailId: '',
    shiftPatternId: '',
    businessId: '',
    startHourForm: '00',
    startTimeForm: '00',
    isStartTimeNextDay: false,
    endHourForm: '00',
    endTimeForm: '00',
    isEndTimeNextDay: false,
    createUser: '',
    updateUser: '',
  }]);

  // データの取得
  const fetchData = useCallback(async () => {
    let defaultBusinessId = '';
    if (attendBusinessOptions.length) {
      defaultBusinessId = attendBusinessOptions[0].value;
    }

    if (shiftPatternDetailsList?.length) {
      setDetailList(shiftPatternDetailsList.map(
        (shiftPatternDetail: ShiftPatternDetailInput) => {
          let businessId = '';
          if (!shiftPatternDetail.businessId) {
            businessId = defaultBusinessId;
          } else {
            //
            const issetBusinessOption = attendBusinessOptions.filter((businessOption: any) => {
              const { value } = businessOption;
              if (value === shiftPatternDetail.businessId) {
                return true;
              }
              return false;
            });
            //
            if (!issetBusinessOption.length) {
              businessId = defaultBusinessId;
            } else {
              businessId = shiftPatternDetail.businessId;
            }
          }

          const { stampId } = shiftPatternDetail;
          let dateFormat = 'HH:mm:ss';
          if (stampId) {
            dateFormat = 'YYYY-MM-DD HH:mm:ss';
          }

          return {
            shiftPatternDetailId: isCreate === true ? '' : shiftPatternDetail.shiftPatternDetailId,
            shiftPatternId: isCreate === true ? '' : shiftPatternDetail.shiftPatternId,
            businessId,
            startHourForm: moment(shiftPatternDetail.startTime, `${dateFormat}`).format('HH'),
            startTimeForm: moment(shiftPatternDetail.startTime, `${dateFormat}`).format('mm'),
            isStartTimeNextDay: shiftPatternDetail.isStartTimeNextDay,
            endHourForm: moment(shiftPatternDetail.endTime, `${dateFormat}`).format('HH'),
            endTimeForm: moment(shiftPatternDetail.endTime, `${dateFormat}`).format('mm'),
            isEndTimeNextDay: shiftPatternDetail.isEndTimeNextDay,
            createUser: shiftPatternDetail.createUser,
            updateUser: shiftPatternDetail.createUser,
          };
        }
        ,
      ));
    } else {
      setDetailList([
        {
          shiftPatternDetailId: '',
          shiftPatternId: '',
          businessId: defaultBusinessId,
          startHourForm: '00',
          startTimeForm: '00',
          isStartTimeNextDay: false,
          endHourForm: '00',
          endTimeForm: '00',
          isEndTimeNextDay: false,
          createUser: '',
          updateUser: '',
        },
      ]);
      convertDataOuput();
    }
    //
    setHourFormOptions(listHours.map((Hours) => ({
      value: Hours,
      label: Hours,
    })));

    //
    setTimeFormOptions(listTimes.map((Hours: any) => ({
      value: Hours,
      label: Hours,
    })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listTimes, attendBusinessOptions, shiftPatternDetailsList]);

  const convertDataOuput = useCallback(async (items?: any) => {
    if (items) {
      setShiftPatternDetailsList(items.map(
        (shiftPatternDetail: ShiftPatternDetail) => ({
          createUser: shiftPatternDetail.createUser,
          updateUser: shiftPatternDetail.updateUser,
          shiftPatternDetailId: isCreate === true ? '' : shiftPatternDetail.shiftPatternDetailId,
          shiftPatternId: isCreate === true ? '' : shiftPatternDetail.shiftPatternId,
          businessId: shiftPatternDetail.businessId,
          startTime: `${shiftPatternDetail.startHourForm}:${shiftPatternDetail.startTimeForm}`,
          isStartTimeNextDay: shiftPatternDetail.isStartTimeNextDay,
          endTime: `${shiftPatternDetail.endHourForm}:${shiftPatternDetail.endTimeForm}`,
          isEndTimeNextDay: shiftPatternDetail.isEndTimeNextDay,
        })
        ,
      ));
    } else {
      setShiftPatternDetailsList(detailList.map(
        (shiftPatternDetail: ShiftPatternDetail) => ({
          createUser: shiftPatternDetail.createUser,
          updateUser: shiftPatternDetail.updateUser,
          shiftPatternDetailId: isCreate === true ? '' : shiftPatternDetail.shiftPatternDetailId,
          shiftPatternId: isCreate === true ? '' : shiftPatternDetail.shiftPatternId,
          businessId: shiftPatternDetail.businessId,
          startTime: `${shiftPatternDetail.startHourForm}:${shiftPatternDetail.startTimeForm}`,
          isStartTimeNextDay: shiftPatternDetail.isStartTimeNextDay,
          endTime: `${shiftPatternDetail.endHourForm}:${shiftPatternDetail.endTimeForm}`,
          isEndTimeNextDay: shiftPatternDetail.isEndTimeNextDay,
        })
        ,
      ));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailList]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    timeFormOptions,
    hourFormOptions,
    detailList,
    setDetailList,
    convertDataOuput,
  };
};


export default {
  useShiftPatternDetailDomainForm,
};
