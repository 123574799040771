import React from 'react';
import { Link } from 'react-router-dom';
import DatePickerForm from 'components/molecules/DatePickerForm';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import BlockUI from 'components/molecules/BlockUi';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Checkbox from 'components/molecules/Checkbox';
import { css } from '@emotion/core';
import { useSalesSuppliersForm } from './hook';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const SalesSuppliersInputForm: React.FC<{}> = () => {
  const {
    blocking,
    transactionType,
    setTransactionType,
    suppliersCode,
    setSuppliersCode,
    suppliersName,
    setSuppliersName,
    dispOrder,
    setDispOrder,
    applyStartDate,
    setApplyStartDate,
    applyEndDate,
    setApplyEndDate,
    undecided,
    setUndecided,
    suppliersCodeId,
    transaction,
    confirmModalOpen,
    closeConfirmModal,
    openConfirmModal,
    handleSubmit,
  } = useSalesSuppliersForm();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <BlockUI blocking={blocking}>
      <FormContents>
        <p>
          {`${!suppliersCodeId ? '取引先を登録します。コード、名称、並び順、適用開始日を入力してください。' : ''}`}
        </p>
        <FormField>
          <VerticalLabelSelectForm
            label="取引タイプ"
            name="transactionType"
            value={String(transactionType)}
            setValue={(v: string) => {
              setTransactionType(v);
            }}
            required={true}
            disable={!!transaction}
            options={[{ value: '0', label: 'フード' }, { value: '1', label: 'ドリンク' }, { value: '2', label: 'その他' }]}
          />
        </FormField>
        <FormField>
          <TextForm
            name="suppliersCode"
            label="コード"
            value={suppliersCode}
            required={true}
            disable={!!suppliersCodeId}
            onChange={
              (e) => { setSuppliersCode(e.target.value); }
            }
          />
        </FormField>
        <FormField>
          <TextForm
            name="SuppliersName"
            label="名称"
            value={suppliersName}
            required={true}
            onChange={
              (e) => { setSuppliersName(e.target.value); }
            }
          />
        </FormField>
        <FormField>
          <TextForm
            type="number"
            name="dispOrder"
            label="並び順"
            value={String(dispOrder)}
            required={true}
            onChange={
              (e) => { setDispOrder(Number(e.target.value)); }
            }
          />
        </FormField>
        <FormField>
          <DatePickerForm
            required={true}
            dateFormat="yyyy年MM月"
            label="適用開始月"
            isFullWidth={true}
            date={applyStartDate}
            showMonthYearPicker={true}
            changeDate={(date: Date) => {
              if (date !== null) {
                setApplyStartDate(date);
              }
            }}
          />
        </FormField>
        <FormField>
          <FlexBox>
            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月"
                label="適用終了月"
                isFullWidth={true}
                date={applyEndDate}
                disable={undecided}
                showMonthYearPicker={true}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setApplyEndDate(date);
                  }
                }}
              />
            </FlexBoxItem>
            <FlexBoxItem customStyle={css({ paddingTop: '15px', marginLeft: '15px' })}>
              <Checkbox
                id="undecided"
                name="undecided"
                value=""
                label="未定"
                checked={undecided}
                onChange={(e) => {
                  setUndecided(!undecided);
                  if (e.target.checked) {
                    setApplyEndDate(undefined);
                  } else {
                    setApplyEndDate(new Date());
                  }
                }}
              />
            </FlexBoxItem>
          </FlexBox>
        </FormField>
        <FormSubmitArea>
          {
            roleScreen && roleScreen.editable === 1 && (
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton
                  text={`${suppliersCodeId ? '更新' : '登録'}`}
                  onClick={openConfirmModal}
                />
              </div>
            )
          }
          <Link to="/salesSuppliersMST">
            <PrimaryButton text="戻る" ghost={true} />
          </Link>
        </FormSubmitArea>

        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={() => handleSubmit()}
          actionType={suppliersCodeId ? ActionType.UPDATE : ActionType.CREATE}
        />
      </FormContents>
    </BlockUI>
  );
};

export default SalesSuppliersInputForm;
