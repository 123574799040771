import React from 'react';

import FormContents from 'components/atoms/Form/FormContents';
import { Terms } from 'domain/master/attend/terms';
import EmploymentBasicSettings from './EmploymentBasicSettings';
import EmploymentWorkSystemAndBreakSettings from './EmploymentWorkSystemAndBreakSettings';
import EmploymentWorkTimeRoundingSettings from './EmploymentWorkTimeRoundingSettings';
import EmploymentVacationSettings from './EmploymentVacationSettings';
import EmploymentOvertimeSettings from './EmploymentOvertimeSettings';
import EmploymentOtherSettings from './EmploymentOtherSettings';

const EmploymentInputAddForm: React.FC<{
  formik: any,
  termMst: Array<Terms>,
}> = ({
  formik,
  termMst,
}) => (
  <div>
    <FormContents>
      <EmploymentBasicSettings formik={formik} />
    </FormContents>

    <FormContents>
      <EmploymentWorkSystemAndBreakSettings formik={formik} />
    </FormContents>

    <FormContents>
      <EmploymentWorkTimeRoundingSettings formik={formik} />
    </FormContents>

    <FormContents>
      <EmploymentOvertimeSettings formik={formik} />
    </FormContents>

    <FormContents>
      <EmploymentVacationSettings formik={formik} />
    </FormContents>

    <FormContents>
      <EmploymentOtherSettings formik={formik} termMst={termMst} />
    </FormContents>


  </div>
);

export default EmploymentInputAddForm;
