import React from 'react';

import StampAccordion from './StampAccordion';
import HolidayAccordion from './HolidayAccordion';
import HolidayWorkAccordion from './HolidayWorkAccordion';
import OvertimeAccordion from './OvertimeAccordion';
import TransferAccordion from './TransferAccordion';

import RejectedStampDomain from '../domain/rejectedStampDomain';
import RejectedHolidayDomain from '../domain/rejectedHolidayDomain';
import RejectedOvertimeDomain from '../domain/rejectedOvertimeDomain';
import RejectedHolidayWorkDomain from '../domain/rejectedHolidayWorkDomain';
import RejectedTransferDomain from '../domain/rejectedTransferDomain';


const RejectedTab: React.FC<{
  stampList: RejectedStampDomain[]
  holidayList: RejectedHolidayDomain[]
  overtimeList: RejectedOvertimeDomain[]
  holidayWorkList: RejectedHolidayWorkDomain[]
  transferList: RejectedTransferDomain[]
}> = ({
  stampList,
  holidayList,
  overtimeList,
  holidayWorkList,
  transferList,
}) => (
  <div>
    <StampAccordion list={stampList} />

    <HolidayAccordion list={holidayList} />

    <OvertimeAccordion list={overtimeList} />

    <HolidayWorkAccordion list={holidayWorkList} />

    <TransferAccordion list={transferList} />
  </div>
);

export default RejectedTab;
