
export const formatNumberMinus = (number: number) => {
  if (Number(number) <= 0 && Number(number) > -1) {
    return Math.abs(number);
  }
  return number;
}
export const formatNumberFormatterMinus = (number: string) => {
  if (number === '-0') {
    return number.replace(/[/-]/g, '');
  }
  return number;
}
export const formatPercentMinus = (percent: string) => {
  if(percent && percent.indexOf('-0%') !== -1){
    return percent.replace(/[/-]/g, '')
  }else{
     return percent
  }
}
export const sumTotalOfArray = (arr:Array<number | null | boolean>)=>{
  return arr && arr.length >0 ? arr.reduce(
    (acc, value) => {
      return Number(acc) + (!isNaN(Number(value)) ? Number(value) : 0);
    },
    0
  ) : 0
}

export const removeComma = (number: any) => {
  const tmp = String(number).replace(/[\,]/g, '');
  if (!isNaN(Number(tmp))) {
    if(tmp == '-0'){
      return tmp
    }else{
      return Number(tmp);
    }
  }
  return number === '-' || number === '-0' ? number : 0;
};
export const formatter = new Intl.NumberFormat("ja-JP", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});
export const numberFormaterInput = (num: any) => {
  if (typeof num === "undefined" || isNaN(num)) {
    return num;
  }
  const tmp = String(num).split(".");
  if (tmp[1]) {
    let fixed = 3;
    if (tmp[1].length < 3) {
      fixed = tmp[1].length;
    }
    return parseFloat(num).toFixed(fixed);
  }
  return formatter.format(num);
};
export const numberFormater = (num: any) => {
  if (typeof num === "undefined" || isNaN(num) || !isFinite(num)) {
    return 0;
  }
  return num;
};

export const getRatioLabel = (numerator: any, denominator: any) => {
  let result = 0;
  if (typeof numerator === "undefined" || typeof denominator === "undefined") {
    result = 0;
  } else if (isNaN(numerator) || isNaN(denominator)) {
    result = 0;
  } else if (numerator === 0 || denominator === 0) {
    result = 0;
  } else {
    result = numerator / denominator;
  }
  return Number(result);
};
export const formatterPercent = new Intl.NumberFormat("ja-JP", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  style: "percent"
});

export const formatDevision = (numerator: any, denominator: any) => {
  let result = 0;
  if (typeof numerator === 'undefined' || typeof denominator === 'undefined') {
    result = 0;
  } else if (isNaN(numerator) || isNaN(denominator)) {
    result = 0;
  } else if (numerator === 0 || denominator === 0) {
    result = 0;
  } else {
    result = numerator / denominator;
  }
  return Number(result);
};

export const getDivision = (numerator: any, denominator: any, fixed?: any) => {
  let rsNumber = 0;
  let tmp: Array<any> = [];
  if (String(numerator).indexOf('%') !== -1) {
    tmp = String(numerator).split('%');
    // eslint-disable-next-line no-param-reassign
    numerator = tmp[0] / 100;
  }
  if (String(denominator).indexOf('%') !== -1) {
    tmp = String(denominator).split('%');
    // eslint-disable-next-line no-param-reassign
    denominator = tmp[0] / 100;
  }
  if (typeof numerator === 'undefined' || typeof denominator === 'undefined') {
    rsNumber = 0;
  } else if (isNaN(numerator) || isNaN(denominator)) {
    rsNumber = 0;
  } else if (numerator === 0 || denominator === 0) {
    rsNumber = 0;
  } else {
    rsNumber = numerator / denominator;
  }
  if (typeof fixed === 'undefined') {
    tmp = String(rsNumber).split('.');
    fixed = 0;
    if (tmp[1] && tmp[1].length >= 3) {
      fixed = 3;
    } else if (tmp[1]) {
      fixed = tmp[1].length;
    }
  }
  return Number(parseFloat(String(rsNumber)).toFixed(fixed));
};