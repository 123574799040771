import { useEffect, useState, useCallback } from 'react';
import { getPeriodSales } from 'api/periodSales';
import moment from 'moment';

const usePeriodSalesForm = () => {
  const [operationReport, setOperationReport] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const userOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [selectOrgCode, setSelectOrgCode] = useState(userOrgCode);
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);
  const [searchTime, setSearchTime] = useState(new Date());
  const [targetDateFrom, setTargetDateFrom] = useState(new Date(moment().startOf('month').format('YYYY-MM-DD')));
  const [targetDateTo, setTargetDateTo] = useState(new Date());

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getPeriodSales(selectOrgCode, targetDateFrom, targetDateTo).then((response: any) => {
      setOperationReport(response);
      setSearchTime(new Date());
    }).catch((error: any) => {
      setOperationReport([]);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [selectOrgCode, targetDateFrom, targetDateTo]);

  useEffect(() => {
    fetchData();
  }, [fetchData, selectOrgCode, targetDateFrom]);

  return {
    categoryAry,
    setCategoryAry,
    operationReport,
    isLoading,
    setIsLoading,
    fetchData,
    selectOrgCode,
    setSelectOrgCode,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    searchTime,
    setSearchTime,
  };
};

export default usePeriodSalesForm;
