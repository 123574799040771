
import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import { useParams } from 'react-router-dom';
import AdvertisingCreateForm from '../AdvertisingCreateForm/AdvertisingCreateForm';

const AdvertisingCreatePage: React.FC = () => {
  const { advertisingPlanMstCode } = useParams();

  return (
    <SidebarTemplate pageTitle={advertisingPlanMstCode ? '広告プラン編集' : '広告プラン作成'}>
      <AdvertisingCreateForm />
    </SidebarTemplate>
  );
};

export default AdvertisingCreatePage;
