import SalesSlipForm from 'components/organismos/master/sales/SalesSlipForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';

const SalesSlipPage = () => (
  <SidebarTemplate pageTitle="レシート別売上登録">
    <SalesSlipForm />
  </SidebarTemplate>
);

export default SalesSlipPage;
