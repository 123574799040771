/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import moment from 'moment';
import { isMacOs } from "react-device-detect";
import { numberFormater } from './format';
import TextTooltip from '../component/TextTooltip';

const InboundStatementListTable: React.FC<{
    listSalesManagement: any;
    orgCode: any;
    orgName: any;
  }> = ({
    listSalesManagement,
    orgCode,
    orgName
  }) =>{
    return  (
      <div>
          <div style={{ margin: '15px' }}>
          <div className="templateTable newDesignTable" style={{ height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: isMacOs ? '35px' : '1px' }}>
            <table className="table table-bordered text-nowrap" id="wrapTblRight">
              <thead>
                <tr>
                  <th style={{ top: '0', maxWidth: '100px', width: '150px', minWidth: '100px' }} className="text-center stickyStyle">店舗CD</th>
                  <th style={{ top: '0', maxWidth: '150px', width: '150px', minWidth: '150px' }} className="text-center stickyStyle">店舗名</th>
                  <th style={{ top: '0', maxWidth: '150px', width: '150px', minWidth: '150px' }} className="text-center stickyStyle">営業日</th>
                  <th style={{ top: '0', maxWidth: '50px', width: '50px', minWidth: '50px' }} className="text-center stickyStyle">曜日</th>
                  <th style={{ top: '0' }} className="text-center stickyStyle">売上区分</th>
                  <th style={{ top: '0' }} className="text-center stickyStyle">広告店舗名</th>
                  <th style={{ top: '0' }} className="text-center stickyStyle">媒体名</th>
                  <th style={{ top: '0', maxWidth: '50px', width: '50px', minWidth: '50px' }} className="text-center stickyStyle">組数</th>
                  <th style={{ top: '0', maxWidth: '50px', width: '50px', minWidth: '50px' }} className="text-center stickyStyle">客数</th>
                  <th style={{ top: '0' }} className="text-center stickyStyle">売上高（税込）</th>
                  <th style={{ top: '0' }} className="text-center stickyStyle">売上高（税抜）</th>
                </tr>
              </thead>
              <tbody>
                {listSalesManagement && listSalesManagement.length > 0 ? listSalesManagement.map((item: any, index: any) => (
                  <tr key={index}>
                    <td className="text-center" style={{ top: '0', maxWidth: '100px', width: '100px', minWidth: '100px' }}>{item.orgCode ? item.orgCode : orgCode}</td>
                    <td className="text-center" style={{ top: '0', maxWidth: '150px', width: '150px', minWidth: '150px' }}>{item.orgName ? item.orgName : orgName}</td>
                    <td className="text-center" style={{ top: '0', maxWidth: '150px', width: '150px', minWidth: '150px' }}>{item.targetDate ? moment(item.targetDate).format("YYYY/MM/DD") : "合計"}</td>
                    <td className="text-center" style={{ top: '0', maxWidth: '50px', width: '50px', minWidth: '50px' }}>{item.weekName}</td>
                    <td className="text-left">
                      <TextTooltip 
                        id={`segmentName${item.id}`}
                        text={item.segmentName}
                      />
                    </td>
                    <td className="text-left">
                      <TextTooltip 
                        id={`advertisingName${item.id}`}
                        text={item.advertisingName}
                      />
                    </td>
                    <td className="text-left">
                    <TextTooltip 
                        id={`advertisingMediaMstName${item.id}`}
                        text={item.advertisingMediaMstName}
                      />
                    </td>
                    <td className="text-right" style={{ top: '0', maxWidth: '50px', width: '50px', minWidth: '50px' }}>{numberFormater(item.customers)}</td>
                    <td className="text-right" style={{ top: '0', maxWidth: '50px', width: '50px', minWidth: '50px' }}>{numberFormater(item.guestCnt)}</td>
                    <td className="text-right">{numberFormater(item.totalSales)}</td>
                    <td className="text-right">{numberFormater(item.netSales)}</td>
                  </tr>
                ))
                  : <tr>
                    <td colSpan={16} style={{ left: 0 }} className="text-center stickyStyle fristColSticky">データはありません。</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>  
   );
  }
export default InboundStatementListTable;