/**
 * APIのデータ型
 */
export interface PaidHolidayPattern {
  companyCode: string;
  createUser: string;
  updateUser: string;
  paidHolidayPatternId: string;
  paidHolidayPatternCode: string;
	paidHolidayPatternName: string;
  grantStartMonths: number;
}

export default class PaidHolidayPatternDomain {
  constructor(private rawData: PaidHolidayPattern) {
    // do nothing
  }

  static generateInitial(): PaidHolidayPatternDomain {
    return new PaidHolidayPatternDomain({
      companyCode: '',
      createUser: '',
      updateUser: '',
      paidHolidayPatternId: '',
      paidHolidayPatternCode: '',
      paidHolidayPatternName: '',
      grantStartMonths: 0,
    });
  }

  getRawData(): PaidHolidayPattern {
    return this.rawData;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = String(companyCode);
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set paidHolidayPatternId(paidHolidayPatternId: string) {
    this.rawData.paidHolidayPatternId = String(paidHolidayPatternId);
  }

  get paidHolidayPatternId(): string {
    return this.rawData.paidHolidayPatternId;
  }

  set paidHolidayPatternName(paidHolidayPatternName: string) {
    this.rawData.paidHolidayPatternName = String(paidHolidayPatternName);
  }

  get paidHolidayPatternName(): string {
    return this.rawData.paidHolidayPatternName;
  }

  set paidHolidayPatternCode(paidHolidayPatternCode: string) {
    this.rawData.paidHolidayPatternCode = String(paidHolidayPatternCode);
  }

  get paidHolidayPatternCode(): string {
    return this.rawData.paidHolidayPatternCode;
  }

  get createUser():string {
    return this.rawData.createUser;
  }

  set createUser(createUser:string) {
    this.rawData.createUser = createUser;
  }


  get updateUser():string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser:string) {
    this.rawData.updateUser = updateUser;
  }

  get grantStartMonths(): number {
    return this.rawData.grantStartMonths;
  }

  set grantStartMonths(grantStartMonths: number) {
    this.rawData.grantStartMonths = grantStartMonths;
  }
}
