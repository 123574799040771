/** @jsx jsx */
import React, { ComponentProps } from 'react';
import { css, jsx } from '@emotion/core';

import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBox from 'components/atoms/FlexBox';
import FormDescription from 'components/atoms/Form/FormDescription';
import Select from 'components/atoms/Select';

const styles = {
  container: css({
    padding: '0',
  }),
  label: css({
    flexBasis: '140px',
  }),
  select: css({
    flexGrow: 4,
    minWidth: '300px',
  }),
  smallSelect: css({
    flexGrow: 4,
    minWidth: '200px',
  }),
  fullWidthLabel: css({
    flexBasis: '100%',
  })
};

/**
 * 選択式フォーム
 */
const SelectFormVirtical: React.FC<{
  label: string;
  description?: string;
  readOnly?: boolean;
  fullWidthLabel?: boolean;
  paddingTop?: number;
  paddingBottom?: number;
  smallSelect?: boolean;
} & ComponentProps<typeof Select> > = ({
  label, name, value, options, setValue, description, isMulti, readOnly = false, fullWidthLabel = false, paddingTop, paddingBottom, smallSelect
}) => (
  <div css={styles.container}>
    <FlexBox>
      <div style={{ paddingTop: paddingTop, paddingBottom: paddingBottom }} css={fullWidthLabel ? styles.fullWidthLabel : styles.label}>
        <FormLabel
          label={label}
        />
      </div>
    </FlexBox>
    <FlexBox>
      <div css={smallSelect ? styles.smallSelect : styles.select}>
        <Select
          name={name}
          value={value}
          setValue={setValue}
          options={options}
          isMulti={isMulti}
          readOnly={readOnly}
        />
      </div>
    </FlexBox>
    <FlexBox>
      <div css={styles.label} />
      <div css={smallSelect ? styles.smallSelect : styles.select}>
        {description
            && <FormDescription description={description} />}
      </div>
    </FlexBox>
  </div>
);

export default SelectFormVirtical;
