import { useState, useEffect, useCallback } from 'react';
import {
  getDaySeparateSalesStoreManagement,
  downloadCsv,
  downloadExcel,
} from 'api/daySeparateSalesStoreManagement';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import DaySeparateSalesStoreManagementDomain from 'domain/master/sales/daySeparateSalesStoreManagement';
import { listAdvertisingStatement } from './dataTemplate';
import mockData from './mockData.json';
import useToastNotification from 'hooks/useToastNotification';

export interface IDaySeparateSalesStoreManagementList {
  'targetDay': string,
  'row1_col1': number,
  'row1_col2': number,
  'row1_col3': number,
  'row2_col1': number,
  'row2_col2': number,
  'row2_col3': number,
  'row3_col1': number,
  'row3_col2': number,
  'row3_col3': number,
  'row4_col1': number,
  'row4_col2': number,
  'row4_col3': number,
  'row5_col1': number,
  'row5_col2': number,
  'row5_col3': number,
  'row6_col1': number,
  'row6_col2': number,
  'row6_col3': number,
  'row7_col1': number,
  'row7_col2': number,
  'row7_col3': number,
  'row8_col1': number,
  'row8_col2': number,
  'row8_col3': number,
  'row9_col1': number,
  'row9_col2': number,
  'row9_col3': number,
  'row10_col1': number,
  'row10_col2': number,
  'row10_col3': number,
  'row11_col1': number,
  'row11_col2': number,
  'row11_col3': number,
  'row12_col1': number,
  'row12_col2': number,
  'row12_col3': number,
  'row13_col1': number,
  'row13_col2': number,
  'row13_col3': number,
  'row14_col1': number,
  'row14_col2': number,
  'row14_col3': number,
  'row15_col1': number,
  'row15_col2': number,
  'row15_col3': number,
  'row16_col1': number,
  'row16_col2': number,
  'row16_col3': number,
  'row17_col1': number,
  'row17_col2': number,
  'row17_col3': number,
  'row18_col1': number,
  'row18_col2': number,
  'row18_col3': number,
  'row19_col1': number,
  'row19_col2': number,
  'row19_col3': number,
  'row20_col1': number,
  'row20_col2': number,
  'row20_col3': number,
  'row21_col1': number,
  'row21_col2': number,
  'row21_col3': number,
  'row22_col1': number,
  'row22_col2': number,
  'row22_col3': number,
  'row23_col1': number,
  'row23_col2': number,
  'row23_col3': number,
  'row24_col1': number,
  'row24_col2': number,
  'row24_col3': number,
  'row25_col1': number,
  'row25_col2': number,
  'row25_col3': number,
  'row26_col1': number,
  'row26_col2': number,
  'row26_col3': number,
  'row27_col1': number,
  'row27_col2': number,
  'row27_col3': number,
  'row28_col1': number,
  'row28_col2': number,
  'row28_col3': number,
  'row29_col1': number,
  'row29_col2': number,
  'row29_col3': number,
  'row30_col1': number,
  'row30_col2': number,
  'row30_col3': number,
  'row31_col1': number,
  'row31_col2': number,
  'row31_col3': number,
  'row32_col1': number,
  'row32_col2': number,
  'row32_col3': number,
  'row33_col1': number,
  'row33_col2': number,
  'row33_col3': number,
  'row34_col1': number,
  'row34_col2': number,
  'row34_col3': number,
  'row35_col1': number,
  'row35_col2': number,
  'row35_col3': number,
  'row36_col1': number,
  'row36_col2': number,
  'row36_col3': number,
  'row37_col1': number,
  'row37_col2': number,
  'row37_col3': number,
  'row38_col1': number,
  'row38_col2': number,
  'row38_col3': number,
  'row39_col1': number,
  'row39_col2': number,
  'row39_col3': number,
  'row40_col1': number,
  'row40_col2': number,
  'row40_col3': number,
  'row41_col1': number,
  'row41_col2': number,
  'row41_col3': number,
  'row42_col1': number,
  'row42_col2': number,
  'row42_col3': number,
  'row43_col1': number,
  'row43_col2': number,
  'row43_col3': number,
  'row44_col1': number,
  'row44_col2': number,
  'row44_col3': number,
  'row45_col1': number,
  'row45_col2': number,
  'row45_col3': number,
  'row46_col1': number,
  'row46_col2': number,
  'row46_col3': number,
  'row47_col1': number,
  'row47_col2': number,
  'row47_col3': number,
  'row48_col1': number,
  'row48_col2': number,
  'row48_col3': number,
  'row49_col1': number,
  'row49_col2': number,
  'row49_col3': number,
  'row50_col1': number,
  'row50_col2': number,
  'row50_col3': number,
  'row51_col1': number,
  'row51_col2': number,
  'row51_col3': number,
  'row52_col1': number,
  'row52_col2': number,
  'row52_col3': number,
  'row53_col1': number,
  'row53_col2': number,
  'row53_col3': number,
  'row54_col1': number,
  'row54_col2': number,
  'row54_col3': number,
  'row55_col1': number,
  'row55_col2': number,
  'row55_col3': number,
  'row56_col1': number,
  'row56_col2': number,
  'row56_col3': number,
  'row57_col1': number,
  'row57_col2': number,
  'row57_col3': number,
  'row58_col1': number,
  'row58_col2': number,
  'row58_col3': number,
  'row59_col1': number,
  'row59_col2': number,
  'row59_col3': number,
  'row60_col1': number,
  'row60_col2': number,
  'row60_col3': number
}

const useDaySeparateSalesStoreManagementList = () => {
  const { errorNotification } = useToastNotification();
  const functionType = 3;
  const params = useParams<any>();
  const monthSelect = params.targetDate || new Date();
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date(monthSelect));
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode'));
  const [orgName, setorgName] = useState(sessionStorage.getItem('loginUser.orgName') ? sessionStorage.getItem('loginUser.orgName') : '');
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);
  const [daySeparateSalesStoreManagementList, setDaySeparateSalesStoreManagementList] = useState<Array<IDaySeparateSalesStoreManagementList>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  // データの取得
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const params = {

    };
    let appendUrl = '';
    if (Array.isArray(categoryAry)) {
      categoryAry.forEach((cateArr: any, index: number) => {
        appendUrl += `&categoryAry=${cateArr.join(',')}`;
      });
    }
    try {
      const response = await getDaySeparateSalesStoreManagement(params, appendUrl);
      setDaySeparateSalesStoreManagementList(response.map((result: any) => new DaySeparateSalesStoreManagementDomain(result)));
      // setCommentList(listAdvertisingStatement);
    } catch (error) {
      setDaySeparateSalesStoreManagementList([]);
    }
    setIsLoading(false);
  }, [categoryAry]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  // useEffect(() => {
  //   setIsLoading(true);
  //   const num = moment(targetDateTo).valueOf() - moment(targetDateFrom).valueOf();
  //   const numOfDays = moment(num).valueOf() / 86_400_000;
  //   // console.log(numOfDays);

  //   const newDaySeparateSalesStoreManagementList: any[] = [];
  //   newDaySeparateSalesStoreManagementList.push({
  //     ...mockData,
  //     targetDay: `${moment(targetDateFrom).format('MM/DD')} - ${moment(targetDateTo).format('MM/DD')}(日)`,
  //   });
  //   for (let index = 0; index <= numOfDays; index++) {
  //     const targetDay = moment(targetDateFrom).valueOf() + (index * 86_400_000);
  //     newDaySeparateSalesStoreManagementList.push({
  //       ...mockData,
  //       targetDay: `${moment(targetDay).format('MM/DD')}(${moment(targetDay).format('ddd')})`,
  //     });
  //   }
  //   setDaySeparateSalesStoreManagementList(newDaySeparateSalesStoreManagementList);
  //   setIsLoading(false);
  // }, [targetDateFrom, targetDateTo]);

  const handleError = (error: any) => {
    if (error.response && error.response.data && error.response.data.errors) {
      let msgError = '';
      error.response.data.errors.map((item: { defaultMessage: string; }) => {
        msgError += `${item.defaultMessage} \n`;
        return msgError;
      });
      errorNotification(msgError);
    } else {
      errorNotification('サーバー側でエラーが発生しました。');
    }
  }

  const handleDownloadCsv = (fileName: string) => {
    let params = {
      orgCode: String(orgCode),
      monthlyStartDate: moment(selectedMonth).startOf('month').format('YYYY/MM/DD'),
      monthlyEndDate: moment(selectedMonth).endOf('month').format('YYYY/MM/DD'),
      loginStaffCode: loginStaffCode,
      functionType: functionType.toString()
    }
    const queryParameters = new URLSearchParams(params);
    const queryParameter = new URLSearchParams(categoryAry.map(s=>['categoryAry',s]));
    const queryString = categoryAry?.length > 0 ? `${queryParameters.toString()}&${queryParameter.toString()}` : queryParameters.toString();
    setIsLoading(true);
    downloadCsv(queryString, fileName).then(()=>{
      setIsLoading(false);
    }).catch((error)=>{
      setIsLoading(false);
      handleError(error);
    }).finally(()=>{
      setIsLoading(false);
    });
  }

  const handleDownloadExcel = (fileName: string) => {
    let params = {
      orgCode: String(orgCode),
      monthlyStartDate: moment(selectedMonth).startOf('month').format('YYYY/MM/DD'),
      monthlyEndDate: moment(selectedMonth).endOf('month').format('YYYY/MM/DD'),
      loginStaffCode: loginStaffCode,
      functionType: functionType.toString()
    }
    const queryParameters = new URLSearchParams(params);
    const queryParameter = new URLSearchParams(categoryAry.map(s=>['categoryAry',s]));
    const queryString = categoryAry?.length > 0 ? `${queryParameters.toString()}&${queryParameter.toString()}` : queryParameters.toString();
    setIsLoading(true);
    downloadExcel(queryString, fileName).then(()=>{
      setIsLoading(false);
    }).catch((error)=>{
      setIsLoading(false);
      handleError(error);
    }).finally(()=>{
      setIsLoading(false);
    });
  }

  return {
    isLoading,
    selectedMonth,
    setSelectedMonth,
    functionType,
    orgCode,
    setOrgCode,
    orgName,
    setorgName,
    categoryAry,
    setCategoryAry,
    daySeparateSalesStoreManagementList,
    handleDownloadCsv,
    handleDownloadExcel
  };
};

export default useDaySeparateSalesStoreManagementList;
