import moment from 'moment';
import ApiClient from './ApiClient';

export const getOperationReportQuick = async (
  orgCode: string,
  targetDateFrom: Date,
): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const targetDate = moment(targetDateFrom).format('YYYY/MM/DD');
  const params = {
    orgCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/jsp/sj/operational_report/quick/${companyCode}`, params);
  return response.data;
};

export default { };
