export type ManagerDataStaff = {
  managerId: number | '',
  staffCode: string,
  orgCode: string,
  orgName: string,
  managerType: number,
  startDateStr: string,
  endDateStr: string | null,
}

export class ManagerDataStaffDomain {
  constructor(private rawData: ManagerDataStaff) {
    // do nothing
  }

  getRawData(): ManagerDataStaff {
    return this.rawData;
  }

  get managerId(): number | '' {
    if (this.rawData.managerId) {
      return this.rawData.managerId;
    }
    return '';
  }

  set managerId(managerId: number | '') {
    this.rawData.managerId = managerId;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get startDate(): Date {
    return new Date(this.rawData.startDateStr.replace('年', '-').replace('月', '-').replace('日', ''));
  }

  get startDateStr(): string {
    return this.rawData.startDateStr;
  }

  set startDateStr(startDateStr: string) {
    this.rawData.startDateStr = startDateStr;
  }

  get endDate(): Date | null {
    if (this.rawData.endDateStr) {
      return new Date(this.rawData.endDateStr.replace('年', '-').replace('月', '-').replace('日', ''));
    }
    return null;
  }

  get endDateStr(): string | null {
    return this.rawData.endDateStr;
  }

  set endDateStr(endDateStr: string | null) {
    this.rawData.endDateStr = endDateStr;
  }

  get managerType(): number {
    return this.rawData.managerType;
  }

  set managerType(managerType: number) {
    this.rawData.managerType = managerType;
  }
}
