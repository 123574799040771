import { useState, useEffect } from 'react';
import { getAccountTitleMSTList, getAccountTreeOption } from 'api/subjectTitleMST';
import AccountTitleSubMSTItemDomain from 'domain/master/general/accountTitleSubMSTItem';
import { OptionType } from 'components/atoms/Select';

export const useSubjectMSTItemList = (accountTitleId: string) => {
  const [accountTitleSubMSTItemList, setAccountTitleSubMSTItemList] = useState<Array<AccountTitleSubMSTItemDomain>>([]);

  useEffect(() => {
    getAccountTitleMSTList(accountTitleId).then((response: Array<any>) => {
      setAccountTitleSubMSTItemList(
        response.map((result) => new AccountTitleSubMSTItemDomain(result)),

      );
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountTitleId]);
  return { accountTitleSubMSTItemList, setAccountTitleSubMSTItemList };
};

export const useAccoutTitleTree = () : any => {
  const [accoutOption, setAccountOption] = useState<Array<OptionType>>([]);
  const [accountTitleId, setAccountTitleId] = useState(sessionStorage.getItem('accountTitleId') ? sessionStorage.getItem('accountTitleId')
    : sessionStorage.getItem('accountTitleIdFirst'));
  useEffect(() => {
    getAccountTreeOption().then((trees: Array<any>) => {
      const OptionTree = trees.map((tree: any) => ({
        value: tree.accountTitleId,
        label: `${tree.accountTitleCode} ${tree.accountTitleName}`,
      }));
      setAccountOption(OptionTree);
      sessionStorage.setItem('accountTitleIdFirst', OptionTree.length === 0 ? '' : OptionTree[0].value);
      if (OptionTree && OptionTree[0]) {
        if (!sessionStorage.getItem('accountTitleId')) {
          setAccountTitleId(OptionTree[0].value);
        }
      }
    });
  }, []);
  return {
    accoutOption,
    accountTitleId,
    setAccountTitleId,
  };
};
export default useSubjectMSTItemList;
