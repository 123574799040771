import React, { useEffect, useState } from 'react';

import { utilityColor } from 'components/styles';
import FormContents from 'components/atoms/Form/FormContents';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import RadioSelector from 'components/molecules/RadioSelector';
import FileSelectButton from 'components/molecules/FileSelectButton';
import BodyText from 'components/atoms/BodyText';

import { Link } from 'react-router-dom';
import BlockUI from 'components/molecules/BlockUi';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { useOrganizationCsvForm } from './hooks';
import {useSelector} from 'react-redux';
import {Store} from 'modules/store';

export type UploadType = 'organization' | 'manager' | 'specialday';

const OrganizationCsvAddForm: React.FC = () => {
  const {
    uploadType, setUploadType, uploadTypeItems, uploadFile, setUploadFile, onSubmit,
    errors, blocking,
    confirmModalOpen, closeConfirmModal,
  } = useOrganizationCsvForm();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  return (
    <BlockUI blocking={blocking}>
      <FormContents>
        <FormTitle
          title="登録するデータ"
        />

        <p>
          組織情報、マネージャ権限、特賃日割当情報のうちどれかを選択して、正しい形式のファイルをアップロードしてください。
        </p>

        <FormField>
          <RadioSelector
            name="uploadType"
            items={uploadTypeItems}
            value={uploadType}
            setValue={(
              e: React.ChangeEvent<HTMLInputElement>,
            ) => setUploadType(e.target.value as UploadType)}
          />
        </FormField>

        <BodyText color={utilityColor.error} size="md">※CSVファイルの１行目（ヘッダー行）はインポートされません。</BodyText>
        <FormField>
          <FileSelectButton
            text="CSVファイルをドラッグ＆ドロップ または クリックしてファイルを選択してください"
            ghost={true}
            setFile={setUploadFile}
            accept=".csv"
          />
        </FormField>

        {uploadFile || errors
          ? (
            <FormField
              displayBlock={true}
            >
              <div>
                {uploadFile?.name}
              </div>
              {errors && errors.map((error) => (
                <div key={error}>
                  <BodyText color={utilityColor.error}>
                    {error}
                  </BodyText>
                </div>
              ))}
            </FormField>
          )
          : null}

        <FormSubmitArea>
          {
            roleScreen && roleScreen.importFlag === 1 && (
              <PrimaryButton
                text="インポート"
                onClick={onSubmit}
                disabled={!uploadFile}
              />
            )
          }
          <Link to="/organizations">
            <PrimaryButton
              text="戻る"
              ghost={true}
            />
          </Link>
        </FormSubmitArea>
      </FormContents>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={onSubmit}
        actionType={ActionType.IMPORT}
      />
    </BlockUI>
  );
};

export default OrganizationCsvAddForm;
