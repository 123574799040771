import ApiClient from './ApiClient';

export type ShiftConfirmedDate = {
  date: string;
};

export type ShiftConfirmedState = {
  shiftConfirm: {[key:string]: boolean};
}
export interface IShiftConfirmV1 {
  companyCode: string;
  createUser: string;
  createDate: string;
  updateUser: string;
  updateDate: string;
  shiftConfirmId: string;
  orgCode: string;
  targetDate: string;
}
/**
 * シフト確定済み日の取得
 *
 * @param orgCode 組織コード
 * @param dayFrom 対象期間開始(yyyy-MM-dd)
 * @param dayTo 対象期間終了(yyyy-MM-dd)
 */
export const getShiftConfirmedDate = async (
  orgCode: string, dayFrom: string, dayTo: string,
): Promise<ShiftConfirmedDate> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    dayFrom,
    dayTo,
  };
  const response = await ApiClient.get(`/v2/shift/confirm/comfirmedDate/${companyCode}`, params);
  return response.data;
};

/**
 * シフト確定
 *
 * @param orgCode 組織コード
 * @param dayFrom 対象期間開始(yyyy-MM-dd)
 * @param dayTo 対象期間終了(yyyy-MM-dd)
 */
export const postShiftConfirm = async (
  orgCode: string, dayFrom: string, dayTo: string,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const updateUser = sessionStorage.getItem('loginUser.staffName') || '';
  const query = {
    orgCode,
    dayFrom,
    dayTo,
    updateUser,
  };
  const response = await ApiClient.post(`/v2/shift/confirm/${companyCode}`, query, {});
  return response.data;
};

/**
 * 対象期間のシフト確定状態を取得
 *
 * @param orgCode 組織コード
 * @param targetDateFrom 対象日開始(yyyy-MM-dd)
 * @param targetDateTo 対象日終了(yyyy-MM-dd)
 */
export const getShiftConfirmedState = async (
  orgCode: string, targetDateFrom: string, targetDateTo: string,
): Promise<ShiftConfirmedState> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
  };
  const response = await ApiClient.get(`/v1/mws/confirm/${companyCode}`, params);
  return response.data;
};


export const shiftConfirmReleaseV1 = async (orgCode: string, targetDateFrom: string, targetDateTo: string): Promise<any> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const updateUser = sessionStorage.getItem("loginUser.staffName") || "";
  let query = {
    orgCode,
    targetDateFrom,
    targetDateTo,
    updateUser,
  };

  const response = await ApiClient.delete(`/v1/shift/confirm/${companyCode}`, query);
  return response.data;
};

export const shiftConfirmV1 = async (orgCode: string, targetDateFrom: string, targetDateTo: string, targetStaffCodes: any): Promise<any> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const updateUser = sessionStorage.getItem("loginUser.staffName") || "";

  let queryString = `orgCode=${orgCode}&targetDateFrom=${targetDateFrom}&targetDateTo=${targetDateTo}&updateUser=${updateUser}`;
  for (let i = 0; i < targetStaffCodes.length; i++) {
    queryString += "&targetStaffCodes=" + targetStaffCodes[i];
  }
  
  const response = await ApiClient.post_shift(`/v1/shift/confirm/${companyCode}?${queryString}`);
  return response.data;
};
export default {};
