/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React, { useState } from 'react';
import Calendar from 'components/molecules/Calendar';
import { jsx } from '@emotion/core';
import { ShiftMonthCalendarEvent } from 'components/pages/master/attend/SchedulePage/hooks';
import { ScheduleStatus } from 'api/schedule';
import ScheduleEventWrapper from './ScheduleEventWrapper';
import ScheduleMobileDateHeader from './ScheduleMobileDateHeader';

const ScheduleMobileCalendar: React.FC<{
  events: ShiftMonthCalendarEvent[],
  date: Date;
  datePickerValue: Date;
  setDate: (date: Date) => void;
  setDatePickerValue: (date: Date) => void;
  setIsHasActionBar: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  date, datePickerValue, setDate, setDatePickerValue, events, setIsHasActionBar,
}) => {
  // Events that dont have event can't edit & ready to submit
  const configEvents = events.filter((item) => !(item.hopeShift?.isEditable === false && item.hopeShift.fixStatus === ScheduleStatus.UNSUBMITED));

  // Show important event on a Date
  // const sortEvents = configEvents.filter((a, b) => b.fixStatus - a.fixStatus);

  const newEvents = configEvents.map(
    (item) => ({
      ...item,

      start: item.start,
      end: (item.start && item.end)
        ? (item.start!.getDate() !== item.end!.getDate()
          ? new Date(item.start!.getFullYear(),
                    item.start!.getMonth(),
                    item.start!.getDate(), 23, 30, 0, 0)
          : item.end) : undefined,
      title: '',
    }),
  );

  let previousOrientation = window.orientation;

  const [height, setHeight] = useState(previousOrientation === 0 ? 'calc(100vh - 250px)' : 'calc(100vh)');

  const checkOrientation = () => {
    if (window.orientation !== previousOrientation) {
      previousOrientation = window.orientation;

      if (previousOrientation === 90) {
        setHeight('calc(100vh)');
      } else {
        setHeight('calc(100vh - 250px)');
      }
    }
  };

  window.addEventListener('resize', checkOrientation, false);
  window.addEventListener('orientationchange', checkOrientation, false);

  return (
    <Calendar
      // height={`${calenderHeight}px`}
      height={height}
      // height={isSelected ? `${calenderHeight - 170}px` : `${calenderHeight}px`}
      date={datePickerValue}
      setDate={setDatePickerValue}
      events={newEvents}
      views={['month']}
      onDrillDown={(selectDate) => {
        setDate(selectDate);
        setIsHasActionBar(true);
      }}
      components={{
        month: {
          dateHeader: ScheduleMobileDateHeader,
          // event: () => {},
        },
        eventWrapper: ScheduleEventWrapper,
        toolbar: () => <div />,
      }}
      messages={{
        showMore: (count: number) => '',
      }}
    />
  );
};

export default ScheduleMobileCalendar;
