/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import FormContents from 'components/atoms/Form/FormContents';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import DatePicker from 'components/molecules/DatePicker';
import FlexBox from 'components/atoms/FlexBox';
import useSalesManagementList,{useDownload} from 'components/organismos/master/general/salesPage/SalesManagementList/hook';
import SalesManagementListTable from 'components/organismos/master/general/salesPage/SalesManagementList/SalesManagementListTable';
import SalesManagementListDetailFLTable from 'components/organismos/master/general/salesPage/SalesManagementFLDetailList/SalesManagementFLDetailListTable';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import SalesManagementDiffPreviousListTable from 'components/organismos/master/general/salesPage/SalesManagementDiffPreviousList/SalesManagementDiffPreviousListTable';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import SalesManagementFLListTable from 'components/organismos/master/general/salesPage/SalesManagementFLList/SalesManagementFLListTable';
import DeficienciesListTable from 'components/organismos/master/general/salesPage/DeficienciesList/DeficienciesListTable';
import SalesAuditItemsListTable from 'components/organismos/master/general/salesPage/SalesAuditItemsList/SalesAuditItemsListTable';
import SalesAuditListTable from 'components/organismos/master/general/salesPage/salesAuditList/SalesAuditListTable';
import moment from 'moment';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const SalesManagementList: React.FC<{}> = () => {
  const {
    isLoading,
    listSalesManagement,
    listSalesManagement2,
    listSalesManagement3,
    listSalesManagement4,
    listSalesManagement5,
    listSalesManagement6,
    listSalesManagement7,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo,
    functionType,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    action,
    setAction,
    ActionType,
    getMonthlyComparePrevYear,
    setMonthlyComparePrevYear,
    orgName,
    setorgName
  } = useSalesManagementList();

  const {downloadCsv1,downloadExcel1} = useDownload();

  const [isActiveRow, setActiveRow] = useState();
  const [isActiveColumn, setActiveColumn] = useState();

  const handleClickActive = (row: number, column: number) => {
    setActiveRow(row);
    setActiveColumn(column);
  };


  const [isShowContentAction, setIsShowContentAction] = useState(false);

  const getTab1 = () => {
    setAction(ActionType.getTab1);
    setIsShowContentAction(true);
  };

  const getTab2 = () => {
    setAction(ActionType.getTab2);
    setIsShowContentAction(true);
  };

  const getTab3 = () => {
    setAction(ActionType.getTab3);
    setIsShowContentAction(true);
  };
  const getTab4 = () => {
    setAction(ActionType.getTab4);
    setIsShowContentAction(true);
  };

  const getTab5 = () => {
    setAction(ActionType.getTab5);
    setIsShowContentAction(true);
  };

  const getTab6 = () => {
    setAction(ActionType.getTab6);
    setIsShowContentAction(true);
  };
  const getTab7 = () => {
    setAction(ActionType.getTab7);
    setIsShowContentAction(true);
  };

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox>
          <FlexBoxItem>
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                date={selectedDateFrom}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setSelectedDateFrom(date);
                    sessionStorage.setItem('dateSelectSalePageFrom6', `${date}`);
                  }
                }}
                isFullWidth={true}
              />
          </FlexBoxItem>
          <FlexBoxItem>
            <span style={{ margin: '0 30px' }}>～</span>
          </FlexBoxItem>

          <FlexBoxItem>
            <DatePicker
              dateFormat="yyyy年MM月dd日"
              date={selectedDateTo}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setSelectedDateTo(date);
                }
              }}
              isFullWidth={true}
            />
          </FlexBoxItem>
        </FlexBox>
        <div>
          <OrganizationFilteredTree
            functionType={functionType}
            orgCallback={(args: string | Array<string>) => {
              setOrgCode(String(args));
            }}
            orgCategoryCallback={
                (args: Array<string>) => {
                  setCategoryAry(args);
                }
              }
            orgNameCallback={(arg: any) => {
              setorgName(String(arg));
            }}
            initOrgValue={String(orgCode)}
            staffCode={loginStaffCode}
            orgLabel="組織名"
            addAllItem={true}
          />
        </div>
        <FlexBoxItem>
            <RadioSelectForm
              label="前年比較対象"
              name="monthlyComparePrevYear"
              items={[
                { label: '同曜日', value: '0' },
                { label: '同日', value: '1' },
              ]}
              value={String(getMonthlyComparePrevYear)}
              setValue={(e) => setMonthlyComparePrevYear(Number(e.target.value))}
            />
          </FlexBoxItem>

        <FormField>
          <FormSubmitArea>
            <div style={{ display: 'flex', marginTop: '-15px' }}>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton text="売上管理" onClick={getTab1} />
              </div>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton text="前年対比" onClick={getTab2} />
              </div>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton text="FL" onClick={getTab4} />
              </div>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton text="FL詳細" onClick={getTab5} />
              </div>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton text="コメント" onClick={getTab7} />
              </div>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton text="監査項目" onClick={getTab6} />
              </div>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton text="不備一覧" onClick={getTab3} />
              </div>
            </div>
          </FormSubmitArea>
        </FormField>
        {/* 売上管理 */}
        {isShowContentAction && action === ActionType.getTab1
            && (
            <SalesManagementListTable
              listSalesManagement={listSalesManagement}
              isActiveRow={isActiveRow}
              isActiveColumn={isActiveColumn}
              handleClickActive={handleClickActive}
              selectedDateFrom={selectedDateFrom}
              selectedDateTo={selectedDateTo}
              orgName={orgName}
              roleScreen={roleScreen}
            />
        )}
        {/* 前年対比 */}
        {isShowContentAction && action === ActionType.getTab2
            && (
              <SalesManagementDiffPreviousListTable
              listSalesManagement2={listSalesManagement2}
              isActiveRow={isActiveRow}
              isActiveColumn={isActiveColumn}
              handleClickActive={handleClickActive}
              selectedDateFrom={selectedDateFrom}
              selectedDateTo={selectedDateTo}
              orgName={orgName}
              roleScreen={roleScreen}
              />
        )}
        {/* 不備一覧 */}
        {isShowContentAction && action === ActionType.getTab3 && (
          <DeficienciesListTable
              listSalesManagement3={listSalesManagement3}
              isActiveRow={isActiveRow}
              isActiveColumn={isActiveColumn}
              handleClickActive={handleClickActive}
              selectedDateFrom={selectedDateFrom}
              selectedDateTo={selectedDateTo}
              orgName={orgName}
              roleScreen={roleScreen}
          />
        )}
        {/* FL */}
        {isShowContentAction && action === ActionType.getTab4 && (
          <SalesManagementFLListTable
              listSalesManagement4={listSalesManagement4}
              isActiveRow={isActiveRow}
              isActiveColumn={isActiveColumn}
              handleClickActive={handleClickActive}
              selectedDateFrom={selectedDateFrom}
              selectedDateTo={selectedDateTo}
              orgName={orgName}
              roleScreen={roleScreen}
          />
        )}
        {/* FL詳細 */}
        {isShowContentAction && action === ActionType.getTab5 && (
          <SalesManagementListDetailFLTable
          listSalesManagement5={listSalesManagement5}
          isActiveRow={isActiveRow}
          isActiveColumn={isActiveColumn}
          handleClickActive={handleClickActive}
          selectedDateFrom={selectedDateFrom}
          selectedDateTo={selectedDateTo}
          orgName={orgName}
          roleScreen={roleScreen}
        />
        )}
        {/* 監査項目 */}
        {isShowContentAction && action === ActionType.getTab6 && (
          <SalesAuditItemsListTable
          listSalesManagement6={listSalesManagement6}
          isActiveRow={isActiveRow}
          isActiveColumn={isActiveColumn}
          handleClickActive={handleClickActive}
          selectedDateFrom={selectedDateFrom}
          selectedDateTo={selectedDateTo}
          orgName={orgName}
          roleScreen={roleScreen}
        />
        )}
        {/* コメント */}
        {isShowContentAction && action === ActionType.getTab7 && (
          <SalesAuditListTable
            listSalesManagement7={listSalesManagement7}
            isActiveRow={isActiveRow}
            isActiveColumn={isActiveColumn}
            handleClickActive={handleClickActive}
            selectedDateFrom={selectedDateFrom}
            selectedDateTo={selectedDateTo}
            orgName={orgName}
            roleScreen={roleScreen}
          />
        )}

      </FormContents>
    </BlockUI>
  );
};
export default SalesManagementList;
