import ApiClient from './ApiClient';

export interface WorkingHours {
  companyCode: string;
  workingHoursId: string;
  workingHoursPatternCode: string;
  workingHoursPatternName: string;
  targetYear: string;
}

export const getListWorkingHours = async (): Promise<Array<WorkingHours>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/workingHours/list/${companyCode}`, {});
  return response.data;
};

export const getWorkingHours = async (targetYear: string, workingHoursId: string): Promise<Array<WorkingHours>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    year: targetYear,
    workingHoursId,
  };
  const response = await ApiClient.get(`/v1/workingHours/currentYear/${companyCode}`, query);
  return response.data;
};

export const post = async (companyCode: string, dataWorkingHours: any) => {
  // TODO パラメータ確認
  const query = {
  };
  const response = await ApiClient.post(`/v1/workingHours/${companyCode}`, query, dataWorkingHours);
  return response.data;
};

export const getWorkingHoursPreviousYear = async (targetYear: string, workingHoursId: string, workingHoursDetailId: string): Promise<Array<WorkingHours>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    year: targetYear,
    workingHoursId,
    workingHoursDetailId,
  };
  const response = await ApiClient.get(`/v1/workingHours/previousYear/${companyCode}`, query);
  return response.data;
};

export default {
  getListWorkingHours,
};
