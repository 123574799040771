import React, { SVGProps } from 'react';

const RadioOn: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M10 4a6.5 6.5 0 110 13 6.5 6.5 0 010-13zm0 1.25a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zM10 7a3.5 3.5 0 110 7 3.5 3.5 0 010-7z"
        fill="#333"
      />
    </g>
  </svg>
);

export default RadioOn;
