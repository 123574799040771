/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import productColor, { utilityColor, iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';

const styles = {
  label: css({
    alignItems: 'center',
    display: 'inline-flex',
    cursor: 'pointer',
    lineHeight: 0,
  }),
  icon: css({
    paddingRight: '9px',
  }),
  input: css({
    display: 'none',
  }),
};

const Checkbox: React.FC<{
  id: string;
  name: string;
  label?: string;
  value: string;
  checked: boolean;
  readOnly?: boolean;
  disabled?:boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  id, name, label, value, checked, onChange, readOnly = false, disabled = false
}) => (
  <div>
    <label
      css={styles.label}
      htmlFor={id}
    >
      {checked
        ? <Icon type="checkboxOn" color={utilityColor.black} />
        : <Icon type="checkboxOff" color={readOnly ? iconColor.gray : utilityColor.black} />}
      <input
        css={styles.input}
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
      />
      {label && (
        <BodyText size="re" color={readOnly ? iconColor.gray : productColor.primary}>
          {label}
        </BodyText>
      )}
    </label>
  </div>
);

export default Checkbox;
