/* eslint-disable array-callback-return */
import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import FormLabel from 'components/atoms/Form/FormLabel';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import MultipleCheckboxForm from 'components/molecules/MultipleCheckboxForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import HourInput from 'components/atoms/Form/HourInput';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import FlexBox from 'components/atoms/FlexBox';
import ColorForm from 'components/molecules/ColorForm';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import useHolidayOptions from 'hooks/useHolidayOptions';
import usePaidHolidayPatternOptions from 'hooks/usePaidHolidayPatternOptions';
import { useHqStaffsOptions } from 'hooks/useStaffsOptions';
import { AttendHoliday, NoticeStaff } from 'domain/employment';
import { gridCol } from 'components/styles';
import BodyText from 'components/atoms/BodyText';
import SelectForm from 'components/molecules/SelectForm';

const EmploymentVacationSettings: React.FC<{
  formik: any
}> = ({
  formik,
}) => {
  const holidayOptions = useHolidayOptions();
  const paidHolidayPatternOptions = usePaidHolidayPatternOptions();
  const hqStaffsOptions = useHqStaffsOptions();
  return (
    <>
      <FormTitle
        title="休暇設定"
      />
      <div>
        <VerticalLabelMutilSelectForm
          label="使用する休暇"
          name="holidays"
          values={formik.values.holidays.map(
            (holiday: AttendHoliday) => holidayOptions.find(
              (item) => holiday.holidayId === item.value,
            ),
          )}
          setValue={(val: any) => {
            if (val !== '') {
              formik.setFieldValue('holidays', val.slice(0, -1).split(',').map(
                (id: string) => ({ holidayId: id }),
              ));
            } else {
              formik.setFieldValue('holidays', []);
            }
          }}
          options={holidayOptions}
          isMulti={true}
        />

        <FormField>
          <RadioSelectForm
            label="休日出勤申請機能"
            name="necessaryHolidayWorkApplication"
            items={[
              {
                label: '使用しない',
                value: 'false',
              },
              {
                label: '使用する',
                value: 'true',
              },
            ]}
            value={String(formik.values.necessaryHolidayWorkApplication)}
            setValue={formik.handleChange}
          />
        </FormField>

        <div style={{ display: 'inline-flex', width: '300px' }}>
          <ColorForm
            name="holidayWorkBgColor"
            label="休日出勤の背景色"
            color={formik.values.holidayWorkBgColor}
            isFullW={true}
            onChange={(e) => {
              formik.setFieldValue('holidayWorkBgColor', e.target.value);
            }}
          />
          <ColorForm
            name="holidayWorkForeColor"
            label="休日出勤の文字色"
            color={formik.values.holidayWorkForeColor}
            isFullW={true}
            onChange={(e) => {
              formik.setFieldValue('holidayWorkForeColor', e.target.value);
            }}
          />
        </div>

        <FormField>
          <RadioSelectForm
            label="休日を法定休日と所定休日で区別する"
            name="distinctionHoliday"
            items={[
              {
                label: 'しない',
                value: 'false',
              },
              {
                label: 'する',
                value: 'true',
              },
            ]}
            value={String(formik.values.distinctionHoliday)}
            setValue={formik.handleChange}
          />
        </FormField>

        <div style={String(formik.values.workSystem) === '0' ? {} : { display: 'none' }}>
          <FormField>
            <MultipleCheckboxForm
              name="letBeLegalHoliday"
              label="特定の曜日を法定休日とする"
              items={[
                {
                  id: 'letPubBeLegalHoliday',
                  label: '祝日',
                  value: formik.values.letPubBeLegalHoliday,
                },
                {
                  id: 'letSatBeLegalHoliday',
                  label: '土曜日',
                  value: formik.values.letSatBeLegalHoliday,
                },
                {
                  id: 'letSunBeLegalHoliday',
                  label: '日曜日',
                  value: formik.values.letSunBeLegalHoliday,
                },
                {
                  id: 'letMonBeLegalHoliday',
                  label: '月曜日',
                  value: formik.values.letMonBeLegalHoliday,
                },
                {
                  id: 'letTueBeLegalHoliday',
                  label: '火曜日',
                  value: formik.values.letTueBeLegalHoliday,
                },
                {
                  id: 'letWedBeLegalHoliday',
                  label: '水曜日',
                  value: formik.values.letWedBeLegalHoliday,
                },
                {
                  id: 'letTurBeLegalHoliday',
                  label: '木曜日',
                  value: formik.values.letTurBeLegalHoliday,
                },
                {
                  id: 'letFriBeLegalHoliday',
                  label: '金曜日',
                  value: formik.values.letFriBeLegalHoliday,
                },
              ]}
              setValue={(e) => formik.setFieldValue(String(e.target.id), !(e.target.value === 'true'))}
            />
          </FormField>
        </div>

        <div style={String(formik.values.workSystem) === '0' && String(formik.values.distinctionHoliday) === 'true' ? {} : { display: 'none' }}>
          <FormField>
            <MultipleCheckboxForm
              name="letBeNonLegalHoliday"
              label="特定の曜日を所定休日とする"
              items={[
                {
                  id: 'letPubBeNonLegalHoliday',
                  label: '祝日',
                  value: formik.values.letPubBeNonLegalHoliday,
                },
                {
                  id: 'letSatBeNonLegalHoliday',
                  label: '土曜日',
                  value: formik.values.letSatBeNonLegalHoliday,
                },
                {
                  id: 'letSunBeNonLegalHoliday',
                  label: '日曜日',
                  value: formik.values.letSunBeNonLegalHoliday,
                },
                {
                  id: 'letMonBeNonLegalHoliday',
                  label: '月曜日',
                  value: formik.values.letMonBeNonLegalHoliday,
                },
                {
                  id: 'letTueBeNonLegalHoliday',
                  label: '火曜日',
                  value: formik.values.letTueBeNonLegalHoliday,
                },
                {
                  id: 'letWedBeNonLegalHoliday',
                  label: '水曜日',
                  value: formik.values.letWedBeNonLegalHoliday,
                },
                {
                  id: 'letTurBeNonLegalHoliday',
                  label: '木曜日',
                  value: formik.values.letTurBeNonLegalHoliday,
                },
                {
                  id: 'letFriBeNonLegalHoliday',
                  label: '金曜日',
                  value: formik.values.letFriBeNonLegalHoliday,
                },
              ]}
              setValue={(e) => formik.setFieldValue(String(e.target.id), !(e.target.value === 'true'))}
            />
          </FormField>
        </div>

        <FormField>
          <RadioSelectForm
            label="自動有給管理"
            name="autoPaidManageFunc"
            items={[
              {
                label: 'しない',
                value: 'false',
              },
              {
                label: 'する',
                value: 'true',
              },
            ]}
            value={String(formik.values.autoPaidManageFunc)}
            setValue={formik.handleChange}
          />
        </FormField>

        <div style={String(formik.values.autoPaidManageFunc) === 'true' ? {} : { display: 'none' }}>
          <FormField>
            <RadioSelectForm
              label="有給付与方法"
              name="paidProvisionMethod"
              items={[
                {
                  label: '入社日基準で付与',
                  value: '0',
                },
                {
                  label: '指定日で付与',
                  value: '1',
                },
              ]}
              value={String(formik.values.paidProvisionMethod)}
              setValue={formik.handleChange}
            />
          </FormField>

          <VerticalLabelSelectForm
            label="有給付与パターン"
            name="paidHolidayPatternId"
            value={String(formik.values.paidHolidayPatternId)}
            // setValue={(val: string) => { formik.setFieldValue('paidHolidayPatternId', Number(val)); }}
            setValue={(val: string) => formik.setFieldValue('paidHolidayPatternId', val)}
            options={paidHolidayPatternOptions}
          />

          <div style={String(formik.values.paidProvisionMethod) === '1' ? {} : { display: 'none' }}>
            {/* <FormField>
              <MonthDateSelectForm
                label="有給付与指定日"
                name="sameDays"
                dateFormat="MM/DD"
                labelFormat="MM月DD日"
                setValue={(items: any) => formik.setFieldValue('sameDays', items)}
                // isMulti={true}
                values={formik.values.sameDays}
              />
            </FormField> */}
            <FormField>
              <VerticalLabelFormLayout
                label={(
                  <FormLabel
                    label="有給付与指定日"
                    required={false}
                  />
            )}
                input={(
                  <FlexBox>
                    <BodyText>
                      毎年
                    </BodyText>
                    <SelectForm
                      label=""
                      name="paidBonusAwardDateMonth"
                      value={String(formik.values.paidBonusAwardDateMonth)}
                      setValue={(val: string) => { formik.setFieldValue('paidBonusAwardDateMonth', Number(val)); }}
                      options={[new Option('1'), new Option('2'), new Option('3'), new Option('4'),
                        new Option('5'), new Option('6'), new Option('7'), new Option('8'),
                        new Option('9'), new Option('10'), new Option('11'), new Option('12')]}
                    />
                    <BodyText>
                      月
                    </BodyText>

                    <SelectForm
                      label=""
                      name="paidBonusAwardDateDay"
                      value={String(formik.values.paidBonusAwardDateDay)}
                      setValue={(val: string) => { formik.setFieldValue('paidBonusAwardDateDay', Number(val)); }}
                      options={[new Option('1'), new Option('2'), new Option('3'), new Option('4'),
                        new Option('5'), new Option('6'), new Option('7'), new Option('8'),
                        new Option('9'), new Option('10'), new Option('11'), new Option('12'),
                        new Option('13'), new Option('14'), new Option('15'), new Option('16'),
                        new Option('17'), new Option('18'), new Option('19'), new Option('20'),
                        new Option('21'), new Option('22'), new Option('23'), new Option('24'),
                        new Option('25'), new Option('26'), new Option('27'), new Option('28'),
                        new Option('29'), new Option('30'), new Option('31')]}
                    />
                    <BodyText>
                      日
                    </BodyText>
                  </FlexBox>
            )}
              />
            </FormField>

            <VerticalLabelFormLayout
              label={(
                <FormLabel
                  label="有給付与期間と日数"
                />
                )}
              input={(
                <FlexBox>
                  <div style={{ marginRight: '10px' }}>
                    初回付与は有給起算日から
                  </div>
                  <div style={{ width: '200px', marginRight: '10px' }}>
                    <VerticalLabelSelectForm
                      label=""
                      name="paidHolidayInitialGrantMonths"
                      value={String(formik.values.paidHolidayInitialGrantMonths)}
                      setValue={(val: string) => formik.setFieldValue('paidHolidayInitialGrantMonths', val)}
                      options={[
                        {
                          label: '0ヶ月',
                          value: '0',
                        },
                        {
                          label: '1ヶ月',
                          value: '1',
                        },
                        {
                          label: '2ヶ月',
                          value: '2',
                        },
                        {
                          label: '3ヶ月',
                          value: '3',
                        },
                        {
                          label: '4ヶ月',
                          value: '4',
                        },
                        {
                          label: '5ヶ月',
                          value: '5',
                        },
                        {
                          label: '6ヶ月',
                          value: '6',
                        },
                      ]}
                    />
                  </div>
                  <div style={{ margin: '0 10px' }}>
                    で
                  </div>
                  <HourInput
                    name="paidHolidayInitialGrantDays"
                    value={formik.values.paidHolidayInitialGrantDays}
                    label="日"
                    onChange={formik.handleChange}
                  />
                  <div style={{ marginLeft: '10px' }}>
                    付与する
                  </div>
                </FlexBox>
              )}
            />
          </div>

          <FormField>
            <RadioSelectForm
              label="有給付与日数計算方法"
              name="calcMethodPaidGrantDays"
              items={[
                {
                  label: '週の契約労働日数を使用',
                  value: '0',
                },
                {
                  label: '労働日数を使用',
                  value: '1',
                },
              ]}
              value={String(formik.values.calcMethodPaidGrantDays)}
              setValue={formik.handleChange}
            />
          </FormField>

          <VerticalLabelMutilSelectForm
            label="自動有給付与結果通知先"
            name="noticeStaffs"
            values={formik.values.noticeStaffs.map(
              (noticeStaff: NoticeStaff) => hqStaffsOptions.find(
                (item) => noticeStaff.staffCode === item.value,
              ),
            )}
            setValue={(val: any) => {
              if (val !== '') {
                formik.setFieldValue('noticeStaffs', val.slice(0, -1).split(',').map(
                  (id: string) => ({ staffCode: id }),
                ));
              } else {
                formik.setFieldValue('noticeStaffs', []);
              }
            }}
            options={hqStaffsOptions}
            isMulti={true}
          />
          <FormField>
            <RadioSelectForm
              label="有給の消化順"
              name="paidDigestionOrder"
              items={[
                {
                  label: '前年付与から消化',
                  value: '0',
                },
                {
                  label: '当年付与から消化',
                  value: '1',
                },
              ]}
              value={String(formik.values.paidDigestionOrder || 0)}
              setValue={formik.handleChange}
            />
          </FormField>
          <FormField>
            <RadioSelectForm
              label="時間単位の休暇"
              name="timeHoliday"
              items={[
                {
                  label: '使用しない',
                  value: '0',
                },
                {
                  label: '使用する',
                  value: '1',
                },
              ]}
              value={String(formik.values.timeHoliday)}
              setValue={formik.handleChange}
            />
          </FormField>

          <div style={String(formik.values.timeHoliday) === '1' ? {} : { display: 'none' }}>
            <FormField>
              <div style={{ width: gridCol.grid03 }}>
                <p>時間単位休暇の最大時間数</p>
                <HourInput
                  name="timeHolidayMaxHour"
                  value={String(formik.values.timeHolidayMaxHour)}
                  label="時間"
                  onChange={formik.handleChange}
                  min={0}
                  max={40}
                />
                {/* <TextForm
                  name="timeHolidayMaxHour"
                  label="時間単位休暇の最大時間数"
                  groupText="時間"
                  value={String(formik.values.timeHolidayMaxHour)}
                  onChange={formik.handleChange}
                /> */}
              </div>
            </FormField>
            <FormField>
              <div style={{ width: gridCol.grid03 }}>
                <p>時間単位休暇の１日の時間数</p>
                <HourInput
                  name="timeHolidayHourOfDay"
                  value={String(formik.values.timeHolidayHourOfDay)}
                  label="時間"
                  onChange={formik.handleChange}
                  min={0}
                  max={8}
                />
                {/* <TextForm
                  name="timeHolidayHourOfDay"
                  label="時間単位休暇の１日の時間数"
                  groupText="時間"
                  value={String(formik.values.timeHolidayHourOfDay)}
                  onChange={formik.handleChange}
                /> */}
              </div>
            </FormField>
          </div>
        </div>

        <FormField>
          <RadioSelectForm
            label="振替申請"
            name="useTransferApplication"
            items={[
              {
                label: '使用しない',
                value: '0',
              },
              {
                label: '使用する',
                value: '1',
              },
            ]}
            value={String(formik.values.useTransferApplication)}
            setValue={formik.handleChange}
          />
        </FormField>

        <div style={String(formik.values.useTransferApplication) === '1' ? {} : { display: 'none' }}>
          <FormField>
            <div style={{ display: 'inline-flex', width: '300px' }}>
              <ColorForm
                name="transferWorkBgColor"
                label="振替出勤日の背景色"
                color={formik.values.transferWorkBgColor}
                isFullW={true}
                onChange={(e) => {
                  formik.setFieldValue('transferWorkBgColor', e.target.value);
                }}
              />
              <ColorForm
                name="transferWorkForecolor"
                label="振替出勤日の文字色"
                color={formik.values.transferWorkForeColor}
                isFullW={true}
                onChange={(e) => {
                  formik.setFieldValue('transferWorkForeColor', e.target.value);
                }}
              />
            </div>
          </FormField>

          <FormField>
            <div style={{ display: 'inline-flex', width: '300px' }}>
              <ColorForm
                name="transferHolidayBgColor"
                label="振替休日の背景色"
                color={formik.values.transferHolidayBgColor}
                isFullW={true}
                onChange={(e) => {
                  formik.setFieldValue('transferHolidayBgColor', e.target.value);
                }}
              />

              <ColorForm
                name="transferHolidayForeColor"
                label="振替休日の文字色"
                color={formik.values.transferHolidayForeColor}
                isFullW={true}
                onChange={(e) => {
                  formik.setFieldValue('transferHolidayForeColor', e.target.value);
                }}
              />
            </div>
          </FormField>
        </div>
      </div>
    </>
  );
};

export default EmploymentVacationSettings;
