import { createOrUpdateAdvertising, getAdvertising } from 'api/advertising';
import AdvertisingDomain from 'domain/advertising';
import { useFormik } from 'formik';
import useToastNotification from 'hooks/useToastNotification';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

type AdvertisingDomainKey = keyof Pick<AdvertisingDomain, 'advertisingPlanMstCode'|'advertisingPlanMstName'|'dispOrder'>;

export const useAdvertisingCreateForm = () => {
  const { advertisingPlanMstCode } = useParams();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmModalCustomOpen, setConfirmModalCustomOpen] = useState(false);
  const [contentModalCustom, setContentModalCustom] = useState('');
  const { successNotification, errorNotification } = useToastNotification();
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const history = useHistory();

  const onSubmit = async (val: any) => {
    if (val.advertisingPlanMstCode === undefined || val.advertisingPlanMstCode === null) {
      errorNotification('コードを入力してください。');
      return;
    }
    if (val.advertisingPlanMstName === undefined || val.advertisingPlanMstName === null) {
      errorNotification('名称を入力してください。');
      return;
    }
    if (val.dispOrder === undefined || val.dispOrder === null) {
      errorNotification('並び順を入力してください。');
      return;
    }
    if (!confirmModalOpen && !confirmModalCustomOpen) {
      setConfirmModalOpen(true);
      return;
    }

    const submitObj = !advertisingPlanMstCode ? {
      companyCode,
      advertisingPlanMstCode: val.advertisingPlanMstCode,
      advertisingPlanMstName: val.advertisingPlanMstName,
      dispOrder: val.dispOrder,
      createDate: new Date(),
      createUser: staffName,
      updateDate: new Date(),
      updateUser: staffName,
    } : {
      advertisingPlanMstCode: val.advertisingPlanMstCode,
      advertisingPlanMstName: val.advertisingPlanMstName,
      dispOrder: val.dispOrder,
      updateDate: new Date(),
      updateUser: staffName,
    };

    createOrUpdateAdvertising(!!advertisingPlanMstCode, !!isConfirm, submitObj)
      .then((response: any) => {
        successNotification('更新しました。');
        history.push('/salesAdvertisingPlan/advertisingMaster');
      })
      .catch((error: any) => {
        setIsConfirm(false);
        setConfirmModalOpen(false);
        setConfirmModalCustomOpen(false);
        if (error.response.status === 406) {
          let strCode = '';
          const advertisingMst = error.response.data;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < advertisingMst.length; i++) {
            const code = advertisingMst[i];
            strCode += `${code.advertisingPlanMstCode}, `;
          }
          strCode = strCode.slice(0, strCode.length - 2);
          setContentModalCustom(`既に同じ支払メディア名が登録されているますが、よろしいですか？（支払メディアコード：${strCode})`);
          setConfirmModalCustomOpen(true);
          setIsConfirm(true);
        // eslint-disable-next-line max-len
        } else if (error.response && error.response.data && error.response.data.errors.count !== 0) {
          errorNotification(`${error.response.data.errors[0].defaultMessage}`);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  };

  const formik = useFormik({
    initialValues: AdvertisingDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    if (advertisingPlanMstCode) {
      getAdvertising(`${advertisingPlanMstCode}`).then((response: any) => {
        formik.setValues(response);
        return true;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertisingPlanMstCode]);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmModalCustom = useCallback(() => {
    setIsConfirm(false);
    setConfirmModalCustomOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    history,
    confirmModalCustomOpen,
    closeConfirmModalCustom,
    contentModalCustom,
  };
};


export default useAdvertisingCreateForm;
