
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterOrganizeSettingLayout from 'components/organismos/master/labor/MasterOrganizeSettingLayout';

const MasterOrganizeSettingPage = () => (
  <SidebarTemplate pageTitle="シフト設定">
    <MasterOrganizeSettingLayout />
  </SidebarTemplate>

);

export default MasterOrganizeSettingPage;
