import { formatNumber, formatPercent } from "utility/formatUtil";

export interface YearlySalesManagementReport {
  orgCode: string,
  orgName: string,
  areaName: string,
	businessCategoryName: string,
	items: Array<YearlySalesManagementReportData>,
}

export interface YearlySalesManagementReportData {
  orgCode: string,
  targetMonth: Date,
  orgName: string,
  netSales: number,
  salesBudget: number,
  perSalesBudget: number,
  netSalesPreviousYear: number,
  perPrevSales: number,
  areaName: number,
  businessCategoryName: number,
  guestCnt: number,
  guestCntPreviousYear: number,
  perPrevGuestCnt: number,
  customers: number,
  customersPreviousYear: number,
  perPrevCustomers: number,
}

export default class YearlySalesManagementReportDomain {
  constructor(private rawData: YearlySalesManagementReport) {
    // do nothing
  }

  static generateInitial(): YearlySalesManagementReportDomain {
    return new YearlySalesManagementReportDomain({
      orgCode: '',
      orgName: '',
      areaName: '',
      businessCategoryName: '',
      items: [],
    });
  }

  getRawData(): YearlySalesManagementReport {
    return this.rawData;
  }

  /** 出力データ（CSV出力など） */
  getOutputData(): Array<any> {
    return [
      this.orgCode,
      this.orgName,
      ...this.items?.flatMap((item: YearlySalesManagementReportData) => [
        formatNumber(item.netSales),
        formatNumber(item.salesBudget),
        formatPercent(item.perSalesBudget),
        formatNumber(item.netSalesPreviousYear),
        formatPercent(item.perPrevSales),
        formatNumber(item.guestCnt),
        formatNumber(item.guestCntPreviousYear),
        formatPercent(item.perPrevGuestCnt),
        formatNumber(item.customers),
        formatNumber(item.customersPreviousYear),
        formatPercent(item.perPrevCustomers)
      ])
    ]
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  get areaName(): string {
    return this.rawData.areaName;
  }

  get businessCategoryName(): string {
    return this.rawData.businessCategoryName;
  }

  get items(): Array<YearlySalesManagementReportData> {
    return this.rawData.items;
  }

}
