export interface SmartHRSyncReportList {
    sync_date: Date,
    sync_status: string,
    staff_code: string,
    staff_name: string,
    department_name: string,
    position: string,
    employment: string,
    status: string,
    err_message: string
  }

export default class SmartHRSyncReportListDomain {
  constructor(private rawData: SmartHRSyncReportList) {
    // do nothing
  }

  static generateInitial(): SmartHRSyncReportListDomain {
    return new SmartHRSyncReportListDomain({
      sync_date: new Date(),
      sync_status: '',
      staff_code: '',
      staff_name: '',
      department_name: '',
      position: '',
      employment: '',
      status: '',
      err_message: ''
    });
  }

  getRawData(): SmartHRSyncReportList {
    return this.rawData;
  }

  get syncDate(): Date {
    return this.rawData.sync_date;
  }

  set syncDate(syncDate: Date) {
    this.rawData.sync_date = syncDate;
  }

  get syncStatus(): string {
    return this.rawData.sync_status;
  }

  set syncStatus(syncStatus: string) {
    this.rawData.sync_status = syncStatus;
  }

  get staffCode(): string {
    return this.rawData.staff_code;
  }

  set staffCode(staffCode: string) {
    this.rawData.staff_code = staffCode;
  }

  get staffName(): string {
    return this.rawData.staff_name;
  }

  set staffName(staffName: string) {
    this.rawData.staff_name = staffName;
  }

  get departmentName(): string {
    return this.rawData.department_name;
  }

  set departmentName(departmentName: string) {
    this.rawData.department_name = departmentName;
  }

  get position(): string {
    return this.rawData.position;
  }

  set position(position: string) {
    this.rawData.position = position;
  }

  get employment(): string {
    return this.rawData.employment;
  }

  set employment(employment: string) {
    this.rawData.employment = employment;
  }

  get status(): string {
    return this.rawData.status;
  }

  set status(status: string) {
    this.rawData.status = status;
  }

  get errMsg(): string {
    return this.rawData.err_message;
  }

  set errMsg(errMsg: string) {
    this.rawData.err_message = errMsg;
  }
}
