/** @jsx jsx */
import React, { MouseEventHandler } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { textFontSize, productColor } from 'components/styles';
import { Link } from 'react-router-dom';

const style = (color: string, padding: string, margin: string, hoverColor: string) => css({
  color,
  margin,
  padding,
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  fontFamily: 'inherit',
  fontSize: textFontSize.re,
  textAlign: 'left',
  width: '100%',
  ':hover': {
    backgroundColor: hoverColor,
  },
  ':disabled': {
    cursor: 'not-allowed',
  },
});

interface ClickableLinkProps {
  onClick?: MouseEventHandler;
  to?: string;
  disabled?: boolean;
  color?: string;
  hoverColor?: string;
  padding?: string;
  margin?: string;
  target?: string;
  customCss?: SerializedStyles;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;

}

const ClickableLink: React.FC<ClickableLinkProps> = ({
  children,
  onClick = () => {},
  to,
  disabled = false,
  color = productColor.primary,
  hoverColor = productColor.primaryM95,
  padding = '6px 8px',
  margin = '0',
  target,
  customCss,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}) => (
  <React.Fragment>
    {
      to
        ? (
          <Link to={to} target={target}>
            <div>
              <button
                type="button"
                css={css(style(color, padding, margin, hoverColor), customCss)}
                disabled={disabled}
              >
                {children}
              </button>
            </div>
          </Link>
        )
        : (
          <button
            type="button"
            css={css(style(color, padding, margin, hoverColor), customCss)}
            disabled={disabled}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {children}
          </button>
        )
    }
  </React.Fragment>
);

export default ClickableLink;
