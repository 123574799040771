import React from 'react';
import { css } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BodyText from 'components/atoms/BodyText';
import RadioSelectForm from 'components/molecules/RadioSelectForm';

const EmploymentRegistrationStatusRadio: React.FC<{
  employmentRegistrationStatus: string;
  setEmploymentRegistrationStatus: (employmentRegistrationStatus: string) => void;
}> = ({ employmentRegistrationStatus, setEmploymentRegistrationStatus }) => (
  <FlexBox
    customStyle={css({
      padding: '8px',
    })}
  >
    <FlexBoxItem basis="10%">
      <BodyText> 雇用形態 </BodyText>
    </FlexBoxItem>
    <FlexBoxItem basis="90%">
      <RadioSelectForm
        name="EmploymentRegistrationStatusRadio"
        items={[
          {
            value: '-1',
            label: 'すべて',
          },
          {
            value: '0',
            label: '未設定',
          },
          {
            value: '1',
            label: '設定済',
          }
        ]}
        value={employmentRegistrationStatus}
        setValue={(e) => {
          setEmploymentRegistrationStatus(e.target.value);
        }}
      />
    </FlexBoxItem>
  </FlexBox>
);

export default EmploymentRegistrationStatusRadio;
