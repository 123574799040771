/** @jsx jsx */
import { useState, useMemo, useEffect } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import { useOrgTreesOptionsAll } from 'hooks/useOrgTreesOptions';
import moment from 'moment';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import FormField from 'components/atoms/Form/FormField';
import DatePicker from 'components/molecules/DatePicker';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import SelectForm from 'components/molecules/SelectForm';
import StampHistoryDomain from 'domain/master/atendenceManagement/stampHistory';
import useToastNotification from 'hooks/useToastNotification';
import { getSearchDateClosing } from 'api/stampHistory';
import Lightbox from 'react-image-lightbox';
import { utilityColor } from 'components/styles';
import BlockUI from 'components/molecules/BlockUi';
import { css, jsx } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import { useStampHistoryV2 } from './hooks';
import 'react-image-lightbox/style.css';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';

const StampHistoryLayout = () => {
  const d = new Date();
  const y = d.getFullYear();
  const m = d.getMonth();
  const [targetDateFrom, setTargetDateFrom] = useState(new Date(y, m, 1));
  const [targetDateTo, setTargetDateTo] = useState(new Date(y, m + 1, 0));

  const functionType = 2;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  useEffect(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getSearchDateClosing(companyCode).then((data) => {
      const dateFrom = new Date();
      setTargetDateFrom(new Date(data[0]));
      setTargetDateTo(new Date(data[1]));
      if (data[0] == null || data[1] == null) {
        setTargetDateFrom(
          new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1),
        );
        setTargetDateTo(
          new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0),
        );
      }
    });
  }, []);

  const { errorNotification } = useToastNotification();

  const orgTreesOptions = useOrgTreesOptionsAll(
    moment(targetDateFrom).format('YYYY-MM-DD'),
    moment(targetDateTo).format('YYYY-MM-DD'),
    undefined,
    false,
    true,
  );
  const [orgCode, setorgCode] = useState(
    sessionStorage.getItem('loginUser.orgCode') || '',
  );

  // const { stampHistory, blocking } = useStampHistory(orgCode || '', moment(targetDateFrom).format('YYYY年MM月DD日'),
  //   moment(targetDateTo).format('YYYY年MM月DD日'));
  const [orgCategoryAry, setOrgCategoryAry] = useState<Array<Array<string>>>(
    [],
  );

  const { stampHistory, blocking } = useStampHistoryV2(
    orgCode || '',
    moment(targetDateFrom).format('YYYY年MM月DD日'),
    moment(targetDateTo).format('YYYY年MM月DD日'),
    functionType,
    orgCategoryAry,
  );
  // useStampHistory(orgCode || '', moment(targetDateFrom).format('YYYY年MM月DD日'),
  //   moment(targetDateTo).format('YYYY年MM月DD日'));

  // 対象日の範囲が１年を超えていた場合、エラーとする
  const [stampHistoryNull, setstampHistoryNull] = useState(true);
  useEffect(() => {
    const startDay = new Date(
      Date.parse(moment(targetDateFrom, 'YYYY年MM月DD日').format('YYYY/MM/DD')),
    );
    const endDay = new Date(
      moment(targetDateTo, 'YYYY年MM月DD日').format('YYYY/MM/DD'),
    );
    const startDayNextYear = new Date(
      startDay.getFullYear() + 1,
      startDay.getMonth(),
      startDay.getDate(),
    );
    if (endDay.getTime() >= startDayNextYear.getTime()) {
      errorNotification('期間の範囲は１年以内で入力してください');
      setstampHistoryNull(false);
    } else {
      setstampHistoryNull(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDateFrom, targetDateTo]);
  const columns: Array<Column<StampHistoryDomain>> = useMemo(
    () => [
      {
        Header: '店舗コード',
        accessor: 'orgCode',
      },
      {
        Header: '勤務先',
        accessor: 'orgName',
      },
      {
        Header: '実打刻場所',
        accessor: 'timeRecoderOrgName',
      },
      {
        Header: '打刻方法',
        accessor: 'stampModeName',
      },
      {
        Header: '撮影写真',
        id: 'imageURL',
        accessor: 'orgCode',
        disableSortBy: true,
        Cell: (cell: {
          row: { isExpanded: boolean; original: any };
          page: [];
        }) => {
          const [imageOPen, setimageOpen] = useState(false);
          const imageURL = cell.row.original.photoFullpath;

          // ----page-- many image
          let StringPhoto = '';
          let descript = '';
          cell.page.map((element: any) => {
            StringPhoto += `${element.original.photoFullpath},`;
            descript += `${element.original.staffCode}/${
              element.original.staffName
            }/${element.original.stampName}/${moment(
              element.original.stampTime,
            ).format('YYYY/MM/DD HH:mm')},`;
            return StringPhoto;
          });
          const images = StringPhoto.slice(0, -1).split(',');
          const Arraydescript = descript.slice(0, -1).split(',');

          // Reset the initial value when closing the model
          const [photoIndex, setphotoIndex] = useState(
            images.indexOf(imageURL),
          );
          useEffect(() => {
            setphotoIndex(images.indexOf(imageURL));
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [imageOPen]);

          return (
            <div>
              {imageURL !== null && (
                <div style={{ textAlign: 'center' }}>
                  <button
                    style={{
                      backgroundColor: '#fff',
                      border: 'none',
                      fontWeight: 400,
                      cursor: 'pointer',
                    }}
                    type="button"
                    onClick={() => setimageOpen(true)}
                  >
                    <img src={imageURL} alt={imageURL} width={20} height={20} />
                  </button>

                  {imageOPen && (
                    <Lightbox
                      mainSrc={images[photoIndex]}
                      nextSrc={images[(photoIndex + 1) % images.length]}
                      prevSrc={
                        images[(photoIndex + images.length - 1) % images.length]
                      }
                      onCloseRequest={() => setimageOpen(false)}
                      onMovePrevRequest={() => setphotoIndex(
                        (photoIndex + images.length - 1) % images.length,
                      )}
                      onMoveNextRequest={() => setphotoIndex((photoIndex + 1) % images.length)}
                      imageCaption={Arraydescript[photoIndex]}
                      imageTitle="撮影写真"
                      animationDuration={500}
                    />
                  )}
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: 'スタッフコード',
        accessor: 'staffCode',
      },
      {
        Header: '氏名',
        accessor: 'staffName',
      },
      {
        Header: '打刻種別',
        accessor: 'stampName',
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => {
          const { errorMessage } = cell.row.original;
          const { stampName } = cell.row.original;
          return (
            <div>
              <div>
                {(errorMessage === null || errorMessage === '') && (
                  <div>{stampName}</div>
                )}
              </div>
              <div>
                {errorMessage !== null && errorMessage !== '' && (
                  <div
                    style={{ color: utilityColor.error }}
                    data-tip={errorMessage}
                  >
                    {stampName}
                    ...
                    <ReactTooltip html={true} />
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: '打刻時刻',
        accessor: 'stampTimeStr',
      },
    ],
    [],
  );

  return (
    <BlockUI blocking={blocking}>
      <div>
        <FormContents>
          <FormField>
            <FlexBox>
              <FlexBoxItem width="100px">
                <FormLabel label="期間" />
              </FlexBoxItem>
              <FlexBoxItem>
                <DatePicker
                  dateFormat="yyyy年MM月dd日"
                  date={targetDateFrom}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      setTargetDateFrom(date);
                    }
                  }}
                  isFullWidth={true}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <span style={{ margin: '0 30px' }}>～</span>
              </FlexBoxItem>
              <FlexBoxItem>
                <DatePicker
                  dateFormat="yyyy年MM月dd日"
                  date={targetDateTo}
                  changeDate={(date: Date) => {
                    if (date !== null) {
                      setTargetDateTo(date);
                    }
                  }}
                  isFullWidth={true}
                />
              </FlexBoxItem>
            </FlexBox>
          </FormField>

          <FormField displayBlock={true}>
            <OrganizationFilteredTree
              functionType={functionType}
              staffCode={staffCode}
              targetdayForm={targetDateFrom}
              targetdayTo={targetDateTo}
              orgLabel="勤務先"
              initOrgValue={String(orgCode)}
              orgCallback={(args: string | Array<string>) => {
                setorgCode(String(args));
              }}
              orgCategoryCallback={(args: any) => {
                setOrgCategoryAry(args);
              }}
              addAllItem={true}
              styles={{
                label: {
                  toggleSwitch: css({ marginLeft: '0px' }),
                },
                toggle: {
                  width: '19rem',
                  marginLeft: '0.4rem',
                },
              }}
            />
            {/* <SelectForm
              label="組織名"
              name="orgCode"
              value={String(orgCode)}
              setValue={(v) => {
                setorgCode(v);
              }}
              options={orgTreesOptions}
              description=""
            /> */}
          </FormField>

          <div
            css={css`
              table th:nth-child(1) {
                width: 65px;
              }
              table th:nth-child(2) {
                width: 100px;
              }
              table th:nth-child(3) {
                width: 120px;
              }
              table th:nth-child(4) {
                width: 60px;
              }
              table th:nth-child(5) {
                width: 80px;
              }
              table th:nth-child(6) {
                width: 90px;
              }
              table th:nth-child(7) {
                width: 100px;
              }
              table th:nth-child(8) {
                width: 80px;
              }
              table th:nth-child(9) {
                width: 120px;
              }
            `}
          >
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
              <DataTable
                columns={columns}
                data={stampHistoryNull ? stampHistory : []}
                isGlobalFilter={true}
                minWidth="100%"
                sortBy={[
                  { id: 'stampTimeStr', desc: true },
                ]}
              />
            </div>
          </div>
        </FormContents>
      </div>
    </BlockUI>
  );
};

export default StampHistoryLayout;
