import moment from 'moment';
import ApiClient from './ApiClient';

export const getSmartHRList = async (params: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v3/smartHr/error/${companyCode}`, params);
  return response.data;
};

export default {};
