import _ from "lodash";

export interface MonthlyDiscountSummaryReport {
  /** 値引・割引一覧 */
  detailList: Array<MonthlyDiscountSummaryReportDetail>,
  /** 値引・割引ヘッダ */
  headerList: Array<MonthlyDiscountSummaryReportDiscount>,
}

export interface MonthlyDiscountSummaryReportDetail {
  /** 値引種別コード */
  orgCode: string,
  /** 値引種別名 */
  orgName: string,
  /** 値引・割引リスト */
  discountList: Array<MonthlyDiscountSummaryReportDiscount>,
}

export interface MonthlyDiscountSummaryReportDiscount {
  /** 値引種別コード */
  discountTypeMstCode: string,
  /** 値引種別名 */
  discountTypeMstName: string,
  /** 値引金額 */
  discountAmount: number,
  /** 値引件数 */
  discountCnt: number,
}

/** 月次帳票 値引・割引集計表  */
export default class MonthlyDiscountSummaryReportDomain {

  private _detailList: Array<MonthlyDiscountSummaryReportDetail>;
  private _headerList: Array<MonthlyDiscountSummaryReportDiscount>;

  constructor(private rawData: MonthlyDiscountSummaryReport) {
    this._detailList = rawData.detailList || [];
    this._headerList = rawData.headerList || [];
  }

  static generateInitial(): MonthlyDiscountSummaryReportDomain {
    return new MonthlyDiscountSummaryReportDomain({
      detailList: [],
      headerList: [],
    });
  }

  /** 出力データ（CSV出力など） */
  static getOutputDetailData(detailList: Array<MonthlyDiscountSummaryReportDetail>): Array<any> {
    return detailList.map((detail: MonthlyDiscountSummaryReportDetail) => [
      detail.orgCode,
      detail.orgName,
      ...detail.discountList?.flatMap((discount: MonthlyDiscountSummaryReportDiscount) => [
        discount.discountAmount,
        discount.discountCnt
      ])
    ])
  }

  getRawData(): MonthlyDiscountSummaryReport {
    return this.rawData;
  }

  get detailList(): Array<MonthlyDiscountSummaryReportDetail> {
    return this._detailList;
  }

  get headerList(): Array<MonthlyDiscountSummaryReportDiscount> {
    return this._headerList;
  }

}
