
import { useFormik } from 'formik';
import { useState, useCallback, useEffect } from 'react';
import useToastNotification from 'hooks/useToastNotification';
import { useHistory } from 'react-router-dom';
import DescriptionMasterDomain from 'domain/master/general/descriptionMaster';
import { saveDescriptionMSTDetail, getDescriptionMstDetail } from 'api/descriptionMaster';

const useDescriptMSTDomainForm = (descriptionId: string) => {
  // modal confirm
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const history = useHistory();
  // Modal alert
  const { successNotification, errorNotification } = useToastNotification();

  const onSubmit = async (values: DescriptionMasterDomain) => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
    } else {
      try {
        const response = await saveDescriptionMSTDetail(descriptionId, values.getRawData());
        if (!response.errors) {
          setConfirmModalOpen(false);
          let successMes = '登録しました。';
          if (descriptionId && descriptionId !== 'undefined') {
            successMes = '更新しました。';
          }
          successNotification(successMes);
          history.push('/accountTitleMst');
        }
      } catch (error) {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.length) {
          const listErr = error.response.data;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage} \n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    }
  };
  const formik = useFormik({
    initialValues: DescriptionMasterDomain.generateInitial(),
    validationSchema: false,
    onSubmit,
  });


  useEffect(() => {
    if (descriptionId && descriptionId !== 'undefined') {
      getDescriptionMstDetail(descriptionId).then((response: any) => {
        if (response) {
          formik.setValues(new DescriptionMasterDomain(response));
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionId]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
  };
};

export default useDescriptMSTDomainForm;
