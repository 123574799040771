import {
  useState, useCallback, useEffect,
} from 'react';

import {
  getDigestionItemsForSpecialHoliday,
  downloadSpecialHolidayDigestionCsv,
  getSpecialHolidayDigestionDetail,
} from 'api/holidayManagement';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import SpecialHolidayDigestionDomain from 'domain/master/holidayManagement/specialHolidayDigestion';
import useToastNotification from 'hooks/useToastNotification';

export interface DetailItems {
  cnt: string,
  orgCode: string,
  orgName: string,
  staffCode: string,
  staffName: string,
  holidayCode: string,
  holidayName: string,
  total: string,
  specialHolidayDigestionDetailList: [],
  fromDate: Date,
  toDate: Date,
}

export const useAcquisitionStatusDomainForm = () => {
  const sessionSearchDate = sessionStorage.getItem('specialHoliday.acquisitionStatus.searchDate') || new Date().toString();
  const loadDateYearAgo = new Date(sessionSearchDate);
  loadDateYearAgo.setDate(loadDateYearAgo.getDate() + 1);
  loadDateYearAgo.setFullYear(loadDateYearAgo.getFullYear() - 1);

  const sessionStorageOrgCode = String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}6`)) !== 'null' ? (String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}6`))) : (sessionStorage.getItem('loginUser.orgCode') || '');
  const [
    paidHolidayDigestedtHistoryList, setPaidHolidayDigestedtHistoryList,
  ] = useState<Array<SpecialHolidayDigestionDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useState(sessionStorageOrgCode);
  const [searchDate, setSearchDate] = useState<Date>(new Date(sessionSearchDate));
  const [dateYearAgo, setDateYearAgo] = useState<Date>(loadDateYearAgo);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailItems, setDetailItems] = useState<DetailItems>({
    cnt: '',
    orgCode: '',
    orgName: '',
    staffCode: '',
    staffName: '',
    holidayCode: '',
    holidayName: '',
    total: '',
    specialHolidayDigestionDetailList: [],
    fromDate: new Date(),
    toDate: new Date(),
  });
  const { errorNotification } = useToastNotification();


  // データの取得
  const fetchData = useCallback(async () => {
    const response = await getDigestionItemsForSpecialHoliday(
      orgCode !== 'all' ? orgCode : '',
      String(moment(moment(searchDate).toDate()).format('YYYY/MM/DD')),
    );
    setPaidHolidayDigestedtHistoryList(
      response.map((result) => new SpecialHolidayDigestionDomain(result)),
    );
  }, [orgCode, searchDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, searchDate]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  //
  const callGetAcquisitionStatusDetail = useCallback(async (staffCode: string) => {
    try {
      await getSpecialHolidayDigestionDetail(
        staffCode,
        String(moment(moment(searchDate).toDate()).format('YYYY/MM/DD')),
      ).then((response: any) => {
        setDetailItems(response);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [errorNotification, searchDate]);

  return {
    paidHolidayDigestedtHistoryList,
    callGetAcquisitionStatusDetail,
    toggleNoManager,
    orgCode,
    setOrgCode,
    searchDate,
    setSearchDate,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    dateYearAgo,
    setDateYearAgo,
  };
};

export const useDownloadHolidayManagementAcquisitionStatus = (
  orgCode: string,
  targetDate: Date,
) => {
  const downloadHolidayManagementAcquisitionStatus = useCallback((isDownloadAll?: boolean) => {
    downloadSpecialHolidayDigestionCsv(
      isDownloadAll ? '' : orgCode,
      targetDate,
    );
  }, [orgCode, targetDate]);

  return {
    downloadHolidayManagementAcquisitionStatus,
  };
};

export default {
  useAcquisitionStatusDomainForm,
};
