import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import StaffCsvUploadForm from 'components/organismos/master/general/staff/StaffCsvUploadForm';

const StaffCsvUploadPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="スタッフインポート"
  >
    <StaffCsvUploadForm />
  </SidebarTemplate>
);

export default StaffCsvUploadPage;
