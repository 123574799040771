import ApiClient from './ApiClient';

export interface PosType {
    posNo: number;
    posName: string;
    dispOrder: number;
  }
export const getListTaxRate = async (orgCode: string):Promise<any> => {
  const params = {
    orgCode,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/salesTax/${companyCode}`, params);
  return response.data;
};

export const deleteByTaxRate = async (taxRateData: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v1/sales/master/salesTax/delete/${companyCode}`, {}, taxRateData);
  return response.data;
};


export const createOrUpdateTaxRate = async (isCreate: number, taxRateData: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isCreate,
  };
  const response = await ApiClient.post(`/v1/sales/master/salesTax/${companyCode}`, params, taxRateData);
  return response.data;
};
export const getTaxTypeList = async ():Promise<Array<any>> => {
  const response = await ApiClient.get('/v1/sales/master/salesTaxType', {});
  return response.data;
};

export const getPostTypeList = async (): Promise<Array<PosType>> => {
  const response = await ApiClient.get('/v1/posType', {});
  return response.data;
};

export default getListTaxRate;
