import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MonthlyDailyStoreLayout from 'components/organismos/master/storeManagement/report/MonthlyDailyStoreLayout.tsx';


const MonthlyDailyStore = () => (
  <SidebarTemplate pageTitle="日別店舗売上">
    <MonthlyDailyStoreLayout />
  </SidebarTemplate>
);
export default MonthlyDailyStore;
