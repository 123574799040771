import React, { useState, useCallback, useEffect } from 'react';

import FormContents from 'components/atoms/Form/FormContents';
import DatePickerForm from 'components/molecules/DatePickerForm';
import PrimaryButton from 'components/atoms/Button';
import { exportFilePDF } from 'api/attendAchievementOutput';
import moment from 'moment';
import useToastNotification from 'hooks/useToastNotification';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import SelectForm from 'components/molecules/SelectForm';
import { css } from '@emotion/core';
import { useStaffOptions } from 'hooks/useEmploymentOptions';
import BlockUI from 'components/molecules/BlockUi';
import { useHistory } from 'react-router-dom';
import { downloadOriginalPDF } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const AttendHelpLayoutList: React.FC = () => {
  const orgCode = !sessionStorage.getItem('orgCode')
    ? sessionStorage.getItem('loginUser.orgCode')
    : sessionStorage.getItem('orgCode');
  const targetDate = sessionStorage.getItem('targetDate');

  const [getSupportSourceOrg, setSupportSourceOrg] = useState(orgCode || '');
  const [staffCode, setStaffCode] = useState(sessionStorage.getItem('loginUser.staffCode') || '');

  let dateFormats = new Date();
  let setDate = moment()
    .startOf('month')
    .format('YYYY-MM-DD');
  if (targetDate) {
    dateFormats = new Date(targetDate);
    setDate = moment(dateFormats.toString()).format('YYYY-MM-DD');
  }
  const functionType = 2;
  const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
    undefined,
  );

  const [blocking, setBlocking] = useState(false);
  const [getTargetDate, setTargetDate] = useState(dateFormats);
  sessionStorage.setItem('orgCode', String(getSupportSourceOrg));

  const { errorNotification } = useToastNotification();
  const handleClick = useCallback(
    () => exportFilePDF(getSupportSourceOrg, setDate, functionType, categoryAry, staffCode)
      .then((response: any) => {
        setBlocking(false);
        downloadOriginalPDF(response, '勤怠実績出力.pdf');
      })
      .catch((error) => {
        setBlocking(false);
        errorNotification(
          '対象月、対象店舗において在籍するスタッフがいませんでした。',
        );
      }),
    [categoryAry, errorNotification, getSupportSourceOrg, setDate, staffCode],
  );


  const d = new Date();
  const y = d.getFullYear();
  const m = d.getMonth();

  const [targetDateFrom, setTargetDateFrom] = useState(new Date(y, m, 1));
  const [targetDateTo, setTargetDateTo] = useState(new Date(y, m + 1, 0));

  const staffOptions = useStaffOptions(
    getSupportSourceOrg,
    targetDateFrom,
    targetDateTo,
    functionType,
    categoryAry,
  );

  useEffect(() => {
    const currenStaff = staffOptions.find(item => item.value === sessionStorage.getItem('loginUser.staffCode'))
    if (!currenStaff) {
      setStaffCode('all')
    } else {
      setStaffCode(currenStaff.value)
    }
  }, [staffOptions])

  const history = useHistory();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === getSupportSourceOrg);

  const checkUserRole = getSupportSourceOrg !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  return (
    <div>
      <BlockUI blocking={blocking}>
        <FormContents>
          <DatePickerForm
            dateFormat="yyyy年MM月"
            label="営業月"
            date={getTargetDate}
            changeDate={(date: Date) => {
              if (date !== null) {
                sessionStorage.setItem('targetDate', String(date));
                setTargetDate(date);
                setTargetDateFrom(new Date(date.getFullYear(), date.getMonth(), 1));
                setTargetDateTo(new Date(date.getFullYear(), date.getMonth() + 1, 0));
              }
            }}
            showMonthYearPicker={true}
            isFullWidth={true}
            required={false}
          />
          <OrganizationFilteredTree
            functionType={functionType}
            staffCode={staffCode}
            orgLabel="組織名"
            initOrgValue={String(getSupportSourceOrg)}
            orgCallback={(args: string | Array<string>) => {
              setSupportSourceOrg(String(args));
            }}
            orgCategoryCallback={
            (args: Array<string>) => {
              setCategoryAr(args);
            }
          }
            targetdayForm={new Date(getTargetDate.getFullYear(), getTargetDate.getMonth(), 1)}
            targetdayTo={new Date(getTargetDate.getFullYear(), getTargetDate.getMonth() + 1, 0)}
            styles={{
              label: {
                toggleSwitch: css({ marginLeft: '0px' }),
              },
              toggle: {
                width: '19rem',
                marginLeft: '0.4rem',
              },
            }}
          />

          <SelectForm
            label="スタッフ"
            name="staffCode"
            value={String(staffCode)}
            setValue={(v) => { setStaffCode(v); }}
            options={staffOptions}
            description=""
          />
          <div style={{ marginTop: '15px' }}>
            <PrimaryButton ghost={false} text="印刷" onClick={() => { setBlocking(true); handleClick(); }} />
          </div>
        </FormContents>
      </BlockUI>
    </div>
  );
};

export default AttendHelpLayoutList;
