/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { EventProps } from 'react-big-calendar';

const style = css({
  textAlign: 'center',
});

/**
 * 月表示時のイベント
 * - 表示ラベルのカスタマイズ
 */
// TODO クリックできないようにする
const MonthEvent: React.FC = (props) => {
  // console.log(props);
  const { title } = props as EventProps;
  return (
    <div css={style}>
      {title}
    </div>
  );
};

export default MonthEvent;
