import React, { useMemo, useState, useEffect } from 'react';

import FormContents from 'components/atoms/Form/FormContents';
import DataTable from 'components/organismos/DataTable/DataTable';
import AttendHelpListDomain from 'domain/master/attend/attendHelpList';
import { Column } from 'react-table';
import DatePickerForm from 'components/molecules/DatePickerForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import { css } from '@emotion/core';
import Table from 'components/molecules/Table';
import moment from 'moment';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { useHistory } from 'react-router-dom';
import {
  useAttendHelpList,
  useGetAttendHelpListV2,
  useGetSubTotalListV2,
  useDownload,
} from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const styles = css({
  width: '100%',
  border: 'none',
  borderSpacing: '0px 1.5px',
  marginTop: '46px',
  textAlign: 'center',
});

const AttendHelpLayoutList: React.FC = () => {
  const { handleDownloadSupportListCsv } = useDownload();

  const { formik, functionRoles } = useAttendHelpList();
  const [getSupportSourceOrg, setSupportSourceOrg] = useState('');
  const [getSupportDestinationOrg, setSupportDestinationOrg] = useState('');

  const functionType = 2;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateFrom = moment(new Date(formik.values.targetDateFrom)).format(
    'YYYY-MM-DD',
  );
  const targetDateTo = moment(new Date(formik.values.targetDateTo)).format(
    'YYYY-MM-DD',
  );
  // const dateFrom = moment([new Date(targetDateFrom).getFullYear(),
  //   new Date(targetDateFrom).getMonth(), new Date(targetDateFrom).getDate()]);
  // const dateTo = moment([new Date(targetDateTo).getFullYear(),
  //   new Date(targetDateTo).getMonth(), new Date(targetDateTo).getDate()]);

  const [categoryArySource, setCategoryArySource] = useState<
    Array<Array<string>>
  >([]);
  const [categoryAryDestination, setCategoryAryDestination] = useState<
    Array<Array<string>>
  >([]);

  const { getAttendHelpList } = useGetAttendHelpListV2(
    targetDateFrom,
    targetDateTo,
    getSupportSourceOrg,
    getSupportDestinationOrg,
    functionType,
    categoryArySource,
    categoryAryDestination,
    staffCode,
  );

  // const { getSubTotalList } = useGetSubTotalList(
  //   targetDateFrom,
  //   targetDateTo,
  //   getSupportSourceOrg,
  //   getSupportDestinationOrg,
  // );
  const { getSubTotalList } = useGetSubTotalListV2(
    targetDateFrom,
    targetDateTo,
    getSupportSourceOrg,
    getSupportDestinationOrg,
    functionType,
    categoryArySource,
    categoryAryDestination,
    staffCode,
  );
  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  // eslint-disable-next-line no-nested-ternary
  const sessionSupportSourceStore = getSupportSourceOrg !== 'all' ? (sessionStorage.getItem('SessionSupportSourceStore') ? sessionStorage.getItem('SessionSupportSourceStore') : '') : '';
  // eslint-disable-next-line no-nested-ternary
  const sessionSupportDestinationStore = getSupportDestinationOrg !== 'all' ? (sessionStorage.getItem('SessionSupportDestinationStore') ? sessionStorage.getItem('SessionSupportDestinationStore') : '') : '';

  const targetStoresData = `支援元店舗：${sessionSupportSourceStore}`;

  const supportDestinationStore = `支援先店舗：${sessionSupportDestinationStore}`;

  const targetPeriodData = `対象期間：${moment(targetDateFrom).format('YYYY/MM/DD')}~${moment(targetDateTo).format('YYYY/MM/DD')}`;

  const headerInformation = [
    ['支援元店舗コード', '支援元組織名', '支援先店舗コード', '支援先組織名', 'スタッフコード', 'スタッフ名', '出勤日数', '総労働時間', '人件費'],
  ];

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === getSupportSourceOrg || getSupportDestinationOrg);

  const checkUserRole = getSupportSourceOrg !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  const searchResult: any[] = [];
  useEffect(() => {
    getAttendHelpList.map((item: any, index: any) => {
      searchResult.push([
        item.belongOrgCode || '',
        item.belongOrgName || '',
        item.orgCode || '',
        item.orgName || '',
        item.staffCode || '',
        item.staffName || '',
        item.numberSubtotalDays || 0,
        item.subtotalTimeDisp || '',
        checkUserRole && checkUserRole.usabilityGeneralItem1 === 0 ? '-' : item.totalCost,
      ]);
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAttendHelpList, searchResult, checkUserRole]);

  const data = {
    footer: '',
    formName: '他店舗支援',
    targetPeriod: targetPeriodData,
    targetStores: targetStoresData,
    supportDestinationStore,
    headerInformation,
    searchResult,
    totalInformation: [],
  };

  const handleExportCSV = () => handleDownloadSupportListCsv(data);

  const history = useHistory();

  const columns: Array<Column<AttendHelpListDomain>> = useMemo(
    () => [
      {
        Header: '支援元店舗コード',
        accessor: 'belongOrgCode',
      },
      {
        Header: '支援元組織名',
        accessor: 'belongOrgName',
        sortType: 'basic'
      },
      {
        Header: '支援先店舗コード',
        accessor: 'orgCode',
      },
      {
        Header: '支援先組織名',
        accessor: 'orgName',
      },
      {
        Header: 'スタッフコード',
        accessor: 'staffCode',
      },
      {
        Header: 'スタッフ名',
        accessor: 'staffName',
      },
      {
        Header: '出勤日数',
        accessor: 'subtotalDays',
      },
      {
        Header: '総労働時間',
        accessor: 'subtotalTimeDisp',
      },
      {
        Header: '人件費',
        accessor: 'totalCost',
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendHelpListDomain };
          data: Array<AttendHelpListDomain>;
        }) => (checkUserRole && checkUserRole.usabilityGeneralItem1 === 1 ? (
          <div
            style={{
              textAlign: 'right',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {formatter.format(cell.row.original.totalCost)}円
          </div>
        )
          : (
            <div
              style={{
                textAlign: 'right',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              -
            </div>
          )),
      },
    ],
    [formatter, checkUserRole],
  );

  const sortBy = React.useMemo(
    () => [
      {
        id: 'belongOrgName',
        desc: false,
      },
    ],
    [],
  );
  return (
    <div>
      <FormContents>
        <div style={{ width: '300px' }}>
          <FlexBox>
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label="期間"
              date={formik.values.targetDateFrom}
              isFullWidth={true}
              changeDate={(date: Date) => {
                if (date !== null) {
                  formik.setFieldValue('setTargetDateFrom', date);
                }
              }}
            />
            <span style={{ margin: '0 30px', marginTop: '24px' }}>～</span>
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label="期間"
              isFullWidth={true}
              date={formik.values.targetDateTo}
              changeDate={(date: Date) => {
                if (date !== null) {
                  formik.setFieldValue('setTargetDateTo', date);
                }
              }}
            />
          </FlexBox>
        </div>
        <OrganizationFilteredTree
           functionType={functionType}
           staffCode={staffCode}
           targetdayForm={moment(
             new Date(formik.values.targetDateFrom),
	           ).toDate()}
           targetdayTo={moment(new Date(formik.values.targetDateTo)).toDate()}
           orgLabel="支援元店舗"
           initOrgValue={String(getSupportSourceOrg)}
           orgCallback={(args: string | Array<string>) => {
             setSupportSourceOrg(String(args));
           }}
           orgNameCallback={(args: string | Array<string>) => {
             sessionStorage.setItem('SessionSupportSourceStore', String(args));
           }}
           orgCategoryCallback={(args: Array<Array<string>>) => {
             setCategoryArySource(args);
           }}
           addAllItem={true}
           styles={{
             label: {
               toggleSwitch: css({ marginLeft: '0px' }),
             },
             toggle: {
               width: '13rem',
             },
           }}
	      />
 
        <OrganizationFilteredTree
          functionType={functionType}
          staffCode={staffCode}
          targetdayForm={moment(
            new Date(formik.values.targetDateFrom),
          ).toDate()}
          targetdayTo={moment(new Date(formik.values.targetDateTo)).toDate()}
          orgLabel="支援先店舗"
          initOrgValue={String(getSupportDestinationOrg)}
          orgCallback={(args: string | Array<string>) => {
            setSupportDestinationOrg(String(args));
          }}
          orgNameCallback={(args: string | Array<string>) => {
            sessionStorage.setItem('SessionSupportDestinationStore', String(args));
          }}
          orgCategoryCallback={(args: Array<Array<string>>) => {
            setCategoryAryDestination(args);
          }}
          addAllItem={true}
          styles={{
            label: {
              toggleSwitch: css({ marginLeft: '0px' }),
            },
            toggle: {
              width: '13rem',
            },
          }}
        />
        <FlexBox>
          <FlexBoxItem>
            {checkUserRole && checkUserRole.downloadFlag === 1 && (
              <IconLabelButton
                onClick={handleExportCSV}
                iconType="download"
                text=" CSV出力"
              />
            )}
          </FlexBoxItem>
        </FlexBox>
        <div
          css={css`
            table th:nth-child(1) {
              width: 140px;
            }
            table th:nth-child(2) {
              width: 140px;
            }
            table th:nth-child(3) {
              width: 140px;
            }
            table th:nth-child(4) {
              width: 140px;
            }
            table th:nth-child(5) {
              width: 120px;
            }
            table th:nth-child(6) {
              width: 120px;
            }
            table th:nth-child(7) {
              width: 80px;
            }
            table th:nth-child(8) {
              width: 80px;
            }
            table th:nth-child(9) {
              width: 80px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <DataTable
              columns={columns}
              data={getAttendHelpList}
              sortBy={sortBy}
              minWidth="1112px"
            />
          </div>
        </div>
        <div
          className="total-table"
          style={{ maxWidth: '800px', margin: '0 auto', marginTop: '100px' }}
        >
          <Table css={styles}>
            <thead
              style={{
                textAlign: 'center',
                backgroundColor: '#F2F5F9',
                padding: '15px 0',
              }}
            >
              <tr
                style={{
                  textAlign: 'center',
                  padding: '15px 0',
                  fontSize: '14px',
                  height: '30px',
                  color: '#333333',
                }}
              >
                <td>スタッフ数合計</td>
                <td>出勤日数合計</td>
                <td>総労働時間合計</td>
                <td>人件費合計</td>
              </tr>
            </thead>
            <tbody style={{ textAlign: 'center', backgroundColor: '#F2F5F9' }}>
              <tr
                style={{
                  borderTop: '1px solid #CEDAEA',
                  borderBottom: '1px solid #CEDAEA',
                  height: '40px',
                }}
              >
                <td>
                  {getSubTotalList ? `${getSubTotalList.staffSubtotal}人` : 0}
                </td>
                <td>
                  {getSubTotalList
                    ? `${getSubTotalList.attendanceDaysSubtotal}日`
                    : ''}
                </td>
                <td>
                  {getSubTotalList
                    ? getSubTotalList.allWorkTimeSubtotalDisp
                    : ''}
                </td>
                <td>
                  {getSubTotalList ? `${getSubTotalList.totalCostDisp}円` : ''}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </FormContents>
    </div>
  );
};

export default AttendHelpLayoutList;
