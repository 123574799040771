
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AccountTitleSubMSTForm from 'components/organismos/master/general/accountTitleSubMST/AccountTitleSubMSTForm';
import { useParams } from 'react-router-dom';

const AccountTitleSubMSTAddPage: React.FC = () => {
  const { accountTitleSubId } = useParams();
  return (
    <SidebarTemplate pageTitle={accountTitleSubId ? '補助科目編集' : '補助科目登録'}>
      <AccountTitleSubMSTForm />
    </SidebarTemplate>
  );
};


export default AccountTitleSubMSTAddPage;
