/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBox from 'components/atoms/FlexBox';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import productColor, {
  grayScale, textFontSize,
} from 'components/styles';
import DataTable from 'components/organismos/DataTable/DataTable';
import { css, jsx } from '@emotion/core';
import TransferHolidayStatusDomain from 'domain/master/holidayManagement/transferHolidayStatus';
import { Column } from 'react-table';
import DatePicker from 'components/molecules/DatePicker';
import Modal from 'components/molecules/Modal';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { useHistory } from 'react-router-dom';
import { useHolidayManagementRestForm, useDownloadHolidayManagementRest } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';


export interface DataDetail {
  staffCode: string,
  staffName: string,
  transferDayNumb: string
}

// create style form
const styles = {
  form: css({
    paddingTop: '15px',
  }),
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  input: css({
    flex: 1,
    width: '100px',
    padding: '7px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: 'none',
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px 0 0 4px',
  }),
  addon: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100px',
    padding: '7px',
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
  }),
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  selectDay: css({
    minWidth: '100px',
    width: '150px',
  }),
  detail: css({
    backgroundColor: productColor.primary,
    borderRadius: '5px',
    textAlign: 'center',
    marginLeft: '5px',
  }),
  detailButton: css({
    padding: '4px',
    color: 'white',
  }),
  textMessage: css({
    color: 'black',
  }),
};

const HolidayManagementRestForm: React.FC = () => {
  const {
    transferHolidayStatusList,
    callGetTransferHolidayStatusDetail,
    orgCode,
    setOrgCode,
    searchDate,
    setSearchDate,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    dateYearAgo,
    setDateYearAgo,
  } = useHolidayManagementRestForm();

  const {
    downloadHolidayManagementRest,
  } = useDownloadHolidayManagementRest(
    orgCode,
    searchDate,
  );

  //
  const [dataDetail, setDataDetail] = useState<DataDetail>({
    staffCode: '',
    staffName: '',
    transferDayNumb: '',
  });

  // handlePaidHolidayStatusDetail
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTransferHolidayStatusDetail = async (data: any) => {
    setDataDetail(data);
    callGetTransferHolidayStatusDetail(data.staffCode);
    setModalOpen(true);
  };

  const history = useHistory();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  const columns: Array<Column<TransferHolidayStatusDomain>> = React.useMemo(() => [
    {
      Header: () => 'スタッフコード',
      id: 'staffCode',
      accessor: 'staffCode',
      sortType: 'basic',
      Cell: (cell: { row :{ isExpanded: boolean, original: TransferHolidayStatusDomain },
        data: Array<TransferHolidayStatusDomain>
      }) => (
        <FlexBox>
          {cell.row.original.staffCode}
          <div css={css(styles.detail)}>
            <ClickableIconButton
              onClick={() => handleTransferHolidayStatusDetail(cell.row.original)}
              additionalCss={styles.detailButton}
            >
              詳細
            </ClickableIconButton>
          </div>
        </FlexBox>
      ),
    },
    {
      Header: 'スタッフ名',
      accessor: 'staffName',
    },
    {
      Header: '振替日数',
      accessor: 'transferDayNumbStr',
      Cell: (cell: { row :{ isExpanded: boolean, original: any }}) => (
        <div style={{ textAlign: 'center' }}>
          {cell.row.original.transferDayNumbStr}
        </div>
      ),
    },
  ], [handleTransferHolidayStatusDetail]);
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const accessLevel = sessionStorage.getItem('loginUser.accessLevel');
  const managementOrg = 8;
  return (
    <div>

      <div css={css(styles.form)}>
        <div css={styles.container}>
          <FlexBox>
            <div css={styles.label}>
              <FormLabel
                label="対象期間"
              />
            </div>
            <div css={styles.selectDay}>
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                date={searchDate}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setSearchDate(date);
                    const minusYear = new Date(date);
                    minusYear.setDate(minusYear.getDate() + 1);
                    minusYear.setFullYear(minusYear.getFullYear() - 1);
                    setDateYearAgo(new Date(minusYear));
                  }
                  sessionStorage.setItem('restHoliday.searchDate', String(date));
                }}
                isFullWidth={true}
              />
            </div>
            <div style={{ paddingLeft: '5px' }}>
              から過去1年間
            </div>
          </FlexBox>
        </div>


        <OrganizationFilteredTree
          functionType={2}
          staffCode={staffCode}
          orgLabel="組織名"
          initOrgValue={String(orgCode)}
          orgCallback={(args: string | Array<string>) => {
            setOrgCode(String(args));
          }}
          targetdayForm={dateYearAgo}
          targetdayTo={searchDate}
          addAllItem={false}
          styles={{
            label: {
              toggleSwitch: css({ marginLeft: '0px' }),
            },
            toggle: {
              width: '13rem',
            },
          }}
          orgFilter={managementOrg}
        />
        <div
          css={css`
              table th:nth-child(1) {
                width: 200px;
              }
              table th:nth-child(2) {
                width: 200px;
              }
              table th:nth-child(3) {
                width: 100px;
              }
            `}
        >

          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <DataTable
              columns={columns}
              data={transferHolidayStatusList}
              isGlobalFilter={true}
              sortBy={[
                { id: 'staffCode', desc: false }
              ]}
            />
          </div>
        </div>

        <FormSubmitArea>
          <div style={{ marginRight: '12px' }}>
            {checkUserRole && checkUserRole.downloadFlag === 1 && (
            <Button
              text="ダウンロード "
              onClick={() => downloadHolidayManagementRest()}
            />
            )}
          </div>
          {Number(accessLevel) === 1 && checkUserRole && checkUserRole.downloadFlag === 1 && (
          <Button
            text="全店舗ダウンロード "
            onClick={() => downloadHolidayManagementRest(true)}
          />
          )}

        </FormSubmitArea>

        {/* Form Detail */}
        <Modal
          open={modalOpen}
          closeHandler={closeModal}
          title="取得状況詳細"
          submitText="閉じる"
          onSubmit={closeModal}
          isShowButtonClose={false}
        >
          <div style={{ width: '500px' }}>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td className="active">スタッフコード：</td>
                  <td>
                    {dataDetail.staffCode}
                  </td>
                </tr>
                <tr>
                  <td className="active">スタッフ名：</td>
                  <td>
                    {dataDetail.staffName}
                  </td>
                </tr>
                <tr>
                  <td className="active">期間：</td>
                  <td>
                    {`${moment(detailItems.targetDateFrom).format('YYYY年MM月DD日')}～${moment(detailItems.targetDateTo).format('YYYY年MM月DD日')}`}
                  </td>
                </tr>
              </tbody>
            </table>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>振替出勤日</th>
                  <th>振替休日</th>
                </tr>
              </thead>
              <tbody>
                {detailItems.attendTransfer && detailItems.attendTransfer.map((item: any) => (
                  <tr>
                    <td>
                      {moment(item.targetDate).format('YYYY年MM月DD日')}
                    </td>
                    <td>
                      {moment(item.transferHolidayDate).format('YYYY年MM月DD日')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      </div>

    </div>
  );
};
export default HolidayManagementRestForm;
