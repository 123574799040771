/** @jsx jsx */
import React from 'react';
import moment from 'moment';
import { css, jsx } from '@emotion/core';

const styles = css({
  fontWeight: 400,
  fontSize: 14,
});

const PaidHolidayStatus: React.FC<{
  getPaidHolidayStatusList: any
}> = ({
  getPaidHolidayStatusList,
}) => (
  <div className="table-responsive" style={{ display: 'block', overflowX: 'auto', whiteSpace: 'nowrap' }}>
    <table id="paidHolidayGrantList" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }}>
      <thead>
        <tr role="row">
          <th style={{ width: '7%' }}>スタッフコード</th>
          <th style={{ width: '14%' }}>スタッフ名</th>
          <th style={{ width: '10%' }}>前年繰越数</th>
          <th style={{ width: '10%' }}>当年付与数</th>
          <th style={{ width: '10%' }}>消化数</th>
          <th style={{ width: '10%' }}>残有給数</th>
          <th style={{ width: '16%' }}>次回付与日</th>
          <th style={{ width: '10%' }}>次回付与予定日数</th>
          <th style={{ width: '10%' }}>次回繰越日数</th>
          <th style={{ width: '10%' }}>次回消滅日数</th>
        </tr>
      </thead>
      <tbody css={styles}>
        {
        getPaidHolidayStatusList != null && getPaidHolidayStatusList.map((paidHolidayGrantData: any, index: number) => (
          <tr role="row">
            <td>{paidHolidayGrantData.staffCode}</td>
            <td>{paidHolidayGrantData.staffName}</td>
            <td>
              {paidHolidayGrantData.lastGrantDaysNumSum.toFixed(1)}
              日
              {paidHolidayGrantData.lastGrantTimesNumSum}
              時間
            </td>
            <td>
              {paidHolidayGrantData.grantDaysNumSum.toFixed(1)}
              日
              {paidHolidayGrantData.grantTimesNumSum}
              時間
            </td>
            <td>
              {paidHolidayGrantData.digestedDaysNum.toFixed(1)}
              日
              {paidHolidayGrantData.digestedTimesNum}
              時間
            </td>
            <td>
              {paidHolidayGrantData.remainDaysNum.toFixed(1)}
              日
              {paidHolidayGrantData.remainTimesNum}
              時間
            </td>
            <td>{paidHolidayGrantData.nextGrantDate == null ? '' : paidHolidayGrantData.nextGrantDate}</td>
            <td>
              {paidHolidayGrantData.nextGrantDate == null ? '' : paidHolidayGrantData.nextGrantDays}
              日
            </td>
            <td>
              {paidHolidayGrantData.nextGrantDate == null ? '' : paidHolidayGrantData.nextCarryOverDaysNum.toFixed(1)}
              日
              {paidHolidayGrantData.nextCarryOverTimesNum}
              時間
            </td>
            <td>
              {paidHolidayGrantData.nextGrantDate == null ? '' : paidHolidayGrantData.nextExtinctionDaysNum.toFixed(1)}
              日
              {paidHolidayGrantData.nextExtinctionTimesNum}
              時間
            </td>
          </tr>
        ))
      }
      </tbody>
    </table>
  </div>
);
export default PaidHolidayStatus;
