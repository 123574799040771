/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { deleteAdver, downloadCSV } from 'api/advertising';
import PrimaryButton from 'components/atoms/Button';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Icon from 'components/atoms/Icon';
import IconLabelButton from 'components/molecules/IconLabelButton';
import ConfirmModal from 'components/organismos/ConfirmModal';
import DataTable from 'components/organismos/DataTable/DataTable';
import ToastModal from 'components/organismos/ToastModal';
import BlockUI from 'components/molecules/BlockUi';
import { iconColor } from 'components/styles';
import AdvertisingDomain from 'domain/advertising';
import useToastNotification from 'hooks/useToastNotification';
import React, {
  useCallback, useMemo, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import useAdvertisingPlanPage from './hook';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const AdvertisingPlanPage: React.FC = () => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalResendOpen, setConfirmModalResendOpen] = useState(false);
  const [confirmModalContent] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [toastMessage] = useState('');
  const { mediaMasterList, setMediaMasterList, isLoading } = useAdvertisingPlanPage();
  const [selectedObj, setSelectedObj] = useState(AdvertisingDomain.generateInitial());
  const { successNotification, errorNotification } = useToastNotification();

  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmResendModal = useCallback(() => {
    setConfirmModalResendOpen(false);
  }, []);

  const openConfirmModal = useCallback((val : AdvertisingDomain) => {
    setConfirmModalOpen(true);
    setSelectedObj(val);
  }, []);

  const deleteAdvertising = useCallback(() => {
    const submitObj = {
      advertisingPlanMstCode: selectedObj.advertisingPlanMstCode,
    };
    deleteAdver(confirmModalResendOpen ? '1' : submitObj.advertisingPlanMstCode).then((Response: any) => {
      setMediaMasterList(mediaMasterList.filter(
        (mediaMaster) => mediaMaster.advertisingPlanMstCode !== selectedObj.advertisingPlanMstCode,
      ));
      setConfirmModalOpen(false);
      successNotification('削除しました。');
    }).catch((error) => {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      }
      else if (error.response && error.response.data && error.response.data.defaultMessage) {
        const stringErr = error.response.data.defaultMessage;
        errorNotification(stringErr);
      }
      else if ((error.response && error.response.data && error.response.data.message)) {
        const stringErr = error.response.data.message;
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    });
  }, [selectedObj.advertisingPlanMstCode,
    confirmModalResendOpen,
    setMediaMasterList,
    mediaMasterList,
    successNotification,
    errorNotification]);


  const downloadFileCSV = useCallback(() => {
    downloadCSV('広告プラン');
  }, []);

  const columns: Array<Column<AdvertisingDomain>> = useMemo(() => [
    {
      Header: 'コード',
      accessor: 'advertisingPlanMstCode',
      sortType: 'basic',
    },
    {
      Header: '名称',
      accessor: 'advertisingPlanMstName',
    },
    {
      Header: '並び順',
      accessor: 'dispOrder',
    },
    {
      Header: () => null,
      id: 'actionButton',
      accessor: 'advertisingPlanMstCode',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: AdvertisingDomain } }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/salesAdvertisingPlan/advertisingMaster/edit/${cell.row.original.advertisingPlanMstCode}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>

            {roleScreen && roleScreen.editable === 1 && (
            <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
              <span
                style={{ marginLeft: '20px', marginRight: '10px' }}
                onMouseEnter={() => setIsHoverDelete(true)}
                onMouseLeave={() => setIsHoverDelete(false)}
              >
                <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            )}
          </div>
        );
      },
    },
  ], [openConfirmModal, roleScreen]);
  return (
    <BlockUI blocking={isLoading}>
      <div style={{ marginTop: '12px' }}>
        <FlexBox>
          <FlexBoxItem>
            {roleScreen && roleScreen.downloadFlag === 1 && (
            <IconLabelButton
              onClick={downloadFileCSV}
              iconType="download"
              text="CSVダウンロード"
          />
            )}
          </FlexBoxItem>
          <FlexBoxItem grow={1}>
            <div style={{ textAlign: 'right' }}>
              {roleScreen && roleScreen.importFlag === 1 && (
              <Link to="/salesAdvertisingPlan/advertisingMaster/import" style={{ marginRight: 10 }}>
                <PrimaryButton
                ghost={false}
                text="インポート"
              />
              </Link>
              )}
              {roleScreen && roleScreen.editable === 1 && (
              <Link to="/salesAdvertisingPlan/advertisingMaster/add">
                <PrimaryButton
                ghost={false}
                text="新規広告プラン作成"
              />
              </Link>
              )}
            </div>
          </FlexBoxItem>
        </FlexBox>

        <div
          css={css`
            table th:nth-child(1) {
              width: 150px;
            }
            table th:nth-child(2) {
              width: 300px;
            }
            table th:nth-child(3) {
              width: 100px;
            }
            table th:nth-child(4) {
              width: 80px;
            }
          `}
      >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <DataTable
              columns={columns}
              data={mediaMasterList}
              isGlobalFilter={true}
              sortBy={[
                { id: 'advertisingPlanMstCode', desc: false },
              ]}
          />
          </div>
        </div>

        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={deleteAdvertising}
          title="確認メッセージ"
          content="削除します。よろしいですか？"
          submitText="削除"
          items={[]}
      />
        <ConfirmModal
          open={confirmModalResendOpen}
          closeHandler={closeConfirmResendModal}
          onSubmit={deleteAdvertising}
          title="確認"
          content={confirmModalContent}
          items={[]}
      />
        <ToastModal
          open={toastModalOpen}
          closeHandler={() => setToastModalOpen(false)}
          title={toastMessage}
      />
      </div>
    </BlockUI>
  );
};


export default AdvertisingPlanPage;
