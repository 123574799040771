/** @jsx jsx */
import React from 'react';
import Table from 'components/molecules/Table';
import DatePickerForm from 'components/molecules/DatePickerForm';
import Checkbox from 'components/molecules/Checkbox';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import TextInput from 'components/atoms/Form/TextInput';
import moment from 'moment';
import { css, jsx } from '@emotion/core';
import { OptionType } from 'components/atoms/Select';
import { TCompanyBank } from '../../../type/regist';
import styles from './styles';
import { IOtherBankOrgTree } from '../../../context/BankRegistDetails';

const OtherBankLayout: React.FC<{
  renderList: TCompanyBank[],
  orgTreeBank: OptionType[],
  otherBankOrgTree: IOtherBankOrgTree,
  handleChangeTrap: {
    set(obj: TCompanyBank, prop: string, value: any): boolean;
  };
  allDateChangeStatus: boolean;
  handleChangeAllDateStatus: any
  currentBank: string;
}> = ({
  renderList,
  orgTreeBank,
  otherBankOrgTree,
  handleChangeTrap,
  allDateChangeStatus,
  handleChangeAllDateStatus,
  currentBank,
}) => {
    const formatter = new Intl.NumberFormat('ja-JP', {
      minimumFractionDigits: 0, maximumFractionDigits: 2,
    });
    const filterInt = (value: string, preValue: number): number => {
      if (/^[-+]?(\d+|Infinity)$/.test(value)) return Number(value);
      if (value.trim() === '') return 0;
      return preValue;
    };
    return (
      <Table customStyle={styles.table}>
        <thead>
          <tr>
            <Table.HeaderCell customStyle={styles.recordNo}>ﾚｺｰﾄﾞNo</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.delete}>削除</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.store}>店舗</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.cardNo}>登録番号</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.bankName}>銀行名</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.nonInterlocking}>非連動</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.depositAmount}>入金額</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.withdrawalAmount}>出金額</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.businessDayStart}>営業日（開始）</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.businessDayEnd}>営業日（終了）</Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.dateChangeStatus}>
              <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
              }}
              >
                <div>一括</div>
                <div style={{ height: 19 }}>
                  <Checkbox
                    id={'allDate'.concat(String(currentBank || ''))}
                    name="allDateChangeStatus"
                    value={String(allDateChangeStatus)}
                    checked={allDateChangeStatus}
                    onChange={(e) => {
                      handleChangeAllDateStatus(e, currentBank);
                    }}
                  />
                </div>
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell customStyle={styles.remarks}>備考</Table.HeaderCell>
          </tr>
        </thead>
        <tbody>
          {
            renderList && renderList.map((obj: TCompanyBank, index: number) => {
              const item = new Proxy(obj, handleChangeTrap);
              return (
                <tr
                  key={item.recordNo}
                  css={css({
                    '& :is(td:nth-of-type(1), td:nth-of-type(2), td:nth-of-type(3), td:nth-of-type(4))': {
                      zIndex: renderList.length - index + 1, overflow: 'unset',
                    },
                    '& :is(td:nth-of-type(5))': {
                      zIndex: renderList.length - index, overflow: 'unset',
                    },
                  })}
                >
                  <Table.Cell customStyle={styles.recordNoStickyBody}>{item.recordNo}</Table.Cell>
                  <Table.Cell customStyle={styles.deleteStickyBody}>
                    <Checkbox
                      id={'deleteFlg'.concat(String(item.recordNo))}
                      name="deleteFlg"
                      value=""
                      checked={item.deleteFlg}
                      onChange={(e) => { item.deleteFlg = e.target.checked; }}
                    />
                  </Table.Cell>
                  <Table.Cell customStyle={styles.storeStickyBody}>
                    <VerticalLabelSelectForm
                      name="store"
                      value={item.orgCode}
                      // setValue={(val: string) => { formik.setFieldValue('roleCode', val); }}
                      setValue={(val) => { item.orgCode = val; }}
                      // options={optionType2}
                      options={orgTreeBank}
                      customizeSelectPropStyle={
                        {
                          menuPortal: (oldStyles: any) => ({ ...oldStyles, zIndex: 9999 }),
                        }
                      }
                      createMenuPortal={true}
                      removePaddingContainer={true}
                    />

                  </Table.Cell>
                  <Table.Cell customStyle={styles.cardNoStickyBody}>{item.registrationNo}</Table.Cell>
                  <Table.Cell>
                    <VerticalLabelSelectForm
                      name="bankName"
                      value={item.organizationBankId}
                      // setValue={(val: string) => { formik.setFieldValue('roleCode', val); }}
                      setValue={(val) => { item.organizationBankId = val; }}
                      // options={optionType2}
                      options={otherBankOrgTree[`${item.recordNo}`]}
                      customizeSelectPropStyle={
                        {
                          menuPortal: (oldStyles: any) => ({ ...oldStyles, zIndex: 9999 }),
                        }
                      }
                      createMenuPortal={true}
                      removePaddingContainer={true}
                    />
                  </Table.Cell>
                  <Table.Cell customStyle={styles.accountingNonLinkFlgBody}>
                    <Checkbox
                      id={'accountingNonLinkFlg'.concat(String(item.recordNo))}
                      name="accountingNonLinkFlg"
                      value=""
                      checked={item.accountingNonLinkFlg}
                      onChange={(e) => { item.accountingNonLinkFlg = e.target.checked; }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <TextInput
                      customStyle={css({ textAlign: 'right' })}
                      type="text"
                      name="depositAmount"
                      value={formatter.format(item.depositAmount)}
                      onChange={(e) => { item.depositAmount = filterInt(e.target.value.replace(/,/g, ''), item.depositAmount); }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <TextInput
                      customStyle={css({ textAlign: 'right' })}
                      type="text"
                      name="withdrawalAmount"
                      value={formatter.format(item.withdrawalAmount)}
                      onChange={(e) => { item.withdrawalAmount = filterInt(e.target.value.replace(/,/g, ''), item.withdrawalAmount); }}
                    />
                  </Table.Cell>
                  <Table.Cell customStyle={css({ '&>div>div': { marginTop: '0px' } })}>
                    <DatePickerForm
                      label=""
                      date={moment(item.businessStartDate).toDate()}
                      dateFormat="yyyy年MM月dd日"
                      changeDate={(date: Date) => {
                        if (date !== null) {
                          item.businessStartDate = moment(date).format('YYYY/MM/DD');
                        }
                      }}
                      isFullWidth={true}
                    />
                  </Table.Cell>
                  <Table.Cell customStyle={css({ '&>div>div': { marginTop: '0px' } })}>
                    <DatePickerForm
                      label=""
                      date={moment(item.businessEndDate).toDate()}
                      dateFormat="yyyy年MM月dd日"
                      changeDate={(date: Date) => {
                        if (date !== null) {
                          item.businessEndDate = moment(date).format('YYYY/MM/DD');
                        }
                      }}
                      isFullWidth={true}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <div style={{
                      height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 7,
                    }}
                    >
                      <Checkbox
                        id={'dateChangeStatus-id'.concat(String(index))}
                        name="dateChangeStatus"
                        value={String(item.dateChangeStatus)}
                        checked={item.dateChangeStatus || false}
                        onChange={(e) => {
                          item.dateChangeStatus = e.target.checked;
                        }}
                      />
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <TextInput
                      name="remark"
                      value={item.remark}
                      onChange={(e) => { item.remark = e.target.value; }}
                    />
                  </Table.Cell>
                </tr>
              );
            })
          }
        </tbody>
      </Table>
    );
  };
export default OtherBankLayout;
