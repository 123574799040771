/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';

import Select from 'components/atoms/Select';
import BodyText from 'components/atoms/BodyText';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import DatePicker from 'components/molecules/DatePicker';
import { ScheduleStatusColor, ScheduleStatus } from 'api/schedule';

const ScheduleCalendarToolbar: React.FC<{
  date: Date;
  setDate: (arg: Date) => void;
  setDatePickerValue: (arg: Date) => void;
  view: 'month'|'week';
  setView: (arg: 'month'|'week') => void;
}> = ({
  date, setDate, setDatePickerValue, view, setView,
}) => (
  <FlexBox>
    <FlexBoxItem marginRight="5px">
      <Select
        name="calendarViewSelect"
        value={view}
        setValue={setView as (val: string) => void}
        options={[
          { label: '週', value: 'week' },
          { label: '月', value: 'month' },
        ]}
      />
    </FlexBoxItem>

    <FlexBoxItem>
      <DatePicker
        date={date}
        dateFormat={view === 'month' ? 'yyyy/MM' : 'yyyy/MM/dd'}
        changeDate={(argDate) => {
          if (argDate !== null) {
            setDate(argDate);
            setDatePickerValue(argDate);
          }
        }}
        showMonthYearPicker={view === 'month'}
      />
    </FlexBoxItem>
    <FlexBoxItem>
      <FlexBox>
        <div style={{
          width: '24px', height: '24px', backgroundColor: ScheduleStatusColor[ScheduleStatus.ACTUAL], marginLeft: '10px', marginRight: '5px',
        }}
        />
        <BodyText>実績</BodyText>
        <div style={{
          width: '24px', height: '24px', backgroundColor: ScheduleStatusColor[ScheduleStatus.APPROVED], marginLeft: '10px', marginRight: '5px',
        }}
        />
        <BodyText>確定</BodyText>
        <div style={{
          width: '24px', height: '24px', backgroundColor: ScheduleStatusColor[ScheduleStatus.NOT_APPROVED], marginLeft: '10px', marginRight: '5px',
        }}
        />
        <BodyText>未確定</BodyText>
        <div style={{
          width: '24px', height: '24px', backgroundColor: ScheduleStatusColor[ScheduleStatus.DAY_OFF], marginLeft: '10px', marginRight: '5px',
        }}
        />
        <BodyText>休暇</BodyText>
        <div style={{
          width: '23px', height: '23px', backgroundColor: ScheduleStatusColor[ScheduleStatus.UNSUBMITED], marginLeft: '10px', marginRight: '5px', border: '1px solid', borderColor: '#0D4796',
        }}
        />
        <BodyText>未提出</BodyText>
      </FlexBox>

    </FlexBoxItem>

  </FlexBox>
);

export default ScheduleCalendarToolbar;
