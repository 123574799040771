import { useState, useEffect } from 'react';
import { getDisCountTypeList } from 'api/disCountType';
import DiscountTypeDomain from 'domain/master/sales/discoutType';

export const useDisCountTypeList = () => {
  const [discountTypeList, setDiscountTypeList] = useState<Array<DiscountTypeDomain>>([]);
  useEffect(() => {
    getDisCountTypeList().then((response: Array<DiscountTypeDomain>) => {
      setDiscountTypeList(
        response.map((result) => new DiscountTypeDomain(result)),
      );
    });
  }, []);
  return {
    discountTypeList, setDiscountTypeList,
  };
};

export default useDisCountTypeList;
