import { useState, useEffect } from 'react';
import { getTreesByCategory, getPriceWidthdrawalList } from 'api/priceWidthdrawal';
import PriceWithrowalDomain from 'domain/master/sales/priceWithrowal';
import { OptionType } from 'components/atoms/Select';

export const usePriceWidthdrawalList = (orgCode: string) => {
  // eslint-disable-next-line max-len
  const [priceWidthdrawalItemList, setpriceWidthdrawalItemList] = useState<Array<PriceWithrowalDomain>>([]);
  useEffect(() => {
    getPriceWidthdrawalList(orgCode).then((response: Array<any>) => {
      setpriceWidthdrawalItemList(
        response.map((result) => new PriceWithrowalDomain(result)),
      );
    });
  }, [orgCode]);
  return { priceWidthdrawalItemList, setpriceWidthdrawalItemList };
};


export const useOrgTreesByCategoryOptions = ():any => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  const [orgCode, setorgCode] = useState(sessionStorage.getItem('selectedOrgCode') ? sessionStorage.getItem('selectedOrgCode') : sessionStorage.getItem('selectedOrgCodeFirst'));
  useEffect(() => {
    const category: any[] = [];
    const functionType: number = 1;
    getTreesByCategory(functionType,category).then((trees) => {
      const OptionTree = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      }));
      setOrgTreesOptionsList(OptionTree);
    });
  }, []);
  return {
    orgCode,
    setorgCode,
    orgTreesOptions,
  };
};

export default usePriceWidthdrawalList;
