import { ErrorMessage } from "./messages";

export const getErrorMessageForResponse = (error: any) => {
  let errorMsg = "";
  if (error) {
    if(error.response && error.response.data && error.response.data.errors) {
      error.response.data.errors.map((item: { defaultMessage: string }) => {
        errorMsg += `${item.defaultMessage} <br />`;
      });
    } else if(error.response && typeof(error.response.data) === "string"){
      errorMsg += `${error.response.data}`;
    } else if(error.response && error.response.data && error.response.data.defaultMessage) {
      errorMsg = error.response.data.defaultMessage;
    }
  }
  return errorMsg || ErrorMessage.Common.INTERNAL_SERVER_ERROR;
}
