/** @jsx jsx */
import React, { useState } from 'react';
import { Column } from 'react-table';
import { css, jsx } from '@emotion/core';
import { grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';
import DataTable from 'components/organismos/DataTable/DataTable';
import TextTooltip from 'components/atoms/TextTooltip';
import StaffRegistrationStatusDomain from 'domain/master/general/staffRegistrationStatus';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import ButtonNavigation from './ButtonNavigation';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import { OrgStatusType, UserRegistrationStatusType, SessionStorageKey, Values } from 'utility/constants';
import PhotoFrame from 'components/molecules/PhotoFrame';
import Modal from 'components/molecules/Modal';
import FlexBox from 'components/atoms/FlexBox';
import UserRegistrationStatusRadio from './UserRegistrationStatusRadio';
import FaceRegistrationStatusRadio from './FaceRegistrationStatusRadio';
import EmploymentRegistrationStatusRadio from './EmploymentRegistrationStatusRadio';
import { photoPathByStaffCode } from 'hooks/usePhotoPath';
import SecondaryButton from 'components/atoms/Button/SecondaryButton';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { InfoMessage } from 'utility/messages';

const StaffRegistrationListTable:React.FC<{
    staffRegisterList: Array<StaffRegistrationStatusDomain>;
    orgCode: string;
    setOrgCode : (arg: string) => void;
    userRegistrationStatus: UserRegistrationStatusType;
    setUserRegistrationStatus : (arg: UserRegistrationStatusType) => void;
    faceRegistrationStatus: string;
    setFaceRegistrationStatus : (arg: string) => void;
    employmentRegistrationStatus: string;
    setEmploymentRegistrationStatus : (arg: string) => void;
    categoryArr: Array<string>;
    setCategoryAr: (arg: Array<string>) => void;
    orgStatus: OrgStatusType;
    setOrgStatus : (arg: OrgStatusType) => void;
    downloadCsv: () => void;
    deletePhoto: () => void;
    selectedDeleteRow: StaffRegistrationStatusDomain | null;
    setSelectedDeleteRow : (arg: StaffRegistrationStatusDomain | null) => void;
    openConfirmModal: boolean;
    setOpenConfirmModal : (arg: boolean) => void;

}> = ({
  staffRegisterList,
  orgCode, setOrgCode,
  categoryArr, setCategoryAr,
  orgStatus, setOrgStatus,
  userRegistrationStatus, setUserRegistrationStatus,
  faceRegistrationStatus, setFaceRegistrationStatus,
  employmentRegistrationStatus, setEmploymentRegistrationStatus,
  downloadCsv, deletePhoto, selectedDeleteRow, setSelectedDeleteRow,
  openConfirmModal, setOpenConfirmModal
}) => {
  //
  const functionType = Values.FunctionType.Master.value;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const styles = {
    image: css({
      margin: 'auto'
    }),
  };

  const [openModal, setOpenModal] = useState(false);
  const [faceImage , setFaceImage] = useState('');
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    setSelectedDeleteRow(null);
  };

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  const sortBy = React.useMemo(
    () => [
      {
        id: 'orgCode',
        desc: false,
      },
    ],
    [],
  );

  const columns: Array<Column<StaffRegistrationStatusDomain>> = React.useMemo(() => [
    {
      Header: '組織コード',
      accessor: 'orgCode',
    },
    {
      Header: '組織名',
      accessor: 'orgName',
      sortType: 'basic',
      Cell: (cell: { row :{ original: StaffRegistrationStatusDomain }}) => {
        return (
          <TextTooltip id={`orgName_${cell.row.original.orgName}`} text={cell.row.original.orgName ?? ''} />
        )
      },
    },
    {
      Header: 'スタッフコード',
      accessor: 'staffCode',
    },
    {
      Header: 'スタッフ名',
      accessor: 'staffName',
      sortType: 'basic',
      Cell: (cell: { row :{ original: StaffRegistrationStatusDomain }}) => {
        return (
          <TextTooltip id={`staffName_${cell.row.original.staffCode}`} text={cell.row.original.staffName} />
        )
      },
    },
    {
      Header: '役職',
      accessor: 'executiveCode',
      Cell: (cell: { row :{ original: StaffRegistrationStatusDomain }}) => {
        return (
          <TextTooltip id={`executiveCode_${cell.row.original.staffCode}`} text={cell.row.original.executiveName ?? ''} />
        )
      },
    },
    {
      Header: 'メールアドレス',
      accessor: 'mail',
      Cell: (cell: { row :{ original: StaffRegistrationStatusDomain}}) => {
        return (
          <TextTooltip id={`mail_${cell.row.original.staffCode}`} text={cell.row.original.mail ?? ''} />
        )
      },
    },
    {
      Header: 'ユーザー登録',
      accessor: 'userRegistrationStatus',
      Cell: (cell: { row :{ original: StaffRegistrationStatusDomain}}) => {
        return (
          <TextTooltip id={`userRegistrationStatus_${cell.row.original.staffCode}`} text={cell.row.original.userRegistrationStatusName} />
        )
      },
    },
    {
      Header: '顔写真',
      accessor: 'nameplate',
      Cell: (cell: { row :{ original: StaffRegistrationStatusDomain }}) => {
        const photoPath = cell.row.original.nameplate ? photoPathByStaffCode(cell.row.original.staffCode, cell.row.original.nameplate) : "";
        return (
          photoPath
            ? (
              <div onClick={(e) => {
                setOpenModal(true)
                setFaceImage(photoPath)
              }}>
                <PhotoFrame src={photoPath} size="50px" />
              </div>
            ) : (
              <div>
                <Icon type="avatar" size="50px" color={grayScale.gray50} disabled={true} />
              </div>
            )
        )
      },
    },
    {
      Header: '',
      id: 'deletePhoto',
      disableSortBy: true,
      Cell: (cell: { row: { original: StaffRegistrationStatusDomain } }) => (
        <div style={{ textAlign: 'left' }}>
          {roleScreen && roleScreen.editable === 1 && (
            <SecondaryButton
              disabled={!cell.row.original.nameplate}
              ghost={false}
              text="顔写真削除"
              onClick={() => {
                setOpenConfirmModal(true);
                setSelectedDeleteRow(cell.row.original);
              }}
            />
          )}
        </div>
      ),
    },


  ], [roleScreen, setOpenModal, setFaceImage]);

  return (
    <React.Fragment>
      <div style={{ marginLeft: '8px' }}>
        <OrganizationFilteredTree
          functionType={functionType}
          orgCallback={(args: string | Array<string>) => {
            setOrgCode(String(args));
            sessionStorage.setItem('selectedOrgCodeStaffMaster', String(args));
          }}
          orgCategoryCallback={
              (args: Array<string>) => {
                setCategoryAr(args);
              }
            }
          initOrgValue={String(orgCode)}
          staffCode={loginStaffCode}
          orgLabel="組織名"
          addAllItem={true}
          orgFilter={parseInt(orgStatus)}
          showSelectOrgStatus={true}
          orgStatus={orgStatus}
          orgStatusCallback={(args: OrgStatusType) => {
            setOrgStatus(args);
            setOrgCode('all');
            // NOTE: 組織カテゴリでの絞り込みを行うために選択中の組織カテゴリを再設定 ※ 再設定を行わないとカテゴリでの絞り込みが行われない
            setCategoryAr(JSON.parse(String(sessionStorage.getItem(SessionStorageKey.Common.ORG_FILTER_TREE_TOGGLE(window.location.pathname, parseInt(args))))));
          }}
          styles={{
            toggle: {
              width: '8rem'
            },
            selectOrgStatus: {
              width: '13rem'
            }
          }}
          isShowSituation={true}
        />
      </div>

      <UserRegistrationStatusRadio
        userRegistrationStatus={userRegistrationStatus}
        setUserRegistrationStatus={(arg : UserRegistrationStatusType) => {
          sessionStorage.setItem('selectedRegistaionStatusSlect', arg.toString());
          setUserRegistrationStatus(arg );
        }}
      />

      <FaceRegistrationStatusRadio
        faceRegistrationStatus={faceRegistrationStatus}
        setFaceRegistrationStatus={(arg : string) => {
          sessionStorage.setItem('selectedFacePhotoStatus', arg.toString());
          setFaceRegistrationStatus(arg);
        }}
      />

      <EmploymentRegistrationStatusRadio
        employmentRegistrationStatus={employmentRegistrationStatus}
        setEmploymentRegistrationStatus={(arg : string) => {
          sessionStorage.setItem('selectedFacePhotoStatus', arg.toString());
          setEmploymentRegistrationStatus(arg);
        }}
      />

      <ButtonNavigation
        downloadCsv={downloadCsv}
        roleScreen={roleScreen}
      />

      <div css={css`
        table th:nth-child(1) {
          width: 60px;
        }
        table th:nth-child(2) {
          width: 200px;
        }
        table th:nth-child(3) {
          width: 80px;
        }
        table th:nth-child(4) {
          width: 80px;
        }
        table th:nth-child(5) {
          width: 80px;
        }
        table th:nth-child(6) {
          width: 200px;
        }
        table th:nth-child(7) {
          width: 100px;
        }
        table th:nth-child(8) {
          width: 60px;
        }
        table th:nth-child(9) {
          width: 200px;
        }
      `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
          <DataTable columns={columns} data={staffRegisterList} isGlobalFilter={true} minWidth="1202px" sortBy={sortBy} useSession={true}/>
        </div>
      </div>
      <Modal
        open={openModal}
        closeHandler={handleCloseModal}
        title=""
        submitText=""
        onSubmit={handleCloseModal}
        isShowButtonClose={false}
      >
        <FlexBox alignItems='center'>
          <div css={css(styles.image)}>
            <PhotoFrame src={faceImage} size="500px" />
          </div>
        </FlexBox>
      </Modal>

      {/* 顔写真削除モーダル */}
      <ConfirmActionModal
        open={openConfirmModal}
        closeHandler={handleCloseConfirmModal}
        onSubmit={deletePhoto}
        actionType={ActionType.DELETE}
        message={InfoMessage.StaffRegistrationStatus.CONFIRM_MESSAGE_DELETE_PHOTO(selectedDeleteRow?.staffName || '')}
      />
    </React.Fragment>
  );
};

export default StaffRegistrationListTable;
