import moment from 'moment';
/**
 * API 勤怠再計算
 */

const arrStateFlg = ['------', '計算準備中', '計算中', '完了'];

export interface TimeRecalculation {
    recalculationId: string;
    targetEndDate: Date;
    orgCode: string;
    orgName: string;
    employmentName: string;
    staffCode: string,
    staffName: string,
    targetShiftCount: number,
    targetAchievementCount: number,
    createUser: string;
    updateUser: string;
    stateFlg: number;

}


export default class TimeRecalculationDomain {
  constructor(private rawData: TimeRecalculation) {
    // do nothing
  }

  static generateInitial(): TimeRecalculationDomain {
    return new TimeRecalculationDomain({
      recalculationId: '',
      targetEndDate: new Date(),
      orgCode: '',
      orgName: '',
      employmentName: '',
      staffCode: '',
      staffName: '',
      targetShiftCount: 0,
      targetAchievementCount: 0,
      createUser: '',
      updateUser: '',
      stateFlg: 0,

    });
  }

  getRawData(): TimeRecalculation {
    return this.rawData;
  }


  get recalculationId(): string {
    return this.rawData.recalculationId;
  }

  set recalculationId(recalculationId: string) {
    this.rawData.recalculationId = recalculationId;
  }

  get targetEndDate(): Date {
    return this.rawData.targetEndDate;
  }

  get targetEndDateStr(): string {
    return moment(moment(this.rawData.targetEndDate).toDate()).format('YYYY年MM月');
  }

  set targetEndDate(date: Date) {
    this.rawData.targetEndDate = date;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode:string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get employmentName():string {
    return this.rawData.employmentName;
  }

  set employmentName(employmentName:string) {
    this.rawData.employmentName = employmentName;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }


  get targetShiftCount():number {
    return this.rawData.targetShiftCount;
  }

  get targetShiftCountStr():string {
    if (this.rawData.targetShiftCount === -1) {
      return '-';
    }
    return String(this.rawData.targetShiftCount);
  }

  set targetShiftCount(targetShiftCount:number) {
    this.rawData.targetShiftCount = targetShiftCount;
  }

  get targetAchievementCount():number {
    return this.rawData.targetAchievementCount;
  }

  set targetAchievementCount(targetAchievementCount:number) {
    this.rawData.targetAchievementCount = targetAchievementCount;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  get stateFlgString(): string {
    const keyFlg = Number(this.rawData.stateFlg);
    if (!arrStateFlg[keyFlg]) {
      return arrStateFlg[0];
    }
    return arrStateFlg[keyFlg];
  }
}
