/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  eqeqeq */
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormPadding from 'components/atoms/Form/FormPadding';
import { Row, Col } from 'react-grid-system';
import BodyText from 'components/atoms/BodyText';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import PrimaryButton, { SecondaryButton } from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import moment from 'moment';
import Checkbox from 'components/molecules/Checkbox';
import { confirmMonthlyLockOutV1 } from 'api/shift';
import DatePickerForm from 'components/molecules/DatePickerForm';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import {
  fetchShopPlan, fetchMonthlyShift,
} from '../api';
import { OptionType } from 'components/atoms/Select';
import { getList } from 'api/businessMaster';
import { AttendBusiness } from 'domain/employment';
import { useMonthlyShiftContext } from './contextHook';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

export const useBusinessMasterOptions = (): Array<OptionType> => {
  const [businessOptions, setBusinessOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getList().then((businesses) => {
      setBusinessOptions(businesses.map((business) => ({
        value: business.businessId,
        label: `${business.businessCode} ${business.businessName}`,
      })));
    });
  }, []);
  return businessOptions;
};

const Filters: React.FC <{
  dispatch?: any,
}> = ({
  dispatch,
}) => {
  const {
    isLoading,
    setLoading,
    setMonthlyTableLoading,
    setOpenConfirmModal,
    loadEmploymentDone,
    setOpenRegisterModal,
    filters: {
      orgs,
      setOrgs,
      dateFromStr,
      setDateFromStr,
      isAttendExists,
      setAttendExists,
      selectedOrg,
      setSelectedOrg,
      employmentOptions,
      setEmploymentOptions,
      viewPeriod,
      setViewPeriod,
      dateToStr,
      checkall,
      setCheckall,
    },
    monthlyCalendar: {
      pages,
      setPages,
      setMonthlyShifts,
      setStaffs,
      setShopPlan,
      setAchievementConfirm,
      shiftConfirm,
      setShiftConfirm,
      downloadPdf,
      achievementConfirm,
      downloadExcel,
      reFetchMonthlyShifts,
    },
  } = useMonthlyShiftContext();
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  // const session_selected_org = sessionStorage.getItem('sessionOrganizationFileterTree/monthlyShift.Toggle')
  const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
    undefined,
  );
  const [businessIds, setBusinessIds] = useState<any>([]);
  const businessOptions = useBusinessMasterOptions();

    useEffect(() => {
      async function fetch() {
        setLoading(true);
        try {
          // check confirmed dates
          confirmMonthlyLockOutV1(selectedOrg, dateFromStr, dateToStr).then((response) => {
            setAchievementConfirm(response.achievementConfirm);
            setShiftConfirm(response.shiftConfirm);
          });

          // fetch Shopplan
          fetchShopPlan(dateFromStr, dateToStr, selectedOrg).then((response) => {
            setShopPlan(response);
          });

          // fetch Monthly shift
          const employmentIds = employmentOptions.reduce((prev, current) => (current.checked ? `${current.id},${prev}` : prev), '1');
          const page_index = Number(sessionStorage.getItem('monthly_shift_current_page_index')) || 0;
          const page_size = Number(sessionStorage.getItem('monthly_shift_current_page_size')) || 50;
          console.log('selectedOrg', selectedOrg);

          console.log('orgCodeStepTry', selectedOrg);
          const monthlyData = await fetchMonthlyShift(dateFromStr, dateToStr, selectedOrg, isAttendExists, employmentIds, '', page_index, page_size, businessIds);
          setStaffs(monthlyData.staffInfo);
          setPages(monthlyData.page);
          setMonthlyShifts(monthlyData);
        } catch (error) {
          console.log('error');
        }
        console.log('DONE FETCHING...', selectedOrg);
        setLoading(false);
        setMonthlyTableLoading(false);
      }
      fetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateToStr, selectedOrg, isAttendExists, employmentOptions, businessIds]);


    const history = useHistory();
    useEffect(() => {
      if (employmentOptions !== null && employmentOptions !== [] && loadEmploymentDone) {
        let sessionData = '';
        const list = employmentOptions.reduce((x: any, y: any) => (y.checked === true ? [...x, y.value] : x), []);
        if (list.length === employmentOptions.length) {
          sessionData = 'all';
          setCheckall(true);
        } else {
          sessionData = list.join();
          setCheckall(false);
        }
        sessionStorage.setItem(`0/sessionData${window.location.pathname}.employmentOptions`, sessionData);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employmentOptions, loadEmploymentDone]);

    // Check prototype role staff
    const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

    const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === selectedOrg);

    const checkUserRole = staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

    useEffect(() => {
      if (checkUserRole && checkUserRole.available === false) {
        history.push('/404')
      }
    }, [checkUserRole]);
    return (
      <FormPadding isSubForm={window.screen.width < 800}>
        <Row>
          <Col md={2} lg={1}>
            <div css={css({ marginTop: '18px' })}>
              <BodyText>期間</BodyText>
            </div>
          </Col>
          <Col md={10} lg={11}>
            <FlexBox>
              <Col md={3} xl={2}>
                {dateFromStr && (
                  <DatePickerForm
                    label=""
                    date={moment(dateFromStr).toDate()}
                    dateFormat="yyyy年MM月dd日"
                    changeDate={(date: Date) => {
                      if (date !== null) {
                        const newdate = date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
                        setDateFromStr(newdate);
                        sessionStorage.setItem('attendStampList.monthlyShift.targetDateFrom', newdate);
                        dispatch({
                          type: 'TARGET_DATE',
                          payload: newdate,
                        });
                      }
                    }}
                    isFullWidth={true}
                  />
                )}
              </Col>
              <Col md={9}>
                <div css={css({
                  display: 'flex', alignItems: 'baseline', marginLeft: '20px', marginTop: '-8px',
                })}
                >
                  <RadioSelectForm
                    label=""
                    name="haveShiftRecord"
                    items={[
                      {
                        label: '1週間表示',
                        value: 'weekly',
                      },
                      {
                        label: '1ヶ月表示',
                        value: 'monthly',
                      },
                    ]}
                    value={viewPeriod}
                    setValue={(e) => {
                      setViewPeriod(e.target.value);
                      sessionStorage.setItem(`0/sessionData${window.location.pathname}.viewPeriod`, e.target.value);
                    }}
                  />
                  <Checkbox
                    id="1"
                    name="isAttendExists"
                    label="シフト・実績有る人のみ表示"
                    value="1"
                    checked={isAttendExists === '1'}
                    onChange={() => {
                      sessionStorage.setItem('attendStampList.attendFlag', isAttendExists === '1' ? '0' : '1');
                      setAttendExists(isAttendExists === '1' ? '0' : '1');
                    }}
                  />
                </div>
              </Col>
            </FlexBox>
          </Col>
        </Row>


        <Row>
          <Col lg={1} md={2}>
            <div css={css({ marginTop: '24px' })}>
              <BodyText>組織</BodyText>
            </div>
          </Col>
          <Col lg={11} md={10}>

            {/* <VerticalLabelSelectForm
                        label="出勤店舗"
                        name="orgCode"
                        value={String(formik.values.orgCode)}
                        setValue={(val: string) => setSelectOrgCode(val)}
                        options={orgTreesOptions}
                        required={true}
                      /> */}

            <OrganizationFilteredTree
              functionType={2}
              targetdayForm={moment(dateFromStr).toDate()}
              targetdayTo={moment(dateFromStr).toDate()}
              orgCallback={(args: string | Array<string>) => {
                sessionStorage.removeItem('monthly_shift_current_page_index');
                sessionStorage.setItem('sessionOrgSelect/monthlyShift', String(args));
                setSelectedOrg(String(args));
                dispatch({
                  type: 'ORG_CODE',
                  payload: String(args),
                });
              }}
              initOrgValue={sessionStorage.getItem('sessionOrgSelect/monthlyShift') || selectedOrg}
              staffCode={loginStaffCode}
              orgLabel="出勤店舗"
              addAllItem={false}
              orgCategoryCallback={
                (args: Array<string>) => {
                  setCategoryAr(args);
                  console.log(args, '??????');
                }
              }
              orgNameCallback={(args: string) => {
                sessionStorage.setItem('monthlyShift.orgName', String(args));
                dispatch({
                  type: 'ORG_NAME',
                  payload: String(args),
                });
              }}
            />

            {/* <VerticalLabelSelectFormV2
            label=""
            name="attendEmployment"
            value={selectedOrg}
            setValue={(orgCode) => {
              setSelectedOrg(orgCode);
              sessionStorage.setItem("attendStampList.monthlyShift.orgCode", orgCode);
            }}
            options={orgs}
            ghost={sessionStorage.getItem('loginUser.site') !== '3'}
          /> */}
        </Col>
        <Col lg={1} md={2}>
          <div css={css({ marginTop: '15px' })}>
            <BodyText>業務</BodyText>
          </div>
        </Col>
        <Col lg={5}>
          <VerticalLabelMutilSelectForm
            label=""
            name="businesses"
            values={businessIds.map(
              (business: AttendBusiness) => businessOptions.find(
                (item) => business.businessId === item.value,
              ),
            )}
            setValue={(val: any) => {
              if (val !== '') {
                setBusinessIds(val.slice(0, -1).split(',').map(
                  (id: string) => ({ businessId: id }),
                ));
              } else {
                setBusinessIds([]);
              }
            }}
            options={businessOptions}
            isMulti={true}
          />
        </Col>
      </Row>

        {/* <Row>
        <Col lg={1} md={2}>
          <div css={css({ marginTop: "8px" })}>
            <BodyText>雇用形態</BodyText>
          </div>
        </Col>
        <Col lg={11} md={10} css={css({ display: "flex", flexWrap: "wrap" })}>
          <div css={css({ padding: "8px 10px 5px 0" })}>
            <Checkbox
              id={"all"}
              name={"checkall"}
              label="すべて"
              value={"1"}
              checked={checkall}
              onChange={(event) => {
                let newEmploymentOptions = employmentOptions.map((option) => {
                  option.checked = !checkall;
                  return option;
                });
                setEmploymentOptions(newEmploymentOptions);
                setCheckall(!checkall);
              }}
            />
          </div>
          {employmentOptions.map((option, key) => {
            return (
              <div key={key} css={css({ padding: "8px 10px 5px 0" })}>
                <Checkbox
                  id={option.id}
                  name={option.label}
                  label={option.label}
                  value={option.value}
                  checked={option.checked}
                  onChange={(event) => {
                    let newEmploymentOptions = [...employmentOptions];
                    newEmploymentOptions[key].checked = event.target.checked;
                    setEmploymentOptions(newEmploymentOptions);
                  }}
                />
              </div>
            );
          })}
        </Col>
      </Row> */}
        <Row>
          <FlexBox customStyle={css({ margin: '1rem 0', width: '100%', justifyContent: 'flex-start' })}>
            <span style={{ marginRight: '10px' }}>
              {checkUserRole && checkUserRole.editable === 1 && (
                <PrimaryButton
                  text="支援スタッフの追加"
                  onClick={() => {
                    setOpenRegisterModal(true);
                  }}
                />
              )}
            </span>
            {shiftConfirm?.isConfirmedAllDays && checkUserRole && checkUserRole.usabilityGeneralItem1 === 1 ? (
              <SecondaryButton
                text="シフト確定解除"
                onClick={() => {
                  setOpenConfirmModal(true);
                }}
              />
            ) : checkUserRole && checkUserRole.usabilityGeneralItem1 === 1 && (
              <PrimaryButton
                text="シフト確定"
                disabled={achievementConfirm.isConfirmedAllDays || shiftConfirm.isConfirmedAllDays}
                onClick={() => {
                  setOpenConfirmModal(true);
                }}
              />

            )}
            {checkUserRole && checkUserRole.downloadFlag === 1 && (
              <ClickableIconButton onClick={downloadPdf} additionalCss={css({ margin: '0 10px 0 10px', cursor: 'pointer' })}>
                <span className="print">
                  <Icon type="printer" color="" size="24px" />
                </span>
              </ClickableIconButton>
            )}
            <FlexBoxItem marginLeft="20px">
              {/* <IconLabelButton onClick={() => history.push("/monthlyShift/import")} iconType="addCircle" text="インポート" /> */}
              {/* <PrimaryButton
              text="インポート"
              onClick={() => history.push('/monthlyShift/import')}
            /> */}
            </FlexBoxItem>

            {/* <FlexBoxItem>
            <IconLabelButton
              onClick={downloadExcel}
              iconType="download"
              text="Excelダウンロード"
            />
          </FlexBoxItem> */}

          </FlexBox>
        </Row>
      </FormPadding>
    );
  };

export default Filters;
