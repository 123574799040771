import React, { useState } from 'react';
import TabControl from 'components/atoms/TabControl';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import { useParams, useHistory } from 'react-router-dom';
import FormContents from 'components/atoms/Form/FormContents';
import AdvertisingImportMasterPage from './AdvertisingImportMasterPage';
import AdvertisingMediaMasterPage from './AdvertisingMediaMasterPage';

const AdvertisingMasterPage: React.FC = () => {
  const { currentTab } = useParams();
  const [value, setValue] = useState((currentTab === 'advertisingMaster' || currentTab === 'advertisingSetting') ? currentTab : 'advertisingMaster');
  const history = useHistory();

  return (
    <>
      <SidebarTemplate pageTitle="広告媒体マスタ">
        <FormContents>
          <TabControl
            items={[
              { value: 'advertisingMaster', display: '広告媒体' },
              { value: 'advertisingSetting', display: '広告取込一覧' },
            ]}
            setValue={(val: string) => {
              sessionStorage.setItem('selectedOrgCode', sessionStorage.getItem('loginUser.orgCode')!);
              setValue(val);
              history.push(`/salesAdvertisingMedia/${val}`);
            }}
            currentValue={value}
            isFixed={true}
          />
          {value === 'advertisingMaster'
            ? <AdvertisingMediaMasterPage />
            : <AdvertisingImportMasterPage />}
        </FormContents>
      </SidebarTemplate>
    </>
  );
};

export default AdvertisingMasterPage;
