import moment from 'moment';
import ApiClient from './ApiClient';

export const getPeriodSales = async (
  orgCode: string,
  DateFrom: Date,
  DateTo: Date,
): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const targetDateFrom = moment(DateFrom).format('YYYY-MM-DD');
  const targetDateTo = moment(DateTo).format('YYYY-MM-DD');


  const params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
  };
  const response = await ApiClient.get(`/v1/jsp/sj/period_sales/${companyCode}`, params);
  return response.data;
};

export default { };
