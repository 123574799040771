import { useEffect, useCallback, useState } from 'react';
import { getSmartHRList } from 'api/smartHR';
import SmartHRSyncReportListDomain from 'domain/master/storeManagement/smartHRList';
import moment from 'moment';

export const useSmartHRSyncReportList = (
  monthlyStartDateTarget: string,
  monthlyEndDateTarget: string
) => {
  const [smartHRSyncReportList, setSmartHRSyncReportList] = useState<Array<SmartHRSyncReportListDomain>>([]);
  const [blocking, setBlocking] = useState(false);
  const [stateStatus, setStateStatus] = useState(0);
  // データの取得
  const fetchData = useCallback(async () => {
    const params = {
      syncDateFrom: moment(monthlyStartDateTarget).format('YYYY/MM/DD'),
      syncDateTo: moment(monthlyEndDateTarget).format('YYYY/MM/DD'),
      syncStatus: stateStatus
    }
    setBlocking(true);
    try {
      const response = await getSmartHRList(params);
      setSmartHRSyncReportList(response.map((result: any) => new SmartHRSyncReportListDomain(result)));
    } catch (error) {
      setSmartHRSyncReportList([]);
    } finally {
      setBlocking(false);
    }
  }, [monthlyStartDateTarget, monthlyEndDateTarget, stateStatus]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { smartHRSyncReportList, setSmartHRSyncReportList, blocking, stateStatus, setStateStatus };
};

export default { useSmartHRSyncReportList };
