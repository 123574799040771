import React from 'react';

import { grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';

interface SortIconProps {
  isSortedDesc?: boolean;
  margin? : string;
}

const SortIcon: React.FC<SortIconProps> = ({ isSortedDesc , margin}) => (
  <>
    {
      isSortedDesc
        ? <Icon type="sortDown" color={grayScale.gray100} size="25px" margin={margin} />
        : <Icon type="sortUp" color={grayScale.gray100} size="25px" margin={margin}/>
    }
  </>
);

export default SortIcon;
