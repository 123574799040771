/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import SortIcon from 'components/molecules/SortIcon';
import { grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';
import { useDownload } from './hook';

const styles = css({
  fontWeight: 400,
  fontSize: 14,
});


const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 1,
});

const formatterPercent = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'percent',
});

const DEFAULT_SORT_FIELD = { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number' } as FieldSortState

const MediaAnalysisBillListTable: React.FC<{
    listMediaAnalysisBill: any;
    isActiveRow: number;
    isActiveColumn: number;
    handleClickActive: any;
    orgName: any;
    targetPeriodData: any;
    roleScreen: any;
  }> = ({
    listMediaAnalysisBill,
    isActiveRow,
    isActiveColumn,
    handleClickActive,
    orgName,
    targetPeriodData,
    roleScreen,
  }) => {

    const listData = useMemo(() => listMediaAnalysisBill[0] || [], [listMediaAnalysisBill])
    const totalData = useMemo(() => listMediaAnalysisBill[1] && listMediaAnalysisBill[1][0] ? listMediaAnalysisBill[1][0] : [], [listMediaAnalysisBill])

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(listData, DEFAULT_SORT_FIELD);

    const { downloadMediaAnalysisCsv, downloadMediaAnalysiExcel } = useDownload();

    const searchResult:any[] = [];

    const targetStoresData = `対象店舗: ${orgName}`;

    const headerInformation = [
      ['', '', ''],
      ['組織', '並び順', '売上(税抜)'],
    ];

    let mergedRegions:any[] = [];
    const codeColumnIndex:any[] = [];
    const data = {
      footer: '',
      formName: '媒体分析帳票',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult,
      totalInformation: [],
    };

    const dataExcel = {
      formName: '媒体分析帳票',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex,
    };

    const handleDownloadMediaAnalysisCsv = () => {
      if(totalData){
        if(totalData.slitListMonthlyMediaAnalysisDataList && totalData.slitListMonthlyMediaAnalysisDataList.length > 0){
          totalData.slitListMonthlyMediaAnalysisDataList.map((slitListMonthlyMediaAnalysisData: any, index: number) => {
            // push
            data.headerInformation[0].push(
              slitListMonthlyMediaAnalysisData.advertisingMediaMstName,
              '',
              '',
              ''
            );

            // push
            data.headerInformation[1].push(
              '人数',
              '金額',
              '客単価',
              '組数'
            );

          });
        }
      }
      downloadMediaAnalysisCsv(data);
    };

    const handleDownloadMediaAnalysisExcel = () => {
      if (mergedRegions) {
        mergedRegions = [];
      }


      // merge row
      mergedRegions.push([3, 4, 0, 0]);
      mergedRegions.push([3, 4, 1, 1]);
      mergedRegions.push([3, 4, 2, 2]);
      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [0];
      dataExcel.headerInformation = [
        ['組織', '並び順', '売上(税抜)'],
        ['', '', ''],
      ];

      if(totalData){
        if(totalData.slitListMonthlyMediaAnalysisDataList && totalData.slitListMonthlyMediaAnalysisDataList.length > 0){
          totalData.slitListMonthlyMediaAnalysisDataList.map((slitListMonthlyMediaAnalysisData: any, index: number) => {
            // push
            dataExcel.headerInformation[0].push(
              slitListMonthlyMediaAnalysisData.advertisingMediaMstName,
              '',
              '',
              ''
            );

            // push
            dataExcel.headerInformation[1].push(
              '人数',
              '金額',
              '客単価',
              '組数'
            );

            // merge col row one
            mergedRegions.push([3, 3, 3 + (index * 4), 6 + (index * 4)]);

          });
        }
      }
      return downloadMediaAnalysiExcel(dataExcel);
    };

    useEffect(() => {
      if (dataSort) {
        // content
        dataSort.map((mediaAnalysisBill: any, index: number) => {
          const searchItem: any[] = [];
          searchItem.push(
            mediaAnalysisBill.orgName,
            mediaAnalysisBill.dispOrder,
            `¥${formatter.format(mediaAnalysisBill.netSales)}`
          );

          if(mediaAnalysisBill.slitListMonthlyMediaAnalysisDataList && mediaAnalysisBill.slitListMonthlyMediaAnalysisDataList.length > 0){
            mediaAnalysisBill.slitListMonthlyMediaAnalysisDataList.map((slitListMonthlyMediaAnalysisData: any, index: number) => {
              searchItem.push(
                formatter.format(slitListMonthlyMediaAnalysisData.guestCnt),
                formatter.format(slitListMonthlyMediaAnalysisData.advertisingMediaAmount),
                formatter.format(slitListMonthlyMediaAnalysisData.customerUnitPrice),
                formatter.format(slitListMonthlyMediaAnalysisData.customers)
              );

            });
          }
          searchResult.push(searchItem);
        });

        //
        if(totalData){
          const searchItem: any[] = [];
          searchItem.push(
            '合計',
            '',
            `¥${formatter.format(totalData.netSales)}`
          );

          if(totalData.slitListMonthlyMediaAnalysisDataList && totalData.slitListMonthlyMediaAnalysisDataList.length > 0){
            totalData.slitListMonthlyMediaAnalysisDataList.map((slitListMonthlyMediaAnalysisData: any, index: number) => {
              searchItem.push(
                formatter.format(slitListMonthlyMediaAnalysisData.guestCnt),
                formatter.format(slitListMonthlyMediaAnalysisData.advertisingMediaAmount),
                formatter.format(slitListMonthlyMediaAnalysisData.customerUnitPrice),
                formatter.format(slitListMonthlyMediaAnalysisData.customers)
              );

            });
          }
          searchResult.push(searchItem);
        }
      }
    }, [dataSort, totalData, searchResult]);

  const renderHeaderColumn = useCallback((displayName: string, columnSortField: FieldSortState, rowSpan: number, className: string = "") => (
    <th rowSpan={rowSpan} className={className} onClick={e => sortByFieldPath(columnSortField.fieldPath, columnSortField.sortType)}>
      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
        <span style={{ margin: 'auto' }}>{displayName}</span>
        {sortField.fieldPath === columnSortField.fieldPath && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
        {sortField.fieldPath !== columnSortField.fieldPath && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
      </div>
    </th>
    ), [sortField]);

  return (
    <div>
      {
        roleScreen && roleScreen.downloadFlag === 1 && (
        <FlexBox>
          <FlexBoxItem>
            <IconLabelButton
              onClick={handleDownloadMediaAnalysisCsv}
              iconType="download"
              text=" CSV出力"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <IconLabelButton
              onClick={handleDownloadMediaAnalysisExcel}
              iconType="download"
              text=" EXCEL出力"
            />
          </FlexBoxItem>
        </FlexBox>
      )}
      <div
        className="table-responsive"
        style={{
          display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', maxHeight: 600,
        }}
      >
        <table id="salesPageForm" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }}>
          <thead>
            <tr>
              { renderHeaderColumn('店舗', { fieldPath: 'orgName', sort: 'asc', sortType: 'string'}, 3) }
              { renderHeaderColumn('並び順', { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number'}, 3) }
              { renderHeaderColumn('売上(税抜)', { fieldPath: 'netSales', sort: 'asc', sortType: 'number'}, 3) }
              {
                totalData && totalData.slitListMonthlyMediaAnalysisDataList && totalData.slitListMonthlyMediaAnalysisDataList.length > 0 && totalData.slitListMonthlyMediaAnalysisDataList.map((slitListMonthlyMediaAnalysisData: any, index: number) => (
                  <th key={index} colSpan={4}>{slitListMonthlyMediaAnalysisData.advertisingMediaMstName}</th>
                ))
              }
            </tr>
            <tr className="subThead">
              {
                totalData && totalData.slitListMonthlyMediaAnalysisDataList && totalData.slitListMonthlyMediaAnalysisDataList.length > 0 && totalData.slitListMonthlyMediaAnalysisDataList.map((slitListMonthlyMediaAnalysisData: any, index: number) => (
                  <React.Fragment key={index}>
                   { renderHeaderColumn('人数', { fieldPath: `slitListMonthlyMediaAnalysisDataList[${index}].guestCnt`, sort: 'asc', sortType: 'number'}, 1) }
                   { renderHeaderColumn('金額', { fieldPath: `slitListMonthlyMediaAnalysisDataList[${index}].advertisingMediaAmount`, sort: 'asc', sortType: 'number'}, 1) }
                   { renderHeaderColumn('客単価', { fieldPath: `slitListMonthlyMediaAnalysisDataList[${index}].customerUnitPrice`, sort: 'asc', sortType: 'number'}, 1) }
                   { renderHeaderColumn('組数', { fieldPath: `slitListMonthlyMediaAnalysisDataList[${index}].customers`, sort: 'asc', sortType: 'number'}, 1) }
                  </React.Fragment>
                ))
              }
            </tr>
          </thead>
            {
                  dataSort && dataSort.length > 0 ? dataSort.map((mediaAnalysisBill: any, index: number) => (
                    <tbody key={index} css={styles}>
                      <tr className={isActiveRow === index ? 'activeClickFrames' : ''}>
                        <td
                          className={`textLeft storeStyle ${isActiveColumn === 1 ? 'activeClickFrames' : ''}`}
                          onClick={() => { handleClickActive(index, 1); }}
                        >
                          {mediaAnalysisBill.orgName}
                        </td>
                        <td
                          className={`textLeft storeStyle ${isActiveColumn === 2 ? 'activeClickFrames' : ''}`}
                          onClick={() => { handleClickActive(index, 2); }}
                        >
                          {mediaAnalysisBill.dispOrder}
                        </td>
                        <td
                          className={isActiveColumn === 3 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 3); }}
                        >
                          {`¥${formatter.format(mediaAnalysisBill.netSales)}`}
                        </td>
                        {
                          mediaAnalysisBill.slitListMonthlyMediaAnalysisDataList && mediaAnalysisBill.slitListMonthlyMediaAnalysisDataList.length > 0 ? mediaAnalysisBill.slitListMonthlyMediaAnalysisDataList.map((slitListMonthlyMediaAnalysisData: any, indexsub: number) => (
                            <React.Fragment key={indexsub}>
                              <td
                                className={isActiveColumn === (4 + (4 * indexsub)) ? 'activeClickFrames' : ''}
                                onClick={() => { handleClickActive(index, 4 + (4 * indexsub)); }}
                              >
                                {formatter.format(slitListMonthlyMediaAnalysisData.guestCnt)}
                              </td>
                              <td
                                className={isActiveColumn === (5 + (4 * indexsub)) ? 'activeClickFrames' : ''}
                                onClick={() => { handleClickActive(index, 5 + (4 * indexsub)); }}
                              >
                                {formatter.format(slitListMonthlyMediaAnalysisData.advertisingMediaAmount)}
                              </td>
                              <td
                                className={isActiveColumn === (6 + (4 * indexsub)) ? 'activeClickFrames' : ''}
                                onClick={() => { handleClickActive(index, 6 + (4 * indexsub)); }}
                              >
                                {formatter.format(slitListMonthlyMediaAnalysisData.customerUnitPrice)}
                              </td>
                              <td
                                className={isActiveColumn === (7 + (4 * indexsub)) ? 'activeClickFrames' : ''}
                                onClick={() => { handleClickActive(index, 7 + (4 * indexsub)); }}
                              >
                                {formatter.format(slitListMonthlyMediaAnalysisData.customers)}
                              </td>
                            </React.Fragment>
                          )) : ''
                        }
                      </tr>
                    </tbody>
                  )) : <tbody css={styles}></tbody>
              }
            {totalData
                  && (
                  <tfoot css={styles}>
                    <tr>
                      <td className="textLeft">合計</td>
                      <td></td>
                      <td>
                        {`¥${formatter.format(totalData.netSales)}`}
                      </td>
                      {
                        totalData.slitListMonthlyMediaAnalysisDataList && totalData.slitListMonthlyMediaAnalysisDataList.length > 0 ? totalData.slitListMonthlyMediaAnalysisDataList.map((slitListMonthlyMediaAnalysisData: any, index: number) => (
                          <React.Fragment key={index}>
                            <td>
                              {formatter.format(slitListMonthlyMediaAnalysisData.guestCnt)}
                            </td>
                            <td>
                              {formatter.format(slitListMonthlyMediaAnalysisData.advertisingMediaAmount)}
                            </td>
                            <td>
                              {formatter.format(slitListMonthlyMediaAnalysisData.customerUnitPrice)}
                            </td>
                            <td>
                              {formatter.format(slitListMonthlyMediaAnalysisData.customers)}
                            </td>
                          </React.Fragment>
                        )) : ''
                      }
                    </tr>
                  </tfoot>
                  )}
        </table>
      </div>
    </div>
  );
};

export default MediaAnalysisBillListTable;
