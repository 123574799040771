/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */
/** @jsx jsx*/
import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import ShiftFormSubmitArea from 'components/atoms/Form/ShiftFormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import FormTitle from 'components/atoms/Form/FormTitle';
import ShiftFormField from 'components/atoms/Form/ShiftFormField';
import FileSelectButton from 'components/molecules/FileSelectButton';
import { utilityColor } from 'components/styles';
import BodyText from 'components/atoms/BodyText';
import ConfirmModal from 'components/organismos/ConfirmModal';
import useMonthlyShiftImport from './hooks';
import FormPadding from 'components/atoms/Form/FormPadding';
import { css, jsx } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';

const wrap = {
  padding: '0px 30px 30px'
}

const styles = {
  fileUpload: css({
    backgroundColor: '#fff',
    padding: '15px 0px',
    maxWidth: '650px'
  }),
  groupButton: css({
    marginTop: '15px'
  }),
  formField: css({
    display: 'flex',
    justifyContent: 'center'
  })
}
const MonthlyShiftImport: React.FC = () => {

  const {
    setUploadFile, uploadFile, onSubmit, errors, confirmModalOpen, closeConfirmModal, handleSubmit, inputValue, blocking
  } = useMonthlyShiftImport();
  return (
    <BlockUI blocking={blocking}>
      <FormContents>
        <FormPadding customStyle={wrap}>
          <div style={{ marginTop: '50px' }}>
            <FormTitle
              title="登録するデータ"
            />
          </div>
          <div css={styles.fileUpload}>
            <FileSelectButton
              text="Excelファイルをドラッグ＆ドロップ  または  クリックしてファイルを選択してください"
              ghost={true}
              setFile={setUploadFile}
              value={inputValue}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>

          {uploadFile || errors
            ? (
              <ShiftFormField
                displayBlock={true}
              >
                <div>
                  {uploadFile?.name}
                </div>
                {errors && errors.map((error) => (
                  <div key={error}>
                    <BodyText color={utilityColor.error}>
                      {error}
                    </BodyText>
                  </div>
                ))}
              </ShiftFormField>
            )
            : null}
          <ShiftFormSubmitArea customStyle={styles.groupButton}>
            <ShiftFormField customStyle={styles.formField}>
              <PrimaryButton
                text="インポート"
                onClick={handleSubmit}
                disabled={!uploadFile}
              />
            </ShiftFormField>
            <ShiftFormField customStyle={styles.formField}>
              <PrimaryButton
                text="戻る"
                ghost={true}
                // eslint-disable-next-line no-restricted-globals
                onClick={() => history.back()}
              />
            </ShiftFormField>
          </ShiftFormSubmitArea>
          <ConfirmModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            title="確認メッセージ"
            onSubmit={onSubmit}
            content="ファイルをアップロードします。よろしいですか？"
            items={[]}
          />
        </FormPadding>
      </FormContents>
    </BlockUI>
  );
};


export default MonthlyShiftImport;
