import _ from "lodash";
import moment from 'moment';
import { formatNumber } from "utility/formatUtil";

export interface TimeDaySeparateSalesReport {
  /** 日付*/
  targetDay: Date,
  /** 表示用日付*/
  dispTargetDay: string,
  /** 時間別売上 */
  items: Array<TimeDaySeparateSalesReportItemDomain>,
}

export interface TimeDaySeparateSalesReportItem {
  pk: {
    /** 日付 */
    targetDay: Date,
    /** 時間帯 */
    timeZone: string,
  },
  /** 表示順 */
  dispSort: number,
  /** 売上 */
  amount: number,
  /** 数量 */
  item: number,
  /** 客数 */
  guestCnt: number,
  /** 組数 */
  customers: number,
}

/** 時間別帳票 日別売上  */
export default class TimeDaySeparateSalesReportItemDomain {

  private _targetDay: string;
  private _timeZone: string;
  private _dispSort: number;
  private _amount: string;
  private _item: string;
  private _guestCnt: string;
  private _customers: string;

  constructor(private rawData: TimeDaySeparateSalesReportItem) {
    this._targetDay = rawData.pk ? moment(rawData.pk.targetDay, 'YYYY-MM-DD').format('YYYY年MM月DD日（ddd）') : '';
    this._timeZone  = rawData.pk ? `${Number(rawData.pk.timeZone)}時` : "合計";
    this._dispSort  = rawData.dispSort;
    this._amount    = formatNumber(rawData.amount);
    this._item      = formatNumber(rawData.item, 0, 2);
    this._guestCnt  = formatNumber(rawData.guestCnt);
    this._customers = formatNumber(rawData.customers);
  }

  static generateInitial(): TimeDaySeparateSalesReportItemDomain {
    return new TimeDaySeparateSalesReportItemDomain({
      pk: {
        targetDay: new Date(),
        timeZone: '',
      },
      dispSort: 0,
      amount: 0,
      item: 0,
      guestCnt: 0,
      customers: 0,
    });
  }

  /** 表示データ（画面非表示のプロパティを除外） */
  getDispData(): Partial<this>{
    return _.omit(this, ['rawData', '_targetDay', '_timeZone', '_dispSort']);
  }

  /** 出力データ（CSV出力など） */
  getOutputData(): Array<Partial<this>> {
    return Object.values(this.getDispData());
  }

  getRawData(): TimeDaySeparateSalesReportItem {
    return this.rawData;
  }

  get targetDay(): string {
    return this._targetDay;
  }

  get timeZone(): string {
    return this._timeZone;
  }

  get dispSort(): number {
    return this._dispSort;
  }

  get amount(): string {
    return this._amount;
  }

  get item(): string {
    return this._item;
  }

  get guestCnt(): string {
    return this._guestCnt;
  }

  get customers(): string {
    return this._customers;
  }

}
