export interface MonthlyReport {
    targetDate:any,
    companyCode: string;
    orgCode: string;
    orgName: string;
    netSales: number;
    tax: number,
    totalSales: number,
    cashVoucherSalesTotalAmount:number,
    guestCnt:number,
    perCustomerPrice: number,
    customers: number,
    perGroupPrice: number,
    totalDiscountAmount: number,
    totalDiscountCnt: number,
    voidAmount: number,
    voidCnt: number,
    stopAccountAmount: number,
    stopAccountCnt: number,
    posMenuCancelAmount: number,
    posMenuCancelCnt: number,
    changeReserve: number,
    cashStock: number,
    excessAndDeficiency: number,
    bankDepositAmount: number,
    areaName: string;
    businessCategoryName: string;
    monthlyComparePrevYear:number;
    paymentMediaAmount:any;
    groupCnt:number;
    discountAmount:number;
    discountTypeMstCode: string;
    discountCnt:number;
    cancelMenuAmount:number;
    cancelMenuCnt:number,
    cashExcessOrDeficiency:number,
    bankDeposit:number,
    itemPub:boolean,
    itemSat:boolean,
    itemSun:boolean,
    itemMon:boolean,
    itemTue:boolean,
    itemWed:boolean,
    itemTur:boolean,
    itemFri:boolean,
    timePub:boolean,
    timeSat:boolean,
    timeSun:boolean,
    timeMon:boolean,
    timeTue:boolean,
    timeWed:boolean,
    timeTur:boolean,
    timeFri:boolean,
  }

export default class MonthlyReportDomain {
  constructor(private rawData: MonthlyReport) {
    // do nothing
  }

  static generateInitial(): MonthlyReportDomain {
    return new MonthlyReportDomain({
      targetDate: '',
      companyCode: '',
      orgCode: '',
      orgName: '',
      netSales: 0,
      tax: 0,
      totalSales: 0,
      cashVoucherSalesTotalAmount: 0,
      guestCnt: 0,
      perCustomerPrice: 0,
      customers: 0,
      perGroupPrice: 0,
      totalDiscountAmount: 0,
      totalDiscountCnt: 0,
      voidAmount: 0,
      voidCnt: 0,
      stopAccountAmount: 0,
      stopAccountCnt: 0,
      posMenuCancelAmount: 0,
      posMenuCancelCnt: 0,
      changeReserve: 0,
      cashStock: 0,
      excessAndDeficiency: 0,
      bankDepositAmount: 0,
      areaName: '',
      businessCategoryName: '',
      monthlyComparePrevYear: 0,
      paymentMediaAmount: 0,
      groupCnt: 0,
      discountAmount: 0,
      discountTypeMstCode: '',
      discountCnt: 0,
      cancelMenuAmount: 0,
      cancelMenuCnt: 0,
      cashExcessOrDeficiency: 0,
      bankDeposit: 0,
      itemPub: true,
      itemSun: true,
      itemSat: true,
      itemMon: true,
      itemTue: true,
      itemWed: true,
      itemTur: true,
      itemFri: true,
      timePub: true,
      timeSun: true,
      timeSat: true,
      timeMon: true,
      timeTue: true,
      timeWed: true,
      timeTur: true,
      timeFri: true,
    });
  }

  getRawData(): MonthlyReport {
    return this.rawData;
  }

  set timeFri(timeFri: boolean) {
    this.rawData.timeFri = timeFri;
  }

  get timeFri(): boolean {
    return this.rawData.timeFri;
  }

  set timeTur(timeTur: boolean) {
    this.rawData.timeTur = timeTur;
  }

  get timeTur(): boolean {
    return this.rawData.timeTur;
  }

  set timeWed(timeWed: boolean) {
    this.rawData.timeWed = timeWed;
  }

  get timeWed(): boolean {
    return this.rawData.timeWed;
  }

  set timeTue(timeTue: boolean) {
    this.rawData.timeTue = timeTue;
  }

  get timeTue(): boolean {
    return this.rawData.timeTue;
  }

  set timeMon(timeMon: boolean) {
    this.rawData.timeMon = timeMon;
  }

  get timeMon(): boolean {
    return this.rawData.timeMon;
  }

  set timeSat(timeSat: boolean) {
    this.rawData.timeSat = timeSat;
  }

  get timeSat(): boolean {
    return this.rawData.timeSat;
  }

  set timeSun(timeSun: boolean) {
    this.rawData.timeSun = timeSun;
  }

  get timeSun(): boolean {
    return this.rawData.timeSun;
  }

  set timePub(timePub: boolean) {
    this.rawData.timePub = timePub;
  }

  get timePub(): boolean {
    return this.rawData.timePub;
  }

  set itemSun(itemSun: boolean) {
    this.rawData.itemSun = itemSun;
  }

  get itemSun(): boolean {
    return this.rawData.itemSun;
  }

  set itemFri(itemFri: boolean) {
    this.rawData.itemFri = itemFri;
  }

  get itemFri(): boolean {
    return this.rawData.itemFri;
  }

  set itemTur(itemTur: boolean) {
    this.rawData.itemTur = itemTur;
  }

  get itemTur(): boolean {
    return this.rawData.itemTur;
  }

  set itemWed(itemWed: boolean) {
    this.rawData.itemWed = itemWed;
  }

  get itemWed(): boolean {
    return this.rawData.itemWed;
  }

  set itemTue(itemTue: boolean) {
    this.rawData.itemTue = itemTue;
  }

  get itemTue(): boolean {
    return this.rawData.itemTue;
  }

  set itemMon(itemMon: boolean) {
    this.rawData.itemMon = itemMon;
  }

  get itemMon(): boolean {
    return this.rawData.itemMon;
  }

  set itemPub(itemPub: boolean) {
    this.rawData.itemPub = itemPub;
  }

  get itemPub(): boolean {
    return this.rawData.itemPub;
  }

  set itemSat(itemSat: boolean) {
    this.rawData.itemSat = itemSat;
  }

  get itemSat(): boolean {
    return this.rawData.itemSat;
  }


  set bankDeposit(bankDeposit: number) {
    this.rawData.bankDeposit = bankDeposit;
  }

  get bankDeposit(): number {
    return this.rawData.bankDeposit;
  }

  set cashExcessOrDeficiency(cashExcessOrDeficiency: number) {
    this.rawData.cashExcessOrDeficiency = cashExcessOrDeficiency;
  }

  get cashExcessOrDeficiency(): number {
    return this.rawData.cashExcessOrDeficiency;
  }

  set cancelMenuCnt(cancelMenuCnt: number) {
    this.rawData.cancelMenuCnt = cancelMenuCnt;
  }

  get cancelMenuCnt(): number {
    return this.rawData.cancelMenuCnt;
  }

  set cancelMenuAmount(cancelMenuAmount: number) {
    this.rawData.cancelMenuAmount = cancelMenuAmount;
  }

  get cancelMenuAmount(): number {
    return this.rawData.cancelMenuAmount;
  }

  set discountCnt(discountCnt: number) {
    this.rawData.discountCnt = discountCnt;
  }

  get discountCnt(): number {
    return this.rawData.discountCnt;
  }

  set discountAmount(discountAmount: number) {
    this.rawData.discountAmount = discountAmount;
  }

  get discountAmount(): number {
    return this.rawData.discountAmount;
  }

  set discountTypeMstCode(discountTypeMstCode: string) {
    this.rawData.discountTypeMstCode = discountTypeMstCode;
  }

  get discountTypeMstCode(): string {
    return this.rawData.discountTypeMstCode;
  }

  set paymentMediaAmount(paymentMediaAmount: number) {
    this.rawData.paymentMediaAmount = paymentMediaAmount;
  }

  get paymentMediaAmount(): number {
    return this.rawData.paymentMediaAmount;
  }

  set groupCnt(groupCnt: number) {
    this.rawData.groupCnt = groupCnt;
  }

  get groupCnt(): number {
    return this.rawData.groupCnt;
  }

  set targetDate(targetDate: number) {
    this.rawData.targetDate = targetDate;
  }

  get targetDate(): number {
    return this.rawData.targetDate;
  }

  set monthlyComparePrevYear(monthlyComparePrevYear: number) {
    this.rawData.monthlyComparePrevYear = monthlyComparePrevYear;
  }

  get monthlyComparePrevYear(): number {
    return this.rawData.monthlyComparePrevYear;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = String(companyCode);
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = String(orgCode);
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = String(orgName);
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set netSales(netSales: number) {
    this.rawData.netSales = Number(netSales);
  }

  get netSales(): number {
    return this.rawData.netSales;
  }

  set tax(tax: number) {
    this.rawData.tax = Number(tax);
  }

  get tax(): number {
    return this.rawData.tax;
  }

  set totalSales(totalSales: number) {
    this.rawData.totalSales = Number(totalSales);
  }

  get totalSales(): number {
    return this.rawData.totalSales;
  }

  set cashVoucherSalesTotalAmount(cashVoucherSalesTotalAmount: number) {
    this.rawData.cashVoucherSalesTotalAmount = Number(cashVoucherSalesTotalAmount);
  }

  get cashVoucherSalesTotalAmount(): number {
    return this.rawData.cashVoucherSalesTotalAmount;
  }

  set guestCnt(guestCnt: number) {
    this.rawData.guestCnt = Number(guestCnt);
  }

  get guestCnt(): number {
    return this.rawData.guestCnt;
  }

  set perCustomerPrice(perCustomerPrice: number) {
    this.rawData.perCustomerPrice = Number(perCustomerPrice);
  }

  get perCustomerPrice(): number {
    return this.rawData.perCustomerPrice;
  }

  set customers(customers: number) {
    this.rawData.customers = Number(customers);
  }

  get customers(): number {
    return this.rawData.customers;
  }

  set perGroupPrice(perGroupPrice: number) {
    this.rawData.perGroupPrice = Number(perGroupPrice);
  }

  get perGroupPrice(): number {
    return this.rawData.perGroupPrice;
  }

  set totalDiscountAmount(totalDiscountAmount: number) {
    this.rawData.totalDiscountAmount = Number(totalDiscountAmount);
  }

  get totalDiscountAmount(): number {
    return this.rawData.totalDiscountAmount;
  }

  set totalDiscountCnt(totalDiscountCnt: number) {
    this.rawData.totalDiscountCnt = Number(totalDiscountCnt);
  }

  get totalDiscountCnt(): number {
    return this.rawData.totalDiscountCnt;
  }

  set voidAmount(voidAmount: number) {
    this.rawData.voidAmount = Number(voidAmount);
  }

  get voidAmount(): number {
    return this.rawData.voidAmount;
  }

  set voidCnt(voidCnt: number) {
    this.rawData.voidCnt = Number(voidCnt);
  }

  get voidCnt(): number {
    return this.rawData.voidCnt;
  }

  set stopAccountAmount(stopAccountAmount: number) {
    this.rawData.stopAccountAmount = Number(stopAccountAmount);
  }

  get stopAccountAmount(): number {
    return this.rawData.stopAccountAmount;
  }

  set stopAccountCnt(stopAccountCnt: number) {
    this.rawData.stopAccountCnt = Number(stopAccountCnt);
  }

  get stopAccountCnt(): number {
    return this.rawData.stopAccountCnt;
  }

  set posMenuCancelAmount(posMenuCancelAmount: number) {
    this.rawData.posMenuCancelAmount = Number(posMenuCancelAmount);
  }

  get posMenuCancelAmount(): number {
    return this.rawData.posMenuCancelAmount;
  }

  set posMenuCancelCnt(posMenuCancelCnt: number) {
    this.rawData.posMenuCancelCnt = Number(posMenuCancelCnt);
  }

  get posMenuCancelCnt(): number {
    return this.rawData.posMenuCancelCnt;
  }

  set changeReserve(changeReserve: number) {
    this.rawData.changeReserve = Number(changeReserve);
  }

  get changeReserve(): number {
    return this.rawData.changeReserve;
  }

  set cashStock(cashStock: number) {
    this.rawData.cashStock = Number(cashStock);
  }

  get cashStock(): number {
    return this.rawData.cashStock;
  }

  set excessAndDeficiency(excessAndDeficiency: number) {
    this.rawData.excessAndDeficiency = Number(excessAndDeficiency);
  }

  get excessAndDeficiency(): number {
    return this.rawData.excessAndDeficiency;
  }

  set bankDepositAmount(bankDepositAmount: number) {
    this.rawData.bankDepositAmount = Number(bankDepositAmount);
  }

  get bankDepositAmount(): number {
    return this.rawData.bankDepositAmount;
  }

  set areaName(areaName: string) {
    this.rawData.areaName = areaName;
  }

  get areaName(): string {
    return this.rawData.areaName;
  }

  set businessCategoryName(businessCategoryName: string) {
    this.rawData.businessCategoryName = businessCategoryName;
  }

  get businessCategoryName(): string {
    return this.rawData.businessCategoryName;
  }
}
