import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesAdvertisingMonthlyReport from 'components/organismos/master/general/salesPage/salesAdvertisingMonthlyReport';
import React from 'react';

const SalesAdvertisingMonthlyReportPage = () => (
  <SidebarTemplate pageTitle="広告売上管理月報">
    <SalesAdvertisingMonthlyReport />
  </SidebarTemplate>
);
export default SalesAdvertisingMonthlyReportPage;
