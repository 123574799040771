import axios from 'axios';
import ApiClient from './ApiClient';

export const staffRegisterTouch = async (mail: string): Promise<any> => {
  const payload = {
    mail
  };
  const response = await ApiClient.postWithoutAuth('/v2/staffRegistration/touch', payload);
  return response.data;
};

export const checkEmailExists = async (mail: string): Promise<any> => {
  const params = {
    mail
  };

  const response = await ApiClient.getNoAuth('', '/v2/signup/checkMail', params);
  return response.data;
};
