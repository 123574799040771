/** @jsx jsx */
import React, { useState } from 'react';
import moment from 'moment';
import { downloadCsv, downloadExcel } from 'api/salesAdvertsingPlanReport';
import useToastNotification from 'hooks/useToastNotification';

interface ISalesAdvertsingPlanReport {
  orgName: string,
  setorgName: React.Dispatch<React.SetStateAction<string>>,
  targetDateFrom: Date,
  setTargetDateFrom: React.Dispatch<React.SetStateAction<Date>>,
  targetDateTo: Date,
  setTargetDateTo: React.Dispatch<React.SetStateAction<Date>>,
  stores: any,
  isLoading: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setCategoryAry: React.Dispatch<React.SetStateAction<any>>,
  orgCode: string | null, 
  setOrgCode: React.Dispatch<React.SetStateAction<string | null>>,
  handleOnDownloadCsv: (fileName: string) => void,
  handleOnDownloadExcel: (fileName: string) => void
}

// eslint-disable-next-line max-len
export const salesAdvertsingPlanReportContext = React.createContext<ISalesAdvertsingPlanReport>({} as ISalesAdvertsingPlanReport);
export const useSalesAdvertsingPlanReportContext = (): ISalesAdvertsingPlanReport => {
  const context = React.useContext(salesAdvertsingPlanReportContext);
  return context;
};
const useSalesAdvertsingPlanReport = () => {
  const { errorNotification } = useToastNotification();
  const orgNameSelected = sessionStorage.getItem('sessionOrgNameSelected');
  const [orgName, setorgName] = React.useState(!orgNameSelected ? '' : orgNameSelected);

  const fromdate = new Date();
  const datefrom = new Date(fromdate.getFullYear(), fromdate.getMonth(), 1); // get first date
  const dateTo = moment(datefrom).clone().endOf('month').toDate(); // get date
  const [targetDateFrom, setTargetDateFrom] = React.useState<Date>(datefrom);
  const [targetDateTo, setTargetDateTo] = React.useState<Date>(dateTo);

  const [isLoading, setIsLoading] = React.useState(false);
  const [stores, setStores] = React.useState<any[]>([]);
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('sessionOrgSelect/incomeAndExpenditureStore') ? sessionStorage.getItem('sessionOrgSelect/incomeAndExpenditureStore') : '');
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);

  const handleError = (error: any) => {
    if (error.response && error.response.data && error.response.data.errors) {
      let msgError = '';
      error.response.data.errors.map((item: { defaultMessage: string; }) => {
        msgError += `${item.defaultMessage} \n`;
        return msgError;
      });
      errorNotification(msgError);
    } else {
      errorNotification('サーバー側でエラーが発生しました。');
    }
  }

  const handleOnDownloadCsv = (fileName: string) => {
    let params = {
      orgCode: String(orgCode),
      monthlyStartDate: moment(targetDateFrom).startOf('month').format('YYYY/MM/DD'),
      monthlyEndDate: moment(targetDateTo).endOf('month').format('YYYY/MM/DD'),
      loginStaffCode: loginStaffCode,
      functionType: "3"
    }
    const queryParameters = new URLSearchParams(params);
    const queryParameter = new URLSearchParams(categoryAry.map(s=>['categoryAry',s]));
    const queryString = categoryAry?.length > 0 ? `${queryParameters.toString()}&${queryParameter.toString()}` : queryParameters.toString();
    setIsLoading(true);
    downloadCsv(queryString, fileName).then(()=>{
      setIsLoading(false);
    }).catch((error)=>{
      setIsLoading(false);
      handleError(error);
    }).finally(()=>{
      setIsLoading(false);
    });
  }

  const handleOnDownloadExcel = (fileName: string) => {
    let params = {
      orgCode: String(orgCode),
      monthlyStartDate: moment(targetDateFrom).startOf('month').format('YYYY/MM/DD'),
      monthlyEndDate: moment(targetDateTo).endOf('month').format('YYYY/MM/DD'),
      loginStaffCode: loginStaffCode,
      functionType: "3"
    }
    const queryParameters = new URLSearchParams(params);
    const queryParameter = new URLSearchParams(categoryAry.map(s=>['categoryAry',s]));
    const queryString = categoryAry?.length > 0 ? `${queryParameters.toString()}&${queryParameter.toString()}` : queryParameters.toString();
    setIsLoading(true);
    downloadExcel(queryString, fileName).then(()=>{
      setIsLoading(false);
    }).catch((error)=>{
      setIsLoading(false);
      handleError(error);
    }).finally(()=>{
      setIsLoading(false);
    });
  }

  return {
    stores,
    isLoading,
    setIsLoading,

    orgName,
    setorgName,

    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    setCategoryAry,
    orgCode,
    setOrgCode,
    handleOnDownloadCsv,
    handleOnDownloadExcel
  };
};

export default useSalesAdvertsingPlanReport;
