import { WorkingDays } from 'domain/master/labor/workingDays';
import flatten from 'flat';
import ApiClient from './ApiClient';


export const getWorkingDayList = async (): Promise<Array<WorkingDays>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/workingDays/list/${companyCode}`, {});
  return response.data;
};

export const deleteWorkingDayById = async (workingDaysId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = { workingDaysId };
  const reponse = await ApiClient.delete(`/v1/workingDays/${companyCode}`, params);
  return reponse.data;
};

export const getWorkingDaysById = async (targetYear: string, workingDaysId: string):
Promise<Array<WorkingDays>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    year: targetYear,
    workingDaysId,
  };
  const response = await ApiClient.get(`/v1/workingDays/currentYear/${companyCode}`, query);
  return response.data;
};

export const createOrUpdateWorkingDays = async (companyCode: string, dataWorkingDays: any,
  tabIndex: number) => {
  const query = {
    tabIndex,
  };

  const flattenParams: {[key: string]: any} = flatten(dataWorkingDays);
  const param = new URLSearchParams();
  Object.keys(flattenParams).forEach((flattenKey) => {
    if (flattenKey.includes('workingDays') && !flattenKey.includes('workingDaysId')
    && !flattenKey.includes('workingDaysPatternCode')
    && !flattenKey.includes('workingDaysPatternName')
    && !flattenKey.includes('totalWorkingDays')) {
      param.append('workingDays', flattenParams[flattenKey]);
    } else {
      param.append(flattenKey, flattenParams[flattenKey]);
    }
  });

  const response = await ApiClient.postOriginalData(`/v1/workingDays/${companyCode}`, query, param);
  return response.data;
};

export const getWorkingDaysPreviousYear = async (targetYear: string, workingDaysId: string):
Promise<Array<WorkingDays>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    year: targetYear,
    workingDaysId,
  };
  const response = await ApiClient.get(`/v1/workingDays/previousYear/${companyCode}`, query);
  return response.data;
};


export default {
  getWorkingDayList,
};
