import ApiClient from "api/ApiClient";
import {
    ITransfer,
    IStamp,
    IHoliday,
    IOvertime,
    IHolidayWork,
    IchangeLog
} from "./interface";

// Applying
export const getApplyingStampList = async (params?: any): Promise<IStamp[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/applicationStampList/${companyCode}`, {}, params);
    
    return data;
};
export const getApplyingHolidayList = async (params?: any): Promise<IHoliday[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/applicationHolidayList/${companyCode}`, {}, params);
    
    return data;
};
export const getApplyingOverTimeList = async (params?: any): Promise<IOvertime[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/applicationOverTimeList/${companyCode}`, {}, params);
    
    return data;
};
export const getApplyingHolidayWorkList = async (params?: any): Promise<IHolidayWork[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/applicationHolidayWorkList/${companyCode}`, {}, params);
    
    return data;
};
export const getApplyingTransferList = async (params?: any): Promise<ITransfer[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.get(`/v1/attendModifier/approval/applicationTransferList/${companyCode}`, {}, params);
    
    return data;
};

// Approved
export const getApprovedStampList = async (params?: any): Promise<IStamp[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v2/attendModifier/approval/approvalStampList/${companyCode}`, {}, params);
    
    return data;
};
export const getApprovedHolidayList = async (params?: any): Promise<IHoliday[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v2/attendModifier/approval/approvalHolidayList/${companyCode}`, {}, params);
    
    return data;
};
export const getApprovedOverTimeList = async (params?: any): Promise<IOvertime[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v2/attendModifier/approval/approvalOverTimeList/${companyCode}`, {}, params);
    
    return data;
};
export const getApprovedHolidayWorkList = async (params?: any): Promise<IHolidayWork[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v2/attendModifier/approval/approvalHolidayWorkList/${companyCode}`, {}, params);
    
    return data;
};
export const getApprovedTransferList = async (params?: any): Promise<ITransfer[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.get(`/v2/attendModifier/approval/approvalTransferList/${companyCode}`, {}, params);
    
    return data;
};

// Rejected
export const getRejectedStampList = async (params?: any): Promise<IStamp[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v2/attendModifier/approval/rejectionStampList/${companyCode}`, {}, params);
    
    return data;
};
export const getRejectedHolidayList = async (params?: any): Promise<IHoliday[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v2/attendModifier/approval/rejectionHolidayList/${companyCode}`, {}, params);
    
    return data;
};
export const getRejectedOverTimeList = async (params?: any): Promise<IOvertime[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v2/attendModifier/approval/rejectionOverTimeList/${companyCode}`, {}, params);
    
    return data;
};
export const getRejectedHolidayWorkList = async (params?: any): Promise<IHolidayWork[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v2/attendModifier/approval/rejectionHolidayWorkList/${companyCode}`, {}, params);
    
    return data;
};
export const getRejectedTransferList = async (params?: any): Promise<ITransfer[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.get(`/v2/attendModifier/approval/rejectionTransferList/${companyCode}`, {}, params);
    
    return data;
};

//Stamp Approve
export const postApproveStamp = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/stamp/${companyCode}`, {}, params);
    return data;
};
export const postBulkApproveStamp = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/stamps/${companyCode}`, {}, params);
    return data;
};

//Holiday Approve
export const postApproveHoliday = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/holiday/${companyCode}`, {}, params);
    return data;
};
export const postBulkApproveHoliday = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/holidays/${companyCode}`, {}, params);
    return data;
};

//Overtime Approve
export const postApproveOvertime = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/overtime/${companyCode}`, {}, params);
    return data;
};
export const postBulkApproveOvertime = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/overtimes/${companyCode}`, {}, params);
    return data;
};

//HolidayWork Approve
export const postApproveHolidayWork = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/holidaywork/${companyCode}`, {}, params);
    return data;
};
export const postBulkApproveHolidayWork = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/holidayworks/${companyCode}`, {}, params);
    return data;
};

//Transfer Approve
export const postApproveTransfer = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/transfer/${companyCode}`, {}, params);
    return data;
};
export const postBulkApproveTransfer = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/transfers/${companyCode}`, {}, params);
    return data;
};

//Get applying-stamplist History
export const getApplyingStampListHistory = async (params?: any): Promise<IchangeLog[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.get(`/v1/attendModifier/stampHistoryListByAppId/${companyCode}`, {}, params);
    // data.sort((a:IchangeLog, b:IchangeLog) => (a.version - b.version));
    return data;
};