import ApiClient from './ApiClient';

export const getList = async (companyCode: string):Promise<any> => {
  const params = {};
  const response = await ApiClient.get(`/v1/term/list/${companyCode}`, params);
  return response.data;
};

export const deleteById = async (companyCode: string, termId: string): Promise<any> => {
  const params = {
    termId,
  };
  const response = await ApiClient.delete(`/v1/term/${companyCode}`, params);
  return response.data;
};


export const updateSortGetListTimeZone = async (companyCode: string, data: any) => {
  const query = {
  };
  const response = await ApiClient.post(`/v1/term/${companyCode}`, query, data);
  return response.data;
};
export default getList;
