import ApiClient from './ApiClient';

export const getOvertime = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const response = await ApiClient.get(`/v1/attendOvertimeApplication/overtime/${companyCode}`, {}, params);
  return response.data;
};

export const postOvertime = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v3/attendOvertimeApplication/overtime/${companyCode}`, {}, params);
  return response.data;
};

export default {
  getOvertime,
  postOvertime,
};
