import ApiClient from './ApiClient';

export const getRevision = async (): Promise<any> => {
  // TODO 仮リクエスト
  const params = {
  };

  const url = `/revision.json?${new Date().getTime()}`;
  const response = await ApiClient.getOrgDomain(url, params);
  return response.data;
};

export default getRevision;
