import SidebarTemplate from 'components/templates/SidebarTemplate';
import MonthlyReportLayout from 'components/organismos/master/storeManagement/report/monthlyReport/MonthlyReportLayout';
import React from 'react';
import NumberIndicationAnalysisLayout from 'components/organismos/master/storeManagement/report/NumberIndicationAnalysisForm';

const NumberIndicationAnalysisPage = () => (
    <SidebarTemplate pageTitle="指摘件数分析帳票">
    <NumberIndicationAnalysisLayout/>
</SidebarTemplate>
    
);
export default NumberIndicationAnalysisPage;
