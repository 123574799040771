import moment from 'moment';
/**
 * API GpsStampHistory
 */

export interface GpsStampHistory {
    gpsAchievementId: string;
    orgCode: string;
    orgName: string;
    staffCode: string,
    staffName: string;
    targetDay: Date;
    workTime: Date;
    gpsStampKind: string;
    address: string;
    latitude: string,
    longitude: string;
}

export default class GpsStampHistoryDomain {
  constructor(private rawData: GpsStampHistory) {
    // do nothing
  }

  static generateInitial(): GpsStampHistoryDomain {
    return new GpsStampHistoryDomain({
      gpsAchievementId: '',
      targetDay: new Date(),
      workTime: new Date(),
      gpsStampKind: '',
      address: '',
      staffName: '',
      staffCode: '',
      latitude: '',
      orgCode: '',
      orgName: '',
      longitude: '',

    });
  }

  getRawData(): GpsStampHistory {
    return this.rawData;
  }

  get gpsAchievementId(): string {
    return this.rawData.gpsAchievementId;
  }

  set gpsAchievementId(gpsAchievementId: string) {
    this.rawData.gpsAchievementId = gpsAchievementId;
  }

  get targetDay(): Date {
    return this.rawData.targetDay;
  }

  get targetDayStr(): string {
    return moment(moment(this.rawData.targetDay).toDate()).format('YYYY/MM/DD HH:mm:ss');
  }

  set targetDay(date: Date) {
    this.rawData.targetDay = date;
  }

  get workTime(): Date {
    return this.rawData.workTime;
  }

  get workTimeStr(): string {
    return moment(moment(this.rawData.workTime).toDate()).format('YYYY/MM/DD HH:mm:ss');
  }

  set workTime(date: Date) {
    this.rawData.workTime = date;
  }

  get gpsStampKind():string {
    return this.rawData.gpsStampKind;
  }

  set gpsStampKind(gpsStampKind:string) {
    this.rawData.gpsStampKind = gpsStampKind;
  }

  get address():string {
    return this.rawData.address;
  }

  set address(address:string) {
    this.rawData.address = address;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get latitude():string {
    return this.rawData.latitude;
  }

  set latitude(latitude:string) {
    this.rawData.latitude = latitude;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get longitude(): string {
    return this.rawData.longitude;
  }

  set longitude(longitude: string) {
    this.rawData.longitude = longitude;
  }
}
