import {
  useState, useEffect,
} from 'react';
import { getDrawingInfo } from 'api/schedule';


export const useDrawingInfo = () => {
  const [startHour, setStartHour] = useState<number>(0);
  useEffect(() => {
    getDrawingInfo().then((response) => {
      // Set up shift pattern list
      setStartHour(response.dispStartHour);
    });
  }, []);
  return startHour;
};

export default useDrawingInfo;
