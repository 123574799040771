import React from 'react';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';

const ButtonNavigation: React.FC<{
  downloadCsv: () => void;
  roleScreen?: any
}> = ({ downloadCsv, roleScreen }) => (
  <FlexBox>
    { roleScreen && roleScreen.downloadFlag === 1 && (
      <FlexBoxItem>
        <IconLabelButton
          onClick={downloadCsv}
          iconType="download"
          text="CSVダウンロード"
        />
      </FlexBoxItem>
      )
    }
  </FlexBox>
);

export default ButtonNavigation;
