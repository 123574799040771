import React, { SVGProps } from 'react';

const Analize: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M17 15.875a.625.625 0 110 1.25H3a.625.625 0 110-1.25zm-1.25-12.5a1.875 1.875 0 11-.809 3.567l-2.499 2.5a1.875 1.875 0 01-3.266 1.828l-3.051 1.014a1.875 1.875 0 11-.396-1.186l3.157-1.053a1.875 1.875 0 012.673-1.487l2.499-2.5a1.875 1.875 0 011.692-2.684zm-11.5 8.25a.625.625 0 100 1.25.625.625 0 000-1.25zm6.5-2a.625.625 0 00-.569.885l-.004-.01a.622.622 0 00.13.191l-.024-.025c.02.022.04.042.063.061l-.038-.036c.02.02.04.039.063.056l-.025-.02c.023.02.048.037.073.053l-.048-.033c.026.02.054.038.083.053l-.035-.02a.623.623 0 00.073.04l-.038-.02a.625.625 0 00.906-.411l-.018.061a.62.62 0 00.025-.098l-.008.037a.627.627 0 00-.517-.757l-.092-.007zm5-5a.623.623 0 00-.277.065l.037-.017a.623.623 0 00-.079.04l.042-.023a.625.625 0 00-.08.048l.038-.026a.628.628 0 00-.223.227l.029-.046a.624.624 0 00-.051.088l.022-.042a.623.623 0 00-.035.07l.013-.028a.62.62 0 00-.033.084l.02-.056a.62.62 0 00-.03.093l.01-.037a.621.621 0 00-.02.09v-.001l-.008.096a.625.625 0 00.817.595l-.07.018a.62.62 0 00.094-.026l-.024.008a.62.62 0 00.078-.031l-.054.023a.622.622 0 00.095-.045l-.04.022a.624.624 0 00.086-.051l-.046.03a.628.628 0 00.23-.23l-.032.05a.625.625 0 00.049-.081l-.016.03a.622.622 0 00.038-.08l-.022.05a.621.621 0 00.036-.086l-.014.037a.62.62 0 00.03-.099l-.016.062a.624.624 0 00-.594-.821z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Analize;
