import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';
import { apiGetHeadQuarters } from 'api/monthly/headquarters';
import {downloadMonthlyReportCsv,downloadMonthlyReportExcel} from 'api/monthlyReport';

const useSalesPageForm = () => {
  const functionType = 1;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode'));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);
  const monthSelect =  new Date();
  const targetdayFrom = moment().startOf('month').format('YYYY-MM-DD');

  const [selectedDateFrom, setSelectedDateFrom] = useState<Date>(new Date(targetdayFrom));

  const dateSelectTo =  moment().format('DD') !== '01' ? moment().add(-1, 'days').format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD');
  const [selectedDateTo, setSelectedDateTo] = useState<Date>(new Date(dateSelectTo))

  const [listSalesPageForms, setListSalesPageForms] = useState([]);

  const [orgName, setorgName] = useState(sessionStorage.getItem('loginUser.orgName') ? sessionStorage.getItem('loginUser.orgName') : '');

  const fetchData = useCallback(async () => {
    setLoading(true);
    const path = 'daySeparateHqConfirm';
    try {
      const response = await apiGetHeadQuarters(path, String(orgCode), moment(selectedDateFrom.toString()).format('YYYY/MM/DD'), moment(selectedDateTo.toString()).format('YYYY/MM/DD') , categoryAry);
      setListSalesPageForms(response);
    } catch (error) {
      setListSalesPageForms([]);
    }
    setLoading(false);
  }, [orgCode, selectedDateFrom, selectedDateTo, categoryAry]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    isLoading,
    listSalesPageForms,
    setListSalesPageForms,
    functionType,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo,
    orgName,
    setorgName
  };
};


export const useDownload = () => {
  const downloadCsv = (data: any) => {
   downloadMonthlyReportCsv(data, '【日別】本部確認.csv');
  };

  const downloadExcel = (data: any) => {
    downloadMonthlyReportExcel(data, '【日別】本部確認.xlsx');
  };

  return {
    downloadCsv,
    downloadExcel,
  };

};

export default useSalesPageForm;
