/** @jsx jsx */
import React, { useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { css, jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';

import PrimaryButton, { SecondaryButton } from 'components/atoms/Button';
import DropdownBoxResponsive from 'components/molecules/Dropdown/DropdownBoxResponsive';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FlexBox from 'components/atoms/FlexBox';
import useComponentVisible from 'hooks/useComponentVisible';
import moment from 'moment';

const style = {
  dropdownList: css`
    > div {
      background-color: white;
      padding: 5px 10px;
    }
    button {
      width: 140px;
      margin: 5px 0;
    }
  `,
};

export type DropdownMenuType = {
  label: string;
  isRed: boolean;
  isSelectable: boolean;
  isHidden?: boolean;
  link?: string;
  stamptype: number;
  /**
   * linkがなければ必須
   */
  onClick?: () => void;
};

const DropdownContextMenu: React.FC<{
  text: string;
  modifierApplicationId: any;
  orgCode: string;
  staffCode: string;
  staffName: string;
  modifierApplicationStatus: number | null;
  holidayApplicationStatus: number | null;
  overworkApplicationStatus: number | null;
  holidayworkApplicationStatus: number | null;
  transferApplicationStatus: number | null;
  stampApplicationStatus?: number | null;
  attendanceOrgFix: string;
  useOverTimeApplication: any;
  necessaryHolidayWorkApplication: any;
  useTransferApplication: any;
  achievementId: any;
  targetDate: any;
  stampVersion: any;
  dispStaffName?: string,
  filterTargetDateFrom?: Date,
  filterTargetDateTo?: Date,
  filterOrgCode?: string,
  filterStaffCode?: string,
  belongOrgFix?: string,
  applying?: any,
  checkStampAddButton?: boolean,
  customDropdownMenuButtons?: DropdownMenuType[],
}> = ({
  text,
  modifierApplicationId,
  orgCode,
  staffCode,
  staffName,
  modifierApplicationStatus,
  holidayApplicationStatus,
  overworkApplicationStatus,
  holidayworkApplicationStatus,
  transferApplicationStatus,
  stampApplicationStatus,
  attendanceOrgFix,
  useOverTimeApplication,
  necessaryHolidayWorkApplication,
  useTransferApplication,
  achievementId,
  targetDate,
  stampVersion,
  dispStaffName,
  filterTargetDateFrom,
  filterTargetDateTo,
  filterOrgCode,
  filterStaffCode,
  belongOrgFix,
  applying,
  checkStampAddButton,
  customDropdownMenuButtons,
}) => {
  const isRedButton = modifierApplicationStatus === 0
    || holidayApplicationStatus === 0
    || overworkApplicationStatus === 0
    || holidayworkApplicationStatus === 0
    || applying === 0
    || transferApplicationStatus === 0
    || stampApplicationStatus === 0;

  const stampAmendmentButton: DropdownMenuType = {
    isRed: modifierApplicationStatus === 0 || stampApplicationStatus === 0,
    // isSelectable: !(achievementId === null || (modifierApplicationStatus === 1 || modifierApplicationStatus === 2) || attendanceOrgFix === "1"),
    isSelectable: (achievementId !== null && holidayApplicationStatus === null) || modifierApplicationStatus !== null || attendanceOrgFix === '1',
    label: '打刻修正申請',
    isHidden: false,
    link: '/attendStampModificationApplication',
    stamptype: 1,
  };

  const stampAddButton: DropdownMenuType = {
    isRed: false,
    isSelectable: attendanceOrgFix !== '1',
    label: '打刻追加申請',
    isHidden: false,
    link: '/attendStampModificationApplication',
    stamptype: 0,
  };

  const applyLeaveButton: DropdownMenuType = {
    isRed: holidayApplicationStatus === 0,
    isSelectable: attendanceOrgFix !== '1',
    label: '休暇申請',
    isHidden: false,
    link: '/attendHolidayApplication',
    stamptype: 0,
  };

  const applyOvertimeButton: DropdownMenuType = {
    isRed: overworkApplicationStatus === 0,
    isSelectable: overworkApplicationStatus !== 0 || !(attendanceOrgFix === '1'),
    isHidden: useOverTimeApplication === 0,
    label: '残業申請',
    link: '/attendOvertimeApplication',
    stamptype: 0,
  };

  const applyHolidayWorkButton: DropdownMenuType = {
    isRed: holidayworkApplicationStatus === 0,
    isSelectable: holidayworkApplicationStatus !== 0 || !(attendanceOrgFix === '1'),
    isHidden: necessaryHolidayWorkApplication === false,
    label: '休日出勤申請',
    link: '/attendHolidayworkApplication',
    stamptype: 0,
  };

  const applyTransferButton: DropdownMenuType = {
    isRed: transferApplicationStatus === 0,
    isSelectable: transferApplicationStatus !== 0 || !(attendanceOrgFix === '1'),
    isHidden: useTransferApplication === 0,
    label: '振替申請',
    link: '/attendTransferApplication',
    stamptype: 0,
  };

  const setSessionStorage = (link:string, stamptype:number) => {
    // 自画面用パラメータ
    if (filterTargetDateFrom) {
      sessionStorage.setItem('attendStampList.targetDateFrom', moment(filterTargetDateFrom).format('YYYY-MM-DD'));
      sessionStorage.setItem('attendStampList.targetDateTo', moment(filterTargetDateTo).format('YYYY-MM-DD'));
      sessionStorage.setItem('attendStampList.orgCode', String(filterOrgCode));
      sessionStorage.setItem('attendStampList.staffCode', String(filterStaffCode));
    }

    // Common parameters
    if (orgCode) {
      sessionStorage.setItem('application.orgCode', orgCode);
    } else if (filterOrgCode) {
      sessionStorage.setItem('application.orgCode', filterOrgCode);
    }
    sessionStorage.setItem('application.staffCode', String(staffCode));
    if (dispStaffName) {
      sessionStorage.setItem('application.dispStaffName', String(dispStaffName));
    } else {
      sessionStorage.setItem('application.dispStaffName', `${staffCode} ${staffName}`);
    }
    sessionStorage.setItem('application.targetDate', moment(targetDate).format('YYYY-MM-DD'));
    sessionStorage.setItem('application.returnDestination', window.location.pathname);
    sessionStorage.setItem('attendStampList.returnDestination', window.location.pathname);

    switch (link) {
      case '/attendStampModificationApplication':
        sessionStorage.removeItem('application.achievementId');
        sessionStorage.removeItem('application.stampVersion');
        sessionStorage.removeItem('application.modifierApplicationId');

        // if( modifierApplicationId ){
        if (stamptype === 1) {
          // 修正申請
          sessionStorage.setItem('application.achievementId', achievementId || '');
          sessionStorage.setItem('application.stampVersion', stampVersion || '');
          sessionStorage.setItem('application.modifierApplicationId', modifierApplicationId || '');
        }
        break;

      case '/attendHolidayApplication':
        sessionStorage.setItem('application.achievementId', achievementId);
        break;

      default:
        // do nothing
        break;
    }
  };

  const history = useHistory();
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const bodyNode = document.querySelector('body') as HTMLBodyElement;
  const [parentEle, setParentEle] = useState<HTMLElement|null>(null);

  useEffect(() => {
    setParentEle(ref.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickMenu = useCallback(() => {
    if (belongOrgFix && belongOrgFix === '1') {
      return;
    }

    setIsComponentVisible(!isComponentVisible);
  }, [belongOrgFix, isComponentVisible, setIsComponentVisible]);

  let arrButton = [
    stampAmendmentButton,
    stampAddButton,
    applyLeaveButton,
    applyOvertimeButton,
    applyHolidayWorkButton,
    applyTransferButton,
  ];

  if (checkStampAddButton) {
    arrButton = [
      stampAmendmentButton,
      applyLeaveButton,
      applyOvertimeButton,
      applyHolidayWorkButton,
      applyTransferButton,
    ];
  }

  if(customDropdownMenuButtons) {
    arrButton = arrButton.concat(customDropdownMenuButtons);
  }

  const handleClickLinkButton = (stamptype: number, link?: string) => {
    if(link) {
      setSessionStorage(link, stamptype);
      history.push(link);
    }
  }

  const renderDropdownButton = (item: DropdownMenuType) => {
    return (
      item.isRed ? (
        <SecondaryButton
          text={item.label}
          disabled={!item.isSelectable}
          onClick={
            item.link
            ? () => handleClickLinkButton(item.stamptype, item.link)
            : async () => {
                if (item.onClick) {
                  await item.onClick();
                }
                setIsComponentVisible(false);
              }
          }
        />
      ) : (
        <PrimaryButton
          text={item.label}
          disabled={!item.isSelectable}
          onClick={
            item.link
            ? () => handleClickLinkButton(item.stamptype, item.link)
            : async () => {
                if (item.onClick) {
                  await item.onClick();
                }
                setIsComponentVisible(false);
              }
          }
        />
      )
    )
  }

  return (
    <FlexBox
      customStyle={css({
        justifyContent: 'center',
      })}
    >
      <FlexBoxItem>
        <div ref={ref}>
          {isRedButton ? (
            <SecondaryButton
              onClick={() => handleClickMenu()}
              text="申請中"
            />
          ) : (
            <PrimaryButton
              onClick={() => handleClickMenu()}
              text={text}
            />
          )}

          {ReactDOM.createPortal(
            <div className="dropdown-list" css={style.dropdownList}>
              <DropdownBoxResponsive isVisible={isComponentVisible} parentEle={parentEle} parentRef={ref}>
                {
                  arrButton.filter((item) => !item.isHidden)
                    .map((item, index) => (
                      <div key={index}>
                        {renderDropdownButton(item)}
                      </div>
                    )
                  )
                }
              </DropdownBoxResponsive>
            </div>,
            bodyNode,
          )}
        </div>
      </FlexBoxItem>
    </FlexBox>
  );
};

export default DropdownContextMenu;
