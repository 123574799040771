import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import SignUpInputForm from './SignUpInputForm';

const SignUpForm: React.FC = () => (
  <FormContents>
    <SignUpInputForm />
  </FormContents>
);

export default SignUpForm;
