/** @jsx jsx */
import React, { ReactNode } from 'react';
import { css, jsx } from '@emotion/core';

const styles = {
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  input: css({
    marginTop: '4px',
  }),
  error: css({
    marginTop: '4px',
    color: 'red',
  }),
};

/**
 * フォームのレイアウト
 *  - display: flexでレイアウト
 *  - ラベルとinputの間にマージンをとる
 */
const DateTimeSelectFormLayout: React.FC<{
  label: ReactNode;
  input: ReactNode;
  errorMsg?: string;
}> = ({
  label, input, errorMsg,
}) => (
  <div css={css(styles.wrapper)}>
    {label}
    <div css={styles.input}>
      {input}
    </div>
    <span css={styles.error}>
      {errorMsg}
    </span>
  </div>
);

export default DateTimeSelectFormLayout;
