import axios from 'axios';
import ApiClient from './ApiClient';

export const getItem = async (mail: string): Promise<any> => {
  // TODO 仮リクエスト
  const params = {
    mail
  };

  const response = await ApiClient.get('/v2/loginuser', params);
  return response.data;
};

export const handleHelpUrlForScreen = async (screenTitle: string): Promise<any> => {
  // TODO 仮リクエスト
  const params = {
    screenTitle,
  };

  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/help/url`, {
    params,
    data: {},
  });
  if (response.data && response.data.url) {
    window.open(response.data.url, '_blank');
  }
  return true;
};

export const getMenuItems = async (companyCode: string, staffCode: string): Promise<any> => {
  // TODO 仮リクエスト
  const params = {
    staffCode,
  };

  const response = await ApiClient.get(`/v1/menu/items/available/${companyCode}`, params);
  return response.data;
};

export const getInfomationOrgLocalStorage = async (
  companyCode : string,
  specifyCode: string,
): Promise<Array<any>> => {
  const params = {
    specifyCode,
  };
  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/whereami/${companyCode}`, {
    params,
    data: {},
  });
  return response.data;
};
export default getItem;

export const saveLoginHistory = async (mail: String, loginResult: boolean, failPassword: String, failReason: String)
 : Promise<any> => {
  const prams = {
    mail,
    loginResult,
    failPassword,
    failReason,
  };
  const reponse = await ApiClient.postLoginHistory('/v1/login/history', {}, prams);
  return reponse.data;
};

export const saveAccessHistory = async (companyCode: String, staffCode: String, accessUrl: String)
 : Promise<any> => {
  if (companyCode === '' || staffCode === '') {
    return null;
  }
  const prams = {
    companyCode,
    staffCode,
    accessUrl,
  };
  const reponse = await ApiClient.post('/v1/access/history', {}, prams);
  return reponse.data;
};

export const getRoleByPath = async (path: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode');
  const staffCode = sessionStorage.getItem('loginUser.staffCode');
  const response = await ApiClient.get(`/v1/roles/function/${companyCode}`, { staffCode, path });
  return response.data;
};
