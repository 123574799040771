/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

const style = css({
  padding: '0px 45px 15px 45px',
  backgroundColor: '#FFFFFF',
});

const subStyle = css({
  margin: '5px 10px',
  padding: '5px 10px',
  backgroundColor: '#FFFFFF',
});

/**
 * フォームのコンテンツ
 */
const FormPadding: React.FC<{ 
  isSubForm?: boolean, 
  customStyle?:any,
}> = ({
  children,
  isSubForm = false,
  customStyle,
}) => (
  <div css={[isSubForm ? subStyle : style, customStyle]}>
    {children}
  </div>
);

export default FormPadding;
