import { useState, useEffect } from 'react';

import AdverstingMediaDomain from 'domain/master/sales/adverstingMedia';

import getList from 'api/advertiseMedia';

export const useAdvertise = () => {
  const [advertise, setAdvertise] = useState<Array<AdverstingMediaDomain>>([]);
  useEffect(() => {
    getList().then((response: Array<any>) => {
      setAdvertise(response.map((result) => new AdverstingMediaDomain(result)));
    });
  }, []);
  return { advertise, setAdvertise };
};

export default useAdvertise;
