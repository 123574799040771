/** @jsx jsx */
import React, { useMemo, useState, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import { iconColor } from 'components/styles';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import useToastNotification from 'hooks/useToastNotification';

import Icon from 'components/atoms/Icon';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import PrimaryButton from 'components/atoms/Button';
import DataTable from 'components/organismos/DataTable/DataTable';
import { downloadCSV, deleteAccountTitleMST } from 'api/subjectTitleMST';
import AccountTitleSubMSTItemDomain from 'domain/master/general/accountTitleSubMSTItem';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import SelectForm from 'components/molecules/SelectForm';
import { useSubjectMSTItemList, useAccoutTitleTree } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const SubjectMaster: React.FC<{
}> = () => {
  const {
    accoutOption, accountTitleId,
    setAccountTitleId,
  } = useAccoutTitleTree();

  const { accountTitleSubMSTItemList, setAccountTitleSubMSTItemList } = useSubjectMSTItemList(String(accountTitleId));
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const [selectedAccountTitleMSTItem, setSelectedAccountTitleMSTItem] = useState(
    AccountTitleSubMSTItemDomain.generateInitial(),
  );

  const downloadFileCSV = useCallback(() => {
    downloadCSV('補助科目');
  }, []);

  const openConfirmModal = useCallback((accountTitleMSTItem : AccountTitleSubMSTItemDomain) => {
    setConfirmModalOpen(true);
    setSelectedAccountTitleMSTItem(accountTitleMSTItem);
  }, []);

  const deleteAccountTitleItem = useCallback(() => {
    deleteAccountTitleMST(selectedAccountTitleMSTItem.accountTitleSubId)
      .then(() => {
        setAccountTitleSubMSTItemList(
          accountTitleSubMSTItemList.filter(
            (item) => item.accountTitleSubId
              !== selectedAccountTitleMSTItem.accountTitleSubId,
          ),
        );
        setConfirmModalOpen(false);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.defaultMessage) {
          errorNotification(error.response.data.defaultMessage);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  }, [accountTitleSubMSTItemList, errorNotification, selectedAccountTitleMSTItem.accountTitleSubId,
    setAccountTitleSubMSTItemList, successNotification]);

  const columns: Array<Column<AccountTitleSubMSTItemDomain>> = useMemo(() => [
    {
      Header: '補助科目コード',
      accessor: 'accountTitleSubCode',
      sortType: 'basic'
    },
    {
      Header: '補助科目名',
      accessor: 'accountTitleSubName',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: AccountTitleSubMSTItemDomain },
        data: Array<AccountTitleSubMSTItemDomain>
        }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/accountTitleSubMst/edit/${`${cell.row.original.accountTitleSubId}`}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            {
              roleScreen && roleScreen.downloadFlag === 1 && (
              <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                <span
                  style={{ marginLeft: '20px', marginRight: '10px' }}
                  onMouseEnter={() => setIsHoverDelete(true)}
                  onMouseLeave={() => setIsHoverDelete(false)}
                >
                  <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                </span>
              </Link>
              )
            }
          </div>
        );
      },
    },
  ], [openConfirmModal, roleScreen]); sessionStorage.getItem('accountTitleIdFirst');

  return (
    <div>
      <div style={{ margin: '8px 16px' }}>
        <p>勘定科目の内訳項目を設定します。</p>
        <div style={{ marginBottom: '16px' }}>
          <SelectForm
            label="勘定科目"
            name="accountTitleId"
            value={String(accountTitleId)}
            setValue={(v) => {
              setAccountTitleId(v);
              sessionStorage.setItem('accountTitleId', v);
            }}
            options={accoutOption}
          />
        </div>

        <FlexBox>
          {
            roleScreen && roleScreen.downloadFlag === 1 && (
              <FlexBoxItem>
                <IconLabelButton
                  onClick={downloadFileCSV}
                  iconType="download"
                  text="CSVダウンロード"
                />
              </FlexBoxItem>
            )
          }
          {
            roleScreen && roleScreen.importFlag === 1 && (
              <FlexBoxItem>
                <Link to="/accountTitleSubMst/import">
                  <PrimaryButton
                    ghost={false}
                    text="インポート"
                  />
                </Link>
              </FlexBoxItem>
            )
          }
          {
            roleScreen && roleScreen.editable === 1 && (
              <FlexBoxItem grow={1}>
                <div style={{ textAlign: 'right' }}>
                  <Link to="/accountTitleSubMst/add">
                    <PrimaryButton
                      ghost={false}
                      text="補助科目作成"
                    />
                  </Link>
                </div>
              </FlexBoxItem>
            )
          }
        </FlexBox>
      </div>
      <div
        css={css`
            table th:nth-child(1) {
                width: 100px;
            }
            table th:nth-child(2) {
                width: 200px;
            }
            table th:nth-child(3) {
                width: 80px;
            }
            `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
          <DataTable
            columns={columns}
            data={accountTitleSubMSTItemList}
            isGlobalFilter={true}
            sortBy={[
              { id: 'accountTitleSubCode', desc: false }
            ]}
          />
        </div>
      </div>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={deleteAccountTitleItem}
        actionType={ActionType.DELETE}
      />
    </div>
  );
};

export default SubjectMaster;
