import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getAllAdminOrAttendanceStaff, getHqStaff, getStaffListForOrgCode } from 'api/staff';


/**
 * Get all admin or attendance staffs
 */
export const useStaffsOptions = (): Array<OptionType> => {
  const [staffOptions, setStaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getAllAdminOrAttendanceStaff().then((trees) => {
      setStaffOptions(trees.map((staff) => ({
        value: staff.staffCode,
        label: `${staff.staffCode} ${staff.staffName}`,
      })));
    });
  }, []);
  return staffOptions;
};

export const useHqStaffsOptions = (): Array<OptionType> => {
  const [staffOptions, setStaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getHqStaff().then((trees) => {
      setStaffOptions(trees.map((staff) => ({
        value: staff.staffCode,
        label: `${staff.staffCode} ${staff.staffName}`,
      })));
    });
  }, []);
  return staffOptions;
};

/**
 * Get all staff for 対象店舗
 */
export const useStaffsForOrgCodeOptions = (orgCode: string): Array<OptionType> => {
  const [staffOptions, setStaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getStaffListForOrgCode(orgCode).then((trees) => {
      setStaffOptions(trees.map((staff: any) => ({
        value: staff.staffCode,
        label: `${staff.staffCode} ${staff.staffName}`,
      })));
    });
  }, [orgCode]);
  return staffOptions;
};

export default useStaffsOptions;
