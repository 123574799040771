import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesBudgetYearImportLayout from 'components/organismos/master/storeManagement/salesBudgetYear/SalesBudgetYearImportLayout';

const salesBudgetYearImport = () => (
  <SidebarTemplate pageTitle="予算編成インポート">
    <SalesBudgetYearImportLayout />
  </SidebarTemplate>
);
export default salesBudgetYearImport;
