import { useState, useEffect } from 'react';
import { OptionType } from 'components/atoms/Select';
import { getPostTypeList } from 'api/advertisingMediaOrganization';

/**
 * Get post type options
 */
export const usePostTypeOptions = (): Array<OptionType> => {
  const [postTypeOptions, setPostTypeOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getPostTypeList().then((postTypes) => {
      setPostTypeOptions(postTypes.map((postType) => ({
        value: postType.posNo.toString(),
        label: postType.posName,
      })));
    });
  }, []);
  return postTypeOptions;
};

/**
 * Get post type options by name
 */
export const usePostTypeOptionsByName = (): Array<OptionType> => {
  const [postTypeOptions, setPostTypeOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getPostTypeList().then((postTypes) => {
      setPostTypeOptions(postTypes.map((postType) => ({
        value: postType.posName,
        label: postType.posName,
      })));
    });
  }, []);
  return postTypeOptions;
};

export default usePostTypeOptions;
