/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { textFontSize, textColor, utilityColor } from 'components/styles';
import BodyText from '../BodyText';

const styles = {
  label: css({
    fontSize: textFontSize.re,
  }),
  subText: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: '4px',
  }),
};

const FormLabel: React.FC<{
  label: string;
  subText?: string;
  required?: boolean;
  appendLabel?: string;
  customStyle?: SerializedStyles;
}> = ({
  label, subText, required, appendLabel, customStyle,
}) => (
  <React.Fragment>
    <span css={css(styles.label, customStyle)}>
      {label}
      { appendLabel ? <BodyText color={utilityColor.error} size="sm">{appendLabel}</BodyText> : '' }
      {required && <span css={css(styles.subText, { color: utilityColor.error })}>*必須</span>}
    </span>
    {subText && <span css={styles.subText}>{subText}</span>}
  </React.Fragment>
);

export default FormLabel;
