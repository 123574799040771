/** @jsx jsx */
import React, {
  useState, useEffect, useCallback,
} from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import PrimaryButton from 'components/atoms/Button';
import productColor, {
  utilityColor,
  iconColor,
  grayScale,
  textFontSize,
} from 'components/styles';
import { css, jsx } from '@emotion/core';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { useHistory } from 'react-router-dom';
import useOutputBaseItems from 'hooks/useOutputBaseItems';
import { ReactSortable } from 'react-sortablejs';
import TextInput from 'components/atoms/Form/TextInput';
import Checkbox from 'components/molecules/Checkbox';
import Modal from 'components/molecules/Modal';
import { getOutputBaseItems, reflectData } from 'api/timeOutputLayout';
import useToastNotification from 'hooks/useToastNotification';
import { useTimeOutputLayoutDomainAddForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

// create style form
const styles = {
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  input: css({
    flex: 1,
    width: '100px',
    padding: '7px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: 'none',
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px 0 0 4px',
  }),
  addon: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100px',
    padding: '7px',
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
  }),
  cellTable: css({
    width: '100%',
    border: 'none',
    borderSpacing: '0px 1.5px',
    overflow: 'scroll',
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: utilityColor.error,
    marginLeft: '4px',
    float: 'right',
    marginTop: '-37px',
  }),
  textMessage: css({
    color: 'black',
  }),
};

//
const baseItemHour = 1;
const baseItemDay = 2;
const checkboxOnChangePlus = 1;
const checkboxOnChangeMinus = 2;

const TimeOutputLayoutInputAddForm: React.FC<{ layoutId: string }> = ({
  layoutId,
}) => {
  const {
    formik,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    detailList,
    setDetailList,
    getOutputLayouts,

  } = useTimeOutputLayoutDomainAddForm(layoutId);
  console.log(detailList,'detail')

  const { successNotification, errorNotification } = useToastNotification();

  //
  const outputBaseItemsOptions1 = useOutputBaseItems(baseItemHour);

  //
  const [modalOpen, setModalOpen] = useState(false);

  //
  const [baseItemType, setBaseItemType] = useState(baseItemHour);

  //
  const [outputBaseItems, setOutputBaseItems] = useState(outputBaseItemsOptions1);

  const [checkChangeText, setCheckChangeText] = useState(0);

  const [openModalNotice, setOpenModalNotice] = useState(false);

  const [handleButton, setHandleButton] = useState(0);

  // history
  const history = useHistory();

  //
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  /**
   *  handle on change output for detail list
   */
  const handleChangeOutput = async (outputItemId: any, e: any) => {
    const items = [...detailList];
    items.filter((item: any) => {
      if (item.outputItemId === outputItemId) {
        if (item.output === true) {
          item.output = false;
        } else {
          item.output = true;
        }
        return true;
      }
      return false;
    });
    setDetailList(items);
    setCheckChangeText(1);
  };

  /**
   *  handle on change item name  for detail list
   */
  const handleChangeItemName = async (outputItemId: any, e: any) => {
    const items = [...detailList];
    items.filter((item: any) => {
      if (item.outputItemId === outputItemId) {
        item.outputItemName = e.target.value;
        return true;
      }
      return false;
    });
    setDetailList(items);
    setCheckChangeText(1);
  };

  /**
   *  handle submit
   */
  const handleSubmitAddOutputBaseItems = async (bItemType: any) => {
    let convertData = {};
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginUser = sessionStorage.getItem('loginUser.staffName') || '';

    let checkFlg = false;
    outputBaseItems.forEach((item: any, index: number) => {
      delete (item as any).updateDate;
      delete (item as any).createDate;
      delete (item as any).createUser;
      delete (item as any).updateUser;
      delete (item as any).calculationType;
      delete (item as any).companyCode;
      delete (item as any).id.outputItemId;


      if ((item as any).plus === true) {
        //
        (item as any).plus = 'on';
        checkFlg = true;
      } else {
        delete (item as any).plus;
      }

      if ((item as any).minus === true) {
        //
        (item as any).minus = 'on';
        checkFlg = true;
      } else {
        delete (item as any).minus;
      }

      convertData = {
        ...convertData,
        [`baseItemList[${index}]`]: {
          ...item,
        },

      };
    });

    if (!checkFlg) {
      errorNotification('出力項目を選択してください。');
      return;
    }

    //
    convertData = {
      ...convertData,
      createUser: loginUser,
      updateUser: loginUser,
    };

    setCheckChangeText(0);

    try {
      await reflectData(companyCode, convertData);
      successNotification('登録しました。');
      getOutputLayouts(layoutId);
      setModalOpen(false);
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  /**
   *  handle click button add
   */
  const handleClickButtonAddOutputBaseItems = async (bItemType: any) => {
    //
    getOutputBaseItems(bItemType).then((response) => {
      setOutputBaseItems(response);
    });


    //
    setBaseItemType(bItemType);

    //
    setModalOpen(true);
  };


  /**
   *  handle on change
   */
  const handleChangePlusAndMinus = async (checkboxOnChange: any, baseItemId: any, e: any) => {
    const items = [...outputBaseItems];
    items.filter((item: any) => {
      if (item.id.baseItemId === baseItemId) {
        if (checkboxOnChange === checkboxOnChangePlus) {
          if (item.plus === true) {
            item.plus = false;
          } else {
            item.plus = true;
            item.minus = false;
          }
        } else if (item.minus === true) {
          item.minus = false;
        } else {
          item.minus = true;
          item.plus = false;
        }
        return true;
      }
      return false;
    });
    setOutputBaseItems(items);
  };


  /**
   *  handle back
   */
  const handleBack = async () => {
    history.push('/masterTimeOutputLayout');
  };

  const handleNoticeExit = () => {
    setOpenModalNotice(true);
    setHandleButton(0);
  };

  const closeNoticeExit = () => {
    setOpenModalNotice(false);
  };

  const agreeCloseNoticeExit = () => {
    handleClickButtonAddOutputBaseItems(baseItemDay);
    setOpenModalNotice(false);
  };

  const handleNoticeExitHour = () => {
    setOpenModalNotice(true);
    setHandleButton(1);
  };

  const agreeCloseNoticeExitHour = () => {
    handleClickButtonAddOutputBaseItems(baseItemHour);
    setOpenModalNotice(false);
  };

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <div>
      <p>レイアウトコードとレイアウト名を入力してください。</p>

      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormField>
            <TextForm
              name="layoutCode"
              label="レイアウトコード"
              value={String(formik.values.layoutCode)}
              onChange={formik.handleChange}
              errorMsg={formik.errors.layoutCode}
              required={true}
            />
          </FormField>

          <FormField>
            <TextForm
              name="layoutName"
              label="レイアウト名"
              value={String(formik.values.layoutName)}
              onChange={formik.handleChange}
              errorMsg={formik.errors.layoutName}
              required={true}
            />
          </FormField>

          <FormTitle title="出力内容設定" />

          <p>
            勤怠を出力する際の項目とレイアウトを確認・編集してください。
            <br />
            レイアウトの順番を並べ替える場合はドラッグ&ドロップしてください。
          </p>

          <FormSubmitArea>
            <div style={{ marginRight: '12px' }}>
              <Button
                text="勤務時間項目を追加する"
                onClick={layoutId && checkChangeText === 1 ? () => handleNoticeExitHour() : () => handleClickButtonAddOutputBaseItems(baseItemHour)}
              />
            </div>
            <Button
              text="勤務日数項目を追加する"
              onClick={layoutId && checkChangeText === 1 ? () => handleNoticeExit() : () => handleClickButtonAddOutputBaseItems(baseItemDay)}
            />
          </FormSubmitArea>


          <div>
            <div style={{ width: '100%' }}>
              <FormTitle title="レイアウトの並び順" />
            </div>
            <table css={styles.cellTable} style={{ width: '100%' }}>
              <thead>
                <tr
                  style={{
                    borderTop: '1px solid #CEDAEA',
                    borderBottom: '1px solid #CEDAEA',
                    backgroundColor: '#F2F5F9',
                    height: '40px',
                  }}
                >
                  <th
                    style={{
                      fontWeight: 'inherit',
                      textAlign: 'left',
                      width: '100px',
                    }}
                  >
                    出力
                  </th>
                  <th
                    style={{
                      fontWeight: 'inherit',
                      textAlign: 'left',
                      width: '300px',
                    }}
                  >
                    出力内容
                  </th>
                  <th
                    style={{
                      fontWeight: 'inherit',
                      textAlign: 'left',
                      width: 'calc(100vw - 400px)',
                    }}
                  >
                    出力時項目名
                  </th>
                </tr>
              </thead>
            </table>
            <div style={{ height: 'calc(50vh)', overflow: 'auto' }}>
              <table css={styles.cellTable} style={{ width: '100%' }}>
                <tbody>
                  <ReactSortable
                    list={detailList}
                    setList={setDetailList}
                    onChange={() => {
                      setCheckChangeText(1);
                    }}
                  >
                    {detailList.map((item: any) => (
                      <tr style={{ backgroundColor: '#ffffff' }}>
                        <td style={{ width: '100px' }}>
                          <Checkbox
                            id={`itemOutput${item.outputItemId}`}
                            name={`itemOutput${item.outputItemId}`}
                            value={item.outputItemId}
                            checked={Boolean(item.output)}
                            onChange={(e) => handleChangeOutput(item.outputItemId, e)}
                          />
                        </td>
                        <td style={{ width: '300px' }}>
                          {item.outputItemContent}
                        </td>
                        <td style={{ width: 'calc(100vw - 400px)' }}>
                          <TextInput
                            name={`outputItemName${item.outputItemId}`}
                            value={item.outputItemName}
                            readOnly={!item.output}
                            customStyle={
                              !item.output
                                ? css({ background: iconColor.gray })
                                : css({})
                            }
                            onChange={(e) => handleChangeItemName(item.outputItemId, e)}
                          />
                        </td>
                      </tr>
                    ))}
                  </ReactSortable>
                </tbody>
              </table>
            </div>
          </div>

          <FormSubmitArea>
            {
              roleScreen && roleScreen.editable === 1 && (
                <div style={{ marginRight: '12px' }}>
                  <Button
                    text={layoutId ? '更新' : '新規勤怠確定出力レイアウトを登録'}
                    onClick={formik.handleSubmit}
                  />
                </div>
              )
            }
            <PrimaryButton
              text="戻る"
              onClick={() => handleBack()}
              ghost={true}
            />
          </FormSubmitArea>
        </div>

        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          title="確認メッセージ"
          content={`${layoutId ? '更新' : '登録'}します。よろしいですか？`}
          submitText={layoutId ? '更新' : '登録'}
        />

        {/* Form add Type 1 */}
        <Modal
          open={modalOpen}
          closeHandler={closeModal}
          title={baseItemHour === baseItemType ? '勤務時間項目追加' : '勤務日数項目を追加する'}
          submitText="登録"
          onSubmit={() => handleSubmitAddOutputBaseItems(baseItemType)}
        >
          <div>
            <table css={styles.cellTable} style={{ width: '600px' }}>
              <thead>
                <tr
                  style={{
                    borderTop: '1px solid #CEDAEA',
                    borderBottom: '1px solid #CEDAEA',
                    backgroundColor: '#F2F5F9',
                    height: '40px',
                  }}
                >
                  <th
                    style={{
                      fontWeight: 'inherit',
                      textAlign: 'center',
                      width: '100px',
                    }}
                  >
                    ＋
                  </th>
                  <th
                    style={{
                      fontWeight: 'inherit',
                      textAlign: 'center',
                      width: '100px',
                    }}
                  >
                    －
                  </th>
                  <th
                    style={{
                      fontWeight: 'inherit',
                      textAlign: 'left',
                      width: '400px',
                    }}
                  >
                    出力項目名
                  </th>
                </tr>
              </thead>
            </table>
            <div style={{ maxHeight: '300px', overflow: 'auto' }}>
              <table css={styles.cellTable} style={{ width: '600px' }}>
                <tbody>
                  {outputBaseItems.map((item) => (
                    <tr style={{ backgroundColor: '#ffffff' }}>
                      <td style={{
                        fontWeight: 'inherit',
                        textAlign: 'center',
                        width: '100px',
                      }}
                      >
                        <Checkbox
                          id={`baseItemPlus${item.id.baseItemId}`}
                          name={`baseItem${item.id.baseItemId}`}
                          value={item.id.baseItemId}
                          checked={Boolean(item.plus)}
                          onChange={(e) => handleChangePlusAndMinus(checkboxOnChangePlus, item.id.baseItemId, e)}
                        />
                      </td>
                      <td
                        style={{
                          fontWeight: 'inherit',
                          textAlign: 'center',
                          width: '100px',
                        }}
                      >
                        <Checkbox
                          id={`baseItemMinus${item.id.baseItemId}`}
                          name={`baseItemMinus${item.id.baseItemId}`}
                          value={item.id.baseItemId}
                          checked={Boolean(item.minus)}
                          onChange={(e) => handleChangePlusAndMinus(checkboxOnChangeMinus, item.id.baseItemId, e)}
                        />
                      </td>
                      <td
                        style={{
                          fontWeight: 'inherit',
                          textAlign: 'left',
                          width: '400px',
                        }}
                      >
                        {item.baseItemName}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>

        <Modal
          open={openModalNotice}
          closeHandler={closeNoticeExit}
          title=""
          submitText="OK"
          onSubmit={handleButton === 1 ? agreeCloseNoticeExitHour : agreeCloseNoticeExit}
        >
          <p css={styles.textMessage}>編集中の内容は破棄されます。よろしいですか？</p>
        </Modal>
      </form>
    </div>
  );
};

export default TimeOutputLayoutInputAddForm;
