
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterMediaImportForm from 'components/organismos/master/sales/MasterMediaImportForm/MasterMediaImportForm';

const MediaMasterImportPage = () => (
  <SidebarTemplate pageTitle="支払種別インポート">
    <MasterMediaImportForm />
  </SidebarTemplate>
);

export default MediaMasterImportPage;
