/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import {
  useState, useCallback, useEffect, Dispatch, useMemo,
} from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import useToastNotification from 'hooks/useToastNotification';
import StaffDomain from 'domain/staff';
import { deleteStaffOrganization, postAffiliateInfo } from 'api/staff';
import SelfOrganizationDomain from 'domain/master/general/selfOrganization';
import { ManagerDataStaffDomain } from 'domain/master/general/managerDataStaff';
import moment from 'moment';
import { OrganizationDataStaffDomain } from 'domain/master/general/organizationDataStaff';
import { getErrorMessageForResponse } from 'utility/errorUtil';
import { InfoMessage } from 'utility/messages';
import { SessionStorageKey } from 'utility/constants';

type StaffDomainKey = keyof Pick<StaffDomain, 'orgApplyDate'|'orgCode'>;
const StaffDomainSchema: {[key in StaffDomainKey]: any} = {
  orgCode: Yup.string().required('組織を入力してください。'),
  orgApplyDate: Yup.string().required('所属情報の異動日を入力してください。'),
};
const schema = Yup.object({ ...StaffDomainSchema });

export const useAffiliationForm = (
  initialStaffDomain: StaffDomain = StaffDomain.generateInitial(),
  isEditMode: boolean = false,
  setIsLoading: Dispatch<React.SetStateAction<boolean>>,
  menuType: string,
  isEditingLoginUser: boolean,
) => {
  const history = useHistory();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const [openManagerAddModal, setOpenManagerAddModal] = useState<boolean>(false);
  const [deleteTargetStaffOrganization, setDeleteTargetStaffOrganization] = useState<OrganizationDataStaffDomain|null>(null);

  const onSubmit = async (values: StaffDomain) => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    setConfirmModalOpen(false);
    setIsLoading(true);

    try {
      const response = await postAffiliateInfo(isEditMode ? 1 : 0, menuType, values.getStaffInfoPostData(isEditMode));
      const updatedStaff = new StaffDomain(response.staff);
      formik.setFieldValue('organizationDataList', updatedStaff.organizationDataList);
      formik.setFieldValue('weekShiftPatternList', updatedStaff.weekShiftPatternList);
      formik.setFieldValue('managerList', updatedStaff.managerList);
      formik.setFieldValue('managerDomainList', updatedStaff.managerDomainList);
      sessionStorage.setItem('selectedOrgCodeStaffMaster', updatedStaff.orgCode);
      // ログインユーザー自身の編集中の場合
      if (isEditingLoginUser) {
        // ログインユーザー自身の所属を更新した場合は、ログイン情報の所属組織を更新する
        if (updatedStaff.orgCode !== sessionStorage.getItem(SessionStorageKey.LoginUser.ORG_CODE)) {
          sessionStorage.setItem(SessionStorageKey.LoginUser.ORG_CODE, updatedStaff.orgCode);
          sessionStorage.setItem(SessionStorageKey.LoginUser.ORG_NAME, updatedStaff.orgName);
        }
      }
      setIsLoading(false);
      successNotification(isEditMode ? '更新しました。' : '登録しました。');

      if (!isEditMode && response) {
        history.goBack();
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.map((item: { defaultMessage: string; }) => errorNotification(item.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };
  const formik = useFormik({
    initialValues: initialStaffDomain,
    onSubmit,
    validateOnChange: false,
    validate: async (values) => {
      const errors: {[key: string]: string} = {};
      try {
        await schema.validate(values, { abortEarly: false });
      } catch (error) {
        const validationError = error as Yup.ValidationError;
        validationError.inner.forEach((innerError) => {
          errors[innerError.path] = innerError.message;
        });
      }
      return errors;
    },
  });

  useEffect(() => {
    formik.setValues(initialStaffDomain);
  }, [initialStaffDomain]);

  // const getSelectedOrganizationInfo = useCallback(async () => {
  //   await getOrganizationInfo(formik.values.orgCode, moment(formik.values.hireDate).format('YYYY-MM-DD')).then((response: any) => {
  //     if (response.employmentId) {
  //       formik.setFieldValue('employmentId', response.employmentId);
  //     } else {
  //       formik.setFieldValue('employmentId', '');
  //     }
  //   });
  // }, [formik]);


  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const isOpenDeleteModal = useMemo(() => !!deleteTargetStaffOrganization, [deleteTargetStaffOrganization]);
  const closeDeleteModal = useCallback(() => setDeleteTargetStaffOrganization(null), []);

  const onSubmitDeleteStaffOrganization = useCallback(async () => {
    if (!deleteTargetStaffOrganization) return;
    setIsLoading(true);
    try {
      const result = await deleteStaffOrganization(initialStaffDomain.staffCode, deleteTargetStaffOrganization.applyStartDate);
      initialStaffDomain.organizationDataList = result.organizationDataList;
      formik.setFieldValue('organizationDataList', result.organizationDataList);
      // ログインユーザー自身の所属を削除した場合は、ログイン情報の所属組織を更新する
      if (initialStaffDomain.staffCode === sessionStorage.getItem(SessionStorageKey.LoginUser.STAFF_CODE)
        && result.orgCode !== sessionStorage.getItem(SessionStorageKey.LoginUser.ORG_CODE)) {
        sessionStorage.setItem(SessionStorageKey.LoginUser.ORG_CODE, result.orgCode);
        sessionStorage.setItem(SessionStorageKey.LoginUser.ORG_NAME, result.orgName);
      }
      successNotification(InfoMessage.Common.DELETE_COMPLETED);
    } catch(error)  {
        errorNotification(getErrorMessageForResponse(error));
    } finally {
      setDeleteTargetStaffOrganization(null);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik, initialStaffDomain, successNotification, errorNotification]);

  const addManager = useCallback((orgList: Array<SelfOrganizationDomain>) => {
    if (!formik.values.managerDomainList) {
      return;
    }
    const managerDataList : Array<ManagerDataStaffDomain> = orgList.map((org) => new ManagerDataStaffDomain({
      managerId: '',
      staffCode: formik.values.staffCode,
      orgCode: org.orgCode,
      orgName: org.orgName,
      managerType: 0,
      startDateStr: moment(formik.values.orgApplyDate).format('YYYY年MM月DD日'),
      endDateStr: org.endDateAsDate ? moment(org.endDateAsDate).format('YYYY年MM月DD日') : null,
    }));
    formik.setFieldValue('managerDomainList', formik.values.managerDomainList.concat(managerDataList));
  }, []);

  const deleteOrganizationManager = useCallback((index: number) => () => {
    if (!formik.values.managerDomainList) {
      return;
    }
    formik.setFieldValue('managerDomainList', formik.values.managerDomainList.filter((manager, idx) => idx !== index));
  }, []);

  const setOrganizationManager = useCallback((index: number) => (newManager: ManagerDataStaffDomain) => {
    if (!formik.values.managerDomainList) {
      return;
    }
    formik.setFieldValue('managerDomainList', formik.values.managerDomainList.map((manager, idx) => (idx === index ? newManager : manager)));
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    openManagerAddModal,
    setOpenManagerAddModal,
    addManager,
    deleteOrganizationManager,
    setOrganizationManager,
    // getSelectedOrganizationInfo,
    onSubmitDeleteStaffOrganization,
    setDeleteTargetStaffOrganization,
    isOpenDeleteModal,
    closeDeleteModal,
  };
};

export default {
  useAffiliationForm,
};
