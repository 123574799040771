/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
// import { Container, Row, Col } from 'react-grid-system';

import FormLabel from 'components/atoms/Form/FormLabel';
import TextInput from 'components/atoms/Form/TextInput';
import FormDescription from 'components/atoms/Form/FormDescription';
import { utilityColor } from 'components/styles';
import FlexBox from 'components/atoms/FlexBox';

const styles = {
  error: css({
    marginTop: '4px',
    color: utilityColor.error,
  }),
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
    minWidth: '100px',
  }),
};

/**
 * ラベルが横にあるテキストフォーム
 */
const SideLabelTextForm: React.FC<{
  name: string;
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  description?: string;
  errorMsg?: string;
}> = ({
  name, label, value, onChange, description, errorMsg,
}) => (
  <div css={styles.container}>
    <FlexBox>
      <div css={styles.label}>
        <FormLabel
          label={label}
        />
      </div>
      <div css={styles.select}>
        <TextInput
          name={name}
          label={label}
          value={value}
          onChange={onChange}
        />
      </div>
    </FlexBox>
    <FlexBox>
      <div css={styles.label} />
      <div css={styles.select}>
        {description
            && <FormDescription description={description} />}
      </div>
    </FlexBox>
  </div>
  // <Container>
  //   <Row align="center">
  //     <Col xs={12} sm={2} md={1} width="100px">
  //       <FormLabel
  //         label={label}
  //       />
  //     </Col>
  //     <Col xs={12} sm={10} md={11}>
  //       <TextInput
  //         name={name}
  //         label={label}
  //         value={value}
  //         onChange={onChange}
  //       />
  //     </Col>
  //   </Row>
  //   <Row>
  //     <Col xs={12} sm={2} md={1} />
  //     {description && (
  //       <Col xs={12} sm={10} md={11}>
  //         <FormDescription description={description} />
  //       </Col>
  //     )}
  //   </Row>
  //   <Row>
  //     <Col xs={12} sm={2} md={1} />
  //     <Col xs={12} sm={10} md={11}>
  //       <span css={styles.error}>
  //         {errorMsg}
  //       </span>
  //     </Col>
  //   </Row>
  // </Container>
);

export default SideLabelTextForm;
