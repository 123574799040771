/* eslint-disable camelcase */
import moment from 'moment';
import ApiClient from './ApiClient';

export type Budget = {
  net_sales: number;
  menu_cost_total: number;
  land_rent: number;
  guest_cnt: number;
  sales_budget_total: number;
  sales_budget_cost_total: number;
  sales_budget_part_labor_cost: number;
  sales_budget_other_variable_costs: number;
  sales_budget_employee_labor_cost: number;
  sales_budget_land_rent: number;
  sales_budget_other_fixed_cost: number;
  other_expenses_total: number;
  sales_budget_guest_cnt: number;
  per_customer_price: number;
  purchase_food_cost: number;
  purchase_drink_cost: number;
  purchase_other_cost: number;
  petty_cash_food: number;
  petty_cash_drink: number;
  petty_cash_expendables: number;
  petty_cash_other: number;
}

export type SalesSetting = {
  calculationItemOfFRate: number;
  includePettyCashInPurchase: boolean;
}

export type LoborCost = {
  employee_labor_cost: number;
  part_labor_cost: number;
}

export type Advertising = {
  advertising_media_expense: number;
  budget_advertising_media_expense: number;
}

export type GeneralReportMonth = {
  budget: Budget;
  labor_cost: any;
  advertising: Array<Advertising>;
}

export type GeneralReport = {
  days: any;
  month: GeneralReportMonth;
  setting: SalesSetting;
}


export type DetailApplicationData = {
  applicant: string | null;
  application_time: Date | null;
  changer: string | null;
  update_time: Date | null;
  authorizer: string | null;
  approval_time: Date | null;
}

export const getSalesPlanMonthly = async (
  orgCode: string,
  targetMonth: Date,
  categoryAry: Array<any>,

): Promise<GeneralReport> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetMonth: moment(new Date(targetMonth.getFullYear(), targetMonth.getMonth(), 1)).format('YYYY-MM-DD'),
    functionType: 3,
    loginStaffCode: sessionStorage.getItem('loginUser.staffCode') || '',
  };
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const response = await ApiClient.get(`/v4/report/general/${companyCode}?${appendUrl}`, params);
  return response.data;
};

export const getSalesPlanDaily = async (orgCode: string, targetDay: Date): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    targetDay: moment(targetDay).format('YYYY-MM-DD'),
    loginStaffCode
  };
  const response = await ApiClient.get(`/v7/report/daily_detail/${companyCode}`, params);
  return response.data;
};

export const updateCommentForSale = async (
  orgCode: string,
  targetDay: Date,
  budgetProgressComment: string,
  storeOperationComment: string,
  otherComment: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const updateUser = sessionStorage.getItem('loginUser.staffName');
  const params = {
    orgCode,
    updateUser,
    targetDay: moment(targetDay).format('YYYY-MM-DD'),
    budgetProgressComment,
    storeOperationComment,
    otherComment,
  };
  const response = await ApiClient.post(`/v2/report/daily_detail/update_comment/${companyCode}`, {}, params);
  return response.data;
};

//
export const updateImageDetailReport = async (dataPost: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.postMutipartData(`/v2/report/daily_detail/update_image/${companyCode}`, {}, dataPost);
  return response.data;
};

//
export const deleteImageDetailReport = async (dataPost: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.postMutipartData(`/v1/report/daily_detail/delete_image/${companyCode}`, {}, dataPost);
  return response.data;
};

export const updateCashierReportManual = async (orgCode: string, targetDay: Date, data: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDay: moment(targetDay).format('YYYY-MM-DD'),
    loginStaffCode: sessionStorage.getItem('loginUser.staffCode') || '',
  };
  const response = await ApiClient.post(`/v5/report/daily/cashierReport/manual/${companyCode}`, params, data);
  return response.data;
};

export const exportFilePDF = async (
  orgCode: any,
  targetDateExport: any,
): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const targetDate = moment(targetDateExport).format('YYYY-MM-DD');
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    targetDate,
    loginStaffCode,
  };

  const response = await ApiClient.getDownloadFile(`/v1/detailReport/exportFile/pdf/${companyCode}?`, params);
  return response.data;
};

export default {
  getSalesPlanMonthly,
};
