/**
 * API 祝日マスタ
 */

export interface Business {
  businessId: string;
  businessCode: string;
  businessName: string;
  businessBgColor:string;
  recess:boolean;
  createUser: string,
  updateUser: string,
}


export default class BusinessDomain {
  constructor(private rawData: Business) {
    // do nothing
  }

  static generateInitial(): BusinessDomain {
    return new BusinessDomain({
      businessId: '',
      businessCode: '',
      businessName: '',
      businessBgColor: '#ffffff',
      recess: false,
      createUser: '',
      updateUser: '',
    });
  }

  getRawData(): Business {
    return this.rawData;
  }

  get businessId(): string {
    return this.rawData.businessId;
  }

  set businessId(businessId: string) {
    this.rawData.businessId = businessId;
  }

  get businessCode():string {
    return this.rawData.businessCode;
  }

  set businessCode(businessCode:string) {
    this.rawData.businessCode = businessCode;
  }

  get businessName(): string {
    return this.rawData.businessName;
  }

  set businessName(businessName: string) {
    this.rawData.businessName = businessName;
  }

  get businessBgColor():string {
    return this.rawData.businessBgColor;
  }

  set businessBgColor(businessBgColor:string) {
    this.rawData.businessBgColor = businessBgColor;
  }

  get recess(): boolean {
    return this.rawData.recess;
  }

  set recess(recess: boolean) {
    this.rawData.recess = recess;
  }


  get createUser():string {
    return this.rawData.createUser;
  }

  set createUser(createUser:string) {
    this.rawData.createUser = createUser;
  }


  get updateUser():string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser:string) {
    this.rawData.updateUser = updateUser;
  }
}
