/** @jsx jsx */
import {
  Route, RouteProps, Redirect, useLocation, useHistory,
} from 'react-router-dom';
import { jsx } from '@emotion/core';
import RevisionDomain from 'domain/revision';
import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import {
  getItem, getMenuItems, saveAccessHistory, getRoleByPath,
} from 'api/login';
import LoginUserDomain from 'domain/loginUser';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SidebarItemType } from 'components/organismos/Sidebar';
import { useDispatch } from 'react-redux';
import { uiSidebarActions } from 'modules/ui/sidebar';
import { isMobileOnly } from 'react-device-detect';
import { getRevision } from './api/revision';
import { authActions } from './modules/auth';
import useLoginUser from 'hooks/useLoginUser';

declare let gRevision: string;
const REVISION_KEY: string = 'EC_APP_REVISION';

const initialRole = {
  "companyCode":"",
  "roleCode":"",
  "menuId":"",
  "available":true,
  "editable":0,
  "importFlag":0,
  "downloadFlag":0,
  "usabilityGeneralItem1":0,
  "usabilityGeneralItem2":0,
  "usabilityGeneralItem3":0,
  "usabilityGeneralItem4":0,
  "usabilityGeneralItem5":0,
  "usabilityGeneralItem6":0,
  "usabilityGeneralItem7":0,
  "usabilityGeneralItem8":0,
  "usabilityGeneralItem9":0,
  "usabilityGeneralItem10":0,
  "useStaffAccess":false,
  "staffAccessFunctionRoles":[]
};

/**
  * リビジョン管理用 ローカルストレージ保存
  *
  * @param obj セッション格納オブジェクト
  */
function setLocalStorageRevision(obj: any) {
  localStorage.setItem(REVISION_KEY, JSON.stringify({ ...JSON.parse(localStorage.getItem(REVISION_KEY) || '{}'), ...obj }));
}

/**
 * リビジョン管理用 ローカルストレージ取得
 *
 * @param key
 * @returns key セッションキー
 */
function getLocalStorageRevision(key: string) {
  if (!JSON.parse(localStorage.getItem(REVISION_KEY) || '{}') || !JSON.parse(localStorage.getItem(REVISION_KEY) || '{}')[key]) {
    return false;
  }
  return JSON.parse(localStorage.getItem(REVISION_KEY) || '{}')[key];
}


// /**
//  * ログイン中のユーザ情報を取得
//  */
// async function getUserInfo() {
//   const userInfo = await Auth.currentAuthenticatedUser();
//   console.log(`userInfo = ${userInfo}`);
//   return userInfo;
// }

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const rest = _.omit(props, ['component']);
  let loginPath: string = '/login';
  let moveApproval: boolean = false;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { storeLoginUserToSessionStorage } = useLoginUser();
  const getPath = useCallback((array: Array<any>) => {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i].href === '/') {
        return array[i];
      }
      if (array[i].hierarchyItems && array[i].hierarchyItems !== null) {
        return array[i].hierarchyItems[0];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest]);
  const fetchRole = useCallback(async (restPath) => {
    try {
      await getRoleByPath(String(restPath)).then((role: any) => {
        if (role) {
          if (role[0].available !== undefined && role[0].available === false) {
            history.push('/404');
          }
        }
        if (role.length > 0) {
          dispatch(authActions.setRoleScreen(role[0]));
        }
      }).catch((error: any) => {
        if (error.response?.status === 503) {
          // ログイン制限モード（503）の場合はメンテナンス画面を表示
          history.push('/maintenance');
        } else {
          history.push('/404');
        }
      });
    } catch (error) {
      history.push('/404');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest]);

  const fetchRoleOperationalReportQuick = useCallback(async () => {
    await getRoleByPath(String('/shogun/operationalReportQuick')).then((role: any) => {
      if (role) {
        if (role[0].available !== undefined && role[0].available === true) {
          sessionStorage.setItem('role.operationalReportQuick', 'true');
        } else {
          sessionStorage.removeItem('role.operationalReportQuick');
        }
      } else {
        sessionStorage.removeItem('role.operationalReportQuick');
      }
    }).catch(() => {
      sessionStorage.removeItem('role.operationalReportQuick');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest]);

  const fetchRolePeriodSales = useCallback(async () => {
    try {
      const role = await getRoleByPath(String('/shogun/periodSales'));
      if (role[0]?.available === true) {
        sessionStorage.setItem('role.periodSales', 'true');
      } else {
        sessionStorage.removeItem('role.periodSales');
      }
    } catch (error) {
      sessionStorage.removeItem('role.periodSales');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest]);

  const fetchRoleShogunStampHistory = useCallback(async () => {
    try {
      const role = await getRoleByPath(String('/shogun/stampHistory'));
      if (role[0]?.available === true) {
        sessionStorage.setItem('role.shogunStampHistory', 'true');
      } else {
        sessionStorage.removeItem('role.shogunStampHistory');
      }
    } catch (error) {
      sessionStorage.removeItem('role.shogunStampHistory');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest]);

  const fetchRoleOperationalReportList = useCallback(async () => {
    await getRoleByPath(String('/shogun/operationalReportList')).then((role: any) => {
      if (role) {
        if (role[0].available !== undefined && role[0].available === true) {
          sessionStorage.setItem('role.operationalReportList', 'true');
        } else {
          sessionStorage.removeItem('role.operationalReportList');
        }
      } else {
        sessionStorage.removeItem('role.operationalReportList');
      }
    }).catch(() => {
      sessionStorage.removeItem('role.operationalReportList');
    });
  }, []);

  // Save values on screen during operation and assign default values when returning to the screen
  if (['/agreementMaster', '/holidayMaster/create', '/holidayMaster/import', '/holidayMaster/edit/:publicHolidayId'].indexOf(String(rest.path)) !== -1) {
    if (!sessionStorage.getItem('selectedOrgCodeHolidayMaster')) {
      sessionStorage.setItem('selectedOrgCodeHolidayMaster', 'all');
    }
    if (!sessionStorage.getItem('selectedYearHolidayMaster')) {
      sessionStorage.setItem('selectedYearHolidayMaster', new Date().getFullYear().toString());
    }
    sessionStorage.removeItem('roleOrg.holidayMaster');
  } else {
    sessionStorage.removeItem('selectedOrgCodeHolidayMaster');
    sessionStorage.removeItem('selectedYearHolidayMaster');
  }

  if (['/masterShiftPattern', '/masterShiftPattern/add', '/masterShiftPattern/:typeButton/:shiftPatternId'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('shiftPatternOrgCode');
    sessionStorage.removeItem('roleOrg.masterShiftPattern');
  }

  //
  if (['/organizations', '/organizations/add', '/organizations/csv', '/organizations/:orgCode/specialsalary', '/organizations/:orgCode/managers', '/organizations/:orgCode'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('organizations.noManager');
    sessionStorage.removeItem('organizations.unsetTargetDate');
    sessionStorage.removeItem('organizations.orgStatus');
  }

  if (['/organizationCategory', '/organizationCategory/add', '/organizationCategory/edit/:organizationCategoryMstDetailId', '/organizationCategory/import'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('currentOrgCatTab');
    sessionStorage.removeItem('selectedOrganizationCategory');
    sessionStorage.removeItem('organizationCategoryMstHeadId');
  }

  if (['/masterEmploymentForm', '/masterEmploymentForm/add', '/masterEmploymentForm/edit/:isEdit/:employmentId/:applyStartDate'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('masterEmploymentForm.applyDate');
    sessionStorage.removeItem('masterEmploymentForm.applyDate');
  }

  if (['/attendResult', '/attendStampList', '/attendStampModificationApplication', '/attendHolidayApplication', '/attendOvertimeApplication', '/attendHolidayworkApplication', '/attendTransferApplication'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('attendResult.selectOrgCode');
    sessionStorage.removeItem('attendResult.searchTargetDateFrom');
    sessionStorage.removeItem('attendResult.searchTargetDateTo');
    sessionStorage.removeItem('headquartersFinal.monthlyList.returnDestination');
  }

  if (['/attendError', '/attendStampModificationApplication', '/attendHolidayApplication', '/attendOvertimeApplication', '/attendHolidayworkApplication', '/attendTransferApplication'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('attendError.selectCategoryOption');
    sessionStorage.removeItem('attendError.selectCategory');
  }

  if (['/attendError', '/attendStampModificationApplication', '/attendHolidayApplication', '/attendOvertimeApplication',
    '/attendHolidayworkApplication', '/attendTransferApplication'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('attendError.targetDateFrom');
    sessionStorage.removeItem('attendError.targetDateTo');
    sessionStorage.removeItem('attendError.isFilter');
    sessionStorage.removeItem('attendError.orgCode');
    sessionStorage.removeItem('attendErrorIcon.targetDateFrom');
    sessionStorage.removeItem('attendErrorIcon.targetDateTo');
  }
  if (['/attendMonthlyList', '/attendStampList', '/attendStampModificationApplication', '/attendHolidayApplication', '/attendOvertimeApplication',
    '/attendHolidayworkApplication', '/attendTransferApplication'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('headquartersFinal.monthlyList.selClosingDate');
  }

  if (['/attendError'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('clearToggle');
  }


  if (['/salesBudgetYear', '/salesBudgetYearImport', '/salesBudgetMonth'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('orgCode_DestinationOrg');
  }
  if (['/salesDailyReport'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('loginDailyCode');
  }

  if (['accountTitleMst', 'accountTitleSubMst', 'journalMaster', 'descriptionMst'].indexOf(String(rest.path).split('/')[1]) === -1) {
    sessionStorage.removeItem('currentTabAccount');
    sessionStorage.removeItem('accountTitleId');
    sessionStorage.removeItem('checkJournalData');
  }

  if (['pettyCash'].indexOf(String(rest.path).split('/')[1]) === -1) {
    sessionStorage.removeItem('updatePettyCashHead');
    sessionStorage.removeItem('dateSelectPettyCase');
  }

  // if (['/attendError'].indexOf(String(rest.path)) === -1) {
  //   sessionStorage.removeItem('attendErrorIcon.targetDateFrom');
  //   sessionStorage.removeItem('attendErrorIcon.targetDateTo');
  // }

  if (['/attendHeadquartersFinal', '/attendMonthlyList', '/attendStampList', '/attendStampModificationApplication', '/attendStampModificationApplication', '/attendHolidayApplication', '/attendOvertimeApplication', '/attendHolidayworkApplication', '/attendTransferApplication'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('headquartersFinal.searchTargetDateFrom');
    sessionStorage.removeItem('headquartersFinal.searchTargetDateTo');
    sessionStorage.removeItem('headquartersFinal.searchTargetMonth');
    sessionStorage.removeItem('headquartersFinal.searchEmploymentId');
    sessionStorage.removeItem('headquartersFinal.searchOutputLayout');
    sessionStorage.removeItem('headquartersFinal.viewPeriod');
    sessionStorage.removeItem('headquartersFinal.selClosingDate');
  }
  // clear ssesion khi vào attendHeadquartersFinal
  // -- session còn lại ở dataTable khi chuyển đến trang không có Controllbar
  const tableId = 1;
  if (['/attendHeadquartersFinal', '/attendMonthlyList', '/attendStampList', '/attendStampModificationApplication', '/attendStampModificationApplication', '/attendHolidayApplication', '/attendOvertimeApplication', '/attendHolidayworkApplication', '/attendTransferApplication'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem(`sessionDataTable${tableId}/attendHeadquartersFinal.initPageIndex`);
  }
  // end clear

  if (['/salesBudgetYear', '/salesBudgetYearImport', '/salesBudgetMonth'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('orgCode_DestinationOrg');
  }
  if (['salesDailyReport'].indexOf(String(rest.path).split('/')[1]) === -1) {
    sessionStorage.removeItem('loginDailyCode');
  }

  if (['accountTitleMst', 'accountTitleSubMst', 'journalMaster', 'descriptionMst'].indexOf(String(rest.path).split('/')[1]) === -1) {
    sessionStorage.removeItem('currentTabAccount');
    sessionStorage.removeItem('accountTitleId');
    sessionStorage.removeItem('checkJournalData');
  }

  if (['/attendError', '/attendStampModificationApplication', '/attendHolidayApplication', '/attendOvertimeApplication', '/attendHolidayworkApplication', '/attendTransferApplication'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('attendError.selectCategoryOption');
    sessionStorage.removeItem('attendError.selectCategory');
  }

  if (['/masterShopSpecify'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('masterShopSpecify');
    sessionStorage.removeItem('masterShopSpecifyOrg');
    sessionStorage.removeItem('sessionOrgSelect/masterShopSpecify');
  }

  const countTabAttendHoliday = ['1', '2', '3', '4', '5', '6', '7', '8'];
  const accorditionTab = [
    'paid.management',
    'paid.grandhistory',
    'paid.acquisition',
    'specialHoliday.management',
    'specialHoliday.grandhistory',
    'specialHoliday.acquisition',
  ];

  if (['/attendHolidayManagement'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('management.employmentId');
    sessionStorage.removeItem('management.extinctionDaysStart');
    sessionStorage.removeItem('management.extinctionDaysEnd');
    sessionStorage.removeItem('management.targetGrantTenDays');
    sessionStorage.removeItem('grantHistory.targetDateFrom');
    sessionStorage.removeItem('grantHistory.paidGrantResult');
    sessionStorage.removeItem('acquisitionStatus.searchDate');
    sessionStorage.removeItem('holiday.searchDate');
    sessionStorage.removeItem('restHoliday.searchDate');
    sessionStorage.removeItem('specialHoliday.managementInformation.employmentId');
    sessionStorage.removeItem('specialHoliday.managementInformation.specialManageHolidaySelect');
    sessionStorage.removeItem('specialHoliday.grandHistory.targetDateFrom');
    sessionStorage.removeItem('specialHoliday.grandHistory.targetDateTo');
    sessionStorage.removeItem('specialHoliday.acquisitionStatus.searchDate');
    sessionStorage.removeItem('toggle');
    // eslint-disable-next-line array-callback-return
    countTabAttendHoliday.map((index) => {
      sessionStorage.removeItem(`sessionOrganizationFileterTree/attendHolidayManagement${index}.Toggle`);
      sessionStorage.removeItem(`sessionOrganizationFileterTree/attendHolidayManagement${index}.Selected`);
      sessionStorage.removeItem(`sessionOrgSelect/attendHolidayManagement${index}`);
    });
    // eslint-disable-next-line array-callback-return
    accorditionTab.map((index) => {
      sessionStorage.removeItem(`attendHolidayManagement.accordion.${index}`);
    });
  }

  if (['salesDailyReport'].indexOf(String(rest.path).split('/')[1]) === -1) {
    sessionStorage.removeItem('SalesDailyReport.targetDay');
    sessionStorage.removeItem('SalesDailyReport.targetOrgCode');
    sessionStorage.removeItem('SalesDailyReport.targetOrgName');
    sessionStorage.removeItem('SalesDailyReport.tabCashRegister');
  }

  // if (['attendStampList'].indexOf(String(rest.path).split('/')[1]) === -1) {
  //   sessionStorage.removeItem('headquartersFinal.monthlyList.returnDestination');
  // }

  if (['pettyCash'].indexOf(String(rest.path).split('/')[1]) === -1) {
    sessionStorage.removeItem('updatePettyCashHead');
    sessionStorage.removeItem('dateSelectPettyCase');
  }

  if (['dailyShift', 'monthlyShift'].indexOf(String(rest.path).split('/')[1]) === -1) {
    sessionStorage.removeItem('staff.closingHour');
  }

  if (['/shogun/operationalReportList', '/shogun/stampHistory/:orgCode?/:targetDate?', '/shogun/operationalReportQuick/:orgCode?/:targetDate?'].indexOf(String(rest.path)) === -1) {
    sessionStorage.removeItem('targetDateReportQuick');
    sessionStorage.removeItem('orgCodeReportQuick');
  }

  console.log(`location.pathname = ${String(rest.path)}`);
  if (['/approvalModificationApplication/:applicationId'].indexOf(String(rest.path)) !== -1
    || ['/approvalHolidayApplication/:applicationId'].indexOf(String(rest.path)) !== -1
    || ['/approvalOvertimeApplication/:applicationId'].indexOf(String(rest.path)) !== -1
    || ['/approvalHolidayworkApplication/:applicationId'].indexOf(String(rest.path)) !== -1
    || ['/approvalTransferApplication/:applicationId'].indexOf(String(rest.path)) !== -1) {
    moveApproval = true;
    console.log(`location.pathname = ${location.pathname}`);
    loginPath = `/login${location.pathname}`;
  }


  saveAccessHistory(sessionStorage.getItem('loginUser.companyCode') || '', sessionStorage.getItem('loginUser.staffCode') || '', location.pathname);

  // リビジョンチェック
  getRevision()
    .then((response: any) => {
      const revision = new RevisionDomain(response);

      // 現在のリビジョンと取得したリビジョンが異なる場合、リロード
      if (gRevision !== revision.revision) {
        // 無限リロード対策（更新中フラグで管理する）
        const revisionChangingFl = getLocalStorageRevision('revision_changing_fl');
        setLocalStorageRevision({ revision_changing_fl: true });
        if (!revisionChangingFl) {
          // リビジョン更新
          window.location.reload(true);
        }
      } else {
        // リビジョンが一致
        // LocalStorageのリビジョン更新フラグをfalse
        setLocalStorageRevision({ revision_changing_fl: false });
      }
    });

  // SessionStorageに[loginUser.mail]が存在しない場合
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [userInfo, setUserInfo] = useState(null);
  const [getLoginUser, setGetLoginUser] = useState(false);
  // const user = getUserInfo();

  const logoutAction = () => {
    dispatch(uiSidebarActions.setHierarchy(undefined));
    sessionStorage.clear();
    localStorage.removeItem('logged');
    localStorage.removeItem('topPageSP');
    localStorage.removeItem('topPagePC');
    Auth.signOut();
    history.push('/login');
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then((user: any) => {
        if (user) {
          const { attributes } = user;
          const loginUserAuto = sessionStorage.getItem('loginUser');
          console.log('loginUserAuto', loginUserAuto);

          console.log(`attributes.email = ${attributes.email}`);
          getItem(attributes.email)
            .then((response: any) => {
              const loginUser = new LoginUserDomain(response);
              console.log(loginUser);
              console.log(loginUser.companyCode);
              // Save staff permission to session
              console.log(`loginUser.mail = ${loginUser.mail}`);
              localStorage.setItem('logged', 'true');
              storeLoginUserToSessionStorage(loginUser);

              if (loginUser.retired) {
                console.error('退職済みのためログインできません');
                logoutAction();
                return;
              } else {
                setGetLoginUser(true);
              }

              // get list menu
              getMenuItems(loginUser.companyCode, loginUser.staffCode)
                .then((menuItems: any) => {
                  const tmpMenu: Array<SidebarItemType> = [];
                  if (menuItems.length) {
                    menuItems.forEach((menuParent: any) => {
                      if (menuParent.isSeparate && menuParent.isSeparate === true) {
                        tmpMenu.push({ separate: true });
                        return;
                      }

                      // eslint-disable-next-line no-param-reassign
                      menuParent.icon = menuParent.iconType;
                      if (!menuParent.hierarchyItems) {
                        // eslint-disable-next-line no-param-reassign
                        delete menuParent.hierarchyItems;
                      }
                      if (menuParent.href !== null && menuParent.href === '/') {
                        sessionStorage.setItem('mobileDashboard', '1');
                      }
                      if (menuParent.href === null && menuParent.iconType === 'workSchedule' && menuParent.hierarchyItems && menuParent.hierarchyItems.length > 0) {
                        menuParent.hierarchyItems.map((menuChild: any) => {
                          if (menuChild.to === '/schedule') {
                            sessionStorage.setItem('mobileSchedule', '1');
                          }
                          if (menuChild.to === '/workAchievement') {
                            sessionStorage.setItem('mobileWorkAchievement', '1');
                          }
                        });
                      }
                      tmpMenu.push(menuParent);
                    });
                  }

                  const pathFound = getPath(tmpMenu);
                  if (pathFound.href && pathFound.href !== null) {
                    if (isMobileOnly) {
                      localStorage.setItem('topPageSP', '/dashboard');
                      // sessionStorage.setItem('mobileDashboard', '1');
                    } else {
                      localStorage.setItem('topPagePC', '/dashboard');
                    }
                  } else if (isMobileOnly) {
                    if (sessionStorage.getItem('mobileWorkAchievement') && sessionStorage.getItem('mobileWorkAchievement') === '1') {
                      localStorage.setItem('topPageSP', '/workAchievement');
                    } else if (sessionStorage.getItem('mobileSchedule') && sessionStorage.getItem('mobileSchedule') === '1') {
                      localStorage.setItem('topPageSP', '/schedule');
                    }
                    // sessionStorage.setItem('mobileDashboard', '0');
                  } else {
                    localStorage.setItem('topPagePC', pathFound.to);
                  }
                  if (isMobileOnly && sessionStorage.getItem('role.operationalReportQuick') === 'true') {
                    localStorage.setItem('topPageSP', '/shogun/operationalReportQuick');
                  }
                  sessionStorage.setItem('menuItems', JSON.stringify(tmpMenu));
                  // setUserInfo(user);
                  setIsAuthenticated(true);
                }).catch((error: any) => {
                  setIsAuthenticated(true);
                });
            })
            .catch((error: any) => {
              setIsAuthenticated(true);
              logoutAction();
            });
        } else {
          setIsAuthenticated(true);
          logoutAction();
        }
        // console.log(`user = ${user}`);
      })
      .catch((error: any) => {
        setIsAuthenticated(true);
        logoutAction();
      });
    // console.log(`isAuthenticated1 = ${isAuthenticated}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    // 権限初期化
    dispatch(authActions.setRoleScreen(initialRole));
    let restPath = '';
    const formatPath = `/${window.location.pathname.split('/')[1]}`;
    const formatMultiPath = `/${window.location.pathname.split('/')[1]}/${window.location.pathname.split('/')[2]}`;
    if (
      window.location.pathname.split('/')[2] !== undefined && (
        formatMultiPath === '/staffs/employee'
        || formatMultiPath === '/salesPaymentMedia/paymentMaster'
        || formatMultiPath === '/salesPaymentMedia/paymentSetting'
        || formatMultiPath === '/salesAdvertisingMedia/advertisingMaster'
        || formatMultiPath === '/salesAdvertisingMedia/advertisingSetting'
        || formatMultiPath === '/monthlyShift/attendStampModificationApplication'
        || formatMultiPath === '/monthlyShift/attendHolidayApplication'
        || formatMultiPath === '/monthlyShift/attendOvertimeApplication'
        || formatMultiPath === '/monthlyShift/attendHolidayworkApplication'
        || formatMultiPath === '/monthlyShift/attendTransferApplication'
        || formatMultiPath === '/dailyShift/attendStampModificationApplication'
        || formatMultiPath === '/dailyShift/attendHolidayApplication'
        || formatMultiPath === '/dailyShift/attendOvertimeApplication'
        || formatMultiPath === '/dailyShift/attendHolidayworkApplication'
        || formatMultiPath === '/dailyShift/attendTransferApplication'
        || formatMultiPath === '/shogun/timeListDataOutput'
        || formatMultiPath === '/shogun/operationalReportQuick'
        || formatMultiPath === '/shogun/daySeparateReport'
        || formatMultiPath === '/shogun/salesDailyReport'
        || formatMultiPath === '/shogun/operationalReportList'
        || formatMultiPath === '/shogun/periodSales'
        || formatMultiPath === '/shogun/stampHistory'
        || formatMultiPath === '/staffs/staffRegistrationStatus'
      )
    ) {
      if (formatMultiPath === '/salesAdvertisingMedia/advertisingSetting') {
        restPath = '/salesAdvertisingMedia/advertisingMaster';
      } else if (formatMultiPath === '/salesPaymentMedia/paymentSetting') {
        restPath = '/salesPaymentMedia/paymentMaster';
      } else {
        restPath = formatMultiPath;
      }
    } else if (formatPath === '/dashboard') {
      restPath = '/';
    } else if (formatPath === '/salesBudgetMonth' || formatPath === '/salesBudgetYearImport') {
      restPath = '/salesBudgetYear';
    } else if (formatPath === '/monthlyShift') {
      restPath = '/dailyShift';
    } else if (
      formatPath === '/holidayMaster'
      || formatPath === '/masterSpecialSalaryPattern'
      || formatPath === '/vacationMaster'
      || formatPath === '/masterPaidHolidayPattern'
      || formatPath === '/businessMaster'
      || formatPath === '/masterTerm'
      || formatPath === '/masterWorkingDays'
      || formatPath === '/masterWorkingHours'
    ) {
      restPath = '/agreementMaster';
    } else if (
      formatPath === '/accountTitleSubMst'
      || formatPath === '/journalMaster'
      || formatPath === '/descriptionMst'
      || formatPath === '/accountTitleMST'
    ) {
      restPath = '/accountTitleMst';
    } else if (formatPath === '/attendHolidayManagementImport') {
      restPath = '/attendHolidayManagement';
    } else if (formatPath === '/attendMonthlyList') {
      restPath = '/attendHeadquartersFinal';
    } else if (formatPath === '/salesAdvertisingPlan') {
      restPath = '/salesAdvertisingPlan/advertisingMaster';
    } else {
      restPath = formatPath;
    }
    console.log(formatPath, 'formatPath');
    if (sessionStorage.getItem('loginUser.companyCode') && formatPath !== '/' && formatPath !== '') {
      // 権限取得
      fetchRole(restPath);
      if (isMobileOnly) {
        fetchRoleOperationalReportQuick();
        fetchRoleOperationalReportList();
        fetchRolePeriodSales();
        fetchRoleShogunStampHistory();
      }
    }
    if (formatPath === '/' || formatPath === '') {
      if (isMobileOnly) {
        history.push(localStorage.getItem('topPageSP') || '/workAchievement');
      } else {
        history.push(localStorage.getItem('topPagePC') || '/dashboard');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.path, sessionStorage.getItem('loginUser.companyCode')]);

  // const state = useContext(LoginContext);
  if (!isAuthenticated) return null;
  return (
    <React.Fragment>
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={(innerProps) => (getLoginUser ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Route {...props} />
        ) : (
          <Redirect
            to={{
              pathname: !moveApproval ? '/login' : loginPath,
              state: { from: innerProps.location },
            }}
          />
        ))}
      />
    </React.Fragment>
  );
};

export default PrivateRoute;
