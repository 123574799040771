/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FlexBox from 'components/atoms/FlexBox';
import moment from 'moment';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import DatePicker from 'components/molecules/DatePicker';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import useToastNotification from 'hooks/useToastNotification';
import BlockUI from 'components/molecules/BlockUi';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import useYearlyReport, { useGetListMonthlyReport } from './hooks';
import MonthlySalesManagementTable from './MonthlySalesManagementTable';
import YearlySalesManagementTable from './YearlySalesManagementTable_new';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import { getOrgNameFromOrgOptionLabel } from 'utility/formatUtil';

const YearlySalesManagementLayout: React.FC<{
  fiscalYear:any,
}> = ({
  fiscalYear,
}) => {
  const fiscalStartMonth = moment(fiscalYear.fiscalStartMonth, 'YYYY-MM-DD').toString();
  const fiscalEndMonth = moment(fiscalYear.fiscalEndMonth, 'YYYY-MM-DD').toString();
  const datefrom = new Date(fiscalStartMonth);
  const dateto = new Date(fiscalEndMonth);
  const [targetDateFrom, setTargetDateFrom] = useState(datefrom);
  const [targetDateTo, setTargetDateTo] = useState(dateto);
  const [orgTreesOptions, setOrgTreesOptions] = useState([]);

  //
  const functionType = 3;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
    undefined,
  );

  const st = new Date();
  st.setMonth(0);
  st.setDate(1);
  const ed = new Date(st);
  if (!targetDateFrom) {
    setTargetDateFrom(st);
  }

  if (!targetDateTo) {
    setTargetDateTo(ed);
  }

  const [isShowContentAction, setIsShowContentAction] = useState(false);

  const [orgCode, setOrgCode] = useState('all');
  const [orgName, setOrgName] = useState('');
  const { yearlySalesManagementReport, getIsLoading, setIsLoading } = useYearlyReport(
    String(orgCode),
    moment(targetDateFrom).format('YYYY-MM-DD'), moment(targetDateTo).format('YYYY-MM-DD'),
    categoryAry,
  );
  const targetPeriodText = `対象期間：${moment(targetDateFrom).format('YYYY/MM')}~${moment(targetDateTo).format('YYYY/MM')}`;
  const {
    action,
    setAction,
    ActionType,
  } = useGetListMonthlyReport();
  const { errorNotification } = useToastNotification();

  // eslint-disable-next-line consistent-return
  const getFetchOption = () => {
    if (!orgCode || orgTreesOptions.length === 0) {
      errorNotification('組織名を選択してください。');
      return false;
    }
    setAction(ActionType.getFetchOption);
    setIsShowContentAction(true);
  };

  const targetPeriodList: Array<string> = useMemo(() => {
    const tmpTargetPeriodList = ['期間合計'];
    let startDate = new Date(targetDateFrom);
    const endDate = new Date(targetDateTo);
    for (;
      startDate.getTime() <= endDate.getTime();
      startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1, 0, 0, 0)
    ) {
      tmpTargetPeriodList.push(moment(startDate, 'YYYY/MM/DD').format('YYYY年MM月'));
    }
    return tmpTargetPeriodList;
  }, [targetDateFrom, targetDateTo])

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  useEffect(() => {
    if (orgCode) {
      orgTreesOptions.map((data: any) => {
        if (data.value === orgCode) {
          return setOrgName(getOrgNameFromOrgOptionLabel(data.label));
        }
        return true;
      });
    }
  }, [orgCode, orgTreesOptions]);

  return (
    <div>
      <FormContents>
        <FlexBox>
          <FlexBoxItem width="100px">
            <FormLabel label="対象期間" />
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePicker
              dateFormat="yyyy年MM月"
              date={targetDateFrom}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDateFrom(date);
                }
              }}
              isFullWidth={true}
              showMonthYearPicker={true}
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <span style={{ margin: '0 30px' }}>～</span>
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePicker
              dateFormat="yyyy年MM月"
              date={targetDateTo}
              changeDate={(date: Date) => {
                if (date !== null) {
                  const newEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                  newEndDate.setHours(23, 59, 59, 59);
                  setTargetDateTo(newEndDate);
                }
              }}
              isFullWidth={true}
              showMonthYearPicker={true}
            />
          </FlexBoxItem>
        </FlexBox>

        <OrganizationFilteredTree
          functionType={functionType}
          orgCallback={(args: string | Array<string>) => {
            setOrgCode(String(args));
          }}
          orgCategoryCallback={
              (args: Array<string>) => {
                setCategoryAr(args);
              }
            }
          initOrgValue={String(orgCode)}
          staffCode={loginStaffCode}
          orgLabel="組織名"
          addAllItem={true}
          targetdayForm={targetDateFrom}
          targetdayTo={targetDateTo}
          orgOrgOptionListCallback={setOrgTreesOptions}
        />

        <FormField>
          <FormSubmitArea>
            <div style={{ marginRight: '12px', marginTop: '-15px' }}>
              <PrimaryButton
                text="売上管理表 "
                onClick={getFetchOption}
                ghost={!(isShowContentAction && action === ActionType.getFetchOption)}
              />
            </div>
          </FormSubmitArea>
        </FormField>

        <div>
          { isShowContentAction && action === ActionType.getFetchOption
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <YearlySalesManagementTable
                   setIsLoading={setIsLoading}
                   orgName={orgName}
                   yearlySalesManagementReport={yearlySalesManagementReport}
                   targetPeriodText={targetPeriodText}
                   targetPeriodList={targetPeriodList}
                   yearlyStartDate={targetDateFrom}
                   yearlyEndDate={targetDateTo}
                   roleScreen={roleScreen}
                 />
               </BlockUI>
             </div>
           </div>
           )}
        </div>

      </FormContents>
    </div>
  );
};

export default YearlySalesManagementLayout;
