import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import ForgotPasswordInputForm from './ForgotPasswordInputForm';

const ForgotPasswordForm: React.FC = () => (
  <FormContents>
    <ForgotPasswordInputForm />
  </FormContents>
);

export default ForgotPasswordForm;
