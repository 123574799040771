import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import SpecialSalaryPatternInputForm from 'components/organismos/master/labor/SpecialSalaryPatternInputForm';
import { useParams } from 'react-router-dom';
import FormContents from 'components/atoms/Form/FormContents';
import TabControl from 'components/atoms/TabControl';

type SelectTab = 'add';
const SelectTab: {[key in SelectTab]: SelectTab} = {
  add: 'add',
};

const SpecialSalaryPatternAddPage: React.FC = () => {
  const {
    specialSalaryPatternId,
  } = useParams();
  const [selectTab, setSelectTab] = React.useState<SelectTab>(SelectTab.add);

  return (
    <SidebarTemplate pageTitle={specialSalaryPatternId ? '特賃日編集' : '特賃日作成'}>
      <FormContents>
        <TabControl
          items={[
            {
              value: SelectTab.add,
              display: '特賃日情報',
            },
          ]}
          setValue={setSelectTab as (arg: string) => void}
          currentValue={selectTab}
          isFixed={true}
        />
        <SpecialSalaryPatternInputForm />
      </FormContents>
    </SidebarTemplate>
  );
};

export default SpecialSalaryPatternAddPage;
