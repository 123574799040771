import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AuthorityMasterLayout from 'components/organismos/master/authority/AuthorityMaster/index';

const authorityMasterPage = () => (
  <SidebarTemplate pageTitle="権限マスタ">
    <AuthorityMasterLayout />
  </SidebarTemplate>
);

export default authorityMasterPage;