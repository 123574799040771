/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { css, jsx } from '@emotion/core';
import { stateSymbol } from 'components/organismos/master/general/salesPage//SalesManagementList/hookSysboy';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { useDownload } from 'components/organismos/master/general/salesPage/SalesManagementList/hook';
import { Link } from 'react-router-dom';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';
import { grayScale } from 'components/styles';

const styles = css({
  fontWeight: 400,
  fontSize: 14,
});

const DEFAULT_SORT_FIELD = { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number' } as FieldSortState

const DeficienciesListTable: React.FC<{
    listSalesManagement3: any;
    isActiveRow: number;
    isActiveColumn: number;
    handleClickActive: any;
    selectedDateFrom: any;
    selectedDateTo: any;
    orgName: any;
    roleScreen?: any;
  }> = ({
    listSalesManagement3,
    isActiveRow,
    isActiveColumn,
    handleClickActive,
    selectedDateFrom,
    selectedDateTo,
    orgName,
    roleScreen
  }) => {
    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(listSalesManagement3, DEFAULT_SORT_FIELD);

    const searchResult: any[] = [];
    const totalInformation: any[] = [];
    const { downloadCsv3, downloadExcel3 } = useDownload();

    const headerInformation = [
      ['状態', '店舗名', '並び順', '日付','指摘概要','指摘内容','指摘日'],
    ];

    const data = {
      footer: '',
      formName: '営業管理【不備一覧】',
      targetPeriod: `対象期間：${moment(selectedDateFrom).format('YYYY-MM-DD')} ~ ${moment(selectedDateTo).format('YYYY-MM-DD')}`,
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: totalInformation,
    };

    let mergedRegions: any[] = [];
    const codeColumnIndex: any[] = [];
    const dataExcel = {
      formName: '営業管理【不備一覧】',
      targetPeriod: `対象期間: ${moment(selectedDateFrom).format('YYYY-MM-DD')} ~ ${moment(selectedDateTo).format('YYYY-MM-DD')}`,
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: totalInformation,
      mergedRegions,
      codeColumnIndex,
    };

    const handleExportCSV = () => downloadCsv3(data);

    const handleExportExcel = () => {
      if (mergedRegions) {
        mergedRegions = [];
      }
      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [0];
      dataExcel.headerInformation = headerInformation;
      return downloadExcel3(dataExcel);
    };

    const renderHeaderColumn = useCallback((displayName: string, columnSortField: FieldSortState, rowSpan: number, className: string = "") => (
      <th rowSpan={rowSpan} className={className} onClick={e => sortByFieldPath(columnSortField.fieldPath, columnSortField.sortType)}>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <span style={{ margin: 'auto' }}>{displayName}</span>
          {sortField.fieldPath === columnSortField.fieldPath && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
          {sortField.fieldPath !== columnSortField.fieldPath && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
        </div>
      </th>
     ), [sortField]);

    return (
      <div>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportCSV}
                iconType="download"
                text=" CSV出力"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportExcel}
                iconType="download"
                text=" EXCEL出力"
              />
            </FlexBoxItem>
          </FlexBox>
        )}
        <div
        className="table-responsive"
        style={{
          display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', marginTop: 20, maxHeight: 600,
        }}
      >
        <table id="salesPageDeficiencies" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }}>
          <thead>
            <tr>
              { renderHeaderColumn('状態', { fieldPath: 'pointedOutStatus', sort: 'asc', sortType: 'string'}, 1) }
              { renderHeaderColumn('店舗名', { fieldPath: 'orgName', sort: 'asc', sortType: 'string'}, 1) }
              { renderHeaderColumn('並び順', { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number'}, 1) }
              { renderHeaderColumn('日付', { fieldPath: 'targetDay', sort: 'asc', sortType: 'date'}, 1) }
              { renderHeaderColumn('指摘概要', { fieldPath: 'overview', sort: 'asc', sortType: 'string'}, 1) }
              { renderHeaderColumn('指摘内容', { fieldPath: 'contents', sort: 'asc', sortType: 'string'}, 1) }
              { renderHeaderColumn('指摘日', { fieldPath: 'createDate', sort: 'asc', sortType: 'date'}, 1) }
            </tr>
          </thead>
            {
                  listSalesManagement3 && listSalesManagement3.length > 0 ? dataSort.map((deficiencies: any, index: number) => {
                    {searchResult.push([
                      deficiencies.pointedOutStatus || '',
                      deficiencies.orgName || '',
                      deficiencies.dispOrder || 0,
                      deficiencies.targetDay ? moment(deficiencies.targetDay).format('YYYY-MM-DD') : '',
                      deficiencies.overview || '',
                      deficiencies.contents || '',
                      deficiencies.targetDay ? moment(deficiencies.createDate).format('YYYY-MM-DD') : '',
                      ])
                    }
                    return (
                    <tbody key={index} css={styles}>
                      <tr className={isActiveRow === index ? 'activeClickFrames' : ''}>
                        <td
                          className={`textLeft ${isActiveColumn === 1 ? 'activeClickFrames' : ''}`}
                          onClick={() => { handleClickActive(index, 1); }}
                        >
                          {
                                deficiencies.status !== 99
                                ? <Link target={"_blank"} to={{ pathname: `/salesDailyReport/${deficiencies.orgCode}/${moment(deficiencies.targetDay).format('YYYY-MM-DD')}` }}>
                                  {deficiencies.pointedOutStatus}
                                  </Link>
                                : deficiencies.pointedOutStatus
                          }
                        </td>
                        <td
                          className={`textLeft ${isActiveColumn === 2 ? 'activeClickFrames' : ''}`}
                          onClick={() => { handleClickActive(index, 2); }}
                        >
                          {deficiencies.orgName}
                        </td>
                        <td
                          className={`textLeft ${isActiveColumn === 3 ? 'activeClickFrames' : ''}`}
                          onClick={() => { handleClickActive(index, 3); }}
                        >
                          {deficiencies.dispOrder}
                        </td>
                        <td
                          className={`textLeft ${isActiveColumn === 4 ? 'activeClickFrames' : ''}`}
                          onClick={() => { handleClickActive(index, 4); }}
                        >
                          {moment(deficiencies.targetDay).format('YYYY-MM-DD')}
                        </td>
                        <td
                          className={`textLeft ${isActiveColumn === 5 ? 'activeClickFrames' : ''}`}
                          onClick={() => { handleClickActive(index, 5); }}
                        >
                          {deficiencies.overview}
                        </td>
                        <td
                          className={isActiveColumn === 6 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 6); }}
                        >
                          {deficiencies.contents}
                        </td>
                        <td
                          className={isActiveColumn === 7 ? 'activeClickFrames' : ''}
                          onClick={() => { handleClickActive(index, 7); }}
                        >
                          {moment(deficiencies.createDate).format('YYYY-MM-DD')}
                        </td>
                      </tr>
                    </tbody>
                  )
                }) : <tbody css={styles}></tbody>
              }
        </table>
      </div>

      </div>

    );
  }
export default DeficienciesListTable;
