/** @jsx jsx */
import React, { useEffect } from 'react';
import YearPicker from 'components/molecules/YearPicker/YearPicker';

import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import PrimaryButton from 'components/atoms/Button';
import HourInput from 'components/atoms/Form/HourInput';
import Icon from 'components/atoms/Icon';
import productColor, { textColor, grayScale, textFontSize } from 'components/styles';
import { css, jsx } from '@emotion/core';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { getWorkingHoursPreviousYear } from 'api/workingHours';
import { useHistory } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';
import { useWorkingHoursDomainAddForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

// create style form
const styles = {
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  input: css({
    flex: 1,
    width: '100px',
    padding: '7px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: 'none',
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px 0 0 4px',
  }),
  addon: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100px',
    padding: '7px',
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
  }),
  cellTable: css({
    width: '100%',
    border: 'none',
    borderSpacing: '0px 1.5px',
    overflow: 'scroll',
  }),
};


const WorkingHoursInputAddForm: React.FC<{workingHoursId: string}> = ({
  workingHoursId,
}) => {
  const {
    formik,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal, arrMonth,
    valueWorkingHours, setValueWorkingHours,
    valueWorkingMinuteForms, setValueWorkingMinuteForms,
    handleGetWorkingHours,
  } = useWorkingHoursDomainAddForm(workingHoursId);

  const { errorNotification } = useToastNotification();

  // history
  const history = useHistory();

  //
  let workingHoursDetails = [
    {
      month: 0,
      workingHoursForm: 0,
      workingMinuteForm: 0,
    },
  ];

  workingHoursDetails = [];

  arrMonth.map((month) => {
    const tmpMonthDetail = {
      month,
      workingHoursForm: 0,
      workingMinuteForm: 0,
    };
    workingHoursDetails.push(tmpMonthDetail);
    //
    return true;
  });

  //
  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *  handle on change input working hours && working minute on form
   */
  const handleChange = async (e: any) => {
    const checkName = e.target.name.split('_');
    if (checkName[0] === 'workingHoursForm') {
      const items = [...valueWorkingHours];
      (items as any)[checkName[1]] = e.target.value;
      setValueWorkingHours(items);
    } else {
      const items = [...valueWorkingMinuteForms];
      (items as any)[checkName[1]] = e.target.value;
      setValueWorkingMinuteForms(items);
    }
  };

  /**
   *  handle back
   */
  const handleBack = async () => {
    sessionStorage.setItem('currentTab', '8');
    history.push('/agreementMaster');
  };


  /**
   *
   *
  */
  const handleChangeYear = async (year: any) => {
    // set year
    formik.setFieldValue('targetYear', year);

    // update working detail
    await handleGetWorkingHours(year, workingHoursId);
  };


  /**
   *  handle on click button 対象年の前年の所定労働時間を設定
   */
  const handleSetWorkingHours = async (e: any) => {
    //
    const rawData = formik.values.getRawData();
    const { targetYear, workingHoursId } = rawData;
    const workingHoursDetailId = ((rawData as any).workingHoursDetails && (rawData as any).workingHoursDetails.workingHoursDetailId) ? (rawData as any).workingHoursDetails.workingHoursDetailId : '';
    getWorkingHoursPreviousYear(targetYear, workingHoursId, workingHoursDetailId).then((response: any) => {
      if (response) {
        const { workingHoursDetails } = response;
        if (workingHoursDetails) {
          const itemsHour = [...valueWorkingHours];
          const itemsMinute = [...valueWorkingMinuteForms];
          Object.keys(workingHoursDetails).forEach((key_obj) => {
            const value = workingHoursDetails[key_obj];
            if (key_obj.includes('workingHoursForm')) {
              const split_key_obj = key_obj.split('workingHoursForm');
              (itemsHour as any)[parseInt(split_key_obj[1]) - 1] = value;
            } else if (key_obj.includes('workingMinuteForm')) {
              const split_key_obj = key_obj.split('workingMinuteForm');
              (itemsMinute as any)[parseInt(split_key_obj[1]) - 1] = value;
            }
          });
          setValueWorkingHours(itemsHour);
          setValueWorkingMinuteForms(itemsMinute);
        }
      }
    }).catch((error : any) => {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any, index: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    });
  };

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <div>
      <p>
        所定労働時間を登録します。コードとパターン名を入力し、対象となる年を選択してから、各月毎の所定労働時間を設定してください。
      </p>

      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormField>
            <TextForm
              name="workingHoursPatternCode"
              label="パターンコード"
              value={String(formik.values.workingHoursPatternCode)}
              onChange={formik.handleChange}
              errorMsg={formik.errors.workingHoursPatternCode}
              required={true}
            />
          </FormField>

          <FormField>
            <TextForm
              name="workingHoursPatternName"
              label="パターン名"
              value={String(formik.values.workingHoursPatternName)}
              onChange={formik.handleChange}
              errorMsg={formik.errors.workingHoursPatternName}
              required={true}
            />
          </FormField>

          <FormTitle
            title="各月所定労働時間設定"
          />

          <p>
            対象となる年を選択して、各月ごとの所定労働時間を設定してください。
          </p>

          <FormField>
            <div style={{ paddingRight: '10px' }}>
              対象年
            </div>

            <div style={{ paddingRight: '10px', width: '100px', marginTop: '-5px' }}>
              <YearPicker selectedYear={formik.values.targetYear} onChange={(year: Date) => handleChangeYear(year)} />
            </div>
            { workingHoursId
              ? (
                <div onClick={handleSetWorkingHours} style={{ color: productColor.primary, cursor: 'grab' }}>
                  <Icon type="settings" color={textColor.main} />
                  対象年の前年の所定労働時間を設定
                </div>
              )
              : ''}
          </FormField>

          <div>
            <table css={styles.cellTable} style={{ width: '100%' }}>
              <thead>
                <tr style={{
                  borderTop: '1px solid #CEDAEA', borderBottom: '1px solid #CEDAEA', backgroundColor: '#F2F5F9', height: '40px',
                }}
                >
                  <th style={{ fontWeight: 'inherit', textAlign: 'left' }}>月</th>
                  <th style={{ fontWeight: 'inherit', textAlign: 'left' }}>所定労働時間</th>
                </tr>
              </thead>
              <tbody>
                {workingHoursDetails.map((item, k_detail) => (
                  <tr style={{ backgroundColor: '#ffffff' }}>
                    <td>
                      {item.month}
                      月
                    </td>
                    <td>
                      <div style={{ width: '310px' }}>
                        <div style={{ float: 'left', width: '140px' }}>
                          <HourInput
                            name={`workingHoursForm_${k_detail}`}
                            value={valueWorkingHours[k_detail] ? Number(valueWorkingHours[k_detail]) : 0}
                            label="時間"
                            onChange={handleChange}
                            isFullW={true}
                            min={0}
                            max={999}
                          />
                        </div>
                        <div style={{ paddingLeft: '10px', float: 'left', width: '120px' }}>
                          <HourInput
                            name={`workingMinuteForm_${k_detail}`}
                            value={valueWorkingMinuteForms[k_detail] ? Number(valueWorkingMinuteForms[k_detail]) : 0}
                            label="分"
                            onChange={handleChange}
                            min={0}
                            max={59}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <FormSubmitArea>
            {
              roleScreen && roleScreen.editable === 1 && (
                <div style={{ marginRight: '12px' }}>
                  <Button
                    text="所定労働時間を登録"
                    onClick={formik.handleSubmit}
                  />
                </div>
              )
            }
            <PrimaryButton
              text="戻る"
              onClick={() => handleBack()}
              ghost={true}
            />
          </FormSubmitArea>

        </div>

        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          title="確認メッセージ"
          content={`${workingHoursId ? '更新' : '登録'}します。よろしいですか？`}
          submitText={workingHoursId ? '更新' : '登録'}
        />

      </form>
    </div>
  );
};

export default WorkingHoursInputAddForm;
