/* eslint-disable max-len */
import {
  useState, useCallback, useEffect,
} from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { createOrUpdateDiscountType, getDiscountMaster } from 'api/disCountType';
import DiscountTypeDomain from 'domain/master/sales/discoutType';
import useToastNotification from 'hooks/useToastNotification';

  type DiscountDomainKey = keyof Pick<DiscountTypeDomain, 'discountTypeMstCode'|'discountTypeMstName'|'dispOrder'>;

export const useDiscountTypeAddForm = () => {
  const { discountTypeMstCode } = useParams();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmModalCustomOpen, setConfirmModalCustomOpen] = useState(false);
  const [contentModalCustom, setContentModalCustom] = useState('');
  const { successNotification, errorNotification } = useToastNotification();

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const history = useHistory();

  const onSubmit = async (val: any) => {
    if (val.discountTypeMstCode === undefined || val.discountTypeMstCode === null) {
      errorNotification('コードを入力してください。');
      return;
    }
    if (val.discountTypeMstName === undefined || val.discountTypeMstName === null) {
      errorNotification('名称を入力してください。');
      return;
    }
    if (val.dispOrder === undefined || val.dispOrder === null) {
      errorNotification('並び順を入力してください。');
      return;
    }
    if (!confirmModalOpen && !confirmModalCustomOpen) {
      setConfirmModalOpen(true);
      return;
    }

    const submitObj = {
      id: {
        companyCode,
        discountTypeMstCode: val.discountTypeMstCode,
      },
      discountTypeMstName: val.discountTypeMstName,
      dispOrder: val.dispOrder,
      createUser: staffName,
      updateUser: staffName,
    };

    createOrUpdateDiscountType(!!discountTypeMstCode, !!isConfirm, submitObj)
      .then((response: any) => {
        successNotification('登録しました。');
        history.push('/discountTypeMst/discountType');
      })
      .catch((error: any) => {
        setIsConfirm(false);
        setConfirmModalOpen(false);
        setConfirmModalCustomOpen(false);
        if (error.response.status === 406) {
          let strCode = '';
          const salesDiscountMst = error.response.data;
          for (let i = 0; i < salesDiscountMst.length; i++) {
            const code = salesDiscountMst[i];
            strCode += `${code.id.discountTypeMstCode}, `;
          }
          strCode = strCode.slice(0, strCode.length - 2);
          setContentModalCustom(`既に同じ値引・割引種別名が登録されていますが、よろしいですか？（値引・割引種別コード：${strCode})`);
          setConfirmModalCustomOpen(true);
          setIsConfirm(true);
        } else if (error.response && error.response.data && error.response.data.errors.count !== 0) {
          errorNotification(error.response.data.errors[0].defaultMessage);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  };

  const formik = useFormik({
    initialValues: DiscountTypeDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    if (discountTypeMstCode) {
      getDiscountMaster(discountTypeMstCode).then((response: Array<DiscountTypeDomain>) => {
        response.filter((item) => {
          const parseItem = new DiscountTypeDomain(item);
          if (parseItem.discountTypeMstCode === discountTypeMstCode) {
            formik.setValues(parseItem);
            return true;
          }
          return false;
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountTypeMstCode]);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmModalCustom = useCallback(() => {
    setIsConfirm(false);
    setConfirmModalCustomOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    history,
    confirmModalCustomOpen,
    closeConfirmModalCustom,
    contentModalCustom,
  };
};


export default useDiscountTypeAddForm;
