/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { textFontSize, grayScale } from 'components/styles';
import VerticalSeparator from 'components/atoms/VerticalSeparator';
import ClickableLink from 'components/atoms/ClickableLink';
import Dropdown from 'components/molecules/Dropdown';

const style = css({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
  fontSize: textFontSize.re,
});

export interface PaginationProps {
  total?: number;
  pageSize: number;
  pageIndex: number;
  pageCount: number;
  nextPage: () => void;
  canNextPage: boolean;
  previousPage: () => void;
  canPreviousPage: boolean;
  setPageSize: (pageSize:number) => void;
  gotoPage: (nextPage: number) => void;
  tableId?: number;
}

const Pagination: React.FC<PaginationProps> = ({
  total, pageSize, pageIndex, pageCount, nextPage, canNextPage, previousPage,
  canPreviousPage, setPageSize, gotoPage, tableId,
}) => (
  <div css={style}>
    <div>
      {total}
      件中
      {' '}
      {pageIndex * pageSize + 1}
      〜
      {(pageIndex + 1) * pageSize}
      件
    </div>

    <VerticalSeparator />

    <div>
      表示件数
      {' '}
    </div>
    <Dropdown
      items={[{ value: 20 }, { value: 50 }, { value: 100 }]}
      setValue={(value) => {
        setPageSize(Number(value));
        sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageSize`, String(value));
      }}
    >
      {pageSize}
    </Dropdown>

    <VerticalSeparator />

    <div>
      ページ数
    </div>
    <Dropdown
      items={[...Array(pageCount)].map((_, pageNum) => ({ value: pageNum + 1 }))}
      setValue={(value) => {
        const pageIndex = Number(value);
        gotoPage(pageIndex);
        sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(pageIndex - 1));
      }}
    >
      {`${pageIndex + 1} / ${pageCount !== 0 ? pageCount : 1} ページ`}

    </Dropdown>

    <VerticalSeparator />

    <div>
      <ClickableLink
        onClick={() => {
          previousPage();
          sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(pageIndex - 1));
        }}
        disabled={!canPreviousPage}
        color={grayScale.gray100}
      >
        ←
      </ClickableLink>
    </div>
    <div>
      <ClickableLink
        onClick={() => {
          nextPage();
          sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(pageIndex + 1));
        }}
        disabled={!canNextPage}
        color={grayScale.gray100}
      >
        →
      </ClickableLink>
    </div>
  </div>
);

export default Pagination;
