import moment from 'moment';
/**
 * API 祝日マスタ
 */

export interface HolidayMaster {
    publicHolidayId: string;
    publicHolidayDate: Date;
    publicHolidayName: string;
    isAll:boolean;
    isEdit:boolean;
}


export default class HolidayMasterDomain {
  constructor(private rawData: HolidayMaster) {
    // do nothing
  }

  static generateInitial(): HolidayMasterDomain {
    return new HolidayMasterDomain({
      publicHolidayId: '',
      publicHolidayDate: new Date(),
      publicHolidayName: '',
      isAll: false,
      isEdit: false,
    });
  }

  getRawData(): HolidayMaster {
    return this.rawData;
  }

  get publicHolidayId(): string {
    return this.rawData.publicHolidayId;
  }

  set publicHolidayId(publicHolidayId: string) {
    this.rawData.publicHolidayId = publicHolidayId;
  }

  get publicHolidayName():string {
    return this.rawData.publicHolidayName;
  }

  set publicHolidayName(publicHolidayName:string) {
    this.rawData.publicHolidayName = publicHolidayName;
  }

  get publicHolidayDate(): Date {
    return this.rawData.publicHolidayDate;
  }

  get publicHolidayDateStr(): string {
    return moment(this.rawData.publicHolidayDate).format('YYYY/MM/DD');
  }

  set publicHolidayDate(date: Date) {
    this.rawData.publicHolidayDate = date;
  }

  set isAll(isAll: boolean) {
    this.rawData.isAll = isAll;
  }

  get isEdit(): boolean {
    return this.rawData.isEdit;
  }
}
