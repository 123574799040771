/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { textFontSize, utilityColor } from 'components/styles';

const styles = {
  title: css({
    margin: '16px 0',
  }),
  note: css({
    fontSize: textFontSize.sm,
    marginLeft: '4px',
  }),
};

const FormTitle: React.FC<{
  title: string;
  note?: string;
  noteColor?: string;
}> = ({ title, note, noteColor = utilityColor.error }) => (
  <h4 css={styles.title}>
    {title}
    {note && <span css={css(styles.note, { color: noteColor })}>{note}</span>}
  </h4>
);

export default FormTitle;
