/** @jsx jsx */
import React, { useMemo, useState, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import { iconColor } from 'components/styles';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import useToastNotification from 'hooks/useToastNotification';

import Icon from 'components/atoms/Icon';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import PrimaryButton from 'components/atoms/Button';
import DataTable from 'components/organismos/DataTable/DataTable';
import { downloadCSV, deleteAccountTitleMST } from 'api/accountTitleMST';
import AccountTitleMSTItemDomain from 'domain/master/general/accountTitleMSTItem';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const AccountMaster: React.FC<{
        accountTitleMSTItemList: any,
        setAccountTitleMSTItemList: any
    }> = ({
      accountTitleMSTItemList,
      setAccountTitleMSTItemList,
    }) => {
      const [confirmModalOpen, setConfirmModalOpen] = useState(false);
      const { successNotification, errorNotification } = useToastNotification();

      const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

      const closeConfirmModal = useCallback(() => {
        setConfirmModalOpen(false);
      }, []);
      const [selectedAccountTitleMSTItem, setSelectedAccountTitleMSTItem] = useState(
        AccountTitleMSTItemDomain.generateInitial(),
      );

      const downloadFileCSV = useCallback(() => {
        downloadCSV('勘定科目');
      }, []);

      const openConfirmModal = useCallback((accountTitleMSTItem : AccountTitleMSTItemDomain) => {
        setConfirmModalOpen(true);
        setSelectedAccountTitleMSTItem(accountTitleMSTItem);
      }, []);

      const deleteAccountTitleItem = useCallback(() => {
        deleteAccountTitleMST(
          selectedAccountTitleMSTItem.accountTitleId,
        ).then((response: any) => {
          setAccountTitleMSTItemList(
            accountTitleMSTItemList.filter(
              (item: any) => item.accountTitleId
              !== selectedAccountTitleMSTItem.accountTitleId,
            ),
          );
          sessionStorage.removeItem('accountTitleId');
          setConfirmModalOpen(false);
          successNotification('削除しました。');
        }).catch((error) => {
          setConfirmModalOpen(false);
          if (error.response && error.response.data && error.response.data.defaultMessage) {
            errorNotification(error.response.data.defaultMessage);
          } else {
            errorNotification('サーバー側でエラーが発生しました。');
          }
        });
      }, [errorNotification, accountTitleMSTItemList,
        selectedAccountTitleMSTItem.accountTitleId,
        setAccountTitleMSTItemList, successNotification]);

      const columns: Array<Column<AccountTitleMSTItemDomain>> = useMemo(() => [
        {
          Header: '勘定科目コード',
          accessor: 'accountTitleCode',
          sortType: 'basic'
        },
        {
          Header: '勘定科目名',
          accessor: 'accountTitleName',
        },
        {
          Header: () => null,
          id: 'actionButton',
          disableSortBy: true,
          Cell: (cell: { row :{ isExpanded: boolean, original: AccountTitleMSTItemDomain },
        data: Array<AccountTitleMSTItemDomain>
        }) => {
            const [isHoverEdit, setIsHoverEdit] = useState(false);
            const [isHoverDelete, setIsHoverDelete] = useState(false);
            return (
              <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                <Link to={`/accountTitleMst/edit/${`${cell.row.original.accountTitleId}`}`}>
                  <span
                    style={{ marginLeft: '20px' }}
                    onMouseEnter={() => setIsHoverEdit(true)}
                    onMouseLeave={() => setIsHoverEdit(false)}
                  >
                    <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </Link>
                {
                  roleScreen && roleScreen.editable === 1 && (
                    <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                      <span
                        style={{ marginLeft: '20px', marginRight: '10px' }}
                        onMouseEnter={() => setIsHoverDelete(true)}
                        onMouseLeave={() => setIsHoverDelete(false)}
                      >
                        <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                      </span>
                    </Link>
                  )
                }
              </div>
            );
          },
        },
      ], [openConfirmModal, roleScreen]);

      return (
        <div>
          <div style={{ margin: '8px 16px' }}>
            <p>小口現金管理で登録されたデータを仕訳するための勘定科目を設定します。</p>
            <FlexBox>
              {
                roleScreen && roleScreen.downloadFlag === 1 && (
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={downloadFileCSV}
                    iconType="download"
                    text="CSVダウンロード"
                  />
                </FlexBoxItem>
                )
              }
              {
                roleScreen && roleScreen.importFlag === 1 && (
                <FlexBoxItem>
                  <Link to="/accountTitleMst/import">
                    <PrimaryButton
                      ghost={false}
                      text="インポート"
                    />
                  </Link>
                </FlexBoxItem>
                )
              }
              {
                roleScreen && roleScreen.editable === 1 && (
                <FlexBoxItem grow={1}>
                  <div style={{ textAlign: 'right' }}>
                    <Link to="/accountTitleMst/add">
                      <PrimaryButton
                        ghost={false}
                        text="勘定科目作成"
                      />
                    </Link>
                  </div>
                </FlexBoxItem>
                )
              }
            </FlexBox>
          </div>
          <div
            css={css`
            table th:nth-child(1) {
                width: 100px;
            }
            table th:nth-child(2) {
                width: 200px;
            }
            table th:nth-child(3) {
                width: 80px;
            }
            `}
          >
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
              <DataTable
                columns={columns}
                data={accountTitleMSTItemList}
                isGlobalFilter={true}
                sortBy={[
                  { id: 'accountTitleCode', desc: false }
                ]}
              />
            </div>
          </div>
          <ConfirmActionModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            onSubmit={deleteAccountTitleItem}
            actionType={ActionType.DELETE}
          />
        </div>
      );
    };

export default AccountMaster;
