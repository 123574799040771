/* eslint-disable no-nested-ternary */
import ApiClient from './ApiClient';

// get api saleslip breakDown
export const getSalesBreakDown = async (query:any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/report/segmentalSalesBreakdown/${companyCode}`, query);
  return response.data;
};

// get api adv plan advertising
export const getListAd = async (query: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/AdvertisingPlanMaster/list/simple/${companyCode}`, query);
  return response.data;
};

// get api adv plan order advertising
export const getListOrderAd = async (query: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/othersPlanMaster/list/${companyCode}`, query);
  return response.data;
};

// Get api list segment
export const getListSegment = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/slip/segment/list/${companyCode}`, {});
  return response.data;
};

// get api adv plan Organizatio advertising
export const getListOrganizationAdvertising = async (query: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/organizationAdvertisingNameMaster/list/${companyCode}`, query);
  return response.data;
};

// get api adv plan Organizatio advertising
export const getListMediaAdvertising = async (query: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/advertisingMediaOrganization/list/${companyCode}`, query);
  return response.data;
};


// get api sales slip list
export const getSalesSlipList = async (query:any, selectTab:any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const param = {
    orgCode: query.orgCode,
    targetDay: query.targetDay,
    functionType: query.functionType,
    loginStaffCode: query.loginStaffCode,
    segmentType: selectTab === 'tab_advertising_table' ? 1 : 2,
  };
  const response = await ApiClient.get(`/v1/sales/report/salesSlipList/${companyCode}`, param);
  return response.data;
};

// update api sales slip list
export const updateSalesSlipList = async (formSubmit:any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const body = {
    salesSlipHeaderFormList: formSubmit,
  };
  const response = await ApiClient.postMutipartData(`/v1/sales/report/salesSlipList/${companyCode}`, {}, body);
  return response.data;
};

export default { };
