import React from 'react';

import FormContents from 'components/atoms/Form/FormContents';
import FiscalYearInputAddForm from './FiscalYearInputAddForm';

const FiscalYearAddForm: React.FC<{}> = () => (
  <FormContents>
    <FiscalYearInputAddForm />
  </FormContents>
);

export default FiscalYearAddForm;
