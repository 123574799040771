import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import ApprovalModificationApplication from 'components/organismos/master/attend/ApprovalApplication/ApprovalModificationApplication';

const ApprovalModificationApplicationPage: React.FC = () => (
  <SidebarTemplate pageTitle="打刻修正申請 承認・否決">
    <ApprovalModificationApplication />
  </SidebarTemplate>
);

export default ApprovalModificationApplicationPage;
