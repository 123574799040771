/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import FormContents from 'components/atoms/Form/FormContents';
import {
  Container, Row, Col,
} from 'react-grid-system';
// import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import moment from 'moment';
import BodyText from 'components/atoms/BodyText';
import Button, { PrimaryButton } from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import TextForm from 'components/molecules/TextForm';
import FormField from 'components/atoms/Form/FormField';
import BlockUI from 'components/molecules/BlockUi';
import { productColor, textFontSize, textColor } from 'components/styles';
// import FlexBox from 'components/atoms/FlexBox';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';

import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import ToastModal from 'components/organismos/ToastModal';
import { HolidayWorkApplicationForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const HolidayWorkApplicationPage: React.FC = () => {
  const history = useHistory();
  const {
    formik,
    // orgTreesOptions,
    targetDate,
    toastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    closeConfirmApplyModal,
    isLoading,
    dispStaffName,
    setSelectOrgCode,
    isGetHolidayWorkSuccees,
  } = HolidayWorkApplicationForm();
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <SidebarTemplate pageTitle="休日出勤申請">
      <BlockUI blocking={isLoading}>
        <FormContents>
          <form onSubmit={formik.handleSubmit}>
            <Container fluid={true}>
              <Row>
                <Col md={2} xs={12}>
                  <BodyText>対象日</BodyText>
                </Col>
                <Col md={6} xs={12}>
                  <BodyText>
                    {moment(`${targetDate}`).format('YYYY年MM月DD日(ddd)')}
                  </BodyText>
                </Col>
              </Row>
              <Row>
                <Col md={2} xs={12}>
                  <BodyText>スタッフ</BodyText>
                </Col>
                <Col md={6} xs={12}>
                  <BodyText>
                    {dispStaffName}
                  </BodyText>
                </Col>
              </Row>
              {
                isGetHolidayWorkSuccees && (
                  <Row>
                    { formik.values.modifierApplicationId === null && (
                    <Col md={12} xs={12}>
                      {/* <VerticalLabelSelectForm
                        label="出勤店舗"
                        name="orgCode"
                        value={String(formik.values.orgCode)}
                        setValue={(val: string) => setSelectOrgCode(val)}
                        options={orgTreesOptions}
                        required={true}
                      /> */}

                      <OrganizationFilteredTree
                        functionType={2}
                        targetdayForm={moment(targetDate).toDate()}
                        targetdayTo={moment(targetDate).toDate()}
                        orgCallback={(args: string | Array<string>) => {
                          setSelectOrgCode(String(args));
                        }}
                        initOrgValue={String(formik.values.orgCode)}
                        staffCode={loginStaffCode}
                        orgLabel="出勤店舗"
                        addAllItem={false}
                      />

                    </Col>
                    )}
                    {
                      formik.values.modifierApplicationId !== null && (
                        <Col xs={12}>
                          <Row>
                            <Col md={2} xs={12}>
                              <BodyText>出勤店舗</BodyText>
                            </Col>
                            <Col md={6} xs={12}>
                              <BodyText>
                                {formik.values.orgName}
                              </BodyText>
                            </Col>
                          </Row>
                          <Row>&nbsp;</Row>
                        </Col>
                      )
                   }
                  </Row>
                )
              }
              <Row style={{
                padding: '4px 16px',
                backgroundColor: productColor.primaryM95,
                fontWeight: 'normal',
                textAlign: 'center',
                fontSize: textFontSize.re,
                borderTop: `1px solid ${productColor.primaryM80}`,
                borderBottom: `1px solid ${productColor.primaryM80}`,
                color: textColor.main,
              }}
              >
                <Col xs={6}>出社時間</Col>
                <Col xs={6}>退社時間</Col>
              </Row>
              <Row style={{
                textAlign: 'center',
                fontSize: textFontSize.re,
              }}
              >
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  xs={6}
                >
                  {(formik.values.workTimeData != null && formik.values.workTimeData.startTime) ? formik.values.workTimeData.startTime : ''}
                  &nbsp;
                </Col>
                <Col
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  xs={6}
                >
                  {(formik.values.workTimeData != null && formik.values.workTimeData.endTime) ? formik.values.workTimeData.endTime : ''}
                  &nbsp;
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormField>
                    <TextForm
                      required={true}
                      name="applicationReason"
                      label="申請理由"
                      value={String(formik.values.applicationReason)}
                      onChange={formik.handleChange}
                      errorMsg={formik.errors.applicationReason}
                    />
                  </FormField>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <FormSubmitArea>
                    {
                      (isGetHolidayWorkSuccees && (formik.values.modifiyStatus === null || formik.values.modifiyStatus === 0
                        || (formik.values.modifiyStatus === 1 && formik.values.applicationStatus !== 0))) 
                        && roleScreen && (roleScreen.editable === 1 || roleScreen.length === 0) && (
                        <div style={{ marginRight: '12px' }}>
                          <PrimaryButton
                            ghost={false}
                            text="申請"
                            onClick={() => {
                              formik.handleSubmit();
                              formik.setFieldValue('buttonType', 0);
                            }}
                          />
                        </div>
                      )
                    }
                    {
                      formik.values.applicationStatus === 0
                      && roleScreen && (roleScreen.editable === 1 || roleScreen.length === 0) && (
                      <div style={{ marginRight: '12px' }}>
                        <PrimaryButton
                          ghost={false}
                          text="申請キャンセル"
                          onClick={() => {
                            formik.handleSubmit();
                            formik.setFieldValue('buttonType', 1);
                          }}
                        />
                      </div>
                      )
                    }
                    {
                      formik.values.deleteAvailable && roleScreen && (roleScreen.editable === 1 || roleScreen.length === 0) && (
                      <div style={{ marginRight: '12px' }}>
                        <PrimaryButton
                          ghost={false}
                          text="削除申請"
                          onClick={() => {
                            formik.handleSubmit();
                            formik.setFieldValue('buttonType', 2);
                          }}
                        />
                      </div>
                      )
                    }
                    <Button
                      onClick={() => history.push(sessionStorage.getItem('attendStampList.returnDestination')! || '')}
                      text="戻る"
                      ghost={true}
                    />
                  </FormSubmitArea>
                </Col>
              </Row>
            </Container>
          </form>

          <ToastModal
            open={toastModalOpen}
            closeHandler={() => setToastModalOpen(false)}
            title={toastMessage}
          />

          <ConfirmModal
            open={confirmApplyModalOpen}
            closeHandler={closeConfirmApplyModal}
            onSubmit={formik.handleSubmit}
            title="確認メッセージ"
            // eslint-disable-next-line no-nested-ternary
            content={formik.values.buttonType === 0 ? '申請します。よろしいですか？'
              : formik.values.buttonType === 1 ? '申請をキャンセルします。よろしいですか？' : '削除申請をします。よろしいですか？'}
            // eslint-disable-next-line no-nested-ternary
            submitText={formik.values.buttonType === 0 ? '申請'
              : formik.values.buttonType === 1 ? '申請キャンセル' : '削除申請'}
          />
        </FormContents>

      </BlockUI>
    </SidebarTemplate>
  );
};

export default HolidayWorkApplicationPage;
