import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import StampHistoryLayout from 'components/organismos/master/atendanceManagement/StampHistoryLayout';

const StampHistoryPage = () => (
  <SidebarTemplate pageTitle="打刻実績">
    <StampHistoryLayout />
  </SidebarTemplate>
);

export default StampHistoryPage;
