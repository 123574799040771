import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AttendanceError from './AttendanceError';

const AttendanceErrorPage: React.FC = () => {
  return (
    <SidebarTemplate pageTitle="勤怠エラーリスト">
      <AttendanceError
        orgCodeNoti={String(sessionStorage.getItem('attendError.orgCode'))}
        targetDateFromNoti={sessionStorage.getItem('attendErrorIcon.targetDateFrom')}
        targetDateToNoti={sessionStorage.getItem('attendErrorIcon.targetDateTo')}
        clearToggle={sessionStorage.getItem('clearToggle')}
      />
    </SidebarTemplate>
  );
};

export default AttendanceErrorPage;
