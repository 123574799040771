/* eslint-disable jsx-a11y/control-has-associated-label */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import BlockUI from 'components/molecules/BlockUi';
import FormLabel from 'components/atoms/Form/FormLabel';
import { isMobile, isMobileOnly } from 'react-device-detect';
import moment from 'moment';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import Icon from 'components/atoms/Icon';
import { textColor } from 'components/styles';
import BottomBar from 'components/organismos/BottomBar';
import DatePickerForm from 'components/molecules/DatePickerForm';
import { Link, useParams } from 'react-router-dom';
import Button from 'components/atoms/Button';
import useStampHistoryForm from './hooks';

const ShogunStampHistoryForm = () => {
  const functionType = 3;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = useParams<any>();

  const {
    setCategoryAry,
    stampHistory,
    isLoading,
    fetchData,
    setSelectOrgCode,
    selectOrgCode,
    targetDate,
    setTargetDate,
    searchTime,
  } = useStampHistoryForm();

  function checkTime(stringDate: any) {
    let time = '';
    const date = moment(stringDate).format('YYYY-MM-DD');
    const formatSearchTime = moment(searchTime).format('YYYY-MM-DD');
    if (formatSearchTime === date) {
      time = moment(searchTime).format('YYYY年MM月DD日（ddd） HH時mm分まで');
    }
    return time;
  }

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox alignItems="center">
          <FlexBoxItem basis={isMobile ? '20%' : '10%'}>
            <FormLabel label="対象日" />
          </FlexBoxItem>
          <FlexBoxItem basis="50%">
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label=""
              isFullWidth={true}
              date={targetDate}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDate(date);
                }
              }}
            />
          </FlexBoxItem>
          <FlexBoxItem
            marginLeft="auto"
            customStyle={css({ marginTop: isMobileOnly ? '5px' : 0 })}
          >
            <ClickableIconButton
              additionalCss={css({
                backgroundColor: '#0D4796',
                paddingLeft: '25px',
                paddingRight: '25px',
                borderRadius: '5px',
              })}
              onClick={fetchData}
            >
              <Icon
                type="reload"
                color={textColor.inversed}
                isHover={false}
                size="25"
              />
            </ClickableIconButton>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox>
          <FlexBoxItem width="100%">
            <OrganizationFilteredTree
              functionType={functionType}
              staffCode={staffCode}
              orgLabel="組織名"
              initOrgValue={String(selectOrgCode)}
              orgCallback={(args: string | Array<string>) => {
                setSelectOrgCode(String(args));
              }}
              orgCategoryCallback={(args: Array<string>) => {
                setCategoryAry(args);
              }}
              styles={{
                label: {
                  toggleSwitch: css({ marginLeft: '0px' }),
                },
                toggle: {
                  width: '13rem',
                },
              }}
            />
          </FlexBoxItem>
        </FlexBox>

        <FlexBox customStyle={css({ marginTop: '12px', marginBottom: '16px' })}>
          <FormLabel label={checkTime(targetDate)} />
        </FlexBox>
        <div style={{ marginTop: '15px', overflow: 'auto', marginBottom: '30px' }}>
          <table className="table table-bordered" id="shogunStampHistory" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: isMobile ? '100%' : '500px' }}>
            <thead>
              <tr>
                <th>時刻</th>
                <th>内容</th>
                <th>社員ID</th>
                <th>社員名</th>
              </tr>
            </thead>
            <tbody style={{ maxHeight: '100%', width: '100%' }}>
              {stampHistory?.list?.map((item: any) => (
                <tr style={{ background: 'white' }}>
                  <td className="text-center">{item.targetTime}</td>
                  <td className="text-center">{item.targetName}</td>
                  <td className="text-right pr-10">{item.staffCode}</td>
                  <td className="text-left pr-10">{item.staffName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {params.orgCode && params.targetDate
          && (
          <Link to="/shogun/operationalReportList">
            <Button
              text="戻る"
              ghost={true}
            />
          </Link>
          )}
      </FormContents>
      {isMobileOnly && <BottomBar />}
    </BlockUI>
  );
};
export default ShogunStampHistoryForm;
