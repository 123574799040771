import { useState, useEffect } from 'react';
import {
  saveSalesSuppliers, getDetailSalesSuppliers, updateSalesSuppliers,
} from 'api/salesSuppliersMST';
import { useParams, useHistory } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';
import moment from 'moment';

export const useSalesSuppliersForm = () => {
  const [blocking, setBlocking] = useState(false);
  const [transactionType, setTransactionType] = useState<string>('0');
  const [suppliersCode, setSuppliersCode] = useState<string>('');
  const [suppliersName, setSuppliersName] = useState<string>('');
  const [dispOrder, setDispOrder] = useState<number>(0);
  const [applyStartDate, setApplyStartDate] = useState<Date>(new Date());
  const [applyEndDate, setApplyEndDate] = useState<Date | undefined>(undefined);
  const [save, setSave] = useState<number>(0);
  const { suppliersCodeId, transaction } = useParams<any>();
  const { successNotification, errorNotification } = useToastNotification();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [undecided, setUndecided] = useState(true);
  const history = useHistory();

  // eslint-disable-next-line no-shadow
  const handleSaveSalesSuppliers = async (transactionType: string, suppliersCode: string, suppliersName: string, dispOrder: number, applyStartDate: string, applyEndDate: string) => {
    saveSalesSuppliers(transactionType, suppliersCode, suppliersName, dispOrder, applyStartDate, applyEndDate).then((response: any) => {
      let errors = '';
      if (response.status && response.status !== 200) {
        (response.data as Array<any>).map(
          (err) => {
            errors += err.defaultMessage;
          },
        );
        errorNotification(errors);
      } else {
        setTransactionType('0');
        setSuppliersCode('');
        setSuppliersName('');
        setDispOrder(0);
        setApplyStartDate(new Date());
        setApplyEndDate(undefined);
        setUndecided(true);
        successNotification('登録しました。');
        history.push('/salesSuppliersMST');
      }
      setBlocking(false);
    }).catch((error: any) => {
      let errors = '';
      if (error.response.data) {
        errors += error.response.data.defaultMessage;
        errorNotification(errors);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setBlocking(false);
    });
  };

  // eslint-disable-next-line no-shadow
  const handleUpdateSalesSuppliers = async (transactionType: string, suppliersCode: string, suppliersName: string, dispOrder: number, applyStartDate: string, applyEndDate: string) => {
    updateSalesSuppliers(transactionType, suppliersCode, suppliersName, dispOrder, applyStartDate, applyEndDate).then((response: any) => {
      let errors = '';
      if (response.status && response.status !== 200) {
        (response.data as Array<any>).map(
          (err) => {
            errors += err.defaultMessage;
          },
        );
        errorNotification(errors);
      } else {
        successNotification('更新しました。');
      }
      setBlocking(false);
    }).catch((error: any) => {
      let errors = '';
      if (error.response.data) {
        errors += error.response.data.defaultMessage;
        errorNotification(errors);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      setBlocking(false);
    });
  };

  useEffect(() => {
    if (save === 1) {
      const startDate = moment(applyStartDate).startOf('month').format('YYYY-MM-DD');
      const endDate = !undecided ? moment(applyEndDate).endOf('month').format('YYYY-MM-DD') : moment('9999-12-31').format('YYYY-MM-DD');
      if (suppliersCode.trim() === '') {
        setSave(0);
        setBlocking(false);
        errorNotification('コードを入力してください。');
        return;
      }
      if (suppliersName.trim() === '') {
        setSave(0);
        setBlocking(false);
        errorNotification('名称を入力してください。');
        return;
      }
      if (startDate > endDate) {
        setSave(0);
        setBlocking(false);
        errorNotification('適用終了月には適用開始月以降の年月を指定してください');
        return;
      }
      if (suppliersCodeId) {
        setBlocking(true);
        handleUpdateSalesSuppliers(transactionType, suppliersCode, suppliersName, dispOrder, startDate, endDate);
      } else {
        handleSaveSalesSuppliers(transactionType, suppliersCode, suppliersName, dispOrder, startDate, endDate);
      }
      setSave(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save]);

  useEffect(() => {
    if (suppliersCodeId) {
      setBlocking(true);
      getDetailSalesSuppliers(suppliersCodeId, transaction).then((response: any) => {
        setTransactionType(transaction);
        setSuppliersCode(suppliersCodeId);
        setSuppliersName(response.suppliersName);
        setDispOrder(response.dispOrder);
        setApplyStartDate(new Date(response.applyStartDate));
        setApplyEndDate(new Date(response.applyEndDate));
        if (moment(response.applyEndDate).format('YYYY-MM-DD') !== '9999-12-31') {
          setUndecided(false);
        }
        setBlocking(false);
      }).catch((error: any) => {
        let errors = '';
        if (error.response && error.response.data.length > 0) {
          errors += error.response.data.defaultMessage;
          errorNotification(errors);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
        setTransactionType('0');
        setSuppliersCode('');
        setBlocking(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  const handleSubmit = () => {
    setBlocking(true);
    setSave(1);
    closeConfirmModal();
  };

  return {
    blocking,
    setBlocking,
    transactionType,
    setTransactionType,
    suppliersCode,
    setSuppliersCode,
    suppliersName,
    setSuppliersName,
    dispOrder,
    setDispOrder,
    applyStartDate,
    setApplyStartDate,
    applyEndDate,
    setApplyEndDate,
    setSave,
    undecided,
    setUndecided,
    suppliersCodeId,
    transaction,
    confirmModalOpen,
    closeConfirmModal,
    openConfirmModal,
    handleSubmit,
  };
};

export default {

};
