/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { grayScale, productColor, textFontSize } from 'components/styles';
import Icon from 'components/atoms/Icon';

const styles = {
  label: css({
    alignItems: 'center',
    display: 'inline-flex',
    cursor: 'pointer',
    color: productColor.primary,
    fontSize: textFontSize.re,
    '& > span > svg': {
      marginRight: '5px',
    },
  }),
  input: css({
    display: 'none',
  }),
};

const Radio: React.FC<{
  id: string;
  name: string;
  label: string;
  value: string;
  checked: boolean;
  readOnly?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, menuId: string) => void
  menuId: string;
}> = ({
  id, name, label, value, checked, onChange, readOnly = false, menuId
}) => (
    <div>
      <label
        css={styles.label}
        htmlFor={id}
      >
        {checked
          ? <Icon type="radioOn" color={grayScale.gray100} />
          : <Icon type="radioOff" color={grayScale.gray100} />}
        <input
          css={styles.input}
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={(e) => onChange(e, menuId)}
          readOnly={readOnly}
        />
        {label}
      </label>
    </div>
  );

export default Radio;
