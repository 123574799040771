import {
  Column, useTable, useSortBy, usePagination,
} from 'react-table';
interface SortBy {
  id: string;
  desc: boolean;
}

export const useDataTableSort = (columns: Array<Column>, data: Array<{[key: string]: string}>, sortBy?: Array<SortBy>,) => {
  const newLocal = Number.MAX_SAFE_INTEGER;
  const {
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: { 
        pageSize: newLocal,
        sortBy: sortBy || [],
       },
       disableSortRemove: true
    },
    useSortBy,
    usePagination,
  );

  return {
    headerGroups,
    page,
    prepareRow,

  };
};

export default {
  useDataTableSort,
};
