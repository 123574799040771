import { useState, useEffect } from 'react';
import { getAccountTitleMSTList } from 'api/accountTitleMST';
import AccountTitleMSTItemDomain from 'domain/master/general/accountTitleMSTItem';

export const useAccountTitleMSTItemList = () => {
  const [
    accountTitleMSTItemList,
    setAccountTitleMSTItemList] = useState<Array<AccountTitleMSTItemDomain>>([]);
  useEffect(() => {
    getAccountTitleMSTList().then((response: Array<any>) => {
      setAccountTitleMSTItemList(
        response.map((result) => result),
      );
    });
  }, []);
  return {
    accountTitleMSTItemList, setAccountTitleMSTItemList,
  };
};

export default useAccountTitleMSTItemList;
