/* eslint-disable max-len */
import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

export const downloadCSV = async (
  fileName: string, orgCode: string,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsv(`/v3/discountTypeOrganization/outputCsv/${companyCode}?orgCode=${orgCode}`, {}, getDownloadCsvFileName(`${fileName}_`));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export const getTreesByCategory = async (functionType:number,category?: Array<any>): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  // const siteType = 'HQ';
  // TODO: V4にバージョンアップ予定
  const response = await ApiClient.get(`/v3/organizations/trees/${companyCode}?staffCode=${staffCode}&functionType=${functionType}&category=${category}`, {});
  return response.data;
};


export const getPriceWidthdrawalList = async (orgCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = { orgCode };
  const response = await ApiClient.get(`/v3/discountTypeOrganization/list/${companyCode}`, params);
  return response.data;
};


export const deletePriceWithdrawal = async (isConfirmed: number,
  advertisingMediaOrganization: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isConfirmed,
  };

  const response = await ApiClient.post(`/v3/discountTypeOrganization/delete/${companyCode}`, params, advertisingMediaOrganization);
  return response.data;
};


export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v3/discountTypeOrganization/csv/import/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

// export const getListAdvertisingMediaOrganization = async (orgCode: string, discountTypeCode: string): Promise<any> => {
//   const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
//   const params = { orgCode, discountTypeCode };


//   const response = await ApiClient.get(`/v3/discountTypeOrganization/list/${companyCode}`, params);
//   return response.data;
// };


export const createOrUpdateAdvertisingMediaOrganization = async (isCreate: boolean,
  advertisingMediaOrganization: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isCreate: !!isCreate,
  };
  const response = await ApiClient.post(`/v3/discountTypeOrganization/save/${companyCode}`, params, advertisingMediaOrganization);
  return response.data;
};

export const getDiscountOrganizationList = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v3/discountType/list/${companyCode}`, {});
  return response.data;
};

export type Error = {
    error: string;
    defaultMessage: string;
  }
