/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsx jsx */
import React from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import ReactTooltip from "react-tooltip";
import productColor from "components/styles";

const styles = {
  customTagContent: css({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "8px 0"
  }),
  customTag: css({
    display: "flex",
    flexWrap: "wrap"
  }),
  customTagItems: css({
    margin: "5px 0 5px 5px",
    span: {
      backgroundColor: "#E7EDF5",
      color: productColor.primary,
      padding: "3px 12px",
      borderRadius: "12px"
    }
  }),
  customTooltip: css({
    ".other": {
      margin: 0,
      marginLeft: "10px",
      color: productColor.primary,
      cursor: "pointer"
    },
    ".__react_component_tooltip": {
      lineHeight: "10px"
    }
  })
};

export interface TagViewProps {
  list: Array<any>;
  maxLengthItem?: number;
  id: string;
  customStyle?: SerializedStyles;
  placeTooltip?: ReactTooltip.Place;
}

const TagView: React.FC<TagViewProps> = ({
  list,
  maxLengthItem = 3,
  id,
  customStyle,
  placeTooltip = "top"
}) => {
  const groupHeadName = Array.from(new Set(list.map(item => item?.organizationCategoryMstHeadName)));
  const newList = groupHeadName.map(item => {
    return {
      label: item,
      option: list.filter(x => x?.organizationCategoryMstHeadName === item).map(item => item?.organizationCategoryMstName)
    };
  });

  return (
    <div css={customStyle}>
      {newList?.map((item, i) => {
        return (
          <div css={styles.customTagContent}>
            <label>{`${item.label}:`}</label>
            <div css={styles.customTag}>
              {item.option.map((option, index) => {
                if (index < maxLengthItem) {
                  return (
                    <div css={styles.customTagItems}>
                      <span>{option}</span>
                    </div>
                  );
                }
              })}
              {item.option?.length > maxLengthItem && (
                <div css={styles.customTooltip}>
                  <p className="other" data-tip={false} data-for={id}>
                    ...
                  </p>
                  <ReactTooltip id={id} type="dark" place={placeTooltip}>
                    {item.option.map((option, i) => {
                      return (
                        <p style={{ display: i < maxLengthItem ? "none" : "" }}>
                          {option}
                        </p>
                      );
                    })}
                  </ReactTooltip>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TagView;
