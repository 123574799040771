import { useCallback, useEffect, useState } from 'react';
import { getOrganizationsTrees } from 'api/attendAchievementOutput';
import { OptionType } from 'components/atoms/Select';
import * as api from 'api/timeListDataOutput';
import useToastNotification from 'hooks/useToastNotification';
import { getErrorMessageForResponse } from 'utility/errorUtil';
import moment from 'moment';

const { errorNotification } = useToastNotification();

export const useTimeListReport = () => {
  const [orgCode, setorgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || '');
  const [staffCode] = useState(sessionStorage.getItem('loginUser.staffCode') || '');
  const functionType = 3;
  const [categoryAry, setCategoryAry] = useState<Array<any>>([]);
  const [blocking, setBlocking] = useState(false);
  const [targetDateFrom, setTargetDateFrom] = useState(new Date(moment().startOf('month').format('YYYY-MM-DD')));
  const [targetDateTo, setTargetDateTo] = useState(new Date());
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const downloadListDataOutput = useCallback(() => {
    let appendUrl = '';
    if (Array.isArray(categoryAry)) {
      categoryAry.forEach((cateArr: any) => {
        appendUrl += `&categoryAry=${cateArr.join(',')}`;
      });
    }
    const params = {
      orgCode,
      targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
      targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      loginStaffCode,
      functionType,
    };
    api.downloadCSVTimList(params, appendUrl)
      .then(() => {
        setBlocking(false);
      }).catch((error) => {
        setBlocking(false);
        const message = getErrorMessageForResponse(error);
        errorNotification(message);
      }).finally(() => {
        setBlocking(false);
      });
  }, [categoryAry, orgCode, targetDateFrom, targetDateTo]);

  return {
    categoryAry,
    setCategoryAry,
    blocking,
    setBlocking,
    targetDateFrom,
    setTargetDateFrom,
    staffCode,
    orgCode,
    setorgCode,
    targetDateTo,
    setTargetDateTo,
    functionType,
    downloadListDataOutput,
  };
};


export const useGetSupportSourceOrg = () => {
  const [organizationsTrees, setOrganizationsTrees] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const functionType:number = 3;
    getOrganizationsTrees(staffCode, functionType).then((response:any) => {
      const orgs = response.map((res: any) => ({
        value: res.orgCode,
        label: `${res.orgCode} ${res.orgName}`,
      }));
      setOrganizationsTrees(orgs);
    });
  }, []);
  return { organizationsTrees };
};


export default useTimeListReport;
