import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import BankDepositLayout from './BankDepositExportLayout';

const BankDepositExportPage = () => (
  <SidebarTemplate pageTitle="入出金仕訳データ出力">
    <BankDepositLayout />
  </SidebarTemplate>
);

export default BankDepositExportPage;
