/* eslint-disable max-len */
import React, { useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
// import { useOrgTreesOptionsShop } from 'hooks/useOrgTreesOptions';
import SelectForm from 'components/molecules/SelectForm';
import FormField from 'components/atoms/Form/FormField';

import {
  hopeShiftLimitFlagLabel, hopeShiftLimitFlag,
  //  mwsDisplayFlagLabel, mwsDisplayFlag,
  mwsDisplayStartDowLabel, mwsDisplayStartDow, shiftCreateFlag, shiftCreateFlagLabel,
} from 'domain/master/labor/organizationSetting';
import FormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBox from 'components/atoms/FlexBox';
import HourInput from 'components/atoms/Form/HourInput';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import BodyText from 'components/atoms/BodyText';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import { utilityColor } from 'components/styles';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { useSettingUpdateForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const MasterOrganizeSettingLayout = () => {

  // const orgTreesOptions = useOrgTreesOptionsShop(undefined, undefined, true);
  const [orgCode, setorgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || '');
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const { formik, confirmModalOpen, closeConfirmModal } = useSettingUpdateForm(orgCode);

  const functionType = 1;

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <div>
      <FormContents>
        <form onSubmit={formik.handleSubmit}>
          <OrganizationFilteredTree
            functionType={functionType}
            orgCallback={(args: string | Array<string>) => {
              setorgCode(String(args));
            }}
            initOrgValue={orgCode}
            staffCode={staffCode}
            orgLabel="組織名"
          />

          {/* <SelectForm
            label="組織名"
            name="orgCode"
            value={String(orgCode)}
            setValue={(v) => {
              setorgCode(v);
            }}
            options={orgTreesOptions}
            description=""
          /> */}
          {/* dispStartHour and dispHour */}
          <FormField>
            <FormLayout
              label={(
                <FormLabel
                  label="シフト表示時間"
                />
                        )}
              input={(
                <FlexBox>
                  <div style={{ float: 'left', marginRight: '12px' }}>
                    <HourInput
                      name="dispStartHour"
                      value={formik.values.dispStartHour ? formik.values.dispStartHour : 0}
                      label="時から"
                      onChange={formik.handleChange}
                      min={0}
                      max={23}
                    />
                  </div>
                  <SelectForm
                    label=""
                    name="orgCode"
                    value={String(formik.values.dispHour)}
                    options={[
                      {
                        label: '12',
                        value: '12',
                      },
                      {
                        label: '18',
                        value: '18',
                      },
                      {
                        label: '24',
                        value: '24',
                      },
                    ]}
                    setValue={(hour) => {
                      formik.setFieldValue('dispHour', hour);
                    }}
                    description=""
                  />
                  <div style={{ marginLeft: '12px' }}>
                    <BodyText> 時間 </BodyText>
                  </div>
                </FlexBox>
                                )}
            />
          </FormField>

          {/* mwsDisplayFlag */}
          {/* <FormField>
            <RadioSelectForm
              label="月次シフト表示設定"
              items={[
                {
                  label: mwsDisplayFlagLabel[mwsDisplayFlag.mwsDisplayFlag0],
                  value: '0',
                },
                {
                  label: mwsDisplayFlagLabel[mwsDisplayFlag.mwsDisplayFlag1],
                  value: '1',
                },
              ]}
              name="mwsDisplayFlag"
              value={formik.values.mwsDisplayFlag !== null ? String(formik.values.mwsDisplayFlag) : '0'}
              setValue={(e) => {
                formik.setFieldValue('mwsDisplayFlag', e.target.value);
              }}
            />
          </FormField>
          {String(formik.values.mwsDisplayFlag) === mwsDisplayFlag.mwsDisplayFlag0.toString() && (
            <FormContents>
              <FlexBox>
                <SelectForm
                  label=""
                  name="mwsDisplayStartDow"
                  value={formik.values.mwsDisplayStartDow ? String(formik.values.mwsDisplayStartDow) : '0'}
                  options={[
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow0],
                      value: '0',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow1],
                      value: '1',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow2],
                      value: '2',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow3],
                      value: '3',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow4],
                      value: '4',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow5],
                      value: '5',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow6],
                      value: '6',
                    },
                  ]}
                  setValue={(val) => { formik.setFieldValue('mwsDisplayStartDow', val); }}
                />
                <div style={{ marginLeft: '12px' }}>
                  <BodyText>曜日～1週間表示</BodyText>
                </div>
              </FlexBox>
            </FormContents>
          )}
          {String(formik.values.mwsDisplayFlag) === mwsDisplayFlag.mwsDisplayFlag1.toString() && (
            <FormContents>
              <FlexBox>
                <HourInput
                  name="mwsDisplayStartDay1"
                  value={formik.values.mwsDisplayStartDay1?formik.values.mwsDisplayStartDay1:0}
                  label="日～1ヶ月表示"
                  onChange={formik.handleChange}
                  min={0}
                  max={31}
                />
                <BodyText color={utilityColor.error} size="sm">※「0」を入力すると月末という扱いになります。</BodyText>
              </FlexBox>
            </FormContents>
          )} */}

          {/* hopeShiftLimitFlag*/}
          <FormField>
            <RadioSelectForm
              label="希望シフト提出期限 "
              items={[
                {
                  label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag0],
                  value: '0',
                },
                {
                  label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag1],
                  value: '1',
                },
                {
                  label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag2],
                  value: '2',
                },
                {
                  label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag3],
                  value: '3',
                },
              ]}
              name="hopeShiftLimitFlag"
              value={formik.values.hopeShiftLimitFlag ? String(formik.values.hopeShiftLimitFlag) : '0'}
              setValue={(e) => {
                formik.setFieldValue('hopeShiftLimitFlag', e.target.value);
              }}
            />
          </FormField>

          {/* - hopeShiftLimitFixedDay1 */}
          {String(formik.values.hopeShiftLimitFlag) === hopeShiftLimitFlag.hopeShiftLimitFlag1.toString() && (
          <FormContents>
            <FlexBox>
              <div style={{ marginRight: '12px' }}>
                <BodyText> 前月 </BodyText>
              </div>
              <HourInput
                name="hopeShiftLimitFixedDay1"
                value={formik.values.hopeShiftLimitFixedDay1 ? formik.values.hopeShiftLimitFixedDay1 : 0}
                label="日まで"
                onChange={formik.handleChange}
                min={0}
                max={31}
              />
              <BodyText color={utilityColor.error} size="sm">※「0」を入力すると月末という扱いになります。</BodyText>
            </FlexBox>
          </FormContents>
          )}

          {/* hopeShiftLimitFixedDay1 - hopeShiftLimitFixedDay2*/}
          {String(formik.values.hopeShiftLimitFlag) === hopeShiftLimitFlag.hopeShiftLimitFlag2.toString() && (
          <FormContents>
            <div>
              <FlexBox>
                <div style={{ marginRight: '35px' }}>
                  <BodyText> 1日～15日分シフト </BodyText>
                </div>
                <div style={{ marginRight: '12px' }}>
                  <BodyText> 前月 </BodyText>
                </div>
                <HourInput
                  name="hopeShiftLimitFixedDay1"
                  value={formik.values.hopeShiftLimitFixedDay1 ? formik.values.hopeShiftLimitFixedDay1 : 0}
                  label="日まで"
                  onChange={formik.handleChange}
                  min={0}
                  max={31}
                />
              </FlexBox>
            </div>
            <div style={{ marginTop: '10px' }}>
              <FlexBox>
                <div style={{ marginRight: '30px' }}>
                  <BodyText> 16日～末日分シフト </BodyText>
                </div>
                <div style={{ marginRight: '12px' }}>
                  <BodyText> 当月 </BodyText>
                </div>
                <HourInput
                  name="hopeShiftLimitFixedDay2"
                  value={formik.values.hopeShiftLimitFixedDay2 ? formik.values.hopeShiftLimitFixedDay2 : 0}
                  label="日まで"
                  onChange={formik.handleChange}
                  min={0}
                  max={31}
                />
              </FlexBox>
            </div>

            <BodyText color={utilityColor.error} size="sm">※「0」を入力すると月末という扱いになります。</BodyText>
          </FormContents>
          )}

          {/* hopeShiftWeekStartDow - hopeShiftWeekOffset - hopeShiftWeekLimitDow*/}
          {String(formik.values.hopeShiftLimitFlag) === hopeShiftLimitFlag.hopeShiftLimitFlag3.toString() && (
          <FormContents>
            <FlexBox>
              <div style={{ marginRight: '12px' }}>
                <BodyText>対象シフト</BodyText>
              </div>
              <SelectForm
                label=""
                name="hopeShiftWeekStartDow"
                value={formik.values.hopeShiftWeekStartDow !== null ? String(formik.values.hopeShiftWeekStartDow) : '0'}

                options={[
                  {
                    label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow0],
                    value: '0',
                  },
                  {
                    label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow1],
                    value: '1',
                  },
                  {
                    label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow2],
                    value: '2',
                  },
                  {
                    label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow3],
                    value: '3',
                  },
                  {
                    label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow4],
                    value: '4',
                  },
                  {
                    label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow5],
                    value: '5',
                  },
                  {
                    label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow6],
                    value: '6',
                  },
                ]}
                setValue={(val: string) => { formik.setFieldValue('hopeShiftWeekStartDow', val); }}
              />

              <div style={{ marginLeft: '12px' }}>
                <BodyText>曜日～1週間のシフト</BodyText>
              </div>
            </FlexBox>

            <FlexBox>

              <HourInput
                name="hopeShiftWeekOffset"
                value={formik.values.hopeShiftWeekOffset ? formik.values.hopeShiftWeekOffset : 0}
                label="週前の"
                onChange={formik.handleChange}
                min={0}
                max={5}
              />
              <div style={{ marginLeft: '12px' }}>
                <SelectForm
                  label=""
                  name="hopeShiftWeekLimitDow"
                  value={formik.values.hopeShiftWeekLimitDow !== null ? String(formik.values.hopeShiftWeekLimitDow) : '0'}
                  options={[
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow0],
                      value: '0',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow1],
                      value: '1',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow2],
                      value: '2',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow3],
                      value: '3',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow4],
                      value: '4',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow5],
                      value: '5',
                    },
                    {
                      label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow6],
                      value: '6',
                    },
                  ]}
                  setValue={(val: string) => { formik.setFieldValue('hopeShiftWeekLimitDow', val); }}
                />
              </div>
              <div style={{ marginLeft: '12px' }}>
                <BodyText>曜日まで</BodyText>
              </div>
            </FlexBox>
            <BodyText color={utilityColor.error} size="sm">※週の開始日は日曜とします。「0」週間前とすると、同じ週という扱いになります。</BodyText>

          </FormContents>
          )}

          {/* hopeShiftLimitFlag0  */}
          {String(formik.values.hopeShiftLimitFlag) !== hopeShiftLimitFlag.hopeShiftLimitFlag0.toString() && (
            <FormField>
              <RadioSelectForm
                label="契約シフト自動作成"
                items={[
                  {
                    label: shiftCreateFlagLabel[shiftCreateFlag.shiftCreateFlag0],
                    value: '0',
                  },
                  {
                    label: shiftCreateFlagLabel[shiftCreateFlag.shiftCreateFlag1],
                    value: '1',
                  },
                ]}
                name="shiftCreateFlag"
                value={String(formik.values.shiftCreateFlag)}
                setValue={(e) => {
                  formik.setFieldValue('shiftCreateFlag', e.target.value);
                }}
                note="※希望シフト提出期限後に提出がないスタッフのシフトをシフトパターン設定から自動作成します。"
              />
            </FormField>
          )}

          <FormField>
            <RadioSelectForm
              label="出勤予定者表示機能"
              items={[
                {
                  label: shiftCreateFlagLabel[shiftCreateFlag.shiftCreateFlag0],
                  value: '0',
                },
                {
                  label: shiftCreateFlagLabel[shiftCreateFlag.shiftCreateFlag1],
                  value: '1',
                },
              ]}
              name="shiftStaffDisplayFlag"
              value={String(formik.values.shiftStaffDisplayFlag)}
              setValue={(e) => {
                formik.setFieldValue('shiftStaffDisplayFlag', e.target.value);
              }}
            />
          </FormField>
          {
            roleScreen && roleScreen.editable === 1 && (
            <FormSubmitArea>
              <Button
                text="登録"
                onClick={formik.handleSubmit}
              />
            </FormSubmitArea>
          )}

        </form>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          actionType={ActionType.CREATE}
        />
      </FormContents>
    </div>

  );
};

export default MasterOrganizeSettingLayout;
