import React, { SVGProps } from 'react';

const Comment: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    id="comment_svg___x31_0"
    x={0}
    y={0}
    viewBox="0 0 512 512"
    style={{
      width: 16,
      height: 16
    }}
    xmlSpace="preserve"
    opacity={1}
    width="20px"
    height="20px"
    {...props}
  >
    <style>{'.comment_svg__st0{fill:#0d4796}'}</style>
    <path
      className="comment_svg__st0"
      d="M343.719 167.957H168.282c-6.903 0-12.495 5.596-12.495 12.495s5.592 12.495 12.495 12.495H343.72c6.899 0 12.495-5.596 12.495-12.495s-5.597-12.495-12.496-12.495zM343.719 229.043H168.282c-6.903 0-12.495 5.596-12.495 12.495s5.592 12.491 12.495 12.491H343.72c6.899 0 12.495-5.592 12.495-12.491s-5.597-12.495-12.496-12.495zM268.554 290.126H168.282c-6.903 0-12.495 5.592-12.495 12.494 0 6.899 5.592 12.491 12.495 12.491h100.272c6.899 0 12.495-5.592 12.495-12.491 0-6.902-5.596-12.494-12.495-12.494z"
      fill="#0d4796"
    />
    <path
      className="comment_svg__st0"
      d="M433.214 61.82C385.426 21.954 322.49 0 256 0S126.574 21.954 78.786 61.82C28.147 104.062.262 161.078.262 222.358c0 98.037 74.15 183.937 179.726 212.396l28.889 50.039A54.405 54.405 0 00256 512a54.41 54.41 0 0047.12-27.206l28.892-50.039C437.584 406.296 511.738 320.4 511.738 222.359c0-61.281-27.888-118.297-78.524-160.539zm10.918 220.239c-1.674 3.668-3.434 7.305-5.401 10.848-1.264 2.267-2.72 4.445-4.101 6.658-1.214 1.951-2.338 3.946-3.638 5.846-1.662 2.431-3.519 4.753-5.318 7.11-1.249 1.627-2.404 3.31-3.715 4.898-1.584 1.924-3.34 3.738-5.018 5.608-1.748 1.943-3.422 3.937-5.268 5.814-1.065 1.082-2.243 2.084-3.336 3.142-2.666 2.572-5.315 5.155-8.156 7.586-.094.082-.199.156-.297.238-27.475 23.418-63.091 40.166-103.039 47.034l-27.277 47.248-13.614 23.422-40.798-70.67c-39.881-6.857-75.43-23.562-102.887-46.917-.148-.126-.316-.238-.464-.367-2.728-2.334-5.264-4.819-7.828-7.282-1.21-1.166-2.502-2.267-3.672-3.462-1.71-1.736-3.254-3.586-4.878-5.381-1.819-2.006-3.704-3.965-5.409-6.041-1.186-1.436-2.224-2.958-3.352-4.426-1.932-2.505-3.902-4.986-5.674-7.578-1.206-1.764-2.247-3.622-3.379-5.424-1.471-2.35-3.009-4.663-4.351-7.067-1.927-3.47-3.644-7.036-5.291-10.63-.359-.777-.792-1.522-1.139-2.306-7.863-17.966-12.159-37.364-12.159-57.602 0-92.757 90.138-167.946 201.329-167.946 111.187 0 201.326 75.189 201.326 167.946 0 20.242-4.297 39.643-12.163 57.61-.315.71-.709 1.385-1.033 2.091z"
      fill="#0d4796"
    />
  </svg>
);

export default Comment;
