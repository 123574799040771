import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AttendAchievementLayoutOutput from 'components/organismos/master/attend/AttendAchievementLayoutOutput';

const AttendAchievementOutput: React.FC = () => (
  <SidebarTemplate pageTitle="勤怠実績印刷">
    <AttendAchievementLayoutOutput />
  </SidebarTemplate>
);

export default AttendAchievementOutput;
