/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { utilityColor, } from 'components/styles';
import Table from 'components/molecules/Table';
import PrimaryButton from 'components/atoms/Button';
import moment from 'moment';
import SinglecheckRounded from 'components/atoms/Icon/SinglecheckRounded';
import DropdownContextMenu from 'components/molecules/Dropdown/DropdownContextMenu';
import { IWorkingStatus } from '../../interface';
import Icon from 'components/atoms/Icon';
import IconTooltip from 'components/molecules/IconTooltip';

const headers = [
  '',
  '',
  '日付',
  '出勤状況',
  '出勤店舗',
  '出勤時間',
  '退勤時間',
  '休憩時間',
  '総労働時間',
];

const styles = {
  table: css`
    table {
      margin-top: 15px;
      th {
        white-space: nowrap;
      }
      min-width: 1100px;
    }
    table th:nth-child(1) {
      width: 50px;
    }
    table th:nth-child(2) {
      width: 120px;
    }
    table th:nth-child(3) {
      width: 100px;
    }
    table th:nth-child(4) {
      width: 120px;
    }
    table th:nth-child(5) {
      width: 290px;
    }
    table th:nth-child(6) {
      width: 105px;
    }
    table th:nth-child(7) {
      width: 105px;
    }
    table th:nth-child(8) {
      width: 105px;
    }
    table th:nth-child(9) {
      width: 105px;
    }
    .check {
      svg {
        path:last-child {
          fill: green;
          vertical-align: 'bottom';
        }
      }
    }
    button {
      width: 120px;
    }
  `,
};

const WorkRecord: React.FC<{ list: IWorkingStatus }> = ({ list }) => (
  <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
    <div css={styles.table}>
      <Table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {list.staffStampList
            && list.staffStampList.map((cell, index) => (
              <tr key={index}>
                <Table.Cell>
                  <span className="check">
                    {(cell.attendanceOrgFix === '1'
                        || cell.belongOrgFix === '1') && (
                          <Icon
                            type="singlecheckRounded"
                            color="#34a659"
                            verticalAlign="bottom"
                          />
                        )}
                  </span>
                  <span className="alert" data-tip={cell.errorMsg}>
                    {(
                      cell.errorMsg) && (
                        <IconTooltip
                          iconType="remove"
                          text={ cell.errorMsg || '' }
                          color={ utilityColor.white }
                          customCss={css({ margin: '0' })}
                          inlineBlock={true}
                        />
                    )}
                    {(
                      cell.alertMsg) && (
                        <IconTooltip
                          iconType="warningFill"
                          text={ cell.alertMsg || '' }
                          color={ utilityColor.yellow }
                          customCss={css({ margin: '0' })}
                          inlineBlock={true}
                        />
                    )}
                    {(
                      cell.infoMsg) && (
                        <IconTooltip
                          iconType="warning"
                          text={ cell.infoMsg || '' }
                          color={ utilityColor.yellow }
                          customCss={css({ margin: '0' })}
                          inlineBlock={true}
                        />
                    )}
                  </span>
                </Table.Cell>
                <Table.Cell customStyle={css({ overflow: 'visible' })}>
                  {
                    cell.employmentId == null ? (
                      null
                    ) : (
                      cell.belongOrgFix === '1' ? (
                        <PrimaryButton disabled={true} text="修正申請 ›" />
                      ) : (
                        <DropdownContextMenu
                          text="修正申請 ›"
                          modifierApplicationId={cell.modifierApplicationId}
                          orgCode={cell.orgCode || sessionStorage.getItem('loginUser.orgCode') || ''}
                          staffCode={list.getLoginStaffCcde}
                          staffName={list.getLoginStaffName}
                          modifierApplicationStatus={cell.modifierApplicationStatus}
                          holidayApplicationStatus={cell.holidayApplicationStatus}
                          overworkApplicationStatus={cell.overworkApplicationStatus}
                          holidayworkApplicationStatus={cell.holidayworkApplicationStatus}
                          transferApplicationStatus={cell.transferApplicationStatus}
                          attendanceOrgFix={cell.attendanceOrgFix}
                          useOverTimeApplication={list.employment?.useOverTimeApplication}
                          necessaryHolidayWorkApplication={list.employment?.necessaryHolidayWorkApplication}
                          useTransferApplication={list.employment?.useTransferApplication}
                          achievementId={cell.achievementId}
                          targetDate={cell.targetDate}
                          stampVersion={cell.stampVersion}
                        />
                      )
                    )
                  }

                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {moment(cell.targetDate).format('YYYY/MM/DD')}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>{cell.workStatus}</Table.Cell>
                <Table.Cell>{cell.orgName}</Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>{cell.startTime}</Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>{cell.endTime}</Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {cell.recessTime && `${cell.recessTime}`}
                </Table.Cell>
                <Table.Cell customStyle={css({ textAlign: 'left' })}>
                  {cell.allWorkTime && `${cell.allWorkTime}`}
                </Table.Cell>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  </div>
);
export default WorkRecord;
