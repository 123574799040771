import ApiClient from 'api/ApiClient';
import moment from 'moment';

export const getHqPointedOut = async (
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  status: string,
  categoryAry: any,
)
  : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    loginStaffCode,
    functionType: 3,
    status,
  };

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }

  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/hqPointedOutConfirm/${companyCode}?${appendUrl}`, params);
  return response.data;
};

export const getTableNumberIndicationAnalysis = async (
  companyCode: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  paths: any,
  categoryAry?: any,
):Promise<any> => {
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    loginStaffCode,
    functionType: 3, 
  }; 

  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/${paths}/${companyCode}?${appendUrl}`, params);
return response.data;


}



export const downloadPointedOutCsv = async (data: any, fileName:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsvPost(`/v1/reportExportFile/csv/${companyCode}`, {}, data, fileName);
};

export const downloadPointedOutExcel = async (data: any, fileName:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadExcelPost(`/v1/reportExportFile/excel/${companyCode}`, {}, data, fileName);
};
