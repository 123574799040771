import React, { useState, useEffect } from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import OrganizationCategory from 'components/organismos/master/general/organizationCategory/OrganizationCategory';
import TabControl from 'components/atoms/TabControl';
import BlockUI from 'components/molecules/BlockUi';
import useOrganizationCategoryList from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const OrganizationCategoryPage: React.FC = () => {
  const currentTab = sessionStorage.getItem('currentOrgCatTab') ? sessionStorage.getItem('currentOrgCatTab')! : '1';
  const [value, setValue] = useState<string>(currentTab);
  const {
    organizationCategoryList,
    orgCat,
    setOrgCat,
    fetchData,
    blocking,
  } = useOrganizationCategoryList();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  return (
    <SidebarTemplate pageTitle="組織カテゴリマスタ">
      <BlockUI blocking={blocking}>
        <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingTop: '30px' }}>
          <TabControl
            items={organizationCategoryList.map((m, index) => ({
              value: `${index + 1}`,
              display: m.categoryDispName,
            }))}
            setValue={(val: any) => {
              setValue(val);
              const selectedOrgCat = organizationCategoryList[parseFloat(val) - 1];
              if (
                selectedOrgCat.organizationCategoryMstHeadId !== orgCat?.organizationCategoryMstHeadId
              ) {
                sessionStorage.setItem('currentOrgCatTab', val);
                sessionStorage.setItem('selectedOrganizationCategory', selectedOrgCat.categoryDispName);
                sessionStorage.setItem('organizationCategoryMstHeadId', selectedOrgCat.organizationCategoryMstHeadId);
                setOrgCat(selectedOrgCat);
              }
            }}
            currentValue={value}
          />
        </div>

        <div>
          <OrganizationCategory 
            initialOrganizationCategoryDomain={orgCat} 
            fetchData={fetchData} 
            roleScreen={roleScreen} 
          />
        </div>
      </BlockUI>
    </SidebarTemplate>

  );
};
export default OrganizationCategoryPage;
