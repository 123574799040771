/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// /** @jsx jsx */
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { stateSymbol } from 'components/organismos/master/general/salesPage//SalesManagementList/hookSysboy';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { useDownload } from 'components/organismos/master/general/salesPage/SalesManagementList/hook';
import moment from 'moment';
import { Link } from 'react-router-dom';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import SortIcon from 'components/molecules/SortIcon';
import { grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';

const styles = css({
  fontWeight: 400,
  fontSize: 14,
});

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 1,
});

const formatterPercent = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'percent',
});

const DEFAULT_SORT_FIELD = { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number' } as FieldSortState

const SalesManagementFLListTable: React.FC<{
    listSalesManagement5: any;
    isActiveRow: number;
    isActiveColumn: number;
    handleClickActive: any;
    selectedDateFrom: any;
    selectedDateTo: any;
    orgName: any;
    roleScreen?: any;
  }> = ({
    listSalesManagement5,
    isActiveRow,
    isActiveColumn,
    handleClickActive,
    selectedDateFrom,
    selectedDateTo,
    orgName,
    roleScreen
  }) => {
    const listData = useMemo(() => listSalesManagement5.slice(0, -1), [listSalesManagement5])
    const totalData = useMemo(() => listSalesManagement5.slice(-1), [listSalesManagement5])

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(listData, DEFAULT_SORT_FIELD);

    const searchResult: any[] = [];
    const totalInformation: any[] = [];
    const { downloadCsv5, downloadExcel5 } = useDownload();

    const headerInformation = [
      ['', '', '売上','','','','','','L (累計)', '', '','L (日次)','','','','','F','','','','',''],
      ['店舗', '並び順', '売上(税抜)', '予算', '達成率(%)','累計売上', '累計予算','累計達成率','累計人件費','累計PA人件費','累計PA人件費率','日次人件費率','社員人件費','社員人件費率','PA人件費','PA人件費率','累計Fコスト','日次Fコスト','ドリンク','ドリンク率','フード','フード率'],
    ];

    const data = {
      footer: '',
      formName: '営業管理【FL詳細】',
      targetPeriod: `対象期間：${moment(selectedDateFrom).format('YYYY-MM-DD')} ~ ${moment(selectedDateTo).format('YYYY-MM-DD')}`,
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: totalInformation,
    };

    let mergedRegions: any[] = [];
    const codeColumnIndex: any[] = [];
    const dataExcel = {
      formName: '営業管理【FL詳細】',
      targetPeriod: `対象期間: ${moment(selectedDateFrom).format('YYYY-MM-DD')} ~ ${moment(selectedDateTo).format('YYYY-MM-DD')}`,
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: totalInformation,
      mergedRegions,
      codeColumnIndex,
    };

    const handleExportCSV = () => downloadCsv5(data);

    const handleExportExcel = () => {
      if (mergedRegions) {
        mergedRegions = [];
      }
      mergedRegions.push([3, 3, 2, 7]);
      mergedRegions.push([3, 3, 8, 10]);
      mergedRegions.push([3, 3, 11, 15]);
      mergedRegions.push([3, 3, 16, 21]);
      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [0];
      dataExcel.headerInformation = headerInformation;
      return downloadExcel5(dataExcel);
    };

    useEffect(() => {
      if (listSalesManagement5.length > 0) {
        dataSort.map((list5detail: any, index: any) => {
          searchResult.push([
            (stateSymbol as any)[list5detail.status] + list5detail.orgName,
              list5detail.dispOrder,
              formatter.format(list5detail.netSales),
              formatter.format(list5detail.salesBudget),
              formatter.format(list5detail.salesAchievementRate)+'%',
              formatter.format(list5detail.cumulativeSales),
              formatter.format(list5detail.cumulativeSalesBudget),
              formatter.format(list5detail.cumulativeSalesAchievementRate)+'%',
              formatter.format(list5detail.cumulativeLaborAchievementRate)+'%',
              formatter.format(list5detail.cumulative_part_salary),
              formatter.format(list5detail.cumulativePartLaborAchievementRate)+'%',
              formatter.format(list5detail.laborAchievementRate)+'%',
              formatter.format(list5detail.employeeSalary),
              formatter.format(list5detail.employeeLaborAchievementRate)+'%',
              formatter.format(list5detail.partSalary),
              formatter.format(list5detail.partLaborAchievementRate)+'%',
              formatter.format(list5detail.cumulativeFoodDrink),
              formatter.format(list5detail.foodDrinkAchievementRate)+'%',
              formatter.format(list5detail.drinkCost),
              formatter.format(list5detail.drinkAchievementRate)+'%',
              formatter.format(list5detail.foodCost),
              formatter.format(list5detail.foodAchievementRate)+'%',
          ])
        })
        totalData.map((list5detail: any, index: any) => {
          totalInformation.push([
              '合計',
              '',
              formatter.format(list5detail.netSales),
              formatter.format(list5detail.salesBudget),
              formatter.format(list5detail.salesAchievementRate)+'%',
              formatter.format(list5detail.cumulativeSales),
              formatter.format(list5detail.cumulativeSales),
              formatter.format(list5detail.cumulativeSalesAchievementRate)+'%',
              formatter.format(list5detail.cumulativeLaborAchievementRate)+'%',
              formatter.format(list5detail.cumulative_part_salary),
              formatter.format(list5detail.cumulativePartLaborAchievementRate)+'%',
              formatter.format(list5detail.laborAchievementRate)+'%',
              formatter.format(list5detail.employeeSalary),
              formatter.format(list5detail.employeeLaborAchievementRate)+'%',
              formatter.format(list5detail.partSalary),
              formatter.format(list5detail.partLaborAchievementRate)+'%',
              formatter.format(list5detail.cumulativeFoodDrink),
              formatter.format(list5detail.foodDrinkAchievementRate)+'%',
              formatter.format(list5detail.drinkCost),
              formatter.format(list5detail.drinkAchievementRate)+'%',
              formatter.format(list5detail.foodCost),
              formatter.format(list5detail.foodAchievementRate)+'%',
          ])
        })
      }
    }, [listSalesManagement5, dataSort, totalData]);

    const renderHeaderColumn = useCallback((displayName: string, columnSortField: FieldSortState, rowSpan: number, className: string = "") => (
    <th rowSpan={rowSpan} className={className} onClick={e => sortByFieldPath(columnSortField.fieldPath, columnSortField.sortType)}>
      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
        <span style={{ margin: 'auto' }}>{displayName}</span>
        {sortField.fieldPath === columnSortField.fieldPath && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
        {sortField.fieldPath !== columnSortField.fieldPath && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
      </div>
    </th>
    ), [sortField]);

    return  (
      <div>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportCSV}
                iconType="download"
                text=" CSV出力"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportExcel}
                iconType="download"
                text=" EXCEL出力"
              />
            </FlexBoxItem>
          </FlexBox>
        )}
        <div
          className="table-responsive"
          style={{
            display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', marginTop: 20, maxHeight: 600,
          }}
        >
          <table id="salesPageForm" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }}>
            <thead>
              <tr role="row">
                <th colSpan={2}/>
                <th colSpan={6}>売上</th>
                <th colSpan={3}>L (累計)</th>
                <th colSpan={5}>L (日次)</th>
                <th colSpan={6}>F</th>
              </tr>
              <tr className="subThead">
                { renderHeaderColumn('店舗', { fieldPath: 'orgName', sort: 'asc', sortType: 'string'}, 1) }
                { renderHeaderColumn('並び順', { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number'}, 1) }
                { renderHeaderColumn('売上(税抜)', { fieldPath: 'netSales', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('予算', { fieldPath: 'salesBudget', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('達成率(%)', { fieldPath: 'salesAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('累計売上', { fieldPath: 'cumulativeSales', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('累計予算', { fieldPath: 'cumulativeSalesBudget', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('累計達成率', { fieldPath: 'cumulativeSalesAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('累計人件費', { fieldPath: 'cumulativeLaborAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('累計PA人件費', { fieldPath: 'cumulativePartSalary', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('累計PA人件費率', { fieldPath: 'cumulativePartLaborAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('日次人件費率', { fieldPath: 'laborAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('社員人件費', { fieldPath: 'employeeSalary', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('社員人件費率', { fieldPath: 'employeeLaborAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('PA人件費', { fieldPath: 'partSalary', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('PA人件費率', { fieldPath: 'partLaborAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('累計Fコスト', { fieldPath: 'cumulativeFoodDrink', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('日次Fコスト', { fieldPath: 'foodDrinkAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('ドリンク', { fieldPath: 'drinkCost', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('ドリンク率', { fieldPath: 'drinkAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('フード', { fieldPath: 'foodCost', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
                { renderHeaderColumn('フード率', { fieldPath: 'foodAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              </tr>
            </thead>
              {
                listSalesManagement5 && listSalesManagement5.length > 0 ? [...dataSort, ...totalData].map((list5detail: any, index: number) => {
                  return (
                    (index < listSalesManagement5.length - 1)
                      ?
                        <tbody key={index} css={styles}>
                          <tr className={isActiveRow === index ? 'activeClickFrames' : ''}>
                            <td
                              className={`textLeft ${isActiveColumn === 1 ? 'activeClickFrames' : ''}`}
                              onClick={() => { handleClickActive(index, 1); }}
                            >
                              {
                                list5detail.status !== 99
                                ? <Link target={"_blank"} to={{ pathname: `/salesDailyReport/${list5detail.orgCode}/${moment(selectedDateTo).format('YYYY-MM-DD')}` }}>
                                  {(stateSymbol as any)[list5detail.status]}
                                  </Link>
                                :  (stateSymbol as any)[list5detail.status]
                              }
                              {list5detail.orgName}
                            </td>
                            <td
                              className={isActiveColumn === 2 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 2); }}
                            >
                              {formatter.format(list5detail.dispOrder)}
                            </td>
                            <td
                              className={isActiveColumn === 3 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 3); }}
                            >
                              {formatter.format(list5detail.netSales)}
                            </td>
                            <td
                              className={isActiveColumn === 4 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 4); }}
                            >
                              {formatter.format(list5detail.salesBudget)}
                            </td>
                            <td
                              className={isActiveColumn === 5 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 5); }}
                            >
                              {formatter.format(list5detail.salesAchievementRate)+'%'}
                            </td>
                            <td
                              className={isActiveColumn === 6 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 6); }}
                            >
                              {formatter.format(list5detail.cumulativeSales)}
                            </td>
                            <td
                              className={isActiveColumn === 7 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 7); }}
                            >
                              {formatter.format(list5detail.cumulativeSalesBudget)}
                            </td>
                            <td
                              className={isActiveColumn === 8 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 8); }}
                            >
                              {formatter.format(list5detail.cumulativeSalesAchievementRate)+'%'}
                            </td>
                            <td
                              className={isActiveColumn === 9 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 9); }}
                            >
                              {formatter.format(list5detail.cumulativeLaborAchievementRate)+'%'}
                            </td>
                            <td
                              className={isActiveColumn === 10 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 10); }}
                            >
                              {formatter.format(list5detail.cumulativePartSalary)}
                            </td>
                            <td
                              className={isActiveColumn === 11 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 11); }}
                            >
                              {formatter.format(list5detail.cumulativePartLaborAchievementRate)+'%'}
                            </td>
                            <td
                              className={isActiveColumn === 12 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 12); }}
                            >
                              {formatter.format(list5detail.laborAchievementRate)+'%'}
                            </td>
                            <td
                              className={isActiveColumn === 13 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 13); }}
                            >
                              {formatter.format(list5detail.employeeSalary)}
                            </td>
                            <td
                              className={isActiveColumn === 14 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 14); }}
                            >
                              {formatter.format(list5detail.employeeLaborAchievementRate)+'%'}
                            </td>
                            <td
                              className={isActiveColumn === 15 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 15); }}
                            >
                              {formatter.format(list5detail.partSalary)}
                            </td>
                            <td
                              className={isActiveColumn === 16 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 16); }}
                            >
                              {formatter.format(list5detail.partLaborAchievementRate)+'%'}
                            </td>
                            <td
                              className={isActiveColumn === 17 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 17); }}
                            >
                              {formatter.format(list5detail.cumulativeFoodDrink)}
                            </td>
                            <td
                              className={isActiveColumn === 18 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 18); }}
                            >
                              {formatter.format(list5detail.foodDrinkAchievementRate)+'%'}
                            </td>
                            <td
                              className={isActiveColumn === 19 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 19); }}
                            >
                              {formatter.format(list5detail.drinkCost)}
                            </td>
                            <td
                              className={isActiveColumn === 20 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 20); }}
                            >
                              {formatter.format(list5detail.drinkAchievementRate)+'%'}
                            </td>
                            <td
                              className={isActiveColumn === 21 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 21); }}
                            >
                              {formatter.format(list5detail.foodCost)}
                            </td>
                            <td
                              className={isActiveColumn === 22 ? 'activeClickFrames' : ''}
                              onClick={() => { handleClickActive(index, 22); }}
                            >
                              {formatter.format(list5detail.foodAchievementRate)+'%'}
                            </td>
                          </tr>
                        </tbody>
                      : (
                        <tfoot key={index}>
                          <tr className="custom_total">
                            <td className="textLeft">合計</td>
                            <td></td>
                            <td>{formatter.format(list5detail.netSales)}</td>
                            <td>{formatter.format(list5detail.salesBudget)}</td>
                            <td>{formatter.format(list5detail.salesAchievementRate)+'%'}</td>
                            <td>{formatter.format(list5detail.cumulativeSales)}</td>
                            <td>{formatter.format(list5detail.cumulativeSales)}</td>
                            <td>{formatter.format(list5detail.cumulativeSalesAchievementRate)+'%'}</td>
                            <td>{formatter.format(list5detail.cumulativeLaborAchievementRate)+'%'}</td>
                            <td>{formatter.format(list5detail.cumulativePartSalary)}</td>
                            <td>{formatter.format(list5detail.cumulativePartLaborAchievementRate)+'%'}</td>
                            <td>{formatter.format(list5detail.laborAchievementRate)+'%'}</td>
                            <td>{formatter.format(list5detail.employeeSalary)}</td>
                            <td>{formatter.format(list5detail.employeeLaborAchievementRate)+'%'}</td>
                            <td>{formatter.format(list5detail.partSalary)}</td>
                            <td>{formatter.format(list5detail.partLaborAchievementRate)+'%'}</td>
                            <td>{formatter.format(list5detail.cumulativeFoodDrink)}</td>
                            <td>{formatter.format(list5detail.foodDrinkAchievementRate)+'%'}</td>
                            <td>{formatter.format(list5detail.drinkCost)}</td>
                            <td>{formatter.format(list5detail.drinkAchievementRate)+'%'}</td>
                            <td>{formatter.format(list5detail.foodCost)}</td>
                            <td>{formatter.format(list5detail.foodAchievementRate)+'%'}</td>
                          </tr>
                        </tfoot>
                      )
                  )
                }) : <tbody css={styles}></tbody>
              }
          </table>
        </div>
      </div>
   );
  }
export default SalesManagementFLListTable;
