/**
 * API 有給パターンマスタ
 */
export interface PaidGrantPattern{
    paidHolidayPatternId: string,
    paidHolidayPatternCode: string,
    paidHolidayPatternName: string,
    grantStartMonths: number
}
export default class PaidGrantPatternDomain {
  constructor(private rowData: PaidGrantPattern) {
    //
  }

  static generateInitial(): PaidGrantPatternDomain {
    return new PaidGrantPatternDomain({
      paidHolidayPatternId: '',
      paidHolidayPatternCode: '',
      paidHolidayPatternName: '',
      grantStartMonths: 0,

    });
  }

  getRowData(): PaidGrantPattern {
    return this.rowData;
  }

  get paidHolidayPatternId(): string {
    return this.rowData.paidHolidayPatternId;
  }

  set paidHolidayPatternId(paidHolidayPatternId: string) {
    this.rowData.paidHolidayPatternId = paidHolidayPatternId;
  }

  get paidHolidayPatternCode(): string {
    return this.rowData.paidHolidayPatternCode;
  }

  set paidHolidayPatternCode(paidHolidayPatternCode: string) {
    this.rowData.paidHolidayPatternCode = paidHolidayPatternCode;
  }

  get paidHolidayPatternName() {
    return this.rowData.paidHolidayPatternName;
  }

  set paidHolidayPatternName(paidHolidayPatternName: string) {
    this.rowData.paidHolidayPatternName = paidHolidayPatternName;
  }

  get grantStartMonths(): number {
    return this.rowData.grantStartMonths;
  }

  set grantStartMonths(grantStartMonths: number) {
    this.rowData.grantStartMonths = grantStartMonths;
  }

  get grantStartMonthsFull(): string {
    return `${this.rowData.grantStartMonths}ヶ月目`;
  }
}
