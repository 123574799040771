/** @jsx jsx */
import React, {
  createRef, useMemo, useState, useEffect, useRef, ReactNode,
} from 'react';
import { css, jsx } from '@emotion/core';
import Button from 'components/atoms/Button';
import FormContents from 'components/atoms/Form/FormContents';
import { Link } from 'react-router-dom';
import {useSelector} from "react-redux";
import {Store} from "../../../modules/store";

const ScrollMenuComponent: React.FC<{
  children: any;
  items: any,
  buttonSubmit?: boolean,
  handleButtonSubmit?: any;
  renderElementByAddOrRemove?: any,
  openDetailForm?: any;
  setOpenDetailForm?: any;
  indexHiddenContent?: number;
  roleScreen?: any;
  isEditMode?: boolean;
}> = ({
  children,
  items,
  buttonSubmit = false,
  handleButtonSubmit,
  renderElementByAddOrRemove,
  openDetailForm,
  setOpenDetailForm,
  indexHiddenContent,
  roleScreen,
  isEditMode = false,
}) => {
    const [active, setActive] = useState(0);

    const [pageYRef, setPageYRef] = useState<any>();

    const [stateScroll, setStateScroll] = useState(0);

    const ref = createRef<any | null>();

    const scrollDiv = useMemo(() => items.map(() => createRef<HTMLInputElement>()), [items]);

    const compareMaxNearest = (getBoundingClientRect: any) => {
      let flagCompare = 0;
      let flagIndex = 0;
      if (pageYRef !== undefined) {
        pageYRef.map((item: number, index: number) => {
          flagCompare = item;
          if (flagCompare <= Math.abs(getBoundingClientRect.y)) {
            flagIndex = index + 1;
          }
        });
      }
      setActive(flagIndex);
    };

    const scrollToContent = (scrollDiv: any, index: number) => {
      scrollDiv[index] && scrollDiv[index].current.scrollIntoView({ behavior: 'auto', block: 'start' });
      setActive(index);
    };

    const handleScroll = () => {
      if (!ref.current) return;
      const mainObj = document.getElementsByTagName('main');
      const { scrollTop } = mainObj[0];
      const { offsetHeight } = mainObj[0];
      const { scrollHeight } = mainObj[0];
      if (ref.current.getBoundingClientRect().y !== null) {
        if (scrollTop + offsetHeight < scrollHeight) {
          compareMaxNearest(ref.current.getBoundingClientRect());
        } else {
          setActive(items.length - 1);
        }
      }
      if (stateScroll === 0) {
        setStateScroll(1);
      }
    };

    useEffect(() => {
      const arrScrollDiv: any = [];
      let pageYRefTotal = 0;
      scrollDiv.map((scroll: any) => {
        pageYRefTotal += scroll.current.scrollHeight;
        arrScrollDiv.push(pageYRefTotal);
      });
      setPageYRef(arrScrollDiv);
    }, [scrollDiv, stateScroll, renderElementByAddOrRemove]);

    useEffect(() => {
      window.addEventListener('scroll', handleScroll, true);

      return () => window.removeEventListener('scroll', handleScroll);
    });

    const stateRoleScreen = useSelector((state: Store) => state.auth.roleScreen);
    const userRole = roleScreen ? (stateRoleScreen ? stateRoleScreen : null) : roleScreen;

    return (
      <React.Fragment>
        <ul style={{
          listStyle: 'none', width: '8.5%', position: 'fixed', top: '100px', right: '30px', zIndex: 999,
        }}
        >
          {
            items.length > 0 && items.map((item: any, index: number) => (
              <li
                style={{
                  padding: '5px', cursor: 'pointer', background: active === index ? '#0D4796' : '#c4c4c4', color: active === index ? '#ffffff' : '#000000', borderBottom: '1px solid #ffffff',
                }}
                onClick={() => scrollToContent(scrollDiv, index)}
              >
                {item}
              </li>
            ))
          }
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", margin: '15px 0', padding: '0 5px' }}>
          {
            buttonSubmit && userRole && userRole.editable === 1 && (
              <Button
                text={isEditMode ? '更新' : '登録'}
                onClick={handleButtonSubmit}
              />
            )
          }
          <Link to="/organizations">
            <Button
              text="戻る"
              ghost={true}
            />
          </Link>
          </div>
            
        </ul>
        <div ref={ref} style={{ width: '90%' }}>
          {
            items && items.length > 0 && items.map((item: any, index: number) => {
              return (
                <React.Fragment>
                  <div key={item} ref={scrollDiv[index]} style={{ paddingTop: '40px' }}>
                    <div style={{
                      background: '#0D4796', color: '#ffffff', fontWeight: 'bold', padding: '10px',
                    }}
                    >
                      {item}
                    </div>
                    <FormContents>{children[index] ? children[index] : (children[index + 1] ? children[index + 1] : null )}</FormContents>
                    {
                      indexHiddenContent === index && openDetailForm === false && (
                        <div style={{ margin: '15px 0' }}>
                          <Button
                            text="詳細情報を入力する"
                            onClick={() => setOpenDetailForm(true)}
                            fullwidth={true}
                          />

                        </div>
                      )
                    }
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
      </React.Fragment>
    );
  };
export default ScrollMenuComponent;
