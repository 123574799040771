/**
 * API 値引種別
 */
export interface Discount {
  id: {
    companyCode:string,
    discountTypeMstCode: string,
  },
  discountTypeMstName: string,
  dispOrder: string,
  // paymentMediaCategoryCode: string,
  createUser: string,
  updateUser: string,
}

export default class DiscountTypeDomain {
  constructor(private rawData: any) {
    this.rawData = {
      ...rawData,
      id: {
        companyCode: rawData[0],
        discountTypeMstCode: rawData[1],
      },
      discountTypeMstName: rawData[2],
      dispOrder: rawData[3],
      createUser: rawData.createUser,
      updateUser: rawData.updateUser,
    };
  }

  static generateInitial(): DiscountTypeDomain {
    return new DiscountTypeDomain({
      id: {
        companyCode: '',
        discountTypeMstCode: '',
      },
      discountTypeMstName: '',
      dispOrder: '',
      createUser: '',
      updateUser: '',
    });
  }

  getRawData(): Discount {
    return this.rawData;
  }

  get companyCode(): string {
    return this.rawData.id.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.id.companyCode = companyCode;
  }

  get discountTypeMstCode(): string {
    return this.rawData.id.discountTypeMstCode;
  }

  set discountTypeMstCode(discountTypeMstCode: string) {
    this.rawData.id.discountTypeMstCode = discountTypeMstCode;
  }

  get discountTypeMstName(): string {
    return this.rawData.discountTypeMstName;
  }

  set discountTypeMstName(discountTypeMstName: string) {
    this.rawData.discountTypeMstName = discountTypeMstName;
  }

  get dispOrder(): string {
    return this.rawData.dispOrder;
  }

  set dispOrder(dispOrder: string) {
    this.rawData.dispOrder = dispOrder;
  }
}
