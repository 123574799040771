import ApiClient from './ApiClient';

export type Role = {
  roleId: string,
  companyCode: string,
  roleName: string,
  administrator: boolean,
  allOrganizationAccess: boolean,
  allStaffAccess: boolean,
  retired: boolean,
  paidControl: boolean,
  accountingRole: boolean,
  attendanceRole: boolean,
  createDate: Date,
  updateDate: Date,
}

export type AllRole = {
  hqRoleList : Array<Role>,
  shopRoleList: Array<Role>,
  personalRoleList: Array<Role>,
}

export const getAllRoles = async (): Promise<AllRole> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/roles/all/${companyCode}`, {});
  return response.data;
};


export default getAllRoles;
