/** @jsx jsx */
import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { grayScale, utilityColor, textFontSize } from 'components/styles';
import Icon from 'components/atoms/Icon';
import useComponentVisible from 'hooks/useComponentVisible';
import DropdownBox from 'components/molecules/Dropdown/DropdownBox';
import DropdownItem, { DropdownItemProps } from 'components/molecules/Dropdown/DropdownItem';
import CircleIconButton from 'components/molecules/CircleIconButton';

const styles = {
  dropdown_center: css({
    position: 'relative',
    textAlign: 'center',
  }),
  dropdown: css({
    position: 'relative',
    textAlign: 'left',
  }),
  dropdownButton: css({
    display: 'inline',
    cursor: 'pointer',
    border: 'none',
    padding: '0 4px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    backgroundColor: 'transparent',
  }),
};

const Dropdown: React.FC<{
  items: Array<DropdownItemProps>;
  setValue?: (value: number|string) => void;
  isIconButton?: boolean,
  disabled?:boolean,
  isDropdownDisplayed?:boolean,
  isTextCenter?:boolean,
  isScroll?: boolean,
  setScroll?: any,
  fixedDropDown?: boolean,
}> = ({
  children,
  items,
  setValue,
  isIconButton = false,
  disabled = false,
  isDropdownDisplayed,
  isTextCenter = false,
  isScroll = false,
  setScroll,
  fixedDropDown = false,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const onClickHandler = React.useCallback((value: number|string) => {
    if (setValue) setValue(value);
    setIsComponentVisible(true);
  }, [setIsComponentVisible, setValue]);

  useEffect(() => {
    if (isScroll === false) {
      setIsComponentVisible(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScroll]);

  return (
    <div css={isTextCenter ? styles.dropdown_center : styles.dropdown} ref={ref}>
      {isIconButton
        ? (
          <CircleIconButton
            iconType="dropdown"
            iconColor={grayScale.gray100}
            borderColor={utilityColor.transparent}
            color={grayScale.gray10}
            onClick={() => {
              setIsComponentVisible(!isComponentVisible);
              setScroll && setScroll(true);
            }}
          />
        )
        : (
          <button
            type="button"
            onClick={() => {
              setIsComponentVisible(!isComponentVisible);
              setScroll && setScroll(true);
            }}
            css={styles.dropdownButton}
          >
            {children}
            {isDropdownDisplayed ? <Icon type="dropdown" color={grayScale.gray100} /> : '' }
          </button>
        )}

      <DropdownBox isVisible={isComponentVisible} fixedDropDown={fixedDropDown}>
        {items.map((item) => (
          <DropdownItem
            key={item.value}
            value={item.value}
            label={item.label}
            linkTo={item.linkTo}
            disabled={disabled}
            onClick={disabled ? () => { } : () => {
              onClickHandler(item.value);
              if (item.onClick) item.onClick();
            }}
          />
        ))}
      </DropdownBox>
    </div>
  );
};

export default Dropdown;
