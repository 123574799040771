import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesExpensesMSTImportLayout from "./salesExpensesMSTImportLayout";

const SalesExpensesMSTImportLayoutPage = () => (
    <SidebarTemplate pageTitle="その他経費インポート">
        <SalesExpensesMSTImportLayout />
    </SidebarTemplate>
);
export default SalesExpensesMSTImportLayoutPage;
