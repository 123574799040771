/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { css, jsx } from "@emotion/core";
import { isMacOs } from "react-device-detect";
import moment from "moment";

const styles = css({
  fontWeight: 400,
  fontSize: 14
});

const formatter = new Intl.NumberFormat("ja-JP", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

const formatterPercent = (number: number) => {
  return formatter.format(number) + "%";
};

const IncomeExpenditureListTable: React.FC<{
  listIncomeExpenditure: any;
  year: number;
  orgCode: string | null;
  orgName: string | null;
}> = ({ listIncomeExpenditure, year, orgCode, orgName }) => {
  const [checkScrollBar, setCheckScrollBar] = useState(0);

  useEffect(() => {
    const offsetWidth =
      document.getElementById("wrapContent")?.offsetWidth || 0;
    const clientWidth =
      document.getElementById("wrapTblRight")?.offsetWidth || 0;
    if (offsetWidth - (clientWidth + 300) === 0) {
      setCheckScrollBar(0);
    } else {
      setCheckScrollBar(1);
    }
    console.log("width", offsetWidth, clientWidth + 300);
  }, [listIncomeExpenditure]);

  const eventScroll = useCallback((e: { target: any }) => {
    const { target } = e;
    const wrapTblRightHead = document.getElementById("wrapTblRightHead");
    const wrapTblRight = document.getElementById("wrapTblRight");
    const jsScrollAction = document.getElementById("jsScrollAction");
    if (wrapTblRight) {
      wrapTblRight.scrollLeft = target.scrollLeft;
    }
    if (jsScrollAction) {
      jsScrollAction.scrollLeft = target.scrollLeft;
    }
    if (wrapTblRightHead) {
      wrapTblRightHead.scrollLeft = target.scrollLeft;
    }
  }, []);

  const widthScrollbar = useMemo(() => 24 * 200, []);

  const monthlyDataFilterNull = listIncomeExpenditure.filter(function(el: any) {
    return el != null;
  });

  const monthlyHeader =
    monthlyDataFilterNull && monthlyDataFilterNull.length > 0
      ? monthlyDataFilterNull.map((item: any) => {
          return {
            month: moment(item.targetMonth).format("YYYY年MM月")
          };
        })
      : "";

  // 人件費＞社員
  const listEmployeeLaborCost =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.employeeLaborCost,
        rate: item.employeeLaborCostRate
      };
    });

  // 人件費＞アルバイト
  const listPartSalary =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.partSalary,
        rate: item.partSalaryRate
      };
    });

  // 人件費＞アルバイト調整額
  const listPartAdjustCost =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.partAdjustCost,
        rate: item.partAdjustCostRate
      };
    });

  // 人件費＞人件費合計率
  const listTotalLaborCost =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalLaborCost,
        rate: item.totalLaborCostRate
      };
    });

  // 人件費(退職者)
  const listRetireLaborCost =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.retireLaborCost,
        rate: item.retireLaborCostRate
      };
    });

  // 食材合計
  const listFoodTotal =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.foodTotal,
        rate: item.foodTotalRate
      };
    });

  // 飲材合計
  const listDrinkTotal =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.drinkTotal,
        rate: item.drinkTotalRate
      };
    });

  // 前月棚卸額
  const listTermStart =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.termStart,
        rate: item.termStartRate
      };
    });

  // 当月棚卸額
  const listTermEnd =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.termEnd,
        rate: item.termEndRate
      };
    });

  // 仕入原材料合計
  const listTotalPurchase =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalPurchase,
        rate: item.totalPurchaseRate
      };
    });

  // 通信費合計
  const listTotalCommunicationCost =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalCommunicationCost,
        rate: item.totalCommunicationCostRate
      };
    });

  // 宣伝費合計
  const listTotalAdvertisingCost =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalAdvertisingCost,
        rate: item.totalAdvertisingCostRate
      };
    });

  // 募集費合計
  const listTotalRecruitmentCost =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalRecruitmentCost,
        rate: item.totalRecruitmentCostRate
      };
    });

  // 諸経費合計
  const listTotalExpenses =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalExpenses,
        rate: item.totalExpensesRate
      };
    });

  // 光熱費合計
  const listTotalUtilityCosts =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalUtilityCosts,
        rate: item.totalUtilityCostsRate
      };
    });

  //家賃合計
  const listTotalLandRent =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalLandRent,
        rate: item.totalLandRentRate
      };
    });

  // 決済売上(税抜)
  const listTotalCcAmount =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalCcAmount,
        rate: item.totalCcAmountRate
      };
    });

  // 手数料
  const listCcFee =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.ccFee,
        rate: item.ccFeeRate
      };
    });

  // 減価償却費
  const listDepreciation =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.depreciation,
        rate: item.depreciationRate
      };
    });

  // 賞与償却費
  const listBonusDepreciation =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.bonusDepreciation,
        rate: item.bonusDepreciationRate
      };
    });

  // 修繕費
  const listRepairCosts =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.repairCosts,
        rate: item.repairCostsRate
      };
    });

  // 共通費
  const listCommonFee =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.commonFee,
        rate: item.commonFeeRate
      };
    });

  // 支出総計
  const listTotalExpenditure =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalExpenditure,
        rate: item.totalExpenditureRate
      };
    });

  // POS売上
  const listNetSales =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return { total: item.netSales };
    });

  console.log("listNetSales", listNetSales);

  //その他売上
  const listOtherNetSales =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return { total: item.otherNetSales };
    });

  //売上合計
  const listTotalNetSales =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return { total: item.totalNetSales };
    });

  // 予算/達成率
  const listSalesBudgetTotal =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.salesBudgetTotal,
        rate: item.salesAchievementRate
      };
    });

  // FLコスト/率
  const listTotalFlCost =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalFlCost,
        rate: item.totalFlRate
      };
    });

  //FLAコスト/率
  const listTotalFlaCost =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalFlaCost,
        rate: item.totalFlaRate
      };
    });

  //FLARコスト/率
  const listTotalFlarCost =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalFlarCost,
        rate: item.totalFlarRate
      };
    });

  //FLAROコスト/率
  const listTotalFlaroCost =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.totalFlaroCost,
        rate: item.totalFlaroRate
      };
    });

  console.log("listTotalFlaroCost", listTotalFlaroCost);

  // 営業利益/率
  const listOrdinaryIncome =
    monthlyDataFilterNull &&
    monthlyDataFilterNull.map((item: any, index: number) => {
      return {
        total: item.ordinaryIncome,
        rate: item.profitRate
      };
    });

  console.log("listOrdinaryIncome", listOrdinaryIncome);

  const monthData: any = [];
  monthData.push(
    listEmployeeLaborCost,
    listPartSalary,
    listPartAdjustCost,
    listTotalLaborCost,
    listRetireLaborCost,
    listFoodTotal,
    listDrinkTotal,
    listTermStart,
    listTermEnd,
    listTotalPurchase,
    listTotalCommunicationCost,
    listTotalAdvertisingCost,
    listTotalRecruitmentCost,
    listTotalExpenses,
    listTotalUtilityCosts,
    listTotalLandRent,
    listTotalCcAmount,
    listCcFee,
    listDepreciation,
    listBonusDepreciation,
    listRepairCosts,
    listCommonFee,
    listTotalExpenditure,
    listNetSales,
    listOtherNetSales,
    listTotalNetSales,
    listOrdinaryIncome,
    listSalesBudgetTotal,
    listTotalFlCost,
    listTotalFlaCost,
    listTotalFlarCost,
    listTotalFlaroCost
  );

  console.log("monthData", monthData);

  return (
    <div style={{ marginTop: "20px" }}>
      <div style={{ float: "left", width: "300px" }}>
        <table className="incomeExpenditureTable">
          <thead>
            <tr>
              <th colSpan={2}>{orgCode + " " + orgName}</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        style={{
          float: "left",
          width: isMacOs
            ? !checkScrollBar
              ? "calc(100% - 300px)"
              : "calc(100% - 315px)"
            : "calc(100% - 317px)",
          overflowX: "hidden"
        }}
        id="wrapTblRightHead"
        onScroll={eventScroll}
      >
        <table className="incomeExpenditureTable incomeExpenditureTableSecond">
          <thead>
            <tr>
              {monthlyHeader.length > 0 &&
                monthlyHeader.map((item: any, index: number) => {
                  return <th>{index === 0 ? `${year}年 合計` : item.month}</th>;
                })}
            </tr>
          </thead>
        </table>
      </div>
      <div style={{ clear: "both" }}></div>
      <div
        className="incomeExpenditureWrapper"
        id="wrapContent"
        style={{
          overflowY: "auto",
          height: "calc(100vh - 300px)",
          position: "relative",
          top: 0,
          overflowX: "hidden"
        }}
      >
        <div style={{ float: "left", width: "300px" }}>
          <table className="incomeExpenditureTable">
            <tbody style = {{fontWeight: 'bold'}}>
              <tr>
                <td rowSpan={4}>人件費</td>
                <td>社員</td>
              </tr>
              <tr>
                <td>アルバイト</td>
              </tr>
              <tr>
                <td>アルバイト調整額</td>
              </tr>
              <tr>
                <td>人件費合計</td>
              </tr>
              <tr>
                <td colSpan={2}>人件費(退職者)</td>
              </tr>
              <tr>
                <td rowSpan={5}>仕入れ</td>
                <td>食材合計</td>
              </tr>
              <tr>
                <td>飲材合計</td>
              </tr>
              <tr>
                <td>前月棚卸額</td>
              </tr>
              <tr>
                <td>当月棚卸額</td>
              </tr>
              <tr>
                <td>仕入原材料合計</td>
              </tr>
              <tr>
                <td colSpan={2}>通信費合計</td>
              </tr>
              <tr>
                <td colSpan={2}>宣伝費合計</td>
              </tr>
              <tr>
                <td colSpan={2}>募集費合計</td>
              </tr>
              <tr>
                <td colSpan={2}>諸経費合計</td>
              </tr>
              <tr>
                <td colSpan={2}>光熱費合計</td>
              </tr>
              <tr>
                <td colSpan={2}>家賃合計</td>
              </tr>
              <tr>
                <td rowSpan={2}>決済手数料</td>
                <td>決済売上(税抜)</td>
              </tr>
              <tr>
                <td>手数料</td>
              </tr>
              <tr>
                <td colSpan={2}>減価償却費</td>
              </tr>
              <tr>
                <td colSpan={2}>賞与償却費</td>
              </tr>
              <tr>
                <td colSpan={2}>修繕費</td>
              </tr>
              <tr>
                <td colSpan={2}>共通費</td>
              </tr>
              <tr>
                <td colSpan={2}>支出総計</td>
              </tr>
              <tr>
                <td rowSpan={3}>売上(税抜)</td>
                <td>POS売上</td>
              </tr>
              <tr>
                <td>その他売上</td>
              </tr>
              <tr>
                <td>売上合計</td>
              </tr>
              <tr>
                <td colSpan={2}>営業利益/率</td>
              </tr>
              <tr>
                <td colSpan={2}>予算/達成率</td>
              </tr>
              <tr>
                <td colSpan={2}>FLコスト/率</td>
              </tr>
              <tr>
                <td colSpan={2}>FLAコスト/率</td>
              </tr>
              <tr>
                <td colSpan={2}>FLARコスト/率</td>
              </tr>
              <tr>
                <td colSpan={2}>FLAROコスト/率</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            float: "left",
            width: "calc(100% - 300px)",
            overflowX: "auto"
          }}
          id="wrapTblRight"
          onScroll={eventScroll}
        >
          <table className="incomeExpenditureTable incomeExpenditureTableSecond">
            <tbody>
              {monthData.length > 0 &&
                monthData.map((element: any, index: number) => {
                  return (
                    <tr>
                      {element.length > 0 &&
                        element.map((item: any) => {
                          return (
                            <React.Fragment>
                              <td
                                colSpan={
                                  index === 23 || index === 24 || index === 25
                                    ? 2
                                    : 0
                                }
                                style={{
                                  color:
                                    item.total && item.total < 0
                                      ? "red"
                                      : "black",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100px",
                                  textAlign:
                                    index === 23 || index === 24 || index === 25
                                      ? "center"
                                      : "right"
                                }}
                              >
                                {formatter.format(item.total)}
                              </td>
                              {index !== 23 && index !== 24 && index !== 25 && (
                                <td
                                  style={{
                                    color:
                                      item.total && item.rate < 0
                                        ? "red"
                                        : "black",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "100px"
                                  }}
                                >
                                  {formatterPercent(item.rate)}
                                </td>
                              )}
                            </React.Fragment>
                          );
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row_scroll" style={{ position: "relative" }}>
        <div
          className="col_left"
          style={{
            borderRight: "5px solid #d2d6de",
            width: "300px",
            position: "relative",
            float: "left"
          }}
        />
        <div
          className="col_right scrollAction"
          id="jsScrollAction"
          onScroll={eventScroll}
          style={{
            overflow: "auto",
            height: "15px",
            float: "right",
            width: "calc(100% - 300px)"
          }}
        >
          <div
            className="scrollActionContent"
            style={{
              width: `${widthScrollbar}px`,
              height: "10px",
              marginTop: "-15px"
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default IncomeExpenditureListTable;
