/* eslint-disable max-len */
import ApiClient from './ApiClient';

export const getListHistoryDownloadList = async (params: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/achivementHistoryDownloadList/${companyCode}`, params);
  return response.data;
};

export const getListAttendCSVOutput = async (params: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/achievementCsv/csvOutput/${companyCode}`, params);
  return response.data;
};

export const getAchievementLayout = async (companyCode: string): Promise<Array<any>> => {
  const response = await ApiClient.get(`/v1/achievementCsv/outputLayout/${companyCode}`, {});
  return response.data;
};

export const postProcessingHistoryDownload = async (params: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const reponse = await ApiClient.post(`/v1/processingDownloadAchievement/${companyCode}`, {}, params);
  return reponse.data;
};

export const downloadHistoryById = async (data: any, fileName:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsv(`/v1/downloadFileAchivementHistoryById/${companyCode}`, data, fileName);
};

export const deleteHistoryDownloadById = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.delete(`/v1/deleteAchivementHistoryDownloadById/${companyCode}`, params);
  return response.data;
};

export const cancelHistoryDownloadById = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.delete(`/v1/cancelAchivementHistoryDownloadById/${companyCode}`, params);
  return response.data;
};
