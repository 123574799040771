/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import FormLabel from 'components/atoms/Form/FormLabel';
import FormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import HourInput from 'components/atoms/Form/HourInput';
import FlexBox from 'components/atoms/FlexBox';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import Accordion from 'components/molecules/Accordion';
import { productColor, gridCol, grayScale } from 'components/styles';
import useTermOptions from 'hooks/useTermOptions';
import { AttendEmploymentTermData } from 'domain/employment';
import IconLabelButton from 'components/molecules/IconLabelButton';
import Select from 'components/atoms/Select';
import { Terms } from 'domain/master/attend/terms';


const EmploymentOtherSettings: React.FC<{
  formik: any,
  termMst: Array<Terms>,
  // editEmployment: EmploymentDomain
}> = ({
  formik,
  termMst,
}) => {
  const { termOptions, termMstList } = useTermOptions();
  // console.log(`formik.values.terms.length = ${formik.values.terms.length}`);

  // const selTermItems = formik.values.terms;
  const [selTermItems, setSelTermLength] = useState(formik.values.terms.length);

  // for (let j = 1; j <= numberSelTerm;) {
  //   selTermItems.push(j);
  //   j += 1;
  // }

  const recessTimeAlertItems = [];
  const recessTimeAlert = 3;
  for (let i = 0; i < recessTimeAlert;) {
    recessTimeAlertItems.push(i);
    i += 1;
  }
  // const [numberRecessTimeAlert, setNumberRecessTimeAlert] = React.useState(recessTimeAlert);

  const handleAddTerm = () => {
    // console.log('handleAddTerm');
    formik.values.terms.push({
      id: { termId: '', employmentId: formik.values.employmentId, applyStartDate: formik.values.applyStartDate },
      termName: '',
      targetType: '',
      startTime: '',
      endTime: '',
      addHourlySalary: 0,
    });
    // formik.setFieldValue('terms', selTermItems);
    setSelTermLength(formik.values.terms.length);
    // console.log(`selTermItems.length = ${termLength}`);
    // console.log(`formik.values.terms.length = ${formik.values.terms.length}`);
  };

  const handleDelTerm = (index: number) => {
    // console.log('handleDelTerm');
    // console.log(`index = ${index}`);
    formik.setFieldValue(
      'terms',
      formik.values.terms.filter((term:AttendEmploymentTermData, idx: number) => idx !== index),
    );
    setSelTermLength(formik.values.terms.length);
    // console.log(`selTermItems.length = ${termLength}`);
    // console.log(`formik.values.terms.length = ${formik.values.terms.length}`);
  };

  const changeSalary = (index: number, e: any) => {
    const term: AttendEmploymentTermData = formik.values.terms[index];
    term.addHourlySalary = e.target.value;
    // console.log(`formik.values.terms.length = ${formik.values.terms.length}`);
    formik.values.terms.splice(index, 1, term);
    // console.log(`formik.values.terms.length = ${formik.values.terms.length}`);
    formik.setFieldValue('terms', formik.values.terms);
  };

  return (
    <>
      <FormTitle
        title="その他設定"
      />
      <div>
        <div style={{ clear: 'both' }}> </div>
        <div style={{ borderTop: `1px solid ${productColor.primary}`, borderBottom: `1px solid ${productColor.primary}`, padding: '10px 0px' }}>
          <Accordion text="アラート設定" color={grayScale.gray100} callBack={() => {}}>
            <div>
              <FormField>
                <div style={{ width: gridCol.grid02 }}>
                  <TextForm
                    name="overTimeAlert"
                    label="当月残業アラート設定"
                    value={String(formik.values.overTimeAlert)}
                    onChange={formik.handleChange}
                  />
                </div>
                <div style={{ width: gridCol.grid06, paddingLeft: '10px' }}>
                  <div style={{ paddingLeft: '10px', marginTop: '30px' }}>
                    <FormLabel
                      label="時間を超える残業をアラートとする"
                    />
                  </div>
                </div>
              </FormField>

              {recessTimeAlertItems.map((value, key) => (
                <FormField>
                  <div className="col col-lg-6" style={{ marginRight: '12px' }}>
                    <FormLayout
                      label={(
                        <FormLabel
                          label={`休憩アラート${String(value + 1)}`}
                        />
                  )}
                      input={(
                        <FlexBox>
                          <div style={{ width: '120px' }}>
                            <HourInput
                              name={`recessTimeAlertWorkTime${String(value + 1)}`}
                              value={
                            value === 0
                              ? formik.values.recessTimeAlertWorkTime1
                              : value === 1
                                ? formik.values.recessTimeAlertWorkTime2
                                : formik.values.recessTimeAlertWorkTime3
                          }
                              label="分"
                              min={0}
                              max={1440}
                              onChange={formik.handleChange}
                            />
                          </div>
                          <FormLabel
                            label="を超える勤務で"
                          />
                          <div style={{ width: '120px' }}>
                            <HourInput
                              name={`recessTimeAlertTime${String(value + 1)}`}
                              value={
                            value === 0
                              ? formik.values.recessTimeAlertTime1
                              : value === 1
                                ? formik.values.recessTimeAlertTime2
                                : formik.values.recessTimeAlertTime3
                          }
                              label="分"
                              min={0}
                              max={1440}
                              onChange={formik.handleChange}
                            />
                          </div>
                          <FormLabel
                            label="未満の休憩をアラートとする"
                          />
                        </FlexBox>
                  )}
                    />
                  </div>
                </FormField>
              ))}

              {/* <FormSubmitArea>
                <Button
                  text="休憩アラートを追加"
                  onClick={() => setNumberRecessTimeAlert(numberRecessTimeAlert + 1)}
                  // fullwidth={true}
                  disabled={(numberRecessTimeAlert > 2)}
                />
              </FormSubmitArea> */}

              <FormField>
                <div style={{ width: gridCol.grid03 }}>
                  <TextForm
                    name="continuousWorkAlert"
                    label=""
                    value={formik.values.continuousWorkAlert}
                    onChange={formik.handleChange}
                  />
                </div>
                <div style={{ width: gridCol.grid06 }}>
                  <div style={{ lineHeight: '45px', marginLeft: '10px' }}>
                    <FormLabel
                      label="連勤以上をアラートとする"
                    />
                  </div>
                </div>
                <div style={{ width: gridCol.grid12 }}>
                  <div style={{ lineHeight: '45px', marginLeft: '10px', color: 'red' }}>
                    ※「0」を入力すると連勤チェックは行いません。
                  </div>
                </div>
              </FormField>
            </div>
          </Accordion>
        </div>

        <div style={{ clear: 'both' }}> </div>
        <div style={{ borderBottom: `1px solid ${productColor.primary}`, padding: '10px 0px' }}>
          <Accordion text="時間帯毎の集計設定" color={grayScale.gray100} callBack={() => {}}>
            {formik.values.terms.map((value: AttendEmploymentTermData, index: number) => (
              <FormField>
                <div style={{ lineHeight: '45px', width: '70px' }}>
                  <IconLabelButton
                    iconType="delete"
                    text="削除"
                    onClick={() => { handleDelTerm(index); }}
                    padding=""
                  />
                </div>
                <div style={{ display: 'inline-flex' }}>
                  <FlexBox>
                    <FormLabel label="時間帯" />
                    <div style={{ width: '200px', paddingLeft: '10px' }}>
                      <Select
                        name={`sel_termId_${String(index)}`}
                        value={String(value.id.termId)}
                        setValue={(val: string) => {
                          const term: AttendEmploymentTermData = formik.values.terms[index];
                          if (val.length > 0) {
                            const tgtTerm: Array<Terms> = termMstList.filter(
                              (item: Terms) => item.termId === val,
                            );
                            // console.log(`tgtTerm.length = ${tgtTerm.length}`);
                            term.id.termId = val;
                            term.targetType = String(tgtTerm[0].targetType);
                            term.startTime = tgtTerm[0].startTime;
                            term.endTime = tgtTerm[0].endTime;
                          } else {
                            term.id.termId = '';
                            term.targetType = '';
                            term.startTime = '';
                            term.endTime = '';
                          }
                          // console.log(`formik.values.terms.length = ${formik.values.terms.length}`);
                          formik.values.terms.splice(index, 1, term);
                          // console.log(`formik.values.terms.length = ${formik.values.terms.length}`);
                          formik.setFieldValue('terms', formik.values.terms);
                        }}
                        options={termOptions}
                      />
                    </div>
                  </FlexBox>
                  <div style={{ lineHeight: '45px', width: '130px', paddingLeft: '10px' }}>
                    <FormLabel
                      label={
                        String(value.targetType) === '0'
                          ? '対象：平日・休日'
                          : String(value.targetType) === '1'
                            ? '対象：平日のみ'
                            : String(value.targetType) === '2'
                              ? '対象：休日のみ'
                              : ''
                      }
                    />
                  </div>
                  <div style={{ lineHeight: '45px', width: '150px', paddingLeft: '10px' }}>
                    <FormLabel
                      label={value.id.termId ? `時間：${value.startTime} 〜 ${value.endTime}` : ''}
                    />
                  </div>
                </div>
                <div style={{ display: 'inline-flex' }}>
                  <FlexBox>
                    <FormLabel label="加算額" />
                    <div style={{ width: '120px', paddingLeft: '10px' }}>
                      <HourInput
                        name={`terms_${String(index)}`}
                        value={String(value.addHourlySalary)}
                        label="円"
                        min={0}
                        onChange={(e) => changeSalary(index, e)}
                      />
                    </div>
                  </FlexBox>
                </div>
              </FormField>
            ))}

            <FormSubmitArea>
              <Button
                text="適用する時間帯を追加"
                onClick={handleAddTerm}
              />
            </FormSubmitArea>
            {/* </Container> */}
          </Accordion>
        </div>

        <div style={{ clear: 'both' }}> </div>
        <div style={{ borderBottom: `1px solid ${productColor.primary}`, padding: '10px 0px' }}>
          <Accordion text="GPS打刻設定" color={grayScale.gray100} callBack={() => {}}>
            <FormField>
              <RadioSelectForm
                label="GPS打刻"
                name="useGpsStamp"
                items={[
                  {
                    label: '使用しない',
                    value: '0',
                  },
                  {
                    label: '使用する',
                    value: '1',
                  },
                ]}
                value={String(formik.values.useGpsStamp)}
                setValue={formik.handleChange}
              />
            </FormField>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default EmploymentOtherSettings;
