
import { useState, useEffect } from 'react';

import { getList } from 'api/prescribedHours';
import PrescribedHoursDomain from 'domain/master/labor/prescribedHours';

export const usePrescribedHoursList = () => {
  const [prescribedList, setPrescribedList] = useState<Array<PrescribedHoursDomain>>([]);
  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getList(companyCode).then((response: Array<any>) => {
      if (isSubscribed) {
        setPrescribedList(response.map((result) => new PrescribedHoursDomain(result)));
      }
    });
    return () => { isSubscribed = false; };
  }, []);
  return { prescribedList, setPrescribedList };
};

export default usePrescribedHoursList;
