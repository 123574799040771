import { useState, useEffect } from 'react';
import moment from 'moment';

import { OptionType } from 'components/atoms/Select';
import {
  getTrees, getTreesByCategory, getTreesOrgCode, getTreesByCategoryAll, getTreesAllOrgCode, getOrgManged, getOrganizationTrees,
} from 'api/organization';
import { OrgStatusType } from 'utility/constants';

/**
 * 所定労働日数の選択ボックス用データを取得
 */
export const useOrgTreesOptions = (
  targetDateFrom?: String, targetDateTo?: String,
): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const dateFrom = targetDateFrom || moment().startOf('year').format('YYYY-MM-DD');
    const dateTo = targetDateTo || moment().endOf('year').format('YYYY-MM-DD');
    const functionType = 2
    getTreesOrgCode(functionType,String(dateFrom), String(dateTo)).then((trees) => {
      setOrgTreesOptionsList(trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      })));
    });
  }, [targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesOptionsAll = (targetDateFrom?: string,
  targetDateTo?: string, onlyManager?: boolean, isNotOptionAll?: boolean,
  isValueAllOption?: boolean): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  const functionType = 2

  useEffect(() => {
    getTreesOrgCode(functionType,targetDateFrom, targetDateTo).then((trees) => {
      if (!trees) {
        console.error('店舗一覧が取得できません');
        return;
      }
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      }));
      if (!isNotOptionAll) {
        orgs.unshift({
          value: isValueAllOption ? 'all' : '',
          label: 'all 全店舗',
        });
      }
      setOrgTreesOptionsList(orgs);
    });
  }, [isNotOptionAll, isValueAllOption, onlyManager, targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesOptionsShop = (targetDateFrom?: string,
  targetDateTo?: string): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  const functionType = 2

  useEffect(() => {
    getTreesOrgCode(functionType,targetDateFrom, targetDateTo).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      }));
      setOrgTreesOptionsList(orgs);
    });
  }, [targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};


export const useOrgTreesOptionsManaged = (): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getOrgManged().then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.specifyCode,
        label: `${tree.id.orgCode} ${tree.orgName}`,
      }));
      setOrgTreesOptionsList(orgs);
    });
  }, []);
  return orgTreesOptions;
};

export const useOrgTreesOptionsManagedCustom = (): Array<any> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<any>>([]);
  useEffect(() => {
    getOrgManged().then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.specifyCode,
        label: `${tree.id.orgCode} ${tree.orgName}`,
        orgCode: tree.id.orgCode,
      }));
      setOrgTreesOptionsList(orgs);
    });
  }, []);
  return orgTreesOptions;
};


export const useOrgNameTreesOptionsAll = (): Array<OptionType> => {
  const [orgNameTreesOptions, setOrgNameTreesOptionsList] = useState<Array<OptionType>>([]);
  const functionType = 2
  useEffect(() => {
    const dateFrom = moment().startOf('year').format('YYYY-MM-DD');
    const dateTo = moment().endOf('year').format('YYYY-MM-DD');
    getTrees(functionType,dateFrom, dateTo).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgName}`,
      }));
      setOrgNameTreesOptionsList(orgs);
    });
  }, []);
  return orgNameTreesOptions;
};

export const useAllOrgTrees = (): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getTreesAllOrgCode().then((trees) => {
      setOrgTreesOptionsList(trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      })));
    });
  }, []);
  return orgTreesOptions;
};

export const useOrgTreesByCategoryOptions = (): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const category:any = [];
    const functionType:number = 1;
    getTreesByCategory(functionType,category).then((trees) => {
      setOrgTreesOptionsList(trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      })));
    });
  }, []);
  return orgTreesOptions;
};
export const useOrgTreesByOrgCode = (targetDateFrom: Date, targetDateTo: Date): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    if (targetDateFrom === null || targetDateTo === null) return;
    const dateFrom = moment(new Date(targetDateFrom.getFullYear(), targetDateFrom.getMonth() - 1, 1)).format('YYYY-MM-DD');
    const dateTo = moment(new Date(targetDateTo.getFullYear(), targetDateTo.getMonth() + 1, 0, 23, 59, 59)).format('YYYY-MM-DD');
    const functionType = 2
    getTreesOrgCode(functionType,dateFrom, dateTo).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      }));
      orgs.unshift({
        value: '',
        label: 'all 全店舗',
      });
      setOrgTreesOptionsList(orgs);
    });
  }, [targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesByCategoryAllOptions = (targetDateFrom?: string,
  targetDateTo?: string): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const category: any[] = [];
    const functionType:number = 2
    getTreesByCategoryAll(functionType,targetDateFrom, targetDateTo, category).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      }));
      orgs.unshift({
        value: '',
        label: 'all 全店舗',
      });
      setOrgTreesOptionsList(orgs);
    });
  }, [targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesByCategoryNotAllOptions = (targetDateFrom?: string,
  targetDateTo?: string): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const category: any[] = [];
    const functionType:number = 2
    getTreesByCategoryAll(functionType,targetDateFrom, targetDateTo, category).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      }));
      setOrgTreesOptionsList(orgs);
    });
  }, [targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrganizationTrees = (functionType: number, targetDayFrom?: string,
  targetDayTo?: string, staffCode?: string, addAllItem?: boolean, categoryAry?: Array<any>, orgStatus?: OrgStatusType): Array<any> => {
  const [orgTrees, setOrgTrees] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getOrganizationTrees(functionType, targetDayFrom, targetDayTo, staffCode, addAllItem, categoryAry, orgStatus).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
        specifyCodeValue: tree.specifyCode,
        name: tree.orgName,
      }));
      setOrgTrees(orgs);
      //
      if (sessionStorage.getItem('flgHanldeScreenAttendError')) {
        sessionStorage.setItem('flgHanldeScreenAttendError', String(2));
      }
    });
  }, [functionType, targetDayFrom, targetDayTo, staffCode, addAllItem, categoryAry, orgStatus]);
  return orgTrees;
};


export const useOrganizationTreesOptionsManaged = (functionType: number, targetDayFrom?: string,
  targetDayTo?: string, staffCode?: string, addAllItem?: boolean, categoryAry?: Array<any>, isNotOptionAll?: boolean): Array<any> => {
  const [orgTrees, setOrgTrees] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const dateFrom = targetDayFrom || moment().startOf('year').format('YYYY-MM-DD');
    const dateTo = targetDayTo || moment().endOf('year').format('YYYY-MM-DD');
    getOrganizationTrees(functionType, dateFrom, dateTo, staffCode, addAllItem, categoryAry).then((trees) => {
      let orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
        specifyCodeValue: tree.specifyCode,
      }));
      orgs = orgs.filter((item) => {
        if (item.value !== 'all') return true;
        return false;
      });
      setOrgTrees(orgs);
    });
  }, [addAllItem, categoryAry, functionType, staffCode, targetDayFrom, targetDayTo]);
  return orgTrees;
};


export default useOrgTreesOptions;
