import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getPrefectureList } from 'api/prefecture';

/**
 * 所在地の選択ボックス用データを取得
 */
export const usePrefectureOptions = (): Array<OptionType> => {
  const [prefectureOptions, setPrepectureOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getPrefectureList().then((prefectures) => {
      setPrepectureOptions(prefectures.map((prefecture) => ({
        value: prefecture.prefectureCode,
        label: prefecture.prefectureName,
      })));
    });
  }, []);
  return prefectureOptions;
};

export default usePrefectureOptions;
