import ApiClient from './ApiClient';

export const getDataApprovalApplication = async (
  applicationId : string,
  staffCode: string,
  staffName: string,
  typeUrl: string,
  apiVersion: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    applicationId,
    loginUserCode: staffCode,
    loginUserName: staffName,
  };
  // const response = await ApiClient.get(`/v1/approval/${typeUrl}/${companyCode}`, params);
  const response = await ApiClient.get(`/${apiVersion}/approval/${typeUrl}/${companyCode}`, params);
  return response.data;
};

export const getHistoryApprovalApplication = async (
  applicationId : string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    applicationId,
  };
  const response = await ApiClient.get(`/v1/attendModifier/stampHistoryListByAppId/${companyCode}`, params);
  return response.data;
};

export const handleSubmitModificationApplication = async (params: any) => {
  // TODO パラメータ確認
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const query = {
    ...params,
  };

  const response = await ApiClient.post(`/v1/approval/stamp/${companyCode}`, {}, query);
  return response.data;
};

export const handleSubmitOvertimeApplication = async (params: any) => {
  // TODO パラメータ確認
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const query = {
    ...params,
  };

  const response = await ApiClient.post(`/v1/approval/overtime/${companyCode}`, {}, query);
  // const response = await ApiClient.post(`/v1/approval/overtime/${companyCode}`, {}, query);
  return response.data;
};

export const handleSubmitHolidayWorkApplication = async (params: any) => {
  // TODO パラメータ確認
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const query = {
    ...params,
  };

  const response = await ApiClient.post(`/v1/approval/holidaywork/${companyCode}`, {}, query);
  return response.data;
};

export const handleSubmitTransferApplication = async (params: any) => {
  // TODO パラメータ確認
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const query = {
    ...params,
  };

  const response = await ApiClient.post(`/v1/approval/transfer/${companyCode}`, {}, query);
  return response.data;
};
export const handleSubmitHolidayApplication = async (params: any) => {
  // TODO パラメータ確認
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const query = {
    ...params,
  };

  const response = await ApiClient.post(`/v1/approval/holiday/${companyCode}`, {}, query);
  return response.data;
};

export default getDataApprovalApplication;
