import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import { getDailyDrawingInfo } from 'api/shift';
import { getShiftCostRate, getShiftHopeRate, ShiftHopeRate } from 'api/shiftCost';
import { getShiftConfirmedDate, postShiftConfirm, getShiftConfirmedState } from 'api/shiftConfirm';
import useToastNotification from 'hooks/useToastNotification';
import useBusinessMasterOptions from 'hooks/useBusinessMasterOptions';

export const useShiftPage = () => {
  const { successNotification, errorNotification } = useToastNotification();

  const [date, setDate] = useState(moment().toDate());
  const [view, setView] = useState<'month'|'day'>('month');
  const [orgCode, setOrgCode] = useState('');
  const [businessIds, setBusinessIds] = useState<Array<string>>([]);
  const [shiftCostRate, setShiftCostRate] = useState<number>(0);
  const [shiftHopeRates, setShiftHopeRates] = useState<Array<ShiftHopeRate>>([]);
  const [openShiftConfirmModal, setShiftConfirmModalFlag] = useState(false);
  const [shiftConfirmState, setShiftConfirmState] = useState({});

  // シフト確定
  const [dayFrom, setDayFrom] = useState(new Date());
  const [dayTo, setDayTo] = useState(new Date());
  const orgOptions:any = [];
  const businessMasterOptions = useBusinessMasterOptions();
  const { dispStartHour, dispHour } = useDailyDrawingInfo(orgCode);

  useEffect(() => {
    if (!orgCode) {
      // alert(orgCode);
      setShiftCostRate(0);
      setShiftHopeRates([]);
      const dayFromMoment = moment(date).startOf('month');
      setDayFrom(dayFromMoment.toDate());
      setDayTo(dayFromMoment.add(1, 'day').toDate());
      return;
    }
    const targetDateStr = moment(date).format('YYYY-MM-DD');

    // シフトコストを取得
    getShiftCostRate(orgCode, targetDateStr).then((response) => {
      setShiftCostRate(response.rate);
    });

    // 稼ぎ叶え度を取得
    // getShiftHopeRate(orgCode, targetDateStr).then((response) => {
    //   setShiftHopeRates(response);
    // });

    // シフト確定日を取得
    getShiftConfirmedDate(
      orgCode,
      moment(date).startOf('month').format('YYYY-MM-DD'),
      moment(date).endOf('month').format('YYYY-MM-DD'),
    ).then((response) => {
      let dayFrom = moment(date).startOf('month');
      let dayTo = moment(date).startOf('month');
      if (response.date != null) {
        dayFrom = moment(response.date).add(1, 'day');
        dayTo = moment(response.date).add(1, 'day');
      }
      setDayFrom(dayFrom.toDate());
      setDayTo(dayTo.toDate());
      /*
      const dayFromMoment = response.date ? moment(response.date) : moment(date).startOf('month');
      setDayFrom(dayFromMoment.toDate());
      // 1日の場合、toも同日、2日以降の場合、toは + 1日
      if (dayFromMoment.date() === 1) {
        setDayTo(dayFromMoment.toDate());
      } else {
        setDayTo(dayFromMoment.add(1, 'day').toDate());
      }
      */
    });

    // 月のシフト確定状態を取得
    getShiftConfirmedState(
      orgCode,
      moment(date).startOf('month').format('YYYY-MM-DD'),
      moment(date).endOf('month').format('YYYY-MM-DD'),
    ).then((response) => {
      const state = response.shiftConfirm ? response.shiftConfirm : {};
      setShiftConfirmState(state);
    });
  }, [date, orgCode]);

  /**
   * 確定ボタン押下
   */
  const onClickShiftConfirm = useCallback(() => {
    if (moment(dayFrom).diff(moment(dayFrom).endOf('month'), 'day') === 0) {
      errorNotification('当月のシフトは確定済みです');
      return;
    }
    setShiftConfirmModalFlag(true);
  }, [dayFrom, errorNotification]);

  /**
   * シフトの確定
   */
  const shiftSubmit = useCallback(() => {
    postShiftConfirm(
      orgCode,
      moment(dayFrom).format('YYYY-MM-DD'),
      moment(dayTo).format('YYYY-MM-DD'),
    ).then(() => {
      successNotification('シフトを確定しました');
      setShiftConfirmModalFlag(false);

      // シフト確定日を取得（再取得）
      getShiftConfirmedDate(
        orgCode,
        moment(date).startOf('month').format('YYYY-MM-DD'),
        moment(date).endOf('month').format('YYYY-MM-DD'),
      ).then((response) => {
        let dayFrom = moment(date).startOf('month');
        let dayTo = moment(date).startOf('month');
        if (response.date != null) {
          dayFrom = moment(response.date).add(1, 'day');
          dayTo = moment(response.date).add(1, 'day');
        }
        setDayFrom(dayFrom.toDate());
        setDayTo(dayTo.toDate());
        /*
        const dayFromMoment = response.date ? moment(response.date) : moment(date).startOf('month');
        setDayFrom(dayFromMoment.toDate());
        // 1日の場合、toも同日、2日以降の場合、toは + 1日
        if (dayFromMoment.date() === 1) {
          setDayTo(dayFromMoment.toDate());
        } else {
          setDayTo(dayFromMoment.add(1, 'day').toDate());
        }
        */
      });

      // 月のシフト確定状態を取得（再取得）
      getShiftConfirmedState(
        orgCode,
        moment(date).startOf('month').format('YYYY-MM-DD'),
        moment(date).endOf('month').format('YYYY-MM-DD'),
      ).then((response) => {
        const state = response.shiftConfirm ? response.shiftConfirm : {};
        setShiftConfirmState(state);
      });
    }).catch(() => {
      errorNotification('シフトの確定に失敗しました');
    });
  }, [orgCode, dayFrom, dayTo, successNotification, date, errorNotification]);

  return {
    state: {
      date,
      view,
      orgCode,
      orgOptions,
      businessIds,
      businessMasterOptions,
      dispStartHour,
      dispHour,
      shiftCostRate,
      shiftHopeRates,
      openShiftConfirmModal,
      dayFrom,
      dayTo,
      shiftConfirmState,
    },
    setter: {
      setDate,
      setView,
      setOrgCode,
      setBusinessIds,
      setShiftConfirmModalFlag,
      setDayTo,
    },
    actions: {
      shiftSubmit,
      onClickShiftConfirm,
    },
  };
};

/**
 * 日表示に必要なデータをAPIから取得
 */
const useDailyDrawingInfo = (orgCode: string) => {
  const [dispStartHour, setDispStartHour] = useState<number>(6);
  const [dispHour, setDispHour] = useState<number>(12);
  useEffect(() => {
    if (!orgCode) {
      return;
    }

    const fetchDailyDrawingInfo = async () => {
      const response = await getDailyDrawingInfo(orgCode);
      setDispStartHour(response.dispStartHour);
      setDispHour(response.dispHour);
    };
    fetchDailyDrawingInfo();
  }, [orgCode]);
  return {
    dispStartHour,
    dispHour,
  };
};

export default {};
