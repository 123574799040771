import { useState, useEffect } from 'react';

import SpecialDateMasterDomain from 'domain/master/labor/specialDateMaster';

import getList from 'api/specialDateMaster';

export const useSpecialDateMaster = () => {
  const [specialList, setSpecialList] = useState<Array<SpecialDateMasterDomain>>([]);
  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getList(companyCode).then((response: Array<any>) => {
      if (isSubscribed) {
        setSpecialList(response.map((result) => new SpecialDateMasterDomain(result)));
      }
    });
    return () => { isSubscribed = false; };
  }, []);
  return { specialList, setSpecialList };
};

export default useSpecialDateMaster;
