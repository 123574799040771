const keydownFunc = function (e) {
  //arrows: left, right
  if ([37, 39].indexOf(e.keyCode) > -1) {
    e.preventDefault();
  }
}

function addListener(component) {
  component._resizeEventListener = {
    handleEvent: () => {
      setTimeout(() => {
        component.resize()
      }, 500)
    }
  }

  window.addEventListener('resize', component._resizeEventListener)
  const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
      if (entry.contentBoxSize) {
        setTimeout(() => {
          component && component.resize()
        }, 500)
      }
    }
  });

  window.addEventListener("keydown", keydownFunc, false);

  if (document.getElementsByTagName('aside') && document.getElementsByTagName('aside')[0]) {
    resizeObserver.observe(document.getElementsByTagName('aside') && document.getElementsByTagName('aside')[0]);
    window.resizeObserver = resizeObserver;

  }
}

function removeListener(component) {
  window.removeEventListener('resize', component._resizeEventListener)
  window.removeEventListener('keydown', keydownFunc, false)
  if (document.getElementsByTagName('aside') && document.getElementsByTagName('aside')[0]) {
    window.resizeObserver.disconnect()
  }
}

export default {
  addListener,
  removeListener
}
