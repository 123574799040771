/* eslint-disable eqeqeq */
/** @jsx jsx */
import React, {
  useRef, useEffect, useState, createRef, useMemo,
} from 'react';
import { css, jsx } from '@emotion/core';
import {
  gridCol, utilityColor,
} from 'components/styles';
import Icon from 'components/atoms/Icon';
import DropdownContextMenu, { DropdownMenuType } from 'components/molecules/Dropdown/DropdownContextMenu';
import IconTooltip from 'components/molecules/IconTooltip';

const AttendStampTable: React.FC<{
  eventScroll: any
  business: Array<any>,
  term: Array<any>,
  targetDateFrom: Date,
  targetDateTo: Date,
  selectOrgCode: String,
  attendFlag: String,
  stampItems: any,
  emp: any,
  staffCode: any,
  staffList: any,
  role:any
  displayActualStampingFlag?: number,
  getCustomDropdownMenuButtons: (item: any, distinctionHoliday: boolean, usabilityGeneralItem2: number) => DropdownMenuType[]
}> = ({
  eventScroll,
  business,
  term,
  targetDateFrom,
  targetDateTo,
  selectOrgCode,
  attendFlag,
  stampItems,
  emp,
  staffCode,
  staffList,
  role,
  displayActualStampingFlag,
  getCustomDropdownMenuButtons
}) => {
  const refTableScroll = useRef(Object());
  const refTableContent = useRef(Object());
  const [refTrContent, setRefTrContent] = useState([]);
  const [heights, setHeights] = useState([]);
  const [offsetWidthTable, setOffsetWidthTable] = useState(0);

  useMemo(() => {
    setRefTrContent(stampItems.map(() => createRef()));
  }, [stampItems]);

  function getHolidayStyle(holidayColor: any, holidayApplyStatus: any, holidayType: any,
    holidayDeleted: any, hasData: any) {
    if (!hasData) {
      return {};
    }
    if (holidayDeleted) {
      return {};
    }
    if (holidayColor != '' && holidayType == '0') {
      return { backgroundColor: holidayColor };
    } if (holidayColor != '' && holidayApplyStatus == '0') {
      return { backgroundColor: holidayColor, border: '5px solid rgb(60,189,172)' };
    } if (holidayColor != '' && holidayApplyStatus == '1') {
      return { backgroundColor: holidayColor, border: '5px solid rgb(60,77,189)' };
    }
    return { backgroundColor: holidayColor };
  }

  function getStampTimeStyle(alertStatus: any, applyStatus: any, hasDispData: any) {
    if (!hasDispData) {
      return {};
    }

    // エラー
    if (alertStatus == '2') {
      return { border: '5px solid rgb(189,60,77)' };
    }

    // 申請ステータスをみない
    if (applyStatus == -1) {

    // 申請中
    } else if (applyStatus == 0) {
      return { border: '5px solid rgb(60,189,172)' };
    } else if (applyStatus == 1) { // 承認
      return { border: '5px solid rgb(60,77,189)' };
    }
    // アラート
    if (alertStatus == '1') {
      return { border: '5px solid rgb(189,172,60)' };
    }

    return {};
  }

  function getStampTimeStyleDisplayableEmpty(alertStatus: any, applyStatus: any, hasDispData: any, targetErrorFlg: any, modifierDeleted: any) {
    // 削除申請時は何も表示しない
    if (modifierDeleted) {
      return {};
    }
    // データの有無にかかわらず表示可能なエラー
    if (alertStatus == '2' && targetErrorFlg) {
      return { border: '5px solid rgb(189,60,77)' };
    }
    if (hasDispData) {
      // エラー
      if (alertStatus == '2') {
        return { border: '5px solid rgb(189,60,77)' };
      }
      // 申請中
      if (applyStatus == 0) {
        return { border: '5px solid rgb(60,189,172)' };
      } if (applyStatus == 1) { // 承認
        return { border: '5px solid rgb(60,77,189)' };
      }
      // アラート
      if (alertStatus == '1') {
        return { border: '5px solid rgb(189,172,60)' };
      }
    }
    return {};
  }

  function getStyleAertUnder18MidnightWorkFlg(flag: any) {
    if (flag) {
      return { border: '5px solid rgb(189,172,60)' };
    }

    return {};
  }

  function getStyleMaxHeight(index: number) {
    if (heights[index]) {
      return { height: `${heights[index]}px` };
    }

    return {};
  }

  useEffect(() => {
    const width = refTableScroll.current ? refTableScroll.current.offsetWidth : 0;
    setOffsetWidthTable(width);

    const nextHeights: any = refTrContent && refTrContent.map(
      (ref: any) => ref.current && ref.current.clientHeight,
    );
    setHeights(nextHeights);
  }, [refTableScroll.current.offsetWidth, refTrContent, refTableContent.current.clientHeight]);

  const staff = staffList.filter((staff: any) => staff.value === staffCode);
  const dispStaffName = staff[0] ? staff[0].label : '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  return (
    <div className="wrap-attend-stamp-list">
      <div style={{ display: 'flex' }}>
        <div className="top-left-area-time">
          <table className="table-condensed nowrap time-table margin-bottom-0 fixedTable border-right-none" style={{ width: gridCol.grid12 }}>
            <thead>
              <tr>
                <th className="head-line4-height" style={{ width: '120px' }}>
                  修正
                </th>
                <th className="head-line4-height" style={{ width: '120px' }}>日付</th>
              </tr>
            </thead>
          </table>
        </div>
        <div id="topRightAreaTime" onScroll={eventScroll} style={{ width: 'calc(100% - 232px)', overflow: 'auto' }}>
          <div className="maxContent" ref={refTableScroll}>
            <table className="table-condensed nowrap time-table margin-bottom-0 fixedTable border-left-none" id="top-right-time">
              <thead>
                <tr>
                  <th className="head-line4-height" style={{ width: '40px' }}>確定</th>
                  <th style={{ width: '180px' }}>勤務予定</th>
                  <th style={{ width: '100px' }}>休暇</th>
                  {
                    emp.distinctionHoliday && (
                    <th style={{ width: '60px' }}>
                      休日
                      <br />
                      種別
                    </th>
                    )
                  }
                  <th style={{ width: '80px' }}>出社</th>
                  <th style={{ width: '80px' }}>退社</th>
                  {
                    displayActualStampingFlag === 1 && (
                      <React.Fragment>
                        <th style={{ width: '100px' }}>出勤<br/>（打刻）</th>
                        <th style={{ width: '100px' }}>退勤<br/>（打刻）</th>
                      </React.Fragment>
                    )
                  }
                  <th style={{ width: '180px' }}>休憩時刻</th>
                  <th style={{ width: '60px' }}>普通</th>
                  {
                    emp.handleLegalInOvertime == 0 && (
                    <th style={{ width: '60px' }}>
                      普通
                      <br />
                      残業
                    </th>
                    )
                  }
                  {
                    emp.handleLegalInOvertime == 1 && (
                    <th style={{ width: '60px' }}>
                      法定内
                      <br />
                      残業
                    </th>
                    )
                  }
                  {
                    emp.handleLegalInOvertime == 1 && (
                    <th style={{ width: '60px' }}>
                      法定外
                      <br />
                      残業
                    </th>
                    )
                  }
                  {
                    emp.useExtraOverTime && (
                    <th style={{ width: '60px' }}>
                      割増
                      <br />
                      残業1
                    </th>
                    )
                  }
                  {
                    (emp.useExtraOverTime && emp.extraOverTime2 > 0) && (
                      <th style={{ width: '60px' }}>
                        割増
                        <br />
                        残業2
                      </th>
                    )
                  }
                  {
                    (emp.useExtraOverTime && emp.extraOverTime3 > 0) && (
                      <th style={{ width: '60px' }}>
                        割増
                        <br />
                        残業3
                      </th>
                    )
                  }

                  <th style={{ width: '60px' }}>深夜</th>
                  {
                    emp.handleLegalInOvertime == 0 && (
                    <th style={{ width: '60px' }}>
                      深夜
                      <br />
                      残業
                    </th>
                    )
                  }
                  {
                    emp.handleLegalInOvertime == 1 && (
                    <th style={{ width: '60px' }}>
                      深夜
                      <br />
                      法定内
                      <br />
                      残業
                    </th>
                    )
                  }
                  {
                    emp.handleLegalInOvertime == 1 && (
                    <th style={{ width: '60px' }}>
                      深夜
                      <br />
                      法定外
                      <br />
                      残業
                    </th>
                    )
                  }
                  {
                    emp.useExtraOverTime && (
                    <th style={{ width: '60px' }}>
                      深夜
                      <br />
                      割増
                      <br />
                      残業1
                    </th>
                    )
                  }
                  {
                    (emp.useExtraOverTime && emp.extraOverTime2 > 0) && (
                      <th style={{ width: '60px' }}>
                        深夜
                        <br />
                        割増
                        <br />
                        残業2
                      </th>
                    )
                  }
                  {
                    (emp.useExtraOverTime && emp.extraOverTime3) > 0 && (
                      <th style={{ width: '60px' }}>
                        深夜
                        <br />
                        割増
                        <br />
                        残業3
                      </th>
                    )
                  }
                  {
                    emp.useOverTimeApplication != 0 && (
                    <th style={{ width: '60px' }}>
                      未承認
                      <br />
                      残業
                    </th>
                    )
                  }
                  <th style={{ width: '60px' }}>休憩</th>
                  <th style={{ width: '60px' }}>遅刻</th>
                  <th style={{ width: '60px' }}>早退</th>
                  <th style={{ width: '60px' }}>みなし時間</th>
                  <th style={{ width: '60px' }}>
                    総労働
                    <br />
                    時間
                  </th>
                  {
                    business && business.map((businessItem: any) => (
                      <th key={businessItem.businessCode} style={{ width: '120px' }}>{businessItem.businessName}</th>
                    ))
                  }
                  {
                    term && term.map((termItem: any) => (
                      <th key={termItem.termId} style={{ width: '120px' }}>{ termItem.termName }</th>
                    ))
                  }
                  <th style={{ width: '200px' }}>出勤店舗</th>
                  <th style={{ width: '400px' }}>備考</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div className="table-content">
        <div style={{ display: 'inline-flex', width: gridCol.grid12 }}>
          <div className="bottomLeftAreaTime">
            <table className="table-condensed nowrap time-table margin-bottom-0 fixedTable border-right-none">
              <tbody>
                {
                  stampItems && stampItems.map((item: any, index: any) => (
                    <tr key={String(index)} style={{ backgroundColor: item.weekdayColor, ...getStyleMaxHeight(index) }}>
                      <td className="head-line1-height" style={{ width: '120px' }}>
                        {
                          (item.applying != 0 && item.employmentId != null) && role !== null && ((role.editable === 1 && loginStaffCode === staffCode) || (loginStaffCode !== staffCode && role.usabilityGeneralItem1 === 1)) && (
                            <DropdownContextMenu
                              text="申請"
                              modifierApplicationId={item.modifierApplicationId}
                              orgCode={item.orgCode}
                              staffCode={staffCode}
                              staffName=""
                              dispStaffName={dispStaffName}
                              modifierApplicationStatus={item.modifierApplicationStatus}
                              holidayApplicationStatus={item.holidayApplicationStatus}
                              overworkApplicationStatus={item.overworkApplicationStatus}
                              holidayworkApplicationStatus={item.holidayworkApplicationStatus}
                              transferApplicationStatus={item.transferApplicationStatus}
                              attendanceOrgFix={item.attendanceOrgFix}
                              useOverTimeApplication={item.stampEmployment?.useOverTimeApplication}
                              necessaryHolidayWorkApplication={item.stampEmployment?.necessaryHolidayWorkApplication}
                              useTransferApplication={item.stampEmployment?.useTransferApplication}
                              achievementId={item.achievementId}
                              targetDate={item.targetDate}
                              stampVersion={item.stampVersion}
                              filterTargetDateFrom={targetDateFrom}
                              filterTargetDateTo={targetDateTo}
                              filterOrgCode={String(selectOrgCode)}
                              filterStaffCode={staffCode}
                              belongOrgFix={item.belongOrgFix}
                              applying={item.applying}
                              customDropdownMenuButtons={getCustomDropdownMenuButtons(item, emp.distinctionHoliday, role == null ? 0 : role.usabilityGeneralItem2 == null ? 0 : role.usabilityGeneralItem2)}
                            />
                          )
                        }
                        {
                          (item.applying == 0 && item.employmentId != null) && role !== null && ((role.editable === 1 && loginStaffCode === staffCode) || (loginStaffCode !== staffCode && role.usabilityGeneralItem1 === 1)) && (
                            <DropdownContextMenu
                              text="申請中"
                              modifierApplicationId={item.modifierApplicationId}
                              orgCode={item.orgCode}
                              staffCode={staffCode}
                              staffName=""
                              dispStaffName={dispStaffName}
                              modifierApplicationStatus={item.modifierApplicationStatus}
                              holidayApplicationStatus={item.holidayApplicationStatus}
                              overworkApplicationStatus={item.overworkApplicationStatus}
                              holidayworkApplicationStatus={item.holidayworkApplicationStatus}
                              transferApplicationStatus={item.transferApplicationStatus}
                              attendanceOrgFix={item.attendanceOrgFix}
                              useOverTimeApplication={item.stampEmployment?.useOverTimeApplication}
                              necessaryHolidayWorkApplication={item.stampEmployment?.necessaryHolidayWorkApplication}
                              useTransferApplication={item.stampEmployment?.useTransferApplication}
                              achievementId={item.achievementId}
                              targetDate={item.targetDate}
                              stampVersion={item.stampVersion}
                              filterTargetDateFrom={targetDateFrom}
                              filterTargetDateTo={targetDateTo}
                              filterOrgCode={String(selectOrgCode)}
                              filterStaffCode={staffCode}
                              belongOrgFix={item.belongOrgFix}
                              applying={item.applying}
                              customDropdownMenuButtons={getCustomDropdownMenuButtons(item, emp.distinctionHoliday, role == null ? 0 : role.usabilityGeneralItem2 == null ? 0 : role.usabilityGeneralItem2)}
                            />
                          )
                        }
                      </td>
                      {
                        (item.alert == '0' || !item.alert || item.modifierDeleted) && <td style={{ width: '120px' }}>{item.day}</td>
                      }
                      {
                        !item.modifierDeleted && item.errorMsg && !item.alertMsg && !item.infoMsg && (
                          <td style={{ width: '120px' }}>
                            {item.day}
                            <br />
                            <IconTooltip
                              iconType="remove"
                              text={item.errorMsg || ''}
                              color={utilityColor.white}
                              customCss={css({ margin: '0' })}
                              inlineBlock={true}
                            />
                          </td>
                        )
                      }
                      {
                          !item.modifierDeleted && !item.errorMsg && item.alertMsg && !item.infoMsg && (
                            <td style={{ width: '120px' }}>
                                {item.day}
                                <br />
                                <IconTooltip
                                iconType="warningFill"
                                text={item.alertMsg || ''}
                                color={utilityColor.yellow}
                                customCss={css({ margin: '0' })}
                                inlineBlock={true}
                              />
                            </td>
                          )
                        }
                      {
                          !item.modifierDeleted && !item.errorMsg && !item.alertMsg && item.infoMsg && (
                            <td style={{ width: '120px' }}>
                              {item.day}
                              <br />
                              <IconTooltip
                                iconType="warning"
                                text={item.infoMsg || ''}
                                color={utilityColor.yellow}
                                customCss={css({ margin: '0' })}
                                inlineBlock={true}
                              />
                            </td>
                          )
                        }
                      {
                          !item.modifierDeleted && item.errorMsg && item.alertMsg && !item.infoMsg && (
                            <td style={{ width: '120px' }}>
                              {item.day}
                              <br />
                              <IconTooltip
                                iconType="remove"
                                text={item.errorMsg || ''}
                                color={utilityColor.white}
                                customCss={css({ margin: '0' })}
                                inlineBlock={true}
                              />
                              <IconTooltip
                                iconType="warningFill"
                                text={item.alertMsg || ''}
                                color={utilityColor.yellow}
                                customCss={css({ margin: '0' })}
                                inlineBlock={true}
                              />
                            </td>
                          )
                        }
                      {
                          !item.modifierDeleted && item.errorMsg && !item.alertMsg && item.infoMsg && (
                            <td style={{ width: '120px' }}>
                              {item.day}
                              <br />
                              <IconTooltip
                                iconType="remove"
                                text={item.errorMsg || ''}
                                color={utilityColor.white}
                                customCss={css({ margin: '0' })}
                                inlineBlock={true}
                              />
                              <IconTooltip
                                iconType="warning"
                                text={item.infoMsg || ''}
                                color={utilityColor.yellow}
                                customCss={css({ margin: '0' })}
                                inlineBlock={true}
                              />
                            </td>
                          )
                        }
                      {
                          !item.modifierDeleted && !item.errorMsg && item.alertMsg && item.infoMsg && (
                            <td style={{ width: '120px' }}>
                              {item.day}
                              <br />
                              <IconTooltip
                                iconType="warningFill"
                                text={item.alertMsg || ''}
                                color={utilityColor.yellow}
                                customCss={css({ margin: '0' })}
                                inlineBlock={true}
                              />
                              <IconTooltip
                                iconType="warning"
                                text={item.infoMsg || ''}
                                color={utilityColor.yellow}
                                customCss={css({ margin: '0' })}
                                inlineBlock={true}
                              />
                            </td>
                          )
                        }
                      {
                          !item.modifierDeleted && item.errorMsg && item.alertMsg && item.infoMsg && (
                            <td style={{ width: '120px' }}>
                              {item.day}
                              <br />
                              <IconTooltip
                                iconType="remove"
                                text={item.errorMsg || ''}
                                color={utilityColor.white}
                                customCss={css({ margin: '0' })}
                                inlineBlock={true}
                              />
                              <IconTooltip
                                iconType="warningFill"
                                text={item.alertMsg || ''}
                                color={utilityColor.yellow}
                                customCss={css({ margin: '0' })}
                                inlineBlock={true}
                              />
                              <IconTooltip
                                iconType="warning"
                                text={item.infoMsg || ''}
                                color={utilityColor.yellow}
                                customCss={css({ margin: '0' })}
                                inlineBlock={true}
                              />
                            </td>
                          )
                        }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div id="bottomRightAreaTime" style={{ width: 'calc(100% - 232px)', overflow: 'auto' }} onScroll={eventScroll}>
            <div style={{ width: `${offsetWidthTable}px` }}>
              <table ref={refTableContent} className="table-condensed nowrap time-table margin-bottom-0 fixedTable border-left-none" id="bottom-right-time">
                <tbody>
                  {/* fix width */}
                  <tr style={{ visibility: 'collapse' }}>
                    <td style={{
                      width: '40px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    <td style={{
                      width: '180px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    <td style={{
                      width: '100px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    {
                      emp.distinctionHoliday && (
                      <td style={{
                        width: '60px', height: '0px', padding: '0px', border: 'none',
                      }}
                      />
                      )
                    }
                    <td style={{
                      width: '80px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    <td style={{
                      width: '80px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    {
                      displayActualStampingFlag === 1 && (
                        <React.Fragment>
                          <td style={{
                            width: '100px', height: '0px', padding: '0px', border: 'none',
                          }}
                          />
                          <td style={{
                            width: '100px', height: '0px', padding: '0px', border: 'none',
                          }}
                          />
                        </React.Fragment>
                      )
                    }
                    <td style={{
                      width: '180px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    <td style={{
                      width: '60px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    {
                      emp.handleLegalInOvertime == 0 && (
                      <td style={{
                        width: '60px', height: '0px', padding: '0px', border: 'none',
                      }}
                      />
                      )
                    }
                    {
                      emp.handleLegalInOvertime == 1 && (
                      <td style={{
                        width: '60px', height: '0px', padding: '0px', border: 'none',
                      }}
                      />
                      )
                    }
                    {
                      emp.handleLegalInOvertime == 1 && (
                      <td style={{
                        width: '60px', height: '0px', padding: '0px', border: 'none',
                      }}
                      />
                      )
                    }
                    {
                      emp.useExtraOverTime && (
                      <td style={{
                        width: '60px', height: '0px', padding: '0px', border: 'none',
                      }}
                      />
                      )
                    }
                    {
                      (emp.useExtraOverTime && emp.extraOverTime2 > 0) && (
                        <td style={{
                          width: '60px', height: '0px', padding: '0px', border: 'none',
                        }}
                        />
                      )
                    }
                    {
                      (emp.useExtraOverTime && emp.extraOverTime3 > 0) && (
                        <td style={{
                          width: '60px', height: '0px', padding: '0px', border: 'none',
                        }}
                        />
                      )
                    }

                    <td style={{
                      width: '60px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    {
                      emp.handleLegalInOvertime == 0 && (
                      <td style={{
                        width: '60px', height: '0px', padding: '0px', border: 'none',
                      }}
                      />
                      )
                    }
                    {
                      emp.handleLegalInOvertime == 1 && (
                      <td style={{
                        width: '60px', height: '0px', padding: '0px', border: 'none',
                      }}
                      />
                      )
                    }
                    {
                      emp.handleLegalInOvertime == 1 && (
                      <td style={{
                        width: '60px', height: '0px', padding: '0px', border: 'none',
                      }}
                      />
                      )
                    }
                    {
                      emp.useExtraOverTime && (
                      <td style={{
                        width: '60px', height: '0px', padding: '0px', border: 'none',
                      }}
                      />
                      )
                    }
                    {
                      (emp.useExtraOverTime && emp.extraOverTime2 > 0) && (
                        <td style={{
                          width: '60px', height: '0px', padding: '0px', border: 'none',
                        }}
                        />
                      )
                    }
                    {
                      (emp.useExtraOverTime && emp.extraOverTime3) > 0 && (
                        <td style={{
                          width: '60px', height: '0px', padding: '0px', border: 'none',
                        }}
                        />
                      )
                    }
                    {
                      emp.useOverTimeApplication != 0 && (
                      <td style={{
                        width: '60px', height: '0px', padding: '0px', border: 'none',
                      }}
                      />
                      )
                    }
                    <td style={{
                      width: '60px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    <td style={{
                      width: '60px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    <td style={{
                      width: '60px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    <td style={{
                      width: '60px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    <td style={{
                      width: '60px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    {
                      business && business.map((businessItem: any) => (
                        <td
                          key={businessItem.businessCode}
                          style={{
                            width: '120px', height: '0px', padding: '0px', border: 'none',
                          }}
                        />
                      ))
                    }
                    {
                      term && term.map((termItem: any) => (
                        <td
                          key={termItem.termId}
                          style={{
                            width: '120px', height: '0px', padding: '0px', border: 'none',
                          }}
                        />
                      ))
                    }
                    <td style={{
                      width: '200px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                    <td style={{
                      width: '400px', height: '0px', padding: '0px', border: 'none',
                    }}
                    />
                  </tr>
                  {
                    stampItems && stampItems.map((item: any, index: any) => (
                      <tr ref={refTrContent[index]} key={String(index)} style={{ backgroundColor: item.weekdayColor }}>
                        {
                          item.attendanceOrgFix != '1' && item.belongOrgFix != '1' && <td className="head-line1-height" />
                        }
                        {
                          (item.attendanceOrgFix == '1' || item.belongOrgFix == '1') && <td className="head-line1-height"><Icon type="singlecheckRounded" color="#34a659" size="20px" /></td>
                        }

                        {/* <!--勤務予定 --> */}
                        <td style={{ width: '180px' }}>
                          {item.shiftTime}
                        </td>

                        {/* <!--休暇 --> */}
                        <td style={{
                          color: item.holidayForeColor, ...getHolidayStyle(item.holidayBgColor, item.holidayApplicationStatus, item.holidayType, item.holidayDeleted, item.holidayName),
                        }}
                        >
                          {
                            !item.holidayDeleted && <span>{item.holidayName}</span>
                          }
                        </td>

                        {/* <!--休日種別 --> */}
                        {
                          (item.stampEmployment != null && (item.stampEmployment.distinctionHoliday && item.isLegal && item.transferType != '1' && item.holidayType == '0')) && <td>法定</td>
                        }
                        {
                          (item.stampEmployment != null && (item.stampEmployment.distinctionHoliday && !item.isLegal && item.holidayName && item.transferType != '1' && item.holidayType == '0')) && <td>所定</td>
                        }
                        {
                          (item.stampEmployment != null && (item.stampEmployment.distinctionHoliday && (!item.holidayName || item.transferType == '1' || item.holidayType != '0'))) && <td />
                        }
                        {
                          (item.stampEmployment != null && (!item.stampEmployment.distinctionHoliday && emp.distinctionHoliday && item.holidayType == '0' && (item.holidayName && item.transferType != '1'))) && <td>法定</td>
                        }
                        {
                          (item.stampEmployment != null && (!item.stampEmployment.distinctionHoliday && emp.distinctionHoliday && item.holidayType != '0' && !(item.holidayName && item.transferType != '1'))) && <td />
                        }
                        {
                          item.stampEmployment == null && <td />
                        }

                        {/* <!--出社 --> */}
                        <td style={getStampTimeStyle(item.startTimeAlert, item.modifierApplicationStatus, (!item.modifierDeleted && item.startTime))}>
                          {
                            (!item.modifierDeleted && (item.holidayDigestiveUnit != 1 && item.holidayDigestiveUnit != 2)) && <span>{item.startTime}</span>
                          }
                        </td>

                        {/* <!--退社 --> */}
                        <td style={getStampTimeStyleDisplayableEmpty(item.endTimeAlert, item.modifierApplicationStatus, (!item.modifierDeleted && item.endTime), item.errorEndStampFlg, item.modifierDeleted)}>
                          {
                            (!item.modifierDeleted && (item.holidayDigestiveUnit != 1 && item.holidayDigestiveUnit != 2)) && <span>{item.endTime}</span>
                          }
                        </td>

                        {
                          displayActualStampingFlag === 1 && (
                          <React.Fragment>
                            {/* <!--出勤（打刻） --> */}
                            <td>
                              {
                                <span>{item.startTimeOrg && item.startTimeOrg}</span>
                              }
                            </td>

                            {/* <!--退勤（打刻） --> */}
                            <td>
                              {
                                <span>{item.endTimeOrg && item.endTimeOrg}</span>
                              }
                            </td>
                          </React.Fragment>
                          )
                        }

                        {/* <!--休憩時刻 --> */}
                        <td style={getStampTimeStyleDisplayableEmpty(item.recessStartTimeAlert, item.modifierApplicationStatus, (!item.modifierDeleted && item.recess != null && Object.keys(item.recess).length), item.errorRecessEndStampFlg, item.modifierDeleted)}>
                          {
                             !item.modifierDeleted && item.recess && item.recess.map((recess: any, index2: any) => (
                               <span key={String(index2)}>
                                 <div className="shadow-sm bg-primary text-white" style={{ borderRadius: '4px', margin: '5px' }}>
                                   {recess.startTime}
                                   ～
                                   {recess.endTime}
                                 </div>
                               </span>
                             ))
                          }
                        </td>

                        {/* <!--普通時間 --> */}
                        <td>
                          {
                            !item.modifierDeleted && <span>{item.daytime}</span>
                          }
                        </td>

                        {/* <!--残業（法定内/法定外の区別なし） --> */}
                        {
                          (item.stampEmployment != null && item.stampEmployment.handleLegalInOvertime == 0 && emp.handleLegalInOvertime == 1) && <td />
                        }
                        {
                          (item.stampEmployment != null && item.stampEmployment.handleLegalInOvertime == 0) && (
                            <td style={getStampTimeStyle(item.normalNonLegalOverTimeAlert, item.applyoverworkApplicationStatusing, (!item.modifierDeleted && item.normalNonLegalOverTime))}>
                              {
                                !item.modifierDeleted && <span>{item.normalNonLegalOverTime}</span>
                              }
                            </td>
                          )
                        }

                        {/* <!--法定内残業時間（法定内/法定外の区別あり） --> */}
                        {
                          (item.stampEmployment != null && (item.stampEmployment.handleLegalInOvertime == 1)) && (
                            <td>
                              {
                                !item.modifierDeleted && <span>{item.normalLegalOverTime}</span>
                              }
                            </td>
                          )
                        }

                        {/* <!--法定外残業時間（法定内/法定外の区別あり） --> */}
                        {
                          (item.stampEmployment != null && (item.stampEmployment.handleLegalInOvertime == 1)) && (
                            <td style={getStampTimeStyle(item.normalNonLegalOverTimeAlert, item.overworkApplicationStatus, (!item.modifierDeleted && item.normalNonLegalOverTime))}>
                              {
                               !item.modifierDeleted && <span>{item.normalNonLegalOverTime}</span>
                              }
                            </td>
                          )
                        }

                        {/* <!--割増残業時間1 --> */}
                        {
                          (item.stampEmployment != null && item.stampEmployment.useExtraOverTime) && (
                            <td style={getStampTimeStyle(item.premiumOverTime1Alert, item.overworkApplicationStatus, (!item.modifierDeleted && item.premiumOverTime1))}>
                              {
                               !item.modifierDeleted && <span>{item.premiumOverTime1}</span>
                              }
                            </td>
                          )
                        }
                        {
                          (item.stampEmployment != null && !item.stampEmployment.useExtraOverTime && emp.useExtraOverTime) && <td />
                        }

                        {/* <!--割増残業時間2 --> */}
                        {
                          (item.stampEmployment != null && item.stampEmployment.useExtraOverTime && item.stampEmployment.extraOverTime2 > 0) && (
                            <td style={getStampTimeStyle(item.premiumOverTime2Alert, item.overworkApplicationStatus, (!item.modifierDeleted && item.premiumOverTime2))}>
                              {
                               !item.modifierDeleted && <span>{item.premiumOverTime2}</span>
                              }
                            </td>
                          )
                        }
                        {
                          (item.stampEmployment != null && !(item.stampEmployment.useExtraOverTime && item.stampEmployment.extraOverTime2 > 0) && (emp.useExtraOverTime && emp.extraOverTime2 > 0)) && <td />
                        }

                        {/* <!--割増残業時間3 --> */}
                        {
                          (item.stampEmployment != null && item.stampEmployment.useExtraOverTime && item.stampEmployment.extraOverTime3 > 0) && (
                            <td style={getStampTimeStyle(item.premiumOverTime3Alert, item.overworkApplicationStatus, (!item.modifierDeleted && item.premiumOverTime3))}>
                              {
                               !item.modifierDeleted && <span>{item.premiumOverTime3}</span>
                              }
                            </td>
                          )
                        }
                        {
                          (item.stampEmployment != null && !(item.stampEmployment.useExtraOverTime && item.stampEmployment.extraOverTime3 > 0) && (emp.useExtraOverTime && emp.extraOverTime3 > 0)) && <td />
                        }

                        {/* <!--深夜時間 --> */}
                        <td style={getStyleAertUnder18MidnightWorkFlg(item.alertUnder18MidnightWorkFlg)}>
                          {
                            !item.modifierDeleted && <span>{item.midnightTime}</span>
                          }
                        </td>

                        {/* <!--深夜法定内残業時間（法定内の区別なし） --> */}
                        {
                          (item.stampEmployment != null && item.stampEmployment.handleLegalInOvertime == 0 && emp.handleLegalInOvertime == 1) && <td />
                        }
                        {
                          (item.stampEmployment != null && item.stampEmployment.handleLegalInOvertime == 0) && (
                            <td style={getStampTimeStyle(item.midnightNonLegalOverTimeAlert, -1, (!item.modifierDeleted && item.midnightNonLegalOverTime))}>
                              {
                                !item.modifierDeleted && <span>{item.midnightNonLegalOverTime}</span>
                              }
                            </td>
                          )
                        }


                        {/* <!--深夜法定内残業時間（法定内の区別あり） --> */}
                        {
                          (item.stampEmployment != null && item.stampEmployment.handleLegalInOvertime == 1) && (
                            <td style={getStampTimeStyle(item.midnightLegalOverTimeAlert, -1, (!item.modifierDeleted && item.midnightLegalOverTime))}>
                              {
                                !item.modifierDeleted && <span>{item.midnightLegalOverTime}</span>
                              }
                            </td>
                          )
                        }

                        {/* <!--深夜法定外残業時間（法定内の区別あり） --> */}
                        {
                          (item.stampEmployment != null && item.stampEmployment.handleLegalInOvertime == 1) && (
                            <td style={getStampTimeStyle(item.midnightNonLegalOverTimeAlert, item.overworkApplicationStatus, (!item.modifierDeleted && item.midnightNonLegalOverTime))}>
                              {
                                !item.modifierDeleted && <span>{item.midnightNonLegalOverTime}</span>
                              }
                            </td>
                          )
                        }

                        {/* <!--深夜割増残業時間1 --> */}
                        {
                          (item.stampEmployment != null && item.stampEmployment.useExtraOverTime) && (
                            <td style={getStampTimeStyle(item.midnightPremiumOverTime1Alert, item.overworkApplicationStatus, (!item.modifierDeleted && item.midnightPremiumOverTime1))}>
                              {
                                !item.modifierDeleted && <span>{item.midnightPremiumOverTime1}</span>
                              }
                            </td>
                          )
                        }
                        {
                          (item.stampEmployment != null && !item.stampEmployment.useExtraOverTime && emp.useExtraOverTime) && <td />
                        }


                        {/* <!--深夜割増残業時間2 --> */}
                        {
                          (item.stampEmployment != null && item.stampEmployment.useExtraOverTime && item.stampEmployment.extraOverTime2 > 0) && (
                            <td style={getStampTimeStyle(item.midnightPremiumOverTime2Alert, item.overworkApplicationStatus, (!item.modifierDeleted && item.midnightPremiumOverTime2))}>
                              {
                                !item.modifierDeleted && <span>{item.midnightPremiumOverTime2}</span>
                              }
                            </td>
                          )
                        }
                        {
                          (item.stampEmployment != null && !(item.stampEmployment.useExtraOverTime && item.stampEmployment.extraOverTime2 > 0) && (emp.useExtraOverTime && emp.extraOverTime2 > 0)) && <td />
                        }


                        {/* <!--深夜割増残業時間3 --> */}
                        {
                          (item.stampEmployment != null && item.stampEmployment.useExtraOverTime && item.stampEmployment.extraOverTime3 > 0) && (
                          <td style={getStampTimeStyle(item.midnightPremiumOverTime3Alert, item.overworkApplicationStatus, (!item.modifierDeleted && item.midnightPremiumOverTime3))}>
                            {
                              !item.modifierDeleted && <span>{item.midnightPremiumOverTime3}</span>
                            }
                          </td>
                          )
                        }
                        {
                          (item.stampEmployment != null && !(item.stampEmployment.useExtraOverTime && item.stampEmployment.extraOverTime3 > 0) && (emp.useExtraOverTime && emp.extraOverTime3 > 0)) && <td />
                        }

                        {/* <!--未承認残業時間 --> */}
                        {
                          (item.stampEmployment != null && item.stampEmployment.useOverTimeApplication != 0) && (
                          <td style={getStampTimeStyle(item.unapprovedOverTimeAlert, item.overworkApplicationStatus, (!item.modifierDeleted && item.unapprovedOverTime))}>
                            {
                              !item.modifierDeleted && <span>{item.unapprovedOverTime}</span>
                            }
                          </td>
                          )
                        }
                        {
                          (item.stampEmployment != null && (item.stampEmployment.useOverTimeApplication == 0 && emp.useOverTimeApplication != 0)) && <td />
                        }
                        {
                          item.stampEmployment == null && <td />
                        }

                        <td>
                          {
                            !item.modifierDeleted && <span>{item.recessTime}</span>
                          }
                        </td>
                        <td>
                          {
                            !item.modifierDeleted && <span>{item.lateTime}</span>
                          }
                        </td>
                        <td>
                          {
                            !item.modifierDeleted && <span>{item.earlyTime}</span>
                          }
                        </td>
                        <td>
                          {
                            (!item.modifierDeleted && !item.holidayDeleted) && <span>{item.deemedTime}</span>
                          }
                        </td>
                        <td>
                          {
                            (!item.modifierDeleted && !item.holidayDeleted) && <span>{item.allWorkTime}</span>
                          }
                        </td>

                        {
                          business && business.map((businessItem: any, busIndex: any) => (
                            <td key={businessItem.businessCode}>
                              {
                                (item.achievementId && !item.modifierDeleted) && (
                                  <span>
                                    {item.business && item.business[busIndex] && item.business[busIndex].time}
                                  </span>
                                )
                              }
                            </td>
                          ))
                        }
                        {
                          term && term.map((termItem: any, tIndex: any) => (
                            <td key={termItem.termId}>
                              {
                                (item.achievementId && !item.modifierDeleted) && (
                                  <span>
                                    {item.term && item.term[tIndex] && item.term[tIndex].time}
                                  </span>
                                )
                              }
                            </td>
                          ))
                        }

                        <td>{item.orgName}</td>

                        <td style={{ textAlign: 'left', paddingRight: '35px' }}>
                          {
                            item.stampApplicationNote && (
                              <div>
                                修正申請：
                                {item.stampApplicationNote}
                              </div>
                            )
                          }
                          {
                            item.holidayApplicationNote && (
                              <div>
                                休暇申請：
                                {item.holidayApplicationNote}
                              </div>
                            )
                          }
                          {
                            item.holidayApplicationTime && (
                              <div>
                                {item.holidayApplicationTime}
                              </div>
                            )
                          }
                          {
                            item.overtimeApplicationNote && (
                              <div>
                                残業申請：
                                {item.overtimeApplicationNote}
                              </div>
                            )
                          }
                          {
                            item.holidayWorkApplicationNote && (
                              <div>
                                休日出勤申請：
                                {item.holidayWorkApplicationNote}
                              </div>
                            )
                          }
                          {
                            item.transferApplicationNote && (
                              <div>
                                振替申請：
                                {item.transferApplicationNote}
                              </div>
                            )
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="content-scroll-bottom" onScroll={eventScroll}>
        <div
          id="scrollBottom"
          style={{
            width: 'calc(100% - 232px)', float: 'right', height: '24px', overflow: 'auto',
          }}
        >
          {
            business && term && <div className="scroll" style={{ width: `${offsetWidthTable - 15}px` }} />
          }

        </div>
      </div>
    </div>
  );
};
export default AttendStampTable;
