import React from 'react';

import { useParams } from 'react-router-dom';
import FormContents from 'components/atoms/Form/FormContents';
import TermInputAddForm from './TermInputAddForm';

const TermAddForm: React.FC = () => {
  const {
    termId
  } = useParams();

  return (
    <FormContents>
      <TermInputAddForm termId={termId || ''} />
    </FormContents>
  );
};

export default TermAddForm;
