/* eslint-disable no-param-reassign */
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import PrimaryButton from 'components/atoms/Button';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormContents from 'components/atoms/Form/FormContents';
import FormLabel from 'components/atoms/Form/FormLabel';
import Select from 'components/atoms/Select';
import Checkbox from 'components/molecules/Checkbox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import IconLabelButton from 'components/molecules/IconLabelButton';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BlockUI from 'components/molecules/BlockUi';
import useCreateSettingForm from './hook';

const styles = {
  wrappBtn: css({
    '& path:nth-of-type(2)': {
      fill: 'red !important',
    },
    '& button': {
      padding: '0 !important',
    },
  }),
};

const CreateStoreSettingsForm: React.FC = () => {
  const { editStore } = useParams();
  const history = useHistory();
  const {
    detailList,
    handleAddStoreDetails,
    handleDeleteShift,
    handleChangeCheckboxNextDay,
    handleDeleteShiftTrash,
    handleAdvertising,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    handleDateForm,
    hideOrg,
    orgCode,
    setOrgCode,
    setCategoryAr,
    functionType,
    staffCode,
    isLoading,
    toggle,
    handleSubmitForm,
    checkUserRole,
  } = useCreateSettingForm();
  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <p>{editStore ? '広告プランの組織設定を変更します。広告プラン、適用開始日、適用終了日を編集してください。' : '広告プランを組織に設定します。組織、広告プラン、適用開始日、適用終了日を入力してください。'}</p>
        <OrganizationFilteredTree
          isHide={!!editStore}
          initOrgValue={orgCode}
          disabled={hideOrg}
          orgCallback={(args: string | Array<string>) => {
            setOrgCode(String(args));
          }}
          orgCategoryCallback={
            (args: Array<string>) => {
              setCategoryAr(args);
            }
          }
          isAlignItemsCenter={true}
          functionType={functionType}
          staffCode={staffCode}
          addAllItem={!!toggle}
          required={true}
        />
        {detailList.map((value: any, keyIndex: number) => (
          <div style={{ marginTop: 15, marginBottom: 15 }}>
            <FlexBox>
              <FlexBoxItem>
                <FormLabel
                  required={true}
                  label="広告プラン"
                />
                <Select
                  controlStyle={{ marginTop: 4, width: 250 }}
                  name={`advertisingPlanCode${keyIndex}`}
                  value={value.advertisingPlanCode}
                  setValue={(val: string) => { handleAdvertising(val, keyIndex, 'advertisingPlanCode'); }}
                  options={value.optionAdvertisingName}
                  isDisabled={value.hideTrash}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <span style={{ margin: '0 30px' }} />
              </FlexBoxItem>
              <FlexBoxItem>
                <FormLabel
                  required={true}
                  label="適用開始日"
                />
                <DatePickerForm
                  label=""
                  dateFormat="yyyy年MM月dd日"
                  date={value.applicationStartDate || null}
                  isShowDateOnDisable={hideOrg}
                  disable={value.hideTrash}
                  changeDate={(date:Date) => {
                    if (date !== null) {
                      handleDateForm(keyIndex, 'applicationStartDate', date);
                    }
                  }}
                  isFullWidth={true}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <span style={{ margin: '0 20px' }} />
              </FlexBoxItem>
              <FlexBoxItem>
                <FormLabel
                  label="適用終了日"
                />
                <DatePickerForm
                  label=""
                  dateFormat="yyyy年MM月dd日"
                  disable={value.isDisable}
                  date={value.applicationEndDate || null}
                  changeDate={(date:Date) => {
                    if (date !== null) {
                      handleDateForm(keyIndex, 'applicationEndDate', date);
                    }
                  }}
                  isFullWidth={true}
                />
              </FlexBoxItem>
              <FlexBoxItem marginLeft="20px">
                <div style={{ marginTop: '2.5rem' }}>
                  <Checkbox
                    label="未定"
                    id={`undecided${keyIndex}`}
                    name={`undecided${keyIndex}`}
                    value={value.undecided}
                    checked={value.undecided}
                    onChange={() => handleChangeCheckboxNextDay(
                      keyIndex,
                      'undecided',
                      'isDisable',
                    )}
                  />
                </div>
              </FlexBoxItem>


              <FlexBoxItem marginLeft="20px">
                <FormLabel
                  label="削除"
                />
                {!value.hideTrash ? (
                  <div
                    style={{ marginTop: 10 }}
                    css={css(styles.wrappBtn)}
                  >
                    <IconLabelButton
                      iconType="close"
                      text=""
                      onClick={() => handleDeleteShiftTrash(keyIndex)}
                      padding=""
                      disabled={value.isShow}
                    />
                  </div>
                ) : (
                  <div style={{ marginTop: '1rem' }}>
                    <Checkbox
                      id={`isDelete${keyIndex}`}
                      name={`isDelete${keyIndex}`}
                      value={value.isDelete}
                      checked={value.isDelete}
                      onChange={() => handleDeleteShift(keyIndex, 'isDelete')}
                    />
                  </div>
                )}
              </FlexBoxItem>
            </FlexBox>
          </div>
        ))}
        <div style={{ marginTop: 20 }}>
          <PrimaryButton
            ghost={false}
            text="追加"
            onClick={handleAddStoreDetails}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          {checkUserRole !== null && checkUserRole.editable === 1 && (
            <React.Fragment>
              <PrimaryButton
                ghost={false}
                text={editStore ? '更新' : '登録'}
                onClick={() => setConfirmModalOpen(true)}
              />
              <span style={{ marginRight: 10 }} />
            </React.Fragment>
          )}
          <PrimaryButton
            ghost={true}
            text="戻る"
            onClick={() => {
              history.replace('/salesAdvertisingPlan/advertisingOrganization');
            }}
          />
          <ConfirmActionModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            onSubmit={() => handleSubmitForm()}
            actionType={editStore ? ActionType.UPDATE : ActionType.CREATE}
          />
        </div>
      </FormContents>
    </BlockUI>
  );
};

export default CreateStoreSettingsForm;
