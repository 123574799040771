import React from 'react';
import { css } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BodyText from 'components/atoms/BodyText';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import { UserRegistrationStatusType, Values } from 'utility/constants';
const UserRegistrationStatusRadio: React.FC<{
  userRegistrationStatus:UserRegistrationStatusType;
  setUserRegistrationStatus: (userRegistrationStatus:UserRegistrationStatusType) => void;
}> = ({ userRegistrationStatus, setUserRegistrationStatus }) => (
  <FlexBox
    customStyle={css({
      padding: '8px',
    })}
  >
    <FlexBoxItem basis="10%">
      <BodyText> ユーザー登録 </BodyText>
    </FlexBoxItem>
    <FlexBoxItem basis="90%">
      <RadioSelectForm
        name="UserRegistrationStatusRadio"
        items={[
          { value: '-1', label: 'すべて', },
          ...Object.values(Values.UserRegistrationStatus)
        ]}
        value={userRegistrationStatus}
        setValue={(e) => {
          setUserRegistrationStatus(e.target.value as UserRegistrationStatusType);
        }}
      />
    </FlexBoxItem>
  </FlexBox>
);

export default UserRegistrationStatusRadio;
