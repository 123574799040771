/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Checkbox from 'components/molecules/Checkbox';
import BodyText from 'components/atoms/BodyText';
import DatePicker from 'components/molecules/DatePicker';

const ApplyDateFilter: React.FC<{
  allEmployment: boolean;
  toggleAllEmployment: () => void;
  applyDate: Date;
  // applyDateStr:string
  setApplyDate: (date: Date) => void;
}> = ({
  allEmployment, toggleAllEmployment, applyDate, setApplyDate,
}) => (
  <FlexBox
    customStyle={css({
      padding: '24px 8px 8px 8px',
    })}
  >
    <FlexBoxItem>
      <BodyText>適用年月</BodyText>
    </FlexBoxItem>
    <FlexBoxItem marginLeft="20px">
      <DatePicker
        dateFormat="yyyy年MM月"
        date={applyDate}
        showMonthYearPicker={true}
        isFullWidth={false}
        changeDate={(date: Date) => {
          if (date !== null) {
            setApplyDate(date);
          }
        }}
        disabled={allEmployment}
      />
    </FlexBoxItem>
    <FlexBoxItem marginLeft="24px">
      <Checkbox
        id="allEmployment"
        name="allEmployment"
        value="allEmployment"
        label="全ての雇用形態を表示"
        checked={allEmployment}
        onChange={toggleAllEmployment}
      />
    </FlexBoxItem>
  </FlexBox>
);

export default ApplyDateFilter;
