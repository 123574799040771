import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import RadioTreeFormItem from './RadioTreeFormItem'
import { TRoleMasterList, CategoryMenuList, MenuDetail } from './type'

const RoleMasterTabRadioComponent: React.FC<{
  state: TRoleMasterList,
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>, menuId: string) => void,
  categoryMenuId: string,
}> = ({
  state,
  handleOnChange,
  categoryMenuId
}) => {
    const foundMenuList = state.categoryMenuList && state.categoryMenuList.find((item: CategoryMenuList) => item.categoryMenuId === categoryMenuId)
    const componentMenuDetailData = foundMenuList && foundMenuList.menuDetailData
    const optionList = [
      {
        label: '使用可',
        value: '1',
      },
      {
        label: '使用不可',
        value: '0',
      },
    ]
    return (
      <>
        {
          componentMenuDetailData && componentMenuDetailData.map((item: MenuDetail) => {
            return <FormContents isWidthoutTopBottom={true}>
              <RadioTreeFormItem
                optionList={optionList}
                onChange={handleOnChange}
                data={item}
              />
            </FormContents>
          })
        }
      </>
    );
  };

export default RoleMasterTabRadioComponent;
