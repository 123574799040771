
import { useState, useEffect } from 'react';
import { StampStaffDomain } from 'domain/master/attend/stampStaff';
import { getAttendStampStaffItem, getGpsAddress, postGpsStamp } from 'api/attendStamp';
import useToastNotification from 'hooks/useToastNotification';
// import Geocode from 'react-geocode';

// Geocode.setApiKey('AIzaSyDg0JgU73D5xrFeROkDxbWQd1wEB_m3i2E');
// Geocode.setRegion('ja');

export const useTimeRecorderConfirm = () => {
  const [stampStaff, setStampStaff] = useState<StampStaffDomain | null>(null);
  const orgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [blocking, setBlocking] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  // const [stampType, setStampType] = useState(0);
  // const [businessId, setBusinessId] = useState('');
  let stampType = 0;
  let businessId = '';

  useEffect(() => {
    setBlocking(true);
    getAttendStampStaffItem(orgCode, staffCode)
      .then((response: any) => {
        setStampStaff(response);
        setBlocking(false);
      })
      .catch((error: any) => {
        setBlocking(false);
      });
  }, [orgCode, staffCode]);

  const handleStampEvent = (stamp: string, id: string) => {
    setBlocking(true);
    console.log(`stamp = ${stamp}`);
    console.log(`id = ${id}`);
    if (stamp === 'in') {
      stampType = 0;
      businessId = '';
    } else if (stamp === 'recess_in') {
      stampType = 1;
      businessId = '';
    } else if (stamp === 'recess_out') {
      stampType = 2;
      businessId = '';
    } else if (stamp === 'out') {
      stampType = 3;
      businessId = '';
    } else if (stamp === 'business') {
      stampType = 4;
      businessId = id;
    } else if (stamp === 'current') {
      stampType = 6;
      businessId = '';
    }
    // console.log('出社打刻');
    const geoOptions = { maximumAge: 0, timeout: 10000, enableHighAccuracy: true };
    navigator.geolocation.getCurrentPosition(
      successGeoLacation,
      failGeoLacation,
      geoOptions,
    );
  };

  const successGeoLacation = (pos: any) => {
    console.log(pos);
    getGpsAddress(`${String(pos.coords.latitude)},${String(pos.coords.longitude)}`)
      .then((response: any) => {
        let gAddress: string = response.results[0].formatted_address;
        gAddress = gAddress.replace('日本、', '');
        console.log(`stampType = ${stampType}`);
        console.log(`businessId = ${businessId}`);
        const param = {
          orgCode,
          staffCode,
          procesFlg: stampType,
          businessId,
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
          gpsAddress: gAddress,
        };
        postGpsStamp(param)
          .then((result: any) => {
            getAttendStampStaffItem(orgCode, staffCode)
              .then((result2: any) => {
                setStampStaff(result2);
                successNotification(result.stampResultInfo.split('<h4>').join('')
                  .split('</h4>')
                  .join(''));
                setBlocking(false);
              })
              .catch((error: any) => {
                setBlocking(false);
                if (error.response.status === 400) {
                  if (error.response.data.errors && error.response.data.errors.length > 0) {
                    errorNotification(error.response.data.errors.map((x: any) => x.defaultMessage));
                  } else if (error.response.data.defaultMessage) {
                    errorNotification(error.response.data.defaultMessage);
                  } else {
                    errorNotification(error.response.data.message);
                  }
                } else {
                  errorNotification('サーバー側でエラーが発生しました。');
                }
              });
          })
          .catch((error: any) => {
            setBlocking(false);
            if (error.response.status === 400) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                errorNotification(error.response.data.errors.map((x: any) => x.defaultMessage));
              } else if (error.response.data.defaultMessage) {
                errorNotification(error.response.data.defaultMessage);
              } else {
                errorNotification(error.response.data.message);
              }
            } else {
              errorNotification('サーバー側でエラーが発生しました。');
            }
          });
      })
      .catch((err: any) => {
        errorNotification('位置情報の取得に失敗しました。');
        setBlocking(false);
      });
  };

  const failGeoLacation = (err: any) => {
    if (err.code === 1) {
      errorNotification('位置情報の利用が許可されていませんので、許可してください。');
    } else if (err.code === 2) {
      errorNotification('位置情報が判定できませんでした。');
    } else if (err.code === 3) {
      errorNotification('タイムアウトしました。');
    } else {
      errorNotification('位置情報の取得に失敗しました。');
    }
    // console.log(err);
    setBlocking(false);
  };

  return {
    stampStaff,
    handleStampEvent,
    blocking,
  };
};

export default useTimeRecorderConfirm;
