import { HierarchySet } from 'components/organismos/Sidebar/HierarchyMode';
import reducer from './reducer';
import * as uiSidebarActions from './actions';

export interface UiSidebarState {
  isOpen: boolean;
  hierarchy?: HierarchySet;
}

export {
  uiSidebarActions,
};

export default reducer;
