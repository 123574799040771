import { useState, useEffect } from 'react';
import { getJourneyMasterList } from 'api/journalMaster';
import { getAccountTitleMSTList } from 'api/subjectTitleMST';
import AccountTitleMSTItemDomain from 'domain/master/general/accountTitleMSTItem';
import AccountTitleSubMSTItemDomain from 'domain/master/general/accountTitleSubMSTItem';

export const useJournalMasterItemList = () => {
  const [journalMasterItemList, setJournalMasterItemList] = useState<Array<AccountTitleMSTItemDomain>>([]);
  useEffect(() => {
    getJourneyMasterList().then((response: Array<any>) => {
        if(response.length > 0){
          sessionStorage.setItem('checkJournalData', '1');
        }else{
          sessionStorage.setItem('checkJournalData', '0');
        }
        setJournalMasterItemList(
        response.map((result) => result),
      );
    });
  }, []);

  return { journalMasterItemList, setJournalMasterItemList };
};

export const useCreditAccountTitleSubItemList = (accountTitleId: string) => {
  const [creditAccountTitleSubItemList, setCreditAccountTitleSubItemList] = useState<Array<AccountTitleSubMSTItemDomain>>([]);

  useEffect(() => {
    getAccountTitleMSTList(accountTitleId).then((response: Array<any>) => {
      setCreditAccountTitleSubItemList(
        response.map((result) => new AccountTitleSubMSTItemDomain(result)),
      );
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountTitleId]);
  return { creditAccountTitleSubItemList, setCreditAccountTitleSubItemList };
};

export default {};
