/* eslint-disable react-hooks/rules-of-hooks */
import { useFormik } from 'formik';
import AttendAchievementDomain from 'domain/master/attend/attendAchievementDomain';
import { useEffect, useState } from 'react';
import { getOrganizationsTrees } from 'api/attendAchievementOutput';
import { OptionType } from 'components/atoms/Select';

export const useAttendAchievement = () => {
  const onSubmit = () => {

  };
  const formik = useFormik({
    initialValues: AttendAchievementDomain.generateInitial(),
    validationSchema: false,
    onSubmit,
  });
  return {
    formik,
  };
};

export const useGetSupportSourceOrg = () => {
  const [organizationsTrees, setOrganizationsTrees] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const functionType:number = 2;
    getOrganizationsTrees(staffCode,functionType).then((response:any) => {
      const orgs = response.map((res: any) => ({
        value: res.orgCode,
        label: `${res.orgCode} ${res.orgName}`,
      }));
      setOrganizationsTrees(orgs);
    });
  }, []);
  return { organizationsTrees };
};

export const downloadOriginalPDF = async (response: any, downloadFileName: string) => {
  const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
  const file = new Blob([bom, response], {
    type: 'application/pdf',
  });
  saveAs(file, downloadFileName);
};

export default {
  useAttendAchievement,
};
