import ApiClient from './ApiClient';
import moment from 'moment';
import { AxiosResponse } from 'axios';

export const getListMediaMaster = async (orgCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = { orgCode };

  const response = await ApiClient.get(`/v1/sales/master/paymentMediaRelation/${companyCode}`, params);
  return response.data;
};

export const getMediaMaster = async (media: string) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = { media };
  const response = await ApiClient.get(`/v1/sales/master/paymentMediaRelation/${companyCode}`, params);
  return response.data;
};

export const deleteMediaMaster = async (paymentMediaMstCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    paymentMediaMstCode,
  };

  const response = await ApiClient.delete(`/v1/paymentMediaRelation/delete/${companyCode}`, params);
  return response.data;
};

export const createOrUpdateMediaMaster = async (isCreate: boolean, isConfirm: boolean, mediaMaster: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isCreate: isCreate ? 1 : 0,
    isConfirmed: isConfirm ? 1 : 0,
  };
  const response = await ApiClient.post(`/v1/paymentMediaMstRelation/${companyCode}`, params, mediaMaster);
  return response.data;
};

export const getMediaMasterList = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/paymentMediaRelation/${companyCode}`, {});
  return response.data;
};

export const downloadCSV = async (fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsv(`/v3/paymentMediaMstRelation/csv/download/${companyCode}`, {}, getDownloadCsvFileName(`${fileName}_`)); 
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v3/paymentMediaMstRelation/csv/import/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export type Error = {
  error: string;
  defaultMessage: string;
}
export default getListMediaMaster;
