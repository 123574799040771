/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BlockUI from 'components/molecules/BlockUi';
import TabControl from 'components/atoms/TabControl';
import FormLabel from 'components/atoms/Form/FormLabel';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from 'components/atoms/Button';
import { exportFilePDF } from 'api/shogun';
import DatePickerForm from 'components/molecules/DatePickerForm';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import moment from 'moment';
import BodyText from 'components/atoms/BodyText';
import useToastNotification from 'hooks/useToastNotification';
import { css } from '@emotion/core';
import Modal from 'components/molecules/Modal';
import useSalesMonthlyReport from './hooks';
import CashRegisterReport from './CashRegisterReport';
import useApplicationReport, { downloadOriginalPDF } from './hookApplicationReport';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

type SelectType = 'cashRegisterReport';
const SelectType: { [key in SelectType]: SelectType } = {
  cashRegisterReport: 'cashRegisterReport',
};
const SalesDailyReportLayout = () => {
  const {
    targetOrgCode,
    setTargetOrgCode,
    countAdvertising,
    generalData,
    dataKeyName,
    grandTotalnData,
    sameDayData,
    advertisingData,
    isLoading,
    setIsLoading,
    targetDay,
    setTargetDay,
    cashRegisterReport,
    reportByTimeOfDay,
    setGeneralData,
    updateCountDayAndAdvertising,
    budgetProgressComment,
    storeOperationComment,
    otherComment,
    setBudgetProgressComment,
    setStoreOperationComment,
    setOtherComment,
    paymentMediaAmountManual,
    setPaymentMediaAmountManual,
    attachFileData,
    setAttachFileData,
    discountTypeData,
    setDiscountTypeData,
    setting,
    detailApplicationData,
    expensesData,
    countExpenses,
  } = useSalesMonthlyReport();
  const [selectType, setSelectType] = React.useState<SelectType>(SelectType.cashRegisterReport);
  const [categoryArr, setCategoryArr] = useState<Array<String>>([]);
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [reportStatus, setReportStatus] = useState<number | null>(null);
  const [isStatusVisible, setIsStatusVisible] = useState<boolean>(false);
  const [salesBudgetPlanDailyStatus, setSalesBudgetPlanDailyStatus] = useState<number | null>(null);
  const { errorNotification } = useToastNotification();
  const [orgName, setOrgName] = useState(sessionStorage.getItem('salesMonthlyReport.orgName') || sessionStorage.getItem('loginUser.orgName'));
  const history = useHistory();
  const handleClick = useCallback((usabilityGeneralItem3, usabilityGeneralItem1) => {
    const displayHqSharedMatters = usabilityGeneralItem1 || 0;
    return exportFilePDF(targetOrgCode, targetDay, usabilityGeneralItem3, usabilityGeneralItem1, displayHqSharedMatters)
      .then((response: any) => {
        setIsLoading(false);
        downloadOriginalPDF(response, '売上日報.pdf');
      })
      .catch((error) => {
        setIsLoading(false);
        errorNotification(
          '対象月、対象店舗において在籍するスタッフがいませんでした。',
        );
      });
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [targetOrgCode, targetDay]);

  const getStatus = (status: number | null, budgetStatus: number | null = null) => {
    setReportStatus(status);
    if (budgetStatus !== undefined) setSalesBudgetPlanDailyStatus(budgetStatus);
  };

  const printStatus = (): String => {
    switch (reportStatus) {
      case 0:
        return '申請中';
      case 1:
        return '差し戻し';
      case 2:
        return '承認';
      default:
        // null
        if (salesBudgetPlanDailyStatus === null) return '-';
        if (salesBudgetPlanDailyStatus === 3) return '-';
        return '未申請';// salesBudgetPlanDailyStatus !== 3
    }
  };
  const printColor = (): 'red' | 'green' | 'blue' | 'black' => {
    switch (reportStatus) {
      case 0:
        return 'blue';
      case 1:
        return 'red';
      case 2:
        return 'green';
      default:
        return (salesBudgetPlanDailyStatus === null || salesBudgetPlanDailyStatus === 3) ? 'black' : 'red';
    }
  };

  useApplicationReport(targetDay, targetOrgCode, getStatus, setIsStatusVisible);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === targetOrgCode);

  const checkUserRole = staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  return (
    <div>
      <FormContents>
        <FlexBox>
          <FlexBoxItem basis="8%">
            <FormLabel label="営業日：" />
          </FlexBoxItem>
          <FlexBoxItem basis="1%" />

          <FlexBoxItem basis="20%">
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label=""
              isFullWidth={true}
              date={targetDay}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDay(date);
                  sessionStorage.setItem(
                    'SalesDailyReport.targetDay',
                    moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                  );
                }
              }}
            />
          </FlexBoxItem>
          {
            checkUserRole && checkUserRole.downloadFlag === 1 && selectType === SelectType.cashRegisterReport && (
              <FlexBoxItem basis="70%" customStyle={css({ textAlign: 'right' })}>
                <PrimaryButton ghost={false} text="印刷" onClick={() => { setIsLoading(true); handleClick(checkUserRole.usabilityGeneralItem3, checkUserRole.usabilityGeneralItem1); }} />
              </FlexBoxItem>
            )
          }
        </FlexBox>

        <OrganizationFilteredTree
          functionType={3}
          orgCallback={(args: string | Array<string>) => {
            setTargetOrgCode(String(args));
            sessionStorage.setItem('SalesDailyReport.targetOrgCode', String(args));
          }}
          targetdayForm={targetDay}
          targetdayTo={targetDay}
          initOrgValue={String(targetOrgCode)}
          staffCode={staffCode}
          orgLabel="店舗名"
          addAllItem={false}
          orgCategoryCallback={
            (args: Array<string>) => {
              setCategoryArr(args);
            }
          }
          orgNameCallback={(args: string) => {
            setOrgName(args);
          }}
        />
        {isStatusVisible
          && (
            <BodyText size="re">
              レポート状況：
              <strong style={{ marginLeft: 60, color: printColor() }}>{printStatus()}</strong>
            </BodyText>
          )}
        <div style={{ marginTop: '20px' }}>
          <BlockUI blocking={isLoading}>
            <TabControl
              items={[
                {
                  value: SelectType.cashRegisterReport,
                  display: ' レジレポート',
                },
              ]}
              setValue={setSelectType as (arg: string) => void}
              currentValue={selectType}
              isFixed={true}
            />
            <div style={{ marginTop: '20px' }}>
              {/* レジレポート */}
              {selectType === SelectType.cashRegisterReport
                && (
                  <CashRegisterReport
                    dailyData={generalData}
                    cashRegisterReport={cashRegisterReport}
                    setIsLoading={setIsLoading}
                    orgName={orgName}
                    targetOrgCode={targetOrgCode}
                    targetDay={targetDay}
                    setGeneralData={setGeneralData}
                    updateCountDayAndAdvertising={updateCountDayAndAdvertising}
                    budgetProgressComment={budgetProgressComment}
                    storeOperationComment={storeOperationComment}
                    otherComment={otherComment}
                    setBudgetProgressComment={setBudgetProgressComment}
                    setStoreOperationComment={setStoreOperationComment}
                    setOtherComment={setOtherComment}
                    paymentMediaAmountManual={paymentMediaAmountManual}
                    setPaymentMediaAmountManual={setPaymentMediaAmountManual}
                    attachFileData={attachFileData}
                    setAttachFileData={setAttachFileData}
                    discountTypeData={discountTypeData}
                    setDiscountTypeData={setDiscountTypeData}
                    getStatus={getStatus}
                    sameDayData={sameDayData}
                    setting={setting || []}
                    detailApplicationData={detailApplicationData}
                    selectType={selectType}
                    roleScreen={checkUserRole}
                  />
                )}
            </div>
          </BlockUI>
        </div>
      </FormContents>
    </div>
  );
};
export default SalesDailyReportLayout;
