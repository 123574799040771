/** @jsx jsx */
import React from 'react';
import BodyText from 'components/atoms/BodyText';
import { css, jsx } from '@emotion/core';
import useAttendTimeRecorder from './hooks';
import PrimaryButton from 'components/atoms/Button';

const styles = {

  header: css({
    margin: '29px 0 32px 0',
    textAlign: 'center',
    width: '100%'
  }),
  timeTitle: css({
    fontSize: '64px',
  }),
  button: css({
    left: '77px',
    top: '85px',
    position: 'absolute'
  })
};
const TimeRecorderTimeHeader: React.FC<{
  attendStampReturnUrl?: any,
}> = ({
  attendStampReturnUrl,
}) => {
  const {
    currentDay,
    timeNow,
  } = useAttendTimeRecorder();

  const handleRedirect = (attendStampReturnUrl: string) => {
    window.location.href = attendStampReturnUrl;
  }

  const query = new URLSearchParams(window.location.search);
  const params = query.get('use_close');

  return (
    <React.Fragment>
      <div>
        { attendStampReturnUrl && attendStampReturnUrl !== '' && params === '1' &&
          <div css={styles.button}>
            <PrimaryButton text="閉じる" onClick={() => { handleRedirect(attendStampReturnUrl)}} minWidth="100px" />
          </div>
        }
        <div css={styles.header}>
          <BodyText size="xxl">{currentDay}</BodyText>
          <br />
          <BodyText customStyle={styles.timeTitle}>{timeNow}</BodyText>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TimeRecorderTimeHeader;
