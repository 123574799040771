/* eslint-disable no-mixed-operators */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import moment from 'moment';
import React from 'react';
import { isMacOs } from 'react-device-detect';
import { Link } from 'react-router-dom';
import useDailySalesManage from './hook';

const styles = {
  link: css`
    a {
     color:#0000EE !important;
     text-decoration: underline; 
    }    
  `,
};

const DailySalesManagementTable: React.FC<{
    targetDateFrom: Date,
    targetDateTo: Date,
    dailyReport: any,
    getMediaMst: any,
    getCreditMst: any,
    targetPeriodData: any,
    orgLabel: any,
    orgCode:any,
    categoryAry: any,
    checkUserRole: any,
    loadingCallback?: (args: any) => void;
}> = ({
  targetDateFrom,
  targetDateTo,
  dailyReport,
  getMediaMst,
  getCreditMst,
  targetPeriodData,
  orgLabel,
  orgCode,
  categoryAry,
  checkUserRole,
  loadingCallback,
}) => {
  const { downloadDaySeparateCsv, downloadDaySeparateExcel, commentSubjectForBudgetProcess,
    commentSubjectForStoreManagement,
    commentSubjectForOthers } = useDailySalesManage();
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const functionType = 3;
  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0, maximumFractionDigits: 0,
  });
  let bottomRow: any = null;
  const param = {
    orgCode,
    monthlyStartDate: moment(targetDateFrom).format('YYYY/MM/DD'),
    monthlyEndDate: moment(targetDateTo).format('YYYY/MM/DD'),
    loginStaffCode: staffCode,
    functionType,
    categoryAry: categoryAry.length > 0 ? String(categoryAry) : '',
  };
  return (
    <div>
      {checkUserRole && checkUserRole.downloadFlag === 1 && (
      <FlexBox>
        <FlexBoxItem>
          <IconLabelButton
            onClick={() => {
              if (loadingCallback) {
                loadingCallback(true);
              }
              downloadDaySeparateCsv(param).then(() => {
                if (loadingCallback) {
                  loadingCallback(false);
                }
              });
            }}
            iconType="download"
            text=" CSV出力"
          />
        </FlexBoxItem>
        <FlexBoxItem>
          <IconLabelButton
            onClick={() => {
              if (loadingCallback) {
                loadingCallback(true);
              }
              downloadDaySeparateExcel(param).then(() => {
                if (loadingCallback) {
                  loadingCallback(false);
                }
              });
            }}
            iconType="download"
            text=" EXCEL出力"
          />
        </FlexBoxItem>
      </FlexBox>
      )}

      {orgCode && orgCode !== 'all' && (
        <div style={{ margin: '15px' }}>
          <div
            className="templateTable newDesignTable"
            style={{
              height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: isMacOs ? '35px' : '1px',
            }}
          >
            <table className="table table-bordered text-nowrap">
              <thead>
                {/* frist row */}
                <tr>
                  {/* left */}
                  <th
                    rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 2}
                    colSpan={3}
                    style={{
                      top: '0', left: '0', zIndex: 99, maxWidth: '500px', width: '500px', minWidth: '500px',
                    }}
                    className="text-center stickyStyle fristColSticky"
                  >
                    <span>売上</span>

                  </th>
                  <th colSpan={getMediaMst ? 8 + getMediaMst.length + (getCreditMst.length * getCreditMst.length * 2) : 8} style={{ top: '0' }} className="text-center stickyStyle">売上</th>
                  {/* right */}
                  <th colSpan={2} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>値引・割引券</span></th>
                  <th colSpan={2} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>VOID</span></th>
                  <th colSpan={2} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>会計中止</span></th>
                  <th colSpan={2} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>メニュー取消</span></th>
                  <th colSpan={4} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>レジ金</span></th>
                  <th rowSpan={getMediaMst && getMediaMst.length > 0 ? 4 : 4} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>データ手入力</span></th>
                  <th colSpan={3} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>コメント</span></th>
                  <th
                    rowSpan={getMediaMst && getMediaMst.length > 0 ? 4 : 4}
                    style={{
                      top: '0', maxWidth: ' 150px', width: '150px', minWidth: '150px',
                    }}
                    className="text-center stickyStyle"
                  >
                    <span style={{ width: '100%' }}>精算時間</span>
                  </th>
                  <th
                    rowSpan={getMediaMst && getMediaMst.length > 0 ? 4 : 4}
                    style={{
                      top: '0', maxWidth: ' 150px', width: '150px', minWidth: '150px',
                    }}
                    className="text-center stickyStyle"
                  >
                    <span style={{ width: '100%' }}>深夜酒類届け出有無</span>
                  </th>
                </tr>
                {/* flexible second row  */}
                <tr>
                  {(getMediaMst && getMediaMst.length > 0) && (<th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>純売上</span></th>)}
                  {(getMediaMst && getMediaMst.length > 0) && (<th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>消費税</span></th>)}
                  {(getMediaMst && getMediaMst.length > 0) && (<th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>総売上</span></th>)}
                  {(getMediaMst && getMediaMst.length > 0) && (<th colSpan={getMediaMst.length} style={{ top: '34px' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>支払い種別</span></th>)}
                  {getCreditMst && getCreditMst.length > 0 && getMediaMst && getMediaMst.length > 0
                  && (
                    <th colSpan={getCreditMst.length * getCreditMst.length * 2} style={{ top: '34px' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>クレジット詳細</span></th>
                  )}
                  {(getMediaMst && getMediaMst.length > 0) && (<th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>金券販売</span></th>)}
                  {(getMediaMst && getMediaMst.length > 0) && (<th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>客数</span></th>)}
                  {(getMediaMst && getMediaMst.length > 0) && (<th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>客単価</span></th>)}
                  {(getMediaMst && getMediaMst.length > 0) && (<th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>組数</span></th>)}
                  {(getMediaMst && getMediaMst.length > 0) && (<th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>組単価</span></th>)}
                </tr>
                {/* third row */}
                <tr>
                  {/* left */}

                  <th rowSpan={2} style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px', left: '0', zIndex: 99 }} className="text-center stickyStyle fristColSticky"><span>店舗コード</span></th>
                  <th rowSpan={2} style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px', left: '168px', zIndex: 99 }} className="text-center stickyStyle fristColSticky"><span>店舗名</span></th>
                  <th
                    rowSpan={2}
                    style={{
                      top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px', left: '336px', zIndex: 99,
                    }}
                    className="text-center stickyStyle fristColSticky"
                  >
                    <span>日付</span>
                  </th>
                  {/* right */}
                  { !(getMediaMst && getMediaMst.length > 0) && (
                    <React.Fragment>
                      <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle"><span>純売上</span></th>
                      <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle"><span>消費税</span></th>
                      <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle"><span>総売上</span></th>
                    </React.Fragment>
                  )}
                  {
                    getMediaMst && getMediaMst.length > 0
                    && getMediaMst.map((media: any, index: any) => (
                      <th rowSpan={2} key={index + media} className="text-center stickyStyle" style={{ fontSize: '12px', top: '68px' }}><span>{media}</span></th>
                    ))
                  }
                  {
                    getCreditMst && getCreditMst.map((media: any, index: any) => (
                      <th colSpan={getCreditMst.length * 2} key={index + 1} className="text-center stickyStyle" style={{ fontSize: '12px', top: (getMediaMst && getMediaMst.length > 0) ? '68px' : '34px' }}><span>{media}</span></th>
                    ))
                  }
                  { !(getMediaMst && getMediaMst.length > 0) && (
                    <React.Fragment>
                      <th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>金券販売</span></th>
                      <th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>客数</span></th>
                      <th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>客単価</span></th>
                      <th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>組数</span></th>
                      <th rowSpan={3} style={{ top: '34px' }} className="text-center stickyStyle"><span>組単価</span></th>
                    </React.Fragment>
                  )}
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>金額</span></th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>枚数</span></th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>金額</span></th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>件数</span></th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>金額</span></th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>件数</span></th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>金額</span></th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>件数</span></th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>釣銭準備金</span></th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>現金在高</span></th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>現金過不足</span></th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2}><span>銀行入金</span></th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2} title={commentSubjectForBudgetProcess}>
                    <div style={{borderBottom: '1px' ,overflow:'hidden' ,whiteSpace:'nowrap',textOverflow:'ellipsis'}}>
                      {commentSubjectForBudgetProcess}
                    </div>
                  </th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2} title={commentSubjectForStoreManagement}>
                    <div style={{borderBottom: '1px' ,overflow:'hidden' ,whiteSpace:'nowrap',textOverflow:'ellipsis'}}>
                      {commentSubjectForStoreManagement}
                    </div>
                  </th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" rowSpan={2} title={commentSubjectForOthers}>
                    <div style={{borderBottom: '1px' ,overflow:'hidden' ,whiteSpace:'nowrap',textOverflow:'ellipsis'}}>
                      {commentSubjectForOthers}
                    </div>
                  </th>
                </tr>
                <tr>
                  {
                    getCreditMst && getCreditMst.map((media: any, index: any) => (
                      <React.Fragment key={index}>
                        <th colSpan={getCreditMst.length} className="text-center stickyStyle" style={{ fontSize: '12px', top: (getMediaMst && getMediaMst.length > 0) ? '102px' : '68px' }}><span>件数</span></th>
                        <th colSpan={getCreditMst.length} className="text-center stickyStyle" style={{ fontSize: '12px', top: (getMediaMst && getMediaMst.length > 0) ? '102px' : '68px' }}><span>金額</span></th>
                      </React.Fragment>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                dailyReport && dailyReport.length > 0 ? dailyReport.map((item: any, index: any) => {
                  bottomRow = item;
                  if (item.targetDate !== null) {
                    return (
                      <tr key={item.id}>
                        {/* left */}
                        <td style={{ left: '0', zIndex: 95 }} className="text-center stickyStyle fristColSticky"><span>{item.orgCode}</span></td>
                        <td style={{ left: '168px', zIndex: 95 }} className="text-center stickyStyle "><span>{item.orgName}</span></td>
                        <td style={{ left: '336px', zIndex: 95 }} className="text-center stickyStyle ">
                          <span css={styles.link}>
                            <Link to={`/salesDailyReport/${item.orgCode}/${moment(item.targetDate, 'YYYY-MM-DD').format('YYYY-MM-DD')}`}>
                              {moment(item.targetDate, 'YYYY-MM-DD').format('YYYY年MM月DD日（ddd）')}
                            </Link>
                          </span>
                        </td>
                        {/* right */}
                        <td className="text-right"><span>{formatter.format(item.netSales)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.tax)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.totalSales)}</span></td>
                        {
                          item.mediaList && item.mediaList.length > 0
                            ? item.mediaList.map((mediaData: any, idx: any) => (
                              <td className="text-right" key={mediaData.paymentMediaCode}><span>{formatter.format(mediaData.paymentMediaAmount)}</span></td>
                            )) : null
                        }
                        {
                          item.creditList && item.creditList.length > 0
                            ? item.creditList.map((creditData: any, idx: any) => (
                              <React.Fragment key={idx}>
                                <td colSpan={getCreditMst.length} className="text-right" key={creditData.creditCnt}><span>{formatter.format(creditData.creditCnt)}</span></td>
                                <td colSpan={getCreditMst.length} className="text-right" key={creditData.creditCompanyMstCode + 1}><span>{formatter.format(creditData.creditAmount)}</span></td>
                              </React.Fragment>
                            )) : null
                        }
                        <td className="text-right"><span>{formatter.format(item.cashVoucherSalesTotalAmount)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.guestCnt)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.perCustomerPrice)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.groupCnt)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.perGroupPrice)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.discountAmount)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.discountCnt)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.voidAmount)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.voidCnt)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.stopAccountAmount)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.stopAccountCnt)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.cancelMenuAmount)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.cancelMenuCnt)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.changeReserve)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.cashStock)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.cashExcessOrDeficiency)}</span></td>
                        <td className="text-right"><span>{formatter.format(item.bankDeposit)}</span></td>
                        <td className="text-center"><span>{(item.editData)}</span></td>
                        <td className="text-left"><span>{item.budgetProgressComment }</span></td>
                        <td className="text-left"><span>{item.storeOperationComment }</span></td>
                        <td className="text-left"><span>{item.otherComment}</span></td>
                        <td className="text-center"><span>{item.clearingDatetime}</span></td>
                        <td className="text-center"><span>{item.lateNightAlcoholOfferNotificationType}</span></td>
                      </tr>
                    );
                  }
                }) : null
              }
                {/* footer */}
                <tr style={{ fontWeight: 'bolder' }}>
                  <td colSpan={3} className="text-center stickyStyle fristColSticky" style={{ top: '0', left: '0', zIndex: 99 }}><span>合計</span></td>
                  {bottomRow && (
                  <React.Fragment>
                    <td className="text-right"><span>{formatter.format(bottomRow.netSales)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.tax)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.totalSales)}</span></td>
                    {
                        bottomRow.mediaList && bottomRow.mediaList.length > 0
                          ? bottomRow.mediaList.map((mediaData: any, idx: number) => (
                            <React.Fragment key={idx}>
                              <td className="text-right" key={mediaData.paymentMediaCode}>
                                <span>
                                  {formatter.format(mediaData.paymentMediaAmount)}
                                </span>
                              </td>
                            </React.Fragment>
                          )) : null
                    }
                    {
                        bottomRow.creditList && bottomRow.creditList.length > 0
                          ? bottomRow.creditList.map((creditData: any, idx: number) => (
                            <React.Fragment key={idx}>
                              <React.Fragment>
                                <td colSpan={getCreditMst.length} className="text-right" key={creditData.creditCnt}><span>{formatter.format(creditData.creditCnt)}</span></td>
                                <td colSpan={getCreditMst.length} className="text-right" key={creditData.creditCompanyMstCode + 1}><span>{formatter.format(creditData.creditAmount)}</span></td>
                              </React.Fragment>
                            </React.Fragment>
                          )) : null
                    }
                    <td className="text-right"><span>{formatter.format(bottomRow.cashVoucherSalesTotalAmount)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.guestCnt)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.perCustomerPrice)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.groupCnt)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.perGroupPrice)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.discountAmount)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.discountCnt)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.voidAmount)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.voidCnt)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.stopAccountAmount)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.stopAccountCnt)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.cancelMenuAmount)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.cancelMenuCnt)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.changeReserve)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.cashStock)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.cashExcessOrDeficiency)}</span></td>
                    <td className="text-right"><span>{formatter.format(bottomRow.bankDeposit)}</span></td>
                    <td className="text-center"><span>{(bottomRow.editData)}</span></td>
                    <td className="text-left"><span>{bottomRow.budgetProgressComment}</span></td>
                    <td className="text-left"><span>{bottomRow.storeOperationComment }</span></td>
                    <td className="text-left"><span>{bottomRow.otherComment}</span></td>
                    <td className="text-center"><span>{bottomRow.clearingDatetime }</span></td>
                    <td className="text-center"><span>{bottomRow.lateNightAlcoholOfferNotificationType}</span></td>
                  </React.Fragment>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default DailySalesManagementTable;
