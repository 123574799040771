import { useEffect, useCallback, useState } from 'react';
import { getCommentList, downloadCommentCsv, downloadCommentExcel } from 'api/commentList';
import CommentListDomain from 'domain/master/storeManagement/commentList';
import moment from 'moment';
import { getTimeSetting } from 'api/salesSetting';

export const useCommentList = (
  orgCode: string,
  monthlyStartDateTarget: string,
  monthlyEndDateTarget: string,
  stateType: number,
  functionType: number,
  categoryAry: any,
) => {
  const [commentList, setCommentList] = useState<Array<CommentListDomain>>([]);
  const [blocking, setBlocking] = useState(false);
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const [commentSubjectForBudgetProcess , setCommentSubjectForBudgetProcess] = useState('予算進捗について');
  const [commentSubjectForStoreManagement , setCommentSubjectForStoreManagement] = useState('店舗経営について');
  const [commentSubjectForOthers , setCommentSubjectForOthers] = useState('その他');
  

  // データの取得
  const fetchData = useCallback(async () => {
    setBlocking(true);
    try {
      const monthlyStartDate = moment(monthlyStartDateTarget).format('YYYY-MM-DD');
      const monthlyEndDate = moment(monthlyEndDateTarget).format('YYYY-MM-DD');
      if (new Date(monthlyStartDate) > new Date(monthlyEndDate)) {
        setBlocking(false);
        setCommentList([]);
      } else {
        const response = await getCommentList(orgCode, monthlyStartDate, monthlyEndDate, stateType, functionType, categoryAry);
        setCommentList(response.map((result: any) => new CommentListDomain(result)));
      }
    // eslint-disable-next-line no-empty
    } catch (error) {
      setCommentList([]);
    }
    setBlocking(false);
  }, [orgCode, monthlyStartDateTarget, monthlyEndDateTarget, stateType, functionType, categoryAry]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    getTimeSetting(companyCode).then((response: any) => {
      setCommentSubjectForBudgetProcess(response.commentSubjectForBudgetProcess)
      setCommentSubjectForStoreManagement(response.commentSubjectForStoreManagement)
      setCommentSubjectForOthers(response.commentSubjectForOthers)
    })
  }, [companyCode]);

  return { commentList, setCommentList, blocking,
    commentSubjectForBudgetProcess, commentSubjectForStoreManagement,
    commentSubjectForOthers};
};

export const useDownload = () => {
  const downloadCsv = (data: any) => {
    downloadCommentCsv(data, '日次コメント帳票');
  };

  const downloadExcel = (data: any) => {
    downloadCommentExcel(data, '日次コメント帳票');
  };

  return {
    downloadCsv,
    downloadExcel,
  };
};

export default { useCommentList };
