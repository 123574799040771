import React from 'react';
import { css } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BodyText from 'components/atoms/BodyText';
import Select from 'components/atoms/Select';
import useEmploymentOptions from 'hooks/useEmploymentOptions';

const EmploymentFilter: React.FC<{
  employmentId: string;
  setEmploymentId: (employmentId: string) => void;
}> = ({ employmentId, setEmploymentId }) => {
  const employmentOptions = useEmploymentOptions();

  return (
    <FlexBox
      customStyle={css({
        padding: '8px',
      })}
    >
      <FlexBoxItem basis="100px">
        <BodyText> 雇用形態 </BodyText>
      </FlexBoxItem>
      <FlexBoxItem basis="400px">
        <Select
          name="orgState"
          value={employmentId}
          setValue={(arg: string) => { setEmploymentId(arg); }}
          options={employmentOptions}
        />
      </FlexBoxItem>
    </FlexBox>
  );
};

export default EmploymentFilter;
