import ApiClient from './ApiClient';

export interface AchievementConfirm {
  orgName: string,
  orgCode: string,
  closingDate: string,
  dateFrom: string,
  dateTo: string,
  confirmStatus: string,
  unConfirmFlag: string,
  ownCnt: string,
}

export const searchDate = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const query = {
    ...params,
    closeDateFlg: false,
    targetDate: '',
  };

  const response = await ApiClient.get(`/v1/employments/searchDate/${companyCode}`, {}, query);
  return response.data;
};

export const getListClosingDate = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v1/employments/selectListClosingDate/${companyCode}`, {}, query);
  return response.data;
};

export const getListEmployment = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v1/employments/selectListRangeAndClosingDate/${companyCode}`, {}, query);
  return response.data;
};

export const getEmployment = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v1/employments/applied/all/${companyCode}`, {}, query);
  return response.data;
};


export const getOrganizationsTrees = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    ...params,
  };

  // TODO: V4にバージョンアップ予定
  const response = await ApiClient.get(`/v3/organizations/trees/${companyCode}`, {}, query);
  return response.data;
};


// TODO: /v2/staffs/listをコールしていない理由を要確認
export const getListStaff = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v1/staffs/list/${companyCode}`, {}, query);
  return response.data;
};


export const getStamplist = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v2/stamplist/list/${companyCode}`, {}, query);
  return response.data;
};


export const getHolidayUsingStatus = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v1/stamplist/holiday/usingstatus/${companyCode}`, {}, query);
  return response.data;
};

export const getStampSubTotal = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v1/stamplist/subtotal/${companyCode}`, {}, query);
  return response.data;
};

export const getListOutputLayout = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const response = await ApiClient.get(`/v1/outputlayouts/${companyCode}`, {}, {});
  return response.data;
};

export const achievementConfirm = async (params: any, url: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    ...params,
  };

  const reponse = await ApiClient.post(`/v2/achievementConfirm/${url}/${companyCode}`, {}, query);
  return reponse.data;
};

export const achievementAllConfirmV3 = async (params: any, url: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    ...params,
  };

  const reponse = await ApiClient.post(`/v3/achievementConfirm/${url}/${companyCode}`, {}, query);
  return reponse.data;
};
export const getCsvOutput = async (params: any, fileName: string, categoryAry:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const query = {
    ...params,
  };
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  await ApiClient.downloadCsvNoConvert(`/v5/achievementConfirm/csvOutput/${companyCode}?${appendUrl}`, query, fileName);
};

export const getTimeList = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    ...params,
  };

  const reponse = await ApiClient.get(`/v2/achievementConfirm/timeDayList/${companyCode}`, {}, query);
  return reponse.data;
};

export const getAchievementConfirmV3 = async (
  targetDateFrom: string,
  targetDateTo: string,
  viewPeriod:any,
  employmentId: string,
  closingDate: string,
  orgCode:string,
  functionType:number,
  categoryAry:any,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    orgCode,
    functionType,
    targetDateFrom,
    targetDateTo,
    viewPeriod,
    employmentId,
    closingDate,
    loginStaffCode
  };
  const reponse = await ApiClient.get(`/v3/achievementConfirm/${companyCode}?${appendUrl}`, params);
  return reponse.data;
};
