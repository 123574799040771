/** @jsx jsx */
import React, { useState, useCallback, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Amplify, { Auth } from 'aws-amplify';
import awsExports from 'aws-exports'; // 設定情報を読み込みます。

import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import PasswordForm from 'components/molecules/PasswordForm';
import Button, { PrimaryButton } from 'components/atoms/Button';
import { useHistory, Link, useParams } from 'react-router-dom';
import {
  getItem, handleHelpUrlForScreen, saveLoginHistory, getMenuItems,
  getRoleByPath,
} from 'api/login';

import LoginUserDomain from 'domain/loginUser';
import useToastNotification from 'hooks/useToastNotification';
import BlockUI from 'components/molecules/BlockUi';
import ClickableLink from 'components/atoms/ClickableLink';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { isMobileOnly } from 'react-device-detect';
import { SidebarItemType } from 'components/organismos/Sidebar';
import Modal from 'components/molecules/Modal';
import useLoginUser from 'hooks/useLoginUser';
import { InfoMessage } from 'utility/messages';

const styles = {
  formFotter: css({
    padding: '0.2em 0.5em',
    textAlign: 'center',
    fontSize: '0.75em',
  }),
  formHeader: css({
    padding: '0.2em 0.5em',
    textAlign: 'center',
    fontSize: '0.75em',
    marginTop: '5px',
  }),
  'formFotter a': css({
    textDecoration: 'none',
    color: '#0070d2',
  }),
  'formHeader a': css({
    textDecoration: 'none',
    color: '#0070d2',
  }),
  textMessage: css({
    color: 'black',
  }),
};

const LOCAL_STORAGE_SHOP_INFO_KEY_NAME: string = 'win-board-localstorage-shop-info';

Amplify.configure(awsExports); // 設定情報をAmplifyに反映させます。

const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('形式がemailではありません')
    .required('メールアドレスを入力してください'),
  password: Yup.string()
    .min(6, 'パスワードは6文字以上で入力して下さい')
    .required('パスワードを入力してください'),
});

const LoginInputForm: React.FC = () => {
  const [blocking, setBlocking] = useState(false);
  const { errorNotification } = useToastNotification();
  const history = useHistory();
  const { storeLoginUserToSessionStorage } = useLoginUser();
  const showTimeRecorder = localStorage.getItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME);
  const { approvalMode } = useParams<{ approvalMode: string }>();
  const { applicationId } = useParams<{ applicationId: string }>();

  const [openModalWithoutStaff, setOpenModalWithoutStaff] = useState(false);

  const getPath = useCallback((array: Array<any>) => {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i].href === '/') {
        return array[i];
      }
      if (array[i].hierarchyItems && array[i].hierarchyItems !== null) {
        return array[i].hierarchyItems[0];
      }
    }
  }, []);

  const fetchRoleOperationalReportQuick = useCallback(async (flag: boolean) => {
    await getRoleByPath(String('/shogun/operationalReportQuick')).then((role: any) => {
      if (role) {
        if (role[0].available !== undefined && role[0].available === true) {
          history.push('/shogun/operationalReportQuick');
          localStorage.setItem('topPageSP', '/shogun/operationalReportQuick');
        } else if (flag) {
          if (sessionStorage.getItem('mobileWorkAchievement') && sessionStorage.getItem('mobileWorkAchievement') === '1') {
            history.push('/workAchievement');
            localStorage.setItem('topPageSP', '/workAchievement');
          } else if (sessionStorage.getItem('mobileSchedule') && sessionStorage.getItem('mobileSchedule') === '1') {
            history.push('/schedule');
            localStorage.setItem('topPageSP', '/schedule');
          }
        } else {
          history.push('/dashboard');
          localStorage.setItem('topPageSP', '/dashboard');
        }
      } else if (flag) {
        if (sessionStorage.getItem('mobileWorkAchievement') && sessionStorage.getItem('mobileWorkAchievement') === '1') {
          history.push('/workAchievement');
          localStorage.setItem('topPageSP', '/workAchievement');
        } else if (sessionStorage.getItem('mobileSchedule') && sessionStorage.getItem('mobileSchedule') === '1') {
          history.push('/schedule');
          localStorage.setItem('topPageSP', '/schedule');
        }
      } else {
        history.push('/dashboard');
        localStorage.setItem('topPageSP', '/dashboard');
      }
    }).catch(() => {
      if (flag) {
        if (sessionStorage.getItem('mobileWorkAchievement') && sessionStorage.getItem('mobileWorkAchievement') === '1') {
          history.push('/workAchievement');
          localStorage.setItem('topPageSP', '/workAchievement');
        } else if (sessionStorage.getItem('mobileSchedule') && sessionStorage.getItem('mobileSchedule') === '1') {
          history.push('/schedule');
          localStorage.setItem('topPageSP', '/schedule');
        }
      } else {
        history.push('/dashboard');
        localStorage.setItem('topPageSP', '/dashboard');
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      setBlocking(true);
      Auth.signIn(formik.values.email, formik.values.password)
        .then((user: any) => {
          if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            // Do something...
            console.log(`user.challengeName = ${user.challengeName}`);
          } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // Do something...
            console.log(`user.challengeName = ${user.challengeName}`);
          } else if (user.challengeName === 'MFA_SETUP') {
            // Do something...
            console.log(`user.challengeName = ${user.challengeName}`);
          } else {
            console.log('ログインしました');
            console.log('loginUserを確認します');

            getItem(formik.values.email)
              .then((response: any) => {
                const loginUser = new LoginUserDomain(response);
                console.log(loginUser);
                console.log(loginUser.companyCode);
                // Save staff permission to session
                console.log(`loginUser.mail = ${loginUser.mail}`);
                storeLoginUserToSessionStorage(loginUser);

                setBlocking(false);
                if (loginUser.retired) {
                  console.error('ユーザー登録の途中です');
                  errorNotification('退職済みのためログインできません');
                  saveLoginHistory(formik.values.email, false, '', '退職済みのためログインできません');
                } else {
                  saveLoginHistory(formik.values.email, true, '', '');
                  // if (isMobileOnly) {
                  //   history.push('/dashboard');
                  // } else if (!isMobileOnly) {
                  //   history.push('/');
                  // } else {
                  //   history.push(`/${approvalMode}/${applicationId}`);
                  // }
                  getMenuItems(loginUser.companyCode, loginUser.staffCode)
                    .then((menuItems: any) => {
                      const tmpMenu: Array<SidebarItemType> = [];
                      if (menuItems.length) {
                        menuItems.forEach((menuParent: any) => {
                          if (menuParent.isSeparate && menuParent.isSeparate === true) {
                            tmpMenu.push({ separate: true });
                            return;
                          }

                          // eslint-disable-next-line no-param-reassign
                          menuParent.icon = menuParent.iconType;
                          if (!menuParent.hierarchyItems) {
                          // eslint-disable-next-line no-param-reassign
                            delete menuParent.hierarchyItems;
                          }

                          if (menuParent.href !== null && menuParent.href === '/') {
                            sessionStorage.setItem('mobileDashboard', '1');
                          }
                          if (menuParent.href === null && menuParent.iconType === 'workSchedule' && menuParent.hierarchyItems && menuParent.hierarchyItems.length > 0) {
                            menuParent.hierarchyItems.map((menuChild: any) => {
                              if (menuChild.to === '/schedule') {
                                sessionStorage.setItem('mobileSchedule', '1');
                              }
                              if (menuChild.to === '/workAchievement') {
                                sessionStorage.setItem('mobileWorkAchievement', '1');
                              }
                            });
                          }
                          tmpMenu.push(menuParent);
                        });
                      }
                      const pathFound = getPath(tmpMenu);
                      if (pathFound.href && pathFound.href !== null) {
                        if (isMobileOnly) {
                          fetchRoleOperationalReportQuick(false);
                        // sessionStorage.setItem('mobileDashboard', '1');
                        } else {
                          localStorage.setItem('topPagePC', '/dashboard');
                          history.push('/dashboard');
                        }
                      } else if (isMobileOnly) {
                        fetchRoleOperationalReportQuick(true);
                        // history.push('/workAchievement');
                        // localStorage.setItem('topPageSP', '/workAchievement')
                        // sessionStorage.setItem('mobileDashboard', '0');
                      } else {
                        history.push(pathFound.to);
                        localStorage.setItem('topPagePC', pathFound.to);
                      }
                    }).catch(() => {
                      history.push('/personalSetting');
                      localStorage.setItem('topPageSP', '/personalSetting');
                      localStorage.setItem('topPagePC', '/personalSetting');
                    });
                  if (isMobileOnly && !sessionStorage.getItem('mobileWorkAchievement') && !sessionStorage.getItem('mobileSchedule') && !sessionStorage.getItem('mobileDashboard')) {
                    history.push('/personalSetting');
                    localStorage.setItem('topPageSP', '/personalSetting');
                    localStorage.setItem('topPagePC', '/personalSetting');
                  }
                }
              })
              .catch((error: any) => {
                setBlocking(false);
                const { response } = error;
                if (response?.status === 503) {
                  // ログイン制限モード（503を返す）
                  errorNotification(InfoMessage.Common.MAINTENANCE);
                } else {
                  errorNotification('スタッフ情報が登録されていないためログインできません');
                  saveLoginHistory(formik.values.email, false, '', 'スタッフ情報が登録されていないためログインできません');
                }
              });
          }
        })
        .catch((err: any) => {
          setBlocking(false);
          if (err.code === 'UserNotConfirmedException') {
            console.error('ユーザー登録の途中です');
            errorNotification('ユーザー登録の途中です');
            saveLoginHistory(formik.values.email, false, '', 'ユーザー登録の途中です');
          } else if (err.code === 'PasswordResetRequiredException') {
            console.error('パスワードを変更する必要があります');
            errorNotification('パスワードを変更する必要があります');
            saveLoginHistory(formik.values.email, false, '', 'パスワードを変更する必要があります');
          } else if (err.code === 'NotAuthorizedException') {
            console.error('ユーザー名またはパスワードが異なります');
            errorNotification('ユーザー名またはパスワードが異なります');
            saveLoginHistory(formik.values.email, false, formik.values.password, 'ユーザー名またはパスワードが異なります');
          } else if (err.code === 'UserNotFoundException') {
            setOpenModalWithoutStaff(true);
            console.error('ユーザー名またはパスワードが異なります');
            saveLoginHistory(formik.values.email, false, formik.values.password, 'ユーザー名またはパスワードが異なります');
          } else {
            console.error(err);
            console.error('予期しないエラーが発生しました error');
            errorNotification('予期しないエラーが発生しました');
            saveLoginHistory(formik.values.email, false, formik.values.password, '予期しないエラーが発生しました');
          }
        });
    },

  });
  const handleEnterKeyPress = (e: any) => {
    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
      formik.handleSubmit();
    }
  };

  useEffect(() => {
    if (localStorage.getItem('logged') === 'true') {
      if (isMobileOnly) {
        history.push(localStorage.getItem('topPageSP') || '/workAchievement');
      } else {
        history.push(localStorage.getItem('topPagePC') || '/dashboard');
      }
    }
  }, [history]);

  const handleCloseModal = () => {
    setOpenModalWithoutStaff(false);
  };

  return (
    <BlockUI blocking={blocking}>
      <form onSubmit={formik.handleSubmit}>

        <FormField>
          <TextForm
            name="email"
            label="メールアドレス"
            value={formik.values.email}
            onChange={formik.handleChange}
            errorMsg={formik.errors.email}
          />
        </FormField>

        <FormField>
          <PasswordForm
            name="password"
            label="パスワード"
            value={formik.values.password}
            onChange={formik.handleChange}
            errorMsg={formik.errors.password}
            onKeyPress={handleEnterKeyPress}
          />
        </FormField>

        <FormSubmitArea>
          <Button
            text="ログイン"
            onClick={formik.handleSubmit}
            fullwidth={true}
          />
        </FormSubmitArea>
        <div css={styles.formHeader}>
          <Link to="/signup" css={styles['formFotter a']}>
            ユーザ登録はこちらから
          </Link>
        </div>
        <div css={styles.formHeader}>
          <Link to="/activation" css={styles['formFotter a']}>
            認証コードの再送、入力はこちら
          </Link>
        </div>
        <div css={styles.formFotter}>
          <Link to="/forgotPassword" css={styles['formFotter a']}>
            パスワードを忘れた方
          </Link>
        </div>
        <div css={styles.formFotter}>
          <Link onClick={() => handleHelpUrlForScreen('ログイン')} to="#" css={styles['formFotter a']}>
            ヘルプサイトへ
          </Link>
        </div>

        {
          showTimeRecorder
            ? (
              <FlexBox>
                <FlexBoxItem marginLeft="auto" marginRight="auto">
                  <ClickableLink
                    to="/timeRecorder"
                    target="_blank"
                  >
                    <PrimaryButton text="タイムレコーダー" ghost={true} />
                  </ClickableLink>
                </FlexBoxItem>
              </FlexBox>
            )
            : ''
        }
        {/*         <div css={styles.formFotter}>
          <Link to="/activation" css={styles['formFotter a']}>
            認証コードの再送、入力はこちら
          </Link>
        </div>
 */}
        {' '}

      </form>
      <Modal
        open={openModalWithoutStaff}
        closeHandler={handleCloseModal}
        title="確認"
        submitText=""
        onSubmit={handleCloseModal}
        isShowButtonClose={false}
      >
        <p css={styles.textMessage}>ユーザー登録が完了していません。</p>
        <p css={styles.textMessage}>
          <Link to="/signup">こちら</Link>
          からユーザー登録を行ってください。
        </p>
      </Modal>
    </BlockUI>
  );
};

export default LoginInputForm;
