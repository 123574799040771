/** @jsx jsx */
import { useState, useMemo, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import moment from 'moment';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { Column } from 'react-table';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import DataTable from 'components/organismos/DataTable/DataTable';
import BlockUI from 'components/molecules/BlockUi';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import CommentListDomain from 'domain/master/storeManagement/commentList';
import ClickableLink from 'components/atoms/ClickableLink';
import { useCommentList, useDownload } from '../hook';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const optionTimeRepresentation = [
  {
    label: '登録済',
    value: '0',
  },
  {
    label: '未登録',
    value: '1',
  },
  {
    label: '全て',
    value: '2',
  },
];

const CommentListManagement = () => {
  const datefrom = new Date(moment().subtract(1, 'days').toString());
  const dateTo = new Date(moment().toString());
  const [targetDateFrom, setTargetDateFrom] = useState(datefrom);
  const [targetDateTo, setTargetDateTo] = useState(dateTo);
  const [orgTreesOptions, setOrgTreesOptions] = useState([]);
  const functionType = 3;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';


  const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
    undefined,
  );
  const [orgCode, setOrgCode] = useState('all');
  const [orgCodeName, setOrgCodeName] = useState('全ての組織')
  const [stateStatus, setStateStatus] = useState<number>(0);

  const { downloadCsv, downloadExcel } = useDownload();

  const { commentList, blocking, commentSubjectForBudgetProcess, commentSubjectForStoreManagement,
    commentSubjectForOthers} = useCommentList(
    orgCode || '',
    moment(targetDateFrom).format('YYYY-MM-DD'),
    moment(targetDateTo).format('YYYY-MM-DD'),
    stateStatus,
    functionType,
    categoryAry,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRedirectDailyReport = (targetDay: any, targetOrgCode: string) => {
    if (!targetOrgCode || targetOrgCode === 'all') return;
    sessionStorage.setItem('SalesDailyReport.targetDay', targetDay);
    sessionStorage.setItem('SalesDailyReport.targetOrgCode', targetOrgCode);
    let orgCodeName = '';
    orgTreesOptions.filter((item: any) => {
      if (item.value === targetOrgCode) {
        orgCodeName = item.label;
        return true;
      }
      return false;
    });
    sessionStorage.setItem('SalesDailyReport.targetOrgName', orgCodeName);
    sessionStorage.setItem('SalesDailyReport.tabCashRegister', '1');
    window.open('/salesDailyReport', '_blank');
  };

  const targetDataColumn: any[] = [];
  const mergedRegions: any[] = [];
  const targetPeriod:any[] = [];

  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  // eslint-disable-next-line no-nested-ternary
  const sessionSupportSourceStore = orgCode !== 'all' ? (sessionStorage.getItem('SessionSupportStore') ? sessionStorage.getItem('SessionSupportStore') : '') : '';

  const targetStoresData = `組織名：${orgCodeName}`;

  const targetPeriodData = `対象期間：${moment(targetDateFrom).format('YYYY/MM/DD')}~${moment(targetDateTo).format('YYYY/MM/DD')}`;

  const headerInformation = [
    ['日付', '店舗コード', '店舗名', '状態', commentSubjectForBudgetProcess, commentSubjectForStoreManagement, commentSubjectForOthers, '最終更新日', '最終更新者'],
  ];

  const searchResult: any[] = [];
  useEffect(() => {
    commentList.map((item: any, index: any) => {
      searchResult.push([
        item.targetMonth ? moment(item.targetMonth).format('YYYY-MM-DD') : '',
        item.orgCode || '',
        item.orgName || '',
        item.stateString || '',
        item.budgetProgressComment || '',
        item.storeOperationComment || '',
        item.otherComment || '',
        item.updateDate ? moment(item.updateDate).format('YYYY-MM-DD') : '',
        item.updateUser || '',
      ]);
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentList, searchResult]);

  const timeRepresentation = `コメント：${optionTimeRepresentation[stateStatus].label}`;

  const data = {
    footer: '',
    formName: '日次コメント帳票',
    targetPeriod: targetPeriodData,
    targetStores: targetStoresData,
    timeRepresentation,
    headerInformation,
    searchResult,
    totalInformation: [],
  };

  const dataExcel = {
    formName: '日次コメント帳票',
    targetPeriod: targetPeriodData,
    targetStores: targetStoresData,
    timeRepresentation,
    headerInformation,
    searchResult,
    totalInformation: [],
    mergedRegions,
    codeColumnIndex: [0],
  };

  const downloadFileCSV = () => downloadCsv(data);
  const downloadFileExcel = () => downloadExcel(dataExcel);

  const columns: Array<Column<CommentListDomain>> = useMemo(
    () => [
      {
        Header: '日付',
        accessor: 'targetMonth',
        Cell: (cell: {
          row: { isExpanded: boolean; original: CommentListDomain };
          data: Array<CommentListDomain>;
        }) => (
          <div>
            <ClickableLink
              onClick={() => handleRedirectDailyReport(moment(cell.row.original.targetMonth).format('YYYY-MM-DD'), cell.row.original.orgCode)}
            >
              {moment(cell.row.original.targetMonth).format('YYYY-MM-DD')}
            </ClickableLink>
          </div>
        ),
      },
      {
        Header: '店舗コード',
        accessor: 'orgCode',
      },
      {
        Header: '店舗名',
        accessor: 'orgName',
      },
      {
        Header: '状態',
        accessor: 'stateString',
      },
      {
        Header: commentSubjectForBudgetProcess,
        accessor: 'budgetProgressComment',
      },
      {
        Header: commentSubjectForStoreManagement,
        accessor: 'storeOperationComment',
      },
      {
        Header: commentSubjectForOthers,
        accessor: 'otherComment',
      },
      {
        Header: '最終更新日',
        accessor: 'updateDate',
        Cell: (cell: {
          row: { isExpanded: boolean; original: CommentListDomain };
          data: Array<CommentListDomain>;
        }) => (
          <div>
            {cell.row.original.updateDate !== null && moment(cell.row.original.updateDate).format('YYYY-MM-DD')}
          </div>
        ),
      },
      {
        Header: '最終更新者',
        accessor: 'updateUser',
      },
    ],
    [handleRedirectDailyReport],
  );

  const handleChange = async (e: any) => {
    const val = e.target.value;
    setStateStatus(val);
  };

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <BlockUI blocking={blocking}>
      <div>
        <FormContents>
          <FlexBox>
            <FlexBoxItem width="100px">
              <FormLabel
                label="対象期間"
              />
            </FlexBoxItem>

            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月dd日"
                label=""
                date={targetDateFrom}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateFrom(date);
                  }
                }}
                isFullWidth={true}
              />
            </FlexBoxItem>

            <FlexBoxItem>
              <span style={{ margin: '0 20px' }}>～</span>
            </FlexBoxItem>

            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月dd日"
                label=""
                date={targetDateTo}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateTo(date);
                  }
                }}
                isFullWidth={true}
              />
            </FlexBoxItem>
          </FlexBox>

          <OrganizationFilteredTree
            functionType={functionType}
            orgCallback={(args: string | Array<string>) => {
              setOrgCode(String(args));
            }}
            orgNameCallback={(args: string | Array<string>) => {
              sessionStorage.setItem('SessionSupportStore', String(args));
              setOrgCodeName(String(args));
            }}
            orgCategoryCallback={
                (args: Array<string>) => {
                  setCategoryAr(args);
                }
            }
            initOrgValue={String(orgCode)}
            staffCode={loginStaffCode}
            orgLabel="組織名"
            addAllItem={true}
            targetdayForm={targetDateFrom}
            targetdayTo={targetDateTo}
            orgOrgOptionListCallback={setOrgTreesOptions}
          />

          <FlexBox>
            <FlexBoxItem width="105px">
              <FormLabel label="コメント" />
            </FlexBoxItem>
            <FlexBoxItem
              width="100%"
              customStyle={css({
                marginTop: '-5px',
              })}
            >
              <RadioSelectForm
                label=""
                subLabel=""
                items={optionTimeRepresentation}
                name="timeDisplayFormat"
                value={String(stateStatus)}
                setValue={(e) => handleChange(e)}
              />
            </FlexBoxItem>
          </FlexBox>
          
          {
            roleScreen && roleScreen.downloadFlag === 1 && (
            <FlexBox customStyle={css({ margin: '15px 0' })}>
              <FlexBoxItem>
                <IconLabelButton
                  onClick={downloadFileCSV}
                  iconType="download"
                  text="CSVダウンロード"
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <IconLabelButton
                  onClick={downloadFileExcel}
                  iconType="download"
                  text="EXCELダウンロード"
                />
              </FlexBoxItem>
            </FlexBox>
          )}
          
          <div
            css={css`
                table {
                  white-space: pre;
                }
                table th:nth-child(1), table>tbody>tr>td:nth-child(1) {
                    width: 80px;
                }
                table th:nth-child(2), table>tbody>tr>td:nth-child(2)  {
                    width: 100px;
                }
                table th:nth-child(3), table>tbody>tr>td:nth-child(3)  {
                    width: 200px;
                }
                table th:nth-child(4), table>tbody>tr>td:nth-child(4)  {
                    width: 60px;
                }
                table th:nth-child(5), table>tbody>tr>td:nth-child(5)  {
                    width: 300px;
                    overflow: visible;
                    white-space: pre-wrap;
                }
                table th:nth-child(6), table>tbody>tr>td:nth-child(6)  {
                    width: 300px;
                    overflow: visible;
                    white-space: pre-wrap;
                }
                table th:nth-child(7), table>tbody>tr>td:nth-child(7)  {
                    width: 300px;
                    overflow: visible;
                    white-space: pre-wrap;
                }
                table th:nth-child(8), table>tbody>tr>td:nth-child(8)  {
                    width: 90px;
                }
                table th:nth-child(9), table>tbody>tr>td:nth-child(9)  {
                    width: 90px;
                }
                `}
          >
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
              <DataTable
                columns={columns}
                data={commentList}
                isGlobalFilter={true}
                minWidth="1552px"
                sortBy={[
                  {id: 'targetMonth', desc: false}
                ]}
              />
            </div>
          </div>
        </FormContents>
      </div>
    </BlockUI>
  );
};

export default CommentListManagement;
