import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

export const getDigestionItemsForPaidHolidayStatus = async (
  orgCode: string,
  startDigestedDay: any,
  endDigestedDay: any,
  employmentId: string,
  isHasTenGrantDays: boolean,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    orgCode,
    startDigestedDay,
    endDigestedDay,
    employmentId,
    isHasTenGrantDays,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/paidHolidayStatus/${companyCode}`, params);
  return response.data;
};

export const getDigestionItemsForPaidHolidayStatus2 = async (
  orgCode: string,
  startDigestedDay: any,
  endDigestedDay: any,
  employmentId: string,
  isHasTenGrantDays: boolean,
  closingDate: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    orgCode,
    startDigestedDay,
    endDigestedDay,
    employmentId,
    isHasTenGrantDays,
    closingDate,
  };
  const response = await ApiClient.get(`/v2/holidayManagement/paidHolidayStatus/${companyCode}`, params);
  return response.data;
};

export const getDigestionItemsForTransferHolidayStatus = async (
  orgCode: string,
  targetDate: any,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    orgCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/transferHolidayStatus/${companyCode}`, params);
  return response.data;
};

export const getDigestionItemsForSpecialHolidayStatus = async (
  orgCode: string,
  employmentId: string,
  holidayId: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    orgCode,
    employmentId,
    holidayId,
  };
  const response = await ApiClient.get(`/v2/holidayManagement/specialHolidayStatus/${companyCode}`, params);
  return response.data;
};

/**
 * saveUseItem
 *
 * @param companyCode
 * @param dataShiftPattern
 */
export const saveUseItemForPaidHolidayStatus = async (companyCode: string, data: any) => {
  // TODO パラメータ確認
  const query = {
  };
  const response = await ApiClient.post(`/v1/holidayManagement/save/paidHolidayStatus/${companyCode}`, query, data);
  return response.data;
};

/**
 * saveUseItem
 *
 * @param companyCode
 * @param dataShiftPattern
 */
export const saveUseItemForSpecialHoliday = async (companyCode: string, data: any) => {
  // TODO パラメータ確認
  const query = {
  };
  const response = await ApiClient.post(`/v1/holidayManagement/save/specialHoliday/${companyCode}`, query, data);
  return response.data;
};

export const getGrantItemsForPaidHolidayGrantHistory = async (
  orgCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
  paidGrantResult: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
    paidGrantResult,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/paidHolidayGrantHistory/${companyCode}`, params);
  return response.data;
};


export const getGrantItemsForSpecialHolidayGrantHistory = async (
  orgCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
  };
  const response = await ApiClient.get(`/v1/holidayManagement/specialHolidayGrantHistory/${companyCode}`, params);
  return response.data;
};

export const getDigestionItemsForSubstituteHolidayStatus = async (
  orgCode: string,
  targetDate: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    orgCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/substituteHolidayStatus/${companyCode}`, params);
  return response.data;
};

export const getDigestionItemsForSpecialHoliday = async (
  orgCode: string,
  targetDate: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    orgCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/specialHolidayDigestion/${companyCode}`, params);
  return response.data;
};

export const getDigestionItemsForPaidHolidayDigestedtHistory = async (
  orgCode: string,
  targetDate: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    orgCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/paidHolidayDigestedtHistory/${companyCode}`, params);
  return response.data;
};

export const getDigestionItemsDetail = async (
  staffCode: string,
  targetDate: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/paidHolidayDigestionDetail/${companyCode}`, params);
  return response.data;
};

export const getSpecialHolidayStatusDetail = async (
  staffCode: string,
  staffName: string,
  holidayId: string,
  holidayName: string,
  specialHolidayGrantId: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    holidayId,
    holidayName,
    staffName,
    specialHolidayGrantId,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/specialHolidayStatusDetail/${companyCode}`, params);
  return response.data;
};

export const getTransferHolidayStatusDetail = async (
  staffCode: string,
  targetDate: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/transferDigestedtHistory/${companyCode}`, params);
  return response.data;
};

export const getPaidHolidayStatusDetail = async (
  staffCode: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/paidHolidayStatusDetail/${companyCode}`, params);
  return response.data;
};

export const getSpecialHolidayDigestionDetail = async (
  staffCode: string,
  targetDate: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/specialHolidayDigestionDetail/${companyCode}`, params);
  return response.data;
};

export const getSubstituteDigestedtHistoryDetail = async (
  staffCode: string,
  targetDate: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/substituteDigestedtHistory/${companyCode}`, params);
  return response.data;
};

export const getRestDigestedDetail = async (
  staffCode: string,
  targetDate: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/staff/detail/${companyCode}`, params);
  return response.data;
};

/**
 * CSVファイルインポート
 */

export const downloadPaidHolidayDigestionCsv = async (
  orgCode: string,
  targetDate: Date,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDate: moment(targetDate).format('YYYY/MM/DD'),
  };
  const nameCsvExport = '有給取得状況_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/paidHolidayDigestion/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadSubstituteHolidayCsv = async (
  orgCode: string,
  targetDate: Date,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDate: moment(targetDate).format('YYYY/MM/DD'),
  };
  const nameCsvExport = '代休取得状況_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/substituteHoliday/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadSpecialHolidayGrantHistoryCsv = async (
  orgCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
  };
  const nameCsvExport = '特休付与履歴_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/specialHolidayGrantHistory/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadSpecialHolidayStatusCsv = async (
  orgCode: string,
  employmentId: string,
  holidayId: string,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    employmentId,
    holidayId,
  };
  const nameCsvExport = '特休管理情報_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/specialHolidayStatus/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadPaidHolidayGrantHistoryCsv = async (
  orgCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
  paidGrantResult: string,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
    paidGrantResult,
  };
  const nameCsvExport = '有給付与履歴_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/paidHolidayGrantHistory/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadPaidHolidayStatusCsv = async (
  orgCode: string,
  startDigestedDay: string,
  endDigestedDay: string,
  employmentId: string,
  isHasTenGrantDays: boolean,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    startDigestedDay,
    endDigestedDay,
    employmentId,
    isHasTenGrantDays,
  };
  const nameCsvExport = '有給管理情報_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/paidHolidayStatus/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadTransferHolidayCsv = async (
  orgCode: string,
  targetDate: string,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDate,
  };
  const nameCsvExport = '振休取得状況_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/transferHoliday/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadSpecialHolidayDigestionCsv = async (
  orgCode: string,
  targetDate: Date,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDate: moment(targetDate).format('YYYY/MM/DD'),
  };
  const nameCsvExport = '特休取得状況_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/specialHolidayDigestion/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};


/**
 * CSVファイルインポート
 */
export const importFileCSV = async (uploadFile: File, formatType?: number): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    formatType: Number(formatType),
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v1/holidayManagement/csv/import/history/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export type Error = {
  error: string;
  defaultMessage: string;
}

export default getDigestionItemsForPaidHolidayStatus;
