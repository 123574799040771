/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  eqeqeq */
/* eslint-disable  no-mixed-operators */
import React, {
  useState,
  useRef,
  RefObject,
  useEffect,
  createContext,
  useContext
} from "react";
import {
  TimelineItemBase,
  TimelineGroupCustom
} from "types/react-calendar-timeline";
import {
  DailyShiftContextType,
  StaffInfo,
  ShiftPattern,
  EmploymentOptionType,
  IDailyShift
} from "../type";
import moment, { Moment } from "moment";
import useToastNotification from "hooks/useToastNotification";
import { ContextMenuType } from "components/molecules/ContextMenu/type";
import { useHistory } from "react-router-dom";
import Icon from "components/atoms/Icon";
import {
  fetchDrawingInfoDaily,
  fetchDailyShift,
  getShiftPatterns,
  downloadDailyShiftPdf,
  getDailyAddStaffs,
  ShiftDailyStaff,
} from "../api";
import { getEmploymentSelectList } from "api/employment";
import { registerHoliday, deleteShiftV1, postShiftV1 } from "api/shift";
import { addShiftPattern } from "api/shiftPattern";
import { shiftConfirmReleaseV1, shiftConfirmV1 } from "api/shiftConfirm";
import { OptionType } from "components/atoms/Select";
import { useParams } from 'react-router-dom';

export const dailyShiftContext = createContext<DailyShiftContextType>(
  {} as DailyShiftContextType
);

export const useDailyShiftContext = (): DailyShiftContextType => {
  const context = useContext(dailyShiftContext);
  return context;
};

export const useInitialState = () => {
  const params = useParams<any>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [remote, setRemote] = useState<boolean>(true);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [checkall, setCheckall] = useState<boolean>(
    sessionStorage.getItem(
      `0/sessionData${window.location.pathname}.employmentOptions`
    ) === "all"
  );
  const [dailyShift, setDailyShift] = useState<IDailyShift>({} as IDailyShift);
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const history = useHistory();
  const { errorNotification, successNotification } = useToastNotification();
  const [loadEmploymentDone, setLoadEmploymentDone] = useState<boolean>(false);

  /* -------------------------------------------------------------------------- */
  /*                                Filter                                      */
  /* -------------------------------------------------------------------------- */
  const [dateFromStr, setDateFromStr] = useState<string>(
    params.targetDate !== undefined ? moment(params.targetDate).format("YYYY/MM/DD") : (sessionStorage.getItem("attendStampList.dailyShift.targetDateFrom") ||
      moment()
        .startOf("day")
        .format("YYYY/MM/DD"))
  );
  const [dateToStr, setDateToStr] = useState<string>(
    sessionStorage.getItem("attendStampList.targetDateTo") ||
    moment()
      .endOf("day")
      .format("YYYY/MM/DD")
  );
  const [isAttendExists, setAttendExists] = useState<string>(
    sessionStorage.getItem("attendStampList.attendFlag") || "0"
  );
  const [orgs, setOrgs] = useState<OptionType[]>([]);
  const [employmentOptions, setEmploymentOptions] = useState<
    EmploymentOptionType[]
  >([]);
  const [selectedOrg, setSelectedOrg] = useState<string>(
    params.targetDate !== undefined ? params.orgCode : (sessionStorage.getItem("attendStampList.dailyShift.orgCode") ||
      sessionStorage.getItem("loginUser.orgCode") ||
      "")
  );
  const [selectedOrgName, setSelectedOrgName] = useState<string>(
    params.orgName !== undefined ? params.orgName?.replace('%2F', '/') : (sessionStorage.getItem("dailyShift.orgName") ||
      sessionStorage.getItem("loginUser.orgName") ||
      "")
  );

  const [businessIds, setBusinessIds] = useState<any>([]);

  const [
    selectedCumulativeClosingDate,
    setSelectedCumulativeClosingDate
  ] = useState<string>(
    sessionStorage.getItem(
      "attendStampList.dailyShift.cumulativeClosingDate"
    ) || "1"
  );

  const [orgCodeModal, setOrgCodeModal] = useState("");

  const downloadPdf = async () => {
    setLoading(true);
    let employmentIds = employmentOptions.reduce(
      (totalStr, current) =>
        current.checked ? `${current.id},${totalStr}` : totalStr,
      ""
    );
    downloadDailyShiftPdf(
      selectedOrg,
      moment(dateFromStr).format("YYYY-MM-DD"),
      employmentIds,
      isAttendExists
    )
      .catch(error => {
        errorNotification(
          "対象日、対象組織において在籍するスタッフがいませんでした。"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitShiftLockout = async () => {
    setOpenConfirmModal(false);
    if (dailyShift.isShiftConfirm) {
      await shiftConfirmReleaseV1(
        selectedOrg,
        moment(dateFromStr).format("YYYY/MM/DD"),
        moment(dateFromStr).format("YYYY/MM/DD")
      );
      await fetchDailyShiftData();
      successNotification("シフト確定を解除しました。");
    } else {
      let targetStaffCodes = groups.map(staff => staff.staffCode);
      await shiftConfirmV1(
        selectedOrg,
        moment(dateFromStr).format("YYYY/MM/DD"),
        moment(dateFromStr).format("YYYY/MM/DD"),
        targetStaffCodes
      );
      await fetchDailyShiftData();
      successNotification("シフトを確定しました。");
    }

  };

  /* -------------------------------------------------------------------------- */
  /*                               Calendar                                     */
  /* -------------------------------------------------------------------------- */
  const [items, setItems] = useState<TimelineItemBase<number>[]>([]);
  const [groups, setGroups] = useState<TimelineGroupCustom[]>([]);
  const [selectedItem, setSelectedItem] = useState<TimelineItemBase<
    number
  > | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<TimelineGroupCustom>(
    {} as TimelineGroupCustom
  );
  const [defaultTimeStart, setDefaultTimeStart] = useState<Moment>(
    moment().startOf("day")
  );
  const [defaultTimeEnd, setDefaultTimeEnd] = useState<Moment>(
    moment().endOf("day")
  );
  const [boundaryStart, setBoundaryStart] = useState<number>(
    defaultTimeStart.valueOf()
  );
  const [boundaryEnd, setBoundaryEnd] = useState<number>(
    defaultTimeEnd.valueOf()
  );
  const [selectedItemIdArr, setSelectedItemIdArr] = useState<number[]>([]);
  const sidebarName = 160;
  const sidebarSupport = 0;
  const sidebarType = 100;
  const sidebarHeaderWidth = sidebarName + sidebarSupport + sidebarType;
  const sidebarHeaderRightWidth = 366;
  const [countHeaderHour, setCountHeaderHour] = useState<number>(0);

  const getGroups = (
    staffInfo: StaffInfo[],
    isShiftConfirm: boolean,
    isAchievementConfirm: boolean
  ) => {
    return staffInfo.map((staff, index) => {
      return {
        id: index + 1,
        isShiftConfirm: isShiftConfirm || staff.dateInfo[0].workSystem === 0,
        isAchievementConfirm: isAchievementConfirm,
        rightData: [
          "-",
          staff.dateInfo[0].workTimeShiftDisp,
          staff.dateInfo[0].workTimeAchievementDisp
        ],
        ...staff
      };
    });
  };

  const getRow1Items = (
    staffInfo: StaffInfo[],
    bounceTimeStart: Moment,
    bounceTimeEnd: Moment
  ) => {
    let counter = 0;
    return staffInfo.reduce(
      (total: TimelineItemBase<number>[], staff, staffIndex) => {
        if (staff.dateInfo[0].hope) {
          let hope = staff.dateInfo[0].hope;
          let title = "";
          let hopeStartTime = hope.startTime
            ? moment(hope.startTime)
            : moment(hope.targetDate).startOf("day");
          let hopeEndTime = hope.endTime
            ? moment(hope.endTime)
            : moment(hope.targetDate).endOf("day");

          switch (hope.hopeShiftPatternType) {
            case 1:
              title = "o";
              break;
            case 2:
              title = "出勤できません";
              break;
            case 4:
              title = hope.holidayName || "";
              break;
            case 5:
              title = hope.holidayName || "";
              break;
            default:
              title = `${hope.shiftPatternName} (${hope.shiftTimePdfDisp})`;
              break;
          }

          counter++;
          return total.concat({
            ...hope,
            id: counter,
            group: staffIndex + 1,
            rowId: 1,
            title: title,
            start_time:
              hopeStartTime < bounceTimeStart
                ? bounceTimeStart.valueOf()
                : hopeStartTime.valueOf(),
            end_time:
              hopeEndTime > bounceTimeEnd
                ? bounceTimeEnd.valueOf()
                : hopeEndTime.valueOf(),
            canMove: false,
            canResize: false,
            canSelect: false
          });
        } else {
          return total;
        }
      },
      []
    );
  };

  const getRow2Items = (
    staffInfo: StaffInfo[],
    isConfirm: boolean = false,
    bounceTimeStart: Moment,
    bounceTimeEnd: Moment,
    nextIndex: number
  ) => {
    return staffInfo.reduce(
      (total: TimelineItemBase<number>[], staff, staffIndex) => {
        if (staff.dateInfo[0].shiftList.length > 0) {
          let shiftList = staff.dateInfo[0].shiftList.map(shift => {
            let title = "";
            if (shift.holidayName) {
              title = shift.holidayName;
              if (shift.distinctionHoliday) {
                if (shift.isLegal) {
                  title += "（法定）";
                } else {
                  title += "（所定）";
                }
              }
            } else if (shift.shiftPatternId) {
              title = `${shift.shiftPatternName} (${shift.shiftTimePdfDisp})`;
            } else {
              title = shift.shiftTimePdfDisp;
            }

            // 他店舗に出勤する所属スタッフは支援先の店舗名を付加
            if (shift.orgCode !== selectedOrg) {
              title += ` (${shift.orgName})`;
            }

            nextIndex++;

            let freezeMove =
              moment(shift.endTime!) > bounceTimeEnd ? true : false;

            if (
              (shift.recessTime > 0 && shift.detailList) ||
              shift.workSystem === 3
            ) {
              let breaks = shift.detailList.reduce((arr: any[], detail) => {
                return arr.concat({
                      break_start: moment(detail.startTime).valueOf(),
                      break_end: moment(detail.endTime!).valueOf()
                    });
              }, []);

              let breakStart = 0,
                breakEnd = 0;
              for (let i = 0; i < shift.detailList.length; i++) {
                let detail = shift.detailList[i];
                if (detail.businessId) {
                  breakStart =
                    breakStart === 0
                      ? moment(detail.startTime).valueOf()
                      : breakStart;
                  breakEnd = moment(detail.endTime!).valueOf();
                }
              }

              return {
                ...shift,
                id: nextIndex,
                group: staffIndex + 1,
                rowId: 2,
                title: title,
                start_time:
                  moment(shift.startTime) < bounceTimeStart
                    ? bounceTimeStart.valueOf()
                    : moment(shift.startTime).valueOf(),
                end_time:
                  moment(shift.endTime!) > bounceTimeEnd
                    ? bounceTimeEnd.valueOf()
                    : moment(shift.endTime!).valueOf(),
                break_start: breakStart,
                break_end: breakEnd,
                canMove: !(
                  isConfirm ||
                  shift.holidayId ||
                  shift.workSystem === 0 ||
                  shift.workSystem === 2 ||
                  shift.workSystem === 3 ||
                  freezeMove ||
                  ((shift.orgCode !== shift.belongOrgCode && shift.orgCode !== selectedOrg) || (shift.orgCode === shift.belongOrgCode && shift.orgCode !== selectedOrg))
                ),
                canResize: !(
                  isConfirm ||
                  shift.holidayId ||
                  shift.workSystem === 0 ||
                  shift.workSystem === 2 ||
                  shift.workSystem === 3 ||
                  ((shift.orgCode !== shift.belongOrgCode && shift.orgCode !== selectedOrg) || (shift.orgCode === shift.belongOrgCode && shift.orgCode !== selectedOrg))
                ),
                canSelect: !(
                  isConfirm ||
                  shift.workSystem === 0 ||
                  shift.workSystem === 2 ||
                  shift.workSystem === 3 ||
                  ((shift.orgCode !== shift.belongOrgCode && shift.orgCode !== selectedOrg) || (shift.orgCode === shift.belongOrgCode && shift.orgCode !== selectedOrg))
                ),
                breaks
              };
            }
            console.log(
              "status",
              !(
                isConfirm ||
                staff.isSupportStaff ||
                shift.workSystem === 0 ||
                shift.workSystem === 2 ||
                shift.workSystem === 3
              )
            );
            console.log(
              "statusDetail",
              isConfirm,
              staff.isSupportStaff,
              shift.workSystem === 0,
              shift.workSystem === 2,
              shift.workSystem === 3
            );
            return {
              ...shift,
              id: nextIndex,
              group: staffIndex + 1,
              rowId: 2,
              title: title,
              start_time:
                moment(shift.startTime) < bounceTimeStart
                  ? bounceTimeStart.valueOf()
                  : moment(shift.startTime).valueOf(),
              end_time:
                moment(shift.endTime!) > bounceTimeEnd
                  ? bounceTimeEnd.valueOf()
                  : moment(shift.endTime!).valueOf(),
              canMove: !(
                isConfirm ||
                shift.holidayId ||
                shift.workSystem === 0 ||
                shift.workSystem === 2 ||
                shift.workSystem === 3 ||
                freezeMove ||
                ((shift.orgCode !== shift.belongOrgCode && shift.orgCode !== selectedOrg) || (shift.orgCode === shift.belongOrgCode && shift.orgCode !== selectedOrg))
              ),
              canResize: !(
                isConfirm ||
                shift.holidayId ||
                shift.workSystem === 0 ||
                shift.workSystem === 2 ||
                shift.workSystem === 3 ||
                ((shift.orgCode !== shift.belongOrgCode && shift.orgCode !== selectedOrg) || (shift.orgCode === shift.belongOrgCode && shift.orgCode !== selectedOrg))
              ),
              canSelect: !(
                isConfirm ||
                shift.workSystem === 0 ||
                shift.workSystem === 2 ||
                shift.workSystem === 3 ||
                ((shift.orgCode !== shift.belongOrgCode && shift.orgCode !== selectedOrg) || (shift.orgCode === shift.belongOrgCode && shift.orgCode !== selectedOrg))
              ),
              breaks: []
            };
          });

          return total.concat(shiftList);
        } else {
          return total;
        }
      },
      []
    );
  };

  const getRow3Items = (
    staffInfo: StaffInfo[],
    isConfirm: boolean = false,
    bounceTimeStart: Moment,
    bounceTimeEnd: Moment,
    nextIndex: number
  ) => {
    return staffInfo.reduce(
      (total: TimelineItemBase<number>[], staff, staffIndex) => {
        if (staff.dateInfo[0].achievementList.length > 0) {
          let achievementList = staff.dateInfo[0].achievementList.map(
            achievement => {
              let title = "";
              if (achievement.holidayName) {
                let holidayTypeName = "";

                if (achievement.holidayType === 1) {
                  switch (achievement.holidayDigestiveUnit) {
                    case 0:
                      holidayTypeName = " (全)";
                      break;

                    case 2:
                      holidayTypeName = " (時間)";
                      break;

                    default:
                      break;
                  }
                }

                title = achievement.isHolidayWork
                  ? "休日出勤"
                  : achievement.holidayName + holidayTypeName;
              } else {
                title =
                  achievement.workTimeDisp || achievement.shiftTimePdfDisp;
              }
              nextIndex++;

              if (achievement.detailList.length > 0) {
                let breaks = achievement.detailList.reduce(
                  (arr: any[], detail) => {
                    return detail.businessId
                      ? arr.concat({
                        break_start: moment(detail.startTime).valueOf(),
                        break_end: moment(detail.endTime!).valueOf()
                      })
                      : arr;
                  },
                  []
                );

                let breakStart = 0,
                  breakEnd = 0;
                for (let i = 0; i < achievement.detailList.length; i++) {
                  let detail = achievement.detailList[i];
                  if (detail.businessId) {
                    breakStart =
                      breakStart === 0
                        ? moment(detail.startTime).valueOf()
                        : breakStart;
                    breakEnd = moment(detail.endTime!).valueOf();
                  }
                }
                return {
                  ...achievement,
                  id: nextIndex,
                  group: staffIndex + 1,
                  rowId: 3,
                  title: title,
                  start_time:
                    moment(achievement.startTime) < bounceTimeStart
                      ? bounceTimeStart.valueOf()
                      : moment(achievement.startTime).valueOf(),
                  end_time:
                    moment(achievement.endTime!) > bounceTimeEnd
                      ? bounceTimeEnd.valueOf()
                      : moment(achievement.endTime!).valueOf(),
                  break_start: breakStart,
                  break_end: breakEnd,
                  canMove: false,
                  canResize: false,
                  canSelect: true,
                  breaks
                };
              }
              return {
                ...achievement,
                id: nextIndex,
                group: staffIndex + 1,
                rowId: 3,
                title: title,
                start_time:
                  moment(achievement.startTime) < bounceTimeStart
                    ? bounceTimeStart.valueOf()
                    : moment(achievement.startTime).valueOf(),
                end_time:
                  moment(achievement.endTime!) > bounceTimeEnd
                    ? bounceTimeEnd.valueOf()
                    : moment(achievement.endTime!).valueOf(),
                canMove: false,
                canResize: false,
                canSelect: true,
                breaks: []
              };
            }
          );

          return total.concat(achievementList);
        } else {
          return total;
        }
      },
      []
    );
  };

  const onTimeChange = (
    visibleTimeStart: number,
    visibleTimeEnd: number,
    updateScrollCanvas: Function
  ) => {
    if (visibleTimeStart < boundaryStart && visibleTimeEnd > boundaryEnd) {
      updateScrollCanvas(boundaryStart, boundaryEnd);
    } else if (visibleTimeStart < boundaryStart) {
      updateScrollCanvas(
        boundaryStart,
        boundaryStart + (visibleTimeEnd - visibleTimeStart)
      );
    } else if (visibleTimeEnd > boundaryEnd) {
      updateScrollCanvas(
        boundaryEnd - (visibleTimeEnd - visibleTimeStart),
        boundaryEnd
      );
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    }
  };

  const onItemMove = async (
    itemId: number,
    dragTime: number,
    newGroupOrder: number
  ) => {
    let newItems = JSON.parse(JSON.stringify(items)), //deep copy
      item = newItems[itemId - 1],
      newStartTimestamp: number = dragTime,
      timeGap: number = dragTime - item.start_time,
      newEndTimestamp: number = item.end_time + timeGap;

    //round-up start time & end time when move back from rightEdge
    if (newEndTimestamp === boundaryEnd && item.end_time !== boundaryEnd) {
      newStartTimestamp += 1000;
    }
    if (item.end_time === boundaryEnd && newEndTimestamp !== boundaryEnd) {
      newEndTimestamp += 1000;
    }
    //.

    if (item.break_start && item.break_end) {
      let timeStartToBreakStartRange = item.break_start - item.start_time,
        timeStartToBreakEndRange = item.break_end - item.start_time;

      item.break_start = newStartTimestamp + timeStartToBreakStartRange;
      item.break_end = newStartTimestamp + timeStartToBreakEndRange;
    }

    item.start_time = newStartTimestamp;
    item.end_time = newEndTimestamp;

    try {
      setLoading(true);
      let submitItem = formatSubmitObjItemMove(item, timeGap);

      setItems(newItems);
      await postShiftV1(submitItem);
      await fetchDailyShiftData();
    } catch (error) {
      setLoading(false);
      errorNotification(error.response.data.message);
      setItems(items);
    } finally {
      setLoading(false);
      setSelectedItemIdArr([]);
    }
  };

  const onItemSelect = (itemId: number, e: any, time: number) => {
    setSelectedItemIdArr([itemId]);
  };

  //when item is already selected
  const onItemClick = async (
    itemId: string | number,
    e: MouseEvent,
    time: number
  ) => {
    let item:any = items.find(item => item.id === itemId);
    let staff:any = groups.find(staff => staff.id === item?.group);
    setSelectedItemIdArr([Number(itemId)]);
    if (staff && item) {
      setContextMenuY(e.pageY);
      setContextMenuX(e.pageX);
      setSelectedGroup(staff);

      if (item.rowId === 2) {
        setSelectedItem(item);
        let itemButtons: ContextMenuType[] = [];
        var shiftPatterns: any = [];
        await getShiftPatterns(
          staff.employmentId,
          selectedOrg
        ).then((response) => {
          shiftPatterns = response;
        }).catch(() => {
          shiftPatterns = [];
        });
        itemButtons = ((item.orgCode !== item.belongOrgCode && item.orgCode !== selectedOrg) || (item.orgCode === item.belongOrgCode && item.orgCode !== selectedOrg))
        ? [] : getMenuItemButtons(
          staff.staffCode,
          staff.staffName,
          dateFromStr,
          shiftPatterns,
          item.distinctionHoliday,
        );
        setShiftPatternOptions(shiftPatterns);
        setMenuItemButtons(itemButtons);
        setItemMenuVisible(true);
      }

      if (item.rowId === 3) {
        let performanceButtons = getMenuPerformanceButtons(
          item.modifierApplicationId, //modifierApplicationId : any
          selectedOrg,
          staff.staffCode,
          staff.staffName,
          null, //modifierApplicationStatus: number | null
          item.holidayApplicationStatus, //holidayApplicationStatus: number | null,
          null, //overworkApplicationStatus: number | null,
          null, //holidayworkApplicationStatus: number | null,
          null, //transferApplicationStatus: number | null,
          "1", //attendanceOrgFix: string,
          staff.dateInfo[0].useOverTimeApplication, //useOverTimeApplication: any,
          staff.dateInfo[0].necessaryHolidayWorkApplication, //necessaryHolidayWorkApplication: any,
          staff.dateInfo[0].useTransferApplication, //useTransferApplication: any,
          item.idToString, //achievementId: any,
          item.targetDate,
          item.stampVersion, //stampVersion: any,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          item.holidayId,
          item.isHolidayWork
        );
        setMenuPerformanceButtons(performanceButtons);
        setPerformanceMenuVisible(true);
      }
    }
  };

  const onCanvasClick = async (groupIndex: number, time: number, e: any) => {
    let rowId = e.target.getAttribute("rowid");
    let staff = groups[groupIndex - 1];
    console.log("staff", staff);
    setContextMenuY(e.pageY);
    setContextMenuX(e.pageX);
    setSelectedGroup(staff);
    setSelectedItem(null);
    if (
      rowId == 2 &&
      !dailyShift.isShiftConfirm &&
      !dailyShift.isAchievementConfirm &&
      ![0, 2, 3].includes(staff.dateInfo[0].workSystem)
    ) {
      //fetch Shiftpattern
      try {
        var shiftPatterns = await getShiftPatterns(
          staff.employmentId,
          selectedOrg
        );
      } catch (error) {
        shiftPatterns = [];
      }
      setShiftPatternOptions(shiftPatterns);
      setRemote(staff.dateInfo[0].useRemote);
      let timelineButtons =
        staff.orgCode === selectedOrg
          ? getMenuTimelineButtons(
            staff.staffCode,
            staff.staffName,
            dateFromStr,
            shiftPatterns,
            staff.dateInfo[0].distinctionHoliday,
            staff.dateInfo[0].closingHour,
          )
          : getOtherOrgStaffMenuTimelineButtons(
            staff.staffCode,
            dateFromStr,
            shiftPatterns
          );
      setMenuTimelineButtons(timelineButtons);
      setTimelineMenuVisible(true);
    }
    if (rowId == 3 && !dailyShift.isAchievementConfirm) {
      let performanceButtons = getMenuPerformanceButtons(
        "", //modifierApplicationId : any
        selectedOrg,
        staff.staffCode,
        staff.staffName,
        null, //modifierApplicationStatus: number | null
        null, //holidayApplicationStatus: number | null,
        null, //overworkApplicationStatus: number | null,
        null, //holidayworkApplicationStatus: number | null,
        null, //transferApplicationStatus: number | null,
        "", //attendanceOrgFix: string,
        staff.dateInfo[0].useOverTimeApplication, //useOverTimeApplication: any,
        staff.dateInfo[0].necessaryHolidayWorkApplication, //necessaryHolidayWorkApplication: any,
        staff.dateInfo[0].useTransferApplication, //useTransferApplication: any,
        null, //achievementId: any,
        staff.dateInfo[0].targetDate,
        0 //stampVersion: any,
      );
      setMenuPerformanceButtons(performanceButtons);
      setPerformanceMenuVisible(true);
    }
  };

  const onItemResize = async (itemId: number, time: number, edge: string) => {
    let oldItems = [...items];
    let newItems = [...items],
      item = newItems[itemId - 1],
      selectedItemStart: number = item.start_time,
      newEndTimestamp: number = time;

    //round-up end time when resize from rightEdge to normal
    if (item.end_time === boundaryEnd && newEndTimestamp != boundaryEnd) {
      newEndTimestamp += 1000;
    }

    item.start_time = selectedItemStart;
    item.end_time = newEndTimestamp;
    item.title = `${moment(item.start_time)
      .format("HH:mm")
      .toString()} ~ ${moment(item.end_time)
        .format("HH:mm")
        .toString()}`;
    try {
      setLoading(true);
      let submitItem = formatSubmitObjItemResize(item, newEndTimestamp);
      setItems(newItems);
      await postShiftV1(submitItem);
      await fetchDailyShiftData();
    } catch (error) {
      setLoading(false);
      errorNotification(error.response.data.message);
      setItems(oldItems);
    } finally {
      setLoading(false);
    }
  };

  const moveResizeValidator = (
    action: "move" | "resize",
    item: TimelineItemBase<number>,
    time: number,
    resizeEdge: "left" | "right"
  ): number => {
    let selectedItemDuration: number = item.end_time - item.start_time,
      itemsToCheck = items.filter(
        itemToCheck =>
          itemToCheck.rowId === item.rowId &&
          itemToCheck.group === item.group &&
          itemToCheck.id != item.id
      );

    if (action === "resize") {
      //minimum resize of 1 hour
      if (time - item.start_time < 3600000) {
        time = item.start_time + 3600000;
      }

      //breaktime collision
      if (item.break_end && time < item.break_end) {
        time = item.break_end + 15 * 60 * 1000; //add 15minutes
      }

      if (resizeEdge === "left") {
        if (boundaryStart > time) {
          time = boundaryStart;
        }
      } else {
        //resizeEdge right
        if (boundaryEnd < time) {
          time = boundaryEnd;
        }

        // check collision
        for (let i = 0; i < itemsToCheck.length; i++) {
          const itemToCheck = itemsToCheck[i];

          if (time > itemToCheck.start_time && time < itemToCheck.end_time) {
            time = itemToCheck.start_time;
          } else if (
            item.start_time < itemToCheck.start_time &&
            time > itemToCheck.end_time
          ) {
            time = itemToCheck.start_time;
          }
        }
      }
    } else {
      //action === 'move'
      let newItemStart = time,
        newItemEnd = time + selectedItemDuration;

      if (!itemsToCheck.length) {
        //left Edge
        if (boundaryStart > time) {
          time = boundaryStart;
        }

        //right Edge
        if (boundaryEnd < time + selectedItemDuration) {
          time = boundaryEnd - selectedItemDuration;
        }
      } else {
        // check collision
        for (let i = 0; i < itemsToCheck.length; i++) {
          const itemToCheck = itemsToCheck[i];
          let remainingDurationLeftside =
            itemToCheck.start_time - boundaryStart,
            remainingDurationRightside = boundaryEnd - itemToCheck.end_time;

          //wrap around or contain inside
          if (
            (newItemStart <= itemToCheck.start_time &&
              newItemEnd > itemToCheck.start_time) ||
            (newItemStart > itemToCheck.start_time &&
              newItemStart < itemToCheck.end_time)
          ) {
            if (remainingDurationRightside <= selectedItemDuration) {
              time = itemToCheck.start_time - selectedItemDuration;
            } else {
              time = itemToCheck.end_time;
            }
          } else {
            //left Edge
            if (boundaryStart > time) {
              if (remainingDurationLeftside < selectedItemDuration) {
                time = itemToCheck.end_time;
              } else {
                time = boundaryStart;
              }
            }

            //right Edge
            if (boundaryEnd < time + selectedItemDuration) {
              if (remainingDurationRightside < selectedItemDuration) {
                time = itemToCheck.start_time - selectedItemDuration;
              } else {
                time = boundaryEnd - selectedItemDuration;
              }
            }
          }
        }
      }
    }
    return time;
  };
  const onClickAttendStampList = (staff: StaffInfo) => {
    // 自画面用パラメータ
    if (dateFromStr) {
      sessionStorage.setItem(
        "attendStampList.targetDateFrom",
        moment(dateFromStr).format("YYYY-MM-DD")
      );
      sessionStorage.setItem(
        "attendStampList.targetDateTo",
        moment(dateFromStr).format("YYYY-MM-DD")
      );
      sessionStorage.setItem("attendStampList.orgCode", selectedOrg);
      sessionStorage.setItem("attendStampList.staffCode", staff.staffCode);
    }

    // Common parameters
    sessionStorage.setItem("application.staffCode", staff.staffCode);
    sessionStorage.setItem(
      "application.dispStaffName",
      `${staff.staffCode} ${staff.staffName}`
    );
    sessionStorage.setItem(
      "application.targetDate",
      moment(dateFromStr).format("YYYY-MM-DD")
    );
    sessionStorage.setItem(
      "application.returnDestination",
      window.location.pathname
    );
    sessionStorage.setItem(
      "attendStampList.returnDestination",
      window.location.pathname
    );
    sessionStorage.setItem("attendStampList.closingDate", dateFromStr);
    sessionStorage.setItem("attendStampList.employmentId", staff.employmentId);
    sessionStorage.setItem("attendStampList.attendFlag", isAttendExists);
    sessionStorage.setItem('headquartersFinal.monthlyList.returnDestination', '/dailyShift');
    history.push(`/attendStampList`);
  };

  const deleteItem = async (shiftId: string) => {
    setLoading(true);
    let updateUser = sessionStorage.getItem("loginUser.staffName")!;

    try {
      await deleteShiftV1(shiftId, updateUser);
      await fetchDailyShiftData();
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
      setSelectedItemIdArr([]);
    }
  };

  const formatSubmitObjItemMove = (
    shift: TimelineItemBase<number>,
    timeGap: number
  ) => {
    let targetDateMoment = moment(shift.targetDate);

    return {
      shiftId: shift.idToString,
      orgCode: shift.orgCode,
      orgName: shift.orgName,
      belongOrgCode: shift.belongOrgCode,
      staffCode: shift.staffCode,
      staffName: shift.staffCode,
      targetDate: targetDateMoment.format("YYYY/MM/DD HH:mm:ss"),
      startTime: moment(shift.start_time).format("YYYY/MM/DD HH:mm:ss"),
      endTime: moment(shift.end_time).format("YYYY/MM/DD HH:mm:ss"),
      attendType: 0,
      procType: 1,
      createUser: sessionStorage.getItem("loginUser.staffName") || "",
      updateUser: sessionStorage.getItem("loginUser.staffName") || "",
      companyCode: sessionStorage.getItem("loginUser.companyCode") || "",
      shiftDetailList: shift.detailList!.map((detail, index) => {
        let newStartTimeUnix = moment(detail.startTime).valueOf() + timeGap;
        let newEndTimeUnix = moment(detail.endTime!).valueOf() + timeGap;
        let forceStartTimeNextDay = false;
        let forceEndTimeNextDay = false;

        if (moment(newStartTimeUnix).isAfter(targetDateMoment, "day")) {
          forceStartTimeNextDay = true;
        }
        if (moment(newEndTimeUnix).isAfter(targetDateMoment, "day")) {
          forceEndTimeNextDay = true;
        }

        return {
          startTime: moment(newStartTimeUnix).format("YYYY/MM/DD HH:mm:ss"),
          isStartTimeNextDay: forceStartTimeNextDay,
          endTime: moment(newEndTimeUnix).format("YYYY/MM/DD HH:mm:ss"),
          isEndTimeNextDay: forceEndTimeNextDay,
          businessId: detail.businessId || "",
          createUser: sessionStorage.getItem("loginUser.staffName") || "",
          updateUser: sessionStorage.getItem("loginUser.staffName") || "",
          companyCode: sessionStorage.getItem("loginUser.companyCode") || "",
          shiftDetailId: detail.detailId,
          shiftId: shift.idToString
        };
      })
    };
  };

  const formatSubmitObjItemResize = (
    shift: TimelineItemBase<number>,
    newEndTime: number
  ) => {
    let detailListLength = shift.detailList.length;
    return {
      shiftId: shift.idToString,
      orgCode: shift.orgCode,
      orgName: shift.orgName,
      belongOrgCode: shift.belongOrgCode,
      staffCode: shift.staffCode,
      staffName: shift.staffCode,
      targetDate: moment(shift.targetDate).format("YYYY/MM/DD HH:mm:ss"),
      startTime: moment(shift.start_time).format("YYYY/MM/DD HH:mm:ss"),
      endTime: moment(newEndTime).format("YYYY/MM/DD HH:mm:ss"),
      attendType: 0,
      procType: 1,
      createUser: sessionStorage.getItem("loginUser.staffName") || "",
      updateUser: sessionStorage.getItem("loginUser.staffName") || "",
      companyCode: sessionStorage.getItem("loginUser.companyCode") || "",
      shiftDetailList: shift.detailList.map(detail => {
        return {
          startTime: moment(detail.startTime).format("YYYY/MM/DD HH:mm:ss"),
          isStartTimeNextDay: detail.isStartTimeNextDay,
          endTime: moment(
            !--detailListLength ? newEndTime : detail.endTime!
          ).format("YYYY/MM/DD HH:mm:ss"), //edit last item only
          isEndTimeNextDay: detail.isEndTimeNextDay,
          businessId: detail.businessId || "",
          createUser: sessionStorage.getItem("loginUser.staffName") || "",
          updateUser: sessionStorage.getItem("loginUser.staffName") || "",
          companyCode: sessionStorage.getItem("loginUser.companyCode") || "",
          shiftDetailId: detail.detailId,
          shiftId: shift.idToString
        };
      })
    };
  };

  /* -------------------------------------------------------------------------- */
  /*                          ShiftPattern modal                                */
  /* -------------------------------------------------------------------------- */
  const [openShiftPatternModal, setShiftPatternModal] = useState<boolean>(
    false
  );
  const [shiftPatternOptions, setShiftPatternOptions] = useState<
    ShiftPattern[]
  >([]);
  const [openShiftPatternStaffModal, setShiftPatternStaffModal] = useState<
    boolean
  >(false);

  const getWorkdayOptions = (
    distinctionHoliday: boolean,
    staffOtherOrg?: boolean
  ) => {
    if (staffOtherOrg) {
      return [{ value: "normal", label: "通常" }];
    }

    console.log('aaa', distinctionHoliday)
    if (distinctionHoliday) {
      console.log('bbbb')
      return [
        { value: "normal", label: "通常" },
        { value: "legal", label: "公休（法定休日）" },
        { value: "scheduled", label: "公休（所定休日）" }
      ];
    }

    return [
      { value: "normal", label: "通常" },
      { value: "legal", label: "公休" }
    ];
  };

  /* -------------------------------------------------------------------------- */
  /*                             ContextMenu                                    */
  /* -------------------------------------------------------------------------- */
  const [isPerformanceMenuVisible, setPerformanceMenuVisible] = useState<
    boolean
  >(false);
  const [isItemMenuVisible, setItemMenuVisible] = useState<boolean>(false);
  const [isTimelineMenuVisible, setTimelineMenuVisible] = useState<boolean>(
    false
  );
  const contextMenuRef = useRef() as RefObject<HTMLElement>;
  const [contextMenuX, setContextMenuX] = useState<number>(0);
  const [contextMenuY, setContextMenuY] = useState<number>(0);
  const [menuTimelineButtons, setMenuTimelineButtons] = useState<
    ContextMenuType[]
  >([]);
  const [menuPerformanceButtons, setMenuPerformanceButtons] = useState<
    ContextMenuType[]
  >([]);
  const [menuItemButtons, setMenuItemButtons] = useState<ContextMenuType[]>([]);

  const handleClickOutside = (event: any) => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target)
    ) {
      setPerformanceMenuVisible(false);
      setItemMenuVisible(false);
      setTimelineMenuVisible(false);
    }
  };

  const getMenuPerformanceButtons = (
    modifierApplicationId: any,
    orgCode: string,
    staffCode: string,
    staffName: string,
    modifierApplicationStatus: number | null,
    holidayApplicationStatus: number | null,
    overworkApplicationStatus: number | null,
    holidayworkApplicationStatus: number | null,
    transferApplicationStatus: number | null,
    attendanceOrgFix: string,
    useOverTimeApplication: any,
    necessaryHolidayWorkApplication: any,
    useTransferApplication: any,
    achievementId: any,
    targetDate: any,
    stampVersion: any,
    dispStaffName?: string,
    filterTargetDateFrom?: Date,
    filterTargetDateTo?: Date,
    filterOrgCode?: string,
    filterStaffCode?: string,
    belongOrgFix?: string,
    applying?: any,
    holidayId?: any,
    isHolidayWork?: any
  ) => {
    const isHoliday = holidayId && !isHolidayWork;

    // TODO:  isSelectableで使用している以下の項目はレスポンスで返していないプロパティ
    //        holidayApplicationStatus、modifierApplicationStatus、attendanceOrgFix
    //        返していない理由が不明なため精査が必要
    const stampAmendmentButton: ContextMenuType = {
      isRed: modifierApplicationStatus === 0,
      isSelectable:
        ((achievementId !== null && holidayApplicationStatus === null) ||
          modifierApplicationStatus !== null ||
          attendanceOrgFix === "1") &&
        !isHoliday,
      label: "修正申請",
      isHidden: false,
      icon: <Icon type="edit" color="black" size="16px" verticalAlign="sub" />,
      stamptype: 1,
      onClick: () => {
        const link = `/dailyShift/attendStampModificationApplication`;
        setSessionStorage(link, stampAmendmentButton.stamptype, targetDate);
        history.push(link);
      }
    };

    const stampAddButton: ContextMenuType = {
      isRed: false,
      isSelectable: attendanceOrgFix !== "1" || isHoliday,
      label: "追加申請",
      isHidden: false,
      stamptype: 0,
      onClick: () => {
        const link = `/dailyShift/attendStampModificationApplication`;
        setSessionStorage(link, stampAddButton.stamptype, targetDate);
        history.push(link);
      }
    };

    const stampButtons: ContextMenuType = {
      isRed: false,
      isSelectable: true,
      label: "打刻修正申請",
      isHidden: false,
      stamptype: 1,
      subMenu: [stampAmendmentButton, stampAddButton]
    };

    const applyLeaveButton: ContextMenuType = {
      isRed: holidayApplicationStatus === 0,
      isSelectable: true,
      label: "休暇申請",
      isHidden: false,
      stamptype: 0,
      onClick: () => {
        const link = `/dailyShift/attendHolidayApplication`;
        setSessionStorage(link, applyLeaveButton.stamptype, targetDate);
        history.push(link);
      }
    };

    const applyOvertimeButton: ContextMenuType = {
      isRed: overworkApplicationStatus === 0,
      isSelectable:
        overworkApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: useOverTimeApplication === 0,
      label: "残業申請",
      stamptype: 0,
      onClick: () => {
        const link = `/dailyShift/attendOvertimeApplication`;
        setSessionStorage(link, applyOvertimeButton.stamptype, targetDate);
        history.push(link);
      }
    };

    const applyHolidayWorkButton: ContextMenuType = {
      isRed: holidayworkApplicationStatus === 0,
      isSelectable:
        holidayworkApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: necessaryHolidayWorkApplication === false,
      label: "休日出勤申請",
      stamptype: 0,
      onClick: () => {
        const link = `/dailyShift/attendHolidayworkApplication`;
        setSessionStorage(link, applyHolidayWorkButton.stamptype, targetDate);
        history.push(link);
      }
    };

    const applyTransferButton: ContextMenuType = {
      isRed: transferApplicationStatus === 0,
      isSelectable:
        transferApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: useTransferApplication === 0,
      label: "振替申請",
      stamptype: 0,
      onClick: () => {
        const link = `/dailyShift/attendTransferApplication`;
        setSessionStorage(
          link || "",
          applyTransferButton.stamptype,
          targetDate
        );
        history.push(link);
      }
    };

    const setSessionStorage = (
      link: string,
      stamptype: number,
      targetDate: string
    ) => {
      // 自画面用パラメータ
      if (filterTargetDateFrom) {
        sessionStorage.setItem(
          "attendStampList.targetDateFrom",
          moment(filterTargetDateFrom).format("YYYY-MM-DD")
        );
        sessionStorage.setItem(
          "attendStampList.targetDateTo",
          moment(filterTargetDateTo).format("YYYY-MM-DD")
        );
        sessionStorage.setItem(
          "attendStampList.orgCode",
          String(filterOrgCode)
        );
        sessionStorage.setItem(
          "attendStampList.staffCode",
          String(filterStaffCode)
        );
      }

      // Common parameters
      if (orgCode) {
        sessionStorage.setItem("application.orgCode", orgCode);
      } else if (filterOrgCode) {
        sessionStorage.setItem("application.orgCode", filterOrgCode);
      }
      sessionStorage.setItem("application.staffCode", String(staffCode));
      if (dispStaffName) {
        sessionStorage.setItem(
          "application.dispStaffName",
          String(dispStaffName)
        );
      } else {
        sessionStorage.setItem(
          "application.dispStaffName",
          `${staffCode} ${staffName}`
        );
      }
      sessionStorage.setItem(
        "application.targetDate",
        moment(targetDate).format("YYYY-MM-DD")
      );
      sessionStorage.setItem(
        "application.returnDestination",
        window.location.pathname
      );
      sessionStorage.setItem(
        "attendStampList.returnDestination",
        window.location.pathname
      );

      switch (link) {
        case `/dailyShift/attendStampModificationApplication`:
          sessionStorage.removeItem("application.achievementId");
          sessionStorage.removeItem("application.stampVersion");
          sessionStorage.removeItem("application.modifierApplicationId");

          // if( modifierApplicationId ){
          if (stamptype === 1) {
            // 修正申請
            sessionStorage.setItem(
              "application.achievementId",
              achievementId || ""
            );
            sessionStorage.setItem(
              "application.stampVersion",
              stampVersion || ""
            );
            sessionStorage.setItem(
              "application.modifierApplicationId",
              modifierApplicationId || ""
            );
          }
          break;

        case `/dailyShift/attendHolidayApplication`:
          sessionStorage.setItem("application.achievementId", achievementId);
          break;

        default:
          // do nothing
          break;
      }
    };

    return [
      stampButtons,
      applyLeaveButton,
      applyOvertimeButton,
      applyHolidayWorkButton,
      applyTransferButton
    ];
  };

  const getMenuItemButtons = (
    staffCode: string,
    staffName: string,
    targetDate: string,
    shiftPatternOptions: ShiftPattern[],
    distinctionHoliday: boolean,
    closingHour?: number
  ): ContextMenuType[] => {
    const editBtn = {
      isRed: false,
      isSelectable: true,
      label: "シフトを編集する",
      isHidden: false,
      icon: <Icon type="edit" color="black" size="16px" verticalAlign="sub" />,
      stamptype: 0,
      onClick: () => {
        setShiftPatternModal(true);
        sessionStorage.setItem('staff.closingHour', String(closingHour));
      }
    };
    const deleteBtn = {
      isRed: false,
      isSelectable: true,
      label: "シフトを削除する",
      isHidden: false,
      icon: (
        <Icon type="delete" color="black" size="16px" verticalAlign="sub" />
      ),
      stamptype: 0,
      onClick: () => {
        setOpenDeleteModal(true);
      }
    };

    return [
      editBtn,
      deleteBtn /* , ...getMenuTimelineButtons(staffCode, staffName, targetDate, shiftPatternOptions, distinctionHoliday) */
    ];
  };

  const getOtherOrgStaffMenuTimelineButtons = (
    staffCode: string,
    targetDate: string,
    shiftPatternOptions: ShiftPattern[]
  ): ContextMenuType[] => {
    const addShiftOtherOrgStaff = {
      label: "シフトを追加する",
      isRed: false,
      isSelectable: true,
      stamptype: 0,
      onClick: () => {
        setShiftPatternStaffModal(true);
      }
    };

    const addFromShiftPattern = {
      label: "シフトパターンから追加する",
      isRed: false,
      isSelectable: true,
      stamptype: 0,
      subMenu:
        shiftPatternOptions.length > 0
          ? shiftPatternOptions.map(pattern => ({
            label: pattern.shiftPatternName,
            isRed: false,
            isSelectable: true,
            stamptype: 0,
            onClick: () => {
              handleAddFromShiftPattern(
                pattern.shiftPatternId,
                staffCode,
                targetDate
              );
            }
          }))
          : [
            {
              label: "データがありません",
              isRed: false,
              isSelectable: false,
              stamptype: 0
            }
          ]
    };
    return [addShiftOtherOrgStaff, addFromShiftPattern];
  };

  const getOtherOrgStaffMenuItemButtons = (): ContextMenuType[] => {
    const deleteBtn = {
      isRed: false,
      isSelectable: true,
      label: "シフトを削除する",
      isHidden: false,
      icon: (
        <Icon type="delete" color="black" size="16px" verticalAlign="sub" />
      ),
      stamptype: 0,
      onClick: () => {
        setOpenDeleteModal(true);
      }
    };

    return [deleteBtn];
  };

  const getMenuTimelineButtons = (
    staffCode: string,
    staffName: string,
    targetDate: string,
    shiftPatternOptions: ShiftPattern[],
    distinctionHoliday: boolean,
    closingHour: number
  ): ContextMenuType[] => {
    const addShift = {
      label: "シフトを追加する",
      isRed: false,
      isSelectable: true,
      stamptype: 0,
      onClick: () => {
        setShiftPatternModal(true);
        sessionStorage.setItem('staff.closingHour', String(closingHour));
      }
    };
    const takeHoliday = distinctionHoliday
      ? {
        label: "公休にする",
        isRed: false,
        isSelectable: true,
        stamptype: 0,
        subMenu: [
          {
            label: "法定休日",
            isRed: false,
            isSelectable: true,
            stamptype: 0,
            onClick: async () => {
              let user = sessionStorage.getItem("loginUser.staffName")!;
              try {
                setLoading(true);
                await registerHoliday(
                  selectedOrg,
                  staffCode,
                  moment(targetDate).format("YYYY/MM/DD"),
                  true,
                  user,
                  user
                );
                await fetchDailyShiftData();
              } catch (error) {
                errorNotification(error.response.data.message);
              } finally {
                setLoading(false);
              }
            }
          },
          {
            label: "所定休日",
            isRed: false,
            isSelectable: true,
            stamptype: 0,
            onClick: async () => {
              // let user = sessionStorage.getItem("loginUser.staffName")!;
              try {
                setLoading(true);
                await registerHoliday(
                  selectedOrg,
                  staffCode,
                  moment(targetDate).format("YYYY/MM/DD"),
                  false,
                  staffName,
                  staffName
                );
                await fetchDailyShiftData();
              } catch (error) {
                errorNotification(error.response.data.message);
              } finally {
                setLoading(false);
              }
            }
          }
        ]
      }
      : {
        label: "公休にする",
        isRed: false,
        isSelectable: true,
        stamptype: 0,
        onClick: async () => {
          try {
            setLoading(true);
            await registerHoliday(
              selectedOrg,
              staffCode,
              moment(targetDate).format("YYYY/MM/DD"),
              true,
              staffName,
              staffName
            );
            await fetchDailyShiftData();
          } catch (error) {
            errorNotification(error.response.data.message);
          } finally {
            setLoading(false);
          }
        }
      };

    const addFromShiftPattern = {
      label: "シフトパターンから追加する",
      isRed: false,
      isSelectable: true,
      stamptype: 0,
      subMenu:
        shiftPatternOptions.length > 0
          ? shiftPatternOptions.map(pattern => ({
            label: pattern.shiftPatternName,
            isRed: false,
            isSelectable: true,
            stamptype: 0,
            onClick: () => {
              handleAddFromShiftPattern(
                pattern.shiftPatternId,
                staffCode,
                targetDate
              );
            }
          }))
          : [
            {
              label: "データがありません",
              isRed: false,
              isSelectable: false,
              stamptype: 0
            }
          ]
    };

    return [addShift, takeHoliday, addFromShiftPattern];
  };

  const fetchDailyShiftData = async (
    defaultStart: Moment = moment(boundaryStart),
    defaultEnd: Moment = moment(boundaryEnd),
    businessIds?: any,
    staffDailyShift?: any,
  ) => {
    try {
      setLoading(true);
      let employmentIds = employmentOptions.reduce(
        (prev, current) => (current.checked ? `${current.id},${prev}` : prev),
        "1"
      );
      // track cumulativeClosingDate
      const selectedCumulativeClosingDateIndex = String(
        Number(selectedCumulativeClosingDate) - 1
      );
      let dailyShiftData = await fetchDailyShift(
        selectedOrg,
        moment(dateFromStr).format("YYYY-MM-DD"),
        isAttendExists,
        employmentIds,
        staffDailyShift,
        selectedCumulativeClosingDateIndex,
        businessIds,
      );
      setDailyShift(dailyShiftData);

      let newGroups = getGroups(
        dailyShiftData.staffInfo,
        dailyShiftData.isShiftConfirm,
        dailyShiftData.isAchievementConfirm
      );
      let row1Items = getRow1Items(
        dailyShiftData.staffInfo,
        defaultStart,
        defaultEnd
      );
      let row2Items = getRow2Items(
        dailyShiftData.staffInfo,
        dailyShiftData.isShiftConfirm,
        defaultStart,
        defaultEnd,
        row1Items.length
      );
      let row3Items = getRow3Items(
        dailyShiftData.staffInfo,
        dailyShiftData.isAchievementConfirm,
        defaultStart,
        defaultEnd,
        row1Items.length + row2Items.length
      );
      setGroups(newGroups);
      setItems([...row1Items, ...row2Items, ...row3Items]);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        errorNotification("サーバー側からエラーが発生します。");
      } else {
        errorNotification("エラー");
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500); //delay to wait for timeline-react-calendar render
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Add staff to dailyShift
  const handleAddFromShiftPattern = async (
    shiftPatternId: string,
    staffCode: string,
    targetDate: string
  ) => {
    let createUser = sessionStorage.getItem("loginUser.staffName")!,
      updateUser = sessionStorage.getItem("loginUser.staffName")!;
    setLoading(true);
    try {
      await addShiftPattern(
        selectedOrg,
        staffCode,
        moment(targetDate).format("YYYY/MM/DD"),
        shiftPatternId,
        createUser,
        updateUser
      );
      await fetchDailyShiftData();
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const [appendStaffs, setAppendStaffs] = useState<Array<string>>([]);

  const handleAddStaffDaily = async (staffCode: any) => {
    setLoading(true);
    try {
      const staffList = appendStaffs;
      staffList.push(staffCode);
      fetchDailyShiftData(
        moment(boundaryStart),
        moment(boundaryEnd),
        businessIds,
        staffList
      );
      setAppendStaffs(staffList);
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setAppendStaffs([]);
  }, [selectedOrg]);

  useEffect(() => {
    const fetchOnetimeData = async () => {
      let employmentList = await getEmploymentSelectList();
      let sessionData = sessionStorage.getItem(
        `0/sessionData${window.location.pathname}.employmentOptions`
      );
      let sessionList: any = [];
      if (sessionData) {
        sessionList = sessionData?.split(",");
      }
      let formatOptionType = employmentList.map(opt => ({
        id: opt.employmentId,
        label: opt.employmentName,
        value: opt.employmentCode,
        checked:
          sessionList.includes(opt.employmentCode) ||
          sessionList.length === 0 ||
          sessionList.includes("all")
      }));
      setLoadEmploymentDone(true);
      setEmploymentOptions(formatOptionType);
    };
    fetchOnetimeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [width, setWidth] = useState(window.innerWidth);
  const [getDrawingInfo, setDrawingInfo] = useState<any>();
  const [getFlagLoading, setFlagLoading] = useState<number>(0);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", () => setTimeout(() => updateWidthAndHeight(), 1000));
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });
  let calendarDaily = document.getElementById("calendar-daily")?.clientWidth;
  useEffect(() => {
    console.log('getDrawingInfo', getDrawingInfo, getFlagLoading)
    if (getDrawingInfo && getFlagLoading) {
      let startHourNumber = getDrawingInfo.timeHeaderList[0],
        endHourNumber =
          getDrawingInfo.timeHeaderList[
          getDrawingInfo.timeHeaderList.length - 1
          ],
        defaultStart = moment(dateFromStr).set("hour", startHourNumber),
        defaultEnd = moment(dateFromStr).set("hour", endHourNumber + 1);
      let calculatorItem = Number(calendarDaily) - Number(sidebarHeaderRightWidth) - Number(sidebarHeaderWidth);
      let widthItem = getDrawingInfo.timeHeaderList.length === 12 ? 72 : getDrawingInfo.timeHeaderList.length == 18 ? 48 : 36;
      if (defaultEnd.diff(defaultStart, "hours") > Number(calculatorItem) / Number(widthItem)) {
        setDefaultTimeEnd(
          moment(defaultStart).add("hours", Number(calculatorItem) / Number(widthItem))
        );
      } else {
        setDefaultTimeEnd(defaultEnd);
      }
      //fetchDailyShiftData();
    }
  }, [width])

  useEffect(() => {
    setFlagLoading(0);
    const fetchDrawingInfo = async () => {
      setLoading(true);
      let drawingInfoDaily = await fetchDrawingInfoDaily(
        selectedOrg,
        moment(dateFromStr).format("YYYY-MM-DD")
      );
      setDrawingInfo(drawingInfoDaily);
      let startHourNumber = drawingInfoDaily.timeHeaderList[0],
        endHourNumber =
          drawingInfoDaily.timeHeaderList[
          drawingInfoDaily.timeHeaderList.length - 1
          ],
        defaultStart = moment(dateFromStr).set("hour", startHourNumber),
        defaultEnd = moment(dateFromStr).set("hour", endHourNumber + 1); //do not remove
      if (startHourNumber > endHourNumber) {
        defaultEnd.add("day", 1);
      }
      setCountHeaderHour(drawingInfoDaily.timeHeaderList.length);
      setDefaultTimeStart(defaultStart);
      //if hour gap is greater than 12h then set defaultTimeEnd to 12h ahead
      //this is to enable scroll
      let calculatorItem =
        Number(calendarDaily) -
        Number(sidebarHeaderRightWidth) -
        Number(sidebarHeaderWidth);
      let widthItem =
        drawingInfoDaily.timeHeaderList.length === 12
          ? 72
          : drawingInfoDaily.timeHeaderList.length == 18
            ? 48
            : 36;
      if (
        defaultEnd.diff(defaultStart, "hours") >
        Number(calculatorItem) / Number(widthItem)
      ) {
        setDefaultTimeEnd(
          moment(defaultStart).add(
            "hours",
            Number(calculatorItem) / Number(widthItem)
          )
        );
      } else {
        setDefaultTimeEnd(defaultEnd);
      }

      setBoundaryStart(defaultStart.valueOf());
      setBoundaryEnd(defaultEnd.valueOf());

      fetchDailyShiftData(defaultStart, defaultEnd, businessIds);
    };

    fetchDrawingInfo();
    setFlagLoading(1);

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedOrg,
    dateFromStr,
    isAttendExists,
    employmentOptions,
    selectedCumulativeClosingDate,
    calendarDaily,
    businessIds
  ]);

  return {
    remote,
    setRemote,
    isLoading,
    setLoading,
    openConfirmModal,
    setOpenConfirmModal,
    openRegisterModal,
    setOpenRegisterModal,
    openDeleteModal,
    setOpenDeleteModal,
    companyCode,
    dailyShift,
    setDailyShift,
    loadEmploymentDone,
    orgCodeModal,
    setOrgCodeModal,
    handleAddStaffDaily,
    countHeaderHour,
    businessIds,
    setBusinessIds,
    filters: {
      dateFromStr,
      setDateFromStr,
      dateToStr,
      setDateToStr,
      isAttendExists,
      setAttendExists,
      selectedOrgName,
      selectedOrg,
      setSelectedOrg,
      selectedCumulativeClosingDate,
      setSelectedCumulativeClosingDate,
      employmentOptions,
      setEmploymentOptions,
      orgs,
      setOrgs,
      downloadPdf,
      submitShiftLockout,
      checkall,
      setCheckall
    },

    calendar: {
      items,
      setItems,
      deleteItem,
      groups,
      setGroups,
      selectedItem,
      setSelectedItem,
      selectedItemIdArr,
      setSelectedItemIdArr,
      selectedGroup,
      setSelectedGroup,
      defaultTimeStart,
      setDefaultTimeStart,
      defaultTimeEnd,
      setDefaultTimeEnd,
      sidebarName,
      sidebarSupport,
      sidebarType,
      sidebarHeaderWidth,
      sidebarHeaderRightWidth,
      onTimeChange,
      onItemMove,
      onItemSelect,
      onItemClick,
      onCanvasClick,
      onItemResize,
      moveResizeValidator,
      onClickAttendStampList,
      fetchDailyShiftData
    },
    contextMenu: {
      isPerformanceMenuVisible,
      setPerformanceMenuVisible,
      openShiftPatternModal,
      setShiftPatternModal,
      openShiftPatternStaffModal,
      setShiftPatternStaffModal,
      isItemMenuVisible,
      setItemMenuVisible,
      isTimelineMenuVisible,
      setTimelineMenuVisible,
      contextMenuRef,
      contextMenuX,
      setContextMenuX,
      contextMenuY,
      setContextMenuY,
      menuTimelineButtons,
      setMenuTimelineButtons,
      menuPerformanceButtons,
      setMenuPerformanceButtons,
      menuItemButtons,
      setMenuItemButtons,
      getMenuPerformanceButtons,
      getMenuItemButtons,
      getMenuTimelineButtons
    },
    shiftPattern: {
      openShiftPatternModal,
      setShiftPatternModal,
      openShiftPatternStaffModal,
      setShiftPatternStaffModal,
      shiftPatternOptions,
      setShiftPatternOptions,
      getWorkdayOptions
    }
  };
};
