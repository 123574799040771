
/**
 * API 勤怠再計算
 */

export interface ConfirmRecorderByFace {
  orgCode: string,
  orgName: string,
  loginOrgCode: string,
  loginOrgName: string,
  tmpFilePath: string,
  staffName: string,
  faceId: string,
  similarity: string,
  mistake: any,
  stampTime: any,
  stampType: number,
  businessId: string,
  stampMode: number,
}


export default class ConfirmRecorderByFaceDomain {
  constructor(private rawData: ConfirmRecorderByFace) {
  // do nothing
  }

  static generateInitial(): ConfirmRecorderByFaceDomain {
    return new ConfirmRecorderByFaceDomain({
      orgCode: '',
      orgName: '',
      loginOrgCode: '',
      loginOrgName: '',
      tmpFilePath: '',
      staffName: '',
      faceId: '',
      similarity: '',
      mistake: null,
      stampTime: null,
      stampType: 0,
      businessId: '',
      stampMode: 0,
    });
  }

  getRawData(): ConfirmRecorderByFace {
    return this.rawData;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode:string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get loginOrgCode():string {
    return this.rawData.loginOrgCode;
  }

  set loginOrgCode(loginOrgCode:string) {
    this.rawData.loginOrgCode = loginOrgCode;
  }

  get loginOrgName():string {
    return this.rawData.loginOrgName;
  }

  set loginOrgName(loginOrgName:string) {
    this.rawData.loginOrgName = loginOrgName;
  }


  get tmpFilePath():string {
    return this.rawData.tmpFilePath;
  }

  set tmpFilePath(tmpFilePath:string) {
    this.rawData.tmpFilePath = tmpFilePath;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  get stampTime():string {
    return this.rawData.stampTime;
  }

  set stampTime(stampTime:string) {
    this.rawData.stampTime = stampTime;
  }

  get stampMode():number {
    return this.rawData.stampMode;
  }

  set stampMode(stampMode:number) {
    this.rawData.stampMode = stampMode;
  }

  get faceId():string {
    return this.rawData.faceId;
  }

  set faceId(faceId:string) {
    this.rawData.faceId = faceId;
  }

  get similarity():string {
    return this.rawData.similarity;
  }

  set similarity(similarity:string) {
    this.rawData.similarity = similarity;
  }

  get mistake():any {
    return this.rawData.mistake;
  }

  set mistake(mistake:any) {
    this.rawData.mistake = mistake;
  }

  get businessId():string {
    return this.rawData.businessId;
  }

  set businessId(businessId:string) {
    this.rawData.businessId = businessId;
  }

  get stampType():number {
    return this.rawData.stampType;
  }

  set stampType(stampType:number) {
    this.rawData.stampType = stampType;
  }
  // get

// custom data use DataTable
}
