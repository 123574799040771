import SalesAdvertisingPlanReportLayout from 'components/organismos/master/sales/SalesAdvertisingPlanReportForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';

const SalesAdvertisingPlanReport = () => (
  <SidebarTemplate pageTitle="広告プラン別売上">
    <SalesAdvertisingPlanReportLayout />
  </SidebarTemplate>
);

export default SalesAdvertisingPlanReport;
