import React from 'react';
import AttendMonthlyForm from 'components/organismos/master/attend/AttendMonthlyForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const AttendMonthlyList = () => {
  const attendFlag = sessionStorage.getItem('headquartersFinal.monthlyList.attendFlag') || '0';
  return (
    <SidebarTemplate pageTitle={attendFlag === '0' ? '勤務データ確認' : '勤務データ確認'}>
      <AttendMonthlyForm />
    </SidebarTemplate>
  );
};

export default AttendMonthlyList;
