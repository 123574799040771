import React, { SVGProps } from 'react';

const ChevronDown: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path d="M10.137 12.065L5.5 7.588l-.868.899 5.536 5.346 5.346-5.536-.9-.868z" fill="#333" />
    </g>
  </svg>
);

export default ChevronDown;
