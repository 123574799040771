import React from 'react';

import FormContents from 'components/atoms/Form/FormContents';
import SalaryCsvSettingInputAddForm from './SalaryCsvSettingInputAddForm';

/**
 * Salary csv setting registration form
 *
 */
const SalaryCsvSettingAddForm: React.FC = () => (
  <FormContents>
    <SalaryCsvSettingInputAddForm />
  </FormContents>
);

export default SalaryCsvSettingAddForm;
