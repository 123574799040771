import React from 'react';
import { Link } from 'react-router-dom';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import BlockUI from 'components/molecules/BlockUi';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FileSelectButton from 'components/molecules/FileSelectButton';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import { utilityColor } from 'components/styles';
import BodyText from 'components/atoms/BodyText';
import PrimaryButton from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import FormTitle from 'components/atoms/Form/FormTitle';
import { useSalesSuppliersImport } from './hook';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const SalesSuppliersMSTImportPage: React.FC = () => {
  const {
    inputValue, setUploadFile, uploadFile, onSubmit, errors, confirmModalOpen, closeConfirmModal, handleSubmit, blocking,
  } = useSalesSuppliersImport();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <SidebarTemplate pageTitle="取引先インポート">
      <BlockUI blocking={blocking}>
        <FormContents>
          <FormTitle
            title="登録するデータ"
          />
          <BodyText color={utilityColor.error} size="md">※CSVファイルの１行目（ヘッダー行）はインポートされません。</BodyText>
          <FormField>
            <FileSelectButton
              text="CSVファイルをドラッグ＆ドロップ  または  クリックしてファイルを選択してください"
              ghost={true}
              setFile={setUploadFile}
              value={inputValue}
              accept=".csv"
            />
          </FormField>
          {uploadFile || errors
            ? (
              <FormField
                displayBlock={true}
              >
                <div>
                  {uploadFile?.name}
                </div>
                {errors && errors.map((error) => (
                  <div key={error}>
                    <BodyText color={utilityColor.error}>
                      {error}
                    </BodyText>
                  </div>
                ))}
              </FormField>
            )
            : null}
          <FormSubmitArea>
            {
              roleScreen && roleScreen.importFlag === 1 && (
                <div style={{ marginRight: '12px' }}>
                  <PrimaryButton
                    text="インポート"
                    onClick={handleSubmit}
                    disabled={!uploadFile}
                  />
                </div>
              )
            }
            <Link to="/salesSuppliersMST">
              <PrimaryButton text="戻る" ghost={true} />
            </Link>
          </FormSubmitArea>
        </FormContents>
      </BlockUI>

      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        title="確認メッセージ"
        onSubmit={onSubmit}
        content="ファイルをアップロードします。よろしいですか？"
        items={[]}
      />
    </SidebarTemplate>
  );
};

export default SalesSuppliersMSTImportPage;
