import React, { useState, useEffect } from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import FormContents from 'components/atoms/Form/FormContents';
import TabControl from 'components/atoms/TabControl';
import StaffInfoForm from 'components/organismos/master/general/staff/StaffInfoForm';
import AffiliationInfoForm from 'components/organismos/master/general/staff/AffiliationInfoForm';
import EmploymentInfoForm from 'components/organismos/master/general/staff/EmploymentInfoForm';
import SalaryInfoForm from 'components/organismos/master/general/staff/SalaryInfoForm';
import WorkPatternInfoForm from 'components/organismos/master/general/staff/WorkPatternInfoForm';
import BlockUI from 'components/molecules/BlockUi';
import PermissionForm from 'components/organismos/master/general/staff/PermissionForm';
import useStaffEdit from './hooks';
import { TablItem } from '../StaffAddPage/StaffAddPage';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

type SelectType = 'staff' | 'affiliation' | 'permission' | 'employment' | 'laborCost' | 'workPattern';
const SelectType: { [key in SelectType]: SelectType } = {
  staff: 'staff',
  affiliation: 'affiliation',
  permission: 'permission',
  employment: 'employment',
  laborCost: 'laborCost',
  workPattern: 'workPattern',
};

const StaffEditPage: React.FC = () => {
  const {
    isLoading, setIsLoading, staffDomain, setStaffDomain,
    trialPeriodOptions, businessOptions,
    hqRoleOptions, shopRoleOptions, personalRoleOptions,
    executiveOptions, employmentOptions,
    orgTreeOptions,
    setOrgTargetDate,
    setOrgApplyDate,
    setEmpTargetDate,
    breakBusiness,
    roleOptions,
    menuType,
    isEditingLoginUser,
  } = useStaffEdit();
  const [selectType, setSelectType] = React.useState<SelectType>('staff');
  const [tabItems, setTabItems] = useState<Array<TablItem>>([
    {
      value: SelectType.staff,
      display: 'スタッフ情報',
    },
    {
      value: SelectType.affiliation,
      display: '所属設定',
    },
    {
      value: SelectType.permission,
      display: '権限設定',
    },
    {
      value: SelectType.employment,
      display: '雇用設定',
    },
    {
      value: SelectType.laborCost,
      display: '人件費設定',
    },
    {
      value: SelectType.workPattern,
      display: 'シフトパターン設定',
    },
  ]);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  return (
    <SidebarTemplate pageTitle="スタッフ 編集">
      <BlockUI blocking={isLoading}>
        <FormContents>
          <div style={{ overflowX: 'auto' }}>
            <TabControl
              items={tabItems}
              setValue={setSelectType as (arg: string) => void}
              currentValue={selectType}
              isFixed={true}
            />
          </div>


          {selectType === SelectType.staff
            && (
              <>
                <StaffInfoForm
                  initialStaffDomain={staffDomain}
                  setInitialStaffDomain={setStaffDomain}
                  isEditMode={true}
                  tabItems={tabItems}
                  setTabItems={setTabItems}
                  setSelectType={setSelectType}
                  setOrgTargetDate={setOrgTargetDate}
                  setOrgApplyDate={setOrgApplyDate}
                  setEmpTargetDate={setEmpTargetDate}
                  trialPeriodOptions={trialPeriodOptions}
                  businessOptions={businessOptions}
                  hqRoleOptions={hqRoleOptions}
                  shopRoleOptions={shopRoleOptions}
                  personalRoleOptions={personalRoleOptions}
                  roleOptions={roleOptions}
                  setIsLoading={setIsLoading}
                  menuType={menuType || '0'}
                  roleScreen={roleScreen}
                  isEditingLoginUser={isEditingLoginUser}
                />
              </>
            )}
          {selectType === SelectType.affiliation
            && (
              <AffiliationInfoForm
                initialStaffDomain={staffDomain}
                setInitialStaffDomain={setStaffDomain}
                isEditMode={true}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
                setOrgTargetDate={setOrgTargetDate}
                setOrgApplyDate={setOrgApplyDate}
                organizationOptions={orgTreeOptions}
                executiveOptions={executiveOptions}
                setIsLoading={setIsLoading}
                menuType={menuType || '0'}
                roleScreen={roleScreen}
                isEditingLoginUser={isEditingLoginUser}
              />
            )}
          {selectType === SelectType.permission
            && (
              <PermissionForm
                initialStaffDomain={staffDomain}
                setInitialStaffDomain={setStaffDomain}
                isEditMode={true}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
                organizationOptions={orgTreeOptions}
                employmentOptions={employmentOptions}
                roleOptions={roleOptions}
                setIsLoading={setIsLoading}
                menuType={menuType || '0'}
                roleScreen={roleScreen}
                isEditingLoginUser={isEditingLoginUser}
              />
            )}
          {selectType === SelectType.employment
            && (
              <EmploymentInfoForm
                initialStaffDomain={staffDomain}
                setInitialStaffDomain={setStaffDomain}
                isEditMode={true}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
                setEmpTargetDate={setEmpTargetDate}
                employmentOptions={employmentOptions}
                setIsLoading={setIsLoading}
                menuType={menuType || '0'}
                roleScreen={roleScreen}
              />
            )}
          {selectType === SelectType.laborCost
            && (
              <SalaryInfoForm
                initialStaffDomain={staffDomain}
                setInitialStaffDomain={setStaffDomain}
                isEditMode={true}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
                businessOptions={businessOptions}
                setIsLoading={setIsLoading}
                menuType={menuType || '0'}
                roleScreen={roleScreen}
              />
            )}

          {selectType === SelectType.workPattern
            && (
              <WorkPatternInfoForm
                setInitialStaffDomain={setStaffDomain}
                initialStaffDomain={staffDomain}
                isEditMode={true}
                breakBusiness={breakBusiness}
                setIsLoading={setIsLoading}
                menuType={menuType || '0'}
                roleScreen={roleScreen}
              />
            )}
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default StaffEditPage;
