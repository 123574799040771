import React from 'react';

import FormField from 'components/atoms/Form/FormField';
import FormLabel from 'components/atoms/Form/FormLabel';
import TextForm from 'components/molecules/TextForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import PrimaryButton from 'components/atoms/Button';
import TimeInput from 'components/atoms/Form/TimeInput';
import { gridCol } from 'components/styles';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { useHistory } from 'react-router-dom';
import BlockUI from 'components/molecules/BlockUi';
import { useTermsDomainAddForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const TermInputAddForm: React.FC<{termId: string}> = ({
  termId,
}) => {
  const {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    optionsTargetType,
    isLoading,
  } = useTermsDomainAddForm(termId);

  // history
  const history = useHistory();

  /**
   *  handle back
   */
  const handleBack = async () => {
    sessionStorage.setItem('currentTab', '6');
    history.push('/agreementMaster');
  };

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <>
      <BlockUI blocking={isLoading}>
        <p>
          時間帯を登録します。時間帯名を入力し、対象となる日付区分を選択してから、時間帯を設定してください。
        </p>

        <form onSubmit={formik.handleSubmit}>
          <div>
            <FormField>
              <TextForm
                name="termName"
                label="時間帯名"
                value={formik.values.termName}
                onChange={formik.handleChange}
                errorMsg={formik.errors.termName}
                required={true}
              />
            </FormField>

            <FormField>
              <RadioSelectForm
                label="対象となる日付区分"
                items={optionsTargetType}
                name="targetType"
                value={String(formik.values.targetType)}
                setValue={formik.handleChange}
              />
            </FormField>

            <FormField>
              <div style={{ width: gridCol.grid12 }}>
                <div style={{ width: gridCol.grid12 }}>
                  <FormLabel
                    label="時間帯設定"
                  />
                </div>
                <div style={{ width: '350px' }}>
                  <div style={{ width: gridCol.grid05, float: 'left' }}>
                    <TimeInput
                      name="startTime"
                      value={formik.values.startTime}
                      onChange={() => {}}
                      setValue={(val: string) => formik.setFieldValue('startTime', val)}
                    />

                  </div>
                  <div style={{
                    width: gridCol.grid02, textAlign: 'center', float: 'left', lineHeight: '38px',
                  }}
                  >
                    〜
                  </div>
                  <div style={{ width: gridCol.grid05, float: 'right' }}>
                    <TimeInput
                      name="endTime"
                      value={formik.values.endTime}
                      onChange={() => {}}
                      setValue={(val: string) => formik.setFieldValue('endTime', val)}
                    />
                  </div>
                </div>
              </div>
            </FormField>

            <FormSubmitArea>
              {
                roleScreen && roleScreen.editable === 1 && (
                  <div style={{ marginRight: '12px' }}>
                    <Button
                      text="時間帯を登録"
                      onClick={formik.handleSubmit}
                    />
                  </div>
                )
              }
              <PrimaryButton
                text="戻る"
                onClick={() => handleBack()}
                ghost={true}
              />
            </FormSubmitArea>

          </div>

          <ConfirmModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            onSubmit={formik.handleSubmit}
            title="確認メッセージ"
            content={`${termId ? '更新' : '登録'}します。よろしいですか？`}
            submitText={termId ? '更新' : '登録'}
          />

        </form>
      </BlockUI>
    </>
  );
};

export default TermInputAddForm;
