/** @jsx jsx */
import { useState, useMemo } from 'react';
import { css, jsx } from '@emotion/core';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import PrimaryButton from 'components/atoms/Button';
import moment from 'moment';
import { Column } from 'react-table';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import DataTable from 'components/organismos/DataTable/DataTable';
import BlockUI from 'components/molecules/BlockUi';
import SalesSuppliersMSTDomain from 'domain/master/general/salesSuppliersMST';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import ClickableLink from 'components/atoms/ClickableLink';
import IconLabelButton from 'components/molecules/IconLabelButton';
import Icon from 'components/atoms/Icon';
import Modal from 'components/molecules/Modal';
import { iconColor } from 'components/styles';
import { Link } from 'react-router-dom';
import { useSalesSuppliersMSTList, useSalesSuppliersDelete, useDownload } from '../hook';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const SalesSuppliersMSTListManagement = () => {
  const { salesSuppliersMSTList, blocking, fetchData } = useSalesSuppliersMSTList();
  const {
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteSalesSuppliers,
    deleteSalesSuppliersTarget,
    setDeleteSalesSuppliersTarget,
  } = useSalesSuppliersDelete(fetchData);

  const { downloadSuppliersCsv } = useDownload();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const columns: Array<Column<SalesSuppliersMSTDomain>> = useMemo(
    () => [
      {
        Header: 'コード',
        accessor: 'suppliersCode',
      },
      {
        Header: '名称',
        accessor: 'suppliersName',
      },
      {
        Header: '並び順',
        accessor: 'dispOrder',
      },
      {
        Header: '適用開始月',
        accessor: 'applyStartDate',
        Cell: (cell: { row :{ original: any }}) => (
          <div style={{ textAlign: 'left' }}>
            {moment(cell.row.original.applyStartDate).format('YYYY年MM月')}
          </div>
        ),
      },
      {
        Header: '適用終了月',
        accessor: 'applyEndDate',
        Cell: (cell: { row :{ original: any }}) => (
          <div style={{ textAlign: 'left' }}>
            {moment(cell.row.original.applyEndDate).format('YYYY-MM-DD') !== '9999-12-31' ? moment(cell.row.original.applyEndDate).format('YYYY年MM月') : null }
          </div>
        ),
      },

      {
        Header: () => null,
        accessor: 'transactionType',
        disableSortBy: true,
        Cell: (cell: { row :{ original: any }}) => {
          const [isHoverEdit, setIsHoverEdit] = useState(false);
          const [isHoverDelete, setIsHoverDelete] = useState(false);

          return (

            <FlexBox>
              <FlexBoxItem marginLeft="auto">
                <ClickableLink to={`/salesSuppliersMST/edit/${cell.row.original.suppliersCode}/${cell.row.original.transactionType}`}>
                  <span
                    style={{ marginLeft: '20px' }}
                    onMouseEnter={() => setIsHoverEdit(true)}
                    onMouseLeave={() => setIsHoverEdit(false)}
                  >
                    <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </ClickableLink>
              </FlexBoxItem>
              {
                roleScreen && roleScreen.editable === 1 && (
                  <FlexBoxItem>
                    <ClickableIconButton
                      onClick={() => setDeleteSalesSuppliersTarget(cell.row.original)}
                    >
                      <span
                        onMouseEnter={() => setIsHoverDelete(true)}
                        onMouseLeave={() => setIsHoverDelete(false)}
                      >
                        <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                      </span>
                    </ClickableIconButton>
                  </FlexBoxItem>
                )
              }
            </FlexBox>
          );
        },
      },
    ],
    [setDeleteSalesSuppliersTarget, roleScreen],
  );

  return (
    <BlockUI blocking={blocking}>
      <div>
        <FormContents>
          {
            roleScreen && roleScreen.editable === 1 && (
              <FlexBox customStyle={css({ flexDirection: 'row-reverse' })}>
                <FlexBoxItem marginLeft="20px">
                  <Link to="/salesSuppliersMST/add">
                    <PrimaryButton
                      text="新規作成"
                    />
                  </Link>
                </FlexBoxItem>
              </FlexBox>
            )
          }

          <FlexBox>
            {
              roleScreen && roleScreen.downloadFlag === 1 && (
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={downloadSuppliersCsv}
                    iconType="download"
                    text=" CSVダウンロード"
                  />
                </FlexBoxItem>
              )
            }
            {
              roleScreen && roleScreen.importFlag === 1 && (
                <FlexBoxItem>
                  <Link to="/salesSuppliersMST/import">
                    <PrimaryButton
                      text="インポート"
                    />
                  </Link>
                </FlexBoxItem>
              )
            }
          </FlexBox>

          <div
            css={css`
                table {
                  white-space: pre;
                  border-collapse: collapse;
                }
                table th:nth-child(1), table>tbody>tr>td:nth-child(1) {
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 150px;
                }
                table th:nth-child(2), table>tbody>tr>td:nth-child(2)  {
                    width: 250px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 250px;
                }
                table th:nth-child(3), table>tbody>tr>td:nth-child(3)  {
                    width: 100px;
                }
                table th:nth-child(4), table>tbody>tr>td:nth-child(4)  {
                    width: 100px;
                }
                table th:nth-child(5), table>tbody>tr>td:nth-child(5)  {
                    width: 100px;
                }
                table th:nth-child(6), table>tbody>tr>td:nth-child(6)  {
                    width: 80px;
                }
                table th:nth-child(7), table>tbody>tr>td:nth-child(7)  {
                    width: 80px;
                }
                `}
          >
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
              <DataTable
                columns={columns}
                data={salesSuppliersMSTList}
                isGlobalFilter={true}
                minWidth="1000px"
                sortBy={[
                  { id: 'dispOrder', desc: false }
                ]}
              />
            </div>
          </div>
        </FormContents>
      </div>
      <Modal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        submitText="削除"
        title="確認メッセージ"
        onSubmit={onSubmitDeleteSalesSuppliers}
      >
        {deleteSalesSuppliersTarget?.suppliersCode}
        を削除します。よろしいですか？
      </Modal>
    </BlockUI>
  );
};

export default SalesSuppliersMSTListManagement;
