/** @jsx jsx */
import React, { useMemo, useState, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import DataTable from 'components/organismos/DataTable/DataTable';
import ConfirmModal from 'components/organismos/ConfirmModal';
import ToastModal from 'components/organismos/ToastModal';
import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import SelectForm from 'components/molecules/SelectForm';
import PaymentMediaOrganizationDomain from 'domain/master/sales/paymentMediaOrganization';
import { downloadCSV, deletePaymentMediaOrganization } from 'api/paymentMediaOrganization';
import { useOrgTreesByCategoryOptions } from 'hooks/useOrgTreesOptions';
import useToastNotification from 'hooks/useToastNotification';
import usePaymentMediaOrganizationList from './hooks';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FlexBox from 'components/atoms/FlexBox';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const PaymentImportMasterPage: React.FC = () => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalResendOpen, setConfirmModalResendOpen] = useState(false);
  const [confirmModalContent, setConfirmModalContent] = useState('');
  const [selectedObj, setSelectedObj] = useState(PaymentMediaOrganizationDomain.generateInitial());
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [toastMessage] = useState('');
  const [isHover, setHover] = useState('');
  const [isHover2, setHover2] = useState('');
  const [orgCode, setorgCode] = useState(sessionStorage.getItem('selectedOrgCode')!);
  const { successNotification, errorNotification } = useToastNotification();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)
  
  const orgTreesOptions = useOrgTreesByCategoryOptions();
  console.log(orgTreesOptions, 'orgTreesOptions')
  const {
    paymentMediaOrganizationList,
    setPaymentMediaOrganizationList,
  } = usePaymentMediaOrganizationList(orgCode);

  // Set default selected org
  useMemo(() => {
    setorgCode(sessionStorage.getItem('selectedOrgCode')!);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmResendModal = useCallback(() => {
    setConfirmModalResendOpen(false);
  }, []);

  const openConfirmModal = useCallback((payment: PaymentMediaOrganizationDomain) => {
    setConfirmModalOpen(true);
    setSelectedObj(payment);
  }, []);
  const downloadFileCSV = useCallback(() => {
    downloadCSV('支払取込一覧情報', orgCode);
  }, [orgCode]);


  const deletePaymentMediaOrg = useCallback(() => {
    const staffName = sessionStorage.getItem('loginUser.staffName') || '';
    const submitObj = {
      paymentMediaMstCode: selectedObj.paymentMediaCode,
      id: {
        companyCode: selectedObj.companyCode,
        orgCode,
        posType: selectedObj.postType.toString(),
        paymentMediaCode: selectedObj.importCode,
      },
      applyStartDayStr: selectedObj.applicableStartMonthStr,
      applyEndDayStr: selectedObj.applicableEndMonthStr,
      createUser: staffName,
      updateUser: staffName,
    };
    deletePaymentMediaOrganization(
      confirmModalResendOpen ? 1 : 0, submitObj,
    ).then((Response: any) => {
      setPaymentMediaOrganizationList(
        paymentMediaOrganizationList.filter(
          (
            paymentMediaOrganization,
          ) => paymentMediaOrganization.paymentMediaCode !== selectedObj.paymentMediaCode
          || paymentMediaOrganization.postType !== selectedObj.postType
            || paymentMediaOrganization.importCode !== selectedObj.importCode,
        ),
      );
      setConfirmModalOpen(false);
      setConfirmModalResendOpen(false);
      successNotification('削除しました。');
    }).catch((error) => {
      setConfirmModalOpen(false);
      if (error.response.status === 406) {
        const errorString = '既にPOSデータが取り込まれています。削除するとレポートや帳票で売上の金額が合わなくなる可能性がありますが、よろしいですか？';
        setConfirmModalResendOpen(true);
        setConfirmModalContent(errorString);
      } else {
        if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
          let errorString: string = '';

          error.response.data.errors.forEach((errorStr: { defaultMessage: any; }) => { errorString = `${errorString}${errorStr.defaultMessage}</br>`; });
          errorNotification(errorString);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
        setToastModalOpen(true);
      }
    });
  }, [confirmModalResendOpen, errorNotification, orgCode,
    paymentMediaOrganizationList, selectedObj.applicableEndMonthStr,
    selectedObj.applicableStartMonthStr, selectedObj.companyCode,
    selectedObj.importCode, selectedObj.paymentMediaCode,
    selectedObj.postType, setPaymentMediaOrganizationList, successNotification]);


  const columns: Array<Column<PaymentMediaOrganizationDomain>> = useMemo(() => [
    {
      Header: '支払メディア',
      accessor: 'paymentMediaStr',
      sortBy: 'basic'
    },
    {
      Header: 'POS',
      accessor: 'postTypeStr',
    },
    {
      Header: '取込コード',
      accessor: 'importCode',
    },
    {
      Header: '適用開始月',
      accessor: 'applicableStartMonthStr',
    },
    {
      Header: '適用終了月',
      accessor: 'applicableEndMonthStr',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row: { isExpanded: boolean, original: PaymentMediaOrganizationDomain } }) => (
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
          <Link to={`/salesPaymentMedia/paymentSetting/edit/${cell.row.original.importCode}/${cell.row.original.postType}/${cell.row.original.paymentMediaCode}/${cell.row.original.applicableStartMonthFormat}/${orgCode}`}>
            <span
              style={{ marginLeft: '20px' }}
              onMouseOver={
                () => {
                  setHover(
                    cell.row.original.importCode.concat(
                      cell.row.original.postType, cell.row.original.paymentMediaCode,
                    ),
                  );
                }
              }
              onMouseOut={() => { setHover(''); }}
              onFocus={() => { }}
              onBlur={() => { }}
            >
              <Icon type="edit" color={(isHover && isHover === (cell.row.original.importCode.concat(cell.row.original.postType, cell.row.original.paymentMediaCode))) ? iconColor.grayhover : iconColor.gray} />
            </span>
          </Link>
          {
            roleScreen && roleScreen.editable === 1 && (
              <span
                style={{ marginLeft: '20px', marginRight: '10px' }}
                onMouseOver={
                  () => {
                    setHover2(
                      cell.row.original.importCode.concat(
                        cell.row.original.postType, cell.row.original.paymentMediaCode,
                      ),
                    );
                  }
                }
                onMouseOut={() => { setHover2(''); }}
                onClick={() => { openConfirmModal(cell.row.original); }}
                role="presentation"
                onFocus={() => { }}
                onBlur={() => { }}
              >
                <Icon type="delete" color={(isHover2 && isHover2 === (cell.row.original.importCode.concat(cell.row.original.postType, cell.row.original.paymentMediaCode))) ? iconColor.grayhover : iconColor.gray} />
              </span>
            )
          }
        </div>
      ),
    },
  ], [isHover, isHover2, openConfirmModal, orgCode, roleScreen]);
  return (
    <div style={{ marginTop: '12px' }}>
      <FlexBox>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
            <FlexBoxItem>
              <IconLabelButton
                onClick={downloadFileCSV}
                iconType="download"
                text="CSVダウンロード"
              />
            </FlexBoxItem>
          )
        }
        {
          roleScreen && roleScreen.importFlag === 1 && (
            <FlexBoxItem>
              <Link to={`/salesPaymentMedia/paymentSetting/import`}>
                <PrimaryButton
                  ghost={false}
                  text="インポート"
                />
              </Link>
            </FlexBoxItem>
          )
        }
        {
          roleScreen && roleScreen.editable === 1 && (
            <FlexBoxItem grow={1}>
              <div style={{ textAlign: 'right' }}>
                <Link to={`/salesPaymentMedia/paymentSetting/add/${orgCode}`}>
                  <PrimaryButton
                    ghost={false}
                    text="新規登録"
                  />
                </Link>
              </div>
            </FlexBoxItem>
          )
        }
      </FlexBox>


      <div style={{ marginRight: '12px', padding: '16px' }}>
        <SelectForm
          label="組織名："
          name="orgCode"
          value={orgCode}
          setValue={(v) => {
            sessionStorage.setItem('selectedOrgCode', v);
            setorgCode(v);
          }}
          options={orgTreesOptions}
          description=""
        />
      </div>

      <div
        css={css`
            table th:nth-child(1) {
              width: 200px;
            }
            table th:nth-child(2) {
              width: 100px;
            }
            table th:nth-child(3) {
              width: 100px;
            }
            table th:nth-child(4) {
              width: 100px;
            }
            table th:nth-child(5) {
              width: 100px;
            }
            table th:nth-child(6) {
              width: 80px;
            }
          `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
          <DataTable
            columns={columns}
            data={paymentMediaOrganizationList}
            sortBy={[
              { id: 'paymentMediaStr', desc: false }
            ]}
          />
        </div>
      </div>
      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={deletePaymentMediaOrg}
        title="確認メッセージ"
        content="削除します。よろしいですか？"
        submitText="削除"
        items={[]}
      />
      <ConfirmModal
        open={confirmModalResendOpen}
        closeHandler={closeConfirmResendModal}
        onSubmit={deletePaymentMediaOrg}
        title="確認"
        content={confirmModalContent}
        submitText="削除"
        items={[]}
      />
      <ToastModal
        open={toastModalOpen}
        closeHandler={() => setToastModalOpen(false)}
        title={toastMessage}
      />
    </div>
  );
};

export default PaymentImportMasterPage;
