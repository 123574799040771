import { Holiday } from 'domain/holiday';
import ApiClient from './ApiClient';

export const getCalendar = async (year: string): Promise<Array<Holiday>> => {
  const response = await ApiClient.get(`/v1/public/holidays/calendar?year=${year}`, {});
  return response.data;
};

export const importHoliday = async (year: string, orgCodes: string[]) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  let params = {
    createUser: staffName,
    updateUser: staffName,
  };

  orgCodes.forEach((value: string, index: number) => {
    params = {
      ...params,
      [`orgCodes[${index}]`]: value,
    };
  });

  const response = await ApiClient.post(`/v1/public/holidays/${companyCode}`, { year }, params);
  return response.data;
};

export const createOrUpdateHoliday = async (postData: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {};
  const response = await ApiClient.post(`/v2/public/holidays/original/${companyCode}`, query, postData);
  return response.data;
};

export default {
  getCalendar,
};
