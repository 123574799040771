import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import OrganizationSpecialSalaryEditForm from 'components/organismos/organization/OrganizationSpecialSalaryEditForm';

const OrganizationSpecialSalaryPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="特賃日設定"
  >
    <OrganizationSpecialSalaryEditForm />
  </SidebarTemplate>
);

export default OrganizationSpecialSalaryPage;
