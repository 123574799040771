import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesMonthlyReportLayout from 'components/organismos/master/storeManagement/salesReport/SalesMonthlyReportLayout';

const SalesMonthlyReportPage = () => (
  <SidebarTemplate pageTitle="レポート">
    <SalesMonthlyReportLayout />
  </SidebarTemplate>
);
export default SalesMonthlyReportPage;
