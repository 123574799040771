import { useState, useEffect } from 'react';
import SalesMenuTypeDomain from 'domain/master/sales/salesMenuType';
import getListMenuType from 'api/menuType';

export const useMenuTypeList = (orgCode: string) => {
  const [menuTypeList, setMenuTypeList] = useState < Array<SalesMenuTypeDomain>>([]);
  useEffect(() => {
    getListMenuType(orgCode).then((response: Array<any>) => {
      setMenuTypeList(response.map(
        (result) => new SalesMenuTypeDomain(result),
      ));
    });
  }, [orgCode]);
  return { menuTypeList, setMenuTypeList };
};

export default useMenuTypeList;
