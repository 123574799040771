/** @jsx jsx */
import React, {
  useState, useEffect, useRef,
} from 'react';
import { css, jsx } from '@emotion/core';
import DatePicker from 'components/molecules/DatePicker';
import PrimaryButton from 'components/atoms/Button';
import FlexBox from 'components/atoms/FlexBox';
import BodyText from 'components/atoms/BodyText';
import BlockUI from 'components/molecules/BlockUi';
import BottomBar from 'components/organismos/BottomBar';
import ScheduleMobileCalendar from './ScheduleMobileCalendar';
import MobileContainer from '../MobileContainer';
import ActionBar from './ActionBar';
import { useSchedulePage } from '../../attend/SchedulePage/hooks';

const styles = {
  captionWrapper: css({
    justifyContent: 'space-around',
    margin: '1rem 0',
  }),
  captionColor: (color: string, border?: string) => css({
    background: color,
    width: '18px',
    height: '18px',
    border: `1px solid ${border || color}`,
  }),
  calendar: css`
    .rbc-date-cell {
      button {
        div {
          height: 40px !important;
          width: 40px !important;
          line-height: 40px !important;
          border-radius: 0 !important;
        }
      }
    }

    .rbc-event {
      border-radius: 0 !important;
    }
  `,
};

const captionBar = [
  { name: '実績', color: '#ddd', border: '' },
  { name: '確定', color: '#9eb5d5', border: '' },
  { name: '未確定', color: '#d4eae3', border: '' },
  { name: '休暇', color: '#ffb8b8', border: '' },
  { name: '未提出', color: '#fff', border: '#0D4796' },
];

const ScheduleLayout: React.FC = () => {
  const {
    state, setter,
  } = useSchedulePage();
  const [isHasActionBar, setIsHasActionBar] = useState(false);
  const wrapperRef = useRef(null);
  const wrapperRef2 = useRef(null);

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !(wrapperRef.current as any).contains(event.target)
    && wrapperRef2.current && !(wrapperRef2.current as any).contains(event.target)) {
      // setIsHasActionBar(false);
    }
  };


  return (
    <BlockUI blocking={state.isLoading}>
      <div style={{
        overflowY: 'scroll',
        // height: (window.screen.height - 210) > 600 ? 600
        //   : (window.screen.height - 210),
        height: 'calc(100vh)',
      }}
      >
        <MobileContainer>
          <FlexBox customStyle={css({ justifyContent: 'space-between' })}>
            <DatePicker
              dateFormat="yyyy/MM"
              date={state.datePickerValue}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setter.setDatePickerValue(date);
                  setter.setDate(date);
                }
              }}
              showMonthYearPicker={true}
              customStyle={css({ width: 'fit-content' })}
            />

            <PrimaryButton text="提出" minWidth="150px" onClick={setter.onSubmitHopeShifts} />

          </FlexBox>

          <FlexBox customStyle={styles.captionWrapper}>
            {captionBar.map((item, index) => (
              <FlexBox key={index.toString()}>
                <div css={styles.captionColor(item.color, item.border)} />
                <BodyText size="sm">
                  &nbsp;
                  {item.name}
                </BodyText>
              </FlexBox>
            ))}
          </FlexBox>

          <div css={styles.calendar} ref={wrapperRef}>
            <ScheduleMobileCalendar
              events={state.events}
              date={state.date}
              datePickerValue={state.datePickerValue}
              setDate={setter.setDate}
              setDatePickerValue={setter.setDatePickerValue}
              setIsHasActionBar={setIsHasActionBar}
            />
          </div>
          {isHasActionBar && (
            <div ref={wrapperRef2}>
              <ActionBar
                setSelectedHopeShift={setter.setSelectedHopeShift}
                shiftPatternList={state.shiftPatternList}
                selectedHopeShift={state.selectedHopeShift}
                shiftPatternOptions={state.shiftPatternOptions}
                hopeShiftList={state.hopeShiftList}
                setIsHasActionBar={setIsHasActionBar}
                state={state}
                setter={setter}
              />
            </div>
          )}
        </MobileContainer>
        <BottomBar />

      </div>
    </BlockUI>
  );
};

export default ScheduleLayout;
