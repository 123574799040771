/** @jsx jsx */
import React, { ReactNode } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';
import { EventWrapperProps } from 'react-big-calendar';

import {
  productColor, textColor, textFontSize,
} from 'components/styles';

const createStyle = (color: string = productColor.primaryM95) => css({
  fontSize: textFontSize.re,
  width: '100%',
  '& > *': {
    backgroundColor: color || productColor.primaryM80,
    color: textColor.main,
    textAlign: 'center',
  },
});

/**
 * イベントラッパー
 * - eventにcolorが指定されている場合に設定する
 */
const EventWrapper: React.FC<EventWrapperProps & {customCss?: SerializedStyles}> = (props) => {
  // console.log(props);
  const {
    children, event, customCss,
  } = props as EventWrapperProps & {
    children: ReactNode;
    event: Event & { color?: string };
    customCss: SerializedStyles;
  };

  return (
    <div css={css(createStyle(event.color), customCss)}>
      {children}
    </div>
  );
};

export default EventWrapper;
