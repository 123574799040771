
import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import { useParams } from 'react-router-dom';
import CreateStoreSettingsForm from '../CreateStoreSettingsForm';

const CreateStoreSettingsPage: React.FC = () => {
  const { editStore } = useParams();
  return (
    <SidebarTemplate pageTitle={editStore ? '組織設定編集' : '店舗設定作成'}>
      <CreateStoreSettingsForm />
    </SidebarTemplate>
  );
};

export default CreateStoreSettingsPage;
