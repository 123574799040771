import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesBudgetMonthLayout from 'components/organismos/master/storeManagement/salesBudgetYear/SalesBudgetMonthLayout';

const SalesBudgetMonth = () => (
  <SidebarTemplate pageTitle="予算編成（月間）">
    <SalesBudgetMonthLayout />
  </SidebarTemplate>
);
export default SalesBudgetMonth;
