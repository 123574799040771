import moment from 'moment';
import ApiClient from './ApiClient';

export const getListDailyReport = async (param:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/jsp/sj/daySeparateReport/${companyCode}`, param);
  return response.data;
};

export const getListMedia = async (param:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/jsp/sj/daySeparateReport/paymentmedianame/${companyCode}`, param);
  return response.data;
};

export const getListCredit = async (param:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/jsp/sj/daySeparateReport/creditname/${companyCode}`, param);
  return response.data;
};

export const downloadCsv = async (param:any, fileName: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadExcelPost(`/v1/jsp/sj/daySeparateReport/exportFile/csv//${companyCode}`, param, {}, getDownloadCsvFileName(fileName));
};

const getDownloadCsvFileName = (prefix: string) => `${prefix}.csv`;

export const updateCommentForSale = async (
  orgCode: string,
  targetDay: Date,
  budgetProgressComment: string,
  storeOperationComment: string,
  otherComment: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const updateUser = sessionStorage.getItem('loginUser.staffName');
  const params = {
    orgCode,
    updateUser,
    targetDay: moment(targetDay).format('YYYY-MM-DD'),
    budgetProgressComment,
    storeOperationComment,
    otherComment,
  };
  const response = await ApiClient.post(`/v2/report/daily_detail/update_comment/${companyCode}`, {}, params);
  return response.data;
};

export const updateCashierReportManual = async (orgCode: string, targetDay: Date, data: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDay: moment(targetDay).format('YYYY-MM-DD'),
    loginStaffCode: sessionStorage.getItem('loginUser.staffCode') || '',
  };
  const response = await ApiClient.post(`/v1/jsp/sj/salesDailyReport/update/${companyCode}`, params, data);
  return response.data;
};

export const updateImageDetailReport = async (dataPost: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.postMutipartData(`/v1/jsp/sj/salesDailyReport/update/attachment/${companyCode}`, {}, dataPost);
  return response.data;
};

export const deleteImageDetailReport = async (dataPost: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.postMutipartData(`/v1/jsp/sj/salesDailyReport/delete/attachment/${companyCode}`, {}, dataPost);
  return response.data;
};

export const exportFilePDF = async (
  orgCode: any,
  targetDateExport: any,
  usabilityGeneralItem3: number,
  usabilityGeneralItem1:number,
  displayHqSharedMatters: number,
): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const targetDate = moment(targetDateExport).format('YYYY-MM-DD');
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    targetDate,
    loginStaffCode,
    staffLaborCostFlag: usabilityGeneralItem3,
    roleFlag: usabilityGeneralItem1 === 1 ? '1' : '0',
    displayHqSharedMatters,
  };

  const response = await ApiClient.getDownloadFile(`/v1/jsp/sj/salesDailyReport/export/pdf/${companyCode}?`, params);
  return response.data;
};

export type DetailApplicationData = {
  applicant: string | null;
  application_time: Date | null;
  changer: string | null;
  update_time: Date | null;
  authorizer: string | null;
  approval_time: Date | null;
}
export type SalesSetting = {
  calculationItemOfFRate: number;
  includePettyCashInPurchase: boolean;
}


export const getSalesPlanDaily = async (orgCode: string, targetDay: Date): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDay: moment(targetDay).format('YYYY-MM-DD'),
  };
  const response = await ApiClient.get(`/v1/jsp/sj/salesDailyReport/get/${companyCode}`, params);
  return response.data;
};

export default { getListDailyReport };
