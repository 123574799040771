import React, { SVGProps } from 'react';

const Company: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M16.5 3.375c.345 0 .625.28.625.625v12c0 .345-.28.625-.625.625h-13A.625.625 0 012.875 16V9c0-.345.28-.625.625-.625h5.875V4c0-.345.28-.625.625-.625h6.5zm-.625 1.25h-5.25v10.75h5.25V4.625zm-6.5 5h-5.25v5.75h5.25v-5.75zM5.625 13a.625.625 0 110 1.25.625.625 0 010-1.25zm2.25 0a.625.625 0 110 1.25.625.625 0 010-1.25zm4.25 0a.625.625 0 110 1.25.625.625 0 010-1.25zm2.25 0a.625.625 0 110 1.25.625.625 0 010-1.25zm-8.75-2.25a.625.625 0 110 1.25.625.625 0 010-1.25zm2.25 0a.625.625 0 110 1.25.625.625 0 010-1.25zm4.25 0a.625.625 0 110 1.25.625.625 0 010-1.25zm2.25 0a.625.625 0 110 1.25.625.625 0 010-1.25zm-2.25-2.25a.625.625 0 110 1.25.625.625 0 010-1.25zm2.25 0a.625.625 0 110 1.25.625.625 0 010-1.25zm-2.25-2.25a.625.625 0 110 1.25.625.625 0 010-1.25zm2.25 0a.625.625 0 110 1.25.625.625 0 010-1.25z"
        fill="#333"
      />
    </g>
  </svg>
);

export default Company;
