import LoginUserDomain from 'domain/loginUser';
import { useEffect, useState } from 'react';
import { SessionStorageKey } from 'utility/constants';
import { photoPathByStaffCode } from './usePhotoPath';

const useLoginUser = () => {
  const [staffName, setStaffName] = useState('');

  useEffect(() => {
    if (sessionStorage.getItem('login.shopCode')) {
      setStaffName(sessionStorage.getItem('login.shopName') || '');
    } else {
      setStaffName(sessionStorage.getItem('loginUser.staffName') || '');
    }
  }, []);

  const storeLoginUserToSessionStorage = (loginUser: LoginUserDomain) => {
    sessionStorage.setItem(SessionStorageKey.LoginUser.MAIL, loginUser.mail);
    sessionStorage.setItem(SessionStorageKey.LoginUser.COMPANY_CODE, loginUser.companyCode);
    sessionStorage.setItem(SessionStorageKey.LoginUser.STAFF_CODE, loginUser.staffCode);
    sessionStorage.setItem(SessionStorageKey.LoginUser.STAFF_NAME, loginUser.staffName);
    sessionStorage.setItem(SessionStorageKey.LoginUser.ORG_CODE, loginUser.orgCode);
    sessionStorage.setItem(SessionStorageKey.LoginUser.ORG_NAME, loginUser.orgName);
    sessionStorage.setItem(SessionStorageKey.LoginUser.PERSONAL_ROLE, loginUser.personalRole);
    sessionStorage.setItem(SessionStorageKey.LoginUser.ADMINISTRATOR, String(loginUser.administrator));
    sessionStorage.setItem(SessionStorageKey.LoginUser.USE_GPS_STAMP, String(loginUser.useGpsStamp));
    sessionStorage.setItem(SessionStorageKey.LoginUser.USE_SALARY_PAY_SLIP, String(loginUser.useSalaryPayslip));
    sessionStorage.setItem(SessionStorageKey.LoginUser.ACCESS_LEVEL, loginUser.accessLevel);
    sessionStorage.setItem(SessionStorageKey.LoginUser.MODIFY_REGIREPORT, String(loginUser.modifyRegireport));
    sessionStorage.setItem(SessionStorageKey.LoginUser.DISPLAY_LABOR_COST, String(loginUser.displayLaborCost));
    sessionStorage.setItem(SessionStorageKey.LoginUser.NOTIFICATION_TYPE, String(loginUser.notificationType));
    const photoPath = photoPathByStaffCode(loginUser.staffCode, '1.jpg', loginUser.companyCode);
    sessionStorage.setItem(SessionStorageKey.LoginUser.PHOTO_PATH, photoPath);
  }

  return {
    staffName,
    storeLoginUserToSessionStorage,
  };
};

export default useLoginUser;
