import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import ForgotPasswordSubmitInputForm from './ForgotPasswordSubmitInputForm';

const ForgotPasswordSubmitForm: React.FC = () => (
  <FormContents>
    <ForgotPasswordSubmitInputForm />
  </FormContents>
);

export default ForgotPasswordSubmitForm;
