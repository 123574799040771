/** @jsx jsx */
import { Column } from 'react-table';
import React, { useMemo, useCallback, useState } from 'react';
import { css, jsx } from '@emotion/core';
import LableTitle from 'components/atoms/LableTitle';
import PageTitle from 'components/atoms/PageTitle';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import DataTable from 'components/organismos/DataTable/DataTable';
import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import WorkingDaysDomain from 'domain/master/labor/workingDays';
import { deleteWorkingDayById } from 'api/workingDay';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import useToastNotification from 'hooks/useToastNotification';

import UsePrescribedWorkingDaysList from './hooks';

const PrescribedDaysPage: React.FC<{
  roleScreen?: any
}> = ({ roleScreen }) => {
  const { workingDayList, setWorkingDayList } = UsePrescribedWorkingDaysList();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedPrescribed, setSelectedPrescribed] = useState(WorkingDaysDomain.generateInitial());
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((workingDay : WorkingDaysDomain) => {
    setConfirmModalOpen(true);
    setSelectedPrescribed(workingDay);
  }, []);


  const deletePrescribed = useCallback(
    () => {
      deleteWorkingDayById(selectedPrescribed.workingDaysId).then(() => {
        setWorkingDayList(workingDayList.filter(
          (prescribed: WorkingDaysDomain) => prescribed.workingDaysId
                                            !== selectedPrescribed.workingDaysId,
        ));
        setConfirmModalOpen(false);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.map((element: any, index: any) => {
            stringErr += `${element.defaultMessage}\n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    },
    [errorNotification, selectedPrescribed.workingDaysId, setWorkingDayList,
      successNotification, workingDayList],
  );

  const columns: Array<Column<WorkingDaysDomain>> = useMemo(() => [
    {
      Header: 'コード',
      accessor: 'workingDaysPatternCode',
      sortType: 'basic'
    },
    {
      Header: 'パターン名',
      accessor: 'workingDaysPatternName',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: WorkingDaysDomain },
            data: Array<WorkingDaysDomain>
          }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/masterWorkingDays/edit/${cell.row.original.workingDaysId}/0`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />

              </span>
            </Link>
            {
              roleScreen && roleScreen.editable === 1 && (
              <React.Fragment>
                <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                  <span
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                    onMouseEnter={() => setIsHoverDelete(true)}
                    onMouseLeave={() => setIsHoverDelete(false)}
                  >
                    <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </Link>
              </React.Fragment>
            )}
          </div>
        );
      },
    },
  ], [openConfirmModal, roleScreen]);
  return (
    <React.Fragment>
      <div>
        <LableTitle title="月給者の人件費を所定労働日数で按分する日数を登録します。" />
        {
          roleScreen && roleScreen.editable === 1 && (
          <PageTitle
            title=""
            rightContents={(
              <Link to="/masterWorkingDays/add">
                <span style={{ marginRight: '12px' }}>
                  <PrimaryButton
                    ghost={false}
                    text="新規所定労働日数作成"
                  />
                </span>
              </Link>
            )}
          />
        )}
        <div
          css={css`
            table th:nth-child(1) {
              width: 100px;
            }
            table th:nth-child(2) {
              width: 200px;
            }
            table th:nth-child(3) {
              width: 120px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTable
              columns={columns}
              data={workingDayList}
              sortBy={[
                { id: 'workingDaysPatternCode', desc: false }
              ]}
            />
          </div>
        </div>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={deletePrescribed}
          actionType={ActionType.DELETE}
        />
      </div>
    </React.Fragment>
  );
};

export default PrescribedDaysPage;
