/* eslint-disable max-len */
import { useEffect, useState, useCallback } from 'react';
import organization, { getSelOrganize, getSelOrganizeStaff } from 'api/organization';
import SelfOrganizationDomain from 'domain/master/general/selfOrganization';

export const useOrgList = (
  addOrganization: (arg: Array<SelfOrganizationDomain>) => void,
  openHandler: (arg: boolean) => void,
) => {
  const [organizationList, setOrganizationList] = useState<Array<SelfOrganizationDomain>|null>(null);
  const [organizationListFilter, setOrganizationListFilter] = useState<Array<SelfOrganizationDomain>|null>(null);
  const [checkedOrgCodeList, setCheckedOrgCodeList] = useState<Array<string>>([]);
  const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(undefined);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    const fetchStaffList = async () => {
      // const fetchedStaffList = await getSelOrganize();
      const fetchedStaffList = await getSelOrganizeStaff(categoryAry);

      setOrganizationList(fetchedStaffList.map((fetchStaff) => new SelfOrganizationDomain(fetchStaff)));
      setOrganizationListFilter(fetchedStaffList.map((fetchStaff) => new SelfOrganizationDomain(fetchStaff)));
    };
    fetchStaffList();
  }, [categoryAry, setOrganizationList]);

  useEffect(() => {
    let filter: any = organizationListFilter?.filter((staff) => {
      return staff.orgName.toLowerCase().includes(search.toLowerCase()) || staff.orgCode.toLowerCase().includes(search.toLowerCase())
    });
    setOrganizationList(filter);
  }, [search])

  /**
   * チェックボックスのハンドラー
   */
  const checkHandler = (staffCode: string) => {
    const isChecked = checkedOrgCodeList.includes(staffCode);
    if (isChecked) {
      // チェック済みならリストからはずす
      setCheckedOrgCodeList(checkedOrgCodeList.filter(
        (checkedStaffCode) => checkedStaffCode !== staffCode,
      ));
      setCheckAll(false);
      return;
    }
    setCheckedOrgCodeList(checkedOrgCodeList.concat(staffCode));
    if (checkedOrgCodeList.concat(staffCode).length === organizationList?.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }

  const checkAllHandler = (checkAll: boolean) => {
    const arrOrganization: any = [];
    if (checkAll) {
      organizationList?.map((organization) => {
        arrOrganization.push(organization.orgCode);
      });
    }
    setCheckedOrgCodeList(arrOrganization);
  } 

  /**
   * 追加実行処理
   */
  const onSubmit = useCallback(() => {
    if (!organizationList) {
      return;
    }
    addOrganization(organizationList.filter((organization) => checkedOrgCodeList.includes(organization.orgCode)));

    // モーダルを閉じる
    openHandler(false);

    // reset checkbox
    setCheckedOrgCodeList([]);
    setCheckAll(false);
    setSearch('');
  }, [addOrganization, checkedOrgCodeList, openHandler, organizationList]);

  return {
    organizationList,
    checkedOrgCodeList,
    setCheckedOrgCodeList,
    checkHandler,
    onSubmit,
    categoryAry,
    setCategoryAr,
    checkAll,
    setCheckAll,
    checkAllHandler,
    search,
    setSearch,
  };
};

export default useOrgList;
