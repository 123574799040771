import ApiClient from 'api/ApiClient';

export interface MediaSales {
    advertisingMediaMstCode: String;
    advertisingMediaMstName: String;
    dispOrder: number;
    guestCnt: number;
    customers: number;
    advertisingMediaAmount:number;
  }

export const apiGetMediaSales = async (
  path: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  categoryAry: any,
)
  : Promise<MediaSales> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    loginStaffCode,
    functionType: 3,
  };

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }

  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/${path}/${companyCode}?${appendUrl}`, params);
  return response.data;
};
