/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import { isMacOs } from 'react-device-detect';
import { useDownload } from './hooks';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';
import { grayScale } from 'components/styles';
import useSortTable, { FieldSortState, SortColumn } from 'hooks/useSortTable';
import TimeProductReportItemDomain, { TimeProductReport } from 'domain/master/storeManagement/TimeProductReportItem';

const DEFAULT_SORT_FIELD = { fieldPath: 'menuCode', sort: 'asc' } as FieldSortState

const TimeDaySeparateProducts: React.FC<{
  setIsLoading: any,
  productTimeList: Array<TimeProductReport>,
  targetTimeList: Array<string>,
  targetPeriodText: string,
  targetStoreText: string,
  targetAggregateText: string,
  targetWeekText: string,
  targetTimeText: string,
  roleScreen: any
}> = ({
  setIsLoading,
  productTimeList,
  targetTimeList,
  targetPeriodText,
  targetStoreText,
  targetAggregateText,
  targetWeekText,
  targetTimeText,
  roleScreen
}) => {

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート項目 */
    const sortColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '商品コード', sortField: 'menuCode',  sortType: 'string', style: { top: '0', left: '0', zIndex: 99, maxWidth: ' 150px', width: '150px', minWidth: '150px' },     className: "text-center stickyStyle fristColSticky" },
      { displayName: '商品名',     sortField: 'menuName',  sortType: 'string', style: { top: '0', left: '153px', zIndex: 99, maxWidth: ' 200px', width: '200px', minWidth: '200px' }, className: "text-center stickyStyle fristColSticky" },
      { displayName: '単価',       sortField: 'unitPrice', sortType: 'number', style: { top: '0', left: '356px', zIndex: 99 },                                                        className: "text-center stickyStyle fristColSticky" },
    ], []);

    const sortSubColumns: Array<SortColumn> = useMemo(() => [
      { displayName: '数量', sortField: 'cnt',   sortType: 'number', style: { position: 'sticky', top: '34px' }, className: "text-center stickyStyle" },
      { displayName: '売上', sortField: 'sales', sortType: 'number', style: { position: 'sticky', top: '34px' }, className: "text-center stickyStyle" },
    ], []);

    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(productTimeList, DEFAULT_SORT_FIELD);

    // ------------------------------------------------------------------
    // 出力
    // ------------------------------------------------------------------
    /** CSV・Excel出力実装 */
    const { downloadCntOrderReportCsv, downloadCntOrderReportExcel } = useDownload();
    /** 出力用データ */
    const outputDataList = useMemo(() => (
      dataSort.map((data: TimeProductReport) => [
        data.menuCode, data.menuName, data.unitPrice,
        ...data.items.flatMap((item: TimeProductReportItemDomain) => item.getOutputData())
      ])
    ), [dataSort]);
    /** CSV出力用ヘッダ情報 */
    const headerInformation = useMemo(() => [
      [
        ...sortColumns.map(s => ''),
        ...targetTimeList.flatMap(targetTime => [targetTime, ...Array((sortSubColumns.length - 1)).fill('')]),
      ],
      [
        ...sortColumns.map(s => s.displayName),
        ...targetTimeList.flatMap(h => sortSubColumns.map(s => s.displayName)),
      ],
    ], [targetTimeList]);

    /** Excel出力用ヘッダ情報 */
    const headerInformationExcel = useMemo(() => [
      [
        ...sortColumns.map(s => s.displayName),
        ...targetTimeList.flatMap(targetTime => [targetTime, ...Array((sortSubColumns.length - 1)).fill('')]),
      ],
      [
        ...sortColumns.map(s => ''),
        ...targetTimeList.flatMap(h => sortSubColumns.map(s => s.displayName)),
      ],
    ], [targetTimeList]);
    /**
     * Excel出力用セル結合情報
     * NOTE: [firstRow, lastRow, firstCol, lastCol] の配列で結合するセル範囲を指定する
    */
    const mergedRegions = useMemo(() => {
      const tmpMergedRegions: any[] = [];
      // ヘッダの商品コードセルを縦方向に結合
      tmpMergedRegions.push([6, 7, 0, 0]);
      // ヘッダの商品名セルを縦方向に結合
      tmpMergedRegions.push([6, 7, 1, 1]);
      // ヘッダの単価セルを縦方向に結合
      tmpMergedRegions.push([6, 7, 2, 2]);
      // ヘッダの時間を時間毎に横方向に結合
      let firstColIndex = sortColumns.length;
      let lastColIndex = firstColIndex + sortSubColumns.length - 1;
      targetTimeList.map((targetTime: string) => {
        tmpMergedRegions.push([6, 6, firstColIndex, lastColIndex]);
        firstColIndex += sortSubColumns.length;
        lastColIndex += sortSubColumns.length;
      })
      return tmpMergedRegions;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetTimeList]);


    const data = {
      footer: null,
      formName: '商品時間別',
      targetPeriod: targetPeriodText,
      targetStores: targetStoreText,
      targetAggregate: targetAggregateText,
      targetDay: targetWeekText,
      targetTime: targetTimeText,
      headerInformation,
      searchResult: outputDataList,
      totalInformation: [],
    };

    const dataExcel = {
      formName: '商品時間別',
      targetPeriod: targetPeriodText,
      targetStores: targetStoreText,
      targetAggregate: targetAggregateText,
      targetDay: targetWeekText,
      targetTime: targetTimeText,
      headerInformation: headerInformationExcel,
      searchResult: outputDataList,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex: [],
    };

    const handleExportCSV = () => downloadCntOrderReportCsv(data, setIsLoading);
    const handleExportExcel = () => downloadCntOrderReportExcel(dataExcel, setIsLoading);

    return (
      <div>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportCSV}
                iconType="download"
                text=" CSV出力"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportExcel}
                iconType="download"
                text=" EXCEL出力"
              />
            </FlexBoxItem>
          </FlexBox>
        )}
        <div style={{ margin: '15px' }}>
          <div className="templateTable newDesignTable" style={{ height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: isMacOs ? '35px' : '1px' }} >
            <table className="table table-bordered text-nowrap">
              <thead>
                <tr>
                  { sortColumns.map((col: SortColumn, index: number) => (
                    <th key={index} style={col.style} rowSpan={2} className={col.className} onClick={e => sortByFieldPath(col.sortField, col.sortType)}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ margin: 'auto' }}>{col.displayName}</span>
                        {sortField.fieldPath === col.sortField && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                        {sortField.fieldPath !== col.sortField && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                      </div>
                    </th>
                  ))}
                  {
                    targetTimeList.map((name: any, index: any) => (
                      <th key={index} colSpan={sortSubColumns.length} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>{name}</span></th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    targetTimeList.map((name: any, index: number) => (
                      sortSubColumns.map((col: SortColumn, idx: number) => (
                        <th key={idx} style={col.style} className={col.className}  onClick={e => sortByFieldPath(`items[${index}].${col.sortField}`, col.sortType)}>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ margin: 'auto' }}>{col.displayName}</span>
                            {sortField.fieldPath === `items[${index}].${col.sortField}` && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                            {sortField.fieldPath !== `items[${index}].${col.sortField}` && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                          </div>
                        </th>
                      ))
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  dataSort.length > 0 ? dataSort.map((dispSlitData: TimeProductReport, index: number) => (
                    <tr key={index}>
                      <td className="text-center stickyStyle fristColSticky" style={{ left: '0', zIndex: 10 }}><span><div className="text-ellipsis">{dispSlitData.menuCode}</div></span></td>
                      <td className="text-center stickyStyle " style={{ left: '153px', zIndex: 10 }}><span><div className="text-ellipsis">{dispSlitData.menuName}</div></span></td>
                      <td className="text-center stickyStyle " style={{ left: '356px', zIndex: 10 }}><span><div className="text-ellipsis">{dispSlitData.unitPrice}</div></span></td>
                      {
                        dispSlitData.items.map((dataItem: TimeProductReportItemDomain, idx: number) => (
                          <React.Fragment key={idx}>
                            <td className="text-right"><span><div className="text-ellipsis">{dataItem.cnt}</div></span></td>
                            <td className="text-right"><span><div className="text-ellipsis">{dataItem.sales}</div></span></td>
                          </React.Fragment>
                        ))
                      }
                    </tr>
                  )) : null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

export default TimeDaySeparateProducts;
