import ApiClient from 'api/ApiClient';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { TCompanyBankList } from '../type/import';

type TBankList = {
  bankCode: string,
  bankName:string
}
/**
 * シフトインポート（Excel)
 */
// eslint-disable-next-line
export const importZenginFileExcel = async (uploadFile: any, businessStartDate:string, businessEndDate:string): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const createUser = sessionStorage.getItem('loginUser.staffName') || '';
  console.log(uploadFile, 'uploadFile');
  const params = {
    businessStartDate,
    businessEndDate,
    createUser,
  };
  const response = await ApiClient.postFile(`/v1/bank/deposit/importZengin/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

/**
 * シフトインポート（Excel)
 */
// eslint-disable-next-line
export const importKoyuuFileExcel = async (uploadFile: any, businessStartDate:string, businessEndDate:string, bankCode:string): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const createUser = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    bankCode,
    businessStartDate,
    businessEndDate,
    createUser,
  };
  const response = await ApiClient.postFile(`/v1/bank/deposit/importDeposit/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};


// get bank list
export const getBankList = async (): Promise<AxiosResponse<TCompanyBankList>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    importFlg: 'true',
    hasOther: 'false',
    zenginFlg: 'false',
  };
  const response = await ApiClient.get(`/v1/companyBank/list/${companyCode}`, {}, params);
  return response.data;
};

// post import regist
export const postImportRegist = async (params:any): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.postNoQueryJson(`/v1/bank/deposit/importRegist/${companyCode}`, params);
  return response.data;
};
