/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import moment from 'moment';
// import Calendar from 'components/molecules/Calendar';
import {
  momentLocalizer, Calendar,
} from 'react-big-calendar';
import { ShiftMonthCalendarEvent } from 'components/pages/master/attend/SchedulePage/hooks';
import { ScheduleStatus } from 'api/schedule';
import WeekEventWrapper from './WeekEventWrapper';
import WeekEvent from './WeekEvent';
import useDrawingInfo from './hooks';


const styles = {
  wrapperHour: css({
    overflowY: 'scroll',
    height: 580,
    marginTop: 20,
    marginLeft: -20,
    marginRight: -20,
    // 選択中のイベント
    '.rbc-event-label': {
      alignSelf: 'center',
    },
    '.rbc-time-header-content': {
      pointerEvents: 'none',
      height: 60,
      borderLeft: 'none',
    },
    '.rbc-allday-cell': {
      height: 0,
    },
    '.rbc-header': {
      borderBottom: 'none',
      borderLeft: 'none',
    },
    '.rbc-time-header': {
      borderRight: 'none',
    },
    '.rbc-time-view': {
      border: 'none',
    },
    '.rbc-today': {
      backgroundColor: 'transparent',
    },
    '.rbc-time-slot': {
      borderTop: 'none',
    },
    '.rbc-current-time-indicator': {
      backgroundColor: 'transparent',
    },
  }),

};
const ScheduleWeekCalendar: React.FC<{
  events: ShiftMonthCalendarEvent[],
  date: Date;
  datePickerValue: Date;
  setDate: (date: Date) => void;
  setDatePickerValue: (date: Date) => void;}> = ({
    events, date, datePickerValue, setDate, setDatePickerValue,
  }) => {
    const startHour = useDrawingInfo();

    // Events that dont have event can't edit & ready to submit
    const configEvents = events.filter((item) => !(item.hopeShift?.isEditable === false && item.hopeShift.fixStatus === ScheduleStatus.UNSUBMITED));

    const configTime = configEvents.filter((item) => item.start && item.end).map(
      (item) => ({
        ...item,
        start: (item.start && item.end)
          ? new Date(item.start?.getFullYear(),
          item.start?.getMonth(),
          item.start.getDate(),
          item.start.getHours() - startHour,
          item.start.getMinutes(), 0, 0) : new Date(),
        end: (item.start && item.end)
          ? new Date(item.end?.getFullYear(),
            item.end?.getMonth(),
            item.end.getDate(),
            item.end.getHours() - startHour,
            item.end.getMinutes(), 0, 0) : new Date(),
        title: item.fixStatus === 5 ? item.title : `${item.title}\n ${item.hopeShift?.orgName}`,
      }),
    );
    const newEvents = configTime.map(
      (item) => {
        const targetDate : Date = new Date(item.targetDate);
        targetDate.setHours(0);
        targetDate.setMinutes(0);
        const startTime : Date = item.start!;
        const endTime : Date = item.end!;

        // check round time
        if (endTime.getHours() === 0 && endTime.getMinutes() === 0) {
          endTime.setDate(endTime.getDate() - 1);
          endTime.setHours(23);
          endTime.setMinutes(59);
        }

        if (new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), 0, 0, 0, 0) < targetDate
        && (startTime.getDate() !== endTime.getDate())) {
          startTime.setDate(targetDate.getDate());
          startTime.setMonth(targetDate.getMonth());
          startTime.setHours(0);
          startTime.setMinutes(0);
        }
        if (new Date(endTime.getFullYear(), endTime.getMonth(), endTime.getDate(), 0, 0, 0, 0) > targetDate) {
          endTime.setDate(targetDate.getDate());
          endTime.setMonth(targetDate.getMonth());
          endTime.setHours(23);
          endTime.setMinutes(59);
        }
        const newEvent = {
          ...item,
          start: startTime,
          end: endTime,
          title: item.title,
        };
        return newEvent;
      },
    );

    const CustomStyleFunction = () => {
      const elements : any = document.getElementsByClassName('rbc-event');
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = elements.length; i < len; i++) {
        if (elements[i].style.top === '-2.08333%') {
          elements[i].style.top = '0%';
        }
        const { title } = elements[i];
        elements[i].title = title.replace(': ', '');
      }
    };
    CustomStyleFunction();

    return (

      <div css={css(styles.wrapperHour)}>
        <Calendar
          selectable={true}
          onSelecting={(slot) => false}
          localizer={momentLocalizer(moment)}
          events={newEvents}
          onNavigate={(newDate: Date) => {
            setDatePickerValue(newDate);
          }}
          defaultView="week"
          date={datePickerValue}
          onSelectEvent={(event: ShiftMonthCalendarEvent) => {
            setDate(new Date(event.targetDate));
          }}
          onSelectSlot={({ start }) => {
            setDate(new Date(start));
          }}
          formats={{
            timeGutterFormat: (time) => `${Number(moment(time).format('HH')) + startHour < 24 ? Number(moment(time).format('HH')) + startHour : Number(moment(time).format('HH')) + startHour - 24}`,
            // eventTimeRangeFormat:
            // ({ start, end }, culture, local) => `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`,
            eventTimeRangeFormat:
            ({ start, end }, culture, local) => '',
          }}
          components={{
            week: {
              event: WeekEvent,
            },
            toolbar: () => <div />,
            eventWrapper: WeekEventWrapper,
          }}
        />
      </div>
    );
  };

export default ScheduleWeekCalendar;
