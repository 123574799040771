import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import AccountTitleMSTImport from 'components/organismos/master/general/accountTitleMST/ImportForm/AccountTitleMSTImportForm';

const AccountTitleMSTAddPage: React.FC = () => {

  return (
    <SidebarTemplate pageTitle='勘定科目インポート'>
        <AccountTitleMSTImport />
    </SidebarTemplate>
  );
};


export default AccountTitleMSTAddPage;
