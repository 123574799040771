import { useState, useEffect } from 'react';

import TimeZoneDomain from 'domain/master/labor/TimeZone';

import getList from 'api/timeZone';

export const useTimeZone = () => {
  const [timeZoneList, setTimeZoneList] = useState<Array<TimeZoneDomain>>([]);
  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getList(companyCode).then((response: Array<any>) => {
      if (isSubscribed) {
        setTimeZoneList(response.map((result) => new TimeZoneDomain(result)));
      }
    });
    return () => { isSubscribed = false; };
  }, []);
  return { timeZoneList, setTimeZoneList };
};

export default useTimeZone;
