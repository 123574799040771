/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import moment from 'moment';

import productColor, { utilityColor } from 'components/styles';
import Calendar from 'components/molecules/Calendar';
import Icon from 'components/atoms/Icon';

import { useShiftMonthCalendarContext } from './hooks';
import IconTooltip from 'components/molecules/IconTooltip';

const styles = {
  warning: css({
    position: 'relative',
    top: '-26px',
    left: '25px',
  }),
  confirm: css({
    position: 'relative',
    top: '-26px',
    left: '25px',
  }),
};

const ShiftMonthCalendarDateHeader: React.FC = (props) => {
  const { events } = useShiftMonthCalendarContext();
  const { date } = props as {
    date: Date;
  };

  // 対象日付のeventデータを取得して、アラートを出すかのフラグを取り出す
  const targetEvent = React.useMemo(() => events.filter((event) => moment(event.start).isSame(moment(date), 'day'))[0], [date, events]);
  const isAlert = targetEvent?.isAlert;
  const isShiftConfirm = targetEvent?.isShiftConfirm;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Calendar.DateHeader {...props}>
      {isAlert && (
        <div css={styles.warning}>
          <Icon type="warning" color={utilityColor.yellow} />
        </div>
      )}
      {isShiftConfirm && (
        <div css={styles.confirm}>
          <IconTooltip 
            iconType="workSchedule" 
            text="シフト確定済み"
            color={productColor.primary}
          />
        </div>
    )}
    </Calendar.DateHeader>
  );
};

export default ShiftMonthCalendarDateHeader;
