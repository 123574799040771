import ApiClient from './ApiClient';


export const postSpecialSalaryPattern = async (dataPost: any) => {
  const query = {
    tabIndex: 0,
  };

  const response = await post(dataPost, query);
  return response.data;
};

export const postSpecialSalaryPatternSetting = async (dataPost: any) => {
  const query = {
    tabIndex: 1,
  };

  const response = await post(dataPost, query);
  return response.data;
};


export const post = async (params: any, query: {[key: string]: string|number}) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v1/specialSalaryPattern/${companyCode}`, query, params);

  return response;
};

export const getSpecialSalaryPatternList = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/specialSalaryPattern/list/${companyCode}`, {});
  return response.data;
};

export const getSpecialSalaryPattern = async (specialSalaryPatternId: string) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    specialSalaryPatternId,
  };
  const response = await ApiClient.get(`/v1/specialSalaryPattern/${companyCode}`, query);
  return response.data;
};
