/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect } from "react";
import FormContents from "components/atoms/Form/FormContents";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { Link, useHistory } from "react-router-dom";
import PrimaryButton from "components/atoms/Button";
import YearPicker from "components/molecules/YearPicker/YearPicker";
import moment from "moment";
import { exportFileExceltoBudgetYear } from "api/salesBudgetYear";
import useToastNotification from "hooks/useToastNotification";
import ConfirmModal from "components/organismos/ConfirmModal";
import BlockUI from "components/molecules/BlockUi";
import OrganizationFilteredTree from "components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree";
import useSalesBudgetYear, {
  downloadOriginalFile,
  setFileName,
  useGetFiscalYear2
} from "./hooks";
import BudgetYearTable from './BudgetYearTable';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const SalesBudgetYearLayout: React.FC<{
  getFiscalYear: number;
}> = ({ getFiscalYear }) => {
  const [getDisableValue, setDisableValue] = useState(false);
  const dateSelect = sessionStorage.getItem("dateSelect")
    ? sessionStorage.getItem("dateSelect")
    : getFiscalYear;
  const [selectedYear, setSelectedYear] = useState(Number(dateSelect));
  const [orgName, setOrgName] = useState(
    sessionStorage.getItem("destination_org") ||
      sessionStorage.getItem("loginUser.orgName") ||
      ""
  );
  const [getSupportDestinationOrg, setSupportDestinationOrg] = useState(
    sessionStorage.getItem("orgCode_DestinationOrg") || "all" || ""
  );
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";

  // notification
  const { errorNotification } = useToastNotification();
  const {
    handleChange,
    yearFormState,
    getIsLoading,
    setIsLoading,
    confirmModalOpen,
    closeConfirmModal,
    categoryAry,
    setCategoryAry,
    onSubmit,
    getSalesExpensesMSTList
  } = useSalesBudgetYear(selectedYear, getSupportDestinationOrg);

  const { fiscalStartEnd } = useGetFiscalYear2(Number(selectedYear));

  let getInputDisable = false;

  if (!getSupportDestinationOrg || getSupportDestinationOrg === "all") {
    getInputDisable = true;
  }

  // export
  const handleExport = useCallback(() => {
    const targetYear = `${selectedYear}/01/01`;
    const procDateStr = moment(new Date()).format("YYYYMMDDHHmmss");
    setIsLoading(true);
    setDisableValue(true);
    exportFileExceltoBudgetYear(
      getSupportDestinationOrg || "all",
      orgName,
      targetYear,
      procDateStr,
      categoryAry
    )
      .then(response => {
        const fileName = setFileName(
          String(getSupportDestinationOrg),
          dateSelect,
          procDateStr
        );
        downloadOriginalFile(response, fileName);
        setIsLoading(false);
        setDisableValue(false);
      })
      .catch(() => {
        setIsLoading(false);
        setDisableValue(false);
        errorNotification("サーバー側でエラーが発生しました。");
      });
  }, [
    categoryAry,
    dateSelect,
    errorNotification,
    getSupportDestinationOrg,
    orgName,
    selectedYear,
    setIsLoading
  ]);

  // 初回表示時にsessionStorageにパラメータの会計年度を格納
  if (getFiscalYear && !sessionStorage.getItem("dateSelect")) {
    sessionStorage.setItem("dateSelect", `${getFiscalYear}`);
  }

  sessionStorage.setItem(
    "orgCode_DestinationOrg",
    String(getSupportDestinationOrg)
  );
  sessionStorage.setItem("destination_org", orgName);
  sessionStorage.setItem(
    "fiscalStartEnd.Start",
    fiscalStartEnd.start.toString()
  );
  sessionStorage.setItem("fiscalStartEnd.End", fiscalStartEnd.end.toString());

  const history = useHistory();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === getSupportDestinationOrg);

  const checkUserRole = getSupportDestinationOrg !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole])

  return (
    <div>
      <BlockUI blocking={getIsLoading}>
        <FormContents>
          <FlexBox>
            <YearPicker
              selectedYear={selectedYear}
              onChange={(year: number) => {
                setSelectedYear(year);
                sessionStorage.setItem("dateSelect", `${year}`);
              }}
              setAutoLeft={false}
            />
            {
              checkUserRole && checkUserRole.importFlag === 1 && (
              <FlexBoxItem marginLeft="auto">
                <Link to="/salesBudgetYearImport">
                  <PrimaryButton
                    disabled={getSupportDestinationOrg === "all"}
                    text="インポート "
                  />
                </Link>
              </FlexBoxItem>
            )}
            {
              checkUserRole && checkUserRole.downloadFlag === 1 && (
              <FlexBoxItem marginLeft={checkUserRole && checkUserRole.importFlag === 1 ? "20px" : "auto"} >
                <PrimaryButton
                  disabled={getDisableValue}
                  onClick={handleExport}
                  text="ダウンロード "
                />
              </FlexBoxItem>
            )}
          </FlexBox>

          <OrganizationFilteredTree
            functionType={3}
            staffCode={staffCode}
            orgLabel="店舗名"
            initOrgValue={String(getSupportDestinationOrg)}
            orgCallback={(args: string | Array<string>) => {
              setSupportDestinationOrg(String(args));
            }}
            orgCategoryCallback={(args: Array<string>) => {
              setCategoryAry(args);
            }}
            orgNameCallback={(args: string | Array<string>) => {
              setOrgName(String(args));
            }}
            targetdayForm={fiscalStartEnd.start}
            targetdayTo={fiscalStartEnd.end}
            addAllItem={true}
          />
        </FormContents>
        <FormContents>
          <BudgetYearTable
            getInputDisable={getInputDisable}
            yearFormState={yearFormState}
            handleChange={handleChange}
            getSalesExpensesMSTList={getSalesExpensesMSTList}
          />
        </FormContents>
        {
          checkUserRole && checkUserRole.editable === 1 && (
            <div style={{ textAlign: "center", margin: "15px" }}>
              <PrimaryButton
                disabled={getInputDisable}
                onClick={() => onSubmit()}
                text="登録 "
              />
            </div>
          )
        }
      </BlockUI>

      <ConfirmModal
        title="確認メッセージ"
        content="登録します。よろしいですか？"
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={() => onSubmit()}
        submitText="登録"
        items={[]}
      />
    </div>
  );
};
export default SalesBudgetYearLayout;
