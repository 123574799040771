import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getList } from 'api/termsMaster';
import { Terms } from 'domain/master/attend/terms';

/**
 * 時間帯の選択ボックス用データを取得
 */
export const useTermOptions = (): any => {
  const [termOptions, setTermsOptions] = useState<Array<OptionType>>([]);
  const [termMstList, setTermMstList] = useState<Array<Terms>>([]);
  useEffect(() => {
    getList().then((Terms) => {
      const termOption = Terms.map((term) => ({
        value: term.termId,
        label: term.termName,
      }));
      termOption.unshift({
        value: '',
        label: '-',
      });
      setTermsOptions(termOption);
      setTermMstList(Terms);
    });
  }, []);
  return { termOptions, termMstList };
};

export default useTermOptions;
