import { AchievementConfirm } from 'api/achievementConfirm';

export default class AttendHeadQuartersFinalDomain {
  constructor(private rawData: AchievementConfirm) {
    //
  }

  static generateInitial(): AttendHeadQuartersFinalDomain {
    return new AttendHeadQuartersFinalDomain({
      orgName: '',
      orgCode: '',
      closingDate: '',
      dateFrom: '',
      dateTo: '',
      confirmStatus: '',
      unConfirmFlag: '',
      ownCnt: '',
    });
  }

  getRawData(): AchievementConfirm {
    return this.rawData;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get closingDate(): string {
    return this.rawData.closingDate;
  }

  get dateFrom(): string {
    return this.rawData.dateFrom;
  }

  get dateTo(): string {
    return this.rawData.dateTo;
  }

  get confirmStatus(): string {
    return this.rawData.confirmStatus;
  }

  get unConfirmFlag(): string {
    return this.rawData.unConfirmFlag;
  }

  get ownCnt(): string {
    return this.rawData.ownCnt;
  }
}
