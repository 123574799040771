import _ from "lodash";
import { formatNumber, formatPercent } from "utility/formatUtil";

export interface MonthlyBudgetPerformanceManagementReport {
    /** 店舗コード */
    orgCode: string;
    /** 店舗名 */
    orgName: string;
    /** 純売上 */
    cumulativeSales: number;
    /** 売上予算 */
    cumulativeSalesBudget: number;
    /** 予実差額 */
    diffCumulativeSales: number;
    /** 予実達成率 */
    perGoalCumulativeSales: number;
    /** 前年売上 */
    prevCumulativeSales: number;
    /** 累計前年対比 */
    perPrevCumulativeSales: number;
    /** 客数 */
    guestCnt: number;
    /** 目標客数 */
    guestCntBudget: number;
    /** 客数予実達成率 */
    perGoalGuestCntBudget: number;
    /** 客単価 */
    perGuestCnt: number;
    /** 目標客単価 */
    perGuestCntBudget: number;
    /** 客単価差額 */
    diffPerGuestCnt: number;
    /** 仕入 */
    cumulativePurchase: number;
    /** 仕入予算 */
    cumulativePurchaseBudget: number;
    /** 累計原価率 */
    perCumulativePurchase: number;
    /** 目標原価率 */
    perCumulativePurchaseBudget: number;
    /** 予実差率 */
    diffPerCumulativePurchase: number;
    /** 人件費 */
    cumulativeLaborCost: number;
    /** 人件費予算 */
    cumulativeLaborCostBudget: number;
    /** 人件費率 */
    perCumulativeLaborCost: number;
    /** 目標人件費率 */
    perCumulativeLaborCostBudget: number;
    /** 予実差率 */
    diffPerCumulativeLaborCost: number;
    /** 表示用社員労働時間 */
    employeeWorkTimeDisp: string;
    /** 表示用パート労働時間 */
    partWorkTimeDisp: string;
    /** 表示用合計労働時間 */
    totalWorkTimeDisp: string;
    /** 固定費 */
    cumulativeFixCost: number;
    /** 変動費 */
    cumulativeVariableCost: number;
    /** 利益 */
    cumulativeProfit: number;
    /** 目標利益 */
    cumulativeProfitBudget: number;
    /** 予実達成率 */
    perGoalCumulativeProfit: number;
    /** 社員労働時間 */
    employeeWorkTime: number;
    /** パート労働時間 */
    partWorkTime: number;
    /** 合計労働時間 */
    totalWorkTime: number;
  }

export default class MonthlyBudgetPerformanceManagementReportDomain {

      private _orgCode: string;
      private _orgName: string;
      private _cumulativeSales: string;
      private _cumulativeSalesBudget: string;
      private _diffCumulativeSales: string;
      private _perGoalCumulativeSales: string;
      private _prevCumulativeSales: string;
      private _perPrevCumulativeSales: string;
      private _guestCnt: string;
      private _guestCntBudget: string;
      private _perGoalGuestCntBudget: string;
      private _perGuestCnt: string;
      private _perGuestCntBudget: string;
      private _diffPerGuestCnt: string;
      private _cumulativePurchase: string;
      private _cumulativePurchaseBudget: string;
      private _perCumulativePurchase: string;
      private _perCumulativePurchaseBudget: string;
      private _diffPerCumulativePurchase: string;
      private _cumulativeLaborCost: string;
      private _cumulativeLaborCostBudget: string;
      private _perCumulativeLaborCost: string;
      private _perCumulativeLaborCostBudget: string;
      private _diffPerCumulativeLaborCost: string;
      private _employeeWorkTimeDisp: string;
      private _partWorkTimeDisp: string;
      private _totalWorkTimeDisp: string;
      private _cumulativeFixCost: string;
      private _cumulativeVariableCost: string;
      private _cumulativeProfit: string;
      private _cumulativeProfitBudget: string;
      private _perGoalCumulativeProfit: string;
      private _employeeWorkTime: number;
      private _partWorkTime: number;
      private _totalWorkTime: number;

  constructor(private rawData: MonthlyBudgetPerformanceManagementReport) {
    this._orgCode                      = rawData.orgCode;
    this._orgName                      = rawData.orgName;
    this._cumulativeSales              = formatNumber(rawData.cumulativeSales);
    this._cumulativeSalesBudget        = formatNumber(rawData.cumulativeSalesBudget);
    this._diffCumulativeSales          = formatNumber(rawData.diffCumulativeSales);
    this._perGoalCumulativeSales       = formatPercent(rawData.perGoalCumulativeSales);
    this._prevCumulativeSales          = formatNumber(rawData.prevCumulativeSales);
    this._perPrevCumulativeSales       = formatPercent(rawData.perPrevCumulativeSales);
    this._guestCnt                     = formatNumber(rawData.guestCnt);
    this._guestCntBudget               = formatNumber(rawData.guestCntBudget);
    this._perGoalGuestCntBudget        = formatPercent(rawData.perGoalGuestCntBudget);
    this._perGuestCnt                  = formatNumber(rawData.perGuestCnt);
    this._perGuestCntBudget            = formatNumber(rawData.perGuestCntBudget);
    this._diffPerGuestCnt              = formatNumber(rawData.diffPerGuestCnt);
    this._cumulativePurchase           = formatNumber(rawData.cumulativePurchase);
    this._cumulativePurchaseBudget     = formatNumber(rawData.cumulativePurchaseBudget);
    this._perCumulativePurchase        = formatPercent(rawData.perCumulativePurchase);
    this._perCumulativePurchaseBudget  = formatPercent(rawData.perCumulativePurchaseBudget);
    this._diffPerCumulativePurchase    = formatPercent(rawData.diffPerCumulativePurchase);
    this._cumulativeLaborCost          = formatNumber(rawData.cumulativeLaborCost);
    this._cumulativeLaborCostBudget    = formatNumber(rawData.cumulativeLaborCostBudget);
    this._perCumulativeLaborCost       = formatPercent(rawData.perCumulativeLaborCost);
    this._perCumulativeLaborCostBudget = formatPercent(rawData.perCumulativeLaborCostBudget);
    this._diffPerCumulativeLaborCost   = formatPercent(rawData.diffPerCumulativeLaborCost);
    this._cumulativeFixCost            = formatNumber(rawData.cumulativeFixCost);
    this._cumulativeVariableCost       = formatNumber(rawData.cumulativeVariableCost);
    this._cumulativeProfit             = formatNumber(rawData.cumulativeProfit);
    this._cumulativeProfitBudget       = formatNumber(rawData.cumulativeProfitBudget);
    this._perGoalCumulativeProfit      = formatPercent(rawData.perGoalCumulativeProfit);
    this._employeeWorkTimeDisp         = rawData.employeeWorkTimeDisp;
    this._partWorkTimeDisp             = rawData.partWorkTimeDisp;
    this._totalWorkTimeDisp            = rawData.totalWorkTimeDisp;
    this._employeeWorkTime             = rawData.employeeWorkTime;
    this._partWorkTime                 = rawData.partWorkTime;
    this._totalWorkTime                = rawData.totalWorkTime;
  }

  static generateInitial(): MonthlyBudgetPerformanceManagementReportDomain {
    return new MonthlyBudgetPerformanceManagementReportDomain({
      orgCode: '',
      orgName: '',
      cumulativeSales: 0,
      cumulativeSalesBudget: 0,
      diffCumulativeSales: 0,
      perGoalCumulativeSales: 0,
      prevCumulativeSales: 0,
      perPrevCumulativeSales: 0,
      guestCnt: 0,
      guestCntBudget: 0,
      perGoalGuestCntBudget: 0,
      perGuestCnt: 0,
      perGuestCntBudget: 0,
      diffPerGuestCnt: 0,
      cumulativePurchase: 0,
      cumulativePurchaseBudget: 0,
      perCumulativePurchase: 0,
      perCumulativePurchaseBudget: 0,
      diffPerCumulativePurchase: 0,
      cumulativeLaborCost: 0,
      cumulativeLaborCostBudget: 0,
      perCumulativeLaborCost: 0,
      perCumulativeLaborCostBudget: 0,
      diffPerCumulativeLaborCost: 0,
      employeeWorkTimeDisp: '0',
      partWorkTimeDisp: '0',
      totalWorkTimeDisp: '0',
      cumulativeFixCost: 0,
      cumulativeVariableCost: 0,
      cumulativeProfit: 0,
      cumulativeProfitBudget: 0,
      perGoalCumulativeProfit: 0,
      employeeWorkTime: 0,
      partWorkTime: 0,
      totalWorkTime: 0,
    });
  }

  getRawData(): MonthlyBudgetPerformanceManagementReport {
    return this.rawData;
  }

  /** 表示データ（画面非表示のプロパティを除外） */
  getDispData(): Partial<this>{
    return _.omit(this, ['rawData', '_employeeWorkTime', '_partWorkTime', '_totalWorkTime']);
  }

  /** 出力データ（CSV出力など） */
  getOutputData(): Array<Partial<this>> {
    return Object.values(this.getDispData());
  }

  get orgCode() : string {
    return this._orgCode;
  }

  get orgName() : string {
    return this._orgName;
  }

  get cumulativeSales() : string {
    return this._cumulativeSales;
  }

  get cumulativeSalesBudget() : string {
    return this._cumulativeSalesBudget;
  }

  get diffCumulativeSales() : string {
    return this._diffCumulativeSales;
  }

  get perGoalCumulativeSales() : string {
    return this._perGoalCumulativeSales;
  }

  get prevCumulativeSales() : string {
    return this._prevCumulativeSales;
  }

  get perPrevCumulativeSales() : string {
    return this._perPrevCumulativeSales;
  }

  get guestCnt() : string {
    return this._guestCnt;
  }

  get guestCntBudget() : string {
    return this._guestCntBudget;
  }

  get perGoalGuestCntBudget() : string {
    return this._perGoalGuestCntBudget;
  }

  get perGuestCnt() : string {
    return this._perGuestCnt;
  }

  get perGuestCntBudget() : string {
    return this._perGuestCntBudget;
  }

  get diffPerGuestCnt() : string {
    return this._diffPerGuestCnt;
  }

  get cumulativePurchase() : string {
    return this._cumulativePurchase;
  }

  get cumulativePurchaseBudget() : string {
    return this._cumulativePurchaseBudget;
  }

  get perCumulativePurchase() : string {
    return this._perCumulativePurchase;
  }

  get perCumulativePurchaseBudget() : string {
    return this._perCumulativePurchaseBudget;
  }

  get diffPerCumulativePurchase() : string {
    return this._diffPerCumulativePurchase;
  }

  get cumulativeLaborCost() : string {
    return this._cumulativeLaborCost;
  }

  get cumulativeLaborCostBudget() : string {
    return this._cumulativeLaborCostBudget;
  }

  get perCumulativeLaborCost() : string {
    return this._perCumulativeLaborCost;
  }

  get perCumulativeLaborCostBudget() : string {
    return this._perCumulativeLaborCostBudget;
  }

  get diffPerCumulativeLaborCost() : string {
    return this._diffPerCumulativeLaborCost;
  }

  get cumulativeFixCost() : string {
    return this._cumulativeFixCost;
  }

  get cumulativeVariableCost() : string {
    return this._cumulativeVariableCost;
  }

  get cumulativeProfit() : string {
    return this._cumulativeProfit;
  }

  get cumulativeProfitBudget() : string {
    return this._cumulativeProfitBudget;
  }

  get perGoalCumulativeProfit() : string {
    return this._perGoalCumulativeProfit;
  }

  get employeeWorkTimeDisp() : string {
    return this._employeeWorkTimeDisp;
  }

  get partWorkTimeDisp() : string {
    return this._partWorkTimeDisp;
  }

  get totalWorkTimeDisp() : string {
    return this._totalWorkTimeDisp;
  }

  get employeeWorkTime() : number {
    return this._employeeWorkTime;
  }

  get partWorkTime() : number {
    return this._partWorkTime;
  }

  get totalWorkTime() : number {
    return this._totalWorkTime;
  }

}
