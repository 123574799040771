import { useState, useEffect } from 'react';
import useToastNotification from 'hooks/useToastNotification';

import {
  getEmployment, getStampSubTotal, getHolidayUsingStatus,
  getOrganizationsTrees, getListStaff, getStamplist,
} from 'api/achievementConfirm';
import { getTimeSetting } from 'api/timeSetting';
import moment from 'moment';
import { DropdownMenuType } from 'components/molecules/Dropdown/DropdownContextMenu';
import { deleteShiftV1, registerHoliday } from 'api/shift';

export const useGetEmployment = (targetDateFrom: Date, targetDateTo: Date,
  staffCode: String, orgCode: String) => {
  const [employment, setEmployment] = useState(Object());
  useEffect(() => {
    const params = {
      orgCode,
      targetDateFrom: moment(new Date(targetDateFrom)).format('YYYY年MM月DD日'),
      targetDateTo: moment(new Date(targetDateTo)).format('YYYY年MM月DD日'),
      staffCode,
    };

    getEmployment(params).then((response:any) => {
      setEmployment(response);
    });
  }, [orgCode, staffCode, targetDateFrom, targetDateTo]);
  return {
    employment,
    setEmployment,
  };
};

export const useGetOrganizationsTrees = (targetDateFrom: Date, targetDateTo: Date, orgCode: String) => {
  const [orgCodeList, setOrgCodeList] = useState([]);
  useEffect(() => {
    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const functionType:number = 2
    const params = {
      staffCode,
      functionType,
      targetDayFrom: moment(new Date(targetDateFrom)).format('YYYY-MM-DD'),
      targetDayTo: moment(new Date(targetDateTo)).format('YYYY-MM-DD'),
    };

    getOrganizationsTrees(params).then((response:any) => {
      setOrgCodeList(response.map((item: any) => ({
        value: String(item.orgCode),
        label: `${item.orgCode} ${item.orgName}`,
      })));
    });
  }, [orgCode, targetDateFrom, targetDateTo]);
  return {
    orgCodeList,
    setOrgCodeList,
  };
};

export const useGetStaffs = (targetDateFrom: Date, targetDateTo: Date, orgCode: String, selectedEmploymentId: String, attendFlag: String) => {
  const [staffList, setStaffList] = useState([]);
  useEffect(() => {
    const params = {
      orgCode,
      targetDayFrom: moment(new Date(targetDateFrom)).format('YYYY-MM-DD'),
      targetDayTo: moment(new Date(targetDateTo)).format('YYYY-MM-DD'),
      attendStaff: attendFlag,
      stateStatus: '1, 3, 4',
      employmentId: selectedEmploymentId,
    };

    getListStaff(params).then((response:any) => {
      setStaffList(response.map((item: any) => ({
        value: String(item.staffCode),
        label: `${item.staffCode} ${item.staffName}`,
      })));
    });
  }, [attendFlag, orgCode, selectedEmploymentId, targetDateFrom, targetDateTo]);
  return {
    staffList,
    setStaffList,
  };
};

export const useGetStampList = (
    targetDateFrom: Date, targetDateTo: Date, staffCode: String, attendFlag: String, selectOrgCode: String,
    setToastMessage: any, setToastModalOpen: any, setSubTotalTime: any, setSubTotalDay: any,
    setHolidaySubtotalItem: any, setAttendHolidays: any, setStaffList: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    isRefreshStampList: boolean, setIsRefreshStampList: React.Dispatch<React.SetStateAction<boolean>>,
    setIsRefreshStampSubTotal: React.Dispatch<React.SetStateAction<boolean>>,
    setIsRefreshHolidayUsingStatus: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
  const [stampItems, setStampItems] = useState([]);
  const [stampBusiness, setStampBusiness] = useState([]);
  const [stampTerm, setStampTerm] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteShiftId, setDeleteShiftId] = useState<string | null>(null);
  const { errorNotification } = useToastNotification();

  const fetchStampList = () => {
    const params = {
      periodFrom: moment(new Date(targetDateFrom)).format('YYYY-MM-DD'),
      periodTo: moment(new Date(targetDateTo)).format('YYYY-MM-DD'),
      attendFlag,
      staffCode,
      orgCode: selectOrgCode,
    };
    setIsLoading(true);
    getStamplist(params).then((data:any) => {
      const startDayNextYear = new Date(targetDateFrom.getFullYear() + 1, targetDateFrom.getMonth(), targetDateFrom.getDate());
      if (targetDateTo.getTime() >= startDayNextYear.getTime()) {
        setToastMessage('対象期間の範囲は１年以内で入力してください');
        setToastModalOpen(true);
      } else {
        setStampItems(data.attendStampDataList);
        setStampBusiness(data.business);
        setStampTerm(data.term);
      }

      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);

      setStampBusiness([]);
      setStampTerm([]);
      setSubTotalTime({});
      setSubTotalDay({});
      setHolidaySubtotalItem([]);
      setAttendHolidays([]);
      setStampItems([]);
      if (error.response && error.response.status === 400) {
        console.log('400');
      } else if (error.response && error.response.data && error.response.data.errors) {
        let msgError = '';
        error.response.data.errors.map((item: { defaultMessage: string; }) => {
          msgError += `${item.defaultMessage} </br>`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }

  useEffect(() => {
    fetchStampList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendFlag, selectOrgCode, setAttendHolidays, setHolidaySubtotalItem, setStaffList, setSubTotalDay, setSubTotalTime, setToastMessage, setToastModalOpen, staffCode, targetDateFrom, targetDateTo]);

  useEffect(() => {
    if(!isRefreshStampList) {
      return;
    }
    fetchStampList();
    setIsRefreshStampList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshStampList]);

  const registerPublicHoliday = async (targetDate: Date, isLegal: boolean) => {
    let user = sessionStorage.getItem("loginUser.staffName")!;
    try {
      setIsLoading(true);
      await registerHoliday(
        selectOrgCode.toString(),
        staffCode.toString(),
        moment(targetDate).format("YYYY/MM/DD"),
        isLegal,
        user,
        user
      );
      setIsRefreshStampList(true);
      setIsRefreshStampSubTotal(true);
      setIsRefreshHolidayUsingStatus(true);
    } catch (error) {
      setIsRefreshStampList(false);
      setIsRefreshStampSubTotal(false);
      setIsRefreshHolidayUsingStatus(false);
      setIsLoading(false);
      if (error && error.response && error.response.data) {
        errorNotification(error.response.data.message);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  }

  const deletePublicHoliday = async () => {
    setIsLoading(true);
    if (!deleteShiftId) {
      setIsLoading(false);
      errorNotification('該当する公休がありません');
      return;
    }

    let updateUser = sessionStorage.getItem("loginUser.staffName")!;
    try {
      await deleteShiftV1(deleteShiftId, updateUser);
      setIsRefreshStampList(true);
      setIsRefreshStampSubTotal(true);
      setIsRefreshHolidayUsingStatus(true);
    } catch (error) {
      setIsRefreshStampList(false);
      setIsRefreshStampSubTotal(false);
      setIsRefreshHolidayUsingStatus(false);
      setIsLoading(false);
      if (error && error.response && error.response.data) {
        errorNotification(error.response.data.message);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };

  const getCustomDropdownMenuButtons = (item: any, distinctionHoliday: boolean, usabilityGeneralItem2: number) : DropdownMenuType[] => {
    const buttons: DropdownMenuType[]  = [];

    if ( usabilityGeneralItem2 !== 1 ) {
      return buttons;
    }

    if(item.holidayType === null && !distinctionHoliday) {
      buttons.push({ isRed: false, isSelectable: true, label: '公休登録', stamptype: 0, onClick: async () => await registerPublicHoliday(item.targetDate, true) });
    }
    if(item.holidayType === null && !!distinctionHoliday) {
      buttons.push({ isRed: false, isSelectable: true, label: '公休登録（法定）', stamptype: 0, onClick: async () => await registerPublicHoliday(item.targetDate, true) });
      buttons.push({ isRed: false, isSelectable: true, label: '公休登録（所定）', stamptype: 0, onClick: async () => await registerPublicHoliday(item.targetDate, false) });
    }
    if(item.holidayType === '0' && item.shiftId) {
      buttons.push({ isRed: true, isSelectable: true, label: '公休削除', stamptype: 0,
        onClick: async () => {
          await setDeleteShiftId(item.shiftId);
          setOpenDeleteModal(true);
        }
      });
    }
    return buttons;
  }

  return {
    stampItems,
    stampBusiness,
    stampTerm,
    openDeleteModal,
    setOpenDeleteModal,
    deletePublicHoliday,
    getCustomDropdownMenuButtons
  };
};

export const useGetHolidayUsingStatus = (
    targetDateFrom: Date, targetDateTo: Date, staffCode: String,
    isRefreshHolidayUsingStatus: boolean, setIsRefreshHolidayUsingStatus: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
  const [paidHolidayGrantData, setPaidHolidayGrantData] = useState(Object());
  const [specialHolidayGrantList, setSpecialHolidayGrantList] = useState([]);
  const [substituteHolidayUsingStatusList, setSubstituteHolidayUsingStatusList] = useState([]);
  const [transferHolidayUsingStatusList, setTransferHolidayUsingStatusList] = useState([]);

  const fetchtHolidayUsingStatus = () => {
    const params = {
      targetDateFrom: moment(new Date(targetDateFrom)).format('YYYY-MM-DD'),
      targetDateTo: moment(new Date(targetDateTo)).format('YYYY-MM-DD'),
      staffCode,
    };
    getHolidayUsingStatus(params).then((data:any) => {
      if (data.paidHolidayGrantData != null) {
        setPaidHolidayGrantData(data.paidHolidayGrantData);
      }
      if (data.specialHolidayGrantList != null) {
        setSpecialHolidayGrantList(data.specialHolidayGrantList);
      }
      if (data.substituteHolidayUsingStatusList != null) {
        setSubstituteHolidayUsingStatusList(data.substituteHolidayUsingStatusList);
      }
      if (data.transferHolidayUsingStatusList != null) {
        setTransferHolidayUsingStatusList(data.transferHolidayUsingStatusList);
      }
    });
  }

  useEffect(() => {
    fetchtHolidayUsingStatus();
  }, [staffCode, targetDateFrom, targetDateTo]);

  useEffect(() => {
    if(!isRefreshHolidayUsingStatus) {
      return;
    }
    fetchtHolidayUsingStatus();
    setIsRefreshHolidayUsingStatus(false);
  }, [isRefreshHolidayUsingStatus]);

  return {
    paidHolidayGrantData,
    specialHolidayGrantList,
    substituteHolidayUsingStatusList,
    transferHolidayUsingStatusList,
  };
};

export const useGetStampSubTotal = (
    targetDateFrom: Date, targetDateTo: Date,
    staffCode: String, orgCode: String, attendFlag: String,
    isRefreshStampSubTotal: boolean, setIsRefreshStampSubTotal: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
  const [attendHolidays, setAttendHolidays] = useState([]);
  const [subTotalTime, setSubTotalTime] = useState(Object());
  const [subTotalDay, setSubTotalDay] = useState(Object());
  const [business, setBusiness] = useState([]);
  const [term, setTerm] = useState([]);
  const [holidaySubtotalItem, setHolidaySubtotalItem] = useState([]);

  const fetchStampSubTotal = () => {
      const params = {
        orgCode,
        periodFrom: moment(new Date(targetDateFrom)).format('YYYY-MM-DD'),
        periodTo: moment(new Date(targetDateTo)).format('YYYY-MM-DD'),
        staffCode,
        attendFlag,
      };

      getStampSubTotal(params).then((response:any) => {
        setBusiness(response.business);
        setTerm(response.term);
        setAttendHolidays(response.attendHolidays);
        setSubTotalTime(response.attendStampSubTotalTime[0]);
        setSubTotalDay(response.attendStampSubTotalDay[0]);
        setHolidaySubtotalItem(response.holidaySubtotalItem);
      }).catch((error) => {
      });
  }

  useEffect(() => {
    fetchStampSubTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendFlag, orgCode, staffCode, targetDateFrom, targetDateTo]);

  useEffect(() => {
    if(!isRefreshStampSubTotal) {
      return;
    }
    fetchStampSubTotal();
    setIsRefreshStampSubTotal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshStampSubTotal]);

  return {
    term,
    business,
    attendHolidays,
    subTotalTime,
    subTotalDay,
    holidaySubtotalItem,
    setSubTotalTime,
    setSubTotalDay,
    setHolidaySubtotalItem,
    setAttendHolidays,
  };
};

export const eventScroll = (e: { target: any; }) => {
  const { target } = e;
  const contentRight = document.getElementById('bottomRightAreaTime');
  const headRight = document.getElementById('topRightAreaTime');
  const scrollBottom = document.getElementById('scrollBottom');
  if (contentRight && headRight && scrollBottom) {
    contentRight.scrollLeft = target.scrollLeft;
    headRight.scrollLeft = target.scrollLeft;
    scrollBottom.scrollLeft = target.scrollLeft;
  }
};

export const useGetAttendSetting = () => {
  const [displayActualStampingFlag, setDisplayActualStampingFlag] = useState<number>(0);
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  useEffect(() => {
    getTimeSetting(companyCode).then((response:any) => {
      setDisplayActualStampingFlag(response.displayActualStamping);
    });
  }, [])
  return { displayActualStampingFlag }
}

export default {
  useGetEmployment,
  useGetStampSubTotal,
  useGetHolidayUsingStatus,
  useGetStaffs,
  useGetStampList,
  eventScroll,
  useGetAttendSetting
};
