/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */
/** @jsx jsx */
import React, {
  useMemo, useState, useCallback
} from 'react';
import { css, jsx } from '@emotion/core';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import PageTitle from 'components/atoms/PageTitle';
import DataTable from 'components/organismos/DataTable/DataTable';
import Icon from 'components/atoms/Icon';
import { iconColor } from 'components/styles';
import IconLabelButton from 'components/molecules/IconLabelButton';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import ExecutiveDomain from 'domain/master/labor/executive';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { deleteById, downloadCSV } from 'api/executive';
import ToastModal from 'components/organismos/ToastModal';
import useExecutiveList from 'components/organismos/Executive/ExecutiveIndexPage/hooks';
import useToastNotification from 'hooks/useToastNotification';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';


const ExecutiveIndexLayoutPage: React.FC = () => {
  const { executiveList, setExecutiveList } = useExecutiveList();
  const [valueExecutiveId, setValue] = useState('');
  const [isHover, setHover] = useState('');

  const [isHover2, setHover2] = useState('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((item : ExecutiveDomain) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setValue(item.executiveId);
    setConfirmModalOpen(true);
  }, []);

  const downloadFileCSV = useCallback(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    downloadCSV(companyCode);
  }, []);

  const deleteExecutiveById = useCallback(() => {
    if (executiveList) {
      deleteById(valueExecutiveId).then((response: any) => {
        setExecutiveList(executiveList.filter((executive) => executive.executiveId !== valueExecutiveId));
        setConfirmModalOpen(false);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = '';
          error.response.data.errors.map((item: { defaultMessage: string; }) => {
            msgError += `${item.defaultMessage} </br>`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueExecutiveId, executiveList]);

  const columns: Array<Column<ExecutiveDomain>> = useMemo(() => [
    {
      Header: '役職コード',
      accessor: 'executiveCode',
      sortType: 'basic'
    },
    {
      Header: '役職名',
      accessor: 'executiveName',
    },
    {
      Header: () => null,
      accessor: 'action',
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: ExecutiveDomain },
        data: Array<ExecutiveDomain> }) => (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/executive/edit/${cell.row.original.companyCode}/${cell.row.original.executiveId}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseOver={() => { setHover((cell.row.original.executiveId)); }}
                onMouseOut={() => { setHover(''); }}
              >
                <Icon type="edit" color={(isHover && isHover === (cell.row.original.executiveId)) ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            {
              roleScreen && roleScreen.editable === 1 && (
                <span
                  style={{ marginLeft: '20px', marginRight: '10px' }}
                  role="presentation"
                  onMouseOver={() => { setHover2((cell.row.original.executiveId)); }}
                  onFocus={() => {}}
                  onMouseOut={() => { setHover2(''); }}
                  onClick={() => { openConfirmModal(cell.row.original); }}
                  onBlur={() => {}}
                >
                  <Icon type="delete" color={(isHover2 && isHover2 === (cell.row.original.executiveId)) ? iconColor.grayhover : iconColor.gray} />
                </span>
              )
            }
          </div>
      ),
    },
  ], [isHover, isHover2, openConfirmModal, roleScreen]);

  const sortBy = React.useMemo(
    () => [
      {
        id: 'executiveCode',
        desc: false,
      },
    ],
    [],
  );
  return (
    <React.Fragment>
      <div>
        <PageTitle
          title=""
          rightContents={
            roleScreen && roleScreen.editable === 1 && (
              <Link to="/executive/add">
                <PrimaryButton
                  ghost={false}
                  text="新規役職マスタ作成"
                />
              </Link>
          )}
        >
          <FlexBox>
            {
              roleScreen && roleScreen.downloadFlag === 1 && (
              <FlexBoxItem>
                <IconLabelButton
                  onClick={downloadFileCSV}
                  iconType="download"
                  text="CSVダウンロード"
                />
              </FlexBoxItem>
              )
            }
            {
              roleScreen && roleScreen.importFlag === 1 && (
              <FlexBoxItem>
                <Link to="/executive/import">
                  <PrimaryButton
                    ghost={false}
                    text="インポート"
                  />
                </Link>
              </FlexBoxItem>
              )
            }
          </FlexBox>
        </PageTitle>

        <div
          css={css`
            table th:nth-child(1) {
              width: 100px;
            }
            table th:nth-child(2) {
              width: 200px;
            }
            table th:nth-child(3) {
              width: 80px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTable
              columns={columns}
              data={executiveList}
              isGlobalFilter={true}
              sortBy={sortBy}
            />
          </div>
        </div>

        {/* confirm modal */}
        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          title="確認メッセージ"
          content="削除します。よろしいですか？"
          submitText="削除"
          onSubmit={deleteExecutiveById}
          items={[]}
        />

        {/* ToastModal */}
        <ToastModal
          open={toastModalOpen}
          closeHandler={() => setToastModalOpen(false)}
          title={toastMessage}
        />
      </div>
    </React.Fragment>
  );
};

export default ExecutiveIndexLayoutPage;
