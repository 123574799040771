import { getList } from 'api/organizationListTable';
import OrganizationDomain from 'domain/organization';
import OrganizationDomainListTable from 'domain/organizationListTable';
import useToastNotification from 'hooks/useToastNotification';
import { useCallback, useEffect, useState } from 'react';
import { getCompanyBankList } from 'api/companyBank';
import { OrgStatusType, Values } from 'utility/constants';

export const useOrganizationList = () => {
  const [organizationList, setOrganizationList] = useState<Array<OrganizationDomainListTable>>([]);
  const constNoManager = sessionStorage.getItem('organizations.noManager') === 'true';
  const constUnsetTargetDate = sessionStorage.getItem('organizations.unsetTargetDate') || new Date();
  const constOrgStatus: OrgStatusType = (sessionStorage.getItem('organizations.orgStatus') || Values.OrgStatus.Open.value) as OrgStatusType;
  const [noManager, setNoManager] = useState(constNoManager);
  const [orgStatus, setOrgStatus] = useState<OrgStatusType>(constOrgStatus);
  const [unsetTargetDate, setUnsetTargetDate] = useState<Date>(new Date(constUnsetTargetDate));
  const [blocking, setBlocking] = useState(false);
  const [categoryArr, setCategoryArr] = useState<Array<any>>(JSON.parse(String(sessionStorage.getItem(`sessionOrganizationFileterTree${window.location.pathname}2.Toggle`))));
  const { errorNotification } = useToastNotification();
  const [arrCompanyBank, setArrCompanyBank] = useState<any>();

  // データの取得
  const fetchData = useCallback(async () => {
    setBlocking(true);
    const response = await getList(
      noManager,
      Number(orgStatus),
      new Date(),
      categoryArr,
      noManager ? unsetTargetDate : undefined,
    );
    setOrganizationList(response.map((result) => new OrganizationDomainListTable(result)));
    setBlocking(false);
  }, [categoryArr, noManager, orgStatus, unsetTargetDate]);

  useEffect(() => {
    fetchData();
    sessionStorage.setItem('organizations.noManager', String(noManager));
    sessionStorage.setItem('organizations.unsetTargetDate', unsetTargetDate.toString());
    sessionStorage.setItem('organizations.orgStatus', orgStatus);
  }, [fetchData, noManager, orgStatus, unsetTargetDate]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const fetchCompanyBank = useCallback(async () => {
    setBlocking(true);
    await getCompanyBankList('', true).then((response: any) => {
      setArrCompanyBank(response);
      setBlocking(false);
    }).catch((error: any) => {
      setArrCompanyBank([]);
      setBlocking(false);
    })
  }, [])

  useEffect(() => {
    fetchCompanyBank()
  }, [fetchCompanyBank])

  return {
    organizationList,
    fetchData,
    noManager,
    toggleNoManager,
    orgStatus,
    setOrgStatus,
    unsetTargetDate,
    setUnsetTargetDate,
    blocking,
    categoryArr,
    setCategoryArr,
    arrCompanyBank
  };
};

export default {};
