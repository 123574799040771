import {
  useState, useCallback, useEffect,
} from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import OrganizationDomain from 'domain/organization';
import { postOrganization } from 'api/organization';
import useToastNotification from 'hooks/useToastNotification';

type OrganizationDomainKey = keyof Pick<OrganizationDomain, 'orgCode'|'orgName'>;

export const useOrganizationAddForm = (
  initialOrganizationDomain: OrganizationDomain = OrganizationDomain.generateInitial(),
  isEditMode: boolean = false,
  historyList: Array<OrganizationDomain> = [],
  setHistoryList: (arg: Array<OrganizationDomain>) => void,
) => {
  const history = useHistory();

  // 登録内容確認モーダルの開閉状態
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  // 詳細項目入力を表示するかどうか
  const [detailMode, setDetailMode] = useState(true);
  const { successNotification, errorNotification } = useToastNotification();
  // const [historyList, setHistoryList] = useState<Array<OrganizationDomain>>([]);

  const onSubmit = async (values: OrganizationDomain) => {
    if (formik.values.orgCode === '') {
      errorNotification('組織コードを入力してください。');
      return;
    }
    if (formik.values.orgName === '') {
      errorNotification('組織名を入力してください。');
      return;
    }
    if (!confirmModalOpen) {
      // 登録内容確認モーダルが表示されていなければ表示する
      setConfirmModalOpen(true);
      return;
    }
    if (formik.values.organizationCategoryMstDetilId === undefined) {
      formik.setFieldValue('organizationCategoryMstDetilId', []);
    }
    try {
      // const response = await postOrganization(values.getRawData());
      const response = await postOrganization(values.getBasicPostData(), isEditMode);
      console.log(`historyList.length = ${historyList.length}`);

      setHistoryList(response.map((org) => new OrganizationDomain(org)));

      setConfirmModalOpen(false);

      successNotification(isEditMode ? '更新しました' : '組織を登録しました');

      // 新規登録の場合はマネージャー登録ページへ遷移
      if (!isEditMode && response[0]) {
        history.push(`/organizations/${response[0].orgCode}/managers`);
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };
  // console.log(`initialOrganizationDomain = ${initialOrganizationDomain?.orgCode}`);
  const formik = useFormik({
    initialValues: initialOrganizationDomain,
    onSubmit,
  });

  useEffect(() => {
    formik.setValues(initialOrganizationDomain);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOrganizationDomain]);
  // 登録内容確認モーダル用データ
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);
  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
  };
};

export default {
  useOrganizationAddForm,
};
