/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { grayScale, textFontSize } from 'components/styles';

const style = css({
  border: `solid 1px ${grayScale.gray10}`,
  boxSizing: 'border-box',
  borderRadius: '4px',
  width: '100%',
  fontSize: textFontSize.re,
  padding: '8px',
  marginTop: '4px',
});

const PasswordInput: React.FC<{
  name: string;
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}> = ({
  name, value, onChange,onKeyPress
}) => (
  <input
    css={style}
    id={name}
    name={name}
    type="password"
    value={value}
    onChange={onChange}
    onKeyPress={onKeyPress}

  />
);

export default PasswordInput;
