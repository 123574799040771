import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesSuppliersMSTForm from 'components/organismos/master/general/salesSuppliersMST/salesSuppliersMSTForm';

const SalesExpensesMSTEditPage: React.FC = () => (
  <SidebarTemplate pageTitle="取引先編集">
    <SalesSuppliersMSTForm />
  </SidebarTemplate>
);

export default SalesExpensesMSTEditPage;
