
import { useState, useEffect } from 'react';
import AdvertisingDomain from 'domain/advertising';
import { getListAdvertising } from 'api/advertising';

export const useAdvertisingPlanPage = () => {
  // eslint-disable-next-line max-len
  const [mediaMasterList, setMediaMasterList] = useState < Array<AdvertisingDomain>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getListAdvertising().then((response: Array<any>) => {
      setMediaMasterList(response.map(
        (result) => new AdvertisingDomain(result),
      ));
      setIsLoading(false);
    });
  }, [setMediaMasterList]);
  return { mediaMasterList, setMediaMasterList, isLoading };
};

export default useAdvertisingPlanPage;
