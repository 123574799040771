import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { createOrUpdateVacation, getAttendVacation } from 'api/vacation';
import { useHistory } from 'react-router-dom';
import VacationDomain, { HolidayType } from 'domain/vacation';
import useToastNotification from 'hooks/useToastNotification';

type VacationDomainKey = keyof Pick<VacationDomain, 'holidayCode'|'holidayName'>;

export const useVacationAddForm = (vacationId?: string) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalResendOpen, setConfirmModalResendOpen] = useState(false);
  const [confirmModalContent] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [vacation, setVacation] = useState(VacationDomain.generateInitial());
  const { successNotification, errorNotification } = useToastNotification();

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';

  const history = useHistory();

  const onSubmit = async (val: any) => {
    if (val.holidayCode === null) {
      errorNotification('休暇コードを入力してください。');
      return;
    }
    if (val.holidayName === null) {
      errorNotification('休暇名を入力してください。');
      return;
    }
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    let submitVacation : any;
    if (vacationId) {
      submitVacation = val.getRawDataWithoutNullData();
      submitVacation.updateUser = staffName;
    } else {
      if (Number(val.holidayType) === HolidayType.TEXIU) {
        submitVacation = val.getRawDataCreationTexiu();
      } else {
        submitVacation = val.getRawDataCreation();
      }

      submitVacation.createUser = staffName;
      submitVacation.updateUser = staffName;
    }
    submitVacation.noticeStaffs = submitVacation.noticeStaffs.toString();

    if (submitVacation.salaryCalculation === 1) {
      submitVacation.deemedWorkingHours = 0;
    } else {
      submitVacation.unitPricePeriod = 0;
      submitVacation.unitPriceTransportation = false;
      submitVacation.unitPriceRounding = 4;
    }

    if (Number(val.holidayType) === HolidayType.PAID_HALF_DAY) {
      submitVacation.halfHoliday = true;
    }

    if (Number(val.holidayType) === HolidayType.PAID_1_DAY || Number(val.holidayType) === HolidayType.PAID_HALF_DAY) {
      submitVacation.paidCalculationStandard = true;
    }

    if (Number(val.holidayType) === HolidayType.PAID_1_DAY || Number(val.holidayType) === HolidayType.PAID_HALF_DAY) {
      submitVacation.paidCalculationStandard = true;
    }

    if ([HolidayType.TEXIU].indexOf(Number(val.holidayType)) !== -1) {
      submitVacation.validityPeriodStartMonth = submitVacation.validityPeriodStartMonth;
      submitVacation.validityPeriodStartDay = submitVacation.validityPeriodStartDay;
      submitVacation.validityPeriodEndMonth = submitVacation.validityPeriodEndMonth;
      submitVacation.validityPeriodEndDay = submitVacation.validityPeriodEndDay;
    }
    createOrUpdateVacation(companyCode, false, submitVacation)
      .then((response: any) => {
        successNotification('登録しました。');
        history.push('/agreementMaster');
      })
      .catch((error: any) => {
        if (error.response.status === 406) {
          const errorString : string = error.response.data.errors[0].defaultMessage;
          setVacation(submitVacation);
          setConfirmModalOpen(false);
          errorNotification(errorString);
        } else {
          setConfirmModalOpen(false);
          if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
            const listErr = error.response.data.errors;
            let stringErr = '';
            listErr.map((element: any, index: any) => {
              stringErr += `${element.defaultMessage}<br />`;
              return stringErr;
            });
            errorNotification(stringErr);
          } else {
            errorNotification('Something wrong. please try again');
          }
        }
      });
  };

  const formik = useFormik({
    initialValues: vacation || VacationDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    if (vacationId !== undefined) {
      getAttendVacation(companyCode, vacationId).then((response: any) => {
        setVacation(new VacationDomain(response));
        formik.setValues(new VacationDomain(response));
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacationId]);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
    setConfirmModalOpen(false);
    setConfirmModalResendOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);


  const closeConfirmResendModal = useCallback(() => {
    setConfirmModalResendOpen(false);
  }, []);

  const submitConfirmResendModal = useCallback(() => {
    createOrUpdateVacation(companyCode, true, vacation)
      .then((response: any) => {
        history.push('/agreementMaster');
      })
      .catch((error2: any) => {
        if (error2.response.data.errors.length !== 0) {
          errorNotification(error2.response.data.errors[error2.response.data.errors.length - 1]
            .defaultMessage);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    setConfirmModalResendOpen(false);
  }, [companyCode, errorNotification, history, vacation]);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    confirmModalResendOpen,
    closeConfirmResendModal,
    submitConfirmResendModal,
    confirmModalContent,
    history,
  };
};


export default useVacationAddForm;
