import React from 'react';

import TimeRecorderGpsStampBtn from 'components/organismos/AttendTimeRecorder/TimeRecorderGpsStampBtn/TimeRecorderGpsStampBtn';
import TimeRecorderTimeHeader from 'components/organismos/AttendTimeRecorder/TimeRecorderTimeHeader/TimeRecorderTimeHeader';
import { isMobileOnly } from 'react-device-detect';
import BottomBar from 'components/organismos/BottomBar';

const TimeRecorderGpsForm: React.FC = () => (
  <>
    <TimeRecorderTimeHeader />
    <TimeRecorderGpsStampBtn />
    {
      isMobileOnly && <BottomBar />
    }
  </>
);

export default TimeRecorderGpsForm;
