import { Terms } from 'domain/master/attend/terms';
import ApiClient from './ApiClient';

export const getList = async (): Promise<Array<Terms>> => {
  // TODO 仮リクエスト
  const params = {
  };
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/term/list//${companyCode}`, params);
  return response.data;
};

export const post = async (companyCode: string, terms: any) => {
  // TODO パラメータ確認
  const query = {
  };
  const response = await ApiClient.post(`/v1/term/${companyCode}`, query, terms);
  return response.data;
};


export default getList;
