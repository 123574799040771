/**
 * API 広告媒体マスタ
 */
export interface AdverstingMedia{
    id: {
        companyCode: string,
        advertisingMediaMstCode: string,
      },
    advertisingMediaMstName: string;
    dispOrder:number;
    createUser: string;
    updateUser: string;

}

export default class AdverstingMediaDomain {
  constructor(private rawData: AdverstingMedia) {
    //
  }

  static generateInitial(): AdverstingMediaDomain {
    return new AdverstingMediaDomain({
      id: {
        companyCode: '',
        advertisingMediaMstCode: '',
      },
      advertisingMediaMstName: '',
      dispOrder: 0,
      createUser: '',
      updateUser: '',

    });
  }

  getRawData(): AdverstingMedia {
    return this.rawData;
  }

  getRawDataForCreate(): any {
    return {
      id: {
        companyCode: sessionStorage.getItem('loginUser.companyCode') || '',
        advertisingMediaMstCode: this.rawData.id.advertisingMediaMstCode,
      },
      advertisingMediaMstName: this.rawData.advertisingMediaMstName,
      dispOrder: this.rawData.dispOrder,
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
    };
  }

  getRawDataForUpdate(): any {
    return ({
      id: {
        companyCode: sessionStorage.getItem('loginUser.companyCode') || '',
        advertisingMediaMstCode: this.rawData.id.advertisingMediaMstCode,
      },
      advertisingMediaMstName: this.rawData.advertisingMediaMstName,
      dispOrder: this.rawData.dispOrder,
      createUser: this.rawData.createUser,
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
    });
  }

  get advertisingMediaMstCode(): string {
    return this.rawData.id.advertisingMediaMstCode;
  }

  set advertisingMediaMstCode(advertisingMediaMstCode: string) {
    this.rawData.id.advertisingMediaMstCode = advertisingMediaMstCode;
  }

  get advertisingMediaMstName(): string {
    return this.rawData.advertisingMediaMstName;
  }

  set advertisingMediaMstName(advertisingMediaMstName: string) {
    this.rawData.advertisingMediaMstName = advertisingMediaMstName;
  }

  get dispOrder(): number {
    return this.rawData.dispOrder;
  }

  set dispOrder(dispOrder: number) {
    this.rawData.dispOrder = dispOrder;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }
}
