/** @jsx jsx */
import { Column } from 'react-table';
import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import DataTable from 'components/organismos/DataTable/DataTable';
import { css, jsx } from '@emotion/core';
import FormContents from 'components/atoms/Form/FormContents';
import BodyText from 'components/atoms/BodyText';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import DatePickerForm from 'components/molecules/DatePickerForm';
import { isMobileOnly, isTablet } from 'react-device-detect';
import FormLabel from 'components/atoms/Form/FormLabel';
import productColor, { utilityColor, grayScale } from 'components/styles';
import moment from 'moment';
import { DashboardShiftDomain } from 'domain/master/general/dashboardShift';
import { DashboardReportStatusDomain } from 'domain/master/general/dashboardReportStatus';
import CardContents from 'components/atoms/Form/CardContents';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import BottomBar from 'components/organismos/BottomBar';
import BlockUI from 'components/molecules/BlockUi';
import Checkbox from 'components/molecules/Checkbox';
import MonthlyChart from 'components/organismos/Charts/MonthlyChart';
import YearlyChart from 'components/organismos/Charts/YearlyChart';
import PulseLoader from 'react-spinners/PulseLoader';
import { useDashboard } from './hooks';
import useDashboardGraph from './useDashboardGraph';
import DashboardAnalysticList from './DashboardAnalysticList';
import DashboardAnalysticDetail from './DashboardAnalysticDetail';
import DashboardInformationDetail from './DashboardInformationDetail';

const DashboardForm = () => {
  const { option, detailOption, informationDetail } = useParams();

  const {
    shiftList,
    orgCode,
    targetDate,
    salesAmountOnTheMonth,
    salesAmountOnTheDay,
    numberOfCustomersUpToBreakEvenPoint,
    numberOfCustomersToReachBudgetMonth,
    monthlyBudgetProgressRate,
    monthlyBudgetProgressRank,
    FRate,
    LRate,
    voidRate,
    setorgCode,
    setTargetDate,
    categoryAry,
    setCategoryAry,
    dateFrom,
    dateTo,
    DashboardRequestTable,
    isLoading,
    reportApproval,
    StartDateUseReportApproval,
    isBodyLoading,
    getSPDashboard,
    dispType,
    period,
    setPeriod,
    targetDayFrom,
    setTargetDayFrom,
    targetDayTo,
    setTargetDayTo,
  } = useDashboard();

  const {
    isLoading: graphLoading,
    graphMonthly,
    graphYearly,
  } = useDashboardGraph({
    targetDate,
    orgCode,
    categoryAry,
  });

  const columns: Array<Column<DashboardShiftDomain>> = useMemo(
    () => [
      {
        Header: 'スタッフ名',
        accessor: 'staffName',
        sortType: 'basic',
      },
      {
        Header: '時間',
        accessor: 'shiftStartTimeToEndTimeStr',
      },
      {
        Header: '勤務状況',
        id: 'staffStatusStr',
        disableSortBy: true,
        Cell: (cell: { row: { original: DashboardShiftDomain } }) => {
          let bgColor = grayScale.gray20;
          let textColor = utilityColor.black;
          if (cell.row.original.staffStatus === 1) {
            bgColor = utilityColor.error;
            textColor = utilityColor.white;
          } else if (cell.row.original.staffStatus === 3) {
            bgColor = productColor.primary;
            textColor = utilityColor.white;
          }
          return (
            <div
              style={{
                backgroundColor: bgColor,
                color: textColor,
                width: '100px',
                minHeight: '20px',
                borderRadius: '4px',
                textAlign: 'center',
                paddingTop: '6px',
                paddingBottom: '6px',
                margin: '5px',
              }}
            >
              {cell.row.original.staffStatusStr}
            </div>
          );
        },
      },
      {
        Header: '備考',
        accessor: 'note',
      },
    ],
    [],
  );

  const request_columns: Array<Column<DashboardReportStatusDomain>> = [
    {
      Header: 'レポート状況	',
      accessor: 'status',
      id: 'status',
      Cell: (cell: { row: { original: any } }) => (
        <div>
          <span style={{ marginRight: 5, whiteSpace: 'pre-wrap' }}>
            <Link
              to={{
                pathname: `/salesDailyReport/${
                  cell.row.original.orgCode
                }/${moment(cell.row.original.targetDay, 'YYYY-MM-DD').format(
                  'YYYY-MM-DD',
                )}`,
              }}
            >
              {cell.row.original.status === 0
                ? '申請中'
                : cell.row.original.status === 1
                ? '差し戻し'
                : '未申請'}
            </Link>
          </span>
        </div>
      ),
    },
    {
      Header: '日付',
      accessor: 'targetDay',
      id: 'targetDay',
      Cell: (cell: { row: { original: any } }) => (
        <div>
          <span style={{ whiteSpace: 'pre-wrap' }}>
            {moment(cell.row.original.targetDay, 'YYYY-MM-DD').format(
              'YYYY年MM月DD日（ddd）',
            )}
          </span>
        </div>
      ),
    },
    {
      Header: '店舗コード	',
      accessor: 'orgCode',
      sortType: 'basic',
    },
    {
      Header: '店舗名',
      accessor: 'orgName',
    },
    {
      Header: '指摘概要',
      accessor: 'overviews',
      id: 'overviews',
      Cell: (cell: { row: { id: string; original: any } }) => (
        <table>
          <tbody>
            {/* <tr>
              <td>{cell.row.original.overview}</td>
            </tr> */}
            {cell.row.original.overviews !== null ? (
              cell.row.original.overviews.map((item: any, index: number) => (
                <tr key={`overviews-${cell.row.id}-${index}`}>
                  <td
                    id={`overviews-${cell.row.id}-${index}`}
                    style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}
                  >
                    {item.overview}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td />
              </tr>
            )}
          </tbody>
        </table>
      ),
    },
    {
      Header: '状態',
      accessor: 'statusName',
      id: 'statusName',
      Cell: (cell: { row: { id: string; original: any } }) => (
        <table>
          <tbody>
            {/* <tr>
              <td>{cell.row.original.statusName}</td>
            </tr> */}

            {cell.row.original.overviews !== null ? (
              cell.row.original.overviews.map((item: any, index: number) => {
                const overviewElement = document.getElementById(
                  `overviews-${cell.row.id}-${index}`,
                );
                return (
                  <tr key={`statusName-${cell.row.id}-${index}`}>
                    <td style={{ height: overviewElement?.clientHeight }}>
                      {item.statusName}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td />
              </tr>
            )}
          </tbody>
        </table>
      ),
    },
  ];

  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const datefrom = new Date(dateFrom);
  const dateto = new Date(dateTo);
  const functionType = 4;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  function handleOnCheckPeriod(e: any) {
    setPeriod(!period);
  }

  return (
    <BlockUI blocking={isBodyLoading}>
      <React.Fragment>
        <div>
          <FormContents isFullWidthForm={!!isMobileOnly}>
            {isMobileOnly ? (
              <div>
                {option !== undefined &&
                option !== '' &&
                detailOption === undefined ? (
                  <React.Fragment>
                    <FlexBox customStyle={css({ marginBottom: '15px' })}>
                      <FormLabel
                        label={sessionStorage.getItem('categoryDispName') || ''}
                        customStyle={css({
                          color: '#0D4796',
                          marginRight: '15px',
                        })}
                      />
                    </FlexBox>
                  </React.Fragment>
                ) : (
                  ''
                )}
                {detailOption !== undefined &&
                detailOption !== '' &&
                informationDetail === undefined ? (
                  <React.Fragment>
                    <FlexBox customStyle={css({ marginBottom: '15px' })}>
                      <FormLabel
                        label={sessionStorage.getItem('detailName') || ''}
                        customStyle={css({
                          color: '#0D4796',
                          marginRight: '15px',
                        })}
                      />
                    </FlexBox>
                  </React.Fragment>
                ) : (
                  ''
                )}
                {informationDetail !== undefined && informationDetail !== '' ? (
                  <FlexBox customStyle={css({ marginBottom: '15px' })}>
                    <FormLabel
                      label={sessionStorage.getItem('orgName') || ''}
                      customStyle={css({
                        color: '#0D4796',
                        marginRight: '15px',
                      })}
                    />
                  </FlexBox>
                ) : (
                  ''
                )}
                <FlexBox>
                  <FlexBoxItem basis="82%">
                    {period ? (
                      <div>
                        <FlexBox>
                          <FlexBoxItem basis="25%">
                            <FormLabel label="開始日" />
                          </FlexBoxItem>
                          <FlexBoxItem basis="75%">
                            <DatePickerForm
                              dateFormat="yyyy年MM月dd日"
                              label=""
                              date={targetDayFrom}
                              changeDate={(date: Date) => {
                                if (date !== null) {
                                  setTargetDayFrom(date);
                                  setTargetDate(date);
                                }
                              }}
                              isFullWidth={true}
                              isShowFullWidthDatePicker={true}
                            />
                          </FlexBoxItem>
                        </FlexBox>
                        <FlexBox>
                          <FlexBoxItem basis="25%">
                            <FormLabel label="終了日" />
                          </FlexBoxItem>
                          <FlexBoxItem basis="75%">
                            <DatePickerForm
                              dateFormat="yyyy年MM月dd日"
                              label=""
                              date={targetDayTo}
                              changeDate={(date: Date) => {
                                if (date !== null) {
                                  setTargetDayTo(date);
                                }
                              }}
                              isFullWidth={true}
                              isShowFullWidthDatePicker={true}
                            />
                          </FlexBoxItem>
                        </FlexBox>
                      </div>
                    ) : (
                      <FlexBox>
                        <FlexBoxItem basis="25%">
                          <FormLabel label="表示日" />
                        </FlexBoxItem>
                        <FlexBoxItem basis="75%">
                          <DatePickerForm
                            dateFormat="yyyy年MM月dd日"
                            label=""
                            date={targetDate}
                            changeDate={(date: Date) => {
                              if (date !== null) {
                                setTargetDate(date);
                                setTargetDayFrom(date);
                                setTargetDayTo(date);
                              }
                            }}
                            isFullWidth={true}
                            isShowFullWidthDatePicker={true}
                          />
                        </FlexBoxItem>
                      </FlexBox>
                    )}
                  </FlexBoxItem>
                  <FlexBoxItem
                    basis="18%"
                    customStyle={css({
                      div: {
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      },
                    })}
                  >
                    <Checkbox
                      id="period"
                      name="period"
                      label="期間"
                      value={String(period)}
                      checked={period}
                      onChange={handleOnCheckPeriod}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </div>
            ) : (
              <React.Fragment>
                <FlexBox>
                  <FlexBoxItem basis={isMobileOnly ? '80px' : '100px'}>
                    <FormLabel label="表示日" />
                  </FlexBoxItem>
                  <div>
                    <DatePickerForm
                      dateFormat="yyyy年MM月dd日"
                      label=""
                      date={targetDate}
                      changeDate={(date: Date) => {
                        if (date !== null) {
                          setTargetDate(date);
                        }
                      }}
                      isFullWidth={true}
                    />
                  </div>
                </FlexBox>
                <OrganizationFilteredTree
                  functionType={functionType}
                  staffCode={staffCode}
                  targetdayForm={datefrom}
                  targetdayTo={dateto}
                  addAllItem={true}
                  initOrgValue={String(orgCode)}
                  orgLabel="店舗名"
                  orgCallback={(args: any) => {
                    setorgCode(String(args));
                  }}
                  orgCategoryCallback={(args: Array<string>) => {
                    setCategoryAry(args);
                  }}
                />
              </React.Fragment>
            )}
          </FormContents>

          {isMobileOnly ? (
            <div>
              <FormContents isWithoutTop={true}>
                <DashboardAnalysticDetail
                  dataGeneral={
                    getSPDashboard &&
                    getSPDashboard.spDashboardDailySalesTotalInfo !== undefined
                      ? getSPDashboard.spDashboardDailySalesTotalInfo
                      : null
                  }
                  chevronLeft={!!(option !== undefined && option !== '')}
                />
              </FormContents>

              <FormContents isWithoutTop={true}>
                {informationDetail !== undefined && informationDetail !== '' ? (
                  getSPDashboard &&
                  getSPDashboard.spDashboardDailySalesTotalInfo !==
                    undefined ? (
                    <DashboardInformationDetail
                      detailReport={getSPDashboard.spDashboardMonthlySalesInfo}
                      period={period}
                    />
                  ) : null
                ) : detailOption !== undefined && detailOption !== '' ? (
                  getSPDashboard &&
                  getSPDashboard.spDashboardDailySalesGroupByOrgInfoList !==
                    undefined &&
                  getSPDashboard.spDashboardDailySalesGroupByOrgInfoList !==
                    null ? (
                    getSPDashboard.spDashboardDailySalesGroupByOrgInfoList.map(
                      (report: any) => (
                        <React.Fragment>
                          <FlexBox
                            customStyle={css({
                              background: '#979797',
                              color: '#ffffff',
                              padding: '5px 15px',
                              fontSize: '14px',
                            })}
                          >
                            {`${report.orgCode} ${report.orgName}`}
                          </FlexBox>
                          <DashboardAnalysticDetail
                            dataGeneral={report}
                            chevronRight={true}
                            dispType={dispType}
                          />
                        </React.Fragment>
                      ),
                    )
                  ) : null
                ) : option !== undefined && option !== '' ? (
                  getSPDashboard &&
                  getSPDashboard.spDashboardDailySalesGroupByCateInfoList !==
                    undefined &&
                  getSPDashboard.spDashboardDailySalesGroupByCateInfoList !==
                    null ? (
                    getSPDashboard.spDashboardDailySalesGroupByCateInfoList.map(
                      (report: any) => (
                        <React.Fragment>
                          <FlexBox
                            customStyle={css({
                              background: '#979797',
                              color: '#ffffff',
                              padding: '5px 15px',
                              fontSize: '14px',
                            })}
                          >
                            {report.detailName}
                          </FlexBox>
                          <DashboardAnalysticDetail
                            dataGeneral={report}
                            chevronRight={true}
                            dispType={dispType}
                          />
                        </React.Fragment>
                      ),
                    )
                  ) : null
                ) : (
                  getSPDashboard &&
                  (getSPDashboard.dispType === 1 ? (
                    <DashboardAnalysticList dataList={getSPDashboard} />
                  ) : getSPDashboard.dispType === 2 ? (
                    getSPDashboard &&
                    getSPDashboard.spDashboardDailySalesGroupByOrgInfoList !==
                      undefined &&
                    getSPDashboard.spDashboardDailySalesGroupByOrgInfoList !==
                      null ? (
                      getSPDashboard.spDashboardDailySalesGroupByOrgInfoList.map(
                        (report: any) => (
                          <React.Fragment>
                            <FlexBox
                              customStyle={css({
                                background: '#979797',
                                color: '#ffffff',
                                padding: '5px 15px',
                                fontSize: '14px',
                              })}
                            >
                              {`${report.orgCode} ${report.orgName}`}
                            </FlexBox>
                            <DashboardAnalysticDetail
                              dataGeneral={report}
                              chevronRight={true}
                              dispType={dispType}
                            />
                          </React.Fragment>
                        ),
                      )
                    ) : null
                  ) : (
                    <DashboardInformationDetail
                      detailReport={getSPDashboard.spDashboardMonthlySalesInfo}
                      period={period}
                    />
                  ))
                )}
              </FormContents>
            </div>
          ) : (
            <React.Fragment>
              <div style={{ marginRight: '16px', marginTop: '16px' }}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns:
                      isMobileOnly || isTablet
                        ? 'repeat(2, 1fr)'
                        : 'repeat(4, 1fr)',
                    gap: '4px',
                  }}
                >
                  <CardContents
                    className="cardContentDashboard"
                    text="今月の売上"
                    tooltipText="月初から表示日までの売上の合計"
                    place={isMobileOnly || isTablet ? 'left' : 'top'}
                  >
                    <BodyText
                      size="lg"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '80%',
                      })}
                    >
                      ¥
                    </BodyText>
                    <BodyText
                      size="xl2"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '120%',
                      })}
                    >
                      {formatter.format(salesAmountOnTheMonth.cardValue)}
                    </BodyText>
                  </CardContents>
                  <CardContents
                    className="cardContentDashboard"
                    text="表示日の売上"
                    tooltipText="表示日（デフォルトは前日）の売上"
                    place={isMobileOnly || isTablet ? 'left' : 'top'}
                  >
                    <BodyText
                      size="lg"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '80%',
                      })}
                    >
                      ¥
                    </BodyText>
                    <BodyText
                      size="xl2"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '120%',
                      })}
                    >
                      {formatter.format(salesAmountOnTheDay.cardValue)}
                    </BodyText>
                  </CardContents>
                  <CardContents
                    className="cardContentDashboard"
                    tooltipText="黒字化するまでに必要な残り客数"
                    text="損益分岐点までの客数"
                    place={isMobileOnly || isTablet ? 'left' : 'top'}
                  >
                    <BodyText
                      size="xl2"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '120%',
                      })}
                    >
                      {formatter.format(
                        numberOfCustomersUpToBreakEvenPoint.cardValue,
                      )}
                    </BodyText>
                    <BodyText
                      size="lg"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '80%',
                      })}
                    >
                      人
                    </BodyText>
                  </CardContents>
                  <CardContents
                    className="cardContentDashboard"
                    text="予算達成までの客数"
                    tooltipText="予算達成までに必要な残り客数"
                    place="left"
                  >
                    <BodyText
                      size="xl2"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '120%',
                      })}
                    >
                      {formatter.format(
                        numberOfCustomersToReachBudgetMonth.cardValue,
                      )}
                    </BodyText>
                    <BodyText
                      size="lg"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '80%',
                      })}
                    >
                      人
                    </BodyText>
                  </CardContents>
                  <CardContents
                    className="cardContentDashboard"
                    text="今月の予算進捗率"
                    tooltipText="表示日までの消化予算 / 月予算"
                    place={isMobileOnly || isTablet ? 'left' : 'top'}
                  >
                    <BodyText
                      size="xl2"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '120%',
                      })}
                    >
                      {formatter.format(monthlyBudgetProgressRate.cardValue)}
                    </BodyText>
                    <BodyText
                      size="lg"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '80%',
                      })}
                    >
                      %
                    </BodyText>
                  </CardContents>
                  <CardContents
                    className="cardContentDashboard"
                    text="今月の予算達成ランキング"
                    tooltipText="全店舗中の予算達成率順位"
                    place={isMobileOnly || isTablet ? 'left' : 'top'}
                  >
                    <BodyText
                      size="xl2"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '120%',
                      })}
                    >
                      {monthlyBudgetProgressRank.rankStr}
                    </BodyText>
                    <BodyText
                      size="lg"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '80%',
                      })}
                    >
                      位 /
                    </BodyText>
                    <BodyText
                      size="xl2"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '120%',
                      })}
                    >
                      {monthlyBudgetProgressRank.totalNumberStr}
                    </BodyText>
                    <BodyText
                      size="lg"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '80%',
                      })}
                    >
                      店舗
                    </BodyText>
                  </CardContents>
                  <CardContents
                    className="cardContentDashboard"
                    text="今月の主なコスト"
                    tooltipText={`${
                      FRate.calculationItemOfFRate === 0
                        ? '［ F：仕入コスト］'
                        : '［ F：食材原価 ］'
                    }［ L：人件費］`}
                    place={isMobileOnly || isTablet ? 'left' : 'top'}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        marginRight: '4px',
                      }}
                    >
                      <BodyText size="lg" className="cardContentBodyText">
                        F
                      </BodyText>
                      <BodyText size="xl2" className="cardContentBodyValue">
                        {formatter.format(FRate.cardValue)}
                      </BodyText>
                      <BodyText size="lg" className="cardContentBodyPercent">
                        %
                      </BodyText>
                    </div>
                    <BodyText size="lg" className="cardContentBodyText">
                      L
                    </BodyText>
                    <BodyText size="xl2" className="cardContentBodyValue">
                      {formatter.format(LRate.cardValue)}
                    </BodyText>
                    <BodyText size="lg" className="cardContentBodyPercent">
                      %
                    </BodyText>
                  </CardContents>
                  <CardContents
                    className="cardContentDashboard"
                    text="今月のVOID率"
                    tooltipText="取り消し会計の処理数 / 全会計"
                    place="left"
                  >
                    <BodyText
                      size="xl2"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '120%',
                      })}
                    >
                      {formatter.format(voidRate.cardValue)}
                    </BodyText>
                    <BodyText
                      size="lg"
                      customStyle={css({
                        textOverflow: 'hidden',
                        fontSize: '80%',
                      })}
                    >
                      %
                    </BodyText>
                  </CardContents>
                </div>
              </div>
              <div style={{ margin: '8px 16px 0px 16px' }}>
                <FormContents isWithoutTop={true}>
                  {graphLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        padding: '24px 0px',
                      }}
                    >
                      <PulseLoader color="#3145D1" loading={graphLoading} />
                    </div>
                  ) : (
                    <React.Fragment>
                      <div style={{ width: '100%', padding: '8px 0px' }}>
                        <MonthlyChart
                          targetDate={targetDate}
                          yaxisScaleCurrency={
                            graphMonthly?.graph.yaxisScaleCurrency
                          }
                          labels={
                            graphMonthly?.data.map((item) =>
                              item.date.toString(),
                            ) ?? []
                          }
                          series={[
                            {
                              name: '売上',
                              type: 'column',
                              color: '#FFBA66',
                              data:
                                graphMonthly?.data.map(
                                  (item) => item.netSales,
                                ) ?? [],
                            },
                            {
                              name: '予算',
                              type: 'column',
                              color: '#E0E0E0',
                              data:
                                graphMonthly?.data.map((item) => item.budget) ??
                                [],
                            },
                            {
                              name: '営業利益',
                              color: '#02AAF2',
                              type: 'line',
                              data:
                                graphMonthly?.data.map(
                                  (item) => item.operatingIncome,
                                ) ?? [],
                            },
                          ]}
                        />
                      </div>
                      <div style={{ width: '100%', paddingTop: '32px' }}>
                        <YearlyChart
                          targetDate={targetDate}
                          fiscalYear={graphYearly?.fiscalYear}
                          yaxisScaleCurrency={
                            graphYearly?.graph.yaxisScaleCurrency
                          }
                          labels={
                            graphYearly?.data.map((item) =>
                              item.month.toString(),
                            ) ?? []
                          }
                          series={[
                            {
                              name: '売上',
                              type: 'column',
                              color: '#FFBA66',
                              data:
                                graphYearly?.data.map(
                                  (item) => item.netSales,
                                ) ?? [],
                            },
                            {
                              name: '予算',
                              type: 'column',
                              color: '#E0E0E0',
                              data:
                                graphYearly?.data.map((item) => item.budget) ??
                                [],
                            },
                            {
                              name: '営業利益',
                              type: 'line',
                              color: '#02AAF2',
                              data:
                                graphYearly?.data.map(
                                  (item) => item.operatingIncome,
                                ) ?? [],
                            },
                          ]}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </FormContents>
              </div>
            </React.Fragment>
          )}
          {!isMobileOnly && (
            <FormContents>
              <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <FlexBox customStyle={css({ minWidth: '488px' })}>
                  <BodyText>
                    {moment(new Date()).format(
                      '今日 (YYYY年MM月DD日) のシフト',
                    )}
                  </BodyText>
                  <FlexBoxItem marginLeft="auto">
                    <Link to="/shift">
                      <span style={{ marginRight: '12px' }}>
                        <PrimaryButton
                          ghost={false}
                          text="シフト編成へ"
                          disabled={orgCode === 'all'}
                        />
                      </span>
                    </Link>
                  </FlexBoxItem>
                </FlexBox>

                <div
                  css={css`
                    table th:nth-child(1) {
                      width: 100px;
                    }
                    table th:nth-child(2) {
                      width: 80px;
                    }
                    table th:nth-child(3) {
                      width: 100px;
                    }
                    table th:nth-child(4) {
                      width: 200px;
                    }
                  `}
                >
                  <DataTable
                    columns={columns}
                    data={shiftList}
                    sortBy={[{ id: 'staffName', desc: false }]}
                  />
                </div>
              </div>
            </FormContents>
          )}
          {!isMobileOnly ? (
            StartDateUseReportApproval && reportApproval ? (
              <div style={{ marginTop: 20 }}>
                <BlockUI blocking={isLoading}>
                  <FormContents scroll={true}>
                    <div>
                      <BodyText>未申請 / 未承認レポート</BodyText>
                    </div>
                    <div
                      css={css`
                        table th:nth-child(1) {
                          width: 70px;
                        }
                        table th:nth-child(2) {
                          width: 160px;
                        }
                        table th:nth-child(3) {
                          width: 100px;
                        }
                        table th:nth-child(4) {
                          width: 120px;
                        }
                        table th:nth-child(5) {
                          width: 400px;
                        }
                        table th:nth-child(6) {
                          width: 70px;
                        }
                      `}
                      className="requestDashboard requestDashboardTop"
                    >
                      <DataTable
                        columns={request_columns}
                        data={DashboardRequestTable}
                        sortBy={[{ id: 'orgCode', desc: false }]}
                      />
                    </div>
                  </FormContents>
                </BlockUI>
              </div>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {isMobileOnly && <BottomBar />}
        </div>
      </React.Fragment>
    </BlockUI>
  );
};

export default DashboardForm;
