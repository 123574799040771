/* eslint-disable max-len */
import {
  useState, useCallback, useEffect, Dispatch, useMemo,
} from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import useToastNotification from 'hooks/useToastNotification';
import StaffDomain from 'domain/staff';
import { deleteStaffEmployment, postEmploymentInfo } from 'api/staff';
import { EmploymentDataStaffDomain } from 'domain/master/general/employmentDataStaff';
import { InfoMessage } from 'utility/messages';
import { getErrorMessageForResponse } from 'utility/errorUtil';

type StaffDomainKey = keyof Pick<StaffDomain, 'employmentId'>;
const StaffDomainSchema: {[key in StaffDomainKey]: any} = {
  employmentId: Yup.string().required('雇用形態を入力してください。'),
};
const schema = Yup.object({ ...StaffDomainSchema });

export const useEmploymentForm = (
  initialStaffDomain: StaffDomain = StaffDomain.generateInitial(),
  isEditMode: boolean = false,
  setIsLoading: Dispatch<React.SetStateAction<boolean>>,
  menuType: string,
) => {
  const history = useHistory();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [detailMode, setDetailMode] = useState(false);
  const [deleteTargetStaffEmployment, setDeleteTargetStaffEmployment] = useState<EmploymentDataStaffDomain|null>(null);
  const { successNotification, errorNotification } = useToastNotification();

  const onSubmit = async (values: StaffDomain) => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    setConfirmModalOpen(false);
    setIsLoading(true);

    try {
      const response = await postEmploymentInfo(isEditMode ? 1 : 0, menuType, values.getStaffInfoPostData(isEditMode));
      const updatedStaff = new StaffDomain(response.staff);
      formik.setFieldValue('employmentDataList', updatedStaff.employmentDataList);
      formik.setFieldValue('weekShiftPatternList', updatedStaff.weekShiftPatternList);
      if (!isEditMode) {
        sessionStorage.setItem('selectedOrgCodeStaffMaster', updatedStaff.orgCode);
      }
      setIsLoading(false);
      successNotification(isEditMode ? '更新しました。' : '登録しました。');

      if (!isEditMode && response) {
        history.goBack();
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.map((item: { defaultMessage: string; }) => errorNotification(item.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };
  const formik = useFormik({
    initialValues: initialStaffDomain,
    onSubmit,
    validateOnChange: false,
    validate: async (values) => {
      const errors: {[key: string]: string} = {};
      try {
        await schema.validate(values, { abortEarly: false });
      } catch (error) {
        const validationError = error as Yup.ValidationError;
        validationError.inner.forEach((innerError) => {
          errors[innerError.path] = innerError.message;
        });
      }
      return errors;
    },
  });

  useEffect(() => {
    formik.setValues(initialStaffDomain);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStaffDomain]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const isOpenDeleteModal = useMemo(() => !!deleteTargetStaffEmployment, [deleteTargetStaffEmployment]);
  const closeDeleteModal = useCallback(() => setDeleteTargetStaffEmployment(null), []);

  const onSubmitDeleteStaffEmployment = useCallback(async () => {
    if (!deleteTargetStaffEmployment) return;
    setIsLoading(true);
    try {
      const employmentDataList = await deleteStaffEmployment(initialStaffDomain.staffCode, deleteTargetStaffEmployment.applyStartDate);
      initialStaffDomain.employmentDataList = employmentDataList;
      formik.setFieldValue('employmentDataList', employmentDataList);
      successNotification(InfoMessage.Common.DELETE_COMPLETED);
    } catch(error)  {
        errorNotification(getErrorMessageForResponse(error));
    } finally {
      setDeleteTargetStaffEmployment(null);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik, initialStaffDomain, successNotification, errorNotification]);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    onSubmitDeleteStaffEmployment,
    setDeleteTargetStaffEmployment,
    isOpenDeleteModal,
    closeDeleteModal,
  };
};

export default {
  useEmploymentForm,
};
