import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

export const downloadCSV = async (
  fileName: string,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsv(`/v2/accountTitleMst/outputCsv/${companyCode}`, {}, getDownloadCsvFileName(`${fileName}_`));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v2/accountTitleMst/csv/import/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export const getAccountTitleMSTList = async (): Promise<any> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await ApiClient.get(`/v2/accountTitleMst/list/${companyCode}`, {});
    return response.data;
};

export const getAccountTitleMSTDetail = async (
    accountTitleId: string): Promise<any> => {
    const params = {
        accountTitleId,
    };
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await ApiClient.get(`/v2/accountTitleMst/${companyCode}`, params);
    return response.data;
};

export const saveAccountTitleMSTDetail = async (
    accountTitleId: string, data: any) => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const params: any = {
      companyCode,
      accountTitleId: '',
      accountTitleCode: data.accountTitleCode,
      accountTitleName: data.accountTitleName,
      createUser: staffCode,
      updateUser: staffCode,
    };
    if (accountTitleId && accountTitleId !== 'undefined') {
      params.accountTitleId = accountTitleId;
    }
    const response = await ApiClient.post(`/v2/accountTitleMst/save/${companyCode}`, {}, params);
    return response.data;
  };

export const deleteAccountTitleMST = async (
    accountTitleId: string): Promise<any> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const params = {
        accountTitleId,
    };

    const response = await ApiClient.delete(`/v2/accountTitleMst/delete/${companyCode}`, params);
    return response.data;
};

export type Error = {
  error: string;
  defaultMessage: string;
}

export default { };
