import React from 'react';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormField from 'components/atoms/Form/FormField';
import FormContents from 'components/atoms/Form/FormContents';
import RadioSelector from './RadioSelector'
import { TRoleMasterList, CategoryMenuList, MenuDetail } from './type'

type TRadioObject = {
  label: string,
  value: string,
}
const RadioTreeFormItem: React.FC<{
  optionList: TRadioObject[],
  onChange: (event: React.ChangeEvent<HTMLInputElement>, menuId: string) => void,
  data: MenuDetail,
}> = ({
  optionList,
  onChange,
  data,
}) => {
    return (
      <React.Fragment>
        {
          <FormField disabled={data.isDefault} isPadding={true}>
            <RadioSelector
              label={data.menuName}
              items={optionList}
              name={'available'}
              value={String(Number(Boolean(data.available)))}
              setValue={data.isDefault ? () => { } : onChange}
              labelInline={true}
              menuId={data.menuId}
            />
          </FormField>
        }
        { data.isDefault ? '' : String(Number(data.available)) === '1' ? (
          <div style={{ padding: '0px 24px' }}>
            {/* Edit */}
            {data.useEditable === 1 && (
              <FormField isPadding={true}>
                <RadioSelector
                  label='編集'
                  items={optionList}
                  name='editable'
                  value={String(data.editable)}
                  setValue={onChange}
                  labelInline={true}
                  menuId={data.menuId}
                />
              </FormField>
            )}

            {/* Import */}
            {data.useImport === 1 && (
              <FormField isPadding={true}>
                <RadioSelector
                  label='インポート'
                  items={optionList}
                  name='importFlag'
                  value={String(data.importFlag)}
                  setValue={onChange}
                  labelInline={true}
                  menuId={data.menuId}
                />
              </FormField>
            )}

            {/* Download */}
            {data.useDownload === 1 && (
              <FormField isPadding={true}>
                <RadioSelector
                  label='ダウンロード'
                  items={optionList}
                  name='downloadFlag'
                  value={String(data.downloadFlag)}
                  setValue={onChange}
                  labelInline={true}
                  menuId={data.menuId}
                />
              </FormField>
            )}

            {/* usabilityGeneralItem1Name */}
            {data.usabilityGeneralItem1Name !== null && (
              <FormField isPadding={true}>
                <RadioSelector
                  label={data.usabilityGeneralItem1Name}
                  items={optionList}
                  name='usabilityGeneralItem1'
                  value={data.usabilityGeneralItem1 === null ? '1' : String(data.usabilityGeneralItem1)}
                  setValue={onChange}
                  labelInline={true}
                  menuId={data.menuId}
                />
              </FormField>
            )}

            {/* usabilityGeneralItem2Name */}
            {data.usabilityGeneralItem2Name !== null && (
              <FormField isPadding={true}>
                <RadioSelector
                  label={data.usabilityGeneralItem2Name}
                  items={optionList}
                  name='usabilityGeneralItem2'
                  value={data.usabilityGeneralItem2 === null ? '1' : String(data.usabilityGeneralItem2)}
                  setValue={onChange}
                  labelInline={true}
                  menuId={data.menuId}

                />
              </FormField>
            )}

            {/* usabilityGeneralItem3Name */}
            {data.usabilityGeneralItem3Name !== null && (
              <FormField isPadding={true}>
                <RadioSelector
                  label={data.usabilityGeneralItem3Name}
                  items={optionList}
                  name='usabilityGeneralItem3'
                  value={data.usabilityGeneralItem3 === null ? '1' : String(data.usabilityGeneralItem3)}
                  setValue={onChange}
                  labelInline={true}
                  menuId={data.menuId}

                />
              </FormField>
            )}

            {/* usabilityGeneralItem4Name */}
            {data.usabilityGeneralItem4Name !== null && (
              <FormField isPadding={true}>
                <RadioSelector
                  label={data.usabilityGeneralItem4Name}
                  items={optionList}
                  name='usabilityGeneralItem4'
                  value={data.usabilityGeneralItem4 === null ? '1' : String(data.usabilityGeneralItem4)}
                  setValue={onChange}
                  labelInline={true}
                  menuId={data.menuId}

                />
              </FormField>
            )}

            {/* usabilityGeneralItem5Name */}
            {data.usabilityGeneralItem5Name !== null && (
              <FormField isPadding={true}>
                <RadioSelector
                  label={data.usabilityGeneralItem5Name}
                  items={optionList}
                  name='usabilityGeneralItem5'
                  value={data.usabilityGeneralItem5 === null ? '1' : String(data.usabilityGeneralItem5)}
                  setValue={onChange}
                  labelInline={true}
                  menuId={data.menuId}

                />
              </FormField>
            )}

            {/* usabilityGeneralItem6Name */}
            {data.usabilityGeneralItem6Name !== null && (
              <FormField isPadding={true}>
                <RadioSelector
                  label={data.usabilityGeneralItem6Name}
                  items={optionList}
                  name='usabilityGeneralItem6'
                  value={data.usabilityGeneralItem6 === null ? '1' : String(data.usabilityGeneralItem6)}
                  setValue={onChange}
                  labelInline={true}
                  menuId={data.menuId}

                />
              </FormField>
            )}

            {/* usabilityGeneralItem7Name */}
            {data.usabilityGeneralItem7Name !== null && (
              <FormField isPadding={true}>
                <RadioSelector
                  label={data.usabilityGeneralItem7Name}
                  items={optionList}
                  name='usabilityGeneralItem7'
                  value={data.usabilityGeneralItem7 === null ? '1' : String(data.usabilityGeneralItem7)}
                  setValue={onChange}
                  labelInline={true}
                  menuId={data.menuId}

                />
              </FormField>
            )}

            {/* usabilityGeneralItem8Name */}
            {data.usabilityGeneralItem8Name !== null && (
              <FormField isPadding={true}>
                <RadioSelector
                  label={data.usabilityGeneralItem8Name}
                  items={optionList}
                  name='usabilityGeneralItem8'
                  value={data.usabilityGeneralItem8 === null ? '1' : String(data.usabilityGeneralItem8)}
                  setValue={onChange}
                  labelInline={true}
                  menuId={data.menuId}

                />
              </FormField>
            )}

            {/* usabilityGeneralItem9Name */}
            {data.usabilityGeneralItem9Name !== null && (
              <FormField isPadding={true}>
                <RadioSelector
                  label={data.usabilityGeneralItem9Name}
                  items={optionList}
                  name='usabilityGeneralItem9'
                  value={data.usabilityGeneralItem9 === null ? '1' : String(data.usabilityGeneralItem9)}
                  setValue={onChange}
                  labelInline={true}
                  menuId={data.menuId}

                />
              </FormField>
            )}

            {/* usabilityGeneralItem10Name */}
            {data.usabilityGeneralItem10Name !== null && (
              <FormField isPadding={true}>
                <RadioSelector
                  label={data.usabilityGeneralItem9Name}
                  items={optionList}
                  name='usabilityGeneralItem10'
                  value={data.usabilityGeneralItem10 === null ? '1' : String(data.usabilityGeneralItem10)}
                  setValue={onChange}
                  labelInline={true}
                  menuId={data.menuId}

                />
              </FormField>
            )}

          </div>
        ) : ''}
      </React.Fragment>
    );
  }

export default RadioTreeFormItem;
