/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { grayScale } from 'components/styles';

const style = css({
  margin: '0 8px',
  height: '18px',
  borderLeft: `1px solid ${grayScale.gray20}`,
});

const VerticalSeparator: React.FC = () => (
  <div css={style} />
);

export default VerticalSeparator;
