/* eslint-disable max-len */

import React, { useState, useEffect } from 'react';
import {
  getDashboardShiftList,
  getSalesAmountOnTheDay, getSalesAmountOnTheMonth,
  getNumberOfCustomersUpToBreakEvenPoint,
  getNumberOfCustomersToReachBudgetMonth, getMonthlyBudgetProgressRate,
  getMonthlyBudgetProgressRank, getfRate, getlRate, getVoidRate,
  getDashboardRequestTable, getSPDashboardFirstView, getSPDashboardCategoryView,
  getSPDashboardCategoryDetailView, getSPDashboardOrgDetailView,
} from 'api/dashboard';
import { DashboardShiftDomain } from 'domain/master/general/dashboardShift';
import { DashboardReportStatusDomain } from 'domain/master/general/dashboardReportStatus';
import { DashboardCard, DashboardCardDomain } from 'domain/master/general/dashboardCard';
import moment from 'moment';
import { getTimeSetting } from 'api/salesSetting';
import { useParams, useHistory } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { getErrorMessageForResponse } from 'utility/errorUtil';
import useToastNotification from 'hooks/useToastNotification';


export const useDashboard = () => {
  const { errorNotification } = useToastNotification();
  const history = useHistory();
  const d = new Date();
  d.setDate(d.getDate() - 1);

  const [orgCode, setorgCode] = useState('all');
  const [targetDate, setTargetDate] = useState(d);
  const [shiftList, setShiftList] = useState<Array<DashboardShiftDomain>>([]);
  const [salesAmountOnTheMonth, setSalesAmountOnTheMonth] = useState(DashboardCardDomain.generateInitial());
  const [salesAmountOnTheDay, setSalesAmountOnTheDay] = useState(DashboardCardDomain.generateInitial());
  const [numberOfCustomersUpToBreakEvenPoint, setNumberOfCustomersUpToBreakEvenPoint] = useState(DashboardCardDomain.generateInitial());
  const [numberOfCustomersToReachBudgetMonth, setNumberOfCustomersToReachBudgetMonth] = useState(DashboardCardDomain.generateInitial());
  const [monthlyBudgetProgressRate, setMonthlyBudgetProgressRate] = useState(DashboardCardDomain.generateInitial());
  const [monthlyBudgetProgressRank, setMonthlyBudgetProgressRank] = useState(DashboardCardDomain.generateInitial());
  // const [FLRate, setFLRate] = useState(DashboardCardDomain.generateInitial());
  const [voidRate, setVoidRate] = useState(DashboardCardDomain.generateInitial());
  const dateFrom = moment(new Date(targetDate.getFullYear(), targetDate.getMonth(), 1)).format('YYYY-MM-DD');
  const dateTo = moment(targetDate).format('YYYY-MM-DD');
  const [categoryAry, setCategoryAry] = React.useState<Array<string>>([]);
  const [FRate, setFRate] = useState(DashboardCardDomain.generateInitial());
  const [LRate, setLRate] = useState(DashboardCardDomain.generateInitial());
  const [DashboardRequestTable, setDashboardRequestTable] = useState<Array<DashboardReportStatusDomain>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBodyLoading, setIsBodyLoading] = useState<boolean>(false);
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const [reportApproval, setReportApproval] = useState(false);
  const [StartDateUseReportApproval, setStartDateUseReportApproval] = useState(true);

  const { option, detailOption, informationDetail } = useParams<any>();
  const [dispType, setdispType] = useState<number>(0);

  const [getSPDashboard, setSPDashboard] = useState<any>();

  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [period, setPeriod] = useState(false);
  const [targetDayFrom, setTargetDayFrom] = useState(new Date(moment(d).startOf('month').format("YYYY/MM/DD")));
  const [targetDayTo, setTargetDayTo] = useState(d.getDate() === 1 ? new Date(moment(d).endOf('month').format("YYYY/MM/DD")) : new Date(d));

  useEffect(() => {
    !isMobileOnly && getTimeSetting(companyCode).then((response) => {
      setReportApproval(response.useReportApproval);
      setStartDateUseReportApproval(new Date().valueOf() > moment(response.reportApprovalStartDate).valueOf());
    }).catch((e) => {
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode]);
  useEffect(() => {
    console.log('zooooo', orgCode);
    let isSubscribed = true;
    setIsLoading(true);
    setIsBodyLoading(true);
    if (orgCode !== 'all') {
      getDashboardShiftList(orgCode).then((response: Array<any>) => {
        if (isSubscribed) {
          setShiftList(response.map((result) => new DashboardShiftDomain(result)));
        }
      });
    } else {
      setShiftList([]);
    }

    // Sale month 1
    !isMobileOnly && getSalesAmountOnTheMonth(orgCode, targetDate, categoryAry).then((response: DashboardCard) => {
      setSalesAmountOnTheMonth(new DashboardCardDomain(response));
    });
    // Sale day 2
    !isMobileOnly && getSalesAmountOnTheDay(orgCode, targetDate, categoryAry).then((response: DashboardCard) => {
      setSalesAmountOnTheDay(new DashboardCardDomain(response));
    });
    // Number of customers to breakeven 3
    !isMobileOnly && getNumberOfCustomersUpToBreakEvenPoint(orgCode, targetDate, categoryAry).then((response: DashboardCard) => {
      setNumberOfCustomersUpToBreakEvenPoint(new DashboardCardDomain(response));
    });
    // Number of customers to reach budget 4
    !isMobileOnly && getNumberOfCustomersToReachBudgetMonth(orgCode, targetDate, categoryAry).then((response: DashboardCard) => {
      setNumberOfCustomersToReachBudgetMonth(new DashboardCardDomain(response));
    });
    // VOID rate for this month 5
    !isMobileOnly && getVoidRate(orgCode, targetDate, categoryAry).then((response: any) => {
      setVoidRate(response);
    });
    // 6
    !isMobileOnly && getfRate(orgCode, targetDate, categoryAry).then((response: any) => {
      setFRate(response);
    });
    // 7
    !isMobileOnly && getlRate(orgCode, targetDate, categoryAry).then((response: any) => {
      setIsBodyLoading(false);
      setLRate(response);
    })

    // Sale month 8
    !isMobileOnly && getDashboardRequestTable(orgCode, categoryAry).then((response: any) => {
      setIsLoading(false);
      setDashboardRequestTable(Array.isArray(response) ? response.map((result: any) => new DashboardReportStatusDomain(result)) : []);
    });
    // Monthly budget progress
    !isMobileOnly && getMonthlyBudgetProgressRate(orgCode, targetDate, categoryAry).then((response: DashboardCard) => {
      setIsLoading(false);
      setMonthlyBudgetProgressRate(new DashboardCardDomain(response));
    });
    // Monthly budget rank
    !isMobileOnly && getMonthlyBudgetProgressRank(orgCode, targetDate, categoryAry).then((response: DashboardCard) => {
      setIsLoading(false);
      setMonthlyBudgetProgressRank(new DashboardCardDomain(response));
    });
    // FL Rate
    // getFLRate(orgCode, targetDate, categoryAry).then((response: any) => {
    //   setFLRate(response);
    // });
    if (isMobileOnly) {
      if (option !== undefined && option !== '' && detailOption !== undefined && detailOption !== '' && informationDetail !== undefined && informationDetail !== '') {
        const params = {
          staffCode: staffCode,
          targetDay: !period ? moment(targetDate).format('YYYY-MM-DD') : null,
          targetDayFrom: period ? moment(targetDayFrom).format('YYYY-MM-DD') : null,
          targetDayTo: period ? moment(targetDayTo).format('YYYY-MM-DD') : null,
          orgCode: informationDetail
        }
        getSPDashboardOrgDetailView(params).then((response: any) => {
          setIsLoading(false);
          setSPDashboard(response);
          setIsBodyLoading(false);
        }).catch((error) => {
          const mess = getErrorMessageForResponse(error);
          errorNotification(mess);
          setIsLoading(false);
          setIsBodyLoading(false);
        });
      } else if (option !== undefined && option !== '' && detailOption !== undefined && detailOption !== '') {
        const params = {
          staffCode: staffCode,
          targetDay: !period ? moment(targetDate).format('YYYY-MM-DD') : null,
          targetDayFrom: period ? moment(targetDayFrom).format('YYYY-MM-DD') : null,
          targetDayTo: period ? moment(targetDayTo).format('YYYY-MM-DD') : null,
          organizationCategoryMstHeadId: option,
          organizationCategoryMstDetailId: detailOption
        }
        getSPDashboardCategoryDetailView(params).then((response: any) => {
          setIsLoading(false);
          setSPDashboard(response);
          setIsBodyLoading(false);
        }).catch((error) => {
          const mess = getErrorMessageForResponse(error);
          errorNotification(mess);
          setIsLoading(false);
          setIsBodyLoading(false);
        });
      } else if (option !== undefined && option !== '') {
        const params = {
          staffCode: staffCode,
          targetDay: !period ? moment(targetDate).format('YYYY-MM-DD') : null,
          targetDayFrom: period ? moment(targetDayFrom).format('YYYY-MM-DD') : null,
          targetDayTo: period ? moment(targetDayTo).format('YYYY-MM-DD') : null,
          organizationCategoryMstHeadId: option
        }
        getSPDashboardCategoryView(params).then((response: any) => {
          setIsLoading(false);
          setSPDashboard(response);
          setIsBodyLoading(false);
        }).catch((error) => {
          const mess = getErrorMessageForResponse(error);
          errorNotification(mess);
          setIsLoading(false);
          setIsBodyLoading(false);
        });
      } else {
        const params = {
          staffCode: staffCode,
          targetDay: !period ? moment(targetDate).format('YYYY-MM-DD') : null,
          targetDayFrom: period ? moment(targetDayFrom).format('YYYY-MM-DD') : null,
          targetDayTo: period ? moment(targetDayTo).format('YYYY-MM-DD') : null
        }
        getSPDashboardFirstView(params).then((response: any) => {
          setIsLoading(false);
          setSPDashboard(response);
          setdispType(response.dispType);
          setIsBodyLoading(false);
          console.log(response, 'test getSPDashboardFirstView');
        }).catch((error) => {
          const mess = getErrorMessageForResponse(error);
          errorNotification(mess);
          setIsLoading(false);
          setIsBodyLoading(false);
        });
      }
    }
    return () => { isSubscribed = false; };
  }, [categoryAry, orgCode, targetDate, option, detailOption, informationDetail, period, targetDayFrom, targetDayTo]);

  return {
    orgCode,
    targetDate,
    shiftList,
    salesAmountOnTheMonth,
    salesAmountOnTheDay,
    numberOfCustomersUpToBreakEvenPoint,
    numberOfCustomersToReachBudgetMonth,
    monthlyBudgetProgressRate,
    monthlyBudgetProgressRank,
    voidRate,
    setorgCode,
    setTargetDate,
    categoryAry,
    setCategoryAry,
    dateFrom,
    dateTo,
    FRate,
    LRate,
    DashboardRequestTable,
    isLoading,
    reportApproval,
    StartDateUseReportApproval,
    isBodyLoading,
    getSPDashboard,
    dispType,
    period,
    setPeriod,
    targetDayFrom,
    setTargetDayFrom,
    targetDayTo,
    setTargetDayTo
  };
};

export default useDashboard;
