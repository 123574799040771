/* eslint-disable @typescript-eslint/no-unused-vars */
/** @jsx jsx */
import React, { useState, useEffect, useRef } from 'react';
import { css, jsx } from '@emotion/core';
import productColor, { grayScale, textFontSize } from 'components/styles';
import Icon from 'components/atoms/Icon';
import AddOnInput from 'components/atoms/Form/AddOnInput';
import Radio from 'components/molecules/Radio';
import ClickableIconButton from '../ClickableIconButton';

let checkUpdateValue = true;

const styles = {
  wrapper: css({
    display: 'flex',
    width: '150px',
  }),
  membrane: css({
    position: 'absolute',
    zIndex: 2,
    width: '112px',
    height: '38px',
  }),
  displayNone: css({
    display: 'none',
  }),
  input: css({
    flex: 1,
    width: '75px',
    padding: '7px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    color: grayScale.gray100,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: 'none',
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px 0 0 4px',
  }),
  addon: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '7px',
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
  }),
  icon: css({
    padding: '8px 5px 12px 8px',
  }),
};

const TimeInput: React.FC<{
  name: string;
  value: string;
  setValue: (val: string) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({

  name, value, setValue,
}) => {
  const timeInput = useRef(null);
  const [isFirstFocus, setIsFirstFocus] = useState(true);

  const onBlur = (e: any) => {
    if (e.target.value) {
      setValue(e.target.value);
    }
    setTimeout(() => {
      setIsFirstFocus(true);
    }, 1000);
  };

  const onClickDiv = (timeInput: any) => {
    if (isFirstFocus) {
      setTimeout(() => {
        timeInput.current.focus();
        setIsFirstFocus(false);
      }, 50);
    }
  };

  const [time, setTime] = useState(value);

  useEffect(() => {
    if (checkUpdateValue) {
      setTime(value);
    }
    checkUpdateValue = true;
  }, [value]);

  const onClick = () => {
    checkUpdateValue = false;
    setValue('00:00');
    setTime('--:-- --');
  };

  return (
    <div css={styles.wrapper}>
      <div
        css={
        isFirstFocus ? styles.membrane : styles.displayNone
       }
        onClick={() => onClickDiv(timeInput)}
      />
      <AddOnInput
        input={(
          <input
            ref={timeInput}
            css={css(styles.input)}
            type="time"
            name={name}
            onBlur={onBlur}
            value={time}
            onChange={(e) => { setTime(e.target.value); }}
            inputMode="decimal"
            style={{ imeMode: 'disabled' }}
          />

        )}
        addOn={(
          <ClickableIconButton onClick={onClick}>
            <Icon type="remove" color={grayScale.gray100} />
          </ClickableIconButton>
          )}
      />
    </div>
  );
};

export default TimeInput;
