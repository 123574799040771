import React from 'react';

const MonthHeader: React.FC = (props) => {
  const { label } = props as any;
  return (
    <div>{label}</div>
  );
};

export default MonthHeader;
