import React from 'react';
import { useParams } from 'react-router-dom';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import PrescribedDaysCreateForm from 'components/organismos/master/labor/PrescribedDaysCreateForm';
import FormContents from 'components/atoms/Form/FormContents';
import TabControl from 'components/atoms/TabControl';

type SelectTab = 'add';
const SelectTab: {[key in SelectTab]: SelectTab} = {
  add: 'add',
};

const WorkingDaysCreatePage: React.FC = () => {
  const { workingDaysId } = useParams();
  const [selectTab, setSelectTab] = React.useState<SelectTab>(SelectTab.add);

  return (
    <SidebarTemplate pageTitle={workingDaysId ? '所定日数編集' : '所定日数作成'}>
      <FormContents>
        <TabControl
          items={[
            {
              value: SelectTab.add,
              display: '所定労働日数',
            },
          ]}
          setValue={setSelectTab as (arg: string) => void}
          currentValue={selectTab}
          isFixed={true}
        />
        <PrescribedDaysCreateForm />
      </FormContents>
    </SidebarTemplate>
  );
};

export default WorkingDaysCreatePage;
