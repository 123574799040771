import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import Modal from 'components/molecules/Modal';
import Table from 'components/molecules/Table';
import Checkbox from 'components/molecules/Checkbox';
import SelfOrganizationDomain from 'domain/master/general/selfOrganization';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { grayScale, textFontSize, iconColor } from 'components/styles';
import useOrgList from './hooks';

const OrganizationAddModal: React.FC<{
  open: boolean;
  openHandler: (arg: boolean) => void;
  addOrganization: (arg: Array<SelfOrganizationDomain>) => void
}> = ({ open, openHandler, addOrganization }) => {
  const {
    organizationList,
    checkedOrgCodeList,
    setCheckedOrgCodeList,
    checkHandler,
    onSubmit,
    setCategoryAr,
    checkAll,
    setCheckAll,
    checkAllHandler,
    search,
    setSearch,
  } = useOrgList(addOrganization, openHandler);
  //
  const functionType = 1;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  let checkWidthSM = false;
  const mq = window.matchMedia('(max-width: 1024px)');
  if (mq.matches) {
    checkWidthSM = true;
  } else {
    checkWidthSM = false;
  }

  const styles = {
    model: css({
      width: checkWidthSM ? '70%' : '50%',
    }),
  };

  // reset list selOrganize as the first time
  useEffect(() => {
    if (open) {
      setCategoryAr([]);
    }
  }, [open, setCategoryAr]);

  const handleCheckAll = () => {
    checkAllHandler(!checkAll);
    setCheckAll(!checkAll);
  }

  const handleCloseModal = () => {
    setCheckedOrgCodeList([]);
    openHandler(false);
    setCheckAll(false);
    setSearch('');
  }

  return (
    <Modal
      open={open}
      closeHandler={handleCloseModal}
      title="組織選択"
      submitText="追加"
      onSubmit={onSubmit}
      customStyle={styles.model}
    >
      <OrganizationFilteredTree
        functionType={functionType}
        orgCategoryCallback={
          (args: Array<string>) => {
            setCategoryAr(args);
          }
        }
        staffCode={loginStaffCode}
        orgLabel="組織名"
        showSelectOrg={false}
        addAllItem={true}
      />

      <input
        style={{
          border: `solid 1px ${grayScale.gray10}`,
          boxSizing: 'border-box',
          borderRadius: '4px',
          fontSize: textFontSize.re,
          fontFamily: 'inherit',
          padding: '8px',
          marginBottom: '10px'
        }}
        name='search'
        value={search}
        onChange={
          (e: any) => {
            setSearch(e.target.value);
          }
        }
        onKeyUp={(e: any) => {
            setSearch(e.target.value);
          }
        }
      />

      <Table>
        <thead>
          <tr>
            <Table.HeaderCell customStyle={css({ width: '10%' })}>
              <Checkbox
                id='checkall'
                name='checkall'
                value='checkall'
                checked={checkAll}
                onChange={handleCheckAll}
              />
            </Table.HeaderCell>
            <Table.HeaderCell customStyle={css({ width: '30%' })}>
              組織コード
            </Table.HeaderCell>
            <Table.HeaderCell customStyle={css({ width: '60%' })}>
              組織名
            </Table.HeaderCell>
          </tr>
        </thead>
        <tbody>
          {organizationList && (
            organizationList.map((organization, index) => (
              <tr key={index.toString()}>
                <Table.Cell>
                  <Checkbox
                    id={organization.orgCode}
                    name={organization.orgCode}
                    value={organization.orgCode}
                    checked={checkedOrgCodeList.includes(organization.orgCode)}
                    onChange={() => checkHandler(organization.orgCode)}
                  />
                </Table.Cell>
                <Table.Cell>
                  {organization.orgCode}
                </Table.Cell>
                <Table.Cell>
                  {organization.orgName}
                </Table.Cell>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Modal>
  );
};

export default OrganizationAddModal;
