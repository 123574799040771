/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import React, { useEffect, useCallback, useMemo} from 'react';
import { css, jsx } from '@emotion/core';
import { stateSymbol } from 'components/organismos/master/general/salesPage//SalesManagementList/hookSysboy';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { useDownload } from 'components/organismos/master/general/salesPage/SalesManagementList/hook';
import moment from 'moment';
import { Link } from 'react-router-dom';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import SortIcon from 'components/molecules/SortIcon';
import { grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';

const styles = css({
  fontWeight: 400,
  fontSize: 14,
});

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 1,
});

const DEFAULT_SORT_FIELD = { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number' } as FieldSortState

const SalesAuditItemsListTable: React.FC<{
    listSalesManagement6: any;
    isActiveRow: number;
    isActiveColumn: number;
    handleClickActive: any;
    selectedDateFrom: any;
    selectedDateTo: any;
    orgName: any;
    roleScreen?: any;
  }> = ({
     listSalesManagement6,
    isActiveRow,
    isActiveColumn,
    handleClickActive,
    selectedDateFrom,
    selectedDateTo,
    orgName,
    roleScreen
  }) => {
    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(listSalesManagement6, DEFAULT_SORT_FIELD);

    const searchResult: any[] = [];
    const totalInformation: any[] = [];
    const { downloadCsv6, downloadExcel6 } = useDownload();

    const headerInformation = [
      ['店舗', '並び順', '小口購入額','値引き合計','VOID金額','月次', '現金過不足']
    ];

    const data = {
      footer: '',
      formName: '営業管理【監査項目】',
      targetPeriod: `対象期間：${moment(selectedDateFrom).format('YYYY-MM-DD')} ~ ${moment(selectedDateTo).format('YYYY-MM-DD')}`,
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: [],
    };

    let mergedRegions: any[] = [];
    const codeColumnIndex: any[] = [];
    const dataExcel = {
      formName: '営業管理【監査項目】',
      targetPeriod: `対象期間: ${moment(selectedDateFrom).format('YYYY-MM-DD')} ~ ${moment(selectedDateTo).format('YYYY-MM-DD')}`,
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex,
    };

    const handleExportCSV = () => downloadCsv6(data);

    const handleExportExcel = () => {
      if (mergedRegions) {
        mergedRegions = [];
      }
      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [0];
      dataExcel.headerInformation = headerInformation;
      return downloadExcel6(dataExcel);
    };

    const renderHeaderColumn = useCallback((displayName: string, columnSortField: FieldSortState, rowSpan: number, className: string = "") => (
      <th rowSpan={rowSpan} className={className} onClick={e => sortByFieldPath(columnSortField.fieldPath, columnSortField.sortType)}>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <span style={{ margin: 'auto' }}>{displayName}</span>
          {sortField.fieldPath === columnSortField.fieldPath && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
          {sortField.fieldPath !== columnSortField.fieldPath && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
        </div>
      </th>
      ), [sortField]);

      return  (
      <div>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportCSV}
                iconType="download"
                text=" CSV出力"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportExcel}
                iconType="download"
                text=" EXCEL出力"
              />
            </FlexBoxItem>
          </FlexBox>
        )}
      <div
        className="table-responsive"
        style={{
          display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', marginTop: 20, maxHeight: 600,
        }}
      >
        <table id="salesPageForm" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }}>
          <thead>
            <tr>
              { renderHeaderColumn('店舗', { fieldPath: 'orgName', sort: 'asc', sortType: 'string'}, 1) }
              { renderHeaderColumn('並び順', { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number'}, 1) }
              { renderHeaderColumn('小口購入額', { fieldPath: 'totalPettyAmount', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('値引き合計', { fieldPath: 'totalDiscountAmount', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('VOID件数', { fieldPath: 'voidCnt', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('VOID金額', { fieldPath: 'voidAmount', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('現金過不足', { fieldPath: 'excessAndDeficiency', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
            </tr>
          </thead>
            {
              listSalesManagement6 &&  listSalesManagement6.length > 0 ?  dataSort.map((salesAuditItems: any, index: number) => {
              {searchResult.push([
                (stateSymbol as any)[salesAuditItems.status] + salesAuditItems.orgName,
                salesAuditItems.dispOrder,
                formatter.format(salesAuditItems.totalPettyAmount) || '',
                formatter.format(salesAuditItems.totalDiscountAmount) || '',
                formatter.format(salesAuditItems.voidCnt)|| '',
                formatter.format(salesAuditItems.voidAmount) || '',
                formatter.format(salesAuditItems.excessAndDeficiency) || '',
                ])
              }
                return  (
                  <tbody key={index} css={styles}>
                    <tr className={isActiveRow === index ? 'activeClickFrames' : ''}>
                      <td
                        className={`textLeft ${isActiveColumn === 1 ? 'activeClickFrames' : ''}`}
                        onClick={() => { handleClickActive(index, 1); }}
                      >
                      {
                              salesAuditItems.status !== 99
                              ? <Link target={"_blank"} to={{ pathname: `/salesDailyReport/${salesAuditItems.orgCode}/${moment(selectedDateTo).format('YYYY-MM-DD')}` }}>
                                {(stateSymbol as any)[salesAuditItems.status]}
                                </Link>
                              :  (stateSymbol as any)[salesAuditItems.status]
                      }
                      {salesAuditItems.orgName}
                      </td>
                      <td
                        className={isActiveColumn === 2 ? 'activeClickFrames' : ''}
                        onClick={() => { handleClickActive(index, 2); }}
                      >
                          {salesAuditItems.dispOrder}
                      </td>
                      <td
                        className={isActiveColumn === 3 ? 'activeClickFrames' : ''}
                        onClick={() => { handleClickActive(index, 3); }}
                      >
                          {formatter.format(salesAuditItems.totalPettyAmount)}
                      </td>
                      <td
                        className={isActiveColumn === 4 ? 'activeClickFrames' : ''}
                        onClick={() => { handleClickActive(index, 4); }}
                      >
                        {formatter.format(salesAuditItems.totalDiscountAmount)}
                      </td>
                      <td
                        className={isActiveColumn === 5 ? 'activeClickFrames' : ''}
                        onClick={() => { handleClickActive(index, 5); }}
                      >
                        {formatter.format(salesAuditItems.voidCnt)}
                      </td>
                      <td
                        className={isActiveColumn === 6 ? 'activeClickFrames' : ''}
                        onClick={() => { handleClickActive(index, 6); }}
                      >
                        {formatter.format(salesAuditItems.voidAmount)}
                      </td>
                      <td
                        className={isActiveColumn === 7 ? 'activeClickFrames' : ''}
                        onClick={() => { handleClickActive(index, 7); }}
                      >
                          {formatter.format(salesAuditItems.excessAndDeficiency)}
                      </td>
                    </tr>
                  </tbody>
              )
              }) : <tbody css={styles}></tbody>
            }
        </table>
        </div>
      </div>
    );
  }
export default SalesAuditItemsListTable;
