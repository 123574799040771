/** @jsx jsx */
import { useState, useMemo, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import moment from 'moment';
import { Column } from 'react-table';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import DataTable from 'components/organismos/DataTable/DataTable';
import BlockUI from 'components/molecules/BlockUi';
import SmartHRSyncReportListDomain from 'domain/master/storeManagement/smartHRList';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import { useSmartHRSyncReportList } from '../hook';

const SmartHRSyncReportManagement = () => {
  const datefrom = new Date(moment().subtract(1, 'days').toString());
  const dateTo = new Date(moment().subtract(1, 'days').toString());
  const [targetDateFrom, setTargetDateFrom] = useState(datefrom);
  const [targetDateTo, setTargetDateTo] = useState(dateTo);

  const { smartHRSyncReportList, blocking, stateStatus, setStateStatus } = useSmartHRSyncReportList(
    moment(targetDateFrom).format('YYYY-MM-DD'),
    moment(targetDateTo).format('YYYY-MM-DD')
  );

  const searchResult: any[] = [];
  useEffect(() => {
    smartHRSyncReportList.map((item: any, index: any) => {
      searchResult.push([
        item.syncDate ? moment(item.syncDate).format('YYYY-MM-DD') : '',
        item.syncStatus || '',
        item.staffCode || '',
        item.staffName || '',
        item.departmentName || '',
        item.position || '',
        item.employment || '',
        item.status || '',
        item.errMsg || '',
      ]);
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartHRSyncReportList, searchResult]);

  const columns: Array<Column<SmartHRSyncReportListDomain>> = useMemo(
    () => [
      {
        Header: () => '同期日',
        accessor: 'syncDate',
        id: 'syncDate',
        Cell: (cell:{ row:{isExpanded: boolean, original: any }, page:[]}) => 
        (cell.row.original.syncDate ? moment(cell.row.original.syncDate).format('MM/DD/YYYY') : '')
      },
      {
        Header: '同期区分',
        accessor: 'syncStatus'
      },
      {
        Header: 'スタッフコード',
        accessor: 'staffCode'
      },
      {
        Header: 'スタッフ名',
        accessor: 'staffName'
      },
      {
        Header: 'エラーメッセージ',
        accessor: 'errMsg',
        id: 'errMsg',
        Cell: (cell:{ row:{isExpanded: boolean, original: any }, page:[]}) => 
        <div css={css({
          overflow: 'auto',
          padding: '5px 0',
           '::-webkit-scrollbar': {
              width: '5px',
              height: '5px'
            },
           '::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
              background: '#f1f1f1',
              borderRadius: '5px'
            },
           '::-webkit-scrollbar-thumb': {
              background: '#888',
              borderRadius: '5px'
            },
           '::-webkit-scrollbar-thumb:hover': {
              background: '#555' 
            }
        })}>
          <span>{cell.row.original.errMsg}</span>
        </div>
      }
    ],
    [],
  );

  return (
    <BlockUI blocking={blocking}>
      <div>
        <FormContents>
          <FlexBox>
            <FlexBoxItem width="100px">
              <FormLabel
                label="同期日"
              />
            </FlexBoxItem>

            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月dd日"
                label=""
                date={targetDateFrom}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateFrom(date);
                  }
                }}
                isFullWidth={true}
              />
            </FlexBoxItem>

            <FlexBoxItem>
              <span style={{ margin: '0 20px' }}>～</span>
            </FlexBoxItem>

            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月dd日"
                label=""
                date={targetDateTo}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setTargetDateTo(date);
                  }
                }}
                isFullWidth={true}
              />
            </FlexBoxItem>
          </FlexBox>
          <FlexBox>
          <FlexBoxItem width="105px">
            <FormLabel label="同期区分" />
          </FlexBoxItem>
          <FlexBoxItem width="100%" customStyle={css({marginTop: '8px'})}>
             <RadioSelectForm
              label=""
              name="syncStatus"
              items={[
                { label: 'すべて', value: '0' },
                { label: '登録失敗', value: '1' },
                { label: '同期失敗', value: '2' }
              ]}
              value={String(stateStatus)}
              setValue={(e) => setStateStatus(Number(e.target.value))}
            />
          </FlexBoxItem>
        </FlexBox>
          
          <div
            css={css`
                table {
                  white-space: pre;
                }
                table th:nth-child(1), table>tbody>tr>td:nth-child(1) {
                    width: 80px;
                }
                table th:nth-child(2), table>tbody>tr>td:nth-child(2)  {
                    width: 80px;
                }
                table th:nth-child(3), table>tbody>tr>td:nth-child(3)  {
                    width: 100px;
                }
                table th:nth-child(4), table>tbody>tr>td:nth-child(4)  {
                    width: 100px;
                }
                table th:nth-child(5), table>tbody>tr>td:nth-child(5)  {
                    width: 200px;
                    overflow: visible;
                    white-space: pre-wrap;
                }
                table th:nth-child(6), table>tbody>tr>td:nth-child(6)  {
                    width: 100px;
                    overflow: visible;
                    white-space: pre-wrap;
                }
                table th:nth-child(7), table>tbody>tr>td:nth-child(7)  {
                    width: 100px;
                    overflow: visible;
                    white-space: pre-wrap;
                }
                table th:nth-child(8), table>tbody>tr>td:nth-child(8)  {
                    width: 80px;
                }
                table th:nth-child(9), table>tbody>tr>td:nth-child(9)  {
                    width: 300px;
                }
                `}
          >
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
              <DataTable
                columns={columns}
                data={smartHRSyncReportList}
                isGlobalFilter={true}
                minWidth="1552px"
                sortBy={[
                  {id: 'syncDate', desc: false}
                ]}
              />
            </div>
          </div>
        </FormContents>
      </div>
    </BlockUI>
  );
};

export default SmartHRSyncReportManagement;
