
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

const SalesTable: React.FC<{
  caption: string,
  stores: any[],
}> = ({ caption, stores }) => {
  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <div css={css({ marginTop: '10px' })}>
      <span css={css({ fontWeight: 700, fontSize: '14px' })}>
        {caption}
      </span>
      <div css={css({
        overflow: 'auto',
        table: {
          borderSpacing: '0px',
          borderCollapse: 'collapse',
        },
        'table tr td': {
          // minWidth: '50px',
          border: '1px solid #222d32',
          height: '32px',
          fontSize: '12px',
        },
        'table thead tr td': {
          textAlign: 'center',
          fontWeight: 700,
        },
        'table tbody tr td': {
          textAlign: 'right',
          paddingRight: '5px',
        },
        'table tbody tr td:nth-of-type(1)': {
          width: '150px',
          textAlign: 'center',
        },
        'table tbody tr :is(td:nth-of-type(2), td:nth-of-type(3), td:nth-of-type(5), td:nth-of-type(6), td:nth-of-type(8), td:nth-of-type(9), td:nth-of-type(11), td:nth-of-type(12), td:nth-of-type(14), td:nth-of-type(15))': {
          width: '50px',
        },
        'table tbody tr :is(td:nth-of-type(4), td:nth-of-type(7), td:nth-of-type(10), td:nth-of-type(13), td:nth-of-type(16))': {
          width: '100px',
        },
      })}
      >
        <table css={css({ minWidth: '1024px' })}>
          <thead>
            <tr>
              {stores && stores.slice(0, 1).map((storeHeder: any[]) => (
                storeHeder.map((item) => (
                  item.header === ''
                    ? <td>{item.header}</td>
                    : <td colSpan={3}>{item.header}</td>
                ))
              ))}
            </tr>
          </thead>
          <tbody>
            {stores && stores.slice(1).map((storeRow) => (
              <tr>
                {storeRow.map((item: any) => (
                  Object.keys(item).length === 1
                    ? <td>{item.value}</td>
                    : (
                      <React.Fragment>
                        <td>{item.value1.concat('組')}</td>
                        <td>{item.value2.concat('名')}</td>
                        <td>
                          {formatter.format(item.value3).concat('円')}
                        </td>
                      </React.Fragment>
                    )
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesTable;
