/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { apiGetSlitMonthlyBalanceTable } from 'api/incomeAndExpenditureStore';

const useIncomeAndExpenditureStore = (
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  categoryAry: any,
) => {
  const [incomeAndExpenditureStore, setIncomeAndExpenditureStore] = useState<Array<any>>([]);
  const [listHeader, setListHeader] = useState();
  const [getIsLoading, setIsLoading] = useState(false);
  useEffect(() => {
    let isSubscribed = true;
    setIsLoading(true);
    apiGetSlitMonthlyBalanceTable(
      orgCode,
      monthlyStartDate,
      monthlyEndDate,
      categoryAry,
    ).then((response: any) => {
      if (isSubscribed) {
        setIncomeAndExpenditureStore(response);
      }
      setIsLoading(false);
    }).catch((err) => console.log(err, 'err'));
  }, [orgCode, monthlyStartDate, categoryAry]);

  return {
    incomeAndExpenditureStore, setIncomeAndExpenditureStore, getIsLoading, listHeader, setIsLoading
  };
};

export default useIncomeAndExpenditureStore;