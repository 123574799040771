import React, { useEffect, useState, useCallback } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import BlockUI from 'components/molecules/BlockUi';
import FlexBox from 'components/atoms/FlexBox';
import { css } from '@emotion/core';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BodyText from 'components/atoms/BodyText';
import ToggleSwitch from 'components/molecules/ToggleSwitch';
import Select, { OptionType } from 'components/atoms/Select';
import { isMobileOnly, isMobile } from 'react-device-detect';
import OrganizationSelectForm from '../OrganizationTree/OrganizationSelectForm';
import { useOrganizationFilteredTree } from './hooks';
import OrganizationCategorySelect from '../OrganizationCategory/OrganizationCategorySelect';
import { OrgStatusType } from 'utility/constants';

const OrganizationFilteredTree: React.FC<{
  functionType: number;
  staffCode: string;
  targetdayForm?: Date;
  targetdayTo?: Date;
  orgLabel?: string;
  initOrgValue?: Array<string> | string;
  orgCallback?: (args: any) => void;
  orgSpecifyCallback?: (args: any) => void;
  orgCategoryCallback?: (args: any) => void;
  orgOrgOptionListCallback?: (args: any) => void;
  selectCheck?: (args: boolean) => void;
  orgMulti?: true | false;
  addAllItem?: true | false;
  key?: any;
  styles?: any;
  required?: boolean;
  showFilterCategory?: boolean;
  notSetLoginStaffCode?: boolean;
  showSelectOrg?: boolean;
  orgNameCallback?: (args: any) => void;
  isNotShowCatagoryMobie?: boolean;
  initCatagory?: (args: any) => void;
  clearToggle?: any,
  setClearToggle?: any,
  disabled?: boolean;
  orgFilter?: number;
  isAlignItemsCenter?: boolean;
  customSelectOrg?: boolean;
  isHide?: boolean;
  defaultToggleValue?:boolean;
  defaultCategoryValues?:any;
  showSelectOrgStatus?: boolean;
  orgStatus?: OrgStatusType;
  orgStatusCallback?: (args: OrgStatusType) => void;
  isShowSituation?: boolean;
}> = ({
  functionType,
  staffCode,
  targetdayForm,
  targetdayTo,
  orgLabel,
  initOrgValue,
  orgCallback,
  orgCategoryCallback,
  orgSpecifyCallback,
  orgOrgOptionListCallback,
  orgMulti = false,
  addAllItem = false,
  key = moment()
    .toDate()
    .getTime(),
  styles = {},
  required,
  showFilterCategory = false,
  notSetLoginStaffCode = false,
  showSelectOrg = true,
  orgNameCallback,
  isNotShowCatagoryMobie = false,
  initCatagory,
  clearToggle,
  setClearToggle,
  disabled = false,
  orgFilter,
  isAlignItemsCenter,
  customSelectOrg = false,
  isHide = false,
  selectCheck,
  defaultToggleValue = false,
  defaultCategoryValues = false,
  showSelectOrgStatus = false,
  orgStatus,
  orgStatusCallback,
  isShowSituation= true,
}) => {
    const toggle = sessionStorage.getItem('toggle');
    const getToggleFromSession = sessionStorage.getItem(`sessionOrganizationFileterTree${window.location.pathname}.Toggle`) || ''
    const categoryArrToggleStatus = getToggleFromSession ? JSON.parse(String(getToggleFromSession)).length > 0 : false
    const [isToggleSelect, hasToggleSelect] = React.useState<boolean>(() => {
      return defaultToggleValue || String(toggle) === 'true' || categoryArrToggleStatus
    });
    const {
      state, setter, orgSelectFilter, setOrgSelectFilter,
    } = useOrganizationFilteredTree(
      functionType,
      staffCode,
      initOrgValue,
      orgMulti,
      addAllItem,
      key,
      targetdayForm,
      targetdayTo,
      orgCallback,
      orgCategoryCallback,
      orgFilter,
      orgStatus,
    );

    const accessLevel = sessionStorage.getItem('loginUser.accessLevel');
    const previous = sessionStorage.getItem('pathOrganizationFileterTree') || '';

    const categorySessionSelect = defaultCategoryValues || JSON.parse(String(sessionStorage.getItem(`sessionOrganizationFileterTree${window.location.pathname}${orgFilter !== undefined ? orgFilter : ''}.Selected`)));
    let categorySessionInit = categorySessionSelect;
    const [categoryAttendSelect, setcategoryAttendSelect] = useState(JSON.parse(String(sessionStorage.getItem('attendResult.selectCategory'))));

    if (['attendStampList', 'masterShiftPattern'].indexOf(String(previous).split('/')[1]) !== -1) {
      categorySessionInit = categoryAttendSelect;
    }

    // fix session attend Error
    if (window.location.pathname.split('/')[1] === 'attendError') {
      categorySessionInit = JSON.parse(String(sessionStorage.getItem('attendError.selectCategory')));
    }
    useEffect(() => {
      if ((window.location.pathname.split('/')[1] !== (previous ? previous.split('/')[1] : ''))
        && (['attendStampList', 'attendResult', 'masterShiftPattern']
          .indexOf(String(previous).split('/')[1]) === -1) && previous !== '/attendError'
        && previous !== '/attendStampModificationApplication' && previous !== '/attendHolidayApplication'
        && previous !== '/attendOvertimeApplication' && previous !== '/attendHolidayworkApplication' && previous !== '/attendTransferApplication'
      ) {
        for (const variable in sessionStorage) {
          // eslint-disable-next-line no-prototype-builtins
          if (sessionStorage.hasOwnProperty(variable)) {
            if (variable.search('sessionOrganizationFileterTree') !== -1) {
              sessionStorage.removeItem(variable);
            }
          }
        }
        sessionStorage.setItem('toggle', String(false));
        hasToggleSelect(defaultToggleValue || false);
      }
      sessionStorage.setItem('pathOrganizationFileterTree', window.location.pathname);
    }, [previous]);

    const sessionClearToggle = sessionStorage.getItem('clearToggle');
    if (sessionClearToggle && sessionClearToggle === '1') {
      for (const variable in sessionStorage) {
        // eslint-disable-next-line no-prototype-builtins
        if (sessionStorage.hasOwnProperty(variable)) {
          if (variable.search('sessionOrganizationFileterTree') !== -1) {
            sessionStorage.removeItem(variable);
          }
        }
      }
      sessionStorage.setItem('toggle', String(false));
      setter.setCategorySelect([]);
      setter.setCategoryAry([]);
      sessionStorage.setItem('clearToggle', String(0));
      hasToggleSelect(false);
    }

    //
    if (sessionStorage.getItem('flgHanldeScreenAttendError') && sessionStorage.getItem('flgHanldeScreenAttendError') === '2') {
      setter.setOrgSelect([sessionStorage.getItem('attendError.orgCode') || '']);
      setter.setOrgCode([sessionStorage.getItem('attendError.orgCode') || '']);
      sessionStorage.removeItem('flgHanldeScreenAttendError');
    }

    return (
      <BlockUI blocking={state.blocking}>
        {/* <BodyText customStyle={styles.label}>絞り込み</BodyText> */}
        <FlexBox
          direction="row"
          alignItems="center"
          customStyle={css({
            paddingTop: isMobileOnly ? '0.5rem' : '1rem',
            paddingBottom: isMobileOnly ? '0.5rem' : '1rem',
            flexWrap: 'wrap',
          })}
        >
          {(isMobileOnly && isNotShowCatagoryMobie || isHide)
            ? ('')
            : (
              accessLevel !== '0' || showFilterCategory) && state.options && state.options.length > 0 && (
              <FlexBoxItem customStyle={css({ paddingBottom: isAlignItemsCenter ? '0' : '1rem' })}>
                <BodyText
                  customStyle={
                    styles.label?.toggleSwitch
                    || css({
                      // margin: '0 12px',
                      minWidth: '32px',
                    })
                  }
                >
                  絞り込み
                </BodyText>
              </FlexBoxItem>
            )}

          {(isMobileOnly && isNotShowCatagoryMobie || isHide)
            ? ('')
            : (accessLevel !== '0' || showFilterCategory) && state.options && state.options.length > 0 && (
              <FlexBoxItem
                width={styles.toggle?.width || '20%'}
                customStyle={css({ paddingTop: isAlignItemsCenter ? '0.3rem' : '0', paddingBottom: isAlignItemsCenter ? '0' : '1rem' })}
              >
                <ToggleSwitch
                  name={`toggleSelectOrgCategory${key}`}
                  offLabel=""
                  onLabel=""
                  value="1"
                  checked={isToggleSelect}
                  isMobile={!!isMobile}
                  onChange={() => {
                    hasToggleSelect(!isToggleSelect);
                    if (selectCheck) {
                      selectCheck(!isToggleSelect);
                    }
                    sessionStorage.setItem('toggle', String(!isToggleSelect));
                  }}
                />
              </FlexBoxItem>
            )}

          {showSelectOrg
            && (
              <FlexBoxItem width={isMobileOnly ? '100%' : ''}>
                <OrganizationSelectForm
                  functionType={functionType}
                  label={orgLabel || '店舗名'}
                  values={orgSelectFilter || state.orgSelect}
                  setValues={(args) => {
                    const list = state.orgOptionList;
                    setter.setOrgCode(args);
                    if (!customSelectOrg) {
                      sessionStorage.setItem(`sessionOrgSelect${window.location.pathname}${orgFilter !== undefined ? orgFilter : ''}`, String(args));
                    }
                    if (orgMulti) {
                      if (orgCallback) {
                        orgCallback(args);
                      }
                    } else {
                      if (orgCallback) {
                        orgCallback(String(args));
                      }
                      if (orgSpecifyCallback) {
                        const specify = list.find((item: any) => {
                          if (item.value === args[0]) {
                            return true;
                          }
                          return false;
                        });
                        if (specify) {
                          orgSpecifyCallback(String(specify.specifyCodeValue));
                        }
                      }
                      if (orgNameCallback) {
                        const orgName = list.find((item: any) => {
                          if (item.value === args[0]) {
                            return true;
                          }
                          return false;
                        });
                        if (orgName) {
                          orgNameCallback(String(orgName.name));
                        }
                      }
                    }
                  }}
                  isMulti={orgMulti}
                  isVertical={false}
                  categoryAry={state.categoryAry}
                  getOptionsList={setter.setOrgOptionList}
                  orgOrgOptionListCallback={orgOrgOptionListCallback}
                  addAllItem={addAllItem}
                  required={required}
                  targetDayFrom={targetdayForm}
                  targetDayTo={targetdayTo}
                  notSetLoginStaffCode={notSetLoginStaffCode}
                  disabled={disabled}
                  orgStatus={orgStatus}
                  setOrgStatus={orgStatusCallback}
                  isShowSituation={isShowSituation}
                />
              </FlexBoxItem>
            )}
        </FlexBox>
        {isToggleSelect && state.options && state.options.length > 0 && (
          <OrganizationCategorySelect
            optionsList={state.options}
            label=""
            values={categorySessionInit || []}
            setValues={(args: any) => {
              const tempList: Array<any> = [];
              state.options.forEach((item: any) => {
                setter.setBlocking(true);
                const categoryTempList: Array<any> = [];
                item.options.filter((option: OptionType) => {
                  if (args.includes(option.value)) {
                    categoryTempList.push(option.value);
                    return true;
                  }
                  return false;
                });
                if (categoryTempList.length) {
                  tempList.push(categoryTempList);
                }
              });
              if (orgCategoryCallback) {
                orgCategoryCallback(tempList);
              }
              if (initCatagory) {
                initCatagory(args);
              }

              setter.setBlocking(false);
              setter.setCategorySelect(args);

              sessionStorage.setItem(`sessionOrganizationFileterTree${window.location.pathname}${orgFilter !== undefined ? orgFilter : ''}.Selected`, JSON.stringify(args));
              sessionStorage.setItem('toggle', String(true));
              setter.setCategoryAry(tempList);
              setcategoryAttendSelect(args);
              sessionStorage.setItem(`sessionOrganizationFileterTree${window.location.pathname}${orgFilter !== undefined ? orgFilter : ''}.Toggle`, JSON.stringify(tempList));
            }}
            isVertical={false}
            isMulti={true}
            isSplitted={true}
          />
        )}
      </BlockUI>
    );
  };
export default OrganizationFilteredTree;
