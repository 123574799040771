/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import ClickableLink from 'components/atoms/ClickableLink';
import productColor, { textFontSize, grayScale } from 'components/styles';
import BodyText from 'components/atoms/BodyText';

const style = css({
  padding: '3px 5px',
  backgroundColor: '#ffff',
  fontSize: textFontSize.re,
  ':hover': {
    backgroundColor: productColor.primaryM95,
  },
});

export interface ContextMenuItemProps {
  /**
   * 表示名
   */
  label: string;
  /**
   * リンク先
   */
  linkTo?: string;
  /**
   * リンクでなければ必須
   */
  onClick?: () => void;
  disabled?: boolean;
  icon?: React.ReactNode
}

const ContextMenuItem: React.FC<ContextMenuItemProps> = ({
  label,
  linkTo,
  onClick,
  disabled = false,
  icon
}) => (
    <div css={style}>
      {disabled
        ? <BodyText customStyle={css({padding : '6px 8px', display: 'block'})} color={grayScale.gray50}>{label}</BodyText>
        : <ClickableLink to={linkTo} onClick={onClick} color={grayScale.gray100}>
          {icon} {label}
        </ClickableLink>}
    </div>
  );

export default ContextMenuItem;
