/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import Table from 'components/molecules/Table';
import BodyText from 'components/atoms/BodyText';
import moment from 'moment';
import { utilityColor } from 'components/styles';
import { IApplyResult } from '../../interface';

const headers = [
  '結果',
  '対象日',
  '種別',
  '決裁日',
  '決裁者',
  '申請日',
  '否決コメント',
];

const styles = {
  table: css`
    table {
      margin-top: 15px;
      th {
        white-space: nowrap;
      }
      min-width: 686px;
    }
    table th:nth-child(1) {
      width: 40px;
    }
    table th:nth-child(2) {
      width: 80px;
    }
    table th:nth-child(3) {
      width: 80px;
    }
    table th:nth-child(4) {
      width: 80px;
    }
    table th:nth-child(5) {
      width: 120px;
    }
    table th:nth-child(6) {
      width: 80px;
    }
    table th:nth-child(7) {
      width: 150px;
    }
  `,
};

const Result: React.FC<{ list: IApplyResult[] }> = ({ list }) => (
  <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
    <div css={styles.table}>
      <Table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {list.map((cell, index) => (
            <tr key={index}>
              <Table.Cell customStyle={css({ textAlign: 'left' })}>
                <BodyText
                  color={
                    cell.appicationResult === '否決' ? utilityColor.error : ''
                  }
                >
                  {cell.appicationResult}
                </BodyText>
              </Table.Cell>
              <Table.Cell customStyle={css({ textAlign: 'left' })}>{moment(cell.targetDate).format('YYYY/MM/DD')}</Table.Cell>
              <Table.Cell customStyle={css({ textAlign: 'left' })}>{cell.appicationTypeName}</Table.Cell>
              <Table.Cell customStyle={css({ textAlign: 'left' })}>
                {moment(cell.approvalDate).format('YYYY/MM/DD')}
              </Table.Cell>
              <Table.Cell>{cell.approvalStaffName}</Table.Cell>
              <Table.Cell customStyle={css({ textAlign: 'left' })}>
                {moment(cell.applicationDate).format('YYYY/MM/DD')}
              </Table.Cell>
              <Table.Cell customStyle={css({ textAlign: 'left' })}>{cell.rejectReason}</Table.Cell>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  </div>
);
export default Result;
