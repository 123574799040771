/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  eqeqeq */
import React, {
  useState,
  useRef,
  RefObject,
  useEffect,
  createContext,
  useContext
} from "react";
import {
  MonthlyShiftContextType,
  IMonthlyShift,
  StaffInfo,
  IShopPlan,
  ShiftPattern,
  EmploymentOptionType,
  MonthlyShiftPage
} from "../type";
import moment from "moment";
import useToastNotification from "hooks/useToastNotification";
import { ContextMenuType } from "components/molecules/ContextMenu/type";
import { useHistory } from "react-router-dom";
import Icon from "components/atoms/Icon";
import {
  fetchMonthlyShift,
  fetchDrawingInfo,
  downloadMonthlyShiftPdf,
  downloadMonthlyShiftExcel,
  getDailyAddStaffs,
} from "../api";
import { addShiftPattern } from "api/shiftPattern";
import {
  deleteShiftV1,
  ConfirmType,
  confirmMonthlyLockOutV1,
  registerHoliday,
  copyFromPreviousDay
} from "api/shift";
import { shiftConfirmReleaseV1, shiftConfirmV1 } from "api/shiftConfirm";
import { getEmploymentSelectList } from "api/employment";
import { OptionType } from "components/atoms/Select";
import { useParams } from 'react-router-dom';

export const monthlyShiftContext = createContext<MonthlyShiftContextType>(
  {} as MonthlyShiftContextType
);

export const useMonthlyShiftContext = (): MonthlyShiftContextType => {
  const context = useContext(monthlyShiftContext);
  return context;
};

export const useInitialState = () => {
  const params = useParams<any>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isMonthlyTableLoading, setMonthlyTableLoading] = useState<boolean>(
    true
  );
  const [remote, setRemote] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [loadEmploymentDone, setLoadEmploymentDone] = useState<boolean>(false);
  const [checkall, setCheckall] = useState<boolean>(
    sessionStorage.getItem(
      `0/sessionData${window.location.pathname}.employmentOptions`
    ) === "all"
  );
  const { errorNotification, successNotification } = useToastNotification();
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const [orgCodeModal, setOrgCodeModal] = useState("");
  const history = useHistory();

  /* -------------------------------------------------------------------------- */
  /*                                Filter states                               */
  /* -------------------------------------------------------------------------- */
  const [dateFromStr, setDateFromStr] = useState<string>(
    params.targetDate || sessionStorage.getItem("attendStampList.monthlyShift.targetDateFrom") || moment().startOf("day").format("YYYY-MM-DD")
  );
  const [dateToStr, setDateToStr] = useState<string>(
    sessionStorage.getItem("attendStampList.monthlyShift.targetDateTo") || ""
  );
  const [viewPeriod, setViewPeriod] = useState<string>(
    sessionStorage.getItem(
      `0/sessionData${window.location.pathname}.viewPeriod`
    ) || "weekly"
  );
  const [isAttendExists, setAttendExists] = useState<string>(
    sessionStorage.getItem("attendStampList.attendFlag") || "0"
  );
  const [orgs, setOrgs] = useState<OptionType[]>([]);
  const [pageTotal, setPageTotal] = useState<Number>(0);
  // const [pageIndex, setPageIndex] = useState(Number(sessionStorage.getItem('monthly_shift_current_page_index')) || 0);
  console.log(
    "Number(sessionStorage.getItem('monthly_shift_current_page_index'))",
    Number(sessionStorage.getItem("monthly_shift_current_page_index"))
  );
  const [pageIndex, setPageIndex] = useState<number>(
    Number(sessionStorage.getItem("monthly_shift_current_page_index")) || 0
  );

  const [selectedOrg, setSelectedOrg] = useState<string>(
    params.orgCode || sessionStorage.getItem("sessionOrgSelect/monthlyShift") ||
      sessionStorage.getItem("loginUser.orgCode") ||
      ""
  );

  const [selectedOrgName, setSelectedOrgName] = useState<string>(
    params.orgName?.replace('%2F', '/') || sessionStorage.getItem("monthlyShift.orgName") ||
      sessionStorage.getItem("loginUser.orgName") ||
      ""
  );

  const [employmentOptions, setEmploymentOptions] = useState<
    EmploymentOptionType[]
  >([]);

  /* -------------------------------------------------------------------------- */
  /*                               Calendar states                              */
  /* -------------------------------------------------------------------------- */
  const [selectedItem, setSelectedItem] = useState<any>();
  const [selectedCell, setSelectedCell] = useState<any>();
  const [monthlyShifts, setMonthlyShifts] = useState<IMonthlyShift>(
    {} as IMonthlyShift
  );
  const [staffs, setStaffs] = useState<StaffInfo[]>([]);
  const [pages, setPages] = useState<any>({});
  const [shopPlan, setShopPlan] = useState<IShopPlan[]>([]);
  const [openDailyShiftConfirmModal, setDailyShiftConfirmModal] = useState<
    boolean
  >(false);
  const [selectedDay, setSelectedDay] = useState<string>("");

  /* -------------------------------------------------------------------------- */
  /*                          ShiftPattern modal states                         */
  /* -------------------------------------------------------------------------- */
  const [openShiftPatternModal, setShiftPatternModal] = useState<boolean>(
    false
  );
  const [shiftPatternOptions, setShiftPatternOptions] = useState<
    ShiftPattern[]
  >([]);
  const [achievementConfirm, setAchievementConfirm] = useState<ConfirmType>(
    {} as ConfirmType
  );
  const [shiftConfirm, setShiftConfirm] = useState<ConfirmType>(
    {} as ConfirmType
  );
  const [openShiftPatternStaffModal, setShiftPatternStaffModal] = useState<
    boolean
  >(false);

  /* -------------------------------------------------------------------------- */
  /*                             ContextMenu states                             */
  /* -------------------------------------------------------------------------- */
  const [isPerformanceMenuVisible, setPerformanceMenuVisible] = useState<
    boolean
  >(false);
  const [isItemMenuVisible, setItemMenuVisible] = useState<boolean>(false);
  const [isTimelineMenuVisible, setTimelineMenuVisible] = useState<boolean>(
    false
  );
  const contextMenuRef = useRef() as RefObject<any>;
  const [contextMenuX, setContextMenuX] = useState<number>(0);
  const [contextMenuY, setContextMenuY] = useState<number>(0);
  const [menuTimelineButtons, setMenuTimelineButtons] = useState<
    ContextMenuType[]
  >([]);
  const [menuPerformanceButtons, setMenuPerformanceButtons] = useState<
    ContextMenuType[]
  >([]);
  const [menuItemButtons, setMenuItemButtons] = useState<ContextMenuType[]>([]);

  /* -------------------------------------------------------------------------- */
  /*                              CALENDAR FUNCTIONS                            */
  /* -------------------------------------------------------------------------- */
  const deleteItem = async (shiftId: string) => {
    setLoading(true);
    let updateUser = sessionStorage.getItem("loginUser.staffName")!;

    try {
      await deleteShiftV1(shiftId, updateUser);
      await reFetchMonthlyShifts();
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const submitShiftLockout = async () => {
    if (shiftConfirm.isConfirmedAllDays) {
      await shiftConfirmReleaseV1(
        selectedOrg,
        moment(dateFromStr).format("YYYY/MM/DD"),
        moment(dateToStr).format("YYYY/MM/DD")
      );
      successNotification("シフト確定を解除しました。");
    } else {
      let targetStaffCodes = staffs.map(staff => staff.staffCode);
      await shiftConfirmV1(
        selectedOrg,
        moment(dateFromStr).format("YYYY/MM/DD"),
        moment(dateToStr).format("YYYY/MM/DD"),
        targetStaffCodes
      );
      successNotification("シフトを確定しました。");
    }
    confirmMonthlyLockOutV1(selectedOrg, dateFromStr, dateToStr).then(
      response => {
        setAchievementConfirm(response.achievementConfirm);
        setShiftConfirm(response.shiftConfirm);
      }
    );
    setOpenConfirmModal(false);
  };

  const submitDayShiftLockout = async () => {
    try {
      setDailyShiftConfirmModal(false);
      setLoading(true);
      let targetStaffCodes = staffs.map(staff => staff.staffCode);
      await shiftConfirmV1(
        selectedOrg,
        selectedDay,
        selectedDay,
        targetStaffCodes
      );
      await reFetchMonthlyShifts(true);
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const reFetchMonthlyShifts = async (
    fetchDrawingData: boolean = false,
    staffDailyShift?: any
  ) => {
    if (fetchDrawingData) {
      confirmMonthlyLockOutV1(selectedOrg, dateFromStr, dateToStr).then(
        response => {
          setAchievementConfirm(response.achievementConfirm);
          setShiftConfirm(response.shiftConfirm);
        }
      );
    }
    let employmentIds = employmentOptions.reduce(
      (prev, current) => (current.checked ? `${current.id},${prev}` : prev),
      "1"
    );
    let page_index =
      Number(sessionStorage.getItem("monthly_shift_current_page_index")) || 0;
    let page_size =
      Number(sessionStorage.getItem("monthly_shift_current_page_size")) || 50;
    let monthlyData = await fetchMonthlyShift(
      dateFromStr,
      dateToStr,
      selectedOrg,
      isAttendExists,
      employmentIds,
      staffDailyShift,
      page_index,
      page_size
    );
    setStaffs(monthlyData.staffInfo);
    setPages(monthlyData.page);
    setPageIndex(monthlyData.page.pageNumber || 0);
    setMonthlyShifts(monthlyData);
  };

  const downloadPdf = async () => {
    setLoading(true);
    let employmentIds = employmentOptions.reduce(
      (totalStr, current) =>
        current.checked ? `${current.id},${totalStr}` : totalStr,
      ""
    );
    downloadMonthlyShiftPdf(
      selectedOrg,
      dateFromStr,
      dateToStr,
      employmentIds,
      isAttendExists
    )
      .catch(error => {
        errorNotification(
          "対象月、対象組織において在籍するスタッフがいませんでした。"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const downloadExcel = async () => {
    setLoading(true);
    let employmentIds = employmentOptions.reduce(
      (totalStr, current) =>
        current.checked ? `${current.id},${totalStr}` : totalStr,
      ""
    );
    downloadMonthlyShiftExcel(
      selectedOrg,
      dateFromStr,
      dateToStr,
      employmentIds,
      isAttendExists
    )
      .catch(error => {
        errorNotification(
          "対象月、対象組織において在籍するスタッフがいませんでした。"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onClickAttendStampList = (staff: StaffInfo) => {
    sessionStorage.setItem("attendStampList.orgCode", selectedOrg);
    sessionStorage.setItem("attendStampList.staffCode", staff.staffCode);
    sessionStorage.setItem(
      "attendStampList.targetDateFrom",
      moment(dateFromStr).format("YYYY-MM-DD")
    );
    sessionStorage.setItem(
      "attendStampList.targetDateTo",
      moment(dateToStr).format("YYYY-MM-DD")
    );
    sessionStorage.setItem("attendStampList.closingDate", dateToStr);
    sessionStorage.setItem("attendStampList.employmentId", staff.employmentId);
    sessionStorage.setItem("attendStampList.attendFlag", isAttendExists);
    sessionStorage.setItem(
      `0/sessionData${window.location.pathname}.viewPeriod`,
      viewPeriod
    );
    sessionStorage.setItem(
      "attendStampList.returnDestination",
      window.location.pathname
    );
    sessionStorage.setItem('headquartersFinal.monthlyList.returnDestination', '/monthlyShift');
    history.push(`/attendStampList`);
  };

  /* -------------------------------------------------------------------------- */
  /*                           SHIFTPATTERN FUNCTIONS                           */
  /* -------------------------------------------------------------------------- */
  const getWorkdayOptions = (
    distinctionHoliday: boolean,
    staffOtherOrg?: boolean
  ) => {
    if (staffOtherOrg) {
      return [{ value: "normal", label: "通常" }];
    }
    console.log('distinctionHoliday', distinctionHoliday);
    if (distinctionHoliday) {
      return [
        { value: "normal", label: "通常" },
        { value: "legal", label: "公休（法定休日）" },
        { value: "scheduled", label: "公休（所定休日）" }
      ];
    }

    return [
      { value: "normal", label: "通常" },
      { value: "legal", label: "公休" }
    ];
  };

  /* -------------------------------------------------------------------------- */
  /*                            CONTEXTMENU FUNCTIONS                           */
  /* -------------------------------------------------------------------------- */
  const handleClickOutside = (event: any) => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target)
    ) {
      setPerformanceMenuVisible(false);
      setItemMenuVisible(false);
      setTimelineMenuVisible(false);
    }
  };

  const getMenuPerformanceButtons = (
    modifierApplicationId: any,
    orgCode: string,
    staffCode: string,
    staffName: string,
    modifierApplicationStatus: number | null,
    holidayApplicationStatus: number | null,
    overworkApplicationStatus: number | null,
    holidayworkApplicationStatus: number | null,
    transferApplicationStatus: number | null,
    attendanceOrgFix: string,
    useOverTimeApplication: any,
    necessaryHolidayWorkApplication: any,
    useTransferApplication: any,
    achievementId: any,
    targetDate: any,
    stampVersion: any,
    dispStaffName?: string,
    filterTargetDateFrom?: Date,
    filterTargetDateTo?: Date,
    filterOrgCode?: string,
    filterStaffCode?: string,
    belongOrgFix?: string,
    applying?: any,
    holidayId?: any,
    isHolidayWork?: any
  ) => {
    const isHoliday = holidayId && !isHolidayWork;

    // TODO:  isSelectableで使用している以下の項目はレスポンスで返していないプロパティ
    //        holidayApplicationStatus、modifierApplicationStatus、attendanceOrgFix
    //        返していない理由が不明なため精査が必要
    const stampAmendmentButton: ContextMenuType = {
      isRed: modifierApplicationStatus === 0,
      isSelectable:
        ((achievementId !== null && (holidayApplicationStatus === null || holidayApplicationStatus === undefined)) ||
          modifierApplicationStatus !== null ||
          attendanceOrgFix === "1") &&
        !isHoliday,
      label: "修正申請",
      isHidden: false,
      icon: <Icon type="edit" color="black" size="16px" verticalAlign="sub" />,
      stamptype: 1,
      onClick: () => {
        const link = `/monthlyShift/attendStampModificationApplication`;
        setSessionStorage(link, stampAmendmentButton.stamptype, targetDate);
        history.push(link);
      }
    };

    const stampAddButton: ContextMenuType = {
      isRed: false,
      isSelectable: true,
      label: "追加申請",
      isHidden: false,
      stamptype: 0,
      onClick: () => {
        const link = `/monthlyShift/attendStampModificationApplication`;
        setSessionStorage(link, stampAddButton.stamptype, targetDate);
        history.push(link);
      }
    };

    const stampButtons: ContextMenuType = {
      isRed: false,
      isSelectable: true,
      label: "打刻修正申請",
      isHidden: false,
      stamptype: 1,
      subMenu: [stampAmendmentButton, stampAddButton]
    };

    const applyLeaveButton: ContextMenuType = {
      isRed: holidayApplicationStatus === 0,
      isSelectable: true,
      label: "休暇申請",
      isHidden: false,
      stamptype: 0,
      onClick: () => {
        const link = `/monthlyShift/attendHolidayApplication`;
        setSessionStorage(link, applyLeaveButton.stamptype, targetDate);
        history.push(link);
      }
    };

    const applyOvertimeButton: ContextMenuType = {
      isRed: overworkApplicationStatus === 0,
      isSelectable:
        overworkApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: useOverTimeApplication === 0,
      label: "残業申請",
      stamptype: 0,
      onClick: () => {
        const link = `/monthlyShift/attendOvertimeApplication`;
        setSessionStorage(link, applyOvertimeButton.stamptype, targetDate);
        history.push(link);
      }
    };

    const applyHolidayWorkButton: ContextMenuType = {
      isRed: holidayworkApplicationStatus === 0,
      isSelectable:
        holidayworkApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: necessaryHolidayWorkApplication === false,
      label: "休日出勤申請",
      stamptype: 0,
      onClick: () => {
        const link = `/monthlyShift/attendHolidayworkApplication`;
        setSessionStorage(link, applyHolidayWorkButton.stamptype, targetDate);
        history.push(link);
      }
    };

    const applyTransferButton: ContextMenuType = {
      isRed: transferApplicationStatus === 0,
      isSelectable:
        transferApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: useTransferApplication === 0,
      label: "振替申請",
      stamptype: 0,
      onClick: () => {
        const link = `/monthlyShift/attendTransferApplication`;
        setSessionStorage(
          link || "",
          applyTransferButton.stamptype,
          targetDate
        );
        history.push(link);
      }
    };

    const setSessionStorage = (
      link: string,
      stamptype: number,
      targetDate: string
    ) => {
      // 自画面用パラメータ
      if (filterTargetDateFrom) {
        sessionStorage.setItem(
          "attendStampList.targetDateFrom",
          moment(filterTargetDateFrom).format("YYYY-MM-DD")
        );
        sessionStorage.setItem(
          "attendStampList.targetDateTo",
          moment(filterTargetDateTo).format("YYYY-MM-DD")
        );
        sessionStorage.setItem(
          "attendStampList.orgCode",
          String(filterOrgCode)
        );
        sessionStorage.setItem(
          "attendStampList.staffCode",
          String(filterStaffCode)
        );
      }

      // Common parameters
      if (orgCode) {
        sessionStorage.setItem("application.orgCode", orgCode);
      } else if (filterOrgCode) {
        sessionStorage.setItem("application.orgCode", filterOrgCode);
      }
      sessionStorage.setItem("application.staffCode", String(staffCode));
      if (dispStaffName) {
        sessionStorage.setItem(
          "application.dispStaffName",
          String(dispStaffName)
        );
      } else {
        sessionStorage.setItem(
          "application.dispStaffName",
          `${staffCode} ${staffName}`
        );
      }
      sessionStorage.setItem(
        "application.targetDate",
        moment(targetDate).format("YYYY-MM-DD")
      );
      sessionStorage.setItem(
        "application.returnDestination",
        window.location.pathname
      );
      sessionStorage.setItem(
        "attendStampList.returnDestination",
        window.location.pathname
      );

      switch (link) {
        case `/monthlyShift/attendStampModificationApplication`:
          sessionStorage.removeItem("application.achievementId");
          sessionStorage.removeItem("application.stampVersion");
          sessionStorage.removeItem("application.modifierApplicationId");

          // if( modifierApplicationId ){
          if (stamptype === 1) {
            // 修正申請
            sessionStorage.setItem(
              "application.achievementId",
              achievementId || ""
            );
            sessionStorage.setItem(
              "application.stampVersion",
              stampVersion || ""
            );
            sessionStorage.setItem(
              "application.modifierApplicationId",
              modifierApplicationId || ""
            );
          }
          break;

        case `/monthlyShift/attendHolidayApplication`:
          sessionStorage.setItem("application.achievementId", achievementId);
          break;

        default:
          // do nothing
          break;
      }
    };

    return [
      stampButtons,
      applyLeaveButton,
      applyOvertimeButton,
      applyHolidayWorkButton,
      applyTransferButton
    ];
  };

  const getMenuItemButtons = (
    staffCode: string,
    staffName: string,
    targetDate: string,
    shiftPatternOptions: ShiftPattern[],
    distinctionHoliday: boolean,
    closingHour: number
  ): ContextMenuType[] => {
    const editBtn = {
      isRed: false,
      isSelectable: true,
      label: "シフトを編集する",
      isHidden: false,
      icon: <Icon type="edit" color="black" size="16px" verticalAlign="sub" />,
      stamptype: 0,
      onClick: () => {
        setShiftPatternModal(true);
      }
    };
    const deleteBtn = {
      isRed: false,
      isSelectable: true,
      label: "シフトを削除する",
      isHidden: false,
      icon: (
        <Icon type="delete" color="black" size="16px" verticalAlign="sub" />
      ),
      stamptype: 0,
      onClick: () => {
        setOpenDeleteModal(true);
      }
    };

    return [
      editBtn,
      deleteBtn,
      ...getMenuTimelineButtons(
        staffCode,
        staffName,
        targetDate,
        shiftPatternOptions,
        distinctionHoliday,
        closingHour,
      )
    ];
  };

  const getOtherOrgStaffMenuTimelineButtons = (
    staffCode: string,
    targetDate: string,
    shiftPatternOptions: ShiftPattern[]
  ): ContextMenuType[] => {
    const addShift = {
      label: "シフトを追加する",
      isRed: false,
      isSelectable: true,
      stamptype: 0,
      onClick: () => {
        setSelectedItem(null);
        setShiftPatternStaffModal(true);
      }
    };

    const addFromShiftPattern = {
      label: "シフトパターンから追加する",
      isRed: false,
      isSelectable: true,
      stamptype: 0,
      subMenu:
        shiftPatternOptions.length > 0
          ? shiftPatternOptions.map(pattern => ({
              label: pattern.shiftPatternName,
              isRed: false,
              isSelectable: true,
              stamptype: 0,
              onClick: () => {
                handleAddFromShiftPattern(
                  pattern.shiftPatternId,
                  staffCode,
                  targetDate
                );
              }
            }))
          : [
              {
                label: "データがありません",
                isRed: false,
                isSelectable: false,
                stamptype: 0
              }
            ]
    };

    const copy = {
      label: "前日のコピー",
      isRed: false,
      isSelectable: true,
      stamptype: 0,
      onClick: async () => {
        let user = sessionStorage.getItem("loginUser.staffName")!;
        try {
          setLoading(true);
          await copyFromPreviousDay(
            selectedOrg,
            staffCode,
            moment(targetDate).format("YYYY/MM/DD"),
            user
          );
          await reFetchMonthlyShifts();
        } catch (error) {
          errorNotification(error.response.data.message);
        } finally {
          setLoading(false);
        }
      }
    };

    return [addShift, addFromShiftPattern, copy];
  };

  const getOtherOrgStaffMenuItemButtons = (
    staffCode: string,
    targetDate: string,
    shiftPatternOptions: ShiftPattern[]
  ): ContextMenuType[] => {
    const editBtn = {
      isRed: false,
      isSelectable: true,
      label: "シフトを編集する",
      isHidden: false,
      icon: <Icon type="edit" color="black" size="16px" verticalAlign="sub" />,
      stamptype: 0,
      onClick: () => {
        setShiftPatternModal(true);
      }
    };
    const deleteBtn = {
      isRed: false,
      isSelectable: true,
      label: "シフトを削除する",
      isHidden: false,
      icon: (
        <Icon type="delete" color="black" size="16px" verticalAlign="sub" />
      ),
      stamptype: 0,
      onClick: () => {
        setOpenDeleteModal(true);
      }
    };

    return [
      editBtn,
      deleteBtn,
      ...getOtherOrgStaffMenuTimelineButtons(
        staffCode,
        targetDate,
        shiftPatternOptions
      )
    ];
  };

  const getMenuTimelineButtons = (
    staffCode: string,
    staffName: string,
    targetDate: string,
    shiftPatternOptions: ShiftPattern[],
    distinctionHoliday: boolean,
    closingHour: number,
  ): ContextMenuType[] => {
    const addShift = {
      label: "シフトを追加する",
      isRed: false,
      isSelectable: true,
      stamptype: 0,
      onClick: () => {
        setSelectedItem(null);
        setShiftPatternModal(true);
        sessionStorage.setItem('staff.closingHour', String(closingHour));
      }
    };
    const takeHoliday = distinctionHoliday
      ? {
          label: "公休にする",
          isRed: false,
          isSelectable: true,
          stamptype: 0,
          subMenu: [
            {
              label: "法定休日",
              isRed: false,
              isSelectable: true,
              stamptype: 0,
              onClick: async () => {
                let user = sessionStorage.getItem("loginUser.staffName")!;
                try {
                  setLoading(true);
                  await registerHoliday(
                    selectedOrg,
                    staffCode,
                    moment(targetDate).format("YYYY/MM/DD"),
                    true,
                    user,
                    user
                  );
                  await reFetchMonthlyShifts();
                } catch (error) {
                  errorNotification(error.response.data.message);
                } finally {
                  setLoading(false);
                }
              }
            },
            {
              label: "所定休日",
              isRed: false,
              isSelectable: true,
              stamptype: 0,
              onClick: async () => {
                // let user = sessionStorage.getItem("loginUser.staffName")!;
                try {
                  setLoading(true);
                  await registerHoliday(
                    selectedOrg,
                    staffCode,
                    moment(targetDate).format("YYYY/MM/DD"),
                    false,
                    staffName,
                    staffName
                  );
                  await reFetchMonthlyShifts();
                } catch (error) {
                  errorNotification(error.response.data.message);
                } finally {
                  setLoading(false);
                }
              }
            }
          ]
        }
      : {
          label: "公休にする",
          isRed: false,
          isSelectable: true,
          stamptype: 0,
          onClick: async () => {
            try {
              setLoading(true);
              await registerHoliday(
                selectedOrg,
                staffCode,
                moment(targetDate).format("YYYY/MM/DD"),
                true,
                staffName,
                staffName
              );
              await reFetchMonthlyShifts();
            } catch (error) {
              errorNotification(error.response.data.message);
            } finally {
              setLoading(false);
            }
          }
        };

    const addFromShiftPattern = {
      label: "シフトパターンから追加する",
      isRed: false,
      isSelectable: true,
      stamptype: 0,
      subMenu:
        shiftPatternOptions.length > 0
          ? shiftPatternOptions.map(pattern => ({
              label: pattern.shiftPatternName,
              isRed: false,
              isSelectable: true,
              stamptype: 0,
              onClick: () => {
                handleAddFromShiftPattern(
                  pattern.shiftPatternId,
                  staffCode,
                  targetDate
                );
              }
            }))
          : [
              {
                label: "データがありません",
                isRed: false,
                isSelectable: false,
                stamptype: 0
              }
            ]
    };

    const copy = {
      label: "前日のコピー",
      isRed: false,
      isSelectable: true,
      stamptype: 0,
      onClick: async () => {
        let user = sessionStorage.getItem("loginUser.staffName")!;
        try {
          setLoading(true);
          await copyFromPreviousDay(
            selectedOrg,
            staffCode,
            moment(targetDate).format("YYYY/MM/DD"),
            user
          );
          await reFetchMonthlyShifts();
        } catch (error) {
          errorNotification(error.response.data.message);
        } finally {
          setLoading(false);
        }
      }
    };

    return [addShift, takeHoliday, addFromShiftPattern, copy];
  };

  const handleAddFromShiftPattern = async (
    shiftPatternId: string,
    staffCode: string,
    targetDate: string
  ) => {
    let createUser = sessionStorage.getItem("loginUser.staffName")!,
      updateUser = sessionStorage.getItem("loginUser.staffName")!;
    setLoading(true);
    try {
      await addShiftPattern(
        selectedOrg,
        staffCode,
        moment(targetDate).format("YYYY/MM/DD"),
        shiftPatternId,
        createUser,
        updateUser
      );
      await reFetchMonthlyShifts();
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };



  const [appendStaffs, setAppendStaffs] = useState<Array<string>>([]);

  const handleAddStaffMonthly = async (staffCode: any) => {
    setLoading(true);
    try {
      const staffList = appendStaffs;
      staffList.push(staffCode);
      reFetchMonthlyShifts(true, staffList);
      setAppendStaffs(staffList);
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setAppendStaffs([]);
  }, [selectedOrg]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchDrawingData = async () => {
      setLoading(true);

      let { dateFrom, dateTo } = await fetchDrawingInfo(
        selectedOrg,
        dateFromStr || "",
        viewPeriod
      );
      setDateFromStr(dateFrom);
      if (dateFromStr) {
        setDateToStr(dateTo);
      }
    };

    fetchDrawingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFromStr, viewPeriod]);

  useEffect(() => {
    const fetchEmploymentOptions = async () => {
      let employmentList = await getEmploymentSelectList();
      let sessionData = sessionStorage.getItem(
        `0/sessionData${window.location.pathname}.employmentOptions`
      );
      let sessionList: any = [];
      if (sessionData) {
        sessionList = sessionData?.split(",");
      }
      let formatOptionType = employmentList.map(opt => ({
        id: opt.employmentId,
        label: opt.employmentName,
        value: opt.employmentCode,
        checked:
          sessionList.includes(opt.employmentCode) ||
          sessionList.length === 0 ||
          sessionList.includes("all")
      }));
      setLoadEmploymentDone(true);
      setEmploymentOptions(formatOptionType);
    };
    fetchEmploymentOptions();
  }, []);

  return {
    remote,
    setRemote,
    isLoading,
    setLoading,
    isMonthlyTableLoading,
    setMonthlyTableLoading,
    openConfirmModal,
    setOpenConfirmModal,
    openRegisterModal,
    setOpenRegisterModal,
    openDeleteModal,
    setOpenDeleteModal,
    companyCode,
    history,
    loadEmploymentDone,
    orgCodeModal,
    setOrgCodeModal,
    handleAddStaffMonthly,
    filters: {
      dateFromStr,
      setDateFromStr,
      dateToStr,
      setDateToStr,
      isAttendExists,
      setAttendExists,
      selectedOrgName,
      selectedOrg,
      setSelectedOrg,
      employmentOptions,
      setEmploymentOptions,
      orgs,
      setOrgs,
      viewPeriod,
      setViewPeriod,
      checkall,
      setCheckall,
      pageTotal,
      setPageTotal,
      pageIndex,
      setPageIndex
    },
    monthlyCalendar: {
      monthlyShifts,
      setMonthlyShifts,
      staffs,
      setStaffs,
      pages,
      setPages,
      shopPlan,
      setShopPlan,
      deleteItem,
      selectedItem,
      setSelectedItem,
      selectedCell,
      setSelectedCell,
      achievementConfirm,
      setAchievementConfirm,
      shiftConfirm,
      setShiftConfirm,
      submitShiftLockout,
      submitDayShiftLockout,
      downloadPdf,
      downloadExcel,
      reFetchMonthlyShifts,
      onClickAttendStampList,
      openDailyShiftConfirmModal,
      setDailyShiftConfirmModal,
      selectedDay,
      setSelectedDay
    },
    contextMenu: {
      isPerformanceMenuVisible,
      setPerformanceMenuVisible,
      openShiftPatternModal,
      setShiftPatternModal,
      isItemMenuVisible,
      setItemMenuVisible,
      isTimelineMenuVisible,
      setTimelineMenuVisible,
      contextMenuRef,
      contextMenuX,
      setContextMenuX,
      contextMenuY,
      setContextMenuY,
      menuTimelineButtons,
      setMenuTimelineButtons,
      menuPerformanceButtons,
      setMenuPerformanceButtons,
      menuItemButtons,
      setMenuItemButtons,
      getMenuPerformanceButtons,
      getMenuItemButtons,
      getOtherOrgStaffMenuItemButtons,
      getMenuTimelineButtons,
      getOtherOrgStaffMenuTimelineButtons,
      openShiftPatternStaffModal,
      setShiftPatternStaffModal
    },
    shiftPattern: {
      shiftPatternOptions,
      setShiftPatternOptions,
      openShiftPatternModal,
      setShiftPatternModal,
      getWorkdayOptions,
      openShiftPatternStaffModal,
      setShiftPatternStaffModal
    }
  };
};
