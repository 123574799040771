import React from 'react';
import { useParams } from 'react-router-dom';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MediaMasterCreateForm from 'components/organismos/master/sales/MediaMasterCreateForm';

const MediaMasterCreatePage: React.FC = () => {
  const {paymentMediaMstCode}= useParams();
  return (
    <SidebarTemplate pageTitle={paymentMediaMstCode ? '支払種別編集' : '支払種別作成'}>
      <MediaMasterCreateForm />
    </SidebarTemplate>
  );
};

export default MediaMasterCreatePage;
