/** @jsx jsx */
import FormContents from 'components/atoms/Form/FormContents';
import { jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import DatePickerForm from 'components/molecules/DatePickerForm';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import BlockUI from 'components/molecules/BlockUi';
import useDailySalesManage from './hook';
import DailySalesManagementTable from './DaySeparateReportLayoutTable';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const DaySeparateReportLayout = () => {
  const {
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    functionType,
    staffCode,
    orgCode,
    setorgName,
    setOrgCode,
    categoryAry,
    setCategoryAry,
    isLoading,
    setIsLoading,
    dailyReport,
    getMediaMst,
    getCreditMst,
  } = useDailySalesManage();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox>
          <FlexBoxItem width="100px">
            <FormLabel
              label="営業日"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label=""
              date={targetDateFrom}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDateFrom(date);
                }
              }}
              isFullWidth={true}
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <span style={{ margin: '0 30px' }}>～</span>
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label=""
              date={targetDateTo}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setTargetDateTo(date);
                }
              }}
              isFullWidth={true}
            />
          </FlexBoxItem>
        </FlexBox>
        <FlexBox>
          <FlexBoxItem width="50%">
            <OrganizationFilteredTree
              orgLabel="組織"
              isAlignItemsCenter={true}
              functionType={functionType}
              staffCode={staffCode}
              addAllItem={true}
              initOrgValue={String(orgCode)}
              targetdayForm={targetDateFrom}
              targetdayTo={targetDateTo}
              orgCallback={(args: any) => {
                setOrgCode(String(args));
              }}
              orgCategoryCallback={(args: Array<string>) => {
                setCategoryAry(args);
              }}
              orgNameCallback={(arg: any) => {
                setorgName(String(arg));
              }}
            />
          </FlexBoxItem>
        </FlexBox>
        <FlexBox>
          <FlexBoxItem width="100%">
            <DailySalesManagementTable
              targetDateFrom={targetDateFrom}
              targetDateTo={targetDateTo}
              dailyReport={dailyReport}
              getMediaMst={getMediaMst}
              getCreditMst={getCreditMst}
              orgCode={orgCode}
              categoryAry={categoryAry}
              loadingCallback={(args: any) => {
                setIsLoading(args);
              }}
              roleScreen={roleScreen}
            />
          </FlexBoxItem>
        </FlexBox>
      </FormContents>
    </BlockUI>
  );
};

export default DaySeparateReportLayout;
