 const arListSymbol = [
    {
      value: -1,
      symbol: 'x'
    },
    {
      value: 0,
      symbol: '△'
    },
    {
      value: 1,
      symbol: '▼'
    },
    {
      value: 2,
      symbol: '〇'
    },
    {
      value: 3,
      symbol: '−'
    },
    {
      value: 99,
      symbol: ''
    }
  ];
  
export const stateSymbol= {};
  arListSymbol.forEach((item) => {
    (stateSymbol as any)[item.value]= item.symbol;
  });