/* eslint-disable jsx-a11y/alt-text */
/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import Icon from 'components/atoms/Icon';

interface PhotoFrameProps {
  src: string,
  size?: string,
  isCircle?: boolean;
  onErrorIconColor?: string;
}

const createStyle = (size: string, isCircle: boolean) => css({
  width: size,
  height: size,
  borderRadius: isCircle ? '50%' : '0%',
  objectFit: 'cover',
});

const PhotoFrame: React.FC<PhotoFrameProps> = ({
  src, size = '24px', isCircle = false, onErrorIconColor = 'white',
}) => {
  const [errored, setErrored] = useState(false);
  const onError = (e: any) => {
    const current = e.target.src.split('?');
    const inputSrc = src.split('?');
    if (current[0] !== inputSrc[0]) {
      e.target.onerror = null;
      e.target.src = src;
    } else {
      setErrored(true);
    }
  };
  return (
    errored
      ? <Icon type="avatar" size={size} color={onErrorIconColor} />
      : (
        <img
          css={createStyle(size, isCircle)}
          src={src}
          onError={(e) => onError(e)}
        />
      )
  );
};

export default PhotoFrame;
