import React, { useState, useMemo } from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import TabControl from 'components/atoms/TabControl';
import BlockUI from 'components/molecules/BlockUi';
import AccountMaster from './AccountMaster';
import JournalMaster from './JournalMaster';
import SubjectMaster from './SubjectMaster';
import DescriptionMaster from './DescriptionMaster';
import { useAccountTitleMSTItemList } from './AccountMaster/hooks';
import { useJournalMasterItemList } from './JournalMaster/hooks';

export type SelectType = 'accountMaster' | 'subjectMaster' | 'journalMaster' | 'descriptionMaster';

const AccountTitleMSTPage: React.FC = () => {
  const {
    accountTitleMSTItemList, setAccountTitleMSTItemList,
  } = useAccountTitleMSTItemList();

  const {
    journalMasterItemList,
  } = useJournalMasterItemList();


  const tabTypes: { [key in SelectType]: SelectType } = {
    accountMaster: 'accountMaster',
    subjectMaster: 'subjectMaster',
    journalMaster: 'journalMaster',
    descriptionMaster: 'descriptionMaster',
  };
  const currentTab = sessionStorage.getItem('currentTabAccount') ? sessionStorage.getItem('currentTabAccount')! : String(tabTypes.accountMaster);

  const [selectType, setSelectType] = useState(currentTab);

  const [checkJournalData, setJournalData] = useState(1);
  const renderTab = useMemo(() => {
    sessionStorage.setItem('currentTabAccount', selectType);
    switch (selectType) {
      case tabTypes.subjectMaster: {
        return <SubjectMaster />;
      }
      case tabTypes.journalMaster: {
        return <JournalMaster setJournalData={setJournalData} />;
      }
      case tabTypes.descriptionMaster: {
        return <DescriptionMaster />;
      }
      default:
        return (
            <AccountMaster
              accountTitleMSTItemList={accountTitleMSTItemList}
              setAccountTitleMSTItemList={setAccountTitleMSTItemList}
            />
        );
    }
  }, [accountTitleMSTItemList, selectType, setAccountTitleMSTItemList, tabTypes.descriptionMaster, tabTypes.journalMaster, tabTypes.subjectMaster]);


  return (
    <SidebarTemplate pageTitle="小口現金設定">
      <BlockUI blocking={false}>
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <p>小口現金設定は小口現金機能を使用するためのマスタ設定です。</p>
          <TabControl
            items={[
              {
                value: tabTypes.accountMaster,
                display: '勘定科目マスタ',
              },
              {
                value: tabTypes.subjectMaster,
                display: '補助科目マスタ',
                disabled: accountTitleMSTItemList.length === 0,
              },
              {
                value: tabTypes.journalMaster,
                display: '仕訳マスタ',
                disabled: accountTitleMSTItemList.length === 0,
              },
              {
                value: tabTypes.descriptionMaster,
                display: '摘要マスタ',
                disabled: accountTitleMSTItemList.length === 0 || journalMasterItemList.length === 0 || sessionStorage.getItem('checkJournalData') === '0' || checkJournalData === 0,
              },
            ]}
            // items={tabItems}
            setValue={setSelectType as (arg: string) => void}
            currentValue={selectType}
            isFixed={true}
          />
        </div>
        <p />
        <div>
          {renderTab}
        </div>
      </BlockUI>
    </SidebarTemplate>

  );
};
export default AccountTitleMSTPage;
