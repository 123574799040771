import SidebarTemplate from 'components/templates/SidebarTemplate';
import MonthlyReportLayout from 'components/organismos/master/storeManagement/report/monthlyReport/MonthlyReportLayout';
import React from 'react';
import MonthlySubmissionConfirmationLayout from 'components/organismos/master/storeManagement/report/MonthlySubmissionConfirmation';

const MonthlySubmissionConfirmationPage = () => (
    <div className="usePrintTableA4">
        <SidebarTemplate pageTitle="月次書類提出確認表">
        <MonthlySubmissionConfirmationLayout/>
    </SidebarTemplate>
    </div>
    
);
export default MonthlySubmissionConfirmationPage;
