import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import FormByProductLayout from 'components/organismos/master/storeManagement/report/FormByProduct/FormByProductLayout';

const FormByProduct = () => (
  <SidebarTemplate pageTitle="商品別帳票">
    <FormByProductLayout />
  </SidebarTemplate>
);
export default FormByProduct;
