/* eslint-disable max-len */
/** @jsx jsx */
import { useMemo, useState, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import { iconColor } from 'components/styles';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import useToastNotification from 'hooks/useToastNotification';

import Icon from 'components/atoms/Icon';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import PrimaryButton from 'components/atoms/Button';
import DataTable from 'components/organismos/DataTable/DataTable';
import { downloadCSV, deleteDisCountTypeById } from 'api/disCountType';
import DiscountTypeDomain from 'domain/master/sales/discoutType';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import useDisCountTypeList from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const DiscountTypePage: React.FC<{
  linktab: any
}> = ({
  linktab,
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const [selecteDiscountType, setselecteDiscountType] = useState(DiscountTypeDomain.generateInitial());

  const openConfirmModal = useCallback((accountTitleMSTItem : DiscountTypeDomain) => {
    setConfirmModalOpen(true);
    setselecteDiscountType(accountTitleMSTItem);
  }, []);

  const downloadFileCSV = useCallback(() => {
    downloadCSV('値引・割引種別情報');
  }, []);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const { discountTypeList, setDiscountTypeList } = useDisCountTypeList();
  const { errorNotification, successNotification } = useToastNotification();

  const deleteDisCountType = useCallback(
    () => {
      console.log('selecteDiscountType.discountTypeMstCode', selecteDiscountType.discountTypeMstCode);
      deleteDisCountTypeById(selecteDiscountType.discountTypeMstCode).then((response:any) => {
        setDiscountTypeList(discountTypeList.filter((item) => item.discountTypeMstCode !== selecteDiscountType.discountTypeMstCode));
        setConfirmModalOpen(false);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response.data.message != null && error.response.data.message.length !== 0) {
          errorNotification(error.response.data.message);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    },
    [discountTypeList, errorNotification, selecteDiscountType.discountTypeMstCode, setDiscountTypeList, successNotification],
  );
  const columns: Array<Column<DiscountTypeDomain>> = useMemo(() => [
    {
      Header: 'コード',
      accessor: 'discountTypeMstCode',
    },
    {
      Header: '名称',
      accessor: 'discountTypeMstName',
    },
    {
      Header: '並び順',
      accessor: 'dispOrder',
    },
    {
      Header: () => null,
      id: 'actionButton',
      accessor: 'dispOrder',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: DiscountTypeDomain },
    data: Array<DiscountTypeDomain>
    }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/discountTypeMst/discountType/edit/${cell.row.original.discountTypeMstCode}/${cell.row.original.dispOrder}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            {
              roleScreen && roleScreen.editable === 1 && (
                <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                  <span
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                    onMouseEnter={() => setIsHoverDelete(true)}
                    onMouseLeave={() => setIsHoverDelete(false)}
                  >
                    <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </Link>
              )
            }
          </div>
        );
      },
    },
  ], [openConfirmModal, roleScreen]);

  return (
    <div style={{ marginTop: '12px' }}>
      <FlexBox>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
            <FlexBoxItem>
              <IconLabelButton
                onClick={downloadFileCSV}
                iconType="download"
                text="CSVダウンロード"
              />
            </FlexBoxItem>
          )
        }
        {
          roleScreen && roleScreen.importFlag === 1 && (
            <FlexBoxItem>
              <Link to={`/discountTypeMst/${linktab}/import`}>
                <PrimaryButton
                  ghost={false}
                  text="インポート"
                />
              </Link>
            </FlexBoxItem>
          )
        }
        {
          roleScreen && roleScreen.editable === 1 && (
            <FlexBoxItem grow={1}>
              <div style={{ textAlign: 'right' }}>
                <Link to={`/discountTypeMst/${linktab}/add`}>
                  <PrimaryButton
                    ghost={false}
                    text="新規値引・割引種別作成"
                  />
                </Link>
              </div>
            </FlexBoxItem>
          )
        }
      </FlexBox>
      <div
        css={css`
        table th:nth-child(1) {
          width: 150px;
        }
        table th:nth-child(2) {
          width: 300px;
        }
        table th:nth-child(3) {
          width: 100px;
        }
        table th:nth-child(4) {
          width: 80px;
        }
      `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>

          <DataTable
            columns={columns}
            data={discountTypeList}
            isGlobalFilter={true}
            sortBy={[
              { id: 'dispOrder', desc: false }
            ]}
          />
        </div>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={deleteDisCountType}
          actionType={ActionType.DELETE}
        />
      </div>
    </div>
  );
};

export default DiscountTypePage;
