import ApiClient from 'api/ApiClient';
import { AxiosResponse } from 'axios';

export const getList = async (depositDate: string, bankCode: string): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    depositDate,
    bankCode,
  };
  const response = await ApiClient.get(`/v1/bank/deposit/list/${companyCode}`, {}, params);
  return response;
};


export const getBankList = async (depositDate: string, orgCode: string, bankCode: string): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    depositDate,
    orgCode,
    bankCode,
  };
  const response = await ApiClient.get(`/v1/organizations/bank/list/${companyCode}`, {}, params);
  return response;
};
