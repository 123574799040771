import { useState, useEffect, useCallback } from 'react';
import TransferApplicationDomain from 'domain/master/attend/attendTransferApplication';
import { useHistory } from 'react-router-dom';
import { getTransfer, postTransfer } from 'api/attendTransferApplication';
import { useFormik } from 'formik';
import moment from 'moment';
import useToastNotification from 'hooks/useToastNotification';

type HolidayWorkApplicationKey = keyof Pick<TransferApplicationDomain, 'applicationReason'>;

export const TransferApplicationForm = () => {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [targetDate] = useState(sessionStorage.getItem('application.targetDate') || '');
  const [staffName] = useState(sessionStorage.getItem('application.dispStaffName') || '');
  const [selectStaffCode] = useState(sessionStorage.getItem('application.staffCode') || '');
  const [achievementId] = useState(sessionStorage.getItem('application.achievementId') || '');
  const [dispStaffName] = useState(sessionStorage.getItem('application.dispStaffName') || '');
  const [selectOrgCode, setSelectOrgCode] = useState(sessionStorage.getItem('application.orgCode') || '');
  const [toastMessage, setToastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmApplyModalOpen, setConfirmApplyModalOpen] = useState(false);
  const [isGetTransferSuccees, setIsGetTransferSuccees] = useState(true);
  const { errorNotification } = useToastNotification();

  const onSubmit = async (values: TransferApplicationDomain) => {
    console.log(values);
    if (values.applicationReason === null) {
      errorNotification('申請理由を入力してください。');
      return;
    }

    if (!confirmApplyModalOpen) {
      setConfirmApplyModalOpen(true);
      return;
    }

    const {
      modifierApplicationId, version, orgName, buttonType,
      applicationReason, staffCode, orgCode, targetDateStr, transferHolidayDate, applicationStatus, modifiyStatus,
    } = values.getRawData();
    const dataPost = {
      transferHolidayDateStr: transferHolidayDate == null ? moment(new Date(targetDate)).format('YYYY年MM月DD日') : moment(transferHolidayDate).format('YYYY年MM月DD日'),
      modifierApplicationId: modifierApplicationId || '',
      version: version || '',
      orgCode: orgCode || selectOrgCode,
      orgName,
      staffCode,
      buttonType,
      achievementId: achievementId || '',
      targetDateStr: moment(new Date(targetDateStr)).format('YYYY年MM月DD日'),
      loginUserCode: sessionStorage.getItem('loginUser.staffCode') || '',
      applicationReason,
      applicationStatus,
      modifiyStatus,
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
    };

    try {
      setLoading(true);
      setConfirmApplyModalOpen(false);
      const response = await postTransfer(dataPost);
      if (!response.errors) {
        history.push(sessionStorage.getItem('attendStampList.returnDestination')! || '');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setConfirmApplyModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: TransferApplicationDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  useEffect(() => {
    setLoading(true);
    getTransfer({
      orgCode: selectOrgCode,
      targetDate,
      staffCode: selectStaffCode,
    }).then((data: any) => {
      console.log(data);
      formik.setValues(new TransferApplicationDomain(data));
      setLoading(false);
    }).catch(() => {
      setIsGetTransferSuccees(false);
      setLoading(false);
      errorNotification('サーバー側でエラーが発生しました。');
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgCode, selectStaffCode, staffName, targetDate]);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmApplyModal = useCallback(() => {
    setConfirmApplyModalOpen(false);
  }, []);

  return {
    formik,
    selectOrgCode,
    setSelectOrgCode,
    staffName,
    targetDate,
    toastMessage,
    setToastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    setConfirmApplyModalOpen,
    closeToastModal,
    closeConfirmApplyModal,
    isLoading,
    dispStaffName,
    isGetTransferSuccees,
  };
};

export default TransferApplicationForm;
