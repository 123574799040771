/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import React, { useCallback, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { useDownload } from 'components/organismos/master/general/salesPage/SalesManagementList/hook';
import moment from 'moment';
import { stateSymbol } from 'components/organismos/master/general/salesPage/SalesManagementList/hookSysboy';
import { Link } from 'react-router-dom';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import SortIcon from 'components/molecules/SortIcon';
import { grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';

const styles = css({
  fontWeight: 400,
  fontSize: 14,
});

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 1,
});

const formatterPercent = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'percent',
});

const DEFAULT_SORT_FIELD = { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number' } as FieldSortState

const SalesManagementListTable: React.FC<{
    listSalesManagement: any;
    isActiveRow: number;
    isActiveColumn: number;
    handleClickActive: any;
    selectedDateFrom: any;
    selectedDateTo: any;
    orgName: any;
    roleScreen?: any;
  }> = ({
    listSalesManagement,
    isActiveRow,
    isActiveColumn,
    handleClickActive,
    selectedDateFrom,
    selectedDateTo,
    orgName,
    roleScreen
  }) =>{

    const listData = useMemo(() => listSalesManagement.slice(0, -1), [listSalesManagement])
    const totalData = useMemo(() => listSalesManagement.slice(-1), [listSalesManagement])

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(listData, DEFAULT_SORT_FIELD);

    const searchResult: any[] = [];
    const totalInformation: any[] = [];
    const { downloadCsv1, downloadExcel1 } = useDownload();

    const headerInformation = [
      ['', '', '日次','','','月次', '', '', '客単価', '',''],
      ['店舗', '並び順', '売上(税抜)', '予算', '達成率(%)','累計売上', '累計予算', '累計達成率', '組数','客数','客単価'],
    ];

    const data = {
      footer: '',
      formName: '【営業管理【売上管理】',
      targetPeriod: `対象期間：${moment(selectedDateFrom).format('YYYY-MM-DD')}~${moment(selectedDateTo).format('YYYY-MM-DD')}`,
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: totalInformation,
    };

    let mergedRegions: any[] = [];
    const codeColumnIndex: any[] = [];
    const dataExcel = {
      formName: '【営業管理【売上管理】',
      targetPeriod: `対象期間: ${moment(selectedDateFrom).format('YYYY-MM-DD')}~${moment(selectedDateTo).format('YYYY-MM-DD')}`,
      targetStores: `対象店舗：${orgName}`,
      headerInformation,
      searchResult,
      totalInformation: totalInformation,
      mergedRegions,
      codeColumnIndex,
    };

    const handleExportCSV = () => downloadCsv1(data);

    const handleExportExcel = () => {
      if (mergedRegions) {
        mergedRegions = [];
      }
      mergedRegions.push([3, 3, 2, 4]);
      mergedRegions.push([3, 3, 5, 7]);
      mergedRegions.push([3, 3, 8, 10]);
      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [0];
      dataExcel.headerInformation = headerInformation;
      return downloadExcel1(dataExcel);
    };

    const renderHeaderColumn = useCallback((displayName: string, columnSortField: FieldSortState, rowSpan: number, className: string = "") => (
      <th rowSpan={rowSpan} className={className} onClick={e => sortByFieldPath(columnSortField.fieldPath, columnSortField.sortType)}>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <span style={{ margin: 'auto' }}>{displayName}</span>
          {sortField.fieldPath === columnSortField.fieldPath && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
          {sortField.fieldPath !== columnSortField.fieldPath && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
        </div>
      </th>
     ), [sortField]);

    return  (
      <div>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportCSV}
                iconType="download"
                text=" CSV出力"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportExcel}
                iconType="download"
                text=" EXCEL出力"
              />
            </FlexBoxItem>
          </FlexBox>
        )}
        <div
        className="table-responsive"
        style={{
          display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', marginTop: 20, maxHeight: 600,
        }}
      >
        <table id="salesPageForm" className="table table-bordered table-condensed" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%', textAlign: 'center' }}>
          <thead>
            <tr role="row">
              <th colSpan={2}/>
              <th colSpan={3}>日次</th>
              <th colSpan={3}>月次</th>
              <th colSpan={3}>客単価</th>
            </tr>
            <tr className="subThead">
              { renderHeaderColumn('店舗', { fieldPath: 'orgName', sort: 'asc', sortType: 'string'}, 1) }
              { renderHeaderColumn('並び順', { fieldPath: 'dispOrder', sort: 'asc', sortType: 'number'}, 1) }
              { renderHeaderColumn('売上(税抜)', { fieldPath: 'netSales', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('予算', { fieldPath: 'salesBudget', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('達成率(%)', { fieldPath: 'salesAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('累計売上', { fieldPath: 'cumulativeSales', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('累計予算', { fieldPath: 'cumulativeSalesBudget', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('累計達成率', { fieldPath: 'cumulativeSalesAchievementRate', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('組数', { fieldPath: 'customers', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('客数', { fieldPath: 'guestCnt', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
              { renderHeaderColumn('客単価', { fieldPath: 'customerUnitPrice', sort: 'asc', sortType: 'number'}, 1, "color_merge") }
            </tr>
          </thead>
            {
                    listSalesManagement && listSalesManagement.length > 0 ? dataSort.map((saleManagement: any, index: number) => {
                      {searchResult.push([
                        (stateSymbol as any)[saleManagement.status] + saleManagement.orgName,
                        saleManagement.dispOrder || 0,
                        formatter.format(saleManagement.netSales) || '',
                        formatter.format(saleManagement.salesBudget) || '',
                        formatter.format(saleManagement.salesAchievementRate)+'%' || '',
                        formatter.format(saleManagement.cumulativeSales) || '',
                        formatter.format(saleManagement.cumulativeSalesBudget) || '',
                        formatter.format(saleManagement.cumulativeSalesAchievementRate)+'%' || '',
                        formatter.format(saleManagement.customers) || '',
                        formatter.format(saleManagement.guestCnt) || '',
                        formatter.format(saleManagement.customerUnitPrice) || '',
                        ])
                      }
                      //console.log(saleManagement,'check exexex')
                      return  (
                      <tbody key={index} css={styles}>
                        <tr className={isActiveRow === index ? 'activeClickFrames' : ''}>
                          <td
                            className={`textLeft ${isActiveColumn === 1 ? 'activeClickFrames' : ''}`}
                            onClick={() => { handleClickActive(index, 1); }}
                          >
                             {
                                saleManagement.status !== 99
                                ? <Link target={"_blank"} to={{ pathname: `/salesDailyReport/${saleManagement.orgCode}/${moment(selectedDateTo).format('YYYY-MM-DD')}` }}>
                                  {(stateSymbol as any)[saleManagement.status]}
                                  </Link>
                                :  (stateSymbol as any)[saleManagement.status]
                              }
                           {saleManagement.orgName}
                          </td>
                          <td
                            className={isActiveColumn === 2 ? 'activeClickFrames' : ''}
                            onClick={() => { handleClickActive(index, 2); }}
                          >
                            {saleManagement.dispOrder}
                          </td>
                          <td
                            className={isActiveColumn === 3 ? 'activeClickFrames' : ''}
                            onClick={() => { handleClickActive(index, 3); }}
                          >
                            {formatter.format(saleManagement.netSales) }
                          </td>
                          <td
                            className={isActiveColumn === 4 ? 'activeClickFrames' : ''}
                            onClick={() => { handleClickActive(index, 4); }}
                          >
                            {formatter.format(saleManagement.salesBudget) }
                          </td>
                          <td
                            className={isActiveColumn === 5 ? 'activeClickFrames' : ''}
                            onClick={() => { handleClickActive(index, 5); }}
                          >
                            {formatter.format(saleManagement.salesAchievementRate)+'%' }
                          </td>
                          <td
                            className={isActiveColumn === 6 ? 'activeClickFrames' : ''}
                            onClick={() => { handleClickActive(index, 6); }}
                          >
                            {formatter.format(saleManagement.cumulativeSales) }
                          </td>
                          <td
                            className={isActiveColumn === 7 ? 'activeClickFrames' : ''}
                            onClick={() => { handleClickActive(index, 7); }}
                          >
                            {formatter.format(saleManagement.cumulativeSalesBudget) }
                          </td>
                          <td
                            className={isActiveColumn === 8 ? 'activeClickFrames' : ''}
                            onClick={() => { handleClickActive(index, 8); }}
                          >
                            {formatter.format(saleManagement.cumulativeSalesAchievementRate)+'%' }
                          </td>
                          <td
                            className={isActiveColumn === 9 ? 'activeClickFrames' : ''}
                            onClick={() => { handleClickActive(index, 9); }}
                          >
                            {formatter.format(saleManagement.customers) }
                          </td>
                          <td
                            className={isActiveColumn === 10 ? 'activeClickFrames' : ''}
                            onClick={() => { handleClickActive(index, 10); }}
                          >
                            {formatter.format(saleManagement.guestCnt) }
                          </td>
                          <td
                            className={isActiveColumn === 11 ? 'activeClickFrames' : ''}
                            onClick={() => { handleClickActive(index, 11); }}
                          >
                            {formatter.format(saleManagement.customerUnitPrice) }
                          </td>


                        </tr>
                      </tbody>
                      )
                    } ) : <tbody css={styles}></tbody>
              }
          <tfoot>
            {listSalesManagement.length > 0 && totalData.map((item: any, index: any) => {
               {totalInformation.push([
                '合計',
                item.dispOrder || 0,
                formatter.format(item.netSales) || '',
                formatter.format(item.salesBudget) || '',
                formatter.format(item.salesAchievementRate)+'%' || '',
                formatter.format(item.cumulativeSales) || '',
                formatter.format(item.cumulativeSalesBudget) || '',
                formatter.format(item.cumulativeSalesAchievementRate)+'%' || '',
                formatter.format(item.customers) || '',
                formatter.format(item.guestCnt) || '',
                formatter.format(item.customerUnitPrice) || '',
              ])}
              return (
              <tr key={index} className="custom_total">
                <td className="textLeft ">合計</td>
                <td>{item.dispOrder}</td>
                <td>{formatter.format(item.netSales)}</td>
                <td>{formatter.format(item.salesBudget)}</td>
                <td>{formatter.format(item.salesAchievementRate)+'%'}</td>
                <td>{formatter.format(item.cumulativeSales)}</td>
                <td>{formatter.format(item.cumulativeSalesBudget)}</td>
                <td>{formatter.format(item.cumulativeSalesAchievementRate)+'%'}</td>
                <td>{formatter.format(item.customers)}</td>
                <td>{formatter.format(item.guestCnt)}</td>
                <td>{formatter.format(item.customerUnitPrice)}</td>
              </tr>
            )
            } )}
          </tfoot>
        </table>
      </div>

      </div>

   );
  }
export default SalesManagementListTable;
