/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import moment from 'moment';
import PrimaryButton from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import BlockUI from 'components/molecules/BlockUi';
import { useHistory } from 'react-router-dom';
import { useSalesBudgetMonth } from './hooks';
import TableSalesPlanDaily from './TableSalesPlanDaily';
import TableBiasSettings from './TableBiasSettings';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const SalesBudgetYearLayout = () => {
  const {
    handleChangeInputBias, isLoading, getListBiasSetting, getRows, formik, handleChangeSelect, confirmModalOpen, closeConfirmModal, arrayIndexHoliday,
  } = useSalesBudgetMonth();
  const history = useHistory();

  const orgCode = sessionStorage.getItem('orgCode_DestinationOrg') || '';

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole])

  const orgName = sessionStorage.getItem('destination_org');
  const targetMonth = sessionStorage.getItem('month_selected');
  const handleBack = () => {
    history.push('/salesBudgetYear');
    sessionStorage.setItem('isBack', 'true');
  };

  return (
    <div className="sales_budget_month">
      <BlockUI blocking={isLoading}>
      <FormContents>
        <p>
          対象年月：
          {moment(targetMonth?.toString()).format('YYYY年MM月')}
        </p>
        <p>
          組織名：
          {orgName}
        </p>
        <p>
          <br />
          バイアス設定
        </p>
          <TableBiasSettings
            formik={formik}
            listBiasSettings={getListBiasSetting}
            getRows={getRows}
            handleChangeInputBias={handleChangeInputBias}
          />
          <div style={{ marginTop: '40px' }}>
            <TableSalesPlanDaily
              formik={formik}
              getRows={getRows}
              listBiasSettings={getListBiasSetting}
              handleChangeSelect={handleChangeSelect}
              arrayIndexHoliday={arrayIndexHoliday}
            />
          </div>
      </FormContents>
      <div style={{ textAlign: 'center', margin: '15px' }}>
        { 
          checkUserRole && checkUserRole.editable === 1 && (
          <span style={{ margin: '0px 10px' }}>
            <PrimaryButton
              onClick={formik.handleSubmit}
              text="更新 "
            />
          </span>
        )}
        <span style={{ margin: '0px 10px' }}>
          <PrimaryButton
            onClick={handleBack}
            text="戻る "
          />
        </span>
      </div>
      </BlockUI>

      <ConfirmModal
        title="確認メッセージ"
        content="更新します。よろしいですか？"
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.submitForm}
        submitText="更新"
        items={[]}
      />
    </div>
  );
};
export default SalesBudgetYearLayout;
