/* eslint-disable max-len */
import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import FormLabel from 'components/atoms/Form/FormLabel';
import DatePickerForm from 'components/molecules/DatePickerForm';
import TextForm from 'components/molecules/TextForm';
import FormHelp from 'components/atoms/Form/FormHelp';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import useBusinessMasterOptions from 'hooks/useBusinessMasterOptions';
import HourInput from 'components/atoms/Form/HourInput';
import FlexBox from 'components/atoms/FlexBox';
import FormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import { gridCol } from 'components/styles';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import { AttendBusiness } from 'domain/employment';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';

const EmploymentBasicSettings: React.FC<{
  formik: any
}> = ({
  formik,
}) => {
  const businessOptions = useBusinessMasterOptions();
  return (
    <>
      <FormTitle
        title="基本設定"
      />
      <div>

        <FormField>
          <TextForm
            name="employmentCode"
            label="雇用形態コード"
            required={true}
            value={formik.values.employmentCode}
            onChange={formik.handleChange}
            placeHolder="雇用形態コードを入力して下さい"
            errorMsg={formik.errors.employmentCode}
          />
        </FormField>
        <FormField>
          <TextForm
            name="employmentName"
            label="雇用形態名"
            required={true}
            value={formik.values.employmentName}
            onChange={formik.handleChange}
            placeHolder="雇用形態名を入力して下さい"
            errorMsg={formik.errors.employmentName}
          />
        </FormField>

        <FormField>
          <div style={{ marginRight: '12px' }}>
            <DatePickerForm
              required={true}
              dateFormat="yyyy/MM"
              label="雇用形態の適用開始年月"
              showMonthYearPicker={true}
              isFullWidth={true}
              date={formik.values.applyDate}
              changeDate={(date: Date) => {
                if (date !== null) {
                  formik.setFieldValue('applyDate', date);
                }
              }}
            />
            <div style={{ marginTop: '4px' }}>
              <span style={{ color: 'red', marginTop: '4px' }}>{formik.errors.applyDate}</span>
            </div>
          </div>
        </FormField>

        <FormField displayBlock={true}>
          <div style={{ marginRight: '12px', width: gridCol.grid06 }}>
            <FormLayout
              label={(
                <FormLabel
                  label="営業日切替時刻"
                />
                )}
              input={(
                <FlexBox>
                  <HourInput
                    name="closingHour"
                    value={formik.values.closingHour}
                    label="時"
                    min={0}
                    max={23}
                    onChange={formik.handleChange}
                  />
                </FlexBox>
                )}
            />
          </div>
          <FormHelp
            content="日付を変更する切り替え時刻を選択します。"
          />
        </FormField>

        <FormField displayBlock={true}>
          <div style={{ marginRight: '12px', width: gridCol.grid06 }}>
            <FormLayout
              label={(
                <FormLabel
                  label="給与締め日"
                />
                )}
              input={(
                <FlexBox>
                  <HourInput
                    name="closingDate"
                    value={formik.values.closingDate}
                    label="日"
                    min={0}
                    max={31}
                    onChange={formik.handleChange}
                  />
                </FlexBox>
                )}
            />
          </div>
          <FormHelp
            content="「0」を入力すると月末という扱いになります。"
          />
        </FormField>

        <FormField displayBlock={true}>
          <div style={{ marginRight: '12px', width: gridCol.grid06 }}>
            <VerticalLabelSelectForm
              label="週の締め曜日"
              name="closingDayOfWeek"
              value={String(formik.values.closingDayOfWeek)}
              setValue={(val: string) => formik.setFieldValue('closingDayOfWeek', val)}
              options={[
                { label: '日曜日', value: '0' },
                { label: '月曜日', value: '1' },
                { label: '火曜日', value: '2' },
                { label: '水曜日', value: '3' },
                { label: '木曜日', value: '4' },
                { label: '金曜日', value: '5' },
                { label: '土曜日', value: '6' },
              ]}
            />
          </div>
          <FormHelp
            content="週の締め曜日は週単位の所定労働時間算出に使う曜日です。"
          />
        </FormField>

        <FormField displayBlock={true}>

          <FormField>
            <div style={{ width: gridCol.grid12 }}>
              <div style={{ width: gridCol.grid12 }}>
                <FormLabel
                  label="深夜勤務時間帯"
                />
              </div>
              <div style={{ width: '350px' }}>
                <div style={{ width: '120px', float: 'left' }}>
                  {/* <TimeInput
                    name="midnightStartTime"
                    value={formik.values.midnightStartTime}
                    onChange={() => {}}
                    setValue={(val: string) => formik.setFieldValue('midnightStartTime', val)}
                  /> */}
                  <TimePicker
                    name="midnightStartTime"
                    format="HH:mm"
                    // defaultValue={formik.values.midnightStartTime.length > 0 ? moment(formik.values.midnightStartTime, 'HH:mm') : moment('00:00', 'HH:mm')}
                    value={formik.values.midnightStartTimeMoment}
                    showSecond={false}
                    onChange={(val) => formik.setFieldValue('midnightStartTime', val ? val.format('HH:mm') : '00:00')}
                  />
                </div>
                <div style={{
                  width: '30px', textAlign: 'center', float: 'left',
                }}
                >
                  〜
                </div>
                <div style={{ width: '120px', float: 'left' }}>
                  {/* <TimeInput
                    name="midnightEndTime"
                    value={formik.values.midnightEndTime}
                    onChange={() => {}}
                    setValue={(val: string) => formik.setFieldValue('midnightEndTime', val)}
                  /> */}
                  <TimePicker
                    name="midnightEndTime"
                    format="HH:mm"
                    // value={formik.values.midnightEndTime ? moment(formik.values.midnightEndTime, 'HH:mm') : moment('05:00', 'HH:mm')}
                    value={formik.values.midnightEndTimeMoment}
                    showSecond={false}
                    onChange={(val) => formik.setFieldValue('midnightEndTime', val ? val.format('HH:mm') : '00:00')}
                  />
                </div>
              </div>
            </div>
          </FormField>
          <FormHelp
            content="深夜労働手当の対象となる時間帯を入力します。"
          />
        </FormField>

        <FormField displayBlock={true}>
          <div style={{ marginRight: '12px', width: gridCol.grid06 }}>
            <FormLayout
              label={(
                <FormLabel
                  label="日の契約労働時間"
                />
                )}
              input={(
                <FlexBox>
                  <HourInput
                    name="contractWorkHourDay"
                    value={String(formik.values.contractWorkHourDay)}
                    label="時間"
                    onChange={formik.handleChange}
                    min={0}
                    max={23}
                  />
                </FlexBox>
                )}
            />
          </div>
          <FormHelp
            content="有給のみなし時間を契約時間とする場合に使用されます。"
          />
        </FormField>

        <FormField displayBlock={true}>
          <FormField>
            <div style={{ marginRight: '12px', width: gridCol.grid06 }}>
              <VerticalLabelSelectForm
                label="週の契約労働日数"
                name="contractWorkDateNumWeek"
                value={String(formik.values.contractWorkDateNumWeek)}
                setValue={(val: string) => formik.setFieldValue('contractWorkDateNumWeek', val)}
                options={[
                  { label: '5日以上', value: '5' },
                  { label: '4日', value: '4' },
                  { label: '3日', value: '3' },
                  { label: '2日', value: '2' },
                  { label: '1日', value: '1' },
                ]}
              />
            </div>
          </FormField>
          <FormHelp
            content="有給を自動付与する場合の判断条件として使用されます。"
          />
        </FormField>

        <FormField displayBlock={true}>
          <FormField>
            <div style={{ marginRight: '12px', width: gridCol.grid06 }}>
              <VerticalLabelSelectForm
                label="労働形態"
                name="workingForm"
                value={String(formik.values.workingForm)}
                setValue={(val: string) => formik.setFieldValue('workingForm', val)}
                options={[
                  { label: 'フルタイム', value: '0' },
                  { label: 'パートタイム', value: '1' },
                ]}
              />
            </div>
          </FormField>
          <FormHelp
            content="シフト編成画面の並び順で使用しフルタイムが優先されます。ダッシュボードの年間給与情報で使用しパートタイムの場合に表示されます。"
          />
        </FormField>

        <VerticalLabelMutilSelectForm
          label="使用する業務"
          name="businesses"
          values={formik.values.businesses.map(
            (business: AttendBusiness) => businessOptions.find(
              (item) => business.businessId === item.value,
            ),
          )}
          setValue={(val: any) => {
            if (val !== '') {
              formik.setFieldValue('businesses', val.slice(0, -1).split(',').map(
                (id: string) => ({ businessId: id }),
              ));
            } else {
              formik.setFieldValue('businesses', []);
            }
          }}
          options={businessOptions}
          note="業務単位に勤務時間を集計したい場合に使用します。"
          isMulti={true}
        />
      </div>
    </>
  );
};

export default EmploymentBasicSettings;
