import moment from 'moment';

/**
 * APIのデータ型
 */


export type AttendBusiness = {
  businessId: string,
  businessCode: string,
  businessName: string,
  companyCode: string,
  createUser: string,
  updateUser: string,
}

export type AttendHoliday = {
  holidayId: string,
  holidayCode: string,
  holidayName: string,
  companyCode: string,
  createUser: string,
  updateUser: string,
}

export type NoticeStaff = {
  staffCode: string,
  employmentId: string,
  applyStartDate: Date,
  companyCode: string,
  createUser: string,
  updateUser: string,
}

export type AttendEmploymentTermData = {
  id: {
    termId: string,
    employmentId: string,
    applyStartDate: Date,
  },
  termName: string,
  targetType: string,
  startTime: string,
  endTime: string,
  addHourlySalary: number,
}

export type AttendEmploymentHolidayPatternData = {
  paidHolidayPatternId: string,
}

export interface Employment {
  id: {
    employmentId: string;
    applyStartDate: string;
  };
  employmentId: string;
  employmentCode: string;
  employmentName: string;
  applyEndDate: string;
  dispApplyDate: string,
  applyDate: string,
  closingDate: number,
  closingHour: number,
  contractWorkHourDay: number,
  midnightStartTime: string,
  midnightStartTimeHour: number,
  midnightStartTimeMinute: number,
  midnightEndTime: string,
  // midnightEndTimeHour: number,
  // midnightEndTimeMinute: number,
  businesses : Array<AttendBusiness>,
  businessesIds : Array<string>,
  holidays : Array<AttendHoliday>,
  attendHolidayIds : Array<string>,
  noticeStaffs : Array<NoticeStaff>,
  terms : Array<AttendEmploymentTermData>,
  staffCodes : Array<string>,
  dispKyukaNames: string,
  paidHolidayPatternId: string,
  createUser: string,
  updateUser: string,
  closingDayOfWeek: number,
  contractWorkDateNumWeek: number,
  workingForm: number,
  workSystem: number,
  startTime: string,
  startTimeHour: number,
  startTimeMinute: number,
  endTime: string,
  endTimeHour: number,
  endTimeMinute: number,
  useAutoRecess: number,
  autoRecessWorkConditionTime1: number,
  autoRecessGrantTime1: number,
  autoRecessWorkConditionTime2: number,
  autoRecessGrantTime2: number,
  autoRecessWorkConditionTime3: number,
  autoRecessGrantTime3: number,
  autoRecessPriorityOfSubtract: number,
  autoRecessDuplication: number,
  recessStartTime1: string,
  recessEndTime1: string,
  recessStartTime2: string,
  recessEndTime2: string,
  recessStartTime3: string,
  recessEndTime3: string,
  isStartTimeNextDay: boolean,
  isEndTimeNextDay: boolean,
  isRecessStartTimeNextDay1: boolean,
  isRecessEndTimeNextDay1: boolean,
  isRecessStartTimeNextDay2: boolean,
  isRecessEndTimeNextDay2: boolean,
  isRecessStartTimeNextDay3: boolean,
  isRecessEndTimeNextDay3: boolean,
  roundMethodStartStamp: number;
  roundMethodEndStamp: number;
  roundUnitStartStamp: number,
  roundTimingRecess: number;
  roundUnitRecessStamp: number,
  roundUnitDay: string,
  roundMethodDay: string,
  roundUnitMonth: string,
  roundMethodMonth: string,
  roundShiftStartTime: number,
  roundShiftEndTime: number,
  roundEndElapsedTime: number,
  useGpsStamp: number,
  warningDistance: string,
  calcOverTimeInDay: boolean,
  prescribedWorkHourOfDay: number,
  letShiftPredeterminedWorkTimeOfDay: number,
  handleLegalInOvertime: number,
  // calcLegalOverTime: number,
  treatmentStatutoryHolidayOvertime: number,
  calcOverTimeInWeek: boolean,
  prescribedWorkHourOfWeek: number,
  letShiftPredeterminedWorkTimeOfWeek: number,
  // carryOverMonthMethod: number,
  // carryOverMonthRounding: number,
  handleShiftOutsideTimeOfWeek: string,
  calcOverTimeInMonth: boolean,
  workingHoursId: string,
  paidHolidayPattern: string,
  useExtraOverTime: boolean,
  useOverTimeApplication: number,
  extraOverTime1: number,
  extraOverTime2: number,
  extraOverTime3: number,
  holidayWorkBgColor: string,
  holidayWorkForeColor: string,
  necessaryHolidayWorkApplication: boolean,
  letPubBeLegalHoliday: boolean,
  letSatBeLegalHoliday: boolean,
  letSunBeLegalHoliday: boolean,
  letMonBeLegalHoliday: boolean,
  letTueBeLegalHoliday: boolean,
  letWedBeLegalHoliday: boolean,
  letTurBeLegalHoliday: boolean,
  letFriBeLegalHoliday: boolean,
  distinctionHoliday: boolean,
  letPubBeNonLegalHoliday: boolean,
  letSatBeNonLegalHoliday: boolean,
  letSunBeNonLegalHoliday: boolean,
  letMonBeNonLegalHoliday: boolean,
  letTueBeNonLegalHoliday: boolean,
  letWedBeNonLegalHoliday: boolean,
  letTurBeNonLegalHoliday: boolean,
  letFriBeNonLegalHoliday: boolean,
  autoPaidManageFunc: boolean,
  paidProvisionMethod: number,
  paidBonusAwardDate: string,
  paidBonusAwardDateMonth: number,
  paidBonusAwardDateDay: number,
  paidHolidayInitialGrantMonths: number,
  paidHolidayInitialGrantDays: number,
  calcMethodPaidGrantDays: number,
  grantedPaidAtCompanyEntry: string,
  paidDigestionOrder: number,
  timeHoliday: string,
  timeHolidayMaxHour: string,
  timeHolidayHourOfDay: string,
  useTransferApplication: number,
  transferWorkBgColor: string,
  transferWorkForeColor: string,
  transferHolidayBgColor: string,
  transferHolidayForeColor: string,
  autoHolidayFunc: boolean,
  overTimeAlert: number,
  recessTimeAlertWorkTime1: number,
  recessTimeAlertTime1: number,
  recessTimeAlertWorkTime2: number,
  recessTimeAlertTime2: number,
  recessTimeAlertWorkTime3: number,
  recessTimeAlertTime3: number,
  continuousWorkAlert: number,
  holidayPattern: AttendEmploymentHolidayPatternData | undefined,
  isDisplayAutoBreak: boolean;
}

// export default class EmploymentDomain {
//   constructor(private rawData: Employment) {
//     this.rawData = {
//       ...rawData,
//     };
//   }
export default class EmploymentDomain {
  private rawData: Employment

  constructor(rawData: Employment) {
    // console.log(`midnightStartTime1 = ${rawData.midnightStartTime}`);
    this.rawData = {
      ...rawData,
      id: {
        ...rawData.id,
        applyStartDate: convertDateToStringYYYYMMDD(rawData.id.applyStartDate),
      },
      applyDate: rawData.id.applyStartDate && convertDateToStringYYYYMMDD(rawData.id.applyStartDate),
      paidHolidayPatternId: rawData.holidayPattern ? rawData.holidayPattern.paidHolidayPatternId : '',
    };
    // console.log(`midnightStartTime3 = ${this.rawData.midnightStartTime}`);
  }

  static generateInitial(isEdit?: number): EmploymentDomain {
    return new EmploymentDomain({
      id: {
        employmentId: '',
        applyStartDate: moment().format('YYYY/MM/DD'),
      },
      employmentId: '',
      employmentCode: '',
      employmentName: '',
      applyEndDate: moment().format('YYYY/MM/DD'),
      applyDate: moment().format('YYYY/MM/DD'),
      dispApplyDate: '',
      closingDate: 0,
      closingHour: 0,
      contractWorkHourDay: 0,
      midnightStartTime: '22:00',
      midnightStartTimeHour: 22,
      midnightStartTimeMinute: 0,
      midnightEndTime: '5:00',
      // midnightEndTimeHour: 5,
      // midnightEndTimeMinute: 0,
      businesses: [],
      businessesIds: [],
      holidays: [],
      attendHolidayIds: [],
      noticeStaffs: [],
      terms: [],
      staffCodes: [],
      dispKyukaNames: '',
      paidHolidayPatternId: '',
      createUser: '',
      updateUser: '',
      closingDayOfWeek: 0,
      contractWorkDateNumWeek: 5,
      workingForm: 0,
      workSystem: 1,
      startTime: '00:00',
      endTime: '00:00',
      startTimeHour: 0,
      startTimeMinute: 0,
      endTimeHour: 0,
      endTimeMinute: 0,
      useAutoRecess: 0,
      autoRecessWorkConditionTime1: 0,
      autoRecessGrantTime1: 0,
      autoRecessWorkConditionTime2: 0,
      autoRecessGrantTime2: 0,
      autoRecessWorkConditionTime3: 0,
      autoRecessGrantTime3: 0,
      autoRecessPriorityOfSubtract: 0,
      autoRecessDuplication: 0,
      recessStartTime1: '00:00',
      recessEndTime1: '00:00',
      recessStartTime2: '00:00',
      recessEndTime2: '00:00',
      recessStartTime3: '00:00',
      recessEndTime3: '00:00',
      isStartTimeNextDay: false,
      isEndTimeNextDay: false,
      isRecessStartTimeNextDay1: false,
      isRecessEndTimeNextDay1: false,
      isRecessStartTimeNextDay2: false,
      isRecessEndTimeNextDay2: false,
      isRecessStartTimeNextDay3: false,
      isRecessEndTimeNextDay3: false,
      roundMethodStartStamp: 1,
      roundMethodEndStamp: 0,
      roundUnitStartStamp: 1,
      roundTimingRecess: 0,
      roundUnitRecessStamp: 1,
      roundUnitDay: '',
      roundMethodDay: '',
      roundUnitMonth: '',
      roundMethodMonth: '',
      roundShiftStartTime: 0,
      roundShiftEndTime: 0,
      roundEndElapsedTime: 0,
      useGpsStamp: 0,
      warningDistance: '',
      calcOverTimeInDay: false,
      prescribedWorkHourOfDay: 0,
      letShiftPredeterminedWorkTimeOfDay: 0,
      handleLegalInOvertime: 0,
      // calcLegalOverTime: 0,
      treatmentStatutoryHolidayOvertime: 0,
      calcOverTimeInWeek: false,
      prescribedWorkHourOfWeek: 0,
      letShiftPredeterminedWorkTimeOfWeek: 0,
      // carryOverMonthMethod: 0,
      // carryOverMonthRounding:4,
      handleShiftOutsideTimeOfWeek: '',
      calcOverTimeInMonth: false,
      workingHoursId: '',
      paidHolidayPattern: '',
      useExtraOverTime: false,
      useOverTimeApplication: 0,
      extraOverTime1: 0,
      extraOverTime2: 0,
      extraOverTime3: 0,
      holidayWorkBgColor: '#ffffff',
      holidayWorkForeColor: '#000000',
      necessaryHolidayWorkApplication: false,
      letPubBeLegalHoliday: false,
      letSatBeLegalHoliday: false,
      letSunBeLegalHoliday: false,
      letMonBeLegalHoliday: false,
      letTueBeLegalHoliday: false,
      letWedBeLegalHoliday: false,
      letTurBeLegalHoliday: false,
      letFriBeLegalHoliday: false,
      distinctionHoliday: false,
      letPubBeNonLegalHoliday: false,
      letSatBeNonLegalHoliday: false,
      letSunBeNonLegalHoliday: false,
      letMonBeNonLegalHoliday: false,
      letTueBeNonLegalHoliday: false,
      letWedBeNonLegalHoliday: false,
      letTurBeNonLegalHoliday: false,
      letFriBeNonLegalHoliday: false,
      autoPaidManageFunc: false,
      paidProvisionMethod: 0,
      paidBonusAwardDate: moment().format('YYYY年MM月'),
      paidBonusAwardDateMonth: 1,
      paidBonusAwardDateDay: 1,
      paidHolidayInitialGrantMonths: 0,
      paidHolidayInitialGrantDays: 0,
      calcMethodPaidGrantDays: 0,
      grantedPaidAtCompanyEntry: '',
      paidDigestionOrder: 0,
      timeHoliday: '',
      timeHolidayMaxHour: '',
      timeHolidayHourOfDay: '',
      useTransferApplication: 0,
      transferWorkBgColor: '#ffffff',
      transferWorkForeColor: '#000000',
      transferHolidayBgColor: '#ffffff',
      transferHolidayForeColor: '#000000',
      autoHolidayFunc: false,
      overTimeAlert: 45,
      recessTimeAlertWorkTime1: 0,
      recessTimeAlertTime1: 0,
      recessTimeAlertWorkTime2: 0,
      recessTimeAlertTime2: 0,
      recessTimeAlertWorkTime3: 0,
      recessTimeAlertTime3: 0,
      continuousWorkAlert: 0,
      holidayPattern: undefined,
      isDisplayAutoBreak: false
    });
  }

  getRawData(): Employment {
    return this.rawData;
  }

  get employmentId(): string {
    return this.rawData.id.employmentId;
  }

  set employmentId(employmentId: string) {
    this.rawData.id.employmentId = employmentId;
  }

  get businesses(): Array<AttendBusiness> {
    return this.rawData.businesses;
  }

  set businesses(businesses: Array<AttendBusiness>) {
    this.rawData.businesses = businesses;
  }

  get businessesIds(): Array<string> {
    return this.rawData.businessesIds;
  }

  set businessesIds(businessesIds: Array<string>) {
    this.rawData.businessesIds = businessesIds;
  }

  get holidays(): Array<AttendHoliday> {
    return this.rawData.holidays;
  }

  set holidays(holidays: Array<AttendHoliday>) {
    // // this.rawData.holidays = holidays;
    // let dateFormats = new Date();
    // // eslint-disable-next-line guard-for-in
    // for (const i in holidays) {
    //   dateFormats = new Date(holidays[i].createDate);
    //   const wkDate = new Date(moment(dateFormats.toString()).format('YYYY-MM-DD HH:mm:ss'));
    //   // eslint-disable-next-line no-param-reassign
    //   holidays[i].createDate = wkDate;
    // }
    // // if (holidays && holidays != null) {
    // //   for (let i = 1; i <= holidays.length;) {
    // //     dateFormats = new Date(applyStartDate);
    // //     applyDate = moment(dateFormats.toString()).format('YYYY-MM-DD');
    // //     i += 1;
    // //   }
    // // }

    this.rawData.holidays = holidays;
  }

  get attendHolidayIds(): Array<string> {
    return this.rawData.attendHolidayIds;
  }

  set attendHolidayIds(attendHolidayIds: Array<string>) {
    this.rawData.attendHolidayIds = attendHolidayIds;
  }

  get noticeStaffs(): Array<NoticeStaff> {
    return this.rawData.noticeStaffs;
  }

  set noticeStaffs(noticeStaffs: Array<NoticeStaff>) {
    this.rawData.noticeStaffs = noticeStaffs;
  }

  get terms(): Array<AttendEmploymentTermData> {
    return this.rawData.terms;
  }

  set terms(terms: Array<AttendEmploymentTermData>) {
    this.rawData.terms = terms;
  }

  get staffCodes(): Array<string> {
    return this.rawData.staffCodes;
  }

  set staffCodes(staffCodes: Array<string>) {
    this.rawData.staffCodes = staffCodes;
  }

  get employmentCode(): string {
    return this.rawData.employmentCode;
  }

  set employmentCode(employmentCode: string) {
    this.rawData.employmentCode = employmentCode;
  }

  get closingDate(): number {
    return this.rawData.closingDate;
  }

  set closingDate(closingDate: number) {
    this.rawData.closingDate = closingDate;
  }

  get closingHour(): number {
    return this.rawData.closingHour;
  }

  set closingHour(closingHour: number) {
    this.rawData.closingHour = closingHour;
  }

  get contractWorkHourDay(): number {
    return this.rawData.contractWorkHourDay;
  }

  set contractWorkHourDay(contractWorkHourDay: number) {
    this.rawData.contractWorkHourDay = contractWorkHourDay;
  }

  get midnightStartTime(): string {
    return this.rawData.midnightStartTime;
  }

  get midnightStartTimeMoment(): moment.Moment {
    if (this.rawData.midnightStartTime && this.rawData.midnightStartTime.length > 0) {
      const splitMidnightStartTime = this.rawData.midnightStartTime.split(':');
      if (splitMidnightStartTime[1]) {
        return moment()
          .hour(Number(splitMidnightStartTime[0]))
          .minutes(Number(splitMidnightStartTime[1]));
      }
    }
    return moment().hour(22).minutes(0);
  }

  set midnightStartTime(midnightStartTime: string) {
    this.rawData.midnightStartTime = midnightStartTime;

    if (midnightStartTime !== null && midnightStartTime !== undefined && midnightStartTime.length > 4) {
      this.rawData.midnightStartTimeHour = Number(midnightStartTime.substr(0, 2));
      this.rawData.midnightStartTimeMinute = Number(midnightStartTime.substr(3, 2));
    }
  }

  get midnightStartTimeHour(): number {
    return this.rawData.midnightStartTimeHour;
  }

  set midnightStartTimeHour(midnightStartTimeHour: number) {
    this.rawData.midnightStartTimeHour = midnightStartTimeHour;
  }

  get midnightStartTimeMinute(): number {
    return this.rawData.midnightStartTimeMinute;
  }

  set midnightStartTimeMinute(midnightStartTimeMinute: number) {
    this.rawData.midnightStartTimeMinute = midnightStartTimeMinute;
  }

  get midnightEndTime(): string {
    return this.rawData.midnightEndTime;
  }

  get midnightEndTimeMoment(): moment.Moment {
    if (this.rawData.midnightEndTime && this.rawData.midnightEndTime.length > 0) {
      const splitMidnightEndTime = this.rawData.midnightEndTime.split(':');
      if (splitMidnightEndTime[1]) {
        return moment()
          .hour(Number(splitMidnightEndTime[0]))
          .minutes(Number(splitMidnightEndTime[1]));
      }
    }
    return moment().hour(5).minutes(0);
  }

  set midnightEndTime(midnightEndTime: string) {
    this.rawData.midnightEndTime = midnightEndTime;
    // if (midnightEndTime != null && midnightEndTime != undefined && midnightEndTime.length > 4) {
    //   this.rawData.midnightEndTimeHour = Number(midnightEndTime.substr(0, 2));
    //   this.rawData.midnightEndTimeMinute = Number(midnightEndTime.substr(3, 2));
    // }
  }

  // get midnightEndTimeHour(): number {
  //   return this.rawData.midnightEndTimeHour;
  // }

  // set midnightEndTimeHour(midnightEndTimeHour: number) {
  //   this.rawData.midnightEndTimeHour = midnightEndTimeHour;
  // }

  // get midnightEndTimeMinute(): number {
  //   return this.rawData.midnightEndTimeMinute;
  // }

  // set midnightEndTimeMinute(midnightEndTimeMinute: number) {
  //   this.rawData.midnightEndTimeMinute = midnightEndTimeMinute;
  // }

  get dispKyukaNames(): string {
    return this.rawData.dispKyukaNames;
  }

  set dispKyukaNames(dispKyukaNames: string) {
    this.rawData.dispKyukaNames = dispKyukaNames;
  }

  get paidHolidayPatternId(): string {
    return this.rawData.paidHolidayPatternId;
  }

  set paidHolidayPatternId(paidHolidayPatternId: string) {
    this.rawData.paidHolidayPatternId = paidHolidayPatternId;
  }

  get employmentName(): string {
    return this.rawData.employmentName;
  }

  set employmentName(employmentName: string) {
    this.rawData.employmentName = employmentName;
  }

  get applyStartDate(): Date {
    return moment(this.rawData.id.applyStartDate).toDate();
    // return moment(moment(this.rawData.id.applyStartDate).toDate()).format('YYYY-MM-DD');
    // return moment(this.rawData.id.applyStartDate).toDate();
    // // return moment(this.rawData.id.applyStartDate).toDate();
    // return moment(this.rawData.id.applyStartDate).format('YYYY-MM-DD').toDate();
  }

  set applyStartDate(date: Date) {
    this.rawData.id.applyStartDate = moment(date).format('YYYY/MM/DD');
  }

  get applyStartDateString(): string {
    return moment(moment(this.rawData.id.applyStartDate).toDate()).format('YYYY年MM月');
  }

  get paidBonusAwardDate(): Date {
    return moment(this.rawData.paidBonusAwardDate).toDate();
  }

  set paidBonusAwardDate(paidBonusAwardDate: Date) {
    this.rawData.paidBonusAwardDate = moment(paidBonusAwardDate).format('YYYY/MM/DD');
  }

  get applyEndDate(): Date {
    return moment(this.rawData.applyEndDate).toDate();
  }

  set applyEndDate(date: Date) {
    this.rawData.applyEndDate = moment(date).format('YYYY/MM/DD');
  }

  get applyDate(): Date {
    return moment(this.rawData.applyDate).toDate();
  }

  get dispApplyDate(): Date {
    return moment(this.rawData.dispApplyDate).toDate();
  }

  get dispApplyDateStr(): string {
    return moment(moment(this.rawData.dispApplyDate).toDate()).format('YYYY年MM月');
  }

  get applyDateStringDashFormat(): string {
    return moment(moment(this.rawData.id.applyStartDate).toDate()).format('YYYY-MM-DD');
  }

  set applyDate(date: Date) {
    this.rawData.applyDate = moment(date).format('YYYY/MM/DD');
  }

  set dispApplyDate(date: Date) {
    this.rawData.dispApplyDate = moment(date).format('YYYY/MM/DD');
  }


  // get applyDateStr(): string {
  //   return this.rawData.applyDateStr;
  // }

  // set applyDateStr(applyDateStr: string) {
  //   this.rawData.applyDateStr = applyDateStr;
  // }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set closingDayOfWeek(closingDayOfWeek: number) {
    this.rawData.closingDayOfWeek = closingDayOfWeek;
  }

  get closingDayOfWeek(): number {
    return this.rawData.closingDayOfWeek;
  }

  set workingForm(workingForm: number) {
    this.rawData.workingForm = workingForm;
  }

  get workingForm(): number {
    return this.rawData.workingForm;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set contractWorkDateNumWeek(contractWorkDateNumWeek: number) {
    this.rawData.contractWorkDateNumWeek = contractWorkDateNumWeek;
  }

  get contractWorkDateNumWeek(): number {
    return this.rawData.contractWorkDateNumWeek;
  }

  set workSystem(workSystem: number) {
    this.rawData.workSystem = workSystem;
  }

  get workSystem(): number {
    return this.rawData.workSystem;
  }

  set startTime(startTime: string) {
    this.rawData.startTime = startTime;
    if (startTime !== null && startTime !== undefined && startTime.length > 4) {
      this.rawData.startTimeHour = Number(startTime.substr(0, 2));
      this.rawData.startTimeMinute = Number(startTime.substr(3, 2));
    }
  }

  get startTime(): string {
    return this.rawData.startTime;
  }

  get startTimeMoment(): moment.Moment {
    if (this.rawData.startTime && this.rawData.startTime.length > 0) {
      return moment()
        .hour(Number(this.rawData.startTime.substr(0, 2)))
        .minutes(Number(this.rawData.startTime.substr(3, 2)));
    }
    return moment().hour(0).minutes(0);
  }

  set startTimeHour(startTimeHour: number) {
    this.rawData.startTimeHour = startTimeHour;
  }

  get startTimeHour(): number {
    return this.rawData.startTimeHour;
  }

  set startTimeMinute(startTimeMinute: number) {
    this.rawData.startTimeMinute = startTimeMinute;
  }

  get startTimeMinute(): number {
    return this.rawData.startTimeMinute;
  }

  set endTime(endTime: string) {
    this.rawData.endTime = endTime;
    if (endTime !== null && endTime !== undefined && endTime.length > 4) {
      this.rawData.endTimeHour = Number(endTime.substr(0, 2));
      this.rawData.endTimeMinute = Number(endTime.substr(3, 2));
    }
  }

  get endTime(): string {
    return this.rawData.endTime;
  }

  get endTimeMoment(): moment.Moment {
    if (this.rawData.endTime && this.rawData.endTime.length > 0) {
      return moment()
        .hour(Number(this.rawData.endTime.substr(0, 2)))
        .minutes(Number(this.rawData.endTime.substr(3, 2)));
    }
    return moment().hour(0).minutes(0);
  }

  set endTimeHour(endTimeHour: number) {
    this.rawData.endTimeHour = endTimeHour;
  }

  get endTimeHour(): number {
    return this.rawData.endTimeHour;
  }

  set endTimeMinute(endTimeMinute: number) {
    this.rawData.endTimeMinute = endTimeMinute;
  }

  get endTimeMinute(): number {
    return this.rawData.endTimeMinute;
  }

  set useAutoRecess(useAutoRecess: number) {
    this.rawData.useAutoRecess = useAutoRecess;
  }

  get useAutoRecess(): number {
    return this.rawData.useAutoRecess;
  }

  set autoRecessWorkConditionTime1(autoRecessWorkConditionTime1: number) {
    this.rawData.autoRecessWorkConditionTime1 = autoRecessWorkConditionTime1;
  }

  get autoRecessWorkConditionTime1(): number {
    return this.rawData.autoRecessWorkConditionTime1;
  }

  set autoRecessGrantTime1(autoRecessGrantTime1: number) {
    this.rawData.autoRecessGrantTime1 = autoRecessGrantTime1;
  }

  get autoRecessGrantTime1(): number {
    return this.rawData.autoRecessGrantTime1;
  }

  set autoRecessWorkConditionTime2(autoRecessWorkConditionTime2: number) {
    this.rawData.autoRecessWorkConditionTime2 = autoRecessWorkConditionTime2;
  }

  get autoRecessWorkConditionTime2(): number {
    return this.rawData.autoRecessWorkConditionTime2;
  }

  set autoRecessGrantTime2(autoRecessGrantTime2: number) {
    this.rawData.autoRecessGrantTime2 = autoRecessGrantTime2;
  }

  get autoRecessGrantTime2(): number {
    return this.rawData.autoRecessGrantTime2;
  }

  set autoRecessWorkConditionTime3(autoRecessWorkConditionTime3: number) {
    this.rawData.autoRecessWorkConditionTime3 = autoRecessWorkConditionTime3;
  }

  get autoRecessWorkConditionTime3(): number {
    return this.rawData.autoRecessWorkConditionTime3;
  }

  set autoRecessGrantTime3(autoRecessGrantTime3: number) {
    this.rawData.autoRecessGrantTime3 = autoRecessGrantTime3;
  }

  get autoRecessGrantTime3(): number {
    return this.rawData.autoRecessGrantTime3;
  }

  set autoRecessPriorityOfSubtract(autoRecessPriorityOfSubtract: number) {
    this.rawData.autoRecessPriorityOfSubtract = autoRecessPriorityOfSubtract;
  }

  get autoRecessPriorityOfSubtract(): number {
    return this.rawData.autoRecessPriorityOfSubtract;
  }

  set autoRecessDuplication(autoRecessDuplication: number) {
    this.rawData.autoRecessDuplication = autoRecessDuplication;
  }

  get autoRecessDuplication(): number {
    return this.rawData.autoRecessDuplication;
  }

  // set autoRecessNum(autoRecessNum: number) {
  //   this.rawData.autoRecessNum = autoRecessNum;
  // }

  // get autoRecessNum(): number {
  //   return this.rawData.autoRecessNum;
  // }

  set recessStartTime1(recessStartTime1: string) {
    this.rawData.recessStartTime1 = recessStartTime1;
  }

  get recessStartTime1(): string {
    return this.rawData.recessStartTime1;
  }

  set recessEndTime1(recessEndTime1: string) {
    this.rawData.recessEndTime1 = recessEndTime1;
  }

  get recessEndTime1(): string {
    return this.rawData.recessEndTime1;
  }

  get recessStartTime1Moment(): moment.Moment {
    if (this.rawData.recessStartTime1 && this.rawData.recessStartTime1.length > 0) {
      return moment()
        .hour(Number(this.rawData.recessStartTime1.substr(0, 2)))
        .minutes(Number(this.rawData.recessStartTime1.substr(3, 2)));
    }
    return moment().hour(0).minutes(0);
  }

  get recessEndTime1Moment(): moment.Moment {
    if (this.rawData.recessEndTime1 && this.rawData.recessEndTime1.length > 0) {
      return moment()
        .hour(Number(this.rawData.recessEndTime1.substr(0, 2)))
        .minutes(Number(this.rawData.recessEndTime1.substr(3, 2)));
    }
    return moment().hour(0).minutes(0);
  }

  set recessStartTime2(recessStartTime2: string) {
    this.rawData.recessStartTime2 = recessStartTime2;
  }

  get recessStartTime2(): string {
    return this.rawData.recessStartTime2;
  }

  set recessEndTime2(recessEndTime2: string) {
    this.rawData.recessEndTime2 = recessEndTime2;
  }

  get recessEndTime2(): string {
    return this.rawData.recessEndTime2;
  }

  get recessStartTime2Moment(): moment.Moment {
    if (this.rawData.recessStartTime2 && this.rawData.recessStartTime2.length > 0) {
      return moment()
        .hour(Number(this.rawData.recessStartTime2.substr(0, 2)))
        .minutes(Number(this.rawData.recessStartTime2.substr(3, 2)));
    }
    return moment().hour(0).minutes(0);
  }

  get recessEndTime2Moment(): moment.Moment {
    if (this.rawData.recessEndTime2 && this.rawData.recessEndTime2.length > 0) {
      return moment()
        .hour(Number(this.rawData.recessEndTime2.substr(0, 2)))
        .minutes(Number(this.rawData.recessEndTime2.substr(3, 2)));
    }
    return moment().hour(0).minutes(0);
  }

  set recessStartTime3(recessStartTime3: string) {
    this.rawData.recessStartTime3 = recessStartTime3;
  }

  get recessStartTime3(): string {
    return this.rawData.recessStartTime3;
  }

  set recessEndTime3(recessEndTime3: string) {
    this.rawData.recessEndTime3 = recessEndTime3;
  }

  get recessEndTime3(): string {
    return this.rawData.recessEndTime3;
  }

  get recessStartTime3Moment(): moment.Moment {
    if (this.rawData.recessStartTime3 && this.rawData.recessStartTime3.length > 0) {
      return moment()
        .hour(Number(this.rawData.recessStartTime3.substr(0, 2)))
        .minutes(Number(this.rawData.recessStartTime3.substr(3, 2)));
    }
    return moment().hour(0).minutes(0);
  }

  get recessEndTime3Moment(): moment.Moment {
    if (this.rawData.recessEndTime3 && this.rawData.recessEndTime3.length > 0) {
      return moment()
        .hour(Number(this.rawData.recessEndTime3.substr(0, 2)))
        .minutes(Number(this.rawData.recessEndTime3.substr(3, 2)));
    }
    return moment().hour(0).minutes(0);
  }

  set isStartTimeNextDay(isStartTimeNextDay: boolean) {
    this.rawData.isStartTimeNextDay = isStartTimeNextDay;
  }

  get isStartTimeNextDay(): boolean {
    return this.rawData.isStartTimeNextDay;
  }

  set isEndTimeNextDay(isEndTimeNextDay: boolean) {
    this.rawData.isEndTimeNextDay = isEndTimeNextDay;
  }

  get isEndTimeNextDay(): boolean {
    return this.rawData.isEndTimeNextDay;
  }

  set isRecessStartTimeNextDay1(isRecessStartTimeNextDay1: boolean) {
    this.rawData.isRecessStartTimeNextDay1 = isRecessStartTimeNextDay1;
  }

  get isRecessStartTimeNextDay1(): boolean {
    return this.rawData.isRecessStartTimeNextDay1;
  }

  set isRecessEndTimeNextDay1(isRecessEndTimeNextDay1: boolean) {
    this.rawData.isRecessEndTimeNextDay1 = isRecessEndTimeNextDay1;
  }

  get isRecessEndTimeNextDay1(): boolean {
    return this.rawData.isRecessEndTimeNextDay1;
  }

  set isRecessStartTimeNextDay2(isRecessStartTimeNextDay2: boolean) {
    this.rawData.isRecessStartTimeNextDay2 = isRecessStartTimeNextDay2;
  }

  get isRecessStartTimeNextDay2(): boolean {
    return this.rawData.isRecessStartTimeNextDay2;
  }

  set isRecessEndTimeNextDay2(isRecessEndTimeNextDay2: boolean) {
    this.rawData.isRecessEndTimeNextDay2 = isRecessEndTimeNextDay2;
  }

  get isRecessEndTimeNextDay2(): boolean {
    return this.rawData.isRecessEndTimeNextDay2;
  }

  set isRecessStartTimeNextDay3(isRecessStartTimeNextDay3: boolean) {
    this.rawData.isRecessStartTimeNextDay3 = isRecessStartTimeNextDay3;
  }

  get isRecessStartTimeNextDay3(): boolean {
    return this.rawData.isRecessStartTimeNextDay3;
  }

  set isRecessEndTimeNextDay3(isRecessEndTimeNextDay3: boolean) {
    this.rawData.isRecessEndTimeNextDay3 = isRecessEndTimeNextDay3;
  }

  get isRecessEndTimeNextDay3(): boolean {
    return this.rawData.isRecessEndTimeNextDay3;
  }

  set roundMethodStartStamp(roundMethodStartStamp: number) {
    this.rawData.roundMethodStartStamp = roundMethodStartStamp;
  }

  get roundMethodStartStamp(): number {
    return this.rawData.roundMethodStartStamp;
  }

  set roundMethodEndStamp(roundMethodEndStamp: number) {
    this.rawData.roundMethodEndStamp = roundMethodEndStamp;
  }

  get roundMethodEndStamp(): number {
    return this.rawData.roundMethodEndStamp;
  }

  set roundUnitStartStamp(roundUnitStartStamp: number) {
    this.rawData.roundUnitStartStamp = roundUnitStartStamp;
  }

  get roundUnitStartStamp(): number {
    return this.rawData.roundUnitStartStamp;
  }

  set roundTimingRecess(roundTimingRecess: number) {
    this.rawData.roundTimingRecess = roundTimingRecess;
  }

  get roundTimingRecess(): number {
    return this.rawData.roundTimingRecess;
  }

  set roundUnitRecessStamp(roundUnitRecessStamp: number) {
    this.rawData.roundUnitRecessStamp = roundUnitRecessStamp;
  }

  get roundUnitRecessStamp(): number {
    return this.rawData.roundUnitRecessStamp;
  }

  set roundUnitDay(roundUnitDay: string) {
    this.rawData.roundUnitDay = roundUnitDay;
  }

  get roundUnitDay(): string {
    return this.rawData.roundUnitDay;
  }

  set roundMethodDay(roundMethodDay: string) {
    this.rawData.roundMethodDay = roundMethodDay;
  }

  get roundMethodDay(): string {
    return this.rawData.roundMethodDay;
  }

  set roundUnitMonth(roundUnitMonth: string) {
    this.rawData.roundUnitMonth = roundUnitMonth;
  }

  get roundUnitMonth(): string {
    return this.rawData.roundUnitMonth;
  }

  set roundMethodMonth(roundMethodMonth: string) {
    this.rawData.roundMethodMonth = roundMethodMonth;
  }

  get roundMethodMonth(): string {
    return this.rawData.roundMethodMonth;
  }

  set roundShiftStartTime(roundShiftStartTime: number) {
    this.rawData.roundShiftStartTime = roundShiftStartTime;
  }

  get roundShiftStartTime(): number {
    return this.rawData.roundShiftStartTime;
  }

  set roundShiftEndTime(roundShiftEndTime: number) {
    this.rawData.roundShiftEndTime = roundShiftEndTime;
  }

  get roundShiftEndTime(): number {
    return this.rawData.roundShiftEndTime;
  }

  set roundEndElapsedTime(roundEndElapsedTime: number) {
    this.rawData.roundEndElapsedTime = roundEndElapsedTime;
  }

  get roundEndElapsedTime(): number {
    return this.rawData.roundEndElapsedTime;
  }

  set useGpsStamp(useGpsStamp: number) {
    this.rawData.useGpsStamp = useGpsStamp;
  }

  get useGpsStamp(): number {
    return this.rawData.useGpsStamp;
  }

  set warningDistance(warningDistance: string) {
    this.rawData.warningDistance = warningDistance;
  }

  get warningDistance(): string {
    return this.rawData.warningDistance;
  }

  set calcOverTimeInDay(calcOverTimeInDay: boolean) {
    this.rawData.calcOverTimeInDay = calcOverTimeInDay;
  }

  get calcOverTimeInDay(): boolean {
    return this.rawData.calcOverTimeInDay;
  }

  set prescribedWorkHourOfDay(prescribedWorkHourOfDay: number) {
    this.rawData.prescribedWorkHourOfDay = prescribedWorkHourOfDay;
  }

  get prescribedWorkHourOfDay(): number {
    return this.rawData.prescribedWorkHourOfDay;
  }

  set letShiftPredeterminedWorkTimeOfDay(letShiftPredeterminedWorkTimeOfDay: number) {
    this.rawData.letShiftPredeterminedWorkTimeOfDay = letShiftPredeterminedWorkTimeOfDay;
  }

  get letShiftPredeterminedWorkTimeOfDay(): number {
    return this.rawData.letShiftPredeterminedWorkTimeOfDay;
  }

  set handleLegalInOvertime(handleLegalInOvertime: number) {
    this.rawData.handleLegalInOvertime = handleLegalInOvertime;
  }

  get handleLegalInOvertime(): number {
    return this.rawData.handleLegalInOvertime;
  }

  // set calcLegalOverTime(calcLegalOverTime: number) {
  //   this.rawData.calcLegalOverTime = calcLegalOverTime;
  // }

  // get calcLegalOverTime(): number {
  //   return this.rawData.calcLegalOverTime;
  // }
  
  set treatmentStatutoryHolidayOvertime(treatmentStatutoryHolidayOvertime: number) {
    this.rawData.treatmentStatutoryHolidayOvertime = treatmentStatutoryHolidayOvertime;
  }

  get treatmentStatutoryHolidayOvertime(): number {
    return this.rawData.treatmentStatutoryHolidayOvertime;
  }

  set calcOverTimeInWeek(calcOverTimeInWeek: boolean) {
    this.rawData.calcOverTimeInWeek = calcOverTimeInWeek;
  }

  get calcOverTimeInWeek(): boolean {
    return this.rawData.calcOverTimeInWeek;
  }

  set prescribedWorkHourOfWeek(prescribedWorkHourOfWeek: number) {
    this.rawData.prescribedWorkHourOfWeek = prescribedWorkHourOfWeek;
  }

  get prescribedWorkHourOfWeek(): number {
    return this.rawData.prescribedWorkHourOfWeek;
  }

  set letShiftPredeterminedWorkTimeOfWeek(letShiftPredeterminedWorkTimeOfWeek: number) {
    this.rawData.letShiftPredeterminedWorkTimeOfWeek = letShiftPredeterminedWorkTimeOfWeek;
  }

  get letShiftPredeterminedWorkTimeOfWeek(): number {
    return this.rawData.letShiftPredeterminedWorkTimeOfWeek;
  }

  // set carryOverMonthMethod(carryOverMonthMethod: number) {
  //   this.rawData.carryOverMonthMethod = carryOverMonthMethod;
  // }

  // get carryOverMonthMethod(): number {
  //   return this.rawData.carryOverMonthMethod;
  // }

  // set carryOverMonthRounding(carryOverMonthRounding: number) {
  //   this.rawData.carryOverMonthRounding = carryOverMonthRounding;
  // }

  // get carryOverMonthRounding(): number {
  //   return this.rawData.carryOverMonthRounding;
  // }

  set handleShiftOutsideTimeOfWeek(handleShiftOutsideTimeOfWeek: string) {
    this.rawData.handleShiftOutsideTimeOfWeek = handleShiftOutsideTimeOfWeek;
  }

  get handleShiftOutsideTimeOfWeek(): string {
    return this.rawData.handleShiftOutsideTimeOfWeek;
  }

  set calcOverTimeInMonth(calcOverTimeInMonth: boolean) {
    this.rawData.calcOverTimeInMonth = calcOverTimeInMonth;
  }

  get calcOverTimeInMonth(): boolean {
    return this.rawData.calcOverTimeInMonth;
  }

  set workingHoursId(workingHoursId: string) {
    this.rawData.workingHoursId = workingHoursId;
  }

  get workingHoursId(): string {
    return this.rawData.workingHoursId;
  }

  set paidHolidayPattern(paidHolidayPattern: string) {
    this.rawData.paidHolidayPattern = paidHolidayPattern;
  }

  get paidHolidayPattern(): string {
    return this.rawData.paidHolidayPattern;
  }


  set useExtraOverTime(useExtraOverTime: boolean) {
    this.rawData.useExtraOverTime = useExtraOverTime;
  }

  get useExtraOverTime(): boolean {
    return this.rawData.useExtraOverTime;
  }

  set useOverTimeApplication(useOverTimeApplication: number) {
    this.rawData.useOverTimeApplication = useOverTimeApplication;
  }

  get useOverTimeApplication(): number {
    return this.rawData.useOverTimeApplication;
  }

  set extraOverTime1(extraOverTime1: number) {
    this.rawData.extraOverTime1 = extraOverTime1;
  }

  get extraOverTime1(): number {
    return this.rawData.extraOverTime1;
  }

  set extraOverTime2(extraOverTime2: number) {
    this.rawData.extraOverTime2 = extraOverTime2;
  }

  get extraOverTime2(): number {
    return this.rawData.extraOverTime2;
  }

  set extraOverTime3(extraOverTime3: number) {
    this.rawData.extraOverTime3 = extraOverTime3;
  }

  get extraOverTime3(): number {
    return this.rawData.extraOverTime3;
  }

  set holidayWorkBgColor(holidayWorkBgColor: string) {
    this.rawData.holidayWorkBgColor = holidayWorkBgColor;
  }

  get holidayWorkBgColor(): string {
    return this.rawData.holidayWorkBgColor;
  }

  set holidayWorkForeColor(holidayWorkForeColor: string) {
    this.rawData.holidayWorkForeColor = holidayWorkForeColor;
  }

  get holidayWorkForeColor(): string {
    return this.rawData.holidayWorkForeColor;
  }

  set necessaryHolidayWorkApplication(necessaryHolidayWorkApplication: boolean) {
    this.rawData.necessaryHolidayWorkApplication = necessaryHolidayWorkApplication;
  }

  get necessaryHolidayWorkApplication(): boolean {
    return this.rawData.necessaryHolidayWorkApplication;
  }

  set letPubBeLegalHoliday(letPubBeLegalHoliday: boolean) {
    this.rawData.letPubBeLegalHoliday = letPubBeLegalHoliday;
  }

  get letPubBeLegalHoliday(): boolean {
    return this.rawData.letPubBeLegalHoliday;
  }

  set letSatBeLegalHoliday(letSatBeLegalHoliday: boolean) {
    this.rawData.letSatBeLegalHoliday = letSatBeLegalHoliday;
  }

  get letSatBeLegalHoliday(): boolean {
    return this.rawData.letSatBeLegalHoliday;
  }

  set letSunBeLegalHoliday(letSunBeLegalHoliday: boolean) {
    this.rawData.letSunBeLegalHoliday = letSunBeLegalHoliday;
  }

  get letSunBeLegalHoliday(): boolean {
    return this.rawData.letSunBeLegalHoliday;
  }

  set letMonBeLegalHoliday(letMonBeLegalHoliday: boolean) {
    this.rawData.letMonBeLegalHoliday = letMonBeLegalHoliday;
  }

  get letMonBeLegalHoliday(): boolean {
    return this.rawData.letMonBeLegalHoliday;
  }

  set letTueBeLegalHoliday(letTueBeLegalHoliday: boolean) {
    this.rawData.letTueBeLegalHoliday = letTueBeLegalHoliday;
  }

  get letTueBeLegalHoliday(): boolean {
    return this.rawData.letTueBeLegalHoliday;
  }

  set letWedBeLegalHoliday(letWedBeLegalHoliday: boolean) {
    this.rawData.letWedBeLegalHoliday = letWedBeLegalHoliday;
  }

  get letWedBeLegalHoliday(): boolean {
    return this.rawData.letWedBeLegalHoliday;
  }

  set letTurBeLegalHoliday(letTurBeLegalHoliday: boolean) {
    this.rawData.letTurBeLegalHoliday = letTurBeLegalHoliday;
  }

  get letTurBeLegalHoliday(): boolean {
    return this.rawData.letTurBeLegalHoliday;
  }

  set letFriBeLegalHoliday(letFriBeLegalHoliday: boolean) {
    this.rawData.letFriBeLegalHoliday = letFriBeLegalHoliday;
  }

  get letFriBeLegalHoliday(): boolean {
    return this.rawData.letFriBeLegalHoliday;
  }

  set distinctionHoliday(distinctionHoliday: boolean) {
    this.rawData.distinctionHoliday = distinctionHoliday;
  }

  get distinctionHoliday(): boolean {
    return this.rawData.distinctionHoliday;
  }

  set letPubBeNonLegalHoliday(letPubBeNonLegalHoliday: boolean) {
    this.rawData.letPubBeNonLegalHoliday = letPubBeNonLegalHoliday;
  }

  get letPubBeNonLegalHoliday(): boolean {
    return this.rawData.letPubBeNonLegalHoliday;
  }

  set letSatBeNonLegalHoliday(letSatBeNonLegalHoliday: boolean) {
    this.rawData.letSatBeNonLegalHoliday = letSatBeNonLegalHoliday;
  }

  get letSatBeNonLegalHoliday(): boolean {
    return this.rawData.letSatBeNonLegalHoliday;
  }

  set letSunBeNonLegalHoliday(letSunBeNonLegalHoliday: boolean) {
    this.rawData.letSunBeNonLegalHoliday = letSunBeNonLegalHoliday;
  }

  get letSunBeNonLegalHoliday(): boolean {
    return this.rawData.letSunBeNonLegalHoliday;
  }

  set letMonBeNonLegalHoliday(letMonBeNonLegalHoliday: boolean) {
    this.rawData.letMonBeNonLegalHoliday = letMonBeNonLegalHoliday;
  }

  get letMonBeNonLegalHoliday(): boolean {
    return this.rawData.letMonBeNonLegalHoliday;
  }

  set letTueBeNonLegalHoliday(letTueBeNonLegalHoliday: boolean) {
    this.rawData.letTueBeNonLegalHoliday = letTueBeNonLegalHoliday;
  }

  get letTueBeNonLegalHoliday(): boolean {
    return this.rawData.letTueBeNonLegalHoliday;
  }

  set letWedBeNonLegalHoliday(letWedBeNonLegalHoliday: boolean) {
    this.rawData.letWedBeNonLegalHoliday = letWedBeNonLegalHoliday;
  }

  get letWedBeNonLegalHoliday(): boolean {
    return this.rawData.letWedBeNonLegalHoliday;
  }

  set letTurBeNonLegalHoliday(letTurBeNonLegalHoliday: boolean) {
    this.rawData.letTurBeNonLegalHoliday = letTurBeNonLegalHoliday;
  }

  get letTurBeNonLegalHoliday(): boolean {
    return this.rawData.letTurBeNonLegalHoliday;
  }

  set letFriBeNonLegalHoliday(letFriBeNonLegalHoliday: boolean) {
    this.rawData.letFriBeNonLegalHoliday = letFriBeNonLegalHoliday;
  }

  get letFriBeNonLegalHoliday(): boolean {
    return this.rawData.letFriBeNonLegalHoliday;
  }

  set autoPaidManageFunc(autoPaidManageFunc: boolean) {
    this.rawData.autoPaidManageFunc = autoPaidManageFunc;
  }

  get autoPaidManageFunc(): boolean {
    return this.rawData.autoPaidManageFunc;
  }

  set paidProvisionMethod(paidProvisionMethod: number) {
    this.rawData.paidProvisionMethod = paidProvisionMethod;
  }

  get paidProvisionMethod(): number {
    return this.rawData.paidProvisionMethod;
  }

  set paidBonusAwardDateMonth(paidBonusAwardDateMonth: number) {
    this.rawData.paidBonusAwardDateMonth = paidBonusAwardDateMonth;
  }

  get paidBonusAwardDateMonth(): number {
    return this.rawData.paidBonusAwardDateMonth;
  }

  set paidBonusAwardDateDay(paidBonusAwardDateDay: number) {
    this.rawData.paidBonusAwardDateDay = paidBonusAwardDateDay;
  }

  get paidBonusAwardDateDay(): number {
    return this.rawData.paidBonusAwardDateDay;
  }

  set paidHolidayInitialGrantMonths(paidHolidayInitialGrantMonths: number) {
    this.rawData.paidHolidayInitialGrantMonths = paidHolidayInitialGrantMonths;
  }

  get paidHolidayInitialGrantMonths(): number {
    return this.rawData.paidHolidayInitialGrantMonths;
  }

  set paidHolidayInitialGrantDays(paidHolidayInitialGrantDays: number) {
    this.rawData.paidHolidayInitialGrantDays = paidHolidayInitialGrantDays;
  }

  get paidHolidayInitialGrantDays(): number {
    return this.rawData.paidHolidayInitialGrantDays;
  }

  set calcMethodPaidGrantDays(calcMethodPaidGrantDays: number) {
    this.rawData.calcMethodPaidGrantDays = calcMethodPaidGrantDays;
  }

  get calcMethodPaidGrantDays(): number {
    return this.rawData.calcMethodPaidGrantDays;
  }

  set grantedPaidAtCompanyEntry(grantedPaidAtCompanyEntry: string) {
    this.rawData.grantedPaidAtCompanyEntry = grantedPaidAtCompanyEntry;
  }

  get grantedPaidAtCompanyEntry(): string {
    return this.rawData.grantedPaidAtCompanyEntry;
  }

  set paidDigestionOrder(paidDigestionOrder: number) {
    this.rawData.paidDigestionOrder = paidDigestionOrder;
  }

  get paidDigestionOrder(): number {
    return this.rawData.paidDigestionOrder;
  }

  set timeHoliday(timeHoliday: string) {
    this.rawData.timeHoliday = timeHoliday;
  }

  get timeHoliday(): string {
    return this.rawData.timeHoliday;
  }

  set timeHolidayMaxHour(timeHolidayMaxHour: string) {
    this.rawData.timeHolidayMaxHour = timeHolidayMaxHour;
  }

  get timeHolidayMaxHour(): string {
    return this.rawData.timeHolidayMaxHour;
  }

  set timeHolidayHourOfDay(timeHolidayHourOfDay: string) {
    this.rawData.timeHolidayHourOfDay = timeHolidayHourOfDay;
  }

  get timeHolidayHourOfDay(): string {
    return this.rawData.timeHolidayHourOfDay;
  }

  set useTransferApplication(useTransferApplication: number) {
    this.rawData.useTransferApplication = useTransferApplication;
  }

  get useTransferApplication(): number {
    return this.rawData.useTransferApplication;
  }

  set transferWorkBgColor(transferWorkBgColor: string) {
    this.rawData.transferWorkBgColor = transferWorkBgColor;
  }

  get transferWorkBgColor(): string {
    return this.rawData.transferWorkBgColor;
  }

  set transferWorkForeColor(transferWorkForeColor: string) {
    this.rawData.transferWorkForeColor = transferWorkForeColor;
  }

  get transferWorkForeColor(): string {
    return this.rawData.transferWorkForeColor;
  }

  set transferHolidayBgColor(transferHolidayBgColor: string) {
    this.rawData.transferHolidayBgColor = transferHolidayBgColor;
  }

  get transferHolidayBgColor(): string {
    return this.rawData.transferHolidayBgColor;
  }

  set transferHolidayForeColor(transferHolidayForeColor: string) {
    this.rawData.transferHolidayForeColor = transferHolidayForeColor;
  }

  get transferHolidayForeColor(): string {
    return this.rawData.transferHolidayForeColor;
  }

  set autoHolidayFunc(autoHolidayFunc: boolean) {
    this.rawData.autoHolidayFunc = autoHolidayFunc;
  }

  get autoHolidayFunc(): boolean {
    return this.rawData.autoHolidayFunc;
  }

  set overTimeAlert(overTimeAlert: number) {
    this.rawData.overTimeAlert = overTimeAlert;
  }

  get overTimeAlert(): number {
    return this.rawData.overTimeAlert;
  }

  set recessTimeAlertWorkTime1(recessTimeAlertWorkTime1: number) {
    this.rawData.recessTimeAlertWorkTime1 = recessTimeAlertWorkTime1;
  }

  get recessTimeAlertWorkTime1(): number {
    return this.rawData.recessTimeAlertWorkTime1;
  }

  set recessTimeAlertTime1(recessTimeAlertTime1: number) {
    this.rawData.recessTimeAlertTime1 = recessTimeAlertTime1;
  }

  get recessTimeAlertTime1(): number {
    return this.rawData.recessTimeAlertTime1;
  }

  set recessTimeAlertWorkTime2(recessTimeAlertWorkTime2: number) {
    this.rawData.recessTimeAlertWorkTime2 = recessTimeAlertWorkTime2;
  }

  get recessTimeAlertWorkTime2(): number {
    return this.rawData.recessTimeAlertWorkTime2;
  }

  set recessTimeAlertTime2(recessTimeAlertTime2: number) {
    this.rawData.recessTimeAlertTime2 = recessTimeAlertTime2;
  }

  get recessTimeAlertTime2(): number {
    return this.rawData.recessTimeAlertTime2;
  }

  set recessTimeAlertWorkTime3(recessTimeAlertWorkTime3: number) {
    this.rawData.recessTimeAlertWorkTime3 = recessTimeAlertWorkTime3;
  }

  get recessTimeAlertWorkTime3(): number {
    return this.rawData.recessTimeAlertWorkTime3;
  }

  set recessTimeAlertTime3(recessTimeAlertTime3: number) {
    this.rawData.recessTimeAlertTime3 = recessTimeAlertTime3;
  }

  get recessTimeAlertTime3(): number {
    return this.rawData.recessTimeAlertTime3;
  }

  set continuousWorkAlert(continuousWorkAlert: number) {
    this.rawData.continuousWorkAlert = continuousWorkAlert;
  }

  get continuousWorkAlert(): number {
    return this.rawData.continuousWorkAlert;
  }

  get holidayPattern(): AttendEmploymentHolidayPatternData | undefined {
    return this.rawData.holidayPattern;
  }

  set holidayPattern(holidayPattern: AttendEmploymentHolidayPatternData | undefined) {
    this.rawData.holidayPattern = holidayPattern;
  }

  get isDisplayAutoBreak(): boolean {
    return this.rawData.isDisplayAutoBreak;
  }

  set isDisplayAutoBreak(isDisplayAutoBreak: boolean) {
    this.rawData.isDisplayAutoBreak = isDisplayAutoBreak;
  } 

}
const convertDateToStringYYYYMMDD = (dateStr?: string) => {
  if (!dateStr) {
    return '';
  }
  return moment(dateStr).format('YYYY/MM/DD');
};
