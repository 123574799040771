import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import FileSelectButton from 'components/molecules/FileSelectButton';
import { utilityColor } from 'components/styles';
import BodyText from 'components/atoms/BodyText';
import ConfirmModal from 'components/organismos/ConfirmModal';
import BlockUI from 'components/molecules/BlockUi';
import useAccountTitleCsvUploadDomainForm from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';


const AccountTitleMSTImportForm: React.FC = () => {
  const {
    setUploadFile,
    uploadFile,
    onSubmit,
    errors,
    confirmModalOpen,
    closeConfirmModal,
    handleSubmit,
    inputValue,
    blocking,
  } = useAccountTitleCsvUploadDomainForm();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <div>
      <BlockUI blocking={blocking}>
        <FormContents>
          <FormTitle
            title="登録するデータ"
          />
          <BodyText color={utilityColor.error} size="md">※CSVファイルの１行目（ヘッダー行）はインポートされません。</BodyText>
          <FormField>
            <FileSelectButton
              text="CSVファイルをドラッグ＆ドロップ または クリックしてファイルを選択してください"
              ghost={true}
              setFile={setUploadFile}
              value={inputValue}
              accept=".csv"
            />
          </FormField>

          {uploadFile || errors
            ? (
              <FormField
                displayBlock={true}
              >
                <div>
                  {uploadFile?.name}
                </div>
                {errors && errors.map((error) => (
                  <div key={error}>
                    <BodyText color={utilityColor.error}>
                      {error}
                    </BodyText>
                  </div>
                ))}
              </FormField>
            )
            : null}
          <FormSubmitArea>
            {
              roleScreen && roleScreen.importFlag === 1 && (
                <PrimaryButton
                  text="インポート"
                  onClick={handleSubmit}
                  disabled={!uploadFile}
                />
              )
            }

            <PrimaryButton
              text="戻る"
              ghost={true}
            // eslint-disable-next-line no-restricted-globals
              onClick={() => history.back()}
            />
          </FormSubmitArea>
        </FormContents>

      </BlockUI>

      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        title="確認メッセージ"
        onSubmit={onSubmit}
        content="ファイルをアップロードします。よろしいですか？"
        items={[]}
      />
    </div>
  );
};


export default AccountTitleMSTImportForm;
