/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';
import { getList, getListWorkType, fetchBreakUUID } from 'api/shiftPattern';
import ShiftPatternDomain from 'domain/master/attend/shiftPattern';
import { OptionType } from 'components/atoms/Select';
import useToastNotification from 'hooks/useToastNotification';

export const useShiftPatternList = (orgCode: string, employmentId: string) => {
  const { errorNotification } = useToastNotification();
  const [shiftPatternList, setShiftPatternList] = useState<Array<ShiftPatternDomain>>([]);
  const [categoryArr, setCategoryArr] = useState<Array<String>>([]);
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  let addAllItem = false;
  const accessLevel = sessionStorage.getItem('loginUser.accessLevel');
  if (Number(accessLevel) === 1) {
    addAllItem = true;
  } else {
    addAllItem = false;
  }

  useEffect(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    // eslint-disable-next-line no-shadow
    const orgCode = sessionStorage.getItem('shiftPatternOrgCode') || (sessionStorage.getItem('loginUser.orgCode') || '');
    // eslint-disable-next-line no-shadow
    let employmentId = (sessionStorage.getItem('employmentIdSelected') !== 'NaN') ? sessionStorage.getItem('employmentIdSelected') : '';
    if (employmentId === 'all') {
      employmentId = '';
    }
    getList(companyCode, orgCode || '', employmentId || '').then((response: Array<any>) => {
      setShiftPatternList(response.map((result) => new ShiftPatternDomain(result)));
    });
  }, [employmentId, orgCode]);

  return {
    shiftPatternList, setShiftPatternList, categoryArr, setCategoryArr, staffCode, addAllItem
  };
};

export const useWorkTypeAll = () => {
  const [shiftPatternWorkType, setShiftPatternWorkType] = useState<Array<OptionType>>([]);
  const [getName, setName] = useState<Array<OptionType>>([]);

  useEffect(() => {
    getListWorkType().then((response: Array<any>) => {
      const orgs = response.map((res) => ({
        value: res.employmentId,
        label: `${res.employmentCode} ${res.employmentName}`,
      }));
      orgs.unshift({
        value: 'all',
        label: '-',
      });
      setShiftPatternWorkType(orgs);
      const name = response.map((res) => ({
        value: res.employmentId,
        label: `${res.employmentName}`,
      }));
      setName(name);
    });
  }, []);
  return { shiftPatternWorkType, getName };
};

export const findMaximunHour = (listShift: string | any[]) => {
  let maximumHour = listShift[0].endTime;
  let maxNextDay = listShift[0].isEndTimeNextDay;
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < listShift.length; i++) {
    // eslint-disable-next-line no-empty
    if (!listShift[i].isEndTimeNextDay && maxNextDay) {
    } else if (listShift[i].isEndTimeNextDay && !maxNextDay) {
      maximumHour = listShift[i].endTime;
      maxNextDay = listShift[i].isEndTimeNextDay;
    } else if (listShift[i].endTime > maximumHour) {
      maximumHour = listShift[i].endTime;
      maxNextDay = listShift[i].isEndTimeNextDay;
    }
  }
  return [maximumHour, maxNextDay];
};
export const findMinimumHour = (listShift: string | any[]) => {
  let miniHour = listShift[0].startTime;
  let miniNextDay = listShift[0].isStartTimeNextDay;
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < listShift.length; i++) {
    if (!listShift[i].isStartTimeNextDay && miniNextDay) {
      miniHour = listShift[i].startTime;
      miniNextDay = listShift[i].isStartTimeNextDay;
    // eslint-disable-next-line no-empty
    } else if (listShift[i].isStartTimeNextDay && !miniNextDay) {
    } else if (listShift[i].startTime < miniHour) {
      miniHour = listShift[i].startTime;
      miniNextDay = listShift[i].isStartTimeNextDay;
    }
  }
  return [miniHour, miniNextDay];
};


export const useBreakList = () => {
  const [shiftBreakList, setShiftBreakList] = useState<Array<ShiftPatternDomain>>([]);
  useEffect(() => {
    fetchBreakUUID().then((response: Array<any>) => {
      setShiftBreakList(response);
    });
  }, []);
  return { shiftBreakList, setShiftBreakList };
};

export const findBreakShift = (listShift: any, getshiftBreakList: any) => {
  let retList = [];
  if (getshiftBreakList != null) {
    retList = listShift.filter(
      (each: { businessId: any; }) => each.businessId === getshiftBreakList.businessId,
    ).sort((a: { isStartTimeNextDay: any; startTime: number; }, b: { isStartTimeNextDay: any; startTime: number; }) => {
      if (a.isStartTimeNextDay === b.isStartTimeNextDay) {
        if (a.startTime < b.startTime) { return -1; }
        if (a.startTime > b.startTime) { return 1; }
      } else if (a.isStartTimeNextDay) {
        return 1;
      } else if (b.isStartTimeNextDay) {
        return -1;
      }
      return 0;
    });
  }
  return retList;
};

export default {
  useShiftPatternList,
  useWorkTypeAll,
  findMaximunHour,
  findMinimumHour,
  useBreakList,
  findBreakShift,
};
