import { Staff } from 'domain/staff';
import moment from 'moment';
import { stringify } from 'querystring';
import ApiClient from './ApiClient';
import { OrgStatusType, UserRegistrationStatusType, Values } from 'utility/constants';
import { StaffRegistrationStatus } from 'domain/master/general/staffRegistrationStatus';

export type Error = {
    error: string;
    defaultMessage: string;
  }

const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export const getListStaffRegistrationStatus = async (
    orgCode: string,
    categoryAry: any,
    orgStatus: OrgStatusType,
    userRegistrationStatus: UserRegistrationStatusType,
    faceRegistrationStatus: string,
    employmentRegistrationStatus: string,
  ): Promise<Array<StaffRegistrationStatus>> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    let appendUrl = '';

    if (Array.isArray(categoryAry)) {
      categoryAry.forEach((cateArr: any, index: number) => {
        appendUrl += `&categoryAry=${cateArr.join(',')}`;
      });
    }
    const params = {
      orgCode,
      functionType: Values.FunctionType.Master.value,
      loginStaffCode,
      orgStatus,
      userRegistrationStatus,
      faceRegistrationStatus,
      employmentRegistrationStatus,
    };
    const queryStringParams = stringify(params);
    const response = await ApiClient.get(`/v2/staffs/registrationStatus/list/${companyCode}`, {}, queryStringParams + appendUrl);
    return response.data;
  };

  export const downloadCsvStaffRegistratoinStatus = async (
    orgCode: string,
    categoryAry: any,
    orgStatus: OrgStatusType,
    userRegistrationStatus: UserRegistrationStatusType,
    faceRegistrationStatus: string,
    employmentRegistrationStatus: string,
  ): Promise<void> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    let appendUrl = '';
    if (Array.isArray(categoryAry)) {
      categoryAry.forEach((cateArr: any, index: number) => {
        appendUrl += `&categoryAry=${cateArr.join(',')}`;
      });
    }
    const params = {
      orgCode,
      functionType: Values.FunctionType.Master.value,
      loginStaffCode,
      orgStatus,
      userRegistrationStatus,
      faceRegistrationStatus,
      employmentRegistrationStatus,
    };
    const queryStringParams = stringify(params);
    await ApiClient.downloadOriginalCsv(`/v2/staffs/registrationStatus/csv/${companyCode}`, queryStringParams + appendUrl, getDownloadCsvFileName('ユーザー登録状況確認_'));
  };

  export const deletePhoto = async (
    staffCode: string,
  ): Promise<void> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const updateUser = sessionStorage.getItem('loginUser.staffName') || '';
    const params = {
      staffCode,
      updateUser,
    };
    await ApiClient.delete(`/v2/staffs/registrationStatus/photos/${companyCode}`, params);
  };
