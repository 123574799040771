/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import productColor, { grayScale } from 'components/styles';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';

const styles = {
  wrapper: css({
    padding: '15px 20px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${productColor.primaryM90}`,
  }),
  closeButton: css({
    marginLeft: 'auto',
  }),
};

/**
 * モーダルのヘッダー
 *  - 閉じるボタンを持つ
 */
const Header: React.FC<{
  title: string
  closeHandler: () => void
}> = ({ title, closeHandler }) => (
  <div css={styles.wrapper}>
    <BodyText size="lg" bold={true}>
      {title}
    </BodyText>
    <div css={styles.closeButton}>
      <ClickableIconButton onClick={closeHandler}>
        <Icon type="close" color={grayScale.gray100} />
      </ClickableIconButton>
    </div>
  </div>
);

export default Header;
