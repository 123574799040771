import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getWorkingDayList } from 'api/workingDay';

/**
 * 所定労働日数の選択ボックス用データを取得
 */
export const useWorkingDayOptions = (): Array<OptionType> => {
  const [workingDayOptions, setWorkingDayOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getWorkingDayList().then((workingDays) => {
      const workingDaysOption = workingDays.map((workingDay: any) => ({
        value: workingDay.workingDaysId,
        label: workingDay.workingDaysPatternName, // TODO 項目確認
      }));
      workingDaysOption.unshift({
        value: '',
        label: '-',
      });
      setWorkingDayOptions(workingDaysOption);
    });
  }, []);
  return workingDayOptions;
};

export default useWorkingDayOptions;
