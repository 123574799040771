/** @jsx jsx */
import React, { useReducer, useCallback, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import YenInputBox from 'components/molecules/YenInputBox';
import Button from 'components/atoms/Button';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { Row, Col } from 'react-grid-system';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import DatePickerForm from 'components/molecules/DatePickerForm';
import BlockUI from 'components/molecules/BlockUi';
import BodyText from 'components/atoms/BodyText';
import moment from 'moment';
import { useAttendIncentiveSalary } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const PercentRegistration: React.FC<{}> = ({}) => {

const styleTable = css({
    tableLayout: 'fixed'
});

const currentDate = new Date();
const firstDayStr = moment(currentDate).format('YYYY-MM-01');
const lastDayStr = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
).format('YYYY-MM-DD');

const [targetMonthStart, setTargetMonthStart] = useState(new Date(firstDayStr));
const [targetMonthEnd, setTargetMonthEnd] = useState(new Date(lastDayStr));

const functionType = 2;
const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
const userOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
const [selectOrgCode, setSelectOrgCode] = useState(userOrgCode);
const [categoryArr, setCategoryArr] = useState<Array<String>>([]);

const { 
  headers, 
  listIncentiveSalary, 
  isLoading, 
  handleCreateUpdateAttendIncentive
  } = useAttendIncentiveSalary(
    selectOrgCode, moment(targetMonthStart).format('YYYY-MM-DD'), categoryArr
  );

const defaultReducer = listIncentiveSalary;

const defaultIncentiveSalary = {
  "incentiveSalaryCode": null,
  "incentiveSalary": 0
}

const removeComma = (number: any) => {
  const tmp = String(number).replace(/[\,]/g, '');
  if (tmp === '' || tmp === '-' || tmp === '-0') {
    return tmp;
  }
  if (!isNaN(Number(tmp))) {
    return Number(tmp);
  }
  return Number(0);
};

const memorizedReducer = useCallback((state: any, { type, payload }: any) => {
  switch(type) {
    case 'DEFAULT':
      return payload;
  
    default:
      const result = {...state}
      const newResult = result[payload.index].incentiveSalaryDataList[payload.indexSalary].incentiveSalaryList.filter((item: any) => item.incentiveSalaryCode == payload.incentiveSalaryCode)
      if (newResult.length > 0){
        result[payload.index].incentiveSalaryDataList[payload.indexSalary].incentiveSalaryList.filter((item: any) => item.incentiveSalaryCode == payload.incentiveSalaryCode)[0]['incentiveSalary'] = payload.val;
      } else {
        let newIncentiveSalary = { ...defaultIncentiveSalary };
        newIncentiveSalary.incentiveSalary = payload.val;
        newIncentiveSalary.incentiveSalaryCode = payload.incentiveSalaryCode;
        result[payload.index].incentiveSalaryDataList[payload.indexSalary].incentiveSalaryList.push(newIncentiveSalary);
      }
      return result;
  }
}, []);

const [state, dispatch] = useReducer(memorizedReducer, defaultReducer);

console.log('state', state);

const reNumberic = (value: string) => {
    const re = /^[0-9\b\n\,\-\b]*$/;
    return re.test(value);
}

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const numberFormater = (num: any) => {
  const number: any = removeComma(num)
  if (!isNaN(number)) {
    return formatter.format(number);
  }
  return number;
};

const handleOnChange = (e: any, index: number, indexSalary: number, indexIncentive: number, incentiveSalaryCode: string) => {
  const type = e.target.name;
  const payload = {
      index,
      indexSalary,
      indexIncentive,
      incentiveSalaryCode,
      val: e.target.value
  };
  dispatch({type, payload});
}

const removeCommaObjectUpdate = (number: any) => {
  const tmp = String(number).replace(/[\,]/g, '');
  if (!isNaN(Number(tmp))) {
    return Number(tmp);
  }
  return Number(0);
};

const formatObjectUpdate = (state: any) => {
  const newState: any = {};
  const incentiveSalaryDataList: any = [];
  Object.values(state).map((item: any) => {
    item.incentiveSalaryDataList.map((incentive: any) => {
      const formatIncentive = incentive.incentiveSalaryList.map((item: any, index: number) => {
        item.incentiveSalary = removeCommaObjectUpdate(item.incentiveSalary);
        return item;
      })
      incentive.incentiveSalaryList = formatIncentive;
      incentiveSalaryDataList.push(incentive);
    })
  });
  newState.incentiveSalaryDataList = incentiveSalaryDataList;
  return newState;
}

const handleSubmit = () => {
  const newState = formatObjectUpdate(state);
  const targetMonth = moment(targetMonthStart).format('YYYY-MM-DD');
  handleCreateUpdateAttendIncentive(targetMonth , newState);
}

useEffect(() => {
    dispatch({ type: 'DEFAULT', payload: defaultReducer })
}, [listIncentiveSalary])

const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <Row>
          <Col md={2}>
            <div style={{ marginTop: '10px' }}>
              <BodyText>対象年月</BodyText>
            </div>
          </Col>
          <Col md={10}>
            <FlexBox>
              <FlexBoxItem>
                <DatePickerForm
                    dateFormat="yyyy年MM月"
                    label=""
                    showMonthYearPicker={true}
                    isFullWidth={true}
                    date={targetMonthStart}
                    changeDate={(date: Date) => {
                      if (date !== null) {
                        setTargetMonthStart(date);
                        let endDate = moment(
                            new Date(date.getFullYear(), date.getMonth() + 1, 0),
                        ).format('YYYY-MM-DD');
                        setTargetMonthEnd(new Date(endDate))
                      }
                    }}
                />
              </FlexBoxItem>
            </FlexBox>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <OrganizationFilteredTree
              functionType={functionType}
              staffCode={staffCode}
              targetdayForm={targetMonthStart}
              targetdayTo={targetMonthEnd}
              orgLabel="組織名"
              initOrgValue={String(selectOrgCode)}
              orgCallback={(args: string | Array<string>) => {
                setSelectOrgCode(String(args));
                sessionStorage.setItem(
                  'attendResult.selectOrgCode',
                  String(args),
                );
              }}
              initCatagory={(args: string) => {
                sessionStorage.setItem('attendResult.selectCategory', JSON.stringify(args));
              }}

              orgCategoryCallback={(args: any) => {
                setCategoryArr(args);
                sessionStorage.setItem('attendResult.selectCategoryOption', JSON.stringify(args));
              }}

              styles={{
                label: {
                  toggleSwitch: css({ marginLeft: '0px' }),
                },
                toggle: {
                  width: '13rem',
                },
              }}
            />
          </Col>
        </Row>
        <div className="table-responsive"
        css={css`
            table{
                border-spacing: 0
            }
            table th, table td{
                padding: 7px 5px;
                width: 150px;
            }
            table th:nth-child(1), table td:nth-child(1),
            table th:nth-child(2), table td:nth-child(2) {
                text-overflow: ellipsis; 
                overflow: hidden; 
                white-space: nowrap;
            }
        `}
        style={{ width: '100%', overflow: 'auto', maxHeight: '500px' }}
        >
        <table
            className="table table-bordered table-striped table-condensed percentRegistration"
            css={css(styleTable)}
            style={{ width: 150 * (3 + headers.length) }}
        >
            <thead>
            <tr>
                <th className="text-center">スタッフコード</th>
                <th className="text-center">スタッフ名</th>
                <th className="text-center">出勤日数</th>
                {headers.map((itemHeader: any, i: any) =>
                    <th className="text-center">{itemHeader.incentiveSalaryName}</th>
                )}
            </tr>
            </thead>
            <tbody>
                {
                  listIncentiveSalary.map((item: any, index: number) => {
                    return (
                      item.incentiveSalaryDataList.map((itemSalary: any, indexSalary: number) => {
                        return(
                          <tr> 
                              <td className="text-left">{itemSalary.staffCode}</td>
                              <td className="text-left">{itemSalary.staffName}</td>
                              <td className="text-right">{Number(itemSalary.normalWork) + Number(itemSalary.holidayWork)}日</td>
                              {headers.map((itemIncentive: any, indexIncentive: number) => {
                                const rePosition = state[index]?.incentiveSalaryDataList[indexSalary]?.incentiveSalaryList.filter((item: any) => item.incentiveSalaryCode === itemIncentive.incentiveSalaryCode);
                                return(
                                  <td className="text-center">
                                      <YenInputBox
                                          name="incentive"
                                          value={String(rePosition !== undefined && rePosition.length > 0 ? numberFormater(rePosition[0].incentiveSalary) : 0)}
                                          onChange={(e) => { 
                                              let maxLength = 11;
                                              if (e.target.value.indexOf('-') !== -1) {
                                                maxLength = 12;
                                              }
                                              if (reNumberic(e.target.value) && e.target.value.length <= maxLength){
                                                handleOnChange(e, index, indexSalary, indexIncentive, itemIncentive.incentiveSalaryCode) 
                                              }
                                          }}
                                          disable={itemSalary.isConfirmed}
                                      />
                                  </td>  
                                )}
                              )}
                          </tr>
                        )
                      })
                    )
                  })
                }
            </tbody>
        </table>
        </div>
        {
          roleScreen && roleScreen.editable === 1 && (
            <div className="text-center" style={{ marginTop: '30px' }}>
                <Button
                text="登録"
                onClick={handleSubmit}
                />
            </div>
          )
        }
        </FormContents>
    </BlockUI>
)};
export default PercentRegistration;
