import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

export const importPayslipFileCSV = async (type:any, uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    formatType: type,
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v1/payslip/csv/import/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export type Error = {
  error: string;
  defaultMessage: string;
}

export default { importPayslipFileCSV };
