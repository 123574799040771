/** @jsx jsx */
import React, {
  useCallback, useMemo, useState,
} from 'react';
import { Column } from 'react-table';
import { useHistory } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import SecondaryButton from 'components/atoms/Button/SecondaryButton';
import DataTable from 'components/organismos/DataTable/DataTable';
import AttendHeadQuartersFinalDomain from 'domain/master/attend/attendHeadquartersFinal';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import DropdownBox from 'components/molecules/Dropdown/DropdownBox';
import DropdownItem from 'components/molecules/Dropdown/DropdownItem';
import useComponentVisible from 'hooks/useComponentVisible';
import moment from 'moment';
import BodyText from 'components/atoms/BodyText';
import ReactTooltip from 'react-tooltip';
import BlockUI from 'components/molecules/BlockUi';
import useToastNotification from 'hooks/useToastNotification';
import {
  useAchievementConfirmList,
  useGetClosingList, useGetEmployments, useGetOutputLayouts,
} from './hooks';
import { getCsvOutput, achievementAllConfirmV3 } from 'api/achievementConfirm';
import ToastModal from 'components/organismos/ToastModal';
import DatePickerForm from 'components/molecules/DatePickerForm';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import { ErrorMessage } from 'utility/messages';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const HeadquartersFinalForm: React.FC<{
  targetMonthProp: Date,
  targetDateFromProp: Date,
  targetDateToProp: Date,
}> = ({
  targetMonthProp,
  targetDateFromProp,
  targetDateToProp,
}) => {
    const history = useHistory();
    const [allConfirm, setAllConfirm] = useState(false);
    const [allCsvOutput, setAllCsvOutput] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastModalOpen, setToastModalOpen] = useState(false);
    const functionType = 2;
    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const categorySession = JSON.parse(String(sessionStorage.getItem(`sessionOrganizationFileterTree${window.location.pathname}.Toggle`)))
    const [categoryArr, setCategoryArr] = useState<Array<String>>(categorySession);
    const [orgTreesOptions, setOrgTreesOptions] = useState([]);
    const attendHeadquartersFinal = sessionStorage.getItem('sessionOrgSelect/attendHeadquartersFinal') || 'all';
    const [orgCode, setOrgCode] = useState(attendHeadquartersFinal);
    const [isAllConfirmProcessing, setIsAllConfirmProcessing] = useState(false);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [targetMonth, setTargetMonth] = useState(sessionStorage.getItem('headquartersFinal.searchTargetMonth') ? new Date(sessionStorage.getItem('headquartersFinal.searchTargetMonth')!) : targetMonthProp);
    const [targetDateFrom, setTargetDateFrom] = useState(sessionStorage.getItem('headquartersFinal.searchTargetDateFrom') ? new Date(sessionStorage.getItem('headquartersFinal.searchTargetDateFrom')!) : targetDateFromProp);
    const [targetDateTo, setTargetDateTo] = useState(sessionStorage.getItem('headquartersFinal.searchTargetDateTo') ? new Date(sessionStorage.getItem('headquartersFinal.searchTargetDateTo')!) : targetDateToProp);
    const [selectedClosingDate, setSelectedClosingDate] = useState(sessionStorage.getItem('headquartersFinal.selClosingDate') || '-1');
    const [selectedEmploymentId, setSelectedEmploymentId] = useState(sessionStorage.getItem('headquartersFinal.searchEmploymentId') || '');
    const [outputLayoutId, setOutputLayoutId] = useState(sessionStorage.getItem('headquartersFinal.searchOutputLayout') || '');
    const [viewPeriod, setViewPeriod] = useState(sessionStorage.getItem('headquartersFinal.viewPeriod') || 'monthly');
    const { errorNotification } = useToastNotification();
    const { outputLayoutList } = useGetOutputLayouts(setOutputLayoutId);
    const {
      closingDateList,
    } = useGetClosingList(viewPeriod, targetMonth, targetDateTo);
    const { employmentList } = useGetEmployments(viewPeriod, targetMonth,
      targetDateFrom, targetDateTo, selectedClosingDate);
    const {
      achievementConfirmList, setAchievementConfirmList, isLoading, setLoading, achievementConfirmV3
    } = useAchievementConfirmList(viewPeriod, setAllConfirm, setAllCsvOutput,
      targetMonth, targetDateFrom, targetDateTo, selectedEmploymentId,
      selectedClosingDate, setToastMessage, setToastModalOpen,
      categoryArr, orgCode
    );
    const filteredTreeTargetDayFrom = useMemo(() => {
      if (viewPeriod === 'monthly') {
        return moment(targetMonth)
          .startOf("month")
          .format("YYYY-MM-DD")
      } else if (viewPeriod === 'daily') {
        return moment(targetDateFrom).format("YYYY-MM-DD")
      } else {
        return moment()
          .startOf("month")
          .format("YYYY-MM-DD")
      }
    }, [viewPeriod, targetMonth, targetDateFrom])
    const filteredTreeTargetDayTo = useMemo(() => {
      if (viewPeriod === 'monthly') {
        return moment(targetMonth)
          .endOf("month")
          .format("YYYY-MM-DD")
      } else if (viewPeriod === 'daily') {
        return moment(targetDateTo).format("YYYY-MM-DD")
      } else {
        return moment()
          .endOf("month")
          .format("YYYY-MM-DD")
      }
    }, [viewPeriod, targetMonth, targetDateTo])

    const handleCsvOutput: (item: any, type: any) => void = useCallback(
      async (item, type) => {
        let fileName = '勤怠データ(';
        if (viewPeriod === 'monthly') {
          if (item) {
            fileName += `${moment(item.rawData.dateFrom, 'YYYY-MM-DD').format('YYYYMMDD')}-${moment(item.rawData.dateTo, 'YYYY-MM-DD').format('YYYYMMDD')})`;
          } else {
            fileName += `${moment(targetMonth).format('YYYYMM')})`;
          }
        } else {
          fileName += `${moment(targetDateFrom).format('YYYYMMDD')}-${moment(targetDateTo).format('YYYYMMDD')})`;
        }

        if (item) {
          fileName = `${fileName}_${item.rawData.orgName}`;
        }
        fileName += '.csv';

        let dateFrom = moment(targetMonth).format('YYYY-MM-01');
        let dateTo = moment(targetMonth).format('YYYY-MM-01');
        let closingDate = selectedClosingDate;

        if (item) {
          dateFrom = item.rawData.dateFrom;
          dateTo = item.rawData.dateTo;
          if (dateFrom > dateTo) {
            errorNotification(ErrorMessage.Common.INVALID_DATE_RANGE('開始日', '対象期間の終了日'));
            return;
          }
          closingDate = item.rawData.closingDate;
        } else if (viewPeriod === 'daily') {
          dateFrom = moment(targetDateFrom).format('YYYY-MM-DD');
          dateTo = moment(targetDateTo).format('YYYY-MM-DD');
          if (dateFrom > dateTo) {
            errorNotification(ErrorMessage.Common.INVALID_DATE_RANGE('開始日', '対象期間の終了日'));
            return;
          }
        }

        let targetYmd = '';
        if (viewPeriod === 'monthly') {
          targetYmd = moment(targetMonth).format('YYYY-MM-01');
        }

        let orgCodeParam = '';
        if (item) {
          orgCodeParam = item.rawData.orgCode;
        }
        const param = {
          targetDateFrom: dateFrom,
          targetDateTo: dateTo,
          attendFlag: type === 2 ? 0 : type,
          employmentId: selectedEmploymentId || '',
          viewPeriod,
          closingDate,
          orgCode: orgCodeParam,
          outputLayoutId,
          targetYmd,
          loginStaffCode: sessionStorage.getItem('loginUser.staffCode') || '',
          functionType,
          searchOrgCode: orgCode,
        };

        setLoading(true);
        await getCsvOutput(param, fileName, categoryArr).then((response: any) => {
          setLoading(false);
        }).catch((error: any) => {
          setLoading(false);
          const { response } = error;
          if (response.status === 400) {
            if (response.data.errors && response.data.errors.length > 0) {
              errorNotification(response.data.errors.map((x: any) => x.defaultMessage).join('<br/>'));
            } else if (response.data.defaultMessage) {
              errorNotification(response.data.defaultMessage);
            } else {
              errorNotification(response.data.message);
            }
          } else {
            errorNotification('サーバー側でエラーが発生しました。');
          }
        });
      },
      [errorNotification, outputLayoutId, selectedClosingDate,
        selectedEmploymentId, setLoading, targetDateFrom, targetDateTo, targetMonth, viewPeriod],
    );

    // // fix set initPageIndex về mặc định khi thay đổi điều kiện lọc theo yc kh
    // console.log('targetMonth', targetMonth);
    // const tableId = 1;
    // useEffect(() => {
    //   sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(0));
    // }, [targetMonth]);

    const handleDispMonthlyList: (item: any, type: any) => void = useCallback(
      (item, type) => {
        sessionStorage.setItem('headquartersFinal.searchTargetDateFrom', moment(targetDateFrom).format('YYYY-MM-DD'));
        sessionStorage.setItem('headquartersFinal.searchTargetDateTo', moment(targetDateTo).format('YYYY-MM-DD'));
        sessionStorage.setItem('headquartersFinal.searchTargetMonth', moment(targetMonth).format('YYYY-MM-01'));
        sessionStorage.setItem('headquartersFinal.searchEmploymentId', selectedEmploymentId);
        sessionStorage.setItem('headquartersFinal.searchOutputLayout', outputLayoutId);
        sessionStorage.setItem('headquartersFinal.viewPeriod', viewPeriod);
        sessionStorage.setItem('headquartersFinal.selClosingDate', selectedClosingDate);
        sessionStorage.setItem('headquartersFinal.selectOrgCode', item.rawData.orgCode);
        sessionStorage.setItem('headquartersFinal.selectOrgName', item.rawData.orgName);

        sessionStorage.setItem('headquartersFinal.monthlyList.searchTargetDateFrom', moment(item.rawData.dateFrom, 'YYYY-MM-DD').format('YYYY-MM-DD'));
        sessionStorage.setItem('headquartersFinal.monthlyList.searchTargetDateTo', moment(item.rawData.dateTo, 'YYYY-MM-DD').format('YYYY-MM-DD'));
        sessionStorage.setItem('headquartersFinal.monthlyList.selClosingDate', item.rawData.closingDate);
        sessionStorage.setItem('headquartersFinal.monthlyList.searchEmploymentId', selectedEmploymentId);
        sessionStorage.setItem('headquartersFinal.monthlyList.selectOrgCode', item.rawData.orgCode);
        sessionStorage.setItem('headquartersFinal.monthlyList.selectOrgName', item.rawData.orgName);
        sessionStorage.setItem('headquartersFinal.monthlyList.attendFlag', type);

        history.push('/attendMonthlyList');
      },
      [history, outputLayoutId, selectedClosingDate, selectedEmploymentId,
        targetDateFrom, targetDateTo, targetMonth, viewPeriod],
    );

    const handleConfirm: (item: any, type: any) => void = useCallback(
      (item, type) => {
        const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

        let url = '';
        if (type === 0) {
          url += 'confirm';
        } else if (type === 1) {
          url += 'unConfirm';
        } else if (type === 2) {
          url += 'allConfirm';
          setIsAllConfirmProcessing(true);
        }

        let param = {};
        if (url !== 'allConfirm') {
          if (item) {
            param = {
              targetDateFrom: item.rawData.dateFrom,
              targetDateTo: item.rawData.dateTo,
              staffCode,
              employmentId: selectedEmploymentId,
              closingDate: item.rawData.closingDate,
              orgCode: item.rawData.orgCode,
              searchClosingDate: selectedClosingDate,
              searchOrgCode: orgCode,
              functionType,
              categoryAry: categoryArr && categoryArr.length > 0 ? String(categoryArr) : null
            };
          } else {
            param = {
              targetDateFrom: moment(targetMonth).format('YYYY-MM-01'),
              targetDateTo: moment(targetMonth).format('YYYY-MM-01'),
              staffCode,
              employmentId: selectedEmploymentId,
              orgCode: item.rawData.orgCode,
              closingDate: selectedClosingDate,
              searchOrgCode: orgCode,
              functionType,
              categoryAry: categoryArr && categoryArr.length > 0 ? String(categoryArr) : null
            };
          }
        } else {
          if (item) {
            param = {
              targetDateFrom: item.rawData.dateFrom,
              targetDateTo: item.rawData.dateTo,
              staffCode,
              employmentId: selectedEmploymentId,
              closingDate: item.rawData.closingDate,
              searchClosingDate: selectedClosingDate,
              orgCode,
              functionType,
              categoryAry: categoryArr && categoryArr.length > 0 ? String(categoryArr) : null
            };
          } else {
            param = {
              targetDateFrom: moment(targetMonth).format('YYYY-MM-01'),
              targetDateTo: moment(targetMonth).format('YYYY-MM-01'),
              staffCode,
              employmentId: selectedEmploymentId,
              closingDate: selectedClosingDate,
              orgCode,
              functionType,
              categoryAry: categoryArr && categoryArr.length > 0 ? String(categoryArr) : null
            };
          }
        }

        setLoading(true);
        achievementAllConfirmV3(param, url).then((response: any) => {
          setLoading(false);
          setAchievementConfirmList(
            response.confirmList.map((result: any) => new AttendHeadQuartersFinalDomain(result)),
          );
          setAllConfirm(response.allConfirm);
          setAllCsvOutput(response.allCsvOutput);
          if (type === 1) {
            setToastMessage('解除しました。');
          } else {
            setToastMessage('確定しました。');
          }
          setToastModalOpen(true);
          setIsAllConfirmProcessing(false);
        }).catch((error: any) => {
          setLoading(false);
          setIsAllConfirmProcessing(false);
          const { response } = error;
          if (response.status === 400) {
            if (response.data.errors && response.data.errors.length > 0) {
              setToastMessage(response.data.errors.map((x: any) => x.defaultMessage).join('<br/>'));
              setToastModalOpen(true);
            } else if (response.data.defaultMessage) {
              setToastMessage(response.data.defaultMessage);
              setToastModalOpen(true);
            } else {
              setToastMessage(response.data.message);
              setToastModalOpen(true);
            }
          } else {
            errorNotification('サーバー側でエラーが発生しました。');
          }
        });

      },
      [errorNotification, selectedClosingDate, selectedEmploymentId,
        setAchievementConfirmList, setLoading, targetMonth],
    );

    // Check prototype role staff
    const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

    const columns: Array<Column<AttendHeadQuartersFinalDomain>> = useMemo(() => [
      {
        Header: '店舗コード',
        accessor: 'orgCode',
        sortType: 'basic'
      },
      {
        Header: '組織名',
        accessor: 'orgName',
      },
      {
        Header: '締日',
        id: 'closingDateStr',
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean, original: any } }) => (
          viewPeriod === 'monthly' && (
            <span>
              {(cell.row.original.closingDate === 0 && '末')}
              {(cell.row.original.closingDate !== 0 && `${cell.row.original.closingDate}`)}
              日
            </span>
          )
        ),
      },
      {
        Header: '期間',
        id: 'periodStr',
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean, original: any } }) => (
          viewPeriod === 'monthly' && (
            <span>
              {(`${moment(cell.row.original.dateFrom, 'YYYY-MM-DD').format('YYYY/MM/DD')}～${moment(cell.row.original.dateTo, 'YYYY-MM-DD').format('YYYY/MM/DD')}`)}
            </span>
          )
        ),
      },
      {
        Header: '本部確定',
        id: 'actionConfirm',
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean, original: any } }) => {
          const confirmData = cell.row.original.rawData;
          let msg = '';

          if (confirmData.employmentChangeErrorMsg != null && confirmData.employmentChangeErrorMsg !== '') {
            msg = confirmData.employmentChangeErrorMsg;
          }
          if (confirmData.errorAchieventErrorMsg != null && confirmData.errorAchieventErrorMsg !== '') {
            if (msg !== '') {
              msg += '<br>';
            }
            msg += confirmData.errorAchieventErrorMsg;
          }
          if (confirmData.applyingErrorMsg != null && confirmData.applyingErrorMsg !== '') {
            if (msg !== '') {
              msg += '<br>';
            }
            msg += confirmData.applyingErrorMsg;
          }

          return (
            <div style={{ textAlign: 'left' }}>
              {viewPeriod === 'monthly' && cell.row.original.confirmStatus === 0 && roleScreen && roleScreen.editable === 1 && (
                <span>
                  <PrimaryButton
                    disabled={isAllConfirmProcessing}
                    onClick={() => handleConfirm(cell.row.original, 0)}
                    ghost={false}
                    text="確定する"
                  />
                </span>
              )}
              {viewPeriod === 'monthly' && cell.row.original.confirmStatus === 1 && <span style={{ color: 'blue' }}><b>確定済</b></span>}
              {viewPeriod === 'monthly' && cell.row.original.confirmStatus === 2 && (
                <span style={{ color: 'red' }}>
                  <div data-tip={msg}><b>確定不可</b></div>
                  <ReactTooltip html={true} />
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: '解除',
        id: 'actionUnConfirm',
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean, original: any } }) => (
          <div style={{ textAlign: 'left' }}>
            {cell.row.original.unConfirmFlag === 1
              && roleScreen && roleScreen.editable === 1 && (
                <SecondaryButton
                  disabled={isAllConfirmProcessing}
                  ghost={false}
                  text="確定解除"
                  onClick={() => handleConfirm(cell.row.original, 1)}
                />
              )}
          </div>
        ),
      },
      {
        Header: '所属スタッフ出力',
        id: 'actionUnconfirmedBelong',
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean, original: any } }) => {
          const confirmData = cell.row.original.rawData;
          let msg = '';

          if (confirmData.employmentChangeBelong != null && confirmData.employmentChangeBelong !== '') {
            msg = confirmData.employmentChangeBelong;
          }
          if (confirmData.errorAchieventBelong != null && confirmData.errorAchieventBelong !== '') {
            if (msg !== '') {
              msg += '<br>';
            }
            msg += confirmData.errorAchieventBelong;
          }
          if (confirmData.applyingBelong != null && confirmData.applyingBelong !== '') {
            if (msg !== '') {
              msg += '<br>';
            }
            msg += confirmData.applyingBelong;
          }

          return (
            <div style={{ textAlign: 'left' }}>
              <div data-tip={msg}>
                {cell.row.original.ownCnt > 0
                  && roleScreen && roleScreen.downloadFlag === 1 && (
                    <PrimaryButton
                      disabled={(!(cell.row.original.confirmStatus === 1) && viewPeriod === 'monthly') || isAllConfirmProcessing}
                      ghost={false}
                      text="CSV出力"
                      onClick={() => handleCsvOutput(cell.row.original, 0)}
                    />
                  )}
                &nbsp;
                {cell.row.original.ownCnt > 0 && (
                  <PrimaryButton
                    ghost={false}
                    text="勤怠確認"
                    onClick={() => handleDispMonthlyList(cell.row.original, 0)}
                  />
                )}
              </div>
              <ReactTooltip html={true} />
            </div>
          );
        },
      },
      {
        Header: '勤務スタッフ出力',
        id: 'actionUnconfirmedSupport',
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean, original: any } }) => {
          const confirmData = cell.row.original.rawData;
          let msg = '';

          if (confirmData.employmentChangeSupport != null && confirmData.employmentChangeSupport !== '') {
            msg = confirmData.employmentChangeSupport;
          }
          if (confirmData.errorAchieventSupport != null && confirmData.errorAchieventSupport !== '') {
            if (msg !== '') {
              msg += '<br>';
            }
            msg += confirmData.errorAchieventSupport;
          }
          if (confirmData.applyingSupport != null && confirmData.applyingSupport !== '') {
            if (msg !== '') {
              msg += '<br>';
            }
            msg += confirmData.applyingSupport;
          }

          return (
            <div style={{ textAlign: 'center' }}>
              <div data-toggle="tooltip" data-container="body" data-html="true" title={msg}>
                {roleScreen && roleScreen.downloadFlag === 1 && (
                  <PrimaryButton
                    disabled={(!(cell.row.original.confirmStatus === 1) && viewPeriod === 'monthly') || isAllConfirmProcessing}
                    ghost={false}
                    text="CSV出力"
                    onClick={() => handleCsvOutput(cell.row.original, 1)}
                  />
                )}
                &nbsp;
                <PrimaryButton
                  ghost={false}
                  text="勤怠確認"
                  onClick={() => handleDispMonthlyList(cell.row.original, 1)}
                />
              </div>
            </div>
          );
        },
      },
    ], [handleConfirm, handleCsvOutput, handleDispMonthlyList, isAllConfirmProcessing, roleScreen, viewPeriod]);


    let checkWidthIPad = false;
    const mq = window.matchMedia('(max-width: 1024px)');
    if (mq.matches) {
      checkWidthIPad = true;
    } else {
      checkWidthIPad = false;
    }
    const tableId = 1;
    return (
      <React.Fragment>
        <BlockUI blocking={isLoading}>
          <FormContents>
            <Row>
              <Col md={2}>
                <div style={{ marginTop: '16px' }}>
                  <BodyText>対象期間</BodyText>
                </div>
              </Col>
              <Col md={10}>

                {checkWidthIPad === false && (
                  <FlexBox customStyle={css({ paddingBottom: '4px', marginLeft: '-3px' })}>
                    <Col xl={1} lg={2} md={2} sm={2}>
                      <RadioSelectForm
                        label=""
                        name="monthly"
                        items={[
                          {
                            label: '月',
                            value: 'monthly',
                          },
                        ]}
                        value={viewPeriod}
                        setValue={(e) => {
                          setViewPeriod(e.target.value);
                          sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(0));
                        }}
                      />
                    </Col>
                    <Col xl={1} lg={2} md={2} sm={2}>
                      <RadioSelectForm
                        label=""
                        name="daily"
                        items={[
                          {
                            label: '期間',
                            value: 'daily',
                          },
                        ]}
                        value={viewPeriod}
                        setValue={(e) => {
                          setViewPeriod(e.target.value);
                          sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(0));
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      {viewPeriod === 'monthly' && (
                        <DatePickerForm
                          dateFormat="yyyy年MM月"
                          label=""
                          showMonthYearPicker={true}
                          isFullWidth={true}
                          date={targetMonth}
                          changeDate={(date: Date) => {
                            if (date !== null) {
                              setTargetMonth(date);
                              sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(0));
                            }
                          }}
                        />
                      )}
                      {viewPeriod === 'daily' && (
                        <FlexBox>
                          <FlexBoxItem>
                            <DatePickerForm
                              dateFormat="yyyy年MM月dd日"
                              label=""
                              isFullWidth={true}
                              date={targetDateFrom}
                              changeDate={(date: Date) => {
                                if (date !== null) {
                                  setTargetDateFrom(date);
                                  sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(0));
                                }
                              }}
                            />
                          </FlexBoxItem>
                          <FlexBoxItem><span style={{ margin: '0 30px' }}>～</span></FlexBoxItem>
                          <FlexBoxItem>
                            <DatePickerForm
                              dateFormat="yyyy年MM月dd日"
                              label=""
                              isFullWidth={true}
                              date={targetDateTo}
                              changeDate={(date: Date) => {
                                if (date !== null) {
                                  setTargetDateTo(date);
                                  sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(0));
                                }
                              }}
                            />
                          </FlexBoxItem>
                        </FlexBox>
                      )}
                    </Col>
                  </FlexBox>
                )}
                {checkWidthIPad === true && (
                  <div style={{ marginLeft: '-3px' }}>
                    <div style={{ paddingBottom: '4px' }}>
                      <Row>
                        <RadioSelectForm
                          label=""
                          name="monthly"
                          items={[
                            {
                              label: '月',
                              value: 'monthly',
                            },
                          ]}
                          value={viewPeriod}
                          setValue={(e) => {
                            setViewPeriod(e.target.value);
                            sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(0));
                          }}
                        />
                        <RadioSelectForm
                          label=""
                          name="daily"
                          items={[
                            {
                              label: '期間',
                              value: 'daily',
                            },
                          ]}
                          value={viewPeriod}
                          setValue={(e) => {
                            sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(0));
                            setViewPeriod(e.target.value);
                          }}
                        />
                      </Row>
                    </div>
                    <div style={{ paddingBottom: '4px', paddingLeft: '3px' }}>
                      <Row>
                        {viewPeriod === 'monthly' && (
                          <DatePickerForm
                            dateFormat="yyyy年MM月"
                            label=""
                            showMonthYearPicker={true}
                            isFullWidth={true}
                            date={targetMonth}
                            changeDate={(date: Date) => {
                              if (date !== null) {
                                setTargetMonth(date);
                                sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(0));
                              }
                            }}
                          />
                        )}
                        {viewPeriod === 'daily' && (
                          <FlexBox>
                            <FlexBoxItem>
                              <DatePickerForm
                                dateFormat="yyyy年MM月dd日"
                                label=""
                                isFullWidth={true}
                                date={targetDateFrom}
                                changeDate={(date: Date) => {
                                  if (date !== null) {
                                    sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(0));
                                    setTargetDateFrom(date);
                                  }
                                }}
                              />
                            </FlexBoxItem>
                            <FlexBoxItem><span style={{ margin: '0 4px' }}>～</span></FlexBoxItem>
                            <FlexBoxItem>
                              <DatePickerForm
                                dateFormat="yyyy年MM月dd日"
                                label=""
                                isFullWidth={true}
                                date={targetDateTo}
                                changeDate={(date: Date) => {
                                  if (date !== null) {
                                    setTargetDateTo(date);
                                    sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(0));
                                  }
                                }}
                              />
                            </FlexBoxItem>
                          </FlexBox>
                        )}
                      </Row>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col style={{ display: 'flex', alignItems: 'center' }} md={2}>
                <BodyText>締日・雇用形態</BodyText>
              </Col>
              <Col md={10}>
                <Row>
                  <Col md={2}>
                    <FlexBox>
                      <FlexBoxItem grow={0.5}>
                        <VerticalLabelSelectForm
                          label=""
                          name="closingDate"
                          value={selectedClosingDate}
                          setValue={(v) => {
                            setSelectedEmploymentId('');
                            setSelectedClosingDate(v);
                            sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(0));
                          }}

                          options={[...[{ value: '-1', label: '-' }], ...closingDateList]}
                        />
                      </FlexBoxItem>
                      <span>&nbsp;日</span>
                    </FlexBox>
                  </Col>
                  <Col md={4}>
                    <VerticalLabelSelectForm
                      label=""
                      name="attendEmployment"
                      value={selectedEmploymentId}
                      setValue={(v) => {
                        setSelectedEmploymentId(v);
                        sessionStorage.setItem(`sessionDataTable${tableId}${window.location.pathname}.initPageIndex`, String(0));
                      }}
                      options={[...[{ value: '', label: '-' }], ...employmentList]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={2} style={{ display: 'flex', alignItems: 'center' }}>
                <BodyText>
                  出力レイアウト
                </BodyText>
              </Col>
              <Col md={5}>
                <VerticalLabelSelectForm
                  label=""
                  name="outputLayout"
                  value={outputLayoutId}
                  setValue={(v) => setOutputLayoutId(v)}
                  options={outputLayoutList}
                />
              </Col>
            </Row>
            <Row>
              <Col md={7}>
                <OrganizationFilteredTree
                  functionType={3}
                  staffCode={staffCode}
                  orgLabel="店舗名"
                  initOrgValue={String(orgCode)}
                  orgCallback={(args: string | Array<string>) => {
                    setOrgCode(String(args));
                  }}
                  orgCategoryCallback={(args: Array<string>) => {
                    setCategoryArr(args);
                  }}
                  orgNameCallback={(args: string | Array<string>) => {
                    sessionStorage.setItem('SessionHeadQuarterStore', String(args));
                  }}
                  targetdayForm={moment(
                    new Date(filteredTreeTargetDayFrom),
                  ).toDate()}
                  targetdayTo={moment(new Date(filteredTreeTargetDayTo)).toDate()}
                  addAllItem={true}
                  styles={{
                    label: {
                      toggleSwitch: css({ marginLeft: '0px' }),
                    },
                    toggle: {
                      width: '13rem',
                    },
                  }}
                />
              </Col>
            </Row>
            <div
              css={css`
              table th:nth-child(1) {
                width: 100px;
              }
              table th:nth-child(2) {
                width: 260px;
              }
              table th:nth-child(3) {
                width: 60px;
              }
              table th:nth-child(4) {
                width: 170px;
              }
              table th:nth-child(5) {
                width: 110px;
              }
              table th:nth-child(6) {
                width: 110px;
              }
              table th:nth-child(7) {
                width: 190px;
              }
              table th:last-child {
                  width: 190px;
                }
              `}
            >
              <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <DataTable
                  columns={columns}
                  data={achievementConfirmList}
                  minWidth="1200px"
                  useSession={true}
                  tableId={1}
                  sortBy={[
                    { id: 'orgCode', desc: false }
                  ]}
                />
              </div>
            </div>

            <Container>
              <Row>
                <p style={{ textAlign: 'center', width: '100%' }}>
                  {isAllConfirmProcessing && <span style={{ color: 'red', fontWeight: 'bold' }}>一括確定中です...</span>}
                </p>
              </Row>
              <Row style={{ paddingBottom: '60px' }}>
                <FlexBox alignItems="center" customStyle={css({ width: '100%', justifyContent: 'center' })}>
                  <FlexBoxItem>
                    {roleScreen && roleScreen.editable === 1 && (
                      <PrimaryButton
                        disabled={!allConfirm || isAllConfirmProcessing}
                        onClick={() => handleConfirm(null, 2)}
                        ghost={false}
                        text="一括確定"
                      />
                    )}

                  </FlexBoxItem>
                  <FlexBoxItem marginLeft="10px">
                    <div style={{ position: 'relative' }} ref={ref}>
                      {roleScreen && roleScreen.downloadFlag === 1 && (
                        <PrimaryButton
                          onClick={() => setIsComponentVisible(!isComponentVisible)}
                          disabled={!allCsvOutput || isAllConfirmProcessing}
                          ghost={false}
                          text="一括CSV出力"
                        />
                      )}

                      <DropdownBox isVisible={isComponentVisible}>
                        {[{
                          value: '0',
                          label: '所属スタッフ出力',
                        }, {
                          value: '1',
                          label: '勤務スタッフ出力',
                        }].map((item) => (
                          <DropdownItem
                            key={item.value}
                            value={item.value}
                            label={item.label}
                            onClick={() => {
                              handleCsvOutput(null, item.value);
                              setIsComponentVisible(!isComponentVisible);
                            }}
                          />
                        ))}
                      </DropdownBox>
                    </div>
                  </FlexBoxItem>
                </FlexBox>
              </Row>
            </Container>
            <ToastModal
              open={toastModalOpen}
              closeHandler={() => setToastModalOpen(false)}
              title={toastMessage}
            />

          </FormContents>
        </BlockUI>
      </React.Fragment>
    );
  };

export default HeadquartersFinalForm;
