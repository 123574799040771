import React from 'react';
import { useParams } from 'react-router-dom';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button, { PrimaryButton } from 'components/atoms/Button';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';

import { useWorkingDaysDomainConfigForm } from './hooks';

const PrescribedDaysConfigForm: React.FC = () => {
  const { workingDaysId } = useParams();
  const {
    formik, confirmModalOpen, closeConfirmModal, orgTreesOptions, history,
  } = useWorkingDaysDomainConfigForm(workingDaysId);
  return (
    <>
      <p />
      <form onSubmit={formik.handleSubmit}>
        <VerticalLabelMutilSelectForm
          label="組織選択"
          name="orgDaysList"
          values={orgTreesOptions.length === 0 || (formik.values.orgDaysList.length === 1 && formik.values.orgDaysList[0].id.orgCode === '') ? undefined : formik.values.orgDaysList.map((orgObject) => {
            const selectedOrgCode = orgTreesOptions.find(
              (orgOption) => orgOption.value === orgObject.id.orgCode,
            );
            if (selectedOrgCode) {
              return selectedOrgCode;
            }
            return new Option();
          })}
          setValue={(val: string) => {
            formik.setFieldValue('orgDaysList', val.slice(0, -1).split(',').map((orgCode) => ({ id: { orgCode } })));
          }}
          options={orgTreesOptions}
          isMulti={true}
        />
        <FormSubmitArea>
          <div style={{ marginRight: '12px' }}>
            <Button
              text="組織を割当"
              onClick={formik.handleSubmit}
            />
          </div>
          <PrimaryButton
            text="戻る"
            onClick={() => {
              history.replace('/agreementMaster');
            }}
            ghost={true}
          />
        </FormSubmitArea>
      </form>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={workingDaysId ? ActionType.UPDATE : ActionType.CREATE}
      />
    </>

  );
};
export default PrescribedDaysConfigForm;
