import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import FiscalYearAddForm from 'components/organismos/master/sales/FiscalYearAddForm';
import { useParams } from 'react-router-dom';

const FiscalYearAddPage: React.FC = () => {
  const {
    fiscalYear,
  } = useParams();

  return (
    <SidebarTemplate pageTitle={fiscalYear ? '会計年度編集' : '会計年度作成'}>
      <FiscalYearAddForm />
    </SidebarTemplate>
  );
};

export default FiscalYearAddPage;
