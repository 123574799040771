/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import FormContents from 'components/atoms/Form/FormContents';
import useSalesPageForm, { useDownload } from 'components/organismos/master/general/salesPage/hook';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import SalesFormTable from 'components/organismos/master/general/salesPage/SalesFormList/SalesFormTable';
import DatePicker from 'components/molecules/DatePicker';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import moment from 'moment';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const SalesPageForm: React.FC<{}> = () => {
  const {
    isLoading, listSalesPageForms, functionType, setOrgCode, orgCode, loginStaffCode, setCategoryAry, selectedDateFrom, setSelectedDateFrom,selectedDateTo,setSelectedDateTo,
    orgName,
    setorgName,
  } = useSalesPageForm();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox>
          <FlexBoxItem>
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                date={selectedDateFrom}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    setSelectedDateFrom(date);
                  }
                }}
                isFullWidth={true}
              />
          </FlexBoxItem>
          <FlexBoxItem>
            <span style={{ margin: '0 30px' }}>～</span>
          </FlexBoxItem>

          <FlexBoxItem>
            <DatePicker
              dateFormat="yyyy年MM月dd日"
              date={selectedDateTo}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setSelectedDateTo(date);
                }
              }}
              isFullWidth={true}
            />
          </FlexBoxItem>
        </FlexBox>
        <div style={{ marginLeft: '8px' }}>
          <OrganizationFilteredTree
            functionType={functionType}
            orgCallback={(args: string | Array<string>) => {
              setOrgCode(String(args));
            }}
            orgCategoryCallback={
                (args: Array<string>) => {
                  setCategoryAry(args);
                }
              }
            orgNameCallback={(arg: any) => {
              setorgName(String(arg));
            }}
            initOrgValue={String(orgCode)}
            staffCode={loginStaffCode}
            orgLabel="組織名"
            addAllItem={true}
          />
        </div>

        <SalesFormTable
          listSalesPageForms={listSalesPageForms}
          orgCode={orgCode}
          orgName={orgName}
          selectedDateFrom={selectedDateFrom}
          selectedDateTo={selectedDateTo}
          roleScreen={roleScreen}
        />
      </FormContents>
    </BlockUI>
  );
};
export default SalesPageForm;
