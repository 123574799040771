import { useEffect, useCallback, useState } from 'react';
import { getAttendIncentiveSalaryHeader, getAttendIncentiveSalaryDataList, createAttendIncentive } from 'api/attendIncentiveSalary';
import useToastNotification from 'hooks/useToastNotification';

export const useAttendIncentiveSalary = (orgCode: string, targetMonth: any, categoryAry: any) => {
  const [headers, setHeaders] = useState([]);
  const [listIncentiveSalary, setListIncentiveSalary] = useState<any>([]);
  const [isLoading, setLoading] = useState<any>(false);
  const { successNotification, errorNotification } = useToastNotification();

  const functionType = 3;

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
        getAttendIncentiveSalaryDataList(orgCode, targetMonth, functionType, categoryAry).then((response: any) => {
            setListIncentiveSalary(response);
            setLoading(false);
        }).catch(() => {
          setLoading(false);
          setListIncentiveSalary([])
        });
    } catch (e) {
        setLoading(false);
    }
  }, [orgCode, targetMonth, categoryAry]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() =>{
    getAttendIncentiveSalaryHeader().then((response: any) => {
        setHeaders(response)
    });
  }, [])

  const handleCreateUpdateAttendIncentive = (targetMonth: any, incentiveSalaryDataList: any) => {
    setLoading(true);
    createAttendIncentive(targetMonth, incentiveSalaryDataList).then((response: any) => {
      setLoading(false);
      successNotification('更新しました。');
    }).catch((error: any) => {
      setLoading(false);
      if (error && error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }

  return { headers, listIncentiveSalary, isLoading, handleCreateUpdateAttendIncentive };
};

export default { useAttendIncentiveSalary };
