import ApiClient from './ApiClient';

export const exportFilePDF = async (
    orgCode: any,
    reportType:Number,
    targetDateFrom: any,
    targetDateTo:any,
    functionType: any,
    categoryAry: any,
    staffCode: any,
  ) => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  

    
    if(reportType === 0) {
        targetDateTo = targetDateFrom
    }

    const laborCostSettingFlag = false

    let appendUrl = '';
    if (Array.isArray(categoryAry)) {
      categoryAry.forEach((cateArr: any) => {
        appendUrl += `&categoryAry=${cateArr}`;
      });
    }
  
    const res = await ApiClient.getDownloadFile(`/v2/sales/attendAchievementList/${companyCode}?staffCode=${staffCode}&functionType=${functionType}&orgCode=${orgCode}&reportType=${reportType}&targetDateFrom=${targetDateFrom}&targetDateTo=${targetDateTo}&laborCostSettingFlag=${laborCostSettingFlag}${appendUrl}`, {});
    return res.data
  };