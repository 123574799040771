/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { Fragment, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import moment from 'moment';
import useScrollbarAutoWidth from 'hooks/useScrollbarAutoWidth';
import { Link } from 'react-router-dom'
import { useDownload } from './hooks';
import { isMobileOnly, isMacOs } from 'react-device-detect';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';
import { grayScale } from 'components/styles';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import { formatNumber } from 'utility/formatUtil';

const DEFAULT_SORT_FIELD = { fieldPath: 'item', sort: 'asc' } as FieldSortState
const MonthlySeparateDiscountSumaryTable: React.FC<{
  setIsLoading: any,
  orgCode: any,
  listHeader: any,
  discountSumary: any
  targetPeriodData: any
  getMediaMst: any
  compareToPreviousYear: any
  orgName: any,
  targetDateFrom: any,
  targetDateTo: any,
  roleScreen: any,
}> = ({
  setIsLoading,
  orgCode,
  listHeader,
  discountSumary,
  targetPeriodData,
  getMediaMst,
  compareToPreviousYear,
  orgName,
  targetDateFrom,
  targetDateTo,
  roleScreen,
}) => {

    const getDatesBetweenDates = useCallback((startDate: any, endDate: any) => {
      let dates: any = [];
      const theDate = new Date(startDate);
      while (theDate <= new Date(endDate)) {
        dates = [...dates, new Date(theDate)];
        theDate.setDate(theDate.getDate() + 1);
      }
      return dates;
    }, []);

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    const listDate = getDatesBetweenDates(targetDateFrom, targetDateTo);
    const listData = useMemo(() => {
      let finalResult: any = []
      listDate.map((item: any, index: any) => {
        let listItem: {}[] = []
        listHeader.map((itemHeader: any) => {
          const splitHeader = itemHeader.split(',');
          const variable = discountSumary && discountSumary.length > 0 && discountSumary[0] && discountSumary[0].length > 0
            ? discountSumary[0].map((itemDiscount: any) => (
              itemDiscount.discountTypeMstCode === splitHeader[0] && moment(item, 'YYYY-MM-DD').format('YYYYMMDD') === moment(itemDiscount.targetDay, 'YYYY-MM-DD').format('YYYYMMDD')
                ? { discountAmount: itemDiscount.discountAmount, discountCnt: itemDiscount.discountCnt } : 0
            ))
            : 0;
          const result = variable.length > 0 ? variable.filter((variableitem: any) => variableitem) : null;

          result && result.length > 0 ? listItem.push(result[0]) : listItem.push({ discountAmount: 0, discountCnt: 0 })
          // data.item.push(result[0])
        })
        finalResult.push({
          item,
          listItem
        })
      })
      return finalResult
    }, [listHeader, discountSumary])
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(listData, DEFAULT_SORT_FIELD)

    // ------------------------------------------------------------------
    // 出力
    // ------------------------------------------------------------------
    const { downloadSeparateDiscountReportCsv, downloadSeparateDiscountExcel } = useDownload();
    const headerInformation: any = [];
    let mergedRegions: any[] = [];
    const codeColumnIndex: any[] = [];
    const totalInformation: any[] = [];
    const searchResult = useMemo(() => [
      ...dataSort.map((d: any) => [moment(d.item, 'YYYY-MM-DD').format('YYYY年MM月DD日（ddd）'), ...d.listItem.flatMap((item: any) => [item.discountAmount, item.discountCnt])])
    ], [dataSort]);

    const titleText: any[] = [];

    useEffect(() => {
      if (listDate) {
        if (listHeader && listHeader.length > 0) {
          const searchFooter: any[] = [];
          listHeader.map((itemHeader: any) => {
            const splitHeader = itemHeader.split(',');
            // footer
            const variableTotal = discountSumary && discountSumary.length > 0 && discountSumary[1] && discountSumary[1].length > 0
              ? discountSumary[1].map((itemDiscount: any) => (
                itemDiscount.discountTypeMstCode === splitHeader[0] && itemDiscount.targetDay === null ? { discountAmount: itemDiscount.discountAmount, discountCnt: itemDiscount.discountCnt } : null
              ))
              : 0;
            const resultTotal = variableTotal.length > 0 ? variableTotal.filter((variableitem: any) => variableitem) : null;
            if (resultTotal && resultTotal.length > 0) {
              searchFooter.push(resultTotal[0].discountAmount);
              searchFooter.push(resultTotal[0].discountCnt);
            } else {
              searchFooter.push(0);
              searchFooter.push(0);
            }
          });

          totalInformation.push(['合計', ...searchFooter]);
        }
        // Header
        const HeaderItem: any[] = [];
        HeaderItem.push('日付');
        if (listHeader && listHeader.length > 0) {
          listHeader.map((itemHeader: any) => {
            const splitHeader = itemHeader.split(',');
            HeaderItem.push(splitHeader[1], '');
          });
          headerInformation.push(HeaderItem);
          listHeader.map((item: any) => {
            titleText.push('金額');
            titleText.push('枚数');
          });

          headerInformation.push(['', ...titleText]);
        } else {
          headerInformation.push(HeaderItem);
        }
      }
    }, [discountSumary, headerInformation, listDate, listHeader, titleText, totalInformation]);
    const targetStoresData = useMemo(() => `対象店舗：${orgName}`, [orgName]);

    const data = {
      footer: '',
      formName: '月次日別値引・割引集計表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      compareToPreviousYear,
      headerInformation,
      searchResult,
      totalInformation,
    };
    const handleExportCSV = () => downloadSeparateDiscountReportCsv(data, setIsLoading);
    const dataExcel = {
      formName: '月次日別値引・割引集計表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      compareToPreviousYear,
      headerInformation,
      searchResult,
      totalInformation,
      mergedRegions,
      codeColumnIndex,
    };

    const handleExportExcel = useCallback(() => {
      if (mergedRegions) {
        mergedRegions = [];
      }
      if (listHeader && listHeader.length) {
        mergedRegions.push([4, 5, 0, 0]);
        for (let i = 1; i < titleText.length; i++) {
          if (i % 2 === 1) {
            mergedRegions.push([4, 4, i, i + 1]);
          }
        }
      } else {
        mergedRegions = [];
      }
      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [];
      dataExcel.headerInformation = headerInformation;
      return downloadSeparateDiscountExcel(dataExcel, setIsLoading);
    }, [mergedRegions, listHeader, titleText, headerInformation]);

    return (
      <div>
        <FlexBox>
          {
            roleScreen && roleScreen.downloadFlag === 1 && (
              <React.Fragment>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportCSV}
                    iconType="download"
                    text=" CSV出力"
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportExcel}
                    iconType="download"
                    text=" EXCEL出力"
                  />
                </FlexBoxItem>
              </React.Fragment>
            )}
        </FlexBox>
        <div style={{ margin: '15px' }}>
          <div className="templateTable newDesignTable" style={{ height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: isMacOs ? '35px' : '1px' }} >
            <table className="table table-bordered text-nowrap">
              <thead>
                {/* frist row */}
                <tr>
                  {/* left side */}
                  <th rowSpan={(listHeader && listHeader.length > 0) ? 2 : 1} style={{ top: '0', left: '0', zIndex: 99, maxWidth: ' 150px', width: '150px', minWidth: '150px' }}
                    className="text-center stickyStyle fristColSticky" onClick={e => sortByFieldPath('item')} >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>日付</span>
                      {sortField.fieldPath === 'item' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'item' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  {/* right side */}
                  {
                    listHeader && listHeader.length > 0
                      ?
                      listHeader.map((item: any, index: any) => {
                        const splitHeader = item.split(',');
                        return (
                          <th colSpan={2} key={index} style={{ top: '0', zIndex: 95, minWidth: '400px', width: "100%" }} className="text-center stickyStyle"><span style={{ width: '100%', minWidth: '251px' }}>{splitHeader[1]}</span></th>
                        );
                      })
                      : <th colSpan={listHeader && listHeader.length} style={{ top: '0', width: "100%" }} className="text-center stickyStyle"><span>-</span></th>
                  }

                </tr>
                {/* second row */}
                <tr>
                  {
                    (listHeader && listHeader.length > 0) &&
                    (
                      listHeader.map((item: any, index: any) => (
                        <Fragment key={index}>
                          <th style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath(`listItem[${index}].discountAmount`, 'number')}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <span style={{ margin: 'auto' }}>金額</span>
                              {sortField.fieldPath === `listItem[${index}].discountAmount` && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                              {sortField.fieldPath !== `listItem[${index}].discountAmount` && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                            </div>
                          </th>
                          <th style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath(`listItem[${index}].discountCnt`, 'number')}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <span style={{ margin: 'auto' }}>枚数</span>
                              {sortField.fieldPath === `listItem[${index}].discountCnt` && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                              {sortField.fieldPath !== `listItem[${index}].discountCnt` && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                            </div>
                          </th>
                        </Fragment>
                      ))
                    )
                  }
                </tr>
              </thead>
              <tbody>
                {
                  dataSort && dataSort.map((item: any, index: number) => (
                    <tr key={index}>
                      {/* left side */}
                      <td className="text-center stickyStyle fristColSticky" style={{ left: '0', zIndex: 10 }}>
                        <span>
                          <div className="text-ellipsis">
                            <Link to={{ pathname: `/salesDailyReport/${orgCode}/${moment(item.item, 'YYYY-MM-DD').format('YYYY-MM-DD')}` }}>
                              {moment(item.item, 'YYYY-MM-DD').format('YYYY年MM月DD日（ddd）')}
                            </Link>
                          </div>
                        </span>
                      </td>
                      {/* right side */}
                      {
                        item.listItem && item.listItem.length ? item.listItem.map((result: any, index2: number) => {
                          return (
                            <Fragment key={index2}>
                              <td className="text-right">
                                <span>{formatNumber(result.discountAmount)}</span>
                              </td>
                              <td className="text-right">
                                <span>{formatNumber(result.discountCnt)}</span>
                              </td>
                            </Fragment>
                          )
                        }) : (<td className="text-center"><span>-</span></td>)
                      }
                    </tr>
                  ))
                }
                {/* footer */}
                <tr style={{ fontWeight: 'bolder' }}>
                  <td className="text-center stickyStyle fristColSticky" style={{ top: '0', left: '0', zIndex: 99 }}>合計</td>
                  {
                    listHeader && listHeader.length ? listHeader.map((itemHeader: any, index: number) => {
                      const splitHeader = itemHeader.split(',');
                      const variableTotal = discountSumary && discountSumary.length > 0 && discountSumary[1] && discountSumary[1].length > 0
                        ? discountSumary[1].map((itemDiscount: any) => (
                          itemDiscount.discountTypeMstCode === splitHeader[0] && itemDiscount.targetDay === null ? itemDiscount.discountAmount : null
                        ))
                        : 0;
                      const resultTotal = variableTotal.length > 0 ? variableTotal.filter((variableitem: any) => variableitem) : null;

                      const variablediscountCnt = discountSumary && discountSumary.length > 0 && discountSumary[1] && discountSumary[1].length > 0
                        ? discountSumary[1].map((itemDiscount: any) => (
                          itemDiscount.discountTypeMstCode === splitHeader[0] && itemDiscount.targetDay === null ? itemDiscount.discountCnt : null
                        ))
                        : 0;
                      const resultdiscountCnt = variablediscountCnt.length > 0 ? variablediscountCnt.filter((variableitem: any) => variableitem) : null;

                      return (
                        <Fragment key={index}>
                          <td className="text-right">
                            <span>{resultTotal && resultTotal.length > 0 ? formatNumber(resultTotal[0]) : 0}</span>
                          </td>
                          <td className="text-right">
                            <span>{resultdiscountCnt && resultdiscountCnt.length > 0 ? formatNumber(resultdiscountCnt[0]) : 0}</span>
                          </td>
                        </Fragment>
                      );
                    }) : (<td className="text-right"><span>-</span></td>)
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    );
  };
export default MonthlySeparateDiscountSumaryTable;
