/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import FormContents from "components/atoms/Form/FormContents";
import OrganizationFilteredTree from "components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree";
import YearPicker from "components/molecules/YearPicker/YearPicker";
import FlexBox from "components/atoms/FlexBox";
import moment from "moment";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import IconLabelButton from "components/molecules/IconLabelButton";
import useIncomeExpenditure, {
  useDownload
} from "components/organismos/master/general/salesPage/incomeExpenditure/hook";
import IncomeExpenditureListTable from "components/organismos/master/general/salesPage/incomeExpenditure/incomeExpenditureListTable";
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const IncomeExpenditureList: React.FC<{}> = () => {
  const {
    isLoading,
    listIncomeExpenditure,
    functionType,
    orgCode,
    setOrgCode,
    loginStaffCode,
    categoryAry,
    setCategoryAry,
    selectedYear,
    setSelectedYear
  } = useIncomeExpenditure();

  const formatter = new Intl.NumberFormat("ja-JP", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });

  const formatterPercent = (number: number) => {
    return formatter.format(number) + "%";
  };

  const [orgName, setorgName] = useState(
    sessionStorage.getItem("loginUser.orgName")
      ? sessionStorage.getItem("loginUser.orgName")
      : ""
  );

  const searchResult: any[] = [];

  const { downloadCsv, downloadExcel } = useDownload();

  const headerInformation: any[] = [];

  let mergedRegions: any[] = [];

  const codeColumnIndex: any[] = [];

  const data = {
    footer: "",
    formName: "【月別】収支表帳票",
    targetPeriod: `対象期間: ${selectedYear}`,
    targetStores: `対象店舗：${orgName}`,
    headerInformation,
    searchResult,
    totalInformation: []
  };

  const dataExcel = {
    formName: "【月別】収支表帳票",
    targetPeriod: `対象期間: ${selectedYear}`,
    targetStores: `対象店舗：${orgName}`,
    headerInformation,
    searchResult,
    totalInformation: [],
    mergedRegions,
    codeColumnIndex
  };

  const handleExportCSV = () => downloadCsv(data);

  const handleExportExcel = () => downloadExcel(dataExcel);

  useEffect(() => {
    let employeeLaborCost: any = [];
    let partSalary: any = [];
    let partAdjustCost: any = [];
    let totalLaborCost: any = [];
    let retireLaborCost: any = [];
    let foodTotal: any = [];
    let drinkTotal: any = [];
    let termStart: any = [];
    let termEnd: any = [];
    let totalPurchase: any = [];
    let totalCommunicationCost: any = [];
    let totalAdvertisingCost: any = [];
    let totalRecruitmentCost: any = [];
    let totalExpenses: any = [];
    let totalUtilityCosts: any = [];
    let totalLandRent: any = [];
    let totalCcAmount: any = [];
    let ccFee: any = [];
    let depreciation: any = [];
    let bonusDepreciation: any = [];
    let repairCosts: any = [];
    let commonFee: any = [];
    let totalExpenditure: any = [];
    let netSales: any = [];
    let otherNetSales: any = [];
    let totalNetSales: any = [];
    let ordinaryIncome: any = [];
    let salesBudgetTotal: any = [];
    let totalFlCost: any = [];
    let totalFlaCost: any = [];
    let totalFlarCost: any = [];
    let totalFlaroCost: any = [];
    let monthCSV: any = [];

    employeeLaborCost.push("人件費", "社員");
    partSalary.push("", "アルバイト");
    partAdjustCost.push("", "アルバイト調整額");
    totalLaborCost.push("", "人件費合計");
    retireLaborCost.push("人件費(退職者)", "");
    foodTotal.push("仕入", "食材合計");
    drinkTotal.push("", "飲材合計");
    termStart.push("", "前月棚卸額");
    termEnd.push("", "当月棚卸額");
    totalPurchase.push("", "仕入原材料合計");
    totalCommunicationCost.push("通信費合計", "");
    totalAdvertisingCost.push("宣伝費合計", "");
    totalRecruitmentCost.push("募集費合計", "");
    totalExpenses.push("諸経費合計", "");
    totalUtilityCosts.push("光熱費合計", "");
    totalLandRent.push("家賃合計", "");
    totalCcAmount.push("決済手数料", "決済売上(税抜)");
    ccFee.push("", "手数料");
    depreciation.push("減価償却費", "");
    bonusDepreciation.push("賞与償却費", "");
    repairCosts.push("修繕費", "");
    commonFee.push("共通費", "");
    totalExpenditure.push("支出総計", "");
    netSales.push("売上(税抜)", "POS売上");
    otherNetSales.push("", "その他売上");
    totalNetSales.push("", "売上合計");
    ordinaryIncome.push("営業利益/率", "");
    salesBudgetTotal.push("予算/達成率", "");
    totalFlCost.push("FLコスト/率", "");
    totalFlaCost.push("FLAコスト/率", "");
    totalFlarCost.push("FLARコスト/率", "");
    totalFlaroCost.push("FLAROコスト/率", "");
    monthCSV.push(orgCode + " " + orgName, "");

    let monthlyDataFilterNull = listIncomeExpenditure.filter(function(el: any) {
      return el != null;
    });

    // Merge Column && Row Excel
    if (mergedRegions) {
      mergedRegions = [];
    }

    mergedRegions.push([4, 7, 0, 0]);
    mergedRegions.push([8, 8, 0, 1]);
    mergedRegions.push([9, 13, 0, 0]);
    mergedRegions.push([14, 14, 0, 1]);
    mergedRegions.push([15, 15, 0, 1]);
    mergedRegions.push([16, 16, 0, 1]);
    mergedRegions.push([17, 17, 0, 1]);
    mergedRegions.push([18, 18, 0, 1]);
    mergedRegions.push([19, 19, 0, 1]);
    mergedRegions.push([20, 21, 0, 0]);
    mergedRegions.push([22, 22, 0, 1]);
    mergedRegions.push([23, 23, 0, 1]);
    mergedRegions.push([24, 24, 0, 1]);
    mergedRegions.push([25, 25, 0, 1]);
    mergedRegions.push([26, 26, 0, 1]);
    mergedRegions.push([27, 29, 0, 0]);
    mergedRegions.push([30, 30, 0, 1]);
    mergedRegions.push([31, 31, 0, 1]);
    mergedRegions.push([32, 32, 0, 1]);
    mergedRegions.push([33, 33, 0, 1]);
    mergedRegions.push([34, 34, 0, 1]);
    mergedRegions.push([35, 35, 0, 1]);

    dataExcel.mergedRegions = mergedRegions;
    dataExcel.codeColumnIndex = [0];

    monthlyDataFilterNull.length > 0 &&
      monthlyDataFilterNull.map((item: any, index: any) => {
        // Merge Column Data Excel
        index === 0
          ? mergedRegions.push([3, 3, 0, 1]) && mergedRegions.push([3, 3, 2, 3])
          : mergedRegions.push([3, 3, (index + 1) * 2, (index + 1) * 2 + 1]);
        mergedRegions.push([27, 27, (index + 1) * 2, (index + 1) * 2 + 1]);
        mergedRegions.push([28, 28, (index + 1) * 2, (index + 1) * 2 + 1]);
        mergedRegions.push([29, 29, (index + 1) * 2, (index + 1) * 2 + 1]);

        // Handle add data to CSV + Excel
        monthCSV.push(
          moment(item.targetMonth).format("YYYY") !== "9999"
            ? moment(item.targetMonth).format("YYYY年MM月")
            : `${selectedYear}年 合計`,
          ""
        );

        employeeLaborCost.push(
          formatter.format(item.employeeLaborCost),
          item.employeeLaborCostRate + "%"
        );
        partSalary.push(
          formatter.format(item.partSalary),
          item.partSalaryRate + "%"
        );
        partAdjustCost.push(
          formatter.format(item.partAdjustCost),
          item.partAdjustCostRate + "%"
        );
        totalLaborCost.push(
          formatter.format(item.totalLaborCost),
          item.totalLaborCostRate + "%"
        );
        retireLaborCost.push(
          formatter.format(item.retireLaborCost),
          item.retireLaborCostRate + "%"
        );
        foodTotal.push(
          formatter.format(item.foodTotal),
          item.foodTotalRate + "%"
        );
        drinkTotal.push(
          formatter.format(item.drinkTotal),
          item.drinkTotalRate + "%"
        );
        termStart.push(
          formatter.format(item.termStart),
          item.termStartRate + "%"
        );
        termEnd.push(formatter.format(item.termEnd), item.termEndRate + "%");
        totalPurchase.push(
          formatter.format(item.totalPurchase),
          item.totalPurchaseRate + "%"
        );
        totalCommunicationCost.push(
          formatter.format(item.totalCommunicationCost),
          item.totalCommunicationCostRate + "%"
        );
        totalAdvertisingCost.push(
          formatter.format(item.totalAdvertisingCost),
          item.totalAdvertisingCostRate + "%"
        );
        totalRecruitmentCost.push(
          formatter.format(item.totalRecruitmentCost),
          item.totalRecruitmentCostRate + "%"
        );
        totalExpenses.push(
          formatter.format(item.totalExpenses),
          item.totalExpensesRate + "%"
        );
        totalUtilityCosts.push(
          formatter.format(item.totalUtilityCosts),
          item.totalUtilityCostsRate + "%"
        );
        totalLandRent.push(
          formatter.format(item.totalLandRent),
          item.totalLandRentRate + "%"
        );
        totalCcAmount.push(
          formatter.format(item.totalCcAmount),
          item.totalCcAmountRate + "%"
        );
        ccFee.push(formatter.format(item.ccFee), item.ccFeeRate + "%");
        depreciation.push(
          formatter.format(item.depreciation),
          item.depreciationRate + "%"
        );
        bonusDepreciation.push(
          formatter.format(item.bonusDepreciation),
          item.bonusDepreciationRate + "%"
        );
        repairCosts.push(
          formatter.format(item.repairCosts),
          item.repairCostsRate + "%"
        );
        commonFee.push(
          formatter.format(item.commonFee),
          item.commonFeeRate + "%"
        );
        totalExpenditure.push(
          formatter.format(item.totalExpenditure),
          item.totalExpenditureRate + "%"
        );
        netSales.push(formatter.format(item.netSales), '');
        otherNetSales.push(formatter.format(item.otherNetSales), '');
        totalNetSales.push(formatter.format(item.totalNetSales), '');
        ordinaryIncome.push(
          formatter.format(item.ordinaryIncome),
          item.profitRate + "%"
        );
        salesBudgetTotal.push(
          formatter.format(item.salesBudgetTotal),
          item.salesAchievementRate + "%"
        );
        totalFlCost.push(
          formatter.format(item.totalFlCost),
          item.totalFlRate + "%"
        );
        totalFlaCost.push(
          formatter.format(item.totalFlaCost),
          item.totalFlaRate + "%"
        );
        totalFlarCost.push(
          formatter.format(item.totalFlarCost),
          item.totalFlarRate + "%"
        );
        totalFlaroCost.push(
          formatter.format(item.totalFlaroCost),
          item.totalFlaroRate + "%"
        );

        return true;
      });
    headerInformation.push(monthCSV);
    searchResult.push(
      employeeLaborCost,
      partSalary,
      partAdjustCost,
      totalLaborCost,
      retireLaborCost,
      foodTotal,
      drinkTotal,
      termStart,
      termEnd,
      totalPurchase,
      totalCommunicationCost,
      totalAdvertisingCost,
      totalRecruitmentCost,
      totalExpenses,
      totalUtilityCosts,
      totalLandRent,
      totalCcAmount,
      ccFee,
      depreciation,
      bonusDepreciation,
      repairCosts,
      commonFee,
      totalExpenditure,
      netSales,
      otherNetSales,
      totalNetSales,
      ordinaryIncome,
      salesBudgetTotal,
      totalFlCost,
      totalFlaCost,
      totalFlarCost,
      totalFlaroCost
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listIncomeExpenditure, searchResult]);

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox>
          <YearPicker
            selectedYear={selectedYear}
            onChange={(year: number) => {
              setSelectedYear(year);
            }}
            setAutoLeft={false}
          />
        </FlexBox>
        <div style={{ marginLeft: "8px" }}>
          <OrganizationFilteredTree
            functionType={functionType}
            orgCallback={(args: string | Array<string>) => {
              setOrgCode(String(args));
            }}
            orgCategoryCallback={(args: Array<string>) => {
              setCategoryAry(args);
            }}
            orgNameCallback={(arg: any) => {
              setorgName(String(arg));
            }}
            initOrgValue={String(orgCode)}
            staffCode={loginStaffCode}
            orgLabel="組織名"
            addAllItem={false}
          />
        </div>
        {
          roleScreen && roleScreen.downloadFlag === 1 && (
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportCSV}
                iconType="download"
                text=" CSV出力"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <IconLabelButton
                onClick={handleExportExcel}
                iconType="download"
                text=" EXCEL出力"
              />
            </FlexBoxItem>
          </FlexBox>
        )}
        <IncomeExpenditureListTable
          listIncomeExpenditure={listIncomeExpenditure}
          year={selectedYear}
          orgCode={orgCode}
          orgName={orgName}
        />
      </FormContents>
    </BlockUI>
  );
};
export default IncomeExpenditureList;
