/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { textFontSize, textColor, utilityColor } from 'components/styles';
import FormLabel from 'components/atoms/Form/FormLabel';
import Checkbox from 'components/molecules/Checkbox';

const styles = {
  subLabel: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: '4px',
  }),
  selector: css({
    display: 'flex',
    marginTop: '8px',
  }),
  item: css({
    marginRight: '12px',
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: '4px',
  }),
};

interface CheckboxItem {
  id: string;
  value: boolean;
  label: string;
}

const MultipleCheckboxForm: React.FC<{
  label: string;
  subLabel?: string;
  items: Array<CheckboxItem>;
  name: string;
  note?: string;
  setValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disableCheckboxs?: Array<string>;
}> = ({
  label, subLabel, items, name, setValue, note, disableCheckboxs,
}) => (
  <div>
    <FormLabel
      label={label}
      subText={subLabel}
    />
    <div css={styles.selector}>
      {items.map((item, id) => (
        <div css={styles.item} key={item.id}>
          <Checkbox
            id={`${String(item.id)}`}
            name={name}
            label={item.label}
            value={String(item.value)}
            checked={item.value}
            onChange={setValue}
            readOnly={!!disableCheckboxs?.includes(item.id)}
          />
        </div>
      ))}
    </div>
    {note && <span css={css(styles.noteText, { color: utilityColor.error })} dangerouslySetInnerHTML={{ __html: note }} />}
  </div>
);

export default MultipleCheckboxForm;
