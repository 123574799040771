/**
 * APIのデータ型
 */
export interface SalaryCsvSetting {
    companyCode: string;
    createUser: string;
    updateUser: string;
    formatId: string;
    formatType: number;
  }


export default class SalaryCsvSettingDomain {
  constructor(private rawData: SalaryCsvSetting) {
    // do nothing
  }

  static generateInitial(): SalaryCsvSettingDomain {
    return new SalaryCsvSettingDomain({
      companyCode: '',
      createUser: '',
      updateUser: '',
      formatId: '',
      formatType: 0,
    });
  }

  getRawData(): SalaryCsvSetting {
    return this.rawData;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = String(companyCode);
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set formatId(formatId: string) {
    this.rawData.formatId = String(formatId);
  }

  get formatId(): string {
    return this.rawData.formatId;
  }

  set formatType(formatType: number) {
    this.rawData.formatType = Number(formatType);
  }

  get formatType(): number {
    return this.rawData.formatType;
  }
}
