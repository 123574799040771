/** @jsx jsx */

import React, {
    useRef, useLayoutEffect, useState, useEffect,
  } from 'react';
  import { css, jsx } from '@emotion/core';
  import Link from 'components/atoms/Icon/Link';
  
  const styles = {
    wrapper: css({
      position: 'relative',
      padding: '0 15px 0 5px', 
      whiteSpace: "pre-line"
    }),
    externalLink: css({
      position: 'absolute',
      right: 0 ,
      top: '-10px',
      transform: 'scale(0.75)',
      cursor: 'pointer',
      svg: {
        path: {
          fill: 'blue !important',
        },
      },
    }),
  };
  const urlRegex = new RegExp('(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})');
  
  const PreText: React.FC<{
    rows?: number;
    value?: string;
    showExternalLink?: boolean;
  }> = ({
    rows = 2, value = '', showExternalLink = false,
  }) => {
    const [showLinks, setShowLinks] = useState(false);
    const linkRef = useRef<string>('');
  
    useEffect(() => {
      if (!showExternalLink) {
        return;
      }
      if (urlRegex.test(value) && urlRegex.exec(value)) {
        const links = urlRegex.exec(value);
        if (links) {
          let result = links[0];
          if (value?.includes('https') && !result.includes('https')) {
            result = `https://${result}`;
          } else if (value?.includes('http') && !result.includes('http')) {
            result = `http://${result}`;
          }
          if (!result.includes('http')) {
            result = `http://${result}`;
          }
          linkRef.current = result;
          setShowLinks(true)
        } else {
          linkRef.current = '';
          setShowLinks(false)
        }
      } else {
        linkRef.current = '';
        setShowLinks(false)
      }
    }, [showExternalLink, value]);

    const openLink = () => {
      window.open(linkRef.current, '_blank');
    };
    return (
        <pre css={css(styles.wrapper)}>
          {value}
          {showLinks && linkRef.current && (
          <div aria-hidden="true" css={css(styles.externalLink)} onClick={openLink} title={linkRef.current}>
              <Link />
          </div>
          )}
      </pre>
    );
  };
  
  export default PreText;
  