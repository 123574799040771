import React from 'react';
import { useParams } from 'react-router-dom';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AccountTitleForm from 'components/organismos/master/general/accountTitleMST/AccountTitleMSTForm';

const AccountTitleMSTAddPage: React.FC = () => {
  const { accountTitleId } = useParams();
  return (
    <SidebarTemplate pageTitle={accountTitleId ? '勘定科目編集' : '勘定科目登録'}>
        <AccountTitleForm />
    </SidebarTemplate>
  );
};


export default AccountTitleMSTAddPage;
