import { useState, useEffect } from 'react';
import { getDescriptionMstList, getjournalMstSelect, getClassificationMstSelect } from 'api/descriptionMaster';
import DescriptionMasterDomain from 'domain/master/general/descriptionMaster';
import { OptionType } from 'components/atoms/Select';

export const useDescriptionMasterItemList = () => {
  const [descriptMasterItemList, setDescriptMasterItemList] = useState<Array<any>>([]);
  useEffect(() => {
    getDescriptionMstList().then((response: Array<any>) => {
      setDescriptMasterItemList(
        response.map((result) => new DescriptionMasterDomain(result)),
      );
    });
  }, []);
  return {
    descriptMasterItemList, setDescriptMasterItemList,

  };
};

export const useJournalMstSelectTree = () : any => {
  const [journalMstSelect, setJournalMstSelect] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getjournalMstSelect().then((trees: Array<any>) => {
      const OptionTree = trees.map((tree: any) => ({
        value: tree.journalId,
        label: `${tree.journalCode} ${tree.journalName}`,
      }));
      setJournalMstSelect(OptionTree);
    });
  }, []);
  return {
    journalMstSelect,
  };
};

export const useClassificationMstTree = () : any => {
  const [classificationMstOption, setClassificationMstOption] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getClassificationMstSelect().then((trees: Array<any>) => {
      const OptionTree = trees.map((tree: any) => ({
        value: tree.classificationId,
        label: `${tree.classificationCode} ${tree.classificationName}`,
      }));
      setClassificationMstOption(OptionTree);
    });
  }, []);
  return {
    classificationMstOption,
  };
};

export default useDescriptionMasterItemList;
