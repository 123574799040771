import React from 'react';

import FormLabel from 'components/atoms/Form/FormLabel';
import PasswordInput from 'components/atoms/Form/PasswordInput';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';

const PasswordForm: React.FC<{
  name: string;
  label: string;
  value: string;
  width?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  errorMsg?: string;
}> = ({
  name, label, value, onChange, width, errorMsg,onKeyPress
}) => (
  <VerticalLabelFormLayout
    label={(
      <FormLabel
        label={label}
      />
      )}
    input={(
      <PasswordInput
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      )}
    errorMsg={errorMsg}
  />
);

export default PasswordForm;
