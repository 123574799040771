import moment from 'moment';
import { TGeneralImport } from '../../type/import';
import actionTypes from './actionType';
import { Actions } from './action';

export const initialState = {
  bankOption: '0',
  businessStartDate: moment(new Date()).format('YYYY/MM/DD'),
  businessEndDate: moment(new Date()).format('YYYY/MM/DD'),
};
export const reducer = (state: TGeneralImport, action: Actions) => {
  const { type, response } = action;
  const cloneData:TGeneralImport = JSON.parse(JSON.stringify({ ...response }));
  switch (type) {
    // Fetch data from API action
    case actionTypes.FETCH:
      // cloneData.generalData = {
      //   // checkExist: response.checkExist,
      //   // listAdvertising: response.listAdvertising,
      //   // listAdvertisingApplyPeriod: response.listAdvertisingApplyPeriod,
      //   // listAdvertisingExpense: response.listAdvertisingExpense,
      //   // listExpenses: response.listExpenses,
      //   // salesBudgetMonthly: response.salesBudgetMonthly
      // };
      return cloneData;
    default:
      throw new Error();
  }
};
