/* eslint-disable quotes */
/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import {
  getListAd,
  getListMediaAdvertising, getListOrganizationAdvertising,
  getListSegment,
  getSalesSlipList,
} from 'api/salesSlip';
import CheckboxOff from 'components/atoms/Icon/CheckboxOff';
import CheckboxOn from 'components/atoms/Icon/CheckboxOn';
import { TableColumnsType } from 'domain/salesReport';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

const styles = {
  table: css({
    width: '100%',
    borderSpacing: 0,
    borderLeft: '1px solid #222d32',
    borderBottom: '1px solid #222d32',
    minWidth: '280%',
  }),
  border: css({
    fontWeight: 'bold',
    fontSize: 12,
    borderRight: '1px solid #222d32',
    borderTop: '1px solid #222d32',
    padding: '5px 15px',
  }),
  body: css({
    fontWeight: 400,
    fontSize: 14,
  }),
  scrollBar: css({
    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar:horizontal': {
      height: 6,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparentize(#ccc, 0.7)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      background: '#7f7f7f',
    },
  }),
};

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0, maximumFractionDigits: 0,
});

const SalesUncategorizedTable: React.FC<{
  organization: string,
  orgApplyDate: Date,
  functionType: number,
  categoryArray: Array<any>,
  loginStaffCode: string,
  selectTab: string,
  salesSlip: any,
  salesSlipCallback: (args: any) => void,
  isDisableCallback: (args: any) => void,
  initState: any,
  organizationMedia: any
}> = ({
  organization,
  organizationMedia,
  orgApplyDate,
  functionType,
  categoryArray,
  loginStaffCode,
  selectTab,
  salesSlip,
  salesSlipCallback,
  isDisableCallback,
  initState,
}) => {
    const [advertisingMedia, setAdvertisingMedia] = useState<Array<any>>([]);
    const [advertisingPlan, setAdvertisingPlan] = useState<Array<any>>([]);
    const [segmentList, setSegmentList] = useState<Array<any>>([]);
    useEffect(() => {
      const query = {
        orgCode: organization,
        targetDay: moment(orgApplyDate).format('YYYY-MM-DD'),
        functionType,
        categoryAry: categoryArray,
        loginStaffCode,
      };

      getListSegment().then((res: any) => {
        if (res.length > 0) {
          const newList = res.map((value: any) => ({
            segmentName: value.segmentName,
            segmentCode: value.id.segmentCode,
            segmentType: value.segmentType,
          }));
          setSegmentList(newList);
        }
      });

      getListAd(query).then((res: any) => {
        if (res.length > 0) {
          const newList = res.map((value: any) => {
            const ogList = value.salesAdvertisingPlanMstDataList;
            return ogList.map((data: any) => ({
              advertisingPlanMstName: data.advertisingPlanMstName,
              advertisingPlanMstCode: data.advertisingPlanMstCode,
            }));
          });
          const fn = _.spread(_.union);
          const result = fn(newList);
          setAdvertisingPlan(result);
        }
      });

      getListMediaAdvertising(query).then((res: any) => {
        if (res.length > 0) {
          const newList = res.map((value: any) => {
            const ogList = value.salesAdvertisingMediaOrganizationDataList;
            return ogList.map((data: any) => ({
              advertisingMediaMstName: data.advertisingMediaMstName,
              advertisingMediaMstCode: data.advertisingMediaMstCode,
            }));
          });
          const fn = _.spread(_.union);
          const result = fn(newList);
          setAdvertisingMedia(result);
        }
      });
    }, [categoryArray, functionType, loginStaffCode, orgApplyDate, organization]);


    const tableColumns: TableColumnsType[] = [
      {
        name: '伝票番号',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} style={{ textAlign: 'center', width: '60px' }} css={styles.border}>{salesSlip[rowIndex].slipNo}</td>
        ),
      },
      {
        name: '注文日時',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} style={{ textAlign: 'center', width: '50px' }} css={styles.border}>{salesSlip[rowIndex].orderTime !== '-' ? moment(salesSlip[rowIndex].orderTime).format('HH:mm') : '-'}</td>
        ),
      },
      {
        name: '会計日時',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} style={{ textAlign: 'center', width: '50px' }} css={styles.border}>{salesSlip[rowIndex].accountTime !== '-' ? moment(salesSlip[rowIndex].accountTime).format('HH:mm') : '-'}</td>
        ),
      },
      {
        name: '売上区分',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} style={{ textAlign: 'center', width: '180px' }} css={styles.border}>
            <select
              id={String(colIndex)}
              style={{ padding: '5px', height: '30px', width: '100%' }}
              value={salesSlip[rowIndex].segment}
              onChange={(e) => {
                const option = e.target;
                const segmentTypeSelect = Number(option[option.selectedIndex].getAttribute('data-items'));
                const newSalesSlip = [...salesSlip];
                newSalesSlip[rowIndex].segment = parseInt(option.value);
                newSalesSlip[rowIndex].segmentType = segmentTypeSelect;
                salesSlipCallback(newSalesSlip);
              }}
            >
              {segmentList.length > 0 ? segmentList.map((data: any, i: number) => (
                <option key={i} value={data.segmentCode} data-items={data.segmentType}>
                  {data.segmentName}
                </option>
              )) : (<option value="">-</option>)}
              ))
            </select>
          </td>
        ),
      },
      {
        name: '担当者',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} style={{ textAlign: 'center', width: '40px' }} css={styles.border}>{salesSlip[rowIndex].personInChargeNo}</td>
        ),
      },
      {
        name: '広告店舗',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} css={styles.border} style={{ width: '180px' }}>
            <select
              style={{ padding: '5px', height: '30px', width: '100%' }}
              key={colIndex}
              onChange={(e) => {
                const option = e.target;
                const newSalesSlip = [...salesSlip];
                newSalesSlip[rowIndex].organizationAdvertisingNameNo = option.value;
                salesSlipCallback(newSalesSlip);
              }}
              disabled={salesSlip[rowIndex].segmentType === 2 && true}
              value={salesSlip[rowIndex].organizationAdvertisingNameNo}
            >
              {organizationMedia.length > 0 ? organizationMedia.map((data: any, i: number) => (
                <option key={i} value={data.organizationAdvertisingNameNo}>
                  {data.advertisingName}
                </option>
              )) : (<option value="">-</option>)}
              ))
            </select>
          </td>
        ),
      },
      {
        name: '広告媒体',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} css={styles.border} style={{ width: '180px' }}>
            <select
              style={{ padding: '5px', height: '30px', width: '100%' }}
              key={colIndex}
              onChange={(e) => {
                const option = e.target;
                const newSalesSlip = [...salesSlip];
                newSalesSlip[rowIndex].advertisingMediaCode = option.value;
                salesSlipCallback(newSalesSlip);
              }}
              disabled={salesSlip[rowIndex].segmentType === 2 && true}
              value={salesSlip[rowIndex].advertisingMediaCode}
            >
              {advertisingMedia.length > 0 ? advertisingMedia.map((data: any, i: number) => (
                <option key={i} value={data.advertisingMediaMstCode}>
                  {data.advertisingMediaMstName}
                </option>
              )) : (<option value="">-</option>)}
              ))
            </select>
          </td>
        ),
      },
      {
        name: 'テーブル',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} style={{ textAlign: 'center', width: '50px' }} css={styles.border}>{salesSlip[rowIndex].tableNo}</td>
        ),
      },
      {
        name: '客数',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} style={{ textAlign: 'right', width: '50px' }} css={styles.border}>{formatter.format(salesSlip[rowIndex].guestCnt)}</td>
        ),
      },
      {
        name: 'アイテム総点数',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} style={{ textAlign: 'right', width: '50px' }} css={styles.border}>{formatter.format(salesSlip[rowIndex].itemNum)}</td>
        ),
      },
      {
        name: '総売上',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} style={{ textAlign: 'right', width: '50px' }} css={styles.border}>{formatter.format(salesSlip[rowIndex].totalSales)}</td>
        ),
      },
      {
        name: '純売上',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} style={{ textAlign: 'right', width: '50px' }} css={styles.border}>{formatter.format(salesSlip[rowIndex].netSales)}</td>
        ),
      },
      {
        name: '消費税',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} style={{ textAlign: 'right', width: '50px' }} css={styles.border}>{formatter.format(salesSlip[rowIndex].tax)}</td>
        ),
      },
      {
        name: '値割引合計',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} style={{ textAlign: 'right', width: '50px' }} css={styles.border}>{formatter.format(salesSlip[rowIndex].totalDiscount)}</td>
        ),
      },
      {
        name: '端数値引き合計',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} style={{ textAlign: 'right', width: '50px' }} css={styles.border}>{formatter.format(salesSlip[rowIndex].fractionalDiscount)}</td>
        ),
      },
      {
        name: 'プラン',
        visible: true,
        content: (rowIndex: number, colIndex: number) => (
          <td key={colIndex} css={styles.border} style={{ width: '180px' }}>
            <select
              style={{ padding: '5px', height: '30px', width: '100%' }}
              key={colIndex}
              onChange={(e) => {
                const option = e.target;
                const newSalesSlip = [...salesSlip];
                newSalesSlip[rowIndex].planMstCode = option.value;
                newSalesSlip[rowIndex].advertisingPlanMstCode = option.value;
                salesSlipCallback(newSalesSlip);
              }}
              value={salesSlip[rowIndex].advertisingPlanMstCode}
            >
              <option value="">-</option>
              {advertisingPlan.length > 0 && advertisingPlan.map((data: any, i: number) => (
                <option key={i} value={data.advertisingPlanMstCode}>
                  {data.advertisingPlanMstName}
                </option>
              ))}
            </select>
          </td>
        ),
      },
      {
        name: 'BDP',
        visible: true,
        content: (rowIndex: number, colIndex: number) => {
          const status = salesSlip[rowIndex].bdpFlag;
          const opacityValue = salesSlip[rowIndex].segmentType !== 2 ? 1 : 0.45;
          return (
            <td key={colIndex} style={{ textAlign: 'center', width: '40px' }} css={styles.border}>
              {status
                ? <CheckboxOn style={{ opacity: opacityValue }} onClick={() => handleToggleStatusCheckboxRow(rowIndex, 'bdpFlag')} />
                : <CheckboxOff style={{ opacity: opacityValue }} onClick={() => handleToggleStatusCheckboxRow(rowIndex, 'bdpFlag')} />}
            </td>
          );
        },
      },
      {
        name: 'インバウンド',
        visible: true,
        content: (rowIndex: number, colIndex: number) => {
          const status = salesSlip[rowIndex].inboundFlag;
          const opacityValue = 1;
          return (
            <td key={colIndex} style={{ textAlign: 'center', opacity: opacityValue, width: '40px' }} css={styles.border}>
              {status
                ? <CheckboxOn onClick={() => handleToggleStatusCheckboxRow(rowIndex, 'inboundFlag')} />
                : <CheckboxOff onClick={() => handleToggleStatusCheckboxRow(rowIndex, 'inboundFlag')} />}
            </td>
          );
        },
      },
      {
        name: 'リピーター',
        visible: true,
        content: (rowIndex: number, colIndex: number) => {
          const status = salesSlip[rowIndex].repeaterFlag;
          const opacityValue = 1;
          return (
            <td key={colIndex} style={{ textAlign: 'center', opacity: opacityValue, width: '40px' }} css={styles.border}>
              {status
                ? <CheckboxOn onClick={() => handleToggleStatusCheckboxRow(rowIndex, 'repeaterFlag')} />
                : <CheckboxOff onClick={() => handleToggleStatusCheckboxRow(rowIndex, 'repeaterFlag')} />}
            </td>
          );
        },
      },
      {
        name: 'ランチ',
        visible: true,
        content: (rowIndex: number, colIndex: number) => {
          const status = salesSlip[rowIndex].lunchFlag;
          const opacityValue = 1;
          return (
            <td key={colIndex} style={{ textAlign: 'center', opacity: opacityValue, width: '40px' }} css={styles.border}>
              {status
                ? <CheckboxOn onClick={() => handleToggleStatusCheckboxRow(rowIndex, 'lunchFlag')} />
                : <CheckboxOff onClick={() => handleToggleStatusCheckboxRow(rowIndex, 'lunchFlag')} />}
            </td>
          );
        },
      },
      {
        name: '領収書',
        visible: true,
        content: (rowIndex: number, colIndex: number) => {
          const status = salesSlip[rowIndex].receiptFlg;
          const opacityValue = 1;
          return (
            <td key={colIndex} style={{ textAlign: 'center', opacity: opacityValue, width: '40px' }} css={styles.border}>
              {status
                ? <CheckboxOn onClick={() => handleToggleStatusCheckboxRow(rowIndex, 'receiptFlg')} />
                : <CheckboxOff onClick={() => handleToggleStatusCheckboxRow(rowIndex, 'receiptFlg')} />}
            </td>
          );
        },
      },
      {
        name: 'VOID',
        visible: true,
        content: (rowIndex: number, colIndex: number) => {
          const status = salesSlip[rowIndex].voidFlg;
          const opacityValue = 0.45;
          return (
            <td key={colIndex} style={{ textAlign: 'center', width: '40px' }} css={styles.border}>
              {status
                ? <CheckboxOn style={{ opacity: opacityValue }} onClick={() => handleToggleStatusCheckboxRow(rowIndex, 'voidFlg')} />
                : <CheckboxOff style={{ opacity: opacityValue }} onClick={() => handleToggleStatusCheckboxRow(rowIndex, 'voidFlg')} />}
            </td>
          );
        },
      },
      {
        name: 'VOID日時',
        visible: true,
        content: (rowIndex: number, colIndex: number) => <td key={colIndex} style={{ textAlign: 'center', width: '180px' }} css={styles.border}>{salesSlip[rowIndex].voidTime !== '-' ? moment(salesSlip[rowIndex].voidTime).format('YYYY-MM-DD') : '-'}</td>,
      },
    ];

    useEffect(() => {
      const formBreak = {
        orgCode: organization,
        targetDay: moment(orgApplyDate).format('YYYY-MM-DD'),
        functionType,
        categoryAry: categoryArray?.length > 0 ? String(categoryArray) : '',
        loginStaffCode,
      };
      getSalesSlipList(formBreak, selectTab).then((res: any) => {
        if (res.length > 0) {
          const newList = res.map((value: any) => ({
            accountTime: value.accountTime,
            advertisingPlanMstCode: value.advertisingPlanMstCode !== null ? value.advertisingPlanMstCode : '',
            advertisingMediaCode: value.advertisingMediaCode !== "" && value.advertisingMediaCode !== null ? value.advertisingMediaCode : advertisingMedia.length > 0 ? advertisingMedia[0].advertisingMediaMstCode : '',
            othersPlanMstCode: value.othersPlanMstCode !== null ? value.othersPlanMstCode : '',
            organizationAdvertisingNameNo: value.organizationAdvertisingNameNo !== null ? value.organizationAdvertisingNameNo : '',
            fractionalDiscount: value.fractionalDiscount !== null ? value.fractionalDiscount : '-',
            guestCnt: value.guestCnt !== null ? value.guestCnt : '-',
            itemNum: value.itemNum !== null ? value.itemNum : '-',
            netSales: value.netSales !== null ? value.netSales : '-',
            orderTime: value.orderTime,
            orgCode: value.orgCode,
            personInChargeNo: value.personInChargeNo !== null ? value.personInChargeNo : '-',
            receiptFlg: value.receiptFlg,
            segment: value.segment !== null ? value.segment : '',
            segmentType: value.segmentType !== null ? value.segmentType : '',
            posType: value.posType !== null ? value.posType : '',
            slipNo: value.slipNo !== null ? value.slipNo : '-',
            tableNo: value.tableNo !== null ? value.tableNo : '-',
            targetDay: value.targetDay !== null ? value.targetDay : '-',
            tax: value.tax !== null ? value.tax : '-',
            totalDiscount: value.totalDiscount !== null ? value.totalDiscount : '-',
            totalSales: value.totalSales !== null ? value.totalSales : '-',
            voidFlg: value.voidFlg,
            voidTime: value.voidTime !== null ? value.voidTime : '-',
            bdpFlag: value.bdpFlg !== null ? value.bdpFlg : false,
            inboundFlag: value.ibFlg !== null ? value.ibFlg : false,
            repeaterFlag: value.repeaterFlg !== null ? value.repeaterFlg : false,
            lunchFlag: value.lunchFlg !== null ? value.lunchFlg : false,
            planMstCode: value.segment === 1 ? value.advertisingPlanMstCode : value.segment === 2 ? value.othersPlanMstCode : '',
          }));
          if (isDisableCallback) {
            isDisableCallback(false);
          }
          return salesSlipCallback(newList);
        }
        if (isDisableCallback) {
          isDisableCallback(true);
        }
        salesSlipCallback([initState]);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryArray,
      functionType,
      loginStaffCode,
      orgApplyDate,
      organization,
      selectTab,
      advertisingMedia]);

    const handleToggleStatusCheckboxRow = useCallback((rowIndex: number, name: string) => {
      const tmpPointedOut = [...salesSlip];
      if (name === 'receiptFlg') {
        tmpPointedOut[rowIndex].receiptFlg = !tmpPointedOut[rowIndex].receiptFlg;
        return salesSlipCallback(tmpPointedOut);
      }

      if (name === 'voidFlg') {
        return salesSlipCallback(tmpPointedOut);
      }

      if (name === 'bdpFlag' && salesSlip[rowIndex].segment !== 2) {
        tmpPointedOut[rowIndex].bdpFlag = !tmpPointedOut[rowIndex].bdpFlag;
        return salesSlipCallback(tmpPointedOut);
      }

      if (name === 'inboundFlag') {
        tmpPointedOut[rowIndex].inboundFlag = !tmpPointedOut[rowIndex].inboundFlag;
        return salesSlipCallback(tmpPointedOut);
      }

      if (name === 'repeaterFlag') {
        tmpPointedOut[rowIndex].repeaterFlag = !tmpPointedOut[rowIndex].repeaterFlag;
        return salesSlipCallback(tmpPointedOut);
      }

      if (name === 'lunchFlag') {
        tmpPointedOut[rowIndex].lunchFlag = !tmpPointedOut[rowIndex].lunchFlag;
        return salesSlipCallback(tmpPointedOut);
      }
    }, [salesSlip, salesSlipCallback]);


    return (
      <div
        className="panel panel-default table-responsive"
        style={{
          marginTop: '20px', marginBottom: '20px', maxWidth: '1650px', overflowX: 'scroll',
        }}
        css={styles.scrollBar}
      >
        <table className="table table-bordered table-striped table-condensed text-nowrap" css={styles.table}>
          <thead>
            <tr className="width_row_contents">
              {tableColumns
                .filter((col) => col.visible)
                .map((column, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <th key={i} className="text-center" css={styles.border} style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {column.name}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody css={styles.body}>
            {salesSlip.map((row: any, rowIndex: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={rowIndex}>
                {tableColumns.filter((col) => col.visible).map(
                  (column, colIndex) => column.content(rowIndex, colIndex),
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };


export default SalesUncategorizedTable;
