import {
  useState, useCallback, useEffect,
} from 'react';

import {
  getGrantItemsForPaidHolidayGrantHistory,
  downloadPaidHolidayGrantHistoryCsv,
} from 'api/holidayManagement';
import PaidHolidayGrantHistoryDomain from 'domain/master/holidayManagement/paidHolidayGrantHistory';

export const useGrantHistoryDomainForm = () => {
  const sessionStorageOrgCode = String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}2`)) !== 'null' ? (String(sessionStorage.getItem(`sessionOrgSelect${window.location.pathname}2`))) : (sessionStorage.getItem('loginUser.orgCode') || '');
  const [
    paidHolidayGrantHistoryList, setPaidHolidayGrantHistoryList,
  ] = useState<Array<PaidHolidayGrantHistoryDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useState(sessionStorageOrgCode);
  const yearCurrent = new Date().getFullYear().toString();
  const sessionGrantHistoryTargetDateFrom = sessionStorage.getItem('grantHistory.targetDateFrom') || `${yearCurrent}/01/01`;
  const [
    targetDateFrom, setTargetDateFrom,
  ] = useState<Date>(new Date(sessionGrantHistoryTargetDateFrom));
  const sessionGrantHistoryTargetDateTo = sessionStorage.getItem('grantHistory.targetDateTo') || `${yearCurrent}/12/31`;
  const [targetDateTo, setTargetDateTo] = useState<Date>(new Date(sessionGrantHistoryTargetDateTo));
  const sessionGrantHistoryPaidGrantResult = sessionStorage.getItem('grantHistory.paidGrantResult') || '0';
  const [paidGrantResult, setPaidGrantResult] = useState(sessionGrantHistoryPaidGrantResult);
  const [modalOpen, setModalOpen] = useState(false);

  // データの取得
  const fetchData = useCallback(async () => {
    const response = await getGrantItemsForPaidHolidayGrantHistory(
      orgCode !== 'all' ? orgCode : '',
      targetDateFrom,
      targetDateTo,
      paidGrantResult,
    );
    setPaidHolidayGrantHistoryList(
      response.map((result) => new PaidHolidayGrantHistoryDomain(result)),
    );
  }, [orgCode, paidGrantResult, targetDateFrom, targetDateTo]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, paidGrantResult, targetDateFrom, targetDateTo]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return {
    paidHolidayGrantHistoryList,
    toggleNoManager,
    orgCode,
    setOrgCode,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    paidGrantResult,
    setPaidGrantResult,
    modalOpen,
    setModalOpen,
    closeModal,
  };
};

export const useDownloadHolidayManagementGrantHistory = (
  orgCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
  paidGrantResult: string,
) => {
  const downloadHolidayManagementGrantHistory = useCallback((isDownloadAll?: boolean) => {
    downloadPaidHolidayGrantHistoryCsv(
      isDownloadAll ? '' : orgCode,
      targetDateFrom,
      targetDateTo,
      paidGrantResult,
    );
  }, [orgCode, paidGrantResult, targetDateFrom, targetDateTo]);

  return {
    downloadHolidayManagementGrantHistory,
  };
};

export default {
  useGrantHistoryDomainForm,
};
