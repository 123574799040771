
import React from 'react';
import HeadquarterPointedOutList from 'components/organismos/master/general/salesPage/headquarterPointedOutList/headquarterPointedOutList';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const HeadquarterPointedOut = () => (
  <SidebarTemplate pageTitle="本部用指摘内容確認帳票">
    <HeadquarterPointedOutList />
  </SidebarTemplate>
);

export default HeadquarterPointedOut;
