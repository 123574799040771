/** @jsx jsx */
import TimeZoneDomain from 'domain/master/labor/TimeZone';
import { Column } from 'react-table';
import React, {
  useMemo, useCallback, useState, useEffect,
} from 'react';
import { css, jsx } from '@emotion/core';

import LableTitle from 'components/atoms/LableTitle';
import PageTitle from 'components/atoms/PageTitle';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';

import { deleteById, updateSortGetListTimeZone } from 'api/timeZone';
import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import DataTableSort from 'components/organismos/DataTableSort/DataTableSort';
import useToastNotification from 'hooks/useToastNotification';
import useTimeZone from './hooks';

const TimeZonePage: React.FC<{
  roleScreen?: any
}> = ({ roleScreen }) => {
  const { timeZoneList, setTimeZoneList } = useTimeZone();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState(TimeZoneDomain.generateInitial());
  const [pageList, setPageList] = useState([]);
  const { successNotification, errorNotification } = useToastNotification();

  useEffect(() => {
    let convertData = {};
    pageList.forEach((item: any, index: number) => {
      const ObjSubmit = {
        termOrder: index,
        termId: item.original.rawData.termId,
        termName: item.original.rawData.termName,
        targetType: item.original.rawData.targetType,
        startTime: item.original.rawData.startTime,
        endTime: item.original.rawData.endTime,
      };

      convertData = {
        ...convertData,
        [`terms[${index}]`]: {
          ...ObjSubmit,
        },
      };

      const loginUser = sessionStorage.getItem('loginUser.staffName') || '';
      convertData = {
        ...convertData,
        createUser: loginUser,
        updateUser: loginUser,
      };
    });

    try {
      const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
      updateSortGetListTimeZone(companyCode, convertData).then((response: Array<any>) => {
      });
    } catch {
      errorNotification('サーバー側でエラーが発生しました。');
    }
  }, [errorNotification, pageList]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((timeZone : TimeZoneDomain) => {
    setConfirmModalOpen(true);
    setSelectedTimeZone(timeZone);
  }, []);

  const deleteTimeZone = useCallback(
    () => {
      const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
      deleteById(companyCode, selectedTimeZone.termId).then((response:any) => {
        setTimeZoneList(timeZoneList.filter((timeZone) => timeZone.termId
                                                  !== selectedTimeZone.termId));
        setConfirmModalOpen(false);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = '';
          error.response.data.errors.map((item: { defaultMessage: string; }) => {
            msgError += `${item.defaultMessage} \n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    },
    [errorNotification, selectedTimeZone.termId,
      setTimeZoneList, successNotification, timeZoneList],
  );
  const columns: Array<Column<TimeZoneDomain>> = useMemo(() => [
    {
      Header: '時間帯名',
      accessor: 'termName',
      sortType: 'basic'
    },
    {
      Header: '対象',
      accessor: 'targetTypeLabel',
    },
    {
      Header: '時間帯',
      accessor: 'timeFull',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: TimeZoneDomain },
            data: Array<TimeZoneDomain>
          }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/masterTerm/edit/${cell.row.original.termId}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            {
              roleScreen && roleScreen.editable === 1 && (
              <React.Fragment>
                <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
                  <span
                    style={{ marginLeft: '20px', marginRight: '10px' }}
                    onMouseEnter={() => setIsHoverDelete(true)}
                    onMouseLeave={() => setIsHoverDelete(false)}
                  >
                    <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                  </span>
                </Link>
              </React.Fragment>
            )}
          </div>
        );
      },
    },
  ], [openConfirmModal, roleScreen]);
  return (
    <React.Fragment>
      <div>
        <LableTitle title="各時間帯で勤怠を集計することが出来るようになります。また、各時間帯で時給を加算させて人件費を計算することが出来るようになります。" />
        {
          roleScreen && roleScreen.editable === 1 && (
          <PageTitle
            title=""
            rightContents={(
              <Link to="/masterTerm/add">
                <span style={{ marginRight: '12px' }}>
                  <PrimaryButton
                    ghost={false}
                    text="新規時間帯マスタ作成"
                  />
                </span>
              </Link>
            )}
          />
        )}
        <div
          css={css`
            table th:nth-child(1) {
              width: 200px;
            }
            table th:nth-child(2) {
              width: 200px;
            }
            table th:nth-child(3) {
              width: 120px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTableSort
              columns={columns}
              data={timeZoneList}
              setPageList={setPageList}
              sortBy={[
                { id: 'termName', desc: false }
              ]}
            />
          </div>
        </div>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={deleteTimeZone}
          actionType={ActionType.DELETE}
        />
      </div>
    </React.Fragment>
  );
};

export default TimeZonePage;
