import { useEffect, useState } from 'react';
import {
  getPaymeCoordination, getCsvOutputPaymeCoordination, getPaymeCoordinationV2, getCsvOutputPaymeCoordinationV2,
} from 'api/PaymeCoordination';
import PaymeCoordinationDomain from 'domain/master/atendenceManagement/paymeCoordination';

const usePaymeCoordination = (orgCode: string, targetDateFrom: string,
  targetDateTo: string, employmentIds: string) => {
  const [paymeCoordination, setpaymeCoordination] = useState<Array<PaymeCoordinationDomain>>([]);
  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getPaymeCoordination(companyCode, orgCode, targetDateFrom, targetDateTo, employmentIds)
      .then((response: any) => {
        if (isSubscribed) {
          setpaymeCoordination(response.dataList.map(
            (result: any) => new PaymeCoordinationDomain(result.valueList),
          ));
        }
      });
    return () => { isSubscribed = false; };
  }, [employmentIds, orgCode, targetDateFrom, targetDateTo]);
  return { paymeCoordination, setpaymeCoordination };
};

export const usePaymeCoordinationV2 = (orgCode: string, targetDateFrom: string,
  targetDateTo: string, employmentIds: string, functionType: number, categoryAry: Array<Array<string>>, loginStaffCode: string) => {
  const [paymeCoordination, setpaymeCoordination] = useState<Array<PaymeCoordinationDomain>>([]);
  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getPaymeCoordinationV2(companyCode, orgCode, targetDateFrom, targetDateTo, employmentIds, functionType, categoryAry, loginStaffCode)
      .then((response: any) => {
        if (isSubscribed) {
          setpaymeCoordination(response.dataList.map(
            (result: any) => new PaymeCoordinationDomain(result.valueList),
          ));
        }
      });
    return () => { isSubscribed = false; };
  }, [categoryAry, employmentIds, functionType, loginStaffCode, orgCode, targetDateFrom, targetDateTo]);
  return { paymeCoordination, setpaymeCoordination };
};


export const downloadCSV = (targetDateFrom: string, targetDateTo: string,
  orgCode: string, employmentIds: string, orgCodeName: string) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  getCsvOutputPaymeCoordination(
    companyCode,
    orgCode,
    targetDateFrom,
    targetDateTo,
    employmentIds,
    orgCodeName,
  );
};

export const downloadCSVV2 = (targetDateFrom: string, targetDateTo: string,
  orgCode: string, employmentIds: string, orgCodeName: string, functionType: number, categoryAry: Array<Array<string>>, loginStaffCode: string) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  getCsvOutputPaymeCoordinationV2(
    companyCode,
    orgCode,
    targetDateFrom,
    targetDateTo,
    employmentIds,
    orgCodeName,
    functionType,
    categoryAry,
    loginStaffCode,
  );
};


export default usePaymeCoordination;
