import ApiClient from './ApiClient';

export type ShiftCostRate = {
  /** 人件費率 */
  rate: number;
  /** 人件費 */
  laborCost: number;
  /** 売上予算 */
  budget: number;
}

export type ShiftHopeRate = {
  alertCnt: number;
  /** 叶え度 */
  hopeRateStr: string;
  hopeTime: number;
  /** シフト時間表示用 */
  hopeTimeDisp: string;
  hopeTimeHour: number;
  hopeTimeMin: number;
  /** 希望叶え度フラグ(1の場合はアラート) */
  overFlag: number;
  /** スタッフの写真保存パス */
  photoPath: string;
  shiftTime: number;
  /** シフト時間表示用 */
  shiftTimeDisp: string;
  shiftTimeHour: number;
  shiftTimeMin: number;
  staffCode: string;
  staffName: string;
  timeDisplayFormat: number;
}

export const getShiftCostRate = async (
  orgCode: string,
  targetDate:string,
): Promise<ShiftCostRate> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    orgCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/shift/costRate/${companyCode}`, query, undefined);
  return response.data;
};

export const getShiftHopeRate = async (
  orgCode: string,
  targetDate:string,
): Promise<Array<ShiftHopeRate>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    orgCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/shift/hopeRate/${companyCode}`, query, undefined);
  return response.data;
};

export default {};
