export interface AttendNoticeSetting {
  notifyBeforeShiftStart: string,
  minutesBeforeShiftStart: string,
  notifyToStampForgot: string,
}

export interface PersonalApplication {
  companyCode: string,
  staffCode: string,
  staffName: string,
  orgCode: string,
  orgName: string,
  mail: string,
  paymentTarget: Date,
  formatType: string,
  personalRole: string,
  shopRole: string,
  hqRole: string,
  attendNoticeSetting: AttendNoticeSetting;
}

export default class PersonalSettingApplicationDomain {
  constructor(private rawData: PersonalApplication) {
    // do nothing
  }

  static generateInitial(): PersonalSettingApplicationDomain {
    return new PersonalSettingApplicationDomain({
      companyCode: '',
      staffCode: '',
      staffName: '',
      orgCode: '',
      orgName: '',
      mail: '',
      paymentTarget: new Date(),
      formatType: '0',
      personalRole: '',
      shopRole: '',
      hqRole: '',
      attendNoticeSetting: {
        notifyBeforeShiftStart: '',
        minutesBeforeShiftStart: '',
        notifyToStampForgot: '',
      },
    });
  }

  getRawData(): PersonalApplication {
    return this.rawData;
  }

  get attendNoticeSetting(): AttendNoticeSetting {
    return this.rawData.attendNoticeSetting;
  }

  set attendNoticeSetting(attendNoticeSetting: AttendNoticeSetting) {
    this.rawData.attendNoticeSetting = attendNoticeSetting;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName(): string {
    return this.rawData.staffName;
  }

  set staffName(staffName: string) {
    this.rawData.staffName = staffName;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get mail(): string {
    return this.rawData.mail;
  }

  set mail(mail: string) {
    this.rawData.mail = mail;
  }

  get paymentTarget(): Date {
    return this.rawData.paymentTarget;
  }

  set paymentTarget(paymentTarget: Date) {
    this.rawData.paymentTarget = paymentTarget;
  }

  get formatType(): string {
    return this.rawData.formatType;
  }

  set formatType(formatType: string) {
    this.rawData.formatType = formatType;
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = companyCode;
  }

  get personalRole(): string {
    return this.rawData.personalRole;
  }

  set personalRole(personalRole: string) {
    this.rawData.personalRole = personalRole;
  }

  get shopRole(): string {
    return this.rawData.shopRole;
  }

  set shopRole(shopRole: string) {
    this.rawData.shopRole = shopRole;
  }

  get hqRole(): string {
    return this.rawData.shopRole;
  }

  set hqRole(hqRole: string) {
    this.rawData.shopRole = hqRole;
  }
}
