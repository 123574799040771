import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import { css } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import DatePickerForm from 'components/molecules/DatePickerForm';
import OrganizationFilteredTree from 'components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree';
import moment from "moment"
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import PrimaryButton from 'components/atoms/Button';
import useToastNotification from 'hooks/useToastNotification';
import { downloadOriginalPDF } from './hooks';
import { exportFilePDF } from 'api/AttendAchievementList';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const AttendAchievementListPage: React.FC<{ formik: any }> = ({ formik }) => {

    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const history = useHistory();

    const d = new Date();
    const y = d.getFullYear();
    const m = d.getMonth();

    const functionType = 2;
    const [categoryAry, setCategoryAr] = useState<Array<any> | undefined>(
        undefined,
    );

    const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || '');

    const [blocking, setBlocking] = useState(false);

    const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

    const [reportType, setReportType] = useState<Number>(0 | 0);

    const [targetDateFrom, setTargetDateFrom] = useState(moment(new Date(y, m, 1)).format("YYYY-MM-DD"));
    const [targetDateTo, setTargetDateTo] = useState(moment(new Date(y, m + 1, 0)).format("YYYY-MM-DD"));

    const [targetDate, setTargetDate] = useState(new Date());

    const staffAccess = roleScreen && roleScreen.useStaffAccess === true && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

    const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

    useEffect(() => {
      if (checkUserRole && checkUserRole.available === false) {
          history.push('/404')
      }
    }, [checkUserRole]);

    useEffect(() => {
        if(reportType === 0) {
            setTargetDateFrom(moment(targetDate).format("YYYY-MM-DD"));
            setTargetDateTo(moment(targetDate).format("YYYY-MM-DD"));
        }
        if(reportType === 1) {
            setTargetDateFrom(moment(new Date(targetDate.getFullYear(), targetDate.getMonth(), 1)).format("YYYY-MM-DD"));
            setTargetDateTo(moment(new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0)).format("YYYY-MM-DD"));
        }
    }, [reportType]);

    const { errorNotification } = useToastNotification();
    const handleClick = useCallback(
      () => exportFilePDF(orgCode,reportType,targetDateFrom,targetDateTo,functionType, categoryAry, staffCode)
        .then((response: any) => {
          setBlocking(false);
          downloadOriginalPDF(response, `${reportType === 0 ? '日別': '月別'}勤務先人件費一覧_${moment(targetDate).format(reportType === 0 ? 'YYYY年MM年DD日' : 'YYYY年MM月')}.pdf`);
        })
        .catch((error: any) => {
            setBlocking(false);
            if(error.response.status === 404) {
                errorNotification("出力対象となる勤務情報が存在しません。");
            } else {
                errorNotification('サーバー側でエラーが発生しました。');
            }
        }),
      [categoryAry, errorNotification, orgCode, targetDate, staffCode],
    );

    return (
        <>
            <SidebarTemplate pageTitle="勤務先人件費一覧">
                <BlockUI blocking={blocking}>
                    <FormContents>
                        <FlexBox customStyle={css({ paddingBottom: '1rem' })}>
                            <FlexBoxItem width="100px" customStyle={css({ paddingTop: '8px' })}>
                                <FormLabel
                                    label="出力帳票"
                                />
                            </FlexBoxItem>
                            <FlexBoxItem>
                                <RadioSelectForm
                                    label=''
                                    items={
                                        [
                                            {
                                                label: '日別',
                                                value: '0',
                                            },
                                            {
                                                label: '月別',
                                                value: '1',
                                            }
                                        ]
                                    }
                                    name='reportType'
                                    value={String(reportType)}
                                    setValue={e => setReportType(Number(e.target.value))}
                                    labelInline={true}
                                />
                            </FlexBoxItem>
                        </FlexBox>
                        <FlexBox>
                            <FlexBoxItem width="100px">
                                <FormLabel
                                    label={reportType === 0 ? "営業日" : "営業月"}
                                />
                            </FlexBoxItem>
                            <FlexBoxItem>
                                {reportType === 0 &&
                                    <DatePickerForm
                                        dateFormat="yyyy年MM月dd日"
                                        label=""
                                        date={targetDate}
                                        changeDate={(date: Date) => {
                                            if (date !== null) {
                                                setTargetDate(date);
                                                setTargetDateFrom(moment(date).format("YYYY-MM-DD"));
                                                setTargetDateTo(moment(date).format("YYYY-MM-DD"));
                                            }
                                        }}
                                        showMonthYearPicker={false}
                                        isFullWidth={true}
                                        required={false}
                                    />
                                }
                                {reportType === 1 &&
                                    <DatePickerForm
                                        dateFormat="yyyy年MM月"
                                        label=""
                                        date={targetDate}
                                        changeDate={(date: Date) => {
                                            if (date !== null) {
                                                setTargetDate(date);
                                                setTargetDateFrom(moment(new Date(date.getFullYear(), date.getMonth(), 1)).format("YYYY-MM-DD"));
                                                setTargetDateTo(moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format("YYYY-MM-DD"));
                                            }
                                        }}
                                        showMonthYearPicker={true}
                                        isFullWidth={true}
                                        required={false}
                                    />
                                }
                            </FlexBoxItem>
                        </FlexBox>
                        <OrganizationFilteredTree
                            functionType={functionType}
                            staffCode={staffCode}
                            orgLabel="組織名"
                            initOrgValue={String(orgCode)}
                            orgCallback={(args: string | Array<string>) => {
                                setOrgCode(String(args));
                            }}
                            orgCategoryCallback={
                                (args: Array<string>) => {
                                    setCategoryAr(args);
                                }
                            }
                            targetdayForm={new Date(targetDate.getFullYear(), targetDate.getMonth(), 1)}
                            targetdayTo={new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0)}
                            addAllItem={true}
                            isAlignItemsCenter={true}
                            styles={{
                                label: {
                                    toggleSwitch: css({ marginLeft: '0px' }),
                                },
                                toggle: {
                                    width: '19rem',
                                    marginLeft: '0.4rem',
                                },
                            }}
                        />
                        <div style={{ marginTop: '36px' }}>
                            <PrimaryButton ghost={false} text="印刷" onClick={() => { setBlocking(true); handleClick();}} />
                        </div>
                    </FormContents>
                </BlockUI>
            </SidebarTemplate>
        </>
    );
};

export default AttendAchievementListPage;
