import {
  downloadCsv,
  downloadExcel, getListCredit,
  getListDailyReport,
  getListMedia,
} from 'api/dailySeparateReport';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Store} from 'modules/store';
import { getTimeSetting } from 'api/salesSetting';

export const useDailySalesManage = () => {
  const fromdate = new Date();
  const datefrom = new Date(fromdate.getFullYear(), fromdate.getMonth(), 1);
  const dateTo = (new Date(fromdate.getFullYear(), fromdate.getMonth() + 1, 0, 23, 59, 59));
  const [targetDateFrom, setTargetDateFrom] = useState(datefrom);
  const [targetDateTo, setTargetDateTo] = useState(dateTo);
  const functionType = 3;
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode'));
  const [categoryAry, setCategoryAry] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orgName, setorgName] = useState('全ての組織');
  const targetPeriodData = `営業日：${moment(targetDateFrom).format('YYYY/MM/DD')}~${moment(targetDateTo).format('YYYY/MM/DD')}`;
  const [dailyReport, setDailyReport] = useState<Array<any>>([]);
  const [getMediaMst, setMediaMst] = useState<Array<any>>([]);
  const [getCreditMst, setCreditMst] = useState<Array<any>>([]);
  const history = useHistory();
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const [commentSubjectForBudgetProcess , setCommentSubjectForBudgetProcess] = useState('予算進捗について');
  const [commentSubjectForStoreManagement , setCommentSubjectForStoreManagement] = useState('店舗経営について');
  const [commentSubjectForOthers , setCommentSubjectForOthers] = useState('その他');
  // Check prototype role staff
  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  const staffAccess = roleScreen && roleScreen.useStaffAccess === true
   && roleScreen.staffAccessFunctionRoles.filter((role: any) => role.orgCode === orgCode);

  const checkUserRole = orgCode !== 'all' && staffAccess && staffAccess.length > 0 ? staffAccess[0] : roleScreen;

  useEffect(() => {
    if (checkUserRole && checkUserRole.available === false) {
      history.push('/404')
    }
  }, [checkUserRole]);

  useEffect(() => {
    if (orgCode !== 'all') {
      setIsLoading(true);
      const param = {
        orgCode,
        monthlyStartDate: moment(targetDateFrom).format('YYYY/MM/DD'),
        monthlyEndDate: moment(targetDateTo).format('YYYY/MM/DD'),
        loginStaffCode: staffCode,
        functionType,
        categoryAry: categoryAry.length > 0 ? String(categoryAry) : '',
      };
      getListMedia(param).then((response:any) => {
        if (response && response.length > 0) {
          return setMediaMst(response);
        }
        return setMediaMst([]);
      });
      getListCredit(param).then((response:any) => {
        if (response && response.length > 0) {
          return setCreditMst(response);
        }
        return setCreditMst([]);
      });
      getListDailyReport(param).then((response:any) => {
        setIsLoading(false);
        if (response && response.length > 0) {
          setDailyReport(response);
        }
      });
    }
    setDailyReport([]);
  }, [categoryAry, orgCode, staffCode, targetDateFrom, targetDateTo]);

  useEffect(() => {
    getTimeSetting(companyCode).then((response: any) => {
      setCommentSubjectForBudgetProcess(response.commentSubjectForBudgetProcess)
      setCommentSubjectForStoreManagement(response.commentSubjectForStoreManagement)
      setCommentSubjectForOthers(response.commentSubjectForOthers)
    })
  }, [companyCode]);

  const downloadDaySeparateCsv = (param: any) => {
    const response = downloadCsv(param, '売上日別日報').then(() => true);
    return response;
  };

  const downloadDaySeparateExcel = (param: any) => {
    const response = downloadExcel(param, '売上日別日報').then(() => true);
    return response;
  };

  return {
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    functionType,
    staffCode,
    orgCode,
    setOrgCode,
    orgName,
    setorgName,
    categoryAry,
    setCategoryAry,
    isLoading,
    setIsLoading,
    targetPeriodData,
    dailyReport,
    setDailyReport,
    getMediaMst,
    getCreditMst,
    downloadDaySeparateCsv,
    downloadDaySeparateExcel,
    checkUserRole,
    commentSubjectForBudgetProcess,
    commentSubjectForStoreManagement,
    commentSubjectForOthers
  };
};

export default useDailySalesManage;
