
/**
 * API 時間帯マスタ
 */
export interface TimeZone{
    termId: string;
    termName: string;
    targetType:number;
    startTime: string;
    endTime:string;
}
export enum TargetTypeLabel {
    '平日・休日' = 0,
    '平日のみ' = 1,
    '休日のみ'= 2,
  }
export default class TimeZoneDomain {
  constructor(private rawData: TimeZone) {
    //
  }

  static generateInitial(): TimeZoneDomain {
    return new TimeZoneDomain({
      termId: '',
      termName: '',
      targetType: 0,
      startTime: '',
      endTime: '',
    });
  }

  getRowData(): TimeZone {
    return this.rawData;
  }

  get termId(): string {
    return this.rawData.termId;
  }

  set termId(termId: string) {
    this.rawData.termId = termId;
  }

  get termName(): string {
    return this.rawData.termName;
  }

  set termName(termName: string) {
    this.rawData.termName = termName;
  }

  get targetType(): number {
    return this.rawData.targetType;
  }

  set targetType(targetType: number) {
    this.rawData.targetType = targetType;
  }

  get startTime(): string {
    return this.rawData.startTime;
  }

  set startTime(endTime: string) {
    this.rawData.endTime = endTime;
  }

  get endTime(): string {
    return this.rawData.endTime;
  }

  set endTime(endTime: string) {
    this.rawData.endTime = endTime;
  }

  get targetTypeLabel(): String {
    return TargetTypeLabel[this.rawData.targetType];
  }

  get timeFull(): string {
    return `${this.rawData.startTime}~${this.rawData.endTime}`;
  }
}
