/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import Heading from './Heading';

const styles = {
  pageTitle: css({
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
  }),
  childTitle: css({
    marginLeft: '68px !important',
  }),
  actionButton: css({
    marginLeft: 'auto',
  }),
};

const PageTitle: React.FC<{
  title: string;
  isChildTitle?: boolean,
  rightContents?: React.ReactNode;
}> = ({
  children, title, isChildTitle, rightContents,
}) => (
  <section css={styles.pageTitle}>
    <Heading level={2} customStyle={isChildTitle ? styles.childTitle : undefined}>
      {title}
    </Heading>

    <div>
      {children}
    </div>

    <div css={styles.actionButton}>
      {rightContents}
    </div>
  </section>
);

export default PageTitle;
