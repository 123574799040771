
import { getTaxTypeList } from 'api/taxRateCaptureSetting';
import { useState, useEffect } from 'react';
import { OptionType } from 'components/atoms/Select';

/**
 * Get tax type options
 */
export const useTaxTypeOptions = (): Array<OptionType> => {
  const [taxTypeOptions, setTaxTypeOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getTaxTypeList().then((taxTypes) => {
      setTaxTypeOptions(taxTypes.map((taxType) => ({
        value: String(taxType[0]),
        label: taxType[1],
      })));
    });
  }, []);
  return taxTypeOptions;
};
export default useTaxTypeOptions;
