/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import FormLabel from 'components/atoms/Form/FormLabel';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import DatePicker from './DatePicker';

const styles = {
  datePicker: css({
    marginTop: '4px',
    width: '148px',
    zIndex:1000
  }),
  datePickerNotMargin: css({
    width: '148px',
    zIndex:1000
  }),
  wrapper: css({
    width: '100%',
  }),
};

const DatePickerForm: React.FC<{
  label: string;
  date?: any;
  dateFormat?: string;
  required?: boolean;
  isFullWidth?: boolean;
  showMonthYearPicker?: boolean;
  changeDate: (arg: any) => void;
  errorMsg?: string;
  disable? : boolean;
  isShowDateOnDisable?: boolean;
  readOnly?: boolean;
  noDeleteDate?: boolean;
  isShowFullWidthDatePicker?: boolean;
  removeMargin?: boolean;
}> = ({
  label, date, changeDate, dateFormat, isFullWidth = false, showMonthYearPicker = false,
  required, errorMsg, disable, isShowDateOnDisable, readOnly, noDeleteDate, isShowFullWidthDatePicker = false, removeMargin = false
}) => (
  <VerticalLabelFormLayout
    label={(
      <FormLabel
        label={label}
        required={required}
      />
  )}
    input={(
      <div css={isShowFullWidthDatePicker ? styles.wrapper : removeMargin ? styles.datePickerNotMargin : styles.datePicker}>
        <DatePicker
          dateFormat={dateFormat}
          date={date}
          changeDate={changeDate}
          showMonthYearPicker={showMonthYearPicker}
          isFullWidth={isFullWidth}
          disabled={disable}
          isShowDateOnDisable={isShowDateOnDisable}
          readOnly={readOnly}
          noDeleteDate={noDeleteDate}
        />
      </div>
  )}
    errorMsg={errorMsg}
  />
);

export default DatePickerForm;
