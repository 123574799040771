import _ from "lodash";
import { formatNumber } from "utility/formatUtil";

export interface MonthlySalesManagementReport {
    /** 店舗コード */
    orgCode: string;
    /** 店舗名 */
    orgName: string;
    /** 純売上 */
    netSales: number;
    /** 消費税 */
    tax: number,
    /** 総売上 */
    totalSales: number,
    /** 金券販売 */
    cashVoucherSalesTotalAmount:number,
    /** 客数 */
    guestCnt:number,
    /** 客単価 */
    perCustomerPrice: number,
    /** 組数 */
    customers: number,
    /** 組単価 */
    perGroupPrice: number,
    /** 値引・割引券 金額 */
    totalDiscountAmount: number,
    /** 値引・割引券 枚数 */
    totalDiscountCnt: number,
    /** VOID 金額 */
    voidAmount: number,
    /** VOID 件数 */
    voidCnt: number,
    /** 会計中止 金額 */
    stopAccountAmount: number,
    /** 会計中止 件数 */
    stopAccountCnt: number,
    /** メニュー取消 金額 */
    posMenuCancelAmount: number,
    /** メニュー取消 件数 */
    posMenuCancelCnt: number,
    /** 釣銭準備金 */
    changeReserve: number,
    /** 現金在高 */
    cashStock: number,
    /** 現金過不足 */
    excessAndDeficiency: number,
    /** 銀行入金 */
    bankDepositAmount: number,
  }

/** 月次帳票 売上管理表  */
export default class MonthlySalesManagementReportDomain {

  private _orgCode: string;
  private _orgName: string;
  private _netSales: string;
  private _tax: string;
  private _totalSales: string;
  private _cashVoucherSalesTotalAmount: string;
  private _guestCnt: string;
  private _perCustomerPrice: string;
  private _customers: string;
  private _perGroupPrice: string;
  private _totalDiscountAmount: string;
  private _totalDiscountCnt: string;
  private _voidAmount: string;
  private _voidCnt: string;
  private _stopAccountAmount: string;
  private _stopAccountCnt: string;
  private _posMenuCancelAmount: string;
  private _posMenuCancelCnt: string;
  private _changeReserve: string;
  private _cashStock: string;
  private _excessAndDeficiency: string;
  private _bankDepositAmount: string;

  constructor(private rawData: MonthlySalesManagementReport) {
    this._orgCode = this.rawData.orgCode;
    this._orgName = this.rawData.orgName;
    this._netSales = formatNumber(this.rawData.netSales);
    this._tax = formatNumber(this.rawData.tax);
    this._totalSales = formatNumber(this.rawData.totalSales);
    this._cashVoucherSalesTotalAmount = formatNumber(this.rawData.cashVoucherSalesTotalAmount);
    this._guestCnt = formatNumber(this.rawData.guestCnt);
    this._perCustomerPrice = formatNumber(this.rawData.perCustomerPrice);
    this._customers = formatNumber(this.rawData.customers);
    this._perGroupPrice = formatNumber(this.rawData.perGroupPrice);
    this._totalDiscountAmount = formatNumber(this.rawData.totalDiscountAmount);
    this._totalDiscountCnt = formatNumber(this.rawData.totalDiscountCnt);
    this._voidAmount = formatNumber(this.rawData.voidAmount);
    this._voidCnt = formatNumber(this.rawData.voidCnt);
    this._stopAccountAmount = formatNumber(this.rawData.stopAccountAmount);
    this._stopAccountCnt = formatNumber(this.rawData.stopAccountCnt);
    this._posMenuCancelAmount = formatNumber(this.rawData.posMenuCancelAmount);
    this._posMenuCancelCnt = formatNumber(this.rawData.posMenuCancelCnt);
    this._changeReserve = formatNumber(this.rawData.changeReserve);
    this._cashStock = formatNumber(this.rawData.cashStock);
    this._excessAndDeficiency = formatNumber(this.rawData.excessAndDeficiency);
    this._bankDepositAmount = formatNumber(this.rawData.bankDepositAmount);
  }

  static generateInitial(): MonthlySalesManagementReportDomain {
    return new MonthlySalesManagementReportDomain({
      orgCode: '',
      orgName: '',
      netSales: 0,
      tax: 0,
      totalSales: 0,
      cashVoucherSalesTotalAmount: 0,
      guestCnt: 0,
      perCustomerPrice: 0,
      customers: 0,
      perGroupPrice: 0,
      totalDiscountAmount: 0,
      totalDiscountCnt: 0,
      voidAmount: 0,
      voidCnt: 0,
      stopAccountAmount: 0,
      stopAccountCnt: 0,
      posMenuCancelAmount: 0,
      posMenuCancelCnt: 0,
      changeReserve: 0,
      cashStock: 0,
      excessAndDeficiency: 0,
      bankDepositAmount: 0,
    });
  }

  getRawData(): MonthlySalesManagementReport {
    return this.rawData;
  }

  /** 表示データ（画面非表示のプロパティを除外） */
  getDispData(): Partial<this>{
    return _.omit(this, ['rawData']);
  }

  /** 出力データ（CSV出力など） */
  getOutputData(): Array<Partial<this>> {
    return Object.values(this.getDispData());
  }

  get orgCode(): string {
    return this._orgCode;
  }

  get orgName(): string {
    return this._orgName;
  }

  get netSales(): string {
    return this._netSales;
  }

  get tax(): string {
    return this._tax;
  }

  get totalSales(): string {
    return this._totalSales;
  }

  get cashVoucherSalesTotalAmount(): string {
    return this._cashVoucherSalesTotalAmount;
  }

  get guestCnt(): string {
    return this._guestCnt;
  }

  get perCustomerPrice(): string {
    return this._perCustomerPrice;
  }

  get customers(): string {
    return this._customers;
  }

  get perGroupPrice(): string {
    return this._perGroupPrice;
  }

  get totalDiscountAmount(): string {
    return this._totalDiscountAmount;
  }

  get totalDiscountCnt(): string {
    return this._totalDiscountCnt;
  }

  get voidAmount(): string {
    return this._voidAmount;
  }

  get voidCnt(): string {
    return this._voidCnt;
  }

  get stopAccountAmount(): string {
    return this._stopAccountAmount;
  }

  get stopAccountCnt(): string {
    return this._stopAccountCnt;
  }

  get posMenuCancelAmount(): string {
    return this._posMenuCancelAmount;
  }

  get posMenuCancelCnt(): string {
    return this._posMenuCancelCnt;
  }

  get changeReserve(): string {
    return this._changeReserve;
  }

  get cashStock(): string {
    return this._cashStock;
  }

  get excessAndDeficiency(): string {
    return this._excessAndDeficiency;
  }

  get bankDepositAmount(): string {
    return this._bankDepositAmount;
  }
}
