import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { getAttendSettingOrganization, UpdateAttendSettingOrganization } from 'api/timeSetting';
import OrganizationSettingDomain from 'domain/master/labor/organizationSetting';
import useToastNotification from 'hooks/useToastNotification';

export const useSettingUpdateForm = (orgCode: string) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const { successNotification, errorNotification } = useToastNotification();

  const onSubmit = async (val: any) => {
    const OrganizationSetting = val.getRawDataWithoutNullData();

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    // const jsonStringSubmit = JSON.stringify(OrganizationSetting);
    UpdateAttendSettingOrganization(companyCode, orgCode, OrganizationSetting)
      .then((response: any) => {
        setConfirmModalOpen(false);
        successNotification('登録しました。');
      })
      .catch((error: any) => {
        setConfirmModalOpen(false);
        if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
          let msgError = '';
          error.response.data.errors.map((iteam: { defaultMessage: string; }) => {
            msgError += `${iteam.defaultMessage} \n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification('Something wrong. please try again');
        }
      });
  };

  const formik = useFormik({
    initialValues: OrganizationSettingDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    getAttendSettingOrganization(companyCode, orgCode).then((response: any) => {
      // formik.setValues(new TimeSettingDomain(response));
      formik.setValues(new OrganizationSettingDomain(response));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgCode]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal
  };
};


export default useSettingUpdateForm;
