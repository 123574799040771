/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { productColor, grayScale } from 'components/styles';
import Icon, { IconType } from 'components/atoms/Icon';
import ClickableLink from 'components/atoms/ClickableLink';
import BodyText from 'components/atoms/BodyText';
import { HierarchyItem } from './SidebarItem';

const styles = {
  parentInfo: css({
    padding: '12px 8px',
  }),
  parentInfoText: css({
    color: grayScale.gray100,
    fontSize: '16px',
    fontWeight: 'bold',
    marginLeft: '4px',
  }),
};

export interface HierarchySet {
  parentIcon: IconType;
  parentText: string;
  items: Array<HierarchyItem>;
}

const HierarchyMode: React.FC<{
  hierarchy?: HierarchySet;
  setHierarchy: (hierarchySet?: HierarchySet) => void
}> = ({
  hierarchy, setHierarchy,
}) => {
  if (!hierarchy) {
    return null;
  }
  return (
    <React.Fragment>
      <ClickableLink onClick={() => setHierarchy(undefined)}>
        <Icon type="chevronLeft" color={productColor.primary} />
        <BodyText>戻る</BodyText>
      </ClickableLink>

      <div css={styles.parentInfo}>
        <Icon type={hierarchy.parentIcon} color={grayScale.gray100} />
        <BodyText customStyle={styles.parentInfoText}>{hierarchy.parentText}</BodyText>
      </div>

      {hierarchy.items?.map((item) => (
        <ClickableLink to={item.to} key={item.text}>
          {item.text}
        </ClickableLink>
      ))}
    </React.Fragment>
  );
};

export default HierarchyMode;
