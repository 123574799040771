import { useEffect, useState } from 'react';
import * as api from 'api/monthlyReport';

const useGetFiscalYearly = () => {
  const [getValueFiscalYear, setFiscalYear] = useState();
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  useEffect(() => {
    api.getFiscalYear(companyCode).then((response: any) => {
      setFiscalYear(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFiscalYear]);
  return { getValueFiscalYear };
};

export default useGetFiscalYearly;
