import ApiClient from 'api/ApiClient';
import { downloadOriginalPDF } from 'api/personalSetting';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import {
  IMonthlyShift, IDailyShift, IShopPlan, IDrawingInfo, IOrganizationV1, ShiftPattern, IDrawingInfoDaily,
} from './type';
import { SessionStorageKey } from 'utility/constants';

export type ShiftDailyStaff = {
  staffCode: string;
  staffName: string;
  belongOrgCode: string;
  employmentId: string;
  closingHour: number;
  distinctionHoliday: boolean;
}

export const getShiftPatterns = async (employmentId: string, orgCode: string): Promise<ShiftPattern[]> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    employmentId,
  };
  const response = await ApiClient.get(`/v1/shift/patterns/available/${companyCode}`, params, {});

  return response.data;
};

export const fetchDrawingInfo = async (orgCode: string, dateFromStr: string, viewPeriod: string = 'monthly'): Promise<IDrawingInfo> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    dateFromStr,
    viewPeriod,
  };
  const response = await ApiClient.get(`/v1/mws/drawingInfo/${companyCode}`, params, {});

  return response.data;
};

export const fetchDrawingInfoDaily = async (orgCode: string, targetDate: string): Promise<IDrawingInfoDaily> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/dws/drawingInfo/${companyCode}`, params, {});

  return response.data;
};

export const fetchShopPlan = async (targetDateFrom: string, targetDateTo: string, orgCode: string): Promise<IShopPlan[]> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
  };
  const response = await ApiClient.get(`/v1/shopPlan/${companyCode}`, params, {});

  return response.data;
};


export const fetchMonthlyShift = async (
  targetDateFrom: string,
  targetDateTo: string,
  orgCode: string,
  isAttendExists: string = '0',
  employments: string = '',
  addStaffCodeList: any,
  pageIndex: number = 0,
  pageItem: number = 50,
  businessIds?: any,
): Promise<IMonthlyShift> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  let addStaffCodes = '';
  if (Array.isArray(addStaffCodeList)) {
    addStaffCodeList.forEach((staff: any) => {
      addStaffCodes += `&addStaffCodes=${staff}`;
    });
  } else {
    addStaffCodes = '&addStaffCodes=';
  }

  let businesses = '';
  businessIds && businessIds.length > 0 && businessIds.map((item: any, index: number) => {
    if (index < businessIds.length - 1) {
      businesses += item.businessId+',';
    } else {
      businesses += item.businessId;
    }
  })
  const response = await ApiClient.get(`/v5/mws/info/${companyCode}?loginStaffCode=${sessionStorage.getItem(SessionStorageKey.LoginUser.STAFF_CODE)}&orgCode=${orgCode}&targetDateFrom=${targetDateFrom}&targetDateTo=${targetDateTo}&employments=${employments}&businesses=${businesses}&isAttendExists=${isAttendExists}&pageIndex=${pageIndex}&pageItem=${pageItem}${addStaffCodes}`, {});

  return response.data;
};

// export const fetchMonthlyShift = async (
//   targetDateFrom: string,
//   targetDateTo: string,
//   orgCode: string,
//   isAttendExists: string = "0",
//   employments: string = "",
//   addStaffCodes: any = "",
// ): Promise<IMonthlyShift> => {
//   const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
//   let params = {
//     orgCode,
//     targetDateFrom,
//     targetDateTo,
//     addStaffCodes,
//     isAttendExists,
//     employments,
//   };

//   let response = await ApiClient.get(`/v1/mws/info/${companyCode}`, params, {});

//   return response.data;
// };

export const fetchDailyShift = async (
  orgCode: string,
  targetDate: string,
  isAttendExists: string = '0',
  employments: string = '',
  addStaffCodeList: any,
  cumulativeClosingDate: string = '0',
  businessIds?: any,
): Promise<IDailyShift> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  let addStaffCodes = '';
  if (Array.isArray(addStaffCodeList)) {
    addStaffCodeList.forEach((staff: any) => {
      addStaffCodes += `&addStaffCodes=${staff}`;
    });
  } else {
    addStaffCodes = '&addStaffCodes=';
  }
  let businesses = '';
  businessIds && businessIds.length > 0 && businessIds.map((item: any, index: number) => {
    if (index < businessIds.length - 1) {
      businesses += item.businessId+',';
    } else {
      businesses += item.businessId;
    }
  })
  const response = await ApiClient.get(`/v4/dws/info/${companyCode}?loginStaffCode=${sessionStorage.getItem(SessionStorageKey.LoginUser.STAFF_CODE)}&orgCode=${orgCode}&targetDate=${targetDate}&employments=${employments}&cumulativeClosingDate=${cumulativeClosingDate}&businesses=${businesses}&isAttendExists=${isAttendExists}${addStaffCodes}`, {});

  return response.data;
};


export const downloadMonthlyShiftPdf = async (
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  employments: string = '',
  isAttendExists: string = '1',
  useSales: boolean = true,
  filename: string = 'monthly_work_schedule',
) => {
  const orgName = orgCode;
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const params = {
    orgCode,
    orgName,
    targetDateFrom,
    targetDateTo,
    employments,
    useSales,
    isAttendExists,
  };

  const response = await ApiClient.getDownloadFile(`/v1/mws/pdf/${companyCode}`, params, {});

  downloadOriginalPDF(response.data, `${filename}.pdf`);
};

export const downloadMonthlyShiftExcel = async (
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  employments: string = '',
  isAttendExists: string = '1',
  filename: string = '',
) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  filename = `${orgCode}_${moment(targetDateFrom).format('YYYYMMDD')}-${moment(targetDateTo).format('YYYYMMDD')}`;

  const params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
    employments,
    isAttendExists,
  };

  const response = await ApiClient.getDownloadFile(`/v1/mws/export/excel/${companyCode}`, params, {});

  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
  });
  saveAs(blob, filename);
};
/**
 * シフトインポート（Excel)
 */
export const importFileExcel = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    staffCode,
    staffName,
  };
  const response = await ApiClient.postFile(`/v1/mws/import/excel/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};
export const downloadDailyShiftPdf = async (
  orgCode: string,
  targetDate: string,
  employments: string = '',
  isAttendExists: string = '1',
  useSales: boolean = true,
  filename: string = 'daily_work_schedule',
) => {
  // const orgCode = sessionStorage.getItem("loginUser.orgCode") || "";
  const orgName = orgCode;
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const params = {
    orgCode,
    orgName,
    targetDate,
    employments,
    useSales,
    isAttendExists,
  };

  const response = await ApiClient.getDownloadFile(`/v1/dws/pdf/${companyCode}`, params, {});

  downloadOriginalPDF(response.data, `${filename}.pdf`);
};
/**
 * 丸め処理済シフトパターン取得
 * @param orgCode
 */
export const getRoundShiftPatterns = async (
  orgCode: string, employmentId: string, targetDate: string): Promise<ShiftPattern[]> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    employmentId,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/shift/patterns/available/round/${companyCode}`, params, {});

  return response.data;
};

export const getDailyAddStaffs = async (
  orgCode: string,
  targetOrgCode: string,
  targetDay: string,
): Promise<Array<ShiftDailyStaff>> => {
  const params = {
    orgCode,
    targetOrgCode,
    targetDay,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/shift/daily/add/staffs/${companyCode}`, params, undefined);
  return response.data;
};
