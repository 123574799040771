
export interface Advertising {
  orgName: string,
  orgCode: string,
  advertisingPlanMstCode: string,
  advertisingPlanMstName: string,
  applyStartDay: string,
  applyEndDay:string
}


export default class AdvertisingPlanDomain {
  constructor(private rawData: any) {
    this.rawData = {
      ...rawData,
      orgName: rawData.orgName,
      orgCode: rawData.orgCode,
      advertisingPlanMstCode: rawData.advertisingPlanMstCode,
      advertisingPlanMstName: rawData.advertisingPlanMstName,
      applyStartDay: rawData.applyStartDay,
      applyEndDay: rawData.applyEndDay,
    };
  }

  static generateInitial(): AdvertisingPlanDomain {
    return new AdvertisingPlanDomain({
      orgName: '',
      orgCode: '',
      advertisingPlanMstCode: '',
      advertisingPlanMstName: '',
      applyStartDay: '',
      applyEndDay: '',
    });
  }

  getRawData(): Advertising {
    return this.rawData;
  }

  getRawDataPost(): any {
    return {
      orgName: this.rawData.orgName,
      orgCode: this.rawData.orgCode,
      advertisingPlanMstCode: this.rawData.advertisingPlanMstCode,
      advertisingPlanMstName: this.rawData.advertisingPlanMstName,
      applyStartDay: this.rawData.applyStartDay,
      applyEndDay: this.rawData.applyEndDay,
      isCreate: true,
    };
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgName = orgCode;
  }

  get advertisingPlanMstCode(): string {
    return this.rawData.advertisingPlanMstCode !== 'undefined' ? this.rawData.advertisingPlanMstCode : '';
  }

  set advertisingPlanMstCode(advertisingPlanMstCode: string) {
    this.rawData.advertisingPlanMstCode = advertisingPlanMstCode;
  }

  get advertisingPlanMstName(): string {
    return this.rawData.advertisingPlanMstName !== 'undefined' ? this.rawData.advertisingPlanMstName : '';
  }

  set advertisingPlanMstName(advertisingPlanMstName: string) {
    this.rawData.advertisingPlanMstName = advertisingPlanMstName;
  }

  get applyStartDay(): string {
    return this.rawData.applyStartDay;
  }

  set applyStartDay(applyStartDay: string) {
    this.rawData.applyStartDay = applyStartDay;
  }

  get applyEndDay(): string {
    return this.rawData.applyEndDay;
  }

  set applyEndDay(applyEndDay: string) {
    this.rawData.applyEndDay = applyEndDay;
  }
}
