/* eslint-disable no-nested-ternary */
import { getSalesBreakDown, updateSalesSlipList } from 'api/salesSlip';
import useToastNotification from 'hooks/useToastNotification';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { getListOrganizationAdvertising } from 'api/salesSlip';
import _ from 'lodash';

interface salesBreak {
  orgCode: string,
  targetDay: string,
  totalSales: number,
  advertisingSales: number,
  otherSales: number,
  balanceDue: number,
}

interface ISaleSlipColumnType {
  accountTime: string
  advertisingPlanMstCode: string
  advertisingMediaCode: string
  othersPlanMstCode: string
  organizationAdvertisingNameNo: string
  fractionalDiscount: number
  planMstCode: string
  guestCnt: number
  itemNum: number
  netSales: number
  orderTime: string
  orgCode: string
  personInChargeNo: string
  receiptFlg: boolean
  segment: number
  segmentType: number
  posType: number
  slipNo: string
  tableNo: string
  targetDay: string
  tax: number
  totalDiscount: number
  totalSales: number,
  voidFlg: boolean
  voidTime: string
  bdpFlag: boolean
  inboundFlag: boolean
  repeaterFlag: boolean
  lunchFlag: boolean
}

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const numberFormater = (num: any) => {
  if (typeof num === 'undefined' || isNaN(num)) {
    return num;
  }
  const tmp = String(num).split('.');
  if (tmp[1]) {
    let fixed = 3;
    if (tmp[1].length < 3) {
      fixed = tmp[1].length;
    }
    return parseFloat(num).toFixed(fixed);
  }
  return formatter.format(num);
};

export const useStaffEdit = (selectTab?:any) => {
  const initState = {
    accountTime: '-',
    advertisingPlanMstCode: '',
    advertisingMediaCode: '',
    othersPlanMstCode: '',
    organizationAdvertisingNameNo: '',
    fractionalDiscount: 0,
    planMstCode: '',
    guestCnt: 0,
    itemNum: 0,
    netSales: 0,
    orderTime: '-',
    orgCode: '-',
    personInChargeNo: '-',
    receiptFlg: false,
    segment: 0,
    segmentType: 0,
    posType: 0,
    slipNo: '-',
    tableNo: '-',
    targetDay: '-',
    tax: 0,
    totalDiscount: 0,
    totalSales: 0,
    voidFlg: false,
    voidTime: '-',
    bdpFlag: false,
    inboundFlag: false,
    repeaterFlag: false,
    lunchFlag: false,
  };
  const functionType = 3;
  const [salesSlip, setSalesSlip] = useState<ISaleSlipColumnType[]>([initState]);
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [orgApplyDate, setOrgApplyDate] = useState<Date>(new Date());
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const loginStaffName = sessionStorage.getItem('loginUser.staffName') || '';
  const [organization, setOrganization] = useState(sessionStorage.getItem('loginUser.orgCode') || '');
  const [confirmSale, setConfirmSale] = useState(false);
  const [organizationMedia, setOrganizationMedia] = useState<Array<any>>([]);

  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [categoryArray, setCategoryAr] = useState<Array<any> | []>(
    [],
  );
  const { successNotification, errorNotification } = useToastNotification();
  const closeConfirmSaleDetail = useCallback(() => {
    setConfirmSale(false);
  }, []);
  const [salesBreak, setSalesBreak] = useState<salesBreak>({
    orgCode: '',
    targetDay: '',
    totalSales: 0,
    advertisingSales: 0,
    otherSales: 0,
    balanceDue: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const getApiSaleBreakDown = () => {
    const formBreak = {
      orgCode: organization,
      targetDay: moment(orgApplyDate).format('YYYY-MM-DD'),
      functionType,
      // categoryAry: categoryArray?.length > 0 ? String(categoryArray) : '',
      loginStaffCode,
    };
    getSalesBreakDown(formBreak)
      .then((res: any) => {
        setSalesBreak({
          orgCode: res[0].orgCode,
          targetDay: res[0].targetDay,
          totalSales: res[0].totalSales,
          advertisingSales: res[0].advertisingSales,
          otherSales: res[0].otherSales,
          balanceDue: res[0].balanceDue,
        });
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        errorNotification('サーバー側でエラーが発生しました。');
      });
  };
  useEffect(() => {
    setIsLoading(true);
    getApiSaleBreakDown();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryArray,
    loginStaffCode,
    orgApplyDate,
    organization,
    selectTab,
    staffCode]);

    useEffect(() => {
      const query = {
        orgCode: organization,
        targetDay: moment(orgApplyDate).format('YYYY-MM-DD'),
        functionType,
        categoryAry: categoryArray,
        loginStaffCode,
      };

      getListOrganizationAdvertising(query).then((res: any) => {
        if (res.length > 0) {
          const newList = res.map((value: any) => {
            const ogList = value.organizationAdvertisingNameDataList;
            return ogList.map((data: any) => ({
              advertisingName: data.advertisingName,
              organizationAdvertisingNameNo: data.organizationAdvertisingNameNo,
            }));
          });
          const fn = _.spread(_.union);
          const result = fn(newList);
          setOrganizationMedia(result);
        }
      });

    }, [categoryArray, functionType, loginStaffCode, orgApplyDate, organization]);
  // Call api update Sales Slip
  const handleUpdateSalesSlip = useCallback(() => {
    setConfirmSale(false);
    setIsLoading(true);
    const formSubmit = salesSlip.map((value) => ({
      orgCode: value.orgCode,
      slipNo: value.slipNo,
      personInChargeNo: value.personInChargeNo,
      tableNo: value.tableNo,
      orderTime: moment(value.orderTime).format('YYYY-MM-DD'),
      accountTime: moment(value.accountTime).format('YYYY-MM-DD'),
      guestCnt: value.guestCnt,
      itemNum: value.itemNum,
      organizationAdvertisingNameNo: value.organizationAdvertisingNameNo ? value.organizationAdvertisingNameNo : organizationMedia &&  organizationMedia.length > 0 ? organizationMedia[0].organizationAdvertisingNameNo : '',
      advertisingMediaMstCode: value.advertisingMediaCode,
      totalSales: value.totalSales,
      netSales: value.netSales,
      tax: value.tax,
      totalDiscount: value.totalDiscount,
      fractionalDiscount: value.fractionalDiscount,
      planMstCode: value.planMstCode !== null ? value.planMstCode : '',
      receiptFlg: value.receiptFlg,
      targetDay: moment(value.targetDay).format('YYYY-MM-DD'),
      segment: value.segment,
      segmentType: value.segmentType,
      posType: value.posType,
      bdpFlg: value.bdpFlag,
      ibFlg: value.inboundFlag,
      repeaterFlg: value.repeaterFlag,
      lunchFlg: value.lunchFlag,
      createUser: loginStaffName,
      updateUser: loginStaffName,
    }));
    updateSalesSlipList(formSubmit)
      .then((res: any) => {
        const segmentType = selectTab === 'tab_advertising_table' ? 1 : 2;
        if (segmentType === 1 || segmentType === 2) {
          const newList = salesSlip.filter((item) => item.segmentType === segmentType);
          setSalesSlip(newList);
        }
        getApiSaleBreakDown();
        successNotification('更新しました。');
      })
      .catch((error: any) => {
        setIsLoading(false);
        errorNotification('サーバー側でエラーが発生しました。');
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorNotification, loginStaffName, salesSlip, selectTab, successNotification]);
  return {
    orgApplyDate,
    setOrgApplyDate,
    functionType,
    organization,
    setOrganization,
    confirmSale,
    setConfirmSale,
    staffCode,
    categoryArray,
    setCategoryAr,
    salesBreak,
    loginStaffCode,
    isLoading,
    setIsLoading,
    salesSlip,
    setSalesSlip,
    initState,
    closeConfirmSaleDetail,
    handleUpdateSalesSlip,
    numberFormater,
    isDisable,
    setIsDisable,
    organizationMedia
  };
};

export default useStaffEdit;
