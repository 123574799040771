import React from 'react';
import Icon from 'components/atoms/Icon';
import {
  grayScale,
} from 'components/styles';

function YearInput({
  value, openPanel, selected, clear, onChange, disabled,
}) {
  const selectedClass = selected ? 'imput-wrapper-selected' : '';

  return (
    <div className={`input-wrapper ${selectedClass}`}>
      <div>
        <Icon type="calendar" color={grayScale.gray100} />
      </div>
      <input
        onChange={onChange}
        className="year-input"
        value={value}
        onClick={openPanel}
        readOnly={true}
        disabled={disabled}
      />
    </div>
  );
}

export default YearInput;
