/** @jsx jsx */
import React from 'react';

import FormLabel from 'components/atoms/Form/FormLabel';
import TextInput from 'components/atoms/Form/TextInput';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import { css, jsx, SerializedStyles } from '@emotion/core';
import productColor, { grayScale, textFontSize } from 'components/styles';
import FlexBox from 'components/atoms/FlexBox';
import IconTooltip from './IconTooltip';

const styles = {
  wrapper: css({
    display: 'flex',
  }),
  input: css({
    flex: 1,
    width: '32px',
    padding: '7px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: 'none',
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px 0 0 4px',
  }),
  addon: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '7px',
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
  }),
};

const TextForm: React.FC<{
  name: string;
  label: string;
  value: string;
  note?: string;
  type?: string;
  placeHolder?: string;
  groupText?: string;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  errorMsg?: string;
  disable? : boolean;
  min?: string;
  max?: string;
  maxLength?: number,
  appendLabel?: string;
  toolTip? : string;
  customStyleLabel?: SerializedStyles;
  autoComplete?: boolean
}> = ({
  name, label, type, value, onChange, onBlur, errorMsg, note, placeHolder, groupText,
  required, disable, min, max, appendLabel, toolTip, maxLength, customStyleLabel, autoComplete = true
}) => (
  <VerticalLabelFormLayout
    label={(
      <FlexBox>
        <FormLabel
          label={label}
          required={required}
          appendLabel={appendLabel}
          customStyle={customStyleLabel}
        />
        {toolTip && (
        <IconTooltip
          iconType="help"
          text={toolTip}
          color="#000"
        />
        )}
      </FlexBox>
      )}
    input={(
      <div css={styles.wrapper}>
        <TextInput
          min={min}
          max={max}
          maxLength={maxLength}
          name={name}
          label={label}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeHolder={placeHolder}
          type={type}
          disable={disable}
          customStyle={disable ? css({ backgroundColor: grayScale.gray05 }) : css({})}
          autoComplete={autoComplete}
        />


        { groupText && (
        <div css={styles.addon}>
          {' '}
          {groupText}
        </div>
        )}
      </div>
      )}
    errorMsg={errorMsg}
  />
);

export default TextForm;
