import DataTableSort from 'components/organismos/DataTableSort/DataTableSort';
import React, { useMemo } from 'react';
import { ResDataType, HeaderType } from './hooks';
import FlexBox from 'components/atoms/FlexBox';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { useDowload,numberIndicationAnalysisPattern } from './hooks';
const NumberIndicationAnalysisTable: React.FC<{
    numberIndicationAnalysisData: Array<numberIndicationAnalysisPattern>,
    setPageList: any,
    targetPeriodData: any,
    targetStoresData: string,
    roleScreen: any,
}> = ({ numberIndicationAnalysisData, setPageList, targetPeriodData, targetStoresData, roleScreen }) => {
    const { downloadNumberIndicationAnalysisCsv, downloadNumberIndicationAnalysisExcel } = useDowload();
    const headerList: Array<HeaderType> = [{
        Header: "",
        accessor: "org",
        sortType: 'basic',
    },
    {
        Header: "1日以内",
        accessor: "oneDay",
    },
    {
        Header: "2日以内",
        accessor: "twoDay",
    },
    {
        Header: "7日以内",
        accessor: "sevenDay",
    },
    {
        Header: "8日以上",
        accessor: "eightDay",
    },
    {
        Header: "未解決",
        accessor: "unconfirm",
    },
    {
        Header: "合計件数",
        accessor: "total",
    }];
    const headerInformation = [headerList.map((item) => item.Header)];
    const searchResult = useMemo(() => {
        if (numberIndicationAnalysisData)
            return numberIndicationAnalysisData.map((item) => {
                const { org, oneDay, total, unconfirm, eightDay, sevenDay, twoDay } = item;
                return [org, String(oneDay), String(twoDay), String(sevenDay), String(eightDay), String(unconfirm), String(total)]
            })
        else return []
    }, [numberIndicationAnalysisData]);
    // const targetStoresData = `対象店舗：${orgLabel}`;
    const dataCSV = {
        footer: '',
        formName: '指摘件数分析帳票',
        targetPeriod: targetPeriodData,
        targetStores: targetStoresData,
        headerInformation,
        searchResult,
        totalInformation: [],
    };
    console.log(numberIndicationAnalysisData);
    let mergedRegions: any[] = [];
    const dataExcel = {
        formName: '指摘件数分析帳票',
        targetPeriod: targetPeriodData,
        targetStores: targetStoresData,
        headerInformation,
        searchResult,
        totalInformation: [],
        mergedRegions,
        codeColumnIndex: [0],
    };

    const handleExportCSV = () => downloadNumberIndicationAnalysisCsv(dataCSV);
    //  ExportExcel 
    const handleExportExcel = () => downloadNumberIndicationAnalysisExcel(dataExcel);;
    return (
        <div
        // style={{ marginTop: '30px' }}
        style={{zIndex:1,position:'relative'}}
        >
            {
                roleScreen && roleScreen.downloadFlag === 1 && (
                <FlexBox>
                    <FlexBoxItem>
                        <IconLabelButton
                            onClick={handleExportCSV}
                            iconType="download"
                            text=" CSV出力"
                        />
                    </FlexBoxItem>
                    <FlexBoxItem>
                        <IconLabelButton
                            onClick={handleExportExcel}
                            iconType="download"
                            text=" EXCEL出力"
                        />
                    </FlexBoxItem>
                </FlexBox>
            )}
            <div
                style={{ marginTop: '15px' }}
                className="tableNumberIndicationAnalysis"
            >
                <DataTableSort
                    columns={headerList}
                    data={numberIndicationAnalysisData}
                    // setPageList={setPageList}
                    isBorder={true}
                    useSetPageList={false}
                    sortBy={[
                        { id: 'org', desc: false }
                    ]}
                />
            </div>

        </div>
    );
}

export default NumberIndicationAnalysisTable;
