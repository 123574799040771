import { actionTypes } from './actionTypes';
import {SaleBudgetYearType} from './type'

export const fetch = (response: SaleBudgetYearType)=> {
    return {type: actionTypes.FETCH, response};
}

export const get = (response:SaleBudgetYearType)=> {
    return {type: actionTypes.GET, response};
}

export const input_change = (response:any) =>{
    console.log(response,'check response')
    return {type: actionTypes.INPUT_CHANGE, response}
}

export const enable_month_link = (response: SaleBudgetYearType) =>{
    return {type: actionTypes.ENABLE_MONTH_LINK, response}
}

export type Actions =
| ReturnType<typeof fetch>
| ReturnType<typeof get>
| ReturnType<typeof input_change>
| ReturnType<typeof enable_month_link>
