/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { productColor } from 'components/styles';
import MainContents from 'components/atoms/MainContents';
import SidebarMustHead from 'components/organismos/Sidebar/SidebarMustHead';
// import { setIsOpen } from 'modules/ui/sidebar/actions';
import { isMobileOnly } from 'react-device-detect';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { handleHelpUrlForScreen } from 'api/login';
import Separater from 'components/atoms/Separator';
import { SidebarItemType } from '.';
import SidebarItem from './SidebarItem';
import HierarchyMode, { HierarchySet } from './HierarchyMode';

const styles = {
  container: css({
    display: 'flex',
    height: '100vh',
  }),
  sidebar: (isOpen: boolean) => css({
    top: 0,
    position: 'sticky',
    backgroundColor: productColor.primaryM95,
    borderRight: `1px solid ${productColor.primaryM90}`,
    minWidth: isOpen ? '210px' : 0,
  }),
  main: css({
    flex: '1',
    overflow: 'scroll',
    backgroundColor: '#F5F6F7',
  }),
  displayNone: css({
    display: 'none',
  }),
};


const Sidebar: React.FC<{
  isOpen: boolean;
  items: Array<SidebarItemType>;
  hierarchy?: HierarchySet;
  setHierarchy: (hierarchySet?: HierarchySet) => void;
}> = ({
  children, isOpen, items, hierarchy, setHierarchy,
}) => (

  <div css={styles.container}>
    <aside css={css(styles.sidebar(isOpen), (isMobileOnly && isOpen) ? styles.displayNone : {})}>
      <SidebarMustHead />
      {
            hierarchy ? (
              <HierarchyMode
                hierarchy={hierarchy}
                setHierarchy={setHierarchy}
              />
            ) : items.map((item, i) => {
              const key = `sidebar-item-${i}`;
              return (
                <SidebarItem
                  key={key}
                  isOpen={isOpen}
                  item={item}
                  setHierarchy={setHierarchy}
                />
              );
            })
          }
      <Separater margin="8px 12px" />
      <div style={{ padding: '2px 0px' }}>
        <IconLabelButton
          iconType="help"
          text={isOpen ? 'ヘルプ' : ''}
          onClick={() => handleHelpUrlForScreen(String(sessionStorage.getItem('PageTitleCurrent')))}
          customStyle={css({ color: productColor.primary })}
        />
      </div>
    </aside>
    <MainContents additionalCss={styles.main}>
      {children}
    </MainContents>
  </div>
);

export default Sidebar;
