import React from 'react';

import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import MultipleCheckboxForm from 'components/molecules/MultipleCheckboxForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import DateTimeSelectForm from 'components/molecules/DateTimeSelectForm';
import MonthDateSelectForm from 'components/molecules/MonthDateSelectForm';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { useParams } from 'react-router-dom';
import { useSpecialSalaryPatternAddForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const SpecialSalaryPatternInputForm:React.FC = () => {
  const {
    specialSalaryPatternId,
  } = useParams();
  const {
    formik, confirmModalOpen, closeConfirmModal, handleBack,
    confirmModalContent,
    confirmModalSubmitText,
  } = useSpecialSalaryPatternAddForm();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <>
      <p>
        特賃日を登録します。コードとパターン名を入力して、曜日または日付を選択してください。
      </p>

      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormField>
            <TextForm
              name="specialSalaryPatternCode"
              label="パターンコード"
              value={String(formik.values.specialSalaryPatternCode)}
              onChange={formik.handleChange}
              errorMsg={formik.errors.specialSalaryPatternCode}
              required={true}
            />
          </FormField>

          <FormField>
            <TextForm
              name="specialSalaryPatternName"
              label="パターン名"
              value={String(formik.values.specialSalaryPatternName)}
              onChange={formik.handleChange}
              errorMsg={formik.errors.specialSalaryPatternName}
              required={true}
            />
          </FormField>

          <FormField>
            <MultipleCheckboxForm
              name="category"
              label="指定された曜日を特賃日に設定"
              items={[
                {
                  id: 'holidayFlag',
                  label: '祝日',
                  value: formik.values.holidayFlag,
                },
                {
                  id: 'saturdayFlag',
                  label: '土曜日',
                  value: formik.values.saturdayFlag,
                },
                {
                  id: 'sundayFlag',
                  label: '日曜日',
                  value: formik.values.sundayFlag,
                },
                {
                  id: 'mondayFlag',
                  label: '月曜日',
                  value: formik.values.mondayFlag,
                },
                {
                  id: 'tuesdayFlag',
                  label: '火曜日',
                  value: formik.values.tuesdayFlag,
                },
                {
                  id: 'wednesdayFlag',
                  label: '水曜日',
                  value: formik.values.wednesdayFlag,
                },
                {
                  id: 'thursdayFlag',
                  label: '木曜日',
                  value: formik.values.thursdayFlag,
                },
                {
                  id: 'fridayFlag',
                  label: '金曜日',
                  value: formik.values.fridayFlag,
                },
              ]}
              setValue={(e) => formik.setFieldValue(String(e.target.id), !(e.target.value === 'true'))}
            />
          </FormField>

          <MonthDateSelectForm
            label="指定された日付を毎年の特賃日に設定"
            name="sameDays"
            dateFormat="MM/DD"
            labelFormat="MM月DD日"
            setValue={(items: any) => formik.setFieldValue('sameDays', items)}
            isMulti={true}
            values={formik.values.sameDays}
          />

          <DateTimeSelectForm
            label="指定された日付を特賃日に設定"
            name="fixedDays"
            dateFormat="yyyy/MM/dd"
            labelFormat="YYYY年MM月DD日"
            valueFormat="YYYY/MM/DD"
            setValue={(items: any) => formik.setFieldValue('fixedDays', items)}
            isMulti={true}
            values={formik.values.fixedDays}
          />

          <FormSubmitArea>
            {
              roleScreen && roleScreen.editable === 1 && (
                <div style={{ marginRight: '12px' }}>
                  <Button
                    text={specialSalaryPatternId ? '更新' : '特賃日を登録'}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  />
                </div>
              )
            }
            <Button
              text="戻る"
              ghost={true}
              onClick={handleBack}
            />
          </FormSubmitArea>
        </div>

        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          title="確認メッセージ"
          content={confirmModalContent}
          submitText={confirmModalSubmitText}
        />
      </form>
    </>
  );
};

export default SpecialSalaryPatternInputForm;
