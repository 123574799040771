/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { Column } from 'react-table';
import { css, jsx } from '@emotion/core';
import { iconColor } from 'components/styles';
import DataTable from 'components/organismos/DataTable/DataTable';
import Icon from 'components/atoms/Icon';
import AuthorityDomain from './type';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import ClickableLink from 'components/atoms/ClickableLink';
import ButtonNavigation from './ButtonNavigation';
import BlockUI from 'components/molecules/BlockUi';
import { useDownload, useDelete, useAuthorityMasterList } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const AuthorityMasterLayout: React.FC = () => {
  const {
    blocking,
    authorityMasterList,
    fetchData
  } = useAuthorityMasterList();

  const { downloadExcel } = useDownload();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen);

  const data = {
    footer: '',
    formName: 'Role Master',
    targetPeriod: '',
    targetStores: '',
    headerInformation: ['権限コード', '	権限名', '権限レベル', '人数'],
    searchResult: authorityMasterList,
    totalInformation: [],
  };

  const {
    setDeleteTarget,
    isOpenDeleteModal,
    closeDeleteModal, onSubmitDelete,
  } = useDelete(fetchData);

  const columns: Array<Column<AuthorityDomain>> = React.useMemo(() => [
    {
      Header: '権限コード',
      accessor: 'roleCode',
      sortType: 'basic'
    },
    {
      Header: '権限名',
      accessor: 'roleName',
    },
    {
      Header: '権限レベル',
      accessor: 'accessLevel',
    },
    {
      Header: '人数',
      accessor: 'users',
    },
    {
      Header: () => null,
      accessor: 'staffCode',
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row: { isExpanded: boolean, original: any } }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        const [isHoverCopy, setIsHoverCopy] = useState(false);
        return (
          <FlexBox customStyle={css({ height: '36px' })}>
            {cell.row.original.roleName !== 'システム管理者' && (
              <React.Fragment>
                <FlexBoxItem marginLeft="auto">
                  <ClickableLink to={`/roleMaster/edit/${cell.row.original.roleId}`}>
                    <span
                      style={{ marginLeft: '20px' }}
                      onMouseEnter={() => setIsHoverEdit(true)}
                      onMouseLeave={() => setIsHoverEdit(false)}
                    >
                      <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
                    </span>
                  </ClickableLink>
                </FlexBoxItem>
                {
                  roleScreen && roleScreen.editable === 1 && (
                    <React.Fragment>
                      <FlexBoxItem>
                        <ClickableLink to={`/roleMaster/copy/${cell.row.original.roleId}`}>
                          <span
                            onMouseEnter={() => setIsHoverCopy(true)}
                            onMouseLeave={() => setIsHoverCopy(false)}
                          >
                            <Icon type="copy" color={isHoverCopy ? iconColor.grayhover : iconColor.gray} />
                          </span>
                        </ClickableLink>
                      </FlexBoxItem>
                      <FlexBoxItem marginRight="20px">
                        <ClickableIconButton
                          onClick={() => { setDeleteTarget(cell.row.original) }}
                        >
                          <span
                            onMouseEnter={() => setIsHoverDelete(true)}
                            onMouseLeave={() => setIsHoverDelete(false)}
                          >
                            <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                          </span>
                        </ClickableIconButton>
                      </FlexBoxItem>
                    </React.Fragment>
                  )
                }
              </React.Fragment>
            )}
          </FlexBox>
        );
      },
    },
  ], [roleScreen]);

  return (
    <BlockUI blocking={blocking}>
      
      <ButtonNavigation
        downloadExcel={downloadExcel}
        roleScreen={roleScreen}
      />

      <div
        css={css`
      table th:nth-child(1) {
        width: 100px;
      }
      table th:nth-child(2) {
        width: 150px;
      }
      table th:nth-child(3) {
        width: 100px;
      }
      table th:nth-child(4) {
        width: 150px;
      }
      table th:nth-child(5) {
        width: 80px;
      }
      `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
          <DataTable 
          columns={columns} 
          data={authorityMasterList} 
          isGlobalFilter={true} 
          minWidth="1202px" 
          sortBy={[
            { id: 'roleCode', desc: false },
          ]}
          />
        </div>
      </div>

      <ConfirmActionModal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        onSubmit={onSubmitDelete}
        actionType={ActionType.DELETE}
      />
    </BlockUI>
  );
};

export default AuthorityMasterLayout;
