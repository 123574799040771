/** @jsx jsx */
import React from 'react';
import { Link } from 'react-router-dom';
import { css, jsx } from '@emotion/core';

import Icon, { IconType } from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';

const styles = {
  link: css({
    minWidth: '140px',
    margin: '6px 8px',
    textDecoration: 'none',
  }),
  label: css({
    marginLeft: '4px',
  }),
};

export interface IconLinkProps {
  href: string;
  iconType: IconType;
  text: string;
  color: string;
}

/**
 * アイコンつきのリンク
 */
const IconLink: React.FC<IconLinkProps> = ({
  href, iconType, text, color,
}) => (
  <Link to={href} css={styles.link}>
    <Icon type={iconType} color={color} />
    <BodyText customStyle={styles.label}>{text}</BodyText>
  </Link>
);

export default IconLink;
