/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { productColor, grayScale, textFontSize } from 'components/styles';

const styles = {
  wrapper: css({
    backgroundColor: 'white',
    border: `1px solid ${grayScale.gray20}`,
    borderRadius: '3px',
    overflowX: 'auto',
    overflowY: 'hidden',
  }),
  tabWrapper: css({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    alignItems: 'center',
    height: '48px',

  }),
  label: css({
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    placeContent: 'center',
    fontSize: textFontSize.re,
  }),
  selected: css({
    marginBottom: '2px',
    borderBottom: `3px solid ${productColor.primary}`,
    color: productColor.primary,
    cursor: 'pointer',
  }),
  notSelected: css({
    color: grayScale.gray60,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: productColor.primaryM95,
      // borderBottom: `3px solid ${productColor.primary}`,
    },
  }),
  radio: css({
    display: 'none',
  }),
};

export interface TablItem {
  value: string;
  display?: string;
}

const TabBar: React.FC<{
  items: Array<TablItem>;
  setValue: (value: string) => void;
  currentValue: string;
  isFixed?: boolean;
}> = ({
  items, setValue, currentValue, isFixed = false,
}) => {
    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //   setValue(e.target.value);
    // };
    return (
      <div css={styles.wrapper}>
        <div css={isFixed ? css(styles.tabWrapper, { width: 125 * items.length }) : styles.tabWrapper}>
          {items.map((item) => item.display !== '' && (
            <React.Fragment key={item.value}>
              <label
                htmlFor={item.value}
                css={css(styles.label, currentValue === 'dailyShift' || currentValue === 'monthlyShift' 
                ? (currentValue === item.value.split('/')[0] ? styles.selected : styles.notSelected ) 
                : (currentValue === item.value ? styles.selected : styles.notSelected))}
              >
                <input
                  css={styles.radio}
                  type="radio"
                  id={item.value}
                  value={item.value}
                  checked={item.value === currentValue}
                  onChange={() => {}}
                  onClick={(val: any) => {
                    setValue(val.target.value);
                  }}
                />
                {item.display}
              </label>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

export default TabBar;
