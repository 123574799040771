
/**
 * API 勤怠再計算
 */

export interface ConfirmRecorderByBarcodeId {
  orgCode: string,
  orgName: string,
  loginOrgCode: string,
  loginOrgName: string,
  barcodeId: string,
  staffName: string,
  inputBarcodeId: string,
  stampTime: string,
  stampMode: number,
  stampByBelongOrg: any
}


export default class ConfirmRecorderByBarcodeIdDomain {
  constructor(private rawData: ConfirmRecorderByBarcodeId) {
  // do nothing
  }

  static generateInitial(): ConfirmRecorderByBarcodeIdDomain {
    return new ConfirmRecorderByBarcodeIdDomain({
      orgCode: '',
      orgName: '',
      loginOrgCode: '',
      loginOrgName: '',
      barcodeId: '',
      staffName: '',
      inputBarcodeId: '',
      stampTime: '',
      stampMode: 3,
      stampByBelongOrg: 0,
    });
  }

  getRawData(): ConfirmRecorderByBarcodeId {
    return this.rawData;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode:string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get loginOrgCode():string {
    return this.rawData.loginOrgCode;
  }

  set loginOrgCode(loginOrgCode:string) {
    this.rawData.loginOrgCode = loginOrgCode;
  }

  get loginOrgName():string {
    return this.rawData.loginOrgName;
  }

  set loginOrgName(loginOrgName:string) {
    this.rawData.loginOrgName = loginOrgName;
  }


  get inputBarcodeId():string {
    return this.rawData.inputBarcodeId;
  }

  set inputBarcodeId(inputBarcodeId:string) {
    this.rawData.inputBarcodeId = inputBarcodeId;
  }

  get barcodeId():string {
    return this.rawData.barcodeId;
  }

  set barcodeId(barcodeId:string) {
    this.rawData.barcodeId = barcodeId;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  get stampTime():string {
    return this.rawData.stampTime;
  }

  set stampTime(stampTime:string) {
    this.rawData.stampTime = stampTime;
  }

  get stampMode():number {
    return this.rawData.stampMode;
  }

  set stampMode(stampMode:number) {
    this.rawData.stampMode = stampMode;
  }

  get stampByBelongOrg():any {
    return this.rawData.stampByBelongOrg;
  }

  set stampByBelongOrg(stampByBelongOrg:any) {
    this.rawData.stampByBelongOrg = stampByBelongOrg;
  }
  // get

// custom data use DataTable
}
