/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import Radio from 'components/molecules/Radio';
import FormLabel from 'components/atoms/Form/FormLabel';

const styles = {
  selector: css({
    display: 'flex',
    marginTop: '8px',
  }),
  selectorInline: css({
    display: 'inline-flex',
    margin: '8px 0 0 20px',
  }),
  item: css({
    marginRight: '12px',
  }),
};

type RadioItem = {
  value: string;
  label: string;
}

const RadioSelector: React.FC<{
  items: Array<RadioItem>;
  name: string;
  value: string;
  readOnly?: boolean;
  noTitle?: boolean;
  setValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectorInline?: boolean;
  labelInline?: boolean;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  labelInlineWidth?:number;
}> = ({
  items, name, value, setValue, readOnly = false, noTitle = false, selectorInline = false, labelInline = false, label, required = false, disabled = false, labelInlineWidth = 0,
}) => (
  <div css={css(selectorInline ? styles.selectorInline : styles.selector, noTitle ? 'margin-top:0px' : '')}>
    {label && labelInline
    && (
    <FormLabel
      label={label}
      required={required}
      customStyle={css({ width: labelInlineWidth || '200px' })}
    />
    )}
    {items.map((item, id) => (
      <div css={styles.item} key={item.value}>
        <Radio
          id={`${name}_${String(id)}`}
          name={name}
          label={item.label}
          value={item.value}
          checked={value === item.value}
          onChange={setValue}
          readOnly={readOnly}
          disabled={disabled}
        />
      </div>
    ))}
  </div>
);

export default RadioSelector;
