/* eslint-disable max-len */
/* eslint-disable camelcase */
import moment from 'moment';

import { OrganizationDataStaff, OrganizationDataStaffDomain } from './master/general/organizationDataStaff';
import { EmploymentDataStaff, EmploymentDataStaffDomain } from './master/general/employmentDataStaff';
import { SalaryDataStaffDomain, SalaryDataStaff } from './master/general/salaryDataStaff';
import { WeekShiftPatternDataStaff, WeekShiftPatternDataStaffDomain } from './master/general/weekShiftPatternDataStaff';
import { ShiftPattern } from './master/attend/shiftPattern';
import { ManagerDataStaff, ManagerDataStaffDomain } from './master/general/managerDataStaff';
import { Business } from './master/attend/business';

type TAccessRegist = {
  orgCode:string;
  roleCode:string
}
export type StaffBusiness = {
  staff_business_id: string,
  staff_code: string,
  business_id: string,
  company_code: string,
  create_user: string,
  create_date: Date,
  update_user: string,
  update_date: Date,
}
export type StaffAccess = {
    companyCode: string,
    createUser: string,
    createDate: Date,
    updateUser: string,
    updateDate: Date,
    staffAccessId: string,
    staffCode: string,
    targetFunc: number,
    accessType: number,
    organizationCategoryMstHeadId: string,
    organizationCategoryMstDetailId: string,
    orgCode: string,
    roleCode: string,
}

export type Staff = {
  id: {
    companyCode?: string;
    staffCode: string;
  };
  staffCode: string;
  staffName: string;
  staffNameKana: string;
  mail: string;
  barcodeId: string;
  hireDate?: Date;
  retirementDate?: Date;
  birthDate?: Date;
  hqRole: string ;
  shopRole: string;
  personalRole: string;
  orgCode: string;
  orgName: string;
  executiveId: string;
  executiveCode: string;
  executiveName: string;
  employmentId: string;
  employmentCode: string;
  employmentName: string;
  hqRoleName: string;
  shopRoleName: string;
  personalRoleName: string;
  stateStatus: number;
  stateStatusDisp: string;
  role: string;
  roleCode:string;
  roleName:string;

  phoneNumber?: string,
  postalCode?: string;
  address1?: string;
  address2?: string;
  trialTerm?: number,
  gender?: number,
  nameplate?: string,
  nationality?: number,
  passportNumber: string;
  passportExpirationDate?: Date,
  residenceCardNumber: string;
  alienRegistrationCardExpirationDate?: Date,
  residenceCardExpirationDate?: Date,
  statusResidence?: number,
  createUser?: string,
  updateUser?: string,
  dispHireDate?: string,
  dispRetirementDate?: string,
  dispBirthDate?: string,
  dispOrgApplyDate?: string,
  dispEmpApplyDate?: string,
  dispPassportExpirationDate?: string,
  dispAlienRegistrationCardExpirationDate?: string,
  dispResidenceCardExpirationDate?: string,
  editStaffCode?: string,
  orgApplyDate?: Date,
  empApplyDate?: Date,
  empApplyEndDate?: Date,
  workingForm?: number,
  stateFlg?: boolean,
  // newEmployment:
  // adoptionDate: Date,
  staffBusinessList : Array<StaffBusiness>,
  organizationDataList : Array<OrganizationDataStaff>,
  employmentDataList: Array<EmploymentDataStaff>,
  salaryList : Array<SalaryDataStaff>,
  weekShiftPatternList : Array<WeekShiftPatternDataStaff>,
  roundTimeList : Array<string>,
  shiftPatternList : Array<ShiftPattern>,
  managerList: Array<ManagerDataStaff>,

  empBusinessList: Array<Business>,

  accessLevel: number
  categoryAccessSetAry: Array<any>,
  orgAccessSetAry: Array<any>,
  categoryAccessMap: any,
  orgAccessList: Array<StaffAccess>,
  orgAccessRegistList: Array<TAccessRegist>,
  applyStaffSalary?: SalaryDataStaff
}

export type StoreState = 'open' | 'close';

export default class StaffDomain {
  private rawData: Staff

  private selectedSalary: SalaryDataStaffDomain;

  private selectedWeekShiftPattern: WeekShiftPatternDataStaffDomain;

  constructor(rawData: Staff) {
    this.rawData = {
      ...rawData,
      id: {
        staffCode: rawData.id ? rawData.id.staffCode : rawData.staffCode,
      },
    };
    // 適用中の人件費情報を初期値に設定
    if (this.rawData.applyStaffSalary) {
      this.selectedSalary = new SalaryDataStaffDomain({ ...this.rawData.applyStaffSalary });
    } else {
      this.selectedSalary = SalaryDataStaffDomain.generateInitial();
    }

    // Set default selected week shift pattern
    this.selectedWeekShiftPattern = WeekShiftPatternDataStaffDomain.generateInitial();

    // DBから取得したロール情報は3つのサイトごとに分割されているためここで1つに統合する
    if (rawData.hqRole === 'HQ000000000000000000000000000003') {
      this.rawData.role = 'HQ000000000000000000000000000003';
    } else if (rawData.hqRole === 'HQ000000000000000000000000000001') {
      this.rawData.role = 'HQ000000000000000000000000000001';
    } else if (rawData.shopRole === 'SHOP0000000000000000000000000003') {
      this.rawData.role = 'SHOP0000000000000000000000000003';
    } else if (rawData.shopRole === 'SHOP0000000000000000000000000001') {
      this.rawData.role = 'SHOP0000000000000000000000000001';
    } else {
      this.rawData.role = 'PERSONAL000000000000000000000001';
    }
  }

  static generateInitial(): StaffDomain {
    return new StaffDomain({
      id: {
        staffCode: '',
      },
      staffCode: '',
      staffName: '',
      staffNameKana: '',
      mail: '',
      phoneNumber: '',
      postalCode: '',
      address1: '',
      address2: '',
      barcodeId: '',
      hireDate: undefined,
      retirementDate: undefined,
      birthDate: undefined,
      hqRole: '',
      shopRole: '',
      personalRole: sessionStorage.getItem('personalRole') || sessionStorage.getItem('loginUser.personalRole')!,
      orgCode: '',
      orgName: '',
      executiveId: '',
      executiveCode: '',
      executiveName: '',
      employmentId: '',
      employmentCode: '',
      employmentName: '',
      hqRoleName: '',
      shopRoleName: '',
      personalRoleName: '',
      stateStatus: 0,
      stateStatusDisp: '',
      passportNumber: '',
      residenceCardNumber: '',

      role: 'PERSONAL000000000000000000000001',
      roleCode:'',
      roleName:'',

      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',

      statusResidence: 1,
      trialTerm: 0,
      nationality: 1,
      staffBusinessList: [],
      organizationDataList: [],
      employmentDataList: [],
      salaryList: [],
      weekShiftPatternList: [],
      roundTimeList: [],
      shiftPatternList: [],
      managerList: [],
      empBusinessList: [],
      editStaffCode: '',
      accessLevel: 0,
      categoryAccessSetAry: [],
      orgAccessSetAry: [],
      categoryAccessMap: { },
      orgAccessList: [],
      orgAccessRegistList:[],
      applyStaffSalary: undefined,
    });
  }

  getRawData(): Staff {
    return this.rawData;
  }

  getStaffInfoPostData(isEditMode?: boolean): any {
    const business : Array<any> = this.staffBusinessList.map((item) => ({
      businessId: item.business_id,
      companyCode: sessionStorage.getItem('loginUser.companyCode') || '',
      staffCode: this.rawData.id.staffCode,
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
    }));

    // DBから取得したロール情報は3つのサイトごとに分割されているためここで1つに統合する
    if (this.role === 'HQ000000000000000000000000000003') {
      this.hqRole = 'HQ000000000000000000000000000003';
      this.shopRole = 'SHOP0000000000000000000000000003';
    } else if (this.role === 'HQ000000000000000000000000000001') {
      this.hqRole = 'HQ000000000000000000000000000001';
      this.shopRole = 'SHOP0000000000000000000000000001';
    } else if (this.role === 'SHOP0000000000000000000000000003') {
      this.hqRole = '';
      this.shopRole = 'SHOP0000000000000000000000000003';
    } else if (this.role === 'SHOP0000000000000000000000000001') {
      this.hqRole = '';
      this.shopRole = 'SHOP0000000000000000000000000001';
    } else {
      this.hqRole = '';
      this.shopRole = '';
    }
    // const categoryAccessSetArystr : Array<any> = this.categoryAccessSetAry.map((item) => ({
    //   categoryAccessSetAry: item.join(','),
    // }));

    const postRawData = {
      staffName: this.staffName,
      staffNameKana: this.staffNameKana,
      id: { staffCode: this.staffCode },
      beforeStaffCode: isEditMode ? this.editStaffCode : '',
      dispHireDate: this.dispHireDate || '',
      trialTerm: this.trialTerm,
      dispRetirementDate: this.dispRetirementDate || '',
      mail: this.mail,
      phoneNumber: this.phoneNumber,
      postalCode: this.postalCode,
      address1: this.address1,
      address2: this.address2,

      // personalRole: this.personalRole,
      personalRole: 'PERSONAL000000000000000000000001',
      roleCode: this.roleCode,
      roleName: this.roleName,

      shopRole: this.shopRole,
      hqRole: this.hqRole,
      gender: this.gender || '',
      dispBirthDate: this.dispBirthDate || '',
      nationality: this.nationality,
      passportNumber: this.passportNumber,
      dispPassportExpirationDate: this.dispPassportExpirationDate || '',
      residenceCardNumber: this.residenceCardNumber,
      dispAlienRegistrationCardExpirationDate: this.dispAlienRegistrationCardExpirationDate || '',
      dispResidenceCardExpirationDate: this.dispResidenceCardExpirationDate || '',
      statusResidence: this.statusResidence,
      barcodeId: this.barcodeId,
      dispOrgApplyDate: this.dispOrgApplyDate,
      orgCode: this.orgCode,
      executiveId: this.executiveId,
      employmentId: this.employmentId,
      createUser: this.createUser,
      updateUser: this.updateUser,
      daysOfWeek: '',
      business: business.length === 0 ? '' : business,
      dispEmpApplyDate: this.dispEmpApplyDate,
      managerList: this.managerList,
      role: this.role,
      accessLevel: this.accessLevel,
      categoryAccessSetAry: this.categoryAccessSetAry,
      orgAccessSetAry: this.orgAccessSetAry,
      orgAccessRegistList: this.orgAccessRegistList,
      // categoryAccessMap: this.categoryAccessMap,
      // orgAccessList: this.orgAccessList,

    };

    return {
      ...postRawData,
      ...this.getSubmitSalary(),
      ...this.getWorkPatternPostData(),
    };
  }


  private getWorkPatternPostData(): any {
    const attendShiftPatternDetails : Array<any> = this.selectedWeekShiftPattern.attendShiftPatternDetails.map((item) => ({
      shiftPatternDetailId: item.shiftPatternDetailId,
      shiftPatternId: item.shiftPatternId,
      startHourForm: item.startTime.substr(0, 2),
      startTimeForm: item.startTime.substr(3, 2),
      endHourForm: item.endTime.substr(0, 2),
      endTimeForm: item.endTime.substr(3, 2),
      createUser: item.createUser || sessionStorage.getItem('loginUser.staffName'),
      updateUser: item.updateUser || sessionStorage.getItem('loginUser.staffName'),
    }));

    const shiftPattern = {
      shiftPatternId: this.selectWeekShiftPattern.shiftPatternId,
      shiftPatternCode: this.selectWeekShiftPattern.shiftPatternCode,
      shiftPatternName: this.selectWeekShiftPattern.shiftPatternName,
      shortName: this.selectWeekShiftPattern.shortName,
      attendType: this.selectedWeekShiftPattern.attendType,
      attendShiftPatternDetails: this.selectedWeekShiftPattern.attendShiftPatternDetails.map((item) => ({
        startTime: item.startTime.substr(0, 5),
        endTime: item.endTime.substr(0, 5),
        isStartTimeNextDay: item.isStartTimeNextDay,
        isEndTimeNextDay: item.isEndTimeNextDay,
        businessId: item.businessId,
      })),
    };
    const registerDaysOfWeek = this.selectWeekShiftPattern.selectedDayOfWeeks.concat(this.selectWeekShiftPattern.dayOfWeek).filter((item) => item !== undefined);

    return {
      attendShiftPatternDetails,
      shiftPattern,
      staffWeekShiftPatternId: this.selectWeekShiftPattern.staffWeekShiftPatternId,
      daysOfWeek: registerDaysOfWeek.length === 0 ? '' : registerDaysOfWeek,
    };
  }


  private getSubmitSalary() : any {
    const salarySupportData = this.selectSalary.salarySupportDataList.map(
      (item) => ({
        id: { orgCode: item.orgCode },
        addHourlySalary: item.addHourlySalary,
        supportTransportation: item.supportTransportation,
      }),
    );
    const salaryBusinesData = this.selectSalary.salaryBusinessList.map(
      (item) => ({
        id: { businessId: item.id.businessId },
        addHourlySalary: item.addHourlySalary,
      }),
    );
    const salaryData = [{
      applyStartDateStr: this.selectedSalary.applyStartDateStr,
      staffSalaryId: this.salaryDomainList[0]?.staffSalaryId || this.selectedSalary.staffSalaryId,
      beforeApplyStartDateStr: this.selectedSalary.applyStartDateStr,
      salaryPayment: this.selectedSalary.salaryPayment,
      monthlyHourlySalary: this.selectedSalary.salaryPayment === 0 ? '' : this.selectedSalary.monthlyHourlySalary,
      hourlySalary: this.selectedSalary.salaryPayment === 0 ? '' : this.selectedSalary.hourlySalary,
      monthlySalary: this.selectedSalary.salaryPayment === 0 ? '' : this.selectedSalary.monthlySalary,
      dailySalary: this.selectedSalary.salaryPayment === 0 ? '' : this.selectedSalary.dailySalary,
      dailyHourlySalary: this.selectedSalary.salaryPayment === 0 ? '' : this.selectedSalary.dailyHourlySalary,
      fixedOvertimeHours: this.selectedSalary.salaryPayment === 0 ? 0 : this.selectedSalary.fixedOvertimeHours,
      transportationFlag: this.selectedSalary.transportationFlag,
      dailyTransportation: this.selectedSalary.transportationFlag === 0 ? '' : this.selectedSalary.dailyTransportation,
      monthlyTransportation: this.selectedSalary.transportationFlag === 0 ? '' : this.selectedSalary.monthlyTransportation,
      limitTransportation: this.selectedSalary.transportationFlag === 0 ? '' : this.selectedSalary.limitTransportation,
      trainingTermDay: '',
      trainingTermDivision: 1,
      trainingTermHour: '',
      trainingHourlyRate: '',
      trainingHourlyDivision: 1,
      hourlyTrainingFlag: 0,
      trainingHourlyDifference: this.selectedSalary.trainingHourlyDifference,
      salarySupportList: salarySupportData,
      salaryBusinessList: salaryBusinesData,
    }];
    return { salaryList: salaryData };
  }

  private getPostData(): Staff {
    // nullを除去する
    const postRawData: Staff = JSON.parse(
      JSON.stringify(this.rawData, (key, value) => (value === null ? '' : value)),
    );

    return {
      ...postRawData,
    };
  }

  get staffCode(): string {
    return this.rawData.id.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.id.staffCode = staffCode;
  }

  get staffName(): string {
    return this.rawData.staffName;
  }

  set staffName(staffName: string) {
    this.rawData.staffName = staffName;
  }

  get staffNameKana(): string {
    return this.rawData.staffNameKana;
  }

  set staffNameKana(staffNameKana: string) {
    this.rawData.staffNameKana = staffNameKana;
  }

  get mail(): string {
    return this.rawData.mail;
  }

  set mail(mail: string) {
    this.rawData.mail = mail;
  }

  get barcodeId(): string {
    return this.rawData.barcodeId;
  }

  set barcodeId(barcodeId: string) {
    this.rawData.barcodeId = barcodeId;
  }

  get hireDate(): Date | undefined {
    if (this.rawData.hireDate) {
      return moment(this.rawData.hireDate).toDate();
    }
    return undefined;
  }

  get dispHireDate(): string | undefined {
    if (this.rawData.hireDate) {
      return moment(this.rawData.hireDate).format('YYYY年MM月DD日');
    }
    return undefined;
  }

  set hireDate(hireDate: Date | undefined) {
    this.rawData.hireDate = hireDate;
  }

  get retirementDate(): Date | undefined {
    if (this.rawData.retirementDate) {
      return moment(this.rawData.retirementDate).toDate();
    }
    return undefined;
  }

  get dispRetirementDate(): string | undefined {
    if (this.rawData.retirementDate) {
      return moment(this.rawData.retirementDate).format('YYYY年MM月DD日');
    }
    return undefined;
  }

  set retirementDate(retirementDate: Date | undefined) {
    this.rawData.retirementDate = retirementDate;
  }

  get birthDate(): Date | undefined {
    if (this.rawData.birthDate) {
      return moment(this.rawData.birthDate).toDate();
    }
    return undefined;
  }

  get dispBirthDate(): string | undefined {
    if (this.rawData.birthDate) {
      return moment(this.rawData.birthDate).format('YYYY年MM月DD日');
    }
    return undefined;
  }

  set birthDate(birthDate: Date | undefined) {
    this.rawData.birthDate = birthDate;
  }

  get hqRole(): string {
    if (!this.rawData.hqRole) {
      return '';
    }
    return this.rawData.hqRole;
  }

  set hqRole(hqRole: string) {
    this.rawData.hqRole = hqRole;
  }

  get shopRole(): string {
    if (!this.rawData.shopRole) {
      return '';
    }
    return this.rawData.shopRole;
  }

  set shopRole(shopRole: string) {
    this.rawData.shopRole = shopRole;
  }

  get personalRole(): string {
    if (!this.rawData.personalRole) {
      return '';
    }
    return this.rawData.personalRole;
  }

  set personalRole(personalRole: string) {
    this.rawData.personalRole = personalRole;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get executiveId(): string {
    if (this.rawData.executiveId) {
      return this.rawData.executiveId;
    }
    return '';
  }

  set executiveId(executiveId: string) {
    this.rawData.executiveId = executiveId;
  }

  get executiveCode(): string {
    return this.rawData.executiveCode;
  }

  set executiveCode(executiveCode: string) {
    this.rawData.executiveCode = executiveCode;
  }

  get executiveName(): string {
    return this.rawData.executiveName;
  }

  set executiveName(executiveName: string) {
    this.rawData.executiveName = executiveName;
  }

  get employmentId(): string {
    return this.rawData.employmentId;
  }

  set employmentId(employmentId: string) {
    this.rawData.employmentId = employmentId;
  }

  get employmentCode(): string {
    return this.rawData.employmentCode;
  }

  set employmentCode(employmentCode: string) {
    this.rawData.employmentCode = employmentCode;
  }

  get employmentName(): string {
    return this.rawData.employmentName;
  }

  set employmentName(employmentName: string) {
    this.rawData.employmentName = employmentName;
  }


  get hqRoleName(): string {
    return this.rawData.hqRoleName;
  }

  set hqRoleName(hqRoleName: string) {
    this.rawData.hqRoleName = hqRoleName;
  }

  get shopRoleName(): string {
    return this.rawData.shopRoleName;
  }

  set shopRoleName(shopRoleName: string) {
    this.rawData.shopRoleName = shopRoleName;
  }

  get personalRoleName(): string {
    return this.rawData.personalRoleName;
  }

  set personalRoleName(personalRoleName: string) {
    this.rawData.personalRoleName = personalRoleName;
  }

  get stateStatus(): number {
    return this.rawData.stateStatus;
  }

  set stateStatus(stateStatus: number) {
    this.rawData.stateStatus = stateStatus;
  }

  get stateStatusDisp(): string {
    return this.rawData.stateStatusDisp;
  }

  set stateStatusDisp(stateStatusDisp: string) {
    this.rawData.stateStatusDisp = stateStatusDisp;
  }

  get phoneNumber(): string | undefined {
    return this.rawData.phoneNumber;
  }

  set phoneNumber(phoneNumber: string | undefined) {
    this.rawData.phoneNumber = phoneNumber;
  }

  get postalCode(): string | undefined {
    return this.rawData.postalCode;
  }

  set postalCode(postalCode: string | undefined) {
    this.rawData.postalCode = postalCode;
  }

  get phoneNumaddress1ber(): string | undefined {
    return this.rawData.address1;
  }

  get address1(): string | undefined {
    return this.rawData.address1;
  }

  set address1(address1: string | undefined) {
    this.rawData.address1 = address1;
  }

  get address2(): string | undefined {
    return this.rawData.address2;
  }

  set address2(address2: string | undefined) {
    this.rawData.address2 = address2;
  }

  get trialTerm(): number | undefined {
    return this.rawData.trialTerm;
  }

  set trialTerm(trialTerm: number | undefined) {
    this.rawData.trialTerm = trialTerm;
  }

  get gender(): number | undefined {
    return this.rawData.gender;
  }

  set gender(gender: number | undefined) {
    this.rawData.gender = gender;
  }

  get nationality(): number | undefined {
    return this.rawData.nationality;
  }

  set nationality(nationality: number | undefined) {
    this.rawData.nationality = nationality;
  }

  get passportNumber(): string {
    return this.rawData.passportNumber;
  }

  set passportNumber(passportNumber: string) {
    this.rawData.passportNumber = passportNumber;
  }

  get passportExpirationDate(): Date | undefined {
    if (this.rawData.passportExpirationDate) {
      return moment(this.rawData.passportExpirationDate).toDate();
    }
    return undefined;
  }

  get dispPassportExpirationDate(): string | undefined {
    if (this.rawData.passportExpirationDate) {
      return moment(this.rawData.passportExpirationDate).format('YYYY年MM月DD日');
    }
    return undefined;
  }

  set passportExpirationDate(passportExpirationDate: Date | undefined) {
    this.rawData.passportExpirationDate = passportExpirationDate;
  }


  get residenceCardNumber(): string {
    return this.rawData.residenceCardNumber;
  }

  set residenceCardNumber(residenceCardNumber: string) {
    this.rawData.residenceCardNumber = residenceCardNumber;
  }

  get alienRegistrationCardExpirationDate(): Date | undefined {
    if (this.rawData.alienRegistrationCardExpirationDate) {
      return moment(this.rawData.alienRegistrationCardExpirationDate).toDate();
    }
    return undefined;
  }

  get dispAlienRegistrationCardExpirationDate(): string | undefined {
    if (this.rawData.alienRegistrationCardExpirationDate) {
      return moment(this.rawData.alienRegistrationCardExpirationDate).format('YYYY年MM月DD日');
    }
    return undefined;
  }

  set alienRegistrationCardExpirationDate(alienRegistrationCardExpirationDate: Date | undefined) {
    this.rawData.alienRegistrationCardExpirationDate = alienRegistrationCardExpirationDate;
  }

  get residenceCardExpirationDate(): Date | undefined {
    if (this.rawData.residenceCardExpirationDate) {
      return moment(this.rawData.residenceCardExpirationDate).toDate();
    }
    return undefined;
  }

  get dispResidenceCardExpirationDate(): string | undefined {
    if (this.rawData.residenceCardExpirationDate) {
      return moment(this.rawData.residenceCardExpirationDate).format('YYYY年MM月DD日');
    }
    return undefined;
  }

  set residenceCardExpirationDate(residenceCardExpirationDate: Date | undefined) {
    this.rawData.residenceCardExpirationDate = residenceCardExpirationDate;
  }

  get statusResidence(): number | undefined {
    return this.rawData.statusResidence;
  }

  set statusResidence(statusResidence: number | undefined) {
    this.rawData.statusResidence = statusResidence;
  }

  get createUser(): string | undefined {
    return this.rawData.createUser;
  }

  set createUser(createUser: string | undefined) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string | undefined {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string | undefined) {
    this.rawData.updateUser = updateUser;
  }

  get editStaffCode(): string | undefined {
    return this.rawData.editStaffCode;
  }

  set editStaffCode(editStaffCode: string | undefined) {
    this.rawData.editStaffCode = editStaffCode;
  }


  get orgApplyDate(): Date | undefined {
    if (this.rawData.orgApplyDate) {
      return moment(this.rawData.orgApplyDate).toDate();
    }
    return undefined;
  }

  set orgApplyDate(orgApplyDate: Date | undefined) {
    this.rawData.orgApplyDate = orgApplyDate;
  }

  get dispOrgApplyDate(): string | undefined {
    if (this.rawData.orgApplyDate) {
      return moment(this.rawData.orgApplyDate).format('YYYY年MM月DD日');
    }
    return undefined;
  }

  get empApplyDate(): Date | undefined {
    if (this.rawData.empApplyDate) {
      return moment(this.rawData.empApplyDate).toDate();
    }
    return new Date();
  }

  set empApplyDate(empApplyDate: Date | undefined) {
    this.rawData.empApplyDate = empApplyDate;
  }

  get dispEmpApplyDate(): string | undefined {
    if (this.rawData.empApplyDate) {
      return moment(this.rawData.empApplyDate).format('YYYY年MM月DD日');
    }
    return moment(new Date()).format('YYYY年MM月');
  }

  get staffBusinessList(): Array<StaffBusiness> {
    return this.rawData.staffBusinessList;
  }

  set staffBusinessList(staffBusinessList: Array<StaffBusiness>) {
    this.rawData.staffBusinessList = staffBusinessList;
  }

  get organizationDataList(): Array<OrganizationDataStaff> {
    return this.rawData.organizationDataList;
  }

  get organizationDataDomainList(): Array<OrganizationDataStaffDomain> {
    return this.rawData.organizationDataList.map((item) => new OrganizationDataStaffDomain(item));
  }

  set organizationDataList(organizationDataList: Array<OrganizationDataStaff>) {
    this.rawData.organizationDataList = organizationDataList;
  }

  get employmentDataList(): Array<EmploymentDataStaff> {
    return this.rawData.employmentDataList;
  }

  get employmentDataDomainList(): Array<EmploymentDataStaffDomain> {
    return this.rawData.employmentDataList.map((item) => new EmploymentDataStaffDomain(item));
  }

  set employmentDataList(employmentDataList: Array<EmploymentDataStaff>) {
    this.rawData.employmentDataList = employmentDataList;
  }

  get selectSalary(): SalaryDataStaffDomain {
    return this.selectedSalary;
  }

  set selectSalary(selectSalary: SalaryDataStaffDomain) {
    this.selectedSalary = selectSalary;
  }

  get salaryList(): Array<SalaryDataStaff> {
    return this.rawData.salaryList;
  }

  get salaryDomainList(): Array<SalaryDataStaffDomain> {
    return this.rawData.salaryList.map((item) => new SalaryDataStaffDomain(item));
  }

  set salaryList(salaryList: Array<SalaryDataStaff>) {
    this.rawData.salaryList = salaryList;
  }

  get selectWeekShiftPattern(): WeekShiftPatternDataStaffDomain {
    return this.selectedWeekShiftPattern;
  }

  set selectWeekShiftPattern(selectWeekShiftPattern: WeekShiftPatternDataStaffDomain) {
    this.selectedWeekShiftPattern = selectWeekShiftPattern;
  }

  get weekShiftPatternList(): Array<WeekShiftPatternDataStaff> {
    return this.rawData.weekShiftPatternList;
  }

  get weekShiftPatternDomainList(): Array<WeekShiftPatternDataStaffDomain> {
    return this.rawData.weekShiftPatternList.map(
      (item) => new WeekShiftPatternDataStaffDomain(item),
    );
  }

  set weekShiftPatternList(weekShiftPatternList: Array<WeekShiftPatternDataStaff>) {
    this.rawData.weekShiftPatternList = weekShiftPatternList;
  }

  get empBusinessList(): Array<Business> {
    return this.rawData.empBusinessList;
  }

  set empBusinessList(empBusinessList: Array<Business>) {
    this.rawData.empBusinessList = empBusinessList;
  }

  get roundTimeList(): Array<string> {
    return this.rawData.roundTimeList;
  }

  set roundTimeList(roundTimeList: Array<string>) {
    this.rawData.roundTimeList = roundTimeList;
  }

  get shiftPatternList(): Array<ShiftPattern> {
    return this.rawData.shiftPatternList;
  }

  set shiftPatternList(shiftPatternList: Array<ShiftPattern>) {
    this.rawData.shiftPatternList = shiftPatternList;
  }

  get managerList(): Array<ManagerDataStaff> {
    return this.rawData.managerList;
  }

  get managerDomainList(): Array<ManagerDataStaffDomain> {
    return this.rawData.managerList.map(
      (item) => new ManagerDataStaffDomain(item),
    );
  }


  set managerDomainList(managerDomainList: Array<ManagerDataStaffDomain>) {
    this.rawData.managerList = managerDomainList.map((item) => ({
      managerId: item.managerId,
      staffCode: item.staffCode,
      startDate: item.startDate,
      endDate: item.endDate,
      orgCode: item.orgCode,
      orgName: item.orgName,
      managerType: item.managerType,
      startDateStr: item.startDateStr,
      endDateStr: item.endDateStr,
    }));
  }

  set managerList(managerList: Array<ManagerDataStaff>) {
    this.rawData.managerList = managerList;
  }

  get role(): string {
    if (!this.rawData.role) {
      return '';
    }
    return this.rawData.role;
  }

  set role(role: string) {
    this.rawData.role = role;
  }

  get roleCode(): string {
    return this.rawData.roleCode;
  }

  set roleCode(roleCode: string) {
    this.rawData.roleCode = roleCode;
  }

  get roleName(): string {
    return this.rawData.roleName;
  }

  set roleName(roleName: string) {
    this.rawData.roleName = roleName;
  }

  get accessLevel(): number {
    return this.rawData.accessLevel;
  }

  set accessLevel(accessLevel:number) {
    this.rawData.accessLevel = accessLevel;
  }

  get categoryAccessSetAry(): Array<any> {
    if (this.rawData.categoryAccessSetAry) {
      return this.rawData.categoryAccessSetAry;
    }
    //
    const { categoryAccessMap } = this.rawData;

    if (!categoryAccessMap) {
      return [];
    }

    const categoryAccessMapArr = Object.values(categoryAccessMap);
    if (!categoryAccessMapArr || !categoryAccessMapArr.length) {
      return [];
    }
    const categoryArr = [] as any;
    categoryAccessMapArr.forEach((categoryAcc: any) => {
      if (categoryAcc && categoryAcc.length) {
        categoryAcc.forEach((cateyAcc: any) => {
          categoryArr.push(cateyAcc.organizationCategoryMstDetailId);
        });
      }
    });
    return categoryArr;
  }

  set categoryAccessSetAry(categoryAccessSetAry: Array<any>) {
    this.rawData.categoryAccessSetAry = categoryAccessSetAry;
  }

  get orgAccessSetAry(): Array<any> {
    if (this.rawData.orgAccessSetAry) {
      return this.rawData.orgAccessSetAry;
    }

    //
    const { orgAccessList } = this.rawData;
    if (!orgAccessList || !orgAccessList.length) {
      return [];
    }
    const orgArr = [] as any;
    orgAccessList.forEach((orgAc: any) => {
      orgArr.push(orgAc.orgCode);
    });
    return orgArr;
  }

  set orgAccessSetAry(orgAccessSetAry: Array<any>) {
    this.rawData.orgAccessSetAry = orgAccessSetAry;
  }

  get categoryAccessMap(): Array<StaffAccess> {
    return this.rawData.categoryAccessMap;
  }

  set categoryAccessMap(categoryAccessMap: Array<StaffAccess>) {
    this.rawData.categoryAccessMap = categoryAccessMap;
  }

  get orgAccessList(): Array<StaffAccess> {
    return this.rawData.orgAccessList;
  }

  set orgAccessList(orgAccessList: Array<StaffAccess>) {
    this.rawData.orgAccessList = orgAccessList;
  }

  get orgAccessRegistList(): Array<TAccessRegist> {
    return this.rawData.orgAccessRegistList;
  }

  set orgAccessRegistList(orgAccessRegistList: Array<TAccessRegist>) {
    this.rawData.orgAccessRegistList = orgAccessRegistList;
  }

  get applyStaffSalary(): SalaryDataStaff | undefined {
    return this.rawData.applyStaffSalary;
  }

  set applyStaffSalary(applyStaffSalary: SalaryDataStaff | undefined) {
    this.rawData.applyStaffSalary = applyStaffSalary;
  }
}
