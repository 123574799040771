import moment from 'moment';
import ApiClient from './ApiClient';

export interface SalesBudgetYear {
}

/**
 * getPayslipCsvFormat
 *
 * @param formatType
 *
 */
export const importFileExceltoBudgetYear = async (uploadFile: File, orgCode: any, targetYear: any):
  Promise<Array<SalesBudgetYear>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    orgCode,
    targetYear,
    createUser: staffName,
    updateUser: staffName,
  };

  const response = await ApiClient.postFile(`/v2/salesBudgetYearly/importExcel/${companyCode}`, params, 'uploadFile', uploadFile);
  return response.data;
};

export const exportFileExceltoBudgetYear = async (
  orgCode: any,
  orgName: any,
  targetYear: any,
  procDateStr: any,
  categoryAry: any,
):
  Promise<Array<SalesBudgetYear>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  orgName = `${orgCode} ${orgName}`;
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    orgName,
    targetYear,
    procDateStr,
    loginStaffCode,
    functionType: 3,
  };
  const response = await ApiClient.getDownloadFile(`/v3/salesBudgetYearly/outputExcel/${companyCode}?${appendUrl}`, params);
  return response.data;
};

export const updateSalesBudgetYear = async (companyCode: string, data: any) => {
  // TODO パラメータ確認
  const response = await ApiClient.postOriginalData(`/v2/salesBudgetYearly/${companyCode}`, {}, data);
  return response.data;
};

export const getFiscalYearFromDate = async () => {
  // TODO パラメータ確認
  const params = {
    date: moment(new Date(), 'YYYY/MM/DD').format('YYYY-MM-DD'),
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const response = await ApiClient.get(`/v1/fiscalYear/targetdate/${companyCode}`, params);
  return response.data;
};

export const getFiscalYearFromDateToYear = async (year: number) => {
  // TODO パラメータ確認
  const params = {
    year,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const response = await ApiClient.get(`/v1/fiscalYear/targetyear/${companyCode}`, params);
  return response.data;
};


export const getBiasSetting = async (orgCode: any, targetMonth: any) => {
  const query = {
    orgCode,
    targetMonth,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/salesPlan/daily/biasSetting/${companyCode}`, query, {});
  return response.data;
};

export const salesBudgetPlanDaily = async (orgCode: any, targetMonth: any) => {
  const query = {
    orgCode,
    targetMonth,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/salesBudgetPlan/daily/${companyCode}`, query, {});
  return response.data;
};

export const updateSalesBudgetMonth = async (companyCode: string, data: any) => {
  // TODO パラメータ確認
  const response = await ApiClient.postOriginalData(`/v2/salesBudgetPlan/daily/${companyCode}`, {}, data);
  return response.data;
};

export type Error = {
  error: string;
  defaultMessage: string;
}

export default {
  importFileExceltoBudgetYear,
};
