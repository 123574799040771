import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import OrganizationCategoryCsvUploadForm from 'components/organismos/master/general/organizationCategory/OrganizationCategoryCsvUploadForm';


const OrganizationCategoryImportPage: React.FC = () => {
  const pageTitle = sessionStorage.getItem('selectedOrganizationCategory') ?? '';
  return (
    <SidebarTemplate pageTitle={`${pageTitle}インポート`}>
      <OrganizationCategoryCsvUploadForm />
    </SidebarTemplate>
  );
};


export default OrganizationCategoryImportPage;
