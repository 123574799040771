
export interface Media {
  id: {
    companyCode:string,
    paymentMediaMstCode: string,
  },
  paymentMediaMstName: string,
  dispOrder: string,
  paymentMediaCategoryCode: string,
  createUser: string,
  updateUser: string,
}


export default class MediaDomain {
  constructor(private rawData: any) {
    this.rawData = {
      ...rawData,
      id: {
        companyCode: rawData[0],
        paymentMediaMstCode: rawData[1],
      },
      paymentMediaMstName: rawData[2],
      dispOrder: rawData[3],
      paymentMediaCategoryCode: rawData[4],
      createUser: rawData.createUser,
      updateUser: rawData.updateUser,
    };
  }

  static generateInitial(): MediaDomain {
    return new MediaDomain({
      id: {
        companyCode: '',
        paymentMediaMstCode: '',
      },
      paymentMediaMstName: '',
      dispOrder: '',
      paymentMediaCategoryCode: '',
      createUser: '',
      updateUser: '',
    });
  }

  getRawData(): Media {
    return this.rawData;
  }

  getRawDataPost(): any {
    return {
      paymentMediaMstCode: this.rawData.id.paymentMediaMstCode,
      paymentMediaMstName: this.rawData.paymentMediaMstName,
      dispOrder: this.rawData.dispOrder,
      paymentMediaCategoryCode: this.rawData.paymentMediaCategoryCode,
      isCreate: true,
      createUser: this.rawData.createUser,
      updateUser: this.rawData.updateUser,
    };
  }

  get companyCode(): string {
    return this.rawData.id.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.id.companyCode = companyCode;
  }

  get paymentMediaMstCode(): string {
    return this.rawData.id.paymentMediaMstCode;
  }

  set paymentMediaMstCode(paymentMediaMstCode: string) {
    this.rawData.id.paymentMediaMstCode = paymentMediaMstCode;
  }

  get paymentMediaMstName(): string {
    return this.rawData.paymentMediaMstName;
  }

  set paymentMediaMstName(paymentMediaMstName: string) {
    this.rawData.paymentMediaMstName = paymentMediaMstName;
  }

  get dispOrder(): string {
    return this.rawData.dispOrder;
  }

  set dispOrder(dispOrder: string) {
    this.rawData.dispOrder = dispOrder;
  }

  get paymentMediaCategoryCode(): string {
    return this.rawData.paymentMediaCategoryCode !== 'undefined' ? this.rawData.paymentMediaCategoryCode : '';
  }

  set paymentMediaCategoryCode(paymentMediaCategoryCode: string) {
    this.rawData.paymentMediaCategoryCode = paymentMediaCategoryCode;
  }
}
