import moment from 'moment';
import ApiClient from './ApiClient';

export const getCommentList = async (
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  stateType: number,
  functionType: number,
  categoryAry: any,
): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    stateType,
    loginStaffCode,
    functionType,
  };

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const response = await ApiClient.get(`/v1/sales/list/standard/daily/comment/${companyCode}?${appendUrl}`, params);
  return response.data;
};

export const downloadCommentCsv = async (data: any, fileName:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsvPost(`/v1/sales/list/standard/daily/comment/csv/${companyCode}`, {}, data, getDownloadCsvFileName(`${fileName}_`));
};

const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export const downloadCommentExcel = async (data: any, fileName:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadExcelPost(`/v1/sales/list/standard/daily/comment/excel/${companyCode}`, {}, data, getDownloadExcelFileName(`${fileName}_`));
};

const getDownloadExcelFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.xlsx`;

export default { };
