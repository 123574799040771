/* eslint-disable max-len */
import React, { useState, Dispatch } from 'react';
import { Link } from 'react-router-dom';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import FormContents from 'components/atoms/Form/FormContents';
import StaffDomain from 'domain/staff';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import Icon from 'components/atoms/Icon';
import { iconColor } from 'components/styles';
import TextForm from 'components/molecules/TextForm';
import ShiftPatternDetails from 'components/organismos/master/labor/shiftPattern/ShiftPatternDetails';
import {
  WeekShiftPatternDataStaffDomain, DayOfWeekLabel, DayOfWeek,
} from 'domain/master/general/weekShiftPatternDataStaff';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import ClickableLink from 'components/atoms/ClickableLink';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import IconLabelButton from 'components/molecules/IconLabelButton';
import BodyText from 'components/atoms/BodyText';
import MultipleCheckboxForm from 'components/molecules/MultipleCheckboxForm';
import ShiftPatternDomain from 'domain/master/attend/shiftPattern';
import ReactTooltip from 'react-tooltip';
import { useAffiliationForm } from './hooks';
import { InfoMessage } from 'utility/messages';

type SelectType = 'staff'|'affiliation'|'permission'|'employment'|'laborCost'|'workPattern';
const SelectType: {[key in SelectType]: SelectType} = {
  staff: 'staff',
  affiliation: 'affiliation',
  permission: 'permission',
  employment: 'employment',
  laborCost: 'laborCost',
  workPattern: 'workPattern',
};

const WorkPatternInfoForm: React.FC<{
  setInitialStaffDomain: Dispatch<React.SetStateAction<StaffDomain>>;
  initialStaffDomain?: StaffDomain;
  isEditMode?: boolean;
  setIsEditMode?: Dispatch<React.SetStateAction<boolean>>;
  breakBusiness: ShiftPatternDomain;
  setIsLoading: Dispatch<React.SetStateAction<boolean>>;
  menuType: string;
  roleScreen?: any;
}> = ({
  setInitialStaffDomain, setIsEditMode,
  initialStaffDomain, isEditMode = false,
  breakBusiness,
  setIsLoading,
  menuType,
  roleScreen
}) => {
  const {
    formik,
    businessOptions,
    shiftPatternOptions,
    confirmModalOpen, closeConfirmModal,
    selectedDayOfWeeks,
    setSelectedDayOfWeeks,
    registeredDayOfWeeks,
    setDeleteTargetWeekShift,
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteStaff,
  } = useAffiliationForm(
    initialStaffDomain,
    isEditMode,
    setIsLoading,
    menuType,
    setIsEditMode,
  );

  const columns: Array<Column<WeekShiftPatternDataStaffDomain>> = React.useMemo(() => [
    {
      Header: '曜日',
      accessor: 'dayOfWeekStr',
      disableSortBy: true,
    },
    {
      Header: 'パターンコード',
      accessor: 'shiftPatternCode',
      disableSortBy: true,
    },
    {
      Header: 'パターン名',
      accessor: 'shiftPatternNameWithAll',
      disableSortBy: true,
    },
    {
      Header: '出社時間',
      accessor: 'startTimeStr',
      disableSortBy: true,
    },
    {
      Header: '退社時間',
      accessor: 'endTimeStr',
      disableSortBy: true,
    },
    {
      Header: '休憩時間',
      accessor: 'breakTimeStr',
      disableSortBy: true,
    },
    {
      Header: () => null,
      accessor: 'x',
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: WeekShiftPatternDataStaffDomain }}) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <FlexBox>
            <FlexBoxItem marginLeft="auto">
              <ClickableLink onClick={() => {
                formik.values.selectWeekShiftPattern = new WeekShiftPatternDataStaffDomain({ ...cell.row.original.getRawData() });
                formik.values.selectWeekShiftPattern.selectedDayOfWeeks = [cell.row.original.dayOfWeek];
                setSelectedDayOfWeeks([cell.row.original.dayOfWeek]);
                formik.setFieldValue('selectWeekShiftPattern', new WeekShiftPatternDataStaffDomain({ ...cell.row.original.getRawData() }));
                if (formik.values.selectWeekShiftPattern.attendType !== 0) {
                  let initShiftPattern = WeekShiftPatternDataStaffDomain.generateInitial();
                  initShiftPattern.staffWeekShiftPatternId = formik.values.selectWeekShiftPattern.staffWeekShiftPatternId;
                  initShiftPattern.attendType = formik.values.selectWeekShiftPattern.attendType;
                  initShiftPattern.patternIdStr = formik.values.selectWeekShiftPattern.patternIdStr;
                  initShiftPattern.selectedDayOfWeeks = [cell.row.original.dayOfWeek];
                  formik.setFieldValue('selectWeekShiftPattern', initShiftPattern);
                }
                setInitialStaffDomain(formik.values);
              }}
              >
                {' '}
                <span
                  style={{ marginLeft: '20px' }}
                  onMouseEnter={() => setIsHoverEdit(true)}
                  onMouseLeave={() => setIsHoverEdit(false)}
                >
                  <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
                </span>
              </ClickableLink>
            </FlexBoxItem>
            <FlexBoxItem>
              <ClickableIconButton
                onClick={() => {
                  setDeleteTargetWeekShift(cell.row.original);
                }}
              >
                <span
                  onMouseEnter={() => setIsHoverDelete(true)}
                  onMouseLeave={() => setIsHoverDelete(false)}
                >
                  <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
                </span>
              </ClickableIconButton>
            </FlexBoxItem>
          </FlexBox>
        );
      },
    },

  ], [formik, setDeleteTargetWeekShift, setInitialStaffDomain, setSelectedDayOfWeeks]);

  return (
    <>
      {formik.values.weekShiftPatternList
      && formik.values.weekShiftPatternList.length !== 0 && (
        <DataTable
          columns={columns}
          data={formik.values.weekShiftPatternDomainList}
        />
      )}

      <form onSubmit={formik.handleSubmit}>
        <FormContents>
          <FlexBox>
            <FlexBoxItem basis="10%">
              <BodyText> 設定曜日 </BodyText>
            </FlexBoxItem>
            <FlexBoxItem basis="90%">
              <div style={{ marginBottom: '6px' }}>
                <MultipleCheckboxForm
                  disableCheckboxs={registeredDayOfWeeks.map((item) => item.toString())}
                  name="dayOfWeek"
                  label=""
                  items={[
                    {
                      id: DayOfWeek.HOLIDAY.toString(),
                      label: DayOfWeekLabel[DayOfWeek.HOLIDAY],
                      value: selectedDayOfWeeks.includes(DayOfWeek.HOLIDAY),
                    },
                    {
                      id: DayOfWeek.SAT.toString(),
                      label: DayOfWeekLabel[DayOfWeek.SAT],
                      value: selectedDayOfWeeks.includes(DayOfWeek.SAT),
                    },
                    {
                      id: DayOfWeek.SUN.toString(),
                      label: DayOfWeekLabel[DayOfWeek.SUN],
                      value: selectedDayOfWeeks.includes(DayOfWeek.SUN),
                    },
                    {
                      id: DayOfWeek.MON.toString(),
                      label: DayOfWeekLabel[DayOfWeek.MON],
                      value: selectedDayOfWeeks.includes(DayOfWeek.MON),
                    },
                    {
                      id: DayOfWeek.TUE.toString(),
                      label: DayOfWeekLabel[DayOfWeek.TUE],
                      value: selectedDayOfWeeks.includes(DayOfWeek.TUE),
                    },
                    {
                      id: DayOfWeek.WED.toString(),
                      label: DayOfWeekLabel[DayOfWeek.WED],
                      value: selectedDayOfWeeks.includes(DayOfWeek.WED),
                    },
                    {
                      id: DayOfWeek.THU.toString(),
                      label: DayOfWeekLabel[DayOfWeek.THU],
                      value: selectedDayOfWeeks.includes(DayOfWeek.THU),
                    },
                    {
                      id: DayOfWeek.FRI.toString(),
                      label: DayOfWeekLabel[DayOfWeek.FRI],
                      value: selectedDayOfWeeks.includes(DayOfWeek.FRI),
                    },

                  ]}
                  setValue={(e) => {
                    const selectDayOfWeek = Number(e.target.id);
                    if (!registeredDayOfWeeks.includes(selectDayOfWeek)) {
                      if (e.target.value === 'true') {
                        formik.values.selectWeekShiftPattern.selectedDayOfWeeks = selectedDayOfWeeks.filter((item) => item !== selectDayOfWeek);

                        setSelectedDayOfWeeks(selectedDayOfWeeks.filter((item) => item !== selectDayOfWeek));
                        setInitialStaffDomain(formik.values);
                      } else {
                        formik.values.selectWeekShiftPattern.selectedDayOfWeeks = [...selectedDayOfWeeks, selectDayOfWeek];

                        setSelectedDayOfWeeks([...selectedDayOfWeeks, selectDayOfWeek]);
                        setInitialStaffDomain(formik.values);
                      }
                    }
                  }}
                />
              </div>
            </FlexBoxItem>
          </FlexBox>
          <FlexBox>
            <FlexBoxItem basis="60%">
              <VerticalLabelSelectForm
                label="シフトパターン"
                name="selectWeekShiftPattern.patternIdStr"
                value={formik.values.selectWeekShiftPattern.patternIdStr}
                setValue={(val: string) => {
                  let selectWeekShiftPattern : any;
                  if (val === '') {
                    selectWeekShiftPattern = WeekShiftPatternDataStaffDomain.generateInitial();
                    selectWeekShiftPattern.staffWeekShiftPatternId = formik.values.selectWeekShiftPattern.staffWeekShiftPatternId;
                    selectWeekShiftPattern.attendType = 0;
                    selectWeekShiftPattern.daysOfWeek = [formik.values.selectWeekShiftPattern.dayOfWeek];
                    selectWeekShiftPattern.selectedDayOfWeeks = selectedDayOfWeeks;
                  }  else if (val === 'legal') {
                    selectWeekShiftPattern = WeekShiftPatternDataStaffDomain.generateInitial();
                    selectWeekShiftPattern.patternIdStr = 'legal';
                    selectWeekShiftPattern.attendType = 1;
                    selectWeekShiftPattern.daysOfWeek = [formik.values.selectWeekShiftPattern.dayOfWeek];
                    selectWeekShiftPattern.selectedDayOfWeeks = selectedDayOfWeeks;
                  } else if (val === 'scheduled') {
                    selectWeekShiftPattern = WeekShiftPatternDataStaffDomain.generateInitial();
                    selectWeekShiftPattern.patternIdStr = 'scheduled';
                    selectWeekShiftPattern.attendType = 2;
                    selectWeekShiftPattern.daysOfWeek = [formik.values.selectWeekShiftPattern.dayOfWeek];
                    selectWeekShiftPattern.selectedDayOfWeeks = selectedDayOfWeeks;
                  } else {
                    const shiftPattern = formik.values.shiftPatternList.find((item) => item.shiftPatternId === val)!;
                    selectWeekShiftPattern = {
                      companyCode: shiftPattern.companyCode,
                      createUser: shiftPattern.createUser,
                      updateUser: shiftPattern.updateUser,
                      orgCode: shiftPattern.orgCode,
                      patternIdStr: shiftPattern.patternIdStr,
                      shiftPatternId: shiftPattern.shiftPatternId,
                      shiftPatternCode: shiftPattern.shiftPatternCode,
                      shiftPatternName: shiftPattern.shiftPatternName,
                      shortName: shiftPattern.shortName,
                      staffWeekShiftPatternId: formik.values.selectWeekShiftPattern.staffWeekShiftPatternId,
                      daysOfWeek: [formik.values.selectWeekShiftPattern.dayOfWeek],
                      selectedDayOfWeeks,
                      attendShiftPatternDetails: shiftPattern.attendShiftPatternDetails,
                      attendType: 0,
                    };
                  }
                  formik.values.selectWeekShiftPattern = selectWeekShiftPattern;

                  formik.setFieldValue('selectWeekShiftPattern', selectWeekShiftPattern);
                  setInitialStaffDomain(formik.values);
                }}
                options={shiftPatternOptions}
                errorMsg={formik.errors.orgCode}
                required={true}
              />
            </FlexBoxItem>
            <FlexBoxItem marginLeft="20px">
              <div className="tooltip" style={(formik.values.selectWeekShiftPattern.attendType !== 0 || formik.values.selectWeekShiftPattern.shiftPatternId === '') ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
                <p data-tip="選択中のシフトパターンをもとに </br> 新規にパターンを作成します。">
                  <IconLabelButton
                    iconType="copy"
                    text="コピー"
                    onClick={() => {
                      formik.values.selectWeekShiftPattern.shiftPatternId = '';

                      formik.setFieldValue('selectWeekShiftPattern.shiftPatternId', '');
                      setInitialStaffDomain(formik.values);
                    }}
                    padding=""
                  />
                </p>
                <ReactTooltip html={true} />
              </div>

            </FlexBoxItem>
          </FlexBox>
          <FlexBox>
            <FlexBoxItem basis="60%">
              <TextForm
                name="selectWeekShiftPattern.shiftPatternCode"
                label="シフトパターンコード"
                value={formik.values.selectWeekShiftPattern.shiftPatternCode}
                required={true}
                onChange={(val) => {
                  formik.values.selectWeekShiftPattern.shiftPatternCode = val.target.value;

                  formik.setFieldValue('selectWeekShiftPattern.shiftPatternCode', val.target.value);
                  setInitialStaffDomain(formik.values);
                }}
                disable={formik.values.selectWeekShiftPattern.shiftPatternId !== '' || 0 < formik.values.selectWeekShiftPattern.attendType}
                maxLength={10}
              />
            </FlexBoxItem>
          </FlexBox>
          <FlexBox>
            <FlexBoxItem basis="60%">
              <TextForm
                name="selectWeekShiftPattern.shiftPatternName"
                label="シフトパターン名"
                value={formik.values.selectWeekShiftPattern.orgCode === 'all' ? `${formik.values.selectWeekShiftPattern.shiftPatternName}(共通)` : formik.values.selectWeekShiftPattern.shiftPatternName}
                required={true}
                onChange={(val) => {
                  formik.values.selectWeekShiftPattern.shiftPatternName = val.target.value;

                  formik.setFieldValue('selectWeekShiftPattern.orgCode', '');
                  formik.setFieldValue('selectWeekShiftPattern.shiftPatternName', val.target.value);
                  setInitialStaffDomain(formik.values);
                }}
                disable={formik.values.selectWeekShiftPattern.shiftPatternId !== '' || 0 < formik.values.selectWeekShiftPattern.attendType}
              />
            </FlexBoxItem>
          </FlexBox>
          <FlexBox>
            <FlexBoxItem basis="60%">
              <TextForm
                name="selectWeekShiftPattern.shortName"
                label="短縮名"
                value={formik.values.selectWeekShiftPattern.shortName}
                required={true}
                onChange={(val) => {
                  formik.values.selectWeekShiftPattern.shortName = val.target.value;

                  formik.setFieldValue('selectWeekShiftPattern.shortName', val.target.value);
                  setInitialStaffDomain(formik.values);
                }}
                appendLabel="（※5文字まで）"
                disable={formik.values.selectWeekShiftPattern.shiftPatternId !== '' || 0 < formik.values.selectWeekShiftPattern.attendType}
                maxLength={5}
              />
            </FlexBoxItem>
          </FlexBox>

          <ShiftPatternDetails
            shiftPatternDetailsList={formik.values.selectWeekShiftPattern.attendShiftPatternDetails}
            setShiftPatternDetailsList={(data:any) => {
              formik.values.selectWeekShiftPattern.attendShiftPatternDetails = data;

              formik.setFieldValue('selectWeekShiftPattern.attendShiftPatternDetails', data);
              setInitialStaffDomain(formik.values);
            }}
            attendBusinessOptions={businessOptions}
            listTimes={formik.values.roundTimeList || ['00']}
            isDisableAll={!(formik.values.selectWeekShiftPattern.shiftPatternId === '' && 0 === formik.values.selectWeekShiftPattern.attendType)}
          />

        </FormContents>


        <FormSubmitArea>
          {
            roleScreen && roleScreen.editable === 1 && (
              <div style={{ marginRight: '12px' }}>
                <Button
                  text={isEditMode ? 'シフトパターン設定を登録' : '登録'}
                  onClick={formik.handleSubmit}
                />
              </div>
            )
          }

          {menuType !== '1' && (
            <Link to="/staffs">
            <Button
              text="スタッフ一覧に戻る"
              ghost={true}
            />
          </Link>
          )}
          {menuType === '1' && (
            <Link to="/staffs/employee">
            <Button
              text="スタッフ一覧に戻る"
              ghost={true}
            />
          </Link>
          )}
        </FormSubmitArea>
      </form>

      {/* 登録内容確認モーダル */}
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
      />

      <ConfirmActionModal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        onSubmit={onSubmitDeleteStaff}
        actionType={ActionType.DELETE}
      />
    </>
  );
};

export default WorkPatternInfoForm;
