/* eslint-disable max-len */
import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import getListTimeRecalculation, {
  getRecalculateTime,
  preRecalculate,
  recalculate,
} from 'api/timeRecalculation';
import TimeRecalculationDomain, { TimeRecalculation } from 'domain/master/atendenceManagement/timeRecalculation';
import { useFormik } from 'formik';
import moment from 'moment';
// import 'moment/locale/en-SG'; // without this line it didn't work
import useToastNotification from 'hooks/useToastNotification';


type ActionType = 'handleCalcTarget'|'handleClickCalc';

const ActionType: {[key in ActionType]: ActionType} = {
  handleCalcTarget: 'handleCalcTarget',
  handleClickCalc: 'handleClickCalc',
};

export const useTimeRecalculation = () => {
  const [timeRecalculation, setTimeRecalculation] = useState<Array<TimeRecalculationDomain>>([]);
  const [isFinished, setIsFinished] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isRecalculateState, setIsRecalculateState] = useState(false);


  /**
   * arrNecessaryShiftCalc
   *
   */
  const arrNecessaryShiftCalc = [
    {
      label: 'しない',
      value: '0',
    },
    {
      label: 'する',
      value: '1',
    },
  ];

  useEffect(() => {
    const isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getListTimeRecalculation(companyCode).then((response: any) => {
      if (isSubscribed) {
        setTimeRecalculation(response.recalculateDetailData.map((result: TimeRecalculation) => new TimeRecalculationDomain(result)));

        if (!response.processing) {
          setIsFinished(true);
        } else {
          setIsFinished(false);
        }
        setTimeout(() => { checkRecalculateFinished(); }, 2000);
        setLoading(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecalculateState]);

  // 再計算が終了しているかチェック
  const checkRecalculateFinished = useCallback(() => {
    if (!isFinished) {
      setIsRecalculateState(!isRecalculateState);
    }
  }, [isFinished, isRecalculateState]);

  //
  const handleReload = useCallback(() => {
    setIsRecalculateState(!isRecalculateState);
  }, [isRecalculateState]);


  return {
    timeRecalculation,
    setTimeRecalculation,
    isFinished,
    isLoading,
    setLoading,
    setIsFinished,
    isRecalculateState,
    setIsRecalculateState,
    checkRecalculateFinished,
    handleReload,
    arrNecessaryShiftCalc,
  };
};


export const useUpdateTimeRecalcution = (
  setTimeRecalculation: Dispatch<SetStateAction<TimeRecalculationDomain[]>>,
  timeRecalculation: TimeRecalculationDomain[],
  isFinished: boolean,
  setIsFinished: Dispatch<SetStateAction<boolean>>,
  isRecalculateState: boolean,
  setIsRecalculateState: Dispatch<SetStateAction<boolean>>,
  checkRecalculateFinished: any,
  isLoading: boolean,
  setLoading: Dispatch<SetStateAction<boolean>>,
) => {
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [toastMessage] = useState('');
  const [action, setAction] = useState<ActionType>(ActionType.handleCalcTarget);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalContent, setConfirmModalContent] = useState('');
  // submitConfirmModal;
  const [submitedBusinessG, setSubmitedBusinessG] = useState({});
  const { errorNotification } = useToastNotification();
  const [categoryArr, setCategoryArr] = useState<Array<String>>([]);

  const closeToastModalOpen = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const onSubmit = async (values: any) => {
    setTimeRecalculation([]);
    // check between date
    const checkTarget = await checkTargetRange(values.targetDateFrom, values.targetDateTo);
    if (!checkTarget) {
      return;
    }
    const submitObj = {
      necessaryShiftCalc: values.necessaryShiftCalc,
      createUser: values.createUser,
      updateUser: values.updateUser,
      orgCode: values.orgCode,
      employmentId: values.employmentId,
      staffCode: values.staffCode === 'all' ? '' : values.staffCode,
      targetDateFrom: moment(new Date(values.targetDateFrom.getFullYear(), (values.targetDateFrom.getMonth()), 1)).format('YYYY-MM-DD'),
      targetDateTo: moment(new Date(values.targetDateTo.getFullYear(), values.targetDateTo.getMonth() + 1, 0, 23, 59, 59)).format('YYYY-MM-DD'), // get last date month
    };

    if (action === ActionType.handleCalcTarget) {
      setLoading(false);
      getRecalculateTime(submitObj, categoryArr)
        .then((response : Array<any>) => {
          setTimeRecalculation((response.map(
            (result) => new TimeRecalculationDomain(result),
          )));
          if (response.length === 0) {
            errorNotification('再計算対象の実績はありません');
          }
          setLoading(true);
        }).catch((error : any) => {
          setLoading(true);
          if (error.response.status === 406) {
            let msgError = '';
            error.response.data.map((item: { defauleMessage: any; }) => {
              msgError += `${item.defauleMessage}, `;
              return msgError;
            });
            errorNotification(msgError);
          } else {
            errorNotification('サーバー側でエラーが発生しました。');
          }
        });
    }

    if (action === ActionType.handleClickCalc) {
      getRecalculateTime(submitObj, categoryArr)
        .then((response : Array<any>) => {
          setTimeRecalculation((response.map(
            (result) => new TimeRecalculationDomain(result),
          )));
          if (response.length === 0) {
            errorNotification('再計算対象の実績はありません');
          } else {
            // get values getScheduledEndTime
            // const getScheduledEndTime = () => {
            let targetAchievementCount = 0;
            let targetShiftCount = 0;
            const canShiftCalc = (formik.values.necessaryShiftCalc === 1);

            response.map((a) => {
              targetAchievementCount += a.targetAchievementCount;
              return targetAchievementCount;
            });

            let time = targetAchievementCount * 1.0;
            if (canShiftCalc) {
              response.map((a) => {
                targetShiftCount += a.targetShiftCount;
                return targetShiftCount;
              });

              time += (targetShiftCount * 0.5);
            }

            const scheduledEndTimetest = moment(new Date(), 'YYYY-MM-DD HH:mm:ss.SSS').add(time, 's');
            const getScheduledEndTime = moment(scheduledEndTimetest, 'YYYY-MM-DD HH:mm').format('YYYY年MM月DD日 HH時mm分');

            // end get values getScheduledEndTime
            const { recalculationId } = response[0];

            const submitObj2 = {
              recalculationId,
              staffCode: sessionStorage.getItem('loginUser.staffCode') || '',
              scheduledEndTime: scheduledEndTimetest.locale('en').format('dddd MMM DD YYYY hh:mm:ss'),

            };

            setConfirmModalOpen(true);
            const msgError = `<p>再計算の予定終了時刻は${getScheduledEndTime}です。<br/>再計算を行いますか？</p>`;
            setConfirmModalContent(msgError);
            setSubmitedBusinessG(submitObj2);
          }
        }).catch((error : any) => {
        });
    }
  };

  const submitConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
    // call  api v1/attend/preRecalcute
    preRecalculate(submitedBusinessG)
      .then((response : any) => {
        const { recalculationId } = response;

        // call api v1/attend/recalcute
        recalculate(recalculationId)
          .then((response2: any) => {
            if (response2.status === 400) {
              errorNotification(response.data.message);
              console.log('then recalcute', response.data.message);
            }
          }).catch((error: any) => {
            console.log('error', error);
          });
        setIsFinished(false);
        setIsRecalculateState(!isRecalculateState);
        checkRecalculateFinished();
      }).catch((error: any) => {
        if (error.response.status === 400) {
          errorNotification(error.response.data.message);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  }, [checkRecalculateFinished, errorNotification, isRecalculateState, setIsFinished, setIsRecalculateState, submitedBusinessG]);

  const checkTargetRange = async (strStartDate: Date, strEndDate: Date, isNoMessage?: boolean) => {
    const startDay = new Date(Date.parse(moment(strStartDate, 'YYYY年MM月DD日').format('YYYY/MM/DD')));
    const endDay = new Date(moment(strEndDate, 'YYYY年MM月DD日').format('YYYY/MM/DD'));
    const startDayNextYear = new Date(startDay.getFullYear() + 1, startDay.getMonth(), startDay.getDate());
    if (endDay.getTime() >= startDayNextYear.getTime()) {
      if (!isNoMessage) {
        errorNotification('対象期間の範囲は１年以内で入力してください');
      }
      return false;
    }
    return true;
  };

  const formik = useFormik({
    initialValues: {
      necessaryShiftCalc: 1,
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      orgCode: sessionStorage.getItem('loginUser.orgCode'),
      employmentId: '',
      staffCode: '',
      targetDateFrom: new Date(),
      targetDateTo: new Date(),
      recalculationId: '',
      scheduledEndTime: new Date(),
    },
    onSubmit,
  });

  return {
    formik,
    toastModalOpen,
    toastMessage,
    closeToastModalOpen,
    action,
    setAction,
    confirmModalContent,
    confirmModalOpen,
    closeConfirmModal,
    submitConfirmModal,
    ActionType,
    checkTargetRange,
    categoryArr,
    setCategoryArr,
  };
};


export default useTimeRecalculation;
