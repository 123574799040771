import moment, { Moment } from 'moment';

export const MinDate = moment(new Date('0001-01-01')).format('YYYY-MM-DD');
export const MaxDate = moment(new Date('9999-12-31')).format('YYYY-MM-DD');

export const convertDateStringToMoment = (dateStr: string) => moment(dateStr, ['HH:mm', 'HH:mm:ss', 'YYYY/MM/DD HH:mm:ss']);

export const convertMomentToYYYYMMDD = (momentObj: Moment) => momentObj.format('YYYY/MM/DD HH:mm:ss');

export const convertToNextDay = (dateStr: string, nextDay: boolean, time: string) => {
    let dateMoment = convertDateStringToMoment(dateStr);
    if (nextDay) {
        dateMoment = dateMoment.add(1, 'days');
    }
    const dateTime =  moment(`${dateMoment.format("YYYY/MM/DD")} ${time.split(' ')[1]}`) ;
    return dateTime.format("YYYY/MM/DD HH:mm:ss");
  };

export default {};
