/* eslint-disable max-len */
import { apiGetYearlyBalaceDetailTable, downloadIncomeAndExpenditureScheduleMonthlyCsv, downloadIncomeAndExpenditureScheduleMonthlyExcel } from "api/incomeAndExpenditureScheduleMonthly";
import { error } from "console";
import useToastNotification from "hooks/useToastNotification";
import { useEffect, useState } from "react";
import {TypeIncomeAndExpenditureScheduleMonthly} from './type'

const useIncomeAndExpenditureScheduleMonthly = (
    orgCode: string,
    yearlyStartDate: string,
    yearlyEndDate: string,
    categoryAry: any,
) => {

    const [incomeAndExpenditureScheduleMonthly, setIncomeAndExpenditureScheduleMonthly] = useState<Array<TypeIncomeAndExpenditureScheduleMonthly>>([{
        advertisingDetailAmount: "",
        advertisingDetailAmountRate: "",
        advertisingDetailName: "",
        bonusDepreciation: 0,
        bonusDepreciationRate: 0,
        ccFee: 0,
        ccFeeRate: 0,
        commonFee: 0,
        commonFeeRate: 0,
        commonServiceFee: 0,
        commonServiceFeeRate: 0,
        communicationDetailAmount: "",
        communicationDetailAmountRate: "",
        communicationDetailName: "",
        companyCode: "",
        depreciation: 0,
        depreciationDetail: 0,
        depreciationDetailRate: 0,
        depreciationRate: 0,
        designFee: 0,
        designFeeRate: 0,
        drinkDetailAmount: "",
        drinkDetailAmountRate: "",
        drinkDetailName: "",
        drinkTotal: 0,
        drinkTotalRate: 0,
        electricBill: 0,
        electricBillRate: 0,
        employeeLaborCost: 0,
        employeeLaborCostRate: 0,
        expensesDetailAmount: "",
        expensesDetailAmountRate: "",
        expensesDetailName: "",
        foodDetailAmount: "",
        foodDetailAmountRate: "",
        foodDetailName: "",
        foodTotal: 0,
        foodTotalRate: 0,
        garbageDisposalFee: 0,
        garbageDisposalFeeRate: 0,
        gasFee: 0,
        gasFeeRate: 0,
        insectRepellent: 0,
        insectRepellentRate: 0,
        landRent: 0,
        landRentRate: 0,
        leaseDebt: 0,
        leaseDebtRate: 0,
        leasePayment: 0,
        leasePaymentRate: 0,
        netSales: 0,
        ordinaryIncome: 0,
        orgCode: "",
        org_name: "",
        otherNetSales: 0,
        otherSalesDetailAmount: "",
        otherSalesDetailName: "",
        partAdjustCost: 0,
        partAdjustCostRate: 0,
        partSalary: 0,
        partSalaryRate: 0,
        pettyDrinkTotal: 0,
        pettyDrinkTotalRate: 0,
        pettyFoodTotal: 0,
        pettyFoodTotalRate: 0,
        profitRate: 0,
        recruitmentDetailAmount: "",
        recruitmentDetailAmountRate: "",
        recruitmentDetailName: "",
        rentOther: 0,
        rentOtherRate: 0,
        repairCosts: 0,
        repairCostsRate: 0,
        retireLaborCost: 0,
        retireLaborCostRate: 0,
        salesAchievementRate: 0,
        salesBudgetTotal: 0,
        storeEquipmentRent: 0,
        storeEquipmentRentRate: 0,
        targetMonth: "9999-12-31T00:00:00.000+0900",
        termEnd: 0,
        termEndRate: 0,
        termStart: 0,
        termStartRate: 0,
        totalAdvertisingCost: 0,
        totalAdvertisingCostRate: 0,
        totalCcAmount: 0,
        totalCcAmountRate: 0,
        totalCommunicationCost: 0,
        totalCommunicationCostRate: 0,
        totalExpenditure: 0,
        totalExpenditureRate: 0,
        totalExpenses: 0,
        totalExpensesRate: 0,
        totalFlCost: 0,
        totalFlRate: 0,
        totalFlaCost: 0,
        totalFlaRate: 0,
        totalFlarCost: 0,
        totalFlarRate: 0,
        totalFlaroCost: 0,
        totalFlaroRate: 0,
        totalLaborCost: 0,
        totalLaborCostRate: 0,
        totalLandRent: 0,
        totalLandRentRate: 0,
        totalNetSales: 0,
        totalPurchase: 0,
        totalPurchaseRate: 0,
        totalRecruitmentCost: 0,
        totalRecruitmentCostRate: 0,
        totalUtilityCosts: 0,
        totalUtilityCostsRate: 0,
        waterFee: 0,
        waterFeeRate: 0,
    }]);
    const [getIsLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        apiGetYearlyBalaceDetailTable(
            orgCode,
            yearlyStartDate,
            yearlyEndDate,
            categoryAry
        ).then((response: TypeIncomeAndExpenditureScheduleMonthly[]) => {
            console.log(response,'???')
            if(response && response[0] && response[0] !== null){
                setIncomeAndExpenditureScheduleMonthly(response);
            }
            setIsLoading(false);
        }).catch((err) => { console.log(err, 'error') });
    }, [orgCode, yearlyStartDate, yearlyEndDate, categoryAry]);

    return {
        incomeAndExpenditureScheduleMonthly, setIncomeAndExpenditureScheduleMonthly, getIsLoading
    };
};

export const useDownload = () => {
    const downloadCsv = (data: any) => {
        downloadIncomeAndExpenditureScheduleMonthlyCsv(data, '【月別】収支明細表.csv');
    };

    const downloadExcel = (data: any) => {
        downloadIncomeAndExpenditureScheduleMonthlyExcel(data, '【月別】収支明細表.xlsx');
    };

    return {
        downloadCsv,
        downloadExcel
    };
};

export default useIncomeAndExpenditureScheduleMonthly;