import React from 'react';

import { productColor, textColor } from 'components/styles';
import BaseButton, { ButtonProps } from './BaseButton';

/**
 * プライマリボタン
 *
 * @see https://sketch.cloud/s/2b3Pe/a/k9qkrA
 */
const PrimaryButton: React.FC<ButtonProps> = ({
  text, onClick, disabled, ghost, children, fullwidth = false, minWidth,
}) => (
  <BaseButton
    color={{
      baseColor: productColor.primary,
      textColor: textColor.inversed,
      ghostColor: productColor.primaryP30,
      ghostTextColor: productColor.primary,
      hoverColor: productColor.primaryP20,
      focusColor: productColor.primaryP30,
    }}
    text={text}
    onClick={onClick}
    disabled={disabled}
    ghost={ghost}
    fullwidth={fullwidth}
    minWidth={minWidth}
  >
    {children}
  </BaseButton>
);

export default PrimaryButton;
