/* eslint-disable max-len */
import {
  useState, useCallback, useEffect, Dispatch,
} from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';
import StaffDomain from 'domain/staff';
import { postStaffInfo } from 'api/staff';
import { Auth } from 'aws-amplify';
import { SessionStorageKey } from 'utility/constants';
import { photoPathByStaffCode } from 'hooks/usePhotoPath';
import { ErrorMessage } from 'utility/messages';

export const useStaffAddForm = (
  initialStaffDomain: StaffDomain,
  isEditMode: boolean,
  setIsLoading: Dispatch<React.SetStateAction<boolean>>,
  menuType: string,
  isEditingLoginUser: boolean,
) => {
  const history = useHistory();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmDeleteShiftModalOpen, setConfirmDeleteShiftModalOpen] = useState(false);
  const [detailMode, setDetailMode] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const onSubmit = async (values: StaffDomain, ) => {
    if (formik.values.staffName === '') {
      errorNotification(ErrorMessage.Common.REQUIRED_INPUT('姓・名'));
      return;
    }
    if (menuType!=='1' && formik.values.staffCode === '') {
      errorNotification(ErrorMessage.Common.REQUIRED_INPUT('スタッフコード'));
      return;
    }

    if (formik.values.hireDate === undefined) {
      errorNotification(ErrorMessage.Common.REQUIRED_INPUT('入社日'));
      return;
    }

    // ログインユーザー自身のメールアドレスは削除不可（Cognitoのユーザープールから削除されてしまうため）
    if (isEditingLoginUser && !formik.values.mail) {
      errorNotification(ErrorMessage.Common.REQUIRED_INPUT('メールアドレス'));
      return;
    }

    // 確認ダイアログ
    if (!confirmModalOpen && !confirmDeleteShiftModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    setConfirmModalOpen(false);
    setIsLoading(true);

    try {

      const response = await postStaffInfo(
        isEditMode ? 1 : 0,
        menuType,
        values.getStaffInfoPostData(isEditMode),
        confirmDeleteShiftModalOpen,
      );

      // 退職日の翌日以降のシフトがある
      if(response.isExistsShift) {
        // シフト削除確認ダイアログを表示
        setIsLoading(false);
        setConfirmDeleteShiftModalOpen(true);
        return;
      }

      setConfirmDeleteShiftModalOpen(false);

      const updatedStaff = new StaffDomain(response.staff);

      if (!isEditMode) {
        sessionStorage.setItem('selectedOrgCodeStaffMaster', response.staff.orgCode);
      } else {
        // Update organization info when update
        formik.setFieldValue('staff', updatedStaff.orgCode);
        formik.setFieldValue('editStaffCode', updatedStaff.editStaffCode);
        formik.setFieldValue('orgName', updatedStaff.orgName);
        formik.setFieldValue('orgApplyDate', updatedStaff.orgApplyDate);
        formik.setFieldValue('organizationDataList', updatedStaff.organizationDataList);
        formik.setFieldValue('empApplyDate', updatedStaff.empApplyDate);
        formik.setFieldValue('employmentDataList', updatedStaff.employmentDataList);
        formik.setFieldValue('salaryList', updatedStaff.salaryList);
        formik.setFieldValue('managerList', updatedStaff.managerList);
        formik.setFieldValue('managerDomainList', updatedStaff.managerDomainList);

        // ログインユーザー自身を編集した場合
        if (isEditingLoginUser) {
          // スタッフ名を変更
          if(updatedStaff.staffName !== sessionStorage.getItem(SessionStorageKey.LoginUser.STAFF_NAME)) {
            // ログイン情報のスタッフ名を更新
            sessionStorage.setItem(SessionStorageKey.LoginUser.STAFF_NAME, updatedStaff.staffName);
          }
          // メールアドレスを変更
          if(updatedStaff.mail !== sessionStorage.getItem(SessionStorageKey.LoginUser.MAIL)) {
          // ログイン情報のメールアドレスを更新
            sessionStorage.setItem(SessionStorageKey.LoginUser.MAIL, updatedStaff.mail);
            // Cognitoの認証ユーザー情報を再取得
            // NOTE: `bypassCache: true`でキャッシュに保存されているユーザー情報を無視し、最新のユーザー情報を取り直す
            await Auth.currentAuthenticatedUser({ bypassCache: true });
          }
          // スタッフコードを変更
          if(updatedStaff.staffCode !== sessionStorage.getItem(SessionStorageKey.LoginUser.STAFF_CODE)) {
            // ログイン情報のスタッフコードを更新
            sessionStorage.setItem(SessionStorageKey.LoginUser.STAFF_CODE, updatedStaff.staffCode);
            // ログイン情報の顔写真ファイルパスを更新
            const photoPath = photoPathByStaffCode(updatedStaff.staffCode, '1.jpg');
            sessionStorage.setItem(SessionStorageKey.LoginUser.PHOTO_PATH, photoPath);
            // スタッフコードが変更された場合はスタッフ一覧に戻す
            // NOTE: URLパラメータが古いスタッフコードのままなので、ブラウザを再表示するとスタッフ情報を取得できない
            history.goBack();
          }
        }
      }
      setIsLoading(false);
      successNotification(isEditMode ? '更新しました。' : '登録しました。');

      if (!isEditMode && response) {
        history.goBack();
      }

    } catch (error) {
      setConfirmModalOpen(false);
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.map((item: { defaultMessage: string; }) => errorNotification(item.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };

  const formik = useFormik({
    initialValues: initialStaffDomain,
    onSubmit,
  });

  useEffect(() => {
    formik.setValues(initialStaffDomain);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStaffDomain]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmDeleteShiftModal = useCallback(() => {
    setConfirmDeleteShiftModalOpen(false);
  }, []);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    confirmDeleteShiftModalOpen,
    closeConfirmDeleteShiftModal,
  };
};

export default useStaffAddForm;
