import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import HolidayManagementForm from 'components/organismos/master/attend/HolidayManagementForm';

const HolidayManagementPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="休暇実績"
  >
    <HolidayManagementForm />
  </SidebarTemplate>
);


export default HolidayManagementPage;
