import { useState, useMemo, useCallback } from 'react';

import * as api from 'api/staff';
import ToastNotification from 'components/molecules/ToastNotification';
import StaffDomain from 'domain/staff';
import useToastNotification from 'hooks/useToastNotification';
import { OrgStatusType } from 'utility/constants';

export const useStaffDelete = (fetchData: () => Promise<void>) => {
  const [deleteTargetStaff, setDeleteTargetStaff] = useState<StaffDomain|null>(null);
  const { successNotification, errorNotification } = useToastNotification();

  const isOpenDeleteModal = useMemo(() => !!deleteTargetStaff, [deleteTargetStaff]);

  const closeDeleteModal = useCallback(() => setDeleteTargetStaff(null), []);

  const onSubmitDeleteStaff = useCallback(() => {
    if (!deleteTargetStaff) {
      return;
    }
    api.deleteStaff(deleteTargetStaff.staffCode).then(() => {
      setDeleteTargetStaff(null);
      successNotification('削除しました。');
      // 削除後にデータを再取得
      fetchData();
    }).catch((error) => {
      setDeleteTargetStaff(null);
      if (error.response && error.response.data && error.response.data.errors) {
        let msgError = '';
        error.response.data.errors.map((item: { defaultMessage: string; }) => {
          msgError += `${item.defaultMessage} \n`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [deleteTargetStaff, errorNotification, fetchData, successNotification]);

  return {
    deleteTargetStaff,
    setDeleteTargetStaff,
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteStaff,
  };
};


export const useDownload = (
  orgCode: string,
  executiveId: string,
  employmentId: string,
  stateStatus: Array<number>,
  categoryArr: Array<string>,
  orgStatus: OrgStatusType,
) => {
  const downloadStaffCsv = useCallback(() => {
    if (stateStatus.length === 0) {
      ToastNotification.error('状態を選択してください。');
      return;
    }
    api.downloadStaffCsv(
      orgCode,
      executiveId,
      employmentId,
      stateStatus,
      categoryArr,
      orgStatus,
    );
  }, [categoryArr, employmentId, executiveId, orgCode, stateStatus, orgStatus]);

  const downloadStaffManagerCsv = useCallback(() => {
    if (stateStatus.length === 0) {
      ToastNotification.error('状態を選択してください。');
      return;
    }
    api.downloadStaffManagerCsv(
      orgCode,
      executiveId,
      employmentId,
      stateStatus,
      categoryArr,
      orgStatus,
    );
  }, [categoryArr, employmentId, executiveId, orgCode, stateStatus, orgStatus]);

  const downloadStaffSalaryCsv = useCallback(() => {
    if (stateStatus.length === 0) {
      ToastNotification.error('状態を選択してください。');
      return;
    }
    api.downloadStaffSalaryCsv(
      orgCode,
      executiveId,
      employmentId,
      stateStatus,
      categoryArr,
      orgStatus,
    );
  }, [categoryArr, employmentId, executiveId, orgCode, stateStatus, orgStatus]);

  return {
    downloadStaffCsv,
    downloadStaffManagerCsv,
    downloadStaffSalaryCsv,
  };
};

export default {};
