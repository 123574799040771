/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import moment from 'moment';

import { grayScale, textColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import FlexBox from 'components/atoms/FlexBox';
import BodyText from 'components/atoms/BodyText';
import Checkbox from 'components/molecules/Checkbox';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Separater from 'components/atoms/Separator';
import DateRangePicker from 'components/molecules/DateRangePicker';

import ManagerDomain from 'domain/manager';
import { useManagerEdit } from './hooks';

const styles = {
  undecidedCheckBox: css({
    padding: '6px 8px',
  }),
};

const ManagerEdit: React.FC<{
  manager: ManagerDomain;
  setManager: (manager: ManagerDomain) => void;
  deleteManager: () => void;
  positionFixed?: boolean;
}> = ({
  manager, deleteManager, setManager, positionFixed = false,
}) => {
  const {
    isEndDateUndecided,
    toggleEndDateUndecided,
    changeStartDate,
    changeEndDate,
  } = useManagerEdit(manager, setManager);
  return (
    <React.Fragment>
      <FlexBox>
        <FlexBoxItem>
          <Icon type="avatar" color={grayScale.gray100} size="30.5px" />
        </FlexBoxItem>

        <FlexBoxItem grow={1} marginLeft="4px">
          <FlexBox>
            <FlexBoxItem>
              <BodyText>
                {manager.staffName}
              </BodyText>
            </FlexBoxItem>

            <FlexBoxItem marginLeft="auto">
              <IconLabelButton
                iconType="delete"
                text="削除"
                onClick={deleteManager}
                padding=""
              />
            </FlexBoxItem>
          </FlexBox>
          <BodyText size="sm" color={textColor.subText01}>
            {manager.staffCode}
          </BodyText>
        </FlexBoxItem>
      </FlexBox>

      <div css={styles.undecidedCheckBox}>
        <FlexBox>
          <FlexBoxItem>
            <BodyText>期間</BodyText>
          </FlexBoxItem>

          <FlexBoxItem>
            <DateRangePicker
              startDate={moment(manager.startDate).isValid()
                ? moment(manager.startDate).toDate()
                : new Date()}
              setStartDate={changeStartDate}
              endDate={manager.endDate
                ? moment(manager.endDate).toDate()
                : undefined}
              setEndDate={changeEndDate}
              marginLeft="8px"
              endDateDisabled={isEndDateUndecided}
              positionFixed={positionFixed}
            />
          </FlexBoxItem>

          <FlexBoxItem marginLeft="auto">
            <Checkbox
              id={JSON.stringify(manager)}
              name={JSON.stringify(manager)}
              label="未定"
              value="check"
              checked={isEndDateUndecided}
              onChange={toggleEndDateUndecided}
            />
          </FlexBoxItem>
        </FlexBox>
      </div>

      <Separater margin="12px 0" />
    </React.Fragment>
  );
};

export default ManagerEdit;
