import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import ShiftPatternAddForm from 'components/organismos/master/labor/shiftPattern/ShiftPatternAddForm';
import { useParams } from 'react-router-dom';

const ShiftPatternAddPage: React.FC = () => {
  const {
    shiftPatternId,
    typeButton,
  } = useParams();
  let title = '';
  if (typeButton === 'copy') {
    title = 'シフトパターン複製';
  } else if (typeButton === 'edit') {
    title = 'シフトパターン編集';
  }
  return (
    <SidebarTemplate pageTitle={shiftPatternId ? title : 'シフトパターン作成'}>
      <ShiftPatternAddForm />
    </SidebarTemplate>
  );
};

export default ShiftPatternAddPage;
