/** @jsx jsx */
import React, { ComponentProps } from 'react';
import { css, jsx } from '@emotion/core';

import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBox from 'components/atoms/FlexBox';
import FormDescription from 'components/atoms/Form/FormDescription';
import Select from 'components/atoms/Select';

const styles = {
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  labelLong: css({
    flexBasis: '180px',
    paddingRight: '15px',
  }),
  select: css({
    flexGrow: 4,
    minWidth: '110px',
    // zIndex: 1,
  }),
};

/**
 * 選択式フォーム
 */
const SelectForm: React.FC<{
  label: string;
  description?: string;
  readOnly?: boolean;
  islabelLong?: boolean;
  placeholder?: string;
} & ComponentProps<typeof Select> > = ({
  label, name, value, options, setValue, description, isMulti, readOnly = false,
  islabelLong = false, placeholder,
}) => (
  <div css={styles.container}>
    <FlexBox>
      <div css={islabelLong ? styles.labelLong : styles.label}>
        <FormLabel
          label={label}
        />
      </div>
      <div css={styles.select}>
        <Select
          name={name}
          value={value}
          setValue={setValue}
          options={options}
          isMulti={isMulti}
          readOnly={readOnly}
          placeholder={placeholder}
        />
      </div>
    </FlexBox>
    <FlexBox>
      <div css={styles.label} />
      <div css={styles.select}>
        {description
            && <FormDescription description={description} />}
      </div>
    </FlexBox>
  </div>
);

export default SelectForm;
