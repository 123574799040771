export interface JournalMasterItem {
      journalId: string;
      journalCode: string;
      journalName: string;
      debitAccountTitleCode: string;
      debitAccountTitleName: string;
      debitAccountTitleSubCode: string;
      debitAccountTitleSubName: string;
      debitAccountTitleId: string;
      debitAccountTitleSubId: string;
      creditAccountTitleCode: string;
      creditAccountTitleName: string;
      creditAccountTitleSubCode: string;
      creditAccountTitleSubName: string;
      creditAccountTitleId: string;
      creditAccountTitleSubId: string;
      debitTaxType: number;
      creditTaxType: number;
      debitTaxRate: string;
      creditTaxRate: string;
      isAll: boolean;
      isEdit: boolean;
    }
    
    export default class JournalMasterItemDomain {
      constructor(private rawData: JournalMasterItem) {
        // do nothing
      }
    
      static generateInitial(): JournalMasterItemDomain {
        return new JournalMasterItemDomain({
            journalId: '',
            journalCode: '',
            journalName: '',
            debitAccountTitleCode: '',
            debitAccountTitleName: '',
            debitAccountTitleSubCode: '',
            debitAccountTitleSubName: '',
            debitAccountTitleId: '',
            debitAccountTitleSubId: '',
            creditAccountTitleCode: '',
            creditAccountTitleName: '',
            creditAccountTitleSubCode: '',
            creditAccountTitleSubName: '',
            creditAccountTitleId: '',
            creditAccountTitleSubId: '',
            debitTaxType: 0,
            creditTaxType: 0,
            debitTaxRate: '',
            creditTaxRate: '',
            isAll: false,
            isEdit: false,
        });
      }
    
      getRawData(): JournalMasterItem {
        return this.rawData;
      }
    
      get journalId(): string {
        return this.rawData.journalId;
      }
    
      set journalId(journalId: string) {
        this.rawData.journalId = journalId;
      }
    
      get journalCode(): string {
        return this.rawData.journalCode;
      }
    
      set journalCode(journalCode: string) {
        this.rawData.journalCode = journalCode;
      }
    
      get journalName(): string {
        return this.rawData.journalName;
      }
    
      set journalName(journalName: string) {
        this.rawData.journalName = journalName;
      }

      get debitAccountTitleCode(): string {
        return this.rawData.debitAccountTitleCode;
      }
    
      set debitAccountTitleCode(debitAccountTitleCode: string) {
        this.rawData.debitAccountTitleCode = debitAccountTitleCode;
      }

      get debitAccountTitleName(): string {
        return this.rawData.debitAccountTitleName;
      }
    
      set debitAccountTitleName(debitAccountTitleName: string) {
        this.rawData.debitAccountTitleName = debitAccountTitleName;
      }

      get debitAccountTitleSubCode(): string {
        return this.rawData.debitAccountTitleSubCode;
      }
    
      set debitAccountTitleSubCode(debitAccountTitleSubCode: string) {
        this.rawData.debitAccountTitleSubCode = debitAccountTitleSubCode;
      }

      get debitAccountTitleSubName(): string {
        return this.rawData.journalName;
      }
    
      set debitAccountTitleSubName(debitAccountTitleSubName: string) {
        this.rawData.debitAccountTitleSubName = debitAccountTitleSubName;
      }

      get debitAccountTitleId(): string {
        return this.rawData.debitAccountTitleId;
      }
    
      set debitAccountTitleId(debitAccountTitleId: string) {
        this.rawData.debitAccountTitleId = debitAccountTitleId;
      }

      get debitAccountTitleSubId(): string {
        return this.rawData.debitAccountTitleSubId;
      }
    
      set debitAccountTitleSubId(debitAccountTitleSubId: string) {
        this.rawData.debitAccountTitleSubId = debitAccountTitleSubId;
      }

      get debitTaxType(): number {
        return this.rawData.debitTaxType;
      }
    
      set debitTaxType(debitTaxType: number) {
        this.rawData.debitTaxType = debitTaxType;
      }

      get creditAccountTitleCode(): string {
        return this.rawData.creditAccountTitleCode;
      }
    
      set creditAccountTitleCode(creditAccountTitleCode: string) {
        this.rawData.creditAccountTitleCode = creditAccountTitleCode;
      }

      get creditAccountTitleName(): string {
        return this.rawData.creditAccountTitleName;
      }
    
      set creditAccountTitleName(creditAccountTitleName: string) {
        this.rawData.creditAccountTitleName = creditAccountTitleName;
      }

      get creditAccountTitleSubCode(): string {
        return this.rawData.creditAccountTitleCode;
      }
    
      set creditAccountTitleSubCode(creditAccountTitleSubCode: string) {
        this.rawData.creditAccountTitleSubCode = creditAccountTitleSubCode;
      }

      get creditAccountTitleSubName(): string {
        return this.rawData.creditAccountTitleCode;
      }
    
      set creditAccountTitleSubName(creditAccountTitleSubName: string) {
        this.rawData.creditAccountTitleSubName = creditAccountTitleSubName;
      }

      get creditAccountTitleId(): string {
        return this.rawData.creditAccountTitleId;
      }
    
      set creditAccountTitleId(creditAccountTitleId: string) {
        this.rawData.creditAccountTitleId = creditAccountTitleId;
      }

      get creditAccountTitleSubId(): string {
        return this.rawData.creditAccountTitleSubId;
      }
    
      set creditAccountTitleSubId(creditAccountTitleSubId: string) {
        this.rawData.creditAccountTitleSubId = creditAccountTitleSubId;
      }

      get creditTaxType(): number {
        return this.rawData.creditTaxType;
      }
    
      set creditTaxType(creditTaxType: number) {
        this.rawData.creditTaxType = creditTaxType;
      }

      get debitTaxRate(): string {
        return this.rawData.debitTaxRate;
      }
    
      set debitTaxRate(debitTaxRate: string) {
        this.rawData.debitTaxRate = debitTaxRate;
      }

      get creditTaxRate(): string {
        return this.rawData.creditTaxRate;
      }
    
      set creditTaxRate(creditTaxRate: string) {
        this.rawData.creditTaxRate = creditTaxRate;
      }
    
      set isAll(isAll: boolean) {
        this.rawData.isAll = isAll;
      }
    
      get isEdit(): boolean {
        return this.rawData.isEdit;
      }
    }
    