/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { textFontSize } from 'components/styles';

const styles = {
  wrapper: css({
    display: 'block',
  }),
  container: css({
    paddingInlineStart: 0,
    listStyle: 'none',
    columnCount: 2,
  }),
  title: css({
    fontSize: textFontSize.md,
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
  }),
  line: css({
    marginBottom: '12px',
  }),
  value: css({
    marginRight: '6px',
    fontSize: textFontSize.re,
  }),
  label: css({
    marginLeft: '6px',
    fontSize: textFontSize.re,
  }),
};

interface ListItems {
  label: string;
  value: string;
}

const ListVerticalSeperator: React.FC<{
  title?: string,
  items: Array<ListItems>;
}> = ({ title, items }) => (
  <div css={styles.wrapper}>
    <h2 css={styles.title}>{title}</h2>
    <ul css={styles.container}>
      {items.map((item) => (
        <li css={styles.line} key={item.value}>
          <span css={styles.value}>
            {item.value}
          </span>
          <span css={styles.label}>
            {item.label}
          </span>
        </li>
      ))}
    </ul>
  </div>
);

export default ListVerticalSeperator;
