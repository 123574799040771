import React, { useEffect } from 'react';

import ManagerDomain from 'domain/manager';

import TwoColumnLayout from 'components/atoms/layout/TwoColumnLayout';
import OrganizationManagerList from 'components/organismos/organization/OrganizationManagerList';
import OrganizationManagerAddModal from 'components/organismos/organization/OrganizationManagerAddModal';

import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { Link } from 'react-router-dom';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { useManagerListHandler, useStaffAddModal } from './hooks';

const OrganizationManagerEditForm: React.FC<{
  managerList: Array<any>|null;
  seniorManagerList: Array<any>|null;
  setManagerList: (arg: Array<any>) => void;
  setSeniorManagerList: (arg: Array<any>) => void;
  isEditMode?: boolean;
  state? :any;
  dispatch?: any;
}> = ({
  managerList, seniorManagerList,
  setManagerList, setSeniorManagerList,
  isEditMode = false,
  state,
  dispatch
}) => {
  const {
    setManager, setSeniorManager, deleteManager, deleteSeniorManager, addManager,
    addSeniorManager, onSubmit,
    confirmModalOpen, closeConfirmModal,
  } = useManagerListHandler(managerList, setManagerList, seniorManagerList, setSeniorManagerList,
    isEditMode);
  const modal = useStaffAddModal();

  useEffect(() => {
    dispatch({ type: 'MANAGER_LIST', payload: managerList })
  }, [managerList])

  useEffect(() => {
    dispatch({ type: 'SENIOR_MANAGER_LIST', payload: seniorManagerList })
  }, [seniorManagerList])

  useEffect(() => {
    setManagerList(state?.managerList);
    setSeniorManagerList(state?.seniorManagerList);
  }, [state?.managerList, state?.seniorManagerList])
  
  return (
    <>
      <TwoColumnLayout>
        {{
          firstColumn: (
            <OrganizationManagerList
              prefix="マネージャー"
              managerList={state?.managerList}
              setManager={setManager}
              deleteManager={deleteManager}
              openModal={() => modal.setOpenManagerAddModal(true)}
            />
          ),
          secondColumn: (
            <OrganizationManagerList
              prefix="シニアマネージャー"
              managerList={state?.seniorManagerList}
              setManager={setSeniorManager}
              deleteManager={deleteSeniorManager}
              openModal={() => modal.setOpenSeniorManagerAddModal(true)}
            />
          ),
        }}
      </TwoColumnLayout>

      {/* マネージャーの追加モーダル */}
      <OrganizationManagerAddModal
        open={modal.openManagerAddModal}
        openHandler={modal.setOpenManagerAddModal}
        addManagerList={addManager}
      />

      {/* シニアマネージャーの追加モーダル */}
      <OrganizationManagerAddModal
        open={modal.openSeniorManagerAddModal}
        openHandler={modal.setOpenSeniorManagerAddModal}
        addManagerList={addSeniorManager}
      />

      {/* <FormSubmitArea>
        {
          userRole !== null && userRole.editable === 1 && (
            <div style={{ marginRight: '12px' }}>
              <Button
                text={isEditMode ? '更新' : 'マネージャー情報を登録して特賃日設定へ'}
                onClick={onSubmit}
              />
            </div>
          )
        }
        <Link to="/organizations">
          <Button
            text="戻る"
            ghost={true}
          />
        </Link>
      </FormSubmitArea>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={onSubmit}
        actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
      /> */}
    </>
  );
};

export default OrganizationManagerEditForm;
