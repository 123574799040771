import _ from "lodash";
import { formatNumber } from "utility/formatUtil";

export interface TimeProductReport {
  /** 商品コード */
  menuCode: string,
  /** 商品名 */
  menuName: string,
  /** 単価 */
	unitPrice: number,
  /** 商品別売上 */
  items: Array<TimeProductReportItemDomain>,
}

export interface TimeProductReportItem {
  pk: {
    /** 商品コード */
    menuCode: string,
    /** 商品名 */
    menuName: string,
    /** 単価 */
    unitPrice: number,
    /** 時間帯 */
    timeZone: string,
  },
  /** 表示順 */
  dispSort: number,
  /** 数量 */
  cnt: number,
  /** 売上 */
  sales: number,
}

/** 時間別帳票 商品別売上  */
export default class TimeProductReportItemDomain {

  private _menuCode: string;
  private _menuName: string;
  private _unitPrice: number;
  private _timeZone: string;
  private _dispSort: number;
  private _cnt: string;
  private _sales: string;

  constructor(private rawData: TimeProductReportItem) {
    this._menuCode  = rawData.pk ? rawData.pk.menuCode : '';
    this._menuName  = rawData.pk ? rawData.pk.menuName : '';
    this._unitPrice = rawData.pk ? rawData.pk.unitPrice : 0;
    this._timeZone  = rawData.pk ? `${Number(rawData.pk.timeZone)}時` : "合計";
    this._dispSort  = rawData.dispSort;
    this._cnt       = formatNumber(rawData.cnt);
    this._sales     = formatNumber(rawData.sales);
  }

  static generateInitial(): TimeProductReportItemDomain {
    return new TimeProductReportItemDomain({
      pk: {
        menuCode: '',
        menuName: '',
        unitPrice: 0,
        timeZone: '',
      },
      dispSort: 0,
      cnt: 0,
      sales: 0,
    });
  }

  /** 表示データ（画面非表示のプロパティを除外） */
  getDispData(): Partial<this>{
    return _.omit(this, ['rawData', '_menuCode', '_menuName', '_unitPrice', '_timeZone', '_dispSort']);
  }

  /** 出力データ（CSV出力など） */
  getOutputData(): Array<Partial<this>> {
    return Object.values(this.getDispData());
  }

  getRawData(): TimeProductReportItem {
    return this.rawData;
  }

  get menuCode(): string {
    return this._menuCode;
  }

  get menuName(): string {
    return this._menuName;
  }

  get unitPrice(): number {
    return this._unitPrice;
  }

  get timeZone(): string {
    return this._timeZone;
  }

  get dispSort(): number {
    return this._dispSort;
  }

  get cnt(): string {
    return this._cnt;
  }

  get sales(): string {
    return this._sales;
  }

}
