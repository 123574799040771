import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import SelectFormVirtical from 'components/molecules/SelectFormVirtical';
import FormField from 'components/atoms/Form/FormField';
import DatePickerForm from 'components/molecules/DatePickerForm';
import TextForm from 'components/molecules/TextForm';
import moment from 'moment';
import TextareaAutoResize from "components/atoms/input/TextareaAutoResize";

const FacilityForm: React.FC<{
  state: any,
  handleOnChange: any,
}> = ({
  state,
  handleOnChange
}) => {

  const reNumberic = (value: string) => {
    const re = /^[0-9\b\n]*$/;
    return re.test(value);
  }

  return (
    <React.Fragment>

      {/* 契約情報 */}
      <FormTitle title="契約情報" />

      <FormField>
        <DatePickerForm 
          dateFormat="yyyy/MM/dd"
          label="賃貸契約開始日"
          date={state.leaseContractStartDate !== '' ? moment(state.leaseContractStartDate).toDate() : undefined}
          changeDate={(date: Date) => {
            if (date !== null) {
              handleOnChange(moment(date).format("YYYY/MM/DD"), 'leaseContractStartDate', moment(date).format("YYYY/MM/DD"))
            }
          }}
        />

        <DatePickerForm
          dateFormat="yyyy/MM/dd"
          label="賃貸契約終了日"
          date={state.leaseContractEndDate !== '' ? moment(state.leaseContractEndDate).toDate() : undefined}
          changeDate={(date: Date) => {
            if (date !== null) {
              handleOnChange(moment(date).format("YYYY/MM/DD"), 'leaseContractEndDate', moment(date).format("YYYY/MM/DD"))
            }
          }}
        />
      </FormField>
      
      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="leaseContractRent"
            label="月契約家賃（税抜）"
            value={state.leaseContractRent}
            maxLength={9}
            onChange={(e) => {
              if (reNumberic(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="leaseContractYears"
            label="賃貸契約年数"
            value={state.leaseContractYears}
            onChange={handleOnChange}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="leaseContractCommonServiceFee"
            label="月契約共益費（税抜）"
            value={state.leaseContractCommonServiceFee}
            maxLength={9}
            onChange={(e) => {
              if (reNumberic(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="leaseContractDeposit"
            label="敷金・保証金"
            value={state.leaseContractDeposit}
            onChange={handleOnChange}
          />
        </div>
      </FormField>

      <FormField displayBlock={true}>
        <div style={{ fontSize: '14px' }}>月その他費用内容</div>
        <TextareaAutoResize
          name="leaseContractOtherPurchaseContent"
          value={state.leaseContractOtherPurchaseContent}
          onChange={handleOnChange}
          rows={5}
        />
      </FormField>

      <FormField displayBlock={true}>
        <div style={{ fontSize: '14px' }}>特記事項</div>
        <TextareaAutoResize
          name="leaseContractNote"
          value={state.leaseContractNote}
          onChange={handleOnChange}
          rows={5}
        />
      </FormField>

      <FormField>
        <TextForm
          name="leaseContractOtherPurchase"
          label="月その他費用（税抜）"
          value={state.leaseContractOtherPurchase}
          maxLength={9}
          onChange={(e) => {
            if (reNumberic(e.target.value)){
              handleOnChange(e)
            }
          }}
        />
      </FormField>

      <FormField>
        <DatePickerForm
          dateFormat="yyyy/MM/dd"
          label="店舗契約検討日"
          date={state.leaseContractReviewDate !== '' ? moment(state.leaseContractReviewDate).toDate() : undefined}
          changeDate={(date: Date) => {
            if (date !== null) {
              handleOnChange(moment(date).format("YYYY/MM/DD"), 'leaseContractReviewDate', moment(date).format("YYYY/MM/DD"))
            }
          }}
        />

        <TextForm
          name="leaseContractStories"
          label="○階建"
          value={state.leaseContractStories}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <SelectFormVirtical
            label="再契約"
            name="leaseRenewedContractType"
            value={String(state.leaseRenewedContractType)}
            setValue={(val) => { handleOnChange (val, 'leaseRenewedContractType', val)}}
            options={[
              { label: '無', value: '0' },
              { label: '有', value: '1' },
            ]}
          />
        </div>

        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="leaseRenewalFee"
            label="更新料（税抜）"
            value={state.leaseRenewalFee}
            maxLength={9}
            onChange={(e) => {
              if (reNumberic(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField displayBlock={true}>
        <div style={{ fontSize: '14px' }}>再契約備考</div>
        <TextareaAutoResize
          name="leaseRenewedContractRemark"
          value={state.leaseRenewedContractRemark}
          onChange={handleOnChange}
          rows={5}
        />
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="leaseContractExpirationNotice"
            label="満期○ケ月前予告"
            value={state.leaseContractExpirationNotice}
            maxLength={4}
            onChange={(e) => {
              if (reNumberic(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="leaseContractExpirationAmortization"
            label="満期償却金（税抜）"
            value={state.leaseContractExpirationAmortization}
            maxLength={9}
            onChange={(e) => {
              if (reNumberic(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField displayBlock={true}>
        <div style={{ fontSize: '14px' }}>満期返還備考</div>
        <TextareaAutoResize
          name="leaseContractExpirationReturnRemark"
          value={state.leaseContractExpirationReturnRemark}
          onChange={handleOnChange}
          rows={5}
        />
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="leaseContractCancelNotice"
            label="中途解約○ケ月前予告"
            maxLength={4}
            value={state.leaseContractCancelNotice}
            onChange={(e) => {
              if (reNumberic(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="leaseContractCancelFee"
            label="中途解約違約損害金（税抜）"
            value={state.leaseContractCancelFee}
            maxLength={9}
            onChange={(e) => {
              if (reNumberic(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField displayBlock={true}>
        <div style={{ fontSize: '14px' }}>中途解約備考</div>
        <TextareaAutoResize
          name="leaseContractCancelRemark"
          value={state.leaseContractCancelRemark}
          onChange={handleOnChange}
          rows={5}
        />
      </FormField>

      <FormField>
        <SelectFormVirtical
          label="原状回復義務"
          name="leaseContractRestoreType"
          value={String(state.leaseContractRestoreType)}
          setValue={(val) => { handleOnChange (val, 'leaseContractRestoreType', val)}}
          options={[
            { label: '無', value: '0' },
            { label: '有', value: '1' },
          ]}
        />
      </FormField>

    </React.Fragment>
  );
};

export default FacilityForm;
