import React from 'react';

import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { Link, useParams } from 'react-router-dom';
import ConfirmModal from 'components/organismos/ConfirmModal';
import FormContents from 'components/atoms/Form/FormContents';
import { useAccountTitleSubMSTDomainForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const AccountTitleSubMSTForm: React.FC = () => {
  const { accountTitleSubId } = useParams();
  let accountTitleId = sessionStorage.getItem('accountTitleId');
  if (accountTitleId === null) {
    accountTitleId = sessionStorage.getItem('accountTitleIdFirst');
  }
  const {
    formik, confirmModalOpen, closeConfirmModal,
  } = useAccountTitleSubMSTDomainForm(String(accountTitleId), String(accountTitleSubId));

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <div>
      <FormContents>
        <p>勘定科目の内訳を設定します。コードと名称を入力してください。</p>
        <form>
          <FormField>
            <TextForm
              name="accountTitleSubCode"
              label="補助科目コード"
              value={formik.values.accountTitleSubCode}
              required={true}
              onChange={formik.handleChange}
            />
          </FormField>
          <FormField>
            <TextForm
              name="accountTitleSubName"
              label="補助科目名"
              value={formik.values.accountTitleSubName}
              required={true}
              onChange={formik.handleChange}
            />
          </FormField>
          <FormSubmitArea>
            {
              roleScreen && roleScreen.editable === 1 && (
                <div style={{ marginRight: '12px' }}>
                  <Button
                    text={accountTitleSubId ? '更新' : '登録'}
                    onClick={formik.handleSubmit}
                  />
                </div>
              )
            }
            <Link to="/accountTitleMST">
              <Button
                text="戻る"
                ghost={true}
              />
            </Link>
          </FormSubmitArea>
        </form>
      </FormContents>

      <ConfirmModal
        title="確認メッセージ"
        content={accountTitleSubId ? '更新します。よろしいですか？' : '登録します。よろしいですか？'}
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.submitForm}
        items={[]}
      />
    </div>
  );
};

export default AccountTitleSubMSTForm;
