import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as api from 'api/monthlyReport';
import moment from 'moment';

export type ResDataType = { targetDate: string, status: number, airpayAmount: number, cashVoucherPaymentTotalAmount: number, kakeuriAmount: number, pettyWithdrawalDiff: number, voidCnt: number };

const useMonthlySubmissionConfirmation = (orgCode: string, categoryAry: any) => {
    let lastMonth = useMemo(() => new Date((new Date()).setMonth((new Date()).getMonth() - 1)), []); // run frist time  
    const monthSelect = sessionStorage.getItem('dateSelectMonthlySubmissionConfirmation') ||  lastMonth;
    const [selectedMonth, setSelectedMonth] = useState<Date>(new Date(monthSelect));
    const [monthlyReportSubmissionConfirmationData, setmonthlyReportSubmissionConfirmationData] = useState<Array<ResDataType>>([]);
    const fromdate = useMemo(()=>new Date(monthSelect),[monthSelect]);
    let monthlyEndDate = useMemo(()=>String(new Date(fromdate.getFullYear(), fromdate.getMonth() + 1, 0)),[fromdate]); // get last date
    let monthlyStartDate = useMemo(()=>String(new Date(fromdate.getFullYear(), fromdate.getMonth(), 1)),[fromdate]); // get first date
    const [getIsLoading, setIsLoading] = useState(false);

    useEffect(() => {  
        const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
        const path = 'daySeparate/ConfirmDocSubmission';
        setIsLoading(true);
        api.getMonthlyConfirmDocSubmission(companyCode, orgCode, monthlyStartDate, monthlyEndDate, path, categoryAry).then((response: Array<ResDataType>) => {
            setmonthlyReportSubmissionConfirmationData(response);
            setIsLoading(false); 

        });

    }, [monthSelect, orgCode, categoryAry]);

    return {
        selectedMonth,
        getIsLoading,
        setSelectedMonth,
        monthlyReportSubmissionConfirmationData
    };
}

export default useMonthlySubmissionConfirmation;