/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import FormContents from 'components/atoms/Form/FormContents';
import {
  Container, Row, Col,
} from 'react-grid-system';
import moment from 'moment';
import BodyText from 'components/atoms/BodyText';
import Button, { PrimaryButton } from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import TextForm from 'components/molecules/TextForm';
import FormField from 'components/atoms/Form/FormField';
import BlockUI from 'components/molecules/BlockUi';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import DatePickerForm from 'components/molecules/DatePickerForm';
import ToastModal from 'components/organismos/ToastModal';
import { TransferApplicationForm } from './hooks';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';

const TransferApplicationPage: React.FC = () => {
  const history = useHistory();
  const {
    formik,
    targetDate,
    toastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    closeConfirmApplyModal,
    isLoading,
    dispStaffName,
    isGetTransferSuccees,
  } = TransferApplicationForm();

  const roleScreen = useSelector((state: Store) => state.auth.roleScreen)

  return (
    <SidebarTemplate pageTitle="振替申請">
      <BlockUI blocking={isLoading}>
        <FormContents>
          <form onSubmit={formik.handleSubmit}>
            <Container fluid={true}>
              <Row>
                <Col md={2} xs={12}>
                  <BodyText>対象日</BodyText>
                </Col>
                <Col md={6} xs={12}>
                  <BodyText>
                    {moment(`${targetDate}`).format('YYYY年MM月DD日(ddd)')}
                  </BodyText>
                </Col>
              </Row>
              <Row>
                <Col md={2} xs={12}>
                  <BodyText>スタッフ</BodyText>
                </Col>
                <Col md={6} xs={12}>
                  <BodyText>
                    {dispStaffName}
                  </BodyText>
                </Col>
              </Row>
              <DatePickerForm
                dateFormat="yyyy年MM月dd日"
                required={true}
                label="振替休日"
                isFullWidth={true}
                date={moment(formik.values.transferHolidayDate).format('YYYYMMDD') === '19700101' ? new Date(targetDate) : formik.values.transferHolidayDate}
                changeDate={(date: Date) => {
                  if (date !== null) {
                    formik.setFieldValue('transferHolidayDate', date);
                  }
                }}
              />
              <Row>
                <Col xs={12}>
                  <FormField>
                    <TextForm
                      required={true}
                      name="applicationReason"
                      label="申請理由"
                      value={String(formik.values.applicationReason)}
                      onChange={formik.handleChange}
                      errorMsg={formik.errors.applicationReason}
                    />
                  </FormField>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <FormSubmitArea>
                    {
                      (isGetTransferSuccees && (formik.values.modifiyStatus === null || formik.values.modifiyStatus === 0
                        || (formik.values.modifiyStatus === 1 && formik.values.applicationStatus !== 0))
                        && roleScreen && (roleScreen.editable === 1 || roleScreen.length === 0)) && (
                        <div style={{ marginRight: '12px' }}>
                          <PrimaryButton
                            ghost={false}
                            text="申請"
                            onClick={() => {
                              formik.handleSubmit();
                              formik.setFieldValue('buttonType', 0);
                            }}
                          />
                        </div>
                      )
                    }
                    {
                      formik.values.applicationStatus === 0
                      && roleScreen && (roleScreen.editable === 1 || roleScreen.length === 0) && (
                      <div style={{ marginRight: '12px' }}>
                        <PrimaryButton
                          ghost={false}
                          text="申請キャンセル"
                          onClick={() => {
                            formik.handleSubmit();
                            formik.setFieldValue('buttonType', 1);
                          }}
                        />
                      </div>
                      )
                    }
                    {
                      formik.values.deleteAvailable && roleScreen && (roleScreen.editable === 1 || roleScreen.length === 0) && (
                      <div style={{ marginRight: '12px' }}>
                        <PrimaryButton
                          ghost={false}
                          text="削除申請"
                          onClick={() => {
                            formik.handleSubmit();
                            formik.setFieldValue('buttonType', 2);
                          }}
                        />
                      </div>
                      )
                    }
                    <Button
                      onClick={() => history.push(sessionStorage.getItem('attendStampList.returnDestination')! || '')}
                      text="戻る"
                      ghost={true}
                    />
                  </FormSubmitArea>
                </Col>
              </Row>
            </Container>
          </form>

          <ToastModal
            open={toastModalOpen}
            closeHandler={() => setToastModalOpen(false)}
            title={toastMessage}
          />

          <ConfirmModal
            open={confirmApplyModalOpen}
            closeHandler={closeConfirmApplyModal}
            onSubmit={formik.handleSubmit}
            title="確認メッセージ"
            // eslint-disable-next-line no-nested-ternary
            content={formik.values.buttonType === 0 ? '申請します。よろしいですか？'
              : formik.values.buttonType === 1 ? '申請をキャンセルします。よろしいですか？' : '削除申請をします。よろしいですか？'}
            // eslint-disable-next-line no-nested-ternary
            submitText={formik.values.buttonType === 0 ? '申請'
              : formik.values.buttonType === 1 ? '申請キャンセル' : '削除申請'}
          />
        </FormContents>

      </BlockUI>
    </SidebarTemplate>
  );
};

export default TransferApplicationPage;
