import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import useToastNotification from 'hooks/useToastNotification';
import { getOrganizationCategoryItem, createOrUpdateOrganizationCategoryItem } from 'api/organizationCategory';
import OrganizationCategoryItemDomain from 'domain/master/general/organizationCategoryItem';
import { useHistory } from 'react-router-dom';


export const useOrganizationCategoryDomainForm = (organizationCategoryMstDetailId?: string) => {
  const [detailMode, setDetailMode] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const history = useHistory();

  // modal confirm
  const [toastModalOpen, setToastModalOpen] = useState(false);

  // Modal alert
  const { successNotification, errorNotification } = useToastNotification();
  const onSubmit = async (values: OrganizationCategoryItemDomain) => {
    if (values.detailCode === null) {
      errorNotification('画面　項目コード設定値');
      return false;
    }
    if (values.detailName === null) {
      errorNotification('画面　項目名設定値');
      return false;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
    } else {
      try {
        const response = await createOrUpdateOrganizationCategoryItem(
          String(organizationCategoryMstDetailId), values.getRawData(),
        );
        if (!response.errors) {
          setConfirmModalOpen(false);
          let successMes = '登録しました。';
          if (organizationCategoryMstDetailId && organizationCategoryMstDetailId !== 'undefined') {
            successMes = '更新しました。';
          }
          successNotification(successMes);
          history.push('/organizationCategory');
        }
      } catch (error) {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.length) {
          const listErr = error.response.data;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage} \n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    }
    return false;
  };

  const formik = useFormik({
    initialValues: OrganizationCategoryItemDomain.generateInitial(),
    validationSchema: false,
    onSubmit,
  });

  useEffect(() => {
    if (organizationCategoryMstDetailId) {
      getOrganizationCategoryItem(organizationCategoryMstDetailId).then((response: any) => {
        if (response) {
          formik.setValues(new OrganizationCategoryItemDomain(response));
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationCategoryMstDetailId]);


  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    setToastModalOpen,
  };
};

export default {
  useOrganizationCategoryDomainForm,
};
