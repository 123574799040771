import React from 'react';
import SalesMenuTypeForm from 'components/organismos/master/sales/SalesMenuTypeForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const SalesMenuType = () => {
  sessionStorage.setItem('selectedOrgCode', sessionStorage.getItem('loginUser.orgCode')!);
  return (
    <SidebarTemplate pageTitle="商品カテゴリマスタ">
      <SalesMenuTypeForm />
    </SidebarTemplate>
  );
};


export default SalesMenuType;
