import moment from 'moment';
import ApiClient from './ApiClient';

export interface AttendAchievementLock {
  id: {
    targetDate: Date,
    orgCode: string,
  },
  orgName: string,
  managerFlag: boolean,
  lockStatus: string,
  errorCount: number,
  alertOvertimeApplicationCount: number,
  alertHolidayworkApplicationCount: number,
  disapprovalCount: number,
}

export interface AttendAchievementLockResponse {
	lockList: AttendAchievementLock[],
	availableDailyShift: boolean,
	availableAttendApplication: boolean,
}

export interface ModifyInfo {
  targetDate: string,
  orgCode: string,
}

export interface ModifyParameter {
  lockList: ModifyInfo[],
  loginStaffName: string
}

export const getOrganizationsTrees = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    ...params,
  };

  // TODO: V4にバージョンアップ予定
  const response = await ApiClient.get(`/v3/organizations/trees/${companyCode}`, {}, query);
  return response.data;
};

export const getAttendAchievementLockList = async (
  targetDateFrom: Date,
  targetDateTo: Date,
  functionType:number,
  orgCode:string,
  categoryAry:any,
  lockStatus: string,
  isError: boolean,
  isAlertOvertime: boolean,
  isAlertHolidaywork: boolean,
  isDisapproval: boolean,
  isLockable: boolean
): Promise<AttendAchievementLockResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }
  const params = {
    targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
    targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
    orgCode,
    functionType,
    loginStaffCode,
    lockStatus,
    isError,
    isAlertOvertime,
    isAlertHolidaywork,
    isDisapproval,
    isLockable
  };
  const response = await ApiClient.get(`/v2/attend/achievementLock/${companyCode}?${appendUrl}`, params);
  console.log(`api-response:${JSON.stringify(response.data)}`);
  return response.data;
};

export const modifyAttendAchievementLock = async (params: ModifyInfo[], url: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffName = sessionStorage.getItem('loginUser.staffName') || '';
  const query = {
    lockList: params,
    loginStaffName: loginStaffName
  } as ModifyParameter;

  const reponse = await ApiClient.post(`/v2/attend/achievementLock/${url}/${companyCode}`, {}, query);
  return reponse.data;
};
