import {
  useEffect, useCallback, useState, useMemo,
} from 'react';
import { getSalesExpensesList, deleteSalesExpenses } from 'api/salesExpensesMST';
import useToastNotification from 'hooks/useToastNotification';
import SalesExpensesMSTDomain from 'domain/master/general/salesExpensesMST';

export const useSalesExpensesMSTList = () => {
  const [salesExpensesMSTList, setSalesExpensesMSTList] = useState<Array<SalesExpensesMSTDomain>>([]);
  const [blocking, setBlocking] = useState(false);
  // データの取得
  const fetchData = useCallback(async () => {
    setBlocking(true);
    try {
      const response = await getSalesExpensesList();
      setSalesExpensesMSTList(response.map((result: any) => new SalesExpensesMSTDomain(result)));
    // eslint-disable-next-line no-empty
    } catch (error) {
      setSalesExpensesMSTList([]);
    }
    setBlocking(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    salesExpensesMSTList, setSalesExpensesMSTList, blocking, fetchData,
  };
};

export const useSalesExpensesDelete = (fetchData: () => Promise<void>) => {
  const [deleteSalesExpenseTarget, setDeleteSalesExpenseTarget] = useState<SalesExpensesMSTDomain|null>(null);
  const { successNotification, errorNotification } = useToastNotification();

  // 削除モーダルの開閉状態
  const isOpenDeleteModal = useMemo(() => !!deleteSalesExpenseTarget, [deleteSalesExpenseTarget]);

  // 削除モーダルを閉じる
  const closeDeleteModal = useCallback(() => setDeleteSalesExpenseTarget(null), []);

  // 削除実行
  const onSubmitDeleteSalesExpense = useCallback(() => {
    if (!deleteSalesExpenseTarget) {
      return;
    }
    deleteSalesExpenses(deleteSalesExpenseTarget.expensesCode).then(() => {
      setDeleteSalesExpenseTarget(null);
      successNotification('削除しました。');
      // 削除後にデータを再取得
      fetchData();
    }).catch((error) => {
      setDeleteSalesExpenseTarget(null);
      if (error.response && error.response.data && error.response.data.defaultMessage) {
        errorNotification(error.response.data.defaultMessage);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [deleteSalesExpenseTarget, errorNotification, fetchData, successNotification]);

  return {
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteSalesExpense,
    deleteSalesExpenseTarget,
    setDeleteSalesExpenseTarget,
  };
};

export default { useSalesExpensesMSTList, useSalesExpensesDelete };
