import ApiClient from 'api/ApiClient';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import useToastNotification from 'hooks/useToastNotification';
import PrimaryButton, { SecondaryButton } from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import {
  RoleAction, Roles, SaleReportStatus, VisibleButtonsType,
} from 'domain/salesReport';
import { SalesSetting } from 'domain/master/general/salesSetting';
import { getTimeSetting } from 'api/salesSetting';

const useApplicationReport = (
  targetDay: Date,
  targetOrgCode: string,
  getStatus: (status: number | null, budgetStatus?: number | null) => void,
  setIsStatusVisible?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const site = sessionStorage.getItem('loginUser.site') || '1';
  const createUser = sessionStorage.getItem('loginUser.staffName') || '';
  const defaultSaleReport = {
    salesReportApplicationPK: {
      companyCode,
      orgCode: targetOrgCode,
      targetDay: '',
      historyNumber: 0,
    },
    status: 3, // default
    createDate: '',
    createUser,
    updateDate: '',
    updateUser: createUser,
    proposeStatus: 0,
    statusBudget: null,
  };
  const [saleReport, setSaleReport] = useState<SaleReportStatus>(defaultSaleReport);
  const { errorNotification, successNotification } = useToastNotification();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [submitMessage, setSubmitMessage] = useState<string>('');
  const [isDetailApplicationDisplay,setIsDetailApplicationDisplay] = useState<boolean>(false);
  const closeModal = () => {
    setModalOpen(false);
  };

  const statusReport = [
    // status 0
    {
      shop: {
        request: true,
        reject: false,
        approve: false,
        withdraw: false,
        reportInstructionTable: false,
      },
      admin: {
        request: false,
        reject: true,
        approve: true,
        withdraw: false,
        reportInstructionTable: false,
      },
      user: {
        request: false,
        reject: false,
        approve: false,
        withdraw: false,
        reportInstructionTable: false,
      },
    },
    // status 1
    {
      shop: {
        request: true,
        reject: false,
        approve: false,
        withdraw: false,
        reportInstructionTable: false,
      },
      admin: {
        request: true,
        reject: false,
        approve: false,
        withdraw: false,
        reportInstructionTable: false,
      },
      user: {
        request: false,
        reject: false,
        approve: false,
        withdraw: false,
        reportInstructionTable: false,
      },
    },
    // status 2
    {
      shop: {
        request: true,
        reject: false,
        approve: false,
        withdraw: false,
        reportInstructionTable: false,
      },
      admin: {
        request: false,
        reject: false,
        approve: false,
        withdraw: true,
        reportInstructionTable: false,
      },
      user: {
        request: false,
        reject: false,
        approve: false,
        withdraw: false,
        reportInstructionTable: false,
      },
    },
    // status 3 (default)
    {
      shop: {
        request: true,
        reject: false,
        approve: false,
        withdraw: false,
        reportInstructionTable: false,
      },
      admin: {
        request: true,
        reject: false,
        approve: false,
        withdraw: false,
        reportInstructionTable: false,
      },
      user: {
        request: false,
        reject: false,
        approve: false,
        withdraw: false,
        reportInstructionTable: false,
      },
    },];

  const [buttonsVisibility, setButtonsVisibility] = useState<VisibleButtonsType[]>(statusReport);

  const disableButtons: RoleAction[] = [
    // status 0
    {
      shop: {
        manualInput: false,
        comment: false,
        attach: false,
        request: false,
        instructAdd: false,
        instructUpdate: true,
        reject: false,
        approve: false,
        withdraw: false,
      },
      admin: {
        manualInput: false,
        comment: false,
        attach: false,
        request: false,
        instructAdd: true,
        instructUpdate: true,
        reject: true,
        approve: true,
        withdraw: false,
      },
      user: {
        manualInput: false,
        comment: false,
        attach: false,
        request: false,
        instructAdd: false,
        instructUpdate: false,
        reject: false,
        approve: false,
        withdraw: false,
      },
    },
    // status 1
    {
      shop: {
        manualInput: true,
        comment: true,
        attach: true,
        request: true,
        instructAdd: false,
        instructUpdate: true,
        reject: false,
        approve: false,
        withdraw: false,
      },
      admin: {
        manualInput: true,
        comment: true,
        attach: true,
        request: true,
        instructAdd: true,
        instructUpdate: true,
        reject: false,
        approve: false,
        withdraw: false,
      },
      user: {
        manualInput: false,
        comment: false,
        attach: false,
        request: false,
        instructAdd: false,
        instructUpdate: false,
        reject: false,
        approve: false,
        withdraw: false,
      },
    },
    // status 2
    {
      shop: {
        manualInput: false,
        comment: false,
        attach: false,
        request: false,
        instructAdd: false,
        instructUpdate: true,
        reject: false,
        approve: false,
        withdraw: false,
      },
      admin: {
        manualInput: false,
        comment: false,
        attach: false,
        request: false,
        instructAdd: true,
        instructUpdate: true,
        reject: false,
        approve: false,
        withdraw: true,
      },
      user: {
        manualInput: false,
        comment: false,
        attach: false,
        request: false,
        instructAdd: false,
        instructUpdate: false,
        reject: false,
        approve: false,
        withdraw: false,
      },
    },
    // status 3 (default when no request)
    {
      shop: {
        manualInput: true,
        comment: true,
        attach: true,
        request: true,
        instructAdd: false,
        instructUpdate: true,
        reject: true,
        approve: true,
        withdraw: true,
      },
      admin: {
        manualInput: true,
        comment: true,
        attach: true,
        request: true,
        instructAdd: true,
        instructUpdate: true,
        reject: true,
        approve: true,
        withdraw: true,
      },
      user: {
        manualInput: false,
        comment: false,
        attach: false,
        request: false,
        instructAdd: false,
        instructUpdate: false,
        reject: false,
        approve: false,
        withdraw: false,
      },
    },
  ];
  const [disableButtonsState, setDisableButtonsState] = useState<RoleAction[]>(disableButtons);

  const getRole = (site: string): Roles => {
    switch (site) {
      case '3':
        return 'admin';
      case '2':
        return 'shop';

      default:
        return 'user';
    }
  };

  const role = getRole(site);

  const canManualInput = () => disableButtonsState[saleReport.status][role].manualInput;
  const canComment = () => disableButtonsState[saleReport.status][role].comment;
  const canAttach = () => disableButtonsState[saleReport.status][role].attach;
  const canInstructUpdate = () => disableButtonsState[saleReport.status][role].instructUpdate;
  const canInstructAdd = () => disableButtonsState[saleReport.status][role].instructAdd;
  const canRequest = () => disableButtonsState[saleReport.status][role].request;
  const canReject = () => disableButtonsState[saleReport.status][role].reject;
  const canApprove = () => disableButtonsState[saleReport.status][role].approve;
  const canWithdraw = () => disableButtonsState[saleReport.status][role].withdraw;

  // New role for button
  const [status, setStatus] = useState<number>(1);
  const [isTurnOff, setIsTurnOff] = useState<boolean>(false);

  const [hiddenBtn, setHiddenBtn] = useState(0);

  const RequestButton: React.FC = () => {
    return hiddenBtn === 1 && (status === 1 || status === null) ? (
      <div style={{ marginLeft: 15 }}>
        <PrimaryButton
          text="申請"
          onClick={() => {
            setSaleReport({ ...saleReport, proposeStatus: 0 });
            setModalOpen(true);
            setSubmitMessage('申請');
          }}
          // disabled={!canRequest()}
        />
      </div>
    ) : (
      <></>
    );
  };
  const ApproveButton: React.FC = () => {    
    return hiddenBtn === 1 && status === 0 ? (
      <div style={{ marginLeft: 15 }}>
        <PrimaryButton
          text="承認"
          onClick={() => {
            setSaleReport({ ...saleReport, proposeStatus: 2 });
            setModalOpen(true);
            setSubmitMessage('承認');            
          }}
          // disabled={!canApprove()}
        />
      </div>
    ) : (
      <></>
    );
  };
  const RejectButton: React.FC = () => {    
    return hiddenBtn === 1 && status === 0 ? (
      <div style={{ marginLeft: 15 }}>
        <SecondaryButton
          text="差し戻し"
          onClick={() => {
            setSaleReport({ ...saleReport, proposeStatus: 1 });
            setModalOpen(true);
            setSubmitMessage('差し戻し');            
          }}
          // disabled={!canReject()}
        />
      </div>
    ) : (
      <></>
    );
  };
  const WithdrawButton: React.FC = () => {    
    return hiddenBtn === 1 && status === 2 ? (
      <div style={{ marginLeft: 15 }}>
        <SecondaryButton
          text="承認取り消し"
          onClick={() => {
            setSaleReport({ ...saleReport, proposeStatus: 1 });
            setModalOpen(true);
            setSubmitMessage('承認取り消し');            
          }}
          // disabled={!canWithdraw()}
        />
      </div>
    ) : (
      <></>
    );
  };

  const ApproveButtonConfirm: React.FC<{
    handleAllSubmit:()=>void,
    isDisabled: boolean,
  }> = ({handleAllSubmit,isDisabled}) => {
    return (
      <ConfirmModal
        open={isModalOpen}
        closeHandler={closeModal}
        title="確認メッセージ"
        content={`${submitMessage}します。よろしいですか？`}
        submitText={submitMessage}
        onSubmit={()=>{
          if (isDisabled) {
            handleAllSubmit()
          }
          submitReport()
        }}
      />
    );
  };

  const postReportApplication = async (status: Number): Promise<SaleReportStatus> => {
    const params = {
      orgCode: targetOrgCode,
      status,
      targetDay: moment(targetDay).format('YYYY-MM-DD'),
      createUser,
      loginStaffCode,
    };
    const { data } = await ApiClient.postJsonData(`/v2/report/application/${companyCode}`, {}, params);
    return data;
  };

  const getReportApplication = async (): Promise<SaleReportStatus> => {
    const query = {
      orgCode: targetOrgCode,
      targetDay: moment(targetDay).format('YYYY-MM-DD'),
    };    
    const { data } = await ApiClient.get(`/v1/report/application/${companyCode}`, query, {});    
    
    return data;
  };

  const getReportSetting = async (): Promise<Omit<SalesSetting, 'useReportApproval'> & {
    useReportApproval: boolean;
  }> => {
    const { data } = await ApiClient.get(`/v1/setting/reports/${companyCode}`, {});
    return data;
  };

  const submitReport = async () => {
    try {
      setModalOpen(false);
      const data = await postReportApplication(saleReport?.proposeStatus || 0);
      setSaleReport(data);
      getStatus(data.status);      

      const dataRule = await getReportSetting();     
      const canStartDateUseReportApproval = targetDay.valueOf() >= moment(dataRule.reportApprovalStartDate).valueOf();
      if (canStartDateUseReportApproval && dataRule.useReportApproval) {                        
        const request = await getReportApplication();                             
        setStatus(request.status);                                         
      }

      successNotification(`${submitMessage}しました。`);
    } catch (error) {
      errorNotification(error.response.data.error);
    }
  };

  useEffect(() => {
    async function getApplicationReport() {
      try {
        const data = await getReportApplication();

        if (data && data.status !== null) {
          setSaleReport(data);
          getStatus(Number(data.status), data.statusBudget);
        } else {
          setSaleReport(defaultSaleReport);
          getStatus(data.status, data.statusBudget);
        }
        console.log('dataTest', data);
      } catch (error) {
        console.log('Server error', error);
      }
    }
    getApplicationReport();
  }, [targetDay, targetOrgCode]);

  useEffect(() => {
    async function reportSetting() {
      try {
        const data = await getReportSetting();              
        const canStartDateUseReportApproval = targetDay.valueOf() >= moment(data.reportApprovalStartDate).valueOf();
        setIsDetailApplicationDisplay(data.useReportApproval && moment(data.reportApprovalStartDate).isSameOrBefore(moment(targetDay)))
        const newButtonsVisibility = buttonsVisibility.map((status) => {
          // only change if not allow to use table
          if (!(canStartDateUseReportApproval && data.useReportApproval)) {
            status[role].request = canStartDateUseReportApproval && data.useReportApproval;
            status[role].approve = canStartDateUseReportApproval && data.useReportApproval;
            status[role].reject = canStartDateUseReportApproval && data.useReportApproval;
            status[role].withdraw = canStartDateUseReportApproval && data.useReportApproval;
          }else{
            status[role].request = statusReport[saleReport.status][role].request;
            status[role].approve = statusReport[saleReport.status][role].approve;
            status[role].reject = statusReport[saleReport.status][role].reject;
            status[role].withdraw = statusReport[saleReport.status][role].withdraw;
          }
          status[role].reportInstructionTable = canStartDateUseReportApproval && data.useReportApproval;
          return status;
        });

        if (!data.useReportApproval) {
          const newDisableButtons = disableButtonsState.map((btn) => {
            btn[role].manualInput = true;
            btn[role].attach = true;
            btn[role].comment = true;
            return btn;
          });                    
          setDisableButtonsState(newDisableButtons);
        }

        if (canStartDateUseReportApproval && data.useReportApproval) {
          console.log('dev a', targetDay.valueOf(), moment(data.reportApprovalStartDate).valueOf(), data.useReportApproval)
          setHiddenBtn(1);
        }else{
          console.log('dev b', targetDay.valueOf(), moment(data.reportApprovalStartDate).valueOf(), data.useReportApproval)
          setHiddenBtn(0);
        }

        // set Label visibility in tab [進捗状況]
        if (setIsStatusVisible) {
          if (canStartDateUseReportApproval && data.useReportApproval) {
            setIsStatusVisible(true);
          } else {
            setIsStatusVisible(false);
          }
        }

        setButtonsVisibility(newButtonsVisibility);
      } catch (error) {
        console.log('Server error', error);
      }
    }
    reportSetting();
  }, [targetDay, targetOrgCode, saleReport]);


  // New handle set role for button
  useEffect(() => {
    async function handleReportSetting() {
      try {
        const data = await getReportSetting();
        const canStartDateUseReportApproval = targetDay.valueOf() >= moment(data.reportApprovalStartDate).valueOf();
        if (canStartDateUseReportApproval && data.useReportApproval) {
          const request = await getReportApplication();

          if (!Object.keys(request).length) {
            setStatus(1);
          } else {
            setStatus(request.status);
          }

          if (request.status === 0 || request.status === 2) {
            setIsTurnOff(true);
          } else {
            setIsTurnOff(false);
          }
        }
      } catch (error) {
        console.log('Server error', error);
      }
    }
    handleReportSetting();
  }, [targetDay, targetOrgCode, status])

  return {
    saleReport,
    setSaleReport,
    canManualInput,
    canComment,
    canAttach,
    canInstructUpdate,
    canInstructAdd,
    canRequest,
    canReject,
    canApprove,
    canWithdraw,
    submitReport,
    isDetailApplicationDisplay,
    RequestButton,
    ApproveButton,
    RejectButton,
    WithdrawButton,
    ApproveButtonConfirm,
    isTableVisibility: buttonsVisibility[saleReport.status][role].reportInstructionTable,        
    isTurnOff,
    status
  };
};

export const downloadOriginalPDF = async (response: any, downloadFileName: string) => {
  const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
  const file = new Blob([bom, response], {
    type: 'application/pdf',
  });
  saveAs(file, downloadFileName);
};


export default useApplicationReport;
