import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import FormContents from 'components/atoms/Form/FormContents';
import ManagerEdit from 'components/organismos/ManagerEdit';
import BodyText from 'components/atoms/BodyText';
import PrimaryButton from 'components/atoms/Button';

import ManagerDomain from 'domain/manager';

const OrganizationManagerList: React.FC<{
  prefix: string;
  managerList: Array<ManagerDomain>|null;
  deleteManager: (index: number) => () => void;
  setManager: (index: number) => (manager: ManagerDomain) => void;
  openModal: () => void;
}> = ({
  prefix, managerList, deleteManager, setManager, openModal,
}) => (
  <React.Fragment>
    <FormTitle
      title={`${prefix}設定`}
    />

    {managerList ? managerList.map((manager, index) => (
      <ManagerEdit
        key={JSON.stringify(manager)}
        manager={manager}
        deleteManager={deleteManager(index)}
        setManager={setManager(index)}
        positionFixed={true}
      />
    )) : <BodyText></BodyText>}

    <div>
      <PrimaryButton
        text={`${prefix}を追加`}
        onClick={openModal}
        fullwidth={false}
      />
    </div>
  </React.Fragment>
);

export default OrganizationManagerList;
