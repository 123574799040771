import React, { useRef, useState, useEffect } from 'react';
import { gridCol } from 'components/styles';
import ReactTooltip from 'react-tooltip';

const DayTable: React.FC<{
  eventScroll: any,
  holidaySubtotalItem: Array<any>,
  subTotalDayList: Array<any>
}> = ({
  eventScroll, holidaySubtotalItem, subTotalDayList,
}) => {
  const refTableScroll = useRef(Object());
  const [offsetWidthTable, setOffsetWidthTable] = useState(0);
  const refTableContent = useRef(Object());
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  useEffect(() => {
    const width = refTableScroll.current ? refTableScroll.current.offsetWidth : 0;
    setOffsetWidthTable(width);
  }, [refTableScroll.current.offsetWidth]);

  useEffect(() => {
    if (refTableContent.current) {
      const width = refTableContent.current.offsetWidth - refTableContent.current.clientWidth;
      setScrollBarWidth(width);
    }
  }, []);

  return (
    <div className="wrap-attend-monthly-list">
      <div style={{ display: 'flex' }}>
        <div className="top-left-area-time">
          <table className="table-condensed nowrap time-table margin-bottom-0 fixedTable" style={{ width: gridCol.grid12 }}>
            <thead>
              <tr>
                <th className="headline-cell-width-120 head-line3-height text-center">
                  ススタッフ
                  <br />
                  コード
                </th>
                <th className="headline-cell-width-120 head-line3-height text-center">スタッフ名</th>
              </tr>
            </thead>
          </table>
        </div>
        <div id="topRightAreaTime" onScroll={eventScroll} style={{ width: 'calc(100% - 241px)', overflow: 'auto' }}>
          <div style={{ width: `${offsetWidthTable + scrollBarWidth}px` }}>
            <table className="table-condensed nowrap time-table margin-bottom-0 fixedTable" id="top-right-time" style={{ width: `${offsetWidthTable}px` }}>
              <thead>

                <tr>
                  <th colSpan={3} className="active headline-cell-width-120 head-line1-height text-center">出勤日数</th>
                  <th
                    colSpan={holidaySubtotalItem && holidaySubtotalItem.length}
                    className="active headline-cell-width-120 head-line1-height text-center border-right-none"
                  >
                    休暇日数
                  </th>
                  <th colSpan={2} className="active headline-cell-width-120 head-line1-height text-center border-left-none">&nbsp;</th>
                </tr>

                <tr>
                  <th className="active headline-cell-width-80 head-line2-height text-center">平日</th>
                  <th className="active headline-cell-width-80 head-line2-height text-center">
                    法定
                    <br />
                    休日
                  </th>
                  <th className="active headline-cell-width-80 head-line2-height text-center">
                    所定
                    <br />
                    休日
                  </th>
                  {
                    holidaySubtotalItem && holidaySubtotalItem.map((item: any, index) => (
                      <th
                        key={String(index)}
                        className="active headline-cell-width-150 head-line2-height text-center"
                        style={{ wordBreak: 'break-all' }}
                      >
                        {item.holidayName}
                        {item.holidayType === 0 && item.isLegal ? '（法定）' : ''}
                        {item.holidayType === 0 && !item.isLegal ? '（所定）' : ''}
                      </th>
                    ))
                  }
                  <th className="active headline-cell-width-80 head-line2-height text-center">遅刻</th>
                  <th className="active headline-cell-width-80 head-line2-height text-center">早退</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div ref={refTableContent} className="table-content">
        <div style={{ display: 'inline-flex', width: gridCol.grid12 }}>
          <div className="bottomLeftAreaTime">
            <table className="table-condensed nowrap time-table margin-bottom-0 fixedTable">
              {
                subTotalDayList.map((subTotalDay: any) => (
                  <tbody key={subTotalDay.staffCode}>
                    <tr>
                      <td className="headline-cell-width-120 head-line1-height text-center">{subTotalDay.staffCode}</td>
                      <td className="headline-cell-width-120 head-line1-height text-center" data-tip={`${subTotalDay.employmentName ? `${subTotalDay.employmentName}<br>` : ''} ${subTotalDay.staffName}<br> ${subTotalDay.staffOrgName ? subTotalDay.staffOrgName : ''}`}>
                        <div className="ellipsis headline-cell-width-120">
                          {subTotalDay.staffName}
                        </div>
                        <ReactTooltip html={true} />
                      </td>
                    </tr>
                  </tbody>
                ))
              }
            </table>
          </div>
          <div id="bottomRightAreaTime" style={{ width: 'calc(100% - 241px)', overflow: 'auto' }} onScroll={eventScroll}>
            <div style={{ width: `${offsetWidthTable}px` }}>
              <table ref={refTableScroll} className="table-condensed nowrap time-table margin-bottom-0 fixedTable" id="bottom-right-time">
                {
                  subTotalDayList.map((subTotalDay: any, index) => (
                    <tbody key={String(index)}>
                      <tr>
                        <td className="headline-cell-width-80 head-line1-height text-center">{subTotalDay.normalWork}</td>
                        <td className="headline-cell-width-80 head-line1-height text-center">{subTotalDay.legalHolidayWork}</td>
                        <td className="headline-cell-width-80 head-line1-height text-center">{subTotalDay.nonLegalHolidayWork}</td>
                        {
                          subTotalDay.attendHolidaySubtotals.map((attendHolidaySubtotal: any, index2: any) => (
                            <td key={String(index2)} className="headline-cell-width-150 head-line1-height text-center">
                              {attendHolidaySubtotal.resultHoliday}
                            </td>
                          ))
                        }
                        <td className="headline-cell-width-80 head-line1-height text-center">{subTotalDay.late}</td>
                        <td className="headline-cell-width-80 head-line1-height text-center">{subTotalDay.earlier}</td>
                      </tr>
                    </tbody>
                  ))
                }
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="content-scroll-bottom" onScroll={eventScroll}>
        <div style={{ width: '241px', float: 'left' }} />
        <div
          id="scrollBottom"
          style={{
            width: 'calc(100% - 241px)', float: 'right', height: '24px', overflow: 'auto',
          }}
        >
          {
          holidaySubtotalItem && <div className="scroll" style={{ width: `${offsetWidthTable + scrollBarWidth}px` }} />
        }
        </div>
      </div>
    </div>
  );
};
export default DayTable;
