import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import moment from 'moment';
import HolidayDomain from 'domain/holiday';
import useToastNotification from 'hooks/useToastNotification';

import { getCalendar, importHoliday } from 'api/holiday';

export const useHolidayAddForm = () => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [holidayList, setHolidayList] = useState<Array<{label:string, value:string}>>([]);
  const { successNotification, errorNotification } = useToastNotification();
  const history = useHistory();
  const year = moment().format('YYYY');

  const onSubmit = async (val: any) => {
    if (val.option === 'selection' && (val.orgCodes === null || val.orgCodes.length === 1)) {
      errorNotification('祝日を割り当てる組織を選択してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    try {
      const targetYear = sessionStorage.getItem('selectedYearHolidayMaster') || year;
      let orgList: string[] = [];
      const postData = val.getRawData();
      if (val.isAll) {
        orgList = ['all'];
        postData.orgCodes = orgList;
      }
      const response = await importHoliday(targetYear, postData.orgCodes);
      if (!response.errors) {
        setConfirmModalOpen(false);
        successNotification('登録しました');
        history.goBack();
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };

  const formik = useFormik({
    initialValues: HolidayDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    const targetYear = sessionStorage.getItem('selectedYearHolidayMaster') || year;
    getCalendar(targetYear).then((publicHolidays) => {
      setHolidayList(publicHolidays.map((ph) => ({
        label: ph.holidayName,
        value: moment(ph.holidayDate).format('YYYY/MM/DD'),
      })));
    })
      .catch((error: any) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.forEach((element: any) => {
            stringErr += `${element.defaultMessage}<br />`;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  return {
    formik,
    holidayList,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
  };
};

export default useHolidayAddForm;
