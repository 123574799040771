import React, { useEffect, useMemo, useState } from 'react';
import * as api from 'api/monthly/hqPointedOut';
import moment from 'moment';

export type ResDataType = {
    eightDay: number,
    oneDay: number,
    orgCode: string,
    org_name: string,
    sevenDay: number,
    total: number,
    twoDay: number,
    unconfirm: number,
}
export type HeaderType = {
    Header: string,
    accessor: string,
    disableSortBy?: boolean,
    sortType?: string,
}
// export class numberIndicationAnalysisPattern {
//     constructor(private rawData: ResDataType[]) { }
//     getRawData(): any {
//         return (this.rawData).map((item: ResDataType) => {
//             return  {rawData:{ ...item, org: String(item.orgCode + " " + item.org_name) }}
//         })
//     }
// }
export class numberIndicationAnalysisPattern {
    org: string;
    oneDay: number;
    twoDay: number;
    sevenDay: number;
    eightDay: number;
    unconfirm: number;
    total: number;
    constructor(rawData: ResDataType) {
        this.org = `${rawData.orgCode} ${rawData.org_name}`
        this.oneDay = rawData.oneDay;
        this.twoDay = rawData.twoDay;
        this.sevenDay = rawData.sevenDay;
        this.eightDay = rawData.eightDay;
        this.unconfirm = rawData.unconfirm;
        this.total = rawData.total;
    }
    getRowData(): any {
        return {
            org: this.org,
            oneDay: this.oneDay,
            twoDay: this.twoDay,
            sevenDay: this.sevenDay,
            eightDay: this.eightDay,
            unconfirm: this.unconfirm,
            total: this.total
        };
    }
}
export const useDowload = () => {
    const downloadNumberIndicationAnalysisCsv = (numberData: any) => {
        api.downloadPointedOutCsv(numberData, '指摘件数分析帳票.csv');
    }
    const downloadNumberIndicationAnalysisExcel = (numberData: any) => {
        api.downloadPointedOutExcel(numberData, "指摘件数分析帳票.xlsx");
    }
    return { downloadNumberIndicationAnalysisCsv, downloadNumberIndicationAnalysisExcel }
}
const useNumberIndicationAnalysis = (orgCode: string, categoryAry: any) => {
    // default date
    const fromdate = useMemo(() => new Date(), []);
    const [numberIndicationAnalysisData, setNumberIndicationAnalysisData] = useState<numberIndicationAnalysisPattern[]>([]);
    const [targetDateFrom, setTargetDateFrom] = useState(String(new Date(fromdate.getFullYear(), fromdate.getMonth() + 1, 0)));
    const [targetDateTo, setTargetDateTo] = useState(String(new Date(fromdate.getFullYear(), fromdate.getMonth(), 1)));
    const [getIsLoading, setIsLoading] = useState(false);

    // get frist day with start month select
    let monthlyStartDate = useMemo(() => {
        let date = new Date(targetDateFrom);
        return String(new Date(date.getFullYear(), date.getMonth(), 1))
    }, [targetDateFrom]);
    // get last day with end month select
    let monthlyEndDate = useMemo(() => {
        let date = new Date(targetDateTo);
        return String(new Date(date.getFullYear(), date.getMonth() + 1, 0))
    }, [targetDateTo]);
    const HandleTransformData = (response: any) => {

    };
    // const targetPeriodData = `対象期間: ${moment(targetDateFrom).format('YYYY/MM/DD')} ~ ${moment(targetDateTo).format('YYYY/MM/DD')}`;
    const targetPeriodData = `対象期間: ${moment(monthlyStartDate).format('YYYY/MM/DD')} ~ ${moment(monthlyEndDate).format('YYYY/MM/DD')}`;
    useEffect(() => {
        const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
        const paths = 'HqPointedOutCnt';
        setIsLoading(true);
        api.getTableNumberIndicationAnalysis(companyCode, orgCode, monthlyStartDate, monthlyEndDate, paths, categoryAry).then((response: Array<ResDataType>) => {
            setIsLoading(false);
            // HandleTransformData(response);
            let resArr = response.map((item: any) => (new numberIndicationAnalysisPattern(item)));
            setNumberIndicationAnalysisData(resArr);
        })
    }, [monthlyStartDate, monthlyEndDate, orgCode, categoryAry]);

    return {
        targetDateFrom,
        setTargetDateFrom,
        targetDateTo,
        setTargetDateTo,
        numberIndicationAnalysisData,
        getIsLoading,
        setNumberIndicationAnalysisData,
        targetPeriodData
    };
}
export default useNumberIndicationAnalysis;