import moment from 'moment';
/**
 * APIのデータ型
 */
export interface WorkingHours {
  companyCode: string;
  createUser: string;
  updateUser: string;
  workingHoursId: string;
  workingHoursPatternCode: string;
	workingHoursPatternName: string;
  targetYear: string;
}

export default class WorkingHoursDomain {
  constructor(private rawData: WorkingHours) {
    // do nothing
  }

  static generateInitial(): WorkingHoursDomain {
    return new WorkingHoursDomain({
      companyCode: '',
      createUser: '',
      updateUser: '',
      workingHoursId: '',
      workingHoursPatternCode: '',
      workingHoursPatternName: '',
      targetYear: moment().format('YYYY')
    });
  }

  getRawData(): WorkingHours {
    return this.rawData;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = String(companyCode);
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set workingHoursId(workingHoursId: string) {
    this.rawData.workingHoursId = String(workingHoursId);
  }

  get workingHoursId(): string {
    return this.rawData.workingHoursId;
  }

  set workingHoursPatternCode(workingHoursPatternCode: string) {
    this.rawData.workingHoursPatternCode = String(workingHoursPatternCode);
  }

  get workingHoursPatternCode(): string {
    return this.rawData.workingHoursPatternCode;
  }

  set workingHoursPatternName(workingHoursPatternName: string) {
    this.rawData.workingHoursPatternName = String(workingHoursPatternName);
  }

  get workingHoursPatternName(): string {
    return this.rawData.workingHoursPatternName;
  }

  get createUser():string {
    return this.rawData.createUser;
  }

  set createUser(createUser:string) {
    this.rawData.createUser = createUser;
  }


  get updateUser():string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser:string) {
    this.rawData.updateUser = updateUser;
  }
  
  get targetYear():string {
    return this.rawData.targetYear;
  }

  set targetYear(targetYear:string) {
    this.rawData.targetYear = targetYear;
  }
}
