import ApiClient from './ApiClient';

export const getStampList = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/attendModifier/stampList/${companyCode}`, {}, params);
  return response.data;
};

export const createOrUpdateStampModification = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v3/attendModifier/stamp/${companyCode}`, {}, params);
  return response.data;
};