import ApiClient from 'api/ApiClient';

export interface DaySeparateSalesManagementCos {
    cumulativeProfit: number;
    cumulativeProfitRate: number;
    cumulativeSales: number;
    cumulativeSalesAchievementRate: number;
    cumulativeSalesBudget: number;
    customerUnitPrice: number;
    customers: number;
    diffCumulativeNetSales: number;
    diffNetSales: number;
    drinkCost: number;
    employeeSalary: number;
    expensesAchievementRate: number;
    foodAchievementRate: number;
    foodCost: number;
    foodLaborAchievementRate: number;
    guestCnt: number;
    laborAchievementRate: number;
    netSales: number;
    othreExpenses: number;
    partSalary: number;
    profit: number;
    profitRate: number;
    salesAchievementRate: number;
    salesBudget: number;
    status: number;
    targetDate: Date;
    totalFoodDrinkCost: number;
    totalFoodLaborCost: number;
    totalLaborCost: number;
    totalSales: number;
  }

export const apiGetDaySeparateSalesManagementCos = async (
  path: string,
  orgCode: string,
  monthlyStartDate: string,
  monthlyEndDate: string,
  categoryAry: any,
)
  : Promise<DaySeparateSalesManagementCos> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    loginStaffCode,
    functionType: 3,
  };

  let appendUrl = '';
  if (Array.isArray(categoryAry)) {
    categoryAry.forEach((cateArr: any, index: number) => {
      appendUrl += `&categoryAry=${cateArr.join(',')}`;
    });
  }

  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/${path}/${companyCode}?${appendUrl}`, params);
  return response.data;
};
