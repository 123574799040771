import React, { useEffect } from 'react';

import StampAccordion from './StampAccordion';
import HolidayAccordion from './HolidayAccordion';
import ApprovedTransferDomain from '../domain/approvedTransferDomain';
import ApprovedStampDomain from '../domain/approvedStampDomain';
import ApprovedHolidayDomain from '../domain/approvedHolidayDomain';
import ApprovedOvertimeDomain from '../domain/approvedOvertimeDomain';
import ApprovedHolidayWorkDomain from '../domain/approvedHolidayWorkDomain';
import OvertimeAccordion from './OvertimeAccordion';
import HolidayWorkAccordion from './HolidayWorkAccordion';
import TransferAccordion from './TransferAccordion';

const ApprovedTab: React.FC<{
  stampList: ApprovedStampDomain[];
  holidayList: ApprovedHolidayDomain[];
  overtimeList: ApprovedOvertimeDomain[];
  holidayWorkList: ApprovedHolidayWorkDomain[];
  transferList: ApprovedTransferDomain[];
}> = ({
  stampList,
  holidayList,
  overtimeList,
  holidayWorkList,
  transferList,
}) => {
  useEffect(() => {
    console.log('ApprovedTab');
  }, [
    stampList,
    holidayList,
    overtimeList,
    holidayWorkList,
    transferList,
  ]);

  return (
    <div>
      <StampAccordion list={stampList} />

      <HolidayAccordion list={holidayList} />

      <OvertimeAccordion list={overtimeList} />

      <HolidayWorkAccordion list={holidayWorkList} />

      <TransferAccordion list={transferList} />
    </div>
  );
};

export default ApprovedTab;
