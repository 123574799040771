import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import SelectFormVirtical from 'components/molecules/SelectFormVirtical';
import FormField from 'components/atoms/Form/FormField';
import { PrimaryButton } from "components/atoms/Button";
import TextForm from 'components/molecules/TextForm';
import TextareaAutoResize from "components/atoms/input/TextareaAutoResize";
import FormHelp from 'components/atoms/Form/FormHelp';
import usePrefectureOptions from 'hooks/usePrefectureOptions';

const StoreBasicInformationForm: React.FC<{
  state: any
  handleOnChange: any
  handleOnBlur: any
  addName: any
  tableColumnsName: any
  addRow: any
}> = ({
  state,
  handleOnChange,
  handleOnBlur,
  addName,
  tableColumnsName,
  addRow
}) => {
  
  const prefectureOptions = usePrefectureOptions();

  const reAlphaNumbericSymbols = (value: string) => {
    const re =  /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\b\n ]*$/;
    return re.test(value);
  }

  const reNumberic = (value: string) => {
    const re = /^[0-9\b\n]*$/;
    return re.test(value);
  }

  const reDecimalNumber = (value: string) => {
    const re = /^([0-9]{0,5}(\.[0-9]{0,2})?)?$/;
    return re.test(value);
  }

  return (
    <React.Fragment>
      
      {/* 所在地 */}
      <FormTitle title="所在地" />

      <FormField>
        <TextForm
          name="zipCode"
          label="郵便番号"
          value={state.zipCode}
          onChange={(e) => {
            if (reAlphaNumbericSymbols(e.target.value)){
              handleOnChange(e)
            }
          }}
        />
      </FormField>

      <FormField>
        <SelectFormVirtical
          label="都道府県"
          name="prefectureCode"
          value={String(state.prefectureCode)}
          setValue={(val) => { handleOnChange (val, 'prefectureCode', val)}}
          options={prefectureOptions}
        />
      </FormField>

      <FormField>
        <TextForm
          name="address"
          label="所在地"
          value={state.address}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="tel1"
            label="TEL1"
            value={state.tel1}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="fax1"
            label="FAX1"
            value={state.fax1}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="tel2"
            label="TEL2"
            value={state.tel2}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="fax2"
            label="FAX2"
            value={state.fax2}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="tel3"
            label="TEL3"
            value={state.tel3}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="fax3"
            label="FAX3"
            value={state.fax3}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField>
        <TextForm
          name="operatingCompany"
          label="運営会社"
          value={state.operatingCompany}
          onChange={handleOnChange}
        />
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="operatingCompanyRepresentative"
            label="代表者"
            value={state.operatingCompanyRepresentative}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="operatingCompanyRepresentativeTel"
            label="代表者連絡先"
            value={state.operatingCompanyRepresentativeTel}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField displayBlock={true}>
        <div style={{ fontSize: '14px' }}>入店方法</div>
        <TextareaAutoResize
          name="entryMethod"
          value={state.entryMethod}
          onChange={handleOnChange}
          rows={5}
        />
      </FormField>

      {/* 店舗面積 */}
      <FormTitle title="店舗面積" />

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="squareMeters"
            label="平米数（㎡）"
            value={state.squareMeters}
            onChange={(e) => {
              if (reDecimalNumber(e.target.value)){
                handleOnChange(e)
              }
            }}
            onBlur={handleOnBlur}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="sharedSpaceSquareMeters"
            label="共有部分 平米数"
            value={state.sharedSpaceSquareMeters}
            onChange={(e) => {
              if (reDecimalNumber(e.target.value)){
                handleOnChange(e)
              }
            }}
            onBlur={handleOnBlur}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="floorSpace"
            label="面積（坪）"
            value={state.floorSpace}
            onChange={(e) => {
              if (reDecimalNumber(e.target.value)){
                handleOnChange(e)
              }
            }}
            onBlur={handleOnBlur}
          />
          <FormHelp
            content="帳票やレポートの計算値として使用します。"
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="numberOfSeat"
            label="席数"
            value={state.numberOfSeat}
            maxLength={5}
            onChange={(e) => {
              if (reNumberic(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
          <FormHelp
            content="帳票やレポートの計算値として使用します。"
          />
        </div>
      </FormField>

      {/* 人事 */}
      <FormTitle title="人事" />

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="shopManager"
            label="店長名"
            value={state.shopManager}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="shopManagerTel"
            label="店長連絡先"
            value={state.shopManagerTel}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="posContact"
            label="ＰＯＳレジ担当者"
            value={state.posContact}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="posContactTel"
            label="ＰＯＳ担当者連絡先"
            value={state.posContactTel}
            onChange={(e) => {
              if (reAlphaNumbericSymbols(e.target.value)){
                handleOnChange(e)
              }
            }}
          />
        </div>
      </FormField>

      <FormField>
        <TextForm
          name="headChef"
          label="料理長名"
          value={state.headChef}
          onChange={handleOnChange}
        />
      </FormField>

      {/* 営業時間 */}
      <FormTitle title="営業時間" />

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="businessHoursMon"
            label="月曜日営業時間"
            value={state.businessHoursMon}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="businessHoursTue"
            label="火曜日営業時間"
            value={state.businessHoursTue}
            onChange={handleOnChange}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="businessHoursWed"
            label="水曜日営業時間"
            value={state.businessHoursWed}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="businessHoursThu"
            label="木曜日営業時間"
            value={state.businessHoursThu}
            onChange={handleOnChange}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="businessHoursFri"
            label="金曜日営業時間"
            value={state.businessHoursFri}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="businessHoursSat"
            label="土曜日営業時間"
            value={state.businessHoursSat}
            onChange={handleOnChange}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="businessHoursSun"
            label="日曜日営業時間"
            value={state.businessHoursSun}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="businessHoursHoliday"
            label="祝日・連休営業時間"
            value={state.businessHoursHoliday}
            onChange={handleOnChange}
          />
        </div>
      </FormField>

      <FormField>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="businessHoursLunch"
            label="ランチ営業時間"
            value={state.businessHoursLunch}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ paddingRight: '15px', width: '100%' }}>
          <TextForm
            name="closingDay"
            label="定休日"
            value={state.closingDay}
            onChange={handleOnChange}
          />
        </div>
      </FormField>

      {/* 広告掲載店舗名 */}
      <FormTitle title="広告掲載店舗名" />
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridGap: '15px'
      }}>
        {addName && addName.length > 0 && addName.map((row: any, rowIndex: number)=>{
          return (
         <>{tableColumnsName.filter((col: any) => col.visible).map((column: any, colIndex: number) => column.content(rowIndex, colIndex))}</>
        )
        })}
      </div>
    </React.Fragment>
  );
};

export default StoreBasicInformationForm;
