import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import PrescribedDaysConfigForm from 'components/organismos/master/labor/PrescribedDaysConfigForm';
import { useParams } from 'react-router-dom';
import FormContents from 'components/atoms/Form/FormContents';
import TabControl from 'components/atoms/TabControl';
import PrescribedDaysCreateForm from 'components/organismos/master/labor/PrescribedDaysCreateForm';

type SelectTab = 'edit'|'setting';
const SelectTab: {[key in SelectTab]: SelectTab} = {
  edit: 'edit',
  setting: 'setting',
};

const WorkingDaysEditPage: React.FC = () => {
  const {
    workingDaysId,
    tabIndex,
  } = useParams();

  const [selectTab, setSelectTab] = React.useState<SelectTab>(
    tabIndex === '1' ? SelectTab.setting : SelectTab.edit,
  );

  return (
    <SidebarTemplate pageTitle={workingDaysId ? '所定日数編集' : '所定日数作成'}>
      <FormContents>
        <TabControl
          items={[
            {
              value: SelectTab.edit,
              display: '所定日数情報',
            },
            {
              value: SelectTab.setting,
              display: '所定日数割当',
            },
          ]}
          setValue={setSelectTab as (arg: string) => void}
          currentValue={selectTab}
          isFixed={true}
        />
        {selectTab === SelectTab.edit
        && (
          <PrescribedDaysCreateForm />
        )}
        {selectTab === SelectTab.setting
        && (
          <PrescribedDaysConfigForm />
        )}

      </FormContents>
    </SidebarTemplate>
  );
};
export default WorkingDaysEditPage;
