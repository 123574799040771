import { css } from '@emotion/core';

const styles = {
  table: css({
    width: 1675,
    // position: 'relative',
  }),
  recordNo: css({
    width: 80,
    textAlign: 'center',
    position: 'sticky',
    left: 0,
    top: 0,
    zIndex: 999,
  }),
  delete: css({
    width: 50,
    textAlign: 'center',
    position: 'sticky',
    left: 82,
    top: 0,
    zIndex: 999,
  }),
  store: css({
    width: 280,
    textAlign: 'center',
    position: 'sticky',
    left: 134,
    top: 0,
    zIndex: 999,
  }),
  cardNo: css({
    width: 80,
    textAlign: 'center',
    position: 'sticky',
    left: 416,
    top: 0,
    zIndex: 999,
  }),
  bankName: css({
    width: 300,
    textAlign: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 9,
  }),
  // notLinked: css({
  //   width: 80,
  //   textAlign: 'center',
  // }),
  nonInterlocking: css({
    width: 80,
    textAlign: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 9,
  }),
  depositAmount: css({
    width: 100,
    textAlign: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 9,
  }),
  withdrawalAmount: css({
    width: 100,
    textAlign: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 9,
  }),
  balance: css({
    width: 100,
    textAlign: 'center',
  }),
  businessDayStart: css({
    width: 160,
    textAlign: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 9,
  }),
  businessDayEnd: css({
    width: 160,
    textAlign: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 9,
  }),
  specialBusinessDay: css({
    width: 80,
    textAlign: 'center',
  }),
  remarks: css({
    width: 350,
    textAlign: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 9,
  }),
  reasonForChange: css({
    width: 350,
    textAlign: 'center',
  }),
  remarksBodyTextLeft: css({
    width: 350,
    textAlign: 'left',
  }),
  // For sticky cells
  recordNoStickyBody: css({
    width: 80,
    textAlign: 'center',
    position: 'sticky',
    left: 0,
    backgroundColor: '#FFF',
    zIndex: 9,
  }),
  deleteStickyBody: css({
    width: 50,
    textAlign: 'center',
    position: 'sticky',
    left: 82,
    backgroundColor: '#FFF',
    zIndex: 9,
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  storeStickyBody: css({
    width: 280,
    position: 'sticky',
    left: 134,
    backgroundColor: '#FFF',
    zIndex: 9,
  }),
  cardNoStickyBody: css({
    width: 80,
    textAlign: 'center',
    position: 'sticky',
    left: 416,
    backgroundColor: '#FFF',
    zIndex: 9,
  }),
  dateChangeStatus: css({
    width: 55,
    textAlign: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 9,
    padding: 0,
  }),
  accountingNonLinkFlgBody: css({
    textAlign: 'center',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
};

export default styles;
