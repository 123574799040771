import moment from 'moment';

/**
 * API 祝日マスタ
 */
export default class AdvertisingMediaOrganizationDomain {
  constructor(private rawData: Array<any>) {
    // do nothing
  }

  static generateInitial(): AdvertisingMediaOrganizationDomain {
    return new AdvertisingMediaOrganizationDomain(['', '', '', '', '', '', new Date(), new Date('9999-12-31')]);
  }

  getRawData(): Array<any> {
    return this.rawData;
  }

  get companyCode(): string {
    return this.rawData[0];
  }

  set companyCode(companyCode: string) {
    this.rawData[0] = companyCode;
  }

  get advertisingMediaCode(): string {
    return this.rawData[1];
  }

  set advertisingMediaCode(mediaCode:string) {
    this.rawData[1] = mediaCode;
  }

  get advertisingMediaStr(): string {
    return this.rawData[2];
  }

  set advertisingMediaStr(advertisingMedia: string) {
    this.rawData[2] = advertisingMedia;
  }

  get postType(): string {
    return this.rawData[3];
  }

  set postType(postType:string) {
    this.rawData[3] = postType;
  }

  get postTypeStr(): string {
    return this.rawData[4];
  }

  set postTypeStr(postTypeStr:string) {
    this.rawData[4] = postTypeStr;
  }

  get importCode(): string {
    return this.rawData[5];
  }

  set importCode(importCode:string) {
    this.rawData[5] = importCode;
  }

  get applicableStartMonth(): Date {
    return moment(this.rawData[6]).toDate();
  }

  get applicableStartMonthStr(): string {
    return moment(this.rawData[6]).format('YYYY年MM月');
  }

  set applicableStartMonth(applicableStartMonth: Date) {
    this.rawData[6] = applicableStartMonth;
  }

  get applicableStartMonthFormat(): string {
    return moment(this.rawData[6]).format('YYYY-MM');
  }

  get applicableEndMonth(): Date {
    if (this.rawData[7] === '9999-12-31') {
      return new Date('9999-12-31');
    }
    return moment(this.rawData[7]).toDate();
  }

  get applicableEndMonthStr(): string {
    if (this.rawData[7] === '9999-12-31') {
      return '-';
    }
    return moment(this.rawData[7]).format('YYYY年MM月');
  }

  set applicableEndMonth(applicableEndMonth: Date) {
    this.rawData[7] = applicableEndMonth;
  }
}
